import WasmController from "react-lib/frameworks/WasmController";
import DrugOrderQueueList from "issara-sdk/apis/DrugOrderQueueList_apps_TPD";
import DoctorPendingTaskDrugOrderList from "issara-sdk/apis/DoctorPendingTaskDrugOrderList_apps_DPO";
import { formatDate } from "react-lib/utils/dateUtils";
import moment from "moment";

export type State = {
  // Common
  masterOptions?: any;
  selectedEncounter?: any;
  // Seq
  SetDrugOrderQueueSequence?: {
    sequenceIndex: string | null;
    selectedPatientSearch?: any;
    selectedPatientSearchHN?: any;
    selectedDivision?: any;
    selectedDrugOrderType?: any;
    selectedShipper?: any;
    selectedDrugOrderStatus?: any;
    selectedStartDate?: any;
    selectedEndDate?: any;
    selectedLocation?: any;
    checkedOPD?: boolean | null;
    checkedIPD?: boolean | null;
    checkedContinue?: boolean | null;
    searching?: boolean | null;
  } | null;
  drugOrderQueue?: any[];
  verbalDrugOrderQueue?: any[];
};

export const StateInitial: State = {
  SetDrugOrderQueueSequence: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  user?: number;
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  let state = controller.getState();
  console.log("SetDrugOrderQueue Start: Before clear state: ", state);
  console.log("SetDrugOrderQueue Start: Before clear params: ", params);
  if (!state.SetDrugOrderQueueSequence) {
    return;
  }
  // Master data 
  // แยก เพาะ division โหลดช้า
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["division", {}],
        ["shippingCompany", {}],
        ["divisionTypeDrug", {}],
      ],
    },
  });

  await controller.handleEvent({
    message: "GetMasterData",
    params: { masters: [["drugOrderType", {}], ["drugOrderStatus", {}]] },
  });

  state = controller.getState();
  // If Card is not mount do nothing
  controller.setState({
    SetDrugOrderQueueSequence: {
      ...state.SetDrugOrderQueueSequence,
      sequenceIndex: "preparedMasterData",
    },
  });
};

export const preparedMasterData: Handler = async (controller, params) => {
  let state = controller.getState();

  const filter = JSON.parse(
    controller.cookies.get("filterDrugOrderQueue") || "{}"
  );


  console.log("CLEAR preparedMasterData SelectEncounter !!! params: ", params);


  // console.log("SetDrugOrderQueue clear selectedEncounter: null ")
  let today = formatDate(moment());
  controller.setState(
    {
      SetDrugOrderQueueSequence: {
        ...state.SetDrugOrderQueueSequence,
        sequenceIndex: "SearchDrugOrder",
        checkedIPD: true,
        checkedOPD: true,
        selectedLocation: controller.data.division,
        selectedStartDate: today,
        selectedEndDate: today,
        ...getDrugOrderStatusType(controller.getState()?.masterOptions),
        ...filter,
      },
      selectedEncounter : null
    },
    () =>
      controller.handleEvent({
        message: "RunSequence",
        params: { ...params, action: "search" },
      })
  );
  console.log("SetDrugOrderQueue Start: clear selectedEncounter !!!! ");
};

export const SearchDrugOrder: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.SetDrugOrderQueueSequence) {
    console.warn("SetDrugOrderQueueSequence are null return");
    return;
  }

  if (params?.action === "search") {
    controller.setState({
      SetDrugOrderQueueSequence: {
        ...state.SetDrugOrderQueueSequence,
        searching: true,
      },
    });

    const drugOrder = state.SetDrugOrderQueueSequence || {};
    const type: any[] = drugOrder.selectedDrugOrderType || [];
    const status: any[] = drugOrder.selectedDrugOrderStatus || [];

    let urlParams: any = {
      is_opd: drugOrder.checkedOPD,
      is_ipd: drugOrder.checkedIPD,
      is_continue_day_dose: drugOrder.checkedContinue,
      order_div: drugOrder.selectedDivision || "",
      type: type,
      patient: drugOrder.selectedPatientSearch || "",
      is_shipping: true,
      status: status,
      shipping_company: drugOrder.selectedShipper || "",
      order_perform_div: drugOrder.selectedLocation || "",
      start_date: drugOrder.selectedStartDate || "",
      end_date: drugOrder.selectedEndDate || "",
      exclude_verbal_null_item: true
    };

    // Case Drug Order
    // #58186 set cookies ทุกครั้งเมื่อมีการค้นหา
    controller.cookies.set("filterDrugOrderQueue", JSON.stringify(drugOrder));

    const [drugRes, drugErr] = await DrugOrderQueueList.list({
      params: urlParams,
      apiToken: controller.apiToken,
    });

    if (drugErr) {
      console.log("Drug queue error fetching");
      controller.setState({
        SetDrugOrderQueueSequence: {
          ...state.SetDrugOrderQueueSequence,
          searching: false,
        },
      });
      return;
    }

    controller.setState({
      SetDrugOrderQueueSequence: {
        ...state.SetDrugOrderQueueSequence,
        searching: false,
      },
      drugOrderQueue: drugRes?.items || [],
    });

    // verbal order
    const [drugVerbalRes, drugVerbalErr] =
      await DoctorPendingTaskDrugOrderList.get({
        params: {
          order_specific_types: "DRUG_ORDER",
          patient: state.SetDrugOrderQueueSequence?.selectedPatientSearch || "",
        },
        apiToken: controller.apiToken,
      });

    controller.setState({
      SetDrugOrderQueueSequence: {
        ...state.SetDrugOrderQueueSequence,
        searching: false,
      },
      verbalDrugOrderQueue: drugVerbalRes?.items,
    });
  }
};

// Utils
export const getDrugOrderStatusType = (
  masterOptions: Record<string, any[]>
) => {
  const statusKeys = ["REQUESTED", "PRINTED", "CHECKED"];
  const typeKeys = [
    "STAT",
    "ONE_DAY",
    "ONE_DOSE",
    "HOME_OPD",
    "CONTINUE_PLAN",
    "CONTINUE",
    "HOME_IPD",
    "OPERATING",
  ];

  const options = masterOptions || {};
  const type: any[] = options.drugOrderType || [];
  const status: any[] = options.drugOrderStatus || [];

  const assignValue = (lists: any[], keys: string[]) => {
    return lists.flatMap((item) =>
      keys.includes(item.text) ? [item.value] : []
    );
  };

  return {
    selectedDrugOrderStatus: assignValue(status, statusKeys),
    selectedDrugOrderType: assignValue(type, typeKeys),
  };
};
