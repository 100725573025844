import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  TextArea,
  Label,
  Button
} from 'semantic-ui-react'

const CardPatientPanelUX = (props: any) => {
    return(
      <div
        style={{width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
        <div
          style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "10px", height: "250px", width: "auto"}}>
          {props.profileImage}
        </div>
        <div>
          
          <div
            style={{ display: "grid",alignItems: "center", marginTop:"-50px",float:'right'}}>
            
            <div
              style={{display: props.adrList ?  "flex" : "none" ,justifyContent: "row", alignItems: "center", marginLeft:"40px"}}>
              
              <div
                style={{padding: "2px"}}>
                {props.otherImage}
              </div>
              <div
                style={{padding: "2px"}}>
                {props.foodImage}
              </div>
              <div
                style={{padding: "2px"}}>
                {props.drugImage}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{padding: "10px",marginTop:"10px"}}>
          {props.statusAndAction}
        </div>
        <div
          style={{"width":"100%","height":"100%" , position:"relative", "paddingLeft":"10px"}}>
          
          <div
            style={{display: "flex", width : "100%"}}>
            
            <div
              style={{ width: "35%"}}>
              HN
            </div>
            <div
              style={{width: "65%"}}>
              {props.hn}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{ width: "35%"}}>
              ชื่อ
            </div>
            <div
              style={{ width: "65%"}}>
              {props.patientName}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{ width: "35%", display: "flex", alignItems: "center", }}>
              เพศ
            </div>
            <div
              style={{ width: "32%", display: "flex", alignItems: "center",}}>
              {props.gender}
            </div>
            <div
              style={{ width: "33%", display: "flex", alignItems: "center",  ...(!props.isShowPregnancy && { display: "none"})}}>
              {props.pregnancyImage}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{ width: "35%"}}>
              อายุ
            </div>
            <div
              style={{ width: "65%"}}>
              {props.age}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{ width: "35%"}}>
              วันเกิด
            </div>
            <div
              style={{ width: "65%"}}>
              {props.birthdate}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{ width: "35%"}}>
              เบอร์โทร
            </div>
            <div
              style={{ width: "65%"}}>
              {props.tel_mobile}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px", }}>
            
            <div
              style={{ width: "35%"}}>
              E-mail
            </div>
            <div
              style={{ width: "65%", wordWrap: "break-word"}}>
              {props.email}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{width: "100%" }}>
              Citizen ID / Passport no
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{width: "100%" }}>
              {props.citizen_no}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{ width: "35%"}}>
              สิทธิ
            </div>
            <div
              style={{ width: "65%"}}>
              {props.coverage}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{ width: "35%"}}>
              Payer
            </div>
            <div
              style={{ width: "65%"}}>
              {props.payer}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{ width: "35%"}}>
              ศาสนา
            </div>
            <div
              style={{ width: "65%"}}>
              {props.religion}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{ width: "35%"}}>
              Checkout
            </div>
            <div
              style={{ width: "65%"}}>
              {props.checkout}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{ width: "35%"}}>
              จุดสังเกต
            </div>
            <div
              style={{ width: "65%"}}>
              {props.remark}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{ width: "35%"}}>
              หมายเหตุ
            </div>
            <div
              style={{ width: "65%"}}>
              {props.note}
            </div>
          </div>
          <div>
            
            <div>
              ภาษาหลักที่ใช้ในการติดต่อ
            </div>
            <div>
              {props.patientLanguage}
            </div>
          </div>
          <div
            style={{display: "flex", width : "100%", margin: "10px 0px"}}>
            
            <div
              style={{ "textAlign":"center", width: "35%"}}>
              {props.telemed}
            </div>
            <div>
              {props.ButtonSuspending}
            </div>
            <div
              style={{ width: "65%"}}>
              {props.btnBindId}
            </div>
          </div>
        </div>
        <div
          style={{padding:"5px"}}>
          
          <Button
            color="pink"
            disabled={!props.showDentalHistory}
            fluid={true}
            onClick={props.goToDentalHistory}
            style={{display: props.showDentalHistory ? "":"none", padding: "0.6em 1.25em"}}>
            <div
              style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              
              <img
                src="/static/images/tooth-icon.png"
                style={{width: "20px", marginRight: "7px"}}>
              </img>
              <label>
                Dental History
              </label>
            </div>
          </Button>
          <Button
            color="blue"
            onClick={props.goToEclinical}
            style={{width: "100%", margin: "10px 0px",...(!props.ENABLE_ECLINICAL&& {display: "none"})}}>
            E-clinical
          </Button>
          <Button
            color="blue"
            onClick={props.goToCreateAppointment}
            style={{width: "100%", margin: "10px 0px"}}>
            ทำนัดหมาย
          </Button>
          <div
            style={{position :"relative"}}>
            
            <TextArea
              style={{ width: "100%", height: "100%" }}
              value={props?.selectedADR || ""}>
            </TextArea>
            <Label
              onClick={props?.OnADR}
              style={{position: "absolute",right:"3px",bottom:"9px"}}>
              ADR
            </Label>
          </div>
          <div
            style={{position :"relative"}}>
            
            <TextArea
              style={{ width: "100%", height: "100%" }}
              value={props?.underlyingDisease || ""}>
            </TextArea>
            <Label
              onClick={ props?.OnUnderlyingDisease}
              style={{position: "absolute",right:"3px",bottom:"9px"}}>
              U/D
            </Label>
          </div>
          <div
            style={{position :"relative"}}>
            
            <TextArea
              style={{ width: "100%", height: "100%" }}
              value={props?.labResult || ""}>
            </TextArea>
            <Label
              onClick={ props?.OnLab}
              style={{position: "absolute",right:"3px",bottom:"9px"}}>
              Lab
            </Label>
          </div>
          <div
            style={{position :"relative"}}>
            
            <TextArea
              style={{ width: "100%", height: "100%" }}
              value={props?.vitalSign || ""}>
            </TextArea>
            <Label
              onClick={ props?.OnVitalSign}
              style={{position: "absolute",right:"3px",bottom:"9px"}}>
              V/S
            </Label>
          </div>
          <div
            style={{"display":"grid","position":"relative","gridTemplateRows":"repeat(5, 1fr)","gridTemplateColumns":"1fr 2fr", "paddingLeft":"10px"}}>
            
            <div>
              น้ำหนัก
            </div>
            <div>
              {props?.weigth}
            </div>
            <div>
              ส่วนสูง
            </div>
            <div>
              {props?.height}
            </div>
            <div>
              สูบบุหรี่
            </div>
            <div>
              {props?.tobacco}
            </div>
            <div>
              ดื่ม
            </div>
            <div>
              {props?.narcotic}
            </div>
          </div>
        </div>
        <div>
          
          <div>
            {props.modalDrug}
          </div>
          <div>
            {props.modalFood}
          </div>
          <div>
            {props.modalOther}
          </div>
          <div>
            {props.modalKDA}
          </div>
          <div>
            {props.modalKFA}
          </div>
          <div>
            {props.modalKOA}
          </div>
        </div>
      </div>
    )
}


export default CardPatientPanelUX

export const screenPropsDefault = {"hn":"1234","patientImage":"https://www.gannett-cdn.com/presto/2022/02/27/USAT/1846e15e-f386-491c-9b5a-e6f9f6ace1e1-AP_Russia_Space_Putin.jpg?crop=3884,2913,x0,y0&quality=50&width=640","patientName":"วลาดิเมีย ปูติน","profileImage":"[Profile Image]","telemed":"[O]"}

/* Date Time : Thu Jun 29 2023 03:42:29 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", display: \"flex\", flexDirection: \"column\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.profileImage"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"10px\", height: \"250px\", width: \"auto\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.statusAndAction"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\",marginTop:\"10px\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding:\"5px\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position :\"relative\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "TextArea",
      "parent": 72,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props?.selectedADR || \"\""
        }
      },
      "seq": 73,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Label",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "ADR"
        },
        "onClick": {
          "type": "code",
          "value": "props?.OnADR"
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\",right:\"3px\",bottom:\"9px\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position :\"relative\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "TextArea",
      "parent": 75,
      "props": {
        "onBlur": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props?.underlyingDisease || \"\""
        }
      },
      "seq": 76,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Label",
      "parent": 75,
      "props": {
        "children": {
          "type": "value",
          "value": "U/D"
        },
        "onClick": {
          "type": "code",
          "value": " props?.OnUnderlyingDisease"
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\",right:\"3px\",bottom:\"9px\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position :\"relative\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position :\"relative\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "TextArea",
      "parent": 79,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props?.labResult || \"\""
        }
      },
      "seq": 81,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "TextArea",
      "parent": 80,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props?.vitalSign || \"\""
        }
      },
      "seq": 82,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Label",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab"
        },
        "onClick": {
          "type": "code",
          "value": " props?.OnLab"
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\",right:\"3px\",bottom:\"9px\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Label",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": "V/S"
        },
        "onClick": {
          "type": "code",
          "value": " props?.OnVitalSign"
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\",right:\"3px\",bottom:\"9px\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(5, 1fr)\",\"gridTemplateColumns\":\"1fr 2fr\", \"paddingLeft\":\"10px\"}"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "น้ำหนัก"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.weigth"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่วนสูง"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.height"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "สูบบุหรี่"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.tobacco"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "ดื่ม"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.narcotic"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Button",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "ทำนัดหมาย"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.goToCreateAppointment"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"grid\",alignItems: \"center\", marginTop:\"-50px\",float:'right'}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.adrList ?  \"flex\" : \"none\" ,justifyContent: \"row\", alignItems: \"center\", marginLeft:\"40px\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "code",
          "value": "props.otherImage"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"2px\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "code",
          "value": "props.foodImage"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"2px\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugImage"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"2px\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalDrug"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalFood"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalOther"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\" , position:\"relative\", \"paddingLeft\":\"10px\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 118,
      "props": {
        "children": {
          "type": "code",
          "value": "props.hn"
        },
        "style": {
          "type": "code",
          "value": "{width: \"65%\"}"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 121,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 121,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientName"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": "เพศ"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\", display: \"flex\", alignItems: \"center\", }"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "code",
          "value": "props.gender"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"32%\", display: \"flex\", alignItems: \"center\",}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 127,
      "props": {
        "children": {
          "type": "value",
          "value": "อายุ"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 127,
      "props": {
        "children": {
          "type": "code",
          "value": "props.age"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเกิด"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 130,
      "props": {
        "children": {
          "type": "code",
          "value": "props.birthdate"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 133,
      "props": {
        "children": {
          "type": "value",
          "value": "เบอร์โทร"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 133,
      "props": {
        "children": {
          "type": "code",
          "value": "props.tel_mobile"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\", }"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": "E-mail"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "code",
          "value": "props.email"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\", wordWrap: \"break-word\"}"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": "สิทธิ"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "code",
          "value": "props.coverage"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "div",
      "parent": 142,
      "props": {
        "children": {
          "type": "value",
          "value": "Citizen ID / Passport no"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\" }"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 143,
      "props": {
        "children": {
          "type": "code",
          "value": "props.citizen_no"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\" }"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width : \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 146,
      "props": {
        "children": {
          "type": "value",
          "value": "Payer"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 146,
      "props": {
        "children": {
          "type": "code",
          "value": "props.payer"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "div",
      "parent": 147,
      "props": {
        "children": {
          "type": "value",
          "value": "ศาสนา"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "div",
      "parent": 147,
      "props": {
        "children": {
          "type": "code",
          "value": "props.religion"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 148,
      "props": {
        "children": {
          "type": "value",
          "value": "Checkout"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 148,
      "props": {
        "children": {
          "type": "code",
          "value": "props.checkout"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "div",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": "จุดสังเกต"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 149,
      "props": {
        "children": {
          "type": "code",
          "value": "props.remark"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\"}"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "div",
      "parent": 150,
      "props": {
        "children": {
          "type": "code",
          "value": "props.note"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 151,
      "props": {
        "children": {
          "type": "code",
          "value": "props.telemed"
        },
        "style": {
          "type": "code",
          "value": "{ \"textAlign\":\"center\", width: \"35%\"}"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "div",
      "parent": 151,
      "props": {
        "children": {
          "type": "code",
          "value": "props.btnBindId"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\"}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalKDA"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalKFA"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalKOA"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 151,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSuspending"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "Button",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "E-clinical"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.goToEclinical"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", margin: \"10px 0px\",...(!props.ENABLE_ECLINICAL&& {display: \"none\"})}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pregnancyImage"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\", alignItems: \"center\",  ...(!props.isShowPregnancy && { display: \"none\"})}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "Button",
      "parent": 71,
      "props": {
        "color": {
          "type": "value",
          "value": "pink"
        },
        "disabled": {
          "type": "code",
          "value": "!props.showDentalHistory"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.goToDentalHistory"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showDentalHistory ? \"\":\"none\", padding: \"0.6em 1.25em\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "div",
      "parent": 174,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"center\", alignItems: \"center\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "img",
      "parent": 175,
      "props": {
        "src": {
          "type": "value",
          "value": "/static/images/tooth-icon.png"
        },
        "style": {
          "type": "code",
          "value": "{width: \"20px\", marginRight: \"7px\"}"
        }
      },
      "seq": 176,
      "void": true
    },
    {
      "from": null,
      "id": 177,
      "name": "label",
      "parent": 175,
      "props": {
        "children": {
          "type": "value",
          "value": "Dental History"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "div",
      "parent": 178,
      "props": {
        "children": {
          "type": "value",
          "value": "ภาษาหลักที่ใช้ในการติดต่อ"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "div",
      "parent": 178,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientLanguage"
        }
      },
      "seq": 180,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": false,
  "memo": false,
  "name": "CardPatientPanelUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "hn": "1234",
    "patientImage": "https://www.gannett-cdn.com/presto/2022/02/27/USAT/1846e15e-f386-491c-9b5a-e6f9f6ace1e1-AP_Russia_Space_Putin.jpg?crop=3884,2913,x0,y0&quality=50&width=640",
    "patientName": "วลาดิเมีย ปูติน",
    "profileImage": "[Profile Image]",
    "telemed": "[O]"
  },
  "width": 15
}

*********************************************************************************** */
