import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Input,
  Dropdown,
  Label,
  Button,
  Icon
} from 'semantic-ui-react'

const CardSupplyOrderWorkflowUX = (props: any) => {
    return(
      <div
        style={{margin: "10px",width: "100%"}}>
        <div
          style={{display: "none", justifyContent: "flex-start", alignItems: "center",padding: "10px 0px 20px 0px"}}>
          
          <div
            style={{fontWeight: "bold" , fontSize: "large", marginRight: "10px"}}>
            กรอกรหัส
          </div>
          <Input
            onChange={props.changeCoUser}
            onKeyDown={(e: any) => { e.key === "Enter" && props.coUserVerify(); }}
            style={{marginRight: "10px"}}
            value={props.coUser}>
          </Input>
          <Dropdown
            selection={true}>
          </Dropdown>
        </div>
        <div
          style={{display: "flex", justifyContent: "space-between", margin: "10px 20px 10px 0px"}}>
          
          <div
            style={{display: "flex", alignItems:"center"}}>
            
            <Label
              color={props.ribbonColor}
              style={{marginRight: "10px"}}>
              {props.titleRibbon}
            </Label>
            <div
              style={{fontWeight: "bold",fontSize: "large"}}>
              {props.title}
            </div>
          </div>
          <div
            style={{display: "flex", alignItems:"center"}}>
            
            <Label
              color={props.statusColor}
              style={{marginRight: "10px"}}>
              {props.status}
            </Label>
            <Label
              color={props.isPaidColor}
              style={!props.isPaid ? {display: "none"} : {}}>
              {props.isPaid}
            </Label>
          </div>
        </div>
        <div
          style={{paddingRight: "10px"}}>
          {props.userActionLogList}
        </div>
        <Table
          data={props.supplyData}
          getTrProps={props.getTrProps}
          headers={props.headers ? props.headers : "No,Code,Mode,Product Name,ขนาดบรรจุ,คงคลัง,จำนวนสั่ง,จะจ่าย,ขาดคราว,คืน,หน่วยสั่ง,ราคาต่อหน่วย,ราคารวม,เบิกได้,เบิกไม่ได้,ส่วนลด,Eligibility Type"}
          keys={props.keys ? props.keys : "no,code,mode,name,package_size,stock_balance,quantity_request, quantity_issue,quantity_postpone,returned_quantity, stock_unit, price_unit, price_total, price_claimable, price_non_claimable, price_discount, eligibility_type"}
          minRows={10}
          showPagination={false}
          style={{marginRight: "20px"}}
          widths={props.widths ? props.widths : "40,100,105,180,100,60,80,60,80,60,90,100,70,60,70,60,120"}>
        </Table>
        <div
          style={{margin:"10px"}}>
          {props.errorMessage}
        </div>
        <div
          style={{display: "flex",margin:"10px 0px"}}>
          
          <div
            style={{display: "flex",justifyContent:"center",alignItems:"center",gap:"10px",margin: "0px 10px"}}>
            
            <div>
              {props.isCostCenter ? "มูลค่ารวม" : "ราคารวม"}
            </div>
            <Input
              value={props.sumPriceTotal}>
            </Input>
          </div>
          <div
            style={{display: props.isCostCenter? "none": "flex",justifyContent:"center",alignItems:"center",gap:"10px",margin: "0px 10px"}}>
            
            <div>
              เบิกได้
            </div>
            <Input
              value={props.sumPriceClaimable}>
            </Input>
          </div>
          <div
            style={{display: props.isCostCenter? "none": "flex",justifyContent:"center",alignItems:"center",gap:"10px",margin: "0px 10px"}}>
            
            <div>
              เบิกไม่ได้
            </div>
            <Input
              value={props.sumPriceNonClaimable}>
            </Input>
          </div>
          <div
            style={{display: props.isCostCenter? "none": "flex",justifyContent:"center",alignItems:"center",gap:"10px",margin: "0px 10px"}}>
            
            <div>
              ส่วนลด
            </div>
            <Input
              value={props.sumPriceDiscount}>
            </Input>
          </div>
        </div>
        <div
          style={{display: "flex",margin: "10px 10px"}}>
          
          <div
            style={{display:"flex"}}>
            
            <Button
              icon={true}
              onClick={props.refreshSupply}
              size="small">
              <Icon
                name={"redo"}>
              </Icon>
            </Button>
            <Button
              icon={true}
              onClick={props.getActionLog}
              size="small">
              LOG
            </Button>
            <div
              style={{display:props.status ==="PRINTED" ? "":"none"}}>
              {props.buttonRePrint}
            </div>
          </div>
          <div
            style={{flex:"1"}}>
            
          </div>
          <div
            style={{display: props.type === "IPD" && props.unCheck ? "" : "none"}}>
            {props.additionalProps}
          </div>
          <div
            style={{display: "flex", gap: "5px",marginRight: "10px", alignItems: "center",marginLeft:"10px"}}>
            
            <Button
              color="yellow"
              onClick={props.onEdit}
              size="small"
              style={{display: props.status === "REQUESTED" || props.status === "VERIFY" ? "" : "none"}}>
              แก้ไข
            </Button>
            <Button
              disabled={props.selectedRow ? false : true}
              onClick={props.returnSupply}
              size="small"
              style={{display: props.showButtonReturn? "" : "none"}}>
              คืนเวชภัณฑ์
            </Button>
            <div
              id="btn-supplyCheckOrder"
              style={{display: props.status === "REQUESTED" || props.status === "VERIFY" ? "" : "none"}}>
              {props.buttonCheckOrder}
            </div>
            <div
              id="btn-supplyCheck"
              style={{display: props.status === "PRINTED" ? "" : "none"}}>
              {props.buttonCheck}
            </div>
            <div
              style={{display: props.checkIPD || props.checkOPD ? "" : "none"}}>
              {props.buttonPaid}
            </div>
            <div
              style={{display: props.status === "TRANSPORTED" ? "" : "none"}}>
              {props.buttonReceive}
            </div>
            <Button
              onClick={props.OnReturn}
              size="small"
              style={{display: props.showButtonRequestReturn ? "":"none"}}>
              ขอคืนเวชภัณฑ์
            </Button>
            <Button
              color="red"
              onClick={props.OnCancel}
              size="small"
              style={{display: props.status === "REQUESTED" || props.status === "VERIFY" ? "" : "none"}}>
              ยกเลิกการสั่ง
            </Button>
            <Button
              color="red"
              onClick={props.OnUnCheck}
              size="small"
              style={{display:props.unCheck || props.unCheckOneday ? "" : "none"}}>
              ยกเลิกการจัด
            </Button>
            <Button
              color="red"
              onClick={props.OnUnVerify}
              size="small"
              style={{display: props.status === "PRINTED"  || props.status === "VERIFIED"? "" : "none"}}>
              ยกเลิกการตรวจ
            </Button>
            <Button
              color="red"
              onClick={props.OnUndeliver}
              size="small"
              style={{display: (props.unDeliverIPD || props.unDeliverOPD) && !props.hideBtnUnDeliver ? "" : "none"}}>
              ยกเลิกการจ่าย
            </Button>
          </div>
        </div>
      </div>
    )
}

export default CardSupplyOrderWorkflowUX

export const screenPropsDefault = {}

/* Date Time : Mon Apr 24 2023 15:50:31 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{margin: \"10px\",width: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\", justifyContent: \"flex-start\", alignItems: \"center\",padding: \"10px 0px 20px 0px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\", margin: \"10px 20px 10px 0px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "กรอกรหัส"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\" , fontSize: \"large\", marginRight: \"10px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems:\"center\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems:\"center\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 20,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.supplyData"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "code",
          "value": "props.headers ? props.headers : \"No,Code,Mode,Product Name,ขนาดบรรจุ,คงคลัง,จำนวนสั่ง,จะจ่าย,ขาดคราว,คืน,หน่วยสั่ง,ราคาต่อหน่วย,ราคารวม,เบิกได้,เบิกไม่ได้,ส่วนลด,Eligibility Type\""
        },
        "keys": {
          "type": "code",
          "value": "props.keys ? props.keys : \"no,code,mode,name,package_size,stock_balance,quantity_request, quantity_issue,quantity_postpone,returned_quantity, stock_unit, price_unit, price_total, price_claimable, price_non_claimable, price_discount, eligibility_type\""
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"20px\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.widths ? props.widths : \"40,100,105,180,100,60,80,60,80,60,90,100,70,60,70,60,120\""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",margin:\"10px 0px\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",justifyContent:\"center\",alignItems:\"center\",gap:\"10px\",margin: \"0px 10px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.isCostCenter? \"none\": \"flex\",justifyContent:\"center\",alignItems:\"center\",gap:\"10px\",margin: \"0px 10px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.isCostCenter? \"none\": \"flex\",justifyContent:\"center\",alignItems:\"center\",gap:\"10px\",margin: \"0px 10px\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.isCostCenter? \"none\": \"flex\",justifyContent:\"center\",alignItems:\"center\",gap:\"10px\",margin: \"0px 10px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",margin: \"10px 10px\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Input",
      "parent": 1,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeCoUser"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any) => { e.key === \"Enter\" && props.coUserVerify(); }"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.coUser"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Dropdown",
      "parent": 1,
      "props": {
        "selection": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Label",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.titleRibbon"
        },
        "color": {
          "type": "code",
          "value": "props.ribbonColor"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.title"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",fontSize: \"large\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Label",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        },
        "color": {
          "type": "code",
          "value": "props.statusColor"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Label",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.isPaid"
        },
        "color": {
          "type": "code",
          "value": "props.isPaidColor"
        },
        "style": {
          "type": "code",
          "value": "!props.isPaid ? {display: \"none\"} : {}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": "props.isCostCenter ? \"มูลค่ารวม\" : \"ราคารวม\""
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกได้"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกไม่ได้"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่วนลด"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Input",
      "parent": 22,
      "props": {
        "value": {
          "type": "code",
          "value": "props.sumPriceTotal"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Input",
      "parent": 23,
      "props": {
        "value": {
          "type": "code",
          "value": "props.sumPriceClaimable"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Input",
      "parent": 24,
      "props": {
        "value": {
          "type": "code",
          "value": "props.sumPriceNonClaimable"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Input",
      "parent": 25,
      "props": {
        "value": {
          "type": "code",
          "value": "props.sumPriceDiscount"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Button",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": "LOG"
        },
        "icon": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.getActionLog"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Button",
      "parent": 68,
      "props": {
        "icon": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.refreshSupply"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Icon",
      "parent": 65,
      "props": {
        "name": {
          "type": "code",
          "value": "\"redo\""
        },
        "onClick": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.userActionLogList"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: \"10px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"5px\",marginRight: \"10px\", alignItems: \"center\",marginLeft:\"10px\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Button",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "แก้ไข"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onEdit"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.status === \"REQUESTED\" || props.status === \"VERIFY\" ? \"\" : \"none\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Button",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกการสั่ง"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnCancel"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.status === \"REQUESTED\" || props.status === \"VERIFY\" ? \"\" : \"none\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 69,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonCheck"
        },
        "id": {
          "type": "value",
          "value": "btn-supplyCheck"
        },
        "style": {
          "type": "code",
          "value": "{display: props.status === \"PRINTED\" ? \"\" : \"none\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 69,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPaid"
        },
        "style": {
          "type": "code",
          "value": "{display: props.checkIPD || props.checkOPD ? \"\" : \"none\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Button",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกการจัด"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnUnCheck"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display:props.unCheck || props.unCheckOneday ? \"\" : \"none\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Button",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกการจ่าย"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnUndeliver"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: (props.unDeliverIPD || props.unDeliverOPD) && !props.hideBtnUnDeliver ? \"\" : \"none\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Button",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "ขอคืนเวชภัณฑ์"
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.OnReturn"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showButtonRequestReturn ? \"\":\"none\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Button",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "คืนเวชภัณฑ์"
        },
        "disabled": {
          "type": "code",
          "value": "props.selectedRow ? false : true"
        },
        "onClick": {
          "type": "code",
          "value": "props.returnSupply"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showButtonReturn? \"\" : \"none\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 69,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonCheckOrder"
        },
        "id": {
          "type": "value",
          "value": "btn-supplyCheckOrder"
        },
        "style": {
          "type": "code",
          "value": "{display: props.status === \"REQUESTED\" || props.status === \"VERIFY\" ? \"\" : \"none\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.additionalProps"
        },
        "style": {
          "type": "code",
          "value": "{display: props.type === \"IPD\" && props.unCheck ? \"\" : \"none\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 69,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonReceive"
        },
        "style": {
          "type": "code",
          "value": "{display: props.status === \"TRANSPORTED\" ? \"\" : \"none\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Button",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกการตรวจ"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnUnVerify"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.status === \"PRINTED\"  || props.status === \"VERIFIED\"? \"\" : \"none\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonRePrint"
        },
        "style": {
          "type": "code",
          "value": "{display:props.status ===\"PRINTED\" ? \"\":\"none\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.errorMessage"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 26,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSupplyOrderWorkflowUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
