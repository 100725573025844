import WasmController from 'react-lib/frameworks/WasmController';
import EncounterActionOnly from "issara-sdk/apis/EncounterActionOnly_core";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM"
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import TriageFromEncounter from "issara-sdk/apis/TriageFromEncounter_core";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import CancelCheckInView from "issara-sdk/apis/CancelCheckInView_apps_DPO"

export type State =
  {
    buttonLoadCheck?: any;
    loadingStatus?: any;
    selectedEncounter?: any,
    selectedEmr?: any | null,
    CreateUpdateEncounterSequence?: any;
    AssessmentSequence?: {
      sequenceIndex: string | null,
      patientAssessmentId?: number | null,
      patientAssessment?: any,
      fallRiskAssessmentId?: number | null,
      fallRiskAssessment?: any,
      painAssessmentId?: number | null,
      painAssessment?: any,
      nutritionAssessmentId?: number | null,
      nutritionAssessment?: any,
      reassessmentAssessmentId?: number | null,
      reassessmentAssessment?: any,
      cardPregnancyAssessment?: {
        pregnancy_status?: any,
        pregnancy_period?: any,
        pregnancyPeriodID?: any,
      }
    } | null,
    successMessage?: any;
    errorMessage?: any;
  }

export const StateInitial: State =
{
  AssessmentSequence: null,
  successMessage: null,
  errorMessage: null,
}

export type Event =
  { message: "RunSequence", params: {} }
  | { message: "GetMasterData", params: any }

export type Data =
  {
    division?: number,
    device?: number,
    masterData?: Record<string, any>
  }

export const DataInitial =
{
}

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

export const Setup: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.AssessmentSequence) return
  if (!Number.isInteger(state.selectedEncounter?.patient_id)) return;
  // Master data
  controller.handleEvent({
    message: "GetMasterData", params: {
      masters: [
        ["belief", {}],
        ["characteristic", {}],
        ["functional", {}],
        ["location", {}],
        ["pain", {}],
        ["painFreq", {}],
        ["patientEducation", {}],
      ]
    }
  });

  if (["OPD_QUEUE_EXAM", "OPD_CANCEL_QUEUE_EXAM", "OPD_CANCEL_CHECK_IN"].includes(params.action)) {

    const [resPregnancy, errPregnancy, netPregnancy] =
      await FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedEncounter?.id,
          form_code: "CardPregnancyAssessment",
          form_version: "1.0",
        },
        extra: { division: controller.data.division },
      });

    controller.setState(
      {
        AssessmentSequence: {
          ...state.AssessmentSequence,
          cardPregnancyAssessment: {
            pregnancy_status: resPregnancy?.data?.pregnancy_status || 1,
            pregnancy_period: resPregnancy?.data?.pregnancy_period,
            pregnancyPeriodID: resPregnancy?.id,
          },
          sequenceIndex: "Edit",
        },
      },
      () => {
        controller.handleEvent({ message: "RunSequence", params });
      }
    );
    
  } else {
    await RefreshAll(controller, params)
  }
}

export const Edit: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.AssessmentSequence) return
  if (params.restart) {
    return controller.setState(
      { AssessmentSequence: { sequenceIndex: "START" } },
      () => controller.handleEvent(
        { message: "RunSequence", params: { sequence: "Assessment" } }));
  }
  if (!Number.isInteger(state.selectedEncounter?.id)) return;
  if (params?.action === "SAVE") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    let patientAssessment = SaveOrCreate(controller, {
      id: state.AssessmentSequence?.patientAssessmentId,
      assessment: state.AssessmentSequence?.patientAssessment,
      params
    });
    let fallRiskAssessment = SaveOrCreate(controller, {
      id: state.AssessmentSequence?.fallRiskAssessmentId,
      assessment: state.AssessmentSequence?.fallRiskAssessment,
      params
    });
    let painAssessment = SaveOrCreate(controller, {
      id: state.AssessmentSequence?.painAssessmentId,
      assessment: state.AssessmentSequence?.painAssessment,
      params
    });
    let nutritionAssessment = SaveOrCreate(controller, {
      id: state.AssessmentSequence?.nutritionAssessmentId,
      assessment: state.AssessmentSequence?.nutritionAssessment,
      params
    });
    let reassessmentAssessment: any = new Promise((resolve) => resolve([{}, null, null]));

    if (params.isSaveReassessment) {
      reassessmentAssessment = SaveOrCreate(controller, {
        id: state.AssessmentSequence?.reassessmentAssessmentId,
        assessment: state.AssessmentSequence?.reassessmentAssessment,
        params
      });
    }


    [patientAssessment, fallRiskAssessment, painAssessment, nutritionAssessment, reassessmentAssessment] =
      await Promise.all([
        patientAssessment,
        fallRiskAssessment,
        painAssessment,
        nutritionAssessment,
        reassessmentAssessment
      ])

    // Edit CardPregnancyAssessment
    const pregnancyAssessment = await FormDataDetail.update({
      pk: state.AssessmentSequence?.cardPregnancyAssessment?.pregnancyPeriodID,
      data: {
        form_code: "CardPregnancyAssessment",
        form_name: "CardPregnancyAssessment",
        form_version: "1.0",
        encounter: state.selectedEncounter?.id,
        action: "CONFIRM",
        data: {
          pregnancy_status: state.AssessmentSequence?.cardPregnancyAssessment?.pregnancy_status,
          pregnancy_period: state.AssessmentSequence?.cardPregnancyAssessment?.pregnancy_period,
        },
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    controller.setState({
      CreateUpdateEncounterSequence: {
        ...state.CreateUpdateEncounterSequence,
        pregnancy_status: pregnancyAssessment?.[0]?.data?.pregnancy_status,
        pregnancy_period: pregnancyAssessment?.[0]?.data?.pregnancy_period,
      }
    });

    if (patientAssessment[0] !== null &&
      fallRiskAssessment[0] !== null &&
      painAssessment[0] !== null &&
      nutritionAssessment[0] !== null &&
      reassessmentAssessment[0] !== null) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
      });

      const TriageSequence = (state as any).TriageSequence;

      if (TriageSequence?.triage?.id) {
        UpdateTriage(controller, { assessment: patientAssessment[0] })
      }
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    }
    await RefreshAll(controller, params);
  } else if (params?.action === "OPD_SCREEN") {
    const status = await EncounterActionOnly.patch({
      pk: state.selectedEncounter.id,
      data: {
        action: "OPD_SCREEN"
      },
      apiToken: controller.apiToken
    });
    console.log(status[1] ? status[1] : status[0]);
  } else if (["OPD_QUEUE_EXAM", "OPD_CANCEL_QUEUE_EXAM"].includes(params.action)) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: true },
    })

    const status = await EncounterActionOnly.patch({
      pk: state.selectedEncounter.id,
      data: {
        action: params.action
      },
      apiToken: controller.apiToken
    });

    params.onSuccess?.()

    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: false },
    }, () => controller.handleEvent({ message: "HandleRefreshEncounter" as any, params: {} }));

  } else if (params.action === "OPD_CANCEL_CHECK_IN") {
    // CancelCheckInView
    const status = await CancelCheckInView.put({
      pk: state.selectedEmr.id,
      apiToken: controller.apiToken
    });

    if (status[1]) {
      params.onFailed?.(status[1].message)
    } else {
      params.onSuccess?.(status[0])
      controller.handleEvent({ message: "HandleRefreshEncounter" as any, params: {} })
    }
  } else if (params?.action === "CLEAR") {
    const state = controller.getState()
    controller.setState({
      AssessmentSequence: {
        ...state.AssessmentSequence,
        sequenceIndex: "Edit",
        patientAssessment: []
      }
    });
  } else if (params.action === "clearMessage") {
    controller.setState({
      successMessage: { ...state.successMessage, [params?.sequence]: null },
      errorMessage: { ...state.errorMessage, [params?.sequence]: null },
    });
  } else if (params.action === "UpdatePain") {
    console.log("item", params?.item)
    const formData = await FormDataDetail.update({
      pk: state.AssessmentSequence?.painAssessmentId,
      data: {
        ...state.AssessmentSequence?.painAssessment,
        action: "SAVE",
        status: "EDITED",
        id: state.AssessmentSequence?.painAssessmentId,
      },
      apiToken: controller.apiToken
    });
    console.log("formData", formData)
    // controller.setState({
    //   successMessage: { ...state.successMessage, [params?.sequence]: null },
    //   errorMessage: { ...state.errorMessage, [params?.sequence]: null },
    // });
  }
  else {
    // Get form data
  }
}

// Functions handling each form type=================================================
const RefreshAll: Handler = async (controller, params) => {
  const state = controller.getState();
  // PatientAssessment
  const [
    patientAssessment,
    fallRiskAssessment,
    painAssessment,
    nutritionAssessment,
    reassessmentAssessment,
  ] = await Promise.all([
    RefreshPatientAssessment(controller, params),
    RefreshFallRiskAssessment(controller, params),
    RefreshPainAssessment(controller, params),
    RefreshNutritionAssessment(controller, params),
    RefreshOPDReassessment(controller, params)
  ]);

  let selectedEncounter = state.selectedEncounter;

  if (!state.AssessmentSequence?.patientAssessmentId && params?.action === "SAVE") {
    const encounter = await EncounterDetail.retrieve({
      pk: state.selectedEncounter?.id,
      apiToken: controller.apiToken,
    });

    selectedEncounter = encounter[0];
  }

  const pregnancyAssessment = await FormDataLatest.retrieve({
    apiToken: controller.apiToken,
    params: {
      encounter: state.selectedEncounter?.id,
      form_code: "CardPregnancyAssessment",
      form_version: "1.0",
    },
    extra: { division: controller.data.division },
  });

  controller.setState({
    selectedEncounter,
    AssessmentSequence: {
      ...state.AssessmentSequence,
      sequenceIndex: "Edit",
      patientAssessmentId: patientAssessment[0],
      patientAssessment: patientAssessment[1],
      fallRiskAssessmentId: fallRiskAssessment[0],
      fallRiskAssessment: fallRiskAssessment[1],
      painAssessmentId: painAssessment[0],
      painAssessment: painAssessment[1],
      nutritionAssessmentId: nutritionAssessment[0],
      nutritionAssessment: nutritionAssessment[1],
      reassessmentAssessmentId: reassessmentAssessment[0],
      reassessmentAssessment: reassessmentAssessment[1],
      cardPregnancyAssessment: {
        pregnancy_status: pregnancyAssessment?.[0]?.data?.pregnancy_status,
        pregnancy_period: pregnancyAssessment?.[0]?.data?.pregnancy_period,
        pregnancyPeriodID: pregnancyAssessment?.[0]?.id,
      }
    }
  });
}

const RefreshPatientAssessment: Handler = async (controller, params) => {
  const state = controller.getState();
  const formLatest = await FormDataLatest.retrieve({
    params: {
      encounter: state.selectedEncounter?.id,
      form_code: 'CardPatientAssessment',
      form_version: '0.1'
    },
    apiToken: controller.apiToken
  });
  console.log(formLatest[1] ? formLatest[1] : formLatest[0]);

  let assessmentId: number | null = null;
  let assessment: any = null;

  if (formLatest[1]) {
    // PatientAssessment hasn't been created
    const triage = await TriageFromEncounter.retrieve({
      pk: state.selectedEncounter?.id,
      apiToken: controller.apiToken
    });

    const options: any[] = (controller.data.masterData?.arriveStatus || []).map(
      (item: any) => ({
        key: item.code,
        value: item.code,
        text: item.name,
      })
    );

    if (triage[0]) {
      assessmentId = null;
      assessment = {
        form_code: 'CardPatientAssessment',
        form_name: 'แบบประเมินแรกรับ',
        form_version: '0.1',
        encounter: state.selectedEncounter?.id,
        data: {
          arrive_status: options.find((item: any) => item.text === triage[0].arrive_status)?.value || "",
          chief_complaint: triage[0].chief_complaint,
          triage_level: triage[0].triage_level,
        }
      };
    }
  } else {
    assessmentId = formLatest[0]?.id;
    assessment = {
      status: formLatest[0].status,
      form_code: 'CardPatientAssessment',
      form_name: 'แบบประเมินแรกรับ',
      form_version: '0.1',
      encounter: formLatest[0].encounter,
      data: formLatest[0].data,
    };
  }
  return [assessmentId, assessment];
}

const RefreshOPDReassessment: Handler = async (controller, params) => {
  const state = controller.getState();
  const formLatest = await FormDataLatest.retrieve({
    params: {
      encounter: state.selectedEncounter?.id,
      form_code: 'CardReassessment',
      form_version: '0.1'
    },
    apiToken: controller.apiToken
  });
  console.log(formLatest[1] ? formLatest[1] : formLatest[0]);

  let assessmentId: number | null = null;
  let assessment: any = {
    form_code: 'CardReassessment',
    form_name: 'ประเมินผู้ป่วยหลังออกจากห้องตรวจ (OPD Reassessment)',
    form_version: '0.1',
    encounter: state.selectedEncounter?.id,
  };

  if (formLatest[1]) {
    // Assessment hasn't been created
    assessment.data = [];
  } else {
    assessmentId = formLatest[0]?.id;
    assessment.data = formLatest[0].data;
  }
  return [assessmentId, assessment];
}

const RefreshFallRiskAssessment: Handler = async (controller, params) => {
  const state = controller.getState();
  const formLatest = await FormDataLatest.retrieve({
    params: {
      encounter: state.selectedEncounter?.id,
      form_code: 'CardFallRiskAssessment',
      form_version: '0.1'
    },
    apiToken: controller.apiToken
  });
  console.log(formLatest[1] ? formLatest[1] : formLatest[0]);

  let assessmentId: number | null = null;
  let assessment: any = {
    form_code: 'CardFallRiskAssessment',
    form_name: 'Fall Risk Assessment',
    form_version: '0.1',
    encounter: state.selectedEncounter?.id,
  };

  if (formLatest[1]) {
    // Assessment hasn't been created
    assessment.data = [];
  } else {
    assessmentId = formLatest[0]?.id;
    assessment.data = formLatest[0].data;
  }
  return [assessmentId, assessment];
}

const RefreshPainAssessment: Handler = async (controller, params) => {
  const state = controller.getState();
  const formLatest = await FormDataLatest.retrieve({
    params: {
      encounter: state.selectedEncounter?.id,
      form_code: 'CardPainAssessment',
      form_version: '0.1'
    },
    apiToken: controller.apiToken
  });
  console.log(formLatest[1] ? formLatest[1] : formLatest[0]);

  let assessmentId: number | null = null;
  let assessment: any = {
    form_code: 'CardPainAssessment',
    form_name: 'Pain Assessment',
    form_version: '0.1',
    encounter: state.selectedEncounter?.id,
  };

  if (formLatest[1]) {
    // Assessment hasn't been created
    assessment.data = [];
  } else {
    assessmentId = formLatest[0]?.id;
    assessment.data = formLatest[0].data;
  }
  return [assessmentId, assessment];
}

const RefreshNutritionAssessment: Handler = async (controller, params) => {
  const state = controller.getState();
  const formLatest = await FormDataLatest.retrieve({
    params: {
      encounter: state.selectedEncounter?.id,
      form_code: 'CardNutritionAssessment',
      form_version: '0.1'
    },
    apiToken: controller.apiToken
  });
  console.log(formLatest[1] ? formLatest[1] : formLatest[0]);

  let assessmentId: number | null = null;
  let assessment: any = {
    form_code: 'CardNutritionAssessment',
    form_name: 'Nutrition Assessment',
    form_version: '0.1',
    encounter: state.selectedEncounter?.id,
  };

  if (formLatest[1]) {
    // Assessment hasn't been created
    assessment.data = [];
  } else {
    assessmentId = formLatest[0]?.id;
    assessment.data = formLatest[0].data;
  }
  return [assessmentId, assessment];
}

export const SaveOrCreate: Handler = async (controller, params) => {
  if (params?.id) {
    const formData = await FormDataDetail.update({
      pk: params?.id,
      data: {
        ...params?.assessment,
        action: params?.params?.status
      },
      apiToken: controller.apiToken
    });
    console.log(formData[1] ? formData[1] : formData[0]);
    return formData;
  } else {
    const formData = await FormDataList.create({
      data: {
        ...params?.assessment,
        action: params?.params?.status
      },
      apiToken: controller.apiToken
    });
    console.log(formData[1] ? formData[1] : formData[0]);
    return formData;
  }
}

const UpdateTriage: Handler = (controller, params) => {
  const TriageSequence = (controller.getState() as any).TriageSequence;

  const assessment = params.assessment;
  const status = (controller.data.masterData?.arriveStatus || []).find((item: any) =>
    item.code === assessment?.data?.arrive_status)?.id

  controller.setState({
    TriageSequence: {
      ...TriageSequence,
      triage: {
        ...TriageSequence.triage,
        chief_complaint: assessment?.data?.chief_complaint || "",
        triage_level: assessment?.data?.triage_level || "3",
        arrive_status: status || "",
      },
    }
  } as any, () => controller.handleEvent({
    message: "RunSequence",
    params: { sequence: "Triage", action: "save", updateOnly: true }
  }));
}