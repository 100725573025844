import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Dropdown, Header, Icon, Input, Segment } from "semantic-ui-react";

import moment from "moment";

// Framework
import { Table } from "react-lib/frameworks/Table";

// Common
import {
  DateTextBox,
  ModAuthen,
  PatientIdentification,
} from "react-lib/apps/common";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";

const styles = {
  rightMargin: {
    marginRight: "5px",
  },
};

const SEARCH_KEY: string = "ModLabReport";

const SEARCH_CENTRAL_LAB_TEST_LR = "CentralLabTest_LR";

const FrameChrome = ({ file }: any) => {
  if (file) {
    let frame =
      '<iframe src="' + file + '" width="100%" height="100%" ></iframe>';
    return (
      <div
        style={{ width: "100%", height: "100%" }}
        dangerouslySetInnerHTML={{ __html: frame }}
      />
    );
  } else {
    return <></>;
  }
};

const ModLabReport = (props: any) => {
  const [selectedResult, setSelectedResult] = useState<any>(null);
  const [notConfidential, setNotConfidential] = useState(false);
  const [labTestId, setLabTestId] = useState<any>(null);
  // Mod
  const [openModAuthen, setOpenModAuthen] = useState<boolean>(false);

  // Use Effect
  useEffect(() => {
    const fetchDate = async () => {
      const date = props.selectedLabOrderWorking?.date?.split(" ")?.[0] || "";

      await props.setProp("LabReportSequence.selectedStartDate", date);
      await props.setProp("LabReportSequence.selectedEndDate", date);

      fetchLabSummaryFileResult({
        encounter: props.selectedLabOrderWorking?.encounter,
        patient: props.patientIdInitial,
      });
    };

    fetchDate();
  }, []);

  useEffect(() => {
    if (selectedResult) {
      const selectItem: any[] = (
        props.LabReportSequence?.LabReportList || []
      ).find(
        (item: any) => item.order_item_id === selectedResult.order_item_id
      );

      if (!selectItem) {
        setSelectedResult(null);
        props.setProp("permissionLabResultConfidential", false);
        setNotConfidential(false);
      }
    }
  }, [props.LabReportSequence?.LabReportList]);

  useEffect(() => {
    if (
      selectedResult?.labTestIsConfidential &&
      !props.permissionLabResultConfidential
    ) {
      setOpenModAuthen(true);
    }
  }, [selectedResult]);

  // Use Callback
  const handleSelectedItem = useCallback(
    async (value: any, key: any, searchText?: string) => {
      if ((searchText?.length || 0) < 3 || (value !== null && key !== null)) {
        const selected = props.searchedItemListWithKey?.[
          SEARCH_CENTRAL_LAB_TEST_LR
        ]?.find((item: any) => item.id === key);

        if (!selected?.id) {
          await props.setProp(
            `searchedItemListWithKey.${SEARCH_CENTRAL_LAB_TEST_LR}`,
            []
          );
        }

        setLabTestId(selected?.id);

        props.setProp(
          "LabReportSequence.selectedLabTest",
          selected?.name || ""
        );
      }
    },
    [props.searchedItemListWithKey]
  );

  // Use Memo
  const reportList = useMemo(() => {
    const list: any[] = props.LabReportSequence?.LabReportList || [];

    return list.map((item: any, index: number) => ({
      ...item,
      no: index + 1,
      orderTime: moment(item.order_time, "YYYY-MM-DD HH:mm:ss").format(
        "DD/MM/YYYY HH:mm"
      ),
      specimenTime: item.specimen_datetime
        ? `${formatDate(moment(item.specimen_datetime))} [${moment(
            item.specimen_datetime
          ).format("HH:mm")}]`
        : "",
      labTestName: (
        <label>
          {item.lab_test_name}{" "}
          {item.lab_test_is_confidential && (
            <label style={{ color: "red" }}>*</label>
          )}
        </label>
      ),
      labTestIsConfidential: item.ref_order_items
        .map(
          (id: number) =>
            list.find((acc) => acc.order_item_id === id)
              ?.lab_test_is_confidential
        )
        ?.includes(true),
    }));
  }, [props.LabReportSequence?.LabReportList]);

  // Memo Effect
  useEffect(() => {
    if (props.selectedLabOrderItem) {
      const selectItem: any = reportList.find(
        (item: any) => item.order_item_id === props.selectedLabOrderItem
      );

      if (!!selectItem) {
        if (!selectItem.labTestIsConfidential) {
          props.setProp("permissionLabResultConfidential", true);
          setNotConfidential(true);
        }

        setSelectedResult(selectItem);
      }
    }
  }, [props.selectedLabOrderItem, reportList]);

  const fetchLabSummaryFileResult = (params: any) => {
    props.runSequence({
      sequence: "LabReport",
      action: "FETCH_LAB_SUMMARY_FILE_RESULT",
      btnKey: SEARCH_KEY,
      params: params,
    });
  };

  const handleDeny = () => {
    setOpenModAuthen(false);
  };

  const handleApprove = ({ username, password }: any) => {
    props.onEvent({
      message: "CheckPermissionLabConfidential",
      params: {
        username: username,
        password: password,
        cardKey: props.cardKey || SEARCH_KEY,
        onSuccess: handleDeny,
      },
    });
  };

  return (
    <div style={{ height: "78%" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${SEARCH_KEY}`, null);
          props.setProp(`successMessage.${SEARCH_KEY}`, null);
        }}
        error={props.errorMessage?.[SEARCH_KEY]}
        success={props.successMessage?.[SEARCH_KEY]}
      />

      <div style={{ display: "flex", marginTop: "5px" }}>
        <Header as="h3">รายงานผลการทดสอบทางห้องปฏิบัติการ</Header>
        <div style={{ flex: "1" }} />
        <Icon
          color="red"
          name="close"
          style={{ cursor: "pointer" }}
          onClick={() => props.onModLabReport()}
        ></Icon>
      </div>
      <hr></hr>

      <PatientIdentification patientInfo={props?.patientInfo || []} />

      <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
        <div style={styles.rightMargin}> Order Date </div>
        <div style={styles.rightMargin}>
          <DateTextBox
            maxDate={formatDate(new Date())}
            onChange={(date: string) => {
              props.setProp("LabReportSequence.selectedStartDate", date);
            }}
            value={props.LabReportSequence?.selectedStartDate}
          ></DateTextBox>
        </div>
        <div style={styles.rightMargin}> to </div>
        <div style={styles.rightMargin}>
          <DateTextBox
            maxDate={formatDate(new Date())}
            onChange={(date: string) => {
              props.setProp("LabReportSequence.selectedEndDate", date);
            }}
            value={props.LabReportSequence?.selectedEndDate}
          ></DateTextBox>
        </div>
        <div style={styles.rightMargin}> Lab Division </div>
        <div style={styles.rightMargin}>
          <Dropdown
            onChange={(e: any, v: any) => {
              props.setProp("LabReportSequence.selectedLabDivision", v.value);
            }}
            options={[
              { key: "all", value: "all", text: "All" },
              ...(props.masterOptions?.labDivision || []),
            ]}
            selection={true}
            search={true}
            value={props.LabReportSequence?.selectedLabDivision}
          ></Dropdown>
        </div>
        <div style={styles.rightMargin}> Lab Test </div>
        <div style={styles.rightMargin}>
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // data
            encounterId={props.encounterId}
            // config
            type="CentralLabTest"
            id="LR"
            style={{ width: "100%" }}
            useWithKey={true}
            icon="search"
            limit={20}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={labTestId || null}
            setSelectedItem={handleSelectedItem}
          />
        </div>
        <div style={styles.rightMargin}>
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              fetchLabSummaryFileResult({
                patient: props.patientInfo?.patient_id,
              });
            }}
            // data
            paramKey={SEARCH_KEY}
            buttonLoadCheck={props.buttonLoadCheck?.[SEARCH_KEY]}
            // config
            color="green"
            title="ค้นหา"
          />
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "40% 60%",
          columnGap: "10px",
          padding: "10px 0px",
          height: "100%",
        }}
      >
        <div>
          <Table
            data={reportList || []}
            headers="No., Order Datetime, Division, Lab Test, Specimen Datetime"
            keys="no, orderTime, lab_division_name, labTestName, specimenTime"
            widths="35, 90, 130, 200, "
            getTrProps={(
              _state: any,
              rowInfo: any,
              _column: any,
              _instance: any
            ) => {
              const original = rowInfo?.original || {};

              return {
                style: {
                  backgroundColor:
                    original.order_item_id &&
                    `${original.id}-${original.order_item_id}` ===
                      `${selectedResult?.id}-${selectedResult?.order_item_id}`
                      ? "#cccccc"
                      : "white",
                },
                onClick: () => {
                  if (notConfidential && original.labTestIsConfidential) {
                    props.setProp("permissionLabResultConfidential", false);
                    setNotConfidential(false);
                  }

                  setSelectedResult({ ...original });
                },
              };
            }}
            showPagination={false}
            style={{ height: "100%" }}
            minRows="10"
          ></Table>
        </div>
        <div>
          <Segment
            id="container"
            style={{ height: "100%", margin: "0px 10px 0px 0px" }}
            className="grey inverted"
          >
            {props.permissionLabResultConfidential && (
              <FrameChrome file={selectedResult?.summary_file || null} />
            )}
          </Segment>
        </div>
      </div>

      <ModAuthen
        titleName="อนุญาตให้เข้าถึงผลการทดสอบทางห้องปฏิบัติการ เฉพาะบุคคลที่มีสิทธิ์เท่านั้น"
        titleColor="blue"
        open={openModAuthen}
        style={{ marginTop: "30vh" }}
        onDeny={handleDeny}
        onApprove={handleApprove}
      />
    </div>
  );
};

export default React.memo(ModLabReport);
