import WasmController from "react-lib/frameworks/WasmController";
import RejectedOrderList from "issara-sdk/apis/RejectedOrderList_core";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import AdverseReactionList from "issara-sdk/apis/AdverseReactionList_apps_ADR";
import DrugOrderDetailForAction from "issara-sdk/apis/DrugOrderDetailForAction_apps_TPD";
import DrugOrderActionView from "issara-sdk/apis/DrugOrderActionView_apps_TPD";

export type State = {
  RejectOrderSequence?: {
    sequenceIndex?: string | null;
    selectedHN?: string | null;
    selectedFirstname?: string | null;
    selectedLastname?: string | null;
    orderType?: string | null;
    RejectOrderList?: any;
  } | null;
  errorMessage?: any;
  successMessage?: any;
  drugOrder?: any;
  patientADR?: any;
};

export const StateInitial: State = {
  RejectOrderSequence: {
    sequenceIndex: "",
    RejectOrderList: {},
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "SelectEncounter"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.RejectOrderSequence) return;

  const rejecteOrder = await RejectedOrderList.list({
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });

  controller.setState({
    RejectOrderSequence: {
      sequenceIndex: "SearchAndAction",
      RejectOrderList: rejecteOrder?.[0],
    },
  });
};

export const SearchAndAction: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params.action === "search") {
    const rejectOrder = await RejectedOrderList.list({
      params: {
        hn: state.RejectOrderSequence?.selectedHN || "",
        firstname: state.RejectOrderSequence?.selectedFirstname || "",
        lastname: state.RejectOrderSequence?.selectedLastname || "",
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    controller.setState({
      RejectOrderSequence: {
        ...state.RejectOrderSequence,
        RejectOrderList: rejectOrder?.[0],
      },
    });
  } else if (params.action === "select_encounter") {
    const encounter = await EncounterDetail.retrieve({
      pk: params.item?.encounter,
      apiToken: controller.apiToken,
    });
    if (encounter[1]) {
      return controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: encounter[1] },
      });
    }
    controller.handleEvent({
      message: "SelectEncounter",
      params: {
        encounter: encounter[0],
        goToMenu: params.goToMenu,
        goToTab: params.goToTab
      },
    });
  } else if (params.action === "select_drug") {
    const adr = await AdverseReactionList.list({
      params: {
        patient: params.item?.patient,
        exclude_cancel: true,
        severe_first: true,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    const drug = await DrugOrderDetailForAction.retrieve({
      pk: params.item?.order_id,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    const orderType =
      drug?.[0]?.type === "ONE_DOSE" ||
      drug?.[0]?.type === "STAT" ||
      drug?.[0]?.type === "ONE_DAY"
        ? `${drug?.[0]?.type}_${params.item?.encounter_type}`
        : drug?.[0]?.type;
    controller.setState(
      {
        patientADR: adr?.[0],
        RejectOrderSequence: {
          ...state.RejectOrderSequence,
          orderType: orderType,
        },
        selectedEncounter: { id: params.item.encounter }
      } as any,
      () =>{
        controller.handleEvent({
          message: "SetDrugOrder" as any,
          params: {
            item: params.item,
            orderType: orderType,
            card: params.card,
          },
        })
        controller.handleEvent({
          message: "HandleRefreshEncounter" as any,
          params: { isOpen: false },
        })
      }
    );
  } else if (params.action === "cancel") {
    const data = state?.drugOrder;
    delete data?.items;
    const [r, e, n] = await DrugOrderActionView.update({
      pk: state?.drugOrder?.id,
      data: {
        ...data,
        action: "CANCEL",
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });
    if (e) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: e },
      });
    } else {
      controller.setState(
        {
          errorMessage: { ...state.errorMessage, [params.card]: null },
          successMessage: { ...state.successMessage, [params.card]: r },
          RejectOrderSequence: {
            sequenceIndex: "START",
          },
        },
        () =>
          controller.handleEvent({
            message: "RunSequence" as any,
            params: {
              sequence: "RejectOrder" as any,
            },
          })
      );
    }
  }
};
