import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Dropdown
} from 'semantic-ui-react'

const CardVerbalOrderUX = (props: any) => {
    return(
      <div>
        <div
          style={{padding: "10px 0", fontSize: "1.3rem", fontWeight: "bold" ,display: "flex", alignItems: "center" }}>
          รับคำสั่งแพทย์
        </div>
        <div
          style={{padding: "10px 0", fontSize: "1.2rem", fontWeight: "bold" ,display: "flex", alignItems: "center" }}>
          
          <Button
            color={props.containerName === "history" ? "blue":  ""}
            onClick={()=> props.onChangeTab("history")}>
            ประวัติ รคส.
          </Button>
          <Button
            color={props.containerName === "admit" ? "blue":  ""}
            onClick={()=> props.onChangeTab("admit")}
            style={{marginLeft: "15px"}}>
            Admit Order
          </Button>
          <Button
            color={props.containerName === "drug" ? "blue":  ""}
            onClick={()=> props.onChangeTab("drug")}
            style={{marginLeft: "15px"}}>
            ยา
          </Button>
          <Button
            color={props.containerName === "lab" ? "blue":  ""}
            onClick={()=> props.onChangeTab("lab")}
            style={{marginLeft: "15px"}}>
            Central Lab
          </Button>
          <Button
            color={props.containerName === "treatment" ? "blue":  ""}
            onClick={()=> props.onChangeTab("treatment")}
            style={{marginLeft: "15px"}}>
            Treatment Order
          </Button>
          <Button
            disabled={true}
            style={{marginLeft: "15px"}}>
            ผ่าตัด
          </Button>
        </div>
        <div
          style={{padding: "10px", display: "flex", alignItems: "center", backgroundColor: "#F3F3F3" }}>
          {props.container}
        </div>
        <div
          style={{padding: "10px", fontSize: "1.2rem", display: props.hiddenPerformed? "none":"flex", alignItems: "center" }}>
          
          <div
            style={{padding: "10px", fontSize: "1.1rem" ,display: "flex", alignItems: "center", marginLeft: "auto" }}>
            
            <label>
              
            </label>
            <div>
              {props.doctorSearchBox}
            </div>
            <div
              style={{marginLeft: "15px",fontWeight: "bold"}}>
              รับคำสั่ง
            </div>
            <Dropdown
              onChange={props.methodOnChange}
              options={props.methodOptions}
              selection={true}
              style={{marginLeft: "15px"}}
              value={props.method}>
            </Dropdown>
            <Button
              color="green"
              fluid={false}
              loading={props.saveloading}
              onClick={props.saveVerbalOrder}
              style={{marginLeft: "15px", width: "100px"}}>
              {props.save}
            </Button>
          </div>
        </div>
      </div>
    )
}


export default CardVerbalOrderUX

export const screenPropsDefault = {}

/* Date Time : Mon Jul 17 2023 11:48:13 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "รับคำสั่งแพทย์"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 0\", fontSize: \"1.3rem\", fontWeight: \"bold\" ,display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 0\", fontSize: \"1.2rem\", fontWeight: \"bold\" ,display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติ รคส."
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"history\" ? \"blue\":  \"\""
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onChangeTab(\"history\")"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Admit Order"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"admit\" ? \"blue\":  \"\""
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onChangeTab(\"admit\")"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ยา"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"drug\" ? \"blue\":  \"\""
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onChangeTab(\"drug\")"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Central Lab"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"lab\" ? \"blue\":  \"\""
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onChangeTab(\"lab\")"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Treatment Order"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"treatment\" ? \"blue\":  \"\""
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onChangeTab(\"treatment\")"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ผ่าตัด"
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.container"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", display: \"flex\", alignItems: \"center\", backgroundColor: \"#F3F3F3\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontSize: \"1.2rem\", display: props.hiddenPerformed? \"none\":\"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "รับคำสั่ง"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\",fontWeight: \"bold\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Dropdown",
      "parent": 16,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.methodOnChange"
        },
        "options": {
          "type": "code",
          "value": "props.methodOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.method"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Button",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.save"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "fluid": {
          "type": "code",
          "value": "false"
        },
        "loading": {
          "type": "code",
          "value": "props.saveloading"
        },
        "onClick": {
          "type": "code",
          "value": "props.saveVerbalOrder"
        },
        "size": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", width: \"100px\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontSize: \"1.1rem\" ,display: \"flex\", alignItems: \"center\", marginLeft: \"auto\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearchBox"
        }
      },
      "seq": 13,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardVerbalOrderUX",
  "project": "New Project",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
