import React, { CSSProperties, useState } from "react";
import { Card } from "semantic-ui-react";

// UX
import CardUDSegmentResultUX from "./CardUDSegmentResultUX";
import SubUDSegmentResultDetailUX from "./SubUDSegmentResultDetailUX";
import ModUDSegmentGroup from "./ModUDSegmentGroup";

// Interface
import {
  RunSequence,
  SegmentGroupDetailType,
  SubSegmentType,
} from "./sequence/UnderlyingDisease";
import { ModConfirm, ModInfo } from "react-lib/apps/common";
import { CARD_UNDERLYING_DISEASE } from "./CardUnderlyingDisease";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Types
type CardUDSegmentResultProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  // data
  segment?: SegmentGroupDetailType;
  subSegmentList?: SubSegmentType[];
  patientSegmentList?: any[];
  // seq
  runSequence: RunSequence;
};

// Constant
const ICONS = {
  segment: "/static/images/segment-icon.png",
};

const styles = {
  no_chat: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    color: "rgb(158 157 157)",
  } as CSSProperties,
};

const CARD_UD_SEGMENT_RESULT = "CardUDSegmentResult";

const CardUDSegmentResult = (props: CardUDSegmentResultProps) => {
  const [openModConfirm, setOpenModConfirm] = useState<{
    open?: boolean;
    index?: number;
  }>({});
  const [openModInfo, setOpenModInfo] = useState<boolean>(false);
  const [openModConfirmRemove, setOpenModConfirmRemove] =
    useState<boolean>(false);

  // Handler
  const handleChangeValue = (index: number) => (e: any, data: any) => {
    props.runSequence({
      sequence: "UnderlyingDisease",
      action: "CHANGE_SUB_SEGMENT",
      index,
      method: "UPDATE",
      ...data,
    });
  };

  const handleAddSegmentResult = () => {
    props.runSequence({
      sequence: "UnderlyingDisease",
      action: "CHANGE_SUB_SEGMENT",
      method: "ADD",
    });
  };

  const handleRemove = (index?: number, confirm?: boolean) => (e: any) => {
    props.runSequence({
      sequence: "UnderlyingDisease",
      action: "CHANGE_SUB_SEGMENT",
      method: "DELETE",
      index,
      confirm,
      errorKey: CARD_UNDERLYING_DISEASE,
      callback: (type: string, data?: Record<string, any>) => {
        if (type === "HAVE_MEMBERS") {
          setOpenModInfo(true);
        } else if (type === "CONFIRM") {
          setOpenModConfirm({ open: true, index: data?.index });
        } else {
          setOpenModConfirm({});
          setOpenModInfo(false);
        }
      },
    });
  };

  const handleSave = () => {
    // props.runSequence({
    //   sequence: "UnderlyingDisease",
    //   action: "CHANGE_SUB_SEGMENT",
    //   method: "CHECK",
    //   card: CARD_UD_SEGMENT_RESULT,
    //   errorKey: CARD_UNDERLYING_DISEASE,
    // });
    props.runSequence({
      sequence: "UnderlyingDisease",
      action: "CREATE_SEGMENT_RESULT",
      card: CARD_UD_SEGMENT_RESULT,
      errorKey: CARD_UNDERLYING_DISEASE,
    });
  };

  const handleChangeGroupDetail = (data: { value: string; name: string }) => {
    props.setProp(
      `UnderlyingDiseaseSequence.segmentGroupDetail.${data.name}`,
      data.value
    );
  };

  // Mod
  const handleConfirmRemove = () => {
    props.runSequence({
      sequence: "UnderlyingDisease",
      action: "DELETE_SEGMENT_GROUP",
      card: CARD_UD_SEGMENT_RESULT,
      errorKey: CARD_UNDERLYING_DISEASE,
      onSuccess: () => setOpenModConfirmRemove(false),
    });
  };

  const handleCloseModInfo = () => {
    setOpenModInfo(false);
  };

  const handleCloseModConfirm = () => {
    setOpenModConfirm({});
  };

  const handleOpenModConfirmRemove = () => {
    setOpenModConfirmRemove(true);
  };

  const handleCloseModConfirmRemove = () => {
    setOpenModConfirmRemove(false);
  };

  return (
    <div style={{ height: "100%" }}>
      {props.segment ? (
        <>
          <CardUDSegmentResultUX
            // config
            // disabledDelete={!!props.patientSegmentList?.length}
            // callback
            onAddSegmentResult={handleAddSegmentResult}
            onDelete={handleOpenModConfirmRemove}
            // Element
            ButtonSave={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleSave}
                // data
                paramKey={`${CARD_UD_SEGMENT_RESULT}_SAVE`}
                buttonLoadCheck={
                  props.buttonLoadCheck?.[`${CARD_UD_SEGMENT_RESULT}_SAVE`]
                }
                // config
                color={"green"}
                name="SAVE"
                size="medium"
                title="SAVE"
              />
            }
            SegmentGroupDetail={
              <ModUDSegmentGroup
                detail={props.segment}
                // config
                contentStyle={{ padding: "", marginLeft: "-1rem" }}
                hideHeader={true}
                hideAction={true}
                // readOnly={true}
                // callback
                onChange={handleChangeGroupDetail}
              />
            }
            SubSegmentList={
              <div
                style={{
                  height: "calc(100vh - 340px)",
                  width: "100%",
                  overflow: "auto",
                  padding: "2px 3px 2px 2px",
                }}
              >
                {props.subSegmentList?.map(
                  (item, index) =>
                    item.active && (
                      <Card style={{ width: "100%" }}>
                        <SubUDSegmentResultDetailUX
                          // data
                          code={item.code}
                          name={item.name}
                          error={item.error}
                          // callback
                          onRemove={handleRemove(index)}
                          onChangeValue={handleChangeValue(index)}
                        />
                      </Card>
                    )
                )}
              </div>
            }
          />
        </>
      ) : (
        <div style={styles.no_chat}>
          <img
            src={ICONS.segment}
            alt={`segment.icon`}
            style={{ width: "10rem", marginRight: "-3rem" }}
          />
          <p style={{ fontSize: "1.25rem", marginTop: "-1rem" }}>
            เลือก Segment Group
          </p>
        </div>
      )}

      <ModInfo
        open={openModInfo}
        titleColor={"red"}
        titleName={"ข้อความแจ้งเตือน"}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        <div
          style={{ padding: "0.5rem 0", fontWeight: "bold", lineHeight: 1.5 }}
        >
          <div>ไม่สามารถลบกลุ่มย่อยได้</div>
          <div>มีสมาชิกในกลุ่มย่อย กรุณาลบหรือย้ายกลุ่มสมาชิก</div>
        </div>
      </ModInfo>

      <ModConfirm
        openModal={openModConfirmRemove}
        titleName="ลบกลุ่ม"
        titleColor="red"
        size="mini"
        denyButtonColor="red"
        denyButtonText="ยกเลิก"
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirmRemove}
            // data
            paramKey={`${CARD_UD_SEGMENT_RESULT}_REMOVE`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_UD_SEGMENT_RESULT}_REMOVE`]
            }
            // config
            color={"green"}
            name="REMOVE"
            size="medium"
            title="ตกลง"
            basic={true}
          />
        }
        content={
          <div
            style={{
              fontWeight: "bold",
              textAlign: "center",
              margin: "0rem 0 -1rem",
            }}
          >
            ต้องการลบกลุ่มที่เลือก
          </div>
        }
        // onApprove={handleConfirmRemove}
        onDeny={handleCloseModConfirmRemove}
        onCloseWithDimmerClick={handleCloseModConfirmRemove}
      />

      <ModConfirm
        openModal={openModConfirm.open}
        titleName="ลบกลุ่มย่อย"
        titleColor="red"
        size="mini"
        denyButtonColor="red"
        denyButtonText="ยกเลิก"
        approveButtonColor="green"
        approveButtonText="ตกลง"
        content={
          <div
            style={{
              fontWeight: "bold",
              textAlign: "center",
              margin: "0rem 0 -1rem",
            }}
          >
            ต้องการลบรายการกลุ่มที่เลือก
          </div>
        }
        onApprove={handleRemove(openModConfirm.index, true)}
        onDeny={handleCloseModConfirm}
        onCloseWithDimmerClick={handleCloseModConfirm}
      />
    </div>
  );
};

export default React.memo(CardUDSegmentResult);
