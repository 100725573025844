const FormLabExamSummary = (props: any) => {
  const setColumns = (ids: any[], data: any) => {
    let tableData: any[] = [];
    const borderColorGrey = ["#646464", "#646464", "#646464", "#646464"];

    ids.map((key: any, index: number) => {
      let tableLabData: any[] = [];
      data[key].items.map((item: any) => {
        if (item?.dates) {
          tableLabData.push([
            {
              borderColor: borderColorGrey,
              border: [true, false, false, true],
              text: item?.name || " ",
            },
            {
              borderColor: borderColorGrey,
              border: [false, false, false, true],
              text: item?.dates[0]?.value || " ",
            },
            {
              borderColor: borderColorGrey,
              border: [false, false, false, true],
              text: [
                {
                  text: `${item?.ref_value || ""}\n` || " \n",
                },
                { text: item?.unit || " " },
              ],
            },
            // {
            //   borderColor: borderColorGrey,
            //   border: [false, false, false, true],
            //   text: " ",
            // },
            {
              borderColor: borderColorGrey,
              border: [false, false, true, true],
              text: item?.recommendation_text || " ",
            },
          ]);
        } else {
          tableLabData.push([
            {
              border: [true, false, false, false],
              text: item?.name,
              style: {
                fontSize: 12,
                bold: true,
              },
            },
            { border: [false, false, false, false], text: "" },
            { border: [false, false, false, false], text: "" },
            // { border: [false, false, false, false], text: "" },
            { border: [false, false, true, false], text: "" },
          ]);
        }
      });
      tableData.push([
        {
          table: {
            headerRows: 1,
            widths: ["25%", "17.5%", "22.5%", "35%"],
            body: [
              [
                {
                  borderColor: borderColorGrey,
                  border: [true, true, false, true],
                  text: [{ text: "ชื่อแลป\n" }, { text: "Lab Name" }],
                  fillColor: "#646464",
                  style: {
                    fontSize: 14,
                    bold: true,
                    color: "white"
                  },
                },
                {
                  borderColor: borderColorGrey,
                  border: [false, true, false, true],
                  text: [{ text: "ผลแลป\n" }, { text: "Result" }],
                  fillColor: "#646464",

                  style: {
                    fontSize: 14,
                    bold: true,
                    color: "white"
                  },
                },
                {
                  borderColor: borderColorGrey,
                  border: [false, true, false, true],
                  text: [{ text: "ช่วงค่าปกติ\n" }, { text: "Ref. value" }],
                  fillColor: "#646464",

                  style: {
                    fontSize: 14,
                    bold: true,
                    color: "white"
                  },
                },
                // {
                //   borderColor: borderColorGreen,
                //   border: [false, true, false, true],
                //   text: [{ text: "การแปลผล\n" }, { text: "Interpretation" }],
                //   style: {
                //     fontSize: 14,
                //     bold: true,
                //   },
                // },
                {
                  borderColor: borderColorGrey,
                  border: [false, true, true, true],
                  text: [{ text: "คำแนะนำ\n" }, { text: "Recommendation" }],
                  fillColor: "#646464",

                  style: {
                    fontSize: 14,
                    bold: true,
                    color: "white"
                  },
                },
              ],
              ...tableLabData,
            ],
          },
        },
        {
          margin: [0, 0, 0, -5],
          table: {
            headerRows: 1,
            widths: ["100%"],
            body: [
              // box outer
              [
                {
                  borderColor: borderColorGrey,
                  border: [true, false, true, ids.length - 1 === index],
                  margin: [0, 0, 0, 0],
                  table: {
                    headerRows: 1,
                    widths: ["100%"],
                    body: [
                      // box inner
                      [
                        {
                          margin: [5, 5, 5, 5],
                          stack: [
                            {
                              margin: [0, 0, 0, 0],
                              columns: [
                                {
                                  width: 75,
                                  text: "คำแนะนำจากแพทย์",
                                  style: {
                                    fontSize: 12,
                                    bold: true,
                                  },
                                },
                                /* A table that is not used. */
                                {
                                  image: "queue",
                                  width: 10,
                                },
                              ],
                            },
                            {
                              text: props.doctorRecommends[data[key].id] || "-",
                            },
                          ],
                        },
                      ],
                      [{ border: [false, false, false, false], text: "" }],
                    ],
                  },
                  layout: {
                    hLineStyle: function (i: any, node: any) {
                      return { dash: { length: 2, space: 2 } };
                    },
                    vLineStyle: function (i: any, node: any) {
                      return { dash: { length: 2, space: 2 } };
                    },
                    hLineColor: function (i: any) {
                      return "white";
                    },
                    vLineColor: function (i: any) {
                      return "white";
                    },
                  },
                },
              ],
              [{ border: [false, false, false, false], text: "" }],
            ],
          },
        },
      ]);
    });
    return tableData;
  };

  let columns = setColumns(props.parentLabID, props.labResultList);

  return {
    defaultStyle: {
      font: "THSarabunNew",
      // alignment: 'justify'
      lineHeight: 1,
      fontSize: 13,
    },
    pageMargins: [20, 90, 20, 40],
    // A4: [595.28, 841.89]
    pageSize: "A4",
    styles: {},
    header: {
      margin: [20, 20, 20, 0],

      stack: [
        {
          layout: "noBorders",
          // fillColor: "#1b9d2c",
          fillOpacity: 0.4,
          table: {
            widths: ["10%", "90%"],
            heights: [55, 55],
            body: [
              [
                {
                  margin: [5, 2, 0, 0],
                  layout: "noBorders",
                  // fillColor: "#1b9d2c",
                  fillOpacity: 0.4,
                  stack: [
                    {
                      image: "logo",
                      // fillColor: "#1b9d2c",
                      fillOpacity: 0.4,
                      width: 150,
                    },
                  ],
                },
                {
                  margin: [0, 2, 0, 0],
                  text: [
                    { text: "Laboratory Examination Summary\n" },
                    {
                      text: "รายงานสรุปผลตรวจทางห้องปฏิบัติการ",
                    },
                  ],
                  alignment: "center",
                  style: {
                    fontSize: 20,
                    bold: true,
                  },
                },
              ],
            ],
          },
        },
      ],
    },
    content: [
      {
        stack: [...columns],
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        margin: [20, 0, 20, 0],
        stack: [
          {
            text: `Approved by: ${props.doctorDetail.full_name || ""} (ว.${
              props.doctorDetail?.certificate_no || ""
            })`,
            style: {
              bold: true,
              alignment: "right",
            },
          },
          {
            table: {
              widths: ["50%", "40%", "10%"],
              body: [
                [
                  {
                    borderColor: ["#000000", "#000000", "#000000", "#000000"],
                    border: [false, true, false, false],
                    text: `HN: ${props.patient?.hn} ${props.patient?.full_name_th} DOB: ${props.patient?.birthdate}`,
                    italics: true,
                    style: {
                      fontSize: 12,
                      color: "#5A5A5A",
                    },
                  },
                  {
                    border: [false, true, false, false],
                    text: `Printed date: ${props.date} [${props.time}]`,
                    italics: true,
                    style: {
                      fontSize: 12,
                      color: "#5A5A5A",
                    },
                  },
                  {
                    border: [false, true, false, false],
                    text: `${currentPage.toString()} / ${pageCount}`,
                    italics: true,
                    style: {
                      fontSize: 12,
                      color: "#5A5A5A",
                      alignment: "right",
                    },
                  },
                ],
              ],
            },
          },
        ],
      };
    },
    images: {
      logo: origin + "/static/images/logochula_dent.jpg",
      queue: origin + "/static/images/menus/queue.png",
    },
  };
};

export default FormLabExamSummary;
