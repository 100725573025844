import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { Button, FormField, FormGroup, Icon, Modal } from "semantic-ui-react";

import moment from "moment";
import {
  momentLocalizer,
  Calendar,
  ViewsProps,
  View,
} from "react-big-calendar";

// UX
import CardSelectDateTimeOperationUX from "./CardSelectDateTimeOperationUX";
import ModSelectOperatingDateTime from "react-lib/apps/HISV3/ORM/ModSelectOperatingDateTime";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import DropdownOptions from "react-lib/appcon/common/DropdownOptions";
import { ModInfo } from "react-lib/apps/common";

// Types
type BarColorKeys = keyof typeof BAR_COLORS;

moment.locale("en-es", { week: { dow: 1 } });
const localizer = momentLocalizer(moment);
const formats = {
  timeGutterFormat: (date: any, culture: any, localizer: any) =>
    localizer.format(date, "H:mm", culture),
  eventTimeRangeFormat: () => "",
};
const CARD_SELECT_DATE_TIME_OPERATION = "CardSelectDateTimeOperation";

const styles = {
  flexCenter: {
    display: "flex",
    padding: "5px 2px 0px",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    overflow: "auto",
  } as CSSProperties,
  paddingTextSlot: {
    padding: "5px 0px",
  } as CSSProperties,
  paddingTextLeftSlot: {
    padding: "5px 0 0",
    textAlign: "left",
    width: "100%",
  } as CSSProperties,
  paddingTextRightSlot: {
    padding: "5px 0px",
    textAlign: "right",
    width: "100%",
  } as CSSProperties,
  paddingHeaderSlot: {
    padding: "5px 0px",
    fontSize: "16px",
    fontWeight: "bold",
  } as CSSProperties,
  paddingTimeSlot: {
    padding: "5px 0px 10px 0px",
  } as CSSProperties,
  point: {
    width: "9px",
    height: "8px",
    borderRadius: "50%",
    marginRight: "0.5rem",
    marginTop: "5px",
  } as CSSProperties,
  bar: {
    height: "100%",
    borderRadius: "5px",
    cursor: "pointer",
    width: "30px",
  } as CSSProperties,
  pointLarge: {
    borderRadius: "50%",
    marginRight: "0.5rem",
    width: "12px",
    height: "12px",
    marginTop: 0,
  },
};

const BAR_COLORS = {
  doctor: {
    bg: "#FFE1A9",
    bd: "#ffa809",
  },
  anesthetic: {
    bg: "#E3BFF3",
    bd: "#b667d8",
  },
  major: {
    bg: "#E95959",
    bd: "#e95959",
  },
  minor: {
    bg: "#FFC5C5",
    bd: "#f08f8f",
  },
} as const;

const CURRENT_DATE = moment().format("YYYY-MM-DD");
const START_WEEK_DATE = moment().clone().startOf("week").toDate();
const END_WEEK_DATE = moment().clone().endOf("week").toDate();

const CardSelectDateTimeOperation = (props: any) => {
  // ORM
  // Mod
  const [openModOperatingDateTime, setOpenModOperatingDateTime] =
    useState<boolean>(false);
  const [openModDetail, setOpenModDetail] = useState<boolean>(false);
  const [modError, setModError] = useState<string>("");
  // Filter
  const [doctor, setDoctor] = useState<any>(null);
  const [anesthesiologist, setAnesthesiologist] = useState<any>(null);
  const [room, setRoom] = useState<any[]>([]);
  const [filterDoctor, setFilterDocter] = useState<boolean>(true);
  const [filterAnesthetic, setFilterAnesthetoc] = useState<boolean>(true);
  // loading
  const [loadingTimeSlot, setLoadingTimeSlot] = useState<boolean>(false);
  const [operatingId, setOperatingId] = useState<any>(null);
  const [selectedOperatingDetail, setSelectedOperatingDetail] =
    useState<any>(null);
  // ---
  const [calendarView, setCalendarView] = useState<"day" | "week" | "month">(
    "week"
  );
  const [rangeDate, setRangeDate] = useState<{
    start: Date;
    end: Date;
  }>({
    start: START_WEEK_DATE,
    end: END_WEEK_DATE,
  });

  useEffect(() => {
    props.runSequence({ sequence: "OperatingDateTime", restart: true });
    return () => {
      props.runSequence({ sequence: "OperatingDateTime", clear: true });
    };
  }, []);

  useEffect(() => {
    if (
      props.OperatingDateTimeSequence?.sequenceIndex === "Action" &&
      props.selectedDoctor
    ) {
      const params: any = {
        doctor: props.selectedDoctor,
        anesthesiologist: null,
        room: [],
        start_date: moment(rangeDate.start).format("YYYY-MM-DD"),
        end_date: moment(rangeDate.end).format("YYYY-MM-DD"),
      };

      setDoctor(props.selectedDoctor || null);

      if (props.selectedAnesthesiologist) {
        params["anesthesiologist"] = props.selectedAnesthesiologist;
        setAnesthesiologist(props.selectedAnesthesiologist);
      } else {
        setAnesthesiologist(null);
      }

      if (props.selectedOperatingRoom) {
        params["room"] = [props.selectedOperatingRoom];
        setRoom([props.selectedOperatingRoom]);
      } else {
        setRoom([]);
      }

      let operatingId =
        props.preOrderList?.find((item: any) => item.type === "operatingorder")
          ?.id || null;

      props.runSequence({
        sequence: "OperatingDateTime",
        action: "getDSBBlock",
        card: CARD_SELECT_DATE_TIME_OPERATION,
        ...params,
        operatingId,
      });

      setOperatingId(operatingId);
    } else {
      setDoctor(null);
    }
  }, [
    props.selectedDoctor,
    props.selectedAnesthesiologist,
    props.selectedOperatingRoom,
    props.preOrderList,
    props.OperatingDateTimeSequence?.sequenceIndex,
  ]);

  const roomDict = useMemo(() => {
    if (!Array.isArray(room)) {
      return {};
    }

    const options = props.operatingLocationRoom?.flatMap(
      (option: any) => option.subOptions
    );
    return room.reduce(
      (result, id) => ({
        ...result,
        [id]: options?.find((option: any) => option.id === id)?.text || "",
      }),
      {}
    );
  }, [room, props.operatingLocationRoom]);

  const selectedDoctorSchedule = useMemo(() => {
    return props.OperatingDateTimeSequence?.selectedDoctorSchedule;
  }, [props.OperatingDateTimeSequence?.selectedDoctorSchedule]);

  const getDetailBackgroundColor = useCallback(
    (item: any) => {
      const showDetail =
        item.operating_detail === props.selectedOperatingDetail ||
        item.operating_detail === props.selectedOrOrder?.operating_detail;

      return item.order_status === 1 &&
        showDetail &&
        props.selectOperatingDSBChange
        ? "#4CDCFC4D" // light blue
        : item.order_status === 1 && showDetail
        ? "#4CDCFC" // blue
        : item.doctor === doctor && !item.is_doctor_schedule && showDetail
        ? "#64E282" // green
        : "";
    },
    [
      props.selectedOrOrder,
      props.selectedOperatingDetail,
      doctor,
      props.selectOperatingDSBChange,
    ]
  );

  const getBarList = (event: any): { key: BarColorKeys; hide?: boolean }[] => {
    const isDoctor = doctor && event.doctor === doctor;
    const isAnesthetic =
      anesthesiologist && event.anesthesiologist === anesthesiologist;
    const isRoom = !!room.length && !!roomDict[event.room];

    return [
      // เมื่อแพทย์ตรงกัน และ filter doctor
      isDoctor && { key: "doctor", hide: !filterDoctor },
      // เมื่อวิสัญญีแพทย์ตรงกัน
      isAnesthetic && { key: "anesthetic", hide: !filterAnesthetic },
      // เมื่อห้องผ่าตัดตรงกัน และ filter doctor anesthetic
      isRoom && {
        key: event.procedure_type.includes("Major")
          ? "major"
          : event.procedure_type.includes("Minor")
          ? "minor"
          : "",
      },
    ].filter((o) => o?.key);
  };

  const handleChangeOption = (name: string) => (e: any, data: any) => {
    let params = {
      doctor: doctor,
      anesthesiologist: anesthesiologist,
      room: room,
      start_date: moment(rangeDate.start).format("YYYY-MM-DD"),
      end_date: moment(rangeDate.end).format("YYYY-MM-DD"),
      [name]: data.value,
    };

    if (name === "doctor") {
      setDoctor(data.value);
    } else if (name === "anesthesiologist") {
      setAnesthesiologist(data.value);
    }

    setLoadingTimeSlot(true);

    props.runSequence({
      sequence: "OperatingDateTime",
      action: "getDSBBlock",
      card: CARD_SELECT_DATE_TIME_OPERATION,
      ...params,
      operatingId,
      onSuccess: () => setLoadingTimeSlot(false),
    });
  };

  const handleOnCancelSelectOperatingTime = () => {
    let params = {
      doctor: props.selectedDoctor,
      anesthesiologist: null,
      room: null,
      start_date: moment(rangeDate.start).format("YYYY-MM-DD"),
      end_date: moment(rangeDate.end).format("YYYY-MM-DD"),
    };

    setDoctor(props.selectedDoctor || null);

    if (props.selectedAnesthesiologist) {
      params["anesthesiologist"] = props.selectedAnesthesiologist;
      setAnesthesiologist(props.selectedAnesthesiologist);
    } else {
      setAnesthesiologist(null);
    }

    if (props.selectedOperatingRoom) {
      params["room"] = props.selectedOperatingRoom;
      setRoom([props.selectedOperatingRoom]);
    } else {
      setRoom([]);
    }

    setLoadingTimeSlot(true);

    props.runSequence({
      sequence: "OperatingDateTime",
      action: "unsetAndClose",
      card: props.card,
      callback: () => {
        setOpenModOperatingDateTime(false);
        props.runSequence({
          sequence: "OperatingDateTime",
          action: "getDSBBlock",
          card: CARD_SELECT_DATE_TIME_OPERATION,
          ...params,
          operatingId,
          onSuccess: () => {
            setLoadingTimeSlot(false);
          },
        });
      },
    });
  };

  const handleOnChangeWeekRange = (start: Date, end: Date, view: string) => {
    props.runSequence({
      sequence: "OperatingDateTime",
      action: "getDSBBlock",
      card: CARD_SELECT_DATE_TIME_OPERATION,
      doctor: doctor,
      anesthesiologist: anesthesiologist,
      room: room,
      start_date: moment(start).format("YYYY-MM-DD"),
      end_date: moment(end).format("YYYY-MM-DD"),
      operatingId,
      view,
      onSuccess: () => setLoadingTimeSlot(false),
    });
  };

  const operatingBlock = useMemo((): any[] => {
    const renderRoomSchedule = (item: any, index: number) => {
      if (item.operating_detail === props.selectedOperatingDetail) {
        let start_time = moment(item.start_datetime).format("HH:mm");
        let end_time = moment(item.end_datetime).format("HH:mm");
        return (
          <div
            key={`DSB${index + 1}`}
            style={{
              ...styles.flexCenter,
              lineHeight: 1.25,
              paddingTop: "2px",
            }}
          >
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  ...styles.point,
                  backgroundColor: (BAR_COLORS as any)[
                    item.procedure_type.includes("Major")
                      ? "major"
                      : item.procedure_type.includes("Minor")
                      ? "minor"
                      : ""
                  ]?.bg,
                }}
              ></div>
              <strong>{item.anesthesia_method}</strong>
            </div>
            {/* <div
              style={styles.paddingTimeSlot}
            >{`${start_time} น. - ${end_time} น.`}</div>
            <div style={styles.paddingTextLeftSlot}>{item.patient_name}</div>
            <div style={styles.paddingTextLeftSlot}>{item.doctor_name}</div> */}
            {item?.treatments?.map((treatment: any, ti: number) => {
              return (
                <div
                  key={`DSB${index + 1}T${ti}`}
                  style={styles.paddingTextLeftSlot}
                >
                  {treatment}
                </div>
              );
            }) || <div />}
            <strong style={{ ...styles.paddingTextRightSlot }}>
              {item.order_status_label}
            </strong>
          </div>
        );
      }
    };

    const renderScheduleDetail = (item: any, index: number) => {
      return (
        <div
          key={`DSB${index + 1}`}
          style={{
            ...styles.flexCenter,
            padding: "5px 10px 0px 5px",
            color: "rgba(0,0,0,0.87)",
          }}
        >
          <div style={{ display: "flex", width: "100%" }}>
            <div
              style={{
                ...styles.pointLarge,
                backgroundColor: (BAR_COLORS as any)[
                  item.procedure_type.includes("Major")
                    ? "major"
                    : item.procedure_type.includes("Minor")
                    ? "minor"
                    : "major"
                ]?.bg,
                marginTop: "1px",
              }}
            ></div>
            <strong>{item.anesthesia_method}</strong>
            <div style={{ flex: 1 }}></div>
            <strong>{item.room_name}</strong>
          </div>
          <div
            style={{
              paddingLeft: "2rem",
              textAlign: "left",
              width: "100%",
              lineHeight: 1.5,
            }}
          >
            <label>{item.patient_name}</label>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  ...styles.pointLarge,
                  backgroundColor: BAR_COLORS.doctor.bg,
                  border: "1px solid black",
                }}
              ></div>
              <div>{item.doctor_name}</div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  ...styles.pointLarge,
                  backgroundColor: BAR_COLORS.anesthetic.bg,
                  border: "1px solid black",
                }}
              ></div>
              <div>{item.anesthesiologist_name}</div>
            </div>
          </div>
          {(item?.treatments || []).map((treatment: any, ti: number) => {
            return (
              <div
                key={`DSB${index + 1}T${ti}`}
                style={styles.paddingTextLeftSlot}
              >
                {treatment}
              </div>
            );
          }) || <div />}
          <strong style={styles.paddingTextRightSlot}>
            {item.order_status_label}
          </strong>
        </div>
      );
    };

    const renderDoctorSchedule = (item: any, index: number) => {
      return (
        <div
          key={`DSB${index + 1}`}
          style={{
            ...styles.flexCenter,
            padding: "0.5rem 10px 0px 5px",
            color: "rgba(0,0,0,0.87)",
            fontWeight: "bold",
          }}
        >
          <div
            style={{
              paddingLeft: "1rem",
              textAlign: "left",
              width: "100%",
              lineHeight: 2,
            }}
          >
            <label>{item.doctor_name}</label>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div>{item.division_name}</div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>(ผู้ป่วย {item.patient_count} คน)</div>
            </div>
          </div>
        </div>
      );
    };

    const renderScheduleDetailList = (bars: any, item: any, index: number) => {
      return (
        <div style={{ display: "flex", height: "inherit" }}>
          {bars.map((bar: any) => (
            <div
              style={{
                borderRadius: "5px",
                backgroundColor: (BAR_COLORS as any)[bar.key]?.bg,
                border: `1px solid ${(BAR_COLORS as any)[bar.key]?.bd}`,
                width: "100%",
                padding: "2px 5px",
                ...(bar.hide
                  ? { opacity: 0, pointerEvents: "none" }
                  : { pointerEvents: "auto" }),
              }}
            >
              {renderScheduleDetail(item, index)}
            </div>
          ))}
        </div>
      );
    };

    const renderBarList = (barList: any[]) => {
      return (
        <>
          {barList.map((bar: any) => (
            <div
              style={{
                background: (BAR_COLORS as any)[bar.key].bg,
                border: `2px solid ${(BAR_COLORS as any)[bar.key].bd}`,
                ...(bar.hide
                  ? { opacity: 0, pointerEvents: "none" }
                  : { pointerEvents: "auto" }),
                ...styles.bar,
              }}
            ></div>
          ))}
        </>
      );
    };

    /*
    render will be order like this:
    1. doctor item match selected doctor and is_doctor_schedule = true  => renderDoctorSchedule
    2. doctor item match selected doctor => renderRoomSchedule
    3. anesthesiologist item match selected anesthesiologist => renderAnesthesia
    4. other doctor but use the same operating room => renderRoomSchedule
    */

    let operatingBlock = props.operatingBlock || [];

    return operatingBlock.map((item: any, index: number) => {
      const bg = getDetailBackgroundColor(item);

      // เมื่อเป็น mode day
      const barList = getBarList(item);
      const subSlot = barList.length > 1 && !bg;

      // เมื่อเป็น mode week
      const showBar = !!barList.length && !bg && calendarView === "week";

      return {
        ...item,
        title:
          // เมื่อเป็น mode week
          !!bg && calendarView === "week" ? (
            renderRoomSchedule(item, index)
          ) : // เมื่อเป็น mode day
          calendarView === "day" ? (
            // เมื่อเป็น ตารางแพทย์
            item.is_doctor_schedule ? (
              renderDoctorSchedule(item, index)
            ) : // เมื่อต้องการแสดง slot แบบแยกแพทย์,วิสัญญีแพทย์,ห้องผ่าตัด
            subSlot ? (
              renderScheduleDetailList(barList, item, index)
            ) : (
              renderScheduleDetail(item, index)
            )
          ) : // เมื่อเป็น mode week
          showBar ? (
            <div style={{ display: "flex", height: "100%" }}>
              {renderBarList(barList)}
            </div>
          ) : (
            "-"
          ),
        show_detail: !!bg,
        children: subSlot ? barList : null,
      };
    });
  }, [
    props.operatingBlock,
    props.selectedOrOrder,
    props.selectedOperatingDetail,
    calendarView,
    filterDoctor,
    filterAnesthetic,
  ]);

  const groupOperatingBlock = useMemo(() => {
    if (calendarView !== "month") {
      return operatingBlock;
    }

    const groupDate = operatingBlock.reduce((result, item) => {
      const date = moment(item.start_datetime).format("YYYY-MM-DD");
      if (!result[date]) {
        const items = operatingBlock.filter(
          (item) => date === moment(item.start_datetime).format("YYYY-MM-DD")
        );

        const barList = items.flatMap((item) => getBarList(item));

        result[date] = ["doctor", "anesthetic", "procedure"].map(
          (type, index) => {
            const renderTitle = (count: number, style?: any) => (
              <div style={{ display: "flex" }}>
                <Icon
                  name="user"
                  color="grey"
                  style={{
                    margin: "0 3rem 0 0.5rem",
                    fontSize: "0.65rem",
                    ...(style || {}),
                  }}
                />
                <div
                  style={{
                    fontSize: "0.85rem",
                    color: "#575656",
                  }}
                >
                  <label>{count}</label>
                </div>
              </div>
            );

            const renderTitleList = (barList: any) => {
              return (
                <>
                  {barList.map((bar: any, index: number) => (
                    <div
                      style={{
                        width: "50%",
                        backgroundColor: (BAR_COLORS as any)[bar.type].bg,
                        borderRadius: "5px",
                        padding: "2px 0",
                        margin: index === 0 ? "0 0.25rem 0 0" : "0 0 0 0.25rem",
                        ...(!bar.count
                          ? { opacity: 0, pointerEvents: "none" }
                          : { pointerEvents: "auto" }),
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectEvent(bar);
                      }}
                    >
                      {renderTitle(bar.count, {
                        margin: "0px 1.25rem 0px 0.9rem",
                      })}
                    </div>
                  ))}
                </>
              );
            };

            const count = barList.filter(
              (item) => item.key === type && !item.hide
            ).length;

            const start = new Date(`${date} ${index + 1}:00`);
            const end = new Date(`${date} ${index + 1}:00`);

            return {
              title:
                type !== "procedure" ? (
                  renderTitle(count)
                ) : (
                  <div style={{ display: "flex" }}>
                    {renderTitleList(
                      ["major", "minor"].map((type) => {
                        const count = barList.filter(
                          (item) => item.key === type && !item.hide
                        ).length;

                        return {
                          type,
                          count,
                          start,
                          end,
                        };
                      })
                    )}
                  </div>
                ),
              start,
              end,
              type,
              count: type === "procedure" ? 1 : count,
            };
          }
        );
      }

      return result;
    }, {});

    return Object.values(groupDate).flat();
  }, [operatingBlock, calendarView]);

  const disabledFilter = useMemo(() => {
    return (
      !!props.selectedOrOrder?.operating_room && !props.selectOperatingDSBChange
    );
  }, [props.selectOperatingDSBChange, props.selectedOrOrder]);

  const handleChangeOptions = (e: any, v: any) => {
    let params = {
      doctor: doctor,
      anesthesiologist: anesthesiologist,
      start_date: moment(rangeDate.start).format("YYYY-MM-DD"),
      end_date: moment(rangeDate.end).format("YYYY-MM-DD"),
    };

    setRoom(v.value);

    setLoadingTimeSlot(true);

    props.runSequence({
      sequence: "OperatingDateTime",
      action: "getDSBBlock",
      card: CARD_SELECT_DATE_TIME_OPERATION,
      ...params,
      room: v.value,
      operatingId,
      onSuccess: () => setLoadingTimeSlot(false),
    });
  };

  const handleCloseModWarning = () => {
    setModError("");
  };

  const handleCloseModDetail = () => {
    setOpenModDetail(false);
  };

  const handleCloseModDoctorSchedule = () => {
    props.setProp("OperatingDateTimeSequence.selectedDoctorSchedule", null);
  };

  const handleSelectEvent = async (event: any) => {
    // if (event.operating_detail === props.selectedOperatingDetail || event.operating_detail === props.selectedOrOrder?.operating_detail) {
    if (event.operating_detail) {
      await props.setProp("OperatingDateTimeSequence.estimateEndDate", null);

      props.runSequence({
        sequence: "OperatingDateTime",
        action: "selectEvent",
        card: CARD_SELECT_DATE_TIME_OPERATION,
        data: { ...event },
      });

      setSelectedOperatingDetail(event.operating_detail);
      setOpenModOperatingDateTime(true);
    } else if (event.is_doctor_schedule) {
      props.runSequence({
        sequence: "OperatingDateTime",
        action: "selectDoctorSchedule",
        card: CARD_SELECT_DATE_TIME_OPERATION,
        data: { ...event },
      });
    } else if (calendarView === "month") {
      setLoadingTimeSlot(true);

      const start = moment(event.start).startOf("week").toDate();
      const end = moment(event.start).endOf("week").toDate();

      setRangeDate({ start, end });
      setCalendarView("week");

      setTimeout(() => {
        setLoadingTimeSlot(false);
      }, 100);
    }
  };

  const handleSelectSlot = async (info: any) => {
    // by month ไม่ให้กด นัด slot
    if (calendarView === "month") {
      return;
    }

    const showDetail =
      props.selectedAppointment &&
      props.selectedAppointment.division_service_block &&
      !props.selectOperatingDSBChange;

    if (info.action === "click" && !showDetail) {
      if (!doctor || !filterDoctor) {
        return setModError("กรุณาระบุแพทย์ผ่าตัด สำหรับระบุนัดหมายผ่าตัด");
      } else if (!room.length) {
        return setModError("กรุณาระบุห้องผ่าตัด สำหรับระบุนัดหมายผ่าตัด");
      }
    }

    if (info.action === "click" && doctor) {
      // let findExistsDSB = (props.operatingBlock || []).find((item: any, index: number) =>
      //   item.operating_detail === props.selectedOperatingDetail)
      // console.log("find index: ", info)
      let isTimePast = true;

      if (info.start) {
        let selectDate = moment(info.start).format("YYYY-MM-DD");
        // let currentDate = new Date(moment().format("YYYY-MM-DD"));
        isTimePast =
          selectDate > CURRENT_DATE ||
          (selectDate === CURRENT_DATE &&
            moment(info.start).diff(moment(), "minutes") >= 0);
      }

      const selectable =
        ((props.selectedAppointment &&
          !props.selectedAppointment.division_service_block) ||
          props.selectOperatingDSBChange) &&
        !loadingTimeSlot &&
        isTimePast;

      if (!!selectable) {
        await props.setProp("OperatingDateTimeSequence.estimateEndDate", null);
        await props.setProp("OperatingDateTimeSequence.operatingRoom", null);

        props.runSequence({
          sequence: "OperatingDateTime",
          action: "selectDateTime",
          card: CARD_SELECT_DATE_TIME_OPERATION,
          doctor: doctor,
          anesthesia: anesthesiologist,
          room: room,
          data: {
            ...info,
            operatingId,
          },
        });
        setSelectedOperatingDetail(props.selectedOperatingDetail);
        setOpenModOperatingDateTime(true);
      } else if (showDetail) {
        setOpenModDetail(true);
      }
    }
  };

  console.log("CardSelectDateTimeOperation: ", props);

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(
            `errorMessage.${CARD_SELECT_DATE_TIME_OPERATION}`,
            null
          );
        }}
        error={
          props.errorMessage?.[CARD_SELECT_DATE_TIME_OPERATION]?.message
            ? props.errorMessage?.[CARD_SELECT_DATE_TIME_OPERATION]?.message
            : props.errorMessage?.[CARD_SELECT_DATE_TIME_OPERATION]
        }
        success={null}
      />
      <CardSelectDateTimeOperationUX
        operationDoctor={doctor}
        ansDoctor={anesthesiologist}
        // ORRoom={room}
        disabledDoctor={true}
        disabledAnesthesia={true}
        disabledOperatingRoom={true}
        disabledDoctorOptions={disabledFilter}
        disabledAnesthesiaOptions={disabledFilter}
        // disabledDoctorOptions={!props.selectOperatingDSBChange}
        operationDoctorOptions={props.masterOptions?.doctor || []}
        ansDoctorOptions={props.masterOptions?.doctor || []}
        handleChangeOption={handleChangeOption}
        // Component
        doctorLabel={
          <div style={{ display: "flex", marginBottom: "5px" }}>
            <div
              style={{
                backgroundColor: "#FFE1A9",
                padding: "2px 4px",
                borderRadius: "2px",
              }}
            >
              แพทย์ผ่าตัด
            </div>
            <Icon
              name={filterDoctor ? "eye" : "low vision"}
              style={{
                paddingLeft: "5px",
                cursor: "pointer",
                marginTop: "3px",
              }}
              onClick={() => {
                setFilterDocter(!filterDoctor);
              }}
            />
          </div>
        }
        anesthesiaLabel={
          <div style={{ display: "flex", marginBottom: "5px" }}>
            <div
              style={{
                backgroundColor: "#E3BFF3",
                padding: "2px 4px",
                borderRadius: "2px",
              }}
            >
              วิสัญญีแพทย์
            </div>
            <Icon
              name={filterAnesthetic ? "eye" : "low vision"}
              style={{
                paddingLeft: "5px",
                cursor: "pointer",
                marginTop: "3px",
              }}
              onClick={() => {
                setFilterAnesthetoc(!filterAnesthetic);
              }}
            />
          </div>
        }
        operatingRoomLabel={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <div
              style={{
                backgroundColor: "#E95959",
                padding: "2px 4px",
                borderRadius: "2px",
              }}
            >
              ห้องผ่าตัด
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "5px",
              }}
            >
              <Icon
                name="circle"
                style={{ color: "#E95959", marginTop: "-3px" }}
              />
              <div>Major</div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "5px",
              }}
            >
              <Icon
                name="circle"
                style={{ color: "#FFC5C5", marginTop: "-3px" }}
              />
              <div>Minor</div>
            </div>
          </div>
        }
        operatingRoomOptions={
          <DropdownOptions
            value={room}
            multiple={true}
            search={true}
            checked={true}
            options={props.operatingLocationRoom || []}
            onChange={handleChangeOptions}
            showSubOptions={true}
            disabled={disabledFilter}
            // fluid={true}
            style={{ width: "100%" }}
          />
        }
        selectCalendar={
          <>
            <Calendar
              defaultView="week"
              localizer={localizer}
              events={groupOperatingBlock}
              views={["day", "week", "month"]}
              startAccessor="start"
              endAccessor="end"
              formats={formats}
              date={rangeDate.start}
              view={calendarView}
              showAllEvents={true}
              style={{ height: 600 }}
              // dayLayoutAlgorithm={"no-overlap"}
              selectable={true}
              onSelectEvent={(event, e) => handleSelectEvent(event)}
              onSelectSlot={handleSelectSlot}
              dayPropGetter={(date, resourceId) => {
                // console.log("dayPropGetter", date, resourceId);
                return {
                  style: {
                    backgroundColor: "transparent",
                  },
                };
              }}
              slotPropGetter={(date, resourceId) => {
                return {
                  style: {
                    backgroundColor:
                      moment(date).format("YYYY-MM-DD") === CURRENT_DATE &&
                      moment(date).diff(moment(), "minutes") >= 0
                        ? "#FFFCEF"
                        : "",
                  },
                };
              }}
              eventPropGetter={(
                event: any,
                start: Date,
                end: Date,
                isSelected: boolean
              ) => {
                // console.log("CardDentistSchedule blockList: ", blockList);
                // console.log("CardDentistSchedule event", event);
                const bg = getDetailBackgroundColor(event);

                return {
                  // event.doctor === doctor && !event.is_doctor_schedule &&
                  style: !!bg
                    ? { backgroundColor: bg, color: "#000000" }
                    : calendarView === "week"
                    ? {
                        padding: 0,
                        border: "none",
                        backgroundColor: "transparent",
                        pointerEvents: "none",
                      }
                    : calendarView === "month"
                    ? {
                        backgroundColor: (BAR_COLORS as any)[event.type]?.bg,
                        ...(!event.count
                          ? { opacity: 0, pointerEvents: "none" }
                          : event.type === "procedure"
                          ? { backgroundColor: "transparent", padding: 0 }
                          : {}),
                      }
                    : calendarView === "day" && !!event.children?.length
                    ? {
                        backgroundColor: "transparent",
                        border: "none",
                        padding: 0,
                        borderRadius: 0,
                        pointerEvents: "none",
                      }
                    : calendarView === "day"
                    ? {
                        backgroundColor: BAR_COLORS.doctor.bg,
                        border: `2px solid ${BAR_COLORS.doctor.bd}`,
                      }
                    : {},
                  //     : event.room && !event.doctor
                  //     ? {
                  //         backgroundColor: "#D9D9D9",
                  //         color: "#D9D9D9",
                  //         borderColor: "#D9D9D9",
                  //         maxWidth: "30px",
                  //       }
                  //     : event.room &&
                  //       event.procedure_type.includes("Major Surgical")
                };
              }}
              onRangeChange={(range: any, view?: View) => {
                console.log("onRangeChange", range, view);
                if (calendarView === view) {
                  return;
                }

                let data: any = rangeDate;
                const currentView = view || calendarView;

                if (currentView === "day") {
                  const date = !!view
                    ? moment(range[0]).add(4, "days").toDate()
                    : range[0];

                  data = { start: date, end: date };
                } else if (currentView === "month") {
                  setRangeDate(range);
                  data = range;
                } else if (currentView === "week") {
                  data = {
                    start: range[0],
                    end: range[range.length - 1],
                  };
                }

                // set view mode
                if (!!view) {
                  setCalendarView(view as any);
                }

                data = {
                  ...data,
                  [calendarView]: rangeDate,
                  ...(!!view && !!(rangeDate as any)[view]
                    ? (rangeDate as any)[view]
                    : {}),
                };

                setRangeDate(data);

                handleOnChangeWeekRange(
                  data.start,
                  data.end,
                  view || calendarView
                );
              }}
            ></Calendar>
          </>
        }
      />

      <Modal
        open={openModOperatingDateTime}
        style={{
          padding: "5vh 0",
          background: "transparent",
          boxShadow: "none",
        }}
        // size="large"
        onClose={() => setOpenModOperatingDateTime(false)}
      >
        <ModSelectOperatingDateTime
          onEvent={props.onEvent}
          setProp={props.setProp}
          runSequence={props.runSequence}
          masterOptions={props.masterOptions}
          card={CARD_SELECT_DATE_TIME_OPERATION}
          doctor={doctor}
          anesthesia={anesthesiologist}
          operatingRoom={room}
          onPostpone={props.onPostpone}
          isTargetOperatingDetail={
            selectedOperatingDetail === props.selectedOperatingDetail
          }
          room={room}
          onCancel={handleOnCancelSelectOperatingTime}
          OperatingDateTimeSequence={props.OperatingDateTimeSequence}
          estimateTimeText={props.estimateTimeText}
          selectOperatingDSBChange={props.selectOperatingDSBChange}
          onClose={() => {
            setOpenModOperatingDateTime(false);
          }}
        />
      </Modal>

      <ModInfo
        open={openModDetail}
        titleName="รายการนี้มีนัดหมายแล้ว"
        titleColor="red"
        style={{ width: "fit-content" }}
        buttonColor="red"
        btnText="ปิด"
        closeOnDimmerClick={true}
        onApprove={handleCloseModDetail}
        onClose={handleCloseModDetail}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "-1rem",
          }}
        >
          <div
            style={{ padding: "20px", fontSize: "1.15rem", lineHeight: 1.75 }}
          >
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px" }}>HN: </strong>
              <span>{props.selectedAppointment?.patient_hn}</span>
              <strong style={{ padding: "0px 5px 0px 15px" }}>
                ชื่อ-สกุล:
              </strong>
              <span>
                {`${
                  props.selectedAppointment?.patient_pre_name
                    ? props.selectedAppointment?.patient_pre_name + " "
                    : ""
                }${props.selectedAppointment?.patient_first_name} ${
                  props.selectedAppointment?.patient_last_name
                }`}
              </span>
            </div>
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px" }}>ห้องผ่าตัด:</strong>
              <span>{`${props.selectedOrOrder?.operating_room_no} (${props.selectedOrOrder?.primary_doctor_name})`}</span>
            </div>
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px" }}>วัน เวลานัดหมาย:</strong>
              <span>{props.selectedAppointment?.estimated_at}</span>
            </div>
          </div>
        </div>
      </ModInfo>

      <ModInfo
        open={!!selectedDoctorSchedule}
        titleName="ข้อมูลตารางออกตรวจแพทย์"
        titleColor="yellow"
        style={{ width: "fit-content" }}
        buttonColor="yellow"
        btnText="ปิด"
        closeOnDimmerClick={true}
        onApprove={handleCloseModDoctorSchedule}
        onClose={handleCloseModDoctorSchedule}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "-1rem",
          }}
        >
          <div
            style={{
              padding: "20px",
              fontSize: "1.15rem",
              lineHeight: 1.75,
              textAlign: "left",
            }}
          >
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px", width: "9rem" }}>
                แพทย์
              </strong>
              <span>{selectedDoctorSchedule?.doctor_name}</span>
            </div>
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px", width: "9rem" }}>
                วันที่
              </strong>
              <span>
                {moment(selectedDoctorSchedule?.start_datetime)
                  .clone()
                  .locale("th")
                  .format(`DD MMMM`)}{" "}
                {+moment(selectedDoctorSchedule?.start_datetime).format(
                  "YYYY"
                ) + 543}
              </span>
              <strong style={{ padding: "0 2rem 0 3rem" }}>เวลา</strong>
              <span>
                {moment(selectedDoctorSchedule?.start_datetime).format("HH:mm")}
              </span>
              <strong style={{ padding: "0 2rem" }}>ถึง</strong>
              <span>
                {moment(selectedDoctorSchedule?.end_datetime).format("HH:mm")}
              </span>
            </div>
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px", width: "9rem" }}>
                แผนก
              </strong>
              <span>{selectedDoctorSchedule?.division_name}</span>
            </div>
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px", width: "9rem" }}>
                จำนวนผู้ป่วย
              </strong>
              <span>{`${selectedDoctorSchedule?.patient_count} คน`}</span>
            </div>
          </div>
        </div>
      </ModInfo>

      <ModInfo
        open={!!modError}
        titleName="แจ้งเตือน !"
        titleColor="red"
        size="mini"
        buttonColor="red"
        btnText="ปิด"
        closeOnDimmerClick={true}
        onApprove={handleCloseModWarning}
        onClose={handleCloseModWarning}
      >
        <div
          style={{
            margin: "1.75rem 0rem 0.75rem",
          }}
        >
          {modError}
        </div>
      </ModInfo>
    </div>
  );
};

export default React.memo(CardSelectDateTimeOperation);
