import axios from 'axios'
import { to } from '../../../../utils'
import BaseService from  '../../../services/BaseService'
import config from '../../../../../config/config'
import { Response, Error } from '../../common'
const HOST = `${config.API_HOST}`

export interface PatientOldNameSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    patient?: any,
    citizen_passport?: any,
    pre_name_th?: any,
    first_name_th?: any,
    middle_name_th?: any,
    last_name_th?: any,
    pre_name_en?: any,
    first_name_en?: any,
    middle_name_en?: any,
    last_name_en?: any,
    reason?: any,
    change_ref?: any,
    full_name_th?: any,
    full_name_en?: any,
}


/* ['generics.ListCreateAPIView'] */
/* params:  */
/* data:  */
export const PatientOldNameList : 
{
  list:     (arg0:
             { patient_id: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  create:   (arg0:
             { patient_id: any, 
                params?: any,
                data?: PatientOldNameSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  list: async ({patient_id, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/REG/patient/${patient_id}/name/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  create: async ({patient_id, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/apis/REG/patient/${patient_id}/name/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

/* ['generics.RetrieveUpdateDestroyAPIView'] */
/* params:  */
/* data:  */
export const PatientOldNameDetail : 
{
  retrieve: (arg0:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  update:   (arg0:
             { pk: any, 
                params?: any,
                data?: PatientOldNameSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    (arg0:
             { pk: any, 
                params?: any,
                data?: PatientOldNameSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/REG/patient/name/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  update: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${HOST}/apis/REG/patient/name/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${HOST}/apis/REG/patient/name/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

