import React, { useState, useEffect } from "react";
import {
  Form,
  Label,
  Select,
  Button,
  Icon,
  Table,
  Popup,
  Dimmer,
  Loader,
  Checkbox
} from "semantic-ui-react";
import CardLayout from "../common/CardLayout";
import DateTextBox from "../common/DateTextBox";
import { formatDate, parseDate } from '../../utils/dateUtils';
import PropTypes from "prop-types";

const CardMedicationRecord = props => {
  const {
    closeable,
    toggleable,
    medRecordList,
    getMedicationRecord,
    triggerMedicationRecord,
    isLoading
  } = props;

  let defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 5);

  const [date, setDate] = useState(formatDate(defaultStartDate));
  const [drugType, setDrugType] = useState("");
  const [startDateRange, setStartDateRange] = useState(null);
  const [stopDateRange, setStopDateRange] = useState(null);

  let medRecord = medRecordList ? medRecordList.items : [];
  let datesRecord = medRecordList ? medRecordList.dates : [];
  let checkInTime = medRecordList ? medRecordList.check_in_time : ""; // OPD
  let checkOutTime = medRecordList ? medRecordList.check_out_time : ""; // OPD
  let admitTime = medRecordList ? medRecordList.admit_time : ""; // IPD
  let dischargeTime = medRecordList ? medRecordList.discharge_time : ""; // IPD

  const drugOptions = [
    { key: "a", text: "ALL", value: "" },
    { key: "s", text: "STAT", value: "STAT" },
    { key: "c", text: "CONTINUE_PLAN", value: "CONTINUE_PLAN" },
    { key: "o", text: "ONE_DOSE", value: "ONE_DOSE" },
    { key: "d", text: "ONE_DAY", value: "ONE_DAY" },
    { key: "p", text: "PICKING", value: "PICKING" }
  ];

  const handleGetMedicationRecord = () => {
    getMedicationRecord({ date: date, type: drugType });
  };

  useEffect(() => {
    handleGetMedicationRecord();
  }, []);

  useEffect(() => {
    handleGetMedicationRecord();
  }, [drugType, date]);

  useEffect(() => {
    if (
      triggerMedicationRecord["dischargeTime"] &&
      triggerMedicationRecord["checkInTime"]
    ) {
      if (triggerMedicationRecord["encounterType"]) {
        if (triggerMedicationRecord["encounterType"] === "IPD") {
          let toDate = parseDate(
            triggerMedicationRecord["dischargeTime"],
            false
          );
          let startDate = parseDate(
            triggerMedicationRecord["checkInTime"],
            false
          );

          toDate.setDate(toDate.getDate() - 6);
          startDate.setDate(startDate.getDate() - 6);
          setStopDateRange(formatDate(toDate));
          setStartDateRange(formatDate(startDate));

          setDate(formatDate(toDate));
        } else {
          let toDate = parseDate(
            triggerMedicationRecord["dischargeTime"],
            false
          );
          toDate.setDate(toDate.getDate() - 6);
          setStopDateRange(formatDate(toDate));
          setStartDateRange(formatDate(toDate));
          setDate(formatDate(toDate));
        }
      }
    }
  }, [triggerMedicationRecord]);

  const handleDateChange = date => {
    setDate(date);
  };

  const HeaderMedRecord = params => {
    return (
      <>
        {params.dates.map(item => {
          return (
            <Table.HeaderCell key={item} rowSpan="2">
              {" "}
              {item}{" "}
            </Table.HeaderCell>
          );
        })}
      </>
    );
  };

  const handlePrint = params => {
    props.onMedicaltionRecordPrint()
  };

  const handleOnChangeSelect = (e, { value }) => {
    setDrugType(value);
  };
  const handlePlusDate = () => {
    let plus = parseDate(date, false);
    plus.setDate(plus.getDate() + 1);
    setDate(formatDate(plus));
  };

  const handleMinusDate = () => {
    let plus = parseDate(date, false);
    plus.setDate(plus.getDate() - 1);
    setDate(formatDate(plus));
  };

  const MedRecordTable = params => {
    return (
      <>
        {params.medRecordItems.map(item => {
          let time = Object.keys(item.admin_time_list[0]);
          time.filter(item => item !== "standard_admin_time");
          console.log('MedRecordTable', item.admin_time_list[0][time[0]].drug_order_item_id)
          return (
            <MedRecordDetail
              key={item.admin_time_list[0][time[0]].drug_order_item_id}
              row={item}
              dates={params.datesRecordList}
            ></MedRecordDetail>
          );
        })}
      </>
    );
  };

  const IconCircleDrug = params => {
    return (
      <Popup
        position="bottom right"
        size="large"
        header={params.description}
        trigger={
          <Label as="a" circular color="yellow">
            {params.abbr}
          </Label>
        }
      />
    );
  };

  const MedRecordDetail = params => {
    let dates = params.dates;
    let item = params.row;
    let drugOrder = item.drug_order_item_summary
      .split("\n")
      .map((i, key) => <p key={key}>{i}</p>);

    let lasa = item.lasa;
    let pregcat = item.pregcat;
    let pregcat_description = item.pregcat_description;
    let highalert = item.highalert;
    let highalert_description = item.highalert_description;

    let span = item.admin_time_list.length;
    let rows = item.admin_time_list;

    return (
      <>
        {rows.map((row, i) => (
          <Table.Row key={row.standard_admin_time}>
            {i === 0 ? (
              <>
                <Table.Cell rowSpan={span.toString()}>
                  <Checkbox key={item.drug_order_item_summary} />
                </Table.Cell>
                <Table.Cell rowSpan={span.toString()} width={6}>
                  {drugOrder}

                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {pregcat ? (
                      <IconCircleDrug
                        abbr={pregcat}
                        description={pregcat_description}
                      />
                    ) : null}
                    {highalert ? (
                      <IconCircleDrug
                        abbr="HAD"
                        description={highalert_description}
                      />
                    ) : null}
                    {lasa ? (
                      <IconCircleDrug
                        abbr="LASA"
                        description="LASA: look-alike, sound-alike"
                      />
                    ) : null}
                  </div>
                </Table.Cell>
              </>
            ) : null}

            <Table.Cell> {row.standard_admin_time} </Table.Cell>
            {dates.map((item, i) => {
              let color =
                row[item] && row[item].is_editable ? "lightyellow" : "gray";
              color = row[item] && row[item].is_omitted ? "#ff8383" : color;
              return (
                <Table.Cell key={item} style={{ backgroundColor: color }}>
                  {row[item] && row[item].summary ? row[item].summary : null}
                </Table.Cell>
              );
            })}
          </Table.Row>
        ))}
      </>
    );
  };

  let current = parseDate(date, false);
  let disableBack = current <= parseDate(startDateRange, false);
  let disableForward = parseDate(stopDateRange, false) <= current;

  return (
    <CardLayout
      titleText="Medication Administration Record"
      closeable={closeable}
      toggleable={toggleable}
      hideHeaderIcon={true}
    >
      <Form style={{ display: "flex", justifyContent: "flex-end" }}>
        <Form.Group inline>
          <Form.Field>
            <label>Type</label>
          </Form.Field>

          <Select
            size="tiny"
            placeholder="ALL"
            options={drugOptions}
            onChange={handleOnChangeSelect}
            style={{ marginRight: 14 }}
          />

          <Form.Field>
            <Button
              icon
              color="teal"
              size="tiny"
              onClick={handleMinusDate}
              disabled={disableBack}
            >
              <Icon name="chevron left" />
            </Button>
          </Form.Field>

          <Form.Field>
            <DateTextBox
              minDate={startDateRange}
              maxDate={stopDateRange}
              size="small"
              value={date}
              onChange={handleDateChange}
            />
          </Form.Field>

          <Form.Field>
            <Button
              icon
              color="teal"
              size="tiny"
              onClick={handlePlusDate}
              disabled={disableForward}
            >
              <Icon name="chevron right" />
            </Button>
          </Form.Field>

          {/* <Form.Field>
            <Button
              size="tiny"
              color="teal"
              content="พิมพ์"
              icon="print"
              labelPosition="left"
              onClick={handlePrint}
            />
          </Form.Field> */}
        </Form.Group>
      </Form>
      <Dimmer active={isLoading} inverted>
        <Loader />
      </Dimmer>
      {medRecordList &&
        medRecordList.items &&
        medRecordList.items.length > 0 ? (
          <>
            <Table celled structured>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell rowSpan="2">StdTime</Table.HeaderCell>
                  <HeaderMedRecord dates={datesRecord} />
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell>MEDICATION AND DOSAGE</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <MedRecordTable
                  medRecordItems={medRecord}
                  datesRecordList={datesRecord}
                />
              </Table.Body>
            </Table>
          </>
        ) : (
          <div> No Medication Record </div>
        )}
    </CardLayout>
  );
};

CardMedicationRecord.defaultProps = {
  closeable: false,
  toggleable: false,
  hideHeaderIcon: true,
  isLoading: false,
  medRecordList: {},
  triggerMedicationRecord: {},
  getMedicationRecord: () => { },
  getMedicationRecordPrint: () => { },
  onMedicaltionRecordPrint: () => { }
};

CardMedicationRecord.propTypes = {
  closeable: PropTypes.bool,
  toggleable: PropTypes.bool,
  hideHeaderIcon: PropTypes.bool,
  isLoading: PropTypes.bool,
  medRecordList: PropTypes.object,
  getMedicationRecord: PropTypes.func,
  triggerMedicationRecord: PropTypes.object,
  getMedicationRecordPrint: PropTypes.func,
  onMedicaltionRecordPrint: PropTypes.func,
};

export default CardMedicationRecord;
