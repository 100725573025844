import React, { useMemo, useState, useCallback, useEffect } from "react";
import { Checkbox } from "semantic-ui-react";

// UX
import ModStockOtherStoreUX from "./ModStockOtherStoreUX";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Interface
import {
  BUTTON_ACTIONS,
  RunSequence,
  ProductStockSerializer,
  PermissionsType,
  stockExpired,
} from "./sequence/StockManagement";
import { CARD_STOCK_MANAGEMENT } from "./CardStockManagement";

// Utils
import { CARD_STOCK_MANAGEMENT_TAB_LOT } from "./CardStockManagementTabLot";

type ModStockOtherStoreProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: RunSequence;
  // data
  data: Partial<ProductStockSerializer>;
  productStockByLotList: ProductStockSerializer[];
  permissions?: PermissionsType;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  // callback
  onClose?: () => any;
};

const MOD_STOCK_OTHER_STORE = "ModStockOtherStore";

const ModStockOtherStore = (props: ModStockOtherStoreProps) => {
  const [lotList, setLotList] = useState<ProductStockSerializer[]>([]);

  useEffect(() => {
    setLotList(structuredClone(props.productStockByLotList));
  }, [props.productStockByLotList]);

  // Use Callback
  const handleCheck = useCallback(
    (index: number) => (e: any, data: any) => {
      if (!props.permissions?.OTHER_STORE_ACTIVE) {
        return;
      }

      const list = [...lotList];

      list[index].active = !data.checked;

      setLotList(list);
    },
    [lotList, props.permissions]
  );

  const handleCheckAll = useCallback(
    (e: any, data: any) => {
      if (!props.permissions?.OTHER_STORE_ACTIVE) {
        return;
      }

      const mapActive = (active: boolean) =>
        lotList.map((item) => ({ ...item, active }));

      const items = data.checked ? mapActive(false) : mapActive(true);

      setLotList([...items]);
    },
    [lotList, props.permissions]
  );

  const handleClickLabel = useCallback(
    (checked: boolean) => () => {
      handleCheckAll(null, { checked });
    },
    [props.productStockByLotList]
  );

  // Use Memo
  const otherStoreItems = useMemo(() => {
    return lotList.map((item, index) => ({
      ...item,
      store: item.storage.name,
      balance: item.quantity,
      storage: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Checkbox
            name={item.id.toString()}
            checked={!item.active}
            disabled={!props.permissions?.OTHER_STORE_ACTIVE}
            // callback
            onClick={handleCheck(index)}
          />
        </div>
      ),
    }));
  }, [lotList, props.permissions]);

  const isCheckedAll = useMemo(() => {
    return lotList.every((item) => !item.active) && !!lotList.length;
  }, [lotList]);

  const headers = useMemo(() => {
    return [
      "Store",
      "Balance",
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Checkbox
          checked={isCheckedAll}
          disabled={!props.permissions?.OTHER_STORE_ACTIVE}
          // callback
          onClick={handleCheckAll}
        />
        <label
          style={{ color: "white", marginLeft: "10px", cursor: "pointer" }}
          // callback
          onClick={handleClickLabel(!isCheckedAll)}
        >
          OFF (Storage)
        </label>
      </div>,
    ];
  }, [isCheckedAll, props.permissions]);

  const titleName = useMemo(() => {
    return `[${props.data?.product?.code || ""}]-${
      props.data?.product?.name || ""
    }`;
  }, [props.data]);

  const color = useMemo(() => {
    const isExp = stockExpired(props.data.lot?.exp_datetime || "");

    return isExp ? "#DA0000" : "";
  }, [props.data.lot?.exp_datetime]);

  const handleSave = () => {
    const list = lotList
      .filter(
        (item) =>
          props.productStockByLotList.find((acc) => acc.id === item.id)
            ?.active !== item.active
      )
      .map((item) => ({
        product: item.product.id,
        storage: item.storage.id,
        lot: item.lot?.id,
        active: item.active,
      }));

    props.runSequence({
      sequence: "StockManagement",
      action: "SAVE_STOCK_OTHER_STORE",
      offStorageList: list,
      card: MOD_STOCK_OTHER_STORE,
      errorKey: CARD_STOCK_MANAGEMENT,
      btnAction: BUTTON_ACTIONS.SAVE,
      onSuccess: () => {
        props.runSequence({
          sequence: "StockManagement",
          action: "SEARCH_LOT",
          card: CARD_STOCK_MANAGEMENT_TAB_LOT,
          errorKey: CARD_STOCK_MANAGEMENT,
          btnAction: BUTTON_ACTIONS.SAVE,
        });
        props.onClose?.();
      },
    });
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <ModStockOtherStoreUX
        // data
        otherStoreList={otherStoreItems}
        name={titleName}
        lotNo={<span style={{ color }}>{props.data.lot?.mfc_no}</span>}
        expireDate={
          <span style={{ color }}>{props.data.lot?.exp_datetime}</span>
        }
        // headers
        headers={headers}
        // callback
        onClose={props.onClose}
        onCancel={props.onClose}
        // Element
        ButtonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={`${MOD_STOCK_OTHER_STORE}_${BUTTON_ACTIONS.SAVE}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${MOD_STOCK_OTHER_STORE}_${BUTTON_ACTIONS.SAVE}`
              ]
            }
            style={{ width: "130px", margin: "0 2rem" }}
            // config
            color={"blue"}
            disabled={!props.permissions?.OTHER_STORE_ACTIVE}
            name={BUTTON_ACTIONS.SAVE}
            size="medium"
            title="บันทึก"
          />
        }
      />
    </div>
  );
};

export default React.memo(ModStockOtherStore);
