import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import CardLayout from "../common/CardLayout";
import RightLabel from "../common/RightLabel";
import ReactTable from "react-table-6";
import _ from "../../compat/lodashplus";
import {
  Form,
  Button,
  Icon,
  Checkbox,
  Segment,
  Modal,
  Label,
} from "semantic-ui-react";
import ErrorMessage from "../common/ErrorMessage";
import MultidisciplinaryEditor from "./MultiDisciplinaryEditor";
import { toast } from "react-toastify";
import DateTextBox from "../common/DateTextBox";
import { useFormatDropdown } from "react-lib/utils/hooksUtils";
import { isEmpty } from "lodash";

const CardMultiDisciplinaryView = (props) => {
  const [errors, setErrors] = useState([]);
  const [isShowModAddMulti, setIsShowModAddMulti] = useState(false);
  const [authmodalOpen, setAuthModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [authErrorsMessage, setAuthErrorsMessage] = useState(null);
  const [cancelUser, setCancelUser] = useState("");
  const [cancelPassword, setCancelPassword] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [modifiedData, setModifiedData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchByDate, setSearchByDate] = useState(false);
  const [printText, setPrintText] = useState(false);
  const [loading, setLoading] = useState(false);
  // choice
  const [practitionerChoice, setPractitionerChoice] = useState({});
  const [roleType, setRoleType] = useState("");

  const editorRef = useRef(null);
  const isMounted = useRef(false);

  const style = {
    fluid: { width: "100%" },
    chkboxRight: { marginLeft: "auto", marginRight: 0 },
    inline: { margin: "0 0 0.5em 0" },
    blueFont: { color: "#3180ed" },
    grayColor: "#f3f3f3",
    textWrap: { wordWrap: "break-word", whiteSpace: "pre-line" },
  };

  useEffect(() => {
    isMounted.current = true;
    getPractitionerChoice();
    props.onGetMultidisciplinary({});

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    props.onGetMultidisciplinary({});
  }, [props.encounterId]);

  useEffect(() => {
    if (props.multidisciplinaryData !== null) {
      // console.log(" props.multidisciplinaryData", props.multidisciplinaryData)
      var clonedProp = JSON.parse(JSON.stringify(props.multidisciplinaryData));
      var tmpData = [];
      for (let item of clonedProp) {
        item.chk = false;
        console.log(item);
        tmpData.push(item);
      }
      if (isMounted.current) {
        console.log(tmpData);
        setModifiedData(tmpData);
      }
    }
  }, [props.multidisciplinaryData]);

  const searchAndFilter = () => {
    var query = {};
    if (searchByDate) {
      query.nurseRecordStartDate = startDate ? startDate.toString() : "";
      query.nurseRecordEndDate = endDate ? endDate.toString() : "";
    }
    if (props.encounterId) {
      query.encounterId = props.encounterId;
    }
    query.practitionerRole = roleType ? roleType : "";
    console.log("searchAndFilter: " + query);
    props.onGetMultidisciplinary(query);
  };

  const getPractitionerChoice = async () => {
    if (props.controller) {
      const [data, error] = await props.controller.getChoices({
        model: "users.Role",
        field: "type",
        nameAsId: true,
      });
      if (isMounted.current) {
        if (error) {
          setErrors(error);
        }
        data.splice(0, 0, { id: 0, value: "", text: "ทั้งหมด" });
        setPractitionerChoice(data);
      }
    }
  };

  const practitionerChoiceList = useFormatDropdown(
    practitionerChoice ? practitionerChoice : [],
    "text",
    "id"
  );

  const onSaveMultidisciplinary = async (data) => {
    console.log("onSaveMultidisciplinary");
    console.log(props.multidisciplinaryData);
    console.log(data);

    let errors = await props.onSaveMultidisciplinary(data); //เรียกจริง
    if (isEmpty(errors)) {
      console.log("Save success");
      props.onGetMultidisciplinary({});
      setIsShowModAddMulti(false);
      editorRef.current.clearValue();
    } else {
      console.log("Save failed", errors);
    }

    return errors;
  };

  const handleModVerifyUserCancel = () => {
    setDeleteItemId(null);
    setAuthModalOpen(false);
    setCancelPassword("");
  };

  const handleDeleteVerify = async () => {
    setDeleteLoading(true);
    setAuthErrorsMessage(null);
    var params = {};
    params.username = cancelUser;
    params.password = cancelPassword;

    let errors = await props.onDeleteMultidisciplinary(deleteItemId, params);
    if (errors) {
      setDeleteLoading(false);
      console.log(errors);
      setAuthErrorsMessage(errors);
    } else {
      props.onGetMultidisciplinary({}); //refresh
      setAuthModalOpen(false);
      setDeleteLoading(false);
      setDeleteItemId(null);
      setCancelPassword("");
    }
  };

  const handleCheckboxChange = (checked, data) => {
    data.original.chk = checked;
    setModifiedData(JSON.parse(JSON.stringify(modifiedData)));
  };

  const handleCheckAll = (checked) => {
    for (let item of modifiedData) {
      item.chk = checked;
    }
    setModifiedData(JSON.parse(JSON.stringify(modifiedData)));
  };

  const handlePrintItem = async () => {
    setLoading(true);
    var params = {
      encounter: props.encounterId,
      pdf: true,
      item_list: [],
    };
    var printList = [];
    for (let item of modifiedData) {
      if (item.chk) {
        printList.push(item.id);
      }
    }
    params.item_list = printList;
    let errors = await props.onPrintMultidisciplinary(params);

    if (errors) {
      // toast.error("พิมพ์ไม่สำเร็จ");
      setLoading(false);
    } else {
      setLoading(false);
      setPrintText(true);
      setTimeout(() => {
        setPrintText(false);
      }, 2000);
      // toast.success("พิมพ์สำเร็จ");
    }
  };

  useEffect(() => {
    if (!isShowModAddMulti) {
      editorRef.current.clearValue();
    }
  }, [isShowModAddMulti]);

  return (
    <CardLayout
      titleText="Multidisciplinary"
      headerColor="teal"
      closeable={props.closeable}
      toggleable={props.toggleable}
      defaultBackground={props.defaultBackground}
      hideHeaderIcon={props.hideHeaderIcon}
      loading={props.multidisciplinaryLoading}
      enableMargin={props.enableMargin}
    >
      <Form error={errors ? true : false}>
        <ErrorMessage error={errors} />
        <Form.Group inline style={style.inline}>
          <Form.Field className="four wide" />
          <Form.Field width={2}>
            <RightLabel>Practitioner</RightLabel>
          </Form.Field>
          <Form.Dropdown
            width={2}
            selection
            style={style.fluid}
            value={roleType}
            options={practitionerChoiceList}
            onChange={(e, data) => {
              setRoleType(data.value);
            }}
          />
          <Form.Field className="two wide">
            <Checkbox
              style={style.chkboxRight}
              label="ระบุวันที่"
              className={"ui checkbox"}
              defaultChecked={false}
              onChange={(e, value) => {
                setSearchByDate(value.checked);
              }}
            />
          </Form.Field>
          <Form.Field className="two wide">
            <DateTextBox
              value={startDate}
              onChange={(date) => {
                setStartDate(date);
              }}
            />
          </Form.Field>
          <Form.Field className="two wide">
            <DateTextBox
              value={endDate}
              onChange={(date) => {
                setEndDate(date);
              }}
            />
          </Form.Field>
          <Form.Field className="two wide">
            <Button
              icon
              type="button"
              color="green"
              style={{ width: "100%" }}
              size={"small"}
              onClick={_.debounce(searchAndFilter, 500, { leading: true })}
            >
              <Icon name="search" />
              ค้นหา
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
      <ReactTable
        style={{ height: 470, backgroundColor: "#FFFFFF" }}
        defaultPageSize={modifiedData.length > 8 ? modifiedData.length : 8}
        showPagination={modifiedData.length >= 25}
        data={modifiedData}
        columns={[
          {
            Header: "",
            accessor: "working_shift",
            style: { whiteSpace: "unset", textAlign: "center" },
            width: 60,
            Cell: (data) => {
              return (
                <div style={{ textAlign: "center", width: "100%" }}>
                  <Checkbox
                    // defaultChecked={false}
                    checked={data.original.chk}
                    onChange={(e, value) => {
                      handleCheckboxChange(value.checked, data);
                    }}
                  ></Checkbox>
                </div>
              );
            },
          },
          {
            Header: "Date",
            accessor: "date",
            style: { whiteSpace: "unset" },
            minWidth: 50,
          },
          {
            Header: "Problem",
            accessor: "problem",
            style: { whiteSpace: "unset" },
            minWidth: 100,
            Cell: (data) => {
              return (
                <div>
                  <p style={style.textWrap}>{data.original.problem}</p>
                </div>
              );
            },
          },
          {
            Header: "Plan",
            accessor: "plan",
            style: { whiteSpace: "unset" },
            minWidth: 100,
            Cell: (data) => {
              return (
                <div>
                  <p style={style.textWrap}>{data.original.plan}</p>
                </div>
              );
            },
          },
          {
            Header: "Goal",
            accessor: "goal",
            style: { whiteSpace: "unset" },
            minWidth: 100,
            Cell: (data) => {
              return (
                <div>
                  <p style={style.textWrap}>{data.original.goal}</p>
                </div>
              );
            },
          },
          {
            Header: "Practitioner",
            accessor: "practitioner_name",
            style: { whiteSpace: "unset" },
            minWidth: 50,
          },
          {
            Header: "End Date",
            accessor: "end_date",
            style: { whiteSpace: "unset" },
            minWidth: 50,
          },
          {
            Header: "",
            accessor: "edit",
            width: 120,
            Cell: (data) => {
              return (
                <Form style={{ display: props.readOnly ? "none" : "block" }}>
                  <Form.Field>
                    <Button
                      icon
                      color="yellow"
                      onClick={() => {
                        console.log(editorRef.current.getValue());
                        setIsShowModAddMulti(true);
                        editorRef.current.setValue(data.original);
                        console.log(data.original);
                      }}
                    >
                      <Icon name="pencil" />
                    </Button>
                    <Button icon color="red">
                      <Icon
                        name="trash"
                        onClick={() => {
                          setDeleteItemId(data.original.id);
                          setAuthModalOpen(true);
                        }}
                      />
                    </Button>
                  </Form.Field>
                </Form>
              );
            },
          },
        ]}
      />

      <Form>
        <Form.Group inline style={{ marginTop: "10px" }}>
          <Form.Field className="one wide">
            <Button
              icon
              circular
              color="yellow"
              style={{ display: props.readOnly ? "none" : "absolute" }}
              onClick={() => {
                setIsShowModAddMulti(true);
                editorRef.current.clearValue();
              }}
            >
              <Icon name="add" />
            </Button>
          </Form.Field>
          <Form.Field className="twelve wide" />
          <Form.Field className="two wide">
            <Checkbox
              label="เลือกทั้งหมด"
              className={"ui checkbox"}
              defaultChecked={false}
              onChange={(e, value) => {
                handleCheckAll(value.checked);
              }}
            />
          </Form.Field>
          <Form.Field className="two wide">
            <Button
              icon
              color="blue"
              style={{ width: "100%" }}
              onClick={_.debounce(handlePrintItem, 500, { leading: true })}
              loading={loading}
            >
              {printText ? (
                <Icon className="check" />
              ) : (
                <div>
                  <Icon name="print" />
                  พิมพ์
                </div>
              )}
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>

      <Segment style={{ display: isShowModAddMulti ? "block" : "none" }}>
        <MultidisciplinaryEditor
          ref={editorRef}
          encounterId={props.encounterId}
          onSaveMultidisciplinary={onSaveMultidisciplinary}
          onClose={() => {
            console.log("close click");
            setIsShowModAddMulti(false);
          }}
        />
      </Segment>

      <Modal
        open={authmodalOpen}
        onClose={handleModVerifyUserCancel}
        size="mini"
      >
        <Modal.Header
          style={{
            background: "#2185D0",
            color: "white",
            padding: "12px",
            fontSize: "14px",
          }}
        >
          กรุณา Log in เพื่อยืนยันการลบ
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Input
              placeholder="Username"
              name="name"
              value={cancelUser}
              label="Username"
              onChange={(e, item) => setCancelUser(item.value)}
            />
            <Form.Input
              placeholder="Password"
              name="email"
              value={cancelPassword}
              type="password"
              label="Password"
              onChange={(e, item) => setCancelPassword(item.value)}
            />
            <ErrorMessage style={{ width: "100%" }} error={authErrorsMessage} />
            <Form.Group>
              <Button
                className={"ui positive basic button"}
                style={{ width: "100%" }}
                loading={deleteLoading}
                disabled={deleteLoading}
                onClick={handleDeleteVerify}
              >
                ใช่
              </Button>
              <Button
                className={"ui negative basic button"}
                style={{ width: "100%" }}
                onClick={handleModVerifyUserCancel}
              >
                ไม่
              </Button>
            </Form.Group>
          </Form>
        </Modal.Content>
      </Modal>
    </CardLayout>
  );
};

CardMultiDisciplinaryView.propTypes = {
  controller: PropTypes.object,
  onGetMultidisciplinary: PropTypes.func,
  onSaveMultidisciplinary: PropTypes.func,
  onDeleteMultidisciplinary: PropTypes.func,
  onPrintMultidisciplinary: PropTypes.func,
  encounterId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

CardMultiDisciplinaryView.defaultProps = {
  closeable: true,
  toggleable: true,
  defaultBackground: true,
  hideHeaderIcon: false,
  onGetMultidisciplinary: () => {},
  onSaveMultidisciplinary: () => {},
  onDeleteMultidisciplinary: () => {},
  onPrintMultidisciplinary: () => {},
  multidisciplinaryLoading: false,
  multidisciplinaryData: [],
  enableMargin: true,
  readOnly: true,
  encounterId: "",
};

// Do not use React.memo
// because checkbox in the table not re-render
export default React.memo(CardMultiDisciplinaryView);
