import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Label,
  Input
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardReturnDrugWorkingUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          className="ui form"
          style={{display: "flex", alignItems: "center"}}>
          
          <div
            className="ui label brown ribbon">
            {props.DrugReturnRequestHistorySequence?.selectedBox?.code || "-"}
          </div>
          <div>
            
            <div
              style={{fontWeight: "bold", fontSize: "1.2rem"}}>
              คืนยากลับเข้าห้องยา
            </div>
          </div>
          <div
            style={{flex: 1}}>
            
          </div>
          <Label>
            {props.DrugReturnRequestHistorySequence?.selectedBox?.status_name || "-"}
          </Label>
        </div>
        <div
          className="ui form"
          style={{marginBottom: "1.5rem"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label>
                เลขที่ใบยา
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Label
                className="large"
                style={{backgroundColor: "#C3F4FF", color: "rgba(0,0,0,.87)"}}>
                {props.DrugReturnRequestHistorySequence?.selectedBox?.order_code || "-"}
              </Label>
            </FormField>
            <FormField
              inline={true}>
              <label>
                ผู้ป่วย
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Label
                className="large"
                style={{backgroundColor: "#C3F4FF", color: "rgba(0,0,0,.87)"}}>
                {props.DrugReturnRequestHistorySequence?.selectedBox?.patient_name_code || "-"}
              </Label>
            </FormField>
            <FormField
              inline={true}>
              <label>
                หน่วยงาน
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Label
                className="large"
                style={{backgroundColor: "#C3F4FF", color: "rgba(0,0,0,.87)"}}>
                {props.DrugReturnRequestHistorySequence?.selectedBox?.requester_name || "-"}
              </Label>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{width: "70px"}}>
                ผู้ขอคืน
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.DrugReturnRequestHistorySequence?.selectedBox?.requested?.name || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label>
                วัน-เวลา
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.DrugReturnRequestHistorySequence?.selectedBox?.requested?.datetime_str || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label>
                แผนก
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.DrugReturnRequestHistorySequence?.selectedBox?.requested?.division || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{width: "70px"}}>
                ผู้อนุมัติ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.DrugReturnRequestHistorySequence?.selectedBox?.approved?.name || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label>
                วัน-เวลา
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.DrugReturnRequestHistorySequence?.selectedBox?.approved?.datetime_str || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label>
                แผนก
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.DrugReturnRequestHistorySequence?.selectedBox?.approved?.division || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{width: "70px"}}>
                ผู้ส่ง
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.DrugReturnRequestHistorySequence?.selectedBox?.delivered?.name || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label>
                วัน-เวลา
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.DrugReturnRequestHistorySequence?.selectedBox?.delivered?.datetime_str || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label>
                แผนก
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.DrugReturnRequestHistorySequence?.selectedBox?.delivered?.division || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{width: "70px"}}>
                ผู้รับ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.DrugReturnRequestHistorySequence?.selectedBox?.received?.name || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label>
                วัน-เวลา
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.DrugReturnRequestHistorySequence?.selectedBox?.received?.datetime_str || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label>
                แผนก
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.DrugReturnRequestHistorySequence?.selectedBox?.received?.division || ""}>
              </Input>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form">
          
          <div
            style={{width: "100%"}}>
            {props.TopTable}
          </div>
          <Table
            data={props.items || []}
            getTheadThProps={props.getTheadThProps}
            getTrProps={props.getTrProps}
            headers="ลำดับ,รหัสยา,ชื่อยา,จำนวนจ่าย,จำนวนคงเหลือ,จำนวนขอคืน,จำนวนรับคืนเข้าคลัง,จำนวนจ่ายรับคืนทำลาย,เหตุผล,หน่วย"
            keys="no,code,name,quantity_issue,quantity_left,quantity_request,quantity_restock,quantity_discard,reason,stock_unit_name"
            showPagination={false}
            style={{height: "200px"}}
            widths="50,100,,100,100,100,100,100,150,100">
          </Table>
          <div>
            {props.ErrorMessage}
          </div>
        </div>
        <div
          className="ui form"
          style={{marginTop: "2rem"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
            </FormField>
            <div
              className="field"
              style={{flex: 1}}>
              
            </div>
            <FormField
              inline={true}>
              <div>
                {props.buttonReject}
              </div>
            </FormField>
            <FormField>
              <div>
                {props.buttonApprove}
              </div>
            </FormField>
            <FormField>
              <div>
                {props.buttonDeliver}
              </div>
            </FormField>
            <FormField>
              <div>
                {props.buttonEdit}
              </div>
            </FormField>
            <FormField>
              <div>
                {props.buttonReceive}
              </div>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}

export default React.memo(CardReturnDrugWorkingUX)

export const screenPropsDefault = {}

/* Date Time : Sat Feb 25 2023 15:22:05 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "คืนยากลับเข้าห้องยา"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.5rem\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบยา"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Label",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DrugReturnRequestHistorySequence?.selectedBox?.order_code || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#C3F4FF\", color: \"rgba(0,0,0,.87)\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormGroup",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormGroup",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormGroup",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormField",
      "parent": 28,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 28,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 28,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 28,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 29,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 29,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 29,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 29,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ขอคืน"
        },
        "style": {
          "type": "code",
          "value": "{width: \"70px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน-เวลา"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้อนุมัติ"
        },
        "style": {
          "type": "code",
          "value": "{width: \"70px\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน-เวลา"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ส่ง"
        },
        "style": {
          "type": "code",
          "value": "{width: \"70px\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "label",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน-เวลา"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Input",
      "parent": 31,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugReturnRequestHistorySequence?.selectedBox?.requested?.name || \"\""
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Input",
      "parent": 33,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugReturnRequestHistorySequence?.selectedBox?.requested?.datetime_str || \"\""
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Input",
      "parent": 35,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugReturnRequestHistorySequence?.selectedBox?.approved?.name || \"\""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Input",
      "parent": 37,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugReturnRequestHistorySequence?.selectedBox?.approved?.datetime_str || \"\""
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Input",
      "parent": 39,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugReturnRequestHistorySequence?.selectedBox?.delivered?.name || \"\""
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Input",
      "parent": 41,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugReturnRequestHistorySequence?.selectedBox?.delivered?.datetime_str || \"\""
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 56,
      "name": "Table",
      "parent": 54,
      "props": {
        "data": {
          "type": "code",
          "value": "props.items || []"
        },
        "getTheadThProps": {
          "type": "code",
          "value": "props.getTheadThProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "ลำดับ,รหัสยา,ชื่อยา,จำนวนจ่าย,จำนวนคงเหลือ,จำนวนขอคืน,จำนวนรับคืนเข้าคลัง,จำนวนจ่ายรับคืนทำลาย,เหตุผล,หน่วย"
        },
        "keys": {
          "type": "value",
          "value": "no,code,name,quantity_issue,quantity_left,quantity_request,quantity_restock,quantity_discard,reason,stock_unit_name"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\"}"
        },
        "widths": {
          "type": "value",
          "value": "50,100,,100,100,100,100,100,150,100"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"2rem\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormGroup",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "code",
          "value": "props.TopTable"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonReject"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonApprove"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 86,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonDeliver"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 58,
      "props": {
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "FormField",
      "parent": 58,
      "props": {
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "FormField",
      "parent": 58,
      "props": {
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonEdit"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormField",
      "parent": 58,
      "props": {
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonReceive"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DrugReturnRequestHistorySequence?.selectedBox?.code || \"-\""
        },
        "className": {
          "type": "value",
          "value": "ui label brown ribbon"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DrugReturnRequestHistorySequence?.selectedBox?.status_name || \"-\""
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "FormGroup",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "FormField",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "label",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้รับ"
        },
        "style": {
          "type": "code",
          "value": "{width: \"70px\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormField",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "Input",
      "parent": 98,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugReturnRequestHistorySequence?.selectedBox?.received?.name || \"\""
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน-เวลา"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Input",
      "parent": 102,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugReturnRequestHistorySequence?.selectedBox?.received?.datetime_str || \"\""
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "label",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "FormField",
      "parent": 28,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "label",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 29,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "label",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "FormField",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "label",
      "parent": 110,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Input",
      "parent": 112,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugReturnRequestHistorySequence?.selectedBox?.requested?.division || \"\""
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "FormField",
      "parent": 28,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Input",
      "parent": 114,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugReturnRequestHistorySequence?.selectedBox?.approved?.division || \"\""
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormField",
      "parent": 29,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "Input",
      "parent": 116,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugReturnRequestHistorySequence?.selectedBox?.delivered?.division || \"\""
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "FormField",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "Input",
      "parent": 118,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugReturnRequestHistorySequence?.selectedBox?.received?.division || \"\""
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "label",
      "parent": 125,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ป่วย"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "label",
      "parent": 128,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงาน"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 131,
      "name": "Label",
      "parent": 130,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DrugReturnRequestHistorySequence?.selectedBox?.patient_name_code || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#C3F4FF\", color: \"rgba(0,0,0,.87)\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "Label",
      "parent": 132,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DrugReturnRequestHistorySequence?.selectedBox?.requester_name || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#C3F4FF\", color: \"rgba(0,0,0,.87)\"}"
        }
      },
      "seq": 133,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardReturnDrugWorkingUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
