import WasmController from "react-lib/frameworks/WasmController";
// APIs
import AdmitOrderRoomItemDetail from "issara-sdk/apis/AdmitOrderRoomItemDetail_apps_ADM";
import AdmitOrderRoomItemList from "issara-sdk/apis/AdmitOrderRoomItemList_apps_ADM";
import CleaningChoiceList from "issara-sdk/apis/CleaningChoiceList_core";
import CurrentRoomByPatient from "issara-sdk/apis/CurrentRoomByPatient_apps_ADM";
import RoomList from "issara-sdk/apis/RoomList_apps_ADM";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // sequence
  AdmitChangeSequence?: {
    sequenceIndex?: string | null;

    cleaningOptions?: any[];
    newRoomOptions?: any[];
    selectedRoomSearchBox?: any;

    current_admit_order_id?: number | null;
    current_admit_order_room_item_id?: number | null;
    plan_transfer_admit_order_room_item_id?: number | null;

    patientId?: number | null;
    hn?: string;
    fullName?: string;
    birthdate?: string;

    oldRoom?: string;
    oldWardType?: string;

    cleaning?: number | null;

    room?: number | null;
    newWardType?: string;
    newRoomStatus?: string;
  } | null;
};

export const StateInitial: State = {
  // sequence
  AdmitChangeSequence: {
    sequenceIndex: null,

    cleaningOptions: [],
    newRoomOptions: [],
    selectedRoomSearchBox: null,

    current_admit_order_id: null,
    current_admit_order_room_item_id: null,
    plan_transfer_admit_order_room_item_id: null,

    patientId: null,
    hn: "",
    fullName: "-",
    birthdate: "-",

    oldRoom: "-",
    oldWardType: "-",

    cleaning: null,

    room: null,
    newWardType: "-",
    newRoomStatus: "-",
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  masterData?: { [name: string]: any };
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const [resp, error, network] = await CleaningChoiceList.list({
    apiToken: controller.apiToken,
  });

  const state = controller.getState()

  controller.setState(
    {
      AdmitChangeSequence: {
        ...state.AdmitChangeSequence,
        sequenceIndex: "Action",

        cleaningOptions: error
          ? []
          : mapOptions(resp.items, "id", "descriptive_name"),
      },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  let state = controller.getState();

  if (params?.action === "CLEAR") {
    controller.setState({
      AdmitChangeSequence: {
        ...state.AdmitChangeSequence,

        newRoomOptions: [],
        selectedRoomSearchBox: null,

        current_admit_order_id: null,
        current_admit_order_room_item_id: null,
        plan_transfer_admit_order_room_item_id: null,

        patientId: null,
        hn: "",
        fullName: "-",
        birthdate: "-",

        oldRoom: "-",
        oldWardType: "-",

        room: null,
        newWardType: "-",
        newRoomStatus: "-",
      },
    });
  } else if (params?.action === "SET_PATIENT_DATA") {
    state = controller.getState();

    if (params.data?.patientId) {
      controller.setState({
        AdmitChangeSequence: {
          ...state.AdmitChangeSequence,
          patientId: params.data?.patientId,
          hn: params.data?.hn || "",
          fullName: params.data?.fullName || "-",
          birthdate: params.data?.birthdate || "-",
        },
      }, () => getCurrentRoom(
        controller,
        {
          patientId: params.data?.patientId,
          action: params.data?.action,
          data: params.data
        }
      ));
    } else {
      controller.handleEvent({
        message: "RunSequence",
        params: { action: "CLEAR" },
      });
    }
  } else if (params?.action === "SET_NEW_ROOM") {
    state = controller.getState();

    if (params.selectedRoom?.value !== 0) {
      let newWardType: string = "-";
      let newRoomStatus: string = "-";
      let selectedRoom: any[] = []

      if (params.selectedRoom?.notFilter) {
        selectedRoom = [params.selectedRoom]
      } else {
        selectedRoom =
          state.AdmitChangeSequence?.newRoomOptions?.filter((item: any) => {
            return item.value === params.selectedRoom?.value;
          }) || [];
      }

      if (selectedRoom?.length > 0) {
        newWardType = selectedRoom[0].ward_name || "-";
        newRoomStatus = selectedRoom[0].room_status || "-";
      }

      controller.setState({
        AdmitChangeSequence: {
          ...state.AdmitChangeSequence,

          room: params.selectedRoom?.value,
          newWardType: newWardType,
          newRoomStatus: newRoomStatus,
        },
      });
    }
  } else if (params?.action === "SET_NEW_ROOM_SEARCH_BOX") {
    if (params.selectedRoom?.id !== 0) {
      controller.setState({
        AdmitChangeSequence: {
          ...state.AdmitChangeSequence,

          selectedRoomSearchBox: params?.selectedRoom,
          room: params.selectedRoom?.id,
          newWardType: params.selectedRoom?.ward_name,
          newRoomStatus: params.selectedRoom?.room_status,
        },
      });
    }
  } else if (params?.action === "SAVE") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params?.btnKey]: "LOADING",
      },
    });

    let resp = null;
    let error = null;
    let network = null;
    let data: any = {
      room: state.AdmitChangeSequence?.room,
      admit_order: state.AdmitChangeSequence?.current_admit_order_id,
      action: params.data?.action,
    };

    // ย้าย Ward
    if (params.data?.action === "RESERVE_TRANSFER") {
      if (state.AdmitChangeSequence?.plan_transfer_admit_order_room_item_id) {
        [resp, error, network] = await AdmitOrderRoomItemDetail.update({
          apiToken: controller.apiToken,
          pk: state.AdmitChangeSequence?.plan_transfer_admit_order_room_item_id,
          data: data,
        });
      } else {
        [resp, error, network] = await AdmitOrderRoomItemList.create({
          apiToken: controller.apiToken,
          data: data,
        });
      }
    }
    // ย้ายเตียง
    else if (params.data?.action === "CHANGE_BED") {
      data.cleaning = state.AdmitChangeSequence?.cleaning;

      [resp, error, network] = await AdmitOrderRoomItemDetail.update({
        apiToken: controller.apiToken,
        pk: state.AdmitChangeSequence?.current_admit_order_room_item_id,
        data: data,
      });
    }
    if (error) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params?.btnKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params?.btnKey]: error,
        },
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params?.btnKey]: "SUCCESS",
        },
        errorMessage: {
          ...state.errorMessage,
          [params?.btnKey]: null,
        },
      });
    }
  }
};

const getCurrentRoom: Handler = async (controller: any, params) => {
  const [resp, error, network] = await CurrentRoomByPatient.get({
    apiToken: controller.apiToken,
    pk: params.patientId,
  });

  const state = controller.getState();

  if (error) {
  } else {
    let roomOptions: any[] = [];

    if (
      state.django?.user?.role_types?.indexOf("ADMIT_STAFF") !== -1 &&
      params.action === "CHANGE_BED"
    ) {
      // ย้ายเตียง หน้า ADM
      if (resp.current_division_id) {
        const [respRoom, errRoom, netwRoom] = await RoomList.list({
          apiToken: controller.apiToken,
          params: { division: resp.current_division_id, can_reserve: true },
        });
        roomOptions = errRoom ? [] : mapRoomOptions(respRoom?.items);
      } else {
        roomOptions = [];
      }
    }

    controller.setState({
      AdmitChangeSequence: {
        ...state.AdmitChangeSequence,

        current_admit_order_id: resp.current_admit_order_id,
        current_admit_order_room_item_id: resp.current_admit_order_room_item_id,
        plan_transfer_admit_order_room_item_id:
          resp.plan_transfer_admit_order_room_item_id,

        oldRoom: resp.current_room_no || "-",
        oldWardType: resp.current_ward_type || "-",

        newRoomOptions: roomOptions,
        patientId: params.data?.patientId,
        hn: params.data?.hn || "",
        fullName: params.data?.fullName || "-",
        birthdate: params.data?.birthdate || "-",
      },
    });
  }
};

// utilities
const mapOptions = (list: any[], valueKey = "id", textKey = "name") => {
  return list?.map((item: any) => ({
    key: item.id,
    value: item[valueKey],
    text: item[textKey],
  }));
};

const mapRoomOptions = (list: any[]) => {
  return list?.map((item: any) => ({
    key: item.id,
    value: item.id,
    text: item.room_no,
    ward_name: item.ward_name,
    room_status: item.room_status,
  }));
};
