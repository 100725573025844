import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'

const CardDrugOrderListUX = (props: any) => {
    return(
      <div>
        
        <div
          style={{fontSize: "1.5rem", fontWeight: "bold",margin: "1rem 0.5rem 1rem"}}>
          ใบสั่งยา
        </div>
        <Table
          data={props.data}
          getTrProps={props.getTrProps}
          headers="วันเวลาที่ออกใบยา,เลขที่ใบยา,สถานะใบยา,ประเภทคนไข้,แพทย์ที่สั่ง,แผนก,ห้องยา"
          keys="requested,prescription_code,status,patient,order_by_name,order_div_name,requester_name"
          minRows={5}>
        </Table>
      </div>
    )
}

export default CardDrugOrderListUX

export const screenPropsDefault = {}

/* Date Time : Fri Apr 21 2023 08:15:20 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "วันเวลาที่ออกใบยา,เลขที่ใบยา,สถานะใบยา,ประเภทคนไข้,แพทย์ที่สั่ง,แผนก,ห้องยา"
        },
        "keys": {
          "type": "value",
          "value": "requested,prescription_code,status,patient,order_by_name,order_div_name,requester_name"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ใบสั่งยา"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.5rem\", fontWeight: \"bold\",margin: \"1rem 0.5rem 1rem\"}"
        }
      },
      "seq": 1,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugOrderListUX",
  "project": "New Project",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
