import React, {
  useEffect,
  useState,
  useRef,
  MutableRefObject,
  useMemo,
  CSSProperties,
  useCallback,
} from "react";

// UX
import CardMedicationErrorListUX from "./CardMedicationErrorListUX";
import CardMedicationErrorWorking from "./CardMedicationErrorWorking";
import ModSelectQuarter from "./ModSelectQuarter";

// Common
import SearchBox from "react-lib/apps/common/SearchBox";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import DropdownOptions from "react-lib/appcon/common/DropdownOptions";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import { ModInfo, ErrorMessage, DateTextBox } from "react-lib/apps/common";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";
import { Button, Checkbox, Icon, Modal } from "semantic-ui-react";

// types
type CardMedicationErrorListProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: any;
  // data
  filterMedError?: Record<string, any>;
  medErrorList?: any[];
  userId?: number;
  medErrorWorking?: Record<string, any>;
  // options
  masterOptions?: Record<string, any[]>;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  django?: Record<string, any>;
  selectedDivision?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  // config
  allowedPrint?: boolean;
};

// Const
const CenterStyle = {
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
} as CSSProperties;

const BUTTON_ACTIONS = {
  SEARCH: "search",
  PRINT: "print",
};

const CARD_MEDICATION_ERROR_LIST = "CardMedicationErrorList";

const CardMedicationErrorList = (props: CardMedicationErrorListProps) => {
  // Data
  const [quarterDetail, setQuarterDetail] = useState<any>({});
  // Selected
  const [selectedEdit, setSelectedEdit] = useState<any>(null);
  const [selectedDelete, setSelectedDelete] = useState<any>(null);
  // Checked
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  // Mod
  const [openModInfo, setOpenModInfo] = useState<any>(null);
  const [openModFeedbackReport, setOpenModFeedbackReport] =
    useState<boolean>(false);

  const userRef = useRef() as MutableRefObject<any>;
  const staffRef = useRef() as MutableRefObject<any>;

  useEffect(() => {
    props.runSequence({
      sequence: "MedErrorList",
      byCorrective: !props.allowedPrint,
    });
    return () => {
      props.setProp("MedErrorListSequence.sequenceIndex", "START");
    };
  }, []);

  useEffect(() => {
    userRef.current?.setId(props.filterMedError?.stakeholder);
    userRef.current?.setText(props.filterMedError?.stakeholder_name);
  }, []);

  // Memo Callback
  const handleCheck = useCallback(
    (e: any, data: any) => {
      let ids = [...checkedIds];
      if (data.checked) {
        ids.push(data.name);
      } else {
        ids = ids.filter((id) => id !== data.name);
      }

      setCheckedIds(ids);
    },
    [checkedIds]
  );

  // Callback
  const mapUserOptions = useCallback((items: any) => {
    return items.map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.full_name,
    }));
  }, []);

  const handleSelectedItem = useCallback(
    async (value: any, key: any, obj: any) => {
      let user = props.searchedItemListWithKey?.User_1?.find(
        (item: any) => item.id === key
      );

      if (!user?.id) {
        await props.setProp(`searchedItemListWithKey.User_1`, []);
      }

      handleChange(null, {
        name: "stakeholder",
        value: user?.id ? { id: user.id, full_name: user.full_name } : null,
      });
    },
    [props.searchedItemListWithKey]
  );

  const handlePrint = useCallback(
    (item: any) => (e: any) => {
      props.runSequence({
        sequence: "MedErrorList",
        action: "print",
        list: [item],
      });
    },
    [quarterDetail]
  );

  const medErrorItems = useMemo(() => {
    return (props.medErrorList || []).map((item: any, index: number) => {
      const editable =
        props.django?.user?.role_types?.includes("PHARMACIST") ||
        item.stakeholder === props.userId ||
        item.corrective_user === props.userId ||
        item.corrective_division === props.selectedDivision?.id ||
        item.editable;

      const isDelete = editable && item.status === "INCOMPLETE";

      const centerFormat =
        "type_name,type_detail,cause_detail,stakeholder_name,status"
          .split(",")
          .reduce(
            (a, k) => ({ ...a, [k]: <div style={CenterStyle}>{item[k]}</div> }),
            {} as any
          );

      return {
        ...item,
        chk: (
          <div style={{ display: "grid", placeContent: "center" }}>
            <Checkbox
              checked={checkedIds.includes(item.id)}
              name={item.id}
              onChange={handleCheck}
            />
          </div>
        ),
        datetime: <div style={CenterStyle}>{formatDatetime(item.created)}</div>,
        no: <div style={CenterStyle}>{index + 1}</div>,
        drug_name: (
          <div style={CenterStyle}>{`[${item.drug_code || ""}][${
            item.drug_name || ""
          }]`}</div>
        ),
        ...centerFormat,
        action: (
          <div style={CenterStyle}>
            {props.allowedPrint && (
              <Icon
                name="print"
                color="black"
                disabled={!item.quarter_no}
                // size="mini"
                style={{
                  margin: "0 0.5rem",
                  cursor: item.quarter_no ? "pointer" : "",
                }}
                onClick={handlePrint(item)}
              />
            )}

            {editable ? (
              <Icon
                name={item.status === "INCOMPLETE" ? "edit outline" : "eye"}
                color={item.status === "INCOMPLETE" ? "yellow" : "black"}
                style={{ margin: "0 0.5rem", cursor: "pointer" }}
                onClick={() => {
                  props.runSequence({
                    sequence: "MedErrorList",
                    action: "edit",
                    callback: () =>
                      setSelectedEdit({
                        ...item,
                        readOnly: item.status === "INCOMPLETE" ? false : true,
                      }),
                  });
                }}
              />
            ) : (
              <Icon
                name="edit outline"
                color="grey"
                disabled={true}
                style={{ margin: "0 0.5rem", cursor: "pointer" }}
              />
            )}

            {props.allowedPrint && (
              <Icon
                name="trash alternate"
                color={!isDelete ? "grey" : "red"}
                disabled={!isDelete}
                style={{ margin: "0 0.5rem", cursor: "pointer" }}
                onClick={() => setSelectedDelete(item)}
              />
            )}
          </div>
        ),
      };
    });
  }, [
    props.medErrorList,
    props.userId,
    checkedIds,
    quarterDetail,
    props.django,
  ]);

  const isCorrectiveDiv = useMemo(() => {
    const data = props.medErrorWorking || {};
    const correctiveDiv =
      data.detail?.corrective_division &&
      data.detail?.corrective_division === props.selectedDivision?.id;

    return correctiveDiv;
  }, [props.medErrorWorking?.detail, props.userId]);

  const isActionComplete = useMemo(() => {
    const data = props.medErrorWorking || {};

    const correctiveUser =
      data.detail?.corrective_user === Number(props.userId) &&
      data.detail?.corrective_user;

    return isCorrectiveDiv || correctiveUser;
  }, [props.medErrorWorking?.detail, props.userId, isCorrectiveDiv]);

  const divisionDrugOptions = useMemo(() => {
    return [
      {
        key: "ALL",
        text: "แสดงทั้งหมด",
        value: "ALL",
      },
      ...(props.masterOptions?.division || []),
    ];
  }, [props.masterOptions?.division]);

  const handleCheckAll = useCallback(
    (e: any, data: any) => {
      let ids = [...checkedIds];

      if (data.checked) {
        ids = medErrorItems.map((item: any) => item.id);
      } else {
        ids = [];
      }

      setCheckedIds(ids);
    },
    [checkedIds, medErrorItems]
  );

  const headers = useMemo(() => {
    const list: any[] = [
      "ลำดับ",
      "วัน-เวลาที่รายงาน",
      "รายการที่คลาดเคลื่อน",
      "ประเภท",
      "ลักษณะ",
      "สาเหตุ",
      "ผู้ที่ทำให้เกิดความผิดพลาด",
      "สถานะ",
      "Action",
    ];

    if (props.allowedPrint) {
      list.unshift(
        <Checkbox
          checked={
            medErrorItems.length === checkedIds.length && !!medErrorItems.length
          }
          onChange={handleCheckAll}
        />
      );
    }

    return list;
  }, [checkedIds, medErrorItems]);

  const handleChange = (e: any, v: any) => {
    props.runSequence({
      sequence: "MedErrorList",
      action: "change",
      data: {
        ...(props.filterMedError || {}),
        [v.name]: v.value,
        ...(v.name === "stakeholder"
          ? {
              stakeholder: v.value?.id || null,
              stakeholder_name: v.value?.full_name || null,
            }
          : {}),
      },
    });
  };

  const handleGetSearchOptions = (data: any) => {
    return props.onEvent({
      message: "GetSearchUserList",
      params: { search: data.searchText },
    });
  };

  const handleRefresh = (e: any, data: any) => {
    props.runSequence({
      sequence: "MedErrorList",
      action: "search",
      card: CARD_MEDICATION_ERROR_LIST,
      byCorrective: !props.allowedPrint,
    });
  };

  const handleApprove = () => {
    handleClose();

    props.runSequence({
      sequence: "MedErrorList",
      action: "delete",
      data: {
        id: selectedDelete?.id,
      },
      byCorrective: !props.allowedPrint,
      callback: (status: string, message: any) => {
        setOpenModInfo({ status, message });
        if (status === "success") {
          props.runSequence({
            sequence: "MedErrorList",
            restart: true,
            filter: props.filterMedError,
          });
        }
      },
    });
  };

  const handleClose = () => {
    setSelectedDelete(null);
  };

  const onCloseModSuccess = () => {
    setOpenModInfo(null);
  };

  const handleCloseModEdit = () => {
    setSelectedEdit(null);
    props.runSequence({ sequence: "MedErrorList", action: "close" });
  };

  const handleEdited = () => {
    handleCloseModEdit();
    props.runSequence({
      sequence: "MedErrorList",
      restart: true,
      filter: props.filterMedError,
      byCorrective: !props.allowedPrint,
    });
  };

  const handleChangeDate = (name: string) => (value: string) => {
    handleChange(null, { value, name });
  };

  const handleGetStaffOptions = (data: any) => {
    return props.onEvent({
      message: "GetSearchUserList",
      params: { search: data.searchText },
    });
  };

  const handleOpenModFeedbackReport = () => {
    setOpenModFeedbackReport(true);
  };

  const handleCloseModFeedbackReport = () => {
    setOpenModFeedbackReport(false);
  };

  const handleSaveQuarter = (detail: any) => {
    setQuarterDetail(detail);
    handleCloseModFeedbackReport();
  };

  const handleExportExcel = () => {
    props.runSequence({
      sequence: "MedErrorList",
      action: "export_excel",
    });
  };

  const handleClear = () => {
    props.runSequence({
      sequence: "MedErrorList",
      action: "change",
      data: {
        division: "ALL",
        start_date: "",
        end_date: "",
      },
    });
  };

  const handlePrintReport = () => {
    props.runSequence({
      sequence: "MedErrorList",
      action: "print",
      list: (props.medErrorList || []).filter((item: any) =>
        checkedIds.includes(item.id)
      ),
      quarterDetail,
      card: CARD_MEDICATION_ERROR_LIST,
    });
  };

  const handleClickStatistics = async () => {
    await props.setProp("MedErrorListSequence.medErrorStatistics", {
      open: true,
      start_date: props.filterMedError?.start_date,
      end_date: props.filterMedError?.end_date,
      items: [],
    });

    props.runSequence({
      sequence: "MedErrorList",
      action: "statistics_search",
      card: CARD_MEDICATION_ERROR_LIST,
    });
  };

  const handleClearQuarter = () => {
    setQuarterDetail({});
  };

  console.log("CardMedicationErrorListUX", props);

  return (
    <div style={{ padding: props.allowedPrint ? "" : "0 5px" }}>
      <CardMedicationErrorListUX
        // data
        filterMedError={props.filterMedError}
        medErrorList={medErrorItems}
        quarter={`${quarterDetail.quarter || ""}${
          quarterDetail.quarter || quarterDetail.year ? "/" : ""
        }${quarterDetail.year || ""}`}
        // options
        divisionDrugOptions={divisionDrugOptions}
        // config
        allowedPrint={props.allowedPrint}
        // callback
        onChange={handleChange}
        onRefresh={handleRefresh}
        onOpenModFeedbackReport={handleOpenModFeedbackReport}
        onExportExcel={handleExportExcel}
        onClear={handleClear}
        onClickStatistics={handleClickStatistics}
        onClearQuarter={handleClearQuarter}
        // Header
        headers={headers}
        // Element
        userSearch={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "60px" }}>ผู้บันทึก</div>
            <SearchBox
              ref={userRef}
              key="searchBoxMedicationError"
              onGetSearchOptions={handleGetSearchOptions}
              textField="full_name"
              fluid={true}
              onSelectOption={(option: any) => {
                setTimeout(() => {
                  handleChange(null, { name: "user", value: option.id });
                });
              }}
            />
          </div>
        }
        StartDate={
          <DateTextBox
            value={props.filterMedError?.start_date || ""}
            inputFluid={true}
            inputStyle={{ width: "100%" }}
            style={{ width: "100%" }}
            onChange={handleChangeDate("start_date")}
          />
        }
        EndDate={
          <DateTextBox
            value={props.filterMedError?.end_date || ""}
            inputFluid={true}
            inputStyle={{ width: "100%" }}
            style={{ width: "100%" }}
            onChange={handleChangeDate("end_date")}
          />
        }
        statusDropdown={
          <DropdownOptions
            value={props.filterMedError?.status || ""}
            options={props.masterOptions?.medErrorStatus}
            name={"status"}
            fluid={true}
            multiple={true}
            search={true}
            style={{ width: "100%" }}
            onChange={handleChange}
          />
        }
        typeDropdown={
          <DropdownOptions
            value={props.filterMedError?.type || ""}
            options={props.masterOptions?.medErrorType}
            name={"type"}
            fluid={true}
            multiple={true}
            search={true}
            style={{ width: "100%" }}
            onChange={handleChange}
          />
        }
        SearchBox={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="User"
            id="1"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={props.filterMedError?.stakeholder || null}
            setSelectedItem={handleSelectedItem}
            // options
            mapOptions={mapUserOptions}
          />
        }
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleRefresh}
            // data
            paramKey={`${CARD_MEDICATION_ERROR_LIST}_${BUTTON_ACTIONS.SEARCH}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_MEDICATION_ERROR_LIST}_${BUTTON_ACTIONS.SEARCH}`
              ]
            }
            // config
            color={"blue"}
            name={BUTTON_ACTIONS.SEARCH}
            size="small"
            title="ค้นหา"
          />
        }
        ButtonPrintReport={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handlePrintReport}
            // data
            paramKey={`${CARD_MEDICATION_ERROR_LIST}_${BUTTON_ACTIONS.PRINT}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_MEDICATION_ERROR_LIST}_${BUTTON_ACTIONS.PRINT}`
              ]
            }
            // config
            disabled={!checkedIds.length || !quarterDetail.quarter}
            color={"green"}
            name={BUTTON_ACTIONS.PRINT}
            size="small"
            title="พิมพ์รายงาน Feedback"
            style={{ minWidth: "max-content" }}
          />
        }
      />
      <ModConfirm
        openModal={!!selectedDelete}
        content={<>ต้องการยกเลิกรายการนี้</>}
        onApprove={handleApprove}
        onDeny={handleClose}
      />
      <ModInfo
        open={!!openModInfo}
        titleColor={openModInfo?.status === "success" ? "green" : "red"}
        titleName={
          openModInfo?.status === "success" ? "ยกเลิกรายการสำเร็จ" : ""
        }
        onApprove={onCloseModSuccess}
        style={{ top: "calc(50vh - 130px)" }}
      >
        {openModInfo?.status === "error" && (
          <ErrorMessage error={openModInfo?.message} />
        )}
      </ModInfo>
      <Modal
        open={!!selectedEdit}
        closeOnDimmerClick={true}
        closeIcon={true}
        onClose={handleCloseModEdit}
      >
        <CardMedicationErrorWorking
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // data
          medErrId={selectedEdit?.id}
          medErrorWorking={props.medErrorWorking || {}}
          selectedDivision={props.selectedDivision}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // options
          masterOptions={props.masterOptions}
          // config
          showSolution={isActionComplete && !isCorrectiveDiv}
          isActionComplete={isActionComplete}
          readOnly={!!selectedEdit?.readOnly}
          disabledEdit={isCorrectiveDiv}
          editMode={true}
          hideEditQuarter={!props.allowedPrint || !!selectedEdit?.readOnly}
          // callback
          onEdited={handleEdited}
        />
      </Modal>

      <Modal
        open={openModFeedbackReport}
        closeOnDimmerClick={true}
        // callback
        onClose={handleCloseModFeedbackReport}
      >
        <ModSelectQuarter
          detail={quarterDetail}
          onSave={handleSaveQuarter}
          // callback
          onClose={handleCloseModFeedbackReport}
        />
      </Modal>
    </div>
  );
};

export default React.memo(CardMedicationErrorList);
