import React, { useState, useCallback, useEffect } from "react";
import { Checkbox, Modal } from "semantic-ui-react";

// Common
import ModConfirmCNMI from "react-lib/apps/common/cnmi/ModConfirm";

// Types
type ModDrugPackageAlertProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: any;
  // data
  items?: {
    id: number;
    drug_code: string;
    drug_name: string;
    quantity: string;
    created_at: string;
    edited_at: string;
    created_by: number;
    edited_by: number;
    encounter: number;
    drug: number;
    appointment_package_binder: number;
    to_order_item: any;
  }[];
  card: string;
  orderType: string;
  // callback
  onClose?: () => any;
};

const ModDrugPackageAlert = (props: ModDrugPackageAlertProps) => {
  const [checkedIds, setCheckedIds] = useState<number[]>([]);

  useEffect(() => {
    setCheckedIds([])
  }, [props.items])
  

  const handleChecked = useCallback(
    (id: number) => (_e: any, data: any) => {
      setCheckedIds((checked) =>
        data.checked
          ? [...checked, id]
          : checked.filter((value) => value !== id)
      );
    },
    []
  );

  const handleAddToOrder = () => {
    props.onEvent({
      message: "DrugPackageAddToOrder",
      params: {
        items: (props.items || []).filter((item) =>
          checkedIds.includes(item.id)
        ),
        orderType: props.orderType,
        card: props.card,
      },
    });
  };

  return (
    <ModConfirmCNMI
      openModal={!!props.items?.length}
      titleName="รายการยาใน Package"
      backgroundColor="var(--primary-theme-color)"
      denyButtonColor="green"
      denyButtonText="Add to order"
      closeIcon={true}
      disabledDenyButton={!checkedIds.length}
      // Style
      denyButtonStyle={{ minWidth: "max-content", marginLeft: "-1rem" }}
      // callback
      onCloseWithDimmerClick={props.onClose}
      onDeny={handleAddToOrder}
      // Element
      approveButton={<></>}
      content={
        <div
          style={{
            margin: "-0.5rem 0px -2rem 0.5rem",
          }}
        >
          <>
            {props.items?.map((item) => (
              <div style={{ paddingBottom: "1rem" }}>
                <Checkbox
                  checked={checkedIds.includes(item.id)}
                  label={`[${item.drug_code}] ${item.drug_name}`}
                  onChange={handleChecked(item.id)}
                />
              </div>
            ))}
          </>
        </div>
      }
    ></ModConfirmCNMI>
  );
};

export default React.memo(ModDrugPackageAlert);
