import React, { useEffect, useState, useCallback } from "react";
import { Button, Icon } from "semantic-ui-react";

type ButtonLoadCheckProps = {
  // function
  setProp: any;
  onClick?: any;
  // data
  paramKey?: string;
  buttonLoadCheck?: string;
  // config
  color?: string;
  disabled?: boolean;
  fluid?: boolean;
  name?: string;
  size?: string;
  style?: any;
  iconStyle?: any;
  title?: any;
  basic?: boolean;
  className?: string;
  icon?: string;
  id?: string;
};

const ButtonLoadCheckInitial: ButtonLoadCheckProps = {
  // function
  setProp: () => null,
  onClick: () => null,
  // data
  paramKey: "",
  buttonLoadCheck: "",
  // config
  color: "",
  disabled: false,
  fluid: false,
  name: "",
  size: "small",
  style: {},
  iconStyle: {},
  title: "",
  basic: false,
  className: "",
  icon: "",
  id: "",
};

const ButtonLoadCheck: React.FC<ButtonLoadCheckProps> = (props: any) => {
  const [iconName, setIconName] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonColor, setButtonColor] = useState<string>("");

  const handleReset = useCallback(() => {
    setTimeout(() => {
      setIconName(null);
      setButtonColor("");
      props.setProp(`buttonLoadCheck.${props.paramKey}`, "");
    }, 2000);
  }, []);

  useEffect(() => {
    if (props.buttonLoadCheck === "LOADING") {
      setLoading(true);
      setIconName(null);
    } else if (props.buttonLoadCheck === "SUCCESS") {
      setLoading(false);
      setIconName("check");

      handleReset();
    } else if (props.buttonLoadCheck === "ERROR") {
      setLoading(false);
      setIconName("cancel");
      setButtonColor("red");

      handleReset();
    } else if (!props.buttonLoadCheck) {
      setLoading(false);

      handleReset();
    }
  }, [props.buttonLoadCheck]);

  return (
    <Button
      id={props.id}
      className={props.className}
      color={buttonColor || props.color}
      disabled={props.disabled}
      fluid={props.fluid}
      loading={loading}
      name={props.name}
      onClick={props.onClick}
      size={props.size}
      style={props.style}
      basic={props.basic}
    >
      {iconName ? (
        <Icon name={iconName as any} style={props.iconStyle}></Icon>
      ) : (
        <>{props.title}</>
      )}
    </Button>
  );
};

ButtonLoadCheck.defaultProps = ButtonLoadCheckInitial;

export default React.memo(ButtonLoadCheck);
