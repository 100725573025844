
import moment from "moment";
import { adToBe } from "react-lib/utils/dateUtils";

// const FIRST_PAGE_TOP = 325;
// const ANOTHER_PAGE_TOP = 345;
// const BOTTOM = 364;
const BACKEND_DATE_FORMAT = "YYYY-MM-DD"
const BE_DATE_FORMAT = "DD/MM/YYYY"

const FormLabCrossMatchQueue = (props: any) => {
    console.log("FormLabCrossMatchQueue", props);

    const generatedTableRow = (data: any) => {
        let rowTable: any[] = [
            [
                { text: "ลำดับ", style: "fieldKey", alignment: "center" },
                { text: "วันที่/เวลานัด", style: "fieldKey", alignment: "center" },
                { text: "HN/AN", style: "fieldKey", alignment: "center" },
                { text: "ชื่อ - สกุลผู้ป่วย", style: "fieldKey", alignment: "center" },
                { text: "เบอร์โทรศัพท์", style: "fieldKey", alignment: "center" },
                { text: "แพทย์ผู้สั่ง", style: "fieldKey", alignment: "center" },
                { text: "นัดเพื่อ", style: "fieldKey", alignment: "center" },
            ]
        ]

        data.forEach((item: any, index: number) => {
            rowTable.push([
                { text: index + 1, alignment: "center" },
                {
                    stack: [
                        { text: item.date ? adToBe(moment(item.date, BACKEND_DATE_FORMAT).format(BE_DATE_FORMAT)) : "", alignment: "center" },
                        { text: item.time ? moment(item.time, "HH:mm:sss").format("[[]HH:mm[]]") : "", alignment: "center" },
                    ]
                },
                {
                    stack: [
                        { text: item.hn, alignment: "center" },
                        { text: item.an, alignment: "center" }
                    ]
                },
                { text: item.patient_name },
                { text: item.phone_number ? item.phone_number : "-", alignment: "center" },
                { text: item.doctor_name },
                {
                    stack: [
                        { text: item.blood_components, alignment: "center" },
                        { text: item.note, alignment: "center" }
                    ]
                }
            ])
        })
        return rowTable
    }

    return {
        pageSize: "A4",
        pageOrientation: "portrait",
        defaultStyle: {
            font: "THSarabunNew",
            lineHeight: 1,
            fontSize: 14
        },
        pageMargins: [10, 120, 10, 45],
        styles: {
            facultyHeader: {
                fontSize: 20,
                bold: true
            },
            fieldKey: {
                bold: true,
                fontSize: 15
            },
            fieldValue: {
                fontSize: 15
            },
            miniFieldKey: {
                bold: true,
                fontSize: 13
            },
            miniFieldValue: {
                fontSize: 13
            }
        },
        header: (currentPage: number, pageCount: number) => {
            return {
                margin: [10, 5, 10, 0],
                stack: [
                    {
                        columns: [
                            {
                                width: "20%",
                                stack: [
                                    {
                                        margin: [0, 25, 0, 0],
                                        width: 110,
                                        image: "logochula",
                                        alignment: "center"
                                    }
                                ]
                            },
                            {
                                width: "65%",
                                stack: [
                                    {
                                        text: " ",
                                        style: "facultyHeader",
                                        alignment: "center"
                                    },
                                    {
                                        text: "CHULALONGKORN UNIVERSITY, FACULTY OF DENTISTRY",
                                        style: "facultyHeader",
                                        alignment: "center"
                                    },
                                    {
                                        text: "ห้องปฏิบัติการ รพ.คณะฯ (ห้องตรวจเลือด)",
                                        style: "fieldKey",
                                        alignment: "center"
                                    },
                                    {
                                        text: "รายงานสรุปผู้ป่วยที่ต้องการทำ Cross Match",
                                        style: "fieldKey",
                                        alignment: "center"
                                    },
                                    {
                                        text: [
                                            { text: "วันที่ " },
                                            { text: props.start_date },
                                            { text: " - " },
                                            { text: props.end_date }
                                        ],
                                        style: "fieldKey",
                                        alignment: "center"
                                    }
                                ]
                            }
                        ]
                    },
                ]
            };
        },
        content: [
            {
                margin: [10, 0, 10, 0],
                table: {
                    headerRows: 1,
                    dontBreakRows: true,
                    widths: ["5%", "15%", "10%", "20%", "15%", "15%", "20%"],
                    body: generatedTableRow(props.labData)
                }
            }
        ],
        footer: function (currentPage: number, pageCount: number) {
            return {
                margin: [10, 0, 10, 0],
                stack: [
                    {
                        columns: [
                            {
                                width: "50%",
                                text: [
                                    { text: "วันเวลาที่พิมพ์: ", style: "fieldKey" },
                                    { text: adToBe(moment().format(BE_DATE_FORMAT)), style: "fieldKey" },
                                    { text: " " + moment().format("[[]HH:mm[]]"), style: "fieldKey" }
                                ]
                            },
                            {
                                width: "50%",
                                alignment: "right",
                                text: [
                                    { text: "หน้าที่ ", style: "fieldKey" },
                                    { text: currentPage + "/", style: "fieldKey" },
                                    { text: pageCount, style: "fieldKey" }
                                ]
                            }
                        ]
                    },
                    {
                        text: [
                            { text: "ผู้พิมพ์เอกสาร: ", style: "fieldKey" },
                            { text: props.printed_user, style: "fieldKey" }
                        ]
                    }
                ]
            };
        },
        images: {
            logo: origin + "/static/images/IHimage.png",
            logochula: origin + "/static/images/logochula_dent.jpg",
        }
    };
}

export default FormLabCrossMatchQueue;