import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Segment,
  Label,
  Dropdown,
  Button,
  FormField,
  TextArea,
  Icon
} from 'semantic-ui-react'

const CardMedicationErrorWorkingUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width : "100%", height: "100%" }}>
        <Segment>
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","rowGap":"10px","gridTemplateColumns":"repeat(6, 1fr)"}}>
            <div
              style={{gridArea: "1 / 1 / 2 / 5", display: "flex", alignItems: "center"}}>
              
              <label
                style={{fontSize: "1.2rem", fontWeight: "bold"}}>
                {props.title ? props.title : "รายงานอุบัติการณ์ความเสี่ยงและความคลาดเคลื่อนทางยา (Feedback Report)"}
              </label>
            </div>
            <div
              style={{gridArea: "1 / 5 / 2 / 7", display: "flex", justifyContent: "flex-end", alignItems: "baseline"}}>
              
              <Label>
                {props.status}
              </Label>
            </div>
            <div
              style={{gridArea: "2/ 1 / 3 / 7"}}>
              
              <div
                style={{display: "grid", width: "100%"}}>
                
                <div
                  style={{width: "100%"}}>
                  
                  <hr>
                  </hr>
                </div>
                <div>
                  {props.errorMessage}
                </div>
              </div>
              <div
                style={{display: !!props.quarterDetail ? "flex" :"none", flexDirection: "column", alignItems: "center", lineHeight: 1.75, margin: "0.25rem 0"}}>
                
                <div>
                  {}
                </div>
                <div
                  style={{display: "flex"}}>
                  
                  <div
                    style={{fontWeight: "bold"}}>
                    {`ไตรมาสที่ ${props.quarterDetail?.quarter || ""}${props.quarterDetail?.quarter || props.quarterDetail?.year ? "/" : ""}${props.quarterDetail?.year || ""}`}
                  </div>
                  <div
                    style={{cursor: "pointer", marginLeft: "1rem", marginTop: "-1px", display: props.hideEditQuarter ? "none":""}}>
                    
                    <Icon
                      className="yellow"
                      name="edit"
                      onClick={props.onOpenModSelectQuarter}>
                    </Icon>
                  </div>
                </div>
                <div
                  style={{fontWeight: "bold"}}>
                  {`ระหว่างวันที่ ${props.quarterDetail?.startDate || ""}${props.quarterDetail?.startDate || props.quarterDetail?.endDate ? " - " : ""}${props.quarterDetail?.endDate || ""}`}
                </div>
              </div>
            </div>
            <div
              style={{gridArea: "3/ 1 / 4 / 2", display: "flex", marginTop: "5px"}}>
              
              <label>
                รายการที่คลาดเคลื่อน
              </label>
            </div>
            <div
              style={{gridArea: "3/ 2 / 4 / 7"}}>
              
              <div
                className="ui label large"
                style={{display: "flex"}}>
                {`[${props.drugCode || ""}][${props.drugName || ""}]`}
              </div>
            </div>
            <div
              style={{gridArea: "4/ 1 / 5 / 2", display: "flex", alignItems: "center"}}>
              
              <label>
                ชื่อ - สกุลผู้ป่วย
              </label>
            </div>
            <div
              style={{gridArea: "4/ 2 / 5 / 4"}}>
              
              <div
                className="ui label large"
                style={{display: "flex"}}>
                {props.patientName || "-"}
              </div>
            </div>
            <div
              style={{gridArea: "4 / 4 / 5 / 5", display: "flex", alignItems: "center"}}>
              
              <label
                style={{marginLeft: props.editMode ? "25%" : "35%"}}>
                หน่วยงานที่สั่ง
              </label>
            </div>
            <div
              style={{gridArea: "4 / 5 / 5 / 7"}}>
              
              <div
                className="ui label large"
                style={{display: "flex"}}>
                {props.divisionName || "-"}
              </div>
            </div>
            <div
              style={{gridArea: props.editMode ? "6/ 1 / 7 / 2": "5/ 1 / 6 / 2", display: "flex", alignItems: "center"}}>
              
              <label
                style={{color: "red"}}>
                ผู้ทำให้เกิด Error*
              </label>
            </div>
            <div
              style={{gridArea: props.editMode ? "6 / 2 / 7 / 4":"5/ 2 / 6 / 4"}}>
              
              <div>
                {props.userSearch}
              </div>
            </div>
            <div
              style={{gridArea: "5 / 4 / 6 / 5", alignItems: "center", display: props.editMode ? "none" : "flex"}}>
              
              <label
                style={{marginLeft: "35%"}}>
                Risk
              </label>
            </div>
            <div
              style={{gridArea: "5 / 5 / 6 / 7", display: props.editMode ? "none" : ""}}>
              
              <Dropdown
                fluid={true}
                name="risk_type"
                onChange={props.onChange}
                options={props.riskTypeOptions}
                selection={true}
                value={props.riskType}>
              </Dropdown>
            </div>
            <div
              style={{gridArea: props.editMode ? "5 / 4 / 6 / 5": "6/ 1 / 7 / 2", display: "flex", alignItems: "center"}}>
              
              <label
                style={{color: "red",marginLeft: props.editMode ? "25%" : "", lineHeight: 1.35}}>
                {props.editMode ? "ประเภทความเสี่ยง":"ประเภท*"}
              </label>
            </div>
            <div
              style={{gridArea:  props.editMode ? "5 / 5 / 6 / 7" :  "6 / 2 / 7 / 4"}}>
              
              <Dropdown
                fluid={true}
                name="type"
                onChange={props.onChange}
                options={props.typeOptions}
                selection={true}
                value={props.type}>
              </Dropdown>
            </div>
            <div
              style={{gridArea: "6 / 4 / 7 / 5", display: "flex", alignItems: "center"}}>
              
              <label
                style={{marginLeft: props.editMode ? "25%" : "35%", lineHeight: 1.35}}>
                {props.editMode ? "ผลกระทบที่อาจเกิดขึ้น" :"Category"}
              </label>
              <div>
                {props.Tooltip}
              </div>
            </div>
            <div
              style={{gridArea: "6/ 5 / 7/ 7"}}>
              
              <Dropdown
                fluid={true}
                name="category"
                onChange={props.onChange}
                options={props.categoryOptions}
                selection={true}
                value={props.category}>
              </Dropdown>
            </div>
            <div
              style={{gridArea: "7 / 1 / 8 / 2", display: "flex", alignItems: "center"}}>
              
              <label
                style={{color: "red"}}>
                {props.editMode ? "หัวข้อความเสี่ยงเรื่อง *" : "ลักษณะ*"}
              </label>
            </div>
            <div
              style={{gridArea: "7/ 2 / 8/ 7"}}>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="type_detail"
                onAddItem={props.onAddOptions}
                onChange={props.onChange}
                options={props.typeDetailOptions}
                search={true}
                selection={true}
                value={props.typeDetail}>
              </Dropdown>
            </div>
            <div
              style={{gridArea: "8/ 1 / 9 / 2", display: props.editMode ?"none":"flex", alignItems: "center"}}>
              
              <label>
                สาเหตุ
              </label>
            </div>
            <div
              style={{gridArea: "8 / 2 / 9 / 7",display: props.editMode ?"none":"",}}>
              
              <Dropdown
                fluid={true}
                multiple={true}
                name="cause"
                onChange={props.onChange}
                options={props.causeOptions}
                selection={true}
                value={props.cause}>
              </Dropdown>
            </div>
            <div
              style={{gridArea: props.editMode? "8 / 1 / 10 / 2":"9 / 1 / 10 / 2", display: "flex", alignItems: "center"}}>
              
              <label>
                รายละเอียด
              </label>
            </div>
            <div
              style={{gridArea: props.editMode ? "8 / 2 / 10 / 7":"9 / 2 / 10 / 7"}}>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="cause_detail"
                onAddItem={props.onAddOptions}
                onChange={props.onChange}
                options={props.causeDetailOptions}
                search={true}
                selection={true}
                value={props.causeDetail}>
              </Dropdown>
            </div>
            <div
              className="ui form"
              style={{gridArea: "10/ 1 /11/ 2", display: "flex", alignItems: "center"}}>
              
              <label
                style={{marginTop: "-0.95rem"}}>
                {props.editMode ? "ผู้ดำเนินการแก้ไข" : "วันที่พบปัญหา"}
              </label>
            </div>
            <div
              className="ui form"
              style={{gridArea: "10/ 2 /11 / 7", display: "flex", alignItems: "center"}}>
              
              <FormField
                className={props.editMode ? "hidden" : ""}
                inline={true}>
                <div
                  style={{width: "100%"}}>
                  {props.StartDate}
                </div>
              </FormField>
              <FormField
                className={props.editMode ? "hidden":""}
                inline={true}>
                <label
                  style={{color: "red", padding: "0 1rem"}}>
                  ผู้ดำเนินการแก้ไข*
                </label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Dropdown
                  fluid={true}
                  name="editor_by"
                  onChange={props.onChange}
                  options={[{key: 1 ,text: "คลินิก", value:"CLINIC"},{key: 2 ,text: "เจ้าหน้าที่", value:"STAFF"}]}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.editorBy || ""}>
                </Dropdown>
              </FormField>
              <FormField>
                <div
                  style={{width: "100%"}}>
                  {props.staffSearch}
                </div>
              </FormField>
            </div>
            <div
              style={{gridArea: "11/ 1 / 13 / 2", display: props.showSolution ? "flex":"none", alignItems: "baseline"}}>
              
              <label
                style={{paddingTop: "5px"}}>
                แนวทางการแก้ไข
              </label>
            </div>
            <div
              className="ui form"
              style={{gridArea: "11/ 2/ 13/ 7", display: props.showSolution ? "flex":"none", alignItems: "center"}}>
              
              <TextArea
                name="solution_note"
                onChange={props.onChange}
                value={props.solutionNote || ""}>
              </TextArea>
            </div>
            <div
              style={{gridArea: props.showSolution ? "13/ 1 / 14/ 7":"11/ 1 / 12/ 7", display: "flex", justifyContent: "flex-end", padding: "5px 0"}}>
              
              <Button
                color={props.detail.pk ? "yellow" : "green"}
                disabled={props.disabledEdit || false}
                onClick={props.onEdit}>
                {props.detail.pk ? "EDIT" : "NOTE"}
              </Button>
            </div>
            <div
              style={{gridArea: "5/ 1 / 6 / 2",  display: props.editMode ? "flex":"none", alignItems: "center"}}>
              
              <label>
                วันที่เกิดเหตุ
              </label>
            </div>
            <div
              style={{gridArea: "5/ 2 / 6 / 4",  display: props.editMode ? "flex":"none", alignItems: "center"}}>
              
              <div
                className="ui label large"
                style={{flex: 1}}>
                {props.incidentDate || "-"}
              </div>
            </div>
          </div>
        </Segment>
      </div>
    )
}

export default CardMedicationErrorWorkingUX

export const screenPropsDefault = {"cannotPay":false,"cashAmountText":"14","citizenID":"31020000552","oweText":123,"patientNameAndHN":"HN: xxxxx นายทดสอบ","sumAmount":{"price":100,"reimbursable":42}}

/* Date Time : Mon Apr 24 2023 03:06:34 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width : \"100%\", height: \"100%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Segment",
      "parent": 0,
      "props": {
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"rowGap\":\"10px\",\"gridTemplateColumns\":\"repeat(6, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"1 / 1 / 2 / 5\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.title ? props.title : \"รายงานอุบัติการณ์ความเสี่ยงและความคลาดเคลื่อนทางยา (Feedback Report)\""
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"1 / 5 / 2 / 7\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"baseline\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Label",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"2/ 1 / 3 / 7\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"3/ 1 / 4 / 2\", display: \"flex\", marginTop: \"5px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการที่คลาดเคลื่อน"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"3/ 2 / 4 / 7\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: props.editMode ? \"6/ 1 / 7 / 2\": \"5/ 1 / 6 / 2\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ทำให้เกิด Error*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: props.editMode ? \"6 / 2 / 7 / 4\":\"5/ 2 / 6 / 4\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.userSearch"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"5 / 4 / 6 / 5\", alignItems: \"center\", display: props.editMode ? \"none\" : \"flex\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Risk"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"35%\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"5 / 5 / 6 / 7\", display: props.editMode ? \"none\" : \"\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Dropdown",
      "parent": 20,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "risk_type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "options": {
          "type": "code",
          "value": "props.riskTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.riskType"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: props.editMode ? \"5 / 4 / 6 / 5\": \"6/ 1 / 7 / 2\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editMode ? \"ประเภทความเสี่ยง\":\"ประเภท*\""
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\",marginLeft: props.editMode ? \"25%\" : \"\", lineHeight: 1.35}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea:  props.editMode ? \"5 / 5 / 6 / 7\" :  \"6 / 2 / 7 / 4\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Dropdown",
      "parent": 24,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "options": {
          "type": "code",
          "value": "props.typeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.type"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"6 / 4 / 7 / 5\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editMode ? \"ผลกระทบที่อาจเกิดขึ้น\" :\"Category\""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: props.editMode ? \"25%\" : \"35%\", lineHeight: 1.35}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"6/ 5 / 7/ 7\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Dropdown",
      "parent": 28,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "category"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "options": {
          "type": "code",
          "value": "props.categoryOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.category"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"7 / 1 / 8 / 2\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editMode ? \"หัวข้อความเสี่ยงเรื่อง *\" : \"ลักษณะ*\""
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"7/ 2 / 8/ 7\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Dropdown",
      "parent": 32,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "type_detail"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "options": {
          "type": "code",
          "value": "props.typeDetailOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.typeDetail"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"8/ 1 / 9 / 2\", display: props.editMode ?\"none\":\"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "label",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "สาเหตุ"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"8 / 2 / 9 / 7\",display: props.editMode ?\"none\":\"\",}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Dropdown",
      "parent": 36,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "cause"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "options": {
          "type": "code",
          "value": "props.causeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.cause"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: props.editMode? \"8 / 1 / 10 / 2\":\"9 / 1 / 10 / 2\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียด"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: props.editMode ? \"8 / 2 / 10 / 7\":\"9 / 2 / 10 / 7\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Dropdown",
      "parent": 40,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "cause_detail"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "options": {
          "type": "code",
          "value": "props.causeDetailOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.causeDetail"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: props.showSolution ? \"13/ 1 / 14/ 7\":\"11/ 1 / 12/ 7\", display: \"flex\", justifyContent: \"flex-end\", padding: \"5px 0\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Button",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.detail.pk ? \"EDIT\" : \"NOTE\""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "code",
          "value": "props.detail.pk ? \"yellow\" : \"green\""
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledEdit || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onEdit"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "`[${props.drugCode || \"\"}][${props.drugName || \"\"}]`"
        },
        "className": {
          "type": "value",
          "value": "ui label large"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", width: \"100%\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "code",
          "value": "props.errorMessage"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "hr",
      "parent": 49,
      "props": {
      },
      "seq": 50,
      "void": true
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.Tooltip"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"10/ 1 /11/ 2\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "label",
      "parent": 52,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editMode ? \"ผู้ดำเนินการแก้ไข\" : \"วันที่พบปัญหา\""
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"-0.95rem\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"10/ 2 /11 / 7\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 54,
      "props": {
        "className": {
          "type": "code",
          "value": "props.editMode ? \"hidden\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 54,
      "props": {
        "className": {
          "type": "code",
          "value": "props.editMode ? \"hidden\":\"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ดำเนินการแก้ไข*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", padding: \"0 1rem\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 54,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Dropdown",
      "parent": 67,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "editor_by"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "options": {
          "type": "code",
          "value": "[{key: 1 ,text: \"คลินิก\", value:\"CLINIC\"},{key: 2 ,text: \"เจ้าหน้าที่\", value:\"STAFF\"}]"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.editorBy || \"\""
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 54,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "code",
          "value": "props.staffSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "code",
          "value": "props.StartDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"11/ 1 / 13 / 2\", display: props.showSolution ? \"flex\":\"none\", alignItems: \"baseline\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"11/ 2/ 13/ 7\", display: props.showSolution ? \"flex\":\"none\", alignItems: \"center\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "แนวทางการแก้ไข"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"5px\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "TextArea",
      "parent": 74,
      "props": {
        "name": {
          "type": "value",
          "value": "solution_note"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "value": {
          "type": "code",
          "value": "props.solutionNote || \"\""
        }
      },
      "seq": 76,
      "void": true
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"4/ 1 / 5 / 2\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"4/ 2 / 5 / 4\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"4 / 4 / 5 / 5\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"4 / 5 / 5 / 7\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "label",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ - สกุลผู้ป่วย"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "label",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานที่สั่ง"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: props.editMode ? \"25%\" : \"35%\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientName || \"-\""
        },
        "className": {
          "type": "value",
          "value": "ui label large"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 80,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divisionName || \"-\""
        },
        "className": {
          "type": "value",
          "value": "ui label large"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: !!props.quarterDetail ? \"flex\" :\"none\", flexDirection: \"column\", alignItems: \"center\", lineHeight: 1.75, margin: \"0.25rem 0\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "`ระหว่างวันที่ ${props.quarterDetail?.startDate || \"\"}${props.quarterDetail?.startDate || props.quarterDetail?.endDate ? \" - \" : \"\"}${props.quarterDetail?.endDate || \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"5/ 1 / 6 / 2\",  display: props.editMode ? \"flex\":\"none\", alignItems: \"center\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridArea: \"5/ 2 / 6 / 4\",  display: props.editMode ? \"flex\":\"none\", alignItems: \"center\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "label",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่เกิดเหตุ"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "code",
          "value": "props.incidentDate || \"-\""
        },
        "className": {
          "type": "value",
          "value": "ui label large"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 92,
      "props": {
        "children": {
          "type": "code",
          "value": "`ไตรมาสที่ ${props.quarterDetail?.quarter || \"\"}${props.quarterDetail?.quarter || props.quarterDetail?.year ? \"/\" : \"\"}${props.quarterDetail?.year || \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", marginLeft: \"1rem\", marginTop: \"-1px\", display: props.hideEditQuarter ? \"none\":\"\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "Icon",
      "parent": 94,
      "props": {
        "className": {
          "type": "value",
          "value": "yellow"
        },
        "name": {
          "type": "value",
          "value": "edit"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenModSelectQuarter"
        }
      },
      "seq": 95,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 45,
  "isMounted": true,
  "memo": false,
  "name": "CardMedicationErrorWorkingUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "cannotPay": false,
    "cashAmountText": "14",
    "citizenID": "31020000552",
    "oweText": 123,
    "patientNameAndHN": "HN: xxxxx นายทดสอบ",
    "sumAmount": {
      "price": 100,
      "reimbursable": 42
    }
  },
  "width": 60
}

*********************************************************************************** */
