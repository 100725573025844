import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  SyntheticEvent,
  CSSProperties,
} from "react";
import { Checkbox, Icon, Label } from "semantic-ui-react";

import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

// UX
import CardDrugOrderQueueUX from "../TPD/CardDrugOrderQueueUX";

// Common
import DropdownOptions from "../../../appcon/common/DropdownOptions";
import { DateTextBox, PatientSearchBox } from "react-lib/apps/common";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";
import { getDrugOrderStatusType } from "./sequence/SetDrugOrderQueue";

// Const
const CheckboxStyle = {
  display: "grid",
  justifyContent: "center",
  marginTop: -7,
  marginLeft: -4,
  width: "calc(100% + 10px)",
  height: "calc(100% + 14px)",
  alignItems: "center",
} as CSSProperties;

const CardDrugOrderQueue = (props: any) => {
  console.log('Init CardDrugOrderQueue ');
  const [nationalities, setNationalities] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patientList, setPatientList] = useState<any>([]);
  const [currentHNPage, setCurrentHNPage] = useState(1);
  const [numberOfHNPage, setNumberOfHNPage] = useState(1);
  const [checkedList, setCheckedList] = useState<number[]>([]);

  const patientRef = React.useRef();
  const isMounted = React.useRef(false);

  const history = useHistory();

  const VERBAL_ORDER_MAPPED: { [key: string]: string } = {
    DRUG_ORDER_ONE_DOSE__OPD: "ONE_DOSE_OPD",
    DRUG_ORDER_HOME_OPD: "HOME_OPD",
    DRUG_ORDER_HOME_IPD: "HOME_IPD",
    DRUG_ORDER_ONE_DOSE__IPD: "ONE_DOSE_IPD",
    DRUG_ORDER_ONE_DAY: "ONE_DAY_IPD",
  };

  useEffect(() => {
    console.log(" CardDrugOrderQueue !!! useEffect  ")
    isMounted.current = true;
    if (props.runSequence) {
      console.log("UseEffect Involved SelectEncounter !!!")
      props?.runSequence({ sequence: "SetDrugOrderQueue"});

      return () => {
        console.log("UseEffect unregister Involved SelectEncounter !!!")
        console.log(" CardDrugOrderQueue !!! return unregister useEffect  ")
        isMounted.current = false;
        props?.runSequence({ sequence: "SetDrugOrderQueue", clear: true });
      };
    }
  }, []);

  useEffect(() => {
    if (props.SetDrugOrderQueueSequence?.sequenceIndex === "preparedMasterData") {
      console.log("useEffect 2 preparedMasterData Tick Involved SelectEncounter !!!")
      props?.runSequence({ sequence: "SetDrugOrderQueue"});
    }
  }, [props.SetDrugOrderQueueSequence?.sequenceIndex])
  

  const handleChangeCheckbox = useCallback(
    (e: SyntheticEvent, v: any) => {
      handlePreventDefault(e);

      let checked = [...checkedList];
      if (v.checked) {
        checked.push(v.name);
      } else {
        checked = checked.filter((id) => id !== v.name);
      }

      setCheckedList(checked);
    },
    [checkedList]
  );

  const handlePreventDefault = useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const drugOrderQueue = useMemo(() => {
    const orderQueue = Number.isInteger(props.selectedEncounter?.patient_id)
      ? props.drugOrderQueue.filter(
          (item: any) =>
            item.patient_id === props.selectedEncounter?.patient_id.toString()
        )
      : props.drugOrderQueue;

    return (
      orderQueue?.map((drug: any) => {
        return {
          ...drug,
          checkbox: (
            <div style={CheckboxStyle} onClick={handlePreventDefault}>
              <Checkbox
                checked={checkedList.includes(drug.id)}
                onChange={handleChangeCheckbox}
                name={drug.id}
              />
            </div>
          ),
          requested: drug.requested ? formatDatetime(drug.requested) : "",
          encounter_type: (
            <div style={{ display: "flex" }}>
              <div>{drug.encounter_type}</div>
              {drug.is_telemed && (
                <Label
                  circular
                  color="yellow"
                  style={{ height: "1.8rem", width: "1.8rem" }}
                >
                  <Icon name="phone" style={{ transform: "rotate(90deg)" }} />
                </Label>
              )}
            </div>
          ),
        }; //moment(drug.requested).format("DD-MM-YYYY [[]HH:mm[]]"): ""}
      }) || []
    );
  }, [props.drugOrderQueue, props.selectedEncounter, checkedList]);

  const handleCheckAll = useCallback(
    (e: any, v: any) => {
      if (v.checked) {
        setCheckedList(drugOrderQueue.map((item: any) => item.id));
      } else {
        setCheckedList([]);
      }
    },
    [drugOrderQueue]
  );

  const drugOrderQueueHeaders = useMemo(() => {
    return [
      <Checkbox
        checked={
          drugOrderQueue.length === checkedList.length &&
          !!drugOrderQueue.length
        }
        onClick={handleCheckAll}
      />,
      "Order No.",
      "Status",
      "Encounter Type",
      "Order Type",
      "HN",
      "Patient name",
      "Order by",
      "Department",
      "Order date/time",
      "Shipper",
      "Tracking No.",
    ];
  }, [drugOrderQueue, checkedList]);

  const getNationality = async () => {
    const [data, error] = await props.controller.getNationalities();
    if (isMounted.current) {
      if (data) {
        setNationalities(data);
      } else {
        setNationalities([]);
      }
    }
  };

  const getPatientList = async ({
    hn,
    fullName,
    citizenId,
    nationality,
    patientCurrentPage,
  }: any = {}) => {
    let limit = 40;
    let page = patientCurrentPage ? patientCurrentPage : currentHNPage;

    setIsLoading(true);
    setCurrentHNPage(page);

    const [data, error] = await props.controller.getSearchAllPatient({
      hn,
      fullName,
      citizenId,
      nationality,
      is_old_name_search: true,
      limit,
      offset: (page - 1) * limit,
    });

    if (isMounted.current) {
      setIsLoading(false);

      if (data) {
        setPatientList(data.items);
        setNumberOfHNPage(parseInt(data.total) / limit + 1);
      } else {
        setPatientList([]);
        setNumberOfHNPage(1);
      }
    }
    return [data, error];
  };

  const handlePaginationChange = async ({
    hn,
    fullName,
    citizenId,
    nationality,
    patientCurrentPage,
  }: any = {}) => {
    setCurrentHNPage(patientCurrentPage);
    getPatientList({
      hn,
      fullName,
      citizenId,
      nationality,
      patientCurrentPage,
    });
  };

  const handleGetPatientByHN = async (hn: any) => {
    const [data, error, network] = await props.controller.getPatientByHN({
      hn,
    });
    return [data, error, network];
  };

  const handleOnEnterPatientSearch = async (
    id: number,
    hn: string,
    full_name: string
  ) => {
    if (isMounted.current) {
      await props.setProp(
        "SetDrugOrderQueueSequence.selectedPatientSearch",
        id
      );

      props.setProp("SetDrugOrderQueueSequence.selectedPatientSearchHN", hn);
    }
  };

  // Table
  const handleVerbalRowProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id &&
          rowInfo?.original?.id === props.selectedDrugOrderWorking?.pk
            ? "#cccccc"
            : "white",
      },
      onClick: () => {
        props.onEvent({
          message: "SelectVerbalDrugOrderWorking",
          params: { verbalDrugOrder: rowInfo?.original || null },
        });

        props?.layout === 0 && props?.forward?.();

        if (
          VERBAL_ORDER_MAPPED[rowInfo?.original?.order_specific_type].includes(
            "IPD"
          )
        ) {
          props.setProp("selectedRecordViewIndex", 11);
        } else {
          props.setProp("selectedRecordViewIndex", 10);
        }
      },
      onDoubleClick: () => {
        if (props?.layout === 1) {
          console.log(rowInfo?.original);
          props.onEvent({
            message: "SelectVerbalDrugOrderWorking",
            params: { verbalDrugOrder: rowInfo?.original || null },
          });
          props?.forward?.();

          if (
            VERBAL_ORDER_MAPPED[
              rowInfo?.original?.order_specific_type
            ].includes("IPD")
          ) {
            props.setProp("selectedRecordViewIndex", 11);
          } else {
            props.setProp("selectedRecordViewIndex", 10);
          }
        }
      },
    };
  };

  const handleRowProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id &&
          rowInfo?.original?.id === props.selectedDrugOrderWorking?.pk
            ? "#cccccc"
            : "white",
        color:
          rowInfo?.original?.type === "OPERATING"
            ? "#810CB6"
            : rowInfo?.original?.type === "STAT"
            ? "#db2828"
            : "",
        fontWeight: rowInfo?.original?.type === "STAT" ? "bold" : "",
      },
      onClick: () => {
        console.log('onClick props?.layout: ', props?.layout);
        props.onEvent({
          message: "SelectDrugOrderWorking",
          params: {
            drugOrder: rowInfo?.original || null,
            history,
          },
        });
        props?.layout === 0 && props?.forward?.();
        props.setProp("selectedRecordViewIndex", 0);
      },
      // onDoubleClick: () => {
      //   console.log('onDoubleClick props?.layout: ', props?.layout);
      //   if (props?.layout === 1) {
      //     props.onEvent({
      //       message: "SelectDrugOrderWorking",
      //       params: { drugOrder: rowInfo?.original || null, history },
      //     });
      //     props?.forward?.();
      //     props.setProp("selectedRecordViewIndex", 0);
      //   }
      // },
    };
  };

  const changeSetDrugOrder = (key: string) => (e: any, v: any) => {
    props.setProp(`SetDrugOrderQueueSequence.${key}`, v.value);
  };

  const changeSetDrugOrderCheck =
    (key: string) =>
    (e: any, { checked }: any) => {
      props.setProp(`SetDrugOrderQueueSequence.${key}`, checked);
    };

  const handleResetFilter =
    (key: "selectedDrugOrderStatus" | "selectedDrugOrderType") => (e: any) => {
      const data = getDrugOrderStatusType(props.masterOptions);

      changeSetDrugOrder(key)(null, { value: data[key] });
    };

  console.log("CardDrugOrderQueue:", props);

  return (
    <CardDrugOrderQueueUX
      // data
      // hidePanel={props.hidePanel || false}
      hidePanel={props.hidePanel || false}
      isVerbal={props.isVerbal || false}
      height={props.height}
      drugOrderQueueHeaders={drugOrderQueueHeaders}
      drugOrderQueue={drugOrderQueue}
      verbalOrderQueue={props.verbalDrugOrderQueue}
      selectedDrugOrderWorking={props.selectedDrugOrderWorking}
      selectedVerbalDrugOrderWorking={props.selectedVerbalDrugOrderWorking}
      searching={props.SetDrugOrderQueueSequence?.searching || false}
      selectedDivision={props.SetDrugOrderQueueSequence?.selectedDivision || ""}
      selectedDrugOrderType={
        props.SetDrugOrderQueueSequence?.selectedDrugOrderType || ""
      }
      selectedShipper={props.SetDrugOrderQueueSequence?.selectedShipper || ""}
      selectedDrugOrderStatus={
        props.SetDrugOrderQueueSequence?.selectedDrugOrderStatus || ""
      }
      selectedLocation={props.SetDrugOrderQueueSequence?.selectedLocation || ""}
      checkedOPD={props.SetDrugOrderQueueSequence?.checkedOPD || false}
      checkedIPD={props.SetDrugOrderQueueSequence?.checkedIPD || false}
      checkedContinue={
        props.SetDrugOrderQueueSequence?.checkedContinue || false
      }
      // options
      divisionOptions={props.masterOptions?.division || []}
      drugOrderType={props.masterOptions?.drugOrderType || []}
      orderStatusOptions={props.masterOptions?.drugOrderStatus || []}
      shipperOptions={props.masterOptions?.shippingCompany || []}
      divisionTypeDrugOptions={props.masterOptions?.divisionTypeDrug || []}
      // function
      changeSetDrugOrder={changeSetDrugOrder}
      changeSetDrugOrderCheck={changeSetDrugOrderCheck}
      onSearch={() => {
        props.runSequence({ sequence: "SetDrugOrderQueue", action: "search" });
      }}
      // table
      rowProps={handleRowProps}
      // verbal table
      verbalRowProps={handleVerbalRowProps}
      onResetFilter={handleResetFilter}
      // patient search
      patientSearch={
        <PatientSearchBox
          ref={patientRef}
          defaultValue={
            props.SetDrugOrderQueueSequence?.selectedPatientSearchHN || ""
          }
          patientList={patientList}
          nationalitiesList={nationalities}
          patientListLoading={isLoading}
          numberOfPage={numberOfHNPage}
          currentPage={currentHNPage}
          modalStyle={{ width: "auto" }}
          getNationality={getNationality}
          getPatientList={getPatientList}
          onEnter={handleOnEnterPatientSearch}
          onSelectPatient={handleOnEnterPatientSearch}
          onGetPatientByHN={handleGetPatientByHN}
          onPatientListPaginationChange={handlePaginationChange}
        />
      }
      // Element
      StartDate={
        <DateTextBox
          value={props.SetDrugOrderQueueSequence?.selectedStartDate || ""}
          inputFluid={true}
          inputStyle={{ width: "130px" }}
          style={{ width: "130px" }}
          onChange={(date: string) =>
            changeSetDrugOrder("selectedStartDate")(null, { value: date })
          }
        />
      }
      EndDate={
        <DateTextBox
          value={props.SetDrugOrderQueueSequence?.selectedEndDate || ""}
          inputFluid={true}
          style={{ width: "130px" }}
          inputStyle={{ width: "130px" }}
          onChange={(date: string) =>
            changeSetDrugOrder("selectedEndDate")(null, { value: date })
          }
        />
      }
      OrderStatus={
        <DropdownOptions
          value={props.SetDrugOrderQueueSequence?.selectedDrugOrderStatus || ""}
          multiple={true}
          search={true}
          placeholder="เลือก Order Status"
          checked={true}
          options={props.masterOptions?.drugOrderStatus || []}
          onChange={changeSetDrugOrder("selectedDrugOrderStatus")}
          // fluid={true}
          // style={{ width: "100%" }}
        />
      }
      OrderType={
        <DropdownOptions
          value={props.SetDrugOrderQueueSequence?.selectedDrugOrderType || []}
          multiple={true}
          search={true}
          placeholder="เลือก Order Type"
          checked={true}
          options={props.masterOptions?.drugOrderType || []}
          onChange={changeSetDrugOrder("selectedDrugOrderType")}
        />
      }
      refillPlaned={props.refillPlaned}
      showDrugOrderPendingList={props.showDrugOrderPendingList}
    />
  );
};

export default React.memo(CardDrugOrderQueue);
