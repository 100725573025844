// NPM: Main
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef
} from "react";
import {
  Input,
  Form,
  Select,
  Button,
  Icon,
  Label,
  Loader,
  Dimmer,
  Popup,
  Modal,
  Grid,
  Header,
  TextArea,
  Segment,
  SemanticCOLORS,
  Dropdown,
  Checkbox
} from "semantic-ui-react";
import moment from "moment"
import _ from "../../compat/lodashplus"

// CARD
import { DateTextBox } from "../common"
import CardLayout from "../common/CardLayout"
import PatientPanel2 from "../common/PatientPanel2"
import SemanticTable from "./SemanticTable"
import MedAdminPrintBox from "./MedAdminPrintBox"
import ModMedicationRecordDetail from "./ModMedicationRecordDetail"
import SubUserToken from "./SubUserToken"
import TimeField from "react-simple-timefield";

//Mod
import { ErrorMessage } from "../common"
import ModInfo from "../common/ModInfo"

// Event
// import { MedAdminEvent } from "../../../TPD/MedAdminInterface"
// import MedAdminController from "../../../TPD/MedAdminController"

// Utils
import * as Util from "../../utils";
import PatientData from "../common/PatientData"
import {
  PopupConfig,
  CorrectionReasonType,
  ModDescriptionType,
  ModErrorType
} from "./CardPreAdminQueueWard"
import { DRUG_TYPE_COLOR, TYPE_COLOR } from "./CardQueueWard"

//STYLE
import "./CardMedAdmin.scss"

type MedicationRecordProps = {
  dates: string[];
  items: any[]
}

export type CardMedAdminStateType = {
  emrId: number | null;
  medRecordSummary: MedicationRecordProps;
  django: any;
  controller: any;
  onEvent: (e: any) => any;
  originalOnEvent?: any;
  division: any[];
  patientInfo: any;
  tableHeight?: string;
  // Element
  SubDrugOrderStatusAlert?: any;
};

export const CardMedAdminInitial: CardMedAdminStateType = {
  emrId: null,
  medRecordSummary: {
    dates: [],
    items: []
  },
  django: {},
  controller: null,
  onEvent: () => null,
  division: [],
  patientInfo: null
}

// option choose drug
const drugOptions = [
  { key: "a", text: "ALL", value: "" },
  { key: "s", text: "STAT", value: "STAT" },
  { key: "o", text: "ONE_DOSE", value: "ONE_DOSE" },
  { key: "d", text: "ONE_DAY", value: "ONE_DAY" },
  { key: "c", text: "CONTINUE_PLAN", value: "CONTINUE_PLAN" },
];

const currentTime = () => Util.formatDatetime(moment()).split(" ")[1]

const initialCorReason = {
  time: currentTime(),
  reason: "",
  token: "",
  error: false,
  id: null,
  timeOld: ""
}

const initialModDescription: ModDescriptionType = {
  textContent: null,
  color: "",
  open: false,
  title: ""
}

const initialModError: ModErrorType = {
  error: null,
  type: "",
  open: false,
  title: ""
}

const CardMedAdmin: React.FC<CardMedAdminStateType> = (props) => {
  //* Show loading icon when fetch api
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false)
  const [date, setDate] = useState<string>(Util.formatDate(moment()))
  const [time, setTime] = useState(currentTime())
  //* GridColumnns: medication-record-list-summary
  const [gridColumns, setGridColumns] = useState<any[]>([])

  //* Choose drug type
  const [selectedMedType, setSelectedMedType] = useState<string>("")

  //* Data: medication-record-list-summary
  const [medicationRecord, setMedicationRecord] = useState<MedicationRecordProps>({
    dates: [],
    items: []
  })
  const [patientData, setPatientData] = useState<PatientData | null>(null)
  const [adminDivision, setAdminDivision] = useState<any>(" ")
  const [remarkDivision, setRemarkDivision] = useState<any>(" ")
  const [reasonList, setReasonList] = useState<any[]>([])
  const [adminRemarkList, setAdminRemarkList] = useState([])
  const [reason, setReason] = useState<any>("")
  const [checkedTime, setCheckedTime] = useState(false)

  //* Show Mod
  const [openModSuccess, setOpenModSuccess] = useState(false)
  const [openModError, setOpenModError] = useState<ModErrorType>(initialModError)
  const [openModDescription, setOpenModDescription] = useState(initialModDescription)

  //* Show Modal
  const [openModalDetail, setOpenModalDetail] = useState<string>("")
  const [openModalPrint, setOpenModalPrint] = useState<boolean>(false)
  const [openModalReason, setOpenModalReason] = useState(false)
  const [openModalMedDetail, setOpenModalMedDetail] = useState<boolean>(false)

  const [selectedDetail, setSelectedDetail] = useState<any>(null)
  const [correctionReason, setCorrectionReason] = useState<CorrectionReasonType>(initialCorReason)
  const [adminTimeList, setAdminTimeList] = useState<any>(null)

  const textAreaRef = useRef<HTMLTextAreaElement>()
  useEffect(() => {
    setIsLoading(true)
    setGridColumns(onSetGridDefault())
    onFetchAdminRemarkList()
  }, [])

  useEffect(() => {
    if (!_.isEmpty(props.medRecordSummary)) {
      setGridColumns(onSetGridColumns(props.medRecordSummary))
      setMedicationRecord(props.medRecordSummary)
    } else {
      setGridColumns(onSetGridDefault())
      setMedicationRecord({
        dates: [],
        items: []
      })
    }
  }, [props.medRecordSummary])

  useEffect(() => {
    if (props.patientInfo && props.emrId) {
      const { id, encounter_id, hn, age } = props.patientInfo
      const patient = new PatientData()
      patient.setPatientId(id)
      patient.setEncounterId(encounter_id)
      patient.sethn(hn)
      patient.setAge(age)
      patient.setEmrId(props.emrId)
      setPatientData(patient)

      onFetchMedicationRecord(props.emrId)
    } else {
      setIsLoading(false)
    }

  }, [props.patientInfo, props.emrId])

  // Component
  const SubDrugOrderStatusAlert = useMemo(() => {
    return props.SubDrugOrderStatusAlert;
  }, [props.SubDrugOrderStatusAlert, props.originalOnEvent]);

  const onFetchMedicationRecord = async (emr: any) => {
    setIsLoading(true)
    await getMedicationRecordSummary(emr)
    setIsLoading(false)
  }

  const onFetchAdminRemarkList = async () => {
    if (!reasonList[0]) {
      const [res] = await getClinicalTermSet({ questionList: ["ADMIN_REMARK"] })
      if (res?.ADMIN_REMARK) {
        setAdminRemarkList(res.ADMIN_REMARK.items)
      }
    }
  }
  // ---------------------------- FETCH API, method: GET ----------------------------
  const getMedicationRecordSummary = async (emr: any) => {
    if (!emr) {
      return [, null]
    }
    const date = addDay(-6)
    const params: any = {
      emr,
      date,
      type: selectedMedType
    }

    if (typeof adminDivision !== "string") {
      params.admin_division = adminDivision
    }
    if (checkedTime) {
      params.time = time
    }

    return await props.onEvent({
      message: "getMedicationRecordSummary",
      params
    })
  }

  const getClinicalTermSet = async (params: any) => {
    return props.onEvent({
      message: "getClinicalTermSet",
      params
    })
  }

  const getUserTokenize = async (code: any) => {
    return props.onEvent({
      message: "getUserTokenize",
      params: {
        code
      }
    })
  }

  // ---------------------------- FETCH API, method: PUT ---------------------
  const putDrugOrderItemIdAdminDivision = async (id: any, params: any) => {
    return await props.onEvent({
      message: "putDrugOrderItemIdAdminDivision",
      params: {
        drugOrderItemId: id,
        data: params
      }
    })
  }

  const putDrugOrderItemIdAddStdTimeManual = async (id: any, params: any) => {
    return await props.onEvent({
      message: "putDrugOrderItemIdAddStdTimeManual",
      params: {
        drugOrderItemId: id,
        data: params
      }
    })
  }

  const putDrugOrderItemIdEditStdTimeManual = async (id: any, params: any) => {
    return await props.onEvent({
      message: "putDrugOrderItemIdEditStdTimeManual",
      params: {
        drugOrderItemId: id,
        data: params
      }
    })
  }
  // ---------------------------- END FETCH API ----------------------------

  const createPopup = (
    textContent: string,
    textLabel: string | JSX.Element,
    config: PopupConfig) => {

    return <Popup
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "offsetParent",
        },
      }}
      offset={config.offset}
      trigger={
        typeof textLabel === "string" ?
          <Label
            size="tiny"
            circular
            style={{ cursor: "pointer" }}
            color={config.color}
          >
            {textLabel}
          </Label> : textLabel
      }
      wide
      inverted={config.inverted}
      position={config.position || "bottom left"}
      content={
        <div
          dangerouslySetInnerHTML={{
            __html: textContent
          }}
        />
      }
    />
  }

  const adminTimeStatus = (value: any) => {
    let style = {
      backgroundColor: 'gray',
      cursor: ''
    }
    let innerHTML = ""

    if (!value) {
      return { style, innerHTML }
    }
    if (value.is_editable) {
      style.backgroundColor = 'lightyellow'
    }

    if (value.is_omitted) {
      style.backgroundColor = '#ff8383'
    }
    if (style.backgroundColor !== 'gray') {
      value.is_editable = true
      style.cursor = 'pointer'
    }
    innerHTML = value?.summary || '';

    return { style, innerHTML }
  }

  const addDay = (days: any) => {
    const dateSplit = date.split("/").reverse()
    const dateString = moment(`${+dateSplit[0] - 543}-${dateSplit[1]}-${dateSplit[2]}`)
      .add(days, "days")
      .format("DD/MM/YYYY")
      .split("/");
    dateString[2] = (parseInt(dateString[2]) + 543).toString();

    return dateString.join("/")
  }

  const handleOnClickDetail = (drugInfo: any) => {
    setOpenModalDetail("EDIT")
    setRemarkDivision(props.django?.division?.id)
    setTimeout(() => {
      (textAreaRef.current as any).value = drugInfo.admin_remark || ""
    });
    if (drugInfo.admin_division) {
      setRemarkDivision(drugInfo.admin_division)
    }
  }

  const handleSearch = () => {
    onFetchMedicationRecord(patientData?.EMR?.emr_id)
  }

  const handleOnSelectMedicineType = (ev: any, type: any) => {
    setSelectedMedType(type.value)
  }

  const handleMinusDate = (type: string) => {
    if (type === "back") {
      setDate(addDay(-1))
    } else {
      setDate(addDay(1))
    }
  }

  const handleRemoveRemark = () => {
    handleEditRemark("")
  }

  const handleEditRemark = async (value: any = null) => {
    const params = {
      admin_division: remarkDivision,
      admin_remark: value ?? (textAreaRef.current as any).value
    }

    setIsLoadingModal(true)
    const [, err] = await putDrugOrderItemIdAdminDivision(
      selectedDetail?.drug_order_item_id, params)

    setIsLoadingModal(false)
    if (err) {
      setOpenModError({
        ...openModError,
        open: true,
        error: err
      })
    } else {
      setOpenModSuccess(true)
      setSelectedDetail(null)
      onFetchMedicationRecord(props.emrId)
      handleCloseModalDetail()
    }
  }

  const handleOnSaveReason = async () => {
    const { time, token, id, timeOld } = correctionReason
    const params: any = {
      remark: correctionReason.reason,
      user: token,
      date
    }

    let response = []
    setIsLoadingModal(true)
    if (timeOld) {
      params.std_time_old = timeOld
      params.std_time_new = time
      response = await putDrugOrderItemIdEditStdTimeManual(id, params)
    } else {
      params.std_time = time
      response = await putDrugOrderItemIdAddStdTimeManual(id, params)
    }
    const [, err] = response

    setIsLoadingModal(false)
    if (err) {
      setOpenModError({
        ...openModError,
        open: true,
        error: err
      })
    } else {
      setOpenModSuccess(true)
      handleCloseCorReason()
      onFetchMedicationRecord(props.emrId)
    }
  }

  const handleCloseCorReason = () => {
    setCorrectionReason(initialCorReason)
    setReason("")
    setOpenModalReason(false)
  }

  const handleCloseModalDetail = () => {
    setOpenModalDetail("")
  }

  const handleClickAddStdTime = async () => {
    if (!reasonList[0]) {
      const [res] = await getClinicalTermSet({ questionList: ["ADD_STD_TIME_REMARK"] })

      if (res?.ADD_STD_TIME_REMARK) {
        setReasonList(res.ADD_STD_TIME_REMARK.items)
      }
    }
    setOpenModalReason(true)
  }

  const handleClickEditStdTime = async (id: any, timeManual: any) => {
    const { manual_remark, standard_admin_time, manual_user } = timeManual
    const [res, err] = await getUserTokenize(manual_user.match(/\d{4,}/i)[0])
    setCorrectionReason({
      ...correctionReason,
      reason: manual_remark,
      time: standard_admin_time,
      id,
      timeOld: standard_admin_time,
      token: res.token
    })
    setReason(manual_remark)
    handleClickAddStdTime()
  }

  const handleClickMedRecord = (info: any, medRecord: any) => {
    const modError = {
      ...openModError,
      open: true,
      type: "warning",
      title: `ฉลากยาของ ${info.drug_order_item_summary.split("\n")[0]}`
    }

    if (medRecord?.no_issue) {
      modError.error = "รายการยานี้ไม่มี Continue Issue"
      setOpenModError(modError)
      return
    }

    if (!medRecord?.can_admin) {
      modError.error = "รายการยานี้ยังไม่ Pre Admin<br/>ไม่สามารถ Admin ได้"
      setOpenModError(modError)
      return
    }

    setAdminTimeList({
      ...medRecord,
      admin_remark: info.admin_remark,
      admin_division: info.admin_division
    })
    setOpenModalMedDetail(true)
  }

  const grids: any = [
    {
      Header: "DATE",
      columns: [
        {
          Header: "MEDICATION AND DOSAGE",
          width: 300,
          accessor: " ",
          Cell: (row: any) => {
            const { solvent } = row
            return (
              <Form>
                <Form.Group>
                  <Form.Field>
                    {row.type_name && (
                      <Label
                        color={TYPE_COLOR[row.type_name as DRUG_TYPE_COLOR] || undefined}
                        style={{ textTransform: "lowercase" }}
                      >
                        {row.type_name.replace(/_/g, " ")}
                      </Label>
                    )}
                  </Form.Field>
                </Form.Group>

                <Form.Group>
                  <Form.Field>
                    {/* <b>{row.drug_order_item_summary}</b> */}
                    <div>
                      <b>{row.drug_order_item_summary.split("\n")[0]}</b>
                      <p>{row.drug_order_item_summary.split("\n")[1]}</p>
                    </div>
                  </Form.Field>
                </Form.Group>
                <Form.Group style={{ display: "grid" }}>
                  <Form.Field width={16}>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {/* TODO เรียกใช้ Component แบบนี้เพราะ จะได้ไม่กระทบของเดิม CNMI และเรียกใช้ Component ได้โดยตรงจาก HISV3 */}
                      {SubDrugOrderStatusAlert ? (
                        <SubDrugOrderStatusAlert
                          item={{
                            ...row,
                            alerts: {
                              ...(row.alerts || {}),
                              H: row.highalert,
                              L: row.lasa,
                              I: row?.drug_interaction_info || row?.note_drug_interaction
                              ? {
                                  info:
                                  row.drug_interaction_info ||
                                  row.note_drug_interaction,
                                }
                              : null,
                            },
                            claim_payload: null,
                          }}
                          isFemale={true}
                          onEvent={props.originalOnEvent}
                          targetStateOrder={"medRecordSummary"}
                        />
                      ) : (
                        <>
                          {!_.isEmpty(row.drug_interaction) && (
                            <Label
                              circular
                              size="tiny"
                              color="orange"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setOpenModDescription({
                                  open: true,
                                  title: `แจ้งเตือน !!! Drug Interaction`,
                                  textContent: row.drug_interaction?.info_tpd,
                                  color: "orange",
                                });
                              }}
                            >
                              I
                            </Label>
                          )}
                          {row.pregcat &&
                            row.pregcat !== "NA" &&
                            createPopup(row.pregcat_description, "P", {
                              color: "pink",
                              offset: -14,
                            })}
                          {row.highalert &&
                            createPopup(row.highalert_description, "HAD", {
                              color: "red",
                            })}
                          {row.lasa &&
                            createPopup("LASA", "LASA", {
                              color: "yellow",
                            })}
                        </>
                      )}
                      <Label
                        circular
                        size="tiny"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOpenModDescription({
                            open: true,
                            title: `ฉลากยาของ ${
                              row.drug_order_item_summary.split("\n")[0]
                            }`,
                            textContent: row.drug_label,
                          });
                        }}
                      >
                        <Icon name="info" style={{ margin: "0" }} />
                      </Label>
                    </div>
                  </Form.Field>
                  {row.is_external && (
                    <Form.Field width={16} style={{ marginTop: "5px" }}>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Label size="tiny" color="blue">
                          External
                        </Label>
                      </div>
                    </Form.Field>
                  )}
                </Form.Group>
                {solvent && (
                  <>
                    <Form.Group>
                      <Form.Field>
                        {/* <b>{row.drug_order_item_summary}</b> */}
                        <div>
                          <b>+ {solvent.name}</b>
                          <Form>
                            <Form.Group inline>
                              <Form.Field width={8}>
                                Conc. {solvent.conc}
                              </Form.Field>
                              <Form.Field width={8}>
                                VOLUME: {solvent.volume}
                              </Form.Field>
                            </Form.Group>
                            <Form.Group inline>
                              <Form.Field width={8}>
                                Rate: {solvent.rate}
                              </Form.Field>
                              <Form.Field width={8}>
                                Drip in {solvent.drip}
                              </Form.Field>
                            </Form.Group>
                          </Form>
                        </div>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group style={{ display: "grid" }}>
                      <Form.Field width={16}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {/* TODO เรียกใช้ Component แบบนี้เพราะ จะได้ไม่กระทบของเดิม CNMI และเรียกใช้ Component ได้โดยตรงจาก HISV3 */}
                          {SubDrugOrderStatusAlert ? (
                            <SubDrugOrderStatusAlert
                              item={{
                                ...solvent,
                                alerts: {
                                  ...(solvent.alerts || {}),
                                  H: solvent.highalert,
                                  I: row?.drug_interaction_info || row?.note_drug_interaction
                                    ? {
                                        info:
                                        row.drug_interaction_info ||
                                        row.note_drug_interaction,
                                      }
                                    : null,
                                },
                                claim_payload: null,
                              }}
                              isFemale={true}
                              onEvent={props.originalOnEvent}
                              targetStateOrder={"medRecordSummary"}
                            />
                          ) : (
                            <>
                              {!_.isEmpty(solvent.drug_interaction) && (
                                <Label
                                  circular
                                  size="tiny"
                                  color="orange"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setOpenModDescription({
                                      open: true,
                                      title: `แจ้งเตือน !!! Drug Interaction`,
                                      textContent:
                                        solvent.drug_interaction?.info_tpd,
                                      color: "orange",
                                    });
                                  }}
                                >
                                  I
                                </Label>
                              )}
                              {solvent.pregcat &&
                                createPopup(row.pregcat_description, "P", {
                                  color: "pink",
                                  offset: -14,
                                })}
                              {solvent.highalert &&
                                createPopup(row.highalert_description, "HAD", {
                                  color: "red",
                                })}
                              {solvent.alerts["L"] &&
                                createPopup(solvent.alerts["L"], "LASA", {
                                  color: "yellow",
                                })}
                            </>
                          )}
                          <Label
                            circular
                            size="tiny"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setOpenModDescription({
                                open: true,
                                title: `ฉลากยาของ ${solvent.name}`,
                                textContent: solvent?.drug_label,
                              });
                            }}
                          >
                            <Icon name="info" style={{ margin: "0" }} />
                          </Label>
                        </div>
                      </Form.Field>
                      {solvent.is_external && (
                        <Form.Field width={16} style={{ marginTop: "5px" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Label size="tiny" color="blue">
                              External
                            </Label>
                          </div>
                        </Form.Field>
                      )}
                    </Form.Group>
                  </>
                )}
                <Form.Group>
                  <Form.Field inline>
                    <Button
                      content="Detail"
                      color="orange"
                      size="tiny"
                      className="button-tiny"
                      onClick={() => {
                        const date = row.admin_time_list.map((acc: any) =>
                          Object.keys(acc)?.find(
                            (item) => acc[item]?.drug_order_item_id
                          )
                        );

                        if (date[0]) {
                          setSelectedDetail(row.admin_time_list[0][date[0]]);
                          handleOnClickDetail(row);
                        }
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <div>
                      {row.admin_remark?.split("\n").map((str: string) => (
                        <div>{str}</div>
                      ))}
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form>
            );
          },
        },
      ],
    },
    {
      Header: "Std Time",
      accessor: "",
      width: 70,
      Cell: (row: any) => {
        const list = row.admin_time_list,
          { length } = list

        return _.map(list, (item: any, index: number) =>
          <Form style={{
            display: "grid", margin: "0 auto",
            alignItems: "center"
          }}>
            <Form.Group inline>
              <Form.Field
                width={15}
                style={{ paddingRight: '0' }}>
                <b>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.standard_admin_time || '&nbsp;'
                    }}
                  />
                </b>
              </Form.Field>
              {
                item.is_manual_time &&
                <Form.Field width={1} style={{
                  position: "absolute",
                  right: "-15px"
                }}>
                  {createPopup(
                    `<p>Edited: ${item.manual_user}</p>
                    <p>${item.manual_remark}</p>`,
                    <div
                      style={{
                        position: "absolute",
                        cursor: "pointer",
                        fontSize: "1.5em",
                        color: "red",
                        paddingLeft: "3px"
                      }}
                      onClick={() => {
                        const date = row.admin_time_list.map((acc: any) =>
                          Object.keys(acc)?.find((item) => acc[item]?.drug_order_item_id)
                        )

                        if (date[0]) {
                          handleClickEditStdTime(row.admin_time_list[0][date[0]].drug_order_item_id, item)
                        }
                      }}
                    > *</div>,
                    {
                      offset: 15,
                      inverted: true,
                      position: "bottom right"
                    }
                  )}
                </Form.Field>
              }
            </Form.Group>
            {/* {
              (row.can_add_std_time && index === length - 1) &&
              <>
                <br />
                <div style={{ width: "100%", marginLeft: "-8px" }}>
                  <Button icon='plus'
                    size="tiny"
                    style={{ transform: "scale(0.7)" }}
                    color="green"
                    onClick={() => {
                      const date = row.admin_time_list.map((acc) =>
                        Object.keys(acc).find((item) => acc[item]?.drug_order_item_id)
                      )

                      if (date[0]) {
                        setCorrectionReason({
                          ...correctionReason,
                          id: row.admin_time_list[0][date[0]].drug_order_item_id
                        })
                        handleClickAddStdTime()
                      }
                    }}
                  />
                </div>
              </>
            } */}
          </Form>

        )
      },

    },

  ]

  const onSetGridDefault = useCallback(
    () => {
      const gridClone = [...grids, {
        Header: "",
        accessor: "",
        minWidth: 200
      }]

      return gridClone
    },
    [date]
  )

  const onSetGridColumns = useCallback(
    (value: any) => {
      const gridClone = [...grids]

      if (!value.dates[0]) {
        return onSetGridDefault()
      }
      for (const date of value.dates) {
        gridClone.push(
          //@ts-ignore
          {
            Header: date,
            accessor: "",
            width: 160,
            Cell: (row: any) => {
              return _.map(row.admin_time_list, (item: any) => {
                const medRecord = item[date];
                const isEditable = medRecord?.on_date_status === "ACTIVE" || row.type_name !== "CONTINUE_PLAN"
                const isReasonAdmin = medRecord?.summary && !medRecord?.is_omitted && medRecord?.reason
                const adminStatus = adminTimeStatus(medRecord)
                const statusText = medRecord?.on_date_status === "HELD" ? "HOLD" : medRecord?.on_date_status
                const reason = adminRemarkList?.find((list) => list.id === medRecord?.reason)
                const isNoIssue = medRecord?.no_issue && !["RENEWED", "HOLD", "OFF"].includes(statusText)

                return {
                  Cell: isEditable ? (<Form>
                    <Form.Group >
                      <Form.Field
                        width={15}
                        style={{ paddingRight: '0' }}>
                        {adminStatus.innerHTML}
                      </Form.Field>
                      {
                        isReasonAdmin &&
                        <Form.Field width={1} style={{
                          position: "absolute",
                          right: "5px"
                        }}>
                          {
                            createPopup(
                              `<p>${reason?.name}</p>`,
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  color: "red",
                                  fontSize: "1.5em",
                                  cursor: "pointer",
                                }}>
                                *</div>,
                              {
                                offset: 15,
                                inverted: true,
                                position: "bottom right"
                              }
                            )
                          }

                        </Form.Field>
                      }
                    </Form.Group>
                  </Form>) :
                    <div>{isNoIssue ? "" : statusText}</div>
                  ,
                  getProps: {
                    onClick: (ev: any, rows: any, self: any) => {
                      if (self.getProps.style?.cursor === "pointer") {
                        handleClickMedRecord(row, medRecord)
                      }
                    },
                    style: isNoIssue ? {
                      ...adminStatus.style,
                      backgroundColor: "lightyellow"
                    } :
                      (isEditable ?
                        {
                          ...adminStatus.style,
                          backgroundColor: isReasonAdmin ? "lightyellow" : adminStatus.style.backgroundColor
                        } : {
                          backgroundColor: medRecord?.on_date_status === "OFF" ? "#474744" : "grey",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          fontWeight: "500",
                        })
                  }
                }
              }
              )
            }
          }
        )
      }

      return gridClone
    },
    [date, props.django, adminRemarkList],
  )

  // option choose admin division
  const adminDivisionOptions = useMemo(() => {
    return props.division ?
      [{ key: "", text: "All", value: " " },
      ..._.map(props.division, (item: any, index: number) => ({ key: index, text: item.name, value: item.id }))
      ] : []
  }, [props.division])

  const reasonOptions = useMemo(() => {
    return reasonList ?
      _.map(reasonList, (item: any, index: number) => ({ key: index, text: item.name, value: item.name }))
      : []
  }, [reasonList])

  return (
    <>
      <PatientPanel2
        controller={props.controller.patientPanelController}
        patientId={patientData?.patient_id}
        encounterId={patientData?.ENCOUNTER.encounter_id}
        disableEpisode={true}
      />

      <CardLayout
        titleText="Medication Administration Record"
        closeable={false}
        toggleable={false}
        headerColor="red"
        cardLayoutClassName="medication-admin-ward-table"
        hideHeaderIcon={true}
      >
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <Form>
          <Form.Group inline>
            <Form.Field width={4}>
              <label>ประเภทใบยา</label>
              <Select
                size="tiny"
                placeholder="ALL"
                options={drugOptions}
                onChange={handleOnSelectMedicineType}
                style={{ marginRight: 14 }}
              />
            </Form.Field>

            <Form.Field width={3}>
              <Button
                icon
                color="teal"
                size="tiny"
                onClick={() => handleMinusDate("back")}
              >
                <Icon name="chevron left" />
              </Button>

              <DateTextBox
                value={date}
                onChange={(value: any) => setDate(value)}
              />
              <Button
                icon
                color="teal"
                size="tiny"
                onClick={() => handleMinusDate("go")}
                style={{ marginLeft: "15px" }}
              >
                <Icon name="chevron right" />
              </Button>
            </Form.Field>

            <Form.Field
              style={{
                display: "flex",
                paddingRight: "5px"
              }}
            >
              <Checkbox
                checked={checkedTime}
                onChange={() =>
                  setCheckedTime(!checkedTime)
                }
              />
            </Form.Field>

            <Form.Field width={2}>
              <label>เวลา</label>
              <TimeField
                input={<Input icon="clock outline" />}
                value={time}
                onChange={(ev, value) => {
                  setTime(value)
                }}
              />
            </Form.Field>

            <Form.Field width={4}>
              <label>Admin Division</label>
              <Dropdown
                size="tiny"
                className="fluidDropdown"
                selection
                search
                value={adminDivision}
                options={adminDivisionOptions}
                onChange={(ev, input) => {
                  setAdminDivision(input.value)
                }}
                style={{ marginRight: 14 }}
              />
            </Form.Field>

            <Form.Field width={1}>
              <Button color="blue" onClick={handleSearch} >ค้นหา</Button>
            </Form.Field>

            <Form.Field width={1}>
              <Button
                content='Print'
                icon='print'
                labelPosition='right'
                color="teal"
                onClick={() => setOpenModalPrint(true)} />
            </Form.Field>

          </Form.Group>
        </Form>

        <SemanticTable
          data={medicationRecord.items}
          columns={gridColumns}
          headerLevel={1}
          tableHeight={props.tableHeight}
        />

        <ModInfo
          type={openModError.type || "error"}
          titleName={openModError.title}
          closeOnDimmerClick
          open={openModError.open}
          onApprove={() => setOpenModError(initialModError)}
          onClose={() => setOpenModError(initialModError)}
        >
          {typeof openModError.error === "string" ?
            <div
              dangerouslySetInnerHTML={{
                __html: openModError.error
              }}
            /> :
            <ErrorMessage error={openModError.error} />
          }
        </ModInfo>

        <ModInfo
          type="success"
          titleName={"บันทึกสำเร็จ"}
          closeOnDimmerClick
          open={openModSuccess}
          onApprove={() => setOpenModSuccess(false)}
          onClose={() => setOpenModSuccess(false)}
        />

        <ModInfo
          color={openModDescription.color || "blue"}
          titleName={openModDescription.title}
          closeOnDimmerClick
          open={openModDescription.open}
          onApprove={() => setOpenModDescription(initialModDescription)}
          onClose={() => setOpenModDescription(initialModDescription)}
        >
          <div style={{
            display: "grid",
            textAlign: "left"
          }}>
            {openModDescription.textContent?.split('\n').map((str: string, index: any) => <p key={index}>{str}</p>)}
          </div>
        </ModInfo>

        {/* Modal manage admin_remark */}
        <Modal
          size="tiny"
          open={!!openModalDetail}
          style={{ margin: "auto" }}
          onClose={handleCloseModalDetail}
        >
          <Form>
            <Dimmer active={isLoadingModal} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
            <Form.Group >
              <Form.Field inline width={16} >
                <Grid >
                  <Grid.Row >
                    <Grid.Column
                      textAlign="right"
                      width={16}
                    >
                      <Icon
                        name="close"
                        color="red"
                        style={{ cursor: "pointer" }}
                        onClick={handleCloseModalDetail}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
            </Form.Group>

            <Form.Group inline style={{ paddingLeft: "10px" }}>
              <Form.Field width={12}>
                <Header as='h5'>Admin Division</Header>
                <Dropdown
                  size="tiny"
                  className="fluidDropdown"
                  selection
                  search
                  value={remarkDivision}
                  options={adminDivisionOptions}
                  onChange={(ev, input) => {
                    setRemarkDivision(input.value)
                  }}
                  style={{ marginRight: 14 }}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group inline style={{ paddingLeft: "10px" }}>
              <Form.Field width={16}>
                <Header as='h5'>Remark</Header>
                <TextArea
                  placeholder=""
                  rows={3}
                  style={{ resize: "none" }}
                  ref={(instance) => {
                    if (instance) {
                      textAreaRef.current = instance.ref.current
                    }
                  }}
                />
              </Form.Field>
            </Form.Group>

            <br />
            {
              openModalDetail === "EDIT" &&
              <Button.Group fluid>
                <Button color="red" onClick={handleRemoveRemark} >REMOVE</Button>
                <Button color="yellow" onClick={() => handleEditRemark()}>EDIT</Button>
              </Button.Group>
            }
          </Form>
        </Modal>


        <Modal
          open={openModalPrint}
          onClose={() => setOpenModalPrint(false)}
        >

          <MedAdminPrintBox
            controller={props.controller}
            PATIENT_DATA={patientData}
            DJANGO={props.django}
            onClose={() => setOpenModalPrint(false)}
            onPrinted={() => {
              setOpenModalPrint(false)
              setOpenModError({
                open: true,
                title: "PRINT สำเร็จ",
                type: "success"
              })
            }}
          />

        </Modal>

        <Modal
          size="tiny"
          open={openModalReason}
          style={{ margin: "auto" }}
          onClose={handleCloseCorReason}
        >
          <Segment>
            <Dimmer active={isLoadingModal} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
            <Grid >
              <Grid.Row >
                <Grid.Column
                  textAlign="right"
                  width={16}
                >
                  <Icon
                    name="close"
                    size="large"
                    color="red"
                    style={{ cursor: "pointer" }}
                    onClick={handleCloseCorReason}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Form>
              <Form.Group inline>
                <Form.Field width={4}>
                  <label >Time</label>
                </Form.Field>
                <Form.Field width={5}>
                  <TimeField
                    input={<Input icon="clock outline" />}
                    value={correctionReason.time}
                    onChange={(ev, value) => {
                      setCorrectionReason({
                        ...correctionReason,
                        time: value
                      })
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field width={4}>
                  <label >เหตุผลการแก้ไข</label>
                </Form.Field>
                <Form.Field width={12}>
                  <Dropdown
                    size="tiny"
                    className="fluidDropdown"
                    selection
                    search
                    value={reason}
                    options={reasonOptions}
                    onChange={(ev, input) => {
                      setCorrectionReason({
                        ...correctionReason,
                        reason: input.value
                      })
                      setReason(input.value)
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field width={4}>
                  <label >รหัสพยาบาล</label>
                </Form.Field>
                <Form.Field width={7}>
                  <SubUserToken
                    token={correctionReason.token}
                    controller={props.controller}
                    error={correctionReason.error}
                    onChangeToken={(token) => {
                      setCorrectionReason({
                        ...correctionReason,
                        token
                      })
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field width={12} />
                <Form.Field width={2} >
                  <Button color="green" onClick={handleOnSaveReason}>บันทึก</Button>
                </Form.Field>
              </Form.Group>
            </Form>
          </Segment>
        </Modal>
        {
          props.django &&
          <ModMedicationRecordDetail
            type="MED_RECORD"
            DJANGO={props.django}
            orderItemId={adminTimeList?.medication_record_id}
            open={openModalMedDetail}
            record={adminTimeList}
            onClose={() => {
              setAdminTimeList(null)
              setOpenModalMedDetail(false)
            }}
            onEvent={props.onEvent}
            controller={props.controller}
            onSaved={() => {
              setOpenModalMedDetail(false)
              setAdminTimeList(null)
              setOpenModSuccess(true)
              onFetchMedicationRecord(props.emrId)
            }}
          />
        }
      </CardLayout>
    </>
  )
}

CardMedAdmin.defaultProps = CardMedAdminInitial

export default CardMedAdmin