import WasmController from 'react-lib/frameworks/WasmController';
import DoctorFeeSummaryView from 'issara-sdk/apis/DoctorFeeSummaryView_apps_DFC';

export type State = 
  {
    selectedDivision?: any,
    SetDoctorFeeReportSequence?: {
      sequenceIndex: string | null,
      selectedDivision?: any,
      reportData?: any,
    } | null,
  }

export const StateInitial: State = 
  {
    SetDoctorFeeReportSequence: null,
  }

export type Event = 
  { message: "RunSequence", params: {} }

export type Data = 
  {
    division?: number,
    divisionDict?: any,
    device?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetDoctorFeeReportSequence) return

  if (params.action === "get_data") {
    const [response, error] = await DoctorFeeSummaryView.get({
      params: {
          doctor: params.doctor,
          report: params.report,
          start_date: params.start_date,
          end_date: params.end_date,
        } as any,
      apiToken: controller.apiToken
    })
  
    if (response) {
      controller.setState({
        SetDoctorFeeReportSequence: {
          ...state.SetDoctorFeeReportSequence,
          reportData: response,
        }
      });
    }
    
  }
}