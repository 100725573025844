import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const ModDrugOrderRejectUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
        <div
          style={{backgroundColor: "#2185D0", color: "white", fontSize: "1.4rem", padding: "10px"}}>
          กรุณากรอกเหตุผลการ Reject ยา
        </div>
        <div>
          {props.ErrorMessage}
        </div>
        <div
          style={{padding: "10px", display: "flex", flexDirection: "column"}}>
          
          <div>
            
            <Table
              data={props.dataRow}
              headers="ชื่อยา,ฉลากยา,เหตุผล"
              keys="name,label,note"
              minRows="4"
              showPagination={false}>
            </Table>
          </div>
          <div
            style={{padding: "20px", display: "flex", alignItems: "center", justifyContent: "space-evenly"}}>
            
            <Button
              className="basic"
              color="green"
              onClick={props.onConfirm}
              style={{width:"200px"}}>
              ใช่
            </Button>
            <Button
              className="basic"
              color="red"
              onClick={props.onClose}
              style={{width:"200px"}}>
              ไม่
            </Button>
          </div>
        </div>
      </div>
    )
}

export default ModDrugOrderRejectUX

export const screenPropsDefault = {"diDetail":"ทดสอบ","needNote":true}

/* Date Time : Thu Jun 16 2022 10:58:31 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\", display: \"flex\", flexDirection: \"column\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "กรุณากรอกเหตุผลการ Reject ยา"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#2185D0\", color: \"white\", fontSize: \"1.4rem\", padding: \"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", display: \"flex\", flexDirection: \"column\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"20px\", display: \"flex\", alignItems: \"center\", justifyContent: \"space-evenly\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ใช่"
        },
        "className": {
          "type": "value",
          "value": "basic"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onConfirm"
        },
        "style": {
          "type": "code",
          "value": "{width:\"200px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ไม่"
        },
        "className": {
          "type": "value",
          "value": "basic"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        },
        "style": {
          "type": "code",
          "value": "{width:\"200px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 37,
      "name": "Table",
      "parent": 36,
      "props": {
        "data": {
          "type": "code",
          "value": "props.dataRow"
        },
        "headers": {
          "type": "value",
          "value": "ชื่อยา,ฉลากยา,เหตุผล"
        },
        "keys": {
          "type": "value",
          "value": "name,label,note"
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        }
      },
      "seq": 10,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 55,
  "isMounted": true,
  "memo": false,
  "name": "ModDrugOrderRejectUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "diDetail": "ทดสอบ",
    "needNote": true
  },
  "width": 65
}

*********************************************************************************** */
