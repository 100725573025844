import React, { useMemo, useState } from "react";
import {
  Icon,
  Tab,
  Form,
  FormField,
  Input,
  Button,
  FormGroup,
  TabProps,
} from "semantic-ui-react";

// UI
import CardSurgeryTeamUX from "./CardSurgeryTeamUX";
import SubIcdDetail from "../ADM/SubIcdDetail";

import { TeamInitial } from "./sequence/ORRequest";

// Common
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";

const CardSurgeryTeam = (props: any) => {
  const getHasDescription = (id: number) => {
    return props.masterData.otherTrtPosition?.find(
      (item: any) => item.id === id
    )?.has_description;
  };

  const handleChiefSurgeon = (index: number) => async (item: any) => {
    await props.setProp(
      `ORRequestSequence.teams.${index}.chief_surgeon`,
      item?.id || null
    );
    await props.setProp(
      `ORRequestSequence.teams.${index}.chief_surgeon_item`,
      item
    );

    props.runSequence({
      sequence: "ORRequest",
      action: "update_location_room",
    });
  };

  const handleAddTeam = (e: any) => {
    props.runSequence({ sequence: "ORRequest", action: "add_team" });
  };

  const handleSelectTeam = (e: any, data: TabProps) => {
    if (props.teams?.length === Number(data.activeIndex)) {
      handleAddTeam(e);
      return;
    }

    props.setProp(
      `ORRequestSequence.activeSurgeonTeam`,
      Number(data.activeIndex)
    );
  };

  const handleChange =
    (key: string, index: number) => async (e: any, v: any) => {
      const lastKey = key.slice(-1)?.[0] || "";

      if (lastKey === "position" && !getHasDescription(v.value)) {
        await props.setProp(
          `ORRequestSequence.teams.${index}.pre_operating_order_item.other_position`,
          ""
        );
      }

      props.onChangeValue(e, { ...v, name: `${key}.${index}.${v.name}` });
    };

  const handleActionOperatingTreatment =
    (index: number) => (cIndex: number, item: any) => {
      const treatments =
        props.teams[index].pre_operating_order_item?.operating_treatments || [];

      if (item === "add") {
        if (treatments.every((item: any) => item?.id)) {
          treatments.push(null);
        } else {
          return;
        }
      } else if (item === "remove") {
        treatments.splice(cIndex, 1);
      } else {
        treatments[cIndex] = item
          ? {
              id: item.id,
              name_code: item.name_code,
            }
          : null;
      }

      props.setProp(
        `ORRequestSequence.teams.${index}.pre_operating_order_item.operating_treatments`,
        treatments
      );
    };

  const handleActionAssistantSurgeon =
    (index: number) => (cIndex: number, item: any) => {
      const items = props.teams[index].assistant_surgeon_items || [];

      if (item === "add") {
        if (items.every((item: any) => item)) {
          items.push(null);
        } else {
          return;
        }
      } else if (item === "remove") {
        items.splice(cIndex, 1);
      } else {
        items[cIndex] = item;
      }

      props.setProp(
        `ORRequestSequence.teams.${index}.assistant_surgeon_items`,
        items
      );
    };

  const handleDeleteTeam = async () => {
    const teams = props.teams || [];

    if (teams.length === 1) {
      teams[props.activeIndex] = TeamInitial;
    } else {
      teams.splice(props.activeIndex, 1);

      const index = props.activeIndex - 2;

      await props.setProp(
        `ORRequestSequence.activeSurgeonTeam`,
        index < 0 ? 0 : index
      );
    }

    await props.setProp(`ORRequestSequence.teams`, teams);

    props.runSequence({
      sequence: "ORRequest",
      action: "update_location_room",
    });
  };

  const handleActionProvision =
    (action: string, index: number) => (cIndex: any, v: any) => {
      let procedures: any[] = [];

      // console.log(v);

      if (v.field === "procedures") {
        procedures =
          props.teams[index].pre_operating_order_item?.procedures || [];
      } else {
        procedures = props.teams[index].diagnosis || [];
      }

      if (action === "change") {
        procedures[cIndex][v.name] = v.value;
      } else if (action === "add") {
        procedures.push({ subType: "PRIMARY" });
      } else if (action === "delete") {
        procedures.splice(cIndex, 1);
      }

      props.setProp(
        `ORRequestSequence.teams.${index}.${
          v.field === "procedures"
            ? "pre_operating_order_item.procedures"
            : "diagnosis"
        }`,
        procedures
      );
    };

  const handleSearch = (index: number) => (cIndex: any, params: any) => {
    const { field, ...res } = params;

    handleAction({
      action: "search",
      key: field,
      index: cIndex,
      teamIndex: index,
      ...res,
    });
  };

  const handleSelect = (index: number) => (cIndex: any, data: any) => {
    const { icdType, field, ...res } = data;

    handleAction({
      action: "select",
      key: field,
      index: cIndex,
      teamIndex: index,
      icdType,
      selectItem: res,
    });
  };

  const handleClear = (index: number) => (cIndex: any, data: any) => {
    handleAction({
      action: "clear",
      key: data.field,
      index: cIndex,
      teamIndex: index,
    });
  };

  const handleAction = (params: any) => {
    props.runSequence({ sequence: "ORRequest", ...params });
  };

  const handleAddAnsTeam = (e: any) => {
    props.runSequence({ sequence: "ORRequest", action: "anesthesia_team" });
  };

  const handleAddition = (index: number) => (e: any, v: any) => {
    const options = props.orImplantOptions;

    props.setProp("ORRequestSequence.orImplantOptions", [
      { key: v.value, text: v.value, value: v.value },
      ...options,
    ]);
  };

  return (
    <div style={{ padding: "1rem 0", marginTop: "0rem", position: "relative" }}>
      <Form
        style={{ position: "absolute", width: "100%", paddingRight: "1rem" }}
      >
        <Form.Group inline={true}>
          <Form.Field width={14} />
          <Form.Field width={2} >
            <Button color="purple" fluid={true} style={{minWIdth:"max-content"}} onClick={handleAddAnsTeam}>
              Add ANS
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>

      <Tab
        id="enable-pointer-tap"
        activeIndex={props.activeIndex}
        menu={{ secondary: true, pointing: true, color: "blue" }}
        onTabChange={handleSelectTeam}
        panes={[
          ...(props.teams || []).map((item: any, index: number) => ({
            menuItem: {
              key: `MenuItem-team${index}`,
              id: `MenuItem-team${index}`,
              content: (
                <MenuItem
                  index={index}
                  activeIndex={props.activeIndex}
                  title="Surgery Team"
                  isStar={item.is_main}
                />
              ),
            },
            render: () => (
              <CardSurgeryTeamUX
                // data
                isMain={item.is_main}
                implant={item.implant}
                otherTreatment={item.pre_operating_order_item?.other_treatment}
                position={item.pre_operating_order_item?.position}
                otherPosition={item.pre_operating_order_item?.other_position}
                otherTrtPositionOptions={props.masterOptions.otherTrtPosition}
                siteRight={item.pre_operating_order_item?.site_right}
                siteLeft={item.pre_operating_order_item?.site_left}
                // options
                orImplantOptions={props.orImplantOptions}
                // callback
                onChangeValue={handleChange("teams", index)}
                onDeleteTeam={handleDeleteTeam}
                onAddition={handleAddition(index)}
                // config
                config={{
                  showOtherPosition: getHasDescription(
                    item.pre_operating_order_item?.position
                  ),
                }}
                // Element
                AssistantsElement={
                  <AssistantsElement
                    // callback
                    onEvent={props.onEvent}
                    // data
                    index={index}
                    items={item.assistant_surgeon_items || []}
                    type="Doctor"
                    field="Assist_Surgeon"
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    setSelected={handleActionAssistantSurgeon(index)}
                    onClick={handleActionAssistantSurgeon(index)}
                  />
                }
                TreatmentElement={
                  <AssistantsElement
                    // callback
                    onEvent={props.onEvent}
                    // data
                    index={index}
                    items={
                      item.pre_operating_order_item?.operating_treatments || []
                    }
                    type="OperatingTreatment"
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    onClick={handleActionOperatingTreatment(index)}
                    setSelected={handleActionOperatingTreatment(index)}
                  />
                }
                ProvisionDiagElement={
                  <ProvisionDiagElement
                    // data
                    field="procedures"
                    defaultLength={1}
                    items={item.pre_operating_order_item?.procedures || []}
                    showHeader={false}
                    addable={true}
                    deletable={true}
                    showSubType={true}
                    removable={false}
                    icdType="ICD9CM"
                    // options
                    typeOptions={props.masterOptions.procedureType}
                    // callback
                    onChangeValue={handleActionProvision("change", index)}
                    onSearch={handleSearch(index)}
                    onSelect={handleSelect(index)}
                    onClear={handleClear(index)}
                    onAdd={handleActionProvision("add", index)}
                    onDelete={handleActionProvision("delete", index)}
                  />
                }
                PreoperativeDiagElement={
                  <ProvisionDiagElement
                    // data
                    field="diagnosis"
                    defaultLength={2}
                    items={item.diagnosis || []}
                    showHeader={true}
                    addable={false}
                    deletable={false}
                    showSubType={false}
                    removable={true}
                    icdType="ICD10"
                    // options
                    typeOptions={props.masterOptions.procedureType}
                    // config
                    requiredMedicalTerm={item.is_main}
                    // callback
                    onChangeValue={handleActionProvision("change", index)}
                    onSearch={handleSearch(index)}
                    onSelect={handleSelect(index)}
                    onClear={handleClear(index)}
                    onAdd={handleActionProvision("add", index)}
                    onDelete={handleActionProvision("delete", index)}
                  />
                }
                SurgeonElement={
                  <SearchBoxWithKey
                    // callback
                    onEvent={props.onEvent}
                    type="Doctor"
                    id={`Surgeon_${index + 1}`}
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    selectedItem={item.chief_surgeon_item || null}
                    setSelected={handleChiefSurgeon(index)}
                  />
                }
              />
            ),
          })),
          {
            menuItem: {
              key: `MenuItem-teams`,
              content: (
                <div>
                  <Icon
                    name="add circle"
                    color="teal"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ),
            },
          },
        ]}
      />
    </div>
  );
};

export const SearchBoxWithKey = (props: any) => {
  const mapOptions = (items: any) => {
    // console.log("mapDivisionOptions items: ", items);
    return items.map((item: any) => ({
      key: item?.code || item?.id,
      value: item?.name_code || item?.full_name_code,
      text: item?.name_code || item?.full_name_code,
    }));
  };

  return (
    <SearchBoxDropdown
      type={props.type}
      id={props.id?.toString()}
      style={{ width: "100%" }}
      boxStyle={{ width: "100%" }}
      fluid={true}
      label=""
      role={props.role}
      disabled={props.disabled}
      // disabled={selectedManagePatient !== null}
      toDisplay={(item: any) => `${item.name_code || item?.full_name_code}`}
      onEvent={props.onEvent}
      // searchedItemList={props.searchedItemList}
      searchedItemListWithKey={props.searchedItemListWithKey}
      selectedItem={props.selectedItem}
      setSelectedItem={(value: any, key: any, obj: any) => {
        if (value === null && key === null) {
          props.setSelected(null);
          return;
        }
        let data = props.searchedItemListWithKey?.[
          `${props.type}_${props.id?.toString()}`
        ]?.find((item: any) => (item.code || item.id) === key);
        props.setSelected(data);
      }}
      useWithKey={true}
      mapOptions={mapOptions}
    />
  );
};

/* ------------------------------------------------------ */

/*                   AssistantsElement;                   */

/* ------------------------------------------------------ */
export const AssistantsElement = (props: any) => {
  const handleSelected = (index: number) => (item: any) => {
    props.setSelected(index, item);
  };

  const handleClick = (action: string, index: number) => (e: any) => {
    props.onClick(index, action);
  };

  return (
    <>
      {(props.items?.length ? props.items : [null]).map(
        (item: any, cIndex: number) => (
          <FormGroup key={`assistant-${props.index}-` + cIndex} inline={true}>
            <FormField inline={true} width={10}>
              <SearchBoxWithKey
                // callback
                onEvent={props.onEvent}
                // data
                type={props.type}
                id={`${props.field ? `${props.field}_` : ""}${
                  props.index + 1
                }_${cIndex + 1}`}
                role={props.role}
                searchedItemListWithKey={props.searchedItemListWithKey}
                selectedItem={item}
                setSelected={handleSelected(cIndex)}
              />
            </FormField>
            <FormField inline={true}>
              {cIndex !== 0 && (
                <Button
                  color={"red"}
                  icon="minus"
                  onClick={handleClick("remove", cIndex)}
                ></Button>
              )}
              <Button
                color={"green"}
                icon="plus"
                onClick={handleClick("add", cIndex)}
              ></Button>
            </FormField>
          </FormGroup>
        )
      )}
    </>
  );
};

const ProvisionDiagElement = (props: any) => {
  const handleChange = (index: number) => (e: any, v: any) => {
    props.onChangeValue?.(index, { ...v, field: props.field });
  };

  const handleSearch = (index: number) => (params: any) => {
    props.onSearch?.(index, { ...params, field: props.field });
  };

  const handleSelect = (index: number) => (data: any) => {
    props.onSelect?.(index, { ...data, field: props.field });
  };

  const handleClear = (index: number) => (e: any, v: any) => {
    props.onClear?.(index, { field: props.field });
  };

  const handleAdd = (index: number) => (e: any, v: any) => {
    props.onAdd?.(index, { field: props.field });
  };

  const handleDelete = (index: number) => (e: any, v: any) => {
    props.onDelete?.(index, { field: props.field });
  };

  // console.log(props.items, props.icdType);
  return (
    <>
      {(props.items.length < props.defaultLength
        ? [
            ...props.items,
            ...Array(props.defaultLength - props.items.length).fill({}),
          ]
        : props.items
      ).map((item: any, index: number) => (
        <SubIcdDetail
          key={"sub-icd-procedure-" + index}
          item={item}
          primaryText="Principle Diagnosis"
          secondaryText="Secondary Diagnosis"
          icdType={props.icdType}
          icdKeyUpSearchList={item.table || []}
          showHeader={props.showHeader ? index === 0 : props.showHeader}
          hideActiveDisease={false}
          showSubType={props.showSubType}
          removable={props.removable ? index > 1 : props.removable}
          clearable={true}
          deletable={props.deletable ? index !== 0 : props.deletable}
          allowedAdd={index !== 0}
          addable={props.addable}
          boxStyle={{
            backgroundColor: "transparent",
            boxShadow: "none",
            border: "none",
            padding: 0,
          }}
          typeOptions={props.typeOptions}
          onChangeValue={handleChange(index)}
          subType={item.subType}
          // config
          primaryTextStyle={{ textTransform: "unset" }}
          secondaryTextStyle={{ textTransform: "unset" }}
          requiredMedicalTerm={
            index === 0 && props.requiredMedicalTerm ? true : false
          }
          // callback
          onClear={handleClear(index)}
          onAdd={handleAdd(index)}
          onClickAdd={handleAdd(index)}
          onDelete={handleDelete(index)}
          onSearch={handleSearch(index)}
          onSelect={handleSelect(index)}
          onRemove={handleDelete(index)}
        />
      ))}
    </>
  );
};

/* ------------------------------------------------------ */

/*                        MenuItem                        */

/* ------------------------------------------------------ */
export const MenuItem = (props: any) => {
  return (
    <div
      // className={`item${props.index === props.activeIndex ? " active" : ""}`}
      style={{
        cursor: "pointer",
        padding: 0,
        ...(props.index === props.activeIndex
          ? {
              color: props.color || "var(--primary-theme-color)",
              // borderBottom: `3px solid ${
              //   props.color || "var(--primary-theme-color)"
              // }`,
              fontWeight: "bold",
            }
          : {}),
      }}
    >
      <div>
        {props.isStar && <Icon name="star" color="yellow" />}
        <label>
          {props.title} {props.index + 1}
        </label>
      </div>
    </div>
  );
};

const SURGERY_TEAM = [{}, {}];

export default CardSurgeryTeam;
