import React, { useEffect, useMemo, useState } from "react";
import { Icon } from "semantic-ui-react";

import { toast } from "react-toastify";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import AutoCompleteBox from "react-lib/apps/common/AutoCompleteBox";

// UX
import CardTriageUX from "./CardTriageUX";

const CardTriage = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const inputRef = React.useRef();

  useEffect(() => {
    if (props.TriageSequence) {
      props.runSequence({
        sequence: "Triage",
        action: "refreshTriage",
      });
    }
  }, []);

  useEffect(() => {
    if (
      props.errorMessage?.Triage !== null &&
      props.errorMessage?.Triage !== undefined
    ) {
      setLoading(false);
      setTimeout(() => {
        props.runSequence({
          sequence: "Triage",
          action: "clearMessage",
        });
      }, 2000);
      toast.error("เกิดความผิดพลาดในการบันทึก Triage");
    }
    if (
      props.successMessage?.Triage !== null &&
      props.successMessage?.Triage !== undefined
    ) {
      setLoading(false);
      setCompleted(true);
      setTimeout(() => {
        setCompleted(false);
      }, 2000);
      props.runSequence({
        sequence: "Triage",
        action: "clearMessage",
      });
      toast.success("บันทึก Triage สำเร็จ");
    }
  }, [props.successMessage?.Triage, props.errorMessage?.Triage]);

  useEffect(() => {
    if (!Number.isInteger(props?.selectedEncounter?.patient_id)) return;
    props.runSequence({ sequence: "Triage" });
  }, [props.selectedEncounter]);

  const choiceTriageLevel = useMemo(() => {
    return (props.choiceTriage?.items || [])?.map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.value,
    }));
  }, [props.choiceTriage]);

  const changeTriage = (key: string, value?: any) => (_e: any, v: any) =>
    props.setProp(
      `TriageSequence.triage.${key}`,
      typeof value !== "undefined" ? value : v.value
    );

  const changeAdr = (key: string, value?: any) => (_e: any, v: any) =>
    props.setProp(
      `TriageSequence.adr.${key}`,
      typeof value !== "undefined" ? value : v.value
    );

  const triage = props.TriageSequence?.triage;
  const adrList = props.TriageSequence?.adrList;
  const adr = props.TriageSequence?.adr;

  return (
    <div>
      <CardTriageUX
        // Triage Fields
        chief_complaint={triage?.chief_complaint || ""}
        patient_type={triage?.patient_type || ""}
        patientTypeOptions={[
          { key: "WLK", value: "WLK", text: "Walk-in" },
          { key: "APP", value: "APP", text: "นัดหมาย" },
          { key: "REF", value: "REF", text: "Refer-in" },
          { key: "CON", value: "CON", text: "Consult" },
          { key: "TRF", value: "TRF", text: "ถูกส่งมาจากหน่วยงานอื่น" },
        ]}
        arrive_status={triage?.arrive_status || ""}
        arriveStatusOptions={props.masterOptions?.arriveStatus || []}
        case={triage?.case || ""}
        caseOptions={props.masterOptions?.case || []}
        referer={triage?.referer || ""}
        triage_level={triage?.triage_level || ""}
        triageLevelOptions={choiceTriageLevel}
        // ADR fields
        adrList={adrList || []}
        action={adr?.action || ""}
        note={adr?.note || []}
        // Methods
        changeTriage={changeTriage}
        changeAdr={changeAdr}
        onSaveTriage={() =>
          props.runSequence({ sequence: "Triage", action: "save" })
        }
        loading={loading}
        save={completed ? <Icon name="check"></Icon> : "SAVE"}
        hideDrugAllergyHistory={true}
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              const text = (inputRef as any).current.getValue();
              props.setProp("TriageSequence.triage.chief_complaint", text);
              props.runSequence({
                sequence: "Triage",
                action: "save",
                card: CardTriage,
                buttonLoadKey: `${CardTriage}_SAVE`,
              });
            }}
            // data
            paramKey={`${CardTriage}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CardTriage}_SAVE`]}
            // config
            name="SAVE"
            size="mini"
            style={{ width: "100%" }}
            title="SAVE"
            color="green"
          />
        }
        chiefComplaint={
          <AutoCompleteBox
            disabled={false}
            options={props.masterOptions?.symptoms}
            value={triage?.chief_complaint}
            ref={inputRef}
          />
        }
      />
    </div>
  );
};

export default React.memo(CardTriage);
