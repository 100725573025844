import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardSelectTemplateUX = (props: any) => {
    return(
      <div
        style={{height: "100%"}}>
        <div
          style={{margin: "15px 0 0 0"}}>
          ลงตารางโดยอ้างอิงจากเทมเพลทตารางคลินิก
        </div>
        <Table
          data={props.scheduleTemplates}
          getTrProps={props.templateRowProps}
          headers="วันที่เริ่ม,วันที่สิ้นสุด"
          keys="start,end"
          minRows="2"
          showPagination={false}
          style={{height: "120px"}}>
        </Table>
        <div
          style={{flexDirection: "column", marginTop: "10px", display: "flex",justifyContent: "space-between"}}>
          
          <div
            style={{display: "flex"}}>
            
            <div
              style={{marginRight: "10px", flex: 1}}>
              ตั้งแต่
            </div>
            <div
              style={{flex: 5}}>
              
              <DateTextBox
                onChange={props.changeDate("startDateTime")}
                style={{flex: 3}}
                value={props.startDateTime}>
              </DateTextBox>
            </div>
          </div>
          <div
            style={{display: "flex"}}>
            
            <div
              style={{marginRight: "10px", flex: 1}}>
              ถึง
            </div>
            <div
              style={{flex: 5}}>
              
              <DateTextBox
                onChange={props.changeDate("endDateTime")}
                style={{flex: 3}}
                value={props.endDateTime}>
              </DateTextBox>
            </div>
          </div>
        </div>
        <div
          style={{margin: "15px 0 0 0"}}>
          เลือกวันเวลา
        </div>
        <Table
          data={props.templateItems}
          getTrProps={props.templateItemProps}
          headers="เลือก,วัน,รายละเอียด,"
          keys="active,weekday,detail,edit"
          minRows="6"
          showPagination={false}
          style={{height: "35vh"}}
          widths="50,100,200">
        </Table>
        <div
          style={{display: "flex", justifyContent: "flex-end", margin: "15px 0 0 0"}}>
          
          <div>
            {props.buttonCreate}
          </div>
        </div>
      </div>
    )
}

export default CardSelectTemplateUX

export const screenPropsDefault = {"providerSelector":"[Component for selecting provider]","selectedProvider":{}}

/* Date Time : Thu Aug 25 2022 10:16:01 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ลงตารางโดยอ้างอิงจากเทมเพลทตารางคลินิก"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"15px 0 0 0\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 9,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.scheduleTemplates"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.templateRowProps"
        },
        "headers": {
          "type": "value",
          "value": "วันที่เริ่ม,วันที่สิ้นสุด"
        },
        "keys": {
          "type": "value",
          "value": "start,end"
        },
        "minRows": {
          "type": "value",
          "value": "2"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"120px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 10,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.templateItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.templateItemProps"
        },
        "headers": {
          "type": "value",
          "value": "เลือก,วัน,รายละเอียด,"
        },
        "keys": {
          "type": "value",
          "value": "active,weekday,detail,edit"
        },
        "minRows": {
          "type": "value",
          "value": "6"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"35vh\"}"
        },
        "widths": {
          "type": "value",
          "value": "50,100,200"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกวันเวลา"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"15px 0 0 0\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", margin: \"15px 0 0 0\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flexDirection: \"column\", marginTop: \"10px\", display: \"flex\",justifyContent: \"space-between\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "ตั้งแต่"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\", flex: 1}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\", flex: 1}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 20,
      "name": "DateTextBox",
      "parent": 27,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"startDateTime\")"
        },
        "style": {
          "type": "code",
          "value": "{flex: 3}"
        },
        "value": {
          "type": "code",
          "value": "props.startDateTime"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 21,
      "name": "DateTextBox",
      "parent": 28,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"endDateTime\")"
        },
        "style": {
          "type": "code",
          "value": "{flex: 3}"
        },
        "value": {
          "type": "code",
          "value": "props.endDateTime"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 5}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 5}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonCreate"
        }
      },
      "seq": 29,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSelectTemplateUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "providerSelector": "[Component for selecting provider]",
    "selectedProvider": {
    }
  },
  "width": 25
}

*********************************************************************************** */
