import {
  useState,
  useEffect,
  useMemo,
  CSSProperties,
  useCallback,
  useRef,
} from "react";
import {
  Dropdown,
  DropdownProps,
  Icon,
  SemanticICONS,
} from "semantic-ui-react";

type SearchBoxDropdownProps = {
  id?: number | string;
  mapOptions?: (items: any[]) => any[];
  searchedItemList?: any;
  vertical?: boolean;
  label?: any;
  style?: CSSProperties;
  boxStyle?: CSSProperties;
  selectedItem?: any;
  toDisplay?: (item: any) => any;
  setSelectedItem?: (value: any, key: any) => any;
  startSearchLength?: number;
  onEvent: (e: any) => any;
  type?: string;
  verbose?: boolean;
  placeholder?: any;
  fluid?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  search?: boolean;
  noResultsMessage?: React.ReactNode;
  encounterId?: number;
  icon?: SemanticICONS;
  iconStyle?: CSSProperties;
  dropdownStyle?: CSSProperties;
  defaultText?: string;
  onBlur?: (e: any, data: DropdownProps) => any;

  searchedItemListWithKey?: any;
  useWithKey?: boolean;
  role?: Record<string, any>;
  limit?: number;
  active?: boolean;
};

const SearchBoxDropdown = (props: SearchBoxDropdownProps) => {
  const [searchText, setSearchText] = useState("");

  const isFocusRef = useRef<boolean>(false);
  const isClearRef = useRef<boolean>(false);
  const dropdownRef = useRef<any>(null);

  const handleClear = useCallback(() => {
    props.onEvent({
      message: "ItemSearch",
      params: {
        id: props.id,
        type: props.type,
        action: "clear",
        role: props.role,
      },
    });
  }, [props.id, props.type, props.role]);

  // Callback Effect
  const handleOnClick = useCallback(async (ev: any) => {
    const parent = ev.target.closest(".dropdown");

    if (isFocusRef.current && !parent) {
      dropdownRef.current.ref.current.firstElementChild.blur();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleOnClick);

    return () => {
      document.removeEventListener("click", handleOnClick);
    };
  }, []);

  useEffect(() => {
    if (props.selectedItem === null) {
      handleClear();
    }
  }, [props.selectedItem]);

  const mapOptions = useCallback((items: any[]) => {
    return items.map((item) => ({
      key: item.id,
      value: item.id,
      text: item.name_code ?? item.name,
    }));
  }, []);

  const options = useMemo(() => {
    const key = props.type + "_" + props.id;
    const itemList = props.searchedItemList;
    const listWithKey = props.searchedItemListWithKey?.[key];

    return !props.useWithKey
      ? props.searchedItemList?.items
        ? props.mapOptions
          ? props.mapOptions(itemList.items)
          : mapOptions(itemList.items)
        : props.searchedItemList
        ? props.mapOptions
          ? props.mapOptions(itemList)
          : mapOptions(itemList)
        : []
      : props.searchedItemListWithKey?.[key]?.items
      ? props.mapOptions
        ? props.mapOptions?.(listWithKey.items)
        : mapOptions(listWithKey.items)
      : props.searchedItemListWithKey?.[key]
      ? props.mapOptions
        ? props.mapOptions?.(listWithKey)
        : mapOptions(listWithKey)
      : [];
  }, [
    props.searchedItemList,
    props.searchedItemListWithKey,
    props.type,
    props.id,
    props.useWithKey,
  ]);

  const value = useMemo(() => {
    return props.selectedItem
      ? props.toDisplay
        ? props.toDisplay(props.selectedItem)
        : props.selectedItem
      : searchText || "";
  }, [props.selectedItem, props.toDisplay, searchText]);

  const open = useMemo(() => {
    const key = props.type + "_" + props.id;

    const isOpen = !props.useWithKey
      ? props.searchedItemList?.items
        ? props.searchedItemList?.items?.length > 0 &&
          props.selectedItem === null
        : props.searchedItemList
        ? props.searchedItemList?.length > 0 && props.selectedItem === null
        : false
      : props.searchedItemListWithKey?.[key]?.items
      ? props.searchedItemListWithKey?.[key]?.items?.length > 0 &&
        props.selectedItem === null
      : props.searchedItemListWithKey?.[key]
      ? props.searchedItemListWithKey?.[key]?.length > 0 &&
        props.selectedItem === null
      : false;

    return isOpen && isFocusRef.current;
  }, [
    props.useWithKey,
    props.searchedItemList,
    props.selectedItem,
    props.searchedItemListWithKey,
    props.type,
    props.id,
  ]);

  const showDefaultText = !searchText && !!props.defaultText && !options.length;

  useEffect(() => {
    if (!!options?.length && !value && !isFocusRef.current) {
      handleClear();
    }
  }, [value, options, open]);

  const handleSearchChange = (e: any, { searchQuery }: any) => {
    console.log("onSearchChange searchQuery: ", searchQuery);

    setSearchText(searchQuery);

    if (!searchText.length && searchQuery.length === 1 && value) {
      props.setSelectedItem?.(null, null);
    }

    if (searchQuery.length >= (props.startSearchLength || 3)) {
      props.onEvent({
        message: "ItemSearch",
        params: {
          id: props.id,
          type: props.type,
          searchText: searchQuery,
          verbose: props?.verbose,
          role: props.role,
          limit: props.limit,
          encounter: props.encounterId,
          active: props.active,
        },
      });
    } else if (!!options?.length) {
      handleClear();
    }
  };

  const handleChange = (event: any, data: any) => {
    if (data?.value === "" || event.type === "blur") {
      if (!props.onBlur) {
        props.setSelectedItem?.("", null);
      }

      if (!!options?.length) {
        handleClear();
      }

      return;
    }

    const obj = data.options?.find((item: any) => data.value === item.value);

    if (obj) {
      props.setSelectedItem?.(data.value, obj?.key);

      setSearchText("");
    }
  };

  const handleClick = (event: any, data: any) => {
    const target = event.target as HTMLElement;

    if (event.type === "click" && target.className.includes("clear")) {
      const inputElm = target.parentElement
        ?.firstElementChild as HTMLInputElement;

      if (!!inputElm) {
        isClearRef.current = true;

        inputElm.focus();
        inputElm.blur();
      }
    }
  };

  const handleBlur = async (e: any, data: any) => {
    if (!props.selectedItem || isClearRef.current) {
      props.onBlur?.(e, {
        ...data,
        value: isClearRef.current ? "" : e.target.value,
      });
    }

    setTimeout(() => {
      isFocusRef.current = false;

      setSearchText("");

      isClearRef.current = false;
    });
  };

  const handleFocus = (e: any) => {
    isFocusRef.current = true;

    if (!value && !isClearRef.current && props.defaultText) {
      handleSearchChange(null, { searchQuery: props.defaultText });
    }
  };

  return (
    <div
      style={{
        ...(props.vertical
          ? {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }
          : {
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }),
        ...(props.boxStyle || {}),
      }}
    >
      <div style={{ marginRight: props.label ? "10px" : "0px" }}>
        {props.label}
      </div>
      <div style={{ position: "relative", ...(props.style || {}) }}>
        <Dropdown
          ref={dropdownRef}
          clearable={props.clearable ?? true}
          search={props.search ?? true}
          searchQuery={searchText}
          selection
          value={showDefaultText ? props.defaultText : value}
          disabled={props.disabled}
          style={props.dropdownStyle}
          onSearchChange={handleSearchChange}
          open={
            // !!props.noResultsMessage &&
            searchText.length >= (props.startSearchLength || 3) && !open
              ? true
              : open
          }
          noResultsMessage={props.noResultsMessage}
          options={
            showDefaultText
              ? [{ key: 1, value: props.defaultText, text: props.defaultText }]
              : options
          }
          onChange={handleChange}
          onClick={handleClick}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={props.placeholder}
          fluid={props.fluid}
          icon={
            props.icon ? (
              <Icon
                name={props.icon}
                style={{
                  marginTop: "-1rem",
                  marginRight: "-1rem",
                  ...(props.iconStyle || {}),
                }}
              />
            ) : (
              "dropdown"
            )
          }
        />
      </div>
    </div>
  );
};

export default SearchBoxDropdown;
