import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { Checkbox, Button } from "semantic-ui-react";
import { Table } from "react-lib/frameworks/Table";

const CardAnesDoctorFeeListUX = (props: any) => {
  return (
    <div>
      <div style={{ padding: "30px 20px 20px" }}>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <Checkbox
            checked={props.AnesDoctorFeeListSequence?.anesFilter?.checkedDate}
            label="วันที่ผ่าตัด"
            name="checkedDate"
            onChange={props.onChangeFilter}
            style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}
          ></Checkbox>
          <div style={{ marginRight: "10px" }}>{props.startDateTextBox}</div>
          <label style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}>
            ถึง
          </label>
          <div style={{ marginRight: "10px" }}>{props.endDateTextBox}</div>
          <Checkbox
            checked={
              props.AnesDoctorFeeListSequence?.anesFilter?.checkedEmployee
            }
            label="วิสัญญีแพทย์"
            name="checkedEmployee"
            onChange={props.onChangeFilter}
            style={{ marginTop: "10px", fontWeight: "bold" }}
          ></Checkbox>
          <div style={{ marginRight: "5px" }}>{props.doctorSearchBox}</div>
          <Checkbox
            checked={
              props.AnesDoctorFeeListSequence?.anesFilter?.checkedDrFeeRule
            }
            label="Service Code/Product Name"
            name="checkedDrFeeRule"
            onChange={props.onChangeFilter}
            style={{ marginTop: "10px", fontWeight: "bold" }}
          ></Checkbox>
          <div style={{ marginRight: "5px" }}>
            {props.doctorFeeRuleSearchBox}
          </div>
          <div style={{ marginRight: "5px" }}>{props.buttonSearch}</div>
          <div style={{ marginRight: "5px" }}>{props.buttonPrint}</div>
          <Button
            color="yellow"
            onClick={props.onClear}
            size="small"
            style={{ marginRight: "10px" }}
          >
            ล้างการค้นหา
          </Button>
        </div>
      </div>
      <div
        style={{
          padding: "0px 20px",
          display: props.showStatistic ? "none" : "",
        }}
      >
        <Table
          data={props.AnesDoctorFeeListSequence?.drFeeList?.items || []}
          headers="วันที่ผ่าตัด, วิสัญญีแพทย์, HN, ชื่อ - สกุล, Service Code, Product Name, จำนวนที่สั่ง, ราคาต่อหน่วย, ราคารวม, ค่าตอบแทน"
          keys="performDT, received_employee_display, hn, patient_name, service_code, doctor_fee_rule_display, quantity, price_unit, price_total, final_compensation_price"
          minRows={10}
          widths="100, auto, 100, auto, 100, auto, 100, 100, 100, 100"
        ></Table>
      </div>
    </div>
  );
};

export default CardAnesDoctorFeeListUX;

export const screenPropsDefault = {};

/* Date Time : Tue Mar 14 2023 09:16:18 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"30px 20px 20px\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginBottom: \"10px\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 20px\", display: props.showStatistic ? \"none\" : \"\" }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Checkbox",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesDoctorFeeListSequence?.anesFilter?.checkedEmployee"
        },
        "label": {
          "type": "value",
          "value": "วิสัญญีแพทย์"
        },
        "name": {
          "type": "value",
          "value": "checkedEmployee"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"10px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"5px\" }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 33,
      "name": "Table",
      "parent": 22,
      "props": {
        "data": {
          "type": "code",
          "value": "props.AnesDoctorFeeListSequence?.drFeeList?.items || []"
        },
        "headers": {
          "type": "value",
          "value": "วันที่ผ่าตัด, วิสัญญีแพทย์, HN, ชื่อ - สกุล, Service Code, Product Name, จำนวนที่สั่ง, ราคาต่อหน่วย, ราคารวม, ค่าตอบแทน"
        },
        "keys": {
          "type": "value",
          "value": "performDT, received_employee_display, hn, patient_name, service_code, doctor_fee_rule_display, quantity, price_unit, price_total, final_compensation_price"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "widths": {
          "type": "value",
          "value": "100, auto, 100, auto, 100, auto, 100, 100, 100, 100"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Button",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างการค้นหา"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startDateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endDateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Checkbox",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesDoctorFeeListSequence?.anesFilter?.checkedDate"
        },
        "label": {
          "type": "value",
          "value": "วันที่ผ่าตัด"
        },
        "name": {
          "type": "value",
          "value": "checkedDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"5px\" }"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"5px\" }"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Checkbox",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesDoctorFeeListSequence?.anesFilter?.checkedDrFeeRule"
        },
        "label": {
          "type": "value",
          "value": "Service Code/Product Name"
        },
        "name": {
          "type": "value",
          "value": "checkedDrFeeRule"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"10px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorFeeRuleSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"5px\" }"
        }
      },
      "seq": 57,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardAnesDoctorFeeListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
