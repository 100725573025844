import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Input,
  Checkbox,
  Dropdown,
  Icon
} from 'semantic-ui-react'

const CardSpecimenCollectorUX = (props: any) => {
    return(
      <div>
        <div
          style={{ display: "flex", paddingBottom: "10px" }}>
          
          <div
            style={{fontSize: "1.2rem", fontWeight: "bold", paddingTop: "8px" }}>
            Specimen
          </div>
          <Checkbox
            checked={props.filterData?.isLabDivision}
            label="Lab Division"
            name="isLabDivision"
            onChange={props.onFilterChange}
            style={{ padding: "10px" }}>
          </Checkbox>
          <Dropdown
            clearable={true}
            name="labDivision"
            onChange={props.onFilterChange}
            options={props.specimenCollectorMasterData?.labDivisionOptions || []}
            search={true}
            selection={true}
            style={{ width: "150px" }}
            value={props.filterData?.labDivision}>
          </Dropdown>
          <Checkbox
            checked={props.filterData?.isStatus}
            label="สถานะ"
            name="isStatus"
            onChange={props.onFilterChange}
            style={{ padding: "10px" }}>
          </Checkbox>
          <Dropdown
            clearable={true}
            name="status"
            onChange={props.onFilterChange}
            options={props.specimenCollectorMasterData?.labItemStatusOptions || []}
            search={true}
            selection={true}
            style={{ width: "150px" }}
            value={props.filterData?.status}>
          </Dropdown>
          <Button
            color="blue"
            onClick={props.onSearch}
            size="small"
            style={{ marginLeft: "10px" }}>
            ค้นหา
          </Button>
          <Checkbox
            checked={props.filterData?.isFinished}
            label="เก็บ Specimen เสร็จสิ้น"
            name="isFinished"
            onChange={props.onFilterChange}
            style={{ padding: "10px" }}>
          </Checkbox>
        </div>
        <Table
          columns={props.columns_specimenItems}
          data={props.containerList}
          getTrProps={props.rowProps}
          minRows={props.containerList.length > 10 ? props.containerList.length : 10}
          resizable={false}
          showPagination={false}
          style={{ height: "55vh" }}>
        </Table>
        <div
          style={{display: "flex" }}>
          
          <Button
            color="green"
            disabled={props.disablePrint}
            loading={props.loadingPrintSticker}
            onClick={props.onPrintSticker}
            size="mini">
            {props.print}
          </Button>
          <Button
            color="orange"
            disabled={props.disablePrint}
            loading={props.loadingPrintRobo}
            onClick={props.onPrintRobo}
            size="mini"
            style={{marginLeft: "0.5rem"}}>
            {props.robo}
          </Button>
          <Button
            color="blue"
            disabled={props.disabledPrintLabSummary || false}
            onClick={props.onPrintLabSummary}
            size="mini"
            style={{ display: "flex", paddingRight: "0.5rem", marginLeft: "0.5rem", alignItems: "center"}}>
            
            <label
              style={{marginRight: "0.75rem"}}>
              พิมพ์สรุปรายการแลป
            </label>
            <Icon
              className="large"
              name="print">
            </Icon>
          </Button>
          <div
            style={{flex: 1}}>
            
          </div>
          <Input
            disabled={props.disableInputUsername || false}
            onChange={props.changeUsername}
            placeholder="username"
            size="mini"
            value={props.username}>
          </Input>
          <Input
            disabled={props.disableInputPassword || false}
            onChange={props.changePassword}
            placeholder="password"
            size="mini"
            type="password"
            value={props.password}>
          </Input>
          <Button
            color="blue"
            disabled={props.disablePrint}
            loading={props.loadingCollect}
            onClick={props.handleCollect}
            size="mini">
            {props.collect}
          </Button>
          <div>
            {props.buttonReceive}
          </div>
          <div>
            {props.buttonReject}
          </div>
        </div>
      </div>
    )
}


export default CardSpecimenCollectorUX

export const screenPropsDefault = {}

/* Date Time : Tue May 30 2023 03:32:35 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "columns": {
          "type": "code",
          "value": "props.columns_specimenItems"
        },
        "data": {
          "type": "code",
          "value": "props.containerList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": ""
        },
        "keys": {
          "type": "value",
          "value": ""
        },
        "minRows": {
          "type": "code",
          "value": "props.containerList.length > 10 ? props.containerList.length : 10"
        },
        "resizable": {
          "type": "code",
          "value": "false"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55vh\" }"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.print"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disablePrint"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingPrintSticker"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintSticker"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.collect"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disablePrint"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingCollect"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleCollect"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Input",
      "parent": 13,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disableInputUsername || false"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeUsername"
        },
        "placeholder": {
          "type": "value",
          "value": "username"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.username"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Input",
      "parent": 13,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disableInputPassword || false"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePassword"
        },
        "placeholder": {
          "type": "value",
          "value": "password"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "type": {
          "type": "value",
          "value": "password"
        },
        "value": {
          "type": "code",
          "value": "props.password"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"10px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "Specimen"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\", paddingTop: \"8px\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Checkbox",
      "parent": 15,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterData?.isLabDivision"
        },
        "label": {
          "type": "value",
          "value": "Lab Division"
        },
        "name": {
          "type": "value",
          "value": "isLabDivision"
        },
        "onChange": {
          "type": "code",
          "value": "props.onFilterChange"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Dropdown",
      "parent": 15,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "labDivision"
        },
        "onChange": {
          "type": "code",
          "value": "props.onFilterChange"
        },
        "options": {
          "type": "code",
          "value": "props.specimenCollectorMasterData?.labDivisionOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"150px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.filterData?.labDivision"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Checkbox",
      "parent": 15,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterData?.isStatus"
        },
        "label": {
          "type": "value",
          "value": "สถานะ"
        },
        "name": {
          "type": "value",
          "value": "isStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onFilterChange"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\" }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Dropdown",
      "parent": 15,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onFilterChange"
        },
        "options": {
          "type": "code",
          "value": "props.specimenCollectorMasterData?.labItemStatusOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"150px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.filterData?.status"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"10px\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Checkbox",
      "parent": 15,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterData?.isFinished"
        },
        "label": {
          "type": "value",
          "value": "เก็บ Specimen เสร็จสิ้น"
        },
        "name": {
          "type": "value",
          "value": "isFinished"
        },
        "onChange": {
          "type": "code",
          "value": "props.onFilterChange"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\" }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonReceive"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonReject"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledPrintLabSummary || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintLabSummary"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingRight: \"0.5rem\", marginLeft: \"0.5rem\", alignItems: \"center\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Icon",
      "parent": 25,
      "props": {
        "className": {
          "type": "value",
          "value": "large"
        },
        "name": {
          "type": "value",
          "value": "print"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์สรุปรายการแลป"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.75rem\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.robo"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "disabled": {
          "type": "code",
          "value": "props.disablePrint"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingPrintRobo"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintRobo"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.5rem\"}"
        }
      },
      "seq": 8,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 55,
  "isMounted": false,
  "memo": false,
  "name": "CardSpecimenCollectorUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 55
}

*********************************************************************************** */
