import React, { useEffect, useState, CSSProperties } from "react";
import moment from 'moment';
import { Modal } from "semantic-ui-react";
import { momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import CardAppointmentManageUX from "./CardAppointmentManageUX";
import ModBlockDetailUX from "./ModBlockDetailUX";
import SearchBox from "react-lib/appcon/common/SearchBox";

const CardAppointmentManage = (props: any) => {
  const [tab, setTab] = useState("calendar");
  const [showBlockDetail, setShowBlockDetail] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<any>(null);
  const [selectedBlock, setSelectedBlock] = useState<any>(null);
  
  
  useEffect(() => {
    props.runSequence({sequence: "SetAppointment", restart: true});
  }, []);

  moment.locale('en-es', { week: { dow: 1 } });
  const localizer = momentLocalizer(moment);

  const appointmentList = (props.SetAppointmentSequence?.appointmentList || [])
    .map((app: any) => ({
      ...app,
      date: app.display_info?.start_datetime?.split("T")?.[0],
      start: app.display_info?.start_datetime?.split("T")?.[1]?.substring(0, 5),
      end: app.display_info?.end_datetime?.split("T")?.[1]?.substring(0, 5),
      patient_name: `${app.patient_first_name} ${app.patient_last_name}`,
      detail: `${app.division_name || ""} (${app.display_info?.provider_name || ""})`,
    }))
    .map((app: any) => ({
      ...app,
      datetime: `${app.date} ${app.start} ${app.end}`,
    }))
    .sort((a: any, b: any) => 
      (a.display_info?.start_serial < b.display_info?.start_serial ? -1 : 1)
    );

  // Prepare blockList for display
  const blockList = 
    ((selectedProvider === null || typeof selectedProvider === "undefined")  ? 
    props.SetAppointmentSequence?.blockList || []
    : 
    (props.SetAppointmentSequence?.blockList || [])
    .filter((event: any) => 
      event.providers
           .map((provider: any) => provider.provider_id)
           .includes(selectedProvider?.provider_id))
    )
    .map((item: any) => ({
      ...item,
      providers: selectedProvider === null ? item.providers
                 : item.providers.filter((p: any) => 
                      p.provider_id === selectedProvider?.provider_id),
    }))
    .map((item: any) => ({
      ...item,
      providerNames: item.providers
                         .filter((p: any) => p.provider_id !== "Unspecified")
                         .map((p: any) => p?.dsb?.provider_name)
    }))
    .map((item: any) => ({
      ...item,
      title: (selectedProvider === null && item.providerNames.length > 0) ? 
             `${item.title} (${item.providerNames.length})`
             : item.title,
      tooltip: item.providerNames.length > 0 ? item.providerNames.join(", ") 
               : "ไม่ระบุรายชื่อแพทย์"
    }));

  const selectedAppointment = props.SetAppointmentSequence?.selectedAppointment;
  
  console.log(props.SetAppointmentSequence);
  console.log(selectedProvider);
  console.log(props.searchedItemList)

  return(<div>
    <CardAppointmentManageUX
      tab={tab}
      setTab={setTab}
      localizer={localizer}
      appointmentList={appointmentList || []}
      blockList={blockList || []}
      eventPropGetter={(event: any, start: Date, end: Date, isSelected: boolean) => {
        return { 
          style: event?.providerNames?.length === 0 ? {backgroundColor: "pink", color: "black"} 
                  : {} as CSSProperties
        }
      }}
      appointmentRowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
        style: { 
          backgroundColor: (selectedAppointment && rowInfo?.original?.id &&
                           rowInfo?.original?.id === selectedAppointment?.id) ?
                          "#d6ecf3" : "white"
        },
        onClick: () => props.runSequence({
          sequence: "SetAppointment", action: "appointment", item: rowInfo?.original})
      })}
      onSelectEvent={(e: any) => {
        console.log(e);
        setSelectedBlock(e);
        setShowBlockDetail(true);
      }}
      onRangeChange={(e: any) => {
        // console.log(e);
        // let year = e.start?.getFullYear();
        // let month = e.start?.getMonth() + 1;
        // if (!Number.isInteger(year) || !Number.isInteger(month)) return;
        // if (e.start?.getDate() !== 1) {
        //   if (month !== 12) {
        //     month = month + 1
        //   } else {
        //     month = 1;
        //     year += 1;
        //   }
        // }
        // props.onEvent({ 
        //   message: "FilterSchedule", 
        //   params: { range: {year, month} }})
      }}
      providerSelector={
        <SearchBox
          onEvent={props.onEvent}
          setProp={props.setProp}
          searchedItemList={props.searchedItemList}
          selectedItem={selectedProvider}
          setSelectedItem={setSelectedProvider}
          type="Provider"
          label="ค้นหาแพทย์"
          placeholder="กรอกชื่อแพทย์ที่ต้องการค้นหา"
          toDisplay={(item: any) => `${item.first_name} ${item.last_name}`}
        />
      }
    />
    <Modal
      closeOnDimmerClick={true}
      open={showBlockDetail}
      onClose={() => setShowBlockDetail(false)}>
      <ModBlockDetailUX
        providers={selectedBlock?.providers?.map((item: any) => item?.dsb)}
        rowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
          style: { 
            backgroundColor: (selectedBlock && rowInfo?.original?.dsb_id &&
                            rowInfo?.original?.dsb_id === selectedBlock?.dsb_id) ?
                            "#d6ecf3" : "white"
          },
          onClick: async () => {
            setSelectedBlock(rowInfo?.original);
            await props.runSequence({
              sequence: "SetAppointment", action: "block", item: rowInfo?.original});
            setShowBlockDetail(false)
          }
        })}
      />
    </Modal>
  </div>)
}

export default CardAppointmentManage;
