import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'

const CardBillingReportUX = (props: any) => {
    return(
      <div
        style={{ zoom: "90%" }}>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem",padding: "10px 10px 0px 10px", display: "flex", justifyContent: "space-between"}}>
          
          <div>
            
          </div>
        </div>
        <div
          style={{ padding: "0px 10px 10px 10px"}}>
          
          <hr>
          </hr>
        </div>
        <div
          style={{ margin: "10px", width: "100%" }}>
          
          <div
            style={{ display : "flex", justifyContent:  "space-around" }}>
            
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("รายงาน Drawer Cash")}>
                รายงาน Drawer Cash
              </Button>
            </div>
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("รายงานรายได้")}>
                รายงานรายได้
              </Button>
            </div>
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("รายงานค่ารักษาพยาบาลค้างชำระ")}>
                รายงานค่ารักษาพยาบาลค้างชำระ
              </Button>
            </div>
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("รายการรอปรับปรุง Approval Code")}>
                รายการรอปรับปรุง Approval Code
              </Button>
            </div>
          </div>
          <div
            style={{ display : "flex", justifyContent:  "space-around", margin: "20px 0px" }}>
            
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("รายงาน Drawer Coverage")}>
                รายงาน Drawer Coverage
              </Button>
            </div>
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("รายงานรายได้ประจำวันประเภทเงินสด")}>
                รายงานรายได้ประจำวันประเภทเงินสด
              </Button>
            </div>
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("รายงานผ่านสิทธิไม่รับบริการ")}>
                รายงานผ่านสิทธิไม่รับบริการ
              </Button>
            </div>
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("รายงานผู้ป่วย Discharge")}>
                รายงานผู้ป่วย Discharge
              </Button>
            </div>
          </div>
          <div
            style={{ display : "flex", justifyContent:  "space-around", margin: "20px 0px" }}>
            
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("รายงานใบนำส่งเงิน")}>
                รายงานใบนำส่งเงิน
              </Button>
            </div>
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("รายงานรายได้ประจำวันประเภทเงินเชื่อ")}>
                รายงานรายได้ประจำวันประเภทเงินเชื่อ
              </Button>
            </div>
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("รายงานการผ่านสิทธิผ่านเครื่อง EDC")}>
                รายงานการผ่านสิทธิผ่านเครื่อง EDC
              </Button>
            </div>
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("รายงานผ่อนชำระ")}>
                รายงานผ่อนชำระ
              </Button>
            </div>
          </div>
          <div
            style={{ display : "flex", justifyContent:  "space-around" }}>
            
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("รายงานค่ารักษาพยาบาลแยกสิทธิ")}>
                รายงานค่ารักษาพยาบาลแยกสิทธิ
              </Button>
            </div>
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("รายงานการปฏิบัติงานของเจ้าหน้าที่")}>
                รายงานการปฏิบัติงานของเจ้าหน้าที่
              </Button>
            </div>
            <div
              style={{ width: "20%" }}>
              
            </div>
            <div
              style={{ width: "20%" }}>
              
            </div>
          </div>
        </div>
        <div
          style={{width: "100%", height: "200px"}}>
          
        </div>
      </div>
    )
}

export default CardBillingReportUX

export const screenPropsDefault = {"debug":true,"showSupplyList":false}

/* Date Time : Tue Mar 14 2023 08:03:18 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ zoom: \"90%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\",padding: \"10px 10px 0px 10px\", display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"200px\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 10px 10px 10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "hr",
      "parent": 87,
      "props": {
      },
      "seq": 88,
      "void": true
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px\", width: \"100%\" }"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display : \"flex\", justifyContent:  \"space-around\" }"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display : \"flex\", justifyContent:  \"space-around\", margin: \"20px 0px\" }"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display : \"flex\", justifyContent:  \"space-around\", margin: \"20px 0px\" }"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Button",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงาน Drawer Cash"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"รายงาน Drawer Cash\")"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "Button",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานรายได้"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"รายงานรายได้\")"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Button",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานค่ารักษาพยาบาลค้างชำระ"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"รายงานค่ารักษาพยาบาลค้างชำระ\")"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Button",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการรอปรับปรุง Approval Code"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"รายการรอปรับปรุง Approval Code\")"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Button",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงาน Drawer Coverage"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"รายงาน Drawer Coverage\")"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Button",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานรายได้ประจำวันประเภทเงินสด"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"รายงานรายได้ประจำวันประเภทเงินสด\")"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "Button",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานผ่านสิทธิไม่รับบริการ"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"รายงานผ่านสิทธิไม่รับบริการ\")"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "Button",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานผู้ป่วย Discharge"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"รายงานผู้ป่วย Discharge\")"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "Button",
      "parent": 107,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานรายได้ประจำวันประเภทเงินเชื่อ"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"รายงานรายได้ประจำวันประเภทเงินเชื่อ\")"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "Button",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานการผ่านสิทธิผ่านเครื่อง EDC"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"รายงานการผ่านสิทธิผ่านเครื่อง EDC\")"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "Button",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานผ่อนชำระ"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"รายงานผ่อนชำระ\")"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "Button",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานใบนำส่งเงิน"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"รายงานใบนำส่งเงิน\")"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display : \"flex\", justifyContent:  \"space-around\" }"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "Button",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานค่ารักษาพยาบาลแยกสิทธิ"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"รายงานค่ารักษาพยาบาลแยกสิทธิ\")"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "Button",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานการปฏิบัติงานของเจ้าหน้าที่"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"รายงานการปฏิบัติงานของเจ้าหน้าที่\")"
        }
      },
      "seq": 128,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardBillingReportUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "debug": true,
    "showSupplyList": false
  },
  "width": 80
}

*********************************************************************************** */
