import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Icon,
  Dropdown,
  Input
} from 'semantic-ui-react'

const CardDischargeTeachingUX = (props: any) => {
    return(
      <div
        style={{padding: "10px"}}>
        <div
          style={{display: "flex", justifyContent: "space-between"}}>
          
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <div
              className="ui label yellow ribbon">
              DISCHARGE TEACHING
            </div>
          </div>
          <div
            style={{display: "flex"}}>
            
            <Label
              color="yellow"
              style={{display: props.showStatus ? "" : "none"}}>
              Draft
            </Label>
            <div
              style={{cursor: "pointer", margin: "-5px -5px 0 15px", display: props.closeIcon ? "" : "none"}}>
              
              <Icon
                className="red large"
                name={"close"}
                onClick={props.onClose}>
              </Icon>
            </div>
          </div>
        </div>
        <div
          style={{paddingTop:"10px"}}>
          
          <div
            style={{ display: "grid", gridTemplateColumns: "repeat(13, minmax(0, 1fr))", }}>
            
            <div
              style={{ gridColumn: "1/3", fontWeight: "bold" ,display:"flex" }}>
              ผู้ป่วยเเละครอบครัวได้รับการสอน /คำเเนะนำ
            </div>
            <div>
              ผู้รับการสอน
            </div>
            <div>
              ชื่อผู้รับ
            </div>
            <div>
              วิธีการให้ความรู้
            </div>
            <div>
              ระดับความรู้ก่อนสอน
            </div>
            <div>
              ผู้ประเมินก่อนสอน
            </div>
            <div>
              ระดับความรู้สอนครั้งที่ 1 
            </div>
            <div>
              ผู้ประเมินครั้งที่ 1 
            </div>
            <div>
              ระดับความรู้สอนครั้งที่ 2
            </div>
            <div>
              ผู้ประเมินครั้งที่ 2 
            </div>
            <div>
              ระดับความรู้สอนก่อนกลับบ้าน
            </div>
            <div>
              ผู้ประเมินก่อนกลับบ้าน
            </div>
            <div
              style={{ gridColumn: "1/3", fontWeight: "bold" ,display:"flex" }}>
              {`1. ${props.ClinicalTermTeaching?.disc_teaching1?.name}`}
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="relative"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.main_relative")}
                options={props.options}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.main_relative}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.main_relative_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.main_relative_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="method"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.main_method")}
                options={props.optionsmethod}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.main_method}>
              </Dropdown>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.main_before")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.main_before}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.main_before_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.main_before_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.main_first")}
                options={props.optionstype}
                search={true}
                selection={true}
                value="props.ClinicalTermTeaching?.disc_teaching1?.main_first">
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.main_first_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.main_first_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.main_second")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.main_second}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.main_second_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.main_second_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.main_home")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.main_home}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.main_home_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.main_home_user_name}>
              </Input>
            </div>
            <div
              style={{ gridColumn: "1/3" ,display:"flex" }}>
              {`- ${props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.name}`}
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="relative"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.items.0.sub_relative")}
                options={props.options}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_relative}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.items.0.sub_relative_name")}
                style={{width:"100%" }}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_relative_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="method"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.items.0.sub_medthod")}
                options={props.optionsmethod}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_method}>
              </Dropdown>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onChange={props.handleChangeValue("disc_teaching1.items.0.sub_before")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_before}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.items.0.sub_before_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_before_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.items.0.sub_first")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_first}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.items.0.sub_first_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_first_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.items.0.sub_second")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_second}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.items.0.sub_second_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_second_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.items.0.sub_home")}
                options={props.optionstype}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_home}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.items.0.sub_home_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_home_user_name}>
              </Input>
            </div>
            <div
              style={{ gridColumn: "1/3" ,display:"flex" }}>
              {`- ${props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.name}`}
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="relative"
                onChange={props.handleChangeValue("disc_teaching1.items.1.sub_relative")}
                options={props.options}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_relative}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.items.1.sub_relative_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_relative_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="method"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.items.1.sub_medthod")}
                options={props.optionsmethod}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_method}>
              </Dropdown>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.items.1.sub_before")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_before}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.items.1.sub_before_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_before_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.items.1.sub_first")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_first}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.items.1.sub_first_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_first_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.items.1.sub_second")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_second}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.items.1.sub_second_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_second_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.items.1.sub_home")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_home}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.items.1.sub_home_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_home_user_name}>
              </Input>
            </div>
            <div
              style={{ gridColumn: "1/3" ,display:"flex" }}>
              {`- ${props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.name}`}
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="relative"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.items.2.sub_relative")}
                options={props.options}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_relative}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.items.2.sub_relative_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_relative_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="method"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.items.2.sub_medthod")}
                options={props.optionsmethod}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_method}>
              </Dropdown>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.items.2.sub_before")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_before}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.items.2.sub_before_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_before_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.items.2.sub_first")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_first}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.items.2.sub_first_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_first_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.items.2.sub_second")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_second}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.items.2.sub_second_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_second_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                defaultValue={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching1.items.2.sub_home")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_home}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching1.items.2.sub_home_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_home_user_name}>
              </Input>
            </div>
            <div
              style={{ gridColumn: "1/3", fontWeight: "bold" ,display:"flex" }}>
              {`2. ${props.ClinicalTermTeaching?.disc_teaching2?.name}`}
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="relative"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching2.main_relative")}
                options={props.options}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching2?.main_relative}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching2.main_relative_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching2?.main_relative_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="method"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching2.main_method")}
                options={props.optionsmethod}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching2?.main_method}>
              </Dropdown>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching2.main_before")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching2?.main_before}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching2.main_before_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching2?.main_before_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching2.main_first")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching2?.main_first}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching2.main_first_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching2?.main_first_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching2.main_second")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching2?.main_second}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching2.main_second_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching2?.main_second_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching2.main_home")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching2?.main_home}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching2.main_home_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching2?.main_home_user_name}>
              </Input>
            </div>
            <div
              style={{ gridColumn: "1/3", fontWeight: "bold" ,display:"flex" }}>
              {`3. ${props.ClinicalTermTeaching?.disc_teaching3?.name}`}
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="relative"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching3.main_relative")}
                options={props.options}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching3?.main_relative}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching3.main_relative_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching3?.main_relative_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="method"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching3.main_method")}
                options={props.optionsmethod}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching3?.main_method}>
              </Dropdown>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching3.main_before")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching3?.main_before}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching3.main_before_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching3?.main_before_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching3.main_first")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching3?.main_first}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching3.main_first_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching3?.main_first_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching3.main_second")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching3?.main_second}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching3.main_second_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching3?.main_second_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching3.main_home")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching3?.main_home}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching3.main_home_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching3?.main_home_user_name}>
              </Input>
            </div>
            <div
              style={{ gridColumn: "1/3", fontWeight: "bold" ,display:"flex" }}>
              {`4. ${props.ClinicalTermTeaching?.disc_teaching4?.name}`}
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="relative"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching4.main_relative")}
                options={props.options}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching4?.main_relative}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching4.main_relative_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching4?.main_relative_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="method"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching4.main_method")}
                options={props.optionsmethod}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching4?.main_method}>
              </Dropdown>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching4.main_before")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching4?.main_before}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching4.main_before_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching4?.main_before_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching4.main_first")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching4?.main_first}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching4.main_first_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching4?.main_first_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching4.main_second")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching4?.main_second}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching4.main_second_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching4?.main_second_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching4.main_home")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching4?.main_home}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching4.main_home_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching4?.main_home_user_name}>
              </Input>
            </div>
            <div
              style={{ gridColumn: "1/3", fontWeight: "bold" ,display:"flex" }}>
              {`5. ${props.ClinicalTermTeaching?.disc_teaching5?.name}`}
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="relative"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching5.main_relative")}
                options={props.options}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching5?.main_relative}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching5.main_relative_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching5?.main_relative_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="method"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching5.main_method")}
                options={props.optionsmethod}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching5?.main_method}>
              </Dropdown>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching5.main_before")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching5?.main_before}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching5.main_before_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching5?.main_before_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching5.main_first")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching5?.main_first}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching5.main_first_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching5?.main_first_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching5.main_second")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching5?.main_second}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching5.main_second_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching5?.main_second_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching5.main_home")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching5?.main_home}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching5.main_home_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching5?.main_home_user_name}>
              </Input>
            </div>
            <div
              style={{ gridColumn: "1/3", fontWeight: "bold" ,display:"flex" }}>
              {`6. ${props.ClinicalTermTeaching?.disc_teaching6?.name}`}
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="relative"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching6.main_relative")}
                options={props.options}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching6?.main_relative}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching6.main_relative_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching6?.main_relative_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="method"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching6.main_method")}
                options={props.optionsmethod}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching6?.main_method}>
              </Dropdown>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching6.main_before")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching6?.main_before}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching6.main_before_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching6?.main_before_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching6.main_first")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching6?.main_first}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching6.main_first_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching6?.main_first_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching6.main_second")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching6?.main_second}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching6.main_second_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching6?.main_second_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching6.main_home")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching6?.main_home}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching6.main_home_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching6?.main_home_user_name}>
              </Input>
            </div>
            <div
              style={{ gridColumn: "1/3", fontWeight: "bold" ,display:"flex" }}>
              {`7. ${props.ClinicalTermTeaching?.disc_teaching7?.name}`}
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="relative"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.main_relative")}
                options={props.options}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.main_relative}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.main_relative_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.main_relative_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="method"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.main_method")}
                options={props.optionsmethod}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.main_method}>
              </Dropdown>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.main_before")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.main_before}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.main_before_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.main_before_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.main_first")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.main_first}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.main_first_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.main_first_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.main_second")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.main_second}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.main_second_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.main_second_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.main_home")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.main_home}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.main_home_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.main_home_user_name}>
              </Input>
            </div>
            <div
              style={{ gridColumn: "1/3" ,display:"flex" }}>
              {`- ${props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.name}`}
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="relative"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.items.0.sub_relative")}
                options={props.options}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_relative}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.items.0.sub_relative_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_relative_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="method"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.items.0.sub_medthod")}
                options={props.optionsmethod}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_method}>
              </Dropdown>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.items.0.sub_before")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_before}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.items.0.sub_before_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_before_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_first}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.items.0.sub_first_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_first_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.items.0.sub_second")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_second}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.items.0.sub_second_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_second_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.items.0.sub_home")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_home}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.items.0.sub_home_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_home_user_name}>
              </Input>
            </div>
            <div
              style={{ gridColumn: "1/3" ,display:"flex" }}>
              {`- ${props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.name}`}
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="relative"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.items.1.sub_relative")}
                options={props.options}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_relative}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.items.1.sub_relative_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_relative_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="method"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.items.1.sub_medthod")}
                options={props.optionsmethod}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_method}>
              </Dropdown>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.items.1.sub_before")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_before}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.items.1.sub_before_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_before_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_first}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.items.1.sub_first_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_first_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.items.1.sub_second")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_second}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.items.1.sub_second_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_second_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.items.1.sub_home")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_home}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.items.1.sub_home_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_home_user_name}>
              </Input>
            </div>
            <div
              style={{ gridColumn: "1/3" ,display:"flex" }}>
              {`- ${props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.name}`}
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="relative"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.items.2.sub_relative")}
                options={props.options}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_relative}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.items.2.sub_relative_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_relative_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="method"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.items.2.sub_medthod")}
                options={props.optionsmethod}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_method}>
              </Dropdown>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.items2.sub_before")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_before}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.items.2.sub_before_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_before_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_first}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.items.2.sub_first_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_first_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.items.2.sub_second")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_second}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.items.2.sub_second_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_second_user_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching7.items.2.sub_home")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_home}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching7.items.2.sub_home_user_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_home_user_name}>
              </Input>
            </div>
            <div
              style={{ gridColumn: "1/3", fontWeight: "bold" ,display:"flex" }}>
              {`8. ${props.ClinicalTermTeaching?.disc_teaching8?.name}`}
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="relative"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching8.main_relative")}
                options={props.options}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching8?.main_relative}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                onChange={props.handleChangeValue("disc_teaching8.main_relative_name")}
                style={{width:"100%"}}
                value={props.ClinicalTermTeaching?.disc_teaching8?.main_relative_name}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="method"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching8.main_method")}
                options={props.optionsmethod}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching8?.main_method}>
              </Dropdown>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching8.main_before")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching8?.main_before}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                style={{width:"100%"}}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching8.main_first")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching8?.main_first}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                style={{width:"100%"}}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching8.main_second")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching8?.main_second}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                style={{width:"100%"}}>
              </Input>
            </div>
            <div>
              
              <Dropdown
                allowAdditions={true}
                fluid={true}
                multiple={true}
                name="knowledge"
                onAddItem={props.onAddOptions}
                onChange={props.handleChangeValue("disc_teaching8.main_home")}
                options={props.optionstype}
                search={true}
                selection={true}
                value={props.ClinicalTermTeaching?.disc_teaching8?.main_home}>
              </Dropdown>
            </div>
            <div>
              
              <Input
                style={{width:"100%"}}>
              </Input>
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardDischargeTeachingUX

export const screenPropsDefault = {"drugSearch":"[Drug Search Box]"}

/* Date Time : Thu Jun 30 2022 12:23:44 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "DISCHARGE TEACHING"
        },
        "className": {
          "type": "value",
          "value": "ui label yellow ribbon"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Label",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "Draft"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showStatus ? \"\" : \"none\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", margin: \"-5px -5px 0 15px\", display: props.closeIcon ? \"\" : \"none\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Icon",
      "parent": 102,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "name": {
          "type": "code",
          "value": "\"close\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop:\"10px\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"grid\", gridTemplateColumns: \"repeat(13, minmax(0, 1fr))\", }"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ป่วยเเละครอบครัวได้รับการสอน /คำเเนะนำ"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/3\", fontWeight: \"bold\" ,display:\"flex\" }"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้รับการสอน"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อผู้รับ"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "วิธีการให้ความรู้"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "ระดับความรู้ก่อนสอน"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ประเมินก่อนสอน"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "ระดับความรู้สอนครั้งที่ 1 "
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ประเมินครั้งที่ 1 "
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "ระดับความรู้สอนครั้งที่ 2"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ประเมินครั้งที่ 2 "
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "ระดับความรู้สอนก่อนกลับบ้าน"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ประเมินก่อนกลับบ้าน"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "code",
          "value": "`1. ${props.ClinicalTermTeaching?.disc_teaching1?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/3\", fontWeight: \"bold\" ,display:\"flex\" }"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "Dropdown",
      "parent": 133,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "relative"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.main_relative\")"
        },
        "options": {
          "type": "code",
          "value": "props.options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.main_relative"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "Dropdown",
      "parent": 136,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "method"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.main_method\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionsmethod"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.main_method"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "Dropdown",
      "parent": 137,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.main_before\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.main_before"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "Dropdown",
      "parent": 139,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.main_first\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "value",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.main_first"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "Dropdown",
      "parent": 141,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.main_second\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.main_second"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "Dropdown",
      "parent": 143,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.main_home\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.main_home"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "Input",
      "parent": 135,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.main_relative_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.main_relative_name"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "code",
          "value": "`- ${props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/3\" ,display:\"flex\" }"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "Input",
      "parent": 158,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.0.sub_relative_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_relative_name"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "Dropdown",
      "parent": 157,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "relative"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.0.sub_relative\")"
        },
        "options": {
          "type": "code",
          "value": "props.options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_relative"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "Dropdown",
      "parent": 159,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "method"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.0.sub_medthod\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionsmethod"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_method"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "Dropdown",
      "parent": 160,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.0.sub_before\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_before"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 173,
      "name": "Dropdown",
      "parent": 162,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.0.sub_first\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_first"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "Dropdown",
      "parent": 164,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.0.sub_second\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_second"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "Dropdown",
      "parent": 166,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.0.sub_home\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": ""
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_home"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "code",
          "value": "`- ${props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/3\" ,display:\"flex\" }"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 186,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 191,
      "name": "Input",
      "parent": 181,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.1.sub_relative_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_relative_name"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 192,
      "name": "Dropdown",
      "parent": 180,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "relative"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.1.sub_relative\")"
        },
        "options": {
          "type": "code",
          "value": "props.options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_relative"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 193,
      "name": "Dropdown",
      "parent": 182,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "method"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.1.sub_medthod\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionsmethod"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_method"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 194,
      "name": "Dropdown",
      "parent": 183,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.1.sub_before\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_before"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "Dropdown",
      "parent": 185,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.1.sub_first\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_first"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "Dropdown",
      "parent": 187,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.1.sub_second\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_second"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "Dropdown",
      "parent": 189,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true",
          "valueEN": ""
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.1.sub_home\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_home"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": null,
      "id": 202,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "code",
          "value": "`- ${props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/3\" ,display:\"flex\" }"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": null,
      "id": 203,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": null,
      "id": 204,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": null,
      "id": 205,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": null,
      "id": 206,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": null,
      "id": 207,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": null,
      "id": 208,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": null,
      "id": 209,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": null,
      "id": 210,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": null,
      "id": 211,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": null,
      "id": 212,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": null,
      "id": 213,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 214,
      "name": "Input",
      "parent": 204,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.2.sub_relative_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_relative_name"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 215,
      "name": "Dropdown",
      "parent": 203,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "relative"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.2.sub_relative\")"
        },
        "options": {
          "type": "code",
          "value": "props.options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_relative"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "Dropdown",
      "parent": 205,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "method"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.2.sub_medthod\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionsmethod"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_method"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 217,
      "name": "Dropdown",
      "parent": 206,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.2.sub_before\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_before"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 219,
      "name": "Dropdown",
      "parent": 208,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.2.sub_first\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_first"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 221,
      "name": "Dropdown",
      "parent": 210,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.2.sub_second\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_second"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 223,
      "name": "Dropdown",
      "parent": 212,
      "props": {
        "defaultValue": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.2.sub_home\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_home"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": null,
      "id": 225,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "code",
          "value": "`2. ${props.ClinicalTermTeaching?.disc_teaching2?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/3\", fontWeight: \"bold\" ,display:\"flex\" }"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": null,
      "id": 226,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": null,
      "id": 227,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": null,
      "id": 228,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": null,
      "id": 229,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": null,
      "id": 230,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": null,
      "id": 231,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": null,
      "id": 232,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": null,
      "id": 233,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": null,
      "id": 234,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": null,
      "id": 235,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": null,
      "id": 236,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 237,
      "name": "Input",
      "parent": 227,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching2.main_relative_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching2?.main_relative_name"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 238,
      "name": "Dropdown",
      "parent": 226,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "relative"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching2.main_relative\")"
        },
        "options": {
          "type": "code",
          "value": "props.options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching2?.main_relative"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 239,
      "name": "Dropdown",
      "parent": 228,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "method"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching2.main_method\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionsmethod"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching2?.main_method"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 240,
      "name": "Dropdown",
      "parent": 229,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching2.main_before\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching2?.main_before"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 242,
      "name": "Dropdown",
      "parent": 231,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching2.main_first\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching2?.main_first"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 244,
      "name": "Dropdown",
      "parent": 233,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching2.main_second\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching2?.main_second"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 246,
      "name": "Dropdown",
      "parent": 235,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching2.main_home\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching2?.main_home"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": null,
      "id": 248,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "code",
          "value": "`3. ${props.ClinicalTermTeaching?.disc_teaching3?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/3\", fontWeight: \"bold\" ,display:\"flex\" }"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": null,
      "id": 249,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": null,
      "id": 250,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": null,
      "id": 251,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": null,
      "id": 252,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": null,
      "id": 253,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": null,
      "id": 254,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": null,
      "id": 255,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": null,
      "id": 256,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": null,
      "id": 257,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": null,
      "id": 258,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": null,
      "id": 259,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 260,
      "name": "Input",
      "parent": 250,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching3.main_relative_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching3?.main_relative_name"
        }
      },
      "seq": 260,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 261,
      "name": "Dropdown",
      "parent": 249,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "relative"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching3.main_relative\")"
        },
        "options": {
          "type": "code",
          "value": "props.options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching3?.main_relative"
        }
      },
      "seq": 261,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 262,
      "name": "Dropdown",
      "parent": 251,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "method"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching3.main_method\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionsmethod"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching3?.main_method"
        }
      },
      "seq": 262,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 263,
      "name": "Dropdown",
      "parent": 252,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching3.main_before\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching3?.main_before"
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 265,
      "name": "Dropdown",
      "parent": 254,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching3.main_first\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching3?.main_first"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 267,
      "name": "Dropdown",
      "parent": 256,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching3.main_second\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching3?.main_second"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 269,
      "name": "Dropdown",
      "parent": 258,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching3.main_home\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching3?.main_home"
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": null,
      "id": 271,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "code",
          "value": "`4. ${props.ClinicalTermTeaching?.disc_teaching4?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/3\", fontWeight: \"bold\" ,display:\"flex\" }"
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": null,
      "id": 272,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": null,
      "id": 273,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": null,
      "id": 274,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": null,
      "id": 275,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": null,
      "id": 276,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": null,
      "id": 277,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 278,
      "void": false
    },
    {
      "from": null,
      "id": 278,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": null,
      "id": 279,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": null,
      "id": 280,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": null,
      "id": 281,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": null,
      "id": 282,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 283,
      "name": "Input",
      "parent": 273,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching4.main_relative_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching4?.main_relative_name"
        }
      },
      "seq": 283,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 284,
      "name": "Dropdown",
      "parent": 272,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "relative"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching4.main_relative\")"
        },
        "options": {
          "type": "code",
          "value": "props.options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching4?.main_relative"
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 285,
      "name": "Dropdown",
      "parent": 274,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "method"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching4.main_method\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionsmethod"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching4?.main_method"
        }
      },
      "seq": 285,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 286,
      "name": "Dropdown",
      "parent": 275,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching4.main_before\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching4?.main_before"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 289,
      "name": "Dropdown",
      "parent": 278,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching4.main_first\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching4?.main_first"
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 290,
      "name": "Dropdown",
      "parent": 279,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching4.main_second\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching4?.main_second"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 291,
      "name": "Dropdown",
      "parent": 281,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "compact": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching4.main_home\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching4?.main_home"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": null,
      "id": 294,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "code",
          "value": "`5. ${props.ClinicalTermTeaching?.disc_teaching5?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/3\", fontWeight: \"bold\" ,display:\"flex\" }"
        }
      },
      "seq": 294,
      "void": false
    },
    {
      "from": null,
      "id": 295,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 295,
      "void": false
    },
    {
      "from": null,
      "id": 296,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 296,
      "void": false
    },
    {
      "from": null,
      "id": 297,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": null,
      "id": 298,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 298,
      "void": false
    },
    {
      "from": null,
      "id": 299,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": null,
      "id": 300,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": null,
      "id": 301,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 301,
      "void": false
    },
    {
      "from": null,
      "id": 302,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 302,
      "void": false
    },
    {
      "from": null,
      "id": 303,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 303,
      "void": false
    },
    {
      "from": null,
      "id": 304,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 304,
      "void": false
    },
    {
      "from": null,
      "id": 305,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 305,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 306,
      "name": "Input",
      "parent": 296,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching5.main_relative_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching5?.main_relative_name"
        }
      },
      "seq": 306,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 307,
      "name": "Dropdown",
      "parent": 295,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "relative"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching5.main_relative\")"
        },
        "options": {
          "type": "code",
          "value": "props.options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching5?.main_relative"
        }
      },
      "seq": 307,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 308,
      "name": "Dropdown",
      "parent": 297,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "method"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching5.main_method\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionsmethod"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching5?.main_method"
        }
      },
      "seq": 308,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 309,
      "name": "Dropdown",
      "parent": 298,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching5.main_before\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching5?.main_before"
        }
      },
      "seq": 309,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 311,
      "name": "Dropdown",
      "parent": 300,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching5.main_first\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching5?.main_first"
        }
      },
      "seq": 311,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 313,
      "name": "Dropdown",
      "parent": 302,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching5.main_second\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching5?.main_second"
        }
      },
      "seq": 313,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 315,
      "name": "Dropdown",
      "parent": 304,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching5.main_home\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching5?.main_home"
        }
      },
      "seq": 315,
      "void": false
    },
    {
      "from": null,
      "id": 317,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "code",
          "value": "`6. ${props.ClinicalTermTeaching?.disc_teaching6?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/3\", fontWeight: \"bold\" ,display:\"flex\" }"
        }
      },
      "seq": 317,
      "void": false
    },
    {
      "from": null,
      "id": 318,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 318,
      "void": false
    },
    {
      "from": null,
      "id": 319,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 319,
      "void": false
    },
    {
      "from": null,
      "id": 320,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 320,
      "void": false
    },
    {
      "from": null,
      "id": 321,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 321,
      "void": false
    },
    {
      "from": null,
      "id": 322,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 322,
      "void": false
    },
    {
      "from": null,
      "id": 323,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 323,
      "void": false
    },
    {
      "from": null,
      "id": 324,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 324,
      "void": false
    },
    {
      "from": null,
      "id": 325,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 325,
      "void": false
    },
    {
      "from": null,
      "id": 326,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 326,
      "void": false
    },
    {
      "from": null,
      "id": 327,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 327,
      "void": false
    },
    {
      "from": null,
      "id": 328,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 328,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 329,
      "name": "Input",
      "parent": 319,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching6.main_relative_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching6?.main_relative_name"
        }
      },
      "seq": 329,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 330,
      "name": "Dropdown",
      "parent": 318,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "relative"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching6.main_relative\")"
        },
        "options": {
          "type": "code",
          "value": "props.options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching6?.main_relative"
        }
      },
      "seq": 330,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 331,
      "name": "Dropdown",
      "parent": 320,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "method"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching6.main_method\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionsmethod"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching6?.main_method"
        }
      },
      "seq": 331,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 332,
      "name": "Dropdown",
      "parent": 321,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching6.main_before\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching6?.main_before"
        }
      },
      "seq": 332,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 334,
      "name": "Dropdown",
      "parent": 323,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching6.main_first\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching6?.main_first"
        }
      },
      "seq": 334,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 336,
      "name": "Dropdown",
      "parent": 325,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching6.main_second\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching6?.main_second"
        }
      },
      "seq": 336,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 338,
      "name": "Dropdown",
      "parent": 327,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching6.main_home\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching6?.main_home"
        }
      },
      "seq": 338,
      "void": false
    },
    {
      "from": null,
      "id": 340,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "code",
          "value": "`7. ${props.ClinicalTermTeaching?.disc_teaching7?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/3\", fontWeight: \"bold\" ,display:\"flex\" }"
        }
      },
      "seq": 340,
      "void": false
    },
    {
      "from": null,
      "id": 341,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 341,
      "void": false
    },
    {
      "from": null,
      "id": 342,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 342,
      "void": false
    },
    {
      "from": null,
      "id": 343,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 343,
      "void": false
    },
    {
      "from": null,
      "id": 344,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 344,
      "void": false
    },
    {
      "from": null,
      "id": 345,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 345,
      "void": false
    },
    {
      "from": null,
      "id": 346,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 346,
      "void": false
    },
    {
      "from": null,
      "id": 347,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 347,
      "void": false
    },
    {
      "from": null,
      "id": 348,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 348,
      "void": false
    },
    {
      "from": null,
      "id": 349,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 349,
      "void": false
    },
    {
      "from": null,
      "id": 350,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 350,
      "void": false
    },
    {
      "from": null,
      "id": 351,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 351,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 352,
      "name": "Input",
      "parent": 342,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.main_relative_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.main_relative_name"
        }
      },
      "seq": 352,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 353,
      "name": "Dropdown",
      "parent": 341,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "relative"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.main_relative\")"
        },
        "options": {
          "type": "code",
          "value": "props.options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.main_relative"
        }
      },
      "seq": 353,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 354,
      "name": "Dropdown",
      "parent": 343,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "method"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.main_method\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionsmethod"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.main_method"
        }
      },
      "seq": 354,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 355,
      "name": "Dropdown",
      "parent": 344,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.main_before\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.main_before"
        }
      },
      "seq": 355,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 357,
      "name": "Dropdown",
      "parent": 346,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.main_first\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.main_first"
        }
      },
      "seq": 357,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 359,
      "name": "Dropdown",
      "parent": 348,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.main_second\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.main_second"
        }
      },
      "seq": 359,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 361,
      "name": "Dropdown",
      "parent": 350,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.main_home\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.main_home"
        }
      },
      "seq": 361,
      "void": false
    },
    {
      "from": null,
      "id": 363,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "code",
          "value": "`- ${props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/3\" ,display:\"flex\" }"
        }
      },
      "seq": 363,
      "void": false
    },
    {
      "from": null,
      "id": 364,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 364,
      "void": false
    },
    {
      "from": null,
      "id": 365,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 365,
      "void": false
    },
    {
      "from": null,
      "id": 366,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 366,
      "void": false
    },
    {
      "from": null,
      "id": 367,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 367,
      "void": false
    },
    {
      "from": null,
      "id": 368,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 368,
      "void": false
    },
    {
      "from": null,
      "id": 369,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 369,
      "void": false
    },
    {
      "from": null,
      "id": 370,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 370,
      "void": false
    },
    {
      "from": null,
      "id": 371,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 371,
      "void": false
    },
    {
      "from": null,
      "id": 372,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 372,
      "void": false
    },
    {
      "from": null,
      "id": 373,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 373,
      "void": false
    },
    {
      "from": null,
      "id": 374,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 374,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 375,
      "name": "Input",
      "parent": 365,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.0.sub_relative_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_relative_name"
        }
      },
      "seq": 375,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 376,
      "name": "Dropdown",
      "parent": 364,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "relative"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.0.sub_relative\")"
        },
        "options": {
          "type": "code",
          "value": "props.options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_relative"
        }
      },
      "seq": 376,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 377,
      "name": "Dropdown",
      "parent": 366,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "method"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.0.sub_medthod\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionsmethod"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_method"
        }
      },
      "seq": 377,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 378,
      "name": "Dropdown",
      "parent": 367,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.0.sub_before\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_before"
        }
      },
      "seq": 378,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 380,
      "name": "Dropdown",
      "parent": 369,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_first"
        }
      },
      "seq": 380,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 382,
      "name": "Dropdown",
      "parent": 371,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.0.sub_second\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_second"
        }
      },
      "seq": 382,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 384,
      "name": "Dropdown",
      "parent": 373,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.0.sub_home\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_home"
        }
      },
      "seq": 384,
      "void": false
    },
    {
      "from": null,
      "id": 386,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "code",
          "value": "`- ${props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/3\" ,display:\"flex\" }"
        }
      },
      "seq": 386,
      "void": false
    },
    {
      "from": null,
      "id": 387,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 387,
      "void": false
    },
    {
      "from": null,
      "id": 388,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 388,
      "void": false
    },
    {
      "from": null,
      "id": 389,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 389,
      "void": false
    },
    {
      "from": null,
      "id": 390,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 390,
      "void": false
    },
    {
      "from": null,
      "id": 391,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 391,
      "void": false
    },
    {
      "from": null,
      "id": 392,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 392,
      "void": false
    },
    {
      "from": null,
      "id": 393,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 393,
      "void": false
    },
    {
      "from": null,
      "id": 394,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 394,
      "void": false
    },
    {
      "from": null,
      "id": 395,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 395,
      "void": false
    },
    {
      "from": null,
      "id": 396,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 396,
      "void": false
    },
    {
      "from": null,
      "id": 397,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 397,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 398,
      "name": "Input",
      "parent": 388,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.1.sub_relative_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_relative_name"
        }
      },
      "seq": 398,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 399,
      "name": "Dropdown",
      "parent": 387,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "relative"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.1.sub_relative\")"
        },
        "options": {
          "type": "code",
          "value": "props.options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_relative"
        }
      },
      "seq": 399,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 400,
      "name": "Dropdown",
      "parent": 389,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "method"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.1.sub_medthod\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionsmethod"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_method"
        }
      },
      "seq": 400,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 401,
      "name": "Dropdown",
      "parent": 390,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.1.sub_before\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_before"
        }
      },
      "seq": 401,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 403,
      "name": "Dropdown",
      "parent": 392,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_first"
        }
      },
      "seq": 403,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 405,
      "name": "Dropdown",
      "parent": 394,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.1.sub_second\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_second"
        }
      },
      "seq": 405,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 407,
      "name": "Dropdown",
      "parent": 396,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "value",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.1.sub_home\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_home"
        }
      },
      "seq": 407,
      "void": false
    },
    {
      "from": null,
      "id": 409,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "code",
          "value": "`- ${props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/3\" ,display:\"flex\" }"
        }
      },
      "seq": 409,
      "void": false
    },
    {
      "from": null,
      "id": 410,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 410,
      "void": false
    },
    {
      "from": null,
      "id": 411,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 411,
      "void": false
    },
    {
      "from": null,
      "id": 412,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 412,
      "void": false
    },
    {
      "from": null,
      "id": 413,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 413,
      "void": false
    },
    {
      "from": null,
      "id": 414,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 414,
      "void": false
    },
    {
      "from": null,
      "id": 415,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 415,
      "void": false
    },
    {
      "from": null,
      "id": 416,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 416,
      "void": false
    },
    {
      "from": null,
      "id": 417,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 417,
      "void": false
    },
    {
      "from": null,
      "id": 418,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 418,
      "void": false
    },
    {
      "from": null,
      "id": 419,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 419,
      "void": false
    },
    {
      "from": null,
      "id": 420,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 420,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 421,
      "name": "Input",
      "parent": 411,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.2.sub_relative_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_relative_name"
        }
      },
      "seq": 421,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 422,
      "name": "Dropdown",
      "parent": 410,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "relative"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.2.sub_relative\")"
        },
        "options": {
          "type": "code",
          "value": "props.options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_relative"
        }
      },
      "seq": 422,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 423,
      "name": "Dropdown",
      "parent": 412,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "method"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.2.sub_medthod\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionsmethod"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_method"
        }
      },
      "seq": 423,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 424,
      "name": "Dropdown",
      "parent": 413,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items2.sub_before\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_before"
        }
      },
      "seq": 424,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 426,
      "name": "Dropdown",
      "parent": 415,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_first"
        }
      },
      "seq": 426,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 428,
      "name": "Dropdown",
      "parent": 417,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.2.sub_second\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_second"
        }
      },
      "seq": 428,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 430,
      "name": "Dropdown",
      "parent": 419,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "value",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.2.sub_home\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_home"
        }
      },
      "seq": 430,
      "void": false
    },
    {
      "from": null,
      "id": 432,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "code",
          "value": "`8. ${props.ClinicalTermTeaching?.disc_teaching8?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/3\", fontWeight: \"bold\" ,display:\"flex\" }"
        }
      },
      "seq": 432,
      "void": false
    },
    {
      "from": null,
      "id": 433,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 433,
      "void": false
    },
    {
      "from": null,
      "id": 434,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 434,
      "void": false
    },
    {
      "from": null,
      "id": 435,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 435,
      "void": false
    },
    {
      "from": null,
      "id": 436,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 436,
      "void": false
    },
    {
      "from": null,
      "id": 437,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 437,
      "void": false
    },
    {
      "from": null,
      "id": 438,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 438,
      "void": false
    },
    {
      "from": null,
      "id": 439,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 439,
      "void": false
    },
    {
      "from": null,
      "id": 440,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 440,
      "void": false
    },
    {
      "from": null,
      "id": 441,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 441,
      "void": false
    },
    {
      "from": null,
      "id": 442,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 442,
      "void": false
    },
    {
      "from": null,
      "id": 443,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 443,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 444,
      "name": "Input",
      "parent": 434,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching8.main_relative_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching8?.main_relative_name"
        }
      },
      "seq": 444,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 445,
      "name": "Dropdown",
      "parent": 433,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "relative"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching8.main_relative\")"
        },
        "options": {
          "type": "code",
          "value": "props.options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching8?.main_relative"
        }
      },
      "seq": 445,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 446,
      "name": "Dropdown",
      "parent": 435,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "method"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching8.main_method\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionsmethod"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching8?.main_method"
        }
      },
      "seq": 446,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 447,
      "name": "Dropdown",
      "parent": 436,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching8.main_before\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching8?.main_before"
        }
      },
      "seq": 447,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 449,
      "name": "Dropdown",
      "parent": 438,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching8.main_first\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching8?.main_first"
        }
      },
      "seq": 449,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 451,
      "name": "Dropdown",
      "parent": 440,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching8.main_second\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching8?.main_second"
        }
      },
      "seq": 451,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 453,
      "name": "Dropdown",
      "parent": 442,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "knowledge"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching8.main_home\")"
        },
        "options": {
          "type": "code",
          "value": "props.optionstype"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching8?.main_home"
        }
      },
      "seq": 453,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 455,
      "name": "Input",
      "parent": 138,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.main_before_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.main_before_user_name"
        }
      },
      "seq": 455,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 456,
      "name": "Input",
      "parent": 161,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.0.sub_before_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_before_user_name"
        }
      },
      "seq": 456,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 457,
      "name": "Input",
      "parent": 184,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.1.sub_before_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_before_user_name"
        }
      },
      "seq": 457,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 458,
      "name": "Input",
      "parent": 207,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.2.sub_before_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_before_user_name"
        }
      },
      "seq": 458,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 459,
      "name": "Input",
      "parent": 230,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching2.main_before_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching2?.main_before_user_name"
        }
      },
      "seq": 459,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 460,
      "name": "Input",
      "parent": 253,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching3.main_before_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching3?.main_before_user_name"
        }
      },
      "seq": 460,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 461,
      "name": "Input",
      "parent": 276,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching4.main_before_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching4?.main_before_user_name"
        }
      },
      "seq": 461,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 462,
      "name": "Input",
      "parent": 299,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching5.main_before_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching5?.main_before_user_name"
        }
      },
      "seq": 462,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 463,
      "name": "Input",
      "parent": 322,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching6.main_before_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching6?.main_before_user_name"
        }
      },
      "seq": 463,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 464,
      "name": "Input",
      "parent": 345,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.main_before_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.main_before_user_name"
        }
      },
      "seq": 464,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 465,
      "name": "Input",
      "parent": 368,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.0.sub_before_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_before_user_name"
        }
      },
      "seq": 465,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 466,
      "name": "Input",
      "parent": 391,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.1.sub_before_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_before_user_name"
        }
      },
      "seq": 466,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 467,
      "name": "Input",
      "parent": 414,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.2.sub_before_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_before_user_name"
        }
      },
      "seq": 467,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 468,
      "name": "Input",
      "parent": 437,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 468,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 469,
      "name": "Input",
      "parent": 140,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.main_first_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.main_first_user_name"
        }
      },
      "seq": 469,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 470,
      "name": "Input",
      "parent": 163,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.0.sub_first_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_first_user_name"
        }
      },
      "seq": 470,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 471,
      "name": "Input",
      "parent": 186,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.1.sub_first_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_first_user_name"
        }
      },
      "seq": 471,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 472,
      "name": "Input",
      "parent": 209,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.2.sub_first_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_first_user_name"
        }
      },
      "seq": 472,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 473,
      "name": "Input",
      "parent": 232,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching2.main_first_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching2?.main_first_user_name"
        }
      },
      "seq": 473,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 474,
      "name": "Input",
      "parent": 255,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching3.main_first_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching3?.main_first_user_name"
        }
      },
      "seq": 474,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 475,
      "name": "Input",
      "parent": 277,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching4.main_first_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching4?.main_first_user_name"
        }
      },
      "seq": 475,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 476,
      "name": "Input",
      "parent": 301,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching5.main_first_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching5?.main_first_user_name"
        }
      },
      "seq": 476,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 477,
      "name": "Input",
      "parent": 324,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching6.main_first_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching6?.main_first_user_name"
        }
      },
      "seq": 477,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 478,
      "name": "Input",
      "parent": 347,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.main_first_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.main_first_user_name"
        }
      },
      "seq": 478,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 479,
      "name": "Input",
      "parent": 370,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.0.sub_first_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_first_user_name"
        }
      },
      "seq": 479,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 480,
      "name": "Input",
      "parent": 393,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.1.sub_first_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_first_user_name"
        }
      },
      "seq": 480,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 481,
      "name": "Input",
      "parent": 416,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.2.sub_first_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_first_user_name"
        }
      },
      "seq": 481,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 482,
      "name": "Input",
      "parent": 439,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 482,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 483,
      "name": "Input",
      "parent": 142,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.main_second_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.main_second_user_name"
        }
      },
      "seq": 483,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 484,
      "name": "Input",
      "parent": 165,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.0.sub_second_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_second_user_name"
        }
      },
      "seq": 484,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 485,
      "name": "Input",
      "parent": 188,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.1.sub_second_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_second_user_name"
        }
      },
      "seq": 485,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 486,
      "name": "Input",
      "parent": 211,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.2.sub_second_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_second_user_name"
        }
      },
      "seq": 486,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 487,
      "name": "Input",
      "parent": 234,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching2.main_second_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching2?.main_second_user_name"
        }
      },
      "seq": 487,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 488,
      "name": "Input",
      "parent": 257,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching3.main_second_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching3?.main_second_user_name"
        }
      },
      "seq": 488,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 489,
      "name": "Input",
      "parent": 154,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.main_home_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.main_home_user_name"
        }
      },
      "seq": 489,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 491,
      "name": "Input",
      "parent": 167,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.0.sub_home_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[0]?.sub_home_user_name"
        }
      },
      "seq": 491,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 492,
      "name": "Input",
      "parent": 190,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.1.sub_home_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[1]?.sub_home_user_name"
        }
      },
      "seq": 492,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 493,
      "name": "Input",
      "parent": 213,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching1.items.2.sub_home_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching1?.items?.[2]?.sub_home_user_name"
        }
      },
      "seq": 493,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 495,
      "name": "Input",
      "parent": 280,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching4.main_second_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching4?.main_second_user_name"
        }
      },
      "seq": 495,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 496,
      "name": "Input",
      "parent": 303,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching5.main_second_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching5?.main_second_user_name"
        }
      },
      "seq": 496,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 497,
      "name": "Input",
      "parent": 326,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching6.main_second_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching6?.main_second_user_name"
        }
      },
      "seq": 497,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 498,
      "name": "Input",
      "parent": 349,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.main_second_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.main_second_user_name"
        }
      },
      "seq": 498,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 499,
      "name": "Input",
      "parent": 372,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.0.sub_second_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_second_user_name"
        }
      },
      "seq": 499,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 500,
      "name": "Input",
      "parent": 395,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.1.sub_second_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_second_user_name"
        }
      },
      "seq": 500,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 501,
      "name": "Input",
      "parent": 418,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.2.sub_second_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_second_user_name"
        }
      },
      "seq": 501,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 502,
      "name": "Input",
      "parent": 441,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 502,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 503,
      "name": "Input",
      "parent": 236,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching2.main_home_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching2?.main_home_user_name"
        }
      },
      "seq": 503,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 504,
      "name": "Input",
      "parent": 259,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching3.main_home_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching3?.main_home_user_name"
        }
      },
      "seq": 504,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 505,
      "name": "Input",
      "parent": 282,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching4.main_home_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching4?.main_home_user_name"
        }
      },
      "seq": 505,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 506,
      "name": "Input",
      "parent": 305,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching5.main_home_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching5?.main_home_user_name"
        }
      },
      "seq": 506,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 507,
      "name": "Input",
      "parent": 328,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching6.main_home_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching6?.main_home_user_name"
        }
      },
      "seq": 507,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 508,
      "name": "Input",
      "parent": 351,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.main_home_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.main_home_user_name"
        }
      },
      "seq": 508,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 509,
      "name": "Input",
      "parent": 374,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.0.sub_home_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[0]?.sub_home_user_name"
        }
      },
      "seq": 509,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 510,
      "name": "Input",
      "parent": 397,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.1.sub_home_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[1]?.sub_home_user_name"
        }
      },
      "seq": 510,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 511,
      "name": "Input",
      "parent": 420,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_teaching7.items.2.sub_home_user_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermTeaching?.disc_teaching7?.items?.[2]?.sub_home_user_name"
        }
      },
      "seq": 511,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 512,
      "name": "Input",
      "parent": 443,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 512,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardDischargeTeachingUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "drugSearch": "[Drug Search Box]"
  },
  "width": 95
}

*********************************************************************************** */
