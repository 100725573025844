import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Checkbox,
  Input
} from 'semantic-ui-react'

const CardIrrigationUX = (props: any) => {
    return(
      <div
        style={{ margin: "15px",  padding: "20px",  backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", fontSize: "22px" }}>
            Irrigation
          </label>
          <Label
            color={props.statusColor}
            style={{display: props.config?.hideStatus ? "none" : "", float: "right" }}>
            {props.status}
          </Label>
          <hr>
          </hr>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              Irrigation
            </label>
            <Checkbox
              checked={props.isIrrigation || false}
              label={props.isIrrigation ? "Yes" : "No"}
              name="is_irrigation"
              onChange={props.onChangeData}
              style={{ float: "right", marginRight: "20px"}}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            style={{display:"flex"}}>
            
            <Checkbox
              checked={props.isNss || false}
              disabled={!props.config?.enabledNss}
              label="NSS"
              name="is_nss"
              onChange={props.onChangeData}
              style={{margin:"5px 5px 0px 5px"}}>
            </Checkbox>
          </div>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{   width: "25%",  margin: "5px 10px 0px 0px", }}>
            
          </div>
          <div
            style={{display:"flex"}}>
            
            <Checkbox
              checked={props.isWater || false}
              disabled={!props.config?.enabledWater}
              label="Water"
              name="is_water"
              onChange={props.onChangeData}
              style={{margin:"5px 5px 0px 5px"}}>
            </Checkbox>
          </div>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{   width: "25%",  margin: "5px 10px 0px 0px", }}>
            
          </div>
          <div
            style={{display:"flex"}}>
            
            <Checkbox
              checked={props.isGlycline || false}
              disabled={!props.config?.enabledGlycline}
              label="Glycline"
              name="is_glycline"
              onChange={props.onChangeData}
              style={{margin:"5px 5px 0px 5px"}}>
            </Checkbox>
          </div>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{   width: "25%",  margin: "5px 10px 0px 0px", }}>
            
          </div>
          <div
            style={{display:"flex"}}>
            
            <Checkbox
              checked={props.isBss || false}
              disabled={!props.config?.enabledBss}
              label="BSS"
              name="is_bss"
              onChange={props.onChangeData}
              style={{margin:"5px 25px 0px 5px"}}>
            </Checkbox>
          </div>
          <Input
            disabled={!props.config?.enabledBssDetail}
            name="bss"
            onChange={props.onChangeData}
            value={props.bss || ""}>
          </Input>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{   width: "25%",  margin: "5px 10px 0px 0px", }}>
            
          </div>
          <div
            style={{display:"flex"}}>
            
            <Checkbox
              checked={props.isOtherIrrigation || false}
              disabled={!props.config?.enabledOther}
              label="Other"
              name="is_other_irrigation"
              onChange={props.onChangeData}
              style={{margin:"5px 15px 0px 5px"}}>
            </Checkbox>
          </div>
          <Input
            disabled={!props.config?.enabledOtherDetail}
            name="other_irrigation"
            onChange={props.onChangeData}
            value={props.otherIrrigation || ""}>
          </Input>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{   width: "25%",  margin: "5px 10px 0px 0px", }}>
            
          </div>
          <div
            style={{display:"flex"}}>
            
            <label
              style={{margin: "0px 10px 0px 0px",display:"flex",alignItems:"center"  }}>
              In
            </label>
            <Input
              disabled={!props.config?.enabledIn}
              name="irrigation_in"
              onChange={props.onChangeData}
              value={props.irrigationIn || ""}>
            </Input>
            <label
              style={{margin: "0px 10px 0px 10px",display:"flex",alignItems:"center" }}>
              ml
            </label>
            <label
              style={{margin: "0px 10px 0px 10px", display:"flex",alignItems:"center" }}>
              Out
            </label>
            <Input
              disabled={!props.config?.enabledOut}
              name="irrigation_out"
              onChange={props.onChangeData}
              value={props.irrigationOut || ""}>
            </Input>
            <label
              style={{margin: "0px 10px 0px 10px", display:"flex",alignItems:"center" }}>
              ml
            </label>
          </div>
        </div>
        <div
          style={{ height: "55px", paddingTop: "15px" }}>
          
          <div
            style={{ float: "right", display: "flex" }}>
            
            <div
              style={{margin: "0 0.25rem"}}>
              {props.ButtonSave}
            </div>
            <div
              style={{margin: "0 0.25rem"}}>
              {props.ButtonConfirm}
            </div>
            <div
              style={{margin: "0 0.25rem"}}>
              {props.ButtonUnConfirm}
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardIrrigationUX

export const screenPropsDefault = {}

/* Date Time : Wed Sep 14 2022 07:18:26 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\",  padding: \"20px\",  backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Irrigation"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"22px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        },
        "color": {
          "type": "code",
          "value": "props.statusColor"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideStatus ? \"none\" : \"\", float: \"right\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "Irrigation"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{   width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\" }"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\", display: \"flex\" }"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "Checkbox",
      "parent": 69,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isIrrigation || false"
        },
        "label": {
          "type": "code",
          "value": "props.isIrrigation ? \"Yes\" : \"No\""
        },
        "name": {
          "type": "value",
          "value": "is_irrigation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onClick": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\", marginRight: \"20px\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "Checkbox",
      "parent": 161,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isNss || false"
        },
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledNss"
        },
        "label": {
          "type": "value",
          "value": "NSS"
        },
        "name": {
          "type": "value",
          "value": "is_nss"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"5px 5px 0px 5px\"}"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "Checkbox",
      "parent": 164,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isWater || false"
        },
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledWater"
        },
        "label": {
          "type": "value",
          "value": "Water"
        },
        "name": {
          "type": "value",
          "value": "is_water"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"5px 5px 0px 5px\"}"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 165,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{   width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 165,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "div",
      "parent": 166,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{   width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 166,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "div",
      "parent": 168,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{   width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "div",
      "parent": 168,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "div",
      "parent": 169,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{   width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "div",
      "parent": 169,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "Checkbox",
      "parent": 173,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isBss || false"
        },
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledBss"
        },
        "label": {
          "type": "value",
          "value": "BSS"
        },
        "name": {
          "type": "value",
          "value": "is_bss"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"5px 25px 0px 5px\"}"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "Checkbox",
      "parent": 175,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOtherIrrigation || false"
        },
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledOther"
        },
        "label": {
          "type": "value",
          "value": "Other"
        },
        "name": {
          "type": "value",
          "value": "is_other_irrigation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"5px 15px 0px 5px\"}"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "Checkbox",
      "parent": 171,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isGlycline || false"
        },
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledGlycline"
        },
        "label": {
          "type": "value",
          "value": "Glycline"
        },
        "name": {
          "type": "value",
          "value": "is_glycline"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"5px 5px 0px 5px\"}"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "Input",
      "parent": 166,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledBssDetail"
        },
        "name": {
          "type": "value",
          "value": "bss"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.bss || \"\""
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "Input",
      "parent": 168,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledOtherDetail"
        },
        "name": {
          "type": "value",
          "value": "other_irrigation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.otherIrrigation || \"\""
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "label",
      "parent": 177,
      "props": {
        "children": {
          "type": "value",
          "value": "In"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0px 0px\",display:\"flex\",alignItems:\"center\"  }"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "label",
      "parent": 177,
      "props": {
        "children": {
          "type": "value",
          "value": "ml"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0px 10px\",display:\"flex\",alignItems:\"center\" }"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "label",
      "parent": 177,
      "props": {
        "children": {
          "type": "value",
          "value": "Out"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0px 10px\", display:\"flex\",alignItems:\"center\" }"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "Input",
      "parent": 177,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledIn"
        },
        "name": {
          "type": "value",
          "value": "irrigation_in"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.irrigationIn || \"\""
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 187,
      "name": "Input",
      "parent": 177,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledOut"
        },
        "name": {
          "type": "value",
          "value": "irrigation_out"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.irrigationOut || \"\""
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "label",
      "parent": 177,
      "props": {
        "children": {
          "type": "value",
          "value": "ml"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0px 10px\", display:\"flex\",alignItems:\"center\" }"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 0.25rem\"}"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 0.25rem\"}"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonUnConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 0.25rem\"}"
        }
      },
      "seq": 191,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardIrrigationUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
