import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Checkbox,
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardSupplyOrderHistoryUX = (props: any) => {
    return(
      <div
        style={{padding:"5px"}}>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem",margin:"10px"}}>
          ประวัติการสั่งเวชภัณฑ์
        </div>
        <div>
          
          <div
            style={{ display: "flex", paddingBottom: "5px" }}>
            
            <div
              style={{ width: "10%" }}>
              
              <Checkbox
                checked={props.checkedPatient}
                label="ชื่อหรือรหัสผู้ป่วย"
                onChange={props.handleChangeChecked("checkedPatient")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </div>
            <div
              style={{ width: "35%" }}>
              {}
              <div
                style={{width:"55%"}}>
                {props.patientSearch}
              </div>
            </div>
            <div
              style={{ width: "10%" }}>
              
              <Checkbox
                checked={props.checkedDoctor}
                label="ชื่อหรือรหัสเเพทย์"
                onChange={props.handleChangeChecked("checkedDoctor")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </div>
            <div
              style={{ width: "20%" }}>
              {props.doctorSearch}
            </div>
          </div>
          <div
            style={{ display: "flex", paddingBottom: "5px" }}>
            
            <div
              style={{ width: "10%" }}>
              
              <Checkbox
                checked={props.checkedType}
                label="ประเภท"
                onChange={props.handleChangeChecked("checkedType")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </div>
            <div
              style={{ width: "35%" }}>
              
              <div
                style={{display:"flex"}}>
                
                <Dropdown
                  fluid={true}
                  onChange={props.handleChangeValue("selectedType","checkedType")}
                  options={props.masterOptions?.supplyType}
                  selection={true}
                  style={{width:"55%"}}
                  value={props.selectedType}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ width: "10%" }}>
              
              <Checkbox
                checked={props.checkedStatus}
                label="สถานะ"
                onChange={props.handleChangeChecked("checkedStatus")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </div>
            <div
              style={{ width: "20%" }}>
              
              <Dropdown
                fluid={true}
                onChange={props.handleChangeValue("selectedStatus","checkedStatus")}
                options={props.masterOptions?.supplyStatus}
                selection={true}
                value={props.selectedStatus}>
              </Dropdown>
            </div>
          </div>
          <div
            style={{ display: "flex", paddingBottom: "5px" }}>
            
            <div
              style={{ width: "10%" }}>
              
              <Checkbox
                checked={props.checkedOrderDivision}
                label="หน่ยงานสั่ง"
                onChange={props.handleChangeChecked("checkedOrderDivision")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </div>
            <div
              style={{ width: "35%" }}>
              
              <div
                style={{display:"flex",}}>
                
                <Dropdown
                  fluid={true}
                  onChange={props.handleChangeValue("selectedOrderDivision","checkedOrderDivision")}
                  options={props.masterOptions?.division}
                  search={true}
                  selection={true}
                  style={{width:"55%"}}
                  value={props.selectedOrderDivision}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ width: "10%" }}>
              
              <Checkbox
                checked={props.checkedPerformDivision}
                label="หน่วยงานจ่าย"
                onChange={props.handleChangeChecked("checkedPerformDivision")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </div>
            <div
              style={{ width: "20%" }}>
              
              <Dropdown
                fluid={true}
                onChange={props.handleChangeValue("selectedPerformDivision","checkedPerformDivision")}
                options={props.masterOptions?.divisionSupply}
                search={true}
                selection={true}
                value={props.selectedPerformDivision}>
              </Dropdown>
            </div>
          </div>
          <div
            style={{ display: "flex", paddingBottom: "5px" }}>
            
            <div
              style={{ width: "10%" }}>
              
              <Checkbox
                checked={props.checkedOrderDate}
                label="วันที่สั่ง"
                onChange={props.handleChangeChecked("checkedOrderDate")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </div>
            <div
              style={{ width: "35%" ,display:"flex"}}>
              
              <DateTextBox
                onChange={props.handleChangeDate("selectedOrderStartDate")}
                value={props.selectedOrderStartDate}>
              </DateTextBox>
              <DateTextBox
                onChange={props.handleChangeDate("selectedOrderEndDate")}
                style={{marginLeft:"26px"}}
                value={props.selectedOrderEndDate}>
              </DateTextBox>
            </div>
            <div
              style={{ width: "10%" }}>
              
              <Checkbox
                checked={props.checkedUser}
                label="ผู้บันทึก"
                onChange={props.handleChangeChecked("checkedUser")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </div>
            <div
              style={{ width: "20%" }}>
              {props.employeeCode}
            </div>
          </div>
          <div
            style={{ display: "flex" }}>
            
            <div
              style={{ width: "10%" }}>
              
              <Checkbox
                checked={props.checkedPerformDate}
                label="วันที่จ่าย"
                onChange={props.handleChangeChecked("checkedPerformDate")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </div>
            <div
              style={{ width: "35%" ,display:"flex"}}>
              
              <DateTextBox
                onChange={props.handleChangeDate("selectedPerformStartDate")}
                value={props.selectedPerformStartDate}>
              </DateTextBox>
              <DateTextBox
                onChange={props.handleChangeDate("selectedPerformEndDate")}
                style={{marginLeft:"26px"}}
                value={props.selectedPerformEndDate}>
              </DateTextBox>
            </div>
            <div
              style={{display:"flex",justifyContent:"flex-end",width: "30%" }}>
              
              <Button
                color="yellow"
                onClick={props.onClear}
                size="small">
                ล้างตัวกรอง
              </Button>
              <div>
                {props.buttonSearch}
              </div>
            </div>
            <div>
              
            </div>
          </div>
        </div>
        <div
          style={{marginTop:"20px"}}>
          
          <Table
            data={props.supplyOrderHistoryList}
            getTrProps={props.handleGetTrProps}
            headers="รหัส,ประเภท,สถานะ,วันที่สั่ง,หน่วยงานที่สั่ง,ผู้ป่วย,แพทย์,วันที่จ่าย,หน่วยงานที่จ่าย,ผู้บันทึกล่าสุด"
            keys="code,type,status,orderTime,order_div_name,patient,doctor,orderPerformTime,order_perform_div_name, edit_user"
            minRows="10"
            showPagination={false}
            style={{height: "400px"}}>
          </Table>
        </div>
      </div>
    )
}

export default CardSupplyOrderHistoryUX

export const screenPropsDefault = {"patientSearch":"[Patient Search Box]"}

/* Date Time : Thu Sep 22 2022 16:36:37 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding:\"5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการสั่งเวชภัณฑ์"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\",margin:\"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"20px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 4,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.supplyOrderHistoryList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.handleGetTrProps"
        },
        "headers": {
          "type": "value",
          "value": "รหัส,ประเภท,สถานะ,วันที่สั่ง,หน่วยงานที่สั่ง,ผู้ป่วย,แพทย์,วันที่จ่าย,หน่วยงานที่จ่าย,ผู้บันทึกล่าสุด"
        },
        "keys": {
          "type": "value",
          "value": "code,type,status,orderTime,order_div_name,patient,doctor,orderPerformTime,order_perform_div_name, edit_user"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "pageSize": {
          "type": "code",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"400px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\" }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Checkbox",
      "parent": 7,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedPatient"
        },
        "label": {
          "type": "value",
          "value": "ชื่อหรือรหัสผู้ป่วย"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedPatient\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Checkbox",
      "parent": 10,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedDoctor"
        },
        "label": {
          "type": "value",
          "value": "ชื่อหรือรหัสเเพทย์"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedDoctor\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearch"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Checkbox",
      "parent": 15,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedType"
        },
        "label": {
          "type": "value",
          "value": "ประเภท"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedType\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\" }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Checkbox",
      "parent": 19,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedStatus"
        },
        "label": {
          "type": "value",
          "value": "สถานะ"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedStatus\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Dropdown",
      "parent": 20,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedStatus\",\"checkedStatus\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.supplyStatus"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedStatus"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\" }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\" }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\" }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Checkbox",
      "parent": 25,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedOrderDivision"
        },
        "label": {
          "type": "value",
          "value": "หน่ยงานสั่ง"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedOrderDivision\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Checkbox",
      "parent": 27,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedPerformDivision"
        },
        "label": {
          "type": "value",
          "value": "หน่วยงานจ่าย"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedPerformDivision\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Dropdown",
      "parent": 28,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedPerformDivision\",\"checkedPerformDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.divisionSupply"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedPerformDivision"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\" }"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\" ,display:\"flex\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\" }"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "code",
          "value": "props.employeeCode"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Checkbox",
      "parent": 33,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedOrderDate"
        },
        "label": {
          "type": "value",
          "value": "วันที่สั่ง"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedOrderDate\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Checkbox",
      "parent": 35,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedUser"
        },
        "label": {
          "type": "value",
          "value": "ผู้บันทึก"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedUser\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 39,
      "name": "DateTextBox",
      "parent": 34,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedOrderStartDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.selectedOrderStartDate"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\" }"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\" ,display:\"flex\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"flex-end\",width: \"30%\" }"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Checkbox",
      "parent": 41,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedPerformDate"
        },
        "label": {
          "type": "value",
          "value": "วันที่จ่าย"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedPerformDate\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 46,
      "name": "DateTextBox",
      "parent": 42,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedPerformStartDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.selectedPerformStartDate"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 47,
      "name": "DateTextBox",
      "parent": 42,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedPerformEndDate\")"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"26px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedPerformEndDate"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Button",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างตัวกรอง"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 50,
      "name": "DateTextBox",
      "parent": 34,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedOrderEndDate\")"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"26px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedOrderEndDate"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Dropdown",
      "parent": 51,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedType\",\"checkedType\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.supplyType"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"55%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedType"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearch"
        },
        "style": {
          "type": "code",
          "value": "{width:\"55%\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Dropdown",
      "parent": 54,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedOrderDivision\",\"checkedOrderDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.division"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"55%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedOrderDivision"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 56,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSupplyOrderHistoryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "patientSearch": "[Patient Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
