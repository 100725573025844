import React, { useEffect, useMemo, useRef, useState } from "react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
// ui common
import SnackMessage from "react-lib/apps/common/SnackMessage";
// ui ADM
import CardReAssessmentUX from "./CardReAssessmentUX";
import CardReAssessmentHistoryUX from "./CardReAssessmentHistoryUX";
import { Button, Icon, Input, Modal, Segment } from "semantic-ui-react";
import { parseDate } from "react-lib/utils/dateUtils";
import moment from "moment";
import ReactTable from "react-table-6";

const CARD_RE_ASSESSMENT_ID: string = "CardReAssessment";

type CardReAssessmentProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // seq
  runSequence: any;
  ReAssessmentSequence?: any;

  // options
  masterOptions?: any;
  reAssessmentOptions?: any;
};

const CardReAssessmentInitial: CardReAssessmentProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  errorMessage: null,
  successMessage: null,
  buttonLoadCheck: null,

  // seq
  runSequence: null,

  // options
  masterOptions: {},
  reAssessmentOptions: {},
};

const CardReAssessment: React.FC<CardReAssessmentProps> = (props: any) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [textReason, setTextReason] = useState("");
  const [openForm, setOpenForm] = useState(true);
  //Pain
  const [tableRowPain, setTableRowPain] = useState<any>([]);
  const [tableColumnPain, setTableColumnPain] = useState<any>([]);
  const [slicePain, setSlicePain] = useState(0);
  //RiskFall
  const [tableRowRiskFall, setTableRowRiskFall] = useState<any>([]);
  const [tableColumnRiskFall, setTableColumnRiskFall] = useState<any>([]);
  const [sliceRiskFall, setSliceRiskFall] = useState(0);
  //Sleep
  const [tableRowSleep, setTableRowSleep] = useState<any>([]);
  const [tableColumnSleep, setTableColumnSleep] = useState<any>([]);
  const [sliceSleep, setSliceSleep] = useState(0);
  const [itemDelete, setItemDelete] = useState([]);
  const myRef = useRef(null);

  useEffect(() => {
    props.runSequence({ sequence: "ReAssessment", restart: true });
  }, []);

  useEffect(() => {
    if (!openForm) {
      props.runSequence({
        sequence: "ReAssessment",
        action: "getDataHistory",
      });
    }
  }, [openForm]);

  useEffect(() => {
    createTableHeader("painDataHistory");
  }, [props?.ReAssessmentSequence?.painDataHistory?.data, slicePain]);

  useEffect(() => {
    createTableHeader("riskFallDataHistory");
  }, [props?.ReAssessmentSequence?.riskFallDataHistory?.data, sliceRiskFall]);

  useEffect(() => {
    createTableHeader("sleepDataHistory");
  }, [props?.ReAssessmentSequence?.sleepDataHistory?.data, sliceSleep]);

  const handleChangeData = (_event: any, data: any) => {
    if (data?.type === "checkbox") {
      props.setProp(`ReAssessmentSequence.${data.name}`, data.checked);
    } else {
      props.setProp(`ReAssessmentSequence.${data.name}`, data.value);
    }
  };

  const handleChangeDate = (name: any, date: string) => {
    props.setProp(`ReAssessmentSequence.${name}`, date);
  };

  const handleActionForm = (_event: any, data: any) => {
    props.runSequence({
      sequence: "ReAssessment",
      action: data.name,
      card: CARD_RE_ASSESSMENT_ID,
    });
  };

  const handleChangeDataForm = (dateString: string) => {
    if (dateString) {
      let date = dateString.split("/");
      let year = date[2].split("[");
      return `${parseInt(year[0], 10) - 543}-${date[1]}-${date[0]}`;
    }
  };
  const createTableHeader = (type: any) => {
    let dataHistory: any;
    let sliceValue = 0;

    if (type === "painDataHistory") {
      dataHistory = props.ReAssessmentSequence?.painDataHistory;
      sliceValue = slicePain;
    } else if (type === "riskFallDataHistory") {
      dataHistory = props.ReAssessmentSequence?.riskFallDataHistory;
      sliceValue = sliceRiskFall;
    } else if (type === "sleepDataHistory") {
      dataHistory = props.ReAssessmentSequence?.sleepDataHistory;
      sliceValue = sliceSleep;
    }

    // formatDate to use isBetween()
    let startDate = handleChangeDataForm(dataHistory?.startDate);
    let endDate = handleChangeDataForm(dataHistory?.endDate);

    let itemData =
      dataHistory?.data.filter((item: any) =>
        moment(handleChangeDataForm(item?.saveData)).isBetween(
          startDate,
          endDate,
          undefined,
          "[]"
        )
      ) || [];

    const items = itemData
      .slice(0 + sliceValue, 7 + sliceValue)
      .map((item: any, index: any) => {
        if (type === "painDataHistory") {
          var painData;
          var painLocation;
          var painCharacteristic;
          props.masterOptions?.pain?.filter((data: any) => {
            if (data.value === item?.assessPainLevel) {
              painData = data.text;
            }
          });

          props.masterOptions?.location?.filter((data: any) => {
            if (data.value === item?.painArea) {
              painLocation = data.text;
            }
          });

          props.masterOptions?.characteristic?.filter((data: any) => {
            if (data.value === item?.painNature) {
              painCharacteristic = data.text;
            }
          });

          return {
            ...item,
            ความเจ็บปวด: item?.painStatus,
            มาตรฐานการประเมิน: painData,
            ระดับความเจ็บปวด: item?.painLevel,
            บริเวณที่ปวด: painLocation,
            ลักษณะการปวด: painCharacteristic,
            การให้การพยาบาลผู้ป่วย: item?.sensesNursingCare,
            ผู้บันทึก: item.editUserName,
            type: type,
          };
        } else if (type === "riskFallDataHistory") {
          return {
            ...item,
            ความเสี่ยง: item?.riskFall,
            ระดับความเสี่ยง: item?.riskFallRemark,
            การให้การพยาบาลผู้ป่วย: item?.nursingCare,
            ผู้บันทึก: item.editUserName,
            type: type,
          };
        } else if (type === "sleepDataHistory") {
          return {
            ...item,
            ชั่วโมงการนอน: item?.sleepingPerDay,
            ความเพียงพอ: item?.sleepingEnough,
            ปัญหาการนอน:
              item?.sleepingProblem === "มี"
                ? `${item?.sleepingProblem} ${item?.sleepingProblemRemark}`
                : item?.sleepingProblem,
            ใช้ยานอนหลับ:
              item?.sleepPill === "ใช้"
                ? `${item?.sleepPill} ${item?.sleepPillRemark}`
                : item?.sleepPill,
            ผู้บันทึก: item?.editUserName,
            type: type,
          };
        }
      });

    const dictionary = Object.assign(
      {},
      ...items.map((item: any, index: any) => ({ [index]: item }))
    );

    let headerColumn = [
      {
        Header: " ",
        accessor: "name",
        fixed: "left",
        resizable: false,
        width: 200,
      },
      ...items.map((item: any, index: any) => ({
        accessor: `${index}`,
        // Header: "",
        Header: () => {
          return (
            <div style={{ margin: "5px" }}>
              <div style={{ display: "flex", justifyContent: "left" }}>
                <label>{item.saveData}</label>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label>{item.saveTime}</label>
                <div>
                  <Icon
                    name="trash alternate"
                    inverted
                    color="red"
                    link={true}
                    onClick={async () => {
                      setItemDelete({ ...item, index: index });
                      setOpenDelete(true);
                    }}
                  />
                </div>
              </div>
            </div>
          );
        },
      })),
    ];

    if (headerColumn.length < 8) {
      let i = headerColumn.length;
      for (i; i < 8; i++) {
        headerColumn.push({
          accessor: " ",
          Header: () => {
            return <div></div>;
          },
        });
      }
    }

    let data: any[] = [];
    if (type === "painDataHistory") {
      data = [
        { header: "ความเจ็บปวด" },
        { header: "มาตรฐานการประเมิน" },
        { header: "ระดับความเจ็บปวด" },
        { header: "บริเวณที่ปวด" },
        { header: "ลักษณะการปวด" },
        { header: "การให้การพยาบาลผู้ป่วย" },
        { header: "ผู้บันทึก" },
      ];
    } else if (type === "riskFallDataHistory") {
      data = [
        { header: "ความเสี่ยง" },
        { header: "ระดับความเสี่ยง" },
        { header: "การให้การพยาบาลผู้ป่วย" },
        { header: "ผู้บันทึก" },
      ];
    } else if (type === "sleepDataHistory") {
      data = [
        { header: "ชั่วโมงการนอน" },
        { header: "ความเพียงพอ" },
        { header: "ปัญหาการนอน" },
        { header: "ใช้ยานอนหลับ" },
        { header: "ผู้บันทึก" },
      ];
    }

    const rowData: any[] = data.map((item) => ({
      ...item,
      ...Object.assign(
        {},
        ...headerColumn.map((column: any, index: any) => ({
          [index]: dictionary[index]?.[item.header],
        }))
      ),
      name: item.header,
    }));

    if (type === "painDataHistory") {
      setTableColumnPain(headerColumn);
      setTableRowPain(rowData);
    } else if (type === "riskFallDataHistory") {
      setTableColumnRiskFall(headerColumn);
      setTableRowRiskFall(rowData);
    } else if (type === "sleepDataHistory") {
      setTableColumnSleep(headerColumn);
      setTableRowSleep(rowData);
    }
  };

  return (
    <>
      {(props.errorMessage?.[CARD_RE_ASSESSMENT_ID] ||
        props.successMessage?.[CARD_RE_ASSESSMENT_ID]) && (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            props.setProp(`errorMessage.${CARD_RE_ASSESSMENT_ID}`, null);
            props.setProp(`successMessage.${CARD_RE_ASSESSMENT_ID}`, null);
          }}
          error={props.errorMessage?.[CARD_RE_ASSESSMENT_ID]}
          success={props.successMessage?.[CARD_RE_ASSESSMENT_ID]}
        />
      )}

      <div style={{ margin: "20px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: "22px", fontWeight: "bold" }}>
            Nursing Re-assessment
          </div>
          <div style={{ margin: "0px 20px" }}>
            <Button
              onClick={() => setOpenForm(true)}
              {...(openForm && { color: "blue" })}
            >
              แบบฟอร์มการประเมิน
            </Button>
          </div>
          <div>
            <Button
              onClick={() => setOpenForm(false)}
              {...(!openForm && { color: "blue" })}
            >
              ประวัติการบันทึก
            </Button>
          </div>
        </div>
      </div>

      {openForm ? (
        <CardReAssessmentUX
          // function
          onChangeData={handleChangeData}
          // seq
          ReAssessmentSequence={props.ReAssessmentSequence}
          // options
          masterOptions={props.masterOptions}
          reAssessmentOptions={props.reAssessmentOptions}
          //onChange
          onChangDatePain={(date: any) =>
            props.setProp(`ReAssessmentSequence.painData.saveData`, date)
          }
          onChangDateRiskFall={(date: any) =>
            props.setProp(`ReAssessmentSequence.riskFallData.saveData`, date)
          }
          onChangDateSleep={(date: any) =>
            props.setProp(`ReAssessmentSequence.sleepData.saveData`, date)
          }
          onChangTimePain={(value: any) =>
            props.setProp(`ReAssessmentSequence.painData.saveTime`, value)
          }
          onChangTimeRiskFall={(value: any) =>
            props.setProp(`ReAssessmentSequence.riskFallData.saveTime`, value)
          }
          onChangTimeSleep={(value: any) =>
            props.setProp(`ReAssessmentSequence.sleepData.saveTime`, value)
          }
          buttonSavePain={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleActionForm}
              // data
              paramKey={`${CARD_RE_ASSESSMENT_ID}_savePain`}
              buttonLoadCheck={
                props.buttonLoadCheck?.[`${CARD_RE_ASSESSMENT_ID}_savePain`]
              }
              // config
              color={"green"}
              name="savePain"
              size="small"
              title={"บันทึก"}
            />
          }
          buttonSaveRiskFall={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleActionForm}
              // data
              paramKey={`${CARD_RE_ASSESSMENT_ID}_saveRiskFall`}
              buttonLoadCheck={
                props.buttonLoadCheck?.[`${CARD_RE_ASSESSMENT_ID}_saveRiskFall`]
              }
              // config
              color={"green"}
              name="saveRiskFall"
              size="small"
              title={"บันทึก"}
            />
          }
          buttonSaveSleep={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleActionForm}
              // data
              paramKey={`${CARD_RE_ASSESSMENT_ID}_saveSleep`}
              buttonLoadCheck={
                props.buttonLoadCheck?.[`${CARD_RE_ASSESSMENT_ID}_saveSleep`]
              }
              // config
              color={"green"}
              name="saveSleep"
              size="small"
              title={"บันทึก"}
            />
          }
          // check input number
          onKeyDown={(e: any) => {
            if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
              e.preventDefault();
            }
          }}
        />
      ) : (
        <>
          <CardReAssessmentHistoryUX
            title={"การประเมินความเจ็บปวด"}
            startDate={props.ReAssessmentSequence?.painDataHistory?.startDate}
            endDate={props.ReAssessmentSequence?.painDataHistory?.endDate}
            onChangeStartDate={(date: any) =>
              handleChangeDate("painDataHistory.startDate", date)
            }
            onChangeEndDate={(date: any) =>
              handleChangeDate("painDataHistory.endDate", date)
            }
            sliceA={() => {
              if (slicePain - 1 < 0) {
                setSlicePain(0);
              } else {
                setSlicePain(slicePain - 1);
              }
            }}
            sliceB={() => {
              if (
                slicePain + 1 >=
                props.ReAssessmentSequence?.painDataHistory?.data.length
              ) {
                setSlicePain(slicePain);
              } else {
                setSlicePain(slicePain + 1);
              }
            }}
            tableData={
              <ReactTable
                ref={myRef}
                showPagination={false}
                data={tableRowPain}
                columns={tableColumnPain}
                pageSize={7}
                sortable={false}
              />
            }
            onSearch={() => createTableHeader("painDataHistory")}
          />

          <CardReAssessmentHistoryUX
            title={"การประเมินความเสี่ยงต่อการพลัดตกหกล้ม"}
            startDate={
              props.ReAssessmentSequence?.riskFallDataHistory?.startDate
            }
            endDate={props.ReAssessmentSequence?.riskFallDataHistory?.endDate}
            onChangeStartDate={(date: any) =>
              handleChangeDate("riskFallDataHistory.startDate", date)
            }
            onChangeEndDate={(date: any) =>
              handleChangeDate("riskFallDataHistory.endDate", date)
            }
            sliceA={() => {
              if (sliceRiskFall - 1 < 0) {
                setSliceRiskFall(0);
              } else {
                setSliceRiskFall(sliceRiskFall - 1);
              }
            }}
            sliceB={() => {
              if (
                sliceRiskFall + 1 >=
                props.ReAssessmentSequence?.riskFallDataHistory?.data.length
              ) {
                setSliceRiskFall(sliceRiskFall);
              } else {
                setSliceRiskFall(sliceRiskFall + 1);
              }
            }}
            tableData={
              <ReactTable
                ref={myRef}
                showPagination={false}
                data={tableRowRiskFall}
                columns={tableColumnRiskFall}
                pageSize={4}
                sortable={false}
              />
            }
            onSearch={() => createTableHeader("riskFallDataHistory")}
          />

          <CardReAssessmentHistoryUX
            title={"การพักผ่อนนอนหลับ"}
            startDate={props.ReAssessmentSequence?.sleepDataHistory?.startDate}
            endDate={props.ReAssessmentSequence?.sleepDataHistory?.endDate}
            onChangeStartDate={(date: any) =>
              handleChangeDate("sleepDataHistory.startDate", date)
            }
            onChangeEndDate={(date: any) =>
              handleChangeDate("sleepDataHistory.endDate", date)
            }
            sliceA={() => {
              if (sliceSleep - 1 < 0) {
                setSliceSleep(0);
              } else {
                setSliceSleep(sliceSleep - 1);
              }
            }}
            sliceB={() => {
              if (
                sliceSleep + 1 >=
                props.ReAssessmentSequence?.sleepDataHistory?.data.length
              ) {
                setSliceSleep(sliceSleep);
              } else {
                setSliceSleep(sliceSleep + 1);
              }
            }}
            tableData={
              <ReactTable
                ref={myRef}
                showPagination={false}
                data={tableRowSleep}
                columns={tableColumnSleep}
                pageSize={5}
                sortable={false}
              />
            }
            onSearch={() => createTableHeader("sleepDataHistory")}
          />
        </>
      )}

      <Modal
        size="tiny"
        open={openDelete}
        onClose={() => {
          setItemDelete([]);
          setTextReason("");
          setOpenDelete(false);
        }}
      >
        <Segment inverted className="red" style={{ margin: "0px" }}>
          ข้อความแจ้งเตือน
        </Segment>
        <div style={{ margin: "10px 20px" }}>
          <div style={{ fontWeight: "bold" }}>
            กรุณาระบุเหตุผล เพื่อยืนยันการลบข้อมูลการบันทึก
          </div>
          <div style={{ margin: "10px 0px" }}>
            <Input
              fluid={true}
              value={textReason}
              onChange={(e, { value }) => setTextReason(value)}
            ></Input>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="green"
              onClick={() => {
                props.runSequence({
                  sequence: "ReAssessment",
                  action: "deleteDataHistory",
                  card: CARD_RE_ASSESSMENT_ID,
                  item: itemDelete,
                });

                setItemDelete([]);
                setTextReason("");
                setOpenDelete(false);
              }}
              style={{ margin: "0px 20px" }}
            >
              ยืนยัน
            </Button>
            <Button
              color="red"
              onClick={() => {
                setItemDelete([]);
                setTextReason("");
                setOpenDelete(false);
              }}
              style={{ margin: "0px 20px" }}
            >
              ยกเลิก
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

CardReAssessment.defaultProps = CardReAssessmentInitial;

export default React.memo(CardReAssessment);
