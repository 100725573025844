import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Input
} from 'semantic-ui-react'

const CardAuditDrugOrderUX = (props: any) => {
    return(
      <div
        style={{margin:"5px"}}>
        <div
          style={{fontSize: "1.5em", fontWeight: "bold", margin: "2rem 0px 1rem"}}>
          ตรวจสอบการสั่งยาผู้ป่วย
        </div>
        <div
          style={{display:"flex",margin:"10px 0px",alignItems:"center",gap:"20px",marginLeft:"10px",fontWeight:"bolder"}}>
          
          <div>
            การวินิจฉัย
          </div>
          <Input>
          </Input>
        </div>
        <Table
          data={props.data}
          headers="Order name,Priority,Dispense Type,Admin Route,Dosage,Frequency,Duration,PRN: Indication for,Qty,Override Qty.,Qty. Adminstratered,Processing Note"
          keys="drug_label_name,name,name,name,name,name,name,name,name,name,name"
          minRows={5}>
        </Table>
      </div>
    )
}

export default CardAuditDrugOrderUX

export const screenPropsDefault = {}

/* Date Time : Thu Mar 30 2023 08:48:49 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{margin:\"5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "headers": {
          "type": "value",
          "value": "Order name,Priority,Dispense Type,Admin Route,Dosage,Frequency,Duration,PRN: Indication for,Qty,Override Qty.,Qty. Adminstratered,Processing Note"
        },
        "keys": {
          "type": "value",
          "value": "drug_label_name,name,name,name,name,name,name,name,name,name,name"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ตรวจสอบการสั่งยาผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.5em\", fontWeight: \"bold\", margin: \"2rem 0px 1rem\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",margin:\"10px 0px\",alignItems:\"center\",gap:\"20px\",marginLeft:\"10px\",fontWeight:\"bolder\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "การวินิจฉัย"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Input",
      "parent": 6,
      "props": {
      },
      "seq": 8,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardAuditDrugOrderUX",
  "project": "New Project",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
