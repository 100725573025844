import React, { useEffect, useCallback, useState, useMemo } from "react";

// UX
import CardReportPatientListUX from "./CardReportPatientListUX";

// Common
import CardPatientSearchBox from "../TPD/CardPatientSearchBox";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import { DateTextBox } from "react-lib/apps/common";

// Interface
import {
  State,
  MasterOptionsType,
  RunSequence,
} from "./sequence/ReportPatientList";

// Utils

// Types
type CardReportPatientListProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: RunSequence;
  ReportPatientListSequence: State["ReportPatientListSequence"];
  // controller
  drugOrderQueueController: any;
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  searchedItemListWithKey?: Record<string, any>;
  // options
  masterOptions?: MasterOptionsType;
};

// Const
const BUTTON_ACTIONS = {
  SEARCH: "SEARCH",
  PRINT: "PRINT",
  SEARCH_STATISTIC: "SEARCH_STATISTIC",
  PRINT_STATISTIC: "PRINT_STATISTIC",
};

const CARD_REPORT_PATIENT_LIST = "CardReportPatientList";

const CardReportPatientList = (props: CardReportPatientListProps) => {
  const [showStatistic, setShowStatistic] = useState<boolean>(false);

  // Effect
  useEffect(() => {
    props.runSequence({ sequence: "ReportPatientList", restart: true });
    return () => {
      const clear = async () => {
        const { Division_1, Diagnosis_1, Doctor_1, ...res } =
          props.searchedItemListWithKey || {};

        await props.setProp("searchedItemListWithKey", res);

        props.runSequence({ sequence: "ReportPatientList", clear: true });
      };

      clear();
    };
  }, []);

  // Callback
  const mapOptions = useCallback((items: any) => {
    return items.map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.name ?? item.name_code,
    }));
  }, []);

  const handleSelectedItem = useCallback(
    (data: { id: string; name: string; type: string }) =>
      async (value: any, key: any, obj: any) => {
        let selected = props.searchedItemListWithKey?.[
          `${data.type}_${data.id}`
        ]?.find((item: any) => item.id === key);

        if (!selected?.id) {
          await props.setProp(
            `searchedItemListWithKey.${data.type}_${data.id}`,
            []
          );
        }

        handleChangeValue(null, {
          name: data.name,
          value: selected?.id || null,
        });
      },
    [props.searchedItemListWithKey, props.ReportPatientListSequence?.filter]
  );

  const alignCenter = useCallback((item: any, key: string) => {
    return key.split(",").reduce(
      (r: any, k: any) => ({
        ...r,
        [k]: <div style={{ textAlign: "center" }}>{item[k]}</div>,
      }),
      {}
    );
  }, []);

  // UseMemo
  const statItems = useMemo(() => {
    return (props.ReportPatientListSequence?.statList || []).map(
      (item: any, index: number) => {
        return {
          ...item,
          no: <div style={{ textAlign: "center" }}>{index + 1}</div>,
          ...alignCenter(
            item,
            `doctor_name,patient_case_name,encounter_general_type,icd10_name,encounter_count`
          ),
        };
      }
    );
  }, [props.ReportPatientListSequence?.statList]);

  const orderItems = useMemo(() => {
    return (props.ReportPatientListSequence?.orderReportList || []).map(
      (item, index) => ({
        ...item,
        ...alignCenter(
          item,
          `encounter_datetime,division_name,hn_encounter_number,patient_fullname,patient_case_name,encounter_general_type,doctor_name,icd10_name`
        ),
      })
    );
  }, [props.ReportPatientListSequence?.orderReportList]);

  const patientCaseOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "แสดงทั้งหมด" },
      ...(props.masterOptions?.patientCase || []),
    ];
  }, [props.masterOptions?.patientCase]);

  const handleChangePatient = (
    id: any,
    hn: string,
    full_name: string,
    birthdate: string
  ) => {
    props.setProp(
      "ReportPatientListSequence.filter.patient",
      id ? { id, hn, full_name, birthdate } : {}
    );
  };

  const handleChangeValue = (e: any, data: any) => {
    const detail: any = props.ReportPatientListSequence?.filter || {};
    let value = typeof data.checked === "boolean" ? data.checked : data.value;

    const keys = (
      {
        isHN: "patient",
        isDoctor: "doctor",
        isDiagnosis: "diagnosis",
      } as any
    )[data.name];

    detail[data.name] = value;

    if (["isHN", "isDoctor", "isDiagnosis"].includes(data.name) && !value) {
      detail[keys] = null;
    }

    props.setProp("ReportPatientListSequence.filter", { ...detail });
  };

  const handleChangeDate = (name: string) => (value: string) => {
    handleChangeValue(null, { value, name });
  };

  const handleClearFilter = () => {
    props.setProp("ReportPatientListSequence.filter", {
      patientCase: "ALL",
    });
  };

  const handleSearch = () => {
    setShowStatistic(false);

    props.runSequence({
      sequence: "ReportPatientList",
      action: "SEARCH",
      card: CARD_REPORT_PATIENT_LIST,
    });
  };

  const handlePrint = () => {
    props.runSequence({
      sequence: "ReportPatientList",
      action: "PRINT",
      card: CARD_REPORT_PATIENT_LIST,
    });
  };

  const handleSearchStatistic = () => {
    setShowStatistic(true);

    props.runSequence({
      sequence: "ReportPatientList",
      action: "SEARCH_STATISTIC",
      card: CARD_REPORT_PATIENT_LIST,
    });
  };

  const handlePrintStatistic = () => {
    props.runSequence({
      sequence: "ReportPatientList",
      action: "PRINT_STATISTIC",
      card: CARD_REPORT_PATIENT_LIST,
    });
  };

  const handleEventSearch = (data: any) => {
    props.runSequence({
      sequence: "ReportPatientList",
      action: "SEARCH_DIAGNOSIS",
      data,
    });
  };

  console.log("CardReportPatientList", props);

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_REPORT_PATIENT_LIST}`, null);
        }}
        error={props.errorMessage?.[CARD_REPORT_PATIENT_LIST]}
        success={null}
      />

      <CardReportPatientListUX
        // data
        fullName={props.ReportPatientListSequence?.filter?.patient?.full_name}
        filter={props.ReportPatientListSequence?.filter}
        statItems={statItems}
        orderItems={orderItems}
        // options
        patientCaseOptions={patientCaseOptions}
        // config
        showStatistic={showStatistic}
        // callback
        onChangeValue={handleChangeValue}
        onClear={handleClearFilter}
        // Element
        PatientSearchBox={
          <CardPatientSearchBox
            // controller
            controller={props.drugOrderQueueController}
            // data
            modalStyle={{ width: "auto" }}
            disabled={!props.ReportPatientListSequence?.filter?.isHN}
            clearHNInput={
              !props.ReportPatientListSequence?.filter?.patient ? true : false
            }
            // callback
            onEnterPatientSearch={handleChangePatient}
          />
        }
        ClinicSearchBox={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="Division"
            id="1"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={
              props.ReportPatientListSequence?.filter?.division || null
            }
            setSelectedItem={handleSelectedItem({
              id: "1",
              name: "division",
              type: "Division",
            })}
            // options
            mapOptions={mapOptions}
          />
        }
        DoctorSearchBox={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="Doctor"
            id="1"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            disabled={!props.ReportPatientListSequence?.filter?.isDoctor}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={
              props.ReportPatientListSequence?.filter?.doctor || null
            }
            setSelectedItem={handleSelectedItem({
              id: "1",
              name: "doctor",
              type: "Doctor",
            })}
            // options
            mapOptions={mapOptions}
          />
        }
        DiagnosisSearchBox={
          <SearchBoxDropdown
            onEvent={handleEventSearch}
            // config
            type="Diagnosis"
            id="1"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            disabled={!props.ReportPatientListSequence?.filter?.isDiagnosis}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={
              props.ReportPatientListSequence?.filter?.diagnosis || null
            }
            setSelectedItem={handleSelectedItem({
              id: "1",
              name: "diagnosis",
              type: "Diagnosis",
            })}
            // options
            mapOptions={mapOptions}
          />
        }
        StartDate={
          <DateTextBox
            value={props.ReportPatientListSequence?.filter?.startDate}
            fluid={true}
            // disabled={!filter.isCreateDate}
            inputStyle={{ width: "135px" }}
            onChange={handleChangeDate("startDate")}
          />
        }
        EndDate={
          <DateTextBox
            value={props.ReportPatientListSequence?.filter?.endDate}
            fluid={true}
            // disabled={!filter.isCreateDate}
            inputStyle={{ width: "135px" }}
            onChange={handleChangeDate("endDate")}
          />
        }
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={`${CARD_REPORT_PATIENT_LIST}_${BUTTON_ACTIONS.SEARCH}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_REPORT_PATIENT_LIST}_${BUTTON_ACTIONS.SEARCH}`
              ]
            }
            // config
            color="blue"
            name={BUTTON_ACTIONS.SEARCH}
            size="small"
            title="ค้นหา"
            style={{ marginRight: "-0.5rem" }}
          />
        }
        ButtonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handlePrint}
            // data
            paramKey={`${CARD_REPORT_PATIENT_LIST}_${BUTTON_ACTIONS.PRINT}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_REPORT_PATIENT_LIST}_${BUTTON_ACTIONS.PRINT}`
              ]
            }
            // config
            disabled={
              !(
                !showStatistic &&
                !!props.ReportPatientListSequence?.orderReportList?.length
              )
            }
            color="green"
            name={BUTTON_ACTIONS.PRINT}
            size="small"
            title="พิมพ์รายงาน"
            style={{ minWidth: "max-content", marginRight: "-0.5rem" }}
          />
        }
        ButtonStatistic={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearchStatistic}
            // data
            paramKey={`${CARD_REPORT_PATIENT_LIST}_${BUTTON_ACTIONS.SEARCH_STATISTIC}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_REPORT_PATIENT_LIST}_${BUTTON_ACTIONS.SEARCH_STATISTIC}`
              ]
            }
            // config
            color="orange"
            name={BUTTON_ACTIONS.SEARCH_STATISTIC}
            size="small"
            title="สถิติ"
            style={{ marginRight: "-0.5rem" }}
          />
        }
        ButtonStatisticPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handlePrintStatistic}
            // data
            paramKey={`${CARD_REPORT_PATIENT_LIST}_${BUTTON_ACTIONS.PRINT_STATISTIC}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_REPORT_PATIENT_LIST}_${BUTTON_ACTIONS.PRINT_STATISTIC}`
              ]
            }
            // config
            disabled={
              !(
                showStatistic &&
                !!props.ReportPatientListSequence?.statList?.length
              )
            }
            color="green"
            name={BUTTON_ACTIONS.PRINT_STATISTIC}
            size="small"
            title="พิมพ์รายงานสถิติ"
            style={{ minWidth: "max-content", marginRight: "-0.5rem" }}
          />
        }
      />
    </div>
  );
};

export default React.memo(CardReportPatientList);
