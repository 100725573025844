import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
  SyntheticEvent,
} from "react";
import CardTreatmentOrderUX from "./CardTreatmentOrderUX";
import ModTreatmentTemplateUX from "./ModTreatmentTemplateUX";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import { ErrorMessage, ModInfo } from "react-lib/apps/common";
import { Icon, Item, Modal, Button } from "semantic-ui-react";
import DeleteColumn from "react-lib/appcon/common/DeleteColumn";

import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";

const CARD_TREATMENT_ORDER = "CardTreatmentOrder";
const MOD_CANCEL = "ModCancel";

const CardTreatmentOrder = (props: any) => {
  const [selectedRowTemplate, setSelectedRowTemplate] = useState<any>(null);
  const [openModTemplate, setOpenModTemplate] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.checkOutPerformDiv) {
      return;
    }

    if (
      props.errorMessage?.OrderTreatment !== null &&
      props.errorMessage?.OrderTreatment !== undefined
    ) {
      setLoading(false);
      props.runSequence({ sequence: "OrderTreatment", action: "clearMessage" });
    }
    if (
      props.successMessage?.OrderTreatment !== null &&
      props.successMessage?.OrderTreatment !== undefined
    ) {
      setLoading(false);
      setCompleted(true);
      setTimeout(() => {
        setCompleted(false);
        props.runSequence({
          sequence: "OrderTreatment",
          action: "clearMessage",
        });
      }, 2000);
    }
  }, [
    props.successMessage?.OrderTreatment,
    props.errorMessage?.OrderTreatment,
  ]);

  useEffect(() => {
    // console.log("CardTreatmentOrder start");
    props.runSequence({
      sequence: "OrderTreatment",
      restart: true,
      isNullEmr: props.isNullEmr,
      isOutPerformDiv: props.checkOutPerformDiv,
      editId: props.editId,
    });
    return () => {
      props.runSequence({ sequence: "OrderTreatment", clear: true });
    };
  }, []);

  // MedReconcile Check
  useEffect(() => {
    console.log("useEffect props.selectedEncounter: ", props.selectedEncounter);
    console.log("useEffect props.divisionType: ", props.divisionType);
    let roleTypes = props.django?.user?.role_types || [];
    if (
      ["หอผู้ป่วย"].includes(props.divisionType) &&
      (roleTypes || []).includes("DOCTOR") &&
      props.selectedEncounter?.type === "IPD"
    ) {
      let callFrom = "DPI";
      if (props.selectedEncounter?.id) {
        props.onEvent({
          message: "GetMedReconcileCheck",
          params: { encounter: props.selectedEncounter, callForm: callFrom },
        });
      }
    } else {
      console.warn("ไม่ได้มาจาก หอผู้ป่วย เปิด CardTreatmentOrder: ");
    }
  }, [props.selectedEncounter, props.django, props.divisionType]);

  const setSelectedTreatment = useCallback(
    (name: string, id: number) => {
      console.log(" setSelectedTreatment name, id: ", name, id);

      props.setProp("OrderTreatmentSequence", {
        ...props.OrderTreatmentSequence,
        searchTreatment: name,
        searchTreatmentId: id,
      });
    },
    [props.OrderTreatmentSequence, props.setProp]
  );

  const mapTreatment = (items: any) =>
    items.map((item: any) => ({
      key: item.id,
      value: item.name_code,
      text: item.name_code,
    }));

  const handleDeleteOrder = useCallback(
    (product: any) => {
      let list = (props?.OrderTreatmentSequence?.treatmentList || []).filter(
        (item: any) => item.product !== product
      );

      props.runSequence({
        sequence: "OrderTreatment",
        action: "evaluate",
        list,
        isNullEmr: props.isNullEmr,
      });
    },
    [props?.OrderTreatmentSequence?.treatmentList]
  );

  const icd9Search = useMemo(() => {
    return (props?.procedureItems?.response || []).map((item: any) => ({
      ...item,
    }));
  }, [props?.procedureItems?.response]);

  const dataTemplate = useMemo(() => {
    return (props?.treatmentTemplateList || []).map((item: any) => ({
      ...item,
      item_summary: (
        <div dangerouslySetInnerHTML={{ __html: item.item_summary }} />
      ),
    }));
  }, [props?.treatmentTemplateList]);

  const OrderTreatments = useMemo(() => {
    return (props?.OrderTreatmentSequence?.OrderTreatments || []).map(
      (item: any) => ({
        ...item,
        detail: (
          <div
            style={{
              textDecoration:
                item.order_status === "CANCEL" ? "line-through" : "",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: item.detail }} />
          </div>
        ),
        delete: (
          <div
            style={{ display: item.order_status === "PENDING" ? "" : "none" }}
          >
            <DeleteColumn
              value={true}
              id={item.id}
              titleName={`ต้องการลบรายการหัตการ นี้ใช่หรือไม่`}
              icon="trash alternat"
              circular={true}
              size="mini"
              titleColor="blue"
              onConfirm={() => {
                props.runSequence({
                  sequence: "OrderTreatment",
                  action: "cancel",
                  card: MOD_CANCEL,
                  item: item,
                });
              }}
            />
          </div>
        ),
        edit: (
          <div
            style={{ display: item.order_status === "PENDING" ? "" : "none" }}
          >
            <Button
              size="mini"
              circular
              icon="edit"
              color="blue"
              onClick={() => {
                props.runSequence({
                  sequence: "OrderTreatment",
                  action: "edit",
                  item: item,
                });
              }}
            />
          </div>
        ),
      })
    );
  }, [props?.OrderTreatmentSequence?.OrderTreatments]);

  const treatmentList = useMemo(() => {
    return (props.OrderTreatmentSequence?.treatmentList || []).map(
      (item: any, index: number) => ({
        ...item,
        product: item.name_code,
        quantity: item.quantity,
        claim: props?.claimOptions?.filter(
          (acc: any) => acc.value === item.eligibility_type
        )?.[0]?.text,
        status: (
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "5px" }}>
              {" "}
              {props?.treatmentOrderList?.item?.status_name}
            </div>
            <div style={{ flex: "1" }} />
            <div
              style={{
                marginRight: "5px",
                display: props?.treatmentOrderList?.item
                  ? props?.treatmentOrderList?.item?.status_name ===
                    "รอทำหัตถการ"
                    ? ""
                    : "none"
                  : "",
              }}
            >
              <Button
                size="mini"
                circular
                icon="minus"
                color="red"
                onClick={() => {
                  props.runSequence({
                    sequence: "OrderTreatment",
                    action: "removeTreatmentList",
                    value: item,
                    index: index,
                  });
                }}
              />
            </div>
          </div>
        ),
        del: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="red"
              icon="minus"
              onClick={(e) => handleDeleteOrder(item.product)}
              size={"mini"}
            ></Button>
          </div>
        ),
      })
    );
  }, [props.OrderTreatmentSequence]);

  const diagnosisListSecondary = useMemo(() => {
    return (props.OrderTreatmentSequence?.diagnosisListSecondary || []).map(
      (item: any, index: number) => ({
        ...item,
        icd9cm_med_term: item.medical_description || item.icd9cm_med_term,
        icd9cm_code: (
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "5px" }}> {item.icd9cm_code}</div>
            <div style={{ flex: "1" }} />
            <div style={{ marginRight: "5px" }}>
              <Button
                size="mini"
                circular
                icon="minus"
                color="red"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  props.runSequence({
                    sequence: "OrderTreatment",
                    action: "removeDiagnosis",
                    value: item,
                    index: index,
                  });
                }}
              />
            </div>
          </div>
        ),
      })
    );
  }, [props.OrderTreatmentSequence?.diagnosisListSecondary]);

  const diagnosisListPrincipal = useMemo(() => {
    return (props.OrderTreatmentSequence?.diagnosisListPrincipal || []).map(
      (item: any, index: number) => ({
        ...item,
        icd9cm_med_term: item.medical_description || item.icd9cm_med_term,
        icd9cm_code: (
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "5px" }}> {item.icd9cm_code}</div>
            <div style={{ flex: "1" }} />
            <div style={{ marginRight: "5px" }}>
              <Button
                size="mini"
                circular
                icon="minus"
                color="red"
                onClick={() => {
                  props.runSequence({
                    sequence: "OrderTreatment",
                    action: "removeDiagnosis",
                    value: item,
                    index: index,
                  });
                }}
              />
            </div>
          </div>
        ),
      })
    );
  }, [props.OrderTreatmentSequence]);

  const handleCloseModTemplate = () => setOpenModTemplate(false);

  const handleCloseModEvaluate = () => {
    props.setProp("OrderTreatmentSequence.alertMessage", "");
  };

  const addTreatment = () => {
    console.log(
      "addTreatment: ",
      props.OrderTreatmentSequence?.treatmentList,
      props.OrderTreatmentSequence?.searchTreatmentId,
      props.OrderTreatmentSequence?.searchTreatment,
      props.OrderTreatmentSequence?.treatmentQuantity,
      props.OrderTreatmentSequence?.eligibilityType,
      !props.OrderTreatmentSequence?.treatmentList
    );
    if (
      (props.OrderTreatmentSequence?.treatmentList?.find(
        (item: any) =>
          item.id !== props.OrderTreatmentSequence?.searchTreatmentId
      ) ||
        !props.OrderTreatmentSequence?.treatmentList?.length) &&
      props.OrderTreatmentSequence?.searchTreatmentId &&
      props.OrderTreatmentSequence?.searchTreatment &&
      props.OrderTreatmentSequence?.treatmentQuantity &&
      props.OrderTreatmentSequence?.eligibilityType
    ) {
      console.log(
        "OrderTreatmentSequence.treatmentList",
        props.OrderTreatmentSequence.treatmentList
      );
      let prepare = {
        product: props.OrderTreatmentSequence?.searchTreatmentId,
        name_code: props.OrderTreatmentSequence?.searchTreatment,
        quantity: props.OrderTreatmentSequence?.treatmentQuantity,
        eligibility_type: props.OrderTreatmentSequence?.eligibilityType,
        claim: props.OrderTreatmentSequence?.claim,
      };
      let list = [...(props?.OrderTreatmentSequence?.treatmentList || [])];

      list.push(prepare);

      props.runSequence({
        sequence: "OrderTreatment",
        action: "evaluate",
        list,
        isNullEmr: props.isNullEmr,
        encounterId: props.encounterId || null,
      });
    }
  };

  const onSearchTextChange = (key: string) => async (e: any, v: any) => {
    await props.setProp(`OrderTreatmentSequence.${key}`, v.value);
    props.runSequence({
      sequence: "OrderTreatment",
      action: "searchPrincipal",
      name: v.name,
      value: v.value,
    });
  };

  const onSearchSecondaryChange = (key: string) => async (e: any, v: any) => {
    await props.setProp(`OrderTreatmentSequence.${key}`, v.value);
    props.runSequence({
      sequence: "OrderTreatment",
      action: "searchSecondary",
      name: v.name,
      value: v.value,
    });
  };

  const handleChangeValue = (key: string) => (e: any, v: any) => {
    props.setProp(`treatmentOrderList.${key}`, v.value);
  };

  const handleCloseMessage = () => {
    props.setProp(`successMessage.${CARD_TREATMENT_ORDER}`, null);
    props.setProp(`errorMessage.${CARD_TREATMENT_ORDER}`, null);
  };

  const handleCloseModInfo = () => {
    props.setProp(`OrderTreatmentSequence.isOpenError`, false);
  };

  const handleCheckPreOrder = async (e: any, v: any) => {
    // console.log("Checkbox", e, v);
    const value = typeof v.checked === "boolean" ? v.checked : v.value;
    await props.setProp(`OrderTreatmentSequence.preOrderCheck`, value);

    if (v.checked === false) {
      props.setProp(`OrderTreatmentSequence.preOrderDate`, null);
    }
  };

  const onChangePreOrderDate = (key: string) => (date: any, v: any) => {
    props.setProp(`OrderTreatmentSequence.${key}`, v?.value ? v.value : date);
  };

  const handleSave = () => {
    setLoading(true);
    props.runSequence({
      sequence: "OrderTreatment",
      action: "save",
      isNullEmr: props.isNullEmr,
      card: CARD_TREATMENT_ORDER,
      isOutPerformDiv: props.checkOutPerformDiv,
      onSuccess: props.onSuccess,
      editId: props.editId,
      encounterId: props.encounterId,
      orderStatus: props?.isAppointment ? 1 : 2,
    });
  };

  const handleAddTemplate = (item: any) => {
    if (!props.checkOutPerformDiv) {
      props.runSequence({
        sequence: "OrderTreatment",
        action: "selectTemplate",
        card: CARD_TREATMENT_ORDER,
        value: item,
        isNullEmr: props.isNullEmr,
      });
    } else {
      props.runSequence({
        sequence: "OrderTreatment",
        action: "evaluate",
        list: item.template_items.map((item: any) => ({
          product: item.product,
          name_code: item.name_code,
          quantity: item.quantity,
          eligibility_type: props.claimOptions?.[0]?.value || null,
          claim: props.claimOptions?.[0]?.text || null,
        })),
        isNullEmr: props.isNullEmr,
      });
    }

    setOpenModTemplate(false);
  };

  return (
    <>
      <ModMedReconcileAlert
        // medReconcileCheck={props.medReconcileCheck}
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        onApprove={() => {
          props.setProp(
            "medReconcileCheck.open_card_med_reconciliation",
            false
          );

          props.onEvent({
            message: "OpenMedReconcileFromWarning",
            params: {
              medReconcileCheck: props.medReconcileCheck,
              selecteRecordViewIndex: props.medReconcileIndex,
            },
          });

          // find med reconcile Index
          // if (props.medReconcileIndex !== -1) {
          //   props.setProp("selectedRecordViewIndex", props.medReconcileIndex)
          // }
        }}
      >
        {props.medReconcileCheck?.med_reconciliation != null
          ? `Med Reconcile ${props.medReconcileCheck?.med_reconciliation_label} ยังไม่ได้ถูกรับทราบโดยแพทย์`
          : `ยังไม่ได้สร้าง Medication Reconciliation สำหรับ PlanTransfer ${props.medReconcileCheck?.plan_transfer_id}`}
      </ModMedReconcileAlert>

      <div style={{ height: "100%" }}>
        {props?.OrderTreatmentSequence?.alertMessage && (
          <ModInfo
            open={true}
            titleColor={"blue"}
            titleName={props?.OrderTreatmentSequence?.alertMessage}
            onApprove={handleCloseModEvaluate}
          />
        )}
        <CardTreatmentOrderUX
          setProp={props.setProp}
          onEvent={props.onEvent}
          hidePreOrder={["หน่วยตรวจ"].includes(props.divisionType || "")}
          hideSaveButton={props.hideSaveButton}
          hidePrincipalDiagnosis={props.hidePrincipalDiagnosis}
          hideSecondaryDiagnosis={props.hideSecondaryDiagnosis}
          checkOutPerformDiv={props.checkOutPerformDiv}
          divisionOpdOptions={props.divisionOpdOptions}
          titleName={props.titleName}
          OrderTreatments={OrderTreatments}
          // treatmentQuantity
          treatmentQuantity={props.OrderTreatmentSequence?.treatmentQuantity}
          onChangeTreatmentQuantity={(e: any, { value }: any) => {
            props.setProp("OrderTreatmentSequence.treatmentQuantity", value);
          }}
          // eligibilityType
          claimOptions={props.claimOptions || []}
          eligibilityType={props.OrderTreatmentSequence?.eligibilityType}
          onChangeEligibilityType={(e: any, v: any) => {
            let options = v.options.filter(
              (item: any) => item.value === v.value
            );

            props.setProp("OrderTreatmentSequence", {
              ...props.OrderTreatmentSequence,
              eligibilityType: v.value,
              claim: options?.[0]?.text,
            });
          }}
          searchTreatmentListBox={
            <SearchBoxDropdown
              type={"Treatment"}
              style={{ width: "100%" }}
              fluid={true}
              startSearchLength={3}
              onEvent={props.onEvent}
              searchedItemList={props.searchedItemList}
              selectedItem={props.OrderTreatmentSequence?.searchTreatment}
              setSelectedItem={setSelectedTreatment}
              mapOptions={mapTreatment}
            />
          }
          addTreatment={addTreatment}
          treatmentList={treatmentList}
          treatmentOrder={props?.OrderTreatmentSequence?.treatmentOrder}
          isEdit={!!props.editId}
          //Save
          save={completed ? <Icon className="check"></Icon> : "SAVE"}
          loading={loading}
          orderDetail={props?.treatmentOrderList?.orderDetail || ""}
          toBePerformer={props?.treatmentOrderList?.toBePerformer || ""}
          equipmentDetail={props?.treatmentOrderList?.equipmentDetail || ""}
          ErrorMessage={
            <ErrorMessage error={props?.errorMessage?.OrderTreatment} />
          }
          options={[
            { key: 1, value: "DOCTOR", text: "Doctor" },
            { key: 2, value: "NURSE", text: "Nurse" },
            { key: 3, value: "OTHER", text: "Other" },
          ]}
          handleChangeValue={handleChangeValue}
          onSave={handleSave}
          // Primary
          // Med Term
          medTermPrincipal={
            props.OrderTreatmentSequence?.medTermPrincipal || ""
          }
          loadingmedTermPrincipal={
            props.OrderTreatmentSequence?.loadingICD9CMTermPrincipal
          }
          // ICD9CM Term
          ICD9CMTermPrincipal={
            props.OrderTreatmentSequence?.ICD9CMTermPrincipal || ""
          }
          loadingICD9CMTermPrincipal={
            props.OrderTreatmentSequence?.loadingICD9CMTermPrincipal
          }
          // ICD9CM Code
          ICD9CMCodeprincipal={
            props.OrderTreatmentSequence?.ICD9CMCodeprincipal || ""
          }
          loadingICD9CMCodeprincipal={
            props.OrderTreatmentSequence?.loadingICD9CMCodeprincipal
          }
          //Search icd9
          icd9Search={icd9Search}
          icd9RowProps={(
            state: any,
            rowInfo: any,
            column: any,
            instance: any
          ) => {
            return {
              onClick: () => {
                props.runSequence({
                  sequence: "OrderTreatment",
                  action: "select",
                  card: CARD_TREATMENT_ORDER,
                  value: rowInfo.original,
                });
              },
            };
          }}
          showSearchResult={props?.OrderTreatmentSequence?.showSearchResult}
          onSearchTextChange={onSearchTextChange}
          //Add
          onAddPrincipal={() => {
            props.runSequence({
              sequence: "OrderTreatment",
              action:
                props.OrderTreatmentSequence?.medTermPrincipal ||
                props.OrderTreatmentSequence?.ICD9CMTermPrincipa ||
                props.OrderTreatmentSequence?.ICD9CMCodeprincipal
                  ? "add"
                  : null,
              card: CARD_TREATMENT_ORDER,
            });
          }}
          diagnosisListPrincipal={diagnosisListPrincipal}
          //pre order
          preOrderCheck={props?.OrderTreatmentSequence?.preOrderCheck || false}
          preOrderDate={props.OrderTreatmentSequence?.preOrderDate}
          handleCheckPreOrder={handleCheckPreOrder}
          onChangePreOrderDate={onChangePreOrderDate}
          // Secondary
          // Med Term
          medTermSecondary={
            props.OrderTreatmentSequence?.medTermSecondary || ""
          }
          loadingMedTermSecondary={
            props.OrderTreatmentSequence?.loadingMedTermSecondary
          }
          // ICD9CM Term
          ICD9MTermSecondary={
            props.OrderTreatmentSequence?.ICD9MTermSecondary || ""
          }
          loadingICD9MTermSecondary={
            props.OrderTreatmentSequence?.loadingICD9MTermSecondary
          }
          // ICD9CM Code
          ICD9MCodeSecondary={
            props.OrderTreatmentSequence?.ICD9MCodeSecondary || ""
          }
          loadingICD9MCodeSecondary={
            props.OrderTreatmentSequence?.loadingICD9MCodeSecondary
          }
          onSearchSecondaryChange={onSearchSecondaryChange}
          showSearchResultSecondary={
            props?.OrderTreatmentSequence?.showSearchResultSecondary
          }
          secondarySearch={props?.secondaryItems}
          secondaryRowProps={(
            state: any,
            rowInfo: any,
            column: any,
            instance: any
          ) => {
            return {
              onClick: () => {
                props.runSequence({
                  sequence: "OrderTreatment",
                  action: "selectSecondary",
                  card: CARD_TREATMENT_ORDER,
                  value: rowInfo.original,
                });
              },
            };
          }}
          //Add
          onAddSecondary={() => {
            props.runSequence({
              sequence: "OrderTreatment",
              action:
                props.OrderTreatmentSequence?.medTermSecondary ||
                props.OrderTreatmentSequence?.ICD9MTermSecondary ||
                props.OrderTreatmentSequence?.ICD9MCodeSecondary
                  ? "addSecondary"
                  : null,
              card: CARD_TREATMENT_ORDER,
            });
          }}
          diagnosisListSecondary={diagnosisListSecondary}
          onSearch={() => {
            props.runSequence({
              sequence: "OrderTreatment",
              action: "search",
              card: CARD_TREATMENT_ORDER,
              isNullEmr: props.isNullEmr,
            });
            setOpenModTemplate(true);
          }}

          // onSearch={(e: any) =>
          //   props.runSequence({
          //     sequence: "OrderTreatment",
          //     action: "search",
          //   })
          // }
        />
      </div>

      {/* <ModInfo
        open={!!props.successMessage?.[CARD_TREATMENT_ORDER]}
        titleColor="green"
        titleName="บันทึกข้อมูลสำเร็จ"
        onApprove={handleCloseMessage}
        onClose={handleCloseMessage}
      ></ModInfo>

      <ModInfo
        open={props?.OrderTreatmentSequence?.isOpenError}
        titleColor="red"
        titleName="บันทึกข้อมูลไม่สำเร็จ"
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      ></ModInfo> */}

      <Modal
        open={openModTemplate}
        size="large"
        onClose={handleCloseModTemplate}
      >
        <ModTreatmentTemplateUX
          trearmentTemplate={dataTemplate}
          rowProps={(state: any, rowInfo: any, column: any, instance: any) => {
            return {
              style: {
                backgroundColor:
                  rowInfo?.original?.id &&
                  rowInfo?.original?.id === selectedRowTemplate?.id
                    ? "#cccccc"
                    : "white",
              },
              onClick: () => setSelectedRowTemplate(rowInfo.original),
              onDoubleClick: () => handleAddTemplate(selectedRowTemplate),
            };
          }}
          OnAddTemplate={() => handleAddTemplate(selectedRowTemplate)}
        />
      </Modal>
    </>
  );
};

export default CardTreatmentOrder;
