import React, {
  useRef,
  useState,
  useCallback,
  useMemo,
  SyntheticEvent,
  useEffect,
} from "react";

import { PDFDocument } from "pdf-lib";

// UX
import CardPrintDrugLabelManualUX from "./CardPrintDrugLabelManualUX";
import { Button, Checkbox, Pagination } from "semantic-ui-react";

// Common
import getPdfMake from "react-lib/appcon/common/pdfMake";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import CardPatientSearchBox from "./CardPatientSearchBox";
import { base64toBlob } from "react-lib/apps/HISV3/common/CommonInterface";

// Form
import FormDrugLabel from "./FormDrugLabel";

// Types
type CardPrintDrugLabelManualProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // Controller
  drugOrderQueueController: any;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  // options
  masterOptions?: Record<string, any[]>;
};

type PatientType = Partial<{
  id: number;
  hn: string;
  full_name: string;
  birthdate: string;
}>;

type DrugType = Partial<{
  id: number;
  full_name: string;
  limitedDosageUnits: number[];
  pronunciationTrade: string;
  properties: string;
}>;

type DetailType = Partial<{
  method: string;
  indication: string;
  helperLabels: string;
  remark: string;
  qty: number | string;
  unit: string;
}>;

type DrugListType = {
  name: string;
  detail: DetailType;
  drug: DrugType | null;
};

const CenterStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
} as const;

const PRODUCT_KEY = "Drug_1";
const LIMIT = 10;

const CardPrintDrugLabelManual = (props: CardPrintDrugLabelManualProps) => {
  // Selected
  const [selectedPatient, setSelectedPatient] = useState<PatientType>({});
  const [selectedDrug, setSelectedDrug] = useState<DrugType | null>(null);
  const [selectedRow, setSelectedRow] = useState<
    (DrugListType & { index: number }) | null
  >(null);
  // Data
  const [detail, setDetail] = useState<DetailType>({});
  const [isBlankForm, setIsBlankForm] = useState<boolean>(false);
  const [drugList, setDrugList] = useState<DrugListType[]>([]);
  const [blobURL, setBlobURL] = useState<string>("");
  // Checked
  const [checked, setChecked] = useState<number[]>([]);
  // Pagination
  const [activePage, setActivePage] = useState<number>(1);

  // Ref
  const pdfMakeRef = useRef<any>();
  const actionAddRef = useRef<boolean>(false);

  // UseEffect
  useEffect(() => {
    const getLogochula = async () => {
      // const dataURL = await convertToDataURL("/static/images/logochula.png");
      const pdfMake = await getPdfMake();

      // logochulaRef.current = dataURL;
      pdfMakeRef.current = pdfMake;
    };

    getLogochula();
  }, []);

  useEffect(() => {
    props.onEvent({
      message: "GetMasterData",
      params: { masters: [["unit", {}]] },
    });
  }, []);

  useEffect(() => {
    const createPDF = async () => {
      const pdfDoc = await PDFDocument.create();
      const pdfMake = pdfMakeRef.current
        ? pdfMakeRef.current
        : await getPdfMake();

      const filterItems = isBlankForm
        ? [{}]
        : drugList.filter((_, index) => checked.includes(index));

      const promiseArr: Promise<any>[] = filterItems.map((data) =>
        createPDFBuffer(pdfMake, {
          ...data,
          ...(selectedPatient || {}),
          // logochula: logochulaRef.current,
        }).then((buffer) =>
          PDFDocument.load(buffer)
            // load base64
            .then((doc) =>
              pdfDoc
                .copyPages(doc, [0])
                // copyPages
                .then((copiedPages) => pdfDoc.addPage(copiedPages[0]))
            )
        )
      );

      await Promise.all(promiseArr);

      const data = await pdfDoc.saveAsBase64({ dataUri: true });

      setBlobURL(URL.createObjectURL(base64toBlob(data)));
    };

    if (actionAddRef.current) {
      actionAddRef.current = false;
      return;
    }

    createPDF();
  }, [drugList, checked, selectedPatient.id, isBlankForm]);

  // Use Callback
  const mapOptions = useCallback((items: any[]) => {
    return items.map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.full_name,
    }));
  }, []);

  const handleChecked = useCallback(
    (index: number) => (e: any, data: any) => {
      setChecked((checked) =>
        data.checked
          ? [...checked, index]
          : checked.filter((value) => value !== index)
      );
    },
    []
  );

  const handleEditRow = useCallback(
    (item: DrugListType, index: number) => () => {
      setSelectedRow({
        index,
        ...item,
      });

      props.setProp(`searchedItemListWithKey.${PRODUCT_KEY}`, [item.drug]);

      setSelectedDrug(item.drug);
      setDetail(item.detail);
    },
    []
  );

  const handleRemoveRow = useCallback(
    (index: number) => () => {
      setDrugList((list) => list.filter((_, idx) => idx !== index));
      setChecked((checked) => checked.filter((idx) => idx !== index));
    },
    []
  );

  const handleChangeValue = useCallback((e: any, data: any) => {
    let value = data.value;

    if (data.name === "qty") {
      value = Number(value) < 0 ? 0 : value;
    }

    setDetail((detail) => ({
      ...detail,
      [data.name]: value,
    }));
  }, []);

  const handleCheckedAll = useCallback(
    (e: any, data: any) => {
      setChecked(data.checked ? drugList.map((_, index) => index) : []);
    },
    [drugList]
  );

  // Use Memo
  const items = useMemo(() => {
    const offset = (activePage - 1) * LIMIT || 0;

    const subList =
      LIMIT < drugList.length
        ? drugList.slice(offset, LIMIT + offset)
        : drugList;

    return subList.map((item, index) => ({
      ...item,
      checkbox: (
        <div style={CenterStyle}>
          <Checkbox
            checked={checked.includes(index)}
            onChange={handleChecked(index)}
          />
        </div>
      ),
      edit: (
        <div style={CenterStyle}>
          <Button
            icon="edit"
            size="mini"
            color="yellow"
            style={{ padding: "8px" }}
            onClick={handleEditRow(item, index)}
          />
        </div>
      ),
      remove: (
        <div style={CenterStyle}>
          <Button
            icon="trash alternate"
            size="mini"
            color="red"
            style={{ padding: "8px" }}
            onClick={handleRemoveRow(index)}
          />
        </div>
      ),
    }));
  }, [drugList, checked, activePage]);

  const isCheckedAll = useMemo(() => {
    return !!drugList.length && checked.length === drugList.length;
  }, [drugList, checked]);

  const totalPages = useMemo(() => {
    return LIMIT > 0 ? Math.ceil(drugList.length / LIMIT) : 1;
  }, [drugList]);

  const unitOptions = useMemo(() => {
    const itemOptions = props.masterOptions?.unit || [];
    const units = selectedDrug?.limitedDosageUnits || [];
    const options = !units.length
      ? itemOptions
      : itemOptions.filter((option: any) => units.includes(option.key));

    return options.map((option) => ({ ...option, value: option.text }));
  }, [props.masterOptions?.unit, selectedDrug]);

  const headers = useMemo(() => {
    return [
      <Checkbox
        checked={isCheckedAll}
        disabled={!drugList.length}
        // callback
        onChange={handleCheckedAll}
      />,
      "รายการยา",
      "",
      "",
    ];
  }, [isCheckedAll, drugList]);

  // Handler
  const handleChangePatient = (
    id: any,
    hn: string,
    full_name: string,
    birthdate: string
  ) => {
    setSelectedPatient(id ? { id, hn, full_name, birthdate } : {});
  };

  const handleChangeCheckbox = (e: any, data: any) => {
    setIsBlankForm(data.checked);
  };

  const handleAdd = () => {
    const list = [...drugList];

    list.push({
      name: selectedDrug?.full_name || "",
      detail,
      drug: selectedDrug,
    });

    actionAddRef.current = true;

    setDrugList(list);

    handleClear();
  };

  const handleEdit = () => {
    const index = selectedRow?.index;

    const list = drugList.map((item, idx) =>
      idx === index
        ? {
            name: selectedDrug?.full_name || "",
            detail,
            drug: selectedDrug,
          }
        : { ...item }
    );

    setDrugList(list);

    handleClear();
  };

  const handleClear = () => {
    props.setProp(`searchedItemListWithKey.${PRODUCT_KEY}`, null);

    setSelectedDrug(null);
    setDetail({});
    setSelectedRow(null);
  };

  const handlePageChange = (event: SyntheticEvent, data: any) => {
    setActivePage(data.activePage);
  };

  const handleSelectedItem = async (value: any, key: any, obj: any) => {
    if (value === null && key === null) {
      return setSelectedDrug(null);
    }

    let find = props.searchedItemListWithKey?.[PRODUCT_KEY]?.find(
      (item: any) => item.id === key
    );

    if (!find) {
      return setSelectedDrug(null);
    }

    const [res] = await props.onEvent({
      message: "HandleGetDrugDetail",
      params: { id: find.id },
    });

    const drug = {
      ...find,
      full_name: res?.label_name,
      pronunciationTrade: res?.pronunciation_trade || "",
      properties: res?.indication_th || "",
      limitedDosageUnits: res?.limited_dosage_units || [],
    };

    props.setProp(`searchedItemListWithKey.${PRODUCT_KEY}`, [drug]);

    setSelectedDrug(drug);
    setDetail({ ...detail, unit: "" });
  };

  return (
    <>
      <div style={{ display: "grid", gridTemplateColumns: "47.5% 52.5%" }}>
        <div style={{ overflow: "auto", height: "calc(100vh - 6rem)" }}>
          <CardPrintDrugLabelManualUX
            // data
            patientName={selectedPatient.full_name}
            method={detail.method}
            indication={detail.indication}
            helperLabels={detail.helperLabels}
            remark={detail.remark}
            isBlankForm={isBlankForm}
            qty={detail.qty}
            unit={detail.unit}
            // table
            headers={headers}
            items={items}
            // options
            unitOptions={unitOptions}
            // config
            disabledAdd={!selectedDrug?.id}
            disabledEdit={!selectedDrug?.id}
            editMode={!!selectedRow}
            // callback
            onChangeValue={handleChangeValue}
            onChangeCheckbox={handleChangeCheckbox}
            onClickAdd={handleAdd}
            onClickEdit={handleEdit}
            Element
            PatientSearchBox={
              <CardPatientSearchBox
                // controller
                controller={props.drugOrderQueueController}
                // defaultValue={selectedPatient?.hn || ""}
                modalStyle={{ width: "auto" }}
                // clearHNInput={selectedPatient?.hn === undefined ? true : false}
                // callback
                onEnterPatientSearch={handleChangePatient}
              />
            }
            DrugSearch={
              <SearchBoxDropdown
                // config
                type="Drug"
                id="1"
                fluid={true}
                style={{ width: "100%" }}
                useWithKey={true}
                icon="search"
                // data
                searchedItemListWithKey={props.searchedItemListWithKey}
                selectedItem={selectedDrug?.id || null}
                setSelectedItem={handleSelectedItem}
                // callback
                onEvent={props.onEvent}
                mapOptions={mapOptions}
              />
            }
            Pagination={
              <Pagination
                activePage={activePage}
                boundaryRange={0}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                totalPages={totalPages}
                size="mini"
                // callback
                onPageChange={handlePageChange}
              />
            }
          />
        </div>
        <div style={{ padding: "15px 0 10px 2.5%" }}>
          <iframe src={blobURL} height="100%" width="100%"></iframe>
        </div>
      </div>
    </>
  );
};

// const DATA_URL_PDF =
//   "data:application/pdf;base64,JVBERi0xLjYKJeLjz9MKNSAwIG9iago8PAovRmlsdGVyIC9GbGF0ZURlY29kZQovTGVuZ3RoIDgKPj4Kc3RyZWFtCnicAwAAAAABCmVuZHN0cmVhbQplbmRvYmoKNCAwIG9iago8PAovVHlwZSAvUGFnZQovQ3JvcEJveCBbMCAwIDYxMiA3OTJdCi9NZWRpYUJveCBbMCAwIDYxMiA3OTJdCi9Sb3RhdGUgMAovUmVzb3VyY2VzIDw8Cj4+Ci9Db250ZW50cyA1IDAgUgovUGFyZW50IDIgMCBSCj4+CmVuZG9iagoyIDAgb2JqCjw8Ci9UeXBlIC9QYWdlcwovS2lkcyBbNCAwIFJdCi9Db3VudCAxCj4+CmVuZG9iagoxIDAgb2JqCjw8Ci9UeXBlIC9DYXRhbG9nCi9QYWdlcyAyIDAgUgovT3V0bGluZXMgNiAwIFIKPj4KZW5kb2JqCjMgMCBvYmoKPDwKL0NyZWF0aW9uRGF0ZSAoRDoyMDIzMDIwMzEwMjczNSswNycwMCcpCi9DcmVhdG9yIChBZG9iZSBBY3JvYmF0IFBybyBEQyAyMC42LjIwMDQyKQovUHJvZHVjZXIgKGlMb3ZlUERGKQovTW9kRGF0ZSAoRDoyMDIzMDIwMzAzNDIxNVopCj4+CmVuZG9iago5IDAgb2JqCjw8Ci9UeXBlIC9PYmpTdG0KL04gMwovRmlyc3QgMTQKL0ZpbHRlciAvRmxhdGVEZWNvZGUKL0xlbmd0aCAxMzkKPj4Kc3RyZWFtCnics1AwUDBXMDFRMFOwNOXisrHh0ndRiDYBigYp6EdERoGkLY0U8kpzcmK59IMV9N3zQ/K57Oy4IGodFSxASrn0QzJLclIVNJxyEvOyFQIS01M1ufQDEotS80qAJoNUwLU455cCBQ259N0yi4pLgJaD9fskItghlQWpCvr+pSU5mXmpxWCdAG50KGAKZW5kc3RyZWFtCmVuZG9iagoxMCAwIG9iago8PAovU2l6ZSAxMQovUm9vdCAxIDAgUgovSW5mbyAzIDAgUgovSUQgWzxDRTFGNUJBRUQ1MzQ0NzRFOTRDM0UxOUM1MzA5MjM4ND4gPDk5ODc2MDUwQzY0NDkwNzBFQTE3ODFEOUQwMDZBMEUxPl0KL1R5cGUgL1hSZWYKL1cgWzEgMiAyXQovRmlsdGVyIC9GbGF0ZURlY29kZQovSW5kZXggWzAgMTFdCi9MZW5ndGggNTAKPj4Kc3RyZWFtCnicY2Bg+P+fkVGegYGR4RmQYEwAsWJBBD8DAxMDJwMTiGAEEUDZX0CC6RUDAwC9iQXkCmVuZHN0cmVhbQplbmRvYmoKc3RhcnR4cmVmCjc0NgolJUVPRgo=";

// Utils
const createPDFBuffer = async (pdfMake: any, data: any): Promise<string> => {
  let docDef: any = { content: [] };

  docDef = FormDrugLabel({
    ...data,
    ...(data.detail || {}),
    ...(data.drug || {}),
    patientName: data.full_name,
    drugName: data.name,
    unitName: data.detail?.unit?.replace(/ \(.*\)$/g, ""),
  });

  return new Promise(async (resolve) =>
    pdfMake.createPdf(docDef).getBuffer((buffer: any) => resolve(buffer))
  );
};

const convertToDataURL = (imageURL: string) => {
  var image = new Image();
  image.src = imageURL;
  return new Promise<string>((resolve, reject) => {
    image.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      var ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
      ctx.drawImage(image, 0, 0);
      resolve(canvas.toDataURL("image/png"));
    };
  });
};

export default React.memo(CardPrintDrugLabelManual);
