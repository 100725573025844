import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { Button } from "semantic-ui-react";

const ANES_PreANES_00_tab = (props: any) => {
  const isMounted = React.useRef(true);
  const intl = useIntl();

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div>
      <div style={{ display: "flex", padding: "10px" }}>
        <Button
          name="History"
          onClick={props.onChangeContainerName}
          style={{ color: props.tab === "History" ? "blue" : "grey" }}
        >
          History
        </Button>
        <Button
          name="AirwayEvaluation"
          onClick={props.onChangeContainerName}
          style={{ color: props.tab === "AirwayEvaluation" ? "blue" : "grey" }}
        >
          Airway Evaluation
        </Button>
        <Button
          name="BloodChemistry"
          onClick={props.onChangeContainerName}
          style={{ color: props.tab === "BloodChemistry" ? "blue" : "grey" }}
        >
          Blood chemistry and investigation
        </Button>
        <Button
          name="AnestheticProblems"
          onClick={props.onChangeContainerName}
          style={{
            color: props.tab === "AnestheticProblems" ? "blue" : "grey",
          }}
        >
          Anesthetic problems / considerations
        </Button>
        <Button
          name="RiskAnesthesia"
          onClick={props.onChangeContainerName}
          style={{ color: props.tab === "RiskAnesthesia" ? "blue" : "grey" }}
        >
          Risk of anesthesia included and informed
        </Button>
        <Button
          name="ReEvaluation"
          onClick={props.onChangeContainerName}
          style={{ color: props.tab === "ReEvaluation" ? "blue" : "grey" }}
        >
          Preanesthetic re-evaluation
        </Button>
      </div>
      <div style={{ width: "100%", marginLeft: "10px" }}>
        <hr></hr>
      </div>
    </div>
  );
};

export default ANES_PreANES_00_tab;

export const screenPropsDefault = { containerName: "#746A6A" };

/* Date Time : Fri Nov 18 2022 17:37:57 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,padding : \"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "History"
        },
        "name": {
          "type": "value",
          "value": "History"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeContainerName"
        },
        "style": {
          "type": "code",
          "value": "{ color: props.tab === \"History\" ? \"blue\" : \"grey\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Airway Evaluation"
        },
        "name": {
          "type": "value",
          "value": "AirwayEvaluation"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeContainerName"
        },
        "style": {
          "type": "code",
          "value": "{ color: props.tab === \"AirwayEvaluation\" ? \"blue\" : \"grey\" }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Blood chemistry and investigation"
        },
        "name": {
          "type": "value",
          "value": "BloodChemistry"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeContainerName"
        },
        "style": {
          "type": "code",
          "value": "{ color: props.tab === \"BloodChemistry\" ? \"blue\" : \"grey\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthetic problems / considerations"
        },
        "name": {
          "type": "value",
          "value": "AnestheticProblems"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeContainerName"
        },
        "style": {
          "type": "code",
          "value": "{ color: props.tab === \"AnestheticProblems\" ? \"blue\" : \"grey\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Risk of anesthesia included and informed"
        },
        "name": {
          "type": "value",
          "value": "RiskAnesthesia"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeContainerName"
        },
        "style": {
          "type": "code",
          "value": "{ color: props.tab === \"RiskAnesthesia\" ? \"blue\" : \"grey\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Preanesthetic re-evaluation"
        },
        "name": {
          "type": "value",
          "value": "ReEvaluation"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeContainerName"
        },
        "style": {
          "type": "code",
          "value": "{ color: props.tab === \"ReEvaluation\" ? \"blue\" : \"grey\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\" , marginLeft:\"10px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "hr",
      "parent": 13,
      "props": {
      },
      "seq": 14,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PreANES_00_tab",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
    "containerName": "#746A6A"
  },
  "width": 80
}

*********************************************************************************** */
