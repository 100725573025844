import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown,
  Input,
  Button,
  Checkbox,
  Label
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardLabRequestUX = (props: any) => {
    return(
      <div>
        <div
          style={{padding: "10px 10px 15px", fontSize: "1.2rem", fontWeight: "bold" ,display: "flex", alignItems: "center" }}>
          Lab Request
          <div
            style={{ display: props.isIPD ? "flex" : "none", alignItems: "center", marginLeft: "auto"}}>
            
            <Checkbox
              checked={props.isOrderTime || false}
              label={props.checkOutPerformDiv ? "คำสั่งนอกแผนก" : "สั่ง LAB ล่วงหน้า"}
              onChange={props.isAdvanceChange}
              style={{marginLeft: "15px", fontSize: "1rem", marginRight: props.checkOutPerformDiv ? "12px": ""}}>
            </Checkbox>
            <DateTextBox
              disabled={props.disableOrderTime}
              onChange={props.orderTimeChange}
              style={{marginLeft: "15px", display: props.checkOutPerformDiv  ? "none" : ""}}
              value={props.orderTime}>
            </DateTextBox>
            <Dropdown
              disabled={props.disableOrderTime}
              onChange={props?.onChangeOutPerformDiv}
              options={props.divisionOpdOptions || []}
              search={true}
              selection={true}
              style={{display: props.checkOutPerformDiv  ? "" : "none"}}
              value={props?.outPerformDiv || ""}>
            </Dropdown>
          </div>
          <div
            style={{ display: props.isBloodBank ? "flex" : "none", alignItems: "center", marginLeft: "auto"}}>
            
            <Checkbox
              checked={props.appointmentDate === "beforeAppointment" }
              label={props.configAppointmentDateText}
              onChange={props.onCheckedBeforeAppointment}
              style={{marginLeft: "15px", fontSize: "1rem", marginRight:  "12px"}}>
            </Checkbox>
            <label
              style={{marginLeft: "15px", fontSize: "1rem", marginRight:  "12px"}}>
              แผนก
            </label>
            <Dropdown
              disabled={props.appointmentDate !== "beforeAppointment" }
              onChange={props?.onChangePerformDiv}
              options={props.divisionOpdOptions || []}
              search={true}
              selection={true}
              style={{display: props.appointmentDate === "beforeAppointment"  ? "" : ""}}
              value={props?.performDiv || ""}>
            </Dropdown>
          </div>
        </div>
        <div
          style={{width: "100%"}}>
          {props.ErrorMessage}
        </div>
        <div
          style={{display: "flex", alignItems: "center" }}>
          
          <div
            style={{ padding: "10px" }}>
            Lab Division
          </div>
          <Dropdown
            defaultValue="All Division"
            id="cb-labeDivision"
            onChange={props.changeLabDivision}
            options={props.labDivisions}
            search={true}
            selection={true}
            value={props.labDivision}>
          </Dropdown>
          <div
            style={{marginLeft: "20px", marginRight: "5px"}}>
            รหัสบริการ
          </div>
          <Input
            onChange={props.changeLabCode}
            onKeyDown={(e: any) => { e.key === "Enter" && props.searchLabTest(); }}
            value={props.labCode}>
          </Input>
          <div
            style={{marginLeft: "20px", marginRight: "5px"}}>
            ค้นหาชื่อ Lab
          </div>
          <Input
            onChange={props.changeLabName}
            onKeyDown={(e: any) => { e.key === "Enter" && props.searchLabTest(); }}
            value={props.labName}>
          </Input>
          <Button
            color="blue"
            id="btn-searchLab"
            onClick={props.searchLabTest}
            style={{marginLeft: "15px"}}>
            ค้นหา
          </Button>
          <Button
            color="green"
            onClick={props.openLabGroup}
            style={{marginLeft: "15px", display: props.isBloodBank? "none": ""}}>
            Lab ชุด
          </Button>
        </div>
        <Table
          data={props.labTests}
          getTrProps={props.labTestRowProps}
          headers="Code,Name,Specimen,Lab Division,การส่งตรวจ"
          id="tb-labList"
          keys="lab_code,name,specimen_name,lab_division_name,lab_type_label"
          minRows="5"
          showPagination={false}
          style={{height: "300px", marginTop: "0.5rem"}}>
        </Table>
        <Table
          data={props.labOrderItems}
          getTdProps={props.getTdPropsLabRequest}
          headers="Code,Name,Specimen,การส่งตรวจ,Specimen Time,Urgency,Note,Del"
          id="tb-labReq"
          keys="lab_code,name,specimen_name,lab_type_label,specimen_time,urgency,note,delete"
          minRows="5"
          showPagination={false}
          style={{height: "300px", margin: "0.5rem 0 1rem"}}
          widths="auto,auto,auto,auto,auto,auto,auto,60">
        </Table>
        <div
          style={{display: "flex", alignItems: "center"}}>
          
          <div
            style={{flex: 1.5, marginLeft: "10px", marginRight: "5px" }}>
            รายละเอียดเพิ่มเติม
          </div>
          <Input
            onChange={props.onChangeMessage}
            style={{flex: 3, marginLeft: "10px", marginRight: "5px" }}
            value={props.message}>
          </Input>
          <div
            style={{flex: 0.5, marginLeft: "10px", marginRight: "5px", textAlign: "right" }}>
            ราคา
          </div>
          <Label
            className="large"
            style={{flex: 1, marginLeft: "10px", marginRight: "5px", textAlign: "right", backgroundColor: "#dddddd" }}>
            {props.price_total}
          </Label>
          <div
            style={{flex: 0.5, marginLeft: "10px", marginRight: "5px", textAlign: "right" }}>
            เบิกได้
          </div>
          <Label
            className="large"
            style={{flex: 1, marginLeft: "10px", marginRight: "5px", textAlign: "right", backgroundColor: "#dddddd" }}>
            {props.price_claimable}
          </Label>
          <div
            style={{flex: 0.7, marginLeft: "10px", marginRight: "5px" , textAlign: "right" }}>
            เบิกไม่ได้
          </div>
          <Label
            className="large"
            style={{flex: 1, marginLeft: "10px", marginRight: "5px", textAlign: "right" , backgroundColor: "#dddddd" }}>
            {props.price_non_claimable}
          </Label>
        </div>
        <div
          style={{display: "flex", justifyContent: "flex-end", margin: "0.5rem 0"}}>
          
          <Button
            color={props.isEdit ? "yellow" : "green"}
            id="btn-saveLabReq"
            loading={props.loadingSave}
            onClick={props.saveLabOrder}
            size="mini"
            style={props.saveLabOrderStyle}>
            {props.button_save}
          </Button>
          <Button
            color="red"
            id="btn-cancelLabReq"
            onClick={props.onCancelEdit}
            size="mini"
            style={{display: props.showCancelEdit? "": "none"}}>
            CANCEL
          </Button>
        </div>
        <Table
          data={props.doctorLabOrderList}
          getTrProps={props.getTrPropsDoctorLabOrder}
          headers="Type,Detail,Del,Edit,Status"
          id="tb-labOrderStatus"
          keys="specific_type_name,detail,delete,edit,order_status"
          minRows={6}
          showPagination={false}
          style={{marginBottom: "4rem", ...props.doctorLabOrderTableStyle}}
          widths="300,auto,100,100,200">
        </Table>
      </div>
    )
}


export default CardLabRequestUX

export const screenPropsDefault = {"price_claimable":0,"price_non_claimable":0,"price_total":0}

/* Date Time : Fri Jul 14 2023 09:46:31 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab Request"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 15px\", fontSize: \"1.2rem\", fontWeight: \"bold\" ,display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab Division"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Dropdown",
      "parent": 2,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "defaultValue": {
          "type": "value",
          "value": "All Division"
        },
        "id": {
          "type": "value",
          "value": "cb-labeDivision"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeLabDivision"
        },
        "options": {
          "type": "code",
          "value": "props.labDivisions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.labDivision"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสบริการ"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"20px\", marginRight: \"5px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Input",
      "parent": 2,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeLabCode"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any) => { e.key === \"Enter\" && props.searchLabTest(); }"
        },
        "value": {
          "type": "code",
          "value": "props.labCode"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาชื่อ Lab"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"20px\", marginRight: \"5px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Input",
      "parent": 2,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeLabName"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any) => { e.key === \"Enter\" && props.searchLabTest(); }"
        },
        "value": {
          "type": "code",
          "value": "props.labName"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "btn-searchLab"
        },
        "onClick": {
          "type": "code",
          "value": "props.searchLabTest"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 10,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.labTests"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.labTestRowProps"
        },
        "headers": {
          "type": "value",
          "value": "Code,Name,Specimen,Lab Division,การส่งตรวจ"
        },
        "id": {
          "type": "value",
          "value": "tb-labList"
        },
        "keys": {
          "type": "value",
          "value": "lab_code,name,specimen_name,lab_division_name,lab_type_label"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\", marginTop: \"0.5rem\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 11,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.labOrderItems"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdPropsLabRequest"
        },
        "headers": {
          "type": "value",
          "value": "Code,Name,Specimen,การส่งตรวจ,Specimen Time,Urgency,Note,Del"
        },
        "id": {
          "type": "value",
          "value": "tb-labReq"
        },
        "keys": {
          "type": "value",
          "value": "lab_code,name,specimen_name,lab_type_label,specimen_time,urgency,note,delete"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\", margin: \"0.5rem 0 1rem\"}"
        },
        "widths": {
          "type": "value",
          "value": "auto,auto,auto,auto,auto,auto,auto,60"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดเพิ่มเติม"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1.5, marginLeft: \"10px\", marginRight: \"5px\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Input",
      "parent": 12,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeMessage"
        },
        "style": {
          "type": "code",
          "value": "{flex: 3, marginLeft: \"10px\", marginRight: \"5px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.message"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคา"
        },
        "style": {
          "type": "code",
          "value": "{flex: 0.5, marginLeft: \"10px\", marginRight: \"5px\", textAlign: \"right\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกได้"
        },
        "style": {
          "type": "code",
          "value": "{flex: 0.5, marginLeft: \"10px\", marginRight: \"5px\", textAlign: \"right\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกไม่ได้"
        },
        "style": {
          "type": "code",
          "value": "{flex: 0.7, marginLeft: \"10px\", marginRight: \"5px\" , textAlign: \"right\" }"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", margin: \"0.5rem 0\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Button",
      "parent": 23,
      "props": {
        "children": {
          "type": "code",
          "value": "props.button_save"
        },
        "color": {
          "type": "code",
          "value": "props.isEdit ? \"yellow\" : \"green\""
        },
        "id": {
          "type": "value",
          "value": "btn-saveLabReq"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingSave"
        },
        "onClick": {
          "type": "code",
          "value": "props.saveLabOrder"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "props.saveLabOrderStyle"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 25,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.doctorLabOrderList"
        },
        "getTdProps": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrPropsDoctorLabOrder"
        },
        "headers": {
          "type": "value",
          "value": "Type,Detail,Del,Edit,Status"
        },
        "id": {
          "type": "value",
          "value": "tb-labOrderStatus"
        },
        "keys": {
          "type": "value",
          "value": "specific_type_name,detail,delete,edit,order_status"
        },
        "minRows": {
          "type": "code",
          "value": "6"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"4rem\", ...props.doctorLabOrderTableStyle}"
        },
        "widths": {
          "type": "value",
          "value": "300,auto,100,100,200"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab ชุด"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.openLabGroup"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", display: props.isBloodBank? \"none\": \"\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.isIPD ? \"flex\" : \"none\", alignItems: \"center\", marginLeft: \"auto\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Checkbox",
      "parent": 28,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOrderTime || false"
        },
        "label": {
          "type": "code",
          "value": "props.checkOutPerformDiv ? \"คำสั่งนอกแผนก\" : \"สั่ง LAB ล่วงหน้า\""
        },
        "onChange": {
          "type": "code",
          "value": "props.isAdvanceChange"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", fontSize: \"1rem\", marginRight: props.checkOutPerformDiv ? \"12px\": \"\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 31,
      "name": "DateTextBox",
      "parent": 28,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disableOrderTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.orderTimeChange"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", display: props.checkOutPerformDiv  ? \"none\" : \"\"}"
        },
        "value": {
          "type": "code",
          "value": "props.orderTime"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Label",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.price_total"
        },
        "className": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1, marginLeft: \"10px\", marginRight: \"5px\", textAlign: \"right\", backgroundColor: \"#dddddd\" }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Label",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.price_claimable"
        },
        "className": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1, marginLeft: \"10px\", marginRight: \"5px\", textAlign: \"right\", backgroundColor: \"#dddddd\" }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Label",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.price_non_claimable"
        },
        "className": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1, marginLeft: \"10px\", marginRight: \"5px\", textAlign: \"right\" , backgroundColor: \"#dddddd\" }"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Dropdown",
      "parent": 28,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disableOrderTime"
        },
        "fluid": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props?.onChangeOutPerformDiv"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOpdOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.checkOutPerformDiv  ? \"\" : \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.outPerformDiv || \"\""
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.isBloodBank ? \"flex\" : \"none\", alignItems: \"center\", marginLeft: \"auto\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Dropdown",
      "parent": 37,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.appointmentDate !== \"beforeAppointment\" "
        },
        "fluid": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props?.onChangePerformDiv"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOpdOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.appointmentDate === \"beforeAppointment\"  ? \"\" : \"\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.performDiv || \"\""
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Checkbox",
      "parent": 37,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.appointmentDate === \"beforeAppointment\" "
        },
        "label": {
          "type": "code",
          "value": "props.configAppointmentDateText"
        },
        "onChange": {
          "type": "code",
          "value": "props.onCheckedBeforeAppointment"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", fontSize: \"1rem\", marginRight:  \"12px\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", fontSize: \"1rem\", marginRight:  \"12px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Button",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "CANCEL"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "id": {
          "type": "value",
          "value": "btn-cancelLabReq"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelEdit"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showCancelEdit? \"\": \"none\"}"
        }
      },
      "seq": 44,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardLabRequestUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "price_claimable": 0,
    "price_non_claimable": 0,
    "price_total": 0
  },
  "width": 55
}

*********************************************************************************** */
