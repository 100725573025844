import React, { useEffect, useState, useMemo } from "react";

import CardAdmitUX from "./CardAdmitUX";
import CardPatientSearchBox from "react-lib/apps/HISV3/TPD/CardPatientSearchBox";
import CardRoomSearch from "./CardRoomSearch";
import { ModConfirm } from "react-lib/apps/common";

const CardAdmit = (props: any) => {
  const [selectedPatient, setSelectedPatient] = useState<any>({});
  const [roomDetail, setRoomDetail] = useState<any>({});
  const [reserveCode, setReserveCode] = useState<string>("");
  const [admitOrder, setAdmitOrder] = useState<any[]>([]);
  const [reserveStatus, setReserveStatus] = useState<string>("");
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  useEffect(() => {
    const getAdmitOrderList = async () => {
      const result = await props.getAdmitOrderList({
        encounter__patient__hn: selectedPatient?.hn,
        not_finished: true,
        can_reserve: true,
      });

      const items: any[] = result[0]?.items || [];
      const item =
        items.find((item) => item.code === props.reserveCode) || items[0];

      setAdmitOrder(items);

      handleChangeReserveCode(null, {
        value: item?.code,
        original: item,
      });
    };

    if (selectedPatient?.hn) {
      getAdmitOrderList();
    } else {
      handleClear();
    }
  }, [selectedPatient]);

  const reserveOptions = useMemo(() => {
    return admitOrder.map((item: any, index: number) => ({
      key: index + 1,
      value: item.code,
      text: item.code,
      original: item,
    }));
  }, [admitOrder]);

  const handleChangePatient = (
    id: any,
    hn: string,
    full_name: string,
    birthdate: string
  ) => {
    setSelectedPatient(id ? { id, hn, full_name, birthdate } : {});
  };

  const handleChangeReserveCode = (e: any, v: any) => {
    setReserveCode(v.value || "");
    setReserveStatus(v.original?.status_label || "");
  };

  const handleClear = () => {
    setAdmitOrder([]);
    setReserveCode("");
    setReserveStatus("");
  };

  const handleChangeRoom = (room: any) => {
    setRoomDetail(room || {});
  };

  const handleSaveAdmit = () => {
    props.onSave?.({
      room: roomDetail,
      patient: props.patientInfo,
      reserve: reserveOptions.find((item: any) => item.value === reserveCode)
        ?.original,
    });
    handleCloseModConfirm();
  };

  const handleCloseModConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenModConfirm = () => [setOpenConfirm(true)];

  return (
    <div>
      <CardAdmitUX
        // data
        fullName={selectedPatient?.full_name || ""}
        birthdate={selectedPatient?.birthdate || ""}
        reserveOptions={reserveOptions}
        onChangeReserveCode={handleChangeReserveCode}
        reserveCode={reserveCode}
        reserveStatus={reserveStatus}
        roomName={roomDetail?.room_name || ""}
        roomStatus={roomDetail?.room_status || ""}
        allowSave={selectedPatient?.hn && reserveCode && roomDetail?.room_no}
        // Element
        PatientSearchBox={
          <CardPatientSearchBox
            // controller
            controller={props.drugOrderQueueController}
            // data
            defaultValue={props.patientInfo?.hn}
            modalStyle={{ width: "auto" }}
            // callback
            onEnterPatientSearch={handleChangePatient}
          />
        }
        CardRoomSearch={
          <CardRoomSearch
            // callback
            getRoomList={props.getRoomList}
            onEnter={handleChangeRoom}
            onSelect={handleChangeRoom}
            // options
            wardTypeOptions={props.masterOptions.wardType}
            roomTypeOptions={props.masterOptions.roomType}
            roomStatusOptions={props.masterOptions.roomStatus}
            defaultValue={props.roomNo}
          />
        }
        // callback
        onSave={handleOpenModConfirm}
      />

      <ModConfirm
        openModal={openConfirm}
        titleName="กรุณายืนยัน"
        // @ts-ignore
        content={
          <>
            ต้องการบันทึกการจองห้อง/เตียง {roomDetail?.room_no} ให้ผู้ป่วย HN{" "}
            {selectedPatient?.hn} ชื่อ {selectedPatient?.full_name} ในวันที่{" "}
            {
              reserveOptions.find((item: any) => item.value === reserveCode)
                ?.original?.admit_date
            }{" "}
            ใช่หรือไม่{" "}
          </>
        }
        onApprove={handleSaveAdmit}
        onDeny={handleCloseModConfirm}
      />
    </div>
  );
};

export default CardAdmit;
