import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button
} from 'semantic-ui-react'

const ModCoverageSelectUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{width: "100%", height: "100%", padding:  "15px"}}>
        <div
          style={{display: "flex", justifyContent: "space-between"}}>
          
          <div
            style={{fontWeight: "bold", fontSize: "18px"}}>
            รายการสิทธิที่รับรอง
          </div>
          <div
            style={{marginTop: "-15px", marginRight: "-20px"}}>
            {props.closeIcon}
          </div>
        </div>
        <hr>
        </hr>
        <Table
          data={props.patientCoverageList}
          getTrProps={props.onSelectedPatientCoverage}
          headers="Coverage Name, Payer Name, Service Type, Priority, Start Date,End Date"
          keys="coverage_name,payer_name,service_type_name,priority,start_date,stop_date"
          minRows={10}
          showPagination={false}
          style={{height: "400px"}}
          widths=",,150,150,200,200">
        </Table>
        <div
          style={{display: "flex", padding: "10px 0px", justifyContent: "flex-end"}}>
          
          <Button
            color="green"
            disabled={!props.selectedPatientCoverage}
            onClick={props.onClickSelectedPatientCoverage}
            size="small">
            เลือก
          </Button>
        </div>
      </div>
    )
}

export default ModCoverageSelectUX

export const screenPropsDefault = {}

/* Date Time : Wed Dec 28 2022 16:28:09 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", padding:  \"15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"10px 0px\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 2,
      "void": true
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 4,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.patientCoverageList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onSelectedPatientCoverage"
        },
        "headers": {
          "type": "value",
          "value": "Coverage Name, Payer Name, Service Type, Priority, Start Date,End Date"
        },
        "keys": {
          "type": "value",
          "value": "coverage_name,payer_name,service_type_name,priority,start_date,stop_date"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"400px\"}"
        },
        "widths": {
          "type": "value",
          "value": ",,150,150,200,200"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือก"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "!props.selectedPatientCoverage"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickSelectedPatientCoverage"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการสิทธิที่รับรอง"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"18px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.closeIcon"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"-15px\", marginRight: \"-20px\"}"
        }
      },
      "seq": 7,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 65,
  "isMounted": true,
  "memo": false,
  "name": "ModCoverageSelectUX",
  "project": "New Project",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
