import React, { CSSProperties, useState } from "react";
import {
  Button,
  Dropdown,
  Input,
  DropdownProps,
  InputProps,
} from "semantic-ui-react";

// Common
import ModConfirmCNMI from "react-lib/apps/common/cnmi/ModConfirm";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import EmployeeToken from "react-lib/apps/common/EmployeeToken";

type ModCancelImagingOrderProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // data
  open: boolean;
  action: string;
  orderStatus: string;
  title?: string;
  card: string;
  buttonKey?: string;
  backgroundColor?: CSSProperties["backgroundColor"];
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  userTokenize?: Partial<{
    token: string;
    employeeName: string;
    loading: boolean;
    error: any;
  }>;
  // options
  cancelImagingOrderOptions?: Record<string, any>[];
  // config
  hideReason?: boolean;
  authUsrPass?: boolean;
  infoOnly?: boolean;
  // callback
  onApprove?: (data: ApproveDataType) => any;
  onClose?: () => any;
};

export type ApproveDataType = {
  reason: string | string[];
  username: string;
  password: string;
  onClear?: () => any;
};

// Const
const BUTTON_ACTIONS = {
  del: "CANCEL_ORDER",
} as const;

const ModCancelImagingOrder = (props: ModCancelImagingOrderProps) => {
  const [reason, setReason] = useState<string | string[]>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleChangeReason = (e: any, data: DropdownProps) => {
    setReason(data.value as string | string[]);
  };

  const handleClearToken = () => {
    props.setProp("userTokenize", {
      ...props.userTokenize,
      token: "",
      employeeName: "",
      loading: false,
      error: null,
    });
  };

  const handleClear = () => {
    setReason("");
    setUsername("");
    setPassword("");

    handleClearToken();
  };

  const handleCloseCancel = () => {
    props.onClose?.();

    handleClear();
  };

  const handleApprove = () => {
    props.onApprove?.({
      reason,
      username,
      password,
      onClear: () => {
        handleClear();
      },
    });
  };

  const handleChangeUsername = (e: any, data: InputProps) => {
    setUsername(data.value);
  };

  const handleChangePassword = (e: any, data: InputProps) => {
    setPassword(data.value);
  };

  const handleEnterToken = (code: string) => {
    props.onEvent({
      message: "UserTokenize",
      params: {
        action: "CheckUserToken",
        code: code,
      },
    });
  };

  return (
    <div>
      <ModConfirmCNMI
        openModal={props.open}
        titleName={props.title ?? "Cancel Imaging order"}
        titleColor={!props.backgroundColor && "red"}
        backgroundColor={props.backgroundColor}
        size="small"
        denyButtonColor="red"
        denyButtonText="CANCEL"
        modalStyle={{ width: "27.5%" }}
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleApprove}
            // data
            paramKey={`${props.card}_${props.buttonKey || BUTTON_ACTIONS.del}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${props.card}_${props.buttonKey || BUTTON_ACTIONS.del}`
              ]
            }
            // config
            disabled={
              // disabled เมื่อ ไม่ระบุเหตุผล และ ไม่ระบุ employee code หรือ ไม่กรอก username password หาก เป็น authUsrPass
              props.infoOnly
                ? false
                : props.hideReason
                ? props.authUsrPass
                  ? !username || !password
                  : !props.userTokenize?.token
                : !reason ||
                  (props.authUsrPass
                    ? !username || !password
                    : !props.userTokenize?.token)
            }
            color={"green"}
            name={props.buttonKey || BUTTON_ACTIONS.del}
            size="medium"
            title="CONFIRM"
            basic={true}
            fluid={true}
          />
        }
        content={
          <div
            style={{
              margin: "-1rem 0.5rem -1.5rem 0.5rem",
              lineHeight: 1.75,
              textAlign: "center",
            }}
          >
            <div>
              Do you want to <strong>{props.action}</strong> this order ?
            </div>
            <div
              style={{
                marginBottom: props.hideReason && !props.infoOnly ? "1rem" : "",
              }}
            >
              The order status will be {props.orderStatus}
            </div>

            {/* แสดงเมื่อ ไม่ซ่อนเหตุผล และ แสดงเฉพาะ infomation เท่านั้น */}
            {!props.hideReason && !props.infoOnly && (
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  margin: "1rem 0 1rem",
                }}
              >
                <div style={{ minWidth: "max-content", marginRight: "1rem" }}>
                  Please specify<span style={{ color: "red" }}>*</span>
                </div>
                <Dropdown
                  value={reason || ""}
                  options={props.cancelImagingOrderOptions}
                  search={true}
                  multiple={true}
                  selection={true}
                  fluid={true}
                  // style
                  style={{ width: "100%" }}
                  onChange={handleChangeReason}
                />
              </div>
            )}

            {!props.infoOnly && (
              <>
                {props.authUsrPass ? (
                  <div style={{ display: "flex", padding: "0 1.5rem" }}>
                    <Input
                      value={username}
                      placeholder="Username"
                      fluid={true}
                      style={{ width: "100%", marginRight: "1rem" }}
                      onChange={handleChangeUsername}
                    />
                    <Input
                      value={password}
                      placeholder="Password"
                      fluid={true}
                      type="password"
                      style={{ width: "100%" }}
                      onChange={handleChangePassword}
                    />
                  </div>
                ) : (
                  <div>
                    <EmployeeToken
                      placeholder={"Employee code"}
                      onEnterToken={handleEnterToken}
                      onClearToken={handleClearToken}
                      error={!!props.userTokenize?.error}
                      loading={props.userTokenize?.loading}
                      employeeName={props.userTokenize?.employeeName}
                      fluid={true}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        }
        onDeny={handleCloseCancel}
        onCloseWithDimmerClick={handleCloseCancel}
      />
    </div>
  );
};

export default React.memo(ModCancelImagingOrder);
