import React, {
  CSSProperties,
  SyntheticEvent,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import moment from "moment";
import {
  Modal,
  Checkbox,
  CheckboxProps,
  Divider,
  Dropdown,
  Button,
  Icon,
  Popup,
} from "semantic-ui-react";
import { momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import CardPatientAppointmentUX from "./CardPatientAppointmentUX";
import CardAppointmentDetailUX from "./CardAppointmentDetailUX";
import CardWaitingListUX from "./CardWaitingListUX";
import CardRescheduleUX from "./CardRescheduleUX";
import CardReconfirmUX from "./CardReconfirmUX";
import CardORRequest from "../HISV3/ORM/CardORRequest";
import CardSelectPackage from "../HISV3/HCU/CardSelectPackage";
import CardSelectDateTimePackage from "../HISV3/HCU/CardSelectDateTimePackage";

import ModEventDetailUX from "./ModEventDetailUX";
import ModWaitingListManageUX from "./ModWaitingListManageUX";
import ModOpenSendToClinicUX from "./ModOpenSendToClinicUX";
import ModSummaryWaitingListUX from "./ModSummaryWaitingListUX";
import ModSummaryStaticUX from "./ModSummaryStaticUX";
import ModSearchDoctorNote from "react-lib/apps/APP/ModSearchDoctorNote";
import { appointmentListForPatientAppointment } from "react-lib/apps/Scheduling/common/AppointmentList";
import ModConfirmWithNote from "react-lib/appcon/common/ModConfirmWithNote";
import { PatientSearchBoxCU } from "react-lib/apps/common";
import EmployeeToken from "react-lib/apps/common/EmployeeToken";
import CONFIG from "config/config";
import ModConfirmAuthen from "react-lib/apps/common/ModConfirmAuthen";
import ModDuplicateAppointment from "./ModDuplicateAppointment";
import ModSelectEncounter from "./ModSelectEncounter";
import { MutableRefObject } from "react";
import {
  parseDate,
  formatADtoBEString,
  dateToStringAD,
} from "react-lib/utils/dateUtils";
import { DATE_FORMAT } from "react-lib/apps/Scheduling/common/Utils";
// @ts-ignore
import { CSVLink } from "react-csv";

// Ishealth-v3 port into CUDent
import CardTreatmentOrder from "react-lib/apps/HISV3/DPO/CardTreatmentOrder";
import CardLabRequest from "react-lib/apps/HISV3/LAB/CardLabRequest";
import CardImagingRequest from "react-lib/apps/Scheduling/CardImagingRequest";
import SnackMessage from "../common/SnackMessage";

import CardSelectedDateTimeOperation from "react-lib/apps/Scheduling/CardSelectDateTimeOperation";
import CardOtherOrderAppointment from "react-lib/apps/Scheduling/CardOtherOrderAppointment";

// import config from "config/config";

moment.locale("en-es", { week: { dow: 1 } });
const localizer = momentLocalizer(moment);

const NOTE_MSG = {
  pause: {
    title: "ต้องการพักคิวผู้ป่วย",
    color: "blue",
    subtitle: "ระบุเหตุผลในการพักคิว",
  },
  resume: {
    title: "ต้องการคืนคิวผู้ป่วย",
    color: "orange",
    subtitle: "ระบุเหตุผลในการคืนคิว",
  },
  cancel: {
    title: "ต้องการยกเลิกแผนการรักษา",
    color: "red",
    subtitle: "ระบุเหตุผลในการยกเลิกแผนการรักษา",
  },
};

const IMAGES = {
  appointment_outline: "/static/images/order_images/appointment_outline.png",
  or_outline: "/static/images/order_images/or_outline.png",
  waiting_list_outline: "/static/images/order_images/waiting_list_outline.png",
  package_outline: "/static/images/order_images/package_outline.png",
};

const APPOINTMENT_MSG = {
  appointment: "นัดหมายทั่วไป",
  or: "นัดหมายผ่าตัด (EN เดิม)",
  new_or: "นัดหมายผ่าตัด (EN ใหม่)",
  waiting_list: "Waiting List",
  package: "นัดหมาย Package",
} as const;

const TYPE_DISPLAY = {
  or: "นัดหมายผ่าตัด",
  package: "นัดหมาย Package",
};

const APPOINTMENT_TYPE = [
  { key: 1, text: TYPE_DISPLAY.or, value: "OR" },
  { key: 2, text: "Waiting List", value: "WAITING_LIST" },
  { key: 3, text: "นัดหมายทั่วไป", value: "NORMAL" },
] as const;

const SelectItem = (props: any) => (
  <Checkbox
    key={props.index}
    checked={props.item?.id && props.selectedIdList.includes(props.item.id)}
    onClick={(e: any) => {
      e.stopPropagation();
      console.log(props.item);
      if (props.item?.id) {
        console.log(props.selectedIdList);
        let newSelectedIdList: number[] = Object.assign(
          [],
          props.selectedIdList
        );
        if (props.selectedIdList.includes(props.item.id)) {
          newSelectedIdList = newSelectedIdList.filter(
            (id: number) => id !== props.item.id
          );
        } else {
          newSelectedIdList.push(props.item.id);
          props.setSelectedDoctorEditWaiting("");
          props.setSelectedTreatmentType("");
          props.setNote("");
          props.setOpenModClinic(false);
        }
        console.log(newSelectedIdList);
        props.setSelectedIdList(newSelectedIdList);
        props.setOpenModClinic(false);
      }
    }}
  />
);

const MenuItem = (props: any) => (
  <Button
    size="tiny"
    onClick={(e: any) => props.setTab(props.menu)}
    color={props.menu === props.tab ? "blue" : undefined}
  >
    {props.name}
  </Button>
);

const staterDate = new Date().setMonth(new Date().getMonth() - 1);
const startDateDefault = moment(staterDate)?.format(DATE_FORMAT);
const endDateDefault = moment(new Date())?.format(DATE_FORMAT);

const CardPatientAppointment = (props: any) => {
  // Ishealth-v3 port into CUDent
  const CARD_PATIENT_APPOINTMENT = "CardPatientAppointment";

  // const [mode, setMode] = useState("search");
  const [mode, setMode] = useState(
    !props.config?.disabledWaitingList ? "waitingList" : "search"
  );
  const [tab, setTab] = useState(
    !props.config?.disabledWaitingList ? "detail" : "search"
  );
  const [modOrder, setModOrder] = useState("");
  const [showMod, setShowMod] = useState(false);
  const [showModWaitingList, setShowModWaitingList] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [blockAppointments, setBlockAppointments] = useState<any[]>([]);

  // PatientAppointment
  const [patientSearchText, setPatientSearchText] = useState("");
  const [selectedDoctorPA, setSelectedDoctorPA] = useState<any>(null);
  const [selectDivisionPA, setSelectDivisionPA] = useState<any>(null);
  const [waitingListNamePA, setWaitingListNamePA] = useState<any>("");
  const [filterAppointmentList, setFilterAppointmentList] = useState<any[]>([]);

  // Duplicated make appointment
  const [duplicateReason, setDuplicateReason] = useState("");

  const [selectedDivision, setSelectedDivision] = useState<any>(null);
  // เลือกวันเวลาออกตรวจ
  // ค้นหาทันตแพทย์ สำหรับ เลือกวันเวลาออกตรวจ
  const [selectedProvider, setSelectedProvider] = useState<any>(null);
  const [blockFilterAppointments, setBlockFilterAppointments] = useState<any[]>(
    []
  );

  const [selectedDivisionApp, setSelectedDivisionApp] = useState<any>(null);
  const [estimatedAt, setEstimatedAt] = useState("");
  const [estimatedDuration, setEstimatedDuration] = useState("");
  const [orderNote, setOrderNote] = useState("");
  // const [full, setFull] = useState(false);
  // const [manualTime, setManualTime] = useState(false);

  // ตัวเลือก waitingList ใน WaitingList Table (Tab waitingList)
  const [selectedWaitingList, setSelectedWaitingList] = useState<any>(null);

  const [waitingListItems, setWaitingListItems] = useState<any[]>([]);
  const [manageWaitingListItems, setManageWaitingListItems] = useState<any[]>(
    []
  );
  const [selectedIdList, setSelectedIdList] = useState<number[]>([]);

  // ตัวเลือก waitingListItem ใน WaitingListItem Table (Tab waitingList)
  const [selectedWaitingListItem, setSelectedWaitingListItem] =
    useState<any>(null);
  const [note, setNote] = useState("");
  const [selectedPatientId, setSelectedPatientId] = useState<any>(null);
  const [selectedDoctorAddWaiting, setSelectedDoctorAddWaiting] =
    useState<any>(null);
  const [selectedDoctorEditWaiting, setSelectedDoctorEditWaiting] =
    useState<any>(null);
  const [selectDivision, setSelectDivision] = useState<any>(null);
  const [openModDoctorNote, setOpenModDoctorNote] = useState<boolean>(false);
  const [openModClinic, setOpenModClinic] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = React.useState<any>(null);

  // Input Search
  const [searchWaitingList, setSearchWaitingList] = useState<string>("");
  const [filterWaitingList, setFilterWaitingList] = useState<any[]>([]);
  // Checkbox Search
  const [isAppointed, setIsAppointed] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [isOnHoldQ, setIsOnHoldQ] = useState<boolean>(false);
  // PatientSearchBox
  const [filterPatientName, setFilterPatientName] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>("รอนัดหมาย");
  // จัดการผู้ป่วยรายคน
  const [selectedManagePatient, setSelectedManagePatient] = useState<any>(null);
  const [selectedTreatmentType, setSelectedTreatmentType] = useState<any>(null);
  // group button manage
  const [openModConfirmNote, setOpenModConfirmNote] = useState<boolean>(false);
  const [modConfirmTitle, setModConfirmTitle] = useState<string>("");
  const [modConfirmSubtitle, setModConfirmSubtitle] = useState<string>("");
  const [modColor, setModColor] = useState<string>("");
  const [manageQNote, setManageQNote] = useState<string>("");

  const hnSearchRefPA = useRef(null) as MutableRefObject<any>;

  // PatientSearch
  const hnSearchRef = useRef(null) as MutableRefObject<any>;

  // CardReconfirmed
  const hnSearchRefRC = useRef(null) as MutableRefObject<any>;
  const [selectedDoctorRC, setSelectedDoctorRC] = useState<any>(null);
  const [selectDivisionRC, setSelectDivisionRC] = useState<any>(null);
  const [selectWaitingListRC, setSelectWaitingListRC] = useState("");
  const [selectAppointmentType, setSelectedAppointmentType] = useState("");
  const [selectStatusRC, setSelectStatusRC] = useState("");
  const [startDateRC, setStartDateRC] = useState("");
  const [endDateRC, setEndDateRC] = useState("");

  // CardReschedule
  const hnSearchRefRS = useRef(null) as MutableRefObject<any>;
  const [selectedDoctorRS, setSelectedDoctorRS] = useState<any>(null);
  const [selectDivisionRS, setSelectDivisionRS] = useState<any>(null);
  const [selectWaitingListRS, setSelectWaitingListRS] = useState("");
  const [selectStatusRS, setSelectStatusRS] = useState("");
  const [startDateRS, setStartDateRS] = useState("");
  const [endDateRS, setEndDateRS] = useState("");

  // ModConfirm
  const [questionDetail, setQuestionDetail] = useState<any>(null);
  const [openModMessage, setOpenModMessage] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [currentValue, setCurrentValue] = useState([]);

  //Button Static
  const [modTab, setModTab] = useState("queue");
  const [showModStatic, setShowModStatic] = useState(false);

  //ModWaitingLists
  const [startDateModWL, setStartDateModWL] = useState(startDateDefault);
  const [endDateModWL, setEndDateModWL] = useState(endDateDefault);
  const [waitingQueueList, setWaitingQueueList] = useState<any>([]);

  // sortstate ascending = น้อย ไป มาก
  const [isAscendingCreated, setIsAscendingCreated] = useState(false);
  const [isAscendingAppointed, setIsAscendingAppointed] = useState(false);
  const [isAscendingWaitingTime, setIsAscendingWaitingTime] = useState(false);

  //ModStatic
  const [startDateModST, setStartDateModST] = useState(startDateDefault);
  const [endDateModST, setEndDateModST] = useState(endDateDefault);
  const [onlyNewPatient, setOnlyNewPatient] = useState<boolean>(false);

  const [openModOrder, setOpenModOrder] = useState<{
    id?: number;
    type?: string;
  } | null>(null);
  const [complete, setComplete] = useState<string>("");
  const [openModSelectEncounter, setOpenModSelectEncounter] = useState<{
    open: boolean;
    isNewEncounter: boolean;
  } | null>(null);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [calendarYear, setCalendarYear] = useState<string[]>([
    moment().format("YYYY"),
  ]);

  const isInitialMount = useRef(true); // flag use only one useEffect
  const goToTabSelectDateTime = useRef<boolean>(false);

  // ---------------------------------------------------------------
  //  CDM
  // ---------------------------------------------------------------
  useEffect(() => {
    props.onEvent({
      message: "SetScheduling",
      params: {
        action: "GetCardPatientAppointmentData",
      },
    });

    props.onEvent({
      message: "Holiday",
      params: {
        action: "refresh",
      },
    });
    
    props.onEvent({ message: "GetChairList", params: {} });

    props.onEvent({
      message: "GetMasterData",
      params: {
        masters: [["patientAppType", {}]],
      },
    } as any);
  }, []);

  // ---------------------------------------------------------------
  //  เมื่อส่ง goToCreateAppointment ให้ทำการค้นหา และ เลือกผู้ป่วยคนนั้น
  // ---------------------------------------------------------------
  useEffect(() => {
    if (
      props.goToCreateAppointment?.selectedPatient?.id &&
      props.goToCreateAppointment?.selectedPatient?.hn
    ) {
      setMode("search");
      props.onEvent({
        message: "SearchPatient",
        params: {
          patientSearchText: props.goToCreateAppointment?.selectedPatient?.hn,
        },
      });

      let params = { ...props.goToCreateAppointment?.selectedPatient };
      console.log("params", params);
      props.onEvent({
        message: "SelectPatient",
        params: params,
      });

      hnSearchRefPA?.current?.setValue(
        props.goToCreateAppointment?.selectedPatient?.hn
      );

      setSelectedPatientId(props.goToCreateAppointment?.selectedPatient?.id);
    }
  }, [props.goToCreateAppointment]);

  // ---------------------------------------------------------------
  //  Selected Division
  // ---------------------------------------------------------------
  useEffect(() => {
    props.onEvent({
      message: "FilterSchedule",
      params: {
        divisionId: props.selectedDivision?.id,
        initial: selectedDivision === null,
      },
    });

    setSelectedDivision(props.selectedDivision);
    props.onEvent({
      message: "ItemSearch",
      params: {
        id: "1",
        type: "Division",
        searchText: props.selectedDivision?.name,
        verbose: false,
      },
    });
    setSelectDivisionRC(props.selectedDivision);

    props.onEvent({
      message: "SetScheduling",
      params: {
        action: "GetCardPatientAppointmentData",
      },
    });

    setSelectedWaitingList(null);
    setSelectedWaitingListItem(null);
    setManageWaitingListItems([]);
    setWaitingListItems([]);
    setTab("detail");
    setFilterWaitingList([]);
    setManageWaitingListItems([]);
    // For search mode
    searchPatient();
    setFilterAppointmentList([]);
  }, [props.selectedDivision]);

  const searchPatient = () => {
    const params = new URLSearchParams(window.location.search);
    const hn = params.get("HN");
    const id = params.get("ID");
    if (hn && id) {
      setMode("search");
      window.history.replaceState({}, "", "?app=Scheduling");
      handleOnSelectPatient(id, hn);
    } else {
      props.onEvent({ message: "ClearAppointment", params: {} });
    }
  };

  useEffect(() => {
    if (props.patientList?.[0] && hnSearchRefPA.current) {
      const patient = props.patientList?.[0];
      hnSearchRefPA?.current?.setValue(
        `[${patient?.hn}] ${patient?.full_name}`
      );
    }
  }, [props.patientList]);

  // ---------------------------------------------------------------
  //  Change mode
  // ---------------------------------------------------------------
  useEffect(() => {
    // console.log(" mode useEffect ", mode)

    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    // mode :  "waitingList" "reconfirm"  "reschedule" "search"
    props.onEvent({ message: "ClearAppointment", params: {} });

    if (mode === "reconfirm") {
      props.onEvent({ message: "GetReconfirm", params: {} });
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "GetReconfirmAppointment",
          division_id: props.selectedDivision?.id,
        },
      });
    } else if (mode === "reschedule") {
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "GetRescheduleAppointment",
        },
      });
    } else if (mode === "search") {
      props.onEvent({ message: "SelectPatient", params: null });
      setFilterAppointmentList([]);
    }
    // console.log("Get useEffect mode: ", mode);
  }, [mode]);

  // ---------------------------------------------------------------
  //  เลือก Appointment , เปิด Tab ด้านขวา
  // ---------------------------------------------------------------
  useEffect(() => {
    // เลือก Appointment

    // console.log('useEffect props.selectedAppointment?.division_service_block: ', props.selectedAppointment?.division_service_block);
    // console.log('useEffect props.selectedBlock?.dsb_id: ', props.selectedBlock?.dsb_id);
    // console.log('useEffect props.selectedAppointment: ', props.selectedAppointment, goToTabSelectDateTime);

    if (
      !props.selectedAppointment?.id ||
      props.selectedAppointment?.main_patient_appointment
    ) {
      // if (!props.selectedAppointment?.id && props.selectedBlock) {
      // console.log("useEffect setTab to calendar ")
      if (props.selectedAppointment?.estimated_at_iso) {
        setEstimatedAt(
          moment(props.selectedAppointment?.estimated_at_iso).format("HH:mm")
        );
      }

      if (props.selectedAppointment?.appointment_type === "PACKAGE") {
        setTab("selectpackage");
      } else {
        setTab("detail");
      }

      return;
    }
    // have Appointment
    if (
      // props.selectedAppointment?.division_service_block == null &&
      props.selectedBlock?.dsb_id == null
    ) {
      // null or undefined, https://262.ecma-international.org/12.0/#sec-abstract-equality-comparison
      // console.log("useEffect setTab to calendar ",props.selectedAppointment, isCreate);
      if (props.selectedAppointment?.type_display === TYPE_DISPLAY.or) {
        if (goToTabSelectDateTime.current || props.isSelectTabCalendar) {
          goToTabSelectDateTime.current = false;
          props.setProp("isSelectTabCalendar", false);
          setTab("selectdatetimeoperation");
        } else {
          setTab("or_request");
        }
      } else if (props.selectedAppointment?.appointment_type === "PACKAGE") {
        setTab("selectpackage");
      } else {
        setTab("calendar");
      }
    } else {
      // console.log('props.selectedAppointment?.id: ', props.selectedAppointment?.id);
      // console.log('props.selectedBlock: ', props.selectedBlock);
      if (
        props.selectedAppointment?.type_display !== TYPE_DISPLAY.or &&
        props.selectedAppointment?.id &&
        !props.selectedAppointment?.estimated_at
      ) {
        setTab("calendar");
      } else {
        setTab("detail");
      }

      console.log(
        "useEffect props.selectedAppointment?.estimated_at: ",
        props.selectedAppointment?.estimated_at
      );
      const estimatedTime =
        props.selectedAppointment?.estimated_at?.split(" ")?.[1] || "";
      console.log("useEffect estimatedTime: ", estimatedTime);
      const newEstimatedAt = estimatedTime.substring(
        1,
        estimatedTime.length - 1
      );
      console.log("useEffect newEstimatedAt: ", newEstimatedAt);
      const newManualTime = !props.availableSlots
        .map((item: any) => item.value)
        .includes(newEstimatedAt);
      console.log("useEffect props.availableSlots: ", props.availableSlots);
      console.log("useEffect newManualTime: ", newManualTime);
      console.log("useEffect estimatedAt: ", estimatedAt);

      // setManualTime(newManualTime);

      if (
        props.selectedAppointment?.status === 4 ||
        props.selectedAppointment?.status === 3
      ) {
        // กรณี แก้ไข appointment เดิมก็จะ โชว์ อันเดิม => setEstimatedAt(estimatedAt || newEstimatedAt);
        // แต่ Issue 56255 มันคือ การเลื่อน ต้อง เลือก estimatedAt ใหม่ ซึ่งคือ
        setEstimatedAt(newEstimatedAt);
      } else {
        // กรณี แก้ไข appointment เดิมก็จะ โชว์ อันเดิม
        setEstimatedAt(estimatedAt || newEstimatedAt);
      }

      // console.log("useEffect &&&&&&&&&& props.selectedAppointment?.estimated_duration: ", props.selectedAppointment?.estimated_duration);
      // console.log("useEffect &&&&&&&&&& props.selectedAppointment?.slotLength: ", props.selectedAppointment?.slotLength);
      // กำหนด slotLength จาก template  หรือ เลือกจาก selectedAppointment
      if (!estimatedDuration) {
        if (props.selectedAppointment?.estimated_duration) {
          setEstimatedDuration(
            props.selectedAppointment?.estimated_duration.toString()
          );
        } else {
          setEstimatedDuration(
            props.selectedAppointment?.slotLength?.toString() || 5
          );
        }
      }

      setOrderNote(props.selectedAppointment?.order_note || "");
      // setFull(props.selectedAppointment?.dsb_full);
    }
  }, [props.selectedAppointment, props.availableSlots]);

  // ---------------------------------------------------------------
  // WaitingList เมื่อมีการ update WaitingList จะคำนวน
  // 1. filterWaitingList Table(1)
  // 2. selectedWaitingList เลือก อีกที บางที ไม่มีแล้ว
  // 3. clear Confirmed Note
  // ---------------------------------------------------------------
  useEffect(() => {
    let filter = props.waitingList;

    if (searchWaitingList?.length > 0) {
      filter = props.waitingList?.filter((item: any) =>
        item?.name?.includes(searchWaitingList)
      );
    }

    // console.log('filter: ', filter);
    // Updated added item from props.waitingList
    if (Number.isInteger(selectedWaitingList?.id)) {
      // มีการเลือก
      let findSelected = filter?.find(
        (item: any) => item.id === selectedWaitingList?.id
      );

      // console.log("useEffect &&&&&&&&&&&&&&&& findSelected", findSelected)
      // console.log('useEffect &&&&&&&&&&&&&&&& props.selectedPatient: ', props.selectedPatient);
      // console.log('useEffect &&&&&&&&&&&&&&&& selectedWaitingList: ', selectedWaitingList);
      if (
        (findSelected && !props.selectedPatient) ||
        (findSelected?.items &&
          findSelected?.items?.length !== selectedWaitingList?.items?.length)
      ) {
        // console.log("useEffect &&&&&&&&&&&&&&&& setSelectedWaitingList", findSelected)
        setSelectedWaitingList(findSelected);
      }

      // console.log("useEffect &&&&&&&&&&&&&&&& findSelected?.items?.length", findSelected?.items?.length)
      // console.log("useEffect &&&&&&&&&&&&&&&& selectedWaitingList?.items?.length", selectedWaitingList?.items?.length)
      // if (findSelected?.items?.length !== undefined && selectedWaitingList?.items?.length !== findSelected?.items?.length) {
      //   console.log(" @@@ not equal @@@ findSelected: ", findSelected)

      // }
    }

    if (props.waitingList && openModConfirmNote) {
      setOpenModConfirmNote(false);
      setModConfirmTitle("");
      setModColor("");
      setModConfirmSubtitle("");
      setManageQNote("");
      setSelectedManagePatient(null);
      props.onEvent({
        message: "UserTokenize",
        params: {
          action: "clear",
        },
      });
    }

    setFilterWaitingList(filter);
  }, [props.waitingList, searchWaitingList, selectedWaitingList]);

  // ---------------------------------------------------------------
  // เลือก selectedAppointment จะ คำนวน หา รายชื่อคนไข้อื่นๆในคาบเดียวกัน
  // ---------------------------------------------------------------
  useEffect(() => {
    // console.log(" -->  useEffect blockAppointment props.selectedAppointment?.division_service_block ", props.selectedAppointment?.division_service_block )
    // console.log(" -->  useEffect blockAppointment props.selectedBlock: ", props?.selectedBlock )
    console.log(
      " -->  useEffect blockAppointment props.blockList: ",
      props.blockList
    );

    const temp =
      props.blockList
        .flatMap((block: any) =>
          (block?.providers || []).map((provider: any) => provider?.dsb)
        )
        .find((dsb: any) =>
          props.selectedBlock?.doctor_dsb_id
            ? dsb?.doctor_dsb_id === props.selectedBlock?.doctor_dsb_id
            : dsb?.doctor_dsb_id ===
              props.selectedAppointment?.division_service_block
        )
        ?.appointments?.filter((item: any) => item.status === 1) || []; // filter (!==4 Issue 56214)

    console.log(" -->  useEffect blockAppointment temp: ", temp);
    const result = temp.map((app: any) => ({
      patient_name: `${app?.first_name} ${app?.last_name}`,
      estimated_at_display: app?.estimated_at_display,
      estimated_duration: app?.estimated_duration,
      // edit_user: app?.edit_user,
      edit_user_name: app?.edit_user_name,
      updated: app?.updated ? dateToStringAD(app?.updated) : "",
    }));

    console.log(" -->  useEffect blockAppointment result: ", result);
    setBlockAppointments(result);
  }, [
    props.selectedAppointment?.division_service_block,
    props.selectedBlock,
    props.blockList,
  ]);

  // ---------------------------------------------------------------
  // เลือก selectedWaitingList ยิง API ที่ 2 เพื่อดึง GetWaitingListItem
  // ---------------------------------------------------------------
  useEffect(() => {
    if (!(Array.isArray(selectedIdList) && selectedIdList?.length === 0)) {
      setSelectedIdList([]);
    }
    if (selectedWaitingList?.id) {
      console.log("selectedWaitingList: ", selectedWaitingList);
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "GetWaitingListItem",
          waiting_list_id: selectedWaitingList?.id,
        },
      });
    }
  }, [selectedWaitingList]);

  // useWhatChanged([props.waitingList, selectedStatus, selectedIdList, filterPatientName, selectedWaitingList]);

  // ---------------------------------------------------------------
  // waitingList คำนวน WaitingListItem Table(2)
  // 1. ผ่านทาง updateWaitingListItem Table(2)
  // ---------------------------------------------------------------
  useEffect(() => {
    // console.log("useEffect ---> props.waitingList: ", props.waitingList)
    // console.log('useEffect ---> selectedWaitingList: ', selectedWaitingList);
    if (selectedWaitingList?.id && props.waitingList?.length >= 0) {
      let idx = props.waitingList?.findIndex(
        (item: any) => item.id === selectedWaitingList?.id
      );
      if (idx >= 0) {
        // console.log('useEffect ---> idx: ', idx);
        // console.log('useEffect --->  selectedWaitingListItem?.id' , selectedWaitingListItem?.id)

        // Workaround

        // console.log('useEffect --->  props.waitingList?.[idx].status: ', props.waitingList?.[idx].status);
        // console.log('useEffect --->  props.waitingList: ', props.waitingList);
        // console.log('useEffect --->  selectedWaitingList?.items?.[idx]?.status: ', selectedWaitingList?.items?.[idx]?.status);
        // console.log('useEffect --->  selectedWaitingList: ', selectedWaitingList);
        let newStatus = props.waitingList?.[idx]?.items?.find(
          (item: any) => item.id === selectedWaitingListItem?.id
        )?.status;
        // console.log('useEffect --->  newStatus: ', newStatus);
        let prevStatus = selectedWaitingList?.items?.find(
          (item: any) => item.id === selectedWaitingListItem?.id
        )?.status;
        // console.log('useEffect --->  prevStatus: ', prevStatus);
        if (newStatus && newStatus !== prevStatus) {
          setSelectedWaitingList(props.waitingList?.[idx]);
        }
        updateWaitingListItems();
      }
    }
  }, [
    props.waitingList,
    selectedStatus,
    selectedIdList,
    filterPatientName,
    selectedWaitingList,
  ]);

  // ---------------------------------------------------------------
  // หน้า Modal WaitingList , filter คำนวน manageWaitingListItem
  // ---------------------------------------------------------------
  useEffect(() => {
    if (showModWaitingList && selectedWaitingList?.items) {
      // console.log("useEffect ----->  recal manageWaitingListItems")
      const newManageWaitingListItems = (selectedWaitingList?.items || [])
        .filter((item: any) =>
          isAppointed ? true : item.status !== "APPOINTMENT"
        )
        .filter((item: any) => (isOnHoldQ ? true : item.status !== "STALL"))
        .filter((item: any) => (isCancel ? true : item.status !== "CANCEL"))
        .map((item: any, index: number) => ({
          index: index + 1,
          ...item,
          checkbox: (
            <SelectItem
              index={index}
              item={item}
              selectedIdList={selectedIdList}
              setSelectedIdList={setSelectedIdList}
              setOpenModClinic={setOpenModClinic}
              setSelectedDoctorEditWaiting={setSelectedDoctorEditWaiting}
              setSelectedTreatmentType={setSelectedTreatmentType}
              setNote={setNote}
            />
          ),
          appointment:
            props.patientAppointmentList?.find(
              (item: any) => item.id === item.appointment
            )?.estimate_service_at || item?.appointment,
        }));
      // console.log('newManageWaitingListItems: ', newManageWaitingListItems);
      setManageWaitingListItems(newManageWaitingListItems);
    }
  }, [
    isAppointed,
    isCancel,
    isOnHoldQ,
    selectedWaitingList?.items,
    showModWaitingList,
    selectedIdList,
    props.patientAppointmentList,
  ]);

  // ---------------------------------------------------------------
  // Tab ค้นหา,  filter ของ filterAppointmentList Table นัดหมายก่อนหน้า
  // ---------------------------------------------------------------
  useEffect(() => {
    if (props.appointmentList) {
      let appList = props.appointmentList;
      if (selectedDoctorPA) {
        appList = appList?.filter(
          (item: any) =>
            item?.display_info?.provider === selectedDoctorPA?.provider_id
        );
      }

      if (selectDivisionPA) {
        appList = appList?.filter(
          (item: any) => item.division === selectDivisionPA?.id
        );
      }

      if (waitingListNamePA) {
        // console.log("waitingListNamePA", waitingListNamePA)
        appList = appList?.filter((item: any) =>
          item.waiting_list_name?.includes(waitingListNamePA)
        );
      }
      setFilterAppointmentList(appList);
      // console.log("After filter ", appList)
    }
  }, [
    props.appointmentList,
    selectDivisionPA,
    selectedDoctorPA,
    waitingListNamePA,
  ]);

  // ---------------------------------------------------------------
  // กรองเฉพาะ ที่เลือกหมอ ในหน้า เลือกวันเวลาออกตรวจ สำหรับ CardPatientAppointmentUX
  // ---------------------------------------------------------------

  useEffect(() => {
    // console.log("useEffect props.blockList ", props.blockList);
    // console.log("useEffect selectedProvider?.provider_id ", selectedProvider?.provider_id);
    if (props.blockList != null) {
      // null and undefined
      let titleEdit: any[] = [];
      if (selectedProvider?.provider_id) {
        let filterProvider = props.blockList
          ?.map((item: any) => ({
            ...item,
            providers: (item.providers || [])?.filter(
              (provider: any) =>
                provider.doctor_provider_id === selectedProvider?.provider_id &&
                provider?.dsb?.doctor_dsb_status !== 2
            ),
          }))
          .filter((item: any) => item?.providers?.length !== 0);

        // console.log("useEffect filterProvider: ", filterProvider);
        titleEdit = filterProvider;
      } else {
        // console.log("useEffect mirror props.blockList: ", props.blockList);
        titleEdit = props.blockList;
      }

      titleEdit = titleEdit?.map((item: any) => {
        let length = item?.providers?.filter(
          (item: any) =>
            item?.dsb?.doctor_provider_type === "Doctor" &&
            item?.dsb?.doctor_dsb_status !== 2
        )?.length;
        return {
          ...item,
          title:
            length > 0 ? item.title + " (แพทย์ " + length + " คน)" : item.title,
        };
      });

      const holiday = (props.holiday?.items || []).flatMap((item: any) => {
        const [day, month, year] = item.date[0].split("/");
        return calendarYear.map((year: any) => {
          const date = `${year}-${month}-${day}`;
          return {
            start: new Date(`${date} 00:00`),
            end: new Date(`${date} 23:59`),
            title: item.detail,
            holiday: true,
          };
        });
      });

      // console.log('useEffect titleEdit: ', titleEdit);
      // console.log('useEffect holiday: ', holiday);
      titleEdit = [...holiday, ...titleEdit];
      // console.log('useEffect Summary titleEdit: ', titleEdit);

      setBlockFilterAppointments(titleEdit);
    }
  }, [props.blockList, selectedProvider?.provider_id, props.holiday]);

  // Ishealth-v3 port into CUDent
  useEffect(() => {
    const loading = props.loadingStatus?.[CARD_PATIENT_APPOINTMENT];
    if (loading) {
      setComplete("check");

      setTimeout(() => {
        setComplete("");
        props.setProp(`loadingStatus.${CARD_PATIENT_APPOINTMENT}`, false);
      }, 2000);
    }
  }, [props.loadingStatus]);

  const updateWaitingListItems = () => {
    console.log("updateWaitingListItems", selectedWaitingList);
    const newWaitingListItems = (selectedWaitingList?.items || [])
      .filter((item: any) => {
        let result = false;
        // console.log("item", item);
        switch (selectedStatus) {
          case "แสดงทั้งหมด":
            result = true;
            break;
          case "รอนัดหมาย":
            result = item?.status === "PENDING";
            break;
          case "ยืนยันนัดหมาย":
            result = item?.status === "APPOINTMENT";
            break;
          case "พักคิว":
            result = item?.status === "STALL";
            break;
          default:
            result = true;
        }
        return result;
      })
      .map((item: any, index: number) => ({
        index: index + 1,
        ...item,
        patient_name: item.patient_name + " (" + item.patient_hn + ")",
        checkbox: (
          <SelectItem
            index={index}
            item={item}
            selectedIdList={selectedIdList}
            setSelectedIdList={setSelectedIdList}
            setSelectedDoctorEditWaiting={setSelectedDoctorEditWaiting}
            setSelectedTreatmentType={setSelectedTreatmentType}
            setNote={setNote}
            setOpenModClinic={setOpenModClinic}
          />
        ),
      }))
      ?.filter((item: any) => {
        if (filterPatientName?.length > 0) {
          return item?.patient_name?.includes(filterPatientName);
        } else {
          return item;
        }
      });
    console.log(
      "updateWaitingListItems newWaitingListItems: ",
      newWaitingListItems
    );
    setWaitingListItems(newWaitingListItems);
  };

  const handleSetOrderNote = useCallback((e: SyntheticEvent, data: any) => {
    setOrderNote(data?.value?.toString());
  }, []);

  const editWaitingListItem = () => {
    const selectedWaitingList = (waitingListItems || [])?.filter((item: any) =>
      selectedIdList.includes(item.id)
    );

    props.onEvent({
      message: "SetScheduling",
      params: {
        action: "EditWaitingListItem",
        list: selectedWaitingList,
        selectedProvider: selectedDoctorEditWaiting,
        type: selectedTreatmentType,
        note: note,
        waitingList: selectedWaitingList,
      },
    });
    setSelectedDoctorEditWaiting("");
    setSelectedTreatmentType("");
    setNote("");
  };

  const statusOptions = useMemo(
    () => [
      {
        key: "ALL",
        text: "แสดงทั้งหมด",
        value: "แสดงทั้งหมด",
      },
      {
        key: "PENDING",
        text: "รอนัดหมาย",
        value: "รอนัดหมาย",
      },
      {
        key: "APPOINTMENT",
        text: "ยืนยันนัดหมาย",
        value: "ยืนยันนัดหมาย",
      },
      {
        key: "STALL",
        text: "พักคิว",
        value: "พักคิว",
      },
    ],
    []
  );

  const postponeOptions = useMemo(
    () => [
      {
        key: "คลินิกปิดทำการ",
        text: "คลินิกปิดทำการ",
        value: "คลินิกปิดทำการ",
      },
      { key: "ทันตแพทย์ลา", text: "ทันตแพทย์ลา", value: "ทันตแพทย์ลา" },
      {
        key: "มีปัญหาเรื่องค่าใช้จ่าย",
        text: "มีปัญหาเรื่องค่าใช้จ่าย",
        value: "มีปัญหาเรื่องค่าใช้จ่าย",
      },
      {
        key: "ส่งเตรียมช่องปากก่อน",
        text: "ส่งเตรียมช่องปากก่อน",
        value: "ส่งเตรียมช่องปากก่อน",
      },
      {
        key: "บันทึกข้อมูลผิด",
        text: "บันทึกข้อมูลผิด",
        value: "บันทึกข้อมูลผิด",
      },
      {
        key: "ทพ.ไม่ให้ลงนัด ให้มานั่งรอวันที่มีคลินิกเท่านั้น",
        text: "ทพ.ไม่ให้ลงนัด ให้มานั่งรอวันที่มีคลินิกเท่านั้น",
        value: "ทพ.ไม่ให้ลงนัด ให้มานั่งรอวันที่มีคลินิกเท่านั้น",
      },
      {
        key: "ส่งรักษาโรคประจำตัว",
        text: "ส่งรักษาโรคประจำตัว",
        value: "ส่งรักษาโรคประจำตัว",
      },
      {
        key: "คนไข้มีโรคประจำตัว/ป่วย",
        text: "คนไข้มีโรคประจำตัว/ป่วย",
        value: "คนไข้มีโรคประจำตัว/ป่วย",
      },
      {
        key: "สิทธิ์การรักษาไม่เรียบร้อย",
        text: "สิทธิ์การรักษาไม่เรียบร้อย",
        value: "สิทธิ์การรักษาไม่เรียบร้อย",
      },
      {
        key: "ผู้ป่วยติดธุระ ไม่สะดวกวันนัดเดิม",
        text: "ผู้ป่วยติดธุระ ไม่สะดวกวันนัดเดิม",
        value: "ผู้ป่วยติดธุระ ไม่สะดวกวันนัดเดิม",
      },
      {
        key: "ยาที่จ่ายหมดก่อนวันนัดเดิม",
        text: "ยาที่จ่ายหมดก่อนวันนัดเดิม",
        value: "ยาที่จ่ายหมดก่อนวันนัดเดิม",
      },
      {
        key: "ผู้ป่วยมาสายทันตแพทย์ให้นัดหมาย",
        text: "ผู้ป่วยมาสายทันตแพทย์ให้นัดหมาย",
        value: "ผู้ป่วยมาสายทันตแพทย์ให้นัดหมาย",
      },
      {
        key: "Lab/อุปกรณ์ไม่พร้อม",
        text: "Lab/อุปกรณ์ไม่พร้อม",
        value: "Lab/อุปกรณ์ไม่พร้อม",
      },
      {
        key: "เปลี่ยนแผนการรักษา",
        text: "เปลี่ยนแผนการรักษา",
        value: "เปลี่ยนแผนการรักษา",
      },
      {
        key: "สถานการณ์ Covid 19",
        text: "สถานการณ์ Covid 19",
        value: "สถานการณ์ Covid 19",
      },
    ],
    []
  );

  const cancelOptions = useMemo(
    () => [
      { key: "ทันตแพทย์ลา", text: "ทันตแพทย์ลา", value: "ทันตแพทย์ลา" },
      {
        key: "ผิดนัดหลายครั้ง ให้นัดทันตแพทย์ท่านอื่น",
        text: "ผิดนัดหลายครั้ง ให้นัดทันตแพทย์ท่านอื่น",
        value: "ผิดนัดหลายครั้ง ให้นัดทันตแพทย์ท่านอื่น",
      },
      {
        key: "ผู้ป่วยขอพักคิว",
        text: "ผู้ป่วยขอพักคิว",
        value: "ผู้ป่วยขอพักคิว",
      },
      {
        key: "ผู้ป่วนเสียชีวิต",
        text: "ผู้ป่วนเสียชีวิต",
        value: "ผู้ป่วนเสียชีวิต",
      },
      {
        key: "ติดต่อผู้ป่วยไม่ได้",
        text: "ติดต่อผู้ป่วยไม่ได้",
        value: "ติดต่อผู้ป่วยไม่ได้",
      },
      {
        key: "มีปัญหาเรื่องค่าใช้จ่าย",
        text: "มีปัญหาเรื่องค่าใช้จ่าย",
        value: "มีปัญหาเรื่องค่าใช้จ่าย",
      },
      {
        key: "รอคิวนานเกินไป",
        text: "รอคิวนานเกินไป",
        value: "รอคิวนานเกินไป",
      },
      {
        key: "ผู้ป่วยไม่มีอาการแล้ว",
        text: "ผู้ป่วยไม่มีอาการแล้ว",
        value: "ผู้ป่วยไม่มีอาการแล้ว",
      },
      {
        key: "ส่งเตรียมช่องปากก่อน",
        text: "ส่งเตรียมช่องปากก่อน",
        value: "ส่งเตรียมช่องปากก่อน",
      },
      {
        key: "ผู้ป่วยทำการักษาที่อื่น",
        text: "ผู้ป่วยทำการักษาที่อื่น",
        value: "ผู้ป่วยทำการักษาที่อื่น",
      },
      {
        key: "บันทึกข้อมูลผิด",
        text: "บันทึกข้อมูลผิด",
        value: "บันทึกข้อมูลผิด",
      },
      {
        key: "คนไข้มีโรคประจำตัว",
        text: "คนไข้มีโรคประจำตัว",
        value: "คนไข้มีโรคประจำตัว",
      },
      {
        key: "ทพ.ไม่ให้ลงนัด ให้มานั่งรอวันที่มีคลินิกเท่านั้น",
        text: "ทพ.ไม่ให้ลงนัด ให้มานั่งรอวันที่มีคลินิกเท่านั้น",
        value: "ทพ.ไม่ให้ลงนัด ให้มานั่งรอวันที่มีคลินิกเท่านั้น",
      },
      {
        key: "คนไข้มารักษาก่อนวันนัด",
        text: "คนไข้มารักษาก่อนวันนัด",
        value: "คนไข้มารักษาก่อนวันนัด",
      },
      {
        key: "เปลี่ยนแผนการรักษา",
        text: "เปลี่ยนแผนการรักษา",
        value: "เปลี่ยนแผนการรักษา",
      },
      {
        key: "ผู้ป่วยขอพักคิว",
        text: "ผู้ป่วยขอพักคิว",
        value: "ผู้ป่วยขอพักคิว",
      },
      {
        key: "รอคิวนานเกินไป",
        text: "รอคิวนานเกินไป",
        value: "รอคิวนานเกินไป",
      },
      {
        key: "ผู้ป่วยไม่มีอาการแล้ว",
        text: "ผู้ป่วยไม่มีอาการแล้ว",
        value: "ผู้ป่วยไม่มีอาการแล้ว",
      },
      {
        key: "ผู้ป่วยทำการรักษาที่อื่น",
        text: "ผู้ป่วยทำการรักษาที่อื่น",
        value: "ผู้ป่วยทำการรักษาที่อื่น",
      },
      {
        key: "สถานการณ์ Covid 19",
        text: "สถานการณ์ Covid 19",
        value: "สถานการณ์ Covid 19",
      },
      {
        key: "ผู้ป่วยโทรมายกเลิกนัด Recall Oper",
        text: "ผู้ป่วยโทรมายกเลิกนัด Recall Oper",
        value: "ผู้ป่วยโทรมายกเลิกนัด Recall Oper",
      },
      {
        key: "ผู้ป่วยโทรมายกเลิกนัด Recall endo",
        text: "ผู้ป่วยโทรมายกเลิกนัด Recall endo",
        value: "ผู้ป่วยโทรมายกเลิกนัด Recall endo",
      },
      {
        key: "ผู้ป่วยโทรมายกเลิกนัดตรวจ",
        text: "ผู้ป่วยโทรมายกเลิกนัดตรวจ",
        value: "ผู้ป่วยโทรมายกเลิกนัดตรวจ",
      },
      {
        key: "ผิดนัดหลายครั้ง ให้ตรวจใหม่",
        text: "ผิดนัดหลายครั้ง ให้ตรวจใหม่",
        value: "ผิดนัดหลายครั้ง ให้ตรวจใหม่",
      },
    ],
    []
  );

  const dataWaitingList = useMemo(() => {
    return (props.allWaitingList?.items || []).map(
      (item: any, index: number) => ({
        ...item,
        division_name: props?.divisionList?.filter(
          (acc: any) => acc.id === item.division
        )?.[0]?.name,
      })
    );
  }, [props.allWaitingList?.items]);

  const dataSummaryStatic = useMemo(() => {
    return (props.staticCanCelQueueList || []).map(
      (item: any, index: number) => ({
        ...item,
      })
    );
  }, [props.staticCanCelQueueList]);

  // Ishealth-v3 port into CUDent
  const preOrderList = useMemo(() => {
    return (props.preOrderList || []).map((item: any) => ({
      ...item,
      order_detail: (
        <div
          dangerouslySetInnerHTML={{
            __html: [
              "treatmentorder",
              "centrallaborder",
              "imagingorder",
            ].includes(item.type)
              ? item.summary_detail ||
                item.order_summary_detail ||
                item.order_name ||
                item.name
              : "",
          }}
        />
      ),
      specific_type_name:
        item.type === "treatmentorder"
          ? "Treatment"
          : item.type === "centrallaborder"
          ? "Central Lab"
          : item.type === "imagingorder"
          ? "Imaging"
          : item.type,
      del: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="red"
            icon="minus"
            onClick={(e) => {
              props.onEvent({
                message: "HandleActionPreOrderList",
                params: {
                  action: "delete",
                  id: item.id,
                },
              });
            }}
            size={"mini"}
          ></Button>
        </div>
      ),
      edit: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="blue"
            icon="edit"
            onClick={(e) => {
              setOpenModOrder({
                id: item.id,
                type:
                  item.type === "treatmentorder"
                    ? "TREATMENT"
                    : item.type === "centrallaborder"
                    ? "CENTRAL_LAB"
                    : item.type === "imagingorder"
                    ? "IMAGING"
                    : "",
              });
            }}
            size={"mini"}
          ></Button>
        </div>
      ),
    }));
  }, [props.preOrderList]);

  const getConfigAppointmentIcon = useCallback((item: any) => {
    // console.log("getConfigAppointmentIcon: ", item)
    const type =
      item.type_display === TYPE_DISPLAY.or
        ? "or"
        : item.waiting_list_name
        ? "waiting_list"
        : item.appointment_type === "PACKAGE"
        ? "package"
        : "appointment";

    const config = {
      or: {
        message: item.is_new_order_encounter
          ? APPOINTMENT_MSG.new_or
          : APPOINTMENT_MSG.or,
        color: "#949494",
        image: IMAGES.or_outline,
        status: [
          "REALIZED",
          "OPERATION",
          "COMPLETED",
          "HOLDING_ROOM",
          "OPERATING_ROOM",
          "PACU",
        ],
        type: "operatingdetail",
      },
      appointment: {
        message: APPOINTMENT_MSG.appointment,
        color: "#949494",
        image: IMAGES.appointment_outline,
        status: ["FINISHED"],
        type: "doctor",
      },
      waiting_list: {
        message: item.waiting_list_name,
        color: "#949494",
        image: IMAGES.waiting_list_outline,
        status: ["FINISHED"],
        type: "doctor",
      },
      package: {
        message: APPOINTMENT_MSG.package,
        color: "#949494",
        image: IMAGES.package_outline,
        status: ["FINISHED"],
        type: "doctor",
      },
    }[type];

    if (
      config.status.includes(item.related_detail) &&
      config.type === item.related_type
    ) {
      config.color = "#46A04A";
    }

    return config;
  }, []);

  const handleRemoveAppointment = useCallback(() => {
    if (props.selectedAppointment?.id === null) {
      const list = (props.appointmentList || []).filter(
        (item: any) => item.id !== null
      );

      setTab("detail");

      return props.setProp("appointmentList", [...list]);
    }

    console.log("handleOnCancelAppointment ");
    setOpenModMessage(true);
    setOptions(props.masterOptions?.orCancelNote || []);
    setQuestionDetail({
      type: "remove",
      title: "ยกเลิกนัดหมาย",
      subTitle: "",
      questionDetail: "โปรดระบุเหตุผลในการยกเลิก",
      titleColor: "red",
      userEditQuestion: " ",
      buttonLeftLabel: "ใช่",
      buttonRightLabel: "ไม่",
      buttonLeftColor: "green",
      buttonRightColor: "red",
      isUserEditQuesion: true,
      isRequiredQuestionDetail: true,
      multiple: false,
    });
  }, [props.selectedAppointment?.id, props.appointmentList]);

  const appointmentFilterList = useMemo(() => {
    const list: any[] = appointmentListForPatientAppointment(
      filterAppointmentList
    )?.filter((item: any) => item.status !== 5 && item.status !== 6);

    return list.map((item, index) => {
      const config = getConfigAppointmentIcon(item);

      return {
        ...item,
        icon: (
          <Popup
            popperModifiers={{
              preventOverflow: {
                boundariesElement: "offsetParent",
              },
            }}
            wide
            offset={10}
            trigger={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    width: "32px",
                    height: "32px",
                    backgroundColor: "white",
                    borderRadius: "500rem",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: config.color,
                      borderRadius: "500rem",
                      margin: "auto",
                      padding: "6px",
                    }}
                  >
                    <img
                      src={config.image}
                      alt={`icon-${index}.icon`}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            }
            content={config.message}
            position="bottom right"
          />
        ),
        estimated_time:
          item.order_note === "indefinitely postponed"
            ? "เลื่อนนัดหมายไม่ระบุวัน"
            : item.estimated_at
            ? item.estimated_at
            : props.selectedAppointment?.id === item?.id && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Button
                    icon="minus"
                    circular
                    color="red"
                    size="mini"
                    style={{
                      float: "right",
                      marginRight: "15px",
                      fontSize: "0.75rem",
                    }}
                    onClick={handleRemoveAppointment}
                  />
                </div>
              ),
      };
    });
  }, [filterAppointmentList, props.selectedAppointment?.id]);

  const reconfirmList = useMemo(() => {
    return props.reconfirmList?.map((item: any, index: number) => {
      const config = getConfigAppointmentIcon(item);
      let statusNote = "";
      try {
        statusNote = JSON.parse(item.status_note)?.join(", ");
      } catch (e) {
        statusNote = item.status_note;
      }
      return {
        ...item,
        status_note: statusNote,
        provider_name:
          item.provider_name && item.provider_name !== "undefined"
            ? item.provider_name
            : item.doctor_full_name || "",
        icon: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Popup
              popperModifiers={{
                preventOverflow: {
                  boundariesElement: "offsetParent",
                },
              }}
              wide
              offset={10}
              trigger={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "32px",
                      height: "32px",
                      backgroundColor: "white",
                      borderRadius: "500rem",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: config.color,
                        borderRadius: "500rem",
                        margin: "auto",
                        padding: "6px",
                      }}
                    >
                      <img
                        src={config.image}
                        alt={`icon-${index}.icon`}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              }
              content={config.message}
              position="bottom right"
            />
          </div>
        ),
      };
    });
  }, [props.reconfirmList]);

  const patientList = useMemo(() => {
    return (props.patientList || []).map((item: any) => ({
      ...item,
      tel_num: item.tel_num
        ? item.tel_num
        : item.phone_num
        ? item.phone_num
        : "",
    }));
  }, [props.patientList]);
  // #55748
  // const blockList = useMemo(() => {
  //   const block = selectedDivisionApp && props.selectedDivision?.id !== selectedDivisionApp && props.selectedAppointment?.id ? props.searchBlockList : props.blockList
  //   return blockListForPatientAppointment(block, selectedProvider);
  // }, [props.blockList, props.selectedDivision?.id, props.searchBlockList, props.selectedAppointment?.id, selectedProvider, selectedDivisionApp]);

  const mapProviderOptions = (items: any) => {
    // console.log("mapProviderOptions items", items)
    return items.map((item: any) => ({
      key: item.provider_id,
      value:
        (item?.pre_name || "") +
        " " +
        item.first_name +
        " " +
        item.last_name +
        " [" +
        item.code +
        "]",
      text:
        (item?.pre_name || "") +
        " " +
        item.first_name +
        " " +
        item.last_name +
        " [" +
        item.code +
        "]",
    }));
  };

  const mapDivisionOptions = (items: any) => {
    // console.log("mapDivisionOptions items: ", items);
    return items.map((item: any) => ({
      key: item.code,
      value: item.name,
      text: item.name,
    }));
  };

  const mapWaitingListOptions = (items: any) => {
    return items.map((item: any) => ({
      key: item.id,
      value: item.name,
      text: item.name,
    }));
  };

  // const mapPatientOptions = (items: any) => {
  //   // console.log("mapPatientOptions items", items)
  //   return items.map((item: any) => ({
  //     key: item.id,
  //     value: item.full_name,
  //     text: item.full_name,
  //   }));
  // };

  const handleOnSelectPatient = (id: any, hn: any) => {
    console.log("handleonSelectPatient id: ", id, hn);
    if (id !== null) {
      setPatientSearchText(hn);
      setSelectedPatientId(id);
      setSelectedManagePatient(null);
      setNote("");
      props.setProp("patientList", []);
      props.setProp("appointmentList", []);

      props.onEvent({
        message: "SearchPatient",
        params: { patientSearchText: hn },
      });
    }
  };

  const handleOnCancelAppointment = (e: any) => {
    console.log("handleOnCancelAppointment ");
    setOpenModMessage(true);
    setOptions(cancelOptions);
    setQuestionDetail({
      type: "cancel",
      title: "ต้องการยกเลิกนัดหมายจากแพทย์ใช่หรือไม่",
      subTitle: "",
      questionDetail: "ระบุเหตุผลในการยกเลิก",
      titleColor: "red",
      userEditQuestion: "ระบุชื่อผู้บันทึกข้อมูล",
      buttonLeftLabel: "ใช่",
      buttonRightLabel: "ไม่",
      buttonLeftColor: "green",
      buttonRightColor: "red",
      isUserEditQuesion: true,
      isRequiredQuestionDetail: true,
    });
  };

  const handleOnCancelAppointmentByPatient = (e: any) => {
    console.log("handleOnCancelAppointment ");
    setOpenModMessage(true);
    setOptions(cancelOptions);
    setQuestionDetail({
      type: "cancelbypatient",
      title: "ต้องการยกเลิกนัดหมายจากจากผู้ป่วยใช่หรือไม่",
      subTitle: "",
      questionDetail: "ระบุเหตุผลในการยกเลิก",
      titleColor: "red",
      userEditQuestion: "ระบุชื่อผู้บันทึกข้อมูล",
      buttonLeftLabel: "ใช่",
      buttonRightLabel: "ไม่",
      buttonLeftColor: "green",
      buttonRightColor: "red",
      isUserEditQuesion: true,
      isRequiredQuestionDetail: true,
    });
  };

  const handleOnPostponeAppointment = (e: any) => {
    console.log("handleOnPostponeAppointment ");
    setOpenModMessage(true);
    setOptions(postponeOptions);
    setQuestionDetail({
      type: "postpone",
      title: "ต้องการเลื่อนนัดผู้ป่วยใช่หรือไม่",
      subTitle:
        "กรณีที่เลื่อนนัดผู้ป่วยเจ้าหน้าที่จะต้องทำการติดนามนัดหมายผู้ป่วยใหม่",
      questionDetail: "ระบุเหตุผลเลื่อนนัดผู้ป่วย",
      isRequiredQuestionDetail: true,
      titleColor: "orange",
      userEditQuestion: "ระบุชื่อผู้บันทึกข้อมูล",
      isUserEditQuesion: true,
      buttonLeftLabel: "ใช่",
      buttonRightLabel: "ไม่",
      buttonLeftColor: "green",
      buttonRightColor: "red",
    });
    //
  };

  const handleOnPostponeOperatingAppointment = (callback: any) => {
    console.log("handleOnPostponeOperatingAppointment ");
    setOpenModMessage(true);
    setOptions(postponeOptions);
    setQuestionDetail({
      type: "operatingPostpone",
      title: "ต้องการเลื่อนนัดผู้ป่วยใช่หรือไม่",
      subTitle:
        "กรณีที่เลื่อนนัดผู้ป่วยเจ้าหน้าที่จะต้องทำการติดนามนัดหมายผู้ป่วยใหม่",
      questionDetail: "ระบุเหตุผลเลื่อนนัดผู้ป่วย",
      isRequiredQuestionDetail: true,
      titleColor: "orange",
      userEditQuestion: "ระบุชื่อผู้บันทึกข้อมูล",
      isUserEditQuesion: true,
      buttonLeftLabel: "ใช่",
      buttonRightLabel: "ไม่",
      buttonLeftColor: "green",
      buttonRightColor: "red",
      callback,
    });
    //
  };

  const handleAddition = (e: any, { value }: any) => {
    setOptions([{ text: value, value }, ...options]);
  };

  const handleChange = (e: any, { value }: any) => setCurrentValue(value);

  const handleOpenModOrder = (e: any, v: any) => {
    setOpenModOrder({ type: v.name });
  };

  const handleCloseModOrder = () => {
    if (openModOrder?.type === "IMAGING") {
      props.onEvent({
        message: "ImagingRequestOrder",
        params: {
          action: "clear_data",
        },
      });
    }

    setOpenModOrder(null);

    props.setProp("errorMessage", {
      ...props.errorMessage,
      ["OrderCentralLab"]: null,
    });
  };

  const handleSaveAppointment = (e: any) => {
    console.log("handleSaveAppointment CPA estimatedAt: ", estimatedAt);
    props.onEvent({
      message: "SetScheduling",
      params: {
        action: "SaveAppointment",
        estimatedAt: estimatedAt,
        estimatedDuration: estimatedDuration,
        orderNote: orderNote,
        full: props.selectedAppointment?.dsb_full,
        card: CARD_PATIENT_APPOINTMENT,
      },
    });
  };

  const handleConfirmedButton = () => {
    if (questionDetail?.type === "postpone") {
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "PostponeAppointment",
          reason: currentValue,
          waitingList: selectedWaitingList,
        },
      });
    } else if (questionDetail?.type === "cancel") {
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "CancelAppointment",
          reason: currentValue,
          waitingList: selectedWaitingList,
        },
      });
    } else if (questionDetail?.type === "cancelbypatient") {
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "CancelAppointmentByPatient",
          reason: currentValue,
          waitingList: selectedWaitingList,
        },
      });
    } else if (questionDetail?.type === "remove") {
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "CancelAppointmentByPatient",
          reason: currentValue,
          waitingList: selectedWaitingList,
        },
      });
    } else if (questionDetail?.type === "operatingPostpone") {
      props.runSequence({
        sequence: "OperatingDateTime",
        action: "operatingPostpone",
        reason: currentValue,
        // waitingList: selectedWaitingList,
        callback: questionDetail?.callback,
      });
    }
    setCurrentValue([]);
    setOpenModMessage(false);
    setQuestionDetail(null);
  };

  const handleCancelButton = () => {
    setOpenModMessage(false);
    setOptions([]);
    setCurrentValue([]);
    setQuestionDetail(null);
    props.setProp("userTokenize", null);
  };

  // CardReconfirmed
  // const allStatusOptions = [
  //   { key: "รอนัดหมาย", text: "รอนัดหมาย", value: "รอนัดหมาย" },
  //   { key: "ยืนยันนัดหมายแล้ว", text: "ยืนยันนัดหมายแล้ว", value: "ยืนยันนัดหมายแล้ว" },
  //   { key: "คืนคิว", text: "คืนคิว", value: "คืนคิว" },
  //   { key: "พักคิว", text: "พักคิว", value: "พักคิว" },
  //   { key: "ยกเลิกวางแผนการรักษา", text: "ยกเลิกวางแผนการรักษา", value: "ยกเลิกวางแผนการรักษา" },
  //   { key: "ยกเลิกนัดหมาย", text: "ยกเลิกนัดหมาย", value: "ยกเลิกนัดหมาย" },
  //   { key: "เลื่อนนัดหมาย", text: "เลื่อนนัดหมาย", value: "เลื่อนนัดหมาย" },
  // ];

  // CardReconfirm
  const handleChangeAppointmentType = (e: any, { value }: any) => {
    setSelectedAppointmentType(value);
  };

  const handleChangeSelectWaitingListRC = (e: any, { value }: any) => {
    setSelectWaitingListRC(value);
  };

  const handleChangeSelectStatusRC = (e: any, { value }: any) => {
    setSelectStatusRC(value);
  };

  const handleOnChangeStartDateRC = (date: string) => {
    setStartDateRC(date);
  };

  const handleOnChangeEndDateRC = (date: string) => {
    setEndDateRC(date);
  };

  // CardReschedule
  const handleChangeSelectWaitingListRS = (e: any, { value }: any) => {
    setSelectWaitingListRS(value);
  };

  const handleChangeSelectStatusRS = (e: any, { value }: any) => {
    setSelectStatusRS(value);
  };

  const handleOnChangeStartDateRS = (date: string) => {
    setStartDateRS(date);
  };

  const handleOnChangeEndDateRS = (date: string) => {
    setEndDateRS(date);
  };

  const handlePrintAppointment = (doctorNote: any) => {
    // console.log('handlePrintAppointment doctorNote: ', doctorNote);
    // console.log('handlePrintAppointment selectedWaitingListItem: ', selectedWaitingListItem);
    // console.log('handlePrintAppointment orderNote: ', orderNote);
    props.onEvent({
      message: "PrintScheduling",
      params: {
        form: "FormAppointmentDetail",
        data: {
          ...props.selectedAppointment,
          doctor_note: orderNote?.match(/^.*[^\n\r]/gm) || "",
          description: selectedWaitingListItem?.note || "",
        },
      },
    });
  };

  const handleOnSelectNote = (noteList: string[]) => {
    const note: (string | number)[] = orderNote
      ? [orderNote, ...noteList]
      : noteList;
    setOrderNote(note.join("\n"));
    setOpenModDoctorNote(false);
  };

  const changeDate = (key: string) => (value: any) => {
    console.log(value);
    const newDateString = (parseDate(value, true) as moment.Moment).format(
      DATE_FORMAT
    );
    if (key === "startDateModWL") {
      setStartDateModWL(newDateString);
    } else if (key === "endDateModWL") {
      setEndDateModWL(newDateString);
    } else if (key === "startDateModST") {
      setStartDateModST(newDateString);
    } else if (key === "endDateModST") {
      setEndDateModST(newDateString);
    }
  };

  const dataSummaryWaitingList = useMemo(() => {
    let prenameOptions = props.masterOptions?.prenameTh;
    const waitingList = (props.waitingQueueList || []).map(
      (item: any, index: number) => ({
        ...item,
        name: `${
          prenameOptions?.find(
            (options: any) => options.value === item.patient__pre_name
          )?.text || ""
        } ${item.patient__first_name}  ${item.patient__last_name}`,
        created: formatADtoBEString(moment(item.created).format(DATE_FORMAT)),
        appointment__estimated_at: formatADtoBEString(
          moment(item.appointment__estimated_at).format(DATE_FORMAT)
        ),
      })
    );
    setWaitingQueueList(waitingList);
  }, [props.waitingQueueList]);

  const sortDateWaitngList = () => {
    // console.log("waitingQueueList: ", waitingQueueList);
    const dateWaitngList = waitingQueueList?.sort((a: any, b: any) =>
      isAscendingCreated
        ? a.created < b.created
          ? 1
          : -1
        : a.created < b.created
        ? -1
        : 1
    );
    // console.log("dateWaitngList: ", dateWaitngList);
    setIsAscendingCreated(!isAscendingCreated);
    setWaitingQueueList([...dateWaitngList]);
  };

  const sortDateAppointment = () => {
    // console.log("waitingQueueList: ", waitingQueueList);
    const dateAppointment = waitingQueueList?.sort((a: any, b: any) =>
      isAscendingAppointed
        ? a.appointment__estimated_at < b.appointment__estimated_at
          ? 1
          : -1
        : a.appointment__estimated_at < b.appointment__estimated_at
        ? -1
        : 1
    );
    // console.log("dateAppointment: ", dateAppointment);
    setIsAscendingAppointed(!isAscendingAppointed);
    setWaitingQueueList([...dateAppointment]);
  };

  const sortWaitingTime = () => {
    // console.log("waitingQueueList: ", waitingQueueList);
    const waitingTime = waitingQueueList?.sort((a: any, b: any) =>
      isAscendingWaitingTime
        ? a.waiting_time < b.waiting_time
          ? 1
          : -1
        : a.waiting_time < b.waiting_time
        ? -1
        : 1
    );
    // console.log("waitingTime: ", waitingTime);
    setIsAscendingWaitingTime(!isAscendingWaitingTime);
    setWaitingQueueList([...waitingTime]);
  };

  //  //Export .CSV ModWaitingList
  const headersModWL = [
    { label: "HN", key: "patient__hn" },
    { label: "ชื่อ - สกุล", key: "name" },
    { label: "วันที่เข้า Waiting List", key: "created" },
    { label: "วันที่นัดหมาย", key: "appointment__estimated_at" },
    { label: "ระยะเวลารอคิว (วัน)", key: "waiting_time" },
  ];

  // //Export .CSV ModStatic
  const headersModST = [
    { label: "จำนวนผู้ป่วยที่อยู่ใน Waiting List", key: "all" },
    { label: "จำนวนผู้ป่วยที่ยกเลิกคิว", key: "cancel" },
    { label: "% การยกเลิกคิว", key: "cancel_percent" },
  ];

  const handleConfirmDuplicate = () => {
    props.onEvent({
      message: "SetScheduling",
      params: {
        action: "SaveAppointment",
        estimatedAt: estimatedAt,
        estimatedDuration: estimatedDuration,
        orderNote: orderNote,
        full: props.selectedAppointment?.dsb_full,
        repetition_note: duplicateReason,
      },
    });
  };
  const handleCancelDuplicate = () => {
    props.setProp("duplicateAppointment", null);
  };

  // console.log("CardPatientAppointment : ", props);
  // console.log('CardPatientAppointment : props.divisionList: ', props.divisionList);
  const handleSelectAppointment = async (rowInfo: any) => {
    if (!!props.PackageDateTimeSequence?.sequenceIndex) {
      await props.setProp("PackageDateTimeSequence", null);
    }

    props.onEvent({
      message: "SelectAppointment",
      params: rowInfo?.original,
      card: CARD_PATIENT_APPOINTMENT,
    });
    setEstimatedAt("");
    setEstimatedDuration("");
  };

  const handleSelectEncounter = (encounter: any) => {
    setOpenModSelectEncounter(null);
    props.onEvent({
      message: "CreatePatientAppointment",
      params: {
        WaitingList: selectedWaitingList,
        card: CARD_PATIENT_APPOINTMENT,
        is_or_type: true,
        encounter_id: encounter,
      },
    });
  };

  const handleCreateSuccess = () => {
    goToTabSelectDateTime.current = true;
    setTab("selectdatetimeoperation");
  };

  const handleAddAppointmentPackage = () => {
    const list: any[] = [...(props.appointmentList || [])];

    const findAppPackage = list.find(
      (item) => item.id === null && item.appointment_type === "PACKAGE"
    );

    if (!!findAppPackage) {
      return handleSelectAppointment({ original: findAppPackage });
    }

    list.push({
      id: null,
      appointment_type: "PACKAGE",
      patient_name: props.selectedPatient?.full_name,
      division_name: props.selectedDivision?.name,
    });

    props.setProp("appointmentList", list);
  };

  // console.log("CardPatientAppointment", props.selectedAppointment, props.selectedBlock);
  // console.log('CardPatientAppointment selectedDoctorEditWaiting: ', props, selectedWaitingList, selectedDoctorAddWaiting, selectedDoctorEditWaiting);
  // console.log("waitingQueueList", waitingQueueList);
  console.log("CardPatientAppointment props: ", props);
  return (
    <div>
      <ModConfirmAuthen
        {...questionDetail}
        options={options}
        onEvent={props.onEvent}
        setProp={props.setProp}
        userTokenize={props.userTokenize}
        onButtonLeftClick={handleConfirmedButton}
        onButtonRightClick={handleCancelButton}
        open={openModMessage}
        onClose={handleCancelButton}
        isButtonBasic={true}
        handleAddition={handleAddition}
        handleChange={handleChange}
        currentValue={currentValue}
        isErrMsg={true}
        multiple={questionDetail?.multiple}
      />

      <CardPatientAppointmentUX
        setProp={props.setProp}
        onEvent={props.onEvent}
        mode={mode}
        setMode={setMode}
        tab={tab}
        // tab="selectpackage"
        setTab={setTab}
        calendarDisabled={!props.selectedAppointment?.id}
        detailDisabled={
          !props.selectedAppointment?.id ||
          (props.selectedAppointment?.type_display !== TYPE_DISPLAY.or &&
            props.selectedAppointment?.id &&
            !props.selectedAppointment?.estimated_at)
        }
        // detailDisabled={!props.selectedAppointment?.id || !props.selectedBlock}
        disabledPostponeBtn={!!props.config?.disabledPostpone} // CONFIG.DISABLED_POSTPONE}
        disabledWaitingListBtn={!!props.config?.disabledWaitingList} // CONFIG.DISABLED_WAITINGLIST}
        disabledSelectDateTime={!props.ORRequestSequence?.id}
        disabledSelectDateTimePackage={
          !props.PackageDateTimeSequence?.selectedPackageItems?.length ||
          !!props.selectedAppointment?.date
        }
        disabledOtherOrder={!props.ORRequestSequence?.id}
        appointmentHeaders={
          mode === "search"
            ? "ชื่อ-สกุล,,หน่วยงาน-แพทย์นัดหมาย,วันเวลานัดหมาย"
            : ""
        }
        appointmentKeys={
          mode === "search" ? "patient_name,icon,detail,estimated_time" : ""
        }
        appointmentWidths={mode === "search" ? "150,50" : ""}
        otherOrderAppointmentCard={
          tab === "otherorderappointment" && (
            <CardOtherOrderAppointment
              controller={props.controller}
              onEvent={props.onEvent}
              setProp={props.setProp}
              config={props.config}
              runSequence={props.runSequence}
              masterOptions={props.masterOptions}
              hidePrintAppointmentDoc={
                props.selectedAppointment?.type_display === TYPE_DISPLAY.or
              }
              // drugOperatingOrderId={props.preOrderList?.find((item: any) => item.type === "drugoperatingorder")?.id}
              setTab={setTab}
              setModOrder={setModOrder}
              ORRequestSequence={props.ORRequestSequence}
              OrderTreatmentSequence={props.OrderTreatmentSequence}
              treatmentOrderList={props.treatmentOrderList}
              userLabPermission={props.userLabPermission}
              searchedItemList={props.searchedItemList}
              successMessage={props.successMessage}
              errorMessage={props.errorMessage}
              procedureItems={props.procedureItems}
              secondaryItems={props.secondaryItems}
              treatmentTemplateList={props.treatmentTemplateList}
              selectedAppointment={props.selectedAppointment}
              // central lab
              OrderCentralLabSequence={props.OrderCentralLabSequence}
              selectedEncounter={props.selectedEncounter}
              selectedEmr={props.selectedEmr}
              selectedProgressCycle={props.selectedProgressCycle}
              doctorLabOrderList={props.doctorLabOrderList}
              // imaging
              imagingList={props.imagingList}
              orderImagingList={props.orderImagingList}
              loadingStatus={props.loadingStatus}
              buttonLoadCheck={props.buttonLoadCheck}
              selectedOperatingOption={props.selectedOperatingOption}
              //CardDrugOrder
              selectedDrugItemDetail={props.selectedDrugItemDetail}
              selectedDrugItemDetailOptions={
                props.selectedDrugItemDetailOptions
              }
              drugSearchText={props.drugSearchText}
              drugSearchResult={props.drugSearchResult}
              DrugSelectSequence={props.DrugSelectSequence}
              selectedDrug={props.selectedDrug}
              drugOrderList={props.drugOrderList}
              drugOrder={props.drugOrder}
              renderDrugOrderItems={props.renderDrugOrderItems}
              drugDose={props.drugDose}
              drugQty={props.drugQty}
              drugDuration={props.drugDuration}
              drugDescription={props.drugDescription}
              drugPrn={props.drugPrn}
              // modal doctor certificate check
              modDoctorCertificate={props.modDoctorCertificate}
              modDrugLabInteraction={props.modDrugLabInteraction}
              modDrugDisease={props.modDrugDisease}
              modDrugInteraction={props.modDrugInteraction}
              currentDoctor={props.currentDoctor}
              // modal drug order template
              DrugOrderTemplateSequence={props.DrugOrderTemplateSequence}
              modReMedication={props.modReMedication}
              modConfirmDeleteDrug={props.modConfirmDeleteDrug}
              drugOrderItemEstimate={props.drugOrderItemEstimate}
              drugPermission={props.drugPermission}
              modSolventSelectionConfig={props.modSolventSelectionConfig}
              selectedVerbalDrugOrderWorking={
                props.selectedVerbalDrugOrderWorking
              }
              preOrderList={props.preOrderList}
              defaultModOrder={modOrder}
              defaultOrderBy={true}
              //
              availableOptions={props.availableSlots}
            />
          )
        }
        selectdatetimeopertionCard={
          tab === "selectdatetimeoperation" && (
            <>
              <CardSelectedDateTimeOperation
                runSequence={props.runSequence}
                masterOptions={props.masterOptions}
                operatingLocationRoom={props.operatingLocationRoom}
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                setProp={props.setProp}
                // data
                preOrderList={props.preOrderList}
                OperatingDateTimeSequence={props.OperatingDateTimeSequence}
                onPostpone={handleOnPostponeOperatingAppointment}
                selectedOperatingDetail={props.selectedOperatingDetail}
                selectedOrOrder={props.selectedOrOrder}
                selectedDoctor={props.selectedDoctor}
                selectedAnesthesiologist={props.selectedAnesthesiologist}
                selectedOperatingRoom={props.selectedOperatingRoom}
                operatingBlock={props.operatingBlock}
                selectOperatingDSBChange={props.selectOperatingDSBChange}
                estimateTimeText={props.estimateTimeText}
                selectedAppointment={props.selectedAppointment}
              />
            </>
          )
        }
        PopupElement={
          <Popup
            open={openPopup}
            onClose={() => setOpenPopup(false)}
            position="bottom right"
            content={
              <div>
                <Button
                  content={APPOINTMENT_MSG.appointment}
                  color="orange"
                  size="small"
                  fluid
                  style={{ margin: "0.25rem 0" }}
                  onClick={() => {
                    setOpenPopup(false);
                    props.onEvent({
                      message: "CreatePatientAppointment",
                      params: {
                        WaitingList: selectedWaitingList,
                        card: CARD_PATIENT_APPOINTMENT,
                      },
                    });
                  }}
                />
                {!!props.django?.user?.role_types?.find((role: string) =>
                  ["DOCTOR", "NURSE", "REGISTERED_NURSE"].includes(role)
                ) && (
                  <>
                    <Button
                      content={APPOINTMENT_MSG.new_or}
                      color="teal"
                      size="small"
                      fluid
                      style={{ margin: "0.25rem 0" }}
                      onClick={() => {
                        // setOpenModSelectEncounter({ open: true, isNewEncounter: true });
                        setOpenPopup(false);
                        handleSelectEncounter(undefined);
                      }}
                    />
                    <Button
                      content={APPOINTMENT_MSG.or}
                      color="blue"
                      size="small"
                      fluid
                      style={{ margin: "0.25rem 0" }}
                      onClick={() => {
                        setOpenModSelectEncounter({
                          open: true,
                          isNewEncounter: false,
                        });
                        setOpenPopup(false);
                      }}
                    />
                    <Button
                      content={APPOINTMENT_MSG.package}
                      color="purple"
                      size="small"
                      fluid
                      style={{ margin: "0.25rem 0" }}
                      onClick={() => {
                        handleAddAppointmentPackage();
                        setOpenPopup(false);
                      }}
                    />
                  </>
                )}
              </div>
            }
            on="click"
            pinned
            trigger={
              <Button
                content="เพิ่มนัดหมาย"
                color="green"
                size="small"
                disabled={!props.selectedPatient}
                onClick={() => setOpenPopup(true)}
              />
            }
          />
        }
        // Left panel --------------------------------------------------------------------------
        // Search Patient
        doctorSearch={
          <SearchBoxDropdown
            type="Provider"
            id="5"
            style={{ width: "100%" }}
            fluid={true}
            label=""
            // disabled={selectedManagePatient !== null}
            toDisplay={(item: any) =>
              `${item.pre_name || ""} ${item.first_name} ${item.last_name} [${
                item.code
              }]`
            }
            onEvent={props.onEvent}
            // searchedItemList={props.searchedItemList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={selectedDoctorPA}
            setSelectedItem={(value: any, key: any, obj: any) => {
              if (value === null && key === null) {
                setSelectedDoctorPA(null);
                return;
              }
              let doctor = props.searchedItemListWithKey?.Provider_5?.find(
                (item: any) => item.provider_id === key
              );
              setSelectedDoctorPA(doctor);
            }}
            useWithKey={true}
            mapOptions={mapProviderOptions}
          />
        }
        divisionSearch={
          <SearchBoxDropdown
            type="Division"
            id="3"
            style={{ width: "100%" }}
            fluid={true}
            label=""
            // disabled={selectedManagePatient !== null}
            toDisplay={(item: any) => `${item.name}`}
            onEvent={props.onEvent}
            // searchedItemList={props.searchedItemList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={selectDivisionPA}
            setSelectedItem={(value: any, key: any, obj: any) => {
              if (value === null && key === null) {
                setSelectDivisionPA(null);
                return;
              }
              let division = props.searchedItemListWithKey?.Division_3?.find(
                (item: any) => item.code === key
              );
              setSelectDivisionPA(division);
            }}
            useWithKey={true}
            mapOptions={mapDivisionOptions}
          />
        }
        waitingListFilter={waitingListNamePA}
        onChangeWaitingListFilter={(e: any, { value }: any) => {
          setWaitingListNamePA(value);
        }}
        patientSearch={
          <PatientSearchBoxCU
            ref={hnSearchRefPA}
            controller={props.proxyController}
            inputChangeAutoSearch={false}
            onSelectPatient={handleOnSelectPatient}
            onEnter={handleOnSelectPatient}
            systemHn8Digit={true}
          />
        }
        patientList={patientList}
        onPatientSearch={() =>
          props.onEvent({
            message: "SearchPatient",
            params: { patientSearchText },
          })
        }
        patientRowProps={(
          state: any,
          rowInfo: any,
          column: any,
          instance: any
        ) => ({
          style: {
            backgroundColor:
              props.selectedPatient &&
              rowInfo?.original?.id === props.selectedPatient?.id
                ? "#a5dae6"
                : "#d6ecf3",
            fontWeight:
              props.selectedPatient &&
              rowInfo?.original?.id === props.selectedPatient?.id
                ? "bold"
                : "normal",
            cursor: "pointer",
          },
          // ใส่ blueSelectedRow เพื่อกัน css hover chain ใน ReactTAble.css
          className:
            props.selectedPatient &&
            rowInfo?.original?.id === props.selectedPatient?.id
              ? "blueSelectedRow"
              : "",
          onClick: () => {
            // console.log(" select Patient")
            props.onEvent({
              message: "SelectPatient",
              params: rowInfo?.original,
              card: CARD_PATIENT_APPOINTMENT,
            });
          },
        })}
        // #55840
        disabledCreatePatientAppointment={!props.selectedPatient}
        clearAppointment={(e: any) => {
          hnSearchRefPA.current?.clear();
          setPatientSearchText("");
          props.onEvent({ message: "ClearAppointment", params: {} });
        }}
        appointmentFilterList={appointmentFilterList}
        appointmentList={appointmentListForPatientAppointment(
          props.appointmentList
        )}
        selectedAppointment={props.selectedAppointment}
        isOR={props.selectedAppointment?.type_display === TYPE_DISPLAY.or}
        isPackage={props.selectedAppointment?.appointment_type === "PACKAGE"}
        createPatientAppointment={() =>
          props.onEvent({
            message: "CreatePatientAppointment",
            params: {
              WaitingList: selectedWaitingList,
              card: CARD_PATIENT_APPOINTMENT,
            },
          })
        }
        hideAppointmentTable={false && props.selectedPatient?.id === null}
        appointmentRowProps={(
          state: any,
          rowInfo: any,
          column: any,
          instance: any
        ) => ({
          style: {
            backgroundColor:
              props.selectedAppointment &&
              rowInfo?.original?.id === props.selectedAppointment?.id
                ? "#cccccc"
                : "#d6ecf3",
          },
          onClick: () => handleSelectAppointment(rowInfo),
        })}
        // OR Request
        CardORRequest={
          tab === "or_request" && (
            <CardORRequest
              // callback
              onEvent={props.onEvent}
              setProp={props.setProp}
              runSequence={props.runSequence}
              setTab={setTab}
              setModOrder={setModOrder}
              // controller
              proxyController={props.proxyController}
              // data
              ORRequestSequence={props.ORRequestSequence}
              searchedItemListWithKey={props.searchedItemListWithKey}
              masterData={props.masterData}
              patientAppointmentId={props.selectedAppointment?.id}
              operatingOrderId={
                props.preOrderList?.find(
                  (item: any) => item.type === "operatingorder"
                )?.id || null
              }
              encounterId={props.selectedAppointment?.order_encounter}
              isNewEncounter={props.selectedAppointment?.is_new_order_encounter}
              patientId={props.selectedAppointment?.patient}
              errorMessage={props.errorMessage}
              loadingStatus={props.loadingStatus}
              buttonLoadCheck={props.buttonLoadCheck}
              userTokenize={props.userTokenize}
              divisionId={props.divisionId}
              cardStyle={{ height: "" }}
              onCreateSuccess={handleCreateSuccess}
              // options
              masterOptions={props.masterOptions}
              // config
              isCancel={true}
              // isConfirm={true}
              // isCancelConfirm={true}
            />
          )
        }
        // Package
        selectpackageCard={
          tab === "selectpackage" && (
            <CardSelectPackage
              onEvent={props.onEvent}
              setProp={props.setProp}
              // data
              selectedPatient={props.selectedPatient}
              // controller
              proxyController={props.proxyController}
              // seq
              runSequence={props.runSequence}
              PackagePurchaseSequence={props.PackagePurchaseSequence}
              PackageDateTimeSequence={props.PackageDateTimeSequence}
              // CommonInterface
              selectedDivision={props.selectedDivision}
              userTokenize={props.userTokenize}
              masterOptions={props.masterOptions}
              selectedAppointment={props.selectedAppointment}
              buttonLoadCheck={props.buttonLoadCheck}
              errorMessage={props.errorMessage}
              searchedItemListWithKey={props.searchedItemListWithKey}
            />
          )
        }
        selectdatetimepackageCard={
          tab === "selectdatetimepackage" && (
            <CardSelectDateTimePackage
              onEvent={props.onEvent}
              setProp={props.setProp}
              // data
              selectedPatient={
                props.selectedPatient || {
                  full_name: "นาย โชคชัย งามแท้",
                  hn: "65008965",
                  birthdate: "01/12/2530",
                  phone_num: "065-874-8368",
                }
              }
              // controller
              proxyController={props.proxyController}
              // seq
              runSequence={props.runSequence}
              PackagePurchaseSequence={props.PackagePurchaseSequence}
              PackageDateTimeSequence={props.PackageDateTimeSequence}
              // CommonInterface
              selectedDivision={props.selectedDivision}
              buttonLoadCheck={props.buttonLoadCheck}
              errorMessage={props.errorMessage}
            />
          )
        }
        // Reschedule
        reschedule={
          mode === "reschedule" && (
            <CardRescheduleUX
              onClickSearch={() => {
                console.log("onSearch");
                console.log(
                  "onSearch hnSearchRefRS?.current?.value: ",
                  hnSearchRefRS?.current?.value()
                );
                console.log("onSearch selectedDoctorRS", selectedDoctorRS);
                console.log("onSearch selectDivisionRS", selectDivisionRS);
                console.log(
                  "onSearch selectWaitingListRS",
                  selectWaitingListRS
                );
                console.log("onSearch selectStatusRS", selectStatusRS);
                console.log("onSearch startDateRS", startDateRS);
                console.log("onSearch endDateRS", endDateRS);

                // query parameters:
                // hn: เลข hn ของผู้ป่วย
                // provider_id: provider id ของ หมอ
                // division_id : id ของ หน่วย/แผนก
                // waiting_list: <string>
                // start_date: วันที่เริ่มต้น ในรูปแบบ format DD/MM/YYYY BE
                // end_date: วันที่สิ้นสุด ในรูปแบบ format DD/MM/YYYY BE
                // type: ชนิดของนัดหมาย (id ของ clinicalTermset ของ ประเภทนัดหมาย)

                props.onEvent({
                  message: "SetScheduling",
                  params: {
                    action: "GetRescheduleAppointment",
                    hn: hnSearchRefRS?.current?.value(),
                    provider_id: selectedDoctorRS?.provider_id,
                    division_id: selectDivisionRS?.id,
                    waiting_list: selectWaitingListRS,
                    start_date: startDateRS,
                    end_date: endDateRS,
                    type: selectStatusRS,
                  },
                });
              }}
              onChangeStartDate={handleOnChangeStartDateRS}
              onChangeEndDate={handleOnChangeEndDateRS}
              startDate={startDateRS}
              endDate={endDateRS}
              searchHN={
                <PatientSearchBoxCU
                  fluid={true}
                  style={{ width: "100%" }}
                  ref={hnSearchRefRS}
                  controller={props.proxyController}
                  inputChangeAutoSearch={false}
                  onSelectPatient={handleOnSelectPatient}
                  onEnter={handleOnSelectPatient}
                  systemHn8Digit={true}
                />
              }
              searchDoctor={
                <SearchBoxDropdown
                  type="Provider"
                  id="4"
                  style={{ width: "100%" }}
                  fluid={true}
                  label=""
                  // disabled={selectedManagePatient !== null}
                  toDisplay={(item: any) =>
                    `${item.pre_name || ""} ${item.first_name} ${
                      item.last_name
                    } [${item.code}]`
                  }
                  onEvent={props.onEvent}
                  // searchedItemList={props.searchedItemList}
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  selectedItem={selectedDoctorRS}
                  setSelectedItem={(value: any, key: any, obj: any) => {
                    if (value === null && key === null) {
                      setSelectedDoctorRS(null);
                      return;
                    }
                    let doctor =
                      props.searchedItemListWithKey?.Provider_4?.find(
                        (item: any) => item.provider_id === key
                      );
                    setSelectedDoctorRS(doctor);
                  }}
                  useWithKey={true}
                  mapOptions={mapProviderOptions}
                />
              }
              searchClinic={
                <SearchBoxDropdown
                  type="Division"
                  id="2"
                  style={{ width: "100%" }}
                  fluid={true}
                  label=""
                  // disabled={selectedManagePatient !== null}
                  toDisplay={(item: any) => `${item.name}`}
                  onEvent={props.onEvent}
                  // searchedItemList={props.searchedItemList}
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  selectedItem={selectDivisionRS}
                  setSelectedItem={(value: any, key: any, obj: any) => {
                    if (value === null && key === null) {
                      setSelectDivisionRS(null);
                      return;
                    }
                    let division =
                      props.searchedItemListWithKey?.Division_2?.find(
                        (item: any) => item.code === key
                      );
                    setSelectDivisionRS(division);
                  }}
                  useWithKey={true}
                  mapOptions={mapDivisionOptions}
                />
              }
              searchWaitingList={
                <Dropdown
                  style={{ width: "100%" }}
                  selection
                  value={selectWaitingListRS}
                  onChange={handleChangeSelectWaitingListRS}
                  options={mapWaitingListOptions(props.waitingList)}
                  clearable={true}
                />
              }
              searchStatus={
                <Dropdown
                  style={{ width: "100%" }}
                  selection
                  value={selectStatusRS}
                  onChange={handleChangeSelectStatusRS}
                  options={props.waitingListItemStatus || []}
                  clearable={true}
                />
              }
              rescheduleList={props.rescheduleList}
              rowProps={(
                state: any,
                rowInfo: any,
                column: any,
                instance: any
              ) => ({
                className:
                  props.selectedAppointment &&
                  rowInfo?.original?.id === props.selectedAppointment?.id
                    ? "blueSelectedRow"
                    : "",
                style: {
                  // backgroundColor: props.selectedAppointment && rowInfo?.original?.id === props.selectedAppointment?.id ? "#cccccc" : "#d6ecf3",
                  cursor: "pointer",
                },
                onClick: () => handleSelectAppointment(rowInfo),
              })}
            />
          )
        }
        // Reconfirm
        reconfirm={
          mode === "reconfirm" && (
            <CardReconfirmUX
              onClickSearch={() => {
                console.log("onSearch");
                console.log(
                  "onSearch hnSearchRefRC?.current?.value: ",
                  hnSearchRefRC?.current?.value()
                );
                // console.log("onSearch selectedDoctorRC", selectedDoctorRC);
                // console.log("onSearch selectDivisionRC", selectDivisionRC);
                // console.log("onSearch selectWaitingListRC", selectWaitingListRC);
                // console.log("onSearch selectStatusRC", selectStatusRC);
                // console.log("onSearch startDateRC", startDateRC);
                // console.log("onSearch endDateRC", endDateRC);

                // query parameters:
                // hn: เลข hn ของผู้ป่วย
                // provider_id: provider id ของ หมอ
                // division_id : id ของ หน่วย/แผนก
                // waiting_list: <string>
                // start_date: วันที่เริ่มต้น ในรูปแบบ format DD/MM/YYYY BE
                // end_date: วันที่สิ้นสุด ในรูปแบบ format DD/MM/YYYY BE
                // type: ชนิดของนัดหมาย (id ของ clinicalTermset ของ ประเภทนัดหมาย)

                props.onEvent({
                  message: "SetScheduling",
                  params: {
                    action: "GetReconfirmAppointment",
                    hn: hnSearchRefRC?.current?.value(),
                    provider_id: selectedDoctorRC?.id,
                    division_id: selectDivisionRC?.id,
                    waiting_list: selectWaitingListRC,
                    start_date: startDateRC,
                    end_date: endDateRC,
                    type: selectStatusRC,
                    appointment_type: selectAppointmentType,
                  },
                });
              }}
              onChangeStartDate={handleOnChangeStartDateRC}
              onChangeEndDate={handleOnChangeEndDateRC}
              startDate={startDateRC}
              endDate={endDateRC}
              searchHN={
                <PatientSearchBoxCU
                  fluid={true}
                  style={{ width: "100%" }}
                  ref={hnSearchRefRC}
                  controller={props.proxyController}
                  inputChangeAutoSearch={false}
                  onSelectPatient={handleOnSelectPatient}
                  onEnter={handleOnSelectPatient}
                  systemHn8Digit={true}
                  // defaultValue={props.patientDetail?.hn}
                />
              }
              searchDoctor={
                <SearchBoxDropdown
                  type="Provider"
                  id="3"
                  style={{ width: "100%" }}
                  fluid={true}
                  label=""
                  // disabled={selectedManagePatient !== null}
                  toDisplay={(item: any) =>
                    `${item.pre_name || ""} ${item.first_name} ${
                      item.last_name
                    } [${item.code}]`
                  }
                  onEvent={props.onEvent}
                  // searchedItemList={props.searchedItemList}
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  selectedItem={selectedDoctorRC}
                  setSelectedItem={(value: any, key: any, obj: any) => {
                    if (value === null && key === null) {
                      setSelectedDoctorRC(null);
                      return;
                    }
                    let doctor =
                      props.searchedItemListWithKey?.Provider_3?.find(
                        (item: any) => item.provider_id === key
                      );
                    setSelectedDoctorRC(doctor);
                  }}
                  useWithKey={true}
                  mapOptions={mapProviderOptions}
                />
              }
              searchClinic={
                <SearchBoxDropdown
                  type="Division"
                  id="1"
                  style={{ width: "100%" }}
                  fluid={true}
                  label=""
                  // disabled={selectedManagePatient !== null}
                  toDisplay={(item: any) => `${item.name}`}
                  onEvent={props.onEvent}
                  // searchedItemList={props.searchedItemList}
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  selectedItem={selectDivisionRC}
                  setSelectedItem={(value: any, key: any, obj: any) => {
                    if (value === null && key === null) {
                      setSelectDivisionRC(null);
                      return;
                    }
                    let division =
                      props.searchedItemListWithKey?.Division_1?.find(
                        (item: any) => item.code === key
                      );
                    setSelectDivisionRC(division);
                  }}
                  useWithKey={true}
                  mapOptions={mapDivisionOptions}
                />
              }
              // searchWaitingList={
              //   <Dropdown
              //     style={{ width: "100%" }}
              //     selection
              //     value={selectWaitingListRC}
              //     onChange={handleChangeSelectWaitingListRC}
              //     options={mapWaitingListOptions(props.waitingList)}
              //     clearable={true}
              //   />
              // }
              // searchStatus={
              //   <Dropdown
              //     style={{ width: "100%" }}
              //     selection
              //     value={selectStatusRC}
              //     onChange={handleChangeSelectStatusRC}
              //     options={props.waitingListItemStatus || []}
              //     clearable={true}
              //   />
              // }
              searchAppointmentType={
                <Dropdown
                  style={{ width: "100%" }}
                  selection
                  value={selectAppointmentType}
                  onChange={handleChangeAppointmentType}
                  options={APPOINTMENT_TYPE}
                  clearable={true}
                />
              }
              reconfirmList={reconfirmList}
              rowProps={(
                state: any,
                rowInfo: any,
                column: any,
                instance: any
              ) => ({
                className:
                  props.selectedAppointment &&
                  rowInfo?.original?.id === props.selectedAppointment?.id
                    ? "blueSelectedRow"
                    : "",
                style: {
                  // backgroundColor: props.selectedAppointment && rowInfo?.original?.id === props.selectedAppointment?.id ? "#cccccc" : "#d6ecf3",
                  cursor: "pointer",
                },
                onClick: () => handleSelectAppointment(rowInfo),
              })}
            />
          )
        }
        // Waiting list
        waitingList={
          mode === "waitingList" && (
            <CardWaitingListUX
              // Waiting List
              onNewWaitingList={() => {
                let url = CONFIG.API_HOST + "/admin/QUE/waitinglist";
                globalThis.window?.open?.(url, "_blank")?.focus();
              }}
              onRefresh={() => {
                props.onEvent({
                  message: "SetScheduling",
                  params: {
                    action: "GetWaitingList",
                  },
                });
              }}
              waitingList={filterWaitingList}
              waitingListRowProps={(
                state: any,
                rowInfo: any,
                column: any,
                instance: any
              ) => ({
                className:
                  selectedWaitingList &&
                  rowInfo?.original?.id === selectedWaitingList?.id
                    ? "blueSelectedRow"
                    : "",
                style: {
                  // backgroundColor: selectedWaitingList && rowInfo?.original?.id === selectedWaitingList?.id ? "#cccccc" : "#d6ecf3",
                  ...(rowInfo?.original?.id && { cursor: "pointer" }),
                },
                onClick: () => {
                  if (
                    selectedWaitingList &&
                    selectedWaitingList?.id === rowInfo?.original?.id
                  )
                    setSelectedWaitingList(null);
                  else setSelectedWaitingList(rowInfo?.original);
                  console.log("!! rowInfo?.original: ", rowInfo?.original);

                  // Clear selected patient
                  props.onEvent({ message: "SelectPatient", params: null });
                  setSelectedWaitingListItem(null);
                },
              })}
              /// Search
              waitingListSearch={searchWaitingList}
              onChangeWaitingListSearch={(e: any, { value }: any) => {
                setSearchWaitingList(value);
              }}
              // onSearchWaitingList={handleOnSearchWaitingList}
              // Checkbox appointment
              // isAppointed={isAppointed}
              // toggleIsAppointed={(e:any, {checked}: CheckboxProps)=> { setIsAppointed(!!checked)}}
              // สถานะ dropdown
              statusOptions={statusOptions}
              selectedStatus={selectedStatus}
              onSelectedStatus={(e: any, { value }: any) => {
                setSelectedStatus(value);
                //
                console.log(
                  "onSelectedStatus then setSelectedWaitingListItem selectedWaitingListItem to null , before: ",
                  selectedWaitingListItem
                );
                setSelectedWaitingListItem(null);
                props.onEvent({ message: "SelectPatient", params: null });
              }}
              selectedName={
                selectedWaitingListItem?.patient_name ? (
                  <span style={{ fontWeight: "normal", fontSize: "1em" }}>
                    {" "}
                    {selectedWaitingListItem?.patient_name}{" "}
                  </span>
                ) : (
                  ""
                )
              }
              disabledFilter={!selectedWaitingList?.id}
              searchPatientNameBox={filterPatientName}
              onSearchPatientNameBox={(e: any, { value }: any) => {
                setFilterPatientName(value);
              }}
              selectedWaitingListName={selectedWaitingList?.name || ""}
              setShowModStatic={() => {
                setShowModStatic(true);
                props.onEvent({
                  message: "SetScheduling",
                  params: {
                    action: "SummaryStatic",
                    waitingList: selectedWaitingList,
                  },
                });
              }}
              setShowModWaitingList={(open: boolean) => {
                // props.onEvent({
                //   message: "SetScheduling",
                //   params: {
                //     action: "GetWaitingListItem",
                //     waiting_list_id: selectedWaitingList?.id,
                //   },
                // });

                setSelectedWaitingListItem(null);
                setShowModWaitingList(open);
                props.onEvent({ message: "SelectPatient", params: null });
              }}
              disableWaitingListManage={selectedWaitingList === null}
              // Patient List
              waitingListItems={waitingListItems}
              waitingListItemRowProps={(
                state: any,
                rowInfo: any,
                column: any,
                instance: any
              ) => ({
                className:
                  selectedWaitingListItem &&
                  rowInfo?.original?.id === selectedWaitingListItem?.id
                    ? "blueSelectedRow"
                    : "",
                style: {
                  // backgroundColor: selectedWaitingListItem && rowInfo?.original?.id === selectedWaitingListItem?.id ? "#cccccc" : "#d6ecf3",
                  ...(rowInfo?.original?.id && { cursor: "pointer" }),
                },
                onClick: () => {
                  console.log(
                    " selected !!! selectedWaitingListItem",
                    selectedWaitingListItem
                  );
                  if (
                    selectedWaitingListItem?.id &&
                    selectedWaitingListItem?.id === rowInfo?.original?.id
                  ) {
                    setSelectedWaitingListItem(null);
                    props.onEvent({ message: "SelectPatient", params: null });
                  } else {
                    setSelectedWaitingListItem(rowInfo?.original);
                    props.onEvent({
                      message: "SelectPatient",
                      params: {
                        id: rowInfo?.original?.patient,
                        card: CARD_PATIENT_APPOINTMENT,
                      },
                    });
                  }
                },
              })}
              // Appointment List
              appointmentList={appointmentListForPatientAppointment(
                props.appointmentList?.filter((item: any) => item.status !== 6)
              )}
              appointmentRowProps={(
                state: any,
                rowInfo: any,
                column: any,
                instance: any
              ) => ({
                className:
                  props.selectedAppointment?.id &&
                  rowInfo?.original?.id === props.selectedAppointment?.id
                    ? "blueSelectedRow"
                    : "",
                style: {
                  // backgroundColor: props.selectedAppointment && rowInfo?.original?.id === props.selectedAppointment?.id ? "#cccccc" : "#d6ecf3",
                  ...(rowInfo?.original?.id && { cursor: "pointer" }),
                },
                onClick: () => handleSelectAppointment(rowInfo),
              })}
              createPatientAppointment={() =>
                props.onEvent({
                  message: "CreatePatientAppointment",
                  params: {
                    waitingListItem: selectedWaitingListItem,
                    waitingList: selectedWaitingList,
                    card: CARD_PATIENT_APPOINTMENT,
                  },
                })
              }
              disabledCreatePatientAppointment={
                !selectedWaitingListItem ||
                selectedWaitingListItem?.status !== "PENDING"
              }
            />
          )
        }
        // Right panel --------------------------------------------------------------------------
        // Appointment Detail
        appointmentDetail={
          tab === "detail" && (
            <CardAppointmentDetailUX
              onDateFocus={() => {
                setTab("calendar");
              }}
              useNoteTemplate={true}
              treatmentType={props.selectedAppointment?.type}
              onChangeTreatmentType={(e: any, data: any) => {
                props.setProp("selectedAppointment.type", data.value);
              }}
              onChangeSelectedAppointmentDate={(dateString: any) => {
                props.setProp("selectedAppointment.date", dateString);
              }}
              onTimeAppointmentChange={(e: any, data: any) => {
                console.log(" set Estiamteat data", data);
                setEstimatedAt(data);
              }}
              onAddNote={() => setOpenModDoctorNote(true)}
              treatmentTypeOptions={props.patientAppType}
              allergy={props.selectedAppointment?.extra?.allergy || ""}
              onAllergyChange={(e: any, data: any) => {
                props.setProp("selectedAppointment.extra.allergy", data.value);
              }}
              premedication={
                props.selectedAppointment?.extra?.premedication || ""
              }
              onPreMedicationChange={(e: any, data: any) => {
                props.setProp(
                  "selectedAppointment.extra.premedication",
                  data.value
                );
              }}
              orderLab={props.selectedAppointment?.extra?.orderLab || ""}
              onOrderLabChange={(e: any, data: any) => {
                props.setProp("selectedAppointment.extra.orderLab", data.value);
              }}
              orderXray={props.selectedAppointment?.extra?.orderXray || ""}
              onOrderXrayChange={(e: any, data: any) => {
                props.setProp(
                  "selectedAppointment.extra.orderXray",
                  data.value
                );
              }}
              divider={<Divider />}
              selectedPatient={props.selectedPatient}
              selectedAppointment={props.selectedAppointment}
              minHour={parseInt(props.selectedAppointment?.start) || 0}
              maxHour={parseInt(props.selectedAppointment?.end) || 23}
              estimatedAt={estimatedAt || ""}
              setEstimatedAt={setEstimatedAt}
              estimatedDuration={estimatedDuration || ""}
              intervalTimeSelected={
                props.selectedAppointment?.slotLength ||
                props.selectedAppointment?.estimated_duration
              }
              setEstimatedDuration={setEstimatedDuration}
              orderNote={orderNote || ""}
              setOrderNote={handleSetOrderNote}
              full={props.selectedAppointment?.dsb_full}
              toggleFull={() => {
                props.setProp(
                  "selectedAppointment.dsb_full",
                  !props.selectedAppointment?.dsb_full
                );
              }}
              onSaveAppointment={handleSaveAppointment}
              onCancelAppointment={handleOnCancelAppointment}
              onCancelAppointmentByPatient={handleOnCancelAppointmentByPatient}
              onPostponeAppointment={handleOnPostponeAppointment}
              availableOptions={props.availableSlots}
              reservedText={"(มีนัดหมาย)"}
              manualTime={props.selectedAppointment?.manual_time}
              blockAppointments={blockAppointments}
              setManualTime={() => {
                props.setProp(
                  "selectedAppointment.manual_time",
                  !props.selectedAppointment?.manual_time
                );
              }}
              printAppointment={handlePrintAppointment}
              // Ishealth-v3 port into CUDent ---v
              onClickOrder={handleOpenModOrder}
              preOrderList={preOrderList}
              activeOrder={openModOrder?.type || ""}
              // flag to show appointment order
              showOrderAppointment={props.showOrderAppointment}
              isLoading={!!props.loadingStatus?.[CARD_PATIENT_APPOINTMENT]}
              save={complete ? <Icon name={complete as any}></Icon> : "บันทึก"}
              hideDrugAllergy={true}
              // Ishealth-v3 port into CUDent ---^
              appointmentedDate={props.selectedAppointment?.date}
            />
          )
        }
        onlyNewPatient={onlyNewPatient}
        changeOnlyNewPatient={() => {
          props.onEvent({
            message: "FilterSchedule",
            params: {
              onlyNewPatient: !onlyNewPatient,
              divisionId: props.selectedDivision?.id,
            },
          });
          setOnlyNewPatient(!onlyNewPatient);
        }}
        // Calendar
        minTime={new Date(1972, 0, 1, 8, 0, 0)}
        maxTime={new Date(1972, 0, 1, 20, 0, 0)}
        hideBlockTable={false && props.selectedAppointment?.id === null}
        localizer={localizer}
        blockList={blockFilterAppointments}
        eventPropGetter={(
          event: any,
          start: Date,
          end: Date,
          isSelected: boolean
        ) => {
          // console.log("event", event)
          let length = event?.providers?.filter(
            (item: any) =>
              item?.dsb?.doctor_provider_type === "Doctor" &&
              item?.dsb?.doctor_dsb_status !== 2
          )?.length;

          return {
            style: event?.holiday
              ? {
                  backgroundColor: "#FFE696",
                  color: "black",
                  textAlign: "center",
                }
              : length === 0
              ? { backgroundColor: "pink", color: "black" }
              : ({} as CSSProperties),
          };
        }}
        onSelectEvent={(e: any) => {
          // console.log("onSelectEvent e: ", e);
          if (props.selectedAppointment) {
            setSelectedEvent(e);
            console.log(e);
            setShowMod(true);
          }
        }}
        onRangeChange={(range: any, view: any) => {
          console.log(range);
          let year = range.start?.getFullYear();
          let month = range.start?.getMonth() + 1;
          if (!Number.isInteger(year) || !Number.isInteger(month)) return;
          if (range.start?.getDate() !== 1) {
            if (month !== 12) {
              month = month + 1;
            } else {
              month = 1;
              year += 1;
            }
          }

          const uYear = Array.isArray(range)
            ? [range[0], range.slice(-1)[0]]
            : [range.start, range.end];
          const uniqueYear = Array.from(
            new Set([
              moment(uYear[0]).format("YYYY"),
              moment(uYear[1]).format("YYYY"),
            ])
          );
          if (uniqueYear.toString() !== calendarYear.toString()) {
            setCalendarYear(uniqueYear);
          }
          props.onEvent({
            message: "FilterSchedule",
            params: { range: { year, month } },
          });
        }}
        divisionOptions={[
          {
            key: -1,
            value: -1,
            text: "ไม่ระบุหน่วยงาน",
          },
        ].concat(
          props.divisionList.map((item: any) => ({
            key: item.id,
            value: item.id,
            text: item.name_code,
          }))
        )}
        // #55748
        // selectedDivisionId={selectedDivisionApp || -1}
        selectedDivisionId={props.selectedDivision?.id || -1}
        changeDivision={(e: any, v: any) => {
          // #55748
          // setSelectedDivisionApp(v.value)
          // props.onEvent({
          //   message: "SearchBlockList",
          //   params: { divisionId: v.value, action: v.value === props.selectedDivision?.id ? "clear" : "" },
          // });
          props.onEvent({
            message: "FilterSchedule",
            params: { divisionId: v.value },
          });
        }}
        hideFilterDivision={true}
        providerSelector={
          <>
            {/* <SearchBox
            type="Provider"
            style={{ width: "15vw" }}
            fluid={true}
            label={"ทันตแพทย์"}
            placeholder="ค้นหาชื่อทันตแพทย์"
            toDisplay={(item: any) => `${item.first_name} ${item.last_name}`}
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={selectedProvider}
            setSelectedItem={setSelectedProvider}
          /> */}

            <SearchBoxDropdown
              type="Provider"
              id="6"
              style={{ width: "100%" }}
              fluid={true}
              label="ทันตแพทย์"
              clearable={true}
              disabled={selectedManagePatient !== null}
              toDisplay={(item: any) =>
                `${item.pre_name || ""} ${item.first_name} ${item.last_name} [${
                  item.code
                }]`
              }
              onEvent={props.onEvent}
              // searchedItemList={props.searchedItemList}
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={selectedProvider}
              setSelectedItem={(value: any, key: any, obj: any) => {
                if (value === null && key === null) {
                  setSelectedProvider(null);
                  return;
                }
                let doctor = props.searchedItemListWithKey?.Provider_6?.find(
                  (item: any) => item.provider_id === key
                );
                console.log(
                  "setSelectedDoctor doctor: ",
                  doctor,
                  props.searchedItemListWithKey
                );
                setSelectedProvider(doctor);
              }}
              useWithKey={true}
              mapOptions={mapProviderOptions}
            />
          </>
        }
        orPrintAppointmentButton={
          <Button
            inverted
            color="blue"
            content="พิมพ์ใบนัด"
            icon="print"
            disabled={!props.selectedOrOrder?.id}
            onClick={() => {
              props.onEvent({
                message: "HandlePrintORAppointmentForm",
                params: {},
              });
            }}
          />
        }
      />

      <ModDuplicateAppointment
        // {...questionDetail}
        // options={options}
        title={"กรุณาตรวจสอบนัดหมาย"}
        onEvent={props.onEvent}
        setProp={props.setProp}
        userTokenize={props.userTokenize}
        onButtonLeftClick={handleConfirmDuplicate}
        onButtonRightClick={handleCancelDuplicate}
        open={!!props.duplicateAppointment}
        onClose={handleCancelDuplicate}
        isButtonBasic={true}
        buttonLeftLabel={"ใช่"}
        buttonRightLabel={"ไม่"}
        size={"large"}
        reason={duplicateReason}
        onChangeReason={(event: any, data: any) => {
          setDuplicateReason(data?.value);
        }}
        buttonLeftColor={"red"}
        buttonRightColor={"green"}
        duplicateAppointment={props.duplicateAppointment}
        reoccureDuplicateAppointment={props.reoccureDuplicateAppointment}
        hn={
          props.duplicateAppointment?.rep_patient_appointments?.[0]?.patient_hn
        }
        patientName={
          props.duplicateAppointment?.rep_patient_appointments?.[0]
            ?.patient_pre_name
            ? props.duplicateAppointment?.rep_patient_appointments?.[0]
                ?.patient_pre_name +
              " " +
              props.duplicateAppointment?.rep_patient_appointments?.[0]
                ?.patient_first_name +
              " " +
              props.duplicateAppointment?.rep_patient_appointments?.[0]
                ?.patient_last_name
            : props.duplicateAppointment?.rep_patient_appointments?.[0]
                ?.patient_first_name +
              " " +
              props.duplicateAppointment?.rep_patient_appointments?.[0]
                ?.patient_last_name
        }
        appointmentedDate={
          props.duplicateAppointment?.rep_patient_appointments?.[0]
            ?.estimated_at
        }
        appointmentedClinicName={
          props.duplicateAppointment?.rep_patient_appointments?.[0]
            ?.division_name
        }
        appointmentedDoctorName={
          props.duplicateAppointment?.rep_patient_appointments?.[0]
            ?.display_info?.provider_name
        }
        duplicateDate={
          props.selectedAppointment?.date + " [" + estimatedAt + "]"
        }
        duplicateClinicName={props.selectedAppointment?.division_name}
        duplicateDoctorName={
          props.selectedAppointment?.display_info?.provider_name
        }
        // handleAddition={handleAddition}
        // handleChange={handleChange}
        // currentValue={currentValue}
      />

      {openModSelectEncounter?.open && (
        <ModSelectEncounter
          onEvent={props.onEvent}
          patientId={props.selectedPatient?.id}
          encounterList={props.encounterPatientList}
          isNewEncounter={openModSelectEncounter?.isNewEncounter}
          // Callback
          onClose={() => setOpenModSelectEncounter(null)}
          onApprove={handleSelectEncounter}
        />
      )}

      <Modal
        open={showMod}
        closeOnDimmerClick={true}
        onClose={(e: any) => setShowMod(false)}
      >
        <ModEventDetailUX
          title={`วันที่เลือก: ${formatADtoBEString(selectedEvent?.date)}, ${
            selectedEvent?.title
          }`}
          providers={selectedEvent?.providers
            ?.filter((item: any) => item?.dsb?.doctor_dsb_status !== 2)
            ?.map((item: any) => ({
              ...item?.dsb,
              dsb: item?.dsb,
              number_appointments: `${item?.dsb?.appointments?.length} (${
                ((parseFloat(item?.dsb?.doctor_end_time) -
                  parseFloat(item?.dsb?.doctor_start_time)) *
                  60) /
                item?.dsb?.doctor_dsb_slot_length
              })`,
              chair: item?.dsb?.chair_provider_id
                ? props?.chairList?.filter(
                    (acc: any) => acc.provider === item?.dsb?.chair_provider_id
                  )?.[0]?.name
                : "ว่าง",
              zone: item?.dsb?.chair_zone_id
                ? props?.divisionDict?.[item?.dsb?.chair_zone_id]?.name
                : "ว่าง",
              date: formatADtoBEString(item?.dsb?.date),
            }))
            ?.filter((item: any) => item.doctor_provider_type === "Doctor")}
          rowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
            style: {
              backgroundColor:
                props.selectedBlock &&
                rowInfo?.original?.dsb_id === props.selectedBlock?.dsb_id
                  ? "#cccccc"
                  : "white",
              cursor: "pointer",
            },
            onClick: () => {
              if (!props.selectedAppointment) return;
              console.log(rowInfo?.original);
              props.onEvent({
                message: "AssignAppointmentToBlock",
                params: rowInfo?.original,
              });

              console.log(
                "rowInfo?.origina?.doctor_dsb_slot_length: ",
                rowInfo?.original?.doctor_dsb_slot_length
              );
              setShowMod(false);
              setTab("detail");
              setEstimatedAt("");
              setEstimatedDuration(rowInfo?.original?.doctor_dsb_slot_length);
            },
          })}
        />
      </Modal>

      <Modal
        size="fullscreen"
        open={showModWaitingList}
        closeOnDimmerClick={true}
        onClose={(e: any) => {
          setShowModWaitingList(false);
          setSelectedIdList([]);
          setSelectedDoctorAddWaiting("");
          setOpenModClinic(false);
        }}
      >
        <ModWaitingListManageUX
          disabledPauseQ={selectedWaitingList?.status === "CANCEL"}
          // disabledResumeQ={selectedWaitingList?.status !== "CANCEL"}
          isCancel={isCancel}
          toggleIsCancel={(e: any, { checked }: CheckboxProps) => {
            setIsCancel(!!checked);
          }}
          isAppointed={isAppointed}
          toggleIsAppointed={(e: any, { checked }: CheckboxProps) => {
            setIsAppointed(!!checked);
          }}
          waitingListName={
            selectedWaitingList?.name ? " " + selectedWaitingList?.name : ""
          }
          isOnHoldQ={isOnHoldQ}
          toggleIsOnHoldQ={(e: any, { checked }: CheckboxProps) => {
            setIsOnHoldQ(!!checked);
          }}
          patientSelector={
            <PatientSearchBoxCU
              ref={hnSearchRef}
              controller={props.proxyController}
              inputChangeAutoSearch={false}
              onSelectPatient={handleOnSelectPatient}
              onEnter={handleOnSelectPatient}
              systemHn8Digit={true}
            />
          }
          doctorSelector={
            <SearchBoxDropdown
              type="Provider"
              id="1"
              style={{ width: "100%" }}
              fluid={true}
              label=""
              clearable={true}
              disabled={selectedManagePatient !== null}
              toDisplay={(item: any) =>
                `${item.pre_name || ""} ${item.first_name} ${item.last_name} [${
                  item.code
                }]`
              }
              onEvent={props.onEvent}
              // searchedItemList={props.searchedItemList}
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={selectedDoctorAddWaiting}
              setSelectedItem={(value: any, key: any, obj: any) => {
                if (value === null && key === null) {
                  setSelectedDoctorAddWaiting(null);
                  return;
                }
                let doctor = props.searchedItemListWithKey?.Provider_1?.find(
                  (item: any) => item.provider_id === key
                );
                console.log("setSelectedDoctor doctor: ", doctor);
                setSelectedDoctorAddWaiting(doctor);
              }}
              useWithKey={true}
              mapOptions={mapProviderOptions}
            />
          }
          disableAddPatient={
            !Number.isInteger(selectedWaitingList?.id) ||
            !Number.isInteger(selectedPatientId) ||
            selectedManagePatient !== null
          }
          disabledPrint={!(manageWaitingListItems?.length > 0)}
          // Table 3
          waitingListItems={manageWaitingListItems}
          addPatientToWaitingList={() => {
            props.onEvent({
              message: "SetScheduling",
              params: {
                action: "AddPatientToWaitingList",
                waitingList: selectedWaitingList,
                patientId: selectedPatientId,
                provider: selectedDoctorAddWaiting,
              },
            });
            hnSearchRef?.current?.clear();
            setSelectedDoctorAddWaiting("");
          }}
          printWaitingList={(e: any) => {
            props.onEvent({
              message: "PrintScheduling",
              params: {
                form: "FormWaitingList",
                data: {
                  status_name: isOnHoldQ
                    ? "STALL"
                    : isAppointed
                    ? "PENDING"
                    : "ALL",
                  waiting_list_name: selectedWaitingList.name,
                  division_name: props.selectedDivision.name_code,
                  provider_name: `${selectedDoctorAddWaiting?.first_name} ${selectedDoctorAddWaiting?.last_name}`,
                  orders: manageWaitingListItems,
                },
              },
            });
          }}
          providerSelector={
            <SearchBoxDropdown
              type="Provider"
              id="2"
              style={{ width: "70%" }}
              fluid={true}
              label="ทันตแพทย์ / นิสิต"
              toDisplay={(item: any) =>
                `${item.pre_name || ""} ${item.first_name} ${item.last_name} [${
                  item.code
                }]`
              }
              onEvent={props.onEvent}
              // searchedItemList={props.searchedItemList}
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={selectedDoctorEditWaiting}
              setSelectedItem={(value: any, key: any, obj: any) => {
                if (value === null && key === null) {
                  setSelectedDoctorEditWaiting(null);
                  return;
                }
                let doctor = props.searchedItemListWithKey?.Provider_2?.find(
                  (item: any) => item.provider_id === key
                );
                setSelectedDoctorEditWaiting(doctor);
              }}
              useWithKey={true}
              mapOptions={mapProviderOptions}
            />
          }
          // Dropdown ประเภทการรักษา
          treatmentTypeOptions={props.patientAppType}
          onChangeTreatmentType={(e: any, data: any) => {
            setSelectedTreatmentType(data.value);
          }}
          treatmentType={selectedTreatmentType}
          note={note}
          changeNote={(e: any) => setNote(e.target.value)}
          disableAssign={selectedIdList?.length === 0}
          // Button Group
          onClickPauseQ={() => {
            setOpenModConfirmNote(true);
            setModConfirmTitle(NOTE_MSG.pause.title);
            setModColor(NOTE_MSG.pause.color);
            setModConfirmSubtitle(NOTE_MSG.pause.subtitle);
          }}
          onClickResumeQ={() => {
            setOpenModConfirmNote(true);
            setModConfirmTitle(NOTE_MSG.resume.title);
            setModColor(NOTE_MSG.resume.color);
            setModConfirmSubtitle(NOTE_MSG.resume.subtitle);
          }}
          onCancelTreatmentPlan={() => {
            setOpenModConfirmNote(true);
            setModConfirmTitle(NOTE_MSG.cancel.title);
            setModColor(NOTE_MSG.cancel.color);
            setModConfirmSubtitle(NOTE_MSG.cancel.subtitle);
          }}
          assignWaitingListItems={editWaitingListItem}
          onSendToClinic={() => {
            setOpenModClinic(!openModClinic);
            props.onEvent({
              message: "SetScheduling",
              params: {
                action: "GetAllWaitingList",
              },
            });
          }}
          openModClinic={openModClinic}
          OpenClinic={
            <>
              <ModOpenSendToClinicUX
                dataWaitingList={dataWaitingList}
                divisionSelector={
                  <SearchBoxDropdown
                    type="Division"
                    id="4"
                    style={{ width: "100%" }}
                    fluid={true}
                    label=""
                    // disabled={selectedManagePatient !== null}
                    toDisplay={(item: any) => `${item.name}`}
                    onEvent={props.onEvent}
                    // searchedItemList={props.searchedItemList}
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    selectedItem={selectDivision}
                    setSelectedItem={(value: any, key: any, obj: any) => {
                      if (value === null && key === null) {
                        setSelectDivision(null);
                        return;
                      }
                      let division =
                        props.searchedItemListWithKey?.Division_4?.find(
                          (item: any) => item.code === key
                        );
                      setSelectDivision(division);
                      props.onEvent({
                        message: "SetScheduling",
                        params: {
                          action: "SelectedWaitingList",
                          division: division,
                        },
                      });
                    }}
                    useWithKey={true}
                    mapOptions={mapDivisionOptions}
                  />
                }
                OnCancel={() => setOpenModClinic(false)}
                OnConfirm={() => {
                  const selectedWaitingItemList = (
                    manageWaitingListItems || []
                  )?.filter((item: any) => selectedIdList.includes(item.id));
                  props.onEvent({
                    message: "SetScheduling",
                    params: {
                      action: "SendWaitingListToClinic",
                      waitinglist: selectedRow,
                      patient: selectedWaitingItemList,
                    },
                  });
                  setOpenModClinic(false);
                }}
                getTrPropsSendWaitingList={(
                  state: any,
                  rowInfo: any,
                  column: any,
                  instance: any
                ) => {
                  return {
                    style: {
                      backgroundColor:
                        rowInfo?.original?.id &&
                        rowInfo?.original?.id === selectedRow?.id
                          ? "#cccccc"
                          : "white",
                    },
                    onClick: () => {
                      setSelectedRow(rowInfo?.original);
                    },
                  };
                }}
              />
            </>
          }
        />
      </Modal>
      <Modal
        size="fullscreen"
        open={showModStatic}
        closeOnDimmerClick={true}
        onClose={(e: any) => {
          setShowModStatic(false);
        }}
      >
        <>
          <div style={{ height: "100%", width: "100%", padding: "10px" }}>
            <div style={{ display: "flex" }}>
              <MenuItem
                menu="queue"
                tab={modTab}
                name="ระยะเวลารอคิว"
                setTab={setModTab}
              />
              <MenuItem
                menu="static"
                tab={modTab}
                name="สถิติการเลิกคิว"
                setTab={setModTab}
              />
            </div>

            {modTab === "queue" ? (
              <ModSummaryWaitingListUX
                headers={[
                  "HN",
                  "ชื่อสกุล",
                  <div>
                    {" "}
                    วันที่เข้า WaitingList{" "}
                    {isAscendingCreated ? (
                      <Icon
                        name="sort amount down"
                        onClick={sortDateWaitngList}
                      />
                    ) : (
                      <Icon
                        name="sort amount up"
                        onClick={sortDateWaitngList}
                      />
                    )}
                  </div>,
                  <div>
                    {" "}
                    วันที่นัดหมาย
                    {isAscendingAppointed ? (
                      <Icon
                        name="sort amount down"
                        onClick={sortDateAppointment}
                      />
                    ) : (
                      <Icon
                        name="sort amount up"
                        onClick={sortDateAppointment}
                      />
                    )}
                  </div>,
                  <div>
                    {" "}
                    ระยะเวลารอคิว (วัน)
                    {isAscendingWaitingTime ? (
                      <Icon name="sort amount down" onClick={sortWaitingTime} />
                    ) : (
                      <Icon name="sort amount up" onClick={sortWaitingTime} />
                    )}
                  </div>,
                ]}
                waitingListName={selectedWaitingList?.name || ""}
                startDateModWL={formatADtoBEString(
                  moment(startDateModWL).format(DATE_FORMAT)
                )}
                changeDate={changeDate}
                endDateModWL={formatADtoBEString(
                  moment(endDateModWL).format(DATE_FORMAT)
                )}
                dataSummaryWaitingList={waitingQueueList}
                OnSearch={() => {
                  props.onEvent({
                    message: "SetScheduling",
                    params: {
                      action: "SummaryStatic",
                      card: "ModSummaryWaitingList",
                      startDateModWL: startDateModWL,
                      endDateModWL: endDateModWL,
                      waitingList: selectedWaitingList,
                    },
                  });
                }}
                buttonExportCSV={
                  <CSVLink
                    data={waitingQueueList}
                    headers={headersModWL}
                    filename={"รายงานระยะเวลารอคิว.csv"}
                    target="_blank"
                  >
                    <Button color="orange">
                      <div style={{ display: "flex" }}>
                        <Icon name="download" />
                        <div style={{ marginRight: "3px" }}> Export .CSV </div>
                      </div>
                    </Button>
                  </CSVLink>
                }
              />
            ) : modTab === "static" ? (
              <ModSummaryStaticUX
                waitingListName={selectedWaitingList?.name || ""}
                startDateModST={formatADtoBEString(
                  moment(startDateModST).format(DATE_FORMAT)
                )}
                changeDate={changeDate}
                endDateModST={formatADtoBEString(
                  moment(endDateModST).format(DATE_FORMAT)
                )}
                dataSummaryStatic={dataSummaryStatic}
                OnSearch={() => {
                  props.onEvent({
                    message: "SetScheduling",
                    params: {
                      action: "SummaryStatic",
                      card: "ModSummaryStatic",
                      startDateModST: startDateModST,
                      endDateModST: endDateModST,
                    },
                  });
                }}
                buttonExportCSV={
                  <CSVLink
                    data={dataSummaryStatic}
                    headers={headersModST}
                    filename={"รายงานสถิติการเลิกคิว.csv"}
                    target="_blank"
                  >
                    <Button color="orange">
                      <div style={{ display: "flex" }}>
                        <Icon name="download" />
                        <div style={{ marginRight: "3px" }}> Export .CSV </div>
                      </div>
                    </Button>
                  </CSVLink>
                }
              />
            ) : (
              ""
            )}
          </div>
        </>
      </Modal>
      <ModConfirmWithNote
        modalOpen={openModConfirmNote}
        titleName={modConfirmTitle}
        modalSize="tiny"
        titleColor={modColor}
        showTextNote={true}
        textConTent={modConfirmSubtitle}
        approveButtonText="ใช่"
        approveButtonColor="green"
        denyButtonText="ไม่"
        denyButtonColor="red"
        buttonBasic={true}
        textNote={manageQNote}
        disabledApproveButton={
          !(manageQNote?.length > 3) || !props.userTokenize?.token
        }
        setTextNote={(e: any, { value }: any) => {
          setManageQNote(value);
        }}
        onDeny={() => {
          setOpenModConfirmNote(false);
          setModConfirmTitle("");
          setModColor("");
          setModConfirmSubtitle("");
          setManageQNote("");

          props.onEvent({
            message: "UserTokenize",
            params: {
              action: "clear",
            },
          });
        }}
        onApprove={() => {
          const selectedWaitingItemList = (
            manageWaitingListItems || []
          )?.filter((item: any) => selectedIdList.includes(item.id));
          switch (modConfirmTitle) {
            case NOTE_MSG.pause.title:
              props.onEvent({
                message: "SetScheduling",
                params: {
                  action: "WaitItems",
                  note: manageQNote,
                  token: props.userTokenize?.token,
                  item: selectedWaitingItemList,
                  waitinglist: selectedWaitingList,
                },
              });
              break;
            case NOTE_MSG.resume.title:
              props.onEvent({
                message: "SetScheduling",
                params: {
                  action: "ResumeWaiting",
                  note: manageQNote,
                  token: props.userTokenize?.token,
                  item: selectedWaitingItemList,
                  waitinglist: selectedWaitingList,
                },
              });
              break;
            case NOTE_MSG.cancel.title:
              props.onEvent({
                message: "SetScheduling",
                params: {
                  action: "RemoveItems",
                  note: manageQNote,
                  token: props.userTokenize?.token,
                  item: selectedWaitingItemList,
                  waitinglist: selectedWaitingList,
                },
              });
              break;

            default:
              break;
          }
        }}
        authorizeComponent={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              margin: "10px",
            }}
          >
            {"ระบุชื่อผู้บันทึกข้อมูล "}
            <EmployeeToken
              placeholder={"รหัสผู้บันทึก"}
              onEnterToken={(code) => {
                props.onEvent({
                  message: "UserTokenize",
                  params: {
                    action: "CheckUserToken",
                    code: code,
                  },
                });

                // props.onEvent({
                //   message: "HandleGetDeliverDrugTokenization",
                //   params: { code },
                // });
              }}
              onClearToken={() =>
                props.setProp("userTokenize", {
                  ...props.userTokenize,
                  token: "",
                  employeeName: "",
                  loading: false,
                  error: null,
                })
              }
              error={!!props.userTokenize?.error}
              loading={props.userTokenize?.loading}
              employeeName={props.userTokenize?.employeeName}
            />
          </div>
        }
      />

      <Modal
        open={openModOrder?.type === "TREATMENT"}
        onClose={handleCloseModOrder}
        closeOnDimmerClick
        style={{ width: "70%" }}
      >
        <CardTreatmentOrder
          titleName="Treatment Request"
          setProp={props.setProp}
          onEvent={props.onEvent}
          runSequence={props.runSequence}
          claimOptions={props.masterOptions.claimTreatment}
          OrderTreatmentSequence={props.OrderTreatmentSequence}
          searchedItemList={props.searchedItemList}
          treatmentOrderList={props.treatmentOrderList}
          successMessage={props.successMessage}
          errorMessage={props.errorMessage}
          procedureItems={props.procedureItems}
          secondaryItems={props.secondaryItems}
          treatmentTemplateList={props.treatmentTemplateList}
          hidePrincipalDiagnosis={true}
          hideSecondaryDiagnosis={true}
          encounterId={props.selectedAppointment?.order_encounter || null}
          save="SAVE"
          isAppointment={true}
          isNullEmr={true}
          checkOutPerformDiv={true}
          divisionOpdOptions={props.masterOptions.divisionOpd}
          onSuccess={handleCloseModOrder}
          editId={openModOrder?.id || null}
        />
      </Modal>
      <Modal
        open={openModOrder?.type === "IMAGING"}
        onClose={handleCloseModOrder}
        closeOnDimmerClick
        style={{ width: "auto" }}
      >
        <CardImagingRequest
          setProp={props.setProp}
          onEvent={props.onEvent}
          successMessage={props.successMessage}
          errorMessage={props.errorMessage}
          imagingList={props.imagingList}
          orderImagingList={props.orderImagingList}
          masterOptions={props.masterOptions}
          onSuccess={handleCloseModOrder}
          editId={openModOrder?.id || null}
          isAppointment={true}
        />
      </Modal>
      <Modal
        open={["CENTRAL_LAB", "BLOOD_BANK"].includes(openModOrder?.type || "")}
        onClose={handleCloseModOrder}
        closeOnDimmerClick
        style={{ width: "70%", padding: "10px" }}
      >
        <CardLabRequest
          setProp={props.setProp}
          onEvent={props.onEvent}
          // seq
          runSequence={props.runSequence}
          OrderCentralLabSequence={props.OrderCentralLabSequence}
          // data
          userLabPermission={props.userLabPermission}
          doctorLabOrderList={props.doctorLabOrderList}
          editId={openModOrder?.id || null}
          // options
          divisionOpdOptions={props.masterOptions.divisionOpd}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          selectedEncounter={props.selectedEncounter}
          selectedEmr={props.selectedEmr}
          selectedProgressCycle={props.selectedProgressCycle}
          loadingStatus={props.loadingStatus}
          // style
          doctorLabOrderTableStyle={{ height: "300px", display: "none" }}
          saveLabOrderStyle={{ marginTop: "0.5rem" }}
          // config
          isRestart={true}
          isNullEmr={true}
          checkOutPerformDiv={true}
          isAppointment={true}
          isBloodBank={openModOrder?.type === "BLOOD_BANK"}
          // callback
          onSuccess={handleCloseModOrder}
        />
      </Modal>

      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_PATIENT_APPOINTMENT}`, null);
        }}
        error={props.errorMessage?.[CARD_PATIENT_APPOINTMENT]}
        success={null}
      />

      <Modal
        open={openModDoctorNote}
        onClose={() => setOpenModDoctorNote(false)}
      >
        <ModSearchDoctorNote
          controller={props.proxyController}
          onClose={() => setOpenModDoctorNote(false)}
          onSelectNote={handleOnSelectNote}
        />
      </Modal>
    </div>
  );
};

export default CardPatientAppointment;
