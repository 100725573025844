import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Input,
  Label,
  Icon
} from 'semantic-ui-react'

const CardReturnDrugUX = (props: any) => {
    return(
      <div
        style={{padding: "10px"}}>
        <div
          style={{display: "flex", justifyContent: "space-between"}}>
          
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <div
              className="ui label brown ribbon">
              {props.isRequest? props.orderCode: "NEW"}
            </div>
            <div
              style={{fontWeight: "bold", fontSize: "1.2rem"}}>
              คืนยากลับเข้าห้องยา
            </div>
          </div>
          <div
            style={{display: "flex"}}>
            
            <Label>
              {props.isRequest ?  props.statusName : "NEW"}
            </Label>
            <div
              style={{cursor: "pointer", margin: "-5px -5px 0 15px", display: props.closeIcon ? "" : "none"}}>
              
              <Icon
                className="red large"
                name={"close"}
                onClick={props.onClose}>
              </Icon>
            </div>
          </div>
        </div>
        <div
          className="ui divider"
          style={{margin: "0.5rem 0",  display: props.showStatus ? "" : "none"}}>
          
        </div>
        <div>
          {props.ErrorMessage}
        </div>
        <div
          style={{display:"flex", alignItems: "center"}}>
          
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <div
              style={{margin: "0 5px 0 5px"}}>
              รายการขอคืนยาอื่นๆ
            </div>
            <Button
              onClick={props.handleOnNew}
              size="mini">
              New
            </Button>
            <div
              style={{display: props.buttonOrderCode ? "" : "none"}}>
              {props.buttonOrderCode}
            </div>
          </div>
        </div>
        <div
          style={{display: props.isRequest ? "flex" :"none",paddingTop:"10px"}}>
          
          <div
            style={{display: "flex"}}>
            
            <div
              style={{margin: "10px 5px 0 5px"}}>
              ผู้ขอคืน
            </div>
            <Input
              readOnly={true}
              type="text"
              value={props.requesterName}>
            </Input>
            <div
              style={{margin: "10px 5px 0 5px"}}>
              เวลา
            </div>
            <Input
              readOnly={true}
              type="text"
              value={props.requestedDivision}>
            </Input>
            <div
              style={{margin: "10px 5px 0 5px"}}>
              แผนก
            </div>
            <Input
              readOnly={true}
              type="text"
              value={props.requestedTime}>
            </Input>
          </div>
        </div>
        <div
          style={{display: "flex",padding:"2px"}}>
          
          <div
            style={{margin: "0 5px 0 5px",display: !props.actionCancel ? "none":""}}>
            ผู้ยกเลิก
          </div>
          <Input
            readOnly={true}
            style={{display: !props.actionCancel  ? "none":""}}
            type="text"
            value={props.requested}>
          </Input>
          <div
            style={{margin: "0 5px 0 5px",display: !props.actionCancel ? "none":""}}>
            เวลา
          </div>
          <Input
            readOnly={true}
            style={{display: !props.actionCancel  ? "none":""}}
            type="text"
            value={props.datetimeCancel}>
          </Input>
          <div
            style={{margin: "0 5px 0 5px",display: !props.actionCancel ? "none":""}}>
            เเผนก
          </div>
          <Input
            readOnly={true}
            style={{margin: "0 5px 0 5px",display: !props.actionCancel  ? "none":""}}
            type="text">
          </Input>
        </div>
        <div>
          
          <Table
            data={props.drugOrderList}
            getTrProps={props.drugRowProps}
            headers="ลำดับ,รหัสยา,ชื่อยา,จำนวนจ่าย,จำนวนคงเหลือ,จำนวนขอคืน,หน่วย"
            keys="no,code,name,quantity_issue,quantity_left,quantity_request,stock_unit_name"
            minRows="4"
            showPagination={false}
            style={{height: "200px"}}>
          </Table>
        </div>
        <div
          style={{display: "flex",  marginTop: "10px"}}>
          
          <Button
            onClick={props.handleOnLog}
            size="mini"
            style={{display:props.isRequest? "" : "none"}}>
            LOG
          </Button>
          <div
            style={{flex:"1"}}>
            
            <Label>
              {`จากใบยา: ${props.code}`}
            </Label>
            <Label>
              {`ของผู้ป่วย: ${props.patientName}`}
            </Label>
            <Label>
              {`จากหน่วยงาน: ${props.divisionOrder}`}
            </Label>
          </div>
          <Button
            color="green"
            onClick={props.handleOnRequest}
            size="tiny"
            style={{display: props.isRequest ? "none":""}}>
            REQUEST
          </Button>
          <Button
            color="green"
            onClick={props.handleOnEdit}
            size="tiny"
            style={{display: props.isRequest ? "":"none"}}>
            EDIT
          </Button>
        </div>
      </div>
    )
}

export default CardReturnDrugUX

export const screenPropsDefault = {"drugSearch":"[Drug Search Box]"}

/* Date Time : Fri Jun 24 2022 02:47:26 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  marginTop: \"10px\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 5,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugOrderList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.drugRowProps"
        },
        "headers": {
          "type": "value",
          "value": "ลำดับ,รหัสยา,ชื่อยา,จำนวนจ่าย,จำนวนคงเหลือ,จำนวนขอคืน,หน่วย"
        },
        "keys": {
          "type": "value",
          "value": "no,code,name,quantity_issue,quantity_left,quantity_request,stock_unit_name"
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "REQUEST"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleOnRequest"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{display: props.isRequest ? \"none\":\"\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการขอคืนยาอื่นๆ"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "EDIT"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleOnEdit"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{display: props.isRequest ? \"\":\"none\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "LOG"
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.handleOnLog"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{display:props.isRequest? \"\" : \"none\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.isRequest ? \"flex\" :\"none\",paddingTop:\"10px\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ขอคืน"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px 5px 0 5px\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px 5px 0 5px\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px 5px 0 5px\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Input",
      "parent": 73,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.requesterName"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Input",
      "parent": 73,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.requestedDivision"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Input",
      "parent": 73,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.requestedTime"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",padding:\"2px\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ยกเลิก"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\",display: !props.actionCancel ? \"none\":\"\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\",display: !props.actionCancel ? \"none\":\"\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": "เเผนก"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\",display: !props.actionCancel ? \"none\":\"\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Input",
      "parent": 81,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: !props.actionCancel  ? \"none\":\"\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.requested"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Input",
      "parent": 81,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: !props.actionCancel  ? \"none\":\"\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.datetimeCancel"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Input",
      "parent": 81,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\",display: !props.actionCancel  ? \"none\":\"\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0.5rem 0\",  display: props.showStatus ? \"\" : \"none\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "code",
          "value": "props.isRequest? props.orderCode: \"NEW\""
        },
        "className": {
          "type": "value",
          "value": "ui label brown ribbon"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "คืนยากลับเข้าห้องยา"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Label",
      "parent": 98,
      "props": {
        "children": {
          "type": "code",
          "value": "props.isRequest ?  props.statusName : \"NEW\""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", margin: \"-5px -5px 0 15px\", display: props.closeIcon ? \"\" : \"none\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Icon",
      "parent": 102,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "name": {
          "type": "code",
          "value": "\"close\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "New"
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.handleOnNew"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "Label",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "`จากใบยา: ${props.code}`"
        },
        "name": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Label",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "`ของผู้ป่วย: ${props.patientName}`"
        },
        "className": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "Label",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "`จากหน่วยงาน: ${props.divisionOrder}`"
        },
        "className": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonOrderCode"
        },
        "style": {
          "type": "code",
          "value": "{display: props.buttonOrderCode ? \"\" : \"none\"}"
        }
      },
      "seq": 109,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardReturnDrugUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "drugSearch": "[Drug Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
