import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Icon } from "semantic-ui-react";

// UX
import CardVerbalOrderUX from "./CardVerbalOrderUX";
import CardDrugVerbalOrder from "./CardDrugVerbalOrder";
import CardLabRequest from "react-lib/apps/HISV3/LAB/CardLabRequest";
import CardVerbalOrderHistory from "./CardVerbalOrderHistory";
import CardAdmitOrder from "react-lib/apps/ADM/CardAdmitOrder";
import CardTreatmentOrder from "react-lib/apps/HISV3/DPO/CardTreatmentOrder";

// Common
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";

// Const
const DOCTOR_SEARCH_ID = "Doctor_VO";

const CardVerbalOrder = (props: any) => {
  const [loadding, setLoadding] = useState(false);
  const [completed, setCompleted] = useState(false);

  const [containerName, setContainerName] = useState<string>("drug");
  const [doctor, setDoctor] = useState<Record<string, any> | null>(null);
  const [orderType, setOrderType] = useState("");
  const [drugType, setDrugType] = useState("");
  const [orderDiv, setOrderDiv] = useState("");
  const [note, setNote] = useState("");

  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const [verbalOrderSave, setVerbalOrderSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);

  // Effect
  useEffect(() => {
    console.log("duplicateLabDetected ", props.duplicateLabDetected);
    if (props.duplicateLabDetected === true) {
      setOpenConfirm(true);
    }
  }, [props.duplicateLabDetected]);

  useEffect(() => {
    const roleTypes = props.django?.user?.role_types || [];

    if (
      ["หอผู้ป่วย"].includes(props.divisionType) &&
      (roleTypes || []).includes("REGISTERED_NURSE") &&
      props.selectedEncounter?.type === "IPD"
    ) {
      const callFrom = "WARD";

      if (props.selectedEncounter?.id) {
        props.onEvent({
          message: "GetMedReconcileCheck",
          params: { encounter: props.selectedEncounter, callForm: callFrom },
        });
      }
    } else {
      console.warn("ไม่ได้มาจาก หอผู้ป่วย เปิด Card Nurse Note: ");
    }
  }, [props.selectedEncounter, props.django, props.divisionType]);

  useEffect(() => {
    if (
      props.errorMessage?.CardVerbalOrder !== null &&
      props.errorMessage?.CardVerbalOrder !== undefined
    ) {
      setLoadding(false);
      setErrorMessage(props.errorMessage?.CardVerbalOrder);

      setTimeout(() => {
        props.onEvent({
          message: "SaveVerbalOrder",
          params: { action: "clearMessage" },
        });
      }, 5000);
    }
    if (
      props.successMessage?.CardVerbalOrder !== null &&
      props.successMessage?.CardVerbalOrder !== undefined
    ) {
      setLoadding(false);
      setCompleted(true);

      handleClearValue();

      setTimeout(() => {
        setCompleted(false);
        // props.setProp("successMessage.CardVerbalOrder", "");
        props.onEvent({
          message: "SaveVerbalOrder",
          params: { action: "clearMessage" },
        });
      }, 5000);
      // toast.success("บันทึกข้อมูลสำเร็จ");
    }
  }, [
    props.successMessage?.CardVerbalOrder,
    props.errorMessage?.CardVerbalOrder,
  ]);

  useEffect(() => {
    setOrderDiv(props.selectedDivision?.id);
  }, [props.selectedDivision]);

  // callback
  const handleSelectedItem = useCallback(
    async (value: any) => {
      const data = props.searchedItemListWithKey[DOCTOR_SEARCH_ID]?.find(
        (item: any) => item.id === value
      );

      setDoctor(data);
    },
    [props.searchedItemListWithKey]
  );

  // Memo
  const drugTypeItems = useMemo(() => {
    if (props.selectedEncounter?.type === "IPD") {
      return [
        {
          key: "DRUG_ORDER_HOME_IPD",
          value: "DRUG_ORDER_HOME_IPD",
          text: "IPD HOME รายการสั่งยากลับบ้านผู้ป่วยใน",
        },
        {
          key: "DRUG_ORDER_ONE_DAY",
          value: "DRUG_ORDER_ONE_DAY",
          text: "ONE DAY รายการสั่งยาใช้ภายในวันผู้ป่วยใน",
        },
        {
          key: "DRUG_ORDER_ONE_DOSE__IPD",
          value: "DRUG_ORDER_ONE_DOSE__IPD",
          text: "ONE DOSE รายการสั่งยาใช้ในโรงพยาบาลผู้ป่วยใน",
        },
      ];
    } else {
      return [
        {
          key: "DRUG_ORDER_HOME_OPD",
          value: "DRUG_ORDER_HOME_OPD",
          text: "OPD HOME รายการสั่งยากลับบ้านผู้ป่วยนอก",
        },
        {
          key: "DRUG_ORDER_ONE_DOSE__OPD",
          value: "DRUG_ORDER_ONE_DOSE__OPD",
          text: "ONE DOSE รายการสั่งยาใช้ในโรงพยาบาลผู้ป่วยนอก",
        },
      ];
    }
  }, [props.selectedEncounter?.type]);

  // Handler
  const handleClearValue = async () => {
    setOrderType("");
    setDoctor(null);

    props.setProp(`searchedItemListWithKey.${DOCTOR_SEARCH_ID}`, []);

    // Drug
    setOrderDiv(props.selectedDivision?.id);
    setNote("");
    setDrugType("");

    const tab = containerName;

    await setContainerName("");

    setContainerName(tab);
  };

  const handleSave = () => {
    setLoadding(true);

    props.onEvent({
      message: "SaveVerbalOrder",
      params: {
        action: "REQUEST",
        // date: utils.formatDatetime(moment()).replace(" ", "-"),
        encounterId: props.selectedEncounter?.id,
        note: note,
        order_by: doctor?.id || null,
        order_specific_type: drugType,
        order_type: orderType,
        order: {
          id: null,
          action: "REQUEST",
          emr: props.selectedEmr?.id,
          order_div: orderDiv.toString(),
          is_out_perform_div: false,
        },
      },
    });
  };

  const handleSaveLab = (allowDupLab: boolean) => {
    setLoadding(true);

    props.onEvent({
      message: "SaveVerbalOrder",
      params: {
        action: "REQUEST",
        // date: utils.formatDatetime(moment()).replace(" ", "-"),
        encounterId: props.selectedEncounter?.id,
        note: note,
        order_by: doctor?.id || null,
        order_specific_type: "LAB_ORDER",
        order_type: orderType,
        order: {
          id: null,
          action: "ORDER",
          progression_cycle: props.selectedProgressCycle?.id,
          is_advance: false,
          order_time: null,
          emr: props.selectedEmr?.id,
          encounter: props.selectedEncounter?.id,
          order_status: 2,
          order_payment_status: 2,
          lab_speciality_display_name: null,
          order_items: props.OrderCentralLabSequence?.labOrder?.order_items,
          note: "",
          order_summary: "",
          order_list: "",
          allow_duplicate_flag: allowDupLab || false,
          ack_unavailable_lab: false,
          children: null,
          is_out_perform_div: false,
          out_perform_div: "",
        },
      },
    });
  };

  const handleSaveTreatment = () => {
    setLoadding(true);

    const orderItems = props.OrderTreatmentSequence?.orderItems;
    let orderItemsNoId = [];

    if (orderItems) {
      orderItemsNoId = orderItems.map(function (item: any) {
        delete item.id;
        return item;
      });
    }

    let data = {
      action: "ORDER",
      claim_payload: null,
      core_procedure: [],
      emr: props.selectedEmr?.id,
      encounter: props.selectedEncounter?.id,
      equipment_detail: props.treatmentOrderList?.equipmentDetail,
      id: null,
      is_advance: false,
      is_operation_division: false,
      is_out_perform_div: false,
      note: "",
      order_detail: props.treatmentOrderList?.orderDetail,
      order_items: orderItemsNoId,
      order_status: 2,
      order_summary_detail: "",
      order_time: null,
      out_perform_div: "",
      progression_cycle: "",
      show_alert: true,
      to_be_performer: props.treatmentOrderList?.toBePerformer,
      _items: orderItems,
      _principalDiag: [],
      _secondaryDiag: [],
    };

    props.onEvent({
      message: "SaveVerbalOrder",
      params: {
        action: "REQUEST",
        encounterId: props.selectedEncounter?.id,
        note: note,
        order_by: doctor?.id || null,
        order_specific_type: "TREATMENT_ORDER",
        order_type: orderType,
        order: data,
      },
    });
  };

  const handleSaveAdmitOrder = (data: {}) => {
    props.onEvent({
      message: "SaveVerbalOrder",
      params: {
        action: "REQUEST",
        encounterId: props.selectedEncounter?.id,
        note: note,
        order_by: doctor?.id || null,
        order_specific_type: "ADMIT_ORDER",
        order_type: orderType,
        order: data,
      },
    });

    setVerbalOrderSave(false);
  };

  const handleSaveDupLab = () => {
    props.setProp("duplicateLabDetected", false);
    handleSaveLab(true);
    handleCloseModConfirm();
  };

  const handleCloseModConfirm = () => {
    props.setProp("duplicateLabDetected", false);

    setOpenConfirm(false);
    setLoadding(false);
  };

  const handleEditVerbalOrder = (item: any) => {
    // do something here for edit order

    // recommend below
    // props.onEvent({message: "HandleVerbalOrderAction", params: {action: "edit", pendingTaskOrderId: item.id}})
    console.log("handleEditVerbalOrder: ", item);
  };

  const handleApproveMedReconcile = () => {
    props.setProp("medReconcileCheck.open_card_med_reconciliation", false);

    props.onEvent({
      message: "OpenMedReconcileFromWarning",
      params: {
        medReconcileCheck: props.medReconcileCheck,
        selecteRecordViewIndex: props.medReconcileIndex,
      },
    });
  };

  const handleSaveVerbalOrder = () => {
    if (containerName === "lab") {
      handleSaveLab(false);
    } else if (containerName === "treatment") {
      handleSaveTreatment();
    } else if (containerName === "admit") {
      setLoadding(true);

      setVerbalOrderSave(true);
    } else {
      handleSave();
    }
  };

  // console.log("CardVerbalOrder", props, loadding);

  return (
    <div style={{ padding: "0 10px", paddingBottom: "2rem" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => setErrorMessage(null)}
        error={errorMessage}
        success={null}
      />

      <CardVerbalOrderUX
        setProp={props.setProp}
        onEvent={props.onEvent}
        // open tab
        containerName={containerName}
        method={orderType}
        saveloading={loadding}
        hiddenPerformed={containerName === "history"}
        // options
        methodOptions={[
          { key: "VERBAL", value: "VERBAL", text: "ทางวาจา" },
          { key: "TELEPHONE", value: "TELEPHONE", text: "ทางโทรศัพท์" },
        ]}
        // callback
        onChangeTab={setContainerName}
        methodOnChange={(_e: any, { value }: any) => {
          setOrderType(value);
        }}
        saveVerbalOrder={handleSaveVerbalOrder}
        // Element
        save={completed ? <Icon className="check"></Icon> : "SAVE"}
        doctorSearchBox={
          <div style={{ display: "flex", alignItems: "center" }}>
            <label>แพทย์ผู้สั่ง</label>
            <SearchBoxDropdown
              onEvent={props.onEvent}
              // config
              type={"Doctor"}
              id="VO"
              style={{ width: "275px", marginLeft: "10px" }}
              fluid={true}
              useWithKey={true}
              icon="search"
              limit={20}
              // Select
              searchedItemListWithKey={props.searchedItemListWithKey}
              selectedItem={doctor?.id || null}
              setSelectedItem={handleSelectedItem}
            />
          </div>
        }
        container={
          <div style={{ width: "100%" }}>
            {containerName === "lab" ? (
              <div style={{ width: "100%", marginTop: "-10px" }}>
                <CardLabRequest
                  setProp={props.setProp}
                  onEvent={props.onEvent}
                  // seq
                  runSequence={props.runSequence}
                  OrderCentralLabSequence={props.OrderCentralLabSequence}
                  // data
                  doctorLabOrderList={props.doctorLabOrderList}
                  // CommonInterface
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  selectedEncounter={props.selectedEncounter}
                  selectedEmr={props.selectedEmr}
                  selectedProgressCycle={props.selectedProgressCycle}
                  // style
                  saveLabOrderStyle={{ display: "none" }}
                  doctorLabOrderTableStyle={{ display: "none" }}
                  // config
                  isRestart={true}
                />
              </div>
            ) : containerName === "drug" ? (
              <div style={{}}>
                <CardDrugVerbalOrder
                  setProp={props.setProp}
                  onEvent={props.onEvent}
                  drugTypeOptions={drugTypeItems || []}
                  orderDivOptions={props.masterOptions?.division || []}
                  drugTypeChange={(_e: any, { value }: any) => {
                    setDrugType(value);
                  }}
                  orderDivChange={(_e: any, { value }: any) => {
                    setOrderDiv(value);
                  }}
                  orderDiv={orderDiv}
                  noteChange={(e: any, { value }: any) => {
                    setNote(e.target.value);
                  }}
                />
              </div>
            ) : containerName === "admit" ? (
              <div style={{ width: "100%" }}>
                <CardAdmitOrder
                  controller={props.crdAdmitOrderController}
                  // orderId={
                  //   context.state.encounterDetailDict?.admitorderfrom?.[
                  //     context.state.selectedEncounter.id
                  //   ]?.[0]?.id
                  // }
                  subICDController={props.subICDController}
                  DJANGO={props.django}
                  PATIENT_DATA={props.PATIENT_DATA}
                  showHeader={false}
                  allowClear={true}
                  hideSaveButton={false}
                  hideCancelButton={false}
                  closeable={false}
                  toggleable={false}
                  hideBottom={true}
                  labelFitContent={true}
                  verbalOrderSave={verbalOrderSave}
                  handleSaveVerbalOrder={handleSaveAdmitOrder}
                />
              </div>
            ) : containerName === "treatment" ? (
              <div style={{ width: "100%" }}>
                <CardTreatmentOrder
                  setProp={props.setProp}
                  onEvent={props.onEvent}
                  runSequence={props.runSequence}
                  claimOptions={props.masterOptions.claimTreatment}
                  OrderTreatmentSequence={props.OrderTreatmentSequence}
                  searchedItemList={props.searchedItemList}
                  treatmentOrderList={props.treatmentOrderList}
                  successMessage={props.successMessage}
                  errorMessage={props.errorMessage}
                  hideSaveButton={true}
                  procedureItems={props.procedureItems}
                  secondaryItems={props.secondaryItems}
                  treatmentTemplateList={props.treatmentTemplateList}
                  editId={props?.treatmentOrderList?.item?.id || null}
                  selectedProgressCycle={props.selectedProgressCycle}
                  //MedReconcile
                  medReconcileCheck={props.medReconcileCheck}
                  divisionType={props.divisionType}
                  medReconcileIndex={props.medReconcileIndex}
                  django={props.django}
                  selectedEncounter={props.selectedEncounter}
                />
              </div>
            ) : containerName === "history" ? (
              <div style={{ width: "100%" }}>
                <CardVerbalOrderHistory
                  actionable={true}
                  setProp={props.setProp}
                  onEvent={props.onEvent}
                  runSequence={props.runSequence}
                  searchedItemList={props.searchedItemList}
                  loadingStatus={props.loadingStatus}
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  selectedEncounter={props.selectedEncounter}
                  setEditItem={handleEditVerbalOrder}
                  verbalOrderHistoryFilter={props.verbalOrderHistoryFilter}
                  verbalOrderHistoryList={props.verbalOrderHistoryList}
                  promptMessage={props.promptMessage}
                  duplicateLabDetected={props.duplicateLabDetected}
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>
        }
      />

      <ModMedReconcileAlert
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        onApprove={handleApproveMedReconcile}
      >
        {props.medReconcileCheck?.med_reconciliation != null
          ? `Med Reconcile ${props.medReconcileCheck?.med_reconciliation_label} ยังไม่ได้ถูกรับทราบโดยพยาบาล`
          : `ยังไม่ได้สร้าง Medication Reconciliation สำหรับ PlanTransfer ${props.medReconcileCheck?.plan_transfer_id}`}
      </ModMedReconcileAlert>

      <ModConfirm
        openModal={openConfirm}
        titleName="กรุณายืนยัน"
        // @ts-ignore
        content={<>{props.promptMessage}</>}
        onApprove={handleSaveDupLab}
        onDeny={handleCloseModConfirm}
      />
    </div>
  );
};

export default React.memo(CardVerbalOrder);
