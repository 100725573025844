import React, { useEffect } from "react";
// UI Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
// UI TPD
import CardReportDrugUseRecordUX from "./CardReportDrugUseRecordUX";

const CARD_KEY: string = "CardReportDrugUseRecord";

type CardReportDrugUseRecordProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  ReportDrugUseRecordSequence?: any;
};

const CardReportDrugUseRecord = (props: CardReportDrugUseRecordProps) => {
  useEffect(() => {
    props.runSequence({ sequence: "ReportDrugUseRecord", restart: true });
  }, []);

  const handelSearch = () => {
    props.runSequence({
      sequence: "ReportDrugUseRecord",
      action: "FETCH",
      cardKey: CARD_KEY,
    });
  };

  const handelPrint = () => {
    props.runSequence({
      sequence: "ReportDrugUseRecord",
      action: "PRINT",
      cardKey: CARD_KEY,
    });
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
      />

      <CardReportDrugUseRecordUX
        // function
        setProp={props.setProp}
        // options
        masterOptions={props.masterOptions}
        // seq
        ReportDrugUseRecordSequence={props.ReportDrugUseRecordSequence}
        // component
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handelSearch}
            // data
            paramKey={`${CARD_KEY}_FETCH`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_FETCH`]}
            // config
            color="blue"
            size="small"
            title="แสดง"
          />
        }
        buttonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handelPrint}
            // data
            paramKey={`${CARD_KEY}_PRINT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_PRINT`]}
            // config
            color="green"
            size="small"
            title="พิมพ์รายงาน"
          />
        }
      />
    </>
  );
};

export default React.memo(CardReportDrugUseRecord);
