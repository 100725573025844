import { useState, useMemo, useEffect } from "react";
import { Dimmer, Loader } from "semantic-ui-react";

// UX
import CardPayingUX from "./CardPayingUX";
import SubRepeaterPayMethodUX from "./SubRepeaterPayMethodUX";

// Common
import { ModConfirm, ModInfo } from "react-lib/apps/common";

// ---
import { CARD_MEDICAL_FEE } from "./CardMedicalFee";

// Utils

type CardPayingProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  BillPaymentSequence?: Record<string, any> | null;
  // data
  patientInfo?: Record<string, any>;
  citizenDetail: {
    text: string;
    patientId: string;
  };
  defaultAmount?: Partial<{
    price: number;
    reimbursable: number;
    cashonly: number;
    discount: number;
    payable: number;
  }> | null;
  patientOptionData: {
    options: {
      key: string;
      value: string;
      text: any;
    }[];
    value: string;
  };
  invoiceData: {
    items: any[];
    ids: any[];
    orderBy: string;
  };
  invoiceItemByItems: any[];
  // CommonInterface
  errorMessage?: Record<string, any>;
  // BillingHistory
  invoiceId?: number;
  historyInvoiceData?: any;
  // config
  openPaying?: boolean;
  simpleReceipt?: boolean;
  // callback
  onSuccess?: () => any;
};

type ModType = {
  message: any;
  status: "success" | "confirm" | "error_payment";
};

const CardPaying = (props: CardPayingProps) => {
  // Mod
  const [openModInfo, setOpenModInfo] = useState<ModType | null>(null);
  // Loading
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Use Effect
  useEffect(() => {
    if (props.BillPaymentSequence?.sumAmount) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [props.BillPaymentSequence?.sumAmount]);

  useEffect(() => {
    if (!!props.errorMessage?.[CARD_MEDICAL_FEE] && props.openPaying) {
      setIsLoading(false);
    }
  }, [props.openPaying, props.errorMessage]);

  //  Use Memo
  const masterAccountOptions = useMemo(() => {
    return (props.BillPaymentSequence?.masterAccountList || []).map(
      (item: any) => ({ key: item.id, text: item.name, value: item.id })
    );
  }, [props.BillPaymentSequence?.masterAccountList]);

  const payments = useMemo(() => {
    return (props.BillPaymentSequence?.paymentMethodList || []).filter(
      (item: any) => item.type !== "1"
    );
  }, [props.BillPaymentSequence?.paymentMethodList]);

  const masterEDCOptions = useMemo(() => {
    return (props.BillPaymentSequence?.masterEDCList || []).map(
      (item: any) => ({ key: item.id, text: item.name, value: item.id })
    );
  }, [props.BillPaymentSequence?.masterEDCList]);

  const handlePay = (data: any) => {
    let ids =
      props.invoiceData.ids || props.historyInvoiceData?.items[0]?.id_list;
    const byItems: any[] = props.invoiceItemByItems || [];

    if (props.simpleReceipt) {
      ids = props.invoiceData.items.map((item) => item.id);
    } else if (props.invoiceData.orderBy === "Item") {
      ids = props.invoiceData.items.map((item) => ({
        id: item.id,
        pay: item.pay,
      }));
    } else {
      ids = props.invoiceData.ids.map((id) => {
        const item = byItems.find((item) => item.id === id);
        return {
          id: item.id,
          pay: item.pay,
        };
      });
    }

    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "BillPayment",
        action: "pay",
        idList: ids,
        patientNameLang: props.patientOptionData.value,
        callback: setOpenModInfo,
        loading: setIsLoading,
        invoice: props.invoiceId ? props.invoiceId : null,
        orderBy: props.invoiceData.orderBy,
        confirmData: data.confirmData,
        simpleReceipt: props.simpleReceipt,
      },
    });
  };

  const handleAddPaymentMethod = (type: number) => {
    props.onEvent({
      message: "HandleSetPaymentMethodList",
      params: {
        list: [
          ...(props.BillPaymentSequence?.paymentMethodList || []),
          { type, paymentNo: "", paymentTarget: 0, value: 0 },
        ],
      },
    });
  };

  const handleChangeInput = (e: any, v: any) => {
    props.onEvent({
      message: "HandleChangePaymentMethodList",
      params: { e, v },
    });
  };

  const handleKeyDownCouponNumber = (e: any, v: any) => {
    props.onEvent({
      message: "HandleKeyDownCouponNumber",
      params: { e, v, idList: props.invoiceData.ids },
    });
  };

  const handleRemovePaymentMethod = (index: number) => {
    const filter = props.BillPaymentSequence?.paymentMethodList.filter(
      (_: any, idx: number) => idx !== index
    );
    props.onEvent({
      message: "HandleSetPaymentMethodList",
      params: { list: filter },
    });
  };

  const handleCloseModSuccess = async () => {
    setIsLoading(false);
    setOpenModInfo(null);

    await props.setProp("BillPaymentSequence", null);

    props.onSuccess?.();
  };

  const handleChangeAutoEDC = (e: any, data: any) => {
    let value = typeof data.checked === "boolean" ? data.checked : data.value;
    const detail = props.BillPaymentSequence?.autoEDC || {};

    if (data.name === "check" && value) {
      detail.traceId = "";
      detail.approvalCode = "";
    }

    detail[data.name] = value;

    props.setProp("BillPaymentSequence.autoEDC", { ...detail });
  };

  const handleCloseModInfo = () => {
    setOpenModInfo(null);
  };

  const handleConfirm = () => {
    handlePay({ confirmData: openModInfo?.message });
  };

  const handleKeyDownNumber = (e: any) => {
    if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  return (
    <>
      <CardPayingUX
        // data
        patientNameAndHN={props.patientInfo?.name_code}
        citizenID={props.citizenDetail.text}
        sumAmount={
          props.BillPaymentSequence?.sumAmount || props.defaultAmount || {}
        }
        cashAmountText={props.BillPaymentSequence?.cashAmountText || ""}
        transferText={props?.BillPaymentSequence?.transferText || ""}
        cardText={props.BillPaymentSequence?.cardText || ""}
        chequeText={props.BillPaymentSequence?.chequeText || ""}
        couponText={props.BillPaymentSequence?.couponText || ""}
        installmentText={props.BillPaymentSequence?.installmentText || ""}
        totalText={props.BillPaymentSequence?.totalText || ""}
        rawTotalText={props.BillPaymentSequence?.rawTotalText || ""}
        oweText={props.BillPaymentSequence?.oweText || ""}
        rawOweText={props.BillPaymentSequence?.rawOweText || ""}
        roundOffText={props.BillPaymentSequence?.roundOffText || ""}
        patientValue={props.patientOptionData.value}
        // AutoEDC
        check={props.BillPaymentSequence?.autoEDC?.check}
        approvalType={props.BillPaymentSequence?.autoEDC?.approvalType}
        traceId={props.BillPaymentSequence?.autoEDC?.traceId}
        approvalCode={props.BillPaymentSequence?.autoEDC?.approvalCode}
        showAutoEDC={
          props.BillPaymentSequence?.requireApprovalCodeCheck
            ?.require_approval_code
        }
        // options
        patientOptions={props.patientOptionData.options}
        // callback
        onChangeCashAmountText={(e: any) =>
          props.setProp("BillPaymentSequence.cashAmountText", e.target.value)
        }
        onChangeAutoEDC={handleChangeAutoEDC}
        onEnterCashAmountText={(e: any) => {
          if (e.key === "Enter")
            props.onEvent({
              message: "RunSequence",
              params: { sequence: "BillPayment" },
            });
        }}
        onBlurCashAmountText={(e: any) => {
          props.onEvent({
            message: "RunSequence",
            params: { sequence: "BillPayment", isUpdateCashAmount: true },
          });
        }}
        cannotPay={props?.BillPaymentSequence?.cannotPay}
        onPay={handlePay}
        onAddPaymentMethod={handleAddPaymentMethod}
        // Element
        Loader={
          <Dimmer
            active={isLoading && openModInfo?.status !== "confirm"}
            inverted
          >
            <Loader />
          </Dimmer>
        }
        // ErrorMessage={
        // <ErrorMessage error={props.BillPaymentSequence?.payErrorMessage} />
        // }
        SubRepeaterPayMethod={
          <>
            {payments.map((item: any, index: number) => (
              <SubRepeaterPayMethodUX
                type={item.type}
                paymentNo={item.paymentNo}
                paymentTarget={item.paymentTarget}
                value={item.value}
                couponMessage={item.couponMessage}
                masterAccountOptions={masterAccountOptions}
                masterEDCOptions={masterEDCOptions}
                onChange={(e: any, v: any) =>
                  handleChangeInput(e, { ...v, index: index + 1 })
                }
                onKeyDownCouponNumber={(e: any, v: any) =>
                  handleKeyDownCouponNumber(e, { ...v, index: index + 1 })
                }
                onClose={() => handleRemovePaymentMethod(index + 1)}
                handleKeyDownNumber={handleKeyDownNumber}
              />
            ))}
          </>
        }
      />

      {openModInfo?.status === "success" && (
        <ModInfo
          open={true}
          titleColor={"green"}
          titleName={"บันทึกสำเร็จ"}
          onApprove={handleCloseModSuccess}
        />
      )}

      <ModInfo
        open={openModInfo?.status === "error_payment"}
        titleColor={"red"}
        titleName={"แจ้งเตือน!! ไม่สามารถรับชำระเงินได้"}
        alertText={
          <div style={{ padding: "5px 0" }}>
            <p style={{ textAlign: "center", marginBottom: "0.5rem" }}>
              <strong>
                การยืนยันสิทธิไม่สำเร็จ
                <br />
                เนื่องจากไม่พบรหัส Approval Code
                <br />
                กรุณาตรวจสอบอีกครั้ง
                <br />
              </strong>
            </p>
            <b>
              <p style={{ color: "red" }}>
                Approval Code: -<br />
                ยอดชำระ:{" "}
                {
                  props.BillPaymentSequence?.edcTransactionAmount?.total_amount
                }{" "}
                บาท
              </p>
            </b>
          </div>
        }
        onApprove={handleCloseModInfo}
      />

      <ModConfirm
        openModal={openModInfo?.status === "confirm"}
        titleName="ยืนยันการชำระเงิน!"
        titleColor="blue"
        size="mini"
        denyButtonColor="red"
        denyButtonText="ยกเลิก"
        approveButtonColor="green"
        approveButtonText="ยืนยัน"
        content={
          <div
            style={{
              fontWeight: "bold",
              textAlign: "center",
              margin: "-0.75rem 0px -1.5rem",
            }}
          >
            <Dimmer active={isLoading} inverted>
              <Loader />
            </Dimmer>
            <p style={{ textAlign: "center", marginBottom: "0.5rem" }}>
              <strong>
                กรุณาตรวจสอบ Approval Code และยอดชำระอีกครั้งก่อนกดยืนยัน
                <br />
              </strong>
            </p>
            <b>
              <p style={{ color: "darkblue" }}>
                Approval Code: {openModInfo?.message?.approvalCode}
                <br />
                ยอดชำระ:{" "}
                {
                  props.BillPaymentSequence?.edcTransactionAmount?.total_amount
                }{" "}
                บาท
              </p>
            </b>
          </div>
        }
        onApprove={handleConfirm}
        onDeny={handleCloseModInfo}
        onCloseWithDimmerClick={handleCloseModInfo}
      />
    </>
  );
};

export default CardPaying;
