import React, {
  useEffect,
  useState,
  useMemo,
  SyntheticEvent,
  useCallback,
  CSSProperties,
} from "react";
import {
  Modal,
  Button,
  Checkbox,
  Icon,
  Dropdown,
  Pagination,
  PaginationProps,
  CheckboxProps,
  Label,
  TextArea,
  Form,
  Input,
  Popup,
} from "semantic-ui-react";

// UX
import CardSupplyOrderUX from "./CardSupplyOrderUX";
import CardSupplyOrderSearchUX from "./CardSupplyOrderSearchUX";

// Common
import { ErrorMessage, ModConfirm } from "react-lib/apps/common";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";

// Utils
import { formatDatetime } from "react-lib/utils";
import { get_status_color, get_type_color } from "./dispensing/UtilMSD";

// CSS
import "./CardSupplyOrder.scss";

// if (type == 'HOME_OPD') {
//   result = 'สั่งเวชภัณฑ์สำหรับขายผู้ป่วยนอก'
// } else if (type == 'HOME_IPD') {
//   result = 'สั่งเวชภัณฑ์สำหรับขายผู้ป่วยใน'
// } else if (type == 'STAT') {
//   result = 'สั่งเวชภัณฑ์ฉุกเฉินสำหรับผู้ป่วย'
// } else if (type == 'ONE_DAY') {
//   result = 'สั่งเวชภัณฑ์เพื่อใช้กับผู้ป่วย'
// } else if (type == 'REFILL_OPD') {
//   result = 'บันทึกใช้เวชภัณฑ์ในการรักษาผู้ป่วยนอก'
// } else if (type == 'REFILL_IPD') {
//   result = 'บันทึกใช้เวชภัณฑ์ในการรักษาผู้ป่วยใน'
// } else if (type == 'COST_CENTER') {
// COST_CENTER จะ เบิกไม่ได้ เลย สั่งได้ทันที
//   result = 'บันทึกใช้เวชภัณฑ์เหมารวมในหัตถการ'

// } else {
//   result = ''
// }
type CardSupplyOrderProps = {
  setProp: any;
  onEvent: any;
  // seq
  runSequence: any;
  OrderSupplySequence: any;
  // options
  supplyOrderModeOptions: any[] | undefined;
  supplyOrderEligibilityOptions: any[] | undefined;
  masterOptions: any;
  // CommonInterface
  django?: any;
  errorMessage: any;
  successMessage: any;
  selectedEncounter: any;
  buttonLoadCheck: any;
  // Med Reconcile
  medReconcileCheck?: any;
  divisionType?: any;
  medReconcileIndex?: number;
  // config
  isEdit: boolean;
  // style
  tableStyle?: CSSProperties;
  cardStyle?: CSSProperties;
};

// Const
const MODE_OPTIONS = [
  {
    key: "EQUIPMENT",
    value: "EQUIPMENT",
    text: "EQUIPMENT",
  },
  {
    key: "SUPPLY",
    value: "SUPPLY",
    text: "SUPPLY",
  },
];

const CLAIM_RESULT = {
  1: "เบิกได้",
  2: "เบิกไม่ได้ เนื่องจากตอบคำถามไม่ครบ",
  3: "เบิกไม่ได้ เนื่องจากตอบคำถามไม่ตรงตามเงื่อนไข",
  4: "เบิกไม่ได้ เนื่องจากประเภทรายการสั่งหรือตัวผลิตภัณฑ์ไม่ตรงเงื่อนไข",
};

const CLAIM_RESULT_ICON = {
  1: { name: "check", color: "green" },
  2: { name: "warning sign", color: "yellow" },
  3: { name: "remove", color: "red" },
  4: { name: "minus", color: "gray" },
};

const MOD_CANCEL = "ModalCalcel";

const OPD_TYPE = ["HOME_OPD", "REFILL_OPD", "COST_CENTER"];

const CARD_SUPPLY_ORDER = "CardSupplyOrder";

const CardSupplyOrder = (props: CardSupplyOrderProps) => {
  const [priceTotal, setPriceTotal] = useState<number>(0);
  const [priceClaimable, setPriceClaimable] = useState<number>(0);
  const [priceNonClaimable, setPriceNonClaimable] = useState<number>(0);
  const [priceDiscount, setPriceDiscount] = useState<number>(0);
  const [needCalculation, setNeedCalculation] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [openModCancel, setOpenModCancel] = useState(false);
  const [note, setNote] = useState<any>("");

  useEffect(() => {
    if (
      props.errorMessage?.OrderSupply !== null &&
      props.errorMessage?.OrderSupply !== undefined
    ) {
      setLoading(false);
    }

    if (
      props.successMessage?.OrderSupply !== null &&
      props.successMessage?.OrderSupply !== undefined
    ) {
      setLoading(false);
      setCompleted(true);

      setTimeout(() => {
        setCompleted(false);
      }, 2000);
    }
  }, [props.successMessage?.OrderSupply, props.errorMessage?.OrderSupply]);

  useEffect(() => {
    props.runSequence({ sequence: "OrderSupply", restart: true });

    props.onEvent({ message: "getChoicesSupplyOrderMode", params: {} });
    props.onEvent({ message: "getChoicesSupplyOrderEligibility", params: {} });
    props.onEvent({ message: "getDivisionDetail", params: {} });
  }, []);

  useEffect(() => {
    let total: number = 0;
    let claimable: number = 0;
    let nonClaimable: number = 0;
    let discount: number = 0;

    const isCostCenter =
      props.OrderSupplySequence?.supplyOrder?.type === "COST_CENTER";
    const totalKey = isCostCenter ? "value_total" : "price_total";

    props.OrderSupplySequence?.supplyOrder?.items?.forEach((item: any) => {
      total += item[totalKey] || 0;
      claimable += item.price_claimable || 0;
      nonClaimable += item.price_non_claimable || 0;
      discount += item.price_discount || 0;
    });

    setPriceTotal(total);
    setPriceClaimable(claimable);
    setPriceNonClaimable(nonClaimable);
    setPriceDiscount(discount);
  }, [props.OrderSupplySequence?.supplyOrder]);

  // useEffect(() => {
  //   if (props.setProp && props.supplyOrderModeOptions?.length > 0) {
  //     props.setProp("OrderSupplySequence.searchMode", "ANY");
  //   }
  // }, [props.supplyOrderModeOptions]);

  useEffect(() => {
    if (props.OrderSupplySequence?.searchManufacturer?.length >= 2) {
      props.onEvent({
        message: "checkManufacturer",
        params: {
          name_icontains: props.OrderSupplySequence?.searchManufacturer,
        },
      });
    }
  }, [props.OrderSupplySequence?.searchManufacturer]);

  useEffect(() => {
    if (props.successMessage?.[MOD_CANCEL]) {
      setOpenModCancel(false);
    }
  }, [props.successMessage?.[MOD_CANCEL]]);

  // MedReconcile Check
  useEffect(() => {
    console.log("useEffect props.selectedEncounter: ", props.selectedEncounter);
    console.log("useEffect props.divisionType: ", props.divisionType);
    const roleTypes = props.django?.user?.role_types || [];

    if (
      ["หอผู้ป่วย"].includes(props.divisionType) &&
      (roleTypes || []).includes("DOCTOR") &&
      props.selectedEncounter?.type === "IPD"
    ) {
      const callFrom = "DPI";

      if (props.selectedEncounter?.id) {
        props.onEvent({
          message: "GetMedReconcileCheck",
          params: { encounter: props.selectedEncounter, callForm: callFrom },
        });
      }
    } else {
      console.warn("ไม่ได้มาจาก หอผู้ป่วย เปิด CardSupplyOrder: ");
    }
  }, [props.selectedEncounter, props.django, props.divisionType]);

  const handleEditChange = useCallback((idx: number, key: string) => {
    props.runSequence({
      sequence: "OrderSupply",
      action: "edit",
      idx,
      key,
    });
  }, []);

  const handleChangeValueByIndex = useCallback(
    async (data: { index: number; key: string; value: any }) => {
      const items = props.OrderSupplySequence?.supplyOrder?.items || [];

      items[data.index][data.key] = data.value;

      await props.setProp(`OrderSupplySequence.supplyOrder.items`, [...items]);
    },
    [props.OrderSupplySequence?.supplyOrder?.items]
  );

  const supplyOrderType = useMemo(() => {
    return props.OrderSupplySequence?.supplyOrder?.type || "";
  }, [props.OrderSupplySequence?.supplyOrder?.type]);

  const ribbonColor = useMemo(
    () => get_type_color(supplyOrderType),
    [supplyOrderType]
  );
  const statusColor = useMemo(
    () => get_status_color(props.OrderSupplySequence?.supplyOrder?.status),
    [props.OrderSupplySequence?.supplyOrder?.status]
  );

  // SupplyOrder
  const supplyOrderItems = useMemo(
    () =>
      props.OrderSupplySequence?.supplyOrder?.items.map(
        (item: any, idx: any) => {
          let msg = (CLAIM_RESULT as any)[item.claim_result];

          msg =
            typeof item.claim_result_description !== "undefined"
              ? `${msg}\n${item.claim_result_description}`
              : msg;

          return {
            ...item,
            no: idx + 1,
            condition: (
              <>
                {!!(CLAIM_RESULT_ICON as any)[item.claim_result] && (
                  <Popup
                    trigger={
                      <Icon
                        name={
                          (CLAIM_RESULT_ICON as any)[item.claim_result].name
                        }
                        color={
                          (CLAIM_RESULT_ICON as any)[item.claim_result].color
                        }
                        style={{
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                    }
                    offset={-10}
                    content={msg}
                    position="bottom left"
                  />
                )}
              </>
            ),
            quantity_request: (
              <div style={{ width: "100%", height: "100%" }}>
                <Input
                  ref={(ref: any) =>
                    ref && (ref.inputRef.current.style.textAlign = "right")
                  }
                  min="0"
                  type="number"
                  value={item.quantity_request}
                  fluid={true}
                  onChange={(e, data) => {
                    handleChangeValueByIndex({
                      index: idx,
                      key: "quantity_request",
                      value: data.value,
                    });

                    setNeedCalculation(
                      supplyOrderType === "COST_CENTER" ? false : true
                    );
                  }}
                  onBlur={() => {
                    handleEditChange(idx, "quantity_request");

                    setNeedCalculation(
                      supplyOrderType === "COST_CENTER" ? false : true
                    );
                  }}
                />
              </div>
            ),
            eligibility_type: (
              <div className="dropdown overflow">
                <Dropdown
                  fluid
                  selection
                  upward={false}
                  options={props.supplyOrderEligibilityOptions}
                  value={
                    props.supplyOrderEligibilityOptions?.find(
                      (options: any) => options.key === item.eligibility_type
                    )?.value
                  }
                  onChange={async (e: any, { value }: any) => {
                    let key = props.supplyOrderEligibilityOptions?.find(
                      (item: any) => item.value === value
                    )?.key;

                    await handleChangeValueByIndex({
                      index: idx,
                      key: "eligibility_type",
                      value: key,
                    });

                    handleEditChange(idx, "eligibility_type");
                  }}
                />
              </div>
            ),
            mode: !item.mode_fixed ? (
              <div className="dropdown overflow">
                <Dropdown
                  fluid
                  selection
                  upward={false}
                  options={MODE_OPTIONS}
                  value={item.mode}
                  onChange={async (e: any, { value }: any) => {
                    await handleChangeValueByIndex({
                      index: idx,
                      key: "mode",
                      value,
                    });

                    handleEditChange(idx, "mode");
                  }}
                />
              </div>
            ) : (
              item.mode
            ),
            delete: (
              <Button
                circular
                icon="trash alternate"
                color="red"
                onClick={() => {
                  let items: any[] =
                    props.OrderSupplySequence?.supplyOrder?.items || [];

                  items.splice(idx, 1);

                  props.setProp("OrderSupplySequence.supplyOrder.items", [
                    ...items,
                  ]);
                }}
              />
            ),
            price_claimable:
              item.price_claimable === 0 ? "0.00" : item.price_claimable,
            price_non_claimable:
              item.price_non_claimable === 0
                ? "0.00"
                : item.price_non_claimable,
          };
        }
      ),
    [
      props.supplyOrderEligibilityOptions,
      props.OrderSupplySequence?.supplyOrder?.items,
    ]
  );

  // SupplySequence
  const supplyOrderSearchItems = useMemo(
    () =>
      props.OrderSupplySequence?.supplyList?.map((item: any, idx: any) => {
        return {
          ...item,
          selected: (
            <div>
              <Checkbox
                key={item.id}
                checked={item.selected}
                onChange={(e, data) => {
                  let supplyList = [...props.OrderSupplySequence?.supplyList];

                  supplyList[idx].selected = data.checked;

                  if (supplyList[idx].count === 0) {
                    supplyList[idx].count = 1;
                  }
                  // console.log(" data.checked", data.checked, "index", idx)
                  props.setProp("OrderSupplySequence.supplyList", supplyList);
                }}
              />
            </div>
          ),
          count: (
            <div style={{ width: "100%", height: "100%" }}>
              <Input
                ref={(ref: any) =>
                  ref &&
                  ((ref.inputRef.current.style.textAlign = "right"),
                  (ref.inputRef.current.style.border = "none"))
                }
                min="0"
                type="number"
                value={item.count}
                disabled={!item.selected}
                fluid={true}
                onClick={(e: any) => {
                  e.target.select();
                }}
                onChange={(e) => {
                  let supplyList = [...props.OrderSupplySequence?.supplyList];

                  supplyList[idx].count = e.target.value;

                  props.setProp("OrderSupplySequence.supplyList", supplyList);
                }}
              />
            </div>
          ),
          package_size: (
            <div style={{ textAlign: "right" }}>{item.package_size}</div>
          ),
          price_unit: (
            <div style={{ textAlign: "right" }}>{item.price_unit}</div>
          ),
          favorite: (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {item?.favorLoading ? (
                <Icon
                  loading
                  name="spinner"
                  color={item.favorite ? "yellow" : "black"}
                />
              ) : (
                <Icon
                  name="star"
                  color={item.favorite ? "yellow" : "black"}
                  onClick={() => {
                    let supplyList = [...props.OrderSupplySequence?.supplyList];

                    const supply = supplyList[idx];

                    if (supply.favorite) {
                      props.runSequence({
                        sequence: "OrderSupply",
                        action: "unfavorite",
                        id: supply?.id,
                      });
                    } else {
                      props.runSequence({
                        sequence: "OrderSupply",
                        action: "favorite",
                        id: supply?.id,
                      });
                    }
                  }}
                />
              )}
            </div>
          ),
        };
      }),
    [props.OrderSupplySequence?.supplyList, props.runSequence, props.setProp]
  );

  const historySupplyOrder = useMemo(() => {
    return props.OrderSupplySequence?.historySupplyOrder?.filter((item: any) =>
      props?.selectedEncounter?.type === "OPD"
        ? OPD_TYPE?.includes(item.type)
        : !OPD_TYPE?.includes(item.type)
    );
  }, [props.OrderSupplySequence?.historySupplyOrder, props?.selectedEncounter]);

  const historySupplyOrderItems = useMemo(
    () =>
      historySupplyOrder?.map((item: any, idx: any) => ({
        ...item,
        type: item.type === "COST_CENTER" ? "Cost center" : item.type,
        order_time: formatDatetime(item.order_time, false),
        order_div: item?.order_div_name
          ? item?.order_div_name
          : item?.order_div,
        order_perform_div: item?.order_perform_div_name
          ? item?.order_perform_div_name
          : item?.order_perform_div,
        order_perform_time: formatDatetime(item.order_perform_time, false),
        status: item?.supply_transfer_status || item?.status,
      })),
    [props.OrderSupplySequence?.historySupplyOrder, historySupplyOrder]
  );

  const handleChangeValue = (key: string) => (e: any, v: any) => {
    props.setProp(`OrderSupplySequence.supplyOrder.${key}`, v.value);
  };

  const handleApprove = () => {
    const supplyOrder = props.OrderSupplySequence?.supplyOrder;

    props.runSequence({
      sequence: "OrderSupply",
      action: "cancel",
      card: MOD_CANCEL,
      cardKey: CARD_SUPPLY_ORDER,
      pk: supplyOrder?.id || supplyOrder?.pk,
      note: note,
      isEdit: props?.isEdit,
    });
  };

  const handleClose = () => {
    setOpenModCancel(false);
    setNote(null);
    props.setProp(`errorMessage.${MOD_CANCEL}`, null);
  };

  const handleGetTrPropsHistory = (state: any, rowInfo: any) => {
    // console.log("rowInfo", rowInfo);
    if (rowInfo && rowInfo.row) {
      let background =
        rowInfo?.original?.id ===
        (props.OrderSupplySequence?.searchHistorySelected !== null
          ? props.OrderSupplySequence?.searchHistorySelected?.id
          : null)
          ? "blueSelectedRow"
          : "";

      // console.log("background", background);
      return {
        onClick: (e: any) => {
          props.runSequence({
            sequence: "OrderSupply",
            action: "selectedHistory",
            original: rowInfo?.original,
          });
        },

        className: background,
      };
    } else {
      return {};
    }
  };

  const handleSelectFavorite = async (e: any, data: any) => {
    await props.setProp(
      "OrderSupplySequence.isFavorite",
      !!!props.OrderSupplySequence?.isFavorite
    );

    props.runSequence({
      sequence: "OrderSupply",
      action: "advanceSearch",
    });
  };

  return (
    <div style={{ padding: "0 10px 2rem", ...(props.cardStyle || {}) }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.runSequence({
            sequence: "OrderSupply",
            action: "clear",
          });
        }}
        error={props.errorMessage?.OrderSupply}
        success={null}
      />

      {props.OrderSupplySequence?.showSupplyList > 0 ? (
        <Modal
          size={"large"}
          open={true}
          closeOnDimmerClick={true}
          style={{ left: "unset !important" }}
          onClose={() =>
            props.setProp("OrderSupplySequence.showSupplyList", false)
          }
        >
          <CardSupplyOrderSearchUX
            data={supplyOrderSearchItems || []}
            modeOptions={props.supplyOrderModeOptions}
            searchMode={props.OrderSupplySequence?.searchMode || ""}
            // value
            searchManufacturer={
              props.OrderSupplySequence?.searchManufacturer || ""
            }
            // Manuf
            manufacturerOptions={props.OrderSupplySequence?.manufacturerOptions}
            openManufacturer={props.OrderSupplySequence?.manufactureHaveResult}
            manufactureLoading={props.OrderSupplySequence?.manufactureLoading}
            searchGroup={props.OrderSupplySequence?.searchGroup || ""}
            searchName={props.OrderSupplySequence?.searchSupply || ""}
            nameLoading={props.OrderSupplySequence?.nameLoading}
            // favor
            isFavorite={props.OrderSupplySequence?.isFavorite}
            // นยะรนืห
            groupOptions={[{ key: 0, value: "ANY", text: "ANY" }]}
            // callback
            // Mode
            onChangeSearchMode={(e: any, data: any) =>
              props.setProp("OrderSupplySequence.searchMode", data?.value)
            }
            onCloseSearchManufacturer={() =>
              props.setProp("OrderSupplySequence.manufactureHaveResult", false)
            }
            onChangeSearchManufacturer={(e: any, data: any) => {
              console.log(" onChangeSearchManufacturer");
              props.setProp(
                "OrderSupplySequence.searchManufacturer",
                e?.target?.value
              );
            }}
            // searchQuery
            searchQueryManufacturer={
              props.OrderSupplySequence?.searchManufacturer || ""
            }
            onSearchChangeQueryManufacturer={(e: any) => {
              console.log("onSearchChangeQueryManufacturer");
              props.setProp(
                "OrderSupplySequence.searchManufacturer",
                e?.target?.value
              );
            }}
            //กองทุน
            onChangeSearchGroup={(e: any, data: any) =>
              props.setProp("OrderSupplySequence.searchGroup", data?.value)
            }
            // ชื่อ
            onChangeSearchName={(e: any) =>
              props.setProp("OrderSupplySequence.searchSupply", e.target.value)
            }
            // Button
            // ค้นหา
            onSearch={() => {
              props.runSequence({
                sequence: "OrderSupply",
                action: "advanceSearch",
              });
            }}
            // แสดงภาพ
            onClickListView={null}
            onSelectFavorite={handleSelectFavorite}
            // ยกเลิก
            onCancel={() => {
              props.setProp("OrderSupplySequence.showSupplyList", false);
            }}
            // เลือก
            onSelect={() => {
              props.runSequence({
                sequence: "OrderSupply",
                action: "selectedAndAdd",
              });

              setNeedCalculation(
                supplyOrderType === "COST_CENTER" ? false : true
              );
            }}
          />
        </Modal>
      ) : null}

      <CardSupplyOrderUX
        // options
        masterOptions={props.masterOptions}
        // data
        searchFromHistorySearch={
          props.OrderSupplySequence?.searchFromHistorySearch
        }
        searchToHistorySearch={props.OrderSupplySequence?.searchToHistorySearch}
        isUseRangeSearch={props.OrderSupplySequence?.isUseRangeSearch}
        searchBtnLoading={props.OrderSupplySequence?.searchBtnLoading}
        searchHistorySelected={props.OrderSupplySequence?.searchHistorySelected}
        isStatus={props.OrderSupplySequence?.supplyOrder?.status}
        ribbonColor={ribbonColor}
        titleSupplyOrder={
          supplyOrderType === "COST_CENTER" ? "Cost center" : supplyOrderType
        }
        supplyOrderType={supplyOrderType}
        editCode={props.OrderSupplySequence?.supplyOrder?.code}
        editEncounter={props.OrderSupplySequence?.supplyOrder?.encounter_number}
        editDoctor={props.OrderSupplySequence?.supplyOrder?.doctor}
        editOrderDiv={
          props.masterOptions?.division?.find(
            (options: any) =>
              options.key === props.OrderSupplySequence?.supplyOrder?.order_div
          )?.text
        }
        editOrderPerformDiv={
          props.masterOptions?.division?.find(
            (options: any) =>
              options.key ===
              props.OrderSupplySequence?.supplyOrder?.order_perform_div
          )?.text
        }
        selectedOrderDiv={props.OrderSupplySequence?.supplyOrder?.order_div}
        selectedPerform={
          props.OrderSupplySequence?.supplyOrder?.order_perform_div
        }
        showAddDivision={["REFILL_OPD", "REFILL_IPD", "COST_CENTER"].includes(
          supplyOrderType || ""
        )}
        styleEditSupply={
          supplyOrderType || props.OrderSupplySequence?.searchHistorySelected
            ? { display: "block" }
            : { display: "none" }
        }
        type={props?.selectedEncounter?.type}
        mainQuatityDisabled={!props.OrderSupplySequence?.selectedSupply}
        searchSupply={props.OrderSupplySequence?.searchSupply || ""}
        showSupplyList={false}
        qty={props.OrderSupplySequence?.qty || 0}
        supplyOrderItems={supplyOrderItems || []}
        historySupplyOrder={historySupplyOrderItems || []}
        priceTotal={priceTotal}
        priceClaimable={priceClaimable}
        priceNonClaimable={priceNonClaimable}
        priceDiscount={priceDiscount}
        tableStyle={props.tableStyle}
        // style={{ zoom: "94%" }}
        saveDisabled={needCalculation}
        loadingSave={loading}
        status={props.OrderSupplySequence?.supplyOrder?.status}
        // config
        isEdit={props.isEdit}
        isCostCenter={supplyOrderType === "COST_CENTER"}
        // callback
        getTrPropsHistory={handleGetTrPropsHistory}
        handleChangeValue={handleChangeValue}
        handleHistorySearch={() => {
          props.runSequence({
            sequence: "OrderSupply",
            action: "searchHistory",
            firstPage: true,
          });
        }}
        // ประวัติ การสั่งเวชภัฒฑ์
        handleToggleUseRange={(e: any, data: CheckboxProps) => {
          console.log(" data", data);
          props.setProp("OrderSupplySequence.isUseRangeSearch", data.checked);
        }}
        handleToHistory={(a: any) => {
          props.setProp("OrderSupplySequence.searchToHistorySearch", a);
        }}
        handleFromHistory={(a: any) => {
          props.setProp("OrderSupplySequence.searchFromHistorySearch", a);
        }}
        onNewSupplyOrder={(e: any, data: any) => {
          setNeedCalculation(data.name === "COST_CENTER" ? false : true);

          props.setProp("OrderSupplySequence", {
            ...props.OrderSupplySequence,
            searchHistorySelected: null,
            supplyOrder: { items: [], pk: 0, type: data.name },
          });
        }}
        onChangeSearchSupply={(e: any) =>
          props.setProp("OrderSupplySequence.searchSupply", e.target.value)
        }
        search={() =>
          props.runSequence({
            sequence: "OrderSupply",
            action: "search",
          })
        }
        getTdPropsSupplyOrder={(
          state: any,
          rowInfo: any,
          column: any,
          instance: any
        ) => {
          // console.log(" getTdPropsSupplyOrder state", state);
          // console.log(" getTdPropsSupplyOrder rowInfo", rowInfo);
          // console.log(" getTdPropsSupplyOrder column", column);
          // console.log(" getTdPropsSupplyOrder instance", instance);

          return {
            style: {
              cursor: "pointer",
              overflow: "visible",
            },
          };
        }}
        // Need to handle validation
        onChangeQty={(e: any) => {
          let value;
          try {
            value = parseInt(e.target.value);
          } catch {
            value = 0;
          }
          props.setProp("OrderSupplySequence.qty", value);
        }}
        addToOrder={(e: any) => {
          props.runSequence({
            sequence: "OrderSupply",
            action: "add",
          });
          setNeedCalculation(true);
        }}
        onCancel={() => setOpenModCancel(true)}
        onCalc={() => {
          props.runSequence({
            sequence: "OrderSupply",
            action: "calc",
            onSuccess: () => setNeedCalculation(false),
          });
        }}
        onSave={() => {
          setLoading(true);

          props.runSequence({
            sequence: "OrderSupply",
            action: "save",
          });
        }}
        // Element
        button_save={completed ? <Icon name="check"></Icon> : "บันทึก"}
        paginationComponent={
          props.OrderSupplySequence?.historySupplyTotalPage > 0 ? (
            <Pagination
              activePage={props.OrderSupplySequence?.historySupplyActivePage}
              onPageChange={(
                e: SyntheticEvent,
                { activePage }: PaginationProps
              ) => {
                if (
                  props.OrderSupplySequence?.historySupplyActivePage !==
                  activePage
                ) {
                  // Fetch data
                  props.runSequence({
                    sequence: "OrderSupply",
                    action: "searchHistory",
                    toActivePage: activePage,
                  });
                }
              }}
              totalPages={props.OrderSupplySequence?.historySupplyTotalPage}
            />
          ) : null
        }
        buttonEdit={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "OrderSupply",
                action: "update",
                card: CARD_SUPPLY_ORDER,
                buttonLoadKey: `${CARD_SUPPLY_ORDER}_UPDATE`,
                isEdit: props?.isEdit,
              });
            }}
            // data
            paramKey={`${CARD_SUPPLY_ORDER}_UPDATE`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_SUPPLY_ORDER}_UPDATE`]
            }
            // config
            name="UPDATE"
            title="แก้ไขรายการสั่งจ่ายเวชภัณฑ์"
            color="yellow"
            disabled={needCalculation}
            style={{ minWidth: "max-content", width: "100%" }}
          />
        }
        errorMessage={
          <ErrorMessage
            error={props?.errorMessage?.[CARD_SUPPLY_ORDER]?.error}
          />
        }
        // Label
        statusSupplyOrder={
          props.OrderSupplySequence?.supplyOrder?.status ? (
            <Label color={statusColor}>
              {" "}
              {props.OrderSupplySequence?.supplyOrder?.status}{" "}
            </Label>
          ) : null
        }
      />

      <ModMedReconcileAlert
        // medReconcileCheck={props.medReconcileCheck}
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        onApprove={() => {
          props.setProp(
            "medReconcileCheck.open_card_med_reconciliation",
            false
          );

          props.onEvent({
            message: "OpenMedReconcileFromWarning",
            params: {
              medReconcileCheck: props.medReconcileCheck,
              selecteRecordViewIndex: props.medReconcileIndex,
            },
          });
        }}
      >
        {props.medReconcileCheck?.med_reconciliation != null
          ? `Med Reconcile ${props.medReconcileCheck?.med_reconciliation_label} ยังไม่ได้ถูกรับทราบโดยแพทย์`
          : `ยังไม่ได้สร้าง Medication Reconciliation สำหรับ PlanTransfer ${props.medReconcileCheck?.plan_transfer_id}`}
      </ModMedReconcileAlert>

      <ModConfirm
        openModal={openModCancel}
        titleName="ยืนยันยกเลิก ?"
        onDeny={handleClose}
        onCloseWithDimmerClick={handleClose}
        onApprove={handleApprove}
        content={
          <div style={{ margin: "-1rem 0px -1.5rem" }}>
            <div style={{ marginBottom: "0.5rem" }}>
              <ErrorMessage error={props?.errorMessage?.[MOD_CANCEL]?.error} />
            </div>
            <div> สาเหตุ </div>
            <Form>
              <TextArea
                style={{
                  width: "100%",
                  height: "100%",
                  border: "solid #cccccc 1px",
                  marginTop: "5px",
                }}
                rows={4}
                value={note}
                onChange={(e, v) => setNote(v.value)}
              />
            </Form>
          </div>
        }
      />
    </div>
  );
};

export default React.memo(CardSupplyOrder);
