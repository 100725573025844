import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { Dropdown, Input, Checkbox } from "semantic-ui-react";
import { DateTextBox } from "react-lib/apps/common";

const CardFoodRequestUX = (props: any) => {
  return (
    <div>
      <div style={{ height: "50px" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(4, 1fr)",
            gridTemplateColumns: "repeat(34, 1fr)",
          }}
        >
          <div
            style={{
              gridRow: "2/4",
              gridColumn: "2/18",
              fontWeight: "bold",
              fontSize: "17px",
              borderBottom: "3px solid grey",
            }}
          >
            สั่งอาหาร
          </div>
          <div
            style={{
              gridRow: "2/4",
              gridColumn: "18/34",
              fontSize: "17px",
              borderBottom: "3px solid grey",
              textAlign: "right",
            }}
          >
            {props.status}
          </div>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ height: "650px", width: "60%" }}>
          <div style={{ height: "45px" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(1, 1fr)",
                gridTemplateColumns: "repeat(21, 1fr)",
              }}
            >
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "2/5",
                  fontWeight: "bold",
                }}
              >
                <label>ชนิดอาหาร</label>
                <label style={{ color: "red" }}>*</label>
              </div>
              <div style={{ gridRow: "1/2", gridColumn: "5/21" }}>
                <Dropdown
                  clearable={true}
                  disabled={
                    props.formReadOnly || !!props.FoodRequestSequence?.id
                  }
                  fluid={true}
                  name="food_type"
                  onChange={props.onChangeData}
                  options={props.foodRequestOptions?.foodTypeOptions || []}
                  search={true}
                  selection={true}
                  value={props.FoodRequestSequence?.food_type || ""}
                ></Dropdown>
              </div>
            </div>
          </div>
          <div
            style={{
              display: props.foodType === "R" ? "" : "none",
              height: props.foodType === "R" ? "45px" : "0px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(1, 1fr)",
                gridTemplateColumns: "repeat(21, 1fr)",
              }}
            >
              <div style={{ gridRow: "1/2", gridColumn: "5/21" }}>
                <Dropdown
                  allowAdditions={true}
                  clearable={true}
                  disabled={
                    props.formReadOnly || !!props.FoodRequestSequence?.id
                  }
                  fluid={true}
                  multiple={true}
                  name="routine_type"
                  onAddItem={props.onAddOptions}
                  onChange={props.onChangeData}
                  options={props.foodRequestOptions?.routineTypeOptions || []}
                  search={true}
                  selection={true}
                  value={props.FoodRequestSequence?.routine_type || []}
                ></Dropdown>
              </div>
            </div>
          </div>
          <div style={{ height: "45px" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(1, 1fr)",
                gridTemplateColumns: "repeat(21, 1fr)",
              }}
            >
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "2/5",
                  fontWeight: "bold",
                }}
              >
                <label>จำนวนมื้อ</label>
                <label style={{ color: "red" }}>*</label>
              </div>
              <div style={{ gridRow: "1/2", gridColumn: "5/8" }}>
                <Input
                  disabled={
                    props.formReadOnly || !!props.FoodRequestSequence?.id
                  }
                  fluid={true}
                  name="quantity"
                  onChange={props.onChangeData}
                  type="number"
                  value={props.FoodRequestSequence?.quantity || ""}
                ></Input>
              </div>
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "8/21",
                  paddingLeft: "20px",
                }}
              >
                <Checkbox
                  checked={props.FoodRequestSequence?.is_extra_meal}
                  disabled={
                    props.formReadOnly || !!props.FoodRequestSequence?.id
                  }
                  label="มื้อ Extra"
                  name="is_extra_meal"
                  onChange={props.onChangeData}
                ></Checkbox>
                <label style={{ color: "red" }}>*</label>
              </div>
            </div>
          </div>
          <div style={{ height: "45px" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(1, 1fr)",
                gridTemplateColumns: "repeat(21, 1fr)",
              }}
            >
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "2/5",
                  fontWeight: "bold",
                }}
              >
                <label>มื้ออาหาร</label>
                <label style={{ color: "red" }}>*</label>
              </div>
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "5/21",
                  display: props.foodType === "G" ? "" : "none",
                }}
              >
                {props.generalTimeComponent}
              </div>
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "5/21",
                  display: props.foodType === "R" ? "" : "none",
                }}
              >
                <Dropdown
                  clearable={true}
                  disabled={
                    props.formReadOnly || !!props.FoodRequestSequence?.id
                  }
                  fluid={true}
                  multiple={true}
                  name="routine_time"
                  onChange={props.onChangeData}
                  options={props.foodRequestOptions?.routineTimeOptions || []}
                  search={true}
                  selection={true}
                  value={props.FoodRequestSequence?.routine_time || []}
                ></Dropdown>
              </div>
            </div>
          </div>
          <div
            style={{
              display: props.foodType === "R" ? "" : "none",
              height: props.foodType === "R" ? "145px" : "0px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(3, 1fr)",
                gridTemplateColumns: "repeat(21, 1fr)",
              }}
            >
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "2/5",
                  fontWeight: "bold",
                }}
              >
                <label>ปริมาตร</label>
                <label style={{ color: "red" }}>*</label>
              </div>
              <div style={{ gridRow: "1/2", gridColumn: "5/8" }}>
                <Input
                  disabled={
                    props.formReadOnly || !!props.FoodRequestSequence?.id
                  }
                  fluid={true}
                  name="routine_size"
                  onChange={props.onChangeData}
                  style={{ display: props.foodType === "R" ? "" : "none" }}
                  type="number"
                  value={props.FoodRequestSequence?.routine_size || ""}
                ></Input>
              </div>
              <div style={{ gridRow: "1/2", gridColumn: "8/12" }}>
                <Dropdown
                  clearable={true}
                  disabled={
                    props.formReadOnly || !!props.FoodRequestSequence?.id
                  }
                  fluid={true}
                  name="routine_unit"
                  onChange={props.onChangeData}
                  options={props.foodRequestOptions?.routineUnitOptions || []}
                  search={true}
                  selection={true}
                  style={{ display: props.foodType === "R" ? "" : "none" }}
                  value={props.FoodRequestSequence?.routine_unit || ""}
                ></Dropdown>
              </div>
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "12/15",
                  fontWeight: "bold",
                  textAlign: "right",
                  paddingRight: "20px",
                }}
              >
                <label>ความเข้มข้น</label>
              </div>
              <div style={{ gridRow: "1/2", gridColumn: "15/17" }}>
                <Input
                  disabled={
                    props.formReadOnly || !!props.FoodRequestSequence?.id
                  }
                  fluid={true}
                  name="intensity"
                  onChange={props.onChangeData}
                  style={{ display: props.foodType === "R" ? "" : "none" }}
                  type="number"
                  value={props.FoodRequestSequence?.intensity || ""}
                ></Input>
              </div>
              <div style={{ gridRow: "1/2", gridColumn: "17/21" }}>
                <Dropdown
                  clearable={true}
                  disabled={
                    props.formReadOnly || !!props.FoodRequestSequence?.id
                  }
                  fluid={true}
                  name="intensity_unit"
                  onChange={props.onChangeData}
                  options={props.foodRequestOptions?.intensityUnitOptions || []}
                  search={true}
                  selection={true}
                  style={{ display: props.foodType === "R" ? "" : "none" }}
                  value={props.FoodRequestSequence?.intensity_unit || ""}
                ></Dropdown>
              </div>
              <div
                style={{
                  gridRow: "2/3",
                  gridColumn: "2/5",
                  fontWeight: "bold",
                }}
              >
                <label>Mixture</label>
              </div>
              <div style={{ gridRow: "2/3", gridColumn: "5/12" }}>
                <Dropdown
                  allowAdditions={true}
                  clearable={true}
                  disabled={
                    props.formReadOnly || !!props.FoodRequestSequence?.id
                  }
                  fluid={true}
                  multiple={true}
                  name="mixture"
                  onAddItem={props.onAddOptions}
                  onChange={props.onChangeData}
                  options={props.foodRequestOptions?.mixtureOptions || []}
                  search={true}
                  selection={true}
                  style={{ display: props.foodType === "R" ? "" : "none" }}
                  value={props.FoodRequestSequence?.mixture || []}
                ></Dropdown>
              </div>
              <div
                style={{
                  gridRow: "2/3",
                  gridColumn: "12/15",
                  fontWeight: "bold",
                  textAlign: "right",
                  paddingRight: "20px",
                }}
              >
                <label>Volume</label>
              </div>
              <div style={{ gridRow: "2/3", gridColumn: "15/21" }}>
                <Input
                  disabled={
                    props.formReadOnly || !!props.FoodRequestSequence?.id
                  }
                  fluid={true}
                  name="mixture_volume"
                  onChange={props.onChangeData}
                  style={{ display: props.foodType === "R" ? "" : "none" }}
                  value={props.FoodRequestSequence?.mixture_volume || ""}
                ></Input>
              </div>
              <div
                style={{
                  gridRow: "3/4",
                  gridColumn: "2/5",
                  fontWeight: "bold",
                }}
              >
                <label>หมายเหตุ Mixture</label>
              </div>
              <div style={{ gridRow: "3/4", gridColumn: "5/21" }}>
                <Input
                  disabled={
                    props.formReadOnly || !!props.FoodRequestSequence?.id
                  }
                  fluid={true}
                  name="mixture_remark"
                  onChange={props.onChangeData}
                  style={{ display: props.foodType === "R" ? "" : "none" }}
                  value={props.FoodRequestSequence?.mixture_remark || ""}
                ></Input>
              </div>
            </div>
          </div>
          <div style={{ height: "225px" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(5, 1fr)",
                gridTemplateColumns: "repeat(21, 1fr)",
              }}
            >
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "2/5",
                  fontWeight: "bold",
                }}
              >
                <label>เสริม</label>
              </div>
              <div style={{ gridRow: "1/2", gridColumn: "5/21" }}>
                <Dropdown
                  allowAdditions={true}
                  clearable={true}
                  disabled={props.formReadOnly}
                  fluid={true}
                  multiple={true}
                  name="supplement_food"
                  onAddItem={props.onAddOptions}
                  onChange={props.onChangeData}
                  options={
                    props.foodRequestOptions?.supplementFoodOptions || []
                  }
                  search={true}
                  selection={true}
                  value={props.FoodRequestSequence?.supplement_food || []}
                ></Dropdown>
              </div>
              <div
                style={{
                  gridRow: "2/3",
                  gridColumn: "2/5",
                  fontWeight: "bold",
                }}
              >
                <label>แพ้</label>
              </div>
              <div style={{ gridRow: "2/3", gridColumn: "5/21" }}>
                <Dropdown
                  allowAdditions={true}
                  clearable={true}
                  disabled={props.formReadOnly}
                  fluid={true}
                  multiple={true}
                  name="allergy_food"
                  onAddItem={props.onAddOptions}
                  onChange={props.onChangeData}
                  options={props.foodRequestOptions?.allergyFoodOptions || []}
                  search={true}
                  selection={true}
                  value={props.FoodRequestSequence?.allergy_food || []}
                ></Dropdown>
              </div>
              <div
                style={{
                  gridRow: "3/4",
                  gridColumn: "2/5",
                  fontWeight: "bold",
                }}
              >
                <label>ข้อจำกัด</label>
              </div>
              <div style={{ gridRow: "3/4", gridColumn: "5/21" }}>
                <Dropdown
                  allowAdditions={true}
                  clearable={true}
                  disabled={props.formReadOnly}
                  fluid={true}
                  multiple={true}
                  name="restriction_food"
                  onAddItem={props.onAddOptions}
                  onChange={props.onChangeData}
                  options={
                    props.foodRequestOptions?.restrictionFoodOptions || []
                  }
                  search={true}
                  selection={true}
                  value={props.FoodRequestSequence?.restriction_food || []}
                ></Dropdown>
              </div>
              <div
                style={{
                  gridRow: "4/5",
                  gridColumn: "2/5",
                  fontWeight: "bold",
                }}
              >
                <label>หมายเหตุ</label>
              </div>
              <div style={{ gridRow: "4/5", gridColumn: "5/21" }}>
                <Input
                  disabled={props.formReadOnly}
                  fluid={true}
                  name="remark"
                  onChange={props.onChangeData}
                  value={props.FoodRequestSequence?.remark || ""}
                ></Input>
              </div>
              <div
                style={{
                  gridRow: "5/6",
                  gridColumn: "2/5",
                  fontWeight: "bold",
                }}
              >
                <label>NPO ล่วงหน้า</label>
              </div>
              <div style={{ gridRow: "5/6", gridColumn: "5/21" }}>
                <DateTextBox
                  disabled={props.formReadOnly}
                  minDate={props.betoday}
                  onChange={props.onChangeNPODate}
                  style={{ width: "100%" }}
                  value={props.FoodRequestSequence?.npo_date || ""}
                ></DateTextBox>
              </div>
            </div>
          </div>
          <div style={{ height: "50px" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(2, 1fr)",
                gridTemplateColumns: "repeat(21, 1fr)",
              }}
            >
              <div
                style={{ gridRow: "2/3", gridColumn: "5/21", display: "flex" }}
              >
                <div>{props.buttonSave}</div>
                <div>{props.buttonResume}</div>
                <div>{props.buttonNPO}</div>
                <div>{props.buttonOff}</div>
                <div>{props.buttonRenew}</div>
                <div>{props.buttonClear}</div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "40%", paddingRight: "40px" }}>
          <div>{props.specialDiet}</div>
          <div>{props.nutrition}</div>
        </div>
      </div>
    </div>
  );
};

export default CardFoodRequestUX;

export const screenPropsDefault = {};

/* Date Time : Mon Jul 11 2022 12:22:24 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"50px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(4, 1fr)\",\"gridTemplateColumns\":\"repeat(34, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"18/34\", fontSize: \"17px\", borderBottom: \"3px solid grey\", textAlign: \"right\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "สั่งอาหาร"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"2/18\", fontWeight: \"bold\", fontSize: \"17px\", borderBottom: \"3px solid grey\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 114,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"650px\", width: \"60%\" }"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "label": "divgrid",
      "name": "div",
      "parent": 128,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(5, 1fr)\",\"gridTemplateColumns\":\"repeat(21, 1fr)\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"2/5\", fontWeight: \"bold\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 119,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"5/21\" }"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "ชนิดอาหาร"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": " *"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Dropdown",
      "parent": 18,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "food_type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.foodRequestOptions?.foodTypeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.food_type || \"\""
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 121,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"5/21\" }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Dropdown",
      "parent": 24,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "routine_type"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.foodRequestOptions?.routineTypeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.routine_type || []"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"2/5\", fontWeight: \"bold\" }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนมื้อ"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": " *"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 123,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"5/8\" }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Input",
      "parent": 29,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "quantity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.quantity || \"\""
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 123,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"8/21\", paddingLeft: \"20px\" }"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Checkbox",
      "parent": 31,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.FoodRequestSequence?.is_extra_meal"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "label": {
          "type": "value",
          "value": "มื้อ Extra"
        },
        "name": {
          "type": "value",
          "value": "is_extra_meal"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": " *"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 125,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"2/5\", fontWeight: \"bold\" }"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "label",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "มื้ออาหาร"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": " *"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 125,
      "props": {
        "children": {
          "type": "code",
          "value": "props.generalTimeComponent"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"5/21\", display: props.foodType === \"G\" ? \"\" : \"none\" }  "
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 125,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"5/21\", display: props.foodType === \"R\" ? \"\" : \"none\" }  "
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Dropdown",
      "parent": 41,
      "props": {
        "allowAdditions": {
          "type": "value",
          "value": ""
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "routine_time"
        },
        "onAddItem": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.foodRequestOptions?.routineTimeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.routine_time || []"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 127,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"2/5\", fontWeight: \"bold\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "ปริมาตร"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": " *"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 127,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"5/8\" }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Input",
      "parent": 46,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "routine_size"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"R\" ? \"\" : \"none\" }"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.routine_size || \"\""
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 127,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"8/12\" }"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 127,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"12/15\",  fontWeight: \"bold\", textAlign: \"right\", paddingRight: \"20px\" }"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 127,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"15/17\" }"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 127,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"17/21\" }"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "ความเข้มข้น"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Dropdown",
      "parent": 56,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "routine_unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.foodRequestOptions?.routineUnitOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"R\" ? \"\" : \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.routine_unit || \"\""
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Input",
      "parent": 58,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "intensity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"R\" ? \"\" : \"none\" }"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.intensity || \"\""
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Dropdown",
      "parent": 59,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "intensity_unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.foodRequestOptions?.intensityUnitOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"R\" ? \"\" : \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.intensity_unit || \"\""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 127,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"2/5\", fontWeight: \"bold\" }"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "Mixture"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 127,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"5/12\" }"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Dropdown",
      "parent": 70,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "mixture"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.foodRequestOptions?.mixtureOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"R\" ? \"\" : \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.mixture || []"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 127,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"12/15\",  fontWeight: \"bold\", textAlign: \"right\", paddingRight: \"20px\" }"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "label",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "Volume"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 127,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"15/21\" }"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Input",
      "parent": 74,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "mixture_volume"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"R\" ? \"\" : \"none\" }"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.mixture_volume || \"\""
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 127,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"2/5\", fontWeight: \"bold\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ Mixture"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 127,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"5/21\" }"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Input",
      "parent": 81,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "mixture_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"R\" ? \"\" : \"none\" }"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.mixture_remark || \"\""
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"2/5\", fontWeight: \"bold\" }"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "เสริม"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 12,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"5/21\" }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Dropdown",
      "parent": 85,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "supplement_food"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.foodRequestOptions?.supplementFoodOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.supplement_food || []"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"2/5\", fontWeight: \"bold\" }"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "label",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": "แพ้"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 12,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"5/21\" }"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Dropdown",
      "parent": 89,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "allergy_food"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.foodRequestOptions?.allergyFoodOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.allergy_food || []"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"2/5\", fontWeight: \"bold\" }"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "label",
      "parent": 91,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อจำกัด"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 12,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"5/21\" }"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "Dropdown",
      "parent": 93,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "restriction_food"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.foodRequestOptions?.restrictionFoodOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.restriction_food || []"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"2/5\", fontWeight: \"bold\" }"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "label",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 12,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"5/21\" }"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Input",
      "parent": 97,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.remark || \"\""
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"2/5\", fontWeight: \"bold\" }"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "label",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": "NPO ล่วงหน้า"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 12,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"5/21\" }"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 102,
      "name": "DateTextBox",
      "parent": 101,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly"
        },
        "minDate": {
          "type": "code",
          "value": "props.betoday"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNPODate"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.npo_date || ''"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"50px\" }"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "label": "divgrid",
      "name": "div",
      "parent": 103,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(2, 1fr)\",\"gridTemplateColumns\":\"repeat(21, 1fr)\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 104,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"5/21\", display: \"flex\" }"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 114,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"40%\", paddingRight: \"40px\" }"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "code",
          "value": "props.specialDiet"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nutrition"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"45px\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "label": "divgrid",
      "name": "div",
      "parent": 118,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(21, 1fr)\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"R\" ? \"\" : \"none\", height: props.foodType === \"R\" ? \"45px\" : \"0px\" }"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "label": "divgrid",
      "name": "div",
      "parent": 120,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(21, 1fr)\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"45px\" }"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "label": "divgrid",
      "name": "div",
      "parent": 122,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(21, 1fr)\"}"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"45px\" }"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "label": "divgrid",
      "name": "div",
      "parent": 124,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(21, 1fr)\"}"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"R\" ? \"\" : \"none\", height: props.foodType === \"R\" ? \"145px\" : \"0px\" }"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "label": "divgrid",
      "name": "div",
      "parent": 126,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(3, 1fr)\",\"gridTemplateColumns\":\"repeat(21, 1fr)\"}"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"225px\" }"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 107,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 107,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonClear"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 107,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonNPO"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 107,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonResume"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 107,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonOff"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 107,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonRenew"
        }
      },
      "seq": 134,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardFoodRequestUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
