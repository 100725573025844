import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown,
  Checkbox,
  Input,
  TextArea,
  Radio
} from 'semantic-ui-react'

const ANES_PreANES_05_AnesProb = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", height: "100%" ,padding:"10px"}}>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",height:"100%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            style={{ display: "flex", justifyContent: "flex-end"}}>
            
            <div
              style={{ display: props.PreAnestheticSequence?.status_label === "Confirm" ?  "none" : "",}}>
              {props?.buttonSave}
            </div>
          </div>
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(20, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              style={{ gridRow: "2/4", gridColumn: "1/39"}}>
              <div
                style={{ display: "flex",height:"100%" }}>
                
                <TextArea
                  name="AnestheticProblemsConsiderationsRemark"
                  onChange={props.onChangeData}
                  rows={3}
                  style={{border: "solid 1px rgb(200, 200, 200, 0.5)", width:"100%",}}
                  value={props.PreAnestheticSequence?.data?.AnestheticProblemsConsiderationsRemark || ""}>
                </TextArea>
              </div>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "1/6"}}>
              <label
                style={{display: "flex", fontSize:"16px",fontWeight:"Bold",alignItems:"center",height:"100%",width:"100%"}}>
                Mallampati grade
              </label>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "6/11",height:"100%",width:"100%" }}>
              <Dropdown
                name="MallampatiGrade"
                onChange={props.onChangeData}
                options={props.masterOptions?.preanes_mallampati || []}
                selection={true}
                style={{height:"100%",width:"100%"}}
                value={props.PreAnestheticSequence?.data?.MallampatiGrade || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "13/16",height:"100%",width:"100%"}}>
              <div
                style={{ display: "flex",  alignItems:"center" ,height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.IsEmergency || false}
                  name="IsEmergency"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Emergency
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "16/22"}}>
              <label
                style={{display: "flex", fontSize:"16px",fontWeight:"Bold",alignItems:"center",height:"100%",width:"100%"}}>
                Anesthetic Informed Consent
              </label>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "22/26"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Radio
                  checked={props.PreAnestheticSequence?.data?.IsAnestheticInformedConsent === true}
                  name="IsAnestheticInformedConsent"
                  onChange={props.onChangeData}
                  value={!props.PreAnestheticSequence?.data?.IsAnestheticInformedConsent}>
                </Radio>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Yes
                </label>
                <Radio
                  checked={props.PreAnestheticSequence?.data?.IsAnestheticInformedConsent === false}
                  name="IsAnestheticInformedConsent"
                  onChange={props.onChangeData}
                  value={!props.PreAnestheticSequence?.data?.IsAnestheticInformedConsent}>
                </Radio>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  No
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "25/28",height:"100%",width:"100%"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <label
                  style={{display: "flex", marginRight:"20px",fontSize:"16px",fontWeight:"Bold",alignItems:"center",height:"100%",width:"100%"}}>
                  NPO
                </label>
                <div
                  style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                  
                  <Radio
                    checked={props.PreAnestheticSequence?.data?.IsNPO === true}
                    name="IsNPO"
                    onChange={props.onChangeData}
                    value={!props.PreAnestheticSequence?.data?.IsNPO}>
                  </Radio>
                  <label
                    style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                    Yes
                  </label>
                  <Radio
                    checked={props.PreAnestheticSequence?.data?.IsNPO === false}
                    name="IsNPO"
                    onChange={props.onChangeData}
                    value={!props.PreAnestheticSequence?.data?.IsNPO}>
                  </Radio>
                  <label
                    style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                    No
                  </label>
                </div>
              </div>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "29/39",height:"100%",width:"100%"}}>
              <Input
                disabled={!props.PreAnestheticSequence?.data?.IsNPO}
                name="NPO"
                onChange={props.onChangeData}
                style={{height:"100%",width:"100%"}}
                value={props.PreAnestheticSequence?.data?.NPO || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "7/7", gridColumn: "1/6"}}>
              <label
                style={{display: "flex", fontSize:"16px",fontWeight:"Bold",alignItems:"center",height:"100%",width:"100%"}}>
                Anestheic plan
              </label>
            </div>
            <div
              style={{ gridRow: "7/7", gridColumn: "6/16"}}>
              <Input
                name="AnestheicPlan"
                onChange={props.onChangeData}
                style={{height:"100%",width:"100%"}}
                value={props.PreAnestheticSequence?.data?.AnestheicPlan || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "17/25"}}>
              <label
                style={{display: "flex", fontSize:"16px",fontWeight:"Bold",alignItems:"center",height:"100%",width:"100%"}}>
                Alternative of anesthesia discussed
              </label>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "25/39"}}>
              <Input
                name="AlternativeOfAnesthesiaDiscussed"
                onChange={props.onChangeData}
                style={{height:"100%",width:"100%"}}
                value={props.PreAnestheticSequence?.data?.AlternativeOfAnesthesiaDiscussed || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "9/10", gridColumn: "1/6"}}>
              <label
                style={{display: "flex", fontSize:"16px",fontWeight:"Bold",alignItems:"center",height:"100%",width:"100%"}}>
                Premedication
              </label>
            </div>
            <div
              style={{ gridRow: "9/10", gridColumn: "6/15"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Radio
                  checked={props.PreAnestheticSequence?.data?.PremedicationNone === false}
                  name="PremedicationNone"
                  onChange={props.onChangeData}
                  value={!props.PreAnestheticSequence?.data?.PremedicationNone}>
                </Radio>
                <label
                  style={{ marginLeft:"10px",marginRight:"20px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  None
                </label>
                <Radio
                  checked={props.PreAnestheticSequence?.data?.PremedicationNone === true}
                  name="PremedicationNone"
                  onChange={props.onChangeData}
                  value={!props.PreAnestheticSequence?.data?.PremedicationNone}>
                </Radio>
                <label
                  style={{ marginLeft:"10px",marginRight:"20px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Yes
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "9/10", gridColumn: "10/39"}}>
              <Dropdown
                clearable={true}
                disabled={!props.PreAnestheticSequence?.data?.PremedicationNone}
                multiple={true}
                name="PremedicationNoneRemark"
                onChange={props.onChangeData}
                options={props.masterOptions?.drugName || []}
                search={true}
                selection={true}
                style={{height:"100%",width:"100%"}}
                value={props.PreAnestheticSequence?.data?.PremedicationNoneRemark || []}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "1/6"}}>
              <label
                style={{display: "flex", fontSize:"16px",fontWeight:"Bold",alignItems:"center",height:"100%",width:"100%"}}>
                Blood prepared
              </label>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "6/7", display: "flex", alignItems: "center"}}>
              
              <Radio
                checked={props.PreAnestheticSequence?.data?.BloodPreparedStatus === true}
                name="BloodPreparedStatus"
                onChange={props.onChangeData}
                value={!props.PreAnestheticSequence?.data?.BloodPreparedStatus }>
              </Radio>
              <label
                style={{ marginLeft:"10px",marginRight:"20px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                Yes
              </label>
              <Radio
                checked={props.PreAnestheticSequence?.data?.BloodPreparedStatus === false}
                name="BloodPreparedStatus"
                onChange={props.onChangeData}
                value={!props.PreAnestheticSequence?.data?.BloodPreparedStatus }>
              </Radio>
              <label
                style={{ marginLeft:"10px",marginRight:"20px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                No
              </label>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "8/12"}}>
              
              <Input
                disabled={!props.PreAnestheticSequence?.data?.BloodPreparedStatus}
                name="BloodPrepared"
                onChange={props.onChangeData}
                style={{height:"100%",width:"100%"}}
                value={props.PreAnestheticSequence?.data?.BloodPrepared || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "13/18"}}>
              <label
                style={{display: "flex", fontSize:"16px",fontWeight:"Bold",alignItems:"center",height:"100%",width:"100%"}}>
                Plan of postoperative care
              </label>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "19/25"}}>
              <Dropdown
                name="PlanOfPostoperativeCare"
                onChange={props.onChangeData}
                options={props.masterOptions?.anes_plan_of_postope || []}
                selection={true}
                style={{height:"100%",width:"100%"}}
                value={props.PreAnestheticSequence?.data?.PlanOfPostoperativeCare || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "26/31"}}>
              <label
                style={{display: "flex", fontSize:"16px",fontWeight:"Bold",alignItems:"center",height:"100%",width:"100%"}}>
                Ambulatory patient with companion
              </label>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "31/39"}}>
              <Input
                name="AmbulatoryPatientWithCompanion"
                onChange={props.onChangeData}
                style={{height:"100%",width:"100%"}}
                value={props.PreAnestheticSequence?.data?.AmbulatoryPatientWithCompanion || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "13/14", gridColumn: "1/12"}}>
              <label
                style={{display: "flex", fontSize:"16px",fontWeight:"Bold",alignItems:"center",height:"100%",width:"100%"}}>
                Plan of postoperative pain management
              </label>
            </div>
            <div
              style={{ gridRow: "13/14", gridColumn: "12/19"}}>
              <Dropdown
                name="PlanOfPostoperativePainManagement"
                onChange={props.onChangeData}
                options={props.masterOptions?.preanes_plan_of_post || []}
                selection={true}
                style={{height:"100%",width:"100%"}}
                value={props.PreAnestheticSequence?.data?.PlanOfPostoperativePainManagement || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/15"}}>
              <label
                style={{display: "flex", fontSize:"16px",fontWeight:"Bold",alignItems:"center",height:"100%",width:"100%"}}>
                Anesthetic problems / considerations
              </label>
            </div>
          </div>
        </div>
      </div>
    )
}

export default ANES_PreANES_05_AnesProb

export const screenPropsDefault = {}

/* Date Time : Fri Apr 07 2023 08:56:59 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" ,padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",height:\"100%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(20, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"1/39\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"6/11\",height:\"100%\",width:\"100%\" }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "Mallampati grade"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Dropdown",
      "parent": 5,
      "props": {
        "name": {
          "type": "value",
          "value": "MallampatiGrade"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_mallampati || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.MallampatiGrade || \"\""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"13/16\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\" ,height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Checkbox",
      "parent": 25,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.IsEmergency || false"
        },
        "name": {
          "type": "value",
          "value": "IsEmergency"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "Emergency"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"16/22\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthetic Informed Consent"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"22/26\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"25/28\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "label",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "Yes"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"29/39\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Input",
      "parent": 37,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.IsNPO"
        },
        "name": {
          "type": "value",
          "value": "NPO"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.NPO || \"\""
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",height:\"100%\" }"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "TextArea",
      "parent": 40,
      "props": {
        "name": {
          "type": "value",
          "value": "AnestheticProblemsConsiderationsRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "rows": {
          "type": "code",
          "value": "3"
        },
        "style": {
          "type": "code",
          "value": "{border: \"solid 1px rgb(200, 200, 200, 0.5)\", width:\"100%\",}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AnestheticProblemsConsiderationsRemark || \"\""
        }
      },
      "seq": 41,
      "void": true
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/7\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/7\", gridColumn: \"6/16\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "Anestheic plan"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Input",
      "parent": 43,
      "props": {
        "name": {
          "type": "value",
          "value": "AnestheicPlan"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AnestheicPlan || \"\""
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"17/25\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"25/39\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "Alternative of anesthesia discussed"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Input",
      "parent": 49,
      "props": {
        "name": {
          "type": "value",
          "value": "AlternativeOfAnesthesiaDiscussed"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AlternativeOfAnesthesiaDiscussed || \"\""
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"6/15\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "label",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "Premedication"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"10/39\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"13/18\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"19/25\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"26/31\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"31/39\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "label",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "Blood prepared"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "label",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan of postoperative care"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Dropdown",
      "parent": 63,
      "props": {
        "name": {
          "type": "value",
          "value": "PlanOfPostoperativeCare"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.anes_plan_of_postope || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PlanOfPostoperativeCare || \"\""
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "label",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": "Ambulatory patient with companion"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Input",
      "parent": 65,
      "props": {
        "name": {
          "type": "value",
          "value": "AmbulatoryPatientWithCompanion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AmbulatoryPatientWithCompanion || \"\""
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"1/12\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"12/19\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Dropdown",
      "parent": 73,
      "props": {
        "name": {
          "type": "value",
          "value": "PlanOfPostoperativePainManagement"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_plan_of_post || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PlanOfPostoperativePainManagement || \"\""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan of postoperative pain management"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/15\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthetic problems / considerations"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "Yes"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",marginRight:\"20px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "None"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",marginRight:\"20px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "label",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "No"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "label",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": "NPO"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", marginRight:\"20px\",fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "label",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": "Yes"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "label",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": "No"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "Radio",
      "parent": 78,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PremedicationNone === true"
        },
        "name": {
          "type": "value",
          "value": "PremedicationNone"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.PremedicationNone"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Radio",
      "parent": 78,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PremedicationNone === false"
        },
        "name": {
          "type": "value",
          "value": "PremedicationNone"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.PremedicationNone"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "Radio",
      "parent": 33,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.IsAnestheticInformedConsent === true"
        },
        "name": {
          "type": "value",
          "value": "IsAnestheticInformedConsent"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.IsAnestheticInformedConsent"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "Radio",
      "parent": 33,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.IsAnestheticInformedConsent === false"
        },
        "name": {
          "type": "value",
          "value": "IsAnestheticInformedConsent"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.IsAnestheticInformedConsent"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Radio",
      "parent": 92,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.IsNPO === true"
        },
        "name": {
          "type": "value",
          "value": "IsNPO"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.IsNPO"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Radio",
      "parent": 92,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.IsNPO === false"
        },
        "name": {
          "type": "value",
          "value": "IsNPO"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.IsNPO"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"6/7\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"8/12\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "Input",
      "parent": 105,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.BloodPreparedStatus"
        },
        "name": {
          "type": "value",
          "value": "BloodPrepared"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BloodPrepared || \"\""
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Radio",
      "parent": 103,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BloodPreparedStatus === true"
        },
        "name": {
          "type": "value",
          "value": "BloodPreparedStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.BloodPreparedStatus "
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "Radio",
      "parent": 103,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BloodPreparedStatus === false"
        },
        "name": {
          "type": "value",
          "value": "BloodPreparedStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.BloodPreparedStatus "
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "label",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "Yes"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",marginRight:\"20px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "label",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "No"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",marginRight:\"20px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 111,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.status_label === \"Confirm\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Dropdown",
      "parent": 58,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.PremedicationNone"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PremedicationNoneRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.drugName || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PremedicationNoneRemark || []"
        }
      },
      "seq": 113,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PreANES_05_AnesProb",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
