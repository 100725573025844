import React, { useMemo, useState, useCallback, useEffect } from "react";
import { Button, Dropdown, Input } from "semantic-ui-react";

// UX
import CardClaimInformationDetailUX from "./CardClaimInformationDetailUX";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import EmployeeToken from "../../common/EmployeeToken";
import { ModConfirm } from "react-lib/apps/common";

// Interface
import {
  Key16Files,
  State,
  RunSequence,
  TABLE_16_FILES,
} from "./sequence/ExportEClaimFile";
import { CARD_EXPORT_E_CLAIM_FILE } from "./CardExportEClaimFile";
import { Table } from "react-lib/frameworks/Table";

type CardClaimInformationDetailProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: RunSequence;
  // options
  masterOptions?: Record<string, any>;
  // data
  userTokenize?: any;
  claimInfoDetail?:
    | (Record<string, any> & {
        files: Record<Key16Files, any[]>;
      })
    | null;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  // callback
  onClose: () => void;
};

const BUTTON_ACTIONS = {
  EDIT: "EDIT_REF_NO",
  SAVE: "SAVE",
};

const GENDER_OPTIONS = [
  { key: "M", value: "M", text: "ชาย" },
  { key: "F", value: "F", text: "หญิง" },
];

const CARD_CLAIM_INFORMATION_DETAIL = "CardClaimInformationDetail";

const CardClaimInformationDetail = (props: CardClaimInformationDetailProps) => {
  const [selectedType, setSelectedType] = useState<Key16Files>("ins");
  const [refNo, setRefNo] = useState<string>("");
  const [editModeIndex, setEditModeIndex] = useState<number | null>(null);
  const [openModConfirm, setOpenModConfirm] = useState<Record<
    string,
    any
  > | null>(null);

  useEffect(() => {
    setRefNo(props.claimInfoDetail?.ref_no || "");
    setEditModeIndex(null);
  }, [props.claimInfoDetail?.ref_no]);

  const handleChangeValue = useCallback(
    (data: { id: string; text: string; index: number; options: any[] }) =>
      async (e: any, v: any) => {
        props.runSequence({
          sequence: "ExportEClaimFile",
          action: "CHANGE_VALUE",
          ...data,
          type: selectedType,
          value: v.value,
        });
      },
    [selectedType]
  );

  const headers = useMemo(() => {
    return [...TABLE_16_FILES[selectedType].headers, ""];
  }, [selectedType]);

  const keys = useMemo(() => {
    return [...TABLE_16_FILES[selectedType].headers, "edit"].join(",");
  }, [selectedType]);

  const items = useMemo(() => {
    const files = props.claimInfoDetail?.files;

    return (files?.[selectedType] || []).map((item, index) => {
      const districtOptions = item.province
        ? (props.masterOptions?.district || []).filter(
            (option: any) => option.relate === item.province
          )
        : [];
      const editing = editModeIndex === index;

      return {
        ...item,
        CHANGWAT: editing ? (
          <Dropdown
            value={item.province || ""}
            placeholder="เลือกจังหวัด"
            selection={true}
            basic={true}
            clearable={true}
            search={true}
            fluid={true}
            options={props.masterOptions?.province || []}
            // callback
            onChange={handleChangeValue({
              id: "province",
              text: "CHANGWAT",
              index,
              options: props.masterOptions?.province,
            })}
          />
        ) : (
          item.CHANGWAT || "กรุณาระบุ"
        ),
        AMPHUR: editing ? (
          <Dropdown
            value={item.district || ""}
            placeholder="เลือกอำเภอ"
            selection={true}
            basic={true}
            clearable={true}
            search={true}
            fluid={true}
            options={districtOptions}
            // callback
            onChange={handleChangeValue({
              id: "district",
              text: "AMPHUR",
              index,
              options: districtOptions,
            })}
          />
        ) : (
          item.AMPHUR || "กรุณาระบุ"
        ),
        SEX: editing ? (
          <Dropdown
            value={item.gender || ""}
            basic={true}
            placeholder="เลือกเพศ"
            selection={true}
            fluid={true}
            clearable={true}
            options={GENDER_OPTIONS}
            // callback
            onChange={handleChangeValue({
              id: "gender",
              text: "SEX",
              index,
              options: GENDER_OPTIONS,
            })}
          />
        ) : (
          item.SEX || "กรุณาระบุ"
        ),
        edit: (
          <div style={{ display: "grid", placeContent: "center" }}>
            <Button
              size="mini"
              color={editing ? "green" : "yellow"}
              onClick={() => {
                if (editing) {
                  setOpenModConfirm(item);
                } else {
                  setEditModeIndex(index);
                }
              }}
            >
              {editing ? "บันทึก" : "แก้ไข"}
            </Button>
          </div>
        ),
      };
    });
  }, [
    selectedType,
    editModeIndex,
    props.claimInfoDetail,
    props.masterOptions?.district,
    props.masterOptions?.province,
  ]);

  // Handler
  const handleGetTheadThProps = () => {
    return {
      style: {
        paddingLeft: "0",
        paddingRight: "0",
        fontSize: "0.85rem",
      },
    };
  };

  const handleGetTdProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    const original = rowInfo?.original || {};
    const value = original[column.id];
    const isEmpty =
      (TABLE_16_FILES[selectedType].required as any).includes(column.id) &&
      (!value || value === "กรุณาระบุ");

    return {
      style: {
        display: "flex",
        justifyContent: "center",
        overflow: "unset",
        color: isEmpty ? "#fa4040" : "",
      },
    };
  };

  const handleGetTrProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    const original = rowInfo?.original || {};
    const isEmpty = Object.keys(original).some(
      (key) =>
        (TABLE_16_FILES[selectedType].required as any).includes(key) &&
        (!original[key] || original[key] === "กรุณาระบุ")
    );

    return {
      style: {
        backgroundColor: isEmpty ? "#edbdbd" : "",
      },
    };
  };

  const handleGetTbodyProps = () => {
    return {
      style: { overflow: "unset" },
    };
  };

  const handleSelect = (type: Key16Files) => () => {
    setSelectedType(type);
    setEditModeIndex(null);
  };

  const handleChange = (e: any, v: any) => {
    setRefNo(v.value);
  };

  const handleClose = () => {
    setOpenModConfirm(null);
  };

  const handleSave = () => {
    handleClose();
    setEditModeIndex(null);
    console.log("Save!!!");
  };

  const handleEnterToken = (code: string) => {
    props.onEvent({
      message: "UserTokenize",
      params: {
        action: "CheckUserToken",
        code: code,
      },
    });
  };

  const handleClearToken = () => {
    props.setProp("userTokenize", {
      ...props.userTokenize,
      token: "",
      employeeName: "",
      loading: false,
      error: null,
    });
  };

  const handleEditRefNo = () => {
    props.runSequence({
      sequence: "ExportEClaimFile",
      action: "EDIT_REF_NO",
      refNo,
      card: CARD_CLAIM_INFORMATION_DETAIL,
      errorKey: CARD_EXPORT_E_CLAIM_FILE,
    });
  };

  return (
    <div>
      {/* <TimeComboBox /> */}
      <CardClaimInformationDetailUX
        // data
        title={
          selectedType
            ? `${
                TABLE_16_FILES[selectedType].title
              } (${selectedType.toUpperCase()})`
            : ""
        }
        type={selectedType ? `(${selectedType})` : ""}
        refNo={refNo}
        detail={props.claimInfoDetail}
        // table
        // headers={headers}
        // keys={keys}
        // widths={widths}
        // items={items}
        // config
        isEditPatient={true}
        // callback
        // getTheadThProps={handleGetTheadThProps}
        // getTdProps={handleGetTdProps}
        // getTbodyProps={handleGetTbodyProps}
        onChange={handleChange}
        onClose={props.onClose}
        // Element
        typeList={(Object.keys(TABLE_16_FILES) as Key16Files[]).map((type) => (
          <Button
            style={{
              marginBottom: "8px",
              width: "100%",
              paddingRight: 0,
              paddingLeft: 0,
            }}
            color={selectedType === type ? "blue" : undefined}
            fluid
            onClick={handleSelect(type)}
          >
            {type.toUpperCase()}
          </Button>
        ))}
        ButtonEdit={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleEditRefNo}
            // data
            paramKey={`${CARD_CLAIM_INFORMATION_DETAIL}_${BUTTON_ACTIONS.EDIT}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_CLAIM_INFORMATION_DETAIL}_${BUTTON_ACTIONS.EDIT}`
              ]
            }
            // config
            color={"yellow"}
            name={BUTTON_ACTIONS.EDIT}
            size="medium"
            title="แก้ไข"
          />
        }
        TableElement={
          <Table
            key={"table-" + selectedType}
            data={items || []}
            getTheadThProps={handleGetTheadThProps}
            getTdProps={handleGetTdProps}
            getTrProps={handleGetTrProps}
            getTbodyProps={handleGetTbodyProps}
            headers={headers}
            keys={keys}
            minRows={items?.length > 5 ? items?.length : 5}
            showPagination={false}
            style={{ maxHeight: "75vh" }}
            widths={TABLE_16_FILES[selectedType].widths}
          ></Table>
        }
      />

      <ModConfirm
        openModal={!!openModConfirm}
        basic={false}
        modalStyle={{ marginTop: "calc(50vh - 150px)", width: "28%" }}
        titleName="ยืนยันการแก้ไขข้อมูลส่งเบิก"
        size="small"
        // callback
        onCloseWithDimmerClick={handleClose}
        onClose={handleClose}
        // Element
        approveButton={<></>}
        // !!Note ใช่ onDeny เนื่องจากต้องการให้ปุ่มบันทึกอยู่ทางขวา
        denyButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={`${CARD_EXPORT_E_CLAIM_FILE}_${BUTTON_ACTIONS.SAVE}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_EXPORT_E_CLAIM_FILE}_${BUTTON_ACTIONS.SAVE}`
              ]
            }
            // config
            disabled={!props.userTokenize?.employeeName}
            fluid={true}
            color={"green"}
            name={BUTTON_ACTIONS.SAVE}
            size="medium"
            title="บันทึก"
          />
        }
        content={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0 0 -1rem",
            }}
          >
            <label style={{ marginRight: "2.5rem" }}>
              <strong>กรุณาระบุรหัสพนักงาน</strong>
              <span style={{ color: "red" }}>*</span>
            </label>
            <EmployeeToken
              placeholder={"รหัสผู้บันทึก"}
              onEnterToken={handleEnterToken}
              onClearToken={handleClearToken}
              error={!!props.userTokenize?.error}
              loading={props.userTokenize?.loading}
              employeeName={props.userTokenize?.employeeName}
            />
          </div>
        }
      />
    </div>
  );
};

export default React.memo(CardClaimInformationDetail);
