import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Checkbox, Icon, Input, Modal } from "semantic-ui-react";
// UI Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
// UI ANE
import CardAnesBillTabUX from "./CardAnesBillTabUX";
import CardAnesBillTableUX from "./CardAnesBillTableUX";

// ANS
import CardSearchAnesthesiaTemplate from "../../ANS/CardSearchAnesthesiaTemplate";
import CardDrugClaimQA from "../TPD/CardDrugClaimQA";

const CARD_KEY = "CardAnesBill";

type CardAnesBillProps = {
  // function
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;

  // controller
  cardANSBillController?: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  billDrugOrder?: any;
  selectedEncounter?: any;
  selectedEmr?: any;
  selectedProgressCycle?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  AnesBillSequence?: any;
};

const CardAnesBill = (props: CardAnesBillProps) => {
  const [tab, setTab] = useState<"Drug" | "Supply" | "Operative">("Drug");
  const [openSearchTemplate, setOpenSearchTemplate] = useState<boolean>(false);
  // Use Effect
  useEffect(() => {
    props.runSequence({
      sequence: "AnesBill",
      action: "FETCH_ANES_BILL",
      cardKey: CARD_KEY,
    });
  }, []);

  useEffect(() => {
    const drugOrder = props.billDrugOrder || {};

    const handleClaimQA = async () => {
      const claimQA = props.AnesBillSequence?.claimQA || {};

      if (claimQA.supply) {
        const estimate = claimQA.supply.estimate;

        await props.setProp(
          "AnesBillSequence.bill.drug_claim_payload",
          drugOrder.claim_payload
        );

        await props.setProp("billDrugOrder", {
          claim_payload: estimate.claim_payload,
          items: estimate.items?.map((item: any) => ({
            ...item,
            estimate: estimate,
          })),
          success: false,
        });

        await props.setProp("AnesBillSequence.claimQA", {});

        props.onEvent({
          message: "CheckHaveQuestion",
          params: claimQA.supply,
        });
      } else {
        await props.setProp("billDrugOrder.success", false);

        props.setProp(
          "AnesBillSequence.bill.supply_claim_payload",
          drugOrder.claim_payload
        );
      }
    };

    if (drugOrder.success) {
      handleClaimQA();
    }
  }, [props.billDrugOrder?.success]);

  // Use Callback
  const handleChangeValue = useCallback(
    (v: any) => {
      const data = { ...props.AnesBillSequence.bill };
      const isCheck = typeof v.checked === "boolean";

      const value = isCheck ? v.checked : v.value;

      data[v.key][v.index][v.name] = value;

      if (isCheck) {
        data[v.key][v.index]["quantity"] = value ? "1" : "";
      }

      handleChangeTemplateValue(data);
    },
    [props.AnesBillSequence?.bill]
  );

  // Use Memo
  const tabKey = useMemo(() => {
    return (
      {
        Drug: "drugs",
        Supply: "supplys",
        Operative: "or_treatments",
      } as const
    )[tab];
  }, [tab]);

  // -------------------- Table  ,Qty,ชื่อรายการ,
  const billItems = useMemo(() => {
    const items: any[] = props.AnesBillSequence?.bill?.[tabKey] || [];

    return items.map((item, index) => ({
      ...item,
      chk: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Checkbox
            name="chk"
            checked={item.chk || false}
            onChange={(_event: any, data: any) => {
              handleChangeValue({ ...data, key: tabKey, index });
            }}
          />
        </div>
      ),
      quantity: (
        <Input
          fluid
          type="number"
          name="quantity"
          value={parseInt(item.quantity) || ""}
          disabled={!item.chk}
          onChange={(_event: any, data: any) => {
            handleChangeValue({ ...data, key: tabKey, index });
          }}
        />
      ),
      name: item.name || item.product_name,
      price_claimable: <>{item.price_claimable ?? ""}</>,
      price_non_claimable: <>{item.price_non_claimable ?? ""}</>,
    }));
  }, [props.AnesBillSequence?.bill, tabKey]);

  const isCheckedAll = useMemo(() => {
    const items: any[] = props.AnesBillSequence?.bill?.[tabKey] || [];

    return !!items.length && items.every((item) => item.chk);
  }, [props.AnesBillSequence?.bill, tabKey]);

  const isChecked = useMemo(() => {
    const bill = props.AnesBillSequence?.bill || {};
    const items = [
      ...(bill.drugs || []),
      ...(bill.supplys || []),
      ...(bill.or_treatments || []),
    ];

    return !!items.length && items.some((item) => item.chk);
  }, [props.AnesBillSequence?.bill, tabKey]);

  // Handler
  // -------------------- Tab
  const handleChangeTab = (_event: any, data: any) => {
    setTab(data.name);
  };

  const handleSelectTemplate = () => {
    setOpenSearchTemplate(true);
  };

  const handleCloseSearchTemplate = () => {
    setOpenSearchTemplate(false);
  };

  const handleChangeTemplateValue = (template: any) => {
    props.runSequence({
      sequence: "AnesBill",
      action: "CHANGE_BILL",
      bill: template,
    });
  };

  const handleSelectedTemplate = (template: any) => {
    handleCloseSearchTemplate();

    template.raw_drugs = template.drugs;
    template.raw_supplys = template.supplys.map((_: any, index: number) => ({
      ..._,
      index,
    }));

    handleChangeTemplateValue(template);
  };

  const handleChecked = (e: any, data: any) => {
    const bill = props.AnesBillSequence?.bill || {};

    if (!!bill[tabKey]?.length) {
      bill[tabKey] = bill[tabKey].map((item: any) => ({
        ...item,
        chk: data.checked,
        quantity: data.checked ? "1" : "",
      }));
      handleChangeTemplateValue(bill);
    }
  };

  const handleCalculate = () => {
    props.runSequence({
      sequence: "AnesBill",
      action: "CALCULATE",
      cardKey: CARD_KEY,
    });
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "AnesBill",
      action: "PREPARE_SAVE",
      cardKey: CARD_KEY,
    });
  };

  const handleRefresh = () => {
    props.runSequence({
      sequence: "AnesBill",
      action: "FETCH_ANES_BILL",
      cardKey: CARD_KEY,
    });
  };

  console.log("AnesBillSequence", props);

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
        // callback
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
      />

      <CardAnesBillTabUX
        tab={tab}
        // callback
        onSelectTemplate={handleSelectTemplate}
        onChangeTab={handleChangeTab}
        // Element
        buttonRefresh={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleRefresh}
            // data
            paramKey={`${CARD_KEY}_FETCH_ANES_BILL`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_KEY}_FETCH_ANES_BILL`]
            }
            // config
            color="brown"
            size="small"
            style={{ marginTop: "10px", marginLeft: "10px", width: "40px" }}
            iconStyle={{
              margin: "0.1rem -1rem -1.3rem -1rem",
              fontSize: "1.2rem",
            }}
            title={
              <Icon
                name="sync alternate"
                style={{
                  margin: "0.1rem -1rem -1.3rem -1rem",
                  fontSize: "1.2rem",
                }}
              />
            }
          />
        }
      />

      <CardAnesBillTableUX
        // columnsBill={columnsBill}
        headers={[
          <Checkbox checked={isCheckedAll} onChange={handleChecked} />,
          ...(tab !== "Operative" ? ["Qty"] : []),
          "ชื่อรายการ",
          "เบิกได้",
          "เบิกไม่ได้",
        ]}
        keys={`chk${
          tab !== "Operative" ? ",quantity" : ""
        },name,price_claimable,price_non_claimable`}
        data={billItems}
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={`${CARD_KEY}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_SAVE`]}
            // config
            disabled={!props.AnesBillSequence?.bill?.is_calculate || !isChecked}
            color="green"
            size="medium"
            style={{ width: "120px" }}
            title="บันทึก"
          />
        }
        buttonCalculate={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleCalculate}
            // data
            paramKey={`${CARD_KEY}_CALCULATE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_CALCULATE`]}
            // config
            disabled={!isChecked}
            color="yellow"
            size="medium"
            style={{ width: "120px", marginLeft: "3rem" }}
            title="คำนวณเบิก"
          />
        }
      />

      <Modal
        open={openSearchTemplate}
        closeOnDimmerClick={true}
        closeOnDocumentClick={true}
        onClose={handleCloseSearchTemplate}
      >
        <CardSearchAnesthesiaTemplate
          controller={props.cardANSBillController}
          onSelectedTemplate={handleSelectedTemplate}
          hideCallback={handleCloseSearchTemplate}
        />
      </Modal>

      {props.billDrugOrder?.estimate?.isAdd && (
        <CardDrugClaimQA
          onEvent={props.onEvent}
          estimate={props.billDrugOrder?.estimate || {}}
          orderType={"ANESTHESIA"}
          stateKey="billDrugOrder"
          selectedEncounter={props.selectedEncounter}
          selectedEmr={props.selectedEmr}
          selectedProgressCycle={props.selectedProgressCycle}
        />
      )}
    </>
  );
};

export default React.memo(CardAnesBill);
