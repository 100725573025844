import React, { useEffect, useState } from "react";
import { Label } from "semantic-ui-react";

import MainHIS from "./MainHIS";
import PatientLayout from "HIS/PatientLayout";

// Framework
import Tab from "react-lib/frameworks/Tab";
// Common
import * as Common from "react-lib/apps/common";
import ErrorBoundary from "react-lib/apps/common/ErrorBoundary";
import CardScanner from "react-lib/apps/HISV3/common/CardScanner";
import CardScannedDocument from "react-lib/apps/HISV3/common/CardScannedDocument";
import CardPatientPanel from "react-lib/apps/HISV3/common/CardPatientPanel";

// REG
import CardConfirmCoverage from "react-lib/apps/HISV3/REG/CardConfirmCoverage";
import CardAddress from "react-lib/apps/HISV3/REG/CardAddress";
import CardRegSearch from "react-lib/apps/HISV3/REG/CardRegSearch";
import CardOpenEncounter from "react-lib/apps/HISV3/REG/CardOpenEncounter";
import CardPatientInfo from "react-lib/apps/HISV3/REG/CardPatientInfo";
import CardAppointmentEncounter from "react-lib/apps/HISV3/REG/CardAppointmentEncounter";
// MSG
import ChatBox from "react-lib/apps/MSG/ChatBox";
// BIL
import CardBillingEncounters from "react-lib/apps/HISV3/BIL/CardBillingEncounters";
import CardMedicalFeeHistory from "react-lib/apps/HISV3/BIL/CardMedicalFeeHistory";
import CardBillingHistory from "react-lib/apps/HISV3/BIL/CardBillingHistory";
import CardFinancialAmountSet from "react-lib/apps/HISV3/BIL/CardFinancialAmountSet";
// HCU
import CardSearchPackage from "react-lib/apps/HISV3/HCU/CardSearchPackage";
import CardPurchaseList from "react-lib/apps/HISV3/HCU/CardPurchaseList";
import CardPurchaseHistory from "react-lib/apps/HISV3/HCU/CardPurchaseHistory";

// Config
import CONFIG from "config/config";

const LAYOUT_SETTINGS = [
  {
    patientList: { display: true, flex: 1, size: "max" },
    patientPanel: { display: false, flex: 0, size: "none" },
    patientRecord: { display: false, flex: 0, size: "none" },
  },
  {
    patientList: { display: false, flex: 0, size: "none" },
    patientPanel: { display: true, flex: 1, size: "min" },
    patientRecord: { display: true, flex: 4, size: "max" },
    backward: true,
  },
];

export const Main = (props: any) => {
  useEffect(() => {
    props.runSequence({ sequence: "SetPatient" });
    return () => {
      props.setState({
        SetPatientSequence: null,
        CreateUpdateEncounterSequence: null,
      });
    };
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <PatientLayout
        LAYOUT_SETTINGS={LAYOUT_SETTINGS}
        onBackward={(layout: number) => {
          // issue 55929
          if (layout === 1) {
            props.setState({ CreateUpdateEncounterSequence: null }, () =>
              props.runSequence({
                sequence: "SetPatient",
                action: "backwithKeptSearchText",
              })
            );
          }
        }}
        patientList={(layoutProps: any) => (
          <div
            style={{ backgroundColor: "white", height: "100%", width: "100%" }}
          >
            {props.listView(layoutProps)}
          </div>
        )}
        patientPanel={(layoutProps: any) => (
          <div
            style={{
              backgroundColor: "white",
              height: "100%",
              width: "100%",
              borderRight: "solid #cccccc 1px",
            }}
          >
            {props.panelView(layoutProps)}
          </div>
        )}
        patientRecord={(layoutProps: any) => (
          <div style={{ height: "100%", width: "100%" }}>
            {props.recordView(layoutProps)}
          </div>
        )}
        resetLayout={props.resetLayout}
      />
    </div>
  );
};

export const listView = (context: MainHIS) => (layoutProps: any) => {
  return (
    <div style={{ height: "100%", padding: "10px" }}>
      <CardRegSearch
        setProp={context.controller.setProp}
        onEvent={context.controller.handleEvent}
        // seq
        runSequence={context.runSequence}
        SetPatientSequence={context.state.SetPatientSequence}
        // CommonInterface
        errorMessage={context.state.errorMessage}
        successMessage={context.state.successMessage}
        divisionType={context.state.divisionType}
        buttonLoadCheck={context.state.buttonLoadCheck}
        // callback
        forward={layoutProps?.forward || (() => {})}
      />
    </div>
  );
};

export const panelView = (context: MainHIS) => (layoutProps: any) =>
  (
    <div style={{ height: "100%", width: "100%", overflow: "scroll" }}>
      <CardPatientPanel
        //Feature #54627
        patientADR={context.state.patientADR}
        setProp={context.controller.setProp}
        selectedPatient={context.state.SetPatientSequence?.patient || {}}
        chatDetail={context.state.chatDetail}
        onEvent={context.controller.handleEvent}
        runSequence={context.runSequence}
        errorMessage={context.state.errorMessage}
        loadingStatus={context.state.loadingStatus}
        buttonLoadCheck={context.state.buttonLoadCheck}
        divisionType={context.state.divisionType}
        AllergySequence={context.state.AllergySequence}
        // Suspending
        // encounterSuspension={context.state.encounterSuspension}
        // MedicalFeeHistorySequence={context.state.MedicalFeeHistorySequence}
        CreateUpdateEncounterSequence={
          context.state.CreateUpdateEncounterSequence
        }
        form={"ViewRegistration"}
      />
    </div>
  );

export const recordView = (context: MainHIS) => (layoutProps: any) =>
  (
    <ErrorBoundary>
      <Tab
        tabWidth="150px"
        selectedTabIndex={context.state.selectedRecordViewIndex}
        onTabChange={(index: number) => {
          context.controller.setProp("selectedRecordViewIndex", index);
        }}
        data={[
          {
            key: "ประวัติผู้ป่วย",
            name: "ประวัติผู้ป่วย",
            hide: ["การเงิน"].includes(context.state.divisionType || ""),
            render: () => (
              <CardPatientInfo
                patient={context.state.SetPatientSequence?.patient || {}}
                setProp={context.controller.setProp}
                runSequence={context.runSequence}
                masterOptions={context.state.masterOptions}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                SetPatientSequence={context.state.SetPatientSequence}
                buttonLoadCheck={context.state.buttonLoadCheck}
              />
            ),
          },
          {
            key: "ที่อยู่ผู้ป่วย",
            name: "ที่อยู่ผู้ป่วย",
            hide: ["การเงิน"].includes(context.state.divisionType || ""),
            render: () => (
              <CardAddress
                patient={context.state.SetPatientSequence?.patient || {}}
                setProp={context.controller.setProp}
                runSequence={context.runSequence}
                masterOptions={context.state.masterOptions}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
              />
            ),
          },
          {
            key: "รับรองสิทธิ",
            name: "รับรองสิทธิ",
            hide:
              !Number.isInteger(
                context.state.SetPatientSequence?.patient?.id
              ) || ["การเงิน"].includes(context.state.divisionType || ""),
            render: () => (
              <ErrorBoundary>
                <CardConfirmCoverage
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  runSequence={context.runSequence}
                  SetCoverageSequence={context.state.SetCoverageSequence}
                  masterOptions={context.state.masterOptions}
                  searchedItemList={context.state.searchedItemList}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  errorCoverage={context.state.errorCoverage}
                />
              </ErrorBoundary>
            ),
          },
          {
            key: "เปิด Encounter ใหม่",
            name: "เปิด Encounter ใหม่",
            hide:
              !Number.isInteger(
                context.state.SetPatientSequence?.patient?.id
              ) || ["การเงิน"].includes(context.state.divisionType || ""),
            render: () => (
              <CardOpenEncounter
                onEvent={context.controller.handleEvent}
                setProp={context.controller.setProp}
                runSequence={context.runSequence}
                patient={context.state.SetPatientSequence?.patient || {}}
                selectedPatient={context.state.selectedPatient}
                CreateUpdateEncounterSequence={
                  context.state.CreateUpdateEncounterSequence
                }
                appointmentEncounterList={
                  context.state.appointmentEncounterList
                }
                selectedAppointmentItems={
                  context.state.selectedAppointmentItems
                }
                masterOptions={context.state.masterOptions}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
              />
            ),
          },
          {
            key: "เปิดจากนัดหมาย",
            name: "เปิดจากนัดหมาย",
            hide:
              !Number.isInteger(
                context.state.SetPatientSequence?.patient?.id
              ) || ["การเงิน"].includes(context.state.divisionType || ""),
            render: () => <CardAppointmentEncounter />,
          },
          {
            key: "เปิดใบยาจัดส่งบ้าน",
            name: "เปิดใบยาจัดส่งบ้าน",
            hide: ["การเงิน"].includes(context.state.divisionType || ""),
            render: () => <div>To be implemented</div>,
          },
          {
            key: "Scanned_Document_Register",
            name: "Scanned Document",
            // hide: false,
            hide: ["การเงิน"].includes(context.state.divisionType || ""),
            render: () => (
              <div>
                {context.state.SetPatientSequence?.patient?.hn !== undefined ? (
                  <ErrorBoundary>
                    <CardScannedDocument
                      key="Common/CardScannedDocument"
                      controller={context.scannedDocumentController}
                      patientData={context.state.patientData}
                      patientInfo={context.state.selectedPatient}
                      django={context.state.django}
                      isLoading={context.state.eocIsLoading}
                      onClose={context.removeCardScannedDocument}
                    />
                  </ErrorBoundary>
                ) : (
                  <div>
                    {context.state.SetPatientSequence?.patient &&
                      alert("กรุณาระบุ HN")}
                  </div>
                )}
              </div>
            ),
          },
          {
            key: "Document Scan",
            name: "Document Scan",
            // hide: false,
            hide: ["การเงิน"].includes(context.state.divisionType || ""),
            render: () => (
              <div>
                {context.state.SetPatientSequence?.patient?.hn !== undefined ? (
                  <ErrorBoundary>
                    <CardScanner
                      onClose={() => {
                        context.setState({
                          openModScanner: !context.state.openModScanner,
                        });
                        context.setState({ openMorePopup: false });
                      }}
                      patientInfo={context.state.selectedPatient}
                      controller={context.scannedDocumentController}
                      patientId={context.state.selectedEncounter?.patient_id}
                      PATIENT_DATA={context.state.patientData}
                      onNoPatient={() =>
                        context.setState({ openModScannerError: true })
                      }
                      django={context.state.django}
                    />
                  </ErrorBoundary>
                ) : (
                  <div>
                    {context.state.SetPatientSequence?.patient &&
                      alert("กรุณาระบุ HN")}
                  </div>
                )}
              </div>
            ),
          },
          {
            key: "Print_List_Register",
            name: "Print List",
            // hide: false,
            hide: ["การเงิน"].includes(context.state.divisionType || ""),
            render: () => (
              <div>
                {context.state.SetPatientSequence?.patient?.hn !== undefined ? (
                  <ErrorBoundary>
                    <Common.CardPrintList
                      patientInfo={context.state.selectedPatient}
                      controller={context.printListController}
                      patientData={context.state.patientData}
                      patientId={context.state.selectedEncounter?.patient_id}
                      encounterId={context.state.selectedEncounter?.id}
                      encounterNumber={context.state.selectedEncounter?.number}
                      onClose={context.togglePrintList}
                    />
                  </ErrorBoundary>
                ) : (
                  <div>
                    {context.state.SetPatientSequence?.patient &&
                      alert("กรุณาระบุ HN")}
                  </div>
                )}
              </div>
            ),
          },
          {
            key: "รายการสถานะผู้ป่วย",
            name: "รายการสถานะผู้ป่วย",
            hide: !["การเงิน"].includes(context.state.divisionType || ""),
            render: () => (
              <CardBillingEncounters
                setProp={context.controller.setProp}
                divisionList={context.state.divisionList}
                onEvent={context.controller.handleEvent}
                runSequence={context.runSequence}
                BillingHistorySequence={context.state.BillingHistorySequence}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                selectedEncounter={context.state.selectedEncounter}
              />
            ),
          },
          {
            key: "เอกสารทั้งหมด",
            name: "เอกสารทั้งหมด",
            hide: !["การเงิน"].includes(context.state.divisionType || ""),
            render: () => (
              <CardBillingHistory
                onEvent={context.controller.handleEvent}
                setProp={context.controller.setProp}
                // seq
                runSequence={context.runSequence}
                BillingHistorySequence={context.state.BillingHistorySequence}
                // data
                divisionList={context.state.divisionList}
                // options
                masterOptions={context.state.masterOptions}
                // CommonInterface
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                selectedEncounter={context.state.selectedEncounter}
                buttonLoadCheck={context.state.buttonLoadCheck}
              />
            ),
          },
          {
            key: "สรุปประวัติการรักษาพยาบาล",
            name: "สรุปประวัติการรักษาพยาบาล",
            hide: !["การเงิน"].includes(context.state.divisionType || ""),
            render: () => (
              <CardMedicalFeeHistory
                setProp={context.controller.setProp}
                onEvent={context.controller.handleEvent}
                runSequence={context.runSequence}
                masterOptions={context.state.masterOptions}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                selectedEncounter={context.state.selectedEncounter}
                buttonLoadCheck={context.state.buttonLoadCheck}
                MedicalFeeHistorySequence={
                  context.state.MedicalFeeHistorySequence
                }
                loadingStatus={context.state.loadingStatus}
              />
            ),
          },
          {
            key: "กำหนดวงเงินรายครั้ง",
            name: "กำหนดวงเงินรายครั้ง",
            hide:
              context.state.selectedPatient?.encounter_no ||
              !["การเงิน"].includes(context.state.divisionType || ""),
            render: () => (
              <CardFinancialAmountSet
                // origin function
                setProp={context.controller.setProp}
                onEvent={context.controller.handleEvent}
                runSequence={context.runSequence}
                // origin data
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                selectedEncounter={context.state.selectedEncounter}
                buttonLoadCheck={context.state.buttonLoadCheck}
                // data
                FinancialAmountSetSequence={
                  context.state.FinancialAmountSetSequence
                }
                financialAmountList={context.state.financialAmountList}
                modCoverageApprove={context.state.modCoverageApprove}
              />
            ),
          },
          {
            // Todo: get channel id for given division, hn with PRX/resolve-chat-channel/
            key: "Telehealth_Register",
            name: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label>Telehealth</label>
                {!!context.state.chatDetail?.unread_message_count && (
                  <Label
                    data-testid="msg-noti"
                    circular
                    color={"red"}
                    size="mini"
                  >
                    {context.state.chatDetail.unread_message_count}
                  </Label>
                )}
              </div>
            ),
            hide:
              // !context.state.selectedEncounter ||
              !["หน่วยตรวจ", "หอผู้ป่วย"].includes(
                context.state.divisionType || ""
              ) || context.state.chatDetail === null,
            render: () => (
              <div>
                <ChatBox
                  ref={(ref: any) => (context.chatBoxRef = ref)}
                  // controller
                  controller={context.chatController}
                  classifyController={context.classifyController}
                  diagFormController={context.diagFormController}
                  // data
                  messageViewStyle={{ height: "81vh" }}
                  userId={context.controller.data.user?.toString() || ""}
                  apiToken={context.controller.apiToken}
                  division_id={context.state.selectedDivision?.id}
                  isPatient={false}
                  nullId={false}
                  readOnly={false}
                  fullname={"fullname"}
                  username={"username"}
                  encounterId={context.state.selectedEncounter?.id}
                  patientData={{}}
                  patientId={context.state.selectedPatient?.id}
                  match={{
                    params: {
                      chatChannelId: context.state.chatDetail?.chat_channel,
                    },
                  }}
                  // callback
                  onDidMount={() =>
                    context.controller.setProp(
                      "chatDetail.unread_message_count",
                      0
                    )
                  }
                  onNavigationMessage={({ content }: any) => content}
                  onCallGetLastMessage={() => {}}
                  onCloseVideoCall={() => {}}
                  onOpenVideoCall={(url: string) => {
                    context.controller.setProp("openVideoCallModal", true);
                    context.controller.setProp("videoCallRoom", url);
                    context.controller.setProp(
                      "currChatChannelId",
                      context.state.chatDetail?.chat_channel
                    );
                  }}
                  onSetChannelName={() => {}}
                  enHasNoDiv={() => {}}
                  enHasDiv={() => {}}
                />
              </div>
            ),
          },
          {
            key: "Package",
            name: "Package",
            // hide: !(
            //   // context.state.selectedEncounter
            //   //  &&
            //   // ["ห้องยา"].includes(context.state.divisionType || "")
            // ),
            render: () => (
              <CardSearchPackage
                setProp={context.controller.setProp}
                onEvent={context.controller.handleEvent}
                // seq
                runSequence={context.runSequence}
                PackagePurchaseSequence={context.state.PackagePurchaseSequence}
                // CommonInterface
                searchedItemListWithKey={context.state.searchedItemListWithKey}
                buttonLoadCheck={context.state.buttonLoadCheck}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                // options
                masterOptions={context.state.masterOptions}
              />
            ),
          },
          {
            key: "Purchase list",
            name: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label>Purchase list</label>
                {!!context.state.purchaseOrderList?.length && (
                  <Label
                    data-testid="msg-noti"
                    circular
                    color={"red"}
                    size="mini"
                  >
                    {context.state.purchaseOrderList?.length}
                  </Label>
                )}
              </div>
            ),
            // hide: !(
            //   context.state.selectedEncounter
            //   //  &&
            //   // ["ห้องยา"].includes(context.state.divisionType || "")
            // ),
            render: () => (
              <CardPurchaseList
                setProp={context.controller.setProp}
                onEvent={context.controller.handleEvent}
                // seq
                runSequence={context.runSequence}
                PackagePurchaseSequence={context.state.PackagePurchaseSequence}
                BillPaymentSequence={context.state.BillPaymentSequence}
                // CommonInterface
                searchedItemListWithKey={context.state.searchedItemListWithKey}
                buttonLoadCheck={context.state.buttonLoadCheck}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                patientInfo={context.state.selectedPatient}
                selectedEncounter={context.state.selectedEncounter}
                selectedDevice={context.state.selectedDevice}
                // options
                masterOptions={context.state.masterOptions}
              />
            ),
          },
          {
            key: "Purchase History",
            name: "Purchase History",
            // hide: !(
            //   context.state.selectedEncounter
            //   //  &&
            //   // ["ห้องยา"].includes(context.state.divisionType || "")
            // ),
            render: () => (
              <CardPurchaseHistory
                setProp={context.controller.setProp}
                onEvent={context.controller.handleEvent}
                // seq
                runSequence={context.runSequence}
                PackagePurchaseSequence={context.state.PackagePurchaseSequence}
                // data
                masterData={context.controller.data.masterData}
                // CommonInterface
                searchedItemListWithKey={context.state.searchedItemListWithKey}
                buttonLoadCheck={context.state.buttonLoadCheck}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                // options
                masterOptions={context.state.masterOptions}
              />
            ),
          },
        ]
          ?.filter((item: any) =>
            CONFIG.SCREEN_ITEM?.find((conf: any) => conf.name === item.key)
          )
          ?.filter((item: any) => !item.hide)}
      />
    </ErrorBoundary>
  );
