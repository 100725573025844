import { data } from "jquery";

const FormFoodReport = (props: any) => {
  const setColumns = (id: any, data: any) => {
    let tableData: any[] = [];

    var reportTable = (item: any) => {
      return [
        {
          text: item?.roomList?.room_no || "",
          style: {
            fontSize: 12,
          },
          alignment: "center",
        },
        {
          text: item?.roomList?.hn || "",
          style: {
            fontSize: 12,
          },
          alignment: "center",
        },
        {
          text: item?.roomList?.patient_name || "",
          style: {
            fontSize: 12,
          },
        },
        {
          stack: [
            {
              text: `${item?.foodOrderList?.food_type_name}\n\n` || "",
              style: {
                fontSize: 12,
                bold: true,
              },
            },
            {
              columns: [
                {
                  width: "*",
                  text:
                    item?.foodOrderList?.food_type_type === "R"
                      ? `ปริมาตร : ` || ""
                      : "",
                  style: {
                    bold: true,
                  },
                },
                {
                  width: 60,
                  text:
                    item?.foodOrderList?.food_type_type === "R"
                      ? `${item?.foodOrderList?.routine_size}\n` || ""
                      : "",
                },
              ],
              style: {
                fontSize: 12,
              },
            },
            {
              columns: [
                {
                  width: "*",
                  text:
                    item?.foodOrderList?.food_type_type === "R"
                      ? `ความเข้มข้น : ` || ""
                      : "",
                  style: {
                    bold: true,
                  },
                },
                {
                  width: 45,
                  text:
                    item?.foodOrderList?.food_type_type === "R"
                      ? `${item?.foodOrderList?.intensity}\n` || ""
                      : "",
                },
              ],
              style: {
                fontSize: 12,
              },
            },
            {
              columns: [
                {
                  width: "*",
                  text:
                    item?.foodOrderList?.food_type_type === "R"
                      ? `Mixture : ` || ""
                      : "",
                  style: {
                    bold: true,
                  },
                },
                {
                  width: 60,
                  text:
                    item?.foodOrderList?.food_type_type === "R"
                      ? `${item?.foodOrderList?.mixture}\n` || ""
                      : "",
                },
              ],
              style: {
                fontSize: 12,
              },
            },
            {
              columns: [
                {
                  width: "*",
                  text:
                    item?.foodOrderList?.food_type_type === "R"
                      ? `Volume : ` || ""
                      : "",
                  style: {
                    bold: true,
                  },
                },
                {
                  width: 55,
                  text:
                    item?.foodOrderList?.food_type_type === "R"
                      ? `${item?.foodOrderList?.mixture_volume}\n` || ""
                      : "",
                },
              ],
              style: {
                fontSize: 12,
              },
            },
            {
              columns: [
                {
                  width: "*",
                  text:
                    item?.foodOrderList?.food_type_type === "R"
                      ? `หมายเหตุ : ` || ""
                      : "",
                  style: {
                    bold: true,
                  },
                },
                {
                  width: 55,
                  text:
                    item?.foodOrderList?.food_type_type === "R"
                      ? `${item?.foodOrderList?.remark}\n` || ""
                      : "",
                },
              ],
              style: {
                fontSize: 12,
              },
            },
          ],
        },
        {
          stack: [
            {
              columns: [
                {
                  width: "*",
                  text: `อาหารเฉพาะโรค : ` || "",
                  style: {
                    bold: true,
                  },
                },
                {
                  width: 80,
                  text: `${item?.foodOrderList?.special_diet_detail}` || "",
                },
              ],
              style: {
                fontSize: 12,
              },
            },
            {
              columns: [
                {
                  width: "*",
                  text: `หมายเหตุ : ` || "",
                  style: {
                    bold: true,
                  },
                },
                {
                  width: 110,
                  text: ` ${item?.foodOrderList?.special_diet_remark}` || "",
                },
              ],
              style: {
                fontSize: 12,
              },
            },
            {
              canvas: [
                {
                  lineColor: "gray",
                  type: "line",
                  x1: -4,
                  y1: 0,
                  x2: 145,
                  y2: 0,
                  lineWidth: 1,
                },
              ],
            },
            {
              columns: [
                {
                  text: `คำสั่งควบคุมต่อวัน` || "",
                },
              ],
              style: {
                fontSize: 12,
              },
            },
            {
              columns: [
                {
                  width: "*",
                  text: `พลังงาน : ` || "",
                  style: {
                    bold: true,
                  },
                },
                {
                  width: 110,
                  text:
                    ` ${
                      item?.foodOrderList?.food_nutrition_control?.[0]
                        ?.result || "-"
                    } ${
                      item?.foodOrderList?.food_nutrition_control?.[0]
                        ?.unit_name || ""
                    }` || "",
                },
              ],
              style: {
                fontSize: 12,
              },
            },
            {
              columns: [
                {
                  width: "*",
                  text: `โคเรสเตอรอล : ` || "",
                  style: {
                    bold: true,
                  },
                },
                {
                  width: 95,
                  text:
                    ` ${
                      item?.foodOrderList?.food_nutrition_control?.[1]
                        ?.result || "-"
                    } ${
                      item?.foodOrderList?.food_nutrition_control?.[1]
                        ?.unit_name || ""
                    }` || "",
                },
              ],
              style: {
                fontSize: 12,
              },
            },
            {
              columns: [
                {
                  width: "*",
                  text: `LCT : ` || "",
                  style: {
                    bold: true,
                  },
                },
                {
                  width: 125,
                  text:
                    ` ${
                      item?.foodOrderList?.food_nutrition_control?.[2]
                        ?.result || "-"
                    } ${
                      item?.foodOrderList?.food_nutrition_control?.[2]
                        ?.unit_name || ""
                    }` || "",
                },
              ],
              style: {
                fontSize: 12,
              },
            },
            {
              columns: [
                {
                  width: "*",
                  text: `MCT : ` || "",
                  style: {
                    bold: true,
                  },
                },
                {
                  width: 125,
                  text:
                    ` ${
                      item?.foodOrderList?.food_nutrition_control?.[3]
                        ?.result || "-"
                    } ${
                      item?.foodOrderList?.food_nutrition_control?.[3]
                        ?.unit_name || ""
                    }` || "",
                },
              ],
              style: {
                fontSize: 12,
              },
            },
            {
              columns: [
                {
                  width: "*",
                  text: `ไขมัน : ` || "",
                  style: {
                    bold: true,
                  },
                },
                {
                  width: 125,
                  text:
                    `${
                      item?.foodOrderList?.food_nutrition_control?.[4]
                        ?.result || "-"
                    } ${
                      item?.foodOrderList?.food_nutrition_control?.[4]
                        ?.unit_name || ""
                    }` || "",
                },
              ],
              style: {
                fontSize: 12,
              },
            },
            {
              columns: [
                {
                  width: "*",
                  text: `Salt : ` || "",
                  style: {
                    bold: true,
                  },
                },
                {
                  width: 125,
                  text:
                    `${
                      item?.foodOrderList?.food_nutrition_control?.[5]
                        ?.result || "-"
                    } ${
                      item?.foodOrderList?.food_nutrition_control?.[5]
                        ?.unit_name || ""
                    }` || "",
                },
              ],
              style: {
                fontSize: 12,
              },
            },
            {
              columns: [
                {
                  width: "*",
                  text: `หมายเหตุ : ` || "",
                  style: {
                    bold: true,
                  },
                },
                {
                  width: 110,
                  text:
                    `   ${
                      item?.foodOrderList?.food_nutrition_control?.[6]
                        ?.result || "-"
                    } ${
                      item?.foodOrderList?.food_nutrition_control?.[6]
                        ?.unit_name || ""
                    }` || "",
                },
              ],
              style: {
                fontSize: 12,
              },
            },
          ],
        },
        {
          stack: [
            {
              columns: [
                {
                  width: "*",
                  text: `แพ้ : ` || "",
                  style: {
                    bold: true,
                  },
                },
                {
                  width: 90,
                  text: ` ${item?.foodOrderList?.allergy_food}\n` || "",
                },
              ],
              style: {
                fontSize: 11,
              },
            },
            {
              columns: [
                {
                  width: "*",
                  text: `เสริม : ` || "",
                  style: {
                    bold: true,
                  },
                },
                {
                  width: 80,
                  text: ` ${item?.foodOrderList?.supplement_food}\n` || "",
                },
              ],
              style: {
                fontSize: 11,
              },
            },
            {
              columns: [
                {
                  width: "*",
                  text: `ข้อจำกัด : ` || "",
                  style: {
                    bold: true,
                  },
                },
                {
                  width: 74,
                  text: ` ${item?.foodOrderList?.restriction_food}\n` || "",
                },
              ],
              style: {
                fontSize: 11,
              },
            },
            {
              columns: [
                {
                  width: "*",
                  text: `หมายเหตุ : ` || "",
                  style: {
                    bold: true,
                  },
                },
                {
                  width: 65,
                  text: ` ${item?.foodOrderList?.remark}\n` || "",
                },
              ],
              style: {
                fontSize: 11,
              },
            },
          ],
        },
      ];
    };

    var headerTable = [
      {
        text: "ห้อง/เตียง",
        style: {
          fontSize: 12,
          bold: true,
        },
        alignment: "center",
      },
      {
        text: "HN",
        style: {
          fontSize: 12,
          bold: true,
        },
        alignment: "center",
      },
      {
        text: "ชื่อ นามสกุล",
        style: {
          fontSize: 12,
          bold: true,
        },
        alignment: "center",
      },
      {
        text: "รายการอาหาร",
        style: {
          fontSize: 12,
          bold: true,
        },
        alignment: "center",
      },
      {
        text: "ข้อมูลอาหารเฉพาะโรค/คำสั่งควบคุมต่อวัน",
        style: {
          fontSize: 12,
          bold: true,
        },
        alignment: "center",
      },
      {
        text: "รายละเอียด",
        style: {
          fontSize: 12,
          bold: true,
        },
        alignment: "center",
      },
    ];

    if (data?.length !== 0) {
      var tableFood: any[] = [];
      data?.map((item: any) => {
        tableFood.push(reportTable(item));
      });

      tableData.push([
        {
          margin: [0, 0, 0, 20],
          table: {
            headerRows: 2,
            widths: ["7%", "8%", "20%", "18%", "27%", "20%"],
            body: [
              [
                {
                  text: [
                    {
                      text:
                        id === 1
                          ? "มื้อ 08:00 น."
                          : id === 2
                          ? "มื้อ 13:00 น."
                          : id === 3
                          ? "มื้อ 18:00 น."
                          : id === 4
                          ? "มื้อ 22:00 น."
                          : "",
                    },
                  ],
                  style: {
                    fontSize: 16,
                    bold: true,
                  },
                  colSpan: 6,
                  alignment: "center",
                },
                {},
                {},
                {},
                {},
                {},
              ],
              headerTable,
              ...tableFood,
            ],
          },
        },
      ]);
    }

    return tableData;
  };

  let columnsFoodM = setColumns(1, props.foodData?.foodMData);
  let columnsFoodL = setColumns(2, props.foodData?.foodLData);
  let columnsFoodD = setColumns(3, props.foodData?.foodDData);
  let columnsFoodB = setColumns(4, props.foodData?.foodBData);

  return {
    defaultStyle: {
      font: "THSarabunNew",
      // alignment: 'justify'
      lineHeight: 1,
      fontSize: 13,
    },
    pageMargins: [10, 130, 10, 10],
    // A4: [595.28, 841.89]
    pageSize: "A4",
    styles: {},
    header: {
      margin: [10, 20, 20, 0],

      stack: [
        {
          layout: "noBorders",
          fillOpacity: 0.4,
          table: {
            widths: ["10%", "90%"],
            heights: [55, 55],
            body: [
              [
                {
                  margin: [55, 0, 0, 0],
                  layout: "noBorders",
                  fillOpacity: 0.4,
                  stack: [
                    {
                      image: "logo",
                      fillOpacity: 0.4,
                      width: 90,
                    },
                  ],
                },
                {
                  margin: [0, 10, 0, 0],
                  text: [
                    {
                      text: `CHULALONGKORN UNIVERSITY, FACULTY OF DENTISTRY\n`,
                      style: {
                        fontSize: 18,
                        bold: true,
                      },
                    },
                    {
                      text: `รายการอาหาร ประจําวัน ${props?.date}\n`,
                      style: {
                        fontSize: 18,
                        bold: true,
                      },
                    },
                    {
                      text: `หอผู้ป่วยใน : ${props?.division}`,
                      style: {
                        fontSize: 18,
                      },
                    },
                  ],
                  alignment: "center",
                },
              ],
            ],
          },
        },
      ],
    },
    content: [
      {
        stack: [...columnsFoodM],
      },
      {
        stack: [...columnsFoodL],
      },
      {
        stack: [...columnsFoodD],
      },
      {
        stack: [...columnsFoodB],
      },
    ],
    images: {
      logo: origin + "/static/images/logochula.png",
      queue: origin + "/static/images/menus/queue.png",
    },
  };
};

export default FormFoodReport;
