import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';


const CardManageOperatingRoomUX = (props: any) => {
    return(
      <div
        style={{display: "flex", height: "100%"}}>
        <div
          style={{flex: 1}}>
          
          <div
            style={{width: "100%"}}>
            {props.LocationElement}
          </div>
        </div>
        <div
          style={{flex: 3, padding: "1rem", height: "100%"}}>
          
          <div
            style={{marginBottom: "1rem"}}>
            {`${props.title || ""}`}
          </div>
          <div
            style={{width: "100%", height: "100%"}}>
            {props.selectCalendar}
          </div>
        </div>
      </div>
    )
}

export default CardManageOperatingRoomUX

export const screenPropsDefault = {}

/* Date Time : Thu Sep 08 2022 04:22:00 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"flex\", height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 3, padding: \"1rem\", height: \"100%\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.LocationElement"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "`${props.title || \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1rem\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectCalendar"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        }
      },
      "seq": 6,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardManageOperatingRoomUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
