import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Input
} from 'semantic-ui-react'

const CardLabReportUX = (props: any) => {
    return(
      <div
        style={{padding:"10px"}}>
        <div
          style={{ paddingBottom: "10px" }}>
          
          <div
            style={{ paddingBottom: "10px" }}>
            
            <div
              style={{ fontSize: "1.2rem", fontWeight: "bold"}}>
              Lab Results
            </div>
            <hr>
            </hr>
          </div>
          <div
            style={{display:"flex",  paddingBottom: "10px" }}>
            
            <div
              style={{fontWeight: "bold"}}>
              Lab ID:
            </div>
            <div
              style={{marginRight:"10px", paddingLeft: "5px"}}>
              {props.selectedLabOrderWorking?.pk}
            </div>
            <div
              style={{fontWeight: "bold", marginLeft: "3%"}}>
              วันเวลาที่สั่ง :
            </div>
            <div
              style={{marginRight:"5px", paddingLeft: "5px"}}>
              {props.selectedLabOrderWorking?.date}
            </div>
            <div
              style={{fontWeight: "bold", marginLeft: "3%"}}>
              สถานะ lab order:
            </div>
            <div
              style={{marginRight:"5px", paddingLeft: "5px"}}>
              {props.selectedLabOrderWorking?.status_name}
            </div>
            <div
              style={{ fontWeight: "bold", marginLeft: "3%"}}>
               วันเวลาที่รับออเดอร์ :
            </div>
            <div
              style={ {marginRight:"5px", paddingLeft: "5px"}}>
              {props.selectedLabOrderWorking?.arrive_time}
            </div>
            <div
              style={{fontWeight: "bold", marginLeft: "3%"}}>
              การชำระเงิน :
            </div>
            <div
              style={ {width:"10%",marginRight:"5px", paddingLeft: "5px"}}>
              {props.selectedLabOrderWorking?.payment_status_label}
            </div>
          </div>
          <div
            style={{display:"flex",  paddingBottom: "10px" }}>
            
            <div
              style={{fontWeight: "bold"}}>
              แพทย์ผู้สั่ง :
            </div>
            <div
              style={{marginRight:"2px", paddingLeft: "5px"}}>
              {props.selectedLabOrderWorking?.doctor_name}
            </div>
            <div
              style={{fontWeight: "bold", marginLeft: "3%"}}>
              แผนก :
            </div>
            <div
              style={{marginRight:"2px", paddingLeft: "5px"}}>
              {props.selectedLabOrderWorking?.division_name}
            </div>
            <div
              style={{fontWeight: "bold", marginLeft: "3%"}}>
              Remark:
            </div>
            <div
              style={{marginRight:"5px", paddingLeft: "5px"}}>
              {props.selectedLabOrderWorking?.note}
            </div>
          </div>
        </div>
        <div
          style={{display: "flex" }}>
          
          <Button
            color="blue"
            disabled={props.disabledAttachFile || false}
            loading={props.loadingPrint}
            onClick={props.handleAttachFile}>
            Attach file
          </Button>
          <div
            style={{flex: 1}}>
            
          </div>
          <Input
            onChange={props.changeUsername}
            placeholder="username"
            size="mini"
            style={{marginRight:"5px"}}
            value={props.username}>
          </Input>
          <Input
            onChange={props.changePassword}
            placeholder="password"
            size="mini"
            style={{marginRight:"5px"}}
            type="password"
            value={props.password}>
          </Input>
          <div
            style={{marginRight:"5px"}}>
            {props.buttonSave}
          </div>
          <div>
            {props.buttonAuthorize}
          </div>
        </div>
        <div
          style={{marginTop:"10px"}}>
          {props.tableReport}
        </div>
      </div>
    )
}

export default CardLabReportUX

export const screenPropsDefault = {}

/* Date Time : Fri Apr 07 2023 18:25:21 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "Attach file"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledAttachFile || false"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingPrint"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleAttachFile"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Input",
      "parent": 13,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeUsername"
        },
        "placeholder": {
          "type": "value",
          "value": "username"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.username"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Input",
      "parent": 13,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changePassword"
        },
        "placeholder": {
          "type": "value",
          "value": "password"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"5px\"}"
        },
        "type": {
          "type": "value",
          "value": "password"
        },
        "value": {
          "type": "code",
          "value": "props.password"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"10px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"10px\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab Results"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "hr",
      "parent": 17,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 20,
      "void": true
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.tableReport"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"10px\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",  paddingBottom: \"10px\" }"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab ID:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedLabOrderWorking?.pk"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"10px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเวลาที่สั่ง :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginLeft: \"3%\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedLabOrderWorking?.date"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"5px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ lab order:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginLeft: \"3%\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedLabOrderWorking?.status_name"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"5px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": " วันเวลาที่รับออเดอร์ :"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginLeft: \"3%\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedLabOrderWorking?.arrive_time"
        },
        "style": {
          "type": "code",
          "value": " {marginRight:\"5px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "การชำระเงิน :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginLeft: \"3%\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedLabOrderWorking?.payment_status_label"
        },
        "style": {
          "type": "code",
          "value": " {width:\"10%\",marginRight:\"5px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",  paddingBottom: \"10px\" }"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ผู้สั่ง :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedLabOrderWorking?.doctor_name"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"2px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginLeft: \"3%\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedLabOrderWorking?.division_name"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"2px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "Remark:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginLeft: \"3%\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedLabOrderWorking?.note"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"5px\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"5px\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonAuthorize"
        }
      },
      "seq": 80,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 50,
  "isMounted": false,
  "memo": false,
  "name": "CardLabReportUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 85
}

*********************************************************************************** */
