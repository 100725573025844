import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  Icon,
  FormField,
  Input,
  Button,
  Dropdown
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const ModStockAdjustDataUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          style={{backgroundColor: "var(--primary-theme-color,#5DBCD2)",margin: "-0.75rem -0.75rem -1rem", padding: "1rem", display: "flex", justifyContent: "space-between"}}>
          
          <div
            style={{fontSize: "1.1rem", fontWeight: "bold", padding: "2.5px 0", color: "white"}}>
            Adjust data
          </div>
          <div
            style={{cursor: "pointer", color: "white", marginTop: "2.5px"}}>
            
            <Icon
              className="white"
              name="close"
              onClick={props.onClose}>
            </Icon>
          </div>
        </div>
        <div
          className="ui divider">
          
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{marginLeft: "0.85rem",}}>
                {props.name || ""}
              </label>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{marginLeft: "0.85rem"}}>
                {`Storage:  ${props.storage || "-"}`}
              </label>
            </FormField>
            <FormField>
              <label
                style={{marginLeft: "0.85rem"}}>
                {`Bin Location:  ${props.binLocation|| "-"}`}
              </label>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form"
          style={{padding: "0 10px"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{color:"#797878", width: "65px"}}>
                Lot No.
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Input
                fluid={true}
                name="lot_no"
                onChange={props.onChangeValue}
                style={{width: "100%"}}
                value={props.lotNo || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{color:"#797878"}}>
                Expiry date
              </label>
            </FormField>
            <FormField
              inline={true}>
              <DateTextBox
                onChange={props.onChangeDate("expire_date")}
                value={props.expireDate || ""}>
              </DateTextBox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{color:"#797878", width: "65px"}}>
                Balance
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Input
                name="balance"
                onChange={props.onChangeValue}
                type="number"
                value={props.balance || ""}>
              </Input>
            </FormField>
            <FormField>
              <label
                style={{color: "#0072BC"}}>
                {props.editedDate || ""}
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{color:"#797878", width: "65px"}}>
                Notation
              </label>
            </FormField>
            <FormField
              inline={true}
              width={16}>
              <Dropdown
                allowAdditions={true}
                fluid={true}
                onAddItem={props.onAddItem}
                onChange={props.onChangeNotation}
                options={props.notationOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.notation || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{margin: "2rem 0 0.5rem", display: "flex", justifyContent: "center"}}>
          
          <div>
            {props.ButtonSave}
          </div>
          <Button
            color="red"
            onClick={props.onCancel}
            style={{width: "130px", margin: "0 2rem"}}>
            ยกเลิก
          </Button>
        </div>
      </div>
    )
}

export default React.memo(ModStockAdjustDataUX)

export const screenPropsDefault = {}

/* Date Time : Thu Feb 16 2023 09:52:59 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"var(--primary-theme-color,#5DBCD2)\",margin: \"-0.75rem -0.75rem -1rem\", padding: \"1rem\", display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Adjust data"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\", fontWeight: \"bold\", padding: \"2.5px 0\", color: \"white\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 10px\"}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", color: \"white\", marginTop: \"2.5px\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "Icon",
      "parent": 122,
      "props": {
        "className": {
          "type": "value",
          "value": "white"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormGroup",
      "parent": 132,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "label",
      "parent": 134,
      "props": {
        "children": {
          "type": "code",
          "value": "props.name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.85rem\",}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "FormGroup",
      "parent": 138,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "FormField",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "label",
      "parent": 140,
      "props": {
        "children": {
          "type": "code",
          "value": "`Storage:  ${props.storage || \"-\"}`"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.85rem\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "label",
      "parent": 142,
      "props": {
        "children": {
          "type": "value",
          "value": "Lot No."
        },
        "style": {
          "type": "code",
          "value": "{color:\"#797878\", width: \"65px\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "Input",
      "parent": 143,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "lot_no"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.lotNo || \"\""
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "label",
      "parent": 144,
      "props": {
        "children": {
          "type": "value",
          "value": "Expiry date"
        },
        "style": {
          "type": "code",
          "value": "{color:\"#797878\"}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 151,
      "name": "DateTextBox",
      "parent": 145,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate(\"expire_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.expireDate || \"\""
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "FormField",
      "parent": 159,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "label",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": "Notation"
        },
        "style": {
          "type": "code",
          "value": "{color:\"#797878\", width: \"65px\"}"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"2rem 0 0.5rem\", display: \"flex\", justifyContent: \"center\"}"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "Button",
      "parent": 164,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        },
        "style": {
          "type": "code",
          "value": "{width: \"130px\", margin: \"0 2rem\"}"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "FormField",
      "parent": 139,
      "props": {
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "label",
      "parent": 168,
      "props": {
        "children": {
          "type": "code",
          "value": "`Bin Location:  ${props.binLocation|| \"-\"}`"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.85rem\"}"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "FormField",
      "parent": 170,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 173,
      "name": "Input",
      "parent": 171,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "balance"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.balance || \"\""
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "FormField",
      "parent": 170,
      "props": {
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "label",
      "parent": 174,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editedDate || \"\""
        },
        "style": {
          "type": "code",
          "value": "{color: \"#0072BC\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "FormField",
      "parent": 170,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "label",
      "parent": 176,
      "props": {
        "children": {
          "type": "value",
          "value": "Balance"
        },
        "style": {
          "type": "code",
          "value": "{color:\"#797878\", width: \"65px\"}"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "FormField",
      "parent": 159,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "Dropdown",
      "parent": 178,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddItem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNotation"
        },
        "options": {
          "type": "code",
          "value": "props.notationOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.notation || \"\""
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "div",
      "parent": 164,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        }
      },
      "seq": 166,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "ModStockAdjustDataUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
