import WasmController from "../../../../frameworks/WasmController";

// APIs
// ADM
import EncounterStatReportView from "issara-sdk/apis/EncounterStatReportView_apps_ADM";

// CORE
import icd10KeyUp from "issara-sdk/apis/icd10KeyUp_core";

import moment from "moment";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";

// PDF
import FormPatientList from "../FormPatientList";
import FormStatList from "../FormStatList";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import getPdfMake from "react-lib/appcon/common/pdfMake";

export type State = {
  // sequence
  ReportPatientListSequence?: Partial<{
    sequenceIndex: "Start" | "Action" | null;

    filter: Partial<{
      isHN: boolean;
      patient: Partial<{
        id: number;
        hn: string;
        full_name: string;
        birthdate: string;
      }> | null;
      patientCase: number | "ALL";
      division: number | null;
      isOPD: boolean;
      isIPD: boolean;
      isDoctor: boolean;
      isDiagnosis: boolean;
      doctor: number | null;
      startDate: string;
      endDate: string;
      diagnosis: number;
    }>;
    orderReportList: any[];
    statList: any[];
  }> | null;
};

type Picked = Partial<
  Pick<
    MainState,
    | "searchedItemListWithKey"
    | "errorMessage"
    | "successMessage"
    | "buttonLoadCheck"
    | "django"
    | "selectedDivision"
    | "errorMessage"
  >
>;

export type MasterOptionsType = Record<typeof Masters[number][0], OptionType[]>;

type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
};

// Sequence
type SeqState = {
  sequence: "ReportPatientList";
  restart?: boolean;
  clear?: boolean;
  card?: string;
};

// Handle Action
type ActionType =
  | { action: "SEARCH"; card: string }
  | { action: "PRINT"; card: string }
  | { action: "SEARCH_STATISTIC"; card: string }
  | { action: "PRINT_STATISTIC"; card: string }
  | { action: "SEARCH_DIAGNOSIS"; data: Record<string, any> };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(
  params: SeqType<Pick<SeqAct, K>>
) => any;

type Params<A extends ActionType["action"]> = Extract<
  ActionType,
  { action: A }
>;

export const StateInitial: State = {
  ReportPatientListSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler<P = any, R = any> = (
  controller: WasmController<State & Picked, Event, Data>,
  params: P
) => R;

const Masters = [["patientCase", {}]] as const;

export const GetMaster: Handler<SeqState> = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  } as any);

  const division = state.selectedDivision || {};

  controller.setState({
    ReportPatientListSequence: {
      sequenceIndex: "Action",
      filter: {
        patientCase: "ALL",
        division: division.id,
        isOPD: true,
        isIPD: true,
        startDate: formatDate(moment()),
        endDate: formatDate(moment()),
      },
    },
    searchedItemListWithKey: {
      Division_1: [{ id: division.id, name: division.name }],
    },
  });
};

export const Action: Handler<ActionType> = async (controller, params) => {
  if (params.action === "SEARCH") {
    HandleSearch(controller, params);
  } else if (params.action === "PRINT") {
    HandlePrint(controller, params);
  } else if (params.action === "SEARCH_STATISTIC") {
    HandleSearchStatistic(controller, params);
  } else if (params.action === "PRINT_STATISTIC") {
    HandlePrintStatistic(controller, params);
  } else if (params.action === "SEARCH_DIAGNOSIS") {
    HandleSearchDiagnosis(controller, params);
  }
};

const HandleSearch: Handler<Params<"SEARCH">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const [response, error] = await GetEncounterStatReport(controller, {});

  controller.setState({
    ReportPatientListSequence: {
      ...state.ReportPatientListSequence,
      orderReportList: response?.items || [],
    },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: !!error ? "ERROR" : "SUCCESS",
    },
    errorMessage: {
      ...state.errorMessage,
      [params.card]: error,
    },
  });
};

const HandlePrint: Handler<Params<"PRINT">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const filter = state.ReportPatientListSequence?.filter;

  const data = {
    items: state.ReportPatientListSequence?.orderReportList || [],
    staff: state.django.user.full_name,
    start_date: filter?.startDate,
    end_date: filter?.endDate,
  };

  let docDef: any = { content: [] };

  docDef = FormPatientList(data);

  (await getPdfMake()).createPdf(docDef).open();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "SUCCESS",
    },
  });
};

const HandleSearchStatistic: Handler<Params<"SEARCH_STATISTIC">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const [response, error] = await GetEncounterStatReport(controller, {
    group_by: "stat",
  });

  controller.setState({
    ReportPatientListSequence: {
      ...state.ReportPatientListSequence,
      statList: response?.items || [],
    },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: !!error ? "ERROR" : "SUCCESS",
    },
    errorMessage: {
      ...state.errorMessage,
      [params.card]: error,
    },
  });
};

const HandlePrintStatistic: Handler<Params<"PRINT_STATISTIC">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const filter = state.ReportPatientListSequence?.filter;

  const data = {
    items: state.ReportPatientListSequence?.statList || [],
    staff: state.django.user.full_name,
    start_date: filter?.startDate,
    end_date: filter?.endDate,
  };

  let docDef: any = { content: [] };

  docDef = FormStatList(data);

  (await getPdfMake()).createPdf(docDef).open();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "SUCCESS",
    },
  });
};

export const HandleSearchDiagnosis: Handler<Params<"SEARCH_DIAGNOSIS">> = async (
  controller,
  params
) => {
  let state = controller.getState();

  const urlParams = params.data.params || {};
  const searchText = urlParams.searchText || "";

  if (urlParams.action === "clear") {
    return controller.setState({
      searchedItemListWithKey: {
        ...state.searchedItemListWithKey,
        [`${urlParams.type}_${urlParams.id}`]: []
      },
    });
  }

  const [[meterm], [icdcode]] = await Promise.all([
    GetIcd10KeyUp(controller, { search: searchText, method: "medterm" }),
    GetIcd10KeyUp(controller, { search: searchText, method: "icdcode" }),
  ]);

  const mapIcd10KeyUp = (items: any[]) => {
    return (items || []).map((item: any) => ({
      ...item,
      id: item.icd10_id,
      name: `[${item.icdcode}] ${item.icdterm}`,
    }));
  };

  state = controller.getState();

  const key = "id";
  // @ts-ignore
  const arrayUniqueByKey = Array.from(
    new Map(
      [
        ...mapIcd10KeyUp(icdcode?.response),
        ...mapIcd10KeyUp(meterm?.response),
      ].map((item) => [item[key], item])
    ).values()
  );

  controller.setState({
    searchedItemListWithKey: {
      ...state.searchedItemListWithKey,
      [`${urlParams.type}_${urlParams.id}`]: arrayUniqueByKey,
    },
  });
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
export const GetIcd10KeyUp: Handler<{
  search: string;
  method: "medterm" | "icdcode";
}> = (controller, params) => {
  return icd10KeyUp.post({
    method: params.method,
    apiToken: controller.apiToken,
    data: {
      [params.method]: params.search,
    },
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });
};

const GetEncounterStatReport: Handler = async (controller, params) => {
  const state = controller.getState();

  const filter = state.ReportPatientListSequence?.filter;

  return EncounterStatReportView.get({
    apiToken: controller.apiToken,
    params: {
      patient: filter?.isHN ? filter?.patient?.id : undefined,
      patient_case:
        filter?.patientCase === "ALL" ? undefined : filter?.patientCase, //# ClinicalTerm that type.code = 'PATIENT_CASE'
      division: filter?.division,
      doctor: filter?.isDoctor ? filter?.doctor : undefined,
      icd10: filter?.isDiagnosis ? filter?.diagnosis : undefined,
      start_date: filter?.startDate,
      end_date: filter?.endDate,
      opd: filter?.isOPD,
      ipd: filter?.isIPD,
      ...params,
    },
  });
};
