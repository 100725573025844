import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import {
  Label,
  Input,
  Dropdown,
  Radio,
  Button,
  TextArea,
} from "semantic-ui-react";
import { DateTextBox } from "react-lib/apps/common";
import { Calendar } from "react-big-calendar";

const ModPlanningUX = (props: any) => {
  return (
    <div style={{ height: "500px" }}>
      <div style={{ height: "25%", paddingTop: "30px" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(4, 1fr)",
            gridTemplateColumns: "repeat(18, 1fr)",
          }}
        >
          <div style={{ gridRow: "2/3", gridColumn: "2/18" }}>
            {props.planSummary}
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "2/4" }}>ประเภท</div>
          <div style={{ gridRow: "4/5", gridColumn: "4/10" }}>
            <Dropdown
              fluid={true}
              name="type"
              onChange={props.onChangePlanningData}
              options={[
                { key: 1, value: "NORMAL", text: "ปกติ" },
                { key: 2, value: "CUSTOM", text: "เลือกเอง" },
              ]}
              selection={true}
              value={props.planningData?.type || "NORMAL"}
            ></Dropdown>
          </div>
        </div>
      </div>
      <div
        style={{
          display: props.planningData?.type === "CUSTOM" ? "" : "none",
          height: "55%",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(11, 1fr)",
            gridTemplateColumns: "repeat(18, 1fr)",
          }}
        >
          <div style={{ gridRow: "2/9", gridColumn: "6/14" }}>
            <Calendar
              events={props.planningData?.dates || []}
              localizer={props.calendarLocalizer}
              min={new Date()}
              onNavigate={props.onCalendarNavigate}
              views={["month"]}
            ></Calendar>
          </div>
          <div style={{ gridRow: "10/12", gridColumn: "2/18" }}>
            <TextArea
              disabled={true}
              style={{ width: "100%", textAlign: "center" }}
              value={props.planningData?.dates?.join("\n")}
            ></TextArea>
          </div>
        </div>
      </div>
      <div
        style={{
          display: props.planningData?.type !== "CUSTOM" ? "" : "none",
          height: "55%",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(11, 1fr)",
            gridTemplateColumns: "repeat(18, 1fr)",
          }}
        >
          <div style={{ gridRow: "2/3", gridColumn: "2/4" }}>เริ่มต้น</div>
          <div style={{ gridRow: "2/3", gridColumn: "4/10" }}>
            <DateTextBox
              minDate={props.today}
              onChange={props.onChangePlanningStartDate}
              value={props.planningData?.start_date}
            ></DateTextBox>
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "2/4" }}>ทำซ้ำ</div>
          <div style={{ gridRow: "4/5", gridColumn: "4/9" }}>
            <Input
              fluid={true}
              name="day_take"
              onChange={props.onChangePlanningData}
              placeholder="ระบุตัวเลข"
              type="number"
              value={props.planningData?.day_take}
            ></Input>
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "9/10" }}>
            <Label>วัน</Label>
          </div>
          <div
            style={{ gridRow: "4/5", gridColumn: "10/12", textAlign: "center" }}
          >
            เว้น
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "12/17" }}>
            <Input
              fluid={true}
              name="day_skip"
              onChange={props.onChangePlanningData}
              placeholder="ระบุตัวเลข"
              type="number"
              value={props.planningData?.day_skip}
            ></Input>
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "17/18" }}>
            <Label>วัน</Label>
          </div>
          <div style={{ gridRow: "6/7", gridColumn: "2/4" }}>ในวัน</div>
          <div style={{ gridRow: "6/7", gridColumn: "4/18" }}>
            <Dropdown
              fluid={true}
              multiple={true}
              name="week_days"
              onChange={props.onChangePlanningData}
              options={[
                { key: 1, value: "Mon", text: "จันทร์" },
                { key: 2, value: "Tue", text: "อังคาร" },
                { key: 3, value: "Wed", text: "พุธ" },
                { key: 4, value: "Thu", text: "พฤหัสบดี" },
                { key: 5, value: "Fri", text: "ศุกร์" },
                { key: 6, value: "Sat", text: "เสาร์" },
                { key: 7, value: "Sun", text: "อาทิตย์" },
              ]}
              selection={true}
              value={props.planningData?.week_days}
            ></Dropdown>
          </div>
          <div style={{ gridRow: "8/9", gridColumn: "2/4" }}>สิ้นสุด</div>
          <div style={{ gridRow: "8/9", gridColumn: "4/10" }}>
            <Radio
              checked={
                !["OCCURRENCE", "END_DATE"].includes(
                  props.planningData?.endDate
                )
              }
              label="ไม่มี"
              name="endDate"
              onChange={props.onChangePlanningData}
              value="NONE"
            ></Radio>
          </div>
          <div style={{ gridRow: "9/10", gridColumn: "4/9" }}>
            <Radio
              checked={props.planningData?.endDate === "OCCURRENCE"}
              label="หลังจากเกิดขึ้น"
              name="endDate"
              onChange={props.onChangePlanningData}
              value="OCCURRENCE"
            ></Radio>
          </div>
          <div style={{ gridRow: "9/10", gridColumn: "9/17" }}>
            <Input
              disabled={props.planningData?.endDate !== "OCCURRENCE"}
              fluid={true}
              name="occurrence"
              onChange={props.onChangePlanningData}
              value={props.planningData?.occurrence}
            ></Input>
          </div>
          <div style={{ gridRow: "9/10", gridColumn: "17/18" }}>
            <Label>วัน</Label>
          </div>
          <div style={{ gridRow: "10/11", gridColumn: "4/9" }}>
            <Radio
              checked={props.planningData?.endDate === "END_DATE"}
              label="ในวันที่"
              name="endDate"
              onChange={props.onChangePlanningData}
              value="END_DATE"
            ></Radio>
          </div>
          <div style={{ gridRow: "10/11", gridColumn: "9/17" }}>
            <DateTextBox
              disabled={props.planningData?.endDate !== "END_DATE"}
              minDate={props.today}
              onChange={props.onChangePlanningEndDate}
              value={props.planningData?.end_date}
            ></DateTextBox>
          </div>
        </div>
      </div>
      <div style={{ height: "20%" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(5, 1fr)",
            gridTemplateColumns: "repeat(18, 1fr)",
          }}
        >
          <div
            style={{
              gridRow: "2/3",
              gridColumn: "2/18",
              padding: "5px",
              textAlign: "center",
              border: "solid 1px rgb(200, 200, 200, 0.5)",
            }}
          >
            {props.planningData?.date_time_summary}
          </div>
          <div
            style={{
              gridRow: "4/5",
              gridColumn: "2/18",
              textAlign: "center",
              display: "flex",
            }}
          >
            <Button
              color="green"
              onClick={props.onRequestPlanning}
              style={{
                display: props.planningData?.allowed_actions?.includes(
                  "REQUEST"
                )
                  ? ""
                  : "none",
              }}
            >
              OK
            </Button>
            <div>{props.buttonEdit}</div>
            <div>{props.buttonResume}</div>
            <div>{props.buttonOff}</div>
            <div>{props.buttonHold}</div>
            <div>{props.buttonRenew}</div>
            <Button color="grey" onClick={props.onClose}>
              CLOSE
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModPlanningUX;

export const screenPropsDefault = {};

/* Date Time : Tue Jul 05 2022 15:55:25 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{height: \"500px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 105,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(11, 1fr)\",\"gridTemplateColumns\":\"repeat(18, 1fr)\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "เริ่มต้น"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"2/4\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ทำซ้ำ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"2/4\" }"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "เว้น"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"10/12\", textAlign: \"center\" }"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"4/9\" }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"9/10\" }"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"12/17\" }"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"17/18\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Label",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Label",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Input",
      "parent": 38,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "label": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "day_take"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningData"
        },
        "placeholder": {
          "type": "value",
          "value": "ระบุตัวเลข"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.planningData?.day_take"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Input",
      "parent": 40,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "day_skip"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningData"
        },
        "placeholder": {
          "type": "value",
          "value": "ระบุตัวเลข"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.planningData?.day_skip"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"4/10\" }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"4/18\" }"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Dropdown",
      "parent": 117,
      "props": {
        "defaultValue": {
          "type": "value",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningData "
        },
        "options": {
          "type": "code",
          "value": "[ {key: 1, value: \"NORMAL\", text: \"ปกติ\"}, {key: 2, value: \"CUSTOM\", text: \"เลือกเอง\"} ]"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.planningData?.type || \"NORMAL\""
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 61,
      "name": "DateTextBox",
      "parent": 57,
      "props": {
        "minDate": {
          "type": "code",
          "value": "props.today"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningStartDate"
        },
        "style": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.planningData?.start_date"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Dropdown",
      "parent": 59,
      "props": {
        "allowAdditions": {
          "type": "value",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "week_days"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningData"
        },
        "options": {
          "type": "code",
          "value": "[ {key: 1, value: \"Mon\", text: \"จันทร์\"}, { key: 2, value: \"Tue\", text: \"อังคาร\" }, {key: 3, value: \"Wed\", text: \"พุธ\"}, { key: 4, value: \"Thu\", text: \"พฤหัสบดี\" }, {key: 5, value: \"Fri\", text: \"ศุกร์\"}, { key: 6, value: \"Sat\", text: \"เสาร์\" }, {key: 7, value: \"Sun\", text: \"อาทิตย์\"} ]"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.planningData?.week_days"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ในวัน"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"2/4\" }"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "สิ้นสุด"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"2/4\" }"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"4/10\" }"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Radio",
      "parent": 68,
      "props": {
        "checked": {
          "type": "code",
          "value": "![\"OCCURRENCE\", \"END_DATE\"].includes(props.planningData?.endDate)"
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "endDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningData"
        },
        "value": {
          "type": "value",
          "value": "NONE"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"4/9\" }"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Radio",
      "parent": 72,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.planningData?.endDate === \"OCCURRENCE\""
        },
        "label": {
          "type": "value",
          "value": "หลังจากเกิดขึ้น"
        },
        "name": {
          "type": "value",
          "value": "endDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningData"
        },
        "value": {
          "type": "value",
          "value": "OCCURRENCE"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"9/17\" }"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"17/18\" }"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Input",
      "parent": 78,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.planningData?.endDate !== \"OCCURRENCE\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "occurrence"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningData"
        },
        "readOnly": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.planningData?.occurrence"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Label",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"4/9\" }"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"9/17\" }"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Radio",
      "parent": 88,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.planningData?.endDate === \"END_DATE\""
        },
        "label": {
          "type": "value",
          "value": "ในวันที่"
        },
        "name": {
          "type": "value",
          "value": "endDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningData"
        },
        "value": {
          "type": "value",
          "value": "END_DATE"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 91,
      "name": "DateTextBox",
      "parent": 89,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.planningData?.endDate !== \"END_DATE\""
        },
        "minDate": {
          "type": "code",
          "value": "props.today"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningEndDate"
        },
        "value": {
          "type": "code",
          "value": "props.planningData?.end_date"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 121,
      "props": {
        "children": {
          "type": "code",
          "value": "props.planningData?.date_time_summary"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"2/18\", padding: \"5px\", textAlign: \"center\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 121,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"2/18\", textAlign: \"center\", display: \"flex\" }"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Button",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "OK"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onRequestPlanning"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.planningData?.allowed_actions?.includes(\"REQUEST\") ? \"\" : \"none\" }"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Button",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "CLOSE"
        },
        "color": {
          "type": "value",
          "value": "grey"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.planningData?.type !== \"CUSTOM\" ? \"\" : \"none\", height: \"55%\" }"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "label": "divgrid",
      "name": "div",
      "parent": 118,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(4, 1fr)\",\"gridTemplateColumns\":\"repeat(18, 1fr)\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "code",
          "value": "props.planSummary"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"2/18\" }"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภท"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"2/4\" }"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 106,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"4/10\" }"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"25%\", paddingTop: \"30px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.planningData?.type === \"CUSTOM\" ? \"\" : \"none\", height: \"55%\" }"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"20%\" }"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "label": "divgrid",
      "name": "div",
      "parent": 120,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(5, 1fr)\",\"gridTemplateColumns\":\"repeat(18, 1fr)\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "react-big-calendar",
      "id": 123,
      "name": "Calendar",
      "parent": 133,
      "props": {
        "events": {
          "type": "code",
          "value": "props.planningData?.dates || []"
        },
        "localizer": {
          "type": "code",
          "value": "props.calendarLocalizer"
        },
        "min": {
          "type": "code",
          "value": "new Date()"
        },
        "onNavigate": {
          "type": "code",
          "value": "props.onCalendarNavigate"
        },
        "onSelectEvent": {
          "type": "value",
          "value": ""
        },
        "views": {
          "type": "code",
          "value": "[\"month\"]"
        }
      },
      "seq": 123,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "TextArea",
      "parent": 132,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", textAlign: \"center\" }"
        },
        "value": {
          "type": "code",
          "value": "props.planningData?.dates?.join('\\n')"
        }
      },
      "seq": 124,
      "void": true
    },
    {
      "from": null,
      "id": 125,
      "label": "divgrid",
      "name": "div",
      "parent": 119,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(11, 1fr)\",\"gridTemplateColumns\":\"repeat(18, 1fr)\"}"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 125,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/12\", gridColumn: \"2/18\" }"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 125,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/9\", gridColumn: \"6/14\" }"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonResume"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonOff"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonHold"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonRenew"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonEdit"
        }
      },
      "seq": 101,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "ModPlanningUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
