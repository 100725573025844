import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Input,
  Dropdown,
  Button
} from 'semantic-ui-react'

const CardDrugResuscitationEditUX = (props: any) => {
    return(
      <div
        style={{  margin: "20px"}}>
        <Form>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width="5">
              <label>
                Code
              </label>
              <div
                style={{  width: "100%", padding: "8px", backgroundColor: "rgba(128, 128, 128, 0.5)"}}>
                {props.editData?.drugDetail?.code || ""}
              </div>
            </FormField>
            <FormField
              inline={true}
              width="5">
              <label>
                Trade
              </label>
              <div
                style={{  width: "100%", padding: "8px", backgroundColor: "rgba(128, 128, 128, 0.5)"}}>
                {props.editData?.drugDetail?.name || ""}
              </div>
            </FormField>
            <FormField
              inline={true}
              width="6">
              <label>
                Generic
              </label>
              <div
                style={{  width: "100%", padding: "8px", backgroundColor: "rgba(128, 128, 128, 0.5)"}}>
                {props.editData?.drugDetail?.generic_name || ""}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width="5">
              <div
                style={{display: "flex" }}>
                
                <label>
                   Quantity
                </label>
                <label
                  style={{ color: "red"}}>
                  *
                </label>
              </div>
              <div
                style={{ width: "100%", padding: "0px 10px"}}>
                
                <Input
                  name="quantity"
                  onChange={props.onChangeData}
                  value={props.editData?.quantity || ""}>
                </Input>
              </div>
            </FormField>
            <FormField
              inline={true}
              width="5">
              <div>
                
                <label>
                  Dose
                </label>
                <label
                  style={{ color: "red"}}>
                  *
                </label>
              </div>
              <div
                style={{ width: "100%", padding: "0px 10px"}}>
                
                <Input
                  name="dose"
                  onChange={props.onChangeData}
                  value={props.editData?.dose || ""}>
                </Input>
              </div>
            </FormField>
            <FormField
              inline={true}
              width="6">
              <div>
                
                <label>
                  Unit
                </label>
                <label
                  style={{ color: "red" }}>
                  *
                </label>
              </div>
              <div
                style={{ width: "100%", padding: "0px 10px"}}>
                
                <Dropdown
                  fluid={true}
                  name="unit"
                  onChange={props.onChangeData}
                  options={props.unitOptions}
                  selection={true}
                  value={props.editData?.unit || ""}>
                </Dropdown>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width="8">
              <label>
                Route
              </label>
              <div
                style={{ width: "100%", padding: "0px 10px"}}>
                
                <Dropdown
                  fluid={true}
                  name="route"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.route}
                  selection={true}
                  value={props.editData?.route || ""}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              inline={true}
              width="8">
              <label>
                Site
              </label>
              <div
                style={{ width: "100%", padding: "0px 10px"}}>
                
                <Dropdown
                  fluid={true}
                  name="method"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.site}
                  selection={true}
                  value={props.editData?.method || ""}>
                </Dropdown>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width="8">
              <div>
                
                <label>
                  Nurse
                </label>
                <label
                  style={{ color: "red" }}>
                  *
                </label>
              </div>
              <div
                style={{ width: "100%", padding: "0px 10px"}}>
                
                <Input
                  name="employeeCode"
                  onChange={props.onChangeData}
                  onKeyDown={props.handleKeyDown}
                  placeholder="Code"
                  value={props.editData?.employeeCode || ""}>
                </Input>
              </div>
            </FormField>
            <FormField
              inline={true}
              width="8">
              <div>
                
                <label>
                  Time
                </label>
                <label
                  style={{  color: "red" }}>
                  *
                </label>
              </div>
              <div>
                {props.time}
              </div>
            </FormField>
          </FormGroup>
        </Form>
        <div
          style={{ display: "flex", justifyContent: "space-between"  }}>
          
          <div>
            
          </div>
          <div
            style={{ display: "flex" }}>
            
            <div>
              {props.onSaveEdit}
            </div>
            <Button
              color="red"
              onClick={props.onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    )
}

export default CardDrugResuscitationEditUX

export const screenPropsDefault = {"debug":true,"showSupplyList":false}

/* Date Time : Fri Mar 10 2023 04:54:09 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{  margin: \"20px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"space-between\"  }"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "Form",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "FormGroup",
      "parent": 135,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormField",
      "parent": 136,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "5"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 137,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editData?.drugDetail?.code || \"\""
        },
        "style": {
          "type": "code",
          "value": "{  width: \"100%\", padding: \"8px\", backgroundColor: \"rgba(128, 128, 128, 0.5)\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "label",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": "Code"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "FormField",
      "parent": 136,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "5"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "FormField",
      "parent": 136,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "6"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "label",
      "parent": 140,
      "props": {
        "children": {
          "type": "value",
          "value": "Trade"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": "Generic"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "div",
      "parent": 140,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editData?.drugDetail?.name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{  width: \"100%\", padding: \"8px\", backgroundColor: \"rgba(128, 128, 128, 0.5)\"}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 141,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editData?.drugDetail?.generic_name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{  width: \"100%\", padding: \"8px\", backgroundColor: \"rgba(128, 128, 128, 0.5)\"}"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "FormGroup",
      "parent": 135,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "FormGroup",
      "parent": 135,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "FormGroup",
      "parent": 135,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "FormField",
      "parent": 146,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "5"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "FormField",
      "parent": 146,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "5"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "FormField",
      "parent": 146,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "6"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "FormField",
      "parent": 147,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "8"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "FormField",
      "parent": 147,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "8"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "FormField",
      "parent": 148,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "8"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "FormField",
      "parent": 148,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "8"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "label",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": "Site"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "label",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": "Route"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "div",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", padding: \"0px 10px\"}"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "div",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", padding: \"0px 10px\"}"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "Input",
      "parent": 180,
      "props": {
        "name": {
          "type": "value",
          "value": "dose"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.editData?.dose || \"\""
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "Input",
      "parent": 177,
      "props": {
        "name": {
          "type": "value",
          "value": "quantity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.editData?.quantity || \"\""
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "div",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", padding: \"0px 10px\"}"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "Dropdown",
      "parent": 184,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "open": {
          "type": "value",
          "value": ""
        },
        "options": {
          "type": "code",
          "value": "props.unitOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.editData?.unit || \"\""
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 186,
      "name": "div",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\" }"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "label",
      "parent": 186,
      "props": {
        "children": {
          "type": "value",
          "value": " Quantity"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "label",
      "parent": 186,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "label",
      "parent": 189,
      "props": {
        "children": {
          "type": "value",
          "value": "Dose"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "label",
      "parent": 189,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\"}"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "div",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 193,
      "name": "label",
      "parent": 192,
      "props": {
        "children": {
          "type": "value",
          "value": "Unit"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "label",
      "parent": 192,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": null,
      "id": 195,
      "name": "div",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", padding: \"0px 10px\"}"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": null,
      "id": 196,
      "name": "div",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", padding: \"0px 10px\"}"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "Dropdown",
      "parent": 195,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "route"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.route"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.editData?.route || \"\""
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "Dropdown",
      "parent": 196,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "method"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.site"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.editData?.method || \"\""
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": null,
      "id": 199,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": null,
      "id": 200,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", padding: \"0px 10px\"}"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": null,
      "id": 201,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": null,
      "id": 203,
      "name": "label",
      "parent": 199,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": null,
      "id": 204,
      "name": "label",
      "parent": 199,
      "props": {
        "children": {
          "type": "value",
          "value": "Nurse"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": null,
      "id": 205,
      "name": "label",
      "parent": 201,
      "props": {
        "children": {
          "type": "value",
          "value": "Time"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": null,
      "id": 206,
      "name": "label",
      "parent": 201,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{  color: \"red\" }"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 207,
      "name": "Input",
      "parent": 200,
      "props": {
        "disabled": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "employeeCode"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.handleKeyDown"
        },
        "placeholder": {
          "type": "value",
          "value": "Code"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.editData?.employeeCode || \"\""
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "Button",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": "Cancel"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": null,
      "id": 213,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.time"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": null,
      "id": 215,
      "name": "div",
      "parent": 126,
      "props": {
        "children": {
          "type": "code",
          "value": "props.onSaveEdit"
        }
      },
      "seq": 209,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugResuscitationEditUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "debug": true,
    "showSupplyList": false
  },
  "width": 80
}

*********************************************************************************** */
