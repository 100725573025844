import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown,
  Input,
  Button
} from 'semantic-ui-react'

const CardReceiveOrderUX  = (props: any) => {
    return(
      <div
        style={{ width: "100%",padding: "10px" }}>
        <div
          style={{display: props.hidePanel ? "none" : "flex", alignItems: "center"}}>
          
          <div>
            {props.nameAuto ? "สเเกนรหัส" : "กรอกรหัส" }
          </div>
          <div
            style={{marginRight: "15px",marginLeft:"15px"}}>
            {}
            <Input
              onChange={props.handleChangeValue("selectedCode")}
              style={{width:"500px"}}
              type="text"
              value={props.selectedCode}>
            </Input>
            <Button
              color="green"
              icon="search"
              onClick={props.onhandleSearch}
              style={{marginRight: "15px"}}>
            </Button>
          </div>
          <Dropdown
            clearable={true}
            defaultValue="auto"
            onChange={props.handleChangeValue("selectedType")}
            options={props.options}
            search={true}
            selection={true}
            value={props.selectedType}>
          </Dropdown>
        </div>
        <div
          style={{display: props.encounter ? "" : "none"}}>
          
          <div
            style={{paddingTop: "20px", display: "flex", }}>
            
            <div>
              
            </div>
            <div
              style={{marginTop:"10px",marginRight:"10px"}}>
              HN
            </div>
            <Input
              readOnly={true}
              value={props.hn}>
            </Input>
            <div
              style={{margin:"10px 10px 0px 20px"}}>
              ชื่อผู้ป่วย
            </div>
            <Input
              readOnly={true}
              value={props.name}>
            </Input>
            <div
              style={{margin:"10px 10px 0px 20px"}}>
              เพศ
            </div>
            <Input
              readOnly={true}
              value={props.gender}>
            </Input>
            <div
              style={{margin:"10px 10px 0px 20px"}}>
              วันเกิด
            </div>
            <Input
              readOnly={true}
              value={props.birthdate}>
            </Input>
            <div
              style={{margin:"10px 10px 0px 20px"}}>
              อายุ
            </div>
            <Input
              readOnly={true}
              value={props.age}>
            </Input>
          </div>
        </div>
      </div>
    )
}

export default CardReceiveOrderUX 

export const screenPropsDefault = {"height":"40vh","patientSearch":"[Patient Search Box]"}

/* Date Time : Fri Jun 24 2022 02:47:26 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\",padding: \"10px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.hidePanel ? \"none\" : \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"15px\",marginLeft:\"15px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nameAuto ? \"สเเกนรหัส\" : \"กรอกรหัส\" "
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Dropdown",
      "parent": 15,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "defaultValue": {
          "type": "value",
          "value": "auto"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedType\")"
        },
        "options": {
          "type": "code",
          "value": "props.options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.selectedType"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Input",
      "parent": 16,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedCode\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"500px\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.selectedCode"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Button",
      "parent": 16,
      "props": {
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "onClick": {
          "type": "code",
          "value": "props.onhandleSearch"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"15px\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.encounter ? \"\" : \"none\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"20px\", display: \"flex\", }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"10px\",marginRight:\"10px\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px 10px 0px 20px\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "เพศ"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px 10px 0px 20px\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเกิด"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px 10px 0px 20px\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "อายุ"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px 10px 0px 20px\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Input",
      "parent": 39,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.hn"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Input",
      "parent": 39,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.name"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Input",
      "parent": 39,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.gender"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Input",
      "parent": 39,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.birthdate"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Input",
      "parent": 39,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.age"
        }
      },
      "seq": 52,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 20,
  "isMounted": false,
  "memo": false,
  "name": "CardReceiveOrderUX ",
  "project": "IsHealth",
  "screenPropsDefault": {
    "height": "40vh",
    "patientSearch": "[Patient Search Box]"
  },
  "width": 90
}

*********************************************************************************** */
