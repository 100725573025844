import WasmController from "react-lib/frameworks/WasmController";

// APIs
// Core
import StockAdd from "issara-sdk/apis/StockAdd_core";
import AggregateStockList from "issara-sdk/apis/AggregateStockList_core";
import ProductList from "issara-sdk/apis/ProductList_core";
import ProductLotListCreate from "issara-sdk/apis/ProductLotListCreate_core";
import ProductStockList from "issara-sdk/apis/ProductStockList_coreM";
import DrugCreate from "issara-sdk/apis/DrugCreate_apps_TPD";
import SupplyNewList from "issara-sdk/apis/SupplyNewList_apps_MSD";
import ProductTypeList from "issara-sdk/apis/ProductTypeList_core";
import UpdateAggregateStock from "issara-sdk/apis/UpdateAggregateStock_core";
import DivisionList from "issara-sdk/apis/DivisionList_core";
import StockLogList from "issara-sdk/apis/StockLogList_core";
import StockReconcileDetail from "issara-sdk/apis/StockReconcileView_core";
import ProductStockReconcileView from "issara-sdk/apis/ProductStockReconcileView_core";
import DefaultBarcodeView from "issara-sdk/apis/DefaultBarcodeView_core";
import DispenseOrderList from "issara-sdk/apis/DispenseOrderList_core";
import DispenseOrderItemList from "issara-sdk/apis/DispenseOrderItemList_core";
import DispenseOrderDetail from "issara-sdk/apis/DispenseOrderDetail_core";
import DispenseOrderItemPlanList from "issara-sdk/apis/DispenseOrderItemPlanList_core";
// Bil
import BillModeList from "issara-sdk/apis/BillModeList_apps_BIL";
// TPD
import DrugTransferRequestList from "issara-sdk/apis/DrugTransferRequestList_apps_TPD";
import DrugTransferRequestItemPlanList from "issara-sdk/apis/DrugTransferRequestItemPlanList_apps_TPD";
import DrugOrderDispenseReport from "issara-sdk/apis/DrugOrderDispenseReport_apps_TPD";
import DrugOrderActionView from "issara-sdk/apis/DrugOrderActionView_apps_TPD";
// MSD
import SupplyTransferRequestListCreate from "issara-sdk/apis/SupplyTransferRequestListCreate_apps_MSD";
import SupplyTransferRequestItemPlanList from "issara-sdk/apis/SupplyTransferRequestItemPlanList_apps_MSD";
import SupplyOrderDetail from "issara-sdk/apis/SupplyOrderDetail_apps_MSD";
// USERS
import MyPermissionView from "issara-sdk/apis/MyPermissionView_users";
// REG
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";

// Serializer
import StockLogSerializerI from "issara-sdk/types/StockLogSerializer_core";
import StockReconcileSerializerI from "issara-sdk/types/StockReconcileSerializer_core";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";

import moment from "moment";
import { PDFDocument } from "pdf-lib";

// Form
import FormTransferStock from "../FormTransferStock";
import FormPatientMedicationDispense from "../FormPatientMedicationDispense";
import FormRequestStock from "../FormRequestStock";
import FormAddStock from "../FormAddStock";

// Utils
import getPdfMake from "react-lib/appcon/common/pdfMake";
import { beToAd, formatDate } from "react-lib/utils/dateUtils";
import { base64toBlob } from "react-lib/apps/HISV3/common/CommonInterface";
import FormIssueStock from "../FormIssueStock";

export type State = Partial<{
  // CommonInterface
  masterOptions?: MasterOptionsType;
  firstName?: string;
  lastName?: string;

  // sequence
  StockManagementSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    filterStock: FilterStockType;
    tempFilterStock: FilterStockType;
    filterHistory: Partial<HistoryFilterType>;
    productDetail: Partial<ProductDetailType>;
    stockList: AggStockSerializer[];
    billModeOptions: OptionType[];
    productTypeOptions: OptionType[];
    productLotList: ProductLotSerializer[];
    productStockList: ProductStockSerializer[];
    productStockByLotList: ProductStockSerializer[];
    auditLogList: StockLogSerializer[];
    stockLogList: StockLogSerializer[];
    stockReconcileList: StockReconcileSerializer[];
    myPermissionList: any[];
    permissions: PermissionsType;
    isCounting: boolean;
    pagination: PaginationType[];
    activePage: number;
    modeOptions: OptionType[];
    divisionTypeDrugOptions: OptionType[];
    movementTypeOptions: OptionType[];
    selectedStock: AggStockSerializer | null;
    stockStorageDetail: StockStorageDetailType;
  }> | null;
}>;

export type AggStockSerializer = {
  id: number;
  product: {
    id: number;
    name: string;
    name_en: string;
    code: string;
    p_type_name?:
      | "ค่ายาและสารอาหาร"
      | "ค่าเวชภัณฑ์"
      | "ค่าอุปกรณ์ของใช้และเครื่องมือทางการแพทย์";
    unit_name?: string;
  };
  storage: Partial<{
    id: number;
    name: string;
    name_en: string;
    code: string;
  }>;
  bin_location: string;
  total_qty: number;
  min_qty: number;
  max_qty: number;
  min_exp: string | null;
  unexp_qty: number;
  grand_qty: number;
  active_count: number;
  active_flag?: 1 | 2 | 3 | 4 | 5;
  active?: boolean;
  in_reconcile: number;
};

export type StockStorageDetailType = {
  product_id?: number;
  items: AggStockSerializer["storage"][];
};

type FilterStockType = Partial<{
  product: number;
  productName: string;
  productType: string | "ALL";
  status: ActiveStatusType;
  storage: number | "ALL";
  isMinQTY: boolean;
  isGrandTotal: boolean;
  isExpiryDate: boolean;
  counting: CountingStatusType;
}>;

export type ProductStockSerializer = {
  id: number;
  product: AggStockSerializer["product"];
  storage: AggStockSerializer["storage"];
  lot: ProductLotSerializer | null;
  quantity: number;
  min_quantity: number;
  max_quantity: number;
  counting_datetime: string | null;
  bin_location: string;
  active: boolean;
  product_storage_key: number;
};

export type ProductLotSerializer = {
  id: number;
  is_default: boolean;
  mfc_no: string;
  mfc_datetime: string | null;
  exp_datetime: string | null;
  product: number;
};

export type StockLogSerializer = {
  lot: ProductLotSerializer | null;
  type: keyof typeof MOVEMENT_TYPE;
} & StockLogSerializerI;

export type StockReconcileSerializer = {
  initial_user: {
    id: number;
    username: string;
    full_name: string;
  };
} & StockReconcileSerializerI;

export type ProductDetailType = {
  code: string;
  name: string;
  drug_name?: string;
  unit: number;
  product_type: "SUPPLY" | "DRUG" | "EQUIP";
  bill_mode: number;
  drug: Partial<{
    drug_ingredients: Partial<IngredientType>[];
  }>;
  supply: Partial<{
    mode: number;
    base_unit: number;
    stock_unit: number;
    stock_size: number;
    manufacturer: number;
  }>;
  price_normal: number;
  price_special: number;
  price_premium: number;
  price_foreign: number;
  price_pledge: number;
  max_discount: number;
  overall_cose: number;
  start_date: string;
  end_date: string;
  active_flag: boolean;
};

export type IngredientType = {
  ingredient: number;
  strength: string;
  sequence: number;
};

export type StockDetailType = {
  index?: number;
  storage: number;
  product?: AggStockSerializer["product"];
  bin_location: string;
  lot_no: string;
  lot_id: number | null;
  expire_date: moment.Moment;
  quantity: string;
  reference_text: string;
  options: OptionType[];
};

type GroupDrugType = {
  name: "";
  code: "";
  children: {
    lot_no: string;
    expire_date: string;
    quantity: number;
    total: number;
  }[];
}[];

export type TransferDetailType = {
  requester: number | string;
  provider: number | string;
  quantity: string;
  product: AggStockSerializer["product"];
  storage: AggStockSerializer["storage"];
};

export type IssueStockDetailType = {
  requester: number | string;
  provider: number | string;
  product: AggStockSerializer["product"];
  storage: AggStockSerializer["storage"];
  lot: ProductLotSerializer | null;
  quantity: string;
  max_quantity: number;
  reason: string;
  remark: string;
  stock_id: number;
};

export type HistoryFilterType = {
  startDate: string;
  endDate: string;
  isMoveIn: boolean;
  isMoveOut: boolean;
  movementType: number | "ALL";
  lotNo: string[];
};

export type MasterOptionsType = Record<
  (typeof Masters)[number][0],
  OptionType[]
>;

export type ActiveStatusType = "ALL" | "ACTIVE" | "INACTIVE";

export type CountingStatusType = "ALL" | "UNCOUNTING" | "COUNTING";

export type PermissionsKey =
  | "STOCK_MANAGEMENT_VIEW" //* ค้นหา, เคลียร์
  | "PRODUCT_ADD" //* สร้างรหัสสินค้า
  | "STOCK_VIEW" //* ModProductDetail
  | "STOCK_EDIT" //* ModProductDetail
  | "STOCK_ACTIVE" //* ModProductDetail
  | "COUNTING_EDIT"
  | "TAB_LOT_VIEW"
  | "OTHER_STORE_VIEW"
  | "OTHER_STORE_ACTIVE"
  | "ADJUST_DATA_VIEW"
  | "ADJUST_DATA_EDIT"
  | "AUDIT_LOG_VIEW"
  | "TAB_HISTORY_VIEW"
  | "TAB_ADD_ADD"
  | "TAB_TRANSFER_TRANSFER"
  | "TAB_ISSUE_STOCK_ISSUE";

export type PermissionsType = Record<PermissionsKey, boolean>;

type MyPermissionType =
  | "STOCK_EDIT_ADD"
  | "STOCK_EDIT_SET_ACTIVE"
  | "STOCK_TRANSFER_REQUEST"
  | "STOCK_VIEW"
  | "STOCK_TRANSFER_DELIVER";

type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
};

type PaginationType = {
  api: ("product" | "stock")[];
  offset: (number | number)[];
  limit: (number | number)[];
};

type ButtonActionKey = keyof typeof BUTTON_ACTIONS;

type Picked = Partial<
  Pick<
    MainState,
    "buttonLoadCheck" | "errorMessage" | "django" | "searchedItemListWithKey"
  >
>;

// Sequence
type SeqState = {
  sequence: "StockManagement";
  restart?: boolean;
  clear?: boolean;
  card?: string;
};

// Common Params
type LoadCheck = {
  card: string;
  errorKey: string;
  btnAction: string;
};

// Handle Action
type ActionType =
  // Search
  | { action: "SEARCH"; card: string; noError?: boolean }
  | {
      action: "SEARCH_PAGINATION";
      card: string;
      activePage: number;
      btnAction: ButtonActionKey;
    }
  | ({
      action: "SEARCH_LOT";
      lot?: number | "ALL";
      status?: ActiveStatusType;
    } & LoadCheck)
  | ({ action: "SEARCH_HISTORY" | "PRINT_REPORT" } & LoadCheck)
  // Action
  | {
      action: "SELECT_PRODUCT";
      stock: AggStockSerializer | null;
      errorKey: string;
    }
  | {
      action:
        | "PRINT_REQUEST_STOCK_FORM"
        | "REPRINT_DRUG_SUPPLY"
        | "PRINT_TRANSFER_STOCK_FORM";
      code: string;
      errorKey: string;
      onLoading?: (loading: boolean) => any;
    }
  | {
      action: "PRINT_ADD_STOCK_FORM";
      data: StockLogSerializer;
      errorKey: string;
      onLoading?: (loading: boolean) => any;
    }
  | {
      action: "PRINT_ISSUE_STOCK_FORM";
      data: StockLogSerializer;
      code: string;
      errorKey: string;
      onLoading?: (loading: boolean) => any;
    }
  // Method
  | ({ action: "ADD_PRODUCT"; onSuccess?: Function } & LoadCheck)
  | ({
      action: "EDIT_STOCK";
      data: Partial<AggStockSerializer>;
      onSuccess?: Function;
    } & LoadCheck)
  | ({
      action: "ADD_STOCK";
      stockList: Partial<StockDetailType>[];
      onSuccess?: Function;
    } & LoadCheck)
  | ({
      action: "SAVE_STOCK_OTHER_STORE";
      offStorageList: {
        product?: number;
        storage?: number;
        lot?: number;
        active?: boolean;
      }[];
      onSuccess?: Function;
    } & LoadCheck)
  | ({
      action: "SAVE_TRANSFER";
      transferList: Partial<TransferDetailType>[];
      onSuccess?: Function;
    } & LoadCheck)
  | ({
      action: "SAVE_ISSUE_STOCK";
      issueStockList: Partial<IssueStockDetailType>[];
      onSuccess?: Function;
    } & LoadCheck)
  | ({
      action: "COUNTING";
      stock: AggStockSerializer | null;
      onLoading?: (loading: boolean) => any;
    } & LoadCheck)
  | ({
      action: "SAVE_ADJUST_DATA";
      stockId?: number;
      note?: string;
      interQuantity?: string | number;
      onSuccess?: Function;
    } & LoadCheck)
  | {
      action: "GET_PRODUCT_STOCK_LOT";
      product?: number;
      lot?: number | "ALL";
      status?: ActiveStatusType;
    }
  | { action: "GET_AUDIT_LOG" | "GET_STOCK_RECONCILE"; stockId?: number }
  | { action: "GET_STOCK_STORAGE"; product: AggStockSerializer["product"] };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(
  params: SeqType<Pick<SeqAct, K>>
) => any;

type CustomExtract<T, U> = T extends T
  ? U extends Partial<T>
    ? T
    : never
  : never;

type Params<A extends ActionType["action"]> = CustomExtract<
  ActionType,
  { action: A }
>;

export const StateInitial: State = {
  // sequence
  StockManagementSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

export const BUTTON_ACTIONS = {
  SEARCH: "SEARCH",
  SAVE: "SAVE",
  COUNTING: "COUNTING",
  PRINT: "PRINT",
} as const;

export const DISTRIBUTION_REASON = {
  INTERNAL_DIVISION: "ตัดจ่ายเพื่อใช้งานภายในแผนก",
  TREATMENT: "ตัดจ่ายเพื่อทำหัตการ",
  OTHER_DIVISION: "ตัดจ่ายไปยังหน่วยงานอื่น",
  // VENDOR: "คืนสินค้าให้ผู้ขาย",
  PATIENT: "ตัดจ่ายสินค้าให้ผู้ป่วย",
  DISPOSAL: "สินค้ารอทำลาย",
} as const;

const LIMIT = 20;

const Masters = [
  ["productTypeDrugSupply", {}],
  ["unitCore", {}],
  ["storage", {}],
  ["dosageForm", {}],
  ["stockAddRef", {}],
  ["stockReconcileNote", {}],
  ["division", {}],
] as const;

const MOVEMENT_TYPE = {
  DISPENSE_PERFORM: "DISPENSE_PERFORM",
  RETURN_PERFORM: "RETURN_PERFORM",
  TRANSFER_PERFORM: "TRANSFER_PERFORM",
  TRANSFER_CANCEL: "TRANSFER_CANCEL",
  MOVEMENT_PERFORM: "MOVEMENT_PERFORM",
  RECONCILE_PERFORM: "RECONCILE_PERFORM",
  ADD_PERFORM: "ADD_PERFORM",
  DISPENSE_CANCEL: "DISPENSE_CANCEL",
};

const DATE_FORMAT = "YYYY-MM-DD";

const P_TYPE_CODE = "DRUG,SUPPLY,EQUIP";

type Handler<P = any, R = any> = (
  controller: WasmController<State & Picked, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  } as any);

  const [bil, productType, division, permission] = await Promise.all([
    BillModeList.list({
      apiToken: controller.apiToken,
      params: { search: "ยา" },
    }),
    ProductTypeList.list({
      apiToken: controller.apiToken,
      params: { code__in: P_TYPE_CODE },
    }),
    DivisionList.list({
      apiToken: controller.apiToken,
      params: { type: "DRUG" },
    }),
    MyPermissionView.list({
      apiToken: controller.apiToken,
      params: {
        type: "CUSTOM",
        search: "STOCK_",
      },
      extra: {
        division: controller.data.division,
        location: 1,
      },
    }),
  ]);

  const state = controller.getState();

  const billItems: any[] = bil[0]?.items || [];
  const productTypeItems: any[] = productType[0]?.items || [];
  const divisionItems: any[] = division[0]?.items || [];
  const permissionItems: MyPermissionType[] = (permission[0] || []).map(
    (item: any) => item.identifier
  );

  const setPermission = (
    permission: MyPermissionType,
    keys: PermissionsKey[]
  ) => {
    return keys.reduce(
      (result, key) => ({
        ...result,
        [key]: permissionItems.includes(permission),
      }),
      {}
    );
  };

  const settings: [MyPermissionType, PermissionsKey[]][] = [
    ["STOCK_TRANSFER_REQUEST", ["TAB_TRANSFER_TRANSFER"]],
    ["STOCK_EDIT_SET_ACTIVE", ["STOCK_ACTIVE", "OTHER_STORE_ACTIVE"]],
    [
      "STOCK_VIEW",
      [
        "STOCK_VIEW",
        "TAB_LOT_VIEW",
        "OTHER_STORE_VIEW",
        "ADJUST_DATA_VIEW",
        "AUDIT_LOG_VIEW",
        "TAB_HISTORY_VIEW",
        "STOCK_MANAGEMENT_VIEW",
      ],
    ],
    [
      "STOCK_EDIT_ADD",
      [
        "PRODUCT_ADD",
        "STOCK_EDIT",
        "COUNTING_EDIT",
        "ADJUST_DATA_EDIT",
        "TAB_ADD_ADD",
      ],
    ],
    ["STOCK_TRANSFER_DELIVER", ["TAB_ISSUE_STOCK_ISSUE"]],
  ];

  let permissions = settings.reduce(
    (result, item) => ({ ...result, ...setPermission(item[0], item[1]) }),
    {} as PermissionsType
  );

  const date = formatDate(moment());

  controller.setState(
    {
      StockManagementSequence: {
        ...state.StockManagementSequence,
        sequenceIndex: "Action",
        filterStock: {
          status: "ALL",
          productType: "ALL",
          storage: "ALL",
          counting: "ALL",
        },
        filterHistory: {
          startDate: date,
          endDate: date,
          movementType: "ALL",
          isMoveIn: true,
          isMoveOut: true,
        },
        permissions: permissions,
        myPermissionList: permission[0] || [],
        billModeOptions: mapOptions(billItems, "id", "name"),
        productTypeOptions: mapOptions(productTypeItems, "code", "name", "id"),
        divisionTypeDrugOptions: mapOptions(
          divisionItems,
          "id",
          "name",
          "storage"
        ),
        modeOptions: mapOptions(["EQUIPMENT", "SUPPLY", "BOTH"]),
        movementTypeOptions: [
          { key: "DISPENSE_PERFORM", value: 2, text: "จ่ายออก" },
          { key: "RETURN_PERFORM", value: 4, text: "คืนยา" },
          { key: "TRANSFER_PERFORM", value: 6, text: "โอนสินค้า" },
          { key: "TRANSFER_CANCEL", value: 7, text: "ปฏิเสธสถานะขอสินค้า" },
          { key: "MOVEMENT_PERFORM", value: 8, text: "จาก INF" },
          { key: "RECONCILE_PERFORM", value: 10, text: "Adjust data" },
          { key: "ADD_PERFORM", value: 12, text: "การเติมสินค้าจาก" },
        ],
        selectedStock: null,
      },
    },
    () =>
      Action(controller, {
        card: params.card || "",
        action: "SEARCH",
        noError: true,
      })
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  if (params.action === "SEARCH") {
    HandleSearch(controller, params);
  } else if (params.action === "SEARCH_PAGINATION") {
    return HandleSearchPagination(controller, params);
  } else if (params.action === "ADD_PRODUCT") {
    HandleAddProduct(controller, params);
  } else if (params.action === "EDIT_STOCK") {
    HandleEditStock(controller, params);
  } else if (params.action === "ADD_STOCK") {
    HandleAddStock(controller, params);
  } else if (params.action === "SEARCH_LOT") {
    HandleSearchLot(controller, params);
  } else if (params.action === "SELECT_PRODUCT") {
    HandleSelectProduct(controller, params);
  } else if (params.action === "SEARCH_HISTORY") {
    HandleSearchHistory(controller, params);
  } else if (params.action === "SAVE_STOCK_OTHER_STORE") {
    HandleSaveStockOtherStore(controller, params);
  } else if (params.action === "SAVE_TRANSFER") {
    HandleSaveTransfer(controller, params);
  } else if (params.action === "SAVE_ISSUE_STOCK") {
    HandleSaveIssue(controller, params);
  } else if (params.action === "COUNTING") {
    HandleCounting(controller, params);
  } else if (params.action === "SAVE_ADJUST_DATA") {
    HandleSaveAdjustData(controller, params);
  } else if (params.action === "GET_PRODUCT_STOCK_LOT") {
    HandleGetProductStockLot(controller, params);
  } else if (params.action === "GET_AUDIT_LOG") {
    HandleGetAuditLog(controller, params);
  } else if (params.action === "GET_STOCK_RECONCILE") {
    HandleGetStockReconcile(controller, params);
  } else if (params.action === "PRINT_REPORT") {
    HandlePrintReport(controller, params);
  } else if (params.action === "PRINT_REQUEST_STOCK_FORM") {
    HandlePrintRequestStockForm(controller, params);
  } else if (params.action === "PRINT_TRANSFER_STOCK_FORM") {
    HandlePrintTransferStockForm(controller, params);
  } else if (params.action === "REPRINT_DRUG_SUPPLY") {
    HandleDrugSupplyReprint(controller, params);
  } else if (params.action === "PRINT_ADD_STOCK_FORM") {
    HandlePrintAddStockForm(controller, params);
  } else if (params.action === "PRINT_ISSUE_STOCK_FORM") {
    HandlePrintIssueStockForm(controller, params);
  } else if (params.action === "GET_STOCK_STORAGE") {
    HandleGetStockStorage(controller, params);
  }
};

const HandleSearch: Handler<Params<"SEARCH">> = async (controller, params) => {
  let state = controller.getState();

  if (!state.StockManagementSequence?.permissions?.STOCK_MANAGEMENT_VIEW) {
    return;
  }

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const promiseArr = [
    GetProductList(controller, {}),
    GetAggStockList(controller, {}),
  ];

  const [[product], [stock]] = await Promise.all(promiseArr);

  const pagination = GetPagination(product?.total, stock?.total);

  const allList = ConcatProductStockList(product?.items, stock?.items);

  // -----
  state = controller.getState();

  controller.setState({
    StockManagementSequence: {
      ...state.StockManagementSequence,
      stockList: allList.map((item: any, index: number) => ({
        ...item,
        id: index + 1,
      })),
      pagination,
      activePage: 1,
      tempFilterStock: structuredClone(
        state.StockManagementSequence?.filterStock || {}
      ),
    },
  });

  if (!!allList.length) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });
  } else {
    if (!params.noError) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [`${params.card}_${params.action}`]: "NOT_FOUND",
        },
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
      });
    }
  }
};

const HandleSearchPagination: Handler<
  Params<"SEARCH_PAGINATION">,
  Promise<AggStockSerializer[]>
> = async (controller, params) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction}`]: "LOADING",
    },
  });

  const pagination = state.StockManagementSequence?.pagination || [];
  const tempFilterStock = state.StockManagementSequence?.tempFilterStock || {};

  // * activePage เริ่มต้นที่ 1
  const target = pagination[params.activePage - 1];

  const api = {
    product: GetProductList,
    stock: GetAggStockList,
  };

  const promiseArr = (["product", "stock"] as const).map((key) => {
    const index = target.api.indexOf(key);
    return (
      index >= 0 &&
      api[key](controller, {
        ...params,
        limit: target.limit[index],
        offset: target.offset[index],
        filter: tempFilterStock,
      })
    );
  });

  const [product, stock] = await Promise.all(promiseArr);

  const allList = ConcatProductStockList(
    product?.[0]?.items,
    stock?.[0]?.items
  );

  // -----
  state = controller.getState();

  controller.setState({
    StockManagementSequence: {
      ...state.StockManagementSequence,
      stockList: allList.map((item: any, index: number) => ({
        ...item,
        id: index + 1 + (params.activePage - 1) * LIMIT,
      })),
      activePage: params.activePage,
    },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction}`]: "SUCCESS",
    },
  });

  return allList;
};

const HandleAddProduct: Handler<Params<"ADD_PRODUCT">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction}`]: "LOADING",
    },
  });

  const detail = state.StockManagementSequence?.productDetail || {};
  const productTypeOptions =
    state.StockManagementSequence?.productTypeOptions || [];

  const pricingKey =
    "bill_mode,price_normal,price_special,price_premium,price_foreign,price_pledge,max_discount,start_date,end_date".split(
      ","
    ) as (keyof ProductDetailType)[];
  const fullPricing = pricingKey.reduce(
    (result, key) => ({ ...result, [key]: detail[key] }),
    {} as ProductDetailType
  );

  const data = {
    ...detail,
    ...detail.drug,
    ...detail.supply,
    p_type: productTypeOptions.find(
      (option) => option.value === detail.product_type
    )?.key,
    full_pricings: [
      {
        ...fullPricing,
        start_date: beToAd(fullPricing.start_date)?.format(DATE_FORMAT),
        end_date: beToAd(fullPricing.end_date)?.format(DATE_FORMAT),
      },
    ],
    active_flag: detail.active_flag ? 1 : 5,
  };

  // * เนื่องจาก UI ไม่มีให้กรอก stock_unit, base_unit
  // * จึงใช้ค่าจาก unit เพราะเป็น require field
  if (detail.product_type === "DRUG") {
    (data as any)["stock_unit"] = detail.unit;
    (data as any)["base_unit"] = detail.unit;

    // นำ name บันทึกลงใน drug_name ด้วย
    (data as any)["drug_name"] = detail.name;
  }

  // * ลบ key ที่เอาไปใส่ไว้ใน full_pricings ออก
  // * และ key drug supply ที่เอาไว้แยะข้อมูลตาม product type
  for (const key of [...pricingKey, "drug", "supply", "product_type"]) {
    delete (data as any)[key];
  }

  if (!Object.keys(data.drug_ingredients?.[0] || {}).length) {
    data.drug_ingredients = [];
  }

  // ---- แสดง error จาก frontend
  const isDrug = detail.product_type === "DRUG";
  const isSupply = detail.product_type === "SUPPLY";

  const errCheck = typeof detail.active_flag !== "undefined" && isSupply;

  let errMsg =
    errCheck &&
    (["base_unit", "stock_unit", "stock_size"] as const).reduce(
      (result, key) => ({
        ...result,
        ...(!data[key] ? { [key]: ["This field is required."] } : {}),
      }),
      {}
    );

  if (!!Object.keys(errMsg).length) {
    return SetErrorMessage(controller, { ...params, error: errMsg });
  }

  const api = isDrug ? DrugCreate : SupplyNewList;

  const product = await api.create({
    apiToken: controller.apiToken,
    data,
  });

  if (product[1]) {
    SetErrorMessage(controller, { ...params, error: product[1] });
  } else {
    // -----
    const state = controller.getState();

    controller.setState(
      {
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.btnAction}`]: "SUCCESS",
        },
        StockManagementSequence: {
          ...state.StockManagementSequence,
          productLotList: [],
          productStockList: [],
          stockLogList: [],
          selectedStock: null,
        },
      },
      () => Action(controller, { card: params.card, action: "SEARCH" })
    );

    params.onSuccess?.();
  }
};

const HandleEditStock: Handler<Params<"EDIT_STOCK">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction}`]: "LOADING",
    },
  });

  const data = params.data || {};

  const stock = await UpdateAggStockUpdateList(controller, data);

  if (stock[1]) {
    SetErrorMessage(controller, { ...params, error: stock[1] });
  } else {
    controller.setState(
      {
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.btnAction}`]: "SUCCESS",
        },
      },
      () =>
        Action(controller, {
          card: params.card,
          action: "SEARCH_PAGINATION",
          activePage: state.StockManagementSequence?.activePage || 1,
          btnAction: BUTTON_ACTIONS.SEARCH,
        })
    );

    params.onSuccess?.();
  }
};

const HandleAddStock: Handler<Params<"ADD_STOCK">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction}`]: "LOADING",
    },
  });

  let lotRes: any[] = [];
  const stockUpdate: Record<string, any> = {};

  const newLot = params.stockList.filter((item) => !item.lot_id);

  // หากเป็น lot no ใหม่ให้ทำการ create ก่อน
  if (!!newLot.length) {
    const promiseArr = newLot.map((item) =>
      ProductLotListCreate.create({
        apiToken: controller.apiToken,
        pk: item.product?.id,
        data: {
          mfc_no: item.lot_no,
          mfc_datetime: null, //formatDate(moment()),
          exp_datetime: item.expire_date
            ? formatDatetime(item.expire_date)
            : "",
        },
      })
    );

    lotRes = (await Promise.all(promiseArr)).map((res) => res[0] || {});
  }

  const groupStorage = params.stockList.reduce((result, item) => {
    const storage = item?.storage || "";

    if (!result[storage]) {
      result[storage] = [item];
    } else {
      result[storage].push(item);
    }

    return result;
  }, {} as Record<string, Partial<StockDetailType>[]>);

  const stockList = Object.values(groupStorage).flat();

  for (const item of stockList) {
    const lotId =
      lotRes.find(
        (acc) => acc.product === item.product?.id && acc.mfc_no === item.lot_no
      )?.id || null;

    const res = await StockAdd.create({
      apiToken: controller.apiToken,
      data: {
        product: item.product?.id,
        storage: item.storage,
        lot: item.lot_id || lotId,
        quantity: item.quantity,
        reference_text: item.reference_text,
      } as any,
    });

    stockUpdate[item.product?.id || ""] = {
      storage: { id: res[0]?.stock?.storage },
      product: { id: res[0]?.stock?.product },
      bin_location: item.bin_location,
    };
  }

  const promiseArr = Object.values(stockUpdate).map((item) => {
    return UpdateAggStockUpdateList(controller, item);
  });

  const response = await Promise.all(promiseArr);

  if (response.some((res) => res[1])) {
    SetErrorMessage(controller, {
      ...params,
      error: response.map((res) => res[1]),
    });
  } else {
    await HandlePrintFormAddStock(controller, { groupStorage });

    params.onSuccess?.();

    controller.setState(
      {
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.btnAction}`]: "SUCCESS",
        },
      },
      async () => {
        HandleSearchNSelect(controller, params);
      }
    );
  }
};

const HandleSearchNSelect: Handler<{
  card: string;
  errorKey: string;
}> = async (controller, params) => {
  const state = controller.getState();

  const items: any[] = await Action(controller, {
    card: params.card,
    action: "SEARCH_PAGINATION",
    activePage: state.StockManagementSequence?.activePage || 1,
    btnAction: BUTTON_ACTIONS.SEARCH,
  });

  const stock = state.StockManagementSequence?.selectedStock;
  const target = items.find((item) => item.product?.id === stock?.product?.id);

  Action(controller, {
    action: "SELECT_PRODUCT",
    errorKey: params.errorKey,
    stock: !!target
      ? state.StockManagementSequence?.selectedStock || null
      : null,
  });
};

const HandleSearchLot: Handler<Params<"SEARCH_LOT">> = async (
  controller,
  params
) => {
  let state = controller.getState();

  if (!state.StockManagementSequence?.permissions?.TAB_LOT_VIEW) {
    return;
  }

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction}`]: "LOADING",
    },
  });

  const data = state.StockManagementSequence?.selectedStock;

  const result = await GetProductStock(controller, {
    product: data?.product?.id,
    storage: data?.storage.id,
    lot: params.lot,
    status: params.status,
  });

  // -----
  state = controller.getState();

  const items = result[0]?.items || [];

  if (result[1]) {
    SetErrorMessage(controller, { ...params, error: result[1] });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.btnAction}`]: "SUCCESS",
      },
      StockManagementSequence: {
        ...state.StockManagementSequence,
        productStockList: items,
      },
    });
  }
};

const HandleSelectProduct: Handler<Params<"SELECT_PRODUCT">> = async (
  controller,
  params
) => {
  let state = controller.getState();

  if (!params.stock?.product?.id) {
    return controller.setState({
      StockManagementSequence: {
        ...state.StockManagementSequence,
        productLotList: [],
        productStockList: [],
        stockLogList: [],
        selectedStock: null,
      },
    });
  }

  const productId = params.stock?.product.id;

  const lot = await ProductLotListCreate.list({
    apiToken: controller.apiToken,
    pk: productId,
  });

  const items: any[] = (lot[0]?.items || []).filter((item: any) => item.mfc_no);

  // -----
  state = controller.getState();

  controller.setState(
    {
      StockManagementSequence: {
        ...state.StockManagementSequence,
        productLotList: items,
        selectedStock: params.stock || null,
        filterHistory: {
          ...state.StockManagementSequence?.filterHistory,
          lotNo: [],
        },
      },
    },
    () => {
      Action(controller, {
        action: "SEARCH_LOT",
        card: "",
        btnAction: "",
        errorKey: params.errorKey,
      });
      Action(controller, {
        action: "SEARCH_HISTORY",
        card: "",
        btnAction: "",
        errorKey: params.errorKey,
      });
    }
  );
};

const HandleSearchHistory: Handler<Params<"SEARCH_HISTORY">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  if (!state.StockManagementSequence?.permissions?.TAB_HISTORY_VIEW) {
    return;
  }

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction}`]: "LOADING",
    },
  });

  const data = state.StockManagementSequence?.selectedStock;
  const filter = state.StockManagementSequence?.filterHistory;

  const currentDate = formatDate(moment());
  const startDate = filter?.startDate ?? currentDate;
  const endDate = filter?.endDate ?? currentDate;
  const isAllQuantity = filter?.isMoveIn && filter?.isMoveOut;
  const isNotQuantity = !filter?.isMoveIn && !filter?.isMoveOut;

  const result = await StockLogList.list({
    apiToken: controller.apiToken,
    params: {
      stock__product: data?.product.id,
      datetime__gte: startDate
        ? formatDatetime(beToAd(startDate) as any)
        : undefined,
      datetime__lte: endDate
        ? formatDatetime(beToAd(endDate)?.endOf("day") as any)
        : undefined,
      quantity__gt: isAllQuantity
        ? undefined
        : filter?.isMoveIn || isNotQuantity
        ? -1
        : undefined,
      quantity__lt: isAllQuantity
        ? undefined
        : filter?.isMoveOut || isNotQuantity
        ? 0
        : undefined,
      type: filter?.movementType === "ALL" ? undefined : filter?.movementType,
      stock__lot__mfc_no__in: filter?.lotNo?.join(","),
    },
  });

  if (result[1]) {
    SetErrorMessage(controller, {
      ...params,
      error: result[1],
    });
  } else {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.btnAction}`]: "SUCCESS",
      },
      StockManagementSequence: {
        ...state.StockManagementSequence,
        stockLogList: result[0]?.items || [],
      },
    });
  }
};

const HandleSaveStockOtherStore: Handler<
  Params<"SAVE_STOCK_OTHER_STORE">
> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction}`]: "LOADING",
    },
  });

  const promiseArr = params.offStorageList.map((item) =>
    ProductStockList.create({
      pk: item.product,
      apiToken: controller.apiToken,
      params: {
        storage: item.storage,
        lot: item.lot,
      },
      data: { active: item.active },
    })
  );

  const response = await Promise.all(promiseArr);

  if (response.some((res) => res[1])) {
    SetErrorMessage(controller, {
      ...params,
      error: response.map((res) => res[1]),
    });
  } else {
    params.onSuccess?.();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.btnAction}`]: "SUCCESS",
      },
    });
  }
};

const HandleSaveTransfer: Handler<Params<"SAVE_TRANSFER">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction}`]: "LOADING",
    },
  });

  const list = params.transferList;

  const provider = list[0].provider;
  const requester = list[0].requester;
  const isDrug = list[0].product?.p_type_name === "ค่ายาและสารอาหาร";

  const items = list.map((item) => ({
    [isDrug ? "drug" : "supply"]: item.product?.id,
    code: item.product?.code,
    name: item.product?.name,
    request_quantity: item.quantity,
    stock_unit_name: item.product?.unit_name,
  }));

  const api = isDrug
    ? DrugTransferRequestList
    : SupplyTransferRequestListCreate;

  const result = await api.create({
    data: {
      code: "NEW",
      action: "REQUEST",
      requester_name: "",
      provider_name: "",
      status_name: "NEW",
      items,
      provider,
      requester,
      is_requester: false,
      is_provider: false,
    },
    extra: {
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  if (result[1]) {
    SetErrorMessage(controller, { ...params, error: result[1] });
  } else {
    // await CreatePDFStockTransfer(controller, { data: result[0], isSupply });
    await HandlePrintFormRequestStock(controller, {
      data: result[0],
      items: items,
    });

    params.onSuccess?.();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.btnAction}`]: "SUCCESS",
      },
    });
  }
};

const HandleSaveIssue: Handler<Params<"SAVE_ISSUE_STOCK">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction}`]: "LOADING",
    },
  });

  const list = params.issueStockList.filter((item) => !!Number(item.quantity));
  const detail = list[0];

  const extra = {
    reason: detail.reason,
    division_id:
      detail.reason === DISTRIBUTION_REASON.OTHER_DIVISION
        ? detail.provider
        : null,
    patient_id:
      detail.reason === DISTRIBUTION_REASON.PATIENT ? detail.provider : null,
    note: detail.remark || "",
  };

  const [orderRes] = await DispenseOrderList.create({
    apiToken: controller.apiToken,
    data: {
      order_div: controller.cookies.get("division_id"),
      storage: detail.storage?.id,
      extra,
    } as any,
  });

  // group by product id เพื่อทำการบันทึกและอัพเดต dispense order
  const groupByProduct = list.reduce((result, item) => {
    const groupKey = item.product?.id || "";
    const data = result[groupKey];

    if (!!data) {
      result[groupKey].push(item);
    } else {
      result[groupKey] = [item];
    }

    return result;
  }, {} as Record<string, any[]>);

  for (const [key, items] of Object.entries(groupByProduct)) {
    // add item ไปใน dispense order
    const [itemRes] = await DispenseOrderItemList.create({
      apiToken: controller.apiToken,
      order_id: orderRes?.id,
      data: {
        product: key,
        quantity: 0,
      },
    });

    for (const acc of items) {
      // เลือก lot ที่ต้องการจะดึง
      await DispenseOrderItemPlanList.create({
        apiToken: controller.apiToken,
        order_id: orderRes?.id,
        item_id: itemRes?.id,
        data: {
          stock: acc.stock_id,
          quantity: -Number(acc.quantity),
        },
      });
    }
  }

  // update status เป็น REQUESTED
  const orderDetail = await DispenseOrderDetail.patch({
    apiToken: controller.apiToken,
    pk: orderRes?.id,
    data: { action: "REQUEST" },
  }).then(() =>
    // update status เป็น DELIVERED
    DispenseOrderDetail.patch({
      apiToken: controller.apiToken,
      pk: orderRes?.id,
      data: { action: "DELIVER" },
    })
  );

  if (orderDetail[1]) {
    SetErrorMessage(controller, { ...params, error: orderDetail[1] });
  } else {
    await HandlePrintFormIssueStock(controller, {
      detail: {
        ...extra,
        requester: detail.requester,
        code: orderDetail[0].code,
      },
      items: list,
    });

    params.onSuccess?.();

    controller.setState(
      {
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.btnAction}`]: "SUCCESS",
        },
      },
      () =>
        Action(controller, {
          card: params.card,
          action: "SEARCH_PAGINATION",
          activePage: state.StockManagementSequence?.activePage || 1,
          btnAction: BUTTON_ACTIONS.SEARCH,
        })
    );
  }
};

const HandleCounting: Handler<Params<"COUNTING">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  params.onLoading?.(true);

  const sm = state.StockManagementSequence;
  const storageId = params.stock?.storage?.id;
  const productId = params.stock?.product.id;
  let reconcile: any[] = [null, null, null];
  const starting = !!params.stock?.in_reconcile;

  if (starting) {
    reconcile = await ProductStockReconcileView.patch({
      apiToken: controller.apiToken,
      storage_id: storageId,
      product_id: productId,
    });
  } else {
    reconcile = await ProductStockReconcileView.create({
      apiToken: controller.apiToken,
      storage_id: storageId,
      product_id: productId,
    });
  }

  if (reconcile[0]) {
    HandleSearchNSelect(controller, params);
  } else {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.errorKey]: reconcile[0],
      },
    });
  }

  params.onLoading?.(false);
};

export const HandleSaveAdjustData: Handler<Params<"SAVE_ADJUST_DATA">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction}`]: "LOADING",
    },
  });

  const reconcile = await StockReconcileDetail.patch({
    apiToken: controller.apiToken,
    pk: params.stockId,
    data: {
      note: params.note || "",
      inter_quantity: params.interQuantity,
    } as any,
  });

  if (reconcile[1]) {
    SetErrorMessage(controller, { ...params, error: reconcile[1] });
  } else {
    params.onSuccess?.();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.btnAction}`]: "SUCCESS",
      },
    });
  }
};

const HandleGetProductStockLot: Handler<
  Params<"GET_PRODUCT_STOCK_LOT">
> = async (controller, params) => {
  const result = await GetProductStock(controller, {
    product: params.product,
    lot: params.lot,
    status: params.status,
  });

  const state = controller.getState();

  controller.setState({
    StockManagementSequence: {
      ...state.StockManagementSequence,
      productStockByLotList: result[0]?.items || [],
    },
  });
};

const HandleGetAuditLog: Handler<Params<"GET_AUDIT_LOG">> = async (
  controller,
  params
) => {
  const state = controller.getState();
  const options = state.StockManagementSequence?.movementTypeOptions || [];

  const result = await StockLogList.list({
    apiToken: controller.apiToken,
    params: {
      stock: params.stockId,
      type: options.find(
        (option) => option.key === MOVEMENT_TYPE.RECONCILE_PERFORM
      )?.value,
    },
  });

  controller.setState({
    StockManagementSequence: {
      ...state.StockManagementSequence,
      auditLogList: result[0]?.items || [],
    },
  });
};

const HandleGetStockReconcile: Handler<Params<"GET_STOCK_RECONCILE">> = async (
  controller,
  params
) => {
  const result = await StockReconcileDetail.list({
    apiToken: controller.apiToken,
    pk: params.stockId,
  });

  const state = controller.getState();

  controller.setState({
    StockManagementSequence: {
      ...state.StockManagementSequence,
      stockReconcileList: result[0]?.items || [],
    },
  });
};

const HandleGetStockStorage: Handler<Params<"GET_STOCK_STORAGE">> = async (
  controller,
  params
) => {
  let state = controller.getState();

  const productTypeOptions =
    state.StockManagementSequence?.productTypeOptions || [];
  const product = params.product;

  const [result] = await GetAggStockList(controller, {
    filter: {
      productName: product?.name,
      productType: productTypeOptions.find(
        (option) => option.text === product?.p_type_name
      )?.value as string,
    },
  });

  state = controller.getState();

  const items = (result?.items || []).flatMap((item) =>
    item.product.code === product?.code ? [item.storage] : []
  );

  controller.setState({
    StockManagementSequence: {
      ...state.StockManagementSequence,
      stockStorageDetail: {
        product_id: product?.id,
        items,
      },
    },
  });
};

const HandlePrintReport: Handler<Params<"PRINT_REPORT">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction}`]: "LOADING",
    },
  });

  const data = state.StockManagementSequence?.selectedStock;
  const filter = state.StockManagementSequence?.filterHistory || {};

  const dispense = await DrugOrderDispenseReport.list({
    apiToken: controller.apiToken,
    params: {
      product: data?.product.id,
      start_date: filter.startDate
        ? beToAd(filter.startDate)?.format(DATE_FORMAT)
        : undefined,
      last_date: filter.endDate
        ? beToAd(filter.endDate)?.format(DATE_FORMAT)
        : undefined,
      storage: data?.storage.id,
    } as any,
  });

  if (dispense[1]) {
    SetErrorMessage(controller, { ...params, error: dispense[1] });
  } else {
    await HandlePrintFormPatientMedicationDispense(controller, {
      ...params,
      items: dispense[0].items || [],
    });

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.btnAction}`]: "SUCCESS",
      },
    });
  }
};

const HandlePrintRequestStockForm: Handler<
  Params<"PRINT_REQUEST_STOCK_FORM">
> = async (controller, params) => {
  params.onLoading?.(true);

  const detail = await GetDrugSupplyDetail(controller, params);

  if (!!detail.data) {
    await HandlePrintFormRequestStock(controller, {
      data: detail.data,
      items: detail.data.items,
    });
  }

  params.onLoading?.(false);
};

const HandlePrintTransferStockForm: Handler<
  Params<"PRINT_TRANSFER_STOCK_FORM">
> = async (controller, params) => {
  params.onLoading?.(true);

  const detail = await GetDrugSupplyDetail(controller, {
    ...params,
    plan: true,
  });

  if (!!detail.data) {
    await HandlePrintFormTransferStock(controller, {
      data: detail.data,
      items: detail.data.items,
    });
  }

  params.onLoading?.(false);
};

const HandlePrintAddStockForm: Handler<Params<"PRINT_ADD_STOCK_FORM">> = async (
  controller,
  params
) => {
  params.onLoading?.(true);

  const state = controller.getState();
  const stock = state.StockManagementSequence?.selectedStock;
  const data = params.data;

  const groupStorage: Record<string, Partial<StockDetailType>[]> = {
    [`${stock?.storage?.id}`]: [
      {
        storage: stock?.storage?.id,
        product: stock?.product,
        bin_location: data.bin_location,
        lot_no: data.lot?.mfc_no,
        lot_id: data.lot?.id,
        expire_date: moment(data.lot?.exp_datetime),
        quantity: data.quantity,
        reference_text: data.reference_text,
      },
    ],
  };

  await HandlePrintFormAddStock(controller, {
    groupStorage,
    editor: params.data.edit_user_name,
    datetime: data.datetime,
  });

  params.onLoading?.(false);
};

const HandleDrugSupplyReprint: Handler<Params<"REPRINT_DRUG_SUPPLY">> = async (
  controller,
  params
) => {
  params.onLoading?.(true);

  const state = controller.getState();

  const selected = state.StockManagementSequence?.selectedStock;

  if (selected?.product.p_type_name === "ค่ายาและสารอาหาร") {
    await HandleDrugReprint(controller, params);
  } else if (selected?.product.p_type_name === "ค่าเวชภัณฑ์") {
    await HandleSupplyReprint(controller, params);
  }

  params.onLoading?.(false);
};

const HandlePrintIssueStockForm: Handler<
  Params<"PRINT_ISSUE_STOCK_FORM">
> = async (controller, params) => {
  params.onLoading?.(true);

  const [order] = await DispenseOrderList.list({
    apiToken: controller.apiToken,
    params: {
      code: params.code,
    },
  });

  const items: any[] = order?.items || [];
  const detail = items[0] || {};

  const list = (detail.items || []).flatMap((item: any) => {
    const reverse: any[] = (item.plans || []).reverse();
    const plan = reverse.reduce(
      (result, acc) => {
        const sum = Math.abs(acc.quantity) + result.sum;
        if (sum <= item.quantity) {
          result = {
            sum,
            items: [...result.items, acc],
          };
        }
        return result;
      },
      { sum: 0, items: [] } as any
    );

    return plan.items.reverse().map((plan: any) => ({
      ...plan,
      product: item.product,
      quantity: Math.abs(item.quantity),
    }));
  });

  await HandlePrintFormIssueStock(controller, {
    detail: {
      ...(detail.extra || {}),
      requester: detail.order_div,
      code: params.code,
      userName: params.data.edit_user_name,
    },
    items: list,
  });

  params.onLoading?.(false);
};

/* ------------------------------------------------------ */

/*                        PRINT PDF                       */

/* ------------------------------------------------------ */
export const HandlePrintFormTransferStock: Handler<{
  items: any[];
  data: Record<string, any>;
}> = async (controller, params) => {
  let docDef: any = { content: [] };

  const formatedItems = params.items.flatMap((item) => ({
    code: item.code,
    name: item.name,
    // group ตาม product name
    children: (item.items || []).map((acc: any, index: number) => ({
      lot_no: acc.lot.mfc_no,
      expire_date: formatDate(moment(acc.lot.exp_datetime)),
      quantity: Math.abs(acc.quantity),
      // หาผลรวม
      total: (item.items || [])
        .slice(0, index + 1)
        .reduce((result: any, obj: any) => result + Math.abs(obj.quantity), 0),
    })),
  }));

  const data = {
    ...params.data,
    items: getRowSpanColumns(formatedItems),
  };

  docDef = FormTransferStock({ ...data });

  (await getPdfMake()).createPdf(docDef).open();
};

const HandlePrintFormRequestStock: Handler<{
  items: any[];
  data: Record<string, any>;
}> = async (controller, params) => {
  let docDef: any = { content: [] };

  const data = {
    ...params.data,
    items: params.items,
  };

  docDef = FormRequestStock({ ...data });

  (await getPdfMake()).createPdf(docDef).open();
};

const HandlePrintFormPatientMedicationDispense: Handler<{
  items: any[];
  card: string;
  btnAction: string;
}> = async (controller, params) => {
  const state = controller.getState();

  let docDef: any = { content: [] };

  const selected = state.StockManagementSequence?.selectedStock;
  const filter = state.StockManagementSequence?.filterHistory || {};

  const product = selected?.product;

  const fullName = state.django?.user?.full_name;

  const userName = !!fullName
    ? fullName
    : `${state.firstName || ""} ${state.lastName || ""}`;

  const data = {
    startDate: filter.startDate,
    endDate: filter.endDate,
    userName,
    storageName: selected?.storage.name,
    items: params.items,
    titleName: product?.id
      ? `[${product?.code || ""}]-${product?.name || ""}`
      : "",
  };

  docDef = FormPatientMedicationDispense({ ...data });

  (await getPdfMake()).createPdf(docDef).open();
};

const HandleDrugReprint: Handler<Params<"REPRINT_DRUG_SUPPLY">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const barcode = await GetDefaultBarcode(controller, {
    prefix: "D01",
    code: params.code,
  });

  const print = await DrugOrderActionView.update({
    pk: barcode[0]?.pk,
    data: {
      action: "REPRINT",
      language: "TH",
      note: "เนื่องจาก ",
      print_drug_label: false,
      print_patient: false,
      print_pharma: true,
    },
    extra: {
      division: controller.data.division,
      device: controller.data.device,
    },
    apiToken: controller.apiToken,
  });

  if (print[1]) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.errorKey]: print[1],
      },
    });
  }
};

const HandleSupplyReprint: Handler<Params<"REPRINT_DRUG_SUPPLY">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const barcode = await GetDefaultBarcode(controller, {
    prefix: "S01",
    code: params.code,
  });

  const supply = await SupplyOrderDetail.retrieve({
    pk: barcode[0]?.pk,
    extra: {
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  const print = await SupplyOrderDetail.update({
    pk: barcode[0]?.pk,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
      device: controller.data.device,
    },
    data: {
      action: "REPRINT",
      co_user: "",
      is_transporter: false,
      items: supply[0]?.items,
    },
  });

  if (print[1]) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.errorKey]: print[1],
      },
    });
  }
};

const HandlePrintFormAddStock: Handler<{
  groupStorage: Record<string, Partial<StockDetailType>[]>;
  editor?: string;
  datetime?: string;
}> = async (controller, params) => {
  const state = controller.getState();

  const storageOptions = state.masterOptions?.storage || [];
  const momentDate = moment();
  const fullName = state.django?.user?.full_name;

  const userName = !!fullName
    ? fullName
    : `${state.firstName || ""} ${state.lastName || ""}`;

  const groupStorageItem = Object.entries(params.groupStorage).reduce(
    (result, [key, value]) => {
      result[key] = {
        datetime: params.datetime
          ? `${formatDate(moment(params.datetime))} [${moment(
              params.datetime
            ).format("HH:mm")}]`
          : `${formatDate(momentDate)} [${momentDate.format("HH:mm")}]`,
        storageName:
          storageOptions.find((option) => option.value === Number(key))?.text ||
          "",
        hideCode: true,
        editor: params.editor || userName,
        staff: userName,
        items: groupItemsByProduct(value),
      };

      return result;
    },
    {} as Record<
      string,
      {
        datetime: string;
        storageName: string;
        hideCode?: boolean;
        editor: string;
        staff: string;
        items: GroupDrugType;
      }
    >
  );

  // Create PDF: Add stock
  const pdfMake = await getPdfMake();

  const createPDFBase64 = async (data: any): Promise<string> => {
    let docDef: any = { content: [] };
    docDef = FormAddStock(data);
    return new Promise(async (resolve, reject) =>
      pdfMake.createPdf(docDef).getBase64((result: any) => resolve(result))
    );
  };

  const promiseArr = Object.values(groupStorageItem).map((data: any) => {
    return createPDFBase64({
      ...data,
      items: getRowSpanColumns(data.items),
    });
  });

  const pdfBase64 = await Promise.all(promiseArr);

  const pdfDoc = await PDFDocument.create();

  for (const base64 of pdfBase64) {
    const doc = await PDFDocument.load(base64);
    const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());

    for (const page of copiedPages) {
      pdfDoc.addPage(page);
    }
  }

  const base64Data = await pdfDoc.saveAsBase64();

  const blob = base64toBlob("data:application/pdf;base64," + base64Data);

  const bloburl = URL.createObjectURL(blob);
  window.open(bloburl);
};

const HandlePrintFormIssueStock: Handler<{
  detail: {
    reason?: string;
    note?: string;
    division_id?: string | number | null;
    patient_id?: string | number | null;
    requester?: string | number;
    code: string;
    userName?: string;
  };
  items: Partial<IssueStockDetailType>[];
}> = async (controller, params) => {
  const state = controller.getState();

  const list = params.items.map((item) => ({
    lot_no: item.lot?.mfc_no,
    expire_date: moment(item.lot?.exp_datetime),
    quantity: item.quantity,
    product: item.product,
  }));

  let docDef: any = { content: [] };
  let providerName = "";

  const groupItems = groupItemsByProduct(list);

  const momentDate = moment();
  const detail = params.detail;

  const divisionName =
    state.masterOptions?.division.find(
      (option) => option.value === detail.requester
    )?.text || "";
  const fullName = state.django?.user?.full_name;

  const userName = !!fullName
    ? fullName
    : `${state.firstName || ""} ${state.lastName || ""}`;

  // แสดงชื่อ division
  if (!!detail.division_id) {
    providerName =
      state.masterOptions?.division.find(
        (option) => option.value === detail.division_id
      )?.text || "";
  }
  // แสดงชื่อผู้ป่วย
  else if (detail.patient_id) {
    const [patient] = await PatientDetailView.retrieve({
      apiToken: controller.apiToken,
      pk: detail.patient_id,
    });

    providerName = patient?.full_name || "";
  }

  const data = {
    datetime: `${formatDate(momentDate)} [${momentDate.format("HH:mm")}]`,
    divisionName,
    userName: params.detail.userName || userName,
    remark: detail.note,
    reason: detail.reason,
    providerName,
    code: detail.code,
    items: getRowSpanColumns(groupItems),
  };

  docDef = FormIssueStock({ ...data });

  (await getPdfMake()).createPdf(docDef).open();
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
const GetAggStockList: Handler<
  {
    limit?: number;
    offset?: number;
    filter?: FilterStockType;
  },
  Promise<[{ items: AggStockSerializer[]; total: number } | null, any]>
> = (controller, params) => {
  const state = controller.getState();

  const filter = !!params.filter
    ? params.filter || {}
    : state.StockManagementSequence?.filterStock || {};
  const productTypeOptions =
    state.StockManagementSequence?.productTypeOptions || [];

  const active = {
    ALL: undefined,
    ACTIVE: true,
    INACTIVE: false,
  }[filter.status || "ALL"];

  const formatDate = (date: moment.Moment) => {
    return `${date.format(DATE_FORMAT)}T${date.format("HH:mm")}+07`;
  };

  return AggregateStockList.list({
    apiToken: controller.apiToken,
    params: {
      search: filter.productName,
      min_qty: filter.isMinQTY,
      exp_dt: filter.isExpiryDate
        ? formatDate(moment().add(6, "months"))
        : undefined,
      qty_0: filter.isGrandTotal,
      active,
      storage: filter.storage === "ALL" ? undefined : filter.storage,
      product__p_type:
        filter.productType !== "ALL"
          ? productTypeOptions.find(
              (option) => option.value === filter.productType
            )?.key
          : undefined,
      product__p_type__in:
        filter.productType === "ALL"
          ? productTypeOptions.map((option) => option.key)?.join(",")
          : undefined,
      in_reconcile:
        filter.counting === "COUNTING"
          ? true
          : filter.counting === "UNCOUNTING"
          ? false
          : undefined,
      // has_lot: true,
      limit: params.limit ?? LIMIT,
      offset: params.offset,
    },
  });
};

const GetProductList: Handler<{
  limit?: number;
  offset?: number;
  filter?: FilterStockType;
}> = (controller, params) => {
  const state = controller.getState();

  const filter = !!params.filter
    ? params.filter || {}
    : state.StockManagementSequence?.filterStock || {};

  const active = {
    ALL: undefined,
    ACTIVE: "1,2,3,4",
    INACTIVE: "5",
  }[filter.status || "ALL"];
  // INACTIVE = 5, 'ยังไม่พร้อมขาย'
  // ACTIVE = 1, 'ขายได้ปกติ'
  // INSUFFICIENT = 2, 'ขาดชั่วคราว(Temo Unava)'
  // UNAVAILABLE = 3, 'เลิกใช้งานแต่ของยังไม่หมด'
  // TERMINATED = 4, 'เลิกใช้งาน'

  if (
    filter.isMinQTY ||
    filter.isExpiryDate ||
    filter.isGrandTotal ||
    filter.storage !== "ALL"
  ) {
    return [[], null, null];
  }

  // GET Product
  return ProductList.list({
    apiToken: controller.apiToken,
    params: {
      p_type__code__in: filter.productType === "ALL" ? P_TYPE_CODE : undefined,
      p_type__code:
        filter.productType !== "ALL" ? filter.productType : undefined,
      search: filter.productName,
      active_flag__in: active,
      no_stock: true,
      ordering: "-id",
      limit: params.limit ?? LIMIT,
      offset: params.offset,
    },
  });
};

const UpdateAggStockUpdateList: Handler<Record<string, any>> = async (
  controller,
  params
) => {
  return UpdateAggregateStock.post({
    apiToken: controller.apiToken,
    data: {
      storage: params.storage?.id,
      product: params.product?.id,
      min_quantity: params.min_qty,
      max_quantity: params.max_qty,
      bin_location: params.bin_location,
      active: params.active,
    },
  });
};

const GetProductStock: Handler<
  Partial<{
    product: number;
    storage: number;
    lot: number | "ALL";
    status: ActiveStatusType;
  }>
> = async (controller, params) => {
  const active = {
    ALL: undefined,
    ACTIVE: true,
    INACTIVE: false,
  }[params.status || "ALL"];

  return ProductStockList.list({
    pk: params.product,
    apiToken: controller.apiToken,
    params: {
      storage: params.storage,
      lot: params.lot === "ALL" ? undefined : params.lot,
      active,
    },
  });
};

const GetDefaultBarcode: Handler<{ prefix: "D01" | "S01"; code: string }> = (
  controller,
  params
) => {
  return DefaultBarcodeView.get({
    apiToken: controller.apiToken,
    barcode: `${params.prefix}${params.code}`,
    extra: {
      division: controller.data.division,
      device: controller.data.device,
    },
  });
};
const GetDrugSupplyDetail: Handler<
  { code: string; plan?: boolean },
  Promise<{ type: "DRUG" | "SUPPLY"; data: any }>
> = async (controller, params) => {
  const [[drug], [supply]] = await Promise.all([
    DrugTransferRequestList.list({
      apiToken: controller.apiToken,
      params: { code: params.code },
    }),
    SupplyTransferRequestListCreate.list({
      apiToken: controller.apiToken,
      params: { code: params.code },
    }),
  ]);

  const detail: { type: "DRUG" | "SUPPLY"; data: any } = !!drug.items?.length
    ? { type: "DRUG", data: drug.items[0] }
    : { type: "SUPPLY", data: supply.items?.[0] };

  if (!!detail.data) {
    const api =
      detail.type === "DRUG"
        ? DrugTransferRequestItemPlanList.list
        : SupplyTransferRequestItemPlanList.list;

    const promiseArr = detail.data.items.map((item: any) =>
      api({
        pk: item.id,
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      }).then(([res]: any) => ({ ...item, items: res?.items || [] }))
    );

    detail.data.items = await Promise.all(promiseArr);
  }

  return detail;
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
/**
 *
 * @param pTotal // * จำนวนรายการทั้งหมดของ api product
 * @param sTotal // * จำนวนรายการทั้งหมดของ api agg-stock
 */
const GetPagination = (pTotal: number = 0, sTotal: number = 0) => {
  // * start เริ่มต้น offset default ที่ 0
  const setOffsetApi = (
    api: "product" | "stock",
    total: number,
    start: number = 0
  ) => {
    return Array(Math.ceil((total - start) / LIMIT))
      .fill("")
      .map((_, index) => {
        const offset = index * LIMIT + start;
        // * หากรายการที่เหลือน้อยกว่า limit ให้ set limit เท่ากับรายการที่เหลือ
        const limit = total - offset < LIMIT ? total - offset : LIMIT;
        return {
          api: [api],
          offset: [offset],
          limit: [limit],
        };
      });
  };

  let sOffset = 0;
  const pagination: PaginationType[] = setOffsetApi("product", pTotal);

  const last = pagination.slice(-1)[0] || {};
  const lastLimit = last.limit?.[0] || 0;

  // * product มีรายการอยู่ && offset สุดท้ายมีรายการน้อยกว่า limit && stock มีรายการอยู่
  if (!!pagination.length && lastLimit < LIMIT && !!sTotal) {
    sOffset = LIMIT - last.limit[0];

    pagination[pagination.length - 1] = {
      api: [...last.api, "stock"],
      offset: [...last.offset, 0],
      limit: [...last.limit, sOffset],
    };
  }

  if (!!sTotal && sTotal > sOffset) {
    pagination.push(...setOffsetApi("stock", sTotal, sOffset));
  }

  return pagination;
};

// * รวม product กับ stock โดย product ไว้บนสุด
const ConcatProductStockList = (
  productItems: any[] = [],
  stockItems: any[] = []
) => {
  productItems = productItems.map((item: any) => ({
    product: {
      id: item.id,
      name: item.name,
      name_en: item.name_en,
      code: item.code,
      p_type_name: item.p_type_name,
      unit_name: item.unit_name,
    },
    storage: {},
    active_flag: item.active_flag,
  }));

  return [...productItems, ...stockItems].slice(0, LIMIT);
};

const SetErrorMessage: Handler<LoadCheck & { error: any }> = (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction}`]: "ERROR",
    },
    errorMessage: {
      ...state.errorMessage,
      [params.errorKey]: params.error,
    },
  });
};

/**
 *
 * @param key // * หากต้องการกำหนด key แยกจาก value
 */
const mapOptions = (
  items: (Record<string, any> | string)[],
  valueKey: string = "",
  textKey: string = "",
  key?: string
) => {
  return items.map((value) => {
    const isString = typeof value === "string";
    return {
      key: isString ? value : key ? value[key] : value[valueKey],
      value: isString ? value : value[valueKey],
      text: isString ? value : value[textKey],
    };
  });
};

const formatDatetime = (date: moment.Moment) => {
  return `${date.format(DATE_FORMAT)}T${date.format("HH:mm")}+07`;
};

// ** exp_datetime ISO string
export const stockExpired = (datetime: string) => {
  const sixMonth = moment().add(6, "months");
  const isExp =
    datetime && moment(datetime).toISOString() < sixMonth.toISOString();

  return isExp;
};

const groupItemsByProduct = (items: Partial<StockDetailType>[]) => {
  const drug = items.reduce((result, item) => {
    const productId = item.product?.id || "";
    const lotData = {
      lot_no: item.lot_no,
      expire_date: formatDate(item.expire_date),
      quantity: Number(item.quantity) || 0,
    };
    const length: number = result[productId]?.children?.length || 0;

    if (!result[productId]) {
      result[productId] = {
        code: item.product?.code || "",
        name: item.product?.name || "",
        children: [
          {
            ...lotData,
            total: lotData.quantity,
          },
        ],
      };
    } else {
      result[productId].children.push({
        ...lotData,
        total: lotData.quantity + result[productId].children[length - 1].total,
      });
    }

    return result;
  }, {} as any);

  return Object.values(drug) as GroupDrugType;
};

const getRowSpanColumns = (
  items: { [key: string]: any; children?: Record<string, any>[] }[]
) => {
  return items.flatMap((item: any) =>
    item.children
      ? item.children.map((acc: any, index: number) => ({
          ...acc,
          code: index === 0 ? item.code : "",
          name: index === 0 ? item.name : "",
          rowSpan: index === 0 ? item.children.length : 1,
        }))
      : [item]
  );
};
