import moment from "moment";
import { formatDate } from "react-lib/utils/dateUtils";

const FormInpatientDiagnosis = (props: any) => {
  return {
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 12,
    },
    pageMargins: [15, 125, 0, 50],
    pageSize: "A4",
    styles: {
      title: {
        bold: true,
        fontSize: 20,
        alignment: "center",
      },
      header: {
        fontSize: 18,
        alignment: "center",
      },
      tableHeader: {
        bold: true,
        fontSize: 16,
        alignment: "center",
      },
      tableDetail: {
        fontSize: 14,
        alignment: "center",
      },
      addressRightStyle: {
        fontSize: 12,
        alignment: "right",
      },
      addressLeftStyle: {
        fontSize: 12,
        alignment: "left",
      },
    },
    header: {
      margin: [40, 20, 20, 20],
      stack: [
        {
          columns: [
            {
              width: "50%",
              stack: [{ image: "logochula_dent", width: 150 }],
            },
            {
              width: "50%",
              stack: [
                {
                  text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
                  style: "addressRightStyle",
                },
                {
                  text: [
                    {
                      text: "ที่อยู่ : ",
                      style: "addressRightStyle",
                      bold: true,
                    },
                    {
                      text: "34  ถนนอังรีดูนังต์  แขวงวังใหม่  เขตปทุมวัน",
                      style: "addressRightStyle",
                    },
                  ],
                },
                {
                  text: "กรุงเทพมหานคร  10330  โทร 0 2218 8635",
                  style: "addressRightStyle",
                },
              ],
            },
          ],
        },
        {
          text: "รายงานสถิติวันนอนเฉลี่ยใน แต่ละ Diagnosis (สำหรับผู้ป่วยใน)",
          style: "title",
        },
        {
          text: `วันที่ ${props?.start_date} - ${props?.end_date}`,
          style: "header",
        },
      ],
    },
    content: [
      {
        margin: [20, 0, 10, 10],
        table: {
          widths: ["8%", "25%", "*", "15%"],
          body: [
            [
              { text: "ลำดับ", style: "tableHeader" },
              { text: "รหัสโรค / ICD-10 Code", style: "tableHeader" },
              { text: "Diagnosis / ICD-10 Term", style: "tableHeader" },
              { text: "วันนอน (เฉลี่ย)", style: "tableHeader" },
            ],
            ...(props?.data || []).map((item: any, index: number) => [
              { text: item.no, style: "tableDetail" },
              { text: item.icd10_code, style: "tableDetail" },
              { text: item.icd10_term, style: "tableDetail" },
              { text: item.avg_admit_date, style: "tableDetail" },
            ]),
          ],
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      const printDateMM = moment();
      return {
        stack: [
          {
            margin: [40, 0, 20, 0],
            columns: [
              {
                width: "50%",
                stack: [
                  {
                    text: `วันเวลาที่พิมพ์ : ${formatDate(
                      printDateMM
                    )} [${printDateMM.format("HH:mm")}]`,
                    style: "addressLeftStyle",
                  },
                  {
                    text: `ผู้ออกรายงาน : ${props?.print_user || ""}`,
                    style: "addressLeftStyle",
                  },
                ],
              },
              {
                width: "50%",
                stack: [
                  {
                    text: `หน้าที่ ${currentPage} / ${pageCount}`,
                    style: "addressRightStyle",
                  },
                ],
              },
            ],
          },
        ],
      };
    },
    images: {
      logochula_dent: origin + "/static/images/logochula_dent.jpg",
    },
  };
};

export default FormInpatientDiagnosis;
