import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Dropdown,
  Radio,
  Input,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardConfirmCoverageUX = (props: any) => {
    return(
      <div
        style={{ width: "100%", height: "100%" }}>
        <div
          style={{height: "50px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(48, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
            <div
              style={{ gridRow: "4/18", gridColumn: "1/6", padding: "10px", fontSize: "1.2rem", fontWeight: "bold" }}>
               รายชื่อสิทธิ
            </div>
            <div
              style={{gridRow: "8/47", gridColumn: "45/51"}}>
              <Button
                color="blue"
                id="btn-addCoverage"
                onClick={props.onNewCoverage}
                size="small"
                style={{width: "90%"}}>
                เพิ่มสิทธิการรักษา
              </Button>
            </div>
          </div>
        </div>
        <Table
          data={props.coverageList}
          getTrProps={props.rowProps}
          headers="Coverage Name,Payer Name,Service Type,Priority,Status"
          id="tb-coverage"
          keys="coverage_name,payer_name,service_type_name,priority,status"
          minRows="3"
          showPagination={false}
          style={{height: "150px"}}>
        </Table>
        <div
          style={{height: "500px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(48, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
            <div
              style={{ gridRow: "2/4", gridColumn: "2/10", }}>
              {props.new ? "ข้อมูลสิทธิ (สร้างใหม่)": "ข้อมูลสิทธิเดิม"}
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "2/6", color: "red", }}>
              ประเภทบริการ*
            </div>
            <div
              style={{ textAlign: "right",gridRow: "5/7", gridColumn: "7/9", }}>
              <Radio
                checked={props.service_type === "O"}
                id="rdo-opd"
                onChange={props.changeCoverage("service_type", "O")}>
              </Radio>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "9/11", }}>
              OPD
            </div>
            <div
              style={{ textAlign: "right", gridRow: "5/7", gridColumn: "11/13", }}>
              <Radio
                checked={props.service_type === "I"}
                onChange={props.changeCoverage("service_type", "I")}>
              </Radio>
            </div>
            <div
              id="rdo-ipd"
              style={{ gridRow: "5/7", gridColumn: "13/15", }}>
              IPD
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "17/23", }}>
              ลำดับการเลือกใช้สิทธิ
            </div>
            <div
              style={{ gridRow: "5/8", gridColumn: "24/31", }}>
              <Dropdown
                fluid={true}
                id="cb-priority"
                onChange={props.changeCoverage("priority")}
                options={props.priorityOptions}
                selection={true}
                value={props.priority}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "9/11", gridColumn: "2/5",  color: "red",  }}>
              สิทธิ*
            </div>
            <div
              style={{ gridRow: "11/14", gridColumn: "2/24", }}>
              <Dropdown
                fluid={true}
                id="cb-coverage"
                onChange={props.changeCoverage("coverage")}
                options={props.coverageOptions}
                selection={true}
                value={props.coverage}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "9/11", gridColumn: "25/30",  color: "red",  }}>
              ประเภทสิทธิ์*
            </div>
            <div
              style={{ gridRow: "11/14", gridColumn: "25/49",}}>
              <Dropdown
                fluid={true}
                id="cb-assureType"
                onChange={props.changeCoverage("assure_type")}
                options={props.assureTypeOptions}
                selection={true}
                value={props.assure_type}>
              </Dropdown>
            </div>
            <div
              id="cb-payer"
              style={{ gridRow: "15/20", gridColumn: "2/24", }}>
              {props.searchBoxPayer}
            </div>
            <div
              style={{ gridRow: "15/17", gridColumn: "25/32", }}>
              รหัสสถานพยาบาลหลัก
            </div>
            <div
              style={{ gridRow: "17/20", gridColumn: "25/49", }}>
              <Input
                fluid={true}
                id="txt-hosCode"
                onChange={props.changeCoverage("main_hospital_code")}
                value={props.main_hospital_code}>
              </Input>
            </div>
            <div
              style={{ gridRow: "21/23", gridColumn: "2/5",  color: "red", }}>
              วันที่เริ่ม*
            </div>
            <div
              style={{ gridRow: "23/26", gridColumn: "2/11", }}>
              <DateTextBox
                id="dt-startDateCLM"
                onChange={props.changeDate("start_date")}
                value={props.start_date}>
              </DateTextBox>
            </div>
            <div
              style={{ gridRow: "21/23", gridColumn: "12/16",  color: "red", }}>
              วันหมดอายุ*
            </div>
            <div
              style={{ gridRow: "23/26", gridColumn: "12/21", }}>
              <DateTextBox
                id="dt-endDateCLM"
                onChange={props.changeDate("stop_date")}
                value={props.stop_date}>
              </DateTextBox>
            </div>
            <div
              style={{ gridRow: "26/27", gridColumn: "2/50", borderBottom: "solid 1px rgb(200, 200, 200, 0.5)" }}>
            </div>
            <div
              style={{ gridRow: "28/30", gridColumn: "2/6", }}>
              Refer-in
            </div>
            <div
              style={{ gridRow: "31/33", gridColumn: "2/5", }}>
              Referer:
            </div>
            <div
              style={{ gridRow: "31/34", gridColumn: "5/17",}}>
              <Dropdown
                selection={true}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "31/33", gridColumn: "18/22", }}>
              Refer date:
            </div>
            <div
              style={{ gridRow: "31/34", gridColumn: "22/31",}}>
              <DateTextBox
                onChange={props.changeDate("refer_date")}
                value={props.refer_date}>
              </DateTextBox>
            </div>
            <div
              style={{ gridRow: "36/38", gridColumn: "2/5",}}>
              Refer No.
            </div>
            <div
              style={{ gridRow: "36/39", gridColumn: "5/50", }}>
              <Input
                fluid={true}>
              </Input>
            </div>
            <div
              style={{ gridRow: "40/42", gridColumn: "2/5", }}>
              จุดบริการ
            </div>
            <div
              style={{ gridRow: "40/43", gridColumn: "5/21", }}>
              <Dropdown
                fluid={true}
                selection={true}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "44/47", gridColumn: "46/50", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              <Button
                color="green"
                id="btn-saveCoverage"
                loading={props.loadingSave}
                onClick={props.onSave}
                size="small"
                style={{width: "100%"}}>
                {props.button_save}
              </Button>
            </div>
            <div
              style={{textAlign: "right", "gridRow":"5/7","gridColumn":"32/34"}}>
              <Radio
                checked={props.active}
                id="rdo-claim"
                onChange={props.changeCoverage("active", true)}>
              </Radio>
            </div>
            <div
              style={{paddingLeft: "10px", "gridRow":"5/7","gridColumn":"34/38",}}>
              รับรองสิทธิ
            </div>
            <div
              style={{"textAlign":"right","gridRow":"5/7","gridColumn":"38/40"}}>
              <Radio
                checked={!props.active}
                id="rdo-suspendClaim"
                onChange={props.changeCoverage("active", false)}>
              </Radio>
            </div>
            <div
              style={{"paddingLeft":"10px","gridRow":"5/7","gridColumn":"40/46"}}>
              ระงับสิทธิ
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardConfirmCoverageUX

export const screenPropsDefault = {"searchBoxPayer":"[SearchBox Payer]"}

/* Date Time : Tue May 30 2023 05:17:17 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.coverageList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": "Coverage Name,Payer Name,Service Type,Priority,Status"
        },
        "id": {
          "type": "value",
          "value": "tb-coverage"
        },
        "keys": {
          "type": "value",
          "value": "coverage_name,payer_name,service_type_name,priority,status"
        },
        "minRows": {
          "type": "value",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"150px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"500px\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "label": "divgrid",
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(48, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.new ? \"ข้อมูลสิทธิ (สร้างใหม่)\": \"ข้อมูลสิทธิเดิม\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"2/10\", }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทบริการ*"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"2/6\", color: \"red\", }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\",gridRow: \"5/7\", gridColumn: \"7/9\", }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "OPD"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"9/11\", }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"5/7\", gridColumn: \"11/13\", }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "IPD"
        },
        "id": {
          "type": "value",
          "value": "rdo-ipd"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"13/15\", }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ลำดับการเลือกใช้สิทธิ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"17/23\", }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/8\", gridColumn: \"24/31\", }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "สิทธิ*"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/11\", gridColumn: \"2/5\",  color: \"red\",  }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/14\", gridColumn: \"2/24\", }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทสิทธิ์*"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/11\", gridColumn: \"25/30\",  color: \"red\",  }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/14\", gridColumn: \"25/49\",}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchBoxPayer"
        },
        "id": {
          "type": "value",
          "value": "cb-payer"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"15/20\", gridColumn: \"2/24\", }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสสถานพยาบาลหลัก"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"15/17\", gridColumn: \"25/32\", }"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"17/20\", gridColumn: \"25/49\", }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่เริ่ม*"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"21/23\", gridColumn: \"2/5\",  color: \"red\", }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"23/26\", gridColumn: \"2/11\", }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "วันหมดอายุ*"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"21/23\", gridColumn: \"12/16\",  color: \"red\", }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"23/26\", gridColumn: \"12/21\", }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"26/27\", gridColumn: \"2/50\", borderBottom: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Refer-in"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"28/30\", gridColumn: \"2/6\", }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Referer:"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"31/33\", gridColumn: \"2/5\", }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"31/34\", gridColumn: \"5/17\",}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Refer date:"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"31/33\", gridColumn: \"18/22\", }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"31/34\", gridColumn: \"22/31\",}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Refer No."
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"36/38\", gridColumn: \"2/5\",}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"36/39\", gridColumn: \"5/50\", }"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "จุดบริการ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"40/42\", gridColumn: \"2/5\", }"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"40/43\", gridColumn: \"5/21\", }"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"44/47\", gridColumn: \"46/50\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Dropdown",
      "parent": 11,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-priority"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"priority\")"
        },
        "options": {
          "type": "code",
          "value": "props.priorityOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.priority"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Dropdown",
      "parent": 13,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-coverage"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"coverage\")"
        },
        "options": {
          "type": "code",
          "value": "props.coverageOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.coverage"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Dropdown",
      "parent": 15,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-assureType"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"assure_type\")"
        },
        "options": {
          "type": "code",
          "value": "props.assureTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.assure_type"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Dropdown",
      "parent": 33,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Radio",
      "parent": 6,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.service_type === \"O\""
        },
        "id": {
          "type": "value",
          "value": "rdo-opd"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"service_type\", \"O\")"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Radio",
      "parent": 8,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.service_type === \"I\""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"service_type\", \"I\")"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Input",
      "parent": 19,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "txt-hosCode"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"main_hospital_code\")"
        },
        "value": {
          "type": "code",
          "value": "props.main_hospital_code"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 45,
      "name": "DateTextBox",
      "parent": 21,
      "props": {
        "id": {
          "type": "value",
          "value": "dt-startDateCLM"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"start_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.start_date"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 46,
      "name": "DateTextBox",
      "parent": 23,
      "props": {
        "id": {
          "type": "value",
          "value": "dt-endDateCLM"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"stop_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.stop_date"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Dropdown",
      "parent": 27,
      "props": {
        "selection": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 48,
      "name": "DateTextBox",
      "parent": 29,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"refer_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.refer_date"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Input",
      "parent": 31,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Button",
      "parent": 34,
      "props": {
        "children": {
          "type": "code",
          "value": "props.button_save"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "btn-saveCoverage"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingSave"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSave"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", \"gridRow\":\"5/7\",\"gridColumn\":\"32/34\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "รับรองสิทธิ"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"10px\", \"gridRow\":\"5/7\",\"gridColumn\":\"34/38\",}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"textAlign\":\"right\",\"gridRow\":\"5/7\",\"gridColumn\":\"38/40\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ระงับสิทธิ"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"paddingLeft\":\"10px\",\"gridRow\":\"5/7\",\"gridColumn\":\"40/46\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Radio",
      "parent": 54,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.active"
        },
        "id": {
          "type": "value",
          "value": "rdo-claim"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"active\", true)"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Radio",
      "parent": 56,
      "props": {
        "checked": {
          "type": "code",
          "value": "!props.active"
        },
        "id": {
          "type": "value",
          "value": "rdo-suspendClaim"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoverage(\"active\", false)"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"50px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "label": "divgrid",
      "name": "div",
      "parent": 60,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(48, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": " รายชื่อสิทธิ"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/18\", gridColumn: \"1/6\", padding: \"10px\", fontSize: \"1.2rem\", fontWeight: \"bold\" }"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 62,
      "props": {
        "style": {
          "type": "code",
          "value": "{gridRow: \"8/47\", gridColumn: \"45/51\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Button",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มสิทธิการรักษา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "btn-addCoverage"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNewCoverage"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\"}"
        }
      },
      "seq": 65,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardConfirmCoverageUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "searchBoxPayer": "[SearchBox Payer]"
  },
  "width": 65
}

*********************************************************************************** */
