import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Label,
  FormGroup,
  FormField,
  Input,
  Icon
} from 'semantic-ui-react'

const CardDrugOrderWorkingUX = (props: any) => {
    return(
      <div>
        <div
          style={{ height: props.height || "75vh", position: "relative" }}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","gridTemplateRows":"repeat(66, 1fr)","gridTemplateColumns":"repeat(60, 1fr)"}}>
            <div
              style={{ gridRow: "2/5", gridColumn: "2/26",  fontSize: "1.2rem", fontWeight: "bold", display: "flex" }}>
              {props.nameTypeOrder}
            </div>
            <div
              style={{ gridRow: "2/5", gridColumn: "47/60",  textAlign: "center", display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "5px" }}>
              {}
              <div>
                {props.orderStatus}
              </div>
              <div>
                {props.orderPaymentStatus}
              </div>
            </div>
            <div
              style={{ gridRow: "6/9", gridColumn: "2/6",  display: "flex", alignItems: "center"}}>
              การวินิจฉัย
            </div>
            <div
              style={{ gridRow: "10/13", gridColumn: "2/6",  }}>
              Doctor Note
            </div>
            <div
              style={{ gridRow: "14/17", gridColumn: "2/5",  display: "flex", alignItems: "center" }}>
              ผู้สั่ง
            </div>
            <Label
              style={{ gridRow: "6/9", gridColumn: "7/60", display: "flex", padding: "0.833rem", alignItems: "center" }}>
              {props.drugOrder?.diagnosis || ""}
            </Label>
            <Label
              style={{ gridRow: "10/13", gridColumn: "7/60", display: "flex", padding: "0.833rem", alignItems: "center" }}>
              {props.drugOrder?.doctor_note || ""}
            </Label>
            <Label
              style={{ gridRow: "14/17", gridColumn: "5/17", display: "flex", padding: "0.833rem", alignItems: "center"  }}>
              {props.drugOrder?.requested?.name || ""}
            </Label>
            <div
              style={{ gridRow: "14/17", gridColumn: "18/21",  textAlign: "right" ,  display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
              เวลา
            </div>
            <Label
              style={{ gridRow: "14/17", gridColumn: "22/33", display: "flex", padding: "0.833rem", alignItems: "center"  }}>
              {props.requestTime|| ""}
            </Label>
            <div
              style={{ gridRow: "14/17", gridColumn: "34/37", justifyContent: "flex-end",  textAlign: "right",  display: "flex", alignItems: "center" }}>
              แผนก
            </div>
            <Label
              style={{ gridRow: "14/17", gridColumn: "38/60", display: "flex", padding: "0.833rem", alignItems: "center"  }}>
              {props.drugOrder?.requested?.division || ""}
            </Label>
            <div
              style={{ gridRow: `${38 - (props.numberGridRow?.sub || 0)}/${56 - (props.numberGridRow?.add || 0)}`, gridColumn: "2/60",  display: props.showToolBox ? "flex" : ""}}>
              <Table
                data={props.drugOrderItems}
                getTrProps={props.rowProps}
                headers={props.showPrintLabel? ",ชื่อยา,Dose,Unit,Route,Frequency,Method,PRN,Qty,Onhand Stock,Unit,Price/Unit,Total Price, Label": ",ชื่อยา,Dose,Unit,Route,Frequency,Method,PRN,Qty,Onhand Stock,Unit,Price/Unit,Total Price"}
                id="tb-medOrderReqList"
                keys={props.showPrintLabel? "check, name_status, dose, code_unit, code_route, code_frequency, code_method, prn, quantity, onhand, code_stock, price_unit, price_total,print_label":"check, name_status, dose, code_unit, code_route, code_frequency, code_method, prn, quantity, onhand, code_stock, price_unit, price_total"}
                minRows="4"
                showPagination={false}
                style={{height: "100%", maxHeight: "100%", width: props.showToolBox ? "78%" : "100%" }}
                widths={props.showPrintLabel? "30,auto,auto,auto,auto,auto,auto,auto,auto,auto,auto,auto,auto,":"30,auto,auto,auto,auto,auto,auto,auto,auto,auto,auto,auto,auto"}>
              </Table>
              <div
                style={{width: "22%"}}>
                {props.SubDrugOrderToolbox}
              </div>
            </div>
            <div
              className="ui form"
              style={{ gridRow: `${58 - (props.numberGridRow?.add || 0)}/${63 - (props.numberGridRow?.add || 0)}`, gridColumn: "1/60", paddingLeft: "2rem"}}>
              
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}>
                  <div
                    style={{width: "60px"}}>
                    แผนกที่สั่ง
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={3}>
                  <Input
                    fluid={true}
                    readOnly={true}
                    style={{width: "100%"}}
                    value={props.drugOrder?.order_div || ""}>
                  </Input>
                </FormField>
                <FormField
                  inline={true}>
                  <div
                    style={{width: "42px"}}>
                    ห้องยา
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={3}>
                  <Input
                    fluid={true}
                    readOnly={true}
                    style={{width: "100%"}}
                    value={props.drugOrder?.order_perform_div || ""}>
                  </Input>
                </FormField>
                <FormField
                  inline={true}>
                  <div
                    style={{width: "60px"}}>
                    ราคารวม
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={2}>
                  <Input
                    fluid={true}
                    readOnly={true}
                    style={{width: "100%"}}
                    value={Number(props.sumPriceTotal ||"").toFixed(2)}>
                  </Input>
                </FormField>
                <FormField
                  inline={true}>
                  <div
                    style={{width: "42px"}}>
                    เบิกได้
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={2}>
                  <Input
                    fluid={true}
                    readOnly={true}
                    style={{width: "100%"}}
                    value={Number(props.sumPriceClaimable ||"").toFixed(2)}>
                  </Input>
                </FormField>
                <FormField
                  inline={true}>
                  <div
                    style={{width: "60px"}}>
                    เบิกไม่ได้
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={2}>
                  <Input
                    fluid={true}
                    readOnly={true}
                    style={{width: "100%"}}
                    value={Number(props.sumPriceNonClaimable||"").toFixed(2)}>
                  </Input>
                </FormField>
              </FormGroup>
            </div>
            <div
              style={{"gridRow":`${64 - (props.numberGridRow?.add || 0)}/${64 - (props.numberGridRow?.add || 0)}`,"gridColumn":"2/24", display: "flex", margin: "0 0 auto"}}>
              
              <Button
                onClick={props.handleOnLog}
                size="tiny"
                style={{ width: "auto", padding: "0.78rem 0.75rem" }}>
                LOG
              </Button>
              <Button
                loading={props.loadingNarcotic}
                onClick={props.narcoticPrint}
                style={{display: props.isNarcotic? "block":"none", padding: "0.78rem 0.75rem"}}>
                แบบ ย.ส. 5
              </Button>
              <Button
                onClick={props?.OnReprint}
                style={{display: props?.showReprint ? "" : "none", padding: "0.78rem 0.75rem"}}>
                Reprint
              </Button>
              <Button
                disabled={props.disabledPrintDrugAdviceBtn}
                icon={true}
                labelPosition="left"
                onClick={props.printDrugAdvice}
                style={{...(props.drugOrder?.recommendationFile?.length > 0 ? {}: {display: "none"})}}>
                คำแนะนำการใช้ยา 
                <Icon
                  name="print">
                </Icon>
              </Button>
            </div>
            <div
              style={{"gridRow":`${64 - (props.numberGridRow?.add || 0)}/${64 - (props.numberGridRow?.add || 0)}`,"gridColumn":"44/48", display:props.statusRefill ? "" : "none", margin: "0px 5px"}}>
              
              <div>
                {props.buttonRefill}
              </div>
            </div>
            <div
              style={{"gridRow":`${64 - (props.numberGridRow?.add || 0)}/${64 - (props.numberGridRow?.add || 0)}`,"gridColumn":"55/60", display:props.statusBackward ? "none" : "", margin: "0 0 auto"}}>
              <div>
                {props.buttonBackward}
              </div>
            </div>
            <div
              style={{"gridRow":`${64 - (props.numberGridRow?.add || 0)}/${64 - (props.numberGridRow?.add || 0)}`,"gridColumn":"48/54", display: props.statusForward ?"none":"", margin: "0 0 auto"}}>
              <div>
                {props.buttonForward}
              </div>
            </div>
            <div
              style={{ gridRow: `${64 - (props.numberGridRow?.add || 0)}/${64 - (props.numberGridRow?.add || 0)}`, gridColumn: props.selectedTransporter  ? "23/48":"18/47", ...(props.statusRefill && { display: "none"}),}}>
              {props.additionalProps}
            </div>
            <div
              style={{display : props.drugOrder?. printed ? "flex": "none", gridRow: "18/21", gridColumn: "2/5",  alignItems: "center" }}>
              ผู้ตรวจ
            </div>
            <Label
              style={{ display : props.drugOrder?. printed ? "flex": "none",gridRow: "18/21", gridColumn: "5/17",padding: "0.833rem", alignItems: "center"  }}>
              {props.drugOrder?. printed?.name || ""}
            </Label>
            <div
              style={{display : props.drugOrder?. printed ? "flex": "none", justifyContent: "flex-end",  alignItems: "center", gridRow: "18/21", gridColumn: "18/21",  textAlign: "right" }}>
              เวลา
            </div>
            <Label
              style={{ display : props.drugOrder?. printed ? "flex": "none", gridRow: "18/21", gridColumn: "22/33",  padding: "0.833rem", alignItems: "center"  }}>
              {props.printedTime|| ""}
            </Label>
            <div
              style={{display : props.drugOrder?. printed ? "flex": "none", justifyContent: "flex-end",  alignItems: "center", gridRow: "18/21", gridColumn: "34/37",  textAlign: "right" }}>
              เเผนก
            </div>
            <Label
              style={{display : props.drugOrder?. printed ? "flex": "none", gridRow: "18/21", gridColumn: "38/60",  padding: "0.833rem", alignItems: "center" }}>
              {props.drugOrder?. printed?.division || ""}
            </Label>
            <div
              style={{ display : props.drugOrder?. checked ? "flex": "none",  alignItems: "center",gridRow: "22/25", gridColumn: "2/5", }}>
              ผู้จัด
            </div>
            <Label
              style={{display : props.drugOrder?. checked ? "flex": "none", gridRow: "22/25", gridColumn: "5/17", padding: "0.833rem", alignItems: "center" }}>
              {props.drugOrder?. checked?.name || ""}
            </Label>
            <div
              style={{ display : props.drugOrder?. checked ? "flex": "none", justifyContent: "flex-end",  alignItems: "center",gridRow: "22/25", gridColumn: "18/21",  textAlign: "right" }}>
              เวลา
            </div>
            <Label
              style={{display : props.drugOrder?. checked ? "flex": "none", gridRow: "22/25", gridColumn: "22/33",  padding: "0.833rem", alignItems: "center" }}>
              {props.checkedTime|| ""}
            </Label>
            <div
              style={{display : props.drugOrder?. checked ? "flex": "none", justifyContent: "flex-end",  alignItems: "center", gridRow: "22/25", gridColumn: "34/37",  textAlign: "right" }}>
              แผนก
            </div>
            <Label
              style={{ display : props.drugOrder?. checked ? "flex": "none",gridRow: "22/25", gridColumn: "38/60", padding: "0.833rem", alignItems: "center"  }}>
              {props.drugOrder?. checked?.division || ""}
            </Label>
            <div
              style={{display : props.drugOrder?. delivered ? "flex": "none" ,  alignItems: "center",gridRow: "26/29", gridColumn: "2/5", }}>
              ผู้จ่าย
            </div>
            <Label
              style={{display : props.drugOrder?. delivered ? "flex": "none" , gridRow: "26/29", gridColumn: "5/17",  padding: "0.833rem", alignItems: "center"  }}>
              {props.drugOrder?. delivered?.name || ""}
            </Label>
            <div
              style={{display : props.drugOrder?. delivered ? "flex": "none", justifyContent: "flex-end",  alignItems: "center" , gridRow: "26/29", gridColumn: "18/21",  textAlign: "right" }}>
              เวลา
            </div>
            <Label
              style={{ display : props.drugOrder?. delivered ? "flex": "none" ,gridRow: "26/29", gridColumn: "22/33",  padding: "0.833rem", alignItems: "center"  }}>
              {props.deliveredTime|| ""}
            </Label>
            <div
              style={{display : props.drugOrder?. delivered ? "flex": "none", justifyContent: "flex-end",  alignItems: "center" , gridRow: "26/29", gridColumn: "34/37",  textAlign: "right" }}>
              แผนก
            </div>
            <Label
              style={{display : props.drugOrder?. delivered ? "flex": "none" , gridRow: "26/29", gridColumn: "38/60", padding: "0.833rem", alignItems: "center"  }}>
              {props.drugOrder?. delivered?.division || ""}
            </Label>
            <div
              style={{ display : props.drugOrder?. transported ? "flex": "none",  alignItems: "center" ,gridRow: "30/33", gridColumn: "2/5", }}>
              ผู้ขนส่ง
            </div>
            <Label
              style={{ display : props.drugOrder?. transported ? "flex": "none" , gridRow: "30/33", gridColumn: "5/17", padding: "0.833rem", alignItems: "center"  }}>
              {props.drugOrder?. transported?.name || ""}
            </Label>
            <div
              style={{  display : props.drugOrder?. transported ? "flex": "none", justifyContent: "flex-end",  alignItems: "center" ,gridRow: "30/33", gridColumn: "18/21",  textAlign: "right" }}>
              เวลา
            </div>
            <Label
              style={{ display : props.drugOrder?. transported ? "flex": "none" , gridRow: "30/33", gridColumn: "22/33", padding: "0.833rem", alignItems: "center"  }}>
              {props.transportedTime|| ""}
            </Label>
            <div
              style={{ display : props.drugOrder?. transported ? "flex": "none", justifyContent: "flex-end",  alignItems: "center" , gridRow: "30/33", gridColumn: "34/37",  textAlign: "right" }}>
              แผนก
            </div>
            <Label
              style={{  display : props.drugOrder?. transported ? "flex": "none" ,gridRow: "30/33", gridColumn: "38/60", padding: "0.833rem", alignItems: "center" }}>
              {props.drugOrder?.transported?.division || ""}
            </Label>
            <div
              style={{"gridRow":`${64 - (props.numberGridRow?.add || 0)}/${64 - (props.numberGridRow?.add || 0)}`,"gridColumn":"48/55", display: props.statusForward ?"":"none", margin: "auto 0"}}>
              
              <div
                style={{margin: "-0.55rem 0.5rem 0"}}>
                {props.buttonReturn}
              </div>
            </div>
            <div
              style={{ gridRow: `${65 - (props.numberGridRow?.add || 0)}/${65 - (props.numberGridRow?.add || 0)}`, gridColumn: "20/60"}}>
              {props?.drugDelivery}
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardDrugOrderWorkingUX

export const screenPropsDefault = {"backwardActionButton":"REJECT","forwardActionButton":"VERIFY AND PRINT"}

/* Date Time : Mon Apr 24 2023 03:21:25 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 194,
      "label": "divgrid",
      "name": "div",
      "parent": 195,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"gridTemplateRows\":\"repeat(66, 1fr)\",\"gridTemplateColumns\":\"repeat(60, 1fr)\"}"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": null,
      "id": 195,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: props.height || \"75vh\", position: \"relative\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 196,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nameTypeOrder"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/5\", gridColumn: \"2/26\",  fontSize: \"1.2rem\", fontWeight: \"bold\", display: \"flex\" }"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": null,
      "id": 199,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/5\", gridColumn: \"47/60\",  textAlign: \"center\", display: \"flex\", alignItems: \"center\", justifyContent: \"flex-end\", paddingRight: \"5px\" }"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": null,
      "id": 200,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "การวินิจฉัย"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/9\", gridColumn: \"2/6\",  display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": null,
      "id": 201,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "Doctor Note"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/13\", gridColumn: \"2/6\",  }"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": null,
      "id": 202,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้สั่ง"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/17\", gridColumn: \"2/5\",  display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": null,
      "id": 206,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/17\", gridColumn: \"18/21\",  textAlign: \"right\" ,  display: \"flex\", alignItems: \"center\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": null,
      "id": 208,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/17\", gridColumn: \"34/37\", justifyContent: \"flex-end\",  textAlign: \"right\",  display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": null,
      "id": 210,
      "name": "div",
      "parent": 194,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: `${38 - (props.numberGridRow?.sub || 0)}/${56 - (props.numberGridRow?.add || 0)}`, gridColumn: \"2/60\",  display: props.showToolBox ? \"flex\" : \"\"}"
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": null,
      "id": 221,
      "name": "div",
      "parent": 194,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":`${64 - (props.numberGridRow?.add || 0)}/${64 - (props.numberGridRow?.add || 0)}`,\"gridColumn\":\"55/60\", display:props.statusBackward ? \"none\" : \"\", margin: \"0 0 auto\"}"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": null,
      "id": 222,
      "name": "div",
      "parent": 194,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":`${64 - (props.numberGridRow?.add || 0)}/${64 - (props.numberGridRow?.add || 0)}`,\"gridColumn\":\"48/54\", display: props.statusForward ?\"none\":\"\", margin: \"0 0 auto\"}"
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 225,
      "name": "Table",
      "parent": 210,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugOrderItems"
        },
        "getTdProps": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "code",
          "value": "props.showPrintLabel? \",ชื่อยา,Dose,Unit,Route,Frequency,Method,PRN,Qty,Onhand Stock,Unit,Price/Unit,Total Price, Label\": \",ชื่อยา,Dose,Unit,Route,Frequency,Method,PRN,Qty,Onhand Stock,Unit,Price/Unit,Total Price\""
        },
        "id": {
          "type": "value",
          "value": "tb-medOrderReqList"
        },
        "keys": {
          "type": "code",
          "value": "props.showPrintLabel? \"check, name_status, dose, code_unit, code_route, code_frequency, code_method, prn, quantity, onhand, code_stock, price_unit, price_total,print_label\":\"check, name_status, dose, code_unit, code_route, code_frequency, code_method, prn, quantity, onhand, code_stock, price_unit, price_total\""
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", maxHeight: \"100%\", width: props.showToolBox ? \"78%\" : \"100%\" }"
        },
        "widths": {
          "type": "code",
          "value": "props.showPrintLabel? \"30,auto,auto,auto,auto,auto,auto,auto,auto,auto,auto,auto,auto,\":\"30,auto,auto,auto,auto,auto,auto,auto,auto,auto,auto,auto,auto\""
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": null,
      "id": 226,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.additionalProps"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: `${64 - (props.numberGridRow?.add || 0)}/${64 - (props.numberGridRow?.add || 0)}`, gridColumn: props.selectedTransporter  ? \"23/48\":\"18/47\", ...(props.statusRefill && { display: \"none\"}),}"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": null,
      "id": 227,
      "name": "div",
      "parent": 210,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SubDrugOrderToolbox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"22%\"}"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": null,
      "id": 231,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: `${58 - (props.numberGridRow?.add || 0)}/${63 - (props.numberGridRow?.add || 0)}`, gridColumn: \"1/60\", paddingLeft: \"2rem\"}"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": null,
      "id": 243,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\":`${64 - (props.numberGridRow?.add || 0)}/${64 - (props.numberGridRow?.add || 0)}`,\"gridColumn\":\"2/24\", display: \"flex\", margin: \"0 0 auto\"}"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 244,
      "name": "Button",
      "parent": 243,
      "props": {
        "children": {
          "type": "value",
          "value": "LOG"
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.handleOnLog"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"auto\", padding: \"0.78rem 0.75rem\" }"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 245,
      "name": "Button",
      "parent": 243,
      "props": {
        "children": {
          "type": "value",
          "value": "แบบ ย.ส. 5"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingNarcotic"
        },
        "onClick": {
          "type": "code",
          "value": "props.narcoticPrint"
        },
        "style": {
          "type": "code",
          "value": "{display: props.isNarcotic? \"block\":\"none\", padding: \"0.78rem 0.75rem\"}"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": null,
      "id": 246,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ตรวจ"
        },
        "style": {
          "type": "code",
          "value": "{display : props.drugOrder?. printed ? \"flex\": \"none\", gridRow: \"18/21\", gridColumn: \"2/5\",  alignItems: \"center\" }"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": null,
      "id": 248,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{display : props.drugOrder?. printed ? \"flex\": \"none\", justifyContent: \"flex-end\",  alignItems: \"center\", gridRow: \"18/21\", gridColumn: \"18/21\",  textAlign: \"right\" }"
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": null,
      "id": 250,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "เเผนก"
        },
        "style": {
          "type": "code",
          "value": "{display : props.drugOrder?. printed ? \"flex\": \"none\", justifyContent: \"flex-end\",  alignItems: \"center\", gridRow: \"18/21\", gridColumn: \"34/37\",  textAlign: \"right\" }"
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": null,
      "id": 252,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้จัด"
        },
        "style": {
          "type": "code",
          "value": "{ display : props.drugOrder?. checked ? \"flex\": \"none\",  alignItems: \"center\",gridRow: \"22/25\", gridColumn: \"2/5\", }"
        }
      },
      "seq": 262,
      "void": false
    },
    {
      "from": null,
      "id": 254,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{ display : props.drugOrder?. checked ? \"flex\": \"none\", justifyContent: \"flex-end\",  alignItems: \"center\",gridRow: \"22/25\", gridColumn: \"18/21\",  textAlign: \"right\" }"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": null,
      "id": 256,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{display : props.drugOrder?. checked ? \"flex\": \"none\", justifyContent: \"flex-end\",  alignItems: \"center\", gridRow: \"22/25\", gridColumn: \"34/37\",  textAlign: \"right\" }"
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": null,
      "id": 258,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้จ่าย"
        },
        "style": {
          "type": "code",
          "value": "{display : props.drugOrder?. delivered ? \"flex\": \"none\" ,  alignItems: \"center\",gridRow: \"26/29\", gridColumn: \"2/5\", }"
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": null,
      "id": 260,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{display : props.drugOrder?. delivered ? \"flex\": \"none\", justifyContent: \"flex-end\",  alignItems: \"center\" , gridRow: \"26/29\", gridColumn: \"18/21\",  textAlign: \"right\" }"
        }
      },
      "seq": 278,
      "void": false
    },
    {
      "from": null,
      "id": 262,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{display : props.drugOrder?. delivered ? \"flex\": \"none\", justifyContent: \"flex-end\",  alignItems: \"center\" , gridRow: \"26/29\", gridColumn: \"34/37\",  textAlign: \"right\" }"
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": null,
      "id": 264,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ขนส่ง"
        },
        "style": {
          "type": "code",
          "value": "{ display : props.drugOrder?. transported ? \"flex\": \"none\",  alignItems: \"center\" ,gridRow: \"30/33\", gridColumn: \"2/5\", }"
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": null,
      "id": 266,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{  display : props.drugOrder?. transported ? \"flex\": \"none\", justifyContent: \"flex-end\",  alignItems: \"center\" ,gridRow: \"30/33\", gridColumn: \"18/21\",  textAlign: \"right\" }"
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": null,
      "id": 268,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{ display : props.drugOrder?. transported ? \"flex\": \"none\", justifyContent: \"flex-end\",  alignItems: \"center\" , gridRow: \"30/33\", gridColumn: \"34/37\",  textAlign: \"right\" }"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": null,
      "id": 270,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\":`${64 - (props.numberGridRow?.add || 0)}/${64 - (props.numberGridRow?.add || 0)}`,\"gridColumn\":\"48/55\", display: props.statusForward ?\"\":\"none\", margin: \"auto 0\"}"
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": null,
      "id": 272,
      "name": "div",
      "parent": 222,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonForward"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": null,
      "id": 273,
      "name": "div",
      "parent": 221,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonBackward"
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": null,
      "id": 274,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.drugDelivery"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: `${65 - (props.numberGridRow?.add || 0)}/${65 - (props.numberGridRow?.add || 0)}`, gridColumn: \"20/60\"}"
        }
      },
      "seq": 320,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 275,
      "name": "Button",
      "parent": 243,
      "props": {
        "children": {
          "type": "value",
          "value": "Reprint"
        },
        "onClick": {
          "type": "code",
          "value": "props?.OnReprint"
        },
        "style": {
          "type": "code",
          "value": "{display: props?.showReprint ? \"\" : \"none\", padding: \"0.78rem 0.75rem\"}"
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 276,
      "name": "Label",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?.diagnosis || \"\""
        },
        "name": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/9\", gridColumn: \"7/60\", display: \"flex\", padding: \"0.833rem\", alignItems: \"center\" }"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 277,
      "name": "Label",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?.doctor_note || \"\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/13\", gridColumn: \"7/60\", display: \"flex\", padding: \"0.833rem\", alignItems: \"center\" }"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 278,
      "name": "Label",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?.requested?.name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/17\", gridColumn: \"5/17\", display: \"flex\", padding: \"0.833rem\", alignItems: \"center\"  }"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 279,
      "name": "Label",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.requestTime|| \"\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/17\", gridColumn: \"22/33\", display: \"flex\", padding: \"0.833rem\", alignItems: \"center\"  }"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 280,
      "name": "Label",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?.requested?.division || \"\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/17\", gridColumn: \"38/60\", display: \"flex\", padding: \"0.833rem\", alignItems: \"center\"  }"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 281,
      "name": "Label",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?. printed?.name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{ display : props.drugOrder?. printed ? \"flex\": \"none\",gridRow: \"18/21\", gridColumn: \"5/17\",padding: \"0.833rem\", alignItems: \"center\"  }"
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 282,
      "name": "Label",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.printedTime|| \"\""
        },
        "style": {
          "type": "code",
          "value": "{ display : props.drugOrder?. printed ? \"flex\": \"none\", gridRow: \"18/21\", gridColumn: \"22/33\",  padding: \"0.833rem\", alignItems: \"center\"  }"
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 283,
      "name": "Label",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?. printed?.division || \"\""
        },
        "style": {
          "type": "code",
          "value": "{display : props.drugOrder?. printed ? \"flex\": \"none\", gridRow: \"18/21\", gridColumn: \"38/60\",  padding: \"0.833rem\", alignItems: \"center\" }"
        }
      },
      "seq": 260,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 284,
      "name": "Label",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?. checked?.name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{display : props.drugOrder?. checked ? \"flex\": \"none\", gridRow: \"22/25\", gridColumn: \"5/17\", padding: \"0.833rem\", alignItems: \"center\" }"
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 285,
      "name": "Label",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.checkedTime|| \"\""
        },
        "style": {
          "type": "code",
          "value": "{display : props.drugOrder?. checked ? \"flex\": \"none\", gridRow: \"22/25\", gridColumn: \"22/33\",  padding: \"0.833rem\", alignItems: \"center\" }"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 286,
      "name": "Label",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?. checked?.division || \"\""
        },
        "style": {
          "type": "code",
          "value": "{ display : props.drugOrder?. checked ? \"flex\": \"none\",gridRow: \"22/25\", gridColumn: \"38/60\", padding: \"0.833rem\", alignItems: \"center\"  }"
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 287,
      "name": "Label",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?. delivered?.name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{display : props.drugOrder?. delivered ? \"flex\": \"none\" , gridRow: \"26/29\", gridColumn: \"5/17\",  padding: \"0.833rem\", alignItems: \"center\"  }"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 288,
      "name": "Label",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.deliveredTime|| \"\""
        },
        "style": {
          "type": "code",
          "value": "{ display : props.drugOrder?. delivered ? \"flex\": \"none\" ,gridRow: \"26/29\", gridColumn: \"22/33\",  padding: \"0.833rem\", alignItems: \"center\"  }"
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 289,
      "name": "Label",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?. delivered?.division || \"\""
        },
        "style": {
          "type": "code",
          "value": "{display : props.drugOrder?. delivered ? \"flex\": \"none\" , gridRow: \"26/29\", gridColumn: \"38/60\", padding: \"0.833rem\", alignItems: \"center\"  }"
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 290,
      "name": "Label",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?. transported?.name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{ display : props.drugOrder?. transported ? \"flex\": \"none\" , gridRow: \"30/33\", gridColumn: \"5/17\", padding: \"0.833rem\", alignItems: \"center\"  }"
        }
      },
      "seq": 285,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 291,
      "name": "Label",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.transportedTime|| \"\""
        },
        "style": {
          "type": "code",
          "value": "{ display : props.drugOrder?. transported ? \"flex\": \"none\" , gridRow: \"30/33\", gridColumn: \"22/33\", padding: \"0.833rem\", alignItems: \"center\"  }"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 292,
      "name": "Label",
      "parent": 194,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugOrder?.transported?.division || \"\""
        },
        "style": {
          "type": "code",
          "value": "{  display : props.drugOrder?. transported ? \"flex\": \"none\" ,gridRow: \"30/33\", gridColumn: \"38/60\", padding: \"0.833rem\", alignItems: \"center\" }"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 293,
      "name": "FormGroup",
      "parent": 231,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 294,
      "name": "FormField",
      "parent": 293,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 294,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 295,
      "name": "FormField",
      "parent": 293,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 295,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 296,
      "name": "FormField",
      "parent": 293,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 296,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 297,
      "name": "FormField",
      "parent": 293,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 298,
      "name": "FormField",
      "parent": 293,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 298,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 299,
      "name": "FormField",
      "parent": 293,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 300,
      "name": "FormField",
      "parent": 293,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 301,
      "name": "FormField",
      "parent": 293,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 301,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 302,
      "name": "FormField",
      "parent": 293,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 302,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 303,
      "name": "FormField",
      "parent": 293,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 303,
      "void": false
    },
    {
      "from": null,
      "id": 304,
      "name": "div",
      "parent": 294,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนกที่สั่ง"
        },
        "style": {
          "type": "code",
          "value": "{width: \"60px\"}"
        }
      },
      "seq": 304,
      "void": false
    },
    {
      "from": null,
      "id": 305,
      "name": "div",
      "parent": 296,
      "props": {
        "children": {
          "type": "value",
          "value": "ห้องยา"
        },
        "style": {
          "type": "code",
          "value": "{width: \"42px\"}"
        }
      },
      "seq": 305,
      "void": false
    },
    {
      "from": null,
      "id": 306,
      "name": "div",
      "parent": 298,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคารวม"
        },
        "style": {
          "type": "code",
          "value": "{width: \"60px\"}"
        }
      },
      "seq": 306,
      "void": false
    },
    {
      "from": null,
      "id": 307,
      "name": "div",
      "parent": 300,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกได้"
        },
        "style": {
          "type": "code",
          "value": "{width: \"42px\"}"
        }
      },
      "seq": 307,
      "void": false
    },
    {
      "from": null,
      "id": 308,
      "name": "div",
      "parent": 302,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกไม่ได้"
        },
        "style": {
          "type": "code",
          "value": "{width: \"60px\"}"
        }
      },
      "seq": 308,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 309,
      "name": "Input",
      "parent": 295,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.drugOrder?.order_div || \"\""
        }
      },
      "seq": 309,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 310,
      "name": "Input",
      "parent": 297,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.drugOrder?.order_perform_div || \"\""
        }
      },
      "seq": 310,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 311,
      "name": "Input",
      "parent": 299,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "Number(props.sumPriceTotal ||\"\").toFixed(2)"
        }
      },
      "seq": 311,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 312,
      "name": "Input",
      "parent": 301,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "Number(props.sumPriceClaimable ||\"\").toFixed(2)"
        }
      },
      "seq": 312,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 313,
      "name": "Input",
      "parent": 303,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "Number(props.sumPriceNonClaimable||\"\").toFixed(2)"
        }
      },
      "seq": 313,
      "void": false
    },
    {
      "from": null,
      "id": 314,
      "name": "div",
      "parent": 199,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderStatus"
        }
      },
      "seq": 314,
      "void": false
    },
    {
      "from": null,
      "id": 317,
      "name": "div",
      "parent": 199,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderPaymentStatus"
        }
      },
      "seq": 317,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 318,
      "name": "Button",
      "parent": 243,
      "props": {
        "children": {
          "type": "value",
          "value": "คำแนะนำการใช้ยา "
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledPrintDrugAdviceBtn"
        },
        "icon": {
          "type": "code",
          "value": "true"
        },
        "labelPosition": {
          "type": "value",
          "value": "left"
        },
        "onClick": {
          "type": "code",
          "value": "props.printDrugAdvice"
        },
        "style": {
          "type": "code",
          "value": "{...(props.drugOrder?.recommendationFile?.length > 0 ? {}: {display: \"none\"})}"
        }
      },
      "seq": 318,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 319,
      "name": "Icon",
      "parent": 318,
      "props": {
        "name": {
          "type": "value",
          "value": "print"
        }
      },
      "seq": 319,
      "void": false
    },
    {
      "from": null,
      "id": 320,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\":`${64 - (props.numberGridRow?.add || 0)}/${64 - (props.numberGridRow?.add || 0)}`,\"gridColumn\":\"44/48\", display:props.statusRefill ? \"\" : \"none\", margin: \"0px 5px\"}"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": null,
      "id": 322,
      "name": "div",
      "parent": 320,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonRefill"
        }
      },
      "seq": 322,
      "void": false
    },
    {
      "from": null,
      "id": 323,
      "name": "div",
      "parent": 270,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonReturn"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"-0.55rem 0.5rem 0\"}"
        }
      },
      "seq": 323,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugOrderWorkingUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "backwardActionButton": "REJECT",
    "forwardActionButton": "VERIFY AND PRINT"
  },
  "width": 90
}

*********************************************************************************** */
