import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input,
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardWardQueueUX = (props: any) => {
    return(
      <div
        style={{margin: "3px -7px"}}>
        <div
          style={{height: "40rem", width: "100%"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(45, 1fr)","gridTemplateColumns":"repeat(70, 1fr)"}}>
            <div
              style={{ gridRow: "2/5", gridColumn: "2/11", fontWeight: "bold", fontSize: "20px"}}>
              รายชื่อผู้ป่วยใน
            </div>
            <div
              style={{ gridRow: "2/5", gridColumn: "55/70", display: "flex", alignItems: "center"}}>
              <div
                style={{fontSize: "16px", fontWeight: "bold"}}>
                Barcode/AN/HN
              </div>
              <Input
                disabled={props.loadingFilter}
                onKeyDown={props.onBarcodeKeyDown}
                ref={props.barcodeRef}
                style={{paddingLeft: "10px"}}>
              </Input>
            </div>
            <div
              style={{ gridRow: "6/9", gridColumn: "2/7", fontSize: "16px", fontWeight: "bold", margin: "auto 0"}}>
              Ward
            </div>
            <div
              style={{ gridRow: "6/9", gridColumn: "7/18"}}>
              <Dropdown
                clearable={true}
                disabled={props.loadingFilter}
                fluid={true}
                onChange={props.onChangeWard}
                options={props.wardOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.ward}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "6/9", gridColumn: "20/25",fontSize: "16px", fontWeight: "bold", margin: "auto 0"}}>
              ช่วงวันที่
            </div>
            <div
              style={{ gridRow: "6/9", gridColumn: "25/35"}}>
              {props.startDateElement}
            </div>
            <div
              style={{ gridRow: "6/9", gridColumn: "37/40", fontSize: "16px", fontWeight: "bold", margin: "auto 0"}}>
              ถึง
            </div>
            <div
              style={{ gridRow: "6/9", gridColumn: "40/50"}}>
              <DateTextBox
                disabled={props.loadingFilter}
                onChange={props.onChangeEndDate}
                value={props.endDate}>
              </DateTextBox>
            </div>
            <div
              style={{ gridRow: "10/13", gridColumn: "2/7", fontWeight: "bold", fontSize: "16px", margin: "auto 0"}}>
              สถานะผู้ป่วย
            </div>
            <div
              style={{ gridRow: "10/13", gridColumn: "7/18"}}>
              <Dropdown
                clearable={true}
                disabled={props.loadingFilter}
                fluid={true}
                onChange={props.onChangePatientStatus}
                options={props.patientStatusOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patientStatus}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "10/13", gridColumn: "20/25", fontSize: "16px", fontWeight: "bold", margin: "auto 0"}}>
              เลขห้อง
            </div>
            <div
              style={{ gridRow: "10/13", gridColumn: "25/35",}}>
              <div
                style={{marginLeft: "-0.75rem"}}>
                {props.roomNumberSearch}
              </div>
            </div>
            <div
              style={{ gridRow: "10/13", gridColumn: "40/52", display: "flex"}}>
              <Button
                color="blue"
                disabled={props.loadingFilter}
                loading={props.loadingFilter}
                onClick={props.onSearch}>
                ค้นหา
              </Button>
              <Button
                color="brown"
                disabled={props.disabledPrintReportBtn}
                onClick={props.onPrintReport}
                style={{...(!props.showPrintReportBtn && {display: "none"})}}>
                พิมพ์รายงาน
              </Button>
            </div>
            <div
              style={{ gridRow: "10/13", gridColumn: "52/70", display: "flex", justifyContent: "flex-end"}}>
              <Button
                color="orange"
                disabled={props.config?.disabledAdmitButton || false}
                onClick={props.onAdmitReceive}>
                รับเข้า
              </Button>
              <Button
                color="green"
                disabled={props.config?.disabledBedMoveButton || false}
                onClick={props.onBedMove}
                style={{display: props.config?.hideBedMoveButton ? "none" : ""}}>
                ย้ายเตียง
              </Button>
              <Button
                color="blue"
                disabled={props.moveOutDisable}
                onClick={props.onMoveOut}
                style={{display: props.config?.showMoveOutButton ? "" : "none"}}>
                ย้ายออก
              </Button>
              <Button
                color="red"
                disabled={props.config?.disabledCancelAdmitButton || false}
                onClick={props.onCancelAdmit}>
                ยกเลิกรับเข้า
              </Button>
            </div>
            <div
              style={{ gridRow: "14/45", gridColumn: "2/70", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              <Table
                data={props.wardQueueList}
                getTrProps={props.getSelectedRow}
                headers="ห้อง,Ward,HN/AN,ข้อมูลผู้ป่วย,สถานะผู้ป่วย,สถานะห้อง,สิทธิ/Payer,แพทย์เจ้าของไข้,Diagnosis,New order"
                keys="room_no,ward_name,hn,patient_info,patient_status,room_status,coverage,primary_doctor,diagnosis,new_order"
                minRows="10"
                showPagination={false}
                style={{height: "calc(100vh - 19.5rem)"}}
                widths="100,,,,,100,,,,100">
              </Table>
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardWardQueueUX

export const screenPropsDefault = {"current":null}

/* Date Time : Wed Jul 26 2023 04:20:49 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{margin: \"3px -7px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"40rem\", width: \"100%\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(45, 1fr)\",\"gridTemplateColumns\":\"repeat(70, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "รายชื่อผู้ป่วยใน"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/5\", gridColumn: \"2/11\", fontWeight: \"bold\", fontSize: \"20px\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/5\", gridColumn: \"55/70\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "Barcode/AN/HN"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"16px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Input",
      "parent": 19,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.loadingFilter"
        },
        "onChange": {
          "type": "value",
          "value": ""
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.onBarcodeKeyDown"
        },
        "ref": {
          "type": "code",
          "value": "props.barcodeRef"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"10px\"}"
        },
        "value": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Ward"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/9\", gridColumn: \"2/7\", fontSize: \"16px\", fontWeight: \"bold\", margin: \"auto 0\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/9\", gridColumn: \"7/18\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ช่วงวันที่"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/9\", gridColumn: \"20/25\",fontSize: \"16px\", fontWeight: \"bold\", margin: \"auto 0\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startDateElement"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/9\", gridColumn: \"25/35\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/9\", gridColumn: \"37/40\", fontSize: \"16px\", fontWeight: \"bold\", margin: \"auto 0\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/9\", gridColumn: \"40/50\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/13\", gridColumn: \"2/7\", fontWeight: \"bold\", fontSize: \"16px\", margin: \"auto 0\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/13\", gridColumn: \"7/18\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขห้อง"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/13\", gridColumn: \"20/25\", fontSize: \"16px\", fontWeight: \"bold\", margin: \"auto 0\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/13\", gridColumn: \"25/35\",}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/13\", gridColumn: \"40/52\", display: \"flex\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/13\", gridColumn: \"52/70\", display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/45\", gridColumn: \"2/70\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "code",
          "value": "props.roomNumberSearch"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"-0.75rem\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Dropdown",
      "parent": 24,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.loadingFilter"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeWard"
        },
        "options": {
          "type": "code",
          "value": "props.wardOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ward"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Dropdown",
      "parent": 30,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.loadingFilter"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePatientStatus"
        },
        "options": {
          "type": "code",
          "value": "props.patientStatusOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patientStatus"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 46,
      "name": "DateTextBox",
      "parent": 28,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.loadingFilter"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Button",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.loadingFilter"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingFilter"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Button",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "รับเข้า"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledAdmitButton || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAdmitReceive"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 51,
      "name": "Table",
      "parent": 37,
      "props": {
        "data": {
          "type": "code",
          "value": "props.wardQueueList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getSelectedRow"
        },
        "headers": {
          "type": "value",
          "value": "ห้อง,Ward,HN/AN,ข้อมูลผู้ป่วย,สถานะผู้ป่วย,สถานะห้อง,สิทธิ/Payer,แพทย์เจ้าของไข้,Diagnosis,New order"
        },
        "keys": {
          "type": "value",
          "value": "room_no,ward_name,hn,patient_info,patient_status,room_status,coverage,primary_doctor,diagnosis,new_order"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100vh - 19.5rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": "100,,,,,100,,,,100"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Button",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "ย้ายเตียง"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledBedMoveButton || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onBedMove"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideBedMoveButton ? \"none\" : \"\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Button",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกรับเข้า"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledCancelAdmitButton || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelAdmit"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Button",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "ย้ายออก"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.moveOutDisable"
        },
        "onClick": {
          "type": "code",
          "value": "props.onMoveOut"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.showMoveOutButton ? \"\" : \"none\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Button",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์รายงาน"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "brown"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledPrintReportBtn"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintReport"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.showPrintReportBtn && {display: \"none\"})}"
        }
      },
      "seq": 56,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardWardQueueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "current": null
  },
  "width": 80
}

*********************************************************************************** */
