import React, {useRef, useState, useEffect} from "react";
import PropTypes from "prop-types";
import {
  Input,
  Form,
  Button,
  Divider,
  Checkbox,
  Popup,
  Modal
} from "semantic-ui-react";
import PureReactTable, {
  formatPrice,
  formatComma
} from "../common/PureReactTable";
import * as Util from "../../utils";
import moment from "moment";
import * as CONSTANT from "../../utils/constant";
import * as Common from "../../apps/common";
import * as BIL from "../../apps/BIL";
import {
  DateTextBox,
  ComboBox,
  RightLabel,
  SearchBox
} from "../../apps/common";
import ModInfoError from "../../apps/common/ModInfo";
import ModInfoSuccess from "../../apps/common/ModInfo";
import { boolean } from '@storybook/addon-knobs';

const CardOtherBill = props => {
  const isMounted = useRef(true)
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [eligibilityChoices, setEligibilityChoices] = useState([]);
  const [hospitalFee, setHospitalFee] = useState([]);
  const [selectedCell, setSelectedCell] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [claimablePrice, setClaimablePrice] = useState(0);
  const [nonClaimablePrice, setNonClaimablePrice] = useState(0);
  const [startDate, setStartDate] = useState(Util.formatDate(moment()));
  const [endDate, setEndDate] = useState(Util.formatDate(moment()));
  const [chkFilterDate, setChkFilterDate] = useState(true);
  const [openModInfoError, setOpenModInfoError] = useState(false);
  const [openModInfoSuccess, setOpenModInfoSuccess] = useState(false);
  const [alertText, setAlertText] = useState(null);
  const [openModProduct, setOpenModProduct] = useState(false);
  const [pageLimit, setPageLimit] = useState(20);
  const [numberOfPage, setNumberOfPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  const searchBoxRef = useRef();
  const inputRef = useRef([]);
  const popupRef = useRef();

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  function handleClickOutside(event) {
    // console.log(event.target, 'event')
    // if(popupRef && popupRef.current
    //   //  && !popupRef.current.contains(event.target)
    //    ){
    //     console.log( popupRef.current, ' popupRef.current')
    //   setSelectedCell({})
    // }
    // if (inputRef.current &&
    //   inputRef.current.inputRef && inputRef.current.inputRef.current &&
    //   !inputRef.current.inputRef.current.contains(event.target) &&
    // (itemRef.current && !itemRef.current.contains(event.target))
    // ) {
    //   setVisible(false)
    // }
  }

  useEffect(() => {
    getHospitalFee();
    getEligibilityTypeChoices();
    refresh();
    return () => {
      isMounted.current = false
    }
  }, []);

  useEffect(() => {
    calculatePrice();
  }, [previewData]);

  useEffect(() => {
    refresh();
  }, [pageLimit]);

  useEffect(() => {
    refresh(false);
  }, [currentPage]);

  const columns = [
    {
      Header: "No",
      accessor: "no",
      minWidth: 20,
      width: 40,
      Cell: row => {
        return currentPage * pageLimit + row.index + 1;
      }
    },
    {
      Header: "Service Code",
      accessor: "service_code",
      width: 100
    },
    {
      Header: "Product Name",
      accessor: "name",
      width: 300
    },
    {
      Header: "จำนวนสั่ง",
      accessor: "quantity",
      width: 90,
      getProps: (state, rowInfo, column) => {
        let className = "";
        if (rowInfo && rowInfo.index === selectedCell.quantity) {
          className = "focus";
        }
        return {
          className: className,
          style: {
            cursor: "pointer",
            backgroundColor:
              rowInfo && !hospitalFee.includes(rowInfo.original.code)
                ? "rgb(255, 255, 204)"
                : null
          }
        };
      },
      Cell: row => {
        if (
          hospitalFee.includes(row.original.code) ||
          row.original.can_edit === false
        ) {
          return parseFloat(row.value);
        } else if (row.index === selectedCell.quantity) {
          return (
            <Input
              ref={el => (inputRef.current[row.index] = el)}
              style={{ width: "-webkit-fill-available" }}
              placeholder={row.value}
              onChange={e => {
                const { value } = e.target;
                handleSetPreviewData({
                  field: "quantity",
                  index: row.index,
                  value
                });
              }}
              onBlur={e => {
                setSelectedCell({});
              }}
              onKeyDown={e => {
                if (e.keyCode === CONSTANT.KEY_CODE.ENTER) {
                  if (
                    isNaN(previewData[row.index].quantity) ||
                    parseFloat(previewData[row.index].quantity) < 0
                  ) {
                    handleSetPreviewData({
                      field: "quantity",
                      index: row.index,
                      value: row.value
                    });
                    return setSelectedCell({});
                  }
                  handleChangeQuantity();
                } else if (e.keyCode === CONSTANT.KEY_CODE.ESCAPE) {
                  handleSetPreviewData({
                    field: "quantity",
                    index: row.index,
                    value: row.value
                  });
                  setSelectedCell({});
                }
              }}
            />
          );
        } else {
          return parseFloat(row.value);
        }
      }
    },
    {
      Header: "หน่วยสั่ง",
      accessor: "unit",
      width: 90
    },
    {
      Header: "ราคาต่อหน่วย",
      accessor: "price_unit",
      width: 100,
      Cell: row => formatPrice(row.value)
    },
    {
      Header: "ราคารวม",
      accessor: "price_total",
      width: 100,
      Cell: row => formatPrice(row.value)
    },
    {
      Header: "เบิกได้",
      accessor: "price_claimable",
      width: 100,
      Cell: row => formatPrice(row.value)
    },
    {
      Header: "วันที่ค่าใช้จ่าย",
      accessor: "charges_date",
      width: 100,
      getProps: (state, rowInfo) => {
        let className = "";
        if (rowInfo && rowInfo.index === selectedCell.charges_date) {
          className = "focus";
        }
        return {
          className: className,
          style: {
            backgroundColor: rowInfo ? "rgb(255, 255, 204)" : null,
            cursor: "pointer"
          }
        };
      },
      Cell: row => {
        return (
          <>
            <Popup
              flowing
              position="bottom center"
              ref={popupRef}
              hideOnScroll
              onClose={e => {
                setSelectedCell({});
              }}
              open={row.index === selectedCell.charges_date}
              trigger={<div>{row.value}</div>}
              content={
                <Form>
                  <Form.Group inline>
                    <Form.Field>วันที่</Form.Field>
                    <Form.Field>
                      <DateTextBox
                        value={row.value}
                        onChange={date => {
                          handleSetPreviewData({
                            field: "charges_date",
                            index: row.index,
                            value: date
                          });
                          setSelectedCell({});
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Button
                        color="red"
                        icon="trash"
                        onClick={() => {
                          handleSetPreviewData({
                            field: "charges_date",
                            index: row.index,
                            value: ""
                          });
                          setSelectedCell({});
                        }}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              }
            />
          </>
        );
      }
    },
    {
      Header: "วันที่และเวลาที่บันทึก",
      accessor: "edited_user_date",
      width: 150
    },
    {
      Header: "Eligibility Type",
      accessor: "eligibility_type",
      width: 150,
      getProps: (state, rowInfo, column) => {
        let className = "";
        if (rowInfo && rowInfo.index === selectedCell.eligibility_type) {
          className = "focus";
        }
        return {
          className: className,
          style: {
            cursor: "pointer",
            backgroundColor:
              rowInfo && !hospitalFee.includes(rowInfo.original.code)
                ? "rgb(255, 255, 204)"
                : null
          }
        };
      },
      Cell: row => {
        let index = eligibilityChoices.findIndex(
          item => item.id === row.original.eligibility_type
        );
        let text = row.original.eligibility_type
          ? row.original.eligibility_type
          : "";
        if (index > -1) {
          text = eligibilityChoices[index].text;
        }
        if (
          hospitalFee.includes(row.original.code) ||
          row.original.can_edit === false
        ) {
          return text;
        }
        return (
          <Popup
            flowing
            position="bottom center"
            open={row.index === selectedCell.eligibility_type}
            trigger={<div>{text}</div>}
            hideOnScroll
            onClose={e => {
              setSelectedCell({});
            }}
            content={
              <ComboBox
                options={eligibilityChoices}
                value={row.value}
                onValueChange={value =>
                  handleChangeEligibilityType({
                    value,
                    row: row.original,
                    index: row.index,
                    quantityChange: true
                  })
                }
              />
            }
          />
        );
      }
    },
    {
      Header: "เบิกไม่ได้",
      accessor: "price_non_claimable",
      width: 100,
      Cell: row => formatPrice(row.value)
    },
    {
      Header: "ลบ",
      accessor: "remove",
      minWidth: 50,
      className: "centerText",
      sortable: false,
      Cell: row => {
        return (
          <Button
            icon="minus"
            color="red"
            onClick={() => setConfirmModal(true)}
          />
        );
      }
    }
  ];

  const getMiscellaneous = async ({ searchText, limit, offset } = {}) => {
    if (props.controller) {
      const [data, error] = await props.controller.handleGetMiscellaneous({
        encounterId: props.encounterId,
        searchText,
        groupCode: "MISC",
        limit,
        offset
      });
      return [data, error];
    } else {
      const [data, error] = await props.onGetSearchOptions({
        searchText,
        groupCode: "MISC",
        limit,
        offset
      });
      return [data, error];
    }
  };

  const generatePageOptions = () => {
    let buttonList = [];
    for (let i = 0; i < numberOfPage; i++) {
      buttonList.push(
        <Button
          key={i}
          icon
          color={currentPage === i ? "blue" : null}
          content={i + 1}
          onClick={() => handleChangePage({ page: i })}
        />
      );
    }
    return buttonList;
  };

  const handleChangePage = ({ page }) => {
    setCurrentPage(page);
  };

  const getMiscellaneousOrder = async () => {
    setIsLoading(true);
    setError(null);
    let params = {
      encounterId: props.encounterId,
      active: true,
      orderBy: "-charges_date",
      limit: pageLimit,
      offset: currentPage * pageLimit
    };
    if (chkFilterDate) {
      params.fromDate = startDate;
      params.toDate = endDate;
    }
    var miscellaneousOrderData = null
    var miscellaneousOrderError = null
    if (props.controller) {
      [
        miscellaneousOrderData,
        miscellaneousOrderError
      ] = await props.controller.getMiscellaneousOrder({ ...params });
    } else {
      [
        miscellaneousOrderData,
        miscellaneousOrderError
      ] = await props.getMiscellaneousOrder({ ...params });
    }
    if (!isMounted.current) {
      return 
    }
    if (miscellaneousOrderError) {
      setError(miscellaneousOrderError);
    } else if (miscellaneousOrderData && miscellaneousOrderData.items) {
      setNumberOfPage(parseInt(miscellaneousOrderData.total / pageLimit) + 1);
      setPreviewData(miscellaneousOrderData.items);
    }
    setIsLoading(false);
    return [miscellaneousOrderData, miscellaneousOrderError];
  };

  const refresh = clearPage => {
    if (clearPage !== false) {
      setCurrentPage(0);
    }
    getMiscellaneousOrder();
  };

  const calculatePrice = () => {
    let priceTotal = 0;
    let priceClaimable = 0;
    let priceNonClaimable = 0;
    for (let item of previewData) {
      priceTotal += item.price_total;
      priceClaimable += item.price_claimable;
      priceNonClaimable += item.price_non_claimable;
    }
    setTotalPrice(priceTotal);
    setClaimablePrice(priceClaimable);
    setNonClaimablePrice(priceNonClaimable);
  };

  const handleChangeQuantity = () => {
    handlePreviewData({
      data: {
        items: previewData,
        quantityChange: true
      },
      newRow: false
    });
    setSelectedCell({});
  };

  const handleChangeEligibilityType = ({
    value,
    row,
    index,
    quantityChange
  }) => {
    let newArr = [...previewData];
    let data = { ...newArr[index] };
    data.eligibility_type = value;
    newArr[index] = data;
    handlePreviewData({
      data: {
        items: newArr,
        quantityChange
      },
      newRow: false
    });
    setSelectedCell({});
  };

  const handleSetPreviewData = async ({ field, index, value }) => {
    let newArr = previewData;
    let data = { ...newArr[index] };
    data[field] = value;
    newArr[index] = data;
    setPreviewData(newArr);
  };

  const getHospitalFee = async () => {
    let list =
      "core_HOSPITAL_FEE_REGULAR,core_HOSPITAL_FEE_SPECIAL,core_HOSPITAL_FEE_PREMIUM";
    const [hospitalFeeData, hospitalFeeError] = await props.getHospitalFee({
      list
    });
    if (isMounted.current) { 
      let newArr = [];
      if (hospitalFeeData && hospitalFeeData.result) {
        newArr = Object.values(hospitalFeeData.result);
      }
      setHospitalFee(newArr);
    }
  };

  const getEligibilityTypeChoices = async () => {
    var choicesData = null
    var choicesError = null
    if (props.controller) {
      [choicesData, choicesError] = await props.controller.getChoices({
        model: "MSD.SupplyOrderItem",
        field: "eligibility_type",
        nameAsId: true
      });
    } else {
      [choicesData, choicesError] = await props.getEligibilityTypeChoices({
        model: "MSD.SupplyOrderItem",
        field: "eligibility_type",
        nameAsId: true
      });
    }
    if (isMounted.current) {
      if (choicesData) {
        setEligibilityChoices(choicesData);
      }
    }
  };

  const handlePreviewData = async ({ data, newRow = false } = {}) => {
    setIsLoading(true);
    var orderPreviewData = null
    var orderPreviewError =null
    if (props.controller) {
      [
        orderPreviewData,
        orderPreviewError
      ] = await props.controller.postMiscellaneousOrderPreview({ encounterId:props.encounterId, data });
    } else {
      [
        orderPreviewData,
        orderPreviewError
      ] = await props.postMiscellaneousOrderPreview({ data });
    }

    if (isMounted.current) {
      if (orderPreviewError) {
        setError(orderPreviewError);
      } else if (orderPreviewData) {
        let tranformData = [...orderPreviewData];
        for (let item of tranformData) {
          if (!item.id) {
            item.id = "fakeId" + item.product + item.perform_datetime;
          }
        }
        if (newRow) {
          setPreviewData([...previewData, ...tranformData]);
        } else {
          setPreviewData(tranformData);
        }
      }
      setIsLoading(false);
      return [orderPreviewData, orderPreviewError];
    } else {
      return []
    }
  };

  const handleSelectItem = async ({
    item,
    id,
    quantityChange = false
  } = {}) => {
    let data = {};
    let items = item;
    if (!Array.isArray(item)) {
      items = [{ ...item }];
    }
    for (let eachItem of items) {
      eachItem.product = eachItem.id;
      eachItem.quantity = 1;
      eachItem.price_total = eachItem.price_unit;
      eachItem.charges_date = Util.formatDate(moment());
      eachItem.eligibility_type = "TREATMENT";
      eachItem.encounter =
        props.patientData && props.patientData.ENCOUNTER
          ? props.patientData.ENCOUNTER.encounter_id
          : null;
      delete eachItem["id"];
    }
    data.quantityChange = quantityChange;
    data.items = items;
    await handlePreviewData({ data, newRow: true });
  };

  const handleApproveCancel = async () => {
    let index = Object.values(selectedCell)[0];
    let newData = [...previewData];
    if (index || index === 0) {
      let indexId = previewData[index].id
        ? previewData[index].id.toString()
        : "";
      if (indexId.includes("fakeId")) {
        newData.splice(index, 1);
        setPreviewData(newData);
        return setConfirmModal(false);
      } else {
        setConfirmModal(false);
        const [
          deleteMiscellaneousData,
          deleteMiscellaneousError
        ] = await deleteMiscellaneousOrder({ id: previewData[index].id });
        if (isMounted.current) {
          if (deleteMiscellaneousData) {
            newData.splice(index, 1);
            setPreviewData(newData);
          }
        }
      }
    }
  };

  const deleteMiscellaneousOrder = async ({ id } = {}) => {
    setIsLoading(true);
    var deleteMiscellaneousData = null
    var deleteMiscellaneousError = null
    if (props.controller) {
      [
        deleteMiscellaneousData,
        deleteMiscellaneousError
      ] = await props.controller.deleteMiscellaneousOrder({ id });
    } else {
      [
        deleteMiscellaneousData,
        deleteMiscellaneousError
      ] = await props.deleteMiscellaneousOrder({ id });
    }
    
    if (isMounted.current) {
      if (deleteMiscellaneousError) {
        setAlertText(deleteMiscellaneousError[0]);
        setOpenModInfoError(true);
        // setError(deleteMiscellaneousError)
      }
      setIsLoading(false);
      return [deleteMiscellaneousData, deleteMiscellaneousError];
    } else {
      return []
    }
  };

  const handleSaveData = async () => {
    setIsLoading(true);
    let saveData = [...previewData];
    for (let item of saveData) {
      let itemId = item.id ? item.id.toString() : "";
      if (itemId.includes("fakeId")) {
        item.id = null;
      }

      // การบันทึกหรือแก้ไข item.charges_date_iso จะเป็น MM/DD/YYYY
      // if (item.charges_date_iso) {
      //   item.charges_date_iso = moment(item.charges_date_iso,"DD/MM/YYYY").format(
      //     "YYYY-MM-DD"
      //   );
      // }

    }
    var saveMiscellaneousData = null
    var saveMiscellaneousError = null
    if (props.controller) {
      [
        saveMiscellaneousData,
        saveMiscellaneousError
      ] = await props.controller.saveMiscellaneousOrder({
        saveData: {
          miscellaneous_orders: saveData
        }
      });
    } else {
      [
        saveMiscellaneousData,
        saveMiscellaneousError
      ] = await props.saveMiscellaneousOrder({
        saveData: {
          miscellaneous_orders: saveData
        }
      });
    }
    if (isMounted.current) {
      if (saveMiscellaneousError) {
        setAlertText(saveMiscellaneousError[0]);
        setOpenModInfoError(true);
      } else if (saveMiscellaneousData) {
        setOpenModInfoSuccess(true);
        refresh();
      }
      setIsLoading(false);
      return [saveMiscellaneousData, saveMiscellaneousError];
    } else {
      return []
    }
  };

  return (
    <Common.CardLayout
      headerColor="yellow"
      titleText="บันทึกรายการค่าใช้จ่ายเบ็ดเตล็ด"
      testid="crdOtherBill"
      loading={isLoading}
      onClose={props.onClose}
    >
      <ModInfoError
        titleColor="red"
        titleName="เกิดข้อผิดพลาด"
        btnText="ปิด"
        open={openModInfoError}
        alertText={alertText}
        onApprove={() => setOpenModInfoError(false)}
      />
      <ModInfoSuccess
        titleColor="green"
        titleName="บันทึกสำเร็จ"
        btnText="ตกลง"
        open={openModInfoSuccess}
        onApprove={() => setOpenModInfoSuccess(false)}
      />
      <Common.ModConfirm
        openModal={confirmModal}
        backgroundColor="#3946E6"
        titleName={<b>Confirm Dialog</b>}
        basic={false}
        approveButtonText="ยืนยัน"
        denyButtonText="ยกเลิก"
        onDeny={() => setConfirmModal(false)}
        onApprove={handleApproveCancel}
        content={<div>ต้องการลบรายการใช่หรือไม่</div>}
      />
      <Modal open={openModProduct} size="large">
        <BIL.CardProductSearch
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          hideCallback={() => setOpenModProduct(false)}
          onGetItem={getMiscellaneous}
          onSelectItems={handleSelectItem}
        />
      </Modal>
      <Form>
        <Common.ErrorMessage errors={error} />
        <Form.Group inline>
          <Form.Field width={15}>
            <Form.Field>
              <label>ชื่อรายการ</label>
            </Form.Field>
            <Form.Field width={15}>
              <SearchBox
                ref={searchBoxRef}
                testid="sbxMiscellaneous"
                inputClassName="greyDisabled"
                textField="name"
                onGetSearchOptions={getMiscellaneous}
                // onGetSearchOptions={({searchText} = {}) => props.onGetSearchOptions({searchText, groupCode: 'MISC'})}
                onSelectOption={handleSelectItem}
              />
            </Form.Field>
          </Form.Field>
          <Form.Field width={1}>
            <Button
              fluid
              color="blue"
              testid="btnSearchMiscellaneous"
              onClick={() => setOpenModProduct(true)}
            >
              ค้นหา
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
      <Divider />
      <Form>
        <Form.Group inline className="rowReverse">
          <Button icon="refresh" color="blue" onClick={getMiscellaneousOrder} />
          <Form.Field>
            <DateTextBox
              testid="dtbEnd"
              value={endDate}
              onChange={date => setEndDate(date)}
            />
          </Form.Field>
          <label>-</label>
          <Form.Field>
            <DateTextBox
              testid="dtbStart"
              value={startDate}
              onChange={date => setStartDate(date)}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              testid="chkDate"
              label="วันที่ค่าใช้จ่าย"
              checked={chkFilterDate}
              onChange={(e, { checked }) => setChkFilterDate(checked)}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <PureReactTable
        style={styles.reactTable}
        className=""
        selectedClassName="blueSelectedRow"
        showPagination={false}
        columns={columns}
        data={previewData}
        manual={false}
        pageSize={
          previewData ? (previewData.length < 8 ? 8 : previewData.length) : 8
        }
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, handleOriginal) => {
              if (props.readOnly) {
                return;
              }
              if (rowInfo && selectedCell[column.id] !== rowInfo.index) {
                setSelectedCell({ [column.id]: rowInfo.index });
              }
            }
          };
        }}
        onSelect={originalRow => setSelectedRow(originalRow)}
        selectedRow={selectedRow}
      />
      <br />
      <Form>
        <Form.Group inline>
          <Form.Field>
            <ComboBox
              testid="cboPageNum"
              selection
              options={[
                { text: 10, key: 10, value: 10 },
                { text: 20, key: 20, value: 20 },
                { text: 30, key: 30, value: 30 },
                { text: 40, key: 40, value: 40 },
                { text: 50, key: 50, value: 50 }
              ]}
              value={pageLimit}
              onChange={(e, { value }) => setPageLimit(value)}
            />
          </Form.Field>
          <Form.Field>{generatePageOptions()}</Form.Field>
        </Form.Group>
      </Form>
      <br />
      <Form>
        <Form.Group inline>
          <Form.Field width={1}>
            <RightLabel>รวม</RightLabel>
          </Form.Field>
          <Form.Field width={2}>
            <Input
              testid="txtTotalPrice"
              className="priceInput"
              readOnly
              value={formatComma(totalPrice)}
            />
          </Form.Field>
          <Form.Field width={1}>
            <RightLabel>เบิกได้</RightLabel>
          </Form.Field>
          <Form.Field width={2}>
            <Input
              testid="txtClaimablePrice"
              className="priceInput"
              readOnly
              value={formatComma(claimablePrice)}
            />
          </Form.Field>
          <Form.Field width={1}>
            <RightLabel>เบิกไม่ได้</RightLabel>
          </Form.Field>
          <Form.Field width={2}>
            <Input
              testid="txtNonClaimablePrice"
              className="priceInput"
              readOnly
              value={formatComma(nonClaimablePrice)}
            />
          </Form.Field>
          <Form.Field width={4} />
          <Form.Field width={3}>
            <Button
              testid="btnSave"
              fluid
              color="green"
              onClick={handleSaveData}
            >
              บันทึก
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    </Common.CardLayout>
  );
};

const styles = {
  reactTable: {
    height: "400px"
  }
};

CardOtherBill.defaultProps = {
  controller: null,
  onGetSearchOptions: () => [null, null],
  patientData: {},
  postMiscellaneousOrderPreview: () => [null, null],
  getEligibilityTypeChoices: () => [null, null],
  getHospitalFee: () => [null, null],
  encounterId: null,
  getMiscellaneousOrder: () => [null, null],
  deleteMiscellaneousOrder: () => [null, null],
  saveMiscellaneousOrder: () => [null, null],
  onClose: () => {},
  isFilterByDate: true
};

CardOtherBill.propTypes = {
  controller: PropTypes.object,
  onGetSearchOptions: PropTypes.func,
  patientData: PropTypes.object,
  postMiscellaneousOrderPreview: PropTypes.func,
  getEligibilityTypeChoices: PropTypes.func,
  getHospitalFee: PropTypes.func,
  encounterId: PropTypes.number,
  getMiscellaneousOrder: PropTypes.func,
  deleteMiscellaneousOrder: PropTypes.func,
  saveMiscellaneousOrder: PropTypes.func,
  onClose: PropTypes.func,
  isFilterByDate: PropTypes.bool
};

export default React.memo(CardOtherBill);
