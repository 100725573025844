import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown,
  Input,
  Button,
  Checkbox,
  Form,
  TextArea
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardIntraHospitalTransferFormUX = (props: any) => {
    return(
      <div
        style={{width: "100%", height: "100%",padding: "10px"}}>
        <div
          style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
          
          <div
            style={{padding: "0px 15px"}}>
            From
          </div>
          <Dropdown
            name="from_div"
            onChange={props.onChangeValue}
            options={props.divisionList}
            search={true}
            selection={true}
            value={props.data.from_div || ""}>
          </Dropdown>
          <div
            style={{padding: "0px 10px", display: "flex" }}>
            
            <div>
              To
            </div>
            <div
              style={{ color: "red" }}>
              *
            </div>
          </div>
          <Dropdown
            name="to_div"
            onChange={props.onChangeValue}
            options={props.divisionList}
            search={true}
            selection={true}
            value={props.data.to_div || ""}>
          </Dropdown>
        </div>
        <div
          style={{padding: "10px 10px 15px 10px"}}>
          
          <div
            style={{fontSize: "16px", fontWeight: "bold"}}>
            Situation
          </div>
          <hr>
          </hr>
          <div
            style={{display: "grid", gridTemplateColumns: "15% 85%", padding: "5px 0px"}}>
            
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Chief complain
            </div>
            <Input
              name="chief_complaint"
              onChange={props.onChangeValue}
              value={props.data.chief_complaint || ""}>
            </Input>
          </div>
          <div
            style={{display: "grid", gridTemplateColumns: "15%  35% 15% 35%",padding: "5px 0px"}}>
            
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Reason to Transfer
            </div>
            <Dropdown
              name="reason_to_transfer"
              onChange={props.onChangeValue}
              options={props.reasonToTransferChoicesOptions}
              selection={true}
              value={props.data.reason_to_transfer || ""}>
            </Dropdown>
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Mode of Transfer
            </div>
            <Dropdown
              name="mode_of_transfer"
              onChange={props.onChangeValue}
              options={props.modeOfTransferOptions}
              selection={true}
              value={props.data.mode_of_transfer || ""}>
            </Dropdown>
          </div>
        </div>
        <div
          style={{padding: "10px 10px 20px 10px"}}>
          
          <div
            style={{fontSize: "16px", fontWeight: "bold"}}>
            Backgound
          </div>
          <hr>
          </hr>
          <div
            style={{display: "grid", gridTemplateColumns: "15%  35% 15% 35%",padding: "5px 0px"}}>
            
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Isolation Precaution
            </div>
            <Dropdown
              multiple={true}
              name="isolation_precaution"
              onChange={props.onChangeValue}
              options={props.isolationPrecautionOptions}
              selection={true}
              value={props.data.isolation_precaution || []}>
            </Dropdown>
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
               Type of Transfer
            </div>
            <Dropdown
              name="type_of_transfer"
              onChange={props.onChangeValue}
              options={props.typeOfTransferOptions}
              selection={true}
              value={props.data.type_of_transfer || ""}>
            </Dropdown>
          </div>
          <div
            style={{display: "grid", gridTemplateColumns: "15%  15%  20%  30% 20% ",padding: "5px 0px"}}>
            
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Pretransfer Vital Signs
            </div>
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
               BP(mmHg)
            </div>
            <Input
              readOnly={true}
              value={props.data.vital_bp || ""}>
            </Input>
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Heart Rate(times/min)
            </div>
            <Input
              readOnly={true}
              value={props.data.vital_heart_rate || ""}>
            </Input>
          </div>
          <div
            style={{display: "grid", gridTemplateColumns: "15%  15%  20%  30% 20% ",padding: "5px 0px"}}>
            
            <Button
              color="yellow"
              onClick={props.onSelectVitalSign}>
              Select Vital Signs
            </Button>
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Body Temperature(oC)
            </div>
            <Input
              readOnly={true}
              value={props.data.vital_body_temp || ""}>
            </Input>
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Respiratory Rate(times/min)
            </div>
            <Input
              readOnly={true}
              value={props.data.vital_respiratory_rate || ""}>
            </Input>
          </div>
          <div
            style={{display: "grid", gridTemplateColumns: "15%  15%  20%  30% 20% ",padding: "5px 0px"}}>
            
            <div>
              
            </div>
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              O2Sat(%)
            </div>
            <Input
              readOnly={true}
              value={props.data.vital_o2_sat || ""}>
            </Input>
          </div>
          <div
            style={{display: "grid", gridTemplateColumns: "15%  35% 15% 35%",padding: "5px 0px"}}>
            
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Allergy
            </div>
            <Form>
              <TextArea
                disabled={true}
                rows={3}
                value={props.data.allergy || ""}>
              </TextArea>
            </Form>
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Level of conciousness
            </div>
            <Dropdown
              name="conciousness"
              onChange={props.onChangeValue}
              options={props.conciousnessLevelOptions}
              selection={true}
              style={{height: "fit-content"}}
              value={props.data.conciousness || ""}>
            </Dropdown>
          </div>
        </div>
        <div
          style={{padding: "10px 10px 20px 10px"}}>
          
          <div
            style={{fontSize: "16px", fontWeight: "bold"}}>
            Assessment
          </div>
          <hr>
          </hr>
          <div
            style={{display: "grid", gridTemplateColumns: "15%  15%  20%  30% 20% ",padding: "5px 0px"}}>
            
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Oxygen In Use
            </div>
            <Checkbox
              checked={props.data?.oxygen_in_use || false}
              label={props.data?.oxygen_in_use ? "Yes" : "No"}
              name="oxygen_in_use"
              onChange={props.onChangeValue}
              style={{padding: "10px"}}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            style={{display: "grid", gridTemplateColumns: "15%  10%  5% 10% 5% 10% 15% 5% 10% 5% 10%",padding: "5px 0px"}}>
            
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Bleeding Precaution
            </div>
            <Checkbox
              checked={props.data?.bleeding_precaution || false}
              label={props.data?.bleeding_precaution ? "Yes" : "No"}
              name="bleeding_precaution"
              onChange={props.onChangeValue}
              style={{padding: "10px"}}
              toggle={true}>
            </Checkbox>
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              INR
            </div>
            <Input
              disabled={!props.data?.bleeding_precaution }
              name="bleeding_precaution_inr"
              onChange={props.onChangeValue}
              value={props.data?.bleeding_precaution_inr || ""}>
            </Input>
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Plt
            </div>
            <Input
              disabled={!props.data?.bleeding_precaution }
              name="bleeding_precaution_plt"
              onChange={props.onChangeValue}
              value={props.data?.bleeding_precaution_plt || ""}>
            </Input>
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              x103/cumm
            </div>
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
               Hct(%)
            </div>
            <Input
              disabled={!props.data?.bleeding_precaution }
              name="bleeding_precaution_hct"
              onChange={props.onChangeValue}
              value={props.data?.bleeding_precaution_hct || ""}>
            </Input>
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Hb(g/dl)
            </div>
            <Input
              disabled={!props.data?.bleeding_precaution }
              name="bleeding_precaution_hb"
              onChange={props.onChangeValue}
              value={props.data?.bleeding_precaution_hb || ""}>
            </Input>
          </div>
          <div
            style={{display: "grid", gridTemplateColumns: "15%  10%  55%",padding: "5px 0px"}}>
            
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Protection
            </div>
            <Checkbox
              checked={props.data?.protection || false}
              label={props.data?.protection ? "Yes" : "No"}
              name="protection"
              onChange={props.onChangeValue}
              style={{padding: "10px"}}
              toggle={true}>
            </Checkbox>
            <Dropdown
              disabled={!props.data?.protection }
              multiple={true}
              name="protection_detail"
              onChange={props.onChangeValue}
              options={props.protectionOptions}
              selection={true}
              value={props.data.protection_detail || []}>
            </Dropdown>
          </div>
          <div
            style={{display: "grid", gridTemplateColumns: "15%  15%  20%  30% 20% ",padding: "5px 0px"}}>
            
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Fall risk
            </div>
            <Checkbox
              checked={props.data?.fall_risk || false}
              label={props.data?.fall_risk ? "Yes" : "No"}
              name="fall_risk"
              onChange={props.onChangeValue}
              style={{padding: "10px"}}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            style={{display: "grid", gridTemplateColumns: "15%  10%  10%  20% 10% 10% 25%",padding: "5px 0px"}}>
            
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Pain
            </div>
            <Checkbox
              checked={props.data?.pain || false}
              label={props.data?.pain ? "Yes" : "No"}
              name="pain"
              onChange={props.onChangeValue}
              style={{padding: "10px"}}
              toggle={true}>
            </Checkbox>
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Pain score
            </div>
            <Input
              disabled={!props.data?.pain}
              name="pain_score"
              onChange={props.onChangeValue}
              value={props.data.pain_score || ""}>
            </Input>
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Intervention
            </div>
            <Checkbox
              checked={props.data?.intervention || false}
              label={props.data?.intervention ? "Yes" : "NO"}
              name="intervention"
              onChange={props.onChangeValue}
              style={{padding: "10px"}}
              toggle={true}>
            </Checkbox>
            <Input
              disabled={!props.data?.intervention}
              name="intervention_detail"
              onChange={props.onChangeValue}
              value={props.data.intervention_detail || ""}>
            </Input>
          </div>
          <div
            style={{display: "grid", gridTemplateColumns: "25% 25%",padding: "5px 0px"}}>
            
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Special Care
            </div>
            <Dropdown
              multiple={true}
              name="special_care"
              onChange={props.onChangeValue}
              options={props.specialCareOptions}
              selection={true}
              value={props.data.special_care || []}>
            </Dropdown>
          </div>
          <div
            style={{display: "grid", gridTemplateColumns: "25% 25%",padding: "5px 0px"}}>
            
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Medication
            </div>
            <Input
              name="medication"
              onChange={props.onChangeValue}
              value={props.data.medication || ""}>
            </Input>
          </div>
          <div
            style={{display: "grid", gridTemplateColumns: "25% 25% 25% 25%",padding: "5px 0px"}}>
            
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Special equipment come with patient
            </div>
            <Input
              name="special_equipment_come_with_patient"
              onChange={props.onChangeValue}
              value={props.data.special_equipment_come_with_patient || ""}>
            </Input>
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Special equipment to be prepared
            </div>
            <Input
              name="special_equipment_to_be_prepared"
              onChange={props.onChangeValue}
              value={props.data.special_equipment_to_be_prepared || ""}>
            </Input>
          </div>
          <div
            style={{display: "grid", gridTemplateColumns: "25% 25% 25% 25%",padding: "5px 0px"}}>
            
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              valuable
            </div>
            <Input
              name="valuable"
              onChange={props.onChangeValue}
              value={props.data.valuable || ""}>
            </Input>
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Other
            </div>
            <Input
              name="other"
              onChange={props.onChangeValue}
              value={props.data.other || ""}>
            </Input>
          </div>
        </div>
        <div
          style={{padding: "10px 10px 20px 10px"}}>
          
          <div
            style={{fontSize: "16px", fontWeight: "bold"}}>
            Recommendations
          </div>
          <hr>
          </hr>
          <div
            style={{display: "grid", gridTemplateColumns: "25%  15%  30%",padding: "5px 0px"}}>
            
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Post-transfer Assessment(At arrival)
            </div>
            <Checkbox
              checked={props.data.post_transfer_assessment || false}
              label={props.data?.post_transfer_assessment ? "Change Notify" : "No change"}
              name="post_transfer_assessment"
              onChange={props.onChangeValue}
              style={{padding: "10px"}}
              toggle={true}>
            </Checkbox>
            <Input
              disabled={!props.data.post_transfer_assessment }
              name="post_transfer_assessment_detail"
              onChange={props.onChangeValue}
              value={props.data.post_transfer_assessment_detail || ""}>
            </Input>
          </div>
          <div
            style={{display: "grid", gridTemplateColumns: "25%  30%  20% 20%",padding: "5px 0px"}}>
            
            <div
              style={{padding: "10px", fontWeight: "bold", display: "flex" }}>
              
              <div>
                Transferring Personnel
              </div>
              <div
                style={{ color: "red" }}>
                *
              </div>
            </div>
            <div>
              {props.transferringEmployee}
            </div>
            <div>
              {props.transferringTimeBox}
            </div>
            <DateTextBox
              onChange={(date: string)=> props.onChangeValue(null, {value: date, name: "transferring_personnel_date"})}
              value={props.data.transferring_personnel_date || ""}>
            </DateTextBox>
          </div>
          <div
            style={{display: "grid", gridTemplateColumns: "25%  30%  20% 20%",padding: "5px 0px"}}>
            
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              Receiving Personnel
            </div>
            <div>
              {props.receivingEmployee}
            </div>
            <div>
              {props.receivingTimeBox}
            </div>
            <DateTextBox
              onChange={(date: string)=> props.onChangeValue(null, {value: date, name: "receiving_personnel_date"})}
              value={props.data.receiving_personnel_date || ""}>
            </DateTextBox>
          </div>
          <div
            style={{display: "grid", gridTemplateColumns: "25%  30%  20% 20%",padding: "5px 0px"}}>
            
            <div
              style={{padding: "10px", fontWeight: "bold"}}>
              แพทย์เจ้าของไข้
            </div>
            <div>
              {props.patientDoctor}
            </div>
          </div>
        </div>
        <div
          style={{padding: "10px", display: "flex", justifyContent: "flex-end"}}>
          
          <div>
            {props.actionButton}
          </div>
          <Button
            color="blue"
            disabled={props.disablePrint}
            onClick={props.onPrint}
            style={{width: "200px"}}>
            พิมพ์
          </Button>
        </div>
      </div>
    )
}


export default CardIntraHospitalTransferFormUX

export const screenPropsDefault = {}

/* Date Time : Thu Jul 13 2023 05:36:16 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\",padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 15px 10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 20px 10px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 20px 10px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 20px 10px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "From"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 15px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 10px\", display: \"flex\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Dropdown",
      "parent": 1,
      "props": {
        "name": {
          "type": "value",
          "value": "from_div"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.divisionList"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.from_div || \"\""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Dropdown",
      "parent": 1,
      "props": {
        "name": {
          "type": "value",
          "value": "to_div"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.divisionList"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.to_div || \"\""
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Situation"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"16px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"15% 85%\", padding: \"5px 0px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"15%  35% 15% 35%\",padding: \"5px 0px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "hr",
      "parent": 2,
      "props": {
      },
      "seq": 11,
      "void": true
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "Chief complain"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Input",
      "parent": 11,
      "props": {
        "name": {
          "type": "value",
          "value": "chief_complaint"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data.chief_complaint || \"\""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "Reason to Transfer"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "Mode of Transfer"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Dropdown",
      "parent": 12,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "reason_to_transfer"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.reasonToTransferChoicesOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.reason_to_transfer || \"\""
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Dropdown",
      "parent": 12,
      "props": {
        "name": {
          "type": "value",
          "value": "mode_of_transfer"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.modeOfTransferOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.mode_of_transfer || \"\""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Backgound"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"16px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"15%  35% 15% 35%\",padding: \"5px 0px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"15%  15%  20%  30% 20% \",padding: \"5px 0px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"15%  15%  20%  30% 20% \",padding: \"5px 0px\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"15%  15%  20%  30% 20% \",padding: \"5px 0px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"15%  35% 15% 35%\",padding: \"5px 0px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "hr",
      "parent": 3,
      "props": {
      },
      "seq": 21,
      "void": true
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "Isolation Precaution"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": " Type of Transfer"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Dropdown",
      "parent": 21,
      "props": {
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "isolation_precaution"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.isolationPrecautionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.isolation_precaution || []"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Dropdown",
      "parent": 21,
      "props": {
        "name": {
          "type": "value",
          "value": "type_of_transfer"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.typeOfTransferOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.type_of_transfer || \"\""
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "Pretransfer Vital Signs"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": " BP(mmHg)"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "Heart Rate(times/min)"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Input",
      "parent": 22,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "readonly": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.data.vital_bp || \"\""
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Input",
      "parent": 22,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.vital_heart_rate || \"\""
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Button",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "Select Vital Signs"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectVitalSign"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "Body Temperature(oC)"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "Respiratory Rate(times/min)"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Input",
      "parent": 23,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.vital_body_temp || \"\""
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Input",
      "parent": 23,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.vital_respiratory_rate || \"\""
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "O2Sat(%)"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Input",
      "parent": 24,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.vital_o2_sat || \"\""
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "Allergy"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "Level of conciousness"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Dropdown",
      "parent": 25,
      "props": {
        "name": {
          "type": "value",
          "value": "conciousness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.conciousnessLevelOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height: \"fit-content\"}"
        },
        "value": {
          "type": "code",
          "value": "props.data.conciousness || \"\""
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "Assessment"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"16px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"15%  15%  20%  30% 20% \",padding: \"5px 0px\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"25% 25%\",padding: \"5px 0px\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"25% 25%\",padding: \"5px 0px\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"25% 25% 25% 25%\",padding: \"5px 0px\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"25% 25% 25% 25%\",padding: \"5px 0px\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "hr",
      "parent": 4,
      "props": {
      },
      "seq": 50,
      "void": true
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": "Oxygen In Use"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Checkbox",
      "parent": 50,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.oxygen_in_use || false"
        },
        "label": {
          "type": "code",
          "value": "props.data?.oxygen_in_use ? \"Yes\" : \"No\""
        },
        "name": {
          "type": "value",
          "value": "oxygen_in_use"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"15%  10%  5% 10% 5% 10% 15% 5% 10% 5% 10%\",padding: \"5px 0px\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": "Bleeding Precaution"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Checkbox",
      "parent": 62,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.bleeding_precaution || false"
        },
        "label": {
          "type": "code",
          "value": "props.data?.bleeding_precaution ? \"Yes\" : \"No\""
        },
        "name": {
          "type": "value",
          "value": "bleeding_precaution"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"15%  10%  55%\",padding: \"5px 0px\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "Protection"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "Checkbox",
      "parent": 65,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.protection || false"
        },
        "label": {
          "type": "code",
          "value": "props.data?.protection ? \"Yes\" : \"No\""
        },
        "name": {
          "type": "value",
          "value": "protection"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"15%  15%  20%  30% 20% \",padding: \"5px 0px\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": "Fall risk"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Checkbox",
      "parent": 68,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.fall_risk || false"
        },
        "label": {
          "type": "code",
          "value": "props.data?.fall_risk ? \"Yes\" : \"No\""
        },
        "name": {
          "type": "value",
          "value": "fall_risk"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"15%  10%  10%  20% 10% 10% 25%\",padding: \"5px 0px\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "Pain"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Checkbox",
      "parent": 71,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.pain || false"
        },
        "label": {
          "type": "code",
          "value": "props.data?.pain ? \"Yes\" : \"No\""
        },
        "name": {
          "type": "value",
          "value": "pain"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": "INR"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": "Plt"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": "x103/cumm"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": " Hct(%)"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": "Hb(g/dl)"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Input",
      "parent": 62,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.data?.bleeding_precaution "
        },
        "name": {
          "type": "value",
          "value": "bleeding_precaution_inr"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data?.bleeding_precaution_inr || \"\""
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Input",
      "parent": 62,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.data?.bleeding_precaution "
        },
        "name": {
          "type": "value",
          "value": "bleeding_precaution_plt"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data?.bleeding_precaution_plt || \"\""
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Input",
      "parent": 62,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.data?.bleeding_precaution "
        },
        "name": {
          "type": "value",
          "value": "bleeding_precaution_hct"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data?.bleeding_precaution_hct || \"\""
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Input",
      "parent": 62,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.data?.bleeding_precaution "
        },
        "name": {
          "type": "value",
          "value": "bleeding_precaution_hb"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data?.bleeding_precaution_hb || \"\""
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Dropdown",
      "parent": 65,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.data?.protection "
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "protection_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.protectionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.protection_detail || []"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "Pain score"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "Intervention"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Checkbox",
      "parent": 71,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.intervention || false"
        },
        "label": {
          "type": "code",
          "value": "props.data?.intervention ? \"Yes\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "intervention"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "Input",
      "parent": 71,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.data?.pain"
        },
        "name": {
          "type": "value",
          "value": "pain_score"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data.pain_score || \"\""
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Input",
      "parent": 71,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.data?.intervention"
        },
        "name": {
          "type": "value",
          "value": "intervention_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data.intervention_detail || \"\""
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "Special Care"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": "Medication"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "Special equipment come with patient"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "valuable"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "Dropdown",
      "parent": 55,
      "props": {
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "special_care"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.specialCareOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.special_care || []"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Input",
      "parent": 56,
      "props": {
        "name": {
          "type": "value",
          "value": "medication"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data.medication || \"\""
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "Input",
      "parent": 57,
      "props": {
        "name": {
          "type": "value",
          "value": "special_equipment_come_with_patient"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data.special_equipment_come_with_patient || \"\""
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Input",
      "parent": 57,
      "props": {
        "name": {
          "type": "value",
          "value": "special_equipment_to_be_prepared"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data.special_equipment_to_be_prepared || \"\""
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "Input",
      "parent": 58,
      "props": {
        "name": {
          "type": "value",
          "value": "valuable"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data.valuable || \"\""
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Input",
      "parent": 58,
      "props": {
        "name": {
          "type": "value",
          "value": "other"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data.other || \"\""
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "Special equipment to be prepared"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "Other"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "Recommendations"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"16px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"25%  15%  30%\",padding: \"5px 0px\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "Post-transfer Assessment(At arrival)"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "Checkbox",
      "parent": 106,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data.post_transfer_assessment || false"
        },
        "label": {
          "type": "code",
          "value": "props.data?.post_transfer_assessment ? \"Change Notify\" : \"No change\""
        },
        "name": {
          "type": "value",
          "value": "post_transfer_assessment"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"25%  30%  20% 20%\",padding: \"5px 0px\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\", display: \"flex\" }"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"25%  30%  20% 20%\",padding: \"5px 0px\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "div",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": "Receiving Personnel"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"25%  30%  20% 20%\",padding: \"5px 0px\"}"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์เจ้าของไข้"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "hr",
      "parent": 5,
      "props": {
      },
      "seq": 105,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "Input",
      "parent": 106,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.data.post_transfer_assessment "
        },
        "name": {
          "type": "value",
          "value": "post_transfer_assessment_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data.post_transfer_assessment_detail || \"\""
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "code",
          "value": "props.transferringEmployee"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 112,
      "props": {
        "children": {
          "type": "code",
          "value": "props.receivingEmployee"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientDoctor"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 112,
      "props": {
        "children": {
          "type": "code",
          "value": "props.receivingTimeBox"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "code",
          "value": "props.transferringTimeBox"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 128,
      "name": "DateTextBox",
      "parent": 109,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date: string)=> props.onChangeValue(null, {value: date, name: \"transferring_personnel_date\"})"
        },
        "value": {
          "type": "code",
          "value": "props.data.transferring_personnel_date || \"\""
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 129,
      "name": "DateTextBox",
      "parent": 112,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date: string)=> props.onChangeValue(null, {value: date, name: \"receiving_personnel_date\"})"
        },
        "value": {
          "type": "code",
          "value": "props.data.receiving_personnel_date || \"\""
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 130,
      "props": {
        "children": {
          "type": "code",
          "value": "props.actionButton"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "Button",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disablePrint"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrint"
        },
        "style": {
          "type": "code",
          "value": "{width: \"200px\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "Form",
      "parent": 25,
      "props": {
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "TextArea",
      "parent": 133,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "rows": {
          "type": "code",
          "value": "3"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.data.allergy || \"\""
        }
      },
      "seq": 135,
      "void": true
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 110,
      "props": {
        "children": {
          "type": "value",
          "value": "Transferring Personnel"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 110,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "To"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 139,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardIntraHospitalTransferFormUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
