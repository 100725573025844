import React from "react";
import { Modal, Grid, Button, TextArea, Form, Segment, Input } from "semantic-ui-react";

// const SnackMessage = React.lazy(()=> import('../../../../HIS/common/SnackMessage').catch(()=> ({default: ()=> null })))

import ErrorMessage from "react-lib/apps/common/ErrorMessage";

const styles = {
  header: {
    borderRadius: 0,
    fontWeight: "bold",
  },
  content: {
    lineHeight: "1.5em",
  },
};

type ModConfirmWithNoteProps = {
  // Loading
  loading?: any;
  // Modal
  modalClassName?: any;
  modalOpen?: any;
  modalSize?: any;
  modalId?: any;
  onCloseWithDimmerClick?: any;

  titleColor?: any;
  backgroundColor?: any;
  // Segment 1
  titleName?: any;
  // Segment 2
  textConTent?: any;
  content?: any;
  // TextArea
  textNote?: any;
  setTextNote?: any;
  showTextNote?: any;
  // ErrorMessage,
  errorMessageText?: any;

  // Authorize
  authorizeComponent?: any;

  // Approve Button
  approveButtonText?: any;
  approveButtonColor?: any;
  onApprove?: any;
  // Deny Button
  denyButtonText?: any;
  denyButtonColor?: any;
  onDeny?: any;
  // Button
  buttonBasic?: any;
  disabledApproveButton?: boolean;
  disabledDenyButton?: boolean;

};

const ModConfirmWithNote = (props: ModConfirmWithNoteProps) => {

  let headerColor = props.titleColor;
  if (!props.titleColor && !props.backgroundColor) {
    headerColor = "blue";
  }

  return (
    <Modal
      open={props.modalOpen}
      size={props.modalSize}
      id={"modConfirm" + props.modalId}
      closeOnDimmerClick={true}
      onClose={props.onCloseWithDimmerClick}
      className={props.modalClassName}
    >
      <Segment className={"inverted " + headerColor} style={{ ...styles.header, backgroundColor: props.backgroundColor }}>
        {props.titleName}
      </Segment>
      <Segment basic loading={props.loading} style={styles.content}>
        {props.textConTent}
        {props.content}
        <br /> <br />
        {props.showTextNote && (
          <Form style={{ marginBottom: "20px" }}>
            {" "}
            <div>
              {" "}
              <TextArea onChange={props.setTextNote} value={props.textNote} style={{ maxHeight: "100px", minHeight: "100px" }} />{" "}
            </div>{" "}
          </Form>
        )}
        {props.errorMessageText && <ErrorMessage error={props.errorMessageText} />}
        {props.authorizeComponent}
        <div style={{ display: "flex", justifyContent: "space-evenly", margin: "10px" }}>
          {props.approveButtonText && (
            <Button basic={props.buttonBasic} disabled={props.disabledApproveButton} style={{minWidth: "120px"}} color={props.approveButtonColor} onClick={props.onApprove}>
              {props.approveButtonText}
            </Button>
          )}

          {props.denyButtonText && (
            <Button basic={props.buttonBasic} disabled={props.disabledDenyButton} style={{minWidth: "120px"}} color={props.denyButtonColor} onClick={props.onDeny}>
              {props.denyButtonText}
            </Button>
          )}
        </div>
      </Segment>
    </Modal>
  );
};

export default ModConfirmWithNote;
