import WasmController from "react-lib/frameworks/WasmController";

// APIs
// DEN
import DentalClinicalFindingList from "issara-sdk/apis/DentalClinicalFindingList_apps_DEN";
// IME
import ImagingOrderEstimate from "issara-sdk/apis/ImagingOrderEstimate_apps_IME";
import ImagingOrderList from "issara-sdk/apis/ImagingOrderlist_apps_IME";
import ImagingOrderDetail from "issara-sdk/apis/ImagingOrderDetail_apps_IME";
// CORE

// Serializer
import ImagingOrderSerializerI from "issara-sdk/types/ImagingOrderSerializer_apps_IME";
import ImagingTestSerializer from "issara-sdk/types/ImagingTestSerializer_apps_IME";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import {
  GetIcd10KeyUp,
  HandleSearchDiagnosis,
} from "../../ADM/sequence/ReportPatientList";

import axios from "axios";
import {
  ImagingQueueSerializer,
  ORDER_ITEM_STATUS_LABEL,
  OrderItemStatusType,
  OrderStatusType,
  UpdateOrderItemAction,
} from "./ImagingWorkList";

// Utils
import { formatedLocaltionsName } from "react-lib/apps/common/CUDENT/DentalRecordInterface";
import {
  getLocationsSet,
  tidyLocation2,
} from "react-lib/apps/common/CUDENT/DentalRecordUtil";

export type State = Partial<{
  // Dental disgram
  clinicalFindingId: number | null;
  clinicalFindingIndex: number | null;
  clinicalFindingList: Record<string, any>[];
  filterClinicalFindingIds: number[];
  // -----#

  // CommonInterface
  modXrayDetail: ModXrayDetailType | null;
  imagingOrderEditData?: ImagingQueueSerializer | null;

  // sequence
  ImagingOrderSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    requestOrderList: ImagingOrderSerializer[];
    imagingItemList: ImagingItemSerializer[];
    imagingItemDetail: Partial<ImagingOrderSerializer> | null;
  }> | null;
}>;

type Picked = Partial<
  Pick<
    MainState,
    | "buttonLoadCheck"
    | "errorMessage"
    | "django"
    | "searchedItemListWithKey"
    | "selectedEncounter"
    | "userTokenize"
  >
>;

export type ImagingOrderSerializer = {
  status: OrderStatusType;
  order_status: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  order_status_label: "Pending" | "In Progress" | "Complete";
  order_items: ImagingItemSerializer[];
  extra: {
    out_time: boolean;
    secret: boolean;
    repeat_medication: boolean;
    lawsuit: boolean;
  };
  suspected_diagnosis_code: string;
} & Omit<
  ImagingOrderSerializerI,
  "order_status" | "status" | "extra" | "order_items"
>;

export type ImagingItemSerializer = {
  price_non_claimable: number;
  price_claimable: number;
  quantity?: string | number;
  eligibility_type?: number;
  urgency?: "STAT" | "ROUTINE";
  locations?: number[];
  locations_name?: string;
  product?: number;
  active?: boolean;
  status: OrderItemStatusType;
  ready_to_view: boolean;
  product_name: string;
  editing?: boolean;
  name_code: string;
} & Pick<ImagingTestSerializer, "id" | "price_unit" | "unit" | "name"> &
  Pick<ImagingQueueSerializer, "group_name" | "note" | "group_type">;

export type ModXrayDetailType = {
  order?: number;
  index?: number;
  status?: OrderStatusType;
} & Pick<
  ImagingItemSerializer,
  | "urgency"
  | "group_name"
  | "note"
  | "eligibility_type"
  | "name_code"
  | "id"
  | "locations"
  | "locations_name"
  | "ready_to_view"
  | "group_type"
>;

export type MasterOptionsType = Record<
  (typeof Masters)[number][0],
  OptionType[]
>;

type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
};

// Sequence
type SeqState = {
  sequence: "ImagingOrder";
  restart?: boolean;
  clear?: boolean;
  orderId?: number | null;
};

// Common Params
type LoadCheck = {
  card: string;
  errorKey?: string;
  btnAction?: string;
  action?: string;
};

// Handle Action
type ActionType =
  // Search
  | { action: "SEARCH" }
  // Action
  | { action: "ADD_ITEM"; itemId: number }
  | { action: "SEARCH_DIAGNOSIS"; data: Record<string, any> }
  | {
      action: "MOD_XRAY";
      data?: ModXrayDetailType;
      type: "OPEN" | "CLOSE";
    }
  | { action: "EDIT_REQUEST"; data: ImagingOrderSerializer }
  // Method
  | {
      action: "CANCEL_ORDER";
      card: string;
      data: ImagingOrderSerializer;
      reason: string | string[];
      onSuccess?: Function;
    }
  | { action: "CONFIRM_ORDER"; card: string }
  | {
      action: "EDIT_ITEM";
      data: Partial<ImagingItemSerializer>;
      index: number;
      onSuccess?: Function;
    };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(
  params: SeqType<Pick<SeqAct, K>>
) => any;

type CustomExtract<T, U> = T extends T
  ? U extends Partial<T>
    ? T
    : never
  : never;

type Params<A extends ActionType["action"]> = CustomExtract<
  ActionType,
  { action: A }
>;

export const StateInitial: State = {
  // sequence
  ImagingOrderSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
  organ?: { name: string; items: any[] };
  clinicaltags?: { name: string; items: any[] };
};

export const DataInitial = {};

const Masters = [
  ["eligibilityType", {}],
  ["cancelImagingOrder", {}],
  ["divisionForOrderDiv", {}],
  ["unit", {}],
] as const;

export const XRAY_SEARCH_ID = "ImagingXray_IO";

export const DIAGNOSIS_SEARCH_ID = "Diagnosis_IO";

type Handler<P = any, R = any> = (
  controller: WasmController<State & Picked, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller) => {
  let state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  } as any);

  GetClinicalFindingList(controller, {});

  GetOrgan(controller, {});

  state = controller.getState();

  controller.setState(
    {
      ImagingOrderSequence: {
        ...state.ImagingOrderSequence,
        sequenceIndex: "Action",
        imagingItemList: [],
      },
    },
    () => Action(controller, { action: "SEARCH" })
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  if (params.action === "SEARCH") {
    HandleSearch(controller, params);
  } else if (params.action === "ADD_ITEM") {
    HandleAddImagingItem(controller, params);
  } else if (params.action === "MOD_XRAY") {
    HandleModXrayDetail(controller, params);
  } else if (params.action === "CANCEL_ORDER") {
    HandleDeleteImagingOrder(controller, params);
  } else if (params.action === "SEARCH_DIAGNOSIS") {
    HandleSearchDiagnosis(controller, params);
  } else if (params.action === "CONFIRM_ORDER") {
    HandleConfirmOrder(controller, params);
  } else if (params.action === "EDIT_ITEM") {
    HandleEditImagingItem(controller, params);
  } else if (params.action === "EDIT_REQUEST") {
    HandleEditImagingRequest(controller, params);
  }
};

const HandleSearch: Handler<Params<"SEARCH">> = async (controller) => {
  let state = controller.getState();

  const [orderList] = await ImagingOrderList.list({
    apiToken: controller.apiToken,
    params: { encounter: state.selectedEncounter?.id },
  });

  state = controller.getState();

  let items: ImagingOrderSerializer[] = orderList?.items || [];

  items = items.map((item) => ({
    ...item,
    order_status_label: item.order_items.every((acc) => acc.status === 8)
      ? "Complete"
      : item.order_items.every((acc) => acc.status !== 1)
      ? "In Progress"
      : "Pending",
  }));

  // เมื่อกด popup ปุ่ม imaging order จากหน้า imaging worklist
  if (!!state.imagingOrderEditData) {
    const data = items.find(
      (item) => item.id === state.imagingOrderEditData?.order
    );

    if (!!data) {
      Action(controller, {
        action: "EDIT_REQUEST",
        data,
      });
    }
  }

  controller.setState({
    ImagingOrderSequence: {
      ...state.ImagingOrderSequence,
      requestOrderList: items,
    },
    imagingOrderEditData: null,
  });
};

const HandleAddImagingItem: Handler<Params<"ADD_ITEM">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const imagingOrder = state.ImagingOrderSequence || {};

  const item = state.searchedItemListWithKey?.[XRAY_SEARCH_ID].find(
    (item: any) => item.id === params.itemId
  );

  if (!!item) {
    const list = [...(imagingOrder.imagingItemList || [])];

    item.product = item.id;
    item.eligibility_type = 1; // detailt eligibility_type = 1 เพื่อการรักษา
    item.urgency = "ROUTINE"; // detailt urgency = ROUTINE

    delete item.id;

    const estimate = await GetImagingOrderEstimate(controller, {
      encounter: state.selectedEncounter?.id,
      items: [item],
    });

    const index = list.findIndex((item) => item.product === params.itemId);
    const data = list[index];

    const readOnly =
      !!data?.status && ORDER_ITEM_STATUS_LABEL[data.status] !== "REQUESTED";

    if (readOnly) {
      controller.setState({
        searchedItemListWithKey: {
          ...state.searchedItemListWithKey,
          [XRAY_SEARCH_ID]: [],
        },
      });
      return;
    }

    // หาก item มีอยู่แล้วให้เพิ่ม qty
    if (index >= 0) {
      const qty = Number(data.quantity) + 1;

      // หากเป็น item ที่บันทึกเรียบร้อยแล้วกลับมาแก้ไข
      if (data.id) {
        controller.setState({
          searchedItemListWithKey: {
            ...state.searchedItemListWithKey,
            [XRAY_SEARCH_ID]: [],
          },
        });

        return HandleEditImagingItem(controller, {
          action: "EDIT_ITEM",
          data: { quantity: qty },
          index,
        });
      } else {
        list[index].quantity = qty;
      }
    } else {
      list.push({
        ...item,
        quantity: "1",
        active: true,
        ...(estimate.orders?.[0] || {}),
      });
    }

    controller.setState({
      searchedItemListWithKey: {
        ...state.searchedItemListWithKey,
        [XRAY_SEARCH_ID]: [],
      },
      ImagingOrderSequence: {
        ...state.ImagingOrderSequence,
        imagingItemList: list,
      },
    });
  }
};

const HandleEditImagingItem: Handler<Params<"EDIT_ITEM">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const index = params.index;
  const list = [...(state.ImagingOrderSequence?.imagingItemList || [])];
  const item: any = {
    ...list[index],
  };

  for (const key in params.data) {
    let value = (params.data as any)[key];

    if (key === "quantity") {
      // type number value ไม่น้อยกว่า 1
      value = Number(value) < 1 ? 1 : value;
    }

    if (key === "locations_name" && !!state.clinicalFindingId) {
      const detail = formatedLocaltionsName(controller as any, {
        index: state.clinicalFindingIndex || 0,
        id: state.clinicalFindingId,
      });

      item[key] = detail.data.locations_name;
    } else {
      item[key] = value;
    }
  }

  item.editing = true;

  const estimate = await GetImagingOrderEstimate(controller, {
    encounter: state.selectedEncounter?.id,
    items: [item],
  });

  list[index] = { ...item, ...(estimate.orders?.[0] || {}) };

  controller.setState({
    ImagingOrderSequence: {
      ...state.ImagingOrderSequence,
      imagingItemList: [...list],
    },
  });

  params.onSuccess?.();
};

export const HandleModXrayDetail: Handler<Params<"MOD_XRAY">> = (
  controller,
  params
) => {
  const data = params.data;

  const organ = controller.data.organ;

  if (params.type === "OPEN" && !!data) {
    const id = data.id || "MOCK_ID";

    const locationsSet = getLocationsSet(
      data.locations_name || "",
      organ?.items || []
    );

    const locationsName = tidyLocation2(locationsSet, organ?.items || []).join(
      ","
    );

    controller.setState({
      clinicalFindingId: id,
      clinicalFindingIndex: 0,
      clinicalFindingList: [
        {
          id: id,
          location: "-",
          locations_name: locationsName,
        },
      ],
      filterClinicalFindingIds: [id],
      modXrayDetail: data,
    });
  } else if (params.type === "CLOSE") {
    controller.setState({
      clinicalFindingId: null,
      clinicalFindingIndex: null,
      clinicalFindingList: [],
      filterClinicalFindingIds: [],
      modXrayDetail: null,
    });
  }
};

const HandleDeleteImagingOrder: Handler<Params<"CANCEL_ORDER">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const result = await ImagingOrderDetail.update({
    apiToken: controller.apiToken,
    pk: params.data.id,
    data: {
      ...params.data,
      action: "CANCEL",
    } as any,
    extra: {
      division: controller.data.division,
      device: controller.data?.device,
    },
  });

  if (result[1]) {
    SetErrorMessage(controller, { ...params, error: result[1] });
  } else {
    await controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: null,
      },
    });

    Action(controller, { action: "SEARCH" });

    params.onSuccess?.();
  }
};

const HandleConfirmOrder: Handler<Params<"CONFIRM_ORDER">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const imaging = state.ImagingOrderSequence;
  const list = imaging?.imagingItemList || [];
  const filterList = list.filter((item) => !!item.active);
  const detail = imaging?.imagingItemDetail || {};

  const api = !!detail.id ? ImagingOrderDetail.patch : ImagingOrderList.create;

  const isSomeRequestItem = list.some(
    (item) =>
      !item.status || ORDER_ITEM_STATUS_LABEL[item.status] === "REQUESTED"
  );
  const readyToViewItems = list.filter(
    (item) =>
      item.editing &&
      ["REGISTERED", "EXECUTED"].includes(ORDER_ITEM_STATUS_LABEL[item.status])
  );

  let result: any[] = [null, null, null];

  if (isSomeRequestItem) {
    result = await api({
      apiToken: controller.apiToken,
      pk: detail.id,
      data: {
        action: !!detail.id ? "EDIT" : "ORDER",
        encounter: state.selectedEncounter?.id,
        suspected_diagnosis: detail.suspected_diagnosis,
        is_emergency: detail.is_emergency,
        extra: detail.extra,
        order_items: filterList,
        order_status: 2,
      } as any,
      extra: {
        division: detail.order_div,
        device: controller.data?.device,
      },
    });
  }

  if (readyToViewItems.length) {
    // ทำการอัพเดต ready to view เมื่อเป็น status registered หรือ executed ด้วย action เนื่องจาก
    // imaging-order แก้ไขได้เฉพาะเป็น requested
    const promiseArr = readyToViewItems.map((item) =>
      UpdateOrderItemAction(controller, {
        data: { id: item.id, ready_to_view: item.ready_to_view },
        actionType: item.ready_to_view ? "READY_TO_VIEW" : "UNREADY_TO_VIEW",
      })
    );

    await Promise.all(promiseArr);
  }

  if (result[1]) {
    SetErrorMessage(controller, { ...params, error: result[1] });
  } else {
    controller.setState({
      ImagingOrderSequence: {
        ...state.ImagingOrderSequence,
        imagingItemDetail: null,
        imagingItemList: [],
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });

    Action(controller, { action: "SEARCH" });
  }
};

const HandleEditImagingRequest: Handler<Params<"EDIT_REQUEST">> = async (
  controller,
  params
) => {
  let state = controller.getState();

  const estimate = await GetImagingOrderEstimate(controller, {
    encounter: state.selectedEncounter?.id,
    items: params.data.order_items,
  });

  state = controller.getState();

  const [icd10KeyUp] = await GetIcd10KeyUp(controller, {
    method: "icdcode",
    search: params.data.suspected_diagnosis_code,
  });

  const icd10 = icd10KeyUp?.response?.[0];

  const items = params.data.order_items.map((item: any, index: number) => ({
    ...item,
    name: item.product_name,
    code:
      Array.from((item.name_code as string).matchAll(/\[(\w+)\]/g))?.[0]?.[1] ||
      "",
    active: true,
    ...(estimate.orders?.[index] || {}),
  }));

  controller.setState({
    searchedItemListWithKey: {
      ...state.searchedItemListWithKey,
      [DIAGNOSIS_SEARCH_ID]: icd10
        ? [
            {
              ...icd10,
              id: icd10.icd10_id,
              name: `[${icd10.icdcode}] ${icd10.icdterm}`,
            },
          ]
        : [],
    },
    ImagingOrderSequence: {
      ...state.ImagingOrderSequence,
      imagingItemDetail: params.data,
      imagingItemList: items,
    },
  });
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
export const GetClinicalFindingList: Handler = async (controller) => {
  if (!!controller.data.clinicaltags) {
    return;
  }

  const [r, e, n] = await DentalClinicalFindingList.list({
    apiToken: controller.apiToken,
    params: {
      limit: 99999,
      offset: 0,
    },
  });

  if (e) {
    console.log("masterdata clinicalFinding error.", e);
    return;
  }

  if (r.items && r.items.length > 0) {
    controller.data["clinicaltags"] = {
      name: "clinicaltags",
      items: r.items,
    };
  }
};

export const GetOrgan: Handler = (controller) => {
  if (!!controller.data.organ) {
    return;
  }

  axios.get("/static/organ.json").then((res) => {
    // console.log("finish Load /static/organ.json" , res.data)
    axios.get("/static/otherOrgan.json").then((res2) => {
      const all = res.data.concat(res2.data);
      controller.data["organ"] = {
        items: all,
        name: "organ",
      };
    });
  });
};

const GetImagingOrderEstimate: Handler<{
  encounter: number;
  items: any;
}> = async (controller, params) => {
  const result = await ImagingOrderEstimate.post({
    apiToken: controller.apiToken,
    data: {
      encounter: params.encounter,
      order_items: params.items,
    },
    extra: { division: controller.data.division },
  });

  return result?.[0] || {};
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */

const SetErrorMessage: Handler<LoadCheck & { error: any }> = (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction || params.action}`]: "ERROR",
    },
    errorMessage: {
      ...state.errorMessage,
      [params.errorKey || params.card]: params.error,
    },
  });
};
