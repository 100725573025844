import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Checkbox,
  Input,
  Radio
} from 'semantic-ui-react'

const ANES_AnesComplication01 = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ margin: "0px 0px 50px" }}>
        <div
          style={{height:"70px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(1, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              style={{ gridRow: "1/1", gridColumn: "1/41",display: "flex" , border: "solid 1px rgb(200, 200, 200, 0.5)","backgroundColor":"rgb(161, 221, 230, 0.49)", justifyContent: "space-between", alingItem: "center" }}>
              <div
                style={{paddingTop: "20px"}}>
                
                <label
                  style={{textAlign:"center",marginLeft:"20px","fontWeight":"bold","fontSize":"17px"}}>
                  Complication
                </label>
                <label
                  style={{width: "100%",textAlign:"center",marginLeft:"20px",paddingTop:"20px","fontSize":"17px"}}>
                  1 = Intraoperative | 2 = PACU  | 3 = Postoperative 24 hr. | 4 = Postoperative 48 ht.
                </label>
              </div>
              <div
                style={{paddingTop: "20px", marginRight: "20px", display: props.buttonStatus === "CONFIRMED" ?  "none" : "",}}>
                {props.buttonSave}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{height:"1560px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(26, 1fr)","gridTemplateColumns":"repeat(2, 1fr)"}}>
            <div
              style={{ gridRow: "1/25", gridColumn: "1/2", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              <div
                style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(25, 1fr)","gridTemplateColumns":"repeat(10, 1fr)"}}>
                <div
                  style={{ gridRow: "1/2", gridColumn: "1/7",marginTop:"10px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Retained ETT / Tracheostomy Tube
                  </label>
                </div>
                <div
                  style={{ gridRow: "2/3", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Ventilatory support
                  </label>
                </div>
                <div
                  style={{ gridRow: "3/4", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Sore throat
                  </label>
                </div>
                <div
                  style={{ gridRow: "4/5", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Upper airway obstruction
                  </label>
                </div>
                <div
                  style={{ gridRow: "5/6", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Lower airway obstruction
                  </label>
                </div>
                <div
                  style={{ gridRow: "6/7", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Unpredicted difficult intubation
                  </label>
                </div>
                <div
                  style={{ gridRow: "7/8", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Aspiration
                  </label>
                </div>
                <div
                  style={{ gridRow: "8/9", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Airway injury
                  </label>
                </div>
                <div
                  style={{ gridRow: "9/10", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Dental injury
                  </label>
                </div>
                <div
                  style={{ gridRow: "10/11", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    {`Esophageal intubation (เขียวหรือ SpO2 < 90%)`}
                  </label>
                </div>
                <div
                  style={{ gridRow: "11/12", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Pneumothorax
                  </label>
                </div>
                <div
                  style={{ gridRow: "12/13", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Hypoxaemia
                  </label>
                </div>
                <div
                  style={{ gridRow: "13/14", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Hypoventilation
                  </label>
                </div>
                <div
                  style={{ gridRow: "14/15", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Reintubation / Tube displacement
                  </label>
                </div>
                <div
                  style={{ gridRow: "15/16", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Atelectasis
                  </label>
                </div>
                <div
                  style={{ gridRow: "16/17", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Pulmonary edema / Effusion
                  </label>
                </div>
                <div
                  style={{ gridRow: "17/18", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    {`Sig. hypertension (SBP > 180mmHg)`}
                  </label>
                </div>
                <div
                  style={{ gridRow: "1/2", gridColumn: "7/11"}}>
                  <div
                    style={{marginTop:"10px"}}>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1RetainedETTTracheostomyTube === true}
                      label="1"
                      name="Is1RetainedETTTracheostomyTube"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2RetainedETTTracheostomyTube === true}
                      label="2"
                      name="Is2RetainedETTTracheostomyTube"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3RetainedETTTracheostomyTube === true}
                      label="3"
                      name="Is3RetainedETTTracheostomyTube"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4RetainedETTTracheostomyTube === true}
                      label="4"
                      name="Is4RetainedETTTracheostomyTube"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "2/3", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1VentilatorySupport === true}
                      label="1"
                      name="Is1VentilatorySupport"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2VentilatorySupport === true}
                      label="2"
                      name="Is2VentilatorySupport"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3VentilatorySupport === true}
                      label="3"
                      name="Is3VentilatorySupport"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4VentilatorySupport === true}
                      label="4"
                      name="Is4VentilatorySupport"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "3/4", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1SoreThroat === true}
                      label="1"
                      name="Is1SoreThroat"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2SoreThroat === true}
                      label="2"
                      name="Is2SoreThroat"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3SoreThroat === true}
                      label="3"
                      name="Is3SoreThroat"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4SoreThroat === true}
                      label="4"
                      name="Is4SoreThroat"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "4/5", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1UpperAirwayObstruction === true}
                      label="1"
                      name="Is1UpperAirwayObstruction"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2UpperAirwayObstruction === true}
                      label="2"
                      name="Is2UpperAirwayObstruction"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3UpperAirwayObstruction === true}
                      label="3"
                      name="Is3UpperAirwayObstruction"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4UpperAirwayObstruction === true}
                      label="4"
                      name="Is4UpperAirwayObstruction"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "5/6", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1LowerAirwayObstruction === true}
                      label="1"
                      name="Is1LowerAirwayObstruction"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2LowerAirwayObstruction === true}
                      label="2"
                      name="Is2LowerAirwayObstruction"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3LowerAirwayObstruction === true}
                      label="3"
                      name="Is3LowerAirwayObstruction"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4LowerAirwayObstruction === true}
                      label="4"
                      name="Is4LowerAirwayObstruction"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "6/7", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1UnpredictedDifficultIntubation === true}
                      label="1"
                      name="Is1UnpredictedDifficultIntubation"
                      onClick={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2UnpredictedDifficultIntubation === true}
                      label="2"
                      name="Is2UnpredictedDifficultIntubation"
                      onClick={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3UnpredictedDifficultIntubation === true}
                      label="3"
                      name="Is3UnpredictedDifficultIntubation"
                      onClick={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4UnpredictedDifficultIntubation === true}
                      label="4"
                      name="Is4UnpredictedDifficultIntubation"
                      onClick={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "7/8", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1Aspiration === true}
                      label="1"
                      name="Is1Aspiration"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2Aspiration === true}
                      label="2"
                      name="Is2Aspiration"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3Aspiration === true}
                      label="3"
                      name="Is3Aspiration"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4Aspiration === true}
                      label="4"
                      name="Is4Aspiration"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "8/9", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1AirwayInjury === true}
                      label="1"
                      name="Is1AirwayInjury"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2AirwayInjury === true}
                      label="2"
                      name="Is2AirwayInjury"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3AirwayInjury === true}
                      label="3"
                      name="Is3AirwayInjury"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4AirwayInjury === true}
                      label="4"
                      name="Is4AirwayInjury"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "9/10", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1DentalInjury === true}
                      label="1"
                      name="Is1DentalInjury"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2DentalInjury === true}
                      label="2"
                      name="Is2DentalInjury"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3DentalInjury === true}
                      label="3"
                      name="Is3DentalInjury"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4DentalInjury === true}
                      label="4"
                      name="Is4DentalInjury"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "10/11", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1EsophagealIntubation === true}
                      label="1"
                      name="Is1EsophagealIntubation"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2EsophagealIntubation === true}
                      label="2"
                      name="Is2EsophagealIntubation"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3EsophagealIntubation === true}
                      label="3"
                      name="Is3EsophagealIntubation"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4EsophagealIntubation === true}
                      label="4"
                      name="Is4EsophagealIntubation"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "11/12", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1Pneumothorax === true}
                      label="1"
                      name="Is1Pneumothorax"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2Pneumothorax === true}
                      label="2"
                      name="Is2Pneumothorax"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3Pneumothorax === true}
                      label="3"
                      name="Is3Pneumothorax"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4Pneumothorax === true}
                      label="4"
                      name="Is4Pneumothorax"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "12/13", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1Hypoxaemia === true}
                      label="1"
                      name="Is1Hypoxaemia"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2Hypoxaemia === true}
                      label="2"
                      name="Is2Hypoxaemia"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3Hypoxaemia === true}
                      label="3"
                      name="Is3Hypoxaemia"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4Hypoxaemia === true}
                      label="4"
                      name="Is4Hypoxaemia"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "13/14", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1Hypoventilation === true}
                      label="1"
                      name="Is1Hypoventilation"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2Hypoventilation === true}
                      label="2"
                      name="Is2Hypoventilation"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3Hypoventilation === true}
                      label="3"
                      name="Is3Hypoventilation"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4Hypoventilation === true}
                      label="4"
                      name="Is4Hypoventilation"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "14/15", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1ReintubationTubeDisplacement === true}
                      label="1"
                      name="Is1ReintubationTubeDisplacement"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2ReintubationTubeDisplacement === true}
                      label="2"
                      name="Is2ReintubationTubeDisplacement"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3ReintubationTubeDisplacement === true}
                      label="3"
                      name="Is3ReintubationTubeDisplacement"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4ReintubationTubeDisplacement === true}
                      label="4"
                      name="Is4ReintubationTubeDisplacement"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "15/16", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1Atelectasis === true}
                      label="1"
                      name="Is1Atelectasis"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2Atelectasis === true}
                      label="2"
                      name="Is2Atelectasis"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3Atelectasis === true}
                      label="3"
                      name="Is3Atelectasis"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4Atelectasis === true}
                      label="4"
                      name="Is4Atelectasis"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "16/17", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1PulmonaryEdemaEffusion === true}
                      label="1"
                      name="Is1PulmonaryEdemaEffusion"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2PulmonaryEdemaEffusion === true}
                      label="2"
                      name="Is2PulmonaryEdemaEffusion"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3PulmonaryEdemaEffusion === true}
                      label="3"
                      name="Is3PulmonaryEdemaEffusion"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4PulmonaryEdemaEffusion === true}
                      label="4"
                      name="Is4PulmonaryEdemaEffusion"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "17/18", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1SigHypertensionmore180 === true}
                      label="1"
                      name="Is1SigHypertensionmore180"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2SigHypertensionmore180 === true}
                      label="2"
                      name="Is2SigHypertensionmore180"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3SigHypertensionmore180 === true}
                      label="3"
                      name="Is3SigHypertensionmore180"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4SigHypertensionmore180 === true}
                      label="4"
                      name="Is4SigHypertensionmore180"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "18/19", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    {`Sig. hypotension (SBP < 80 mmHg)`}
                  </label>
                </div>
                <div
                  style={{ gridRow: "19/20", gridColumn: "1/7",marginTop:"5px"}}>
                  <div
                    style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(2, 1fr)","gridTemplateColumns":"repeat(1, 1fr)"}}>
                    <div
                      style={{ gridRow: "1/2", gridColumn: "1/2" }}>
                      <label
                        style={{"fontSize":"17px",marginLeft:"10px"}}>
                        Sig. arrhythmia
                      </label>
                    </div>
                    <div
                      style={{ gridRow: "2/3", gridColumn: "1/2" }}>
                      <label
                        style={{"fontSize":"17px",marginLeft:"10px"}}>
                        {`(Tachycardia > 120 bpm, Bradycardia < 40 bpm)`}
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  style={{ gridRow: "20/21", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Myocardial ischemia / MI
                  </label>
                </div>
                <div
                  style={{ gridRow: "21/22", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Cardiac failure
                  </label>
                </div>
                <div
                  style={{ gridRow: "22/23", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Cardiac arrest
                  </label>
                </div>
                <div
                  style={{ gridRow: "23/24", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Shock
                  </label>
                </div>
                <div
                  style={{ gridRow: "24/25", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    {`Delayed emergence (ตื่นช้า >= 1 ชั่วโมง)`}
                  </label>
                </div>
                <div
                  style={{ gridRow: "25/26", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Coma / CVA
                  </label>
                </div>
                <div
                  style={{ gridRow: "18/19", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1SigHypertensionthan180 === true}
                      label="1"
                      name="Is1SigHypertensionthan180"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2SigHypertensionthan180 === true}
                      label="2"
                      name="Is2SigHypertensionthan180"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3SigHypertensionthan180 === true}
                      label="3"
                      name="Is3SigHypertensionthan180"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4SigHypertensionthan180 === true}
                      label="4"
                      name="Is4SigHypertensionthan180"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "19/20", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1SigArrhythmia === true}
                      label="1"
                      name="Is1SigArrhythmia"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2SigArrhythmia === true}
                      label="2"
                      name="Is2SigArrhythmia"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3SigArrhythmia === true}
                      label="3"
                      name="Is3SigArrhythmia"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4SigArrhythmia === true}
                      label="4"
                      name="Is4SigArrhythmia"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "20/21", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1MyocardialIschemia === true}
                      label="1"
                      name="Is1MyocardialIschemia"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2MyocardialIschemia === true}
                      label="2"
                      name="Is2MyocardialIschemia"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3MyocardialIschemia === true}
                      label="3"
                      name="Is3MyocardialIschemia"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4MyocardialIschemia === true}
                      label="4"
                      name="Is4MyocardialIschemia"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "21/22", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1CardiacFailure === true}
                      label="1"
                      name="Is1CardiacFailure"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2CardiacFailure === true}
                      label="2"
                      name="Is2CardiacFailure"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3CardiacFailure === true}
                      label="3"
                      name="Is3CardiacFailure"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4CardiacFailure === true}
                      label="4"
                      name="Is4CardiacFailure"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "22/23", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1CardiacArrest === true}
                      label="1"
                      name="Is1CardiacArrest"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2CardiacArrest === true}
                      label="2"
                      name="Is2CardiacArrest"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3CardiacArrest === true}
                      label="3"
                      name="Is3CardiacArrest"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4CardiacArrest === true}
                      label="4"
                      name="Is4CardiacArrest"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "23/24", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1Shock === true}
                      label="1"
                      name="Is1Shock"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2Shock === true}
                      label="2"
                      name="Is2Shock"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3Shock === true}
                      label="3"
                      name="Is3Shock"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4Shock === true}
                      label="4"
                      name="Is4Shock"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "24/25", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1DelayedEmergence === true}
                      label="1"
                      name="Is1DelayedEmergence"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2DelayedEmergence === true}
                      label="2"
                      name="Is2DelayedEmergence"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3DelayedEmergence === true}
                      label="3"
                      name="Is3DelayedEmergence"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4DelayedEmergence === true}
                      label="4"
                      name="Is4DelayedEmergence"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "25/26", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1ComaCVA === true}
                      label="1"
                      name="Is1ComaCVA"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2ComaCVA === true}
                      label="2"
                      name="Is2ComaCVA"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3ComaCVA === true}
                      label="3"
                      name="Is3ComaCVA"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4ComaCVA === true}
                      label="4"
                      name="Is4ComaCVA"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ gridRow: "1/25", gridColumn: "2/3", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              <div
                style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(25, 1fr)","gridTemplateColumns":"repeat(10, 1fr)"}}>
                <div
                  style={{ gridRow: "1/2", gridColumn: "1/7",marginTop:"10px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Awareness under GA
                  </label>
                </div>
                <div
                  style={{ gridRow: "2/3", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    High block
                  </label>
                </div>
                <div
                  style={{ gridRow: "3/4", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Post-dural puncture headache
                  </label>
                </div>
                <div
                  style={{ gridRow: "4/5", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Peripheral nerve injury
                  </label>
                </div>
                <div
                  style={{ gridRow: "5/6", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Back pain
                  </label>
                </div>
                <div
                  style={{ gridRow: "6/7", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Convulsion
                  </label>
                </div>
                <div
                  style={{ gridRow: "7/8", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    LA toxicity
                  </label>
                </div>
                <div
                  style={{ gridRow: "8/9", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Delirium
                  </label>
                </div>
                <div
                  style={{ gridRow: "9/10", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Hypoglycemia
                  </label>
                </div>
                <div
                  style={{ gridRow: "10/11", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Electolyte / Acid-base imbalance
                  </label>
                </div>
                <div
                  style={{ gridRow: "11/12", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    {`Hypothermia (Temp < 36 oC)`}
                  </label>
                </div>
                <div
                  style={{ gridRow: "12/13", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    {`Fever (Temp > 38 oC)`}
                  </label>
                </div>
                <div
                  style={{ gridRow: "13/14", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Transfusion reaction
                  </label>
                </div>
                <div
                  style={{ gridRow: "14/15", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Coagulopathy
                  </label>
                </div>
                <div
                  style={{ gridRow: "15/16", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Massive blood loss
                  </label>
                </div>
                <div
                  style={{ gridRow: "16/17", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Allergic reaction
                  </label>
                </div>
                <div
                  style={{ gridRow: "17/18", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Pain
                  </label>
                </div>
                <div
                  style={{ gridRow: "1/2", gridColumn: "7/11"}}>
                  <div
                    style={{marginTop:"10px"}}>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1AwarenessUnderGA === true}
                      label="1"
                      name="Is1AwarenessUnderGA"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2AwarenessUnderGA === true}
                      label="2"
                      name="Is2AwarenessUnderGA"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3AwarenessUnderGA === true}
                      label="3"
                      name="Is3AwarenessUnderGA"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4AwarenessUnderGA === true}
                      label="4"
                      name="Is4AwarenessUnderGA"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "2/3", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1Highblock === true}
                      label="1"
                      name="Is1Highblock"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2Highblock === true}
                      label="2"
                      name="Is2Highblock"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3Highblock === true}
                      label="3"
                      name="Is3Highblock"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4Highblock === true}
                      label="4"
                      name="Is4Highblock"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "3/4", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1PostDuralPunctureHeadache === true}
                      label="1"
                      name="Is1PostDuralPunctureHeadache"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2PostDuralPunctureHeadache === true}
                      label="2"
                      name="Is2PostDuralPunctureHeadache"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3PostDuralPunctureHeadache === true}
                      label="3"
                      name="Is3PostDuralPunctureHeadache"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4PostDuralPunctureHeadache === true}
                      label="4"
                      name="Is4PostDuralPunctureHeadache"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "4/5", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1PeripheralNerveInjury === true}
                      label="1"
                      name="Is1PeripheralNerveInjury"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2PeripheralNerveInjury === true}
                      label="2"
                      name="Is2PeripheralNerveInjury"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3PeripheralNerveInjury === true}
                      label="3"
                      name="Is3PeripheralNerveInjury"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4PeripheralNerveInjury === true}
                      label="4"
                      name="Is4PeripheralNerveInjury"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "5/6", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1BackPain === true}
                      label="1"
                      name="Is1BackPain"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2BackPain === true}
                      label="2"
                      name="Is2BackPain"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3BackPain === true}
                      label="3"
                      name="Is3BackPain"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4BackPain === true}
                      label="4"
                      name="Is4BackPain"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "6/7", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1Convulsion === true}
                      label="1"
                      name="Is1Convulsion"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2Convulsion === true}
                      label="2"
                      name="Is2Convulsion"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3Convulsion === true}
                      label="3"
                      name="Is3Convulsion"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4Convulsion === true}
                      label="4"
                      name="Is4Convulsion"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "7/8", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1LAToxicity === true}
                      label="1"
                      name="Is1LAToxicity"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2LAToxicity === true}
                      label="2"
                      name="Is2LAToxicity"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3LAToxicity === true}
                      label="3"
                      name="Is3LAToxicity"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4LAToxicity === true}
                      label="4"
                      name="Is4LAToxicity"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "8/9", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1Delirium === true}
                      label="1"
                      name="Is1Delirium"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2Delirium === true}
                      label="2"
                      name="Is2Delirium"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3Delirium === true}
                      label="3"
                      name="Is3Delirium"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4Delirium === true}
                      label="4"
                      name="Is4Delirium"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "9/10", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1Hypoglycemia === true}
                      label="1"
                      name="Is1Hypoglycemia"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2Hypoglycemia === true}
                      label="2"
                      name="Is2Hypoglycemia"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3Hypoglycemia === true}
                      label="3"
                      name="Is3Hypoglycemia"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4Hypoglycemia === true}
                      label="4"
                      name="Is4Hypoglycemia"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "10/11", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1ElectolyteAcidBaseImbalance === true}
                      label="1"
                      name="Is1ElectolyteAcidBaseImbalance"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2ElectolyteAcidBaseImbalance === true}
                      label="2"
                      name="Is2ElectolyteAcidBaseImbalance"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3ElectolyteAcidBaseImbalance === true}
                      label="3"
                      name="Is3ElectolyteAcidBaseImbalance"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4ElectolyteAcidBaseImbalance === true}
                      label="4"
                      name="Is4ElectolyteAcidBaseImbalance"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "11/12", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1Hypothermia === true}
                      label="1"
                      name="Is1Hypothermia"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2Hypothermia === true}
                      label="2"
                      name="Is2Hypothermia"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3Hypothermia === true}
                      label="3"
                      name="Is3Hypothermia"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4Hypothermia === true}
                      label="4"
                      name="Is4Hypothermia"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "12/13", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1Fever === true}
                      label="1"
                      name="Is1Fever"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2Fever === true}
                      label="2"
                      name="Is2Fever"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3Fever === true}
                      label="3"
                      name="Is3Fever"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4Fever === true}
                      label="4"
                      name="Is4Fever"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "13/14", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1TransfusionReaction === true}
                      label="1"
                      name="Is1TransfusionReaction"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2TransfusionReaction === true}
                      label="2"
                      name="Is2TransfusionReaction"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3TransfusionReaction === true}
                      label="3"
                      name="Is3TransfusionReaction"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4TransfusionReaction === true}
                      label="4"
                      name="Is4TransfusionReaction"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "14/15", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1CoagulopathyMI === true}
                      label="1"
                      name="Is1CoagulopathyMI"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2CoagulopathyMI === true}
                      label="2"
                      name="Is2CoagulopathyMI"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3CoagulopathyMI === true}
                      label="3"
                      name="Is3CoagulopathyMI"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4CoagulopathyMI === true}
                      label="4"
                      name="Is4CoagulopathyMI"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "15/16", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1MassiveBloodLoss === true}
                      label="1"
                      name="Is1MassiveBloodLoss"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2MassiveBloodLoss === true}
                      label="2"
                      name="Is2MassiveBloodLoss"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3MassiveBloodLoss === true}
                      label="3"
                      name="Is3MassiveBloodLoss"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4MassiveBloodLoss === true}
                      label="4"
                      name="Is4MassiveBloodLoss"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "16/17", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1AllergicReaction === true}
                      label="1"
                      name="Is1AllergicReaction"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2AllergicReaction === true}
                      label="2"
                      name="Is2AllergicReaction"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3AllergicReaction === true}
                      label="3"
                      name="Is3AllergicReaction"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4AllergicReaction === true}
                      label="4"
                      name="Is4AllergicReaction"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "17/18", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1Pain === true}
                      label="1"
                      name="Is1Pain"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2Pain === true}
                      label="2"
                      name="Is2Pain"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3Pain === true}
                      label="3"
                      name="Is3Pain"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4Pain === true}
                      label="4"
                      name="Is4Pain"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "18/19", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Burn
                  </label>
                </div>
                <div
                  style={{ gridRow: "19/20", gridColumn: "1/7",marginTop:"5px"}}>
                  <div
                    style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(2, 1fr)","gridTemplateColumns":"repeat(1, 1fr)"}}>
                    <div
                      style={{ gridRow: "1/2", gridColumn: "1/2" }}>
                      <label
                        style={{"fontSize":"17px",marginLeft:"10px"}}>
                        Shivering
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  style={{ gridRow: "20/21", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Nausea & vomiting
                  </label>
                </div>
                <div
                  style={{ gridRow: "21/22", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Urine retention
                  </label>
                </div>
                <div
                  style={{ gridRow: "22/23", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Itching
                  </label>
                </div>
                <div
                  style={{ gridRow: "23/24", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Drug error / Human error
                  </label>
                </div>
                <div
                  style={{ gridRow: "24/25", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Pressure sore
                  </label>
                </div>
                <div
                  style={{ gridRow: "25/26", gridColumn: "1/7",marginTop:"5px"}}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Re-operation
                  </label>
                </div>
                <div
                  style={{ gridRow: "18/19", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1Burn === true}
                      label="1"
                      name="Is1Burn"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2Burn === true}
                      label="2"
                      name="Is2Burn"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3Burn === true}
                      label="3"
                      name="Is3Burn"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4Burn === true}
                      label="4"
                      name="Is4Burn"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "19/20", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1Shivering === true}
                      label="1"
                      name="Is1Shivering"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2Shivering === true}
                      label="2"
                      name="Is2Shivering"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3Shivering === true}
                      label="3"
                      name="Is3Shivering"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4Shivering === true}
                      label="4"
                      name="Is4Shivering"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "20/21", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1NauseaVomiting === true}
                      label="1"
                      name="Is1NauseaVomiting"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2NauseaVomiting === true}
                      label="2"
                      name="Is2NauseaVomiting"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3NauseaVomiting === true}
                      label="3"
                      name="Is3NauseaVomiting"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4NauseaVomiting === true}
                      label="4"
                      name="Is4NauseaVomiting"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "21/22", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1UrineRetention === true}
                      label="1"
                      name="Is1UrineRetention"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2UrineRetention === true}
                      label="2"
                      name="Is2UrineRetention"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3UrineRetention === true}
                      label="3"
                      name="Is3UrineRetention"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4UrineRetention === true}
                      label="4"
                      name="Is4UrineRetention"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "22/23", gridColumn: "7/11" }}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1Itching === true}
                      label="1"
                      name="Is1Itching"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2Itching === true}
                      label="2"
                      name="Is2Itching"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3Itching === true}
                      label="3"
                      name="Is3Itching"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4Itching === true}
                      label="4"
                      name="Is4Itching"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "23/24", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1DrugErrorHumanError === true}
                      label="1"
                      name="Is1DrugErrorHumanError"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2DrugErrorHumanError === true}
                      label="2"
                      name="Is2DrugErrorHumanError"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3DrugErrorHumanError === true}
                      label="3"
                      name="Is3DrugErrorHumanError"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4DrugErrorHumanError === true}
                      label="4"
                      name="Is4DrugErrorHumanError"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "24/25", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1PressureSore === true}
                      label="1"
                      name="Is1PressureSore"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2PressureSore === true}
                      label="2"
                      name="Is2PressureSore"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3PressureSore === true}
                      label="3"
                      name="Is3PressureSore"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4PressureSore === true}
                      label="4"
                      name="Is4PressureSore"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{ gridRow: "25/26", gridColumn: "7/11"}}>
                  <div>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1ReOperation === true}
                      label="1"
                      name="Is1ReOperation"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2ReOperation === true}
                      label="2"
                      name="Is2ReOperation"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3ReOperation === true}
                      label="3"
                      name="Is3ReOperation"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4ReOperation === true}
                      label="4"
                      name="Is4ReOperation"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ gridRow: "25/26", gridColumn: "1/2", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              <div
                style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(1, 1fr)","gridTemplateColumns":"repeat(10, 1fr)"}}>
                <div
                  style={{ gridRow: "1/2", gridColumn: "1/7" }}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Other specify
                  </label>
                  <Input
                    name="OtherSpecifyRemark"
                    onChange={props.onChangeData}
                    style={{marginTop:"10px",marginLeft:"10px",width:"70%"}}
                    value={props.anesComplication?.OtherSpecifyRemark}>
                  </Input>
                </div>
                <div
                  style={{ gridRow: "1/2", gridColumn: "7/11"}}>
                  <div
                    style={{marginTop:"15px"}}>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1OtherSpecify === true}
                      label="1"
                      name="Is1OtherSpecify"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2OtherSpecify === true}
                      label="2"
                      name="Is2OtherSpecify"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3OtherSpecify === true}
                      label="3"
                      name="Is3OtherSpecify"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4OtherSpecify === true}
                      label="4"
                      name="Is4OtherSpecify"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ gridRow: "25/26", gridColumn: "2/3", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              <div
                style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(1, 1fr)","gridTemplateColumns":"repeat(10, 1fr)"}}>
                <div
                  style={{ gridRow: "1/2", gridColumn: "1/7" }}>
                  <label
                    style={{"fontSize":"17px",marginLeft:"10px"}}>
                    Wound
                  </label>
                  <Input
                    name="WoundRemark"
                    onChange={props.onChangeData}
                    style={{marginTop:"10px",marginLeft:"10px",width:"70%"}}
                    value={props.anesComplication?.WoundRemark}>
                  </Input>
                </div>
                <div
                  style={{ gridRow: "1/2", gridColumn: "7/11"}}>
                  <div
                    style={{marginTop:"15px"}}>
                    
                    <Checkbox
                      checked={props.anesComplication?.Is1Wound === true}
                      label="1"
                      name="Is1Wound"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is2Wound === true}
                      label="2"
                      name="Is2Wound"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is3Wound === true}
                      label="3"
                      name="Is3Wound"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.Is4Wound === true}
                      label="4"
                      name="Is4Wound"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={ {border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              
              <div
                style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(1, 1fr)","gridTemplateColumns":"repeat(10, 1fr)"}}>
                <div
                  style={{ gridRow: "1/2", gridColumn: "1/7"}}>
                  <div
                    style={{ gridRow: "1/2", gridColumn: "1/7" }}>
                    <label
                      style={{"fontSize":"17px",marginLeft:"10px"}}>
                      None
                    </label>
                    <Input
                      name="ComplicationNoneRemark"
                      onChange={props.onChangeData}
                      style={{marginTop:"10px",marginLeft:"70px",width:"70%"}}
                      value={props.anesComplication?.ComplicationNoneRemark}>
                    </Input>
                  </div>
                </div>
                <div
                  style={{ gridRow: "1/2", gridColumn: "7/11"}}>
                  <div
                    style={{marginTop:"15px"}}>
                    
                    <Checkbox
                      checked={props.anesComplication?.IS1ComplicationNone === true}
                      label="1"
                      name="IS1ComplicationNone"
                      onChange={props.onChangeData}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.IS2ComplicationNone === true}
                      label="2"
                      name="IS2ComplicationNone"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.IS3ComplicationNone === true}
                      label="3"
                      name="IS3ComplicationNone"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                    <Checkbox
                      checked={props.anesComplication?.IS4ComplicationNone === true}
                      label="4"
                      name="IS4ComplicationNone"
                      onChange={props.onChangeData}
                      style={{marginLeft:"40px"}}>
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{height:"60px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(1, 1fr)","gridTemplateColumns":"repeat(2, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/2", display:"flex",border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              <label
                style={{textAlign:"center",marginLeft:"20px",paddingTop:"20px","fontWeight":"bold","fontSize":"16px"}}>
                Direct transferred to
              </label>
              <Radio
                checked={props.anesComplication?.IsDirectTransferred === "Ward"}
                label="Ward"
                name="IsDirectTransferred"
                onChange={props.onChangeData}
                style={{textAlign:"center",marginLeft:"20px",paddingTop:"20px","fontSize":"16px"}}
                value="Ward">
              </Radio>
              <Radio
                checked={props.anesComplication?.IsDirectTransferred === "ICU"}
                label="ICU"
                name="IsDirectTransferred"
                onChange={props.onChangeData}
                style={{textAlign:"center",marginLeft:"20px",paddingTop:"20px","fontSize":"16px"}}
                value="ICU">
              </Radio>
              <Radio
                checked={props.anesComplication?.IsDirectTransferred === "Other"}
                label="Other"
                name="IsDirectTransferred"
                onChange={props.onChangeData}
                style={{textAlign:"center",marginLeft:"20px",paddingTop:"20px","fontSize":"16px"}}
                value="Other">
              </Radio>
              <Input
                disabled={props.anesComplication?.IsDirectTransferred !== "Other"}
                name="IsDirectTransferredToOtherRemark"
                onChange={props.onChangeData}
                style={{marginLeft:"20px",padding:"5px",width:"300px"}}
                value={props.anesComplication?.IsDirectTransferred === "Other" ? props.anesComplication?.IsDirectTransferredToOtherRemark : ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "2/2", display:"flex",border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              <label
                style={{textAlign:"center",marginLeft:"5px",paddingTop:"20px","fontWeight":"bold","fontSize":"16px"}}>
                PACU duration
              </label>
              <Input
                name="PACUDurationTime"
                onChange={props.onChangeData}
                style={{padding:"5px",width:"120px"}}
                type="time"
                value={props.anesComplication?.PACUDurationTime}>
              </Input>
              <label
                style={{textAlign:"center",paddingTop:"20px","fontSize":"16px"}}>
                min
              </label>
              <label
                style={{textAlign:"center",marginLeft:"10px",paddingTop:"20px","fontSize":"16px"}}>
                กรณีมากกว่า 60 นาที ระบุเหตุผล
              </label>
              <Input
                name="PACUDurationRemark"
                onChange={props.onChangeData}
                style={{padding:"5px",width:"280px"}}
                value={props.anesComplication?.PACUDurationRemark}>
              </Input>
            </div>
          </div>
        </div>
      </div>
    )
}

export default ANES_AnesComplication01

export const screenPropsDefault = {}

/* Date Time : Thu Dec 29 2022 03:02:22 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 0px 50px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"70px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/1\", gridColumn: \"1/41\",display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230, 0.49)\", justifyContent: \"space-between\", alingItem: \"center\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"1560px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "label": "divgrid",
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(26, 1fr)\",\"gridTemplateColumns\":\"repeat(2, 1fr)\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/25\", gridColumn: \"1/2\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "label": "divgrid",
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(25, 1fr)\",\"gridTemplateColumns\":\"repeat(10, 1fr)\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/7\",marginTop:\"10px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Retained ETT / Tracheostomy Tube"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "Ventilatory support"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "Sore throat"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "Upper airway obstruction"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Lower airway obstruction"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "Unpredicted difficult intubation"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "Aspiration"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "Airway injury"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "Dental injury"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": "`Esophageal intubation (เขียวหรือ SpO2 < 90%)`"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "Pneumothorax"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/13\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "Hypoxaemia"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "label",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "Hypoventilation"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/15\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "Reintubation / Tube displacement"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"15/16\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "Atelectasis"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"16/17\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "label",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "Pulmonary edema / Effusion"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"17/18\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "`Sig. hypertension (SBP > 180mmHg)`"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"10px\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Checkbox",
      "parent": 45,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1RetainedETTTracheostomyTube === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1RetainedETTTracheostomyTube"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Checkbox",
      "parent": 45,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2RetainedETTTracheostomyTube === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2RetainedETTTracheostomyTube"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Checkbox",
      "parent": 45,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3RetainedETTTracheostomyTube === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3RetainedETTTracheostomyTube"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Checkbox",
      "parent": 45,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4RetainedETTTracheostomyTube === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4RetainedETTTracheostomyTube"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/13\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/15\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"15/16\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"16/17\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"17/18\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "Checkbox",
      "parent": 66,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1VentilatorySupport === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1VentilatorySupport"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Checkbox",
      "parent": 66,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2VentilatorySupport === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2VentilatorySupport"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Checkbox",
      "parent": 66,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3VentilatorySupport === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3VentilatorySupport"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Checkbox",
      "parent": 66,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4VentilatorySupport === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4VentilatorySupport"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Checkbox",
      "parent": 71,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1SoreThroat === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1SoreThroat"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Checkbox",
      "parent": 71,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2SoreThroat === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2SoreThroat"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Checkbox",
      "parent": 71,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3SoreThroat === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3SoreThroat"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Checkbox",
      "parent": 71,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4SoreThroat === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4SoreThroat"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Checkbox",
      "parent": 76,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1UpperAirwayObstruction === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1UpperAirwayObstruction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Checkbox",
      "parent": 76,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2UpperAirwayObstruction === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2UpperAirwayObstruction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Checkbox",
      "parent": 76,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3UpperAirwayObstruction === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3UpperAirwayObstruction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Checkbox",
      "parent": 76,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4UpperAirwayObstruction === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4UpperAirwayObstruction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Checkbox",
      "parent": 81,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1LowerAirwayObstruction === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1LowerAirwayObstruction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Checkbox",
      "parent": 81,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2LowerAirwayObstruction === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2LowerAirwayObstruction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Checkbox",
      "parent": 81,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3LowerAirwayObstruction === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3LowerAirwayObstruction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Checkbox",
      "parent": 81,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4LowerAirwayObstruction === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4LowerAirwayObstruction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Checkbox",
      "parent": 86,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1UnpredictedDifficultIntubation === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1UnpredictedDifficultIntubation"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Checkbox",
      "parent": 86,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2UnpredictedDifficultIntubation === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2UnpredictedDifficultIntubation"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "Checkbox",
      "parent": 86,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3UnpredictedDifficultIntubation === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3UnpredictedDifficultIntubation"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Checkbox",
      "parent": 86,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4UnpredictedDifficultIntubation === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4UnpredictedDifficultIntubation"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "Checkbox",
      "parent": 91,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1Aspiration === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1Aspiration"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "Checkbox",
      "parent": 91,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2Aspiration === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2Aspiration"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "Checkbox",
      "parent": 91,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3Aspiration === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3Aspiration"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "Checkbox",
      "parent": 91,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4Aspiration === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4Aspiration"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "Checkbox",
      "parent": 96,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1AirwayInjury === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1AirwayInjury"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Checkbox",
      "parent": 96,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2AirwayInjury === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2AirwayInjury"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "Checkbox",
      "parent": 96,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3AirwayInjury === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3AirwayInjury"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "Checkbox",
      "parent": 96,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4AirwayInjury === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4AirwayInjury"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Checkbox",
      "parent": 101,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1DentalInjury === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1DentalInjury"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Checkbox",
      "parent": 101,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2DentalInjury === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2DentalInjury"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "Checkbox",
      "parent": 101,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3DentalInjury === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3DentalInjury"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Checkbox",
      "parent": 101,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4DentalInjury === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4DentalInjury"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Checkbox",
      "parent": 106,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1EsophagealIntubation === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1EsophagealIntubation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "Checkbox",
      "parent": 106,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2EsophagealIntubation === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2EsophagealIntubation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "Checkbox",
      "parent": 106,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3EsophagealIntubation === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3EsophagealIntubation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Checkbox",
      "parent": 106,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4EsophagealIntubation === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4EsophagealIntubation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Checkbox",
      "parent": 111,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1Pneumothorax === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1Pneumothorax"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Checkbox",
      "parent": 111,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2Pneumothorax === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2Pneumothorax"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Checkbox",
      "parent": 111,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3Pneumothorax === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3Pneumothorax"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Checkbox",
      "parent": 111,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4Pneumothorax === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4Pneumothorax"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "Checkbox",
      "parent": 116,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1Hypoxaemia === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1Hypoxaemia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onClick": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "Checkbox",
      "parent": 116,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2Hypoxaemia === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2Hypoxaemia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "Checkbox",
      "parent": 116,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3Hypoxaemia === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3Hypoxaemia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "Checkbox",
      "parent": 116,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4Hypoxaemia === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4Hypoxaemia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "Checkbox",
      "parent": 121,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1Hypoventilation === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1Hypoventilation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "Checkbox",
      "parent": 121,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2Hypoventilation === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2Hypoventilation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "Checkbox",
      "parent": 121,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3Hypoventilation === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3Hypoventilation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "Checkbox",
      "parent": 121,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4Hypoventilation === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4Hypoventilation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "Checkbox",
      "parent": 126,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1ReintubationTubeDisplacement === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1ReintubationTubeDisplacement"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "Checkbox",
      "parent": 126,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2ReintubationTubeDisplacement === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2ReintubationTubeDisplacement"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "Checkbox",
      "parent": 126,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3ReintubationTubeDisplacement === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3ReintubationTubeDisplacement"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "Checkbox",
      "parent": 126,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4ReintubationTubeDisplacement === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4ReintubationTubeDisplacement"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "Checkbox",
      "parent": 131,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1Atelectasis === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1Atelectasis"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "Checkbox",
      "parent": 131,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2Atelectasis === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2Atelectasis"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "Checkbox",
      "parent": 131,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3Atelectasis === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3Atelectasis"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "Checkbox",
      "parent": 131,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4Atelectasis === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4Atelectasis"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "Checkbox",
      "parent": 136,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1PulmonaryEdemaEffusion === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1PulmonaryEdemaEffusion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "Checkbox",
      "parent": 136,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2PulmonaryEdemaEffusion === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2PulmonaryEdemaEffusion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "Checkbox",
      "parent": 136,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3PulmonaryEdemaEffusion === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3PulmonaryEdemaEffusion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "Checkbox",
      "parent": 136,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4PulmonaryEdemaEffusion === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4PulmonaryEdemaEffusion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "Checkbox",
      "parent": 141,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1SigHypertensionmore180 === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1SigHypertensionmore180"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "Checkbox",
      "parent": 141,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2SigHypertensionmore180 === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2SigHypertensionmore180"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "Checkbox",
      "parent": 141,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3SigHypertensionmore180 === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3SigHypertensionmore180"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "Checkbox",
      "parent": 141,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4SigHypertensionmore180 === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4SigHypertensionmore180"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"18/19\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "label",
      "parent": 146,
      "props": {
        "children": {
          "type": "code",
          "value": "`Sig. hypotension (SBP < 80 mmHg)`"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"19/20\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "label",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": "Sig. arrhythmia"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"20/21\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "label",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": "Myocardial ischemia / MI"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"21/22\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "label",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": "Cardiac failure"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"22/23\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "label",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": "Cardiac arrest"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"23/24\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "label",
      "parent": 156,
      "props": {
        "children": {
          "type": "value",
          "value": "Shock"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"24/25\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "label",
      "parent": 158,
      "props": {
        "children": {
          "type": "code",
          "value": "`Delayed emergence (ตื่นช้า >= 1 ชั่วโมง)`"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"25/26\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "label",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": "Coma / CVA"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "label": "divgrid",
      "name": "div",
      "parent": 148,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(2, 1fr)\",\"gridTemplateColumns\":\"repeat(1, 1fr)\"}"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 162,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 162,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "label",
      "parent": 164,
      "props": {
        "children": {
          "type": "code",
          "value": "`(Tachycardia > 120 bpm, Bradycardia < 40 bpm)`"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"18/19\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"19/20\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"20/21\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"21/22\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"22/23\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"23/24\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"24/25\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"25/26\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "div",
      "parent": 166,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "Checkbox",
      "parent": 174,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1SigHypertensionthan180 === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1SigHypertensionthan180"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "Checkbox",
      "parent": 174,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2SigHypertensionthan180 === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2SigHypertensionthan180"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "Checkbox",
      "parent": 174,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3SigHypertensionthan180 === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3SigHypertensionthan180"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "Checkbox",
      "parent": 174,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4SigHypertensionthan180 === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4SigHypertensionthan180"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "div",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "Checkbox",
      "parent": 179,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1SigArrhythmia === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1SigArrhythmia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "Checkbox",
      "parent": 179,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2SigArrhythmia === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2SigArrhythmia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "Checkbox",
      "parent": 179,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3SigArrhythmia === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3SigArrhythmia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 183,
      "name": "Checkbox",
      "parent": 179,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4SigArrhythmia === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4SigArrhythmia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "div",
      "parent": 168,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "Checkbox",
      "parent": 184,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1MyocardialIschemia === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1MyocardialIschemia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "Checkbox",
      "parent": 184,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2MyocardialIschemia === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2MyocardialIschemia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 187,
      "name": "Checkbox",
      "parent": 184,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3MyocardialIschemia === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3MyocardialIschemia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 188,
      "name": "Checkbox",
      "parent": 184,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4MyocardialIschemia === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4MyocardialIschemia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 169,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 190,
      "name": "Checkbox",
      "parent": 189,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1CardiacFailure === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1CardiacFailure"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 191,
      "name": "Checkbox",
      "parent": 189,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2CardiacFailure === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2CardiacFailure"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 192,
      "name": "Checkbox",
      "parent": 189,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3CardiacFailure === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3CardiacFailure"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 193,
      "name": "Checkbox",
      "parent": 189,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4CardiacFailure === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4CardiacFailure"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "div",
      "parent": 170,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 195,
      "name": "Checkbox",
      "parent": 194,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1CardiacArrest === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1CardiacArrest"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "Checkbox",
      "parent": 194,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2CardiacArrest === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2CardiacArrest"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "Checkbox",
      "parent": 194,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3CardiacArrest === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3CardiacArrest"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "Checkbox",
      "parent": 194,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4CardiacArrest === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4CardiacArrest"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": null,
      "id": 199,
      "name": "div",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "Checkbox",
      "parent": 199,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1Shock === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1Shock"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 201,
      "name": "Checkbox",
      "parent": 199,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2Shock === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2Shock"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 202,
      "name": "Checkbox",
      "parent": 199,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3Shock === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3Shock"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 203,
      "name": "Checkbox",
      "parent": 199,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4Shock === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4Shock"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": null,
      "id": 204,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 205,
      "name": "Checkbox",
      "parent": 204,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1DelayedEmergence === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1DelayedEmergence"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 206,
      "name": "Checkbox",
      "parent": 204,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2DelayedEmergence === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2DelayedEmergence"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 207,
      "name": "Checkbox",
      "parent": 204,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3DelayedEmergence === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3DelayedEmergence"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 208,
      "name": "Checkbox",
      "parent": 204,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4DelayedEmergence === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4DelayedEmergence"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": null,
      "id": 209,
      "name": "div",
      "parent": 173,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "Checkbox",
      "parent": 209,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1ComaCVA === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1ComaCVA"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 211,
      "name": "Checkbox",
      "parent": 209,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2ComaCVA === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2ComaCVA"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 212,
      "name": "Checkbox",
      "parent": 209,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3ComaCVA === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3ComaCVA"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 213,
      "name": "Checkbox",
      "parent": 209,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4ComaCVA === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4ComaCVA"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": null,
      "id": 214,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/25\", gridColumn: \"2/3\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": null,
      "id": 215,
      "name": "div",
      "parent": 214,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(25, 1fr)\",\"gridTemplateColumns\":\"repeat(10, 1fr)\"}"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": null,
      "id": 216,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/7\",marginTop:\"10px\"}"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": null,
      "id": 217,
      "name": "label",
      "parent": 216,
      "props": {
        "children": {
          "type": "value",
          "value": "Awareness under GA"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": null,
      "id": 218,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": null,
      "id": 219,
      "name": "label",
      "parent": 218,
      "props": {
        "children": {
          "type": "value",
          "value": "High block"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": null,
      "id": 220,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": null,
      "id": 221,
      "name": "label",
      "parent": 220,
      "props": {
        "children": {
          "type": "value",
          "value": "Post-dural puncture headache"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": null,
      "id": 222,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": null,
      "id": 223,
      "name": "label",
      "parent": 222,
      "props": {
        "children": {
          "type": "value",
          "value": "Peripheral nerve injury"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": null,
      "id": 224,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": null,
      "id": 225,
      "name": "label",
      "parent": 224,
      "props": {
        "children": {
          "type": "value",
          "value": "Back pain"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": null,
      "id": 226,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": null,
      "id": 227,
      "name": "label",
      "parent": 226,
      "props": {
        "children": {
          "type": "value",
          "value": "Convulsion"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": null,
      "id": 228,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": null,
      "id": 229,
      "name": "label",
      "parent": 228,
      "props": {
        "children": {
          "type": "value",
          "value": "LA toxicity"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": null,
      "id": 230,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": null,
      "id": 231,
      "name": "label",
      "parent": 230,
      "props": {
        "children": {
          "type": "value",
          "value": "Delirium"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": null,
      "id": 232,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": null,
      "id": 233,
      "name": "label",
      "parent": 232,
      "props": {
        "children": {
          "type": "value",
          "value": "Hypoglycemia"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": null,
      "id": 234,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": null,
      "id": 235,
      "name": "label",
      "parent": 234,
      "props": {
        "children": {
          "type": "value",
          "value": "Electolyte / Acid-base imbalance"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": null,
      "id": 236,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": null,
      "id": 237,
      "name": "label",
      "parent": 236,
      "props": {
        "children": {
          "type": "code",
          "value": "`Hypothermia (Temp < 36 oC)`"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": null,
      "id": 238,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/13\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": null,
      "id": 239,
      "name": "label",
      "parent": 238,
      "props": {
        "children": {
          "type": "code",
          "value": "`Fever (Temp > 38 oC)`"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": null,
      "id": 240,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": null,
      "id": 241,
      "name": "label",
      "parent": 240,
      "props": {
        "children": {
          "type": "value",
          "value": "Transfusion reaction"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": null,
      "id": 242,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/15\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": null,
      "id": 243,
      "name": "label",
      "parent": 242,
      "props": {
        "children": {
          "type": "value",
          "value": "Coagulopathy"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": null,
      "id": 244,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"15/16\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": null,
      "id": 245,
      "name": "label",
      "parent": 244,
      "props": {
        "children": {
          "type": "value",
          "value": "Massive blood loss"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": null,
      "id": 246,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"16/17\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": null,
      "id": 247,
      "name": "label",
      "parent": 246,
      "props": {
        "children": {
          "type": "value",
          "value": "Allergic reaction"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": null,
      "id": 248,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"17/18\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": null,
      "id": 249,
      "name": "label",
      "parent": 248,
      "props": {
        "children": {
          "type": "value",
          "value": "Pain"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": null,
      "id": 250,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": null,
      "id": 251,
      "name": "div",
      "parent": 250,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"10px\"}"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 252,
      "name": "Checkbox",
      "parent": 251,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1AwarenessUnderGA === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1AwarenessUnderGA"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 253,
      "name": "Checkbox",
      "parent": 251,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2AwarenessUnderGA === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2AwarenessUnderGA"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 254,
      "name": "Checkbox",
      "parent": 251,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3AwarenessUnderGA === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3AwarenessUnderGA"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 255,
      "name": "Checkbox",
      "parent": 251,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4AwarenessUnderGA === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4AwarenessUnderGA"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": null,
      "id": 256,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": null,
      "id": 257,
      "name": "div",
      "parent": 256,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 258,
      "name": "Checkbox",
      "parent": 257,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1Highblock === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1Highblock"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 259,
      "name": "Checkbox",
      "parent": 257,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2Highblock === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2Highblock"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 260,
      "name": "Checkbox",
      "parent": 257,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3Highblock === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3Highblock"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 260,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 261,
      "name": "Checkbox",
      "parent": 257,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4Highblock === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4Highblock"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 261,
      "void": false
    },
    {
      "from": null,
      "id": 262,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 262,
      "void": false
    },
    {
      "from": null,
      "id": 263,
      "name": "div",
      "parent": 262,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 264,
      "name": "Checkbox",
      "parent": 263,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1PostDuralPunctureHeadache === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1PostDuralPunctureHeadache"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 264,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 265,
      "name": "Checkbox",
      "parent": 263,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2PostDuralPunctureHeadache === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2PostDuralPunctureHeadache"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 266,
      "name": "Checkbox",
      "parent": 263,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3PostDuralPunctureHeadache === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3PostDuralPunctureHeadache"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 267,
      "name": "Checkbox",
      "parent": 263,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4PostDuralPunctureHeadache === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4PostDuralPunctureHeadache"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": null,
      "id": 268,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": null,
      "id": 269,
      "name": "div",
      "parent": 268,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 270,
      "name": "Checkbox",
      "parent": 269,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1PeripheralNerveInjury === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1PeripheralNerveInjury"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 271,
      "name": "Checkbox",
      "parent": 269,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2PeripheralNerveInjury === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2PeripheralNerveInjury"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 272,
      "name": "Checkbox",
      "parent": 269,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3PeripheralNerveInjury === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3PeripheralNerveInjury"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 273,
      "name": "Checkbox",
      "parent": 269,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4PeripheralNerveInjury === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4PeripheralNerveInjury"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": null,
      "id": 274,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": null,
      "id": 275,
      "name": "div",
      "parent": 274,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 276,
      "name": "Checkbox",
      "parent": 275,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1BackPain === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1BackPain"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 277,
      "name": "Checkbox",
      "parent": 275,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2BackPain === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2BackPain"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 278,
      "name": "Checkbox",
      "parent": 275,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3BackPain === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3BackPain"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 278,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 279,
      "name": "Checkbox",
      "parent": 275,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4BackPain === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4BackPain"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": null,
      "id": 280,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": null,
      "id": 281,
      "name": "div",
      "parent": 280,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 282,
      "name": "Checkbox",
      "parent": 281,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1Convulsion === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1Convulsion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 283,
      "name": "Checkbox",
      "parent": 281,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2Convulsion === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2Convulsion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 283,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 284,
      "name": "Checkbox",
      "parent": 281,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3Convulsion === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3Convulsion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 285,
      "name": "Checkbox",
      "parent": 281,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4Convulsion === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4Convulsion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 285,
      "void": false
    },
    {
      "from": null,
      "id": 286,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": null,
      "id": 287,
      "name": "div",
      "parent": 286,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 288,
      "name": "Checkbox",
      "parent": 287,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1LAToxicity === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1LAToxicity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 289,
      "name": "Checkbox",
      "parent": 287,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2LAToxicity === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2LAToxicity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 290,
      "name": "Checkbox",
      "parent": 287,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3LAToxicity === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3LAToxicity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 291,
      "name": "Checkbox",
      "parent": 287,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4LAToxicity === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4LAToxicity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": null,
      "id": 292,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": null,
      "id": 293,
      "name": "div",
      "parent": 292,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 294,
      "name": "Checkbox",
      "parent": 293,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1Delirium === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1Delirium"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 294,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 295,
      "name": "Checkbox",
      "parent": 293,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2Delirium === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2Delirium"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 295,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 296,
      "name": "Checkbox",
      "parent": 293,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3Delirium === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3Delirium"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 296,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 297,
      "name": "Checkbox",
      "parent": 293,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4Delirium === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4Delirium"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": null,
      "id": 298,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 298,
      "void": false
    },
    {
      "from": null,
      "id": 299,
      "name": "div",
      "parent": 298,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 300,
      "name": "Checkbox",
      "parent": 299,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1Hypoglycemia === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1Hypoglycemia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 301,
      "name": "Checkbox",
      "parent": 299,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2Hypoglycemia === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2Hypoglycemia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 301,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 302,
      "name": "Checkbox",
      "parent": 299,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3Hypoglycemia === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3Hypoglycemia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 302,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 303,
      "name": "Checkbox",
      "parent": 299,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4Hypoglycemia === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4Hypoglycemia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 303,
      "void": false
    },
    {
      "from": null,
      "id": 304,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 304,
      "void": false
    },
    {
      "from": null,
      "id": 305,
      "name": "div",
      "parent": 304,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 305,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 306,
      "name": "Checkbox",
      "parent": 305,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1ElectolyteAcidBaseImbalance === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1ElectolyteAcidBaseImbalance"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 306,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 307,
      "name": "Checkbox",
      "parent": 305,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2ElectolyteAcidBaseImbalance === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2ElectolyteAcidBaseImbalance"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 307,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 308,
      "name": "Checkbox",
      "parent": 305,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3ElectolyteAcidBaseImbalance === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3ElectolyteAcidBaseImbalance"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 308,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 309,
      "name": "Checkbox",
      "parent": 305,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4ElectolyteAcidBaseImbalance === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4ElectolyteAcidBaseImbalance"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 309,
      "void": false
    },
    {
      "from": null,
      "id": 310,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 310,
      "void": false
    },
    {
      "from": null,
      "id": 311,
      "name": "div",
      "parent": 310,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 311,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 312,
      "name": "Checkbox",
      "parent": 311,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1Hypothermia === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1Hypothermia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 312,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 313,
      "name": "Checkbox",
      "parent": 311,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2Hypothermia === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2Hypothermia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 313,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 314,
      "name": "Checkbox",
      "parent": 311,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3Hypothermia === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3Hypothermia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 314,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 315,
      "name": "Checkbox",
      "parent": 311,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4Hypothermia === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4Hypothermia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 315,
      "void": false
    },
    {
      "from": null,
      "id": 316,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/13\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 316,
      "void": false
    },
    {
      "from": null,
      "id": 317,
      "name": "div",
      "parent": 316,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 317,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 318,
      "name": "Checkbox",
      "parent": 317,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1Fever === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1Fever"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 318,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 319,
      "name": "Checkbox",
      "parent": 317,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2Fever === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2Fever"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 319,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 320,
      "name": "Checkbox",
      "parent": 317,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3Fever === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3Fever"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 320,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 321,
      "name": "Checkbox",
      "parent": 317,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4Fever === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4Fever"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 321,
      "void": false
    },
    {
      "from": null,
      "id": 322,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 322,
      "void": false
    },
    {
      "from": null,
      "id": 323,
      "name": "div",
      "parent": 322,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 323,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 324,
      "name": "Checkbox",
      "parent": 323,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1TransfusionReaction === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1TransfusionReaction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 324,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 325,
      "name": "Checkbox",
      "parent": 323,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2TransfusionReaction === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2TransfusionReaction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 325,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 326,
      "name": "Checkbox",
      "parent": 323,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3TransfusionReaction === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3TransfusionReaction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 326,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 327,
      "name": "Checkbox",
      "parent": 323,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4TransfusionReaction === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4TransfusionReaction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 327,
      "void": false
    },
    {
      "from": null,
      "id": 328,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/15\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 328,
      "void": false
    },
    {
      "from": null,
      "id": 329,
      "name": "div",
      "parent": 328,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 329,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 330,
      "name": "Checkbox",
      "parent": 329,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1CoagulopathyMI === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1CoagulopathyMI"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 330,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 331,
      "name": "Checkbox",
      "parent": 329,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2CoagulopathyMI === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2CoagulopathyMI"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 331,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 332,
      "name": "Checkbox",
      "parent": 329,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3CoagulopathyMI === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3CoagulopathyMI"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 332,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 333,
      "name": "Checkbox",
      "parent": 329,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4CoagulopathyMI === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4CoagulopathyMI"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 333,
      "void": false
    },
    {
      "from": null,
      "id": 334,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"15/16\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 334,
      "void": false
    },
    {
      "from": null,
      "id": 335,
      "name": "div",
      "parent": 334,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 335,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 336,
      "name": "Checkbox",
      "parent": 335,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1MassiveBloodLoss === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1MassiveBloodLoss"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 336,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 337,
      "name": "Checkbox",
      "parent": 335,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2MassiveBloodLoss === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2MassiveBloodLoss"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 337,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 338,
      "name": "Checkbox",
      "parent": 335,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3MassiveBloodLoss === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3MassiveBloodLoss"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 338,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 339,
      "name": "Checkbox",
      "parent": 335,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4MassiveBloodLoss === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4MassiveBloodLoss"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 339,
      "void": false
    },
    {
      "from": null,
      "id": 340,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"16/17\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 340,
      "void": false
    },
    {
      "from": null,
      "id": 341,
      "name": "div",
      "parent": 340,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 341,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 342,
      "name": "Checkbox",
      "parent": 341,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1AllergicReaction === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1AllergicReaction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 342,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 343,
      "name": "Checkbox",
      "parent": 341,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2AllergicReaction === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2AllergicReaction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 343,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 344,
      "name": "Checkbox",
      "parent": 341,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3AllergicReaction === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3AllergicReaction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 344,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 345,
      "name": "Checkbox",
      "parent": 341,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4AllergicReaction === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4AllergicReaction"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 345,
      "void": false
    },
    {
      "from": null,
      "id": 346,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"17/18\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 346,
      "void": false
    },
    {
      "from": null,
      "id": 347,
      "name": "div",
      "parent": 346,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 347,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 348,
      "name": "Checkbox",
      "parent": 347,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1Pain === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1Pain"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 348,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 349,
      "name": "Checkbox",
      "parent": 347,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2Pain === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2Pain"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 349,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 350,
      "name": "Checkbox",
      "parent": 347,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3Pain === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3Pain"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 350,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 351,
      "name": "Checkbox",
      "parent": 347,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4Pain === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4Pain"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 351,
      "void": false
    },
    {
      "from": null,
      "id": 352,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"18/19\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 352,
      "void": false
    },
    {
      "from": null,
      "id": 353,
      "name": "label",
      "parent": 352,
      "props": {
        "children": {
          "type": "value",
          "value": "Burn"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 353,
      "void": false
    },
    {
      "from": null,
      "id": 354,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"19/20\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 354,
      "void": false
    },
    {
      "from": null,
      "id": 355,
      "name": "div",
      "parent": 354,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(2, 1fr)\",\"gridTemplateColumns\":\"repeat(1, 1fr)\"}"
        }
      },
      "seq": 355,
      "void": false
    },
    {
      "from": null,
      "id": 356,
      "name": "div",
      "parent": 355,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 356,
      "void": false
    },
    {
      "from": null,
      "id": 357,
      "name": "label",
      "parent": 356,
      "props": {
        "children": {
          "type": "value",
          "value": "Shivering"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 357,
      "void": false
    },
    {
      "from": null,
      "id": 360,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"20/21\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 360,
      "void": false
    },
    {
      "from": null,
      "id": 361,
      "name": "label",
      "parent": 360,
      "props": {
        "children": {
          "type": "value",
          "value": "Nausea & vomiting"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 361,
      "void": false
    },
    {
      "from": null,
      "id": 362,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"21/22\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 362,
      "void": false
    },
    {
      "from": null,
      "id": 363,
      "name": "label",
      "parent": 362,
      "props": {
        "children": {
          "type": "value",
          "value": "Urine retention"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 363,
      "void": false
    },
    {
      "from": null,
      "id": 364,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"22/23\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 364,
      "void": false
    },
    {
      "from": null,
      "id": 365,
      "name": "label",
      "parent": 364,
      "props": {
        "children": {
          "type": "value",
          "value": "Itching"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 365,
      "void": false
    },
    {
      "from": null,
      "id": 366,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"23/24\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 366,
      "void": false
    },
    {
      "from": null,
      "id": 367,
      "name": "label",
      "parent": 366,
      "props": {
        "children": {
          "type": "value",
          "value": "Drug error / Human error"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 367,
      "void": false
    },
    {
      "from": null,
      "id": 368,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"24/25\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 368,
      "void": false
    },
    {
      "from": null,
      "id": 369,
      "name": "label",
      "parent": 368,
      "props": {
        "children": {
          "type": "value",
          "value": "Pressure sore"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 369,
      "void": false
    },
    {
      "from": null,
      "id": 370,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"25/26\", gridColumn: \"1/7\",marginTop:\"5px\"}"
        }
      },
      "seq": 370,
      "void": false
    },
    {
      "from": null,
      "id": 371,
      "name": "label",
      "parent": 370,
      "props": {
        "children": {
          "type": "value",
          "value": "Re-operation"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 371,
      "void": false
    },
    {
      "from": null,
      "id": 372,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"18/19\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 372,
      "void": false
    },
    {
      "from": null,
      "id": 373,
      "name": "div",
      "parent": 372,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 373,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 374,
      "name": "Checkbox",
      "parent": 373,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1Burn === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1Burn"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 374,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 375,
      "name": "Checkbox",
      "parent": 373,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2Burn === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2Burn"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 375,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 376,
      "name": "Checkbox",
      "parent": 373,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3Burn === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3Burn"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 376,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 377,
      "name": "Checkbox",
      "parent": 373,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4Burn === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4Burn"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 377,
      "void": false
    },
    {
      "from": null,
      "id": 378,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"19/20\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 378,
      "void": false
    },
    {
      "from": null,
      "id": 379,
      "name": "div",
      "parent": 378,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 379,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 380,
      "name": "Checkbox",
      "parent": 379,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1Shivering === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1Shivering"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 380,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 381,
      "name": "Checkbox",
      "parent": 379,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2Shivering === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2Shivering"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 381,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 382,
      "name": "Checkbox",
      "parent": 379,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3Shivering === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3Shivering"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 382,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 383,
      "name": "Checkbox",
      "parent": 379,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4Shivering === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4Shivering"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 383,
      "void": false
    },
    {
      "from": null,
      "id": 384,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"20/21\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 384,
      "void": false
    },
    {
      "from": null,
      "id": 385,
      "name": "div",
      "parent": 384,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 385,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 386,
      "name": "Checkbox",
      "parent": 385,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1NauseaVomiting === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1NauseaVomiting"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 386,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 387,
      "name": "Checkbox",
      "parent": 385,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2NauseaVomiting === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2NauseaVomiting"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 387,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 388,
      "name": "Checkbox",
      "parent": 385,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3NauseaVomiting === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3NauseaVomiting"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 388,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 389,
      "name": "Checkbox",
      "parent": 385,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4NauseaVomiting === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4NauseaVomiting"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 389,
      "void": false
    },
    {
      "from": null,
      "id": 390,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"21/22\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 390,
      "void": false
    },
    {
      "from": null,
      "id": 391,
      "name": "div",
      "parent": 390,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 391,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 392,
      "name": "Checkbox",
      "parent": 391,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1UrineRetention === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1UrineRetention"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 392,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 393,
      "name": "Checkbox",
      "parent": 391,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2UrineRetention === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2UrineRetention"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 393,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 394,
      "name": "Checkbox",
      "parent": 391,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3UrineRetention === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3UrineRetention"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 394,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 395,
      "name": "Checkbox",
      "parent": 391,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4UrineRetention === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4UrineRetention"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 395,
      "void": false
    },
    {
      "from": null,
      "id": 396,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"22/23\", gridColumn: \"7/11\" }"
        }
      },
      "seq": 396,
      "void": false
    },
    {
      "from": null,
      "id": 397,
      "name": "div",
      "parent": 396,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 397,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 398,
      "name": "Checkbox",
      "parent": 397,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1Itching === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1Itching"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 398,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 399,
      "name": "Checkbox",
      "parent": 397,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2Itching === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2Itching"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 399,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 400,
      "name": "Checkbox",
      "parent": 397,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3Itching === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3Itching"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 400,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 401,
      "name": "Checkbox",
      "parent": 397,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4Itching === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4Itching"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 401,
      "void": false
    },
    {
      "from": null,
      "id": 402,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"23/24\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 402,
      "void": false
    },
    {
      "from": null,
      "id": 403,
      "name": "div",
      "parent": 402,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 403,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 404,
      "name": "Checkbox",
      "parent": 403,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1DrugErrorHumanError === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1DrugErrorHumanError"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 404,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 405,
      "name": "Checkbox",
      "parent": 403,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2DrugErrorHumanError === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2DrugErrorHumanError"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 405,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 406,
      "name": "Checkbox",
      "parent": 403,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3DrugErrorHumanError === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3DrugErrorHumanError"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 406,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 407,
      "name": "Checkbox",
      "parent": 403,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4DrugErrorHumanError === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4DrugErrorHumanError"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 407,
      "void": false
    },
    {
      "from": null,
      "id": 408,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"24/25\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 408,
      "void": false
    },
    {
      "from": null,
      "id": 409,
      "name": "div",
      "parent": 408,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 409,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 410,
      "name": "Checkbox",
      "parent": 409,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1PressureSore === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1PressureSore"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 410,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 411,
      "name": "Checkbox",
      "parent": 409,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2PressureSore === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2PressureSore"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 411,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 412,
      "name": "Checkbox",
      "parent": 409,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3PressureSore === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3PressureSore"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 412,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 413,
      "name": "Checkbox",
      "parent": 409,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4PressureSore === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4PressureSore"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 413,
      "void": false
    },
    {
      "from": null,
      "id": 414,
      "name": "div",
      "parent": 215,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"25/26\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 414,
      "void": false
    },
    {
      "from": null,
      "id": 415,
      "name": "div",
      "parent": 414,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 415,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 416,
      "name": "Checkbox",
      "parent": 415,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1ReOperation === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1ReOperation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 416,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 417,
      "name": "Checkbox",
      "parent": 415,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2ReOperation === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2ReOperation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 417,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 418,
      "name": "Checkbox",
      "parent": 415,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3ReOperation === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3ReOperation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 418,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 419,
      "name": "Checkbox",
      "parent": 415,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4ReOperation === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4ReOperation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 419,
      "void": false
    },
    {
      "from": null,
      "id": 420,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"25/26\", gridColumn: \"1/2\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 420,
      "void": false
    },
    {
      "from": null,
      "id": 421,
      "label": "divgrid",
      "name": "div",
      "parent": 420,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(10, 1fr)\"}"
        }
      },
      "seq": 421,
      "void": false
    },
    {
      "from": null,
      "id": 422,
      "name": "div",
      "parent": 421,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/7\" }"
        }
      },
      "seq": 422,
      "void": false
    },
    {
      "from": null,
      "id": 423,
      "name": "label",
      "parent": 422,
      "props": {
        "children": {
          "type": "value",
          "value": "Other specify"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 423,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 424,
      "name": "Input",
      "parent": 422,
      "props": {
        "name": {
          "type": "value",
          "value": "OtherSpecifyRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"10px\",marginLeft:\"10px\",width:\"70%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.anesComplication?.OtherSpecifyRemark"
        }
      },
      "seq": 424,
      "void": false
    },
    {
      "from": null,
      "id": 425,
      "name": "div",
      "parent": 421,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 425,
      "void": false
    },
    {
      "from": null,
      "id": 426,
      "name": "div",
      "parent": 425,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"15px\"}"
        }
      },
      "seq": 426,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 427,
      "name": "Checkbox",
      "parent": 426,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1OtherSpecify === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1OtherSpecify"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 427,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 428,
      "name": "Checkbox",
      "parent": 426,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2OtherSpecify === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2OtherSpecify"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 428,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 429,
      "name": "Checkbox",
      "parent": 426,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3OtherSpecify === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3OtherSpecify"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 429,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 430,
      "name": "Checkbox",
      "parent": 426,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4OtherSpecify === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4OtherSpecify"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 430,
      "void": false
    },
    {
      "from": null,
      "id": 431,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"25/26\", gridColumn: \"2/3\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 431,
      "void": false
    },
    {
      "from": null,
      "id": 432,
      "name": "div",
      "parent": 431,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(10, 1fr)\"}"
        }
      },
      "seq": 432,
      "void": false
    },
    {
      "from": null,
      "id": 433,
      "name": "div",
      "parent": 432,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/7\" }"
        }
      },
      "seq": 433,
      "void": false
    },
    {
      "from": null,
      "id": 434,
      "name": "label",
      "parent": 433,
      "props": {
        "children": {
          "type": "value",
          "value": "Wound"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 434,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 435,
      "name": "Input",
      "parent": 433,
      "props": {
        "name": {
          "type": "value",
          "value": "WoundRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"10px\",marginLeft:\"10px\",width:\"70%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.anesComplication?.WoundRemark"
        }
      },
      "seq": 435,
      "void": false
    },
    {
      "from": null,
      "id": 436,
      "name": "div",
      "parent": 432,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 436,
      "void": false
    },
    {
      "from": null,
      "id": 437,
      "name": "div",
      "parent": 436,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"15px\"}"
        }
      },
      "seq": 437,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 438,
      "name": "Checkbox",
      "parent": 437,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is1Wound === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "Is1Wound"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 438,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 439,
      "name": "Checkbox",
      "parent": 437,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is2Wound === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "Is2Wound"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 439,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 440,
      "name": "Checkbox",
      "parent": 437,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is3Wound === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "Is3Wound"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 440,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 441,
      "name": "Checkbox",
      "parent": 437,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.Is4Wound === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "Is4Wound"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 441,
      "void": false
    },
    {
      "from": null,
      "id": 442,
      "label": "divgrid",
      "name": "div",
      "parent": 453,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(10, 1fr)\"}"
        }
      },
      "seq": 442,
      "void": false
    },
    {
      "from": null,
      "id": 443,
      "name": "div",
      "parent": 442,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/7\"}"
        }
      },
      "seq": 443,
      "void": false
    },
    {
      "from": null,
      "id": 444,
      "name": "div",
      "parent": 443,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/7\" }"
        }
      },
      "seq": 444,
      "void": false
    },
    {
      "from": null,
      "id": 445,
      "name": "label",
      "parent": 444,
      "props": {
        "children": {
          "type": "value",
          "value": "None"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 445,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 446,
      "name": "Input",
      "parent": 444,
      "props": {
        "name": {
          "type": "value",
          "value": "ComplicationNoneRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"10px\",marginLeft:\"70px\",width:\"70%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.anesComplication?.ComplicationNoneRemark"
        }
      },
      "seq": 446,
      "void": false
    },
    {
      "from": null,
      "id": 447,
      "name": "div",
      "parent": 442,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 447,
      "void": false
    },
    {
      "from": null,
      "id": 448,
      "name": "div",
      "parent": 447,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"15px\"}"
        }
      },
      "seq": 448,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 449,
      "name": "Checkbox",
      "parent": 448,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.IS1ComplicationNone === true"
        },
        "label": {
          "type": "value",
          "value": "1"
        },
        "name": {
          "type": "value",
          "value": "IS1ComplicationNone"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 449,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 450,
      "name": "Checkbox",
      "parent": 448,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.IS2ComplicationNone === true"
        },
        "label": {
          "type": "value",
          "value": "2"
        },
        "name": {
          "type": "value",
          "value": "IS2ComplicationNone"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 450,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 451,
      "name": "Checkbox",
      "parent": 448,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.IS3ComplicationNone === true"
        },
        "label": {
          "type": "value",
          "value": "3"
        },
        "name": {
          "type": "value",
          "value": "IS3ComplicationNone"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 451,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 452,
      "name": "Checkbox",
      "parent": 448,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.IS4ComplicationNone === true"
        },
        "label": {
          "type": "value",
          "value": "4"
        },
        "name": {
          "type": "value",
          "value": "IS4ComplicationNone"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"40px\"}"
        }
      },
      "seq": 452,
      "void": false
    },
    {
      "from": null,
      "id": 453,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": " {border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 453,
      "void": false
    },
    {
      "from": null,
      "id": 454,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"60px\"}"
        }
      },
      "seq": 454,
      "void": false
    },
    {
      "from": null,
      "id": 455,
      "label": "divgrid",
      "name": "div",
      "parent": 454,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(2, 1fr)\"}"
        }
      },
      "seq": 455,
      "void": false
    },
    {
      "from": null,
      "id": 456,
      "name": "div",
      "parent": 455,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/2\", display:\"flex\",border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 456,
      "void": false
    },
    {
      "from": null,
      "id": 457,
      "name": "div",
      "parent": 455,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"2/2\", display:\"flex\",border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 457,
      "void": false
    },
    {
      "from": null,
      "id": 458,
      "name": "label",
      "parent": 456,
      "props": {
        "children": {
          "type": "value",
          "value": "Direct transferred to"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"20px\",\"fontWeight\":\"bold\",\"fontSize\":\"16px\"}"
        }
      },
      "seq": 458,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 459,
      "name": "Radio",
      "parent": 456,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.IsDirectTransferred === \"Ward\""
        },
        "label": {
          "type": "value",
          "value": "Ward"
        },
        "name": {
          "type": "value",
          "value": "IsDirectTransferred"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"20px\",\"fontSize\":\"16px\"}"
        },
        "value": {
          "type": "value",
          "value": "Ward"
        }
      },
      "seq": 459,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 460,
      "name": "Radio",
      "parent": 456,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.IsDirectTransferred === \"ICU\""
        },
        "label": {
          "type": "value",
          "value": "ICU"
        },
        "name": {
          "type": "value",
          "value": "IsDirectTransferred"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"20px\",\"fontSize\":\"16px\"}"
        },
        "value": {
          "type": "value",
          "value": "ICU"
        }
      },
      "seq": 460,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 461,
      "name": "Radio",
      "parent": 456,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.anesComplication?.IsDirectTransferred === \"Other\""
        },
        "label": {
          "type": "value",
          "value": "Other"
        },
        "name": {
          "type": "value",
          "value": "IsDirectTransferred"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"20px\",\"fontSize\":\"16px\"}"
        },
        "value": {
          "type": "value",
          "value": "Other"
        }
      },
      "seq": 461,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 462,
      "name": "Input",
      "parent": 456,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.anesComplication?.IsDirectTransferred !== \"Other\""
        },
        "name": {
          "type": "value",
          "value": "IsDirectTransferredToOtherRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",padding:\"5px\",width:\"300px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.anesComplication?.IsDirectTransferred === \"Other\" ? props.anesComplication?.IsDirectTransferredToOtherRemark : \"\""
        }
      },
      "seq": 462,
      "void": false
    },
    {
      "from": null,
      "id": 463,
      "name": "label",
      "parent": 457,
      "props": {
        "children": {
          "type": "value",
          "value": "PACU duration"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"5px\",paddingTop:\"20px\",\"fontWeight\":\"bold\",\"fontSize\":\"16px\"}"
        }
      },
      "seq": 463,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 464,
      "name": "Input",
      "parent": 457,
      "props": {
        "name": {
          "type": "value",
          "value": "PACUDurationTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{padding:\"5px\",width:\"120px\"}"
        },
        "type": {
          "type": "value",
          "value": "time"
        },
        "value": {
          "type": "code",
          "value": "props.anesComplication?.PACUDurationTime"
        }
      },
      "seq": 464,
      "void": false
    },
    {
      "from": null,
      "id": 465,
      "name": "label",
      "parent": 457,
      "props": {
        "children": {
          "type": "value",
          "value": "min"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",paddingTop:\"20px\",\"fontSize\":\"16px\"}"
        }
      },
      "seq": 465,
      "void": false
    },
    {
      "from": null,
      "id": 466,
      "name": "label",
      "parent": 457,
      "props": {
        "children": {
          "type": "value",
          "value": "กรณีมากกว่า 60 นาที ระบุเหตุผล"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"10px\",paddingTop:\"20px\",\"fontSize\":\"16px\"}"
        }
      },
      "seq": 466,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 467,
      "name": "Input",
      "parent": 457,
      "props": {
        "name": {
          "type": "value",
          "value": "PACUDurationRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{padding:\"5px\",width:\"280px\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.anesComplication?.PACUDurationRemark"
        }
      },
      "seq": 467,
      "void": false
    },
    {
      "from": null,
      "id": 470,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"20px\"}"
        }
      },
      "seq": 470,
      "void": false
    },
    {
      "from": null,
      "id": 471,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"20px\", marginRight: \"20px\", display: props.buttonStatus === \"CONFIRMED\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 471,
      "void": false
    },
    {
      "from": null,
      "id": 472,
      "name": "label",
      "parent": 470,
      "props": {
        "children": {
          "type": "value",
          "value": "Complication"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",\"fontWeight\":\"bold\",\"fontSize\":\"17px\"}"
        }
      },
      "seq": 472,
      "void": false
    },
    {
      "from": null,
      "id": 473,
      "name": "label",
      "parent": 470,
      "props": {
        "children": {
          "type": "value",
          "value": "1 = Intraoperative | 2 = PACU  | 3 = Postoperative 24 hr. | 4 = Postoperative 48 ht."
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"20px\",\"fontSize\":\"17px\"}"
        }
      },
      "seq": 473,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_AnesComplication01",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
