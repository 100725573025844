import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardDoctorFeeOrderAndCompensationUX = (props: any) => {
    return(
      <div
        style={{ height: "80vh" }}>
        <div
          style={{ width: "100%", height: "100%", display: "grid", position: "relative", gridTemplateRows: "repeat(50, 1fr)", gridTemplateColumns: "repeat(50, 1fr)" }}>
          <div
            style={{ gridRow: "2/4", gridColumn: "2/7",  }}>
            รายการค่าใช้จ่าย
          </div>
          <div
            style={{ position: "relative", gridRow: "2/4", gridColumn: "8/41" }}>
            <Input
              fluid={true}
              icon="search"
              id="txt-searchDFbyText"
              onChange={props.onChangeSearchText}
              size="mini"
              value={props.searchText}>
            </Input>
            <div
              style={{ display: props.showSearchResult ? "block" : "none", border: "solid #cccccc 1px", width: "100%", position: "absolute", zIndex: 100, minHeight: "100px" , backgroundColor: "white", maxHeight: "300px", overflow: "auto" }}>
              {props.searchResult}
            </div>
          </div>
          <div
            style={{ gridRow: "2/4", gridColumn: "42/50",  }}>
            <Button
              color="blue"
              id="btn-searchDFList"
              onClick={props.open}
              size="mini"
              style={{ width: "100%" }}>
              ค้นหา
            </Button>
          </div>
          <div
            style={{ gridRow: "6/32", gridColumn: "2/50",  }}>
            <Table
              className="card-doctor-fee-order-compensation"
              data={props.dfPreviewItems}
              headers="No,Service Code,Product Name,จำนวนสั่ง,ราคาสูงสุดที่สามารถระบุได้, ราคาต่อหน่วย,ค่าตอบแทน,เบิกได้,เบิกไม่ได้,ราคารวม,ผู้ได้รับค่าตอบแทน,วันที่ค่าใช้จ่าย,ผู้บันทึกข้อมูล,"
              id="tb-dfListCompensation"
              keys="no,service_code,doctor_fee_rule_display,quantity,price_compensation_max,price_unit,compensation_price,price_claimable,price_non_claimable,price_total,received_employee_display,perform_datetime,edited_by, menu"
              minRows="8"
              showPagination={false}
              style={{ height: "40vh", ...(props.tableStyle || {} ) }}
              widths="40">
            </Table>
          </div>
          <div
            style={{ gridRow: "32/32", gridColumn: "2/4",   display: "flex", alignItems: "center"}}>
            รวม
          </div>
          <div
            style={{ gridRow: "32/32", gridColumn: "5/13" }}>
            <Input
              readOnly={true}
              value={props.priceTotal}>
            </Input>
          </div>
          <div
            style={{ gridRow: "32/32", gridColumn: "14/16",   display: "flex", alignItems: "center"}}>
            เบิกได้
          </div>
          <div
            style={{ gridRow: "32/32", gridColumn: "17/25" }}>
            <Input
              readOnly={true}
              value={props.priceClaimable}>
            </Input>
          </div>
          <div
            style={{ gridRow: "32/32", gridColumn: "26/28",  display: "flex", alignItems: "center"}}>
            เบิกไม่ได้
          </div>
          <div
            style={{ gridRow: "32/32", gridColumn: "29/37" }}>
            <Input
              readonly={true}
              value={props.priceNonClaimable}>
            </Input>
          </div>
          <div
            style={{ gridRow: "32/32", gridColumn: "47/50",  }}>
            <Button
              color="green"
              id="btn-saveDF"
              loading={props.loadingSave}
              onClick={props.onSaveDF}
              size="mini"
              style={{ width: "100%" }}>
              {props.button_save}
            </Button>
          </div>
        </div>
      </div>
    )
}

export default CardDoctorFeeOrderAndCompensationUX

export const screenPropsDefault = {"showSearchResult":false}

/* Date Time : Wed Jan 18 2023 11:02:53 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ height: \"80vh\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\", display: \"grid\", position: \"relative\", gridTemplateRows: \"repeat(50, 1fr)\", gridTemplateColumns: \"repeat(50, 1fr)\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการค่าใช้จ่าย"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"2/7\",  }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ position: \"relative\", gridRow: \"2/4\", gridColumn: \"8/41\" }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"42/50\",  }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/32\", gridColumn: \"2/50\",  }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รวม"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"32/32\", gridColumn: \"2/4\",   display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"32/32\", gridColumn: \"5/13\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกได้"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"32/32\", gridColumn: \"14/16\",   display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"32/32\", gridColumn: \"17/25\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกไม่ได้"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"32/32\", gridColumn: \"26/28\",  display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"32/32\", gridColumn: \"29/37\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"32/32\", gridColumn: \"47/50\",  }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Input",
      "parent": 4,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "txt-searchDFbyText"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSearchText"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "value": {
          "type": "code",
          "value": "props.searchText"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "btn-searchDFList"
        },
        "onClick": {
          "type": "code",
          "value": "props.open"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.button_save"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "id": {
          "type": "value",
          "value": "btn-saveDF"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingSave"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSaveDF"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 17,
      "name": "Table",
      "parent": 6,
      "props": {
        "className": {
          "type": "value",
          "value": "card-doctor-fee-order-compensation"
        },
        "data": {
          "type": "code",
          "value": "props.dfPreviewItems"
        },
        "headers": {
          "type": "value",
          "value": "No,Service Code,Product Name,จำนวนสั่ง,ราคาสูงสุดที่สามารถระบุได้, ราคาต่อหน่วย,ค่าตอบแทน,เบิกได้,เบิกไม่ได้,ราคารวม,ผู้ได้รับค่าตอบแทน,วันที่ค่าใช้จ่าย,ผู้บันทึกข้อมูล,"
        },
        "id": {
          "type": "value",
          "value": "tb-dfListCompensation"
        },
        "keys": {
          "type": "value",
          "value": "no,service_code,doctor_fee_rule_display,quantity,price_compensation_max,price_unit,compensation_price,price_claimable,price_non_claimable,price_total,received_employee_display,perform_datetime,edited_by, menu"
        },
        "minRows": {
          "type": "value",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"40vh\", ...(props.tableStyle || {} ) }"
        },
        "widths": {
          "type": "value",
          "value": "40"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchResult"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.showSearchResult ? \"block\" : \"none\", border: \"solid #cccccc 1px\", width: \"100%\", position: \"absolute\", zIndex: 100, minHeight: \"100px\" , backgroundColor: \"white\", maxHeight: \"300px\", overflow: \"auto\" }"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Input",
      "parent": 8,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.priceTotal"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Input",
      "parent": 10,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.priceClaimable"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Input",
      "parent": 12,
      "props": {
        "readonly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.priceNonClaimable"
        }
      },
      "seq": 21,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDoctorFeeOrderAndCompensationUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "showSearchResult": false
  },
  "width": 80
}

*********************************************************************************** */
