import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Radio,
  Input,
  Dropdown,
  TextArea
} from 'semantic-ui-react'

const CardPreAssessmentGeneralUX = (props: any) => {
    return(
      <div>
        <div
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px" }}>
          ประเมินผู้ป่วยแรกรับ
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "13%", fontWeight: "bold" }}>
              
              <div>
                รับใหม่จาก :
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.come_from === "OPD"}
                label="OPD"
                name="come_from"
                onChange={props.onChangeData}
                style={{ width: "6%", display: "flex", alignItems: "center"  }}
                value="OPD">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.come_from=== "ER"}
                label="ER"
                name="come_from"
                onChange={props.onChangeData}
                style={{ width: "6%", display: "flex", alignItems: "center"  }}
                value="ER">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.come_from=== "Refer-in"}
                label="Refer-in"
                name="come_from"
                onChange={props.onChangeData}
                style={{ width: "9%", display: "flex", alignItems: "center"  }}
                value="Refer-in">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.come_from=== "นัดมาผ่าตัด"}
                label="นัดมาผ่าตัด"
                name="come_from"
                onChange={props.onChangeData}
                style={{ width: "10%", display: "flex", alignItems: "center"  }}
                value="นัดมาผ่าตัด">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.come_from=== "อื่นๆ"}
                label="อื่นๆ โปรดระบุ"
                name="come_from"
                onChange={props.onChangeData}
                style={{ width: "10%", display: "flex", alignItems: "center"  }}
                value="อื่นๆ">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.come_from !== "อื่นๆ" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.come_from !== "อื่นๆ"}
                name="come_from_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.come_from=== "อื่นๆ"  ? props.PreAssessmentSequence?.come_from_remark || ""   : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "13%", fontWeight: "bold" }}>
              
              <div>
                สถานภาพผู้ป่วยแรกรับ :
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.commute_by === "เดินมา"}
                label="เดินมา"
                name="commute_by"
                onChange={props.onChangeData}
                style={{ width: "6%", display: "flex", alignItems: "center"  }}
                value="เดินมา">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.commute_by === "รถนั่ง"}
                label="รถนั่ง"
                name="commute_by"
                onChange={props.onChangeData}
                style={{ width: "6%", display: "flex", alignItems: "center"  }}
                value="รถนั่ง">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.commute_by === "รถนอน"}
                label="รถนอน"
                name="commute_by"
                onChange={props.onChangeData}
                style={{ width: "9%", display: "flex", alignItems: "center"  }}
                value="รถนอน">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.commute_by === "อื่นๆ"}
                label="อื่นๆ โปรดระบุ"
                name="commute_by"
                onChange={props.onChangeData}
                style={{ width: "10%", display: "flex", alignItems: "center"  }}
                value="อื่นๆ">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.commute_by !== "อื่นๆ" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.commute_by !== "อื่นๆ"}
                name="commute_by_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.commute_by === "อื่นๆ"  ? props.PreAssessmentSequence?.commute_by_remark || ""   : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "13%", fontWeight: "bold" }}>
              
              <div>
                นำส่งหอผู้ป่วย :
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.commute_with === "ญาติ"}
                label="ญาติ"
                name="commute_with"
                onChange={props.onChangeData}
                style={{ width: "6%", display: "flex", alignItems: "center"  }}
                value="ญาติ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.commute_with === "เวรเปล"}
                label="เวรเปล"
                name="commute_with"
                onChange={props.onChangeData}
                style={{ width: "6%", display: "flex", alignItems: "center"  }}
                value="เวรเปล">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.commute_with === "ผู้ป่วยมาเอง"}
                label="ผู้ป่วยมาเอง"
                name="commute_with"
                onChange={props.onChangeData}
                style={{ width: "9%", display: "flex", alignItems: "center"  }}
                value="ผู้ป่วยมาเอง">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.commute_with === "อื่นๆ"}
                label="อื่นๆ โปรดระบุ"
                name="commute_with"
                onChange={props.onChangeData}
                style={{ width: "10%", display: "flex", alignItems: "center"  }}
                value="อื่นๆ">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.commute_with !== "อื่นๆ" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.commute_with !== "อื่นๆ"}
                name="commute_with_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.commute_with === "อื่นๆ"  ? props.PreAssessmentSequence?.commute_with_remark || ""   : ""}>
              </Input>
            </div>
          </div>
        </div>
        <div
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px", margin: "30px 0px 20px" }}>
          ประวัติส่วนตัว / ครอบครัว
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "10%", fontWeight: "bold" }}>
              
              <div>
                สุขภาพ :
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.health=== "แข็งแรง"}
                label="แข็งแรง"
                name="health"
                onChange={props.onChangeData}
                style={{ width: "10%", display: "flex", alignItems: "center"  }}
                value="แข็งแรง">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.health=== "ไม่แข็งแรง"}
                label="ไม่แข็งแรง ระบุ"
                name="health"
                onChange={props.onChangeData}
                style={{ width: "10%", display: "flex", alignItems: "center"  }}
                value="ไม่แข็งแรง">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.health !==  "ไม่แข็งแรง" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.health !== "ไม่แข็งแรง" }
                name="health_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.health=== "ไม่แข็งแรง"  ? props.PreAssessmentSequence?.health_remark || ""   : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "33%", display: "flex" }}>
              
              <div
                style={{ width: "30%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                สถานภาพสมรส :
              </div>
              <div
                style={{ width: "55%", itemAlign: "left" }}>
                
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="marital_status"
                  onChange={props.onChangeData}
                  options={props.preAssessmentOptions?.maritalStatusOptions || []}
                  search={true}
                  selection={true}
                  value={props.PreAssessmentSequence?.marital_status}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ width: "33%", display: "flex"}}>
              
              <div
                style={{ width: "30%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                ระดับการศึกษา :
              </div>
              <div
                style={{ width: "55%", itemAlign: "left" }}>
                
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="education"
                  onChange={props.onChangeData}
                  options={props.preAssessmentOptions?.educationOptions || []}
                  search={true}
                  selection={true}
                  value={props.PreAssessmentSequence?.education}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ width: "33%", display: "flex"}}>
              
              <div
                style={{ width: "30%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                อาชีพ :
              </div>
              <div
                style={{ width: "55%", itemAlign: "left" }}>
                
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="occupation"
                  onChange={props.onChangeData}
                  options={props.preAssessmentOptions?.careerOptions || []}
                  selection={true}
                  value={props.PreAssessmentSequence?.occupation}>
                </Dropdown>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "33%", display: "flex" }}>
              
              <div
                style={{ width: "30%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                ศาสนา :
              </div>
              <div
                style={{ width: "55%", itemAlign: "left" }}>
                
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="religion"
                  onChange={props.onChangeData}
                  options={props.preAssessmentOptions?.religionOptions || []}
                  selection={true}
                  value={props.PreAssessmentSequence?.religion}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ width: "33%", display: "flex"}}>
              
              <div
                style={{ width: "30%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                เชื้อชาติ :
              </div>
              <div
                style={{ width: "55%", itemAlign: "left" }}>
                
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="race"
                  onChange={props.onChangeData}
                  options={props.preAssessmentOptions?.raceOptions || []}
                  selection={true}
                  value={props.PreAssessmentSequence?.race}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ width: "33%", display: "flex" }}>
              
              <div
                style={{ width: "30%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                สัญชาติ :
              </div>
              <div
                style={{ width: "55%", itemAlign: "left" }}>
                
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="nationality"
                  onChange={props.onChangeData}
                  options={props.preAssessmentOptions?.nationalityOptions || []}
                  selection={true}
                  value={props.PreAssessmentSequence?.nationality}>
                </Dropdown>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "33%", display: "flex" }}>
              
              <div
                style={{ width: "30%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                รายได้เฉลี่ยต่อเดือน :
              </div>
              <div
                style={{ width: "55%", itemAlign: "left" }}>
                
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="salary"
                  onChange={props.onChangeData}
                  options={props.preAssessmentOptions?.salaryOptions || []}
                  search={true}
                  selection={true}
                  value={props.PreAssessmentSequence?.salary || ""}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ width: "67%", display: "flex" }}>
              
              <div
                style={{ width: "23%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                ค่าใช้จ่ายในการรักษาพยาบาล :
              </div>
              <div
                style={{ width: "65%", display: "flex"}}>
                
                <Radio
                  checked={props.PreAssessmentSequence?.cost_problem=== "ไม่มีปัญหา"}
                  label="ไม่มีปัญหา"
                  name="cost_problem"
                  onChange={props.onChangeData}
                  style={{ width: "18%", display: "flex", alignItems: "center"  }}
                  value="ไม่มีปัญหา">
                </Radio>
                <Radio
                  checked={props.PreAssessmentSequence?.cost_problem=== "มีปัญหา"}
                  label="มีปัญหา โปรดระบุ"
                  name="cost_problem"
                  onChange={props.onChangeData}
                  style={{ width: "25%", display: "flex", alignItems: "center"  }}
                  value="มีปัญหา">
                </Radio>
                <div
                  style={{ display: props.PreAssessmentSequence?.cost_problem !== "มีปัญหา" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                  *
                </div>
                <Input
                  disabled={props.PreAssessmentSequence?.cost_problem !== "มีปัญหา"}
                  name="cost_problem_remark"
                  onChange={props.onChangeData}
                  style={{ width: "60%", margin: "0px 20px" }}
                  value={props.PreAssessmentSequence?.cost_problem === "มีปัญหา" ?  props.PreAssessmentSequence?.cost_problem_remark || ""   : ""}>
                </Input>
              </div>
            </div>
          </div>
          <div
            style={ { display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "10%", fontWeight: "bold" }}>
              
              <div>
                ผู้ให้ข้อมูล :
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Input
                name="info_given_by"
                onChange={props.onChangeData}
                style={{ width: "20%" }}
                value={props.PreAssessmentSequence?.info_given_by || ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "33%", display: "flex" }}>
              
              <div
                style={{ width: "30%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                ผู้ติดต่อ :
              </div>
              <Input
                name="contact_person"
                onChange={props.onChangeData}
                style={{ width: "55%", itemAlign: "left" }}
                value={props.PreAssessmentSequence?.contact_person || ""}>
              </Input>
            </div>
            <div
              style={{ width: "33%", display: "flex" }}>
              
              <div
                style={{ width: "30%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                เกี่ยวข้องเป็น :
              </div>
              <div
                style={{ width: "55%", itemAlign: "left" }}>
                
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="contact_person_relation"
                  onChange={props.onChangeData}
                  options={props.preAssessmentOptions?.contactOptions || []}
                  search={true}
                  selection={true}
                  value={props.PreAssessmentSequence?.contact_person_relation || ""}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ width: "33%", display: "flex" }}>
              
              <div
                style={{ width: "30%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                เบอร์โทรศัพท์ :
              </div>
              <Input
                name="phone"
                onChange={props.onChangePhone}
                style={{ width: "55%", itemAlign: "left" }}
                type="number"
                value={props.PreAssessmentSequence?.phone || ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "10%", fontWeight: "bold" }}>
              
              <div>
                ที่อยู่ที่ติดต่อได้ :
              </div>
            </div>
            <div
              className="ui form"
              style={{ width: "90%", display: "flex"}}>
              
              <TextArea
                name="address"
                onChange={props.onChangeData}
                style={{ width: "100%" }}
                value={props.PreAssessmentSequence?.address || ""}>
              </TextArea>
            </div>
          </div>
        </div>
        <div
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px", margin: "30px 0px 20px" }}>
          การเจ็บป่วยในปัจจุบัน
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "50%", fontWeight: "bold", display: "flex", alignItems: "center", }}>
              
              <div
                style={{ width: "20%", display: "flex", alignItems: "center", fontWeight: "bold"  }}>
                อาการสำคัญ :
              </div>
              <Input
                name="important_symptoms"
                onChange={props.onChangeData}
                style={{ width: "80%" }}
                value={props.PreAssessmentSequence?.important_symptoms || ""}>
              </Input>
            </div>
            <div
              style={{ width: "50%", display: "flex", alignItems: "center",}}>
              
              <div
                style={{ width: "30%", display: "flex", alignItems: "center", margin: "0px 20px", fontWeight: "bold"  }}>
                การวินิจฉัยโรคเมื่อแรกรับ :
              </div>
              <Input
                name="initial_diagnosis"
                onChange={props.onChangeData}
                style={{ width: "80%" }}
                value={props.PreAssessmentSequence?.initial_diagnosis || ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "100%", display: "flex", alignItems: "center" }}>
              
              <div
                style={{ fontWeight: "bold", marginRight: "15px" }}>
                อาการเจ็บป่วยในปัจจุบัน :
              </div>
              <Input
                name="current_illnesses"
                onChange={props.onChangeData}
                style={{ width: "80%" }}
                value={props.PreAssessmentSequence?.current_illnesses || ""}>
              </Input>
            </div>
          </div>
        </div>
        <div
          style={{ fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "12px 15px", margin: "30px 0px 20px" }}>
          ประวัติเจ็บป่วยในอดีต
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "17%", fontWeight: "bold" }}>
              
              <div>
                โรคประจำตัว :
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.personal_disease === "ไม่มี"}
                label="ไม่มี"
                name="personal_disease"
                onChange={props.onChangeData}
                style={{ width: "8%", display: "flex", alignItems: "center"  }}
                value="ไม่มี">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.personal_disease === "ไม่ทราบ"}
                label="ไม่ทราบ"
                name="personal_disease"
                onChange={props.onChangeData}
                style={{ width: "10%", display: "flex", alignItems: "center"  }}
                value="ไม่ทราบ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.personal_disease === "มี"}
                label="มี ระบุ"
                name="personal_disease"
                onChange={props.onChangeData}
                style={{ width: "8%", display: "flex", alignItems: "center"  }}
                value="มี">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.personal_disease !== "มี" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.personal_disease !== "มี"}
                name="personal_disease_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.personal_disease === "มี" ? props.PreAssessmentSequence?.personal_disease_remark || "" : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "17%", fontWeight: "bold" }}>
              
              <div>
                เคยรับการรักษาในโรงพยาบาล :
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.has_been_cured_before === "ไม่มี"}
                label="ไม่มี"
                name="has_been_cured_before"
                onChange={props.onChangeData}
                style={{ width: "8%", display: "flex", alignItems: "center"  }}
                value="ไม่มี">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.has_been_cured_before === "ไม่ทราบ"}
                label="ไม่ทราบ"
                name="has_been_cured_before"
                onChange={props.onChangeData}
                style={{ width: "10%", display: "flex", alignItems: "center"  }}
                value="ไม่ทราบ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.has_been_cured_before === "มี"}
                label="มี ระบุ"
                name="has_been_cured_before"
                onChange={props.onChangeData}
                style={{ width: "8%", display: "flex", alignItems: "center"  }}
                value="มี">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.has_been_cured_before !== "มี" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.has_been_cured_before !== "มี"}
                name="has_been_cured_before_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.has_been_cured_before === "มี" ? props.PreAssessmentSequence?.has_been_cured_before_remark || "" : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "17%", fontWeight: "bold" }}>
              
              <div>
                ประวัติการผ่าตัด :
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.surgical_history === "ไม่มี"}
                label="ไม่มี"
                name="surgical_history"
                onChange={props.onChangeData}
                style={{ width: "8%", display: "flex", alignItems: "center"  }}
                value="ไม่มี">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.surgical_history === "ไม่ทราบ"}
                label="ไม่ทราบ"
                name="surgical_history"
                onChange={props.onChangeData}
                style={{ width: "10%", display: "flex", alignItems: "center"  }}
                value="ไม่ทราบ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.surgical_history === "มี"}
                label="มี ระบุ"
                name="surgical_history"
                onChange={props.onChangeData}
                style={{ width: "8%", display: "flex", alignItems: "center"  }}
                value="มี">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.surgical_history !== "มี" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.surgical_history !== "มี" }
                name="surgical_history_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.surgical_history === "มี" ? props.PreAssessmentSequence?.surgical_history_remark || "" : ""}>
              </Input>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            
            <div
              style={{ width: "17%", fontWeight: "bold" }}>
              
              <div>
                ประวัติการเจ็บป่วยในครอบครัว :
              </div>
            </div>
            <div
              style={{ width: "90%", display: "flex"}}>
              
              <Radio
                checked={props.PreAssessmentSequence?.family_disease === "ไม่มี"}
                label="ไม่มี"
                name="family_disease"
                onChange={props.onChangeData}
                style={{ width: "8%", display: "flex", alignItems: "center"  }}
                value="ไม่มี">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.family_disease === "ไม่ทราบ"}
                label="ไม่ทราบ"
                name="family_disease"
                onChange={props.onChangeData}
                style={{ width: "10%", display: "flex", alignItems: "center"  }}
                value="ไม่ทราบ">
              </Radio>
              <Radio
                checked={props.PreAssessmentSequence?.family_disease === "มี"}
                label="มี ระบุ"
                name="family_disease"
                onChange={props.onChangeData}
                style={{ width: "8%", display: "flex", alignItems: "center"  }}
                value="มี">
              </Radio>
              <div
                style={{ display: props.PreAssessmentSequence?.family_disease !== "มี" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
                *
              </div>
              <Input
                disabled={props.PreAssessmentSequence?.family_disease !== "มี"}
                name="family_disease_remark"
                onChange={props.onChangeData}
                style={{ width: "30%" }}
                value={props.PreAssessmentSequence?.family_disease === "มี"   ? props.PreAssessmentSequence?.family_disease_remark || ""   : ""}>
              </Input>
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardPreAssessmentGeneralUX

export const screenPropsDefault = {}

/* Date Time : Wed May 10 2023 08:11:19 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 709,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเมินผู้ป่วยแรกรับ"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 710,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 711,
      "name": "div",
      "parent": 710,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 711,
      "void": false
    },
    {
      "from": null,
      "id": 712,
      "name": "div",
      "parent": 710,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 712,
      "void": false
    },
    {
      "from": null,
      "id": 719,
      "name": "div",
      "parent": 711,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"13%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 719,
      "void": false
    },
    {
      "from": null,
      "id": 720,
      "name": "div",
      "parent": 711,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 720,
      "void": false
    },
    {
      "from": null,
      "id": 721,
      "name": "div",
      "parent": 719,
      "props": {
        "children": {
          "type": "value",
          "value": "รับใหม่จาก :"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 721,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 722,
      "name": "Radio",
      "parent": 720,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.come_from === \"OPD\""
        },
        "label": {
          "type": "value",
          "value": "OPD"
        },
        "name": {
          "type": "value",
          "value": "come_from"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"6%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "OPD"
        }
      },
      "seq": 722,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 723,
      "name": "Radio",
      "parent": 720,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.come_from=== \"ER\""
        },
        "label": {
          "type": "value",
          "value": "ER"
        },
        "name": {
          "type": "value",
          "value": "come_from"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"6%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ER"
        }
      },
      "seq": 723,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 724,
      "name": "Radio",
      "parent": 720,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.come_from=== \"Refer-in\""
        },
        "label": {
          "type": "value",
          "value": "Refer-in"
        },
        "name": {
          "type": "value",
          "value": "come_from"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"9%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "Refer-in"
        }
      },
      "seq": 724,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 725,
      "name": "Radio",
      "parent": 720,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.come_from=== \"นัดมาผ่าตัด\""
        },
        "label": {
          "type": "value",
          "value": "นัดมาผ่าตัด"
        },
        "name": {
          "type": "value",
          "value": "come_from"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "นัดมาผ่าตัด"
        }
      },
      "seq": 725,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 726,
      "name": "Input",
      "parent": 720,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.come_from !== \"อื่นๆ\""
        },
        "label": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "come_from_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "placeholder": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.come_from=== \"อื่นๆ\"  ? props.PreAssessmentSequence?.come_from_remark || \"\"   : \"\""
        }
      },
      "seq": 889,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 727,
      "name": "Radio",
      "parent": 720,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.come_from=== \"อื่นๆ\""
        },
        "label": {
          "type": "value",
          "value": "อื่นๆ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "come_from"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "อื่นๆ"
        }
      },
      "seq": 726,
      "void": false
    },
    {
      "from": null,
      "id": 728,
      "name": "div",
      "parent": 712,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"13%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 728,
      "void": false
    },
    {
      "from": null,
      "id": 729,
      "name": "div",
      "parent": 728,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานภาพผู้ป่วยแรกรับ :"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 729,
      "void": false
    },
    {
      "from": null,
      "id": 731,
      "name": "div",
      "parent": 712,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 731,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 732,
      "name": "Radio",
      "parent": 731,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_by === \"เดินมา\""
        },
        "label": {
          "type": "value",
          "value": "เดินมา"
        },
        "name": {
          "type": "value",
          "value": "commute_by"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"6%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "เดินมา"
        }
      },
      "seq": 732,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 733,
      "name": "Radio",
      "parent": 731,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_by === \"รถนั่ง\""
        },
        "label": {
          "type": "value",
          "value": "รถนั่ง"
        },
        "name": {
          "type": "value",
          "value": "commute_by"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"6%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "รถนั่ง"
        }
      },
      "seq": 733,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 734,
      "name": "Radio",
      "parent": 731,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_by === \"รถนอน\""
        },
        "label": {
          "type": "value",
          "value": "รถนอน"
        },
        "name": {
          "type": "value",
          "value": "commute_by"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"9%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "รถนอน"
        }
      },
      "seq": 734,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 735,
      "name": "Radio",
      "parent": 731,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_by === \"อื่นๆ\""
        },
        "label": {
          "type": "value",
          "value": "อื่นๆ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "commute_by"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "อื่นๆ"
        }
      },
      "seq": 735,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 736,
      "name": "Input",
      "parent": 731,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_by !== \"อื่นๆ\""
        },
        "name": {
          "type": "value",
          "value": "commute_by_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_by === \"อื่นๆ\"  ? props.PreAssessmentSequence?.commute_by_remark || \"\"   : \"\""
        }
      },
      "seq": 890,
      "void": false
    },
    {
      "from": null,
      "id": 737,
      "name": "div",
      "parent": 710,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 737,
      "void": false
    },
    {
      "from": null,
      "id": 738,
      "name": "div",
      "parent": 737,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"13%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 738,
      "void": false
    },
    {
      "from": null,
      "id": 739,
      "name": "div",
      "parent": 737,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 739,
      "void": false
    },
    {
      "from": null,
      "id": 740,
      "name": "div",
      "parent": 738,
      "props": {
        "children": {
          "type": "value",
          "value": "นำส่งหอผู้ป่วย :"
        }
      },
      "seq": 740,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 741,
      "name": "Radio",
      "parent": 739,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_with === \"ญาติ\""
        },
        "label": {
          "type": "value",
          "value": "ญาติ"
        },
        "name": {
          "type": "value",
          "value": "commute_with"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"6%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ญาติ"
        }
      },
      "seq": 741,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 742,
      "name": "Radio",
      "parent": 739,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_with === \"เวรเปล\""
        },
        "label": {
          "type": "value",
          "value": "เวรเปล"
        },
        "name": {
          "type": "value",
          "value": "commute_with"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"6%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "เวรเปล"
        }
      },
      "seq": 742,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 743,
      "name": "Radio",
      "parent": 739,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_with === \"ผู้ป่วยมาเอง\""
        },
        "label": {
          "type": "value",
          "value": "ผู้ป่วยมาเอง"
        },
        "name": {
          "type": "value",
          "value": "commute_with"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"9%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ผู้ป่วยมาเอง"
        }
      },
      "seq": 743,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 744,
      "name": "Radio",
      "parent": 739,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_with === \"อื่นๆ\""
        },
        "label": {
          "type": "value",
          "value": "อื่นๆ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "commute_with"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "อื่นๆ"
        }
      },
      "seq": 744,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 745,
      "name": "Input",
      "parent": 739,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_with !== \"อื่นๆ\""
        },
        "name": {
          "type": "value",
          "value": "commute_with_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.commute_with === \"อื่นๆ\"  ? props.PreAssessmentSequence?.commute_with_remark || \"\"   : \"\""
        }
      },
      "seq": 891,
      "void": false
    },
    {
      "from": null,
      "id": 746,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 747,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติส่วนตัว / ครอบครัว"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 748,
      "name": "div",
      "parent": 746,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 748,
      "void": false
    },
    {
      "from": null,
      "id": 749,
      "name": "div",
      "parent": 746,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 749,
      "void": false
    },
    {
      "from": null,
      "id": 750,
      "name": "div",
      "parent": 746,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 750,
      "void": false
    },
    {
      "from": null,
      "id": 751,
      "name": "div",
      "parent": 746,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 751,
      "void": false
    },
    {
      "from": null,
      "id": 752,
      "name": "div",
      "parent": 746,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": " { display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 752,
      "void": false
    },
    {
      "from": null,
      "id": 753,
      "name": "div",
      "parent": 746,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 753,
      "void": false
    },
    {
      "from": null,
      "id": 754,
      "name": "div",
      "parent": 746,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 754,
      "void": false
    },
    {
      "from": null,
      "id": 755,
      "name": "div",
      "parent": 748,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 755,
      "void": false
    },
    {
      "from": null,
      "id": 756,
      "name": "div",
      "parent": 748,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 756,
      "void": false
    },
    {
      "from": null,
      "id": 757,
      "name": "div",
      "parent": 755,
      "props": {
        "children": {
          "type": "value",
          "value": "สุขภาพ :"
        }
      },
      "seq": 757,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 758,
      "name": "Radio",
      "parent": 756,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health=== \"แข็งแรง\""
        },
        "label": {
          "type": "value",
          "value": "แข็งแรง"
        },
        "name": {
          "type": "value",
          "value": "health"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "แข็งแรง"
        }
      },
      "seq": 758,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 759,
      "name": "Radio",
      "parent": 756,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health=== \"ไม่แข็งแรง\""
        },
        "label": {
          "type": "value",
          "value": "ไม่แข็งแรง ระบุ"
        },
        "name": {
          "type": "value",
          "value": "health"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่แข็งแรง"
        }
      },
      "seq": 759,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 760,
      "name": "Input",
      "parent": 756,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health !== \"ไม่แข็งแรง\" "
        },
        "name": {
          "type": "value",
          "value": "health_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.health=== \"ไม่แข็งแรง\"  ? props.PreAssessmentSequence?.health_remark || \"\"   : \"\""
        }
      },
      "seq": 892,
      "void": false
    },
    {
      "from": null,
      "id": 761,
      "name": "div",
      "parent": 749,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\" }"
        }
      },
      "seq": 761,
      "void": false
    },
    {
      "from": null,
      "id": 762,
      "name": "div",
      "parent": 749,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\"}"
        }
      },
      "seq": 762,
      "void": false
    },
    {
      "from": null,
      "id": 763,
      "name": "div",
      "parent": 761,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานภาพสมรส :"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 763,
      "void": false
    },
    {
      "from": null,
      "id": 765,
      "name": "div",
      "parent": 762,
      "props": {
        "children": {
          "type": "value",
          "value": "ระดับการศึกษา :"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 765,
      "void": false
    },
    {
      "from": null,
      "id": 766,
      "name": "div",
      "parent": 762,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        }
      },
      "seq": 766,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 770,
      "name": "Dropdown",
      "parent": 766,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "education"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.educationOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.education"
        }
      },
      "seq": 770,
      "void": false
    },
    {
      "from": null,
      "id": 772,
      "name": "div",
      "parent": 750,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\" }"
        }
      },
      "seq": 772,
      "void": false
    },
    {
      "from": null,
      "id": 773,
      "name": "div",
      "parent": 750,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\"}"
        }
      },
      "seq": 773,
      "void": false
    },
    {
      "from": null,
      "id": 774,
      "name": "div",
      "parent": 772,
      "props": {
        "children": {
          "type": "value",
          "value": "ศาสนา :"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 774,
      "void": false
    },
    {
      "from": null,
      "id": 776,
      "name": "div",
      "parent": 773,
      "props": {
        "children": {
          "type": "value",
          "value": "เชื้อชาติ :"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 776,
      "void": false
    },
    {
      "from": null,
      "id": 777,
      "name": "div",
      "parent": 773,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        }
      },
      "seq": 777,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 781,
      "name": "Dropdown",
      "parent": 777,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "race"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.raceOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.race"
        }
      },
      "seq": 781,
      "void": false
    },
    {
      "from": null,
      "id": 783,
      "name": "div",
      "parent": 751,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\" }"
        }
      },
      "seq": 783,
      "void": false
    },
    {
      "from": null,
      "id": 784,
      "name": "div",
      "parent": 751,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"67%\", display: \"flex\" }"
        }
      },
      "seq": 784,
      "void": false
    },
    {
      "from": null,
      "id": 785,
      "name": "div",
      "parent": 783,
      "props": {
        "children": {
          "type": "value",
          "value": "รายได้เฉลี่ยต่อเดือน :"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 785,
      "void": false
    },
    {
      "from": null,
      "id": 787,
      "name": "div",
      "parent": 784,
      "props": {
        "children": {
          "type": "value",
          "value": "ค่าใช้จ่ายในการรักษาพยาบาล :"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"23%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 787,
      "void": false
    },
    {
      "from": null,
      "id": 788,
      "name": "div",
      "parent": 784,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\", display: \"flex\"}"
        }
      },
      "seq": 788,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 789,
      "name": "Radio",
      "parent": 788,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cost_problem=== \"ไม่มีปัญหา\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มีปัญหา"
        },
        "name": {
          "type": "value",
          "value": "cost_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"18%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มีปัญหา"
        }
      },
      "seq": 789,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 790,
      "name": "Radio",
      "parent": 788,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cost_problem=== \"มีปัญหา\""
        },
        "label": {
          "type": "value",
          "value": "มีปัญหา โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "cost_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"25%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "มีปัญหา"
        }
      },
      "seq": 790,
      "void": false
    },
    {
      "from": null,
      "id": 794,
      "name": "div",
      "parent": 752,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 794,
      "void": false
    },
    {
      "from": null,
      "id": 795,
      "name": "div",
      "parent": 752,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 795,
      "void": false
    },
    {
      "from": null,
      "id": 797,
      "name": "div",
      "parent": 794,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ให้ข้อมูล :"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 797,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 798,
      "name": "Input",
      "parent": 795,
      "props": {
        "name": {
          "type": "value",
          "value": "info_given_by"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.info_given_by || \"\""
        }
      },
      "seq": 798,
      "void": false
    },
    {
      "from": null,
      "id": 799,
      "name": "div",
      "parent": 753,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\" }"
        }
      },
      "seq": 799,
      "void": false
    },
    {
      "from": null,
      "id": 800,
      "name": "div",
      "parent": 753,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\" }"
        }
      },
      "seq": 800,
      "void": false
    },
    {
      "from": null,
      "id": 802,
      "name": "div",
      "parent": 800,
      "props": {
        "children": {
          "type": "value",
          "value": "เกี่ยวข้องเป็น :"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 803,
      "void": false
    },
    {
      "from": null,
      "id": 806,
      "name": "div",
      "parent": 799,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ติดต่อ :"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 806,
      "void": false
    },
    {
      "from": null,
      "id": 813,
      "name": "div",
      "parent": 754,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 813,
      "void": false
    },
    {
      "from": null,
      "id": 814,
      "name": "div",
      "parent": 754,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 814,
      "void": false
    },
    {
      "from": null,
      "id": 815,
      "name": "div",
      "parent": 813,
      "props": {
        "children": {
          "type": "value",
          "value": "ที่อยู่ที่ติดต่อได้ :"
        }
      },
      "seq": 815,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 816,
      "name": "TextArea",
      "parent": 814,
      "props": {
        "name": {
          "type": "value",
          "value": "address"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.address || \"\""
        }
      },
      "seq": 816,
      "void": true
    },
    {
      "from": null,
      "id": 817,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": null,
      "id": 818,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "การเจ็บป่วยในปัจจุบัน"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": null,
      "id": 819,
      "name": "div",
      "parent": 817,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 819,
      "void": false
    },
    {
      "from": null,
      "id": 820,
      "name": "div",
      "parent": 817,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 820,
      "void": false
    },
    {
      "from": null,
      "id": 821,
      "name": "div",
      "parent": 819,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", fontWeight: \"bold\", display: \"flex\", alignItems: \"center\", }"
        }
      },
      "seq": 821,
      "void": false
    },
    {
      "from": null,
      "id": 822,
      "name": "div",
      "parent": 820,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 822,
      "void": false
    },
    {
      "from": null,
      "id": 823,
      "name": "div",
      "parent": 821,
      "props": {
        "children": {
          "type": "value",
          "value": "อาการสำคัญ :"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 823,
      "void": false
    },
    {
      "from": null,
      "id": 825,
      "name": "div",
      "parent": 819,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", display: \"flex\", alignItems: \"center\",}"
        }
      },
      "seq": 825,
      "void": false
    },
    {
      "from": null,
      "id": 828,
      "name": "div",
      "parent": 825,
      "props": {
        "children": {
          "type": "value",
          "value": "การวินิจฉัยโรคเมื่อแรกรับ :"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\", alignItems: \"center\", margin: \"0px 20px\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 828,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 829,
      "name": "Input",
      "parent": 825,
      "props": {
        "name": {
          "type": "value",
          "value": "initial_diagnosis"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.initial_diagnosis || \"\""
        }
      },
      "seq": 829,
      "void": false
    },
    {
      "from": null,
      "id": 831,
      "name": "div",
      "parent": 822,
      "props": {
        "children": {
          "type": "value",
          "value": "อาการเจ็บป่วยในปัจจุบัน :"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"15px\" }"
        }
      },
      "seq": 831,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 832,
      "name": "Input",
      "parent": 822,
      "props": {
        "fluid": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "current_illnesses"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.current_illnesses || \"\""
        }
      },
      "seq": 832,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 833,
      "name": "Input",
      "parent": 821,
      "props": {
        "name": {
          "type": "value",
          "value": "important_symptoms"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.important_symptoms || \"\""
        }
      },
      "seq": 833,
      "void": false
    },
    {
      "from": null,
      "id": 834,
      "name": "div",
      "parent": 749,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\"}"
        }
      },
      "seq": 834,
      "void": false
    },
    {
      "from": null,
      "id": 835,
      "name": "div",
      "parent": 761,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        }
      },
      "seq": 835,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 836,
      "name": "Dropdown",
      "parent": 835,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "marital_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.maritalStatusOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.marital_status"
        }
      },
      "seq": 836,
      "void": false
    },
    {
      "from": null,
      "id": 837,
      "name": "div",
      "parent": 834,
      "props": {
        "children": {
          "type": "value",
          "value": "อาชีพ :"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 837,
      "void": false
    },
    {
      "from": null,
      "id": 838,
      "name": "div",
      "parent": 834,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        }
      },
      "seq": 838,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 839,
      "name": "Dropdown",
      "parent": 838,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "occupation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.careerOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.occupation"
        }
      },
      "seq": 839,
      "void": false
    },
    {
      "from": null,
      "id": 840,
      "name": "div",
      "parent": 772,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        }
      },
      "seq": 840,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 841,
      "name": "Dropdown",
      "parent": 840,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "religion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.religionOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.religion"
        }
      },
      "seq": 841,
      "void": false
    },
    {
      "from": null,
      "id": 842,
      "name": "div",
      "parent": 750,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\" }"
        }
      },
      "seq": 842,
      "void": false
    },
    {
      "from": null,
      "id": 843,
      "name": "div",
      "parent": 842,
      "props": {
        "children": {
          "type": "value",
          "value": "สัญชาติ :"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 843,
      "void": false
    },
    {
      "from": null,
      "id": 844,
      "name": "div",
      "parent": 842,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        }
      },
      "seq": 844,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 845,
      "name": "Dropdown",
      "parent": 844,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "nationality"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.nationalityOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.nationality"
        }
      },
      "seq": 845,
      "void": false
    },
    {
      "from": null,
      "id": 846,
      "name": "div",
      "parent": 783,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        }
      },
      "seq": 846,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 847,
      "name": "Dropdown",
      "parent": 846,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "salary"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.salaryOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.salary || \"\""
        }
      },
      "seq": 847,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 848,
      "name": "Input",
      "parent": 788,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cost_problem !== \"มีปัญหา\""
        },
        "name": {
          "type": "value",
          "value": "cost_problem_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"60%\", margin: \"0px 20px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.cost_problem === \"มีปัญหา\" ?  props.PreAssessmentSequence?.cost_problem_remark || \"\"   : \"\""
        }
      },
      "seq": 893,
      "void": false
    },
    {
      "from": null,
      "id": 849,
      "name": "div",
      "parent": 753,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"33%\", display: \"flex\" }"
        }
      },
      "seq": 849,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 850,
      "name": "Input",
      "parent": 799,
      "props": {
        "name": {
          "type": "value",
          "value": "contact_person"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.contact_person || \"\""
        }
      },
      "seq": 850,
      "void": false
    },
    {
      "from": null,
      "id": 851,
      "name": "div",
      "parent": 849,
      "props": {
        "children": {
          "type": "value",
          "value": "เบอร์โทรศัพท์ :"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 851,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 852,
      "name": "Input",
      "parent": 849,
      "props": {
        "name": {
          "type": "value",
          "value": "phone"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePhone"
        },
        "size": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.phone || \"\""
        }
      },
      "seq": 852,
      "void": false
    },
    {
      "from": null,
      "id": 853,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติเจ็บป่วยในอดีต"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"12px 15px\", margin: \"30px 0px 20px\" }"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": null,
      "id": 854,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": null,
      "id": 855,
      "name": "div",
      "parent": 854,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 855,
      "void": false
    },
    {
      "from": null,
      "id": 856,
      "name": "div",
      "parent": 855,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"17%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 856,
      "void": false
    },
    {
      "from": null,
      "id": 857,
      "name": "div",
      "parent": 855,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 857,
      "void": false
    },
    {
      "from": null,
      "id": 858,
      "name": "div",
      "parent": 856,
      "props": {
        "children": {
          "type": "value",
          "value": "โรคประจำตัว :"
        }
      },
      "seq": 858,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 859,
      "name": "Radio",
      "parent": 857,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.personal_disease === \"ไม่มี\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "personal_disease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มี"
        }
      },
      "seq": 859,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 860,
      "name": "Radio",
      "parent": 857,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.personal_disease === \"ไม่ทราบ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "personal_disease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ทราบ"
        }
      },
      "seq": 860,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 861,
      "name": "Radio",
      "parent": 857,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.personal_disease === \"มี\""
        },
        "label": {
          "type": "value",
          "value": "มี ระบุ"
        },
        "name": {
          "type": "value",
          "value": "personal_disease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "มี"
        }
      },
      "seq": 861,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 862,
      "name": "Input",
      "parent": 857,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.personal_disease !== \"มี\""
        },
        "label": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "personal_disease_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.personal_disease === \"มี\" ? props.PreAssessmentSequence?.personal_disease_remark || \"\" : \"\""
        }
      },
      "seq": 894,
      "void": false
    },
    {
      "from": null,
      "id": 863,
      "name": "div",
      "parent": 854,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 863,
      "void": false
    },
    {
      "from": null,
      "id": 864,
      "name": "div",
      "parent": 863,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"17%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 864,
      "void": false
    },
    {
      "from": null,
      "id": 865,
      "name": "div",
      "parent": 863,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 865,
      "void": false
    },
    {
      "from": null,
      "id": 866,
      "name": "div",
      "parent": 864,
      "props": {
        "children": {
          "type": "value",
          "value": "เคยรับการรักษาในโรงพยาบาล :"
        }
      },
      "seq": 866,
      "void": false
    },
    {
      "from": null,
      "id": 867,
      "name": "div",
      "parent": 854,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 867,
      "void": false
    },
    {
      "from": null,
      "id": 868,
      "name": "div",
      "parent": 854,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"10px\" }"
        }
      },
      "seq": 868,
      "void": false
    },
    {
      "from": null,
      "id": 869,
      "name": "div",
      "parent": 867,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"17%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 869,
      "void": false
    },
    {
      "from": null,
      "id": 870,
      "name": "div",
      "parent": 867,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 870,
      "void": false
    },
    {
      "from": null,
      "id": 871,
      "name": "div",
      "parent": 869,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการผ่าตัด :"
        }
      },
      "seq": 871,
      "void": false
    },
    {
      "from": null,
      "id": 872,
      "name": "div",
      "parent": 868,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"17%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 872,
      "void": false
    },
    {
      "from": null,
      "id": 873,
      "name": "div",
      "parent": 868,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\", display: \"flex\"}"
        }
      },
      "seq": 873,
      "void": false
    },
    {
      "from": null,
      "id": 874,
      "name": "div",
      "parent": 872,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการเจ็บป่วยในครอบครัว :"
        }
      },
      "seq": 874,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 875,
      "name": "Radio",
      "parent": 865,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.has_been_cured_before === \"ไม่มี\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "has_been_cured_before"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มี"
        }
      },
      "seq": 875,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 876,
      "name": "Radio",
      "parent": 865,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.has_been_cured_before === \"ไม่ทราบ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "has_been_cured_before"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ทราบ"
        }
      },
      "seq": 876,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 877,
      "name": "Radio",
      "parent": 865,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.has_been_cured_before === \"มี\""
        },
        "label": {
          "type": "value",
          "value": "มี ระบุ"
        },
        "name": {
          "type": "value",
          "value": "has_been_cured_before"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "มี"
        }
      },
      "seq": 877,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 878,
      "name": "Radio",
      "parent": 870,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.surgical_history === \"ไม่มี\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "surgical_history"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มี"
        }
      },
      "seq": 878,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 879,
      "name": "Radio",
      "parent": 870,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.surgical_history === \"ไม่ทราบ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "surgical_history"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ทราบ"
        }
      },
      "seq": 879,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 880,
      "name": "Radio",
      "parent": 870,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.surgical_history === \"มี\""
        },
        "label": {
          "type": "value",
          "value": "มี ระบุ"
        },
        "name": {
          "type": "value",
          "value": "surgical_history"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "มี"
        }
      },
      "seq": 880,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 881,
      "name": "Radio",
      "parent": 873,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.family_disease === \"ไม่มี\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "family_disease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่มี"
        }
      },
      "seq": 881,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 882,
      "name": "Radio",
      "parent": 873,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.family_disease === \"ไม่ทราบ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "family_disease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "ไม่ทราบ"
        }
      },
      "seq": 882,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 883,
      "name": "Radio",
      "parent": 873,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.family_disease === \"มี\""
        },
        "label": {
          "type": "value",
          "value": "มี ระบุ"
        },
        "name": {
          "type": "value",
          "value": "family_disease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "มี"
        }
      },
      "seq": 883,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 884,
      "name": "Input",
      "parent": 865,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.has_been_cured_before !== \"มี\""
        },
        "name": {
          "type": "value",
          "value": "has_been_cured_before_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.has_been_cured_before === \"มี\" ? props.PreAssessmentSequence?.has_been_cured_before_remark || \"\" : \"\""
        }
      },
      "seq": 895,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 885,
      "name": "Input",
      "parent": 870,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.surgical_history !== \"มี\" "
        },
        "name": {
          "type": "value",
          "value": "surgical_history_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.surgical_history === \"มี\" ? props.PreAssessmentSequence?.surgical_history_remark || \"\" : \"\""
        }
      },
      "seq": 896,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 886,
      "name": "Input",
      "parent": 873,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.family_disease !== \"มี\""
        },
        "name": {
          "type": "value",
          "value": "family_disease_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.family_disease === \"มี\"   ? props.PreAssessmentSequence?.family_disease_remark || \"\"   : \"\""
        }
      },
      "seq": 897,
      "void": false
    },
    {
      "from": null,
      "id": 887,
      "name": "div",
      "parent": 800,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\", itemAlign: \"left\" }"
        }
      },
      "seq": 887,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 888,
      "name": "Dropdown",
      "parent": 887,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "contact_person_relation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.preAssessmentOptions?.contactOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreAssessmentSequence?.contact_person_relation || \"\""
        }
      },
      "seq": 888,
      "void": false
    },
    {
      "from": null,
      "id": 889,
      "name": "div",
      "parent": 720,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.come_from !== \"อื่นๆ\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 727,
      "void": false
    },
    {
      "from": null,
      "id": 890,
      "name": "div",
      "parent": 731,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.commute_by !== \"อื่นๆ\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 736,
      "void": false
    },
    {
      "from": null,
      "id": 891,
      "name": "div",
      "parent": 739,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.commute_with !== \"อื่นๆ\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 745,
      "void": false
    },
    {
      "from": null,
      "id": 892,
      "name": "div",
      "parent": 756,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.health !==  \"ไม่แข็งแรง\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 760,
      "void": false
    },
    {
      "from": null,
      "id": 893,
      "name": "div",
      "parent": 788,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.cost_problem !== \"มีปัญหา\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 848,
      "void": false
    },
    {
      "from": null,
      "id": 894,
      "name": "div",
      "parent": 857,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.personal_disease !== \"มี\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 862,
      "void": false
    },
    {
      "from": null,
      "id": 895,
      "name": "div",
      "parent": 865,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.has_been_cured_before !== \"มี\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 884,
      "void": false
    },
    {
      "from": null,
      "id": 896,
      "name": "div",
      "parent": 870,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.surgical_history !== \"มี\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 885,
      "void": false
    },
    {
      "from": null,
      "id": 897,
      "name": "div",
      "parent": 873,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.family_disease !== \"มี\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 886,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardPreAssessmentGeneralUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
