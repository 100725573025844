import WasmController from 'react-lib/frameworks/WasmController';
import DoctorFeeRuleList from "issara-sdk/apis/DoctorFeeRuleList_apps_DFC";
import DoctorFeeOrderList from "issara-sdk/apis/DoctorFeeOrderList_apps_DFC";
import DoctorFeeOrderCreateUpdateList from "issara-sdk/apis/DoctorFeeOrderCreateUpdateList_apps_DFC";
import DoctorFeeOrderPreviewList from "issara-sdk/apis/DoctorFeeOrderPreviewList_apps_DFC";
import EmployeeDetailByUsername from "issara-sdk/apis/EmployeeDetailByUsername_users";
import DoctorFeeOrderDetail from "issara-sdk/apis/DoctorFeeOrderDetail_apps_DFC";
import ServiceCompensationItemList from "issara-sdk/apis/ServiceCompensationItemList_apps_DFC";
import ServiceCompensationItemDetail from "issara-sdk/apis/ServiceCompensationItemDetail_apps_DFC";

export type State =
  {
    selectedEncounter?: any,
    providerEmployeeInfo?: any,
    SetDoctorFeeSequence?: {
      sequenceIndex: string | null,
      searchResult?: any[],
      searchProductResult?: any[],
      dfItems?: any[],
      scItems?: any[],
      dfPreviewItems?: any[],
      scPreviewItems?: any[],
    } | null,
    successMessage?: any,
    errorMessage?: any,
    buttonLoadCheck?: any,
  }

export const StateInitial: State =
{
  SetDoctorFeeSequence: null,
  successMessage: null,
  errorMessage: null,
}

export type Event =
  { message: "RunSequence", params: {} }

export type Data =
  {
    division?: number,
    device?: number,
  }

export const DataInitial =
{
}

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetDoctorFeeSequence) return
  if (!state.selectedEncounter) return

  const existingDF = await DoctorFeeOrderList.list({
    params: { encounter: state.selectedEncounter.id },
    apiToken: controller.apiToken
  });
  console.log(existingDF[1] ? existingDF[1] : existingDF[0]);

  const serviceItemList = await ServiceCompensationItemList.get({
    params: { 
      encounter: state.selectedEncounter.id,
      offset: 0,
      limit: 10,
    },
    apiToken: controller.apiToken
  })
  console.log(serviceItemList[1] ? serviceItemList[1] : serviceItemList[0]);

  controller.setState({
    SetDoctorFeeSequence: {
      ...state.SetDoctorFeeSequence,
      sequenceIndex: "EditDF",
      dfPreviewItems: existingDF[0] ? existingDF[0].items : [],
      scPreviewItems: serviceItemList[0] ? serviceItemList[0].items : [],
      dfItems: []
    }
  });
}

export const EditDF: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetDoctorFeeSequence) return
  if (!state.selectedEncounter) return
  if (params?.action === "search" && params?.searchText?.length >= 3) {
    console.log("search");
    const df = await DoctorFeeRuleList.list({
      encounter: state.selectedEncounter.id,
      params: { keyword: params?.searchText },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken
    })
    console.log(df[1] ? df[1] : df[0]);
    controller.setState({
      SetDoctorFeeSequence: {
        ...state.SetDoctorFeeSequence,
        searchResult: df[0] ? (df[0].items || []) : []
      }
    })
  } else if (params?.action === "add" && params?.item) {
    const item = {
      'encounter': state.selectedEncounter.id,
      'doctor_fee_rule': params?.item?.id,
      'doctor_fee_rule_display': params?.item?.name,
      'service_code': params?.item?.service_code,
      'quantity': 1,
      'price_unit': params?.item?.price_unit,
      'received_employee_display': state.providerEmployeeInfo?.employee_info?.full_name,
      'compensation_price': params?.item?.price_unit,
      'received_employee': state.providerEmployeeInfo?.employee_info?.id,
    }

    const dfItems = [...state.SetDoctorFeeSequence.dfItems || [], item]
    const dfPreviewList = (state.SetDoctorFeeSequence.dfPreviewItems || [])
                          .filter((item: any)=> item.id)

    const dfPreview = await DoctorFeeOrderPreviewList.post({
      data: { items: dfItems },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken
    });
    console.log(dfPreview[1] ? dfPreview[1] : dfPreview[0]);
    const dfPreviewItems = (dfPreview[0]?.items || []).map((item: any) =>
      ({ ...item, encounter: state.selectedEncounter.id }));
    controller.setState({
      SetDoctorFeeSequence: {
        ...state.SetDoctorFeeSequence,
        searchResult: [],
        dfItems: dfItems,
        dfPreviewItems: [...dfPreviewList, ...dfPreviewItems]
      }
    });
  } else if (params?.action === "save") {
    const [response, error] = await DoctorFeeOrderCreateUpdateList.create({
      data: {
        items: state.SetDoctorFeeSequence?.dfPreviewItems || []
      } as any,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken
    });
    // console.log(dfSave[1] ? dfSave[1] : dfSave[0]);
    if (response) {
      controller.setState({
        successMessage: { ...state.successMessage, [params?.sequence]: response},
        SetDoctorFeeSequence:{
          ...state.SetDoctorFeeSequence,
          sequenceIndex: 'START'
        }
      }, () => controller.handleEvent({ message: "RunSequence", params }));
    }
    if (error) {
      params.onFailed?.(error)
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.sequence]: error}
      });
    }
  } else if (params?.action === "Save_SC") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const [response, error] = await ServiceCompensationItemDetail.put({
      data: {
        items: state.SetDoctorFeeSequence?.scPreviewItems || []
      } as any,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken
    })

    if(error){
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: error },
          },
        });
      }
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: error },
        },
      });
    }else{
      controller.setState(
        {
          successMessage: { ...state.successMessage, [params?.card]: response },
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: null },
          },

          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.buttonLoadKey]: "SUCCESS",
          },
        },
      );
    }
    
  } else if (params?.action === "clear") {
    controller.setState({
      successMessage: { ...state.successMessage, [params?.sequence]: null },
      errorMessage: { ...state.errorMessage, [params?.sequence]: null }
    })
  } else if (params?.action === "update") {
    const data = params.data
    let items = state.SetDoctorFeeSequence?.dfPreviewItems || [];
    let dfItems = state.SetDoctorFeeSequence?.dfItems || [];

    const mapItems = (item: any) => {
      items = items.map((acc: any, index: number) =>
        index === data.index ? { ...acc, ...item } : acc
      );
    };

    if (params.data.type === "compensation_price") {
      mapItems({ compensation_price: data.value })

    } else if (params.data.type === "received_employee") {
      if (data.value) {
        const employee = await EmployeeDetailByUsername.get({
          apiToken: controller.apiToken,
          username: data.value
        })

        if (!employee[0]) {
          return params.onFailed?.(employee[1]);
        }

        mapItems({
          received_employee_display: employee[0].name_code,
          received_employee: employee[0].id,
        })
      } else {
        mapItems({ received_employee_display: "", })
      }
    } else if (params.data.type === "perform_datetime") {
      mapItems({ perform_datetime: data.value, })

    } else if (params.data.type === "delete") {

      const sumIdNotNull = items.reduce((sum, item) => item.id ? ( sum += 1 ) : sum, 0)
      dfItems = dfItems.filter((_: any, index: any) => index !== data.index - sumIdNotNull)

      if (data.value){
        await DoctorFeeOrderDetail.delete({
          apiToken: controller.apiToken,
          pk: data.value
        })
        
        items = items.filter((_: any, index: any) => index !== data.index)
        // controller.setState({
        //   SetDoctorFeeSequence: {
        //     ...state.SetDoctorFeeSequence,
        //     sequenceIndex: 'START'
        //   }
        // }, () => controller.handleEvent({ message: "RunSequence", params }));
      }else{
        items = items.filter((_: any, index: any) => index !== data.index)
      }
    }

    let dfUpdate: any = null;
    
    if (["compensation_price", "received_employee"].includes(params.data.type)) {
      dfUpdate = await DoctorFeeOrderCreateUpdateList.create({
        data: {
          items,
        } as any,
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });

      items = dfUpdate[0]?.items
    }

    if (dfUpdate === null || dfUpdate?.[0]) {
      controller.setState({
        SetDoctorFeeSequence: {
          ...state.SetDoctorFeeSequence,
          dfPreviewItems: items,
          dfItems
        },
      });
    } else {
      params.onFailed?.(dfUpdate[1]);
    }
  } else if (params?.action === "open_search") {
    const df = await DoctorFeeRuleList.list({
      encounter: state.selectedEncounter.id,
      params: { keyword: params?.keyword },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken
    })
    console.log(df[1] ? df[1] : df[0]);
    controller.setState({
      SetDoctorFeeSequence: {
        ...state.SetDoctorFeeSequence,
        searchProductResult: df[0] ? (df[0].items || []) : []
      }
    })
  } else if (params?.action === "add_select" && params?.item) {
    const dfItems = [...state.SetDoctorFeeSequence.dfItems || [], params.item]
    const dfPreviewList = (state.SetDoctorFeeSequence.dfPreviewItems || [])
                          .filter((item: any)=> item.id)

    const dfPreview = await DoctorFeeOrderPreviewList.post({
      data: { items: params.item },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken
    });
    console.log(dfPreview[1] ? dfPreview[1] : dfPreview[0]);
    const dfPreviewItems = (dfPreview[0]?.items || []).map((item: any) =>
      ({ ...item, encounter: state.selectedEncounter.id }));
    controller.setState({
      SetDoctorFeeSequence: {
        ...state.SetDoctorFeeSequence,
        searchResult: [],
        dfItems: dfItems,
        dfPreviewItems: [...dfPreviewList, ...dfPreviewItems]
      }
    });
  } else if (params?.action === "updateSc") {
    const data = params.data
    let items = state.SetDoctorFeeSequence?.scPreviewItems || [];
    let scItems = state.SetDoctorFeeSequence?.scItems || [];

    const mapItems = (item: any) => {
      items = items.map((acc: any, index: number) =>
        index === data.index ? { ...acc, ...item } : acc
      );
    };

    if (params.data.type === "compensation_price") {
      mapItems({ compensation_price: data.value })
    } else if (params.data.type === "received_employee") {
      if (data.value) {
        const employee = await EmployeeDetailByUsername.get({
          apiToken: controller.apiToken,
          username: data.value
        })

        if (!employee[0]) {
          return params.onFailed?.(employee[1]);
        }

        mapItems({
          received_employee_display: employee[0].name_code,
          received_employee: employee[0].id,
        })
      } else {
        mapItems({ received_employee_display: "", })
      }
    }

    let scUpdate: any = null;

    scUpdate = await ServiceCompensationItemDetail.put({
      data: {
        items,
      } as any,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });
    
    if (["compensation_price", "received_employee"].includes(params.data.type)) {
      scUpdate = await ServiceCompensationItemDetail.put({
        data: {
          items,
        } as any,
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });

      items = scUpdate[0]?.items
    }

    if (scUpdate === null || scUpdate?.[0]) {
      controller.setState({
        SetDoctorFeeSequence: {
          ...state.SetDoctorFeeSequence,
          scPreviewItems: items,
          scItems
        },
      });
    } else {
      params.onFailed?.(scUpdate[1]);
    }
  }
}
