import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  FormGroup,
  FormField,
  Input,
  Button
} from 'semantic-ui-react'

const CardFaxClaimUX = (props: any) => {
    return(
      <div
        style={{ display: "flex", flexDirection: "column", margin: "10px" }}>
        <div
          style={{ display: props.viewMode ? "none" : "flex", justifyContent: "space-between",  padding: "10px 10px 0px 10px"  }}>
          {}
          <div
            style={{ fontSize: "1.2rem", fontWeight: "bold",display: "flex", alignItems: "center" }}>
            แก้ไขรายละเอียดค่าใช้จ่าย
            <div
              style={{marginLeft: "50px", display: "none"}}>
              เลขที่ใบเสร็จ : 
            </div>
          </div>
          <div
            style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            {}
          </div>
        </div>
        <div
          style={{padding: "0px 10px 10px 10px", display: props.viewMode ? "none" : "" }}>
          
          <hr>
          </hr>
        </div>
        <div
          style={{ display: props.orderBy == "Mode" ? "block" : "none", padding: "0 10px" }}>
          
          <Table
            data={props.faxClaimItems}
            headers="หมวด, ส่งเบิก, สิทธิ (Payer), ลำดับ"
            id="tb-billInvoiceItem"
            keys="mode_name, total_send_claim_price, coverage_payer, order"
            minRows="5"
            showPagination={false}
            style={{ height: "30vh", ...(props.tableStyle || {})}}
            widths="auto,auto,auto,160">
          </Table>
        </div>
        <div
          style={{ display: props.orderBy == "Item" ? "block" : "none", padding: "0 10px" }}>
          
          <Table
            data={props.faxClaimItems}
            headers="Code,Product Name, ส่งเบิก, สิทธิ (Payer), ลำดับ"
            id="tb-billInvoiceItem"
            keys="product_code, product_name, total_send_claim_price, coverage_payer, order"
            minRows="5"
            showPagination={false}
            style={{ height: "30vh", ...(props.tableStyle || {})}}
            widths="auto,auto,auto,auto,160">
          </Table>
        </div>
        <div
          style={{ height: "8vh" , display: "none"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(2, 1fr)","gridTemplateColumns":"repeat(7, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/2", padding: "5px" }}>
              
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                ราคารวม
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "2/3", padding: "5px" }}>
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                ส่งเบิก
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "3/4", padding: "5px" }}>
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                absorb
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "4/5", padding: "5px" }}>
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                เบิกเอง
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "5/6", padding: "5px" }}>
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                เบิกไม่ได้
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "6/7", padding: "5px" }}>
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                ส่วนลด
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "7/8", padding: "5px" }}>
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                ผู้ป่วยชำระ
              </div>
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "1/2", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.price_total}
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "2/3", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.sendClaimTotal}
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "3/4", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.absorbTotal}
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "4/5", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.patientClaimTotal}
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "5/6", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.nonClaimableTotal}
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "6/7", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.discountTotal}
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "7/8", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.payableTotal}
            </div>
          </div>
        </div>
        <div
          className="ui form"
          style={{padding: "10px 15px 5px", margin: "20px 0px"}}>
          
          <FormGroup>
            <FormField
              className="noPadding --hard"
              width={3}>
              <label>
                ราคารวม
              </label>
              <Input
                readOnly={true}
                size="small"
                style={{ marginRight: "20px", width: "90%" }}
                value={props.invoiceItem?.price || ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              width={3}>
              <label>
                ส่งเบิก
              </label>
              <Input
                readOnly={true}
                size="small"
                style={{ marginRight: "20px", width: "90%" }}
                value={props.invoiceItem?.send_claim || ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              width={3}>
              <label>
                absorb
              </label>
              <Input
                readOnly={true}
                size="small"
                style={{ marginRight: "20px", width: "90%" }}
                value={props.invoiceItem?.absorb || props.invoiceItem?.absorbable || ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              width={3}>
              <label>
                เบิกเอง
              </label>
              <Input
                readOnly={true}
                size="small"
                style={{ marginRight: "20px", width: "90%" }}
                value={props.invoiceItem?.patient_claim || ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              width={3}>
              <label>
                 เบิกไม่ได้
              </label>
              <Input
                readOnly={true}
                style={{ marginRight: "20px", width: "90%" }}
                value={props.invoiceItem?.non_claimable || ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              width={3}>
              <label>
                ส่วนลด
              </label>
              <Input
                readOnly={true}
                size="small"
                style={{ marginRight: "20px", width: "90%" }}
                value={props.invoiceItem?.discount || ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              width={3}>
              <label>
                ผู้ป่วยชำระ
              </label>
              <Input
                readOnly={true}
                size="small"
                style={{ marginRight: "20px", width: "90%" }}
                value={props.invoiceItem?.payable || ""}>
              </Input>
            </FormField>
          </FormGroup>
        </div>
        <div>
          
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", margin: "0px 10px"}}>
          
          <div
            style={{ margin: "0px 10px" }}>
            {props.buttonSave}
          </div>
          <div>
            {}
            <Button
              color="red"
              onClick={props.onCancelFaxCliaim}>
              ยกเลิก
            </Button>
          </div>
        </div>
      </div>
    )
}


export default CardFaxClaimUX

export const screenPropsDefault = {"by":"order","title":"MEDICAL FEE","titleDescription":"ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"}

/* Date Time : Wed May 03 2023 07:45:45 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flexDirection: \"column\", margin: \"10px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.viewMode ? \"none\" : \"flex\", justifyContent: \"space-between\",  padding: \"10px 10px 0px 10px\"  }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"8vh\" , display: \"none\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "แก้ไขรายละเอียดค่าใช้จ่าย"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", fontWeight: \"bold\",display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", fontWeight: \"bold\" }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "label": "divgrid",
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(2, 1fr)\",\"gridTemplateColumns\":\"repeat(7, 1fr)\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/2\", padding: \"5px\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"2/3\", padding: \"5px\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"3/4\", padding: \"5px\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"4/5\", padding: \"5px\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"5/6\", padding: \"5px\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"6/7\", padding: \"5px\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"7/8\", padding: \"5px\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.price_total"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"1/2\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.sendClaimTotal"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"2/3\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.absorbTotal"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"3/4\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientClaimTotal"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"4/5\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nonClaimableTotal"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"5/6\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.discountTotal"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"6/7\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคารวม"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่งเบิก"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "absorb"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกเอง"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกไม่ได้"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่วนลด"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ป่วยชำระ"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.payableTotal"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"7/8\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.orderBy == \"Mode\" ? \"block\" : \"none\", padding: \"0 10px\" }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 42,
      "name": "Table",
      "parent": 41,
      "props": {
        "data": {
          "type": "code",
          "value": "props.faxClaimItems"
        },
        "headers": {
          "type": "value",
          "value": "หมวด, ส่งเบิก, สิทธิ (Payer), ลำดับ"
        },
        "id": {
          "type": "value",
          "value": "tb-billInvoiceItem"
        },
        "keys": {
          "type": "value",
          "value": "mode_name, total_send_claim_price, coverage_payer, order"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"30vh\", ...(props.tableStyle || {})}"
        },
        "widths": {
          "type": "value",
          "value": "auto,auto,auto,160"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบเสร็จ : "
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"50px\", display: \"none\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 10px 10px 10px\", display: props.viewMode ? \"none\" : \"\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "hr",
      "parent": 53,
      "props": {
      },
      "seq": 54,
      "void": true
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 15px 5px\", margin: \"20px 0px\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "FormGroup",
      "parent": 55,
      "props": {
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคารวม"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่งเบิก"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "absorb"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "label",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกเอง"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่วนลด"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "label",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ป่วยชำระ"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Input",
      "parent": 57,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", width: \"90%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.invoiceItem?.price || \"\""
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Input",
      "parent": 58,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", width: \"90%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.invoiceItem?.send_claim || \"\""
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Input",
      "parent": 59,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", width: \"90%\" }"
        },
        "type": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.invoiceItem?.absorb || props.invoiceItem?.absorbable || \"\""
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Input",
      "parent": 60,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", width: \"90%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.invoiceItem?.patient_claim || \"\""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Input",
      "parent": 61,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", width: \"90%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.invoiceItem?.discount || \"\""
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Input",
      "parent": 62,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", width: \"90%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.invoiceItem?.payable || \"\""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "label",
      "parent": 75,
      "props": {
        "children": {
          "type": "value",
          "value": " เบิกไม่ได้"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Input",
      "parent": 75,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", width: \"90%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.invoiceItem?.non_claimable || \"\""
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", margin: \"0px 10px\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Button",
      "parent": 86,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelFaxCliaim"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.orderBy == \"Item\" ? \"block\" : \"none\", padding: \"0 10px\" }"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 90,
      "name": "Table",
      "parent": 89,
      "props": {
        "data": {
          "type": "code",
          "value": "props.faxClaimItems"
        },
        "headers": {
          "type": "value",
          "value": "Code,Product Name, ส่งเบิก, สิทธิ (Payer), ลำดับ"
        },
        "id": {
          "type": "value",
          "value": "tb-billInvoiceItem"
        },
        "keys": {
          "type": "value",
          "value": "product_code, product_name, total_send_claim_price, coverage_payer, order"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"30vh\", ...(props.tableStyle || {})}"
        },
        "widths": {
          "type": "value",
          "value": "auto,auto,auto,auto,160"
        }
      },
      "seq": 90,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardFaxClaimUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "by": "order",
    "title": "MEDICAL FEE",
    "titleDescription": "ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"
  },
  "width": 80
}

*********************************************************************************** */
