import WasmController from "react-lib/frameworks/WasmController";
import EncounterBillingSummaryList from "issara-sdk/apis/EncounterBillingSummaryList_apps_BIL";
import NewReportMedicalFeeDetailView from "issara-sdk/apis/NewReportMedicalFeeDetailView_apps_BIL";
import ReportPrintTrackingResult from "issara-sdk/apis/ReportPrintTrackingResult_core";
import DiscountList from "issara-sdk/apis/DiscountList_apps_BIL";
import ProductList from "issara-sdk/apis/ProductList_core";
import BillModeList from "issara-sdk/apis/BillModeList_apps_BIL";
import BilledProductList from "issara-sdk/apis/BilledProductList_apps_BIL";
import BilledBillModeList from "issara-sdk/apis/BilledBillModeList_apps_BIL";
import DiscountDetail from "issara-sdk/apis/DiscountOneTimeDetail_apps_BILM";
import { printURL } from "react-lib/utils/printURL";

export type State = {
  selectedEncounter?: any;
  selectedPatient?: any;
  MedicalFeeHistorySequence?: {
    sequenceIndex?: string | null;
    coverage?: string | null;
    discountByCoverageCode?: string | null;
    discountByCoverageName?: string | null;
    MedicalFeeHistoryList?: any | null;
    DiscountList?: any | null;
    orderList?: any | null;
    productList?: any | null;
    orderProduct?: any | null;
    orderBillMode?: any | null;
    printStatus?: any;
  } | null;
  successMessage?: any;
  errorMessage?: any;
  buttonLoadCheck?: any;
  loadingStatus?: any;
};

export const StateInitial: State = {
  MedicalFeeHistorySequence: {
    sequenceIndex: null,
    MedicalFeeHistoryList: [],
    DiscountList: [],
    orderList: [],
    productList: [],
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
  device?: number;
  masterData?: any;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.MedicalFeeHistorySequence) return;

  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["discount", {}]],
    },
  });

  const [r, e, n] = await EncounterBillingSummaryList.list({
    params: { patient: state.selectedPatient?.patient_id },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });

  controller.setState({
    MedicalFeeHistorySequence: {
      sequenceIndex: "SearchAndEdit",
      MedicalFeeHistoryList: r?.items,
    },
  });
};

export const SearchAndEdit: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.MedicalFeeHistorySequence) return;
  if (!params.action) return;
  if (params.action === "print") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });
    const invoiceID = await NewReportMedicalFeeDetailView.get({
      params: { encounter: params.encounter },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    const [r, e, n] = await ReportPrintTrackingResult.retrieve({
      params: {
        task_id: invoiceID?.[0]?.task_id,
        multiple: true,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
        device: controller.data.device,
      },
    });
    if (e) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: e },
          },
        });
      }
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: e },
        },
      });
    } else {
      controller.setState({
        successMessage: { ...state.successMessage, [params?.card]: r },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: null },
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
        MedicalFeeHistorySequence: {
          ...state.MedicalFeeHistorySequence,
          printStatus: r,
        },
      });
    }
  } else if (params.action === "printPDF") {
    if (params.pdf) {
      const pdfWindow = window.open(
        "_blank",
        `data:application/pdf;base64,${params.reportView.pdf_b64data}`
      );
      pdfWindow?.document?.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64," +
          params.reportView.pdf_b64data +
          "'></iframe>"
      );
    } else {
      printURL(base64ToBlobURL(params.reportView.pdf_b64data));
    }
  } else if (params.action === "trackingPrint") {
    const reportView = await ReportPrintTrackingResult.retrieve({
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
      params: {
        task_id: params.task_id,
        action: params.status,
        multiple: true,
      },
    });

    if (reportView[0].pdf_b64data) {
      controller.setState({
        MedicalFeeHistorySequence: {
          ...state.MedicalFeeHistorySequence,
          printStatus: { ...reportView[0], pdf: params.pdf },
        },
      });

      SearchAndEdit(controller, {
        action: "printPDF",
        pdf: params.pdf,
        reportView: reportView[0],
      });
    } else {
      controller.setState({
        MedicalFeeHistorySequence: {
          ...state.MedicalFeeHistorySequence,
          printStatus: { ...reportView[0], pdf: params.pdf },
        },
      });
    }
  } else if (params.action === "openEdit") {
    const discountList = await DiscountList.list({
      params: {
        patient: state.selectedPatient?.patient_id,
        encounter: params.encounter,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    const discountOneTime = await DiscountDetail.retrieve({
      pk: discountList?.[0]?.items?.[0]?.id,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    controller.setState({
      MedicalFeeHistorySequence: {
        ...state.MedicalFeeHistorySequence,
        DiscountList: discountList?.[0]?.items,
        discountByCoverageCode: discountList?.[0]?.items?.[0]?.code,
        discountByCoverageName: discountList?.[0]?.items?.[0]?.name,
        orderList: discountOneTime?.[0]?.items,
      },
    });
  } else if (params.action === "addCoverage") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const [r, e, n] = await DiscountList.create({
      data: {
        coverage: state.MedicalFeeHistorySequence?.coverage,
        encounter: params.encounter,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    const discountList = await DiscountList.list({
      params: {
        patient: state.selectedPatient?.patient_id,
        encounter: params.encounter,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    if (e) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: e },
          },
        });
      }
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: e },
        },
      });
    } else {
      controller.setState({
        successMessage: { ...state.successMessage, [params?.card]: r },
        errorMessage: { ...state.errorMessage, [params.card]: { error: null } },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
        MedicalFeeHistorySequence: {
          ...state.MedicalFeeHistorySequence,
          DiscountList: discountList?.[0]?.items,
          discountByCoverageCode: discountList?.[0]?.items?.[0]?.code,
          discountByCoverageName: discountList?.[0]?.items?.[0]?.name,
          coverage: "",
        },
      });
    }
  } else if (params.action === "refresh") {
    await Start(controller, params);
  } else if (params.action === "addItem") {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: true },
    });

    if (params.type === "product") {
      const [r, e, n] = await ProductList.list({
        params: { search: params.searchText || "" },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      if (e) return;

      controller.setState({
        MedicalFeeHistorySequence: {
          ...state.MedicalFeeHistorySequence,
          productList: r?.items,
        },
        loadingStatus: { ...state.loadingStatus, [params.card]: false },
      });
    } else if (params.type === "billmode") {
      const [r, e, n] = await BillModeList.list({
        params: { search: params.searchText || "" },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      if (e) return;

      controller.setState({
        MedicalFeeHistorySequence: {
          ...state.MedicalFeeHistorySequence,
          productList: r?.items,
        },
        loadingStatus: { ...state.loadingStatus, [params.card]: false },
      });
    }
  } else if (params.action === "addBillProduct") {
    let orderList = state?.MedicalFeeHistorySequence?.orderList;
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const [r, e, n] = await BilledProductList.get({
      params: {
        patient: state.selectedPatient?.patient_id,
        encounter: params.encounter,
        pending: true,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    if (e) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: e },
          },
        });
      }
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: e },
        },
      });
    } else {
      let items = (r?.items || [])?.map((item: any) => ({
        ...item,
        product: item.id,
      }));

      if (orderList !== undefined) {
        orderList.push(...items);

        controller.setState({
          successMessage: { ...state.successMessage, [params?.card]: r },
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: null },
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.buttonLoadKey]: "SUCCESS",
          },
          MedicalFeeHistorySequence: {
            ...state.MedicalFeeHistorySequence,
            orderList: [...orderList],
          },
        });
      } else {
        controller.setState({
          successMessage: { ...state.successMessage, [params?.card]: r },
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: null },
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.buttonLoadKey]: "SUCCESS",
          },
          MedicalFeeHistorySequence: {
            ...state.MedicalFeeHistorySequence,
            orderList: items,
          },
        });
      }
    }
  } else if (params.action === "addBillMode") {
    let orderList = state?.MedicalFeeHistorySequence?.orderList;

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const [r, e, n] = await BilledBillModeList.get({
      params: {
        patient: state.selectedPatient?.patient_id,
        encounter: params.encounter,
        pending: true,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    if (e) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: e },
          },
        });
      }
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: e },
        },
      });
    } else {
      let items = (r?.items || [])?.map((item: any) => ({
        ...item,
        bill_mode: item.id,
      }));
      if (orderList !== undefined) {
        orderList.push(...items);

        controller.setState({
          successMessage: { ...state.successMessage, [params?.card]: r },
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: null },
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.buttonLoadKey]: "SUCCESS",
          },
          MedicalFeeHistorySequence: {
            ...state.MedicalFeeHistorySequence,
            orderList: [...orderList],
          },
        });
      } else {
        controller.setState({
          successMessage: { ...state.successMessage, [params?.card]: r },
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: null },
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.buttonLoadKey]: "SUCCESS",
          },
          MedicalFeeHistorySequence: {
            ...state.MedicalFeeHistorySequence,
            orderList: items,
          },
        });
      }
    }
  } else if (params.action === "save") {
    let id = state.MedicalFeeHistorySequence?.DiscountList?.find(
      (item: any) =>
        item.code === state.MedicalFeeHistorySequence?.discountByCoverageCode
    )?.id;

    let items = (state.MedicalFeeHistorySequence?.orderList || [])?.map(
      (item: any) => ({
        ...item,
        title: item.name,
      })
    );

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const [r, e, n] = await DiscountDetail.update({
      pk: id,
      data: {
        code: state.MedicalFeeHistorySequence?.discountByCoverageCode,
        name: state.MedicalFeeHistorySequence.discountByCoverageName,
        items: items,
      },

      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });
    if (e) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: e },
          },
        });
      }
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: e },
        },
      });
    } else {
      const discountOneTime = await DiscountDetail.retrieve({
        pk: id,
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      controller.setState({
        successMessage: { ...state.successMessage, [params?.card]: r },
        errorMessage: { ...state.errorMessage, [params.card]: { error: null } },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
        MedicalFeeHistorySequence: {
          ...state.MedicalFeeHistorySequence,
          orderList: discountOneTime?.[0]?.items,
        },
      });
    }
  } else if (params.action === "changeDiscount") {
    const discountList = await DiscountList.list({
      params: {
        patient: state.selectedPatient?.patient_id,
        encounter: params.encounter,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    let id = discountList?.[0]?.items?.find(
      (item: any) => item.code === params.code
    )?.id;

    const discountOneTime = await DiscountDetail.retrieve({
      pk: id,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    controller.setState({
      MedicalFeeHistorySequence: {
        ...state.MedicalFeeHistorySequence,
        DiscountList: discountList?.[0]?.items,
        discountByCoverageCode: params.code,
        discountByCoverageName: params.name,
        orderList: discountOneTime?.[0]?.items,
      },
    });
  }
};
function base64ToBlobURL(pdf_b64data: any): any {
  throw new Error("Function not implemented.");
}
