import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Checkbox,
  Input
} from 'semantic-ui-react'

const ANES_AnesComplication03 = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ margin: "0px 0px 50px" }}>
        <div
          style={{height:"50px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(1, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/41",display: "flex" , border: "solid 1px rgb(200, 200, 200, 0.5)","backgroundColor":"rgb(161, 221, 230, 0.49)", justifyContent: "space-between", }}>
              <label
                style={{textAlign:"center",marginLeft:"20px",paddingTop:"10px","fontWeight":"bold","fontSize":"17px"}}>
                Postoperative Pain management in PACU
              </label>
              <div
                style={{ margin: "5px", display: props.buttonStatus === "CONFIRMED" ?  "none" : "", }}>
                {props.buttonSave}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{height:"50px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(1, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/41",display: "flex" , border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              <Checkbox
                checked={props.AnesComplicationSequence?.IsPostoperativePainManagementInPACUNone}
                label="None"
                name="IsPostoperativePainManagementInPACUNone"
                onChange={props?.onChangeData}
                style={{"fontSize":"17px",marginTop:"20px",marginLeft:"20px"}}>
              </Checkbox>
            </div>
          </div>
        </div>
        <div
          style={{height:"540px",border: "solid 1px rgb(200, 200, 200, 0.5)",}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(9, 1fr)","gridTemplateColumns":"repeat(1, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/2" }}>
              <Checkbox
                checked={props.AnesComplicationSequence?.IsIVIM}
                label="IV / IM"
                name="IsIVIM"
                onChange={props?.onChangeData}
                style={{"fontSize":"17px",marginTop:"20px",marginLeft:"20px"}}>
              </Checkbox>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "1/2" }}>
              <Checkbox
                checked={props.AnesComplicationSequence?.IsPatientControlledAnalgesia}
                label="Patient-controlled analgesia"
                name="IsPatientControlledAnalgesia"
                onChange={props?.onChangeData}
                style={{"fontSize":"17px",marginTop:"20px",marginLeft:"20px"}}>
              </Checkbox>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "1/2" }}>
              <Checkbox
                checked={props.AnesComplicationSequence?.IsLumbarEpidural}
                label="Lumbar epidural"
                name="IsLumbarEpidural"
                onChange={props?.onChangeData}
                style={{"fontSize":"17px",marginTop:"20px",marginLeft:"20px"}}>
              </Checkbox>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "1/2" }}>
              <Checkbox
                checked={props.AnesComplicationSequence?.IsThoracicEpidural}
                label="Thoracic epidural"
                name="IsThoracicEpidural"
                onChange={props?.onChangeData}
                style={{"fontSize":"17px",marginTop:"20px",marginLeft:"20px"}}>
              </Checkbox>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "1/2" }}>
              <Checkbox
                checked={props.AnesComplicationSequence?.IsPeripheralNerveBlock}
                label="Peripheral nerve block"
                name="IsPeripheralNerveBlock"
                onChange={props?.onChangeData}
                style={{"fontSize":"17px",marginTop:"20px",marginLeft:"20px"}}>
              </Checkbox>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "1/2" }}>
              <Checkbox
                checked={props.AnesComplicationSequence?.IsCaudal}
                label="Caudal"
                name="IsCaudal"
                onChange={props?.onChangeData}
                style={{"fontSize":"17px",marginTop:"20px",marginLeft:"20px"}}>
              </Checkbox>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "1/2" }}>
              <Checkbox
                checked={props.AnesComplicationSequence?.SpinalOpioid}
                label="Spinal opioid"
                name="SpinalOpioid"
                onChange={props?.onChangeData}
                style={{"fontSize":"17px",marginTop:"20px",marginLeft:"20px"}}>
              </Checkbox>
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "1/2" }}>
              <Checkbox
                checked={props.AnesComplicationSequence?.Oral}
                label="Oral"
                name="Oral"
                onChange={props?.onChangeData}
                style={{"fontSize":"17px",marginTop:"20px",marginLeft:"20px"}}>
              </Checkbox>
            </div>
            <div
              style={{ gridRow: "9/10", gridColumn: "1/2" }}>
              <Checkbox
                checked={props.AnesComplicationSequence?.IsPostoperativePainManagementInPACU}
                label="Other"
                name="IsPostoperativePainManagementInPACU"
                onChange={props?.onChangeData}
                style={{"fontSize":"17px",marginTop:"20px",marginLeft:"20px"}}>
              </Checkbox>
              <Input
                disabled={props.AnesComplicationSequence?.IsPostoperativePainManagementInPACU !== true}
                name="IsPostoperativePainManagementInPACURemark"
                onChange={props?.onChangeData}
                style={{marginLeft:"20px",width:"80%"}}
                value={props.AnesComplicationSequence?.IsPostoperativePainManagementInPACU === true ? props.AnesComplicationSequence?.IsPostoperativePainManagementInPACURemark : ""}>
              </Input>
            </div>
          </div>
        </div>
      </div>
    )
}

export default ANES_AnesComplication03

export const screenPropsDefault = {}

/* Date Time : Thu Dec 29 2022 03:02:21 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 0px 50px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"50px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/41\",display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230, 0.49)\", justifyContent: \"space-between\", }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Postoperative Pain management in PACU"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"10px\",\"fontWeight\":\"bold\",\"fontSize\":\"17px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"540px\",border: \"solid 1px rgb(200, 200, 200, 0.5)\",}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "label": "divgrid",
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(9, 1fr)\",\"gridTemplateColumns\":\"repeat(1, 1fr)\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Checkbox",
      "parent": 10,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.IsIVIM"
        },
        "label": {
          "type": "value",
          "value": "IV / IM"
        },
        "name": {
          "type": "value",
          "value": "IsIVIM"
        },
        "onChange": {
          "type": "code",
          "value": "props?.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginTop:\"20px\",marginLeft:\"20px\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Checkbox",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.IsPatientControlledAnalgesia"
        },
        "label": {
          "type": "value",
          "value": "Patient-controlled analgesia"
        },
        "name": {
          "type": "value",
          "value": "IsPatientControlledAnalgesia"
        },
        "onChange": {
          "type": "code",
          "value": "props?.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginTop:\"20px\",marginLeft:\"20px\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Checkbox",
      "parent": 14,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.IsLumbarEpidural"
        },
        "label": {
          "type": "value",
          "value": "Lumbar epidural"
        },
        "name": {
          "type": "value",
          "value": "IsLumbarEpidural"
        },
        "onChange": {
          "type": "code",
          "value": "props?.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginTop:\"20px\",marginLeft:\"20px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Checkbox",
      "parent": 16,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.IsThoracicEpidural"
        },
        "label": {
          "type": "value",
          "value": "Thoracic epidural"
        },
        "name": {
          "type": "value",
          "value": "IsThoracicEpidural"
        },
        "onChange": {
          "type": "code",
          "value": "props?.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginTop:\"20px\",marginLeft:\"20px\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Checkbox",
      "parent": 18,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.IsPeripheralNerveBlock"
        },
        "label": {
          "type": "value",
          "value": "Peripheral nerve block"
        },
        "name": {
          "type": "value",
          "value": "IsPeripheralNerveBlock"
        },
        "onChange": {
          "type": "code",
          "value": "props?.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginTop:\"20px\",marginLeft:\"20px\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Checkbox",
      "parent": 20,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.IsCaudal"
        },
        "label": {
          "type": "value",
          "value": "Caudal"
        },
        "name": {
          "type": "value",
          "value": "IsCaudal"
        },
        "onChange": {
          "type": "code",
          "value": "props?.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginTop:\"20px\",marginLeft:\"20px\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Checkbox",
      "parent": 22,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.SpinalOpioid"
        },
        "label": {
          "type": "value",
          "value": "Spinal opioid"
        },
        "name": {
          "type": "value",
          "value": "SpinalOpioid"
        },
        "onChange": {
          "type": "code",
          "value": "props?.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginTop:\"20px\",marginLeft:\"20px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Checkbox",
      "parent": 37,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.Oral"
        },
        "label": {
          "type": "value",
          "value": "Oral"
        },
        "name": {
          "type": "value",
          "value": "Oral"
        },
        "onChange": {
          "type": "code",
          "value": "props?.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginTop:\"20px\",marginLeft:\"20px\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Checkbox",
      "parent": 39,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.IsPostoperativePainManagementInPACU"
        },
        "label": {
          "type": "value",
          "value": "Other"
        },
        "name": {
          "type": "value",
          "value": "IsPostoperativePainManagementInPACU"
        },
        "onChange": {
          "type": "code",
          "value": "props?.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginTop:\"20px\",marginLeft:\"20px\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Input",
      "parent": 39,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.IsPostoperativePainManagementInPACU !== true"
        },
        "name": {
          "type": "value",
          "value": "IsPostoperativePainManagementInPACURemark"
        },
        "onChange": {
          "type": "code",
          "value": "props?.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",width:\"80%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.IsPostoperativePainManagementInPACU === true ? props.AnesComplicationSequence?.IsPostoperativePainManagementInPACURemark : \"\""
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"50px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 42,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 43,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/41\",display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Checkbox",
      "parent": 44,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.IsPostoperativePainManagementInPACUNone"
        },
        "label": {
          "type": "value",
          "value": "None"
        },
        "name": {
          "type": "value",
          "value": "IsPostoperativePainManagementInPACUNone"
        },
        "onChange": {
          "type": "code",
          "value": "props?.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{\"fontSize\":\"17px\",marginTop:\"20px\",marginLeft:\"20px\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"5px\", display: props.buttonStatus === \"CONFIRMED\" ?  \"none\" : \"\", }"
        }
      },
      "seq": 46,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_AnesComplication03",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
