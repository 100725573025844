import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
  SyntheticEvent,
} from "react";
import { Icon, Modal, Checkbox } from "semantic-ui-react";

// Common
import { PatientSearchBox, SearchBox } from "react-lib/apps/common";

// UX
import CardDrugOrderHistoryUX from "../TPD/CardDrugOrderHistoryUX";
import CardDrugOrderWorking from "./CardDrugOrderWorking";

const CardDrugOrderHistory = (props: any) => {
  const [nationalities, setNationalities] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patientList, setPatientList] = useState<any>([]);
  const [currentHNPage, setCurrentHNPage] = useState(1);
  const [numberOfHNPage, setNumberOfHNPage] = useState(1);
  const [selectedDrugOrderId, setSelectedDrugOrderId] = useState(null);
  const [prescriptionOpen, setPrescriptionOpen] = useState(false);
  const [prescriptionData, setPrescriptionData] = useState<any>();
  const [checkedDrug, setCheckedDrug] = useState<number[]>([]);

  const patientRef = useRef<any>();
  const drugRef = useRef<any>();
  const doctorRef = useRef<any>();
  const isMounted = useRef(false);

  // Use effect
  useEffect(() => {
    if (props.runSequence) {
      props?.runSequence({
        sequence: "DrugOrderHistory",
        isDrugOrderHistory: props.isDrugOrderHistory,
        selectedPatientSearch: props.selectedEncounter?.patient_id,
      });

      isMounted.current = true;

      return () => {
        props?.runSequence({ sequence: "DrugOrderHistory", restart: true });
        isMounted.current = false;
      };
    }
  }, []);

  useEffect(() => {
    if (props.drugOrder?.pk && prescriptionOpen && prescriptionData) {
      handleClickRow(prescriptionData);
    }
  }, [props.drugOrder, prescriptionOpen, prescriptionData]);

  // Use callback
  const handleCheckbox = useCallback(
    (e: SyntheticEvent, d: any) => {
      e.preventDefault();
      e.stopPropagation();

      let checkList = [...checkedDrug];
      if (d.checked) {
        checkList.push(d.name);
      } else {
        checkList = checkList.filter((id) => id !== d.name);
      }

      setCheckedDrug(checkList);
    },
    [checkedDrug]
  );

  const drugHistoryData = useMemo(() => {
    return props.drugHistoryOrderList?.map((item: any, number: any) => {
      return {
        ...item,
        prescription: (
          <div
            style={{ display: "flex", justifyContent: "center" }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              setPrescriptionOpen(true);
              setPrescriptionData(item);

              props.onEvent({
                message: "SelectDrugOrderWorking",
                params: {
                  drugOrder:
                    {
                      id: item?.order_id,
                      encounter: item?.encounter_id,
                    } || null,
                  frezzeRecordViewIndex: props.frezzeRecordViewIndex,
                  isNotGetEncounter: props.type === "ALL_HISTORY",
                },
              });
            }}
          >
            <Icon name="file" color="green" style={{ cursor: "pointer" }} />
          </div>
        ),
      };
    });
  }, [props.drugHistoryOrderList]);

  const drugHistoryItems = useMemo(() => {
    return (props.drugHistoryList || []).map((item: any, index: number) => ({
      ...item,
      check: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Checkbox
            name={item.id}
            onChange={handleCheckbox}
            checked={checkedDrug.includes(item.id)}
          />
        </div>
      ),
      name_status: (
        <>
          <div>{item.name}</div>
        </>
      ),
    })) as any[];
  }, [props.drugHistoryList, checkedDrug]);

  // Patient Search Box
  const getNationality = async () => {
    const [data, error] = await props.controller.getNationalities();
    if (isMounted.current) {
      if (data) {
        setNationalities(data);
      } else {
        setNationalities([]);
      }
    }
  };

  const getPatientList = async ({
    hn,
    fullName,
    citizenId,
    nationality,
    patientCurrentPage,
  }: any = {}) => {
    let limit = 40;
    let page = patientCurrentPage ? patientCurrentPage : currentHNPage;
    setIsLoading(true);
    setCurrentHNPage(page);
    const [data, error] = await props.controller.getSearchAllPatient({
      hn,
      fullName,
      citizenId,
      nationality,
      is_old_name_search: true,
      limit,
      offset: (page - 1) * limit,
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        setPatientList(data.items);
        setNumberOfHNPage(parseInt(data.total) / limit + 1);
      } else {
        setPatientList([]);
        setNumberOfHNPage(1);
      }
    }
    return [data, error];
  };

  const handlePaginationChange = async ({
    hn,
    fullName,
    citizenId,
    nationality,
    patientCurrentPage,
  }: any = {}) => {
    setCurrentHNPage(patientCurrentPage);
    getPatientList({
      hn,
      fullName,
      citizenId,
      nationality,
      patientCurrentPage,
    });
  };

  const handleGetPatientByHN = async (hn: any) => {
    const [data, error, network] = await props.controller.getPatientByHN({
      hn,
    });
    return [data, error, network];
  };

  const handleOnEnterPatientSearch = (
    id: number,
    hn: string,
    full_name: string
  ) => {
    if (isMounted.current) {
      props.setProp("DrugOrderHistorySequence.selectedPatientSearch", id);
    }
  };

  const handleClickRow = (original: any) => {
    props?.runSequence({
      sequence: "DrugOrderHistory",
      drug_order: original || null,
      action: "drug_order",
    });

    setCheckedDrug([]);
    setSelectedDrugOrderId(original?.order_id || null);
    // props?.layout === 0 && props?.forward?.();
  };

  // Table
  const handleRowDrugHistoryOrderProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.order_id &&
          rowInfo?.original?.order_id === selectedDrugOrderId
            ? "#cccccc"
            : "white",
        fontSize: "14px",
        fontWeight: "normal",
      },
      onClick: () => {
        handleClickRow(rowInfo?.original);
      },
    };
  };

  const handleGetTrHistoryProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        fontSize: "14px",
        fontWeight: "normal",
      },
    };
  };

  //  Handler
  const handleChangeValue = (key: string) => (e: any, v: any) =>
    props.setProp(`DrugOrderHistorySequence.${key}`, v.value);

  const handleChangeChecked =
    (key: string, textClear: string) =>
    (e: any, { checked }: any) => {
      if (key === "checkedDrugSearch") {
        drugRef?.current?.clear();
      }
      if (key === "checkedDoctorSearch") {
        doctorRef?.current?.clear();
      }
      if (key === "checkedPatientSearch") {
        patientRef.current?.clear();
      }
      if (key === "checkedDrugOrderType") {
        props.setProp("DrugOrderHistorySequence", {
          ...props.DrugOrderHistorySequence,
          [key]: checked,
          [textClear]: [],
        });
      } else {
        props.setProp("DrugOrderHistorySequence", {
          ...props.DrugOrderHistorySequence,
          [key]: checked,
          [textClear]: "",
        });
      }
    };

  const handleChangeCheckedDate =
    (key: string) =>
    (e: any, { checked }: any) => {
      props.setProp("DrugOrderHistorySequence", {
        ...props.DrugOrderHistorySequence,
        [key]: checked,
        selectedStartDate: "",
        selectedEndDate: "",
      });
    };

  const handleChangeDate = (key: string) => (date: any) => {
    console.log("date: ", key, date);
    props.setProp(`DrugOrderHistorySequence.${key}`, date);
  };

  const handleGetDrugOptions = async ({ searchText }: any) => {
    const [data, error] = await props.controller.getDrugSearch({
      keyword: searchText,
    });
    return [data, error];
  };

  const handleSelectedDrug = (id: number) => {
    props.setProp("DrugOrderHistorySequence.selectedDrugSearch", id);
  };

  const handleGetDoctorOptions = async ({ searchText }: any) => {
    const [data, error] = await props.controller.getDoctor({
      name_code: searchText,
    });
    return [data, error];
  };

  const handleSelectedDoctor = (id: number) => {
    props.setProp("DrugOrderHistorySequence.selectedDoctorSearch", id);
  };

  const handleSearchToday = () => {
    props?.runSequence({ sequence: "DrugOrderHistory", action: "today" });
  };

  const handleSearchVaccine = () => {
    props?.runSequence({
      sequence: "DrugOrderHistory",
      action: "vaccine",
    });
  };

  const handleClearSearch = () => {
    props?.runSequence({ sequence: "DrugOrderHistory", action: "clear" });
    doctorRef.current?.clear();
    drugRef.current?.clear();
    patientRef.current?.clear();
  };

  const handleOnSearch = () => {
    props?.runSequence({
      sequence: "DrugOrderHistory",
      action: "search",
    });
  };

  const handleCheckedAll = (e: SyntheticEvent, data: any) => {
    if (!data.checked) {
      setCheckedDrug([]);
    } else {
      setCheckedDrug([...drugHistoryItems.map((item) => item.id)]);
    }
  };

  const handleAddToOrder = () => {
    props.onAddToOrder?.(props.drugHistoryList || [], checkedDrug);
  };

  console.log("CardDrugOrderHistory Props:", props);

  return (
    <>
      <CardDrugOrderHistoryUX
        // options
        divisionPharmaOptions={props.masterOptions?.divisionPharma || []}
        drugOrderStatusOptions={props.masterOptions?.drugStatus || []}
        drugOrderTypeOptions={props.masterOptions?.drugOrderType || []}
        // data
        patientName={`${props.selectedEncounter?.patient_name} (${props.selectedEncounter?.hn})`}
        drugHistoryOrderList={drugHistoryData}
        drugHistoryList={drugHistoryItems}
        selectedStartDate={
          props.DrugOrderHistorySequence?.selectedStartDate || ""
        }
        selectedEndDate={props.DrugOrderHistorySequence?.selectedEndDate || ""}
        selectedDrugOrderType={
          props.DrugOrderHistorySequence?.selectedDrugOrderType || ""
        }
        selectedDrugOrderStatus={
          props.DrugOrderHistorySequence?.selectedDrugOrderStatus || ""
        }
        selectedDivisionPharma={
          props.DrugOrderHistorySequence?.selectedDivisionPharma || ""
        }
        checkedDrugOrderType={
          props.DrugOrderHistorySequence?.checkedDrugOrderType || false
        }
        checkedDrugOrderStatus={
          props.DrugOrderHistorySequence?.checkedDrugOrderStatus || false
        }
        checkedDivisionPharma={
          props.DrugOrderHistorySequence?.checkedDivisionPharma || false
        }
        checkedFilterDate={
          props.DrugOrderHistorySequence?.checkedFilterDate || false
        }
        checkedPatientSearch={
          props.DrugOrderHistorySequence?.checkedPatientSearch ||
          props.addToOrderMode ||
          false
        }
        checkedDrugSearch={
          props.DrugOrderHistorySequence?.checkedDrugSearch || false
        }
        checkedDoctorSearch={
          props.DrugOrderHistorySequence?.checkedDoctorSearch || false
        }
        // config
        isDrugOrderHistory={props.isDrugOrderHistory && !props.addToOrderMode}
        addToOrderMode={props.addToOrderMode}
        showAddToOrder={!!checkedDrug.length}
        closeIcon={props.addToOrderMode}
        // function
        handleChangeValue={handleChangeValue}
        handleChangeChecked={handleChangeChecked}
        handleChangeCheckedDate={handleChangeCheckedDate}
        handleChangeDate={handleChangeDate}
        handleSearchToday={handleSearchToday}
        handleSearchVaccine={handleSearchVaccine}
        handleClearSearch={handleClearSearch}
        handleOnSearch={handleOnSearch}
        onAddToOrder={handleAddToOrder}
        // Table
        rowDrugHistoryOrderProps={handleRowDrugHistoryOrderProps}
        getTrDrugHistoryProps={handleGetTrHistoryProps}
        // component
        drugOrderHeaders={
          props.addToOrderMode
            ? [
                <Checkbox
                  checked={
                    !!checkedDrug.length &&
                    checkedDrug.length === drugHistoryItems.length
                  }
                  onChange={handleCheckedAll}
                />,
                "ชื่อยา",
                "Dose",
                "Unit",
                "Route",
                "Frequency",
                "Method",
                "PRN",
                "Qty",
                "Onhand Stock",
                "Unit",
                "Price/Unit",
                "Total Price",
              ]
            : null
        }
        drugOrderKeys={
          props.addToOrderMode
            ? "check, name_status, dose, code_unit, code_route, code_frequency, code_method, prn, quantity, onhand, code_stock, price_unit, price_total"
            : null
        }
        doctorSearch={
          <SearchBox
            ref={doctorRef}
            onGetSearchOptions={handleGetDoctorOptions}
            textField="name_code"
            onSelectOption={handleSelectedDoctor}
            disabled={!props.DrugOrderHistorySequence?.checkedDoctorSearch}
          />
        }
        drugSearch={
          <SearchBox
            ref={drugRef}
            onGetSearchOptions={handleGetDrugOptions}
            textField="full_name"
            onSelectOption={handleSelectedDrug}
            disabled={!props.DrugOrderHistorySequence?.checkedDrugSearch}
          />
        }
        patientSearch={
          <>
            <PatientSearchBox
              ref={patientRef}
              defaultValue={
                props.addToOrderMode ? props.selectedEncounter?.hn : ""
              }
              patientList={patientList}
              nationalitiesList={nationalities}
              patientListLoading={isLoading}
              numberOfPage={numberOfHNPage}
              currentPage={currentHNPage}
              disabled={
                !props.DrugOrderHistorySequence?.checkedPatientSearch ||
                props.addToOrderMode
              }
              modalStyle={{ width: "auto" }}
              // callback
              getNationality={getNationality}
              getPatientList={getPatientList}
              onEnter={handleOnEnterPatientSearch}
              onSelectPatient={handleOnEnterPatientSearch}
              onGetPatientByHN={handleGetPatientByHN}
              onPatientListPaginationChange={handlePaginationChange}
            />
          </>
        }
      />

      <Modal
        open={prescriptionOpen}
        // size="large"
        style={{
          width: "75%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Modal.Header style={{ padding: "15px 10px 15px 0" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0 0 0 20px",
            }}
          >
            <div>รายละเอียดใบสั่งยา</div>
            <div>
              <Icon
                name="cancel"
                color="red"
                style={{ cursor: "pointer" }}
                onClick={(e: any) => {
                  setPrescriptionOpen(false);
                  setPrescriptionData(null);
                }}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Content
          style={{
            padding:
              props.drugOrder && props.drugOrder?.type === "CONTINUE_PLAN"
                ? "5px 15px 0"
                : "5px 0 0",
            minHeight: "70vh",
          }}
        >
          <CardDrugOrderWorking
            isCardReceive={true}
            isCardDrugOrderHistory={true}
            nameTypeOrder={
              `${prescriptionData?.code} : ${prescriptionData?.type}` || ""
            }
            prescriptionType={prescriptionData?.type}
            runSequence={props.runSequence}
            onEvent={props.onEvent}
            setProp={props.setProp}
            selectedEncounter={props.selectedEncounter}
            selectedEmr={props.selectedEmr}
            selectedDivision={props.selectedDivision}
            drugOrderQueue={props.drugOrderQueue}
            drugOrder={props?.drugOrder}
            drugOrderLog={props.drugOrderLog}
            modNoteReject={props.modNoteReject}
            layout={props.layout}
            forward={props.forward}
            DrugSelectSequence={props.DrugSelectSequence}
            selectedPatient={props.selectedPatient}
            selectedDrug={props.selectedDrug}
            drugDelivery={props.drugDelivery}
            modConfirmAction={props.modConfirmAction || []}
            modDrugRecommendation={props.modDrugRecommendation}
            masterOptions={props.masterOptions}
            medErrorWorking={props.MedErrorListSequence?.medErrorWorking}
            errorMessage={props.errorMessage}
            loadingStatus={props.loadingStatus}
            successMessage={props.successMessage}
            DrugOrderActionSequence={props.DrugOrderActionSequence}
            // DrugTransferRequest
            drugTransferRequestSequence={props.DrugTransferRequestSequence}
            drugOrderHistoryController={props.drugOrderHistoryController}
            drugRequestList={props.drugRequestList}
            selectedDevice={props.selectedDevice}
            drugTransferRequestlist={props.drugTransferRequestlist}
            drugTransferLog={props.drugTransferLog}
            drugTransferRequestDetail={props.drugTransferRequestDetail}
            ReturnOrderSequence={props.ReturnOrderSequence}
            OrderReturnList={props.OrderReturnList}
            DrugReturnList={props.DrugReturnList}
            OrderHistory={props.OrderHistory}
            OrderReturnLog={props.OrderReturnLog}
            // StockManagement
            lotNoExpList={props.lotNoExpList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            // MedReconcile
            // TODO: Check is need MedReconcile Alert modal
            // divisionType={props.divisionType}
            // medReconcileIndex={props.medReconcileIndex}
            // django={props.django}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

export default CardDrugOrderHistory;
