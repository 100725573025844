import { Table } from "react-lib/frameworks/Table";
import { Header} from "semantic-ui-react";


const DrugActionLog = (props: any) => {
    return ( 
    <div style={{padding:"10px"}}>
    <Header> ประวัติการทำงาน </Header>
      <Table  
        data={props.data}
        headers="User,Action,note,division,Date-Time"
        keys="name,action,note,division,datetime"
        minRows="10"
        showPagination={false}
        style={{ height: "300px" }}
      ></Table>
    </div>)
  }

export default DrugActionLog;