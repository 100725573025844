/* Generated apis ---------------------- */
import { to } from "react-lib/utils";
import BaseService from "react-lib/apis/services/BaseService";
import config from "../../../../config/config";
const HOST = `${config.API_HOST}`;
/* ['APIView'] */
/* params:  */
/* data:  */
const UserPermissionView: {
  get: (arg0: { params?: any; apiToken?: any; extra?: any }) => any;
  post: (arg0: { params?: any; data?: any; apiToken?: any; extra?: any }) => any;
  put: (arg0: { params?: any; data?: any; apiToken?: any; extra?: any }) => any;
  patch: (arg0: { params?: any; data?: any; apiToken?: any; extra?: any }) => any;
} = {
  get: async ({ params, apiToken, extra }) => {
    const base = new BaseService();
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const result = await to(
      base.client
        .get(`${HOST}/users/apis/user-permission/`, {
          params: params ? { ...params } : {},
          ...extra,
        })
        .then(base.handleResponse)
        .catch(base.throwErrorMessage)
    );
    return [result[1], result[0], result[2]];
  },

  post: async ({ params, data, apiToken, extra }) => {
    const base = new BaseService();
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const result = await to(
      base.client
        .post(
          `${HOST}/users/apis/user-permission/`,
          {
            ...data,
          },
          {
            params: params ? { ...params } : {},
            ...extra,
          }
        )
        .then(base.handleResponse)
        .catch(base.throwErrorMessage)
    );
    return [result[1], result[0], result[2]];
  },

  put: async ({ params, data, apiToken, extra }) => {
    const base = new BaseService();
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const result = await to(
      base.client
        .put(
          `${HOST}/users/apis/user-permission/`,
          {
            ...data,
          },
          {
            params: params ? { ...params } : {},
            ...extra,
          }
        )
        .then(base.handleResponse)
        .catch(base.throwErrorMessage)
    );
    return [result[1], result[0], result[2]];
  },

  patch: async ({ params, data, apiToken, extra }) => {
    const base = new BaseService();
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const result = await to(
      base.client
        .patch(
          `${HOST}/users/apis/user-permission/`,
          {
            ...data,
          },
          {
            params: params ? { ...params } : {},
            ...extra,
          }
        )
        .then(base.handleResponse)
        .catch(base.throwErrorMessage)
    );
    return [result[1], result[0], result[2]];
  },
};

export default UserPermissionView;
