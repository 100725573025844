import React, { useCallback } from "react";

// UX
import SubProductIngredientsUX from "./SubProductIngredientsUX";

// Common
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import { IngredientType, ProductDetailType } from "./sequence/StockManagement";

// Types
type SubProductIngredientsProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // data
  index: number;
  data: Partial<IngredientType>;
  productDetail?: Partial<ProductDetailType>;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  // config
  hideRemove?: boolean;
};

// Const
const INGREDIENT_NAME = "name";
const INGREDIENT_ID = "id";

const SubProductIngredients = (props: SubProductIngredientsProps) => {
  // Callback
  const mapProductOptions = useCallback((items: any) => {
    // console.log("mapDivisionOptions items: ", items);
    return items.map((item: any) => ({
      key: item[INGREDIENT_ID],
      value: item[INGREDIENT_ID],
      text: item[INGREDIENT_NAME],
    }));
  }, []);

  const handleSelectedItem = useCallback(
    async (value: any, key: any, obj: any) => {
      let product = props.searchedItemListWithKey?.[
        `ADRingredient_${props.index + 1}`
      ]?.find((item: any) => item[INGREDIENT_ID] === key);

      if (!product?.[INGREDIENT_ID]) {
        await props.setProp(
          `searchedItemListWithKey.ADRingredient_${props.index + 1}`,
          []
        );
      }

      handleChangeValue(null, {
        name: "ingredient",
        value: product?.[INGREDIENT_ID] || null,
      });
    },
    [props.searchedItemListWithKey, props.productDetail]
  );

  const handleChangeValue = (e: any, data: any) => {
    let value = data.value;
    const detail = { ...(props.productDetail || {}) };

    if (data.name === "sequence") {
      value = !value ? "" : Number(value) < 1 ? 1 : value;
    }

    (detail as any).drug.drug_ingredients[props.index][data.name] = value;

    props.setProp(`StockManagementSequence.productDetail`, { ...detail });
  };

  const handleAdd = () => {
    const detail = { ...(props.productDetail || {}) };

    (detail as any).drug.drug_ingredients.push({});

    props.setProp(`StockManagementSequence.productDetail`, { ...detail });
  };

  const handleRemove = () => {
    const detail = { ...(props.productDetail || {}) };

    const ingredients = detail.drug?.drug_ingredients || [];
    ingredients.splice(props.index, 1);

    if (!ingredients.length) {
      (detail as any).drug.drug_ingredients = [{}];
    }

    props.setProp(`StockManagementSequence.productDetail`, { ...detail });
  };

  return (
    <SubProductIngredientsUX
      // data
      strength={props.data.strength}
      sequence={props.data.sequence}
      // config
      hideRemove={props.hideRemove}
      // callback
      onChangeValue={handleChangeValue}
      onAdd={handleAdd}
      onRemove={handleRemove}
      // Element
      SearchBox={
        <SearchBoxDropdown
          onEvent={props.onEvent}
          // config
          type="ADRingredient"
          id={`${props.index + 1}`}
          style={{ width: "100%" }}
          fluid={true}
          useWithKey={true}
          icon="search"
          limit={20}
          // Select
          searchedItemListWithKey={props.searchedItemListWithKey}
          selectedItem={props.data.ingredient}
          setSelectedItem={handleSelectedItem}
          // options
          mapOptions={mapProductOptions}
        />
      }
    />
  );
};

export default React.memo(SubProductIngredients);
