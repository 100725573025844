import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Icon,
  Checkbox,
  Dropdown
} from 'semantic-ui-react'

const CardOrderSummaryUX = (props: any) => {
    return(
      <div>
        <div
          style={{fontSize: "1.5rem", fontWeight: "bold", padding: "5px"}}>
          รายการคำสั่งแพทย์
        </div>
        <div
          style={{padding: "5px", display: "flex", justifyContent: "center",alignItems: "center"}}>
          
          <div
            style={{padding: "5px"}}>
            
            <Icon
              name={"angle double left"}
              onClick={props.onChangeStepDate}>
            </Icon>
          </div>
          <DateTextBox
            onChange={props.onChangeDate}
            value={props.date}>
          </DateTextBox>
          <div
            style={{padding: "5px"}}>
            
            <Icon
              name={"angle double right"}
              onClick={props.onChangeStepDate}>
            </Icon>
          </div>
        </div>
        <div
          style={{padding: "5px", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          
          <Checkbox
            checked={props.filterOrderSummary.isNurseStatus}
            name="isNurseStatus"
            onChange={props.onChangeFilter}>
          </Checkbox>
          <div
            style={{margin: "0 5px 0 5px"}}>
            สถานะคำสั่ง
          </div>
          <Dropdown
            name="nurseStatus"
            onChange={props.onChangeFilter}
            options={[{ 'key': 'wait', 'text': 'รอรับคำสั่ง', value: 'wait' }, { 'key': 'know', 'text': 'รับทราบแล้ว', value: 'know' }, { 'key': 'cancel', 'text': 'ยกเลิก', value: 'cancel' }]}
            selection={true}
            value={props.filterOrderSummary.nurseStatus}>
          </Dropdown>
          <div
            style={{flex: 1}}>
            
          </div>
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <Checkbox
              checked={props.filterOrderSummary.isOutPerformDivision}
              name="isOutPerformDivision"
              onChange={props.onChangeFilter}>
            </Checkbox>
            <label
              style={{margin: "0 10px 0 10px"}}>
              คำสั่งนอกแผนก
            </label>
          </div>
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <Checkbox
              checked={props.filterOrderSummary.isPharmacy}
              name="isPharmacy"
              onChange={props.onChangeFilter}>
            </Checkbox>
            <label
              style={{margin: "0 10px 0 10px"}}>
              Pharmacy
            </label>
          </div>
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <Checkbox
              checked={props.filterOrderSummary.isTreatmentOrder}
              name="isTreatmentOrder"
              onChange={props.onChangeFilter}>
            </Checkbox>
            <label
              style={{margin: "0 10px 0 10px"}}>
              Treatment
            </label>
          </div>
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <Checkbox
              checked={props.filterOrderSummary.isLab}
              name="isLab"
              onChange={props.onChangeFilter}>
            </Checkbox>
            <label
              style={{margin: "0 10px 0 10px"}}>
              Lab
            </label>
          </div>
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <Checkbox
              checked={props.filterOrderSummary.isFood}
              name="isFood"
              onChange={props.onChangeFilter}>
            </Checkbox>
            <label
              style={{margin: "0 10px 0 10px"}}>
              Food
            </label>
          </div>
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <Checkbox
              checked={props.filterOrderSummary.isNoteOrder}
              name="isNoteOrder"
              onChange={props.onChangeFilter}>
            </Checkbox>
            <label
              style={{margin: "0 10px 0 10px"}}>
              Note Order
            </label>
          </div>
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <Checkbox
              checked={props.filterOrderSummary.isProcedure}
              name="isProcedure"
              onChange={props.onChangeFilter}>
            </Checkbox>
            <label
              style={{margin: "0 10px 0 10px"}}>
              Procedure
            </label>
          </div>
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <Checkbox
              checked={props.filterOrderSummary.isHD}
              name="isHD"
              onChange={props.onChangeFilter}>
            </Checkbox>
            <label
              style={{margin: "0 10px 0 10px"}}>
              HD
            </label>
          </div>
        </div>
        <div
          style={{display: "flex"}}>
          
          <div
            style={{flex:1, padding: "5px" }}>
            
            <div
              style={{fontSize: "1.2rem", fontWeight: "bold", padding: "5px"}}>
              One Day
            </div>
            <Table
              data={props.orderSummaryOneDayItems}
              headers={props.isDoctor? "Order,รายการคำสั่งแพทย์,รับคำสั่ง,Menu": ",Order,รายการคำสั่งแพทย์,รับคำสั่ง,Menu"}
              keys={props.isDoctor? "type_name,summary,nurse_status, menu":"_check,type_name,summary,nurse_status, menu"}
              minRows="10"
              showPagination={false}
              style={{height: "75vh"}}
              widths={props.isDoctor? "150px, 275px , auto, 80px": "35px,150px, 275px , auto, 80px"}>
            </Table>
          </div>
          <div
            style={{flex:1, padding: "5px" }}>
            
            <div
              style={{fontSize: "1.2rem", fontWeight: "bold", padding: "5px"}}>
              Continue
            </div>
            <Table
              data={props.orderSummaryItems}
              headers={props.isDoctor? "Order,รายการคำสั่งแพทย์,รับคำสั่ง,Menu":",Order,รายการคำสั่งแพทย์,รับคำสั่ง,Menu"}
              keys={props.isDoctor? "type_name,summary,nurse_status, menu": "_check,type_name,summary,nurse_status, menu"}
              minRows="10"
              showPagination={false}
              style={{height: "75vh"}}
              widths={props.isDoctor? "150px, 275px , auto, 80px" : "35px,150px, 275px , auto, 80px"}>
            </Table>
          </div>
        </div>
        <div
          style={{padding: "5px", display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
          {props.orderPerformAction}
        </div>
      </div>
    )
}

export default CardOrderSummaryUX

export const screenPropsDefault = {}

/* Date Time : Fri Feb 03 2023 04:13:07 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการคำสั่งแพทย์"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.5rem\", fontWeight: \"bold\", padding: \"5px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\", display: \"flex\", justifyContent: \"center\",alignItems: \"center\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\", display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1, padding: \"5px\" }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1, padding: \"5px\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 7,
      "name": "Table",
      "parent": 5,
      "props": {
        "data": {
          "type": "code",
          "value": "props.orderSummaryOneDayItems"
        },
        "headers": {
          "type": "code",
          "value": "props.isDoctor? \"Order,รายการคำสั่งแพทย์,รับคำสั่ง,Menu\": \",Order,รายการคำสั่งแพทย์,รับคำสั่ง,Menu\""
        },
        "keys": {
          "type": "code",
          "value": "props.isDoctor? \"type_name,summary,nurse_status, menu\":\"_check,type_name,summary,nurse_status, menu\""
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"75vh\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.isDoctor? \"150px, 275px , auto, 80px\": \"35px,150px, 275px , auto, 80px\""
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 9,
      "name": "Table",
      "parent": 6,
      "props": {
        "data": {
          "type": "code",
          "value": "props.orderSummaryItems"
        },
        "headers": {
          "type": "code",
          "value": "props.isDoctor? \"Order,รายการคำสั่งแพทย์,รับคำสั่ง,Menu\":\",Order,รายการคำสั่งแพทย์,รับคำสั่ง,Menu\""
        },
        "keys": {
          "type": "code",
          "value": "props.isDoctor? \"type_name,summary,nurse_status, menu\": \"_check,type_name,summary,nurse_status, menu\""
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"75vh\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.isDoctor? \"150px, 275px , auto, 80px\" : \"35px,150px, 275px , auto, 80px\""
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 10,
      "name": "DateTextBox",
      "parent": 2,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate"
        },
        "value": {
          "type": "code",
          "value": "props.date"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Icon",
      "parent": 12,
      "props": {
        "name": {
          "type": "code",
          "value": "\"angle double right\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeStepDate"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Icon",
      "parent": 13,
      "props": {
        "name": {
          "type": "code",
          "value": "\"angle double left\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeStepDate"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Checkbox",
      "parent": 3,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterOrderSummary.isNurseStatus"
        },
        "name": {
          "type": "value",
          "value": "isNurseStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Dropdown",
      "parent": 3,
      "props": {
        "name": {
          "type": "value",
          "value": "nurseStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "[{ 'key': 'wait', 'text': 'รอรับคำสั่ง', value: 'wait' }, { 'key': 'know', 'text': 'รับทราบแล้ว', value: 'know' }, { 'key': 'cancel', 'text': 'ยกเลิก', value: 'cancel' }]"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.filterOrderSummary.nurseStatus"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Checkbox",
      "parent": 18,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterOrderSummary.isOutPerformDivision"
        },
        "name": {
          "type": "value",
          "value": "isOutPerformDivision"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "คำสั่งนอกแผนก"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 10px 0 10px\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Checkbox",
      "parent": 21,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterOrderSummary.isPharmacy"
        },
        "name": {
          "type": "value",
          "value": "isPharmacy"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "Pharmacy"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 10px 0 10px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Checkbox",
      "parent": 24,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterOrderSummary.isTreatmentOrder"
        },
        "name": {
          "type": "value",
          "value": "isTreatmentOrder"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "Treatment"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 10px 0 10px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Checkbox",
      "parent": 27,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterOrderSummary.isLab"
        },
        "name": {
          "type": "value",
          "value": "isLab"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 10px 0 10px\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Checkbox",
      "parent": 30,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterOrderSummary.isFood"
        },
        "name": {
          "type": "value",
          "value": "isFood"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "Food"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 10px 0 10px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Checkbox",
      "parent": 33,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterOrderSummary.isNoteOrder"
        },
        "name": {
          "type": "value",
          "value": "isNoteOrder"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "label",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "Note Order"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 10px 0 10px\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Checkbox",
      "parent": 36,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterOrderSummary.isProcedure"
        },
        "name": {
          "type": "value",
          "value": "isProcedure"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "Procedure"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 10px 0 10px\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Checkbox",
      "parent": 39,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterOrderSummary.isHD"
        },
        "name": {
          "type": "value",
          "value": "isHD"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "label",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "HD"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 10px 0 10px\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะคำสั่ง"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "One Day"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\", padding: \"5px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "Continue"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\", padding: \"5px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderPerformAction"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\", display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\"}"
        }
      },
      "seq": 45,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardOrderSummaryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 75
}

*********************************************************************************** */
