import WasmController from "../../../../frameworks/WasmController";
import moment from "moment";
import { formatDate } from "react-lib/utils/dateUtils";
import getPdfMake from "react-lib/appcon/common/pdfMake";
import FormDrugUseRecord from "../FormDrugUseRecord";
// APIs
import MedicationRecordReportList from "issara-sdk/apis/MedicationRecordReportList_apps_TPD";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  masterOptions?: any;

  // sequence
  ReportDrugUseRecordSequence?: {
    sequenceIndex?: string | null;

    filterDrugList?: {
      division?: number | null;
      start_admin?: string;
      end_admin?: string;
    };
    drugUseRecordList?: any;
  } | null;
};

export const StateInitial: State = {
  ReportDrugUseRecordSequence: {
    sequenceIndex: null,

    filterDrugList: {
      division: null,
      start_admin: "",
      end_admin: "",
    },
    drugUseRecordList: {},
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const GetMaster: Handler = async (controller, params) => {
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["divisionWard", {}]],
    },
  });

  controller.setState({
    ReportDrugUseRecordSequence: {
      sequenceIndex: "Action",
      drugUseRecordList: {},
    },
  });
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "FETCH") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    if (!state.ReportDrugUseRecordSequence?.filterDrugList?.division) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: "ระบุ Ward ก่อนทำรายการ",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
      return;
    }

    const [response, error, network] = await MedicationRecordReportList.list({
      apiToken: controller.apiToken,
      params: state.ReportDrugUseRecordSequence?.filterDrugList,
    });
    if (error) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: error,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    } else {
      const drugList = response?.items?.map((item: any) => ({
        ...item,
        order_date_text: formatDate(moment(item?.order_date)),
        drug_quantity_text: `${item?.drug_quantity} ${
          item?.drug_unit_text || ""
        }`,
        apply_text: `${item?.apply} ${item?.drug_unit_text || ""}`,
        reject_text: `${item?.reject} ${item?.drug_unit_text || ""}`,
        admin_date_text: formatDate(moment(item?.admin_date)),
        admin_time_text: moment(item?.admin_time, "HH:mm:ss").format("HH:mm"),
        hn_an: `${item?.hn} (${item?.en_number})`,
      }));

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "SUCCESS",
        },
        ReportDrugUseRecordSequence: {
          ...state.ReportDrugUseRecordSequence,
          drugUseRecordList: { ...response, items: drugList },
        },
      });
    }
  } else if (params?.action === "PRINT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    const wards: any[] = (state.masterOptions?.divisionWard || []).filter(
      (item: any) =>
        item.value === state.ReportDrugUseRecordSequence?.filterDrugList?.division
    );

    const data = Object.assign({
      ward: wards?.[0]?.text || " - ",
      start_date:
        state.ReportDrugUseRecordSequence?.filterDrugList?.start_admin || " - ",
      end_date:
        state.ReportDrugUseRecordSequence?.filterDrugList?.end_admin || " - ",
      data: state.ReportDrugUseRecordSequence?.drugUseRecordList?.items || [],
    });

    let docDef: any = { content: [] };

    docDef = FormDrugUseRecord(data);

    const pdfMake = (await getPdfMake()).createPdf(docDef);
    pdfMake.open();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "SUCCESS",
      },
    });
    return;
  }
};
