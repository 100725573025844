import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Checkbox,
  TextArea,
  Segment,
  Label,
  Dropdown,
  Form
} from 'semantic-ui-react'

const ModWaitingListManageUX = (props: any) => {
    return(
      <div>
        
        <Segment>
          <Label
            color="blue"
            ribbon={true}
            style={{fontSize: "1.2rem", fontWeight: "bold"}}>
            จัดการรายชื่อผู้ป่วย Waiting List
          </Label>
          <Label
            size="big">
            {props.waitingListName}
          </Label>
          <div
            style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "20px 0px 10px 0px"}}>
            
            <div
              style={{display: "flex", flexDirection: "column", width: "40%", padding: "0px 5px"}}>
              ชื่อ-สกุล / HN
              <div
                style={{width: "100%", margin: "10px 0px"}}>
                {props.patientSelector}
              </div>
            </div>
            <div
              style={{display: "flex", flexDirection: "column", width: "30%",padding: "0px 5px"}}>
              ทันตแพทย์ / นิสิต
              <div
                style={{width: "100%", margin: "10px 0px"}}>
                {props.doctorSelector}
              </div>
            </div>
            <div
              style={{flex:1}}>
              
            </div>
            <div
              style={{display: "flex", flexDirection: "column", alignSelf: "flex-end",paddingBottom: "12px"}}>
              {}
              <Button
                color="green"
                disabled={props.disableAddPatient}
                onClick={props.addPatientToWaitingList}
                size="small">
                เพิ่มผู้ป่วยเข้า List
              </Button>
            </div>
            <div
              style={{display: "flex", flexDirection: "column",alignSelf: "flex-end",paddingBottom: "12px"}}>
              
              <Button
                color="blue"
                disabled={props.disabledPrint}
                onClick={props.printWaitingList}
                size="small">
                พิมพ์รายการทั้งหมด
              </Button>
            </div>
          </div>
          <div
            style={{display: "flex", justifyContent: "flex-start", alignItems: "center", padding: "10px 10px"}}>
            
            <Checkbox
              checked={props.isAppointed}
              label="แสดงรายชื่อผู้ป่วยที่มีนัดหมายแล้ว"
              onClick={props.toggleIsAppointed}
              style={{marginRight: "30px"}}>
            </Checkbox>
            <Checkbox
              checked={props.isOnHoldQ}
              label="แสดงรายชื่อผู้ป่วยที่พักคิว"
              onClick={props.toggleIsOnHoldQ}
              style={{marginRight: "30px"}}>
            </Checkbox>
            <Checkbox
              checked={props.isCancel}
              label=" แสดงรายชื่อผู้ป่วยที่ยกเลิก"
              onClick={props.toggleIsCancel}
              style={{display: "none"}}>
            </Checkbox>
          </div>
          <Table
            data={props.waitingListItems}
            getTrProps={props.getTrPropsWaitingList}
            headers=",HN,ชื่อ-สกุล,เบอร์โทรติดต่อ,แพทย์/นิสิต,สถานะ,ประเภทการรักษา,Note"
            keys="checkbox,patient_hn,patient_name,patient_tel,provider_name,status_name,type_name,note"
            minRows="4"
            showPagination={false}
            style={{height: "20vh"}}
            widths="30,80,auto,120,150,80,120,100">
          </Table>
          <div
            style={{ marginTop:"15px", display: props.disableAssign ? "none" : "block" }}>
            
            <div
              style={{display: "flex", flexDirection:"row"}}>
              
              <div
                style={{width: "50%"}}>
                {props.providerSelector}
              </div>
              <div
                style={{display:"flex", justifyContent: "center", alignItems:"center"}}>
                ประเภทการนัดหมาย
                <Dropdown
                  onChange={props.onChangeTreatmentType}
                  options={props.treatmentTypeOptions}
                  selection={true}
                  style={{marginLeft:"10px"}}
                  value={props.treatmentType}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{margin:"20px 0px 10px 0px",fontWeight: "bold"}}>
              รายละเอียด, ตำแหน่ง, หมายเหตุ
            </div>
            <Form>
              <TextArea
                onChange={props.changeNote}
                style={{width: "100%", margin :"0px 0px 20px 0px"}}
                value={props.note}>
              </TextArea>
            </Form>
            <div
              style={{display:"flex", flexDirection: "row"}}>
              
              <Button
                color="green"
                disabled={props.disableAssign}
                onClick={props.assignWaitingListItems}
                size="tiny"
                style={{marginRight: "10px"}}>
                Save
              </Button>
              <Button
                color="orange"
                disabled={props.disabledPauseQ}
                onClick={props.onClickPauseQ}
                size="tiny"
                style={{marginRight: "10px"}}>
                พักคิว
              </Button>
              <Button
                color="blue"
                disabled={props.disabledResumeQ}
                onClick={props.onClickResumeQ}
                size="tiny"
                style={{marginRight: "10px"}}>
                คืนคิว
              </Button>
              <Button
                color="red"
                onClick={props.onCancelTreatmentPlan}
                size="tiny"
                style={{marginRight: "10px"}}>
                ยกเลิกวางแผนการรักษา
              </Button>
              <Button
                onClick={props.onSendToClinic}
                size="tiny"
                style={{backgroundColor: "#5bbdd2", color: "white"}}>
                ส่งต่อคลินิก
              </Button>
            </div>
          </div>
          <div
            style={{padding: "10px",display:props.openModClinic ? "":"none"}}>
            {props.OpenClinic}
          </div>
        </Segment>
      </div>
    )
}

export default ModWaitingListManageUX

export const screenPropsDefault = {"patientSelector":"[Patient Selector]","providerSelector":"[Provider Selector]"}

/* Date Time : Fri Sep 09 2022 04:50:53 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 20,
      "props": {
        "data": {
          "type": "code",
          "value": "props.waitingListItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrPropsWaitingList"
        },
        "headers": {
          "type": "value",
          "value": ",HN,ชื่อ-สกุล,เบอร์โทรติดต่อ,แพทย์/นิสิต,สถานะ,ประเภทการรักษา,Note"
        },
        "keys": {
          "type": "value",
          "value": "checkbox,patient_hn,patient_name,patient_tel,provider_name,status_name,type_name,note"
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"20vh\"}"
        },
        "widths": {
          "type": "value",
          "value": "30,80,auto,120,150,80,120,100"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\", padding: \"20px 0px 10px 0px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มผู้ป่วยเข้า List"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disableAddPatient"
        },
        "onClick": {
          "type": "code",
          "value": "props.addPatientToWaitingList"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSelector"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", margin: \"10px 0px\"}",
          "valueEN": ""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-start\", alignItems: \"center\", padding: \"10px 10px\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Checkbox",
      "parent": 8,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isAppointed"
        },
        "label": {
          "type": "value",
          "value": "แสดงรายชื่อผู้ป่วยที่มีนัดหมายแล้ว"
        },
        "onClick": {
          "type": "code",
          "value": "props.toggleIsAppointed"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"30px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "code",
          "value": "props.providerSelector"
        },
        "style": {
          "type": "code",
          "value": "{width: \"50%\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginTop:\"15px\", display: props.disableAssign ? \"none\" : \"block\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "Save"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disableAssign"
        },
        "onClick": {
          "type": "code",
          "value": "props.assignWaitingListItems"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์รายการทั้งหมด"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledPrint"
        },
        "onClick": {
          "type": "code",
          "value": "props.printWaitingList"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "ทันตแพทย์ / นิสิต"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flexDirection: \"column\", width: \"30%\",padding: \"0px 5px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "TextArea",
      "parent": 36,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeNote"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", margin :\"0px 0px 20px 0px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.note"
        }
      },
      "seq": 30,
      "void": true
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", flexDirection: \"row\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียด, ตำแหน่ง, หมายเหตุ"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"20px 0px 10px 0px\",fontWeight: \"bold\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.OpenClinic"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\",display:props.openModClinic ? \"\":\"none\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Segment",
      "parent": 0,
      "props": {
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "จัดการรายชื่อผู้ป่วย Waiting List"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "ribbon": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุล / HN"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flexDirection: \"column\", width: \"40%\", padding: \"0px 5px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSelector"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flexDirection: \"column\", alignSelf: \"flex-end\",paddingBottom: \"12px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flexDirection: \"column\",alignSelf: \"flex-end\",paddingBottom: \"12px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Checkbox",
      "parent": 8,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOnHoldQ"
        },
        "label": {
          "type": "value",
          "value": "แสดงรายชื่อผู้ป่วยที่พักคิว"
        },
        "onClick": {
          "type": "code",
          "value": "props.toggleIsOnHoldQ"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"30px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Dropdown",
      "parent": 31,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeTreatmentType"
        },
        "options": {
          "type": "code",
          "value": "props.treatmentTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.treatmentType"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flexDirection:\"row\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทการนัดหมาย"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent: \"center\", alignItems:\"center\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "พักคิว"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledPauseQ"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickPauseQ"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "คืนคิว"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledResumeQ"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickResumeQ"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกวางแผนการรักษา"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelTreatmentPlan"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Label",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.waitingListName"
        },
        "size": {
          "type": "value",
          "value": "big"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Form",
      "parent": 12,
      "props": {
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Checkbox",
      "parent": 8,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isCancel"
        },
        "label": {
          "type": "value",
          "value": " แสดงรายชื่อผู้ป่วยที่ยกเลิก"
        },
        "onClick": {
          "type": "code",
          "value": "props.toggleIsCancel"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่งต่อคลินิก"
        },
        "color": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onSendToClinic"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#5bbdd2\", color: \"white\"}"
        }
      },
      "seq": 38,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "ModWaitingListManageUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "patientSelector": "[Patient Selector]",
    "providerSelector": "[Provider Selector]"
  },
  "width": 40
}

*********************************************************************************** */
