import React, { useEffect, useState } from "react";
import { Button, Checkbox, Input } from "semantic-ui-react";
// ui ORM
import CardSurgicalSafetyChecklistSignoutUX from "./CardSurgicalSafetyChecklistSignoutUX";
import { PreOPButtonAction } from "./CardPreOPVisit";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";

const CARD_KEY: string = "surgicalSafetyChecklistSigninOut";
const FORM_CODE: string = "CardAfterClosureAndWoundCare";
const FORM_NAME: string = "After closure & Wound care";
const FORM_VERSION: string = "0.1";

type CardSurgicalSafetyChecklistSignoutProps = {
  // function
  onEvent: any;
  setProp: any;
  buttonLoadCheck?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;
  masterData?: any;

  // seq
  runSequence?: any;
  PerioperativeNursingSequence?: any;
};

const CardSurgicalSafetyChecklistSignoutInitial: CardSurgicalSafetyChecklistSignoutProps =
  {
    // funtion
    onEvent: () => null,
    setProp: () => null,
    buttonLoadCheck: null,
  };

const Specimen = {
  tissue: "",
  other_specimen: "",
  culture: "",
  is_tissue: false,
  is_other_specimen: false,
  is_frozen: false,
  is_estrogen_rec: false,
  is_cyto_section: false,
  is_culture: false,
};

const CardSurgicalSafetyChecklistSignout: React.FC<
  CardSurgicalSafetyChecklistSignoutProps
> = (props: any) => {
  const [closureDate, setClosureDate] = useState("");
  const [closureTime, setClosureTime] = useState("");
  const [remark, setRemark] = useState("");

  useEffect(() => {
    props.runSequence({
      sequence: "PreOperation",
      action: "FETCH_FORM_DATA_LATEST",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formVersion: FORM_VERSION,
    });
  }, []);

  useEffect(() => {
    setClosureDate(
      props.PerioperativeNursingSequence?.operatingDetail?.closure_date
    );
    setClosureTime(
      props.PerioperativeNursingSequence?.operatingDetail?.closure_time
    );
    setRemark(props.PerioperativeNursingSequence?.operatingDetail?.remark);
  }, [props.PerioperativeNursingSequence?.operatingDetail]);

  const handleChangeValue = (key: string) => (_event: any, data: any) => {
    if (key === "remark") {
      setRemark(data.value);
    } else {
      if (data.type === "checkbox" || data.type === "radio") {
        props.setProp(
          `PerioperativeNursingSequence.surgicalSafetyChecklistSigninOut.data.${key}`,
          data.checked
        );
      } else {
        props.setProp(
          `PerioperativeNursingSequence.surgicalSafetyChecklistSigninOut.data.${key}`,
          data.value
        );
      }
    }
  };

  const handleChangeDate = (key: string) => (date: any) => {
    setClosureDate(date);
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SAVE",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
      closure_date: closureDate,
      closure_time: closureTime,
      remark: remark,
    });
  };

  const handleConfirm = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "CONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
      closure_date: closureDate,
      closure_time: closureTime,
      remark: remark,
    });
  };

  const handleUnconfirm = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "UNCONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
      closure_date: closureDate,
      closure_time: closureTime,
      remark: remark,
    });
  };

  const handleChangeSpecimenComponent = (value: any, index: any, key: any) => {
    props.setProp(
      `PerioperativeNursingSequence.surgicalSafetyChecklistSigninOut.data.specimen_items.${index}.${key}`,
      value
    );
  };

  const handlerAddRound = () => {
    const items: any[] =
      props.PerioperativeNursingSequence?.surgicalSafetyChecklistSigninOut?.data
        ?.specimen_items || [];
    items.push({ Specimen });
    props.setProp(
      "PerioperativeNursingSequence.surgicalSafetyChecklistSigninOut.data.specimen_items",
      items
    );
  };

  const handlerDeleteRound = (index: any) => {
    const items: any[] =
      props.PerioperativeNursingSequence?.surgicalSafetyChecklistSigninOut?.data
        ?.specimen_items || [];

    if (items.length === 1) {
      props.setProp(
        "PerioperativeNursingSequence.surgicalSafetyChecklistSigninOut.data.specimen_items.0",
        {
          ...props.PerioperativeNursingSequence.surgicalSafetyChecklistSigninOut
            .data?.specimen_items?.[0],
          tissue: "",
          other_specimen: "",
          culture: "",
          is_tissue: false,
          is_other_specimen: false,
          is_frozen: false,
          is_estrogen_rec: false,
          is_cyto_section: false,
          is_culture: false,
        }
      );
    } else if (items.length > 1) {
      items.splice(index, 1);
      props.setProp(
        "PerioperativeNursingSequence.surgicalSafetyChecklistSigninOut.data.specimen_items",
        items
      );
    }
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={null}
      />
      <CardSurgicalSafetyChecklistSignoutUX
        surgicalSafety={
          props.PerioperativeNursingSequence?.surgicalSafetyChecklistSigninOut
        }
        masterOptions={props.masterOptions}
        handleChangeValue={handleChangeValue}
        handleChangeDate={handleChangeDate}
        remark={remark}
        closureDate={closureDate}
        status={
          props.PerioperativeNursingSequence?.surgicalSafetyChecklistSigninOut
            ?.status
        }
        specimenComponent={
          <div
            style={{
              display: props.PerioperativeNursingSequence
                ?.surgicalSafetyChecklistSigninOut?.data?.no_specimen
                ? "block"
                : "none",
            }}
          >
            {props.PerioperativeNursingSequence?.surgicalSafetyChecklistSigninOut?.data?.specimen_items?.map(
              (item: any, index: any) => {
                return (
                  <RoundData
                    item={item}
                    index={index}
                    onChangeSpecimenComponent={handleChangeSpecimenComponent}
                    handlerDeleteRound={handlerDeleteRound}
                    handlerAddRound={handlerAddRound}
                  />
                );
              }
            )}
          </div>
        }
        buttonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="save"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave}
          />
        }
        buttonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="confirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleConfirm}
          />
        }
        buttonUnconfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="unconfirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleUnconfirm}
          />
        }
        timeTextBox={
          <TimeComboBox
            noMinWidth={true}
            defaultValue={closureTime || ""}
            onTextChange={(time) => setClosureTime(time)}
          />
        }
      />
    </>
  );
};

const RoundData = (props: any) => {
  const handleChange = (key: string) => (e: any, data?: any) => {
    const value = data.type === "checkbox" ? data.checked : data.value;
    props.onChangeSpecimenComponent(value, props.index, key);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginBottom: "15px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "20%",
        }}
      >
        <Checkbox
          checked={props.item?.is_tissue}
          label={"Tissue"}
          onChange={handleChange("is_tissue")}
          style={{ fontWeight: "bold", marginRight: "10px" }}
        ></Checkbox>
        <div style={{ width: "60%" }}>
          <Input
            value={props.item?.tissue}
            onChange={handleChange("tissue")}
            disabled={props.item?.is_tissue ? false : true}
          ></Input>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "0px 10px",
          width: "20%",
        }}
      >
        <Checkbox
          checked={props.item?.is_culture}
          label={"Culture"}
          onChange={handleChange("is_culture")}
          style={{ fontWeight: "bold", marginRight: "10px" }}
        ></Checkbox>
        <div style={{ width: "60%" }}>
          <Input
            value={props.item?.culture}
            onChange={handleChange("culture")}
            disabled={props.item?.is_culture ? false : true}
          ></Input>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "30%",
          margin: "0px 10px",
          justifyContent: "center",
        }}
      >
        <Checkbox
          checked={props.item?.is_frozen}
          label={"Frozen"}
          onChange={handleChange("is_frozen")}
          style={{ fontWeight: "bold", marginRight: "10px" }}
        ></Checkbox>
        <Checkbox
          checked={props.item?.is_cyto_section}
          label={"Cyto_section"}
          onChange={handleChange("is_cyto_section")}
          style={{ fontWeight: "bold", marginRight: "10px" }}
        ></Checkbox>
        <Checkbox
          checked={props.item?.is_estrogen_rec}
          label={"Estrogen rec"}
          onChange={handleChange("is_estrogen_rec")}
          style={{ fontWeight: "bold", marginRight: "10px" }}
        ></Checkbox>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "0px 10px",
          width: "20%",
        }}
      >
        <Checkbox
          checked={props.item?.is_other_specimen}
          label={"Other"}
          onChange={handleChange("is_other_specimen")}
          style={{ fontWeight: "bold", marginRight: "10px" }}
        ></Checkbox>
        <div style={{ margin: "0px 10px", width: "60%" }}>
          <Input
            value={props.item?.other_specimen}
            onChange={handleChange("other_specimen")}
            disabled={props.item?.is_other_specimen ? false : true}
          ></Input>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "10%",
        }}
      >
        <Button
          color="red"
          icon="minus"
          size="mini"
          style={{ margin: "0px 20px" }}
          onClick={() => props.handlerDeleteRound(props.index)}
        ></Button>
        <Button
          color="blue"
          icon="plus"
          size="mini"
          onClick={() => props.handlerAddRound()}
        ></Button>
      </div>
    </div>
  );
};

CardSurgicalSafetyChecklistSignout.defaultProps =
  CardSurgicalSafetyChecklistSignoutInitial;

export default React.memo(CardSurgicalSafetyChecklistSignout);
