import React from 'react';
import CardLayout from '../common/CardLayout';
import ReactTable from 'react-table-6';
import treeTableHOC from '../common/TreeTableHOC';
import { Grid, Form } from 'semantic-ui-react'

const CardOrderSummaryView = (props) => {

  const oneDayData = props.orderSummaryData.oneDay ? props.orderSummaryData.oneDay : []
  const continueData = props.orderSummaryData.continue ? props.orderSummaryData.continue : []
  const TreeTable = treeTableHOC(ReactTable);

  return (
    <CardLayout
      titleText='Order Summary'
      headerColor='yellow'
      closeable={props.closeable}
      toggleable={props.toggleable}
      defaultBackground={props.defaultBackground}
      hideHeaderIcon={props.hideHeaderIcon}
      loading={props.orderSummaryLoading}
      enableMargin={props.enableMargin}
    >
      <Grid columns={2}>
        <Grid.Column>
          <TreeTable
            style={{ height: 470, backgroundColor: '#FFFFFF' }}
            showPagination={false}
            noDataText='ไม่มีข้อมูล'
            data={oneDayData}
            pageSize={oneDayData ? (oneDayData.length < 12 ? 12 : oneDayData.length) : 12}
            pivotBy={["real_order_time"]}
            columns={[{
              accessor: 'real_order_time',
              classname: 'real_order_time',
            }, {
              Header: 'Order',
              accessor: 'order_time',
              style: { 'whiteSpace': 'unset' },
              Cell: (oneDay) => {
                return (
                  <Form >
                    <Form.Field className='inline'>
                      <div dangerouslySetInnerHTML={{ __html: oneDay.original.order_time }} />
                    </Form.Field>
                  </Form>
                );
              }
            },
            {
              Header: 'รายการคำสั่งแพทย์',
              accessor: 'summary',
              style: { 'whiteSpace': 'unset' },
              Cell: (oneDay) => {
                return (
                  <div dangerouslySetInnerHTML={{ __html: oneDay.original.summary }} />
                );
              }
            },
            {
              Header: 'รับคำสั่ง',
              accessor: 'nurse_order',
              style: { 'whiteSpace': 'unset' },
              Cell: (oneDay) => {
                return (
                  <Form >
                    <Form.Field>
                      <div dangerouslySetInnerHTML={{ __html: oneDay.original.nurse_order }} />
                    </Form.Field>
                  </Form>
                );
              },
            }]}
          />
        </Grid.Column>
        <Grid.Column>
          <TreeTable
            style={{ height: 470, backgroundColor: '#FFFFFF' }}
            showPagination={false}
            noDataText='ไม่มีข้อมูล'
            data={continueData}
            pageSize={continueData ? (continueData.length < 12 ? 12 : continueData.length) : 12}
            pivotBy={["real_order_time"]}
            columns={[{
              Header: 'Real order time',
              accessor: 'real_order_time'
            },
            {
              Header: 'Order',
              accessor: 'order_time',
              style: { 'whiteSpace': 'unset' },
              Cell: (grdContinue) => {
                return (
                  <Form >
                    <Form.Field className='inline'>
                      <div dangerouslySetInnerHTML={{ __html: grdContinue.original.order_time }} />
                    </Form.Field>
                  </Form>
                );
              }
            },
            {
              Header: 'รายการคำสั่งแพทย์',
              accessor: 'summary',
              style: { 'whiteSpace': 'unset' },
              Cell: (grdContinue) => {
                return (
                  <div dangerouslySetInnerHTML={{ __html: grdContinue.original.summary }} />
                );
              }
            },
            {
              Header: 'รับคำสั่ง',
              accessor: 'nurse_order',
              style: { 'whiteSpace': 'unset' },
              Cell: (grdContinue) => {
                return (
                  <Form >
                    <Form.Field>
                      <div dangerouslySetInnerHTML={{ __html: grdContinue.original.nurse_order }} />
                    </Form.Field>
                  </Form>
                );
              },
            }]}
          />
        </Grid.Column>
      </Grid>
    </CardLayout>
  )
}

CardOrderSummaryView.defaultProps = {
  closeable: true,
  toggleable: true,
  defaultBackground: true,
  hideHeaderIcon: false,
  onGetOrderSummary: () => { },
  orderSummaryLoading: false,
  orderSummaryData: {},
  enableMargin: true,
};

export default CardOrderSummaryView;
