import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Input,
  Checkbox,
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardStockManagementTabHistoryUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px 0",  height: "100%"}}>
        <div
          className="ui form"
          style={{marginBottom: "1rem"}}>
          
          <div
            style={{fontSize: "1.1rem", fontWeight: "bold"}}>
            ตรวจสอบความเคลื่อนไหวของสินค้า
          </div>
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content",marginRight: "-0.5rem"}}>
                คลังสินค้า
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Input
                fluid={true}
                readOnly={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%", minWidth: "120px",marginRight: "-0.5rem"}}
                value={props.storage || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content", fontWeight: "normal",marginRight: "-0.5rem"}}>
                วันที่
              </label>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%",marginRight: "-0.5rem"}}>
                {props.StartDate}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "fit-content", fontWeight: "normal",marginRight: "-0.5rem"}}>
                ถึง
              </label>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%"}}>
                {props.EndDate}
              </div>
            </FormField>
            <FormField>
              <label
                style={{marginRight: "-0.5rem"}}>
                Lot
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%",marginRight: "-0.5rem"}}>
                {props.LotDropdown}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content",marginRight: "-0.5rem"}}>
                เคลื่อนไหว
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.isMoveIn || false}
                disabled={props.readOnly || false}
                label="เข้า"
                name="isMoveIn"
                onChange={props.onChangeValue}
                style={{fontWeight: "normal",marginRight: "-0.5rem"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.isMoveOut || false}
                disabled={props.readOnly || false}
                label="ออก"
                name="isMoveOut"
                onChange={props.onChangeValue}
                style={{fontWeight: "normal",marginRight: "-0.5rem"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{width: "calc(100vw - 104.75rem)", maxWidth: "max-content",marginRight: "-0.5rem"}}>
                ประเภทการเคลื่อนไหว
              </label>
            </FormField>
            <FormField
              className="noPadding"
              inline={true }
              width={3}>
              <Dropdown
                disabled={props.readOnly || false}
                fluid={true}
                name="movementType"
                onChange={props.onChangeValue}
                options={props.movementTypeOptions || []}
                search={true}
                selection={true}
                style={{width: "100%",marginRight: "-0.5rem"}}
                value={props.movementType || ""}>
              </Dropdown>
            </FormField>
            <div
              className="field inline"
              style={{flex: 1}}>
              
            </div>
            <FormField
              inline={true}>
              <div
                style={{marginRight: "-0.5rem"}}>
                {props.ButtonSearch}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="yellow"
                disabled={props.disabledClear || false}
                onClick={props.onClear}
                size="small"
                style={{marginRight: "-0.5rem",paddingRight: "1em", paddingLeft: "1em"}}>
                เคลียร์
              </Button>
            </FormField>
            <FormField>
              <div
                style={{marginRight: "-1rem"}}>
                {props.ButtonPrint}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form">
          
          <Table
            data={props.stockHistoryList || []}
            headers="วันที่ / เวลา,การเคลื่อนไหว,เข้า/ออก,ผู้ดำเนินการ,เลขที่อ้างอิง,จำนวนที่เคลื่อนไหว,Lot. No.,จำนวนคงเหลือ"
            keys="datetime,reference_text,in_out_bound, edit_user_name,ref_no, quantity,lot_no,balance"
            minRows={5}
            showPagination={false}
            style={{height: "250px"}}>
          </Table>
        </div>
      </div>
    )
}

CardStockManagementTabHistoryUX.displayName = "CardStockManagementTabHistoryUX";
export default React.memo(CardStockManagementTabHistoryUX)

export const screenPropsDefault = {}

/* Date Time : Thu Jul 06 2023 07:47:14 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px 0\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "คลังสินค้า"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\",marginRight: \"-0.5rem\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Input",
      "parent": 4,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth: \"120px\",marginRight: \"-0.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.storage || \"\""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 2,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding"
        },
        "inline": {
          "type": "code",
          "value": "true "
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\",marginRight: \"-0.5rem\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\", fontWeight: \"normal\",marginRight: \"-0.5rem\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "เคลื่อนไหว"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\",marginRight: \"-0.5rem\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Checkbox",
      "parent": 13,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isMoveIn || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "label": {
          "type": "value",
          "value": "เข้า"
        },
        "name": {
          "type": "value",
          "value": "isMoveIn"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\",marginRight: \"-0.5rem\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Checkbox",
      "parent": 14,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isMoveOut || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "label": {
          "type": "value",
          "value": "ออก"
        },
        "name": {
          "type": "value",
          "value": "isMoveOut"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\",marginRight: \"-0.5rem\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทการเคลื่อนไหว"
        },
        "style": {
          "type": "code",
          "value": "{width: \"calc(100vw - 104.75rem)\", maxWidth: \"max-content\",marginRight: \"-0.5rem\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Dropdown",
      "parent": 16,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "movementType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.movementTypeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",marginRight: \"-0.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.movementType || \"\""
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Button",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "เคลียร์"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledClear || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"-0.5rem\",paddingRight: \"1em\", paddingLeft: \"1em\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 31,
      "name": "Table",
      "parent": 30,
      "props": {
        "data": {
          "type": "code",
          "value": "props.stockHistoryList || []"
        },
        "headers": {
          "type": "value",
          "value": "วันที่ / เวลา,การเคลื่อนไหว,เข้า/ออก,ผู้ดำเนินการ,เลขที่อ้างอิง,จำนวนที่เคลื่อนไหว,Lot. No.,จำนวนคงเหลือ"
        },
        "keys": {
          "type": "value",
          "value": "datetime,reference_text,in_out_bound, edit_user_name,ref_no, quantity,lot_no,balance"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"250px\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1rem\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "ตรวจสอบความเคลื่อนไหวของสินค้า"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props.EndDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.StartDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",marginRight: \"-0.5rem\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field inline"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"-0.5rem\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 2,
      "props": {
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "Lot"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"-0.5rem\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "code",
          "value": "props.LotDropdown"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",marginRight: \"-0.5rem\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 2,
      "props": {
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonPrint"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"-1rem\"}"
        }
      },
      "seq": 43,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardStockManagementTabHistoryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
