import { ACTION } from "../../ORM/CardOtherForm";
import * as ARInvoiceGroupI from "./ARInvoiceGroup"
import * as ExportEClaimFileI from "./ExportEClaimFile"
import * as DownloadSentClaimFileI from "./DownloadSentClaimFile"

const SequencePattern: {
  [name: string]: { [flavor: string]: { [func: string]: any } };
} = {
  ARInvoiceGroup: {
    default: {
      START: ARInvoiceGroupI.Start,
      ACTION: ARInvoiceGroupI.Action
    },
  },
  ExportEClaimFile: {
    default: {
      START: ExportEClaimFileI.GetMaster,
      ACTION: ExportEClaimFileI.Action
    },
  },
  DownloadSentClaimFile: {
    default: {
      START: DownloadSentClaimFileI.GetMaster,
      ACTION: DownloadSentClaimFileI.Action
    },
  }
};

export default SequencePattern;