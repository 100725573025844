import React, { useEffect, useState, useMemo, useCallback } from "react";

import { Button, Modal, Label } from "semantic-ui-react";
import { Dialog } from "@mui/material";
import { Table } from "react-lib/frameworks/Table";
import DeleteColumn from "react-lib/appcon/common/DeleteColumn";
import CardRejectOrderUX from "./CardRejectOrderUX";
import CardDrugOrder from "react-lib/apps/HISV3/TPD/CardDrugOrder";

const CARD_DRUG_ORDER_ID = "CardDrugOrder";
const CARD_REJECT_ORDER = "CardRejectOrder";
const CardRejectOrder = (props: any) => {
  const [openModDrug, setOpenModDrug] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();

  useEffect(() => {
    if (props.runSequence) {
      props?.runSequence({ sequence: "RejectOrder", restart: true });

      return () => {
        props?.runSequence({ sequence: "RejectOrder", clear: true });
      };
    }
  }, []);

  const handleClose = useCallback(async () => {
    await props.setProp("selectedEncounter", null);
    props.setProp("selectedEmr", null);
    setOpenModDrug(false);
  }, []);

  useEffect(() => {
    if (props.successMessage?.[CARD_REJECT_ORDER]) {
      handleClose();
    }
  }, [props.successMessage?.[CARD_REJECT_ORDER]]);

  const rejectOrderList = useMemo(() => {
    return (props.RejectOrderSequence?.RejectOrderList?.items || [])?.map(
      (item: any) => ({
        ...item,
        id: item.order_id,
        emr: (
          <>
            <Button
              size="mini"
              icon="edit"
              color="grey"
              onClick={() => {
                const verboseName: string = item.model_verbose_name || "";
                let name = verboseName.match(/ .*$/g)?.[0]?.trim() || ""
     

                name = name.includes("HOME")
                  ? name
                  : `${name} ${item.encounter_type}`;

                props.runSequence({
                  sequence: "RejectOrder",
                  action: "select_encounter",
                  item: item,
                  card: CARD_REJECT_ORDER,
                  goToMenu: "Med Order",
                  goToTab: name.replace(/ /g, "_"),
                });
                props?.layout === 0 && props?.forward?.();
              }}
            />
          </>
        ),
      })
    );
  }, [props.RejectOrderSequence?.RejectOrderList?.items]);

  const adrIcon = useMemo(() => {
    if (props.patientADR?.items) {
      if (props.patientADR?.items?.length === 0) {
        return null;
      } else if (
        props.patientADR?.items?.length === 1 &&
        props.patientADR?.items?.[0].status === "NO_REACTION"
      ) {
        return <Label> NKA </Label>;
      } else {
        return <Label color="red"> ADR </Label>;
      }
    }
  }, [props.patientADR]);

  const ardList = useMemo(
    () =>
      props.patientADR?.items?.map((item: any, idx: any) => {
        return {
          ...item,
        };
      }),
    [props.patientADR]
  );

  const handleChangeValue = (key: string) => (e: any, v: any) => {
    props.setProp(`RejectOrderSequence.${key}`, v.value);
  };

  const handleRowProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.order_id &&
          rowInfo?.original?.order_id === selectedRow?.order_id
            ? "#cccccc"
            : "white",
      },
      onClick: () => {
        setSelectedRow(rowInfo?.original);
      },
      onDoubleClick: () => {
        props.runSequence({
          sequence: "RejectOrder",
          action: "select_drug",
          item: rowInfo?.original,
          card: CARD_DRUG_ORDER_ID,
        });
        setOpenModDrug(true);
        setSelectedRow(rowInfo.original);
      },
    };
  };

  return (
    <>
      <CardRejectOrderUX
        rejectOrderList={rejectOrderList}
        getTrProps={handleRowProps}
        handleChangeValue={handleChangeValue}
        RejectOrderSequence={props.RejectOrderSequence}
        onSearch={() => {
          props.runSequence({
            sequence: "RejectOrder",
            action: "search",
            card: CARD_DRUG_ORDER_ID,
          });
        }}
      />

      <Modal
        closeIcon
        style={{ width: "95%", padding: "1rem 1rem", margin: "2rem 0 0" }}
        open={openModDrug}
        onClose={handleClose}
      >
        <>
          <div style={{ display: "flex" }}>
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                marginRight: "20px",
              }}
            >
              {selectedRow?.model_verbose_name?.replace("รายการสั่ง", "")}
            </div>

            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                marginRight: "15px",
              }}
            >
              {`[${selectedRow?.hn}]  ${selectedRow?.patient_name}`}
            </div>

            {adrIcon !== null ? (
              <div
                className="label-tag-image"
                onClick={() => {
                  if (
                    props.patientADR?.items?.length >= 1 &&
                    props.patientADR?.items?.[0].status !== "NO_REACTION"
                  ) {
                    setOpenDialog(true);
                  }
                }}
              >
                {adrIcon}
              </div>
            ) : null}

            <div style={{ flex: "1" }} />
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                marginRight: "10px",
              }}
            >
              {`Status : ${props.drugOrder?.status_label}`}
            </div>
            <div>
              <DeleteColumn
                value={true}
                icon="trash"
                size="mini"
                nameButton="Delete"
                titleName={`ต้องการยกเลิกใบสั่งยา นี้ใช่หรือไม่`}
                titleColor="orange"
                onConfirm={() => {
                  props.runSequence({
                    sequence: "RejectOrder",
                    action: "cancel",
                    item: selectedRow,
                    card: CARD_REJECT_ORDER,
                  });
                }}
              />
            </div>
          </div>

          <CardDrugOrder
            hiddenMenuOrderType={true}
            hiddenDrugList={true}
            isCardRejectOrder={true}
            orderTypeCardRejectOrder={props.RejectOrderSequence?.orderType}
            openModDrug={openModDrug}
            setOpenModDrug={setOpenModDrug}
            disabledVerbalMedOrder={props.disabledVerbalMedOrder}
            orderTypes={props.orderTypes}
            setProp={props.setProp}
            onEvent={props.onEvent}
            runSequence={props.runSequence}
            controller={props.controller}
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            selectedEncounter={props.selectedEncounter}
            selectedEmr={props.selectedEmr}
            selectedProgressCycle={props.selectedProgressCycle}
            // selectedDrugItem to edit
            selectedDrugItemDetail={props.selectedDrugItemDetail}
            selectedDrugItemDetailOptions={props.selectedDrugItemDetailOptions}
            drugSearchText={props.drugSearchText}
            drugSearchResult={props.drugSearchResult}
            DrugSelectSequence={props.DrugSelectSequence}
            selectedDrug={props.selectedDrug}
            drugOrderList={props.drugOrderList}
            drugOrder={props.drugOrder}
            renderDrugOrderItems={props.renderDrugOrderItems}
            drugDose={props.drugDose}
            drugQty={props.drugQty}
            drugDuration={props.drugDuration}
            drugDescription={props.drugDescription}
            drugPrn={props.drugPrn}
            // modal doctor certificate check
            modDoctorCertificate={props.modDoctorCertificate}
            DrugOrderTemplateSequence={props.DrugOrderTemplateSequence}
            modConfirmDeleteDrug={props.modConfirmDeleteDrug}
            modReMedication={props.modReMedication}
            modDrugLabInteraction={props.modDrugLabInteraction}
            modDrugDisease={props.modDrugDisease}
            modDrugInteraction={props.modDrugInteraction}
            currentDoctor={props.currentDoctor}
            drugOrderItemEstimate={props.drugOrderItemEstimate}
            drugPermission={props.drugPermission}
            searchedItemList={props.searchedItemList}
            drugOrderItemMasterData={{
              masterUnits: props.masterOptions.unit,
              masterRoutes: props.masterOptions.route,
              masterSites: props.masterOptions.site,
              masterFrequencies: props.masterOptions.frequency,
              masterMethods: props.masterOptions.method,
            }}
            divisionForOrderDivOptions={props.masterOptions.divisionForOrderDiv}
            duplicateReasonsOptions={props.masterOptions.duplicateReasons}
            // SolventSelectionConfig
            modSolventSelectionConfig={props.modSolventSelectionConfig}
            selectedVerbalDrugOrderWorking={
              props.selectedVerbalDrugOrderWorking
            }
            django={props.django}
          />
        </>
      </Modal>

      <Dialog
        onClose={() => setOpenDialog(false)}
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
      >
        <Table
          data={ardList}
          headers="ชื่อ,ประเภท,อาการ,ความรุนแรง,ข้อความแจ้งเตือน,สถานะ,ผู้บันทึก,วันที่-เวลา"
          keys="name,type_name,severity_name,severity_name,note,status,edit_user,edited"
          showPagination={false}
          minRows="3"
        ></Table>
      </Dialog>
    </>
  );
};

export default CardRejectOrder;
