import WasmController from "react-lib/frameworks/WasmController";

// APIS
import MedicationRecordListSummaryView from "issara-sdk/apis/MedicationRecordListSummaryView_apps_TPD";
import MedicationAdminList from "issara-sdk/apis/MedicationAdminList_apps_TPD";
import MedicationAdminCreate from "issara-sdk/apis/MedicationAdminCreate_apps_TPD";
import MedicationRecordDetail from "issara-sdk/apis/MedicationRecordDetail_apps_TPD";
import MedicationRecordList from "issara-sdk/apis/MedicationRecordList_apps_TPD";
import MedicationAdminUpdate from "issara-sdk/apis/MedicationAdminUpdate_apps_TPD";
import PatientDetailViewByHn from "issara-sdk/apis/PatientDetailViewByHn_apps_REG";
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";
import DivisionList from "issara-sdk/apis/DivisionList_core";
import ClinicalTermSetView from "issara-sdk/apis/ClinicalTermSetView_core";
import UserPermissionView from "issara-sdk/apis/UserPermissionView_users";
import UserTokenizeView from "issara-sdk/apis/UserTokenizeView_users";
import DrugOrderItemAddStdTime from "issara-sdk/apis/DrugOrderItemAddStdTime_apps_TPD";
import DrugOrderItemEditStdTime from "issara-sdk/apis/DrugOrderItemEditStdTime_apps_TPD";
import DrugOrderItemAdminDivision from "issara-sdk/apis/DrugOrderItemAdminDivision_apps_TPD";
import MedicationRecordPrintView from "issara-sdk/apis/MedicationRecordPrintView_apps_TPD";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import VitalSignValueList from "issara-sdk/apis/VitalSignValueList_apps_PTM";
import EpisodeLatestDetail from "issara-sdk/apis/EpisodeLatestDetail_core";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";

export type State = {
  medRecordSummary?: any;
  tabCurrentIndex?: any;
  medicationRecord?: any;
  patientInfo?: any;
  wardTypeList?: any;
  divisionList?: any[];
};

export const StateInitial: State = {};

export type Event =
  | { message: "MedAdminGetMedicationRecordSummary"; params: any }
  | { message: "MedAdminGetListMedAdminRecord"; params: any }
  | { message: "MedAdminGetPatientByHN"; params: any }
  | { message: "MedAdminGetClinicalTermSet"; params: any }
  | { message: "MedAdminGetUserPermission"; params: any }
  | { message: "MedAdminGetUserTokenize"; params: any }
  | { message: "MedAdminPostCreateMedAdminRecord"; params: any }
  | { message: "MedAdminPutUpdateMedAdminRecord"; params: any }
  | { message: "MedAdminPutMedAdminRecord"; params: any }
  | { message: "MedAdminPutDrugOrderItemIdAddStdTimeManual"; params: any }
  | { message: "MedAdminPutDrugOrderItemIdEditStdTimeManual"; params: any }
  | { message: "MedAdminPutDrugOrderItemIdAdminDivision"; params: any }
  | { message: "MedAdminPostMedAdminRecord"; params: any }
  | { message: "MedAdminMainComponentDidMount"; params: any }
  | { message: "MedAdminGetPrintMedRecord"; params: any }
  | { message: "MedAdminGetPatientInfo"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const MedAdminMainComponentDidMount: Handler = async (
  controller,
  { patientId }
) => {
  if (patientId) {
    MedAdminGetPatientData(controller, patientId);
  }

  // const preflight_data = await core.preflight();
  // controller.setState({ django: preflight_data });
  MedAdminGetWardType(controller);
  getDivision(controller);
};

export const MedAdminGetMedicationRecordSummary: Handler = async (
  controller,
  params
) => {
  const [res, error] = await MedicationRecordListSummaryView.get({
    apiToken: controller.apiToken,
    params,
  });

  if (res) {
    controller.setState({ medRecordSummary: res });
  }
  return [res, error];
};

export const MedAdminGetListMedAdminRecord: Handler = async (
  controller,
  { data, tabCurrentIndex }
) => {
  const [res, err] = await MedicationAdminList.get({
    apiToken: controller.apiToken,
    params: data,
  });

  if (res) {
    controller.setState({
      medicationRecord: res.items || [],
      tabCurrentIndex,
    });
  }
  return [res, err];
};

export const MedAdminPostCreateMedAdminRecord: Handler = async (
  controller,
  params
) => {
  return await MedicationAdminCreate.create({
    apiToken: controller.apiToken,
    data: params,
  });
};

export const MedAdminPutMedAdminRecord: Handler = async (
  controller,
  { medicationRecordId = null, data = {} } = {}
) => {
  return await MedicationRecordDetail.update({
    apiToken: controller.apiToken,
    data,
    pk: medicationRecordId,
  });
};

export const MedAdminPostMedAdminRecord: Handler = async (
  controller,
  params
) => {
  return await MedicationRecordList.create({
    apiToken: controller.apiToken,
    data: params,
  });
};

export const MedAdminPutUpdateMedAdminRecord: Handler = async (
  controller,
  { medicationRecordId, data }
) => {
  return await MedicationAdminUpdate.update({
    pk: medicationRecordId,
    apiToken: controller.apiToken,
    data,
  });
};

export const MedAdminGetPatientByHN: Handler = async (controller, { hn }) => {
  const [res, error] = await PatientDetailViewByHn.get({
    hn,
    apiToken: controller.apiToken,
  });

  if (error) {
    controller.setState({ patientInfo: {} });
  }
  if (res) {
    controller.setState({ patientInfo: res });
  }
  return [res, error];
};

export const MedAdminGetPatientData: Handler = async (
  controller,
  patientId
) => {
  const [res, error] = await PatientDetailView.retrieve({
    pk: patientId,
    apiToken: controller.apiToken,
  });

  if (res) {
    controller.setState({ patientInfo: res });
  }
  return [res, error];
};

export const getDivision: Handler = async (controller) => {
  const [err, res] = await DivisionList.list({
    params: { for_opd_encounter: true },
    apiToken: controller.apiToken,
  });

  if (res) {
    controller.setState({ divisionList: res.items || [] });
  }
};

export const MedAdminGetWardType: Handler = async (controller) => {
  const [res, error] = await DivisionList.list({
    apiToken: controller.apiToken,
    params: { type: "WARD" },
  });
  if (res) {
    controller.setState({ wardTypeList: res.items || [] });
  }
};

export const MedAdminGetClinicalTermSet: Handler = async (
  controller,
  params
) => {
  return await ClinicalTermSetView.post({
    apiToken: controller.apiToken,
    data: params,
  });
};

export const MedAdminGetUserPermission: Handler = async (
  controller,
  params
) => {
  return await UserPermissionView.post({
    data: params,
    apiToken: controller.apiToken,
  });
};

export const MedAdminGetUserTokenize: Handler = async (controller, params) => {
  let data: any = {};

  if (params.code) {
    data["code"] = params.code;
  }

  const [res, err] = await UserTokenizeView.post({
    apiToken: controller.apiToken,
    data,
  });

  return [res, err];
};

export const MedAdminPutDrugOrderItemIdAddStdTimeManual: Handler = async (
  controller,
  { drugOrderItemId, data }
) => {
  return await DrugOrderItemAddStdTime.update({
    pk: drugOrderItemId,
    apiToken: controller.apiToken,
    data,
  });
};

export const MedAdminPutDrugOrderItemIdEditStdTimeManual: Handler = async (
  controller,
  { drugOrderItemId, data }
) => {
  return await DrugOrderItemEditStdTime.update({
    apiToken: controller.apiToken,
    data,
    pk: drugOrderItemId,
  });
};

export const MedAdminPutDrugOrderItemIdAdminDivision: Handler = async (
  controller,
  { drugOrderItemId, data }
) => {
  return await DrugOrderItemAdminDivision.update({
    apiToken: controller.apiToken,
    data,
    pk: drugOrderItemId,
  });
};

export const MedAdminGetPrintMedRecord: Handler = async (
  controller,
  params
) => {
  return await MedicationRecordPrintView.get({
    apiToken: controller.apiToken,
    params,
  });
};

export const MedAdminGetPatientInfo: Handler = async (controller, params) => {
  const { patientId, encounterId } = params;
  const getPatientDetail = PatientDetailView.retrieve({
    pk: patientId,
    apiToken: controller.apiToken,
  });
  const getFormDataLatest = await FormDataLatest.retrieve({
    apiToken: controller.apiToken,
    params: {
      patient: patientId,
      form_version: 0.1,
      form_code: "CardPatientAssessment",
    },
  });
  var getVitalSignValueWeight = await VitalSignValueList.list({
    apiToken: controller.apiToken,
    params: {
      patient: patientId,
      type_code: "Weight",
      latest_measurement: true,
    },
  });
  var getVitalSignValueHeight = await VitalSignValueList.list({
    apiToken: controller.apiToken,
    params: {
      patient: patientId,
      type_code: "Height",
      latest_measurement: true,
    },
  });
  var getEpisodeLatest = await EpisodeLatestDetail.retrieve({
    apiToken: controller.apiToken,
    params: {
      encounter: encounterId,
    },
  });
  const getEncounter = await EncounterDetail.retrieve({
    apiToken: controller.apiToken,
    pk: encounterId,
  });

  let [
    [patientInfo],
    [drugInfo],
    [weightInfo],
    [heightInfo],
    [episodeInfo],
    [encounterInfo],
  ] = await Promise.all([
    getPatientDetail,
    getFormDataLatest,
    getVitalSignValueWeight,
    getVitalSignValueHeight,
    getEpisodeLatest,
    getEncounter,
  ]);

  episodeInfo = {
    ...episodeInfo,
    episode_id: episodeInfo?.id,
    episode_created_at: episodeInfo?.created_at,
    episode_start: episodeInfo?.start_date,
    episode_name: episodeInfo?.name,
    checkin_time: "not implement",
    checkout_time: "not implement",
  };

  weightInfo = {
    ...weightInfo,
    weight: weightInfo ? weightInfo?.formatted_string : "",
    weight_unit: weightInfo ? weightInfo?.unit : "",
  };

  heightInfo = {
    ...heightInfo,
    height: heightInfo ? heightInfo.formatted_string : "",
    height_unit: heightInfo ? heightInfo.unit : "",
  };

  return {
    patientInfo,
    drugInfo,
    weightInfo,
    heightInfo,
    episodeInfo,
    encounterInfo,
  };
};
