import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { Radio, Input, Dropdown, Button } from "semantic-ui-react";

const ModSolventSelectionUX = (props: any) => {
  return (
    <div>
      <div style={{ minHeight: "50px", height: "auto" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(4, 1fr)",
            gridTemplateColumns: "repeat(32, 1fr)",
          }}
        >
          <div
            style={{
              gridRow: "2/3",
              gridColumn: "2/32",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            กรุณาระบุชนิดของตัวทำละลายกรณีใช้สำหรับ
          </div>
          <div
            style={{
              gridRow: "4/5",
              gridColumn: "2/32",
              fontWeight: "bold",
              fontSize: "16px",
              color: "blue",
            }}
          >
            {props.DrugSelectSequence?.full_name}
          </div>
        </div>
      </div>
      <div
        style={{
          display: props.modSolventSelectionConfig?.txtNoRate ? "" : "none",
          height: props.modSolventSelectionConfig?.txtNoRate ? "65px" : "0px",
          padding: "10px 0px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(3, 1fr)",
            gridTemplateColumns: "repeat(32, 1fr)",
          }}
        >
          <div
            style={{
              gridRow: "2/3",
              gridColumn: "2/32",
              border: "solid 1px red",
              color: "red",
              padding: "10px",
            }}
          >
            {props.modSolventSelectionConfig?.txtNoRate}
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(3, 1fr)",
            gridTemplateColumns: "repeat(32, 1fr)",
          }}
        >
          <div
            style={{
              gridRow: "2/3",
              gridColumn: "2/32",
              border: `solid 1px ${props.modSolventSelectionConfig?.txtMaxConcColor}`,
              color: props.modSolventSelectionConfig?.txtMaxConcColor,
              padding: "10px",
            }}
          >
            {props.modSolventSelectionConfig?.txtMaxConc}
          </div>
        </div>
      </div>
      <div
        style={{
          display: props.modSolventSelectionConfig?.txtMaxRate ? "" : "none",
          height: props.modSolventSelectionConfig?.txtMaxRate ? "65px" : "0px",
          padding: "10px 0px",
        }}
      >
        {}
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(3, 1fr)",
            gridTemplateColumns: "repeat(32, 1fr)",
          }}
        >
          <div
            style={{
              gridRow: "2/3",
              gridColumn: "2/32",
              border: `solid 1px ${props.modSolventSelectionConfig?.txtMaxRateColor}`,
              color: props.modSolventSelectionConfig?.txtMaxRateColor,
              padding: "10px",
            }}
          >
            {props.modSolventSelectionConfig?.txtMaxRate}
          </div>
        </div>
      </div>
      <div style={{ height: "60px", padding: "10px 0px" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(2, 1fr)",
            gridTemplateColumns: "repeat(32, 1fr)",
          }}
        >
          <div style={{ gridRow: "2/3", gridColumn: "2/32" }}>
            <Dropdown
              clearable={true}
              fluid={true}
              icon="search"
              name="solvent"
              onChange={props.onSelectedDrugSolvent}
              onSearchChange={props.onSearchDrugSolvent}
              options={
                props.modSolventSelectionConfig?.drugSolventSearchOptions
              }
              search={true}
              selection={true}
              value={props.modSolventSelectionConfig?.solvent}
            ></Dropdown>
          </div>
        </div>
      </div>
      <div
        style={{
          display: props.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE
            ? "none"
            : "",
          height: "70px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(3, 1fr)",
            gridTemplateColumns: "repeat(32, 1fr)",
          }}
        >
          <div style={{ gridRow: "2/3", gridColumn: "2/3" }}>Conc.</div>
          <div style={{ gridRow: "2/3", gridColumn: "3/15" }}>
            <Input fluid={true} readOnly={true} value={props.txtConc}></Input>
          </div>
          <div
            style={{ gridRow: "2/3", gridColumn: "15/18", textAlign: "right" }}
          >
            Quantity
          </div>
          <div style={{ gridRow: "2/3", gridColumn: "18/21" }}>
            <Input
              fluid={true}
              name="old_quantity"
              onChange={props.onChangeModData}
              value={props.modSolventSelectionConfig?.old_quantity}
            ></Input>
          </div>
          <div
            style={{ gridRow: "2/3", gridColumn: "21/23", textAlign: "right" }}
          >
            Rate
          </div>
          <div style={{ gridRow: "2/3", gridColumn: "23/26" }}>
            <Input
              fluid={true}
              name="old_rate"
              onChange={props.onChangeModData}
              value={props.modSolventSelectionConfig?.old_rate}
            ></Input>
          </div>
          <div style={{ gridRow: "2/3", gridColumn: "26/32" }}>
            <Button color="green" onClick={props.onSaveOldData} size="small">
              SAVE
            </Button>
            <Button
              color="red"
              onClick={props.onCloseModSolventSelection}
              size="small"
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
      <div
        style={{
          display: props.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE
            ? ""
            : "none",
          height: "125px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(6, 1fr)",
            gridTemplateColumns: "repeat(32, 1fr)",
          }}
        >
          <div style={{ gridRow: "2/3", gridColumn: "2/5" }}>
            <Radio
              checked={
                props.modSolventSelectionConfig?.concentration === "strength"
              }
              label="Strength"
              name="concentration"
              onChange={props.onChangeModData}
              style={{ fontWeight: "bold" }}
              value="strength"
            ></Radio>
          </div>
          <div style={{ gridRow: "2/3", gridColumn: "5/12" }}>
            <Input
              disabled={
                props.modSolventSelectionConfig?.concentration === "conc"
              }
              fluid={true}
              name="drug_strength"
              onChange={props.onChangeModData}
              type="number"
              value={props.modSolventSelectionConfig?.drug_strength}
            ></Input>
          </div>
          <div style={{ gridRow: "2/3", gridColumn: "12/19" }}>
            <Dropdown
              clearable={true}
              disabled={
                props.modSolventSelectionConfig?.concentration === "conc"
              }
              fluid={true}
              name="drug_strength_choice"
              onChange={props.onChangeModData}
              options={props.modSolventSelectionConfig?.dosageUnitOptions || []}
              search={true}
              selection={true}
              value={props.modSolventSelectionConfig?.drug_strength_choice}
            ></Dropdown>
          </div>
          <div
            style={{
              gridRow: "2/3",
              gridColumn: "19/22",
              fontWeight: "bold",
              paddingLeft: "10px",
            }}
          >
            VOLUME
          </div>
          <div style={{ gridRow: "2/3", gridColumn: "22/30" }}>
            <Input
              disabled={
                props.modSolventSelectionConfig?.concentration === "conc"
              }
              fluid={true}
              name="volume"
              onChange={props.onChangeModData}
              value={props.modSolventSelectionConfig?.volume}
            ></Input>
          </div>
          <div
            style={{
              gridRow: "2/3",
              gridColumn: "30/32",
              fontWeight: "bold",
              paddingLeft: "10px",
            }}
          >
            ML
          </div>
          <div
            style={{ gridRow: "4/5", gridColumn: "2/5", fontWeight: "bold" }}
          >
            <Radio
              checked={
                props.modSolventSelectionConfig?.concentration === "conc"
              }
              label="Conc."
              name="concentration"
              onChange={props.onChangeModData}
              style={{ fontWeight: "bold" }}
              value="conc"
            ></Radio>
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "5/8" }}>
            <Input
              disabled={
                props.modSolventSelectionConfig?.concentration !== "conc"
              }
              fluid={true}
              name="solute_concentration_rate"
              onChange={props.onChangeModData}
              type="number"
              value={props.modSolventSelectionConfig?.solute_concentration_rate}
            ></Input>
          </div>
          <div
            style={{
              gridRow: "4/5",
              gridColumn: "8/9",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            :
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "9/12" }}>
            <Input
              disabled={
                props.modSolventSelectionConfig?.concentration !== "conc"
              }
              fluid={true}
              name="solvent_concentration_rate"
              onChange={props.onChangeModData}
              type="number"
              value={
                props.modSolventSelectionConfig?.solvent_concentration_rate
              }
            ></Input>
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "12/19" }}>
            <Dropdown
              clearable={true}
              disabled={
                props.modSolventSelectionConfig?.concentration !== "conc"
              }
              fluid={true}
              name="conc_choice"
              onChange={props.onChangeModData}
              options={
                props.modSolventSelectionConfig?.concentrationOptions || []
              }
              search={true}
              selection={true}
              value={props.modSolventSelectionConfig?.conc_choice}
            ></Dropdown>
          </div>
          <div
            style={{ gridRow: "6/7", gridColumn: "2/5", fontWeight: "bold" }}
          >
            <Radio
              checked={props.modSolventSelectionConfig?.dripRate === "rate"}
              label="Rate"
              name="dripRate"
              onChange={props.onChangeModData}
              value="rate"
            ></Radio>
          </div>
          <div style={{ gridRow: "6/7", gridColumn: "5/12" }}>
            <Input
              disabled={props.modSolventSelectionConfig?.dripRate !== "rate"}
              fluid={true}
              name="mixture_iv_rate"
              onChange={props.onChangeModData}
              value={props.modSolventSelectionConfig?.mixture_iv_rate}
            ></Input>
          </div>
          <div style={{ gridRow: "6/7", gridColumn: "12/19" }}>
            <Dropdown
              clearable={true}
              disabled={props.modSolventSelectionConfig?.dripRate !== "rate"}
              fluid={true}
              name="mixture_iv_rate_unit"
              onChange={props.onChangeModData}
              options={props.modSolventSelectionConfig?.ivRateOptions || []}
              search={true}
              selection={true}
              value={props.modSolventSelectionConfig?.mixture_iv_rate_unit}
            ></Dropdown>
          </div>
          <div
            style={{ gridRow: "6/7", gridColumn: "19/22", fontWeight: "bold" }}
          >
            <Radio
              checked={props.modSolventSelectionConfig?.dripRate === "drip"}
              label="Drip in"
              name="dripRate"
              onChange={props.onChangeModData}
              style={{ paddingLeft: "10px" }}
              value="drip"
            ></Radio>
          </div>
          <div style={{ gridRow: "6/7", gridColumn: "22/27" }}>
            <Input
              disabled={props.modSolventSelectionConfig?.dripRate === "rate"}
              fluid={true}
              name="drug_drip_in"
              onChange={props.onChangeModData}
              value={props.modSolventSelectionConfig?.drug_drip_in}
            ></Input>
          </div>
          <div style={{ gridRow: "6/7", gridColumn: "27/32" }}>
            <Dropdown
              clearable={true}
              disabled={props.modSolventSelectionConfig?.dripRate === "rate"}
              fluid={true}
              name="drug_drip_in_unit"
              onChange={props.onChangeModData}
              options={props.modSolventSelectionConfig?.dripInOptions || []}
              search={true}
              selection={true}
              value={props.modSolventSelectionConfig?.drug_drip_in_unit}
            ></Dropdown>
          </div>
          <div
            style={{
              gridRow: "4/5",
              gridColumn: "19/22",
              fontWeight: "bold",
              paddingLeft: "10px",
              display:
                !props.drugPermission?.role_PHARMACIST &&
                props.drugPermission?.role_DOCTOR
                  ? "none"
                  : "",
            }}
          >
            Quantity
          </div>
          <div
            style={{
              gridRow: "4/5",
              gridColumn: "22/30",
              display:
                !props.drugPermission?.role_PHARMACIST &&
                props.drugPermission?.role_DOCTOR
                  ? "none"
                  : "",
            }}
          >
            <Input
              fluid={true}
              name="quantity"
              onChange={props.onChangeModData}
              type="number"
              value={props.modSolventSelectionConfig?.quantity}
            ></Input>
          </div>
        </div>
      </div>
      <div
        style={{
          display: props.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE
            ? ""
            : "none",
          height: "70px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(3, 1fr)",
            gridTemplateColumns: "repeat(32, 1fr)",
          }}
        >
          <div
            style={{ gridRow: "2/3", gridColumn: "2/32", textAlign: "right" }}
          >
            <Button
              color="green"
              onClick={props.onSaveQuantity}
              size="small"
              style={{
                display:
                  (!props.modSolventSelectionConfig?.editable &&
                    props.drugPermission.role_PHARMACIST) ||
                  props.drugPermission.role_DOCTOR
                    ? "none"
                    : "",
              }}
            >
              SAVE QUANTITY
            </Button>
            <Button
              color="green"
              onClick={props.onSaveData}
              size="small"
              style={{
                display: props.modSolventSelectionConfig?.editable
                  ? ""
                  : "none",
              }}
            >
              SAVE
            </Button>
            <Button
              color="red"
              onClick={props.onCloseModSolventSelection}
              size="small"
              style={{
                display: props.drugOrderType === "Continue" ? "none" : "",
              }}
            >
              {(!props.modSolventSelectionConfig?.editable ||
                props.drugPermission?.role_PHARMACIST) &&
              props.modSolventSelectionConfig?.editableQuantity
                ? "CLOSE"
                : "CANCEL"}
            </Button>
            <Button
              color="yellow"
              onClick={props.onRemoveSolvent}
              size="small"
              style={{
                display:
                  props.drugOrderType === "Continue" &&
                  props.modSolventSelectionConfig?.editable
                    ? ""
                    : "none",
              }}
            >
              REMOVE SOLVENT
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModSolventSelectionUX;

export const screenPropsDefault = {};

/* Date Time : Thu Jun 23 2022 09:22:25 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ minHeight: \"50px\", height: \"auto\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(4, 1fr)\",\"gridTemplateColumns\":\"repeat(32, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "กรุณาระบุชนิดของตัวทำละลายกรณีใช้สำหรับ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"2/32\", fontWeight: \"bold\", fontSize: \"16px\" }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DrugSelectSequence?.full_name"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"2/32\", fontWeight: \"bold\", fontSize: \"16px\", color: \"blue\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.modSolventSelectionConfig?.txtNoRate ? \"\" : \"none\", height: props.modSolventSelectionConfig?.txtNoRate ? \"65px\" : \"0px\", padding: \"10px 0px\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "label": "divgrid",
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(3, 1fr)\",\"gridTemplateColumns\":\"repeat(32, 1fr)\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.txtNoRate"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"2/32\", border: \"solid 1px red\" , color: \"red\", padding: \"10px\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"60px\", padding: \"10px 0px\" }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "label": "divgrid",
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(2, 1fr)\",\"gridTemplateColumns\":\"repeat(32, 1fr)\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"2/32\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE ? \"\" : \"none\",  height: \"125px\" }"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "label": "divgrid",
      "name": "div",
      "parent": 22,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(6, 1fr)\",\"gridTemplateColumns\":\"repeat(32, 1fr)\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 23,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"2/5\" }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Radio",
      "parent": 27,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.concentration === \"strength\""
        },
        "label": {
          "type": "value",
          "value": "Strength"
        },
        "name": {
          "type": "value",
          "value": "concentration"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        },
        "value": {
          "type": "value",
          "value": "strength"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 23,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"5/12\" }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 23,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"12/19\" }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Input",
      "parent": 29,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.concentration === \"conc\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "drug_strength"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.drug_strength"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Dropdown",
      "parent": 30,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.concentration === \"conc\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "drug_strength_choice"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "options": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.dosageUnitOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.drug_strength_choice"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.txtMaxConc"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"2/32\", border: `solid 1px ${props.modSolventSelectionConfig?.txtMaxConcColor}`, color: props.modSolventSelectionConfig?.txtMaxConcColor, padding: \"10px\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.modSolventSelectionConfig?.txtMaxRate ? \"\" : \"none\", height: props.modSolventSelectionConfig?.txtMaxRate ? \"65px\" : \"0px\", padding: \"10px 0px\" }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 56,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.txtMaxRate"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"2/32\", border: `solid 1px ${props.modSolventSelectionConfig?.txtMaxRateColor}`, color: props.modSolventSelectionConfig?.txtMaxRateColor , padding: \"10px\" }"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "label": "divgrid",
      "name": "div",
      "parent": 45,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(3, 1fr)\",\"gridTemplateColumns\":\"repeat(32, 1fr)\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "label": "divgrid",
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(3, 1fr)\",\"gridTemplateColumns\":\"repeat(32, 1fr)\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "VOLUME"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"19/22\", fontWeight: \"bold\", paddingLeft: \"10px\" }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 23,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"22/30\" }"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "ML"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"30/32\", fontWeight: \"bold\", paddingLeft: \"10px\" }"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Input",
      "parent": 64,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.concentration === \"conc\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "volume"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.volume"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 23,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"2/5\", fontWeight: \"bold\" }"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 23,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"5/8\" }"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": ":"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"8/9\", textAlign: \"center\", fontWeight: \"bold\" }"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 23,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"9/12\" }"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Radio",
      "parent": 77,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.concentration === \"conc\""
        },
        "label": {
          "type": "value",
          "value": "Conc."
        },
        "name": {
          "type": "value",
          "value": "concentration"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        },
        "value": {
          "type": "value",
          "value": "conc"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Input",
      "parent": 78,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.concentration !== \"conc\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "solute_concentration_rate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.solute_concentration_rate"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Input",
      "parent": 80,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.concentration !== \"conc\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "solvent_concentration_rate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.solvent_concentration_rate"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 23,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"12/19\" }"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Dropdown",
      "parent": 87,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.concentration !== \"conc\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "conc_choice"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "options": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.concentrationOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.conc_choice"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 23,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"2/5\", fontWeight: \"bold\" }"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 23,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"5/12\" }"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 23,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"12/19\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 23,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"19/22\", fontWeight: \"bold\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 23,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"22/27\" }"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 23,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"27/32\" }"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Radio",
      "parent": 101,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.dripRate === \"rate\""
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "label": {
          "type": "value",
          "value": "Rate"
        },
        "name": {
          "type": "value",
          "value": "dripRate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "value": {
          "type": "value",
          "value": "rate"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "Input",
      "parent": 102,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.dripRate !== \"rate\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "mixture_iv_rate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.mixture_iv_rate"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "Input",
      "parent": 105,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.dripRate === \"rate\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "drug_drip_in"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.drug_drip_in"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Dropdown",
      "parent": 103,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.dripRate !== \"rate\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "mixture_iv_rate_unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "options": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.ivRateOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.mixture_iv_rate_unit"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "Dropdown",
      "parent": 106,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.dripRate === \"rate\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "drug_drip_in_unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "options": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.dripInOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.drug_drip_in_unit"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Radio",
      "parent": 104,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.dripRate === \"drip\""
        },
        "label": {
          "type": "value",
          "value": "Drip in"
        },
        "name": {
          "type": "value",
          "value": "dripRate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "style": {
          "type": "code",
          "value": "{ paddingLeft: \"10px\" }"
        },
        "value": {
          "type": "value",
          "value": "drip"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE ? \"\" : \"none\", height: \"70px\" }"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "label": "divgrid",
      "name": "div",
      "parent": 113,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(3, 1fr)\",\"gridTemplateColumns\":\"repeat(32, 1fr)\"}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 114,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"2/32\", textAlign: \"right\" }"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "Button",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": "SAVE QUANTITY"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSaveQuantity"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: (!props.modSolventSelectionConfig?.editable && props.drugPermission.role_PHARMACIST) || props.drugPermission.role_DOCTOR ? \"none\" : \"\" }"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "Button",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": "SAVE"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onSaveData"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.modSolventSelectionConfig?.editable ? \"\" : \"none\" }"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "Button",
      "parent": 117,
      "props": {
        "children": {
          "type": "code",
          "value": "(!props.modSolventSelectionConfig?.editable || props.drugPermission?.role_PHARMACIST) && props.modSolventSelectionConfig?.editableQuantity ? \"CLOSE\" : \"CANCEL\""
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCloseModSolventSelection"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.drugOrderType === \"Continue\" ? \"none\" : \"\" }"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "Button",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": "REMOVE SOLVENT"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onRemoveSolvent"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.drugOrderType === \"Continue\" && props.modSolventSelectionConfig?.editable ? \"\" : \"none\" }"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "Dropdown",
      "parent": 20,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "loading": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "solvent"
        },
        "onChange": {
          "type": "code",
          "value": "props.onSelectedDrugSolvent"
        },
        "onSearchChange": {
          "type": "code",
          "value": "props.onSearchDrugSolvent"
        },
        "options": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.drugSolventSearchOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.solvent"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "Quantity"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"19/22\", fontWeight: \"bold\", paddingLeft: \"10px\", display: !props.drugPermission?.role_PHARMACIST && props.drugPermission?.role_DOCTOR ? \"none\" : \"\" }"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 23,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"22/30\", display: !props.drugPermission?.role_PHARMACIST && props.drugPermission?.role_DOCTOR ? \"none\" : \"\" }"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "Input",
      "parent": 128,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "quantity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.quantity"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE ? \"none\" : \"\",  height: \"70px\" }"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "label": "divgrid",
      "name": "div",
      "parent": 130,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(3, 1fr)\",\"gridTemplateColumns\":\"repeat(32, 1fr)\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 131,
      "props": {
        "children": {
          "type": "value",
          "value": "Conc."
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"2/3\" }"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 131,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"26/32\" }"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "Button",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": "SAVE"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSaveOldData"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "Button",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": "CANCEL"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCloseModSolventSelection"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "div",
      "parent": 131,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"23/26\"}"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 131,
      "props": {
        "children": {
          "type": "value",
          "value": "Rate"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"21/23\", textAlign: \"right\" }"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 131,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"18/21\" }"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 131,
      "props": {
        "children": {
          "type": "value",
          "value": "Quantity"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"15/18\", textAlign: \"right\" }"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 131,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"3/15\" }"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "Input",
      "parent": 151,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "old_rate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.old_rate"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "Input",
      "parent": 153,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "old_quantity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.old_quantity"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "Input",
      "parent": 155,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.txtConc"
        }
      },
      "seq": 158,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "ModSolventSelectionUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
