import React, { useEffect, useState } from "react";
import CardPlanTransferUX from "./CardPlanTransferUX";
import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";
import { formatDate } from "react-lib/utils/dateUtils";
import moment from "moment";
import { ModConfirm } from "react-lib/apps/common";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";

const CardPlanTransfer = (props: any) => {
  const [openConfirmedDelete, setOpenConfirmedDelete] = useState(false);
  // const [openConfirmedSwitchTap, setOpenConfirmedSwitchTap] = useState(false);

  useEffect(() => {
    if (props.selectedEmr) {
      console.log("CardPlanTransfer ", props);
      props.runSequence({ sequence: "PlanTransfer", restart: true });
    }

    return () => {
      props.runSequence({ sequence: "PlanTransfer", clear: true });
    };
  }, [props.selectedEmr]);

  useEffect(() => {
    // let callFrom = "WARD";
    let roleTypes = props.django?.user?.role_types || [];
    console.log("useEffect roleTypes: ", roleTypes);
    if (
      ["หอผู้ป่วย"].includes(props.divisionType) &&
      ((roleTypes || []).includes("REGISTERED_NURSE") ||
        (roleTypes || []).includes("DOCTOR")) &&
      props.selectedEncounter?.type === "IPD"
    ) {
      let callFrom = (roleTypes || []).includes("DOCTOR") ? "DPI" : "WARD";
      if (props.selectedEncounter?.id) {
        props.onEvent({
          message: "GetMedReconcileCheck",
          params: { encounter: props.selectedEncounter, callForm: callFrom },
        });
      }
    } else {
      console.warn("ไม่ได้มาจาก หอผู้ป่วย เปิด Card PlanTransfer: ");
    }
  }, [props.selectedEncounter, props.django, props.divisionType]);

  console.log("CardPlanTransfer ", props.controller);

  // Cancel (De)
  const handleConfirmCancelPlanTransfer = () => {
    props.runSequence({
      sequence: "PlanTransfer",
      action: "delete",
    });

    setOpenConfirmedDelete(false);
  };

  const handleCloseModConfirm = () => {
    setOpenConfirmedDelete(false);
  };

  // Switch Tab
  const handleConfirmSwithTab = () => {
    props.onEvent({
      message: "OpenMedReconcileFromWarning",
      params: {
        medReconcileCheck: props.medReconcileCheck,
        selecteRecordViewIndex: props.medReconcileIndex,
      },
    });
    props.setProp("PlanTransferSequence.openRedirect", false);
  };

  const handleCloseModSwithTab = () => {
    props.setProp("PlanTransferSequence.openRedirect", false);
  };

  return (
    <div>
      <ModConfirm
        openModal={props.PlanTransferSequence?.openRedirect}
        titleName="แจ้งเตือนทบทวนการใช้ยา"
        textContent={`ต้องการบันทึกข้อมูลทบทวนการใช้ยา Medication Reconciliation IPD Transfer ใช่หรือไม่ ?`}
        size="mini"
        titleColor="blue"
        denyButtonColor="red"
        denyButtonText="ไม่"
        approveButtonColor="green"
        approveButtonText="ใช่"
        onApprove={handleConfirmSwithTab}
        onDeny={handleCloseModSwithTab}
        onCloseWithDimmerClick={handleCloseModSwithTab}
      />

      <ModConfirm
        openModal={openConfirmedDelete}
        titleName="ต้องการยกเลิกใช่หรือไม่"
        textConTent={`ต้องการยกเลิก plan transfer ${props.PlanTransferSequence?.date} ใช่หรือไม่`}
        size="mini"
        titleColor="orange"
        denyButtonColor="blue"
        denyButtonText="ไม่"
        approveButtonColor="orange"
        approveButtonText="ใช่"
        onApprove={handleConfirmCancelPlanTransfer}
        onDeny={handleCloseModConfirm}
        onCloseWithDimmerClick={handleCloseModConfirm}
      />

      <ModMedReconcileAlert
        // medReconcileCheck={props.medReconcileCheck}
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        onApprove={() => {
          props.setProp(
            "medReconcileCheck.open_card_med_reconciliation",
            false
          );

          props.onEvent({
            message: "OpenMedReconcileFromWarning",
            params: {
              medReconcileCheck: props.medReconcileCheck,
              selecteRecordViewIndex: props.medReconcileIndex,
            },
          });

          // find med reconcile Index
          // if (props.medReconcileIndex !== -1) {
          //   props.setProp("selectedRecordViewIndex", props.medReconcileIndex)
          // }
        }}
      >
        {props.medReconcileCheck?.med_reconciliation != null
          ? `Med Reconcile ${
              props.medReconcileCheck?.med_reconciliation_label
            } ยังไม่ได้ถูกรับทราบโดย${
              props?.django?.user?.role_types?.includes("DOCTOR")
                ? "แพทย์"
                : "พยาบาล"
            }`
          : `ยังไม่ได้สร้าง Medication Reconciliation สำหรับ PlanTransfer ${props.medReconcileCheck?.plan_transfer_id}`}

        {/* {props.medReconcileCheck?.med_reconciliation != null
          ? `Med Reconcile ${props.medReconcileCheck?.med_reconciliation_label} ยังไม่ได้ถูกรับทราบโดยพยาบาล`
          : `ยังไม่ได้สร้าง Medication Reconciliation สำหรับ PlanTransfer ${props.medReconcileCheck?.plan_transfer_id}`} */}
      </ModMedReconcileAlert>

      <CardPlanTransferUX
        // Date
        onTransferDateChange={(date: any) => {
          console.log("PlanTransferSequence date: ", date);
          props.setProp("PlanTransferSequence.date", date);
        }}
        minTransferDate={formatDate(moment())}
        dateTransfer={props.PlanTransferSequence?.date || null}
        onTransferTimeChange={(e: any, value: any) => {
          console.log("PlanTransferSequence value: ", value);
          props.setProp("PlanTransferSequence.time", value.toString());
        }}
        timeTransfer={props.PlanTransferSequence?.time || null}
        // Dropdown
        urgencyOptions={props.masterOptions?.transferUrgency || []}
        urgency={props.PlanTransferSequence?.urgency}
        wardTypeOptions={props.masterOptions?.wardType || []}
        wardType={props.PlanTransferSequence?.wardType}
        roomTypeOptions={props.masterOptions?.roomType || []}
        roomType={props.PlanTransferSequence?.roomType}
        divisionOptions={props.masterOptions?.division || []}
        division={props.PlanTransferSequence?.division}
        onSave={() => {
          props.runSequence({
            sequence: "PlanTransfer",
            action: "save",
          });
        }}
        onCancel={() => {
          setOpenConfirmedDelete(true);
        }}
        saveOrEditName={props.PlanTransferSequence?.id ? "แก้ไข" : "บันทึก"}
        hideCancel={!props.PlanTransferSequence?.id}
        onUrgencyChange={(e: any, { value }: any) => {
          props.setProp("PlanTransferSequence.urgency", value);
        }}
        onWardTypeChange={(e: any, { value }: any) => {
          props.setProp("PlanTransferSequence.wardType", value);
        }}
        onRoomTypeChange={(e: any, { value }: any) => {
          props.setProp("PlanTransferSequence.roomType", value);
        }}
        onDivisionChange={(e: any, { value }: any) => {
          props.setProp("PlanTransferSequence.division", value);
        }}
      />
    </div>
  );
};

export default CardPlanTransfer;
