import React, { useMemo } from "react";
import { Icon, Tab, TabProps } from "semantic-ui-react";

// UI
import { MenuItem, AssistantsElement } from "./CardSurgeryTeam";
import CardAnesthesiaTeamUX from "./CardAnesthesiaTeamUX";
import { SearchBoxWithKey } from "./CardSurgeryTeam";

import { AnesthesiaTeamInitial } from "./sequence/ORRequest";

const CardAnesthesiaTeam = (props: any) => {
	const handleChange =
		(key: string, index: number) => async (e: any, v: any) => {
			props.onChangeValue(e, { ...v, name: `${key}.${index}.${v.name}` });
		};

	const handleAddTeam = (e: any) => {
		props.runSequence({ sequence: "ORRequest", action: "anesthesia_team" });
	};

	const handleSelectTeam = (e: any, data: TabProps) => {
		if (props.teams?.length === Number(data.activeIndex)) {
			handleAddTeam(e);
			return;
		}

		props.setProp(
			`ORRequestSequence.activeAnesthesiaTeam`,
			Number(data.activeIndex)
		);
	};

	const handleDeleteTeam = async () => {
		const teams = props.teams || [];

		// if (teams.length === 1) {
		//   teams[props.activeIndex] = AnesthesiaTeamInitial;
		// } else {
		teams.splice(props.activeIndex, 1);

		const index = props.activeIndex - 2;

		await props.setProp(
			`ORRequestSequence.activeAnesthesiaTeam`,
			index < 0 ? 0 : index
		);
		// }

		props.setProp(`ORRequestSequence.anesthesia_teams`, teams);
	};

	const handleActionAnesthetistSurgeon =
		(index: number) => (cIndex: number, item: any) => {
			const items = props.teams[index].anesthetist_items || [];

			if (item === "add") {
				if (items.every((item: any) => item)) {
					items.push(null);
				} else {
					return;
				}
			} else if (item === "remove") {
				items.splice(cIndex, 1);
			} else {
				items[cIndex] = item;
			}

			props.setProp(
				`ORRequestSequence.anesthesia_teams.${index}.anesthetist_items`,
				items
			);
		};

	const handleAnesthesiologist = (index: number) => async (item: any) => {
		await props.setProp(
			`ORRequestSequence.anesthesia_teams.${index}.anesthesiologist`,
			item?.id || null
		);
		props.setProp(
			`ORRequestSequence.anesthesia_teams.${index}.anesthesiologist_item`,
			item
		);
	};

	return (
		<div style={{ padding: "1rem", marginTop: "-1rem" }}>
			<Tab
				activeIndex={props.activeIndex}
				menu={{ secondary: true, pointing: true, color: "purple" }}
				onTabChange={handleSelectTeam}
				panes={[
					...(props.teams || []).map((item: any, index: number) => ({
						menuItem: {
							key: `MenuItem-team${index}`,
							content: (
								<MenuItem
									index={index}
									activeIndex={props.activeIndex}
									title="Anesthesia Team"
									isStar={item.is_main}
									color="#a333c8"
								/>
							),
						},
						render: () => (
							<CardAnesthesiaTeamUX
								// callback
								onChangeValue={handleChange("anesthesia_teams", index)}
								onDeleteTeam={handleDeleteTeam}
								// Element
								AnesthetistElement={
									<AssistantsElement
										// callback
										onEvent={props.onEvent}
										// data
										index={index}
										items={item.anesthetist_items || []}
										type="User"
										field="Anesthetist"
										role="REGISTERED_NURSE"
										searchedItemListWithKey={props.searchedItemListWithKey}
										setSelected={handleActionAnesthetistSurgeon(index)}
										onClick={handleActionAnesthetistSurgeon(index)}
									/>
								}
								AnesthesiologistElement={
									<SearchBoxWithKey
										// callback
										onEvent={props.onEvent}
										type="Doctor"
										id={`Anesthesiologist_${index + 1}`}
										searchedItemListWithKey={props.searchedItemListWithKey}
										selectedItem={item.anesthesiologist_item || null}
										setSelected={handleAnesthesiologist(index)}
									/>
								}
							/>
						),
					})),
					{
						menuItem: {
							key: `MenuItem-teams`,
							content: (
								<div>
									<Icon
										name="add circle"
										color="purple"
										style={{ cursor: "pointer" }}
									/>
								</div>
							),
						},
					},
				]}
			/>
		</div>
	);
};

export default React.memo(CardAnesthesiaTeam);
