import React, {
  CSSProperties,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Button, Checkbox, Divider, Icon, Input } from "semantic-ui-react";
import { PackageItemDetailType } from "./sequence/PackageDateTime";

// Types
type CardAppointmentPackageProps = {
  // data
  data: DataType[];
  headerName?: string;
  // config
  selectable?: boolean;
  isEdit?: boolean;
  hideHistory?: boolean;
  // callback
  onClose?: Function;
  onSelect?: (data: Record<string, any>[]) => any;
  onHistory?: (data: Partial<HistoryDataType>) => any;
};

type HistoryDataType = {
  product_id: number;
  product_name: string;
  product_code: string;
};

type DataType = {} & Pick<
  PackageItemDetailType,
  | "product"
  | "balance"
  | "p_type_code"
  | "product_name"
  | "product_code"
  | "active"
  | "quantity_appointment"
>;

type ItemType = {
  table?: boolean;
  title: string;
  balance?: string | number;
  header?: boolean;
  qty?: string | number;
  type?: string;
  disabled?: boolean;
  original?: DataType;
};

const GridCenter = { display: "grid", placeContent: "center" } as CSSProperties;

const CardAppointmentPackage = (props: CardAppointmentPackageProps) => {
  // Checked
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [items, setItems] = useState<ItemType[]>([]);
  const [checkableByType, setCheckableByType] = useState<
    Record<string, { header: number; items: number[] }>
  >({});

  useEffect(() => {
    // group by type {[type]: any[]}
    const group = props.data.reduce((result, item) => {
      if (result[item.p_type_code]) {
        result[item.p_type_code].push(item);
      } else {
        result[item.p_type_code] = [item];
      }
      return result;
    }, {} as Record<string, DataType[]>);

    // flatMap any[] ออกมา โดย add header ไว้บนสุด {title, header}
    const flatList: ItemType[] = Object.entries(group).flatMap(
      ([key, value]) => [
        {
          title: key,
          header: true,
          type: key,
          disabled: value.every((item) => !item.balance),
        },
        ...value.map((item) => ({
          title: item.product_name,
          balance: item.balance,
          qty: item.quantity_appointment ?? (!!item.balance ? 1 : ""),
          type: key,
          original: item,
        })),
      ]
    );

    const list = [{ title: "รายการ", balance: "จำนวนคงเหลือ" }, ...flatList];

    setItems(list);

    handleGetCheckableByType(list);
  }, [props.data]);

  useEffect(() => {
    // default checkedids เมื่อเป็นการ edit เท่านั้น
    if (props.isEdit) {
      const activeIndex = items.flatMap((item, index) =>
        item.original?.active ? [index] : []
      );
      // check header เมื่อเลือก children ทั้งหมด
      const headerIndex = Object.values(checkableByType).flatMap((item) =>
        item.items.every((acc) => activeIndex.includes(acc))
          ? [item.header]
          : []
      );

      setCheckedIds([...activeIndex, ...headerIndex]);
    }
  }, [checkableByType, props.isEdit]);

  const handleGetCheckableByType = useCallback((list: ItemType[]) => {
    // group index ที่สามารถ check ได้
    const checkableByType = list.reduce((result, item, index) => {
      const checkable =
        (!!item.balance || item.header) && !item?.disabled && index !== 0;
      const type = item.type || "";

      if (checkable) {
        if (!result[type]) {
          result[type] = { header: index, items: [] };
        } else {
          result[type].items.push(index);
        }
      }

      return result;
    }, {} as Record<string, { header: number; items: number[] }>);

    setCheckableByType(checkableByType);
  }, []);

  const handleChange = useCallback(
    (index: number, item: ItemType) => (e: any, data: any) => {
      let value = Number(data.value);
      const balance = Number(item.balance);

      // type number value ไม่น้อยกว่า 0 และ น้อยกว่าเท่ากับ balance
      value = value < 0 ? 0 : value > balance ? balance : value;

      (items as any)[index][data.name] = value;

      setItems([...items]);
    },
    [items]
  );

  const handleInputRef = useCallback(
    (isActive: boolean) => (ref: any) =>
      ref &&
      ((ref.inputRef.current.style.border = isActive ? "none" : ""),
      (ref.inputRef.current.style.opacity = isActive ? "0.75" : ""),
      (ref.inputRef.current.style.background = isActive ? "#EAEAEA" : ""),
      (ref.inputRef.current.style.textAlign = "right")),
    []
  );

  const checkableAll = useMemo(() => {
    return Object.values(checkableByType).flatMap((item) => [
      ...(item.header ? [item.header] : []),
      ...item.items,
    ]);
  }, [checkableByType]);

  const isCheckedAll = useMemo(() => {
    return !!checkedIds.length && checkedIds.length === checkableAll.length;
  }, [checkedIds, checkableAll]);

  const handleCheckedAll = (e: any, data: any) => {
    setCheckedIds(data.checked ? checkableAll : []);
  };

  const handleChecked = (index: number) => (e: any, v: any) => {
    let checked = [...checkedIds];
    const data = items[index];
    const childrenIndex = items.flatMap((item, index) =>
      item.type === data.type && !item.header && !!item.balance ? [index] : []
    );
    const headerIndex = items.findIndex(
      (item, index) => item.type === data.type && item.header
    );
    const indexList = [headerIndex, ...childrenIndex];

    if (data.header) {
      // check, uncheck children ทั้งหมด
      if (v.checked) {
        checked.push(...indexList);
      } else {
        checked = checked.filter((index) => !indexList.includes(index));
      }
    } else {
      // check, uncheck เฉพาะที่เลือก
      if (v.checked) {
        checked.push(index);
      } else {
        checked = checked.filter((idx) => index !== idx);
      }
    }

    const childrenLength = checked.filter((index) =>
      childrenIndex.includes(index)
    ).length;

    checked =
      // check children ทั้งหมดให้ check header auto
      childrenLength === childrenIndex.length && !checked.includes(headerIndex)
        ? [...checked, headerIndex]
        : // uncheck children ทั้งหมดให้ uncheck header auto
        !childrenLength && checked.includes(headerIndex)
        ? checked.filter((index) => headerIndex !== index)
        : checked;

    setCheckedIds(checked);
  };

  const handleAppointment = () => {
    const activeItems = props.data.map((item) => {
      const index = items.findIndex(
        (acc) => acc.original?.product_code === item.product_code
      );

      return {
        ...item,
        active: !!checkedIds.includes(index) && !!Number(items[index].qty),
        quantity_appointment: items[index].qty,
      };
    });

    props.onSelect?.(activeItems);
  };

  console.log("CardAppointmentPackage", items);

  return (
    <>
      {!!props.headerName && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "18px 10px 3px 25px",
            }}
          >
            <div
              style={{
                color: "#0072BC",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              {props.headerName}
            </div>
            <Icon
              name="close"
              color="red"
              size="large"
              link={true}
              onClick={props.onClose}
            />
          </div>
          <Divider style={{ margin: "1rem 1rem 0" }} />
        </>
      )}
      <div style={{ padding: "1.5rem 2rem" }}>
        <div
          style={{
            fontWeight: "bold",
            marginBottom: "0.75rem",
          }}
        >
          รายการที่สามารถนัดหมายได้
        </div>
        {items.map((item, index) => (
          <div
            style={{
              display: "grid",
              gap: "20px",
              fontWeight: "normal",
              borderTop: item.header ? "1px dashed #CCCCCC" : "",
              borderBottom:
                items.length - 1 === index ? "1px dashed #CCCCCC" : "",
              ...(index === 0
                ? {
                    backgroundColor: "#D0EEF2",
                    border: "1px solid #979797",
                  }
                : {}),
              ...(props.selectable
                ? {
                    padding: item.header
                      ? "7px 25px 7px 5px"
                      : "5px 25px 5px 5px",
                    gridTemplateColumns: "1fr 15% 15% 15%",
                  }
                : {
                    padding: "5px 25px",
                    gridTemplateColumns: "1fr 15% 15%",
                  }),
              ...(props.hideHistory
                ? { gridTemplateColumns: "1fr 15% 15% 2.5%" }
                : {}),
            }}
          >
            <div
              style={{
                display: "flex",
                ...(item.header
                  ? { fontWeight: "bold", color: "#2185D0", fontSize: "1.1rem" }
                  : {}),
              }}
            >
              {props.selectable && (
                <Checkbox
                  checked={
                    index === 0 ? isCheckedAll : checkedIds.includes(index)
                  }
                  disabled={(!item.balance && !item.header) || item.disabled}
                  style={{ marginRight: "1rem" }}
                  onChange={
                    index === 0 ? handleCheckedAll : handleChecked(index)
                  }
                />
              )}
              {item.title}
            </div>
            <div style={GridCenter}> {item.balance}</div>
            {props.selectable && (
              <div style={GridCenter}>
                {index === 0 ? (
                  "จำนวนนัดหมาย"
                ) : !item.header ? (
                  <Input
                    ref={handleInputRef(!item.balance)}
                    value={item.qty}
                    type="number"
                    fluid={true}
                    disabled={!item.balance}
                    name="qty"
                    style={{ width: "100px", opacity: 1 }}
                    onChange={handleChange(index, item)}
                  />
                ) : null}
              </div>
            )}
            <div style={GridCenter}>
              {!!props.hideHistory ? (
                <></>
              ) : index === 0 ? (
                "ประวัติ"
              ) : !item.header ? (
                <Button
                  icon="history"
                  color="grey"
                  size="mini"
                  // style
                  style={{ fontSize: "0.75rem" }}
                  // callback
                  onClick={(e) => {
                    e.stopPropagation();

                    props.onHistory?.({
                      product_id: item.original?.product,
                      product_name: item.original?.product_name,
                      product_code: item.original?.product_code,
                    });
                  }}
                />
              ) : null}
            </div>
          </div>
        ))}

        {props.selectable && (
          <Button
            color={props.isEdit ? "yellow" : "green"}
            disabled={!checkedIds.length}
            style={{ float: "right", margin: "1rem -0.5rem" }}
            onClick={handleAppointment}
          >
            {props.isEdit ? "แก้ไขรายการนัดหมาย" : "เพิ่มรายการรอลงนัดหมาย"}
          </Button>
        )}
      </div>
    </>
  );
};

export default React.memo(CardAppointmentPackage);
