import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Form } from "semantic-ui-react";
import { CardLayout, DateTextBox, ErrorMessage } from "../common";
import { toast } from "react-toastify";
import moment from "moment";
import { formatDateToStringBe } from "react-lib/utils/dateUtils";

const MultiDisciplinaryEditor = forwardRef((props, ref) => {
  const defaultValue = {
    encounter: "",
    date: formatDateToStringBe(moment()),
    end_date: "",
    problem: "",
    plan: "",
    goal: "",
    username: "",
    password: "",
  };

  const [saveLoading, setSaveLoading] = useState(false);
  const [multiErrorsMessage, setMultiErrorsMessage] = useState(null);
  const [state, setState] = useState(defaultValue);

  useImperativeHandle(ref, () => ({
    getValue: () => {
      const data = { ...state };
      return data;
    },
    setValue: (data) => {
      setState(data);
    },
    clearValue: () => {
      setState(defaultValue);
    },
  }));

  const onTextChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setState({ ...state, [name]: value });
  };

  const saveMulti = async () => {
    setSaveLoading(true);
    var params = {
      id: state.id,
      encounter: props.encounterId,
      date: state.date,
      end_date: state.end_date,
      problem: state.problem,
      plan: state.plan,
      goal: state.goal,
      username: state.username,
      password: state.password,
    };

    const errors = await props.onSaveMultidisciplinary(params); //เรียกจริง
    setMultiErrorsMessage(errors);

    if (errors) {
      // toast.error("บันทึกข้อมูลไม่สำเร็จ");
    } else {
      // toast.success("บันทึกช้อมูลสำเร็จ");
    }

    setSaveLoading(false);
  };

  return (
    <CardLayout closeable={true} onClose={props.onClose}>
      <ErrorMessage error={multiErrorsMessage} />
      <h3>เพิ่มข้อมูล Multidisciplinary</h3>
      <Form>
        <Form.Group inline style={{ marginTop: "10px" }}>
          <Form.Field className="two wide" />
          <Form.Field required label="Start Date" className="one wide" />
          <Form.Field className="five wide">
            <DateTextBox
              value={state.date}
              onChange={(date) => {
                setState({ ...state, ["date"]: date });
              }}
            />
          </Form.Field>
          <Form.Field className="one wide" />
          <Form.Field label="End Date" className="one wide" />
          <Form.Field className="five wide">
            <DateTextBox
              value={state.end_date}
              onChange={(date) => {
                setState({ ...state, ["end_date"]: date });
              }}
            />
          </Form.Field>
          <Form.Field className="one wide" />
        </Form.Group>

        <Form.Group inline>
          <Form.Field className="two wide" />
          <Form.Field required label="Problem" className="one wide" />
          <Form.TextArea
            className="twelve wide"
            name="problem"
            value={state.problem}
            onChange={onTextChange.bind(this)}
          />
          <Form.Field className="one wide" />
        </Form.Group>

        <Form.Group inline>
          <Form.Field className="two wide" />
          <Form.Field label="Plan" className="one wide" />
          <Form.TextArea
            className="twelve wide"
            name="plan"
            value={state.plan}
            onChange={onTextChange.bind(this)}
          />
          <Form.Field className="one wide" />
        </Form.Group>

        <Form.Group inline>
          <Form.Field className="two wide" />
          <Form.Field label="Goal" className="one wide" />
          <Form.TextArea
            className="twelve wide"
            name="goal"
            value={state.goal}
            onChange={onTextChange.bind(this)}
          />
          <Form.Field className="one wide" />
        </Form.Group>

        <Form.Group inline>
          <Form.Field className="two wide" />
          <Form.Field required label="Username" className="one wide" />
          <Form.Input
            className="five wide"
            name="username"
            value={state.username}
            onChange={onTextChange.bind(this)}
          />
          <Form.Field required label="Password" className="one wide" />
          <Form.Input
            type="password"
            className="five wide"
            name="password"
            value={state.password}
            onChange={onTextChange.bind(this)}
          />
          <Form.Field className="one wide">
            <Button
              color="green"
              onClick={saveMulti}
              loading={saveLoading}
              disabled={saveLoading}
            >
              บันทึก
            </Button>
          </Form.Field>
          <Form.Field className="one wide" />
        </Form.Group>
      </Form>
    </CardLayout>
  );
});

MultiDisciplinaryEditor.defaultProps = {
  onSaveMultidisciplinary: () => {},
  encounterId: null,
  id: "",
  onClose: () => {},
};

MultiDisciplinaryEditor.propTypes = {
  onSaveMultidisciplinary: PropTypes.func,
  onClose: PropTypes.func,
  encounterId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.string,
};

export default React.memo(MultiDisciplinaryEditor);
