import React, {
  ReactElement,
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  MutableRefObject,
  KeyboardEvent,
  SyntheticEvent,
  CSSProperties,
  useMemo,
} from "react";
import {
  Icon,
  Popup,
  SemanticCOLORS,
  Input,
  Button,
  InputProps,
  Label,
  Form,
} from "semantic-ui-react";

// Common
import { DateTextBox, ErrorMessage, ModInfo } from "react-lib/apps/common";

import CardDrugClaimQA from "./CardDrugClaimQA";
import DrugIconPopup from "./DrugIconPopup";
import { adToBe, beToAd, formatDate } from "react-lib/utils/dateUtils";

const ICON_MARGIN = "0 0.275rem";

const ICONS = {
  duplicate: "images/duplicate.png",
  document_regular: "images/document-regular.png",
  group_message: "images/group-message.png",
  pregnant: "images/pregnant.png",
  alert_shield: "images/alert_shield.png",
  home_di: "images/home_di_icon.png",
};

const LabelStyle = {
  margin: ICON_MARGIN,
  fontWeight: "bold",
  fontSize: ".75285714rem",
  cursor: "pointer",
} as CSSProperties;

const ICONStyle = {
  height: "1.35rem",
  margin: ICON_MARGIN,
  cursor: "pointer",
} as CSSProperties;

const PREGCAT_ALERT = {
  99: "NA",
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "X",
  6: "P1D",
  7: "P3D",
};

const SubDrugOrderStatusAlert: React.FunctionComponent<any> = (props) => {
  const [openNote, setOpenNote] = useState<boolean>(false);
  const [openDI, setOpenDI] = useState<boolean>(false);
  const [openClaimQA, setOpenClaimQA] = useState<boolean>(false);
  const [openModInfo, setOpenModInfo] = useState<{
    content: string;
    color: SemanticCOLORS;
    align?: CSSProperties["textAlign"];
    size?: "mini" | "tiny" | "small" | "large" | "fullscreen";
  } | null>(null);

  const pregcat = useMemo(() => {
    let pregcat: string = props.item.pregcat;
    if (props.item?.pregcat_alert) {
      pregcat = props.item.pregcat_alert.match(/:(.*)]/)?.[1];
    }

    if (typeof pregcat === "number") {
      pregcat = PREGCAT_ALERT[pregcat];
    } else if (pregcat === undefined) {
      pregcat = props.item.pregcat_alert;
    }

    return pregcat;
  }, [props.item.pregcat_alert, props.item.pregcat]);

  const alerts = useMemo(() => {
    const alerts = props.item.alerts;
    return {
      ...alerts,
      I: alerts?.I || alerts?.interaction,
      H: alerts?.H || alerts?.highalert,
      L: alerts?.L || alerts?.lasa,
      D: alerts?.D || alerts?.duplication,
      O: alerts?.O || alerts?.overdoses,
    };
  }, [props.item.alerts]);

  const handleOpenNote = (e: SyntheticEvent) => {
    stopPropagation(e);
    if (props.onSaveNote) {
      setOpenNote(true);
    }
  };

  const handleOpenDI = (e: SyntheticEvent) => {
    stopPropagation(e);
    if (props.onSaveNote) {
      setOpenDI(true);
    }
  };

  const handleOpenDuplicate = (e: SyntheticEvent) => {
    stopPropagation(e);
    setOpenModInfo({
      content: alerts["D"]?.details || "",
      color: "orange",
      size: "small",
    });
  };

  const handleOpenPregnant = (e: SyntheticEvent) => {
    stopPropagation(e);
    setOpenModInfo({
      content: `Pregnancy Category: ${props.item.pregcat}`,
      color: "pink",
      align: "center",
    });
  };

  const handleOpenOrderNotes = (e: SyntheticEvent) => {
    stopPropagation(e);
    setOpenModInfo({ content: alerts["N"], color: "blue" });
  };

  const handleOpenRejectNotes = (e: SyntheticEvent) => {
    stopPropagation(e);
    setOpenModInfo({ content: alerts["X"], color: "black" });
  };

  const handleCloseDuplicate = (e: SyntheticEvent) => {
    stopPropagation(e);
    setOpenModInfo(null);
  };

  const handleOpenDrugClaim = (e: SyntheticEvent) => {
    stopPropagation(e);
    setOpenClaimQA(true);
    props.onDrugClaim?.({
      item: props.item,
      questionIndex: 0,
      haveQuestion: true,
      itemIndex: props.index,
    });
  };

  const stopPropagation = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSave = (data: any) => {
    props.onSaveNote?.(data, props.index);
    handlePopupOpen();
  };

  const handlePopupOpen = () => {
    setOpenNote(false);
    setOpenDI(false);
    setOpenClaimQA(false);
  };

  const handleChangeDate = (name: string, date: string) => {
    const parseDate = beToAd(date);

    if (name === "TAPER") {
      props.onEvent({
        message: "AddToOrder",
        params: {
          editItem: {
            start_date: parseDate?.format("YYYY-MM-DD"),
            end_date: parseDate
              ?.add(props.item.duration ? props.item.duration - 1 : 0)
              .format("YYYY-MM-DD"),
          },
          drugIndex: props.index,
          orderType: props.orderType,
          // card: CARD_DRUG_ORDER_ID,
        },
      });
    } else {
      props.onEvent({
        message: "AddToOrder",
        params: {
          editItem: { start_date: parseDate },
          drugIndex: props.index,
          orderType: props.orderType,
          // card: CARD_DRUG_ORDER_ID,
        },
      });
    }
  };

  // console.log("SubDrugOrderStatusAlert props: ", props);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          rowGap: "5px",
        }}
      >
        {/*  */}
        {props.item.adr_info && (
          <DrugIconPopup
            open={!openNote ? undefined : openNote}
            textContent={
              openNote ? (
                <NotePopup
                  value={props.item.note_adverse_reaction}
                  onSave={(note: string) => {
                    handleSave({
                      ...props.item,
                      note_adverse_reaction: note,
                      key: "note_adverse_reaction",
                    });
                  }}
                  onClose={() => setOpenNote(false)}
                />
              ) : (
                <div>
                  {props.item.adr_info?.split("\n")?.map((note: any) => (
                    <div>{note}</div>
                  ))}
                  <div> Doctor note: {props.item.note_adverse_reaction}</div>
                </div>
              )
            }
            config={{ offset: -10 }}
            onOpen={handlePopupOpen}
          >
            <Label
              color="red"
              size="mini"
              style={{ margin: ICON_MARGIN, cursor: "pointer" }}
              onClick={handleOpenNote}
            >
              A
            </Label>
          </DrugIconPopup>
        )}
        {alerts["I"] && (
          <DrugIconPopup
            open={!openDI ? undefined : openDI}
            textContent={
              // openDI ? (
              //   <NotePopup
              //     value={props.item.note_drug_interaction}
              //     onSave={(note: string) => {
              //       handleSave({
              //         ...props.item,
              //         note_drug_interaction: note,
              //         key: "note_drug_interaction",
              //       });
              //     }}
              //     onClose={() => setOpenDI(false)}
              //   />
              // ) : (
              <div>
                <div>Drug Interaction</div>
                {alerts["I"]?.info
                  .split("\n")
                  .map((text: string, index: number) =>
                    index !== 0 ? <div>{text}</div> : <div />
                  )}
                <div>
                  Doctor note: {props.item.note_drug_interaction || "-"}
                </div>
              </div>
              // )
            }
            config={{ offset: -5 }}
            // onOpen={handlePopupOpen}
          >
            {props.item.drug_interaction_in === 2 ||
            props.item.drug_interaction_in === "TRIAGE" ? (
              <img
                src={ICONS.home_di}
                style={ICONStyle}
                onClick={() => {
                  props.onEvent({
                    message: "HandleModDrugInteraction",
                    params: {
                      action: "open",
                      targetStateOrder: props.targetStateOrder,
                      data: { ...props.item, drugIndex: props.index },
                    },
                  });
                }}
                alt="home_di.icon"
              />
            ) : (
              <Label
                color="yellow"
                size="mini"
                style={{ margin: ICON_MARGIN, cursor: "pointer" }}
                // onClick={handleOpenDI}
                onClick={() => {
                  props.onEvent({
                    message: "HandleModDrugInteraction",
                    params: {
                      action: "open",
                      targetStateOrder: props.targetStateOrder,
                      data: { ...props.item, drugIndex: props.index },
                    },
                  });
                }}
              >
                DI
              </Label>
            )}
          </DrugIconPopup>
        )}

        {alerts["H"] && (
          <DrugIconPopup
            textContent={"High Alert Drug"}
            config={{ offset: -10 }}
            onOpen={handlePopupOpen}
          >
            <label
              style={{
                ...LabelStyle,
                color: "#db2828",
              }}
            >
              HAD
            </label>
          </DrugIconPopup>
        )}

        {alerts["L"] && (
          <DrugIconPopup
            textContent={"Look-Alike, Sound-Alike Drug"}
            config={{ offset: -10 }}
            onOpen={handlePopupOpen}
          >
            <label
              style={{
                ...LabelStyle,
                color: "#000000",
              }}
            >
              LASA
            </label>
          </DrugIconPopup>
        )}

        {alerts["D"] && (
          <DrugIconPopup
            textContent={"Duplicate"}
            config={{ offset: -10 }}
            onOpen={handlePopupOpen}
          >
            <img
              src={ICONS.duplicate}
              style={ICONStyle}
              onClick={handleOpenDuplicate}
              alt="duplicate.icon"
            />
          </DrugIconPopup>
        )}

        {!!props.item.claim_payload?.payloads?.length && (
          <DrugIconPopup
            textContent={"ข้อมูลการตอบคำถาม"}
            config={{ offset: -10 }}
            onOpen={handlePopupOpen}
          >
            <>
              <QAStatus item={props.item} onClick={handleOpenDrugClaim} />
            </>
          </DrugIconPopup>
        )}

        {pregcat && pregcat !== "NA" && props.isFemale && (
          <DrugIconPopup
            textContent={`Pregnancy Category: ${pregcat}`}
            config={{ offset: -10 }}
            onOpen={handlePopupOpen}
          >
            <img
              src={ICONS.pregnant}
              style={ICONStyle}
              onClick={handleOpenPregnant}
              alt="pregnant.icon"
            />
          </DrugIconPopup>
        )}

        {alerts["N"] && (
          <DrugIconPopup
            textContent={"ข้อมูลบันทึกข้อความ"}
            config={{ offset: -10 }}
            onOpen={handlePopupOpen}
          >
            <img
              src={ICONS.group_message}
              style={ICONStyle}
              onClick={handleOpenOrderNotes}
              alt="group_message.icon"
            />
          </DrugIconPopup>
        )}

        {alerts["X"] && (
          <DrugIconPopup
            textContent={"ข้อมูลการปฏิเสธการสั่งยา"}
            config={{ offset: -10 }}
            onOpen={handlePopupOpen}
          >
            <img
              src={ICONS.group_message}
              style={ICONStyle}
              onClick={handleOpenRejectNotes}
              alt="group_message.icon"
            />
          </DrugIconPopup>
        )}

        {alerts["O"] && (
          <DrugIconPopup
            textContent={"Overdose"}
            config={{ offset: -10 }}
            onOpen={handlePopupOpen}
          >
            <Label color="orange" size="mini" style={{ margin: ICON_MARGIN }}>
              <Icon
                name="angle double up"
                style={{ margin: "0px 0.142857em" }}
              />
            </Label>
          </DrugIconPopup>
        )}

        {openClaimQA && (
          <CardDrugClaimQA
            // data
            estimate={props.estimate}
            stateKey={props.stateKey}
            orderType={props.orderType}
            selectedEncounter={props.selectedEncounter}
            selectedEmr={props.selectedEmr}
            selectedProgressCycle={props.selectedProgressCycle}
            statusRequest={props.statusRequest}
            // callback
            onCloseQA={
              props.stateKey === "drugResuscitation"
                ? () => props.onCloseQA()
                : ""
            }
            onSaveQA={
              props.stateKey === "drugResuscitation"
                ? (e: any) => props.onSaveQA(e)
                : ""
            }
            onEvent={props.onEvent}
            onSave={
              props.saveClaimByEvent
                ? null
                : (claim_payload: any, index: number) =>
                    props.onDrugClaim?.({
                      item: { ...props.item, claim_payload },
                      questionIndex: index,
                      itemIndex: props.index,
                      isEdit: true,
                    })
            }
          />
        )}
        {/* Drug Lab Interaction */}
        {props.item.note_drug_lab_interaction && (
          <DrugIconPopup
            open={!openNote ? undefined : openNote}
            textContent={
              <>
                <div>Drug-Lab interaction</div>
                <div>Doctor note: {props.item.note_drug_lab_interaction}</div>
              </>
            }
            config={{ offset: -10 }}
            // onOpen={handlePopupOpen}
          >
            <div>
              <Icon
                color="green"
                name="flask"
                style={{ ...ICONStyle, height: "1.35rem", fontSize: "1.15rem" }}
                onClick={() => {
                  props.onEvent({
                    message: "HandleModDrugLabInteraction",
                    params: {
                      action: "open",
                      data: { ...props.item, drugIndex: props.index },
                    },
                  });
                }}
              />
            </div>
          </DrugIconPopup>
        )}

        {/* Drug Disease */}
        {props.item.note_drug_disease && (
          <DrugIconPopup
            open={!openNote ? undefined : openNote}
            textContent={
              <>
                <div>Drug-Disease interaction</div>
                <div>Doctor note: {props.item.note_drug_disease}</div>
              </>
            }
            config={{ offset: -10 }}
            // onOpen={handlePopupOpen}
          >
            <img
              src={ICONS.alert_shield}
              style={ICONStyle}
              onClick={() => {
                props.onEvent({
                  message: "HandleModDrugDisease",
                  params: {
                    action: "open",
                    data: { ...props.item, drugIndex: props.index },
                  },
                });
              }}
              alt="alert_shield.icon"
            />
          </DrugIconPopup>
        )}

        {/* Syringe need */}
        {props.item.is_syringe_need && (
          <Icon
            color="green"
            inverted
            circular
            name="syringe"
            style={{ margin: "0px 0.142857em", fontSize: "0.75rem" }}
          />
        )}

        {/* Taper drug */}
        {props.item.start_date && (
          <Popup
            content={
              <TaperPopup
                startDate={adToBe(props.item.start_date, "YYYY-MM-DD")}
                duration={props.item.duration}
                readOnly={props.stateKey !== "drugOrder"}
                // callback
                onChangeDate={(date: string) => {
                  handleChangeDate("TAPER", date);
                }}
              />
            }
            on="click"
            position="bottom left"
            pinned
            trigger={
              <img
                src={"/static/images/taper-schedule.png"}
                style={{
                  width: "1.5rem",
                  cursor: "pointer",
                  margin: "0px 0.142857em",
                }}
                alt="taper-schedule.icon"
              />
            }
          />
        )}

        <ModInfo
          open={!!openModInfo}
          titleName={"แจ้งเตือน!!!"}
          size={openModInfo?.size ?? "mini"}
          color={openModInfo?.color}
          // @ts-ignore
          onApprove={handleCloseDuplicate}
        >
          <div
            className="ui"
            style={{
              whiteSpace: "pre-wrap",
              lineHeight: 1.75,
              textAlign: openModInfo?.align ?? "left",
            }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: openModInfo?.content || "" }}
            />
          </div>
        </ModInfo>
      </div>
    </>
  );
};

const NotePopup: React.FunctionComponent<any> = (props) => {
  const [note, setNote] = useState<any>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const inputRef = useRef() as MutableRefObject<any>;

  useEffect(() => {
    setNote(props.value);

    setTimeout(() => {
      inputRef.current.inputRef.current.focus();
    }, 100);
  }, []);

  const handleChangeValue = (e: any, v: InputProps) => {
    setNote(v.value);
  };

  const handleSave = () => {
    if (note) {
      props.onSave?.(note);
    } else {
      setErrorMessage("กรุณาระบุข้อมูลใน หมายเหตุการใช้ยา");
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Icon
          name="close"
          color="red"
          style={{ margin: "-8px -8px 8px", cursor: "pointer" }}
          onClick={props.onClose}
        />
      </div>
      <ErrorMessage error={errorMessage} style={{ marginTop: "5px" }} />
      <div style={{ display: "flex", alignItems: "center" }}>
        <label>หมายเหตุ</label>
        <Input
          ref={inputRef}
          style={{ margin: "0 1rem" }}
          value={note}
          onChange={handleChangeValue}
          error={!!errorMessage}
          onKeyDown={handleKeyDown}
        />
        <Button color="green" onClick={handleSave}>
          บันทึก
        </Button>
      </div>
    </>
  );
};

const CLAIM_RESULT_ICON: Record<number, string> = {
  1: "check green", // เบิกได้'
  2: "warning sign yellow", // เบิกไม่ได้ เนื่องจากตอบคำถามไม่ครบ'
  3: "remove red", // เบิกไม่ได้ เนื่องจากตอบคำถามไม่ตรงตามเงื่อนไข'
  4: "minus gray", // เบิกไม่ได้ โดยตัวผลิตภัณฑ์'
};

const QAStatus: React.FunctionComponent<any> = (props) => {
  const warningIcon = useMemo(() => {
    let warningIcon = "";
    // if (props.item["drug_list"]) {
    if (CLAIM_RESULT_ICON[props.item["claim_result"]]) {
      const claimIcon = CLAIM_RESULT_ICON[props.item["claim_result"]];
      warningIcon = `corner ${claimIcon} icon`;
    } else {
      warningIcon = "corner minus gray icon";
    }
    // }
    // console.log("props.item", props.item, warningIcon);

    return warningIcon;
  }, [props.item]);

  const handleClick = (e: SyntheticEvent) => {
    props.onClick?.(e);
  };

  return (
    <div
      style={{ position: "relative", width: "fit-content", cursor: "pointer" }}
      onClick={handleClick}
    >
      <Icon name="file outline" color="blue" style={{ fontSize: "1.175rem" }} />
      <div style={{ position: "absolute", top: "0", right: "0" }}>
        <Icon className={warningIcon} style={{ fontSize: "0.575rem" }} />
      </div>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                       TaperPopup;                      */

/* ------------------------------------------------------ */
// Types
type TaperPopupProps = {
  // data
  startDate?: string;
  duration?: string | number;
  // config
  readOnly?: boolean;
  // callback
  onChangeDate?: (date: string) => any;
};
export const TaperPopup = (props: TaperPopupProps) => {
  return (
    <div
      style={{
        transform: "scale(0.95)",
        margin: "-0.15rem -1.5rem -0.15rem -0.5rem",
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {/* @ts-ignore */}
      <Form>
        <Form.Group className="noMargin" inline>
          <Form.Field inline>From</Form.Field>
          <Form.Field inline>
            <DateTextBox
              inputRef={(instance: any) =>
                instance &&
                props.readOnly &&
                ((instance.ref.inputRef.current.style.backgroundColor =
                  "rgba(131,129,129,0.45)"),
                (instance.ref.inputRef.current.style.opacity = "1"),
                (instance.ref.inputRef.current.style.fontWeight = "bold"))
              }
              value={props.startDate}
              disabled={props.readOnly}
              style={{ width: "135px" }}
              inputStyle={{ width: "135px" }}
              onChange={props.onChangeDate}
            />
          </Form.Field>
          <Form.Field inline>To</Form.Field>
          <Form.Field inline>
            <DateTextBox
              inputRef={(instance: any) =>
                instance &&
                ((instance.ref.inputRef.current.style.backgroundColor =
                  "rgba(131,129,129,0.45)"),
                (instance.ref.inputRef.current.style.opacity = "1"),
                (instance.ref.inputRef.current.style.fontWeight = "bold"))
              }
              value={
                props.startDate
                  ? formatDate(
                      beToAd(props.startDate)?.add(
                        props.duration ? Number(props.duration) - 1 : 0,
                        "days"
                      )
                    )
                  : ""
              }
              disabled={true}
              style={{ width: "135px" }}
              inputStyle={{ width: "135px" }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </div>
  );
};

export default SubDrugOrderStatusAlert;
