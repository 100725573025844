import WasmController from "../../../../frameworks/WasmController";
import moment from "moment";
import { formatDate } from "react-lib/utils/dateUtils";
import getPdfMake from "react-lib/appcon/common/pdfMake";
// PDF
import FormORCancelList from "../FormORCancelList";
// APIs
import OperatingOrderList from "issara-sdk/apis/OperatingOrderList_apps_ORM";
import OperatingRoomList from "issara-sdk/apis/OperatingRoomList_apps_ORM";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  django?: any;

  // sequence
  ORCancelListSequence?: {
    sequenceIndex?: string | null;

    orFilter?: {
      checkedHn?: boolean;
      emr__encounter__patient__hn?: string;
      patientFullName?: string;
      checkedDoctor?: boolean;
      chief_surgeon?: any | null;
      selectedDoctor?: any;
      operating_detail__operating_room__location_id?: any;
      operating_detail__operating_room__id?: any;
      start_date?: string;
      end_date?: string;
      created?: string;
    };
    roomOptions?: any[];
    cancelOrder?: any;
  } | null;
};

export const StateInitial: State = {
  ORCancelListSequence: {
    sequenceIndex: null,

    orFilter: {
      checkedHn: false,
      emr__encounter__patient__hn: "",
      patientFullName: "",
      checkedDoctor: false,
      chief_surgeon: null,
      selectedDoctor: null,
      operating_detail__operating_room__location_id: "ALL",
      operating_detail__operating_room__id: "ALL",
      start_date: formatDate(moment()),
      end_date: formatDate(moment()),
      created: "",
    },
    roomOptions: [],
    cancelOrder: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  masterData?: { [name: string]: any };
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData" as any,
    params: {
      masters: [
        ["operatingType", {}],
        ["orLocation", {}],
      ],
    },
  });

  controller.setState(
    {
      ORCancelListSequence: { sequenceIndex: "Action" },
    },
    () => {
      Action(controller, { action: "CLEAR_FILTER" });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "FETCH") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params?.action}`]: "LOADING",
      },
    });

    let queryParams: any = {
      status: 10,
      operating_detail__operating_room__location_id:
        state.ORCancelListSequence?.orFilter
          ?.operating_detail__operating_room__location_id === "ALL"
          ? ""
          : state.ORCancelListSequence?.orFilter
              ?.operating_detail__operating_room__location_id,
      operating_detail__operating_room__id:
        state.ORCancelListSequence?.orFilter
          ?.operating_detail__operating_room__id === "ALL"
          ? ""
          : state.ORCancelListSequence?.orFilter
              ?.operating_detail__operating_room__id,
      start_date: state.ORCancelListSequence?.orFilter?.start_date,
      end_date: state.ORCancelListSequence?.orFilter?.end_date,
      created: state.ORCancelListSequence?.orFilter?.created,
      limit: 100,
    };
    if (state.ORCancelListSequence?.orFilter?.checkedHn) {
      queryParams.emr__encounter__patient__hn =
        state.ORCancelListSequence?.orFilter?.emr__encounter__patient__hn;
    }
    if (state.ORCancelListSequence?.orFilter?.checkedDoctor) {
      queryParams.chief_surgeon =
        state.ORCancelListSequence?.orFilter?.chief_surgeon;
    }

    const [resp, error, network] = await OperatingOrderList.list({
      apiToken: controller.apiToken,
      params: queryParams,
    });

    if (error) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params?.action}`]: "ERROR",
        },
        errorMessage: error,
        ORCancelListSequence: {
          ...state.ORCancelListSequence,
          cancelOrder: { items: [], next: null, previous: null, total: 0 },
        },
      });
    } else {
      const items: any[] = resp?.items?.map((item: any) => {
        const orType = (controller.data.masterData?.operatingType || []).filter(
          (t: any) => {
            return t.value === item?.type;
          }
        );
        return {
          ...item,
          locationRoom: `${item?.location_name} / ${item?.operating_room_no}`,
          diagnosis: item.teams
            .flatMap((team: any) =>
              team.is_main
                ? [
                    team.pre_principal_diagnosis?.[0]?.icd_code
                      ? `[${team.pre_principal_diagnosis?.[0]?.icd_code}] ${team.pre_principal_diagnosis?.[0]?.icd_term}`
                      : "",
                  ]
                : []
            )
            .join(", "),
          typeLabel: orType?.[0]?.label,
        };
      });

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params?.action}`]: "SUCCESS",
        },
        ORCancelListSequence: {
          ...state.ORCancelListSequence,
          cancelOrder: { ...resp, items: items },
        },
      });
    }
  } else if (params?.action === "FILTER_LOCATION_AND_FETCH_ROOM_ITEM") {
    if (params.location === "ALL") {
      return;
    }
    const [roomResp, roomErr, roomNet] = await OperatingRoomList.list({
      apiToken: controller.apiToken,
      params: { location: params.location, limit: 100 },
    });
    if (roomErr) {
      controller.setState({
        errorMessage: roomErr,
        ORCancelListSequence: {
          ...state.ORCancelListSequence,
          roomOptions: [],
        },
      });
    } else {
      controller.setState({
        ORCancelListSequence: {
          ...state.ORCancelListSequence,
          orFilter: {
            ...state.ORCancelListSequence?.orFilter,
            operating_detail__operating_room__location_id: params.location,
            operating_detail__operating_room__id: "ALL",
          },
          roomOptions: mapOptions(roomResp.items, "id", "room_no"),
        },
      });
    }
  } else if (params?.action === "SET_FILTER") {
    controller.setState({
      ORCancelListSequence: {
        ...state.ORCancelListSequence,
        orFilter: { ...state.ORCancelListSequence?.orFilter, ...params.data },
      },
    });
  } else if (params?.action === "CLEAR_FILTER") {
    controller.setState({
      ORCancelListSequence: {
        ...state.ORCancelListSequence,
        orFilter: {
          checkedHn: false,
          emr__encounter__patient__hn: "",
          patientFullName: "",
          checkedDoctor: false,
          chief_surgeon: null,
          selectedDoctor: null,
          operating_detail__operating_room__location_id: "ALL",
          operating_detail__operating_room__id: "ALL",
          start_date: formatDate(moment()),
          end_date: formatDate(moment()),
          created: "",
        },
        roomOptions: [],
      },
    });
  } else if (params?.action === "PRINT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    const data = Object.assign({
      start_date: state.ORCancelListSequence?.orFilter?.start_date || " - ",
      end_date: state.ORCancelListSequence?.orFilter?.end_date || " - ",
      print_user: state.django?.user?.full_name || "",
      data: state.ORCancelListSequence?.cancelOrder?.items || [],
    });

    let docDef: any = { content: [] };

    docDef = FormORCancelList(data);

    const pdfMake = (await getPdfMake()).createPdf(docDef);
    pdfMake.open();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "SUCCESS",
      },
      successMessage: "Print Success",
    });
    return;
  }
};

// utilities
const mapOptions = (list: any[], valueKey = "id", textKey = "name") => {
  return list?.map((item: any) => ({
    key: item.id,
    value: item[valueKey],
    text: item[textKey],
  }));
};
