import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Button,
  Input,
  Modal,
  Dimmer,
  Loader,
  Dropdown,
} from "semantic-ui-react";

// UX
import ModEditDiscount from "./ModEditDiscount";
import CardMedicalFeeHistoryUX from "./CardMedicalFeeHistoryUX";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { ErrorMessage, ModInfo, EmployeeToken } from "react-lib/apps/common";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";

// Const
const CARD_MEDICAL_FEE_HISTORY = "CardMedicalFeeHistory";
const MOD_EDIT_DISCOUNT = "ModEditDiscountUX";
const MOD_ADD_ITEMS = "ModAddItems";

const CardMedicalFeeHistory = (props: any) => {
  const [openModEdit, setOpenModEdit] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [tracking, setTracking] = useState(false);

  const trackingStatus = props.MedicalFeeHistorySequence?.printStatus;

  useEffect(() => {
    props.runSequence({ sequence: "MedicalFeeHistory", restart: true });

    return () => {
      props.runSequence({ sequence: "MedicalFeeHistory", clear: true });
    };
  }, []);

  useEffect(() => {
    if (trackingStatus !== undefined && trackingStatus?.status !== "CANCELED") {
      setTracking(true);
    }
    if (
      trackingStatus?.status === "WAITING" ||
      trackingStatus?.status === "IN_PROGRESS"
    ) {
      setTimeout(() => {
        props.runSequence({
          sequence: "MedicalFeeHistory",
          action: "trackingPrint",
          task_id: trackingStatus?.task_id,
          pdf: trackingStatus?.pdf,
        });
      }, 5000);
    }
  }, [trackingStatus]);

  const handleCloseModalTracking = () => {
    if (trackingStatus?.success || trackingStatus?.status === "CANCELED") {
      setTracking(false);
    } else {
      props.runSequence({
        sequence: "MedicalFeeHistory",
        action: "trackingPrint",
        task_id: trackingStatus?.task_id,
        pdf: trackingStatus?.pdf,
        status: "CANCEL",
      });
    }
  };

  const medicalFeeHistoryList = useMemo(() => {
    return (props.MedicalFeeHistorySequence?.MedicalFeeHistoryList || []).map(
      (item: any) => {
        const formatPrice = "total,paid,unpaid,covered,discount"
          .split(",")
          .reduce(
            (r: any, k: any) => ({
              ...r,
              [k]: (
                <div style={{ textAlign: "right" }}>
                  {Number(item[k]).toLocaleString("en-US", {
                    style: "decimal",
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              ),
            }),
            {}
          );

        return {
          ...item,
          created: formatDatetime(item.created),
          ...formatPrice,
        };
      }
    ) as any[];
  }, [props.MedicalFeeHistorySequence?.MedicalFeeHistoryList]);

  const handleRowProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id && rowInfo?.original?.id === selectedRow?.id
            ? "#cccccc"
            : "white",
      },
      onClick: () => setSelectedRow(rowInfo.original),
    };
  };

  return (
    <div>
      <CardMedicalFeeHistoryUX
        MedicalFeeHistoryList={medicalFeeHistoryList}
        selectedRow={selectedRow}
        getTrProps={handleRowProps}
        buttonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "MedicalFeeHistory",
                action: "print",
                encounter: selectedRow?.id,
                card: CARD_MEDICAL_FEE_HISTORY,
                buttonLoadKey: `${CARD_MEDICAL_FEE_HISTORY}_PRINT`,
              });
            }}
            // data
            paramKey={`${CARD_MEDICAL_FEE_HISTORY}_PRINT`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_MEDICAL_FEE_HISTORY}_PRINT`]
            }
            // config
            name="PRINT"
            disabled={selectedRow ? false : true}
            color="gray"
            size="small"
            title={"พิมพ์รายการค่าใช้จ่าย"}
          />
        }
        onEdit={() => {
          setOpenModEdit(true);
          props.runSequence({
            sequence: "MedicalFeeHistory",
            action: "openEdit",
            encounter: selectedRow?.id,
          });
        }}
        onRefresh={() => {
          props.runSequence({
            sequence: "MedicalFeeHistory",
            action: "refresh",
          });
          setSelectedRow(null);
        }}
      />

      <Modal
        open={openModEdit}
        size="large"
        onClose={() => {
          setOpenModEdit(false);
          props.setProp("errorMessage", {
            ...props.errorMessage,
            [`${MOD_EDIT_DISCOUNT}_ADD`]: null,
            [`${MOD_EDIT_DISCOUNT}_SAVE`]: null,
          });
        }}
      >
        <ModEditDiscount
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          MedicalFeeHistorySequence={props.MedicalFeeHistorySequence}
          // data
          // * ไม่ต้องส่ง encounter หากไม่ต้องการ filter by encounter
          encounterId={selectedRow?.id}
          // CommonInterface
          errorMessage={props.errorMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          loadingStatus={props.loadingStatus}
          // options
          masterOptions={props.masterOptions}
        />
      </Modal>

      {/* tracking */}
      <Modal open={tracking} size="mini">
        <div>
          <div
            style={{
              backgroundColor: trackingStatus?.success
                ? "green"
                : trackingStatus?.status === "CANCELED"
                ? "red"
                : "#00B5AD",
              padding: "15px",
              fontWeight: "bold",
              color: "white",
            }}
          >
            {trackingStatus?.success
              ? "เตรียมเอกสารเสร็จสิ้น"
              : trackingStatus?.status === "CANCELED"
              ? "ยกเลิก"
              : "กำลังเตรียมเอกสาร"}
          </div>
          <div style={{ padding: "15px" }}>
            {trackingStatus?.success ? (
              <div>
                <div>เตรียมเอกสารเสร็จสิ้น</div>
              </div>
            ) : trackingStatus?.status === "CANCELED" ? (
              <div>
                <div>{trackingStatus?.current_progress_description}</div>
              </div>
            ) : (
              <div>
                <div>กรุณารอสักครู่</div>
                <div style={{ padding: "10px 0px" }}>
                  For IT: task_id = {trackingStatus?.task_id}
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Button
                basic
                color={trackingStatus?.success ? "green" : "red"}
                size="tiny"
                onClick={() => handleCloseModalTracking()}
              >
                {trackingStatus?.success ||
                trackingStatus?.status === "CANCELED"
                  ? "ตกลง"
                  : "ยกเลิก"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CardMedicalFeeHistory;
