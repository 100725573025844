import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown,
  Input,
  Button,
  Checkbox,
  Radio
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardPatientAssessmentUX = (props: any) => {
    return(
      <div>
        <div
          style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(52, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
          <div
            style={{ fontSize: "1.3rem", fontWeight: "bold", gridRow: "2/4", gridColumn: "2/10",  }}>
            แบบประเมินแรกรับ
          </div>
          <div
            style={{ gridRow: "5/7", gridColumn: "2/5",  }}>
            Date
          </div>
          <div
            style={{ gridRow: "8/10", gridColumn: "2/5",  }}>
            Arrived by
          </div>
          <div
            style={{ gridRow: "11/13", gridColumn: "2/5",  }}>
            ใช้ล่าม
          </div>
          <div
            style={{ gridRow: "13/14", gridColumn: "2/50", borderBottom: "solid 1px rgb(200, 200, 200, 0.5)" }}>
          </div>
          <div
            style={{ paddingRight: "10px", textAlign: "right", gridRow: "15/17", gridColumn: "2/10", }}>
            ผู้บันทึก/แก้ไข
          </div>
          <div
            style={{ paddingRight: "10px", textAlign: "right", gridRow: "18/20", gridColumn: "2/10", }}>
            Functional Assessment
          </div>
          <div
            style={{ paddingRight: "10px", textAlign: "right", gridRow: "21/23", gridColumn: "2/10", }}>
            Psychological Assessment
          </div>
          <div
            style={{ paddingRight: "10px", textAlign: "right", gridRow: "24/26", gridColumn: "2/10", }}>
            Spiritual/Cultural Assessment
          </div>
          <div
            style={{ paddingRight: "10px", textAlign: "right", gridRow: "27/29", gridColumn: "2/10",  }}>
            Suspected abuse or neglect
          </div>
          <div
            style={{ paddingRight: "10px", textAlign: "right", gridRow: "30/32", gridColumn: "2/10",  }}>
            History of Febrile convulsion
          </div>
          <div
            style={{ paddingRight: "10px", textAlign: "right", gridRow: "33/35", gridColumn: "2/10",  }}>
            เสี่ยงต่อการติดเชื้อ
          </div>
          <div
            style={{ paddingRight: "10px", textAlign: "right", gridRow: "36/38", gridColumn: "2/10", }}>
            Isolate Precaution
          </div>
          <div
            style={{ paddingRight: "10px", textAlign: "right", gridRow: "39/41", gridColumn: "2/10", }}>
            ประวัติการดื่มสุรา
          </div>
          <div
            style={{ paddingRight: "10px", textAlign: "right", gridRow: "42/44", gridColumn: "2/10", }}>
            ประวัติการสูบบุหรี่
          </div>
          <div
            style={{ paddingRight: "10px", textAlign: "right", gridRow: "45/47", gridColumn: "2/10", }}>
            ประวัติการใช้สารเสพติด
          </div>
          <div
            style={{ paddingRight: "10px", textAlign: "right", gridRow: "50/52", gridColumn: "2/10", ...(!props.isShowPregnancy && {gridRow: "48/50", }), display: props.isMenstruation ? "" : "none"}}>
            Menstruation
          </div>
          <div
            style={{ gridRow: "8/10", gridColumn: "6/21", }}>
            <Dropdown
              allowAdditions={true}
              compact={true}
              fluid={true}
              onAddItem={props.handleAddition}
              onChange={props.changeForm("arrive_status")}
              options={props.arrivedByOptions}
              selection={true}
              style={{width: "100%", height: "100%"}}
              value={props.arrive_status}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "11/13", gridColumn: "6/8", }}>
            <Checkbox
              checked={props.translator}
              onChange={props.changeForm("translator", !props.translator)}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            style={{ gridRow: "11/13", gridColumn: "9/12",  }}>
            {props.translator ? "ใช้" :  " ไม่ใช้"}
          </div>
          <div
            style={{ gridRow: "5/7", gridColumn: "12/17", textAlign: "right"}}>
            Chief Complaint
          </div>
          <div
            style={{ gridRow: "8/10", gridColumn: "22/27", textAlign: "right"}}>
            ระดับการคัดกรอง
          </div>
          <div
            style={{ gridRow: "5/7", gridColumn: "18/42",  }}>
            <Input
              onChange={props.changeForm("chief_complaint")}
              style={{height: "100%", width: "100%"}}
              value={props?.chief_complaint}>
            </Input>
          </div>
          <div
            style={{ gridRow: "8/10", gridColumn: "28/38",  }}>
            <Dropdown
              fluid={true}
              onChange={props.changeForm("triage_level")}
              options={props.triageLevelOptions}
              selection={true}
              style={{width: "100%", height: "100%"}}
              value={props.triage_level}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "15/17", gridColumn: "11/14",  }}>
            {props.user}
          </div>
          <div
            style={{ gridRow: "18/20", gridColumn: "11/21",  }}>
            <Dropdown
              fluid={true}
              onChange={props.changeForm("functional")}
              options={props.functionalOptions}
              selection={true}
              style={{width: "100%", height: "100%"}}
              value={props.functional}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "18/20", gridColumn: "22/23", }}>
            ระบุ
          </div>
          <div
            style={{ gridRow: "18/20", gridColumn: "23/31",}}>
            <Input
              onChange={props?.changeForm("functional_assm_other")}
              size="mini"
              style={{height: "100%", width: "100%"}}
              value={props?.functional_assm_other}>
            </Input>
          </div>
          <div
            style={{ gridRow: "21/23", gridColumn: "11/13", }}>
            <Checkbox
              checked={props.psychological}
              onChange={props.changeForm("psychological", !props.psychological)}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            style={{ gridRow: "21/23", gridColumn: "14/21", }}>
            {props.psychological ? "แสดงออกทางอารมณ์และพฤติกรรมผิดปกติ" : "แสดงออกทางอารมณ์และพฤติกรรมปกติ"}
          </div>
          <div
            style={{paddingLeft: "5px", gridRow: "21/23", gridColumn: "31/34", }}>
            การให้การพยาบาล
          </div>
          <div
            style={{ gridRow: "21/23", gridColumn: "34/43",}}>
            <Dropdown
              fluid={true}
              onChange={props.changeForm("phyco_nurse_note")}
              options={props.phychoNurseNoteOptions}
              selection={true}
              style={{width: "100%", height: "100%"}}
              value={props.phyco_nurse_note}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "24/26", gridColumn: "11/13",  }}>
            <Checkbox
              checked={props.spiritual}
              onChange={props.changeForm("spiritual", !props.spiritual)}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            style={{ gridRow: "24/26", gridColumn: "14/17",  }}>
            {props.spiritual ? "YES" : "No"}
          </div>
          <div
            style={{ gridRow: "27/29", gridColumn: "11/21",  }}>
            <Dropdown
              fluid={true}
              onChange={props.changeForm("suspected")}
              options={props.suspectedOptions}
              selection={true}
              style={{width: "100%", height: "100%"}}
              value={props.suspected}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "27/29", gridColumn: "22/23", }}>
            ระบุ
          </div>
          <div
            style={{ gridRow: "27/29", gridColumn: "23/31",  }}>
            <Input
              onChange={props?.changeForm("suspected_specific")}
              size="mini"
              style={{height: "100%", width: "100%"}}
              value={props?.suspected_specific}>
            </Input>
          </div>
          <div
            style={{paddingLeft: "5px", gridRow: "27/29", gridColumn: "31/34", }}>
            การให้การพยาบาล
          </div>
          <div
            style={{ gridRow: "27/29", gridColumn: "34/43", }}>
            <Dropdown
              fluid={true}
              onChange={props.changeForm("sus_nurse_note")}
              options={props.susNurseNoteOptions}
              selection={true}
              style={{width: "100%", height: "100%"}}
              value={props.sus_nurse_note}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "30/32", gridColumn: "11/21",  }}>
            <Dropdown
              fluid={true}
              onChange={props.changeForm("convulsion")}
              options={props.convulsionOptions}
              selection={true}
              style={{width: "100%", height: "100%"}}
              value={props.convulsion}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "33/35", gridColumn: "11/21",  }}>
            <Dropdown
              fluid={true}
              onChange={props.changeForm("mdr_triage")}
              options={props.mdrOptions}
              selection={true}
              style={{width: "100%", height: "100%"}}
              value={props.mdr_triage}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "36/38", gridColumn: "11/31",  }}>
            <Dropdown
              fluid={true}
              onChange={props.changeForm("precaution")}
              options={props.precautionOptions}
              selection={true}
              style={{width: "100%", height: "100%"}}
              value={props.precaution}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "39/41", gridColumn: "11/21",  }}>
            <Dropdown
              fluid={true}
              onChange={props.changeForm("alcohol")}
              options={props.alcoholOptions}
              selection={true}
              style={{width: "100%", height: "100%"}}
              value={props.alcohol}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "42/44", gridColumn: "11/21",  }}>
            <Dropdown
              fluid={true}
              onChange={props.changeForm("tobacco")}
              options={props.tobaccoOptions}
              selection={true}
              style={{width: "100%", height: "100%"}}
              value={props.tobacco}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "45/47", gridColumn: "11/21",  }}>
            <Dropdown
              fluid={true}
              onChange={props.changeForm("narcotic")}
              options={props.narcoticOptions}
              selection={true}
              style={{width: "100%", height: "100%"}}
              value={props.narcotic}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "50/52", gridColumn: "11/21",  ...(!props.isShowPregnancy && {gridRow: "48/50", }), display: props.isMenstruation ? "" : "none" }}>
            <Dropdown
              fluid={true}
              onChange={props.changeForm("menstruation")}
              options={props.menstruationOptions}
              selection={true}
              style={{width: "100%", height: "100%"}}
              value={props.menstruation}>
            </Dropdown>
          </div>
          <div
            style={{ display: props.isMenstruation ? props.menstruation === "LMP" ? "block" : "none" : "none" , gridRow: "50/52", gridColumn: "22/33",  ...(!props.isShowPregnancy && {gridRow: "48/50", }),}}>
            <DateTextBox
              onChange={props.changeDate("lmp")}
              value={props.lmp}>
            </DateTextBox>
          </div>
          <div
            style={{ gridRow: "50/52", gridColumn: "45/50",  ...(!props.isShowPregnancy && {gridRow: "48/50", }), }}>
            <Button
              color="blue"
              onClick={props.onClear}
              size="mini"
              style={{width: "100%"}}>
              Clear
            </Button>
          </div>
          <div
            style={{ display: props.translator ? "block" : "none", gridRow: "11/13", gridColumn: "12/17", textAlign: "right"}}>
            ระบุ
          </div>
          <div
            style={{ display: props.translator ? "block" : "none",  gridRow: "11/13", gridColumn: "18/42"}}>
            <Input
              fluid={true}
              onChange={props.changeForm("translator_specific")}
              value={props.translator_specific}>
            </Input>
          </div>
          <div
            style={{ gridRow: "5/7", gridColumn: "6/11", }}>
            {props.date}
          </div>
          <div
            style={{ display: props.psychological ? "block" : "none", gridRow: "21/23", gridColumn: "22/23", }}>
            ระบุ
          </div>
          <div
            style={{ display: props.psychological ? "block" : "none",  gridRow: "21/23", gridColumn: "23/31", }}>
            <Input
              fluid={true}
              onChange={props.changeForm("psychological_specific")}
              value={props.psychological_specific}>
            </Input>
          </div>
          <div
            style={{ display: props.spiritual ? "block" : "none", gridRow: "24/26", gridColumn: "22/23", }}>
            ระบุ
          </div>
          <div
            style={{ display: props.spiritual ? "block" : "none",  gridRow: "24/26", gridColumn: "23/31", }}>
            <Dropdown
              fluid={true}
              onChange={props.changeForm("spiritual_detail")}
              options={props.spiritualDetailOptions}
              selection={true}
              value={props.spiritual_detail}>
            </Dropdown>
          </div>
          <div
            style={{ display: props.phyco_nurse_note === "NON" ? "none" : "block",  gridRow: "21/23", gridColumn: "44/50", }}>
            <Input
              fluid={true}
              onChange={props.changeForm("phyco_nurse_note_specific")}
              placeholder="ระบุ"
              value={props.phyco_nurse_note_specific}>
            </Input>
          </div>
          <div
            style={{ display: props.sus_nurse_note !== "NON" ? "block" : "none", gridRow: "27/29", gridColumn: "44/50",  }}>
            <Input
              fluid={true}
              onChange={props.changeForm("sus_nurse_note_specific")}
              placeholder="ระบุ"
              value={props.sus_nurse_note_specific}>
            </Input>
          </div>
          <div
            style={{ display: props.mdr_triage === "YES" ? "block" : "none",  gridRow: "33/35", gridColumn: "22/24", }}>
            MDR
          </div>
          <div
            style={{ display: props.mdr_triage === "YES" ? "block" : "none", gridRow: "33/35", gridColumn: "24/27", }}>
            <Checkbox
              checked={props.mdr}
              onChange={props.changeForm("mdr", !props.mdr)}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            style={{ display: props.mdr_triage === "YES" ? "block" : "none", gridRow: "33/35", gridColumn: "27/29", }}>
            {props.mdr ? "YES" : "NO"}
          </div>
          <div
            style={{ display: ["YES", "Past"].includes(props.alcohol) ? "block" : "none",  gridRow: "39/41", gridColumn: "22/23", }}>
            ระบุ
          </div>
          <div
            style={{ display: ["YES", "Past"].includes(props.alcohol) ? "block" : "none",   gridRow: "39/41", gridColumn: "23/31",}}>
            <Dropdown
              fluid={true}
              onChange={props.changeForm("alcohol_specific")}
              options={props.alcoholSpecificOptions}
              selection={true}
              value={props.alcohol_specific}>
            </Dropdown>
          </div>
          <div
            style={{ display: ["YES", "Past"].includes(props.tobacco) ? "block" : "none",  gridRow: "42/44", gridColumn: "22/23", }}>
            ระบุ
          </div>
          <div
            style={{ display: ["YES", "Past"].includes(props.tobacco) ? "block" : "none",   gridRow: "42/44", gridColumn: "23/31", }}>
            <Dropdown
              fluid={true}
              onChange={props.changeForm("tobacco_specific")}
              options={props.tobaccoSpecificOptions}
              selection={true}
              value={props.tobacco_specific}>
            </Dropdown>
          </div>
          <div
            style={{ display: props.narcotic === "YES" ? "block" : "none", gridRow: "45/47", gridColumn: "22/23", }}>
            ระบุ
          </div>
          <div
            style={{ display: props.narcotic === "YES" ? "block" : "none", gridRow: "45/47", gridColumn: "23/31", }}>
            <Input
              fluid={true}
              onChange={props.changeForm("narcotic_specific")}
              value={props.narcotic_specific}>
            </Input>
          </div>
          <div
            style={{ paddingRight: "10px", textAlign: "right", gridRow: "48/50", gridColumn: "2/10", alignItems: "center", ...(!props.isShowPregnancy && {display: "none"})}}>
            ประวัติการตั้งครรภ์
          </div>
          <div
            style={{ gridRow: "47/50", gridColumn: "11/50",  display: "flex", alignItems: "center", ...(!props.isShowPregnancy && {display: "none"})}}>
            
            <Radio
              checked={props.pregnancyAssessment?.pregnancy_status === 1}
              label="ไม่ทราบ"
              onChange={props.changePregnancy("pregnancy_status")}
              style={{ marginRight: "20px" }}
              value={1}>
            </Radio>
            <Radio
              checked={props.pregnancyAssessment?.pregnancy_status === 2}
              label="ไม่ตั้งครรภ์"
              onChange={props.changePregnancy("pregnancy_status")}
              style={{ marginRight: "20px" }}
              value={2}>
            </Radio>
            <Radio
              checked={props.pregnancyAssessment?.pregnancy_status === 3}
              label="กำลังตั้งครรภ์"
              onChange={props.changePregnancy("pregnancy_status")}
              style={{ marginRight: "20px" }}
              value={3}>
            </Radio>
            <div
              style={{ marginRight: "20px", ...(props.encounterData?.pregnancy_status !== 3 && {display: "none"})}}>
              ระบุไตรมาส
            </div>
            <Dropdown
              onChange={props.changePregnancy("pregnancy_period")}
              options={props.pregnancyPeriodOptions}
              selection={true}
              style={{ marginRight: "20px", ...(props.pregnancyAssessment?.pregnancy_status !== 3 && {display: "none"})}}
              value={props.pregnancyAssessment?.pregnancy_period}>
            </Dropdown>
          </div>
        </div>
      </div>
    )
}


export default CardPatientAssessmentUX

export const screenPropsDefault = {"alcohol":"YES","mdr":true,"mdr_triage":"YES","menstruation":"LMP","narcotic":"YES","psychological":true,"spiritual":true,"tobacco":"YES","translator":true}

/* Date Time : Mon Jul 24 2023 04:32:39 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(52, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "แบบประเมินแรกรับ"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.3rem\", fontWeight: \"bold\", gridRow: \"2/4\", gridColumn: \"2/10\",  }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Date"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"2/5\",  }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Arrived by"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"2/5\",  }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ใช้ล่าม"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/13\", gridColumn: \"2/5\",  }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"2/50\", borderBottom: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้บันทึก/แก้ไข"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", gridRow: \"15/17\", gridColumn: \"2/10\", }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Functional Assessment"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", gridRow: \"18/20\", gridColumn: \"2/10\", }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Psychological Assessment"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", gridRow: \"21/23\", gridColumn: \"2/10\", }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Spiritual/Cultural Assessment"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", gridRow: \"24/26\", gridColumn: \"2/10\", }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Suspected abuse or neglect"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", gridRow: \"27/29\", gridColumn: \"2/10\",  }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "History of Febrile convulsion"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", gridRow: \"30/32\", gridColumn: \"2/10\",  }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "เสี่ยงต่อการติดเชื้อ"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", gridRow: \"33/35\", gridColumn: \"2/10\",  }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Isolate Precaution"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", gridRow: \"36/38\", gridColumn: \"2/10\", }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการดื่มสุรา"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", gridRow: \"39/41\", gridColumn: \"2/10\", }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการสูบบุหรี่"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", gridRow: \"42/44\", gridColumn: \"2/10\", }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการใช้สารเสพติด"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", gridRow: \"45/47\", gridColumn: \"2/10\", }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Menstruation"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", gridRow: \"50/52\", gridColumn: \"2/10\", ...(!props.isShowPregnancy && {gridRow: \"48/50\", }), display: props.isMenstruation ? \"\" : \"none\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"6/21\", }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/13\", gridColumn: \"6/8\", }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.translator ? \"ใช้\" :  \" ไม่ใช้\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/13\", gridColumn: \"9/12\",  }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Dropdown",
      "parent": 19,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "compact": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "code",
          "value": ""
        },
        "onAddItem": {
          "type": "code",
          "value": "props.handleAddition"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"arrive_status\")"
        },
        "options": {
          "type": "code",
          "value": "props.arrivedByOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.arrive_status"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Chief Complaint"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"12/17\", textAlign: \"right\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ระดับการคัดกรอง"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"22/27\", textAlign: \"right\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"18/42\",  }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"28/38\",  }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.user"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"15/17\", gridColumn: \"11/14\",  }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"18/20\", gridColumn: \"11/21\",  }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"18/20\", gridColumn: \"22/23\", }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"18/20\", gridColumn: \"23/31\",}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"21/23\", gridColumn: \"11/13\", }"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.psychological ? \"แสดงออกทางอารมณ์และพฤติกรรมผิดปกติ\" : \"แสดงออกทางอารมณ์และพฤติกรรมปกติ\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"21/23\", gridColumn: \"14/21\", }"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "การให้การพยาบาล"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"5px\", gridRow: \"21/23\", gridColumn: \"31/34\", }"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"21/23\", gridColumn: \"34/43\",}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"24/26\", gridColumn: \"11/13\",  }"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.spiritual ? \"YES\" : \"No\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"24/26\", gridColumn: \"14/17\",  }"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"27/29\", gridColumn: \"11/21\",  }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"27/29\", gridColumn: \"22/23\", }"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"27/29\", gridColumn: \"23/31\",  }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "การให้การพยาบาล"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"5px\", gridRow: \"27/29\", gridColumn: \"31/34\", }"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"27/29\", gridColumn: \"34/43\", }"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"30/32\", gridColumn: \"11/21\",  }"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"33/35\", gridColumn: \"11/21\",  }"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"36/38\", gridColumn: \"11/31\",  }"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"39/41\", gridColumn: \"11/21\",  }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"42/44\", gridColumn: \"11/21\",  }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"45/47\", gridColumn: \"11/21\",  }"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"50/52\", gridColumn: \"11/21\",  ...(!props.isShowPregnancy && {gridRow: \"48/50\", }), display: props.isMenstruation ? \"\" : \"none\" }"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: props.isMenstruation ? props.menstruation === \"LMP\" ? \"block\" : \"none\" : \"none\" , gridRow: \"50/52\", gridColumn: \"22/33\",  ...(!props.isShowPregnancy && {gridRow: \"48/50\", }),}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"50/52\", gridColumn: \"45/50\",  ...(!props.isShowPregnancy && {gridRow: \"48/50\", }), }"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Dropdown",
      "parent": 26,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"triage_level\")"
        },
        "options": {
          "type": "code",
          "value": "props.triageLevelOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.triage_level"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Input",
      "parent": 25,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"chief_complaint\")"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.chief_complaint"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Dropdown",
      "parent": 34,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"phyco_nurse_note\")"
        },
        "options": {
          "type": "code",
          "value": "props.phychoNurseNoteOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.phyco_nurse_note"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Dropdown",
      "parent": 41,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"sus_nurse_note\")"
        },
        "options": {
          "type": "code",
          "value": "props.susNurseNoteOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.sus_nurse_note"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Dropdown",
      "parent": 28,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"functional\")"
        },
        "options": {
          "type": "code",
          "value": "props.functionalOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.functional"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Dropdown",
      "parent": 37,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"suspected\")"
        },
        "options": {
          "type": "code",
          "value": "props.suspectedOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.suspected"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Dropdown",
      "parent": 42,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"convulsion\")"
        },
        "options": {
          "type": "code",
          "value": "props.convulsionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.convulsion"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Dropdown",
      "parent": 43,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"mdr_triage\")"
        },
        "options": {
          "type": "code",
          "value": "props.mdrOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.mdr_triage"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Dropdown",
      "parent": 44,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"precaution\")"
        },
        "options": {
          "type": "code",
          "value": "props.precautionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.precaution"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Dropdown",
      "parent": 45,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"alcohol\")"
        },
        "options": {
          "type": "code",
          "value": "props.alcoholOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.alcohol"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Dropdown",
      "parent": 46,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"tobacco\")"
        },
        "options": {
          "type": "code",
          "value": "props.tobaccoOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.tobacco"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Dropdown",
      "parent": 47,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"narcotic\")"
        },
        "options": {
          "type": "code",
          "value": "props.narcoticOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.narcotic"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Dropdown",
      "parent": 48,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"menstruation\")"
        },
        "options": {
          "type": "code",
          "value": "props.menstruationOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.menstruation"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Input",
      "parent": 30,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props?.changeForm(\"functional_assm_other\")"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.functional_assm_other"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Input",
      "parent": 39,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props?.changeForm(\"suspected_specific\")"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.suspected_specific"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "Button",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": "Clear"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 68,
      "name": "DateTextBox",
      "parent": 49,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"lmp\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.lmp"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Checkbox",
      "parent": 20,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.translator"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"translator\", !props.translator)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Checkbox",
      "parent": 31,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.psychological"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"psychological\", !props.psychological)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Checkbox",
      "parent": 35,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.spiritual"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"spiritual\", !props.spiritual)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.translator ? \"block\" : \"none\", gridRow: \"11/13\", gridColumn: \"12/17\", textAlign: \"right\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: props.translator ? \"block\" : \"none\",  gridRow: \"11/13\", gridColumn: \"18/42\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.date"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"6/11\", }"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Input",
      "parent": 73,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"translator_specific\")"
        },
        "value": {
          "type": "code",
          "value": "props.translator_specific"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.psychological ? \"block\" : \"none\", gridRow: \"21/23\", gridColumn: \"22/23\", }"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: props.psychological ? \"block\" : \"none\",  gridRow: \"21/23\", gridColumn: \"23/31\", }"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.spiritual ? \"block\" : \"none\", gridRow: \"24/26\", gridColumn: \"22/23\", }"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: props.spiritual ? \"block\" : \"none\",  gridRow: \"24/26\", gridColumn: \"23/31\", }"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Input",
      "parent": 77,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"psychological_specific\")"
        },
        "value": {
          "type": "code",
          "value": "props.psychological_specific"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Dropdown",
      "parent": 79,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"spiritual_detail\")"
        },
        "options": {
          "type": "code",
          "value": "props.spiritualDetailOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.spiritual_detail"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: props.phyco_nurse_note === \"NON\" ? \"none\" : \"block\",  gridRow: \"21/23\", gridColumn: \"44/50\", }"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: props.sus_nurse_note !== \"NON\" ? \"block\" : \"none\", gridRow: \"27/29\", gridColumn: \"44/50\",  }"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Input",
      "parent": 82,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"phyco_nurse_note_specific\")"
        },
        "placeholder": {
          "type": "value",
          "value": "ระบุ"
        },
        "value": {
          "type": "code",
          "value": "props.phyco_nurse_note_specific"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Input",
      "parent": 83,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"sus_nurse_note_specific\")"
        },
        "placeholder": {
          "type": "value",
          "value": "ระบุ"
        },
        "value": {
          "type": "code",
          "value": "props.sus_nurse_note_specific"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "MDR"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.mdr_triage === \"YES\" ? \"block\" : \"none\",  gridRow: \"33/35\", gridColumn: \"22/24\", }"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: props.mdr_triage === \"YES\" ? \"block\" : \"none\", gridRow: \"33/35\", gridColumn: \"24/27\", }"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Checkbox",
      "parent": 87,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.mdr"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"mdr\", !props.mdr)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.mdr ? \"YES\" : \"NO\""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.mdr_triage === \"YES\" ? \"block\" : \"none\", gridRow: \"33/35\", gridColumn: \"27/29\", }"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ display: [\"YES\", \"Past\"].includes(props.alcohol) ? \"block\" : \"none\",  gridRow: \"39/41\", gridColumn: \"22/23\", }"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: [\"YES\", \"Past\"].includes(props.alcohol) ? \"block\" : \"none\",   gridRow: \"39/41\", gridColumn: \"23/31\",}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ display: [\"YES\", \"Past\"].includes(props.tobacco) ? \"block\" : \"none\",  gridRow: \"42/44\", gridColumn: \"22/23\", }"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: [\"YES\", \"Past\"].includes(props.tobacco) ? \"block\" : \"none\",   gridRow: \"42/44\", gridColumn: \"23/31\", }"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.narcotic === \"YES\" ? \"block\" : \"none\", gridRow: \"45/47\", gridColumn: \"22/23\", }"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: props.narcotic === \"YES\" ? \"block\" : \"none\", gridRow: \"45/47\", gridColumn: \"23/31\", }"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Input",
      "parent": 95,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"narcotic_specific\")"
        },
        "value": {
          "type": "code",
          "value": "props.narcotic_specific"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "Dropdown",
      "parent": 91,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"alcohol_specific\")"
        },
        "options": {
          "type": "code",
          "value": "props.alcoholSpecificOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.alcohol_specific"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "Dropdown",
      "parent": 93,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeForm(\"tobacco_specific\")"
        },
        "options": {
          "type": "code",
          "value": "props.tobaccoSpecificOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.tobacco_specific"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการตั้งครรภ์"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", textAlign: \"right\", gridRow: \"48/50\", gridColumn: \"2/10\", alignItems: \"center\", ...(!props.isShowPregnancy && {display: \"none\"})}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"47/50\", gridColumn: \"11/50\",  display: \"flex\", alignItems: \"center\", ...(!props.isShowPregnancy && {display: \"none\"})}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Radio",
      "parent": 102,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.pregnancyAssessment?.pregnancy_status === 1"
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePregnancy(\"pregnancy_status\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "Radio",
      "parent": 102,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.pregnancyAssessment?.pregnancy_status === 2"
        },
        "label": {
          "type": "value",
          "value": "ไม่ตั้งครรภ์"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePregnancy(\"pregnancy_status\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Radio",
      "parent": 102,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.pregnancyAssessment?.pregnancy_status === 3"
        },
        "label": {
          "type": "value",
          "value": "กำลังตั้งครรภ์"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePregnancy(\"pregnancy_status\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุไตรมาส"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", ...(props.encounterData?.pregnancy_status !== 3 && {display: \"none\"})}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Dropdown",
      "parent": 102,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changePregnancy(\"pregnancy_period\")"
        },
        "options": {
          "type": "code",
          "value": "props.pregnancyPeriodOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", ...(props.pregnancyAssessment?.pregnancy_status !== 3 && {display: \"none\"})}"
        },
        "value": {
          "type": "code",
          "value": "props.pregnancyAssessment?.pregnancy_period"
        }
      },
      "seq": 107,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardPatientAssessmentUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "alcohol": "YES",
    "mdr": true,
    "mdr_triage": "YES",
    "menstruation": "LMP",
    "narcotic": "YES",
    "psychological": true,
    "spiritual": true,
    "tobacco": "YES",
    "translator": true
  },
  "width": 75
}

*********************************************************************************** */
