import WasmController from "react-lib/frameworks/WasmController";
import AdverseReactionList from "issara-sdk/apis/AdverseReactionList_apps_ADR";
import AdverseReactionDetail from "issara-sdk/apis/AdverseReactionDetail_apps_ADR";
import IngredientList from "issara-sdk/apis/IngredientList_apps_TPD";
import DrugGroupList from "issara-sdk/apis/DrugGroupList_apps_TPD";
import MultiAdverseReactionList from "issara-sdk/apis/MultiAdverseReactionList_apps_ADR";
import DrugList from "issara-sdk/apis/DrugList_apps_TPD";

import AdverseReactionActionLogList from "issara-sdk/apis/AdverseReactionActionLogList_apps_ADR";
import {
  beToAd,
  formatDate,
  formatDateToYYYYMMDD,
} from "react-lib/utils/dateUtils";
import moment from "moment";

const ALLERY_LIST_LIMIT = 400;

export type State = {
  django?: any;
  selectedEmr?: any;
  selectedPatient?: any;
  selectedEncounter?: any;
  masterOptions?: any;
  searchedItemList?: any[];
  errorMessage?: any;
  successMessage?: any;
  patientADR?: any;
  patientId?: number | null;
  AllergySequence?: {
    selectedAdversaryName?: string | null;
    selectedAdversaryId?: number | null;
    sequenceIndex?: string | null;
    actionButtonRadio?: string;
    adrList?: any[];
    adrTotalPage?: number;
    adrActivePage?: number;
    loadingADRTable?: boolean;
    adrSelected?: any;
    activeADRMenu?:
      | "product"
      | "ingredient"
      | "mims"
      | "atc"
      | "freetext"
      | "food"
      | "other"
      | "group"
      | "other";
    modConfirmInactive?: {
      open?: boolean;
      confirmNote?: string;
    };
    ingredientList: any;
    drugGroupList: any;
    ingredientData?: any;
    drugGroupData?: any;
    actionNote?: string | null;
    actionLog?: any | null;
    showInactive?: boolean;
  } | null;
};

export const StateInitial: State = {
  AllergySequence: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} }
  | { message: "ItemSearch"; params: {} };

export type Data = {
  division?: number;
  divisionDetail?: any;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

let initial: any = {
  filterRadio: "all",
  adrTotalPage: 0,
  adrActivePage: 0,
  loadingADRTable: true,
  adrSelected: null,
  selectedAdversaryName: null,
  selectedAdversaryId: null,
  // newADR: {
  //   activeADRMenu: "product",
  //   type: "DRUG",
  //   adversary_type: "PRODUCT",
  //   other_adversary: "",
  //   selected_adversary: "",
  //   symptoms: [], // อาการ
  //   severity: "", // ความรุนแรง
  //   probability: "", // ความเป็นไปได้
  //   note: "", // ข้อความแจ้งเตือน
  //   verifyChecked: false,
  // },
};

export const Start: Handler = async (controller, params) => {
  let state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["patientCondition", {}],
        ["patientDischarge", {}],
        ["cleaningChoice", {}],
        ["ADRSymptoms", {}],
        ["ADRSeverity", {}],
        ["ADRProbability", {}],
      ],
    },
  });

  if (!state.AllergySequence || (!state.selectedEmr && !params.noEmr)) return;

  const [ingredientList, drugGroupList, adverseReactionList] = await Promise.all([
    IngredientList.list({
      params: { show_on_adr: true },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    }),
    DrugGroupList.list({
      params: { show_on_adr: true },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    }),
    AdverseReactionList.list({
      params: {
        // exclude_unused: true,
        // severe_first: true,
        limit: ALLERY_LIST_LIMIT,
        // patient: state.selectedEncounter?.patient_id || state.patientId || state.selectedPatient?.id,
        patient: params.form === "ViewRegistration" ? state.selectedPatient?.id : state.selectedEncounter?.patient_id,

        exclude_cancel: true,
        // show_inactive: true,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    }),
  ]);

  //  !Today
  // Refer to P'Mahn gave API query params
  // console.log("!Today  Fetch 3", r3)

  state = controller.getState();

  console.log(" !!! adverseReactionList  ", adverseReactionList)
  
  if (adverseReactionList[1]) {
    controller.setState({
      AllergySequence: {
        ...initial,
        sequenceIndex: "Edit",
        loadingADRTable: false,
        adrList: [],
        ingredientData: [],
        drugGroupData: [],
      },
    });
  } else {
    controller.setState({
      AllergySequence: {
        ...state.AllergySequence,
        ...initial,
        sequenceIndex: "Edit",
        loadingADRTable: false,
        adrList: adverseReactionList[0]?.items || [],
        adrTotalPage: adverseReactionList[0]?.total ? Math.ceil(adverseReactionList[0]?.total / ALLERY_LIST_LIMIT) : 0, // r2?.total,
        adrActivePage: 1,
        ingredientList: ingredientList[0]?.items,
        drugGroupList: drugGroupList[0]?.items,
        showInactive: false,
        ingredientData: [],
        drugGroupData: [],
      },
    });
  }
};

export const Edit: Handler = async (controller, params) => {
  var state = controller.getState();

  var result: any[] = [];
  console.log(" params", params);

  if (params?.action === "getActionLog") {
    const [r, e, n] = await AdverseReactionActionLogList.list({
      pk: params?.id,
      params: {
        offset: 0,
        limit: 40,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    if (!e) {
      console.log(" getActionLog r ", r);
      controller.setState({
        AllergySequence: {
          ...state.AllergySequence,
          actionLog: r ? r : null,
        },
      });
    } else {
      controller.setState({
        AllergySequence: {
          ...state.AllergySequence,
          actionLog: null,
        },
      });
    }
  } else if (params?.action === "inactive") {
    const [r, e, n] = await AdverseReactionList.list({
      params: {
        // exclude_unused: true,
        // severe_first: true,
        limit: ALLERY_LIST_LIMIT,
        patient: state.selectedPatient?.id || state.selectedEncounter?.patient_id,
        exclude_cancel: true,
        show_inactive: params?.checked,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    state = controller.getState();
    if (!e) {
      controller.setState({
        AllergySequence: {
          ...state.AllergySequence,
          loadingADRTable: false,
          adrList: r?.items ? r?.items : [],
          // adrTotalPage: r?.total ? Math.ceil(r?.total / ALLERY_LIST_LIMIT) : 0, // r2?.total,
          // adrActivePage: params?.toActivePage,
        },
      });
    }
  } else if (params?.action === "clearNote") {
    controller.setState({
      AllergySequence: {
        ...state.AllergySequence,
        actionNote: "",
      },
      successMessage: {
        ...state.successMessage,
        [params?.card]: null,
      },
      errorMessage: {
        ...state.errorMessage,
        [params?.card]: null,
      },
    });
  } else if (params?.action === "actionButton") {
    if (params?.toggleLock) {
      result = await AdverseReactionDetail.update({
        pk: params?.item?.id,
        data: {
          action: params?.item?.status === "LOCKED" ? "PAUSE" : "LOCK",
          note: state.AllergySequence?.actionNote,
        },
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });
    } else if (params?.cancel) {
      result = await AdverseReactionDetail.update({
        pk: params?.item?.id,
        data: {
          action: "CANCEL",
          note: state.AllergySequence?.actionNote,
        },
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });
    }

    state = controller.getState();
    if (result?.[1]) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params?.card]: result?.[1],
        },
        AllergySequence: {
          ...state.AllergySequence,
          actionNote: "",
        },
      });
    } else if (!result?.[1]) {
      console.log(" Lock Success");
      controller.setState(
        {
          successMessage: {
            ...state.successMessage,
            [params?.card]: "Success",
          },
          AllergySequence: {
            ...state.AllergySequence,
            sequenceIndex: "Fetch",
            actionNote: "",
          },
        },
        () => {
          controller.handleEvent({
            message: "RunSequence",
            params: { sequence: "Allergy" },
          });
        }
      );
    }
  } else if (params?.action === "filter") {
    switch (params?.filter) {
      case "all":
        break;
      case "waiting":
        break;
      case "confirmed":
        break;
      default:
        break;
    }

    controller?.setState({
      AllergySequence: {
        ...state.AllergySequence,
        filterRadio: params?.value,
      },
    });
  }
  if (params?.action === "clear") {
    console.log(" clear ");
    controller?.setState(
      {
        AllergySequence: {
          ...state.AllergySequence,
          sequenceIndex: "Fetch",
          adrSelected: null,
        },
      },
      () => {
        controller.handleEvent({
          message: "RunSequence",
          params: { sequence: "Allergy" },
        });
      }
    );
  } else if (params?.action === "save") {
    let prepareData: any = {};
    if (state.AllergySequence?.adrSelected?.is_inactive) {
      if (!state.AllergySequence?.adrSelected?.inactive_start_date) {
        return controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: "กรุณาระบุ Inactive Date",
          },
        });
      }
      if (!state.AllergySequence?.modConfirmInactive?.confirmNote) {
        return controller.setState({
          AllergySequence: {
            ...state.AllergySequence,
            modConfirmInactive: {
              open: true,
              confirmNote: "",
            },
          },
        });
      } else {
        prepareData.note =
          state.AllergySequence?.modConfirmInactive?.confirmNote;
      }
    }
    switch (state.AllergySequence?.activeADRMenu) {
      case "food":
        prepareData.adversary_type = "FOOD";
        prepareData.selected_adversary =
          state.AllergySequence?.selectedAdversaryId;
        prepareData.adversary_name =
          state.AllergySequence?.selectedAdversaryName;
        prepareData.other_adversary = state.AllergySequence?.adrSelected?.name;
        prepareData.type = "FOOD";
        break;

      case "other":
        prepareData.adversary_type = "OTHER";
        prepareData.selected_adversary =
          state.AllergySequence?.selectedAdversaryId;
        prepareData.adversary_name =
          state.AllergySequence?.selectedAdversaryName;
        prepareData.other_adversary = state.AllergySequence?.adrSelected?.name;
        prepareData.type = "OTHER";
        break;

      case "freetext":
        prepareData.adversary_type = "OTHER";
        prepareData.selected_adversary = null;
        prepareData.adversary_name = "";
        prepareData.other_adversary = state.AllergySequence?.adrSelected?.name;
        prepareData.type = "DRUG";
        break;

      case "atc":
        prepareData.adversary_type = "ATC";
        prepareData.selected_adversary =
          state.AllergySequence?.selectedAdversaryId;
        prepareData.adversary_name =
          state.AllergySequence?.selectedAdversaryName;
        prepareData.other_adversary = "";
        prepareData.type = "DRUG";
        break;

      case "mims":
        prepareData.adversary_type = "MIMS";
        prepareData.selected_adversary =
          state.AllergySequence?.selectedAdversaryId;
        prepareData.adversary_name =
          state.AllergySequence?.selectedAdversaryName;
        prepareData.other_adversary = "";
        prepareData.type = "DRUG";
        break;

      case "ingredient":
        prepareData.adversary_type = "INGREDIENT";
        prepareData.selected_adversary =
          state.AllergySequence?.selectedAdversaryId;
        prepareData.adversary_name =
          state.AllergySequence?.selectedAdversaryName;
        prepareData.other_adversary = "";
        prepareData.type = "DRUG";
        break;

      case "product":
        prepareData.adversary_type = "PRODUCT";
        prepareData.selected_adversary =
          state.AllergySequence?.selectedAdversaryId;
        prepareData.adversary_name =
          state.AllergySequence?.selectedAdversaryName;
        prepareData.other_adversary = "";
        prepareData.type = "DRUG";
        break;
        
      default:
        break;
    }

    let isSuccess: boolean = false;
    if (state.AllergySequence?.adrSelected) {
      // New or Edit
      let symptoms = (state.AllergySequence?.adrSelected?.symptoms || [])?.map(
        (item: any) => {
          return {
            name: state.masterOptions?.ADRSymptoms?.find(
              (opt: any) => opt.value === item
            )?.text,
          };
        }
      );

      let action = "NOTE";
      let isAcknowledged = false;
      let { targetAction, status } = state.AllergySequence?.adrSelected;

      if (targetAction === "LOCKED" && status !== "LOCKED") {
        action = "LOCK";
      } else if (status === "LOCKED" && targetAction === "VERIFIED") {
        action = "UNLOCK";
        isAcknowledged = true;
      } else if (status === "LOCKED" && targetAction === "NOTED") {
        action = "UNLOCK";
        isAcknowledged = false;
      } else if (targetAction === "VERIFIED") {
        isAcknowledged = true;
      } else if (targetAction === "NOTED") {
        isAcknowledged = false;
      }

      let score = state.AllergySequence?.adrSelected?.naranjo_data.score;
      let probability = null;
      if (score) {
        if (score > 8) {
          probability = 4;
        } else if (score <= 8 && score >= 5) {
          probability = 3;
        } else if (score <= 4 && score >= 1) {
          probability = 2;
        } else if (score < 1) {
          probability = 1;
        }
      }

      let data = {
        ...prepareData,
        action,
        adversary_symptom_type:
          state.AllergySequence?.adrSelected?.adversary_symptom_type ||
          "ALLERGY",
        probability:
          probability === null
            ? state.AllergySequence?.adrSelected?.probability
            : probability,
        severity: state.AllergySequence?.adrSelected?.severity,
        symptoms: symptoms,
        note:
          prepareData?.note || state.AllergySequence?.adrSelected?.note || "",
        is_acknowledged: isAcknowledged,
        is_inactive: state.AllergySequence?.adrSelected?.is_inactive,
        inactive_start_date:
          beToAd(
            state.AllergySequence.adrSelected?.inactive_start_date
          )?.format("YYYY-MM-DD") || null,
        naranjo: JSON.stringify(
          state.AllergySequence?.adrSelected?.naranjo_data
        ),
        // moment for adrSelected.type other or food
        issue_date: formatDateToYYYYMMDD(
          state.AllergySequence?.adrSelected?.issue_date ||
            moment().format("DD/MM/YYYY")
        ),
      };

      if (action === "LOCK") {
        const [noteRes, noteErr, noteNet] = await AdverseReactionDetail.update({
          pk: state.AllergySequence?.adrSelected?.id,
          data: { ...data, action: "NOTE" },
          apiToken: controller.apiToken,
          extra: { division: controller.data.division },
        });

        const [lockRes, lockErr, lockNet] = await AdverseReactionDetail.update({
          pk: state.AllergySequence?.adrSelected?.id,
          data: data,
          apiToken: controller.apiToken,
          extra: { division: controller.data.division },
        });

        if (!lockErr) {
          isSuccess = true;
        }
      } else {
        const [r, e, n] = await AdverseReactionDetail.update({
          pk: state.AllergySequence?.adrSelected?.id,
          data: data,
          apiToken: controller.apiToken,
          extra: { division: controller.data.division },
        });

        if (!e) {
          isSuccess = true;
        }
      }
      // const [r, e, n] = await AdverseReactionDetail.update({
      //   pk: state.AllergySequence?.adrSelected?.id,
      //   data: data,
      //   apiToken: controller.apiToken,
      //   extra: { division: controller.data.division },
      // });

      // if (!e) {
      //   isSuccess = true;
      // }
    }

    state = controller.getState();

    if (isSuccess) {
      // Success
      state = controller.getState();
      controller.setState(
        {
          AllergySequence: {
            ...state.AllergySequence,
            adrSelected: null,
            sequenceIndex: "Fetch",
            modConfirmInactive: {
              open: false,
              confirmNote: "",
            },
          },
        },
        () => {
          controller.handleEvent({
            message: "RunSequence",
            params: { sequence: "Allergy" },
          });
        }
      );
    }
  } else if (params?.action === "saveIngredientData") {
    let newData: any = [];
    let ingredient = state.AllergySequence.ingredientData.filter(
      (item: any) => item.checked === true && item?.onDone !== true
    );
    let drugGroup = state.AllergySequence?.drugGroupData.filter(
      (item: any) => item.checked === true && item?.onDone !== true
    );

    ingredient.forEach((item: any) => {
      let setData = {
        action: "NOTE",
        adversary_name: item.name,
        adversary_type: "INGREDIENT",
        inactive_start_date: null,
        is_acknowledged: false,
        is_inactive: false,
        naranjo: "{}",
        note: item.note || "note",
        other_adversary: "",
        probability: null,
        selected_adversary: item.id,
        severity: null,
        symptoms: [],
        type: "DRUG",
      };
      newData.push(setData);
    });

    drugGroup.forEach((item: any) => {
      let setData = {
        action: "NOTE",
        adversary_name: item.name,
        adversary_type: "MIMS",
        inactive_start_date: null,
        is_acknowledged: false,
        is_inactive: false,
        naranjo: "{}",
        note: item.note || "note",
        other_adversary: "",
        probability: null,
        selected_adversary: item.id,
        severity: null,
        symptoms: [],
        type: "DRUG",
      };
      newData.push(setData);
    });

    const [r, e, n] = await MultiAdverseReactionList.create({
      data: {
        action: "NOTE",
        patient: state.selectedEncounter?.patient_id || state.patientId,
        items: newData,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    if (r) {
      // Success
      state = controller.getState();
      controller.setState(
        {
          AllergySequence: {
            ...state.AllergySequence,
            adrSelected: null,
            sequenceIndex: "Fetch",
            modConfirmInactive: {
              open: false,
              confirmNote: "",
            },
            drugGroupData: [],
            ingredientData: [],
          },
        },
        () => {
          controller.handleEvent({
            message: "RunSequence",
            params: { sequence: "Allergy" },
          });
        }
      );
    }
  } else if (params?.action === "newNote") {
    state = controller.getState();
    console.log("NewNote", state);

    if (params?.adr) {
      // Need tick noReactionId to NOTE and Seleect

      // result = await AdverseReactionDetail.update({
      //   pk: params?.id,
      //   data: {
      //     action: "NOT_KNOW",
      //     note: "note",
      //     adversary_type: "OTHER",
      //     selected_adversary: null,
      //     adversary_name: "",
      //     other_adversary: "note",
      //     type: "OTHER",
      //     probability: 0,
      //     severity:0,
      //     symptoms: [],
      //   },
      //   apiToken: controller.apiToken,
      //   extra: { division: controller.data.division },
      // });

      controller.setState({
        AllergySequence: {
          ...state.AllergySequence,
          activeADRMenu: "group",
          adrSelected: params?.adr,
          selectedAdversaryName: null,
        },
      });
    } else {
      // New ADR
      controller.setState({
        AllergySequence: {
          ...state.AllergySequence,
          adrSelected: null,
          activeADRMenu: "group",
          selectedAdversaryName: null,
        },
      });

      result = await AdverseReactionList.create({
        data: {
          action: "NOTE",
          note: state.AllergySequence?.adrSelected?.newNote
            ? state.AllergySequence?.adrSelected?.newNote
            : "note",
          patient: state.selectedEncounter?.patient_id || state.patientId,
        },
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });
      console.log("Call API to Save No ADR");
      state = controller.getState();
    }

    if (!result[1] && result[0]?.id) {
      if (params?.card === "CardPatientPanel") {
        const [r, e, n] = await AdverseReactionList.list({
          params: {
            limit: ALLERY_LIST_LIMIT,
            patient: state.selectedEncounter?.patient_id || state.patientId,
            exclude_cancel: true,
            // exclude_unused: true,
            // severe_first: true,
            show_inactive: state.AllergySequence?.showInactive,
          },
          apiToken: controller.apiToken,
          extra: { division: controller.data.division },
        });
        console.log("Respone", r);

        controller.setState({
          AllergySequence: {
            ...state.AllergySequence,
            adrSelected: {},
          },
          patientADR: r,
        });
      } else {
        controller.setState({
          AllergySequence: {
            ...state.AllergySequence,
            adrSelected: {
              ...result[0],
              targetAction: "NOTED",
            },
          },
        });
      }
    }
  } else if (params?.action === "selectedADR") {
    const [r, e, n] = await AdverseReactionDetail.retrieve({
      pk: params?.original?.id,
      params: { show_note: true },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    let targetAction = "NOTED";
    if (
      params?.original?.status === "LOCKED" &&
      params?.original?.is_acknowledged
    ) {
      targetAction = "LOCKED";
    } else if (params?.original?.is_acknowledged) {
      targetAction = "VERIFIED";
    }

    let ADType: any = "product";
    let allergySequence = {
      ...state.AllergySequence,
      activeADRMenu: ADType,
      adrSelected: {
        ...params?.original,
        targetAction,
        naranjoChecked: r?.naranjo_data.score !== undefined ? true : false,
      },
    };

    if (!e && r?.adversary_type) {
      switch (r?.adversary_type) {
        case "OTHER":
          if (r?.type === "FOOD") {
            ADType = "food";
            break;
          } else if (r?.type === "OTHER") {
            ADType = "other";
            break;
          } else if (r?.type === "DRUG") {
            ADType = "freetext";
            break;
          }
          break;
        case "ATC":
          ADType = "atc";
          break;
        case "MIMS":
          ADType = "mims";
          break;
        case "INGREDIENT":
          ADType = "ingredient";
          break;
        case "PRODUCT":
          ADType = "product";
          break;
        default:
          ADType = "product";
          break;
      }

      allergySequence.selectedAdversaryId = r?.selected_adversary;
      allergySequence.selectedAdversaryName = r?.adversary_name;
      allergySequence.activeADRMenu = ADType;
      allergySequence.adrSelected.issue_date = formatDate(
        moment(r?.issue_date, "DD-MM-YYYY")
      );
      if(allergySequence.adrSelected?.inactive_start_date)
      allergySequence.adrSelected.inactive_start_date = formatDate(
        moment(r?.inactive_start_date, "DD-MM-YYYY")
      );

      if (ADType === "product") {
        const result = await DrugList.list({
          params: { name_code: r?.adversary_name },
          apiToken: controller.apiToken,
        });

        allergySequence.selectedAdversaryName = result[0].items[0]?.name_code;
      } else if (ADType === "mims") {
        const result = await DrugGroupList.list({
          params: {
            type: "MIMS",
            name_desc: r?.adversary_name,
          },
          apiToken: controller.apiToken,
        });

        allergySequence.selectedAdversaryName = result[0].items[0]?.name_desc;
      } else if (ADType === "atc") {
        const result = await DrugGroupList.list({
          params: {
            type: "ATC",
            name_desc: r?.adversary_name,
          },
          apiToken: controller.apiToken,
        });

        allergySequence.selectedAdversaryName = result[0].items[0]?.name_desc;
      }

      controller.handleEvent({
        message: "ItemSearch",
        params: { type: "ADR" + ADType, searchText: r?.adversary_name },
      });
    }

    controller.setState({
      AllergySequence: {
        ...allergySequence,
      },
    });
  } else if (params?.action === "noADR") {
    const [r, e, n] = await AdverseReactionList.create({
      data: {
        action: "NO_REACTION",
        items: [], // ลอกจาก QML
        note: "",
        type: 1,
        type_name_name: "DRUG",
        patient: state.selectedEncounter?.patient_id || state.patientId,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });
    console.log("Call API to Save No ADR");
    state = controller.getState();
    controller.setState(
      {
        AllergySequence: {
          ...state.AllergySequence,
          sequenceIndex: "Fetch",
        },
      },
      () => {
        controller.handleEvent({
          message: "RunSequence",
          params: { sequence: "Allergy" },
        });
      }
    );
  } else if (params?.action === "noFood") {
    const [r, e, n] = await AdverseReactionList.create({
      data: {
        action: "NO_REACTION",
        items: [], // ลอกจาก QML
        note: "",
        type: 2,
        type_name_name: "FOOD",
        patient: state.selectedEncounter?.patient_id || state.patientId,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });
    console.log("Call API to Save No Food");

    state = controller.getState();
    controller.setState(
      {
        AllergySequence: {
          ...state.AllergySequence,
          sequenceIndex: "Fetch",
        },
      },
      () => {
        controller.handleEvent({
          message: "RunSequence",
          params: { sequence: "Allergy" },
        });
      }
    );
  } else if (params?.action === "noOther") {
    const [r, e, n] = await AdverseReactionList.create({
      data: {
        action: "NO_REACTION",
        items: [], // ลอกจาก QML
        note: "",
        type: 4,
        type_name_name: "OTHER",
        patient: state.selectedEncounter?.patient_id || state.patientId,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });
    console.log("Call API to Save No Other");

    state = controller.getState();
    controller.setState(
      {
        AllergySequence: {
          ...state.AllergySequence,
          sequenceIndex: "Fetch",
        },
      },
      () => {
        controller.handleEvent({
          message: "RunSequence",
          params: { sequence: "Allergy" },
        });
      }
    );
  } else if (params?.action === "selectedADRType" && params?.activeADRMenu) {
    let ADType;
    let adrSelected = state.AllergySequence?.adrSelected;
    switch (adrSelected?.adversary_type) {
      case "OTHER":
        if (adrSelected?.type === "FOOD") {
          ADType = "food";
          break;
        } else if (adrSelected?.type === "OTHER") {
          ADType = "other";
          break;
        } else if (adrSelected?.type === "DRUG") {
          ADType = "freetext";
          break;
        }
        break;
      case "ATC":
        ADType = "atc";
        break;
      case "MIMS":
        ADType = "mims";
        break;
      case "INGREDIENT":
        ADType = "ingredient";
        break;
      case "PRODUCT":
        ADType = "product";
        break;
      default:
        ADType = "product";
        break;
    }

    if (ADType !== params.activeADRMenu) {
      // clear adrSelected
      controller.setState({
        AllergySequence: {
          ...state.AllergySequence,
          adrSelected: {
            ...state.AllergySequence.adrSelected,
            symptoms: [],
            adr_short_info: "",
            adversary_symptom_type: "",
            issue_date: "",
            severity: "",
            naranjo: {},
            naranjoChecked: false,
            naranjo_data: {},
            note: "",
            probability: "",
          },
          selectedAdversaryName: "",
          activeADRMenu: params.activeADRMenu,
        },
      });
    } else {
      // filter adverse-reaction
      const [r, e, n] = await AdverseReactionDetail.retrieve({
        pk: state.AllergySequence.adrSelected.id,
        params: { show_note: true },
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });

      if (!e) {
        let symptoms = (r.symptoms || [])?.map((item: any, number: any) => {
          return item.id;
        });

        let selectedAdversaryName;
        if (ADType === "product") {
          const result = await DrugList.list({
            params: { name_code: r?.adversary_name },
            apiToken: controller.apiToken,
            extra: { division: controller.data.division },
          });

          selectedAdversaryName = result[0].items[0]?.name_code;
        } else if (ADType === "ingredient") {
          const result = await IngredientList.list({
            params: { name__icontains: r?.adversary_name },
            apiToken: controller.apiToken,
            extra: { division: controller.data.division },
          });

          selectedAdversaryName = result[0].items[0]?.name;
        } else if (ADType === "mims") {
          const result = await DrugGroupList.list({
            params: {
              type: "MIMS",
              name_desc: r?.adversary_name,
            },
            apiToken: controller.apiToken,
            extra: { division: controller.data.division },
          });

          selectedAdversaryName = result[0].items[0]?.name_desc;
        } else if (ADType === "atc") {
          const result = await DrugGroupList.list({
            params: {
              type: "ATC",
              name_desc: r?.adversary_name,
            },
            apiToken: controller.apiToken,
            extra: { division: controller.data.division },
          });

          selectedAdversaryName = result[0].items[0]?.name_desc;
        }

        controller.setState({
          AllergySequence: {
            ...state.AllergySequence,
            adrSelected: { ...r, symptoms: symptoms },
            selectedAdversaryName: selectedAdversaryName,
            activeADRMenu: params.activeADRMenu,
          },
        });
      }
    }
  } else if (
    params?.action === "editADR" &&
    params?.field &&
    params?.value !== undefined
  ) {
    controller.setState({
      AllergySequence: {
        ...state.AllergySequence,
        adrSelected: {
          ...state.AllergySequence?.adrSelected,
          [params?.field]: params?.value,
        },
      },
    });
  } else if (params?.action === "runningAllergy") {
    console.log("runningAllergy");
    console.log("params?.toActivePage", params?.toActivePage);
    controller.setState({
      AllergySequence: {
        ...state.AllergySequence,
        loadingADRTable: false,
      },
    });

    const [r, e, n] = await AdverseReactionList.list({
      params: {
        limit: ALLERY_LIST_LIMIT,
        patient: state.selectedEncounter?.patient_id || state.patientId,
        exclude_cancel: true,
        // offset: (params?.toActivePage - 1) * ALLERY_LIST_LIMIT,
        // exclude_unused: true,
        // severe_first: true,
        show_inactive: state.AllergySequence?.showInactive,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    state = controller.getState();
    if (!e) {
      controller.setState({
        AllergySequence: {
          ...state.AllergySequence,
          loadingADRTable: false,
          adrList: r?.items ? r?.items : [],
          adrTotalPage: r?.total ? Math.ceil(r?.total / ALLERY_LIST_LIMIT) : 0, // r2?.total,
          adrActivePage: params?.toActivePage,
        },
      });
    }
  } else if (params?.action === "selectedPage") {
    console.log("params?.toActivePage", params?.toActivePage);

    controller.setState({
      AllergySequence: {
        ...state.AllergySequence,
        loadingADRTable: true,
        adrSelected: null,
      },
    });

    const [r, e, n] = await AdverseReactionList.list({
      params: {
        limit: ALLERY_LIST_LIMIT,
        offset: (params?.toActivePage - 1) * ALLERY_LIST_LIMIT,
        patient: state.selectedEncounter?.patient_id || state.patientId,
        exclude_cancel: true,
        // exclude_unused: true,
        // severe_first: true,
        show_inactive: state.AllergySequence?.showInactive,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    state = controller.getState();
    if (!e) {
      controller.setState({
        AllergySequence: {
          ...state.AllergySequence,
          loadingADRTable: false,
          adrList: r?.items?.length > 0 ? r?.items : [],
          adrTotalPage: r?.total ? Math.ceil(r?.total / ALLERY_LIST_LIMIT) : 0, // r2?.total,
          adrActivePage: params?.toActivePage,
        },
      });
    }
  }
};

export const Fetch: Handler = async (controller, params) => {
  var state = controller.getState();

  controller?.setState({
    AllergySequence: {
      ...state.AllergySequence,
      loadingADRTable: true,
    },
  });

  const [r, e, n] = await AdverseReactionList.list({
    params: {
      limit: ALLERY_LIST_LIMIT,
      patient: state.selectedEncounter?.patient_id || state.patientId,
      exclude_cancel: true,
      // exclude_unused: true,
      // severe_first: true,
      show_inactive: state.AllergySequence?.showInactive,
    },
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });
  console.log(" Fetch ", r);

  state = controller.getState();

  if (e) {
    console.log("r", r);
    controller.setState({
      AllergySequence: {
        ...state.AllergySequence,
        ...initial,
        sequenceIndex: "Edit",
        loadingADRTable: false,
        adrList: [],
      },
    });
  } else {
    console.log("r", r);

    controller.setState({
      AllergySequence: {
        ...state.AllergySequence,
        ...initial,
        sequenceIndex: "Edit",
        loadingADRTable: false,
        adrList: r?.items ? r?.items : [],
        adrTotalPage: r?.total ? Math.ceil(r?.total / ALLERY_LIST_LIMIT) : 0, // r2?.total,
        adrActivePage: 1,
      },
      patientADR: r,
    });
  }
};

// //  Today
// const [r2, e2, n2] = await AdverseReactionList.list({
//   params: {
//     patient: state.selectedEncounter?.patient_id,
//     today: true,
//     exclude_cancel: true,
//   },
//   apiToken: controller.apiToken,
//   extra: { division: controller.data.division },
// });
// console.log("Today Fetch 2", r2);

// //  !Today
// const [r3, e3, n3] = await AdverseReactionList.list({
//   params: {
//     patient: state.selectedEncounter?.patient_id,
//     exclude_cancel: true,
//   },
//   apiToken: controller.apiToken,
//   extra: { division: controller.data.division },
// });
// console.log("!Today  Fetch 3", r3);

// // เภสัช ดูด้วย
// const [r4, e4, n4] = await AdverseReactionList.list({
//   params: {
//     patient: state.selectedEncounter?.patient_id,
//     exclude_unused: true,
//   },
//   apiToken: controller.apiToken,
//   extra: { division: controller.data.division },
// });
// console.log("เภสัช ดูด้วย  Fetch ", r4);

// state = controller.getState();
// controller.setState({
//   AllergySequence: {
//     ...state.AllergySequence,
//     sequenceIndex: "Edit",
//   },
// });
