import React, { useEffect, useState, useMemo } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";

import moment from "moment";

// UX
import CardAssessmentBundleUX from "./CardAssessmentBundleUX";
import CardPatientAssessmentUX from "./CardPatientAssessmentUX";
import CardFallRiskAssessmentUX from "./CardFallRiskAssessmentUX";
import CardPainAssessmentUX from "./CardPainAssessmentUX";
import CardNutritionAssessmentUX from "./CardNutritionAssessmentUX";
import CardDischargeReassessment from "./CardDischargeReassessment";
import ModSaveAssessment from "./ModSaveAssessment";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

const CardAssessment = (props: any) => {
  const [newFallRiskData, setNewFallRiskData] = useState<any>({});
  const [newPainData, setNewPainData] = useState<any>({});
  const [newNutritionData, setNewNutritionData] = useState<any>({});
  const [assessmentBefore, setAssessmentBefore] = useState(true);
  const [assessmentAfter, setAssessmentAfter] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [rowID, setRowID] = useState("");
  const [arrived, setArrived] = useState<any[]>([
    // { key: "WLK", value: "WLK", text: "เดิน" },
    // { key: "WCH", value: "WCH", text: "รถนั่ง" },
    // { key: "CRT", value: "CRT", text: "รถนอน" },
    // // { key: "ATH", value: "ATH", text: "อื่นๆ พิมพ์..." },
    // {
    //   key: "PAB05",
    //   value: "PAB05",
    //   text: "ร้องขอโดยไม่มีหลักฐานการเปลี่ยนแปลง โดยผู้ป่วย",
    // },
  ]);

  useEffect(() => {
    if (!Number.isInteger(props?.selectedEncounter?.patient_id)) return;
    props.runSequence({ sequence: "Assessment", restart: true });
  }, []);

  const choiceTriageLevel = useMemo(() => {
    return props.choiceTriage?.items.map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.value,
    }));
  }, [props.choiceTriage]);

  // General helpers
  const changeForm = (form: string) => (key: string, value?: any) => {
    if (typeof value !== "undefined") {
      return () =>
        props.setProp(`AssessmentSequence.${form}.data.${key}`, value);
    } else {
      return (e: any, v: any) =>
        props.setProp(`AssessmentSequence.${form}.data.${key}`, v.value);
    }
  };

  const changeDate = (form: string) => (key: string) => (date: any) =>
    props.setProp(`AssessmentSequence.${form}.data.${key}`, date);

  const changePregnancy = (key: string) => (e: any, v: any) =>
    props.setProp(`AssessmentSequence.cardPregnancyAssessment.${key}`, v.value);

  const handleAddition = (e: any, { value }: any) => {
    // setArrived((prevOptions: any) => [{ text: value, value }, ...prevOptions]);
  };

  const removeRow = (form: string, rowIndex: number) => {
    const newData = (props.AssessmentSequence?.[form]?.data || []).filter(
      (item: any, index: number) => index !== rowIndex
    );
    props.setProp(`AssessmentSequence.${form}.data`, newData);
  };

  const reassess = (form: string, data: any) => () => {
    const newData = [
      ...(props.AssessmentSequence?.[form]?.data || []),
      {
        ...data,
        edit_user: props.DJANGO?.user?.id,
        user_fullname: props.DJANGO?.user?.full_name,
        date: moment().format("HH:mm DD/MM/YYYY"),
      },
    ];
    props.setProp(`AssessmentSequence.${form}.data`, newData);
    setNewPainData({});
  };

  const reassessUpdate = (form: string, data: any, index: any) => () => {
    const painDate = props.AssessmentSequence?.[form]?.data;
    const newData: any[] = [];
    painDate.map((item: any, num: any) => {
      if (num === index) {
        newData.push({
          ...data,
          edit_user: props.DJANGO.user.id,
          user_fullname: props.DJANGO.user.full_name,
          date: moment().format("HH:mm DD/MM/YYYY"),
        });
      } else {
        newData.push(item);
      }
    });
    props.setProp(`AssessmentSequence.${form}.data`, newData);
    setNewPainData({});
    setOpenEdit(false);
    setRowID("");
  };

  // Patient assessment
  const patientAssessment =
    props.AssessmentSequence?.patientAssessment?.data || {};
  const patientAssessmentId = props.AssessmentSequence?.patientAssessmentId;

  useEffect(() => {
    checkArrived();
  }, [patientAssessment.arrive_status]);

  const checkArrived = () => {
    // let testing = arrived.find(
    //   (item: any) => item.value === patientAssessment.arrive_status
    // );
    // if (
    //   testing === undefined &&
    //   patientAssessment.arrive_status !== undefined
    // ) {
    //   let newArrived = [
    //     {
    //       key: patientAssessment.arrive_status,
    //       value: patientAssessment.arrive_status,
    //       text: patientAssessment.arrive_status,
    //     },
    //     ...arrived,
    //   ];
    //   setArrived(newArrived);
    // }
    const options: any[] = (props.masterData?.arriveStatus || []).map(
      (item: any) => ({
        key: item.code,
        value: item.code,
        text: item.name,
      })
    );

    setArrived(options);
  };

  // Fall risk assessment helpers
  const changeFallRisk = (key: string) => (e: any, v: any) => {
    let newData: { [key: string]: string } = Object.assign(
      {},
      { ...newFallRiskData }
    );
    newData[key] = v.value;
    setNewFallRiskData(newData);
  };
  const fallRiskOptions = [
    { key: "LOW", value: "LOW", text: "Low Risk" },
    { key: "HIG", value: "HIG", text: "High Risk" },
  ];
  const fallRiskNursingOptions = [
    { key: "NON", value: "ไม่จำเป็น", text: "ไม่จำเป็น" },
    {
      key: "RSK",
      value:
        "ปฏิบัติตามการให้การพยาบาลผู้ป่วยที่ประเมินแล้วมีความเสี่ยงต่อการพลัดตกหกล้ม",
      text: "ปฏิบัติตามการให้การพยาบาลผู้ป่วยที่ประเมินแล้วมีความเสี่ยงต่อการพลัดตกหกล้ม",
    },
    {
      key: "TRS",
      value: "จัดให้ผู้ป่วยนั่งรถนั่งหรือนอนเตียง Transfer",
      text: "จัดให้ผู้ป่วยนั่งรถนั่งหรือนอนเตียง Transfer",
    },
    {
      key: "ORA",
      value: "ติดสัญลักษณ์แสดงผู้ป่วยที่เสี่ยงต่อการพลัดตกหกล้ม",
      text: "ติดสัญลักษณ์แสดงผู้ป่วยที่เสี่ยงต่อการพลัดตกหกล้ม",
    },
    {
      key: "SPL",
      value: "แจ้งผู้ป่วยและญาติระวังพลัดตกหกล้ม",
      text: "แจ้งผู้ป่วยและญาติระวังพลัดตกหกล้ม",
    },
    {
      key: "LIM",
      value: "จัดให้ผู้ป่วยอยู่ในบริเวณที่กำหนด",
      text: "จัดให้ผู้ป่วยอยู่ในบริเวณที่กำหนด",
    },
    {
      key: "STD",
      value: "Standard Fall Precaution",
      text: "Standard Fall Precaution",
    },
    { key: "OTH", value: "อื่นๆ", text: "อื่นๆ" },
  ];
  const fallRiskAssessment = (
    props.AssessmentSequence?.fallRiskAssessment?.data || []
  ).map((item: any, index: number) => ({
    ...item,
    fall_risk_description: fallRiskOptions.find(
      (row: any) => row.value === item?.fall_risk
    )?.text,
    delete: (
      <div style={{ width: "100%", textAlign: "right" }}>
        <Button
          color="red"
          onClick={(e: any) => removeRow("fallRiskAssessment", index)}
        >
          Delete
        </Button>
      </div>
    ),
  }));

  // Pain assessment helpers
  const changePain = (key: string) => (e: any, v: any) => {
    let newData: { [key: string]: string } = Object.assign(
      {},
      { ...newPainData }
    );
    newData[key] = v.value;
    setNewPainData(newData);
  };
  const painScoreOptions = [
    { key: "0", value: "0", text: "0" },
    { key: "1", value: "1", text: "1" },
    { key: "2", value: "2", text: "2" },
    { key: "3", value: "3", text: "3" },
    { key: "4", value: "4", text: "4" },
    { key: "5", value: "5", text: "5" },
    { key: "6", value: "6", text: "6" },
    { key: "7", value: "7", text: "7" },
    { key: "8", value: "8", text: "8" },
    { key: "9", value: "9", text: "9" },
    { key: "10", value: "10", text: "10" },
    { key: "NA", value: "NA", text: "ไม่สามารถประเมินได้" },
  ];
  const painAssessmentOptions = (props.masterOptions?.pain || []).map(
    (item: any) => ({
      ...item,
      key: item?.key.toString(),
      value: item?.value.toString(),
    })
  );
  const painLocationOptions = (props.masterOptions?.location || []).map(
    (item: any) => ({ ...item, key: item?.text, value: item?.text })
  );
  const painCharacteristicOptions = (
    props.masterOptions?.characteristic || []
  ).map((item: any) => ({ ...item, key: item?.text, value: item?.text }));
  const painFrequencyOptions = (props.masterOptions?.painFreq || []).map(
    (item: any) => ({
      ...item,
      key: item?.key.toString(),
      value: item?.value.toString(),
    })
  );
  const painNursingOptions = [
    { key: "NA", value: "NA", text: "NA" },
    { key: "NON", value: "NON", text: "ไม่จำเป็น" },
    { key: "REL", value: "REL", text: "จัดท่าเพื่อลดความเจ็บปวด" },
    { key: "HCP", value: "HCP", text: "วาง Hot/Cold pack" },
    { key: "REP", value: "REP", text: "รายงานแพทย์ pain score > 3" },
    { key: "YRX", value: "YRX", text: "มี Rx" },
    { key: "NRX", value: "NRX", text: "ไม่มี Rx" },
    { key: "Other", value: "Other", text: "อื่น ๆ" },
  ];

  const nursing_filter = (item: any) => {
    let nursing = item;
    let painOption = painNursingOptions;
    let newNursing = "";
    nursing?.map((acc: any, index: number) => {
      painOption?.map((items: any) => {
        if (items?.value === acc) {
          if (index === 0) {
            newNursing = `${items?.text}`;
          } else {
            newNursing = `${newNursing}, ${items?.text}`;
          }
        }
      });
    });
    return newNursing;
  };

  const painAssessment = (
    props.AssessmentSequence?.painAssessment?.data || []
  ).map((item: any, index: number) => ({
    ...item,
    pain_score_desc: painScoreOptions.find(
      (row: any) => row.value === item?.pain_score
    )?.text,
    pain_assessment_desc: painAssessmentOptions.find(
      (row: any) => row.value.toString() === item?.pain_assessment_tool
    )?.text,
    frequency_desc: painFrequencyOptions.find(
      (row: any) => row.value.toString() === item?.frequency
    )?.text,
    // nursing_desc: painNursingOptions.find(
    //   (row: any) => row.value.toString() === item?.nursing
    // )?.text,
    nursing_desc: nursing_filter(item?.nursing),
    delete: (
      <div style={{ width: "100%", textAlign: "right" }}>
        <Button
          color="red"
          onClick={(e: any) => removeRow("painAssessment", index)}
        >
          Delete
        </Button>
      </div>
    ),
  }));

  // Nutrition assessment helpers
  const changeNutrition = (key: string, value?: any) => (e: any, v: any) => {
    let newData: { [key: string]: string } = Object.assign(
      {},
      { ...newNutritionData }
    );
    newData[key] = typeof value !== "undefined" ? value : v.value;
    setNewNutritionData(newData);
  };

  const resultOptions = [
    { key: "Normal", value: "Normal", text: "ปกติ" },
    { key: "Abnormal1", value: "Abnormal1", text: "ผิดปกติ รายงานแพทย์" },
    { key: "OTH", value: "OTH", text: "อื่นๆ ระบุ" },
    {
      key: "Abnormal",
      value: "Abnormal",
      text: "ผิดปกติ ส่งปรึกษาทีมโภชนบำบัด",
    },
  ];
  const resultPregOptions = [
    { key: "NA", value: "NA", text: "NA" },
    { key: "Normal", value: "Normal", text: "ปกติ" },
    { key: "Abnormal1", value: "Abnormal1", text: "ผิดปกติรายงานแพทย์" },
  ];
  const nutritionAssessment = (
    props.AssessmentSequence?.nutritionAssessment?.data || []
  ).map((item: any, index: number) => ({
    ...item,
    lose_weight_desc: item.lose_weight ? "YES" : "NO",
    malnutrition_desc: item.malnutrition ? "YES" : "NO",
    bmi_outrange_desc: item.bmi_outrange ? "YES" : "NO",
    critical_desc: item.critical ? "YES" : "NO",
    result_desc: resultOptions.find(
      (row: any) => row.value.toString() === item?.result
    )?.text,
    preg1_desc: item.preg1 ? "YES" : "NO",
    preg2_desc: item.preg2 ? "YES" : "NO",
    preg3_desc: item.preg3 ? "YES" : "NO",
    result_preg_desc: resultPregOptions.find(
      (row: any) => row.value.toString() === item?.result_preg
    )?.text,
    delete: (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          color="red"
          onClick={(e: any) => removeRow("nutritionAssessment", index)}
        >
          Delete
        </Button>
      </div>
    ),
  }));

  const handleSave = (params: any) => {
    props.runSequence(params);
    setOpenSave(false);
  };

  return (
    <>
      <ModSaveAssessment
        // data
        open={openSave}
        // config
        isSaveReassessment={false}
        // callback
        onSave={handleSave}
        onClose={() => setOpenSave(false)}
      />

      {!props.showHeader && (
        <div style={{ margin: "15px" }}>
          <Button
            color={assessmentBefore ? "blue" : undefined}
            onClick={() => {
              setAssessmentBefore(true);
              setAssessmentAfter(false);
            }}
          >
            แบบประเมินแรกรับ
          </Button>
          <Button
            color={assessmentAfter ? "blue" : undefined}
            onClick={() => {
              setAssessmentBefore(false);
              setAssessmentAfter(true);
            }}
            style={{ marginLeft: "10px" }}
          >
            แบบประเมินหลังออกจากห้องตรวจ
          </Button>
        </div>
      )}

      {assessmentBefore && (
        <div>
          <CardAssessmentBundleUX
            disableSendToQueue={patientAssessmentId === null}
            buttonSaveCompleted={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={() => setOpenSave(true)}
                // data
                paramKey={"CardAssessment_SAVE"}
                buttonLoadCheck={props.buttonLoadCheck?.["CardAssessment_SAVE"]}
                // config
                style={{ width: "100%" }}
                color="green"
                name="SAVE"
                title="Save Assessment Form"
              />
            }
          />
          <CardPatientAssessmentUX
            // Fields
            date={moment().format("HH:mm DD/MM/YYYY")}
            isShowPregnancy={
              props.selectedPatient?.gender === "F" &&
              props.selectedPatient?.age >= 15 &&
              props.selectedPatient?.age <= 50
            }
            isMenstruation={
              props.selectedPatient?.gender === "F" &&
              props.selectedPatient?.age >= 12 &&
              props.selectedPatient?.age <= 50
            }
            pregnancyAssessment={
              props.AssessmentSequence?.cardPregnancyAssessment
            }
            pregnancyPeriodOptions={[
              { key: 1, value: 0, text: "ไม่ระบุ" },
              { key: 2, value: 1, text: "ไตรมาสที่ 1" },
              { key: 3, value: 2, text: "ไตรมาสที่ 2" },
              { key: 4, value: 3, text: "ไตรมาสที่ 3" },
            ]}
            chief_complaint={patientAssessment?.chief_complaint || ""}
            functional_assm_other={
              patientAssessment?.functional_assm_other || ""
            }
            suspected_specific={patientAssessment?.suspected_specific || ""}
            arrive_status={patientAssessment?.arrive_status || ""}
            arrivedByOptions={arrived}
            triage_level={patientAssessment?.triage_level || ""}
            triageLevelOptions={choiceTriageLevel}
            // triageLevelOptions={[
            //   { key: "1", value: "1", text: "1 Life Threatening" },
            //   { key: "2", value: "2", text: "2 Emergency" },
            //   { key: "3", value: "3", text: "3 Urgency" },
            //   { key: "4", value: "4", text: "4 Semi-Urgency" },
            //   { key: "5", value: "5", text: "5 ทั่วไป" },
            // ]}
            translator={patientAssessment?.translator || false}
            translator_specific={patientAssessment?.translator_specific || ""}
            user={props.DJANGO?.user?.full_name}
            functional={patientAssessment?.functional || ""}
            functionalOptions={props.masterOptions?.functional || []}
            psychological={patientAssessment?.psychological || false}
            psychological_specific={
              patientAssessment?.psychological_specific || ""
            }
            phyco_nurse_note={patientAssessment?.phyco_nurse_note || ""}
            phychoNurseNoteOptions={[
              { key: "NON", value: "NON", text: "No Intervention Require" },
              { key: "REP", value: "REP", text: "Action Plan Require" },
              { key: "ND", value: "ND", text: "Notify Doctor" },
            ]}
            phyco_nurse_note_specific={
              patientAssessment?.phyco_nurse_note_specific || ""
            }
            spiritual={patientAssessment?.spiritual || false}
            spiritual_detail={patientAssessment?.spiritual_detail || ""}
            spiritualDetailOptions={props.masterOptions?.belief || []}
            suspected={patientAssessment?.suspected || ""}
            suspectedOptions={[
              {
                key: "NON",
                value: "NON",
                text: "ไม่พบร่องรอยผิดปกติจากการประเมินโดยการสังเกต",
              },
              {
                key: "SUS1",
                value: "SUS1",
                text: "พบร่องรอยบาดแผล รอยฟกช้ำ ไม่สัมพันธ์กับการเจ็บป่วย รายงานแพทย์",
              },
              {
                key: "SUS2",
                value: "SUS2",
                text: "พบร่องรอยบาดแผล รอยฟกช้ำ มีอาการหวาดกลัว ซึมเศร้า ถามไม่ตอบหรือเหม่อลอย รายงานแพทย์",
              },
            ]}
            sus_nurse_note={patientAssessment?.sus_nurse_note || ""}
            susNurseNoteOptions={[
              { key: "NON", value: "NON", text: "ไม่จำเป็น" },
              { key: "REP", value: "REP", text: "รายงานแพทย์" },
              { key: "OTN", value: "OTN", text: "อื่นๆ" },
            ]}
            sus_nurse_note_specific={
              patientAssessment?.sus_nurse_note_specific || ""
            }
            convulsion={patientAssessment?.convulsion || ""}
            convulsionOptions={[
              { key: "NA", value: "NA", text: "NA" },
              { key: "YES", value: "YES", text: "Yes" },
              { key: "NO", value: "NO", text: "No" },
            ]}
            mdr_triage={patientAssessment?.mdr_triage || ""}
            mdrOptions={[
              { key: "NA", value: "NA", text: "NA" },
              { key: "YES", value: "YES", text: "Yes" },
              { key: "NO", value: "NO", text: "No" },
            ]}
            mdr={patientAssessment?.mdr || false}
            precaution={patientAssessment?.precaution || ""}
            precautionOptions={[
              { key: "SP", value: "SP", text: "Standard Precaution" },
              { key: "AP", value: "AP", text: "Airborned Precaution" },
              { key: "DP", value: "DP", text: "Droplet Precaution" },
              { key: "CP", value: "CP", text: "Contact Precaution" },
            ]}
            alcohol={patientAssessment?.alcohol || ""}
            alcoholOptions={[
              { key: "NA", value: "NA", text: "NA" },
              { key: "NO", value: "NO", text: "ไม่ดื่ม" },
              { key: "YES", value: "YES", text: "ดื่ม" },
              { key: "Past", value: "Past", text: "มีประวัติการดื่ม" },
            ]}
            alcohol_specific={patientAssessment?.alcohol_specific}
            alcoholSpecificOptions={[
              { key: 1, value: 1, text: "บางครั้ง" },
              { key: 2, value: 2, text: "ประจำ" },
            ]}
            tobacco={patientAssessment?.tobacco || ""}
            tobaccoOptions={[
              { key: "NA", value: "NA", text: "NA" },
              { key: "NO", value: "NO", text: "ไม่สูบ" },
              { key: "YES", value: "YES", text: "สูบ" },
              { key: "Past", value: "Past", text: "มีประวัติการสูบ" },
            ]}
            tobacco_specific={patientAssessment?.tobacco_specific}
            tobaccoSpecificOptions={[
              { key: 1, value: 1, text: "บางครั้ง" },
              { key: 2, value: 2, text: "ประจำ" },
            ]}
            narcotic={patientAssessment?.narcotic || ""}
            narcoticOptions={[
              { key: "NA", value: "NA", text: "NA" },
              { key: "NO", value: "NO", text: "ไม่เคยใช้" },
              { key: "YES", value: "YES", text: "มีประวัติการใช้" },
            ]}
            narcotic_specific={patientAssessment?.narcotic_specific}
            menstruation={patientAssessment?.menstruation || ""}
            menstruationOptions={[
              { key: "NA", value: "NA", text: "NA" },
              { key: "LMP", value: "LMP", text: "LMP" },
              { key: "MN", value: "MN", text: "Menopause" },
            ]}
            lmp={patientAssessment?.lmp}
            // Method
            changePregnancy={changePregnancy}
            changeForm={changeForm("patientAssessment")}
            changeDate={changeDate("patientAssessment")}
            handleAddition={handleAddition}
            onClear={() =>
              props.runSequence({
                sequence: "Assessment",
                action: "CLEAR",
              })
            }
          />
          <hr />
          <CardFallRiskAssessmentUX
            // Fields
            data={fallRiskAssessment}
            fallRiskOptions={fallRiskOptions}
            fallRiskNursingOptions={fallRiskNursingOptions}
            fall_risk={newFallRiskData?.fall_risk || ""}
            nursing={newFallRiskData?.nursing || ""}
            // Methods
            changeFallRisk={changeFallRisk}
            onReassess={reassess("fallRiskAssessment", newFallRiskData)}
          />
          <hr />
          <CardPainAssessmentUX
            // Fields
            data={painAssessment}
            pain_score={newPainData?.pain_score || ""}
            painScoreOptions={painScoreOptions}
            pain_assessment_tool={newPainData?.pain_assessment_tool || ""}
            painAssessmentOptions={painAssessmentOptions}
            location={newPainData?.location || ""}
            painLocationOptions={painLocationOptions}
            characteristic={newPainData?.characteristic || ""}
            painCharacteristicOptions={painCharacteristicOptions}
            frequency={newPainData?.frequency || ""}
            painFrequencyOptions={painFrequencyOptions}
            duration_min={newPainData?.duration_min || ""}
            duration_sec={newPainData?.duration_sec || ""}
            nursing={newPainData?.nursing || ""}
            painNursingOptions={painNursingOptions}
            nursing_remark={newPainData?.nursing_remark || ""}
            // Methods
            changePain={changePain}
            onReassess={reassess("painAssessment", newPainData)}
            openEdit={
              openEdit
                ? { gridRow: "37/40", gridColumn: "36/43", marginRight: "10px" }
                : { display: "none" }
            }
            updatePain={reassessUpdate("painAssessment", newPainData, rowID)}
            openClear={
              openEdit
                ? { gridRow: "37/40", gridColumn: "43/50" }
                : { display: "none" }
            }
            clearPain={() => {
              setNewPainData({});
              setOpenEdit(false);
              setRowID("");
            }}
            rowProps={(
              state: any,
              rowInfo: any,
              column: any,
              instance: any
            ) => ({
              style: {
                backgroundColor:
                  props.NursingDiagnosisSequence?.nursingDiagnosisSelected &&
                  rowInfo?.original?.id &&
                  rowInfo?.original?.id ===
                    props.NursingDiagnosisSequence?.nursingDiagnosisSelected?.id
                    ? "#d6ecf3"
                    : "white",
              },
              onClick: async () => {
                console.log(
                  "rowInfo?.original",
                  rowInfo?.original,
                  rowInfo?.index
                );
                setRowID(rowInfo?.index);
                setOpenEdit(true);
                setNewPainData({
                  pain_score: rowInfo?.original?.pain_score,
                  pain_assessment_tool: rowInfo?.original?.pain_assessment_tool,
                  location: rowInfo?.original?.location,
                  characteristic: rowInfo?.original?.characteristic,
                  frequency: rowInfo?.original?.frequency,
                  duration_min: rowInfo?.original?.duration_min,
                  duration_sec: rowInfo?.original?.duration_sec,
                  nursing: rowInfo?.original?.nursing,
                  nursing_remark: rowInfo?.original?.nursing_remark,
                });
              },
            })}
          />
          <hr />
          <CardNutritionAssessmentUX
            // Fields
            data={nutritionAssessment}
            lose_weight={newNutritionData?.lose_weight || false}
            malnutrition={newNutritionData?.malnutrition || false}
            bmi_outrange={newNutritionData?.bmi_outrange || false}
            critical={newNutritionData?.critical || false}
            result={newNutritionData?.result || ""}
            resultOptions={resultOptions}
            preg1={newNutritionData?.preg1 || false}
            preg2={newNutritionData?.preg2 || false}
            preg3={newNutritionData?.preg3 || false}
            result_preg={newNutritionData?.result_preg || ""}
            resultPregOptions={resultPregOptions}
            isShowPregnancy={
              props.AssessmentSequence?.cardPregnancyAssessment
                ?.pregnancy_status === 3
            }
            // Methods
            changeNutrition={changeNutrition}
            onReassess={reassess("nutritionAssessment", newNutritionData)}
          />
        </div>
      )}

      {assessmentAfter && (
        <div>
          <CardDischargeReassessment
            onEvent={props.onEvent}
            setProp={props.setProp}
            runSequence={props.runSequence}
            DJANGO={props.DJANGO}
            selectedEncounter={props.selectedEncounter}
            masterOptions={props.masterOptions}
            AssessmentSequence={props.AssessmentSequence}
            choiceTriage={props.ChoiceTriage}
            patientInfo={props.patientInfo}
          />
        </div>
      )}
    </>
  );
};

export default CardAssessment;
