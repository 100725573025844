import React, { useEffect, useMemo, useState } from "react";
import CardSupplyDeliveryManagerUx from "./CardSupplyDeliveryManagerUx";
import {
  Input,
  Modal,
  Form,
  TextArea,
  Divider,
  Icon,
  Dimmer,
  Loader,
  Segment,
  Header,
  Button
} from "semantic-ui-react";
import { Table } from "react-lib/frameworks/Table";
import { ModInfo, ModConfirm, ErrorMessage } from "react-lib/apps/common";
import { formatDatetime } from "react-lib/utils/dateUtils";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { get_status_color, get_type_color } from "./UtilMSD";

const CARD_SUPPLY_DELIVERY_MANAGER = "CardSupplyDeliveryManager"
const MOD_CANCEL = "ModCancel"
const CardSupplyDeliveryManager = (props: any) => {
  const [completed, setCompleted] = useState(false);
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState<any>("");
  const [openModHistory, setOpenModHistory] = useState<boolean>(false);
  const [saveTitle, setSaveTitle] =useState<string>("จัดเวชภัณฑ์")
  const [openModCancel, setOpenModCancel] = useState(false);
  const [nameCancel, setNameCancel] = useState<any>("");
  const [action, setAction] = useState<any>("");


  useEffect(() => {
    props.runSequence({ sequence: "SupplyDelivery" });
  }, []);
  console.log("SupplyDeliverySequence props", props);

  useEffect(() => {
    if (!!props.successMessage?.[MOD_CANCEL]) {
      setOpenModCancel(false);
      setAction(null)
      setNameCancel(null)
      setNote(null)
    }
  }, [props.successMessage]);

  useEffect(() => {
    if (props.checkOutPerformDiv) {
      return;
    }

    if (
      props.errorMessage?.[CARD_SUPPLY_DELIVERY_MANAGER] !== null &&
      props.errorMessage?.[CARD_SUPPLY_DELIVERY_MANAGER] !== undefined
    ) {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        props.runSequence({
          sequence: "SupplyDelivery",
          action: "clear",
        });
      }, 2000);
    }
    if (
      props.successMessage?.OrderCentralLab !== null &&
      props.successMessage?.OrderCentralLab !== undefined
    ) {
      setCompleted(true);
      setOpen(false);
      setTimeout(() => {
        setCompleted(false);
      }, 2000);
      props.runSequence({
        sequence: "SupplyDelivery",
        action: "clear",
      });
    }
  }, [
    props.successMessage?.[CARD_SUPPLY_DELIVERY_MANAGER],
    props.errorMessage?.[CARD_SUPPLY_DELIVERY_MANAGER],
  ]);

  const deliveryItems = useMemo(
    () =>
      props.SupplyDeliverySequence?.deliveryManager?.items?.map(
        (item: any, idx: any) => {
          return {
            ...item,
            check: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {item.name}
              </div>
            ),
          };
        }
      ),
    [
      props.SupplyDeliverySequence?.deliveryManager,
      props.SupplyDeliverySequence?.deliveryManager?.items,
    ]
  );

  let statusColor = useMemo(
    () =>
      get_status_color(
        props.SupplyDeliverySequence?.deliveryManager?.status_name
      ),
    [props.SupplyDeliverySequence?.deliveryManager?.status_name]
  );

  const actionLogItems = useMemo(() => {
    return (props.SupplyDeliverySequence?.supplyDeliveryActionLog || []).map((item: any) => ({
      ...item,
      datetime: formatDatetime(item.datetime)
    })) as any[];
  }, [props.SupplyDeliverySequence?.supplyDeliveryActionLog]);

  const deliveryData = useMemo(() => {
    let tmpData: any = { ...props.SupplyDeliverySequence?.deliveryManager };
    if (tmpData.printed) {
      tmpData.printed.datetime = formatDatetime(tmpData.printed?.datetime);
    }
    if (tmpData.checked) {
      tmpData.checked.datetime = formatDatetime(tmpData.checked?.datetime);
    }
    if (tmpData.delivered) {
      tmpData.delivered.datetime = formatDatetime(tmpData.delivered?.datetime);
    }
    if (tmpData.transported) {
      tmpData.transported.datetime = formatDatetime(
        tmpData.transported?.datetime
      );
    }

    if (tmpData.status_name==="PRINTED") {
      setSaveTitle("จัดเวชภัณฑ์")
    } else if  (tmpData.status_name==="CHECKED"){
      setSaveTitle("จ่ายเวชภัณฑ์")
    }  else if  (tmpData.status_name ==="TRANSPORTED"){
      setSaveTitle("รับเวชภัณฑ์")
    }

    return props.SupplyDeliverySequence?.deliveryManager;
  }, [props.SupplyDeliverySequence?.deliveryManager]);




  const coUserVerify = () => {
    props.onEvent({
      message: "checkUserTokenize",
      params: {
        code: props.SupplyDeliverySequence?.coUser,
      },
    });
  };



  const handleSave = () => {
    props.runSequence({
      sequence: "SupplyDelivery",
      action: deliveryData?.status_name==="PRINTED" ? "CHECK" : 
      deliveryData?.status_name==="CHECKED" ? "DELIVER" : deliveryData?.status_name==="TRANSPORTED" ? "RECEIVE" : "" ,
      id: props.SupplyDeliverySequence?.selectedTransferId,
      co_user: props.SupplyDeliverySequence?.coUserToken,
      note: "",
      card: CARD_SUPPLY_DELIVERY_MANAGER,
      buttonLoadKey: `${CARD_SUPPLY_DELIVERY_MANAGER}_SAVE`,
    });
   
  };

  // const handleGetTrProps = (
  //   state: any,
  //   rowInfo: any,
  //   column: any,
  //   instance: any
  // ) => ({
  //   onDoubleClick: (e: any) => {
  //     let item = rowInfo?.original;
  //     props.runSequence({
  //       sequence: "SupplyDelivery",
  //       action: "select",
  //       id: item?.id,
  //     });
  //     props.onSelected();
  //   },
  //   onClick: (e: any) => {
  //     let item = rowInfo?.original;
  //     console.log(item);
  //     props.runSequence({
  //       sequence: "SupplyDelivery",
  //       action: "select",
  //       id: item?.id,
  //     });
  //     props.onSelected();
  //   },
  // });

  const handleClickHistory = () => {
    setOpenModHistory(true);
    props.onEvent({
      message: "getSupplyDeliveryAction",
      params: { id: props.SupplyDeliverySequence?.selectedTransferId },
    });
    console.log(props.SupplyDeliverySequence?.supplyDeliveryActionLog);
  };

  const handleCloseModHistory = () => {
    setOpenModHistory(false);
    props.setProp("SupplyDeliverySequence.supplyDeliveryActionLog", []);
  };

  return (
    <div>
      

      <Modal open={openModHistory} size="large" onClose={handleCloseModHistory}>
        {/* @ts-ignore */}
        <Segment>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "-1.2rem",
            }}
          >
            {/* @ts-ignore */}
            <Header>ประวัติการทำงาน</Header>
            <Icon
              name="close"
              size="large"
              color="red"
              style={{ cursor: "pointer" }}
              onClick={handleCloseModHistory}
            />
          </div>
          <Divider />
          {/* <Dimmer active={props.isLoadingModal} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer> */}
          {/* @ts-ignore */}
          <Table
            data={actionLogItems}
            headers="User, Action, Date-time"
            keys="name, action, datetime"
            showPagination={false}
            style={{
              height: "500px",
            }}
          />
        </Segment>
      </Modal>

      <Modal
        open={openModCancel}
        size="mini"
        onClose={() => {
          setOpenModCancel(false);
          setNote(null);
          setAction(null);
          setNameCancel(null)
          props.setProp(`errorMessage.${MOD_CANCEL}`, null);
        }}
      >
        <Modal.Header
          as="h5"
          style={{
            backgroundColor: "#2185D0",
            color: "white",
            fontSize: "16px",
          }}
        >
         {nameCancel}
        </Modal.Header>
        <Modal.Content>
          <div>เหตุผลการในยกเลิก *</div>
          <div style={{ height: "100px" }}>
            <TextArea
              style={{
                width: "100%",
                height: "100%",
                border: "solid #cccccc 1px",
                marginTop: "5px",
              }}
              value={note}
              onChange={(e, v) => {
                setNote(v.value);
              }}
            />
          </div>

          <div style={{ marginTop: "10px" }}>
            <ErrorMessage error={props.errorMessage?.[MOD_CANCEL]} />
          </div>

          <div
            style={{
              padding: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              className="basic"
              color="green"
              onClick={() => {
                props.runSequence({
                  sequence: "SupplyDelivery",
                  action: action,
                  id: props.SupplyDeliverySequence?.selectedTransferId,
                  co_user: props.SupplyDeliverySequence?.coUserToken,
                  note: note,
                  card: MOD_CANCEL
                });
              }}
              style={{ width: "100px" }}
            >
              ใช่
            </Button>
            <Button
              className="basic"
              color="red"
              onClick={() => {
                setOpenModCancel(false);
                setAction(null)
                setNameCancel(null)
                setNote(null);
                props.setProp(`errorMessage.${MOD_CANCEL}`, null);
              }}
              style={{ width: "100px" }}
            >
              ไม่
            </Button>
          </div>
        </Modal.Content>
      </Modal>

      <CardSupplyDeliveryManagerUx
        ErrorMessage={
          <ErrorMessage error={props?.errorMessage?.[CARD_SUPPLY_DELIVERY_MANAGER]} />
        }
        supplyDeliveryManagerData={deliveryData}
        supplyDeliveryManagerItems={deliveryItems}
        isCardReceive={props.isCardReceive}
        statusColor={statusColor}
        showButton={ (props.SupplyDeliverySequence?.deliveryManager?.status_name ===  "TRANSPORTED" && !props.isCardReceive) || props.SupplyDeliverySequence?.deliveryManager?.status_name ===  "CANCELED" ||
        props.SupplyDeliverySequence?.deliveryManager?.status_name ===  "RECEIVED"}
        // getTrProps={handleGetTrProps}
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={`${CARD_SUPPLY_DELIVERY_MANAGER}_SAVE`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_SUPPLY_DELIVERY_MANAGER}_SAVE`]
            }
            // config
            color="green"
            size="small"
            title={saveTitle}
          />
        }
        actionUnCheck={() =>{
          setOpenModCancel(true)
          setAction("UNCHECK")
          setNameCancel("ยกเลิกการส่ง ?")
        }}
        actionUnDeliver={() =>{
          setOpenModCancel(true)
          setAction("UNDELIVER")
          setNameCancel("ยกเลิกการจ่าย ?")
        }
        }
        actionUnCancel={() =>{
          setOpenModCancel(true)
          setAction("CANCEL")
          setNameCancel("ยกเลิกการจัด ?")
        }}
        actionLogClick={handleClickHistory}
        actionUnReceive={() =>{
          setOpenModCancel(true)
          setAction("UNRECEIVE")
          setNameCancel("ยกเลิกรับเวชภัณฑ์ ?")
        }}
        coUser={props.SupplyDeliverySequence?.coUser}
        coUserVerify={coUserVerify}
        changeCoUser={(e: any) =>
          props.setProp("SupplyDeliverySequence.coUser", e.target.value)
        }
        total={deliveryItems?.reduce((accumulator: any, object: any) => {
          return accumulator + object.value_total;
        }, 0)}
        buttonReprint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() =>{
              props.runSequence({
                sequence: "SupplyDelivery",
                action: "REPRINT",
                id: props.SupplyDeliverySequence?.selectedTransferId,
                co_user: props.SupplyDeliverySequence?.coUserToken,
                card: CARD_SUPPLY_DELIVERY_MANAGER,
                buttonLoadKey: `${CARD_SUPPLY_DELIVERY_MANAGER}_REPRINT`,
              });
            }}
            // data
            paramKey={`${CARD_SUPPLY_DELIVERY_MANAGER}_REPRINT`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_SUPPLY_DELIVERY_MANAGER}_REPRINT`]
            }
            // config
            color="yellow"
            size="small"
            title="REPRINT"
          />
        }
      />
    </div>
  );
};

export default CardSupplyDeliveryManager;
