import React, { useEffect, useState } from "react";
import { Input, Modal, TextArea } from "semantic-ui-react";
// UI Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
// UI ANE
import CardPACURecordFormUX from "./CardPACURecordFormUX";
import CardAnesComplicationTabUX from "./CardAnesComplicationTabUX";
import CardAnesComplication01UX from "./CardAnesComplication01UX";
import CardAnesComplication02UX from "./CardAnesComplication02UX";
import CardAnesComplication03UX from "./CardAnesComplication03UX";
import CardAnesComplication04UX from "./CardAnesComplication04UX";
import CardAnesComplication05UX from "./CardAnesComplication05UX";
//
import CardANSLog from "react-lib/apps/ANS/CardANSLog";
import moment from "moment";
import { TimeFreeTextBox24 } from "react-lib/apps/common";

const CARD_KEY: string = "CardAnestheticComplication";
const FORM_CODE: string = "CardAnestheticComplication";
const FORM_NAME: string = "CardAnestheticComplication";
const FORM_VERSION: string = "1.0";

type CardAnesComplicationProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  AnesComplicationSequence?: any;
};

const CardAnesComplication = (props: CardAnesComplicationProps) => {
  const [tab, setTab] = useState<string>("Complication");
  const [code, setCode] = useState("");
  const [transfer, setTransfer] = useState("");
  const [comment, setComment] = useState("");

  useEffect(() => {
    props.runSequence({
      sequence: "AnesComplication",
      action: "FETCH_ANESCOMPLI",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formVersion: FORM_VERSION,
    });
  }, []);

  const handleChangeContainerName = (_event: any, data: any) => {
    setTab(data.name);
  };

  const handleChangeData = (_event: any, data: any) => {
    if (data.type === "checkbox") {
      props.setProp(`AnesComplicationSequence.data.${data.name}`, data.checked);
    } else {
      props.setProp(`AnesComplicationSequence.data.${data.name}`, data.value);
    }
  };

  const handleCodeChange = (e: any, data: any) => {
    setCode(data.value);
  };

  const handleTransferChange = (e: any) => {
    setTransfer(e.target.value);
  };

  const handleCommentChange = (e: any) => {
    setComment(e.target.value);
  };

  const handleAddInformationData = () => {
    props.setProp(`AnesComplicationSequence.data.transferInformationData`, [
      ...props.AnesComplicationSequence.data.transferInformationData,
      {
        id: new Date().getTime(),
        datetime: moment().format("MM/DD/YYYY HH:mm"),
        code: code,
        transfer: transfer,
        comment: comment,
      },
    ]);

    setCode("");
    setTransfer("");
    setComment("");
  };

  const transferInformationColumn = [
    {
      Header: "Date/Time",
      accessor: "datetime",
      Cell: (row: any) => {
        return (
          <Input
            fluid
            defaultValue={row.original.datetime}
            onBlur={(e: any) => {
              handleGridChange(row.index, "datetime", e.target.value);
            }}
          />
        );
      },
    },
    {
      Header: "ผู้ประเมิน Code",
      accessor: "code",
      Cell: (row: any) => {
        return (
          <Input
            fluid
            defaultValue={row.original.code}
            onBlur={(e: any) => {
              handleGridChange(row.index, "code", e.target.value);
            }}
          />
        );
      },
    },
    {
      Header: "ข้อมูลส่งต่อ",
      accessor: "transfer",
      Cell: (row: any) => {
        return (
          <Input
            fluid
            defaultValue={row.original.transfer}
            onBlur={(e: any) => {
              handleGridChange(row.index, "transfer", e.target.value);
            }}
          />
        );
      },
    },
    {
      Header: "Comment",
      accessor: "comment",
      Cell: (row: any) => {
        return (
          <Input
            fluid
            defaultValue={row.original.comment}
            onBlur={(e: any) => {
              handleGridChange(row.index, "comment", e.target.value);
            }}
          />
        );
      },
    },
  ];

  const handleGridChange = (index: any, key: any, value: any) => {
    let dataList = [
      ...props.AnesComplicationSequence.data.transferInformationData,
    ];
    dataList[index][key] = value;
    props.setProp(
      `AnesComplicationSequence.data.transferInformationData`,
      dataList
    );
  };

  const handleSetTime = () => {
    let dataRecord = props.AnesComplicationSequence?.data;
    let newEndDate = moment(
      `${dataRecord?.EndDate} ${dataRecord?.EndAnesRemark}`,
      "DD/MM/YYYY HH:mm"
    );
    let newStartDate = moment(
      `${dataRecord?.StartDate} ${dataRecord?.StartOpRemark}`,
      "DD/MM/YYYY HH:mm"
    );

    let totalTime = newEndDate.diff(newStartDate, "minutes");

    props.setProp("AnesComplicationSequence.data.PACUDurartionTime", totalTime || 0);
  };

  // -------------------- Modal Log
  const handleCloseModLog = () => {
    props.setProp("AnesComplicationSequence.modLogInfo", {
      open: false,
      logList: [],
    });
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
      />

      <CardPACURecordFormUX
        // function
        onChangeData={handleChangeData}
        onChangeDateForm={(date: string) =>
          props.setProp("AnesComplicationSequence.data.DateRamrk", date)
        }
        onChangeStartDate={(date: any) => {
          props.setProp("AnesComplicationSequence.data.StartDate", date);
          handleSetTime();
        }}
        onChangeEndDate={(date: any) => {
          props.setProp("AnesComplicationSequence.data.EndDate", date);
          handleSetTime();
        }}
        // seq
        PACURecordSequence={props.AnesComplicationSequence}
        buttonStatus={props.AnesComplicationSequence?.status}
        masterOptions={props.masterOptions}
        // component
        startTime={
          <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <TimeFreeTextBox24
              className="time-custom-react-picker"
              onChange={(value: string) => {
                props.setProp("AnesComplicationSequence.data.StartOpRemark", value);
                handleSetTime();
              }}
              value={props.AnesComplicationSequence?.data?.StartOpRemark || ""}
              iconClockStyle={{ margin: "auto 30px" }}
              autoFocus={false}
              checkTimeOverflow={true}
            ></TimeFreeTextBox24>
          </div>
        }
        endTime={
          <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <TimeFreeTextBox24
              className="time-custom-react-picker"
              onChange={(value: string) => {
                props.setProp("AnesComplicationSequence.data.EndAnesRemark", value);
                handleSetTime();
              }}
              value={props.AnesComplicationSequence?.data?.EndAnesRemark || ""}
              iconClockStyle={{ margin: "auto 30px" }}
              autoFocus={false}
              checkTimeOverflow={true}
            ></TimeFreeTextBox24>
          </div>
        }
        buttonLog={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "AnesComplication",
                action: "LOG",
                cardKey: CARD_KEY,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_LOG`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_LOG`]}
            // config
            color="orange"
            size="medium"
            style={{ width: "120px" }}
            title="Log"
          />
        }
        buttonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "AnesComplication",
                action: "PRINT",
                cardKey: CARD_KEY,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_PRINT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_PRINT`]}
            // config
            color="blue"
            size="medium"
            style={{ width: "120px" }}
            title="Print"
          />
        }
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "AnesComplication",
                action: "SAVE",
                cardKey: CARD_KEY,
                formCode: FORM_CODE,
                formName: FORM_NAME,
                formVersion: FORM_VERSION,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_SAVE`]}
            // config
            color={"orange"}
            size="medium"
            style={{ width: "120px" }}
            title={"Save Draft"}
          />
        }
        buttonConfirm={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "AnesComplication",
                action: "CONFIRM",
                cardKey: CARD_KEY,
                formCode: FORM_CODE,
                formName: FORM_NAME,
                formVersion: FORM_VERSION,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_CONFIRM`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_CONFIRM`]}
            // config
            color="green"
            size="medium"
            style={{ width: "120px" }}
            title="Confirm"
          />
        }
      />

      <CardAnesComplicationTabUX
        onChangeContainerName={handleChangeContainerName}
        tab={tab}
      />

      <div style={{ padding: "0px 10px" }}>
        {tab === "Complication" ? (
          <CardAnesComplication01UX
            onChangeData={handleChangeData}
            anesComplication={props.AnesComplicationSequence?.data}
            buttonStatus={props.AnesComplicationSequence?.status}
            buttonSave={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={() => {
                  props.runSequence({
                    sequence: "AnesComplication",
                    action: "SAVE",
                    cardKey: "CardAnesComplication",
                    formCode: "CardAnesComplication",
                    formName: "CardAnesComplication",
                    formVersion: FORM_VERSION,
                    tabForm: true,
                  });
                }}
                // data
                paramKey={`CardAnesComplication_SAVE`}
                buttonLoadCheck={
                  props.buttonLoadCheck?.[`CardAnesComplication_SAVE`]
                }
                // config
                color={"orange"}
                size="medium"
                style={{ width: "120px" }}
                title={"Save Draft"}
              />
            }
          />
        ) : tab === "Outcome" ? (
          <CardAnesComplication02UX
            onChangeData={handleChangeData}
            AnesComplicationSequence={props.AnesComplicationSequence?.data}
            buttonStatus={props.AnesComplicationSequence?.status}
            buttonSave={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={() => {
                  props.runSequence({
                    sequence: "AnesComplication",
                    action: "SAVE",
                    cardKey: "CardAnesOutcome",
                    formCode: "CardAnesOutcome",
                    formName: "CardAnesOutcome",
                    formVersion: FORM_VERSION,
                    tabForm: true,
                  });
                }}
                // data
                paramKey={`CardAnesOutcome_SAVE`}
                buttonLoadCheck={
                  props.buttonLoadCheck?.[`CardAnesOutcome_SAVE`]
                }
                // config
                color={"orange"}
                size="medium"
                style={{ width: "120px" }}
                title={"Save Draft"}
              />
            }
          />
        ) : tab === "Postoperative" ? (
          <CardAnesComplication03UX
            onChangeData={handleChangeData}
            AnesComplicationSequence={props.AnesComplicationSequence?.data}
            buttonStatus={props.AnesComplicationSequence?.status}
            buttonSave={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={() => {
                  props.runSequence({
                    sequence: "AnesComplication",
                    action: "SAVE",
                    cardKey: "CardAnesPACU",
                    formCode: "CardAnesPACU",
                    formName: "CardAnesPACU",
                    formVersion: FORM_VERSION,
                    tabForm: true,
                  });
                }}
                // data
                paramKey={`CardAnesPACU_SAVE`}
                buttonLoadCheck={props.buttonLoadCheck?.[`CardAnesPACU_SAVE`]}
                // config
                color={"orange"}
                size="medium"
                style={{ width: "120px" }}
                title={"Save Draft"}
              />
            }
          />
        ) : tab === "Postanesthesia" ? (
          <CardAnesComplication04UX
            onChangeData={handleChangeData}
            AnesComplicationSequence={props.AnesComplicationSequence?.data}
            buttonStatus={props.AnesComplicationSequence?.status}
            buttonSave={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={() => {
                  props.runSequence({
                    sequence: "AnesComplication",
                    action: "SAVE",
                    cardKey: "CardAnesServiceTime",
                    formCode: "CardAnesServiceTime",
                    formName: "CardAnesServiceTime",
                    formVersion: FORM_VERSION,
                    tabForm: true,
                  });
                }}
                // data
                paramKey={`CardAnesServiceTime_SAVE`}
                buttonLoadCheck={
                  props.buttonLoadCheck?.[`CardAnesServiceTime_SAVE`]
                }
                // config
                color={"orange"}
                size="medium"
                style={{ width: "120px" }}
                title={"Save Draft"}
              />
            }
          />
        ) : tab === "Assessment" ? (
          <CardAnesComplication05UX
            code={code}
            transfer={transfer}
            comment={comment}
            handleCodeChange={handleCodeChange}
            handleTransferChange={handleTransferChange}
            handleCommentChange={handleCommentChange}
            handleAddInformationData={handleAddInformationData}
            transferInformationColumn={transferInformationColumn}
            transferInformationData={
              props.AnesComplicationSequence.data.transferInformationData
            }
            buttonStatus={props.AnesComplicationSequence?.status}
            buttonSave={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={() => {
                  props.runSequence({
                    sequence: "AnesComplication",
                    action: "SAVE",
                    cardKey: "CardAnesAssessment",
                    formCode: "CardAnesAssessment",
                    formName: "CardAnesAssessment",
                    formVersion: FORM_VERSION,
                    tabForm: true,
                  });
                }}
                // data
                paramKey={`CardAnesAssessment_SAVE`}
                buttonLoadCheck={
                  props.buttonLoadCheck?.[`CardAnesAssessment_SAVE`]
                }
                // config
                color={"orange"}
                size="medium"
                style={{ width: "120px" }}
                title={"Save Draft"}
              />
            }
          />
        ) : (
          ""
        )}
      </div>

      <Modal
        open={props.AnesComplicationSequence?.modLogInfo?.open}
        closeOnDimmerClick={true}
        onClose={handleCloseModLog}
      >
        <CardANSLog
          editDetail={true}
          dataList={props.AnesComplicationSequence?.modLogInfo?.logList || []}
          hideCallback={handleCloseModLog}
        />
      </Modal>
    </>
  );
};

export default React.memo(CardAnesComplication);
