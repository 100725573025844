import WasmController from 'react-lib/frameworks/WasmController';
import FormTypeList from "issara-sdk/apis/FormTypeList_apps_PTM";
import FormDataNurseNote from "issara-sdk/apis/FormDataNurseNoteList_apps_PTM";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import NurseNoteLatest from "issara-sdk/apis/NurseNoteLatest_apps_PTM";
import NurseNoteItemList from "issara-sdk/apis/NurseNoteItemList_apps_PTM";
import NurseNoteItemDetail from "issara-sdk/apis/NurseNoteItemDetail_apps_PTM";
import NurseNoteDetail from "issara-sdk/apis/NurseNoteDetail_apps_PTM";
import DrOrderList from "issara-sdk/apis/DrOrderList_apps_PTM";
import MultidisciplinaryItemList from "issara-sdk/apis/MultidisciplinaryItemList_apps_PTM";
import MultidisciplinaryItemDetail from "issara-sdk/apis/MultidisciplinaryItemDetail_apps_PTM";
import MultidisciplinaryPrintList from "issara-sdk/apis/MultidisciplinaryPrintList_apps_PTM";
import MeasurementList from "issara-sdk/apis/MeasurementList_apps_PTM";
import VitalSignTypeList from "issara-sdk/apis/VitalSignTypeList_apps_PTM";
import VitalSignValueCreateUpdate from "issara-sdk/apis/VitalSignValueCreateUpdate_apps_PTM";
import MeasurementCancelView from "issara-sdk/apis/MeasurementCancelView_apps_PTM";
import LatestHeight from "issara-sdk/apis/LatestHeight_apps_PTM";

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "apiToken", params: any }
  |  { message: "getPatientData", params: any }
  |  { message: "getPatientWardData", params: any }
  |  { message: "periodWorkerOptions", params: any }
  |  { message: "getFlowSheet", params: any }
  |  { message: "getPatientAssessment", params: any }
  |  { message: "getFormDataLatest", params: any }
  |  { message: "getFormDataActionLog", params: any }
  |  { message: "getPatientWeightAndHeight", params: any }
  |  { message: "getVitalSignValue", params: any }
  |  { message: "getVitalSignValueRaw", params: any }
  |  { message: "getMeasurement", params: any }
  |  { message: "getVitalSignType", params: any }
  |  { message: "getVitalSign", params: any }
  |  { message: "getLastHeight", params: any }
  |  { message: "getIntakeOutput", params: any }
  |  { message: "postMeasurement", params: any }
  |  { message: "patchMeasurement", params: any }
  |  { message: "postVitalSignUpdate", params: any }
  |  { message: "deleteVitalSign", params: any }
  |  { message: "getMultidisciplinary", params: any }
  |  { message: "saveMultidisciplinary", params: any }
  |  { message: "updateMultidisciplinary", params: any }
  |  { message: "printMultidisciplinary", params: any }
  |  { message: "getFormType", params: any }
  |  { message: "getFormDataNurseNote", params: any }
  |  { message: "deleteMultidisciplinary", params: any }
  |  { message: "getNurseDiagnosisResultGroup", params: any }
  |  { message: "putNurseDiagPrintPhv", params: any }
  |  { message: "getNurseNoteLatest", params: any }
  |  { message: "getNurseNoteItem", params: any }
  |  { message: "postNurseNoteItem", params: any }
  |  { message: "putNurseNoteItem", params: any }
  |  { message: "deleteNurseNoteItem", params: any }
  |  { message: "putNurseNote", params: any }
  |  { message: "getDrOrder", params: any }
  |  { message: "getVentilatorMode", params: any }
  |  { message: "putEncounterVitalSignType", params: any }
  |  { message: "putRemoveEncounterVitalSignType", params: any }
  |  { message: "postFormData", params: any }
  |  { message: "putFormData", params: any }
  |  { message: "putMeasurement", params: any }
  |  { message: "ptmService", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const apiToken: Handler = async (controller, {}) => {
//undefined
//
//----> Name mismatch between manager and service
}

export const getPatientData: Handler = async (controller, {}) => {
//function () {
//            return [null, null];
//        }
//
//----> Name mismatch between manager and service
}

export const getPatientWardData: Handler = async (controller, {}) => {
//function () {
//            return [null, null];
//        }
//
//----> Name mismatch between manager and service
}

export const periodWorkerOptions: Handler = async (controller, {}) => {
//function () {
//            return [null, null];
//        }
//
//----> Name mismatch between manager and service
}

export const getFlowSheet: Handler = async (controller, {}) => {
//function () {
//            return [null, null];
//        }
//
//----> Name mismatch between manager and service
}

export const getPatientAssessment: Handler = async (controller, {}) => {
//function (patientId) { return __awaiter(_this, void 0, void 0, function () {
//            var patientAssessmentData, patientAssessmentError, params, _a, error, response;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        patientAssessmentData = {};
//                        patientAssessmentError = null;
//                        params = {
//                            patient: patientId,
//                            form_version: 0.1,
//                            form_code: "CardPatientAssessment"
//                        };
//                        return [4 /*yield*/, utils_1.to(this.ptmService.getFormDataLatest(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1];
//                        if (response) {
//                            patientAssessmentData = response;
//                        }
//                        else {
//                            patientAssessmentError = error;
//                        }
//                        return [2 /*return*/, [patientAssessmentData, patientAssessmentError]];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const getFormDataLatest: Handler = async (controller, {encounterId, formCode, formVersion}) => {
  let params: any = {};
  if (encounterId) {
      params.encounter = encounterId;
  }
  if (formCode) {
      params.form_code = formCode;
  }
  if (formVersion) {
      params.form_version = formVersion;
  }
  return await FormDataLatest.retrieve({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getFormDataActionLog: Handler = async (controller, {}) => {
//function (formId) { return __awaiter(_this, void 0, void 0, function () {
//            var _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0: return [4 /*yield*/, utils_1.to(this.ptmService.getFormDataActionLog(formId))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (formId) {
//        return this.client
//            .get(apis_1.PTM.FORM_DATA_ACTION_LOG({ form_id: formId }))
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PTM/form-data-action-log/' +
//((__t = (form_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getPatientWeightAndHeight: Handler = async (controller, {}) => {
//function (patientId, typeCode) { return __awaiter(_this, void 0, void 0, function () {
//            var weightData, weightError, params, _a, error, response;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        if (!typeCode) {
//                            typeCode = "Weight";
//                        }
//                        weightData = {};
//                        weightError = null;
//                        params = {
//                            patient: patientId,
//                            type_code: typeCode,
//                            latest_measurement: true
//                        };
//                        return [4 /*yield*/, utils_1.to(this.ptmService.getVitalSignValue(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1];
//                        if (response) {
//                            weightData = response.items[0];
//                        }
//                        else {
//                            weightError = error;
//                        }
//                        return [2 /*return*/, [weightData, weightError]];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const getVitalSignValue: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, patientId = _b.patientId, latestMeasurement = _b.latestMeasurement, typeCode = _b.typeCode;
//            return __awaiter(_this, void 0, void 0, function () {
//                var vitalSignValueData, vitalSignValueError, params, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            vitalSignValueData = {};
//                            vitalSignValueError = null;
//                            params = {};
//                            if (patientId) {
//                                params.patient = patientId;
//                            }
//                            if (latestMeasurement) {
//                                params.latest_measurement = latestMeasurement;
//                            }
//                            if (typeCode) {
//                                params.type_code = typeCode;
//                            }
//                            return [4 /*yield*/, utils_1.to(this.ptmService.getVitalSignValue(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                vitalSignValueData = response.items;
//                            }
//                            else {
//                                vitalSignValueError = error;
//                            }
//                            return [2 /*return*/, [vitalSignValueData, vitalSignValueError]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.PTM.VITAL_SIGN_VALUE, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PTM/vital-sign-value/
}

export const getVitalSignValueRaw: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            var vitalSignValueData, vitalSignValueError, _a, error, response;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        vitalSignValueData = {};
//                        vitalSignValueError = null;
//                        return [4 /*yield*/, utils_1.to(this.ptmService.getVitalSignValue(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1];
//                        if (response) {
//                            vitalSignValueData = response;
//                        }
//                        else {
//                            vitalSignValueError = error;
//                        }
//                        return [2 /*return*/, [vitalSignValueData, vitalSignValueError]];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const getMeasurement: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, encounterId = _b.encounterId, usePatient = _b.usePatient, fromDate = _b.fromDate;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, measurementData, measurementError, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            measurementData = [];
//                            measurementError = null;
//                            if (encounterId) {
//                                params.encounter = encounterId;
//                            }
//                            if (usePatient) {
//                                params.use_patient = usePatient;
//                            }
//                            if (fromDate) {
//                                params.from_date = fromDate;
//                            }
//                            return [4 /*yield*/, utils_1.to(this.ptmService.getMeasurement({ params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                measurementData = response;
//                            }
//                            else {
//                                measurementError = error;
//                            }
//                            return [2 /*return*/, [measurementData, measurementError]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.PTM.MEASUREMENT, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PTM/measurement/
}

export const getVitalSignType: Handler = async (controller, { encounterId, divFromEncounter, group }) => {
  let params: any = {};
  var vitalSignType = [];
  var vitalSignTypeError = null;
  if (encounterId) {
    params.encounter = encounterId;
  }
  if (divFromEncounter) {
    params.div_from_encounter = divFromEncounter;
  }
  if (group) {
    params.group = group;
  }
  const result = VitalSignTypeList.list({
    params: params,
    apiToken: controller.apiToken,
    extra: { division: controller.data.division }
  });
  if (result[0]) {
    vitalSignType = result[0].items;
  } else {
    vitalSignTypeError = result[1];
  }
  return [vitalSignType, vitalSignTypeError];
}

export const getVitalSign: Handler = async (controller, params) => {
  var vitalSignData = [];
  var vitalSignError = null;  
  const response = await MeasurementList.list({
    params: params,
    apiToken: controller.apiToken
  });
  if (response[0]) {
    vitalSignData = response[0];
  } else {
    vitalSignError = response[1];
  }
  return [vitalSignData, vitalSignError];
}

export const getLastHeight: Handler = async (controller, patientId) => {
  var data: any = {};
  var dataError = null;
  const result = await LatestHeight.get({
    patient: patientId,
    apiToken: controller.apiToken
  });
  if (result[0]) {
    data = result[0].height;
  } else {
    dataError = result[1];
  }
  return [data, dataError];
}

export const getIntakeOutput: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            return __generator(this, function (_a) {
//                return [2 /*return*/, ["ok", null, null]];
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const postMeasurement: Handler = async (controller, params) => {
  var vsResponse = {};
  var vsError = null;
  const result = await MeasurementList.create({
    data: params,
    apiToken: controller.apiToken,
    extra: { division: controller.data.division }
  });
  if (result[0]) {
    vsResponse = result[0];
  } else {
    vsError = result[1];
  }
  return [vsResponse, vsError, result[2]];
}

export const patchMeasurement: Handler = async (controller, {measurement_id, params}) => {
  var vsResponse: any = {};
  var vsError = null;
  const result = MeasurementCancelView.patch({
    pk: measurement_id,
    data: params,
    apiToken: controller.apiToken
  });
  if (result[0]) {
    vsResponse = result[0];
  } else {
    vsError = result[1];
  }
  return [vsResponse, vsError];
}

export const postVitalSignUpdate: Handler = async (controller, params) => {
  var vsResponse: any = {};
  var vsError = null;
  const result = VitalSignValueCreateUpdate.create({
    data: params,
    apiToken: controller.apiToken
  });
  if (result[0]) {
    vsResponse = result[0];
  } else {
    vsError = result[1];
  }
  return [vsResponse, vsError, result[2]];
}

export const deleteVitalSign: Handler = async (controller, {}) => {
//function (_a) {
//            var id = _a.id;
//            return __awaiter(_this, void 0, void 0, function () {
//                var vsResponse, vsError, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            vsResponse = {};
//                            vsError = null;
//                            return [4 /*yield*/, utils_1.to(this.ptmService.deleteVitalSign({ id: id }))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            if (response) {
//                                vsResponse = response;
//                            }
//                            else {
//                                vsError = error;
//                            }
//                            return [2 /*return*/, [vsResponse, vsError, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var id = _a.id, params = _a.params, data = _a.data;
//        var url = apis_1.PTM.VITAL_SIGN_VALUE_DELETE({ id: id });
//        return this.client
//            .delete(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PTM/vital-sign-value-delete/' +
//((__t = (id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getMultidisciplinary: Handler = async (controller, params) => {
  let multidisciplinaryData = [];
  let multidisciplinaryError = null;
  let numberOfPage = null;
  const result = await MultidisciplinaryItemList.list({
    params: params,
    apiToken: controller.apiToken
  });
  if (result[0]) {
    multidisciplinaryData = result[0].items;
    if (params.limit) {
      numberOfPage = Math.ceil(result[0].total / params.limit);
    }
  } else {
    multidisciplinaryError = result[1];
  }
  return [multidisciplinaryData, multidisciplinaryError, numberOfPage];
}

export const saveMultidisciplinary: Handler = async (controller, params) => {
  var vsResponse: any = {};
  var vsError: any = null;
  const result = await MultidisciplinaryItemList.create({
    data: params,
    apiToken: controller.apiToken
  });
  if (result[0]) {
    vsResponse = result[0];
  } else {
    vsError = result[1];
  }
  return [vsResponse, vsError];
}

export const updateMultidisciplinary: Handler = async (controller, params) => {
  var vsResponse = {};
  var vsError = null;
  const result = await MultidisciplinaryItemDetail.update({
    pk: params.id,
    data: params,
    apiToken: controller.apiToken
  });
  if (result[0]) {
    vsResponse = result[0];
  } else {
    vsError = result[1];
  }
  return [vsResponse, vsError];
}

export const printMultidisciplinary: Handler = async (controller, params) => {
  var vsResponse: any = {};
  var vsError: any = null;
  const result = await MultidisciplinaryPrintList.post({
    params: { pdf: params?.pdf },
    data: params,
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });
  if (result[0]) {
    vsResponse = result[0];
    const pdfWindow = window.open(
      "_blank",
      `data:application/pdf;base64,${vsResponse.pdf_b64data}`
    );
    pdfWindow?.document?.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64," +
        vsResponse.pdf_b64data +
        "'></iframe>"
    );
  } else {
    vsError = result[1];
  }
  return [vsResponse, vsError];
}

export const getFormType: Handler = async (controller, {}) => {
  return await FormTypeList.list({
    apiToken: controller.apiToken
  });
}

export const getFormDataNurseNote: Handler = async (controller, 
  {encounterType, encounterNo, formType, doctor, startDate, endDate, patient}) => {
  let params: any = {};
  if (patient) {
    params.patient = patient;
  }
  if (encounterType) {
    params.encounter_type = encounterType;
  }
  if (encounterNo) {
    params.encounter_number = encounterNo;
  }
  if (formType) {
    params.form_type = formType;
  }
  if (doctor) {
    params.doctor = doctor;
  }
  if (startDate) {
    params.from_date = startDate;
  }
  if (endDate) {
    params.to_date = endDate;
  }
  return FormDataNurseNote.list({
    params: params,
    apiToken: controller.apiToken
  });
}

export const deleteMultidisciplinary: Handler = async (controller, {item_id, params}) => {
  var vsResponse: any = {};
  var vsError: any = null;
  const result = await MultidisciplinaryItemDetail.delete({
    pk: item_id,
    // below shouldn't be like this but copied from old code
    params: { data : params },
    apiToken: controller.apiToken
  });
  if (result[0]) {
    vsResponse = result[0];
  } else {
    vsError = result[1];
  }
  return [vsResponse, vsError];
}

export const getNurseDiagnosisResultGroup: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, encounterId = _b.encounterId, startDate = _b.startDate, endDate = _b.endDate, divisionId = _b.divisionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var data, error, params, _c, responseError, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            data = [];
//                            error = null;
//                            params = {};
//                            if (encounterId) {
//                                params.encounter = encounterId;
//                            }
//                            if (startDate) {
//                                params.start_date = startDate;
//                            }
//                            if (endDate) {
//                                params.end_date = endDate;
//                            }
//                            if (divisionId) {
//                                params.division = divisionId;
//                            }
//                            return [4 /*yield*/, utils_1.to(this.ptmService.getNurseDiagnosisResultGroup(params))];
//                        case 1:
//                            _c = _d.sent(), responseError = _c[0], response = _c[1];
//                            if (response) {
//                                data = response;
//                            }
//                            else {
//                                error = responseError;
//                            }
//                            return [2 /*return*/, [data, error]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.PTM.NURSE_DIAG_RESULT_GROUP, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PTM/nurse-diag-result-group/
}

export const putNurseDiagPrintPhv: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, documentNo = _b.documentNo, pdf = _b.pdf;
//            return __awaiter(_this, void 0, void 0, function () {
//                var data, error, data, _c, responseError, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            data = [];
//                            error = null;
//                            data = {};
//                            if (documentNo) {
//                                data.document_no = documentNo;
//                            }
//                            if (pdf) {
//                                data.pdf = pdf;
//                            }
//                            return [4 /*yield*/, utils_1.to(this.ptmService.putNurseDiagPrintPhv({ data: data }))];
//                        case 1:
//                            _c = _d.sent(), responseError = _c[0], response = _c[1];
//                            if (response) {
//                                data = response;
//                            }
//                            else {
//                                error = responseError;
//                            }
//                            return [2 /*return*/, [data, error]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var params = _a.params, data = _a.data;
//        return this.client
//            .put(apis_1.PTM.NURSE_DIAG_PRINT_PHV, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PTM/nurse-diag-print/phv/
}

export const getNurseNoteLatest: Handler = async (controller, {encounterId}) => {
  let params: any = {};
  if (encounterId) {
      params.encounter = encounterId;
  }
  return await NurseNoteLatest.retrieve({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getNurseNoteItem: Handler = async (controller, {nurseNoteId, encounterId, fromDate, toDate, apiToken}) => {
  let params: any = {};
  if (nurseNoteId) {
      params.nurse_note = nurseNoteId;
  }
  if (fromDate) {
      params.from_date = fromDate;
  }
  if (toDate) {
      params.to_date = toDate;
  }
  if (encounterId) {
      params.encounter = encounterId;
  }
  return await NurseNoteItemList.list({
    params: params,
    apiToken: controller.apiToken
  });
}

export const postNurseNoteItem: Handler = async (controller, 
  {date, time, workingShift, plan, goal, diagnosis, username, password, nurseNoteId, encounter, division, token, doctorOrderId, userToken, apiToken}) => {
  let data: any = {};
  if (encounter) {
      data.encounter = encounter;
  }
  if (division) {
      data.division = division;
  }
  if (nurseNoteId) {
      data.nurse_note_id = nurseNoteId;
  }
  if (workingShift) {
      data.working_shift = workingShift;
  }
  if (date) {
      data.date = date + "-" + time;
  }
  if (plan) {
      data.plan = plan;
  }
  if (diagnosis) {
      data.diagnosis = diagnosis;
  }
  if (goal) {
      data.goal = goal;
  }
  if (doctorOrderId) {
      data.doctor_order_id = doctorOrderId;
  }
  if (username) {
      data.username = username;
  }
  if (password) {
      data.password = password;
  }
  if (token) {
      data.csrfmiddlewaretoken = token;
  }
  if (userToken) {
      data.user_token = userToken;
  }
  let params: any = {};
  return await NurseNoteItemList.create({
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const putNurseNoteItem: Handler = async (controller, 
  {date, time, workingShift, plan, goal, diagnosis, username, password, nurseNoteId, encounter, 
   token, doctorOrderId, nurseNoteItemId, apiToken, division, userToken}) => {
    let data: any = {};
    if (encounter) {
        data.encounter = encounter;
    }
    if (nurseNoteId) {
        data.nurse_note_id = nurseNoteId;
    }
    if (workingShift) {
        data.working_shift = workingShift;
    }
    if (date) {
        data.date = date + "-" + time;
    }
    if (plan) {
        data.plan = plan;
    }
    if (diagnosis) {
        data.diagnosis = diagnosis;
    }
    if (goal) {
        data.goal = goal;
    }
    if (doctorOrderId) {
        data.doctor_order_id = doctorOrderId;
    }
    if (username) {
        data.username = username;
    }
    if (password) {
        data.password = password;
    }
    if (token) {
        data.csrfmiddlewaretoken = token;
    }
    if (division) {
        data.division = division;
    }
    if (userToken) {
        data.user_token = userToken;
    }
    return await NurseNoteItemDetail.update({
      pk: nurseNoteItemId,
      data: data,
      apiToken: controller.apiToken
    });
}

export const deleteNurseNoteItem: Handler = async (controller, {username, password, csrf, nurseNoteItemId}) => {
  let data: any = {};
  if (username) {
      data.username = username;
  }
  if (password) {
      data.password = password;
  }
  if (csrf) {
      data.csrfmiddlewaretoken = csrf;
  }
  return await NurseNoteItemDetail.delete({
    pk: nurseNoteItemId,
    params: { data: data }
  });
}

export const putNurseNote: Handler = async (controller, 
  {username, password, csrf, nurseNoteId, status, action, isCheifNurse, encounter}) => {
    let data: any = {};
    if (username) {
        data.username = username;
    }
    if (password) {
        data.password = password;
    }
    if (csrf) {
        data.csrfmiddlewaretoken = csrf;
    }
    if (nurseNoteId) {
        data.id = nurseNoteId;
    }
    if (status) {
        data.status = status;
    }
    if (action) {
        data.action = action;
    }
    if (isCheifNurse) {
        data.is_cheif_nurse = isCheifNurse;
    }
    if (encounter) {
        data.encounter = encounter;
    }
    return await NurseNoteDetail.update({
      pk: nurseNoteId,
      data: data,
      apiToken: controller.apiToken
    });
}

export const getDrOrder: Handler = async (controller, {fromDate, toDate, encounterId}) => {
  let params: any = {};
  if (fromDate) {
      params.from_date = fromDate;
  }
  if (toDate) {
      params.to_date = toDate;
  }
  if (encounterId) {
      params.encounter = encounterId;
  }
  return await DrOrderList.get({
    params: params
  })
}

export const getVentilatorMode: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            var params, _a, error, response;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        params = {};
//                        return [4 /*yield*/, utils_1.to(this.ptmService.getVentilatorMode({ params: params }))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1];
//                        return [2 /*return*/, [response, error]];
//                }
//            });
//        }); }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params;
//        return this.client
//            .get(apis_1.PTM.VENTILATOR_MODE, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PTM/ventilater-mode/
}

export const putEncounterVitalSignType: Handler = async (controller, {}) => {
//function (_a) {
//            var params = _a.params, data = _a.data, encounterId = _a.encounterId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0: return [4 /*yield*/, utils_1.to(this.ptmService.putEncounterVitalSignType({ params: params, data: data, encounterId: encounterId }))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var params = _a.params, data = _a.data, encounterId = _a.encounterId;
//        var url = apis_1.PTM.ENCOUNTER_VITALSIGN_TYPE({ encounter_id: encounterId });
//        return this.client
//            .put(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PTM/encounter-vitalsign-type/' +
//((__t = (encounter_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const putRemoveEncounterVitalSignType: Handler = async (controller, {}) => {
//function (_a) {
//            var params = _a.params, data = _a.data, encounterId = _a.encounterId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0: return [4 /*yield*/, utils_1.to(this.ptmService.putRemoveEncounterVitalSignType({
//                                params: params,
//                                data: data,
//                                encounterId: encounterId
//                            }))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var params = _a.params, data = _a.data, encounterId = _a.encounterId;
//        var url = apis_1.PTM.REMOVE_ENCOUNTER_VITALSIGN_TYPE({
//            encounter_id: encounterId
//        });
//        return this.client
//            .put(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PTM/encounter-vitalsign-type/' +
//((__t = (encounter_id)) == null ? '' : __t) +
//'/remove/';
//
//}
//return __p
//}
}

export const postFormData: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, action = _b.action, encounter = _b.encounter, formCode = _b.formCode, formName = _b.formName, formVersion = _b.formVersion, formData = _b.formData;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            params.encounter = encounter;
//                            params.action = action;
//                            params.form_code = formCode;
//                            params.form_name = formName;
//                            params.form_version = formVersion;
//                            params.data = formData;
//                            return [4 /*yield*/, utils_1.to(this.ptmService.postFormData({ params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            return [2 /*return*/, [response, error]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params;
//        // /apis/PTM/form-data/ #CREATE, UPDATE FORM_DATA
//        // /apis/PTM/form-data-latest/ #RETRIEVE FORM_DATA_LATEST
//        return this.client
//            .post(apis_1.PTM.FORM_DATA, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PTM/form-data/
}

export const putFormData: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, formDataId = _b.formDataId, action = _b.action, encounter = _b.encounter, formCode = _b.formCode, formName = _b.formName, formVersion = _b.formVersion, formData = _b.formData;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            params.action = action;
//                            params.encounter = encounter;
//                            params.form_code = formCode;
//                            params.form_name = formName;
//                            params.form_version = formVersion;
//                            params.data = formData;
//                            return [4 /*yield*/, utils_1.to(this.ptmService.putFormData({ params: params, formDataId: formDataId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            return [2 /*return*/, [response, error]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, formDataId = _b.formDataId;
//        var url = apis_1.PTM.FORM_DATA_DETAIL({ form_data_id: formDataId });
//        return this.client
//            .put(url, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PTM/form-data/' +
//((__t = (form_data_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const putMeasurement: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, encounterId = _b.encounterId, date = _b.date, time = _b.time, measurementId = _b.measurementId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var data, params, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            data = {};
//                            params = {};
//                            if (measurementId) {
//                                data.id = measurementId;
//                            }
//                            if (date) {
//                                data.date = date;
//                            }
//                            if (encounterId) {
//                                data.encounter = encounterId;
//                            }
//                            if (time) {
//                                data.time = time;
//                            }
//                            return [4 /*yield*/, utils_1.to(this.ptmService.putMeasurement({ params: params, data: data, measurementId: measurementId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            return [2 /*return*/, [response, error]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data, measurementId = _b.measurementId;
//        var url = apis_1.PTM.MEASUREMENT_DETAIL({ measurementId: measurementId });
//        return this.client
//            .put(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PTM/measurement/' +
//((__t = (measurementId)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const ptmService: Handler = async (controller, {}) => {
//[object Object]
//
//----> Name mismatch between manager and service
}
