import React, { useEffect, useMemo, useState } from "react";
import { formatDatetime } from "react-lib/utils/dateUtils";
import CardSupplyOrderHistoryUX from "./CardSupplyOrderHistoryUX";
import {
  PatientSearchBox,
  SearchBox,
  EmployeeToken,
} from "react-lib/apps/common";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

const CARD_SUPPLY_ORDER_HISTORY = "CardSupplyOrderHistory";
const CardSupplyOrderHistory = (props: any) => {
  const isMounted = React.useRef(false);

  const [nationalities, setNationalities] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patientList, setPatientList] = useState<any>([]);
  const [currentHNPage, setCurrentHNPage] = useState(1);
  const [numberOfHNPage, setNumberOfHNPage] = useState(1);

  const patientRef = React.useRef<any>();
  const doctorRef = React.useRef<any>();

  useEffect(() => {
    if (props.runSequence) {
      props?.runSequence({ sequence: "SupplyOrderHistory" });

      isMounted.current = true;

      return () => {
        props?.runSequence({ sequence: "SupplyOrderHistory", clear: true });

        isMounted.current = false;
      };
    }
  }, []);

  const supplyOrderHistoryList = useMemo(() => {
    return (props.SupplyOrderHistorySequence?.supplyOrderHistoryList || []).map(
      (item: any) => ({
        ...item,
        orderTime: item.order_time ? formatDatetime(item.order_time) : "",
        orderPerformTime: item.order_perform_time
          ? formatDatetime(item.order_perform_time)
          : "",
      })
    );
  }, [props.SupplyOrderHistorySequence?.supplyOrderHistoryList]);

  const handleGetDoctorOptions = async ({ searchText }: any) => {
    const [data, error] = await props.controller.getDoctor({
      name_code: searchText,
    });

    return [data, error];
  };

  const handleSelectedDoctor = (item: any) => {
    props.setProp("SupplyOrderHistorySequence", {
      ...props.SupplyOrderHistorySequence,
      checkedDoctor: true,
      selectedDoctor: item.id,
    });
  };

  const getNationality = async () => {
    const [data, error] = await props.controller.getNationalities();

    if (isMounted.current) {
      if (data) {
        setNationalities(data);
      } else {
        setNationalities([]);
      }
    }
  };

  const getPatientList = async ({
    hn,
    fullName,
    citizenId,
    nationality,
    patientCurrentPage,
  }: any = {}) => {
    let limit = 40;
    let page = patientCurrentPage ? patientCurrentPage : currentHNPage;

    setIsLoading(true);
    setCurrentHNPage(page);

    const [data, error] = await props.controller.getSearchAllPatient({
      hn,
      fullName,
      citizenId,
      nationality,
      is_old_name_search: true,
      limit,
      offset: (page - 1) * limit,
    });

    if (isMounted.current) {
      setIsLoading(false);

      if (data) {
        setPatientList(data.items);
        setNumberOfHNPage(parseInt(data.total) / limit + 1);
      } else {
        setPatientList([]);
        setNumberOfHNPage(1);
      }
    }

    return [data, error];
  };

  const handlePaginationChange = async ({
    hn,
    fullName,
    citizenId,
    nationality,
    patientCurrentPage,
  }: any = {}) => {
    setCurrentHNPage(patientCurrentPage);
    getPatientList({
      hn,
      fullName,
      citizenId,
      nationality,
      patientCurrentPage,
    });
  };

  const handleGetPatientByHN = async (hn: any) => {
    const [data, error, network] = await props.controller.getPatientByHN({
      hn,
    });

    return [data, error, network];
  };

  const handleOnEnterPatientSearch = (
    id: number,
    hn: string,
    full_name: string
  ) => {
    if (isMounted.current) {
      props.setProp("SupplyOrderHistorySequence", {
        ...props.SupplyOrderHistorySequence,
        checkedPatient: true,
        selectedPatient: id,
      });
    }
  };

  const handleChangeValue =
    (key: string, keyChecked: string) => (e: any, v: any) => {
      props.setProp("SupplyOrderHistorySequence", {
        ...props.SupplyOrderHistorySequence,
        [keyChecked]: true,
        [key]: v.value,
      });
    };
  const handleChangeChecked =
    (key: string) =>
    (e: any, { checked }: any) => {
      props.setProp(`SupplyOrderHistorySequence.${key}`, checked);
    };

  const handleChangeDate = (key: string) => (date: any) => {
    props.setProp(`SupplyOrderHistorySequence.${key}`, date);
  };

  const handleGetTrProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => ({
    onDoubleClick: (e: any) => {
      props.runSequence({
        sequence: "SupplyOrderHistory",
        action: "select",
        orderId: rowInfo?.original?.id,
      });

      props?.forward();
    },
  });

  return (
    <div>
      <CardSupplyOrderHistoryUX
        supplyOrderHistoryList={supplyOrderHistoryList}
        masterOptions={props.masterOptions}
        handleChangeValue={handleChangeValue}
        handleChangeChecked={handleChangeChecked}
        handleChangeDate={handleChangeDate}
        handleGetTrProps={handleGetTrProps}
        selectedPatient={props.SupplyOrderHistorySequence?.selectedPatient}
        selectedDoctor={props.SupplyOrderHistorySequence?.selectedDoctor}
        selectedType={props.SupplyOrderHistorySequence?.selectedType}
        selectedStatus={props.SupplyOrderHistorySequence?.selectedStatus}
        selectedOrderDivision={
          props.SupplyOrderHistorySequence?.selectedOrderDivision
        }
        selectedPerformDivision={
          props.SupplyOrderHistorySequence?.selectedPerformDivision
        }
        selectedOrderStartDate={
          props.SupplyOrderHistorySequence?.selectedOrderStartDate
        }
        selectedOrderEndDate={
          props.SupplyOrderHistorySequence?.selectedOrderEndDate
        }
        selectedPerformStartDate={
          props.SupplyOrderHistorySequence?.selectedPerformStartDate
        }
        selectedPerformEndDate={
          props.SupplyOrderHistorySequence?.selectedPerformEndDate
        }
        selectedUser={props.SupplyOrderHistorySequence?.selectedUser}
        checkedPatient={props.SupplyOrderHistorySequence?.checkedPatient}
        checkedDoctor={props.SupplyOrderHistorySequence?.checkedDoctor}
        checkedType={props.SupplyOrderHistorySequence?.checkedType}
        checkedStatus={props.SupplyOrderHistorySequence?.checkedStatus}
        checkedOrderDivision={
          props.SupplyOrderHistorySequence?.checkedOrderDivision
        }
        checkedPerformDivision={
          props.SupplyOrderHistorySequence?.checkedPerformDivision
        }
        checkedOrderDate={props.SupplyOrderHistorySequence?.checkedOrderDate}
        checkedPerformDate={
          props.SupplyOrderHistorySequence?.checkedPerformDate
        }
        checkedUser={props.SupplyOrderHistorySequence?.checkedUser}
        doctorSearch={
          <SearchBox
            ref={doctorRef}
            inputStyle={{ width: "100%" }}
            onGetSearchOptions={handleGetDoctorOptions}
            textField="name_code"
            onSelectOption={handleSelectedDoctor}
          />
        }
        patientSearch={
          <PatientSearchBox
            ref={patientRef}
            patientList={patientList}
            nationalitiesList={nationalities}
            patientListLoading={isLoading}
            numberOfPage={numberOfHNPage}
            currentPage={currentHNPage}
            fluid={true}
            modalStyle={{ width: "auto" }}
            getNationality={getNationality}
            getPatientList={getPatientList}
            onEnter={handleOnEnterPatientSearch}
            onSelectPatient={handleOnEnterPatientSearch}
            onGetPatientByHN={handleGetPatientByHN}
            onPatientListPaginationChange={handlePaginationChange}
          />
        }
        employeeCode={
          <EmployeeToken
            onEnterToken={(code) => {
              props.runSequence({
                sequence: "SupplyOrderHistory",
                action: "searchToken",
                code: code,
              });
            }}
            onClearToken={() => {
              props.setProp("SupplyOrderHistorySequence", {
                ...props.SupplyOrderHistorySequence,
                selectedUserToken: "",
                selectedUser: "",
                loading: false,
                error: null,
              });
            }}
            placeholder="Code"
            fluid={true}
            error={props.SupplyOrderHistorySequence?.error}
            loading={props.SupplyOrderHistorySequence?.loading}
            employeeCode={
              props?.SupplyOrderHistorySequence?.selectedUserToken || ""
            }
            employeeName={props?.SupplyOrderHistorySequence?.selectedUser || ""}
          />
        }
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "SupplyOrderHistory",
                action: "search",
                card: CARD_SUPPLY_ORDER_HISTORY,
                buttonLoadKey: `${CARD_SUPPLY_ORDER_HISTORY}_SEARCH`,
              });
            }}
            // data
            paramKey={`${CARD_SUPPLY_ORDER_HISTORY}_SEARCH`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_SUPPLY_ORDER_HISTORY}_SEARCH`]
            }
            // config
            style={{ with: "100%", height: "100%" }}
            size="small"
            name="SEARCH"
            title="ค้นหา"
            color="blue"
          />
        }
        onClear={() => {
          patientRef.current?.clear?.();

          doctorRef.current?.clear?.();

          props.runSequence({
            sequence: "SupplyOrderHistory",
            action: "clear",
          });
        }}
      />
    </div>
  );
};

export default CardSupplyOrderHistory;
