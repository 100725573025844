import React, { useState, useEffect, SyntheticEvent } from "react";
import { Input, Modal } from "semantic-ui-react";

import moment from "moment";

// UX
import CardSearchInvoiceGroupUX from "./CardSearchInvoiceGroupUX";
import { formatDate } from "../../../utils/dateUtils";

// Common
import { DateTextBox } from "react-lib/apps/common";

// Types
type CardSearchInvoiceGroupType = {
  onEvent: (e: any) => any;
  // callback
  onSelected: (data: any) => any;
};

type FilterType = Partial<{
  isCreateDate: boolean;
  createDate: string;
  isInvoiceNo: boolean;
  invoiceNo: string;
  isFiscalYear: boolean;
  fiscalYear: string;
}>;

const CardSearchInvoiceGroup = (props: CardSearchInvoiceGroupType) => {
  const [open, setOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<FilterType>({});
  const [searchList, setSearchList] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<Record<string, any> | null>(
    null
  );
  const [lotNo, setLotNo] = useState<string>("");

  useEffect(() => {
    if (open) {
      setFilter({ isCreateDate: true, createDate: formatDate(moment()) });
    }
  }, [open]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFilter({});
    setSearchList([]);
  };

  const handleChange = (e: any, v: any) => {
    const value = typeof v.checked === "boolean" ? v.checked : v.value;
    setFilter({
      ...filter,
      [v.name]: value,
    });
  };

  const handleChangeDate = (name: string) => (value: string) => {
    handleChange(null, { name, value });
  };

  const handleSelect = () => {
    handleSelected(selectedRow);
  };

  const handleSelected = (row: any) => {
    props.onSelected(row);

    setLotNo(row?.lot_no || "");

    handleClose();
  };

  const handleSearch = async () => {
    const formatParams = (isKey: keyof FilterType, value: any) => {
      return ((filter as any)[isKey] || undefined) && value;
    };

    const [result] = await props.onEvent({
      message: "GetARTransactions",
      params: {
        invoice_date: formatParams("isCreateDate", filter.createDate),
        lot_no: formatParams("isInvoiceNo", filter.invoiceNo),
        fiscal_year: formatParams("isFiscalYear", filter.fiscalYear),
      },
    });

    setSearchList(result?.items || []);
  };

  const handleChangeInput = (e: any, v: any) => {
    setLotNo(v.value);
    props.onSelected({ lot_no: v.value });
  };

  const handleKeyDown = async (e: any) => {
    if (e.key === "Enter") {
      const [result] = await props.onEvent({
        message: "GetARTransactions",
        params: {
          lot_no: e.target.value,
          limit: 1,
        },
      });

      if (result?.items?.length) {
        props.onSelected(result.items[0]);
      }
    }
  };

  // return
  const handleGetTrProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      onClick: () => {
        setSelectedRow(
          selectedRow?.id === rowInfo?.original?.id ? null : rowInfo.original
        );
      },
      onDoubleClick: () => {
        handleSelected(rowInfo?.original);
      },
      className:
        rowInfo?.original?.id && rowInfo?.original?.id === selectedRow?.id
          ? "blueSelectedRow"
          : "",
    };
  };

  return (
    <div>
      <Input
        value={lotNo}
        action={{ icon: "search", onClick: handleClick }}
        placeholder=""
        onKeyDown={handleKeyDown}
        onChange={handleChangeInput}
      />

      <Modal open={open} size="large" closeOnDimmerClick onClose={handleClose}>
        <CardSearchInvoiceGroupUX
          // Data
          items={searchList}
          isCreateDate={filter.isCreateDate}
          createDate={filter.createDate}
          isInvoiceNo={filter.isInvoiceNo}
          invoiceNo={filter.invoiceNo}
          isFiscalYear={filter.isFiscalYear}
          fiscalYear={filter.fiscalYear}
          // callback
          onChangeValue={handleChange}
          onSelect={handleSelect}
          onSearch={handleSearch}
          onClose={handleClose}
          getTrProps={handleGetTrProps}
          // config
          disabledButtonSelect={!selectedRow?.id}
          // Element
          DateTextBox={
            <DateTextBox
              value={filter.createDate || ""}
              fluid={true}
              disabled={!filter.isCreateDate}
              inputStyle={{ width: "145px" }}
              onChange={handleChangeDate("createDate")}
            />
          }
        />
      </Modal>
    </div>
  );
};

export default React.memo(CardSearchInvoiceGroup);
