import { degrees, PDFDocument } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

const base64toBlob = (data: string) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr("data:application/pdf;base64,".length);
  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  const out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
};

export const createPDFNurseNote = (props) => {
  import("pdfmake/build/pdfmake").then((make) => {
    import("react-lib/assets/fonts/pdfFonts").then(async (font) => {
      let pdfMake = make.default;
      pdfMake.vfs = font.default;
      pdfMake.fonts = {
        THSarabunNew: {
          normal: "THSarabunNew.ttf",
          bold: "THSarabunNew-Bold.ttf",
          italics: "THSarabunNew-Italic.ttf",
          bolditalics: "THSarabunNew-BoldItalic.ttf",
        },
        Roboto: {
          normal: "Roboto-Regular.ttf",
          bold: "Roboto-Medium.ttf",
          italics: "Roboto-Italic.ttf",
          bolditalics: "Roboto-MediumItalic.ttf",
        },
      };

      var origin = window?.location?.origin;
      var RiskLowColorKey = "#23B000";
      var RiskMidColorKey = "#FE7D34";
      var RiskHighColorKey = "#FF0000";

      // playground requires you to assign document definition to a variable called dd

      var docDefinition = {
        info: {
          title: props.patient_name,
          author: props.author,
          subject: "Nurse's note Report",
          keywords: "Nurse's note",
        },

        defaultStyle: {
          font: "THSarabunNew",
          // alignment: 'justify'
          lineHeight: 1,
          fontSize: 13,
        },
        footer: function (currentPage: number, pageCount: number) {
          return {
            columns: [{ text: "หน้าที่ " + currentPage.toString() + " / " + pageCount, alignment: "right", margin: [0, 0, 10, 0] }],
          };
        },
        pageMargins: [20, 285, 20, 20],
        //A4: [595.28, 841.89]
        pageSize: "A4",
        styles: {
          facultyHeader: {
            fontSize: 16,
            bold: true,
            margin: [0, 0, 0, 0],
          },
          clinicHeader: {
            fontSize: 16,
            bold: true,
            margin: [0, 0, 0, 15],
          },
          fieldKey: {
            bold: true,
            fontSize: 14,
          },
          fieldValue: {
            fontSize: 14,
          },
          tableNormal: {
            margin: [0, 5, 0, 5],
          },
          tableWithOutBorder: {
            margin: [0, 0, 0, 0],
            border: [false, false, false, false],
          },
          tableHeader: {
            fillColor: "lightgray",
          },
          tableHeaderNurseNote: {
            bold: true,
            fillColor: "lightgray",
          },
          tableHeaderExamination: {
            bold: true,
            fontSize: 13,
            color: "black",
            fillColor: "lightgray",
          },
          tableHeaderType: {
            bold: true,
            fontSize: 13,
            color: "black",
            fillColor: "lightgray",
          },
          tableExamination: {
            fontSize: 13,
            color: "black",
            margin: [0, 10, 0, 10],
          },
          tableDiagram: {
            fontSize: 14,
            bold: true,
            fillColor: "lightgray",
          },
          fieldKeyDiagram: {
            bold: true,
            fontSize: 13,
          },
          fieldValueDiagram: {
            fontSize: 13,
          },
          treamentPerformSubKey: {
            bold: true,
            fillColor: "lightgray",
            color: "black",
            fontSize: 13,
            margin: [10, 0, 0, 0],
          },
          treatmentPerformLine: {
            fillColor: "lightgray",
            color: "black",
            fontSize: 13,
            margin: [5, 0, 0, 0],
          },
          treatmentTableDetail: {
            fillColor: "lightgray",
            color: "black",
            fontSize: 13,
          },
          treatmentTableDetailText: {
            color: "black",
            fontSize: 13,
          },
          galleryMargin: {
            margin: [10, 10, 10, 10],
          },
          answerTab: {
            margin: [15, 0, 0, 0],
          },
          questionTab: {
            margin: [5, 0, 0, 0],
          },
          resultKey: {
            bold: true,
            fontSize: 15,
          },
          resultValueLow: {
            bold: true,
            fontSize: 15,
            color: RiskLowColorKey,
          },
          resultValueMid: {
            bold: true,
            fontSize: 15,
            color: RiskMidColorKey,
          },
          resultValueHigh: {
            bold: true,
            fontSize: 15,
            color: RiskHighColorKey,
          },
        },
        header: {
          margin: [20, 10, 20, 0],
          stack: [
            {
              columns: [
                {
                  // if you specify both width and height - image will be stretched
                  image: "logochula_dent",
                  width: 140,
                  alignment: "left",
                },
                {
                  stack: [
                    { text: "CHULALONGKORN UNIVERSITY, FACULTY OF DENTISTRY", style: "facultyHeader", alignment: "center" },
                    { text: props.divisionName || "", style: "clinicHeader", alignment: "center" },
                  ],
                  fontSize: 15,
                },
                { text: "", width: 70, style: "facultyHeader", alignment: "right" },
              ],
            },
            {
              columns: [
                [
                  {
                    columns: [
                      {
                        // auto-sized columns have their widths based on their content
                        width: "20%",
                        text: [
                          { text: "HN: ", style: "fieldKey" },
                          { text: props.hn, style: "fieldValue" },
                        ],
                      },
                      {
                        // star-sized columns fill the remaining space
                        // if there's more than one star-column, available width is divided equally
                        width: "*",
                        text: [
                          { text: "Patient Name: ", style: "fieldKey" },
                          { text: props.patient_name, style: "fieldValue" },
                        ],
                      },
                      {
                        // star-sized columns fill the remaining space
                        // if there's more than one star-column, available width is divided equally
                        width: "30%",
                        text: [
                          { text: "ว/ด/ป เกิด ", style: "fieldKey" },
                          { text: props.patient_birthdate, style: "fieldValue" },
                        ],
                      },
                    ],
                    columnGap: 5,
                  },

                  {
                    columns: [
                      {
                        // auto-sized columns have their widths based on their content
                        width: "20%",
                        text: [
                          { text: "อาชีพ: ", style: "fieldKey" },
                          { text: props.career, style: "fieldValue" },
                        ],
                      },
                      {
                        // star-sized columns fill the remaining space
                        // if there's more than one star-column, available width is divided equally
                        width: "*",
                        text: [
                          { text: "Tel: ", style: "fieldKey" },
                          { text: props.tel_mobile, style: "fieldValue" },
                        ],
                      },
                      {
                        // star-sized columns fill the remaining space
                        // if there's more than one star-column, available width is divided equally
                        width: "30%",
                        text: [
                          { text: " Doctor: ", style: "fieldKey" },
                          { text: props.doctor_name, style: "fieldValue" },
                        ],
                      },
                    ],
                    // optional space between columns
                    columnGap: 5,
                  },

                  {
                    columns: [
                      {
                        // auto-sized columns have their widths based on their content
                        width: "40%",
                        text: [
                          { text: "โรคประจำตัว: ", style: "fieldKey" },
                          { text: props.congenital_disease, style: "fieldValue" },
                        ],
                      },
                      {
                        // star-sized columns fill the remaining space
                        // if there's more than one star-column, available width is divided equally
                        width: "*",
                        text: [
                          { text: "ประเมินความเสี่ยงระบบทางเดินหายใจ: ", style: "fieldKey" },
                          { text: props.is_respiratory_disease, style: "fieldValue" },
                        ],
                      },
                    ],
                    // optional space between columns
                    columnGap: 5,
                  },
                ],

                {
                  // under NodeJS (or in case you use virtual file system provided by pdfmake)
                  // you can also pass file names here

                  // image: "userProfile",
                  text: "",
                  width: 50,
                  height: 55,
                  alignment: "center",
                },
              ],
              // optional space between columns
              columnGap: 5,
            },

            {
              columns: [
                {
                  // auto-sized columns have their widths based on their content
                  width: "*",
                  text: [
                    { text: "แพ้ยา: ", style: "fieldKey" },
                    { text: props.drugAllergy ? props.drugAllergy : "-", style: "fieldValue" },
                  ],
                },
                {
                  // auto-sized columns have their widths based on their content
                  width: "*",
                  text: [
                    { text: "แพ้อาหาร: ", style: "fieldKey" },
                    { text: props.foodAllergy ? props.foodAllergy : "-", style: "fieldValue" },
                  ],
                },
                {
                  // auto-sized columns have their widths based on their content
                  width: "*",
                  text: [
                    { text: "sex: ", style: "fieldKey" },
                    { text: props.gender_name, style: "fieldValue" },
                  ],
                },
                {
                  // auto-sized columns have their widths based on their content
                  width: "*",
                  text: [
                    { text: "Age: ", style: "fieldKey" },
                    { text: props.full_age, style: "fieldValue" },
                  ],
                },
              ],
              // optional space between columns
              columnGap: 10,
            },
            {
              columns: [
                {
                  // star-sized columns fill the remaining space
                  // if there's more than one star-column, available width is divided equally
                  width: "*",
                  text: [
                    { text: "สภาพผู้ป่วยแรกรับ: ", style: "fieldKey" },
                    { text: props.arrive_status_label ? props.arrive_status_label : "-", style: "fieldValue" },
                  ],
                },
                {
                  // auto-sized columns have their widths based on their content
                  width: "*",
                  text: [
                    { text: "ระดับ: ", style: "fieldKey" },
                    { text: props.triage_level ? props.arrive_status_label : "-", style: "fieldValue" },
                  ],
                },
              ],
              columnGap: 10
            },
            {
              columns: [
                {
                  // auto-sized columns have their widths based on their content
                  width: "36.5%",
                  text: [
                    { text: "ภาวะผู้ป่วยที่ควรเฝ้าระวัง: ", style: "fieldKey" },
                    { text: props.cautions, style: "fieldValue" },
                  ],
                },
                {
                  // auto-sized columns have their widths based on their content
                  width: "auto",
                  text: [
                    { text: "ประวัติการตั้งครรภ์: ", style: "fieldKey" },
                    { text: props.pregnancy_breastfeeding, style: "fieldValue" },
                  ],
                },
                {
                  // auto-sized columns have their widths based on their content
                  width: "*",
                  text: props.created_utc,
                  alignment: "right",
                  style: "fieldValue",
                },
              ],
              // optional space between columns
              columnGap: 10,
            },
            {
              columns: [
                {
                  // auto-sized columns have their widths based on their content
                  width: "36%",
                  text: [
                    { text: "ยาที่ใช้ประจำ: ", style: "fieldKey" },
                    { text: props.medication, style: "fieldValue" },
                  ],
                },
                {
                  // auto-sized columns have their widths based on their content
                  width: "auto",
                  text: [
                    { text: "ประวัติการใช้สารเสพติด: ", style: "fieldKey" },
                    { text: props.narcotic, style: "fieldValue" },
                  ],
                },
              ],
              // optional space between columns
              columnGap: 5,
            },
            {
              style: "tableNormal",
              table: {
                widths: ["15%", "15%", "15%", "20%", "25%"],
                body: [
                  [
                    {
                      style: "tableHeader",
                      stack: [
                        { text: "Body Temps.", style: "fieldKey" },
                        { text: props.temp, color: props.tempColor, alignment: "center", style: "fieldValue" },
                      ],
                    },
                    {
                      style: "tableHeader",
                      stack: [
                        { text: "Pulse", style: "fieldKey" },
                        { text: props.pulse, color: "black", alignment: "center", style: "fieldValue" },
                      ],
                    },
                    {
                      style: "tableHeader",
                      stack: [
                        { text: "Blood pressure", style: "fieldKey" },
                        { text: props.bp, color: "black", alignment: "center", style: "fieldValue" },
                      ],
                    },
                    {
                      style: "tableHeader",
                      stack: [
                        { text: "น้ำหนัก / ส่วนสูง", style: "fieldKey" },
                        { text: props.wh, alignment: "center", style: "fieldValue" },
                      ],
                    },
                    {
                      style: "tableHeader",
                      stack: [
                        { text: "ผู้ประเมิน ", style: "fieldKey" },
                        { text: props.vitalCreated, style: "fieldValue" },
                      ],
                    },
                  ],
                ],
              },
            },
          ],
        },
        content: [
          {
            style: "tableNormal",
            table: {
              widths: ["100%"],
              body: [[{ text: "Nurse's note", style: "tableHeaderNurseNote", alignment: "left", fontSize: 16, border: [false, false, false, false] }]],
            },
          },
          {
            style: "tableNormal",
            table: {
              widths: ["40%", "60%"],
              headerRows: 1,
              // keepWithHeaderRows: 1,
              body: [
                [
                  { text: "Focus", style: "tableHeaderNurseNote", alignment: "center" },
                  {
                    stack: [
                      { text: "Progress Note", style: "fieldKey", alignment: "center" },
                      { text: "A:Assessment I:Intervention E:Evaluation", style: "fieldKey", alignment: "center" },
                    ],
                    style: "tableHeaderNurseNote",
                    alignment: "center",
                  },
                ],
                [
                  { text: props.selectedNurseNote?.focus, alignment: "left", margin: [0, 5, 0, 5] },
                  // { text: "ผู้บันทึก admin 08/11/2536 11", alignment: "left", margin: [0, 5, 0, 5] },
                  {
                    columns: [
                      [
                        {
                          columns: [
                            {
                              width: "4%",
                              text: [{ text: "A: ", style: "fieldKey" }],
                            },
                            {
                              width: "*",
                              text: [
                                {
                                  text: props.selectedNurseNote?.diagnosis,
                                  style: "fieldValue",
                                  preserveLeadingSpaces: true
                                },
                              ],
                            },
                          ],
                          columnGap: 5,
                        },

                        {
                          columns: [
                            {
                              width: "4%",
                              text: [{ text: "I: ", style: "fieldKey" }],
                            },
                            {
                              width: "*",
                              text: [
                                {
                                  text: props.selectedNurseNote?.plan,
                                  style: "fieldValue",
                                  preserveLeadingSpaces: true
                                },
                              ],
                            },
                          ],
                          // optional space between columns
                          columnGap: 5,
                        },

                        {
                          columns: [
                            {
                              width: "4%",
                              text: [{ text: "E: ", style: "fieldKey" }],
                            },
                            {
                              width: "*",
                              text: [
                                {
                                  text: props.selectedNurseNote?.goal,
                                  style: "fieldValue",
                                  preserveLeadingSpaces: true
                                },
                              ],
                            },
                          ],
                          // optional space between columns
                          columnGap: 5,
                        },
                      ],
                    ],
                    // optional space between columns
                    columnGap: 5,
                  },
                ],
                [
                  {
                    colSpan: 2,
                    columns: [
                      {
                        width: "10%",
                        text: [{ text: "ผู้บันทึก ", style: "fieldKey" }],
                      },
                      {
                        width: "*",
                        text: [
                          {
                            text: props.selectedNurseNote?.edit_user + " " + props.selectedNurseNote?.formatted_date,
                            style: "fieldValue",
                          },
                        ],
                      },
                    ],
                    // optional space between columns
                    columnGap: 5,
                  },
                ],
                // [{ text: "ผู้บันทึก " + props.selectedNurseNote?.edit_user + " " + props.selectedNurseNote?.formatted_date, colSpan: 2, alignment: "left", margin: [0, 5, 0, 5] }],
              ],
            },
          },
        ],
        images: {
          logochula_dent: origin + "/static/images/logochula_dent.jpg",
        },
      };

      // var win = window.open("", "_blank");
      // pdfMake.createPdf(docDefinition).open({}, win);
      console.log(" Start PDF Create ");
      // pdfMake.createPdf(docDefinition).download(props.hn + "-" + props.created_utc + ".pdf");
      const pdfDoc = await PDFDocument.create();

      // pdfMake.createPdf(docDefinition).open();
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.getDataUrl(async (dataUrl) => {
        const doc = await PDFDocument.load(dataUrl);
        const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());

        const url = "/static/fonts/THSarabunNew-Bold.ttf";
        pdfDoc.registerFontkit(fontkit);
        const fontBytes = await fetch(url).then((res) => res.arrayBuffer());

        const timesRomanFont = await pdfDoc.embedFont(fontBytes);
        const currentDate = new Date();
        const year = (currentDate.getFullYear() + 543).toString().padStart(4, "0");
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const day = currentDate.getDate().toString().padStart(2, "0");
        const hour = currentDate.getHours().toString().padStart(2, "0");
        const min = currentDate.getMinutes().toString().padStart(2, "0");
        const stringDateTimeWaterMark = day + "/" + month + "/" + year + " " + hour + ":" + min;
        copiedPages.forEach((page) => {
          const pageDraw = pdfDoc.addPage(page);
          pageDraw.drawText(
            "เอกสารควบคุม ห้ามเผยแพร่โดยไม่ได้รับอนุญาต \n คณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย \n เปิดดูโดย (" +
            props.author +
            " " +
            stringDateTimeWaterMark +
            ")",
            {
              font: timesRomanFont,
              x: 135,
              y: 400,
              size: 25,
              lineHeight: 30,
              opacity: 0.2,
              rotate: degrees(30),
            }
          );
        });
        pdfDoc.setTitle(props.patient_name);
        pdfDoc.setAuthor(props.author);
        pdfDoc.setSubject("Visit Report");
        pdfDoc.setKeywords(["Examination Treatment"]);

        const base64Data = await pdfDoc.saveAsBase64();

        const blob = base64toBlob("data:application/pdf;base64," + base64Data);
        const bloburl = URL.createObjectURL(blob);
        window.open(bloburl);
      });
    });
  });
};
