import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  MutableRefObject,
} from "react";
import CardDrugOrder from "react-lib/apps/HISV3/TPD/CardDrugOrder";
import { Button, Divider, Icon, Modal } from "semantic-ui-react";
import CardAppointmentDetailUX from "./CardAppointmentDetailUX";
import CardTreatmentOrder from "react-lib/apps/HISV3/DPO/CardTreatmentOrder";
import CardLabRequest from "react-lib/apps/HISV3/LAB/CardLabRequest";
import CardImagingRequest from "react-lib/apps/Scheduling/CardImagingRequest";
import SnackMessage from "../common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { PreventClickEvent } from "../HISV3/ORM/CardORRequest";
const DOCTOR_ORDER_FILTER_UNSHOWN = ["operatingorder", "drugoperatingorder", "admitorder", "anesthesiaorder"]

const CardOtherOrderAppointment = (props: any) => {
  const CARD_OTHER_APPOINTMENT = "CardOtherOrderAppointment";
  const [estimatedAt, setEstimatedAt] = useState("");
  const [estimatedDuration, setEstimatedDuration] = useState("");
  // const [openModDoctorNote, setOpenModDoctorNote] = useState<boolean>(false);
  const [orderNote, setOrderNote] = useState("");
  const [full, setFull] = useState(false);
  const [openModMessage, setOpenModMessage] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [questionDetail, setQuestionDetail] = useState<any>(null);
  const [isEditable, setIsEditable] = useState<any>(null);
  // const [manualTime, setManualTime] = useState(false);
  // const [blockAppointments, setBlockAppointments] = useState<any[]>([]);
  const [selectedWaitingListItem, setSelectedWaitingListItem] =
    useState<any>(null);
  const [openModOrder, setOpenModOrder] = useState<{
    id?: number;
    type?: string;
  } | null>(null);

  const [complete, setComplete] = useState<string>("");

  const otherRef = useRef() as MutableRefObject<HTMLDivElement>;

  const handleSetOrderNote = useCallback((e: SyntheticEvent, data: any) => {
    setOrderNote(data?.value?.toString());
  }, []);


  // useEffect(() => {
  //   first
  // }, [third])

  // console.log("props.drugOperatingOrderId: ", props.drugOperatingOrderId)

  useEffect(() => {
    setIsEditable((!props.config || props.config.isEditable === undefined)? true : props.config?.isEditable)
  }, [props.config])

  const editable = useMemo(() => {
    const isEdit: boolean = props.ORRequestSequence?.allowed_action?.includes(
      "EDIT_REQUEST"
    );

    return isEdit;
  }, [otherRef, props.ORRequestSequence?.allowed_action]);

  useEffect(() => {
    if (otherRef.current && props.ORRequestSequence?.allowed_action) {
      PreventClickEvent(editable, otherRef.current);
    }
  }, [editable]);

  useEffect(() => {
    const disabled = props.isEditable === undefined ? false : !props.isEditable;

    if (!disabled && editable && props.defaultModOrder) {
      handleOpenModOrder(null, { name: props.defaultModOrder });
      props.setModOrder?.("")
    }
  }, [editable, isEditable, props.defaultModOrder]);

  const handleSaveAppointment = (e: any) => {
    console.log(
      "handleSaveAppointment props.selectedAppointment: ",
      props.selectedAppointment
    );
    console.log('handleSaveAppointment COOA estimatedAt: ', estimatedAt);

    if (props.selectedAppointment?.orders?.find((item: any) => item?.type === "operatingorder" )) {
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "SaveAppointment",
          estimatedAt: estimatedAt,
          estimatedDuration: estimatedDuration,
          orderNote: orderNote,
          full: full,
          isAppointment: props.selectedOperatingOption? props.selectedOperatingOption === "EL" : true,
          card: CARD_OTHER_APPOINTMENT,
        },
      });
    } else {
      // toggle
      console.log("handleSaveAppointment setTab or_request");
      props.setTab("or_request");
    }
  };

  const handleOpenModOrder = (e: any, v: any) => {
    setOpenModOrder({ type: v.name });
  };
  const handleCloseModOrder = async () => {
    // console.log("handleCloseModOrder: ", openModOrder?.type)
    if (openModOrder?.type === "IMAGING") {
      props.onEvent({
        message: "ImagingRequestOrder",
        params: {
          action: "clear_data",
        },
      });
    }
    // console.log("handleCloseModOrder2: ", openModOrder?.type)
    setOpenModOrder(null);

    await props.setProp("errorMessage", {
      ...props.errorMessage,
      [CARD_OTHER_APPOINTMENT]: null,
      ["OrderCentralLab"]: null,
    });
    // console.log("handleCloseModOrder3: ", openModOrder?.type)
  };

  const handleOnCancelAppointment = (e: any) => {
    console.log("handleOnCancelAppointment ");
    setOpenModMessage(true);
    setOptions(cancelOptions);
    setQuestionDetail({
      type: "cancel",
      title: "ต้องการยกเลิกนัดหมายจากแพทย์ใช่หรือไม่",
      subTitle: "",
      questionDetail: "ระบุเหตุผลในการยกเลิก",
      titleColor: "red",
      userEditQuestion: "ระบุชื่อผู้บันทึกข้อมูล",
      buttonLeftLabel: "ใช่",
      buttonRightLabel: "ไม่",
      buttonLeftColor: "green",
      buttonRightColor: "red",
      isUserEditQuesion: true,
      isRequiredQuestionDetail: true,
    });
  };

  const handleOnCancelAppointmentByPatient = (e: any) => {
    console.log("handleOnCancelAppointment ");
    setOpenModMessage(true);
    setOptions(cancelOptions);
    setQuestionDetail({
      type: "cancelbypatient",
      title: "ต้องการยกเลิกนัดหมายจากจากผู้ป่วยใช่หรือไม่",
      subTitle: "",
      questionDetail: "ระบุเหตุผลในการยกเลิก",
      titleColor: "red",
      userEditQuestion: "ระบุชื่อผู้บันทึกข้อมูล",
      buttonLeftLabel: "ใช่",
      buttonRightLabel: "ไม่",
      buttonLeftColor: "green",
      buttonRightColor: "red",
      isUserEditQuesion: true,
      isRequiredQuestionDetail: true,
    });
  };

  const handleOnPostponeAppointment = (e: any) => {
    console.log("handleOnPostponeAppointment ");
    setOpenModMessage(true);
    setOptions(postponeOptions);
    setQuestionDetail({
      type: "postpone",
      title: "ต้องการเลื่อนนัดผู้ป่วยใช่หรือไม่",
      subTitle:
        "กรณีที่เลื่อนนัดผู้ป่วยเจ้าหน้าที่จะต้องทำการติดนามนัดหมายผู้ป่วยใหม่",
      questionDetail: "ระบุเหตุผลเลื่อนนัดผู้ป่วย",
      isRequiredQuestionDetail: true,
      titleColor: "orange",
      userEditQuestion: "ระบุชื่อผู้บันทึกข้อมูล",
      isUserEditQuesion: true,
      buttonLeftLabel: "ใช่",
      buttonRightLabel: "ไม่",
      buttonLeftColor: "green",
      buttonRightColor: "red",
    });
    //
  };

  const handlePrintAppointment = (doctorNote: any) => {
    // console.log('handlePrintAppointment doctorNote: ', doctorNote);
    // console.log('handlePrintAppointment selectedWaitingListItem: ', selectedWaitingListItem);
    // console.log('handlePrintAppointment orderNote: ', orderNote);
    props.onEvent({
      message: "PrintScheduling",
      params: {
        form: "FormAppointmentDetail",
        data: {
          ...props.selectedAppointment,
          doctor_note: orderNote?.match(/^.*[^\n\r]/gm) || "",
          description: selectedWaitingListItem?.note || "",
        },
      },
    });
  };

  // Ishealth-v3 port into CUDent
  const filterPreOrderList = useMemo(() => {
    console.log("filterPreOrderList worker", props.preOrderList)
    return (props.preOrderList || [])
      ?.filter((item: any) => !(DOCTOR_ORDER_FILTER_UNSHOWN.includes(item.type)))
      // ?.filter((item: any) => item.type !== "operatingorder")
      // ?.filter((item: any) => item.type !== "drugoperatingorder")
      // ?.filter((item: any) => item.type !== "admitorder")
      // ?.filter((item: any) => item.type !== "anesthesiaorder")
      ?.map((item: any) => ({
        ...item,
        order_detail: (
          <div
            dangerouslySetInnerHTML={{
              __html: [
                "treatmentorder",
                "centrallaborder",
                "imagingorder",
              ].includes(item.type)
                ? item.summary_detail ||
                  item.order_summary_detail ||
                  item.order_name ||
                  item.name
                : "",
            }}
          />
        ),
        specific_type_name:
          item.type === "treatmentorder"
            ? "Treatment"
            : item.type === "centrallaborder"
            ? "Central Lab"
            : item.type === "imagingorder"
            ? "Imaging"
            : item.type,
        del: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="red"
              icon="minus"
              disabled={!isEditable}
              onClick={(e) => {
                props.onEvent({
                  message: "HandleActionPreOrderList",
                  params: {
                    action: "delete",
                    id: item.id,
                  },
                });
              }}
              size={"mini"}
            ></Button>
          </div>
        ),
        edit: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="blue"
              icon="edit"
              disabled={!isEditable}
              onClick={(e) => {
                setOpenModOrder({
                  id: item.id,
                  type:
                    item.type === "treatmentorder"
                      ? "TREATMENT"
                      : item.type === "centrallaborder"
                      ? "CENTRAL_LAB"
                      : item.type === "imagingorder"
                      ? "IMAGING"
                      : "",
                });
              }}
              size={"mini"}
            ></Button>
          </div>
        ),
      }));
  }, [props.preOrderList, isEditable]);

  const postponeOptions = useMemo(
    () => [
      {
        key: "คลินิกปิดทำการ",
        text: "คลินิกปิดทำการ",
        value: "คลินิกปิดทำการ",
      },
      { key: "ทันตแพทย์ลา", text: "ทันตแพทย์ลา", value: "ทันตแพทย์ลา" },
      {
        key: "มีปัญหาเรื่องค่าใช้จ่าย",
        text: "มีปัญหาเรื่องค่าใช้จ่าย",
        value: "มีปัญหาเรื่องค่าใช้จ่าย",
      },
      {
        key: "ส่งเตรียมช่องปากก่อน",
        text: "ส่งเตรียมช่องปากก่อน",
        value: "ส่งเตรียมช่องปากก่อน",
      },
      {
        key: "บันทึกข้อมูลผิด",
        text: "บันทึกข้อมูลผิด",
        value: "บันทึกข้อมูลผิด",
      },
      {
        key: "ทพ.ไม่ให้ลงนัด ให้มานั่งรอวันที่มีคลินิกเท่านั้น",
        text: "ทพ.ไม่ให้ลงนัด ให้มานั่งรอวันที่มีคลินิกเท่านั้น",
        value: "ทพ.ไม่ให้ลงนัด ให้มานั่งรอวันที่มีคลินิกเท่านั้น",
      },
      {
        key: "ส่งรักษาโรคประจำตัว",
        text: "ส่งรักษาโรคประจำตัว",
        value: "ส่งรักษาโรคประจำตัว",
      },
      {
        key: "คนไข้มีโรคประจำตัว/ป่วย",
        text: "คนไข้มีโรคประจำตัว/ป่วย",
        value: "คนไข้มีโรคประจำตัว/ป่วย",
      },
      {
        key: "สิทธิ์การรักษาไม่เรียบร้อย",
        text: "สิทธิ์การรักษาไม่เรียบร้อย",
        value: "สิทธิ์การรักษาไม่เรียบร้อย",
      },
      {
        key: "ผู้ป่วยติดธุระ ไม่สะดวกวันนัดเดิม",
        text: "ผู้ป่วยติดธุระ ไม่สะดวกวันนัดเดิม",
        value: "ผู้ป่วยติดธุระ ไม่สะดวกวันนัดเดิม",
      },
      {
        key: "ยาที่จ่ายหมดก่อนวันนัดเดิม",
        text: "ยาที่จ่ายหมดก่อนวันนัดเดิม",
        value: "ยาที่จ่ายหมดก่อนวันนัดเดิม",
      },
      {
        key: "ผู้ป่วยมาสายทันตแพทย์ให้นัดหมาย",
        text: "ผู้ป่วยมาสายทันตแพทย์ให้นัดหมาย",
        value: "ผู้ป่วยมาสายทันตแพทย์ให้นัดหมาย",
      },
      {
        key: "Lab/อุปกรณ์ไม่พร้อม",
        text: "Lab/อุปกรณ์ไม่พร้อม",
        value: "Lab/อุปกรณ์ไม่พร้อม",
      },
      {
        key: "เปลี่ยนแผนการรักษา",
        text: "เปลี่ยนแผนการรักษา",
        value: "เปลี่ยนแผนการรักษา",
      },
      {
        key: "สถานการณ์ Covid 19",
        text: "สถานการณ์ Covid 19",
        value: "สถานการณ์ Covid 19",
      },
    ],
    []
  );

  const cancelOptions = useMemo(
    () => [
      { key: "ทันตแพทย์ลา", text: "ทันตแพทย์ลา", value: "ทันตแพทย์ลา" },
      {
        key: "ผิดนัดหลายครั้ง ให้นัดทันตแพทย์ท่านอื่น",
        text: "ผิดนัดหลายครั้ง ให้นัดทันตแพทย์ท่านอื่น",
        value: "ผิดนัดหลายครั้ง ให้นัดทันตแพทย์ท่านอื่น",
      },
      {
        key: "ผู้ป่วยขอพักคิว",
        text: "ผู้ป่วยขอพักคิว",
        value: "ผู้ป่วยขอพักคิว",
      },
      {
        key: "ผู้ป่วนเสียชีวิต",
        text: "ผู้ป่วนเสียชีวิต",
        value: "ผู้ป่วนเสียชีวิต",
      },
      {
        key: "ติดต่อผู้ป่วยไม่ได้",
        text: "ติดต่อผู้ป่วยไม่ได้",
        value: "ติดต่อผู้ป่วยไม่ได้",
      },
      {
        key: "มีปัญหาเรื่องค่าใช้จ่าย",
        text: "มีปัญหาเรื่องค่าใช้จ่าย",
        value: "มีปัญหาเรื่องค่าใช้จ่าย",
      },
      {
        key: "รอคิวนานเกินไป",
        text: "รอคิวนานเกินไป",
        value: "รอคิวนานเกินไป",
      },
      {
        key: "ผู้ป่วยไม่มีอาการแล้ว",
        text: "ผู้ป่วยไม่มีอาการแล้ว",
        value: "ผู้ป่วยไม่มีอาการแล้ว",
      },
      {
        key: "ส่งเตรียมช่องปากก่อน",
        text: "ส่งเตรียมช่องปากก่อน",
        value: "ส่งเตรียมช่องปากก่อน",
      },
      {
        key: "ผู้ป่วยทำการักษาที่อื่น",
        text: "ผู้ป่วยทำการักษาที่อื่น",
        value: "ผู้ป่วยทำการักษาที่อื่น",
      },
      {
        key: "บันทึกข้อมูลผิด",
        text: "บันทึกข้อมูลผิด",
        value: "บันทึกข้อมูลผิด",
      },
      {
        key: "คนไข้มีโรคประจำตัว",
        text: "คนไข้มีโรคประจำตัว",
        value: "คนไข้มีโรคประจำตัว",
      },
      {
        key: "ทพ.ไม่ให้ลงนัด ให้มานั่งรอวันที่มีคลินิกเท่านั้น",
        text: "ทพ.ไม่ให้ลงนัด ให้มานั่งรอวันที่มีคลินิกเท่านั้น",
        value: "ทพ.ไม่ให้ลงนัด ให้มานั่งรอวันที่มีคลินิกเท่านั้น",
      },
      {
        key: "คนไข้มารักษาก่อนวันนัด",
        text: "คนไข้มารักษาก่อนวันนัด",
        value: "คนไข้มารักษาก่อนวันนัด",
      },
      {
        key: "เปลี่ยนแผนการรักษา",
        text: "เปลี่ยนแผนการรักษา",
        value: "เปลี่ยนแผนการรักษา",
      },
      {
        key: "ผู้ป่วยขอพักคิว",
        text: "ผู้ป่วยขอพักคิว",
        value: "ผู้ป่วยขอพักคิว",
      },
      {
        key: "รอคิวนานเกินไป",
        text: "รอคิวนานเกินไป",
        value: "รอคิวนานเกินไป",
      },
      {
        key: "ผู้ป่วยไม่มีอาการแล้ว",
        text: "ผู้ป่วยไม่มีอาการแล้ว",
        value: "ผู้ป่วยไม่มีอาการแล้ว",
      },
      {
        key: "ผู้ป่วยทำการรักษาที่อื่น",
        text: "ผู้ป่วยทำการรักษาที่อื่น",
        value: "ผู้ป่วยทำการรักษาที่อื่น",
      },
      {
        key: "สถานการณ์ Covid 19",
        text: "สถานการณ์ Covid 19",
        value: "สถานการณ์ Covid 19",
      },
      {
        key: "ผู้ป่วยโทรมายกเลิกนัด Recall Oper",
        text: "ผู้ป่วยโทรมายกเลิกนัด Recall Oper",
        value: "ผู้ป่วยโทรมายกเลิกนัด Recall Oper",
      },
      {
        key: "ผู้ป่วยโทรมายกเลิกนัด Recall endo",
        text: "ผู้ป่วยโทรมายกเลิกนัด Recall endo",
        value: "ผู้ป่วยโทรมายกเลิกนัด Recall endo",
      },
      {
        key: "ผู้ป่วยโทรมายกเลิกนัดตรวจ",
        text: "ผู้ป่วยโทรมายกเลิกนัดตรวจ",
        value: "ผู้ป่วยโทรมายกเลิกนัดตรวจ",
      },
      {
        key: "ผิดนัดหลายครั้ง ให้ตรวจใหม่",
        text: "ผิดนัดหลายครั้ง ให้ตรวจใหม่",
        value: "ผิดนัดหลายครั้ง ให้ตรวจใหม่",
      },
    ],
    []
  );

  console.log("CardOtherOrderAppointment props", props, filterPreOrderList);
  // console.log("CardOtherOrderAppointment, props.drugOrder", props.drugOrder);
  // console.log('CardOtherOrderAppointment props.preOrderList?.find((item: any) => item.type === "drugoperatingorder")?.id: ', props.preOrderList?.find((item: any) => item.type === "drugoperatingorder")?.id);
  return (
    <div ref={otherRef}>
      <CardDrugOrder
        disabledConfirmedOrderBtn={true}
        hiddenDrugList={true}
        disabledVerbalMedOrder={true}
        // drugOperatingOrderId={props.preOrderList?.filter((item: any) => item.type === "drugoperatingorder")?.map((item: any) => item.id)?.join(",")}

        preOrderList={props.preOrderList}
        orderTypes={["OPERATING"]}
        title={"ยาสำหรับผ่าตัด"}
        selectedAppointment={props.selectedAppointment}
        onEvent={props.onEvent}
        setProp={props.setProp}
        runSequence={props.runSequence}
        controller={props.controller}
        errorMessage={props.errorMessage}
        successMessage={props.successMessage}
        selectedEncounter={props.selectedEncounter}
        selectedEmr={props.selectedEmr}
        selectedProgressCycle={props.selectedProgressCycle}
        // selectedDrugItem to edit
        selectedDrugItemDetail={props.selectedDrugItemDetail}
        selectedDrugItemDetailOptions={props.selectedDrugItemDetailOptions}
        drugSearchText={props.drugSearchText}
        drugSearchResult={props.drugSearchResult}
        DrugSelectSequence={props.DrugSelectSequence}
        selectedDrug={props.selectedDrug}
        drugOrderList={props.drugOrderList}
        drugOrder={props.drugOrder}
        renderDrugOrderItems={props.renderDrugOrderItems}
        drugDose={props.drugDose}
        drugQty={props.drugQty}
        drugDuration={props.drugDuration}
        drugDescription={props.drugDescription}
        drugPrn={props.drugPrn}
        // modal doctor certificate check
        modDoctorCertificate={props.modDoctorCertificate}
        onCertificateApprove={handleSaveAppointment}
        modDrugLabInteraction={props.modDrugLabInteraction}
        modDrugDisease={props.modDrugDisease}
        modDrugInteraction={props.modDrugInteraction}
        currentDoctor={props.currentDoctor}
        // modal drug order template
        DrugOrderTemplateSequence={props.DrugOrderTemplateSequence}
        modReMedication={props.modReMedication}
        modConfirmDeleteDrug={props.modConfirmDeleteDrug}
        drugOrderItemEstimate={props.drugOrderItemEstimate}
        drugPermission={props.drugPermission}
        searchedItemList={props.searchedItemList}
        drugOrderItemMasterData={{
          masterUnits: props.masterOptions.unit,
          masterRoutes: props.masterOptions.route,
          masterSites: props.masterOptions.site,
          masterFrequencies: props.masterOptions.frequency,
          masterMethods: props.masterOptions.method,
        }}
        duplicateReasonsOptions={props.masterOptions.duplicateReasons}
        divisionForOrderDivOptions={props.masterOptions.divisionForOrderDiv}
        // SolventSelectionConfig
        modSolventSelectionConfig={props.modSolventSelectionConfig}
        selectedVerbalDrugOrderWorking={props.selectedVerbalDrugOrderWorking}
        // isEditable={isEditable}
        isEditable={true} // case hidden drug list
        defaultOrderBy={props.defaultOrderBy}
      />
      <CardAppointmentDetailUX
        showOrderAppointment={true}
        hideGeneralDetail={true}
        useNoteTemplate={true}
        isEditable={isEditable}
        hideButtonSave={!editable}
        hidePrintAppointmentDoc={props.hidePrintAppointmentDoc}
        // ไม่ใช้
        // treatmentType={props.selectedAppointment?.type}
        // onChangeTreatmentType={(e: any, data: any) => {
        //   props.setProp("selectedAppointment.type", data.value);
        // }}
        // treatmentTypeOptions={props.patientAppType}

        // onChangeSelectedAppointmentDate={(dateString: any) => {
        //   props.setProp("selectedAppointment.date", dateString);
        // }}
        // onTimeAppointmentChange={(e: any, data: any) => {
        //   setEstimatedAt(data);
        // }}
        // setEstimatedAt={setEstimatedAt}

        // onAddNote={() => setOpenModDoctorNote(true)}
        // allergy={props.selectedAppointment?.extra?.allergy || ""}
        // onAllergyChange={(e: any, data: any) => {
        //   props.setProp("selectedAppointment.extra.allergy", data.value);
        // }}
        // premedication={
        //   props.selectedAppointment?.extra?.premedication || ""
        // }
        // onPreMedicationChange={(e: any, data: any) => {
        //   props.setProp(
        //     "selectedAppointment.extra.premedication",
        //     data.value
        //   );
        // }}
        // orderLab={props.selectedAppointment?.extra?.orderLab || ""}
        // onOrderLabChange={(e: any, data: any) => {
        //   props.setProp("selectedAppointment.extra.orderLab", data.value);
        // }}
        // orderXray={props.selectedAppointment?.extra?.orderXray || ""}
        // onOrderXrayChange={(e: any, data: any) => {
        //   props.setProp("selectedAppointment.extra.orderXray", data.value);
        // }}
        divider={<Divider />}
        // selectedPatient={props.selectedPatient}
        // selectedAppointment={props.selectedAppointment}
        // minHour={parseInt(props.selectedAppointment?.start)}
        // maxHour={parseInt(props.selectedAppointment?.end)}
        // estimatedAt={estimatedAt || ""}
        // estimatedDuration={estimatedDuration || ""}
        // intervalTimeSelected={
        //   props.selectedAppointment?.slotLength ||
        //   props.selectedAppointment?.estimated_duration
        // }
        // setEstimatedDuration={setEstimatedDuration}
        // orderNote={orderNote || ""}
        // setOrderNote={handleSetOrderNote}
        // full={full}
        // toggleFull={() => setFull(!full)}

        config={{
          ...props.config,
          hideDoctorCancel: true,
          hidePatientCancel: true,
          disabledPostpone: true,
        }}
        onSaveAppointment={handleSaveAppointment}
        onCancelAppointment={handleOnCancelAppointment}
        onCancelAppointmentByPatient={handleOnCancelAppointmentByPatient}
        onPostponeAppointment={handleOnPostponeAppointment}
        // availableOptions={props.availableSlots}
        // reservedText={"(มีนัดหมาย)"}
        // manualTime={manualTime}
        // blockAppointments={blockAppointments}
        // setManualTime={setManualTime}
        printAppointment={handlePrintAppointment}
        // Ishealth-v3 port into CUDent ---v
        onClickOrder={handleOpenModOrder}
        preOrderList={filterPreOrderList}
        activeOrder={openModOrder?.type || ""}
        // flag to show appointment order
        isLoading={!!props.loadingStatus?.[CARD_OTHER_APPOINTMENT]}
        save={complete ? <Icon name={complete as any}></Icon> : "บันทึก"}
        hideDrugAllergy={true}
      />
      <Modal
        open={openModOrder?.type === "TREATMENT"}
        onClose={handleCloseModOrder}
        closeOnDimmerClick
        style={{ width: "70%" }}
      >
        <CardTreatmentOrder
          titleName="Treatment Request"
          setProp={props.setProp}
          onEvent={props.onEvent}
          runSequence={props.runSequence}
          claimOptions={props.masterOptions.claimTreatment}
          OrderTreatmentSequence={props.OrderTreatmentSequence}
          searchedItemList={props.searchedItemList}
          treatmentOrderList={props.treatmentOrderList}
          successMessage={props.successMessage}
          errorMessage={props.errorMessage}
          procedureItems={props.procedureItems}
          secondaryItems={props.secondaryItems}
          treatmentTemplateList={props.treatmentTemplateList}
          hidePrincipalDiagnosis={true}
          hideSecondaryDiagnosis={true}
          encounterId={props.selectedAppointment?.order_encounter || null}
          save="SAVE"
          isAppointment={
            props.selectedOperatingOption
              ? props.selectedOperatingOption === "EL"
              : true
          }
          isNullEmr={true}
          checkOutPerformDiv={true}
          divisionOpdOptions={props.masterOptions.divisionOpd}
          onSuccess={handleCloseModOrder}
          editId={openModOrder?.id || null}
        />
      </Modal>
      <Modal
        open={openModOrder?.type === "IMAGING"}
        onClose={handleCloseModOrder}
        closeOnDimmerClick
        style={{ width: "auto" }}
      >
        <CardImagingRequest
          setProp={props.setProp}
          onEvent={props.onEvent}
          successMessage={props.successMessage}
          errorMessage={props.errorMessage}
          imagingList={props.imagingList}
          orderImagingList={props.orderImagingList}
          masterOptions={props.masterOptions}
          onSuccess={handleCloseModOrder}
          editId={openModOrder?.id || null}
          isAppointment={
            props.selectedOperatingOption
              ? props.selectedOperatingOption === "EL"
              : true
          }
        />
      </Modal>
      <Modal
        open={["CENTRAL_LAB", "BLOOD_BANK"].includes(openModOrder?.type || "")}
        onClose={handleCloseModOrder}
        closeOnDimmerClick
        style={{ width: "70%", padding: "10px" }}
      >
        <CardLabRequest
          setProp={props.setProp}
          onEvent={props.onEvent}
          // seq
          runSequence={props.runSequence}
          OrderCentralLabSequence={props.OrderCentralLabSequence}
          // data
          userLabPermission={props.userLabPermission}
          doctorLabOrderList={props.doctorLabOrderList}
          editId={openModOrder?.id || null}
          // options
          divisionOpdOptions={props.masterOptions.divisionOpd}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          selectedEncounter={props.selectedEncounter}
          selectedEmr={props.selectedEmr}
          selectedProgressCycle={props.selectedProgressCycle}
          loadingStatus={props.loadingStatus}
          // style
          doctorLabOrderTableStyle={{ height: "300px", display: "none" }}
          saveLabOrderStyle={{ marginTop: "0.5rem" }}
          // config
          isRestart={true}
          isNullEmr={true}
          checkOutPerformDiv={true}
          isBloodBank={openModOrder?.type === "BLOOD_BANK"}
          isAppointment={
            props.selectedOperatingOption
              ? props.selectedOperatingOption === "EL"
              : true
          }
          // callback
          onSuccess={handleCloseModOrder}
        />
      </Modal>

      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_OTHER_APPOINTMENT}`, null);
        }}
        error={
          props.errorMessage?.[CARD_OTHER_APPOINTMENT]?.message
            ? props.errorMessage?.[CARD_OTHER_APPOINTMENT]?.message
            : props.errorMessage?.[CARD_OTHER_APPOINTMENT]
        }
        success={null}
      />
    </div>
  );
};

export default React.memo(CardOtherOrderAppointment);
