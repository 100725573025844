import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Input,
  Dropdown,
  Checkbox
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardMedReconcileUX = (props: any) => {
    return(
      <div
        style={{padding: "5px"}}>
        <div
          style={{display: "flex", justifyContent: "space-between"}}>
          
          <div
            style={{fontWeight: "bold", fontSize: "1.3rem", padding: "5px"}}>
            Medication Reconcilliation
          </div>
          <div
            style={{display: "flex",  paddingTop: "10px", textAlign: "right"}}>
            
            <div>
              
              <Button
                onClick={props.onShowMedReconcileList}>
                เลือกใบ Medication Reconciliation
              </Button>
              <div
                style={{display: props.showMedReconcileList? "block": "none", position: "absolute", zIndex: 100, padding: "10px", backgroundColor: "#FFF", right:"10%"}}>
                
                <Table
                  data={props.medReconcileList}
                  getTrProps={props.getTrPropsMedReconcileList}
                  headers="Type,Encounter,วันที่และเวลาสร้าง"
                  keys="med_reconciliation_type_label,name,created_datetime"
                  minRows="3"
                  showPagination={false}
                  style={{height: "250px", width: "600px", textAlign: "center"}}
                  widths="150,,200">
                </Table>
                <div
                  style={{paddingTop: "5px", display: "flex", justifyContent: props.enableNewButtonInMedReconcileList ? "space-between" : "flex-end"}}>
                  
                  <Button
                    color="green"
                    onClick={props.onNewMedReconcile}
                    style={{...(!props.enableNewButtonInMedReconcileList && {display: "none"})}}>
                    New
                  </Button>
                  <Button
                    color="blue"
                    onClick={props.onMedReconcileSelect}>
                    เลือก
                  </Button>
                </div>
              </div>
            </div>
            <div>
              
              <Dropdown
                disabled={!!props.disabledMedReconcileType}
                onChange={props.changeMedReconcileType}
                options={props.medReconcileTypeOptions}
                selection={true}
                style={{transform: "scale(0.85)"}}
                value={props.medReconcileType}>
              </Dropdown>
            </div>
          </div>
        </div>
        <div
          style={{display: props.medReconcileType === "DISCHARGE" ||  props.medReconcileType === "TRANSFER" ?"none":"flex", flexDirection: "column"}}>
          
          <Checkbox
            checked={props.hasNoPastMed}
            label="ไม่มีรายการยาเดิม"
            onChange={props.changeHasNoPastMed}
            style={{padding: "10px", width: "fit-content"}}>
          </Checkbox>
          <Checkbox
            checked={props.notAvailableAtAdmit}
            disabled={props.notAvailableDisabled}
            label={`Not Available at time of admission will be completed in ${props.configMedReconcileAvailable} hour(s). ${props.limitDateTime}`}
            onChange={props.changeNotAvailableAtAdmit}
            style={{padding: "10px", width: "fit-content"}}>
          </Checkbox>
        </div>
        <div
          style={{display: "none"}}>
          
          <div
            style={{marginTop: "10px"}}>
            จากประวัติการสั่งยาจากโรงพยาบาล
          </div>
          <Table
            data={props.internalItems}
            headers="รหัสยา,ชื่อยาและความแรง,วิธีใช้,QTY,Start Date,Time,Last Date,Time,Note,Off Hold Cont.,"
            keys="code,name,label,quantity,start_date,start_time,last_dose_date,last_dose_time,note,_action,remove"
            minRows="6"
            showPagination={false}
            style={{height: "150px"}}>
          </Table>
        </div>
        <div
          style={{display: "none"}}>
          
          <div
            style={{marginTop: "10px"}}>
            ประวัติการใช้ยานอกโรงพยาบาล
          </div>
          <Table
            data={props.externalItems}
            getTrProps={props.externalRowProps}
            headers="ประเภท,ชื่อ,QTY,Start Date,Time,Last Date,Time,Note,Off Hold Cont.,"
            keys="typeName,name,quantity,start_date,start_time,last_dose_date,last_dose_time,note,_action,remove"
            minRows="6"
            showPagination={false}
            style={{height: "150px"}}>
          </Table>
        </div>
        <div
          style={{...(props.medReconcileType === "TRANSFER" && {display: "none"}), marginTop: "10px", borderBottom: "solid #cccccc 1px"}}>
          
          <Button
            color={props.external ? null : "blue"}
            onClick={props.setInternal}
            size="small">
            {props.medReconcileType === "DISCHARGE" ? "เลือกรายการยาจาก Medication Reconciliation แรกรับ":" เพิ่มจากประวัติการสั่งยาจากโรงพยาบาล"}
          </Button>
          <Button
            color={props.external ? "blue" :  null}
            onClick={props.setExternal}
            size="small">
            {props.externalNameBtn}
          </Button>
        </div>
        <div
          style={{...(props.medReconcileType === "TRANSFER" && {display: "none"})}}>
          
          <div
            style={{display: !props.external && props.medReconcileType !== "DISCHARGE" ?  "block" : "none", height: "350px"}}>
            
            <div
              style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(20, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
              <div
                style={{ gridRow: "2/4", gridColumn: "46/50", }}>
                <Button
                  color="blue"
                  onClick={props.onSearchOrderHistory}
                  size="tiny"
                  style={{width: "100%"}}>
                  ค้นหา
                </Button>
              </div>
              <div
                style={{ gridRow: "5/17", gridColumn: "2/50",  }}>
                <Table
                  data={props.drugOrderHistoryItems}
                  getTrProps={props.drugOrderHistoryTrProps}
                  headers=",รหัสยา,ชื่อยา,จำนวนจ่าย,แพทย์,วันที่,หน่วยงาน"
                  keys="_add,code,product_name,quantity,order_by_name,order_time,order_div_name"
                  minRows="5"
                  showPagination={false}
                  style={{height:"100%"}}
                  widths="50,150,300,100">
                </Table>
              </div>
              <div
                style={{ gridRow: "2/4", gridColumn: "39/45"}}>
                {props.endDateInternal}
              </div>
              <div
                style={{ gridRow: "2/4", gridColumn: "32/38"}}>
                {props.startDateInternal}
              </div>
              <div
                style={{ gridRow: "2/4", gridColumn: "27/31", padding: "5px", textAlign: "right"}}>
                <Checkbox
                  checked={props.checkedDateInternal}
                  label="วันที่สั่งยา"
                  onChange={props.changeCheckedDateInternal}>
                </Checkbox>
              </div>
              <div
                style={{ gridRow: "2/4", gridColumn: "20/26",}}>
                {props.searchDrug}
              </div>
              <div
                style={{ gridRow: "2/4", gridColumn: "15/20", padding: "5px", textAlign: "right"}}>
                <Checkbox
                  checked={props.checkedDrug}
                  label="ชื่อหรือรหัสยา"
                  onChange={props.changeCheckedDrug}
                  style={{width: "100%"}}>
                </Checkbox>
              </div>
              <div
                style={{ gridRow: "2/4", gridColumn: "8/14", }}>
                {props.searchDoctor}
              </div>
              <div
                style={{ gridRow: "2/4", gridColumn: "2/8", padding: "5px", textAlign: "right"}}>
                <Checkbox
                  checked={props.checkedDoctor}
                  label="ชื่อหรือรหัสแพทย์"
                  onChange={props.changeCheckedDoctor}>
                </Checkbox>
              </div>
              <div
                style={{ gridRow: "18/20", gridColumn: "46/50"}}>
                <Button
                  color="green"
                  fluid={true}
                  onClick={props.onAddInternalItem}>
                  Add
                </Button>
              </div>
            </div>
          </div>
          <div
            style={{display: !props.external && props.medReconcileType === "DISCHARGE" ?  "block" : "none", height: "280px"}}>
            
            <div>
              
              <Table
                data={props.drugIPDAdmit}
                headers=",รหัสยา, ชื่อยาและความแรง, วิธีใช้, QTY, Start Date, Time, Last Date, Time, Note,Off,Hold,Cont."
                id="drugIPDAdmit"
                keys="_add,code,nameDiv,label,quantity, start_date_text, start_time_text, last_date_text, last_time_text, btn_note,_action_off,_action_hold,_action_cont"
                minRows="5"
                showPagination={false}
                style={{height:"220px"}}
                widths="80,80,,200,50,100,80,100,80,100,60,60,60">
              </Table>
            </div>
            <div
              style={{display: "flex", justifyContent: "flex-end", marginTop: "10px"}}>
              
              <Button
                color="green"
                onClick={props.onAddDrugToMRDischarge}>
                Add to MR discharge List
              </Button>
            </div>
          </div>
          <div
            style={{display: props.external ? "block" : "none", height: "350px"}}>
            
            <div
              style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(16, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
              <div
                style={{gridRow: "2/3", gridColumn: "3/6", }}>
                ประเภท
              </div>
              <div
                style={{ gridRow: "10/11", gridColumn: "3/6",}}>
                Start Date
              </div>
              <div
                style={{ gridRow: "10/11", gridColumn: "20/24", display:  props.checkedUnknowLastDose? "none": "block"}}>
                Last Date
              </div>
              <div
                style={{ padding: "5px", textAlign: "right", gridRow: "11/13", gridColumn: "2/6", }}>
                
              </div>
              <div
                style={{ gridRow: "3/5", gridColumn: "3/13", }}>
                <Dropdown
                  disabled={props.disableDrugType}
                  fluid={true}
                  onChange={props.changeExternalType}
                  options={props.externalTypeOptions}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.externalType}>
                </Dropdown>
              </div>
              <div
                style={{ gridRow: "11/12", gridColumn: "3/11", }}>
                <DateTextBox
                  onChange={props.changeStartDate}
                  style={{width: "100%"}}
                  value={props.startDate}>
                </DateTextBox>
              </div>
              <div
                style={{ gridRow: "10/11", gridColumn: "12/17", }}>
                Time
              </div>
              <div
                style={{ gridRow: "11/12", gridColumn: "12/19", }}>
                <div>
                  {props.StartTimeFreeTextBox}
                </div>
              </div>
              <div
                style={{ gridRow: "11/12", gridColumn: "20/28",display:  props.checkedUnknowLastDose? "none": "block" }}>
                <DateTextBox
                  onChange={props.changeLastDate}
                  value={props.lastDate}>
                </DateTextBox>
              </div>
              <div
                style={{gridRow: "10/11", gridColumn: "29/34", display:  props.checkedUnknowLastDose? "none": "block"}}>
                Time
              </div>
              <div
                style={{ gridRow: "11/12", gridColumn: "29/36", display:  props.checkedUnknowLastDose? "none": "block"}}>
                <div>
                  {props.EndTimeFreeTextBox}
                </div>
              </div>
              <div
                style={{ gridRow: "14/16", gridColumn: "3/11", }}>
                <Checkbox
                  checked={props.checkedUnknowLastDose}
                  label="ไม่สามารถระบุ Last dose"
                  onChange={props.changeUnknowLastDose}
                  style={{padding: "5px"}}>
                </Checkbox>
              </div>
              <div
                style={{ gridRow: "14/16", gridColumn: "35/41",}}>
                <Button
                  color="green"
                  onClick={props.addOtherDrug}
                  size="small"
                  style={{width: "100%", height: "100%"}}>
                  เพิ่ม/อัพเดทรายการนี้
                </Button>
              </div>
              <div
                style={{ gridRow: "2/3", gridColumn: "14/18", }}>
                ชื่อ/รายละเอียด
              </div>
              <div
                style={{ gridRow: "3/5", gridColumn: "14/28", }}>
                {props.externalDrug}
              </div>
              <div
                style={{gridRow: "2/3", gridColumn: "29/32", }}>
                Qty
              </div>
              <div
                style={{ gridRow: "3/5", gridColumn: "29/36",}}>
                <Input
                  fluid={true}
                  min={0}
                  onChange={props.changeQty}
                  style={{height: "100%"}}
                  type="number"
                  value={props.qty}>
                </Input>
              </div>
              <div
                style={{ gridRow: "14/16", gridColumn: "42/48", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
                <Button
                  color="orange"
                  onClick={props.clearExternalDrug}
                  size="small"
                  style={{width: "100%", height: "100%"}}>
                  เคลียร์
                </Button>
              </div>
              <div
                style={{ gridRow: "6/7", gridColumn: "3/6"}}>
                
                <label>
                  Dose
                </label>
              </div>
              <div
                style={{ gridRow: "7/9", gridColumn: "3/9"}}>
                <Input
                  fluid={true}
                  min={0}
                  onChange={props.changeDose}
                  type="number"
                  value={props.dose}>
                </Input>
              </div>
              <div
                style={{ gridRow: "6/7", gridColumn: "10/12"}}>
                Unit
              </div>
              <div
                style={{ gridRow: "7/9", gridColumn: "10/17"}}>
                <Dropdown
                  fluid={true}
                  onChange={props.changeUnit}
                  options={props.unitOptions}
                  search={true}
                  selection={true}
                  style={{height: "auto"}}
                  value={props.unit}>
                </Dropdown>
              </div>
              <div
                style={{ gridRow: "6/7", gridColumn: "18/20"}}>
                Route
              </div>
              <div
                style={{ gridRow: "7/9", gridColumn: "18/25"}}>
                <Dropdown
                  fluid={true}
                  onChange={props.changeRoute}
                  options={props.routeOptions}
                  search={true}
                  selection={true}
                  value={props.route}>
                </Dropdown>
              </div>
              <div
                style={{ gridRow: "6/7", gridColumn: "26/28"}}>
                Site
              </div>
              <div
                style={{ gridRow: "7/9", gridColumn: "26/33"}}>
                <Dropdown
                  fluid={true}
                  onChange={props.changeSite}
                  options={props.siteOptions}
                  search={true}
                  selection={true}
                  value={props.site}>
                </Dropdown>
              </div>
              <div
                style={{ gridRow: "6/7", gridColumn: "34/37",}}>
                Frequency
              </div>
              <div
                style={{ gridRow: "7/9", gridColumn: "34/42"}}>
                <Dropdown
                  fluid={true}
                  onChange={props.changeFrequency}
                  options={props.frequencyOptions}
                  search={true}
                  selection={true}
                  value={props.frequency}>
                </Dropdown>
              </div>
              <div
                style={{ gridRow: "6/7", gridColumn: "43/45"}}>
                Method
              </div>
              <div
                style={{ gridRow: "7/9", gridColumn: "43/50"}}>
                <Dropdown
                  fluid={true}
                  onChange={props.changeMethod}
                  options={props.methodOptions}
                  search={true}
                  selection={true}
                  value={props.method}>
                </Dropdown>
              </div>
              <div
                style={{ gridRow: "2/3", gridColumn: "37/40"}}>
                Note
              </div>
              <div
                style={{ gridRow: "3/5", gridColumn: "37/50"}}>
                <Input
                  fluid={true}
                  onChange={props.changeNote}
                  style={{height: "100%"}}
                  value={props.note}>
                </Input>
              </div>
              <div
                style={{ gridRow: "14/16", gridColumn: "12/20", display:  props.checkedUnknowLastDose? "block": "none"}}>
                <Dropdown
                  onChange={props.changeReason}
                  options={props.reasonOptions}
                  selection={true}
                  value={props.reason}>
                </Dropdown>
              </div>
              <div
                style={{ gridRow: "14/16", gridColumn: "21/34", display:  props.reason === "etc"? "block": "none"}}>
                <Input
                  fluid={true}
                  onChange={props.changeOtherReason}
                  style={{height: "100%"}}
                  value={props.otherReason}>
                </Input>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{...(props.medReconcileType === "TRANSFER" && {display: "none"})}}>
          
          <div
            style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            
            <div
              style={{margin: "10px 0px", fontSize: "16px", fontWeight: "bold", flex: 1}}>
              {props.medReconcileType === "DISCHARGE" ? "Home Medication" : `Past Medication ${props.pastMedRecordDate}`}
            </div>
            <div
              style={{display: props.medReconcileType === "DISCHARGE" ? "flex" : "none", justifyContent: "space-evenly", alignItems: "center", flex: "1"}}>
              
              <Checkbox
                checked={props.checkedIPDHome}
                label="IPD Home"
                onChange={props.onChangeCheckedIPDHome}>
              </Checkbox>
              <Checkbox
                checked={props.checkedMRIPD}
                label="MR IPD แรกรับ"
                onChange={props.onChangeCheckedMRIPD}>
              </Checkbox>
              <Checkbox
                checked={props.checkedSuggestion}
                label="Suggestion"
                onChange={props.onChangeCheckedSuggestion}>
              </Checkbox>
            </div>
          </div>
          <Table
            data={props.medicationItems}
            getTrProps={props.medicationRowProps}
            headers={props.medReconcileType === "DISCHARGE"? "TYPE,รหัสยา, ชื่อยาและความแรง, วิธีใช้, QTY, Start Date, Time, Last Date, Time, Note,ใช้ยาต่อ,หยุดยา," : "TYPE,รหัสยา, ชื่อยาและความแรง, วิธีใช้, QTY, Start Date, Time, Last Date, Time, Note,Off,Hold,Cont.,Edited date / time,"}
            keys={props.medReconcileType === "DISCHARGE"? "isExternal,code, name, label,quantity, start_date_text, start_time_text, last_date_text, last_time_text, btn_note,_is_cont,_is_stop,delete" : "isExternal,code,name,label,quantity, start_date_text, start_time_text, last_date_text, last_time_text, btn_note,_action_off,_action_hold,_action_cont,edit_time,delete"}
            minRows="6"
            showPagination={false}
            style={{height: "300px"}}
            widths={props.medReconcileType === "DISCHARGE"? "120,80,,200,50,100,80,100,80,100,100,100,60" : "80,80,200,200,50,100,80,100,80,100,60,60,60,120,60"}>
          </Table>
        </div>
        <div
          style={{...(props.medReconcileType !== "TRANSFER" && {display: "none"})}}>
          
          <Button
            color="purple"
            onClick={props.onClickSyncDrugTransfer}>
            Sync รายการยา (ต้องรับทราบทุกครั้ง)
          </Button>
          <div
            style={{fontWeight: "bolder", fontSize: "larger", margin: "20px 0px 5px 0px"}}>
            Current Medication (One Day)
          </div>
          <Table
            data={props.transferOneDayData}
            getTdProps={props.transferOneDayTdProps}
            getTrProps={props.transferOneDayTrProps}
            headers="TYPE,รหัสยา,ชื่อยาและความแรง,วิธีใช้,QTY,Start Date,Time,Last Date,Time,Note,Off,On"
            keys="type, code, name, label, quantity, startDate, startTime, lastDoseDate, lastDoseTime,noteIcon,off,on"
            minRows="5"
            showPagination={false}
            widths="80,100,auto,auto,60,100,60,100,60,auto,60,60">
          </Table>
          <div
            style={{fontWeight: "bolder", fontSize: "larger",margin: "20px 0px 5px 0px"}}>
            Current Medication (Continue)
          </div>
          <Table
            data={props.transferContData}
            getTdProps={props.transferContTdProps}
            getTrProps={props.transferContTrProps}
            headers="TYPE,รหัสยา,ชื่อยาและความแรง,วิธีใช้,QTY,Start Date,Time,Last Date,Time,Note,Off,Hold,Cont"
            keys="type, code, name, label, quantity, startDate, startTime, lastDoseDate, lastDoseTime,noteIcon,off,hold,continue"
            minRows="5"
            showPagination={false}
            widths="80,100,auto,auto,60,100,60,100,60,auto,60,60,80">
          </Table>
        </div>
        <div
          style={{height: "300px", borderTop: "solid #cccccc 1px", marginBottom: "5rem"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(25, 1fr)","gridTemplateColumns":"repeat(47, 1fr)"}}>
            <div
              style={{ padding: "5px", gridRow: "2/5", gridColumn: "2/8", }}>
              แพทย์
            </div>
            <div
              style={{ padding: "5px",  gridRow: "5/8", gridColumn: "2/8", }}>
              เภสัชกร
            </div>
            <div
              style={{ padding: "5px",  gridRow: "8/11", gridColumn: "2/8", }}>
              พยาบาล OPD
            </div>
            <div
              style={{ padding: "5px",  gridRow: "11/14", gridColumn: "2/8", }}>
              พยาบาล IPD
            </div>
            <div
              style={{ padding: "5px",  gridRow: "14/17", gridColumn: "2/8", }}>
              ผู้บันทึกข้อมูลล่าสุด
            </div>
            <div
              style={{ padding: "5px",  gridRow: "17/20", gridColumn: "2/8", lineHeight: 3.5 }}>
              หมายเหตุ
            </div>
            <div
              style={{ padding: "5px", margin: "1px", backgroundColor: "#eeeeee", gridRow: "2/5", gridColumn: "8/22", }}>
              {props.doctor}
            </div>
            <div
              style={{ padding: "5px", margin: "1px", backgroundColor: "#eeeeee", gridRow: "5/8", gridColumn: "8/22", }}>
              {props.pharmacist}
            </div>
            <div
              style={{ padding: "5px", margin: "1px", backgroundColor: "#eeeeee",  gridRow: "8/11", gridColumn: "8/22", }}>
              {props.nurseOPD}
            </div>
            <div
              style={{ padding: "5px", margin: "1px", backgroundColor: "#eeeeee",  gridRow: "11/14", gridColumn: "8/22", }}>
              {props.nurseIPD}
            </div>
            <div
              style={{ padding: "5px", margin: "1px", backgroundColor: "#eeeeee", gridRow: "14/17", gridColumn: "8/22", }}>
              {props.editUser}
            </div>
            <div
              style={{gridRow: "17/20", gridColumn: "8/16", }}>
              
              <Input
                fluid={true}
                onChange={props.changeRemark}
                style={{height: "100%", margin: "1rem 0"}}
                value={props.remark}>
              </Input>
            </div>
            <div
              style={{ gridRow: "2/5", gridColumn: "23/26" }}>
              <div
                style={{padding: "5px"}}>
                หมายเหตุ
              </div>
            </div>
            <div
              style={{ gridRow: "5/8", gridColumn: "23/26",}}>
              <div
                style={{padding: "5px"}}>
                หมายเหตุ
              </div>
            </div>
            <div
              style={{ gridRow: "8/11", gridColumn: "23/26", }}>
              <div
                style={{padding: "5px"}}>
                หมายเหตุ
              </div>
            </div>
            <div
              style={{ gridRow: "11/14", gridColumn: "23/26",}}>
              <div
                style={{padding: "5px"}}>
                หมายเหตุ
              </div>
            </div>
            <div
              style={{ textAlign: "right", padding: "5px", gridRow: "14/17", gridColumn: "22/29", }}>
              วันเวลาที่บันทึกล่าสุด
            </div>
            <div
              style={{ padding: "5px", margin: "1px", backgroundColor: "#eeeeee",  gridRow: "14/17", gridColumn: "29/37", }}>
              {props.editDate}
            </div>
            <div
              style={{ textAlign: "right", padding: "5px",  gridRow: "17/20", gridColumn: "17/20", lineHeight: 3.5}}>
              Username
            </div>
            <div
              style={{ gridRow: "17/20", gridColumn: "20/26", }}>
              <Input
                fluid={true}
                onChange={props.changeUsername}
                style={{height: "100%", margin: "1rem 0"}}
                value={props.username}>
              </Input>
            </div>
            <div
              style={{textAlign: "right", padding: "5px",  gridRow: "17/20", gridColumn: "26/29", lineHeight: 3.5 }}>
              Password
            </div>
            <div
              style={{ gridRow: "17/20", gridColumn: "29/35", }}>
              <Input
                fluid={true}
                onChange={props.changePassword}
                style={{height: "100%", margin: "1rem 0"}}
                type="password"
                value={props.password}>
              </Input>
            </div>
            <div
              style={{ ...((props.medReconcileType === "DISCHARGE" || props.medReconcileType === "TRANSFER") ? { gridRow: "21/24", gridColumn: "40/47" } : {gridRow: "21/24", gridColumn: "33/40"}) }}>
              <Button
                color="green"
                disabled={props.disabledSaveBtn}
                onClick={props.onSaveMedReconcile}
                style={{height: "100%", width: "100%", margin: "1rem 0"}}>
                SAVE & ACKNOWLEDGE
              </Button>
            </div>
            <div
              style={{ gridRow: "2/5", gridColumn: "26/45", padding: "5px", margin: "1px", backgroundColor: "#eeeeee",}}>
              {props.doctorRemark}
            </div>
            <div
              style={{ gridRow: "5/8", gridColumn: "26/45", padding: "5px", margin: "1px", backgroundColor: "#eeeeee",}}>
              {props.pharmacistRemark}
            </div>
            <div
              style={{ gridRow: "8/11", gridColumn: "26/45", padding: "5px", margin: "1px", backgroundColor: "#eeeeee",}}>
              {props.nurseOPDRemark}
            </div>
            <div
              style={{ gridRow: "11/14", gridColumn: "26/45", padding: "5px", margin: "1px", backgroundColor: "#eeeeee",}}>
              {props.nurseIPDRemark}
            </div>
            <div
              style={{ gridRow: "21/24", gridColumn: "41/49", }}>
              <Button
                color="blue"
                disabled={props.disabledOrderButton}
                onClick={props.onOrder}
                style={{height: "100%", margin: "1rem 0", ...((props.medReconcileType === "DISCHARGE" || props.medReconcileType === "TRANSFER") && {display: "none"})}}>
                 ACKNOWLEDGE & ORDER
              </Button>
            </div>
            <div
              style={{ gridRow: "14/17", gridColumn: "38/41", border: "none" }}>
              <Button
                color="blue"
                disabled={props.disabledLogBtn}
                onClick={props.onOpenLog}>
                Log
              </Button>
            </div>
            <div
              style={{ gridRow: "21/24", gridColumn: "27/32" }}>
              <Button
                color="yellow"
                onClick={props.onSaveDraft}
                style={{height: "100%", width: "100%", margin: "1rem 0", ...(props.hideSaveDraft && {display: "none"}) }}>
                SAVE DRAFT
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardMedReconcileUX

export const screenPropsDefault = {"external":true}

/* Date Time : Thu Mar 16 2023 09:18:55 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{...(props.medReconcileType === \"TRANSFER\" && {display: \"none\"}), marginTop: \"10px\", borderBottom: \"solid #cccccc 1px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{...(props.medReconcileType === \"TRANSFER\" && {display: \"none\"})}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\", borderTop: \"solid #cccccc 1px\", marginBottom: \"5rem\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "จากประวัติการสั่งยาจากโรงพยาบาล"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"10px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 8,
      "name": "Table",
      "parent": 2,
      "props": {
        "data": {
          "type": "code",
          "value": "props.internalItems"
        },
        "headers": {
          "type": "value",
          "value": "รหัสยา,ชื่อยาและความแรง,วิธีใช้,QTY,Start Date,Time,Last Date,Time,Note,Off Hold Cont.,"
        },
        "keys": {
          "type": "value",
          "value": "code,name,label,quantity,start_date,start_time,last_dose_date,last_dose_time,note,_action,remove"
        },
        "minRows": {
          "type": "value",
          "value": "6"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"150px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการใช้ยานอกโรงพยาบาล"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"10px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: !props.external && props.medReconcileType !== \"DISCHARGE\" ?  \"block\" : \"none\", height: \"350px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.external ? \"block\" : \"none\", height: \"350px\"}"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.medReconcileType === \"DISCHARGE\" ? \"เลือกรายการยาจาก Medication Reconciliation แรกรับ\":\" เพิ่มจากประวัติการสั่งยาจากโรงพยาบาล\""
        },
        "color": {
          "type": "code",
          "value": "props.external ? null : \"blue\""
        },
        "onClick": {
          "type": "code",
          "value": "props.setInternal"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.externalNameBtn"
        },
        "color": {
          "type": "code",
          "value": "props.external ? \"blue\" :  null"
        },
        "onClick": {
          "type": "code",
          "value": "props.setExternal"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 15,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.externalItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.externalRowProps"
        },
        "headers": {
          "type": "value",
          "value": "ประเภท,ชื่อ,QTY,Start Date,Time,Last Date,Time,Note,Off Hold Cont.,"
        },
        "keys": {
          "type": "value",
          "value": "typeName,name,quantity,start_date,start_time,last_dose_date,last_dose_time,note,_action,remove"
        },
        "minRows": {
          "type": "value",
          "value": "6"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"150px\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "label": "divgrid",
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(25, 1fr)\",\"gridTemplateColumns\":\"repeat(47, 1fr)\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "label": "divgrid",
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(20, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "label": "divgrid",
      "name": "div",
      "parent": 12,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(16, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 17,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"46/50\", }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 17,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/17\", gridColumn: \"2/50\",  }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Button",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearchOrderHistory"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 22,
      "name": "Table",
      "parent": 20,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugOrderHistoryItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.drugOrderHistoryTrProps"
        },
        "headers": {
          "type": "value",
          "value": ",รหัสยา,ชื่อยา,จำนวนจ่าย,แพทย์,วันที่,หน่วยงาน"
        },
        "keys": {
          "type": "value",
          "value": "_add,code,product_name,quantity,order_by_name,order_time,order_div_name"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\"}"
        },
        "widths": {
          "type": "value",
          "value": "50,150,300,100"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", gridRow: \"2/5\", gridColumn: \"2/8\", }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "เภสัชกร"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\",  gridRow: \"5/8\", gridColumn: \"2/8\", }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "พยาบาล OPD"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\",  gridRow: \"8/11\", gridColumn: \"2/8\", }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "พยาบาล IPD"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\",  gridRow: \"11/14\", gridColumn: \"2/8\", }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้บันทึกข้อมูลล่าสุด"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\",  gridRow: \"14/17\", gridColumn: \"2/8\", }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\",  gridRow: \"17/20\", gridColumn: \"2/8\", lineHeight: 3.5 }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctor"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", margin: \"1px\", backgroundColor: \"#eeeeee\", gridRow: \"2/5\", gridColumn: \"8/22\", }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pharmacist"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", margin: \"1px\", backgroundColor: \"#eeeeee\", gridRow: \"5/8\", gridColumn: \"8/22\", }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nurseOPD"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", margin: \"1px\", backgroundColor: \"#eeeeee\",  gridRow: \"8/11\", gridColumn: \"8/22\", }"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nurseIPD"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", margin: \"1px\", backgroundColor: \"#eeeeee\",  gridRow: \"11/14\", gridColumn: \"8/22\", }"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editUser"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", margin: \"1px\", backgroundColor: \"#eeeeee\", gridRow: \"14/17\", gridColumn: \"8/22\", }"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{gridRow: \"17/20\", gridColumn: \"8/16\", }"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 16,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/5\", gridColumn: \"23/26\" }"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 16,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/8\", gridColumn: \"23/26\",}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 16,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/11\", gridColumn: \"23/26\", }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 16,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/14\", gridColumn: \"23/26\",}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเวลาที่บันทึกล่าสุด"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", padding: \"5px\", gridRow: \"14/17\", gridColumn: \"22/29\", }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editDate"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", margin: \"1px\", backgroundColor: \"#eeeeee\",  gridRow: \"14/17\", gridColumn: \"29/37\", }"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "Username"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", padding: \"5px\",  gridRow: \"17/20\", gridColumn: \"17/20\", lineHeight: 3.5}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 16,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"17/20\", gridColumn: \"20/26\", }"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "Password"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", padding: \"5px\",  gridRow: \"17/20\", gridColumn: \"26/29\", lineHeight: 3.5 }"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 16,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"17/20\", gridColumn: \"29/35\", }"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 16,
      "props": {
        "style": {
          "type": "code",
          "value": "{ ...((props.medReconcileType === \"DISCHARGE\" || props.medReconcileType === \"TRANSFER\") ? { gridRow: \"21/24\", gridColumn: \"40/47\" } : {gridRow: \"21/24\", gridColumn: \"33/40\"}) }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Button",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "SAVE & ACKNOWLEDGE"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledSaveBtn"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSaveMedReconcile"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", width: \"100%\", margin: \"1rem 0\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Input",
      "parent": 42,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeUsername"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", margin: \"1rem 0\"}"
        },
        "value": {
          "type": "code",
          "value": "props.username"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Input",
      "parent": 44,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePassword"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", margin: \"1rem 0\"}"
        },
        "type": {
          "type": "value",
          "value": "password"
        },
        "value": {
          "type": "code",
          "value": "props.password"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภท"
        },
        "style": {
          "type": "code",
          "value": "{gridRow: \"2/3\", gridColumn: \"3/6\", }"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Start Date"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"3/6\",}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Last Date"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"20/24\", display:  props.checkedUnknowLastDose? \"none\": \"block\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", textAlign: \"right\", gridRow: \"11/13\", gridColumn: \"2/6\", }"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/5\", gridColumn: \"3/13\", }"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"3/11\", }"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Time"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"12/17\", }"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"12/19\", }"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"20/28\",display:  props.checkedUnknowLastDose? \"none\": \"block\" }"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Time"
        },
        "style": {
          "type": "code",
          "value": "{gridRow: \"10/11\", gridColumn: \"29/34\", display:  props.checkedUnknowLastDose? \"none\": \"block\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"29/36\", display:  props.checkedUnknowLastDose? \"none\": \"block\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/16\", gridColumn: \"3/11\", }"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/16\", gridColumn: \"35/41\",}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Button",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่ม/อัพเดทรายการนี้"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.addOtherDrug"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Dropdown",
      "parent": 60,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disableDrugType"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeExternalType"
        },
        "options": {
          "type": "code",
          "value": "props.externalTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.externalType"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 74,
      "name": "DateTextBox",
      "parent": 61,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeStartDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 75,
      "name": "DateTextBox",
      "parent": 64,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeLastDate"
        },
        "value": {
          "type": "code",
          "value": "props.lastDate"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ/รายละเอียด"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"14/18\", }"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "code",
          "value": "props.externalDrug"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/5\", gridColumn: \"14/28\", }"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Qty"
        },
        "style": {
          "type": "code",
          "value": "{gridRow: \"2/3\", gridColumn: \"29/32\", }"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/5\", gridColumn: \"29/36\",}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Input",
      "parent": 81,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "min": {
          "type": "code",
          "value": "0"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeQty"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.qty"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/16\", gridColumn: \"42/48\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Button",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "เคลียร์"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "onClick": {
          "type": "code",
          "value": "props.clearExternalDrug"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"3/6\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"3/9\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Unit"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"10/12\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"10/17\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Route"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"18/20\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"18/25\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Site"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"26/28\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"26/33\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Frequency"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"34/37\",}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"34/42\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Method"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"43/45\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"43/50\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "Input",
      "parent": 86,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "min": {
          "type": "code",
          "value": "0"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDose"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.dose"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Dropdown",
      "parent": 88,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeUnit"
        },
        "options": {
          "type": "code",
          "value": "props.unitOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height: \"auto\"}"
        },
        "value": {
          "type": "code",
          "value": "props.unit"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "Dropdown",
      "parent": 90,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeRoute"
        },
        "options": {
          "type": "code",
          "value": "props.routeOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.route"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "Dropdown",
      "parent": 92,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSite"
        },
        "options": {
          "type": "code",
          "value": "props.siteOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.site"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Dropdown",
      "parent": 94,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeFrequency"
        },
        "options": {
          "type": "code",
          "value": "props.frequencyOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.frequency"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Dropdown",
      "parent": 96,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeMethod"
        },
        "options": {
          "type": "code",
          "value": "props.methodOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.method"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Note"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"37/40\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/5\", gridColumn: \"37/50\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Input",
      "parent": 104,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNote"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.note"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endDateInternal"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"39/45\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startDateInternal"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"32/38\"}"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 17,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"27/31\", padding: \"5px\", textAlign: \"right\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchDrug"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"20/26\",}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "div",
      "parent": 17,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"15/20\", padding: \"5px\", textAlign: \"right\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchDoctor"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"8/14\", }"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 17,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"2/8\", padding: \"5px\", textAlign: \"right\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Checkbox",
      "parent": 112,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedDoctor"
        },
        "label": {
          "type": "value",
          "value": "ชื่อหรือรหัสแพทย์"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCheckedDoctor"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Checkbox",
      "parent": 110,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedDrug"
        },
        "label": {
          "type": "value",
          "value": "ชื่อหรือรหัสยา"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCheckedDrug"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Checkbox",
      "parent": 108,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedDateInternal"
        },
        "label": {
          "type": "value",
          "value": "วันที่สั่งยา"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCheckedDateInternal"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "div",
      "parent": 17,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"18/20\", gridColumn: \"46/50\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "Button",
      "parent": 116,
      "props": {
        "children": {
          "type": "value",
          "value": "Add"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddInternalItem"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/16\", gridColumn: \"12/20\", display:  props.checkedUnknowLastDose? \"block\": \"none\"}"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/16\", gridColumn: \"21/34\", display:  props.reason === \"etc\"? \"block\": \"none\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "Dropdown",
      "parent": 118,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeReason"
        },
        "options": {
          "type": "code",
          "value": "props.reasonOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.reason"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "Input",
      "parent": 119,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeOtherReason"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.otherReason"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "Checkbox",
      "parent": 67,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedUnknowLastDose"
        },
        "label": {
          "type": "value",
          "value": "ไม่สามารถระบุ Last dose"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeUnknowLastDose"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "Input",
      "parent": 34,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeRemark"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", margin: \"1rem 0\"}"
        },
        "value": {
          "type": "code",
          "value": "props.remark"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\"}"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\"}"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorRemark"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/5\", gridColumn: \"26/45\", padding: \"5px\", margin: \"1px\", backgroundColor: \"#eeeeee\",}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pharmacistRemark"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/8\", gridColumn: \"26/45\", padding: \"5px\", margin: \"1px\", backgroundColor: \"#eeeeee\",}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nurseOPDRemark"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/11\", gridColumn: \"26/45\", padding: \"5px\", margin: \"1px\", backgroundColor: \"#eeeeee\",}"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nurseIPDRemark"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/14\", gridColumn: \"26/45\", padding: \"5px\", margin: \"1px\", backgroundColor: \"#eeeeee\",}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  paddingTop: \"10px\", textAlign: \"right\"}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Medication Reconcilliation"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.3rem\", padding: \"5px\"}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "Button",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกใบ Medication Reconciliation"
        },
        "onClick": {
          "type": "code",
          "value": "props.onShowMedReconcileList"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "Dropdown",
      "parent": 137,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!!props.disabledMedReconcileType"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeMedReconcileType"
        },
        "options": {
          "type": "code",
          "value": "props.medReconcileTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{transform: \"scale(0.85)\"}"
        },
        "value": {
          "type": "code",
          "value": "props.medReconcileType"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showMedReconcileList? \"block\": \"none\", position: \"absolute\", zIndex: 100, padding: \"10px\", backgroundColor: \"#FFF\", right:\"10%\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 142,
      "name": "Table",
      "parent": 141,
      "props": {
        "data": {
          "type": "code",
          "value": "props.medReconcileList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrPropsMedReconcileList"
        },
        "headers": {
          "type": "value",
          "value": "Type,Encounter,วันที่และเวลาสร้าง"
        },
        "keys": {
          "type": "value",
          "value": "med_reconciliation_type_label,name,created_datetime"
        },
        "minRows": {
          "type": "value",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"250px\", width: \"600px\", textAlign: \"center\"}"
        },
        "widths": {
          "type": "value",
          "value": "150,,200"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "div",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"5px\", display: \"flex\", justifyContent: props.enableNewButtonInMedReconcileList ? \"space-between\" : \"flex-end\"}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "Button",
      "parent": 143,
      "props": {
        "children": {
          "type": "value",
          "value": "New"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNewMedReconcile"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.enableNewButtonInMedReconcileList && {display: \"none\"})}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "Button",
      "parent": 143,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือก"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onMedReconcileSelect"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.medReconcileType === \"DISCHARGE\" ||  props.medReconcileType === \"TRANSFER\" ?\"none\":\"flex\", flexDirection: \"column\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "Checkbox",
      "parent": 146,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.hasNoPastMed"
        },
        "label": {
          "type": "value",
          "value": "ไม่มีรายการยาเดิม"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeHasNoPastMed"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", width: \"fit-content\"}"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "Checkbox",
      "parent": 146,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.notAvailableAtAdmit"
        },
        "disabled": {
          "type": "code",
          "value": "props.notAvailableDisabled"
        },
        "label": {
          "type": "code",
          "value": "`Not Available at time of admission will be completed in ${props.configMedReconcileAvailable} hour(s). ${props.limitDateTime}`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNotAvailableAtAdmit"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", width: \"fit-content\"}"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 16,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"21/24\", gridColumn: \"41/49\", }"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "Button",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": " ACKNOWLEDGE & ORDER"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledOrderButton"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOrder"
        },
        "size": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", margin: \"1rem 0\", ...((props.medReconcileType === \"DISCHARGE\" || props.medReconcileType === \"TRANSFER\") && {display: \"none\"})}"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "code",
          "value": "props.StartTimeFreeTextBox"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "code",
          "value": "props.EndTimeFreeTextBox"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "label",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "Dose"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{...(props.medReconcileType === \"TRANSFER\" && {display: \"none\"})}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 159,
      "name": "Table",
      "parent": 157,
      "props": {
        "data": {
          "type": "code",
          "value": "props.medicationItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.medicationRowProps"
        },
        "headers": {
          "type": "code",
          "value": "props.medReconcileType === \"DISCHARGE\"? \"TYPE,รหัสยา, ชื่อยาและความแรง, วิธีใช้, QTY, Start Date, Time, Last Date, Time, Note,ใช้ยาต่อ,หยุดยา,\" : \"TYPE,รหัสยา, ชื่อยาและความแรง, วิธีใช้, QTY, Start Date, Time, Last Date, Time, Note,Off,Hold,Cont.,Edited date / time,\""
        },
        "keys": {
          "type": "code",
          "value": "props.medReconcileType === \"DISCHARGE\"? \"isExternal,code, name, label,quantity, start_date_text, start_time_text, last_date_text, last_time_text, btn_note,_is_cont,_is_stop,delete\" : \"isExternal,code,name,label,quantity, start_date_text, start_time_text, last_date_text, last_time_text, btn_note,_action_off,_action_hold,_action_cont,edit_time,delete\""
        },
        "minRows": {
          "type": "value",
          "value": "6"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.medReconcileType === \"DISCHARGE\"? \"120,80,,200,50,100,80,100,80,100,100,100,60\" : \"80,80,200,200,50,100,80,100,80,100,60,60,60,120,60\""
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 16,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/17\", gridColumn: \"38/41\", border: \"none\" }"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "Button",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": "Log"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledLogBtn"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenLog"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 16,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"21/24\", gridColumn: \"27/32\" }"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "Button",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": "SAVE DRAFT"
        },
        "className": {
          "type": "code",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSaveDraft"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", width: \"100%\", margin: \"1rem 0\", ...(props.hideSaveDraft && {display: \"none\"}) }"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: !props.external && props.medReconcileType === \"DISCHARGE\" ?  \"block\" : \"none\", height: \"280px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 165,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "div",
      "parent": 165,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", marginTop: \"10px\"}"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 168,
      "name": "Table",
      "parent": 166,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugIPDAdmit"
        },
        "headers": {
          "type": "value",
          "value": ",รหัสยา, ชื่อยาและความแรง, วิธีใช้, QTY, Start Date, Time, Last Date, Time, Note,Off,Hold,Cont."
        },
        "id": {
          "type": "value",
          "value": "drugIPDAdmit"
        },
        "keys": {
          "type": "value",
          "value": "_add,code,nameDiv,label,quantity, start_date_text, start_time_text, last_date_text, last_time_text, btn_note,_action_off,_action_hold,_action_cont"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"220px\"}"
        },
        "widths": {
          "type": "value",
          "value": "80,80,,200,50,100,80,100,80,100,60,60,60"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "Button",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": "Add to MR discharge List"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddDrugToMRDischarge"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 157,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\"}"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 170,
      "props": {
        "children": {
          "type": "code",
          "value": "props.medReconcileType === \"DISCHARGE\" ? \"Home Medication\" : `Past Medication ${props.pastMedRecordDate}`"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px 0px\", fontSize: \"16px\", fontWeight: \"bold\", flex: 1}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "div",
      "parent": 170,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.medReconcileType === \"DISCHARGE\" ? \"flex\" : \"none\", justifyContent: \"space-evenly\", alignItems: \"center\", flex: \"1\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 173,
      "name": "Checkbox",
      "parent": 172,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedIPDHome"
        },
        "label": {
          "type": "value",
          "value": "IPD Home"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckedIPDHome"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "Checkbox",
      "parent": 172,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedMRIPD"
        },
        "label": {
          "type": "value",
          "value": "MR IPD แรกรับ"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckedMRIPD"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "Checkbox",
      "parent": 172,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedSuggestion"
        },
        "label": {
          "type": "value",
          "value": "Suggestion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckedSuggestion"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{...(props.medReconcileType !== \"TRANSFER\" && {display: \"none\"})}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "Button",
      "parent": 176,
      "props": {
        "children": {
          "type": "value",
          "value": "Sync รายการยา (ต้องรับทราบทุกครั้ง)"
        },
        "color": {
          "type": "value",
          "value": "purple"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickSyncDrugTransfer"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 179,
      "name": "Table",
      "parent": 176,
      "props": {
        "data": {
          "type": "code",
          "value": "props.transferOneDayData"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.transferOneDayTdProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.transferOneDayTrProps"
        },
        "headers": {
          "type": "value",
          "value": "TYPE,รหัสยา,ชื่อยาและความแรง,วิธีใช้,QTY,Start Date,Time,Last Date,Time,Note,Off,On"
        },
        "keys": {
          "type": "value",
          "value": "type, code, name, label, quantity, startDate, startTime, lastDoseDate, lastDoseTime,noteIcon,off,on"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": "80,100,auto,auto,60,100,60,100,60,auto,60,60"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "div",
      "parent": 176,
      "props": {
        "children": {
          "type": "value",
          "value": "Current Medication (Continue)"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\", fontSize: \"larger\",margin: \"20px 0px 5px 0px\"}"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 181,
      "name": "Table",
      "parent": 176,
      "props": {
        "data": {
          "type": "code",
          "value": "props.transferContData"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.transferContTdProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.transferContTrProps"
        },
        "headers": {
          "type": "value",
          "value": "TYPE,รหัสยา,ชื่อยาและความแรง,วิธีใช้,QTY,Start Date,Time,Last Date,Time,Note,Off,Hold,Cont"
        },
        "keys": {
          "type": "value",
          "value": "type, code, name, label, quantity, startDate, startTime, lastDoseDate, lastDoseTime,noteIcon,off,hold,continue"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": "80,100,auto,auto,60,100,60,100,60,auto,60,60,80"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 176,
      "props": {
        "children": {
          "type": "value",
          "value": "Current Medication (One Day)"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\", fontSize: \"larger\", margin: \"20px 0px 5px 0px\"}"
        }
      },
      "seq": 179,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardMedReconcileUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "external": true
  },
  "width": 80
}

*********************************************************************************** */
