import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Grid,
  Icon,
  Input,
  Label,
  Modal,
  Popup,
  TextArea,
  Form,
} from "semantic-ui-react";

import htmlParse from "html-react-parser";

// Interface
import {
  CARD_DUPLICATE_REASON,
  CARD_DUPLICATE_CLASSIFICATION_REASON,
} from "./TPDInterface";

// ui common
import ModPlanning from "../common/ModPlanning";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";
import { ErrorMessage } from "react-lib/apps/common";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// ui tpd
import CardDrugContinuePlanUX from "./CardDrugContinuePlanUX";
import CardDrugClaimQA from "./CardDrugClaimQA";
import CardDuplicateDrugOrders from "./CardDuplicateDrugOrders";
import CardDuplicateDrugClassificationOrders from "./CardDuplicateDrugClassificationOrders";
import DrugActionLog from "./DrugActionLog";
import ModCheckADR from "./ModCheckADRUX";
import ModCheckDI from "./ModCheckDIUX";
import ModSpecialDirection from "./ModSpecialDirection";
import ModSolventSelection from "./ModSolventSelection";
import ModCheckDoctorCertificateUX from "./ModCheckDoctorCertificateUX";
import ModCertificateErrorUX from "./ModCertificateErrorUX";
import SubDrugOrderStatusAlert from "./SubDrugOrderStatusAlert";
import ModDrugLabInteraction from "./ModDrugLabInteraction";
import ModDrugDisease from "./ModDrugDisease";
import ModDrugInteraction from "./ModDrugInteraction";
import ModDrugFact from "./ModDrugFact";
import ModDescriptionUD from "./ModDescriptionUD";

// Utils
import { getStatusOff } from "../DPI/CardOrderSummary";

export const CARD_DRUG_CONT_ID = "CardDrugContinuePlan";

const SUB_DRUG_CONT_ACTION_ID = "SubDrugContinueActionMenu";
const DRUG_ORDER_TYPE = "Continue";

// ================================================== ActionMenuColumn
export const ActionMenuColumn = (props: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentAction, setCurrentAction] = useState<string>("");

  useEffect(() => {
    if (props.plan_item?.id && isOpen) {
      props.setProp("planningData", props.plan_item);
    }
  }, [props.plan_item, isOpen]);

  useEffect(() => {
    if (
      currentAction !== "" &&
      ["ERROR", "SUCCESS"].includes(
        props.buttonLoadCheck?.[`${SUB_DRUG_CONT_ACTION_ID}_${currentAction}`]
      )
    ) {
      setIsOpen(false);
      setCurrentAction("");
    }
  }, [props.buttonLoadCheck]);

  const handleActionPlanItem = (_event: any, data: any) => {
    setCurrentAction(data.name);
    props.onEvent({
      message: "handleActionPlanItem",
      params: { action: data.name, card: SUB_DRUG_CONT_ACTION_ID },
    });
  };

  return (
    <>
      {
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Popup
            open={isOpen}
            style={{ padding: "6px 8px" }}
            content={
              <>
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={handleActionPlanItem}
                  // data
                  paramKey={`${SUB_DRUG_CONT_ACTION_ID}_RESUME`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[`${SUB_DRUG_CONT_ACTION_ID}_RESUME`]
                  }
                  // config
                  color="blue"
                  name="RESUME"
                  style={{
                    display: props.plan_item?.allowed_actions?.includes(
                      "RESUME"
                    )
                      ? ""
                      : "none",
                  }}
                  title="RESUME"
                />

                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={handleActionPlanItem}
                  // data
                  paramKey={`${SUB_DRUG_CONT_ACTION_ID}_OFF`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[`${SUB_DRUG_CONT_ACTION_ID}_OFF`]
                  }
                  // config
                  color="black"
                  name="OFF"
                  style={{
                    display: props.plan_item?.allowed_actions?.includes("OFF")
                      ? ""
                      : "none",
                  }}
                  title="OFF"
                />

                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={handleActionPlanItem}
                  // data
                  paramKey={`${SUB_DRUG_CONT_ACTION_ID}_HOLD`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[`${SUB_DRUG_CONT_ACTION_ID}_HOLD`]
                  }
                  // config
                  color="brown"
                  name="HOLD"
                  style={{
                    display: props.plan_item?.allowed_actions?.includes("HOLD")
                      ? ""
                      : "none",
                  }}
                  title="HOLD"
                />

                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={handleActionPlanItem}
                  // data
                  paramKey={`${SUB_DRUG_CONT_ACTION_ID}_RENEW`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[`${SUB_DRUG_CONT_ACTION_ID}_RENEW`]
                  }
                  // config
                  color="teal"
                  name="RENEW"
                  style={{
                    display: props.plan_item?.allowed_actions?.includes("RENEW")
                      ? ""
                      : "none",
                  }}
                  title="RENEW"
                />
              </>
            }
            position="bottom right"
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            on="click"
            trigger={
              <Button
                icon="list layout"
                size="small"
                onDoubleClick={(e: any) => e.stopPropagation()}
              ></Button>
            }
          />
        </div>
      }
    </>
  );
};

// ================================================== CardDrugContinuePlan
type CardDrugContinuePlanProps = {
  // sequence
  runSequence: any;
  DrugSelectSequence: any;

  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // permission
  drugPermission?: any;

  // options
  duplicateReasonsOptions?: any[];

  // selected data
  selectedPatient?: any;
  selectedEncounter?: any;
  selectedDrug?: any;

  // search drug
  drugSearchLoading?: boolean;
  drugSearchText?: string;
  drugSearchResult?: any[];

  // drug list
  drugContinueFilter?: any;
  drugContinuePlanItems?: any[];

  // planning
  modPlanningConfig?: any;
  planningData?: any;

  // SpecialDirection
  modSpecialDirectionConfig?: any;

  // SolventSelectionConfig
  modSolventSelectionConfig?: any;

  // mod note
  modNoteData?: any;

  // mod doctor certificate
  modDoctorCertificate?: any;
  modDrugLabInteraction?: any;
  modDrugDisease?: any;
  modDrugInteraction?: any;
  currentDoctor?: any;

  // MedReconcile Check
  medReconcileCheck?: any;
  divisionType?: any;
  medReconcileIndex?: number;
  django?: any;
};

const CardDrugContinuePlanInitial: CardDrugContinuePlanProps = {
  // sequence
  runSequence: null,
  DrugSelectSequence: null,

  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  errorMessage: null,
  successMessage: null,
  buttonLoadCheck: null,

  // permission
  drugPermission: null,

  // options
  duplicateReasonsOptions: [],

  // selected data
  selectedPatient: null,
  selectedEncounter: null,
  selectedDrug: null,

  // search drug
  drugSearchLoading: false,
  drugSearchText: "",
  drugSearchResult: [],

  // drug list
  drugContinueFilter: {},
  drugContinuePlanItems: [],

  // planning
  modPlanningConfig: null,
  planningData: {},

  // SpecialDirection
  modSpecialDirectionConfig: null,

  // SolventSelectionConfig
  modSolventSelectionConfig: null,

  // mod note
  modNoteData: {},

  // mod doctor certificate
  modDoctorCertificate: {},

  // medReconcile Check
  medReconcileCheck: null,
  divisionType: null,
  medReconcileIndex: -1,
  django: null,
};

const CardDrugContinuePlan: React.FC<CardDrugContinuePlanProps> = (
  props: any
) => {
  const [dataName, setDataName] = useState("");
  // const [staffRole, setStaffRole] = useState("แพทย์");
  const [checking, setChecking] = useState(false);
  // factsheet
  const [openModFactSheet, setOpenModFactSheet] = useState<boolean>(false);
  const [modDrugDesc, setModDrugDesc] = useState<{
    open?: boolean;
    desc?: string;
    index?: number;
  }>({});
  // DOCTOR
  useEffect(() => {
    console.log("useEffect props.selectedEncounter: ", props.selectedEncounter);
    console.log("useEffect props.divisionType: ", props.divisionType);
    let roleTypes = props.django?.user?.role_types || [];
    if (
      ["หอผู้ป่วย"].includes(props.divisionType) &&
      (roleTypes || []).includes("DOCTOR") &&
      props.selectedEncounter?.type === "IPD"
    ) {
      let callFrom = "DPI";
      if (props.selectedEncounter?.id) {
        props.onEvent({
          message: "GetMedReconcileCheck",
          params: { encounter: props.selectedEncounter, callForm: callFrom },
        });
      }
    } else {
      console.warn("ไม่ได้มาจาก หอผู้ป่วย เปิด CardDrugContinuePlan: ");
    }

    // if ( ["ห้องยา"].includes(props.divisionType) &&
    //   (roleTypes || []).includes("PHARMACIST") &&
    //   (props.medReconcileCheck)) {
    //   props.setProp("medReconcileCheck", null)
    // }
  }, [props.selectedEncounter, props.django, props.divisionType]);

  // Verify
  useEffect(() => {
    console.log("useEffect props.medReconcileCheck: ", props.medReconcileCheck);
    console.log(
      "useEffect props.medReconcileCheck?.open_card_med_reconciliation: ",
      props.medReconcileCheck?.open_card_med_reconciliation
    );
    if (
      checking &&
      props.medReconcileCheck?.open_card_med_reconciliation === true
    ) {
      setDataName("");
      setChecking(false);
    } else if (checking) {
      console.log(" Called");
      let name = dataName;
      setDataName("");
      setChecking(false);
      props.onEvent({
        message: "NoteAndVerifyAndCancelDrugContinuePlan",
        params: { action: name, note: "", card: CARD_DRUG_CONT_ID },
      });
    }
  }, [props.medReconcileCheck, props.django, props.divisionType]);

  // Verify
  // useEffect(() => {
  //   console.log("props.django: ", props.django);
  //   console.log("props.divisionType: ", props.divisionType);
  //   console.log("props.selectedEncounter: ", props.selectedEncounter);

  //   if (props.medReconcileCheck) {
  //     if (props.medReconcileCheck?.open_card_med_reconciliation === false) {
  //       let name = dataName;
  //       props.onEvent({
  //         message: "NoteAndVerifyAndCancelDrugContinuePlan",
  //         params: { action: name, note: "", card: CARD_DRUG_CONT_ID },
  //       });
  //       setDataName("");
  //     }
  //   }
  // }, [props.django, props.divisionType, props.medReconcileCheck]);

  // useEffect(() => {
  //   if ((props.django?.user?.role_types || []).includes("PHARMACIST")) {
  //     setStaffRole("เภสัชกร");
  //   } else if ((props.django?.user?.role_types || []).includes("DOCTOR")) {
  //     setStaffRole("แพทย์");
  //   }
  // }, [props.django]);

  useEffect(() => {
    props.runSequence({
      sequence: "DrugSelect", // Start
      card: CARD_DRUG_CONT_ID,
    });
  }, []);

  useEffect(() => {
    if (!!props.drugContinueFilter?.status) {
      handleSetDrugContinueFilter(null, {
        name: props.drugContinueFilter?.status,
      });
    }
  }, []);

  const renderDrugOrderItems = useMemo(() => {
    return props.drugContinuePlanItems?.map((item: any, index: number) => ({
      ...item,
      title_status: (
        <>
          {item.full_name}
          <div style={{ display: "flex" }}>
            <Icon
              name="history"
              style={{ margin: "0 0.275rem", cursor: "pointer" }}
              onClick={() => {
                props.setProp("DrugSelectSequence.actionLogs.open", true);
              }}
            />

            <SubDrugOrderStatusAlert
              onEvent={props.onEvent}
              stateKey="drugOrder"
              index={index}
              item={{
                ...item,
                alerts: {
                  N: item?.order_notes,
                  X: item?.reject_note,
                  ...(item.alerts || {}),
                },
                pregcat: item?.alerts?.pregcat_alert,
                pregcat_alert: item?.alerts?.pregcat,
                claim_result:
                  item.claim_payload?.claim_results?.length > 0
                    ? item.claim_payload.claim_results[0]?.claim_result
                    : null,
              }}
              saveClaimByEvent={true}
              estimate={props.drugOrder?.estimate || {}}
              selectedEncounter={props.selectedEncounter}
              selectedEmr={props.selectedEmr}
              orderType={DRUG_ORDER_TYPE}
              selectedProgressCycle={props.selectedProgressCycle}
              isFemale={
                props.selectedEncounter?.patient_gender_name === "Female"
              }
              targetStateOrder={"drugContinuePlanItems"}
              //  onSaveNote={handleSaveNote}
              // callback
              //  onDrugClaim={() => handleCheckHaveQuestion(item, index)}
            />
          </div>
        </>
      ),
      action: (
        <>
          {(item.plan_item?.allowed_actions?.includes("RESUME") ||
            item.plan_item?.allowed_actions?.includes("OFF") ||
            item.plan_item?.allowed_actions?.includes("HOLD") ||
            item.plan_item?.allowed_actions?.includes("RENEW")) && (
            <ActionMenuColumn
              onEvent={props.onEvent}
              setProp={props.setProp}
              buttonLoadCheck={props.buttonLoadCheck}
              plan_item={item?.plan_item}
            />
          )}
        </>
      ),
      status:
        item.status === "OFF (VERIFIED)"
          ? `${
              getStatusOff({
                status: "OFF",
                endDate: item.plan_item.end_date,
                endTime: item.plan_item.end_time,
                startDate: item.plan_item.start_date,
              }).status
            } (${"Verified"})`.toUpperCase()
          : item.status.search(/VERIFIED.*Plan Off/g) >= 0
          ? "PLAN OFF (VERIFIED)"
          : item.status,
    }));
  }, [props.drugContinuePlanItems, props.buttonLoadCheck]);

  const renderDrugItemSearhResult = useMemo(() => {
    return (props.drugSearchResult || []).map((drug: any) => ({
      ...drug,
      generic_name: htmlParse(drug.generic_name || ""),
    }));
  }, [props.drugSearchResult]);

  // filter
  const handleSetDrugContinueFilter = (_event: any, data: any) => {
    props.onEvent({
      message: "SetDrugContinueFilter",
      params: { status: data.name },
    });
  };

  // Action DrugContinuePlan
  const handleClearDrugOrderForm = () => {
    props.onEvent({ message: "ClearDrugOrderForm", params: { hard: true } });
  };

  const handlePreviewDrugContinuePlan = (_event: any, data: any) => {
    props.onEvent({
      message: "PreviewDrugContinuePlan",
      params: {
        name: data.name,
        value: data.value,
        card: CARD_DRUG_CONT_ID,
      },
    });
  };

  const handleUnPreviewDrugContinuePlan = (_event: any, data: any) => {
    props.setProp(`DrugSelectSequence.${data.name}`, data.value);
  };

  const handleSave = (_event: any, data: any) => {
    // TODO : Issue 59228
    // Check Generics then Check Drug group

    if (data.name === "REQUEST") {
      props.onEvent({
        message: "CheckDrugGroupDuplicate",
        params: {
          card: CARD_DRUG_CONT_ID,
          action: data.name,
          orderType: "CONTINUE_PLAN",
        },
      });
    } else {
      props.onEvent({
        message: "ConfirmDrugContinueOrder",
        params: {
          action: data.name,
          card: CARD_DRUG_CONT_ID,
          isCheckDrugGroup: true,
        },
      });
    }
  };

  const handleConfirmDrugGroupDuplicateWithReason = (reason: any) => {
    if (reason?.duplicated_note) {
      props.onEvent({
        message: "ConfirmDrugContinueOrder",
        params: {
          action: "REQUEST",
          card: CARD_DRUG_CONT_ID,
          isCheckDrugGroup: true,
          noteGroupDuplicated: reason?.duplicated_note,
        },
      });
    }
  };

  const handleConfirm = (data: any) => {
    const error = props.errorMessage?.[CARD_DUPLICATE_REASON];

    props.onEvent({
      message: "ConfirmDrugContinueOrder",
      params: {
        card: CARD_DRUG_CONT_ID,
        action: error?.action,
        ...(error?.noteGroupDuplicated && {
          noteGroupDuplicated: error?.noteGroupDuplicated,
        }),
        ...data,
        first_duplicated_item: error?.first_duplicated_item,
        confirm_duplicated_drugs: true,
      },
    });
  };

  const handleEditDrugContinuePlan = (_event: any, data: any) => {
    props.onEvent({
      message: "EditDrugContinuePlan",
      params: { action: data.name, card: CARD_DRUG_CONT_ID },
    });
  };

  const handleNoteAndVerifyAndCancelDrugContinuePlan = (
    _event: any,
    data: any
  ) => {
    // TODO: call MedReconcile

    console.log("handleNoteAndVerifyAndCancelDrugContinuePlan: ");
    let roleTypes = props.django?.user?.role_types;
    console.log("handleSaveModNote roleTypes: ", roleTypes);
    console.log("handleSaveModNote props.divisionType", props.divisionType);
    if (
      ["ห้องยา"].includes(props.divisionType) &&
      (roleTypes || []).includes("PHARMACIST")
    ) {
      let callFrom = "TPD";
      if (props.selectedEncounter?.id) {
        setChecking(true);
        setDataName(data?.name);
        props.onEvent({
          message: "GetMedReconcileCheck",
          params: { encounter: props.selectedEncounter, callForm: callFrom },
        });
      }
      return;
    } else {
      console.warn("ไม่ได้มาจาก ห้องจ่ายยา เปิด CardDrugContinuePlan: ");
    }

    // console.log('handleNoteAndVerifyAndCancelDrugContinuePlan data.name: ', data.name);

    // props.onEvent({
    //   message: "NoteAndVerifyAndCancelDrugContinuePlan",
    //   params: { action: data.name, note: "", card: CARD_DRUG_CONT_ID },
    // });
  };

  // Mod Note
  const handleChangeModNoteData = (_event: any, data: any) => {
    props.onEvent({
      message: "SetModNoteData",
      params: { name: data.name, value: data.value },
    });
  };

  const handleSaveModNote = (goToMedreconcile: boolean = false) => {
    console.log("handleSaveModNote: ", goToMedreconcile);

    if (goToMedreconcile) {
      let roleTypes = props.django?.user?.role_types;
      console.log("handleSaveModNote roleTypes: ", roleTypes);
      console.log("handleSaveModNote props.divisionType", props.divisionType);
      if (
        ["ห้องยา"].includes(props.divisionType) &&
        (roleTypes || []).includes("PHARMACIST")
      ) {
        let callFrom = "TPD";
        if (props.selectedEncounter?.id) {
          props.onEvent({
            message: "GetMedReconcileCheck",
            params: { encounter: props.selectedEncounter, callForm: callFrom },
          });
        }
      } else {
        console.warn("ไม่ได้มาจาก ห้องจ่ายยา เปิด CardDrugContinuePlan: ");
      }
    } else {
      props.onEvent({
        message: "NoteAndVerifyAndCancelDrugContinuePlan",
        params: { card: CARD_DRUG_CONT_ID },
      });
    }
  };

  const handleCloseModNote = () => {
    props.onEvent({
      message: "SetModNoteData",
      params: { name: "open", value: false },
    });
  };

  // Mod Planning
  const handleOpenModPlanning = () => {
    if (props.errorMessage?.[CARD_DRUG_CONT_ID]?.error) {
      props.setProp(`errorMessage.${CARD_DRUG_CONT_ID}.showMessage`, true);
    } else {
      if (props.planningData?.id) {
        // fetch /apis/core/plan-item/<id>/
      } else {
        props.onEvent({ message: "handleSetDefaultPlanningData" });
      }
      props.onEvent({
        message: "handleSetModPlanningConfig",
        params: { name: "open", value: true },
      });
    }
  };

  const handleCloseModPlanning = () => {
    props.onEvent({
      message: "handleSetModPlanningConfig",
      params: { name: "open", value: false },
    });
    props.onEvent({ message: "GetDrugContinuePlan" });
  };

  // Mod Special Direction
  const handleOpenModSpecialDirection = () => {
    props.onEvent({
      message: "SetModSpecialDirectionConfig",
      params: { name: "open", value: true },
    });
  };

  const handleCloseModSpecialDirection = () => {
    props.onEvent({
      message: "SetModSpecialDirectionConfig",
      params: { name: "open", value: false },
    });
  };

  // Mod Solvent Selection
  const handleOpenModSolventSelection = () => {
    let admixtureOptions: any = {};
    if (props.drugPermission.config_TPD_ENABLE_IV_COMPATIBLE) {
      admixtureOptions = {
        quantity: props.DrugSelectSequence?.solvent_quantity,
        strength: props.DrugSelectSequence?.dose || 1,
        strength_unit: props.DrugSelectSequence?.unit || "",
        volume: props.DrugSelectSequence?.mixture_solvent_volume,
        mixture_iv_rate: props.DrugSelectSequence?.mixture_iv_rate,
        mixture_iv_rate_unit: props.DrugSelectSequence?.mixture_iv_rate_unit,
        solute_concentration_rate:
          props.DrugSelectSequence?.mixture_concentration_solute,
        solvent_concentration_rate:
          props.DrugSelectSequence?.mixture_concentration_solvent,
        concentration_unit: props.DrugSelectSequence?.drug_concentration_unit,
        drug_drip_in: props.DrugSelectSequence?.drug_drip_in,
        drug_drip_in_unit: props.DrugSelectSequence?.drug_drip_in_unit,
      };

      const editable: boolean =
        props.DrugSelectSequence?.allowed_actions?.includes("REQUEST") ||
        props.DrugSelectSequence?.allowed_actions?.includes("EDIT");
      props.onEvent({
        message: "SetModSolventSelectionConfig",
        params: { editable: editable, editableQuantity: editable },
      });
    } else {
      admixtureOptions = {
        quantity: props.DrugSelectSequence?.solvent_quantity,
        rate: props.DrugSelectSequence?.mixture_iv_rate,
      };
    }
    props.onEvent({
      message: "initModSolventSelection",
      params: {
        drugOrderType: DRUG_ORDER_TYPE,
        solventId: props.DrugSelectSequence?.solvent_product,
        admixtureOptions: admixtureOptions,
      },
    });
  };

  const handleCloseModSolventSelection = () => {
    props.onEvent({
      message: "SetModSolventSelectionConfig",
      params: { openModal: false },
    });
  };

  const handleSaveDrugClaimQA = (claim_payload: any, _index: number) => {
    let drugSelectSeq: any = props.DrugSelectSequence;

    let data: any = { ...drugSelectSeq?.claim_payload };
    data.payloads[0].answer_dict = claim_payload?.payloads?.[0]?.answer_dict;

    props.onEvent({
      message: "ClaimDrugContinuePlan",
      params: { claim_payload: data },
    });
  };

  const handleClickDrugFact = () => {
    setOpenModFactSheet(true);
  };

  const handleOpenModDrugDesc = () => {
    setModDrugDesc({ open: true, desc: props.DrugSelectSequence?.description });
  };

  const handleCloseFactSheet = () => {
    setOpenModFactSheet(false);
  };

  const handleCloseModDesc = () => {
    setModDrugDesc({});
  };

  const handleConfirmDesc = (description: string) => {
    handlePreviewDrugContinuePlan(null, {
      name: "description",
      value: description,
    });

    handleCloseModDesc();
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {props.errorMessage?.[CARD_DRUG_CONT_ID]?.showMessage &&
        props.errorMessage?.[CARD_DRUG_CONT_ID]?.error && (
          <SnackMessage
            onEvent={props.onEvent}
            onClose={() => {
              props.setProp(
                `errorMessage.${CARD_DRUG_CONT_ID}.showMessage`,
                false
              );
            }}
            error={props.errorMessage?.[CARD_DRUG_CONT_ID]?.error}
            success={props.successMessage?.[CARD_DRUG_CONT_ID]}
          />
        )}

      <CardDrugContinuePlanUX
        // seq
        DrugSelectSequence={props.DrugSelectSequence}
        // permission
        drugPermission={props.drugPermission}
        // filter
        onSetdrugContinueFilter={handleSetDrugContinueFilter}
        drugContinueFilter={props.drugContinueFilter}
        // drug list
        drugContinuePlanItems={renderDrugOrderItems}
        drugListProps={(
          _state: any,
          rowInfo: any,
          _column: any,
          _instance: any
        ) => {
          return {
            style: {
              backgroundColor:
                props.DrugSelectSequence?.id &&
                rowInfo?.original?.id === props.DrugSelectSequence?.id
                  ? "#cccccc"
                  : rowInfo?.original?.status === "REQUESTED"
                  ? "#FFFFCC"
                  : "",
            },
            onClick: () => {
              props.onEvent({
                message: "SetDrugContinuePlan",
                params: rowInfo?.original,
              });
            },
          };
        }}
        // drug name search
        onDrugSearchTextChange={(e: any) => {
          props.onEvent({
            message: "DrugSearch",
            params: { keyword: e.target.value },
          });
        }}
        drugSearchLoading={props.drugSearchLoading}
        drugSearchText={props.drugSearchText}
        drugSearchResult={renderDrugItemSearhResult}
        showSearchResult={
          props.selectedDrug === null &&
          (props?.drugSearchText?.length || 0) >= 3 &&
          (props?.drugSearchResult?.length || 0) > 0
        }
        drugSearchProps={(
          _state: any,
          rowInfo: any,
          _column: any,
          _instance: any
        ) => {
          return {
            onClick: () => {
              props.runSequence({
                sequence: "DrugSelect", // CheckADR
                selectedDrug: rowInfo?.original,
                card: CARD_DRUG_CONT_ID,
              });
            },
          };
        }}
        // Master
        drugUnits={props.DrugSelectSequence?.drugUnits || []}
        drugRoutes={props.DrugSelectSequence?.drugRoutes || []}
        drugSites={props.DrugSelectSequence?.drugSites || []}
        drugFrequencies={props.DrugSelectSequence?.drugFrequencies || []}
        drugMethods={props.DrugSelectSequence?.drugMethods || []}
        standardAdminTimeOptions={
          props.DrugSelectSequence?.standardAdminTimeOptions || []
        }
        hasFactsheetData={
          !!Object.keys(props.DrugSelectSequence?.factsheet_data || {}).length
        }
        hideDrugDescription={
          !(
            (props.DrugSelectSequence?.drugFrequencies || [])
              .find(
                (option: any) =>
                  option.value === props.DrugSelectSequence?.frequency
              )
              ?.text?.search(/\(UD\)$/g) >= 0
          )
        }
        onClickDrugFact={handleClickDrugFact}
        onDrugDescription={handleOpenModDrugDesc}
        //  planning
        onOpenModPlanning={handleOpenModPlanning}
        planningData={props.planningData}
        // SpecialDirection
        onOpenModSpecialDirection={handleOpenModSpecialDirection}
        modSpecialDirectionConfig={props.modSpecialDirectionConfig}
        // SolventSelection
        onOpenModSolventSelection={handleOpenModSolventSelection}
        modSolventSelectionConfig={props.modSolventSelectionConfig}
        // function
        onClearDrugOrderForm={handleClearDrugOrderForm}
        onPreviewDrugContinuePlan={handlePreviewDrugContinuePlan}
        onUnPreviewDrugContinuePlan={handleUnPreviewDrugContinuePlan}
        onSave={handleSave}
        onEditDrugContinuePlan={handleEditDrugContinuePlan}
        onNoteAndVerifyAndCancelDrugContinuePlan={
          handleNoteAndVerifyAndCancelDrugContinuePlan
        }
        // component
        buttonEdit={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleEditDrugContinuePlan}
            // data
            paramKey={`${CARD_DRUG_CONT_ID}_EDIT`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_DRUG_CONT_ID}_EDIT`]
            }
            // config
            color="yellow"
            name="EDIT"
            size="small"
            style={{
              display:
                props.DrugSelectSequence?.allowed_actions?.includes("EDIT") &&
                props.drugPermission?.action_TPD_DrugContinuePlan_EDIT
                  ? ""
                  : "none",
            }}
            title="EDIT"
          />
        }
        buttonEditQuantity={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleEditDrugContinuePlan}
            // data
            paramKey={`${CARD_DRUG_CONT_ID}_EDIT_QUANTITY`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_DRUG_CONT_ID}_EDIT_QUANTITY`]
            }
            // config
            color="yellow"
            name="EDIT_QUANTITY"
            size="small"
            style={{
              display:
                props.DrugSelectSequence?.allowed_actions?.includes(
                  "EDIT_QUANTITY"
                ) &&
                props.drugPermission?.action_TPD_DrugContinuePlan_EDIT_QUANTITY
                  ? ""
                  : "none",
            }}
            title="EDIT QUANTITY"
          />
        }
        buttonRequest={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={`${CARD_DRUG_CONT_ID}_REQUEST`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_DRUG_CONT_ID}_REQUEST`]
            }
            // config
            color="green"
            name="REQUEST"
            size="small"
            style={{
              display:
                props.DrugSelectSequence?.id ||
                !props.drugPermission?.action_TPD_DrugContinuePlan_REQUEST
                  ? "none"
                  : "",
            }}
            title="REQUEST"
          />
        }
      />

      {props.errorMessage?.[CARD_DUPLICATE_REASON] && (
        <CardDuplicateDrugOrders
          // function
          onClose={() =>
            props.setProp("errorMessage", {
              ...props.errorMessage,
              [CARD_DUPLICATE_REASON]: null,
            })
          }
          onApprove={handleConfirm}
          // options
          duplicateReasonsOptions={props.duplicateReasonsOptions}
          // data
          duplicateList={[props.errorMessage?.[CARD_DUPLICATE_REASON]]}
        />
      )}

      {props.errorMessage?.[CARD_DUPLICATE_CLASSIFICATION_REASON] && (
        <CardDuplicateDrugClassificationOrders
          // function
          onClose={() =>
            props.setProp("errorMessage", {
              ...props.errorMessage,
              [CARD_DUPLICATE_CLASSIFICATION_REASON]: null,
            })
          }
          onApprove={handleConfirmDrugGroupDuplicateWithReason}
          // options
          duplicateReasonsOptions={props.duplicateReasonsOptions}
          // data
          duplicateList={
            props.errorMessage?.[CARD_DUPLICATE_CLASSIFICATION_REASON]
          }
        />
      )}

      {props.DrugSelectSequence?.claim_payload?.isAdd && (
        <CardDrugClaimQA
          // function
          onEvent={props.onEvent}
          onSave={handleSaveDrugClaimQA}
          // selected
          selectedEncounter={props.selectedEncounter}
          selectedEmr={props.selectedEmr}
          selectedProgressCycle={props.selectedProgressCycle}
          // data
          estimate={props.DrugSelectSequence?.claim_payload || {}}
          orderType="CONTINUE_PLAN"
          notSetAnswer={true}
        />
      )}

      {/* ADR */}
      <Modal size="tiny" open={!!props.DrugSelectSequence?.adrType}>
        <ModCheckADR
          adrType={props.DrugSelectSequence?.adrType}
          adrDetail={(props.DrugSelectSequence?.adrNotes || "")
            .split("\n")
            .map((note: any, note_index: number) => (
              <div style={{ padding: "5px" }} key={note_index}>
                {note}
              </div>
            ))}
          confirmADRNote={props.DrugSelectSequence?.note_adverse_reaction}
          needNote={["NOTED", "PAUSED"].includes(
            props.DrugSelectSequence?.adrType || ""
          )}
          acknowledge={() => {
            props.onEvent({
              message: "RunSequence",
              params: { sequence: "DrugSelect", feedback: "acknowledge" },
            });
          }}
          confirmOrder={() => {
            props.onEvent({
              message: "RunSequence",
              params: { sequence: "DrugSelect", feedback: "confirm" },
            });
          }}
          cancelOrder={() => {
            props.onEvent({
              message: "RunSequence",
              params: { sequence: "DrugSelect", feedback: "cancel" },
            });
          }}
          changeNote={(e: any) => {
            props.setProp(
              "DrugSelectSequence.note_adverse_reaction",
              e.target.value
            );
          }}
        />
      </Modal>

      {/* DI */}
      <Modal size="tiny" open={!!props.DrugSelectSequence?.diFeedback}>
        <ModCheckDI
          diDetail={(props.DrugSelectSequence?.diItems || []).map(
            (item: any, index: number) => (
              <div key={index} style={{ padding: "5px" }}>
                <div>{item.description}</div>
                {item.members_name.map(
                  (member_name: string, member_index: number) => (
                    <div
                      key={member_index}
                    >{`${member_index}. ${member_name}`}</div>
                  )
                )}
              </div>
            )
          )}
          needNote={!props.DrugSelectSequence?.diLock}
          acknowledge={() => {
            props.onEvent({
              message: "RunSequence",
              params: { sequence: "DrugSelect", feedback: "acknowledge" },
            });
          }}
          confirmOrder={() => {
            props.onEvent({
              message: "RunSequence",
              params: { sequence: "DrugSelect", feedback: "confirm" },
            });
          }}
          cancelOrder={() => {
            props.onEvent({
              message: "RunSequence",
              params: { sequence: "DrugSelect", feedback: "cancel" },
            });
          }}
          changeNote={(e: any) => {
            props.setProp("DrugSelectSequence.confirmDINote", e.target.value);
          }}
        />
      </Modal>

      {/* ModNote */}
      <Modal
        closeIcon
        open={props.modNoteData?.open}
        closeOnDimmerClick={true}
        style={{ width: "auto" }}
        onClose={handleCloseModNote}
      >
        <Modal.Content>
          <ErrorMessage error={props.modNoteData?.error} />

          <Grid>
            {props.modNoteData?.error?.["note"] && (
              <Form style={{ width: "100%" }}>
                <Grid.Column width={16}>ข้อความ</Grid.Column>
                <Grid.Column width={16}>
                  <TextArea
                    style={{ width: "100%" }}
                    name="note"
                    onChange={handleChangeModNoteData}
                  />
                </Grid.Column>
              </Form>
            )}

            {props.modNoteData?.error?.["doctor"] && (
              <>
                <Grid.Column width={16}>แพทย์</Grid.Column>
              </>
            )}

            {props.modNoteData?.error?.["first_quantity"] && (
              <>
                <Grid.Column width={16}>
                  จำนวนที่จะจ่ายครั้งแรกในวันนี้ (ใส่ 0 หากไม่จ่าย)
                </Grid.Column>

                <Grid.Column width={14}>
                  <Input
                    fluid
                    type="number"
                    name="first_quantity"
                    value={props.modNoteData?.first_quantity || ""}
                    onChange={handleChangeModNoteData}
                  />
                </Grid.Column>
                <Grid.Column width={2} style={{ paddingLeft: 0, margin: "auto" }}>
                  <Label size="large">{props.modNoteData?.stockUnitName}</Label>
                </Grid.Column>
              </>
            )}

            <Grid.Column width={13}></Grid.Column>
            <Grid.Column width={3}>
              <Button
                fluid
                color="green"
                size="small"
                style={{ minWidth: "max-content" }}
                onClick={() => {
                  if (
                    props.modNoteData?.error?.non_field_errors?.[0]?.includes(
                      "ยังไม่ถูกรับทราบโดย"
                    )
                  ) {
                    /// GoToMedreconcile
                    handleSaveModNote(true);
                  } else {
                    handleSaveModNote();
                  }
                }}
              >
                {props.modNoteData?.error?.non_field_errors?.[0]?.includes(
                  "ยังไม่ถูกรับทราบโดย"
                )
                  ? "รับทราบ"
                  : "SAVE"}
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>

      {/* Planning */}
      <Modal
        closeIcon
        open={props.modPlanningConfig?.open}
        closeOnDimmerClick={true}
        onClose={handleCloseModPlanning}
      >
        <ModPlanning
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
          // selected data
          selectedPatient={props.selectedPatient}
          selectedDrug={props.selectedDrug}
          // planning
          modPlanningConfig={props.modPlanningConfig}
          planningData={props.planningData}
        />
      </Modal>

      {/* SpecialDirection */}
      <Modal
        closeIcon
        open={props.modSpecialDirectionConfig?.open}
        closeOnDimmerClick={true}
        onClose={handleCloseModSpecialDirection}
      >
        <ModSpecialDirection
          // function
          onEvent={props.onEvent}
          // seq
          DrugSelectSequence={props.DrugSelectSequence}
          // data
          hideDuration={true}
          hideWeekday={true}
          hideAdminTime={false}
          hideIntraveneous={true}
        />
      </Modal>

      {/* SolventSelection */}
      <Modal
        closeIcon
        open={props.modSolventSelectionConfig?.openModal}
        closeOnDimmerClick={true}
        onClose={handleCloseModSolventSelection}
      >
        <ModSolventSelection
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // permission
          drugPermission={props.drugPermission}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          // seq
          DrugSelectSequence={props.DrugSelectSequence}
          // Master
          drugUnits={props.DrugSelectSequence?.drugUnits || []}
          // selected data
          selectedDrug={props.selectedDrug}
          // data
          drugOrderType={DRUG_ORDER_TYPE}
          modSolventSelectionConfig={props.modSolventSelectionConfig}
        />
      </Modal>

      {/* DoctorCertificateCheck */}
      <Modal open={props.modDoctorCertificate.open} size="small">
        <ModCheckDoctorCertificateUX
          narcoticTypeDetail={htmlParse(
            props.modDoctorCertificate?.narcoticDrugType || ""
          )}
          doctorCertificate={props.modDoctorCertificate?.doctorCertificate}
          onInputChange={(_e: any, v: any) => {
            props.setProp("modDoctorCertificate.doctorCertificate", v.value);
          }}
          onApprove={() => {
            props.onEvent({
              message: "HandleCheckDoctorCertificate",
              params: {},
            });
          }}
          onCancel={async () => {
            await props.setProp("modDoctorCertificate.open", false);

            props.setProp(
              `buttonLoadCheck.${props.modDoctorCertificate.card}_${props.modDoctorCertificate.action}`,
              ""
            );
          }}
        />
      </Modal>

      {/* DoctorCertificateCheck error*/}
      <Modal
        open={props.modDoctorCertificate.openModConfirm}
        style={{ height: "auto", width: "300px" }}
      >
        <ModCertificateErrorUX
          onConfirm={() => {
            props.setProp("modDoctorCertificate.openModConfirm", false);
          }}
        />
      </Modal>

      {/* drug-lab interaction */}
      <Modal
        open={props.modDrugLabInteraction?.open}
        closeOnDimmerClick={false}
      >
        <ModDrugLabInteraction
          // main function
          runSequence={props.runSequence}
          setProp={props.setProp}
          onEvent={props.onEvent}
          // data
          modDrugLabInteraction={props.modDrugLabInteraction}
          currentDoctor={props.currentDoctor}
        />
      </Modal>

      {/* drug-disease */}
      <Modal open={props.modDrugDisease?.open} closeOnDimmerClick={false}>
        <ModDrugDisease
          // main function
          runSequence={props.runSequence}
          setProp={props.setProp}
          onEvent={props.onEvent}
          // data
          modDrugDisease={props.modDrugDisease}
          currentDoctor={props.currentDoctor}
        />
      </Modal>

      {/* drug-interaction */}
      <Modal open={props.modDrugInteraction?.open} closeOnDimmerClick={false}>
        <ModDrugInteraction
          // main function
          runSequence={props.runSequence}
          setProp={props.setProp}
          onEvent={props.onEvent}
          // data
          modDrugInteraction={props.modDrugInteraction}
          currentDoctor={props.currentDoctor}
        />
      </Modal>

      {/* Action Log */}
      <Modal
        closeIcon
        open={props.DrugSelectSequence?.actionLogs?.open}
        closeOnDimmerClick={true}
        onClose={() => {
          props.setProp("DrugSelectSequence.actionLogs.open", false);
        }}
      >
        <DrugActionLog
          data={props.DrugSelectSequence?.actionLogs?.data?.items || []}
        />
      </Modal>

      {/* MedReconcile Check*/}
      <ModMedReconcileAlert
        // medReconcileCheck={props.medReconcileCheck}
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        onApprove={() => {
          props.setProp(
            "medReconcileCheck.open_card_med_reconciliation",
            false
          );

          props.onEvent({
            message: "OpenMedReconcileFromWarning",
            params: {
              medReconcileCheck: props.medReconcileCheck,
              selecteRecordViewIndex: props.medReconcileIndex,
            },
          });

          // find med reconcile Index
          // if (props.medReconcileIndex !== -1) {
          //   props.setProp("selectedRecordViewIndex", props.medReconcileIndex)
          // }
        }}
      >
        {props.medReconcileCheck?.med_reconciliation != null
          ? `Med Reconcile ${
              props.medReconcileCheck?.med_reconciliation_label
            } ยังไม่ได้ถูกรับทราบโดย${
              props?.django?.user?.role_types?.includes("DOCTOR")
                ? "แพทย์"
                : "เภสัชกร"
            }`
          : `ยังไม่ได้สร้าง Medication Reconciliation สำหรับ PlanTransfer ${props.medReconcileCheck?.plan_transfer_id}`}
      </ModMedReconcileAlert>

      <ModDrugFact
        open={openModFactSheet}
        drugDetail={props.DrugSelectSequence}
        // callback
        onClose={handleCloseFactSheet}
      />

      <ModDescriptionUD
        open={!!modDrugDesc.open}
        description={modDrugDesc.desc}
        // config
        editable={true}
        // callback
        onApprove={handleConfirmDesc}
        onClose={handleCloseModDesc}
      />
    </div>
  );
};

CardDrugContinuePlan.defaultProps = CardDrugContinuePlanInitial;

export default React.memo(CardDrugContinuePlan);
