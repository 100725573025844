import React from 'react';
import CardLayout from '../common/CardLayout';
import FilterDateRange from '../common/FilterDateRange'
import { Form, Tab } from 'semantic-ui-react';
import CardNurseNoteView from './CardNurseNoteView';
import CardPatientAssessmentView from './CardPatientAssessmentView';
import CardDischargePlanningTabView from './CardDischargePlanningTabView';
import CardDischargePlanningView from './CardDischargePlanningView';
import CardDischargeTeachingView from './CardDischargeTeachingView';
import CardMultiDisciplinaryView from '../PTM/CardMultiDisciplinaryView';
import CardNurseDischargeTeachingView from './CardNurseDischargeTeachingView';
import CardDischargeNursingView from './CardDischargeNursingView';
import CardANCRecordView from './CardANCRecordView';
import CardNursingDiagnosisView from './CardNursingDiagnosisView';

const styles = {
  tab: {
    width: '105%',
    fontSize: 13.5
  },
  documentType: {
    fontWeight: 'bold',
    color: '#2F80ED',
    fontSize: 16
  }
}

const NURSE_RECORD_ACTIVE_INDEX = {
  NURSE_NOTE: 0,
  PATIENT_ASSESSMENT: 1,
  NURSING_DISCHARGE_SUMMARY: 2,
  DISCHARGE_TEACHING: 3,
  MULTIDISCIPLINARY: 4,
  ANC_RECORD: 5,
  NURSING_DIAGNOSIS: 6,
};

const HIDE_FILTER = [NURSE_RECORD_ACTIVE_INDEX.NURSING_DISCHARGE_SUMMARY, NURSE_RECORD_ACTIVE_INDEX.DISCHARGE_TEACHING, NURSE_RECORD_ACTIVE_INDEX.ANC_RECORD]

const CardNurseRecord = React.forwardRef((props, ref) => {

  const nursingDiagnosisRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
    nursingDiagnosisRef: nursingDiagnosisRef
  }));

  const panes = [
    {
      menuItem: 'Nurse\'s Note',
      render: () => (
        <CardNurseNoteView
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          defaultBackground={false}
          nurseNote={props.nurseNote}
          onGetNurseNote={props.onGetNurseNote}
          nurseNoteLoading={props.nurseNoteLoading}
          enableMargin={false}
        />
      )
    },
    {
      menuItem: 'แบบประเมินแรกรับ',
      render: () => (
        <CardPatientAssessmentView
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          defaultBackground={false}
          onGetPatientAssessment={props.onGetPatientAssessment}
          patientAssessment={props.patientAssessmentData}
          patientAssessmentLoading={props.patientAssessmentLoading}
          clinicalTermData={props.clinicalTermData}
          enableMargin={false}
          encounterType={props.encounterType}
        />
      )
    },
    {
      menuItem: 'Nursing Discharge Summary',
      render: () => (
        <CardDischargePlanningTabView
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          defaultBackground={false}
          onGetDischargePlanning={props.onGetDischargePlanning}
          dischargePlanningLoading={props.dischargePlanningLoading}
          enableMargin={false}
        >
          <CardDischargePlanningView
            key='dischargePlanning'
            dischargePlanning={props.dischargePlanning}
          />
          <CardNurseDischargeTeachingView
            key='dischargeTeaching'
            dischargePlanning={props.dischargePlanning}
          />
          <CardDischargeNursingView
            key='dischargeSummary'
            dischargePlanning={props.dischargePlanning}
          />
        </CardDischargePlanningTabView>
      )
    },
    {
      menuItem: 'Discharge Teaching',
      render: () => (
        <CardDischargeTeachingView
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          defaultBackground={false}
          onGetDischargeTeaching={props.onGetDischargeTeaching}
          reassessmentLoading={props.reassessmentLoading}
          reassessmentData={props.reassessmentData}
          enableMargin={false}
          encounterType={props.encounterType}
        />
      )
    },
    {
      menuItem: 'Multidisciplinary',
      render: () => (
        <CardMultiDisciplinaryView
          controller={props.crdMultiDisciplinaryController}
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          defaultBackground={false}
          onGetMultidisciplinary={props.onGetMultidisciplinary}
          multidisciplinaryLoading={props.multidisciplinaryLoading}
          multidisciplinaryData={props.multidisciplinaryData}
          enableMargin={false}
          encounterId={props.encounterId}
        />
      )
    },
    {
      menuItem: 'ANC Record',
      render: () => (
        <CardANCRecordView
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          defaultBackground={false}
          getANCRecordPDF={props.getANCRecordPDF}
          encounterId={props.encounterId}
          cardLayoutClassName='noCardMargin'
        />
      )
    },
    {
      menuItem: 'Nursing Diagnosis',
      render: () => (
        <CardNursingDiagnosisView
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          defaultBackground={false}
          getANCRecordPDF={props.getANCRecordPDF}
          encounterId={props.encounterId}
          cardLayoutClassName='noCardMargin'
          getNurseDiagnosisResultGroup={props.getNurseDiagnosisResultGroup}
          getNurseDiagnosisPDF={props.getNurseDiagnosisPDF}
          ref={nursingDiagnosisRef}
        />
      )
    },
  ]

  return (
    <CardLayout
      closeable={props.closeable}
      toggleable={props.toggleable}
    >
      <Form>
        {/* <Form.Group inline>
          <Form.Field>
            ประเภท
        </Form.Field>
          <Dropdown
            search
            selection
            placeholder='แสดงทั้งหมด'
          />
        </Form.Group> */}
        <Form.Group inline>
          <Form.Field width={2} style={styles.documentType}>
            ประเภทเอกสาร
          </Form.Field>
          <Form.Field >
            <FilterDateRange
              style={{ display: HIDE_FILTER.includes(props.activeIndex) ? 'none' : null }}
              onStartDateChange={props.onStartDateChange}
              onEndDateChange={props.onEndDateChange}
              onFilterDate={props.onNurseRecordSearch}
            />
          </Form.Field>
          {/* <Form.Field>
            <Button
              color="teal"
              content="พิมพ์"
              icon="print"
              labelPosition="left"
              onClick={props.onPrintClick}
            />
          </Form.Field> */}
        </Form.Group>
      </Form>
      <Tab
        grid={{ paneWidth: 14, tabWidth: 2 }}
        menu={{ vertical: true, style: styles.tab, className: 'yellowHover' }}
        panes={panes}
        onTabChange={(e, { activeIndex }) => props.setActiveIndex(activeIndex)}
      />
    </CardLayout>
  )
})

CardNurseRecord.defaultProps = {
  closeable: true,
  toggleable: true,
  nurseNote: [],
  onGetNurseNote: () => { },
  onGetPatientAssessment: () => { },
  onPrintClick: () => {},
  encounterId: null,
  getNurseDiagnosisResultGroup: () => [null, null],
};

export default CardNurseRecord;