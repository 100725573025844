import * as I from "./BillPayment";
import * as BillingHistoryI from "./BillingHistory";
import * as MedicalFeeHistoryI from "./MedicalFeeHistory"
import * as InvoicesSummaryI from "./InvoicesSummary"
import * as FinancialAmountSetI from "./FinancialAmountSet"

const SequencePattern: { [name: string]: { [flavor: string] : { [func: string]: any }}} = {
  BillPayment: { 
    default: {
      START: I.BeforePaymentCheck,
      PaymentCalculate: I.PaymentCalculate,
      CreateReceipt: I.CreateReceipt,
    }    
  },
  BillingHistory: {
    default: {
      START: BillingHistoryI.Start,
      Action: BillingHistoryI.Action,
    }
  },
  MedicalFeeHistory: {
    default:{
      START:MedicalFeeHistoryI.Start,
      SearchAndEdit:MedicalFeeHistoryI.SearchAndEdit
    }
  }, 
  InvoicesSummary: { 
    default: {
      START: InvoicesSummaryI.Start
    }
  },
  FinancialAmountSet: {
    default: {
      START: FinancialAmountSetI.Start,
      Action: FinancialAmountSetI.Action,
      ModalAction: FinancialAmountSetI.ModalAction
    }
  }
};

export default SequencePattern;
