import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Label,
  Dropdown,
  Button,
  Input
} from 'semantic-ui-react'

const CardAdmitUX = (props: any) => {
    return(
      <div>
        <div
          className="ui segment raised yellow"
          style={{boxShadow: "none"}}>
          
          <Label
            className="large title"
            color="yellow"
            ribbon={true}>
            จองห้อง
          </Label>
          <div
            className="ui form">
            
            <div
              style={{width: "100%"}}>
              {props.ErrorMessage}
            </div>
            <FormGroup
              inline={true}>
              <FormField
                className="required"
                inline={true}
                width={2}>
                <label
                  style={{textAlign: "right", width: "100%"}}>
                  HN
                </label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{width: "100%"}}>
                  {props.PatientSearchBox}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{textAlign: "right", width: "100%"}}>
                  ชื่อ-สกุล
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Label
                  className="large fluid"
                  style={{width: "100%"}}>
                  {props.fullName || "-"}
                </Label>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{textAlign: "right", width: "100%"}}>
                  วัน/เดือน/ปีเกิด
                </label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Label
                  className="large fluid">
                  {props.birthdate || "-"}
                </Label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                className="required"
                inline={true}
                width={2}>
                <label
                  style={{textAlign: "right", width: "100%"}}>
                  เลขที่ใบจอง
                </label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Dropdown
                  fluid={true}
                  onChange={props.onChangeReserveCode}
                  options={props.reserveOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.reserveCode}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{textAlign: "right", width: "100%"}}>
                  สถานะใบจอง
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Label
                  className="large fluid">
                  {props.reserveStatus || "-"}
                </Label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                className="required"
                inline={true}
                width={2}>
                <label
                  className="required"
                  style={{textAlign: "right", width: "100%"}}>
                  ต้องการจองห้อง
                </label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{width: "100%"}}>
                  {props.CardRoomSearch}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{textAlign: "right", width: "100%"}}>
                  ประเภทหอผู้ป่วย
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Label
                  className="large fluid">
                  {props.roomName || "-"}
                </Label>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{textAlign: "right", width: "100%"}}>
                  สถานะห้อง
                </label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Label
                  className="large fluid">
                  {props.roomStatus || "-"}
                </Label>
              </FormField>
            </FormGroup>
            <div
              className="fields inline"
              style={{display: "none"}}>
              
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{textAlign: "right", width: "100%"}}>
                  Consultation
                </label>
              </FormField>
              <FormField
                inline={true}
                width={15}>
                <Input>
                </Input>
              </FormField>
            </div>
            <div
              className="fields inline"
              style={{display: "none"}}>
              
              <FormField
                inline={true}
                width={5}>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{textAlign: "right", width: "100%"}}>
                  รหัสผู้บันทึก
                </label>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <Input>
                </Input>
              </FormField>
            </div>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={13}>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Button
                  color={"green"}
                  disabled={!props.allowSave}
                  fluid={true}
                  onClick={props.onSave}>
                  บันทึกรายการ
                </Button>
              </FormField>
            </FormGroup>
          </div>
        </div>
      </div>
    )
}

export default CardAdmitUX

export const screenPropsDefault = {}

/* Date Time : Thu Jun 23 2022 07:24:38 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui segment raised yellow"
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormGroup",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormGroup",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormGroup",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormGroup",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 21,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 21,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 21,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 21,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 21,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 21,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PatientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุล"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Label",
      "parent": 30,
      "props": {
        "children": {
          "type": "code",
          "value": "props.fullName || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large fluid"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "จองห้อง"
        },
        "className": {
          "type": "value",
          "value": "large title"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "ribbon": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน/เดือน/ปีเกิด"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Label",
      "parent": 32,
      "props": {
        "children": {
          "type": "code",
          "value": "props.birthdate || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large fluid"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 22,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบจอง"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Dropdown",
      "parent": 41,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeReserveCode"
        },
        "options": {
          "type": "code",
          "value": "props.reserveOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.reserveCode"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะใบจอง"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Label",
      "parent": 43,
      "props": {
        "children": {
          "type": "code",
          "value": "props.reserveStatus || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large fluid"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 23,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 23,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 23,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 23,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 23,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 23,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 26,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 26,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "ต้องการจองห้อง"
        },
        "className": {
          "type": "value",
          "value": "required"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "code",
          "value": "props.CardRoomSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทหอผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Label",
      "parent": 51,
      "props": {
        "children": {
          "type": "code",
          "value": "props.roomName || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large fluid"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะห้อง"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Label",
      "parent": 53,
      "props": {
        "children": {
          "type": "code",
          "value": "props.roomStatus || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large fluid"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Button",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึกรายการ"
        },
        "color": {
          "type": "code",
          "value": "\"green\""
        },
        "disabled": {
          "type": "code",
          "value": "!props.allowSave"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSave"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "fields inline"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "fields inline"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormField",
      "parent": 72,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 72,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 72,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 75,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสผู้บันทึก"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Input",
      "parent": 76,
      "props": {
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormField",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormField",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "label",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": "Consultation"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Input",
      "parent": 80,
      "props": {
      },
      "seq": 82,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": false,
  "name": "CardAdmitUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
