import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Calendar
} from 'react-big-calendar'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button
} from 'semantic-ui-react'

const CardAppointmentSummaryUX = (props: any) => {
    return(
      <div
        style={{height: "90vh", display: "flex"}}>
        <div
          style={{flex: 1}}>
          
          <div>
            
            <div
              style={{margin: "10px 5px 0px 5px"}}>
              ค้นหาโดยระบุวันที่
            </div>
            <div
              style={{display: "flex", alignItems: "center", justifyContent: "flex-start", margin: "10px 5px",gap:"10px"}}>
              {}
              <div>
                {props.startDateBox}
              </div>
              <div>
                ถึง
              </div>
              <div>
                {props.endDateBox}
              </div>
            </div>
            <div
              style={{margin: "10px 5px 0px 5px"}}>
              ค้นหาโดยระบุเวลา
            </div>
            <div
              style={{display: "flex", alignItems: "center", justifyContent: "flex-start", margin: "10px 5px",gap:"10px"}}>
              
              <div>
                {props.startTime}
              </div>
              <div>
                ถึง
              </div>
              <div>
                {props.endTime}
              </div>
            </div>
            <div
              style={{margin: "10px 5px 0px 5px", display: "flex"}}>
              
              <div
                style={{ width: "50%"}}>
                ค้นหาทันตเแพทย์
              </div>
              <div>
                ค้นหาประเภทนัดหมาย
              </div>
            </div>
            <div
              style={{display: "flex", alignItems: "center", justifyContent: "flex-start", margin: "10px 5px", gap: "10px"}}>
              
              <div
                style={{width: "50%"}}>
                {props.searchDoctorBox}
              </div>
              <div>
                {props.appointmentType}
              </div>
            </div>
            <div
              style={{textAlign: "right", padding: "5px"}}>
              
              <Button
                color="blue"
                loading={props.loadingFilterAppList}
                onClick={props.onSearchClick}>
                ค้นหา
              </Button>
            </div>
          </div>
          <div
            style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            
            <div
              style={{margin: "10px 5px 0px 5px"}}>
              สรุปรายการนัดหมายผู้ป่วย
            </div>
            <div
              style={{flex: 1}}>
              
            </div>
            <Button
              color="green"
              onClick={props.showChart}
              size="tiny"
              style={{display: "none"}}>
              รายงาน
            </Button>
            <Button
              color="blue"
              loading={props.loadingPrintAppList}
              onClick={props.print}
              size="tiny">
              พิมพ์
            </Button>
          </div>
          <Table
            data={props.appointmentList || []}
            headers="วันเวลา,ผู้ป่วย,แพทย์"
            keys="datetime,patient,provider"
            showPagination={false}
            style={{height: "60vh"}}>
          </Table>
        </div>
        <div
          style={{flex: 3}}>
          
          <Calendar
            eventPropGetter={props.eventPropGetter}
            events={props.blockList}
            localizer={props.localizer}
            onRangeChange={props.onRangeChange}
            onSelectEvent={props.onSelectEvent}>
          </Calendar>
        </div>
      </div>
    )
}

export default CardAppointmentSummaryUX

export const screenPropsDefault = {}

/* Date Time : Thu Sep 15 2022 08:56:52 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{height: \"90vh\", display: \"flex\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "react-big-calendar",
      "id": 1,
      "name": "Calendar",
      "parent": 3,
      "props": {
        "eventPropGetter": {
          "type": "code",
          "value": "props.eventPropGetter"
        },
        "events": {
          "type": "code",
          "value": "props.blockList"
        },
        "localizer": {
          "type": "code",
          "value": "props.localizer"
        },
        "onRangeChange": {
          "type": "code",
          "value": "props.onRangeChange"
        },
        "onSelectEvent": {
          "type": "code",
          "value": "props.onSelectEvent"
        }
      },
      "seq": 1,
      "void": true
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 3}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "สรุปรายการนัดหมายผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px 5px 0px 5px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 5,
      "name": "Table",
      "parent": 2,
      "props": {
        "data": {
          "type": "code",
          "value": "props.appointmentList || []"
        },
        "headers": {
          "type": "value",
          "value": "วันเวลา,ผู้ป่วย,แพทย์"
        },
        "keys": {
          "type": "value",
          "value": "datetime,patient,provider"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"60vh\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงาน"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.showChart"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingPrintAppList"
        },
        "onClick": {
          "type": "code",
          "value": "props.print"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"flex-start\", margin: \"10px 5px\",gap:\"10px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"flex-start\", margin: \"10px 5px\",gap:\"10px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"flex-start\", margin: \"10px 5px\", gap: \"10px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาโดยระบุวันที่"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px 5px 0px 5px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startDateBox"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endDateBox"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาโดยระบุเวลา"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px 5px 0px 5px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startTime"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endTime"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px 5px 0px 5px\", display: \"flex\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchDoctorBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"50%\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.appointmentType"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", padding: \"5px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Button",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingFilterAppList"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearchClick"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาประเภทนัดหมาย"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาทันตเแพทย์"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\"}"
        }
      },
      "seq": 29,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardAppointmentSummaryUX",
  "project": "cudent2",
  "screenPropsDefault": {
  },
  "width": 85
}

*********************************************************************************** */
