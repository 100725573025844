import React, { useState, useRef, MutableRefObject, CSSProperties } from 'react'
import { Dropdown } from 'semantic-ui-react';

const ColumnStyle = {
    background: "rgb(255, 255, 204)",
    marginTop: -7,
    marginLeft: -4,
    width: "calc(100% + 10px)",
    height: "calc(100% + 14px)",
    padding: "7px 7px",
  } as CSSProperties;

const DropDownColumn:  React.FC<any> = (props) => {
    // showText use when options of Dropdowncolumn have many child with different
    const [isEdit, setIsEdit] = useState<boolean>(false);
  
    const dropdownRef = useRef() as MutableRefObject<any>;
  
    const handleClick = () => {
      if(props.readOnly){
        setIsEdit(false);
      }
      else{
        setIsEdit(true);
        setTimeout(() => {
          dropdownRef.current?.open();
        }, 100);
      } 
    };
  
    const handleClose = () => {
      setIsEdit(false)
    }
  
    const handleValue = (e, data) => {
      props.onDataChanged?.(data.value);

      setIsEdit(false);
    };
    
    return (
    <div style={ColumnStyle} onClick={handleClick}>
      {isEdit ? (
        <Dropdown
          ref={dropdownRef}
          className="fluidDropdown"
          style={{position: "absolute", maxWidth: "200px"}}
          disabled={props.readOnly}
          selection
          value={props.value}
          options={props.options}
          onClose={handleClose}
          onBlur={handleClose}
          onChange={handleValue}
          onClick={handleClick}
      />
      ) : (
        <div style={{ textAlign: "center" }}>{props.options.find(o => o.value === props.value)?.text || props.showText || ""}</div>
      )}
    </div>
    );
  };

  export default DropDownColumn