import React from "react";
import PropTypes from "prop-types";
import * as CONSTANT from "../../utils/constant";
import * as Common from "../common";
import * as PTM from "../PTM";
import { Label } from "semantic-ui-react";

const CardPainAssessment = React.forwardRef((props, ref) => {

  const [isLoading, setIsLoading] = React.useState(false)
  const [status, setStatus] = React.useState(null)
  const [painAssessmentData, setPainAssessmentData] = React.useState([])
  const [subFormViewData, setSubFormViewerData] = React.useState([])

  React.useImperativeHandle(ref, () => ({
    refresh: ({ encounterId } = {}) => {
      refresh({ encounterId });
    }
  }));

  React.useEffect(() => {
    refresh({ encounterId: props.encounterId });
  }, [props.encounterId]);

  React.useEffect(() => {
    formatFormViewData()
  }, [painAssessmentData])

  const refresh = ({ encounterId } = {}) => {
    getFormDataLatest({ encounterId });
  };

  const formatFormViewData = () => {
    let newArr = [...painAssessmentData]
    newArr.forEach((item) => {
      item.nursing = NURSING[item.nursing]
    })
    setSubFormViewerData(newArr);
  }

  const getFormDataLatest = async ({ encounterId }) => {
    setIsLoading(true)
    const [data, error] = await props.controller.getFormDataLatest({
      encounterId: encounterId
        ? encounterId
        : props.patientData.ENCOUNTER.encounter_id,
      formCode: "CardPainAssessment",
      formVersion: "0.1"
    });
    if (data) {
      setStatus(data.status);
      setPainAssessmentData(data.data);
    }
    setIsLoading(false)
  };

  let statusColor = "black";
  if (status === CONSTANT.FORM_DATA_STATUS.DRAFT) {
    statusColor = "yellow";
  } else if (status === CONSTANT.FORM_DATA_STATUS.EDITED) {
    statusColor = "olive";
  } else if (status === CONSTANT.FORM_DATA_STATUS.CONFIRMED) {
    statusColor = "green";
  } else if (status === CONSTANT.FORM_DATA_STATUS.CANCELLED) {
    statusColor = "red";
  }

  let labelStatus = <Label color={statusColor}>{status}</Label>;

  return (
    <Common.CardLayout
      headerColor="grey"
      loading={isLoading}
      titleText="Pain Assessment"
      onClose={props.onClose}
      titleContent={labelStatus}
    >
      {props.showSummary ? (
        <PTM.SubFormViewer
          fieldList={rmdFieldList}
          fieldMap={rmdFieldMap}
          data={subFormViewData}
        />
      ) : (
        "TODO ---> Implement ShowSummary = false"
      )}
    </Common.CardLayout>
  )
})

const NURSING = {
  'NA': 'NA',
  'NON': 'ไม่จำเป็น',
  'REL': 'จัดท่าเพื่อลดความเจ็บปวด',
  'HCP': 'วาง Hot/Cold pack',
  'REP': 'รายงานแพทย์ pain score > 3',
  'YRX': 'มี Rx',
  'NRX': 'ไม่มี Rx',
  'Other': 'อื่น ๆ',
}

const rmdFieldList = ["user_fullname", "date", "pain_score", "pain_assessment_tool", "location", "nursing"]

const rmdFieldMap = {
  "user_fullname": "ผู้บันทึก/แก้ไข",
  "pain_score": "Pain Score",
  "pain_assessment_tool": "Pain Assessment Tool",
  "date": "Date Time",
  "location": "Pain Location",
  "nursing": "การให้การพยาบาลผู้ป่วย",
}

CardPainAssessment.defaultProps = {
  onClose: () => {},
  controller: {},
  encounterId: null,
  showSummary: false,
};

CardPainAssessment.propTypes = {
  onClose: PropTypes.func,
  controller: PropTypes.object,
  encounterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showSummary: PropTypes.bool,
};

export default React.memo(CardPainAssessment);
