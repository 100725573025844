import moment from "moment";
import { formatDate } from "react-lib/utils/dateUtils";

const STATUS: Record<string, string> = {
  PENDING: "รอนัดหมาย",
  STALL: "พักคิว",
  APPOINTMENT: "นัดหมายแล้ว",
  CANCEL: "ถูกยกเลิกจากผู้ป่วย",
  ALL: "ทุกสถานะนัดหมาย",
};

const FormWaitingList = (props: any) => {
  console.log(props.data);

  return {
    pageSize: "A4",
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 14,
    },
    pageMargins: [20, 115, 20, 20],
    styles: {
      facultyHeader: {
        fontSize: 19,
        bold: true,
      },
      divisionHeader: {
        fontSize: 18,
      },
      tableHeader: {
        fontSize: 14,
        alignment: "center",
      },
      fieldKey: {
        bold: true,
        fontSize: 13,
      },
      fieldValue: {
        fontSize: 13,
        alignment: "center",
      },
    },
    header: {
      margin: [20, 10, 20, 10],
      stack: [
        {
          columns: [
            { margin: [0, 0, 0, 0], width: 80, image: "logochula", alignment: "left" },
            {
              margin: [-15, 10, 0, 0],
              width: "*",
              stack: [
                {
                  text: "CHULALONGKORN UNIVERSITY, FACULTY OF DENTISTRY",
                  style: "facultyHeader",
                  alignment: "center",
                },
                {
                  text: [
                    { text: props.data?.division_name || "", style: "divisionHeader", alignment: "center" },
                    { text: ` กลุ่มงานคิว : `, style: "divisionHeader", alignment: "center" },
                    { text: `${props.data?.waiting_list_name || ""} (${STATUS[props.data?.status_name]})`, style: "divisionHeader", alignment: "center" },
                  ],
                },
                // {
                //   alignment: "center",
                //   text: [
                //     { text: "ทันตแพทย์/นิสิต : ", style: "divisionHeader" },
                //     { text: props.data?.provider_name || "", style: "divisionHeader" },
                //   ],
                // },
                { alignment: "center", text: `รายชื่อผู้ป่วยกลุ่มงานคิว${props.data?.waiting_list_name || ""}`, style: "divisionHeader" },
              ],
            },
          ],
        },
      ],
    },
    content: [
      {
        table: {
          headerRows: 1,
          widths: ["5%", "8%", "15.25%", "12.5%", "15%", "15.25%", "14.5%", "14.5%"],
          body: [
            [
              { text: "ลำดับ", style: "tableHeader", alignment: "center" },
              { text: "HN", style: "tableHeader" },
              { text: "ชื่อ-สกุล", style: "tableHeader" },
              { text: "เบอร์โทรติดต่อ", style: "tableHeader" },
              { text: "แพทย์/นิสิต", style: "tableHeader" },
              { text: "ประเภทการรักษา", style: "tableHeader" },
              { text: "สถานะ", style: "tableHeader" },
              { text: "รายละเอียด", style: "tableHeader" },
            ],
          ].concat(
            (props.data?.orders || [])?.map((row: any, index: number) => [
              { text: (index + 1).toString(), style: "fieldValue", alignment: "center" },
              { text: row.patient_hn, style: "fieldValue" },
              { text: row.patient_name ? row.patient_name.replace(/ \(.*\)$/g, "") : "", style: "fieldValue" },
              { text: row.patient_tel ? row.patient_tel.replace(/(^\d{3})/g, "$1-") : "", style: "fieldValue" },
              {
                text: row.provider_name ? `${row.provider_name} (${row.provider_code})` : "ไม่ระบุ",
                style: "fieldValue",
              },
              { text: row.type_name || "", style: "fieldValue" },
              { text: row.status_name || "", style: "fieldValue" },
              { text: row.note, style: "fieldValue" },
            ])
          ),
        },
      },
    ],
    footer: (currentPage: number, pageCount: number) => ({
      margin: [20, -5, 20, 0],
      columns: [
        { width: "*", text: `วันเวลาที่พิมพ์ : ${formatDate(moment())} [${moment().format("HH:mm")}]` },
        { text: `หน้าที่ ${currentPage.toString()} / ${pageCount}`, alignment: "right" },
      ],
    }),
    images: {
      logochula: origin + "/static/images/logochula.png",
    },
  };
};

export default FormWaitingList;
