import moment from "moment";
import WasmController from "react-lib/frameworks/WasmController";

import PlanTransferList from "issara-sdk/apis/PlanTransferList_apps_ADM";
// import PlanTransferListM from "issara-sdk/apis/PlanTransferList_apps_ADMM";
import PlanTransferDetailByEmr from "issara-sdk/apis/PlanTransferDetailByEmr_apps_ADM";
import PlanTransferDetail from "issara-sdk/apis/PlanTransferDetail_apps_ADM";

export type State = {
  PlanTransferSequence?: {
    sequenceIndex?: string | null;
    id?: any;
    emr?: any;
    date?: any;
    time?: any;
    urgency?: any;
    wardType?: any;
    roomType?: any;
    division?: any;
    med_reconcile?: any;
    status_label?: any;
    openRedirect?: boolean
  } | null;
};

export const StateInitial: State = {
  PlanTransferSequence: null,
};

export type Event = { message: "RunSequence",  params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  let state = controller.getState();
  console.log(" PLAN TRANSFER START ");

  let defaultTime = moment().format("HH:");
  controller.setState({
    PlanTransferSequence: {
      ...state.PlanTransferSequence,
      sequenceIndex: "EDIT",
      time: defaultTime + "00",
    },
  });

  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["transferUrgency", {}],
        ["roomType", {}],
        ["wardType", {}],
      ],
    },
  });

  let detail = await PlanTransferDetailByEmr.retrieve({
    pk: state.selectedEmr?.id,
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });

  if (detail[1]) {
    return;
  }

  if (detail[0]?.id) {
    state = controller.getState();

    controller.setState({
      PlanTransferSequence: {
        ...state.PlanTransferSequence,

        id: Number(detail[0]?.id),
        emr: detail[0]?.emr,
        date: detail[0]?.date || null,
        time: detail[0]?.time ? detail[0]?.time?.split(":")?.slice(0,-1).join(":") : null,
        urgency: detail[0]?.urgency || null,
        wardType: Number(detail[0]?.ward_type) || null,
        roomType: Number(detail[0]?.room_type) || null,
        division: Number(detail[0]?.division) || null,
        med_reconcile: detail[0]?.med_reconcile || null,
        status_label: detail[0]?.status_label || null,
      },
    });
  }
};

export const Edit: Handler = async (controller, params) => {
  let state = controller.getState();
  if (params.action === "save") {
    let data = {
      emr: state.selectedEmr.id,
      date: state.PlanTransferSequence?.date,
      time: state.PlanTransferSequence?.time,
      urgency: state.PlanTransferSequence?.urgency,
      room_type: state.PlanTransferSequence?.roomType,
      ward_type: state.PlanTransferSequence?.wardType,
      division: state.PlanTransferSequence?.division,
      action: state.PlanTransferSequence?.id ? "EDIT_REQUEST" : "REQUEST",
      id: state.PlanTransferSequence?.id || null,
      status_label: state.PlanTransferSequence?.status_label || null,
      med_reconcile: state.PlanTransferSequence?.med_reconcile || null,
    };

    let ptl = []

    if (state.PlanTransferSequence?.id) {
      ptl = await PlanTransferDetail.update({
        pk: state.PlanTransferSequence?.id,
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
        data
      });
    } else {
      ptl = await PlanTransferList.create({
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
        data,
      });
    }

    if (ptl[1]) {
      if ("action" in ptl[1] && "message" in ptl[1]?.action) {
        alert(ptl[1]?.action?.message);
      }

      return;
    }

    if (ptl[0] && ptl[0]?.id) {
      let detail = await PlanTransferDetailByEmr.retrieve({
        pk: state.selectedEmr.id,
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });

      if (ptl[1]) {
        alert(ptl[1]);
        return;
      }

      state = controller.getState();
      controller.setState({
        PlanTransferSequence: {
          ...state.PlanTransferSequence,

          id: Number(detail[0]?.id) || null,
          emr: detail[0]?.emr,
          date: detail[0]?.date || null,
          time: detail[0]?.time ? detail[0]?.time?.split(":")?.slice(0,-1).join(":") : null,
          urgency: detail[0]?.urgency || null,
          wardType: Number(detail[0]?.ward_type) || null,
          roomType: Number(detail[0]?.room_type) || null,
          division: Number(detail[0]?.division) || null,
          med_reconcile: Number(detail[0]?.med_reconcile) || null,
          status_label: detail[0]?.status_label || null,
          openRedirect: true,
        },
        
      });
    }
    return;
  } else if (params?.action === "delete") {
    let data = {
      emr: state.PlanTransferSequence?.emr,
      id: state.PlanTransferSequence?.id,
      date: state.PlanTransferSequence?.date,
      time: state.PlanTransferSequence?.time,
      urgency: state.PlanTransferSequence?.urgency,
      room_type: state.PlanTransferSequence?.roomType,
      ward_type: state.PlanTransferSequence?.wardType,
      division: state.PlanTransferSequence?.division,
      med_reconcile: state.PlanTransferSequence?.med_reconcile,
      action: "CANCEL",
      status_label: "Plan Transfer",
    };

    let ptd = await PlanTransferDetail.update({
      pk: state.PlanTransferSequence?.id,
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
      data,
    });

    if (ptd[1]) {
      console.warn(ptd[1]);
      return;
    }

    if (ptd[0] && ptd[0]?.id) {
      let detail = await PlanTransferDetailByEmr.retrieve({
        pk: state.selectedEmr.id,
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });
      console.log("detail: ", detail);

      if (detail[1]) {
        alert(detail[1]);
        return;
      }

      console.log("detail: ", detail);
      state = controller.getState();
      controller.setState({
        PlanTransferSequence: {
          ...state.PlanTransferSequence,
          id: Number(detail[0]?.id) || null,
          emr: detail[0]?.emr,
          date: detail[0]?.date || null,
          time: detail[0]?.time ? detail[0]?.time?.split(":")?.slice(0,-1).join(":") : null,
          urgency: detail[0]?.urgency || null,
          wardType: Number(detail[0]?.ward_type) || null,
          roomType: Number(detail[0]?.room_type) || null,
          division: Number(detail[0]?.division) || null,
          med_reconcile: Number(detail[0]?.med_reconcile) || null,
          status_label: detail[0]?.status_label || null,
        },
      });
    }

    return;
  }
};
