import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Dropdown,
  Input,
  Button,
  Radio
} from 'semantic-ui-react'

const CardOpenEncounter = (props: any) => {
    return(
      <div
        style={{ width: "100%", height: "100%" , padding: "5px" }}>
        <div
          style={{textAlign: "right", ...(props.isEditEncounter && { display: "none" })}}>
          
          <Button
            color="blue"
            disabled={props.disabledOpenEncounterAppointment}
            onClick={props.onOpenEncounterAppointment}>
            {props.appointmentRegisterButton}
          </Button>
        </div>
        <Table
          data={props.appointmentData}
          getTrProps={props.selectedAppointmentRow}
          headers=",วันที่นัด,เวลานัด,ชื่อแพทย์,ชื่อผู้ป่วย,แผนก,Orders,รายละเอียดการนัด"
          id="tb-encounterAppointment"
          keys="_checked,date,time,order_doctor_name,patient_name,division_name,order,detail"
          minRows={5}
          showPagination={false}
          style={{height: "220px", ...(props.isEditEncounter && { display: "none" })}}
          widths="50,150,100,,,,,150">
        </Table>
        <div
          style={{textAlign: "right", paddingTop: "20px"}}>
          
          <Button
            color="green"
            disabled={props.disabledOpenEncounter}
            id="btn-openEncounter"
            onClick={props.onOpenEncounter}
            style={{marginBottom: "0.5rem"}}>
            ลงทะเบียน (เปิด Encounter) เพิ่ม
          </Button>
        </div>
        <Table
          data={props.encounterList}
          getTrProps={props.rowProps}
          headers="Encounter,Division,Doctor,Status,Create,Update,Del"
          id="tb-encounterCreateNew"
          keys="number,division_name,doctor_name,status_name,created,edited,delete"
          minRows="5"
          showPagination={false}
          style={{height: "250px"}}>
        </Table>
        <div
          style={{height: "50vh", display: props.showEncounterEditor? "": "none"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(28, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
            <div
              style={{ gridRow: "2/4", gridColumn: "2/11", }}>
              แผนก
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "12/50", }}>
              <Dropdown
                id="cb-division"
                onChange={props.changeEncounter("division")}
                options={props.divisionOptions}
                search={true}
                selection={true}
                style={{ height: "100%", width: "100%" }}
                value={props.encounterData?.division || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "2/11", }}>
              แพทย์
            </div>
            <div
              style={{ gridRow: "11/13", gridColumn: "2/11", }}>
              ประเภทผู้ป่วย
            </div>
            <div
              style={{ gridRow: "14/16", gridColumn: "2/11",}}>
              จุดสังเกตุ
            </div>
            <div
              style={{ gridRow: "17/19", gridColumn: "2/11", }}>
              หมายเหตุ
            </div>
            <div
              style={{ gridRow: "20/22", gridColumn: "2/11",}}>
              โรคประจำตัว
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "12/50", }}>
              <Dropdown
                id="cb-doctor"
                onChange={props.changeEncounter("doctor")}
                options={props.doctorOptions}
                search={true}
                selection={true}
                style={{ height: "100%", width: "100%" }}
                value={props.encounterData?.doctor || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "11/13", gridColumn: "12/50", }}>
              <Dropdown
                id="cb-patientType"
                onChange={props.changeEncounter("patientCase")}
                options={props.patientCaseOptions}
                search={true}
                selection={true}
                style={{ height: "100%", width: "100%" }}
                value={props.encounterData?.patientCase || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "14/16", gridColumn: "12/50", }}>
              <Input
                fluid={true}
                onChange={props.changeEncounter("remark")}
                value={props.encounterData?.remark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "17/19", gridColumn: "12/50", }}>
              <Input
                fluid={true}
                onChange={props.changeEncounter("note")}
                value={props.encounterData?.note || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "20/22", gridColumn: "12/50",}}>
              <Input
                fluid={true}
                onChange={props.changeEncounter("underlying_disease")}
                value={props.encounterData?.underlying_disease || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "23/25", gridColumn: "42/50", ...(props.isShowPregnancy && {gridRow: "26/28", })}}>
              <div>
                {props.buttonSave}
              </div>
            </div>
            <div
              style={{ gridRow: "23/25", gridColumn: "33/41",  ...(props.isShowPregnancy && {gridRow: "26/28", }), ...(!props.encounterData?.selectedEncouter?.id && { display: "none"})}}>
              <div>
                {props.buttonEdit}
              </div>
            </div>
            <div
              style={{ gridRow: "23/25", gridColumn: "2/11", display: "flex", alignItems: "center", ...(!props.isShowPregnancy && {display: "none"})}}>
              ประวัติการตั้งครรภ์
            </div>
            <div
              style={{ gridRow: "23/25", gridColumn: "12/50", display: "flex", alignItems: "center", ...(!props.isShowPregnancy && {display: "none"})}}>
              
              <Radio
                checked={props.pregnancyData?.pregnancy_status === 1}
                label="ไม่ทราบ"
                onChange={props.changePregnancy("pregnancy_status")}
                style={{ marginRight: "20px" }}
                value={1}>
              </Radio>
              <Radio
                checked={props.pregnancyData?.pregnancy_status === 2}
                label="ไม่ตั้งครรภ์"
                onChange={props.changePregnancy("pregnancy_status")}
                style={{ marginRight: "20px" }}
                value={2}>
              </Radio>
              <Radio
                checked={props.pregnancyData?.pregnancy_status === 3}
                label="กำลังตั้งครรภ์"
                onChange={props.changePregnancy("pregnancy_status")}
                style={{ marginRight: "20px" }}
                value={3}>
              </Radio>
              <div
                style={{ marginRight: "20px", ...(props.pregnancyData?.pregnancy_status !== 3 && {display: "none"})}}>
                 ระบุไตรมาส
              </div>
              <Dropdown
                onChange={props.changePregnancy("pregnancy_period")}
                options={props.encounterData?.pregnancyPeriodOptions}
                selection={true}
                style={{ marginRight: "20px", ...(props.pregnancyData?.pregnancy_status !== 3 && {display: "none"})}}
                value={props.pregnancyData?.pregnancy_period }>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "2/11", }}>
              นิสิต/นักศึกษาแพทย์
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "12/50", }}>
              
              <div
                style={{ display: "flex" }}>
                
                <div
                  style={{ width: "60%" }}>
                  
                  <Dropdown
                    clearable={true}
                    fluid={true}
                    onChange={props.changeEncounter("medical_student")}
                    options={props.doctorOptions}
                    search={true}
                    selection={true}
                    style={{ height: "100%", width: "100%" }}
                    value={props.encounterData?.medical_student || ""}>
                  </Dropdown>
                </div>
                <div
                  style={{ width: "40%", display: "flex", position: "relative" , justifyContent: "center", border: "solid #D9D9D9 1px", marginLeft: "10px", alignItems: "center" }}>
                  
                  <Radio
                    checked={props.encounterData?.medical_student && props.encounterData?.degree === "bachelor"}
                    disabled={props.encounterData?.medical_student ? false : true}
                    label="ระดับปฏิญาตรี"
                    onChange={props.changeEncounter("degree")}
                    style={{ margin: "5px 10px",  }}
                    value="bachelor">
                  </Radio>
                  <Radio
                    checked={props.encounterData?.medical_student && props.encounterData?.degree === "postgraduate"}
                    disabled={props.encounterData?.medical_student ? false : true}
                    label="ระดับสูงกว่าปฏิญาตรี"
                    onChange={props.changeEncounter("degree")}
                    style={{ margin: "5px",  }}
                    value="postgraduate">
                  </Radio>
                  <div
                    style={{ position: "absolute", marginTop: "40px", background: "#FFF", color: "#D9D9D9", ...(props.encounterData?.medical_student && {color: "red"}) }}>
                    *** กรุณาระบุ ***
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardOpenEncounter

export const screenPropsDefault = {}

/* Date Time : Mon Jul 24 2023 03:08:58 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" , padding: \"5px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 24,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(28, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"2/11\", }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"12/50\", }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"2/11\", }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/13\", gridColumn: \"2/11\", }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "จุดสังเกตุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/16\", gridColumn: \"2/11\",}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"17/19\", gridColumn: \"2/11\", }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "โรคประจำตัว"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"20/22\", gridColumn: \"2/11\",}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"12/50\", }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/13\", gridColumn: \"12/50\", }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/16\", gridColumn: \"12/50\", }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"17/19\", gridColumn: \"12/50\", }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"20/22\", gridColumn: \"12/50\",}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"23/25\", gridColumn: \"42/50\", ...(props.isShowPregnancy && {gridRow: \"26/28\", })}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 16,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.encounterList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": "Encounter,Division,Doctor,Status,Create,Update,Del"
        },
        "id": {
          "type": "value",
          "value": "tb-encounterCreateNew"
        },
        "keys": {
          "type": "value",
          "value": "number,division_name,doctor_name,status_name,created,edited,delete"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"250px\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Dropdown",
      "parent": 4,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "cb-division"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"division\")"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.encounterData?.division || \"\""
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Dropdown",
      "parent": 10,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "cb-doctor"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"doctor\")"
        },
        "options": {
          "type": "code",
          "value": "props.doctorOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.encounterData?.doctor || \"\""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Dropdown",
      "parent": 11,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "cb-patientType"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"patientCase\")"
        },
        "options": {
          "type": "code",
          "value": "props.patientCaseOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.encounterData?.patientCase || \"\""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"50vh\", display: props.showEncounterEditor? \"\": \"none\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Input",
      "parent": 12,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"remark\")"
        },
        "value": {
          "type": "code",
          "value": "props.encounterData?.remark || \"\""
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Input",
      "parent": 13,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"note\")"
        },
        "value": {
          "type": "code",
          "value": "props.encounterData?.note || \"\""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Input",
      "parent": 14,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"underlying_disease\")"
        },
        "value": {
          "type": "code",
          "value": "props.encounterData?.underlying_disease || \"\""
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"23/25\", gridColumn: \"33/41\",  ...(props.isShowPregnancy && {gridRow: \"26/28\", }), ...(!props.encounterData?.selectedEncouter?.id && { display: \"none\"})}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 30,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.appointmentData"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.selectedAppointmentRow"
        },
        "headers": {
          "type": "value",
          "value": ",วันที่นัด,เวลานัด,ชื่อแพทย์,ชื่อผู้ป่วย,แผนก,Orders,รายละเอียดการนัด"
        },
        "id": {
          "type": "value",
          "value": "tb-encounterAppointment"
        },
        "keys": {
          "type": "value",
          "value": "_checked,date,time,order_doctor_name,patient_name,division_name,order,detail"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"220px\", ...(props.isEditEncounter && { display: \"none\" })}"
        },
        "widths": {
          "type": "value",
          "value": "50,150,100,,,,,150"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", ...(props.isEditEncounter && { display: \"none\" })}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", paddingTop: \"20px\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Button",
      "parent": 31,
      "props": {
        "children": {
          "type": "code",
          "value": "props.appointmentRegisterButton"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledOpenEncounterAppointment"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenEncounterAppointment"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Button",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "ลงทะเบียน (เปิด Encounter) เพิ่ม"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledOpenEncounter"
        },
        "id": {
          "type": "value",
          "value": "btn-openEncounter"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenEncounter"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการตั้งครรภ์"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"23/25\", gridColumn: \"2/11\", display: \"flex\", alignItems: \"center\", ...(!props.isShowPregnancy && {display: \"none\"})}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"23/25\", gridColumn: \"12/50\", display: \"flex\", alignItems: \"center\", ...(!props.isShowPregnancy && {display: \"none\"})}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Radio",
      "parent": 36,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.pregnancyData?.pregnancy_status === 1"
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePregnancy(\"pregnancy_status\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Radio",
      "parent": 36,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.pregnancyData?.pregnancy_status === 2"
        },
        "label": {
          "type": "value",
          "value": "ไม่ตั้งครรภ์"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePregnancy(\"pregnancy_status\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": " ระบุไตรมาส"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", ...(props.pregnancyData?.pregnancy_status !== 3 && {display: \"none\"})}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Dropdown",
      "parent": 36,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changePregnancy(\"pregnancy_period\")"
        },
        "options": {
          "type": "code",
          "value": "props.encounterData?.pregnancyPeriodOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", ...(props.pregnancyData?.pregnancy_status !== 3 && {display: \"none\"})}"
        },
        "value": {
          "type": "code",
          "value": "props.pregnancyData?.pregnancy_period "
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Radio",
      "parent": 36,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.pregnancyData?.pregnancy_status === 3"
        },
        "label": {
          "type": "value",
          "value": "กำลังตั้งครรภ์"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePregnancy(\"pregnancy_status\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonEdit"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "นิสิต/นักศึกษาแพทย์"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"2/11\", }"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"12/50\", }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"60%\" }"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"40%\", display: \"flex\", position: \"relative\" , justifyContent: \"center\", border: \"solid #D9D9D9 1px\", marginLeft: \"10px\", alignItems: \"center\" }"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Dropdown",
      "parent": 49,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"medical_student\")"
        },
        "options": {
          "type": "code",
          "value": "props.doctorOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.encounterData?.medical_student || \"\""
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Radio",
      "parent": 50,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.encounterData?.medical_student && props.encounterData?.degree === \"bachelor\""
        },
        "disabled": {
          "type": "code",
          "value": "props.encounterData?.medical_student ? false : true"
        },
        "label": {
          "type": "value",
          "value": "ระดับปฏิญาตรี"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"degree\")",
          "valueEN": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"5px 10px\",  }"
        },
        "value": {
          "type": "value",
          "value": "bachelor"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Radio",
      "parent": 50,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.encounterData?.medical_student && props.encounterData?.degree === \"postgraduate\""
        },
        "disabled": {
          "type": "code",
          "value": "props.encounterData?.medical_student ? false : true"
        },
        "label": {
          "type": "value",
          "value": "ระดับสูงกว่าปฏิญาตรี"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"degree\")"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"5px\",  }"
        },
        "value": {
          "type": "value",
          "value": "postgraduate"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": "*** กรุณาระบุ ***"
        },
        "style": {
          "type": "code",
          "value": "{ position: \"absolute\", marginTop: \"40px\", background: \"#FFF\", color: \"#D9D9D9\", ...(props.encounterData?.medical_student && {color: \"red\"}) }"
        }
      },
      "seq": 57,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 95,
  "isMounted": false,
  "memo": false,
  "name": "CardOpenEncounter",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 60
}

*********************************************************************************** */
