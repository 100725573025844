import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'

const CardDischargeSummaryNurseUX  = (props: any) => {
    return(
      <div>
        <div
          className="accordion ui fluid blue raised segment"
          style={{boxShadow: "none"}}>
          
          <div
            style={{display:"flex"}}>
            
            <label
              style={{fontSize: "1.25rem", fontWeight: "bold"}}>
              DISCHARGE SUMMARY
            </label>
            <div
              style={{flex:"1"}}>
              
            </div>
            <Button
              color="blue"
              loading={props.printLoading}
              onClick={props.onDischargeSummaryPrint}>
              พิมพ์ใบสรุปการรักษา
            </Button>
            <Button
              color="teal"
              onClick={props.OnSave}>
              Save
            </Button>
            <Button
              color="green"
              style={{display:"none"}}>
              Confrim
            </Button>
            <Button
              color="blue"
              style={{display:"none"}}>
              พิมพ์ใบ MR1
            </Button>
          </div>
          <div>
            {props.DischargePlanning}
          </div>
          <div
            className="ui divider">
            
          </div>
          <div>
            {props.DischargeTeaching}
          </div>
          <div
            className="ui divider">
            
          </div>
          <div>
            {props.DischargeSummaryPlanning}
          </div>
          <div
            className="ui divider">
            
          </div>
        </div>
      </div>
    )
}

export default CardDischargeSummaryNurseUX 

export const screenPropsDefault = {"by":"order","title":"MEDICAL FEE","titleDescription":"ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"}

/* Date Time : Tue Feb 14 2023 02:53:28 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "accordion ui fluid blue raised segment"
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DischargePlanning"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DischargeTeaching"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "DISCHARGE SUMMARY"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.25rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Button",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "Save"
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnSave"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Button",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "Confrim"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "style": {
          "type": "code",
          "value": "{display:\"none\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Button",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ใบ MR1"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "style": {
          "type": "code",
          "value": "{display:\"none\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Button",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ใบสรุปการรักษา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "loading": {
          "type": "code",
          "value": "props.printLoading"
        },
        "onClick": {
          "type": "code",
          "value": "props.onDischargeSummaryPrint"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DischargeSummaryPlanning"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 46,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 20,
  "isMounted": false,
  "memo": false,
  "name": "CardDischargeSummaryNurseUX ",
  "project": "IsHealth",
  "screenPropsDefault": {
    "by": "order",
    "title": "MEDICAL FEE",
    "titleDescription": "ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"
  },
  "width": 80
}

*********************************************************************************** */
