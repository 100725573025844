import React, {
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Button, Form, Icon, Label, Input } from "semantic-ui-react";

// UX
import CardSpecimenCollector from "./CardSpecimenCollector";
import CardSpecimenCollectorListUX from "./CardSpecimenCollectorListUX";

// Common
import { ModInfo } from "react-lib/apps/common";

const styles = {
  blueLabel: {
    padding: "0.75rem 0.5rem",
    background: "#e6f7fe",
    width: "100%",
  },
  fieldLabel: {
    paddingRight: "7.5px",
    color: "grey",
    minWidth: "max-content",
  },
};

const CardLabPanel = (props: any) => {
  const [labOrder, setLabOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [openModInfo, setOpenModInfo] = useState<boolean>(false);

  const cardSpecimenCollectorRef = useRef<any>();

  useEffect(() => {
    if (
      props.errorMessage?.LabOrderArrive !== null &&
      props.errorMessage?.LabOrderArrive !== undefined
    ) {
      setLoading(false);

      props.onEvent({
        message: "LabOrderArrive",
        params: { action: "clearMessage" },
      });
      // toast.error("รับเข้า Lab Order ไม่สำเร็จ");
    }
    if (
      props.successMessage?.LabOrderArrive !== null &&
      props.successMessage?.LabOrderArrive !== undefined
    ) {
      setLoading(false);
      setLabOrder(true);

      setTimeout(() => {
        setLabOrder(false);
      }, 2000);

      props.onEvent({
        message: "LabOrderArrive",
        params: { action: "clearMessage" },
      });
      // toast.success("รับเข้า Lab Order สำเร็จ");
    }
  }, [
    props.successMessage?.LabOrderArrive,
    props.errorMessage?.LabOrderArrive,
  ]);

  useEffect(() => {
    // props.onEvent({ message: "ScanBarcodeStickerSpecimen", params: {} });
    props.onEvent({
      message: "GetLabOrderQueue",
      params: {
        action: "search",
        hn: props.selectedEncounter?.hn,
      },
    });
  }, []);

  const labOrderQueue = useMemo(() => {
    return (props?.labOrderQueue?.labOrderQueueList || [])
      .sort((a: any, b: any) =>
        a.highest_urgency <= b.highest_urgency ? 1 : -1
      )
      .map((item: any) => ({
        ...item,
        labId: item.id,
        status_name: (
          <div>
            {item.status_name}
            {item.order_item_have_rejected && <Label color="red">REJECT</Label>}
          </div>
        ),
      }));
  }, [props?.labOrderQueue?.labOrderQueueList]);

  const isStatusCancel = useMemo(() => {
    return props.selectedLabOrderWorking?.status_name === "ยกเลิก";
  }, [props.selectedLabOrderWorking?.status_name]);

  const handleSearchBarcode = (text: string) => {
    props.onEvent({
      message: "ScanBarcodeStickerSpecimen",
      params: {
        action: "scan",
        searchText: text,
        specimenList: cardSpecimenCollectorRef.current?.specimenList || [],
        callback: (status: "SUCCESS" | "ERROR") => {
          if (status === "SUCCESS") {
            setSearchText("");
          } else {
            setSearchText(searchText.trim());
            setOpenModInfo(true);
          }
        },
      },
    });
  };

  const handleCloseModInfo = () => {
    setOpenModInfo(false);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const value = (e.target as any).value || "";

      handleSearchBarcode(value);
      setSearchText(value);
    }
  };

  const handleGetLabOrderArrive = () => {
    setLoading(true);

    props.onEvent({
      message: "LabOrderArrive",
      params: { action: "getLabOrderArrive" },
    });
  };

  const handleGetRowProps = (state: any, rowInfo: any) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id &&
          rowInfo?.original?.id === props.selectedLabOrderWorking?.id
            ? "#cccccc"
            : "white",
        color: rowInfo?.original?.highest_urgency === "STAT" ? "red" : "",
      },
      onClick: () => {
        props.setProp("selectedLabOrderWorking", rowInfo?.original?.id);
        props.onEvent({
          message: "LabOrderArrive",
          params: {
            action: "getDataLabOrderArrive",
            labOrderId: rowInfo?.original?.id,
          },
        });
      },
    };
  };

  return (
    <div style={{ height: "94vh" }}>
      <div style={{ padding: "10px" }}>
        <div
          style={{
            display: "flex",
            marginBottom: "10px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              marginRight: "10px",
            }}
          >
            รายการ Lab Order
          </div>

          <div
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                marginRight: "10px",
              }}
            >
              สแกนรหัส Specimen (tube) ID
            </div>
            <Input
              value={searchText}
              style={{ width: "50%" }}
              placeholder="Tube Sticker ID"
              onKeyPress={handleKeyPress}
              onChange={(e: any, v: any) => {
                setSearchText(v.value);
              }}
            ></Input>
            <Button
              icon="search"
              color="blue"
              onClick={() => handleSearchBarcode(searchText)}
            ></Button>
          </div>
        </div>

        <div style={{ margin: "10px 0px 20px" }}>
          <CardSpecimenCollectorListUX
            labOrderQueue={labOrderQueue}
            rowProps={handleGetRowProps}
          />
        </div>

        <div
          style={{
            fontSize: "1.2rem",
            fontWeight: "bold",
            marginBottom: "0.75rem",
          }}
        >
          Lab order
        </div>
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <div style={{ width: "10%" }}>
            <Button
              size="mini"
              color="google plus"
              loading={loading}
              disabled={isStatusCancel}
              style={{ padding: "10px" }}
              onClick={handleGetLabOrderArrive}
            >
              {labOrder ? (
                <div style={{ width: "50px" }}>
                  <Icon name="check"></Icon>
                </div>
              ) : (
                "รับเข้า Lab Order"
              )}
            </Button>
          </div>
          <div style={{ width: "90%" }}>
            <Form>
              <Form.Group inline>
                <Form.Field style={styles.fieldLabel}>วันที่</Form.Field>
                <Form.Field style={{ minWidth: "14%" }}>
                  <Label
                    style={{ ...styles.blueLabel, minWidth: "max-content" }}
                  >
                    {props.selectedLabOrderWorking?.date || "\u00a0"}
                  </Label>
                </Form.Field>
                <Form.Field style={styles.fieldLabel}>สถานะ</Form.Field>
                <Form.Field style={{ minWidth: "16%" }}>
                  <Label style={styles.blueLabel}>
                    {props.selectedLabOrderWorking?.status_name || "\u00a0"}
                  </Label>
                </Form.Field>
                <Form.Field style={styles.fieldLabel}>Arrive Time</Form.Field>
                <Form.Field style={{ minWidth: "14%" }}>
                  <Label style={styles.blueLabel}>
                    {props.selectedLabOrderWorking?.arrive_time || "\u00a0"}
                  </Label>
                </Form.Field>
                <Form.Field style={styles.fieldLabel}>Division</Form.Field>
                <Form.Field style={{ minWidth: "15%" }}>
                  <Label style={styles.blueLabel}>
                    {props.selectedLabOrderWorking?.order_div_name || "\u00a0"}
                  </Label>
                </Form.Field>
                <Form.Field style={styles.fieldLabel}>แพทย์</Form.Field>
                <Form.Field style={{ minWidth: "15%" }}>
                  <Label style={styles.blueLabel}>
                    {props.selectedLabOrderWorking?.doctor_name || "\u00a0"}
                  </Label>
                </Form.Field>
              </Form.Group>

              <Form.Group inline>
                <Form.Field style={styles.fieldLabel}>การชำระเงิน</Form.Field>

                <Form.Field style={{ minWidth: "12%" }}>
                  <Label
                    style={{
                      ...styles.blueLabel,
                      display:
                        props.selectedLabOrderWorking?.payment_status_name ===
                        undefined
                          ? "none"
                          : "",
                      background:
                        props.selectedLabOrderWorking?.payment_status_name ===
                        "PAID"
                          ? "green"
                          : "#fffbc5",
                    }}
                  >
                    {props.selectedLabOrderWorking?.payment_status_name ===
                    "PAID"
                      ? props.selectedLabOrderWorking?.payment_status_label
                      : "ยังไม่ชำระเงิน"}
                  </Label>
                </Form.Field>
                <Form.Field width={1} style={styles.fieldLabel}>
                  <label>Remark</label>
                </Form.Field>
                <Form.Field width={11} style={styles.fieldLabel}>
                  {props.selectedLabOrderWorking?.note || ""}
                </Form.Field>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>

      {props.selectedLabOrderWorking && (
        <div style={{ padding: "10px 10px 30px" }}>
          <CardSpecimenCollector
            ref={cardSpecimenCollectorRef}
            // function
            setProp={props.setProp}
            onEvent={props.onEvent}
            // common data
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            django={props.django}
            // data
            selectedLabOrderWorking={props.selectedLabOrderWorking}
            selectedContainer={props.selectedContainer}
            specimenLogs={props.specimenLogs}
            specimenCollectorMasterData={props.specimenCollectorMasterData}
            selectedDivision={props.selectedDivision}
            // config
            readOnly={isStatusCancel}
          />
        </div>
      )}

      <ModInfo
        open={openModInfo}
        titleColor={"red"}
        titleName="ไม่พบรหัสสิ่งส่งตรวจ (Specimen ID)"
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        {/* <ErrorMessage error={openModError.error} /> */}
      </ModInfo>

      {/* <div style={{ padding: "10px" }}>
        <CardLabResult
          setProp={props.setProp}
          onEvent={props.onEvent}
          labOrderItems={props.labOrderItems || []}
          // To be deprecated
          selectedLabOrderItem={props.selectedLabOrderItem}
          selectedLabOrderItemChild={props.selectedLabOrderItemChild}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
        />
      </div> */}
    </div>
  );
};

export default CardLabPanel;
