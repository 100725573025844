import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Label,
  Modal,
  TextArea,
} from "semantic-ui-react";
import { DateTextBox, ModConfirm } from "react-lib/apps/common";
import { useInterval } from "react-lib/utils/hooksUtils";

import {
  patientTypeOptions,
  deliveryTypeOptions,
  deliveryStatusOptions,
  consultStatusOptions,
} from "./telepharOptions";
import { formatDateToStringBe } from "react-lib/utils/dateUtils";
import moment from "moment";
import CardPatientPharmaListUX from "../common/CardPatientPharmaListUX";
import CONFIG from "config/config";
import { useHistory } from "react-router-dom";

import "./CardDashboardTelephar.scss";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

const styles = {
  button: {
    padding: "14px 0",
    minWidth: "150px",
    borderRadius: "10px",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
  } as CSSProperties,
};

const CardDashboardTelephar = (props: any) => {
  const history = useHistory();

  // Telephar State Search Dashboard
  const [hn, setHn] = useState("");
  const [deliveryNo, setDeliveryNo] = useState("");
  const [en, setEn] = useState("");
  const [searchName, setSearchName] = useState("");
  const [displaySearch, setDisplaySearch] = useState("");
  const [patientType, setPatientType] = useState("");
  const [deliveryType, setDeliveryType] = useState("");
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [consultStatus, setConsultStatus] = useState("");
  const [startDate, setStartDate] = useState(formatDateToStringBe(moment()));
  const [endDate, setEndDate] = useState(formatDateToStringBe(moment()));
  const [openRemark, setOpenRemark] = useState(false);
  const [rowSelete, setRowSelete] = useState<any>({});
  const [remarkStr, setRemarkStr] = useState("");
  // const [orderPerformDiv, setOrderPerformDiv] = useState("");

  useEffect(() => {
    props.runSequence({ sequence: "DashboardTelephar", restart: true });
    return () => {
      props.runSequence({ sequence: "DashboardTelephar", clear: true });
    };
  }, []);

  let interval = CONFIG.POLLING_TRANSPORT_TELEPHAR_SEC * 1000;
  useInterval(() => {
    console.debug("useInterval: called ");
    props.runSequence({
      sequence: "DashboardTelephar",
      action: "search",
      query: {
        patientName: searchName,
        hn,
        patientType,
        startDate,
        endDate,
        en,
        deliveryType,
        deliveryStatus,
        deliveryNo,
        consultStatus,
        // orderPerformDiv,
      },
    });
  }, interval);

  const isDoctor = useMemo(() => {
    return props.django?.user?.role_types?.includes("DOCTOR");
  }, [props.django]);

  const fastCompanyId = useMemo(() => {
    if (Array.isArray(props.masterData?.shippingCompany)) {
      let lalaCompanyId = props.masterData?.shippingCompany?.find(
        (item: any) => item.code === "Lala"
      )?.id;
      console.log("Found Lala Id");
      return lalaCompanyId;
    }
    return 1;
  }, [props.masterData?.shippingCompany]);

  const pharmacyList = useMemo(() => {
    return (props.DashboardTelepharSequence?.drugOrderQueue || []).map(
      (drugOrder: any) => {
        return {
          startDateTime: `${moment(drugOrder?.started)
            .zone("+0700")
            .format("DD/MM/YYYY HH:mm")}`,
          encounter: `${drugOrder?.encounter_number}`,
          patientType: "",
          fullname: `${drugOrder?.pre_name ? drugOrder?.pre_name + " " : ""}${
            drugOrder?.first_name
          } ${drugOrder?.middle_name ? drugOrder?.middle_name + " " : ""}${
            drugOrder?.last_name
          } \nHN: ${drugOrder?.hn}`,
          deliveryType:
            drugOrder?.shipping_order?.shipping_company?.id == fastCompanyId ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "4px",
                }}
              >
                <img
                  alt="fast"
                  src="/static/images/transport/fast-delivery.png"
                  width="20px"
                />{" "}
                <span style={{ color: "red", fontWeight: "bolder" }}>
                  ส่งด่วน
                </span>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "4px",
                }}
              >
                <img
                  alt="fast"
                  src="/static/images/transport/normal-delivery.png"
                  width="20px"
                />{" "}
                <span style={{ color: "red", fontWeight: "bolder" }}>
                  ส่งธรรมดา
                </span>
              </div>
            ),
          deliveryStatus: drugOrder?.shipping_order?.delivery_status,
          deliveryNo: drugOrder?.shipping_order?.tracking_number,
          sentDateTime: drugOrder?.shipping_order?.delivery_datetime
            ? `${moment(drugOrder?.shipping_order?.delivery_datetime)
                .zone("+0700")
                .format("DD/MM/YYYY HH:mm")}`
            : "",
          receivedDateTime: drugOrder?.shipping_order
            ?.delivery_received_datetime
            ? `${moment(drugOrder?.shipping_order?.delivery_received_datetime)
                .zone("+0700")
                .format("DD/MM/YYYY HH:mm")}`
            : "",
          consultStatus: drugOrder?.encounter_status,
          address: drugOrder?.shipping_order?.delivery_address,
          remark: (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <label
                style={{
                  ...(!drugOrder?.shipping_order?.remark && {
                    color: "#C4C4C4",
                  }),
                  marginRight: "10px",
                }}
              >
                {drugOrder?.shipping_order?.remark
                  ? drugOrder?.shipping_order?.remark
                  : "ยังไม่ได้ระบุหมายเหตุ"}
              </label>
              <img
                alt="remark"
                src="/static/images/transport/icon_remark.png"
                width="32px"
              />
            </div>
          ),
          telephone: (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                alt="call"
                src="/static/images/transport/phone.png"
                width="20px"
              />
            </div>
          ),
          ...drugOrder,
        };
      }
    );
  }, [props.DashboardTelepharSequence?.drugOrderQueue]);

  const handleUpdateRemark = () => {
    props.runSequence({
      sequence: "DashboardTelephar",
      action: "updateRemark",
      pk: rowSelete?.shipping_order?.id,
      remarkStr: remarkStr,
    });
  };

  return (
    <div id="dashboard-telephar" style={{ padding: "15px 10px 15px 10px" }}>
      <Form style={{ paddingButtom: "10px" }}>
        <Form.Group inline>
          <Form.Field width={3}>
            <label style={{ whiteSpace: "nowrap" }}>ชื่อ-สกุลคนไข้ / HN</label>
            <Input
              style={{ width: "80%" }}
              placeholder="Search..."
              icon="search"
              value={displaySearch}
              onChange={(e: any) => {
                if (e.target.value.includes("-")) {
                  setHn(e.target.value);
                  setSearchName("");
                  setDisplaySearch(e.target.value);
                } else {
                  setHn("");
                  setSearchName(e.target.value);
                  setDisplaySearch(e.target.value);
                }
              }}
            />
          </Form.Field>

          {/* <Form.Field width={3}>
            <label>HN</label>
            <Input
              placeholder="Search..."
              icon="search"
              value={hn}
              onChange={(e: any) => setHn(e.target.value)}
            />
          </Form.Field> */}
          <Form.Field>
            <label>ประเภทคนไข้</label>
            <Dropdown
              selection
              options={patientTypeOptions}
              onChange={(e: any, v: any) => setPatientType(v.value)}
              value={patientType}
              clearable
            />
          </Form.Field>
          <Form.Field width={6}>
            <label style={{ whiteSpace: "nowrap" }}>วันที่เข้ารับบริการ</label>

            <DateTextBox
              value={startDate}
              onChange={(date: any) => setStartDate(date)}
            ></DateTextBox>

            <label style={{ margin: "0px 10px" }}>ถึง</label>

            <DateTextBox
              value={endDate}
              onChange={(date: any) => setEndDate(date)}
            ></DateTextBox>
          </Form.Field>
          <Form.Field width={3}>
            <label>EN</label>
            <Input
              placeholder="Search..."
              icon="search"
              value={en}
              onChange={(e: any) => setEn(e.target.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={14}>
            <label style={{ minWidth: "max-content" }}>ประเภทการจัดส่ง</label>
            <div style={{ margin: "0 10px" }}>
              <Dropdown
                selection
                options={deliveryTypeOptions}
                onChange={(e: any, v: any) => setDeliveryType(v.value)}
                value={deliveryType}
                clearable
              />
            </div>
            {/* </Form.Field> */}
            {/* <Form.Field width={3}> */}
            <label style={{ minWidth: "max-content" }}>การจัดส่ง</label>
            <div style={{ margin: "0 10px" }}>
              <Dropdown
                selection
                options={deliveryStatusOptions}
                onChange={(e: any, v: any) => setDeliveryStatus(v.value)}
                value={deliveryStatus}
                clearable

                // fluid={true}
              />
            </div>
            {/* </Form.Field> */}
            {/* <Form.Field width={3}> */}
            <label style={{ minWidth: "max-content" }}>เลขที่จัดส่ง</label>
            <div style={{ minWidth: "200px", margin: "0 10px" }}>
              <Input
                placeholder="Search..."
                icon="search"
                value={deliveryNo}
                onChange={(e: any) => setDeliveryNo(e.target.value)}
              />
            </div>
            {/* </Form.Field> */}
            {/* <Form.Field width={3}> */}
            <label style={{ minWidth: "max-content" }}>
              สถานะการให้คำปรึกษา
            </label>
            <div style={{ margin: "0 10px" }}>
              <Dropdown
                selection
                options={consultStatusOptions}
                onChange={(e: any, v: any) => setConsultStatus(v.value)}
                value={consultStatus}
                clearable

                // fluid={true}
              />
            </div>
          </Form.Field>
          <Form.Field style={{ justifyContent: "flex-end" }}>
            <div style={{ display: "flex" }}>
              <Button
                content="ค้นหา"
                color="blue"
                onClick={() => {
                  // let patientName = searchName;
                  // let firstName = "";
                  // let lastName = "";
                  // let patientName = "";
                  // if (searchName) {
                  //   let i = searchName.indexOf(" ");
                  //   if (i > 0) {
                  //     firstName = searchName.substring(0, i);
                  //     lastName = searchName.substring(i + 1);
                  //   } else {
                  //     patientName = searchName;
                  //   }
                  // }

                  props.runSequence({
                    sequence: "DashboardTelephar",
                    action: "search",
                    query: {
                      patientName: searchName,
                      hn,
                      patientType,
                      startDate,
                      endDate,
                      en,
                      deliveryType,
                      deliveryStatus,
                      deliveryNo,
                      consultStatus,
                      // orderPerformDiv,
                    },
                  });
                }}
                type="submit"
                loading={props.DashboardTelepharSequence?.searchLoading}
              />
              <Button
                content="ล้างข้อมูล"
                style={{ minWidth: "max-content" }}
                onClick={() => {
                  let current = formatDateToStringBe(moment());
                  setHn("");
                  setSearchName("");
                  setDisplaySearch("");
                  setPatientType("");
                  setDeliveryStatus("");
                  setConsultStatus("");
                  setStartDate(current);
                  setEndDate(current);
                  // setOrderPerformDiv("")
                  props.runSequence({
                    sequence: "DashboardTelephar",
                    action: "clear",
                  });
                }}
              />
            </div>
          </Form.Field>
        </Form.Group>
        {/* <Form.Group inline>
          <Form.Field width={5}>
            <label>สถานะการให้คำปรึกษา</label>
            <Dropdown
              selection
              options={consultStatusOptions}
              onChange={(e: any, v: any) => setConsultStatus(v.value)}
              value={consultStatus}
              clearable

              // fluid={true}
            />
          </Form.Field>
          <Form.Field inline={true}>
            <label style={{ textAlign: "right", width: "100%" }}>
              Location
            </label>
          </Form.Field>
          <Form.Field inline={true} width={2}>
            <Dropdown
              fluid={true}
              onChange={(e: any, v: any) => setOrderPerformDiv(v.value)}
              options={props.masterOptions?.divisionTypeDrug || []}
              search={true}
              selection={true}
              style={{ width: "100%" }}
              value={orderPerformDiv}
            ></Dropdown>
          </Form.Field>
          <Form.Field width={11} style={{ justifyContent: "flex-end" }}>
            <Button
              content="ค้นหา"
              color="blue"
              onClick={() => {
                // let patientName = searchName;
                // let firstName = "";
                // let lastName = "";
                // let patientName = "";
                // if (searchName) {
                //   let i = searchName.indexOf(" ");
                //   if (i > 0) {
                //     firstName = searchName.substring(0, i);
                //     lastName = searchName.substring(i + 1);
                //   } else {
                //     patientName = searchName;
                //   }
                // }

                props.runSequence({
                  sequence: "DashboardTelephar",
                  action: "search",
                  query: {
                    patientName: searchName,
                    hn,
                    patientType,
                    startDate,
                    endDate,
                    en,
                    deliveryType,
                    deliveryStatus,
                    deliveryNo,
                    consultStatus,
                    // orderPerformDiv,
                  },
                });
              }}
              type="submit"
              loading={props.DashboardTelepharSequence?.searchLoading}
            />
            <Button
              content="ล้างข้อมูล"
              onClick={() => {
                let current = formatDateToStringBe(moment());
                setHn("");
                setSearchName("");
                setPatientType("");
                setDeliveryStatus("");
                setConsultStatus("");
                setStartDate(current);
                setEndDate(current);
                // setOrderPerformDiv("")
                props.runSequence({
                  sequence: "DashboardTelephar",
                  action: "clear",
                });
              }}
            />
          </Form.Field>
        </Form.Group> */}
      </Form>
      <>
        <CardPatientPharmaListUX
          encounterList={pharmacyList}
          showDivision={isDoctor}
          encounterListRowProps={(
            state: any,
            rowInfo: any,
            column: any,
            instance: any
          ) => {
            // console.log("rowInfo ", rowInfo);
            return {
              style: {
                backgroundColor:
                  rowInfo?.original?.id &&
                  rowInfo?.original?.id === props.selectedEncounter?.id
                    ? "#cccccc"
                    : rowInfo?.original?.triage_level?.props?.children ===
                        "1" ||
                      rowInfo?.original?.triage_level?.props?.children === "2"
                    ? "#fc8486"
                    : rowInfo?.original?.triage_level?.props?.children === "3"
                    ? "#feffa9"
                    : "white",
                ...(rowInfo && { cursor: "pointer" }),
              },
            };
          }}
          encounterListColumnsProps={(
            state: any,
            rowInfo: any,
            column: any,
            instance: any
          ) => {
            // console.log("rowInfo ", rowInfo);
            return {
              onClick: () => {
                if (column.id === "remark") {
                  setRowSelete(rowInfo?.original || null);
                  setRemarkStr(rowInfo?.original?.shipping_order?.remark);
                  setOpenRemark(true);
                } else {
                  console.log("onClick props?.layout: ", props?.layout);
                  props.onEvent({
                    message: "SelectDashboardTelephar",
                    params: {
                      drugOrder: rowInfo?.original || null,
                      history,
                      // isQueueScreen: props.layout === 0,
                    },
                  });
                  props?.layout === 0 && props?.forward?.();
                }
                // props.setProp("selectedRecordViewIndex", 0);
              },
            };
          }}
        />
      </>

      <Modal
        open={openRemark}
        onClose={() => setOpenRemark(false)}
        size={"small"}
      >
        <div
          style={{ padding: "30px", display: "flex", flexDirection: "column" }}
        >
          <label style={{ margin: "20px 0", fontSize: "24px",fontWeight:"bold" }}>
            {"เพิ่ม / แก้ไขหมายเหตุ"}
          </label>
          <TextArea
            placeholder="ยังไม่ได้ระบุหมายเหตุ"
            rows={5}
            value={remarkStr}
            style={{fontSize: "16px"}}
            onChange={(e, data: any) => {
              setRemarkStr(data.value);
            }}
          />
          <label
            style={{ color: "#C4C4C4", margin: "20px 0", fontSize: "18px" }}
          >
            {"โปรดตรวจสอบก่อนยืนยันการแก้ไข หรือ เพิ่มข้อมูล"}
          </label>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <ButtonLoadCheck
              style={{
                ...styles.button,
                color: "#0147A3",
                border: "1px solid #0147A3",
                background: "white",
              }}
              title={"ยกเลิก"}
              onClick={() => {
                setOpenRemark(false);
              }}
              setProp={props.setProp}
            />
            <ButtonLoadCheck
              style={{
                ...styles.button,
                background: "#0147A3",
                color: "white",
              }}
              title={"ยืนยัน"}
              buttonLoadCheck={props.DashboardTelepharSequence?.buttonLoadCheck}
              onClick={() => {
                handleUpdateRemark();
              }}
              setProp={props.setProp}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CardDashboardTelephar;
