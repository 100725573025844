import axios from "axios";
import { to } from "../../../utils";
import BaseService from "../../services/BaseService";
import config from "../../../../config/config";
import { Response, Error } from "../common";
const HOST = `${config.API_HOST}`;

export interface ScannedDocumentListSerializer {
  id?: any;
  edit_user?: any;
  created?: any;
  edited?: any;
  document_type?: any;
  document_no?: any;
  document_code?: any;
  document_image?: any;
  scan_user?: any;
  scan_division?: any;
  owner_division?: any;
  page?: any;
  version?: any;
  scan_date?: any;
  document_date?: any;
  expiry_date?: any;
  patient?: any;
  encounter?: any;
  is_secret?: any;
  active?: any;
  remark?: any;
  cancel_note?: any;
  no?: any;
  document_type_name?: any;
}

/* ['generics.ListCreateAPIView'] */
/* params: patient, patient__hn, encounter, document_type, document_no, scan_division, scan_user, owner_division, encounter__doctor, 'scan_date_from', 'scan_date_to', 'doc_date_from', 'doc_date_to', 'expiry_date_from', 'expiry_date_to', 'patient', 'patient__hn', 'encounter', 'patient__hn', 'document_category', 'document_type' */
/* data:  */
export const ScannedDocumentList: {
  list: (arg0: { params?: any; apiToken?: any; extra?: any }) => any;
  create: (arg0: { params?: any; data?: ScannedDocumentListSerializer; apiToken?: any; extra?: any; division?: number }) => any;
} = {
  list: async ({ params, apiToken, extra }) => {
    const base = new BaseService();
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const result = await to(
      base.client
        .get(`${HOST}/apis/core/scanned-document/by-group/`, { params: params ? { ...params } : {}, ...extra })
        .then(base.handleResponse)
        .catch(base.throwErrorMessage)
    );
    return [result[1], result[0], result[2]];
  },

  create: async ({ params, data, apiToken, extra, division }) => {
    const base = new BaseService();
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    if (division) {
      base.client.defaults.headers["DIVISION"] = division;
    }
    const result = await to(
      base.client
        .post(`${HOST}/apis/core/scanned-document/`, { ...data }, { params: params ? { ...params } : {}, ...extra })
        .then(base.handleResponse)
        .catch(base.throwErrorMessage)
    );
    return [result[1], result[0], result[2]];
  },
};
/*----------------------------------------------------------------------------------------------------*/

export interface ScannedDocumentSerializerForPatch {
  id?: any;
  edit_user?: any;
  created?: any;
  edited?: any;
  document_type?: any;
  document_no?: any;
  document_code?: any;
  document_image?: any;
  scan_user?: any;
  scan_division?: any;
  owner_division?: any;
  page?: any;
  version?: any;
  scan_date?: any;
  document_date?: any;
  expiry_date?: any;
  patient?: any;
  encounter?: any;
  is_secret?: any;
  active?: any;
  remark?: any;
  cancel_note?: any;
}

/*----------------------------------------------------------------------------------------------------*/
