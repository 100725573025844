import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Checkbox,
  Input,
  TextArea,
  Dropdown
} from 'semantic-ui-react'

const ANES_PACU_Anesthesia = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", height: "100%" ,padding:"10px"}}>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",height:"80%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(20, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/7",paddingBottom:"10px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Type of anesthesia :
              </label>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "8/12", paddingBottom:"10px"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.IsGA || false}
                  name="IsGA"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  GA
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "13/17", paddingBottom:"10px"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.IsMAC || false}
                  name="IsMAC"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  MAC
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "18/24",paddingBottom:"10px" }}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.IsRegionalAnesthesia || false}
                  name="IsRegionalAnesthesia"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Regional anesthesia
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "1/7", paddingBottom:"10px" }}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Anesthetic agents :
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "8/24",paddingBottom:"10px"}}>
              <div
                style={{ display: "none",height:"100%" }}>
                
                <TextArea
                  name="AnestheticAgentsRemark"
                  onChange={props.onChangeData}
                  style={{border: "solid 1px rgb(200, 200, 200, 0.5)", width:"100%",}}
                  value={props.PACURecordSequence?.data?.AnestheticAgentsRemark || ""}>
                </TextArea>
              </div>
              <div
                style={{ display: "flex",height:"100%" }}>
                
                <Dropdown
                  clearable={true}
                  fluid={true}
                  multiple={true}
                  name="AnestheticAgentsRemark"
                  onChange={props.onChangeData}
                  options={props.masterOptions.drugName || []}
                  search={true}
                  selection={true}
                  value={props.PACURecordSequence?.data?.AnestheticAgentsRemark || ""}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "1/7",paddingBottom:"10px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Anesthesia team :
              </label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "8/24",paddingBottom:"10px" }}>
              <div
                style={{ display: "flex" ,height:"90%",width:"100%",alignitem:"center"}}>
                
                <Input
                  name="AnesthesiaTeamRemark"
                  onChange={props.onChangeData}
                  style={{ display: "flex" ,height:"100%",width:"100%"}}
                  value={props.PACURecordSequence?.data?.AnesthesiaTeamRemark || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "1/7", paddingBottom:"10px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Admission status
              </label>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "8/14"}}>
              <div
                style={{ display: "flex",paddingTop:"5px" }}>
                
                <Dropdown
                  name="AdmissionStatus"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.pacu_adm_status || []}
                  selection={true}
                  style={{minWidth: "100px",Height:"100%" ,flex: "5 4 13.00%"}}
                  value={props.PACURecordSequence?.data?.AdmissionStatus || ""}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "15/17", paddingBottom:"10px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Airway
              </label>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "17/23",paddingBottom:"10px"}}>
              <div
                style={{ display: "flex",paddingTop:"5px" }}>
                
                <Dropdown
                  name="Airway"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.pacu_airway || []}
                  selection={true}
                  style={{minWidth: "100px",Height:"100%" ,flex: "5 4 13.00%"}}
                  value={props.PACURecordSequence?.data?.Airway || ""}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "1/7",paddingBottom:"10px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                ASA-PS
              </label>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "8/14",paddingBottom:"10px"}}>
              <div
                style={{ display: "flex",paddingTop:"5px" }}>
                
                <Dropdown
                  name="ASAPS"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.anes_asa_ps || []}
                  selection={true}
                  style={{minWidth: "100px",Height:"100%" ,flex: "5 4 13.00%"}}
                  value={props.PACURecordSequence?.data?.ASAPS || ""}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "15/19",paddingBottom:"10px"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.Emergency || false}
                  name="Emergency"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Emergency
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "1/8",paddingBottom:"10px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Remarks for adverse events
              </label>
            </div>
            <div
              style={{ gridRow: "7/9", gridColumn: "1/24",}}>
              <div
                style={{ display: "flex"}}>
                
                <TextArea
                  name="AdverseEventsRemark"
                  onChange={props.onChangeData}
                  style={{border: "solid 1px rgb(200, 200, 200, 0.5)", width:"100%",height:"100%"}}
                  value={props.PACURecordSequence?.data?.AdverseEventsRemark || ""}>
                </TextArea>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}


export default ANES_PACU_Anesthesia

export const screenPropsDefault = {}

/* Date Time : Tue May 23 2023 04:16:24 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" ,padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",height:\"80%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(20, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/7\", paddingBottom:\"10px\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"8/24\",paddingBottom:\"10px\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/7\",paddingBottom:\"10px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "Type of anesthesia :"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"8/12\", paddingBottom:\"10px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"13/17\", paddingBottom:\"10px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"18/24\",paddingBottom:\"10px\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Checkbox",
      "parent": 27,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsGA || false"
        },
        "name": {
          "type": "value",
          "value": "IsGA"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "GA"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Checkbox",
      "parent": 30,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsMAC || false"
        },
        "name": {
          "type": "value",
          "value": "IsMAC"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "MAC"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Checkbox",
      "parent": 33,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsRegionalAnesthesia || false"
        },
        "name": {
          "type": "value",
          "value": "IsRegionalAnesthesia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "label",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "Regional anesthesia"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/7\",paddingBottom:\"10px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"8/24\",paddingBottom:\"10px\" }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesia team :"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthetic agents :"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\",alignitem:\"center\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Input",
      "parent": 51,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "AnesthesiaTeamRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "onSearchChange": {
          "type": "value",
          "value": ""
        },
        "placeholder": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"100%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.AnesthesiaTeamRemark || \"\""
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\",height:\"100%\" }"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "TextArea",
      "parent": 53,
      "props": {
        "name": {
          "type": "value",
          "value": "AnestheticAgentsRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{border: \"solid 1px rgb(200, 200, 200, 0.5)\", width:\"100%\",}"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.AnestheticAgentsRemark || \"\""
        }
      },
      "seq": 54,
      "void": true
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/7\", paddingBottom:\"10px\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"8/14\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"15/17\", paddingBottom:\"10px\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"17/23\",paddingBottom:\"10px\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "label",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "Admission status"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "label",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "Airway"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",paddingTop:\"5px\" }"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Dropdown",
      "parent": 65,
      "props": {
        "name": {
          "type": "value",
          "value": "AdmissionStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.pacu_adm_status || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\",Height:\"100%\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.AdmissionStatus || \"\""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",paddingTop:\"5px\" }"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Dropdown",
      "parent": 67,
      "props": {
        "name": {
          "type": "value",
          "value": "Airway"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.pacu_airway || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\",Height:\"100%\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.Airway || \"\""
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/7\",paddingBottom:\"10px\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"8/14\",paddingBottom:\"10px\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"15/19\",paddingBottom:\"10px\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "ASA-PS"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",paddingTop:\"5px\" }"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Dropdown",
      "parent": 73,
      "props": {
        "name": {
          "type": "value",
          "value": "ASAPS"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.anes_asa_ps || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\",Height:\"100%\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.ASAPS || \"\""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Checkbox",
      "parent": 75,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.Emergency || false"
        },
        "name": {
          "type": "value",
          "value": "Emergency"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 75,
      "props": {
        "children": {
          "type": "value",
          "value": "Emergency"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/8\",paddingBottom:\"10px\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"1/24\",}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "TextArea",
      "parent": 82,
      "props": {
        "name": {
          "type": "value",
          "value": "AdverseEventsRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{border: \"solid 1px rgb(200, 200, 200, 0.5)\", width:\"100%\",height:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.AdverseEventsRemark || \"\""
        }
      },
      "seq": 83,
      "void": true
    },
    {
      "from": null,
      "id": 84,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "Remarks for adverse events"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",height:\"100%\" }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Dropdown",
      "parent": 85,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AnestheticAgentsRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions.drugName || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.AnestheticAgentsRemark || \"\""
        }
      },
      "seq": 86,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PACU_Anesthesia",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
