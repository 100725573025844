import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input,
  TextArea
} from 'semantic-ui-react'

const ANES_PreANES_04_Blood = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", height: "100%" ,padding:"10px"}}>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",height:"100%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            style={{ display: "flex", justifyContent: "flex-end"}}>
            
            <div
              style={{ display: props.PreAnestheticSequence?.status_label === "Confirm" ?  "none" : "",}}>
              {props.buttonSave}
            </div>
          </div>
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(20, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              style={{ gridRow: "2/3", gridColumn: "1/4"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Hb
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "1/4" }}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                PT
              </label>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "1/4"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Na
              </label>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "1/4"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                BUN
              </label>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "1/4"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                AST
              </label>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "1/4"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Other
              </label>
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "1/4"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                CXR
              </label>
            </div>
            <div
              style={{ gridRow: "9/10", gridColumn: "1/4"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Imaging
              </label>
            </div>
            <div
              style={{ gridRow: "2/3",  gridColumn: "4/9"}}>
              <Input
                name="HbTime"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.HbTime || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "4/9"}}>
              <Input
                name="PTTime"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.PTTime || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "4/9"}}>
              <Input
                name="NaTime"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.NaTime || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "5/6",gridColumn: "4/9"}}>
              <Input
                name="BunTime"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.BunTime || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "4/9"}}>
              <Input
                name="ASTTime"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.ASTTime || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "4/9"}}>
              <Input
                name="OthersTime"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.OthersTime || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "4/20"}}>
              <TextArea
                name="CXR"
                onChange={props.onChangeData}
                style={{width:"500px",border: "solid 1px rgb(200, 200, 200, 0.5)" }}
                value={props.PreAnestheticSequence?.data?.CXR || ""}>
              </TextArea>
            </div>
            <div
              style={{ gridRow: "9/10", gridColumn: "4/20"}}>
              <TextArea
                name="Imaging"
                onChange={props.onChangeData}
                style={{width:"500px",border: "solid 1px rgb(200, 200, 200, 0.5)" }}
                value={props.PreAnestheticSequence?.data?.Imaging || ""}>
              </TextArea>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "9/12"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Hct
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "9/12"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                PTT
              </label>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "9/12"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                K
              </label>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "9/12"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Cr
              </label>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "9/12"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                ALT
              </label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "12/17"}}>
              <Input
                name="HcTime"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.HcTime || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "3/4",gridColumn: "12/17"}}>
              <Input
                name="PTTTime"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.PTTTime || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "4/5",gridColumn: "12/17"}}>
              <Input
                name="KTime"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.KTime || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "5/6",gridColumn: "12/17"}}>
              <Input
                name="CrTime"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.CrTime || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "6/7",gridColumn: "12/17"}}>
              <Input
                name="ALTTime"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.ALTTime || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "17/20"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Pit
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "17/20"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                INR
              </label>
            </div>
            <div
              style={{ gridRow: "4/5",  gridColumn: "17/20"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Cl
              </label>
            </div>
            <div
              style={{ gridRow: "5/6",  gridColumn: "17/20"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                GFR
              </label>
            </div>
            <div
              style={{ gridRow: "6/7",  gridColumn: "17/20"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Alp
              </label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "20/24"}}>
              <Input
                name="PltTime"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.PltTime || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "20/24"}}>
              <Input
                name="INRTime"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.INRTime || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "20/24"}}>
              <Input
                name="ClTime"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.ClTime || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "20/24"}}>
              <Input
                name="GFRTime"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.GFRTime || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "20/24"}}>
              <Input
                name="AlpTime"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.AlpTime || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "25/28"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                WBC
              </label>
            </div>
            <div
              style={{ gridRow: "4/5",  gridColumn: "25/28"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                CO2
              </label>
            </div>
            <div
              style={{ gridRow: "5/6",  gridColumn: "25/28"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                BS
              </label>
            </div>
            <div
              style={{ gridRow: "6/7",  gridColumn: "25/28"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Alb
              </label>
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "20/24"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                EKG
              </label>
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "24/37"}}>
              <TextArea
                name="EKG"
                onChange={props.onChangeData}
                style={{width:"500px",border: "solid 1px rgb(200, 200, 200, 0.5)" }}
                value={props.PreAnestheticSequence?.data?.EKG || ""}>
              </TextArea>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "28/32"}}>
              <Input
                name="WBCTime"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.WBCTime || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "28/32"}}>
              <Input
                name="CO2Time"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.CO2Time || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "28/32" }}>
              <Input
                name="BSTIME"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.BSTIME || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "28/32" }}>
              <Input
                name="AlbTime"
                onChange={props.onChangeData}
                style={{height:"32px",width:"130px",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.AlbTime || ""}>
              </Input>
            </div>
          </div>
        </div>
      </div>
    )
}

export default ANES_PreANES_04_Blood

export const screenPropsDefault = {}

/* Date Time : Thu Dec 29 2022 03:02:21 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" ,padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",height:\"100%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(20, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/4\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/4\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/4\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/4\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/4\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"1/4\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/4\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\",  gridColumn: \"4/9\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\",gridColumn: \"4/9\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"4/20\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"4/20\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Hb"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "PT"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "Na"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "BUN"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "AST"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "Other"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "CXR"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Imaging"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Input",
      "parent": 11,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "HbTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HbTime || \"\""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Input",
      "parent": 12,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "PTTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PTTime || \"\""
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Input",
      "parent": 13,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "NaTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.NaTime || \"\""
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Input",
      "parent": 14,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "BunTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BunTime || \"\""
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Input",
      "parent": 15,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "ASTTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ASTTime || \"\""
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Input",
      "parent": 16,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "OthersTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.OthersTime || \"\""
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"9/12\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"9/12\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"9/12\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"9/12\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"9/12\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "label",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "Hct"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "PTT"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "K"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "label",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "Cr"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "ALT"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"12/17\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\",gridColumn: \"12/17\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\",gridColumn: \"12/17\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\",gridColumn: \"12/17\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\",gridColumn: \"12/17\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Input",
      "parent": 54,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "HcTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HcTime || \"\""
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Input",
      "parent": 55,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "PTTTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PTTTime || \"\""
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Input",
      "parent": 56,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "KTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.KTime || \"\""
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Input",
      "parent": 57,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "CrTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.CrTime || \"\""
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Input",
      "parent": 58,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "ALTTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ALTTime || \"\""
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "TextArea",
      "parent": 17,
      "props": {
        "name": {
          "type": "value",
          "value": "CXR"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"500px\",border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.CXR || \"\""
        }
      },
      "seq": 65,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "TextArea",
      "parent": 18,
      "props": {
        "name": {
          "type": "value",
          "value": "Imaging"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"500px\",border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Imaging || \"\""
        }
      },
      "seq": 66,
      "void": true
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"17/20\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"17/20\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\",  gridColumn: \"17/20\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\",  gridColumn: \"17/20\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\",  gridColumn: \"17/20\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "Pit"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "label",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": "INR"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "Cl"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "GFR"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "label",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "Alp"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"20/24\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"20/24\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"20/24\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"20/24\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"20/24\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Input",
      "parent": 77,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "PltTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PltTime || \"\""
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Input",
      "parent": 78,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "INRTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.INRTime || \"\""
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Input",
      "parent": 79,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "ClTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ClTime || \"\""
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Input",
      "parent": 80,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "GFRTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.GFRTime || \"\""
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Input",
      "parent": 81,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "AlpTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AlpTime || \"\""
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"25/28\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\",  gridColumn: \"25/28\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\",  gridColumn: \"25/28\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\",  gridColumn: \"25/28\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "label",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": "WBC"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "label",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "CO2"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "label",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": "BS"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "label",
      "parent": 91,
      "props": {
        "children": {
          "type": "value",
          "value": "Alb"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"20/24\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"24/37\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "label",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "EKG"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "TextArea",
      "parent": 98,
      "props": {
        "name": {
          "type": "value",
          "value": "EKG"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"500px\",border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.EKG || \"\""
        }
      },
      "seq": 100,
      "void": true
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"28/32\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"28/32\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"28/32\" }"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"28/32\" }"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Input",
      "parent": 101,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "WBCTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.WBCTime || \"\""
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "Input",
      "parent": 102,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "CO2Time"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.CO2Time || \"\""
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Input",
      "parent": 103,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "BSTIME"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BSTIME || \"\""
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "Input",
      "parent": 104,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "AlbTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"32px\",width:\"130px\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AlbTime || \"\""
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.status_label === \"Confirm\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 111,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PreANES_04_Blood",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
