import axios from 'axios'
import { to } from '../../../utils'
import BaseService from  '../../services/BaseService'
import config from '../../../../config/config'
import { Response, Error } from '../common'
const HOST = `${config.API_HOST}`

export interface DocumentTypeSerializer {
    id?: any,
    name?: any,
    name_en?: any,
    category?: any,
    code?: any,
    print_api?: any,
    jasper_path?: any,
    jasper_module?: any,
    version?: any,
    active?: any,
    display_seq?: any,
}


/* ['generics.ListCreateAPIView'] */
/* params: 'category', 'name', 'code', 'printable', 'division' */
/* data:  */
export const DocumentTypeList : 
{
  list:     (arg0:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  create:   (arg0:
             { 
                params?: any,
                data?: DocumentTypeSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/document-type/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  create: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/apis/core/document-type/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

