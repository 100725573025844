import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
  MutableRefObject,
} from "react";
import { Icon, Button, Modal } from "semantic-ui-react";

import ModIntraTransferUX from "../ADM/ModIntraTransferUX";
import CardIntraHospitalTransferFormUX from "../ADM/CardIntraHospitalTransferFormUX";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";
import EmployeeToken from "react-lib/apps/common/EmployeeToken";
import SearchBox from "react-lib/appcon/common/SearchBox";
import { ErrorMessage } from "react-lib/apps/common";
import DeleteColumn from "react-lib/appcon/common/DeleteColumn";
import CardSelectVitalSign from "react-lib/apps/ER/CardSelectVitalSign";

const CARD_INTRA_HOSPITAL_TRANSFER = "CardIntraHospitalTransfer";
const CARD_QUEUE_WARD = "CardWardQueue";

const CardIntraHospitalTransfer = (props: any) => {
  const [doctor, setDoctor] = useState<any>(null);
  const [complete, setComplete] = useState<string>("");
  const [openModVitalSign, setOpenModVitalSign] = useState<boolean>(false);

  const transferringTimeRef = useRef() as MutableRefObject<any>;
  const receivingTimeRef = useRef() as MutableRefObject<any>;

  useEffect(() => {
    props.runSequence({
      sequence: "WardQueue",
      restart: props.isRestartClear,
      nextSequenceIndex: "AdmitAction",
    });
    return () => {
      if (props.isRestartClear) {
        props.runSequence({ sequence: "WardQueue", clear: true });
      }
      props.setProp("intraTransferForm", { show: false });
    };
  }, []);

  useEffect(() => {
    handleChangeValue(null, {
      value: props.intraTransferForm?.receivingTokenize?.token || "",
      name: "receiving_personnel",
    });
  }, [props.intraTransferForm?.receivingTokenize]);

  useEffect(() => {
    handleChangeValue(null, {
      value: props.intraTransferForm?.transferringTokenize?.token || "",
      name: "transferring_personnel",
    });
  }, [props.intraTransferForm?.transferringTokenize]);

  useEffect(() => {
    const change = async () => {
      await handleChangeValue(null, {
        value: doctor?.id || null,
        name: "doctor",
      });
      await handleChangeValue(null, {
        value: doctor?.name_code || "",
        name: "doctor_name_code",
      });
    };

    change();
  }, [doctor]);

  useEffect(() => {
    transferringTimeRef.current?.setValue(
      props.intraTransferForm?.transferring_personnel_time || ""
    );
    receivingTimeRef.current?.setValue(
      props.intraTransferForm?.receiving_personnel_time || ""
    );

    setDoctor({
      id: props.intraTransferForm?.doctor || null,
      name_code: props.intraTransferForm?.doctor_name_code || "",
    });
  }, [
    props.intraTransferForm.transferring_personnel_time,
    props.intraTransferForm.receiving_personnel_time,
  ]);

  useEffect(() => {
    const loading = props.loadingStatus?.[CARD_INTRA_HOSPITAL_TRANSFER];
    if (loading === null || loading === undefined) {
      setComplete(loading === null ? "check" : "close");

      setTimeout(() => {
        setComplete("");
        props.setProp(`loadingStatus.${CARD_INTRA_HOSPITAL_TRANSFER}`, false);
      }, 2000);
    }
  }, [props.loadingStatus]);

  const handleGetTrProps = useCallback(
    (state: any, rowInfo: any, column: any, instance: any) => ({
      style: {
        backgroundColor:
          Number.isInteger(props.intraTransferForm?.id) &&
          rowInfo?.original?.id === props.intraTransferForm?.id
            ? "#cccccc"
            : "white",
      },
      onClick: async (e: any) => {
        await props.setProp(
          `errorMessage.${CARD_INTRA_HOSPITAL_TRANSFER}`,
          null
        );

        props.runSequence({
          sequence: "WardQueue",
          item: props.intraTransferForm?.items?.[rowInfo.index],
          action: "selected",
        });
      },
    }),
    [props.intraTransferForm?.items, props.intraTransferForm?.id]
  );

  const transferFormData = useMemo(() => {
    return (props.intraTransferForm?.items || []).map((item: any) => ({
      ...item,
      date: item.created,
      from_division: item.from_div_name,
      to_division: item.to_div_name,
      _remove: (
        <DeleteColumn
          id={item.id}
          value={item.id}
          size="mini"
          titleColor="orange"
          titleName="ต้องการยกเลิกใช่หรือไม่"
          content={<div style={{ margin: "-2rem 0" }}></div>}
          onConfirm={() =>
            props.runSequence({
              sequence: "WardQueue",
              action: "save_intra",
              card: CARD_INTRA_HOSPITAL_TRANSFER,
              deleteItem: item,
              patientId: props.patientId,
            })
          }
        />
      ),
    }));
  }, [props.intraTransferForm?.items]);

  const handleChangeValue = async (e: any, v: any) => {
    const value = typeof v.checked === "boolean" ? v.checked : v.value;
    await props.setProp(`intraTransferForm.${v.name}`, value);
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "WardQueue",
      action: "save_intra",
      card: CARD_INTRA_HOSPITAL_TRANSFER,
      patientId: props.patientId,
    });
  };

  const handlePrint = () => {
    props.runSequence({
      sequence: "WardQueue",
      action: "print",
      card: CARD_INTRA_HOSPITAL_TRANSFER,
    });
  };

  const handleSelectVitalSign = ({ row }: any = {}) => {
    var values = row.original.vital_signs.reduce((o: any, cur: any) => {
      o[cur.vitalsign_type_code] = cur.result;
      return o;
    }, {});

    const getHeartRate = () => {
      const keys = ["HR", "HR12+", "HR6-12", "HR3-6", "HR1-3"];
      const field = keys.find((key) => values[key]) || "";

      return values[field] || "";
    };

    props.setProp("intraTransferForm", {
      ...props.intraTransferForm,
      vital_bp: (values.SP || "") + "/" + (values.DP || ""),
      vital_body_temp: values.TEMP || "",
      vital_o2_sat: values.O2Sat || "",
      vital_heart_rate: values.PULSE || "",
      vital_respiratory_rate: values.RR || "",
    });

    setOpenModVitalSign(false);
  };

  console.log("CardIntraHospitalTransfer", props, doctor);

  return (
    <div>
      <ModIntraTransferUX
        hideAdmit={props.hideAdmit}
        // function
        onNewForm={() => {
          props.runSequence({
            sequence: "WardQueue",
            action: "new_form",
            patientId: props.patientId,
            // card: CARD_QUEUE_WARD,
          });
        }}
        getTrProps={handleGetTrProps}
        transferFormData={transferFormData}
        intraTransferForm={
          props.intraTransferForm?.show && (
            <>
              <ErrorMessage
                style={{ margin: "1rem 0 0.5rem" }}
                error={props.errorMessage?.[CARD_INTRA_HOSPITAL_TRANSFER]}
              />
              <CardIntraHospitalTransferFormUX
                data={props.intraTransferForm || {}}
                disablePrint={!props.intraTransferForm?.id}
                divisionList={props.masterOptions.division}
                reasonToTransferChoicesOptions={
                  props.intraHospitalTransferOptions
                    ?.reason_to_transfer_choices || []
                }
                modeOfTransferOptions={
                  props.intraHospitalTransferOptions
                    ?.mode_of_transfer_choices || []
                }
                isolationPrecautionOptions={
                  props.intraHospitalTransferOptions
                    ?.isolation_precaution_choices || []
                }
                typeOfTransferOptions={
                  props.intraHospitalTransferOptions
                    ?.type_of_transfer_choices || []
                }
                conciousnessLevelOptions={
                  props.intraHospitalTransferOptions
                    ?.conciousness_level_choices || []
                }
                protectionOptions={
                  props.intraHospitalTransferOptions?.protection_choices || []
                }
                specialCareOptions={
                  props.intraHospitalTransferOptions?.special_care_choices || []
                }
                // callback
                onChangeValue={handleChangeValue}
                onPrint={handlePrint}
                onSelectVitalSign={() => setOpenModVitalSign(true)}
                // Element
                transferringTimeBox={
                  <TimeComboBox
                    ref={transferringTimeRef}
                    onTextChange={(value: string) =>
                      handleChangeValue(null, {
                        name: "transferring_personnel_time",
                        value,
                      })
                    }
                  />
                }
                receivingTimeBox={
                  <TimeComboBox
                    ref={receivingTimeRef}
                    onTextChange={(value: string) =>
                      handleChangeValue(null, {
                        name: "receiving_personnel_time",
                        value,
                      })
                    }
                  />
                }
                transferringEmployee={
                  <EmployeeToken
                    placeholder="ระบุ code employee"
                    fluid={true}
                    onEnterToken={(code) => {
                      props.runSequence({
                        sequence: "WardQueue",
                        action: "tokenize",
                        code,
                        key: "transferringTokenize",
                      });
                    }}
                    onClearToken={async () => {
                      await props.setProp(
                        "intraTransferForm.transferringTokenize",
                        {}
                      );
                      await props.setProp(
                        "intraTransferForm.transferring_personnel",
                        ""
                      );
                    }}
                    error={props.intraTransferForm?.transferringTokenize?.error}
                    loading={
                      props.intraTransferForm?.transferringTokenize?.loading
                    }
                    employeeName={
                      props.intraTransferForm?.transferringTokenize?.name
                    }
                    inputStyle={{
                      width:
                        props.intraTransferForm?.transferringTokenize?.name ||
                        props.intraTransferForm?.transferringTokenize?.loading
                          ? "calc(100% - 1.4rem)"
                          : "",
                    }}
                  />
                }
                receivingEmployee={
                  <EmployeeToken
                    placeholder="ระบุ code employee"
                    fluid={true}
                    onEnterToken={(code) => {
                      props.runSequence({
                        sequence: "WardQueue",
                        action: "tokenize",
                        code,
                        key: "receivingTokenize",
                      });
                    }}
                    onClearToken={async () => {
                      await props.setProp(
                        "intraTransferForm.receivingTokenize",
                        {}
                      );
                      await props.setProp(
                        "intraTransferForm.receiving_personnel",
                        ""
                      );
                    }}
                    error={props.intraTransferForm?.receivingTokenize?.error}
                    loading={
                      props.intraTransferForm?.receivingTokenize?.loading
                    }
                    employeeName={
                      props.intraTransferForm?.receivingTokenize?.name
                    }
                    inputStyle={{
                      width:
                        props.intraTransferForm?.receivingTokenize?.name ||
                        props.intraTransferForm?.receivingTokenize?.loading
                          ? "calc(100% - 1.4rem)"
                          : "",
                    }}
                  />
                }
                patientDoctor={
                  <SearchBox
                    onEvent={props.onEvent}
                    searchedItemList={props.searchedItemList}
                    selectedItem={doctor}
                    setSelectedItem={setDoctor}
                    type="Doctor"
                    label=""
                    toDisplay={(item: any) => item.name_code || ""}
                    style={{ width: "100%", paddingRight: "1.4rem" }}
                    fluid={true}
                    icon="search"
                  />
                }
                actionButton={
                  <Button
                    color={
                      complete === "close"
                        ? "red"
                        : props.intraTransferForm?.id
                        ? "yellow"
                        : "green"
                    }
                    style={{ width: "200px" }}
                    loading={
                      !!props.loadingStatus?.[CARD_INTRA_HOSPITAL_TRANSFER]
                    }
                    onClick={handleSave}
                  >
                    {complete ? (
                      <Icon name={complete as any}></Icon>
                    ) : props.intraTransferForm?.id ? (
                      "แก้ไข"
                    ) : (
                      "บันทึก"
                    )}
                  </Button>
                }
              />
            </>
          )
        }
        onWardPatient={() => {
          props.runSequence({
            sequence: "WardQueue",
            action: "ward",
            admitRoomItemId:
              props.WardQueueSequence?.selectedAdmitRoomItem?.room_item_id,
            card: CARD_QUEUE_WARD,
          });
        }}
        // Element=
        ErrorMessageWard={
          <ErrorMessage
            style={{ margin: "1rem 0 0.5rem" }}
            error={props.errorMessageWard}
          />
        }
      />

      <Modal
        open={openModVitalSign}
        closeOnDimmerClick
        onClose={() => setOpenModVitalSign(false)}
      >
        <CardSelectVitalSign
          onEvent={props.onEvent}
          loading={props.selectVitalSignLoading}
          getEncounterVitalSignListErr={props.getEncounterVitalSignListErr}
          encounterVitalSignList={props.encounterVitalSignList}
          onSelect={handleSelectVitalSign}
        />
      </Modal>
    </div>
  );
};

export default CardIntraHospitalTransfer;
