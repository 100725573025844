import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'

const ANES_ChargeItem_Tab = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div>
        <div
          style={{height:"50px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(1, 1fr)","gridTemplateColumns":"repeat(20, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/18",display: "flex"}}>
              <label
                style={{textAlign:"center",marginLeft:"20px",paddingTop:"20px","fontWeight":"bold","fontSize":"20px"}}>
                ชุดรายการค่าใช้จ่าย
              </label>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "17/18"}}>
              {props.buttonRefresh}
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "18/21"}}>
              <Button
                color="blue"
                onClick={props.onSelectTemplate}
                style={{marginTop:"10px",width:"90%"}}>
                เลือก Template
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex" ,padding : "20px"}}>
          
          <Button
            color={props.tab === "Drug" ? "blue" : "white"}
            name="Drug"
            onClick={props.onChangeTab}
            style={{ textalign: "center",  fontSize:"16px", width:"33%" }}>
            รายการยา
          </Button>
          <Button
            color={props.tab === "Supply" ? "blue" : "white"}
            name="Supply"
            onClick={props.onChangeTab}
            style={{ textalign: "center",  fontSize:"16px", width:"33%" }}>
            รายการเวชภัณฑ์
          </Button>
          <Button
            color={props.tab === "Operative" ? "blue" : "white"}
            name="Operative"
            onClick={props.onChangeTab}
            style={{ textalign: "center",  fontSize:"16px", width:"33%" }}>
            รายการหัตถการ
          </Button>
        </div>
        <hr>
        </hr>
      </div>
    )
}

export default ANES_ChargeItem_Tab

export const screenPropsDefault = {}

/* Date Time : Wed Mar 15 2023 03:44:47 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,padding : \"20px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการยา"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"Drug\" ? \"blue\" : \"white\""
        },
        "name": {
          "type": "value",
          "value": "Drug"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab"
        },
        "style": {
          "type": "code",
          "value": "{ textalign: \"center\",  fontSize:\"16px\", width:\"33%\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการเวชภัณฑ์"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"Supply\" ? \"blue\" : \"white\""
        },
        "name": {
          "type": "value",
          "value": "Supply"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab"
        },
        "style": {
          "type": "code",
          "value": "{ textalign: \"center\",  fontSize:\"16px\", width:\"33%\" }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการหัตถการ"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"Operative\" ? \"blue\" : \"white\""
        },
        "name": {
          "type": "value",
          "value": "Operative"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab"
        },
        "style": {
          "type": "code",
          "value": "{ textalign: \"center\",  fontSize:\"16px\", width:\"33%\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 13,
      "void": true
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"50px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "label": "divgrid",
      "name": "div",
      "parent": 13,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(20, 1fr)\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 14,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/18\",display: \"flex\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 14,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"18/21\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ชุดรายการค่าใช้จ่าย"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"20px\",\"fontWeight\":\"bold\",\"fontSize\":\"20px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Button",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือก Template"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectTemplate"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"10px\",width:\"90%\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonRefresh"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"17/18\"}"
        }
      },
      "seq": 16,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_ChargeItem_Tab",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
