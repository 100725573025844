import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Dropdown, Form, Icon, Menu, Tab } from "semantic-ui-react";
import {
  CardLayout,
  DoctorSearchBox,
  FilterDateRange,
  RightLabel,
} from "../common/";
import { toast } from "react-toastify";
import { formatDropdown } from "../../utils";

// REACT
import {
  CardAdmissionNoteView,
  CardConsultNote,
  CardDischargeSummaryView,
  CardOperativeNoteView,
  CardOrderSummaryView,
  CardProgressNoteView,
  CardSensitiveNoteView,
  CardTreatmentResultView,
} from "./";

// QML
import CardCovidExamination from "../../../_qmljs/DPO/CardCovidExamination";

const styles = {
  sideTabBar: {
    fontSize: 13.5,
    width: "105%",
  },
};

const ACTIVE_INDEX = {
  PROGRESS_NOTE: 0,
  OPERATIVE_NOTE: 1,
  ADMISSION_NOTE: 2,
  DISCHARGE_SUMMARY: 3,
  ORDER_SUMMARY: 4,
  CONSULT_NOTE: 5,
  TREATMENT_RESULT: 6,
  SENSITIVE_NOTE: 7,
  ARI_EXAMINATION: 8,
  TEC_RECORD: 9,
};

const HIDE_FILTER_INDEX = [
  ACTIVE_INDEX.ARI_EXAMINATION,
  ACTIVE_INDEX.ADMISSION_NOTE,
  ACTIVE_INDEX.DISCHARGE_SUMMARY,
  ACTIVE_INDEX.CONSULT_NOTE,
  ACTIVE_INDEX.SENSITIVE_NOTE,
  ACTIVE_INDEX.TEC_RECORD,
];

const HIDE_VERBAL_ORDER = [
  ACTIVE_INDEX.PROGRESS_NOTE,
  ACTIVE_INDEX.OPERATIVE_NOTE,
  ACTIVE_INDEX.ADMISSION_NOTE,
  ACTIVE_INDEX.DISCHARGE_SUMMARY,
  ACTIVE_INDEX.CONSULT_NOTE,
  ACTIVE_INDEX.SENSITIVE_NOTE,
  ACTIVE_INDEX.ARI_EXAMINATION,
  ACTIVE_INDEX.TEC_RECORD,
];

const HIDE_PRINT_INDEX = [
  ACTIVE_INDEX.PROGRESS_NOTE,
  ACTIVE_INDEX.OPERATIVE_NOTE,
  ACTIVE_INDEX.ADMISSION_NOTE,
  ACTIVE_INDEX.DISCHARGE_SUMMARY,
  ACTIVE_INDEX.CONSULT_NOTE,
  ACTIVE_INDEX.TREATMENT_RESULT,
  ACTIVE_INDEX.SENSITIVE_NOTE,
  ACTIVE_INDEX.ARI_EXAMINATION,
  ACTIVE_INDEX.TEC_RECORD,
];

const CardMedicalNote = forwardRef((props, ref) => {
  const isMounted = useRef(true);

  const covidExaminationRef = useRef(null);
  const cardOperativeNoteViewRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [tabActiveIndex, setTabActiveIndex] = useState(0);

  const [speciality, setSpeciality] = useState({ id: null, name: "" });
  const [performUser, setPerformUser] = useState(null);
  const [performUserOptions, setPerformUserOptions] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (props.encounterId) {
      refresh();
    }
  }, [props.encounterId, tabActiveIndex]);

  useImperativeHandle(ref, () => ({
    getSpeciality: () => {
      return speciality.id;
    },
    getPerformUser: () => {
      return performUser;
    },
    getStartDate: () => {
      return startDate;
    },
    getEndDate: () => {
      return endDate;
    },
    getMedicalNoteActiveTab: () => {
      return tabActiveIndex;
    },
  }));

  // SearchBar
  const handleSpecialitySelected = (id, doctorName) => {
    setSpeciality({ id: id, name: doctorName });
  };

  const handlePerformUser = (event, value) => {
    console.log("value: "+value.value);
    if (value.value === -1) {
      setPerformUser(null);
    } else {
      setPerformUser(value.value);
    }
    
  };

  const handleSearchPerformUser = async (event) => {
    setIsLoading(true);
    if (event.target.value === "") {
      setPerformUserOptions(null);
      setIsLoading(false);
      return
    }
    const [response, error] = await props.controller.getUserList({
      nameUsername: event.target.value,
    });
    if (isMounted) {
      if (error) {
        toast.error(error);
      }
      let userOptions = formatDropdown(response, "full_name", "id");
      userOptions.splice(0, 0, { id: -1, value: -1, text: "ไม่ระบุ" });
      setPerformUserOptions(userOptions);
    }
    setIsLoading(false);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  // Tab
  const handleTabChange = (event, { activeIndex }) => {
    setTabActiveIndex(activeIndex);
    props.showTECSessionCard(false);
  };

  // Search
  const refresh = () => {
    switch (tabActiveIndex) {
      case ACTIVE_INDEX.OPERATIVE_NOTE:
        if (cardOperativeNoteViewRef?.current) {
          cardOperativeNoteViewRef?.current?.callGetOperativeNote();
        }
        break;
      case ACTIVE_INDEX.ARI_EXAMINATION:
        if (covidExaminationRef?.current?.qml) {
          covidExaminationRef?.current?.qml?.refresh();
        }
        break;
      case ACTIVE_INDEX.TEC_RECORD:
        props.showTECSessionCard(true);
        break;
      default:
        props.handleMedicalNoteSearch();
        break;
    }
  };

  return (
    <CardLayout
      titleText="Medical Note"
      closeable={props.closeable}
      toggleable={props.toggleable}
      loading={isLoading}
    >
      <Form>
        <Form.Group inline>
          <Form.Field
            width={1}
            style={{
              display: HIDE_VERBAL_ORDER.includes(tabActiveIndex)
                ? "none"
                : null,
            }}
          >
            <RightLabel>ผู้สั่ง</RightLabel>
          </Form.Field>
          <Form.Field
            width={3}
            style={{
              display: HIDE_VERBAL_ORDER.includes(tabActiveIndex)
                ? "none"
                : null,
            }}
          >
            <DoctorSearchBox
              controller={props.doctorSearchBoxController}
              onSelectDoctor={handleSpecialitySelected}
            />
          </Form.Field>
          <Form.Field
            width={1}
            style={{
              display: HIDE_VERBAL_ORDER.includes(tabActiveIndex)
                ? "none"
                : null,
            }}
          >
            <RightLabel>ผู้ทำ</RightLabel>
          </Form.Field>
          <Form.Field
            width={3}
            style={{
              display: HIDE_VERBAL_ORDER.includes(tabActiveIndex)
                ? "none"
                : null,
            }}
          >
            <Dropdown
              icon="search"
              search
              selection
              placeholder={"ไม่ระบุ"}
              onChange={handlePerformUser}
              onSearchChange={handleSearchPerformUser}
              options={performUserOptions}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group inline>
          <Form.Field
            style={{
              display: HIDE_FILTER_INDEX.includes(tabActiveIndex)
                ? "none"
                : null,
            }}
          >
            <FilterDateRange
              hideSearchButton={true}
              dateTextBoxSize="large"
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
            />
          </Form.Field>
          <Form.Field
            style={{
              display: HIDE_FILTER_INDEX.includes(tabActiveIndex)
                ? "none"
                : null,
            }}
          >
            <Button color="blue" onClick={refresh}>
              ค้นหา
            </Button>
          </Form.Field>
          <Form.Field
            style={{
              display: HIDE_PRINT_INDEX.includes(tabActiveIndex)
                ? "none"
                : null,
            }}
          >
            <Button
              color="teal"
              content="พิมพ์"
              icon="print"
              labelPosition="left"
              loading={props.isPrintLoading}
              onClick={() => {
                if (!props.isPrintLoading) {
                  props.onPrintClick();
                }
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <Tab
        activeIndex={tabActiveIndex}
        grid={{ paneWidth: 14, tabWidth: 2 }}
        menu={{
          vertical: true,
          style: styles.sideTabBar,
          className: "yellowHover",
        }}
        panes={[
          {
            menuItem: <Menu.Item key="progressNote">Progress Note</Menu.Item>,
            render: () => (
              <Tab.Pane>
                <CardProgressNoteView
                  closeable={false}
                  toggleable={false}
                  hideHeaderIcon={true}
                  defaultBackground={false}
                  enableMargin={false}
                  progressionNote={props.progressionNote}
                  encounterType={props.encounterType}
                  getProgressionNote={props.getProgressionNote}
                  progressNoteLoading={props.progressNoteLoading}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: <Menu.Item key="operativeNote">Operative Note</Menu.Item>,
            render: () => (
              <Tab.Pane>
                <CardOperativeNoteView
                  ref={cardOperativeNoteViewRef}
                  closeable={false}
                  toggleable={false}
                  defaultBackground={false}
                  hideHeaderIcon={true}
                  controller={props.cardOperativeNoteViewController}
                  encounterId={props.encounterId}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: <Menu.Item key="admissionNote">Admission Note</Menu.Item>,
            render: () => (
              <Tab.Pane>
                <CardAdmissionNoteView
                  closeable={false}
                  toggleable={false}
                  hideHeaderIcon={true}
                  defaultBackground={false}
                  enableMargin={false}
                  admissionNote={props.admissionNote}
                  getAdmissionNote={props.getAdmissionNote}
                  admissionNoteLoading={props.admissionNoteLoading}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: <Menu.Item key="discSum">Discharge Summary</Menu.Item>,
            render: () => (
              <Tab.Pane>
                <CardDischargeSummaryView
                  closeable={false}
                  toggleable={false}
                  hideHeaderIcon={true}
                  defaultBackground={false}
                  enableMargin={false}
                  encounterType={props.encounterType}
                  dischargeSummary={props.dischargeSummary}
                  onGetDischargeSummary={props.onGetDischargeSummary}
                  dischargeSummaryData={props.dischargeSummaryData}
                  dischargeSummaryLoading={props.dischargeSummaryLoading}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: <Menu.Item key="orderSum">Order Summary</Menu.Item>,
            render: () => (
              <Tab.Pane>
                <CardOrderSummaryView
                  closeable={false}
                  toggleable={false}
                  hideHeaderIcon={true}
                  defaultBackground={false}
                  enableMargin={false}
                  onGetOrderSummary={props.onGetOrderSummary}
                  orderSummaryLoading={props.orderSummaryLoading}
                  orderSummaryData={props.orderSummaryData}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: <Menu.Item key="consultNote">Consult Note</Menu.Item>,
            render: () => (
              <Tab.Pane>
                <CardConsultNote
                  closeable={false}
                  toggleable={false}
                  hideHeaderIcon={true}
                  defaultBackground={false}
                  enableMargin={false}
                  consultOrder={props.consultOrder}
                  consultOrderResponse={props.consultOrderResponse}
                  onGetConsultNote={props.onGetConsultNote}
                  consultNoteLoading={props.consultNoteLoading}
                  consultNoteData={props.consultNoteData}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: <Menu.Item key="TrtResult">Treatment Result</Menu.Item>,
            render: () => (
              <Tab.Pane>
                <CardTreatmentResultView
                  closeable={false}
                  toggleable={false}
                  hideHeaderIcon={true}
                  defaultBackground={false}
                  enableMargin={false}
                  treatmentOrderResult={props.treatmentNoteData}
                  treatmentNoteLoading={props.treatmentNoteLoading}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: <Menu.Item key="sensNote">Sensitive Note</Menu.Item>,
            render: () => (
              <Tab.Pane>
                <CardSensitiveNoteView
                  closeable={false}
                  toggleable={false}
                  hideHeaderIcon={true}
                  defaultBackground={false}
                  enableMargin={false}
                  sensitiveNote={props.sensitiveNote}
                  onGetSensitiveNote={props.onGetSensitiveNote}
                  sensitiveNoteLoading={props.sensitiveNoteLoading}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: <Menu.Item key="ariExam">ARI Examination</Menu.Item>,
            render: () => (
              <Tab.Pane>
                <CardCovidExamination
                  ref={covidExaminationRef}
                  encounter_id={props.encounterId}
                  patient_id={props.patientId}
                  readOnly={true}
                  // onUnmount={() => (window.QmlWeb.engine = null)}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: <Menu.Item key="tecRec">Technicial Record</Menu.Item>,
            render: () => (
              <Tab.Pane>
                <p>
                  กรุณาดูข้อมูลด้านล่าง (Detail are below)
                  <Icon disabled name="arrow down" />
                </p>
              </Tab.Pane>
            ),
          },
        ]}
        onTabChange={handleTabChange}
      />
    </CardLayout>
  );
});

CardMedicalNote.defaultProps = {
  closeable: true,
  toggleable: true,

  controller: null,
  doctorSearchBoxController: null,
  cardOperativeNoteViewController: null,

  patientId: null,
  encounterId: null,
  encounterType: null,

  // Todo: change structure -> controller
  handleMedicalNoteSearch: () => {},

  isPrintLoading: false,
  onPrintClick: () => {},

  progressNoteLoading: false,
  getProgressionNote: () => {},
  progressionNote: null,

  admissionNoteLoading: false,
  getAdmissionNote: () => {},
  admissionNote: null,

  dischargeSummaryLoading: false,
  onGetDischargeSummary: () => {},
  dischargeSummary: null,
  dischargeSummaryData: null,

  orderSummaryLoading: false,
  onGetOrderSummary: () => {},
  orderSummaryData: null,

  consultNoteLoading: false,
  onGetConsultNote: () => {},
  consultOrder: null,
  consultOrderResponse: null,
  consultNoteData: null,

  treatmentNoteLoading: false,
  treatmentNoteData: null,

  sensitiveNoteLoading: false,
  onGetSensitiveNote: () => {},
  sensitiveNote: null,

  showTECSessionCard: () => {},
};

export default CardMedicalNote;
