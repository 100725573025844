import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Checkbox,
  Input,
  Radio,
  Dropdown,
  Button,
  TextArea,
  FormGroup,
  FormField
} from 'semantic-ui-react'

const CardHoldingRoomUX = (props: any) => {
    return(
      <div
        style={{ margin: "15px",  padding: "20px",  backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", fontSize: "22px" }}>
            Holding Room
          </label>
          <Label
            color={props.statusColor}
            style={{ float: "right" }}>
            {props.PreOperationSequence?.holdingRoomData?.status || ""}
          </Label>
          <hr>
          </hr>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold", margin: "5px 10px 0px 0px" }}>
            
            <label>
              Patient identification
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.PreOperationSequence?.holdingRoomData?.data?.verbally}
            label="Verbally"
            name="verbally"
            onChange={props.onChangeData}
            style={{ fontWeight: "bold", width: "10%", margin: "5px 10px" }}>
          </Checkbox>
          <Checkbox
            checked={props.PreOperationSequence?.holdingRoomData?.data?.name_band}
            label="Name band"
            name="name_band"
            onChange={props.onChangeData}
            style={{ fontWeight: "bold", width: "10%", margin: "5px 10px" }}>
          </Checkbox>
          <Checkbox
            checked={props.PreOperationSequence?.holdingRoomData?.data?.chart}
            label="Chart"
            name="chart"
            onChange={props.onChangeData}
            style={{ fontWeight: "bold", width: "10%", margin: "5px 10px" }}>
          </Checkbox>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              History illness
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Input
            name="history_illness"
            onChange={props.onChangeData}
            style={{ width: "50%" }}
            value={props.PreOperationSequence?.holdingRoomData?.data?.history_illness || ""}>
          </Input>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <label
            style={{  fontWeight: "bold",   width: "25%",   margin: "5px 10px 0px 0px" }}>
            Previous Operation
          </label>
          <label>
            {props.PreOperationSequence?.holdingRoomData?.data?.previous_operation || "-"}
          </label>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              Procedure Confirm
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
            <Checkbox
              checked={props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm}
              label={props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm ? "YES" : "NO"}
              name="procedure_confirm"
              onChange={props.onChangeData}
              style={{ float: "right", marginRight: "20px"}}
              toggle={true}>
            </Checkbox>
          </div>
          <Input
            disabled={!props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm}
            name="procedure_confirm_detail"
            onChange={props.onChangeData}
            style={{ width: "50%" }}
            value={props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm_detail || ""}>
          </Input>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              Mark site Confirm
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Radio
            checked={props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === "yes"}
            label="Yes"
            name="mark_site_confirm"
            onChange={props.onChangeData}
            style={{ fontWeight: "bold", width: "5%", margin: "5px 10px" }}
            value="yes">
          </Radio>
          <Radio
            checked={props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === "no"}
            label="No"
            name="mark_site_confirm"
            onChange={props.onChangeData}
            style={{ fontWeight: "bold", width: "5%", margin: "5px 10px" }}
            value="no">
          </Radio>
          <Radio
            checked={props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === "n/a"}
            label="N/A"
            name="mark_site_confirm"
            onChange={props.onChangeData}
            style={{ fontWeight: "bold", margin: "5px 10px" }}
            value="n/a">
          </Radio>
          <Input
            name="mark_site_confirm_detail"
            onChange={props.onChangeData}
            style={{ width: "31%", display: props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === "n/a" ? "" : "none" }}
            value={props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm_detail || ""}>
          </Input>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <label
            style={{  fontWeight: "bold",   width: "25%",   margin: "5px 10px 0px 0px" }}>
            Confirmation Surgical site per PT/Family
          </label>
          <Radio
            checked={props.PreOperationSequence?.holdingRoomData?.data?.confirm_surgical === "yes"}
            label="Yes"
            name="confirm_surgical"
            onChange={props.onChangeData}
            style={{ fontWeight: "bold", width: "5%", margin: "5px 10px" }}
            value="yes">
          </Radio>
          <Radio
            checked={props.PreOperationSequence?.holdingRoomData?.data?.confirm_surgical === "no"}
            label="No"
            name="confirm_surgical"
            onChange={props.onChangeData}
            style={{ fontWeight: "bold", margin: "5px 10px" }}
            value="no">
          </Radio>
        </div>
        <div
          style={{ fontWeight: "bold", paddingBottom: "5px" }}>
          
          <div
            style={{ marginTop: "5px" }}>
            
            <label>
              Home medication
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <div
            style={{ display: "flex", marginTop: "5px"}}>
            
            <div
              style={{ width: "25%", margin: "5px 10px 0px 0px", paddingLeft: "30px"  }}>
              Anti Coagulation Drug
            </div>
            <Checkbox
              checked={props.PreOperationSequence?.holdingRoomData?.data?.anti_coagulation_drug}
              label={props.PreOperationSequence?.holdingRoomData?.data?.anti_coagulation_drug ? "YES" : "NO"}
              name="anti_coagulation_drug"
              onChange={props.onChangeData}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            style={{ display: "flex", marginTop: "5px"}}>
            
            <div
              style={{ width: "25%", margin: "5px 10px 0px 0px", paddingLeft: "30px"  }}>
              DM Drug
            </div>
            <Checkbox
              checked={props.PreOperationSequence?.holdingRoomData?.data?.dm_drug}
              label={props.PreOperationSequence?.holdingRoomData?.data?.dm_drug ? "YES" : "NO"}
              name="dm_drug"
              onChange={props.onChangeData}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            style={{ display: "flex", marginTop: "5px"}}>
            
            <div
              style={{ width: "25%", margin: "5px 10px 0px 0px", paddingLeft: "30px"  }}>
              Anti Hypertensive Drug
            </div>
            <Checkbox
              checked={props.PreOperationSequence?.holdingRoomData?.data?.anti_hypertensive_drug}
              label={props.PreOperationSequence?.holdingRoomData?.data?.anti_hypertensive_drug ? "YES" : "NO"}
              name="anti_hypertensive_drug"
              onChange={props.onChangeData}
              toggle={true}>
            </Checkbox>
          </div>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              Valuable
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.PreOperationSequence?.holdingRoomData?.data?.valuable}
            label={props.PreOperationSequence?.holdingRoomData?.data?.valuable ? "YES" : "NO"}
            name="valuable"
            onChange={props.onChangeData}
            style={{ marginRight: "10px" }}
            toggle={true}>
          </Checkbox>
          <Dropdown
            clearable={true}
            multiple={true}
            name="valuable_detail"
            onChange={props.onChangeData}
            options={props.masterOptions?.orValuable || []}
            search={true}
            selection={true}
            style={{ width: "46%", display: props.PreOperationSequence?.holdingRoomData?.data?.valuable ? "" : "none" }}
            value={props.PreOperationSequence?.holdingRoomData?.data?.valuable_detail || []}>
          </Dropdown>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              Implant
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.PreOperationSequence?.holdingRoomData?.data?.implant}
            label={props.PreOperationSequence?.holdingRoomData?.data?.implant ? "YES" : "NO"}
            name="implant"
            onChange={props.onChangeData}
            style={{ marginRight: "10px" }}
            toggle={true}>
          </Checkbox>
          <Input
            name="implant_detail"
            onChange={props.onChangeData}
            style={{ width: "46%", display: props.PreOperationSequence?.holdingRoomData?.data?.implant ? "" : "none" }}
            value={props.PreOperationSequence?.holdingRoomData?.data?.implant_detail || ""}>
          </Input>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              Limitation
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Dropdown
            name="limitation"
            onChange={props.onChangeData}
            options={props.masterOptions?.limitation || []}
            selection={true}
            style={{ width: "30%" }}
            value={props.PreOperationSequence?.holdingRoomData?.data?.limitation}>
          </Dropdown>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              Status of Conscious
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Dropdown
            name="status_of_conscious"
            onChange={props.onChangeData}
            options={props.masterOptions?.statusOfConscious || []}
            selection={true}
            style={{ width: "50%" }}
            value={props.PreOperationSequence?.holdingRoomData?.data?.status_of_conscious}>
          </Dropdown>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              Psychological Status
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Dropdown
            name="psychological_status"
            onChange={props.onChangeData}
            options={props.masterOptions?.psychologicalStatus || []}
            selection={true}
            style={{ width: "50%" }}
            value={props.PreOperationSequence?.holdingRoomData?.data?.psychological_status}>
          </Dropdown>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              Respiratory Status*
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Dropdown
            name="respiratory_status"
            onChange={props.onChangeData}
            options={props.masterOptions?.respiratoryStatus || []}
            selection={true}
            style={{ width: "50%" }}
            value={props.PreOperationSequence?.holdingRoomData?.data?.respiratory_status}>
          </Dropdown>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <label
            style={{  fontWeight: "bold",   width: "25%",   margin: "5px 10px 0px 0px" }}>
            Lab Data
          </label>
          <label>
            {props.PreOperationSequence?.holdingRoomData?.data?.lab_data || ""}
          </label>
          <Button
            icon="list"
            onClick={props.onShowLabData}
            size="mini">
          </Button>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <label
            style={{  fontWeight: "bold",   width: "25%",   margin: "5px 10px 0px 0px" }}>
            Lab Remark
          </label>
          <Input
            name="lab_remark"
            onChange={props.onChangeData}
            style={{ width: "50%" }}
            value={props.PreOperationSequence?.holdingRoomData?.data?.lab_remark || ""}>
          </Input>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <label
            style={{  fontWeight: "bold",   width: "25%",   margin: "5px 10px 0px 0px" }}>
            Blood
          </label>
          <Input
            name="blood"
            onChange={props.onChangeData}
            style={{ width: "50%" }}
            value={props.PreOperationSequence?.holdingRoomData?.data?.blood || ""}>
          </Input>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <label
            style={{  fontWeight: "bold",   width: "25%",   margin: "5px 10px 0px 0px" }}>
            Remark
          </label>
          <div
            className="ui form"
            style={{ width: "68%" }}>
            
            <FormGroup
              inline={true}>
              <FormField
                width={16}>
                <TextArea
                  name="remark"
                  onChange={props.onChangeData}
                  rows={5}
                  style={{ width: "75%" }}
                  value={props.PreOperationSequence?.holdingRoomData?.data?.remark || ""}>
                </TextArea>
              </FormField>
            </FormGroup>
          </div>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <label
            style={{  fontWeight: "bold",   width: "25%",  margin: "5px 10px 0px 0px" }}>
            Oxygen
          </label>
          <Dropdown
            name="oxygen"
            onChange={props.onChangeData}
            options={[ { key: 1, value: 1, text: "1" }, { key: 2, value: 2, text: "2" }, { key: 3, value: 3, text: "3" }, { key: 4, value: 4, text: "4" }, { key: 5, value: 5, text: "5" }, { key: 6, value: 6, text: "6" }, { key: 7, value: 7, text: "7" }, { key: 8, value: 8, text: "8" }, { key: 9, value: 9, text: "9" }, { key: 10, value: 10, text: "10" } ]}
            selection={true}
            value={props.PreOperationSequence?.holdingRoomData?.data?.oxygen}>
          </Dropdown>
          <label
            style={{  fontWeight: "bold",  paddingLeft: "10px",  marginTop: "5px" }}>
            L/min
          </label>
        </div>
        <div
          style={{ fontWeight: "bold", paddingBottom: "5px" }}>
          
          <label>
            Medication
          </label>
          <div>
            {props.divMedication}
          </div>
        </div>
        <div
          style={{ fontWeight: "bold", paddingBottom: "5px" }}>
          
          <label>
            Other
          </label>
          <div>
            {props.divOtherMedication}
          </div>
        </div>
        <div
          style={{ backgroundColor: "#FDF8DD", padding: "30px", margin: "30px 0px" }}>
          
          <div
            style={{ display: "flex", paddingBottom: "5px" }}>
            
            <label
              style={{  fontWeight: "bold",   width: "15%",  margin: "5px 10px 0px 0px" }}>
              Nursiong Diagnosis
            </label>
            <label
              style={{ width: "30%",  margin: "5px 10px 0px 0px" }}>
              {props.PreOperationSequence?.holdingRoomData?.data?.nursing_diagnosis || "-"}
            </label>
            <label
              style={{  fontWeight: "bold",   width: "10%",  margin: "5px 10px 0px 0px" }}>
              Goal
            </label>
            <label
              style={{ width: "25%",  margin: "5px 10px 0px 0px" }}>
              {props.PreOperationSequence?.holdingRoomData?.data?.goal || "-"}
            </label>
          </div>
          <div
            style={{ display: "flex", paddingBottom: "5px" }}>
            
            <label
              style={{  fontWeight: "bold",   width: "15%",  margin: "5px 10px 0px 0px" }}>
              Plan and implementation
            </label>
            <Dropdown
              clearable={true}
              multiple={true}
              name="plan_and_implementation"
              onChange={props.onChangeData}
              options={props.masterOptions?.planAndImpleHr || []}
              search={true}
              selection={true}
              style={{ width: "30%",  margin: "5px 10px 0px 0px" }}
              value={props.PreOperationSequence?.holdingRoomData?.data?.plan_and_implementation || []}>
            </Dropdown>
            <label
              style={{  fontWeight: "bold",   width: "10%",  margin: "5px 10px 0px 0px" }}>
              Evaluation
            </label>
            <label
              style={{ width: "22%",  margin: "5px 10px 0px 0px" }}>
              {props.PreOperationSequence?.holdingRoomData?.data?.evaluation || "-"}
            </label>
            <Checkbox
              checked={props.PreOperationSequence?.holdingRoomData?.data?.evaluation_check}
              label={props.PreOperationSequence?.holdingRoomData?.data?.evaluation_check ? "YES" : "NO"}
              name="evaluation_check"
              onChange={props.onChangeData}
              style={{ margin: "5px" }}
              toggle={true}>
            </Checkbox>
            <Input
              name="evaluation_detail"
              onChange={props.onChangeData}
              style={{ width: "22%" }}
              value={props.PreOperationSequence?.holdingRoomData?.data?.evaluation_detail || ""}>
            </Input>
          </div>
        </div>
        <div
          style={{ height: "55px", paddingTop: "15px" }}>
          
          <div
            style={{ display: "flex", float: "right" }}>
            
            <div>
              {props.buttonSave}
            </div>
            <div>
              {props.buttonConfirm}
            </div>
            <div>
              {props.buttonUnconfirm}
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardHoldingRoomUX

export const screenPropsDefault = {}

/* Date Time : Tue Mar 07 2023 04:10:29 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\",  padding: \"20px\",  backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Holding Room"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"22px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.status || \"\""
        },
        "color": {
          "type": "code",
          "value": "props.statusColor"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient identification"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Checkbox",
      "parent": 5,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.verbally"
        },
        "label": {
          "type": "value",
          "value": "Verbally"
        },
        "name": {
          "type": "value",
          "value": "verbally"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"10%\", margin: \"5px 10px\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Checkbox",
      "parent": 5,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.name_band"
        },
        "label": {
          "type": "value",
          "value": "Name band"
        },
        "name": {
          "type": "value",
          "value": "name_band"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"10%\", margin: \"5px 10px\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Checkbox",
      "parent": 5,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.chart"
        },
        "label": {
          "type": "value",
          "value": "Chart"
        },
        "name": {
          "type": "value",
          "value": "chart"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"10%\", margin: \"5px 10px\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "History illness"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Input",
      "parent": 12,
      "props": {
        "name": {
          "type": "value",
          "value": "history_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.history_illness || \"\""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "Previous Operation"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"25%\",   margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.previous_operation || \"-\""
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "Procedure Confirm"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Input",
      "parent": 21,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm"
        },
        "name": {
          "type": "value",
          "value": "procedure_confirm_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm_detail || \"\""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Checkbox",
      "parent": 22,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.procedure_confirm ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "procedure_confirm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\", marginRight: \"20px\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "Mark site Confirm"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Radio",
      "parent": 27,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === \"yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "mark_site_confirm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"5%\", margin: \"5px 10px\" }"
        },
        "value": {
          "type": "value",
          "value": "yes"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Radio",
      "parent": 27,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === \"no\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "mark_site_confirm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"5%\", margin: \"5px 10px\" }"
        },
        "value": {
          "type": "value",
          "value": "no"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Radio",
      "parent": 27,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === \"n/a\""
        },
        "label": {
          "type": "value",
          "value": "N/A"
        },
        "name": {
          "type": "value",
          "value": "mark_site_confirm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        },
        "value": {
          "type": "value",
          "value": "n/a"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "Confirmation Surgical site per PT/Family"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"25%\",   margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Radio",
      "parent": 34,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.confirm_surgical === \"yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "confirm_surgical"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"5%\", margin: \"5px 10px\" }"
        },
        "value": {
          "type": "value",
          "value": "yes"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Radio",
      "parent": 34,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.confirm_surgical === \"no\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "confirm_surgical"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        },
        "value": {
          "type": "value",
          "value": "no"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"5px\" }"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "Home medication"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "Anti Coagulation Drug"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"25%\", margin: \"5px 10px 0px 0px\", paddingLeft: \"30px\"  }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Checkbox",
      "parent": 49,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.anti_coagulation_drug"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.anti_coagulation_drug ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "anti_coagulation_drug"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginTop: \"5px\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginTop: \"5px\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": "DM Drug"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"25%\", margin: \"5px 10px 0px 0px\", paddingLeft: \"30px\"  }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Checkbox",
      "parent": 50,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.dm_drug"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.dm_drug ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "dm_drug"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginTop: \"5px\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "Anti Hypertensive Drug"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"25%\", margin: \"5px 10px 0px 0px\", paddingLeft: \"30px\"  }"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Checkbox",
      "parent": 54,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.anti_hypertensive_drug"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.anti_hypertensive_drug ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "anti_hypertensive_drug"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "label",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "Valuable"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "label",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Checkbox",
      "parent": 58,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.valuable"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.valuable ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "valuable"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": "Implant"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "label",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "Checkbox",
      "parent": 63,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.implant"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.implant ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "implant"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "Limitation"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Dropdown",
      "parent": 68,
      "props": {
        "name": {
          "type": "value",
          "value": "limitation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.limitation || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.limitation"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 74,
      "props": {
        "children": {
          "type": "value",
          "value": "Status of Conscious"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "label",
      "parent": 74,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Dropdown",
      "parent": 73,
      "props": {
        "name": {
          "type": "value",
          "value": "status_of_conscious"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.statusOfConscious || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.status_of_conscious"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "label",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": "Psychological Status"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "label",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Dropdown",
      "parent": 78,
      "props": {
        "name": {
          "type": "value",
          "value": "psychological_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.psychologicalStatus || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.psychological_status"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "label",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "Respiratory Status*"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "label",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Dropdown",
      "parent": 83,
      "props": {
        "name": {
          "type": "value",
          "value": "respiratory_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.respiratoryStatus || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.respiratory_status"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "label",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab Data"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"25%\",   margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "Button",
      "parent": 88,
      "props": {
        "icon": {
          "type": "value",
          "value": "list"
        },
        "onClick": {
          "type": "code",
          "value": "props.onShowLabData"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "label",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab Remark"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"25%\",   margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Input",
      "parent": 94,
      "props": {
        "name": {
          "type": "value",
          "value": "lab_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.lab_remark || \"\""
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "label",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": "Blood"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"25%\",   margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Input",
      "parent": 99,
      "props": {
        "name": {
          "type": "value",
          "value": "blood"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.blood || \"\""
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "label",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": "Remark"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"25%\",   margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "TextArea",
      "parent": 173,
      "props": {
        "name": {
          "type": "value",
          "value": "remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "rows": {
          "type": "code",
          "value": "5"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"75%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.remark || \"\""
        }
      },
      "seq": 105,
      "void": true
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "label",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "Oxygen"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"25%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Dropdown",
      "parent": 106,
      "props": {
        "name": {
          "type": "value",
          "value": "oxygen"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "[ { key: 1, value: 1, text: \"1\" }, { key: 2, value: 2, text: \"2\" }, { key: 3, value: 3, text: \"3\" }, { key: 4, value: 4, text: \"4\" }, { key: 5, value: 5, text: \"5\" }, { key: 6, value: 6, text: \"6\" }, { key: 7, value: 7, text: \"7\" }, { key: 8, value: 8, text: \"8\" }, { key: 9, value: 9, text: \"9\" }, { key: 10, value: 10, text: \"10\" } ]"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.oxygen"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "label",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "L/min"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",  paddingLeft: \"10px\",  marginTop: \"5px\" }"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "label",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": "Medication"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#FDF8DD\", padding: \"30px\", margin: \"30px 0px\" }"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": "Nursiong Diagnosis"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"15%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.nursing_diagnosis || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": "Goal"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"10%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.goal || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"25%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan and implementation"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"15%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": "Evaluation"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"10%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.evaluation || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"22%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "Checkbox",
      "parent": 145,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.evaluation_check"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.evaluation_check ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "evaluation_check"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"5px\" }"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "Input",
      "parent": 145,
      "props": {
        "name": {
          "type": "value",
          "value": "evaluation_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"22%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.evaluation_detail || \"\""
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "Dropdown",
      "parent": 145,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "plan_and_implementation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.planAndImpleHr || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\",  margin: \"5px 10px 0px 0px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.plan_and_implementation || []"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\" }"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", float: \"right\" }"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "Dropdown",
      "parent": 58,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "valuable_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orValuable || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"46%\", display: props.PreOperationSequence?.holdingRoomData?.data?.valuable ? \"\" : \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.valuable_detail || []"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "Input",
      "parent": 27,
      "props": {
        "name": {
          "type": "value",
          "value": "mark_site_confirm_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"31%\", display: props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm === \"n/a\" ? \"\" : \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.mark_site_confirm_detail || \"\""
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "Input",
      "parent": 63,
      "props": {
        "name": {
          "type": "value",
          "value": "implant_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"46%\", display: props.PreOperationSequence?.holdingRoomData?.data?.implant ? \"\" : \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.implant_detail || \"\""
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 112,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divMedication"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "label",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": "Other"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 167,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divOtherMedication"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "label",
      "parent": 88,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.holdingRoomData?.data?.lab_data || \"\""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "FormGroup",
      "parent": 174,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 173,
      "name": "FormField",
      "parent": 172,
      "props": {
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "div",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"68%\" }"
        }
      },
      "seq": 174,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardHoldingRoomUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
