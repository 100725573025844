import moment from "moment";
import WasmController from "react-lib/frameworks/WasmController";

// APIs
// INF
import CoveragePayerSentClaimGroupList from "issara-sdk/apis/CoveragePayerSentClaimGroupList_apps_INF";
import BillTransactionList from "issara-sdk/apis/BillTransactionList_apps_INF";
import GenerateSentClaimDataHISFromGroupAPIView from "issara-sdk/apis/GenerateSentClaimDataHISFromGroupAPIView_apps_INF";
import CreateARTransactionAPIView from "issara-sdk/apis/CreateARTransactionAPIView_apps_INF";

// Utils
import { beToAd } from "react-lib/utils/dateUtils";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  masterOptions?: any;
  coveragePayerOptions?: any[];
  ARInvoiceGroupSequence?: {
    sequenceIndex?: string | null;
    selectedCoveragePayer?: any;
    checkedSelectedDate?: boolean | null;
    startDate?: string | null;
    endDate?: string | null;
    selectedPayer?: any;
    checekdInvoiceType?: string | null;
    selectedLotNo?: any;
  };
  billtransactionItem?: any[];
};

export const StateInitial: State = {
  // sequence
  coveragePayerOptions: [],
  ARInvoiceGroupSequence: {
    sequenceIndex: null,
  },
  billtransactionItem: [],
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
  masterData?: { [name: string]: any };
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const { errorMessage } = controller.getState();
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["coverage", {}],
        ["payer", {}],
      ],
    },
  });

  const coveragePayerResult = await CoveragePayerSentClaimGroupList.list({
    apiToken: controller.apiToken,
    params: {},
  });

  if (coveragePayerResult[1]) {
    return controller.setState({
      errorMessage: {
        ...errorMessage,
        ...(params?.card ? { [params.card]: coveragePayerResult[1] } : {}),
      },
    });
  }

  let coveragePayerItems = [];
  if (coveragePayerResult?.[0]?.items) {
    coveragePayerItems = [...coveragePayerResult?.[0]?.items].map(
      (item: any) => ({
        ...item,
        key: item.id,
        value: item.id,
        text: `[${item.code}] ${item.name}`,
      })
    );
  }

  controller.setState({
    coveragePayerOptions: coveragePayerItems,
    ARInvoiceGroupSequence: {
      sequenceIndex: "ACTION",
      selectedCoveragePayer: null,
      checkedSelectedDate: false,
      startDate: "",
      endDate: "",
      selectedPayer: null,
      checekdInvoiceType: null,
      selectedLotNo: null,
    },
  });
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params.action === "search") {
    const billingTransaction = await BillTransactionList.list({
      apiToken: controller.apiToken,
      params: {
        start_date: state.ARInvoiceGroupSequence?.startDate,
        end_date: state.ARInvoiceGroupSequence?.endDate,
        ar_transaction: state.ARInvoiceGroupSequence?.selectedLotNo,
        coverage_payer_sent_claim_group:
          state.ARInvoiceGroupSequence?.selectedCoveragePayer,
      },
    });

    if (billingTransaction[1]) {
      return controller.setState({
        errorMessage: {
          ...state.errorMessage,
          ...(params?.card ? { [params.card]: billingTransaction[1] } : {}),
        },
      });
    }

    controller.setState({
      billtransactionItem: billingTransaction?.[0]?.items || [],
    });
    console.log("billingTransaction: ", billingTransaction);
  } else if (params.action === "create_ar") {
    if (!params.coveragePayer || !params.date) {
      return controller.setState({
        errorMessage: {
          ...state.errorMessage,
          ...(params?.card
            ? { [params.card]: ["coverage payer invalid", "date invalid"] }
            : {}),
        },
      });
    }

    const generateSentClaim =
      await GenerateSentClaimDataHISFromGroupAPIView.get({
        apiToken: controller.apiToken,
        coverage_payer_sent_claim_group_id: params.coveragePayer,
        start_date: beToAd(params.date)?.format("YYYY-MM-DD"),
        end_date: beToAd(params.date)?.format("YYYY-MM-DD"),
      });

    if (generateSentClaim[1]) {
      return controller.setState({
        errorMessage: {
          ...state.errorMessage,
          ...(params?.card ? { [params.card]: generateSentClaim[1] } : {}),
        },
      });
    }
  } else if (params.action === "create_ar_transaction") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    const data = state.ARInvoiceGroupSequence;

    const createAR = await CreateARTransactionAPIView.post({
      apiToken: controller.apiToken,
      data: {
        coverage_payer_sent_claim_group:
          state.ARInvoiceGroupSequence?.selectedCoveragePayer,
        start_date: data?.startDate
          ? beToAd(data?.startDate)?.format("YYYY-MM-DD")
          : undefined,
        end_date: data?.endDate
          ? beToAd(data?.endDate)?.format("YYYY-MM-DD")
          : undefined,
      },
    });

    if (createAR[1]) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: createAR[1],
        },
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
        successMessage: {
          ...state.errorMessage,
          [`${params.card}_${params.action}`]:
            createAR[0]?.lot_no || "",
        },
      });

      Action(controller, { card: params.card, action: 'search' })
    }
  }
};
