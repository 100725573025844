import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Radio,
  TextArea
} from 'semantic-ui-react'

const ANES_AnesComplication04 = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div>
        <div
          style={{height:"50px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(1, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/41",display: "flex" , border: "solid 1px rgb(200, 200, 200, 0.5)","backgroundColor":"rgb(161, 221, 230, 0.49)", justifyContent: "space-between", }}>
              <label
                style={{textAlign:"center",marginLeft:"20px",paddingTop:"10px","fontWeight":"bold","fontSize":"17px"}}>
                Postoperative Pain management in PACU
              </label>
              <div
                style={{ margin: "5px", display: props.buttonStatus === "CONFIRMED" ?  "none" : "", }}>
                {props.buttonSave}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{height:"210px",border: "solid 1px rgb(200, 200, 200, 0.5)"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(4, 1fr)","gridTemplateColumns":"repeat(1, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/2" }}>
              <Radio
                checked={props.AnesComplicationSequence?.PostAnesthesiaCareOffServiceTime === "24 hr."}
                label="24 hr."
                name="PostAnesthesiaCareOffServiceTime"
                onChange={props.onChangeData}
                style={{marginLeft:"30px","fontSize":"17px",marginTop:"10px"}}
                value="24 hr.">
              </Radio>
              <Radio
                checked={props.AnesComplicationSequence?.PostAnesthesiaCareOffServiceTime === "24 - 48 hr."}
                label="24 - 48 hr."
                name="PostAnesthesiaCareOffServiceTime"
                onChange={props.onChangeData}
                style={{marginLeft:"30px","fontSize":"17px",marginTop:"10px"}}
                value="24 - 48 hr.">
              </Radio>
              <Radio
                checked={props.AnesComplicationSequence?.PostAnesthesiaCareOffServiceTime === "48 hr."}
                label="48 hr."
                name="PostAnesthesiaCareOffServiceTime"
                onChange={props.onChangeData}
                style={{marginLeft:"30px","fontSize":"17px",marginTop:"10px"}}
                value="48 hr.">
              </Radio>
              <Radio
                checked={props.AnesComplicationSequence?.PostAnesthesiaCareOffServiceTime === "มากกว่า 48 hr."}
                label="มากกว่า 48 hr."
                name="PostAnesthesiaCareOffServiceTime"
                onChange={props.onChangeData}
                style={{marginLeft:"30px","fontSize":"17px",marginTop:"20px"}}
                value="มากกว่า 48 hr.">
              </Radio>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "1/2",display: "flex"}}>
              <label
                style={{textAlign:"center",marginLeft:"20px",paddingTop:"20px","fontSize":"16px"}}>
                กรณีที่ติดตามเยี่ยมผู้ป่วยต่อเนื่องมากกว่า 24 ชั่วโมงระบุเหตุผล
              </label>
            </div>
            <div
              style={{ gridRow: "3/5", gridColumn: "1/2" }}>
              <TextArea
                name="PostAnesthesiaCareOffServiceTimeRemark"
                onChange={props.onChangeData}
                style={{marginLeft:"20px",width:"80%",height:"80%"}}
                value={props.AnesComplicationSequence?.PostAnesthesiaCareOffServiceTimeRemark}>
              </TextArea>
            </div>
          </div>
        </div>
      </div>
    )
}


export default ANES_AnesComplication04

export const screenPropsDefault = {}

/* Date Time : Thu Jul 13 2023 03:05:24 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"50px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/41\",display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230, 0.49)\", justifyContent: \"space-between\", }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Postoperative Pain management in PACU"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"10px\",\"fontWeight\":\"bold\",\"fontSize\":\"17px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"210px\",border: \"solid 1px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "label": "divgrid",
      "name": "div",
      "parent": 42,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(4, 1fr)\",\"gridTemplateColumns\":\"repeat(1, 1fr)\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 43,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 43,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/2\",display: \"flex\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 43,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/5\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Radio",
      "parent": 44,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.PostAnesthesiaCareOffServiceTime === \"24 hr.\""
        },
        "label": {
          "type": "value",
          "value": "24 hr."
        },
        "name": {
          "type": "value",
          "value": "PostAnesthesiaCareOffServiceTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"30px\",\"fontSize\":\"17px\",marginTop:\"10px\"}"
        },
        "value": {
          "type": "value",
          "value": "24 hr."
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Radio",
      "parent": 44,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.PostAnesthesiaCareOffServiceTime === \"48 hr.\""
        },
        "label": {
          "type": "value",
          "value": "48 hr."
        },
        "name": {
          "type": "value",
          "value": "PostAnesthesiaCareOffServiceTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"30px\",\"fontSize\":\"17px\",marginTop:\"10px\"}"
        },
        "value": {
          "type": "value",
          "value": "48 hr."
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Radio",
      "parent": 44,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.PostAnesthesiaCareOffServiceTime === \"มากกว่า 48 hr.\""
        },
        "label": {
          "type": "value",
          "value": "มากกว่า 48 hr."
        },
        "name": {
          "type": "value",
          "value": "PostAnesthesiaCareOffServiceTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"30px\",\"fontSize\":\"17px\",marginTop:\"20px\"}"
        },
        "value": {
          "type": "value",
          "value": "มากกว่า 48 hr."
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "กรณีที่ติดตามเยี่ยมผู้ป่วยต่อเนื่องมากกว่า 24 ชั่วโมงระบุเหตุผล"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"20px\",\"fontSize\":\"16px\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "TextArea",
      "parent": 46,
      "props": {
        "name": {
          "type": "value",
          "value": "PostAnesthesiaCareOffServiceTimeRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",width:\"80%\",height:\"80%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.PostAnesthesiaCareOffServiceTimeRemark"
        }
      },
      "seq": 51,
      "void": true
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"5px\", display: props.buttonStatus === \"CONFIRMED\" ?  \"none\" : \"\", }"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Radio",
      "parent": 44,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesComplicationSequence?.PostAnesthesiaCareOffServiceTime === \"24 - 48 hr.\""
        },
        "label": {
          "type": "value",
          "value": "24 - 48 hr."
        },
        "name": {
          "type": "value",
          "value": "PostAnesthesiaCareOffServiceTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"30px\",\"fontSize\":\"17px\",marginTop:\"10px\"}"
        },
        "value": {
          "type": "value",
          "value": "24 - 48 hr."
        }
      },
      "seq": 48,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_AnesComplication04",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
