import WasmController from "react-lib/frameworks/WasmController";
import * as OrderSupply from "./sequence/OrderSupply";
import * as SupplyDelivery from "./sequence/SupplyDelivery";
import * as RefillDivisionPrint from "./sequence/RefillDivisionPrint";
import * as ReturnSupply from"./sequence/ReturnSupply";
import ManufacturerList from "issara-sdk/apis/ManufacturerList_apps_MSD";
import UserTokenizeView from "issara-sdk/apis/UserTokenizeView_users";
import SupplyTransferActionLogList from "issara-sdk/apis/SupplyTransferActionLogList_apps_MSD"
import * as MedRequestReturn from"./sequence/MedRequestReturn";

export type State = {} 
& OrderSupply.State 
& SupplyDelivery.State
& RefillDivisionPrint.State
& ReturnSupply.State
& MedRequestReturn.State;

export const StateInitial: State = {
  ...OrderSupply.StateInitial,
  ...SupplyDelivery.StateInitial,
  ...RefillDivisionPrint.StateInitial,
  ...ReturnSupply.StateInitial,
  ...MedRequestReturn.StateInitial
};

export type Event =
  | { message: "RunSequence"; params: any }
  | { message: "checkManufacturer"; params: any }
  | { message: "HandleSelectDispensingList"; params: any}
  | { message: "checkUserTokenize"; params: any } 
  | { message: "getSupplyDeliveryAction"; params: any }
  | OrderSupply.Event
  | ReturnSupply.Event
  | MedRequestReturn.Event

export type Data = {
  division?: number;
} & OrderSupply.Data;

export const DataInitial = {
  ...OrderSupply.DataInitial
};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const getSupplyDeliveryAction: Handler = async (
  controller, params
) => {
  let state = controller.getState();
  const [response, error, network] = await SupplyTransferActionLogList.list({
    pk: params.id,
    params: {},
    apiToken: controller.apiToken,
  });
  
  if (!error && response) {
    controller.setState({
      SupplyDeliverySequence: {
        ...state.SupplyDeliverySequence,
        supplyDeliveryActionLog: response.items
      },
    });
  } else {
    // let mockData = {"total":4,"next":null,"previous":null,"items":[{"id":158413,"code":1554,"name":"นาย เจ้าหน้าที่ เวชภัณฑ์","action":"PRINT","datetime":"2022-06-07T10:19:43.747538","note":"","statable":43560,"user":1554,"division":295},{"id":158415,"code":1554,"name":"นาย เจ้าหน้าที่ เวชภัณฑ์","action":"CHECK","datetime":"2022-06-13T17:45:39.845391","note":"","statable":43560,"user":1554,"division":295},{"id":158430,"code":6707,"name":"powerall","action":"DELIVER","datetime":"2022-07-21T15:43:57.233220","note":"","statable":43560,"user":6707,"division":295},{"id":158431,"code":5,"name":"พยาบาล วิชาชีพ","action":"TRANSPORT","datetime":"2022-07-21T15:43:58.004338","note":"","statable":43560,"user":5,"division":295}]}
    let mockData = {"total":0, "next":null, "previous":null, "items": []}
    controller.setState({
      SupplyDeliverySequence: {
        ...state.SupplyDeliverySequence,
        supplyDeliveryActionLog: mockData.items
      },
    });
  }
}

export const checkUserTokenize: Handler = async (
  controller, params?: any
) => {
  let state = controller.getState();
  console.log("checkUserTokenize", params.code)

  const [response, error, network] = await UserTokenizeView.post({
    data: { code: params.code },
    apiToken: controller.apiToken,
  });
  
  if (!error && response?.token) {
    let encodedName = response?.token.split('.')[1]
    var fullName = decodeURIComponent(atob(encodedName))

    controller.setState({
      SupplyDeliverySequence: {
        ...state.SupplyDeliverySequence,
        coUser: fullName,
        coUserToken: response?.token
      },
    });
    return {coUserToken: response?.token, coUser: fullName}
  } else {
    controller.setState({
      SupplyDeliverySequence: {
        ...state.SupplyDeliverySequence,
        errorMessage: { ...state.errorMessage, ["SupplyDelivery"]: error },
        coUser: "",
        coUserToken: ""
      },
    });
  }
}

export const checkManufacturer: Handler = async (
  controller,
  { name_icontains }
) => {
  let state = controller.getState();

  let params: any = {};
  if (name_icontains) {
    params.name_icontains = name_icontains;
  }

  controller.setState({
    OrderSupplySequence: {
      ...state.OrderSupplySequence,
      manufactureLoading: true,
      manufactureHaveResult: false,
    },
  });

  const [response, error, network] = await ManufacturerList.list({
    params: params,
    apiToken: controller.apiToken,
  });
  state = controller.getState();
  if (!error && response?.items?.length > 0) {
    controller.setState({
      OrderSupplySequence: {
        ...state.OrderSupplySequence,
        manufacturerOptions: response?.items ? response?.items : [],
        manufactureLoading: false,
        manufactureHaveResult: false,
      },
    });
    return 
  } 

  controller.setState({
    OrderSupplySequence: {
      ...state.OrderSupplySequence,
      manufactureLoading: false,
      manufacturerOptions: [],
      manufactureHaveResult: true,
    },
  });

  // controller.setState({ OrderSupplySequence: {
  //   ...state.OrderSupplySequence,
  //   manufacturerOptions: (response ? response : []),
  //   manufactureLoading: false
  // } })
};

