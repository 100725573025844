import React, {
  CSSProperties,
  MutableRefObject,
  useState,
  useRef,
  ChangeEvent,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import {
  Tab,
  TabProps,
  Button,
  Icon,
  Dropdown,
  Input,
} from "semantic-ui-react";

import moment from "moment";

// Framework
import { Table } from "react-lib/frameworks/Table";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Types
type CardUploadPatientCoverageProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // CommonInterface
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  // data
  patientCoverageImportData?: any;
};

// Const
const TabPanStyle = {
  marginTop: "-1rem",
  border: "none",
  boxShadow: "none",
} as CSSProperties;

const OBJECT_KEY_VALUES = {
  datetime: "ประทับเวลา",
  full_name: "ชื่อ-นามสกุล",
  code: "รหัสพนักงาน/ข้าราชการ",
  cid: "บัตรประชาชน",
  division: "หน่วยงาน/สังกัด",
  father_name: "ชื่อบิดา",
  father_cid: "เลขบัตรประชาชน (บิดา)",
  mother_name: "ชื่อมารดา",
  mother_cid: "เลขบัตรประชาชน (มารดา)",
  spouse_full_name: "คู่สมรส : ชื่อ - นามสกุล",
  spouse_cid: "คู่สมรส : หมายเลขบัตรประชำตัวประชาชน",
  first_child_full_name: "บุตรคนที่ 1 : ชื่อ - นามสกุล (อายุไม่เกิน 20 ปี)",
  first_child_cid: "บุตรคนที่ 1 : หมายเลขบัตรประชำตัวประชาชน",
  second_child_full_name: "บุตรคนที่ 2 : ชื่อ - นามสกุล (อายุไม่เกิน 20 ปี)",
  second_child_cid: "บุตรคนที่ 2 : หมายเลขบัตรประชำตัวประชาชน",
  third_child_full_name: "บุตรคนที่ 3 : ชื่อ - นามสกุล (อายุไม่เกิน 20 ปี)",
  third_child_cid: "บุตรคนที่ 3 : หมายเลขบัตรประชำตัวประชาชน",
  fourth_child_full_name: "บุตรคนที่ 4 : หมายเลขบัตรประชำตัวประชาชน",
  fourth_child_cid: "บุตรคนที่ 4 : ชื่อ - นามสกุล (อายุไม่เกิน 20 ปี)",
  house_register:
    "แนบทะเบียนบ้าน กรุณาตั้งชื่อไฟล์เป็นชื่อของท่าน (ต้อง Login ด้วย email @chula.ac.th หรือ @gmail ก่อนแนบไฟล์)",
  first_child_house_register:
    "บุตรคนที่ 1 : แนบทะเบียนบ้านหรือเอกสารใบรับรองบุตรบุญธรรม",
  first_child_child_certificate: "บุตรคนที่ 1 : แนบใบรับรองบุตร",
  second_child_house_register:
    "บุตรคนที่ 2 : แนบทะเบียนบ้านหรือเอกสารใบรับรองบุตรบุญธรรม",
  second_child_child_certificate: "บุตรคนที่ 2 : แนบใบรับรองบุตร",
  third_child_house_register:
    "บุตรคนที่ 3 : แนบทะเบียนบ้านหรือเอกสารใบรับรองบุตรบุญธรรม",
  third_child_child_certificate: "บุตรคนที่ 3 : แนบใบรับรองบุตร",
  fourth_child_house_register:
    "บุตรคนที่ 4 : แนบทะเบียนบ้านหรือเอกสารใบรับรองบุตรบุญธรรม",
  fourth_child_child_certificate: "บุตรคนที่ 4 : แนบใบรับรองบุตร",
  email_address: "ที่อยู่อีเมล",
};

const currentYear = Number(moment().format("YYYY")) + 543;

const CARD_UPLOAD_PATIENT_COVERAGE = "CardUploadPatientCoverage";

const CardUploadPatientCoverage = (props: CardUploadPatientCoverageProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(2);
  // file
  const [coverageFile, setCoverageFile] = useState<File | null>(null);
  // data
  const [items, setItems] = useState<any[]>([]);
  const [fiscalYear, setFiscalYear] = useState<number>(currentYear);
  const [maxReimb, setMaxReimb] = useState<string>("");

  // Ref
  const fileRef = useRef() as MutableRefObject<HTMLInputElement>;

  // Callback Effect
  const handleClear = useCallback(() => {
    setItems([]);
    setFiscalYear(currentYear);
    setMaxReimb("");
    setCoverageFile(null);
  }, []);

  useEffect(() => {
    return () => {
      handleClear();

      props.setProp("patientCoverageImportData", {});
    };
  }, []);

  // Use Memo
  const yearOptions = useMemo(() => {
    const years = Array(111)
      .fill("")
      .map((_, index) => currentYear - 100 + index);

    return years.map((year) => ({ key: year, value: year, text: year }));
  }, []);

  const handleTabChange = (_e: any, data: TabProps) => {
    setActiveIndex(Number(data.activeIndex));
  };

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0] || null;

    setCoverageFile(file);
    setItems([]);

    if (!file) {
      return console.log("errorMessage", "Please select a file.");
    }

    let fileReader = new FileReader();
    fileReader.readAsBinaryString(file);

    fileReader.onload = async (event) => {
      if (!event.target) {
        return;
      }

      const XLSX = await import("xlsx");

      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const rowObject: any[] = (XLSX.utils as any).sheet_to_row_object_array(
        workbook.Sheets[workbook.SheetNames[0]]
      );

      if (!rowObject.length) {
        return console.log(
          "errorMessage",
          "Please check the correctness file."
        );
      }

      const items = rowObject
        .map((item) =>
          Object.assign(
            {},
            ...Object.entries(OBJECT_KEY_VALUES).map(([key, value]) => ({
              [value]: item[value] || "",
            }))
          )
        )
        .filter((item) => Object.values(item).some((value) => value));

      setItems(items);
    };

    fileRef.current.value = "";
  };

  const handleChooseFile = () => {
    fileRef.current.click();
  };

  const handleClearFile = () => {
    setCoverageFile(null);
    setItems([]);
  };

  const handleChangeFiscalYear = (_e: any, data: any) => {
    setFiscalYear(data.value);
  };

  const handleChangeLimit = (_e: any, data: any) => {
    setMaxReimb(Number(data.value) < 0 ? 0 : data.value);
  };

  const handleUpload = (_e: any, data: any) => {
    props.onEvent({
      message: "HandleUploadPatientCoverage",
      params: {
        maxReimb,
        fiscalYear,
        file: coverageFile,
        card: CARD_UPLOAD_PATIENT_COVERAGE,
        btnAction: data.name,
        onSuccess: () => {
          handleClear();
        },
      },
    });
  };

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_UPLOAD_PATIENT_COVERAGE}`, null);
        }}
        error={props.errorMessage?.[CARD_UPLOAD_PATIENT_COVERAGE]}
        success={null}
      />

      <Tab
        activeIndex={activeIndex}
        menu={{ secondary: true, pointing: true }}
        // callback
        onTabChange={handleTabChange}
        // Element
        panes={[
          {
            menuItem: "Success",
            render: () => (
              <Tab.Pane attached={false} style={TabPanStyle}>
                <TableCustom
                  data={props.patientCoverageImportData?.items_success || []}
                  style={{ height: "calc(100vh - 8.5rem)" }}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: "Fail",
            render: () => (
              <Tab.Pane attached={false} style={TabPanStyle}>
                <TableCustom
                  data={props.patientCoverageImportData?.items_failed || []}
                  style={{ height: "calc(100vh - 8.5rem)" }}
                  // config
                  displayError={true}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: "Upload",
            render: () => (
              <Tab.Pane attached={false} style={TabPanStyle}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Button onClick={handleChooseFile}>Choose file</Button>
                    <input
                      ref={fileRef}
                      type="file"
                      accept=".xlsx"
                      onChange={handleChangeFile}
                      hidden
                    />

                    <label style={{ marginLeft: "1rem" }}>
                      {coverageFile?.name || "No File chosen"}
                    </label>

                    {coverageFile?.name && (
                      <Icon
                        name="close"
                        style={{ cursor: "pointer", marginLeft: "0.5rem" }}
                        onClick={handleClearFile}
                      />
                    )}

                    <label style={{ margin: "0 2rem 0 4rem" }}>
                      <strong>ปีงบประมาณ</strong>
                    </label>
                    <Dropdown
                      value={fiscalYear}
                      options={yearOptions}
                      selection={true}
                      onChange={handleChangeFiscalYear}
                    />
                    <label style={{ margin: "0 3rem" }}>
                      <strong>วงเงิน</strong>
                    </label>
                    <Input
                      value={maxReimb}
                      type="number"
                      onChange={handleChangeLimit}
                    />
                  </div>
                  <div>
                    <ButtonLoadCheck
                      // function
                      setProp={props.setProp}
                      onClick={handleUpload}
                      // data
                      paramKey={`${CARD_UPLOAD_PATIENT_COVERAGE}_SAVE`}
                      buttonLoadCheck={
                        props.buttonLoadCheck?.[
                          `${CARD_UPLOAD_PATIENT_COVERAGE}_SAVE`
                        ]
                      }
                      // config
                      disabled={!coverageFile}
                      color={"green"}
                      name="SAVE"
                      size="medium"
                      title={
                        <>
                          <label style={{ marginRight: "0.5rem" }}>
                            Upload
                          </label>{" "}
                          <Icon name="upload" />
                        </>
                      }
                    />
                  </div>
                </div>

                <TableCustom
                  data={items}
                  style={{ marginTop: "1rem", height: "calc(100vh - 12rem)" }}
                />
              </Tab.Pane>
            ),
          },
        ]}
      />
    </div>
  );
};

/* ------------------------------------------------------ */
/*                      TableCustom;                      */
/* ------------------------------------------------------ */
// Types
type TableCustomProps = {
  data: any[];
  style?: CSSProperties;
  // config
  displayError?: boolean;
};

const TableCustom = (props: TableCustomProps) => {
  // UseMemo
  const tableHeaders = useMemo(() => {
    const headers = Object.values(OBJECT_KEY_VALUES).map((value) =>
      value.length > 28 ? `${value.substring(0, 28)}...` : value
    );

    if (props.displayError) {
      headers.unshift("Error");
    }

    return headers.join(",");
  }, [props.displayError]);

  const tableKeys = useMemo(() => {
    const keys = Object.values(OBJECT_KEY_VALUES);

    if (props.displayError) {
      keys.unshift("errors");
    }

    return keys.join(",");
  }, [props.displayError]);

  return (
    <Table
      headers={tableHeaders}
      keys={tableKeys}
      widths={props.displayError ? "200" : ""}
      data={props.data || []}
      showPagination={false}
      style={{
        width: "calc(100vw - 180px)",
        ...(props.style || {}),
      }}
    />
  );
};

export default React.memo(CardUploadPatientCoverage);
