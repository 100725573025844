import WasmController from 'react-lib/frameworks/WasmController';
import * as PlantransferI from "./sequence/PlanTransfer";
import moment from "moment"

// APIS
import IPDContinueDayByEMR from "issara-sdk/apis/IPDContinueDayByEMR_apps_DPI"
import IPDContinueHistory from "issara-sdk/apis/IPDContinueHistory_apps_DPI"
import IPDContinueHistorySerializer_apps_DPI from "issara-sdk/types/IPDContinueHistorySerializer_apps_DPI"
import UserPermissionView from "issara-sdk/apis/UserPermissionView_users"
import PlanItemDetail from "issara-sdk/apis/PlanItemDetail_core"
import IPDOneDayByEMR from "issara-sdk/apis/IPDOneDayByEMR_apps_DPI"
import IPDOneDayByEMRSerializer from "issara-sdk/types/IPDOneDayByEMRSerializer_apps_DPI"
import DoctorOrderCancel from "issara-sdk/apis/DoctorOrderCancel_apps_DPO"
import ProgressionNoteList from "issara-sdk/apis/ProgressionNoteList_apps_DPI"

// Utils
import { dateToStringWithoutTimeBE } from "react-lib/utils/dateUtils"

export type State =
  {
    // Prerequisite
    selectedEncounter?: any | null,
    selectedEmr?: any | null,
    orderSummary?: {
      emr?: number;
      items: any[];
    },
    orderSummaryOneDay?: IPDOneDayByEMRSerializer;
    filterOrderSummary?: {
      date: moment.Moment;
      nurseStatus: string | null;
      isNurseStatus: boolean;
      isOutPerformDivision: boolean;
      isPharmacy: boolean;
      isTreatmentOrder: boolean;
      isLab: boolean;
      isFood: boolean;
      isNoteOrder: boolean;
      isProcedure: boolean;
      isHD: boolean;
    },
    continueHistoryList?: IPDContinueHistorySerializer_apps_DPI | null;
    orderSummaryPermission?: {
      config_BLB_BLOOD_ORDER_DETAIL: string;
      role_DOCTOR: boolean;
    },
    openModSuccessOrderSummary?: {
      open: boolean;
      message: any;
    };
    progressionNoteList?: any[]
  }
  & PlantransferI.State

export const StateInitial: State =
{
  orderSummary: {
    items: []
  },
  filterOrderSummary: {
    date: moment(),
    nurseStatus: null,
    isNurseStatus: false,
    isOutPerformDivision: true,
    isPharmacy: true,
    isTreatmentOrder: true,
    isLab: true,
    isFood: true,
    isNoteOrder: true,
    isProcedure: true,
    isHD: true
  },
  continueHistoryList: null,
  openModSuccessOrderSummary: {
    open: false,
    message: null
  },
  ...PlantransferI.StateInitial
}

export type Event =
  // GET
  { message: "GetIPDOrderSummary", params: any }
  | { message: "GetIPDContinueHistory", params: { id: number } }
  | { message: "GetUserPermissionOrderSummary", params: { id: number } }
  | { message: "GetListProgressionNote", params: { emrId: number } }
  // UPDATE
  | { message: "HandleUpdatePlanItem", params: { action: string; allowed_actions: string[] } }
  | { message: "HandleSetContinueHistoryList", params: { data: any } }
  | { message: "HandleSetOpenModSuccessOrderSummary", params: { open: boolean } }
  | { message: "HandleSetFilterOrderSummary", params: { key: string; value: string | boolean } }
  | { message: "UpdateDoctorOrderCancel", params: { id: number; note: string; } }
  | PlantransferI.Event

export type Data =
  {
    division?: number,
  }

export const DataInitial =
{
}

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

/* ------------------------- GET ------------------------ */
export const GetIPDOrderSummary: Handler = async (controller, params) => {
  const state = controller.getState();

  const filter = state.filterOrderSummary
  const apiParams = {
    date: dateToStringWithoutTimeBE(filter?.date),
    flag_treatment_order: filter?.isTreatmentOrder,
    flag_note_order: filter?.isNoteOrder,
    flag_out_perform_div: filter?.isOutPerformDivision,
    flag_pharmacy: filter?.isPharmacy,
    flag_lab: filter?.isLab,
    flag_food: filter?.isFood,
    flag_or: filter?.isProcedure,
    flag_hd: filter?.isHD,
    nurse_status: filter?.isNurseStatus ? filter.nurseStatus : ""
  }

  await controller.handleEvent({
    message: "GetMasterData" as any,
    params: {
      masters: [
        ["unit", {}],
        ["route", {}],
        ["site", {}],
        ["frequency", {}],
        ["method", {}],
      ],
    },
  });

  GetIPDOrderSummaryContinue(controller, { emrId: state.selectedEmr?.id, ...apiParams })
  GetIPDOrderSummaryOneDay(controller, { emrId: state.selectedEmr?.id, ...apiParams })

}

export const GetIPDOrderSummaryContinue: Handler = async (controller, params) => {

  const orderSummary = await IPDContinueDayByEMR.retrieve({
    pk: params.emrId,
    params: {
      date: params.date,
      flag_treatment_order: params.flag_treatment_order,
      flag_note_order: params.flag_note_order,
      flag_out_perform_div: params.flag_out_perform_div,
      flag_pharmacy: params.flag_pharmacy,
      flag_lab: params.flag_lab,
      flag_food: params.flag_food,
      flag_or: params.flag_or,
      flag_hd: params.flag_hd,
      nurse_status: params.nurse_status
    },
    apiToken: controller.apiToken
  });
  console.log(orderSummary[1] ? orderSummary[1] : orderSummary[0]);
  controller.setState({
    orderSummary: orderSummary[0]
  });
}

export const GetIPDOrderSummaryOneDay: Handler = async (controller, params) => {
  const orderSummary = await IPDOneDayByEMR.retrieve({
    pk: params.emrId,
    params: {
      date: params.date,
      flag_treatment_order: params.flag_treatment_order,
      flag_note_order: params.flag_note_order,
      flag_out_perform_div: params.flag_out_perform_div,
      flag_pharmacy: params.flag_pharmacy,
      flag_lab: params.flag_lab,
      flag_food: params.flag_food,
      flag_or: params.flag_or,
      flag_hd: params.flag_hd,
      nurse_status: params.nurse_status
    },
    apiToken: controller.apiToken
  });
  console.log(orderSummary[1] ? orderSummary[1] : orderSummary[0]);
  controller.setState({
    orderSummaryOneDay: orderSummary[0]
  });
}

export const GetUserPermissionOrderSummary: Handler = async (controller, params) => {
  const permission = await UserPermissionView.post({
    apiToken: controller.apiToken,
    data: {
      config_BLB_BLOOD_ORDER_DETAIL: "",
      role_DOCTOR: false,
    }
  })

  controller.setState({
    orderSummaryPermission: permission[0] || {}
  })
}

export const GetIPDContinueHistory: Handler = async (controller, params) => {
  const continueHistory = await IPDContinueHistory.retrieve({
    pk: params.id,
    apiToken: controller.apiToken
  })

  controller.setState({
    continueHistoryList: continueHistory[0]
  })
}

/* ----------------------- UPDATE ----------------------- */
export const HandleUpdatePlanItem: Handler = async (controller, params) => {
  const response = await PlanItemDetail.update({
    pk: params.id,
    apiToken: controller.apiToken,
    data: {
      action: params.action,
      allowed_actions: params.allowed_actions,
      note: "",
      user_token: "",
      end_time: params.end_time,
      end_date: params.end_date
    }
  })

  if (!response[1]) {
    HandleSetOpenModSuccessOrderSummary(controller, { open: true })
  }

  GetIPDOrderSummary(controller, params)
}

const PutDoctorOrderCancel: Handler = async (controller, params) => {
  const response = await DoctorOrderCancel.put({
    pk: params.id,
    data: {
      action: "CANCEL",
      note: params.note
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  })

  return response
}

export const UpdateDoctorOrderCancel: Handler = async (controller, params) => {
  const response = await PutDoctorOrderCancel(controller, params)

  if (!response[1]) {
    HandleSetOpenModSuccessOrderSummary(controller, { open: true, message: "ยกเลิกสำเร็จ" })
  }

  GetIPDOrderSummary(controller, params)
}

/* ------------------------- SET ------------------------ */
export const HandleSetContinueHistoryList: Handler = (controller, params) => {
  controller.setState({
    continueHistoryList: params.data
  })
}

export const HandleSetOpenModSuccessOrderSummary: Handler = (controller, params) => {
  controller.setState({ openModSuccessOrderSummary: params })
}

export const HandleSetFilterOrderSummary: Handler = (controller, params) => {
  const state = controller.getState()

  controller.setState({
    filterOrderSummary: {
      ...state.filterOrderSummary as any,
      [params.key]: params.value
    }
  }, () => {
    GetIPDOrderSummary(controller, {})
  })
}

export const GetListProgressionNote: Handler = async (controller, params) => {
  const result = await ProgressionNoteList.list({
    apiToken: controller.apiToken,
    params: {
      emr: params.emrId,
      sort: "menu",
      offset: 0,
      limit: 40,
    }
  })

  controller.setState({ progressionNoteList: result[0]?.items || [] })
}