import React, { useEffect, useMemo, useState } from "react";
import CardDrugOrderListUX from "react-lib/apps/HISV3/TPD/CardDrugOrderListUX";
import CardAuditDrugOrderUX from "react-lib/apps/HISV3/TPD/CardAuditDrugOrderUX";

const CardDrugOrderList = (props: any) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [prescription, setPrescription] = useState<any[]>([]);
  useEffect(() => {
    props.runSequence({ sequence: "DrugOrderList", restart: true });
    return () => {
      props.runSequence({ sequence: "DrugOrderList", clear: true });
    };
  }, []);

  const getTrPropsDrugOrderList = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      onClick: (event: any) => {
        let rowId = rowInfo?.original?.id;
        console.log("getTrPropsDrugOrderList rowId: ", rowId);
        setSelectedRow(rowId);
      },

      className: rowInfo?.original?.id === selectedRow ? "blueSelectedRow" : "",
      style: { cursor: "pointer" },
    };
  };

  useEffect(() => {
    // Call API
    if (selectedRow) {
      props.runSequence({
        sequence: "DrugOrderList",
        action: "selectDrugId",
        rowId: selectedRow,
      });
    }
  }, [selectedRow]);

  useEffect(() => {
    if (
      Array.isArray(props.DrugOrderListSequence?.drugOrders) ||
      props.DrugOrderListSequence?.drugOrders?.length > 0
    ) {
      // setPrescription
      let allPrescriptionCode: any[] = (
        props.DrugOrderListSequence?.drugOrders || []
      )?.map((item: any) => item.prescription_code);
      let uniquePrescriptionCode = Array.from(new Set(allPrescriptionCode));
      uniquePrescriptionCode = uniquePrescriptionCode.map((item: any) => {
        return {
          prescription_code: item,
          items: props.DrugOrderListSequence?.drugOrders?.filter(
            (i: any) => i.prescription_code === item
          ),
        };
      });

      setPrescription(uniquePrescriptionCode);
    }
  }, [props.DrugOrderListSequence?.drugOrders]);

  return (
    <>
      <CardDrugOrderListUX
        data={prescription}
        getTrProps={getTrPropsDrugOrderList}
      />
      <CardAuditDrugOrderUX
        data={props.DrugOrderListSequence?.drugs?.items || []}
      />
    </>
  );
};

export default CardDrugOrderList;
