import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  useCallback,
  CSSProperties,
  MutableRefObject,
  useMemo,
  lazy,
  Suspense,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Fingerprint2 from "fingerprintjs2";

import {
  Form,
  Button,
  Modal,
  Icon,
  Loader,
  TextArea,
  Image,
} from "semantic-ui-react";

import FriendMessage from "react-lib/apps/IsHealth/Chat/FriendMessage";
import MyMessage from "react-lib/apps/IsHealth/Chat/MyMessage";
import CardClassify from "../IsHealth/Nurse/CardClassify";

import * as CONSTANT from "react-lib/utils/constant";
import config from "config/config";
import usePushNotifications from "react-lib/apps/IsHealth/Common/usePushNotifications";
import { useStateCallback } from "react-lib/utils/hooksUtils";
import _ from "react-lib/compat/lodashplus";

var globPostMessage: any = {};

const CardDiagFormHistory = lazy(
  () => import("../IsHealth/Nurse/CardDiagFormHistory")
);

type ChatBoxProps = {
  // controller
  controller?: any;
  classifyController?: any;
  diagFormController?: any;
  // Data
  location?: any;
  enabledChatNoEncounter?: boolean;
  data?: any[];
  userId: string;
  division_id: number;
  division?: number;
  useNormalTextAreaKey?: boolean;
  placeholder?: string;
  apiToken: string;
  selectedPatient?: any;
  fullname: string;
  username: string;
  nullId: boolean;
  patientData: any;
  patientId?: number;
  // Search
  searchText?: string;
  searchMessage?: {
    id: number;
    offset: number;
    index: number;
  };

  isPatient: boolean;
  match: any;
  acceptContentHTML?: null | boolean;
  avatarBotUrlString?: string;
  encounterId?: number;

  // Callback
  noApiToken?: Function;
  enHasNoDiv: Function;
  enHasDiv: Function;
  onSendMessage?: Function;
  onSetChannelName: Function;
  onBackToApp?: Function;
  onSetBackToApp?: Function;
  onCallGetLastMessage?: Function;
  onDidMount?: Function;
  onNavigationMessage: ({ content = "" }) => any;
  onOpenVideoCall?: (url: string) => any;
  onCloseVideoCall?: any;
  onPostMessage?: Function;
  onClickLink?: (params: any) => any;

  // Config
  readOnly: boolean;
  hideButtonMenu?: boolean;
  messageViewStyle?: CSSProperties;
  chatBotLogic?: any;
  disabledScrollIntoView?: boolean;
  hideCallButton?: boolean;
  isStaff?: boolean;
};

const ChatBox = React.forwardRef((props: ChatBoxProps, ref): JSX.Element => {
  const [prevData, setPrevData] = useState<any[]>([]); // use for scroll
  const [textMessage, setTextMessage] = useState<string>("");
  const [message, setMessage] = useState<any[]>([]); // set props.data as message;
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [prevUrl, setPrevUrl] = useState<any>(null);
  const [nextUrl, setNextUrl] = useState<any>(null);
  const [postMessage, setPostMessage] = useStateCallback({});
  const [openVideoCallModal, setOpenVideoCallModal] = useState(false);
  const [videoCallID, setVideoCallID] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [patientId, setPatientId] = useState(null);
  const [patientHn, setPatientHn] = useState("-");
  const [readOnly, setReadOnly] = useState(false);
  const [allMessageDict, setAllMessageDict] = useState<
    Record<string, { id: number; element: Element | null; text: string }>
  >({});
  const [prevSearchMessage, setPrevSearchMessage] = useState<{
    id?: number;
    offset?: number;
    index?: number;
  }>({});
  const [prevSearchText, setPrevSearchText] = useState<string>("");

  // Channel detail
  const [channelDetail, setChannelDetail] = useState<any>({});
  const [iframeHeight, setIframeHeight] = useState(480);
  const [iframeWidth, setIframeWidth] = useState(640);
  const [getMessageCancel, setGetMessageCancel] = useState<any>(null);
  const [query, setQuery] = useState<any>(null);
  const [readMessageFlag, setReadMessageFlag] = useState<any>({});
  const [openImageModal, setOpenImageModal] = useState(false);
  const [image, setImage] = useState<string | boolean>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInCall, setIsInCall] = useState(false);
  const [lastCallContent, setLastCallContent] = useState("");
  const [isLoadingBottom, setIsLoadingBottom] = useState<boolean>(false);
  const [messageCount, setMessageCount] = useState<number>(0);
  const [messageCurrentLimit, setMessageCurrentLimit] = useState<number>(0);
  const [openClassify, setOpenClassify] = useState<boolean>(false);
  const [openModDiagHis, setOpenModDiagHis] = useState<boolean>(false);
  const {
    userConsent,
    pushNotificationSupported,
    userSubscription,
    onClickAskUserPermission,
    onClickSusbribeToPushNotification,
    onClickSendSubscriptionToPushServer,
    pushServerSubscriptionId,
    onClickSendNotification,
    getSubscriptionObject,
  } = usePushNotifications();

  const location = useLocation();

  const messageEmptyRef = useRef() as MutableRefObject<HTMLInputElement>;
  const messageViewRef = useRef() as MutableRefObject<HTMLDivElement>;
  const messageRef = useRef<(HTMLDivElement | null)[]>([]);
  const messageDictRef = useRef<
    Record<string, { element: HTMLDivElement | null; activity: boolean }>
  >({});
  const imageRef = useRef() as MutableRefObject<HTMLInputElement>;
  const fileRef = useRef() as MutableRefObject<HTMLInputElement>;
  const emptyRef = useRef() as MutableRefObject<HTMLDivElement>;
  const scrollRef = useRef<boolean>(false);
  const isMounted = useRef<boolean>(true);
  const history = useHistory();
  const scrollTopRef = useRef<number>(0);
  const scrollLeftRef = useRef<number>(0);
  const dontScrollRef = useRef<boolean>(false);
  const laxyLoadRef = useRef<"next" | "prev" | "">("");
  const isLoadingRef = useRef<boolean>(false);
  const isLoadingFocusRef = useRef<boolean>(false);

  useImperativeHandle(ref, () => ({
    receivedMessage: ({ chatChannelId }: any) => {
      // console.log("Chatbox receivedMessage", { chatChannelId });
      console.log(
        "src/react-lib/apps/MSG Chatbox receivedMessage",
        chatChannelId
      );
      handleReceivedMessage({ chatChannelId });
    },
    receivedMessageRead: ({ chatChannelId, messageId }: any = {}) => {
      console.log("Chatbox receivedMessageRead", chatChannelId);
      setReadMessageFlag({ chatChannelId, messageId });
      // handleReceivedMessageRead({ chatChannelId, messageId });
    },
    getLastMessage: ({ chatChannelId }: any = {}) =>
      getLastMessage({ chatChannelId }),
    getChannelDetail: ({} = {}) => {
      return channelDetail;
    },
    callGetChannelDetail: () => {
      console.log("callGetChannelDetail called  getChannelDetail");
      const channel = getChannelDetail().then((res) => {
        return res;
      });
      return channel;
    },
    receivedReclassify: ({
      encounterId,
      filterValue,
      chatChannelId,
    }: any = {}) => {
      console.log("receivedReclassify chatChannelId: ", chatChannelId);
      if (
        props.match &&
        props.match.params &&
        (props.match.params.encounterId || props.match.params.chatChannelId)
      ) {
        let enId = props.match.params.encounterId;
        let chId = props.match.params.chatChannelId;
        if (
          parseInt(encounterId) === parseInt(enId) ||
          parseInt(chatChannelId) === parseInt(chId)
        ) {
          checkReadOnly({ filterValue });
          handleReceivedMessage({ chatChannelId });
        }
      }
    },
    checkReadOnly: ({ filterValue }: any = {}) => {
      checkReadOnly({ filterValue });
    },
    resizeMessageView: resizeMessageView,
    laxyLoad: () => laxyLoadRef.current,
    chatChannelId: props.match.params.chatChannelId,
  }));

  useEffect(() => {
    if (window.requestIdleCallback as any) {
      requestIdleCallback(function () {
        console.log("get fingerprint on requestIdleCallback");
        getFingerPrint();
      });
    } else {
      console.log("fallback with timeout");
      setTimeout(function () {
        getFingerPrint();
      }, 500);
    }

    return () => {
      isMounted.current = false;
      props.onSetChannelName?.({ name: "" });
    };
  }, []);

  useEffect(() => {
    checkBackToApp();
  }, [window.iosNative, window.MobNative]);

  useEffect(() => {
    console.log("!!! Chatbox readMessageFlag", readMessageFlag);
    if (readMessageFlag.chatChannelId && readMessageFlag.messageId) {
      handleReceivedMessageRead({
        chatChannelId: readMessageFlag.chatChannelId,
        messageId: readMessageFlag.messageId,
      });
    }
  }, [readMessageFlag.chatChannelId, readMessageFlag.messageId]);

  // const query = new URLSearchParams(useLocation().search);
  useEffect(() => {
    console.log(location.search, "useLocation().search");
    let qr = new URLSearchParams(location.search);
    setQuery(qr);
  }, [location.search]);

  useEffect(() => {
    console.log(" setReadOnly(props.readOnly)", props.readOnly);
    setReadOnly(props.readOnly);
  }, [props.readOnly]);

  useEffect(() => {
    console.log("ChatBox props.match : ", props.match);

    const getFirstMsg = async () => {
      if (props.chatBotLogic && props.selectedPatient?.chat_channel) {
        console.log("called chatBotLogic !!!! ");
        await props.chatBotLogic();
        getLastMessage();
      }
    };

    const getChannelDetailAndGetLastMessage = async () => {
      await getChannelDetail();
      await getLastMessage();

      console.log("props.selectedPatient: ", props.selectedPatient);
      if (props.selectedPatient?.chat_channel) {
        console.log("props.selectedPatient", props.selectedPatient);
        let msg = `${props.selectedPatient.first_name} ${props.selectedPatient.last_name} started to record data\nคุณ ${props.selectedPatient.first_name} ${props.selectedPatient.last_name} เริ่มต้นบันทึกข้อมูล`;
        sendMessage({ content: msg })
          .then(async () => {
            if (!!props.chatBotLogic) {
              await getFirstMsg();
            }
          })
          .catch(() => {
            console.error(" failed to send start message");
          });
      }
    };

    if (props.match?.params) {
      setMessage([]);
      setTextMessage("");
      if (props.match?.params?.chatChannelId) {
        console.log(
          " useEffect called getChannelDetail",
          props.match.params.chatChannelId,
          props.apiToken
        );
        getChannelDetailAndGetLastMessage();
      }
    }
    return (): any => cancelRequest();
  }, [
    props.match?.params?.chatChannelId,
    props.apiToken,
    props.selectedPatient?.chat_channel,
  ]);

  useEffect(() => {
    let vid_call = message
      .filter((item) => item.content_type === "vid_call")
      .pop();
    let end_call = message
      .filter((item) => item.content_type === "end_call")
      .pop();

    console.log("vid_call", vid_call);
    console.log("end_call", end_call);
    if (vid_call) {
      setIsInCall(true);
      setLastCallContent(vid_call.content);
    }
    if (vid_call && end_call) {
      if (end_call.id > vid_call.id) {
        setIsInCall(false);
        setLastCallContent("");
      }
    }

    if (!dontScrollRef.current && !props.disabledScrollIntoView) {
      if (prevData.length === 0 || message.length <= prevData.length) {
        scrollToBottom();
      } else {
        messageViewRef.current?.scrollTo(
          scrollLeftRef.current,
          messageViewRef.current.scrollHeight - scrollTopRef.current
        );
      }
    }
    setPrevData(message);
  }, [message]);

  useEffect(() => {
    const getMessage = async (type: "next" | "prev", url: string) => {
      if (
        !props.match &&
        !props.match.params &&
        !props.match.params.chatChannelId
      ) {
        laxyLoadRef.current = "";

        return;
      }
      if (!url) {
        return;
      }
      if (message.length === 0 || !message) {
        laxyLoadRef.current = "";

        return;
      }

      setOpenMenu(false);

      type === "prev" ? setIsLoading(true) : setIsLoadingBottom(true);

      if (typeof props.searchMessage?.id === "number" && props.searchText) {
        isLoadingRef.current = true;
      }

      const [response, error, network] =
        await props.controller.getMessageFromURL({
          url: url,
          apiToken: props.apiToken,
        });

      if (isMounted.current) {
        type === "prev" ? setIsLoading(false) : setIsLoadingBottom(false);

        if (response) {
          const uniqueArray = (array: any[]) =>
            Array.from(
              new Map(array.map((item) => [item["id"], item])).values()
            );

          type === "prev"
            ? setPrevUrl(response.previous)
            : setNextUrl(response.next);

          let newArr =
            type === "prev"
              ? [...response.items, ...message]
              : [...message, ...response.items];

          setMessage(uniqueArray(newArr));
        } else {
          setMessage([]);
        }
      }

      laxyLoadRef.current = "";
    };

    const msgRef = messageViewRef.current;
    msgRef.onscroll = () => {
      scrollRef.current = true;

      if (
        msgRef.scrollTop === 0 &&
        prevUrl &&
        !props.searchText &&
        isLoadingFocusRef.current
      ) {
        isLoadingFocusRef.current = false;
      }

      if (laxyLoadRef.current || isLoadingFocusRef.current) {
        return;
      }

      if (msgRef.scrollTop === 0 && !dontScrollRef.current && prevUrl) {
        laxyLoadRef.current = "prev";
        scrollTopRef.current = messageViewRef.current.scrollHeight;
        scrollLeftRef.current = messageViewRef.current.scrollLeft;

        setTimeout(() => getMessage("prev", prevUrl), 0);
      }

      const isBottom =
        msgRef.scrollTop + msgRef.offsetHeight + 15 >= msgRef.scrollHeight;

      if (isBottom && nextUrl) {
        laxyLoadRef.current = "next";
        scrollTopRef.current = 0;
        scrollLeftRef.current = messageViewRef.current.scrollLeft;

        setTimeout(() => getMessage("next", nextUrl), 0);
      }
    };
    return () => {
      scrollRef.current = false;
      msgRef.onscroll = null;
    };
  }, [message, prevUrl, nextUrl]);

  useEffect(() => {
    scrollToBottom({ behavior: "smooth" });
  }, [postMessage]);

  useEffect(() => {
    if (openMenu) {
      emptyRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [openMenu]);

  useEffect(() => {
    if (props.patientData.patient) {
      console.log(props);
      setPatientId(props.patientData.patient);
      if (props.location.search) {
        let urlParams = new URLSearchParams(props.location.search);
        let hn = urlParams.get("hn");
        if (hn !== null && hn !== "") {
          setPatientHn("hn_" + hn);
        } else {
          setPatientHn("id_" + props.patientData.patient);
        }
      }
    }
  }, [props.patientData.patient]);

  useEffect(() => {
    if (patientId && channelDetail.division) {
      checkReadOnly();
    }
  }, [patientId, channelDetail.division]);

  useEffect(() => {
    if (!query) {
      return;
    }
    if (query.get("vid_call") === "true") {
      // console.error("===============\nNot implemented\n===============");
      const url = query.get("content");
      handleOpenVideoCall({ url });
    } else {
      setOpenVideoCallModal(false);
    }
  }, [query]);

  let name = props.fullname ? props.fullname : props.fullname;

  useEffect(() => {
    if (!openImageModal) {
      setImage(false);
    }
  }, [openImageModal]);

  useEffect(() => {
    props.onDidMount?.();
  }, []);

  useEffect(() => {
    if (messageViewRef.current) {
      resizeMessageView();

      new ResizeObserver(resizeMessageView).observe(messageViewRef.current);
    }
  }, []);

  // Highligh text all match
  useEffect(() => {
    const search = (props.searchText || "").toLowerCase();

    setPrevSearchText(search);

    if (!search || search !== prevSearchText) {
      console.log("clear", search, prevSearchText);
      for (const key in allMessageDict) {
        selectMessage({ type: "clear", item: allMessageDict[key] });
      }
      return;
    }

    const searchMessage: any = {};

    for (const key in messageDictRef.current) {
      const item = messageDictRef.current[key];

      const elm = getBubbleChatElement(key);

      if (elm?.innerHTML?.toLowerCase()?.includes(search) && !item.activity) {
        const item = allMessageDict[key]
          ? allMessageDict[key]
          : {
              id: key,
              element: elm || null,
              text: elm?.innerHTML || "",
            };

        searchMessage[key] = item;
      }
    }

    for (const key in searchMessage) {
      selectMessage({ type: "active", item: searchMessage[key] });
    }

    setAllMessageDict(searchMessage);

    // console.log("allMessage", searchMessage, messageDictRef.current);
  }, [message, props.searchText]);

  // Change search index
  useEffect(() => {
    if (
      // !!Object.keys(allMessageDict)?.[0] &&
      // message.length &&
      typeof props.searchMessage?.id === "number" &&
      props.searchText
    ) {
      const item = allMessageDict[props.searchMessage?.id || ""];

      console.log(item, props.searchMessage?.id, prevSearchMessage.id);

      if (!item) {
        isLoadingFocusRef.current = true;

        if (props.searchMessage?.id !== prevSearchMessage.id) {
          getMessageListByOffset({
            offset: props.searchMessage.offset,
            index: props.searchMessage.index,
            prevIndex: prevSearchMessage.index || 0,
          });
        }
      }

      selectMessage({
        type: "active",
        item: allMessageDict[prevSearchMessage?.id || ""],
      });

      const focus = () => selectMessage({ type: "focus", item });

      if (typeof prevSearchMessage.id !== "number") {
        setTimeout(focus, 250);
      } else {
        focus();
      }

      setPrevSearchMessage(props.searchMessage);
    }
  }, [allMessageDict, props.searchMessage]);

  const chatKey = useMemo(() => {
    return props.isStaff ? "_STAFF_CHAT" : "_CHAT";
  }, [props.isStaff]);

  const selectMessage = (params: {
    type: "active" | "focus" | "clear";
    item: any;
  }) => {
    const cleanSearch = (props.searchText || "").replace(
      /(\.|\+|\*|\?|\^|\$|\(|\)|\[|\]|\{|\}|\||\\)/g,
      "\\$1"
    );

    const activeStyle = "background-color:#5DBCD2; color:white;";
    const focusStyle = "background-color:#F0F40E; color:#5DBCD2;";

    const reg = new RegExp(`(${cleanSearch})`, "gi");

    if (params.item?.element) {
      if (params.type === "focus") {
        if (!isLoadingRef.current) {
          params.item.element.scrollIntoView({
            behavior: "auto",
            block: "center",
          });

          isLoadingFocusRef.current = false;
        } else {
          isLoadingRef.current = false;
        }
      }

      const text = `${params.item.text}<div
        style="position: absolute; top: 0; left: 0; padding: 0.5rem; padding-left: 1rem; padding-right: 1rem; color: transparent; pointer-events: none; width: 100%"
      >${params.item.text.replace(
        reg,
        `<span className='highlight-${params.type}' style='${
          params.type === "focus" ? focusStyle : activeStyle
        } padding :1px 0 3px; margin-right: 0'>$1</span>`
      )}</div>`;

      params.item.element.innerHTML =
        params.type === "clear" ? params.item.text : text;
    }
  };

  const resizeMessageView = () => {
    const body = document.querySelector("body") as HTMLBodyElement;
    const textBox = document.querySelector(".textBox") as HTMLDivElement;
    const boxChoice = document.querySelector(
      ".ChatBoxChoice"
    ) as HTMLDivElement;

    const { top } = messageViewRef.current?.getBoundingClientRect() || {};
    const { bottom } = body.getBoundingClientRect();
    const height =
      bottom - top - textBox?.offsetHeight - boxChoice?.offsetHeight;

    if (messageViewRef.current?.style) {
      messageViewRef.current.style.height = `${height}px`;
    }
  };

  const getBubbleChatElement = (key: string) => {
    const item = messageDictRef.current[key];

    const className: string = item?.element?.firstElementChild?.className || "";
    const classMessage = className.match(/(?!= )\w+Message/g)?.[0] || "";

    const child = {
      MyMessage: "content",
      FriendMessage: "bubble",
    }[classMessage];

    return (
      child ? item?.element?.querySelector(`.${classMessage} .${child}`) : null
    ) as Element | null;
  };

  const checkBackToApp = async () => {
    if (typeof window.iosNative !== "undefined") {
      try {
        window.iosNative.consoleLog("backToApp");
        const backToApp = await window.iosNative.backToApp;
        window.iosNative.consoleLog(backToApp);
        if (backToApp === "true") {
          // ios can only send string back
          window.iosNative.consoleLog("have back to app");
          props.onSetBackToApp?.(true);
        }
      } catch (e) {
        console.error(e);
        // console.log(e.message);
      }
    } else if (typeof window.MobNative !== "undefined") {
      const backToApp = await window.MobNative.backToApp();
      if (backToApp) {
        props.onSetBackToApp?.(true);
      }
    }
  };

  const isWebRTCSupport = () => {
    if (window.navigator.userAgent.indexOf("Edge") > -1) {
      return false;
    }

    if (
      typeof navigator.mediaDevices === "object" &&
      typeof navigator.mediaDevices.getUserMedia !== "undefined"
    ) {
      return true;
    }

    return false;
  };

  const checkReadOnly = async ({ filterValue = "all" } = {}) => {
    setReadOnly(props.readOnly);
  };

  const handleReceivedMessageRead = async ({
    chatChannelId,
    messageId,
  }: any = {}) => {
    if (
      !props.match &&
      !props.match.params &&
      !props.match.params.chatChannelId
    ) {
      return;
    }
    if (!chatChannelId) {
      return;
    }

    if (
      parseInt(props.match.params.chatChannelId) === parseInt(chatChannelId)
    ) {
      let readMessage = message.findIndex((item) => item.id === messageId);
      if (readMessage > -1) {
        let newObj = {
          ...message[readMessage],
          user_read_count: message[readMessage].user_read_count + 1,
        };
        dontScrollRef.current = true;
        let newMessageState = [...message];
        newMessageState[readMessage] = newObj;
        scrollTopRef.current = messageViewRef.current.scrollTop;
        scrollLeftRef.current = messageViewRef.current.scrollLeft;
        setMessage(newMessageState);
        setReadMessageFlag({});
        setTimeout(() => {
          dontScrollRef.current = false;
          messageViewRef.current.scrollTo(
            scrollLeftRef.current,
            messageViewRef.current.scrollTop
          );
        }, 0);
      }
    }
  };

  const handleReceivedMessage = async ({ chatChannelId }: any = {}) => {
    if (
      !props.match &&
      !props.match.params &&
      !props.match.params.chatChannelId
    ) {
      return;
    }
    if (!chatChannelId) {
      return;
    }
    if (
      parseInt(props.match.params.chatChannelId) === parseInt(chatChannelId)
    ) {
      await getLastMessage();
    }
  };

  const getChannelDetail = async () => {
    if (
      !props.match &&
      !props.match.params &&
      !props.match.params.chatChannelId
    )
      return {};

    if (!props.apiToken) return;

    const [response, error, network] = await props.controller.getChannelDetail({
      chatChannelId: props.match?.params.chatChannelId,
      apiToken: props.apiToken,
    });

    if (isMounted.current) {
      if (response) {
        console.log(
          "getChannelDetail response ",
          response,
          config.IGNORE_CAN_CHAT
        );
        console.log(props.onSetChannelName);
        props.onSetChannelName?.({
          name: response.division_name || response.name,
          division: response.division,
        });
        // if (!config.IGNORE_CAN_CHAT && response.can_chat === false) {
        //   setReadOnly(true);
        // }
        setChannelDetail(response);
        return response;
      }
      return {};
    }
  };

  const cancelRequest = async () => {
    if (getMessageCancel) {
      getMessageCancel.cancel();
      setGetMessageCancel(null);
    }
  };

  const getLastMessage = async ({ chatChannelId = null } = {}) => {
    console.log("getLastMessage ");
    props.onCallGetLastMessage?.(
      props.match?.params?.chatChannelId,
      chatChannelId
    );
    // chatChannelId = props.match?.params?.chatChannelId || chatChannelId
    if (
      chatChannelId &&
      parseInt(props.match.params.chatChannelId) !== parseInt(chatChannelId)
    ) {
      return;
    }
    const source = axios.CancelToken.source();
    if (
      !props.match &&
      !props.match.params &&
      !props.match.params.chatChannelId
    ) {
      return;
    }
    if (!props.apiToken) {
      return;
    }
    const [response, error, network] =
      await props.controller.getChatChannelMessageList({
        chatChannelId: props.match?.params?.chatChannelId,
        last: true,
        apiToken: props.apiToken,
        cancelToken: source.token,
      });
    if (isMounted.current) {
      setGetMessageCancel(source);
      if (response) {
        setGetMessageCancel(null);
        setPrevUrl(response.previous);
        setNextUrl(response.next);
        const key = "id";

        const result = Array.from(
          new Map(response.items.map((item: any) => [item[key], item])).values()
        );

        setMessage(result);
        postReadMessage();
      } else {
        setMessage([]);
      }

      setMessageCount(response?.count || 0);
      setMessageCurrentLimit(response?.current_limit || 0);
    }
  };

  const getMessageListByOffset = async (data: {
    prevIndex: number;
    offset: number;
    index: number;
  }) => {
    console.log("getMessageListByOffset: ");
    let offset = data.offset;

    if (messageCount && messageCurrentLimit) {
      offset =
        messageCount - offset < messageCurrentLimit
          ? messageCount - messageCurrentLimit
          : offset;
    }

    console.log(data, offset, messageCurrentLimit, messageCount);

    const [response, error, network] =
      await props.controller.getChatChannelMessageList({
        chatChannelId: props.match?.params?.chatChannelId,
        apiToken: props.apiToken,
        offset: offset,
      });

    const messageIds: number[] = (response?.items || []).map(
      (item: any) => item.id
    );
    const oldIds: number[] = message.map((item: any) => item.id);

    const someId = messageIds.some((id) => oldIds.includes(id));

    // response true
    if (!!response) {
      const array: any[] = response.items || [];
      const key = "id";

      const uniqueArray = (array: any[]) =>
        Array.from(new Map(array.map((item) => [item[key], item])).values());

      // มี message intersection กัน
      if (someId && data.index !== data.prevIndex) {
        // concat message
        if (data.index > data.prevIndex) {
          setPrevUrl(response.previous);
          setMessage(uniqueArray([...array, ...message]));
        } else {
          setNextUrl(response.next);
          setMessage(uniqueArray([...message, ...array]));
        }
      } else {
        setNextUrl(response.next);
        setPrevUrl(response.previous);
        setMessage(uniqueArray(array));
      }
    } else {
      setMessage([]);
    }
  };

  const getFingerPrint = () => {
    var options = {};
    Fingerprint2.getPromise(options).then(function (components) {
      // components is array of {key: 'foo', value: 'component value'}
      console.log(components);
      var values = components.map(function (component) {
        return component.value;
      });
      var device_id = Fingerprint2.x64hash128(values.join(""), 31);
      console.log("Fingerprint id:", device_id);

      getSubscriptionObject()
        .then(function (subscription) {
          console.log("Success get notification subscription..");
          console.log(JSON.stringify(subscription));
          postUpdateSubscription(device_id, subscription);
        })
        .catch((err) => {
          console.error(
            "Couldn't create the notification subscription",
            err,
            "name:",
            err.name,
            "message:",
            err.message,
            "code:",
            err.code
          );
        });
    });
  };

  const postUpdateSubscription = async (
    device_id: string,
    subscription: any
  ) => {
    console.log("murmur", device_id);
    const [response, error] = await props.controller.postUpdateSubscription({
      apiToken: props.apiToken,
      device_id,
      subscription,
    });
  };

  const postReadMessage = async () => {
    const [response, error] = await props.controller.postReadMessage({
      chatChannelId: props.match?.params.chatChannelId,
      apiToken: props.apiToken,
    });
  };

  const scrollToBottom = ({ behavior = "auto" }: any = {}) => {
    if (messageEmptyRef.current && !props.disabledScrollIntoView) {
      messageEmptyRef.current.scrollIntoView({
        behavior: behavior,
        block: "nearest",
      });
    }
  };

  const handleSetSelectedMessage = (msgId: any) => {
    if (selectedMessage === msgId) {
      return setSelectedMessage(null);
    }
    return setSelectedMessage(msgId);
  };

  const handleOpenVideoCall = ({ url }: any = {}) => {
    console.log("handleOpenVideoCall");
    console.log(url);
    let vidCallId = url;
    if (vidCallId == null) {
      vidCallId = props.match?.params.chatChannelId;
    } else if (vidCallId.startsWith(config.WEB_RTC)) {
      // format ${config.WEB_RTC}/${videoCallID}/?name=${name}
      vidCallId = vidCallId.slice(config.WEB_RTC.length); // -> ${videoCallID}/?name=${name}
      vidCallId = vidCallId.split("/")[0]; // -> ${videoCallID}
    }
    if (isWebRTCSupport()) {
      // if (query.get("vid_call") !== "true") {
      //   history.push("?vid_call=true");
      // }
      setVideoCallID(vidCallId);

      props?.onOpenVideoCall?.(url);
      // setOpenVideoCallModal(true);
    } else {
      if (typeof window.iosNative !== "undefined") {
        setVideoCallID(vidCallId);
        window.iosNative.onVideoCall(
          `${config.WEB_RTC}/${vidCallId}/?name=${name}`
        );
        return;
      } else {
        alert("เบราว์เซอร์ ไม่รองรับวีดีโอคอล");
      }
    }
  };

  const handleCloseVideoCall = ({ sendEndMessage = true }) => {
    console.log("handleCloseVideoCall 6");
    if (sendEndMessage) {
      setTimeout(() => {
        sendMessage({
          contentType: "end_call",
          content: "", // `${config.WEB_RTC}/${props.match.params.chatChannelId}/?name=${name}`
        });
      }, 1000);
    }

    setTimeout(() => {
      history.replace("?vid_call=false");
      setOpenVideoCallModal(false);
    }, 100);
  };

  const isMobile = () => {
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return isMobile;
  };

  const handleOpenVideoCallFromMessage = ({ url }: any) => {
    console.log("handleOpenVideoCallFromMessage", url);
    // if(config.HIDE_CALL_BUTTON){
    //   return
    // }
    // let qr = new URLSearchParams(`?vid_call=true&content=${url}`);
    // setQuery(qr);
    props?.onOpenVideoCall?.(url);
  };

  const genMessage = () => {
    // console.log("genMessage ", props?.userId, message);
    if (!props?.userId) {
      return;
    }

    return message.map((item, index) => {
      let chat = (
        <FriendMessage
          isBot={!!props.chatBotLogic}
          acceptContentHTML={props.acceptContentHTML}
          avatarBotUrlString={props.avatarBotUrlString}
          // @ts-ignore
          message={item}
          isMobile={isMobile()}
          isSelected={selectedMessage === item.id}
          onSelect={handleSetSelectedMessage}
          onOpenVideoCall={handleOpenVideoCallFromMessage}
          onNavigationMessage={props.onNavigationMessage}
          onMobileOpenImage={handleMobileOpenImage}
          onClickLink={handleClickLink}
        />
      );
      if (item.author && item.author.id === Number(props.userId)) {
        chat = (
          <MyMessage
            isBot={!!props.chatBotLogic}
            // @ts-ignore
            message={item}
            isMobile={isMobile()}
            isSelected={selectedMessage === item.id}
            onSelect={handleSetSelectedMessage}
            onResend={handleResendMessage}
            onOpenVideoCall={handleOpenVideoCallFromMessage}
            onNavigationMessage={props.onNavigationMessage}
            onMobileOpenImage={handleMobileOpenImage}
            onClickLink={handleClickLink}
          />
        );
      }

      if (
        item.content_type === "activity" &&
        (/was kicked\.$/g.test(item.content) ||
          /left the group\.$/g.test(item.content) ||
          /.* ลบ .* ออกจากกลุ่ม$/g.test(item.content))
      ) {
        chat = <LeaveMessage message={item} />;
      }

      return (
        <div
          key={item.id}
          ref={(el) => {
            messageDictRef.current[item.id] = {
              element: el,
              activity: item.content_type === "activity",
            };
            messageRef.current[index] = el;
          }}
        >
          {chat}
        </div>
      );
    });
  };

  const handleMobileOpenImage = (url = "") => {
    setOpenImageModal(true);
    setImage(url);
  };

  const handleClickLink = (e: MouseEvent) => {
    props.onClickLink?.(e);
  };

  const handleResendMessage = async ({ message }: any = {}) => {
    if (!message) {
      return;
    }
    if (
      !props.match &&
      !props.match.params &&
      !props.match.params.chatChannelId
    ) {
      return;
    }
    let chatChannelId = props.match?.params.chatChannelId;
    let index = postMessage[chatChannelId]
      ? postMessage[chatChannelId].findIndex(
          (item: any) => item.id === message.id
        )
      : -1;
    let newObj = { ...postMessage };
    let newArr = postMessage[chatChannelId]
      ? [...postMessage[chatChannelId]]
      : [];
    if (index > -1) {
      newArr[index].sendFail = false;
    }
    newObj[chatChannelId] = newArr;
    await setPostMessage(newObj);
    setTimeout(async () => {
      const [response, error, network] =
        await props.controller.postChatChannelMessage({
          content: message.content,
          contentType: message.content_type,
          contentFile: message.content_file,
          chatChannelId: props.match?.params.chatChannelId,
          apiToken: props.apiToken,
          divisionId: props.division_id ? props.division_id : props.division,
        });

      if (response) {
        await getLastMessage();
        let newObj = { ...postMessage };
        let newArr = postMessage[chatChannelId]
          ? [...postMessage[chatChannelId]]
          : [];
        let arr = newArr.filter((item) => item.id !== message.id);
        newObj[chatChannelId] = arr;
        setPostMessage(newObj);
      } else {
        let newObj = { ...postMessage };
        let newArr = postMessage[chatChannelId]
          ? [...postMessage[chatChannelId]]
          : [];
        let index = newArr.findIndex((item) => item.id === message.id);
        if (index != -1) {
          newArr[index].sendFail = true;
        }
        newObj[chatChannelId] = newArr;
        setPostMessage(newObj);
      }
    }, 0);

    setTextMessage("");
    scrollToBottom({ behavior: "smooth" });
  };

  const genPostMessage = () => {
    let chatChannelId = props.match?.params?.chatChannelId;
    if (!chatChannelId || !postMessage[chatChannelId]) {
      return;
    }
    return postMessage[chatChannelId].map((item: any, index: number) => {
      return (
        <div
          key={item.id}
          ref={(el) => {
            messageDictRef.current[item.id] = {
              element: el,
              activity: item.content_type === "activity",
            };
            messageRef.current[index] = el;
          }}
        >
          <MyMessage
            isBot={!!props.chatBotLogic}
            // @ts-ignore
            message={item}
            isSelected={selectedMessage === item.id}
            onSelect={handleSetSelectedMessage}
            onResend={handleResendMessage}
            onNavigationMessage={props.onNavigationMessage}
            onClickLink={handleClickLink}
          />
        </div>
      );
    });
  };

  const handleCallRequest = async () => {
    let name = props.fullname ? props.fullname : props.fullname;
    if (isWebRTCSupport()) {
      let response: any = await sendMessage({
        contentType: "vid_call",
        content: "",
      });
      let url = null;
      if (response) {
        url = response.content;
      }
      if (url == null) {
        // backward compatible
        url = `${config.WEB_RTC}/${props.match?.params.chatChannelId}/?name=${name}`;
      }
      // history.push(`?vid_call=true&content=${response.content}`);

      props?.onOpenVideoCall?.(url);
      // handleOpenVideoCall({url});
    } else {
      if (typeof window.iosNative !== "undefined") {
        let name = props.fullname ? props.fullname : props.fullname;
        let response: any = await sendMessage({
          contentType: "vid_call",
          content: "",
        });
        let url = response.content;
        if (response.content == null) {
          // backward compatible
          url = `${config.WEB_RTC}/${props.match.params.chatChannelId}/?name=${name}`;
        } else {
          url = `${config.WEB_RTC}/${response.content}/?name=${name}`;
        }
        window.iosNative.onVideoCall(url);
      } else {
        alert("เบราว์เซอร์ ไม่รองรับวีดีโอคอล");
      }
    }
  };

  const handleCallButton = useCallback(
    _.debounce(handleCallRequest, 1000, { leading: true }),
    [props.match?.params?.chatChannelId]
  );

  const sendMessage = ({ contentType, content }: any = {}) => {
    return new Promise(async (resolve, reject) => {
      let txtContent = content ? content : textMessage;
      console.log("txtContent: ", txtContent);

      if (
        contentType !== "vid_call" &&
        contentType !== "end_call" &&
        !txtContent
      ) {
        return;
      }
      if (
        !props.match &&
        !props.match.params &&
        !props.match.params.chatChannelId
      ) {
        return;
      }

      if (props.searchText && nextUrl) {
        await getLastMessage();
      }

      props.onPostMessage?.();

      let chatChannelId = props.match.params.chatChannelId;
      let id = new Date().toISOString();
      let msg = postMessage[chatChannelId]
        ? [...postMessage[chatChannelId]]
        : [];
      if (!globPostMessage[chatChannelId]) {
        globPostMessage[chatChannelId] = [];
      }
      globPostMessage[chatChannelId].push({
        id: id,
        author: {
          id: props.userId,
        },
        content_file: txtContent,
        content: txtContent,
        content_type: contentType ? contentType : "text",
        sendFail: false,
      });

      setTextMessage("");

      var response, error, network;
      console.log("globPostMessage", globPostMessage);
      console.log("postMessage", postMessage);
      setPostMessage({ ...globPostMessage }, async () => {
        [response, error, network] =
          await props.controller.postChatChannelMessage({
            content: txtContent,
            contentFile: txtContent,
            contentType: contentType ? contentType : "text",
            chatChannelId: props.match.params.chatChannelId,
            apiToken: props.apiToken,
            divisionId: props.division_id ? props.division_id : props.division,
          });
        if (isMounted.current) {
          if (response) {
            // Request next question
            if (props.chatBotLogic) {
              await props.chatBotLogic(textMessage);
              // getLastMessage();
            }
            await getLastMessage();
            // let newObj = { ...postMessage, ...postMsg };
            // let newArr = newObj[chatChannelId] ? [...newObj[chatChannelId]] : [];
            let index = globPostMessage[chatChannelId].findIndex(
              (item: any) => item.id === id
            );
            globPostMessage[chatChannelId].splice(index, 1);
            // newObj[chatChannelId] = newArr;
            setPostMessage(globPostMessage);
          } else {
            // let newObj = { ...postMessage, ...postMsg };
            // let newArr = newObj[chatChannelId] ? [...newObj[chatChannelId]] : [];
            let index = globPostMessage[chatChannelId].findIndex(
              (item: any) => item.id === id
            );
            if (index !== -1) {
              globPostMessage[chatChannelId][index].sendFail = true;
              // newObj[chatChannelId] = newArr;
            }
            setPostMessage(globPostMessage);
          }
        }
        resolve(response);
      });
    });
  };

  const handleUploadImage = async (e: {
    preventDefault: () => void;
    target: { files: any };
  }) => {
    e.preventDefault();

    const fileList = e.target.files;

    if (fileList?.length > 0) {
      // console.log("fileList[0].name", fileList[0].name);

      for (const file of fileList) {
        // Not supported in Safari for iOS.
        const name = file.name ? file.name : "NOT SUPPORTED";
        // Not supported in Firefox for Android or Opera for Android.
        const type = file.type ? file.type : "NOT SUPPORTED";
        // Unknown cross-browser support.
        const size = file.size ? file.size : "NOT SUPPORTED";
        console.log({ file, name, type, size });
      }

      if (fileList[0].name.includes("image.")) {
        let newName = new Date().toJSON().replace(".", "") + ".jpg";
        console.log(" Rename to ", newName);
        var renameFile = new File([fileList[0]], newName, {
          type: fileList[0].type,
          lastModified: fileList[0].lastModified,
        });
        sendMessage({ contentType: "image", content: renameFile });
      } else {
        // console.log(" Not rename");
        sendMessage({ contentType: "image", content: fileList[0] });
      }
    }

    imageRef.current.value = "";
  };

  const handleUploadFile = async (e: any) => {
    e.preventDefault();
    console.log(e.target.files, "files");
    sendMessage({ contentType: "file", content: e.target.files[0] });

    fileRef.current.value = ""
  };

  const handleResize = () => {
    setTimeout(() => {
      setIframeHeight(window.innerHeight - 100);
      setIframeWidth(window.innerWidth - 10);
    }, 500);
  };

  const handleIframeMessage = (msg: { data: string }) => {
    try {
      let jsonMsg = JSON.parse(msg.data);
      if (jsonMsg.message === "end_call") {
        handleCloseVideoCall({ sendEndMessage: false });
      }
    } catch (e) {
      console.log("ignore this message");
    }
  };

  const onLoadIframe = () => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("message", handleIframeMessage);
    handleResize();
  };

  const handleOpenModClassify = () => {
    setOpenClassify(true);
  };

  const handleCloseModClassify = () => {
    setOpenClassify(false);
  };

  const handleOpenModDiagHis = () => {
    setOpenModDiagHis(true);
  };

  const handleCloseModDiagHis = () => {
    setOpenModDiagHis(false);
  };

  console.log(props);

  return (
    <div
      key={props.match?.params?.chatChannelId}
      className="ChatBox"
      style={{ height: "unset" }}
    >
      <Modal open={openImageModal} onClose={() => setOpenImageModal(false)}>
        <Image src={image as string} />
      </Modal>

      <div
        ref={messageViewRef}
        className="messageView"
        style={props.messageViewStyle}
        onClick={() => {
          if (scrollRef.current) return;
          setOpenMenu(false);
        }}
      >
        <div
          className="chat-loader"
          style={{ display: isLoading ? "block" : "none" }}
        >
          <Loader active={isLoading} />
        </div>

        {isInCall ? (
          <div
            style={{
              top: "8px",
              position: "sticky",
              zIndex: "999",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              disabled={readOnly}
              size="small"
              color="green"
              onClick={() => {
                handleOpenVideoCallFromMessage({ url: lastCallContent });
              }}
            >
              เข้าร่วม Telemed
            </Button>
          </div>
        ) : null}

        {genMessage()}
        {genPostMessage()}
        <div
          className="chat-loader"
          style={{ display: isLoadingBottom ? "block" : "none" }}
        >
          <Loader active={isLoadingBottom} />
        </div>
        <div ref={messageEmptyRef}></div>
      </div>

      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className="ChatBoxChoice"
      >
        <div style={{ display: "flex" }}>
          <Button
            type="button"
            disabled={readOnly}
            icon="image"
            size="huge"
            compact
            onClick={() => imageRef.current.click()}
          />

          <Button
            type="button"
            disabled={readOnly}
            icon="call"
            size="huge"
            compact
            onClick={handleCallButton}
          />

          <Button
            disabled={readOnly}
            size="huge"
            icon="file"
            compact
            onClick={() => fileRef.current.click()}
          />
        </div>
        <div style={{ display: "flex", paddingRight: "40px" }}>
          {config[`SHOW_CLASSIFY_BUTTON${chatKey}`] && (
            <Button
              size="small"
              style={{
                color: "var(--primary-font-color)",
                backgroundColor: "var(--primary-theme-chat)",
                margin: "5px",
              }}
              onClick={handleOpenModClassify}
              disabled={!props.encounterId}
            >
              Reclassify
            </Button>
          )}
          {config[`SHOW_ASSESSMENT_BUTTON${chatKey}`] && (
            <Button
              size="small"
              style={{
                color: "var(--primary-font-color)",
                backgroundColor: "var(--primary-theme-chat)",
                margin: "5px",
              }}
              onClick={handleOpenModDiagHis}
              // disabled={!props.encounterId}
            >
              แบบคัดกรอง
            </Button>
          )}
        </div>
      </div>
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        ref={imageRef}
        onChange={(e) => handleUploadImage(e)}
      />
      <input
        type="file"
        accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.skt"
        style={{ display: "none" }}
        ref={fileRef}
        onChange={(e) => handleUploadFile(e)}
      />

      <div
        className="textBox"
        style={{ ...(props.chatBotLogic && { paddingLeft: "12px" }) }}
      >
        {!props.chatBotLogic && !props.hideButtonMenu ? (
          <Button
            type="button"
            disabled={readOnly}
            className="sendButton"
            circular
            icon="list"
            onClick={() => {
              setOpenMenu(!openMenu);
            }}
          />
        ) : props.hideButtonMenu ? (
          <div style={{ width: "35px" }}></div>
        ) : null}

        <TextArea
          disabled={readOnly}
          rows={1}
          data-testid="chat"
          className={readOnly ? "readOnly" : ""}
          placeholder={
            readOnly
              ? "ไม่สามารถส่งข้อความได้ เนื่องจากไม่มีสิทธิ"
              : props.placeholder
          }
          onKeyDown={(e: any) => {
            if (!props.useNormalTextAreaKey) {
              if (e.keyCode === CONSTANT.KEY_CODE.ENTER && e.shiftKey) {
                e.preventDefault();
                setTextMessage(textMessage + "\n");
              } else if (e.keyCode === CONSTANT.KEY_CODE.ENTER) {
                e.preventDefault();
                sendMessage();
              }
            }
          }}
          value={textMessage}
          onChange={(e: any) => setTextMessage(e.target.value)}
        />
        <Button
          type="button"
          disabled={readOnly}
          className="sendButton"
          circular
          icon="send"
          onClick={sendMessage}
        />
      </div>

      {openMenu && (
        <div className="menu-div">
          {!config.HIDE_CALL_BUTTON && (
            <Button
              type="button"
              className="sendButton"
              circular
              icon="call"
              onClick={handleCallRequest}
              // onClick={React.useCallback(_.debounce(handleCallRequest, 1000, { leading: true }), [])}
            />
          )}
          <Button
            type="button"
            className="sendButton"
            circular
            icon="image"
            onClick={() => {
              if (typeof window.MobNative !== "undefined") {
                window.MobNative.consoleLog("IMAGE CLICK");
              }
              console.log("image click");
              imageRef.current?.click();
            }}
          />
          <Button
            type="button"
            className="sendButton"
            circular
            icon="file"
            // onClick={() => fileRef.current.click()}
            onClick={() => {
              if (typeof window.MobNative !== "undefined") {
                window.MobNative.consoleLog("FILE CLICK");
              }
              console.log("file click");
              fileRef.current?.click();
            }}
          />
          <div ref={emptyRef}></div>
        </div>
      )}

      <Modal
        basic
        centered={false}
        dimmer={"inverted"}
        open={openVideoCallModal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        size="fullscreen"
        style={{ color: "white" }}
      >
        <Modal.Header>
          <Button
            compact
            negative
            onClick={useCallback(
              _.debounce(handleCloseVideoCall, 1000, { leading: true }),
              [props.match?.params.chatChannelId]
            )}
          >
            End call{" "}
            <Icon
              name="call"
              circular
              inverted
              color="red"
              style={{ transform: "rotate(225deg)", margin: 0 }}
            />
          </Button>
        </Modal.Header>
        <Modal.Content>
          <iframe
            frameBorder={1}
            onLoad={onLoadIframe}
            scrolling="no"
            allow="microphone; camera; *;"
            src={`${config.WEB_RTC}/${videoCallID}/?name=${name}&isPatient=${props.isPatient}&hn=${patientHn}`}
            width={iframeWidth}
            height={iframeHeight}
          />
        </Modal.Content>
      </Modal>

      <Modal open={openClassify} className="classify">
        <CardClassify
          controller={props.classifyController}
          hideCallback={handleCloseModClassify}
          onSuccess={handleCloseModClassify}
          // @ts-ignore
          division={props.division_id}
          apiToken={props.apiToken}
          patientData={props.patientData}
          encounterData={{ id: props.encounterId }}
          diagFormId={props.patientData?.id}
          // onSuccess={() => chatListRef.current.getChatList()}
        />
      </Modal>

      <Modal
        open={openModDiagHis}
        closeOnDimmerClick
        onClose={handleCloseModDiagHis}
        size="large"
      >
        <Suspense fallback={<div>Loading...</div>}>
          <CardDiagFormHistory
            controller={props.diagFormController}
            hideCallback={handleCloseModDiagHis}
            // @ts-ignore
            patientData={{ patient: props.patientId }}
            userId={props.userId}
            division={props.division_id}
            apiToken={props.apiToken}
          />
        </Suspense>
      </Modal>
    </div>
  );
});

const LeaveMessage = (props: any) => {
  return (
    <Form>
      <Form.Field
        style={{
          margin: "1.25rem 0px 0.25rem",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <div
          style={{
            padding: "0.5rem 3rem",
            background: "rgba(255,255,255,0.75)",
            border: "var(--std-border)",
            textAlign: "center",
            color: "#7b7979",
          }}
        >
          <div>
            {props.message.send_at.split(" ")[0]},{" "}
            {props.message.send_at.split(" ")[1]} น.
          </div>
          <div>
            {props.message.content.replace(/was kicked\.$/g, "left the group.")}
          </div>
        </div>
      </Form.Field>
    </Form>
  );
};

export default React.memo(ChatBox);
