import WasmController from "react-lib/frameworks/WasmController";

// APIs
// LAB
import CentralLabTestList from "issara-sdk/apis/CentralLabTestList_apps_LAB";
import CentralLabOrderEstimate from "issara-sdk/apis/CentralLabOrderEstimate_apps_LAB";
// CORE
import MiscellaneousList from "issara-sdk/apis/MiscellaneousList_core";
import PackageTypeList from "issara-sdk/apis/PackageTypeList_core";
import PackageServiceTypeList from "issara-sdk/apis/PackageServiceTypeList_core";
import PackageList from "issara-sdk/apis/PackageList_core";
import ProductForPackageList from "issara-sdk/apis/ProductForPackageList_core";
import PackageDetail from "issara-sdk/apis/PackageDetail_core";
import PackageDuplicateView from "issara-sdk/apis/PackageDuplicateView_core";
import PackageCheckDeleteView from "issara-sdk/apis/PackageCheckDeleteView_core";

// TRT
import TreatmentList from "issara-sdk/apis/TreatmentList_apps_TRT";
import TreatmentOrderEstimate from "issara-sdk/apis/TreatmentOrderEstimate_apps_TRT";
// MSD
import SupplyList from "issara-sdk/apis/SupplyList_apps_MSD";
// TPD
import DrugList from "issara-sdk/apis/DrugList_apps_TPD";
// DFC
import DoctorFeeRuleList from "issara-sdk/apis/DoctorFeeRuleList_apps_DFC";

// Serializer
import ProductForPackageSerializer from "issara-sdk/types/ProductForPackageSerializer_core";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";

import moment from "moment";

export type State = Partial<{
  // sequence
  SettingPackageSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    showDetail: boolean;
    packageDetail: Partial<PackageDetailType>;
    // เพื่อ update active, inactive แล้วคงข้อมูลเดิมไว้
    rawPackageDetail: Partial<PackageDetailType>;
    genderOptions: OptionType[];
    packageTypeOptions: OptionType[];
    serviceTypeOptions: OptionType[];
    selectedProduct: Partial<{
      code: any;
      name: any;
    }>;
    selectedPackage: number | null;
    showRequiredField: RequiredFieldType;
  }> | null;
}>;

type Picked = Partial<
  Pick<
    MainState,
    "buttonLoadCheck" | "errorMessage" | "django" | "searchedItemListWithKey"
  >
>;

type RequiredFieldType = Partial<{
  package_type: string[];
  package_service_type: string[];
  start_date: string[];
  end_date: string[];
  code: string[];
  name: string[];
  selling_price: string[];
  age_range: boolean;
}>;

export type PackageDetailType = {
  id?: number;
  code: string;
  name: string;
  package_type: number;
  package_service_type: number;
  gender: string;
  age_limits_year_start: number;
  age_limits_year_end: number;
  start_date: string;
  end_date: string;
  active: boolean;
  active_flag?: number;
  selling_price: string;
  activated?: boolean;
  can_delete?: boolean;
  items?: ProductDetailType[];
};

export type ProductDetailType = {
  id?: number;
  package?: number;
  product?: number;
  product_code: string;
  product_name: string;
  product_name_en: string;
  p_type: number;
  p_type_code: ProductTypeKey;
  package_group_code: ProductTypeKey;
  price: string;
  unit_price: string;
  quantity: string;
  is_display: true;
  active: true;
  index: number;
  deleted?: boolean;
};

export type ProductTypeKey = keyof typeof PRODUCT_TYPES;

type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
};

type SearchBoxType = {
  id: "code" | "name";
  type: ProductTypeKey;
  searchText: string;
  limit: number;
  action?: string;
};

type ButtonActionKey = keyof typeof BUTTON_ACTIONS;

// Sequence
type SeqState = {
  sequence: "SettingPackage";
  restart?: boolean;
  clear?: boolean;
};

// Common Params
type LoadCheck = {
  card: string;
  errorKey?: string;
  btnAction?: string;
  action?: string;
};

// Handle Action
type ActionType =
  // Search
  | { action: "SEARCH"; searchText: string; card: string }
  | { action: "SEARCH_PRODUCT"; data: SearchBoxType }
  // Action
  | {
      action: "REFRESH_OPTION";
      card: string;
      type: "PACKAGE_TYPE" | "SERVICE_TYPE";
    }
  | { action: "SELECT_PACKAGE"; packageId: number }
  // Method
  | {
      action: "SAVE" | "ACTIVE";
      card: string;
      data: Partial<PackageDetailType>;
      onSuccess?: Function;
    }
  | {
      action: "ADD_PRODUCT";
      type: ProductTypeKey;
      productId: number;
      onSuccess?: (success: boolean) => any;
    }
  | { action: "DELETE"; card: string; errorKey: string; onSuccess?: Function }
  | {
      action: "DUPLICATE";
      card: string;
      code: string;
      name: string;
      onSuccess?: Function;
    };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(
  params: SeqType<Pick<SeqAct, K>>
) => any;

type CustomExtract<T, U> = T extends T
  ? U extends Partial<T>
    ? T
    : never
  : never;

type Params<A extends ActionType["action"]> = CustomExtract<
  ActionType,
  { action: A }
>;

export const StateInitial: State = {
  // sequence
  SettingPackageSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

export const BUTTON_ACTIONS = {
  SEARCH: "SEARCH",
} as const;

export const PRODUCT_TYPES = {
  LAB: "Lab",
  IMAGING: "Imaging",
  TREATMENT: "Treatment",
  SUPPLY: "Supply",
  DRUG: "Drug",
  MISCELLANEOUS: "Miscellaneous",
  DOCTOR_FEE: "Doctor Fee",
};

const GENDER_OPTIONS = [
  { key: 1, text: "ชาย", value: "MALE" },
  { key: 2, text: "หญิง", value: "FEMALE" },
  { key: 3, text: "ไม่ระบุ", value: "NA" },
];

export const PACKAGE_SEARCH_ID = "Package_SSP";

const Masters = [] as const;

type Handler<P = any, R = any> = (
  controller: WasmController<State & Picked, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  } as any);

  const [packageType, serviceType] = await Promise.all([
    GetPackageTypeOptions(controller, {}),
    GetServiceTypeOptions(controller, {}),
  ]);

  controller.setState({
    SettingPackageSequence: {
      ...state.SettingPackageSequence,
      sequenceIndex: "Action",
      showDetail: false,
      genderOptions: GENDER_OPTIONS,
      packageTypeOptions: packageType,
      serviceTypeOptions: serviceType,
      packageDetail: {},
    },
  });
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  if (params.action === "SEARCH") {
    HandleSearch(controller, params);
  } else if (params.action === "SAVE") {
    HandleSave(controller, params);
  } else if (params.action === "ACTIVE") {
    HandleActive(controller, params);
  } else if (params.action === "DELETE") {
    HandleDelete(controller, params);
  } else if (params.action === "DUPLICATE") {
    HandleDuplicate(controller, params);
  } else if (params.action === "SEARCH_PRODUCT") {
    HandleSearchProduct(controller, params);
  } else if (params.action === "ADD_PRODUCT") {
    HandleAddProduct(controller, params);
  } else if (params.action === "REFRESH_OPTION") {
    HandleRefreshOption(controller, params);
  } else if (params.action === "SELECT_PACKAGE") {
    HandleSelectPackage(controller, params);
  }
};

const HandleSearch: Handler<Params<"SEARCH">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const [result, error] = await PackageList.list({
    params: { keyword: params.searchText, limit: 20 },
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });

  if (!result?.items?.length) {
    SetErrorMessage(controller, {
      ...params,
      errorKey: `${params.card}_${params.action}`,
      error: "NOT_FOUND",
    });
  } else {
    controller.setState({
      searchedItemListWithKey: {
        ...state.searchedItemListWithKey,
        [PACKAGE_SEARCH_ID]: result.items || [],
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });
  }
};

const HandleSave: Handler<Params<"SAVE">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const data = params.data;

  data.items = data.items?.map((item) =>
    !Number(item.quantity) ? { ...item, deleted: true } : item
  );

  let [result, error]: any = [null, null];

  if (!!data.id) {
    [result, error] = await PackageDetail.update({
      apiToken: controller.apiToken,
      data,
      pk: data.id,
    });
  } else {
    [result, error] = await PackageList.create({
      apiToken: controller.apiToken,
      data,
    });
  }

  if (error) {
    controller.setState(
      {
        SettingPackageSequence: {
          ...state.SettingPackageSequence,
          showRequiredField: error,
        },
      },
      () => SetErrorMessage(controller, { ...params, error })
    );
  } else {
    const [checkDelete] = await PackageCheckDeleteView.get({
      apiToken: controller.apiToken,
      pk: result.id,
    });

    const data = {
      ...result,
      can_delete: checkDelete?.can_delete || false,
      items: result.items.map((item: any, index: number) => ({
        ...item,
        index,
      })),
    };

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
      SettingPackageSequence: {
        ...state.SettingPackageSequence,
        packageDetail: { ...data },
        rawPackageDetail: { ...data },
        showRequiredField: {},
      },
    });

    params.onSuccess?.();
  }
};

const HandleActive: Handler<Params<"ACTIVE">> = async (controller, params) => {
  const isActive = !!params.data.active;

  HandleSave(controller, {
    ...params,
    data: {
      ...params.data,
      ...(isActive ? { active: false } : { active: true }),
    },
  });
};

const HandleDelete: Handler<Params<"DELETE">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const [result, error] = await PackageDetail.delete({
    apiToken: controller.apiToken,
    pk: state.SettingPackageSequence?.packageDetail?.id,
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });
  } else {
    params.onSuccess?.();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: null,
      },
      searchedItemListWithKey: {
        ...state.searchedItemListWithKey,
        [PACKAGE_SEARCH_ID]: [],
      },
      SettingPackageSequence: {
        ...state.SettingPackageSequence,
        packageDetail: {},
        showDetail: false,
        selectedPackage: null,
      },
    });
  }
};

const HandleDuplicate: Handler<Params<"DUPLICATE">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const [result, error] = await PackageDuplicateView.post({
    pk: state.SettingPackageSequence?.packageDetail?.id,
    apiToken: controller.apiToken,
    data: { code: params.code },
  });

  if (error) {
    SetErrorMessage(controller, {
      ...params,
      error: "DUPLICATE_PACKAGE",
      errorKey: `${params.card}_${params.action}`,
    });
  } else {
    // แก้ไขชื่อ package
    await PackageDetail.update({
      apiToken: controller.apiToken,
      data: { ...result, name: params.name },
      pk: result.id,
    });

    controller.setState(
      {
        searchedItemListWithKey: {
          ...state.searchedItemListWithKey,
          [PACKAGE_SEARCH_ID]: [result],
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: null,
        },
      },
      () =>
        Action(controller, { action: "SELECT_PACKAGE", packageId: result.id })
    );

    params.onSuccess?.();
  }
};

const HandleSearchProduct: Handler<Params<"SEARCH_PRODUCT">> = async (
  controller,
  params
) => {
  let state = controller.getState();

  const data = params.data;
  const type = data.type as ProductTypeKey;

  if (data.action === "clear") {
    return controller.setState({
      searchedItemListWithKey: {
        ...state.searchedItemListWithKey,
        [`${data.type}_${data.id}`]: [],
      },
    });
  }

  const result = await ProductForPackageList.list({
    apiToken: controller.apiToken,
    params: {
      limit: data.limit,
      [data.id]: data.searchText,
      group_code: type,
    },
    extra: { division: controller.data.division },
  });

  state = controller.getState();

  let items: any[] = (result?.[0]?.items || []).map((item: any) => ({
    ...item,
    name_code: `[${item.code}] ${item.name}`,
  }));

  controller.setState({
    searchedItemListWithKey: {
      ...state.searchedItemListWithKey,
      [`${type}_${data.id}`]: [...items],
    },
  });
};

const HandleAddProduct: Handler<Params<"ADD_PRODUCT">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const packageDetail = state.SettingPackageSequence?.packageDetail || {};
  const id = params.productId;
  const type = params.type;

  const findProduct = (key: "name" | "code") => {
    return state.searchedItemListWithKey[`${type}_${key}`]?.find(
      (item: any) => item.id === id
    );
  };

  const productDetail = findProduct("name") || findProduct("code");

  if (!!productDetail) {
    const productIndex = (packageDetail.items || []).findIndex(
      (item) => item.product === productDetail.id
    );

    const list = state.SettingPackageSequence?.packageDetail?.items || [];

    // หากมี product อยู่แล้วให้บวก qty
    if (productIndex >= 0) {
      list[productIndex].quantity = (
        Number(list[productIndex].quantity) + 1
      ).toString();
    } else {
      productDetail.product = productDetail.id;

      delete productDetail.id;

      list.push({
        ...productDetail,
        quantity: "1",
        product_code: productDetail.code,
        product_name: productDetail.name,
        product_name_en: productDetail.name_en,
        unit_price: productDetail.unit_price,
        price: productDetail.unit_price,
        // active: true,
        index: list.length,
        is_display: true,
      });
    }

    controller.setState({
      SettingPackageSequence: {
        ...state.SettingPackageSequence,
        packageDetail: {
          ...state.SettingPackageSequence?.packageDetail,
          items: [...list],
        },
        selectedProduct: {},
      },
      searchedItemListWithKey: {
        ...state.searchedItemListWithKey,
        [`${type}_code`]: [],
        [`${type}_name`]: [],
      },
    });

    params.onSuccess?.(true);
  } else {
    params.onSuccess?.(false);
  }
};

const HandleRefreshOption: Handler<Params<"REFRESH_OPTION">> = async (
  controller,
  params
) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}_${params.type}`]: "LOADING",
    },
  });

  const option = {
    PACKAGE_TYPE: {
      key: "packageTypeOptions",
      api: GetPackageTypeOptions,
    },
    SERVICE_TYPE: {
      key: "serviceTypeOptions",
      api: GetServiceTypeOptions,
    },
  }[params.type];

  const options = await option.api(controller, {});

  state = controller.getState();

  controller.setState({
    SettingPackageSequence: {
      ...state.SettingPackageSequence,
      [option.key]: options,
    },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}_${params.type}`]: "",
    },
  });
};

const HandleSelectPackage: Handler<Params<"SELECT_PACKAGE">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const selected = state.searchedItemListWithKey?.[PACKAGE_SEARCH_ID]?.find(
    (item: any) => item.id === params.packageId
  );

  if (!selected?.id) {
    await controller.setState({
      searchedItemListWithKey: {
        ...state.searchedItemListWithKey,
        [PACKAGE_SEARCH_ID]: [],
      },
    });
  }

  const data = await GetPackageDetail(controller, { id: selected?.id });

  controller.setState({
    SettingPackageSequence: {
      ...state.SettingPackageSequence,
      selectedPackage: selected?.id || null,
      packageDetail: { ...data },
      rawPackageDetail: { ...data },
      showDetail: !!selected?.id,
      showRequiredField: {},
    },
  });
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
const GetPackageTypeOptions: Handler = async (controller, params) => {
  const [result] = await PackageTypeList.list({
    apiToken: controller.apiToken,
  });

  return mapOptions(result?.items || []);
};

const GetServiceTypeOptions: Handler = async (controller, params) => {
  const [result] = await PackageServiceTypeList.list({
    apiToken: controller.apiToken,
  });

  return mapOptions(result?.items || []);
};

const GetPackageDetail: Handler<{ id?: number | null }> = async (
  controller,
  params
) => {
  if (params.id) {
    const [[packageDetail], [checkDelete]] = await Promise.all([
      PackageDetail.retrieve({
        apiToken: controller.apiToken,
        pk: params.id,
      }),
      PackageCheckDeleteView.get({
        apiToken: controller.apiToken,
        pk: params.id,
      }),
    ]);

    return {
      ...packageDetail,
      can_delete: checkDelete?.can_delete || false,
      items: packageDetail?.items?.map((item: any, index: number) => ({
        ...item,
        index,
      })),
    };
  } else {
    return {};
  }
};

const GetLabOrderEstimate: Handler<{ data: any }> = async (
  controller,
  params
) => {
  const data = params.data;

  const orderItem = {
    _id: null,
    lab_code: data.lab_code,
    lab_speciality: data.lab_speciality,
    name: data.name,
    product: data.product_id,
    specimen: data.specimen,
    specimen_name: data.specimen_name,
    specimen_time: moment().format("HH:mm"),
    lab_type_label: data.lab_type_label,
    note: "",
    urgency: "ROUTINE", // To implement selecting urgency
    children: data.children.map((item: any) => ({
      ...item,
      id: null,
      note: "",
    })),
  };

  const estimate = await CentralLabOrderEstimate.post({
    apiToken: controller.apiToken,
    data: {
      order_id: null,
      encounter: 6991999,
      order_items: [orderItem],
      is_appointment: false,
    },
    extra: { division: controller.data.division },
  });

  return estimate[0];
};

const GetTreatmentOrderEstimate: Handler<{ data: any }> = async (
  controller,
  params
) => {
  const data = params.data;

  const orderItem = {
    id: null,
    product: data.id,
    quantity: "1",
  };

  const estimate = await TreatmentOrderEstimate.post({
    apiToken: controller.apiToken,
    data: {
      encounter: 6992056,
      have_compensation: false,
      order_items: [orderItem],
    },
    extra: { division: controller.data.division },
  });

  return estimate[0];
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */

const SetErrorMessage: Handler<LoadCheck & { error: any }> = (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction || params.action}`]: "ERROR",
    },
    errorMessage: {
      ...state.errorMessage,
      [params.errorKey || params.card]: params.error,
    },
  });
};

/**
 *
 * @param key // * หากต้องการกำหนด key แยกจาก value
 */
const mapOptions = (
  items: (Record<string, any> | string)[],
  valueKey: string = "id",
  textKey: string = "name",
  key?: string
) => {
  return items.map((value) => {
    const isString = typeof value === "string";
    return {
      key: isString ? value : key ? value[key] : value[valueKey],
      value: isString ? value : value[valueKey],
      text: isString ? value : value[textKey],
    };
  });
};

// const PRODUCT_SEARCH = {
//   LAB: {
//     api: CentralLabTestList.list,
//     code: "lab_code",
//     name: "name",
//     params: { only_blood_bank: false },
//   },
//   IMAGING: {
//     api: MiscellaneousList.list,
//     code: "name",
//     name: "name",
//     params: { group_code: "XRAY" },
//   },
//   TREATMENT: {
//     api: TreatmentList.list,
//     code: "search",
//     name: "search",
//     params: {},
//   },
//   SUPPLY: {
//     api: SupplyList.list,
//     code: "keyword",
//     name: "keyword",
//     params: {},
//   },
//   DRUG: {
//     api: DrugList.list,
//     code: "keyword",
//     name: "keyword",
//     params: { exclude_outside_drug: true },
//   },
//   MISCELLANEOUS: {
//     api: MiscellaneousList.list,
//     code: "name",
//     name: "name",
//     params: { group_code: "MISC" },
//   },
//   DOCTOR_FEE: {
//     api: DoctorFeeRuleList.list,
//     code: "keyword",
//     name: "keyword",
//     params: {},
//   },
// };
