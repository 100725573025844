import React from "react";
import PropTypes from "prop-types";
import * as CONSTANT from "../../utils/constant";
import * as Common from "../common";
import * as PTM from "../PTM";
import { Label } from "semantic-ui-react";

const CardPatientAssessment = React.forwardRef((props, ref) => {
  const [allowedActions, setAllowedActions] = React.useState([]);
  const [patientAssessmentData, setPatientAssessmentData] = React.useState({});
  const [subFormViewerData, setSubFormViewerData] = React.useState({});
  const [faChoices, setFAChoices] = React.useState([]);
  const [saChoices, setSAChoices] = React.useState([]);
  const [status, setStatus] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    getFunctionalAssessmentChoices();
    getSpiritualAssessmentChoices();
  }, []);

  React.useImperativeHandle(ref, () => ({
    refresh: ({ encounterId } = {}) => {
      refresh({ encounterId });
    }
  }));

  React.useEffect(() => {
    formatFormViewData()
  }, [patientAssessmentData]);

  React.useEffect(() => {
    refresh({ encounterId: props.encounterId });
  }, [props.encounterId]);

  const formatFormViewData = () => {
    let newObj = { ...patientAssessmentData };
    newObj.arrive_status = ARRIVED_BY[newObj.arrive_status];
    newObj.translator = TRANSLATOR[newObj.translator];
    newObj.triage_level = CONSTANT.TRIAGE_LEVEL[newObj.triage_level];
    let faIndex = faChoices.findIndex(item => item.id == newObj.functional);
    if (faIndex > -1) {
      newObj.functional = faChoices[faIndex].name;
    }
    newObj.psychological = PSYCHOLOGICAL_ASSESSMENT[newObj.psychological];
    newObj.phyco_nurse_note = PSYCHO_NURSE_NOTE[newObj.phyco_nurse_note];
    newObj.spiritual = SPIRITUAL[newObj.spiritual];
    newObj.suspected = SUSPECTED[newObj.suspected];
    newObj.sus_nurse_note = SUS_NURSE_NOTE[newObj.sus_nurse_note];
    newObj.convulsion = CONSTANT.YES_NO[newObj.convulsion];
    newObj.mdr_triage = CONSTANT.YES_NO[newObj.mdr_triage];
    newObj.mdr = CONSTANT.YES_NO[newObj.mdr];
    newObj.alcohol = CONSTANT.ALCOHOL[newObj.alcohol];
    newObj.tobacco = CONSTANT.TOBACCO[newObj.tobacco];
    newObj.narcotic = CONSTANT.NARCOTIC[newObj.narcotic];
    setSubFormViewerData(newObj);
  }

  const refresh = ({ encounterId } = {}) => {
    getFormDataLatest({ encounterId });
  };

  const getFormDataLatest = async ({ encounterId }) => {
    setIsLoading(true)
    const [data, error] = await props.controller.getFormDataLatest({
      encounterId: encounterId
        ? encounterId
        : props.patientData.ENCOUNTER.encounter_id,
      formCode: "CardPatientAssessment",
      formVersion: "0.1"
    });
    if (data) {
      setStatus(data.status);
      setAllowedActions(data.allowed_actions);
      setPatientAssessmentData(data.data);
    }
    setIsLoading(false)
  };

  const getFunctionalAssessmentChoices = async () => {
    const [data, error] = await props.controller.getClinicalTerm({
      type: "FA"
    });
    setFAChoices(data);
  };

  const getSpiritualAssessmentChoices = async () => {
    const [data, error] = await props.controller.getClinicalTerm({
      type: "SA"
    });
    setSAChoices(data);
  };

  let statusColor = "black";
  if (status === CONSTANT.FORM_DATA_STATUS.DRAFT) {
    statusColor = "yellow";
  } else if (status === CONSTANT.FORM_DATA_STATUS.EDITED) {
    statusColor = "olive";
  } else if (status === CONSTANT.FORM_DATA_STATUS.CONFIRMED) {
    statusColor = "green";
  } else if (status === CONSTANT.FORM_DATA_STATUS.CANCELLED) {
    statusColor = "red";
  }

  let labelStatus = <Label color={statusColor}>{status}</Label>;

  return (
    <Common.CardLayout
      loading={isLoading}
      titleText="แบบประเมินแรกรับ"
      headerColor="grey"
      onClose={props.onClose}
      titleContent={labelStatus}
    >
      {props.showSummary ? (
        <PTM.SubFormViewer
          fieldList={rmdFieldList}
          fieldMap={rmdFieldMap}
          data={[subFormViewerData]}
        />
      ) : (
        "TODO ---> Implement ShowSummary = false"
      )}
    </Common.CardLayout>
  );
});

const ARRIVED_BY = {
  WLK: "เดิน",
  WCH: "รถนั่ง",
  CRT: "รถนอน",
  ATH: "อื่นๆ พิมพ์..."
};

const TRANSLATOR = {
  true: "ใช้",
  false: "ไม่ใช้"
};

const PSYCHOLOGICAL_ASSESSMENT = {
  true: "Abnormal",
  false: "Normal"
};

const PSYCHO_NURSE_NOTE = {
  NON: "No Intervention Require",
  REP: "Action Plan Require",
  ND: "Notify Doctor"
};

const SPIRITUAL = {
  true: "YES",
  false: "NO"
};

const SUSPECTED = {
  true: "พบร่องรอยการถูกทำร้าย",
  false: "ไม่พบร่องรอยการถูกทำร้าย"
};

const SUS_NURSE_NOTE = {
  NON: "ไม่จำเป็น",
  REP: "รายงานแพทย์",
  OTN: "อื่นๆ"
};

const rmdFieldList = [
  "date",
  "user_fullname",
  "arrive_status",
  "translator",
  "chief_complaint",
  "triage_level",
  "functional",
  "psychological",
  "phyco_nurse_note",
  "spiritual",
  "suspected",
  "sus_nurse_note",
  "convulsion",
  "mdr_triage",
  "mdr",
  "precaution",
  "alcohol",
  "tobacco",
  "narcotic",
  "menstruation",
  "lmp"
];

const rmdFieldMap = {
  date: "Date",
  user_fullname: "ผู้บันทึก/แก้ไข",
  arrive_status: "Arrived by",
  translator: "ใช้ล่าม",
  chief_complaint: "Chief Complaint",
  triage_level: "ระดับการคัดกรอง",
  functional: "Functional Assessment",
  psychological: "Psychological Assessment",
  phyco_nurse_note: "การให้การพยาบาลผู้ป่วย",
  spiritual: "Spiritual/Cultural Assessment",
  suspected: "Suspected abuse or neglect",
  sus_nurse_note: "การให้การพยาบาลผู้ป่วย",
  convulsion: "History of Febrile convulsion",
  mdr_triage: "เสี่ยงต่อการติดเชื้อ",
  mdr: "MDR",
  precaution: "Isolate Precaution",
  alcohol: "ประวัติการดื่มสุรา",
  tobacco: "ประวัติการสูบบุหรี่",
  narcotic: "ประวัติการใช้สารเสพติด",
  menstruation: "ประจำเดือน",
  lmp: "LMP"
};

CardPatientAssessment.defaultProps = {
  onClose: () => {},
  controller: {},
  encounterId: null
};

CardPatientAssessment.propTypes = {
  onClose: PropTypes.func,
  controller: PropTypes.object,
  encounterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default React.memo(CardPatientAssessment);
