import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Dropdown,
  Checkbox,
  Radio,
  Input
} from 'semantic-ui-react'

const CardSkinPreparationUX = (props: any) => {
    return(
      <div
        style={{ margin: "15px",  padding: "20px",  backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", fontSize: "22px" }}>
            Skin Preparation
          </label>
          <Label
            color={props.statusColor}
            style={{display: props.config?.hideStatus ? "none" : "", float: "right" }}>
            {props.status}
          </Label>
          <hr>
          </hr>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              Surgical Skin scrub 
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
            <Checkbox
              checked={props.isSurgicalSkinScrub || false}
              label={props.isSurgicalSkinScrub ? "Yes" : "No"}
              name="is_surgical_skin_scrub"
              onChange={props.onChangeData}
              style={{ float: "right", marginRight: "20px"}}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            style={{display:"flex"}}>
            
            <Checkbox
              checked={props.isSurgicalSite || false}
              label="Surgical site"
              name="is_surgical_site"
              onChange={props.onChangeData}
              style={{margin:"5px 5px 0px 5px"}}>
            </Checkbox>
            <Checkbox
              checked={props.isVagina || false}
              label="Vagina"
              name="is_vagina"
              onChange={props.onChangeData}
              style={{margin:"5px 5px 0px 5px"}}>
            </Checkbox>
          </div>
          <Dropdown
            disabled={props.config?.disabledVagina || false}
            multiple={true}
            name="vagina"
            onChange={props.onChangeData}
            options={props.orVaginaOptions || []}
            selection={true}
            style={{ width: "50%", marginLeft: "2rem" }}
            value={props.vagina || []}>
          </Dropdown>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "20%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              Wound Classification 
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <div
            style={{display:"flex"}}>
            
            <Radio
              checked={props.woundClassification === "clean"}
              label="Clean"
              onChange={(e: any)=>props.onChangeData?.(e,{value: "clean", name: "wound_classification"})}
              style={{margin:"5px 10px 5px 0px"}}>
            </Radio>
            <Radio
              checked={props.woundClassification === "clean_contaminated"}
              label="Clean-contaminated"
              onChange={(e: any)=>props.onChangeData?.(e,{value: "clean_contaminated", name: "wound_classification"})}
              style={{margin:"5px 10px 5px 0px"}}>
            </Radio>
            <Radio
              checked={props.woundClassification === "contaminated"}
              label="Contaminated"
              onChange={(e: any)=>props.onChangeData?.(e,{value: "contaminated", name: "wound_classification"})}
              style={{margin:"5px 10px 5px 0px"}}>
            </Radio>
            <Radio
              checked={props.woundClassification === "infected"}
              label="Infected"
              onChange={(e: any)=>props.onChangeData?.(e,{value: "infected", name: "wound_classification"})}
              style={{margin:"5px 10px 5px 0px"}}>
            </Radio>
            <Radio
              checked={props.woundClassification === "scope"}
              label="Scope"
              onChange={(e: any)=>props.onChangeData?.(e,{value: "scope", name: "wound_classification"})}
              style={{margin:"5px 10px 5px 0px"}}>
            </Radio>
          </div>
        </div>
        <div
          style={{ backgroundColor: "#FDF8DD", padding: "30px", margin: "30px 0px" }}>
          
          <div
            style={{ display: "flex", paddingBottom: "5px" }}>
            
            <label
              style={{  fontWeight: "bold",   width: "12%",  margin: "5px 10px 0px 0px" }}>
              Nursing Diagnosis
            </label>
            <label
              style={{ width: "29.5%",  margin: "5px 10px 0px 0px" }}>
              {props.nurseDiagnosis || ""}
            </label>
            <label
              style={{  fontWeight: "bold",   width: "7%",  margin: "5px 10px 0px 0px" }}>
              Goal
            </label>
            <label
              style={{ width: "25%",  margin: "5px 10px 0px 0px" }}>
              {props.goal || ""}
            </label>
          </div>
          <div
            style={{ display: "flex", paddingBottom: "5px", alignItems: "center" }}>
            
            <label
              style={{  fontWeight: "bold",   width: "12%",  margin: "5px 10px 0px 0px" }}>
              Plan and implementation
            </label>
            <label
              style={{ width: "30%",  margin: "5px 10px 0px 0px" }}>
              {props.planAndImplementation || ""}
            </label>
            <label
              style={{  fontWeight: "bold",   width: "7%",  margin: "5px 10px 0px 0px" }}>
              Evaluation
            </label>
            <label
              style={{    width: "20%",  margin: "5px 10px 0px 0px" }}>
              {props.evaluation || ""}
            </label>
            <Checkbox
              checked={props.evaluationCheck || false}
              label={props.evaluationCheck ? "Yes" : "No"}
              name="evaluation_check"
              onChange={props.onChangeData}
              style={{ float: "right", marginRight: "20px",marginTop:"5px", fontWeight: "bold"}}
              toggle={true}>
            </Checkbox>
            <label
              style={{  fontWeight: "bold",   width: "8%",  margin: "5px 10px 0px 0px" }}>
              
            </label>
            <Input
              name="evaluation_detail"
              onChange={props.onChangeData}
              value={props.evaluationDetail || ""}>
            </Input>
          </div>
        </div>
        <div
          style={{ height: "55px", paddingTop: "15px" }}>
          
          <div
            style={{ float: "right" , display: "flex"}}>
            
            <div
              style={{margin: "0 0.25rem"}}>
              {props.ButtonSave}
            </div>
            <div
              style={{margin: "0 0.25rem"}}>
              {props.ButtonConfirm}
            </div>
            <div
              style={{margin: "0 0.25rem"}}>
              {props.ButtonUnConfirm}
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardSkinPreparationUX

export const screenPropsDefault = {}

/* Date Time : Wed Sep 14 2022 07:18:26 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\",  padding: \"20px\",  backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Skin Preparation"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"22px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        },
        "color": {
          "type": "code",
          "value": "props.statusColor"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideStatus ? \"none\" : \"\", float: \"right\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "Surgical Skin scrub "
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Dropdown",
      "parent": 68,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledVagina || false"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "vagina"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.orVaginaOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", marginLeft: \"2rem\" }"
        },
        "value": {
          "type": "code",
          "value": "props.vagina || []"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"20%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 74,
      "props": {
        "children": {
          "type": "value",
          "value": "Wound Classification "
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "label",
      "parent": 74,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#FDF8DD\", padding: \"30px\", margin: \"30px 0px\" }"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": "Nursing Diagnosis"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"12%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nurseDiagnosis || \"\""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"29.5%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": "Goal"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"7%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "code",
          "value": "props.goal || \"\""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"25%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\", alignItems: \"center\" }"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\" }"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\" , display: \"flex\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan and implementation"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"12%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "Checkbox",
      "parent": 69,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isSurgicalSkinScrub || false"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "code",
          "value": "props.isSurgicalSkinScrub ? \"Yes\" : \"No\""
        },
        "name": {
          "type": "value",
          "value": "is_surgical_skin_scrub"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\", marginRight: \"20px\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "Checkbox",
      "parent": 161,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isSurgicalSite || false"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "value",
          "value": "Surgical site"
        },
        "name": {
          "type": "value",
          "value": "is_surgical_site"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"5px 5px 0px 5px\"}"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "Checkbox",
      "parent": 161,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isVagina || false"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "value",
          "value": "Vagina"
        },
        "name": {
          "type": "value",
          "value": "is_vagina"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"5px 5px 0px 5px\"}"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "Radio",
      "parent": 164,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.woundClassification === \"clean\""
        },
        "label": {
          "type": "value",
          "value": "Clean"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "(e: any)=>props.onChangeData?.(e,{value: \"clean\", name: \"wound_classification\"})"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"5px 10px 5px 0px\"}"
        },
        "value": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "Radio",
      "parent": 164,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.woundClassification === \"clean_contaminated\""
        },
        "label": {
          "type": "value",
          "value": "Clean-contaminated"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "(e: any)=>props.onChangeData?.(e,{value: \"clean_contaminated\", name: \"wound_classification\"})"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"5px 10px 5px 0px\"}"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "Radio",
      "parent": 164,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.woundClassification === \"contaminated\""
        },
        "label": {
          "type": "value",
          "value": "Contaminated"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "(e: any)=>props.onChangeData?.(e,{value: \"contaminated\", name: \"wound_classification\"})"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"5px 10px 5px 0px\"}"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "Radio",
      "parent": 164,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.woundClassification === \"infected\""
        },
        "label": {
          "type": "value",
          "value": "Infected"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "(e: any)=>props.onChangeData?.(e,{value: \"infected\", name: \"wound_classification\"})"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"5px 10px 5px 0px\"}"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "Radio",
      "parent": 164,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.woundClassification === \"scope\""
        },
        "label": {
          "type": "value",
          "value": "Scope"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "(e: any)=>props.onChangeData?.(e,{value: \"scope\", name: \"wound_classification\"})"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"5px 10px 5px 0px\"}"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": "Evaluation"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"7%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "code",
          "value": "props.evaluation || \"\""
        },
        "style": {
          "type": "code",
          "value": "{    width: \"20%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "Checkbox",
      "parent": 145,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.evaluationCheck || false"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "code",
          "value": "props.evaluationCheck ? \"Yes\" : \"No\""
        },
        "name": {
          "type": "value",
          "value": "evaluation_check"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\", marginRight: \"20px\",marginTop:\"5px\", fontWeight: \"bold\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"8%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "Input",
      "parent": 145,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "evaluation_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.evaluationDetail || \"\""
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "code",
          "value": "props.planAndImplementation || \"\""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 0.25rem\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 0.25rem\"}"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonUnConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 0.25rem\"}"
        }
      },
      "seq": 178,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardSkinPreparationUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
