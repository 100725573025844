import React from 'react';
import { IntlProvider, FormattedMessage, useIntl } from 'react-intl';

import {
	Button,
	Input
} from 'semantic-ui-react'
import {
	Table
} from 'react-lib/frameworks/Table'

const ModDrugOrderTemplate = (props: any) => {
	return (
		<div>
			<div>

				<div
					style={{ padding: "15px" }}>

					<div
						style={{ display: "flex", alignItems: "center" }}>
						<div
							style={{ fontWeight: "bold", fontSize: "20px", flex: "1" }}>
							สั่งยาจากชุดยาของแพทย์
						</div>
						<div
							style={{ display: "flex", flex: "2", paddingLeft: "20px" }}>

							<Button
								className={props.selectTab === "doctor" ? "" : "inverted"}
								color="blue"
								onClick={() => { props.onChangeTab("doctor") }}>
								ชุดยาของแพทย์
							</Button>
							<Button
								className={props.selectTab === "central" ? "" : "inverted"}
								color="blue"
								onClick={() => { props.onChangeTab("central") }}>
								ชุดยาส่วนกลาง
							</Button>
						</div>
					</div>
					<div>
						{props.dividerProps}
					</div>
					<div
						style={{ display: "flex", alignItems: "center", padding: "10px 0px" }}>

						<div
							style={{ display: "flex", alignItems: "center", flex: "1", }}>

							<label
								style={{ paddingRight: "10px" }}>
								ค้นหาชุดยา
							</label>
							<Input
								icon="search"
								onChange={props.onChangeSearchText}
								style={{ width: "280px" }}
								value={props.searchText}>
							</Input>
							<Button
								color="blue"
								onClick={props.onSearch}
								style={{ marginLeft: "10px" }}>
								ค้นหา
							</Button>
						</div>
						<div
							style={{ display: "flex", justifyContent: "flex-end", flex: 2 }}>

							<Button
								color="blue"
								onClick={props.onDrugOrderTemplate}>
								การจัดการชุดยา
							</Button>
						</div>
					</div>
					<div
						style={{ display: "flex" }}>
						<div
							style={{ flex: 1 }}>

							<Table
								data={props.drugOrderTemplateData}
								getTrProps={props.drugOrderTrProps}
								headers={props.selectTab === "central" ? ",ชุดยา," : ",ชุดยา"}
								keys={props.selectTab === "central" ? "_checkbox,name,pinned" : "_checkbox,name"}
								minRows="8"
								showPagination={false}
								style={{ height: "50vh" }}
								widths={props.selectTab === "central" ? "50,,100" : "50,"}>
							</Table>
						</div>
						<div
							style={{ flex: 2, paddingLeft: "20px" }}>

							<Table
								data={props.drugTemplate}
								getTrProps={props.drugItemSelectRow}
								headers={props.drugItemHeader ? props.drugItemHeader : ",ชื่อยา,วิธีใช้,จำนวน,หน่วย"}
								keys="_check,product_name,prescription_label,quantity,stock_unit_name"
								minRows="8"
								showPagination={false}
								style={{ height: "50vh" }}
								widths="50,,,100,100">
							</Table>
						</div>
					</div>
					<div
						style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "25px" }}>
						<Button
							color="blue"
							onClick={props.onAddToOrder}>
							ADD TO ORDER
						</Button>
						<Button
							color="grey"
							onClick={props.onCancel}>
							CANCEL
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ModDrugOrderTemplate

export const screenPropsDefault = {}

/* Date Time : Wed Feb 15 2023 14:56:07 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
	"elements": [
		{
			"id": 0,
			"name": "div",
			"parent": -1,
			"seq": 0
		},
		{
			"from": null,
			"id": 1,
			"name": "div",
			"parent": 0,
			"props": {
				"children": {
					"type": "value",
					"value": ""
				},
				"style": {
					"type": "code",
					"value": ""
				}
			},
			"seq": 1,
			"void": false
		},
		{
			"from": null,
			"id": 16,
			"name": "div",
			"parent": 1,
			"props": {
				"children": {
					"type": "value",
					"value": ""
				},
				"style": {
					"type": "code",
					"value": "{padding: \"15px\"}"
				}
			},
			"seq": 16,
			"void": false
		},
		{
			"from": null,
			"id": 17,
			"name": "div",
			"parent": 16,
			"props": {
				"style": {
					"type": "code",
					"value": "{display: \"flex\", alignItems: \"center\"}"
				}
			},
			"seq": 17,
			"void": false
		},
		{
			"from": null,
			"id": 18,
			"name": "div",
			"parent": 17,
			"props": {
				"children": {
					"type": "value",
					"value": "สั่งยาจากชุดยาของแพทย์"
				},
				"style": {
					"type": "code",
					"value": "{fontWeight: \"bold\", fontSize: \"20px\", flex: \"1\"}"
				}
			},
			"seq": 18,
			"void": false
		},
		{
			"from": null,
			"id": 20,
			"name": "div",
			"parent": 16,
			"props": {
				"children": {
					"type": "value",
					"value": ""
				},
				"style": {
					"type": "code",
					"value": "{display: \"flex\", alignItems: \"center\", padding: \"10px 0px\"}"
				}
			},
			"seq": 22,
			"void": false
		},
		{
			"from": null,
			"id": 22,
			"name": "div",
			"parent": 16,
			"props": {
				"style": {
					"type": "code",
					"value": "{display: \"flex\"}"
				}
			},
			"seq": 26,
			"void": false
		},
		{
			"from": null,
			"id": 26,
			"name": "div",
			"parent": 16,
			"props": {
				"style": {
					"type": "code",
					"value": "{display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\", marginTop: \"25px\"}"
				}
			},
			"seq": 43,
			"void": false
		},
		{
			"from": "semantic-ui-react",
			"id": 27,
			"name": "Button",
			"parent": 26,
			"props": {
				"children": {
					"type": "value",
					"value": "ADD TO ORDER"
				},
				"color": {
					"type": "value",
					"value": "blue"
				},
				"name": {
					"type": "value",
					"value": ""
				},
				"onClick": {
					"type": "code",
					"value": "props.onAddToOrder"
				}
			},
			"seq": 27,
			"void": false
		},
		{
			"from": null,
			"id": 31,
			"name": "div",
			"parent": 22,
			"props": {
				"children": {
					"type": "value",
					"value": ""
				},
				"style": {
					"type": "code",
					"value": "{flex: 1}"
				}
			},
			"seq": 31,
			"void": false
		},
		{
			"from": null,
			"id": 33,
			"name": "div",
			"parent": 22,
			"props": {
				"children": {
					"type": "value",
					"value": ""
				},
				"style": {
					"type": "code",
					"value": "{flex: 2, paddingLeft: \"20px\"}"
				}
			},
			"seq": 33,
			"void": false
		},
		{
			"from": "react-lib/frameworks/Table",
			"id": 34,
			"name": "Table",
			"parent": 31,
			"props": {
				"columns": {
					"type": "code",
					"value": ""
				},
				"data": {
					"type": "code",
					"value": "props.drugOrderTemplateData"
				},
				"getTrProps": {
					"type": "code",
					"value": "props.drugOrderTrProps"
				},
				"headers": {
					"type": "code",
					"value": "props.selectTab === \"central\" ?  \",ชุดยา,\" : \",ชุดยา\""
				},
				"keys": {
					"type": "code",
					"value": "props.selectTab === \"central\" ? \"_checkbox,name,pinned\" : \"_checkbox,name\""
				},
				"minRows": {
					"type": "value",
					"value": "8"
				},
				"showPagination": {
					"type": "code",
					"value": "false"
				},
				"style": {
					"type": "code",
					"value": "{ height: \"50vh\" }"
				},
				"widths": {
					"type": "code",
					"value": "props.selectTab === \"central\" ? \"50,,100\" : \"50,\""
				}
			},
			"seq": 34,
			"void": false
		},
		{
			"from": "react-lib/frameworks/Table",
			"id": 35,
			"name": "Table",
			"parent": 33,
			"props": {
				"data": {
					"type": "code",
					"value": "props.drugTemplate"
				},
				"getTrProps": {
					"type": "code",
					"value": "props.drugItemSelectRow"
				},
				"headers": {
					"type": "code",
					"value": "props.drugItemHeader?  props.drugItemHeader :\",ชื่อยา,วิธีใช้,จำนวน,หน่วย\""
				},
				"keys": {
					"type": "value",
					"value": "_check,product_name,prescription_label,quantity,stock_unit_name"
				},
				"minRows": {
					"type": "value",
					"value": "8"
				},
				"showPagination": {
					"type": "code",
					"value": "false"
				},
				"style": {
					"type": "code",
					"value": "{ height: \"50vh\" }"
				},
				"widths": {
					"type": "value",
					"value": "50,,,100,100"
				}
			},
			"seq": 35,
			"void": false
		},
		{
			"from": "semantic-ui-react",
			"id": 36,
			"name": "Button",
			"parent": 26,
			"props": {
				"children": {
					"type": "value",
					"value": "CANCEL"
				},
				"color": {
					"type": "value",
					"value": "grey"
				},
				"name": {
					"type": "value",
					"value": ""
				},
				"onClick": {
					"type": "code",
					"value": "props.onCancel"
				}
			},
			"seq": 36,
			"void": false
		},
		{
			"from": null,
			"id": 37,
			"name": "div",
			"parent": 20,
			"props": {
				"children": {
					"type": "value",
					"value": ""
				},
				"style": {
					"type": "code",
					"value": "{display: \"flex\", alignItems: \"center\",  flex: \"1\",}"
				}
			},
			"seq": 37,
			"void": false
		},
		{
			"from": null,
			"id": 38,
			"name": "div",
			"parent": 20,
			"props": {
				"children": {
					"type": "value",
					"value": ""
				},
				"style": {
					"type": "code",
					"value": "{display: \"flex\", justifyContent:  \"flex-end\", flex: 2}"
				}
			},
			"seq": 38,
			"void": false
		},
		{
			"from": "semantic-ui-react",
			"id": 39,
			"name": "Button",
			"parent": 38,
			"props": {
				"children": {
					"type": "value",
					"value": "การจัดการชุดยา"
				},
				"color": {
					"type": "value",
					"value": "blue"
				},
				"onClick": {
					"type": "code",
					"value": "props.onDrugOrderTemplate"
				}
			},
			"seq": 39,
			"void": false
		},
		{
			"from": null,
			"id": 40,
			"name": "label",
			"parent": 37,
			"props": {
				"children": {
					"type": "value",
					"value": "ค้นหาชุดยา"
				},
				"style": {
					"type": "code",
					"value": "{paddingRight: \"10px\"}"
				}
			},
			"seq": 40,
			"void": false
		},
		{
			"from": "semantic-ui-react",
			"id": 41,
			"name": "Input",
			"parent": 37,
			"props": {
				"icon": {
					"type": "value",
					"value": "search"
				},
				"onChange": {
					"type": "code",
					"value": "props.onChangeSearchText"
				},
				"size": {
					"type": "code",
					"value": ""
				},
				"style": {
					"type": "code",
					"value": "{width: \"280px\"}"
				},
				"value": {
					"type": "code",
					"value": "props.searchText"
				}
			},
			"seq": 41,
			"void": false
		},
		{
			"from": "semantic-ui-react",
			"id": 42,
			"name": "Button",
			"parent": 37,
			"props": {
				"children": {
					"type": "value",
					"value": "ค้นหา"
				},
				"color": {
					"type": "value",
					"value": "blue"
				},
				"onClick": {
					"type": "code",
					"value": "props.onSearch"
				},
				"size": {
					"type": "value",
					"value": ""
				},
				"style": {
					"type": "code",
					"value": "{marginLeft: \"10px\"}"
				}
			},
			"seq": 42,
			"void": false
		},
		{
			"from": null,
			"id": 43,
			"name": "div",
			"parent": 16,
			"props": {
				"children": {
					"type": "code",
					"value": "props.dividerProps"
				}
			},
			"seq": 20,
			"void": false
		},
		{
			"from": null,
			"id": 44,
			"name": "div",
			"parent": 17,
			"props": {
				"children": {
					"type": "value",
					"value": ""
				},
				"style": {
					"type": "code",
					"value": "{display: \"flex\", flex: \"2\", paddingLeft: \"20px\"}"
				}
			},
			"seq": 44,
			"void": false
		},
		{
			"from": "semantic-ui-react",
			"id": 45,
			"name": "Button",
			"parent": 44,
			"props": {
				"children": {
					"type": "value",
					"value": "ชุดยาของแพทย์"
				},
				"className": {
					"type": "code",
					"value": "props.selectTab === \"doctor\" ? \"\" : \"inverted\""
				},
				"color": {
					"type": "value",
					"value": "blue"
				},
				"onClick": {
					"type": "code",
					"value": "() => {props.onChangeTab(\"doctor\")}"
				}
			},
			"seq": 45,
			"void": false
		},
		{
			"from": "semantic-ui-react",
			"id": 46,
			"name": "Button",
			"parent": 44,
			"props": {
				"children": {
					"type": "value",
					"value": "ชุดยาส่วนกลาง"
				},
				"className": {
					"type": "code",
					"value": "props.selectTab === \"central\" ? \"\" : \"inverted\""
				},
				"color": {
					"type": "value",
					"value": "blue"
				},
				"onClick": {
					"type": "code",
					"value": "() => {props.onChangeTab(\"central\")}"
				}
			},
			"seq": 46,
			"void": false
		}
	],
	"forwardRef": false,
	"height": 80,
	"isMounted": false,
	"memo": false,
	"name": "ModDrugOrderTemplate",
	"project": "IsHealth",
	"screenPropsDefault": {
	},
	"width": 80
}

*********************************************************************************** */
