import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Icon,
  FormGroup,
  FormField,
  Input,
  Label,
  Button
} from 'semantic-ui-react'

const CardSaveRefNo = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{width: "100%",height:"100%",padding:"10px"}}>
        <div>
          
          <div
            style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px 0"}}>
            
            <div
              style={{fontSize: "1.2rem", fontWeight: "bold"}}>
              บันทึก Ref No. ส่งเบิก
            </div>
            <div
              style={{cursor: "pointer"}}>
              
              <Icon
                className="red large"
                name="close"
                onClick={props.onClose}>
              </Icon>
            </div>
          </div>
        </div>
        <div
          className="ui divider">
          
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={5}>
              <label>
                เลขที่ใบแจ้งหนี้เรียกเก็บเงินจากต้นสังกัด
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Label
                size="large"
                style={{ width: "100%", padding: "1.1em 1em",textAlign: "center"}}>
                {props.lotNo || "-"}
              </Label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={5}>
              <label>
                วันที่สร้างเลขที่ใบแจ้งหนี้เรียกเก็บเงิน
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Label
                style={{ width: "100%", padding: "1.1em 1em",textAlign: "center"}}>
                {props.createAt || "-"}
              </Label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={5}>
              <label>
                ระบุเลขที่ตอบกลับ Ref No.
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Input
                fluid={true}
                onChange={props.onChangeRefNo}
                placeholder="ระบุเลข Ref No."
                style={{width: "100%"}}
                value={props.refNo || ""}>
              </Input>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form"
          style={{marginTop: "2rem 0 -1rem", marginBottom: "-0.25rem"}}>
          
          <div
            className="inline fields"
            style={{justifyContent: "flex-end"}}>
            
            <FormField
              inline={true}
              width={2}>
              <div>
                {props.ButtonSave}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Button
                color="red"
                fluid={true}
                onClick={props.onCancel}>
                ยกเลิก
              </Button>
            </FormField>
          </div>
        </div>
      </div>
    )
}

export default CardSaveRefNo

export const screenPropsDefault = {}

/* Date Time : Mon Feb 27 2023 11:32:35 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\",height:\"100%\",padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\", padding: \"5px 0\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึก Ref No. ส่งเบิก"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Icon",
      "parent": 72,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormGroup",
      "parent": 69,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormGroup",
      "parent": 69,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormGroup",
      "parent": 69,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormField",
      "parent": 74,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 74,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormField",
      "parent": 75,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormField",
      "parent": 75,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormField",
      "parent": 76,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 76,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "label",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบแจ้งหนี้เรียกเก็บเงินจากต้นสังกัด"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "label",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่สร้างเลขที่ใบแจ้งหนี้เรียกเก็บเงิน"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "label",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุเลขที่ตอบกลับ Ref No."
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Input",
      "parent": 82,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeRefNo"
        },
        "placeholder": {
          "type": "value",
          "value": "ระบุเลข Ref No."
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.refNo || \"\""
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Label",
      "parent": 78,
      "props": {
        "children": {
          "type": "code",
          "value": "props.lotNo || \"-\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", padding: \"1.1em 1em\",textAlign: \"center\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Label",
      "parent": 80,
      "props": {
        "children": {
          "type": "code",
          "value": "props.createAt || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", padding: \"1.1em 1em\",textAlign: \"center\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"2rem 0 -1rem\", marginBottom: \"-0.25rem\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "inline fields"
        },
        "style": {
          "type": "code",
          "value": "{justifyContent: \"flex-end\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "FormField",
      "parent": 90,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "FormField",
      "parent": 90,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "Button",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 91,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        }
      },
      "seq": 95,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": true,
  "memo": false,
  "name": "CardSaveRefNo",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 70
}

*********************************************************************************** */
