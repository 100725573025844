import React, { CSSProperties, useMemo } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
// Mui
import Badge, { BadgeProps } from "@mui/material/Badge";

type IconType =
  | "register"
  | "execute"
  | "result"
  | "pacs"
  | "pacs_grey"
  | "report"
  | "edit"
  | "approve"
  | "unexecute"
  | "unapprove";

type ActionButtonProps = {
  icon: IconType;
  badgeContent?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  // style
  badgeSx?: BadgeProps["sx"];
  onClick?: (icon: IconType) => any;
};

// Const
const styles = {
  box_icon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "0.6rem",
  } as CSSProperties,
  icon_label: {
    fontWeight: "bold",
    textAlign: "center",
    lineHeight: 1.3,
  } as CSSProperties,
};

const IMAGES = {
  execute: "/static/images/x-ray/double-check-execute.png",
  register: "/static/images/x-ray/file-check-register.png",
  male: "/static/images/x-ray/male.png",
  female: "/static/images/x-ray/female.png",
  comment_green: "/static/images/x-ray/comment-outline-green.png",
  comment_grey: "/static/images/x-ray/comment-outline-grey.png",
  result: "/static/images/x-ray/eye-grey.png",
  pacs: "/static/images/x-ray/photo-library-blue.png",
  unexecute: "/static/images/x-ray/uncheck-read.png",
  pacs_grey: "/static/images/x-ray/image-gallery-grey.png",
  report: "/static/images/x-ray/document-add.png",
  edit: "/static/images/x-ray/edit-square-sharp.png",
  approve: "/static/images/x-ray/document-table-checkmark.png",
  unapprove: "/static/images/x-ray/document-dismiss.png",
};

export const ActionButton = (props: ActionButtonProps) => {
  const config = useMemo(() => {
    return {
      register: {
        label: "register imaging order",
        alt: "blue folder with a check mark on it",
        color: "#2185D0",
        title: "REGISTER",
        style: { width: "30px" },
      },
      execute: {
        label: "execute imaging order",
        alt: "yellow square with a check mark on it",
        color: "#F7AC08",
        title: "EXECUTE",
        style: { width: "22px", margin: "3px 0px 5px" },
      },
      result: {
        label: "view diagnostic radiology report",
        alt: "grey eye",
        color: "#898383",
        title: "RESULT",
        style: { width: "28px", margin: "1px 0 1px" },
      },
      pacs: {
        label: "view diagnostic radiographs in the PACS system",
        alt: "blue image gallery",
        color: "#2185D0",
        title: "PACS",
        style: { width: "25px", margin: "4px 0 1px" },
      },
      pacs_grey: {
        label: "",
        alt: "grey image gallery",
        color: "#B8B8B8",
        title: "PACS",
        style: { width: "25px", margin: "4px 0 1px" },
      },
      report: {
        label: "enter the results of the diagnostic radiography.",
        alt: "purple icon with a pen and paper",
        color: "#9B51E0",
        title: "REPORT ENTRY",
        style: { width: "28px", margin: "1px 0 1px" },
      },
      edit: {
        label: "edit diagnostic radiographic results",
        alt: "yellow pencil",
        color: "#F7AC08",
        title: "EDIT RESULT",
        style: { width: "26px", margin: "0 0 4px" },
        labelStyle: { maxWidth: "45px" },
      },
      approve: {
        label: "Approve diagnostic radiography results",
        alt: "green document table with a check mark on it",
        color: "#1B9D2C",
        title: "APPROVE RESULT",
        style: { width: "30px" },
        labelStyle: { maxWidth: "65px" },
      },
      unexecute: {
        label: "unexecute imaging order",
        alt: "red check mark with a dismiss icon on it",
        color: "#FA3030",
        title: "UNEXECUTE",
        style: { width: "28px", margin: "1px 0px 1px 2px" },
      },
      unapprove: {
        label: "revert the status from approved back to reported",
        alt: "red document with a dismiss icon on it",
        color: "#FA3030",
        title: "UNAPPROVE",
        style: { width: "28px", margin: "1px 0px 1px 2px" },
      },
    }[props.icon];
  }, [props.icon]);

  const handleClick = () => {
    if (!props.disabled) {
      props.onClick?.(props.icon);
    }
  };

  return (
    <div>
      <Badge
        badgeContent={props.badgeContent}
        color="error"
        sx={{
          position: "relative",
          "& .MuiBadge-badge": {
            padding: "0 2px",
            margin: "1px 5px 0 0",
            minWidth: "15px",
            height: "15px",
          },
          ...(props.badgeSx || {}),
        }}
      >
        <div
          role="button"
          aria-label={config.label}
          style={{
            ...styles.box_icon,
            cursor: props.disabled ? "" : "pointer",
          }}
          onClick={handleClick}
        >
          <img src={IMAGES[props.icon]} alt={config.alt} style={config.style} />
          <label
            style={{
              color: config.color,
              ...styles.icon_label,
              ...config.labelStyle,
            }}
          >
            {config.title}
          </label>
        </div>

        <Dimmer
          active={props.loading}
          size="small"
          inverted
          style={{
            background: "transparent",
            margin: "-1px 0 0 0px",
            padding: 0,
            maxWidth: config.labelStyle?.maxWidth,
          }}
        >
          <Loader size="small" inline={true}></Loader>
        </Dimmer>
      </Badge>
    </div>
  );
};

export default React.memo(ActionButton);
