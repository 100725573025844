import React, { useCallback, useMemo, CSSProperties, useEffect } from "react";
import { Button } from "semantic-ui-react";

import { Column, RowInfo } from "react-table-6";
import moment from "moment";

// UX
import CardPackagePurchaseHistoryUX from "./CardPackagePurchaseHistoryUX";

// Common
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Interface
import {
  MasterDataType,
  MasterOptionsType,
  PACKAGE_SEARCH_PH,
  RunSequence,
  State,
  PACKAGE_SEARCH_AUH,
  PRODUCT_SEARCH_AUH,
} from "./sequence/PackagePurchase";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import CardAppointmentPackage from "./CardAppointmentPackage";

// Types
type CardPackagePurchaseHistoryProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: RunSequence;
  PackagePurchaseSequence?: State["PackagePurchaseSequence"];
  // data
  masterData?: MasterDataType;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  // options
  masterOptions?: MasterOptionsType;
  // config
  selectable?: boolean;
  selectedDivision?: Record<string, any> | null;
  disabledOrderIds?: number[];
  // callback
  onOpenHistory?: Function;
  onSelectPackage?: (data: Record<string, any>) => any;
};

type HistoryDataType = {
  package_id: number;
  package_name: string;
  package_code: string;
  product_id: number;
  product_name: string;
  product_code: string;
};

// Const
const USAGE_OPTIONS = [
  { key: 1, value: "INCOMPLETED", text: "ไม่สมบูรณ์" },
  { key: 2, value: "COMPLETED", text: "สมบูรณ์" },
];

const BUTTON_ACTIONS = {
  search: "SEARCH",
};

const GridCenter = { display: "grid", placeContent: "center" } as CSSProperties;

const CARD_PACKAGE_PURCHASE_HISTORY = "CardPackagePurchaseHistory";

const CardPackagePurchaseHistory = (props: CardPackagePurchaseHistoryProps) => {
  // Effect
  useEffect(() => {
    return () => {
      props.setProp(`searchedItemListWithKey.${PACKAGE_SEARCH_PH}`, []);
    };
  }, []);

  useEffect(() => {
    const order = props.PackagePurchaseSequence?.selectedPackageOrder;
    const list = props.PackagePurchaseSequence?.purchaseHistoryList || [];

    const findOrder = list.find((item) => item.id === order?.id);

    if (!!order && !findOrder) {
      props.setProp("PackagePurchaseSequence.selectedPackageOrder", null);
    }
  }, [
    props.PackagePurchaseSequence?.purchaseHistoryList,
    props.PackagePurchaseSequence?.selectedPackageOrder,
  ]);

  // Use Callback
  const handleSelectedItem = useCallback(
    async (value: any, key: any) => {
      props.setProp(
        `PackagePurchaseSequence.filterPurchaseHistory.product`,
        value || null
      );
    },
    [props.searchedItemListWithKey]
  );

  const handleClickHistory = useCallback(
    async (data: Partial<HistoryDataType>) => {
      const packageId = data.package_id;
      const productId = data.product_id;

      const initialFilter = async (id: string, key: "package" | "product") => {
        await props.setProp(`searchedItemListWithKey.${id}`, [
          {
            id: data[`${key}_id`],
            name: data[`${key}_name`],
            code: data[`${key}_code`],
          },
        ]);

        return props.setProp(
          "PackagePurchaseSequence.filterAppointmentHistory",
          { [key]: data[`${key}_id`] }
        );
      };

      if (!!packageId) {
        await initialFilter(PACKAGE_SEARCH_AUH, "package");
      } else if (!!productId) {
        await initialFilter(PRODUCT_SEARCH_AUH, "product");
      }

      props.onOpenHistory?.();
    },
    []
  );

  // Use Memo
  const filterPurchaseHistory = useMemo(() => {
    return props.PackagePurchaseSequence?.filterPurchaseHistory || {};
  }, [props.PackagePurchaseSequence?.filterPurchaseHistory]);

  const serviceDivisionOptions = useMemo(() => {
    const divisions = (props.masterData?.packageServiceType || []).flatMap(
      (item) => item.divisions
    );

    const options = (props.masterOptions?.division || []).filter((option) =>
      divisions.includes(option.value)
    );

    if (!!props.selectedDivision?.id) {
      const id = props.selectedDivision.id;

      options.push({
        key: id,
        value: id,
        text: props.selectedDivision.name,
      });
    }

    return Array.from(
      new Map(options.map((item) => [item.value, item])).values()
    );
  }, [
    props.masterOptions?.division,
    props.masterOptions?.packageServiceType,
    props.selectedDivision,
  ]);

  const searchedItemListWithKey = useMemo(() => {
    return {
      ...props.searchedItemListWithKey,
      [PACKAGE_SEARCH_PH]: props.searchedItemListWithKey?.[
        PACKAGE_SEARCH_PH
      ]?.map((item: any) => ({
        ...item,
        name_code: `[${item.code}] ${item.name}`,
      })),
    };
  }, [props.searchedItemListWithKey]);

  const selectedPackageOrder = useMemo(() => {
    const order = props.PackagePurchaseSequence?.selectedPackageOrder;

    if (!!order) {
      order.items = order.items.map((item: any) => ({
        ...item,
        balance: Number(item.quantity_left),
      }));
    }
    return order;
  }, [props.PackagePurchaseSequence?.selectedPackageOrder]);

  const purchaseHistoryItems = useMemo(() => {
    return (props.PackagePurchaseSequence?.purchaseHistoryList || []).map(
      (item) => {
        return {
          ...item,
          date: formatDate(moment(item.paid_at)),
          price: <div style={GridCenter}>{item.price}</div>,
          payment: <div style={GridCenter}>{"ชำระแล้ว"}</div>,
          usage: (
            <div
              style={{
                ...GridCenter,
                color: props.disabledOrderIds?.includes(item.id || "")
                  ? "#BDBDBD"
                  : item.usage_status === "INCOMPLETED"
                  ? "#DA0000"
                  : "#1B9D2C",
              }}
            >
              {item.usage_status_label}
            </div>
          ),
          history: (
            <div style={GridCenter}>
              <Button
                icon="history"
                color="grey"
                size="mini"
                style={{ fontSize: "0.75rem" }}
                onClick={(e) => {
                  e.stopPropagation();

                  handleClickHistory({
                    package_id: item.product,
                    package_name: item.product_name,
                    package_code: item.product_code,
                  });
                }}
              />
            </div>
          ),
        };
      }
    );
  }, [
    props.PackagePurchaseSequence?.purchaseHistoryList,
    props.disabledOrderIds,
  ]);

  // Handler
  const handleChangeValue = async (e: any, data: any) => {
    let value = typeof data.value === "undefined" ? data.checked : data.value;

    const detail = {
      ...(props.PackagePurchaseSequence?.filterPurchaseHistory || {}),
    };

    (detail as any)[data.name] = value;

    await props.setProp(`PackagePurchaseSequence.filterPurchaseHistory`, {
      ...detail,
    });
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "PackagePurchase",
      action: "SEARCH",
      card: CARD_PACKAGE_PURCHASE_HISTORY,
    });
  };

  const handleClear = () => {
    props.setProp("PackagePurchaseSequence.filterPurchaseHistory", {});
  };

  const handleSelectRow = (rowInfo: any) => {
    const list = props.PackagePurchaseSequence?.purchaseHistoryList || [];
    const row: any =
      selectedPackageOrder?.id !== rowInfo?.original?.id
        ? list[rowInfo?.index || 0]
        : null;

    if (!!row) {
      props.runSequence({
        sequence: "PackagePurchase",
        action: "SELECT_PACKAGE_ORDER",
        card: CARD_PACKAGE_PURCHASE_HISTORY,
        orderId: row.id,
      });
    } else {
      props.setProp("PackagePurchaseSequence.selectedPackageOrder", null);
    }
  };

  const handleGetTrProps = (
    state: any,
    rowInfo?: RowInfo,
    column?: Column,
    instance?: any
  ) => {
    const disabled = props.disabledOrderIds?.includes(
      rowInfo?.original?.id || ""
    );

    return {
      onClick: () => {
        if (!disabled) {
          handleSelectRow(rowInfo);
        }
      },

      className:
        !!selectedPackageOrder?.id &&
        rowInfo?.original?.id === selectedPackageOrder?.id
          ? "blueSelectedRow"
          : "",
      style: {
        color: disabled ? "#BDBDBD" : "",
      },
    };
  };

  const handleSelectPackage = (data: any) => {
    props.onSelectPackage?.({
      ...selectedPackageOrder,
      items: data,
    });
  };

  console.log("CardPackagePurchaseHistoryUX", props);

  return (
    <div>
      <CardPackagePurchaseHistoryUX
        // data
        filterPurchaseHistory={filterPurchaseHistory}
        purchaseHistoryItems={purchaseHistoryItems}
        // options
        packageTypeOptions={props.masterOptions?.packageType}
        packageServiceTypeOptions={props.masterOptions?.packageServiceType}
        usageOptions={USAGE_OPTIONS}
        serviceDivisionOptions={serviceDivisionOptions}
        // config
        selectable={props.selectable}
        // callback
        onChangeValue={handleChangeValue}
        onClear={handleClear}
        // table
        getTrProps={handleGetTrProps}
        // Element
        SearchBoxPackageName={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="Package"
            id="PH"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            active={true}
            // Select
            searchedItemListWithKey={searchedItemListWithKey}
            selectedItem={filterPurchaseHistory.product || null}
            setSelectedItem={handleSelectedItem}
          />
        }
        // SearchBoxPackageType={
        //   <SearchBoxDropdown
        //     onEvent={props.onEvent}
        //     // config
        //     type="PackageType"
        //     id="1"
        //     style={{ width: "100%" }}
        //     fluid={true}
        //     useWithKey={true}
        //     icon="search"
        //     limit={20}
        //     // Select
        //     searchedItemListWithKey={props.searchedItemListWithKey}
        //     selectedItem={null}
        //     setSelectedItem={handleSelectedItem}
        //     // options
        //     mapOptions={mapPackageOptions}
        //   />
        // }
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={`${CARD_PACKAGE_PURCHASE_HISTORY}_${BUTTON_ACTIONS.search}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_PACKAGE_PURCHASE_HISTORY}_${BUTTON_ACTIONS.search}`
              ]
            }
            // config
            color={"blue"}
            name={BUTTON_ACTIONS.search}
            size="medium"
            title="ค้นหา"
          />
        }
      />

      {selectedPackageOrder?.usage_status === "INCOMPLETED" && (
        <CardAppointmentPackage
          data={selectedPackageOrder?.items}
          // config
          selectable={props.selectable}
          onSelect={handleSelectPackage}
          onHistory={handleClickHistory}
        />
      )}
    </div>
  );
};

export default React.memo(CardPackagePurchaseHistory);
