import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  FormField,
  FormGroup,
  Input,
  Modal,
  TextArea,
} from "semantic-ui-react";
// UI Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
// UI ANE
import CardAnesRecord01UX from "./CardAnesRecord01UX";
import CardAnesRecord02UX from "./CardAnesRecord02UX";
import CardANSLog from "react-lib/apps/ANS/CardANSLog";
//

const CARD_KEY: string = "CardAnestheticAssessmentRecord";
const FORM_CODE: string = "CardAnestheticAssessmentRecord";
const FORM_NAME: string = "CardAnestheticAssessmentRecord";
const FORM_VERSION: string = "1.0";

type CardAnesRecordProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  AnesRecordSequence?: any;
};

const CardAnesRecord = (props: CardAnesRecordProps) => {
  useEffect(() => {
    props.runSequence({
      sequence: "AnesRecord",
      action: "FETCH_ANESRECORD",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formVersion: FORM_VERSION,
    });
  }, []);

  const handleChangeData = (_event: any, data: any) => {
    if (data.type === "checkbox") {
      props.setProp(`AnesRecordSequence.data.${data.name}`, data.checked);
    } else {
      props.setProp(`AnesRecordSequence.data.${data.name}`, data.value);
    }
  };

  // -------------------- Modal Log
  const handleCloseModLog = () => {
    props.setProp("AnesRecordSequence.modLogInfo", {
      open: false,
      logList: [],
    });
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
      />

      <CardAnesRecord01UX
        AnesRecordSequence={props.AnesRecordSequence?.data}
        AnesRecordStatus={props.AnesRecordSequence?.status}
        masterOptions={props.masterOptions}
        onChangeData={handleChangeData}
        onChangeDateForm={(date: string) =>
          props.setProp("AnesRecordSequence.data.DateRamrk", date)
        }
        onChangeStartAnesTime={(value: string) =>
          props.setProp("AnesRecordSequence.data.StartAnesTime", value)
        }
        onChangeStartOpTime={(value: string) =>
          props.setProp("AnesRecordSequence.data.StartOpTime", value)
        }
        onChangeEndAnesTime={(value: string) =>
          props.setProp("AnesRecordSequence.data.EndAnesTime", value)
        }
        // component
        buttonLog={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "AnesRecord",
                action: "LOG",
                cardKey: CARD_KEY,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_LOG`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_LOG`]}
            // config
            color="orange"
            size="medium"
            style={{ width: "120px" }}
            title="Log"
          />
        }
        buttonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "AnesRecord",
                action: "PRINT",
                cardKey: CARD_KEY,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_PRINT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_PRINT`]}
            // config
            color="blue"
            size="medium"
            style={{ width: "120px" }}
            title="Print"
          />
        }
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "AnesRecord",
                action: "SAVE",
                cardKey: CARD_KEY,
                formCode: FORM_CODE,
                formName: FORM_NAME,
                formVersion: FORM_VERSION,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_SAVE`]}
            // config
            color={"orange"}
            size="medium"
            style={{ width: "120px" }}
            title={"Save Draft"}
          />
        }
        buttonConfirm={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "AnesRecord",
                action: "CONFIRM",
                cardKey: CARD_KEY,
                formCode: FORM_CODE,
                formName: FORM_NAME,
                formVersion: FORM_VERSION,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_CONFIRM`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_CONFIRM`]}
            // config
            color="green"
            size="medium"
            style={{ width: "120px" }}
            title="Confirm"
          />
        }
      />
      <CardAnesRecord02UX
        AnesRecordSequence={props.AnesRecordSequence?.data}
        masterOptions={props.masterOptions}
        onChangeData={handleChangeData}
        premedoication={
          <>
            {props.AnesRecordSequence?.data?.Premedication.map(
              (item: any, index: any) => {
                return (
                  <PremedoicationData
                    AnesRecordSequence={props.AnesRecordSequence}
                    masterOptions={props.masterOptions}
                    index={index}
                    item={item?.premedication}
                    setProp={props.setProp}
                  />
                );
              }
            )}
          </>
        }
      />

      <Modal
        open={props.AnesRecordSequence?.modLogInfo?.open}
        closeOnDimmerClick={true}
        onClose={handleCloseModLog}
      >
        <CardANSLog
          editDetail={true}
          dataList={props.AnesRecordSequence?.modLogInfo?.logList || []}
          hideCallback={handleCloseModLog}
        />
      </Modal>
    </>
  );
};

const PremedoicationData = (props: any) => {
  const handleChangePremedication = (e: any, data?: any) => {
    props.setProp(
      `AnesRecordSequence.data.Premedication.${props.index}.premedication`,
      data.value
    );
  };

  const handlerAddPremedication = () => {
    const Premedication = { premedication: "" };
    const items: any[] = props.AnesRecordSequence?.data?.Premedication || [];

    items.push(Premedication);
    props.setProp("AnesRecordSequence.data.Premedication", items);
  };

  const handlerDeletePremedication = () => {
    const items: any[] = props.AnesRecordSequence?.data?.Premedication || [];

    if (items?.length === 1) {
      props.setProp("AnesRecordSequence.data.Premedication.0", {
        ...(props.AnesRecordSequence?.data?.Premedication || []),
      });
    } else if (items.length > 1) {
      items.splice(props.index, 1);
      props.setProp("AnesRecordSequence.data.Premedication", items);
    }
  };

  return (
    <>
      <FormGroup inline={true}>
        <FormField inline={true} width={3}>
          {props.index === 0 && (
            <label
              style={{
                fontWeight: "bold",
                marginLeft: "10px",
                display: "flex",
                height: "40px",
                fontSize: "16px",
                alignItems: "center",
              }}
            >
              Premedication
            </label>
          )}
        </FormField>
        <FormField inline={true} width={13}>
          <Dropdown
            clearable={true}
            onChange={handleChangePremedication}
            options={props.masterOptions?.drugName}
            selection={true}
            search={true}
            value={props.item}
            style={{ width: "55%" }}
          ></Dropdown>
          <Button
            color="green"
            icon="plus"
            size="mini"
            style={{ marginRight: "10px" }}
            onClick={handlerAddPremedication}
          ></Button>
          <Button
            color="red"
            icon="minus"
            size="mini"
            onClick={handlerDeletePremedication}
          ></Button>
        </FormField>
      </FormGroup>
    </>
  );
};

export default React.memo(CardAnesRecord);
