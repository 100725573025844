import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  SyntheticEvent,
  CSSProperties,
  ChangeEvent,
} from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  Menu,
  MenuItemProps,
} from "semantic-ui-react";

import { Column, RowInfo } from "react-table-6";

// Common
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import { DateTextBox, ModConfirm, ModInfo } from "react-lib/apps/common";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// UX
import CardSettingPackageUX from "./CardSettingPackageUX";
import SubSearchPackage from "./SubSearchPackage";
import LoadingIcon from "./LoadingIcon";

// Interface
import {
  PRODUCT_TYPES,
  PackageDetailType,
  ProductDetailType,
  ProductTypeKey,
  RunSequence,
  State,
} from "./sequence/SettingPackage";

// Utils
import { beToAd } from "react-lib/utils/dateUtils";

// Config
import config from "../../../../config/config";

// Const c.
const API_HOST = `${config.API_HOST}`;

// Types
type CardSettingPackageProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: RunSequence;
  SettingPackageSequence?: State["SettingPackageSequence"];
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  searchedItemListWithKey?: Record<string, any>;
};

type PSTypeKey = keyof typeof PS_TYPES;

// Const
const TAB_PANS = [
  "ALL",
  "LAB",
  "IMAGING",
  "TREATMENT",
  "SUPPLY",
  "DRUG",
  "MISCELLANEOUS",
  "DOCTOR_FEE",
] as const;

const BUTTON_ACTIONS = {
  save: "SAVE",
  active: "ACTIVE",
  dup: "DUPLICATE",
  refresh: "REFRESH_OPTION",
};

const PS_TYPES = {
  PACKAGE_TYPE: "PACKAGE_TYPE",
  SERVICE_TYPE: "SERVICE_TYPE",
} as const;

const ADMINS = {
  [PS_TYPES.PACKAGE_TYPE]: "/admin/core/packagetype/",
  [PS_TYPES.SERVICE_TYPE]: "/admin/core/packageservicetype/",
};

const GridCenter = { display: "grid", placeContent: "center" } as CSSProperties;

export const CARD_SETTING_PACKAGE = "CardSettingPackage";

const CardSettingPackage = (props: CardSettingPackageProps) => {
  const [activeItem, setActiveItem] = useState<ProductTypeKey | "ALL">("ALL");
  const [isNewProduct, setIsNewProduct] = useState(false);
  const [productType, setProductType] = useState<ProductTypeKey | "">("");
  // Mod
  const [openModProductType, setOpenModProductType] = useState<boolean>(false);
  const [openModDuplicate, setOpenModDuplicate] = useState<boolean>(false);
  // data
  const [packageName, setPackageName] = useState<string>("");
  const [packageCode, setPackageCode] = useState<string>("");

  const newWindowRef = useRef<any>({});

  // Use Effect
  useEffect(() => {
    props.runSequence({
      sequence: "SettingPackage",
      restart: true,
    });
  }, []);

  useEffect(() => {
    if (
      !props.SettingPackageSequence?.showDetail &&
      Object.keys(props.SettingPackageSequence?.rawPackageDetail || {}).length >
        0
    ) {
      props.setProp("SettingPackageSequence.rawPackageDetail", {});
    }
  }, [
    props.SettingPackageSequence?.showDetail,
    props.SettingPackageSequence?.rawPackageDetail,
  ]);

  useEffect(() => {
    setIsNewProduct(false);
  }, [props.SettingPackageSequence?.packageDetail?.id]);

  // Memo CAllback
  const productList = useMemo(() => {
    return props.SettingPackageSequence?.packageDetail?.items || [];
  }, [props.SettingPackageSequence?.packageDetail?.items]);

  // Use Callback
  const handleEditProduct = useCallback(
    (item: ProductDetailType) => (e: any, data: any) => {
      const list = [...productList];
      let value = typeof data.checked === "boolean" ? data.checked : data.value;
      const name = data.name as keyof ProductDetailType;

      // type number value ไม่น้อยกว่า 0
      if (["quantity"].includes(name)) {
        value = Number(value) < 0 ? 0 : value;

        const unitPrice = list[item.index]["unit_price"];

        (list as any)[item.index]["price"] = Number(value) * Number(unitPrice);
      }

      (list as any)[item.index][name] = value;

      props.setProp("SettingPackageSequence.packageDetail.items", [...list]);
    },
    [productList]
  );

  const handleDeleteProduct = useCallback(
    (item: ProductDetailType) => (e: any, data: any) => {
      const list = [...productList];

      if (item.id) {
        list[item.index].deleted = true;
      } else {
        list.splice(item.index, 1);
      }

      props.setProp("SettingPackageSequence.packageDetail.items", [...list]);
    },
    [productList]
  );

  const handleInputBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.valueAsNumber;

      if (["age_limits_year_start", "age_limits_year_end"].includes(name)) {
        const detail = props.SettingPackageSequence?.packageDetail || {};
        const start = detail.age_limits_year_start || "";
        const end = detail.age_limits_year_end || "";

        let isError = false;
        // หากระบุ year start มากกว่า year end หรือ year end น้อยกว่า year start ให้แสดงข้อความแจ้งเตือน
        if (
          (name === "age_limits_year_start" &&
            value > Number(end) &&
            end !== "") ||
          (name === "age_limits_year_end" &&
            value < Number(start) &&
            start !== "")
        ) {
          isError = true;
        } else {
          isError = false;
        }

        props.setProp(
          "SettingPackageSequence.showRequiredField.age_range",
          isError
        );
      }
    },
    [props.SettingPackageSequence?.packageDetail]
  );

  const summary = useCallback((list: any[]) => {
    return !!list.length
      ? list.reduce((result, item) => result + Number(item.price), 0).toFixed(2)
      : "";
  }, []);

  const handleSelectedItem = useCallback(
    (data: { id: string }) => async (value: any, key: any, obj: any) => {
      if (!!productType && !!value) {
        props.runSequence({
          sequence: "SettingPackage",
          action: "ADD_PRODUCT",
          type: productType,
          productId: value,
        });
      }
    },
    [props.searchedItemListWithKey, productType]
  );

  // Use Memo
  const filterProductListByType = useMemo(() => {
    return productList.filter((item) => {
      const byType =
        activeItem === "ALL" ? true : activeItem === item.package_group_code;

      return byType && !item.deleted;
    });
  }, [productList, activeItem]);

  const countProductList = useMemo(() => {
    const result = productList.reduce((result, item) => {
      if (item.package_group_code in result) {
        result[item.package_group_code] += 1;
      } else {
        result[item.package_group_code] = 1;
      }
      return result;
    }, {} as Record<ProductTypeKey, number>);

    return {
      ...result,
      ALL: Object.values(result).reduce(
        (result: any, value: number) => result + value,
        0
      ) as number,
    };
  }, [productList, activeItem]);

  const productTypeOptions = useMemo(() => {
    return Object.entries(PRODUCT_TYPES).map(([key, text]) => ({
      key,
      value: key,
      text,
    }));
  }, []);

  const isActive = useMemo(() => {
    return (
      // props.SettingPackageSequence?.packageDetail?.active_flag === 1 &&
      !props.SettingPackageSequence?.packageDetail?.can_delete
    );
  }, [props.SettingPackageSequence?.packageDetail]);

  const priceTotal = useMemo(() => {
    return summary(productList);
  }, [productList]);

  const priceTotalProduct = useMemo(() => {
    return summary(filterProductListByType);
  }, [filterProductListByType]);

  const errorMsgLabel = useMemo(() => {
    const error = props.errorMessage?.[CARD_SETTING_PACKAGE];

    const label = {
      code: "Code รหัสแพกเกจ",
      name: "Package name ชื่อแพกเกจ",
      package_type: "Package type ชนิดแพกเกจ",
      package_service_type: "Service type ประเภทบริการ",
      start_date: "Start_date",
      end_date: "End_date",
      selling_price: "Package pricing ราคาขายของสินค้านี้",
    } as any;

    return !error
      ? null
      : Object.entries(error).reduce(
          (result, [key, value]) => ({
            ...result,
            [label[key] || key]: value,
          }),
          {}
        );
  }, [props.errorMessage]);

  const productItems = useMemo(() => {
    return filterProductListByType.map((item, index) => ({
      ...item,
      price: <div style={{ textAlign: "center" }}>{item.unit_price}</div>,
      quantity: (
        <Input
          value={item.quantity}
          type="number"
          name="quantity"
          disabled={isActive}
          onChange={handleEditProduct(item)}
        />
      ),
      is_display: (
        <div style={GridCenter}>
          <Checkbox
            checked={item.is_display}
            name="is_display"
            disabled={isActive}
            onChange={handleEditProduct(item)}
          />
        </div>
      ),
      delete: (
        <div style={GridCenter}>
          <Button
            icon="minus"
            circular={true}
            size="mini"
            color="red"
            disabled={isActive}
            style={{ fontSize: "0.65rem" }}
            onClick={handleDeleteProduct(item)}
          />
        </div>
      ),
    }));
  }, [filterProductListByType, isActive]);

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    let value = typeof data.checked === "boolean" ? data.checked : data.value;

    const detail = { ...(props.SettingPackageSequence?.packageDetail || {}) };
    const numberKey: (keyof PackageDetailType)[] = [
      "age_limits_year_start",
      "age_limits_year_end",
      "selling_price",
    ];

    // type number value ไม่น้อยกว่า 0
    if (numberKey.includes(data.name)) {
      value = Number(value) < 0 ? 0 : value;
    }

    (detail as any)[data.name] = value;

    props.setProp(`SettingPackageSequence.packageDetail`, { ...detail });
  };

  const handleChangeTab = (_e: any, data: any) => {
    setActiveItem(data.name);
  };

  const handleAddNewProduct = async () => {
    if (activeItem === "ALL") {
      setProductType("LAB");
      setOpenModProductType(true);
    } else {
      await props.setProp(`searchedItemListWithKey.${activeItem}_name`, []);
      props.setProp(`searchedItemListWithKey.${activeItem}_code`, []);

      setProductType(activeItem);
      setIsNewProduct(true);
    }
  };

  const handleAddSuccess = () => {
    if (!!productType) {
      // setActiveItem(productType);
      handleCloseModProductType();
    }
  };

  const handleChangeDate =
    (name: keyof PackageDetailType) => async (date: string) => {
      if (!!date && date.length !== 10) {
        return;
      }

      if (name === "start_date") {
        const start = beToAd(date);
        const end = beToAd(
          props.SettingPackageSequence?.packageDetail?.end_date || ""
        );
        const diff = start?.diff(end, "days") || 0;

        // clear end date หาก start date มากกว่า end date
        if (start && end && diff >= 1) {
          await props.setProp(
            `SettingPackageSequence.packageDetail.end_date`,
            ""
          );
        }
      }

      handleChangeValue(null, { name, value: date });
    };

  const handleChangeProductType = (e: any, data: any) => {
    setProductType(data.value);
  };

  const handleCloseModProductType = () => {
    setIsNewProduct(false);
    setOpenModProductType(false);
    setProductType("");
  };

  const handleConfirmProductType = async () => {
    await props.setProp(`searchedItemListWithKey.${productType}_name`, []);
    props.setProp(`searchedItemListWithKey.${productType}_code`, []);

    setIsNewProduct(true);
    setOpenModProductType(false);
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "SettingPackage",
      action: "SAVE",
      card: CARD_SETTING_PACKAGE,
      data: props.SettingPackageSequence?.packageDetail || {},
      onSuccess: handleCloseModProductType,
    });
  };

  const handleActive = () => {
    props.runSequence({
      sequence: "SettingPackage",
      action: "ACTIVE",
      card: CARD_SETTING_PACKAGE,
      data: props.SettingPackageSequence?.rawPackageDetail || {},
      onSuccess: handleCloseModProductType,
    });
  };

  const handleOpenModDuplicate = () => {
    setPackageName(
      `Copy of Package ${
        props.SettingPackageSequence?.packageDetail?.name || ""
      } (1)`
    );
    setOpenModDuplicate(true);
  };

  const handleConfirmDuplicate = () => {
    props.runSequence({
      sequence: "SettingPackage",
      action: "DUPLICATE",
      card: CARD_SETTING_PACKAGE,
      code: packageCode,
      name: packageName,
      onSuccess: handleCloseModDuplicate,
    });
  };

  const handleCloseModDuplicate = () => {
    setOpenModDuplicate(false);
    setPackageCode("");
    setPackageName("");
  };

  const handleCloseModInfo = () => {
    props.setProp(
      `errorMessage.${CARD_SETTING_PACKAGE}_${BUTTON_ACTIONS.dup}`,
      null
    );
  };

  const handleEventSearch = (data: any) => {
    props.runSequence({
      sequence: "SettingPackage",
      action: "SEARCH_PRODUCT",
      data: data.params,
    });
  };

  const handleOpenAdmin = (type: PSTypeKey) => {
    if (isActive) {
      return;
    }

    newWindowRef.current[type]?.close();

    newWindowRef.current[type] = globalThis.open(
      `${API_HOST}${(ADMINS as any)[type]}`,
      "_blank"
    );
  };

  const handleRefreshOptionType = (type: PSTypeKey) => () => {
    if (isActive) {
      return;
    }

    props.runSequence({
      sequence: "SettingPackage",
      action: "REFRESH_OPTION",
      type,
      card: CARD_SETTING_PACKAGE,
    });
  };

  const handleGetTrProps = (
    state: any,
    rowInfo?: RowInfo,
    column?: Column,
    instance?: any
  ) => {
    return {
      className: isActive ? "--readOnly" : "",
    };
  };

  console.log("CardSettingPackage", props);

  return (
    <div
      style={{
        height: "calc(100vh - 3rem)",
        overflow: "auto",
        paddingBottom: "3rem",
        width: "calc(100vw - 10.5rem)",
      }}
    >
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_SETTING_PACKAGE}`, null);
        }}
        error={errorMsgLabel}
        success={null}
      />

      <CardSettingPackageUX
        // data
        packageDetail={props.SettingPackageSequence?.packageDetail}
        productType={productType}
        productItems={productItems}
        priceTotal={priceTotal}
        priceTotalProduct={priceTotalProduct}
        showPriceTotalProduct={activeItem !== "ALL"}
        isNewProduct={isNewProduct}
        isActive={isActive}
        hideDetail={!props.SettingPackageSequence?.showDetail}
        // options
        packageTypeOptions={props.SettingPackageSequence?.packageTypeOptions}
        serviceTypeOptions={props.SettingPackageSequence?.serviceTypeOptions}
        genderOptions={props.SettingPackageSequence?.genderOptions}
        // config
        disabledAddNewProduct={isActive}
        showAgeError={
          !!props.SettingPackageSequence?.showRequiredField?.age_range
        }
        showRequiredField={props.SettingPackageSequence?.showRequiredField}
        // callback
        onAddNewProduct={handleAddNewProduct}
        onAddSuccess={handleAddSuccess}
        onChangeValue={handleChangeValue}
        onClickDuplicate={handleOpenModDuplicate}
        onOpenAdmin={handleOpenAdmin}
        onRefreshOptionType={handleRefreshOptionType}
        getTrProps={handleGetTrProps}
        // Element
        SubSearchPackage={
          <SubSearchPackage
            setProp={props.setProp}
            onEvent={props.onEvent}
            // seq
            runSequence={props.runSequence}
            SettingPackageSequence={props.SettingPackageSequence}
            // CommonInterface
            // CommonInterface
            buttonLoadCheck={props.buttonLoadCheck}
            searchedItemListWithKey={props.searchedItemListWithKey}
            errorMessage={props.errorMessage}
          />
        }
        StartDate={
          <DateTextBox
            value={
              props.SettingPackageSequence?.packageDetail?.start_date || ""
            }
            inputFluid={true}
            inputStyle={{ width: "100%" }}
            onChange={handleChangeDate("start_date")}
          />
        }
        EndDate={
          <DateTextBox
            value={props.SettingPackageSequence?.packageDetail?.end_date || ""}
            inputFluid={true}
            minDate={
              props.SettingPackageSequence?.packageDetail?.start_date || ""
            }
            inputStyle={{ width: "100%" }}
            onChange={handleChangeDate("end_date")}
          />
        }
        TabPans={
          <TabPan
            activeItem={activeItem}
            countProductList={countProductList}
            // config
            disabled={isNewProduct}
            productType={productType}
            onChangeTab={handleChangeTab}
          />
        }
        SearchBoxPCode={
          <SearchBoxDropdown
            key="SearchBoxPCode"
            onEvent={handleEventSearch}
            // config
            type={productType}
            id="code"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={
              props.SettingPackageSequence?.selectedProduct?.code || null
            }
            setSelectedItem={handleSelectedItem({ id: "code" })}
          />
        }
        SearchBoxPName={
          <SearchBoxDropdown
            key="SearchBoxPName"
            onEvent={handleEventSearch}
            // config
            type={productType}
            id="name"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={
              props.SettingPackageSequence?.selectedProduct?.name || null
            }
            setSelectedItem={handleSelectedItem({ id: "name" })}
          />
        }
        ButtonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={`${CARD_SETTING_PACKAGE}_${BUTTON_ACTIONS.save}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_SETTING_PACKAGE}_${BUTTON_ACTIONS.save}`
              ]
            }
            // config
            disabled={
              !!props.SettingPackageSequence?.showRequiredField?.age_range
            }
            color={"green"}
            name={BUTTON_ACTIONS.save}
            size="medium"
            title="SAVE"
          />
        }
        ButtonActive={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleActive}
            // data
            paramKey={`${CARD_SETTING_PACKAGE}_${BUTTON_ACTIONS.active}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_SETTING_PACKAGE}_${BUTTON_ACTIONS.active}`
              ]
            }
            // config
            disabled={!props.SettingPackageSequence?.packageDetail?.id}
            color={
              !!props.SettingPackageSequence?.packageDetail?.active
                ? "red"
                : "blue"
            }
            name={BUTTON_ACTIONS.active}
            size="medium"
            title={
              !!props.SettingPackageSequence?.packageDetail?.active
                ? "INACTIVE"
                : "ACTIVE"
            }
          />
        }
        PackageRefreshIcon={
          <LoadingIcon
            name="undo"
            loading={
              !!props.buttonLoadCheck?.[
                `${CARD_SETTING_PACKAGE}_${BUTTON_ACTIONS.refresh}_${PS_TYPES.PACKAGE_TYPE}`
              ]
            }
            onClick={handleRefreshOptionType(PS_TYPES.PACKAGE_TYPE)}
          />
        }
        ServiceRefreshIcon={
          <LoadingIcon
            name="undo"
            loading={
              !!props.buttonLoadCheck?.[
                `${CARD_SETTING_PACKAGE}_${BUTTON_ACTIONS.refresh}_${PS_TYPES.SERVICE_TYPE}`
              ]
            }
            onClick={handleRefreshOptionType(PS_TYPES.SERVICE_TYPE)}
          />
        }
      />

      <ModConfirm
        openModal={openModProductType}
        titleName="โปรดระบุประเภทสินค้า ที่ต้องการเพิ่ม"
        titleColor="blue"
        size="mini"
        denyButtonColor="red"
        denyButtonText="ยกเลิก"
        approveButtonColor="green"
        approveButtonText="ตกลง"
        content={
          <div
            style={{
              margin: "0rem 1.5rem -1rem 0rem",
            }}
          >
            <Dropdown
              value={productType}
              options={productTypeOptions}
              selection={true}
              fluid={true}
              style={{ marginLeft: "10px", width: "100%" }}
              onChange={handleChangeProductType}
            />
          </div>
        }
        onApprove={handleConfirmProductType}
        onDeny={handleCloseModProductType}
        onCloseWithDimmerClick={handleCloseModProductType}
      />

      <ModConfirm
        openModal={openModDuplicate}
        titleName="ยืนยันการคัดลอกแพกเกจ โปรดระบุข้อมูลดังนี้"
        titleColor="blue"
        size="small"
        denyButtonColor="red"
        denyButtonText="ยกเลิก"
        modalStyle={{ width: "40%" }}
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirmDuplicate}
            // data
            paramKey={`${CARD_SETTING_PACKAGE}_${BUTTON_ACTIONS.dup}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_SETTING_PACKAGE}_${BUTTON_ACTIONS.dup}`
              ]
            }
            // config
            disabled={!packageCode}
            color={"green"}
            name={BUTTON_ACTIONS.dup}
            size="medium"
            title="ตกลง"
            basic={true}
            fluid={true}
          />
        }
        content={
          <div
            style={{
              margin: "0.5rem 0rem -1.5rem 0.5rem",
            }}
          >
            {/* @ts-ignore */}
            <Form>
              <Form.Group inline style={{ marginBottom: "1.5rem" }}>
                <Form.Field inline width={3}>
                  <label>รหัสแพกเกจ</label>
                </Form.Field>
                <Form.Field inline width={13}>
                  <Input
                    value={packageCode}
                    onChange={(e, d) => setPackageCode(d.value)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field inline width={3}>
                  <label>ชื่อแพกเกจ</label>
                </Form.Field>
                <Form.Field inline width={13}>
                  <Input
                    value={packageName}
                    onChange={(e, d) => setPackageName(d.value)}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </div>
        }
        onDeny={handleCloseModDuplicate}
        onCloseWithDimmerClick={handleCloseModDuplicate}
      />

      <ModInfo
        open={
          props.errorMessage?.[
            `${CARD_SETTING_PACKAGE}_${BUTTON_ACTIONS.dup}`
          ] === "DUPLICATE_PACKAGE"
        }
        titleColor={"red"}
        titleName="แจ้งเตือน!"
        btnText="ปิด"
        buttonColor="blue"
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        <div
          style={{
            padding: "0 0 0.25rem",
            fontWeight: "bold",
            lineHeight: 2.5,
          }}
        >
          <div>รหัสแพกเกจ {packageCode} ถูกสร้างในระบบแล้ว</div>
          <div>โปรดระบุข้อมูลรหัสแพกเกจใหม่</div>
        </div>
      </ModInfo>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                         TabPan                         */

/* ------------------------------------------------------ */
type TabPanProps = {
  activeItem: ProductTypeKey | "ALL";
  countProductList: Record<ProductTypeKey | "ALL", number>;
  productType: ProductTypeKey | "";
  // config
  disabled?: boolean;
  // callback
  onChangeTab: (event: SyntheticEvent, data: MenuItemProps) => void;
};

const TabPan = (props: TabPanProps) => {
  return (
    <Menu tabular>
      {TAB_PANS.map((menu, index) => (
        <Menu.Item
          key={"menu-" + index}
          active={props.activeItem === menu}
          name={menu}
          disabled={
            props.disabled && menu !== props.productType && menu !== "ALL"
          }
          // style
          style={
            props.activeItem === menu
              ? {
                  color: "#0370B7",
                }
              : {}
          }
          // callback
          onClick={props.onChangeTab}
        >
          {menu.replace("_", " ")}
          {!!props.countProductList[menu] && (
            <span
              style={{
                position: "absolute",
                fontSize: "0.7rem",
                right: 5,
                bottom: 5,
                color: "rgba(0,0,0,.95)",
              }}
            >
              ({props.countProductList[menu]})
            </span>
          )}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default React.memo(CardSettingPackage);
