import WasmController from "../../../../frameworks/WasmController";
// APIs
import AnesthesiaDataList from "issara-sdk/apis/AnesthesiaDataList_apps_ANS";
import ANSFormPrint from "issara-sdk/apis/ANSFormPrint_apps_ANS";
import PreAnestheticLogList from "issara-sdk/apis/PreAnestheticLogList_apps_ANS";
import PreAnestheticRecordDetail from "issara-sdk/apis/PreAnestheticRecordDetail_apps_ANS";
import PreAnestheticRecordList from "issara-sdk/apis/PreAnestheticRecordList_apps_ANS";
import PreAnestheticRecordByPatientDetail from "issara-sdk/apis/PreAnestheticRecordByPatientDetail_apps_ANS";
import UserTokenizeLicenseView from "issara-sdk/apis/UserTokenizeLicenseView_users";
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import MeasurementList from "issara-sdk/apis/MeasurementList_apps_PTM";
import UserEmployeeDetailAPIView from "issara-sdk/apis/UserEmployeeDetailAPIView_users";
import OperatingOrderDetail from "issara-sdk/apis/OperatingOrderDetail_apps_ORM";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import moment from "moment";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // ORQueueI
  selectedOrOrder?: any;

  // sequence
  PreAnestheticSequence?: {
    sequenceIndex?: string | null;

    //FormID
    preAnestheticHistoryID?: string;
    preAnestheticAirwayID?: string;
    preAnestheticBloodID?: string;
    preAnestheticProblemsID?: string;
    preAnestheticRiskID?: string;
    preAnestheticReEvaluationRespID?: string;
    id?: number | null;
    data?: {
      // CardPreAnestheticFormUX
      PreanestheticAssessmentAt?: string;
      PreanestheticAssessmentAtRemark?: string;
      BodyWeight?: string;
      Height?: string;
      BMI?: string;
      BP?: string;
      PsychologicalStatus?: string;
      PsychologicalStatusRemark?: string;
      Pulse?: string;
      RR?: string;
      PsychologicalStatusTemp?: string;
      O2SatRA?: string;
      DX?: string;
      ProposedOperation?: string;
      Surgeon?: string;
      DateTime?: string;
      Anesthesiologist?: string;
      AnesthesiologistLicenseNumber?: string;
      AnesthesiologistCode?: string;
      Anesthetist?: string;
      AnesthetistLicenseNumber?: string;
      AnesthetistCode?: string;
      // CardPreAnestheticHistoryUX
      PreviousAnesthesia?: string;
      PreviousAnesthesiaRemark?: string;
      Complication?: string;
      ComplicationRemark?: string;
      KnownDisease?: string;
      KnownDiseaseRemark?: string;
      FamilyHistory?: string;
      FamilyHistoryRemark?: string;
      CurrentMedications?: string;
      CurrentMedicationsRemark?: string;
      HabitIsNon?: boolean;
      HabitAlcohol?: boolean;
      HabitAlcoholRemark?: string;
      HabitSmoking?: boolean;
      HabitSmokingRemark?: string;
      HabitDrugAbuse?: boolean;
      HabitDrugAbuseRemark?: string;
      HabitHerbSupplement?: boolean;
      HabitHerbSupplementRemark?: string;
      FunctionalClass?: string;
      FunctionalLMP?: string;
      FunctionalIsContactLens?: string;
      // CardPreAnestheticAirwayEvaluationUX
      MallampatiGrade?: string;
      ThyromentalDistance?: string;
      MouthOpening?: string;
      PatencyOfNares?: string;
      ProminentIncisor?: string;
      LimitationOfHeadNeckMotility?: string;
      DifficultIntubationExpected?: string;
      Comment?: string;
      ArtificialAirway?: string;
      ArtificialAirwayIsCommentTube?: string;
      Dental?: string;
      Respiratory?: string;
      RespiratoryRemark?: string;
      Cardiovascular?: string;
      CardiovascularRemark?: string;
      Neurologic?: string;
      NeurologicRemark?: string;
      EENT?: string;
      EENTRemark?: string;
      GI?: string;
      GIRemark?: string;
      Genitourinary?: string;
      GenitourinaryRemark?: string;
      EndocrineAndMetabolic?: string;
      EndocrineAndMetabolicRemark?: string;
      SkinBreast?: string;
      SkinBreastRemark?: string;
      ImmunologyHematology?: string;
      ImmunologyHematologyRemark?: string;
      // CardPreAnestheticBloodChemistryUX
      HbTime?: string;
      HcTime?: string;
      PltTime?: string;
      WBCTime?: string;
      PTTime?: string;
      PTTTime?: string;
      INRTime?: string;
      NaTime?: string;
      KTime?: string;
      ClTime?: string;
      CO2Time?: string;
      BunTime?: string;
      CrTime?: string;
      GFRTime?: string;
      BSTIME?: string;
      ASTTime?: string;
      ALTTime?: string;
      AlpTime?: string;
      AlbTime?: string;
      OthersTime?: string;
      CXR?: string;
      EKG?: string;
      Imaging?: string;
      // CardPreAnestheticProblemsConsiderationsUX
      AnestheticProblemsConsiderationsRemark?: string;
      IsEmergency?: boolean;
      IsAnestheticInformedConsent?: boolean;
      NPO?: string;
      AnestheicPlan?: string;
      AlternativeOfAnesthesiaDiscussed?: string;
      PremedicationNone?: boolean;
      PremedicationNoneRemark?: any;
      BloodPrepared?: string;
      BloodPreparedStatus?: boolean;
      PlanOfPostoperativeCare?: string;
      AmbulatoryPatientWithCompanion?: string;
      PlanOfPostoperativePainManagement?: string;
      PlanOfPostoperativePainManagementRemark?: string;
      // CardPreAnestheticRiskUX
      SoreThroat?: boolean;
      Hoarseness?: boolean;
      Nausea?: boolean;
      Vomiting?: boolean;
      Pruritus?: boolean;
      UrinaryRetention?: boolean;
      VentilatorySupport?: boolean;
      RiskOfAnesthesiaIncludedOther?: boolean;
      RiskOfAnesthesiaIncludedOtherRemark?: string;
      RiskOfAnesthesiaIncludedComment?: string;
      // CardPreAnestheticReEvaluationUX
      PreanestheticReEvaluationNoneDueto?: boolean;
      PreanestheticReEvaluationIsNoneDueto?: string;
      PREANESTHETICREEVALUATIONDateTime?: string;
      PREANESTHETICREEVALUATIONDate?: string;
      PREANESTHETICREEVALUATIONTime?: string;
      PREANESTHETICREEVALUATIONBodyWeight?: string;
      PREANESTHETICREEVALUATIONBP?: string;
      PreanestheticReEvaluationIsPR?: string;
      PreanestheticReEvaluationIsRR?: string;
      PreanestheticReEvaluationTemp?: string;
      PreanestheticReEvaluationLMP?: string;
      PreanestheticReEvaluationProposedOperation?: string;
      IsChangeInClinicalCondition?: string;
      ChangeInClinicalConditionRemark?: string;
      IsChangeInCurrentMedication?: string;
      ChangeInCurrentMedicationRemark?: string;
      IsNewDiseaseIllness?: string;
      NewDiseaseIllnessRemark?: string;
      AssessmentIsStableClinicalCondition?: boolean;
      AssessmentIsUnstableDueTo?: boolean;
      AssessmentUnstableDueToRemark?: string;
      PlanIsGoOnAnesthesiaAsPlan?: boolean;
      PlanIsOther?: boolean;
      PlanOtherRemark?: string;
      PreanestheticReEvaluationAnesthetsiologisAnesthetist?: string;
      PreanestheticReEvaluationAnesthetsiologisAnesthetistLicenseNumber?: string;
      PreanestheticReEvaluationAnesthetsiologisAnesthetistCode?: string;
    };
    status_label?: string;
    modLogInfo?: { open: boolean; logList: any[] };
  } | null;
};

export const StateInitial: State = {
  PreAnestheticSequence: {
    sequenceIndex: null,

    //FormID
    preAnestheticHistoryID: "",
    preAnestheticAirwayID: "",
    preAnestheticBloodID: "",
    preAnestheticProblemsID: "",
    preAnestheticRiskID: "",
    preAnestheticReEvaluationRespID: "",
    id: null,
    data: {
      // CardPreAnestheticFormUX
      PreanestheticAssessmentAt: "",
      PreanestheticAssessmentAtRemark: "",
      BodyWeight: "",
      Height: "",
      BMI: "",
      BP: "",
      PsychologicalStatus: "",
      PsychologicalStatusRemark: "",
      Pulse: "",
      RR: "",
      PsychologicalStatusTemp: "",
      O2SatRA: "",
      DX: "",
      ProposedOperation: "",
      Surgeon: "",
      DateTime: "",
      Anesthesiologist: "",
      AnesthesiologistLicenseNumber: "",
      AnesthesiologistCode: "",
      Anesthetist: "",
      AnesthetistLicenseNumber: "",
      AnesthetistCode: "",
      // CardPreAnestheticHistoryUX
      PreviousAnesthesia: "",
      PreviousAnesthesiaRemark: "",
      Complication: "",
      ComplicationRemark: "",
      KnownDisease: "",
      KnownDiseaseRemark: "",
      FamilyHistory: "",
      FamilyHistoryRemark: "",
      CurrentMedications: "",
      CurrentMedicationsRemark: "",
      HabitIsNon: false,
      HabitAlcohol: false,
      HabitAlcoholRemark: "",
      HabitSmoking: false,
      HabitSmokingRemark: "",
      HabitDrugAbuse: false,
      HabitDrugAbuseRemark: "",
      HabitHerbSupplement: false,
      HabitHerbSupplementRemark: "",
      FunctionalClass: "",
      FunctionalLMP: "",
      FunctionalIsContactLens: "",
      // CardPreAnestheticAirwayEvaluationUX
      MallampatiGrade: "",
      ThyromentalDistance: "",
      MouthOpening: "",
      PatencyOfNares: "",
      ProminentIncisor: "",
      LimitationOfHeadNeckMotility: "",
      DifficultIntubationExpected: "",
      Comment: "",
      ArtificialAirway: "none",
      ArtificialAirwayIsCommentTube: "",
      Dental: "",
      Respiratory: "",
      RespiratoryRemark: "",
      Cardiovascular: "",
      CardiovascularRemark: "",
      Neurologic: "",
      NeurologicRemark: "",
      EENT: "",
      EENTRemark: "",
      GI: "",
      GIRemark: "",
      Genitourinary: "",
      GenitourinaryRemark: "",
      EndocrineAndMetabolic: "",
      EndocrineAndMetabolicRemark: "",
      SkinBreast: "",
      SkinBreastRemark: "",
      ImmunologyHematology: "",
      ImmunologyHematologyRemark: "",
      // CardPreAnestheticBloodChemistryUX
      HbTime: "",
      HcTime: "",
      PltTime: "",
      WBCTime: "",
      PTTime: "",
      PTTTime: "",
      INRTime: "",
      NaTime: "",
      KTime: "",
      ClTime: "",
      CO2Time: "",
      BunTime: "",
      CrTime: "",
      GFRTime: "",
      BSTIME: "",
      ASTTime: "",
      ALTTime: "",
      AlpTime: "",
      AlbTime: "",
      OthersTime: "",
      CXR: "",
      EKG: "",
      Imaging: "",
      // CardPreAnestheticProblemsConsiderationsUX
      AnestheticProblemsConsiderationsRemark: "",
      IsEmergency: false,
      IsAnestheticInformedConsent: false,
      NPO: "",
      AnestheicPlan: "",
      AlternativeOfAnesthesiaDiscussed: "",
      PremedicationNone: false,
      PremedicationNoneRemark: [],
      BloodPrepared: "",
      BloodPreparedStatus: false,
      PlanOfPostoperativeCare: "",
      AmbulatoryPatientWithCompanion: "",
      PlanOfPostoperativePainManagement: "",
      PlanOfPostoperativePainManagementRemark: "",
      // CardPreAnestheticRiskUX
      SoreThroat: false,
      Hoarseness: false,
      Nausea: false,
      Vomiting: false,
      Pruritus: false,
      UrinaryRetention: false,
      VentilatorySupport: false,
      RiskOfAnesthesiaIncludedOther: false,
      RiskOfAnesthesiaIncludedOtherRemark: "",
      RiskOfAnesthesiaIncludedComment: "",
      // CardPreAnestheticReEvaluationUX
      PreanestheticReEvaluationNoneDueto: false,
      PreanestheticReEvaluationIsNoneDueto: "",
      PREANESTHETICREEVALUATIONDateTime: "",
      PREANESTHETICREEVALUATIONDate: "",
      PREANESTHETICREEVALUATIONTime: "",
      PREANESTHETICREEVALUATIONBodyWeight: "",
      PREANESTHETICREEVALUATIONBP: "",
      PreanestheticReEvaluationIsPR: "",
      PreanestheticReEvaluationIsRR: "",
      PreanestheticReEvaluationTemp: "",
      PreanestheticReEvaluationLMP: "",
      PreanestheticReEvaluationProposedOperation: "",
      IsChangeInClinicalCondition: "",
      ChangeInClinicalConditionRemark: "",
      IsChangeInCurrentMedication: "",
      ChangeInCurrentMedicationRemark: "",
      IsNewDiseaseIllness: "",
      NewDiseaseIllnessRemark: "",
      AssessmentIsStableClinicalCondition: false,
      AssessmentIsUnstableDueTo: false,
      AssessmentUnstableDueToRemark: "",
      PlanIsGoOnAnesthesiaAsPlan: false,
      PlanIsOther: false,
      PlanOtherRemark: "",
      PreanestheticReEvaluationAnesthetsiologisAnesthetist: "",
      PreanestheticReEvaluationAnesthetsiologisAnesthetistLicenseNumber: "",
      PreanestheticReEvaluationAnesthetsiologisAnesthetistCode: "",
    },
    status_label: "",
    modLogInfo: { open: false, logList: [] },
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const FORM_DATA_ACTION: any = {
  SAVE: "SAVE",
  CONFIRM: "CONFIRM",
};

export const GetMaster: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        // CardPreAnestheticFormUX
        ["drugName", {}],
        ["preanes_assessment_at", {}],
        ["preanes_psychological_status", {}],
        ["doctor", {}],
        ["divisionWard", {}],
        // CardPreAnestheticHistoryUX
        ["preanes_previous", {}],
        ["preanes_complication", {}],
        ["preanes_known_disease", {}],
        ["preanes_family_history", {}],
        ["preanes_current_med", {}],
        ["preanes_functional_class", {}],
        ["preanes_contact_lens", {}],
        // CardPreAnestheticAirwayEvaluationUX
        ["preanes_mallampati", {}],
        ["preanes_thyromental", {}],
        ["preanes_mouth_opening", {}],
        ["preanes_patency_nares", {}],
        ["preanes_prominent", {}],
        ["preanes_limitation", {}],
        ["preanes_difficult", {}],
        ["preanes_respiratory", {}],
        ["preanes_cardiovascul", {}],
        ["preanes_neurologic", {}],
        ["preanes_EENT", {}],
        ["preanes_gi", {}],
        ["preanes_genitourinar", {}],
        ["preanes_Endocrine", {}],
        ["preanes_skin_breast", {}],
        ["preanes_Immunology", {}],
        // CardPreAnestheticProblemsConsiderationsUX
        ["anes_plan_of_postope", {}],
        ["preanes_plan_of_post", {}],
        // CardPreAnestheticReEvaluationUX
        ["preanes_change_clini", {}],
        ["preanes_change_in_cu", {}],
        ["preanes_new_disease", {}],
      ],
    },
  });

  controller.setState(
    {
      PreAnestheticSequence: {
        sequenceIndex: "Action",
        data: {
          ...state.PreAnestheticSequence?.data,
        },
      },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "FETCH_PRE_ANESTHETIC") {
    const [preRecordResp, preRecordErr, preRecordNet] =
      await PreAnestheticRecordByPatientDetail.retrieve({
        apiToken: controller.apiToken,
        params: {
          patient_id: state.selectedOrOrder?.patient_id,
          form_code: "CardPreanestheticAnesthesiaRecord",
          form_version: params.formVersion,
        },
      });

    const [formHistoryResp, formHistoryErr, formHistoryNet] =
      await FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: "CardPreAnestheticHistory",
          form_version: params.formVersion,
        },
      });

    const [formAirwayResp, formAirwayErr, formAirwayNet] =
      await FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: "CardPreAnestheticAirwayEvaluation",
          form_version: params.formVersion,
        },
      });

    const [formBloodResp, formBloodErr, formBloodNet] =
      await FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: "CardPreAnestheticBloodChemistry",
          form_version: params.formVersion,
        },
      });

    const [formProblemsResp, formProblemsErr, formProblemsNet] =
      await FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: "CardPreAnestheticProblemsConsiderations",
          form_version: params.formVersion,
        },
      });

    const [formRiskResp, formRiskErr, formRiskNet] =
      await FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: "CardPreAnestheticRisk",
          form_version: params.formVersion,
        },
      });

    const [formReEvaluationResp, formReEvaluationErr, formReEvaluationNet] =
      await FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: "CardPreAnestheticReEvaluation",
          form_version: params.formVersion,
        },
      });

    // set vital_signs
    const [measurementResp, measurementErr, measurementNet] =
      await MeasurementList.list({
        params: {
          ...params,
          sort_by_seq: true,
          use_patient: true,
          invert_order: true,
          encounter: state.selectedOrOrder?.encounter,
        },
        apiToken: controller.apiToken,
      });

    let measurement: any[] = measurementResp.items[0]?.vital_signs || [];
    let BMI = "";
    let Weight = "";
    let Height = "";
    let TEMP = "";
    let SP = "";
    let DP = "";
    let PULSE = "";
    let O2Sat = "";
    let RR = "";

    measurement.forEach((item: any) => {
      if (item.vitalsign_type_code === "BMI") {
        BMI = item.formatted_string;
      } else if (item.vitalsign_type_code === "Weight") {
        Weight = item.formatted_string;
      } else if (item.vitalsign_type_code === "Height") {
        Height = item.formatted_string;
      } else if (item.vitalsign_type_code === "TEMP") {
        TEMP = item.formatted_string;
      } else if (item.vitalsign_type_code === "SP") {
        SP = item.formatted_string;
      } else if (item.vitalsign_type_code === "DP") {
        DP = item.formatted_string;
      } else if (item.vitalsign_type_code === "PULSE") {
        PULSE = item.formatted_string;
      } else if (item.vitalsign_type_code === "O2Sat") {
        O2Sat = item.formatted_string;
      } else if (item.vitalsign_type_code === "Respiratory Rate") {
        RR = item.formatted_string;
      }
    });

    let measurementData = {
      BodyWeight: Weight,
      Height: Height,
      BMI: BMI,
      BP: `${SP} / ${DP}`,
      Pulse: PULSE,
      RR: RR,
      PsychologicalStatusTemp: TEMP,
      O2SatRA: O2Sat,
    };

    // set anesthesiologist

    const getOperatingOrder = await OperatingOrderDetail.retrieve({
      apiToken: controller.apiToken,
      pk: state.selectedOrOrder.id,
    });

    let anesthesiologist = null;
    let anesthesiologistLicense = null;
    let anesthesiologistCode = null;

    const [doctorResp, doctorErr, doctorNet] = await DoctorDetail.retrieve({
      apiToken: controller.apiToken,
      pk: getOperatingOrder[0]?.anesthesia_teams[0]?.anesthesiologist,
    });

    const [employeeResp, employeeErr, employeeNet] =
      await UserEmployeeDetailAPIView.retrieve({
        pk: doctorResp?.user,
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });

    let license = null
    if (employeeResp?.code) {
      const [userResp, userErr, userNet] = await UserTokenizeLicenseView.post({
        apiToken: controller.apiToken,
        data: {
          code: employeeResp?.code,
        },
      });

      license = userResp?.license
    }

    anesthesiologist = doctorResp?.id;
    anesthesiologistLicense = license;
    anesthesiologistCode = employeeResp?.code;

    // }

    // set DX, ProposedOperation, BloodPrepared, Surgeon, DateTime
    const aneOrderId = state.selectedOrOrder?.other_orders?.find(
      (item: any) => item.model === "anesthesiaorder"
    )?.id;

    let aneDataResp = null;
    let aneDataErr = true;
    let aneDataNet = null;

    if (aneOrderId && state.selectedOrOrder?.encounter) {
      [aneDataResp, aneDataErr, aneDataNet] = await AnesthesiaDataList.list({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          ans_order_id: aneOrderId,
        },
      });
    }

    let dataPreAnesthetic = StateInitial.PreAnestheticSequence?.data;
    if (aneDataResp) {
      dataPreAnesthetic = {
        ...dataPreAnesthetic,
        ...measurementData,
        DX: aneDataResp?.pre_diagnosis || "",
        ProposedOperation: aneDataResp?.pre_operation || "",
        BloodPrepared: aneDataResp?.blood_request || "",
        Surgeon: aneDataResp?.primary_doctor_name || "",
        DateTime: aneDataResp?.or_datetime || "",
        Anesthesiologist: preRecordResp?.data?.Anesthesiologist
          ? preRecordResp?.data?.Anesthesiologist
          : anesthesiologist,
        AnesthesiologistLicenseNumber: preRecordResp?.data?.Anesthesiologist
          ? preRecordResp?.data?.AnesthesiologistLicenseNumber
          : anesthesiologistLicense,
        AnesthesiologistCode: preRecordResp?.data?.Anesthesiologist
          ? preRecordResp?.data?.AnesthesiologistCode
          : anesthesiologistCode,
      };
    }
    if (preRecordErr || !preRecordResp.data) {
      controller.setState({
        PreAnestheticSequence: {
          ...state.PreAnestheticSequence,
          preAnestheticHistoryID: formHistoryResp?.id,
          preAnestheticAirwayID: formAirwayResp?.id,
          preAnestheticBloodID: formBloodResp?.id,
          preAnestheticProblemsID: formProblemsResp?.id,
          preAnestheticRiskID: formRiskResp?.id,
          preAnestheticReEvaluationRespID: formReEvaluationResp?.id,
          data: {
            ...dataPreAnesthetic,
            ...preRecordResp?.data,
            ...formHistoryResp?.data,
            ...formAirwayResp?.data,
            ...formBloodResp?.data,
            ...formProblemsResp?.data,
            ...formRiskResp?.data,
            ...formReEvaluationResp?.data,
          },
        },
      });
    } else {
      if (preRecordResp.data) {
        if (preRecordResp.status_label === "Confirm") {
          controller.setState({
            PreAnestheticSequence: {
              ...state.PreAnestheticSequence,
              ...preRecordResp,
              preAnestheticHistoryID: formHistoryResp?.id,
              preAnestheticAirwayID: formAirwayResp?.id,
              preAnestheticBloodID: formBloodResp?.id,
              preAnestheticProblemsID: formProblemsResp?.id,
              preAnestheticRiskID: formRiskResp?.id,
              preAnestheticReEvaluationRespID: formReEvaluationResp?.id,
              data: {
                ...state.PreAnestheticSequence?.data,
                ...preRecordResp?.data,
                ...formHistoryResp?.data,
                ...formAirwayResp?.data,
                ...formBloodResp?.data,
                ...formProblemsResp?.data,
                ...formRiskResp?.data,
                ...formReEvaluationResp?.data,
              },
            },
          });
        } else {
          controller.setState({
            PreAnestheticSequence: {
              ...state.PreAnestheticSequence,
              ...preRecordResp,
              preAnestheticHistoryID: formHistoryResp?.id,
              preAnestheticAirwayID: formAirwayResp?.id,
              preAnestheticBloodID: formBloodResp?.id,
              preAnestheticProblemsID: formProblemsResp?.id,
              preAnestheticRiskID: formRiskResp?.id,
              preAnestheticReEvaluationRespID: formReEvaluationResp?.id,
              data: {
                ...dataPreAnesthetic,
                // ...preRecordResp?.data,
                ...formHistoryResp?.data,
                ...formAirwayResp?.data,
                ...formBloodResp?.data,
                ...formProblemsResp?.data,
                ...formRiskResp?.data,
                ...formReEvaluationResp?.data,
                PreanestheticAssessmentAt:
                  preRecordResp?.data.PreanestheticAssessmentAt,
                PreanestheticAssessmentAtRemark:
                  preRecordResp?.data.PreanestheticAssessmentAtRemark,
                PsychologicalStatus: preRecordResp?.data.PsychologicalStatus,
                PsychologicalStatusRemark:
                  preRecordResp?.data.PsychologicalStatusRemark,
                // Anesthetist: preRecordResp?.data.Anesthetist,
                // AnesthetistLicenseNumber:
                //   preRecordResp?.data.AnesthetistLicenseNumber,
                // AnesthetistCode: preRecordResp?.data.AnesthetistCode,
                //   getOperatingOrder[0].start_time,
                //   "hh:mm:ss"
                // ).format("HH:mm")} - ${moment(
                //   getOperatingOrder[0].end_date_time
                // ).format("HH:mm")}]`,
                // Anesthesiologist: anesthesiologist,
                // AnesthesiologistLicenseNumber:
                //   preRecordResp?.data.AnesthesiologistLicenseNumber,
                // AnesthesiologistCode: anesthesiologistCode,
              },
            },
          });
        }
      }
    }
  } else if (
    [FORM_DATA_ACTION.SAVE, FORM_DATA_ACTION.CONFIRM].includes(params?.action)
  ) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    let saveResp = null;
    let saveErr = null;
    let saveNet = null;
    let preAnesData = state.PreAnestheticSequence?.data;
    var preID =
      params.formCode === "CardPreAnestheticHistory"
        ? state.PreAnestheticSequence?.preAnestheticHistoryID
        : params.formCode === "CardPreAnestheticAirwayEvaluation"
        ? state.PreAnestheticSequence?.preAnestheticAirwayID
        : params.formCode === "CardPreAnestheticBloodChemistry"
        ? state.PreAnestheticSequence?.preAnestheticBloodID
        : params.formCode === "CardPreAnestheticProblemsConsiderations"
        ? state.PreAnestheticSequence?.preAnestheticProblemsID
        : params.formCode === "CardPreAnestheticRisk"
        ? state.PreAnestheticSequence?.preAnestheticRiskID
        : params.formCode === "CardPreAnestheticReEvaluation"
        ? state.PreAnestheticSequence?.preAnestheticReEvaluationRespID
        : "";

    const dataPreAnesthetic = {
      PreanestheticAssessmentAt: preAnesData?.PreanestheticAssessmentAt,
      PreanestheticAssessmentAtRemark:
        preAnesData?.PreanestheticAssessmentAtRemark,
      BodyWeight: preAnesData?.BodyWeight,
      Height: preAnesData?.Height,
      BMI: preAnesData?.BMI,
      BP: preAnesData?.BP,
      PsychologicalStatus: preAnesData?.PsychologicalStatus,
      PsychologicalStatusRemark: preAnesData?.PsychologicalStatusRemark,
      Pulse: preAnesData?.Pulse,
      RR: preAnesData?.RR,
      PsychologicalStatusTemp: preAnesData?.PsychologicalStatusTemp,
      O2SatRA: preAnesData?.O2SatRA,
      DX: preAnesData?.DX,
      ProposedOperation: preAnesData?.ProposedOperation,
      Surgeon: preAnesData?.Surgeon,
      DateTime: preAnesData?.DateTime,
      Anesthesiologist: preAnesData?.Anesthesiologist,
      AnesthesiologistLicenseNumber: preAnesData?.AnesthesiologistLicenseNumber,
      AnesthesiologistCode: preAnesData?.AnesthesiologistCode,
      Anesthetist: preAnesData?.Anesthetist,
      AnesthetistLicenseNumber: preAnesData?.AnesthetistLicenseNumber,
      AnesthetistCode: preAnesData?.AnesthetistCode,
    };

    const dataHistory = {
      PreviousAnesthesia: preAnesData?.PreviousAnesthesia,
      PreviousAnesthesiaRemark: preAnesData?.PreviousAnesthesiaRemark,
      Complication: preAnesData?.Complication,
      ComplicationRemark: preAnesData?.ComplicationRemark,
      KnownDisease: preAnesData?.KnownDisease,
      KnownDiseaseRemark: preAnesData?.KnownDiseaseRemark,
      FamilyHistory: preAnesData?.FamilyHistory,
      FamilyHistoryRemark: preAnesData?.FamilyHistoryRemark,
      CurrentMedications: preAnesData?.CurrentMedications,
      CurrentMedicationsRemark: preAnesData?.CurrentMedicationsRemark,
      HabitIsNon: preAnesData?.HabitIsNon,
      HabitAlcohol: preAnesData?.HabitAlcohol,
      HabitAlcoholRemark: preAnesData?.HabitAlcoholRemark,
      HabitSmoking: preAnesData?.HabitSmoking,
      HabitSmokingRemark: preAnesData?.HabitSmokingRemark,
      HabitDrugAbuse: preAnesData?.HabitDrugAbuse,
      HabitDrugAbuseRemark: preAnesData?.HabitDrugAbuseRemark,
      HabitHerbSupplement: preAnesData?.HabitHerbSupplement,
      HabitHerbSupplementRemark: preAnesData?.HabitHerbSupplementRemark,
      FunctionalClass: preAnesData?.FunctionalClass,
      FunctionalLMP: preAnesData?.FunctionalLMP,
      FunctionalIsContactLens: preAnesData?.FunctionalIsContactLens,
    };

    const dataAirway = {
      MallampatiGrade: preAnesData?.MallampatiGrade,
      ThyromentalDistance: preAnesData?.ThyromentalDistance,
      MouthOpening: preAnesData?.MouthOpening,
      PatencyOfNares: preAnesData?.PatencyOfNares,
      ProminentIncisor: preAnesData?.ProminentIncisor,
      LimitationOfHeadNeckMotility: preAnesData?.LimitationOfHeadNeckMotility,
      DifficultIntubationExpected: preAnesData?.DifficultIntubationExpected,
      Comment: preAnesData?.Comment,
      ArtificialAirway: preAnesData?.ArtificialAirway,
      ArtificialAirwayIsCommentTube: preAnesData?.ArtificialAirwayIsCommentTube,
      Dental: preAnesData?.Dental,
      Respiratory: preAnesData?.Respiratory,
      RespiratoryRemark: preAnesData?.RespiratoryRemark,
      Cardiovascular: preAnesData?.Cardiovascular,
      CardiovascularRemark: preAnesData?.CardiovascularRemark,
      Neurologic: preAnesData?.Neurologic,
      NeurologicRemark: preAnesData?.NeurologicRemark,
      EENT: preAnesData?.EENT,
      EENTRemark: preAnesData?.EENTRemark,
      GI: preAnesData?.GI,
      GIRemark: preAnesData?.GIRemark,
      Genitourinary: preAnesData?.Genitourinary,
      GenitourinaryRemark: preAnesData?.GenitourinaryRemark,
      EndocrineAndMetabolic: preAnesData?.EndocrineAndMetabolic,
      EndocrineAndMetabolicRemark: preAnesData?.EndocrineAndMetabolicRemark,
      SkinBreast: preAnesData?.SkinBreast,
      SkinBreastRemark: preAnesData?.SkinBreastRemark,
      ImmunologyHematology: preAnesData?.ImmunologyHematology,
      ImmunologyHematologyRemark: preAnesData?.ImmunologyHematologyRemark,
    };

    const dataBlood = {
      HbTime: preAnesData?.HbTime,
      HcTime: preAnesData?.HcTime,
      PltTime: preAnesData?.PltTime,
      WBCTime: preAnesData?.WBCTime,
      PTTime: preAnesData?.PTTime,
      PTTTime: preAnesData?.PTTTime,
      INRTime: preAnesData?.INRTime,
      NaTime: preAnesData?.NaTime,
      KTime: preAnesData?.KTime,
      ClTime: preAnesData?.ClTime,
      CO2Time: preAnesData?.CO2Time,
      BunTime: preAnesData?.BunTime,
      CrTime: preAnesData?.CrTime,
      GFRTime: preAnesData?.GFRTime,
      BSTIME: preAnesData?.BSTIME,
      ASTTime: preAnesData?.ASTTime,
      ALTTime: preAnesData?.ALTTime,
      AlpTime: preAnesData?.AlpTime,
      AlbTime: preAnesData?.AlbTime,
      OthersTime: preAnesData?.OthersTime,
      CXR: preAnesData?.CXR,
      EKG: preAnesData?.EKG,
      Imaging: preAnesData?.Imaging,
    };

    const dataProblems = {
      AnestheticProblemsConsiderationsRemark:
        preAnesData?.AnestheticProblemsConsiderationsRemark,
      IsEmergency: preAnesData?.IsEmergency,
      IsAnestheticInformedConsent: preAnesData?.IsAnestheticInformedConsent,
      NPO: preAnesData?.NPO,
      AnestheicPlan: preAnesData?.AnestheicPlan,
      AlternativeOfAnesthesiaDiscussed:
        preAnesData?.AlternativeOfAnesthesiaDiscussed,
      PremedicationNone: preAnesData?.PremedicationNone,
      PremedicationNoneRemark: preAnesData?.PremedicationNoneRemark,
      BloodPrepared: preAnesData?.BloodPrepared,
      BloodPreparedStatus: preAnesData?.BloodPreparedStatus,
      PlanOfPostoperativeCare: preAnesData?.PlanOfPostoperativeCare,
      AmbulatoryPatientWithCompanion:
        preAnesData?.AmbulatoryPatientWithCompanion,
      PlanOfPostoperativePainManagement:
        preAnesData?.PlanOfPostoperativePainManagement,
      PlanOfPostoperativePainManagementRemark:
        preAnesData?.PlanOfPostoperativePainManagementRemark,
    };

    const dataRisk = {
      SoreThroat: preAnesData?.SoreThroat,
      Hoarseness: preAnesData?.Hoarseness,
      Nausea: preAnesData?.Nausea,
      Vomiting: preAnesData?.Vomiting,
      Pruritus: preAnesData?.Pruritus,
      UrinaryRetention: preAnesData?.UrinaryRetention,
      VentilatorySupport: preAnesData?.VentilatorySupport,
      RiskOfAnesthesiaIncludedOther: preAnesData?.RiskOfAnesthesiaIncludedOther,
      RiskOfAnesthesiaIncludedOtherRemark:
        preAnesData?.RiskOfAnesthesiaIncludedOtherRemark,
      RiskOfAnesthesiaIncludedComment:
        preAnesData?.RiskOfAnesthesiaIncludedComment,
    };

    const dataReEvaluation = {
      PreanestheticReEvaluationNoneDueto:
        preAnesData?.PreanestheticReEvaluationNoneDueto,
      PreanestheticReEvaluationIsNoneDueto:
        preAnesData?.PreanestheticReEvaluationIsNoneDueto,
      PREANESTHETICREEVALUATIONDateTime: `${preAnesData?.PREANESTHETICREEVALUATIONDate} [${preAnesData?.PREANESTHETICREEVALUATIONTime}]`,
      PREANESTHETICREEVALUATIONDate: preAnesData?.PREANESTHETICREEVALUATIONDate,
      PREANESTHETICREEVALUATIONTime: preAnesData?.PREANESTHETICREEVALUATIONTime,
      PREANESTHETICREEVALUATIONBodyWeight:
        preAnesData?.PREANESTHETICREEVALUATIONBodyWeight,
      PREANESTHETICREEVALUATIONBP: preAnesData?.PREANESTHETICREEVALUATIONBP,
      PreanestheticReEvaluationIsPR: preAnesData?.PreanestheticReEvaluationIsPR,
      PreanestheticReEvaluationIsRR: preAnesData?.PreanestheticReEvaluationIsRR,
      PreanestheticReEvaluationTemp: preAnesData?.PreanestheticReEvaluationTemp,
      PreanestheticReEvaluationLMP: preAnesData?.PreanestheticReEvaluationLMP,
      PreanestheticReEvaluationProposedOperation:
        preAnesData?.PreanestheticReEvaluationProposedOperation,
      IsChangeInClinicalCondition: preAnesData?.IsChangeInClinicalCondition,
      ChangeInClinicalConditionRemark:
        preAnesData?.ChangeInClinicalConditionRemark,
      IsChangeInCurrentMedication: preAnesData?.IsChangeInCurrentMedication,
      ChangeInCurrentMedicationRemark:
        preAnesData?.ChangeInCurrentMedicationRemark,
      IsNewDiseaseIllness: preAnesData?.IsNewDiseaseIllness,
      NewDiseaseIllnessRemark: preAnesData?.NewDiseaseIllnessRemark,
      AssessmentIsStableClinicalCondition:
        preAnesData?.AssessmentIsStableClinicalCondition,
      AssessmentIsUnstableDueTo: preAnesData?.AssessmentIsUnstableDueTo,
      AssessmentUnstableDueToRemark: preAnesData?.AssessmentUnstableDueToRemark,
      PlanIsGoOnAnesthesiaAsPlan: preAnesData?.PlanIsGoOnAnesthesiaAsPlan,
      PlanIsOther: preAnesData?.PlanIsOther,
      PlanOtherRemark: preAnesData?.PlanOtherRemark,
      PreanestheticReEvaluationAnesthetsiologisAnesthetist:
        preAnesData?.PreanestheticReEvaluationAnesthetsiologisAnesthetist,
      PreanestheticReEvaluationAnesthetsiologisAnesthetistLicenseNumber:
        preAnesData?.PreanestheticReEvaluationAnesthetsiologisAnesthetistLicenseNumber,
      PreanestheticReEvaluationAnesthetsiologisAnesthetistCode:
        preAnesData?.PreanestheticReEvaluationAnesthetsiologisAnesthetistCode,
    };

    const data = {
      action: params?.action, // SAVE
      data:
        params.formCode === "CardPreAnestheticHistory"
          ? dataHistory
          : params.formCode === "CardPreAnestheticAirwayEvaluation"
          ? dataAirway
          : params.formCode === "CardPreAnestheticBloodChemistry"
          ? dataBlood
          : params.formCode === "CardPreAnestheticProblemsConsiderations"
          ? dataProblems
          : params.formCode === "CardPreAnestheticRisk"
          ? dataRisk
          : params.formCode === "CardPreAnestheticReEvaluation"
          ? dataReEvaluation
          : "",
      encounter: state.selectedOrOrder?.encounter,
      form_code: params?.formCode,
      form_name: params?.formName,
      form_version: params?.formVersion,
    };

    if (params.tabForm) {
      if (preID !== "" && preID !== undefined) {
        [saveResp, saveErr, saveNet] = await FormDataDetail.update({
          apiToken: controller.apiToken,
          pk: preID,
          data: data,
          extra: {
            division: controller.data.division,
          },
        });
      } else {
        [saveResp, saveErr, saveNet] = await FormDataList.create({
          apiToken: controller.apiToken,
          data: data,
          extra: {
            division: controller.data.division,
          },
        });
      }
      if (saveErr) {
        controller.setState({
          errorMessage: { ...state.errorMessage, [params.cardKey]: saveErr },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params.cardKey]: `${params?.action} Success`,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
        Action(controller, { ...params, action: "FETCH_PRE_ANESTHETIC" });
      }
    } else {
      if (state.PreAnestheticSequence?.id) {
        [saveResp, saveErr, saveNet] = await PreAnestheticRecordDetail.update({
          apiToken: controller.apiToken,
          pk: state.PreAnestheticSequence?.id,
          data: {
            action: params.action, // SAVE, CONFIRM
            data: dataPreAnesthetic,
            // state.PreAnestheticSequence?.data,
            encounter: state.selectedOrOrder?.encounter,
            form_code: params.formCode,
            form_version: params.formVersion,
          },
          extra: {
            division: controller.data.division,
          },
        });
      } else {
        [saveResp, saveErr, saveNet] = await PreAnestheticRecordList.create({
          apiToken: controller.apiToken,
          data: {
            action: params.action, // SAVE, CONFIRM
            data: dataPreAnesthetic,
            encounter: state.selectedOrOrder?.encounter,
            form_code: params.formCode,
            form_version: params.formVersion,
          },
          extra: {
            division: controller.data.division,
          },
        });
      }
      if (saveErr) {
        controller.setState({
          errorMessage: { ...state.errorMessage, [params.cardKey]: saveErr },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params.cardKey]: `${params?.action} Success`,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
        Action(controller, { ...params, action: "FETCH_PRE_ANESTHETIC" });
      }
    }
  } else if (params?.action === "PRINT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    if (state.PreAnestheticSequence?.id) {
      const [printResp, printErr, printNet] = await ANSFormPrint.post({
        apiToken: controller.apiToken,
        data: {
          form_id: state.PreAnestheticSequence?.id,
          pdf: true,
        },
        extra: {
          division: controller.data.division,
        },
      });
      if (printErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: printErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
      }
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: "บันทึก Form ก่อนทำรายการ",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params?.action === "LOG") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    if (state.selectedOrOrder?.encounter) {
      const [logResp, logErr, logNet] = await PreAnestheticLogList.list({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: params.formCode,
          form_version: params.formVersion,
        },
        extra: {
          division: controller.data.division,
        },
      });
      if (logErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: logErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
          PreAnestheticSequence: {
            ...state.PreAnestheticSequence,
            modLogInfo: { open: true, logList: logResp?.items || [] },
          },
        });
      }
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: "ไม่มี Encounter",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params?.action === "GET_USER_TOKENIZE_LICENSE") {
    const [userResp, userErr, userNet] = await UserTokenizeLicenseView.post({
      apiToken: controller.apiToken,
      data: {
        code: state.PreAnestheticSequence?.data?.[`${params.paramKey}Code`],
      },
      extra: {
        division: controller.data.division,
      },
    });

    let data: any = { ...state.PreAnestheticSequence?.data };

    if (userErr) {
      data[params.paramKey] = "";
      data[`${params.paramKey}LicenseNumber`] = "";
      data[`${params.paramKey}Code`] = "";

      controller.setState({
        errorMessage: { ...state.errorMessage, [params.cardKey]: userErr },
      });
    } else {
      const encodedName = userResp.token.split(".")[1];
      data[params.paramKey] = decodeURIComponent(atob(encodedName));
      data[`${params.paramKey}LicenseNumber`] = userResp.license;
    }
    //check props.PreAnestheticSequence?.data?.Anesthesiologist
    controller.setState({
      PreAnestheticSequence: {
        ...state.PreAnestheticSequence,
        data: data,
      },
    });
  } else if (params?.action === "GET_LICENSE") {
    // Get Employee Code
    const [employeeResp, employeeErr, employeeNet] =
      await UserEmployeeDetailAPIView.retrieve({
        pk: params.paramKey,
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });

    // Get License
    if (employeeResp?.code) {
      const [userResp, userErr, userNet] = await UserTokenizeLicenseView.post({
        apiToken: controller.apiToken,
        data: {
          code: employeeResp?.code,
        },
        extra: {
          division: controller.data.division,
        },
      });

      let data: any = { ...state.PreAnestheticSequence?.data };

      if (userErr) {
        data[`${params.cardKey}LicenseNumber`] = "";
        data[`${params.cardKey}Code`] = "";

        controller.setState({
          errorMessage: { ...state.errorMessage, [params.cardKey]: userErr },
        });
      } else {
        data[`${params.cardKey}LicenseNumber`] = userResp.license;
        data[`${params.cardKey}Code`] = employeeResp.code;
      }

      controller.setState({
        PreAnestheticSequence: {
          ...state.PreAnestheticSequence,
          data: data,
        },
      });
    }
  }
};
