import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
} from "react";
import { Button, Modal, Icon } from "semantic-ui-react";

// @ts-ignore
import { CSVLink } from "react-csv";

// UX
// CLM
import CardExportEClaimFile2UX from "./CardExportEClaimFile2UX";
import CardSearchInvoiceGroup from "./CardSearchInvoiceGroup";
import CardClaimInformationDetail from "./CardClaimInformationDetail";
import CardSaveRefNo from "./CardSaveRefNoUX";
// TPD
import CardPatientSearchBox from "../TPD/CardPatientSearchBox";
// ADM
import ModalSearchEncounter from "../ADM/ModalSearchEncounter";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { DateTextBox } from "react-lib/apps/common";

// Interface
import { State, RunSequence } from "./sequence/ExportEClaimFile";

type CardExportEClaimFileProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: RunSequence;
  ExportEClaimFileSequence: State["ExportEClaimFileSequence"];
  // controller
  drugOrderQueueController: any;
  // options
  masterOptions?: Record<string, any>;
  // data
  userTokenize?: any;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
};

const TREATMENT_TYPE_OPTIONS = [
  { key: 1, value: "IPD", text: "IPD" },
  { key: 2, value: "OPD", text: "OPD" },
];

const BUTTON_ACTIONS = {
  SEARCH: "SEARCH",
  SAVE: "SAVE_REF_NO",
};

export const CARD_EXPORT_E_CLAIM_FILE = "CardExportEClaimFile";

const CardExportEClaimFile = (props: CardExportEClaimFileProps) => {
  const [openModSave, setOpenModSave] = useState<boolean>(false);
  const [refNo, setRefNo] = useState<string>("");

  const encounterRef = useRef<any>();

  // Effect
  useEffect(() => {
    props.runSequence({ sequence: "ExportEClaimFile", restart: true });
  }, []);

  // Callback
  const handleOpenClaimInfo = useCallback(
    (item: any) => () => {
      props.runSequence({
        sequence: "ExportEClaimFile",
        action: "DESCRIPTION",
        data: item,
      });
    },
    []
  );

  // Memo
  const billTransactionItems = useMemo(() => {
    return (props.ExportEClaimFileSequence?.billTransactionList || []).map(
      (item: any) => ({
        ...item,
        description: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="orange"
              size="mini"
              onClick={handleOpenClaimInfo(item)}
            >
              รายละเอียด
            </Button>
          </div>
        ),
      })
    );
  }, [props.ExportEClaimFileSequence?.billTransactionList]);

  const total = useMemo(() => {
    const summary = props.ExportEClaimFileSequence?.billTransactionSummary;

    const formatPrice = (number?: number) => {
      return (number || 0).toLocaleString("en-US", {
        style: "decimal",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    };

    return {
      sentClaimPrice: formatPrice(summary?.total_sent_claim_price),
      paidPrice: formatPrice(summary?.total_paid_price),
      otherPayPrice: formatPrice(summary?.total_other_pay_price),
    };
  }, [props.ExportEClaimFileSequence?.billTransactionSummary]);

  // Handler
  const handleSelectedAR = async (data: any) => {
    await handleChangeValue(null, {
      name: "arTransaction",
      value: data.id || "",
    });
    await handleChangeValue(null, {
      name: "arTransactionData",
      value: data || "",
    });
    handleChangeValue(null, {
      name: "coveragePayer",
      value:
        data.coverage_payer_sent_claim_group ||
        props.ExportEClaimFileSequence?.coveragePayerOptions?.[0]?.value ||
        "",
    });

    // coverage_payer_sent_claim_group;
  };

  const handleChangeValue = async (e: any, v: any) => {
    const value = typeof v.checked === "boolean" ? v.checked : v.value;

    await props.setProp(`ExportEClaimFileSequence.filter.${v.name}`, value);
  };

  const handleChangeDate = (name: string) => (value: string) => {
    handleChangeValue(null, { name, value });
  };

  const handleChangePatient = async (id: any, hn: any) => {
    await handleChangeValue(null, { name: "hn", value: hn });
    handleChangeValue(null, { name: "patientId", value: id });
  };

  const handleSelectedEncounter = (item: any) => {
    handleChangeValue(null, { name: "encounter", value: item.id });
  };

  const handleClearEncounter = () => {
    handleChangeValue(null, { name: "encounter", value: "" });
  };

  // Modal Search Encounter
  const handleGetEncounterList = async (params: any) => {
    return await props.onEvent({
      message: "GetEncounterList",
      params,
    });
  };

  const handleSearch = (e: any, data: any) => {
    props.runSequence({
      sequence: "ExportEClaimFile",
      action: "SEARCH",
      card: CARD_EXPORT_E_CLAIM_FILE,
    });
  };

  const handleOpenModSave = () => {
    setOpenModSave(true);
  };

  const handleCloseModSave = () => {
    setOpenModSave(false);
    setRefNo("");
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "ExportEClaimFile",
      action: "SAVE_REF_NO",
      card: CARD_EXPORT_E_CLAIM_FILE,
      refNo,
      onSuccess: handleCloseModSave,
    });
  };

  const handleCloseClaimInfo = () => {
    props.setProp("ExportEClaimFileSequence.claimInfoDetail", null);
  };

  const handleExportDataFile = () => {
    props.runSequence({
      sequence: "ExportEClaimFile",
      action: "EXPORT_DATA_16_FILE",
    });
  };

  // Return
  const handleGetTrProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    let data = rowInfo?.original?.row_style || {};

    return {
      style: {
        ...data,
        whiteSpace: "pre-line",
      },
    };
  };

  const handleChangeRefNo = (e: any, data: any) => {
    setRefNo(data.value);
  };

  console.log("CardExportEClaimFile2UX", props);

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_EXPORT_E_CLAIM_FILE}`, null);
        }}
        error={props.errorMessage?.[CARD_EXPORT_E_CLAIM_FILE]}
        success={null}
      />

      <CardExportEClaimFile2UX
        // Data
        billTransactionList={billTransactionItems}
        filter={props.ExportEClaimFileSequence?.filter}
        // options
        treatmentTypeOptions={TREATMENT_TYPE_OPTIONS}
        coveragePayerOptions={
          props.ExportEClaimFileSequence?.coveragePayerOptions
        }
        sentClaimChoiceOptions={props.masterOptions?.sentClaimChoice}
        totalRows={
          props.ExportEClaimFileSequence?.billTransactionSummary?.total_rows
        }
        totalSentClaimPrice={total.sentClaimPrice}
        totalPaidPrice={total.paidPrice}
        totalOtherPayPrice={total.otherPayPrice}
        // config
        disabledSave={!billTransactionItems.length}
        // callback
        onChangeValue={handleChangeValue}
        getTdProps={handleGetTrProps}
        onSave={handleOpenModSave}
        onExportDataFile={handleExportDataFile}
        // Element
        SearchInvoiceGroup={
          <CardSearchInvoiceGroup
            onEvent={props.onEvent}
            onSelected={handleSelectedAR}
          />
        }
        StartDate={
          <DateTextBox
            value={props.ExportEClaimFileSequence?.filter?.startDate}
            fluid={true}
            inputStyle={{ width: "145px" }}
            disabled={!props.ExportEClaimFileSequence?.filter?.isSpecifyDate}
            onChange={handleChangeDate("startDate")}
          />
        }
        EndDate={
          <DateTextBox
            value={props.ExportEClaimFileSequence?.filter?.endDate}
            fluid={true}
            inputStyle={{ width: "145px" }}
            disabled={!props.ExportEClaimFileSequence?.filter?.isSpecifyDate}
            onChange={handleChangeDate("endDate")}
          />
        }
        PatientSearchBox={
          <CardPatientSearchBox
            controller={props.drugOrderQueueController}
            modalStyle={{ width: "auto" }}
            disabled={!props.ExportEClaimFileSequence?.filter?.isHN}
            onEnterPatientSearch={handleChangePatient}
          />
        }
        SearchEncounter={
          <ModalSearchEncounter
            key="searchBoxEncounter"
            ref={encounterRef}
            fluid
            onGetEncounter={handleGetEncounterList}
            onSelect={handleSelectedEncounter}
            onClearInput={handleClearEncounter}
            patientId={props.ExportEClaimFileSequence?.filter?.patientId as any}
            textField="id"
            readOnly={true}
            disabledSearch={
              !props.ExportEClaimFileSequence?.filter?.isEncounter
            }
          />
        }
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={`${CARD_EXPORT_E_CLAIM_FILE}_${BUTTON_ACTIONS.SEARCH}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_EXPORT_E_CLAIM_FILE}_${BUTTON_ACTIONS.SEARCH}`
              ]
            }
            // config
            fluid={true}
            color={"blue"}
            name={BUTTON_ACTIONS.SEARCH}
            size="medium"
            title="ค้นหา"
          />
        }
      />

      <Modal open={openModSave} onClose={handleCloseModSave} closeOnDimmerClick>
        <CardSaveRefNo
          // data
          refNo={refNo}
          lotNo={
            props.ExportEClaimFileSequence?.filter?.arTransactionData?.lot_no
          }
          createAt={
            props.ExportEClaimFileSequence?.filter?.arTransactionData
              ?.created_at
          }
          // callback
          onChangeRefNo={handleChangeRefNo}
          onCancel={handleCloseModSave}
          onClose={handleCloseModSave}
          // Element
          ButtonSave={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleSave}
              // data
              paramKey={`${CARD_EXPORT_E_CLAIM_FILE}_${BUTTON_ACTIONS.SAVE}`}
              buttonLoadCheck={
                props.buttonLoadCheck?.[
                  `${CARD_EXPORT_E_CLAIM_FILE}_${BUTTON_ACTIONS.SAVE}`
                ]
              }
              // config
              color={"green"}
              name={BUTTON_ACTIONS.SAVE}
              size="medium"
              title="บันทึก"
            />
          }
        />
      </Modal>

      <Modal
        open={!!props.ExportEClaimFileSequence?.claimInfoDetail}
        size="fullscreen"
        onClose={handleCloseClaimInfo}
        closeOnDimmerClick
      >
        <CardClaimInformationDetail
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // data
          userTokenize={props.userTokenize}
          claimInfoDetail={props.ExportEClaimFileSequence?.claimInfoDetail}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
          // options
          masterOptions={props.masterOptions}
          // callback
          onClose={handleCloseClaimInfo}
        />
      </Modal>
    </div>
  );
};

export default React.memo(CardExportEClaimFile);
