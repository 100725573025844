import React, { MutableRefObject, useEffect, useRef, useState } from "react";
// ui ORM
import CardSurgicalSafetyChecklistSignin from "./CardSurgicalSafetyChecklistSignin";
import CardSurgicalSafetyChecklistTimeout from "./CardSurgicalSafetyChecklistTimeout";
import CardSurgicalSafetyChecklistSignout from "./CardSurgicalSafetyChecklistSignout";
import "./CardORHistory.scss";

type CardSurgicalSafetyChecklistProps = {
  // function
  onEvent: any;
  setProp: any;
  buttonLoadCheck?: any;
  errorMessage?: any;
  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;
  masterData?: any;

  // seq
  runSequence?: any;
  PerioperativeNursingSequence?: any;

  phvReadOnly?: boolean;
  DJANGO?: any;
};

const CardSurgicalSafetyChecklistInitial: CardSurgicalSafetyChecklistProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,
  buttonLoadCheck: null,
};

const CardSurgicalSafetyChecklist: React.FC<
  CardSurgicalSafetyChecklistProps
> = (props: any) => {

  const sscRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "FETCH_FORM_DATA_SURGICAL_SAFETY",
    });
  }, []);

  useEffect(() => {
    if (sscRef.current && props.phvReadOnly) {
      const children = Array.from(
        sscRef.current.querySelectorAll(".dropdown, .input, .search, input, textarea, .ui.small.button")
      ) as HTMLElement[];

      console.log("children: ", children);
      children.forEach((e) => {
        e.setAttribute("tabindex", -1);
      });
    }
  }, [sscRef?.current, props.phvReadOnly]);

  useEffect(() => {
    console.log("props.DJANGO?.user?.role_types", props.DJANGO?.user?.role_types)
    const isDoctor = props.DJANGO?.user?.role_types?.includes("DOCTOR");

    if (isDoctor) {
      const children = Array.from(
        sscRef.current.querySelectorAll(
          ".dropdown, .input, .search, input, textarea, .ui.small.button"
        )
      ) as HTMLElement[];
      const childrenBtn = Array.from(
        sscRef.current.querySelectorAll(
          ".ui.small.button"
        )
      ) as HTMLElement[];
      const childrenCheckBtn = Array.from(
        sscRef.current.querySelectorAll(
          ".ui.checkbox"
        )
      ) as HTMLElement[];
      console.log("children: ", children);
      children.forEach((e) => {
        e.setAttribute("tabindex", "-1");
        e.classList.add("block-mouse");
      });
      childrenBtn.forEach((e) => {
        e.setAttribute("tabindex", "-1");
        e.setAttribute("disabled", "-1");
      });
      childrenCheckBtn.forEach((e) => {
        e.setAttribute("tabindex", "-1");
        e.setAttribute("disabled", "-1");
        e.classList.add("block-mouse");
      });
    }
  }, []);

  return (
    <div ref={sscRef}>
      <CardSurgicalSafetyChecklistSignin
        onEvent={props.onEvent}
        setProp={props.setProp}
        // seq
        runSequence={props.runSequence}
        PerioperativeNursingSequence={props.PerioperativeNursingSequence}
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        selectedOrOrder={props.selectedOrOrder}
        errorMessage={props.errorMessage}
        // options
        masterOptions={props.masterOptions}
      />

      <CardSurgicalSafetyChecklistTimeout
        onEvent={props.onEvent}
        setProp={props.setProp}
        // seq
        runSequence={props.runSequence}
        PerioperativeNursingSequence={props.PerioperativeNursingSequence}
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        selectedOrOrder={props.selectedOrOrder}
        errorMessage={props.errorMessage}
        // options
        masterOptions={props.masterOptions}
      />

      <CardSurgicalSafetyChecklistSignout
        onEvent={props.onEvent}
        setProp={props.setProp}
        // seq
        runSequence={props.runSequence}
        PerioperativeNursingSequence={props.PerioperativeNursingSequence}
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        selectedOrOrder={props.selectedOrOrder}
        errorMessage={props.errorMessage}
        // options
        masterOptions={props.masterOptions}
      />
    </div>
  );
};

CardSurgicalSafetyChecklist.defaultProps = CardSurgicalSafetyChecklistInitial;

export default React.memo(CardSurgicalSafetyChecklist);
