import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Checkbox,
  Dropdown,
  Input
} from 'semantic-ui-react'

const CardElectricalXrayUltrasoundUX = (props: any) => {
    return(
      <div
        style={{ margin: "15px",  padding: "20px",  backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", fontSize: "22px" }}>
            Electrical & X-ray & Ultrasound (D-F)
          </label>
          <Label
            color={props.statusColor}
            style={{ float: "right" }}>
            {props.status}
          </Label>
          <hr>
          </hr>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              Surgical Cautery
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
            <Checkbox
              checked={props.isSurgicalCautery}
              label={props.isSurgicalCautery ? "YES" : "NO"}
              name="is_surgical_cautery"
              onChange={props.handleChangeData}
              style={{ float: "right", marginRight: "20px"}}
              toggle={true}>
            </Checkbox>
          </div>
          <Dropdown
            fluid={true}
            multiple={true}
            name="surgical_cautery"
            onChange={props.handleChangeData}
            options={props.masterOptions?.orSurgicalCautery}
            selection={true}
            style={{ width: "50%" ,display : props.isSurgicalCautery ? "" :"none"}}
            value={props.surgicalCautery}>
          </Dropdown>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              X-Ray 
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
            <Checkbox
              checked={props.isXray}
              label={props.isXray ? "YES" : "NO"}
              name="is_xray"
              onChange={props.handleChangeData}
              style={{ float: "right", marginRight: "20px"}}
              toggle={true}>
            </Checkbox>
          </div>
          <Dropdown
            fluid={true}
            multiple={true}
            name="xray"
            onChange={props.handleChangeData}
            options={props.masterOptions?.specialEquipment}
            selection={true}
            style={{ width: "50%" ,display : props.isXray ? "" :"none"}}
            value={props.xay}>
          </Dropdown>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              Ground Pad Site 
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Dropdown
            fluid={true}
            multiple={true}
            name="ground_pad_site"
            onChange={props.handleChangeData}
            options={props.masterOptions?.orGroundPadSite}
            search={true}
            selection={true}
            style={{ width: "50%" }}
            value={props.groundPadSite}>
          </Dropdown>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              U/S
            </label>
            <Checkbox
              checked={props.isUs}
              className="electricalXrayUltrasound.chkUS"
              label={props.isUs ? "YES" : "NO"}
              name="is_us"
              onChange={props.handleChangeData}
              style={{ float: "right", marginRight: "20px"}}
              toggle={true}>
            </Checkbox>
          </div>
          <label
            style={{ fontWeight: "bold", margin: "5px 10px 0px 0px",display : props.isUs ? "" :"none" }}>
            frequency
          </label>
          <Input
            name="frequency"
            onChange={props.handleChangeData}
            style={{ width: "20%" ,display : props.isUs ? "" :"none"}}
            value={props.frequency}>
          </Input>
          <label
            style={{ fontWeight: "bold", margin: "5px 10px 0px 5px", display : props.isUs ? "" :"none"}}>
            MHz
          </label>
        </div>
        <div
          style={{ height: "55px", paddingTop: "15px" }}>
          
          <div
            style={{display:"flex",justifyContent:"flex-end"}}>
            
            <div
              style={{margin:"10px"}}>
              {props.buttonSave}
            </div>
            <div
              style={{margin:"10px"}}>
              {props.buttonConfirm}
            </div>
            <div
              style={{margin:"10px"}}>
              {props.buttonUnconfirm}
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardElectricalXrayUltrasoundUX

export const screenPropsDefault = {}

/* Date Time : Thu Sep 15 2022 17:39:09 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\",  padding: \"20px\",  backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Electrical & X-ray & Ultrasound (D-F)"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"22px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        },
        "color": {
          "type": "code",
          "value": "props.statusColor"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "Surgical Cautery"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Checkbox",
      "parent": 22,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isSurgicalCautery"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "code",
          "value": "props.isSurgicalCautery ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "is_surgical_cautery"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\", marginRight: \"20px\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\" }"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"flex-end\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "Dropdown",
      "parent": 21,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "surgical_cautery"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orSurgicalCautery"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" ,display : props.isSurgicalCautery ? \"\" :\"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.surgicalCautery"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 159,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "div",
      "parent": 161,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "label",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": "X-Ray "
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "label",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "label",
      "parent": 164,
      "props": {
        "children": {
          "type": "value",
          "value": "Ground Pad Site "
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "label",
      "parent": 164,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "label",
      "parent": 165,
      "props": {
        "children": {
          "type": "value",
          "value": "U/S"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "Checkbox",
      "parent": 163,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isXray"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "code",
          "value": "props.isXray ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "is_xray"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\", marginRight: \"20px\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "Dropdown",
      "parent": 159,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "xray"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.specialEquipment"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" ,display : props.isXray ? \"\" :\"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.xay"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "Dropdown",
      "parent": 160,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "ground_pad_site"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orGroundPadSite"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.groundPadSite"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "Input",
      "parent": 161,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "frequency"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" ,display : props.isUs ? \"\" :\"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.frequency"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "Checkbox",
      "parent": 165,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isUs"
        },
        "className": {
          "type": "value",
          "value": "electricalXrayUltrasound.chkUS"
        },
        "label": {
          "type": "code",
          "value": "props.isUs ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "is_us"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\", marginRight: \"20px\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "label",
      "parent": 161,
      "props": {
        "children": {
          "type": "value",
          "value": "frequency"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px 0px 0px\",display : props.isUs ? \"\" :\"none\" }"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "label",
      "parent": 161,
      "props": {
        "children": {
          "type": "value",
          "value": "MHz"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px 0px 5px\", display : props.isUs ? \"\" :\"none\"}"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 183,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardElectricalXrayUltrasoundUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
