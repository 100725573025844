import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import { Checkbox, Button, Modal } from "semantic-ui-react";
import { parseDate, formatADtoBEString } from "react-lib/utils/dateUtils";
import { DATE_FORMAT, timeSlotList } from "react-lib/apps/Scheduling/common/Utils";
import CardSelectTemplateUX from "./CardSelectTemplateUX";
import ModSchedulingDetailUX from "./ModSchedulingDetailUX";
import { ModConfirm } from "react-lib/apps/common"
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

const TemplateItemActive = (props: any) => {
  return(
    <div style={{width: "100%", textAlign: "center"}}>
      <Checkbox 
        checked={props.templateItemIndexList.includes(props.index)}
        onClick={(e: any) => {
          if (props.templateItemIndexList.includes(props.index)) {
            const newList = (props.templateItemIndexList || []).filter((index: any) => index !== props.index);
            console.log('1 newList: ', newList);
            props.setTemplateItemIndexList(newList);
          } else {
            const newList = [...props.templateItemIndexList, props.index];
            console.log('2 newList: ', newList);
            props.setTemplateItemIndexList(newList);
          }
        }}
      />
    </div>
  )
}
const CARD_SELECT_TEMPLATE = "CardSelectTemplate";

const CardSelectTemplate = (props: any) => {
  // Template-based edit
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  // For display
  const [templateItems, setTemplateItems] = useState<any[]>([]);
  const [templateItemIndex, setTemplateItemIndex] = useState<number|null>(null);
  // For sending to back-end
  const [templateItemIndexList, setTemplateItemIndexList] = useState<number[]>([]);

  const [timeOptions, setTimeOptions] = useState<any[]>([]);

  const [chair, setChair] = useState<any>(null);
  const [inactive, setInactive] = useState(false);
  const [doctorStartSerial, setDoctorStartSerial] = useState<string|number>("");
  const [doctorEndSerial, setDoctorEndSerial] = useState<string|number>("");
  const [slotLength, setSlotLength] = useState("5");

  const [showModal, setShowModal] = useState(false);
  console.log("CardSelectTemplate",props);
  

  // console.log('CardSelectTemplate templateItems: ', templateItems);
  // console.log('CardSelectTemplate templateItemIndexList: ', templateItemIndexList);
  // console.log("CardSelectTemplate props.chairProviderIdList:", props.chairProviderIdList)
  // console.log("CardSelectTemplate props.chairList", props.chairList)

  useEffect(() => {
    updateTemplateItems(templateItems);
  }, [templateItemIndexList])

  useEffect(() => {
    if (props.defaultChair) {
      updateTemplateItems(updateChairDefault(templateItems, props.defaultChair))
    }
  }, [props.defaultChair])

  useEffect(() => {
    onSelectTemplate(null);
  }, [props.selectedDivision]);

  const scheduleTemplates = useMemo(() => {
    return (props.scheduleTemplates || []).map((item: any) => ({
      ...item,
      start: formatADtoBEString(item.start),
      end: formatADtoBEString(item.end),
    }));
  }, [props.scheduleTemplates]);

  const updateChairDefault = (items: any[], chair: any) => { 
    const newTemplateItems: any[] = items.map((item: any, index: number) => ({
      ...item,
      chair: chair
    }))
    return newTemplateItems
  }

  const serialFromTime: (s: string) => number = (s: string) => {
    console.log(s)
    const elems = s.split(":").map((elem: string) => parseInt(elem));
    const serial = (elems[0] * 60 + elems[1]) / 5 + 17 * 12;
    return serial
  }

  const updateTimeOptions = (start_serial: number, end_serial: number, gap: number) => {
    setTimeOptions(timeSlotList(start_serial, end_serial, gap, false));
  } 

  const onSelectTemplate = (template: any) => {
    // console.log('onSelectTemplate template: ', template);
    // console.log("onSelectTemplate")
    setSelectedTemplate(template);
    setTemplateItemIndex(null);
    setTemplateItemIndexList([]);
    setStartDateTime(template?.start_datetime?.split("T")?.[0] || "")
    setEndDateTime(template?.end_datetime?.split("T")?.[0] || "")
    if(props.onSelectTemplateDate){
      props.onSelectTemplateDate(template?.start_datetime?.split("T")?.[0], template?.end_datetime?.split("T")?.[0])
    }
    updateTemplateItems(template?.items ? updateChairDefault(template?.items, props.defaultChair) : []);
  }

  const changeDate = (key: string) => 
    (value: any) => {
      console.log(value);
      const newDateString = (parseDate(value, true) as moment.Moment).format(DATE_FORMAT);
      if (key === "startDateTime")
        setStartDateTime(newDateString)
      else if (key === "endDateTime")
        setEndDateTime(newDateString)
    }

  const updateTemplateItems = (items: any[]) => {
    console.log(items);

    // let selectedChair = props.chairProviderIdList?.map((cpil: any) =>  props.chairList?.find((item: any) => item.provider === cpil)?.name )

    const newTemplateItems = items.map((item: any, index: number) => ({
      ...item,
      active: <TemplateItemActive
                index={index}
                item={item}
                templateItemIndexList={templateItemIndexList}
                setTemplateItemIndexList={setTemplateItemIndexList}
              />,
      detail: `${item.start_time.substring(0, 5)}-${item.end_time.substring(0, 5)}`
              + `${item.inactive ? " (งดออกตรวจ)" : ""}` + `\n`
              + `เก้าอี้: ${item.chair ? props.chairOptions.find((chair: any) => chair.value === item.chair)?.text : props.selectedChairText ? props.selectedChairText : "ไม่ระบุ"}`,
      edit: <Button 
              color="blue"
              onClick={(e: any) => {
                console.log("check item: ", item);
                // const item = templateItems?.[index];
                // if (item === null || typeof item === "undefined") return
                setTemplateItemIndex(index);
                const start_serial = serialFromTime(item?.start_time);
                console.log('start_serial: ', start_serial);
                const end_serial = serialFromTime(item?.end_time);
                console.log('end_serial: ', end_serial);
                setDoctorStartSerial(start_serial);
                setDoctorEndSerial(end_serial);
                updateTimeOptions(start_serial, end_serial, 6);
                setInactive(item?.inactive);
                setChair(item?.chair || props.defaultChair);
                setSlotLength(item?.slot_length || props.defaultSlotLength);
                setShowModal(true);
              }}>
              แก้ไข
            </Button>
    }));
    console.log(newTemplateItems);
    setTemplateItems(newTemplateItems);
  }

  // console.log(" CardSelectTemplate templateItems: ", scheduleTemplates, selectedTemplate)

  return (
    <div>
      <CardSelectTemplateUX
        scheduleTemplates={scheduleTemplates}
        templateRowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
          style: {
            backgroundColor: selectedTemplate && rowInfo?.original?.id === selectedTemplate?.id ? "#cccccc" : "#d6ecf3",
          },
          onClick: () => onSelectTemplate(rowInfo?.original),
        })}
        startDateTime={formatADtoBEString(moment(startDateTime).format(DATE_FORMAT))}
        changeDate={changeDate}
        endDateTime={formatADtoBEString(moment(endDateTime).format(DATE_FORMAT))}
        templateItems={templateItems}
        templateItemProps={(state: any, rowInfo: any, column: any, instance: any) => ({
          style: { backgroundColor: "#d6ecf3" },
        })}
        
        disableCreate={props.disableCreate}
        buttonCreate={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={(e: any) => {
              let templateIs = templateItems?.map((item: any) => {
                let { active, detail, edit, ...rest } = item;
                return { ...rest };
              });
              //
              props.createDSBFromTemplateItems(templateIs, templateItemIndexList, startDateTime, endDateTime ,props?.card,props?.card);
            }}
          
            // data
            paramKey={props?.card}
            buttonLoadCheck={
              props.buttonLoadCheck?.[props?.card]
            }
            disabled={props.disableCreate}
            // config
            color="green"
            name="_CREATE"
            size="small"
            style={{ width: "100%" }}
            title="ลงตารางจากเทมเพลท"
            />
          }
      />
      <Modal open={showModal} closeOnDimmerClick={true} onClose={() => setShowModal(false)}>
        <ModSchedulingDetailUX
          blockDetail={(() => {
            if (templateItemIndex !== null) {
              const item = templateItems?.[templateItemIndex];
              return `${item.weekday} ` + `${item.start_time.substring(0, 5)}-${item.end_time.substring(0, 5)}`;
            }
            return "x";
          })()}
          // Start / End time
          dentistStartSerial={doctorStartSerial}
          startTimeOptions={timeOptions}
          changeStartSerial={(e: any, v: any) => setDoctorStartSerial(v.value)}
          dentistEndSerial={doctorEndSerial}
          endTimeOptions={timeOptions}
          changeEndSerial={(e: any, v: any) => setDoctorEndSerial(v.value)}
          // Chair
          chair={chair}
          chairOptions={props.chairOptions}
          changeChair={(e: any, v: any) => setChair(v.value)}
          // Slot Length
          slotLength={slotLength}
          changeSlotLength={(e: any) => setSlotLength(e.target.value)}
          // Inactive
          inactive={inactive}
          changeInactive={() => setInactive(!inactive)}
          hideSlotLength={true}
          displayZone={true}
          hideExceptNewPatient={props.hideExceptNewPatient || true}
          zone={props.zone?.name || "ไม่ระบุ"}
          inactiveLabel="ปิดซ่อม"
          // Finish button
          finish={(e: any) => {
            if (templateItemIndex !== null) {
              let templateItem = templateItems?.[templateItemIndex];
              if (templateItem === null) return;
              templateItems[templateItemIndex] = {
                ...templateItem,
                ...(chair ? { chair: chair } : {}),
                ...(slotLength ? { slot_length: slotLength } : {}),
                inactive: inactive,
                start_time: timeOptions.find((option: any) => option.value === doctorStartSerial)?.text + ":00",
                end_time: timeOptions.find((option: any) => option.value === doctorEndSerial)?.text + ":00",
              };
              console.log(templateItems);
              updateTemplateItems(templateItems);
            }
            setShowModal(false);
          }}
        />
      </Modal>

      <ModConfirm
        closeIcon={true}
        titleColor={"red"}
        openModal={false}
        titleName={"ยืนยันงดออกตรวจ"}
        content={
          <div style={{ textAlign: "center", lineHeight: 2.25, margin: "-10px", fontWeight: "bold" }}>
            <div>วันที่ 01/07/2022 [13:00-16:30]</div>
            <div>มีผู้ป่วยนัดหมาย นพ.อมตะ วงสุวรรณ จำนวน 8 คน</div>
            <div>จะต้องยืนยันวันเวลานัดหมายใหม่อีกครั้ง</div>
          </div>
        }
        // onDeny={() => this.setState({ openModMessage: false })}
        // onApprove={() => this.setState({ openModMessage: false })}
        // onCloseWithDimmeClick={() => this.setState({ openModMessage: false })}
        approveButtonText={"ใช่"}
        approveButtonColor={"green"}
        denyButtonText={"ไม่"}
        denyButtonColor={"red"}
      />
      {/* <Modal open={true}>55</Modal> */}
    </div>
  );
}

export default CardSelectTemplate
