import React, { useCallback, useMemo, useState } from "react";
import { Input } from "semantic-ui-react";

import moment from "moment";
import { Column, RowInfo } from "react-table-6";

// Frameworks
import { Table } from "react-lib/frameworks/Table";

// UX
import CardStockOrderWorkingUX from "./CardStockOrderWorkingUX";

// Common
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

const CARD_NAME = "CardStockTransferOrder";

// Types
type CardStockOrderWorkingProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: any;
  StockTransferOrderSequence?: any;
  // CommonInterface
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  selectedDivision?: Record<string, any> | null;
};

const CardStockOrderWorking = (props: CardStockOrderWorkingProps) => {
  const [openModConfirmApprove, setOpenModConfirmApprove] =
    useState<boolean>(false);
  const [openModConfirmReject, setOpenModConfirmReject] = useState(false);

  const isProvider = useMemo(() => {
    return (
      props.StockTransferOrderSequence?.selectedBox?.provider ===
      props.selectedDivision?.id
    );
  }, [
    props.StockTransferOrderSequence?.selectedBox?.provider,
    props.selectedDivision?.id,
  ]);

  const isRequester = useMemo(() => {
    return (
      props.StockTransferOrderSequence?.selectedBox?.request_division ===
      props.selectedDivision?.name
    );
  }, [
    props.StockTransferOrderSequence?.selectedBox?.provider,
    props.selectedDivision?.id,
  ]);

  // Use Memo
  const showApproveBox = useMemo(() => {
    return (
      props.StockTransferOrderSequence?.selectedBox?.status === "REQUESTED" &&
      isProvider
    );
  }, [props.StockTransferOrderSequence?.selectedBox, isProvider]);

  const showRejectBox = useMemo(() => {
    return (
      props.StockTransferOrderSequence?.selectedBox?.status === "REQUESTED" &&
      // || props.StockTransferOrderSequence?.selectedBox?.status === "DELIVERED"
      isProvider
    );
  }, [props.StockTransferOrderSequence?.selectedBox, isProvider]);

  const showDeliverBox = useMemo(() => {
    return (
      props.StockTransferOrderSequence?.selectedBox?.status === "APPROVED" &&
      isProvider
    );
  }, [props.StockTransferOrderSequence?.selectedBox, isProvider]);

  const showReceiveBox = useMemo(() => {
    return (
      props.StockTransferOrderSequence?.selectedBox?.status === "DELIVERED" &&
      isRequester
    );
  }, [props.StockTransferOrderSequence?.selectedBox, isRequester]);

  const workItems = useMemo(() => {
    return props.StockTransferOrderSequence?.selectedBox?.items?.map(
      (item: any, idx: any) => ({
        ...item,
        request_quantity: (
          <div
            style={{ display: "grid", placeContent: "center", height: "100%" }}
          >
            {item.request_quantity}
          </div>
        ),
        provide_quantity_input: (
          <div style={{ margin: "-7px -5px" }}>
            {item.items?.map((acc: any, index: number, self: any[]) => (
              <div
                style={{
                  borderBottom:
                    self.length - 1 !== index
                      ? "solid 1px rgba(0,0,0,0.05)"
                      : "",
                  padding: "7px",
                }}
              >
                {acc?.quantity * -1}
              </div>
            ))}
          </div>
        ),
        total_amount: (
          <div style={{ margin: "-7px -5px" }}>
            {item.items?.map((acc: any, index: number, self: any[]) => {
              if (index === 0) item.items[index]["sum"] = acc?.quantity;
              else
                item.items[index]["sum"] =
                  acc?.quantity + item.items[index - 1]["sum"];
              return (
                <div
                  style={{
                    borderBottom:
                      self.length - 1 !== index
                        ? "solid 1px rgba(0,0,0,0.05)"
                        : "",
                    padding: "7px",
                  }}
                >
                  {acc?.sum * -1 || "-"}
                </div>
              );
            })}
          </div>
        ),
        receive_quantity_input: (
          <div style={{ margin: "-7px -5px" }}>
            {item.items?.map((acc: any, index: number, self: any[]) => (
              <div
                style={{
                  borderBottom:
                    self.length - 1 !== index
                      ? "solid 1px rgba(0,0,0,0.05)"
                      : "",
                  padding: "7px",
                }}
              >
                {acc?.quantity * -1}
              </div>
            ))}
          </div>
        ),
        total_recv_amount: (
          <div style={{ margin: "-7px -5px" }}>
            {item.items?.map((acc: any, index: number, self: any[]) => {
              if (index === 0) item.items[index]["sum"] = acc?.quantity;
              else
                item.items[index]["sum"] =
                  acc?.quantity + item.items[index - 1]["sum"];
              return (
                <div
                  style={{
                    borderBottom:
                      self.length - 1 !== index
                        ? "solid 1px rgba(0,0,0,0.05)"
                        : "",
                    padding: "7px",
                  }}
                >
                  {acc?.sum * -1 || "-"}
                </div>
              );
            })}
          </div>
        ),
        lot_no: (
          <div style={{ margin: "-7px -5px" }}>
            {item.items?.map((acc: any, index: number, self: any[]) => (
              <div
                style={{
                  borderBottom:
                    self.length - 1 !== index
                      ? "solid 1px rgba(0,0,0,0.05)"
                      : "",
                  padding: "7px",
                  textAlign: "center",
                }}
              >
                {acc?.lot?.mfc_no || "\u00a0"}
              </div>
            ))}
          </div>
        ),
        expired_date: (
          <div style={{ margin: "-7px -5px" }}>
            {item.items?.map((acc: any, index: number, self: any[]) => (
              <div
                style={{
                  borderBottom:
                    self.length - 1 !== index
                      ? "solid 1px rgba(0,0,0,0.05)"
                      : "",
                  padding: "7px",
                  textAlign: "center",
                }}
              >
                {!!acc?.lot?.exp_datetime &&
                  moment(acc?.lot?.exp_datetime, "YYYY-MM-DD HH:mm").format(
                    "DD/MM/YYYY"
                  )}
              </div>
            ))}
          </div>
        ),
        quantity: (
          <div style={{ margin: "-7px -5px" }}>
            {item.items?.map((acc: any, index: number, self: any[]) => (
              <div
                style={{
                  borderBottom:
                    self.length - 1 !== index
                      ? "solid 1px rgba(0,0,0,0.05)"
                      : "",
                  padding: "7px",
                }}
              >
                {acc?.lot?.get_quantity || "-"}
              </div>
            ))}
          </div>
        ),
      })
    );
  }, [
    props.StockTransferOrderSequence,
    props.StockTransferOrderSequence?.selectedBox,
  ]);

  // Handler
  const handleGetTopTheadThProps = (
    state: any,
    rowInfo: RowInfo,
    column: Column,
    instance: any
  ) => {
    return {
      style: ["คลังผู้ให้โอนสินค้า", "จำนวนจ่าย จากผู้ให้สินค้า"].includes(
        (column.Header || "") as string
      )
        ? {}
        : { border: "none", backgroundColor: "white" },
    };
  };

  const handleGetTheadThProps = (
    state: any,
    rowInfo: RowInfo,
    column: Column,
    instance: any
  ) => {
    return {
      style: ["จำนวนขอ", "จำนวนจ่าย"].includes((column.Header || "") as string)
        ? { backgroundColor: "#2185D0" }
        : {},
    };
  };

  const handleGetTrProps = (
    state: any,
    rowInfo: RowInfo,
    column: Column,
    instance: any
  ) => ({
    onClick: () => {
      // const item = rowInfo?.original;
      // props.runSequence({
      //   sequence: "StockTransferOrder",
      //   action: "GET_LOTS_DETAIL",
      //   id: item?.id,
      //   index: rowInfo?.index,
      //   selectedBox: props.StockTransferOrderSequence?.selectedBox,
      // });
    },
  });

  // Mod
  const handleCloseModConfirmApprove = () => {
    setOpenModConfirmApprove(false);
  };

  const handleCloseModConfirmReject = () => {
    setOpenModConfirmApprove(false);
  };

  const handleApproveClick = () => {
    props.runSequence({
      sequence: "StockTransferOrder",
      action: "APPROVE",
      card: CARD_NAME,
    });
  };

  const handleDeliverClick = () => {
    props.runSequence({
      sequence: "StockTransferOrder",
      action: "DELIVER",
      card: CARD_NAME,
    });
  };

  const handleReceiveClick = () => {
    props.runSequence({
      sequence: "StockTransferOrder",
      action: "RECEIVE",
      card: CARD_NAME,
    });
  };

  const handleRejectClick = () => {
    props.runSequence({
      sequence: "StockTransferOrder",
      action: "REJECT",
      card: CARD_NAME,
    });
  };

  const handleEditClick = () => {
    props.runSequence({
      sequence: "StockTransferOrder",
      action: "EDIT",
      card: CARD_NAME,
    });
  };

  const handleClose = () => {
    props.setProp("StockTransferOrderSequence.selectedBox", null);
  };

  // console.log("CardStockOrderWorkingUX", props);

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_NAME}`, null);
        }}
        error={props.errorMessage?.[CARD_NAME]}
        success={null}
      />

      <CardStockOrderWorkingUX
        StockTransferOrderSequence={props.StockTransferOrderSequence}
        // ErrorMessage={<ErrorMessage error={props?.errorMessage?.[CARD_NAME]} />}
        // data
        items={workItems}
        // config
        showApproveBox={showApproveBox}
        showRejectBox={showRejectBox}
        showDeliverBox={showDeliverBox}
        showReceiveBox={showReceiveBox}
        // callback
        // table
        getTheadThProps={handleGetTheadThProps}
        getTrProps={handleGetTrProps}
        onClose={handleClose}
        // Element
        TopTable={
          // <Table
          //   headers=",,,คลังผู้ให้โอนสินค้า,,"
          //   widths="150,,150,450,150,150"
          //   showPagination={false}
          //   minRows={0}
          //   resizable={false}
          //   style={{ border: "none" }}
          //   // callback
          //   getTheadThProps={handleGetTopTheadThProps}
          //   // Element
          //   NoDataComponent={() => null}
          // />
          <Table
            headers=",,,,,,จำนวนจ่าย จากผู้ให้สินค้า,"
            widths="100,,100,100,100,100,200,200"
            showPagination={false}
            minRows={0}
            resizable={false}
            style={{ border: "none" }}
            // callback
            getTheadThProps={handleGetTopTheadThProps}
            // Element
            NoDataComponent={() => null}
          />
        }
        buttonApprove={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleApproveClick}
            // data
            paramKey={`${CARD_NAME}`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_NAME}_APPROVE`]}
            // config
            color={"yellow"}
            title={"Approve"}
          />
        }
        buttonReject={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleRejectClick}
            // data
            paramKey={`${CARD_NAME}`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_NAME}_REJECT`]}
            // config
            color={"red"}
            title={"Reject"}
          />
        }
        buttonDeliver={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleDeliverClick}
            // data
            paramKey={`${CARD_NAME}`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_NAME}_DELIVER`]}
            // config
            color={"green"}
            title={"Deliver"}
          />
        }
        buttonReceive={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleReceiveClick}
            // data
            paramKey={`${CARD_NAME}`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_NAME}_RECEIVE`]}
            // config
            color={"green"}
            title={"Receive"}
          />
        }
        buttonEdit={
          // <ButtonLoadCheck
          //   // function
          //   setProp={props.setProp}
          //   onClick={handleEditClick}
          //   // data
          //   paramKey={`${CARD_NAME}`}
          //   buttonLoadCheck={
          //     props.buttonLoadCheck?.[
          //       `${CARD_NAME}`
          //     ]
          //   }
          //   // config
          //   color={"yellow"}
          //   title={"Edit"}
          // />
          <></>
        }
      />

      <ModConfirm
        openModal={openModConfirmApprove}
        titleName="อนุมัติ คำขอสินค้า"
        titleColor="green"
        size="mini"
        denyButtonColor="red"
        denyButtonText="ยกเลิก"
        approveButtonColor="blue"
        approveButtonText="ตกลง"
        content={
          <div
            style={{
              padding: "0.5rem 0",
              lineHeight: 1.5,
              margin: "-1.5rem 0",
            }}
          >
            <div>โปรดระบุเหตุผล</div>
            <div>
              เนื่องจากจ่ายสินค้าไม่ครบตามจำนวนขอ
              <span style={{ color: "red" }}>*</span>
            </div>
            <Input style={{ width: "100%", marginTop: "1rem" }} />
          </div>
        }
        // onApprove={handleConfirmRemove}
        onDeny={handleCloseModConfirmApprove}
        onCloseWithDimmerClick={handleCloseModConfirmApprove}
      />

      <ModConfirm
        openModal={openModConfirmReject}
        titleName="ปฏิเสธ คำขอสินค้า"
        titleColor="red"
        size="mini"
        denyButtonColor="red"
        denyButtonText="ยกเลิก"
        approveButtonColor="blue"
        approveButtonText="ตกลง"
        content={
          <div
            style={{
              padding: "0.5rem 0",
              lineHeight: 1.5,
              margin: "-1.5rem 0",
            }}
          >
            <div>
              โปรระบุเหตุผล ปฏิเสธคำขอ
              <span style={{ color: "red" }}>*</span>
            </div>
            <Input style={{ width: "100%", marginTop: "1rem" }} />
          </div>
        }
        // onApprove={handleConfirmRemove}
        onDeny={handleCloseModConfirmReject}
        onCloseWithDimmerClick={handleCloseModConfirmReject}
      />
    </div>
  );
};

const MOCK_DATA = [
  {
    code: "PARA2T-",
    name: "Paracetamol 500 mg ",
    request: "100",
    items: [
      {
        lot_no: "2A02",
        expired_date: "30/11/2566",
        quantity: "95",
        amount: "95",
      },
      {
        lot_no: "4C09",
        expired_date: "30/11/2569",
        quantity: "5000",
        amount: "5",
      },
    ],
    total_amount: "100",
  },
];

export default React.memo(CardStockOrderWorking);
