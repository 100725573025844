import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'

const CardPreOperationUX = (props: any) => {
    return(
      <div>
        <div
          style={{ paddingTop: "20px", marginBottom: "-6px" }}>
          
          <Button
            color={props.containerName === "ORRequest" ? "blue":  ""}
            name="ORRequest"
            onClick={props.onSetContainerName}
            style={{marginLeft: "15px", display: "none"}}>
            OR Request
          </Button>
          <Button
            color={props.containerName === "PreOPVisit" ? "blue":  ""}
            name="PreOPVisit"
            onClick={props.onSetContainerName}
            style={{marginLeft: "15px"}}>
            Pre-OP Visit
          </Button>
          <Button
            color={props.containerName === "ORRegistrationArea" ? "blue":  ""}
            name="ORRegistrationArea"
            onClick={props.onSetContainerName}
            style={{marginLeft: "15px"}}>
            OR Registration Area
          </Button>
          <Button
            color={props.containerName === "HoldingRoom" ? "blue":  ""}
            name="HoldingRoom"
            onClick={props.onSetContainerName}
            style={{marginLeft: "15px"}}>
            Holding Room
          </Button>
          <Button
            color={props.containerName === "AssessmentBundle" ? "blue":  ""}
            disabled={true}
            name="AssessmentBundle"
            onClick={props.onSetContainerName}
            style={{marginLeft: "15px", display: "none"}}>
            แบบประเมินแรกรับ
          </Button>
          <Button
            color={props.containerName === "ReassessmentBundle" ? "blue":  ""}
            disabled={true}
            name="ReassessmentBundle"
            onClick={props.onSetContainerName}
            style={{marginLeft: "15px", display: "none"}}>
            แบบประเมินออกจากห้องตรวจ
          </Button>
        </div>
        <hr>
        </hr>
      </div>
    )
}

export default CardPreOperationUX

export const screenPropsDefault = {}

/* Date Time : Tue Mar 07 2023 04:25:06 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingTop: \"20px\", marginBottom: \"-6px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "OR Request"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"ORRequest\" ? \"blue\":  \"\""
        },
        "name": {
          "type": "value",
          "value": "ORRequest"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetContainerName"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", display: \"none\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Pre-OP Visit"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"PreOPVisit\" ? \"blue\":  \"\""
        },
        "name": {
          "type": "value",
          "value": "PreOPVisit"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetContainerName"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "OR Registration Area"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"ORRegistrationArea\" ? \"blue\":  \"\""
        },
        "name": {
          "type": "value",
          "value": "ORRegistrationArea"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetContainerName"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Holding Room"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"HoldingRoom\" ? \"blue\":  \"\""
        },
        "name": {
          "type": "value",
          "value": "HoldingRoom"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetContainerName"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "แบบประเมินแรกรับ"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"AssessmentBundle\" ? \"blue\":  \"\""
        },
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AssessmentBundle"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetContainerName"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", display: \"none\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "แบบประเมินออกจากห้องตรวจ"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"ReassessmentBundle\" ? \"blue\":  \"\""
        },
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "ReassessmentBundle"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetContainerName"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", display: \"none\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 9,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardPreOperationUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
