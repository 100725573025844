import { useIntl } from "react-intl";
import React from "react";
import {
  Button,
  Dropdown,
  Input,
  Form,
  FormField,
  FormGroup,
  Radio,
} from "semantic-ui-react";
import { Table } from "react-lib/frameworks/Table";

const ScannedDocument = React.forwardRef((props: any, ref: any) => {
  const intl = useIntl();
  const isMounted = React.useRef(true);

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div
      style={{ display: "grid", gridTemplateColumns: "735px", height: "100%" }}
    >
      <div
        style={{ gridColumn: 1, margin: "5px", border: "solid #cccccc 1px" }}
      >
        <div style={{ margin: "5px" }}>
          <Button
            color={props.tab === "BarcodeScan" ? "blue" : ""}
            onClick={(e) => {
              props.setScannedDocumentTab("BarcodeScan");
            }}
          >
            {intl.formatMessage({ id: "cukey417" })}
          </Button>
          <Button
            color={props.tab === "NewScan" ? "blue" : ""}
            onClick={(e) => {
              props.setScannedDocumentTab("NewScan");
            }}
          >
            {intl.formatMessage({ id: "cukey304" })}
          </Button>
        </div>
        <div>{props.children.find((elm) => elm.key === "errorMessageHN")}</div>
        <div
          style={{
            backgroundColor: "#044c84",
            borderRadius: "5px",
            padding: "5px",
            height: "50px",
            display:
              !props.tab || props.tab !== "BarcodeScan" ? "block" : "none",
          }}
        >
          <Form>
            <FormGroup inline={true}>
              <FormField inline={true} width="7">
                <div style={{ display: "flex" }}>
                  <label style={{ color: "white" }}>
                    {intl.formatMessage({ id: "cukey92" })}
                  </label>
                  <div style={{ color: "red", paddingLeft: "5px" }}>*</div>
                </div>
                <div style={{ width: "100%" }}>
                  {props.children[0].find(
                    (elm) => elm.key === "patient_search_box_1"
                  )}
                </div>
              </FormField>
              <FormField inline={true} width="2">
                <label style={{ color: "white" }}>
                  {intl.formatMessage({ id: "cukey298" })}
                </label>
              </FormField>
              <FormField width="7">
                <div style={{ width: "100%" }}>
                  {props.patientInputName || (
                    <Input
                      readOnly={true}
                      value={props.patientInfo?.full_name || ""}
                    />
                  )}
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <div
          style={{
            padding: "5px",
            display:
              !props.tab || props.tab === "ExistingScan" ? "block" : "none",
          }}
        >
          <div>
            <div>
              {props.children.find((elm) => elm.key === "errorMessage")}
            </div>
            <div style={{ marginTop: "10px", marginBottom: "-5px" }}>
              <Form>
                <FormGroup inline={true}>
                  <FormField width={8}>
                    <label
                      style={{
                        fontSize: "17px",
                        marginTop: "-15px",
                        marginLeft: "5px",
                      }}
                    >
                      {intl.formatMessage({ id: "cukey417" })}
                    </label>
                  </FormField>
                  <FormField width={8}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        paddingLeft: "5px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        {}
                        <label style={{ width: "90px" }}>
                          {intl.formatMessage({ id: "cukey222" })}
                        </label>
                      </div>
                      <div style={{ width: "100%" }}>
                        {props.children.find(
                          (elm) => elm.key === "searchBoxEncounter"
                        )}
                      </div>
                    </div>
                  </FormField>
                </FormGroup>
              </Form>
            </div>
          </div>
          <div style={{ display: "none" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            ></div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>{intl.formatMessage({ id: "cukey220" })}</label>
              <div style={{ width: "100%" }}>
                {props.children.find((elm) => elm.key === "searchBoxEncounter")}
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <div style={{ display: "flex" }}>
                <label>{intl.formatMessage({ id: "cukey335" })}</label>
                <div style={{ color: "red", paddingLeft: "5px" }}>*</div>
              </div>
              <Dropdown
                onChange={(e, v) => {
                  props.handleChangeDocumentCategory(v.value);
                }}
                options={props.documentCategoryOptions}
                search={true}
                selection={true}
                style={{ display: !props.disabledRequiredField ? "" : "none" }}
                value={props.documentCategory}
              ></Dropdown>
              <Input
                readOnly={true}
                style={{ display: props.disabledRequiredField ? "" : "none" }}
                value={
                  props.documentCategoryOptions?.find(
                    (doc) => doc.value === props.documentCategory
                  )?.text || ""
                }
              ></Input>
            </div>
            <div
              className="field error"
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <div style={{ display: "flex" }}>
                <label>{intl.formatMessage({ id: "cukey301" })}</label>
                <div style={{ color: "red", paddingLeft: "5px" }}>*</div>
              </div>
              <Dropdown
                onChange={(e, v) => {
                  props.setProp("documentType", v.value);
                }}
                options={props.documentTypeOptions}
                search={true}
                selection={true}
                value={props.documentType}
              ></Dropdown>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <div style={{ display: "flex" }}>
                <label>{intl.formatMessage({ id: "cukey474" })}</label>
              </div>
              <Dropdown
                onChange={(e, v) => {
                  props.setProp("documentOwnerDivision", v.value);
                }}
                options={props.documentOwnerDivisionOptions}
                search={true}
                selection={true}
                value={props.documentOwnerDivision}
              ></Dropdown>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>{intl.formatMessage({ id: "cukey455" })}</label>
              <Input
                onChange={(e, v) => {
                  props.setProp("documentNo", v.value);
                }}
                value={props.documentNo}
              ></Input>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <div style={{ display: "flex" }}>
                <label>{intl.formatMessage({ id: "cukey395" })}</label>
              </div>
              <div style={{ width: "100%" }}>
                {props.children[1].find((elm) => elm.key === "startDoc")}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>{intl.formatMessage({ id: "cukey400" })}</label>
              <div style={{ width: "100%" }}>
                {props.children[1].find((elm) => elm.key === "endDoc")}
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>{intl.formatMessage({ id: "cukey313" })}</label>
              <div style={{ width: "100%" }}>
                {props.children.find((elm) => elm.key === "searchBoxDoctor")}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>{intl.formatMessage({ id: "cukey427" })}</label>
              <Input
                onChange={(e, v) => {
                  props.setProp("remark", v.value);
                }}
                value={props.remark}
              ></Input>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                padding: "5px 1px 6px",
              }}
            >
              <Button color="blue" onClick={props.handleClickScanDocument}>
                {intl.formatMessage({ id: "cukey188" })}
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            ></div>
          </div>
          <Table
            columns={props.columnsScannedDocument}
            data={props.scannedDocumentData}
            defaultPageSize="5"
            getTrProps={(state, rowInfo, column, instance) => {
              return props.scannedRowProps(state, rowInfo, column, instance);
            }}
            showPagination={false}
            style={{ height: "300px" }}
          ></Table>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px",
            }}
          >
            <Button color="red" onClick={props.handleCancelDocScan}>
              {intl.formatMessage({ id: "cukey356" })}
            </Button>
            <Button color="green" onClick={props.handleSaveDocScan}>
              {intl.formatMessage({ id: "cukey321" })}
            </Button>
          </div>
        </div>
        <div
          style={{
            padding: "5px",
            display: !props.tab || props.tab === "NewScan" ? "block" : "none",
          }}
        >
          <div>
            <div>
              <Form>
                <FormField>
                  <label>{intl.formatMessage({ id: "cukey304" })}</label>
                </FormField>
              </Form>
              <hr></hr>
            </div>
            <div>
              {props.children.find((elm) => elm.key === "errorMessage")}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>{intl.formatMessage({ id: "cukey224" })}</label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "45%" }}>
                  {props.children[1].find((elm) => elm.key === "startVisit")}
                </div>
                <label>{intl.formatMessage({ id: "cukey312" })}</label>
                <div style={{ width: "45%" }}>
                  {props.children[1].find((elm) => elm.key === "endVisit")}
                </div>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>{intl.formatMessage({ id: "cukey222" })}</label>
              <div style={{ width: "100%" }}>
                {props.children.find((elm) => elm.key === "searchBoxEncounter")}
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <div style={{ display: "flex" }}>
                <label>{intl.formatMessage({ id: "cukey335" })}</label>
              </div>
              <Dropdown
                onChange={(e, v) => {
                  props.handleChangeDocumentCategory(v.value);
                }}
                options={props.documentCategoryOptions}
                search={true}
                selection={true}
                style={{ display: !props.disabledRequiredField ? "" : "none" }}
                value={props.documentCategory}
              ></Dropdown>
              <Input
                readOnly={true}
                style={{ display: props.disabledRequiredField ? "" : "none" }}
                value={
                  props.documentCategoryOptions?.find(
                    (doc) => doc.value === props.documentCategory
                  )?.text || "แสดงทุกประเภทเอกสาร"
                }
              ></Input>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <div style={{ display: "flex" }}>
                <label>{intl.formatMessage({ id: "cukey313" })}</label>
              </div>
              <Dropdown
                onChange={(e, v) => {
                  props.setProp("doctorSearch", v.value);
                }}
                options={props.doctorSearchOptions}
                search={true}
                selection={true}
                value={props.doctorSearch}
              ></Dropdown>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <div style={{ display: "flex" }}>
                <label>{intl.formatMessage({ id: "cukey301" })}</label>
              </div>
              <Dropdown
                onChange={(e, v) => {
                  props.setProp("documentType", v.value);
                }}
                options={props.documentTypeOptions}
                search={true}
                selection={true}
                value={props.documentType}
              ></Dropdown>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>{intl.formatMessage({ id: "cukey474" })}</label>
              <Dropdown
                onChange={(e, v) => {
                  props.setProp("documentOwnerDivision", v.value);
                }}
                options={props.documentOwnerDivisionOptions}
                search={true}
                selection={true}
                value={props.documentOwnerDivision}
              ></Dropdown>
            </div>
          </div>
          <div style={{ display: "none" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label>{intl.formatMessage({ id: "cukey455" })}</label>
              <Input
                onChange={(e, v) => {
                  props.setProp("documentNo", v.value);
                }}
                value={props.documentNo}
              ></Input>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label style={{ width: "100%" }}>
                {intl.formatMessage({ id: "cukey225" })}
              </label>
              <div style={{ width: "100%" }}>
                {props.children.find((elm) => elm.key === "searchBoxEncounter")}
              </div>
            </div>
          </div>
          <div style={{ marginTop: "5px" }}>
            <Form>
              <FormGroup inline={true}>
                <FormField width="3">
                  <label style={{ fontWeight: "normal" }}>
                    {intl.formatMessage({ id: "cukey395" })}
                  </label>
                </FormField>
                <FormField width="6">
                  <div style={{ width: "100%" }}>
                    {props.children[1].find((elm) => elm.key === "startDoc")}
                  </div>
                </FormField>
                <FormField width="1">
                  <label style={{ fontWeight: "normal" }}>
                    {intl.formatMessage({ id: "cukey312" })}
                  </label>
                </FormField>
                <FormField width="6">
                  <div style={{ width: "100%" }}>
                    {props.children[1].find((elm) => elm.key === "endDoc")}
                  </div>
                </FormField>
                <FormField width="2">
                  <Button
                    color="green"
                    onClick={props.handleClickSearchDocument}
                  >
                    {intl.formatMessage({ id: "cukey279" })}
                  </Button>
                </FormField>
              </FormGroup>
            </Form>
          </div>
          <div style={{ display: "none" }}>
            <Form>
              <FormGroup inline={true}>
                <FormField width="5">
                  <label style={{ fontWeight: "normal" }}>
                    {intl.formatMessage({ id: "cukey399" })}
                  </label>
                </FormField>
                <FormField width="6">
                  <div style={{ width: "100%" }}>
                    {props.children[1].find((elm) => elm.key === "startScan")}
                  </div>
                </FormField>
                <FormField width="1">
                  <label style={{ fontWeight: "normal" }}>
                    {intl.formatMessage({ id: "cukey312" })}
                  </label>
                </FormField>
                <FormField width="6">
                  <div style={{ width: "100%" }}>
                    {props.children[1].find((elm) => elm.key === "endScan")}
                  </div>
                </FormField>
              </FormGroup>
            </Form>
          </div>
          <div style={{ display: "none" }}>
            <Form>
              <FormGroup inline={true}>
                <FormField width="5">
                  <label style={{ fontWeight: "normal" }}>
                    {intl.formatMessage({ id: "cukey400" })}
                  </label>
                </FormField>
                <FormField width="6">
                  <div style={{ width: "100%" }}>
                    {props.children[1].find((elm) => elm.key === "startExpiry")}
                  </div>
                </FormField>
                <FormField width="1">
                  <label style={{ fontWeight: "normal" }}>
                    {intl.formatMessage({ id: "cukey312" })}
                  </label>
                </FormField>
                <FormField width="6">
                  <div style={{ width: "100%" }}>
                    {props.children[1].find((elm) => elm.key === "endExpiry")}
                  </div>
                </FormField>
              </FormGroup>
            </Form>
          </div>
          <div
            style={{
              display: "none",
              justifyContent: "flex-end",
              padding: "5px",
              paddingTop: "0px",
            }}
          >
            <Button color="green" onClick={props.handleClickSearchDocument}>
              {intl.formatMessage({ id: "cukey279" })}
            </Button>
          </div>
          <div>{props.cardFilterDentistElement}</div>
          <Table
            columns={props.columnsSearchDocument}
            data={props.documentSearchData}
            defaultPageSize="7"
            getTdProps={(state, rowInfo, column, instance) => {
              return props.documentSearchRowProps(
                state,
                rowInfo,
                column,
                instance
              );
            }}
            showPagination={false}
            style={{ height: "420px" }}
          ></Table>
          <div style={{ paddingTop: "10px" }}>
            <div>
              <Form>
                <FormGroup inline={true}>
                  <FormField width="2">
                    <Dropdown
                      className="fluid"
                      onChange={(e, v) => {
                        props.handleChangeDocLimit(v.value);
                      }}
                      options={props.pageOptions}
                      selection={true}
                      value={props.scannedDocLimit}
                    ></Dropdown>
                  </FormField>
                  <FormField className="fluid" width="6">
                    <div>{props.paginationElement}</div>
                  </FormField>
                  <FormField width="4">
                    <div>{props.secretElement.checkboxCancel}</div>
                  </FormField>
                  <FormField width="3">
                    <div>{props.secretElement.checkboxSecret}</div>
                  </FormField>
                  <FormField width="4">
                    <div>{props.secretElement.buttonSecret}</div>
                  </FormField>
                </FormGroup>
              </Form>
            </div>
          </div>
        </div>
        <div
          style={{
            padding: "5px",
            display:
              !props.tab || props.tab === "BarcodeScan" ? "block" : "none",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              marginTop: "5px",
            }}
          >
            <div
              style={{
                marginRight: "15px",
                fontWeight: "bold",
                fontWeight: "16px",
              }}
            >
              {intl.formatMessage({ id: "cukey378" })}
            </div>
            <Radio
              checked={props.toggleBarcodeScan}
              onChange={(e, { checked }) =>
                props.setProp("toggleBarcodeScan", checked)
              }
              toggle={true}
            ></Radio>
            <div
              style={{
                margin: "0 15px",
                fontWeight: "500",
                fontWeight: "16px",
              }}
            >
              {intl.formatMessage({ id: "cukey468" })}
            </div>
            <Button
              color={"green"}
              onClick={props.handleClickBarcodeScan}
              size="tiny"
            >
              {intl.formatMessage({ id: "cukey100" })}
            </Button>
          </div>
          <Table
            columns={props.columnsBarcodeScan}
            data={props.scannedDocumentData}
            defaultPageSize="5"
            getTdProps={(state, rowInfo, column, instance) => {
              return props.scannedTdProps(state, rowInfo, column, instance);
            }}
            getTrProps={(state, rowInfo, column, instance) => {
              return props.scannedRowProps(state, rowInfo, column, instance);
            }}
            showPagination={false}
            style={{ height: "300px" }}
          ></Table>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Button
              disabled={props.scannedDocumentData.length === 0}
              onClick={props.handleSaveBarcodeDocument}
              size={"green"}
            >
              {intl.formatMessage({ id: "cukey321" })}
            </Button>
          </div>
          <div
            style={{
              borderRadius: ".28571429rem",
              border: "1px solid rgba(34,36,38,.15)",
              paddingBottom: "15px",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                backgroundColor: "#1a63bb",
                color: "white",
                padding: "5px",
                marginBottom: "10px",
              }}
            >
              <label>{intl.formatMessage({ id: "cukey247" })}</label>
            </div>
            <Form>
              <FormGroup inline={true}>
                <FormField
                  className={props.requiredFieldError.hn ? "error" : ""}
                  width={4}
                >
                  <div
                    style={{
                      display: "grid",
                      width: "100%",
                      paddingLeft: "5px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <label>{intl.formatMessage({ id: "cukey92" })}</label>
                      <div style={{ color: "red", paddingLeft: "5px" }}>*</div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display:
                          props.selectedDocScan?.isPatient &&
                          props.selectedDocScan
                            ? ""
                            : "none",
                      }}
                    >
                      {props.children[0].find(
                        (elm) => elm.key === "patient_search_box_2"
                      )}
                    </div>
                    <Input
                      readOnly={true}
                      style={{
                        display:
                          (props.selectedDocScan?.hn ||
                            !props.selectedDocScan) &&
                          !props.selectedDocScan?.isPatient
                            ? ""
                            : "none",
                      }}
                      value={props.patientHN}
                    ></Input>
                  </div>
                </FormField>
                <FormField width={5}>
                  <div style={{ display: "grid", width: "100%" }}>
                    <label>{intl.formatMessage({ id: "cukey298" })}</label>
                    <Input
                      readOnly={true}
                      value={props.patientFullName}
                    ></Input>
                  </div>
                </FormField>
                <FormField
                  className={
                    props.requiredFieldError.visit_number ? "error" : ""
                  }
                  width="3"
                >
                  <div style={{ display: "grid", width: "100%" }}>
                    <div style={{ display: "flex" }}>
                      <label>{intl.formatMessage({ id: "cukey219" })}</label>
                      <div style={{ color: "red", paddingLeft: "5px" }}>*</div>
                    </div>
                    <div style={{ width: "100%" }}>
                      {props.children.find(
                        (elm) => elm.key === "searchBoxEncounter"
                      )}
                    </div>
                  </div>
                </FormField>
                <FormField
                  className={props.requiredFieldError.startVisit ? "error" : ""}
                  width="4"
                >
                  <div style={{ display: "grid", width: "100%" }}>
                    <div style={{ display: "flex" }}>
                      <label>{intl.formatMessage({ id: "cukey224" })}</label>
                      <div style={{ color: "red", paddingLeft: "5px" }}>*</div>
                    </div>
                    <div>
                      {props.children[1].find(
                        (elm) => elm.key === "startVisit"
                      )}
                    </div>
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup inline={true}>
                <FormField
                  className={props.requiredFieldError.category ? "error" : ""}
                  width={5}
                >
                  <div
                    style={{
                      display: "grid",
                      width: "100%",
                      paddingLeft: "5px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div>{intl.formatMessage({ id: "cukey335" })}</div>
                      <label style={{ color: "red", paddingLeft: "5px" }}>
                        *
                      </label>
                    </div>
                    <Dropdown
                      onChange={(e, v) => {
                        props.handleChangeDocumentCategory(v.value);
                      }}
                      options={props.documentCategoryOptions}
                      search={true}
                      selection={true}
                      style={{
                        minHeight: "36px",
                        display:
                          props.selectedDocScan &&
                          props.selectedDocScan?.isCategory
                            ? ""
                            : "none",
                      }}
                      value={props.documentCategory}
                    ></Dropdown>
                    <Input
                      readOnly={true}
                      style={{
                        display:
                          (props.selectedDocScan?.document_category ||
                            !props.selectedDocScan) &&
                          !props.selectedDocScan?.isCategory
                            ? ""
                            : "none",
                      }}
                      value={
                        props.documentCategoryOptions?.find(
                          (doc) => doc.value === props.documentCategory
                        )?.text || ""
                      }
                    ></Input>
                  </div>
                </FormField>
                <FormField
                  className={props.requiredFieldError.type ? "error" : ""}
                  width={5}
                >
                  <div style={{ display: "grid", width: "100%" }}>
                    <div style={{ display: "flex" }}>
                      <div>{intl.formatMessage({ id: "cukey301" })}</div>
                      <label style={{ color: "red", paddingLeft: "5px" }}>
                        *
                      </label>
                    </div>
                    <Dropdown
                      onChange={(e, v) => {
                        props.setProp("documentType", v.value);
                      }}
                      options={props.documentTypeOptions}
                      search={true}
                      selection={true}
                      style={{
                        minHeight: "36px",
                        display:
                          props.selectedDocScan && props.selectedDocScan?.isType
                            ? ""
                            : "none",
                      }}
                      value={props.documentType}
                    ></Dropdown>
                    <Input
                      readOnly={true}
                      style={{
                        display:
                          (props.selectedDocScan?.document_type ||
                            !props.selectedDocScan) &&
                          !props.selectedDocScan?.isType
                            ? ""
                            : "none",
                      }}
                      value={props.selectedDocScan?.document_type_name || ""}
                    ></Input>
                  </div>
                </FormField>
                <FormField width={4}>
                  <div style={{ display: "grid", width: "100%" }}>
                    <div style={{ display: "flex" }}>
                      <div>{intl.formatMessage({ id: "cukey395" })}</div>
                    </div>
                    <div style={{ width: "100%" }}>
                      {props.children[1].find((elm) => elm.key === "startDoc")}
                    </div>
                  </div>
                </FormField>
                <FormField
                  className={
                    props.requiredFieldError.doctorSearch ? "error" : ""
                  }
                  width={3}
                >
                  <div style={{ display: "grid", width: "100%" }}>
                    <div style={{ display: "flex" }}>
                      <div>{intl.formatMessage({ id: "cukey313" })}</div>
                      <label style={{ color: "red", paddingLeft: "5px" }}>
                        *
                      </label>
                    </div>
                    <div style={{ width: "100%" }}>
                      {props.children.find(
                        (elm) => elm.key === "searchBoxDoctor"
                      )}
                    </div>
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup inline={true}>
                <FormField width={4}>
                  <div
                    style={{
                      display: "grid",
                      width: "100%",
                      paddingLeft: "5px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "-6px",
                        paddingBottom: "3px",
                        display: "flex",
                      }}
                    >
                      <div>{intl.formatMessage({ id: "cukey455" })}</div>
                    </div>
                    <Input
                      onChange={(e, v) => {
                        props.setProp("documentNo", v.value);
                      }}
                      readOnly={props.inputReadOnly}
                      value={props.documentNo}
                    ></Input>
                  </div>
                </FormField>
                <FormField width={5}>
                  <div style={{ display: "grid", width: "100%" }}>
                    <div>{intl.formatMessage({ id: "cukey474" })}</div>
                    <Dropdown
                      onChange={(e, v) => {
                        props.setProp("documentOwnerDivision", v.value);
                      }}
                      options={props.documentOwnerDivisionOptions}
                      search={true}
                      selection={true}
                      style={{
                        minHeight: "36px",
                        display: !props.selectedDocScan ? "none" : "",
                      }}
                      value={props.documentOwnerDivision}
                    ></Dropdown>
                    <Input
                      readOnly={true}
                      style={{ display: !props.selectedDocScan ? "" : "none" }}
                    ></Input>
                  </div>
                </FormField>
                <FormField width={5}>
                  <div style={{ display: "grid", width: "100%" }}>
                    <div>{intl.formatMessage({ id: "cukey400" })}</div>
                    <div style={{ width: "100%" }}>
                      {props.children[1].find(
                        (elm) => elm.key === "startExpiry"
                      )}
                    </div>
                  </div>
                </FormField>
                <FormField width={3}>
                  <div style={{ display: "grid", width: "100%" }}>
                    <div>{intl.formatMessage({ id: "cukey427" })}</div>
                    <Input
                      onChange={(e, v) => {
                        props.setProp("remark", v.value);
                      }}
                      readOnly={props.inputReadOnly}
                      value={props.remark}
                    ></Input>
                  </div>
                </FormField>
              </FormGroup>
            </Form>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                color={"yellow"}
                disabled={!props.selectedDocScan}
                onClick={props.handleClickEditBarcodeDocument}
                style={{ marginRight: "15px" }}
              >
                {intl.formatMessage({ id: "cukey322" })}
              </Button>
            </div>
          </div>
        </div>
        <div>{props.children.find((elm) => elm.key === "modEditDocument")}</div>
        <div>{props.children.find((elm) => elm.key === "modError")}</div>
        <div>{props.children.find((elm) => elm.key === "modSecretAuthen")}</div>
        <div>{props.children.find((elm) => elm.key === "modConfirmSave")}</div>
        <div>{props.children.find((elm) => elm.key === "printDocument")}</div>
        <div>{props.children.find((elm) => elm.key === "loading")}</div>
        <div>{props.children.find((elm) => elm.key === "modCancelAuthen")}</div>
        <div>{props.children.find((elm) => elm.key === "modConfirmEdit")}</div>
      </div>
      <div
        style={{
          gridColumn: 2,
          border: "solid #cccccc 1px",
          margin: "5px",
          backgroundColor: "#767676",
          color: "#fff",
        }}
      >
        <div
          style={{
            width: "100%",
            height: props.errMessage || props.errMessageHN ? "927px" : "927px",
            display: "flex",
          }}
        >
          <div
            style={{
              background: "white",
              height: "100%",
              width: "240px",
              display: !props.tab || props.tab === "NewScan" ? "" : "none",
            }}
          >
            {}
            <div
              style={{ height: "99.5%", overflow: "auto", paddingTop: "5px" }}
            >
              {props.elementThumbnailList}
            </div>
            <div
              style={{
                display: "none",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Button
                color="blue"
                onClick={(e) => {
                  props.onEvent({ message: "PrintDocument", params: {} });
                }}
              >
                {intl.formatMessage({ id: "cukey349" })}
              </Button>
            </div>
          </div>
          <div style={{ background: "#767676", height: "100%", width: "100%" }}>
            {props.children.find((elm) => elm.key === "viewerDocument")}
          </div>
        </div>
      </div>
    </div>
  );
});

export default React.memo(ScannedDocument);

export const screenPropsDefault = {};

/* Date Time : Thu Oct 07 2021 07:24:46 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"grid\", gridTemplateColumns: \"735px\", height: \"100%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridColumn: 1, margin: \"5px\", border: \"solid #cccccc 1px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"5px\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey304"})}
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"NewScan\" ? \"blue\" : \"\""
        },
        "onClick": {
          "type": "code",
          "value": "(e) => { props.setScannedDocumentTab(\"NewScan\") }"
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 34,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 34,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 34,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 34,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\", padding:\"5px\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 11,
      "props": {
        "className": {
          "type": "value",
          "value": "field error"
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\", padding:\"5px\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Dropdown",
      "parent": 15,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) => { props.handleChangeDocumentCategory(v.value) }"
        },
        "options": {
          "type": "code",
          "value": "props.documentCategoryOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display:!props.disabledRequiredField?\"\":\"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.documentCategory"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 13,
      "props": {
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\", padding:\"5px\"  }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 13,
      "props": {
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\", padding:\"5px\"  }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey400"})}
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 14,
      "props": {
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\",  padding:\"5px\"   }"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Button",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "Scan"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleClickScanDocument"
        },
        "size": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey427"})}
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Input",
      "parent": 29,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) => { props.setProp(\"remark\", v.value) }"
        },
        "value": {
          "type": "code",
          "value": "props.remark"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 14,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flexDirection: \"column\", justifyContent: \"flex-end\",  padding:\"5px 1px 6px\"  }"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", display: !props.tab || props.tab === \"ExistingScan\" ? \"block\" : \"none\" }"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 35,
      "name": "Table",
      "parent": 34,
      "props": {
        "columns": {
          "type": "code",
          "value": "props.columnsScannedDocument"
        },
        "data": {
          "type": "code",
          "value": "props.scannedDocumentData"
        },
        "defaultPageSize": {
          "type": "value",
          "value": "5"
        },
        "getTdProps": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "(state, rowInfo, column, instance) => { return props.scannedRowProps( state, rowInfo, column, instance) }"
        },
        "headers": {
          "type": "value",
          "value": ""
        },
        "keys": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"300px\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 34,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\",  padding:\"10px\"   }"
        }
      },
      "seq": 383,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Button",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey356"})}
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleCancelDocScan"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Button",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey321"})}
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleSaveDocScan"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", display: !props.tab || props.tab === \"NewScan\" ? \"block\" : \"none\" }"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 39,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 39,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 39,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 39,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 39,
      "props": {
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 39,
      "props": {
        "style": {
          "type": "code",
          "value": "{display:\"none\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 39,
      "props": {
        "style": {
          "type": "code",
          "value": "{display:\"none\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 40,
      "props": {
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\",  padding:\"5px\"   }"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 40,
      "props": {
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\",  padding:\"5px\"   }"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 41,
      "props": {
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\", padding: \"5px\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 41,
      "props": {
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\",  padding:\"5px\"   }"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 42,
      "props": {
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\",  padding:\"5px\"   }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 42,
      "props": {
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\",  padding:\"5px\"   }"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 43,
      "props": {
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\",  padding:\"5px\"   }"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 43,
      "props": {
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\",  padding:\"5px\"   }"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Dropdown",
      "parent": 47,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) => { props.handleChangeDocumentCategory(v.value) }"
        },
        "options": {
          "type": "code",
          "value": "props.documentCategoryOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display:!props.disabledRequiredField?\"\":\"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.documentCategory"
        }
      },
      "seq": 352,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "label",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey474"})}
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "label",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey455"})}
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "Visit number (VN)"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Dropdown",
      "parent": 51,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) => { props.setProp(\"documentType\", v.value) }"
        },
        "options": {
          "type": "code",
          "value": "props.documentTypeOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.documentType"
        }
      },
      "seq": 413,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\", justifyContent: \"flex-end\", padding:\"5px\", paddingTop:\"0px\" }"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "Button",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey279"})}
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleClickSearchDocument"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 94,
      "name": "Table",
      "parent": 39,
      "props": {
        "columns": {
          "type": "code",
          "value": "props.columnsSearchDocument"
        },
        "data": {
          "type": "code",
          "value": "props.documentSearchData"
        },
        "defaultPageSize": {
          "type": "value",
          "value": "7"
        },
        "getTdProps": {
          "type": "code",
          "value": "(state, rowInfo, column, instance) => { return props.documentSearchRowProps( state, rowInfo, column, instance) }"
        },
        "getTheadThProps": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": ""
        },
        "keys": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"420px\"}"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 39,
      "props": {
        "style": {
          "type": "code",
          "value": "{paddingTop:\"10px\"}"
        }
      },
      "seq": 410,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 12,
      "props": {
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\" ,padding:\"5px\" }"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 12,
      "props": {
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\", padding:\"5px\"  }"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "label",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey455"})}
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "Dropdown",
      "parent": 111,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) => { props.setProp(\"documentOwnerDivision\", v.value) }"
        },
        "options": {
          "type": "code",
          "value": "props.documentOwnerDivisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.documentOwnerDivision"
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 14,
      "props": {
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\",  padding:\"5px\"   }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "label",
      "parent": 128,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey313"})}
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 14,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flexDirection: \"column\", justifyContent: \"flex-end\" }"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 95,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "Form",
      "parent": 140,
      "props": {
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "div",
      "parent": 39,
      "props": {
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 147,
      "props": {
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "Form",
      "parent": 149,
      "props": {
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "FormField",
      "parent": 150,
      "props": {
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "hr",
      "parent": 149,
      "props": {
      },
      "seq": 152,
      "void": true
    },
    {
      "from": null,
      "id": 153,
      "name": "label",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey304"})}
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 34,
      "props": {
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 154,
      "props": {
        "style": {
          "type": "code",
          "value": "{marginTop:\"10px\", marginBottom:\"-5px\"}"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "Form",
      "parent": 155,
      "props": {
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "Dropdown",
      "parent": 16,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) => { props.setProp(\"documentType\", v.value) }"
        },
        "options": {
          "type": "code",
          "value": "props.documentTypeOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.documentType"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "Dropdown",
      "parent": 52,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) => { props.setProp(\"documentOwnerDivision\", v.value) }"
        },
        "options": {
          "type": "code",
          "value": "props.documentOwnerDivisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.documentOwnerDivision"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "Input",
      "parent": 53,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) => { props.setProp(\"documentNo\", v.value) }"
        },
        "value": {
          "type": "code",
          "value": "props.documentNo"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridColumn: 2, border: \"solid #cccccc 1px\", margin: \"5px\",  backgroundColor: \"#767676\", color: \"#fff\" }"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "FormGroup",
      "parent": 144,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "FormField",
      "parent": 171,
      "props": {
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 173,
      "name": "Dropdown",
      "parent": 172,
      "props": {
        "className": {
          "type": "value",
          "value": "fluid"
        },
        "onChange": {
          "type": "code",
          "value": "(e, v) => { props.handleChangeDocLimit(v.value) }"
        },
        "options": {
          "type": "code",
          "value": "props.pageOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.scannedDocLimit"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "FormField",
      "parent": 171,
      "props": {
        "className": {
          "type": "value",
          "value": "fluid"
        },
        "width": {
          "type": "value",
          "value": "6"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "FormField",
      "parent": 171,
      "props": {
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 361,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "FormField",
      "parent": 171,
      "props": {
        "width": {
          "type": "value",
          "value": "4"
        }
      },
      "seq": 362,
      "void": false
    },
    {
      "from": null,
      "id": 186,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#044c84\", borderRadius: \"5px\", padding: \"5px\",height:\"50px\", display: !props.tab || props.tab !== \"BarcodeScan\" ? \"block\" : \"none\" }"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 187,
      "name": "Form",
      "parent": 186,
      "props": {
      },
      "seq": 187,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 188,
      "name": "FormGroup",
      "parent": 187,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 189,
      "name": "FormField",
      "parent": 188,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "7"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 190,
      "name": "FormField",
      "parent": 188,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 193,
      "name": "label",
      "parent": 190,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey298"})}
        },
        "style": {
          "type": "code",
          "value": "{color: \"white\"}"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "div",
      "parent": 189,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children[0].find(elm=>elm.key === \"patient_search_box_1\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 380,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "FormField",
      "parent": 188,
      "props": {
        "width": {
          "type": "value",
          "value": "7"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": null,
      "id": 197,
      "name": "div",
      "parent": 196,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientInputName || <Input readOnly={true} value={props.patientInfo?.full_name || \"\"} />"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "readonly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "Form",
      "parent": 44,
      "props": {
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "FormGroup",
      "parent": 199,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 202,
      "name": "FormField",
      "parent": 200,
      "props": {
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 203,
      "name": "FormField",
      "parent": 200,
      "props": {
        "width": {
          "type": "value",
          "value": "6"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": null,
      "id": 207,
      "name": "label",
      "parent": 202,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey395"})}
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"normal\"}"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 209,
      "name": "FormField",
      "parent": 200,
      "props": {
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 211,
      "name": "FormField",
      "parent": 200,
      "props": {
        "width": {
          "type": "value",
          "value": "6"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": null,
      "id": 213,
      "name": "div",
      "parent": 203,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children[1].find(elm=>elm.key === \"startDoc\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": null,
      "id": 214,
      "name": "label",
      "parent": 209,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey312"})}
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"normal\"}"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": null,
      "id": 215,
      "name": "div",
      "parent": 211,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children[1].find(elm=>elm.key === \"endDoc\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "Form",
      "parent": 45,
      "props": {
      },
      "seq": 216,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 217,
      "name": "FormGroup",
      "parent": 216,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 218,
      "name": "FormField",
      "parent": 217,
      "props": {
        "width": {
          "type": "value",
          "value": "5"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 219,
      "name": "FormField",
      "parent": 217,
      "props": {
        "width": {
          "type": "value",
          "value": "6"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 220,
      "name": "FormField",
      "parent": 217,
      "props": {
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 221,
      "name": "FormField",
      "parent": 217,
      "props": {
        "width": {
          "type": "value",
          "value": "6"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": null,
      "id": 222,
      "name": "label",
      "parent": 218,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey399"})}
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"normal\"}"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": null,
      "id": 223,
      "name": "div",
      "parent": 219,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children[1].find(elm=>elm.key === \"startScan\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": null,
      "id": 224,
      "name": "label",
      "parent": 220,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey312"})}
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"normal\"}"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": null,
      "id": 225,
      "name": "div",
      "parent": 221,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children[1].find(elm=>elm.key === \"endScan\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 226,
      "name": "Form",
      "parent": 46,
      "props": {
      },
      "seq": 226,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 227,
      "name": "FormGroup",
      "parent": 226,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 228,
      "name": "FormField",
      "parent": 227,
      "props": {
        "width": {
          "type": "value",
          "value": "5"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 229,
      "name": "FormField",
      "parent": 227,
      "props": {
        "width": {
          "type": "value",
          "value": "6"
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 230,
      "name": "FormField",
      "parent": 227,
      "props": {
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 231,
      "name": "FormField",
      "parent": 227,
      "props": {
        "width": {
          "type": "value",
          "value": "6"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": null,
      "id": 232,
      "name": "label",
      "parent": 228,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey400"})}
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"normal\"}"
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": null,
      "id": 233,
      "name": "label",
      "parent": 230,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey312"})}
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"normal\"}"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": null,
      "id": 234,
      "name": "div",
      "parent": 229,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children[1].find(elm=>elm.key === \"startExpiry\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": null,
      "id": 235,
      "name": "div",
      "parent": 231,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children[1].find(elm=>elm.key === \"endExpiry\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": null,
      "id": 236,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"searchBoxEncounter\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": null,
      "id": 237,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"modEditDocument\")"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": null,
      "id": 238,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children[1].find(elm=>elm.key === \"startDoc\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": null,
      "id": 239,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children[1].find(elm=>elm.key === \"endDoc\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": null,
      "id": 240,
      "name": "div",
      "parent": 128,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"searchBoxDoctor\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": null,
      "id": 241,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"modError\")"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": null,
      "id": 242,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"modSecretAuthen\")"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": null,
      "id": 243,
      "name": "div",
      "parent": 168,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",   height: props.errMessage || props.errMessageHN ? \"927px\":\"927px\",   display: \"flex\"}"
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": null,
      "id": 244,
      "name": "div",
      "parent": 243,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{background: \"white\",   height: \"100%\", width: \"240px\", display: !props.tab || props.tab === \"NewScan\" ? \"\" : \"none\"}"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": null,
      "id": 245,
      "name": "div",
      "parent": 243,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"viewerDocument\")"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{background: \"#767676\",   height: \"100%\", width:\"100%\"}"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": null,
      "id": 246,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"errorMessage\")"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 247,
      "name": "Input",
      "parent": 112,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) => { props.setProp(\"documentNo\", v.value) }"
        },
        "value": {
          "type": "code",
          "value": "props.documentNo"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": null,
      "id": 248,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"modConfirmSave\")"
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": null,
      "id": 249,
      "name": "div",
      "parent": 244,
      "props": {
        "children": {
          "type": "code",
          "value": "props.elementThumbnailList"
        },
        "style": {
          "type": "code",
          "value": "{height:\"99.5%\",overflow:\"auto\", paddingTop: \"5px\"}"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": null,
      "id": 251,
      "name": "div",
      "parent": 244,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"none\", justifyContent:\"center\", marginTop:\"10px\"}"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 252,
      "name": "Button",
      "parent": 251,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey349"})}
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => { props.onEvent({ message: \"PrintDocument\", params: {}}) }"
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": null,
      "id": 253,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"errorMessageHN\")"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 254,
      "name": "div",
      "parent": 147,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"errorMessage\")"
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": null,
      "id": 255,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"printDocument\")"
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": null,
      "id": 256,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"loading\")"
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": null,
      "id": 257,
      "name": "label",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "Visit date"
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": null,
      "id": 259,
      "name": "label",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": "Visit (VN/AN)"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": null,
      "id": 263,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"modCancelAuthen\")"
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": null,
      "id": 266,
      "name": "div",
      "parent": 178,
      "props": {
        "children": {
          "type": "code",
          "value": "props.paginationElement"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": null,
      "id": 267,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 268,
      "name": "label",
      "parent": 267,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey335"})}
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": null,
      "id": 269,
      "name": "div",
      "parent": 267,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\", paddingLeft:\"5px\"}"
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": null,
      "id": 270,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 271,
      "name": "div",
      "parent": 270,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\", paddingLeft:\"5px\"}"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": null,
      "id": 272,
      "name": "label",
      "parent": 270,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey301"})}
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": null,
      "id": 273,
      "name": "div",
      "parent": 111,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 275,
      "name": "label",
      "parent": 273,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey474"})}
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": null,
      "id": 276,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": null,
      "id": 278,
      "name": "label",
      "parent": 276,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey395"})}
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 279,
      "name": "Button",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey417"})}
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"BarcodeScan\" ? \"blue\" : \"\""
        },
        "onClick": {
          "type": "code",
          "value": "(e) => { props.setScannedDocumentTab(\"BarcodeScan\") }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 280,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", display: !props.tab || props.tab === \"BarcodeScan\" ? \"block\" : \"none\" }"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": null,
      "id": 281,
      "name": "div",
      "parent": 280,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems:\"center\",marginBottom:\"10px\", marginTop:\"5px\"}"
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": null,
      "id": 282,
      "name": "div",
      "parent": 281,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey378"})}
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"15px\", fontWeight:\"bold\", fontWeight:\"16px\"}"
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 283,
      "name": "Button",
      "parent": 281,
      "props": {
        "children": {
          "type": "value",
          "value": "IMPORT"
        },
        "color": {
          "type": "code",
          "value": "\"green\""
        },
        "onClick": {
          "type": "code",
          "value": "props.handleClickBarcodeScan"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 452,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 284,
      "name": "Table",
      "parent": 280,
      "props": {
        "columns": {
          "type": "code",
          "value": "props.columnsBarcodeScan"
        },
        "data": {
          "type": "code",
          "value": "props.scannedDocumentData"
        },
        "defaultPageSize": {
          "type": "value",
          "value": "5"
        },
        "getTdProps": {
          "type": "code",
          "value": "(state, rowInfo, column, instance) => { return props.scannedTdProps( state, rowInfo, column, instance) }"
        },
        "getTrProps": {
          "type": "code",
          "value": "(state, rowInfo, column, instance) => { return props.scannedRowProps( state, rowInfo, column, instance) }"
        },
        "pageSize": {
          "type": "code",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"300px\"}"
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": null,
      "id": 285,
      "name": "div",
      "parent": 280,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent:\"flex-end\", marginTop:\"10px\"}"
        }
      },
      "seq": 285,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 286,
      "name": "Button",
      "parent": 285,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey321"})}
        },
        "disabled": {
          "type": "code",
          "value": "props.scannedDocumentData.length === 0"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleSaveBarcodeDocument"
        },
        "size": {
          "type": "code",
          "value": "\"green\""
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": null,
      "id": 287,
      "name": "div",
      "parent": 280,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{borderRadius: \".28571429rem\",border: \"1px solid rgba(34,36,38,.15)\", paddingBottom:\"15px\", marginTop:\"10px\"}"
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": null,
      "id": 288,
      "name": "div",
      "parent": 287,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor:\"#1a63bb\", color:\"white\", padding:\"5px\", marginBottom:\"10px\"}"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": null,
      "id": 289,
      "name": "label",
      "parent": 288,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey247"})}
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 290,
      "name": "Form",
      "parent": 287,
      "props": {
      },
      "seq": 290,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 291,
      "name": "FormGroup",
      "parent": 290,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 292,
      "name": "FormField",
      "parent": 291,
      "props": {
        "className": {
          "type": "code",
          "value": "props.requiredFieldError.hn?\"error\":\"\""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 293,
      "name": "FormField",
      "parent": 291,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": null,
      "id": 296,
      "name": "div",
      "parent": 292,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"grid\", width:\"100%\", paddingLeft:\"5px\"}"
        }
      },
      "seq": 296,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 298,
      "name": "Input",
      "parent": 296,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "readonly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:(props.selectedDocScan?.hn || !props.selectedDocScan) &&!props.selectedDocScan?.isPatient ?\"\":\"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patientHN"
        }
      },
      "seq": 377,
      "void": false
    },
    {
      "from": null,
      "id": 299,
      "name": "div",
      "parent": 296,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 298,
      "void": false
    },
    {
      "from": null,
      "id": 300,
      "name": "div",
      "parent": 299,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\", paddingLeft:\"5px\"}"
        }
      },
      "seq": 302,
      "void": false
    },
    {
      "from": null,
      "id": 302,
      "name": "label",
      "parent": 299,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": null,
      "id": 303,
      "name": "div",
      "parent": 293,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"grid\", width:\"100%\"}"
        }
      },
      "seq": 303,
      "void": false
    },
    {
      "from": null,
      "id": 304,
      "name": "label",
      "parent": 303,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey298"})}
        }
      },
      "seq": 304,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 305,
      "name": "Input",
      "parent": 303,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "readonly": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.patientFullName"
        }
      },
      "seq": 305,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 306,
      "name": "FormGroup",
      "parent": 290,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 306,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 307,
      "name": "FormField",
      "parent": 306,
      "props": {
        "className": {
          "type": "code",
          "value": "props.requiredFieldError.type?\"error\":\"\""
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 308,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 308,
      "name": "FormField",
      "parent": 306,
      "props": {
        "className": {
          "type": "code",
          "value": "props.requiredFieldError.category?\"error\":\"\""
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 307,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 309,
      "name": "FormField",
      "parent": 306,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 309,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 310,
      "name": "FormField",
      "parent": 306,
      "props": {
        "className": {
          "type": "code",
          "value": "props.requiredFieldError.doctorSearch?\"error\":\"\""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 310,
      "void": false
    },
    {
      "from": null,
      "id": 311,
      "name": "div",
      "parent": 308,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"grid\", width:\"100%\", paddingLeft:\"5px\"}"
        }
      },
      "seq": 311,
      "void": false
    },
    {
      "from": null,
      "id": 312,
      "name": "div",
      "parent": 307,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"grid\", width:\"100%\"}"
        }
      },
      "seq": 312,
      "void": false
    },
    {
      "from": null,
      "id": 313,
      "name": "div",
      "parent": 309,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"grid\", width:\"100%\"}"
        }
      },
      "seq": 313,
      "void": false
    },
    {
      "from": null,
      "id": 314,
      "name": "div",
      "parent": 310,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"grid\", width:\"100%\"}"
        }
      },
      "seq": 314,
      "void": false
    },
    {
      "from": null,
      "id": 315,
      "name": "div",
      "parent": 311,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 315,
      "void": false
    },
    {
      "from": null,
      "id": 316,
      "name": "div",
      "parent": 312,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 316,
      "void": false
    },
    {
      "from": null,
      "id": 317,
      "name": "div",
      "parent": 313,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 317,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 318,
      "name": "Dropdown",
      "parent": 311,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) => { props.handleChangeDocumentCategory(v.value) }"
        },
        "options": {
          "type": "code",
          "value": "props.documentCategoryOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minHeight:\"36px\",  display:  props.selectedDocScan && props.selectedDocScan?.isCategory ?\"\":\"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.documentCategory"
        }
      },
      "seq": 318,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 319,
      "name": "Dropdown",
      "parent": 312,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) => { props.setProp(\"documentType\", v.value) }"
        },
        "options": {
          "type": "code",
          "value": "props.documentTypeOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minHeight:\"36px\",  display: props.selectedDocScan && props.selectedDocScan?.isType ?\"\":\"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.documentType"
        }
      },
      "seq": 319,
      "void": false
    },
    {
      "from": null,
      "id": 325,
      "name": "div",
      "parent": 314,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 325,
      "void": false
    },
    {
      "from": null,
      "id": 327,
      "name": "div",
      "parent": 317,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey395"})}
        }
      },
      "seq": 327,
      "void": false
    },
    {
      "from": null,
      "id": 329,
      "name": "div",
      "parent": 316,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey301"})}
        }
      },
      "seq": 329,
      "void": false
    },
    {
      "from": null,
      "id": 330,
      "name": "label",
      "parent": 316,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\", paddingLeft:\"5px\"}"
        }
      },
      "seq": 330,
      "void": false
    },
    {
      "from": null,
      "id": 331,
      "name": "div",
      "parent": 315,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey335"})}
        }
      },
      "seq": 331,
      "void": false
    },
    {
      "from": null,
      "id": 332,
      "name": "label",
      "parent": 315,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\", paddingLeft:\"5px\"}"
        }
      },
      "seq": 332,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 333,
      "name": "FormGroup",
      "parent": 290,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 333,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 334,
      "name": "FormField",
      "parent": 333,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 334,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 335,
      "name": "FormField",
      "parent": 333,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 335,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 336,
      "name": "FormField",
      "parent": 333,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 336,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 337,
      "name": "FormField",
      "parent": 333,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 337,
      "void": false
    },
    {
      "from": null,
      "id": 338,
      "name": "div",
      "parent": 334,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"grid\", width:\"100%\", paddingLeft:\"5px\"}"
        }
      },
      "seq": 338,
      "void": false
    },
    {
      "from": null,
      "id": 339,
      "name": "div",
      "parent": 335,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"grid\", width:\"100%\"}"
        }
      },
      "seq": 339,
      "void": false
    },
    {
      "from": null,
      "id": 340,
      "name": "div",
      "parent": 336,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"grid\", width:\"100%\",}"
        }
      },
      "seq": 340,
      "void": false
    },
    {
      "from": null,
      "id": 341,
      "name": "div",
      "parent": 337,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"grid\", width:\"100%\"}"
        }
      },
      "seq": 341,
      "void": false
    },
    {
      "from": null,
      "id": 342,
      "name": "div",
      "parent": 338,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"-6px\", paddingBottom:\"3px\", display:\"flex\"}"
        }
      },
      "seq": 342,
      "void": false
    },
    {
      "from": null,
      "id": 343,
      "name": "div",
      "parent": 339,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey474"})}
        }
      },
      "seq": 343,
      "void": false
    },
    {
      "from": null,
      "id": 344,
      "name": "div",
      "parent": 340,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey400"})}
        }
      },
      "seq": 344,
      "void": false
    },
    {
      "from": null,
      "id": 345,
      "name": "div",
      "parent": 341,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey427"})}
        }
      },
      "seq": 345,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 348,
      "name": "Input",
      "parent": 341,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) => { props.setProp(\"remark\", v.value) }"
        },
        "readOnly": {
          "type": "code",
          "value": "props.inputReadOnly"
        },
        "value": {
          "type": "code",
          "value": "props.remark"
        }
      },
      "seq": 348,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 349,
      "name": "Dropdown",
      "parent": 339,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) => { props.setProp(\"documentOwnerDivision\", v.value) }"
        },
        "options": {
          "type": "code",
          "value": "props.documentOwnerDivisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minHeight:\"36px\",display:!props.selectedDocScan?\"none\":\"\"}"
        },
        "value": {
          "type": "code",
          "value": "props.documentOwnerDivision"
        }
      },
      "seq": 349,
      "void": false
    },
    {
      "from": null,
      "id": 350,
      "name": "div",
      "parent": 287,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent:\"flex-end\"}"
        }
      },
      "seq": 350,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 351,
      "name": "Button",
      "parent": 350,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey322"})}
        },
        "color": {
          "type": "code",
          "value": "\"yellow\""
        },
        "disabled": {
          "type": "code",
          "value": "!props.selectedDocScan"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleClickEditBarcodeDocument"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"15px\"}"
        }
      },
      "seq": 351,
      "void": false
    },
    {
      "from": null,
      "id": 352,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 353,
      "name": "label",
      "parent": 352,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey335"})}
        }
      },
      "seq": 353,
      "void": false
    },
    {
      "from": null,
      "id": 355,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 357,
      "name": "label",
      "parent": 355,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey313"})}
        }
      },
      "seq": 356,
      "void": false
    },
    {
      "from": null,
      "id": 358,
      "name": "div",
      "parent": 313,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children[1].find(elm=>elm.key === \"startDoc\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 358,
      "void": false
    },
    {
      "from": null,
      "id": 360,
      "name": "div",
      "parent": 340,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children[1].find(elm=>elm.key === \"startExpiry\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 360,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 361,
      "name": "FormField",
      "parent": 171,
      "props": {
        "width": {
          "type": "value",
          "value": "4"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 365,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"modConfirmEdit\")"
        }
      },
      "seq": 365,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 366,
      "name": "FormField",
      "parent": 291,
      "props": {
        "className": {
          "type": "code",
          "value": "props.requiredFieldError.startVisit?\"error\":\"\""
        },
        "width": {
          "type": "value",
          "value": "4"
        }
      },
      "seq": 370,
      "void": false
    },
    {
      "from": null,
      "id": 367,
      "name": "div",
      "parent": 366,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"grid\", width:\"100%\"}"
        }
      },
      "seq": 367,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 370,
      "name": "FormField",
      "parent": 291,
      "props": {
        "className": {
          "type": "code",
          "value": "props.requiredFieldError.visit_number?\"error\":\"\""
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 366,
      "void": false
    },
    {
      "from": null,
      "id": 371,
      "name": "div",
      "parent": 370,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"grid\", width:\"100%\"}"
        }
      },
      "seq": 371,
      "void": false
    },
    {
      "from": null,
      "id": 372,
      "name": "div",
      "parent": 371,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"searchBoxEncounter\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 435,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 375,
      "name": "Input",
      "parent": 312,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display:(props.selectedDocScan?.document_type|| !props.selectedDocScan) &&!props.selectedDocScan?.isType ?\"\":\"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDocScan?.document_type_name||\"\""
        }
      },
      "seq": 375,
      "void": false
    },
    {
      "from": null,
      "id": 377,
      "name": "div",
      "parent": 296,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children[0].find(elm=>elm.key === \"patient_search_box_2\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\", display:props.selectedDocScan?.isPatient && props.selectedDocScan?\"\":\"none\"}"
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 378,
      "name": "Input",
      "parent": 311,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display:(props.selectedDocScan?.document_category|| !props.selectedDocScan) &&!props.selectedDocScan?.isCategory ?\"\":\"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.documentCategoryOptions?.find(doc=>doc.value === props.documentCategory)?.text || \"\""
        }
      },
      "seq": 378,
      "void": false
    },
    {
      "from": null,
      "id": 380,
      "name": "div",
      "parent": 189,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": null,
      "id": 381,
      "name": "label",
      "parent": 380,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        },
        "style": {
          "type": "code",
          "value": "{color:\"white\"}"
        }
      },
      "seq": 381,
      "void": false
    },
    {
      "from": null,
      "id": 382,
      "name": "div",
      "parent": 380,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\", paddingLeft:\"5px\"}"
        }
      },
      "seq": 382,
      "void": false
    },
    {
      "from": null,
      "id": 383,
      "name": "div",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 398,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", alignItems:\"center\", justifyContent:\"space-between\"}"
        }
      },
      "seq": 398,
      "void": false
    },
    {
      "from": null,
      "id": 399,
      "name": "div",
      "parent": 398,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children[1].find(elm=>elm.key === \"startVisit\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"45%\"}"
        }
      },
      "seq": 399,
      "void": false
    },
    {
      "from": null,
      "id": 400,
      "name": "div",
      "parent": 398,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children[1].find(elm=>elm.key === \"endVisit\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"45%\"}"
        }
      },
      "seq": 401,
      "void": false
    },
    {
      "from": null,
      "id": 401,
      "name": "label",
      "parent": 398,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey312"})}
        }
      },
      "seq": 400,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 403,
      "name": "Input",
      "parent": 47,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "readonly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:props.disabledRequiredField?\"\":\"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.documentCategoryOptions?.find(doc=>doc.value === props.documentCategory)?.text || \"แสดงทุกประเภทเอกสาร\""
        }
      },
      "seq": 403,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 405,
      "name": "Input",
      "parent": 15,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display:props.disabledRequiredField?\"\":\"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.documentCategoryOptions?.find(doc=>doc.value === props.documentCategory)?.text || \"\""
        }
      },
      "seq": 405,
      "void": false
    },
    {
      "from": null,
      "id": 406,
      "name": "div",
      "parent": 361,
      "props": {
        "children": {
          "type": "code",
          "value": "props.secretElement.checkboxCancel"
        }
      },
      "seq": 406,
      "void": false
    },
    {
      "from": null,
      "id": 407,
      "name": "div",
      "parent": 180,
      "props": {
        "children": {
          "type": "code",
          "value": "props.secretElement.checkboxSecret"
        }
      },
      "seq": 407,
      "void": false
    },
    {
      "from": null,
      "id": 408,
      "name": "div",
      "parent": 182,
      "props": {
        "children": {
          "type": "code",
          "value": "props.secretElement.buttonSecret"
        }
      },
      "seq": 408,
      "void": false
    },
    {
      "from": null,
      "id": 410,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "code",
          "value": "props.cardFilterDentistElement"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 411,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"searchBoxEncounter\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 411,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 412,
      "name": "Dropdown",
      "parent": 48,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) => { props.setProp(\"doctorSearch\", v.value) }"
        },
        "options": {
          "type": "code",
          "value": "props.doctorSearchOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.doctorSearch"
        }
      },
      "seq": 355,
      "void": false
    },
    {
      "from": null,
      "id": 413,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 414,
      "name": "label",
      "parent": 413,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey301"})}
        }
      },
      "seq": 414,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 417,
      "name": "FormField",
      "parent": 200,
      "props": {
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 417,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 418,
      "name": "Button",
      "parent": 417,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey279"})}
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleClickSearchDocument"
        }
      },
      "seq": 418,
      "void": false
    },
    {
      "from": null,
      "id": 419,
      "name": "div",
      "parent": 383,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\", padding:\"5px\" }"
        }
      },
      "seq": 419,
      "void": false
    },
    {
      "from": null,
      "id": 420,
      "name": "div",
      "parent": 383,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1, display: \"flex\", flexDirection: \"column\", padding:\"5px\" }"
        }
      },
      "seq": 420,
      "void": false
    },
    {
      "from": null,
      "id": 421,
      "name": "label",
      "parent": 420,
      "props": {
        "children": {
          "type": "value",
          "value": "VN/AN"
        }
      },
      "seq": 421,
      "void": false
    },
    {
      "from": null,
      "id": 422,
      "name": "div",
      "parent": 420,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"searchBoxEncounter\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 422,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 424,
      "name": "FormGroup",
      "parent": 156,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 424,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 425,
      "name": "FormField",
      "parent": 424,
      "props": {
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 425,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 426,
      "name": "FormField",
      "parent": 424,
      "props": {
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 426,
      "void": false
    },
    {
      "from": null,
      "id": 427,
      "name": "label",
      "parent": 425,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey417"})}
        },
        "style": {
          "type": "code",
          "value": "{fontSize:\"17px\", marginTop:\"-15px\", marginLeft:\"5px\"}"
        }
      },
      "seq": 427,
      "void": false
    },
    {
      "from": null,
      "id": 428,
      "name": "div",
      "parent": 426,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", alignItems:\"center\", width:\"100%\", paddingLeft:\"5px\"}"
        }
      },
      "seq": 428,
      "void": false
    },
    {
      "from": null,
      "id": 429,
      "name": "div",
      "parent": 428,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"searchBoxEncounter\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 431,
      "void": false
    },
    {
      "from": null,
      "id": 431,
      "name": "div",
      "parent": 428,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 429,
      "void": false
    },
    {
      "from": null,
      "id": 433,
      "name": "label",
      "parent": 431,
      "props": {
        "children": {
          "type": "value",
          "value": "Visit (VN/AN)"
        },
        "style": {
          "type": "code",
          "value": "{width:\"90px\"}"
        }
      },
      "seq": 432,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 434,
      "name": "Input",
      "parent": 339,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display:!props.selectedDocScan?\"\":\"none\"}"
        }
      },
      "seq": 434,
      "void": false
    },
    {
      "from": null,
      "id": 435,
      "name": "div",
      "parent": 371,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 373,
      "void": false
    },
    {
      "from": null,
      "id": 436,
      "name": "div",
      "parent": 435,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\", paddingLeft:\"5px\"}"
        }
      },
      "seq": 437,
      "void": false
    },
    {
      "from": null,
      "id": 437,
      "name": "label",
      "parent": 435,
      "props": {
        "children": {
          "type": "value",
          "value": "VN"
        }
      },
      "seq": 436,
      "void": false
    },
    {
      "from": null,
      "id": 439,
      "name": "div",
      "parent": 342,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey455"})}
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 438,
      "void": false
    },
    {
      "from": null,
      "id": 442,
      "name": "div",
      "parent": 367,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children[1].find(elm=>elm.key === \"startVisit\")"
        }
      },
      "seq": 443,
      "void": false
    },
    {
      "from": null,
      "id": 443,
      "name": "div",
      "parent": 367,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 442,
      "void": false
    },
    {
      "from": null,
      "id": 444,
      "name": "div",
      "parent": 443,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\", paddingLeft:\"5px\"}"
        }
      },
      "seq": 445,
      "void": false
    },
    {
      "from": null,
      "id": 445,
      "name": "label",
      "parent": 443,
      "props": {
        "children": {
          "type": "value",
          "value": "Visit date"
        }
      },
      "seq": 444,
      "void": false
    },
    {
      "from": null,
      "id": 446,
      "name": "div",
      "parent": 314,
      "props": {
        "children": {
          "type": "code",
          "value": "props.children.find(elm=>elm.key === \"searchBoxDoctor\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 446,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 447,
      "name": "Input",
      "parent": 338,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) => { props.setProp(\"documentNo\", v.value) }"
        },
        "readOnly": {
          "type": "code",
          "value": "props.inputReadOnly"
        },
        "value": {
          "type": "code",
          "value": "props.documentNo"
        }
      },
      "seq": 447,
      "void": false
    },
    {
      "from": null,
      "id": 448,
      "name": "div",
      "parent": 325,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey313"})}
        }
      },
      "seq": 448,
      "void": false
    },
    {
      "from": null,
      "id": 449,
      "name": "label",
      "parent": 325,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\", paddingLeft:\"5px\"}"
        }
      },
      "seq": 449,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 451,
      "name": "Radio",
      "parent": 281,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.toggleBarcodeScan"
        },
        "onChange": {
          "type": "code",
          "value": "(e,{checked})=>props.setProp(\"toggleBarcodeScan\", checked)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 441,
      "void": false
    },
    {
      "from": null,
      "id": 452,
      "name": "div",
      "parent": 281,
      "props": {
        "children": {
          "type": "value",
          "value": {intl.formatMessage({id: "cukey468"})}
        },
        "style": {
          "type": "code",
          "value": "{margin:\"0 15px\", fontWeight:\"500\", fontWeight:\"16px\"}"
        }
      },
      "seq": 451,
      "void": false
    }
  ],
  "forwardRef": true,
  "height": 100,
  "isMounted": true,
  "memo": true,
  "name": "ScannedDocument",
  "project": "cudent",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
