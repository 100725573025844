import WasmController from "react-lib/frameworks/WasmController";

// APIs
import CentralLabResultCreateList from "issara-sdk/apis/CentralLabResultCreateList_apps_LAB";
import CentralLabResultByOrderGroupbyTestList from "issara-sdk/apis/CentralLabResultByOrderGroupbyTestList_apps_LAB";
import CentralLabSummaryFileResultList from "issara-sdk/apis/CentralLabSummaryFileResultList_apps_LAB";
import CentralLabSummaryFileResultListList from "issara-sdk/apis/CentralLabSummaryFileResultListList_apps_LAB";
import CentralLabSummaryFileResultDetail from "issara-sdk/apis/CentralLabSummaryFileResultDetail_apps_LAB"

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import { HandleGetEmployeeTokenization } from "react-lib/apps/HISV3/TPD/TPDInterface";

export type State = Partial<{
  LabReportSequence: Partial<{
    sequenceIndex: string | null;
    selectedStartDate: string | null;
    selectedEndDate: string | null;
    selectedLabDivision: string | null;
    selectedLabTest: string | null;
    centralLabResult: {
      id?: number | null;
      items?: any[];
    };
    LabReportToken: {
      token?: string;
      employeeName?: string;
      error?: any;
      loading?: boolean;
      code?: string;
    };
    LabReportList: any[];
  }> | null;
  selectedLabOrderWorking: any;
}>;

type Picked = Partial<
  Pick<
    MainState,
    | "selectedPatient"
    | "selectedEncounter"
    | "errorMessage"
    | "buttonLoadCheck"
    | "successMessage"
  >
>;

// Sequence
type SeqState = {
  sequence: "LabReport";
  restart?: boolean;
  clear?: boolean;
};
// Handle Action
type ActionType =
  | {
    action: "attach_file";
    card: string;
    buttonLoadKey: string;
    upload: { result: any }[];
    remove: number[]
    date: string;
    time: string;
    labOrderItems: any[];
  }
  | {
    action: "EDIT" | "AUTHORIZE";
    card: string;
    buttonLoadKey: string;
    labOrderItems: any[];
    username: string;
    password: string;
  }
  | { action: "token"; code: string }
  | { action: "FETCH_LAB_SUMMARY_FILE_RESULT"; btnKey: string; params: any }

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(
  params: SeqType<Pick<SeqAct, K>>
) => any;

type Params<A extends ActionType["action"]> = Extract<
  ActionType,
  { action: A }
>;

export const StateInitial: State = {
  LabReportSequence: {
    sequenceIndex: null,
    selectedStartDate: null,
    selectedEndDate: null,
    selectedLabDivision: null,
    selectedLabTest: "",
    centralLabResult: {},
    LabReportToken: {},
    LabReportList: [],
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
  masterLabDivisions?: any[];
};

export const DataInitial = {
  masterLabDivisions: [],
};

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["labDivision", {}]],
    },
  } as any);

  const state = controller.getState();

  const centralLab = await CentralLabResultByOrderGroupbyTestList.get({
    params: {
      order_id: state.selectedLabOrderWorking?.id,
      ab_type: "OUTLAB",
    },
    apiToken: controller.apiToken,
  });

  controller.setState({
    LabReportSequence: {
      ...state.LabReportSequence,
      LabReportToken: {},
      sequenceIndex: "Action",
      centralLabResult: centralLab?.[0] || {},
    },
  });
};

export const Action: Handler = async (controller, params: ActionType) => {
  const state = controller.getState();

  if (params.action === "token") {
    const [response, error, network] = await HandleGetEmployeeTokenization(
      controller as any,
      { code: params.code }
    );

    if (error) {
      controller.setState({
        LabReportSequence: {
          ...state.LabReportSequence,
          LabReportToken: { error: error, loading: false },
        },
      });
      return;
    }
    controller.setState({
      LabReportSequence: {
        ...state.LabReportSequence,
        LabReportToken: {
          token: response?.token || "",
          employeeName:
            decodeURIComponent(atob(response?.token.split(".")[1])) || "",
          loading: false,
          code: params.code,
        },
      },
    });
  } else if (params.action === "attach_file") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const filterItems = params.labOrderItems
      .flatMap((item: any) => (item.children.length ? item.children : [item]))
      .filter((item: any) => item.chk)
      .map((item) => item.central_lab_order_item_id);

    let error = null

    for (const item of params.upload) {
      const fileResult = await CentralLabSummaryFileResultList.create({
        data: {
          order: state.LabReportSequence?.centralLabResult?.id,
          ref_order_items: filterItems,
          description: "",
          report_pdf: item.result.replace(/^data:application\/pdf;base64,/g, ""),
          employee_code: state.LabReportSequence?.LabReportToken?.code,
          report_datetime: `${params.date}-${params.time}`,
        } as any,
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });

      if (fileResult[1]) {
        error = fileResult[1]
        break;
      }
    }

    if (!error) {
      // หากมี file ที่ต้องการ delete
      const promiseArr = params.remove.map((id) => CentralLabSummaryFileResultDetail.delete({
        pk: id,
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      }))

      const response = await Promise.all(promiseArr)

      if (response.some((item) => item[1])) {
        error = response.map((item) => item[1])
      }
    }

    if (error) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: error },
        },
      });
    } else {
      controller.setState(
        {
          successMessage: {
            ...state.successMessage,
            [params?.card]: "บันทึกสำเร็จ",
          },
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: null },
          },

          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.buttonLoadKey]: "SUCCESS",
          },
          LabReportSequence: {
            sequenceIndex: "START",
            centralLabResult: state.LabReportSequence?.centralLabResult
          },
        },
        () =>
          controller.handleEvent({
            message: "RunSequence" as any,
            params: {
              sequence: "LabReport" as any,
            },
          })
      );
    }
  } else if (params.action === "AUTHORIZE" || params.action === "EDIT") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const flatMapItems = (items?: any[]) =>
      (items || []).flatMap((item: any) =>
        item.children.length ? item.children : [item]
      );

    const rawLabOrderItems = flatMapItems(
      state.LabReportSequence?.centralLabResult?.items
    );

    let labOrderItems = flatMapItems(params.labOrderItems);

    labOrderItems = labOrderItems
      .filter((item: any, index) =>
        params.action === "EDIT"
          ? item.central_lab_result_value !== null && item.central_lab_result_value !==
          rawLabOrderItems[index].central_lab_result_value
          : item.chk
      )
      .map((item: any) => ({
        id: item.central_lab_result_id,
        order_item: item.central_lab_order_item_id,
        value: item.central_lab_result_value,
        comment: {},
      }));

    let saveLabResult: any = [{}, null, null];

    if (!!labOrderItems.length) {
      saveLabResult = await CentralLabResultCreateList.post({
        data: {
          items: labOrderItems,
          action: params.action,
          username: params?.username,
          password: params?.password,
        },
        apiToken: controller.apiToken,
      });
    }

    if (saveLabResult[1]) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: saveLabResult[1] },
        },
      });
    } else {
      controller.setState(
        {
          successMessage: {
            ...state.successMessage,
            [params.card]: saveLabResult[0],
          },
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: null },
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.buttonLoadKey]: "SUCCESS",
          },
          LabReportSequence: {
            sequenceIndex: "START",
            centralLabResult: state.LabReportSequence?.centralLabResult
          },
        },
        () =>
          controller.handleEvent({
            message: "RunSequence" as any,
            params: {
              sequence: "LabReport" as any,
            },
          })
      );
    }
  } else if (params.action === "FETCH_LAB_SUMMARY_FILE_RESULT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params?.btnKey]: "LOADING",
      },
    });

    const [response, error, network] =
      await CentralLabSummaryFileResultListList.get({
        apiToken: controller.apiToken,
        params: {
          ...params.params,
          start_order_date: state.LabReportSequence?.selectedStartDate,
          end_order_date: state.LabReportSequence?.selectedEndDate,
          lab_division:
            state.LabReportSequence?.selectedLabDivision === "all"
              ? ""
              : state.LabReportSequence?.selectedLabDivision,
          lab_test:
            state.LabReportSequence?.selectedLabTest === "all"
              ? ""
              : state.LabReportSequence?.selectedLabTest,
        },
      });
    if (error) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params?.btnKey]: "ERROR",
        },
        LabReportSequence: { ...state.LabReportSequence, LabReportList: [] },
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params?.btnKey]: "SUCCESS",
        },
        LabReportSequence: {
          ...state.LabReportSequence,
          LabReportList: response?.items || [],
        },
      });
    }
  }
};
