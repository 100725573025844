import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import moment from "moment";
import { Button, Header, Icon, Input, Modal, Label } from "semantic-ui-react";
// UI Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { ModConfirm, DateTextBox } from "react-lib/apps/common";
import { formatUTCtoBEString } from "react-lib/utils/dateUtils";
import { Table } from "react-lib/frameworks/Table";
// TPD
import CardDrugResuscitationAdderUx from "./CardDrugResuscitationAdderUx";
import CardDrugResuscitationNurseUx from "./CardDrugResuscitationNurseUx";
import CardDrugResuscitationUx from "./CardDrugResuscitationUx";
import CardDrugResuscitationReturnUx from "./CardDrugResuscitationReturnUx";

import { PDFDocument } from "pdf-lib";
import getPdfMake from "react-lib/appcon/common/pdfMake";
import FormStickerResuscitationBox from "./FormStickerResuscitationBox";
import { beToAd } from "react-lib/utils";
import _ from "react-lib/compat/lodashplus";

const CARD_NAME = "CardDrugResuscitation";

type CardDrugResuscitationProps = {
  // function
  django: any;
  onEvent: any;
  setProp: any;
  controller: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  loadingStatus?: any;
  buttonLoadCheck?: any;
  masterOptions?: any;

  // seq
  runSequence?: any;
  DrugResuscitationSequence?: any;

  isNurseScreen?: boolean;
};

const CardDrugResuscitation = (props: CardDrugResuscitationProps) => {
  const [loadding, setLoadding] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [openActionLog, setOpenActionLog] = useState<boolean>(false);
  const [openDrugActionLog, setOpenDrugActionLog] = useState(false);
  const [openModDeleteBox, setOpenModDeleteBox] = useState<boolean>(false);
  const [containerName, setContainerName] = useState<string>("");
  const [hideDrugList, setHideDrugList] = useState<boolean>(true);
  const [modeName, setModeName] = useState<string>("normal");
  const [note, setNote] = useState<string>("");
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [modSetDrug, setModSetDrug] = useState<any>({
    open: false,
    isCreate: true,
    showSearchResult: false,
    data: {},
  });
  const logochulaRef = useRef<string>("");
  const pdfMakeRef = useRef<any>();
  const [blobURL, setBlobURL] = useState<string>("");
  const [openSticker, setOpenSticker] = useState<boolean>(false);
  const [cIndex, setCIndex] = useState<number>(-1);

  const ActionLog = (props: any) => {
    const data = useMemo(
      () =>
        (props.data || [])?.map((item: any) => ({
          ...item,
          datetime: formatUTCtoBEString(item.datetime)?.join(" "),
        })),
      [props.data]
    );
    return (
      <div style={{ padding: "10px" }}>
        <Header>
          {`ประวัติการทำงาน`}
          <Label color="yellow">{props.code}</Label>
        </Header>
        <Table
          data={data}
          headers="User,Action,division,Date-Time"
          keys="name,action,edit_division_name,datetime"
          minRows="10"
          showPagination={false}
          style={{ height: "300px" }}
        ></Table>
      </div>
    );
  };

  const columnsBoxItem: any[] = [
    {
      Header: props.isNurseScreen ? "วันที่เปิดกล่องยา" : "วันที่สร้างกล่องยา",
      accessor: props.isNurseScreen ? "opened_datetime" : "created",
    },
    {
      Header: "เลขที่ใบจัดกล่อง",
      accessor: "code",
    },
    {
      Header: "เลขที่ตัวล๊อคกล่อง",
      accessor: "break_code",
    },
    {
      Header: "เลขที่กล่อง",
      accessor: "container_code",
    },
    {
      Header: "ชื่อกล่องยา",
      accessor: "container_name",
    },
    props.isNurseScreen
      ? { width: 0 }
      : {
          Header: "แผนกที่รับกล่องยา",
          accessor: "destination_division_name",
        },
    { Header: "วันหมดอายุ", accessor: "expired", width: 150 },
    { Header: "สถานะ", accessor: "status_name", width: 120 },
    {
      Header: "action",
      accessor: "action",
      width: props.isNurseScreen ? 0 : 100,
    },
  ];

  const columnsDrugItem: any[] = [
    {
      Header: "Drug",
      accessor: "generic_name",
      // width: "*",
      Cell: (row: any) => (
        <div
          style={{ color: row?.original?.is_expired ? "red" : "black" }}
          dangerouslySetInnerHTML={{
            __html: row.original?.generic_name,
          }}
        />
      ),
    },
    {
      Header: "จำนวนยาทั้งหมดในกล่อง",
      accessor: "total_quantity",
      // width: 200,
      getProps: (state: any, rowInfo: any, column: any) => {
        return {
          style: { background: "bisque" },
        };
      },
      Cell: (row: any) => (
        <div style={{ padding: "5px" }}>
          <Input
            fluid
            size="mini"
            name="total_quantity"
            value={row.original?.total_quantity || ""}
            onChange={(_event: any, data: any) => {
              handleChangeDrugItem(row.index, data);
            }}
            onKeyDown={handleKeyDownQty}
            error={row?.original?.is_expired ? true : false}
          />
        </div>
      ),
    },
    {
      Header: "Used Quantity",
      accessor: "used",
      // width: 140,
      Cell: (row: any) => (
        <div style={{ color: row?.original?.is_expired ? "red" : "black" }}>
          {row.original?.total_quantity - row.original?.quantity}
        </div>
      ),
    },
    {
      Header: "จำนวนยาที่เหลือในกล่อง",
      accessor: "quantity",
      // width: 200,
      Cell: (row: any) => (
        <div style={{ color: row?.original?.is_expired ? "red" : "black" }}>
          {row.original?.quantity}
        </div>
      ),
    },
    {
      Header: "Stock Unit",
      accessor: "default_dosage_unit_name",
      // width: 140,
      Cell: (row: any) => (
        <div style={{ color: row?.original?.is_expired ? "red" : "black" }}>
          {row.original?.default_dosage_unit_name}
        </div>
      ),
    },
    {
      Header: "วันหมดอายุ",
      accessor: "expired_be",
      width: cIndex !== -1 ? 300 : 280,
      getProps: (state: any, rowInfo: any, column: any) => {
        return {
          style: {
            background: "bisque",
          },
        };
      },
      Cell: (row: any) => (
        // <Input
        //   fluid
        //   size="mini"
        //   name="expired"
        //   value={row.original?.expired || ""}
        //   onChange={(_event: any, data: any) => {
        //     handleChangeDrugItem(row.index, data);
        //   }}
        // />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "1px",
            minHeight: row.index === cIndex ? "300px" : "0px",
          }}
          // onBlur={(e: any) => {
          //   console.log("onBlur")
          //   setCIndex(-1);
          // }}
          onFocus={(e: any) => {
            console.log("onFocus");
            setCIndex(row?.index);
          }}
        >
          <DateTextBox
            error={row?.original?.is_expired ? true : false}
            value={row.original?.expired_be || ""}
            onChange={(date: any) => {
              console.log(date);
              handleChangeDrugItem(row.index, {
                name: "expired_be",
                value: date,
              });
              setCIndex(-1);
            }}
          />
        </div>
      ),
    },
    {
      Header: "",
      accessor: "delete",
      width: props.isNurseScreen ? 0 : 60,
      Cell: (row: any) => (
        <Icon
          color={
            props.DrugResuscitationSequence?.selectedBox?.status_name ===
            "active"
              ? "grey"
              : "red"
          }
          name={"trash"}
          onClick={() => {
            props.runSequence({
              sequence: "DrugResuscitation",
              action: "_delete_drug",
              idx: row.index,
              card: CARD_NAME,
            });
          }}
        />
      ),
    },
  ];

  const columnsDrugActionLogs = [
    {
      Header: "วันที่จ่ายยา",
      accessor: "order_time",
      width: 100,
      Cell: (row: any) => (
        <>
          {moment(row.original?.order_time, "YYYY-MM-DD HH:mm").format(
            "DD/MM/YYYY"
          )}
        </>
      ),
    },
    { Header: "เลขที่ใบยา", accessor: "code", width: 100 },
    {
      Header: "HN [Encounter/AN]",
      accessor: "hn",
      width: 120,
      Cell: (row: any) => (
        <>
          {row.original?.hn}
          <br />
          [EN : {row.original?.en_number}]
        </>
      ),
    },
    { Header: "ประเภท", accessor: "en_type", width: 60 },
    { Header: "คลินิก", accessor: "division_name", width: 140 },
    { Header: "ชื่อ สกุล", accessor: "patient_fullname", width: 160 },
    {
      Header: "รายการยา",
      accessor: "drug_items",
      width: "auto",
      Cell: (row: any) => (
        <>
          {row.original?.drug_items?.map((item: any, index: number) => {
            return (
              <>
                <label style={{ fontWeight: "bold" }}>
                  {item?.drug_trade_name}
                </label>
                &nbsp;&nbsp;
                <label>
                  {item?.quantity} {item?.unit_text}
                </label>
                <br />
              </>
            );
          })}
        </>
      ),
    },
  ];

  const statusTypeItems = useMemo(() => {
    return [
      { key: "", value: "", text: "All" },
      { key: "ACTIVE", value: "ACTIVE", text: "Active" },
      { key: "INACTIVE", value: "INACTIVE", text: "Inactive" },
      { key: "OPEN", value: "OPEN", text: "Open" },
      { key: "RETURNED", value: "RETURNED", text: "Return" },
      { key: "REPLACED", value: "REPLACED", text: "Replace" },
    ];
  }, []);

  const returnStatusTypeItems = useMemo(() => {
    return [
      { key: "", value: "", text: "All" },
      { key: "RETURNED", value: "RETURNED", text: "Request" },
      { key: "APPROVED", value: "APPROVED", text: "Approve" },
    ];
  }, []);

  const divisionOptions = useMemo(() => {
    return [
      {
        key: "",
        value: "",
        text: "",
      },
      ...(props.masterOptions?.division || []),
    ].map((item: any) => ({
      key: item.key,
      value: item.value,
      text: item.text,
    }));
  }, [props.masterOptions?.division]);

  const showApproveBox = useMemo(() => {
    return (
      props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
        "APPROVE"
      ) ||
      props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
        "CANCEL_APPROVE"
      )
    );
  }, [props.DrugResuscitationSequence?.selectedBox]);

  useEffect(() => {
    props.runSequence({
      sequence: "DrugResuscitation",
      card: CARD_NAME,
      restart: true,
    });
    const getPdfMakeRef = async () => {
      const dataURL = await convertToDataURL("/static/images/logochula.png");
      const pdfMake = await getPdfMake();

      logochulaRef.current = dataURL;
      pdfMakeRef.current = pdfMake;
    };
    getPdfMakeRef();
  }, []);

  useEffect(() => {
    const drugList = props.DrugResuscitationSequence?.drugList;
    if (!drugList || drugList.length === 0 || !modSetDrug.data.drugSearch) {
      setModSetDrug({ ...modSetDrug, showSearchResult: false });
    } else {
      setModSetDrug({ ...modSetDrug, showSearchResult: true });
    }
  }, [props.DrugResuscitationSequence?.drugList]);

  useEffect(() => {
    if (props.DrugResuscitationSequence?.drugItems?.length > 0) {
      setHideDrugList(false);
    }
  }, [props.DrugResuscitationSequence?.drugItems]);

  useEffect(() => {
    if (props.isNurseScreen) {
      setContainerName("nursescreen");
    } else if (props.DrugResuscitationSequence?.selectedBox?.id) {
      setContainerName("editor");
    }
  }, [props.DrugResuscitationSequence?.selectedBox]);

  useEffect(() => {
    if (
      props.errorMessage?.CardDrugResuscitation !== null &&
      props.errorMessage?.CardDrugResuscitation !== undefined
    ) {
      setLoadding(false);
    }
    if (
      props.successMessage?.CardDrugResuscitation !== null &&
      props.successMessage?.CardDrugResuscitation !== undefined
    ) {
      setLoadding(false);
      setCompleted(true);
    }
  }, [
    props.successMessage?.CardDrugResuscitation,
    props.errorMessage?.CardDrugResuscitation,
  ]);

  useEffect(() => {
    props.runSequence({
      sequence: "DrugResuscitation",
      action: "search",
      card: CARD_NAME,
      restart: true,
    });
  }, [modeName]);

  const boxItems = useMemo(
    () =>
      props.DrugResuscitationSequence?.boxItems
        ?.filter((item: any) =>
          props.django?.user?.role_types.includes("REGISTERED_NURSE")
            ? item?.status_name === "ACTIVE" || item?.status_name === "OPENED"
            : item
        )
        ?.map((item: any, idx: any) => {
          return {
            ...item,
            drugs_str: item.items.map((d: any) => d.trade_name).join(),
            destination_division_name: item.destination_division && (
              <div>
                {
                  props.masterOptions?.division?.find(
                    (options: any) =>
                      options.value === item.destination_division
                  )?.text
                }
              </div>
            ),
            returned_datetime_div: item.returned_datetime && (
              <div>
                {moment(item.returned_datetime).format("DD/MM/YYYY HH:mm")}
              </div>
            ),
            approved_datetime: item.approved_datetime &&
              item.status_name === "APPROVED" && (
                <div>
                  {moment(item.approved_datetime).format("DD/MM/YYYY HH:mm")}
                </div>
              ),
            created: item.created && (
              <div>{moment(item.created).format("DD/MM/YYYY HH:mm")}</div>
            ),
            opened_datetime: item.opened_datetime && (
              <div>
                {moment(item.opened_datetime).format("DD/MM/YYYY HH:mm")}
              </div>
            ),
            expired: item.first_expired && (
              <div
                style={{
                  color: item.is_expired ? "red" : "rgba(0,0,0,.87)",
                }}
              >
                {item.first_expired}
              </div>
            ),
            action: !props.isNurseScreen && (
              <>
                {item?.status_name === "INACTIVE" && (
                  <Icon
                    color="red"
                    name="trash"
                    onClick={() => {
                      props.setProp("DrugResuscitationSequence", {
                        ...props.DrugResuscitationSequence,
                        selectedBox: item,
                      });
                      setOpenModDeleteBox(true);
                    }}
                  />
                )}
                <Icon
                  color="grey"
                  name="history"
                  onClick={() => {
                    props.runSequence({
                      sequence: "DrugResuscitation",
                      action: "getBoxActionLog",
                      boxId: item.id,
                      card: CARD_NAME,
                    });
                    setOpenActionLog(true);
                  }}
                />
              </>
            ),
          };
        }),
    [
      props.DrugResuscitationSequence,
      props.DrugResuscitationSequence?.drugItems,
    ]
  );

  const boxItemsReturn = useMemo(
    () =>
      props.DrugResuscitationSequence?.boxItems
        ?.filter(
          (item: any) =>
            item?.status_name === "APPROVED" || item?.status_name === "RETURNED"
        )
        ?.map((item: any, idx: any) => {
          return {
            ...item,
            status_name:
              item?.status_name === "RETURNED" ? "REQUEST" : item?.status_name,
            drugs_str: item.items.map((d: any) => d.trade_name).join(),
            destination_division_name: item.destination_division && (
              <div>
                {
                  props.masterOptions?.division?.find(
                    (options: any) =>
                      options.value === item.destination_division
                  )?.text
                }
              </div>
            ),
            returned_datetime_div: item.returned_datetime && (
              <div>
                {moment(item.returned_datetime).format("DD/MM/YYYY HH:mm")}
              </div>
            ),
            approved_datetime: item.approved_datetime &&
              item.status_name === "APPROVED" && (
                <div>
                  {moment(item.approved_datetime).format("DD/MM/YYYY HH:mm")}
                </div>
              ),
          };
        }),
    [
      props.DrugResuscitationSequence,
      props.DrugResuscitationSequence?.drugItems,
    ]
  );

  const returnDrugItems = useMemo(
    () =>
      props.DrugResuscitationSequence?.drugItems?.map((item: any, idx: any) => {
        return {
          ...item,
          return_quantity: (
            <Input
              value={item.return_quantity}
              onChange={(e) => {
                props.setProp(
                  `DrugResuscitationSequence.drugItems.${idx}.return_quantity`,
                  e.target.value
                );
              }}
              style={{ border: "none" }}
            />
          ),
          used: <div>{item.total_quantity - item.quantity}</div>,
          expired: item.expired ? (
            <div>
              {moment(item.expired).isValid()
                ? moment(item.expired).format("DD/MM/YYYY")
                : ""}
            </div>
          ) : null,
        };
      }),
    [
      props.DrugResuscitationSequence,
      props.DrugResuscitationSequence?.drugItems,
    ]
  );

  const nurseDrugItems = useMemo(
    () =>
      props.DrugResuscitationSequence?.drugItems?.map((item: any, idx: any) => {
        return {
          ...item,
          drugs_str: (
            <div
              style={{
                color: item.is_expired ? "red" : "rgba(0,0,0,.87)",
              }}
            >
              {item.trade_name}
            </div>
          ),
          total_quantity: (
            <div
              style={{
                color: item.is_expired ? "red" : "rgba(0,0,0,.87)",
              }}
            >
              {item.total_quantity}
            </div>
          ),
          used: (
            <div
              style={{
                color: item.is_expired ? "red" : "rgba(0,0,0,.87)",
              }}
            >
              {item.total_quantity - item.quantity}
            </div>
          ),
          quantity: (
            <div
              style={{
                color: item.is_expired ? "red" : "rgba(0,0,0,.87)",
              }}
            >
              {item.quantity}
            </div>
          ),
          default_dosage_unit_name: (
            <div
              style={{
                color: item.is_expired ? "red" : "rgba(0,0,0,.87)",
              }}
            >
              {item.default_dosage_unit_name}
            </div>
          ),
          expired: (
            <div
              style={{
                color: item.is_expired ? "red" : "rgba(0,0,0,.87)",
              }}
            >
              {item.expired}
            </div>
          ),
        };
      }),
    [
      props.DrugResuscitationSequence,
      props.DrugResuscitationSequence?.drugItems,
    ]
  );

  const handleSearchClick = () => {
    props.runSequence({
      sequence: "DrugResuscitation",
      action: "search",
      card: CARD_NAME,
    });
  };

  const handleAddNewClick = () => {
    if (containerName !== "adder") {
      setContainerName("adder");
      setHideDrugList(true);
      setModSetDrug({
        open: false,
        isCreate: true,
        showSearchResult: false,
        data: {},
      });
      props.setProp("DrugResuscitationSequence", {
        ...props.DrugResuscitationSequence,
        break_code: "",
        container_code: "",
        container_name: "",
        destination_division_name: "",
        returned_datetime: "",
        drugItems: [],
        selectedBox: {},
      });
    }
  };

  const handleAddToListClick = () => {
    props.runSequence({
      sequence: "DrugResuscitation",
      action: "_add_drug",
      data: modSetDrug.data,
      card: CARD_NAME,
    });
    setModSetDrug({
      open: false,
      isCreate: true,
      showSearchResult: false,
      data: { drugSearch: "", drugSelected: {}, qty: "", expire: "" },
    });
  };

  const handleChangeDrugItem = (index: number, data: any) => {
    let drugData = [...(props.DrugResuscitationSequence.drugItems || [])];
    drugData[index][data.name] = data.value;
    if (data.name === "total_quantity") {
      drugData[index].quantity = data.value;
    } else if (data.name === "expired_be") {
      drugData[index].expired = moment(beToAd(data.value)).format("YYYY-MM-DD");
    }
    props.setProp("DrugResuscitationSequence.drugItems", drugData);
  };

  const toggleHideDrugList = () => {
    if (hideDrugList) setHideDrugList(false);
    else setHideDrugList(true);
  };

  const handleChangeData = (_event: any, data: any) => {
    props.setProp(`DrugResuscitationSequence.${data.name}`, data.value);
  };

  const handleChangeValue = (e: any, v: any) => {
    props.setProp("DrugResuscitationSequence", {
      ...props.DrugResuscitationSequence,
      destinationDivision: v.value,
    });
  };

  const handleSaveBox = () => {
    if (containerName === "adder")
      props.runSequence({
        sequence: "DrugResuscitation",
        action: "CREATE",
        card: CARD_NAME,
        // items: selectedItems,
      });
    else if (containerName === "editor")
      props.runSequence({
        sequence: "DrugResuscitation",
        action: "EDIT",
        card: CARD_NAME,
        // items: selectedItems,
      });
  };

  const handleSaveItems = () => {
    props.runSequence({
      sequence: "DrugResuscitation",
      action: "CREATE_ITEMS",
      card: CARD_NAME,
    });
  };

  const handlePrintClick = () => {
    const createPDF = async () => {
      const pdfDoc = await PDFDocument.create();

      // code
      // destination_division_name
      // break_code
      // edit_division_name
      // edit_user_fullname
      // edited

      let destination_division_name = props.masterOptions?.division?.find(
        (options: any) =>
          options.value ===
          props.DrugResuscitationSequence?.selectedBox?.destination_division
      )?.text;
      let dateFormatted = moment(
        props.DrugResuscitationSequence?.selectedBox?.edited
      ).format("DD/MM/YYYY HH:mm");

      let data: any = {
        code: props.DrugResuscitationSequence?.selectedBox?.code,
        container_name:
          props.DrugResuscitationSequence?.selectedBox?.container_name,
        break_code: props.DrugResuscitationSequence?.selectedBox?.break_code,
        destination_division_name: destination_division_name,
        edit_user_fullname:
          props.DrugResuscitationSequence?.selectedBox?.edit_user_fullname,
        edited: dateFormatted,
      };

      await createPDFBuffer(pdfMakeRef.current, {
        ...data,
        logochula: logochulaRef.current,
      }).then((buffer) =>
        PDFDocument.load(buffer)
          // load base64
          .then((doc) =>
            pdfDoc
              .copyPages(doc, [0])
              // copyPages
              .then((copiedPages) => pdfDoc.addPage(copiedPages[0]))
          )
      );
      const pdfData = await pdfDoc.saveAsBase64({ dataUri: true });
      // setBlobURL(URL.createObjectURL(base64ToBlob(pdfData)));
      const blob = URL.createObjectURL(base64ToBlob(pdfData));
      window.open(blob);
    };

    createPDF();
    // setOpenSticker(true);
  };

  const handleApproveBox = () => {
    if (
      props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
        "CANCEL_APPROVE"
      )
    ) {
      props.runSequence({
        sequence: "DrugResuscitation",
        action: "CANCEL_APPROVE",
        card: CARD_NAME,
      });
    } else {
      props.runSequence({
        sequence: "DrugResuscitation",
        action: "APPROVE",
        card: CARD_NAME,
      });
    }
  };

  const handleReplaceBox = () => {
    props.runSequence({
      sequence: "DrugResuscitation",
      action: "REPLACE",
      card: CARD_NAME,
    });
  };

  const handleRejectBox = () => {
    props.runSequence({
      sequence: "DrugResuscitation",
      action: "REJECT",
      card: CARD_NAME,
    });
  };

  const handleSendBox = () => {
    if (
      props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
        "RETURN_TO_ACTIVE"
      )
    ) {
      props.runSequence({
        sequence: "DrugResuscitation",
        action: "RETURN_TO_ACTIVE",
        card: CARD_NAME,
      });
    } else {
      props.runSequence({
        sequence: "DrugResuscitation",
        action: "ACTIVATE",
        card: CARD_NAME,
      });
    }
  };

  const handleReturnBox = () => {
    props.runSequence({
      sequence: "DrugResuscitation",
      action: "RETURN",
      card: CARD_NAME,
    });
  };

  const handleCancelReturnBox = () => {
    if (
      props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
        "RETURN_TO_OPEN"
      )
    ) {
      props.runSequence({
        sequence: "DrugResuscitation",
        action: "RETURN_TO_OPEN",
        card: CARD_NAME,
      });
    } else if (
      props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
        "RETURN_TO_ACTIVE"
      )
    ) {
      props.runSequence({
        sequence: "DrugResuscitation",
        action: "RETURN_TO_ACTIVE",
        card: CARD_NAME,
      });
    }
  };

  const handleGetDrugActionLog = useCallback(() => {
    props.runSequence({
      sequence: "DrugResuscitation",
      action: "getDrugActionLog",
      card: CARD_NAME,
      isNurseScreen: props.isNurseScreen,
      masterDivision: props.masterOptions?.division,
    });
    setOpenDrugActionLog(true);
  }, [props.runSequence]);

  const handleShowSendBox = useMemo(() => {
    const savedItem =
      props.DrugResuscitationSequence?.selectedBox?.items?.filter(
        (item: any) => item.id
      );
    if (savedItem?.length <= 0) return false;

    return (
      props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
        "ACTIVATE"
      ) ||
      props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
        "RETURN_TO_ACTIVE"
      )
    );
  }, [props.DrugResuscitationSequence?.selectedBox]);

  const isFirstItem = useMemo(() => {
    const savedItem =
      props.DrugResuscitationSequence?.selectedBox?.items?.filter(
        (item: any) => item.id
      );
    if (savedItem?.length <= 0) return true;

    return false;
  }, [props.DrugResuscitationSequence?.selectedBox]);

  const drugSearchResult = useMemo(() => {
    return (props.DrugResuscitationSequence?.drugList || []).map(
      (item: any) => ({
        ...item,
        generic_name: (
          <div dangerouslySetInnerHTML={{ __html: item.generic_name || "" }} />
        ),
      })
    );
  }, [props.DrugResuscitationSequence?.drugList]);

  const showEditBox = useMemo(() => {
    return (
      props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
        "EDIT"
      ) ||
      props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
        "ACTIVATE"
      )
    );
  }, [props.DrugResuscitationSequence?.selectedBox]);

  const handleSearchDrugChange = (value: string) => {
    props.runSequence({
      sequence: "DrugResuscitation",
      action: "SEARCH_DRUG",
      keyword: value,
    });
  };

  const handleKeyDownQty = (e: any) => {
    if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  const hadleChangeQty = (e: any) => {
    let result = {
      ...modSetDrug.data,
      qty: e.target.value,
    };
    setModSetDrug({
      ...modSetDrug,
      showSearchResult: false,
      data: result,
    });
  };

  return (
    <div>
      {/* BoxActionLogs */}
      <Modal
        onClose={() => setOpenActionLog(false)}
        onOpen={() => setOpenActionLog(true)}
        open={openActionLog}
      >
        <ActionLog
          data={props.DrugResuscitationSequence?.boxLogs?.items}
          code={props.DrugResuscitationSequence?.selectedBox?.code}
        />
      </Modal>

      {/* DrugActionLogs */}
      <Modal
        size={"large"}
        closeIcon
        open={openDrugActionLog}
        onClose={() => setOpenDrugActionLog(false)}
      >
        <Header>
          {`ประวัติการจ่ายยาจากกล่องยา`}
          <Label color="yellow">
            {props.DrugResuscitationSequence?.selectedBox?.code}
          </Label>
        </Header>
        <Modal.Content>
          <Table
            columns={columnsDrugActionLogs}
            data={props.DrugResuscitationSequence?.drugLogs || []}
            minRows="8"
          ></Table>
        </Modal.Content>
      </Modal>

      <Modal closeIcon open={openSticker} onClose={() => setOpenSticker(false)}>
        <Modal.Content>
          <div style={{ padding: "15px 0 10px 2.5%", height: "320px" }}>
            <iframe src={blobURL} height="100%" width="100%"></iframe>
          </div>
        </Modal.Content>
      </Modal>

      {/* Delete Box */}
      <ModConfirm
        openModal={openModDeleteBox}
        titleName="กรุณายืนยัน"
        content={`ต้องการลบ กล่อง [${props.DrugResuscitationSequence?.selectedBox?.code}] ${props.DrugResuscitationSequence?.selectedBox?.container_name} ใช่หรือไม่`}
        onApprove={() => {
          props.runSequence({
            sequence: "DrugResuscitation",
            action: "_delete_box",
          });
          setOpenModDeleteBox(false);
        }}
        onDeny={() => setOpenModDeleteBox(false)}
      />

      <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
        <div style={{ margin: "2px" }}>
          <Button
            color={modeName === "return" ? undefined : "blue"}
            onClick={() => {
              setModeName("normal");
            }}
          >
            {props.isNurseScreen
              ? "กล่องยากู้ชีพฉุกเฉิน"
              : "รายการกล่องยากู้ชีพฉุกเฉิน"}
          </Button>
        </div>
        <div style={{ margin: "2px" }}>
          <Button
            color={modeName === "return" ? "blue" : undefined}
            onClick={() => {
              setModeName("return");
            }}
            style={{
              display: props.isNurseScreen ? "none" : "flex",
              margin: "0px 4px 0px 5px",
            }}
          >
            Return กล่องยากู้ชีพฉุกเฉิน
          </Button>
        </div>
      </div>
      <div>
        <hr></hr>
      </div>

      <div style={{ width: "100%" }}>
        {modeName === "return" ? (
          <div style={{ width: "100%" }}>
            <CardDrugResuscitationReturnUx
              setProp={props.setProp}
              onEvent={props.onEvent}
              handleChangeData={handleChangeData}
              DrugResuscitationSequence={props.DrugResuscitationSequence}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              searchClick={handleSearchClick}
              handleChangeValue={handleChangeValue}
              boxItems={boxItemsReturn}
              statusTypeItems={returnStatusTypeItems}
              drugItems={returnDrugItems}
              divisionOptions={divisionOptions}
              rejectClick={handleRejectBox}
              drugResuscitationLabel={`${props.DrugResuscitationSequence?.selectedBox?.status_name} : ${props.DrugResuscitationSequence?.selectedBox?.code}`}
              showEditBox={
                props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
                  "APPROVE"
                ) ||
                props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
                  "REQUEST"
                )
              }
              handleChangeStartDate={(date: string) =>
                props.setProp(
                  `DrugResuscitationSequence.filterBox.created_start`,
                  date
                )
              }
              handleChangeEndDate={(date: string) =>
                props.setProp(
                  `DrugResuscitationSequence.filterBox.created_end`,
                  date
                )
              }
              buttonEdit={
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={handleSaveItems}
                  // data
                  paramKey={`${CARD_NAME}_CREATE_ITEMS`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[`${CARD_NAME}_CREATE_ITEMS`]
                  }
                  // config
                  color={"yellow"}
                  size="small"
                  title={"Edit"}
                  style={{
                    display: "flex",
                    margin: "0px 4px 0px 0px",
                    ...(!props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
                      "REJECT"
                    ) && { display: "none" }),
                  }}
                />
              }
              buttonApprove={
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={handleApproveBox}
                  // data
                  paramKey={`${CARD_NAME}`}
                  buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_NAME}`]}
                  // config
                  color={
                    props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
                      "APPROVE"
                    )
                      ? "green"
                      : "red"
                  }
                  size="small"
                  title={
                    props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
                      "APPROVE"
                    )
                      ? "Approve"
                      : "Unppprove"
                  }
                  style={{
                    display: showApproveBox ? "flex" : "none",
                    margin: "0px 4px 0px 0px",
                  }}
                />
              }
              // component
              buttonSearchBox={
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={handleSearchClick}
                  // data
                  paramKey={`${CARD_NAME}_search`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[`${CARD_NAME}_search`]
                  }
                  // config
                  size="small"
                  title="ค้นหา"
                />
              }
              showRejectBox={props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
                "REJECT"
              )}
              boxTableRowProps={(
                state: any,
                rowInfo: any,
                column: any,
                instance: any
              ) => ({
                style: {
                  backgroundColor:
                    rowInfo?.original?.id &&
                    rowInfo?.original?.id ===
                      props.DrugResuscitationSequence?.selectedBox?.id
                      ? "#cccccc"
                      : "white",
                },
                onClick: () => {
                  if (!props.isNurseScreen) {
                    setContainerName("editor");
                  }

                  let obj = rowInfo?.original;
                  obj["destination_division_name"] =
                    props.masterOptions?.division?.find(
                      (options: any) =>
                        options.value === rowInfo?.original.destination_division
                    )?.text;
                  const returnedDatetime = moment(
                    rowInfo?.original.returned_datetime
                  );
                  obj["returned_datetime_str"] = returnedDatetime.isValid()
                    ? returnedDatetime.format("DD/MM/YYYY HH:mm")
                    : "";
                  props.runSequence({
                    sequence: "DrugResuscitation",
                    action: "SET_SELECTED",
                    selectedBox: obj,
                    card: CARD_NAME,
                  });
                },
              })}
            />
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <CardDrugResuscitationUx
              setProp={props.setProp}
              onEvent={props.onEvent}
              handleChangeData={handleChangeData}
              handleChangeStartDate={(date: string) =>
                props.setProp(
                  `DrugResuscitationSequence.filterBox.created_start`,
                  date
                )
              }
              handleChangeEndDate={(date: string) =>
                props.setProp(
                  `DrugResuscitationSequence.filterBox.created_end`,
                  date
                )
              }
              DrugResuscitationSequence={props.DrugResuscitationSequence}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              statusTypeItems={statusTypeItems}
              boxItems={boxItems}
              columnsBoxItem={columnsBoxItem}
              isNurseScreen={props.isNurseScreen}
              // open tab
              containerName={containerName}
              modeName={modeName}
              // methodOnChange={(e: any, { value }: any) => {
              //   setOrderType(value);
              // }}
              // method={orderType}
              addNewClick={handleAddNewClick}
              boxTableRowProps={(
                state: any,
                rowInfo: any,
                column: any,
                instance: any
              ) => ({
                style: {
                  backgroundColor:
                    rowInfo?.original?.id &&
                    rowInfo?.original?.id ===
                      props.DrugResuscitationSequence?.selectedBox?.id
                      ? "#cccccc"
                      : "white",
                },
                onClick: () => {
                  props.runSequence({
                    sequence: "DrugResuscitation",
                    action: "SET_SELECTED",
                    selectedBox: rowInfo?.original,
                    card: CARD_NAME,
                  });
                },
              })}
              save={completed ? <Icon className="check"></Icon> : "SAVE"}
              saveloading={loadding}
              container={
                <div style={{ width: "100%" }}>
                  {containerName === "adder" || containerName === "editor" ? (
                    <div style={{ width: "100%" }}>
                      <CardDrugResuscitationAdderUx
                        setProp={props.setProp}
                        onEvent={props.onEvent}
                        divisionOptions={divisionOptions}
                        handleChangeData={handleChangeData}
                        handleChangeValue={handleChangeValue}
                        DrugResuscitationSequence={
                          props.DrugResuscitationSequence
                        }
                        errorMessage={props.errorMessage}
                        successMessage={props.successMessage}
                        columnsDrugItem={columnsDrugItem}
                        drugItems={props.DrugResuscitationSequence?.drugItems}
                        handleGetDrugActionLog={handleGetDrugActionLog}
                        addDrugList={toggleHideDrugList} //click event
                        hideDrugList={hideDrugList}
                        containerName={containerName}
                        printClick={handlePrintClick}
                        showSendBox={handleShowSendBox}
                        sendBox={handleSendBox}
                        showReturnBox={props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
                          "RETURN"
                        )}
                        returnBox={handleReturnBox}
                        buttonSaveBox={
                          props.DrugResuscitationSequence?.selectedBox
                            ?.status_name !== "ACTIVE" && (
                            <ButtonLoadCheck
                              // function
                              setProp={props.setProp}
                              onClick={handleSaveBox}
                              // data
                              paramKey={`${CARD_NAME}_CREATE_EDIT`}
                              buttonLoadCheck={
                                props.buttonLoadCheck?.[
                                  `${CARD_NAME}_CREATE_EDIT`
                                ]
                              }
                              // config
                              color={
                                containerName === "adder" ? "green" : "yellow"
                              }
                              size="small"
                              title={
                                containerName === "adder" ? "บันทึก" : "แก้ไข"
                              }
                            />
                          )
                        }
                        buttonReplace={
                          <ButtonLoadCheck
                            // function
                            setProp={props.setProp}
                            onClick={handleReplaceBox}
                            // data
                            paramKey={`${CARD_NAME}`}
                            buttonLoadCheck={
                              props.buttonLoadCheck?.[`${CARD_NAME}`]
                            }
                            // config
                            color={"green"}
                            size="small"
                            title={"Replace"}
                            style={{
                              display:
                                props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
                                  "REPLACE"
                                )
                                  ? "flex"
                                  : "none",
                              margin: "0px 4px 0px 0px",
                            }}
                          />
                        }
                        // table
                        data={modSetDrug.data}
                        showSearchResult={modSetDrug.showSearchResult}
                        drugSearchResult={drugSearchResult}
                        onDrugSearchTextChange={(e: any) => {
                          if (e.target.value === "") {
                            setModSetDrug({
                              open: false,
                              isCreate: true,
                              showSearchResult: false,
                              data: {
                                drugSearch: "",
                                drugSelected: {},
                                qty: "",
                                expire: "",
                              },
                            });
                          } else {
                            let result = {
                              ...modSetDrug.data,
                              drugSearch: e.target.value,
                              drugSelected: {},
                            };
                            setModSetDrug({ ...modSetDrug, data: result });
                            handleSearchDrugChange(e.target.value);
                          }
                        }}
                        onClearDrugSearch={() => {
                          let result = {
                            ...modSetDrug.data,
                            drugSearch: "",
                            drugSelected: {},
                          };
                          setModSetDrug({ ...modSetDrug, data: result });
                        }}
                        drugRowProps={(
                          state: any,
                          rowInfo: any,
                          column: any,
                          instance: any
                        ) => {
                          return {
                            onClick: () => {
                              let result = {
                                ...modSetDrug.data,
                                drugSelected:
                                  props.DrugResuscitationSequence?.drugList[
                                    rowInfo?.index
                                  ],
                              };
                              setModSetDrug({
                                ...modSetDrug,
                                showSearchResult: false,
                                data: result,
                              });

                              props.runSequence({
                                sequence: "DrugResuscitation",
                                action: "GET_DERUG_UNIT",
                                id: result?.drugSelected?.id,
                              });
                            },
                          };
                        }}
                        onChangDateExpire={(date: any) => {
                          let result = {
                            ...modSetDrug.data,
                            expire: date,
                          };
                          setModSetDrug({
                            ...modSetDrug,
                            showSearchResult: false,
                            data: result,
                          });
                        }}
                        addToListClick={handleAddToListClick}
                        // component
                        buttonSaveDrugItem={
                          <ButtonLoadCheck
                            // function
                            setProp={props.setProp}
                            onClick={handleSaveItems}
                            // data
                            paramKey={`${CARD_NAME}_CREATE_ITEMS`}
                            buttonLoadCheck={
                              props.buttonLoadCheck?.[
                                `${CARD_NAME}_CREATE_ITEMS`
                              ]
                            }
                            style={{ display: showEditBox ? "flex" : "none" }}
                            // config
                            color={isFirstItem ? "green" : "yellow"}
                            size="small"
                            title={isFirstItem ? "บันทึก" : "แก้ไข"}
                          />
                        }
                        inputQty={
                          <Input
                            onChange={hadleChangeQty}
                            onKeyDown={handleKeyDownQty}
                            style={{ width: "80px" }}
                            value={modSetDrug.data?.qty}
                          ></Input>
                        }
                      />
                    </div>
                  ) : (
                    <div style={{ width: "100%" }}>
                      <CardDrugResuscitationNurseUx
                        setProp={props.setProp}
                        onEvent={props.onEvent}
                        divisionOptions={divisionOptions}
                        handleChangeData={handleChangeData}
                        DrugResuscitationSequence={
                          props.DrugResuscitationSequence
                        }
                        errorMessage={props.errorMessage}
                        successMessage={props.successMessage}
                        columnsDrugItem={columnsDrugItem}
                        drugItems={nurseDrugItems}
                        handleGetDrugActionLog={handleGetDrugActionLog}
                        containerName={containerName}
                        showReturnBox={props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
                          "RETURN"
                        )}
                        returnBox={handleReturnBox}
                        showCancelReturnBox={
                          props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
                            "RETURN_TO_OPEN"
                          ) ||
                          props.DrugResuscitationSequence?.selectedBox?.allowed_actions?.includes(
                            "RETURN_TO_ACTIVE"
                          )
                        }
                        cancelreturnBox={handleCancelReturnBox}
                      />
                    </div>
                  )}
                </div>
              }
              // component
              buttonSearchBox={
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={handleSearchClick}
                  // data
                  paramKey={`${CARD_NAME}_search`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[`${CARD_NAME}_search`]
                  }
                  // config
                  size="small"
                  title="ค้นหา"
                />
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

const createPDFBuffer = async (pdfMake: any, data: any): Promise<string> => {
  let docDef: any = { content: [] };

  docDef = FormStickerResuscitationBox({
    data: data,
  });

  return new Promise(async (resolve) =>
    pdfMake.createPdf(docDef).getBuffer((buffer: any) => resolve(buffer))
  );
};

const convertToDataURL = (imageURL: string) => {
  var image = new Image();
  image.src = imageURL;
  return new Promise<string>((resolve, reject) => {
    image.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      var ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
      ctx.drawImage(image, 0, 0);
      resolve(canvas.toDataURL("image/png"));
    };
  });
};

const base64ToBlob = (base64: string) => {
  var byteString = atob(base64.split(",")[1]);
  var mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
  var ab = new ArrayBuffer(byteString.length);
  var view = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    view[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

export default CardDrugResuscitation;
