import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  FormGroup,
  FormField,
  Button,
  Input,
  Dropdown
} from 'semantic-ui-react'

const ANES_PACU_Medication = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div>
        <div
          style={{backgroundColor:"rgb(214, 236, 243, 0.23)",width: "100%",height:"500px"}}>
          
          <div
            style={{height:"50px",display:"flex",backgroundColor:"#B4E4EF",border: "solid 1px rgb(200, 200, 200, 0.5)"}}>
            
            <label
              style={{textAlign:"center",marginLeft:"20px",paddingTop:"10px","fontWeight":"bold","fontSize":"20px"}}>
              Medication
            </label>
          </div>
          <div>
            
            <div
              className="ui form"
              style={{ margin: "20px"}}>
              
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={2}>
                  <label
                    style={{textAlign:"center","fontSize":"16px"}}>
                    Time
                  </label>
                  <div>
                    {props.medicationTime}
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={4}>
                  <label
                    style={{textAlign:"center","fontSize":"16px", marginLeft: "30px"}}>
                    Medication
                  </label>
                  <Dropdown
                    clearable={true}
                    icon="search"
                    name="medication"
                    onChange={props.onChangeMedicationInput}
                    options={props.masterOptions?.drugName}
                    search={true}
                    selection={true}
                    style={{ width: "100%"}}
                    value={props.medication?.medication || ""}>
                  </Dropdown>
                </FormField>
                <FormField
                  inline={true}
                  width={4}>
                  <label
                    style={{textAlign:"center","fontSize":"16px"}}>
                    Detail
                  </label>
                  <Input
                    name="detail"
                    onChange={props.onChangeMedicationInput}
                    value={props.medication?.detail || ""}>
                  </Input>
                </FormField>
                <FormField
                  inline={true}
                  width={4}>
                  <label
                    style={{textAlign:"center","fontSize":"16px"}}>
                    MD/RN
                  </label>
                  <Dropdown
                    icon="search"
                    name="mdrn"
                    onChange={props.onChangeMedicationInput}
                    options={props.mdrnOptions}
                    search={true}
                    selection={true}
                    style={{ width: "100%" }}
                    value={props.medication?.mdrn || ""}>
                  </Dropdown>
                </FormField>
                <FormField
                  inline={true}
                  width={1}>
                  <Button
                    color={props.medication?.index === "" ?  "blue" : "yellow"}
                    onClick={props.onAddMedication}>
                    {props.medication?.index === "" ?  "Add" : "Edit"}
                  </Button>
                </FormField>
              </FormGroup>
            </div>
          </div>
          <Table
            data={props.nurseMedicationData}
            headers="Time,Medication,Detail,MD/RN,"
            keys="time,medication,detail,mdrn,menu"
            minRows="8"
            widths="150,auto,auto,auto,150">
          </Table>
        </div>
      </div>
    )
}


export default ANES_PACU_Medication

export const screenPropsDefault = {}

/* Date Time : Wed May 24 2023 04:57:14 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor:\"rgb(214, 236, 243, 0.23)\",width: \"100%\",height:\"500px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"50px\",display:\"flex\",backgroundColor:\"#B4E4EF\",border: \"solid 1px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Medication"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"10px\",\"fontWeight\":\"bold\",\"fontSize\":\"20px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 15,
      "name": "Table",
      "parent": 1,
      "props": {
        "columns": {
          "type": "code",
          "value": ""
        },
        "data": {
          "type": "code",
          "value": "props.nurseMedicationData"
        },
        "headers": {
          "type": "value",
          "value": "Time,Medication,Detail,MD/RN,"
        },
        "keys": {
          "type": "value",
          "value": "time,medication,detail,mdrn,menu"
        },
        "minRows": {
          "type": "value",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "widths": {
          "type": "value",
          "value": "150,auto,auto,auto,150"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"20px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormGroup",
      "parent": 23,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 24,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 24,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 24,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 24,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 24,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "Time"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",\"fontSize\":\"16px\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "Medication"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",\"fontSize\":\"16px\", marginLeft: \"30px\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "Detail"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",\"fontSize\":\"16px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "MD/RN"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",\"fontSize\":\"16px\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Button",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.medication?.index === \"\" ?  \"Add\" : \"Edit\""
        },
        "color": {
          "type": "code",
          "value": "props.medication?.index === \"\" ?  \"blue\" : \"yellow\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddMedication"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Input",
      "parent": 27,
      "props": {
        "name": {
          "type": "value",
          "value": "detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeMedicationInput"
        },
        "value": {
          "type": "code",
          "value": "props.medication?.detail || \"\""
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "code",
          "value": "props.medicationTime"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Dropdown",
      "parent": 26,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "name": {
          "type": "value",
          "value": "medication"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeMedicationInput"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.drugName"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.medication?.medication || \"\""
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Dropdown",
      "parent": 28,
      "props": {
        "icon": {
          "type": "value",
          "value": "search"
        },
        "name": {
          "type": "value",
          "value": "mdrn"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeMedicationInput"
        },
        "options": {
          "type": "code",
          "value": "props.mdrnOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.medication?.mdrn || \"\""
        }
      },
      "seq": 40,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PACU_Medication",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
