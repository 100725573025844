import { useState, useEffect, useRef } from "react";
import { to, setStateAsync } from "../../../utils";
import DPIService from "../../services/DPIService";
import REGService from "../../services/REGService";
import CoreService from "../../services/CoreService";
import DPOService from "../../services/DPOService";
import ADRService from "../../services/ADRService";
import TRTService from "../../services/TRTService";
import ORMService from "../../services/ORMService";
import PTMService from "../../services/PTMService";
import ADMService from "../../services/ADMService";
import UserService from "../../services/UserService";
import TPDService from "../../services/TPDService";
import IMEService from "../../services/IMEService";
import LABService from "../../services/LABService";
// ===================================== TEST HOOKS ==============================================
export const useGetProgressionNote = ({ emrId, dependencies }) => {
  dependencies = dependencies ? dependencies : [];
  const [progressionNoteData, setprogressionNoteData] = useState([]);
  const [progressionNoteError, setprogressionNoteError] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dpiService = new DPIService(this.apiToken);
  async function fetchProgressionNote() {
    if (!emrId) {
      setIsLoading(false);
      return [isLoading, progressionNoteData, progressionNoteError];
    }
    const [error, response] = await to(dpiService.getProgressionNote({ emr: emrId }));
    if (progressionNoteData) {
      setprogressionNoteData(response.items);
      setIsLoading(false);
    } else {
      setprogressionNoteError(error);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    //Dont call async function inside useEffect directly because async function may return promise
    //which may be seen as clean function, instead, write another async function and call it
    // if (didMountRef.current) {
    setIsLoading(true);
    fetchProgressionNote();
    // }
    // didMountRef.current = !didMountRef.current;
  }, dependencies);
  return [isLoading, progressionNoteData, progressionNoteError];
};
// ========================================== Class Manager =========================================
export class MainPHVManager {

  apiToken: any;
  division: number | null;

  constructor(apiToken: any = null, division: number | null = null) {
    this.apiToken = apiToken;
    this.division = division
  }

  getClinialType = async () => {
    const dpoService = new DPOService(this.apiToken);
    var noteType = [];
    var noteError = [];
    const [error, response] = await to(dpoService.getClinialType());
    if (response) {
      noteType = response.items;
    } else {
      noteError = error;
    }
    return [noteType, noteError];
  };
  getProgressionNote = async (emrId, params, is_ipd) => {
    var progressionNoteData = [];
    var progressionNoteError = null;
    var numberOfPage = null;
    if (is_ipd) {
      const dpiService = new DPIService(this.apiToken);
      if (!emrId) {
        return [progressionNoteData, progressionNoteError];
      }
      const [error, response] = await to(dpiService.getProgressionNote(emrId, params));
      if (response) {
        progressionNoteData = response.items;
        if (params.limit) {
          numberOfPage = Math.ceil(response.total / params.limit);
        }
      } else {
        progressionNoteError = error;
      }
    } else {
      const coreService = new CoreService(this.apiToken);
      if (!emrId) {
        return [progressionNoteData, progressionNoteError];
      }
      const [error, response_data] = await to(coreService.getProgressionCycle(params));
      if (response_data) {
        progressionNoteData = response_data.items;
        if (params.limit) {
          numberOfPage = Math.ceil(response_data.total / params.limit);
        }
      } else {
        progressionNoteError = error;
      }
    }
    return [progressionNoteData, progressionNoteError, numberOfPage, is_ipd];
  };
  getPatientByHN = async params => {
    var patientData = [];
    var patientError = [];
    const regService = new REGService(this.apiToken);
    const [error, response] = await to(regService.getPatientByHN(params));
    if (response) {
      patientData = response;
    } else {
      patientError = error;
    }
    return [patientData, patientError];
  };
  getPatientList = async params => {
    var patientListData = [];
    var patientListError = [];
    var numberOfPage = null;
    const regService = new REGService(this.apiToken);
    const [error, response] = await to(regService.getSearchAllPatient(params));
    if (response) {
      patientListData = response.items;
      numberOfPage = Math.ceil(response.total / params.limit);
    } else {
      patientListError = error;
    }
    return [patientListData, patientListError, numberOfPage];
  };
  getNationalities = async () => {
    var nationalitiesData = [];
    var nationalitiesError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getnationalities());
    if (response) {
      nationalitiesData = response.items;
    } else {
      nationalitiesError = error;
    }
    return [nationalitiesData, nationalitiesError];
  };
  getPatientData = async patientId => {
    var patientData = [];
    var patientError = [];
    const regService = new REGService(this.apiToken);
    const [error, response] = await to(regService.getPatientById(patientId));
    if (response) {
      patientData = response;
    } else {
      patientError = error;
    }
    // console.log("getPatientData  MainPHVManager patientData:", patientData)
    return [patientData, patientError];
  };
  getEncounterHistory = async params => {
    var encounterHistoryData = {};
    var encounterHistoryError = [];
    const dpoService = new DPOService(this.apiToken);
    console.log(" getEncounterHistory params:", params)
    const [error, response] = await to(dpoService.getEncounterHistory(params));
    if (response) {
      encounterHistoryData = response;
    } else {
      encounterHistoryError = error;
    }
    return [encounterHistoryData, encounterHistoryError];
  };
  getEMRSummary = async params => {
    var emrSummaryData = [];
    var emrSummaryError = [];
    const ptmService = new PTMService(this.apiToken);
    const [error, response] = await to(ptmService.getEMRSummary(params));
    if (response) {
      emrSummaryData = response;
    } else {
      emrSummaryError = error;
    }
    return [emrSummaryData, emrSummaryError];
  };
  getEMRSummaryPrint = async params => {
    var emrSummaryData = [];
    var emrSummaryError = [];
    const ptmService = new PTMService(this.apiToken);
    const [error, response] = await to(ptmService.getEMRSummaryPrint(params));
    if (response) {
      emrSummaryData = response;
    } else {
      emrSummaryError = error;
    }
    return [emrSummaryData, emrSummaryError];
  };
  getMedicationRecordSummary = async params => {
    var medicationData = [];
    var medicationError = [];
    const tpdService = new TPDService(this.apiToken);
    const [error, response] = await to(tpdService.getMedicationRecordSummary(params));
    if (response) {
      medicationData = response;
    } else {
      medicationError = error;
    }
    return [medicationData, medicationError];
  };
  getMedicationRecordSummaryPrint = async params => {
    var medicationData = null;
    var medicationError = "";
    const tpdService = new TPDService(this.apiToken);
    const [error, response] = await to(tpdService.getMedicationRecordSummaryPrint(params));
    if (response) {
      medicationData = response;
    } else {
      medicationError = error;
    }
    return [medicationData, medicationError];
  };
  getHomeMedicationDrugOrderItems = async ({ emrId, encounterId }) => {
    var homeMedData = {};
    var homeMedError = {};
    const tpdService = new TPDService(this.apiToken);
    const dpoService = new DPOService(this.apiToken);
    const [medRecordError, medRecordData] = await to(dpoService.getMedicalRecordEmrId({ emrId }));
    const [drugError, drugResponse] = await to(
      tpdService.getHomeMedicationDrugOrderItems({ encounterId })
    );
    homeMedData.drugItems = drugResponse ? drugResponse : null;
    homeMedError.drugItems = drugError ? drugError : null;
    homeMedData.medRecord = medRecordData ? medRecordData : null;
    homeMedError.medRecord = medRecordError ? medRecordError : null;
    return [homeMedData, homeMedError];
  };
  getCenterLabDivision = async () => {
    var labDivisionList = {};
    var labDivisionError = [];
    const labService = new LABService(this.apiToken);
    const [error, response] = await to(labService.getCenterLabDivision());
    if (response) {
      labDivisionList = response;
    } else {
      labDivisionError = error;
    }
    return [labDivisionList, labDivisionError];
  };
  getCentalLabTestResultFitlerList = async params => {
    var labFilterList = [];
    var labFilterError = [];
    const labService = new LABService(this.apiToken);
    const [error, response] = await to(labService.getCentalLabTestResultFitlerList(params));
    if (response) {
      labFilterList = response;
    } else {
      labFilterError = error;
    }
    return [labFilterList, labFilterError];
  };
  getCentalLabTestResultComparable = async params => {
    var labDataList = [];
    var labDataError = [];
    const labService = new LABService(this.apiToken);
    const [error, response] = await to(labService.postCenterLabResultComparable(params));
    if (response) {
      labDataList = response;
    } else {
      labDataError = error;
    }
    return [labDataList, labDataError];
  };
  getInterfaceSummaryReportDetail = async params => {
    var reportData = {};
    var reportError = {};
    const labService = new LABService(this.apiToken);
    const [error, response] = await to(labService.getInterfaceSummaryReportDetail(params));
    if (response) {
      reportData = response;
    } else {
      reportError = error;
    }
    return [reportData, reportError];
  };
  getImagingExamResult = async params => {
    var imagingExamResult = [];
    var imagingExamError = [];
    const imeService = new IMEService(this.apiToken);
    const [error, response] = await to(imeService.getImagingExamResult(params));
    if (response) {
      imagingExamResult = response;
    } else {
      imagingExamError = error;
    }
    return [imagingExamResult, imagingExamError];
  };
  getAdmissionNote = async emr => {
    var admissionNoteData = [];
    var admissionNoteError = null;
    const dpiService = new DPIService(this.apiToken);
    const [error, response] = await to(dpiService.getAdmissionForm(emr));
    if (response) {
      admissionNoteData = response;
    } else {
      admissionNoteError = error;
    }
    return [admissionNoteData, admissionNoteError];
  };
  getDischargeSummary = async emr => {
    var dischargeSummaryData = {};
    var dischargeSummaryError = {};
    const dpiService = new DPIService(this.apiToken);
    const [getDischargeSummaryError, getDischargeSummaryResponse] = await to(
      dpiService.getDischargeSummary(emr)
    );
    const [getDiagnosisError, getDiagnosisResponse] = await to(dpiService.getDiagnosis(emr));
    const [getAdmissionFormError, getAdmissionFormResponse] = await to(
      dpiService.getAdmissionForm(emr)
    );
    const [getProcedureError, getProcedureResponse] = await to(dpiService.getProcedure(emr));
    // const [getDrgError, getDrgResponse] = await to(dpiService.getDrg(emr));
    dischargeSummaryData.dischargeSummary = getDischargeSummaryResponse
      ? getDischargeSummaryResponse
      : null;
    dischargeSummaryError.dischargeSummary = getDischargeSummaryError;
    dischargeSummaryData.diagnosis = getDiagnosisResponse ? getDiagnosisResponse : null;
    dischargeSummaryError.diagnosis = getDiagnosisError;
    dischargeSummaryData.admissionForm = getAdmissionFormResponse ? getAdmissionFormResponse : null;
    dischargeSummaryError.admissionForm = getAdmissionFormError;
    dischargeSummaryData.procedure = getProcedureResponse ? getProcedureResponse : null;
    dischargeSummaryError.procedure = getProcedureError;
    // dischargeSummaryData.drg = getDrgResponse ? getDrgResponse.data : null;
    // dischargeSummaryError.drg = getDrgError;
    return [dischargeSummaryData, dischargeSummaryError];
  };
  getDoctorConsultOrder = async params => {
    var doctorConsultOrderData = [];
    var doctorConsultOrderError = [];
    const dpoService = new DPOService(this.apiToken);
    const [error, response] = await to(dpoService.getDoctorConsultOrder(params));
    if (response) {
      doctorConsultOrderData = response.items;
    } else {
      doctorConsultOrderError = error;
    }
    return [doctorConsultOrderData, doctorConsultOrderError];
  };
  getDoctorConsultOrderRaw = async params => {
    var doctorConsultOrderData = [];
    var doctorConsultOrderError = [];
    const dpoService = new DPOService(this.apiToken);
    const [error, response] = await to(dpoService.getDoctorConsultOrder(params));
    if (response) {
      doctorConsultOrderData = response;
    } else {
      doctorConsultOrderError = error;
    }
    return [doctorConsultOrderData, doctorConsultOrderError];
  };
  getADRReaction = async id => {
    var adrReactionData = {};
    var adrReactionError = [];
    const adrService = new ADRService(this.apiToken);
    const [error, response] = await to(adrService.getAdverseReaction(id));
    if (response) {
      adrReactionData = response;
    } else {
      adrReactionError = error;
    }
    return [adrReactionData, adrReactionError];
  };
  getTreatmentNote = async params => {
    var treatmentNoteData = [];
    var treatmentNoteError = [];
    const trtService = new TRTService(this.apiToken);
    const coreService = new CoreService(this.apiToken);
    let numberOfPage = null;
    const [error, response] = await to(trtService.getTreatmentOrder(params));
    if (response) {
      var data = response.items;
      if (params.limit) {
        numberOfPage = Math.ceil(response.total / params.limit);
      }
      for (let items of data) {
        const [procedureError, procedureResponse] = await to(
          coreService.getProcedure({ order: items.pk })
        );
        if (procedureResponse) {
          treatmentNoteData.push({
            treatmentOrder: items,
            procedure: procedureResponse.items
          });
        }
        if (procedureError) {
          treatmentNoteError.push({
            treatmentOrder: items,
            procedure: procedureError
          });
        }
      }
    } else {
      treatmentNoteError.push({ treatmentOrder: error });
    }
    return [treatmentNoteData, treatmentNoteError, numberOfPage];
  };
  getSensitiveNote = async emrId => {
    var sensitiveNoteData = {};
    var sensitiveNoteError = {};
    const dpoService = new DPOService(this.apiToken);
    const [error, response] = await to(dpoService.getSensitiveNote(emrId));
    if (response) {
      sensitiveNoteData = response;
    } else {
      sensitiveNoteError = error;
    }
    return [sensitiveNoteData, sensitiveNoteError];
  };
  getSpeiciality = async () => {
    var specialityData = {};
    var specialityError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getSpeciality());
    if (response) {
      specialityData = response.items;
    } else {
      specialityError = error;
    }
    return [specialityData, specialityError];
  };
  getSearchDoctor = async ({ doctorName }) => {
    var doctorData = [];
    var doctorError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getSearchDoctor(doctorName));
    if (response) {
      doctorData = response.items;
    } else {
      doctorError = error;
    }
    return [doctorData, doctorError];
  };
  getDoctorList = async params => {
    var doctorListData = [];
    var doctorListError = null;
    var numberOfPage = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getDoctor(params));
    if (response) {
      doctorListData = response.items;
      if (params.limit) {
        numberOfPage = Math.ceil(response.total / params.limit);
      }
    } else {
      doctorListError = error;
    }
    return [doctorListData, doctorListError, numberOfPage];
  };
  getOperativeNote = async params => {
    var operativeNoteData = [];
    var operativeNoteError = null;
    var numberOfPage = null;
    const ormService = new ORMService(this.apiToken);
    const [error, response] = await to(ormService.getOperatingDetail(params));
    if (response) {
      let data = response.items;
      for (var items of data) {
        items.image = [];
        if (items.operative_note_id) {
          const [imageError, imageResponse] = await to(
            ormService.getOperativeImage(items.operative_note_id)
          );
          if (imageResponse) {
            for (var image of imageResponse.items) {
              items.image.push(image.image);
            }
          }
          if (imageError) {
            operativeNoteError = imageError;
          }
        }
        operativeNoteData.push(items);
      }
      if (params.limit) {
        numberOfPage = Math.ceil(response.total / params.limit);
      }
    } else {
      operativeNoteError = error;
    }
    return [operativeNoteData, operativeNoteError, numberOfPage];
  };
  getNurseNote = async params => {
    var nurseNoteData = {};
    var nurseNoteError = null;
    var numberOfPage = null;
    const ptmService = new PTMService(this.apiToken);
    const [error, response] = await to(ptmService.getNurseNoteItem(params));
    if (response) {
      nurseNoteData = response.items;
      if (params.limit) {
        numberOfPage = Math.ceil(response.total / params.limit);
      }
    } else {
      nurseNoteError = error;
    }
    return [nurseNoteData, nurseNoteError, numberOfPage];
  };
  postPrintNurseNote = async params => {
    const ptmService = new PTMService(this.apiToken);
    const [error, response] = await to(ptmService.postPrintNurseNote(params));
    return [response, error];
  };
  getPatientAssessment = async (encounterId, startDate, endDate) => {
    var patientAssessmentData = {};
    var patientAssessmentError = {};
    let params = {
      encounter: encounterId,
      form_version: 0.1,
      form_code: "CardPatientAssessment",
      start_date: startDate,
      end_date: endDate
    };
    const ptmService = new PTMService(this.apiToken);
    const [patientError, patientResponse] = await to(ptmService.getFormDataLatest(params));
    if (patientError) {
      const [patientV2Error, patientV2Response] = await to(ptmService.getFormDataLatest({...params, form_version: 0.2}));
      patientAssessmentData.patient = patientV2Response ? patientV2Response : null;
      patientAssessmentError.patient = patientV2Error;
    }
    params.form_code = "CardFallRiskAssessment";
    const [fallRiskError, fallRiskResponse] = await to(ptmService.getFormDataLatest(params));
    if (fallRiskError) {
      const [fallRiskV2Error, fallRiskV2Response] = await to(ptmService.getFormDataLatest({...params, form_version: 0.2}));
      patientAssessmentData.fallRisk = fallRiskV2Response ? fallRiskV2Response : null;
      patientAssessmentError.fallRisk = fallRiskV2Error;
    }
    params.form_code = "CardPainAssessment";
    const [painError, painResponse] = await to(ptmService.getFormDataLatest(params));
    if (painError) {
      const [painV2Error, painV2Response] = await to(ptmService.getFormDataLatest({...params, form_version: 0.2}));
      patientAssessmentData.pain = painV2Response ? painV2Response : null;
      patientAssessmentError.pain = painV2Error;
    }
    params.form_code = "CardNutritionAssessment";
    const [nutritionError, nutritionResponse] = await to(ptmService.getFormDataLatest(params));
    if (nutritionError) {
      const [nutritionV2Error, nutritionV2Response] = await to(ptmService.getFormDataLatest({...params, form_version: 0.2}));
      patientAssessmentData.nutrition = nutritionV2Response ? nutritionV2Response : null;
      patientAssessmentError.nutrition = nutritionV2Error;
    }
    if (patientResponse) {
      patientAssessmentData.patient = patientResponse ? patientResponse : null;
      patientAssessmentError.patient = patientError;
    }
    if (fallRiskResponse) {
      patientAssessmentData.fallRisk = fallRiskResponse ? fallRiskResponse : null;
      patientAssessmentError.fallRisk = fallRiskError;
    }
    if (painResponse) {
      patientAssessmentData.pain = painResponse ? painResponse : null;
      patientAssessmentError.pain = painError;
    }
    if (nutritionResponse) {
      patientAssessmentData.nutrition = nutritionResponse ? nutritionResponse : null;
      patientAssessmentError.nutrition = nutritionError;
    }
    // patientAssessmentData.patient = patientResponse ? patientResponse : null;
    // patientAssessmentError.patient = patientError;
    // patientAssessmentData.fallRisk = fallRiskResponse ? fallRiskResponse : null;
    // patientAssessmentError.fallRisk = fallRiskError;
    // patientAssessmentData.pain = painResponse ? painResponse : null;
    // patientAssessmentError.pain = painError;
    // patientAssessmentData.nutrition = nutritionResponse ? nutritionResponse : null;
    // patientAssessmentError.nutrition = nutritionError;
    return [patientAssessmentData, patientAssessmentError];
  };
  getClinicalTerm = async params => {
    var clinicalTerm = [];
    var ClinicalTermError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getClinicalTerm(params));
    if (response) {
      clinicalTerm = response.items;
    } else {
      ClinicalTermError = error;
    }
    return [clinicalTerm, ClinicalTermError];
  };
  getDischargePlanning = async (encounterId, startDate, endDate) => {
    var dischargePlanning = {};
    var dischargePlanningError = {};
    const ptmService = new PTMService(this.apiToken);
    const admService = new ADMService(this.apiToken);
    let params = {
      form_data_code: "discharge_planning"
      // start_date: startDate,
      // end_date: endDate
    };
    const [planningError, planningResponse] = await to(
      ptmService.getFormDataEncounter(encounterId, params)
    );
    params.form_data_code = "discharge_teaching";
    const [teachingError, teachingResponse] = await to(
      ptmService.getFormDataEncounter(encounterId, params)
    );
    params.form_data_code = "discharge_nursing";
    const [nursingError, nursingResponse] = await to(
      ptmService.getFormDataEncounter(encounterId, params)
    );
    const [dischargeSummaryError, dischargeSummaryResponse] = await to(
      admService.getDischargePlanningSummary(encounterId)
    );
    dischargePlanning.planning = planningResponse ? planningResponse.data : null;
    dischargePlanningError.planning = planningError;
    dischargePlanning.teaching = teachingResponse ? teachingResponse.data : null;
    dischargePlanningError.teaching = teachingError;
    dischargePlanning.nursing = nursingResponse ? nursingResponse.data : null;
    dischargePlanningError.nursing = nursingError;
    dischargePlanning.summary = dischargeSummaryResponse;
    dischargePlanningError.summary = dischargeSummaryError;
    return [dischargePlanning, dischargePlanningError];
  };
  getReassessment = async (encounterId, params) => {
    var reassessmentData = {};
    var reassessmentError = null;
    const ptmService = new PTMService(this.apiToken);
    const [error, response] = await to(ptmService.getFormDataLatest(params));
    if (response) {
      reassessmentData = response;
    } else {
      reassessmentError = error;
    }
    return [reassessmentData, reassessmentError];
  };
  getMultidisciplinary = async params => {
    var multidisciplinaryData = [];
    var multidisciplinaryError = null;
    var numberOfPage = null;
    const ptmService = new PTMService(this.apiToken);
    const [error, response] = await to(ptmService.getMultidisciplinaryItem(params));
    if (response) {
      multidisciplinaryData = response.items;
      if (params.limit) {
        numberOfPage = Math.ceil(response.total / params.limit);
      }
    } else {
      multidisciplinaryError = error;
    }
    return [multidisciplinaryData, multidisciplinaryError, numberOfPage];
  };
  postPrintMultidisciplinary = async data => {
    const ptmService = new PTMService(this.apiToken);
    const [error, response] = await to(ptmService.postPrintMultiDisciplinary(data));
    return [response, error];
  };
  getPrintOPDOrderSummary = async params => {
    const dpoService = new DPOService(this.apiToken);
    const [error, response] = await to(dpoService.getPrintOPDOrderSummary(params));
    return [response, error];
  };
  getPrintIPDOrderSummary = async params => {
    const dpiService = new DPIService(this.apiToken);
    const [error, response] = await to(dpiService.getPrintDoctorOrder(params));
    return [response, error];
  };
  getTaskTrackingPrintResult = async params => {
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getTaskTrackingPrintResult(params));
    return [response, error];
  };
  getScannedDoc = async params => {
    var scannedDocData = [];
    var scannedDocError = null;
    var numberOfPage = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getScannedDoc(params));
    if (response) {
      scannedDocData = response.items;
      if (params.limit) {
        numberOfPage = Math.ceil(response.total / params.limit);
      }
    } else {
      scannedDocError = error;
    }
    return [scannedDocData, scannedDocError, numberOfPage];
  };
  getVitalSign = async (params: any) => {
    var vitalSignData = [];
    var vitalSignError = null;
    const ptmService = new PTMService(this.apiToken);
    const [error, response] = await to(ptmService.getMeasurement(params));
    if (response) {
      vitalSignData = response;
    } else {
      vitalSignError = error;
    }
    return [vitalSignData, vitalSignError];
  };
  getScannedDocOptions = async () => {
    console.log(" getScannedDocOptions ")
    var scannedDocOptions = {};
    var scannedDocOptionsError = {};
    const coreService = new CoreService(this.apiToken);
    const [documentCategoryError, documentCategoryResponse] = await to(
      coreService.getDocumentCategory()
    );
    // const [documentTypeError, documentTypeResponse] = await to(coreService.getDocumentType());
    const [divisionError, divisionResponse] = await to(coreService.getDivision());
    scannedDocOptions.docCategory = documentCategoryResponse ? documentCategoryResponse.items : null;
    // scannedDocOptions.docType = documentTypeResponse ? documentTypeResponse.items : null;
    scannedDocOptions.division = divisionResponse ? divisionResponse.items : null;
    scannedDocOptionsError.docCategory = documentCategoryError;
    // scannedDocOptionsError.docType = documentTypeError;
    scannedDocOptionsError.division = divisionError;
    return [scannedDocOptions, scannedDocOptionsError];
  };
  getScannedDocType = async (params: any) => {
    var scannedDocType = [];
    var scannedDocError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getDocumentType(params));
    if (response) {
      scannedDocType = response.items;
    } else {
      scannedDocError = error;
    }
    return [scannedDocType, scannedDocError];
  };
  getUserList = async params => {
    var userList = [];
    var userError = null;
    const userService = new UserService(this.apiToken);
    const [error, response] = await to(userService.getUser(params));
    if (response) {
      userList = response.items;
    } else {
      userError = error;
    }
    return [userList, userError];
  };
  getOrderSummary = async (emrId, params) => {
    var orderSummaryData = {};
    var orderSummaryError = {};
    var numberOfPage = null;
    const dpiService = new DPIService(this.apiToken);
    const [oneDayError, oneDayResponse] = await to(dpiService.getIpdOneDay(emrId, params));
    const [continueError, continueResponse] = await to(dpiService.getIpdContinue(emrId, params));
    if (oneDayResponse) {
      orderSummaryData.oneDay = oneDayResponse.items;
      if (params.limit) {
        numberOfPage = oneDayResponse.total / params.limit;
      }
    }
    orderSummaryData.continue = continueResponse ? continueResponse.items : null;
    orderSummaryError.oneDay = oneDayError ? oneDayError : null;
    orderSummaryError.continue = continueError ? continueError : null;
    return [orderSummaryData, orderSummaryError, numberOfPage];
  };
  getOrderSummaryOPD = async params => {
    var orderSummaryData = {};
    var orderSummaryError = {};
    var numberOfPage = null;
    const ptmService = new PTMService(this.apiToken);
    const [oneDayError, oneDayResponse] = await to(ptmService.getOpdOneDay(params));
    if (oneDayResponse) {
      orderSummaryData.oneDay = oneDayResponse.items;
      if (params.limit) {
        numberOfPage = oneDayResponse.total / params.limit;
      }
      orderSummaryError.oneDay = oneDayError ? oneDayError : null;
    }
    return [orderSummaryData, orderSummaryError, numberOfPage];
  };
  getVitalSignType = async (params: any) => {
    var vitalSignType = [];
    var vitalSignTypeError = null;
    const ptmService = new PTMService(this.apiToken);
    const [error, response] = await to(ptmService.getVitalSignType(params, this.division));
    if (response) {
      vitalSignType = response.items;
    } else {
      vitalSignTypeError = error;
    }
    return [vitalSignType, vitalSignTypeError];
  };
  getSecretEncounter = async params => {
    var secretEncounterData = [];
    var secretEncounterError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getSecretEncounter(params));
    if (response) {
      secretEncounterData = response.items;
    } else {
      secretEncounterError = error;
    }
    return [secretEncounterData, secretEncounterError];
  };
  postSecretEncounter = async (data, params) => {
    var secretEncounterData = [];
    var secretEncounterError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.postSecretEncounter(data, params));
    if (response) {
      secretEncounterData = response.items;
    } else {
      secretEncounterError = error;
    }
    return [secretEncounterData, secretEncounterError];
  };
  getPatientOldName = async patientId => {
    var patientOldNameData = [];
    var patientOldNameError = null;
    const regService = new REGService(this.apiToken);
    const [error, response] = await to(regService.getPatientName(patientId));
    if (response) {
      patientOldNameData = response.items;
    } else {
      patientOldNameError = error;
    }
    return [patientOldNameData, patientOldNameError];
  };
  getMedReconciliation = async params => {
    var medReconcileData = [];
    var medReconcileError = null
    const tpdService = new TPDService(this.apiToken);
    const [error, response] = await to(tpdService.getMedReconciliation(params));
    if (response) {
      medReconcileData = response.items;
    } else {
      medReconcileError = error;
    }
    return [medReconcileData, medReconcileError];
  }
  getMedReconciliationWithId = async (medReconcileId, params = {}) => {
    var medReconcileData = {}
    var medReconcileError = null
    if (!medReconcileId) {
      return [medReconcileData, medReconcileError];
    }
    const tpdService = new TPDService(this.apiToken);
    const [error, response] = await to(tpdService.getMedReconciliationWithId(medReconcileId, params));
    if (response) {
      medReconcileData = response;
    } else {
      medReconcileError = error;
    }
    return [medReconcileData, medReconcileError];
  }
  getMedReconciliationLog = async (params = {}) => {
    var medReconcileLogData = {}
    var medReconcileLogError = null
    const tpdService = new TPDService(this.apiToken);
    const [error, response] = await to(tpdService.getMedReconciliationLog(params));
    if (response) {
      medReconcileLogData = response.items;
    } else {
      medReconcileLogError = error;
    }
    return [medReconcileLogData, medReconcileLogError];
  }
}
