import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Button,
  Icon
} from 'semantic-ui-react'

const CardAnesthesiaTeamUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "1rem", backgroundColor: props.card === "CardBasicInfo" ? "" :"#D6ECF33B"}}>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <label
                style={{fontWeight: "bold"}}>
                Anesthesiologist
              </label>
              <label
                style={{color: "red"}}>
                *
              </label>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <div
                style={{width: "100%"}}>
                {props.AnesthesiologistElement}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <Button
                color={"red"}
                disabled={props.isDisabled? true :false}
                onClick={props.onDeleteTeam}
                style={{marginLeft: "auto"}}>
                {}
                <Icon
                  name={"trash"}>
                </Icon>
                <label>
                  Delete Team
                </label>
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              inline={true}
              width={2}>
              <label>
                Anesthetist nurse
              </label>
            </FormField>
            <FormField
              inline={true}
              width={14}>
              <div
                style={{width: "100%"}}>
                {props.AnesthetistElement}
              </div>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}


export default CardAnesthesiaTeamUX

export const screenPropsDefault = {}

/* Date Time : Mon Jun 26 2023 06:54:07 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"1rem\", backgroundColor: props.card === \"CardBasicInfo\" ? \"\" :\"#D6ECF33B\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 1,
      "props": {
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesiologist"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthetist nurse"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Button",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "color": {
          "type": "code",
          "value": "\"red\""
        },
        "disabled": {
          "type": "code",
          "value": "props.isDisabled? true :false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onDeleteTeam"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"auto\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.AnesthetistElement"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Icon",
      "parent": 12,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "code",
          "value": "\"trash\""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "Delete Team"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.AnesthesiologistElement"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 18,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardAnesthesiaTeamUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
