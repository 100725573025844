import React, { useMemo, useCallback, useState, useEffect } from "react";
import {
  Menu,
  Input,
  Button,
  Segment,
  Label,
  Message,
  Modal,
  Table,
  Grid,
  Checkbox,
} from "semantic-ui-react";
import CardAdverseReactionNoteUX from "./CardAdverseReactionNoteUX";
import CardNaranjoAlgorithmUX from "./CardNaranjoAlgorithmUX";
import SearchBox from "react-lib/appcon/common/SearchBox";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
// import {
//   Table
// } from 'react-lib/frameworks/Table'

import "./CardAdverseReactionNote.scss";
import { DateTextBox } from "react-lib/apps/common";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import moment from "moment";
import { formatDate } from "react-lib/utils";

type CardAdverseReactionNoteProps = {
  onCancelNewADRNote: any;
  onSaveNewADRNote: any;
  onSaveCommonADRNote?: any;
  AllergySequence: any;
  onEvent: any;
  adrSelected: any;
  masterOptions: any;
  searchedItemList: any;
  setProp: any;
  onEdit?: any;
  rolePharma?: any;
};

const CardAdverseReactionNote = (props: CardAdverseReactionNoteProps) => {
  const [showGroupForm, setShowGroupForm] = useState<boolean>(false);
  const [showNaranjo, setShowNaranjo] = useState<boolean>(false);
  const [showPharmacist, setShowPharmacist] = useState(false);
  // const [selectedAdversary, setSelectedAdversary] = useState<any>(null);
  // const [selectedAdversaryId, setSelectedAdversaryId] = useState<number | null >(null);

  // useEffect(() => {
  //   if (props.AllergySequence?.selectedAdversaryName !== null) {
  //     props.onEvent({
  //       message: "ItemSearch",
  //       params: { action: "clear" },
  //     });
  //     props.setProp("AllergySequence.selectedAdversaryName", null)
  //   }
  // }, [props.AllergySequence?.activeADRMenu]);
  useEffect(() => {
    if (props.rolePharma) {
      setShowPharmacist(true);
    } else {
      setShowPharmacist(false);
    }
  }, [props.AllergySequence?.adrList]);
  console.log("PHARMACIST(AddDrugs) ===", props.rolePharma, showPharmacist);

  var today = new Date();
  var getdate =
    today.getDate() +
    "/" +
    (today.getMonth() + 1) +
    "/" +
    (today.getFullYear() + 543);
  const dayStart = moment(getdate, "DD/MM/YYYY")
    .lang("th")
    .format("DD/MM/YYYY");

  useEffect(() => {
    setShowGroupForm(false);
  }, [props.onEdit]);

  useEffect(() => {
    if (props.AllergySequence?.activeADRMenu === "group") {
      setShowGroupForm(true);
    }
  }, [props.AllergySequence?.activeADRMenu]); 

  const handleItemClick = (e: any, data: any) => {
    setShowGroupForm(false);
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "Allergy",
        action: "selectedADRType",
        activeADRMenu: data.name,
      },
    });
  };

  const handleItemClickFreeText = (e: any, data: any) => {
    setShowGroupForm(false);
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "Allergy",
        action: "selectedADRType",
        activeADRMenu: data.name,
      },
    });
  };

  const handleItemClickND = (e: any, data: any) => {
    setShowGroupForm(false);
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "Allergy",
        action: "selectedADRType",
        activeADRMenu: data.name,
      },
    });
  };

  const handleItemClickCH = (e: any, data: any) => {
    setShowGroupForm(true);
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "Allergy",
        action: "selectedADRType",
        activeADRMenu: data.name,
      },
    });
  };

  const handleNaranjoClick = () => {
    setShowNaranjo(true);
  };

  // useEffect(() => {
  //   setShowDrugForm(true);
  // }, [props.AllergySequence]);
  console.log("showgroup", showGroupForm);
  console.log(
    "props.AllergySequence?.adrSelected?.is_inactive",
    props.AllergySequence?.adrSelected?.is_inactive
  );

  const affectTypeOptions = useMemo(
    () => [
      {
        key: "1",
        text: "แพ้ยา",
        value: "ALLERGY",
      },
      {
        key: "2",
        text: "มีอาการข้างเคียง",
        value: "SIDE_EFFECT",
      },
    ],
    []
  );
  const MenuADR = useMemo(
    () => (
      <div>
        <div className="card-adverse-reaction-note-menu">
          <div className="card-adverse-reaction-note-menu-button">
            <Message
              size="massive"
              color="blue"
              tertiary
              style={{
                paddingLeft: "0.5em",
                paddingRight: "0.5em",
                width: "max-content",
              }}
            >
              <Label
                className="floating-label"
                size="medium"
                basic
                color="blue"
              >
                ยา[Drug]
              </Label>
              <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <Menu>
                  <Menu.Item
                    name="product"
                    active={props.AllergySequence?.activeADRMenu === "product"}
                    content="Product"
                    onClick={handleItemClick}
                  />
                  <Menu.Item
                    name="ingredient"
                    active={
                      props.AllergySequence?.activeADRMenu === "ingredient"
                    }
                    content="Ingredient"
                    onClick={handleItemClick}
                  />
                  <Menu.Item
                    name="mims"
                    active={props.AllergySequence?.activeADRMenu === "mims"}
                    content="MIMS Group"
                    onClick={handleItemClick}
                  />
                  <Menu.Item
                    name="atc"
                    active={props.AllergySequence?.activeADRMenu === "atc"}
                    content="ATC Group"
                    onClick={handleItemClick}
                  />
                  <Menu.Item
                    name="freetext"
                    active={props.AllergySequence?.activeADRMenu === "freetext"}
                    content="FreeText"
                    onClick={handleItemClickFreeText}
                  />
                </Menu>
              </div>
            </Message>
          </div>
          <div style={{ marginLeft: "40px" }}>
            <div className="card-adverse-reaction-note-menu-button">
              <Message
                size="massive"
                color="blue"
                tertiary
                style={{
                  paddingLeft: "0.5em",
                  paddingRight: "0.5em",
                  width: "max-content",
                }}
              >
                <Label
                  className="floating-label"
                  size="medium"
                  basic
                  color="blue"
                >
                  อื่นๆ[Other]
                </Label>
                <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                  <Menu>
                    <Menu.Item
                      name="food"
                      active={props.AllergySequence?.activeADRMenu === "food"}
                      content="แพ้อาหาร"
                      onClick={handleItemClickND}
                    />
                    <Menu.Item
                      name="other"
                      active={props.AllergySequence?.activeADRMenu === "other"}
                      content="แพ้สารอื่นๆ"
                      onClick={handleItemClickND}
                    />
                  </Menu>
                </div>
              </Message>
            </div>
          </div>
          <div
            style={{
              marginLeft: "40px",
              display: "flex",
              alignItems: "center",
              fontSize: "10px",
            }}
          >
            <div className="card-adverse-reaction-note-menu-button">
              <Button
                name="group"
                color="blue"
                size="large"
                onClick={handleItemClickCH}
              >
                เลือกจากข้อมูลที่พบบ่อย
              </Button>
            </div>
          </div>
        </div>
      </div>
    ),
    [props.AllergySequence?.activeADRMenu]
  );

  const handleOnChangeADR = useCallback(
    (field: string) =>
      (e: any, { value, checked }: any) => {
        console.log("handleOnChangeADR value", value);
        console.log("handleOnChangeADR checked", checked);
        let editValue = checked !== undefined ? checked : value;
        if (field === "is_inactive") {
          editValue = !editValue;
        }
        console.log(
          "handleOnChangeAdversaryText field: ",
          field,
          " value: ",
          editValue
        );
        props.onEvent({
          message: "RunSequence",
          params: {
            sequence: "Allergy",
            action: "editADR",
            field,
            value: editValue,
          },
        });
      },
    []
  );

  const handleOnChangeInactiveDate = (date: string) => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "Allergy",
        action: "editADR",
        field: "inactive_start_date",
        value: date,
      },
    });
  };

  const handleonChangeDateSymptoms = (date: string) => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "Allergy",
        action: "editADR",
        field: "issue_date",
        value: date,
      },
    });
  };

  const handleOnChangeTargetAction = (name: string) => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "Allergy",
        action: "editADR",
        field: "targetAction",
        value: name,
      },
    });
  };

  const handleChangeType = (e: any, { value }: any) => {
    console.log("handleChangeType field: type value: ", value);
    props.onEvent({
      message: "RunSequence",
      params: { sequence: "Allergy", action: "editADR", field: "type", value },
    });
  };

  const handleOnChangeNaranjoChecked = (e: any, v: any) => {
    props.setProp("AllergySequence.adrSelected.naranjoChecked", v.checked);

    if (!v.checked) {
      props.setProp("AllergySequence.adrSelected.naranjo_data", {});
      props.setProp("AllergySequence.adrSelected.score", {});
    } else {
      props.setProp("AllergySequence.adrSelected.probability", "");
    }
  };

  const handleChangeNaranjo = (e: any, v: any) => {
    props.setProp(
      `AllergySequence.adrSelected.naranjo_data.${v.name}`,
      v.value
    );

    if (["1", "3", "7", "8", "9", "10"].includes(v.name)) {
      if (v.value === "YES") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, 1);
      } else if (v.value === "NO") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, 0);
      } else if (v.value === "NA") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, 0);
      }
    } else if (["2", "4"].includes(v.name)) {
      if (v.value === "YES") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, 2);
      } else if (v.value === "NO") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, -1);
      } else if (v.value === "NA") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, 0);
      }
    } else if (v.name === "5") {
      if (v.value === "YES") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, -1);
      } else if (v.value === "NO") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, 2);
      } else if (v.value === "NA") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, 0);
      }
    } else if (v.name === "6") {
      if (v.value === "YES") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, -1);
      } else if (v.value === "NO") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, 1);
      } else if (v.value === "NA") {
        props.setProp(`AllergySequence.adrSelected.score.${v.name}`, 0);
      }
    }

    let totalScore = 0;
    for (let i = 0; i <= 10; i++) {
      if (props.AllergySequence.adrSelected.score?.[`${i}`] !== undefined) {
        totalScore =
          totalScore + props.AllergySequence.adrSelected.score?.[`${i}`];
      }
    }
    props.setProp("AllergySequence.adrSelected.naranjo_data.score", totalScore);

    if (totalScore > 8) {
      props.setProp(
        "AllergySequence.adrSelected.naranjo_data.status",
        "Definite [ใช่แน่] (Naranjo’s >8)"
      );
    } else if (totalScore <= 8 && totalScore >= 5) {
      props.setProp(
        "AllergySequence.adrSelected.naranjo_data.status",
        "Probable [น่าจะใช่] (Naranjo’s 5-8)"
      );
    } else if (totalScore <= 4 && totalScore >= 1) {
      props.setProp(
        "AllergySequence.adrSelected.naranjo_data.status",
        "Possible [อาจจะใช่] (Naranjo’s 1-4)"
      );
    } else if (totalScore < 1) {
      props.setProp(
        "AllergySequence.adrSelected.naranjo_data.status",
        "Doubtful [น่าสงสัย] (Naranjo’s <1)"
      );
    }
  };

  const handleSetProbability = () => {
    var score = props.AllergySequence?.adrSelected?.naranjo_data?.score;
    if (score === 0) {
      props.setProp("AllergySequence.adrSelected.probability", 1);
    } else if (score >= 1 && score <= 4) {
      props.setProp("AllergySequence.adrSelected.probability", 2);
    } else if (score >= 5 && score <= 8) {
      props.setProp("AllergySequence.adrSelected.probability", 3);
    } else if (score >= 9) {
      props.setProp("AllergySequence.adrSelected.probability", 4);
    }
    setShowNaranjo(false);
  };

  const mapNameCode = (items: any) =>
    items.map((item: any) => ({
      key: item.id,
      value: item.name_code,
      text: item.name_code,
      content: (
        <div style={{ lineHeight: "1.6" }}>
          {" "}
          <span style={{ fontWeight: "bold" }}>
            {" "}
            {item.name_code}{" "}
          </span> <br /> {item.full_name}{" "}
        </div>
      ), // (<div style={{lineHeight: "1.6"}}> <span style={{fontWeight: "bold"}}> {item.name_code} </span> <br/> {item.full_name} </div>)
    }));

  const mapIngredient = (items: any) =>
    items.map((item: any) => ({
      key: item.id,
      value: item.name,
      text: item.name,
    }));

  const mapMims = (items: any) =>
    items.map((item: any) => ({
      key: item.id,
      value: item.name_desc,
      text: item.name_desc,
    }));

  const mapAtc = (items: any) =>
    items.map((item: any) => ({
      key: item.id,
      value: item.name_desc,
      text: item.name_desc,
    }));

  const setSelectedAdversary = useCallback(
    (name: string, id: number) => {
      console.log(" setSelectedAdversary name, id: ", name, id);
      console.log("props.AllergySequence", props.AllergySequence);
      props.setProp("AllergySequence", {
        ...props.AllergySequence,
        selectedAdversaryName: name,
        selectedAdversaryId: id,
      });
    },
    [props.AllergySequence, props.setProp]
  );

  const searchAdversary = useMemo(() => {
    let searchBox: any;

    switch (props.AllergySequence?.activeADRMenu) {
      case "product":
        searchBox = (
          <SearchBoxDropdown
            type={"ADR" + "product"}
            style={{ width: "100%" }}
            fluid={true}
            startSearchLength={3}
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={props.AllergySequence?.selectedAdversaryName}
            setSelectedItem={setSelectedAdversary}
            mapOptions={mapNameCode}
          />
        );
        break;
      case "ingredient":
        searchBox = (
          <SearchBoxDropdown
            type={"ADR" + "ingredient"}
            style={{ width: "100%" }}
            fluid={true}
            startSearchLength={1}
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={props.AllergySequence?.selectedAdversaryName}
            setSelectedItem={setSelectedAdversary}
            mapOptions={mapIngredient}
          />
        );

        break;
      case "mims":
        searchBox = (
          <SearchBoxDropdown
            type={"ADR" + "mims"}
            style={{ width: "100%" }}
            fluid={true}
            startSearchLength={1}
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={props.AllergySequence?.selectedAdversaryName}
            setSelectedItem={setSelectedAdversary}
            mapOptions={mapMims}
          />
        );
        break;
      case "atc":
        searchBox = (
          <SearchBoxDropdown
            type={"ADR" + "atc"}
            style={{ width: "100%" }}
            fluid={true}
            startSearchLength={1}
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={props.AllergySequence?.selectedAdversaryName}
            setSelectedItem={setSelectedAdversary}
            mapOptions={mapAtc}
          />
        );
        break;
      case "freetext":
        searchBox = (
          <Input
            fluid={true}
            onChange={handleOnChangeADR("name")}
            value={props.AllergySequence?.adrSelected?.name}
          />
        );
        break;
      case "food":
        searchBox = (
          <Input
            fluid={true}
            onChange={handleOnChangeADR("other_adversary")}
            value={props.AllergySequence?.adrSelected?.other_adversary}
          />
        );
        break;

      default:
        break;
    }

    return searchBox;
  }, [
    props.AllergySequence?.activeADRMenu,
    props.searchedItemList,
    props.AllergySequence?.selectedAdversaryName,
    props.onEvent,
    props.AllergySequence?.adrSelected?.name,
    setSelectedAdversary,
  ]);

  return (
    <>
      <CardAdverseReactionNoteUX
        onCancelNewADRNote={props.onCancelNewADRNote}
        onSaveNewADRNote={props.onSaveNewADRNote}
        AllergySequence={props.AllergySequence}
        naranjoChecked={props.AllergySequence?.adrSelected?.naranjoChecked}
        naranjo={props.AllergySequence?.adrSelected?.naranjo_data}
        adrSelected={props.adrSelected}
        adrSymptomsOptions={props.masterOptions?.ADRSymptoms || []}
        adrSeverityOptions={props.masterOptions?.ADRSeverity || []}
        adrProbabilityOptions={props.masterOptions?.ADRProbability || []}
        searchAdversary={searchAdversary}
        // new ADR
        adversaryText={props.AllergySequence?.adrSelected?.name}
        symptoms={props.AllergySequence?.adrSelected?.symptoms || []}
        // TODO: ซ่อนไปก่อน เพราะ ยังไม่ได้ทำหลังบ้าน
        affectTypeOptions={affectTypeOptions} //
        affectType={props.AllergySequence?.adrSelected?.adversary_symptom_type} // ตัว type คือ DRUG, OTHER คนละตัว กับตัว affectType (new)
        severity={props.AllergySequence?.adrSelected?.severity}
        probability={props.AllergySequence?.adrSelected?.probability}
        note={props.AllergySequence?.adrSelected?.note}
        dateSymptoms={props.AllergySequence?.adrSelected?.issue_date}
        onChangeNaranjoChecked={handleOnChangeNaranjoChecked}
        onChangeAdversaryText={handleOnChangeADR("name")}
        onChangeSymptoms={handleOnChangeADR("symptoms")}
        onChangeAffectType={handleOnChangeADR("adversary_symptom_type")}
        onChangeType={handleChangeType}
        onChangeSeverity={handleOnChangeADR("severity")}
        onChangeProbability={handleOnChangeADR("probability")}
        onChangeNote={handleOnChangeADR("note")}
        onChangeDetails={handleOnChangeADR("other_adversary")}
        onChangeAllergy={handleOnChangeADR("allergy")}
        verifyChecked={props?.AllergySequence.adrSelected?.verifyChecked}
        onChangeVerifyChecked={handleOnChangeADR("verifyChecked")}
        onChangeTargetAction={handleOnChangeInactiveDate}
        onChangeDateSymptoms={handleonChangeDateSymptoms}
        menu={MenuADR}
        drugForm={["product", "ingredient", "mims", "atc"].includes(
          props?.AllergySequence.activeADRMenu
        )}
        otherForm={["food", "other"].includes(
          props?.AllergySequence.activeADRMenu
        )}
        showPharma={showPharmacist ? "inline-block" : "none"}
        typePharma={showPharmacist ? "flex" : "none"}
        drugFreeText={props?.AllergySequence.activeADRMenu === "freetext"}
        groupForm={showGroupForm}
        onClickNaranjo={handleNaranjoClick}
        pharmacyAction={props.AllergySequence?.adrSelected?.targetAction}
        onChangePharmacyAction={handleOnChangeTargetAction}
        adrActive={!props.AllergySequence?.adrSelected?.is_inactive}
        onChangeADRActive={handleOnChangeADR("is_inactive")}
        inactiveDate={
          props.AllergySequence?.adrSelected?.is_inactive && (
            <DateTextBox
              maxDate={dayStart}
              value={props?.AllergySequence?.adrSelected?.inactive_start_date}
              onChange={handleOnChangeInactiveDate}
            />
          )
        }
        modalNaranjo={
          <Modal
            open={showNaranjo}
            onClose={() => setShowNaranjo(false)}
            onOpen={() => setShowNaranjo(true)}
            size="large"
          >
            <CardNaranjoAlgorithmUX
              naranjo={props.AllergySequence?.adrSelected?.naranjo_data}
              score={props.AllergySequence?.adrSelected?.score}
              onChangeNaranjo={handleChangeNaranjo}
              onSave={handleSetProbability}
              onCencel={() => {
                props.setProp(`AllergySequence.adrSelected.naranjo_data`, {});
                props.setProp(`AllergySequence.adrSelected.score`, {});
                setShowNaranjo(false);
              }}
            />
          </Modal>
        }
        ingredientList={
          <>
            {props.AllergySequence.ingredientList?.map(
              (item: any, number: any) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      margin: "20px",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "5%" }}>
                      <Checkbox
                        checked={
                          props.AllergySequence?.ingredientData?.[number]
                            ?.checked || false
                        }
                        onChange={(e: any, v: any) => {
                          props.setProp(
                            `AllergySequence.ingredientData.${number}`,
                            {
                              ...item,
                              ...props.AllergySequence?.ingredientData?.[
                                number
                              ],
                              checked: v.checked,
                            }
                          );
                        }}
                      />
                    </div>
                    <div style={{ width: "20%", margin: "0px 20px" }}>
                      {item.name}
                    </div>
                    <div style={{ width: "75%" }}>
                      <Input
                        fluid={true}
                        value={
                          props.AllergySequence?.ingredientData?.[number]?.note
                        }
                        onChange={(e: any, v: any) => {
                          props.setProp(
                            `AllergySequence.ingredientData.${number}.note`,
                            v.value
                          );
                        }}
                      />
                    </div>
                  </div>
                );
              }
            )}
          </>
        }
        drugGroupList={
          <>
            {props.AllergySequence.drugGroupList?.map(
              (item: any, number: any) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      margin: "20px",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "5%" }}>
                      <Checkbox
                        checked={
                          props.AllergySequence?.drugGroupData?.[number]
                            ?.checked || false
                        }
                        onChange={(e: any, v: any) => {
                          props.setProp(
                            `AllergySequence.drugGroupData.${number}`,
                            {
                              ...item,
                              ...props.AllergySequence?.drugGroupData?.[number],
                              checked: v.checked,
                            }
                          );
                        }}
                      />
                    </div>
                    <div style={{ width: "20%", margin: "0px 20px" }}>
                      {item.name}
                    </div>
                    <div style={{ width: "75%" }}>
                      <Input
                        fluid={true}
                        value={
                          props.AllergySequence?.drugGroupData?.[number]?.note
                        }
                        onChange={(e: any, v: any) => {
                          props.setProp(
                            `AllergySequence.drugGroupData.${number}.note`,
                            v.value
                          );
                        }}
                      />
                    </div>
                  </div>
                );
              }
            )}
          </>
        }
        onSaveCommonADRNote={props.onSaveCommonADRNote}
      />

      <Modal
        open={props.AllergySequence?.modConfirmInactive?.open}
        closeOnDimmerClick={false}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              padding: "10px",
              backgroundColor: "#f00",
              color: "white",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            ข้อความแจ้งเตือน
          </div>
          <div
            style={{
              display: "flex",
              padding: "20px",
              flexDirection: "column",
            }}
          >
            <div style={{ paddingBottom: "10px" }}>
              กรุณาใส่เหตุผล เพื่อยืนยันการ Inactive ประวัติ ADR
            </div>
            <Input
              value={props.AllergySequence?.modConfirmInactive?.confirmNote}
              onChange={(e: any, data: any) => {
                props.setProp(
                  "AllergySequence.modConfirmInactive.confirmNote",
                  data.value
                );
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "20px",
            }}
          >
            <Button
              content="ยืนยัน"
              color="green"
              style={{ marginRight: "10px" }}
              onClick={props.onSaveNewADRNote}
            />
            <Button
              content="ยกเลิก"
              color="red"
              onClick={() => {
                props.setProp("AllergySequence.modConfirmInactive.open", false);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(CardAdverseReactionNote);
