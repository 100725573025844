import React, { useEffect } from "react";
import { Dropdown, Grid, Input } from "semantic-ui-react";

const styles = {
  labelBold: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  labelTitle: {
    background: "#2b3f51",
    color: "#fff",
  },
  labelHeader: {
    background: "#bdbdbd",
  },
  labelOdd: {
    background: "#fafafb",
  },
  labelEvent: {
    background: "#fff",
  },
};

const subjects: any[] = [
  { key: "time", title: "Time", style: styles.labelTitle },
  { key: "activity", title: "Activity", style: styles.labelOdd },
  { key: "respiration", title: "Respiration", style: styles.labelEvent },
  { key: "circulation", title: "Circulation", style: styles.labelOdd },
  { key: "consciousness", title: "Consciousness", style: styles.labelEvent },
  { key: "spO2Colour", title: "SpO2 / Colour", style: styles.labelOdd },
  { key: "total", title: "Total", style: styles.labelHeader },
  { key: "nauseaVomiting", title: "Nausea / Vomiting", style: styles.labelOdd },
  {
    key: "pain",
    title: "Pain (Numberic Rating Scale)",
    style: styles.labelEvent,
  },
  { key: "bleeding", title: "Bleeding", style: styles.labelOdd },
  { key: "mdRn", title: "MD/RN", style: styles.labelHeader },
];

type CardPostAnestheticRecoveryScoreProps = {
  // function
  setProp: any;

  // seq
  PACURecordSequence?: any;
  masterOptions?: any;
  runSequence?: any;
};

const CardPostAnestheticRecoveryScore = (
  props: CardPostAnestheticRecoveryScoreProps
) => {
  
  const handleChangeData = (index: number, data: any) => {
    let postData = {
      ...props.PACURecordSequence.data?.postAnestheticRecoveryScoreData,
    };
    postData[data.name][index] = data.value;
    props.setProp(
      "PACURecordSequence.data.postAnestheticRecoveryScoreData",
      postData
    );
  };

  return (
    <div style={{ width: "100%", height: "100%", padding: "10px" }}>
      <div
        style={{
          backgroundColor: "#EDF6F9",
          width: "100%",
          padding: "10px",
          borderRadius: 3,
          border: "solid 0.5px ",
          borderColor: "#5DBCD2",
        }}
      >
        <Grid celled verticalAlign="middle">
          {subjects.map((subj: any, sindex: number) => {
            return (
              <Grid.Row key={sindex} style={subj.style}>
                <Grid.Column width={2}>
                  <label style={styles.labelBold}>{subj.title}</label>
                </Grid.Column>
                {[...Array(7)].map((_item: any, index: number) => {
                  return (
                    // <Grid.Column width={2} key={index}>
                    //   <Input
                    //     fluid
                    //     name={subj.key}
                    //     value={
                    //       props.PACURecordSequence.data
                    //         ?.postAnestheticRecoveryScoreData?.[subj.key]?.[
                    //         index
                    //       ] || ""
                    //     }
                    //     onChange={(_event: any, data: any) =>
                    //       handleChangeData(index, data)
                    //     }
                    //   />
                    // </Grid.Column>
                    <Grid.Column width={2} key={index}>
                      {subj?.key === "mdRn" ? (
                        <Dropdown
                          fluid
                          name={subj.key}
                          // doctor and nurse data
                          options={[
                            ...props.masterOptions?.doctor || [],
                            ...props.PACURecordSequence?.userNurseOptions || [],
                          ]}
                          search={true}
                          selection={true}
                          clearable={true}
                          value={
                            props.PACURecordSequence.data
                              ?.postAnestheticRecoveryScoreData?.[subj.key]?.[
                              index
                            ] || ""
                          }
                          onChange={(_event: any, data: any) =>
                            handleChangeData(index, data)
                          }
                        ></Dropdown>
                      ) : (
                        <Input
                          fluid
                          name={subj.key}
                          value={
                            props.PACURecordSequence.data
                              ?.postAnestheticRecoveryScoreData?.[subj.key]?.[
                              index
                            ] || ""
                          }
                          onChange={(_event: any, data: any) =>
                            handleChangeData(index, data)
                          }
                        />
                      )}
                    </Grid.Column>
                  );
                })}
              </Grid.Row>
            );
          })}
          <Grid.Row style={styles.labelOdd}>
            <Grid.Column width={11}>
              <label style={styles.labelBold}>Total discharge score</label>
            </Grid.Column>
            <Grid.Column width={5}>
              <Input
                fluid
                value={
                  props.PACURecordSequence.data?.postAnestheticRecoveryScoreData
                    ?.totalDischargeScore || ""
                }
                onChange={(_event: any, data: any) =>
                  props.setProp(
                    "PACURecordSequence.data.postAnestheticRecoveryScoreData.totalDischargeScore",
                    data.value
                  )
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default React.memo(CardPostAnestheticRecoveryScore);
