import WasmController from "react-lib/frameworks/WasmController";
import * as ARInvoiceGroup from "./sequence/ARInvoiceGroup";
import * as ExportEClaimFile from "./sequence/ExportEClaimFile";
import * as DownloadSentClaimFile from "./sequence/DownloadSentClaimFile";

// APIS
// INF
import ARTransactionList from "issara-sdk/apis/ARTransactionList_apps_INF";
// Core
import EncounterList from "issara-sdk/apis/EncounterList_core";
// CLM
import ImportEmployeePatientCoverageList from "issara-sdk/apis/ImportEmployeePatientCoverageList_apps_CLMM"

export type State = {
  patientCoverageImportData?: {
    columns: string[];
    items_failed: any[];
    items_success: any[];
    total_failed: number;
    total_success: number;
  }
}
  & ARInvoiceGroup.State
  & ExportEClaimFile.State
  & DownloadSentClaimFile.State;

export const StateInitial: State = {
  ...ARInvoiceGroup.StateInitial,
  ...ExportEClaimFile.StateInitial,
  ...DownloadSentClaimFile.StateInitial
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetARTransactions"; params: {} }
  | { message: "GetEncounterList"; params: { type: string } }
  | { message: "HandleUploadPatientCoverage"; params: { type: string } }
  | ARInvoiceGroup.Event
  | ExportEClaimFile.Event
  | DownloadSentClaimFile.Event

export type Data = {
  division?: number;
  device?: number;
} & ARInvoiceGroup.Data
  & ExportEClaimFile.Data
  & DownloadSentClaimFile.Data

export const DataInitial = {
  ...ARInvoiceGroup.DataInitial,
  ...ExportEClaimFile.DataInitial,
  ...DownloadSentClaimFile.DataInitial
};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const GetARTransactions: Handler = (controller, params) => {
  return ARTransactionList.list({
    apiToken: controller.apiToken,
    params,
  });
};

export const GetEncounterList: Handler = async (controller, params) => {
  const [r, e, n] = await EncounterList.list({
    apiToken: controller.apiToken,
    params: {
      ...params,
    },
  });
  if (e) {
    return [[], null];
  }

  return [r.items, e];
};

export const HandleUploadPatientCoverage: Handler = async (controller, params) => {
  const state = controller.getState()

  const buttonKey = `${params.card}_${params.btnAction}`

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [buttonKey]: "LOADING" }
  })
  //* - fiscal_year = xxxx B.E.  ปีงบประมาณ
  //* - max_reimb = 10000  วงเงิน
  //* - save = true/false (เอาไว้ให้เทส upload ให้ส่ง false มา )
  //* - file = file.xlsx ที่เอาไว้ import 
  const data = {
    fiscal_year: params.fiscalYear,
    max_reimb: params.maxReimb,
    save: true,
    file: params.file
  }

  const result = await ImportEmployeePatientCoverageList.create({
    apiToken: controller.apiToken,
    data
  });


  if (result[1]) {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: result[1] },
      buttonLoadCheck: { ...state.buttonLoadCheck, [buttonKey]: "ERROR" }
    })
  } else {
    params.onSuccess?.()

    controller.setState({
      patientCoverageImportData: result[0],
      buttonLoadCheck: { ...state.buttonLoadCheck, [buttonKey]: "SUCCESS" }
    })
  }
}
