import React, { useMemo, useEffect } from "react";
import {
  Icon,
  Tab,
  Form,
  FormField,
  Input,
  Button,
  FormGroup,
  TabProps,
} from "semantic-ui-react";

// UI
import {
  MenuItem,
  AssistantsElement,
  SearchBoxWithKey,
} from "./CardSurgeryTeam";
import CardAddSurgeryTeamUX from "./CardAddSurgeryTeamUX";
import { TeamInitial } from "./sequence/PerioperativeNursing";

const CardBasicInfoSurgeryTeam = (props: any) => {
  useEffect(() => {
    props.runSequence({
      sequence: "PerioperativeNursing",
    });
  }, []);

  const handleSelectTeam = (e: any, data: TabProps) => {
    if (props.surgery_teams?.length === Number(data.activeIndex)) {
      handleAddTeam(e);
      return;
    }

    props.setProp(
      `PerioperativeNursingSequence.basicInfoData.activeSurgeonTeam`,
      Number(data.activeIndex)
    );
  };

  const handleAddTeam = (e: any) => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "add_surgery_team",
    });
  };

  const handleChiefSurgeon = (index: number) => async (item: any) => {
    await props.setProp(
      `PerioperativeNursingSequence.basicInfoData.surgery_teams.${index}.chief_surgeon`,
      item?.id || null
    );
    props.setProp(
      `PerioperativeNursingSequence.basicInfoData.surgery_teams.${index}.chief_surgeon_item`,
      item
    );
  };

  const handleActionAssistantSurgeon =
    (index: number) => (cIndex: number, item: any) => {
      const items = props.surgery_teams[index].assistant_surgeon_items || [];

      if (item === "add") {
        if (items.every((item: any) => item)) {
          items.push(null);
        } else {
          return;
        }
      } else if (item === "remove") {
        items.splice(cIndex, 1);
      } else {
        items[cIndex] = item;
      }

      props.setProp(
        `PerioperativeNursingSequence.basicInfoData.surgery_teams.${index}.assistant_surgeon_items`,
        items
      );
    };

  const handleActionScrubNurse =
    (index: number) => (cIndex: number, item: any) => {
      const items = props.surgery_teams[index].scrub_nurses_items || [];

      if (item === "add") {
        if (items.every((item: any) => item)) {
          items.push(null);
        } else {
          return;
        }
      } else if (item === "remove") {
        items.splice(cIndex, 1);
      } else {
        items[cIndex] = item;
      }

      props.setProp(
        `PerioperativeNursingSequence.basicInfoData.surgery_teams.${index}.scrub_nurses_items`,
        items
      );
    };

  const handleActionCirculatingNurse =
    (index: number) => (cIndex: number, item: any) => {
      const items = props.surgery_teams[index].circulating_nurses_items || [];

      if (item === "add") {
        if (items.every((item: any) => item)) {
          items.push(null);
        } else {
          return;
        }
      } else if (item === "remove") {
        items.splice(cIndex, 1);
      } else {
        items[cIndex] = item;
      }

      props.setProp(
        `PerioperativeNursingSequence.PerioperativeNursingSequence.basicInfoData.surgery_teams.${index}.circulating_nurses_items`,
        items
      );
    };

  const handleDeleteTeam = async () => {
    const teams = props.surgery_teams || [];

    if (teams.length === 1) {
      teams[props.activeIndex] = TeamInitial;
    } else {
      teams.splice(props.activeIndex, 1);

      const index = props.activeIndex - 2;

      await props.setProp(
        `PerioperativeNursingSequence.PerioperativeNursingSequence.basicInfoData.activeSurgeonTeam`,
        index < 0 ? 0 : index
      );
    }

    props.setProp(
      `PerioperativeNursingSequence.PerioperativeNursingSequence.basicInfoData.surgery_teams`,
      teams
    );
  };
  return (
    <div style={{ padding: "1rem", marginTop: "-1rem" }}>
      <Tab
        menu={{ secondary: true, pointing: true }}
        onTabChange={handleSelectTeam}
        panes={[
          ...(props?.surgery_teams || []).map((item: any, index: number) => ({
            menuItem: {
              key: `MenuItem-${index}`,
              content: (
                <MenuItem
                  index={index}
                  activeIndex={props.activeIndex}
                  title="Surgery Team"
                  isStar={item.is_main}
                />
              ),
            },
            render: () => (
              <CardAddSurgeryTeamUX
                isMain={item.is_main}
                handleDeleteTeam={handleDeleteTeam}
                chiefSurgeon={
                  item.is_main ? (
                    <Input
                      fluid={true}
                      readOnly={true}
                      value={item.chief_surgeon_item.name_code}
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <SearchBoxWithKey
                      // callback
                      onEvent={props.onEvent}
                      type="Doctor"
                      id={`Surgeon_${index + 1}`}
                      searchedItemListWithKey={props.searchedItemListWithKey}
                      selectedItem={item.chief_surgeon_item || null}
                      setSelected={handleChiefSurgeon(index)}
                      disabled={item.main ? true : false}
                    />
                  )
                }
                assistant_surgeons={
                  <AssistantsElement
                    // callback
                    onEvent={props.onEvent}
                    // data
                    index={index}
                    items={item.assistant_surgeon_items || []}
                    type="Doctor"
                    field="Assist_Surgeon"
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    setSelected={handleActionAssistantSurgeon(index)}
                    onClick={handleActionAssistantSurgeon(index)}
                  />
                }
                scrub_nurses={
                  <AssistantsElement
                    // callback
                    onEvent={props.onEvent}
                    // data
                    index={index}
                    items={item.scrub_nurses_items || []}
                    type="User"
                    role="REGISTERED_NURSE"
                    field="Nurse_Scrub_Surgeon"
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    setSelected={handleActionScrubNurse(index)}
                    onClick={handleActionScrubNurse(index)}
                  />
                }
                circulating_nurses={
                  <AssistantsElement
                    // callback
                    onEvent={props.onEvent}
                    // data
                    index={index}
                    items={item.circulating_nurses_items || []}
                    type="User"
                    role="REGISTERED_NURSE"
                    field="Nurse_Circulating_Surgeon_"
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    setSelected={handleActionCirculatingNurse(index)}
                    onClick={handleActionCirculatingNurse(index)}
                  />
                }
              />
            ),
          })),
          {
            menuItem: {
              key: `MenuItem-${props.items?.length || 0}`,
              content: (
                <div>
                  <Icon
                    name="add circle"
                    color="teal"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ),
            },
          },
        ]}
      />
    </div>
  );
};

export default React.memo(CardBasicInfoSurgeryTeam);
