import WasmController from "react-lib/frameworks/WasmController";

// APIS
import DoctorPendingTaskList from "issara-sdk/apis/DoctorPendingTaskList_apps_DPO";
import DoctorPendingTaskEdit from "issara-sdk/apis/DoctorPendingTaskEdit_apps_DPO";

export type State = {
  loadingStatus?: any;
  DoctorPendingTaskSequence?: {
    sequenceIndex?: string | null;
    hn?: string;
    status?: string;
    startDate?: string;
    endDate?: string;
    items?: any[];
  } | null;
};

export const StateInitial: State = {
  DoctorPendingTaskSequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const START: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.DoctorPendingTaskSequence) {
    return;
  }

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["doctorPendingTask", {}]],
    },
  });

  controller.setState(
    {
      DoctorPendingTaskSequence: {
        ...state.DoctorPendingTaskSequence,
        sequenceIndex: "SearchAndEdit",
        status: "all",
      },
    },
    () => {
      controller.handleEvent({
        message: "RunSequence",
        params: { ...params, action: "search" },
      });
    }
  );
};

export const SearchAndEdit: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.DoctorPendingTaskSequence) {
    return;
  }

  const pendingTask = state.DoctorPendingTaskSequence;

  if (params.action === "search") {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: true }
    })

    const result = await DoctorPendingTaskList.list({
      apiToken: controller.apiToken,
      params: {
        status: pendingTask.status === "all" ? "" : pendingTask.status,
        hn: pendingTask.hn,
        start_date: pendingTask.startDate,
        end_date: pendingTask.endDate,
        owner: true,
      },
    });

    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: false },
      DoctorPendingTaskSequence: {
        ...pendingTask,
        items: result[0]?.items || [],
      },
    });
  } else if (params.action === "edit") {
    const field = params.field;
    const data = params.data;
    if (field === "ack_comments") {
      data.ack_comments = params.value;

      if (data.ack_comments === "") {
        data.ack_result = null;
      } else {
        data.ack_result = "EDIT";
      }
    } else if (field === "approve") {
      if (data.ack_result === "APPROVE") {
        data.ack_result = null;
      } else if (data.ack_result === "EDIT") {
        data.ack_result = "EDIT";
      } else if (data.ack_result === null) {
        data.ack_result = "APPROVE";
      }
    }

    const items: any[] = pendingTask.items || [];
    items[params.index] = { ...data };

    controller.setState({
      DoctorPendingTaskSequence: {
        ...pendingTask,
        items: [...items],
      },
    });
  } else if (params.action === "save") {
    const result = await DoctorPendingTaskEdit.update({
      apiToken: controller.apiToken,
      data: {
        items: pendingTask.items || [],
      },
      extra:{
        division: controller.data.division,
        device: controller.data.device
      }
    });

    controller.setState(
      {
        loadingStatus: { ...state.loadingStatus, [params.card]: true },
        DoctorPendingTaskSequence: {
          ...pendingTask,
          sequenceIndex: "START",
        },
      },
      () => {
        controller.handleEvent({
          message: "RunSequence",
          params: { ...params, action: "" },
        });
      }
    );
  }
};