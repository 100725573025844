import React from "react";

// Common
import ModInfo from "react-lib/apps/common/ModInfo";

// Interface
import { PackageDetailType } from "./sequence/SettingPackage";

// Types
type ModDescriptionProps = {
  open: boolean;
  data: PackageDetailType | null;
  // callback
  onClose?: () => any;
};

const ModPackageDescription = (props: ModDescriptionProps) => {
  return (
    <ModInfo
      open={!!props.open}
      titleColor={"blue"}
      titleName="รายละเอียด Package"
      size="small"
      closeIcon={true}
      buttonStyle={{ display: "none" }}
      style={{ width: "auto", minWidth: "30%", maxWidth: "35%" }}
      onClose={props.onClose}
    >
      <div
        style={{
          fontWeight: "bold",
          textAlign: "left",
          maxHeight: "80vh",
          overflow: "auto",
          paddingTop: "1rem",
          margin: "-1rem -1.5em -1.5em",
          padding: "1.5em",
        }}
      >
        <div style={{ color: "#0072BC", fontSize: "1.2rem" }}>
          {props.data?.name}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            margin: "0.5rem 0 1rem",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              textAlign: "right",
              color: "#0072BC",
              fontSize: "1.75rem",
            }}
          >
            {props.data?.selling_price}
          </div>
          <div style={{ color: "#0072BC", marginLeft: "1rem" }}>บาท</div>
        </div>
        {[
          { name: "รายการตรวจ", name_en: "" },
          ...(props.data?.items || []).map((item) => ({
            name: item.product_name,
            name_en: item.product_name_en,
          })),
        ].map((item, index) => (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "20px",
              backgroundColor: index % 2 === 0 ? "#C1E9EE" : "",
              padding: "10px 15px",
              fontWeight: "normal",
              ...(index === 0
                ? {
                    color: "white",
                    fontWeight: "bold",
                    backgroundColor: "#5DBCD2",
                  }
                : {}),
            }}
          >
            <div>{item.name}</div>
            <div>{item.name_en}</div>
          </div>
        ))}
      </div>
    </ModInfo>
  );
};

export default React.memo(ModPackageDescription);
