import React from "react";
import PropTypes from "prop-types";
import * as CONSTANT from "../../utils/constant";
import * as Common from "../common";
import * as PTM from "../PTM";
import { Label } from "semantic-ui-react";

const CardFallRiskAssessment = React.forwardRef((props, ref) => {

  const [isLoading, setIsLoading] = React.useState(false)
  const [status, setStatus] = React.useState(null)
  const [fallRiskAssessmentData, setFallRiskAssessmentData] = React.useState({})


  React.useImperativeHandle(ref, () => ({
    refresh: ({ encounterId } = {}) => {
      refresh({ encounterId });
    }
  }));

  React.useEffect(() => {
    refresh({ encounterId: props.encounterId });
  }, [props.encounterId]);

  const refresh = ({ encounterId } = {}) => {
    getFormDataLatest({ encounterId });
  };

  const getFormDataLatest = async ({ encounterId }) => {
    setIsLoading(true)
    const [data, error] = await props.controller.getFormDataLatest({
      encounterId: encounterId
        ? encounterId
        : props.patientData.ENCOUNTER.encounter_id,
      formCode: "CardFallRiskAssessment",
      formVersion: "0.1"
    });
    if (data) {
      setStatus(data.status);
      setFallRiskAssessmentData(data.data);
    }
    setIsLoading(false)
  };

  let statusColor = "black";
  if (status === CONSTANT.FORM_DATA_STATUS.DRAFT) {
    statusColor = "yellow";
  } else if (status === CONSTANT.FORM_DATA_STATUS.EDITED) {
    statusColor = "olive";
  } else if (status === CONSTANT.FORM_DATA_STATUS.CONFIRMED) {
    statusColor = "green";
  } else if (status === CONSTANT.FORM_DATA_STATUS.CANCELLED) {
    statusColor = "red";
  }

  let labelStatus = <Label color={statusColor}>{status}</Label>;

  return (
    <Common.CardLayout
      headerColor="grey"
      loading={isLoading}
      titleText="Fall Risk Assessment"
      onClose={props.onClose}
      titleContent={labelStatus}
    >
      {props.showSummary ? (
        <PTM.SubFormViewer
          fieldList={rmdFieldList}
          fieldMap={rmdFieldMap}
          data={fallRiskAssessmentData}
        />
      ) : (
        "TODO ---> Implement ShowSummary = false"
      )}
    </Common.CardLayout>
  )
})

const rmdFieldList = ["user_fullname", "fall_risk", "nursing", "date"]

const rmdFieldMap = {
  "user_fullname": "ผู้บันทึก/แก้ไข",
  "fall_risk": "Fall Risk Assessment",
  "nursing": "การให้การพยาบาลผู้ป่วย",
  "date": "Date"
}

CardFallRiskAssessment.defaultProps = {
  onClose: () => {},
  controller: {},
  encounterId: null,
  showSummary: false,
};

CardFallRiskAssessment.propTypes = {
  onClose: PropTypes.func,
  controller: PropTypes.object,
  encounterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showSummary: PropTypes.bool,
};

export default React.memo(CardFallRiskAssessment);
