import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import {
  Label,
  Dropdown,
  Checkbox,
  Input,
  TextArea,
  FormGroup,
  FormField,
} from "semantic-ui-react";
import { DateTextBox } from "react-lib/apps/common";

const CardPreOPVisitUX = (props: any) => {
  return (
    <div
      style={{
        margin: "15px",
        padding: "20px",
        backgroundColor: "rgba(198, 235, 243, 0.2)",
      }}
    >
      <div style={{ paddingBottom: "5px" }}>
        <label style={{ fontWeight: "bold", fontSize: "22px" }}>
          Pre-OP Visit
        </label>
        <Label
          style={{
            float: "right",
            display: props.PreOperationSequence?.preOPVisitData?.status
              ? ""
              : "none",
          }}
        >
          {props.PreOperationSequence?.preOPVisitData?.status}
        </Label>
        <hr></hr>
      </div>
      <div style={{ display: "flex", paddingBottom: "5px" }}>
        <div style={{ display: "flex", width: "33%" }}>
          <label style={{ fontWeight: "bold", margin: "5px 10px 0px 0px" }}>
            Date of visit
          </label>
          <DateTextBox
            onChange={props.onChangeDate}
            value={
              props.PreOperationSequence?.preOPVisitData?.data?.date_of_visit ||
              ""
            }
          ></DateTextBox>
        </div>
        <div style={{ display: "flex", width: "33%" }}>
          <label style={{ fontWeight: "bold", margin: "5px 10px" }}>
            Location
          </label>
          <Dropdown
            clearable={true}
            name="location"
            onChange={props.onChangeData}
            options={props.masterOptions?.division || []}
            search={true}
            selection={true}
            value={
              props.PreOperationSequence?.preOPVisitData?.data?.location || ""
            }
          ></Dropdown>
        </div>
        <div style={{ display: "flex", width: "33%" }}>
          <Checkbox
            checked={
              props.PreOperationSequence?.preOPVisitData?.data
                ?.has_no_visit_reason || false
            }
            label="No Visit Reason"
            name="has_no_visit_reason"
            onChange={props.onChangeData}
            style={{ fontWeight: "bold", margin: "5px 10px" }}
          ></Checkbox>
          <Dropdown
            clearable={true}
            disabled={
              !props.PreOperationSequence?.preOPVisitData?.data
                ?.has_no_visit_reason
            }
            name="no_visit_reason"
            onChange={props.onChangeData}
            options={props.masterOptions?.noVisitReason || []}
            search={true}
            selection={true}
            value={
              props.PreOperationSequence?.preOPVisitData?.data
                ?.no_visit_reason || ""
            }
          ></Dropdown>
        </div>
      </div>
      <div style={{ display: "flex", paddingBottom: "5px" }}>
        <label
          style={{
            fontWeight: "bold",
            width: "15%",
            margin: "5px 10px 0px 0px",
          }}
        >
          History illness
        </label>
        <Input
          name="history_illness"
          onChange={props.onChangeData}
          style={{ width: "50%" }}
          value={
            props.PreOperationSequence?.preOPVisitData?.data?.history_illness ||
            ""
          }
        ></Input>
      </div>
      <div style={{ display: "flex", paddingBottom: "5px" }}>
        <label
          style={{
            fontWeight: "bold",
            width: "15%",
            margin: "5px 10px 0px 0px",
          }}
        >
          Previous Operating
        </label>
        <label>
          {props.PreOperationSequence?.preOPVisitData?.data
            ?.previous_operation || ""}
        </label>
      </div>
      <div style={{ display: "flex", paddingBottom: "5px" }}>
        <label
          style={{
            fontWeight: "bold",
            width: "15%",
            margin: "5px 10px 0px 0px",
          }}
        >
          Status of Conscious
        </label>
        <Dropdown
          clearable={true}
          name="status_of_consciousness"
          onChange={props.onChangeData}
          options={props.masterOptions?.statusOfConscious || []}
          search={true}
          selection={true}
          value={
            props.PreOperationSequence?.preOPVisitData?.data
              ?.status_of_consciousness || ""
          }
        ></Dropdown>
      </div>
      <div style={{ display: "flex", paddingBottom: "5px" }}>
        <label
          style={{
            fontWeight: "bold",
            width: "15%",
            margin: "5px 10px 0px 0px",
          }}
        >
          Psychological Status
        </label>
        <Dropdown
          clearable={true}
          name="psychological_status"
          onChange={props.onChangeData}
          options={props.masterOptions?.psychologicalStatus || []}
          search={true}
          selection={true}
          value={
            props.PreOperationSequence?.preOPVisitData?.data
              ?.psychological_status || ""
          }
        ></Dropdown>
      </div>
      <div style={{ display: "flex", paddingBottom: "5px" }}>
        <label
          style={{
            fontWeight: "bold",
            width: "15%",
            margin: "5px 10px 0px 0px",
          }}
        >
          Respiratory Status
        </label>
        <Dropdown
          clearable={true}
          name="respiratory_status"
          onChange={props.onChangeData}
          options={props.masterOptions?.respiratoryStatus || []}
          search={true}
          selection={true}
          value={
            props.PreOperationSequence?.preOPVisitData?.data
              ?.respiratory_status || ""
          }
        ></Dropdown>
      </div>
      <div style={{ display: "flex", paddingBottom: "5px" }}>
        <label
          style={{
            fontWeight: "bold",
            width: "15%",
            margin: "5px 10px 0px 0px",
          }}
        >
          Limitation
        </label>
        <Dropdown
          clearable={true}
          name="limitation"
          onChange={props.onChangeData}
          options={props.masterOptions?.limitation || []}
          search={true}
          selection={true}
          value={
            props.PreOperationSequence?.preOPVisitData?.data?.limitation || ""
          }
        ></Dropdown>
      </div>
      <div style={{ display: "flex", paddingBottom: "5px" }}>
        <label
          style={{
            fontWeight: "bold",
            width: "15%",
            margin: "5px 10px 0px 0px",
          }}
        >
          Implant
        </label>
        <Input
          name="pre_implant"
          onChange={props.onChangeData}
          style={{ width: "50%" }}
          value={
            props.PreOperationSequence?.preOPVisitData?.data?.pre_implant || ""
          }
        ></Input>
      </div>
      <div style={{ display: "flex", paddingBottom: "5px" }}>
        <label
          style={{
            fontWeight: "bold",
            width: "15%",
            margin: "5px 10px 0px 0px",
          }}
        >
          Remark
        </label>
        <div className="ui form" style={{ width: "60%" }}>
          <FormGroup inline={true}>
            <FormField width={16}>
              <TextArea
                name="remark"
                onChange={props.onChangeData}
                rows={5}
                style={{ width: "85%" }}
                value={
                  props.PreOperationSequence?.preOPVisitData?.data?.remark || ""
                }
              ></TextArea>
            </FormField>
          </FormGroup>
        </div>
      </div>
      <div style={{ height: "55px", paddingTop: "15px" }}>
        <div style={{ display: "flex", float: "right" }}>
          <div>{props.buttonSave}</div>
          <div>{props.buttonConfirm}</div>
          <div>{props.buttonUnconfirm}</div>
        </div>
      </div>
    </div>
  );
};

export default CardPreOPVisitUX;

export const screenPropsDefault = {};

/* Date Time : Mon Sep 19 2022 09:16:26 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\", padding: \"20px\", backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Pre-OP Visit"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"22px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.status"
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\", display: props.PreOperationSequence?.preOPVisitData?.status ? \"\" : \"none\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "Date of visit"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 7,
      "name": "DateTextBox",
      "parent": 12,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.date_of_visit || \"\""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "Location"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Dropdown",
      "parent": 14,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "location"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.division || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.location || \"\""
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Checkbox",
      "parent": 13,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.has_no_visit_reason || false"
        },
        "label": {
          "type": "value",
          "value": "No Visit Reason"
        },
        "name": {
          "type": "value",
          "value": "has_no_visit_reason"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Dropdown",
      "parent": 13,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.PreOperationSequence?.preOPVisitData?.data?.has_no_visit_reason"
        },
        "name": {
          "type": "value",
          "value": "no_visit_reason"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.noVisitReason || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.no_visit_reason || \"\""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"33%\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"33%\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"33%\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "History illness"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Input",
      "parent": 15,
      "props": {
        "name": {
          "type": "value",
          "value": "history_illness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.history_illness || \"\""
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Previous Operating"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.previous_operation || \"\""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "Status of Conscious"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Dropdown",
      "parent": 22,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status_of_consciousness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.statusOfConscious || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.status_of_consciousness || \"\""
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "Psychological Status"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Dropdown",
      "parent": 26,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "psychological_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.psychologicalStatus || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.psychological_status || \"\""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "Respiratory Status"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Dropdown",
      "parent": 29,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "respiratory_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.respiratoryStatus || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.respiratory_status || \"\""
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "Limitation"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Dropdown",
      "parent": 32,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "limitation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.limitation || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.limitation || \"\""
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": "Implant"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Input",
      "parent": 35,
      "props": {
        "name": {
          "type": "value",
          "value": "pre_implant"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.pre_implant || \"\""
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "Remark"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "TextArea",
      "parent": 52,
      "props": {
        "name": {
          "type": "value",
          "value": "remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "rows": {
          "type": "code",
          "value": "5"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"85%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.preOPVisitData?.data?.remark || \"\""
        }
      },
      "seq": 41,
      "void": true
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", float: \"right\" }"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\" }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"60%\" }"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormGroup",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 51,
      "props": {
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 52,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardPreOPVisitUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
