import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input,
  Button,
  TextArea
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const ANES_AnesComplication05 = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ margin: "0px 0px 50px" }}>
        <div
          style={{height:"50px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(1, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/41",display: "flex" , border: "solid 1px rgb(200, 200, 200, 0.5)","backgroundColor":"rgb(161, 221, 230, 0.49)", justifyContent: "space-between", }}>
              <label
                style={{textAlign:"center",marginLeft:"20px",paddingTop:"10px","fontWeight":"bold","fontSize":"17px"}}>
                ลงชื่อผู้ประเมินและข้อมูลส่งต่อ
              </label>
              <div
                style={{ margin: "5px", display: props.buttonStatus === "CONFIRMED" ?  "none" : "", }}>
                {props.buttonSave}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{height:"210px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(4, 1fr)","gridTemplateColumns":"repeat(1, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/2",display: "flex"}}>
              <label
                style={{textAlign:"center",marginLeft:"20px",paddingTop:"20px","fontSize":"16px"}}>
                รหัสเจ้าหน้าที่
              </label>
              <label
                style={{textAlign:"center",paddingTop:"20px","fontSize":"16px",color:"red"}}>
                *
              </label>
              <Input
                onChange={props?.handleCodeChange}
                style={{marginLeft:"20px",paddingTop:"10px",width:"55%"}}
                value={props.code}>
              </Input>
              <Button
                color="blue"
                onClick={props.handleAddInformationData}
                style={{marginLeft:"20px",marginTop:"10px",width:"100px"}}>
                ADD
              </Button>
            </div>
            <div
              style={{ gridRow: "2/5", gridColumn: "1/2" }}>
              <div
                style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(3, 1fr)","gridTemplateColumns":"repeat(28, 1fr)"}}>
                <div
                  style={{ gridRow: "2/3", gridColumn: "1/3" }}>
                  <label
                    style={{textAlign:"center",marginLeft:"20px",paddingTop:"20px","fontSize":"16px"}}>
                    ข้อมูลส่งต่อ
                  </label>
                </div>
                <div
                  style={{ gridRow: "1/4", gridColumn: "3/10"}}>
                  <TextArea
                    onChange={props?.handleTransferChange}
                    style={{width:"100%",height:"80%",marginTop:"10px"}}
                    value={props.transfer}>
                  </TextArea>
                </div>
                <div
                  style={{ gridRow: "2/3", gridColumn: "10/12"}}>
                  <label
                    style={{textAlign:"center",marginLeft:"20px",paddingTop:"20px","fontSize":"16px"}}>
                    Comment
                  </label>
                </div>
                <div
                  style={{ gridRow: "1/4", gridColumn: "12/19"}}>
                  <TextArea
                    onChange={props?.handleCommentChange}
                    style={{width:"100%",height:"80%",marginTop:"10px"}}
                    value={props.comment}>
                  </TextArea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table
          columns={props.transferInformationColumn}
          data={props.transferInformationData}
          showPagination={false}>
        </Table>
      </div>
    )
}

export default ANES_AnesComplication05

export const screenPropsDefault = {}

/* Date Time : Thu Dec 29 2022 03:02:21 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 0px 50px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"50px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/41\",display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230, 0.49)\", justifyContent: \"space-between\", }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ลงชื่อผู้ประเมินและข้อมูลส่งต่อ"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"10px\",\"fontWeight\":\"bold\",\"fontSize\":\"17px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"210px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "label": "divgrid",
      "name": "div",
      "parent": 42,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(4, 1fr)\",\"gridTemplateColumns\":\"repeat(1, 1fr)\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 43,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/2\",display: \"flex\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสเจ้าหน้าที่"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"20px\",\"fontSize\":\"16px\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "label",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",paddingTop:\"20px\",\"fontSize\":\"16px\",color:\"red\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Input",
      "parent": 44,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props?.handleCodeChange"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\",width:\"55%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.code"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Button",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "ADD"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleAddInformationData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",marginTop:\"10px\",width:\"100px\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 43,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/5\", gridColumn: \"1/2\" }"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "label": "divgrid",
      "name": "div",
      "parent": 57,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(3, 1fr)\",\"gridTemplateColumns\":\"repeat(28, 1fr)\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 58,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/3\" }"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 58,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/4\", gridColumn: \"3/10\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อมูลส่งต่อ"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"20px\",\"fontSize\":\"16px\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "TextArea",
      "parent": 60,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props?.handleTransferChange"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\",height:\"80%\",marginTop:\"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.transfer"
        }
      },
      "seq": 64,
      "void": true
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 58,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"10/12\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 58,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/4\", gridColumn: \"12/19\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "Comment"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"20px\",\"fontSize\":\"16px\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "TextArea",
      "parent": 66,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props?.handleCommentChange"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\",height:\"80%\",marginTop:\"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.comment"
        }
      },
      "seq": 68,
      "void": true
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 69,
      "name": "Table",
      "parent": 0,
      "props": {
        "columns": {
          "type": "code",
          "value": "props.transferInformationColumn"
        },
        "data": {
          "type": "code",
          "value": "props.transferInformationData"
        },
        "headers": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"5px\", display: props.buttonStatus === \"CONFIRMED\" ?  \"none\" : \"\", }"
        }
      },
      "seq": 70,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_AnesComplication05",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
