const convertToPlain = (html: any) => {
  // Create a new div element
  var tempDivElement = document.createElement("div");

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;

  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || "";
};

const FormWardQueue = async (props: any, htmlToPdfmakePass: any) => {

  let rowsData = [];
  for await (const item of props.data || []) {
    // console.log('htmlToPdfmakePass: ', htmlToPdfmakePass);
    let diagnosisText = await htmlToPdfmakePass(item.diagnosis);
    // console.log('diagnosisText: ', diagnosisText);
    let temp = [
      { text: item.room_no, style: "tableData", alignment: "center" },
      {
        text: item.ward_name,
        style: "tableData",
        alignment: "center",
      },
      {
        text:
          item.hn +
          (item?.encounter?.number
            ? "\n[" + item?.encounter?.number + "]"
            : ""),
        style: "tableData",
        alignment: "center",
      },
      {
        text:
          item.patient_name +
          "\n" +
          item.age +
          (item.birthdate ? "\n(" + item.birthdate + ")" : ""),
        style: "tableData",
        alignment: "left",
      },
      {
        text: item.room_item_status_label + "\n" + item.status_date,
        style: "tableData",
        alignment: "center",
      },
      {
        text: item.room_status,
        style: "tableData",
        alignment: "center",
      },
      {
        text: item.coverage,
        style: "tableData",
        alignment: "center",
      },
      {
        text: item.primary_doctor,
        style: "tableData",
        alignment: "center",
      },
      {
        stack: [diagnosisText],
        style: "tableData",
        alignment: "center",
      },
      // {
      //   text: ,
      //   style: "tableData",
      //   alignment: "center",
      // },
    ];
    rowsData.push(temp);

  }
  console.log('rowsData: ', rowsData);
  return {
    defaultStyle: {
      font: "THSarabunNew",
      // alignment: 'justify'
      lineHeight: 1,
      fontSize: 12,
    },
    pageMargins: [15, 95, 15, 30],
    // A4: [595.28, 841.89]
    pageSize: "A4",
    styles: {
      table: {
        margin: [0, 5, 0, 15],
      },
      header: {
        fontSize: 22,
        bold: true,
        alignment: "center",
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
        fillColor: "lightgray",
      },
      tableData: {},
    },
    header: {
      margin: [20, 20, 20, 0],
      stack: [
        {
          layout: "noBorders",
          // fillColor: "#1b9d2c",
          // fillOpacity: 0.4,
          table: {
            // widths: ["10%", "90%"],
            widths: ["100%"],
            heights: [55],
            body: [
              [
                // {
                //   // margin: [5, 2, 0, 0],
                //   // layout: "noBorders",
                //   // fillColor: "#1b9d2c",
                //   // fillOpacity: 0.4,
                //   // stack: [
                //   //   {
                //   //     image: "logo",
                //   //     fillColor: "#1b9d2c",
                //   //     fillOpacity: 0.4,
                //   //     width: 90,
                //   //   },
                //   // ],
                //   text: ""
                // },
                {
                  margin: [0, 0, 0, 0],
                  stack: [
                    {
                      text: `รายชื่อผู้ป่วยที่พักรักษาอยู่ภายในหออภิบาลผู้ป่วยใน ${
                        props.wardName ? "[" + props.wardName + "]" : ""
                      } `,
                      style: "header",
                    },
                    {
                      text: `ตั้งแต่วันที่ ${props?.start} ถึง ${props?.end}`,
                      style: "header",
                    },
                  ],
                  alignment: "center",
                  style: {
                    fontSize: 20,
                    bold: true,
                  },
                },
              ],
            ],
          },
        },
      ],
    },
    content: [
      {
        style: "table",
        table: {
          headerRows: 1,
          widths: [30, "*", 40, 80, "*", "*", "*", "*", "*"],
          body: [
            [
              { text: "ห้อง", style: "tableHeader", alignment: "center" },
              { text: "Ward", style: "tableHeader", alignment: "center" },
              { text: "HN/AN", style: "tableHeader", alignment: "center" },
              {
                text: "ข้อมูลผู้ป่วย",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "สถานะผู้ป่วย",
                style: "tableHeader",
                alignment: "center",
              },
              { text: "สถานะห้อง", style: "tableHeader", alignment: "center" },
              {
                text: "สิทธิ/Payer",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "แพทย์เจ้าของไข้",
                style: "tableHeader",
                alignment: "center",
              },
              { text: "Diagnosis", style: "tableHeader", alignment: "center" },
            ],
            ...rowsData,
          ],
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      let pageText = currentPage.toString() + " of " + pageCount;
      return { text: pageText, alignment: "center" };
    },
    // footer: function (currentPage: number, pageCount: number) {
    //   return {
    //     margin: [20, 0, 20, 0],
    //     stack: [
    //       {
    //         text: `Approved by: ${props.doctorDetail.full_name || ""} (ว.${
    //           props.doctorDetail?.certificate_no || ""
    //         })`,
    //         style: {
    //           bold: true,
    //           alignment: "right",
    //         },
    //       },
    //       {
    //         table: {
    //           widths: ["50%", "40%", "10%"],
    //           body: [
    //             [
    //               {
    //                 borderColor: ["#646464", "#646464", "#646464", "#646464"],
    //                 border: [false, true, false, false],
    //                 text: `HN: ${props.patient?.hn} ${props.patient?.full_name_th} DOB: ${props.patient?.birthdate}`,
    //                 italics: true,
    //                 style: {
    //                   fontSize: 12,
    //                   color: "#5A5A5A",
    //                 },
    //               },
    //               {
    //                 border: [false, true, false, false],
    //                 text: `Printed date: ${props.date} [${props.time}]`,
    //                 italics: true,
    //                 style: {
    //                   fontSize: 12,
    //                   color: "#5A5A5A",
    //                 },
    //               },
    //               {
    //                 border: [false, true, false, false],
    //                 text: `${currentPage.toString()} / ${pageCount}`,
    //                 italics: true,
    //                 style: {
    //                   fontSize: 12,
    //                   color: "#5A5A5A",
    //                   alignment: "right",
    //                 },
    //               },
    //             ],
    //           ],
    //         },
    //       },
    //     ],
    //   };
    // },
    // images: {
    //   logo: origin + "/static/images/laboratory_examination_summary/logoMor.png",
    //   queue: origin + "/static/images/menus/queue.png",
    // },
  };
};

export default FormWardQueue;
