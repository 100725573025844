import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input,
  Button,
  Dropdown,
  FormGroup,
  FormField,
  Icon
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardDrugOrderUX = (props: any) => {
    return(
      <div
        style={{ position: "relative" }}>
        <div
          style={{ padding: "5px", ...( props.hiddenDrugList ? {display: "none"} : {display: "block"}) }}>
          
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <div
              style={{ fontSize: "1.2rem", fontWeight: "bold"}}>
              รายการใบยา
            </div>
            <div
              style={{flex: 1}}>
              
            </div>
            <Button
              color="yellow"
              loading={props.loadingStatus?.PRINT_NARCOTIC_FORM}
              onClick={props.onPrintNarcotic}
              style={{display: props.isShowNarcoticPrint ? "" : "none"}}>
              พิมพ์ใบ ยส.
            </Button>
            <Button
              color="yellow"
              loading={props.loadingStatus?.PRINT_PSYCHOTROPIC_FORM}
              onClick={props.onPrintPsychotropic}
              style={{display: props.isShowPsychotropicPrint? "" : "none"}}>
              พิมพ์สั่งจ่ายวัตถุออกฤทธิ์
            </Button>
            <Button
              color="yellow"
              disabled={props.disabledPrintDrugOrderBtn}
              icon={true}
              labelPosition="left"
              onClick={props.onPrintDrugOrder}
              size="small">
              <Icon
                name="print">
              </Icon>
              <label>
                พิมพ์ใบสั่งยา
              </label>
            </Button>
            <Button
              color="green"
              id="btn-addMedOrder"
              onClick={props.addNewOrder}
              size="small">
              เพิ่มใบยา
            </Button>
          </div>
          <Table
            data={props.drugOrderList}
            getTrProps={props.listRowProps}
            headers="Code, Created,Status,Summary, Del, Edit"
            id="tb-medOrderList"
            keys="code,created,status_name,summary,delete,edit"
            minRows="5"
            showPagination={false}
            style={{height: props.statusRequest ? "300px" : "80vh" }}
            widths="200,300,200">
          </Table>
        </div>
        <div
          style={{display:  props.statusRequest ? "grid" : "none",    gridTemplateColumns: "repeat(9, minmax(0, 1fr))", padding: "5px",}}>
          
          <div
            style={{ gridColumn: "1/10"}}>
            {props.ErrorMessage}
          </div>
          <div
            style={{ gridColumn: "1/4", fontWeight: "bold" ,display:"flex" , fontSize: "1.2rem", margin: "20px 0px"}}>
            
            <div
              style={{ marginRight: "20px " }}>
              {props?.orderStatus}
            </div>
            <div>
              {props?.orderName}
            </div>
          </div>
          <div
            style={{ gridColumn: "1/4", fontWeight: "bold" ,display:"flex" }}>
            Drug
            <div
              className="required">
              *
            </div>
          </div>
          <div
            style={{ gridColumn: "5/7", fontWeight: "bold" }}>
            PRN
          </div>
          <div
            id="txt-drugName"
            style={{ gridColumn: "1/4" }}>
            
            <Input
              className={props.errorMessage &&!props.drugSearchText ? "error" :"" }
              disabled={props.statusRequest ? false : true}
              fluid={true}
              onChange={props.onDrugSearchTextChange}
              value={props.drugSearchText}>
            </Input>
            <div
              style={{ display: props.showSearchResult ? "block" : "none", position: "absolute", zIndex: 100, width: "80%", height: "400px" }}>
              
              <Table
                data={props.drugSearchResult}
                getTrProps={props.drugRowProps}
                headers="Generic Name, Drug Name,Form,Strength,Contain,หน่วยขาย"
                id="tb-medSearch"
                keys="generic_name,drug_name,dosage_form_name,strength,container,stock_unit_name"
                minRows="8"
                showPagination={false}
                style={{ height: "250px" }}>
              </Table>
            </div>
          </div>
          <div
            style={{ gridColumn: "4/5" }}>
            
            <Button
              color={props.hasFactSheet ? "blue" : undefined}
              disabled={!props.hasFactSheet}
              onClick={props.openFactSheet}
              style={{ width: "100%" }}>
              DRUG FACT
            </Button>
          </div>
          <div
            style={{ gridColumn: "5/7", fontWeight: "bold" }}>
            
            <Input
              disabled={!props.selectedDrug }
              fluid={true}
              onChange={props.ChangeDrugPrn}
              value={props.drugPrn? props.drugPrn : ""}>
            </Input>
          </div>
          <div
            style={{ gridColumn: "7/8", fontWeight: "bold" }}>
            
            <Button
              disabled={!props.modSolventSelectionConfig?.showButtonSolvent }
              fluid={true}
              onClick={props.onOpenModSolventSelection}
              size="small"
              style={{ display: props.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE ? "" : "none", height: "100%" }}>
              solvent
            </Button>
          </div>
          <div
            style={{display: props.selectedDrug?.original?.is_taper_dose ? "": "none", gridColumn: props.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE ?"8/9": "7/8", fontWeight: "bold" }}>
            
            <div>
              {props.buttonTaperSchedule}
            </div>
          </div>
          <div
            style={{ gridColumn: "9/10", display: "flex", justifyContent: "flex-end" }}>
            
            <Button
              disabled={props.statusRequest ? false : true}
              icon="file"
              onClick={props.clearDrugOrderForm}>
            </Button>
          </div>
          <div
            className="ui form"
            style={{gridColumn: "1/10", paddingLeft: "1rem", marginTop: "0.5rem"}}>
            
            <FormGroup>
              <FormField
                className={`required noPadding --hard${props.errorMessage &&!props.drugDose  ? " error" :""}${props.hideDrugForm?.dose ? " hidden": ""}`}
                width={2}>
                <label>
                  Dose
                </label>
                <Input
                  disabled={!props.selectedDrug}
                  fluid={true}
                  id="txt-drugDose"
                  onChange={props.changeDrugDose}
                  style={{opacity: 1}}
                  type="number"
                  value={props.drugDose ? props.drugDose : ""}>
                </Input>
              </FormField>
              <FormField
                className={`required noPadding --hard${props.errorMessage &&!props.selectedDrugUnitId ? " error" :""}${props.hideDrugForm?.unit ? " hidden": ""}`}
                width={2}>
                <label>
                  Unit
                </label>
                <Dropdown
                  disabled={!props.selectedDrug}
                  fluid={true}
                  id="cb-drugUnit"
                  onChange={props.changeDrugUnit}
                  options={props.drugUnits}
                  search={true}
                  selection={true}
                  value={props.selectedDrugUnitId}>
                </Dropdown>
              </FormField>
              <FormField
                className={`required noPadding --hard${props.errorMessage &&!props.selectedDrugRouteId ? " error" :""}${props.hideDrugForm?.route ? " hidden": ""}`}
                width={2}>
                <label>
                  Route
                </label>
                <Dropdown
                  disabled={!props.selectedDrug}
                  fluid={true}
                  id="cb-drugRoute"
                  onChange={props.changeDrugRoute}
                  options={props.drugRoutes}
                  search={true}
                  selection={true}
                  value={props.selectedDrugRouteId}>
                </Dropdown>
              </FormField>
              <FormField
                className={`required noPadding --hard${props.errorMessage &&!props.selectedDrugSiteId? " error" :"" }${props.hideDrugForm?.site ? " hidden": ""}`}
                width={2}>
                <label>
                  Site
                </label>
                <Dropdown
                  disabled={!props.selectedDrug}
                  fluid={true}
                  id="cb-drugSite"
                  onChange={props.changeDrugSite}
                  options={props.drugSites}
                  search={true}
                  selection={true}
                  value={props.selectedDrugSiteId}>
                </Dropdown>
              </FormField>
              <FormField
                className={`required noPadding --hard${props.errorMessage &&!props.selectedDrugFrequencyId? " error" :"" }${props.hideDrugForm?.frequency ? " hidden": ""}`}
                width={2}>
                <label>
                  Frequency
                </label>
                <Dropdown
                  disabled={!props.selectedDrug}
                  fluid={true}
                  id="cb-drugFrequency"
                  onChange={props.changeDrugFrequency}
                  options={props.drugFrequencies}
                  search={true}
                  selection={true}
                  value={props.selectedDrugFrequencyId}>
                </Dropdown>
              </FormField>
              <FormField
                className={`required noPadding --hard${props.hideDrugForm?.method ? " hidden": ""}`}
                width={2}>
                <label>
                  Method
                </label>
                <Dropdown
                  disabled={!props.selectedDrug}
                  fluid={true}
                  id="cb-drugMedthod"
                  onChange={props.changeDrugMethod}
                  options={props.drugMethods}
                  search={true}
                  selection={true}
                  value={props.selectedDrugMethodId}>
                </Dropdown>
              </FormField>
              <FormField
                className={`required noPadding --hard${props.errorMessage &&!props.drugQty? " error" :""}`}
                width={2}>
                <label>
                  Qty
                </label>
                <Input
                  disabled={!props.selectedDrug}
                  fluid={true}
                  id="txt-drugQty"
                  onChange={props.changeDrugQty}
                  style={{opacity: 1}}
                  value={props.drugQty ? props.drugQty : ""}>
                </Input>
              </FormField>
              <FormField
                className={`${props.noRequiredDuration ? "":"required "}noPadding --hard${props.hideDrugForm?.duration ? " hidden": ""}`}
                width={2}>
                <label>
                  Duration
                </label>
                <Input
                  disabled={!props.selectedDrug}
                  fluid={true}
                  id="txt-drugDuration"
                  onChange={props.changeDrugDuration}
                  readOnly={props.readOnlyDuration || false}
                  style={{opacity: 1}}
                  value={props.drugDuration ? props.drugDuration: ""}>
                </Input>
              </FormField>
              <div
                className="field  noPadding"
                style={{flex: props.drugFormFlexStyle ? 1 : ""}}>
                
              </div>
              <FormField>
                <Button
                  color="green"
                  disabled={props.statusRequest ? false : true}
                  fluid={true}
                  id="btn-addMedOrder"
                  onClick={props.addToOrder}
                  style={{marginTop: "25px", width: "150px"}}>
                  ADD TO ORDER
                </Button>
              </FormField>
            </FormGroup>
            <FormGroup
              style={{ display: props.hideDrugDescription ? "none" : ""}}>
              <FormField
                style={{padding: 0, marginTop: "-0.5rem"}}
                width={Object.values({...(props.hideDrugForm || {}),qty:false}).reduce((r,v)=>r+=(!!v ? 0 : 2),0)}>
                <label>
                  Description
                </label>
                <Input
                  disabled={!props.selectedDrug}
                  fluid={true}
                  id="txt-drugDescription"
                  onChange={props.changeDrugDescription}
                  style={{opacity: 1}}
                  value={props.drugDescription || ""}>
                </Input>
              </FormField>
            </FormGroup>
          </div>
        </div>
        <div
          style={{ display: props.statusRequest ? "" : "none", padding: "5px"}}>
          
          <Table
            data={props.drugOrderItems}
            getTrProps={props.drugItemsTrProps}
            headers="Drug,Dose,Unit,Route,Frequency,Method,PRN,QTY,Duration,Price/Unit,Total Price,"
            id="tb-medicationList"
            keys="title_status,dose,unit,route,frequency,method,prn,quantity,duration,price_unit,price_total,_remove"
            minRows="8"
            showPagination={false}
            style={{ height: "300px" }}
            widths="auto,100,100,100,auto,100,100,80,80,90,90,50">
          </Table>
        </div>
        <div
          style={{ display: "none"  }}>
          
          <div
            style={{"height": "110px", "display": "grid", "gridTemplateRows": "10.638297872340425% 5.319148936170213% 4.25531914893617% 2.127659574468085% 3.1914893617021276% 3.1914893617021276% 2.127659574468085% 2.127659574468085% 3.1914893617021276% 8.51063829787234% 5.319148936170213% 2.127659574468085% 9.574468085106384% 1.0638297872340425% 1.0638297872340425% 7.446808510638298% 1.0638297872340425% 1.0638297872340425% 2.127659574468085% 6.382978723404255% 2.127659574468085% 15.957446808510639%", "gridTemplateColumns": "0.5198180636777128% 1.949317738791423% 2.2092267706302793% 2.079272254710851% 0.8447043534762833% 6.562703053931124% 0.3898635477582846% 3.833658219623132% 37.10201429499675% 0.9746588693957114% 3.5737491877842755% 0.8447043534762833% 0.3898635477582846% 3.248862897985705% 3.378817413905133% 0.9746588693957114% 4.028589993502274% 0.9096816114359974% 0.3248862897985705% 2.3391812865497075% 3.898635477582846% 0.9746588693957114% 5.003248862897986% 4.158544509421702% 0.3248862897985705% 3.248862897985705% 3.898635477582846% 0.4548408057179987% 0.5847953216374269% 0.9746588693957114%"}}>
            <div
              style={{"gridRow": "2/11", "gridColumn": "5/30",  "width": "100%", "height": "100%"}}>
              
              <Dropdown
                onChange={props.onChangeDivision}
                options={props.divisionOptions}
                selection={true}
                style={{ width: "100%" , height: "100%"}}
                value={props.orderDiv}>
              </Dropdown>
            </div>
            <div
              style={{"gridRow": "6/7", "gridColumn": "28/29", "border": "solid black 1px", "width": "100%", "height": "100%"}}>
              x
            </div>
            <div
              style={{"gridRow": "3/8", "gridColumn": "2/4", "width": "100%", "height": "100%"}}>
              แผนกที่สั่ง
            </div>
            <div
              style={{"gridRow": "12/22", "gridColumn": "5/10", "width": "100%", "height": "100%"}}>
              
              <Input
                id="txt-notetoPharmacy"
                onChange={props.onChangeDoctorNote}
                style={{ width: "100%" }}
                value={props.doctorNote}>
              </Input>
            </div>
            <div
              style={{"gridRow": "13/21", "gridColumn": "11/16", "width": "100%", "height": "100%"}}>
              
              <Button
                disabled={props.statusRequest ? false : true}
                id="btn-remed"
                onClick={props.onClickREMED}
                style={{ width: "100%", display: "none" }}>
                REMED
              </Button>
              <Button
                color="blue"
                disabled={props.statusRequest ? false : true}
                onClick={props.onClickDrugHistory}
                style={{width: "100%"}}>
                Drug History
              </Button>
            </div>
            <div
              style={{"gridRow": "14/17", "gridColumn": "2/3", "width": "100%", "height": "100%"}}>
              Note
            </div>
            <div
              style={{"gridRow": "13/21", "gridColumn": "17/22", "width": "100%", "height": "100%"}}>
              
              <Button
                disabled={props.statusRequest ? false : true}
                id="btn-medSet"
                onClick={props.onClickOrderset}
                style={{ width: "100%" }}>
                ชุดยา
              </Button>
            </div>
            <div
              style={{"gridRow": "13/21", "gridColumn": "23/30", "width": "100%", "height": "100%"}}>
              
              <Button
                color="green"
                disabled={props.statusRequest ? false : true}
                id="btn-confirmMedOrder"
                onClick={props.confirmOrder}
                style={{ width: "100%", ...(props.disabledConfirmedOrderBtn && {display: "none"})}}>
                CONFIRM ORDER
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{ display: props.statusRequest ? "" : "none"  }}>
          
          <div
            className="ui form"
            style={{padding: "10px"}}>
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={1}>
                <div
                  style={{minWidth: "max-content"}}>
                  แผนกที่สั่ง
                </div>
              </FormField>
              <FormField
                inline={true}
                width={(props.roleTypes || []).includes("DOCTOR") ? 15 : 7}>
                <div
                  style={{"width": "100%", "height": "100%"}}>
                  
                  <Dropdown
                    onChange={props.onChangeDivision}
                    options={props.divisionOptions}
                    selection={true}
                    style={{ width: "100%" , height: "100%"}}
                    value={props.orderDiv}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={(props.roleTypes || []).includes("DOCTOR") ? 0 : 1}>
                <div
                  style={{ display: (props.roleTypes || []).includes("DOCTOR") ? "none" : "", minWidth: "max-content" }}>
                  แพทย์ผู้สั่ง
                </div>
              </FormField>
              <FormField
                inline={true}
                width={(props.roleTypes || []).includes("DOCTOR") ? 0 : 7}>
                <div
                  style={{"width": "100%", "height": "100%", display: (props.roleTypes || []).includes("DOCTOR") ? "none" : "" }}>
                  {props.doctorElement}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={1}>
                <div
                  style={{"gridRow": "14/17", "gridColumn": "2/3", "width": "100%", "height": "100%"}}>
                  Note
                </div>
              </FormField>
              <FormField
                inline={true}
                width={props.showRecon? 6 : 8}>
                <div
                  style={{"width": "100%", "height": "100%"}}>
                  
                  <Input
                    id="txt-notetoPharmacy"
                    onChange={props.onChangeDoctorNote}
                    style={{ width: "100%" }}
                    value={props.doctorNote}>
                  </Input>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={props.showRecon? 4 :  2}>
                <div
                  style={{"width": "100%", "height": "100%", display: "flex"}}>
                  
                  <Button
                    disabled={props.statusRequest ? false : true}
                    id="btn-remed"
                    onClick={props.onClickREMED}
                    style={{ width: "100%", display: "none" }}>
                    REMED
                  </Button>
                  <Button
                    color="teal"
                    disabled={props.statusRequest ? false : true}
                    id="btn-remed"
                    onClick={props.onClickRecontinueMed}
                    style={{width: "100%", marginRight: "15px", display: props.showRecon? "" : "none"}}>
                    RECONTINUE
                  </Button>
                  <Button
                    color="blue"
                    disabled={props.statusRequest ? false : true}
                    onClick={props.onClickDrugHistory}
                    style={{width: "100%", minWidth: "max-content"}}>
                    Drug History
                  </Button>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{"width": "100%", "height": "100%"}}>
                  
                  <Button
                    disabled={props.statusRequest ? false : true}
                    id="btn-medSet"
                    onClick={props.onClickOrderset}
                    style={{ width: "100%" }}>
                    ชุดยา
                  </Button>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{"width": "100%", "height": "100%"}}>
                  
                  <Button
                    color="green"
                    disabled={props.statusRequest ? false : true}
                    id="btn-confirmMedOrder"
                    onClick={props.confirmOrder}
                    style={{ width: "100%", ...(props.disabledConfirmedOrderBtn && {display: "none"})}}>
                    CONFIRM ORDER
                  </Button>
                  <div>
                    {props.buttonConfirmOrder}
                  </div>
                </div>
              </FormField>
            </FormGroup>
          </div>
        </div>
      </div>
    )
}


export default CardDrugOrderUX

export const screenPropsDefault = {"orderType":"ONE_DOSE","selectedDrug":{},"showSearchResult":true}

/* Date Time : Tue May 09 2023 05:38:09 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ position: \"relative\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:  props.statusRequest ? \"grid\" : \"none\",    gridTemplateColumns: \"repeat(9, minmax(0, 1fr))\", padding: \"5px\",}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\"  }"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.statusRequest ? \"\" : \"none\", padding: \"5px\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Drug"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/4\", fontWeight: \"bold\" ,display:\"flex\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "PRN"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"5/7\", fontWeight: \"bold\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "txt-drugName"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/4\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"4/5\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"5/7\", fontWeight: \"bold\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Input",
      "parent": 8,
      "props": {
        "className": {
          "type": "code",
          "value": "props.errorMessage &&!props.drugSearchText ? \"error\" :\"\" "
        },
        "disabled": {
          "type": "code",
          "value": "props.statusRequest ? false : true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onDrugSearchTextChange"
        },
        "value": {
          "type": "code",
          "value": "props.drugSearchText"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "DRUG FACT"
        },
        "color": {
          "type": "code",
          "value": "props.hasFactSheet ? \"blue\" : undefined"
        },
        "disabled": {
          "type": "code",
          "value": "!props.hasFactSheet"
        },
        "onClick": {
          "type": "code",
          "value": "props.openFactSheet"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Input",
      "parent": 10,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug "
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.ChangeDrugPrn"
        },
        "value": {
          "type": "code",
          "value": "props.drugPrn? props.drugPrn : \"\""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"height\": \"110px\", \"display\": \"grid\", \"gridTemplateRows\": \"10.638297872340425% 5.319148936170213% 4.25531914893617% 2.127659574468085% 3.1914893617021276% 3.1914893617021276% 2.127659574468085% 2.127659574468085% 3.1914893617021276% 8.51063829787234% 5.319148936170213% 2.127659574468085% 9.574468085106384% 1.0638297872340425% 1.0638297872340425% 7.446808510638298% 1.0638297872340425% 1.0638297872340425% 2.127659574468085% 6.382978723404255% 2.127659574468085% 15.957446808510639%\", \"gridTemplateColumns\": \"0.5198180636777128% 1.949317738791423% 2.2092267706302793% 2.079272254710851% 0.8447043534762833% 6.562703053931124% 0.3898635477582846% 3.833658219623132% 37.10201429499675% 0.9746588693957114% 3.5737491877842755% 0.8447043534762833% 0.3898635477582846% 3.248862897985705% 3.378817413905133% 0.9746588693957114% 4.028589993502274% 0.9096816114359974% 0.3248862897985705% 2.3391812865497075% 3.898635477582846% 0.9746588693957114% 5.003248862897986% 4.158544509421702% 0.3248862897985705% 3.248862897985705% 3.898635477582846% 0.4548408057179987% 0.5847953216374269% 0.9746588693957114%\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\": \"2/11\", \"gridColumn\": \"5/30\",  \"width\": \"100%\", \"height\": \"100%\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "x"
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\": \"6/7\", \"gridColumn\": \"28/29\", \"border\": \"solid black 1px\", \"width\": \"100%\", \"height\": \"100%\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนกที่สั่ง"
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\": \"3/8\", \"gridColumn\": \"2/4\", \"width\": \"100%\", \"height\": \"100%\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\": \"12/22\", \"gridColumn\": \"5/10\", \"width\": \"100%\", \"height\": \"100%\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\": \"13/21\", \"gridColumn\": \"11/16\", \"width\": \"100%\", \"height\": \"100%\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "Note"
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\": \"14/17\", \"gridColumn\": \"2/3\", \"width\": \"100%\", \"height\": \"100%\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\": \"13/21\", \"gridColumn\": \"17/22\", \"width\": \"100%\", \"height\": \"100%\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\": \"13/21\", \"gridColumn\": \"23/30\", \"width\": \"100%\", \"height\": \"100%\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Dropdown",
      "parent": 41,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDivision"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" , height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.orderDiv"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Button",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": "REMED"
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.statusRequest ? false : true"
        },
        "id": {
          "type": "value",
          "value": "btn-remed"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickREMED"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"none\" }"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Button",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "ชุดยา"
        },
        "disabled": {
          "type": "code",
          "value": "props.statusRequest ? false : true"
        },
        "id": {
          "type": "value",
          "value": "btn-medSet"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickOrderset"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Input",
      "parent": 46,
      "props": {
        "id": {
          "type": "value",
          "value": "txt-notetoPharmacy"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDoctorNote"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "type": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.doctorNote"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Button",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "CONFIRM ORDER"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.statusRequest ? false : true"
        },
        "id": {
          "type": "value",
          "value": "btn-confirmMedOrder"
        },
        "onClick": {
          "type": "code",
          "value": "props.confirmOrder"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", ...(props.disabledConfirmedOrderBtn && {display: \"none\"})}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.showSearchResult ? \"block\" : \"none\", position: \"absolute\", zIndex: 100, width: \"80%\", height: \"400px\" }"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 62,
      "name": "Table",
      "parent": 61,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugSearchResult"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.drugRowProps"
        },
        "headers": {
          "type": "value",
          "value": "Generic Name, Drug Name,Form,Strength,Contain,หน่วยขาย"
        },
        "id": {
          "type": "value",
          "value": "tb-medSearch"
        },
        "keys": {
          "type": "value",
          "value": "generic_name,drug_name,dosage_form_name,strength,container,stock_unit_name"
        },
        "minRows": {
          "type": "value",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"250px\" }"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 63,
      "name": "Table",
      "parent": 4,
      "props": {
        "columns": {
          "type": "value",
          "value": ""
        },
        "data": {
          "type": "code",
          "value": "props.drugOrderItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.drugItemsTrProps"
        },
        "headers": {
          "type": "value",
          "value": "Drug,Dose,Unit,Route,Frequency,Method,PRN,QTY,Duration,Price/Unit,Total Price,"
        },
        "id": {
          "type": "value",
          "value": "tb-medicationList"
        },
        "keys": {
          "type": "value",
          "value": "title_status,dose,unit,route,frequency,method,prn,quantity,duration,price_unit,price_total,_remove"
        },
        "minRows": {
          "type": "value",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"300px\" }"
        },
        "widths": {
          "type": "value",
          "value": "auto,100,100,100,auto,100,100,80,80,90,90,50"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"9/10\", display: \"flex\", justifyContent: \"flex-end\" }"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Button",
      "parent": 64,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.statusRequest ? false : true"
        },
        "icon": {
          "type": "value",
          "value": "file"
        },
        "onClick": {
          "type": "code",
          "value": "props.clearDrugOrderForm"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", ...( props.hiddenDrugList ? {display: \"none\"} : {display: \"block\"}) }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 71,
      "name": "Table",
      "parent": 70,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugOrderList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.listRowProps"
        },
        "headers": {
          "type": "value",
          "value": "Code, Created,Status,Summary, Del, Edit"
        },
        "id": {
          "type": "value",
          "value": "tb-medOrderList"
        },
        "keys": {
          "type": "value",
          "value": "code,created,status_name,summary,delete,edit"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: props.statusRequest ? \"300px\" : \"80vh\" }"
        },
        "widths": {
          "type": "value",
          "value": "200,300,200"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการใบยา"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Button",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มใบยา"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "id": {
          "type": "value",
          "value": "btn-addMedOrder"
        },
        "onClick": {
          "type": "code",
          "value": "props.addNewOrder"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/10\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "className": {
          "type": "value",
          "value": "required"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"7/8\", fontWeight: \"bold\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "Button",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "solvent"
        },
        "disabled": {
          "type": "code",
          "value": "!props.modSolventSelectionConfig?.showButtonSolvent "
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenModSolventSelection"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE ? \"\" : \"none\", height: \"100%\" }"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridColumn: \"1/4\", fontWeight: \"bold\" ,display:\"flex\" , fontSize: \"1.2rem\", margin: \"20px 0px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 90,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.orderStatus"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px \" }"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 90,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.orderName"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{gridColumn: \"1/10\", paddingLeft: \"1rem\", marginTop: \"0.5rem\"}"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "FormGroup",
      "parent": 93,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "FormField",
      "parent": 94,
      "props": {
        "className": {
          "type": "code",
          "value": "`required noPadding --hard${props.errorMessage &&!props.drugDose  ? \" error\" :\"\"}${props.hideDrugForm?.dose ? \" hidden\": \"\"}`"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "label",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": "Dose"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "Input",
      "parent": 95,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "txt-drugDose"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDrugDose"
        },
        "style": {
          "type": "code",
          "value": "{opacity: 1}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.drugDose ? props.drugDose : \"\""
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormField",
      "parent": 94,
      "props": {
        "className": {
          "type": "code",
          "value": "`required noPadding --hard${props.errorMessage &&!props.selectedDrugUnitId ? \" error\" :\"\"}${props.hideDrugForm?.unit ? \" hidden\": \"\"}`"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "FormField",
      "parent": 94,
      "props": {
        "className": {
          "type": "code",
          "value": "`required noPadding --hard${props.errorMessage &&!props.selectedDrugRouteId ? \" error\" :\"\"}${props.hideDrugForm?.route ? \" hidden\": \"\"}`"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 94,
      "props": {
        "className": {
          "type": "code",
          "value": "`required noPadding --hard${props.errorMessage &&!props.selectedDrugSiteId? \" error\" :\"\" }${props.hideDrugForm?.site ? \" hidden\": \"\"}`"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "FormField",
      "parent": 94,
      "props": {
        "className": {
          "type": "code",
          "value": "`required noPadding --hard${props.errorMessage &&!props.selectedDrugFrequencyId? \" error\" :\"\" }${props.hideDrugForm?.frequency ? \" hidden\": \"\"}`"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 94,
      "props": {
        "className": {
          "type": "code",
          "value": "`required noPadding --hard${props.hideDrugForm?.method ? \" hidden\": \"\"}`"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "FormField",
      "parent": 94,
      "props": {
        "className": {
          "type": "code",
          "value": "`required noPadding --hard${props.errorMessage &&!props.drugQty? \" error\" :\"\"}`"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "FormField",
      "parent": 94,
      "props": {
        "className": {
          "type": "code",
          "value": "`${props.noRequiredDuration ? \"\":\"required \"}noPadding --hard${props.hideDrugForm?.duration ? \" hidden\": \"\"}`"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "FormField",
      "parent": 94,
      "props": {
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "label",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "Unit"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "label",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": "Route"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "Site"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "label",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "Frequency"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "label",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": "Method"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "label",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "Qty"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "label",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "Duration"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Dropdown",
      "parent": 98,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-drugUnit"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDrugUnit"
        },
        "options": {
          "type": "code",
          "value": "props.drugUnits"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugUnitId"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Dropdown",
      "parent": 99,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-drugRoute"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDrugRoute"
        },
        "options": {
          "type": "code",
          "value": "props.drugRoutes"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugRouteId"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Dropdown",
      "parent": 100,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-drugSite"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDrugSite"
        },
        "options": {
          "type": "code",
          "value": "props.drugSites"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugSiteId"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "Dropdown",
      "parent": 101,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-drugFrequency"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDrugFrequency"
        },
        "options": {
          "type": "code",
          "value": "props.drugFrequencies"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugFrequencyId"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "Dropdown",
      "parent": 102,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-drugMedthod"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDrugMethod"
        },
        "options": {
          "type": "code",
          "value": "props.drugMethods"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugMethodId"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "Input",
      "parent": 103,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "txt-drugQty"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDrugQty"
        },
        "style": {
          "type": "code",
          "value": "{opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.drugQty ? props.drugQty : \"\""
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "Input",
      "parent": 104,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "txt-drugDuration"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDrugDuration"
        },
        "readOnly": {
          "type": "code",
          "value": "props.readOnlyDuration || false"
        },
        "style": {
          "type": "code",
          "value": "{opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.drugDuration ? props.drugDuration: \"\""
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "Button",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ADD TO ORDER"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.statusRequest ? false : true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "btn-addMedOrder"
        },
        "onClick": {
          "type": "code",
          "value": "props.addToOrder"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"25px\", width: \"150px\"}"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field  noPadding"
        },
        "style": {
          "type": "code",
          "value": "{flex: props.drugFormFlexStyle ? 1 : \"\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "Button",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": "Drug History"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.statusRequest ? false : true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickDrugHistory"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.statusRequest ? \"\" : \"none\"  }"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "FormField",
      "parent": 140,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormField",
      "parent": 140,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "(props.roleTypes || []).includes(\"DOCTOR\") ? 15 : 7"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "FormField",
      "parent": 141,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "FormField",
      "parent": 141,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "props.showRecon? 6 : 8"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "FormField",
      "parent": 141,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "props.showRecon? 4 :  2"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "FormField",
      "parent": 141,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "FormField",
      "parent": 141,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 143,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"width\": \"100%\", \"height\": \"100%\"}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "Dropdown",
      "parent": 150,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDivision"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" , height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.orderDiv"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 142,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนกที่สั่ง"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 144,
      "props": {
        "children": {
          "type": "value",
          "value": "Note"
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\": \"14/17\", \"gridColumn\": \"2/3\", \"width\": \"100%\", \"height\": \"100%\"}"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"width\": \"100%\", \"height\": \"100%\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "Input",
      "parent": 154,
      "props": {
        "id": {
          "type": "value",
          "value": "txt-notetoPharmacy"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDoctorNote"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "type": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.doctorNote"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 146,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"width\": \"100%\", \"height\": \"100%\", display: \"flex\"}"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "Button",
      "parent": 156,
      "props": {
        "children": {
          "type": "value",
          "value": "REMED"
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.statusRequest ? false : true"
        },
        "id": {
          "type": "value",
          "value": "btn-remed"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickREMED"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"none\" }"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "Button",
      "parent": 156,
      "props": {
        "children": {
          "type": "value",
          "value": "Drug History"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.statusRequest ? false : true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickDrugHistory"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth: \"max-content\"}"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "div",
      "parent": 147,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"width\": \"100%\", \"height\": \"100%\"}"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "Button",
      "parent": 159,
      "props": {
        "children": {
          "type": "value",
          "value": "ชุดยา"
        },
        "disabled": {
          "type": "code",
          "value": "props.statusRequest ? false : true"
        },
        "id": {
          "type": "value",
          "value": "btn-medSet"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickOrderset"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 148,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"width\": \"100%\", \"height\": \"100%\"}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "Button",
      "parent": 161,
      "props": {
        "children": {
          "type": "value",
          "value": "CONFIRM ORDER"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.statusRequest ? false : true"
        },
        "id": {
          "type": "value",
          "value": "btn-confirmMedOrder"
        },
        "onClick": {
          "type": "code",
          "value": "props.confirmOrder"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", ...(props.disabledConfirmedOrderBtn && {display: \"none\"})}"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "Button",
      "parent": 156,
      "props": {
        "children": {
          "type": "value",
          "value": "RECONTINUE"
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "disabled": {
          "type": "code",
          "value": "props.statusRequest ? false : true"
        },
        "id": {
          "type": "value",
          "value": "btn-remed"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickRecontinueMed"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginRight: \"15px\", display: props.showRecon? \"\" : \"none\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "FormField",
      "parent": 140,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "(props.roleTypes || []).includes(\"DOCTOR\") ? 0 : 1"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "div",
      "parent": 164,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ผู้สั่ง"
        },
        "style": {
          "type": "code",
          "value": "{ display: (props.roleTypes || []).includes(\"DOCTOR\") ? \"none\" : \"\", minWidth: \"max-content\" }"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "FormField",
      "parent": 140,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "(props.roleTypes || []).includes(\"DOCTOR\") ? 0 : 7"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "div",
      "parent": 166,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorElement"
        },
        "style": {
          "type": "code",
          "value": "{\"width\": \"100%\", \"height\": \"100%\", display: (props.roleTypes || []).includes(\"DOCTOR\") ? \"none\" : \"\" }"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "div",
      "parent": 161,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirmOrder"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.selectedDrug?.original?.is_taper_dose ? \"\": \"none\", gridColumn: props.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE ?\"8/9\": \"7/8\", fontWeight: \"bold\" }"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 169,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonTaperSchedule"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "Button",
      "parent": 73,
      "props": {
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledPrintDrugOrderBtn"
        },
        "icon": {
          "type": "code",
          "value": "true"
        },
        "labelPosition": {
          "type": "value",
          "value": "left"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintDrugOrder"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "Icon",
      "parent": 171,
      "props": {
        "name": {
          "type": "value",
          "value": "print"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "label",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ใบสั่งยา"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "Button",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ใบ ยส."
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingStatus?.PRINT_NARCOTIC_FORM"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintNarcotic"
        },
        "style": {
          "type": "code",
          "value": "{display: props.isShowNarcoticPrint ? \"\" : \"none\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "Button",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์สั่งจ่ายวัตถุออกฤทธิ์"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingStatus?.PRINT_PSYCHOTROPIC_FORM"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintPsychotropic"
        },
        "style": {
          "type": "code",
          "value": "{display: props.isShowPsychotropicPrint? \"\" : \"none\"}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "FormGroup",
      "parent": 93,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: props.hideDrugDescription ? \"none\" : \"\"}"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "FormField",
      "parent": 177,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: 0, marginTop: \"-0.5rem\"}"
        },
        "width": {
          "type": "code",
          "value": "Object.values({...(props.hideDrugForm || {}),qty:false}).reduce((r,v)=>r+=(!!v ? 0 : 2),0)"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "label",
      "parent": 178,
      "props": {
        "children": {
          "type": "value",
          "value": "Description"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "Input",
      "parent": 178,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "txt-drugDescription"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDrugDescription"
        },
        "style": {
          "type": "code",
          "value": "{opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.drugDescription || \"\""
        }
      },
      "seq": 180,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugOrderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "orderType": "ONE_DOSE",
    "selectedDrug": {
    },
    "showSearchResult": true
  },
  "width": 85
}

*********************************************************************************** */
