import { useEffect, useCallback, useMemo, useState } from "react";

import CardTreatmentResultUX from "./CardTreatmentResultUX";
import { Input, Button, Modal } from "semantic-ui-react";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";
import { EmployeeToken, ErrorMessage, ModInfo } from "react-lib/apps/common";
import {
  formatTimeCurrent,
  dateToStringWithoutTimeBE,
} from "react-lib/utils/dateUtils";

type ModInfoType = {
  open: boolean;
  error?: any;
  title?: string;
  color?: string;
};

const CARD_TREATMENT_RESULT = "CardTreatmentResult";

const REGEX_TIME = /(\d+:\d+)/g;

const CardTreatmentResult = (props: any) => {
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [openModNote, setOpeModNote] = useState<boolean>(false);

  useEffect(() => {
    props.runSequence({ sequence: "TreatmentResult", restart: true });
  }, []);

  const TreatmentList = useMemo(() => {
    return (props.TreatmentResultList || [])?.map(
      (item: any, index: number) => ({
        ...item,
        order_detail: (
          <div
            dangerouslySetInnerHTML={{ __html: item.order_summary_detail }}
          />
        ),
        index: index,
      })
    );
  }, [props?.TreatmentResultList]);

  const selectedTreatment = useMemo(() => {
    return TreatmentList?.[selectedRow?.id ? selectedRow?.index : 0];
  }, [selectedRow, TreatmentList]);

  const formatDate = useCallback((datetime?: string) => {
    return !datetime
      ? dateToStringWithoutTimeBE(new Date())
      : datetime?.split(" [")[0];
  }, []);

  const formatTime = useCallback((datetime?: string) => {
    return !datetime
      ? formatTimeCurrent(new Date())
      : datetime?.match(REGEX_TIME)?.[0];
  }, []);

  const startDatetime = useMemo(() => {
    return {
      date: formatDate(selectedTreatment?.start),
      time: formatTime(selectedTreatment?.start),
    };
  }, [selectedTreatment, props.TreatmentResultList]);

  const endDatetime = useMemo(() => {
    return {
      date: formatDate(selectedTreatment?.finish),
      time: formatTime(selectedTreatment?.finish),
    };
  }, [selectedTreatment, props.TreatmentResultList]);

  useEffect(() => {
    if (!selectedRow) {
      setSelectedRow(selectedTreatment);
    }
  }, [props.TreatmentResultList, selectedRow, TreatmentList]);

  useEffect(() => {
    if (
      selectedTreatment &&
      !selectedTreatment?.start &&
      startDatetime.date &&
      startDatetime.time
    ) {
      props.setProp(
        `TreatmentResultList.${selectedTreatment.index}.start`,
        `${startDatetime.date} [${startDatetime.time}]`
      );
    }
  }, [selectedTreatment, startDatetime]);

  useEffect(() => {
    if (
      selectedTreatment &&
      !selectedTreatment?.finish &&
      endDatetime.date &&
      endDatetime.time
    ) {
      props.setProp(
        `TreatmentResultList.${selectedTreatment.index}.finish`,
        `${endDatetime.date} [${endDatetime.time}]`
      );
    }
  }, [selectedTreatment, endDatetime]);

  const handleRowProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id && rowInfo?.original?.id === selectedRow?.id
            ? "#cccccc"
            : "white",
      },
      onClick: () => {
        const coreProcedureSecondary = rowInfo.original?.core_procedure?.filter(
          (acc: any) => acc.type === "SECONDARY"
        );
        const coreProcedurePrincipal = rowInfo.original?.core_procedure?.filter(
          (acc: any) => acc.type === "PRIMARY"
        );

        setSelectedRow(rowInfo.original);
        props.setProp("TreatmentResultSequence", {
          ...props.TreatmentResultSequence,
          diagnosisListPrincipal: coreProcedurePrincipal,
          diagnosisListSecondary: coreProcedureSecondary,
        });
      },
    };
  };
  const onSearchTextChange = (key: number) => (e: any, v: any) => {
    props.setProp(`TreatmentResultSequence.index`, key);

    props.runSequence({
      sequence: "TreatmentResult",
      action: "searchPrincipal",
      name: v.name,
      value: v.value,
    });
  };

  const onSearchSecondaryChange = (key: number) => (e: any, v: any) => {
    props.setProp(`TreatmentResultSequence.index`, key);
    props.runSequence({
      sequence: "TreatmentResult",
      action: "searchSecondary",
      name: v.name,
      value: v.value,
    });
  };

  const diagnosisListPrincipal = useMemo(
    () =>
      (props?.TreatmentResultSequence?.diagnosisListPrincipal || [])?.map(
        (item: any, index: number) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <div style={{ marginLeft: "10px", marginRight: "5px" }}>
                Medical Term
              </div>
              <Input
                name="medterm"
                onChange={onSearchTextChange(index)}
                value={item.medical_description}
              ></Input>
              <div style={{ marginLeft: "10px", marginRight: "5px" }}>
                ICD9CM Term
              </div>
              <Input
                name="icdterm"
                onChange={onSearchTextChange(index)}
                value={item.icd9cm_term}
              ></Input>
              <div style={{ marginLeft: "10px", marginRight: "5px" }}>
                ICD9CM Code
              </div>
              <Input
                name="icdcode"
                onChange={onSearchTextChange(index)}
                value={item.icd9cm_code}
              ></Input>
              <Button
                size="mini"
                circular
                icon="minus"
                color="red"
                style={{ marginLeft: "10px", marginRight: "5px" }}
                onClick={() => {
                  props.runSequence({
                    sequence: "TreatmentResult",
                    action: "removeDiagnosis",
                    value: item,
                    index: index,
                  });
                }}
              />
            </div>
          );
        }
      ),
    [props?.TreatmentResultSequence?.diagnosisListPrincipal]
  );

  const diagnosisListSecondary = useMemo(
    () =>
      (props?.TreatmentResultSequence?.diagnosisListSecondary || [])?.map(
        (item: any, index: number) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <div style={{ marginLeft: "10px", marginRight: "5px" }}>
                Medical Term
              </div>
              <Input
                name="medterm"
                onChange={onSearchSecondaryChange(index)}
                value={item.medical_description}
              ></Input>
              <div style={{ marginLeft: "10px", marginRight: "5px" }}>
                ICD9CM Term
              </div>
              <Input
                name="icdterm"
                onChange={onSearchSecondaryChange(index)}
                value={item.icd9cm_term}
              ></Input>
              <div style={{ marginLeft: "10px", marginRight: "5px" }}>
                ICD9CM Code
              </div>
              <Input
                name="icdcode"
                onChange={onSearchSecondaryChange(index)}
                value={item.icd9cm_code}
              ></Input>
              <Button
                size="mini"
                circular
                icon="minus"
                color="red"
                style={{ marginLeft: "10px", marginRight: "5px" }}
                onClick={() => {
                  props.runSequence({
                    sequence: "TreatmentResult",
                    action: "removeDiagnosis",
                    value: item,
                    index: index,
                  });
                }}
              />
            </div>
          );
        }
      ),

    [props?.TreatmentResultSequence?.diagnosisListSecondary]
  );

  const handleChangeDate = (key: string) => (date: string, v: any) => {
    const [index, field] = key?.split(".");
    const reg = /^\d{2}\/\d{2}\/\d{4}/g;

    if (date.search(reg) === -1) {
      return;
    }

    if (field === "start") {
      handleChangeValue(`${index}.start`)(null, {
        value: selectedTreatment.start.replace(reg, date),
      });
    } else if (field === "finish") {
      handleChangeValue(`${index}.finish`)(null, {
        value: selectedTreatment.finish.replace(reg, date),
      });
    }
  };

  const handleChangeTime = (key: string) => (time: string) => {
    if (key === "start") {
      handleChangeValue(`${selectedTreatment.index}.start`)(null, {
        value: selectedTreatment.start.replace(REGEX_TIME, time),
      });
    } else if (key === "finish") {
      handleChangeValue(`${selectedTreatment.index}.finish`)(null, {
        value: selectedTreatment.finish.replace(REGEX_TIME, time),
      });
    }
  };

  const handleChangeValue = (key: string) => (e: any, v: any) => {
    props.setProp(`TreatmentResultList.${key}`, v.value);
  };

  const handleChangeNote = (key: string) => (e: any, v: any) => {
    props.setProp(`TreatmentResultSequence.${key}`, v.value);
  };

  const handleCloseModInfo = () => {
    props.setProp(`TreatmentResultSequence.isOpenError`, false);
  };

  const handleCloseMessage = () => {
    setOpeModNote(false);
    props.setProp(`successMessage.${CARD_TREATMENT_RESULT}`, null);
    props.setProp(`errorMessage.${CARD_TREATMENT_RESULT}`, null);
  };

  const handleCloseModNote = () => {
    setOpeModNote(false);
    props.setProp(`errorMessage.${CARD_TREATMENT_RESULT}`, null);
  };

  const handleCloseModInfoNote = () => {
    props.setProp(`TreatmentResultSequence.isOpenErrorNote`, false);
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "TreatmentResult",
      action: "Save",
      card: CARD_TREATMENT_RESULT,
      id: selectedRow?.id ? selectedRow?.index : 0,
      value: selectedRow,
      datetime: {
        startDate: startDatetime.date,
        startTime: startDatetime.time,
        endDate: endDatetime.date,
        endTime: endDatetime.time,
      },
    });
  };

  // console.log("TreatmentResult", props);

  return (
    <>
      <CardTreatmentResultUX
        diagnosisListPrincipal={diagnosisListPrincipal}
        diagnosisListSecondary={diagnosisListSecondary}
        isShowDiagnosisSecondary={diagnosisListSecondary?.length > 0}
        isShowDiagnosisPrincipal={diagnosisListPrincipal?.length > 0}
        dataTreatment={TreatmentList}
        rowProps={handleRowProps}
        treatmentRow={selectedTreatment}
        hideDescription={!selectedRow?.id}
        handleChangeValue={handleChangeValue}
        handleChangeDate={handleChangeDate}
        //Principal
        procedureItems={props?.procedureItems || []}
        showSearchResultPrincipal={
          props.TreatmentResultSequence?.showSearchResultPrincipal
        }
        rowPrincipalProps={(
          state: any,
          rowInfo: any,
          column: any,
          instance: any
        ) => {
          return {
            onClick: (e: any) => {
              e.preventDefault();
              e.stopPropagation();

              props.runSequence({
                sequence: "TreatmentResult",
                action: "selectPrincipal",
                card: CARD_TREATMENT_RESULT,
                value: rowInfo.original,
              });
            },
          };
        }}
        //Secondary=
        showSearchResultSecondary={
          props.TreatmentResultSequence?.showSearchResultSecondary
        }
        secondaryItems={props?.secondaryItems || []}
        rowSecondaryProps={(
          state: any,
          rowInfo: any,
          column: any,
          instance: any
        ) => {
          return {
            onClick: (e: any) => {
              e.preventDefault();
              e.stopPropagation();

              props.runSequence({
                sequence: "TreatmentResult",
                action: "selectSencondary",
                card: CARD_TREATMENT_RESULT,
                value: rowInfo.original,
              });
            },
          };
        }}
        ErrorMessage={
          <ErrorMessage error={props?.errorMessage?.[CARD_TREATMENT_RESULT]} />
        }
        OnCancel={() => setOpeModNote(true)}
        onSave={handleSave}
        showEdit={
          selectedRow?.id
            ? TreatmentList?.[selectedRow?.index]?.status === "PERFORMED"
            : TreatmentList?.[0]?.status === "PERFORMED"
        }
        code={
          <EmployeeToken
            placeholder="code"
            onEnterToken={(code) => {
              props.onEvent({
                message: "HandleGetTreatmentTokenization",
                params: { code, id: selectedRow?.id ? selectedRow?.index : 0 },
              });
            }}
            onClearToken={() =>
              props.setProp("treatmentToken", {
                ...props.treatmentToken,
                token: "",
                employeeName: "",
                loading: false,
                error: null,
              })
            }
            error={props?.treatmentToken?.error}
            loading={props?.treatmentToken?.loading}
            employeeName={props?.treatmentToken?.employeeName}
          />
        }
        StartTime={
          <TimeComboBox
            defaultValue={startDatetime.time || ""}
            onTextChange={handleChangeTime("start")}
          />
        }
        FinishTime={
          <TimeComboBox
            defaultValue={endDatetime.time || ""}
            onTextChange={handleChangeTime("finish")}
          />
        }
        startDate={startDatetime.date}
        finishDate={endDatetime.date}
        OnaddPrincipal={() => {
          props.runSequence({
            sequence: "TreatmentResult",
            action: "addPrincipal",
            card: CARD_TREATMENT_RESULT,
          });
        }}
        OnaddSecondary={() => {
          props.runSequence({
            sequence: "TreatmentResult",
            action: "addSecondary",
            card: CARD_TREATMENT_RESULT,
          });
        }}
      />

      <Modal open={openModNote} size="large" onClose={handleCloseModNote}>
        <div style={{ display: "flex", padding: "10px" }}>
          <ErrorMessage error={props?.errorMessage?.[CARD_TREATMENT_RESULT]} />
        </div>
        <div style={{ display: "flex", padding: "10px" }}>
          <label style={{ padding: "5px" }}> NOTE </label>
          <div style={{ flex: "1", marginLeft: "5px", marginRight: "5px" }}>
            <Input
              className={
                props.errorMessage?.[CARD_TREATMENT_RESULT] &&
                !props?.TreatmentResultSequence?.note
                  ? "error"
                  : ""
              }
              style={{ width: "100%" }}
              value={props?.TreatmentResultSequence?.note}
              onChange={handleChangeNote("note")}
            ></Input>
          </div>

          <Button
            color="green"
            onClick={() => {
              props.runSequence({
                sequence: "TreatmentResult",
                action: "Cancel",
                card: CARD_TREATMENT_RESULT,
                id: selectedRow?.id
                  ? TreatmentList?.[selectedRow?.index]?.id
                  : TreatmentList?.[0]?.id,
              });
            }}
          >
            ยืนยัน
          </Button>
        </div>
      </Modal>

      <ModInfo
        open={props?.TreatmentResultSequence?.isOpenError}
        titleColor="red"
        titleName="บันทึกไม่สำเร็จ"
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      ></ModInfo>

      <ModInfo
        open={!!props.successMessage?.[CARD_TREATMENT_RESULT]}
        titleColor="green"
        titleName="บันทึกสำเร็จ"
        onApprove={handleCloseMessage}
        onClose={handleCloseMessage}
      ></ModInfo>

      <ModInfo
        open={props?.TreatmentResultSequence?.isOpenErrorNote}
        titleColor={"red"}
        titleName="ยกเลิก PERFORM ไม่สำเร็จ"
        onApprove={handleCloseModInfoNote}
        onClose={handleCloseModInfoNote}
      ></ModInfo>
    </>
  );
};

export default CardTreatmentResult;
