import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown,
  Checkbox,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardNutritionAssessmentUX = (props: any) => {
    return(
      <div
        style={{padding:"5px"}}>
        <div
          style={{padding: "5px", fontSize: "1.2rem", fontWeight: "bold"}}>
          Nutrition Assessment
        </div>
        <Table
          data={props.data}
          headers={`Date,User,A1,A2,A3,A4,A5,${props.isShowPregnancy ? "B1,B2,B3,B4,Delete" : "Delete" }`}
          keys={`date,user_fullname,lose_weight_desc,malnutrition_desc,bmi_outrange_desc,critical_desc,result_desc,${props.isShowPregnancy  ?  "preg1_desc,preg2_desc,preg3_desc,result_preg_desc,delete"  : "delete" }`}
          minRows="3"
          showPagination={false}
          style={{height: "150px"}}>
        </Table>
        <div
          style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(26, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
          <div
            style={{ gridRow: "2/4", gridColumn: "2/21", }}>
            A1. ผู้ป่วยมีน้ำหนักตัวลดลงโดยไม่ได้ตั้งใจในช่วง 6 เดือนที่ผ่านมาหรือไม่
          </div>
          <div
            style={{ gridRow: "4/6", gridColumn: "2/21", }}>
            A2. ผู้ป่วยได้รับอาหารน้อยกว่าที่เคยได้ (เกินกว่า 7 วัน)
          </div>
          <div
            style={{ gridRow: "6/8", gridColumn: "2/21", }}>
            {"A3. BMI < 18.5 หรือ >= 25.0 Kg/m2"}
          </div>
          <div
            style={{ gridRow: "8/10", gridColumn: "2/21", }}>
            A4. ผู้ป่วยมีภาวะโรควิกฤตหรือกึ่งวิกฤตร่วมด้วยหรือไม่
          </div>
          <div
            style={{ gridRow: "10/12", gridColumn: "2/21", }}>
            {"A5. ผลการคัดกรอง (ถ้าตอบใช่ >= 2 หัวข้อขึ้นไปถือว่าปกติ)"}
          </div>
          <div
            style={{ gridRow: "13/15", gridColumn: "2/21", ...(!props.isShowPregnancy && {display: "none"}) }}>
            Nutrition Assessment (ใช้เฉพาะหญิงตั้งครรภ์)
          </div>
          <div
            style={{ gridRow: "15/17", gridColumn: "2/21",   ...(!props.isShowPregnancy && {display: "none"})  }}>
            {"B1. ไตรมาสแรก (อายุครรภ์ 0-12 สัปดาห์) น้ำหนักลด > 0.5 kg/week ร่วมกับอาเจียน,กินไม่ได้"}
          </div>
          <div
            style={{ gridRow: "17/19", gridColumn: "2/21",  ...(!props.isShowPregnancy && {display: "none"})  }}>
            B2. ไตรมาสที่ 2 ขึ้นไป (อายุครรภ์ 12 สัปดาห์ขึ้นไป) น้ำหนักเพิ่ม/ลด 1 kg/week
          </div>
          <div
            style={{ gridRow: "19/21", gridColumn: "2/21",  ...(!props.isShowPregnancy && {display: "none"})  }}>
            B3. ไตรมาสที่ 2 ขึ้นไป (อายุครรภ์ 12 สัปดาห์ขึ้นไป) น้ำหนักร่มกับอาการบวม/ความดันโลหิตสูง
          </div>
          <div
            style={{ gridRow: "21/23", gridColumn: "2/21",  ...(!props.isShowPregnancy && {display: "none"})  }}>
            B4. ผลการคัดกรอง (ใช้เฉพาะหญิงตั้งครรภ์)
          </div>
          <div
            style={{ gridRow: "2/23", gridColumn: "21/22", borderRight: "solid 1px rgb(200, 200, 200, 0.5)",   ...(!props.isShowPregnancy && {gridRow: "2/15"})  }}>
          </div>
          <div
            style={{ gridRow: "2/4", gridColumn: "23/25", }}>
            <Checkbox
              checked={props.lose_weight}
              onChange={props.changeNutrition("lose_weight", !props.lose_weight)}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            style={{ gridRow: "2/4", gridColumn: "25/27", }}>
            {props.lose_weight ? "YES" : "NO"}
          </div>
          <div
            style={{ gridRow: "4/6", gridColumn: "23/25", }}>
            <Checkbox
              checked={props.malnutrition}
              onChange={props.changeNutrition("malnutrition", !props.malnutrition)}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            style={{ gridRow: "4/6", gridColumn: "25/27", }}>
            {props.malnutrition ? "YES" : "NO"}
          </div>
          <div
            style={{ gridRow: "6/8", gridColumn: "23/25", }}>
            <Checkbox
              checked={props.bmi_outrange}
              onChange={props.changeNutrition("bmi_outrange", !props.bmi_outrange)}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            style={{ gridRow: "6/8", gridColumn: "25/27", }}>
            {props.bmi_outrange ? "YES" : "NO"}
          </div>
          <div
            style={{ gridRow: "8/10", gridColumn: "23/25", }}>
            <Checkbox
              checked={props.critical}
              onChange={props.changeNutrition("critical", !props.critical)}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            style={{ gridRow: "8/10", gridColumn: "25/27", }}>
            {props.critical ? "YES" : "NO"}
          </div>
          <div
            style={{ gridRow: "10/12", gridColumn: "23/33", }}>
            <Dropdown
              onChange={props.changeNutrition("result")}
              options={props.resultOptions}
              selection={true}
              style={{width: "100%"}}
              value={props.result}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "15/17", gridColumn: "23/25",  ...(!props.isShowPregnancy && {display: "none"}) }}>
            <Checkbox
              checked={props.preg1}
              onChange={props.changeNutrition("preg1", !props.preg1)}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            style={{ gridRow: "15/17", gridColumn: "25/27",  ...(!props.isShowPregnancy && {display: "none"})  }}>
            {props.preg1 ? "YES" : "NO"}
          </div>
          <div
            style={{ gridRow: "17/19", gridColumn: "23/25",  ...(!props.isShowPregnancy && {display: "none"}) }}>
            <Checkbox
              checked={props.preg2}
              onChange={props.changeNutrition("preg2", !props.preg2)}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            style={{ gridRow: "17/19", gridColumn: "25/27",  ...(!props.isShowPregnancy && {display: "none"})  }}>
            {props.preg2 ? "YES" : "NO"}
          </div>
          <div
            style={{ gridRow: "19/21", gridColumn: "23/25",   ...(!props.isShowPregnancy && {display: "none"}) }}>
            <Checkbox
              checked={props.preg3}
              onChange={props.changeNutrition("preg3", !props.preg3)}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            style={{ gridRow: "19/21", gridColumn: "25/27",   ...(!props.isShowPregnancy && {display: "none"}) }}>
            {props.preg3 ? "YES" : "NO"}
          </div>
          <div
            style={{ gridRow: "21/23", gridColumn: "23/33",  ...(!props.isShowPregnancy && {display: "none"})  }}>
            <Dropdown
              onChange={props.changeNutrition("result_preg")}
              options={props.resultPregOptions}
              selection={true}
              style={{width: "100%"}}
              value={props.result_preg}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "21/23", gridColumn: "41/49", border: "solid 1px rgb(200, 200, 200, 0.5)", ...(!props.isShowPregnancy && {gridRow: "13/15"})  }}>
            <Button
              color="blue"
              onClick={props.onReassess}
              style={{width: "100%"}}>
              Reassessment
            </Button>
          </div>
        </div>
      </div>
    )
}


export default CardNutritionAssessmentUX

export const screenPropsDefault = {}

/* Date Time : Wed Jul 26 2023 03:18:55 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding:\"5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(26, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "A1. ผู้ป่วยมีน้ำหนักตัวลดลงโดยไม่ได้ตั้งใจในช่วง 6 เดือนที่ผ่านมาหรือไม่"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"2/21\", }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "A2. ผู้ป่วยได้รับอาหารน้อยกว่าที่เคยได้ (เกินกว่า 7 วัน)"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/6\", gridColumn: \"2/21\", }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "\"A3. BMI < 18.5 หรือ >= 25.0 Kg/m2\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/8\", gridColumn: \"2/21\", }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "A4. ผู้ป่วยมีภาวะโรควิกฤตหรือกึ่งวิกฤตร่วมด้วยหรือไม่"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"2/21\", }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "\"A5. ผลการคัดกรอง (ถ้าตอบใช่ >= 2 หัวข้อขึ้นไปถือว่าปกติ)\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/12\", gridColumn: \"2/21\", }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Nutrition Assessment (ใช้เฉพาะหญิงตั้งครรภ์)"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/15\", gridColumn: \"2/21\", ...(!props.isShowPregnancy && {display: \"none\"}) }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "\"B1. ไตรมาสแรก (อายุครรภ์ 0-12 สัปดาห์) น้ำหนักลด > 0.5 kg/week ร่วมกับอาเจียน,กินไม่ได้\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"15/17\", gridColumn: \"2/21\",   ...(!props.isShowPregnancy && {display: \"none\"})  }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "B2. ไตรมาสที่ 2 ขึ้นไป (อายุครรภ์ 12 สัปดาห์ขึ้นไป) น้ำหนักเพิ่ม/ลด 1 kg/week"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"17/19\", gridColumn: \"2/21\",  ...(!props.isShowPregnancy && {display: \"none\"})  }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "B3. ไตรมาสที่ 2 ขึ้นไป (อายุครรภ์ 12 สัปดาห์ขึ้นไป) น้ำหนักร่มกับอาการบวม/ความดันโลหิตสูง"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"19/21\", gridColumn: \"2/21\",  ...(!props.isShowPregnancy && {display: \"none\"})  }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "B4. ผลการคัดกรอง (ใช้เฉพาะหญิงตั้งครรภ์)"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"21/23\", gridColumn: \"2/21\",  ...(!props.isShowPregnancy && {display: \"none\"})  }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/23\", gridColumn: \"21/22\", borderRight: \"solid 1px rgb(200, 200, 200, 0.5)\",   ...(!props.isShowPregnancy && {gridRow: \"2/15\"})  }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"23/25\", }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.lose_weight ? \"YES\" : \"NO\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"25/27\", }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/6\", gridColumn: \"23/25\", }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.malnutrition ? \"YES\" : \"NO\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/6\", gridColumn: \"25/27\", }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/8\", gridColumn: \"23/25\", }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.bmi_outrange ? \"YES\" : \"NO\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/8\", gridColumn: \"25/27\", }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"23/25\", }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.critical ? \"YES\" : \"NO\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"25/27\", }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/12\", gridColumn: \"23/33\", }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"15/17\", gridColumn: \"23/25\",  ...(!props.isShowPregnancy && {display: \"none\"}) }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.preg1 ? \"YES\" : \"NO\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"15/17\", gridColumn: \"25/27\",  ...(!props.isShowPregnancy && {display: \"none\"})  }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"17/19\", gridColumn: \"23/25\",  ...(!props.isShowPregnancy && {display: \"none\"}) }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.preg2 ? \"YES\" : \"NO\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"17/19\", gridColumn: \"25/27\",  ...(!props.isShowPregnancy && {display: \"none\"})  }"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"19/21\", gridColumn: \"23/25\",   ...(!props.isShowPregnancy && {display: \"none\"}) }"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.preg3 ? \"YES\" : \"NO\""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"19/21\", gridColumn: \"25/27\",   ...(!props.isShowPregnancy && {display: \"none\"}) }"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"21/23\", gridColumn: \"23/33\",  ...(!props.isShowPregnancy && {display: \"none\"})  }"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"21/23\", gridColumn: \"41/49\", border: \"solid 1px rgb(200, 200, 200, 0.5)\", ...(!props.isShowPregnancy && {gridRow: \"13/15\"})  }"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Dropdown",
      "parent": 27,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNutrition(\"result\")"
        },
        "options": {
          "type": "code",
          "value": "props.resultOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.result"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Dropdown",
      "parent": 34,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeNutrition(\"result_preg\")"
        },
        "options": {
          "type": "code",
          "value": "props.resultPregOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.result_preg"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Checkbox",
      "parent": 19,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.lose_weight"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNutrition(\"lose_weight\", !props.lose_weight)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Checkbox",
      "parent": 21,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.malnutrition"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNutrition(\"malnutrition\", !props.malnutrition)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Checkbox",
      "parent": 23,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.bmi_outrange"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNutrition(\"bmi_outrange\", !props.bmi_outrange)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Checkbox",
      "parent": 25,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.critical"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNutrition(\"critical\", !props.critical)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Checkbox",
      "parent": 28,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.preg1"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNutrition(\"preg1\", !props.preg1)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Checkbox",
      "parent": 30,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.preg2"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNutrition(\"preg2\", !props.preg2)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Checkbox",
      "parent": 32,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.preg3"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNutrition(\"preg3\", !props.preg3)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Button",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": "Reassessment"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onReassess"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "Nutrition Assessment"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\", fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 47,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "headers": {
          "type": "code",
          "value": "`Date,User,A1,A2,A3,A4,A5,${props.isShowPregnancy ? \"B1,B2,B3,B4,Delete\" : \"Delete\" }`"
        },
        "keys": {
          "type": "code",
          "value": "`date,user_fullname,lose_weight_desc,malnutrition_desc,bmi_outrange_desc,critical_desc,result_desc,${props.isShowPregnancy  ?  \"preg1_desc,preg2_desc,preg3_desc,result_preg_desc,delete\"  : \"delete\" }`"
        },
        "minRows": {
          "type": "value",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"150px\"}"
        }
      },
      "seq": 46,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardNutritionAssessmentUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
