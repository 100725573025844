import React, { CSSProperties } from "react";
import {
  Dimmer,
  Icon,
  Loader,
  SemanticCOLORS,
  SemanticICONS,
} from "semantic-ui-react";

// Types
type LoadingIconProps = {
  name: SemanticICONS;
  color?: SemanticCOLORS;
  loading?: boolean;
  onClick: () => any;
};

const LoadingIcon = (props: LoadingIconProps) => {
  return (
    <div style={{ position: "relative" }}>
      <Icon
        name={props.name}
        color={props.color ?? "blue"}
        like={true}
        style={{ cursor: "pointer" }}
        onClick={() => props.onClick?.()}
      />
      <Dimmer
        active={props.loading}
        size="mini"
        inverted
        style={{
          background: "transparent",
          margin: "-1px 0 0 -2px",
          padding: 0,
        }}
      >
        <Loader size="mini" inline={true}></Loader>
      </Dimmer>
    </div>
  );
};

export default React.memo(LoadingIcon);
