import React, { useEffect, useState } from "react";
import { Button, Dropdown, Input, Modal } from "semantic-ui-react";
// ui common
import { EmployeeToken } from "react-lib/apps/common";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";
// ui LAB
import CardHistoryCentralLab from "../LAB/CardHistoryCentralLab";
// ui ORM
import CardHoldingRoomUX from "./CardHoldingRoomUX";
import { PreOPButtonAction } from "./CardPreOPVisit";

const CARD_KEY: string = "holdingRoomData";
const FORM_CODE: string = "CardHoldingRoom";
const FORM_NAME: string = "Holding Room";
const FORM_VERSION: string = "0.1";

type CardHoldingRoomProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  PreOperationSequence?: any;
  HistoryCentralLabSequence?: any;
};

const CardHoldingRoomInitial: CardHoldingRoomProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  errorMessage: null,
  successMessage: null,
  buttonLoadCheck: null,

  // select OR
  selectedOrOrder: {},

  // options
  masterOptions: {},

  // seq
  runSequence: null,
  PreOperationSequence: {},
  HistoryCentralLabSequence: {},
};

const CardHoldingRoom: React.FC<CardHoldingRoomProps> = (props: any) => {
  const [openModHistoryCentralLab, setOpenModHistoryCentralLab] =
    useState<boolean>(false);

  useEffect(() => {
    props.runSequence({
      sequence: "PreOperation",
      action: "FETCH_FORM_DATA_LATEST",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formVersion: FORM_VERSION,
    });
  }, []);

  const handleShowLabData = () => {
    setOpenModHistoryCentralLab(true);
  };

  const handleChangeData = (_event: any, data: any) => {
    let value = "";
    if (data.type === "checkbox") {
      value = data.checked;
    } else {
      value = data.value;
    }

    props.runSequence({
      sequence: "PreOperation",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: data.name,
      value: value,
    });
  };

  const handleAddMedication = (key: string) => {
    let result = [...props.PreOperationSequence?.holdingRoomData?.data?.[key]];
    result.push({
      medicine_name: "",
      rount: "",
      time: "",
      code: "",
    });

    props.runSequence({
      sequence: "PreOperation",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: key,
      value: result,
    });
  };

  const handleRemoveMedication = (key: string, index: number) => {
    let result = [...props.PreOperationSequence?.holdingRoomData?.data?.[key]];
    result.splice(index, 1);

    if (result.length === 0) {
      result.push({
        medicine_name: "",
        rount: "",
        time: "",
        code: "",
      });
    }

    props.runSequence({
      sequence: "PreOperation",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: key,
      value: result,
    });
  };

  const handleChangeMedication = (key: string, index: number, data: any) => {
    let result = [...props.PreOperationSequence?.holdingRoomData?.data?.[key]];
    result[index][data.name] = data.value;

    props.runSequence({
      sequence: "PreOperation",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: key,
      value: result,
    });
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "SAVE",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleConfirm = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "CONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleUnconfirm = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "UNCONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  return (
    <>
      {(props.errorMessage?.[CARD_KEY] || props.successMessage?.[CARD_KEY]) && (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            props.setProp(`errorMessage.${CARD_KEY}`, null);
            props.setProp(`successMessage.${CARD_KEY}`, null);
          }}
          error={props.errorMessage?.[CARD_KEY]}
          success={props.successMessage?.[CARD_KEY]}
        />
      )}

      <CardHoldingRoomUX
        // function
        onChangeData={handleChangeData}
        onShowLabData={handleShowLabData}
        // options
        masterOptions={props.masterOptions}
        // seq
        PreOperationSequence={props.PreOperationSequence}
        // component
        divMedication={
          <>
            {props.PreOperationSequence?.holdingRoomData?.data?.medication_item?.map(
              (item: any, index: number) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      marginTop: "5px",
                      paddingLeft: "100px",
                    }}
                  >
                    <Dropdown
                      onChange={(_event: any, data: any) =>
                        handleChangeMedication("medication_item", index, data)
                      }
                      selection={true}
                      options={props.masterOptions?.orMedication || []}
                      name="medicine_name"
                      value={item?.medicine_name}
                    ></Dropdown>
                    <label style={{ margin: "5px 10px 5px 30px" }}>Rount</label>
                    <Dropdown
                      onChange={(_event: any, data: any) =>
                        handleChangeMedication("medication_item", index, data)
                      }
                      selection={true}
                      options={props.masterOptions?.route || []}
                      name="rount"
                      value={item?.rount}
                    ></Dropdown>
                    <label style={{ margin: "5px 10px 5px 30px" }}>Time</label>
                    <TimeComboBox
                      noMinWidth={true}
                      defaultValue={item?.time || ""}
                      onTextChange={(time: string) =>
                        handleChangeMedication("medication_item", index, {
                          name: "time",
                          value: time,
                        })
                      }
                    />
                    <label
                      style={{
                        margin: "5px 10px 5px 30px",
                        minWidth: "max-content",
                      }}
                    >
                      Given by
                    </label>
                    <EmployeeToken
                      onEnterToken={(code) => {
                        props.runSequence({
                          sequence: "PreOperation",
                          action: "USER_TOKENIZE",
                          cardKey: CARD_KEY,
                          code: code,
                          key: "medication_item",
                          index: index,
                        });
                      }}
                      onClearToken={() =>
                        handleChangeMedication("medication_item", index, {
                          name: "code",
                          value: "",
                        })
                      }
                      placeholder="Code"
                      employeeName={
                        item?.code
                          ? decodeURIComponent(atob(item?.code.split(".")[1]))
                          : ""
                      }
                    />
                    <Button
                      color="red"
                      icon="minus"
                      size="mini"
                      style={{ margin: "5px 10px 5px 30px" }}
                      onClick={() =>
                        handleRemoveMedication("medication_item", index)
                      }
                    ></Button>
                    <Button
                      color="green"
                      icon="plus"
                      size="mini"
                      style={{ margin: "5px 10px" }}
                      onClick={() => handleAddMedication("medication_item")}
                    ></Button>
                  </div>
                );
              }
            )}
          </>
        }
        divOtherMedication={
          <>
            {props.PreOperationSequence?.holdingRoomData?.data?.medication_other_item?.map(
              (item: any, index: number) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      marginTop: "5px",
                      paddingLeft: "100px",
                    }}
                  >
                    <Input
                      onChange={(_event: any, data: any) =>
                        handleChangeMedication(
                          "medication_other_item",
                          index,
                          data
                        )
                      }
                      name="medicine_name"
                      value={item?.medicine_name}
                    ></Input>
                    <label style={{ margin: "5px 10px 5px 30px" }}>Rount</label>
                    <Dropdown
                      onChange={(_event: any, data: any) =>
                        handleChangeMedication(
                          "medication_other_item",
                          index,
                          data
                        )
                      }
                      selection={true}
                      options={props.masterOptions?.route || []}
                      name="rount"
                      value={item?.rount}
                    ></Dropdown>
                    <label style={{ margin: "5px 10px 5px 30px" }}>Time</label>
                    <TimeComboBox
                      noMinWidth={true}
                      defaultValue={item?.time || ""}
                      onTextChange={(time: string) =>
                        handleChangeMedication("medication_other_item", index, {
                          name: "time",
                          value: time,
                        })
                      }
                    />
                    <label style={{ margin: "5px 10px 5px 30px" }}>
                      Given by
                    </label>
                    <EmployeeToken
                      onEnterToken={(code) => {
                        props.runSequence({
                          sequence: "PreOperation",
                          action: "USER_TOKENIZE",
                          cardKey: CARD_KEY,
                          code: code,
                          key: "medication_other_item",
                          index: index,
                        });
                      }}
                      onClearToken={() =>
                        handleChangeMedication("medication_other_item", index, {
                          name: "code",
                          value: "",
                        })
                      }
                      placeholder="Code"
                      employeeName={
                        item?.code
                          ? decodeURIComponent(atob(item?.code.split(".")[1]))
                          : ""
                      }
                    />
                    <Button
                      color="red"
                      icon="minus"
                      size="mini"
                      style={{ margin: "5px 10px 5px 30px" }}
                      onClick={() =>
                        handleRemoveMedication("medication_other_item", index)
                      }
                    ></Button>
                    <Button
                      color="green"
                      icon="plus"
                      size="mini"
                      style={{ margin: "5px 10px" }}
                      onClick={() =>
                        handleAddMedication("medication_other_item")
                      }
                    ></Button>
                  </div>
                );
              }
            )}
          </>
        }
        buttonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PreOperationSequence}
            type="save"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave}
          />
        }
        buttonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PreOperationSequence}
            type="confirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleConfirm}
          />
        }
        buttonUnconfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PreOperationSequence}
            type="unconfirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleUnconfirm}
          />
        }
      />

      {openModHistoryCentralLab && (
        <Modal
          size="large"
          closeIcon
          open={openModHistoryCentralLab}
          onClose={() => setOpenModHistoryCentralLab(false)}
        >
          <CardHistoryCentralLab
            // function
            setProp={props.setProp}
            // seq
            runSequence={props.runSequence}
            HistoryCentralLabSequence={props.HistoryCentralLabSequence}
            // data
            buttonLoadCheck={props.buttonLoadCheck}
            patientId={props.selectedOrOrder?.patient_id}
          />
        </Modal>
      )}
    </>
  );
};

CardHoldingRoom.defaultProps = CardHoldingRoomInitial;

export default React.memo(CardHoldingRoom);
