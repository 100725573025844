import WasmController from "../../../../frameworks/WasmController";
import moment from "moment";
import { formatDate } from "../../../../utils/dateUtils";
import getPdfMake from "react-lib/appcon/common/pdfMake";
// PDF
import FormORCaseList from "../FormORCaseList";
import FormORCaseStatList from "../FormORCaseStatList";
// APIs
import OperatingOrderList from "issara-sdk/apis/OperatingOrderList_apps_ORM";
import OperatingRoomList from "issara-sdk/apis/OperatingRoomList_apps_ORM";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  django?: any;

  // sequence
  ORCaseListSequence?: {
    sequenceIndex?: string | null;

    orFilter?: {
      checkedHn?: boolean;
      emr__encounter__patient__hn?: string;
      patientFullName?: string;
      checkedDate?: boolean;
      start_date?: string;
      end_date?: string;
      checkedDoctor?: boolean;
      chief_surgeon?: any | null;
      selectedDoctor?: any;
      checkedCase?: boolean;
      cases?: any[];
      checkedLocation?: boolean;
      operating_detail__operating_room__location_id?: any;
      checkedRoom?: boolean;
      operating_detail__operating_room__id?: any;
    };
    roomOptions?: any[];
    caseList?: any;
    statCaseList?: any;
  } | null;
};

export const StateInitial: State = {
  ORCaseListSequence: {
    sequenceIndex: null,

    orFilter: {
      checkedHn: false,
      emr__encounter__patient__hn: "",
      patientFullName: "",
      checkedDate: true,
      start_date: formatDate(moment()),
      end_date: formatDate(moment()),
      checkedDoctor: false,
      chief_surgeon: null,
      selectedDoctor: null,
      checkedCase: false,
      cases: [],
      checkedLocation: true,
      operating_detail__operating_room__location_id: "ALL",
      checkedRoom: true,
      operating_detail__operating_room__id: "ALL",
    },
    roomOptions: [],
    caseList: null,
    statCaseList: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData" as any,
    params: {
      masters: [["orLocation", {}]],
    },
  });

  controller.setState(
    {
      ORCaseListSequence: { sequenceIndex: "Action" },
    },
    () => {
      Action(controller, { action: "CLEAR_FILTER" });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "FETCH") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params?.action}`]: "LOADING",
      },
    });

    const queryParams: any = setFetchQueryParam(controller);
    const [resp, error, network] = await OperatingOrderList.list({
      apiToken: controller.apiToken,
      params: queryParams,
    });

    if (error) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params?.action}`]: "ERROR",
        },
        ORCaseListSequence: {
          ...state.ORCaseListSequence,
          caseList: { items: [], next: null, previous: null, total: 0 },
        },
      });
    } else {
      const items: any[] = resp?.items?.map((item: any) => {
        let caseText = [];
        if (item?.case?.is_ipd_case) {
          caseText.push("IPD");
        }
        if (item?.case?.is_one_day_case) {
          caseText.push("ONE day");
        }
        if (item?.case?.is_opd_case) {
          caseText.push("OPD");
        }

        return {
          ...item,
          locationRoom: `${item?.location_name} / ${item?.operating_room_no}`,
          caseText: caseText?.join(", "),
        };
      });

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params?.action}`]: "SUCCESS",
        },
        ORCaseListSequence: {
          ...state.ORCaseListSequence,
          caseList: { ...resp, items: items },
        },
      });
    }
  } else if (params?.action === "FETCH_STAT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    const queryParams: any = setFetchQueryParam(controller);
    const [resp, error, network] = await OperatingOrderList.list({
      apiToken: controller.apiToken,
      params: queryParams,
    });
    if (error) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params?.action}`]: "ERROR",
        },
        ORCaseListSequence: {
          ...state.ORCaseListSequence,
          caseList: { items: [], next: null, previous: null, total: 0 },
        },
      });
    } else {
      const items: any[] = resp?.items?.map((item: any) => {
        let caseText = [];
        if (item?.case?.is_ipd_case) {
          caseText.push("IPD");
        }
        if (item?.case?.is_one_day_case) {
          caseText.push("ONE day");
        }
        if (item?.case?.is_opd_case) {
          caseText.push("OPD");
        }

        let ageRangeText = "";
        if (
          0 <= parseInt(item?.patient_age) &&
          parseInt(item?.patient_age) <= 30
        ) {
          ageRangeText = "0-30";
        } else if (
          31 <= parseInt(item?.patient_age) &&
          parseInt(item?.patient_age) <= 60
        ) {
          ageRangeText = "31-60";
        } else if (61 <= parseInt(item?.patient_age)) {
          ageRangeText = "> 60";
        }

        return {
          ...item,
          locationRoom: `${item?.location_name} / ${item?.operating_room_no}`,
          caseText: caseText?.join(", "),
          ageRangeText: ageRangeText,
        };
      });

      let LOCATION_ROOM = "locationRoom";
      let OR_CASE = "caseText";
      let ANES_METHOD = "anesthesia_method_name";
      let PATIENT_AGE = "ageRangeText";
      let PATIENT_GENDER = "gender";

      const arrayString = (items || [])?.map(
        (item: any) =>
          `${LOCATION_ROOM}${item.locationRoom}${OR_CASE}${item.caseText}${ANES_METHOD}${item.anesthesia_method_name}${PATIENT_AGE}${item.ageRangeText}${PATIENT_GENDER}${item.gender}`
      );
      const arrayMap = arrayString.reduce(
        (a: any, k: any) => ((a[k] = (a[k] || 0) + 1), a),
        {}
      );

      const uniqueArrays = Array.from(Object.keys(arrayMap), (x) => {
        let caseTextDataIdx = x.indexOf("caseText");
        let anesthesia_method_name_dataIdx = x.indexOf(
          "anesthesia_method_name"
        );
        let ageRangeTextDataIdx = x.indexOf("ageRangeText");
        let genderDataIdx = x.indexOf("gender");

        let locationRoomData = x.substring(
          LOCATION_ROOM.length,
          caseTextDataIdx
        );
        let caseTextData = x.substring(
          caseTextDataIdx + OR_CASE.length,
          anesthesia_method_name_dataIdx
        );
        let anesthesia_method_name_data = x.substring(
          anesthesia_method_name_dataIdx + ANES_METHOD.length,
          ageRangeTextDataIdx
        );
        let ageRangeTextData = x.substring(
          ageRangeTextDataIdx + PATIENT_AGE.length,
          genderDataIdx
        );
        let genderData = x.substring(genderDataIdx + PATIENT_GENDER.length);

        return {
          locationRoom: locationRoomData,
          caseText: caseTextData,
          anesthesia_method_name: anesthesia_method_name_data,
          ageRangeText: ageRangeTextData,
          gender: genderData,
        };
      });
      const theCount = Object.values(arrayMap);
      const joinArray = uniqueArrays.map((item: any, index: number) => ({
        ...item,
        count: theCount[index],
      }));

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "SUCCESS",
        },
        ORCaseListSequence: {
          ...state.ORCaseListSequence,
          statCaseList: joinArray,
        },
      });
    }
  } else if (params?.action === "FILTER_LOCATION_AND_FETCH_ROOM_ITEM") {
    if (params.location === "ALL") {
      return;
    }
    const [roomResp, roomErr, roomNet] = await OperatingRoomList.list({
      apiToken: controller.apiToken,
      params: { location: params.location, limit: 100 },
    });
    controller.setState({
      ORCaseListSequence: {
        ...state.ORCaseListSequence,
        orFilter: {
          ...state.ORCaseListSequence?.orFilter,
          operating_detail__operating_room__location_id: params.location,
          operating_detail__operating_room__id: "ALL",
        },
        roomOptions: roomErr ? [] : mapOptions(roomResp.items, "id", "room_no"),
      },
    });
  } else if (params?.action === "SET_FILTER") {
    controller.setState({
      ORCaseListSequence: {
        ...state.ORCaseListSequence,
        orFilter: { ...state.ORCaseListSequence?.orFilter, ...params.data },
      },
    });
  } else if (params?.action === "CLEAR_FILTER") {
    controller.setState({
      ORCaseListSequence: {
        ...state.ORCaseListSequence,
        orFilter: {
          checkedHn: false,
          emr__encounter__patient__hn: "",
          patientFullName: "",
          checkedDate: true,
          start_date: formatDate(moment()),
          end_date: formatDate(moment()),
          checkedDoctor: false,
          chief_surgeon: null,
          selectedDoctor: null,
          checkedCase: false,
          cases: [],
          checkedLocation: true,
          operating_detail__operating_room__location_id: "ALL",
          checkedRoom: true,
          operating_detail__operating_room__id: "ALL",
        },
        roomOptions: [],
      },
    });
  } else if (params?.action === "PRINT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    const data = Object.assign({
      start_date: state.ORCaseListSequence?.orFilter?.start_date || " - ",
      end_date: state.ORCaseListSequence?.orFilter?.end_date || " - ",
      print_user: state.django?.user?.full_name || "",
      data: state.ORCaseListSequence?.caseList?.items || [],
    });

    let docDef: any = { content: [] };

    docDef = FormORCaseList(data);

    const pdfMake = (await getPdfMake()).createPdf(docDef);
    pdfMake.open();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "SUCCESS",
      },
      successMessage: "Print Success",
    });
    return;
  } else if (params?.action === "PRINT_STAT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    const data = Object.assign({
      start_date: state.ORCaseListSequence?.orFilter?.start_date || " - ",
      end_date: state.ORCaseListSequence?.orFilter?.end_date || " - ",
      print_user: state.django?.user?.full_name || "",
      data: state.ORCaseListSequence?.statCaseList || [],
    });

    let docDef: any = { content: [] };

    docDef = FormORCaseStatList(data);

    const pdfMake = (await getPdfMake()).createPdf(docDef);
    pdfMake.open();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "SUCCESS",
      },
      successMessage: "Print Success",
    });
    return;
  }
};

// utilities
const mapOptions = (list: any[], valueKey = "id", textKey = "name") => {
  return list?.map((item: any) => ({
    key: item.id,
    value: item[valueKey],
    text: item[textKey],
  }));
};

const setFetchQueryParam = (controller: any) => {
  const state = controller.getState();

  let queryParams: any = {
    status: 6,
    limit: 100,
    emr__encounter__patient__hn: state.ORCaseListSequence?.orFilter?.checkedHn
      ? state.ORCaseListSequence?.orFilter?.emr__encounter__patient__hn
      : "",
    start_date: state.ORCaseListSequence?.orFilter?.checkedDate
      ? state.ORCaseListSequence?.orFilter?.start_date
      : "",
    end_date: state.ORCaseListSequence?.orFilter?.checkedDate
      ? state.ORCaseListSequence?.orFilter?.end_date
      : "",
    chief_surgeon: state.ORCaseListSequence?.orFilter?.checkedDoctor
      ? state.ORCaseListSequence?.orFilter?.chief_surgeon
      : "",
    cases: state.ORCaseListSequence?.orFilter?.checkedCase
      ? state.ORCaseListSequence?.orFilter?.cases
      : [],
  };
  if (state.ORCaseListSequence?.orFilter?.checkedLocation) {
    queryParams.operating_detail__operating_room__location_id =
      state.ORCaseListSequence?.orFilter
        ?.operating_detail__operating_room__location_id === "ALL"
        ? ""
        : state.ORCaseListSequence?.orFilter
            ?.operating_detail__operating_room__location_id;
  }
  if (state.ORCaseListSequence?.orFilter?.checkedRoom) {
    queryParams.operating_detail__operating_room__id =
      state.ORCaseListSequence?.orFilter
        ?.operating_detail__operating_room__id === "ALL"
        ? ""
        : state.ORCaseListSequence?.orFilter
            ?.operating_detail__operating_room__id;
  }

  return queryParams;
};
