import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Input,
  Radio,
} from "semantic-ui-react";
import CardLayout from "react-lib/apps/common/CardLayout";
import CardFallRiskAssessmentUX from "./CardFallRiskAssessmentUX";
import CardPainAssessmentUX from "./CardPainAssessmentUX";
import ModSaveAssessment from "./ModSaveAssessment";

const CardDischargeReassessment = (props: any) => {
  const [educationNeed, setEducationNeed] = useState(false);
  const [barriers, setBarriers] = useState(false);
  const [barriers_detail, setBarriers_detail] = useState("");
  const [readiness, setReadiness] = useState(false);
  const [readiness_detail, setReadiness_detail] = useState("");
  const [education_wayData, setEducation_wayData] = useState();
  const [education_methodData, setEducation_methodData] = useState();
  const [educationData, setEducationData] = useState();
  const [education_evaluationData, setEducation_evaluationData] = useState();
  const [education_evaluation_detail, setEducation_evaluation_detail] =
    useState("");
  const [followUp, setFollowUp] = useState(false);
  const [followUp_detail, setFollowUp_detail] = useState("");
  const [newFallRiskData, setNewFallRiskData] = useState<any>({});
  const [newPainData, setNewPainData] = useState<any>({});
  const [openEdit, setOpenEdit] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [rowID, setRowID] = useState("");

  useEffect(() => {
    if (!Number.isInteger(props?.selectedEncounter?.patient_id)) return;
    props.runSequence({ sequence: "Assessment", restart: true });
  }, []);

  const removeRow = (form: string, rowIndex: number) => {
    const newData = (props.AssessmentSequence?.[form]?.data || []).filter(
      (item: any, index: number) => index !== rowIndex
    );
    props.setProp(`AssessmentSequence.${form}.data`, newData);
  };

  const reassess = (form: string, data: any) => () => {
    console.log(props.DJANGO.user);
    const newData = [
      ...(props.AssessmentSequence?.[form]?.data || []),
      {
        ...data,
        edit_user: props.DJANGO.user.id,
        user_fullname: props.DJANGO.user.full_name,
        date: moment().format("HH:mm DD/MM/YYYY"),
      },
    ];
    props.setProp(`AssessmentSequence.${form}.data`, newData);
  };

  // Fall risk assessment helpers
  const changeFallRisk = (key: string) => (e: any, v: any) => {
    let newData: { [key: string]: string } = Object.assign(
      {},
      { ...newFallRiskData }
    );
    newData[key] = v.value;
    setNewFallRiskData(newData);
  };

  const fallRiskOptions = [
    { key: "LOW", value: "LOW", text: "Low Risk" },
    { key: "HIG", value: "HIG", text: "High Risk" },
  ];
  const fallRiskNursingOptions = [
    { key: "NON", value: "ไม่จำเป็น", text: "ไม่จำเป็น" },
    {
      key: "RSK",
      value:
        "ปฏิบัติตามการให้การพยาบาลผู้ป่วยที่ประเมินแล้วมีความเสี่ยงต่อการพลัดตกหกล้ม",
      text: "ปฏิบัติตามการให้การพยาบาลผู้ป่วยที่ประเมินแล้วมีความเสี่ยงต่อการพลัดตกหกล้ม",
    },
    {
      key: "TRS",
      value: "จัดให้ผู้ป่วยนั่งรถนั่งหรือนอนเตียง Transfer",
      text: "จัดให้ผู้ป่วยนั่งรถนั่งหรือนอนเตียง Transfer",
    },
    {
      key: "ORA",
      value: "ติดสัญลักษณ์แสดงผู้ป่วยที่เสี่ยงต่อการพลัดตกหกล้ม",
      text: "ติดสัญลักษณ์แสดงผู้ป่วยที่เสี่ยงต่อการพลัดตกหกล้ม",
    },
    {
      key: "SPL",
      value: "แจ้งผู้ป่วยและญาติระวังพลัดตกหกล้ม",
      text: "แจ้งผู้ป่วยและญาติระวังพลัดตกหกล้ม",
    },
    {
      key: "LIM",
      value: "จัดให้ผู้ป่วยอยู่ในบริเวณที่กำหนด",
      text: "จัดให้ผู้ป่วยอยู่ในบริเวณที่กำหนด",
    },
    {
      key: "STD",
      value: "Standard Fall Precaution",
      text: "Standard Fall Precaution",
    },
    { key: "OTH", value: "อื่นๆ", text: "อื่นๆ" },
  ];

  const fallRiskAssessment = (
    props.AssessmentSequence?.fallRiskAssessment?.data || []
  ).map((item: any, index: number) => ({
    ...item,
    fall_risk_description: fallRiskOptions.find(
      (row: any) => row.value === item?.fall_risk
    )?.text,
    delete: (
      <div style={{ width: "100%", textAlign: "right" }}>
        <Button
          color="red"
          onClick={(e: any) => removeRow("fallRiskAssessment", index)}
        >
          Delete
        </Button>
      </div>
    ),
  }));

  // Pain assessment helpers
  const changePain = (key: string) => (e: any, v: any) => {
    let newData: { [key: string]: string } = Object.assign(
      {},
      { ...newPainData }
    );
    newData[key] = v.value;
    setNewPainData(newData);
  };
  const painScoreOptions = [
    { key: "0", value: "0", text: "0" },
    { key: "1", value: "1", text: "1" },
    { key: "2", value: "2", text: "2" },
    { key: "3", value: "3", text: "3" },
    { key: "4", value: "4", text: "4" },
    { key: "5", value: "5", text: "5" },
    { key: "6", value: "6", text: "6" },
    { key: "7", value: "7", text: "7" },
    { key: "8", value: "8", text: "8" },
    { key: "9", value: "9", text: "9" },
    { key: "10", value: "10", text: "10" },
    { key: "NA", value: "NA", text: "ไม่สามารถประเมินได้" },
  ];

  const patientEducationOptions = (
    props.masterOptions?.patientEducation || []
  ).map((item: any) => ({
    ...item,
    key: item?.key.toString(),
    value: item?.value.toString(),
  }));
  const painAssessmentOptions = (props.masterOptions?.pain || []).map(
    (item: any) => ({
      ...item,
      key: item?.key.toString(),
      value: item?.value.toString(),
    })
  );

  const painLocationOptions = (props.masterOptions?.location || []).map(
    (item: any) => ({ ...item, key: item?.text, value: item?.text })
  );

  const painCharacteristicOptions = (
    props.masterOptions?.characteristic || []
  ).map((item: any) => ({ ...item, key: item?.text, value: item?.text }));
  const painFrequencyOptions = (props.masterOptions?.painFreq || []).map(
    (item: any) => ({
      ...item,
      key: item?.key.toString(),
      value: item?.value.toString(),
    })
  );

  const painNursingOptions = [
    { key: "NA", value: "NA", text: "NA" },
    { key: "NON", value: "NON", text: "ไม่จำเป็น" },
    { key: "REL", value: "REL", text: "จัดท่าเพื่อลดความเจ็บปวด" },
    { key: "HCP", value: "HCP", text: "วาง Hot/Cold pack" },
    { key: "REP", value: "REP", text: "รายงานแพทย์ pain score > 3" },
    { key: "YRX", value: "YRX", text: "มี Rx" },
    { key: "NRX", value: "NRX", text: "ไม่มี Rx" },
    { key: "Other", value: "Other", text: "อื่น ๆ" },
  ];

  const painAssessment = (
    props.AssessmentSequence?.painAssessment?.data || []
  ).map((item: any, index: number) => ({
    ...item,
    pain_score_desc: painScoreOptions.find(
      (row: any) => row.value === item?.pain_score
    )?.text,
    pain_assessment_desc: painAssessmentOptions.find(
      (row: any) => row.value.toString() === item?.pain_assessment_tool
    )?.text,
    frequency_desc: painFrequencyOptions.find(
      (row: any) => row.value.toString() === item?.frequency
    )?.text,
    nursing_desc: painNursingOptions.find(
      (row: any) => row.value.toString() === item?.nursing
    )?.text,
    delete: (
      <div style={{ width: "100%", textAlign: "right" }}>
        <Button
          color="red"
          onClick={(e: any) => removeRow("painAssessment", index)}
        >
          Delete
        </Button>
      </div>
    ),
  }));

  const education_way = [
    { id: "PT", value: "PT", text: "ผู้ป่วย" },
    { id: "REL", value: "REL", text: "ญาติสายตรง" },
    { id: "PAR", value: "PAR", text: "ผู้ดูแล" },
  ];

  const education_evaluation = [
    { key: "EXP", value: "EXP", text: "เข้าใจดี/ทำได้ดี" },
    { key: "EAM", value: "EAM", text: "ยังมีข้อสงสัย/ทำได้บางส่วน ทบทวน" },
    {
      key: "VDO",
      value: "VDO",
      text: "ทำซ้ำในส่วนที่ยังไม่เข้าใจ หรือทำได้ไม่ดี",
    },
    { key: "LLT", value: "LLT", text: "ไม่เข้าใจ/ทำไม่ได้ต้องสอนใหม่" },
  ];

  const education = [
    { id: "DRD", value: "DRD", text: "ตามแผนการรักษาของแพทย์" },
    { id: "MED", value: "MED", text: "การรับประทานยา/อาการข้างเคียง" },
    { id: "FOD", value: "FOD", text: "อาหาร/นม" },
    { id: "PIN", value: "PIN", text: "การจัดการความปวด" },
    { id: "CRE", value: "CRE", text: "วิธีการดูแลตนเอง" },
    { id: "SCR", value: "SCR", text: "การดูแลพิเศษ" },
    { id: "PRT", value: "PRT", text: "การป้องกัน การส่งเสริม การฟื้นฟูสภาพ" },
    { id: "EXE", value: "EXE", text: "การออกกำลังกาย" },
    { id: "LIM", value: "LIM", text: "ข้อจำกัด" },
    { id: "TOL", value: "TOL", text: "การใช้อุปกรณ์ช่วย" },
    { id: "SYN", value: "SYN", text: "อาการสำคัญที่ควรมาพบแพทย์ก่อนนัด" },
    { id: "MET", value: "MET", text: "การมาตรวจตามนัด" },
  ];

  const headerSaveReassessment = () => {
    let reassessmentData = {};
    reassessmentData = {
      barriers: barriers || false,
      barriers_detail: barriers_detail || "",
      readiness: readiness || false,
      readiness_detail: readiness_detail || "",
      education_way: education_wayData || "",
      education_method: education_methodData || "",
      education: educationData || "",
      education_evaluation: education_evaluationData || "",
      education_evaluation_detail: education_evaluation_detail || "",
      follow_up: followUp || false,
      follow_up_detail: followUp_detail || "",
      user_fullname: props.patientInfo?.doctor_name || "",
      education_need: educationNeed || false,
    };
    console.log("reassessmentData", reassessmentData);
    props.setProp(
      `AssessmentSequence.reassessmentAssessment.data`,
      reassessmentData
    );
  };

  const reassessUpdate = (form: string, data: any, index: any) => () => {
    const painDate = props.AssessmentSequence?.[form]?.data;
    const newData: any[] = [];
    painDate.map((item: any, num: any) => {
      if (num === index) {
        newData.push({
          ...data,
          edit_user: props.DJANGO.user.id,
          user_fullname: props.DJANGO.user.full_name,
          date: moment().format("HH:mm DD/MM/YYYY"),
        });
      } else {
        newData.push(item);
      }
    });
    props.setProp(`AssessmentSequence.${form}.data`, newData);
    setNewPainData({});
    setOpenEdit(false);
    setRowID("");
  };

  const handleSave = (params: any) => {
    headerSaveReassessment();

    props.runSequence(params);

    setOpenSave(false);
  };

  return (
    <>
      <CardLayout
        titleText={"การประเมินออกจากห้องตรวจ"}
        toggleable={true}
        closeable={true}
        onClose={props.onClose}
      >
        <div
          style={{
            paddingBottom: `15px`,
            display: `flex`,
            justifyContent: `flex-end`,
          }}
        >
          <Button
            color="green"
            onClick={() => {
              setOpenSave(true);
            }}
          >
            Save
          </Button>
        </div>

        <ModSaveAssessment
          // data
          open={openSave}
          // config
          isSaveReassessment={true}
          // callback
          onSave={handleSave}
          onClose={() => setOpenSave(false)}
        />

        <CardLayout
          titleText={"ประเมินผู้ป่วยหลังออกจากห้องตรวจ (OPD Reassessment)"}
          toggleable={true}
          closeable={false}
          dividerStyle={{ marginBottom: `0px` }}
        >
          <Form>
            <Form.Field inline={true}>
              <label>Patient and Family Education Need</label>
              <Radio
                toggle
                onChange={() => setEducationNeed(!educationNeed)}
                checked={educationNeed}
              />
            </Form.Field>
            <Divider />

            {educationNeed ? (
              <Form>
                <Form.Group inline>
                  <Form.Field
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    width={4}
                  >
                    <label>ผู้บันทึก/แก้ไข</label>
                  </Form.Field>
                  <Form.Field width={12}>
                    <Button disabled>{props.patientInfo?.doctor_name}</Button>
                  </Form.Field>
                </Form.Group>

                <Form.Group inline>
                  <Form.Field
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    width={4}
                  >
                    <label>ข้อจำกัดในการรับรู้</label>
                  </Form.Field>
                  <Form.Field width={5}>
                    <Radio
                      toggle
                      onChange={() => setBarriers(!barriers)}
                      checked={barriers}
                    />
                    <label>{barriers ? "Yes" : "No"}</label>
                  </Form.Field>
                  <Form.Field
                    control={Input}
                    disabled={!barriers}
                    label="ระบุ"
                    onChange={(e: any) => setBarriers_detail(e.target.value)}
                    value={barriers_detail}
                    width={7}
                  />
                </Form.Group>

                <Form.Group inline>
                  <Form.Field
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    width={4}
                  >
                    <label>Readiness and Willingness</label>
                  </Form.Field>
                  <Form.Field inline={true} width={5}>
                    <Radio
                      toggle
                      onChange={() => setReadiness(!readiness)}
                      checked={readiness}
                    />
                    <label>{readiness ? "Yes" : "No"}</label>
                  </Form.Field>
                  <Form.Field
                    control={Input}
                    disabled={!readiness}
                    label="ระบุ"
                    onChange={(e: any) => setReadiness_detail(e.target.value)}
                    value={readiness_detail}
                    width={7}
                  />
                </Form.Group>

                <Form.Group inline>
                  <Form.Field
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    width={4}
                  >
                    <label>ผู้รับคำแนะนำ</label>
                  </Form.Field>
                  <Form.Field
                    control={Dropdown}
                    fluid
                    multiple
                    selection
                    width={12}
                    options={education_way}
                    onChange={(e: any) => setEducation_wayData(e.target.value)}
                    value={education_wayData}
                    style={{ width: `100%` }}
                  />
                </Form.Group>

                <Form.Group inline>
                  <Form.Field
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    width={4}
                  >
                    <label>วิธีการให้ความรู้</label>
                  </Form.Field>
                  <Form.Field
                    control={Dropdown}
                    fluid
                    multiple
                    selection
                    width={12}
                    options={patientEducationOptions}
                    onChange={(e: any) =>
                      setEducation_methodData(e.target.value)
                    }
                    value={education_methodData}
                    style={{ width: `100%` }}
                  />
                </Form.Group>

                <Form.Group inline>
                  <Form.Field
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    width={4}
                  >
                    <label>คำแนะนำที่ให้</label>
                  </Form.Field>
                  <Form.Field
                    control={Dropdown}
                    fluid
                    multiple
                    selection
                    width={12}
                    options={education}
                    onChange={(e: any) => setEducationData(e.target.value)}
                    value={educationData}
                    style={{ width: `100%` }}
                  />
                </Form.Group>

                <Form.Group inline>
                  <Form.Field
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    width={4}
                  >
                    <label>Education Evaluation</label>
                  </Form.Field>
                  <Form.Field
                    control={Dropdown}
                    fluid
                    selection
                    width={5}
                    options={education_evaluation}
                    onChange={(e: any) =>
                      setEducation_evaluationData(e.target.value)
                    }
                    value={education_evaluationData}
                    style={{ width: `100%` }}
                  />
                  <Form.Field
                    control={Input}
                    label="ระบุ"
                    width={7}
                    onChange={(e: any) =>
                      setEducation_evaluation_detail(e.target.value)
                    }
                    value={education_evaluation_detail}
                  />
                </Form.Group>

                <Form.Group inline>
                  <Form.Field
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    width={4}
                  >
                    <label>Follow up</label>
                  </Form.Field>
                  <Form.Field inline={true} width={5}>
                    <Radio
                      toggle
                      onChange={() => setFollowUp(!followUp)}
                      checked={followUp}
                    />
                    <label>{followUp ? "Yes" : "No"}</label>
                  </Form.Field>
                  <Form.Field
                    control={Input}
                    disabled={!followUp}
                    onChange={(e: any) => setFollowUp_detail(e.target.value)}
                    value={followUp_detail}
                    label="ระบุ"
                    width={7}
                  />
                </Form.Group>
              </Form>
            ) : (
              ""
            )}
          </Form>
        </CardLayout>

        <CardLayout
          titleText={"Fall Risk Assessment"}
          toggleable={true}
          closeable={false}
          dimmerStyle={{ paddingTop: `15px` }}
          dividerStyle={{ marginBottom: `0px` }}
        >
          <CardFallRiskAssessmentUX
            // Fields
            data={fallRiskAssessment}
            fallRiskOptions={fallRiskOptions}
            fallRiskNursingOptions={fallRiskNursingOptions}
            fall_risk={newFallRiskData?.fall_risk || ""}
            nursing={newFallRiskData?.nursing || ""}
            // Methods
            changeFallRisk={changeFallRisk}
            onReassess={reassess("fallRiskAssessment", newFallRiskData)}
          />
        </CardLayout>

        <CardLayout
          titleText={"Pain Assessment"}
          toggleable={true}
          closeable={false}
          dimmerStyle={{ paddingTop: `15px` }}
          dividerStyle={{ marginBottom: `0px` }}
        >
          <CardPainAssessmentUX
            // Fields
            data={painAssessment}
            pain_score={newPainData?.pain_score || ""}
            painScoreOptions={painScoreOptions}
            pain_assessment_tool={newPainData?.pain_assessment_tool || ""}
            painAssessmentOptions={painAssessmentOptions}
            location={newPainData?.location || ""}
            painLocationOptions={painLocationOptions}
            characteristic={newPainData?.characteristic || ""}
            painCharacteristicOptions={painCharacteristicOptions}
            frequency={newPainData?.frequency || ""}
            painFrequencyOptions={painFrequencyOptions}
            duration_min={newPainData?.duration_min || ""}
            duration_sec={newPainData?.duration_sec || ""}
            nursing={newPainData?.nursing || ""}
            painNursingOptions={painNursingOptions}
            nursing_remark={newPainData?.nursing_remark || ""}
            // Methods
            changePain={changePain}
            onReassess={reassess("painAssessment", newPainData)}
            openEdit={
              openEdit
                ? { gridRow: "37/40", gridColumn: "36/43", marginRight: "10px" }
                : { display: "none" }
            }
            updatePain={reassessUpdate("painAssessment", newPainData, rowID)}
            openClear={
              openEdit
                ? { gridRow: "37/40", gridColumn: "43/50" }
                : { display: "none" }
            }
            clearPain={() => {
              setNewPainData({});
              setOpenEdit(false);
              setRowID("");
            }}
            rowProps={(
              state: any,
              rowInfo: any,
              column: any,
              instance: any
            ) => ({
              style: {
                backgroundColor:
                  props.NursingDiagnosisSequence?.nursingDiagnosisSelected &&
                  rowInfo?.original?.id &&
                  rowInfo?.original?.id ===
                    props.NursingDiagnosisSequence?.nursingDiagnosisSelected?.id
                    ? "#d6ecf3"
                    : "white",
              },
              onClick: async () => {
                console.log(
                  "rowInfo?.original",
                  rowInfo?.original,
                  rowInfo?.index
                );
                setRowID(rowInfo?.index);
                setOpenEdit(true);
                setNewPainData({
                  pain_score: rowInfo?.original?.pain_score,
                  pain_assessment_tool: rowInfo?.original?.pain_assessment_tool,
                  location: rowInfo?.original?.location,
                  characteristic: rowInfo?.original?.characteristic,
                  frequency: rowInfo?.original?.frequency,
                  duration_min: rowInfo?.original?.duration_min,
                  duration_sec: rowInfo?.original?.duration_sec,
                  nursing: rowInfo?.original?.nursing,
                  nursing_remark: rowInfo?.original?.nursing_remark,
                });
              },
            })}
          />
        </CardLayout>
      </CardLayout>
    </>
  );
};

export default CardDischargeReassessment;
