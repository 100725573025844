import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const ORCountTable = (props: any) => {
    return(
      <div
        style={{margin: "5px"}}>
        <div
          style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>
          
          <label
            style={{fontWeight: "bold", fontSize: "16px", marginLeft: "14px"}}>
            {props.title}
          </label>
          <div
            style={{display:"flex", width:"auto", marginLeft: "auto"}}>
            
            <Button
              color="yellow"
              onClick={props.addItem}
              size="small">
              Add
            </Button>
          </div>
        </div>
        <Table
          data={props.item?.counts_details}
          headers="Item,Previous,Add,Total,Sterlie,Non Sterlie,Count"
          keys="item.nameButton,total_previous_shift,add_times_text,total_all_shift,sterileCountInput,nonSterileCountInput,countsInShift"
          showPagination={false}
          style={{maxHeight: "15vh"}}
          widths="auto,200,200,200,200,200,200">
        </Table>
      </div>
    )
}

export default ORCountTable

export const screenPropsDefault = {}

/* Date Time : Tue Sep 06 2022 11:07:15 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{margin: \"5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.title"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"16px\", marginLeft: \"14px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", width:\"auto\", marginLeft: \"auto\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Button",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Add"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.addItem"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 5,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.item?.counts_details"
        },
        "headers": {
          "type": "value",
          "value": "Item,Previous,Add,Total,Sterlie,Non Sterlie,Count"
        },
        "keys": {
          "type": "value",
          "value": "item.nameButton,total_previous_shift,add_times_text,total_all_shift,sterileCountInput,nonSterileCountInput,countsInShift"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{maxHeight: \"15vh\"}"
        },
        "widths": {
          "type": "value",
          "value": "auto,200,200,200,200,200,200"
        }
      },
      "seq": 5,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "ORCountTable",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
