import WasmController from "react-lib/frameworks/WasmController";
import * as AppInitialize from "./sequence/AppInitialize";
import * as SearchBoxI from "react-lib/appcon/common/SearchBoxI";
import * as ZoneLogI from "./sequence/ZoneLog";
import * as RejectOrderI from "./sequence/RejectOrder";
import MasterMap from "./MasterMap";
import Cookies from "js-cookie";
import CONFIG from "config/config";

export type State = {
  masterOptions?: any;
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any; // {cardName: LOADING || SUCCESS || ERROR}
} & AppInitialize.State &
  ZoneLogI.State &
  SearchBoxI.State &
  RejectOrderI.State;

export const StateInitial: State = {
  masterOptions: {},
  errorMessage: {},
  successMessage: {},
  buttonLoadCheck: {}, // {cardName: LOADING || SUCCESS || ERROR}
  ...AppInitialize.StateInitial,
  ...ZoneLogI.StateInitial,
  ...SearchBoxI.StateInitial,
  ...RejectOrderI.StateInitial,
};

export type Event =
  | { message: "GetMasterData"; params: { by: string } }
  | { message: "Logout"; params: {} }
  | AppInitialize.Event
  | ZoneLogI.Event
  | SearchBoxI.Event
  | RejectOrderI.Event;

export type Data = {
  division?: number;
  device?: number;
  masterData?: { [name: string]: any };
} & AppInitialize.Data &
  ZoneLogI.Data &
  SearchBoxI.Data &
  RejectOrderI.Data;

export const DataInitial = {
  masterData: {},
  ...AppInitialize.DataInitial,
  ...ZoneLogI.DataInitial,
  ...SearchBoxI.DataInitial,
  ...RejectOrderI.DataInitial,
};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const GetMasterData: Handler = async (controller, params) => {
  const state = controller.getState();
  const masters = params?.masters || [];
  let masterData: { [name: string]: any } = {};
  let masterOptions: { [name: string]: any } = {};
  for (const master of masters) {
    if (master.length < 2) return;
    const masterMap = MasterMap?.[master[0]];
    if (!masterMap || masterMap === null) continue;
    if (Object.keys(state.masterOptions).includes(master[0])) continue;
    const result = await masterMap.api({
      params: {
        ...masterMap.params,
        ...master[1],
      },
      apiToken: controller.apiToken,
    });
    if (result[1]) continue;
    // console.log(result[0], result[0].items);
    masterData[master[0]] = result[0]?.items || [];
    masterOptions[master[0]] = (result[0] || []).items?.map((item: { [name: string]: any }) => ({
      key: item[masterMap.valueKey],
      value: item[masterMap.valueKey],
      text: item[masterMap.textKey],
      ...(masterMap?.relateKey ? { relate: item[masterMap.relateKey] } : {}),
    }));
  }
  controller.data.masterData = {
    ...controller.data.masterData,
    ...masterData,
  };
  controller.setState({
    masterOptions: {
      ...controller.getState().masterOptions,
      ...masterOptions,
    },
  });
};

export const Logout: Handler = async (controller, params) => {
  controller.apiToken = "";
  if (!CONFIG.OFFLINE) {
    controller.app.auth().signOut();
  }

  Cookies.set("apiToken", "");
  Cookies.set("division_id", "");
  Cookies.set("device_id", "");
  Cookies.set("user_id", "");
  Cookies.remove("filterDrugOrderQueue");

  controller.data.provider = 0;

  controller.setState({
    django: {},
    isDoctor: false,
    isNurse: false,
    providerInfo: null,
    providerEmployeeInfo: null,
    userRoleList: [],
    divisionOptions: [],
    selectedDivision: null,
    loggedin: false,
  });

  controller.handleEvent({ message: "RunSequence", params: { sequence: "AppInitialize" } });
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
export const base64toBlob = (data: string) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr(
    "data:application/pdf;base64,".length
  );
  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  const out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
};

/**
 *
 * @param key // * หากต้องการกำหนด key แยกจาก value
 */
export const mapOptions = (
  items: (Record<string, any> | string)[],
  valueKey: string = "id",
  textKey: string = "name",
  key?: string
) => {
  return items.map((value) => {
    const isString = typeof value === "string";
    return {
      key: isString ? value : key ? value[key] : value[valueKey],
      value: isString ? value : value[valueKey],
      text: isString ? value : value[textKey],
    };
  });
};