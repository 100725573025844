import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import Platform from "./Main/Platform";
import MainHIS from "./HIS/MainHIS";
import MainPatient from "./patient-lib/MainPatient";
import { BrowserRouter } from "react-router-dom";


// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
// import { CaptureConsole } from '@sentry/integrations';

// Raven.config('http://71dd760fb02a4f9f8154669d94baecc3@sentry.drjaysayhi.com/25').install()

// Sentry.init({
//     dsn: "https://71dd760fb02a4f9f8154669d94baecc3@sentry.drjaysayhi.com/25",
//     debug: true,
//     integrations: [new BrowserTracing()],
//     // integrations: [
// 	//     new CaptureConsole({
// 	//       levels: ['error']
// 	//     })
// 	//   ],

//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
//   });

const params = new URLSearchParams(window.location.search);
const app = params.get("app");

ReactDOM.render(
  <BrowserRouter>
    {app === "HIS" ? (
      <MainHIS />
    ) : app === "MOB" ? (
      <MainPatient />
    ) : (
      <Platform />
    )}
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
