import React, { useEffect, useState } from "react";
import SubAddressUX from "./SubAddressUX";
import { Button, Icon } from "semantic-ui-react";
import SnackMessage from "react-lib/apps/common/SnackMessage";

const CardAddress = (props: any) => {
  const [loadding, setLoadding] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [openSnackMessage, setOpenSnackMessage] = useState(false);
  
  console.log(props.masterOptions);
  console.log(props.patient);

  useEffect(() => {
    if (props.errorMessage?.SetPatient !== null && props.errorMessage?.SetPatient !== undefined) {
      setLoadding(false);
      setOpenSnackMessage(true);
      setTimeout(() => {
        setOpenSnackMessage(false);
        props.runSequence({
          sequence: "SetPatient",
          action: "clear",
        });
      }, 2000);
    }
    if (props.successMessage?.SetPatient !== null && props.successMessage?.SetPatient !== undefined) {
      setLoadding(false);
      setCompleted(true);
      setTimeout(() => {
        setCompleted(false);
      }, 2000);
      props.runSequence({
        sequence: "SetPatient",
        action: "clear",
      });
    }
  }, [props.errorMessage?.SetPatient, props.successMessage?.SetPatient]);
  
  const home_address = props.patient?.home_address || {};
  const present_address = props.patient?.present_address || {};
  const relative_address = props.patient?.relative_address || {};

  const changeAddress = 
    (address: string) => 
      (key: string) => 
        (e: any, v: any) => props.setProp(
          `SetPatientSequence.patient.${address}_address.${key}`, 
          v.value)

  return(
    <div style={{margin: "10px"}}>
      {openSnackMessage ? (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            setOpenSnackMessage(false);
            props.runSequence({
              sequence: "SetPatient",
              action: "clear",
            });
          }}
          error={props.errorMessage?.SetPatient}
          success={props.successMessage?.SetPatient}
        />
      ) : (
        ""
      )}
      <div>ที่อยู่ตามทะเบียนบ้าน</div>
      <SubAddress
        address={home_address}
        changeAddress={changeAddress("home")}
        masterOptions={props.masterOptions}
      />
      <hr/>
      <div>ที่อยู่ปัจจุบัน</div>
      <SubAddress
        address={present_address}
        changeAddress={changeAddress("present")}
        masterOptions={props.masterOptions}
      />
      <hr/>
      <div>ที่อยู่ผู้ติดต่อ</div>
      <SubAddress
        address={relative_address}
        changeAddress={changeAddress("relative")}
        masterOptions={props.masterOptions}
      />
      <div style={{display: "flex", justifyContent: "flex-end", paddingBottom: "10px"}}>
        <Button
          color="green"
          loading={loadding}
          onClick={(e: any) => {
            setLoadding(true)
            props.runSequence(
              {sequence: "SetPatient", action: "save"});
            }}>
          {completed ? <Icon name="check"></Icon> : "SAVE"}
        </Button>
      </div>
    </div>
  )
}

const SubAddress = (props: any) => {
  return(
    <SubAddressUX
      no={props.address?.no || ""}
      name={props.address?.name || ""}
      type={props.address?.type || ""}
      homeTypeOptions={props.masterOptions?.homeType}
      town={props.address?.town || ""}
      street={props.address?.street || ""}
      road={props.address?.road || ""}
      province={props.address?.province || ""}
      provinceOptions={props.masterOptions?.province || []}
      district={props.address?.district || ""}
      districtOptions={
        props.masterOptions?.district?.filter(
          (item: any) => item.relate === props.address?.province
        ) || []}
      city={props.address?.city || ""}
      cityOptions={
        props.masterOptions?.city?.filter(
          (item: any) => item.relate === props.address?.district
        ) || []}
      zipcode={props.address?.zipcode || ""}
      tel_home={props.address?.tel_home || ""}
      tel_home_suffix={props.address?.tel_home_suffix || ""}
      tel_office={props.address?.tel_office || ""}
      tel_office_suffix={props.address?.tel_office_suffix || ""}
      tel_mobile={props.address?.tel_mobile || ""}
      email={props.address?.email || ""}
      channel={parseInt(props.address?.channel || "0")}
      contactOptions={props.masterOptions?.contact || []}
      note={props.address?.note || ""}
      changeAddress={props.changeAddress}
    />
  )
}

export default CardAddress;