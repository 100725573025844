// DrugResuscitationOPD
import WasmController from "react-lib/frameworks/WasmController";
import DrugResuscitationBoxList from "issara-sdk/apis/DrugResuscitationBoxList_apps_TPD";
import DrugResuscitationOrderList from "issara-sdk/apis/DrugResuscitationOrderList_apps_TPD";
import DrugResuscitationOrderDetail from "issara-sdk/apis/DrugResuscitationOrderDetail_apps_TPD";
import DrugResuscitationBoxDetail from "issara-sdk/apis/DrugResuscitationBoxDetail_apps_TPD";
import DrugResuscitationBoxItemList from "issara-sdk/apis/DrugResuscitationBoxItemList_apps_TPD";
import DrugResuscitationBoxItemActionLogList from "issara-sdk/apis/DrugResuscitationBoxItemActionLogList_apps_TPD";
import DrugResuscitationBoxActionLogList from "issara-sdk/apis/DrugResuscitationBoxActionLogList_apps_TPD";
import DrugList from "issara-sdk/apis/DrugList_apps_TPD";
import DrugDetail from "issara-sdk/apis/DrugDetail_apps_TPD";
import DrugOrderEstimateView from "issara-sdk/apis/DrugOrderEstimateView_apps_TPD";
import { beToAd } from "react-lib/utils/dateUtils";
import moment from "moment";

export type State = {
  // CommonInterface
  django?: any;
  selectedEncounter?: any;
  selectedDivision?: any;
  selectedEmr?: any | null;
  successMessage?: any;
  errorMessage?: any;
  buttonLoadCheck?: any; // {cardName: LOADING || SUCCESS || ERROR}
  loadingStatus?: any;
  masterOptions?: any;
  providerEmployeeInfo?: any;
  // seq
  DrugResuscitationSequenceOPD?: {
    sequenceIndex?: string | null;
    break_code?: string | null;
    container_code?: string | null;
    container_name?: string | null;
    boxItems?: any[];
    listItems?: any[];
    selectedBox?: any;
    drugList?: any[];
    drugUnit?: any;
    drugItems?: any[];
    drugAdd?: any[];
    drugOrder?: any[];
    drugSaveOrder?: any[];
    destinationDivision?: number | null;
    boxLogs?: any;
    drugLogs?: any;
    selectedCode?: string | null;
    encounter?: number | null;
    allList?: any[];
    estimate?: any;
    drugEdit?: any;
    showDivisionError?: string;
    codeDrugsBox?: number;
  } | null;
};

export const StateInitial: State = {
  DrugResuscitationSequenceOPD: {
    break_code: "",
    container_code: "",
    container_name: "",
    boxItems: [],
    listItems: [],
    selectedBox: {},
    drugList: [],
    drugUnit: {},
    drugItems: [],
    drugAdd: [],
    drugOrder: [],
    drugSaveOrder: [],
    boxLogs: {},
    drugLogs: {},
    selectedCode: "",
    allList: [],
    drugEdit: {},
    showDivisionError: "",
    codeDrugsBox: 0,
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const start: Handler = async (controller, params) => {
  const state = controller.getState();
  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["unit", {}],
        ["route", {}],
        ["site", {}],
        // ["frequency", {}],
        // ["method", {}],
        // ["duplicateReasons", {}],
        // ["conversion", {}],
        // ["standardAdminTime", {}],
        // ["shippingEmployee", {}],
        // ["divisionForOrderDiv", {}]
      ],
    },
  });

  controller.setState(
    {
      DrugResuscitationSequenceOPD: {
        ...state.DrugResuscitationSequenceOPD,
        codeDrugsBox: 0,
        sequenceIndex: "Search",
      },
    },
    () => {
      SearchDrugResuscitationOPD(controller, params);
    }
  );
};

export const SearchDrugResuscitationOPD: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  if (params.action === "drugSearch") {
    const [response, error, network] = await DrugResuscitationBoxList.list({
      params: {
        code: state.DrugResuscitationSequenceOPD?.selectedCode,
        division: state.selectedDivision.id,
        encounter: state.selectedEncounter.id,
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (response) {
      console.log(response);
      if (response?.total <= 0) {
        controller.setState({
          DrugResuscitationSequenceOPD: {
            ...state.DrugResuscitationSequenceOPD,
            listItems: response.items || [],
            drugSaveOrder: response || [],
            drugAdd: [],
            showDivisionError:
              "เนื่องจากแผนกที่รับกล่องยา ไม่ตรงกับแผนกที่กำลังจะเปิดใช้\n กรุณาตรวจสอบแผนกที่รับกล่องยา",
          },
        });
      } else if (response?.items[0]?.drug_order === null) {
        controller.setState({
          DrugResuscitationSequenceOPD: {
            ...state.DrugResuscitationSequenceOPD,
            selectedBox: response?.items[0],
            listItems: response.items || [],
            drugSaveOrder: response || [],
            drugAdd: [],
          },
        });
      } else {
        let addedItem: any = [];
        let orderItems = response?.items[0]?.drug_order?.items;
        for (let i = 0; i < orderItems?.length; i++) {
          const [drugResp, drugErr, drugNet] = await DrugDetail.retrieve({
            apiToken: controller.apiToken,
            pk: orderItems?.[i]?.product,
          });

          let box = 0;
          response?.items[0].items.filter((item: any) => {
            if (item.drug === orderItems?.[i]?.product) {
              box = item.id;
            }
          });

          if (drugResp) {
            let drugAdd = {
              id: orderItems?.[i]?.id,
              box_item: box,
              dose: parseInt(orderItems?.[i]?.dose),
              unit: orderItems?.[i]?.unit,
              method: orderItems?.[i]?.method,
              route: orderItems?.[i]?.route,
              quantity: parseInt(orderItems?.[i]?.quantity),
              site: orderItems?.[i]?.site,
              time: moment(
                orderItems?.[i]?.admin_record_time,
                "HH:mm:ss"
              ).format("HH:mm"),
              nurse: orderItems?.[i]?.admin_record_nurse1_fullname,
              nurse1: state.django?.user?.token,
              drugDetail: {
                ...drugResp,
              },
            };
            addedItem.push(drugAdd);
          }
        }

        controller.setState({
          DrugResuscitationSequenceOPD: {
            ...state.DrugResuscitationSequenceOPD,
            selectedBox: response?.items[0],
            listItems: response.items || [],
            drugSaveOrder: response || [],
            drugAdd: addedItem,
          },
        });
      }
    }
  } else if (params?.action === "getOrderList") {
    const [orderListRes, orderListErr] = await DrugResuscitationOrderList.list({
      params: {
        encounter: state.selectedEncounter.id,
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (orderListRes) {
      const [response, error, network] = await DrugResuscitationBoxList.list({
        params: {
          code: orderListRes.items[0].code,
          division: state.selectedDivision.id,
          encounter: state.selectedEncounter.id,
        },
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });

      controller.setState({
        DrugResuscitationSequenceOPD: {
          ...state.DrugResuscitationSequenceOPD,
          listItems: response.items || [],
          drugSaveOrder: response || [],
          drugAdd: orderListRes.items[0].items,
        },
      });
    }
  } else if (params?.action === "saveQA") {
    // estimate
    const [estimateResp, estimateErr, estimateNet] =
      await DrugOrderEstimateView.post({
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
        data: {
          ...params.data,
          claim_payload:
            state.DrugResuscitationSequenceOPD?.estimate?.original
              ?.claim_payload,
          items: state.DrugResuscitationSequenceOPD?.estimate?.items,
        },
      });

    const addedItem =
      state.DrugResuscitationSequenceOPD?.listItems?.[0]?.items[
        state.DrugResuscitationSequenceOPD?.estimate?.index
      ];
    let items = state.DrugResuscitationSequenceOPD?.drugAdd || [];

    //DrugDetail
    const [drugResp, drugErr, drugNet] = await DrugDetail.retrieve({
      apiToken: controller.apiToken,
      pk: addedItem.drug,
    });

    const drug_question = {
      payloads: estimateResp?.claim_payload.payloads,
      selectedProduct: drugResp.id,
      items: [
        {
          ...drugResp,
          quantity: addedItem.quantity,
          dispense_quantity: 1,
          product: addedItem?.drug,
          dose: 0,
        },
      ],
      original: estimateResp,
      index: params.index,
      isAdd: true,
      drugResuscitation: true,
    };

    // check have_question
    if (estimateResp?.claim_payload?.have_question === false) {
      const drug_add = {
        id: null,
        box_item: addedItem.id,
        dose: 1,
        unit: addedItem.default_dosage_unit,
        method: addedItem.default_method,
        route: addedItem.default_route,
        quantity: 1,
        site: addedItem?.default_site,
        time: moment().format("HH:mm"),
        nurse: state.django?.user?.full_name,
        nurse1: state.django?.user?.token,
        drugDetail: {
          ...drugResp,
          estimate: estimateResp,
          question: drug_question,
        },
      };

      if (params.indexDrugAdd !== undefined) {
        let newDrugAdd: any = [];
        state.DrugResuscitationSequenceOPD?.drugAdd?.forEach(
          (item: any, index: any) => {
            if (index === params.indexDrugAdd) {
              newDrugAdd.push({
                ...item,
                drugDetail: {
                  ...item.drugDetail,
                  estimate: estimateResp,
                  question: drug_question,
                },
              });
            } else {
              newDrugAdd.push(item);
            }
          }
        );

        controller.setState({
          DrugResuscitationSequenceOPD: {
            ...state.DrugResuscitationSequenceOPD,
            drugAdd: [...(newDrugAdd || [])],
            estimate: {},
          },
        });
      } else {
        items.push(drug_add);

        controller.setState({
          DrugResuscitationSequenceOPD: {
            ...state.DrugResuscitationSequenceOPD,
            drugAdd: [...(items || [])],
            estimate: {},
          },
        });
      }
    } else {
      controller.setState({
        DrugResuscitationSequenceOPD: {
          ...state.DrugResuscitationSequenceOPD,
          estimate: drug_question,
        },
      });
    }
  } else if (params?.action === "addDrug") {
    const addedItem = params.additem;
    let items = state.DrugResuscitationSequenceOPD?.drugAdd || [];

    //DrugDetail
    const [drugResp, drugErr, drugNet] = await DrugDetail.retrieve({
      apiToken: controller.apiToken,
      pk: addedItem.drug,
    });

    //Estimate
    const [estimateResp, estimateErr, estimateNet] =
      await DrugOrderEstimateView.post({
        apiToken: controller.apiToken,
        data: {
          items: [
            {
              ...drugResp,
              quantity: addedItem?.total_quantity || 0,
              product: addedItem?.drug,
              dose: 0,
            },
          ],
          drug_type: "HOME_OPD",
          encounter: state.selectedEncounter.id,
          emr: state.selectedEmr.id,
          progression_cycle: "",
          claim_payload: {},
        },
        extra: {
          division: controller.data.division,
        },
      });

    const drug_question = {
      payloads: estimateResp?.claim_payload?.payloads,
      selectedProduct: drugResp?.id,
      items: [
        {
          ...drugResp,
          quantity: addedItem.quantity,
          dispense_quantity: 1,
          product: addedItem?.drug,
          dose: 0,
        },
      ],
      original: estimateResp,
      index: params.index,
      isAdd: params.isAdd,
      drugResuscitation: true,
    };

    const drug_add = {
      id: null,
      box_item: addedItem.id,
      dose: 1,
      unit: addedItem.default_dosage_unit,
      method: addedItem.default_method,
      route: addedItem.default_route,
      quantity: 1,
      site: addedItem?.default_site,
      time: moment().format("HH:mm"),
      nurse: state.django?.user?.full_name,
      nurse1: state.django?.user?.token,
      drugDetail: {
        ...drugResp,
        estimate: estimateResp,
        question: drug_question,
      },
    };

    if (estimateResp) {
      if (estimateResp.claim_payload?.have_question === true) {
        controller.setState({
          DrugResuscitationSequenceOPD: {
            ...state.DrugResuscitationSequenceOPD,
            estimate: drug_question,
          },
        });
      } else {
        items.push(drug_add);
        controller.setState({
          DrugResuscitationSequenceOPD: {
            ...state.DrugResuscitationSequenceOPD,
            drugAdd: [...(items || [])],
          },
        });
      }
    } else {
      if (estimateErr?.unit) {
        items.push({ ...drug_add, unit: estimateErr?.unit[0] });

        controller.setState({
          DrugResuscitationSequenceOPD: {
            ...state.DrugResuscitationSequenceOPD,
            drugAdd: [...(items || [])],
          },
        });
      }
    }
  } else if (params.action === "saveEdit") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    // check employeeCode
    if (
      state.DrugResuscitationSequenceOPD?.drugEdit?.employeeCode ===
      state.providerEmployeeInfo?.employee_info.full_name
    ) {
      let newDrugAdd: any = [];

      state.DrugResuscitationSequenceOPD?.drugAdd?.forEach(
        (item: any, index: any) => {
          if (index === params.index) {
            newDrugAdd.push(state.DrugResuscitationSequenceOPD?.drugEdit);
          } else {
            newDrugAdd.push(item);
          }
        }
      );

      // is show question when action saveEdit
      // //Estimate
      // const [estimateResp, estimateErr, estimateNet] =
      //   await DrugOrderEstimateView.post({
      //     apiToken: controller.apiToken,
      //     data: {
      //       items: [
      //         {
      //           ...newDrugAdd[0].drugDetail,
      //           unit: state.DrugResuscitationSequenceOPD?.drugEdit.unit,
      //           quantity:
      //             state.DrugResuscitationSequenceOPD?.drugEdit.quantity || 0,
      //           dose: state.DrugResuscitationSequenceOPD?.drugEdit.dose,
      //           product:
      //             state.DrugResuscitationSequenceOPD?.drugEdit?.drugDetail?.id,
      //         },
      //       ],
      //       drug_type: "HOME_OPD",
      //       encounter: state.selectedEncounter.id,
      //       emr: state.selectedEmr.id,
      //       progression_cycle: "",
      //       claim_payload: {},
      //     },
      //     extra: {
      //       division: controller.data.division,
      //     },
      //   });

      // if (estimateResp.claim_payload.have_question === false) {
      //   controller.setState({
      //     buttonLoadCheck: {
      //       ...state.buttonLoadCheck,
      //       [`${params.card}_${params.action}`]: "SUCCESS",
      //     },
      //     DrugResuscitationSequenceOPD: {
      //       ...state.DrugResuscitationSequenceOPD,
      //       drugAdd: newDrugAdd,
      //       drugEdit: {},
      //     },
      //   });
      // } else {
      //   const drug_question = {
      //     payloads: estimateResp?.claim_payload?.payloads,
      //     selectedProduct:
      //       state.DrugResuscitationSequenceOPD?.drugEdit?.drugDetail?.id,
      //     items: [
      //       {
      //         ...newDrugAdd[0].drugDetail,
      //         unit: state.DrugResuscitationSequenceOPD?.drugEdit.unit,
      //         quantity:
      //           state.DrugResuscitationSequenceOPD?.drugEdit.quantity || 0,
      //         dose: state.DrugResuscitationSequenceOPD?.drugEdit.dose,
      //         product:
      //           state.DrugResuscitationSequenceOPD?.drugEdit?.drugDetail?.id,
      //       },
      //     ],
      //     original: estimateResp,
      //     index: params.index,
      //     isAdd: params.isAdd,
      //     drugResuscitation: true,
      //   };

      //   controller.setState({
      //     buttonLoadCheck: {
      //       ...state.buttonLoadCheck,
      //       [`${params.card}_${params.action}`]: "SUCCESS",
      //     },
      //     DrugResuscitationSequenceOPD: {
      //       ...state.DrugResuscitationSequenceOPD,
      //       drugAdd: newDrugAdd,
      //       drugEdit: {},
      //       estimate: drug_question,
      //     },
      //   });
      // }

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
        DrugResuscitationSequenceOPD: {
          ...state.DrugResuscitationSequenceOPD,
          drugAdd: newDrugAdd,
          drugEdit: {},
        },
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params.action === "delete") {
    controller.setState({
      DrugResuscitationSequenceOPD: {
        ...state.DrugResuscitationSequenceOPD,
      },
    });
  } else if (params.action === "pick") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    // const [response, error] = await DrugResuscitationBoxDetail.update({
    //   pk: state.DrugResuscitationSequenceOPD?.selectedBox?.id,
    //   data: {
    //     action: "OPEN",
    //   },
    //   extra: { division: controller.data.division },
    //   apiToken: controller.apiToken,
    // });

    const [orderListRes, orderListErr] =
      await DrugResuscitationOrderList.create({
        data: {
          action: "PICK",
          encounter: state.selectedEncounter.id,
          items: state.DrugResuscitationSequenceOPD?.drugAdd,
        },
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });

    if (orderListRes) {
      const [response, error, network] = await DrugResuscitationBoxList.list({
        params: {
          code: state.DrugResuscitationSequenceOPD?.selectedCode,
          division: state.selectedDivision.id,
          encounter: state.selectedEncounter.id,
        },
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
      });
      SearchDrugResuscitationOPD(controller, { action: "drugSearch" });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params.action === "edit") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    // const [response, error] = await DrugResuscitationBoxDetail.update({
    //   pk: state.DrugResuscitationSequenceOPD?.selectedBox?.id,
    //   data: {
    //     action: "OPEN",
    //   },
    //   extra: { division: controller.data.division },
    //   apiToken: controller.apiToken,
    // });

    const [orderListRes, orderListErr] =
      await DrugResuscitationOrderDetail.update({
        pk: state.DrugResuscitationSequenceOPD?.drugSaveOrder?.items?.[0]
          ?.drug_order?.pk,
        data: {
          action: "EDIT",
          items: state.DrugResuscitationSequenceOPD?.drugAdd,
        },
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });

    if (orderListRes) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
      });
      SearchDrugResuscitationOPD(controller, { action: "drugSearch" });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params.action === "clear") {
    controller.setState({
      DrugResuscitationSequenceOPD: {
        codeDrugsBox: 0,
      },
    });
  }
};

export const refresh: Handler = async (controller, params) => {
  const state = controller.getState();
};
