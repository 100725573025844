import axios from 'axios'
import { to } from '../../../utils'
import BaseService from  '../../services/BaseService'
import config from '../../../../config/config'
import { Response, Error } from '../common'
const HOST = `${config.API_HOST}`

export interface DoctorSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    code?: any,
    pre_name?: any,
    pre_name_en?: any,
    first_name?: any,
    first_name_en?: any,
    middle_name?: any,
    middle_name_en?: any,
    last_name?: any,
    last_name_en?: any,
    certificate_no?: any,
    user?: any,
    type?: any,
    is_active?: any,
    image?: any,
    image_url?: any,
    common?: any,
    is_telemed?: any,
    name_code?: any,
    specialty?: any,
    full_name?: any,
}


/* ['generics.ListCreateAPIView', 'DGridSortableMixin'] */
/* params: first_name, middle_name, last_name, code, specialty, 'name_code', 'type' */
/* data:  */
export const DoctorList : 
{
  list:     (arg0:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  create:   (arg0:
             { 
                params?: any,
                data?: DoctorSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/doctor/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  create: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/apis/core/doctor/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

/* ['generics.RetrieveUpdateDestroyAPIView'] */
/* params:  */
/* data:  */
export const DoctorDetail : 
{
  retrieve: (arg0:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  update:   (arg0:
             { pk: any, 
                params?: any,
                data?: DoctorSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    (arg0:
             { pk: any, 
                params?: any,
                data?: DoctorSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/doctor/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  update: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${HOST}/apis/core/doctor/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${HOST}/apis/core/doctor/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface DoctorSerializerForLIS {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    code?: any,
    pre_name?: any,
    pre_name_en?: any,
    first_name?: any,
    first_name_en?: any,
    middle_name?: any,
    middle_name_en?: any,
    last_name?: any,
    last_name_en?: any,
    certificate_no?: any,
    user?: any,
    type?: any,
    is_active?: any,
    image?: any,
    image_url?: any,
    common?: any,
    is_telemed?: any,
    user_id?: any,
    title?: any,
}


/* ['generics.RetrieveAPIView'] */
/* params:  */
/* data:  */
export const DoctorDetailByUsername : 
{
  retrieve: (arg0:
             { username: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({username, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/get-doctor/${username}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface MAIMAIDoctorSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    code?: any,
    pre_name?: any,
    pre_name_en?: any,
    first_name?: any,
    first_name_en?: any,
    middle_name?: any,
    middle_name_en?: any,
    last_name?: any,
    last_name_en?: any,
    certificate_no?: any,
    user?: any,
    type?: any,
    is_active?: any,
    image?: any,
    image_url?: any,
    common?: any,
    is_telemed?: any,
    name_code?: any,
    specialty?: any,
    full_name?: any,
    specialties?: any,
    is_doctor?: any,
}


/* ['generics.ListAPIView', 'SetLanguageContextMixin'] */
/* params: 'patient' */
/* data:  */
export const MAIRecentDoctorList : 
{
  list:     (arg0:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/MAI/recent-doctor/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

/* ['DoctorList', 'SetLanguageContextMixin'] */
/* params: is_telemed, 'department' */
/* data:  */
export const MAIDoctorListView : 
{
} = 
{}
/*----------------------------------------------------------------------------------------------------*/

export interface MAIMAIDoctorDetailSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    code?: any,
    pre_name?: any,
    pre_name_en?: any,
    first_name?: any,
    first_name_en?: any,
    middle_name?: any,
    middle_name_en?: any,
    last_name?: any,
    last_name_en?: any,
    certificate_no?: any,
    user?: any,
    type?: any,
    is_active?: any,
    image?: any,
    image_url?: any,
    common?: any,
    is_telemed?: any,
    name_code?: any,
    specialty?: any,
    full_name?: any,
    specialties?: any,
    is_doctor?: any,
    education_list?: any,
    board_list?: any,
    certification_list?: any,
}


/* ['generics.ListAPIView', 'SetLanguageContextMixin'] */
/* params: 'provider_code', 'code', 'is_telemed' */
/* data:  */
export const MAIDoctorListSearch : 
{
  list:     (arg0:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/MAI/doctor-list/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

/* ['generics.RetrieveAPIView', 'SetLanguageContextMixin'] */
/* params:  */
/* data:  */
export const MAIDoctorDetail : 
{
  retrieve: (arg0:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/MAI/doctor-detail/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

