import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown,
  Input
} from 'semantic-ui-react'

const ANES_PreANES_01 = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", padding:"10px"}}>
        <div>
          
          <div
            style={{ gridRow: "1/2", gridColumn: "1/3",marginLeft:"10px",marginTop:"10px", justifyContent: "space-between", display: "flex", alingItem: "center", marginBottom: "10px"}}>
            <div
              style={{ display: "flex" ,fontWeight:"Bold",fontSize:"18px",marginBottom:"10px"}}>
              Pre Anesthetic form
            </div>
            <div
              style={{display: "flex" }}>
              
              <div>
                {props.buttonLog}
              </div>
              <div
                style={{ display: "none" }}>
                {props.buttonPrint}
              </div>
              <div
                style={{ display: props.PreAnestheticSequence?.status_label === "Confirm" ?  "none" : "",}}>
                {props.buttonSave}
              </div>
              <div
                style={{ display: props.PreAnestheticSequence?.status_label === "Confirm" ?  "none" : "",}}>
                {props.buttonConfirm}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",height:"50%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(19, 1fr)","gridTemplateColumns":"repeat(39, 1fr)"}}>
            <div
              style={{ gridRow: "2/4", gridColumn: "1/7"}}>
              <div>
                
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  Preanesthetic assessment at
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "7/16"}}>
              <div
                style={{ display: "flex" }}>
                
                <Dropdown
                  clearable={true}
                  name="PreanestheticAssessmentAt"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_assessment_at || []}
                  search={true}
                  selection={true}
                  style={{minWidth: 140, flex: "0 0 25.00%"}}
                  value={props.PreAnestheticSequence?.data?.PreanestheticAssessmentAt || ""}>
                </Dropdown>
                <Input
                  name="PreanestheticAssessmentAtRemark"
                  onChange={props.onChangeData}
                  style={{marginLeft:"5px",height:"35px"}}
                  value={props.PreAnestheticSequence?.data?.PreanestheticAssessmentAtRemark || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "16/19"}}>
              <div>
                
                <label
                  style={{ fontWeight:"Bold",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Body weight
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "19/22"}}>
              <div
                style={{ display: "flex" }}>
                
                <Input
                  readOnly={true}
                  style={{marginLeft:"5px",height:"35px",width:"80px"}}
                  value={props.PreAnestheticSequence?.data?.BodyWeight || ""}>
                </Input>
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  kg
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "23/24"}}>
              <div>
                
                <label
                  style={{ display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  Height
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "24/27"}}>
              <div
                style={{ display: "flex" }}>
                
                <Input
                  readOnly={true}
                  style={{marginLeft:"5px",height:"35px",width:"80px"}}
                  value={props.PreAnestheticSequence?.data?.Height || ""}>
                </Input>
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  cm
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "27/28"}}>
              <div>
                
                <label
                  style={{ display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  BMI
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "28/32"}}>
              <div
                style={{ display: "flex" }}>
                
                <Input
                  readOnly={true}
                  style={{marginLeft:"5px",height:"35px",width:"80px"}}
                  value={props.PreAnestheticSequence?.data?.BMI || ""}>
                </Input>
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  kg/m2
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "32/33"}}>
              <div>
                
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  BP
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "33/37"}}>
              <div
                style={{ display: "flex" }}>
                
                <Input
                  readOnly={true}
                  style={{marginLeft:"5px",height:"35px",width:"100px"}}
                  value={props.PreAnestheticSequence?.data?.BP || ""}>
                </Input>
                <label
                  style={{marginLeft:"20px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  mmHg
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "1/7"}}>
              <div>
                
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  Psychological status
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "7/16"}}>
              <div
                style={{ display: "flex" }}>
                
                <Dropdown
                  clearable={true}
                  name="PsychologicalStatus"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_psychological_status || []}
                  search={true}
                  selection={true}
                  style={{minWidth: 140, flex: "0 0 25.00%"}}
                  value={props.PreAnestheticSequence?.data?.PsychologicalStatus || ""}>
                </Dropdown>
                <Input
                  name="PsychologicalStatusRemark"
                  onChange={props.onChangeData}
                  style={{marginLeft:"5px",height:"35px"}}
                  value={props.PreAnestheticSequence?.data?.PsychologicalStatusRemark || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "16/19"}}>
              <div>
                
                <label
                  style={{ fontWeight:"Bold",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Pulse
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "19/22" }}>
              <div
                style={{ display: "flex" }}>
                
                <Input
                  readOnly={true}
                  style={{marginLeft:"5px",height:"35px",width:"80px"}}
                  value={props.PreAnestheticSequence?.data?.Pulse || ""}>
                </Input>
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  /min
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "23/24"}}>
              <div>
                
                <label
                  style={{ display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center",}}>
                  RR
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "24/27"}}>
              <div
                style={{ display: "flex" }}>
                
                <Input
                  readOnly={true}
                  style={{marginLeft:"5px",height:"35px",width:"80px"}}
                  value={props.PreAnestheticSequence?.data?.RR || ""}>
                </Input>
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  /min
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "27/28" }}>
              <div>
                
                <label
                  style={{ display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  Temp
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "28/32"}}>
              <div
                style={{ display: "flex" }}>
                
                <Input
                  readOnly={true}
                  style={{marginLeft:"5px",height:"35px",width:"80px"}}
                  value={props.PreAnestheticSequence?.data?.PsychologicalStatusTemp || ""}>
                </Input>
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  oC
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "32/35"}}>
              <div>
                
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  O2 Sat RA
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "34/37"}}>
              <div
                style={{ display: "flex" }}>
                
                <Input
                  readOnly={true}
                  style={{marginLeft:"5px",height:"35px",width:"80px"}}
                  value={props.PreAnestheticSequence?.data?.O2SatRA || ""}>
                </Input>
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  %
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "1/3" }}>
              <div>
                
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  DX
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "3/11"}}>
              <div
                style={{ display: "flex" }}>
                
                <Input
                  readOnly={true}
                  style={{height:"35px",width:"300px"}}
                  value={props.PreAnestheticSequence?.data?.DX || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "11/15" }}>
              <div>
                
                <label
                  style={{fontWeight:"Bold",marginLeft:"2px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Proposed operation
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "15/21" }}>
              <div
                style={{ display: "flex" }}>
                
                <Input
                  readOnly={true}
                  style={{height:"35px",width:"2000px"}}
                  value={props.PreAnestheticSequence?.data?.ProposedOperation || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "22/24"}}>
              <div>
                
                <label
                  style={{marginLeft:"2px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center",fontWeight:"Bold"}}>
                  Surgeon
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "24/30"}}>
              <div
                style={{ display: "flex" }}>
                
                <Input
                  readOnly={true}
                  style={{height:"35px",width:"2000px"}}
                  value={props.PreAnestheticSequence?.data?.Surgeon || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "31/34"}}>
              <div>
                
                <label
                  style={{marginLeft:"2px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  Operative Date
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "34/40" }}>
              <div
                style={{ display: "flex" }}>
                
                <Input
                  readOnly={true}
                  style={{height:"35px",width:"2000px"}}
                  value={props.PreAnestheticSequence?.data?.DateTime || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "11/13", gridColumn: "1/7"}}>
              <div>
                
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  Anesthesiologist
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "11/13", gridColumn: "7/13"}}>
              <div
                style={{ display: "flex" }}>
                
                <Dropdown
                  clearable={true}
                  name="Anesthesiologist"
                  onChange={props.onChangeData}
                  options={props.masterOptions.doctor || []}
                  search={true}
                  selection={true}
                  style={{height:"35px",width:"2000px"}}
                  value={props.PreAnestheticSequence?.data?.Anesthesiologist || ""}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ gridRow: "11/13", gridColumn: "14/18"}}>
              <div>
                
                <label
                  style={{marginLeft:"2px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  License number
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "11/13", gridColumn: "18/24"}}>
              <div
                style={{ display: "flex" }}>
                
                <Input
                  name="AnesthesiologistLicenseNumber"
                  readOnly={true}
                  style={{height:"35px",width:"2000px"}}
                  value={props.PreAnestheticSequence?.data?.AnesthesiologistLicenseNumber || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "11/13", gridColumn: "25/30"}}>
              <div>
                
                <label
                  style={{marginLeft:"2px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center",fontWeight:"Bold"}}>
                  Code
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "11/13", gridColumn: "30/36"}}>
              <div
                style={{ display: "flex" }}>
                
                <Input
                  name="AnesthesiologistCode"
                  onChange={props.onChangeData}
                  onKeyDown={props.onKeyAnesthesiologist}
                  style={{height:"35px",width:"2000px"}}
                  value={props.PreAnestheticSequence?.data?.AnesthesiologistCode || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "14/16", gridColumn: "1/7"}}>
              <div>
                
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  Anesthetist
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "14/16", gridColumn: "7/13" }}>
              <div
                style={{ display: "flex" }}>
                
                <Dropdown
                  clearable={true}
                  name="Anesthetist"
                  onChange={props.onChangeData}
                  options={props.masterOptions.doctor || []}
                  search={true}
                  selection={true}
                  style={{height:"35px",width:"2000px"}}
                  value={props.PreAnestheticSequence?.data?.Anesthetist || ""}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ gridRow: "14/16", gridColumn: "14/18"}}>
              <div>
                
                <label
                  style={{marginLeft:"2px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  License number
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "14/16", gridColumn: "18/24" }}>
              <div
                style={{ display: "flex" }}>
                
                <Input
                  name="AnesthetistLicenseNumber"
                  readOnly={true}
                  style={{height:"35px",width:"2000px"}}
                  value={props.PreAnestheticSequence?.data?.AnesthetistLicenseNumber || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "14/16", gridColumn: "25/30" }}>
              <div>
                
                <label
                  style={{marginLeft:"2px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center",fontWeight:"Bold"}}>
                  Code
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "14/16", gridColumn: "30/36"}}>
              <div
                style={{ display: "flex" }}>
                
                <Input
                  name="AnesthetistCode"
                  onChange={props.onChangeData}
                  onKeyDown={props.onKeyAnesthetist}
                  style={{height:"35px",width:"2000px"}}
                  value={props.PreAnestheticSequence?.data?.AnesthetistCode || ""}>
                </Input>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}


export default ANES_PreANES_01

export const screenPropsDefault = {}

/* Date Time : Wed May 10 2023 10:14:08 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",height:\"50%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/3\",marginLeft:\"10px\",marginTop:\"10px\", justifyContent: \"space-between\", display: \"flex\", alingItem: \"center\", marginBottom: \"10px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "Pre Anesthetic form"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,fontWeight:\"Bold\",fontSize:\"18px\",marginBottom:\"10px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(19, 1fr)\",\"gridTemplateColumns\":\"repeat(39, 1fr)\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"1/7\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "Preanesthetic assessment at"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"7/16\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Dropdown",
      "parent": 14,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PreanestheticAssessmentAt"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_assessment_at || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: 140, flex: \"0 0 25.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticAssessmentAt || \"\""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Input",
      "parent": 14,
      "props": {
        "name": {
          "type": "value",
          "value": "PreanestheticAssessmentAtRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"35px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticAssessmentAtRemark || \"\""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"16/19\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Body weight"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight:\"Bold\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"19/22\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Input",
      "parent": 21,
      "props": {
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"35px\",width:\"80px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BodyWeight || \"\""
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "kg"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"23/24\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "Height"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"24/27\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Input",
      "parent": 29,
      "props": {
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"35px\",width:\"80px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Height || \"\""
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "cm"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"27/28\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"28/32\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"32/33\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": "BMI"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Input",
      "parent": 37,
      "props": {
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"35px\",width:\"80px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BMI || \"\""
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "kg/m2"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "label",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "BP"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"33/37\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Input",
      "parent": 43,
      "props": {
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"35px\",width:\"100px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BP || \"\""
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "mmHg"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"1/7\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"7/16\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"16/19\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"19/22\" }"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"23/24\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"24/27\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"27/28\" }"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"28/32\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"32/35\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"34/37\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "label",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": "Psychological status"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Dropdown",
      "parent": 58,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PsychologicalStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_psychological_status || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: 140, flex: \"0 0 25.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PsychologicalStatus || \"\""
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Input",
      "parent": 58,
      "props": {
        "name": {
          "type": "value",
          "value": "PsychologicalStatusRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"35px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PsychologicalStatusRemark || \"\""
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "label",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "Pulse"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight:\"Bold\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Input",
      "parent": 63,
      "props": {
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"35px\",width:\"80px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Pulse || \"\""
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "/min"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "RR"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\",}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Input",
      "parent": 68,
      "props": {
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"35px\",width:\"80px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.RR || \"\""
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "label",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": "/min"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "Temp"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Input",
      "parent": 73,
      "props": {
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"35px\",width:\"80px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PsychologicalStatusTemp || \"\""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "oC"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": "O2 Sat RA"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Input",
      "parent": 78,
      "props": {
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"35px\",width:\"80px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.O2SatRA || \"\""
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "%"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"1/3\" }"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"3/11\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"11/15\" }"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"15/21\" }"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "label",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "DX"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Input",
      "parent": 87,
      "props": {
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"300px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.DX || \"\""
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "label",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "Proposed operation"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"Bold\",marginLeft:\"2px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "Input",
      "parent": 91,
      "props": {
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"2000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ProposedOperation || \"\""
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"22/24\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"24/30\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"31/34\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"34/40\" }"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "label",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "Surgeon"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"2px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\",fontWeight:\"Bold\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "Input",
      "parent": 99,
      "props": {
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"2000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Surgeon || \"\""
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "label",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "Operative Date"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"2px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "Input",
      "parent": 103,
      "props": {
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"2000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.DateTime || \"\""
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/13\", gridColumn: \"1/7\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/13\", gridColumn: \"7/13\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/13\", gridColumn: \"14/18\"}"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/13\", gridColumn: \"18/24\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/13\", gridColumn: \"25/30\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/13\", gridColumn: \"30/36\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "label",
      "parent": 111,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesiologist"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 107,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "label",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": "License number"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"2px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "Input",
      "parent": 117,
      "props": {
        "name": {
          "type": "value",
          "value": "AnesthesiologistLicenseNumber"
        },
        "onChange": {
          "type": "code",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"2000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AnesthesiologistLicenseNumber || \"\""
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "label",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "Code"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"2px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\",fontWeight:\"Bold\"}"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 110,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "Input",
      "parent": 121,
      "props": {
        "name": {
          "type": "value",
          "value": "AnesthesiologistCode"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.onKeyAnesthesiologist"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"2000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AnesthesiologistCode || \"\""
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/16\", gridColumn: \"1/7\"}"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/16\", gridColumn: \"7/13\" }"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/16\", gridColumn: \"14/18\"}"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/16\", gridColumn: \"18/24\" }"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/16\", gridColumn: \"25/30\" }"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/16\", gridColumn: \"30/36\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "label",
      "parent": 129,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthetist"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 125,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "label",
      "parent": 133,
      "props": {
        "children": {
          "type": "value",
          "value": "License number"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"2px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "Input",
      "parent": 135,
      "props": {
        "name": {
          "type": "value",
          "value": "AnesthetistLicenseNumber"
        },
        "onChange": {
          "type": "code",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"2000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AnesthetistLicenseNumber || \"\""
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 127,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "label",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": "Code"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"2px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\",fontWeight:\"Bold\"}"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 128,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "Input",
      "parent": 139,
      "props": {
        "name": {
          "type": "value",
          "value": "AnesthetistCode"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.onKeyAnesthetist"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"2000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AnesthetistCode || \"\""
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\" }"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonLog"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.status_label === \"Confirm\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.status_label === \"Confirm\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "Dropdown",
      "parent": 113,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "Anesthesiologist"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions.doctor || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"2000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Anesthesiologist || \"\""
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "Dropdown",
      "parent": 131,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "Anesthetist"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions.doctor || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"2000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Anesthetist || \"\""
        }
      },
      "seq": 163,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PreANES_01",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
