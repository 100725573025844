import React, { useEffect, useMemo, useCallback } from "react";
// ui common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
// ui ORM
import CardPreOPVisitUX from "./CardPreOPVisitUX";

import { FORM_DATA_ACTION } from "./sequence/PreOperation";

const CARD_KEY: string = "preOPVisitData";
const FORM_CODE: string = "CardPreOPVisit";
const FORM_NAME: string = "Pre-OP Visit";
const FORM_VERSION: string = "0.1";

type CardPreOPVisitProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  PreOperationSequence?: any;
};

const CardPreOPVisitInitial: CardPreOPVisitProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  errorMessage: null,
  successMessage: null,
  buttonLoadCheck: null,

  // select OR
  selectedOrOrder: {},

  // options
  masterOptions: {},

  // seq
  runSequence: null,
  PreOperationSequence: {},
};

const CardPreOPVisit: React.FC<CardPreOPVisitProps> = (props: any) => {
  useEffect(() => {
    props.runSequence({
      sequence: "PreOperation",
      action: "FETCH_FORM_DATA_LATEST",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formVersion: FORM_VERSION,
    });
  }, []);

  const handleChangeDate = (date: string) => {
    props.runSequence({
      sequence: "PreOperation",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: "date_of_visit",
      value: date,
    });
  };

  const handleChangeData = (_event: any, data: any) => {
    let value = "";
    if (data.type === "checkbox") {
      value = data.checked;
    } else {
      value = data.value;
    }
    props.runSequence({
      sequence: "PreOperation",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: data.name,
      value: value,
    });
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "SAVE",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleConfirm = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "CONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleUnconfirm = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "UNCONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  return (
    <>
      {(props.errorMessage?.[CARD_KEY] || props.successMessage?.[CARD_KEY]) && (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            props.setProp(`errorMessage.${CARD_KEY}`, null);
            props.setProp(`successMessage.${CARD_KEY}`, null);
          }}
          error={props.errorMessage?.[CARD_KEY]}
          success={props.successMessage?.[CARD_KEY]}
        />
      )}
      <CardPreOPVisitUX
        // function
        onChangeDate={handleChangeDate}
        onChangeData={handleChangeData}
        // options
        masterOptions={props.masterOptions}
        // seq
        PreOperationSequence={props.PreOperationSequence}
        // component
        buttonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PreOperationSequence}
            type="save"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave}
          />
        }
        buttonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PreOperationSequence}
            type="confirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleConfirm}
          />
        }
        buttonUnconfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PreOperationSequence}
            type="unconfirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleUnconfirm}
          />
        }
      />
    </>
  );
};

/* ------------------------------------------------------ */

/*                    PreOPButtonAction                   */

/* ------------------------------------------------------ */

type PreOPButtonActionProps = {
  setProp: any;
  // data
  data?: Record<string, any>;
  type: "confirm" | "save" | "unconfirm";
  cardKey: string;
  buttonLoadCheck?: Record<string, any>;
  // callback
  onClick: Function;
};

export const PreOPButtonAction = (props: PreOPButtonActionProps) => {
  const status = useMemo(() => {
    return props.data?.[props.cardKey]?.status as "" | "DRAFT" | "CONFIRMED";
  }, [props.data]);

  const show = useCallback(() => {
    return {
      confirm:
        props.data?.[props.cardKey]?.allowed_actions?.includes(
          FORM_DATA_ACTION.CONFIRM
        ) && status !== "CONFIRMED",
      save:
        (props.data?.[props.cardKey]?.id &&
          props.data?.[props.cardKey]?.allowed_actions?.includes(
            FORM_DATA_ACTION.SAVE
          ) &&
          status !== "CONFIRMED") ||
        !props.data?.[props.cardKey]?.id,
      unconfirm: props.data?.[props.cardKey]?.allowed_actions?.includes(
        FORM_DATA_ACTION.UNCONFIRM
      ),
    }[props.type];
  }, [props.type, props.data, props.cardKey, status]);

  const config = useMemo(() => {
    console.log(status, props.type);
    return {
      confirm: {
        title: "Confirm",
        color: "green",
        action: "CONFIRM",
      },
      save: {
        title: status === "DRAFT" ? "Edit" : "Save",
        color: status === "DRAFT" ? "yellow" : "green",
        action: "SAVE",
        show: true,
      },
      unconfirm: {
        title: "Unconfirm",
        color: "red",
        action: "UNCONFIRM",
        show: true,
      },
    }[props.type];
  }, [props.type, status]);

  const handleClick = (e: any) => {
    props.onClick?.(e, {
      name: `${props.cardKey}_${FORM_DATA_ACTION[config.action]}`,
      card: props.cardKey,
    });
  };

  return (
    <ButtonLoadCheck
      // function
      setProp={props.setProp}
      onClick={handleClick}
      // data
      paramKey={`${props.cardKey}_${FORM_DATA_ACTION[config.action]}`}
      buttonLoadCheck={
        props.buttonLoadCheck?.[
          `${props.cardKey}_${FORM_DATA_ACTION[config.action]}`
        ]
      }
      // config
      color={config.color}
      size="small"
      style={{
        display: show() ? "" : "none",
      }}
      title={config.title}
    />
  );
};

CardPreOPVisit.defaultProps = CardPreOPVisitInitial;

export default React.memo(CardPreOPVisit);
