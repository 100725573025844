import WasmController from 'react-lib/frameworks/WasmController';
import * as SetAppointment from "./sequence/SetAppointment";
import * as SetSchedule from "./sequence/SetSchedule";
import * as SetDoctorFeeReport from "./sequence/SetDoctorFeeReport";

export type State =
  {
  }
  & SetAppointment.State
  & SetSchedule.State
  & SetDoctorFeeReport.State
  
export const StateInitial: State =
{
  ...SetAppointment.StateInitial,
  ...SetSchedule.StateInitial,
  ...SetDoctorFeeReport.StateInitial,
}

export type Event =
  SetAppointment.Event
  | SetSchedule.Event
  | SetDoctorFeeReport.Event

export type Data =
  {
    division?: number,
    device?: number,
  }
  & SetAppointment.Data
  & SetSchedule.Data
  & SetDoctorFeeReport.Data

export const DataInitial = 
  {
    ...SetAppointment.DataInitial,
    ...SetSchedule.DataInitial,
    ...SetDoctorFeeReport.DataInitial,
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any
