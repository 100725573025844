import WasmController from "react-lib/frameworks/WasmController";

// APIs
// REG
import PatientList from "issara-sdk/apis/PatientList_apps_REG";
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";
import PatientQuickList from "issara-sdk/apis/PatientQuickList_apps_REG";
// PRX
import ProxyPatientHasDivisionChatDetail from "issara-sdk/apis/ProxyPatientHasDivisionChatDetail_apps_PRX";
import StaffDirectPatientInfoView from "issara-sdk/apis/StaffDirectPatientInfoView_apps_PRX";
// CORE
import SecretPatientList from "issara-sdk/apis/SecretPatientList_core";
import EncounterList from "issara-sdk/apis/EncounterList_core";

// Common
import * as CAgent from "react-lib/apps/common/CAgent";

import axios from "axios";

// Config
import CONFIG from "config/config";

export type State = {
  selectedPatient?: any;
  SetPatientSequence?: {
    sequenceIndex?: string | null;
    searchText?: string | null;
    patientList?: any[] | null;
    patient?: any | null;
    isNicknameActive?: boolean | null;
    checkCancelNickname?: string | null;
    patientEmergency?: any | null;
    patientEmergencyList?: any | null;
    patientOldName?: any;
    patientListTotal?: number;
  } | null;
  chatDetail?: any;
  successMessage?: any;
  errorMessage?: any;
  buttonLoadCheck?: any;
  CreateUpdateEncounterSequence?: any;
  selectedRecordViewIndex?: any;
  goToMenu?: any;
};

export const StateInitial: State = {
  selectedPatient: {
    isNicknameActive: false,
  },
  SetPatientSequence: null,
  successMessage: null,
  errorMessage: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any }
  | { message: "SelectEncounter"; params: any };

export type Data = {
  division?: number;
  device?: number;
  masterData?: any;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const GetMaster: Handler = async (controller) => {
  const state = controller.getState();
  if (!state.SetPatientSequence) return;
  console.log("set patient sequene start");
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["division", {}],
        ["doctor", {}],
        ["belief", {}],
        ["bloodType", {}],
        ["career", {}],
        ["city", {}],
        ["contact", {}],
        ["country", {}],
        ["district", {}],
        ["education", {}],
        ["gender", {}],
        ["homeType", {}],
        ["infoChannel", {}],
        ["nationality", {}],
        ["position", {}],
        ["prenameEn", {}],
        ["prenameTh", {}],
        ["province", {}],
        ["race", {}],
        ["religion", {}],
        ["nicknameActive", {}],
        ["nickname", {}],
        ["nicknamereason", {}],
      ],
    },
  });

  controller.setState(
    {
      SetPatientSequence: {
        sequenceIndex: "SearchOrNew",
      },
    },
    // () => {
    //   console.log(" SetPatient !! call search params: ", params);
    //   controller.handleEvent({
    //     message: "RunSequence",
    //     params: { ...params, action: "search" },
    //   });
    // }
  );
};

export const SearchOrNew: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.SetPatientSequence) return;
  // Reset selectedPatient
  controller.setState({
    selectedPatient: null,
  });

  if (params?.action === "new") {
    return controller.setState({
      SetPatientSequence: {
        ...state.SetPatientSequence,
        isNicknameActive: false,
        searchText: "",
        patientList: [],
        patient: {
          id: null,
          first_name: "",
          last_name: "",
          info_channel: "",
          pre_name_th: "",
          first_name_th: "",
          last_name_th: "",
          pre_name_en: "",
          first_name_en: "",
          last_name_en: "",
          gender: "",
          blood_type: "",
          birthdate: "",
          nationality: "",
          father: "",
          mother: "",
          citizen_type: "",
          citizen_no: "",
          passport_no: "",
          alien_no: "",
          id_number: "",
        },
        sequenceIndex: "EditPatientInfo",
      },
      selectedRecordViewIndex: 0,
    });
  } else if (params?.action === "search") {
    const state = controller.getState();

    await controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
      SetPatientSequence: {
        ...state.SetPatientSequence,
        patientList: null,
        patientListTotal: 0
      },
    })

    let patientName: string = "";
    let patientHn: string = "";

    // if (!isNaN(params?.searchText)) {
    if (/\d/.test(params?.searchText)) {
      patientHn = params?.searchText?.trim();
    } else {
      patientName = params?.searchText?.trim();
    }

    let page = 1;

    if (params?.page !== null) {
      page = params?.page;
    }

    const patient = await PatientList.list({
      params: {
        ...(patientName && { name_search: patientName }),
        ...(patientHn && { hn: patientHn }),
        ...(controller?.data?.division != null && {
          org_div: controller?.data?.division,
        }),
        limit: params?.limit ? params?.limit : 40,
        offset: (page - 1) * (params?.limit ? params?.limit : 40),
      },
      ...(controller?.data?.division != null && {
        extra: { division: controller?.data?.division },
      }),
      apiToken: controller.apiToken,
    });

    controller.setState({
      SetPatientSequence: {
        ...state.SetPatientSequence,
        patientList: [...(patient[0]?.items || [])],
        patientListTotal: patient[0]?.total || 0,
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "",
      },
    });
  } else if (params?.action === "smartCard") {
    CAgent.smartcard(
      function () {
        // console.log("result",result)
      },
      function (error: any) {
        // console.log("error",error)
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            ["regCAgent"]: { error: error },
          },
        });
      }
    );
  } else if (
    params?.action === "select" &&
    params?.patient?.id &&
    Number.isInteger(params.patient.id)
  ) {
    controller.setState({
      selectedPatient: null,
      SetPatientSequence: {
        ...state.SetPatientSequence,
        isNicknameActive: false,
        patient: null,
      },
    });

    const [patientDetail, chatDetail] = await Promise.all([
      PatientDetailView.retrieve({
        pk: params.patient.id,
        apiToken: controller.apiToken,
      }),
      // Get chat detail
      ProxyPatientHasDivisionChatDetail.retrieve({
        pk: params.patient.id,
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
        params: {
          force_create: true,
        },
      }),
    ]);

    if (CONFIG.PATIENT_IMAGE_URL && patientDetail[0]) {
      patientDetail[0].profile_image = null;

      HandleGetProfileImage(controller, { hn: patientDetail[0]?.hn });
    }

    if (patientDetail[0]) {
      controller.setState({
        selectedPatient: patientDetail[0],
        SetPatientSequence: {
          ...state.SetPatientSequence,
          sequenceIndex: "EditPatientInfo",
          patient: {
            ...patientDetail[0],
            home_address: patientDetail[0].home_address
              ? patientDetail[0].home_address
              : {},
            present_address: patientDetail[0].present_address
              ? patientDetail[0].present_address
              : {},
            relative_address: patientDetail[0].relative_address
              ? patientDetail[0].relative_address
              : {},
          },
          isNicknameActive: patientDetail[0]?.nickname_active,
          patientOldName: {
            preNameTHOld: patientDetail[0]?.pre_name_th,
            firstNameTHOld: patientDetail[0]?.first_name_th,
            midNameTHOld: patientDetail[0]?.middle_name_th,
            lastNameTHOld: patientDetail[0]?.last_name_th,

            preNameENOld: patientDetail[0]?.pre_name_en,
            firstNameENOld: patientDetail[0]?.first_name_en,
            midNameENOld: patientDetail[0]?.middle_name_en,
            lastNameENOld: patientDetail[0]?.last_name_en,

            citizenType: patientDetail[0]?.citizen_type,
            citizenNo: patientDetail[0]?.citizen_no,
            passportNo: patientDetail[0]?.passport_no,
            alienNo: patientDetail[0]?.alien_no,
          },
        },
        chatDetail: chatDetail[0],
        selectedRecordViewIndex: 0,
        goToMenu: params.goToMenu,
      }, () => controller.handleEvent({ message: "HandleGetPurchaseOrderList", params: {} } as any));

      if (CONFIG.SHOW_PATIENT_INFO_FROM_BDMS) {
        HandleGetProfileInfo(controller as any, {
          hn: patientDetail[0]?.hn,
          patient_id: patientDetail[0]?.patient_id,
        });
      }
    }
  } else if (params?.action === "register_emergency") {
    const list = params.patient || [];

    for (const [i, item] of (params.patient || []).entries()) {
      const patient = await PatientQuickList.create({
        data: {
          citizen_passport: "",
          citizen_type: "N",
          first_name_en: "",
          first_name_th: item.first_name_th || "",
          gender:
            item.gender === "หญิง" ? "F" : item.gender === "ชาย" ? "M" : "",
          hn: "",
          last_name_en: "",
          last_name_th: item.last_name_th || "",
          pre_name: controller.data?.masterData?.prenameTh?.filter(
            (acc: any) => acc.name === item.pre_name
          )?.[0]?.id,
          middle_name_en: "",
          middle_name_th: "",
          nickname_active: false,
          nationality: controller.data?.masterData?.nationality?.filter(
            (acc: any) => acc.name === "ไทย"
          )?.[0]?.id,
        },
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });
      list[i] = {
        ...patient[0],
        pre_name: list[i].pre_name,
        gender: list[i].gender,
      };
    }

    controller.setState({
      SetPatientSequence: {
        ...state.SetPatientSequence,
        patientEmergencyList: list,
      },
    });
  } else if (params?.action === "patient_emergency") {
    const [r, e, n] = await PatientQuickList.create({
      data: {
        citizen_passport: "",
        citizen_type: "N",
        first_name_en: "",
        first_name_th:
          state?.SetPatientSequence?.patientEmergency?.first_name || "",
        gender: state?.SetPatientSequence?.patientEmergency?.gender || "",
        hn: "",
        last_name_en: "",
        last_name_th:
          state?.SetPatientSequence?.patientEmergency?.last_name || "",
        middle_name_en: "",
        middle_name_th: "",
        nickname_active: false,
        nationality: controller.data?.masterData?.nationality?.filter(
          (item: any) => item.name === "ไทย"
        )?.[0]?.id,

        home_address: {
          owner_name: "",
          relative: "",
          no: "",
          town: "",
          street: "",
          road: "",
          type: null,
          city: null,
          district: null,
          province: null,
          country: null,
          tel_home: "",
          tel_home_suffix: "",
          tel_office: "",
          tel_office_suffix: "",
          tel_mobile: "",
          email: "",
          channel: "",
          note: "",
        },
        present_address: {
          owner_name: "",
          relative: "",
          no: "",
          town: "",
          street: "",
          road: "",
          type: null,
          city: null,
          district: null,
          province: null,
          country: null,
          tel_home: "",
          tel_home_suffix: "",
          tel_office: "",
          tel_office_suffix: "",
          tel_mobile: "",
          email: "",
          channel: "",
          note: "",
        },
        relative_address: {
          owner_name: "",
          relative: "",
          no: "",
          town: "",
          street: "",
          road: "",
          type: null,
          city: null,
          district: null,
          province: null,
          country: null,
          tel_home: "",
          tel_home_suffix: "",
          tel_office: "",
          tel_office_suffix: "",
          tel_mobile: "",
          email: "",
          channel: "",
          note: "",
        },
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });
    if (e) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: e },
        },
      });
    } else {
      controller.setState({
        SetPatientSequence: {
          ...state.SetPatientSequence,
          patient: r,
        },
        successMessage: { ...state.successMessage, [params?.card]: r },
      });

      SearchOrNew(controller, {
        action: "select",
        patient: r,
        goToMenu: "เปิด Encounter ใหม่",
      });

      params.forward?.();
    }
  } else if (params?.action === "clear_emergency") {
    controller.setState({
      SetPatientSequence: {
        ...state.SetPatientSequence,
        patientEmergency: {
          first_name: "",
          last_name: "",
          gender: "",
        },
      },
    });
  }
};

export const EditPatientInfo: Handler = async (controller, params) => {
  const state = controller.getState();

  const idKey = (citizen_type: string) => {
    const key: string =
      {
        T: "citizen_no",
        F: "passport_no",
        A: "alien_no",
        "": "",
      }?.[citizen_type as string] || "";
    return key;
  };

  if (!state.SetPatientSequence) return;
  console.log("edit patient info");
  if (params?.action === "backwithKeptSearchText") {
    return controller.setState({
      SetPatientSequence: {
        searchText: state.SetPatientSequence?.searchText,
        patientList: state.SetPatientSequence?.patientList,
        sequenceIndex: "SearchOrNew",
      },
    });
  } else if (
    params?.action === "save" &&
    state.SetPatientSequence?.patient?.id === null
  ) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });
    // New Patient
    console.log("Controller", controller);

    const patientDetail = await PatientList.create({
      data: {
        birthdate: state?.SetPatientSequence?.patient?.birthdate,
        birthdate_year_only: false,
        citizen_type: state?.SetPatientSequence?.patient?.citizen_type,
        citizen_no:
          state?.SetPatientSequence?.patient?.[
          idKey(
            state?.SetPatientSequence?.patient?.citizen_type === "T"
              ? state?.SetPatientSequence?.patient?.citizen_type
              : "" || ""
          )
          ],
        citizen_alien:
          state?.SetPatientSequence?.patient?.[
          idKey(
            state?.SetPatientSequence?.patient?.citizen_type === "A"
              ? state?.SetPatientSequence?.patient?.citizen_type
              : ""
          )
          ],
        citizen_passport:
          state?.SetPatientSequence?.patient?.[
          idKey(
            state?.SetPatientSequence?.patient?.citizen_type === "F"
              ? state?.SetPatientSequence?.patient?.citizen_type
              : ""
          )
          ],
        pre_name_th: state?.SetPatientSequence?.patient?.pre_name_th || "",
        pre_name_en: state?.SetPatientSequence?.patient?.pre_name_en || null,
        first_name_th: state?.SetPatientSequence?.patient?.first_name_th || "",
        last_name_th: state?.SetPatientSequence?.patient?.last_name_th || "",
        nickname: state?.SetPatientSequence?.patient?.nickname || "",
        nickname_active:
          state?.SetPatientSequence?.patient?.nickname_active || false,
        nickname_permit_no:
          state?.SetPatientSequence?.patient?.nickname_permit_no || "",
        nickname_reason:
          state?.SetPatientSequence?.patient?.nickname_reason || "",
        nickname_warrantor:
          state?.SetPatientSequence?.patient?.nickname_warrantor || "",
        nickname_first_name:
          controller?.data?.masterData?.nickname?.filter(
            (acc: any) =>
              acc.id === state?.SetPatientSequence?.patient?.nickname
          )?.[0]?.first_name || "",
        nickname_last_name:
          controller?.data?.masterData?.nickname?.filter(
            (acc: any) =>
              acc.id === state?.SetPatientSequence?.patient?.nickname
          )?.[0]?.last_name || "",
        name:
          controller?.data?.masterData?.nickname?.filter(
            (acc: any) =>
              acc.id === state?.SetPatientSequence?.patient?.nickname
          )?.[0]?.full_name || "",
        profile_image: null,
        confirmSave: true,
        gender: state?.SetPatientSequence?.patient?.gender,
        info_channel: state?.SetPatientSequence?.patient?.info_channel || "",
        is_secret: state?.SetPatientSequence?.patient?.is_secret,
        id_number:
          state?.SetPatientSequence?.patient?.[
          idKey(
            state?.SetPatientSequence?.patient?.citizen_type === "T"
              ? state?.SetPatientSequence?.patient?.citizen_type
              : ""
          )
          ],
        alien_no:
          state?.SetPatientSequence?.patient?.[
          idKey(
            state?.SetPatientSequence?.patient?.citizen_type === "A"
              ? state?.SetPatientSequence?.patient?.citizen_type
              : ""
          )
          ],
        passport_no:
          state?.SetPatientSequence?.patient?.[
          idKey(
            state?.SetPatientSequence?.patient?.citizen_type === "F"
              ? state?.SetPatientSequence?.patient?.citizen_type
              : ""
          )
          ],
        reimburse_foreign_code:
          state?.SetPatientSequence?.patient?.reimburse_foreign_code || "",
        non_resident: state?.SetPatientSequence?.patient?.non_resident || false,
        middle_name_th:
          state?.SetPatientSequence?.patient?.middle_name_th || "",
        first_name_en: state?.SetPatientSequence?.patient?.first_name_en || "",
        last_name_en: state?.SetPatientSequence?.patient?.last_name_en || "",
        middle_name_en:
          state?.SetPatientSequence?.patient?.middle_name_en || "",
        blood_type: state?.SetPatientSequence?.patient?.blood_type || "",
        allergy: state?.SetPatientSequence?.patient?.allergy || "",
        religion_code: state?.SetPatientSequence?.patient?.religion_code || "",
        birth_country: state?.SetPatientSequence?.patient?.birth_country || "",
        birth_province:
          state?.SetPatientSequence?.patient?.birth_province || "",
        nationality: state?.SetPatientSequence?.patient?.nationality || "",
        race: state?.SetPatientSequence?.patient?.race || "",
        belief: state?.SetPatientSequence?.patient?.belief || "",
        career: state?.SetPatientSequence?.patient?.career || "",
        position: state?.SetPatientSequence?.patient?.position || "",
        education: state?.SetPatientSequence?.patient?.education || "",
        marriage: state?.SetPatientSequence?.patient?.marriage || "",
        couple: state?.SetPatientSequence?.patient?.couple || "",
        father: state?.SetPatientSequence?.patient?.father || "",
        mother: state?.SetPatientSequence?.patient?.mother || "",
      },
      apiToken: controller.apiToken,
    });

    if (patientDetail[0]) {
      controller.setState({
        selectedPatient: patientDetail[0],
        SetPatientSequence: {
          ...state.SetPatientSequence,
          isNicknameActive: patientDetail[0]?.nickname_active,
          patient: {
            ...patientDetail[0],
            home_address: {},
            present_address: {},
            relative_address: {},
          },
        },
        successMessage: {
          ...state.successMessage,
          [params?.sequence]: patientDetail[0],
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
      });

      SearchOrNew(controller, {
        action: "select",
        patient: state.SetPatientSequence.patient,
      });
    }

    if (patientDetail[1]) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params?.sequence]: patientDetail[1],
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
      });
    }
  } else if (
    params?.action === "save" &&
    state.SetPatientSequence?.patient?.id
  ) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    // Existing patient
    const { id, created, edited, ...patientDetail } =
      state.SetPatientSequence.patient;

    const home_address = Object.fromEntries(
      Object.entries(
        state.SetPatientSequence.patient?.home_address || {}
      ).filter((item: any[]) => !["created", "edited"].includes(item[0]))
    );
    const present_address = Object.fromEntries(
      Object.entries(
        state.SetPatientSequence.patient?.present_address || {}
      ).filter((item: any[]) => !["created", "edited"].includes(item[0]))
    );
    const relative_address = Object.fromEntries(
      Object.entries(
        state.SetPatientSequence.patient?.relative_address || {}
      ).filter((item: any[]) => !["created", "edited"].includes(item[0]))
    );

    const patient = await PatientDetailView.update({
      pk: id,
      data: {
        ...patientDetail,
        id: id,
        confirmSave: true,
        home_address: home_address,
        present_address: present_address,
        relative_address: relative_address,
        document_address: {},
        foreign_address: {},
        change_name_reason: "เปลี่ยนชื่อ",
        change_name_ref_no: "ref_no",
        sequence: 0, // what for ?,
        ...(patientDetail?.profile_image?.image?.includes("http")
          ? { profile_image: null }
          : {}),
        ...(state.SetPatientSequence.checkCancelNickname && {
          nickname: null,
          nickname_active: false,
          nickname_permit_no: "",
          nickname_reason: null,
          nickname_warrantor: null,
          nickname_first_name: null,
          nickname_last_name: null,
          name: null,
        }),
      },
      apiToken: controller.apiToken,
    });

    console.log(patient[1] ? patient[1] : patient[0]);

    if (patient[0]) {
      controller.setState({
        SetPatientSequence: {
          ...state.SetPatientSequence,
          isNicknameActive: patient[0]?.nickname_active,
          patient: patient[0],
        },
        successMessage: {
          ...state.successMessage,
          [params?.sequence]: patient[0],
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
      });

      SearchOrNew(controller, {
        action: "select",
        patient: state.SetPatientSequence.patient,
      });
    }
    if (patient[1]) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.sequence]: patient[1] },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
      });
    }
  } else if (params?.action === "clear") {
    controller.setState({
      successMessage: { ...state.successMessage, [params?.sequence]: null },
      errorMessage: { ...state.errorMessage, [params?.sequence]: null },
    });
  } else if (params?.action === "secret") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const [r, e, n] = await SecretPatientList.post({
      data: {
        active: false,
        patient: state?.selectedPatient?.patient_id,
      },
      apiToken: controller.apiToken,
    });

    if (e) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: e },
          },
        });
      }
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
      });
    }
  } else if (params?.action === "patient_emergency") {
    const [r, e, n] = await PatientQuickList.create({
      data: {
        citizen_passport: "",
        citizen_type: "N",
        first_name_en: "",
        first_name_th:
          state?.SetPatientSequence?.patientEmergency?.first_name || "",
        gender: state?.SetPatientSequence?.patientEmergency?.gender || "",
        hn: "",
        last_name_en: "",
        last_name_th:
          state?.SetPatientSequence?.patientEmergency?.last_name || "",
        middle_name_en: "",
        middle_name_th: "",
        nickname_active: false,
        nationality: controller.data?.masterData?.nationality?.filter(
          (item: any) => item.name === "ไทย"
        )?.[0]?.id,

        home_address: {
          owner_name: "",
          relative: "",
          no: "",
          town: "",
          street: "",
          road: "",
          type: null,
          city: null,
          district: null,
          province: null,
          country: null,
          tel_home: "",
          tel_home_suffix: "",
          tel_office: "",
          tel_office_suffix: "",
          tel_mobile: "",
          email: "",
          channel: "",
          note: "",
        },
        present_address: {
          owner_name: "",
          relative: "",
          no: "",
          town: "",
          street: "",
          road: "",
          type: null,
          city: null,
          district: null,
          province: null,
          country: null,
          tel_home: "",
          tel_home_suffix: "",
          tel_office: "",
          tel_office_suffix: "",
          tel_mobile: "",
          email: "",
          channel: "",
          note: "",
        },
        relative_address: {
          owner_name: "",
          relative: "",
          no: "",
          town: "",
          street: "",
          road: "",
          type: null,
          city: null,
          district: null,
          province: null,
          country: null,
          tel_home: "",
          tel_home_suffix: "",
          tel_office: "",
          tel_office_suffix: "",
          tel_mobile: "",
          email: "",
          channel: "",
          note: "",
        },
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    if (e) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: e },
        },
      });
    } else {
      controller.setState({
        SetPatientSequence: {
          ...state.SetPatientSequence,
          patient: r,
        },
        successMessage: { ...state.successMessage, [params?.card]: r },
      });

      const encounter = await EncounterList.create({
        data: {
          action: "OPD_NEW",
          chief_complaints: "",
          division: controller.data?.masterData?.division?.filter(
            (item: any) => item.code === "CNER"
          )?.[0]?.id,
          doctor: controller.data?.masterData?.doctor?.filter(
            (item: any) => item.code === "OR"
          )?.[0]?.id,
          location: "-",
          note: "",
          patient: r?.id,
          patient_case: "",
          remark: "",
          type: "OPD",
          underlying_disease: "",
          zone: "",
        },
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });

      controller.setState({
        CreateUpdateEncounterSequence: {
          ...state.CreateUpdateEncounterSequence,
          encounterList: [encounter?.[0]],
        },
      });
    }
  } else if (params?.action === "clear_emergency") {
    controller.setState({
      SetPatientSequence: {
        ...state.SetPatientSequence,
        patientEmergency: {
          first_name: "",
          last_name: "",
          gender: "",
        },
      },
    });
  }
};

export const HandleGetProfileImage: Handler = (controller, params) => {
  // เมื่อมี hn เท่านั้น
  if (!params.hn) {
    return;
  }

  // get hospital จาก 01-17-051095 โดยเอาจาก เลข 2 ตัวหน้า
  const hospital =
    params.hn.search(/^\d+-\d+-\d+$/g) >= 0 ? params.hn.split("-")?.[0] : "";

  axios
    .post(
      `${CONFIG.PATIENT_IMAGE_URL}/${hospital ? `${hospital}/` : ""}${params.hn
      }`,
      {
        // Hard code
        programid: "VD004",
        restuser: "glsict",
        restpass: "Glsp@ti3nt",
        hn: params.hn,
      },
      {
        // Hard code
        headers: {
          accesstoken: CONFIG.PATIENT_IMAGE_URL_TOKEN,
          programid: "VD004",
          "Content-Type": "application/json",
        },
        // มีเคส 504 จึง settimeout ไว้ เพื่อไม่ให้รอ response นานเกินไป
        timeout: 3 * 1000,
      }
    )
    .then((response) => {
      const state = controller.getState();
      const params = JSON.parse(response.config.data || "{}");

      // เนื่องจากมีการ delay ตอน getImage จึง check hn เพื่อให้ set ข้อมูลได้ถูกต้อง
      if (state.selectedPatient?.hn === params.hn) {
        const image = !!response.data.length
          ? {
            image: `data:image/png;base64,${response.data[0].Image}`,
            image_type: "P",
          }
          : null;

        controller.setState({
          selectedPatient: { ...state.selectedPatient, profile_image: image },
        });

        const hn = state.SetPatientSequence?.patient?.hn;

        if (state.SetPatientSequence && hn === params.hn) {
          controller.setState({
            SetPatientSequence: {
              ...state.SetPatientSequence,
              patient: {
                ...state.SetPatientSequence.patient,
                profile_image: image,
              },
            },
          });
        }
      }
    })
    .catch((err) => {
      console.log("getImage: error", err);
    });
};

export const HandleGetProfileInfo: Handler = async (controller, params) => {
  const [res, err] = await StaffDirectPatientInfoView.get({
    apiToken: controller.apiToken,
    params: { patient: params.patient_id },
  });

  const state = controller.getState();
  if (res[0]) {
    if (state.selectedPatient?.hn === params.hn) {
      // setState patientLanguage เพิ่ม field ใน selectedPatient
      // setState present_address ทับ field เดิม selectedPatient
      controller.setState({
        selectedPatient: {
          ...state.selectedPatient,
          patientLanguage: res[0]?.PrefLanguage || "",
          present_address: {
            ...state.selectedPatient?.present_address,
            tel_mobile: res[0]?.MobileNumber || "",
          },
        },
      });

      const hn = state.SetPatientSequence?.patient?.hn;

      if (state.SetPatientSequence && hn === params.hn) {
        // setState patientLanguage เพิ่ม field ใน SetPatientSequence.patient
        // setState present_address ทับ field เดิม SetPatientSequence.patient
        controller.setState({
          SetPatientSequence: {
            ...state.SetPatientSequence,
            patient: {
              ...state.SetPatientSequence.patient,
              patientLanguage: res[0]?.PrefLanguage || "",
              present_address: {
                ...state.SetPatientSequence?.patient?.present_address,
                tel_mobile: res[0]?.MobileNumber || "",
              },
            },
          },
        });
      }
    }
  }
};
