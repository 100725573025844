import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  FormGroup,
  FormField,
  Icon,
  TextArea
} from 'semantic-ui-react'

const CardSelectPackageUX = (props: any) => {
    return(
      <div
        style={{width: "100%"}}>
        <div
          style={{border: "2px solid #0072BC", minHeight: "7.5rem", width: "100%", position: "relative", borderRadius: "10px", ...(props.isNotFound ?{display: "grid", placeContent:"center"}:{padding:"0.5rem 1.2rem"})}}>
          
          <Button
            color="blue"
            disabled={props.readOnly}
            icon="plus"
            onClick={props.onAddPackage}
            style={{position: "absolute", right: 12, top: 12, fontSize: "0.9rem", display: props.isSelected ? "none": ""}}>
          </Button>
          <div
            style={{fontWeight: "bold", color: "#BDBDBD", fontSize: "1.3rem", display: props.isNotFound ? "":"none"}}>
            กรุณาเลือก Package ที่ต้องการทำนัดหมาย
          </div>
          <div
            style={{display: props.isNotFound ? "none":""}}>
            {props.Accordion}
          </div>
        </div>
        <div
          style={{padding: "2rem 0.5rem 2rem 1.5rem"}}>
          
          <div
            className="ui form"
            style={{display: props.isSelected ? "": "none"}}>
            
            <FormGroup
              inline={true}
              style={{fontSize: "1.15rem"}}>
              <FormField
                inline={true}>
                <label>
                  {!!props.patientInfo ? `${props.patientInfo?.full_name} [${props.patientInfo?.hn}]` : ""}
                </label>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{transform:"scale(1.3)", margin: "0 -0.5rem 0 -0.5rem"}}>
                  
                  <Icon
                    className="blue"
                    name="man">
                  </Icon>
                </div>
              </FormField>
              <FormField
                inline={true}>
                <label>
                  {props.patientInfo?.birthdate || ""}
                </label>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{transform:"rotate(90deg)", margin: "0 -0.5rem 0 0rem"}}>
                  
                  <Icon
                    className="blue"
                    name="phone">
                  </Icon>
                </div>
              </FormField>
              <FormField
                inline={true}>
                <label>
                  {props.patientInfo?.phone_num || "-"}
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{fontSize: "1.15rem"}}>
              <FormField
                inline={true}>
                <label
                  style={{color: "#0072BC"}}>
                  วันที่ทำการทำนัดหมาย
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{fontWeight: "normal", fontSize: "1rem"}}>
                  {props.slotDate || ""}
                </label>
              </FormField>
              <FormField
                inline={true}
                style={{margin: "0 3rem"}}>
                <label
                  style={{fontWeight: "normal", fontSize: "1rem"}}>
                  {`เวลา ${props.slotTime || ""}`}
                </label>
              </FormField>
              <FormField
                inline={true}>
                <label
                  style={{fontWeight: "normal", fontSize: "1rem"}}>
                  {`สถานที่ : ${props.PackageDateTimeSequence?.appointmentPackageDetail?.performed_division_name|| ""}`}
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{fontSize: "1rem"}}>
                  คำแนะนำในการปฏิบัติตัวเพิ่มเติม
                </label>
              </FormField>
              <FormField
                inline={true}>
                <Button
                  color="blue"
                  onClick={props.onAddNote}
                  size="mini"
                  style={{display: "none"}}>
                  เพิ่มคำแนะนำ
                </Button>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={16}>
                <TextArea
                  disabled={true}
                  name="doctor_note"
                  onChange={props.onChangeValue}
                  rows={5}
                  style={{opacity: 1, fontSize: "1rem", lineHeight: 1.75}}
                  value={props.PackageDateTimeSequence?.appointmentPackageDetail?.doctor_note || ""}>
                </TextArea>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{margin: "2.5rem 0 2rem"}}>
              <FormField
                inline={true}
                style={{flex: 1}}>
              </FormField>
              <FormField
                inline={true}>
                <Button
                  color="orange"
                  onClick={props.onPostponeAppointment}>
                  เลื่อนนัด
                </Button>
                <Button
                  color="red"
                  onClick={props.onCancelAppointment}
                  style={{marginRight: "0.85rem"}}>
                  ยกเลิกนัด
                </Button>
                <Button
                  color="blue">
                  พิมพ์ใบนัด
                </Button>
              </FormField>
            </FormGroup>
          </div>
        </div>
      </div>
    )
}


export default CardSelectPackageUX

export const screenPropsDefault = {"price_claimable":0,"price_non_claimable":0,"price_total":0}

/* Date Time : Wed Jun 21 2023 04:38:58 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{border: \"2px solid #0072BC\", minHeight: \"7.5rem\", width: \"100%\", position: \"relative\", borderRadius: \"10px\", ...(props.isNotFound ?{display: \"grid\", placeContent:\"center\"}:{padding:\"0.5rem 1.2rem\"})}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Button",
      "parent": 1,
      "props": {
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "icon": {
          "type": "value",
          "value": "plus"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddPackage"
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", right: 12, top: 12, fontSize: \"0.9rem\", display: props.isSelected ? \"none\": \"\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "กรุณาเลือก Package ที่ต้องการทำนัดหมาย"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", color: \"#BDBDBD\", fontSize: \"1.3rem\", display: props.isNotFound ? \"\":\"none\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.Accordion"
        },
        "style": {
          "type": "code",
          "value": "{display: props.isNotFound ? \"none\":\"\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"2rem 0.5rem 2rem 1.5rem\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: props.isSelected ? \"\": \"none\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.15rem\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "!!props.patientInfo ? `${props.patientInfo?.full_name} [${props.patientInfo?.hn}]` : \"\""
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientInfo?.birthdate || \"\""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientInfo?.phone_num || \"-\""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{transform:\"rotate(90deg)\", margin: \"0 -0.5rem 0 0rem\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Icon",
      "parent": 19,
      "props": {
        "className": {
          "type": "value",
          "value": "blue"
        },
        "name": {
          "type": "value",
          "value": "phone"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{transform:\"scale(1.3)\", margin: \"0 -0.5rem 0 -0.5rem\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Icon",
      "parent": 21,
      "props": {
        "className": {
          "type": "value",
          "value": "blue"
        },
        "name": {
          "type": "value",
          "value": "man"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.15rem\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 23,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ทำการทำนัดหมาย"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#0072BC\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 26,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 26,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 3rem\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 26,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 27,
      "props": {
        "children": {
          "type": "code",
          "value": "props.slotDate || \"\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", fontSize: \"1rem\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": "`เวลา ${props.slotTime || \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", fontSize: \"1rem\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "`สถานที่ : ${props.PackageDateTimeSequence?.appointmentPackageDetail?.performed_division_name|| \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", fontSize: \"1rem\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormField",
      "parent": 33,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 33,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "คำแนะนำในการปฏิบัติตัวเพิ่มเติม"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1rem\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Button",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มคำแนะนำ"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddNote"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 38,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "TextArea",
      "parent": 39,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "doctor_note"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "rows": {
          "type": "code",
          "value": "5"
        },
        "style": {
          "type": "code",
          "value": "{opacity: 1, fontSize: \"1rem\", lineHeight: 1.75}"
        },
        "value": {
          "type": "code",
          "value": "props.PackageDateTimeSequence?.appointmentPackageDetail?.doctor_note || \"\""
        }
      },
      "seq": 40,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"2.5rem 0 2rem\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 41,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 41,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Button",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "เลื่อนนัด"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPostponeAppointment"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Button",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกนัด"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelAppointment"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.85rem\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Button",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ใบนัด"
        },
        "color": {
          "type": "value",
          "value": "blue"
        }
      },
      "seq": 46,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardSelectPackageUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "price_claimable": 0,
    "price_non_claimable": 0,
    "price_total": 0
  },
  "width": 55
}

*********************************************************************************** */
