import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown
} from 'semantic-ui-react'

const CardSelectDateTimeOperationUX = (props: any) => {
    return(
      <div>
        <div
          style={{display: "flex" , marginBottom: "10px"}}>
          
          <div
            style={{marginRight: "10px"}}>
            
            <div>
              {props.doctorLabel}
            </div>
            <Dropdown
              clearable={true}
              disabled={props.disabledDoctorOptions}
              onChange={props.handleChangeOption("doctor")}
              options={props.operationDoctorOptions}
              search={true}
              selection={true}
              value={props.operationDoctor}>
            </Dropdown>
          </div>
          <div
            style={{marginRight: "10px"}}>
            
            <div>
              {props.anesthesiaLabel}
            </div>
            <Dropdown
              clearable={true}
              disabled={props.disabledAnesthesiaOptions || false}
              onChange={props.handleChangeOption("anesthesiologist")}
              options={props.ansDoctorOptions}
              search={true}
              selection={true}
              value={props.ansDoctor}>
            </Dropdown>
          </div>
          <div
            style={{marginRight: "10px"}}>
            
            <div>
              {props.operatingRoomLabel}
            </div>
            <div>
              {props.operatingRoomOptions}
            </div>
          </div>
        </div>
        <div>
          {props.selectCalendar}
        </div>
      </div>
    )
}


export default CardSelectDateTimeOperationUX

export const screenPropsDefault = {}

/* Date Time : Thu May 18 2023 02:56:52 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\" , marginBottom: \"10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectCalendar"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Dropdown",
      "parent": 4,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledDoctorOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeOption(\"doctor\")"
        },
        "options": {
          "type": "code",
          "value": "props.operationDoctorOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.operationDoctor"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Dropdown",
      "parent": 5,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledAnesthesiaOptions || false"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeOption(\"anesthesiologist\")"
        },
        "options": {
          "type": "code",
          "value": "props.ansDoctorOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ansDoctor"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorLabel"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.anesthesiaLabel"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.operatingRoomLabel"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.operatingRoomOptions"
        }
      },
      "seq": 13,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSelectDateTimeOperationUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
