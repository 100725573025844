import WasmController from "react-lib/frameworks/WasmController";

// APIS
// CORE
import PackageOrderList from "issara-sdk/apis/PackageOrderList_core";

// Serializer
import PackageOrderSerializer from "issara-sdk/types/PackageOrderSerializer_core";

// sequence
import * as SettingPackageI from "./sequence/SettingPackage";
import * as PackagePurchaseI from "./sequence/PackagePurchase";
import * as PackageDateTimeI from "./sequence/PackageDateTime";

// Interface
import { State as MainState } from "../../../../HIS/MainHISInterface";

export type State = Partial<{
  purchaseOrderList: PackageOrderSerializer[];
}> &
  SettingPackageI.State &
  PackagePurchaseI.State &
  PackageDateTimeI.State;

type Picked = Partial<Pick<MainState, "selectedPatient">>;

export const DrugOrderInitial = {};

export const StateInitial: State = {
  ...SettingPackageI.StateInitial,
  ...PackagePurchaseI.StateInitial,
  ...PackageDateTimeI.StateInitial,
};

export type Event =
  | { message: "RunSequence"; params: any }
  | { message: "GetMasterData"; params: {} }
  | { message: "HandleGetPurchaseOrderList"; params: {} }
  | SettingPackageI.Event
  | PackagePurchaseI.Event
  | PackageDateTimeI.Event;

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

export const HandleGetPurchaseOrderList: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  const result = await PackageOrderList.list({
    apiToken: controller.apiToken,
    params: {
      patient: state.selectedPatient?.id,
      simple_billable_status: "BILLED",
      active: true,
    },
  });

  const items = (result?.[0]?.items || []).filter(
    (item: any) => item.active && !item.paid_at
  );

  controller.setState({
    purchaseOrderList: items,
  });
};
