import React, { useEffect, useMemo, useState } from "react";
import { Button, Input } from "semantic-ui-react";
import DropDownColumn from "react-lib/appcon/common/DropDownColumn";
import EditorColumn from "react-lib/appcon/common/EditorColumn";
import SnackMessage from "../common/SnackMessage";
import CardImagingRequestUX from "./CardImagingRequestUX";
import { isNumber } from "react-simple-timefield";

const CARD_IMAGING_REQUEST = "CardImagingRequest";

const CardImagingRequest = (props: any) => {
  const [search, setSearch] = useState<string>("");
  const [selectedProduct, setSelectedProduct] = useState<any>(null);

  useEffect(() => {
    props.onEvent({
      message: "ImagingRequestOrder",
      params: {
        action: "initial",
        orderId: props.editId,
        isAppointment: props.isAppointment,
        card: CARD_IMAGING_REQUEST,
      },
    })
    
  }, [props.editId]);

  const products = useMemo(() => {
    return (props.imagingList || []).map((item: any, index: number) => ({
      ...item,
      number: index + 1,
    }));
  }, [props.imagingList]);

  const selectedProducts = useMemo(() => {
    console.log("orderImagingList: ", props.orderImagingList)
    return (props.orderImagingList || [])
      .filter((item: any) => item.active)
      .map((item: any, index: number) => ({
        ...item,
        number: index + 1,
        unit:
          item.unit && isNumber(item.unit)
            ? props.masterOptions?.["unit"].find(
                (u: any) => u.value === item.unit
              )?.text || ""
            : item.unit,
        quantity: (
          <EditorColumn
            value={item.quantity || ""}
            onDataChanged={(value: string) =>
              props.onEvent({
                message: "ImagingRequestOrder",
                params: {
                  action: "edit_order_item",
                  isAppointment: props.isAppointment,
                  name: "quantity",
                  value: value,
                  targetProduct: item.product,
                  card: CARD_IMAGING_REQUEST,
                },
              })
            }
          />
        ),
        eligibility_type: (
          <DropDownColumn
            options={props.masterOptions.eligibilityType || []}
            value={item.eligibility_type || ""}
            onDataChanged={(value: string) =>
              props.onEvent({
                message: "ImagingRequestOrder",
                params: {
                  action: "edit_order_item",
                  name: "eligibility_type",
                  value: value,
                  targetProduct: item.product,
                  isAppointment: props.isAppointment,
                  card: CARD_IMAGING_REQUEST,
                },
              })
            }
          />
        ),
        _delete: (
          <div style={{ width: "100%", textAlign: "center" }}>
            <Button
              color="red"
              icon="trash alternate"
              size="mini"
              onClick={() => {
                props.onEvent({
                  message: "ImagingRequestOrder",
                  params: {
                    action: "delete_order_item",
                    targetProduct: item.product,
                    isAppointment: props.isAppointment,
                    card: CARD_IMAGING_REQUEST,
                  },
                });
              }}
            />
          </div>
        ),
      }));
  }, [props.orderImagingList]);

  console.log("CardImagingRequest: ", props, selectedProducts);

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_IMAGING_REQUEST}`, null);
          props.setProp(`successMessage.${CARD_IMAGING_REQUEST}`, null);
        }}
        error={props.errorMessage?.[CARD_IMAGING_REQUEST]}
        success={props.successMessage?.[CARD_IMAGING_REQUEST]}
      />
      <CardImagingRequestUX
        searchBox={
          <Input
            icon="search"
            value={search}
            onChange={(e: any) => {
              setSearch(e.target.value);
            }}
          />
        }
        onSearch={() => {
          setSelectedProduct(null);
          props.onEvent({
            message: "ImagingRequestOrder",
            params: {
              searchName: search,
              action: "search_product",
              isAppointment: props.isAppointment,
              card: CARD_IMAGING_REQUEST,
            },
          });
        }}
        searchingData={products}
        getProductRow={(state: any, rowInfo: any, column: any, instance:any) => {
          return {
            onDoubleClick: (e: any) => {
              props.onEvent({
                message: "ImagingRequestOrder",
                params: {
                  action: "add_product",
                  item: rowInfo?.original,
                  isAppointment: props.isAppointment,
                  card: CARD_IMAGING_REQUEST,
                },
              });
            },
            onClick: (e: any) => {
              setSelectedProduct(rowInfo?.original);
            },
            className:
              selectedProduct?.id &&
              rowInfo?.original?.id === selectedProduct?.id
                ? "blueSelectedRow"
                : "",
          };
        }}
        productSelectedData={selectedProducts}
        isCalculate={!!props.orderImagingList?.filter((item:any) => item.active).length}
        totalPrice={[...props.orderImagingList].filter((item:any) => item.active).reduce(
          (total: any, item: any) => total + item.price_total,
          0
        )}
        canClaim={[...props.orderImagingList].filter((item:any) => item.active).reduce(
          (total: any, item: any) => total + item.price_claimable,
          0
        )}
        unclaim={[...props.orderImagingList].filter((item:any) => item.active).reduce(
          (total: any, item: any) => total + item.price_non_claimable,
          0
        )}
        onSaveOrder={() => {
          console.log("save_orders", props.editId)
          props.onEvent({
            message: "ImagingRequestOrder",
            params: {
              orderId: props.editId,
              isAppointment: props.isAppointment,
              action: "save_orders",
              card: CARD_IMAGING_REQUEST,
              onSuccess: props.onSuccess || null
            },
          });
        }}
      />
    </>
  );
};

export default CardImagingRequest;
