import React, { useState, useMemo } from "react";
import { Button, Dropdown } from "semantic-ui-react";

// UX
import CardMedicationUX from "./CardMedicationUX";
import TimeComboBox from "../../common/TimeComboBox";
import EmployeeToken from "../../common/EmployeeToken";
import { ACTION, checkHideButton } from "./CardOtherForm";
import { PreOPButtonAction } from "./CardPreOPVisit";

const Medication = {
  medicine_name: "",
  rount: "",
  time: "",
  code: "",
};

export const OR_MEDICATION = "orMedication";
const CARD_OR_MEDICATION = "CardORMedication";

const CardORMedication = (props: any) => {
  const [medicationData, setMedicationData] = useState([Medication]);
  const [medicationOtherData, setMedicationOtherData] = useState([Medication]);

  const handlerAddMedication = (type: any) => {
    const items: any[] = props.data?.data?.[type] || [];

    items.push(JSON.parse(JSON.stringify(Medication)));
    handleChangeData(null, { name: type, value: items });
  };

  const handlerDeleteMedication = (index: any, type: any) => {
    const items: any[] = props.data?.data?.[type] || [];

    if (items.length > 1) {
      items.splice(index, 1);
      handleChangeData(null, { name: type, value: items });
    }
  };

  const handleChangeMedication = (
    value: any,
    index: any,
    key: any,
    type: any
  ) => {
    const items: any[] = props.data?.data?.[type] || [];

    items[index][key] = value;
    handleChangeData(null, { name: type, value: items });
  };

  const handleSave = (action: string) => (e: any, v: any) => {
    const data = props.data?.data || {};

    const mapItems = (item: any) => ({
      code: item.code || "",
      medicine_name: item.medicine_name || "",
      rount: item.rount || "",
      time: item.time || "",
    });

    data.med_items = data.med_items.map(mapItems);
    data.other_med_items = data.other_med_items.map(mapItems);

    handleChangeData(null, {
      name: "",
      value: data,
      callback: () => {
        props.onSave(action)(e, {
          ...v,
          formCode: CARD_OR_MEDICATION,
          formName: "ORMedication",
          key: OR_MEDICATION,
        });
      },
    });
  };

  const handleChangeData = (e: any, v: any) => {
    props.onChangeData?.(e, v);
  };

  return (
    <>
      <CardMedicationUX
        // data
        status={props.data?.status}
        isOrMedication={props.data?.data?.is_or_medication}
        // callback
        onChangeData={handleChangeData}
        // config
        config={{
          hideStatus: checkHideButton(props.data, "STATUS"),
        }}
        // Element
        medication={
          <div>
            {(props.data?.data?.med_items || [{}])?.map(
              (item: any, index: any) => {
                return (
                  <MedicationData
                    item={item}
                    index={index}
                    type={"med_items"}
                    // options
                    orMedicationOptions={props.masterOptions?.orMedication}
                    routeOptions={props.masterOptions?.route}
                    // config
                    disabled={!props.data?.data?.is_or_medication}
                    // callback
                    onAddMedication={handlerAddMedication}
                    onDeleteMedication={handlerDeleteMedication}
                    onChangeMedication={handleChangeMedication}
                    onEnterToken={props.onEnterToken}
                  />
                );
              }
            )}
          </div>
        }
        medicationOther={
          <div>
            {(props.data?.data?.other_med_items || [{}])?.map(
              (item: any, index: any) => {
                return (
                  <MedicationData
                    item={item}
                    index={index}
                    type={"other_med_items"}
                    // options
                    orMedicationOptions={props.masterOptions?.orMedication}
                    routeOptions={props.masterOptions?.route}
                    // config
                    disabled={!props.data?.data?.is_or_medication}
                    // callback
                    onAddMedication={handlerAddMedication}
                    onDeleteMedication={handlerDeleteMedication}
                    onChangeMedication={handleChangeMedication}
                    onEnterToken={props.onEnterToken}
                  />
                );
              }
            )}
          </div>
        }
        ButtonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [OR_MEDICATION]: props.data }}
            type="save"
            cardKey={OR_MEDICATION}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.SAVE)}
          />
        }
        ButtonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [OR_MEDICATION]: props.data }}
            type="confirm"
            cardKey={OR_MEDICATION}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.CONFIRM)}
          />
        }
        ButtonUnConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [OR_MEDICATION]: props.data }}
            type="unconfirm"
            cardKey={OR_MEDICATION}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.UNCONFIRM)}
          />
        }
      />
    </>
  );
};

/* ------------------------------------------------------ */

/*                    MedicationData;                   */

/* ------------------------------------------------------ */
const MedicationData = (props: any) => {
  const name = useMemo(() => {
    return props.item?.code
      ? decodeURIComponent(atob(props.item.code.split(".")[1]))
      : "";
  }, [props.item?.code]);

  const handleChange = (key: string) => (e: any, data?: any) => {
    const value = typeof e === "string" ? e : data.value;
    props.onChangeMedication(value, props.index, key, props.type);
  };

  const handleEnterToken = (key: string) => (value: string) => {
    props.onEnterToken(value, props.index, key, props.type);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "20%",
        }}
      >
        <div style={{ width: "100%" }}>
          <Dropdown
            selection={true}
            fluid={true}
            options={props.orMedicationOptions}
            value={props.item.medicine_name}
            disabled={props.disabled}
            onChange={handleChange("medicine_name")}
          ></Dropdown>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "0px 10px",
          width: "20%",
        }}
      >
        <div>Rount</div>
        <div style={{ width: "100%", margin: "0px 20px" }}>
          <Dropdown
            selection={true}
            fluid={true}
            options={props.routeOptions}
            value={props.item.rount}
            disabled={props.disabled}
            onChange={handleChange("rount")}
          ></Dropdown>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "20%",
        }}
      >
        <div>Time</div>
        <div style={{ margin: "0px 20px", width: "100%" }}>
          <TimeComboBox
            noMinWidth={true}
            defaultValue={props.item.time}
            disabled={props.disabled}
            fluid={true}
            style={{ margin: "5px 0 0" }}
            onTextChange={handleChange("time")}
          ></TimeComboBox>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "0px 10px",
          width: "20%",
        }}
      >
        <div style={{ minWidth: "max-content" }}>Given by</div>
        <div style={{ margin: "0px 20px", width: "70%" }}>
          {/* <Input disabled={props.disabled} fluid={true}></Input> */}
          <EmployeeToken
            placeholder="Code"
            disabled={props.disabled}
            onEnterToken={handleEnterToken("code")}
            // onClearToken={async () => {
            //   await props.setProp("intraTransferForm.transferringTokenize", {});
            //   await props.setProp(
            //     "intraTransferForm.transferring_personnel",
            //     ""
            //   );
            // }}
            error={props.item?.error}
            loading={props.item?.loading}
            employeeName={name}
            inputStyle={{ width: "100%" }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "20%",
        }}
      >
        {props.index !== 0 && (
          <Button
            color="red"
            icon="minus"
            size="mini"
            disabled={props.disabled}
            style={{ margin: "0px 20px" }}
            onClick={() => props.onDeleteMedication(props.index, props.type)}
          ></Button>
        )}
        <Button
          color="blue"
          icon="plus"
          size="mini"
          disabled={props.disabled}
          style={props.index === 0 ? { margin: "0px 20px" } : {}}
          onClick={() => props.onAddMedication(props.type)}
        ></Button>
      </div>
    </div>
  );
};

export default React.memo(CardORMedication);
