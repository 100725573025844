export const patientTypeOptions = [
  {
    key: 1,
    text: "OPD insite",
    value: 1,
  },
  { key: 2, text: "Telemed", value: 2 },
  { key: 3, text: "Refill med", value: 3 },
];

export const deliveryTypeOptions = [
  {
    key: 1,
    text: "ส่งด่วน",
    value: 1,
  },
  { key: 2, text: "ส่งธรรมดา", value: 2 },
];

export const deliveryStatusOptions = [
  {
    key: 1,
    text: "รอจัดส่ง",
    value: "INITIAL",
  },
  { key: 2, text: "กำลังหาคนขับ", value: "ASSIGN_DRIVER" },
  { key: 3, text: "คนขับกำลังไปรับยา", value: "WAIT_DRIVER"},
  { key: 4, text: "คนขับรับยาแล้ว", value: "PICKED_UP" },
  { key: 5, text: "คนขับรับยาแล้ว > 2 วัน", value: "PICKED_UP" },
  { key: 6, text: "คนไข้รับยาแล้ว", value: "DELIVERED" },
  { key: 7, text: "จัดส่งไม่สำเร็จ", value: "DELIVERY_FAILED" },
  { key: 8, text: "ยกเลิกโดยโรงพยาบาล", value: "CANCELED" },
  { key: 9, text: "ยกเลิกโดยคนขับ", value: "REJECT_DRIVER" },
  { key: 10, text: "เรียกรถเข้ารับไม่สำเร็จ", value: "ERROR" },

];

export const consultStatusOptions = [
  {
    key: 1,
    text: "รอรับคำปรึกษายา",
    value: 1,
  },
  { key: 2, text: "กำลังให้คำปรึกษายา", value: 2 },
  { key: 3, text: "จบการให้คำปรึกษายา", value: 3 },
];
