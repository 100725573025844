import WasmController from "react-lib/frameworks/WasmController";
// sequence
import * as DrugSelect from "./sequence/DrugSelect";
import * as DrugOrderQueue from "./sequence/SetDrugOrderQueue";
import * as DrugOrderHistoryS from "./sequence/DrugOrderHistory";
import * as MedErrorList from "./sequence/MedErrorList";
import * as AllergyI from "./sequence/Allergy";
import * as MedReconcileI from "./sequence/MedReconcile";
import * as DrugTransferRequest from "./sequence/DrugTransferRequest";
import * as DischargeSummary from "../ADM/sequence/DischargeSummary";
import * as DrugTransferRequestHistoryI from "./sequence/DrugTransferRequestHistory";
import * as DrugReturnRequestHistoryI from "./sequence/DrugReturnRequestHistory";
import * as DrugOrderActionI from "./sequence/DrugOrderAction";
import * as ApportmentTelepharI from "./sequence/AppointmentTelephar";
import * as StockManagementI from "./sequence/StockManagement";
import * as DrugOrderTemplateI from "./sequence/DrugOrderTemplate";


import * as CardPatientQueueI from "react-lib/apps/HISV3/common/CardPatientListInterface";


// apis
import DrugContinuePlanActionLogList from "issara-sdk/apis/DrugContinuePlanActionLogList_apps_TPD";
import DrugContinuePlanDetail from "issara-sdk/apis/DrugContinuePlanDetail_apps_TPD";
import DrugContinuePlanList from "issara-sdk/apis/DrugContinuePlanList_apps_TPD";
import DrugDetail from "issara-sdk/apis/DrugDetail_apps_TPD";
import DrugList from "issara-sdk/apis/DrugList_apps_TPD";
import DrugOrderEstimateView from "issara-sdk/apis/DrugOrderEstimateView_apps_TPD";
import DrugOrderDetailList from "issara-sdk/apis/DrugOrderDetailList_apps_TPDM";
import DrugOrderQueueList from "issara-sdk/apis/DrugOrderQueueList_apps_TPD";
import DrugOrderDetailForAction from "issara-sdk/apis/DrugOrderDetailForAction_apps_TPD";
import DrugOrderHistoryList from "issara-sdk/apis/DrugOrderHistoryList_apps_TPD";
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";
import SupplyOrderPreview from "issara-sdk/apis/SupplyOrderPreview_apps_MSD"; // Retrieve api with no pk
import DrugDiseaseCheck from "issara-sdk/apis/CheckDrugDisease_apps_PHR";
import DivisionList from "issara-sdk/apis/DivisionList_core";
import UserFilterDivisionHaveUser from "issara-sdk/apis/UserFilterDivisionHaveUser_apps_PRX";
import MedicationErrorDetail from "issara-sdk/apis/MedicationErrorDetail_apps_TPD";
import DrugRecommendationView from "issara-sdk/apis/DrugRecommendationView_apps_TPD";
import AdverseReactionCheckListView from "issara-sdk/apis/AdverseReactionCheckListView_apps_ADR";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core"

// PRINT
import DrugOrderDoctorPrintView from "issara-sdk/apis/DrugOrderDoctorPrintView_apps_TPD";

// Check Drug Group Druplicate
import CheckDrugDuplicateDrugGroupView from "issara-sdk/apis/CheckDrugDuplicateDrugGroupView_apps_TPD";

// med reconcile APIs
import MedicationReconciliationList from "issara-sdk/apis/MedicationReconciliationNewList_apps_TPD";
import MedicationReconciliationDetail from "issara-sdk/apis/MedicationReconciliationNewDetail_apps_TPD";
import DrugOrderItemForReconciliationList from "issara-sdk/apis/DrugOrderItemForReconciliationList_apps_TPD";
import MedicationReconciliationAcknowledge from "issara-sdk/apis/MedicationReconciliationAcknowledge_apps_TPD";
import CreateUpdateMedicationReconciliationView from "issara-sdk/apis/CreateUpdateMedicationReconciliationView_apps_TPD";
import MedicationReconciliationSummaryView from "issara-sdk/apis/MedicationReconciliationSummaryView_apps_TPD";
import MedicationReconciliationOrderView from "issara-sdk/apis/MedicationReconciliationOrderView_apps_TPD";
import MedicationReconciliationLogList from "issara-sdk/apis/MedicationReconciliationLogList_apps_TPD";
import MedicationReconciliationCheckView from "issara-sdk/apis/MedicationReconciliationCheckView_apps_TPD";
import MedReconciliationIPDAdmitForDischargeView from "issara-sdk/apis/MedReconciliationIPDAdmitForDischargeView_apps_TPD";

import EncounterPatientDetail from "issara-sdk/apis/EncounterPatientDetail_core";
import UserList from "issara-sdk/apis/UserList_users";
import MedicationErrorList from "issara-sdk/apis/MedicationErrorList_apps_TPD";
import MedicationErrorDetailM from "issara-sdk/apis/MedicationErrorDetail_apps_TPDM";
import MedicationErrorTypeList from "issara-sdk/apis/MedicationErrorTypeList_apps_TPD";
import MedicationErrorTypeDetailList from "issara-sdk/apis/MedicationErrorTypeDetailList_apps_TPD";
import MedicationErrorCauseList from "issara-sdk/apis/MedicationErrorCauseList_apps_TPD";
import DrugTransferRequestList from "issara-sdk/apis/DrugTransferRequestList_apps_TPD";
import CheckDoctorCertificate from "issara-sdk/apis/CheckDoctorCertificate_apps_TPD";
import UserTokenizeView from "issara-sdk/apis/UserTokenizeView_users";
import DoctorOrderCancel from "issara-sdk/apis/DoctorOrderCancel_apps_DPO";
import DrugOrderItemPrintLabel from "issara-sdk/apis/DrugOrderItemPrintLabel_apps_TPD";
import PastMedicationList from "issara-sdk/apis/PastMedicationList_apps_TPD";
import DrugOrderItemClaim from "issara-sdk/apis/DrugOrderItemClaim_apps_TPD";
import DrugOrderActionLogList from "issara-sdk/apis/DrugOrderActionLogList_apps_TPD";
import DrugOrderNarcoticPrint from "issara-sdk/apis/DrugOrderNarcoticPrint_apps_TPD";
import DrugOrderPsychotropicPrint from "issara-sdk/apis/DrugOrderPsychotropicPrint_apps_TPD";
import DoctorOrderView from "issara-sdk/apis/DoctorOrderView_apps_PTM";
import DrugPackageAlertList from "issara-sdk/apis/DrugPackageAlertList_apps_TPD"
// OPD DRUG
import DrugOPDOneDoseOrderView from "issara-sdk/apis/DrugOPDOneDoseOrderView_apps_TPDM";
import DrugOPDStatOrderView from "issara-sdk/apis/DrugOPDStatOrderView_apps_TPDM";
import DrugOPDHomeOrderView from "issara-sdk/apis/DrugOPDHomeOrderView_apps_TPDM";
// IPD DRUG
import DrugIPDOneDoseOrderView from "issara-sdk/apis/DrugIPDOneDoseOrderView_apps_TPDM";
import DrugIPDStatOrderView from "issara-sdk/apis/DrugIPDStatOrderView_apps_TPDM";
import DrugIPDHomeOrderView from "issara-sdk/apis/DrugIPDHomeOrderView_apps_TPDM";
import DrugOneDayOrderView from "issara-sdk/apis/DrugOneDayOrderView_apps_TPDM";
import DrugContinuePlanForIPDHomeList from "issara-sdk/apis/DrugContinuePlanForIPDHomeList_apps_TPD";

// Drug Verify
import CheckDrugDuplicateMedReconcileView from "issara-sdk/apis/CheckDrugDuplicateMedReconcileView_apps_TPD";

// OPERATING DRUG
import DrugOperatingOrderView from "issara-sdk/apis/DrugOperatingOrderView_apps_TPDM";

import DoctorPendingTaskDrugOrderList from "issara-sdk/apis/DoctorPendingTaskDrugOrderList_apps_DPO";
// Stock Dispense
import ProductStockList from "issara-sdk/apis/ProductStockList_core";
import DrugOrderItemDispensePlanList from "issara-sdk/apis/DrugOrderItemDispensePlanList_apps_TPD";
import DrugOrderItemDispensePlanUpdate from "issara-sdk/apis/DrugOrderItemDispensePlanUpdate_apps_TPD";

// utils
import { v4 as uuidv4 } from "uuid";
import { beToAd, formatDate } from "react-lib/utils/dateUtils";
import { displayPDF } from "react-lib/utils/index";
import moment from "moment";
import { PDFDocument } from "pdf-lib";
import { base64toBlob } from "react-lib/apps/HISV3/common/CommonInterface";

// interface
import * as ModPlanningI from "../common/ModPlanningInterface";
import * as ModSolventSelectionI from "./ModSolventSelectionInterface";
import DrugLabInteractionCheck from "issara-sdk/apis/DrugLabInteractionCheck_apps_ADR";

import DrugInteractionCheck from "issara-sdk/apis/DrugInteractionCheck_apps_ADR";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";

export type State = {
  // CommonInterface
  masterOptions?: any;
  errorMessage?: any;
  successMessage?: any;
  loadingStatus?: any;
  buttonLoadCheck?: any;

  // common
  selectedEncounter?: any | null;
  selectedEmr?: any | null;
  selectedProgressCycle?: any | null;
  openDischargeReassessment?: any;

  // search drug
  drugSearchLoading?: boolean;
  drugSearchText?: string;
  drugSearchResult?: any[];

  // drug cont filter
  drugContinueFilter?: { status: string };
  selectedDrug?: any | null; // drug selected box
  drugOrderList?: any[];
  drugOrder?: any; // drug order for confirm
  drugOrderQueue?: any[]; // drug order queue list
  verbalDrugOrderQueue?: any[];
  selectedDrugItemDetail?: any; // drug item to edit
  selectedDrugItemDetailOptions?: any;
  selectedDrugOrderWorking?: any;
  medRecordSummary?: any;
  selectedVerbalDrugOrderWorking?: any;
  drugDose?: string | null;
  drugQty?: string | null;
  drugDuration?: string | null;
  drugDescription?: string | null;
  drugPrn?: string | null;
  drugContinuePlanItems?: any[];
  drugOrderHistoryItems?: any[];
  drugRequestList?: any;
  DrugTransferRequest?: any;
  selectedOrOrder?: any; // for Drug Operating Order

  // delivery drug
  drugDelivery?: {
    token?: string;
    employeeName?: string;
    isTransport?: boolean | null;
    error?: any;
    loading?: boolean;
    shipper?: string | null;
    trackingNo?: string | null;
    address?: string | null;
    isDrugDelivery?: boolean;
    showDrugDelivery?: boolean;
  };
  drugOrderLog?: any[];
  modNoteReject?: any;

  // mod note
  modNoteData?: {
    open?: boolean;
    error?: any;
    action?: string;
    note?: string;
    first_quantity?: number | null;
    stockUnitName?: string;
  };

  // mod Special Direction
  modSpecialDirectionConfig?: {
    showButton?: boolean;
    open?: boolean;
  };

  // mod Doctor certificate confirm
  modDoctorCertificate?: {
    open?: boolean;
    narcoticDrugType?: string;
    doctorCertificate?: string;
    openModConfirm?: boolean;
    action?: string;
    card?: string;
  };

  // mod Drug Lab Interaction note
  modDrugLabInteraction?: {
    open?: boolean;
    interactionItems?: any[];
    orderItem?: any;
    note?: string;
  };

  // mod Drug Disease
  modDrugDisease?: {
    open?: boolean;
    diseaseItems?: any[];
    orderItem?: any;
    note?: string;
  };

  // drug disease interaction modal
  modDrugInteraction?: {
    open?: boolean;
    drugItems?: any[];
    orderItem?: any;
    note?: string;
  };

  // mod show Drug order template to selected drug

  // mod confirm remove drug item
  modConfirmDeleteDrug?: {
    open?: boolean;
    product?: number | null;
    index?: number | null;
  };

  // mod remed
  modReMedication?: {
    open?: boolean;
    selectedOrders?: number[];
    selectedDrugs?: number[];
    checkedDoctor?: boolean;
    checkedDivision?: boolean;
    doctor?: number | null;
    division?: number | null;
    remedData?: any[];
  };

  // action confirm
  modConfirmAction?: {
    open?: boolean;
    action?: string;
    value?: any;
    text?: string;
  };

  // Recontinue Medication Modal
  modRecontinueMed?: {
    open?: boolean;
    selectedItems?: Record<string, any>[];
    continuePlanItems?: Record<string, any>[];
  };

  // nurse order performed
  nurseOrderPerformed?: {
    modOpen?: boolean;
    selectedOneDayOrder?: any[];
    selectedContinueOrder?: any[];
    performedTime?: any;
    performedDate?: any;
    nurseName?: any;
    nurseToken?: any;
    nurseError?: any;
    nurseLoading?: any;
  };

  // drug recommendation
  modDrugRecommendation?: {
    open?: boolean;
    data?: any[];
    pdfData?: any;
  };

  // med reconcile
  ackMedReconcileId?: number | null;
  medReconcile?: any;
  medReconcileStartWard?: any;
  medReconcilePendingOrdering?: any;
  medReconcileList?: any[];
  selectedExternalDrug?: any;
  medReconcileInternalCheckedItem?: number[];
  medReconcileInternalFilter?: {
    checkedDoctor?: boolean;
    doctor?: any;
    checkedDrug?: boolean;
    drug?: any;
    checkedDate?: boolean;
    startDate?: any;
    endDate?: any;
  };
  medReconcileOrder?: any;
  openMedReconcileOrder?: boolean;
  openModSuccessOrderSummary?: {
    open?: boolean;
    message?: any;
  };
  modAllVerifyDrug?: {
    open?: boolean | null;
    drugItems?: Record<string, any>[];
    presentDrugs?: Record<string, any>[];
    adverseDrugs?: Record<string, any>[];
    interactionDrugs?: Record<string, any>[];
    interactionLabDrugs?: Record<string, any>[];
    diseaseDrugs?: Record<string, any>[];
    note?: any;
    requireNote?: Record<string, any>[];
    orderType?: string | null;
  } | null;

  medReconcileLogs?: any[];
  openMedReconcileLogs?: boolean;
  medReconcileIPDAdmitItem?: any[];
  // loading narcotic print button
  loadingNarcotic?: boolean;
  drugOrderItemEstimate?: any;
  medErrorList?: any[];
  filterMedError?: any;
  medErrorWorking?: any;
  searchUserList?: any[];
  // for set tab in CardPharmacyTask
  drugOrderTabMode?: string;
  selectedRecordViewIndex?: any;
  medReconcileCheck?: any;
  medReconcileIndex?: any;

  // drug package alert
  drugPackageAlertList?: any[]
  // Stock Mnanagement
  lotNoExpList?: any[];
} & DrugSelect.State &
  DrugOrderQueue.State &
  DrugOrderHistoryS.State &
  ModPlanningI.State &
  ModSolventSelectionI.State &
  MedErrorList.State &
  AllergyI.State &
  MedReconcileI.State &
  DrugTransferRequest.State &
  DischargeSummary.State &
  DrugTransferRequestHistoryI.State &
  DrugReturnRequestHistoryI.State &
  DrugOrderActionI.State &
  ApportmentTelepharI.State &
  StockManagementI.State &
  DrugOrderTemplateI.State &
  CardPatientQueueI.State;

export const DrugOrderInitial = {
  // CommonInterface
  errorMessage: null,
  successMessage: null,

  ...AllergyI.StateInitial,

  //
  encounter: null,
  emr: null,
  progression_cycle: null,
  qa_claim_id: null, //data.estimateResult.claim_payload.qa_claim_id,
  action: "REQUEST",
  doctor_note: "",
  order_by: null,
  claim_payload: {}, //data.estimateResult.claim_payload,
  images: [],
  items: [], //data.estimateResult.items,
  document_images: [],
  allowed_actions: [
    "POSTPONE",
    "UNPOSTPONE",
    "EDIT",
    "VERIFY",
    "PRINT",
    "REJECT",
    "CANCEL",
    "DIVIDE",
    "BORROW",
  ],
  status_label: "",
  order_status_name: "",
  for_appointment: null,
  ref_doctor_order: null,
  nurse_status: "รอรับคำสั่ง",
  initial_order_status: null,
  redirect_service: null,
  confirm_duplicated_drugs: false,
  order_div: "1",
  out_perform_div: null,
  out_perform_div_name_code: "",
  is_out_perform_div: false,
  doctor_certificate_no: "",
  drugOrderTabMode: "",
};

export const StateInitial: State = {
  // CommonInterface
  masterOptions: {},
  errorMessage: null,
  successMessage: null,

  // search drug
  drugSearchLoading: false,
  drugSearchText: "",
  drugSearchResult: [],
  // drug cont filter
  drugContinueFilter: { status: "only_open" },
  //
  // DrugSelect: null,
  selectedDrug: null,
  drugOrderList: [],
  drugOrder: DrugOrderInitial,
  drugDose: null,
  drugQty: null,
  drugDuration: null,
  drugDescription: null,
  drugPrn: null,
  drugContinuePlanItems: [],
  drugOrderHistoryItems: [],
  drugRequestList: [],
  selectedDrugItemDetail: {}, // drug item to edit
  selectedDrugItemDetailOptions: {}, // drug options to edit

  // delivery drug
  drugDelivery: {
    token: "",
    employeeName: "",
    isTransport: null,
    error: null,
    loading: false,
    shipper: null,
    trackingNo: null,
    address: null,
    isDrugDelivery: false,
    showDrugDelivery: false,
  },
  drugOrderLog: [],
  // mod note
  modNoteData: {
    open: false,
    error: null,
    action: "",
    note: "",
    first_quantity: null,
    stockUnitName: "",
  },
  // mod Special Direction
  modSpecialDirectionConfig: {
    showButton: false,
    open: false,
  },
  // mod Doctor Certificate check
  modDoctorCertificate: {
    open: false,
    narcoticDrugType: "",
    doctorCertificate: "",
    openModConfirm: false,
  },

  // mod Drug Lab Interaction confirm
  modDrugLabInteraction: {
    open: false,
    interactionItems: [],
    orderItem: {},
    note: "",
  },

  modDrugDisease: {
    open: false,
    diseaseItems: [],
    orderItem: {},
    note: "",
  },

  // mod Drug Interaction
  modDrugInteraction: {
    open: false,
    drugItems: [],
    orderItem: {},
    note: "",
  },
  modConfirmDeleteDrug: {
    open: false,
    product: null,
    index: null,
  },
  modReMedication: {
    open: false,
    selectedOrders: [],
    selectedDrugs: [],
    checkedDoctor: false,
    checkedDivision: false,
    doctor: null,
    division: null,
    remedData: [],
  },

  modRecontinueMed: {
    open: false,
    selectedItems: [],
    continuePlanItems: [],
  },

  modConfirmAction: {
    open: false,
    action: "",
    value: null,
    text: "",
  },

  modDrugRecommendation: {
    open: false,
    data: [],
    pdfData: null,
  },

  // nurse order performed
  nurseOrderPerformed: {
    modOpen: false,
    selectedOneDayOrder: [],
    selectedContinueOrder: [],
    performedTime: moment().format("HH:mm"),
    performedDate: formatDate(moment()),
    nurseName: null,
    nurseToken: null,
    nurseError: null,
    nurseLoading: null,
  },

  // med reconcile
  ackMedReconcileId: null,
  medReconcile: null,
  medReconcileStartWard: null,
  medReconcilePendingOrdering: null,
  medReconcileList: [],
  selectedExternalDrug: null,
  medReconcileInternalCheckedItem: [],
  medReconcileInternalFilter: {
    checkedDoctor: false,
    doctor: null,
    checkedDrug: false,
    drug: null,
    checkedDate: false,
    startDate: null,
    endDate: null,
  },
  medReconcileIPDAdmitItem: [],
  medReconcileOrder: null,
  openMedReconcileOrder: false,
  medReconcileLogs: [],
  openMedReconcileLogs: false,

  // loading narcotic print button
  loadingNarcotic: false,
  drugOrderItemEstimate: {},
  ...DrugSelect.StateInitial,
  ...ModPlanningI.StateInitial,
  ...ModSolventSelectionI.StateInitial,
  ...DrugOrderQueue.StateInitial,
  ...DrugOrderHistoryS.StateInitial,
  ...MedErrorList.StateInitial,
  ...DrugTransferRequest.StateInitial,
  ...DischargeSummary.StateInitial,
  ...DrugTransferRequestHistoryI.StateInitial,
  ...DrugReturnRequestHistoryI.StateInitial,
  ...DrugOrderActionI.StateInitial,
  ...ApportmentTelepharI.StateInitial,
  ...StockManagementI.StateInitial,
  ...DrugOrderTemplateI.StateInitial,
  ...MedReconcileI.StateInitial,
  ...CardPatientQueueI.StateInitial,
};

export type Event =
  | { message: "RunSequence"; params: any }
  | { message: "GetMasterData"; params: {} }
  | { message: "GetDrugOrderList"; params: { pk: number; orderType: string } }
  | { message: "ClearDrugOperatingOrderList"; params: {} }
  | { message: "SetDrugOrder"; params: { item: any } }
  | { message: "DrugSearch"; params: { keyword: string } }
  | { message: "AddToOrder"; params: {} }
  | { message: "HandleChangeDrugOrderDetail"; params: {} }
  | {
    message: "DeleteDrugOrderItem";
    params: { id: number; orderType: string };
  }
  // Drug Cont
  | {
    message: "PreviewDrugContinuePlan";
    params: { name: string; value: string; card: string };
  }
  | { message: "ClaimDrugContinuePlan"; params: {} }
  | { message: "CheckDrugGroupDuplicate"; params: {} }
  | { message: "ConfirmDrugContinueOrder"; params: {} }
  | {
    message: "EditDrugContinuePlan";
    params: { action: string; card: string };
  }
  | { message: "NoteAndVerifyAndCancelDrugContinuePlan"; params: {} }
  | { message: "ListDrugContinueActionLog"; params: { pk: number } }
  | { message: "SetModNoteData"; params: { name: string; value: any } }
  | {
    message: "SetModSpecialDirectionConfig";
    params: { name: string; value: any };
  }
  | { message: "CheckShowButtonSolvent"; params: any }
  //
  | { message: "ConfirmDrugOrder"; params: {} }
  | { message: "GetDrugOrderQueue"; params: {} }
  | { message: "SelectDrugOrderWorking"; params: { drugOrder: any } }
  | { message: "SelectDashboardTelephar"; params: { drugOrder: any } }
  | { message: "SelectTelepharQueue"; params: { drugOrder: any } }
  | { message: "SelectVerbalDrugOrderWorking"; params: { drugOrder: any } }
  | { message: "ClearDrugOrderForm"; params: { hard?: boolean } }
  // | { message: "SelectEncounter"; params: any }
  | { message: "GetDrugContinuePlan"; params: any }
  | { message: "SetDrugContinueOptions"; params: { drugResp: any } }
  | { message: "SetDrugContinuePlan"; params: {} }
  | { message: "SetDrugContinueFilter"; params: { status: string } }
  | { message: "DrugOrderHistory"; params: any }
  | {
    message: "SetDrugOrderItemsData";
    params: { index: number; items: any[] };
  }
  // Med Reconcile
  | {
    message: "OpenMedReconcileFromWarning";
    params: { encounter: any; selecteRecordViewIndex: number };
  }
  | { message: "ClearMedReconcile"; params: {} }
  | { message: "GetMedReconcile"; params: { encounter: any } }
  | { message: "SetMedReconcile"; params: {} }
  | { message: "GetMedReconcileInternal"; params: {} }
  | { message: "GetMedReconcileLog"; params: { medReconcileId: number } }
  | { message: "MedReconcileDrugOrder"; params: {} }
  | {
    message: "HandleGetDetailForExternalDrug";
    params: { selectedDrug?: any };
  }
  | { message: "GetDrugTransferRequestList"; params: {} }
  // Medication Error List
  | { message: "GetSearchUserList"; params: { search: string } }
  | { message: "GetSearchDivisionList"; params: { search: string } }
  | { message: "GetSearchUserDivisionList"; params: { search: string } }
  | { message: "GetMedicationErrorDetail"; params: { id: number } }
  // CardDrugClaimQA
  | {
    message: "CreateDrugOrderItemClaim";
    params: {
      claim_payload: any;
      emr: number;
      encounter: number;
      order_type: string;
      product: number;
      quantity: number;
    };
  }
  | {
    message: "HandleSavePostponeAnswer";
    params: { data: any; index: number };
  }
  | { message: "HandleCloseDrugOrderEstimate"; params: {} }
  | { message: "HandleCheckDoctorCertificate"; params: {} }
  | { message: "PrintDrugOrderFromDoctor"; params: {} }
  | { message: "HandlePrintNarcoticForm"; params: {} }
  | { message: "HandlePrintPsychotropicForm"; params: {} }
  | { message: "HandleSetFilterMedError"; params: { data: any } }
  | { message: "GetMedicationErrorList"; params: { data: any } }
  | { message: "HandleSaveMedErrorList"; params: { action: string; data: any } }
  | {
    message: "CheckHaveQuestion";
    params: { estimate: any; payload: any; index: number };
  }
  | {
    message: "HandleSetAnswerDrugOrderItem";
    params: { index: number; answer: any };
  }
  | { message: "HanleOnLogDrugOrder"; params: {} }

  // Medication Error Working
  | { message: "GetMedicationErrorWorking"; params: { data: any } }
  | { message: "GetMedicationErrorTypeDetailList"; params: { type: number } }
  | { message: "HandleSetMedErrorWorking"; params: {} }
  // Mod drug order template
  | { message: "HandleDrugOrderTemplate"; params: { action?: string } }
  | { message: "GetDrugItemDetail"; params: { selectedDrugId?: number } }
  | {
    message: "HandleOnRemoveDrug";
    params: { removeDrugId?: number; drugIndex?: number };
  }
  | { message: "HandleGetDeliverDrugTokenization"; params: { code?: string } }
  // Reprint
  | {
    message: "PutDrugOrderItemPrintLabel";
    params: { id: number; data: any; card: string };
  }
  // DrugOrderWorking
  | {
    message: "UpdateDrugOrderWorking";
    params: { data: any };
  }
  | { message: "HandleReMedication"; params: { action?: string | null } }
  | { message: "HandleNurseOrderPerformed"; params: { action?: string | null } }
  | { message: "GetIPDOrderSummary"; params: {} }
  | { message: "HandleModDrugLabInteraction"; params: {} }
  | { message: "HandleModDrugDisease"; params: {} }
  | { message: "HandleModDrugInteraction"; params: {} }
  | { message: "HandleModRecontinueMedication"; params: {} }
  | { message: "HandleGetDrugDetail"; params: {} }
  | { message: "HandleDrugRecommendation"; params: { action?: string | null } }
  | { message: "GetLotNoExp"; params: { productId: number; orderItem: number } }
  | {
    message: "UpdateLotNoExp";
    params: { card: string; data: any; orderItem: number; action: string };
  }
  | { message: "AllVerifyDrug"; params: { action: string } }
  | { message: "DrugPackageAddToOrder"; params: { items: any[]; orderType: string; card: string; } }
  | { message: "GetMedReconcileCheck"; params: {} }
  // selectedDrugOrderWorking
  | DrugOrderHistoryS.Event
  | DrugOrderQueue.Event
  | DrugTransferRequest.Event
  | DrugTransferRequestHistoryI.Event
  | DrugReturnRequestHistoryI.Event
  | DrugOrderActionI.Event
  | ApportmentTelepharI.Event
  | StockManagementI.Event
  | DrugOrderTemplateI.Event
  | CardPatientQueueI.Event;

export type Data = {
  division?: number;
  masterUnits?: any[];
  masterRoutes?: any[];
  masterSites?: any[];
  masterFrequencies?: any[];
  masterMethods?: any[];
  masterData?: { [name: string]: any };
  // estimateResult?: any,
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const CARD_DUPLICATE_REASON = "CardDuplicateReason";
export const CARD_DUPLICATE_CLASSIFICATION_REASON =
  "CardDuplicateClassificationReason";

// Drug order (type-specific) ==============================================
const DrugAPI: { [key: string]: any } = {
  // OPD
  HOME_OPD: DrugOPDHomeOrderView,
  ONE_DOSE_OPD: DrugOPDOneDoseOrderView,
  STAT_OPD: DrugOPDStatOrderView,
  // IPD
  ONE_DOSE_IPD: DrugIPDOneDoseOrderView,
  STAT_IPD: DrugIPDStatOrderView,
  HOME_IPD: DrugIPDHomeOrderView,
  ONE_DAY_IPD: DrugOneDayOrderView,
  OPERATING: DrugOperatingOrderView,
};

export const EstimateMap: { [key: string]: any } = {
  // OPD
  HOME_OPD: "HOME_OPD",
  ONE_DOSE_OPD: "ONE_DOSE",
  STAT_OPD: "STAT",
  // IPD
  HOME_IPD: "HOME_IPD",
  ONE_DOSE_IPD: "ONE_DOSE",
  STAT_IPD: "STAT",
  ONE_DAY_IPD: "ONE_DAY",
  // OPERATION
  OPERATING: "OPERATING",
  // ANES
  ANESTHESIA: "ANESTHESIA",
  CONTINUE_PLAN: "CONTINUE_PLAN",
};

export const ONE_DAY_TYPE = ["STAT_IPD", "STAT_OPD", "ONE_DOSE_OPD", "ONE_DAY", "ONE_DOSE_IPD", "ONE_DAY_IPD"]

export const ClearDrugOperatingOrderList: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    drugOrderList: [],
    drugOrder: {
      ...DrugOrderInitial,
      order_div: state.selectedEncounter?.division || controller.data.division,
    },
  });
};

export const GetDrugOrderList: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!Object.keys(DrugAPI).includes(params?.orderType))
    return console.log("params.orderType is not set correctly");

  // First get drug order list
  let drugOrderList: any[] = [null, true, true];

  const drugOrderApi = (
    {
      HOME_OPD: DrugOrderDetailList.retrieve_drug_opd_home_by_emr,
      ONE_DOSE_OPD: params.progressCycle
        ? DrugOrderDetailList.retrieve_drug_opd_one_dose_by_progression_cycle
        : DrugOrderDetailList.retrieve_drug_opd_one_dose_by_emr,
      STAT_OPD: DrugOrderDetailList.retrieve_drug_opd_stat_by_progression_cycle,
      HOME_IPD: DrugOrderDetailList.retrieve_drug_ipd_home_by_emr,
      ONE_DOSE_IPD: DrugOrderDetailList.retrieve_drug_ipd_one_dose_by_emr,
      ONE_DAY_IPD: DrugOrderDetailList.retrieve_drug_ipd_one_day_by_emr,
      STAT_IPD: DrugOrderDetailList.retrieve_drug_ipd_stat_by_emr,
      OPERATING:
        params.apiType === "pks"
          ? DrugOrderDetailList.retrieve_drug_operation_by_pks
          : DrugOrderDetailList.retrieve_drug_operation_by_emr,
    } as any
  )[params.orderType];

  if (!!drugOrderApi) {
    drugOrderList = await drugOrderApi({
      pk: params.pk,
      apiToken: controller.apiToken,
    });
  }

  // Case can't get order list
  // console.log(drugOrderList[1] ? drugOrderList[1] : drugOrderList[0]);
  if (drugOrderList[1] || !drugOrderList[0]?.items) {
    controller.setState({
      drugOrderList: [],
      drugOrder: {
        ...DrugOrderInitial,
        order_div:
          state.selectedEncounter?.division || controller.data.division,
      },
    });

    return console.log("Error getting drugorder list for pk:", params.pk);
  }

  // Filter out cancelled items
  // #54565
  const items = drugOrderList[0].items.filter((item: any) => item.status_name);
  // const items = drugOrderList[0].items.filter(
  //   (item: any) => item.status_name !== "CANCELED"
  // );
  if (items.length === 0) {
    controller.setState({
      drugOrderList: items,
      drugOrder: {
        ...DrugOrderInitial,
        order_div:
          state.selectedEncounter?.division || controller.data.division,
      },
    });
    return console.log("No active drug order");
  }

  // Then get drug order detail
  const drugDetailList = await DrugAPI[params.orderType].get({
    pk:
      Number.isInteger(state.drugOrder?.id) &&
        items.map((item: any) => item.id).includes(state.drugOrder?.id)
        ? state.drugOrder?.id
        : items[0].id,
    apiToken: controller.apiToken,
  });

  if (
    drugDetailList[1] ||
    !drugDetailList[0].items ||
    drugDetailList[0].items.length === 0
  ) {
    controller.setState({
      drugOrderList: items,
      drugOrder: {
        ...DrugOrderInitial,
        order_div:
          state.selectedEncounter?.division || controller.data.division,
      },
    });
  
    return console.log("Error getting drugdetail list for pk:", items[0].id);
  }

  console.log("drugDetailList", items, drugDetailList[0]);

  const drugDetail = drugDetailList[0];

  if (drugDetail?.items) {
    drugDetail.items = formatDrugEstimate(drugDetail);
  }

  const orderBy = await getDefaultOrderBy(controller, params)

  controller.setState({
    drugOrderList: items,
    drugOrder: {
      ...drugDetail,
      ...orderBy
    },
  });
};

const PutDoctorOrderCancel: Handler = async (controller, params) => {
  const response = await DoctorOrderCancel.put({
    pk: params.id,
    data: {
      action: "REQUEST",
      items: [params.item],
      order_status: "DRAFT",
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });

  return response;
};

export const DeleteDrugOrderItem: Handler = async (controller, params) => {
  await PutDoctorOrderCancel(controller as any, params);

  const state = controller.getState();

  GetDrugOrderList(controller, {
    pk: [
      "HOME_OPD",
      "HOME_IPD",
      "ONE_DOSE_IPD",
      "ONE_DAY_IPD",
      "STAT_IPD",
    ].includes(params.orderType)
      ? state.selectedEmr.id
      : ["ONE_DOSE_OPD", "STAT_OPD"].includes(params.orderType)
        ? state.selectedProgressCycle.id
        : state.selectedEmr.id,
    orderType: params.orderType,
  });
};

export const SetDrugOrder: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!Object.keys(DrugAPI).includes(params?.orderType))
    return console.log("params.orderType is not set correctly");

  if (Number.isInteger(params?.item?.id)) {
    const drugDetailList = await DrugAPI[params.orderType].get({
      pk: params?.item?.id,
      apiToken: controller.apiToken,
    });
    console.log(drugDetailList[1] ? drugDetailList[1] : drugDetailList[1]);

    if (drugDetailList[0]) {
      const drugDetail = drugDetailList[0];

      if (drugDetail?.items) {
        drugDetail.items = formatDrugEstimate(drugDetail);

        let isNarcotic =
          drugDetail.items.filter((item: any) => item.is_narcotic).length > 0;
        let isPsychotropic =
          drugDetail.items.filter((item: any) => item.is_psychotropic).length >
          0;

        if (params?.orderType === "HOME_IPD") {
          drugDetail.items =
            await HandleCheckDrugDuplicatedMedReconcileIPDAdmit(controller, {
              items: drugDetail.items,
            });
        }

        // format dose set
        let drugItems = [...(drugDetail?.items || [])].map((item: any) => {
          let dose = stringToFixedValueFloat(item.dose)
          let quantity = stringToFixedValueFloat(item.quantity)
          return { ...item, dose, quantity }
        })

        let doctorVerbal = null
        if(drugDetail.order_type === "VERBAL"){
          const doctorDetail = await DoctorDetail.retrieve({
            apiToken: controller.apiToken,
            pk: drugDetail?.order_by 
          })
          if(doctorDetail?.[0]){
            let targetDoctor = doctorDetail[0]
            doctorVerbal = {first_name: targetDoctor.first_name, last_name: targetDoctor.last_name, code: targetDoctor.code}
          }
        }

        controller.setState({
          drugOrder: {
            ...drugDetail,
            items: drugItems,
            isShowNarcotic: isNarcotic,
            isShowPsychotropic: isPsychotropic,
            ...(doctorVerbal ? {selectedOrderBy: doctorVerbal}: {})
          },
        });
      }
    } else {
      controller.setState({
        drugOrder: {
          ...DrugOrderInitial,
          order_div: state.selectedEncounter?.division,
        },
      });
    }
  } else {
    let result: any[] = [null, null, null]

    if (state.selectedEncounter?.is_appointment) {
      result = await DrugPackageAlertList.list({
        apiToken: controller.apiToken,
        params: { encounter: state.selectedEncounter?.id, require_alert: true }
      })
    }

    const items = result?.[0]?.items || []

    controller.setState({
      drugPackageAlertList: items,
      drugOrder: {
        ...DrugOrderInitial,
        order_div: state.selectedEncounter?.division,
      },
    });
  }
};

const formatDrugEstimate = (drugDetail: any) => {
  return drugDetail.items.map((item: any) => {
    return formatClaimPayload(drugDetail, item);
  });
};

const formatClaimPayload = (drugDetail: any, item: any) => {
  console.log("!!!!!! formatClaimPayload: !!!!! drugDetail: ", drugDetail);
  console.log("!!!!!! formatClaimPayload: !!!!! item: ", drugDetail);
  const productId = item.product;

  const claim_result = drugDetail.claim_payload?.claim_results
    ?.filter((item: any) => item.product_id === productId)
    ?.slice(-1)?.[0];

  const payload = drugDetail.claim_payload?.payloads
    ?.filter((item: any) => item.product_id === productId)
    ?.slice(-1)?.[0];

  return {
    ...item,
    estimate: {
      // ...drugDetail.claim_payload,
      claim_payload: {
        ...drugDetail.claim_payload,
        have_question: payload ? payload.have_question : false,
        claim_results: claim_result ? [claim_result] : [],
        payloads: payload ? [payload] : [],
      },
      items: [item],
    },
  };
};

export const CheckDrugGroupDuplicate: Handler = async (controller, params) => {
  let state = controller.getState();

  let data: any = setDrugContinueData(controller, params.action);
  data.doctor_certificate_no = "";
  console.log("data: ", data);

  let [checkDrug, errorCheckDrug] = await CheckDrugDuplicateDrugGroupView?.post(
    {
      apiToken: controller.apiToken,
      data: {
        encounter: state.selectedEncounter?.id,
        selected_drug: state.selectedDrug?.id,
        current_drug_order_type: EstimateMap[params?.orderType],
        current_order_items: [
          ...(state.drugContinuePlanItems || []).map((item: any) => ({
            ...item,
            product: item.drug,
          })),
          // {
          //   ...data,
          //   ...(params?.note_group_duplicated && {
          //     note_group_duplicated: params?.note_group_duplicated,
          //   }),
          // },
        ],
      },
      extra: {
        division: controller.data.division,
      },
    }
  );

  if (errorCheckDrug) {
    if (errorCheckDrug?.code === "DUPLICATE_DRUG_GROUP") {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [CARD_DUPLICATE_CLASSIFICATION_REASON]: errorCheckDrug,
        },
        successMessage: { ...state.successMessage, [params.card]: null },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
      return;
    }
  } else if (errorCheckDrug == null) {
    ConfirmDrugContinueOrder(controller, { ...params, isCheckDrugGroup: true });
  }
};

export const AddToOrder: Handler = async (controller, params) => {
  let state = controller.getState();
  console.log("AddToOrder params: ", params);
  console.log("AddToOrder state.selectedDrug", state.selectedDrug);

  if (!Object.keys(EstimateMap).includes(params?.orderType))
    return console.log("params.orderType is not set correctly");

  if (state.selectedEncounter === null)
    return console.log("No selected encounter");

  let items = [];
  // on selected drug order set
  if (params?.drugItems) {
    items = params.drugItems;
  }
  // on edit drug detail
  else if (params?.editItem) {
    items = [
      {
        ...(state.drugOrder?.items?.[params.drugIndex] || {}),
        ...params.editItem,
      },
    ];
  }
  // on add to drug order
  else {
    console.log("AddToOrder Add To DrugOrder !!! ");

    const startDate = state.DrugSelectSequence?.selectedDate;

    let drugItemForSet = {
      ...state.selectedDrug,
      product: state.selectedDrug.id,
      dose: state.drugDose || 0,
      quantity: state.drugQty || 0,
      duration: state.drugDuration || 0,
      description: state.drugDescription || "",
      prn: state.drugPrn || "",
      unit: state.DrugSelectSequence?.selectedDrugUnitId,
      site: state.DrugSelectSequence?.selectedDrugSiteId || "",
      route: state.DrugSelectSequence?.selectedDrugRouteId,
      frequency: state.DrugSelectSequence?.selectedDrugFrequencyId,
      method: state.DrugSelectSequence?.selectedDrugMethodId,
      is_intravaneous: state.selectedDrug?.original?.is_intravaneous || false,
      start_date: beToAd(startDate)?.format("YYYY-MM-DD") || null,
      end_date: beToAd(startDate)
        ? beToAd(startDate)
          ?.add(
            state.drugDuration
              ? parseInt(state.drugDuration as string) - 1
              : 0,
            "days"
          )
          .format("YYYY-MM-DD")
        : null,
    };
    console.log("AddToOrder drugItemForSet: ", drugItemForSet);
    // for case add new drug only
    delete drugItemForSet["id"]; // this can't be null cause it will not pass estimate api
    delete drugItemForSet.original;

    // Issue 59228
    if (params?.isCheckDrugGroup && !params?.noteGroupDuplicated) {
      let currentDrugItems: any[] = (state.drugOrder?.items || []).map(
        (drugOrderItem: any) => {
          let { original, ...rest } = drugOrderItem || {};
          return rest;
        }
      );

      let [checkDrug, errorCheckDrug] =
        await CheckDrugDuplicateDrugGroupView?.post({
          apiToken: controller.apiToken,
          data: {
            encounter: state.selectedEncounter?.id,
            selected_drug: state.selectedDrug?.id,
            current_drug_order_type: EstimateMap[params?.orderType],
            current_order_items: [
              ...currentDrugItems,
              // {
              //   ...drugItemForSet,
              //   ...(params?.note_group_duplicated && {
              //     note_group_duplicated: params?.note_group_duplicated,
              //   }),
              // },
            ],
          },
          extra: {
            division: controller.data.division,
          },
        });

      console.log("AddToOrder errorCheckDrug: ", errorCheckDrug);
      console.log("AddToOrder checkDrug: ", checkDrug);

      if (errorCheckDrug) {
        if (errorCheckDrug?.code === "DUPLICATE_DRUG_GROUP") {
          controller.setState({
            errorMessage: {
              ...state.errorMessage,
              [CARD_DUPLICATE_CLASSIFICATION_REASON]: errorCheckDrug,
            },
            successMessage: { ...state.successMessage, [params.card]: null },
            buttonLoadCheck: {
              ...state.buttonLoadCheck,
              [`${params.card}_${params.action}`]: "ERROR",
            },
          });
          return;
        }
      }

      state = controller.getState();
    } else if (params?.noteGroupDuplicated) {
      drugItemForSet.note_group_duplicated = params?.noteGroupDuplicated;
      if (state.errorMessage?.[CARD_DUPLICATE_CLASSIFICATION_REASON]) {
        drugItemForSet.duplicateReason =
          state.errorMessage?.[CARD_DUPLICATE_CLASSIFICATION_REASON];
      }
      console.log(
        " errorMessage.CARD_DUPLICATE_CLASSIFICATION_REASON",
        state.errorMessage?.[CARD_DUPLICATE_CLASSIFICATION_REASON]
      );
    }

    items = [{ ...drugItemForSet }];

    const drugSelectSeq: any = { ...state.DrugSelectSequence };
    let modSolventConfig: any = { ...state.modSolventSelectionConfig };
    if (
      modSolventConfig?.solvent &&
      state.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE
    ) {
      const drug_admixture_order_key = uuidv4();

      items[0].admin_time = [];
      items[0].day_in_week = [];
      items[0].default_solvent = drugSelectSeq?.default_solvent;
      items[0].default_solvent_quantity =
        drugSelectSeq?.default_solvent_quantity;
      items[0].ended = "";
      items[0].extra_note = "";
      items[0].is_dispensed = false;
      items[0].is_intravaneous = drugSelectSeq?.is_intravaneous;
      items[0].is_solvent = false;
      items[0].iv_rate =
        modSolventConfig?.mixture_iv_rate ||
        drugSelectSeq?.default_iv_rate ||
        0;
      items[0].started = "";
      items[0].drug_admixture_order_key = drug_admixture_order_key;

      items.push({
        ...modSolventConfig,
        admin_time: [],
        concentration_unit: modSolventConfig?.concentration_unit || null,
        day_in_week: [],
        dose: 0,
        drug_drip_in: modSolventConfig?.drug_drip_in || null,
        drug_drip_in_unit: modSolventConfig?.drug_drip_in_unit || null,
        ended: "",
        extra_note: "",
        is_solvent: true,
        mixture_iv_rate:
          modSolventConfig?.mixture_iv_rate ||
          drugSelectSeq?.default_iv_rate ||
          0,
        product: modSolventConfig.solvent,
        product_name: modSolventConfig.selectedSolvent.full_name,
        quantity: modSolventConfig?.solvent_quantity || 1,
        started: "",
        drug_admixture_order_key: drug_admixture_order_key,
      });
    }
  }

  console.log("AddToOrder items: ", items);
  const estimate = await DrugOrderEstimateView.post({
    apiToken: controller.apiToken,
    data: {
      items: items,
      drug_type: EstimateMap[params.orderType],
      encounter: state.selectedEncounter.id,
      emr: state.selectedEmr.id,
      progression_cycle: state.selectedProgressCycle?.id,
      claim_payload: state.drugOrder.claim_payload,
    },
    extra: {
      division: controller.data.division,
    },
  });

  if (estimate[1] || !estimate[0].items || estimate[0].items.length === 0) {
    params.onFailed?.(estimate[1]);

    /// Close Modal
    let { [CARD_DUPLICATE_CLASSIFICATION_REASON]: e, ...remainError } =
      state.errorMessage || {};
    if (remainError) {
      controller.setState({
        errorMessage: { ...remainError, [params.card]: { error: estimate[1] } },
      });
    }

    console.warn(`Error: ${estimate[1].toString()}`);
    // alert(estimate[1].toString());
    return;
  }

  console.log("AddToOrder estimate: ", estimate);
  const productId = items[0]?.product || items[0]?.id;

  const claim_result = estimate[0].claim_payload?.claim_results
    ?.filter((item: any) => item.product_id === productId)
    ?.slice(-1)?.[0];
  console.log("AddToOrder claim_result: ", claim_result);

  const payload = estimate[0].claim_payload?.payloads
    ?.filter((item: any) => item.product_id === productId)
    ?.slice(-1)?.[0];
  console.log("AddToOrder payload: ", payload);

  let claimPayload = state.drugOrder?.claim_payload || {};
  if (!Object.keys(claimPayload).length) {
    claimPayload = estimate[0]?.claim_payload;
  } else {
    if (payload) {
      claimPayload = {
        ...claimPayload,
        claim_results: [...claimPayload.claim_results, claim_result],
        payloads: [...claimPayload.payloads, payload],
      };
    }
  }

  let estimateDetail = estimate[0];

  estimateDetail = {
    ...estimateDetail,
    claim_payload: {
      ...estimateDetail.claim_payload,
      ...(payload != null &&
        "have_question" in payload && { have_question: payload.have_question }),
      // have_question: payload ? payload.have_question : false,
      claim_results: claim_result ? [claim_result] : [],
      payloads: payload ? [payload] : [],
    },
  };
  console.log("AddToOrder estimateDetail: ", estimateDetail);

  let drugItems = state.drugOrder.items || [];
  console.log("AddToOrder drugItems: ", drugItems);

  // if drug more than 1 items
  console.log("AddToOrder params.drugItems: ", params.drugItems);
  if (params.drugItems) {
    drugItems = drugItems.concat(
      estimate[0].items.map((drug: any) => {
        return {
          ...drug,
          title: `${drug.product_name} ${drug.price_unit} ฿`,
          estimate: estimateDetail,
        };
      })
    );
  } // on edit drug detail
  else if (params?.editItem) {
    const { id, ...drugItem } = estimate[0].items[0];
    drugItems = drugItems.map((drug?: any, index?: number) => {
      return params.drugIndex === index
        ? {
          ...drug,
          ...drugItem,
          title: `${drugItem.product_name} ${drugItem.price_unit?.toFixed(2) || ""
            } ฿`,
          estimate: estimateDetail,
        }
        : { ...drug };
    });

    console.log("AddToOrder drugItems: ", drugItems);
    console.log("AddToOrder estimateDetail: ", estimateDetail);
  } else {
    // เพิ่ม ตัวเดียว
    const { id, ...drugItem } = estimate[0].items[0];
    console.log("AddToOrder estimate[0].items[0]: ", estimate[0].items[0]);

    console.log("AddToOrder drugItems: ", drugItems);
    drugItems = drugItems
      .map((drug: any) => {
        let diMember = state.DrugSelectSequence.diItems.filter((di: any) =>
          di.members.includes(drug.product)
        );
        if (diMember?.length > 0) {
          return {
            ...drug,
            drug_interaction_info:
              (diMember || [])
                .map(
                  (item: any) =>
                    `${item.description}\n${item.members_name
                      .map(
                        (value: any, index: number) => `${index + 1}. ${value}`
                      )
                      .join("\n")}`
                )
                .join("\n") || "",
          };
        }
        return { ...drug };
      })
      .concat([
        // new drug
        {
          ...drugItem,
          adr_info: state.DrugSelectSequence.adrNotes || "",
          drug_interaction_info:
            (state.DrugSelectSequence.diItems || [])
              .map(
                (item: any) =>
                  `${item.description}\n${item.members_name
                    .map(
                      (value: any, index: number) => `${index + 1}. ${value}`
                    )
                    .join("\n")}`
              )
              .join("\n") || "",
          title: `${drugItem.product_name} ${drugItem.price_unit?.toFixed(2) || ""
            } ฿`,
          pregcat: state.selectedDrug.original.pregcat || null,
          estimate: estimateDetail,
        },
      ]);
    console.log("AddToOrder (concat) drugItems: ", drugItems);

    if (estimate[0]?.items?.[1]) {
      // TODO: อันนี้คืออะไร หว่า ทำไม เพิ่มได้ สูงสุด แค่ 2 ตัว หรอ ?? หรือมันคือ solvent !
      console.log("estimate[0]?.items?.[1]: ", estimate[0]?.items?.[1]);

      drugItems.push({
        ...estimate[0].items[1],
        adr_info: state.DrugSelectSequence.adrNotes || "",
        drug_interaction_info:
          (state.DrugSelectSequence.diItems || [])
            .map(
              (item: any) =>
                `${item.description}\n${item.members_name
                  .map((value: any, index: number) => `${index + 1}. ${value}`)
                  .join("\n")}`
            )
            .join("\n") || "",
        title: `${estimate[0].items[1].product_name} ${estimate[0].items[1].price_unit} ฿`,
      });
    }
  }

  if (params?.orderType === "HOME_IPD") {
    drugItems = await HandleCheckDrugDuplicatedMedReconcileIPDAdmit(
      controller,
      { items: drugItems }
    );
  }

  let { [CARD_DUPLICATE_CLASSIFICATION_REASON]: e, ...remainError } =
    state.errorMessage || {};

  controller.setState({
    drugOrder: {
      ...state.drugOrder,
      items: drugItems,
      qa_claim_id: estimate[0]?.claim_payload?.qa_claim_id || null,
      claim_payload: claimPayload,
    },
    ...(remainError && { errorMessage: remainError }),
  });

  console.log("estimateDetail: ", estimateDetail);
  console.log(
    "payload: ",
    params.editItem ? estimateDetail.claim_payload?.payloads?.[0] || {} : {}
  );
  console.log("index ", drugItems.length - 1);
  console.log("params.isCheckDrugGroup: ", params.isCheckDrugGroup);

  ClearDrugOrderForm(controller, {});
  CheckHaveQuestion(controller, {
    estimate: estimateDetail,
    payload: params.editItem
      ? estimateDetail.claim_payload?.payloads?.[0] || {}
      : {},
    index: drugItems.length - 1,
    isAdd: true,
  });
};

export const HandleChangeDrugOrderDetail: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  const frequency = controller.data.masterData?.frequency || [];
  const coversion = controller.data.masterData?.conversion || [];

  const updateState: any = {
    ...(state.DrugSelectSequence || {}),
    ...state,
  };

  if (typeof params.value !== "undefined") {
    updateState[params.key] = params.value
  }
  // console.log('updateState: ', updateState);
  // console.log('params', params)

  const dosePerDay = frequency.find(
    (item: any) => item.id === updateState.selectedDrugFrequencyId
  )?.dose_per_day;

  const data = {
    // ['STAT', 'ONE_DOSE', 'ONE_DAY'].includes(type)
    isOneDay: ONE_DAY_TYPE.includes(
      params.orderType
    ), // << -- ทำไม hardcode ว่า isOneDay
    duration: updateState.drugDuration,
    dose: updateState.drugDose,
    drugDetail: state.selectedDrug.original,
    unitId: updateState.selectedDrugUnitId,
    dosePerDay,
    coversion,
  };

  const noCalc =
    (["STAT_OPD", "STAT_IPD", "ONE_DOSE_IPD", "ONE_DOSE_OPD"].includes(
      params.orderType
    ) &&
      ["selectedDrugUnitId", "selectedDrugFrequencyId", "drugQty"].includes(params.key)) ||
    (["ONE_DAY_IPD"].includes(params.orderType) &&
      ["selectedDrugUnitId"].includes(params.key));

  if (!noCalc) {
    if (
      [
        "drugDose",
        "drugDuration",
        "selectedDrugUnitId",
        "selectedDrugFrequencyId",
      ].includes(params.key)
    ) {
      console.log("calculateQuantity !!");
      calculateQuantity(controller, {
        ...data,
        card: params.card,
      });
    } else if (["drugQty"].includes(params.key)) {
      calculateDuration(controller, {
        ...data,
        qty: updateState.drugQty,
        card: params.card,
      });
    }
  }

  if (params.stateKey === "DrugSelectSequence") {
    controller.setState({
      DrugSelectSequence: {
        ...state.DrugSelectSequence,
        [params.key]: params.value,
      },
    });
  } else {
    controller.setState({ [params.key]: params.value });
  }
};

const calculateDailyQuantity: Handler = (controller, params) => {
  let { dose, baseUnit, stockUnit, dosageUnit, stockSize, dosePerDay, card } =
    params;
  const state = controller.getState();
  let dosageSize = 0;

  if (dosageUnit) {
    if (dosageUnit === baseUnit) {
      dosageSize = 1;
    } else if (dosageUnit === stockUnit) {
      dosageSize = 1;
      stockSize = 1;
    } else {
      dosageSize = 0;
    }
  }

  dose = parseFloat(dose);
  console.log("dose: ", dose);
  stockSize = parseFloat(stockSize);
  console.log("stockSize: ", stockSize);
  dosePerDay = parseFloat(dosePerDay);
  console.log("dosePerDay: ", dosePerDay);
  dosageSize = parseFloat(dosageSize.toString());
  console.log("dosageSize: ", dosageSize);

  if (!dose) {
    return 0;
  } else if (!dosageUnit) {
    return 0;
  } else if (isNaN(dosePerDay)) {
    return 0;
  } else if (!stockSize) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [card]: {
          error:
            "ไม่สามารถคำนวณ daily quantity ได้ เนื่องจากไม่ได้ตั้งค่า stock_size ที่ drug master",
        },
      },
    });
    return 0;
  } else if (!dosageSize) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [card]: {
          error:
            "ไม่สามารถคำนวณ daily quantity ได้ เนื่องจากไม่ได้ตั้งค่า unit conversion สำหรับหน่วยที่ระบุ",
        },
      },
    });
    return 0;
  } else if (!dosePerDay) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [card]: {
          error:
            "ไม่สามารถคำนวณ daily quantity ได้ เนื่องจากไม่ได้ตั้งค่า dose_per_day สำหรับความถี่ที่ระบุ",
        },
      },
    });
    return 0;
  }

  let ret = (dose * dosageSize * dosePerDay) / stockSize;
  console.log("ret: ", ret);
  return ret;
};

const getDailyQuantity: Handler = (controller, data: any) => {
  if (!data.drugDetail || !data.drugDetail.require_unit) {
    return 0;
  }
  console.log(" getDailyQuantity", data);

  let dose = data?.dose;
  let baseUnit = data.drugDetail?.base_unit;
  let stockUnit = data.drugDetail?.stock_unit;
  let dosageUnit = data?.unitId;
  let stockSize = data?.drugDetail.stock_size;
  let dosePerDay = data?.dosePerDay;
  let card = data?.card;

  if (data?.isOneDay) {
    dosePerDay = 1;
  } else if (!data?.drugDetail?.require_frequency) {
    return 0;
  }

  return calculateDailyQuantity(controller, {
    dose,
    baseUnit,
    stockUnit,
    dosageUnit,
    stockSize,
    dosePerDay,
    card,
  });
};

const calculateQuantity: Handler = (controller, data: any) => {
  let duration = data?.duration;
  if (data?.isOneDay) {
    duration = duration || 1;
  }

  const quantity = Math.ceil(getDailyQuantity(controller, data) * duration);

  if (quantity) {
    controller.setState({ drugQty: quantity.toString() });
  }
};

const calculateDuration: Handler = (controller, data) => {
  let duration = 0;
  if (data?.isOneDay) {
    return controller.setState({ drugDuration: "1" });
  }

  let quantity = data.qty;
  duration = Math.ceil(quantity / getDailyQuantity(controller, data));

  if (duration && isFinite(duration)) {
    controller.setState({ drugDuration: duration.toString() });
  }
};

// const PreviewDrugOrder: Handler = (controller, params)=>{
//   const state = controller.getState();

//   const items: any[] = state.drugOrder?.items || [];

//   const data = {
//     claim_payload: {
//       claim_results: items[0].estimate.claim_payload.claim_results,
//       have_question: items.some(
//         (item: any) => item.estimate.claim_payload.have_question
//       ),
//       message: "",
//       payloads: items.flatMap(
//         (item: any) => item.estimate.claim_payload.payloads
//       ),
//       qa_claim_id: state.drugOrder.qa_claim_id,
//     },
//     items: items,
//     // progression_cycle: props.selectedProgressCycle?.id || null,
//     doctor_certificate_no: "",
//     drug_type: EstimateMap[params.orderType],
//     emr: state.selectedEmr?.id || null,
//     encounter: state.selectedEncounter?.id || null,
//     progression_cycle: state.selectedProgressCycle?.id || null,
//   };

//   const result = DrugOrderPreviewView.post({
//     apiToken: controller.apiToken,
//     data,
//     extra: { division: controller.data.division }
//   })
// }

export const ConfirmDrugOrder: Handler = async (controller, params) => {
  if (!Object.keys(DrugAPI).includes(params?.orderType))
    return console.log("params.orderType is not set correctly");

  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.cardKey}_${params.action}`]: "LOADING",
    },
  });

  // กรณีเภสัชกร คอนเฟิร์ม ออเดอร์ยา
  if (!state.currentDoctor?.id && (!params.note || !state.drugOrder?.order_by)) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: { showMessage: true, error: "กรุณาระบุแพทย์ผู้สั่ง" },
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "ERROR",
      },
    })
    return;
  }

  // มีกรณีที่เป็น pendingtask (verbal order) ต้อง save pending task ด้วย
  let orderResult;
  if (state.drugOrder.id) {
    const data = {
      ...state.drugOrder,
      action: "EDIT",
      ...(params?.note ? { note: params.note } : {}),
      ...(params.orderType === "OPERATING"
        ? { ref_doctor_order: state.selectedOrOrder?.id || null }
        : {}),
    };

    if (params.confirm_duplicated_drugs) {
      data.confirm_duplicated_drugs = params.confirm_duplicated_drugs;
      const duplicateList =
        state.errorMessage?.[params.card]?.error?.duplicate_list || [];

      data.items = data.items.map((item: any) => {
        const findItem = duplicateList.find((acc: any) =>
          acc.product.includes(item.product.toString())
        );

        return !!findItem
          ? {
            ...item,
            is_duplicated: true,
            first_duplicated_item: findItem.first_duplicated_item,
            duplicated_note: params.duplicated_note,
          }
          : item;
      });
    }

    data.items.forEach((item: any) => {
      if (!item.frequency) {
        delete item["frequency"];
      }
      if (!item.route) {
        delete item["route"];
      }
      if (!item.unit) {
        delete item["unit"];
      }
    });

    orderResult = await DrugAPI[params.orderType].put({
      pk: state.drugOrder.id,
      apiToken: controller.apiToken,
      data,
      extra: { division: controller.data.division },
    });
  } else {
    const data = {
      ...state.drugOrder,
      emr: state.selectedEmr.id,
      progression_cycle: state.selectedProgressCycle?.id,
      ...(params?.note ? { note: params.note } : {}),
      ...(params.orderType === "OPERATING"
        ? { ref_doctor_order: state.selectedOrOrder?.id || null }
        : {}),
    };

    if (params.confirm_duplicated_drugs) {
      data.confirm_duplicated_drugs = params.confirm_duplicated_drugs;
    }

    orderResult = await DrugAPI[params.orderType].post({
      // pk: null,
      apiToken: controller.apiToken,
      data,
      extra: { division: controller.data.division },
    });
  }
  if (orderResult[1]) {
    if (orderResult[1]?.code === "NEED_VERIFY_ORDER") {
      controller.setState({
        modDoctorCertificate: {
          ...state.modDoctorCertificate,
          open: true,
          narcoticDrugType: orderResult[1]?.detail,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
      return console.log("Error: ", orderResult[1]);
    }
    if (params?.card) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { showMessage: true, error: orderResult[1] },
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
    return console.log("Error: ", orderResult[1].toString());
  }

  controller.setState({
    errorMessage: { ...state.errorMessage, [params.card]: null },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.cardKey}_${params.action}`]: "SUCCESS",
    },
  });

  GetDrugOrderList(controller, {
    pk: [
      "HOME_OPD",
      "HOME_IPD",
      "ONE_DOSE_IPD",
      "ONE_DAY_IPD",
      "STAT_IPD",
    ].includes(params.orderType)
      ? state.selectedEmr.id
      : ["ONE_DOSE_OPD", "STAT_OPD"].includes(params.orderType) && state.selectedProgressCycle?.id
        ? state.selectedProgressCycle?.id
        : state.selectedEmr?.id,
    orderType: params.orderType,
    progressCycle: state.selectedProgressCycle?.id,
    emr: state.selectedEmr?.id
  });

  params.onSuccess?.();
};

// Drug order (no type-specific) ===========================================
export const ClearDrugOrderForm: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    // seq
    DrugSelectSequence: {
      sequenceIndex: "CheckADR",
      divisionList: state.DrugSelectSequence?.divisionList,
    },
    // search
    drugSearchLoading: false,
    drugSearchText: params?.keyword ? params.keyword : "",
    drugSearchResult: [],
    //
    selectedDrug: null,
    drugDose: null,
    drugPrn: null,
    drugDuration: null,
    drugDescription: null,
    drugQty: null,
    // planning
    planningData: null,
    // mod note
    modNoteData: null as any,
    // mod Solvent Selection
    modSolventSelectionConfig: null,
    //
    ...(params?.hard
      ? {
        drugOrderList: [],
        drugOrder: {
          ...DrugOrderInitial,
          order_div:
            state.selectedEncounter?.division || controller.data.division,
        },
      }
      : {}),
  } as any);
};

export const CheckHaveQuestion: Handler = (controller, params) => {
  const state = controller.getState();
  console.log("CheckHaveQuestion params: ", params);
  // params.estimate = 1 drug order that have items (drug item) and claim_payload from drug order preview (estimate)
  // const drugEstimate = params.estimate;
  // const items: any[] = params.estimate.items || [];
  // const claim_payload: Record<string, any> = params.estimate.claim_payload || {};
  const hideQuestion = false;
  const stateKey = params.stateKey || "drugOrder";

  if (
    params.payload?.questions?.length ===
    params.estimate?.claim_payload?.payloads?.[0]?.questions?.length
  ) {
    console.log(
      "params.estimate?.claim_payload?.payloads?.[0]?.questions?.length: ",
      params.estimate?.claim_payload?.payloads?.[0]?.questions?.length
    );
    console.log(
      "params.payload?.questions?.length: ",
      params.payload?.questions?.length
    );

    return controller.setState({
      [stateKey]: {
        ...(state as any)[stateKey],
        claim_payload: params.estimate.claim_payload || {},
        estimate: {},
        success: true,
      },
    });
  }

  if (!hideQuestion) {
    if (
      (params.estimate.claim_payload || {})?.have_question ||
      params.haveQuestion
    ) {
      // find product that have question in claim payloads
      console.log("find product that have question in claim payloads: ");
      let selectedProduct: any = null;
      if ((params.estimate.claim_payload || {})?.payloads?.length) {
        console.log(
          "params.estimate.claim_payload || {})?.payloads?.length: ",
          params.estimate.claim_payload
        );
        (params.estimate.items || []).forEach((item: any) => {
          (params.estimate.claim_payload || {})?.payloads.forEach(
            (payload: any) => {
              if (payload["product_id"] == item["product"]) {
                // selectedProduct = item['product']
                // check have question that not answer.
                if ("questions" in payload) {
                  let is_remain_question = false;
                  payload["questions"].forEach((question: any) => {
                    if (!(question["id"] in payload["answer_dict"])) {
                      is_remain_question = true;
                    }
                  });
                  if (is_remain_question) {
                    // console.log("CheckHaveQuestion: ", item)
                    selectedProduct = item["product"];
                  }
                }
              }
            }
          );
        });
      }
      // should not match this case. because have_question = true
      if (selectedProduct === null) {
        selectedProduct = (params.estimate.items || [])?.[0]["product"];
      }
      if (state.drugPermission?.config_CLM_ENABLE_CLAIM_DRUG_FRONTEND_V2) {
      } else {
        // console.log(
        //   "Check have question: ",
        //   claim_payload,
        //   selectedProduct,
        //   items,
        //   params.index
        // );
        return controller.setState({
          [stateKey]: {
            ...(state as any)[stateKey],
            isSupply: params.isSupply,
            estimate: {
              payloads: params.estimate?.claim_payload?.payloads,
              selectedProduct,
              items: params.estimate.items || [],
              original: params.estimate,
              index: params.index,
              isAdd: params.isAdd,
            },
          },
        });
      }
    } else {
      if ((params.estimate.claim_payload || {}).message) {
        // msgClaim.message = claim_payload.message;
        // msgClaim.show();
      }
      if (state.drugPermission?.config_CLM_ENABLE_CLAIM_DRUG_FRONTEND_V2) {
        // modCardClaimQA2.hide();
      } else {
        // modCardClaimQA.hide();
        return controller.setState({
          drugOrder: { ...state.drugOrder, estimate: {} },
        });
      }
    }
  }
  // else {
  // TODO: This is not Implement !!! ")
  // if (state.drugPermission?.config_CLM_ENABLE_CLAIM_DRUG_FRONTEND_V2) {
  //   // modCardClaimQA2.hide();
  // } else {
  //   // modCardClaimQA.hide();
  // }
  // }
  console.log("Clear estimate ");
  return controller.setState({
    drugOrder: { ...state.drugOrder, estimate: {} },
  });
};

export const HandleCloseDrugOrderEstimate: Handler = (controller, params) => {
  const state = controller.getState();

  controller.setState({
    [params.stateKey]: { ...(state as any)[params.stateKey], estimate: {} },
  });
};

export const DrugSearch: Handler = async (controller, params) => {
  const state = controller.getState();
  if (state.selectedDrug) {
    // If selectedDrug was set, clear drug order form and set new keyword
    ClearDrugOrderForm(controller, params);
  } else {
    // Else, just set new keyword
    controller.setState({ drugSearchText: params?.keyword });
  }

  if (params.keyword.length < 3) {
    return;
  }

  controller.setState({ drugSearchLoading: true });

  const [r, e, n] = await DrugList.list({
    params: { keyword: params.keyword, exclude_outside_drug: true },
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });

  controller.setState({ drugSearchLoading: false, drugSearchResult: r?.items || [] });

  return [r, e, n];
};

export const DrugOrderHistory: Handler = async (controller, params) => {
  const state = controller.getState();
  console.log("state.selectedEncounter", state.selectedEncounter);
  const orderHistory = await DrugOrderHistoryList.list({
    params: {
      patient: state.selectedEncounter.patient,
    },
    apiToken: controller.apiToken,
  });
  console.log(
    "orderHistory res/err",
    orderHistory[1] ? orderHistory[1] : orderHistory[0]
  );
  const history = (orderHistory[0]?.items || [])
    .filter((item: any) => item.type === "HOME_OPD")
    .flatMap((item: any) =>
      item.children.map((child: any) => ({
        ...child,
        order_time: item.order_time,
        doctor_full_name: item.doctor_full_name,
        request_division: item.request_division,
        order_status: item.status,
        order_type: item.type,
        order_id: item.order_id,
        encounter_id: item.encounter_id,
        emr_id: item.emr_id,
        patient_id: item.patient_id,
      }))
    );
  console.log("history", history);
  controller.setState({
    drugOrderHistoryItems: history,
  });
};

export const SetDrugOrderItemsData: Handler = (controller, params) => {
  const state = controller.getState();

  let items = [...state.drugOrder.items];
  items[params.index] = { ...items[params.index], ...params.items };
  controller.setState({
    drugOrder: {
      ...state.drugOrder,
      items: items,
    },
  } as any);
};

// Drug continue planning ==================================================
export const GetDrugContinuePlan: Handler = async (controller) => {
  const state = controller.getState();

  const status = state.drugContinueFilter?.status

  const [response, error, _network] = await DrugContinuePlanList.list({
    params: {
      limit: 300,
      emr: state.selectedEmr?.id,
      encounter: state.selectedEncounter?.id,
      status: state.drugContinueFilter?.status,
      is_external: false,
    },
    apiToken: controller.apiToken,
  });
  if (!error) {
    let items: any[] = response?.items || [];

    if (status === "only_open") {
      items = items.filter(
        (item) => !item.status.startsWith("OFF") && item.status !== "RENEWED"
      );
    }

    const masterData = controller.data?.masterData;
    items.map((item: any) => {
      item.unitText = masterData?.unit?.filter(
        (mas: any) => item.unit === mas.id
      )?.[0]?.text;
      item.routeText = masterData?.route?.filter(
        (mas: any) => item.route === mas.id
      )?.[0]?.text;
      item.siteText = masterData?.site?.filter(
        (mas: any) => item.site === mas.id
      )?.[0]?.text;
      item.frequencyText = masterData?.frequency?.filter(
        (mas: any) => item.frequency === mas.id
      )?.[0]?.text + ` ${item.description || ""}`;
      item.methodText = masterData?.method?.filter(
        (mas: any) => item.method === mas.id
      )?.[0]?.text;
      item.dose = stringToFixedValueFloat(item.dose)
      return item;
    });

    const state = controller.getState();

    let target: any = items.find(
      (item: any) => item?.id === state.DrugSelectSequence?.id
    );

    let selected = target ? Object.assign({}, target) : target;

    if (selected) {
      const removeKey = [
        "dose",
        "unit",
        "frequency",
        "site",
        "route",
        "method",
        "prn",
        "calculated_daily_quantity",
        "sending_quantity",
        "sending_interval",
      ];
      for (let key of removeKey) {
        delete selected[key];
      }
    }

    selected =
      //// #เมื่อกด OFF จะเข้า case นี้เนื่องจากรายการที่เลือกไว้ไม่อยู่ใน order
      !selected && state.DrugSelectSequence?.id
        ? selected
        : //// #เมื่อเพิ่มยาใหม่
        !selected && state.DrugSelectSequence?.title
          ? state.DrugSelectSequence
          : //// เมื่อ order มีรายการที่เลือก (ต้องการ set state อื่นๆ แต่เก็บข้อมูลที่กรอกไว้อยู่)
          selected
            ? { ...state.DrugSelectSequence, ...selected }
            : selected;

    controller.setState({
      drugContinuePlanItems: items,
      DrugSelectSequence: selected,
      planningData: selected?.plan_item,
    } as any);

    // await SetDrugContinueOptions(controller, { drugResp: response });
  }
};

export const SetDrugContinueOptions: Handler = (controller, params) => {
  const state = controller.getState();

  console.log("SetDrugContinueOptions", params);

  // Filter master options for selected drug
  let options: { [key: string]: any } = {};
  for (const item of [
    ["unit", "limited_dosage_units"],
    ["route", "limited_routes"],
    ["site", "limited_sites"],
    ["frequency", "limited_frequencies"],
    ["method", "limited_methods"],
  ]) {
    const itemOptions = (state.masterOptions as { [key: string]: any })[
      item[0]
    ];
    options[item[0]] =
      params.drugResp[item[1]].length === 0
        ? itemOptions
        : itemOptions.filter((option: any) =>
          params.drugResp[item[1]].includes(option.key)
        );
  }

  controller.setState({
    DrugSelectSequence: {
      ...state.DrugSelectSequence,
      // master data
      drugFrequencies: options.frequency,
      drugMethods: options.method,
      drugRoutes: options.route,
      drugSites: options.site,
      drugUnits: options.unit,
      standardAdminTimeOptions: state.masterOptions?.standardAdminTime,
    },
  });
};

export const SetDrugContinuePlan: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!params.drug) {
    const [orderRes, orderError, orderNetwork] =
      await DrugContinuePlanDetail.retrieve({
        pk: params.id,
        apiToken: controller.apiToken,
      });
    if (orderError) {
      console.log("orderError: ", orderError);
      return;
    }
    params = { ...params, ...orderRes };
  }

  const [response, _error, _network] = await DrugDetail.retrieve({
    pk: params.drug,
    apiToken: controller.apiToken,
  });

  // ModSpecialDirection
  let modSpecialConfig: any = {
    modSpecialDirectionConfig: { ...state.modSpecialDirectionConfig },
  };

  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["unit", {}],
        ["route", {}],
        ["site", {}],
        ["frequency", {}],
        ["method", {}],
        ["duplicateReasons", {}],
        ["conversion", {}],
        ["standardAdminTime", {}],
      ],
    },
  });

  const othFrequency: any[] = controller.data.masterData?.frequency?.map(
    (item: any) => {
      if (
        item?.code ===
        state.drugPermission?.config_TPD_PRESCRIPTION_FREQUENCY_OTHER_CODE
      ) {
        return item.id;
      }
      return null;
    }
  );
  if (othFrequency?.includes(params?.frequency)) {
    // modSpecialConfig.showButton = true;
  }

  controller.setState({
    drugSearchResult: [response],
    drugSearchText: response.full_name,
    selectedDrug: response,
    DrugSelectSequence: {
      // drug
      ...response,
      ...params,
    },
    planningData: params.plan_item,
    modSpecialDirectionConfig: modSpecialConfig,
  } as any);

  // Filter master options for selected drug
  await SetDrugContinueOptions(controller, { drugResp: response });

  // modSolventSelectionConfig
  await CheckShowButtonSolvent(controller);

  // action log
  await ListDrugContinueActionLog(controller, { pk: params.id });
};

export const SetDrugContinueFilter: Handler = async (controller, params) => {
  const state = controller.getState();

  await controller.setState({
    drugContinueFilter: { ...state.drugContinueFilter, status: params.status },
  } as any);

  await GetDrugContinuePlan(controller);
};

const setDrugContinueData = (controller: any, action: string) => {
  const state = controller.getState();

  let drugSelectSeq: any = { ...state.DrugSelectSequence };

  const selectedFrequency = controller.data.masterData?.frequency?.filter(
    (item: any) => {
      return item.id === drugSelectSeq?.selectedDrugFrequencyId;
    }
  );

  let item: any = {
    action: action,
    admit_time: drugSelectSeq?.admin_time || [],
    calculated_daily_quantity: drugSelectSeq?.calculated_daily_quantity,
    claim_payload: drugSelectSeq?.claim_payload,
    confirm_duplicated_drugs: drugSelectSeq?.confirm_duplicated_drugs,
    doctor: state.selectedEncounter?.doctor,
    // doctor_certificate_no
    dose: drugSelectSeq?.dose,
    drug: state.selectedDrug?.id,
    product: state.selectedDrug?.id,
    duplicated_note: "",
    emr: state.selectedEmr?.id,
    encounter: state.selectedEncounter.id,
    extra_note: drugSelectSeq?.extra_note || "",
    first_duplicated_item: drugSelectSeq?.first_duplicated_item,
    frequency: drugSelectSeq?.frequency,
    is_external: false,
    method: drugSelectSeq?.method,
    description: drugSelectSeq?.description || "",
    note: "",
    note_adverse_reaction: drugSelectSeq?.note_adverse_reaction,
    plan_item: state.planningData,
    prn: drugSelectSeq?.prn,
    qa_claim_id: drugSelectSeq?.claim_payload?.qa_claim_id,
    route: drugSelectSeq?.route,
    sending_interval: drugSelectSeq?.sending_interval || 1,
    sending_quantity: drugSelectSeq?.sending_quantity,
    site: drugSelectSeq?.site,
    solvent_product: drugSelectSeq?.solvent_product,
    standard_admin_time: drugSelectSeq?.standard_admin_time,
    unit: drugSelectSeq?.unit,
    // doctor certificate
    // doctor_certificate_no
    // quantity: state.drugQty,
    // duration: state.drugDuration,
    // note_drug_interaction: drugSelectSeq?.confirmDINote,
  };

  // feature iv compatible
  if (drugSelectSeq?.frequency && state.drugPermission?.role_DOCTOR) {
    item.solvent_quantity = selectedFrequency?.[0]?.dose_per_day || 1;
  } else {
    item.solvent_quantity = drugSelectSeq?.solvent_quantity;
  }

  // IV Compatible
  item.mixture_solvent_volume = drugSelectSeq?.mixture_solvent_volume;
  item.mixture_iv_rate = drugSelectSeq?.mixture_iv_rate;
  item.mixture_iv_rate_unit = drugSelectSeq?.mixture_iv_rate_unit;
  item.mixture_concentration_solute =
    drugSelectSeq?.mixture_concentration_solute;
  item.mixture_concentration_solvent =
    drugSelectSeq?.mixture_concentration_solvent;
  item.drug_concentration_unit = drugSelectSeq?.drug_concentration_unit;
  item.drug_drip_in = drugSelectSeq?.drug_drip_in;
  item.drug_drip_in_unit = drugSelectSeq?.drug_drip_in_unit;

  if (!isNaN(drugSelectSeq?.mixture_iv_rate)) {
    item.mixture_iv_rate = drugSelectSeq?.mixture_iv_rate;
  }
  if (parseFloat(drugSelectSeq?.mixture_iv_rate)) {
    item.mixture_iv_rate = drugSelectSeq?.mixture_iv_rate;
  }

  return item;
};

export const PreviewDrugContinuePlan: Handler = async (controller, params) => {
  let data: any = setDrugContinueData(controller, "PREVIEW");
  data.pk = 0;

  if (params) {
    data[params.name] = params.value;

    if (params.name === "frequency") {
      data.description = "";
    }
  }

  const [response, error, _network] = await DrugContinuePlanList.create({
    data: data,
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
  });

  const state = controller.getState();
  let drugSelectSeq: any = { ...state.DrugSelectSequence };

  if (params) {
    drugSelectSeq[params?.name] = params?.value; // dose, frequency, method, route, site, sending_quantity, unit

    if (params.name === "frequency") {
      drugSelectSeq.description = "";
    }
  }
  if (!error) {
    drugSelectSeq.prn = response.prn || "";
    drugSelectSeq.calculated_daily_quantity =
      // drugSelectSeq?.calculated_daily_quantity ||
      response.calculated_daily_quantity || 0;
    drugSelectSeq.sending_quantity =
      drugSelectSeq?.sending_quantity || response.sending_quantity;
    drugSelectSeq.sending_interval =
      drugSelectSeq?.sending_interval || response.sending_interval || 1;
  }

  // ModSpecialDirection
  let modSpecialConfig: any = {
    modSpecialDirectionConfig: { ...state.modSpecialDirectionConfig },
  };
  const othFrequency: any[] = controller.data.masterData?.frequency?.map(
    (item: any) => {
      if (
        item?.code ===
        state.drugPermission.config_TPD_PRESCRIPTION_FREQUENCY_OTHER_CODE
      ) {
        return item.id;
      }
      return null;
    }
  );
  if (othFrequency.includes(drugSelectSeq?.frequency)) {
    // modSpecialConfig.showButton = true;
  }

  await controller.setState({
    errorMessage: {
      ...state.errorMessage,
      [params.card]: { error: error, showMessage: false },
    },
    successMessage: { ...state.successMessage, [params.card]: null },
    DrugSelectSequence: drugSelectSeq,
    modSpecialDirectionConfig: modSpecialConfig,
  } as any);

  // modSolventSelectionConfig
  await checkOpenSolvent(controller);
  await CheckShowButtonSolvent(controller);
};

export const ClaimDrugContinuePlan: Handler = async (controller, params) => {
  const state = controller.getState();

  const [responseEs, errorEs, _networkEs] = await DrugOrderItemClaim.create({
    apiToken: controller.apiToken,
    data: {
      ...params, // claim_payload
      emr: state.selectedEmr?.id,
      encounter: state.selectedEncounter?.id,
      order_type: "CONTINUE_PLAN",
      product: state.selectedDrug?.id,
      quantity: 1,
    },
  });
  if (!errorEs) {
    let drugSelectSeq: any = { ...state.DrugSelectSequence, ...responseEs };
    drugSelectSeq.claim_payload.selectedProduct =
      responseEs?.claim_payload?.payloads?.[0]?.product_id;
    drugSelectSeq.claim_payload.items = responseEs?.claim_payload?.payloads;

    drugSelectSeq.claim_payload.isAdd =
      drugSelectSeq?.claim_payload?.have_question &&
      (!drugSelectSeq?.claim_payload?.payloads?.[0]?.answer_dict ||
        Object.keys(responseEs?.claim_payload?.payloads?.[0]?.answer_dict)
          .length === 0);

    controller.setState({
      DrugSelectSequence: drugSelectSeq,
    } as any);
  }
};

export const ConfirmDrugContinueOrder: Handler = async (controller, params) => {
  console.log("ConfirmDrugContinueOrder: ", params);
  let state = controller.getState();

  let { [CARD_DUPLICATE_CLASSIFICATION_REASON]: e, ...remainError } =
    state.errorMessage || {};

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
    ...(params?.noteGroupDuplicated &&
      remainError && { errorMessage: remainError }),
  });

  let data: any = setDrugContinueData(controller, params.action);
  data.doctor_certificate_no = params?.doctorCertificate || "";

  if (params?.noteGroupDuplicated) {
    data.note_group_duplicated = params?.noteGroupDuplicated;
  }
  console.log("data: ", data);

  state = controller.getState();

  if (params?.confirm_duplicated_drugs) {
    delete params.noteGroupDuplicated;
    data = { ...data, ...params };
    await controller.setState({
      DrugSelectSequence: { ...state.DrugSelectSequence, ...params },
    });
  }
  console.log("data: ", data);

  const [_response, error, _network] = await DrugContinuePlanList.create({
    data: data,
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
  });

  state = controller.getState();

  if (error) {
    if (error?.code === "NEED_VERIFY_ORDER") {
      controller.setState({
        modDoctorCertificate: {
          ...state.modDoctorCertificate,
          open: true,
          narcoticDrugType: error?.detail,
          action: params.action,
          card: params.card,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
      return;
    } else if (error?.code === "DUPLICATE_DRUG_ORDERS") {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [CARD_DUPLICATE_REASON]: {
            ...error,
            action: params.action,
            noteGroupDuplicated: data.note_group_duplicated,
          },
        },
        successMessage: { ...state.successMessage, [params.card]: null },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
      return;
    }
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: { error: error, showMessage: true },
        [CARD_DUPLICATE_REASON]: null,
      },
      successMessage: { ...state.successMessage, [params.card]: null },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
    });
  } else {
    ClearDrugOrderForm(controller, {});
    GetDrugContinuePlan(controller);
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: { error: null, showMessage: true },
        [CARD_DUPLICATE_REASON]: null,
      },
      successMessage: {
        ...state.successMessage,
        [params.card]: "Create Drug continue plan success.",
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });
  }
};

export const EditDrugContinuePlan: Handler = async (controller, params) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  let data: any = setDrugContinueData(controller, params.action);

  state = controller.getState();
  data.quantity = state.DrugSelectSequence?.quantity;

  const [_response, error, _network] = await DrugContinuePlanDetail.update({
    pk: state.DrugSelectSequence?.id,
    data: data,
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
  });
  if (error) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: { error: error, showMessage: true },
      },
      successMessage: { ...state.successMessage, [params.card]: null },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
    });
  } else {
    ClearDrugOrderForm(controller, {});
    GetDrugContinuePlan(controller);
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: { error: null, showMessage: false },
      },
      successMessage: {
        ...state.successMessage,
        [params.card]: `${params.action} Drug continue plan success.`,
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });
  }
};

export const NoteAndVerifyAndCancelDrugContinuePlan: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const data: any = {
    ...state.modNoteData,
    ...params, // action, note
  };

  const [response, error, _network] = await DrugContinuePlanDetail.update({
    pk: state.DrugSelectSequence?.id,
    data: data,
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
  });

  console.log("NoteAndVerifyAndCancelDrugContinuePlan error: ", error);
  console.log("NoteAndVerifyAndCancelDrugContinuePlan response: ", response);
  if (error) {
    controller.setState({
      modNoteData: {
        ...data,
        open: true,
        error: error,
        stockUnitName: state.DrugSelectSequence?.stock_unit_name,
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
    });
  } else {
    ClearDrugOrderForm(controller, {});
    GetDrugContinuePlan(controller);
    controller.handleEvent({
      message: "GetDrugOrderQueue",
      params: {
        patient: state.selectedEncounter?.patient_id || "",
      },
    });
    controller.setState({
      modNoteData: { open: false },
      errorMessage: {
        ...state.errorMessage,
        [params.card]: { error: null, showMessage: false },
      },
      successMessage: {
        ...state.successMessage,
        [params.card]: `${data.action} Drug continue plan success.`,
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });
  }
};

export const ListDrugContinueActionLog: Handler = async (
  controller,
  params
) => {
  const [response, error, _network] = await DrugContinuePlanActionLogList.list({
    pk: params.pk,
    apiToken: controller.apiToken,
  });

  if (!error) {
    const state = controller.getState();

    controller.setState({
      DrugSelectSequence: {
        ...state.DrugSelectSequence,
        actionLogs: { ...state.DrugSelectSequence.actionLogs, data: response },
      },
    });
  }
};

// ModNote =========================================================
export const SetModNoteData: Handler = (controller, params) => {
  const state = controller.getState();

  controller.setState({
    modNoteData: { ...state.modNoteData, [params.name]: params.value },
  } as any);
};

// ModSpecialDirection =========================================================
export const SetModSpecialDirectionConfig: Handler = (controller, params) => {
  const state = controller.getState();

  controller.setState({
    modSpecialDirectionConfig: {
      ...state.modSpecialDirectionConfig,
      [params.name]: params.value,
    },
  } as any);
};

// ModSolventSelection =========================================================
const checkOpenSolvent = (controller: any) => {
  const state = controller.getState();

  let drugSelectSeq: any = { ...state.DrugSelectSequence };
  let modSolventConfig: any = { ...state.modSolventSelectionConfig };

  if (drugSelectSeq?.is_intravaneous) {
    if (state.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE) {
      if (drugSelectSeq?.add_auto_solvent) {
        modSolventConfig.editableQuantity = true;
        modSolventConfig.editable = true;

        // Todo
      }
    } else {
      // Todo
      controller.handleEvent({
        message: "initModSolventSelection",
        params: {
          drugOrderType: "Continue",
          solventId: null,
          admixtureOptions: null,
        },
      });
    }
  }
};

export const CheckShowButtonSolvent: Handler = (controller) => {
  const state = controller.getState();

  let drugSelectSeq: any = { ...state.DrugSelectSequence };

  let modSolventConfig: any = {
    ...state.modSolventSelectionConfig,
    showButtonSolvent: false,
  };
  if (
    drugSelectSeq?.is_intravaneous &&
    state.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE
  ) {
    modSolventConfig.showButtonSolvent = true;
  }
  controller.setState({ modSolventSelectionConfig: modSolventConfig });
};

// Med reconcile ========================================================

export const OpenMedReconcileFromWarning: Handler = async (
  controller,
  params
) => {
  console.log(
    "OpenMedReconcileFromWarning params.medReconcileCheck.med_reconciliation: ",
    params.medReconcileCheck?.med_reconciliation
  );
  console.log("OpenMedReconcileFromWarning params: ", params);

  // get MedReconcileDetail

  // if (params.medReconcileCheck?.med_reconciliation) {
  //   await SetMedReconcile(controller, {
  //     action: "selected_med_reconcile",
  //     selectedMedReconcile: { id: params.medReconcileCheck.med_reconciliation },
  //   });
  // } else {
  //   console.warn("Can't find med_reconciliation")
  // }



  console.log('Change selectedRecordViewIndex: ', params.selecteRecordViewIndex);
  controller.setState({
    selectedRecordViewIndex: params.selecteRecordViewIndex,
    ackMedReconcileId: params.medReconcileCheck?.med_reconciliation,
  });
};

export const ClearMedReconcile: Handler = async (controller, params) => {
  controller.setState({
    medReconcile: null,
    medReconcileStartWard: null,
    medReconcilePendingOrdering: null,
    medReconcileList: [],
    medReconcileInternalCheckedItem: [],
    medReconcileInternalFilter: {
      checkedDoctor: false,
      doctor: null,
      checkedDrug: false,
      drug: null,
      checkedDate: false,
      startDate: null,
      endDate: null,
    },
    medReconcileIPDAdmitItem: [],
    medReconcileOrder: null,
    openMedReconcileOrder: false,
    medReconcileLogs: [],
    openMedReconcileLogs: false,
    drugOrderHistoryItems: [],
    medReconcileIPDAdmitSelectItem: [],
    medReconcileIPDAdmitSelectOrder: [],
    MedReconcileSequence: null,
  });
};

export const GetMedReconcile: Handler = async (controller, params) => {
  console.log("GetMedReconcile params: ", params);
  let state = controller.getState();

  // const getPermission = await UserPermissionView.post({
  //   apiToken: controller.apiToken,
  //   data: {
  //     config_TPD_MED_RECONCILIATION_AVAILABLE_TIME: null,
  //   },
  // });

  // console.log('GetMedReconcile getPermission: ', getPermission);

  let optionsTarget = [
    "unit",
    "route",
    "site",
    "frequency",
    "method",
    // "medReconcileType",
  ];
  let allKeyOptionsIsIn = true;
  let keysOptions = Object.keys(state.masterOptions);
  optionsTarget.forEach((item: any) => {
    if (!keysOptions.includes(item)) {
      allKeyOptionsIsIn = false;
    }
  });

  if (state.masterOptions.length === 0 || !allKeyOptionsIsIn)
    await controller.handleEvent({
      message: "GetMasterData",
      params: {
        masters: [
          // ["medReconcileType", {}],
          ["unit", {}],
          ["route", {}],
          ["site", {}],
          ["frequency", {}],
          ["method", {}],
        ],
      },
    });

  const medReconcile = await MedicationReconciliationList.list({
    // pk: state.selectedEncounter?.id,
    apiToken: controller.apiToken,
    params: { encounter: state.selectedEncounter?.id },
    extra: {
      division: controller.data.division,
      device: (controller.data as any).device,
    },
  });
  console.log(medReconcile[1] ? medReconcile[1] : medReconcile[0]);

  if (medReconcile[1]) {
    if (params.card) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: medReconcile[1] },
        },
      });
    }
    return console.log("retrive med reconcile card error: ", medReconcile[1]);
  }

  return controller.setState(
    {
      medReconcileList: medReconcile[0]?.items,
    },
    async () => {
      console.log("GetMedReconcile async medReconcile", medReconcile);
      let state = controller.getState();
      console.log("!!!!!!! state.ackMedReconcileId: ", state.ackMedReconcileId);
      if (medReconcile[0]?.items?.length > 0) {
        if (params.didMount) {
          if (params?.encounter?.type === "OPD") {
            // selected OPD

            let medRec = medReconcile[0]?.items?.find(
              (item: any) => item.med_reconciliation_type === "OPD"
            );

            console.log(
              " OPD DidMount Call Selected Med Reconcile medRec: ",
              medRec
            );

            if (state.ackMedReconcileId) {
              await SetMedReconcile(controller, {
                action: "selected_med_reconcile",
                selectedMedReconcile: { id: state.ackMedReconcileId },
              });
            } else {
              await SetMedReconcile(controller, {
                action: "selected_med_reconcile",
                selectedMedReconcile: medRec,
              });
            }
          } else {
            // selected IPD

            let medRec = medReconcile[0]?.items?.find(
              (item: any) => item.med_reconciliation_type !== "OPD"
            );

            console.log(
              " !OPD DidMount Call Selected Med Reconcile medRec: ",
              medRec
            );

            if (state.ackMedReconcileId) {
              await SetMedReconcile(controller, {
                action: "selected_med_reconcile",
                selectedMedReconcile: { id: state.ackMedReconcileId },
              });
            } else {
              await SetMedReconcile(controller, {
                action: "selected_med_reconcile",
                selectedMedReconcile: medRec,
              });
            }
          }
        }
        // console.log("selected_med_reconcile ");
        // let selectedMedReconcile = params?.selectedMedReconcile
        //   ? params.selectedMedReconcile
        //   : medReconcile[0].items[medReconcile[0].items.length - 1];
        // console.log(
        //   "GetMedReconcile selectedMedReconcile: ",
        //   selectedMedReconcile
        // );
        // await SetMedReconcile(controller, {
        //   action: "selected_med_reconcile",
        //   selectedMedReconcile: selectedMedReconcile,
        // });
      } else {
        console.log("TPDInterface new_med_reconcile ", params?.encounter?.type);
        await SetMedReconcile(controller, {
          action: "new_med_reconcile",
          type:
            params?.encounter?.type.toLowerCase() === "opd"
              ? "OPD"
              : "IPD_ADMIT",
        });
      }
    }
  );
};

export const SetMedReconcile: Handler = async (controller, params) => {
  let state = controller.getState();

  console.log(" SetMedReconcile params: ", params);
  // let { id, ...data } = state.medReconcile;
  console.log(state.medReconcile);
  if (params?.action === "save") {
    let medRec = { ...state.medReconcile };
    medRec.items = [...(state.medReconcile?.items || [])].filter(
      (item: any) => item.prepareSave
    );

    // Transfer
    // if (medRec.med_reconciliation_type === "TRANSFER") {
    //   medRec.items_current_continue = [
    //     ...(state.MedReconcileSequence?.transferDrugContinue || []),
    //   ].filter((item: any) => item.prepareSave);
    //   medRec.items_current_one_day = [
    //     ...(state.MedReconcileSequence?.transferDrugOneDay || []),
    //   ].filter((item: any) => item.prepareSave);
    // }

    const medReconcile = await CreateUpdateMedicationReconciliationView.post({
      data: {
        ...medRec,
        encounter: state.selectedEncounter?.id,
        username: params?.username,
        password: params?.password,
        remark: params?.remark,
        acknowledge: params?.acknowledge === false ? false : true,
      } as any,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
        device: (controller.data as any).device,
      },
    });
    console.log(medReconcile[1] ? medReconcile[1] : medReconcile[0]);
    // Error
    if (medReconcile[1]) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: medReconcile[1] },
          },
          ...(params.continueOrdering && {
            medReconcilePendingOrdering: params.continueOrdering,
          }),
        });
      }
      return console.log("error: ", medReconcile[1]);
    }

    console.log("params.continueOrdering", params.continueOrdering);

    // Success
    if (params.card) {
      let remainError: any = {};
      if (state.errorMessage) {
        let { [params.card]: e, ...restError } = state.errorMessage || {};
        remainError = restError;
        controller.setState({ errorMessage: restError });
      }

      return controller.setState(
        {
          successMessage: {
            ...state.successMessage,
            [params.card]: "รับทราบ Med Reconcile : บันทึกสำเร็จ",
          },
          ...(remainError && { errorMessage: { ...remainError } }),
          ...(params.continueOrdering && {
            medReconcilePendingOrdering: params.continueOrdering,
          }),
        },
        async () => {
          let medReconcilePendingOrdering =
            controller.getState()?.medReconcilePendingOrdering;
          if (controller.getState()?.medReconcilePendingOrdering) {
            console.log(
              "state.medReconcilePendingOrdering ",
              medReconcilePendingOrdering
            );
            await MedReconcileDrugOrder(controller, {
              ...medReconcilePendingOrdering,
            });
          }

          console.log(" GetMedReconcile !!!");
          await GetMedReconcile(controller, {
            selectedMedReconcile: medReconcile[0],
          });

          await SetMedReconcile(controller, {
            action: "selected_med_reconcile",
            selectedMedReconcile: medReconcile[0],
          });
        }
      );
      // }
    } else {
      // Success without cardName
      console.warn("should not see me !! success without cardname");
      await GetMedReconcile(controller, {
        selectedMedReconcile: medReconcile[0],
      });
    }
    // }
    return;
  } else if (params?.action === "addOrderItem") {
    let selectedItems = [...(state.drugOrderHistoryItems as any)]?.filter(
      (item: any) =>
        (state.medReconcileInternalCheckedItem as any).includes(item.id)
    );
    console.log("addOrderItem selectedItems: ", selectedItems);
    console.log(
      "addOrderItem state.medReconcileInternalCheckedItem: ",
      state.medReconcileInternalCheckedItem
    );
    // console.log(params?.item);

    // const duplicated = state.medReconcile?.items?.find(
    //   (item: any) =>

    //     selectedItems || []).product === item?.product
    // );

    let duplicatedList = (selectedItems || [])?.filter((item: any) =>
      state.medReconcile?.items?.find((cI: any) => cI.product === item.product)
    );
    console.log("duplicated: ", duplicatedList);

    if (duplicatedList?.length > 0 && !params.allowDuplicated) {
      if (params.card) {
        return controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: {
              error: {
                name: "Duplicated copy internal drug to order",
                duplicatedList,
              },
            },
          },
        });
      }

      console.log("Duplicated");
      return;
    }
    const externalDrug = selectedItems?.find(
      (item: any) => item?.type === "EXTERNAL"
    );

    if (externalDrug) {
      if (params.card) {
        return controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: "ไม่สามารถเลือกยา EXTERNAL ได้" },
          },
        });
      }
      return console.log("ไม่สามารถเลือกยา EXTERNAL ได้");
    }

    let currentMedReconcileItems = [...(state.medReconcile?.items || [])];
    let prepareItems = selectedItems?.map((item: any) => ({
      id: null,
      code: item.code,
      name: item.name,
      label: item.label,
      quantity: item.quantity,
      status: "OFF",
      start_date: item.order_time?.split(" ")?.[0] || null,
      start_time: item.order_time?.split(" ")?.[1]?.slice(1, 6) || null,
      product: item.product,
      dose: item.dose,
      route: item.route,
      unit: item.unit,
      rate: item.rate,
      frequency: item.frequency,
      site: item.site,
      method: item.method,
      last_dose_date: null,
      last_dose_time: null,
      start_dose_date: null,
      start_dose_time: null,
      order_item: item.id,
      note: "",
      active: true,
      is_external: false,
      prepareSave: true,
    }));
    prepareItems.forEach((item: any) => {
      currentMedReconcileItems.push(item);
    });
    // const reconcileItem = await DrugOrderItemDetailForReconcile.retrieve({
    //   pk: params?.item?.pk || params?.item?.id,
    //   order_type: params?.item?.type,
    //   apiToken: controller.apiToken,
    // });
    // console.log(reconcileItem[1] ? reconcileItem[1] : reconcileItem[0]);
    // if (reconcileItem[0]) {
    //   const item = reconcileItem[0];
    //   let itemToAdd = {
    //     code: item.code,
    //     name: item.name,
    //     label: item.label,
    //     quantity: item.quantity,
    //     start_date: formatDate(moment(item.order_time)),
    //     start_time: moment(item.order_time).format("HH:mm"),
    //     last_dose_date: null,
    //     last_dose_time: null,
    //     note: "",
    //     reconcile_item_id: null,
    //     ...params.item,
    //     id: item.id,
    //   };
    console.log(
      "Add items: ",
      currentMedReconcileItems,
      selectedItems,
      state.medReconcileInternalCheckedItem
    );
    return controller.setState({
      medReconcile: {
        ...state.medReconcile,
        items: [...(currentMedReconcileItems || [])],
      },
      MedReconcileSequence: {
        ...state.MedReconcileSequence,
      },
      medReconcileInternalCheckedItem: [],
    });
    // }
  } else if (params?.action === "deleteOrderItem" && params?.item) {
    controller.setState({
      medReconcile: {
        ...state.medReconcile,
        items: (state.medReconcile.items || []).filter((item: any) =>
          item.id !== params.item?.id ? { ...item, active: false } : { ...item }
        ),
      },
    });
  } else if (params?.action === "setExternalItem" && params?.item) {
    console.log("setExternalItem params: ", params);

    // target_type = "custom_drug" (Custom) : have params.item.name = "XX"
    // target_type = "tmt_tp" (Generic/Trade Product) : have params.item.product = "XX"
    // target_type = "tmt_tp" (Generic/Trade Product) : have params.item.product_name = "XX"
    if (
      !(
        (params?.item?.target_type === "custom_drug" && params?.item?.name) ||
        params?.item?.target_type === "tmt_tp"
      )
    ) {
      console.log("Not correct type");
      return;
    }

    let itemsCheck = (state.medReconcile?.items || []).filter(
      (item: any, index: number) =>
        (params.item?.id === null ||
          typeof item?.id === "undefined" ||
          item.id !== params.item?.id) &&
        params?.index !== index
    );
    console.log("itemsCheck: ", itemsCheck);
    let editItems = [...state.medReconcile?.items];
    console.log("editItems: ", editItems);

    // console.log("setExternalItem items: ", items);

    let newItem = params.item;

    // เมื่อ แก้ไข field เหล่านี้ ถูก map มา ดังนั้น ต้องลบ ออก ตาม issue 58857
    if (newItem.name === undefined) {
      delete newItem.name;
    }
    if (newItem.product === undefined) {
      delete newItem.product;
    }
    if (newItem.product_name === undefined) {
      delete newItem.product_name;
    }
    if (!newItem.code) {
      delete newItem.code;
    }

    newItem.prepareSave = true;

    let editIndex = -1;
    let isEqualList =
      itemsCheck.length === (state.medReconcile?.items || []).length;

    if (isEqualList) {
      // Add item - remove id (which should be null)
      console.log(
        "setExternalItem // Add item - remove id (which should be null): "
      );
      delete newItem.id;
      newItem.status = "OFF";
    } else {
      // Edit existing item - combine
      console.log("setExternalItem // Edit existing item - combine: ");

      if (Number.isInteger(params?.index)) {
        editIndex = state.medReconcile?.items?.findIndex(
          (item: any, index: number) => index === params.index
        );
        newItem = {
          ...(state.medReconcile?.items || []).find(
            (item: any, index: number) => index === params.index
          ),
          ...newItem,
        };
      } else {
        editIndex = state.medReconcile?.items?.findIndex(
          (item: any) => item.id === params.item.id
        );
        newItem = {
          ...(state.medReconcile?.items || []).find(
            (item: any) => item.id === params.item.id
          ),
          ...newItem,
        };
      }
    }

    console.log("setExternalItem editIndex: ", editIndex);
    console.log("setExternalItem newItem: ", newItem);

    if (newItem.target_type === "custom_drug") {
      newItem[newItem.target_type] = newItem.name;
    } else {
      newItem[newItem.target_type] = newItem.pk;
    }
    console.log("setExternalItem newItem: ", newItem);

    if (isEqualList) {
      itemsCheck.push(newItem);
      console.log("setExternalItem Check Add New itemsCheck: ", itemsCheck);
    } else {
      if (editIndex !== -1) {
        editItems[editIndex] = newItem;
      }

      console.log("setExternalItem Check Edit editItems: ", editItems);
    }

    controller.setState({
      MedReconcileSequence: {
        ...state.MedReconcileSequence,
      },
      medReconcile: {
        ...state.medReconcile,
        items: isEqualList ? itemsCheck : editItems,
        // external_order: {
        //   ...state.medReconcile.external_order,
        //   items: items,
        // },
      },
    });
  } else if (params?.action === "checked" && Number.isInteger(params?.index)) {
    console.log(" action: checked");

    const items = (state.medReconcile?.items || [])
      // .filter((item: any) => item.active)
      .map((item: any, index: number) =>
        params?.index === index
          ? { ...item, status: params?.status, prepareSave: true }
          : { ...item }
      );

    controller.setState({
      medReconcile: {
        ...state.medReconcile,
        items: items,
      },
      MedReconcileSequence: {
        ...state.MedReconcileSequence,
      },
    });
  } else if (
    params?.action == "deleteExternalItem" &&
    Number.isInteger(params?.index)
  ) {
    /// Toggle delete/activate
    console.log("param.item:  ", params.item);

    const items = params?.item?.id
      ? (state.medReconcile?.items || []).map((item: any, index: number) =>
        index === params.index
          ? { ...item, active: !item.active, prepareSave: true }
          : { ...item }
      )
      : (state.medReconcile?.items || []).filter(
        (item: any, index: number) => index !== params.index
      );

    controller.setState({
      medReconcile: {
        ...state.medReconcile,
        items: items,
        // external_order: {
        //   ...state.medReconcile.external_order,
        //   items: items,
        // },
      },
    });
  } else if (
    params?.action === "change_date_time" &&
    Number.isInteger(params?.index)
  ) {
    if (!params.humanAction) {
      return;
    }
    console.log("change_date_time: params ", params);
    console.log("change_date_time: params.item ", params.item);
    const medReconcileItems = state.medReconcile?.items
      // ?.filter((item: any) => item.active)
      .map((item: any, index: number) => {
        return index === params.index
          ? {
            ...item,
            [params.name]: params.value || "",
            prepareSave: item.prepareSave || params?.humanAction,
          }
          : { ...item };
      });
    console.log("change_date_time medReconcileItems: ", medReconcileItems);

    return controller.setState({
      medReconcile: {
        ...state.medReconcile,
        items: medReconcileItems,
      },
    });
  } else if (
    params?.action === "change_note" &&
    Number.isInteger(params?.index)
  ) {
    console.log("change_note: !!! ", params.item);
    const items = state.medReconcile?.items
      // ?.filter((item: any) => item.active)
      .map((item: any, index: number) => {
        return index === params.index
          ? {
            ...item,
            [params.name]: params.value || "",
            prepareSave: true,
          }
          : { ...item };
      });

    return controller.setState({
      medReconcile: {
        ...state.medReconcile,
        items: items,
      },
    });
  } else if (params?.action === "acknowledge" && params?.role) {
    console.log("ack");
    const ack = await MedicationReconciliationAcknowledge.update({
      pk: state.medReconcile?.id,
      data: {
        role: params?.role,
        is_ipd: params?.is_ipd,
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });
    console.log(ack[1] ? ack[1] : ack[0]);
    GetMedReconcile(controller, params);
  } else if (
    params?.action === "selected_med_reconcile" &&
    params?.selectedMedReconcile
  ) {
    const medReconcileDetail = await MedicationReconciliationDetail.retrieve({
      pk: params?.selectedMedReconcile?.id,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data?.division || null,
        device: (controller.data as any)?.device || null,
      },
    });
    if (medReconcileDetail[1]) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: medReconcileDetail[1] },
          },
        });
      }
      return;
    }

    if (medReconcileDetail[0]) {
      // console.log("medReconcileDetail[0]: ", medReconcileDetail[0]);
      // state = controller.getState();
      // console.log('state.MedReconcileSequence?.permission: ', state.MedReconcileSequence?.permission);
      // var editableAfterSave =
      //   state.MedReconcileSequence?.permission
      //     ?.config_TPD_ENABLE_MED_RECONCILIATION_NOT_AVAILABLE_TIME_AFTER_CHECKED_EDIT;
      // console.log("editableAfterSave: ", editableAfterSave);

      let medReconcileD = { ...medReconcileDetail[0] };
      // console.log("medReconcileD1: ", medReconcileD);

      // if (medReconcileD?.not_available_at_admit_time && !editableAfterSave) {
      //   console.log("set not_available_at_admit_time_disabled_checkbox to true")
      //   medReconcileD = {
      //     ...medReconcileD,
      //     not_available_at_admit_time_disabled_checkbox: true,
      //   };
      // }
      console.log("medReconcileD2: ", medReconcileD);
      if (medReconcileD.med_reconciliation_type === "DISCHARGE") {
        const medReconcileIPDAdmit =
          await MedReconciliationIPDAdmitForDischargeView.list({
            apiToken: controller.apiToken,
            params: {
              encounter: state.selectedEncounter?.id,
            },
          });
        console.log(
          "MedReconciliationIPDAdmitForDischargeView: ",
          medReconcileIPDAdmit
        );
        state = controller.getState();

        if (medReconcileIPDAdmit[1]) {
          if (params.card) {
            controller.setState({
              errorMessage: {
                ...state.errorMessage,
                [params.card]: { error: medReconcileIPDAdmit[1] },
              },
            });
          }
          return;
        }
        return controller.setState({
          medReconcile: medReconcileD,
          medReconcileIPDAdmitItem: medReconcileIPDAdmit[0]?.items || [],
          // ...(medReconcileD.med_reconciliation_type === "TRANSFER" && {MedReconcileSequence: {
          //   ...state.MedReconcileSequence,
          //   transferDrugContinue:  medReconcileD?.items_current_continue,
          //   transferDrugOneDay: medReconcileD?.items_current_one_day,
          // }})
        });
      }
      return controller.setState({
        medReconcile: medReconcileD,
        // ...(medReconcileD.med_reconciliation_type === "TRANSFER" && {MedReconcileSequence: {
        //   ...state.MedReconcileSequence,
        //   transferDrugContinue:  medReconcileD?.items_current_continue,
        //   transferDrugOneDay: medReconcileD?.items_current_one_day,
        // }})
      });
    }
  } else if (params?.action === "new_med_reconcile") {
    controller.setState({
      medReconcile: {
        id: null,
        doctor: null,
        doctor_remark: "",
        encounter: state.selectedEncounter?.id || null,
        med_reconciliation_type: params?.type ? params?.type : "IPD_ADMIT",
        external_order: null,
        from_division: null,
        has_no_past_med: false,
        items: [],
        not_available_at_admit_time: false,
        nurse: null,
        nurse_ipd: null,
        nurse_ipd_remark: "",
        nurse_remark: "",
        pharmacist: null,
        pharmacist_remark: "",
        remark: "",
      },
    });
  }
};

export const GetMedReconcileInternal: Handler = async (controller, params) => {
  const state = controller.getState();

  const medReconcile = await DrugOrderItemForReconciliationList.list({
    apiToken: controller.apiToken,
    params: {
      patient: state.selectedEncounter?.patient,
      product: state.medReconcileInternalFilter?.drug?.id || "",
      doctor: state.medReconcileInternalFilter?.doctor?.id || "",
      start_date: state.medReconcileInternalFilter?.startDate || "",
      end_date: state.medReconcileInternalFilter?.endDate || "",
    },
    extra: {
      evice: (controller.data as any).device,
      division: controller.data.division,
    },
  });

  if (medReconcile[1]) {
    console.log("get internal drug history error: ", medReconcile[1]);
    if (params.card) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: medReconcile[1] },
        },
      });
    }
    return;
  }

  return controller.setState({
    drugOrderHistoryItems: medReconcile[0].items || [],
  });
};

export const GetMedReconcileCheck: Handler = async (controller, params) => {
  const state = controller.getState();

  const medReconcile = await MedicationReconciliationCheckView.get({
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
    params: {
      encounter: params.encounter.id,
      call_from: params.callForm,
    },
  });

  if (medReconcile[1]) {
    console.log("get internal drug history error: ", medReconcile[1]);
    if (params.card) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: medReconcile[1] },
        },
      });
    }
    return;
  }

  return controller.setState({
    medReconcileCheck: medReconcile?.[0] ? medReconcile?.[0] : null,
  });
};

export const GetMedReconcileLog: Handler = async (controller, params) => {
  const state = controller.getState();

  const medReconcile = await MedicationReconciliationLogList.list({
    apiToken: controller.apiToken,
    params: {
      med_reconcile: params.medReconcileId,
    },
    extra: {
      device: (controller.data as any).device,
      division: controller.data.division,
    },
  });

  if (medReconcile[1]) {
    console.log("get internal drug history error: ", medReconcile[1]);
    if (params.card) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: medReconcile[1] },
        },
      });
    }
    return;
  }

  return controller.setState({
    medReconcileLogs: (medReconcile?.[0]?.items || [])?.map((item: any) => ({
      ...item,
      ...(!item.role_name && { role_name: "N/A" }),
    })),
    openMedReconcileLogs: true,
  });
};

export const HandleGetDetailForExternalDrug: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  if (params.selectedDrug) {
    const drug = await DrugDetail.retrieve({
      pk: params.selectedDrug,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
        device: (controller.data as any).device,
      },
    });

    if (drug[1]) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: drug[1] },
          },
        });
      }
      return console.log("Get drug detail error: ", drug[1]);
    }

    controller.setState({
      selectedExternalDrug: { ...drug[0] },
    });
  }
};

export const MedReconcileDrugOrder: Handler = async (controller, params) => {
  let state = controller.getState();

  if (params?.action === "open_order") {
    const medSummary = await MedicationReconciliationSummaryView.get({
      pk: state.medReconcile?.id,
      apiToken: controller.apiToken,
    });
    console.log("medSummary: ", medSummary);
    if (medSummary[1]) {
      if (params?.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: medSummary[1] },
          },
          medReconcilePendingOrdering: null,
        });
      }
      return;
    }

    // TODO: Check limit each drug
    console.log("open_order medSummary[0]", medSummary[0]);

    let promiseExternal = [];
    let promiseInternal = [];

    if (medSummary[0]?.external_product_items?.length > 0) {
      promiseExternal = (medSummary[0]?.external_product_items || [])?.map(
        (item: any) => {
          if (item.selected_product) {
            console.log(" item.selected_product ");
            return DrugDetail.retrieve({
              pk: item.selected_product,
              apiToken: controller.apiToken,
              extra: {
                division: controller.data?.division,
                device: (controller.data as any)?.device,
              },
            });
          } else {
            console.log(" item.selected_product are null ");
            return Promise.resolve([null, null, null]);
          }
        }
      );

      console.log("promiseExternal: ", promiseExternal);
    }

    if (medSummary[0]?.internal_items?.length > 0) {
      promiseInternal = (medSummary[0]?.internal_items || [])?.map(
        (item: any) => {
          if (item.selected_product) {
            return DrugDetail.retrieve({
              pk: item.selected_product,
              apiToken: controller.apiToken,
              extra: {
                division: controller.data?.division,
                device: (controller.data as any)?.device,
              },
            });
          } else {
            return Promise.resolve([null, null, null]);
          }
        }
      );
      console.log("promiseInternal: ", promiseInternal);
    }

    const resolvedlist = await Promise.all([
      ...promiseExternal,
      ...promiseInternal,
    ]);
    console.log("resolvedlist: ", resolvedlist);

    medSummary[0].external_product_items = (
      medSummary[0]?.external_product_items || []
    ).map((item: any) => {
      return {
        ...item,
        drugDetail:
          resolvedlist?.find(
            (list: any) =>
              item.selected_product && list?.[0]?.id === item.selected_product
          )?.[0] || {},
      };
    });

    medSummary[0].internal_items = (medSummary[0]?.internal_items || []).map(
      (item: any) => {
        return {
          ...item,
          drugDetail:
            resolvedlist?.find(
              (list: any) =>
                item.selected_product && list?.[0]?.id === item.selected_product
            )?.[0] || {},
        };
      }
    );

    return controller.setState({
      medReconcileOrder: medSummary[0],
      medReconcilePendingOrdering: null,
      openMedReconcileOrder:
        medSummary[0]?.external_product_items?.length > 0 ||
        medSummary[0]?.internal_items?.length > 0 ||
        medSummary[0]?.external_custom_items?.length > 0,
    });
  } else if (params?.action === "change") {
    console.log("params: ", params);
    // console.log("params?.targetKey: ", params?.targetKey);
    // console.log("params?.keyId: ", params?.keyId);
    // console.log("params?.keyName: ", params?.keyName);
    // console.log("params.chooseId: ", params.chooseId);
    // console.log("params.chooseName: ", params.chooseName);

    let data = state.medReconcileOrder?.[params?.targetKey]?.map(
      (item: any) => {
        const { drugDetail, ...rest } = item;

        return item.id === params.itemTarget?.id
          ? {
            ...rest,
            drugDetail: {},
            [params?.keyId]: params.chooseId,
            [params?.keyName]: params.chooseName,
          }
          : { ...rest, drugDetail: {} };
      }
    );

    // check
    if (params?.keyId === "selected_product" && params.chooseId) {
      let res = await DrugDetail.retrieve({
        pk: params.chooseId,
        apiToken: controller.apiToken,
        extra: {
          division: controller.data?.division,
          device: (controller.data as any)?.device,
        },
      });
      if (res[0]) {
        console.log("append drugDetail with ", res[0]);
        let idx = data?.findIndex(
          (item: any) => item.id === params.itemTarget?.id
        );
        console.log("idx: ", idx);
        if (idx !== -1) {
          data[idx].drugDetail = res[0];
          if (
            res[0]?.limited_dosage_units?.length > 0 &&
            !res[0]?.limited_dosage_units?.includes(data[idx].unit)
          ) {
            data[idx].unit = null;
            data[idx].unit_name = "";
          }
          if (
            res[0]?.limited_routes?.length > 0 &&
            !res[0]?.limited_routes?.includes(data[idx].route)
          ) {
            data[idx].route = null;
            data[idx].route_name = "";
          }
          if (
            res[0]?.limited_sites?.length > 0 &&
            !res[0]?.limited_sites?.includes(data[idx].site)
          ) {
            data[idx].site = null;
            data[idx].site_name = "";
          }
          if (
            res[0]?.limited_frequencies?.length > 0 &&
            !res[0]?.limited_frequencies?.includes(data[idx].frequency)
          ) {
            data[idx].frequency = null;
            data[idx].frequency_name = "";
          }
          if (
            res[0]?.limited_methods?.length > 0 &&
            !res[0]?.limited_methods?.includes(data[idx].method)
          ) {
            data[idx].method = null;
            data[idx].method_name = "";
          }
        }
      }
    }

    console.log("prepare change data: ", data);
    if (data.length > 0) {
      controller.setState({
        medReconcileOrder: {
          ...state.medReconcileOrder,
          [params.targetKey]: data,
        },
      });
    }
    return;
  } else if (params?.action === "internalUseCheck") {
    let data = state.medReconcileOrder?.[params?.targetKey]?.map((item: any) =>
      item.id === params.itemTarget?.id
        ? { ...item, is_to_internal_order: params.value }
        : { ...item }
    );
    if (data.length > 0) {
      controller.setState({
        medReconcileOrder: {
          ...state.medReconcileOrder,
          [params.targetKey]: data,
        },
      });
    }
    return;
  } else if (params?.action === "one_dose_check") {
    let data = state.medReconcileOrder?.[params?.targetKey]?.map((item: any) =>
      item.id === params.itemTarget?.id
        ? { ...item, order_one_dose: params.value }
        : { ...item }
    );
    if (data.length > 0) {
      controller.setState({
        medReconcileOrder: {
          ...state.medReconcileOrder,
          [params.targetKey]: data,
        },
      });
    }
    return;
  } else if (params?.action === "remove_item") {
    let data = state.medReconcileOrder?.[params?.targetKey]?.filter(
      (item: any) => item.id !== params.itemTarget?.id
    );

    return controller.setState({
      medReconcileOrder: {
        ...state.medReconcileOrder,
        [params.targetKey]: data,
      },
    });
  } else if (params?.action === "order") {
    let data = { ...state.medReconcileOrder };
    console.log("data: ", data);

    if (data?.external_custom_items) {
      // delete data.external_custom_items;
      data.external_custom_items = data.external_custom_items.map(
        (item: any) => ({ ...item, order_one_dose: !!item.order_one_dose })
      );
    }

    if (data?.external_product_items) {
      data.external_product_items = data.external_product_items.map(
        (item: any) => ({ ...item, order_one_dose: !!item.order_one_dose })
      ); //item.order_one_dose)
    }

    if (data?.internal_items) {
      data.internal_items = data.internal_items.map((item: any) => ({
        ...item,
        order_one_dose: !!item.order_one_dose,
      })); //item.order_one_dose)
    }

    if (data?.product_list) {
      delete data.product_list;
    }

    const medReconcileOrder = await MedicationReconciliationOrderView.post({
      apiToken: controller.apiToken,
      data: data,
      extra: {
        division: controller.data?.division,
        device: (controller.data as any)?.device,
      },
    });
    if (medReconcileOrder[1]) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: [medReconcileOrder[1]],
          },
        });
      }
      return;
    }
    state = controller.getState();
    return controller.setState({
      medReconcileOrder: null,
      openMedReconcileOrder: false,
      successMessage: {
        ...state.successMessage,
        [params.card]: "สั่งยา: บันทึกสำเร็จ",
      },
    });
  }
};

// Drug order working (pharmacy, nurse) ==================================
export const GetDrugOrderQueue: Handler = async (controller, params) => {
  let drugOrderQueue: any[] = [null, null, null];

  if (!params.fetchDrugVerbalOnly) {
    drugOrderQueue = await DrugOrderQueueList.list({
      params: {
        // pending_only: true,
        order_perform_div: controller.data.division,
        patient: params?.patient || "",
        is_shipping: true,
      },
      apiToken: controller.apiToken,
    });
  }
  if (drugOrderQueue[1])
    return console.log("Error: ", drugOrderQueue[1].toString());

  controller.setState({
    drugOrderQueue: drugOrderQueue[0]?.items || [],
  });

  // verbal order
  const [drugVerbalRes, drugVerbalErr, drugVerbalNetErr] =
    await DoctorPendingTaskDrugOrderList.get({
      params: {
        order_specific_types: "DRUG_ORDER",
        patient: params?.patient || "",
      },
      apiToken: controller.apiToken,
    });

  controller.setState({
    verbalDrugOrderQueue: drugVerbalRes?.items || [],
  });
};

export const SelectDrugOrderWorking: Handler = async (controller, params) => {
  console.log("Handle SelectDrugOrderWorking: ", params);
  if (params.history) {
    params.history.replace(
      `?app=HIS&drug_id=${params.drugOrder.id}&encounter_id=${params.drugOrder.encounter}`
    );
  }

  console.log('DrugOrderDetailForAction: ');
  const drugOrderWorking = await DrugOrderDetailForAction.retrieve({
    pk: params.drugOrder.id,
    apiToken: controller.apiToken,
  });

  const recommendation = await DrugRecommendationView.get({
    apiToken: controller.apiToken,
    params: { order: params.drugOrder.id },
    extra: {
      division: controller.data.division,
      device: (controller.data as any).device,
    },
  });

  if (params.drugOrder?.type === "CONTINUE_PLAN") {
    await GetDrugContinuePlan(controller);
  }

  if (drugOrderWorking[1])
    return console.log("Error: ", drugOrderWorking[1].toString());

  const encounterDetail = await EncounterPatientDetail.retrieve({
    pk: params.drugOrder.encounter,
    apiToken: controller.apiToken,
  });

  controller.setState(
    {
      selectedDrugOrderWorking: params.isQueueScreen
        ? null
        : {
          ...drugOrderWorking[0],
          items: drugOrderWorking[0].items.map((drug: any) => ({
            ...drug,
            print: true,
          })),
          recommendationFile: recommendation?.[0] || [],
        },
      selectedVerbalDrugOrderWorking: {},
    },
    async () => {
      console.log("Handle SelectDrugOrderWorking: Is called !!! ")
      if (params.selectDrugOnly) {
        const [patientInfo] = await PatientDetailView.retrieve({
          pk: encounterDetail[0]?.patient_id,
          apiToken: controller.apiToken,
        });

        console.log('Handle SelectDrugOrderWorking: !!! Is called !!! encounterDetail[0]: ', encounterDetail[0]);
        controller.setState({
          selectedEncounter: encounterDetail[0],
          selectedPatient: patientInfo,
        });
        return;
      }

      if (params?.isNotGetEncounter) return; // for case can handle many encounter in few time

      console.log("Handle SelectDrugOrderWorking: SelectEncounter Called ")
      console.log("SET SelectEncounter !!!")
      controller.handleEvent({
        message: "SelectEncounter",
        params: {
          encounter: encounterDetail[0],
          frezzeRecordViewIndex: params?.frezzeRecordViewIndex,
        },
      });
    }
  );
};

export const SelectTelepharQueue: Handler = async (controller, params) => {
  console.log("Handle SelectTelepharQueue: ", params);
  // if (params.history) {
  //   params.history.replace(
  //     `?app=HIS&drug_id=${params.drugOrder.id}&encounter_id=${params.drugOrder.encounter}`
  //   );
  // }

  // const encounterDetail = await EncounterPatientDetail.retrieve({
  //   pk: params.drugOrder.encounter,
  //   apiToken: controller.apiToken,
  // });

  // const [patientInfo] = await PatientDetailView.retrieve({
  //   pk: encounterDetail[0]?.patient_id,
  //   apiToken: controller.apiToken,
  // });

  // controller.setState({
  //   selectedEncounter: encounterDetail[0],
  //   selectedPatient: patientInfo,
  // });

  // controller.handleEvent({
  //   message: "SelectEncounter",
  //   params: {
  //     encounter: encounterDetail[0]
  //   },
  // });

}
export const SelectDashboardTelephar: Handler = async (controller, params) => {
  console.log("Handle SelectDashboardTelephar: ", params);
  if (params.history) {
    params.history.replace(
      `?app=HIS&drug_id=${params.drugOrder.id}&encounter_id=${params.drugOrder.encounter_id}`
    );
  }

  const encounterDetail = await EncounterPatientDetail.retrieve({
    pk: params.drugOrder.encounter_id,
    apiToken: controller.apiToken,
  });

  const [patientInfo] = await PatientDetailView.retrieve({
    pk: encounterDetail[0]?.patient_id,
    apiToken: controller.apiToken,
  });
  console.log('SelectDashboardTelephar encounterDetail: ', encounterDetail);
  console.log('SelectDashboardTelephar patientInfo: ', patientInfo);

  controller.setState({
    selectedEncounter: encounterDetail[0],
    selectedPatient: patientInfo,
  });

  controller.handleEvent({
    message: "SelectEncounter",
    params: {
      encounter: encounterDetail[0],
      frezzeRecordViewIndex: true
    },
  });

};

export const SelectVerbalDrugOrderWorking: Handler = async (
  controller,
  params
) => {
  const drugOrderWorking = await DrugOrderDetailForAction.retrieve({
    pk: params.verbalDrugOrder.ref_order,
    apiToken: controller.apiToken,
  });

  const encounterDetail = await EncounterPatientDetail.retrieve({
    pk: params.verbalDrugOrder.encounter_id,
    apiToken: controller.apiToken,
  });

  controller.setState(
    {
      selectedDrugOrderWorking: drugOrderWorking[0],
      selectedVerbalDrugOrderWorking: params.verbalDrugOrder,
    },
    () =>
      controller.handleEvent({
        message: "SelectEncounter",
        params: { encounter: encounterDetail[0] },
      })
  );
};

// DrugTransferRequestList ================================================
export const GetDrugTransferRequestList: Handler = async (
  controller,
  params
) => {
  const drugTransferRequestList = await DrugTransferRequestList.list({
    params: { is_queue: true },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });
  controller.setState({ drugRequestList: drugTransferRequestList?.[0] });
};

// Utilities ==============================================================
export const renderDrugOrderItems = (controller: any, drugItems: any[]) => {
  const state = controller.state;
  const data = controller.data;

  console.log(data);
  return drugItems.map((item: any) => ({
    ...item,
    unit: getLabelWithId(data.masterUnits || [], item.unit, "label"),
    route: getLabelWithId(data.masterRoutes || [], item.route, "label"),
    site: getLabelWithId(data.masterSites || [], item.site, "label"),
    frequency: getLabelWithId(
      data.masterFrequencies || [],
      item.frequency,
      "label"
    ),
    method: getLabelWithId(data.masterMethods || [], item.method, "label"),
  }));
};

const getLabelWithId = (items: any[], id: number, labelKey: string) => {
  for (const item of items) if (item.id === id) return item[labelKey];
  return "";
};

export const GetMedicationErrorStatus: Handler = async (controller, params) => {
  const state = controller.getState();

  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["medErrorStatus", {}],
        ["divisionDrug", {}],
      ],
    },
  });

  HandleSetFilterMedError(controller, {
    data: { status: 1, ...(state.filterMedError || {}), user: null },
  });
};

export const GetSearchUserList: Handler = async (controller, params) => {
  let state = controller.getState();
  let previousUserList = [];
  if (params?.selected?.length > 0) {
    previousUserList =
      params?.selected?.map((selectId: any) =>
        state?.searchUserList?.find((item: any) => item.id === selectId)
      ) || [];
  }

  const user = await UserList.list({
    apiToken: controller.apiToken,
    params: {
      full_name: params.search,
    },
  });

  if (user[0]?.items) {
    user[0].items = user[0].items.filter((item: any) => item.first_name);
  }

  if (
    params?.selected?.length > 0 &&
    user[0]?.items &&
    previousUserList?.length > 0
  ) {
    previousUserList = previousUserList?.filter(
      (item: any) => !user[0]?.items?.find((usr: any) => usr.id === item.id)
    );
  }

  if (params.is_set_state && user[0].items) {
    if (params?.selected?.length > 0) {
      controller.setState({
        searchUserList: [...user[0].items, ...previousUserList] || [],
      });
    } else {
      controller.setState({ searchUserList: user[0].items || [] });
    }
  }

  return user;
};

export const GetSearchDivisionList: Handler = async (controller, params) => {
  const user = await DivisionList.list({
    apiToken: controller.apiToken,
    params: {
      name: params.search,
    },
  });

  return user;
};

export const GetSearchUserDivisionList: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  const user = await UserFilterDivisionHaveUser.get({
    apiToken: controller.apiToken,
    params: {
      // * division: <Division id> หา user ที่มี UserHasDivision ตรงกับ division ที่ส่งมา
      // *  pk: <User id>
      // *  role: <Role id> หา user จาก role
      // *  nurse_or_doctor: <Boolean> หา user เฉพาะที่เป็น พยาบาล หรือ แพทย์
      // *  full_name: <String> หา user จากชื่อ-สกุล ที่ตรง หรือมีคำที่เกี่ยวข้อง
      // *  name_username: <String> หา user ที่มี first_name | last_name | username จากคำที่ระบุ
      // *  name_code: <String> หา user ที่มี ที่มี first_name | last_name | username | employee code จากคำที่ระบุ
      division: controller.data.division,
      pk: state.django?.user?.id,
      full_name: params.search,
    },
  });

  return user;
};

export const GetMedicationErrorDetail: Handler = (controller, params) => {
  return MedicationErrorDetail.retrieve({
    apiToken: controller.apiToken,
    pk: params.id,
  });
};

export const HandleSetFilterMedError: Handler = (controller, params) => {
  controller.setState({ filterMedError: params.data });

  GetMedicationErrorList(controller, params.data);
};

export const GetMedicationErrorList: Handler = async (controller, params) => {
  const urlParams: any = {};

  if (params.user) {
    urlParams.edit_user = params.user;
  }
  if (params.division) {
    urlParams.order_perform_div = params.division;
  }
  if (params.status) {
    urlParams.status = params.status;
  }

  const medError = await MedicationErrorList.list({
    apiToken: controller.apiToken,
    params: {
      ...urlParams,
      offset: 0,
      limit: 40,
    },
  });

  controller.setState({ medErrorList: medError?.[0]?.items || [] });
};

export const GetMedicationErrorTypeDetailList: Handler = async (
  controller,
  params
) => {
  const typeDetailList = await MedicationErrorTypeDetailList.list({
    apiToken: controller.apiToken,
    params: { error_type: params.type },
  }); //ลักษณะ

  HandleSetMedErrorWorking(controller, {
    typeDetailOptions: mapOptions(typeDetailList[0]?.items || [], "name"),
  });
};

export const GetMedicationErrorWorking: Handler = async (
  controller,
  params
) => {
  // Get Master
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["medErrorRiskType", {}], //Risk
        ["medErrorCategory", {}], //Category
      ],
    },
  });

  const getDetail = await MedicationErrorDetailM.retrieve({
    apiToken: controller.apiToken,
    pk: params.id,
  });

  const detail = getDetail[0] || {}; //รายการที่คลาดเคลื่อน

  const getUserList = UserList.list({
    apiToken: controller.apiToken,
    params: {
      pk: detail.stakeholder,
    },
  }); //ผู้รับผิดชอบ

  const getTypeList = MedicationErrorTypeList.list({
    apiToken: controller.apiToken,
  }); //ประเภท

  const getTypeDetailList = MedicationErrorTypeDetailList.list({
    apiToken: controller.apiToken,
    params: { error_type: detail.type },
  }); //ลักษณะ

  const getCauseList = MedicationErrorCauseList.list({
    apiToken: controller.apiToken,
  }); //สาเหตุ

  const [userList, typeList, typeDetailList, causeList] = await Promise.all([
    getUserList,
    getTypeList,
    getTypeDetailList,
    getCauseList,
  ]);

  HandleSetMedErrorWorking(controller, {
    detail: {
      ...detail,
      type_detail: detail.type_detail ? detail.type_detail.split(",") : "",
      cause: detail.cause ? detail.cause.split(",") : "",
      cause_detail: detail.cause_detail ? detail.cause_detail.split(",") : "",
    },
    typeList: typeList?.[0]?.items || [],
    typeDetailOptions: mapOptions(typeDetailList[0]?.items || [], "name"),
    causeList: causeList?.[0]?.items || [],
    user: userList[0]?.items?.[0] || {},
    causeDetailOptions: detail.cause_detail
      ? [{ key: 1, value: detail.cause_detail, text: detail.cause_detail }]
      : [],
  });
};

export const HandleSetMedErrorWorking: Handler = (controller, params) => {
  const state = controller.getState();

  controller.setState({
    medErrorWorking: {
      ...(state.medErrorWorking || {}),
      ...params,
    },
  });
};

export const HandleSaveMedErrorList: Handler = async (controller, params) => {
  const data = params.data;

  if (params.action === "edit") {
    const updateRes = await MedicationErrorDetailM.update({
      apiToken: controller.apiToken,
      pk: data.id,
      data: {
        ...data,
        cause: data.cause.join(","),
        cause_detail: data.cause_detail.join(","),
        type_detail: data.type_detail.join(","),
      },
    });

    params.callback?.(updateRes[1] ? "error" : "success", updateRes[1]);
  } else if (params.action === "delete") {
    const delRes = await MedicationErrorDetailM.delete({
      apiToken: controller.apiToken,
      pk: data.id,
    });

    params.callback?.(delRes[1] ? "error" : "success", delRes[1]);
  }
};

export const HandleSavePostponeAnswer: Handler = async (controller, params) => {
  const state = controller.getState();
  const drugOrder = (state as any)[params.stateKey];

  const item = (drugOrder.items || []).find(
    (_: any, index: number) => index === params.index
  );

  let result: any = [null, null, null];

  if (drugOrder.isSupply) {
    result = await SupplyOrderPreview.create({
      data: {
        action: "REQUEST",
        encounter: state.selectedEncounter?.id,
        items: [item],
        status: "",
        type: "HOME_IPD",
        claim_payload: {},
      } as any,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });
  } else {
    result = await DrugOrderEstimateView.post({
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
      data: {
        ...params.data,
        claim_payload: drugOrder.claim_payload,
        items: [item],
      },
    });
  }

  const items = (drugOrder?.items || []).map((item: any, index: number) =>
    index === params.index
      ? {
        ...item,
        ...item.estimate,
        ...formatClaimPayload(result[0], result[0].items[0]),
      }
      : item
  );

  const claimPayload = {
    ...state.drugOrder?.claim_payload,
    payloads: state.drugOrder?.claim_payload?.payloads?.map((item: any) => {
      const payload = result[0].claim_payload?.payloads?.find(
        (acc: any) => acc.product_id === item.product_id
      );

      return payload ? payload : item;
    }),
  };

  controller.setState(
    {
      drugOrder: { ...state.drugOrder, items, claim_payload: claimPayload },
    },
    () => {
      CheckHaveQuestion(controller, {
        estimate: result[0],
        payload: params.data.claim_payload?.payloads?.[0],
        index: params.index,
        isAdd: params.isAdd,
        stateKey: params.stateKey,
      });
    }
  );
};

export const HandleSetAnswerDrugOrderItem: Handler = (controller, params) => {
  const state = controller.getState();
  const drugOrder = (state as any)[params.stateKey];

  const items = (drugOrder?.items || []).map((item: any, index: number) => {
    // console.log("item data: ", item, index, params.index)
    if (index === params.index) {
      item.estimate.claim_payload.payloads[0].answer_dict = {
        ...item.estimate.claim_payload.payloads[0].answer_dict,
        ...params.answer,
      };
    }

    return item;
  });

  const claimPayload = {
    ...drugOrder?.claim_payload,
    payloads: drugOrder?.claim_payload.payloads?.map((item: any) => {
      const findItem = items.find(
        (acc: any) => acc.product === item.product_id
      );
      const payload = findItem?.estimate?.claim_payload?.payloads?.[0] || null;
      return payload ? payload : item;
    }),
  };

  controller.setState({
    [params.stateKey]: {
      ...drugOrder,
      items,
      claim_payload: claimPayload,
    },
  });
};

export const HandleCheckDoctorCertificate: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  const [res, err, net] = await CheckDoctorCertificate.post({
    apiToken: controller.apiToken,
    data: {
      doctor_certificate_no: state.modDoctorCertificate?.doctorCertificate,
    },
  });
  if (err) {
    controller.setState({
      modDoctorCertificate: {
        ...state.modDoctorCertificate,
        openModConfirm: true,
      },
    });
    return;
  } else {
    // for not continue drugs
    if (params?.approvalCallBack) {
      controller.setState(
        {
          drugOrder: {
            ...state.drugOrder,
            doctor_certificate_no:
              state.modDoctorCertificate?.doctorCertificate,
            doctor_note: state.drugOrder?.doctor_note,
          },
          modDoctorCertificate: {
            open: false,
            narcoticDrugType: "",
            doctorCertificate: "",
            openModConfirm: false,
          },
        },
        async () => {
          await params.approvalCallBack();
        }
      );
      return;
    } else if (params?.orderType) {
      controller.setState(
        {
          drugOrder: {
            ...state.drugOrder,
            doctor_certificate_no:
              state.modDoctorCertificate?.doctorCertificate,
            doctor_note: state.drugOrder?.doctor_note,
          },
          modDoctorCertificate: {
            open: false,
            narcoticDrugType: "",
            doctorCertificate: "",
            openModConfirm: false,
          },
        },
        async () => {
          await ConfirmDrugOrder(controller, params);
        }
      );
      return;
    } else {
      const mod = (state as any).modDoctorCertificate;
      const action = mod.action;
      const card = mod.card;

      // for continueDrug
      controller.setState(
        {
          modDoctorCertificate: {
            open: false,
            narcoticDrugType: "",
            doctorCertificate: "",
            openModConfirm: false,
          },
        },
        async () => {
          await ConfirmDrugContinueOrder(controller, {
            doctorCertificate: state.modDoctorCertificate?.doctorCertificate,
            action,
            card,
          });
        }
      );
      return;
    }
  }
};

export const PrintDrugOrderFromDoctor: Handler = async (controller, params) => {
  const state = controller.getState();
  const [response, error, network] = await DrugOrderDoctorPrintView.get({
    params: {
      order: state.drugOrder?.id,
    },
    extra: {
      division: controller.data.division,
      device: (controller.data as any).device,
    },
    apiToken: controller.apiToken,
  });
};

export const HandlePrintPsychotropicForm: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: {
      ...state.loadingStatus,
      [params.loading]: true,
    },
  });
  if (params?.order_id) {
    const psychotropicRes = await DrugOrderPsychotropicPrint.create({
      apiToken: controller.apiToken,
      data: {
        order_id: params.order_id,
      },
    });
    if (psychotropicRes[1]) {
      console.log("error data: ", psychotropicRes[1]);
      controller.setState({
        loadingStatus: {
          ...state.loadingStatus,
          [params.loading]: false,
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: psychotropicRes[1],
        },
      });
      return;
    }
    if (psychotropicRes[0]?.pdf_b64data) {
      displayPDF(psychotropicRes[0].pdf_b64data);
    }
  } else {
    return controller.setState({
      loadingStatus: {
        ...state.loadingStatus,
        [params.loading]: false,
      },
      errorMessage: {
        ...state.errorMessage,
        [params.card]: ["ไม่พบ id ของใบรายการยา"],
      },
    });
  }
  controller.setState({
    loadingStatus: {
      ...state.loadingStatus,
      [params.loading]: false,
    },
  });
};

export const HandlePrintNarcoticForm: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: {
      ...state.loadingStatus,
      [params.loading]: true,
    },
  });
  if (params.order_id) {
    const [res, err, net] = await DrugOrderNarcoticPrint.create({
      apiToken: controller.apiToken,
      data: {
        order_id: params.order_id,
      },
    });
    if (err) {
      console.log("error data: ", err);
      controller.setState({
        loadingStatus: {
          ...state.loadingStatus,
          [params.loading]: false,
        },
        errorMessage: { ...state.errorMessage, [params.card]: [err] },
      });
      return;
    }
    if (res?.pdf_b64data) {
      displayPDF(res.pdf_b64data);
    }
  } else {
    return controller.setState({
      loadingStatus: {
        ...state.loadingStatus,
        [params.loading]: false,
      },
      errorMessage: {
        ...state.errorMessage,
        [params.card]: ["ไม่พบ id ของใบรายการยา"],
      },
    });
  }
  controller.setState({
    loadingStatus: {
      ...state.loadingStatus,
      [params.loading]: false,
    },
  });
};

// for selected drug to edit
export const GetDrugItemDetail: Handler = async (controller, params) => {
  const state = controller.getState();
  if (
    typeof params?.selectedDrugIndex === "number" &&
    (!state.selectedDrugItemDetail?.index ||
      state.selectedDrugItemDetail?.index !== params.selectedDrugIndex)
  ) {
    const [r, e, n] = await DrugDetail.retrieve({
      pk: params?.selectedDrugId,
      apiToken: controller.apiToken,
    });
    if (e) {
      controller.setState({
        showMessage: true,
        errorMessage: { ...state.errorMessage, CardDrugOrder: [e] },
      } as any);
      return;
    }
    if (r) {
      let options: { [key: string]: any } = {};
      for (const item of [
        ["unit", "limited_dosage_units"],
        ["route", "limited_routes"],
        ["site", "limited_sites"],
        ["frequency", "limited_frequencies"],
        ["method", "limited_methods"],
      ]) {
        const itemOptions = (state.masterOptions as { [key: string]: any })[
          item[0]
        ];
        options[item[0]] =
          r[item[1]].length === 0
            ? itemOptions
            : itemOptions.filter((option: any) =>
              r[item[1]].includes(option.key)
            );
      }

      controller.setState({
        selectedDrugItemDetail: { ...r, index: params.selectedDrugIndex },
        selectedDrugItemDetailOptions: { ...options },
      });
    }
  }
};

export const HandleOnRemoveDrug: Handler = async (controller, params) => {
  const state = controller.getState();
  if (params?.action === "need_confirm") {
    if (params?.removeDrugId && typeof params?.drugIndex === "number")
      controller.setState({
        modConfirmDeleteDrug: {
          open: true,
          product: params.removeDrugId,
          index: params.drugIndex,
        },
      });
    return;
  } else if (params?.action === "confirm") {
    controller.setState({
      drugOrder: {
        ...state.drugOrder,
        items: state.drugOrder?.items?.filter(
          (drug: any, index: number) =>
            params.removeDrugId !== drug.product || params.drugIndex !== index
        ),
      },
      modConfirmDeleteDrug: {
        open: false,
        product: null,
        index: null,
      },
    });
    return;
  } else if (params?.action === "unlink_mixture") {
    let drugOrderItems = [...state.drugOrder?.items];
    drugOrderItems?.forEach((item: any) => {
      if (item.drug_admixture_order_key === params.removeAdmixtureKey) {
        item.is_solvent = false;
        item.drug_admixture_order_key = "";
      }
    });
    controller.setState({
      drugOrder: { ...state.drugOrder, items: drugOrderItems },
    });
  }
};

export const HandleGetEmployeeTokenization: Handler = async (
  controller,
  params
) => {
  // params should only have code
  const [response, error, network] = await UserTokenizeView.post({
    apiToken: controller.apiToken,
    data: { ...params },
  });

  return [response, error, network];
};

export const HandleGetDeliverDrugTokenization: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  controller.setState({
    drugDelivery: {
      ...state.drugDelivery,
      loading: true,
    },
  });

  const [response, error, network] = await HandleGetEmployeeTokenization(
    controller,
    params
  );
  if (error) {
    controller.setState({
      drugDelivery: {
        ...state.drugDelivery,
        error: error,
        loading: false,
      },
    });
    return;
  }
  // console.log("decodeURIComponent", decodeURIComponent(atob(response["token"])))
  controller.setState({
    drugDelivery: {
      ...state.drugDelivery,
      token: response?.token || "",
      employeeName:
        decodeURIComponent(atob(response?.token.split(".")[1])) || "",
      loading: false,
    },
  });
};

export const HanleOnLogDrugOrder: Handler = async (controller, params) => {
  const state = controller.getState();

  const [response, error, network] = await DrugOrderActionLogList.list({
    pk: state.selectedDrugOrderWorking?.pk,
    apiToken: controller.apiToken,
  });

  controller.setState({ drugOrderLog: response });
};

export const PutDrugOrderItemPrintLabel: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  if (params.card) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: true },
    });
  }

  const result = await DrugOrderItemPrintLabel.update({
    apiToken: controller.apiToken,
    pk: params.id,
    data: {
      action: "REPRINT",
      print_drug_label: true,
      ...params.data,
    } as any,
    extra: {
      device: (controller.data as any).device,
      division: controller.data.division,
    },
  });

  if (params.card) {
    if (result[1]) {
      return controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { showMessage: true, error: result[1] },
        },
        loadingStatus: { ...state.loadingStatus, [params.card]: false },
      });
    }

    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: null },
      successMessage: { ...state.successMessage, [params.card]: true },
      loadingStatus: { ...state.loadingStatus, [params.card]: false },
    });
  }
};

export const HandleReMedication: Handler = async (controller, params) => {
  const state = controller.getState();
  if (params?.action === "open") {
    controller.setState({
      modReMedication: {
        ...state.modReMedication,
        open: true,
      },
    });
  } else if (params?.action === "show_drug") {
    let data = [...(state.modReMedication?.remedData as any)].map(
      (item: any) => {
        return item?.order === params.orderId
          ? { ...item, show: !item.show }
          : { ...item };
      }
    );
    controller.setState({
      modReMedication: {
        ...state.modReMedication,
        remedData: data,
      },
    });
  } else if (params?.action === "search") {
    const [res, err, net] = await PastMedicationList.get({
      apiToken: controller.apiToken,
      params: {
        patient: state?.selectedPatient?.id || null,
        division: (state?.modReMedication?.division as any)?.id || null,
        doctor: (state?.modReMedication?.doctor as any)?.id || null,
      },
    });
    if (err) {
      return controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: {
            error: err.toString(),
          },
        },
      });
    }

    let data: any = [];
    if (res?.items?.length > 0) {
      res?.items?.forEach((order: any) => {
        data = data.concat({ ...order, orderId: order.id, show: true });
        if (order?.items?.length > 0) {
          data = data.concat(
            order.items.map((drug: any) => ({
              ...drug,
              name: drug.product_name,
              drugId: drug.id,
              order: order.id,
              show: false,
            }))
          );
        }
      });
    }
    return controller.setState({
      modReMedication: {
        ...state.modReMedication,
        remedData: data,
      },
    });
  } else if (params?.action === "select_order") {
    let selectedDrugs = [...(state.modReMedication?.selectedDrugs as any)];
    let selectedOrders = [...(state.modReMedication?.selectedOrders as any)];

    // find match drug
    let targetDrugs = (state.modReMedication?.remedData as any)
      .filter((item: any) => item?.order === params.orderId)
      .map((item: any) => item.drugId);
    // let targetDrug = state.modReMedication.remedData.find(item => item?.drugId === params.drugId)
    if (selectedOrders.includes(params.orderId)) {
      selectedOrders = selectedOrders.filter((item) => params.orderId !== item);
      selectedDrugs = selectedDrugs.filter(
        (item) => !targetDrugs.includes(item)
      );
    } else {
      selectedOrders.push(params.orderId);
      selectedDrugs = selectedDrugs.concat(
        targetDrugs.filter((item: any) => !selectedDrugs.includes(item))
      );
    }

    return controller.setState({
      modReMedication: {
        ...state.modReMedication,
        selectedDrugs: selectedDrugs,
        selectedOrders: selectedOrders,
      },
    });
  } else if (params?.action === "select_drug") {
    let selectedDrugs = [...(state.modReMedication?.selectedDrugs as any)];
    let selectedOrders = [...(state.modReMedication?.selectedOrders as any)];
    // find to remove or check order if all items checked
    let targetDrug = (state.modReMedication?.remedData as any).find(
      (item: any) => item?.drugId === params.drugId
    );
    let targetOrder = (state.modReMedication?.remedData as any).find(
      (item: any) => item?.orderId === targetDrug.order
    );

    if (params.drugId && selectedDrugs.includes(params.drugId)) {
      selectedDrugs = selectedDrugs.filter((item) => item !== params.drugId);
      selectedOrders = selectedOrders.filter(
        (item) => item !== targetDrug?.order
      );
    } else {
      selectedDrugs.push(params.drugId);
      // find match all item
      let allOrderItem = (state.modReMedication?.remedData as any)
        .filter((item: any) => item.order && item.order === targetDrug.order)
        .map((item: any) => item.drugId);
      let selectedDrugItem = selectedDrugs.filter((item) =>
        allOrderItem.includes(item)
      );
      if (selectedDrugItem.length === targetOrder.items?.length) {
        selectedOrders.push(targetDrug.order);
      }
    }

    return controller.setState({
      modReMedication: {
        ...state.modReMedication,
        selectedDrugs: selectedDrugs,
        selectedOrders: selectedOrders,
      },
    });
  } else if (params?.action === "add_to_order") {
    let data = [...(state.modReMedication?.remedData as any)]
      .filter(
        (drug: any) =>
          drug.order &&
          (state.modReMedication?.selectedDrugs as any).includes(drug.drugId)
      )
      .map((drug) => {
        let newDrug = drug;
        delete newDrug["id"];
        delete newDrug["drugId"];
        delete newDrug["show"];
        delete newDrug["order"];
        return newDrug;
      });

    return controller.setState(
      {
        modReMedication: {
          doctor: null,
          division: null,
          checkedDoctor: false,
          checkedDivision: false,
          remedData: [],
          selectedDrugs: [],
          selectedOrders: [],
          open: false,
        },
      },
      async () =>
        await AddToOrder(controller, {
          orderType: params.orderType,
          drugItems: data,
        })
    );
  } else if (params?.action === "close") {
    return controller.setState({
      modReMedication: {
        doctor: null,
        division: null,
        checkedDoctor: false,
        checkedDivision: false,
        remedData: [],
        selectedDrugs: [],
        selectedOrders: [],
        open: false,
      },
    });
  }
};

export const CreateDrugOrderItemClaim: Handler = async (controller, params) => {
  const estimate = await DrugOrderItemClaim.create({
    apiToken: controller.apiToken,
    data: params.data,
  });

  CheckHaveQuestion(controller, {
    estimate: { ...estimate[0], items: [params.item] },
    payload: params.isEdit ? params.data.claim_payload.payloads[0] : {},
    index: params.questionIndex,
    stateKey: params.stateKey,
    haveQuestion: params.haveQuestion,
  });

  const state = controller.getState();

  controller.setState({
    selectedDrugOrderWorking: {
      ...state.selectedDrugOrderWorking,
      items: (state.selectedDrugOrderWorking.items || []).map(
        (item: any, index: any) =>
          index === params.itemIndex
            ? { ...item, claim_payload: estimate[0].claim_payload }
            : item
      ),
    },
  });
};

export const UpdateDrugOrderWorking: Handler = async (controller, params) => {
  const type = params.data.type;

  delete params.data.type;

  const orderResult = await DrugAPI[`${type}_OPD`].put({
    pk: params.data.pk,
    apiToken: controller.apiToken,
    data: {
      ...params.data,
      action: "EDIT",
    },
    extra: { division: controller.data.division },
  });

  const state = controller.getState();

  controller.setState({
    selectedDrugOrderWorking: {
      ...state.selectedDrugOrderWorking,
      items: orderResult[0]?.items || [],
    },
  });
};

export const HandleNurseOrderPerformed: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  if (params?.action === "update_order") {
    // separate oneday + continue
    let orderItems = [];
    if (params?.orderType === "oneday") {
      orderItems = [...(state.nurseOrderPerformed?.selectedOneDayOrder as any)];

      if (orderItems.includes(params?.orderId)) {
        orderItems = orderItems.filter((item) => item !== params.orderId);
      } else {
        orderItems.push(params?.orderId);
      }
      controller.setState({
        nurseOrderPerformed: {
          ...state.nurseOrderPerformed,
          selectedOneDayOrder: orderItems,
        },
      });
    } else if (params?.orderType === "continue") {
      orderItems = [
        ...(state.nurseOrderPerformed?.selectedContinueOrder as any),
      ];

      if (orderItems.includes(params?.orderId)) {
        orderItems = orderItems.filter((item) => item !== params.orderId);
      } else {
        orderItems.push(params?.orderId);
      }
      controller.setState({
        nurseOrderPerformed: {
          ...state.nurseOrderPerformed,
          selectedContinueOrder: orderItems,
        },
      });
    }
    return;
  } else if (params?.action === "select_all") {
    if (params.isChecked) {
      let orderContinue = [...params.continueOrder]
        .filter(
          (order) =>
            !(state.nurseOrderPerformed?.selectedContinueOrder as any).includes(
              order.id
            )
        )
        .map((order) => order.id);
      let orderOneDay = [...params.oneDayOrder]
        .filter(
          (order) =>
            !(state.nurseOrderPerformed?.selectedOneDayOrder as any).includes(
              order.id
            )
        )
        .map((order) => order.id);

      controller.setState({
        nurseOrderPerformed: {
          ...state.nurseOrderPerformed,
          selectedContinueOrder: [
            ...(state.nurseOrderPerformed?.selectedContinueOrder as any),
          ].concat(orderContinue),
          selectedOneDayOrder: [
            ...(state.nurseOrderPerformed?.selectedOneDayOrder as any),
          ].concat(orderOneDay),
        },
      });
    } else {
      controller.setState({
        nurseOrderPerformed: {
          ...state.nurseOrderPerformed,
          selectedContinueOrder: [],
          selectedOneDayOrder: [],
        },
      });
    }
    return;
  } else if (params?.action === "order_performed") {
    return controller.setState({
      nurseOrderPerformed: {
        ...state.nurseOrderPerformed,
        modOpen: true,
      },
    });
  } else if (params?.action === "tokenize") {
    controller.setState({
      nurseOrderPerformed: {
        ...state.nurseOrderPerformed,
        nurseLoading: true,
      },
    });
    const tokenize = await HandleGetEmployeeTokenization(controller, {
      code: params.code,
    });
    if (tokenize[1]) {
      return controller.setState({
        nurseOrderPerformed: {
          ...state.nurseOrderPerformed,
          nurseLoading: false,
          nurseError: true,
        },
      });
    }

    return controller.setState({
      nurseOrderPerformed: {
        ...state.nurseOrderPerformed,
        nurseLoading: false,
        nurseName:
          decodeURIComponent(atob(tokenize[0]?.token.split(".")[1])) || "",
        nurseToken: tokenize[0]?.token,
      },
    });
  } else if (params?.action === "confirm_performed") {
    const performedAck = await DoctorOrderView.put({
      apiToken: controller.apiToken,
      data: {
        order_ack_by: state.nurseOrderPerformed?.nurseToken || "",
        order_ack_date: state.nurseOrderPerformed?.performedDate || "",
        order_ack_time: state.nurseOrderPerformed?.performedTime || "",
        orders: params.orderItems.filter(
          (item: any) =>
            state.nurseOrderPerformed?.selectedContinueOrder?.includes(
              item.id
            ) ||
            state.nurseOrderPerformed?.selectedOneDayOrder?.includes(item.id)
        ),
      },
      extra: {
        division: controller.data?.division,
        device: (controller.data as any)?.device,
      },
    });
    if (performedAck[1]) {
      console.log("error: ", performedAck[1]);
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { showMessage: true, error: performedAck[1] },
          },
        });
      }
      return;
    } else {
      return controller.setState(
        {
          openModSuccessOrderSummary: {
            open: true,
          },
          nurseOrderPerformed: {
            modOpen: false,
            selectedOneDayOrder: [],
            selectedContinueOrder: [],
            performedTime: moment().format("HH:mm"),
            performedDate: formatDate(moment()),
            nurseName: null,
            nurseToken: null,
            nurseError: null,
            nurseLoading: null,
          },
        },
        async () => {
          await controller.handleEvent({
            message: "GetIPDOrderSummary",
            params: {},
          });
        }
      );
    }
  }
};

// *********************************** VERIFY DRUG FUNCTION *******************************************
export const HandleCheckDrugDuplicatedMedReconcileIPDAdmit: Handler = async (
  controller,
  params
) => {
  let items = params.items;
  const { selectedEncounter } = controller.getState();
  const checkDupReconcileIPDAdmit =
    await CheckDrugDuplicateMedReconcileView.post({
      apiToken: controller.apiToken,
      data: {
        encounter: selectedEncounter?.id,
        items: params.items,
      },
    });

  if (checkDupReconcileIPDAdmit[0]?.items) {
    items = items.map((drug: any) => {
      let dupDrugMRAdmit = checkDupReconcileIPDAdmit[0]?.items.find(
        (drugMR: any) => drug.product === drugMR.product
      );
      return dupDrugMRAdmit?.mr_message
        ? { ...drug, mr_duplicate: true, mr_message: dupDrugMRAdmit.mr_message }
        : { ...drug };
    });
  }

  return items;
};

// *****************************************************************************************************

export const HandleModDrugLabInteraction: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  if (params?.action === "open" && params.data?.product) {
    const drugLabInteract = await DrugLabInteractionCheck.get({
      apiToken: controller.apiToken,
      params: {
        drugs: params.data?.product,
        patient: (state.selectedPatient as any)?.id,
      },
      extra: {
        division: controller.data?.division,
        device: (controller.data as any)?.device,
      },
    });
    controller.setState({
      modDrugLabInteraction: {
        ...state.modDrugLabInteraction,
        open: true,
        interactionItems: drugLabInteract[0]?.items,
        orderItem: { ...params.data },
        note: params?.data?.note_drug_lab_interaction,
      },
    });
  } else if (params?.action === "close") {
    controller.setState({
      modDrugLabInteraction: {
        ...state.modDrugLabInteraction,
        open: false,
        interactionItems: [],
        orderItem: {},
        note: "",
      },
    });
  }
};

export const HandleModDrugDisease: Handler = async (controller, params) => {
  const state = controller.getState();
  if (params?.action === "open" && params.data?.product) {
    const drugDisease = await DrugDiseaseCheck.get({
      apiToken: controller.apiToken,
      params: {
        drugs: params.data?.product,
        patient: (state.selectedPatient as any)?.id,
      },
      extra: {
        division: controller.data?.division,
        device: (controller.data as any)?.device,
      },
    });
    controller.setState({
      modDrugDisease: {
        ...state.modDrugDisease,
        open: true,
        diseaseItems: drugDisease[0]?.items,
        orderItem: { ...params.data },
        note: params?.data?.note_drug_disease,
      },
    });
  } else if (params?.action === "close") {
    controller.setState({
      modDrugDisease: {
        ...state.modDrugDisease,
        open: false,
        diseaseItems: [],
        orderItem: {},
        note: "",
      },
    });
  }
};

export const HandleModDrugInteraction: Handler = async (controller, params) => {
  const state = controller.getState();
  if (params?.action === "open" && params.data?.product) {
    let targetItem =
      params?.targetStateOrder === "drugContinuePlanItems"
        ? [...(state.drugContinuePlanItems || [])]
        : [
          ...(state?.[
            params?.targetStateOrder as
            | "drugOrder"
            | "selectedDrugOrderWorking"
            | "medRecordSummary"
          ]?.items || []),
        ];
    const drugInteraction = await DrugInteractionCheck.list({
      apiToken: controller.apiToken,
      params: {
        patient: state.selectedPatient?.id,
        drugs: [params.data?.product]
          .concat((targetItem || []).map((item: any) => item.product))
          .join(","),
      },
      extra: {
        division: controller.data?.division,
        device: (controller.data as any)?.device,
      },
    });

    const items = (drugInteraction[0]?.items || []).filter((item: any) =>
      item.members.includes(params.data?.product)
    );
    const diFeedback = !drugInteraction[1] && items.length > 0;

    controller.setState({
      modDrugInteraction: {
        ...state.modDrugInteraction,
        open: diFeedback,
        drugItems: items,
        orderItem: { ...params.data },
        note: params?.data?.note_drug_interaction,
      },
    });
  } else if (params?.action === "edit_item") {
    let drugInteractionData: any = [];
    state.modDrugInteraction?.drugItems?.forEach((drugI: any) => {
      drugInteractionData = drugInteractionData.concat(drugI.members);
    });

    controller.setState(
      {
        drugOrder: {
          ...state.drugOrder,
          items: [...(state.drugOrder.items || [])].map((item: any) =>
            drugInteractionData.includes(item.product)
              ? { ...item, ...params.editItem }
              : { ...item }
          ),
        },
      },
      async () => {
        await AddToOrder(controller, { ...params, action: "" });
      }
    );
  } else if (params?.action === "close") {
    controller.setState({
      modDrugInteraction: {
        ...state.modDrugInteraction,
        open: false,
        drugItems: [],
        orderItem: {},
        note: "",
      },
    });
  }
};

export const HandleModRecontinueMedication: Handler = async (
  controller,
  params
) => {
  const { modRecontinueMed, selectedEncounter, errorMessage } =
    controller.getState();
  console.log("HandleModRecontinueMedication", params.action);
  if (params.action === "OPEN") {
    controller.setState({
      modRecontinueMed: {
        ...modRecontinueMed,
        open: true,
      },
    });
  } else if (params.action === "SEARCH") {
    const continuePlanMed = await DrugContinuePlanForIPDHomeList.list({
      apiToken: controller.apiToken,
      params: {
        encounter: selectedEncounter?.id || "",
        order_by: params?.doctor || "",
        order_div: params?.division || "",
      },
    });

    if (continuePlanMed[1]) {
      return controller.setState({
        errorMessage: {
          ...errorMessage,
          ...(params.card ? { [params.card]: continuePlanMed[1] } : {}),
        },
      });
    }

    // ?.map((item: any) => item.children)

    let orderItemMap: any = [];
    if (continuePlanMed[0]?.items) {
      continuePlanMed[0].items.forEach((item: any) => {
        orderItemMap = orderItemMap.concat(item.children);
      });
    }

    return controller.setState({
      modRecontinueMed: {
        ...modRecontinueMed,
        continuePlanItems: orderItemMap || [],
      },
    });
  } else if (params.action === "ADD_TO_ORDER") {
    let drugItems: any = modRecontinueMed?.continuePlanItems
      ?.filter((drug: any) => params.selectedItems.includes(drug.id))
      .map((drug: any) => ({ ...drug, drug_interaction_in: "ORDER" }));

    // preprocess data
    drugItems?.forEach((drug: any, index: number) => {
      delete drugItems[index]["id"];
      delete drugItems[index]["order_item_id"];
      delete drugItems[index]["order_item_checked"];
      delete drugItems[index]["order_time_label"];
    });
    await AddToOrder(controller, {
      card: params.card,
      orderType: params.orderType,
      drugItems,
    });
    HandleModRecontinueMedication(controller, { action: "CANCEL" });
  } else if (params.action === "CANCEL") {
    controller.setState({
      modRecontinueMed: {
        open: false,
        selectedItems: [],
        continuePlanItems: [],
      },
    });
  }
};

export const HandleDrugRecommendation: Handler = async (controller, params) => {
  const state = controller.getState();
  if (params?.action === "open") {
    // const recommendation = await DrugRecommendationView.get({
    //   apiToken: controller.apiToken,
    //   params: { order: params.drugOrderId },
    //   extra: {
    //     division: controller.data.division,
    //     device: (controller.data as any).device,
    //   },
    // });

    // if (recommendation[1]) {
    //   return controller.setState({
    //     errorMessage: { ...state.errorMessage, [params.card]: recommendation },
    //   });
    // }

    let reportCounting = 0;
    let data = [...state.selectedDrugOrderWorking?.recommendationFile].map(
      (drug: any) => {
        return {
          ...drug,
          items: drug.items.map((item: any) => {
            reportCounting += 1;
            return { ...item, number: reportCounting };
          }),
        };
      }
    );
    controller.setState({
      modDrugRecommendation: {
        open: true,
        data: data || [],
      },
    });
  } else if (params?.action === "select_report") {
    let formData: any[] = [];
    state.modDrugRecommendation?.data?.forEach((drug: any) => {
      drug.items.forEach((report: any) => {
        if (params.selectedReport.includes(report.number))
          formData.push(report.file);
      });
    });

    if (formData.length > 0) {
      let pdfFetchData = await Promise.all(
        formData.map((item: any) => {
          return fetch(item).then((res: any) => {
            if (res.status === 200) {
              console.log("response: ", res);
              return res.arrayBuffer();
            }
            return false;
          });
        })
      );

      let pdfData = await Promise.all(
        pdfFetchData
          .filter((item: any) => item)
          .map((item: any) => PDFDocument.load(item))
      );
      const processPdf = await PDFDocument.create();
      const pageData = await Promise.all(
        pdfData.map((report) =>
          processPdf.copyPages(report, report.getPageIndices())
        )
      );
      pageData?.forEach((report: any) =>
        report.forEach((page: any) => processPdf.addPage(page))
      );

      const pdfBase64 = await processPdf.saveAsBase64();
      const blob = base64toBlob("data:application/pdf;base64," + pdfBase64);
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }

    await HandleDrugRecommendation(controller, { action: "close" });
  } else if (params?.action === "close") {
    controller.setState({
      modDrugRecommendation: {
        open: false,
        data: [],
      },
    });
  }
};

export const HandleGetDrugDetail: Handler = (controller, params) => {
  return DrugDetail.retrieve({
    pk: params.id,
    apiToken: controller.apiToken,
  });
};

export const GetLotNoExp: Handler = async (controller, params) => {
  const division = controller.data.masterData?.divisionTypeDrug?.find(
    (item: any) => item.name === params.orderPerformDiv
  );

  const [stock, plan] = await Promise.all([
    ProductStockList.list({
      apiToken: controller.apiToken,
      pk: params.productId,
      params: {
        active: true,
        storage: division?.storage,
      },
    }),
    DrugOrderItemDispensePlanList.list({
      apiToken: controller.apiToken,
      pk: params.orderItem,
    }),
  ]);

  const stockItems: any[] = (stock[0]?.items || []).filter(
    (item: any) => item.lot?.mfc_no !== "N/A"
  );
  const planItems: any[] = plan[0]?.items || [];

  const mergeItems = stockItems.map((item) => {
    const plan =
      planItems.find(
        (acc) =>
          acc.lot?.id === item.lot?.id &&
          acc.storage === item.storage?.id &&
          acc.stock === item.id
      ) || {};
    return {
      ...item,
      disp_qty:
        typeof plan.quantity === "number"
          ? Math.abs(plan.quantity)
          : plan.quantity || "",
      order_item: params.orderItem,
      plan_id: plan.id || null,
    };
  });

  controller.setState({
    lotNoExpList: mergeItems,
  });
};

export const UpdateLotNoExp: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const promiseArr = params.data.map((item: any) => {
    const api = item.plan_id
      ? DrugOrderItemDispensePlanUpdate.patch
      : DrugOrderItemDispensePlanList.create;

    return api({
      apiToken: controller.apiToken,
      pk: params.orderItem,
      plan_id: item.plan_id,
      data: {
        lot: item.lot?.id,
        quantity: -Number(item.disp_qty),
        storage: item.storage?.id,
        stock: item.id,
        order_item: params.orderItem,
      },
    });
  });

  const reponse = await Promise.all(promiseArr);

  if (reponse.every((res) => !res[1])) {
    params.onSuccess?.();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.card]: reponse.map((res) => res[1]),
      },
    });
  }
};

export const AllVerifyDrug: Handler = async (controller, params) => {
  const state = controller.getState();
  if (params?.action === "make_verify") {
    if (!(params.drugItems?.length > 0)) return;
    const drugList = [...(params.drugItems || [])]
      .map((item: any) => item.product)
      .join(",");

    const lastesTriage = FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        patient: state.selectedPatient?.id,
        form_code: "CardPreAssessment",
        form_version: "1.0",
      },
    });

    const ADR = await AdverseReactionCheckListView.get({
      params: {
        patient: state.selectedPatient?.id,
        drugs: drugList,
      },
      apiToken: controller.apiToken,
    });

    const DI = DrugInteractionCheck.list({
      params: {
        drugs: drugList,
      },
      apiToken: controller.apiToken,
    });

    const DIL = DrugLabInteractionCheck.get({
      apiToken: controller.apiToken,
      params: {
        drugs: drugList,
        patient: (state.selectedPatient as any)?.id,
      },
      extra: {
        division: controller.data?.division,
        device: (controller.data as any)?.device,
      },
    });

    const DD = DrugDiseaseCheck.get({
      apiToken: controller.apiToken,
      params: {
        drugs: drugList,
        patient: (state.selectedPatient as any)?.id,
      },
      extra: {
        division: controller.data?.division,
        device: (controller.data as any)?.device,
      },
    });

    const [drugAdverse, drugInteraction, drugLabInteraction, drugDisease] =
      await Promise.all([ADR, DI, DIL, DD]);

    const error = [];

    if (drugAdverse[1]) {
      error.push(drugAdverse[1]);
    }
    if (drugInteraction[1]) {
      error.push(drugInteraction[1]);
    }
    if (drugLabInteraction[1]) {
      error.push(drugLabInteraction[1]);
    }
    if (drugDisease[1]) {
      error.push(drugDisease[1]);
    }
    if (error?.length > 0) {
      return controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: error },
      });
    }

    let noteAll: any = {};
    drugAdverse[0].forEach((item: any, index: number) => {
      noteAll[`drug_adverse_note_${index}`] = "";
    });

    drugInteraction[0]?.items.forEach((item: any, index: number) => {
      noteAll[`drug_interaction_note_${index}`] = "";
    });

    drugLabInteraction[0]?.items.forEach(
      (item: any, index: number) =>
        (noteAll[`drug_lab_interaction_note_${index}`] = "")
    );

    drugDisease[0]?.items.forEach(
      (item: any, index: number) => (noteAll[`drug_disease_note_${index}`] = "")
    );

    let isOpenMod = Object.keys(noteAll).length > 0;

    if (isOpenMod) {
      controller.setState({
        modAllVerifyDrug: {
          open: isOpenMod,
          orderType: params?.orderType,
          drugItems: params.drugItems,
          presentDrugs: [
            ...(lastesTriage[0]?.data?.medication?.present_drugs || []),
          ],
          adverseDrugs: drugAdverse[0] || [], // prepare soon
          interactionDrugs: drugInteraction[0]?.items || [],
          interactionLabDrugs: drugLabInteraction[0]?.items || [],
          diseaseDrugs: drugDisease[0]?.items || [],
          note: noteAll,
          requireNote: [],
        },
      });
    } else {
      await AddToOrder(controller, {
        orderType: params?.orderType,
        drugItems: params.drugItems,
      });
    }
  } else if (params?.action === "confirm_note") {
    let allNote = Object.keys({ ...state.modAllVerifyDrug?.note });
    let noteData: any = { ...state.modAllVerifyDrug?.note };
    let validateErr: any[] = [];
    allNote.forEach((note_name: string, index: number) => {
      if (!noteData[note_name]) {
        validateErr.push(note_name);
      }
    });

    if (validateErr?.length > 0) {
      return controller.setState({
        modAllVerifyDrug: {
          ...state.modAllVerifyDrug,
          requireNote: [...validateErr],
        },
      });
    }
    const {
      adverseDrugs,
      interactionDrugs,
      interactionLabDrugs,
      diseaseDrugs,
    } = { ...state.modAllVerifyDrug };
    let drugItems = [...(state.modAllVerifyDrug?.drugItems || [])];
    let drugItemIds = drugItems.map((item: any) => item.product);

    adverseDrugs?.forEach((item: any, index: number) => {
      let noteADR = noteData[`drug_adverse_note_${index}`];
      let indexFind = drugItemIds.indexOf(item.drug_id);
      if (indexFind !== -1) {
        drugItems[indexFind] = {
          ...drugItems[indexFind],
          note_drug_interaction: drugItems[indexFind]?.["note_adverse_reaction"]
            ? `${drugItems[indexFind]?.["note_adverse_reaction"]} ,${noteADR}`
            : noteADR,
        };
      }
    });

    interactionDrugs?.forEach((item: any, index: number) => {
      let noteDI = noteData[`drug_interaction_note_${index}`];
      item.members?.forEach((member: number) => {
        let indexFind = drugItemIds.indexOf(member);
        if (indexFind !== -1) {
          drugItems[indexFind] = {
            ...drugItems[indexFind],
            note_drug_interaction: drugItems[indexFind]?.[
              "note_drug_interaction"
            ]
              ? `${drugItems[indexFind]?.["note_drug_interaction"]} ,${noteDI}`
              : noteDI,
          };
        }
      });
    });

    interactionLabDrugs?.forEach((item: any, index: number) => {
      let noteDLI = noteData[`drug_lab_interaction_note_${index}`];
      let indexFind = drugItemIds.indexOf(item.product_id);
      if (indexFind !== -1) {
        drugItems[indexFind] = {
          ...drugItems[indexFind],
          note_drug_lab_interaction: drugItems[indexFind]?.[
            "note_drug_lab_interaction"
          ]
            ? `${drugItems[indexFind]?.["note_drug_lab_interaction"]} ,${noteDLI}`
            : noteDLI,
        };
      }
    });

    diseaseDrugs?.forEach((item: any, index: number) => {
      let noteDDD = noteData[`drug_disease_note_${index}`];
      let indexFind = drugItemIds.indexOf(item.drug_id);
      if (indexFind !== -1) {
        drugItems[indexFind] = {
          ...drugItems[indexFind],
          note_drug_lab_interaction: drugItems[indexFind]?.["note_drug_disease"]
            ? `${drugItems[indexFind]?.["note_drug_disease"]} ,${noteDDD}`
            : noteDDD,
        };
      }
    });

    console.log("All verify drugItems: ", drugItems);

    controller.setState(
      {
        modAllVerifyDrug: {
          open: false,
          drugItems: [],
          presentDrugs: [],
          adverseDrugs: [],
          interactionDrugs: [],
          interactionLabDrugs: [],
          diseaseDrugs: [],
          note: {},
          requireNote: [],
          orderType: null,
        },
      },
      async () => {
        await AddToOrder(controller, {
          orderType: state.modAllVerifyDrug?.orderType,
          drugItems,
        });
      }
    );

    // controller.handleEvent({
    //   message: "AddToOrder",
    //   params: {
    //     orderType: state.modAllVerifyDrug?.orderType,
    //     drugItems,
    //   },
    // });
  } else if (params?.action === "change_note") {
    controller.setState({
      modAllVerifyDrug: {
        ...state.modAllVerifyDrug,
        note: {
          ...state.modAllVerifyDrug?.note,
          [params.name]: params.value,
        },
      },
    });
  } else if (params?.action === "cancel") {
    controller.setState({
      modAllVerifyDrug: {
        open: false,
        drugItems: [],
        presentDrugs: [],
        adverseDrugs: [],
        interactionDrugs: [],
        interactionLabDrugs: [],
        diseaseDrugs: [],
        note: {},
        requireNote: [],
        orderType: null,
      },
    });
  }
};

export const DrugPackageAddToOrder: Handler = async (controller, params) => {
  const state = controller.getState()

  const items = params.items.map((item: any) => ({
    active: true,
    id: item.id,
    product: item.drug,
    product_name: item.drug_name,
    quantity: 1,
    dose: 1
  }))

  await controller.setState({
    DrugOrderTemplateSequence: {
      ...state.DrugOrderTemplateSequence,
      sequenceIndex: "Action",
      selectedDrugOrderTemplates: [1],
      selectedDrugOrderItem: items,
      drugOrderTemplateList: [{ id: 1, items }],
      open: false
    },
    drugPackageAlertList: []
  })

  controller.handleEvent({
    message: "RunSequence",
    params: {
      sequence: "DrugOrderTemplate",
      action: "add_to_order",
      orderType: params.orderType,
      card: params.card
    },
  });

}

export const getDefaultOrderBy: Handler = async (controller, params) => {
  const state = controller.getState()

  let doctor = state.currentDoctor || null;
  const orderBy = state.drugOrder?.order_by;

  if (orderBy && doctor?.id !== orderBy && params.defaultOrderBy) {
    [doctor] = await DoctorDetail.retrieve({
      apiToken: controller.apiToken,
      pk: orderBy
    })
  }

  const selectedDoctor = doctor?.id && {
    selectedOrderBy: doctor,
    order_by: doctor.id
  }

  return params.defaultOrderBy && !!selectedDoctor ? selectedDoctor : {}
}

// utilities
const mapOptions = (list: any[], valueKey = "id") => {
  return list.map((item: any) => ({
    key: item.id,
    value: item[valueKey],
    text: item.name,
  }));
};

const stringToFixedValueFloat = (data: string) => {
  let text = data
  let textSplit = data?.split(".")
  if (textSplit?.length > 1) {
    let textIntpart = parseInt(textSplit[0])
    let textFloatPart = parseInt(textSplit[1])
    if (textFloatPart > 0) {
      let zeroCount = textSplit[1].split("0")
      let notZeroNum = zeroCount?.filter((num: string) => num)
      let lastNumIndex = zeroCount.indexOf(notZeroNum[notZeroNum?.length - 1])
      let numberShow = zeroCount
        .slice(0, lastNumIndex + 1)
        .map((num: string) => (num ? num : "0"))
        .join("")
      text = `${textIntpart.toString()}.${numberShow}`
    } else {
      text = textIntpart.toString()
    }
  }
  return text
}