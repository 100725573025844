
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
import ImagingQueueSerializer from '../types/ImagingQueueSerializer_apps_IME'


const HOST = `${config.API_HOST}`
/* ['generics.ListAPIView'] */
/* params: 'division', 'xray_group', 'radiologist', 'for_radiologist', 'status', 'billing_status', 'encounter_type', 'patient', 'order_number', 'order_date_start', 'order_date_end', 'encounter', 'exclude_cancel' */
/* data:  */
const ImagingQueueList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.get(`${HOST}/apis/IME/queue-imaging/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default ImagingQueueList

