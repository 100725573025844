import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Dropdown
} from 'semantic-ui-react'
import {
  DateTextBox,
  TimeTextBox
} from 'react-lib/apps/common'

const CardPlanTransferUX = (props: any) => {
    return(
      <div
        style={{margin:"10px"}}>
        <div
          style={{fontSize: "20px", fontWeight: "bold"}}>
          Plan Transfer
        </div>
        <hr>
        </hr>
        <div
          style={{display: "flex",flexDirection:"column",margin: "20px 0px 0px 20px"}}>
          
          <div
            style={{display: "flex",justifyContent:"flex-start",padding:"0px 0px 10px 0px"}}>
            
            <div
              style={{display:"flex",flexDirection:"row",minWidth:"45%",paddingRight:"50px"}}>
              
              <div
                style={{display:"flex", alignSelf:"center",minWidth: "150px"}}>
                วัน/เวลาที่ย้าย
              </div>
              <DateTextBox
                minDate={props.minTransferDate}
                onChange={props.onTransferDateChange}
                value={props.dateTransfer}>
              </DateTextBox>
              <TimeTextBox
                onTimeChange={props.onTransferTimeChange}
                search={true}
                value={props.timeTransfer}>
              </TimeTextBox>
            </div>
            <div
              style={{display:"flex",flexDirection:"row",minWidth:"35%"}}>
              
              <div
                style={{display:"flex", alignSelf:"center",minWidth: "150px"}}>
                ความเร่งด่วน
              </div>
              <Dropdown
                onChange={props.onUrgencyChange}
                options={props.urgencyOptions}
                selection={true}
                value={props.urgency}>
              </Dropdown>
            </div>
          </div>
          <div
            style={{display: "flex",justifyContent:"flex-start",padding:"0px 0px 10px 0px"}}>
            
            <div
              style={{display:"flex",flexDirection:"row",minWidth:"45%",paddingRight:"50px"}}>
              
              <div
                style={{display:"flex", alignSelf:"center",minWidth: "150px"}}>
                ประเภทหอผู้ป่วย
              </div>
              <Dropdown
                onChange={props.onWardTypeChange}
                options={props.wardTypeOptions}
                selection={true}
                value={props.wardType}>
              </Dropdown>
            </div>
            <div
              style={{display:"flex",flexDirection:"row",minWidth:"35%"}}>
              
              <div
                style={{display:"flex", alignSelf:"center",minWidth: "150px"}}>
                ประเภทห้อง
              </div>
              <Dropdown
                onChange={props.onRoomTypeChange}
                options={props.roomTypeOptions}
                selection={true}
                value={props.roomType}>
              </Dropdown>
            </div>
          </div>
          <div
            style={{display: "flex",justifyContent:"flex-start"}}>
            
            <div
              style={{display:"flex",flexDirection:"row",minWidth:"45%",paddingRight:"50px"}}>
              
              <div
                style={{display:"flex", alignSelf:"center",minWidth: "150px"}}>
                แผนก
              </div>
              <Dropdown
                onChange={props.onDivisionChange}
                options={props.divisionOptions}
                selection={true}
                value={props.division}>
              </Dropdown>
            </div>
            <div
              style={{display:"flex",flexDirection:"row",minWidth:"35%",paddingLeft:"10px",gap:"10px"}}>
              
              <Button
                color="green"
                onClick={props.onSave}>
                {props.saveOrEditName}
              </Button>
              <Button
                color="red"
                onClick={props.onCancel}
                style={{...(props.hideCancel && {display: "none"})}}>
                ยกเลิก
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardPlanTransferUX

export const screenPropsDefault = {}

/* Date Time : Mon Feb 06 2023 04:45:43 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan Transfer"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"20px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",flexDirection:\"column\",margin: \"20px 0px 0px 20px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 2,
      "void": true
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",justifyContent:\"flex-start\",padding:\"0px 0px 10px 0px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",justifyContent:\"flex-start\",padding:\"0px 0px 10px 0px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",justifyContent:\"flex-start\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",flexDirection:\"row\",minWidth:\"45%\",paddingRight:\"50px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",flexDirection:\"row\",minWidth:\"35%\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",flexDirection:\"row\",minWidth:\"45%\",paddingRight:\"50px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",flexDirection:\"row\",minWidth:\"35%\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",flexDirection:\"row\",minWidth:\"45%\",paddingRight:\"50px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",flexDirection:\"row\",minWidth:\"35%\",paddingLeft:\"10px\",gap:\"10px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน/เวลาที่ย้าย"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", alignSelf:\"center\",minWidth: \"150px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "ความเร่งด่วน"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", alignSelf:\"center\",minWidth: \"150px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทหอผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", alignSelf:\"center\",minWidth: \"150px\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทห้อง"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", alignSelf:\"center\",minWidth: \"150px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", alignSelf:\"center\",minWidth: \"150px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": "props.saveOrEditName"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSave"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 33,
      "name": "DateTextBox",
      "parent": 12,
      "props": {
        "minDate": {
          "type": "code",
          "value": "props.minTransferDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onTransferDateChange"
        },
        "value": {
          "type": "code",
          "value": "props.dateTransfer"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 34,
      "name": "TimeTextBox",
      "parent": 12,
      "props": {
        "onTimeChange": {
          "type": "code",
          "value": "props.onTransferTimeChange"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.timeTransfer"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Dropdown",
      "parent": 13,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onUrgencyChange"
        },
        "options": {
          "type": "code",
          "value": "props.urgencyOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.urgency"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Dropdown",
      "parent": 14,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onWardTypeChange"
        },
        "options": {
          "type": "code",
          "value": "props.wardTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.wardType"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Dropdown",
      "parent": 15,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onRoomTypeChange"
        },
        "options": {
          "type": "code",
          "value": "props.roomTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.roomType"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Dropdown",
      "parent": 16,
      "props": {
        "fluid": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onDivisionChange"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.division"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        },
        "style": {
          "type": "code",
          "value": "{...(props.hideCancel && {display: \"none\"})}"
        }
      },
      "seq": 39,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardPlanTransferUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
