import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Modal,
  Button,
  Dimmer,
  Loader,
  SemanticCOLORS,
} from "semantic-ui-react";

// Framework
import { Table } from "react-lib/frameworks/Table";

// UX
import CardReturnSupplyUX from "./CardReturnSupplyUX";

// Common
import EditorColumn from "react-lib/appcon/common/EditorColumn";
import SubUserActionLogUX from "react-lib/apps/HISV3/common/SubUserActionLogUX";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import { ErrorMessage } from "react-lib/apps/common";

// Utils
import { formatUTCtoBEString } from "react-lib/utils/dateUtils";

// Types
type CardReturnSupplyProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: any;
  ReturnSupplySequence?: any;
  // data
  isPaid?: boolean;
  // options
  masterOptions?: Record<string, any[]>;
  // CommonInterface
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  loadingStatus?: any;
  // config
  closeIcon?: boolean;
  hideErrorMessage?: boolean;
  isReceiveReturn?: boolean;
  // callback
  onClose?: () => any;
};

type StatusColorKey = keyof typeof STATUS_COLOR;

type ButtonForwardKey = keyof typeof BUTTON_FORWARD;

type ButtonBackwardKey = keyof typeof BUTTON_BACKWARD;

// Const
const STATUS_COLOR = {
  "": "grey",
  REQUESTED: "red",
  APPROVED: "yellow",
  DELIVERED: "green",
  RECEIVED: "blue",
} as const;

const BUTTON_FORWARD = {
  REQUESTED: "APPROVE",
  APPROVED: "DELIVER",
  DELIVERED: "RECEIVE",
} as const;

const BUTTON_BACKWARD = {
  APPROVED: "UNAPPROVE",
  DELIVERED: "UNDELIVER",
  RECEIVED: "UNRECEIVE",
} as const;

const BUTTON_ACTIONS = {
  forward: "forward",
  backward: "backward",
};

const getStatusColorLabel = (status: StatusColorKey): SemanticCOLORS => {
  return STATUS_COLOR[status] || STATUS_COLOR[""];
};
const getNameButtonForward = (status: ButtonForwardKey) => {
  return BUTTON_FORWARD[status];
};
const getNameButtonBackward = (status: ButtonBackwardKey) => {
  return BUTTON_BACKWARD[status];
};

const CARD_RETURN_SUPPLY = "CardReturnSupply";

const CardReturnSupply = (props: CardReturnSupplyProps) => {
  const [openModLog, setOpenModLog] = useState<boolean>(false);

  // Effect
  useEffect(() => {
    return () => {
      props.runSequence({ sequence: "ReturnSupply" });
    };
  }, []);

  // useEffect(() => {
  //   const pk = props.ReturnSupplySequence?.OrderReturnList?.id;
  //   if (!!pk && !!props?.errorMessage?.[CARD_RETURN_SUPPLY]?.error) {
  //     props.setProp(`errorMessage.${CARD_RETURN_SUPPLY}.error`, null);
  //   }
  // }, [props.ReturnSupplySequence?.OrderReturnList?.id]);

  // Callback
  const handleClickAction = useCallback(
    (e: any, data: any) => {
      // action: Log, REQUEST, EDIT, REJECT, New
      props.runSequence({
        sequence: "ReturnSupply",
        action: data.name,
        id: props.ReturnSupplySequence?.OrderReturnList?.id,
        card: CARD_RETURN_SUPPLY,
        btnAction: data.name,
      });

      if (data.name === "Log") {
        setOpenModLog(true);
      }
    },
    [props.ReturnSupplySequence?.OrderReturnList?.id]
  );

  // Memo
  const orderReturnList = useMemo(() => {
    return props.ReturnSupplySequence?.OrderReturnList || {};
  }, [props.ReturnSupplySequence?.OrderReturnList]);

  const userActionLogReturnList = useMemo(() => {
    const nameList = [
      "ผู้ขอคืน",
      "ผู้อนุมัติ",
      "ผู้ส่ง",
      "ผู้รับ",
      "ผู้ปฏิเสธ",
      "ผู้ยกเลิก",
    ];

    const list = [
      orderReturnList?.requested,
      orderReturnList?.approved,
      orderReturnList?.delivered,
      orderReturnList?.received,
      orderReturnList?.rejected,
      orderReturnList?.canceled,
    ];

    return list
      .map(
        (item: any, idx: any) =>
          item && (
            <SubUserActionLogUX
              key={item.id}
              {...item}
              typeName={nameList[idx]}
              datetime={formatUTCtoBEString(item.datetime)?.join(" ")}
              fontSize=""
            />
          )
      )
      .filter(Boolean);
  }, [
    orderReturnList?.requested,
    orderReturnList?.approved,
    orderReturnList?.delivered,
    orderReturnList?.received,
    orderReturnList?.rejected,
    orderReturnList?.canceled,
  ]);

  const buttonOrderCode = useMemo(() => {
    return (props.ReturnSupplySequence?.OrderSupplyHistory || []).map(
      (item: any, index: number) => (
        <Button
          key={index}
          color={getStatusColorLabel(item?.status_name)}
          size="mini"
          content={`${item.status_name}:${item.code}`}
          onClick={() => {
            props.runSequence({
              sequence: "ReturnSupply",
              action: "HistoryRequest",
              orders: item,
            });
          }}
        />
      )
    );
  }, [props.ReturnSupplySequence?.OrderSupplyHistory]);

  const supplyOrderList = useMemo(() => {
    return (props.ReturnSupplySequence?.SupplyReturnList || []).map(
      (item: any, index: number) => {
        const canEdit: boolean =
          ["REQUESTED", "RECEIVED"].includes(
            orderReturnList.status_name || orderReturnList.status
          ) || item.new;

        return {
          ...item,
          no: index + 1,
          quantity_issue: (
            <div style={{ textAlign: "right" }}>{item.quantity_issue}</div>
          ),
          quantity_left: (
            <div style={{ textAlign: "right" }}>{item.quantity_left}</div>
          ),
          quantity_request: (
            <EditorColumn
              value={item.quantity_request}
              disabled={!canEdit}
              backgroundColor={canEdit ? "rgb(255, 255, 204)" : ""}
              onDataChanged={(value: string) =>
                handleChangeEdit({ value, index, type: "quantity_request" })
              }
            />
          ),
          quantity_restock: (
            <EditorColumn
              value={item.quantity_restock}
              disabled={
                orderReturnList.status_name === "DELIVERED" ? false : true
              }
              backgroundColor={
                orderReturnList.status_name === "DELIVERED"
                  ? "rgb(255, 255, 204)"
                  : ""
              }
              onDataChanged={(value: string) =>
                handleChangeEdit({ value, index, type: "quantity_restock" })
              }
            />
          ),
          quantity_discard: (
            <EditorColumn
              value={item.quantity_discard}
              disabled={
                orderReturnList.status_name === "DELIVERED" ? false : true
              }
              backgroundColor={
                orderReturnList.status_name === "DELIVERED"
                  ? "rgb(255, 255, 204)"
                  : ""
              }
              onDataChanged={(value: string) =>
                handleChangeEdit({ value, index, type: "quantity_discard" })
              }
            />
          ),
        };
      }
    );
  }, [props.ReturnSupplySequence?.SupplyReturnList, orderReturnList]);

  const noShowButtonREJECT = useMemo(() => {
    const isRequest = orderReturnList?.status_name === "REQUESTED";

    return (
      !(
        (isRequest && !props.isPaid) ||
        ["APPROVED", "DELIVERED"].includes(orderReturnList?.status_name || "")
      ) ||
      (isRequest && props.isReceiveReturn)
    );
  }, [orderReturnList?.status_name, props.isReceiveReturn]);

  const buttonAction = useMemo(() => {
    const statusName = orderReturnList?.status_name || "";
    const buttons = [
      {
        color: "green",
        name: "REQUEST",
        style: { display: statusName ? "none" : "" },
      },
      {
        color: "yellow",
        name: "EDIT",
        style: {
          display: statusName === "REQUESTED" ? "" : "none",
        },
      },
      {
        color: "red",
        name: "REJECT",
        style: {
          display: noShowButtonREJECT
            ? "none"
            : statusName === "REQUESTED"
            ? ""
            : "none",
        },
      },
      {
        color: "red",
        name: "CANCEL",
        style: { display: statusName === "REQUESTED" ? "" : "none" },
      },
    ];

    return buttons.reduce(
      (result, item) => ({
        ...result,
        [item.name]: (
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleClickAction}
            // data
            paramKey={`${CARD_RETURN_SUPPLY}_${item.name}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_RETURN_SUPPLY}_${item.name}`]
            }
            // config
            color={item.color}
            name={item.name}
            size="tiny"
            title={item.name}
            style={item.style}
          />
        ),
      }),
      {} as any
    );
  }, [orderReturnList?.status_name, noShowButtonREJECT, props.buttonLoadCheck]);

  const supplyOrderLog = useMemo(() => {
    return (props.ReturnSupplySequence?.OrderReturnLog?.items || []).map(
      (item: any) => ({
        ...item,
        datetime: formatUTCtoBEString(item.datetime)?.join(" "),
      })
    );
  }, [props.ReturnSupplySequence?.OrderReturnLog?.items]);

  const noShowButton = useMemo(() => {
    return ["CANCELED", "REJECTED"].includes(
      orderReturnList?.status_name || ""
    );
  }, [orderReturnList?.status_name]);

  const showButtonForward = useMemo(() => {
    const status = orderReturnList?.status_name;

    return (
      status &&
      status !== "RECEIVED" &&
      !(["DELIVERED", "APPROVED"].includes(status) && props.isReceiveReturn)
    );
  }, [orderReturnList?.status_name, props.isReceiveReturn]);

  const showButtonBackward = useMemo(() => {
    const status = orderReturnList?.status_name;

    return (
      status &&
      !noShowButton &&
      status !== "REQUESTED" &&
      !(
        ["RECEIVED", "DELIVERED", "APPROVED"].includes(status) &&
        props.isReceiveReturn
      )
    );
  }, [orderReturnList?.status_name, noShowButton, props.isReceiveReturn]);

  const handleChangeEdit = (data: any) => {
    props.runSequence({
      sequence: "ReturnSupply",
      action: "EditColumn",
      data,
    });
  };

  const handleUpdateAction = (key: string) => {
    const actionForward = getNameButtonForward(orderReturnList?.status_name);
    const actionBackward = getNameButtonBackward(orderReturnList?.status_name);
    props.runSequence({
      sequence: "ReturnSupply",
      action:
        key === "forward"
          ? actionForward
          : key === "backward"
          ? actionBackward
          : "",
      id: orderReturnList?.id,
      card: CARD_RETURN_SUPPLY,
      btnAction: key,
    });
  };

  return (
    <>
      {!props.hideErrorMessage && (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            props.setProp(`errorMessage.${CARD_RETURN_SUPPLY}.error`, null);
          }}
          error={props?.errorMessage?.[CARD_RETURN_SUPPLY]?.error}
          success={null}
        />
      )}

      <CardReturnSupplyUX
        // data
        supplyOrderList={supplyOrderList}
        buttonOrderCode={buttonOrderCode}
        userActionLogReturnList={userActionLogReturnList}
        orderCode={`[${orderReturnList?.code}]`}
        code={orderReturnList?.order_code || orderReturnList?.code}
        divisionOrder={
          orderReturnList?.requester_name ||
          props.masterOptions?.division?.filter(
            (item: any) => item.value === orderReturnList?.order_perform_div
          )?.[0]?.text
        }
        isStatus={orderReturnList?.status_name}
        noAction={orderReturnList?.status_name === ""}
        statusName={orderReturnList?.status_name}
        orderReturnList={orderReturnList}
        buttonAction={buttonAction}
        // callback
        closeIcon={props.closeIcon}
        onClose={props.onClose}
        onClickAction={handleClickAction}
        onForward={() => handleUpdateAction("forward")}
        onBackward={() => handleUpdateAction("backward")}
        // Element
        // errorMessage={
        //   <ErrorMessage
        //     error={props?.errorMessage?.[CARD_RETURN_SUPPLY]?.error}
        //   />
        // }
        // loading={
        //   <>
        //     <Dimmer active={props.loadingStatus?.[CARD_RETURN_SUPPLY]} inverted>
        //       <Loader />
        //     </Dimmer>
        //   </>
        // }
        buttonBackward={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => handleUpdateAction("backward")}
            // data
            paramKey={`${CARD_RETURN_SUPPLY}_${BUTTON_ACTIONS.backward}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_RETURN_SUPPLY}_${BUTTON_ACTIONS.backward}`
              ]
            }
            // config
            color={"red"}
            name={BUTTON_ACTIONS.backward}
            size="tiny"
            title={getNameButtonBackward(orderReturnList?.status_name)}
            style={{
              display: showButtonBackward ? "" : "none",
            }}
          />
        }
        buttonForward={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => handleUpdateAction("forward")}
            // data
            paramKey={`${CARD_RETURN_SUPPLY}_${BUTTON_ACTIONS.forward}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_RETURN_SUPPLY}_${BUTTON_ACTIONS.forward}`
              ]
            }
            // config
            color={"green"}
            name={BUTTON_ACTIONS.forward}
            size="tiny"
            title={getNameButtonForward(orderReturnList?.status_name)}
            style={{
              display: noShowButtonREJECT
                ? "none"
                : !noShowButton && showButtonForward
                ? ""
                : "none",
            }}
          />
        }
      />

      <Modal
        open={openModLog}
        size="small"
        onClose={() => setOpenModLog(false)}
      >
        <div style={{ padding: "10px" }}>
          <Modal.Header as="h3">ประวัติการทำงาน</Modal.Header>
          <Table
            data={supplyOrderLog}
            headers="User,Action,Date-Time"
            keys="name,action,datetime"
            minRows="10"
            showPagination={false}
            style={{ height: "300px" }}
          ></Table>
        </div>
      </Modal>
    </>
  );
};
export default React.memo(CardReturnSupply);
