import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Checkbox,
  Input,
  Dropdown
} from 'semantic-ui-react'
import {
  DateTextBox,
  TimeFreeTextBox24
} from 'react-lib/apps/common'

const ANES_PreANES_07_Reevaluation = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", height: "100%" ,padding:"10px"}}>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",height:"80%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px"}}>
            
            <div
              style={{ display: props.PreAnestheticSequence?.status_label === "Confirm" ?  "none" : "",}}>
              {props?.buttonSave}
            </div>
          </div>
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(20, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/8"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.PreanestheticReEvaluationNoneDueto || false}
                  name="PreanestheticReEvaluationNoneDueto"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  None due to
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "1/4"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Date
              </label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "4/15"}}>
              <Input
                icon="clock"
                name="PREANESTHETICREEVALUATIONDateTime"
                onChange={props.onChangeData}
                style={{height:"35px",width:"150px",marginTop:"5px", display: "none"}}
                value={props.PreAnestheticSequence?.data?.PREANESTHETICREEVALUATIONDateTime || ""}>
              </Input>
              <div
                style={{ display: "flex", marginTop: "5px" }}>
                
                <div
                  style={{ width: "250px" }}>
                  
                  <DateTextBox
                    onChange={props.onChangeDateForm}
                    style={{height:"35px", marginRight:"5px"}}
                    value={props.PreAnestheticSequence?.data?.PREANESTHETICREEVALUATIONDate || ""}>
                  </DateTextBox>
                </div>
                <div
                  style={{ display: "flex", alingItem: "center" }}>
                  {}
                  <TimeFreeTextBox24
                    className="time-custom-react-picker"
                    onChange={props.onChangeTimeForm}
                    value={props.PreAnestheticSequence?.data?.PREANESTHETICREEVALUATIONTime || ""}>
                  </TimeFreeTextBox24>
                </div>
              </div>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "15/18"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Body weight
              </label>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "8/38" }}>
              <Input
                name="PreanestheticReEvaluationIsNoneDueto"
                onChange={props.onChangeData}
                style={{height:"80%",width:"100%",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.PreanestheticReEvaluationIsNoneDueto || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "18/21" }}>
              <Input
                name="PREANESTHETICREEVALUATIONBodyWeight"
                onChange={props.onChangeData}
                style={{height:"80%",width:"100%",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.PREANESTHETICREEVALUATIONBodyWeight || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "21/22"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center",marginLeft:"5px"}}>
                kg.
              </label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "22/24" }}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center",marginLeft:"5px"}}>
                BP
              </label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "24/27"}}>
              <Input
                name="PREANESTHETICREEVALUATIONBP"
                onChange={props.onChangeData}
                style={{height:"80%",width:"100%",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.PREANESTHETICREEVALUATIONBP || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "27/30"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center",marginLeft:"5px"}}>
                mmHg
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "1/4"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                PR
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "4/8"}}>
              <Input
                name="PreanestheticReEvaluationIsPR"
                onChange={props.onChangeData}
                style={{height:"80%",width:"100%",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.PreanestheticReEvaluationIsPR || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "8/11"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center",marginLeft:"10px"}}>
                /min
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "11/13"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                RR
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "13/17"}}>
              <Input
                name="PreanestheticReEvaluationIsRR"
                onChange={props.onChangeData}
                style={{height:"80%",width:"100%",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.PreanestheticReEvaluationIsRR || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "17/19"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center",marginLeft:"10px"}}>
                /min
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "19/21"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Temp
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "21/25"}}>
              <Input
                name="PreanestheticReEvaluationTemp"
                onChange={props.onChangeData}
                style={{height:"80%",width:"100%",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.PreanestheticReEvaluationTemp || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "25/26"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center",marginLeft:"10px"}}>
                ํC
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "26/28"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center",marginLeft:"10px"}}>
                LMP
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "28/32"}}>
              <Input
                name="PreanestheticReEvaluationLMP"
                onChange={props.onChangeData}
                style={{height:"80%",width:"100%",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.PreanestheticReEvaluationLMP || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "1/9"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Proposed operation
              </label>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "1/9"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Change in clinical condition
              </label>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "1/9"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Change in current medication
              </label>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "1/9"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                New disease / illness
              </label>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "9/30"}}>
              <Input
                name="PreanestheticReEvaluationProposedOperation"
                onChange={props.onChangeData}
                style={{height:"80%",width:"100%",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.PreanestheticReEvaluationProposedOperation || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "9/14"}}>
              <div
                style={{ display: "flex",paddingTop:"5px" }}>
                
                <Dropdown
                  name="IsChangeInClinicalCondition"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_change_clini || []}
                  selection={true}
                  style={{minWidth: "100px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                  value={props.PreAnestheticSequence?.data?.IsChangeInClinicalCondition || ""}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "9/14"}}>
              <div
                style={{ display: "flex",paddingTop:"5px" }}>
                
                <Dropdown
                  name="IsChangeInCurrentMedication"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_change_in_cu || []}
                  selection={true}
                  style={{minWidth: "100px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                  value={props.PreAnestheticSequence?.data?.IsChangeInCurrentMedication || ""}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "9/14"}}>
              <div
                style={{ display: "flex",paddingTop:"5px" }}>
                
                <Dropdown
                  name="IsNewDiseaseIllness"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_new_disease || []}
                  selection={true}
                  style={{minWidth: "100px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                  value={props.PreAnestheticSequence?.data?.IsNewDiseaseIllness || ""}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "15/30"}}>
              <Input
                name="ChangeInClinicalConditionRemark"
                onChange={props.onChangeData}
                style={{height:"80%",width:"100%",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.ChangeInClinicalConditionRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "15/30"}}>
              <Input
                name="ChangeInCurrentMedicationRemark"
                onChange={props.onChangeData}
                style={{height:"80%",width:"100%",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.ChangeInCurrentMedicationRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "15/30" }}>
              <Input
                name="NewDiseaseIllnessRemark"
                onChange={props.onChangeData}
                style={{height:"80%",width:"100%",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.NewDiseaseIllnessRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "1/8" }}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Assessment
              </label>
            </div>
            <div
              style={{ gridRow: "9/10", gridColumn: "1/8"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Plan
              </label>
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "9/16"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.AssessmentIsStableClinicalCondition || false}
                  name="AssessmentIsStableClinicalCondition"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Stable clinical condition
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "9/10", gridColumn: "9/16"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.PlanIsGoOnAnesthesiaAsPlan || false}
                  name="PlanIsGoOnAnesthesiaAsPlan"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Go on anesthesia as plan
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "17/23"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.AssessmentIsUnstableDueTo || false}
                  name="AssessmentIsUnstableDueTo"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Unstable due to
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "9/10", gridColumn: "17/30"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.PlanIsOther || false}
                  name="PlanIsOther"
                  onChange={props.onChangeData}>
                </Checkbox>
                <Input
                  name="PlanOtherRemark"
                  onChange={props.onChangeData}
                  style={{height:"80%",width:"100%",marginTop:"5px",marginLeft:"10px"}}
                  value={props.PreAnestheticSequence?.data?.PlanOtherRemark || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "1/8"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Anesthetsiologis / Anesthetist
              </label>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "9/16"}}>
              <Input
                name="PreanestheticReEvaluationAnesthetsiologisAnesthetist"
                readOnly={true}
                style={{height:"80%",width:"100%",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.PreanestheticReEvaluationAnesthetsiologisAnesthetist || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "17/22"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                License number
              </label>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "22/29"}}>
              <Input
                name="PreanestheticReEvaluationAnesthetsiologisAnesthetistLicenseNumber"
                readOnly={true}
                style={{height:"80%",width:"100%",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.PreanestheticReEvaluationAnesthetsiologisAnesthetistLicenseNumber || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "30/32"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Code
              </label>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "32/38"}}>
              <Input
                name="PreanestheticReEvaluationAnesthetsiologisAnesthetistCode"
                onChange={props.onChangeData}
                onKeyDown={props.onKeyAnesthetsiologisAnesthetist}
                style={{height:"80%",width:"100%",marginTop:"5px"}}
                value={props.PreAnestheticSequence?.data?.PreanestheticReEvaluationAnesthetsiologisAnesthetistCode || ""}>
              </Input>
            </div>
          </div>
        </div>
      </div>
    )
}

export default ANES_PreANES_07_Reevaluation

export const screenPropsDefault = {}

/* Date Time : Thu Feb 16 2023 04:06:10 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" ,padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",height:\"80%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(20, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/8\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Checkbox",
      "parent": 5,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticReEvaluationNoneDueto || false"
        },
        "name": {
          "type": "value",
          "value": "PreanestheticReEvaluationNoneDueto"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "None due to"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/4\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"4/15\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"15/18\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "Date"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Input",
      "parent": 9,
      "props": {
        "icon": {
          "type": "value",
          "value": "clock"
        },
        "name": {
          "type": "value",
          "value": "PREANESTHETICREEVALUATIONDateTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"150px\",marginTop:\"5px\", display: \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PREANESTHETICREEVALUATIONDateTime || \"\""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Body weight"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"8/38\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Input",
      "parent": 17,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "PreanestheticReEvaluationIsNoneDueto"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"80%\",width:\"100%\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticReEvaluationIsNoneDueto || \"\""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"18/21\" }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"21/22\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"22/24\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"24/27\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Input",
      "parent": 19,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "PREANESTHETICREEVALUATIONBodyWeight"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"80%\",width:\"100%\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PREANESTHETICREEVALUATIONBodyWeight || \"\""
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Input",
      "parent": 22,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "PREANESTHETICREEVALUATIONBP"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"80%\",width:\"100%\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PREANESTHETICREEVALUATIONBP || \"\""
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "kg."
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\",marginLeft:\"5px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "BP"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\",marginLeft:\"5px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"27/30\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "mmHg"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\",marginLeft:\"5px\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/4\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"4/8\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"8/11\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "PR"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Input",
      "parent": 31,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "PreanestheticReEvaluationIsPR"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"80%\",width:\"100%\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticReEvaluationIsPR || \"\""
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "label",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "/min"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\",marginLeft:\"10px\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"11/13\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"13/17\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"17/19\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "RR"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Input",
      "parent": 37,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "PreanestheticReEvaluationIsRR"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"80%\",width:\"100%\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticReEvaluationIsRR || \"\""
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "/min"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\",marginLeft:\"10px\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"19/21\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"21/25\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"25/26\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "Temp"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Input",
      "parent": 43,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "PreanestheticReEvaluationTemp"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"80%\",width:\"100%\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticReEvaluationTemp || \"\""
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "label",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "ํC"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\",marginLeft:\"10px\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"26/28\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"28/32\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "LMP"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\",marginLeft:\"10px\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Input",
      "parent": 49,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "PreanestheticReEvaluationLMP"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"80%\",width:\"100%\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticReEvaluationLMP || \"\""
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/9\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/9\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/9\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/9\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"9/30\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"9/14\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"9/14\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"9/14\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"15/30\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"15/30\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"15/30\" }"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "Proposed operation"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "label",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": "Change in clinical condition"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "Change in current medication"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "label",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "New disease / illness"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "Input",
      "parent": 56,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "PreanestheticReEvaluationProposedOperation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"80%\",width:\"100%\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticReEvaluationProposedOperation || \"\""
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",paddingTop:\"5px\" }"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Dropdown",
      "parent": 69,
      "props": {
        "name": {
          "type": "value",
          "value": "IsChangeInClinicalCondition"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_change_clini || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.IsChangeInClinicalCondition || \"\""
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",paddingTop:\"5px\" }"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Dropdown",
      "parent": 71,
      "props": {
        "name": {
          "type": "value",
          "value": "IsChangeInCurrentMedication"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_change_in_cu || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.IsChangeInCurrentMedication || \"\""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",paddingTop:\"5px\" }"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Dropdown",
      "parent": 73,
      "props": {
        "name": {
          "type": "value",
          "value": "IsNewDiseaseIllness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_new_disease || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.IsNewDiseaseIllness || \"\""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Input",
      "parent": 60,
      "props": {
        "fluid": {
          "type": "value",
          "value": ""
        },
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "ChangeInClinicalConditionRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"80%\",width:\"100%\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ChangeInClinicalConditionRemark || \"\""
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Input",
      "parent": 61,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "ChangeInCurrentMedicationRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"80%\",width:\"100%\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ChangeInCurrentMedicationRemark || \"\""
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Input",
      "parent": 62,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "NewDiseaseIllnessRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"80%\",width:\"100%\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.NewDiseaseIllnessRemark || \"\""
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"1/8\" }"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/8\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"9/16\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"9/16\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"17/23\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"17/30\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "Assessment"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "label",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Checkbox",
      "parent": 86,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AssessmentIsStableClinicalCondition || false"
        },
        "name": {
          "type": "value",
          "value": "AssessmentIsStableClinicalCondition"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "label",
      "parent": 86,
      "props": {
        "children": {
          "type": "value",
          "value": "Stable clinical condition"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Checkbox",
      "parent": 89,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PlanIsGoOnAnesthesiaAsPlan || false"
        },
        "name": {
          "type": "value",
          "value": "PlanIsGoOnAnesthesiaAsPlan"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "label",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "Go on anesthesia as plan"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "Checkbox",
      "parent": 92,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AssessmentIsUnstableDueTo || false"
        },
        "name": {
          "type": "value",
          "value": "AssessmentIsUnstableDueTo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "label",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": "Unstable due to"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Checkbox",
      "parent": 95,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PlanIsOther || false"
        },
        "name": {
          "type": "value",
          "value": "PlanIsOther"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "Input",
      "parent": 95,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "PlanOtherRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"80%\",width:\"100%\",marginTop:\"5px\",marginLeft:\"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PlanOtherRemark || \"\""
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"1/8\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"9/16\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"17/22\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"22/29\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"30/32\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"32/38\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "label",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthetsiologis / Anesthetist"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Input",
      "parent": 99,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "PreanestheticReEvaluationAnesthetsiologisAnesthetist"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"80%\",width:\"100%\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticReEvaluationAnesthetsiologisAnesthetist || \"\""
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "License number"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Input",
      "parent": 101,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "PreanestheticReEvaluationAnesthetsiologisAnesthetistLicenseNumber"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"80%\",width:\"100%\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticReEvaluationAnesthetsiologisAnesthetistLicenseNumber || \"\""
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "label",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": "Code"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Input",
      "parent": 103,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "PreanestheticReEvaluationAnesthetsiologisAnesthetistCode"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.onKeyAnesthetsiologisAnesthetist"
        },
        "style": {
          "type": "code",
          "value": "{height:\"80%\",width:\"100%\",marginTop:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticReEvaluationAnesthetsiologisAnesthetistCode || \"\""
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", marginBottom: \"10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 111,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.status_label === \"Confirm\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginTop: \"5px\" }"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"250px\" }"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 117,
      "name": "DateTextBox",
      "parent": 116,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDateForm"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\", marginRight:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PREANESTHETICREEVALUATIONDate || \"\""
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alingItem: \"center\" }"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 119,
      "name": "TimeFreeTextBox24",
      "parent": 118,
      "props": {
        "className": {
          "type": "value",
          "value": "time-custom-react-picker"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTimeForm"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PREANESTHETICREEVALUATIONTime || \"\""
        }
      },
      "seq": 119,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PreANES_07_Reevaluation",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
