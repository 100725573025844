import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Input,
  Button,
  Dropdown
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardStockManagementTabAddUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          className="ui form"
          style={{marginBottom: "1rem"}}>
          
          <label
            style={{fontWeight: "bold", fontSize: "1.1rem"}}>
            การเติมสินค้า
          </label>
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "fit-content"}}>
                สินค้า
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                readOnly={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.name || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{display: "flex"}}>
                
                <label
                  style={{fontWeight: "bold"}}>
                  เติมสินค้าไปยัง
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Dropdown
                disabled={props.readOnly || false}
                name="storage"
                onChange={props.onChangeValue}
                options={props.storageOptions || []}
                selection={true}
                value={props.detail?.storage || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{display: "flex"}}>
                
                <label
                  style={{fontWeight: "bold"}}>
                  Bin Location
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Input
                disabled={props.readOnly || false}
                name="bin_location"
                onChange={props.onChangeValue}
                value={props.detail?.bin_location || ""}>
              </Input>
            </FormField>
            <div
              className="field inline"
              style={{flex: 1}}>
              
            </div>
            <FormField
              inline={true}>
              <Button
                color={Number.isInteger(props.detail?.index) ? "yellow" : "green"}
                disabled={props.disabledAdd || false}
                onClick={props.onAdd}>
                {Number.isInteger(props.detail?.index) ? "แก้ไขรายการ" : "เพิ่มรายการ"}
              </Button>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="yellow"
                disabled={props.readOnly || false}
                onClick={props.onClear}>
                เคลียร์
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{display: "flex"}}>
                
                <label
                  style={{fontWeight: "bold"}}>
                  Lot No.
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Dropdown
                allowAdditions={true}
                disabled={props.readOnly || false}
                name="lot_no"
                onAddItem={props.onAddItemLotNo}
                onChange={props.onChangeValue}
                options={props.lotNoOptions || []}
                search={true}
                selection={true}
                value={props.detail?.lot_no || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{display: "flex"}}>
                
                <label
                  style={{fontWeight: "bold"}}>
                  Expiry date
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}>
              <DateTextBox
                disabled={props.readOnly || props.disabledExpireDate || false}
                onChange={props.onChangeDate("expire_date")}
                value={props.expireDate || ""}>
              </DateTextBox>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{display: "flex"}}>
                
                <label
                  style={{fontWeight: "bold"}}>
                  จำนวนเติม
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Input
                disabled={props.readOnly || false}
                name="quantity"
                onChange={props.onChangeValue}
                type="number"
                value={props.detail?.quantity || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{display: "flex"}}>
                
                <label
                  style={{fontWeight: "bold"}}>
                  เติมสินค้าจาก
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Dropdown
                allowAdditions={false}
                disabled={props.readOnly || false}
                name="reference_text"
                onAddItem={props.onAddItemFrom}
                onChange={props.onChangeValue}
                options={props.fromOptions || []}
                search={true}
                selection={true}
                value={props.detail?.reference_text || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form">
          
          <Table
            data={props.productItems || []}
            getTrProps={props.getTrProps}
            headers="รหัสสินค้า,ชื่อ,Product type,Lot No.,Expiry Date,เติมสินค้าไปยัง,เติมสินค้าจาก,จำนวนเติม,แก้ไข/ลบ"
            keys="code,name,type,lot_no,expire_date,storage,reference_text,quantity,action"
            minRows={5}
            showPagination={false}
            style={{height: "250px"}}>
          </Table>
        </div>
        <div
          className="ui form"
          style={{marginTop: "1rem"}}>
          
          <FormGroup
            inline={true}>
            <div
              className="field inline"
              style={{flex: 1}}>
              
            </div>
            <FormField
              inline={true}>
              <div>
                {props.ButtonConfirm}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="red"
                disabled={props.disabledCancel|| false}
                onClick={props.onCancel}>
                ยกเลิก
              </Button>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}

CardStockManagementTabAddUX.displayName = "CardStockManagementTabAddUX";
export default React.memo(CardStockManagementTabAddUX)

export const screenPropsDefault = {}

/* Date Time : Thu Jul 06 2023 07:47:13 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1rem\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "การเติมสินค้า"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.1rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "สินค้า"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Input",
      "parent": 6,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.name || \"\""
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "Number.isInteger(props.detail?.index) ? \"แก้ไขรายการ\" : \"เพิ่มรายการ\""
        },
        "color": {
          "type": "code",
          "value": "Number.isInteger(props.detail?.index) ? \"yellow\" : \"green\""
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledAdd || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAdd"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "เคลียร์"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field inline"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "เติมสินค้าไปยัง"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 4,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 4,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "Bin Location"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Input",
      "parent": 25,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "name": {
          "type": "value",
          "value": "bin_location"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "ref": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.detail?.bin_location || \"\""
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormField",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 30,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 30,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Dropdown",
      "parent": 32,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "name": {
          "type": "value",
          "value": "lot_no"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddItemLotNo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.lotNoOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.detail?.lot_no || \"\""
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 42,
      "name": "DateTextBox",
      "parent": 34,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly || props.disabledExpireDate || false"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate(\"expire_date\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.expireDate || \"\""
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Input",
      "parent": 36,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "name": {
          "type": "value",
          "value": "quantity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.detail?.quantity || \"\""
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Dropdown",
      "parent": 38,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "false"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "name": {
          "type": "value",
          "value": "reference_text"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddItemFrom"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.fromOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.detail?.reference_text || \"\""
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "label",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "Lot No."
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "label",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "Expiry date"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "label",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนเติม"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "label",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "เติมสินค้าจาก"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "label",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 56,
      "name": "Table",
      "parent": 55,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "data": {
          "type": "code",
          "value": "props.productItems || []"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "รหัสสินค้า,ชื่อ,Product type,Lot No.,Expiry Date,เติมสินค้าไปยัง,เติมสินค้าจาก,จำนวนเติม,แก้ไข/ลบ"
        },
        "keys": {
          "type": "value",
          "value": "code,name,type,lot_no,expire_date,storage,reference_text,quantity,action"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"250px\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormGroup",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field inline"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Button",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledCancel|| false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Dropdown",
      "parent": 8,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "name": {
          "type": "value",
          "value": "storage"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.storageOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.detail?.storage || \"\""
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonConfirm"
        }
      },
      "seq": 66,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardStockManagementTabAddUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
