import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Icon
} from 'semantic-ui-react'

const ModTreatmentTemplateUX = (props: any) => {
  console.log("ModTemplate",props);
  
    return(
      <div
        style={{padding: "10px"}}>
        <div
          style={{display: "flex", justifyContent: "space-between"}}>
          
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <div
              style={{fontWeight: "bold", fontSize: "1.2rem"}}>
              เลือกชุดหัตถการ
            </div>
          </div>
          <div
            style={{display: "flex"}}>
            
            <div
              style={{cursor: "pointer", margin: "-5px -5px 0 15px", display: props.closeIcon ? "" : "none"}}>
              
              <Icon
                className="red large"
                name={"close"}
                onClick={props.onClose}>
              </Icon>
            </div>
          </div>
        </div>
        <div
          style={{paddingTop:"5px"}}>
          
          <Table
            data={props?.trearmentTemplate}
            getTrProps={props?.rowProps}
            headers="ชื่อหัตถการ,ICD9CM,บริการที่จะทำ,รายละเอียดเพิ่มเติม,อุปกรณ์,ผู้ทำ"
            keys="name,icd9cm,item_summary,order_detail,equipment_detail,to_be_performer"
            minRows="4"
            showPagination={false}
            style={{height: "200px"}}>
          </Table>
        </div>
        <div
          style={{display: "flex",  marginTop: "10px"}}>
          
          <Button
            color="blue"
            onClick={props.OnAddTemplate}
            size="tiny"
            style={{display: props.isRequest ? "none":""}}>
            เลือก
          </Button>
        </div>
      </div>
    )
}

export default ModTreatmentTemplateUX

export const screenPropsDefault = {"drugSearch":"[Drug Search Box]"}

/* Date Time : Thu Jul 07 2022 11:17:44 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop:\"5px\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  marginTop: \"10px\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 5,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props?.trearmentTemplate"
        },
        "getTrProps": {
          "type": "code",
          "value": "props?.rowProps"
        },
        "headers": {
          "type": "value",
          "value": "ชื่อหัตถการ,ICD9CM,บริการที่จะทำ,รายละเอียดเพิ่มเติม,อุปกรณ์,ผู้ทำ"
        },
        "keys": {
          "type": "value",
          "value": "name,icd9cm,item_summary,order_detail,equipment_detail,to_be_performer"
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือก"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnAddTemplate"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{display: props.isRequest ? \"none\":\"\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกชุดหัตถการ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", margin: \"-5px -5px 0 15px\", display: props.closeIcon ? \"\" : \"none\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Icon",
      "parent": 102,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "name": {
          "type": "code",
          "value": "\"close\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 103,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "ModTreatmentTemplateUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "drugSearch": "[Drug Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
