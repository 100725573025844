import React, {
  MutableRefObject,
  useState,
  useRef,
  useEffect,
  useMemo,
  CSSProperties,
} from "react";
import { Button, Dimmer, Loader, Modal } from "semantic-ui-react";
// Mui
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";

// UX
import ModXrayDetailUX from "./ModXrayDetailUX";
import DentalDiagram from "react-lib/apps/common/CUDENT/DentalDiagram";
import { ModXrayDetailType } from "./sequence/ImagingOrder";

// Types
type ModXrayDetailProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;

  // data
  open: boolean;
  data?: ModXrayDetailType | null;
  clinicalFindingId?: number | null;
  clinicalFindingIndex?: number | null;
  clinicalFindings: Record<string, any>[];
  filterClinicalFindingIds: number[];
  masterData: Partial<{
    organ: { name: string; items: any[] };
    clinicaltags: { name: string; items: any[] };
  }>;
  // options
  eligibilityOptions?: Record<string, any>[];
  // config
  readOnly?: boolean;
  allowReadyToView?: boolean;
  // callback
  onClose: () => any;
  onSave?: (data: Partial<ModXrayDetailType>) => any;
};

export const URGENCY_STATUS = {
  ROUTINE: "ROUTINE",
  STAT: "STAT",
};

const URGENCY_OPTIONS = Object.values(URGENCY_STATUS).map((status) => ({
  key: status,
  value: status,
  text: status,
}));

const IMAGES = {
  photo_white: "/static/images/x-ray/photo-library-white.png",
  photo_blue: "/static/images/x-ray/photo-library-blue-opacity.png",
};

const ModXrayDetail = (props: ModXrayDetailProps) => {
  const [hideBabyTooth, setHideBabyTooth] = useState(true);
  const [detail, setDetail] = useState<Partial<ModXrayDetailType>>({});

  const mainRef = useRef() as MutableRefObject<HTMLDivElement>;

  // Effect
  useEffect(() => {
    setDetail(props.data || {});
  }, [props.data]);

  useEffect(() => {
    const clinical =
      props.clinicalFindings?.[props.clinicalFindingIndex as any];

    if (!!clinical) {
      handleChangeValue(null, {
        name: "locations_name",
        value: clinical.locations_name,
      });
    }
  }, [props.clinicalFindings, props.clinicalFindingIndex]);

  const showDiagram = useMemo(() => {
    return props.data?.group_type === "DENTAL";
  }, [props.data]);

  //  Handler
  const handleChangeValue = (e: any, data: any) => {
    const value = typeof data.checked === "boolean" ? data.checked : data.value;

    setDetail((detail) => ({ ...detail, [data.name]: value }));
  };

  const handleToggleHideBabyTooth = () => {
    setHideBabyTooth(!hideBabyTooth);
  };

  const handleToggleReady = () => {
    handleChangeValue(null, {
      name: "ready_to_view",
      value: !detail.ready_to_view,
    });
  };

  const handleClose = () => {
    setDetail({});

    props.onClose();
  };

  const handleSave = () => {
    props.onSave?.({
      ...detail,
      locations_name:
        props.clinicalFindings?.[props.clinicalFindingIndex as any]
          ?.locations_name || "",
    });
  };

  return (
    <Modal
      open={props.open}
      closeOnDimmerClick={true}
      style={{ width: "fit-content", minWidth: "45%" }}
      // callback
      onClose={handleClose}
    >
      <div ref={mainRef}>
        <ModXrayDetailUX
          // data
          detail={detail}
          locationsName={
            props.clinicalFindings?.[props.clinicalFindingIndex as any]
              ?.locations_name
          }
          // options
          eligibilityOptions={props.eligibilityOptions}
          urgencyOptions={URGENCY_OPTIONS}
          // config
          hideBabyTooth={hideBabyTooth}
          hideLocation={!showDiagram}
          hideDiagram={!showDiagram}
          readOnly={props.readOnly}
          allowReadyToView={props.allowReadyToView}
          // callback
          onClickDeciduousTeeth={handleToggleHideBabyTooth}
          onChangeValue={handleChangeValue}
          onClose={handleClose}
          onSave={handleSave}
          onCancel={handleClose}
          // Element
          DentalDiagram={
            showDiagram && (
              <DentalDiagram
                onEvent={props.onEvent}
                // data
                clinicalFindingId={props.clinicalFindingId}
                clinicalFindingIndex={props.clinicalFindingIndex}
                masterData={{
                  organ: props.masterData?.organ || { name: "", items: [] },
                  clinicaltags: props.masterData?.clinicaltags || {
                    name: "",
                    items: [],
                  },
                }}
                superteeth={[]}
                clinicalFindings={props.clinicalFindings}
                missingteeth={[]}
                implantteeth={[]}
                filterClinicalFindingIds={props.filterClinicalFindingIds}
                // config
                selectorMode={!props.readOnly}
                hideBabyTooth={hideBabyTooth}
                sub={"DiagnosisToday"}
                showDrawingChecked={[]}
                svgZoom={0.65}
              />
            )
          }
          ButtonReadyToView={
            <ButtonReadyToView
              active={!!detail.ready_to_view}
              disabled={!props.allowReadyToView}
              onClick={handleToggleReady}
            />
          }
        />
      </div>
    </Modal>
  );
};

/* ------------------------------------------------------ */

/*                   ButtonReadyToView;                   */

/* ------------------------------------------------------ */
type ButtonReadyToViewProps = {
  active: boolean;
  // style
  style?: CSSProperties;
  checkboxSx?: CheckboxProps["sx"];
  // config
  hideLabel?: boolean;
  disabled?: boolean;
  loading?: boolean;
  // callback
  onClick?: () => any;
};

export const ButtonReadyToView = (props: ButtonReadyToViewProps) => {
  const handleClick = () => {
    if (!props.loading) {
      props.onClick?.();
    }
  };

  return (
    <Button
      disabled={props.disabled}
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: props.active ? "#2185D0" : "transparent",
        border: "1px solid rgba(33, 133, 208, 0.5",
        padding: "0.3rem 0.75rem 0.3rem 0.55rem",
        cursor: props.loading ? "unset" : "pointer",
        ...(props.style || {}),
      }}
      onClick={handleClick}
    >
      <Dimmer
        active={props.loading}
        size="small"
        inverted
        style={{
          background: "transparent",
          padding: 0,
        }}
      >
        <Loader size="small" inline={true}></Loader>
      </Dimmer>

      <Checkbox
        disabled={props.disabled}
        checked={!!props.active}
        style={{
          padding: 0,
          marginRight: "0.5rem",
        }}
        sx={{
          color: "rgba(33, 133, 208, 0.5)",
          "& .MuiSvgIcon-root": { fontSize: "1.75rem" },
          "&.Mui-checked": {
            color: "white",
          },
          ...(props.checkboxSx || {}),
        }}
      />
      <img
        src={props.active ? IMAGES.photo_white : IMAGES.photo_blue}
        alt={props.active ? "white gallery" : "blue gallery"}
        style={{ width: "25px" }}
      />
      {!props.hideLabel && (
        <label
          style={{
            color: props.active ? "white" : "rgba(33, 133, 208, 0.5)",
            marginLeft: "0.5rem",
          }}
        >
          Ready to view
        </label>
      )}
    </Button>
  );
};

export default React.memo(ModXrayDetail);
