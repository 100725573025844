import { degrees, PDFDocument } from "pdf-lib";

const base64toBlob = (data: string) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr(
    "data:application/pdf;base64,".length
  );
  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  const out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
};

export const createPDFQrCodeDrugDelivery = (props) => {
  import("pdfmake/build/pdfmake").then((make) => {
    import("react-lib/assets/fonts/pdfFonts").then(async (font) => {
      let pdfMake = make.default;
      pdfMake.vfs = font.default;
      pdfMake.fonts = {
        THSarabunNew: {
          normal: "THSarabunNew.ttf",
          bold: "THSarabunNew-Bold.ttf",
          italics: "THSarabunNew-Italic.ttf",
          bolditalics: "THSarabunNew-BoldItalic.ttf",
        },
        Roboto: {
          normal: "Roboto-Regular.ttf",
          bold: "Roboto-Medium.ttf",
          italics: "Roboto-Italic.ttf",
          bolditalics: "Roboto-MediumItalic.ttf",
        },
      };

      var origin = window?.location?.origin;
     
      
      // playground requires you to assign document definition to a variable called dd

      var docDefinition = {
        info: {
          title: props.patient_name,
          author: props.author,
          subject: "Drug Delivery QrCode Report",
          keywords: "DrugDelivery QrCode",
        },

        defaultStyle: {
          font: "THSarabunNew",
          // alignment: 'justify'
          lineHeight: 1,
          fontSize: 13,
        },

        // pageMargins: [20, 285, 20, 20],
        // //A4: [595.28, 841.89]
        // pageSize: "A4",
        styles: {
          fieldKey: {
            bold: true,
            fontSize: 20,
          },
          fieldHeader: {
            fontSize: 30,
            bold: true,
            color: "#90419A",
            margin: [0, 20, 0, 10],
          },
          fieldText: {
            fontSize: 23,
            bold: true,
          },

          tableNormal: {
            margin: [0, 5, 0, 5],

            color: "#90419A",
          },
          tableHeaderType: {
            bold: true,
            fontSize: 25,
            color: "white",
            fillColor: "#90419A",
            alignment: "center",
          },
        },
        content: {
          //   margin: [20, 10, 20, 0],
          stack: [
            {
              columns: [
                {
                  style: "tableNormal",
                  table: {
                    //   widths: ["60%"],
                    body: [
                      [{ qr: `{ "encounter" : "${props.encounter}" ,
                                "shipping"  : "${props.shipping}" }`,
                         fit: "220" }],
                      [
                        {
                          style: "tableHeaderType",
                          stack: [{ text: "SCAN NOW" }],
                        },
                      ],
                    ],
                  },
                  layout: {
                    hLineColor: function (i:any, node:any) {
                      return "#90419A";
                    },
                    vLineColor: function (i:any, node:any) {
                      return "#90419A";
                    },
                  },
                },
                {
                  width: "60%",
                  margin: [30, 0, 0, 0],
                  stack: [
                    { text: props?.division, style: "fieldHeader" },
                    {
                      text: [
                        { text: "รายการยา: ", style: "fieldKey" },
                        { text: props.order, style: "fieldKey" },
                      ],
                    },
                    {
                      text: [
                        { text: "ชื่อผู้รับยา: ", style: "fieldKey" },
                        { text: props.patient_name, style: "fieldKey" },
                      ],
                    },
                    {
                      margin: [0, 10, 0, 0],
                      canvas: [
                        {
                          type: "line",
                          color:"lightgray",
                          x1: 0,
                          y1: 5,
                          x2: 220,
                          y2: 5,
                          lineWidth: 0.5,
                        },
                      ],
                    },

                    {
                      margin: [0, 15, 0, 0],
                      text: "กรุณาสแกนคิวอาร์โค้ดเพื่อรับยา ",
                      style: "fieldText",
                    },
                    { text: "และรับคำปรึกษายาจากเภสัชกร", style: "fieldText" },
                  ],
                },
              ],
            },
            ,
          ],
        },

        images: {
          // userProfile: props.imageProfile && props.imageProfile != "/static/images/user.png" ? props.imageProfile : origin + "/static/images/user.png",
          // logochula: origin + "/static/images/logochula.png",
        },
      };

      console.log(" Start PDF Create ");
      // pdfMake.createPdf(docDefinition).download(props.hn + "-" + props.created_utc + ".pdf");
      const pdfDoc = await PDFDocument.create();

      pdfMake.createPdf(docDefinition).open();
      //   const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      //   pdfDocGenerator.getDataUrl(async (dataUrl) => {
      //     const doc = await PDFDocument.load(dataUrl);
      //     const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());

      //     const url = "/static/fonts/THSarabunNew-Bold.ttf";
      //     pdfDoc.registerFontkit(fontkit);
      //     const fontBytes = await fetch(url).then((res) => res.arrayBuffer());

      //     const timesRomanFont = await pdfDoc.embedFont(fontBytes);
      //     const currentDate = new Date();
      //     const year = (currentDate.getFullYear() + 543).toString().padStart(4, "0");
      //     const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      //     const day = currentDate.getDate().toString().padStart(2, "0");
      //     const hour = currentDate.getHours().toString().padStart(2, "0");
      //     const min = currentDate.getMinutes().toString().padStart(2, "0");
      //     const stringDateTimeWaterMark = day + "/" + month + "/" + year + " " + hour + ":" + min;
      //     copiedPages.forEach((page) => {
      //       const pageDraw = pdfDoc.addPage(page);
      //       // pageDraw.drawText(
      //       //   "เอกสารควบคุม ห้ามเผยแพร่โดยไม่ได้รับอนุญาต \n คณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย \n เปิดดูโดย (" +
      //       //   props.author +
      //       //   " " +
      //       //   stringDateTimeWaterMark +
      //       //   ")",
      //       //   {
      //       //     font: timesRomanFont,
      //       //     x: 150,
      //       //     y: 400,
      //       //     size: 25,
      //       //     lineHeight: 30,
      //       //     opacity: 0.2,
      //       //     rotate: degrees(30),
      //       //   }
      //       // );
      //     });
      // pdfDoc.setTitle(props.patient_name);
      // pdfDoc.setAuthor(props.author);
      // pdfDoc.setSubject("Visit Report");
      // pdfDoc.setKeywords(["Examination Treatment"]);

      // const base64Data = await pdfDoc.saveAsBase64();

      // const blob = base64toBlob("data:application/pdf;base64," + base64Data);
      // const bloburl = URL.createObjectURL(blob);
      // window.open(bloburl);
      //   });
    });
  });
};
