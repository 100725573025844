import React, {
  MutableRefObject,
  useEffect,
  useRef,
  useMemo,
  SyntheticEvent,
  useState,
  useCallback,
} from "react";
import { Button, Modal, Popup } from "semantic-ui-react";

import moment from "moment";

// UX
import CardMedicationErrorWorkingUX from "./CardMedicationErrorWorkingUX";
import ModSelectQuarter from "./ModSelectQuarter";

// Common
import SearchBox from "react-lib/apps/common/SearchBox";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import { ModInfo, ErrorMessage, DateTextBox } from "react-lib/apps/common";

// Interface
import { MED_ERROR_CATEGORY } from "./sequence/MedErrorList";

// Utils
import { formatDate, beToAd } from "react-lib/utils/dateUtils";
import { PreventClickEvent } from "../ORM/CardORRequest";

// Types
type CardMedicationErrorWorkingProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: any;
  // data
  medErrId?: number;
  medErrorWorking: Record<string, any>;
  defaultStakeholder?: Record<string, any>;
  drugName?: string;
  drugCode?: string;
  title?: string;
  quarterDetail?: any;
  // options
  masterOptions?: Record<string, any>;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  selectedDivision?: Record<string, any>;
  // config
  readOnly?: boolean;
  isOrderItem?: boolean;
  isActionComplete?: boolean;
  showSolution?: boolean;
  editMode?: boolean;
  disabledEdit?: boolean;
  hideEditQuarter?: boolean;
  // callback
  onEdited: () => any;
};

const CardMedicationErrorWorking = (props: CardMedicationErrorWorkingProps) => {
  // Mod
  const [openModInfo, setOpenModInfo] = useState<any>(null);
  const [openModSelectQuarter, setOpenModSelectQuarter] = useState(false);

  const stakeholderRef = useRef() as MutableRefObject<any>;
  const medErrorRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    PreventClickEvent(!props.readOnly, medErrorRef.current);
  }, [props.readOnly, medErrorRef.current]);

  // Callback Effect
  const updateCorrective = useCallback(async () => {
    const corrective = detail.corrective_user
      ? "STAFF"
      : detail.corrective_division
      ? "CLINIC"
      : "";

    props.runSequence({
      sequence: "MedErrorList",
      action: "update",
      detail: { ...detail, editor_by: corrective },
    });
  }, [props.medErrorWorking?.user]);

  useEffect(() => {
    if (props.medErrId) {
      props.runSequence({
        sequence: "MedErrorList",
        id: props.medErrId,
        isOrderItem: props.isOrderItem,
      });
    }
  }, [props.medErrId]);

  useEffect(() => {
    const user = props.medErrorWorking?.user || {};
    const detail = props.medErrorWorking?.detail || {};

    if (user.id) {
      stakeholderRef.current.setId(user.id || null);
      stakeholderRef.current.setText(user.full_name_code || "");

      if (detail) {
        updateCorrective();
      }
    } else if (props.defaultStakeholder?.id) {
      stakeholderRef.current.setId(props.defaultStakeholder.id);
      stakeholderRef.current.setText(props.defaultStakeholder.name);

      // Initial Date
      handleChangeDate("risk_date")(formatDate(moment()));
    }
  }, [props.medErrorWorking?.user]);

  const mapOptions = (list: any[], valueKey = "id") => {
    return list.map((item: any) => ({
      key: item.id,
      value: item[valueKey],
      text: item.name,
    }));
  };

  const mapUserOptions = useCallback((items: any) => {
    return items.map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.full_name ?? item.name,
    }));
  }, []);

  const handleSelectedItem = useCallback(
    async (value: any, key: any, obj: any) => {
      const detail = props.medErrorWorking?.detail;
      const isClinic = detail.editor_by === "CLINIC";
      const searchKey = `${isClinic ? "Division" : "User"}_2`;

      let search = props.searchedItemListWithKey?.[searchKey]?.find(
        (item: any) => item.id === key
      );

      if (!search?.id) {
        await props.setProp(`searchedItemListWithKey.${searchKey}`, []);
      }

      handleChange(null, {
        name: "corrective",
        value: search?.id
          ? { id: search.id, name: search.full_name ?? search.name }
          : null,
        suffix: isClinic ? "division" : "user",
      });
    },
    [props.searchedItemListWithKey, props.medErrorWorking?.detail]
  );

  const typeOptions = useMemo(() => {
    return mapOptions(props.medErrorWorking?.typeList || []);
  }, [props.medErrorWorking?.typeList]);

  const causeOptions = useMemo(() => {
    return mapOptions(props.medErrorWorking?.causeList || [], "name");
  }, [props.medErrorWorking?.causeList]);

  const detail = useMemo(() => {
    return props.medErrorWorking?.detail || {};
  }, [props.medErrorWorking?.detail]);

  const categoryMessage = useMemo(() => {
    return (
      <>
        {MED_ERROR_CATEGORY.map((text) => (
          <div>{text}</div>
        ))}
      </>
    );
  }, []);

  const quarterDetail = useMemo(() => {
    return {
      quarter: detail.quarter_no,
      year: detail.quarter_year,
      startDate: detail.quarter_start_date
        ? formatDate(moment(detail.quarter_start_date))
        : "",
      endDate: detail.quarter_end_date
        ? formatDate(moment(detail.quarter_end_date))
        : "",
    };
  }, [detail]);

  const handleGetSearchOptions = (data: any) => {
    return props.onEvent({
      message: "GetSearchUserList",
      params: { search: data.searchText },
    });
  };

  const handleChange = async (e: any, v: any) => {
    const data: any = {};
    if (v.name === "type") {
      props.runSequence({
        sequence: "MedErrorList",
        action: "change_type",
        type: v.value,
      });

      data.type_detail = [];
    } else if (v.name === "editor_by") {
      data.corrective_user = null;
      data.corrective_user_name = "";
    }

    data[v.name] = v.value;

    if (v.name === "editor_by" && props.selectedDivision?.id) {
      const division = v.value === "CLINIC" ? props.selectedDivision : {};

      data.corrective_division = division.id || null;
      data.corrective_division_name = division.name || "";
    }

    if (data.corrective_division) {
      await props.setProp("searchedItemListWithKey.Division_2", [
        { id: data.corrective_division, name: data.corrective_division_name },
      ]);
    }

    props.runSequence({
      sequence: "MedErrorList",
      action: "update",
      detail: {
        ...detail,
        ...data,
        ...(v.name === "corrective"
          ? {
              [`corrective_${v.suffix}`]: v.value?.id || null,
              [`corrective_${v.suffix}_name`]: v.value?.name || null,
            }
          : {}),
      },
    });
  };

  const handleAddOptions = (e: SyntheticEvent, v: any) => {
    const list = (
      {
        type_detail: "typeDetailOptions",
        cause_detail: "causeDetailOptions",
      } as any
    )[v.name];

    const arrayUniqueByKey = (items: any[]) => {
      return Array.from(
        new Map(items.map((item) => [item["value"], item])).values()
      );
    };

    props.runSequence({
      sequence: "MedErrorList",
      action: "update",
      [list]: arrayUniqueByKey([
        { key: v.value, text: v.value, value: v.value },
        ...(props.medErrorWorking?.[list] || []),
      ]),
      detail: {
        ...detail,
        [v.name]: arrayUniqueByKey([...detail[v.name], v.value]),
      },
    });
  };

  const handleEdit = () => {
    const division = detail.corrective_division;

    props.runSequence({
      sequence: "MedErrorList",
      action: "edit",
      data: {
        ...props.medErrorWorking.detail,
        drug_name: detail.drug_name || props.drugName,
        drug_code: detail.drug_code || props.drugCode,
        order_item: detail.order_item || props.medErrId,
        stakeholder: stakeholderRef.current?.getId(),
        corrective:
          detail.editor_by === "CLINIC" ? division : detail.corrective_user,
      },
      isActionComplete: props.isActionComplete,
      callback: (status: string, message: any) => {
        setOpenModInfo({ status, message });
      },
    });
  };

  const onCloseModSuccess = () => {
    setOpenModInfo(null);
    props.onEdited?.();
  };

  const handleChangeDate = (name: string) => (value: string) => {
    handleChange(null, { value, name });
  };

  const handleEditQuarter = (data: any) => {
    props.runSequence({
      sequence: "MedErrorList",
      action: "update",
      detail: {
        ...detail,
        ...{
          quarter_no: data.quarter,
          quarter_year: data.year,
          quarter_start_date: data.startDate
            ? beToAd(data.startDate)?.format("YYYY-MM-DD")
            : "",
          quarter_end_date: data.endDate
            ? beToAd(data.endDate)?.format("YYYY-MM-DD")
            : "",
        },
      },
    });

    handleCloseModSelectQuarter();
  };

  const handleOpenModSelectQuarter = () => {
    setOpenModSelectQuarter(true);
  };

  const handleCloseModSelectQuarter = () => {
    setOpenModSelectQuarter(false);
  };

  return (
    <div ref={medErrorRef}>
      <CardMedicationErrorWorkingUX
        // Data
        title={props.title}
        detail={detail}
        status={detail.status || ""}
        drugName={detail.drug_name || props.drugName}
        drugCode={detail.drug_code || props.drugCode}
        riskTypeOptions={props.masterOptions?.medErrorRiskType}
        typeOptions={typeOptions}
        categoryOptions={props.masterOptions?.medErrorCategory}
        typeDetailOptions={props.medErrorWorking?.typeDetailOptions}
        causeOptions={causeOptions}
        causeDetailOptions={props.medErrorWorking?.causeDetailOptions}
        riskType={detail.risk_type}
        type={detail.type}
        category={detail.category}
        typeDetail={detail.type_detail || []}
        cause={detail.cause || []}
        causeDetail={detail.cause_detail || []}
        editorBy={detail.editor_by}
        solutionNote={detail.solution_note}
        patientName={detail.patient_name}
        divisionName={detail.order_division}
        incidentDate={
          detail.request_time
            ? `${formatDate(moment(detail.request_time))} [${moment(
                detail.request_time
              ).format("HH:mm")}]`
            : ""
        }
        quarterDetail={quarterDetail}
        // Callback
        onChange={handleChange}
        onAddOptions={handleAddOptions}
        onEdit={handleEdit}
        onOpenModSelectQuarter={handleOpenModSelectQuarter}
        // config
        showSolution={props.showSolution}
        editMode={props.editMode}
        disabledEdit={props.disabledEdit}
        hideEditQuarter={props.hideEditQuarter}
        // Element
        userSearch={
          <SearchBox
            ref={stakeholderRef}
            defaultId={stakeholderRef.current?.getId()}
            defaultText={stakeholderRef.current?.getText()}
            key="userSearchMedicationWorking"
            onGetSearchOptions={handleGetSearchOptions}
            textField="full_name"
            fluid={true}
            inputStyle={{ width: "100%" }}
          />
        }
        staffSearch={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type={detail.editor_by === "CLINIC" ? "Division" : "User"}
            id="2"
            // fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={
              (detail.editor_by === "CLINIC"
                ? detail.corrective_division
                : detail.corrective_user) || null
            }
            setSelectedItem={handleSelectedItem}
            // options
            mapOptions={mapUserOptions}
            style={{
              marginTop: "-0.95rem",
              marginLeft: "1rem",
              width: "100%",
            }}
            dropdownStyle={{ height: "3rem" }}
          />
        }
        errorMessage={<ErrorMessage error={openModInfo?.message} />}
        StartDate={
          <DateTextBox
            value={detail.risk_date || ""}
            onChange={handleChangeDate("risk_date")}
          />
        }
        Tooltip={
          <Popup
            content={categoryMessage}
            position="right center"
            style={{ minWidth: "22.25rem" }}
            trigger={
              <Button
                icon="info"
                circular
                color="blue"
                size="tiny"
                style={{
                  padding: "2.5px 3px",
                  fontSize: "0.75rem",
                  marginLeft: "5px",
                }}
              />
            }
          />
        }
      />

      <ModInfo
        open={openModInfo?.status === "success"}
        titleColor="green"
        titleName={"บันทึกรายการสำเร็จ"}
        btnText="ตกลง"
        onApprove={onCloseModSuccess}
        style={{ top: "calc(50vh - 130px)" }}
      />

      <Modal
        open={openModSelectQuarter}
        size="small"
        closeOnDimmerClick={true}
        // callback
        onClose={handleCloseModSelectQuarter}
      >
        <ModSelectQuarter
          detail={quarterDetail}
          onSave={handleEditQuarter}
          // callback
          onClose={handleCloseModSelectQuarter}
        />
      </Modal>
    </div>
  );
};

export default React.memo(CardMedicationErrorWorking);
