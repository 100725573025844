import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  TextArea,
  Dropdown
} from 'semantic-ui-react'

const CardDrugVerbalOrderUX = (props: any) => {
    return(
      <div>
        <div
          style={{padding: "10px", fontSize: "1.2rem", fontWeight: "bold" ,display: "flex", alignItems: "center" }}>
          
          <div>
            คำสั่งแพทย์​
          </div>
        </div>
        <div
          style={{padding: "10px" ,display: "flex", alignItems: "center" }}>
          
          <label
            style={{minWidth: "max-content", fontWeight: "bold"}}>
            ประเภทใบยา
          </label>
          <Dropdown
            onChange={props.drugTypeChange}
            options={props.drugTypeOptions}
            selection={true}
            style={{width: "100%", marginLeft:"15px"}}
            value={props.drugType}>
          </Dropdown>
        </div>
        <div
          className="ui form"
          style={{padding: "10px", fontWeight: "bold" ,display: "flex", alignItems: "center" }}>
          
          <TextArea
            onChange={props.noteChange}
            style={{height: "300px", width: "100%"}}
            value={props.note}>
          </TextArea>
        </div>
        <div
          style={{padding: "10px",display: "flex", alignItems: "center" }}>
          
          <label
            style={{minWidth: "max-content", fontWeight: "bold" }}>
            แผนกที่สั่ง
          </label>
          <Dropdown
            onChange={props.orderDivChange}
            options={props.orderDivOptions}
            selection={true}
            style={{width: "100%", marginLeft:"15px"}}
            value={props.orderDiv}>
          </Dropdown>
        </div>
      </div>
    )
}


export default CardDrugVerbalOrderUX

export const screenPropsDefault = {}

/* Date Time : Mon Jul 17 2023 11:48:13 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontSize: \"1.2rem\", fontWeight: \"bold\" ,display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\" ,display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\",display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "คำสั่งแพทย์​"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "TextArea",
      "parent": 2,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.noteChange"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.note"
        }
      },
      "seq": 5,
      "void": true
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\" ,display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Dropdown",
      "parent": 6,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.drugTypeChange"
        },
        "options": {
          "type": "code",
          "value": "props.drugTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginLeft:\"15px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.drugType"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทใบยา"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"bold\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนกที่สั่ง"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"bold\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Dropdown",
      "parent": 3,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.orderDivChange"
        },
        "options": {
          "type": "code",
          "value": "props.orderDivOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginLeft:\"15px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.orderDiv"
        }
      },
      "seq": 11,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugVerbalOrderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
