import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Icon, Modal, Button, Dropdown, Image } from "semantic-ui-react";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import CardTelepharQueueUX from "./CardTelepharQueueUX";
import CardTelepharPostponeUX from "./CardTelepharPostponeUX";
import moment from "moment";
import { set } from "date-fns";
import { formatDatetime, beToAd } from "react-lib/utils/dateUtils";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import "./CardTelepharQueue.scss";
import "react-toastify/dist/ReactToastify.css";

const styles = {
  tableCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  } as CSSProperties,
};

const IMAGES = {
  noti: "/static/images/icon-noti.png",
};

const COLOR = {
  greenOlive: "#75A843",
  greenOliveLight: "#EAA94D",
};

const CardTelepharQueue = (props: any) => {
  const history = useHistory();
  const [openAddPatient, setOpenAddPatient] = useState<boolean>(false);
  const [isPostpone, setIsPostpone] = useState<boolean>(false);
  const [notiList, setNotiList] = useState<any>([]);
  const [isPostponeRequest, setIsPostponeRequest] = useState<boolean>(false);
  const [selectPatient, setSelectPatient] = useState<any>(null);
  const [dateOption, setDateOption] = useState<any[]>([]);
  const [timeOption, setTimeOption] = useState<any[]>([]);
  const [applicationOption, setApplicationOption] = useState<any[]>([]);
  // const { enqueueSnackbar } = useSnackbar();
  const formatDateTiem = "YYYYMMDD HH:mm";
  const formatDate = "YYYYMMDD";
  useEffect(() => {
    props.runSequence({ sequence: "TelePharmacy", restart: true });
    return () => {
      props.runSequence({ sequence: "TelePharmacy", clear: true });
    };
  }, []);

  useEffect(() => {
    if (selectPatient) {
      props.runSequence({
        sequence: "TelePharmacy",
        action: "setPostpone",
        patientName: selectPatient.full_name,
        patientHn: selectPatient.hn,
        patientSelectedId: selectPatient.id,
      });
    }
  }, [selectPatient]);

  useEffect(() => {
    if (props.masterOptions?.applications) {
      const applicationOption = props.masterOptions?.applications.filter(
        (item: any) => {
          return ["ishealth", "HIS"].includes(item.text);
        }
      );
      setApplicationOption(applicationOption);
    } else {
      setApplicationOption([]);
    }
  }, [props.masterOptions?.applications]);

  const handleChangeDateStock = useCallback(
    (v: any, index: any) => {
      const stockDump = (props.TelePharmacySequence?.stockPatient || []).map(
        (items: any, indexMap: any) => {
          if (indexMap === index) {
            return {
              ...items,
              appointmentDate: v.value,
              checkedUnAppointment: false,
            };
          } else {
            return { ...items };
          }
        }
      );
      props.setProp(`TelePharmacySequence.stockPatient`, stockDump);
    },
    [props.TelePharmacySequence]
  );

  const handleChangeTimeStock = useCallback(
    (v: any, index: any) => {
      const stockDump = (props.TelePharmacySequence?.stockPatient || []).map(
        (items: any, indexMap: any) => {
          if (indexMap === index) {
            return { ...items, divisionServiceBlockSelected: v.value };
          } else {
            return { ...items };
          }
        }
      );
      props.setProp(`TelePharmacySequence.stockPatient`, stockDump);
    },
    [props.TelePharmacySequence]
  );

  const TelePharItems = useMemo(() => {
    return (props.TelePharmacySequence?.patientList || []).map(
      (item: any, index: number) => {
        let dateAppointment = "";
        let startTime = "";
        let endTime = "";
        let patientType = "";
        if (item.display_info != null) {
          dateAppointment =
            moment(item.display_info?.start_datetime_iso).format(
              "DD/MM/YYYY"
            ) || "";
          startTime =
            moment(item.display_info?.start_datetime_iso).format("HH:mm") || "";
          endTime =
            moment(item.display_info?.end_datetime_iso).format("HH:mm") || "";
        }

        if (item.division_service_block != null) {
          patientType =
            (props.masterOptions?.patientType || []).find(
              (items: any) => items.value === item.type
            )?.text || "";
        }

        return {
          ...item,
          en: (
            <div style={styles.tableCenter}>
              {item.application === "HIS" ? item.order_encounter : ""}
            </div>
          ),
          hnElement: <div style={styles.tableCenter}>{item.patient_hn}</div>,
          patientName: (
            <div
              style={styles.tableCenter}
            >{`${item.patient_pre_name} ${item.patient_first_name} ${item.patient_last_name}`}</div>
          ),
          patientType: <div style={styles.tableCenter}>{patientType}</div>,
          appointmentType: (
            <div style={styles.tableCenter}>{item.application}</div>
          ),
          appointmentDateTime: (
            <div
              style={styles.tableCenter}
            >{`${dateAppointment} ${startTime}-${endTime}`}</div>
          ),
          booking: (
            <div style={styles.tableCenter}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  minWidth: "195px",
                  background:
                    [2,4].includes(item.status)
                      ? COLOR.greenOlive
                      : COLOR.greenOliveLight,
                  color: "white",
                  padding: "10px 0",
                }}
                onClick={() => {
                  handlePostpone(
                    item,
                    dateAppointment,
                    `${startTime}-${endTime}`,
                    patientType
                  );
                }}
              >
                {[2,4].includes(item.status) ? "Booking Complete" : "Request Booking"}
                <Icon
                  name="calendar alternate outline"
                  style={{ marginLeft: "10px" }}
                />
              </div>
            </div>
          ),
        };
      }
    );
  }, [
    props.TelePharmacySequence?.patientList,
    props.masterOptions.patientType,
  ]);

  const stockPatientList = useMemo(() => {
    return (props.TelePharmacySequence?.stockPatient || []).map(
      (item: any, index: number) => {
        let patientType = "";
        if (item.patientType != null) {
          patientType =
            props.masterOptions?.patientType.find(
              (items: any) => items.value === item.patientType
            )?.text || "";
        }
        let timeOptionFilter = [];
        if (item.appointmentDate != null) {
          timeOptionFilter = timeOption.filter((items: any) => {
            const date = items.key.split("-")?.[0];
            return date === item.appointmentDate;
          });
        }

        return {
          patient_name: (
            <div style={styles.tableCenter}>{item.patient_name}</div>
          ),
          hn: <div style={styles.tableCenter}>{item.hn}</div>,
          patientType: <div style={styles.tableCenter}>{patientType}</div>,
          appointmentDate: (
            <div style={styles.tableCenter}>
              <Dropdown
                upward={false}
                options={dateOption}
                value={item.checkedUnAppointment ? "" : item.appointmentDate}
                placeholder={"ไม่ระบุนัดหมาย"}
                defaultValue={"ไม่ระบุนัดหมาย"}
                onChange={(e, v) => {
                  handleChangeDateStock(v, index);
                }}
              />
            </div>
          ),
          appointmentTime: (
            <div style={styles.tableCenter}>
              <Dropdown
                upward={false}
                options={timeOptionFilter}
                value={
                  item.checkedUnAppointment
                    ? ""
                    : item.divisionServiceBlockSelected
                }
                placeholder={"ไม่ระบุนัดหมาย"}
                defaultValue={"ไม่ระบุนัดหมาย"}
                onChange={(e, v) => {
                  handleChangeTimeStock(v, index);
                }}
              />
            </div>
          ),
          delete: (
            <div
              style={styles.tableCenter}
              onClick={() => {
                handleDeletePatient(item.hn);
              }}
            >
              <Icon name="trash alternate outline" color="blue" size="large" />
            </div>
          ),
        };
      }
    );
  }, [props.TelePharmacySequence?.stockPatient]);

  const timeOptionFilter = useMemo(() => {
    if (props.TelePharmacySequence?.patientAppointmentDate) {
      return timeOption
        .filter((item: any) => {
          const date = item.key.split("-")?.[0];
          return date === props.TelePharmacySequence?.patientAppointmentDate;
        })
        ?.sort((a: any, b: any) => (a.value > b.value ? 1 : -1));
    } else {
      return [];
    }
  }, [props.TelePharmacySequence?.patientAppointmentDate]);

  useEffect(() => {
    if (props.TelePharmacySequence?.divisionServiceBlockList?.length > 0) {
      const DSBClone = props.TelePharmacySequence?.divisionServiceBlockList;
      const dateOption: any[] = [];
      const timeOption: any[] = [];
      DSBClone.forEach((items: any) => {
        const date = moment(items.end_datetime_iso).format("DD/MM/YYYY");
        const dateNum = moment(items.end_datetime_iso).format("YYYYMMDD");
        const time = `${moment(items.start_datetime_iso).format(
          "HH:mm"
        )}-${moment(items.end_datetime_iso).format("HH:mm")}`;
        if (!dateOption.some((item: any) => item.value === date)) {
          dateOption.push({
            key: dateNum,
            text: date,
            value: date,
          });
        }

        timeOption.push({
          key: `${date}-${items.id}`,
          text: time,
          value: items.id,
        });
      });
      dateOption.sort((a: any, b: any) => (a.key > b.key ? 1 : -1));
      const dateOptionFilter = dateOption.filter(
        (item: any) => item.key >= moment().format("YYYYMMDD")
      );

      setDateOption(dateOptionFilter);
      setTimeOption(timeOption);
    }
  }, [props.TelePharmacySequence?.divisionServiceBlockList]);

  const handlePostpone = (item: any, date: any, time: any, type: any) => {
    props.runSequence({
      sequence: "TelePharmacy",
      action: "setPostpone",
      patientName: `${item.patient_pre_name} ${item.patient_first_name} ${item.patient_last_name}`,
      patientHn: item.patient_hn,
      patientType: type || "",
      patientOldAppointmentDate: date || "",
      patientOldAppointmentTime: time || "",
      appointmentId: item.id || 0,
    });

    if (item.booking === "request") {
      setIsPostpone(true);
      setIsPostponeRequest(true);
      setOpenAddPatient(true);
    } else {
      setIsPostpone(true);
      setIsPostponeRequest(false);
      setOpenAddPatient(true);
    }
  };

  const handleDeletePatient = (hn: any) => {
    const stockPatientDelete = props.TelePharmacySequence?.stockPatient?.find(
      (item: any) => item.hn === hn
    );

    props.runSequence({
      sequence: "TelePharmacy",
      action: "updatePatientAppointment",
      id: stockPatientDelete.appointmentId,
      status: 5,
      actionType: "delete",
    });
  };

  const handleClearData = () => {
    props.runSequence({ sequence: "TelePharmacy", action: "clearDataSearch" });
  };

  const handleChangeValue = (key: string) => (e: any, v: any) => {
    props.setProp(`TelePharmacySequence.${key}`, v.value);
  };

  const handleChangeDate = (key: string) => (date: any) => {
    props.setProp(`TelePharmacySequence.${key}`, date);
  };

  const handleOpenAddPatientMode = () => {
    // add Open table stock table
    props.runSequence({
      sequence: "TelePharmacy",
      action: "setPostpone",
    });
    setSelectPatient(null);
    setOpenAddPatient(true);
    setIsPostpone(false);
  };

  const handleChangeChecked =
    (key: string) =>
    (e: any, { checked }: any) => {
      props.setProp(`TelePharmacySequence.${key}`, checked);
    };

  const handleSearchAppointment = () => {
    let patientSearch = "";
    let hn = "";
    if (props.TelePharmacySequence?.selectedPatientSearch?.search("-") !== -1) {
      hn = props.TelePharmacySequence?.selectedPatientSearch;
    } else {
      patientSearch = props.TelePharmacySequence?.selectedPatientSearch;
    }

    const startDate = beToAd(props.TelePharmacySequence?.selectedStartDate);
    const endDate = beToAd(props.TelePharmacySequence?.selectedEndDate);
    props.runSequence({
      sequence: "TelePharmacy",
      action: "searchAppointment",
      waitingList: props.TelePharmacySequence?.wittingList,
      provider_id: props.TelePharmacySequence?.provider,
      selectedDivision: props.selectedDivision?.code,
      hn: hn,
      selectedPatientSearch: patientSearch,
      selectedPatientType: props.TelePharmacySequence?.selectedPatientType,
      selectedApplicationType:
        props.TelePharmacySequence?.selectedApplicationType,
      selectedAppointmentStatus:
        props.TelePharmacySequence?.selectedAppointmentStatus,
      selectedStartDate: moment(startDate, "DD/MM/YYYY").toISOString(),
      selectedEndDate: moment(endDate, "DD/MM/YYYY")
        .add(1, "days")
        .toISOString(),
    });
  };

  const handleAddPatient = () => {
    // add patient to stock table
    if (props.TelePharmacySequence?.patientHn != null) {
      setSelectPatient(null);
      let stockPatientDump: any[] =
        props.TelePharmacySequence?.stockPatient || [];
      stockPatientDump.push({
        patient_name: props.TelePharmacySequence?.patientName,
        hn: props.TelePharmacySequence?.patientHn,
        patientType: props.TelePharmacySequence?.patientType,
        appointmentDate: props.TelePharmacySequence?.checkedUnAppointment
          ? ""
          : props.TelePharmacySequence?.patientAppointmentDate,
        divisionServiceBlockSelected: props.TelePharmacySequence
          ?.checkedUnAppointment
          ? ""
          : props.TelePharmacySequence?.divisionServiceBlockSelected,
        checkedUnAppointment: props.TelePharmacySequence?.checkedUnAppointment,
        patientSelectedId: props.TelePharmacySequence?.patientSelectedId,
        appointmentId: 0,
      });

      props.runSequence({
        sequence: "TelePharmacy",
        action: "createWaitingList",
        stockPatient: stockPatientDump,
      });
    }
  };

  const handlePostponeAppointment = () => {
    if (isPostpone) {
      props.runSequence({
        sequence: "TelePharmacy",
        action: "updatePatientAppointment",
        actionType: "postpone",
        status: 4,
      });
    } else {
      // confirm crate appointment from stockPatient
      props.runSequence({
        sequence: "TelePharmacy",
        action: "updatePatientAppointment",
        actionType: "update",
        status: 2,
      });
    }
  };

  const mapOptions = (items: any) => {
    return items.map((item: any) => ({
      key: item.hn,
      value: item.hn,
      text: `${item.hn} ${item.full_name}`,
    }));
  };

  const handleCheckDisable = (btName?: any) => {
    if (isPostpone) {
      return (
        !(props.TelePharmacySequence?.appointmentId || "") ||
        (props.TelePharmacySequence?.checkedUnAppointment
          ? !props.TelePharmacySequence?.checkedUnAppointment
          : !(props.TelePharmacySequence?.divisionServiceBlockSelected || ""))
      );
    } else {
      if (btName === "addPatient") {
        return (
          !(props.TelePharmacySequence?.patientSelectedId || "") ||
          !(props.TelePharmacySequence?.patientType || "") ||
          (props.TelePharmacySequence?.checkedUnAppointment
            ? !props.TelePharmacySequence?.checkedUnAppointment
            : !(props.TelePharmacySequence?.divisionServiceBlockSelected || ""))
        );
      } else if (btName === "confirmStock") {
        return !(props.TelePharmacySequence?.stockPatient?.length > 0);
      }
    }
  };

  const handleGetTdPropsTelepharQueue = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        display: "flex",
        justifyContent: "center",
        overflow: "unset",
        cursor: "pointer",
      },
      onClick: () => {
        console.log(" rowInfo", rowInfo.original);
        if (column.id !== "booking") {
          props.onEvent({
            message: "SelectPatientForAppointment",
            params: { id: rowInfo?.original?.patient },
          });
          props.layout === 0 && props.forward();
        }
      },
    };
  };
  const handleGetTrPropsTelepharPostpone = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        display: "flex",
        justifyContent: "center",
        overflow: "unset",
        cursor: "pointer",
      },
    };
  };

  const handleGetTbodyProps = () => {
    return {
      style: { overflow: "unset" },
    };
  };

  // console.log("saika props ", props);
  return (
    <div>
      <CardTelepharQueueUX
        //input
        selectedPatient={
          props.TelePharmacySequence?.selectedPatientSearch || ""
        }
        //DateTextBox value
        selectedStartDate={props.TelePharmacySequence?.selectedStartDate}
        selectedEndDate={props.TelePharmacySequence?.selectedEndDate}
        //DropDown && option
        patientOptions={props.masterOptions?.patientType}
        applicationOption={applicationOption}
        appointmentStatusOption={[
          {
            key: "complete",
            text: "Booking Complete",
            value: "2,4"
          },
          {
            key: "request",
            text: "Request Booking",
            value: 3,
          },
        ]}
        //DropDown && value
        selectedPatientType={props.TelePharmacySequence?.selectedPatientType}
        selectedApplicationType={
          props.TelePharmacySequence?.selectedApplicationType
        }
        selectedAppointmentStatus={
          props.TelePharmacySequence?.selectedAppointmentStatus
        }
        // handle
        handleChangeValue={handleChangeValue}
        handleChangeDate={handleChangeDate}
        //onClick
        handleClearData={handleClearData}
        handleSearchAppointment={handleSearchAppointment}
        addPatient={handleOpenAddPatientMode}
        //table Data
        getTdProps={handleGetTdPropsTelepharQueue}
        tableItems={TelePharItems}
        searchLoading={props.TelePharmacySequence?.searchLoading}
        childrenButton={
          <div>
            <Icon name="plus" />
            {"เพิ่มผู้ป่วย"}
          </div>
        }
      />

      <Modal
        open={openAddPatient}
        onClose={() => setOpenAddPatient(false)}
        style={{ width: isPostpone ? "" : "80%" }}
      >
        <div style={{ padding: "10px 15px" }}>
          <div
            style={{
              width: "100%",
              padding: "10px 10px 0 10px",
              display: isPostpone ? "none" : "flex",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                minWidth: "max-content",
              }}
            >
              {"HN/ชื่อ-สกุล"}
            </div>
            <div
              style={{
                flex: "1",
                justifyContent: "center",
                padding: "0 10px",
              }}
            >
              <SearchBoxDropdown
                type="Patient"
                id="1"
                style={{ width: "100%" }}
                fluid={true}
                onEvent={props.onEvent}
                toDisplay={(item: any) => item.hn}
                searchedItemListWithKey={props.searchedItemListWithKey}
                selectedItem={selectPatient}
                setSelectedItem={(value: any, key: any, obj: any) => {
                  if (value === null && key === null) {
                    setSelectPatient(null);
                    return;
                  }
                  let patient = props.searchedItemListWithKey?.Patient_1?.find(
                    (item: any) => item.hn === key
                  );

                  setSelectPatient(patient);
                }}
                useWithKey={true}
                mapOptions={mapOptions}
              />
            </div>
          </div>
          <div
            style={{
              display: isPostpone ? "flex" : "none",
              width: "100%",
              padding: "10px 0 0 10px",
            }}
          >
            <div
              style={{
                fontSize: "18px",
                ...(!props.isPostpone && { display: "none" }),
                justifyContent: "center",
                fontWeight: "bold",
                padding: "10px",
              }}
            >
              {isPostponeRequest ? "สร้างนัดหมายจาก Request" : "เลื่อนนัดหมาย"}
            </div>
          </div>
          <CardTelepharPostponeUX
            // Label current patient selected
            isPostpone={isPostpone}
            patientName={props.TelePharmacySequence?.patientName}
            patientHn={props.TelePharmacySequence?.patientHn}
            patientOldAppointmentDate={
              props.TelePharmacySequence?.patientOldAppointmentDate
            }
            patientOldAppointmentTime={
              props.TelePharmacySequence?.patientOldAppointmentTime
            }
            checkedUnAppointment={
              props.TelePharmacySequence?.checkedUnAppointment
            }
            // DropDown & option
            patientOption={props.masterOptions?.patientType}
            dateOption={dateOption}
            timeOption={timeOptionFilter}
            //DropDown && value
            patientType={props.TelePharmacySequence?.patientType}
            patientAppointmentDate={
              props.TelePharmacySequence?.patientAppointmentDate
            }
            divisionServiceBlockSelected={
              props.TelePharmacySequence?.divisionServiceBlockSelected
            }
            // table data
            tableItems={stockPatientList}
            getTrProps={handleGetTrPropsTelepharPostpone}
            getTbodyProps={handleGetTbodyProps}
            // handle
            handleChangeValue={handleChangeValue}
            handleChangeChecked={handleChangeChecked}
            handleAddPatient={handleAddPatient}
            handleCheckDisable={handleCheckDisable}
            // loading
            buttonLogin={props.TelePharmacySequence?.telepharLoading}
            divisionLoading={props.TelePharmacySequence?.divisionLoading}
            // disable
            disableDate={props.TelePharmacySequence?.checkedUnAppointment}
            disableTime={props.TelePharmacySequence?.checkedUnAppointment}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: "10px 10px 20px 10px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="blue"
              onClick={handlePostponeAppointment}
              loading={props.TelePharmacySequence?.telepharLoading}
              disabled={handleCheckDisable("confirmStock")}
            >
              {isPostpone ? "สร้างนัดหมาย" : "ยืนยันการเพิ่มข้อมูลผู้ป่วย"}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(CardTelepharQueue);
