import WasmController from "react-lib/frameworks/WasmController";
import moment from "moment";

// APIs
import OperatingOrderBundleCreate from "issara-sdk/apis/OperatingOrderBundleCreate_apps_ORM";
import OperatingOrderDetailByEmr from "issara-sdk/apis/OperatingOrderDetailByEmr_apps_ORM";
import OperatingRoomList from "issara-sdk/apis/OperatingRoomList_apps_ORM";
import ClinicalTermSetView from "issara-sdk/apis/ClinicalTermSetView_core";
import AdmitOrderList from "issara-sdk/apis/AdmitOrderList_apps_ADM";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import EncounterMedicalRecordList from "issara-sdk/apis/EncounterMedicalRecordList_core";
import PatientAppointmentUpdate from "issara-sdk/apis/PatientAppointmentUpdate_apps_QUE";
import OperatingOrderDetail from "issara-sdk/apis/OperatingOrderDetail_apps_ORM";
import UserList from "issara-sdk/apis/UserList_users";
import UserPermissionView from "issara-sdk/apis/UserPermissionView_users";
import OperatingLocationList from "issara-sdk/apis/OperatingLocationList_apps_ORM";
import ClinicalTerm from "issara-sdk/apis/ClinicalTermList_core";
import OperatingOrderPrintDetail from "issara-sdk/apis/OperatingOrderPrintDetail_apps_ORM";

import {
  HandleSearch,
  HandleClear,
  HandleSelect,
  formatData as formatProcedureData,
  formatItems as formatProcedureItems,
} from "../../ADM/sequence/DischargeSummary";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import PatientAppointmentView from "issara-sdk/apis/PatientAppointmentView_apps_QUE";
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  loadingStatus?: any;
  masterOptions?: any;
  selectedEmr?: any;
  searchedItemListWithKey?: any;
  selectedAppointment?: any;
  selectedDoctor?: any;
  selectedAnesthesiologist?: any;
  selectedOperatingRoom?: any;
  selectedOperatingOption?: any;
  userTokenize?: any;
  selectedOrOrder?: any;
  selectedPatient?: any;
  appointmentList?: any[];
  selectedEncounter?: any;
  preOrderList?: any;

  // sequence
  ORRequestSequence?: Partial<{
    sequenceIndex?: string | null;
    operatingRoomOptions?: any[];
    orLocationOptions?: any[];
    clinicalTerm: Record<string, any>;
    admitOrderOptions?: any[];
    orImplantOptions?: any[];
    activeSurgeonTeam?: number;
    activeAnesthesiaTeam?: number;
    permission?: any;
    modConfirm?: any;

    id?: number;
    type: string;
    clinic_type: string;
    case: Partial<{
      app_admit_date: string;
      icu_day: string;
      ipd_case: string;
      ipd_day: string;
      is_app_admit: boolean;
      is_ipd_case: boolean;
      is_one_day_case: boolean;
      is_opd_case: boolean;
      is_request_icu: boolean;
    }>;
    blood_request: Partial<{
      ffp: string;
      is_ffp: boolean;
      is_na: boolean;
      is_other: boolean;
      is_plt: boolean;
      is_prc: boolean;
      is_yes: boolean;
      is_yes_ts: boolean;
      other: string;
      plt: string;
      prc: string;
    }>;
    teams: Partial<{
      assistant_surgeons: any[];
      assistant_surgeon_items: any[];
      chief_surgeon: any;
      chief_surgeon_item: any;
      id: number;
      implant: string[];
      is_main: boolean;
      pre_operating_order_item: Partial<{
        other_treatment: string;
        position: number;
        other_position: string;
        operating_treatments: any[];
        procedures: any[];
        site_right: boolean;
        site_left: boolean;
      }>;
      pre_principal_diagnosis: any[];
      pre_secondary_diagnosis: any[];
      diagnosis: any[];
      type: number;
    }>[];
    anesthesia_teams: Partial<{
      anesthesiologist: any;
      anesthesiologist_item: any;
      anesthetists: any[];
      anesthetist_items: any[];
      id: any;
      type: string;
    }>[];
    is_appointment: boolean;
    is_request_icu: boolean;
    is_med_block: boolean;
    med_block_date: string;
    is_anesthesia: boolean;
    anesthesia_date: string;
    emr: number;
    is_other: boolean;
    other: string;
    other_date: string;
    start_date: string;
    anesthesia_method: number;
    main_team: string;
    start_time: string;
    is_ipd_patient: boolean;
    anticipated_blood_loss: Partial<{
      is_less_500: boolean;
      is_more_500: boolean;
      define: number;
    }>;
    anti_platelet_coagulant: Partial<{
      value: string;
      remark: string;
      date: string;
    }>;
    estimate_operation_hour: string;
    estimate_operation_minute: string;
    estimate_operation_time: number;
    admit_order: number;
    operating_room: number;
    frozen_section: boolean;
    contrast_media: number;
    asa_class: number;
    special_equipments: number[];
    special_equipments_text: string;
    location: number;
    remark: number;
    status: number;
    extra: {
      nullMaxDate: boolean;
      minDate: moment.Moment;
      maxDate: moment.Moment;
    };
  }> | null;
};

export const StateInitial: State = {
  // sequence
  ORRequestSequence: {
    sequenceIndex: null,
  },
};

const ORInitial = {
  is_appointment: true,
  start_date: "",
  start_time: "",
  case: {
    app_admit_date: "",
  },
  allowed_action: ["EDIT_REQUEST"],
  blood_request: {
    is_na: true,
  },
  teams: [
    {
      is_main: true,
      implant: [],
      assistant_surgeons: [],
      assistant_surgeon_items: [null],
      pre_operating_order_item: {
        operating_treatments: [null],
        procedures: [{}],
      },
      chief_surgeon: null,
      chief_surgeon_item: null,
      pre_principal_diagnosis: [{}, {}],
      diagnosis: [{}, {}],
    },
  ],
  anesthesia_teams: [],
  extra: {
    nullMaxDate: false,
    minDate: moment(),
    maxDate: moment().add(1, "days"),
  },
};

export const TeamInitial = {
  implant: [],
  is_main: false,
  assistant_surgeons: [],
  assistant_surgeon_items: [null],
  pre_operating_order_item: {
    operating_treatments: [null],
    procedures: [{}],
  },
  pre_principal_diagnosis: [{}, {}],
  diagnosis: [{}, {}],
};

export const AnesthesiaTeamInitial = {
  anesthesiologist: null,
  anesthesiologist_item: null,
  anesthetists: [],
  anesthetist_items: [null],
  id: null,
  type: "ANS",
};

export const ADM_FIBRINOLYTIC_USED = {
  ANTI_PALETELET: "A", // งด
  CUT_DRUG: "C", // ไม่ต้องงด
  NO_DRUG: "N", // ไม่ได้ใช้ยา
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "RefreshAppointment"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

// export const CARD_OR_QUEUE_ID: string = "CardORQueue";
/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler = async (controller, params) => {
  let state = controller.getState();

  // Master data
  if (params.action !== "refresh") {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: true },
    });

    controller.handleEvent({
      message: "GetMasterData",
      params: {
        masters: [
          ["orType", {}],
          // ["orLocation", {}],
          ["orProcedureType", {}],
          ["specialEquipment", {}],
          // ["orImplant", {}],
          ["otherTrtPosition", {}],
          ["procedureType", {}],
          ["orCancelNote", {}],
        ],
      },
    } as any);

    await controller.handleEvent({
      message: "GetMasterData",
      params: {
        masters: [
          ["orClinicType", {}],
          ["anesthesiaMethod", {}],
        ],
      },
    } as any);
  }

  state = controller.getState();

  const options = state.masterOptions;

  const getOperatingOrder = OperatingOrderDetail.retrieve({
    apiToken: controller.apiToken,
    pk: params.operatingOrderId,
  });

  const getClinicalTermSet = ClinicalTermSetView.post({
    apiToken: controller.apiToken,
    data: { question: ["asa_class", "CM", "or_implant"] },
  });

  const getAdmitOrder = AdmitOrderList.list({
    apiToken: controller.apiToken,
    params: {
      patient_id: params.patientId,
      not_finished: true,
      active: true,
      is_short: true,
    },
  });

  const getEncounterMedicalRecord = EncounterMedicalRecordList.list({
    pk: params.encounterId,
    extra: {
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  const getUserPermission = UserPermissionView.post({
    apiToken: controller.apiToken,
    data: {
      action_ORM_OperatingOrder_CONFIRM: "",
      action_ORM_OperatingOrder_CANCEL_CONFIRM: "",
      action_ORM_OperatingOrder_CANCEL: "",
    },
  });

  const [order, clinical, admit, medRecord, permission] = await Promise.all([
    getOperatingOrder,
    getClinicalTermSet,
    getAdmitOrder,
    getEncounterMedicalRecord,
    getUserPermission,
  ]);

  const admitOrderOptions = (admit[0]?.items || []).map((item: any) => ({
    key: item.id,
    value: item.id,
    text: `[${item.code}] ${item.admit_type}: ${item.admit_date} (${item.status_label})`,
  }));

  const orImplantOptions = mapOptions(
    clinical[0]?.or_implant?.items || [],
    "name"
  );

  if (order[0]?.id) {
    const chiefSurgeons: number[] = (order[0].teams || []).map(
      (item: any) => item.chief_surgeon
    );
    const assistSurgeons: number[] = (order[0].teams || []).flatMap(
      (item: any) => item.assistant_surgeons
    );
    const anesthesiologists: number[] = (
      order[0].anesthesia_teams || []
    ).flatMap((item: any) => item.anesthesiologist);
    const anesthetists: number[] = (order[0].anesthesia_teams || []).flatMap(
      (item: any) => item.anesthetists
    );

    const uniqueSurgeons = Array.from(
      new Set([...chiefSurgeons, ...assistSurgeons, ...anesthesiologists])
    );
    const uniqueAnesthetists = Array.from(new Set([...anesthetists]));

    const listWithKey = state.searchedItemListWithKey || {};

    removeListWithKey(listWithKey);

    if (!!chiefSurgeons.length) {
      const doctorRes = await Promise.all(
        uniqueSurgeons.map((id: number) =>
          DoctorDetail.retrieve({
            apiToken: controller.apiToken,
            pk: id,
          })
        )
      );

      const userRes = await Promise.all(
        uniqueAnesthetists.map((id: number) =>
          UserList.list({ apiToken: controller.apiToken, params: { pk: id } })
        )
      );

      const findDoctor = (id: number) => {
        return doctorRes.find((acc: any) => acc[0]?.id === id)?.[0];
      };

      const findUser = (id: number) => {
        return userRes.find((acc: any) => acc[0]?.items?.[0]?.id === id)?.[0]
          ?.items?.[0];
      };

      order[0].teams = order[0].teams.map((item: any, index: number) => {
        const data = findDoctor(item.chief_surgeon);
        const implant: any[] = item.implant ? item.implant.split(",") : [];

        const assistSurgeons = item.assistant_surgeons.length
          ? item.assistant_surgeons.map((id: number) => findDoctor(id))
          : [null];

        if (item?.pre_operating_order_item?.operating_treatments) {
          for (const [
            i,
            treatment,
          ] of item?.pre_operating_order_item.operating_treatments?.entries()) {
            listWithKey[`OperatingTreatment_${index + 1}_${i + 1}`] = [
              treatment,
            ];
          }
        }

        for (const [i, assist] of assistSurgeons.entries()) {
          listWithKey[`Doctor_Assist_Surgeon_${index + 1}_${i + 1}`] = assist
            ? [assist]
            : null;
        }

        for (const value of implant) {
          const findOption = orImplantOptions.find(
            (item: any) => item.text === value
          );
          if (!findOption) {
            orImplantOptions.push({ key: value, value, text: value });
          }
        }

        listWithKey[`Doctor_Surgeon_${index + 1}`] = data ? [data] : null;

        return {
          ...item,
          chief_surgeon_item: data
            ? { ...data, name_code: `${data.full_name} (${data.code})` }
            : null,
          diagnosis: [
            ...formatProcedureItems(
              item.pre_principal_diagnosis[0],
              [],
              "icd10"
            ),
            ...formatProcedureItems(
              item.pre_secondary_diagnosis[0],
              item.pre_secondary_diagnosis.slice(1),
              "icd10"
            ),
          ],
          assistant_surgeon_items: assistSurgeons,
          pre_operating_order_item: {
            ...item.pre_operating_order_item,
            procedures: formatProcedureItems(
              item.pre_operating_order_item?.procedures?.[0],
              item.pre_operating_order_item?.procedures?.slice(1),
              "icd9cm"
            ),
          },
          implant,
        };
      });

      order[0].anesthesia_teams = order[0].anesthesia_teams.map(
        (item: any, index: number) => {
          const data = findDoctor(item.anesthesiologist);

          const anesthetists = item.anesthetists.length
            ? item.anesthetists.map((id: number) => findUser(id))
            : [];

          for (const [i, anesthetist] of anesthetists.entries()) {
            listWithKey[`User_Anesthetist_${index + 1}_${i + 1}`] = [
              anesthetist,
            ];
          }

          listWithKey[`Doctor_Anesthesiologist_${index + 1}`] = data
            ? [data]
            : null;

          return {
            ...item,
            anesthesiologist_item: data
              ? { ...data, name_code: `${data.full_name} (${data.code})` }
              : null,
            anesthetist_items: anesthetists,
          };
        }
      );
    }

    const teamIndex = state.ORRequestSequence?.activeSurgeonTeam || 0;

    state = controller.getState();

    controller.setState(
      {
        loadingStatus: { ...state.loadingStatus, [params.card]: false },
        searchedItemListWithKey: listWithKey,
        selectedDoctor: order[0]?.teams?.[0]?.chief_surgeon_item?.id || null,
        selectedAnesthesiologist:
          order[0]?.anesthesia_teams?.[0]?.anesthesiologist_item?.id || null,
        selectedOperatingRoom: order[0]?.operating_room || null,
        selectedOperatingOption: order[0]?.type || "",
        selectedOrOrder: order[0],
        ORRequestSequence: {
          // ...state.ORRequestSequence,W
          ...order[0],
          permission: permission[0],
          clinicalTerm: clinical[0],
          admitOrderOptions,
          orImplantOptions,
          activeSurgeonTeam: teamIndex,
          activeAnesthesiaTeam:
            state.ORRequestSequence?.activeAnesthesiaTeam || 0,
          extra: {
            nullMaxDate: false,
            minDate: moment(),
            maxDate: moment().add(1, "days"),
          },
          sequenceIndex: "SaveAndEdit",
        },
      },
      () =>
        controller.handleEvent({
          message: "RunSequence",
          params: { ...params, action: "update_location_room" },
        })
    );
  } else {
    HandleInitial(controller, {
      ...params,
      options,
      permission: permission[0],
      emrId: medRecord[0]?.items?.[0]?.id || null,
      clinicalTerm: clinical[0],
      admitOrderOptions,
      orImplantOptions,
    });
  }

  console.log("OR Request,", (state as any).masterOptions);
};

const removeListWithKey = (listWithKey: any) => {
  const removeArrayCIndex = (key: string) => {
    var index = 1;
    var cIndex = 1;

    while (listWithKey[`${key}_${index}_${cIndex}`]) {
      delete listWithKey[`${key}_${index}_${cIndex}`];
      cIndex += 1;
      while (listWithKey[`${key}_${index}_${cIndex}`]) {
        delete listWithKey[`${key}_${index}_${cIndex}`];
        cIndex += 1;
      }
      cIndex = 1;
      index += 1;
    }
  };

  const removeArrayIndex = (key: string) => {
    var index = 1;

    while (listWithKey[`${key}_${index}`]) {
      delete listWithKey[`${key}_${index}`];
      index += 1;
    }
  };

  removeArrayCIndex("Doctor_Assist_Surgeon");
  removeArrayCIndex("OperatingTreatment");
  removeArrayIndex("Doctor_Surgeon");
  removeArrayCIndex("User_Anesthetist");
  removeArrayIndex("Doctor_Anesthesiologist");
};

const HandleInitial: Handler = (controller, params) => {
  const state = controller.getState();
  const options = params.options;

  const listWithKey = state.searchedItemListWithKey || {};

  removeListWithKey(listWithKey);

  controller.setState(
    {
      loadingStatus: { ...state.loadingStatus, [params.card]: false },
      searchedItemListWithKey: listWithKey,
      ORRequestSequence: {
        ...JSON.parse(JSON.stringify(ORInitial)),
        ...(options && {
          type: params.isNewEncounter ? "EL" : "EL_NOT_APP",
          clinic_type: options.orClinicType[0].value,
          anesthesia_method: options.anesthesiaMethod[0].value,
          selectedOrOrder: null,
        }),
        activeSurgeonTeam: 0,
        activeAnesthesiaTeam: 0,
        sequenceIndex: "SaveAndEdit",
        id: params.orderId || null,
        emr: params.emrId || null,
        permission: params.permission,
        clinicalTerm: params.clinicalTerm,
        admitOrderOptions: params.admitOrderOptions,
        orImplantOptions: params.orImplantOptions,
        extra: {
          nullMaxDate: false,
          minDate: moment(),
          maxDate: moment().add(1, "days"),
        },
      },
    },
    () =>
      !params.orderId &&
      controller.handleEvent({ message: "RunSequence", params: params })
  );
};

/* ------------------------------------------------------ */

/*                         ACTION                         */

/* ------------------------------------------------------ */
export const SaveAndEdit: Handler = async (controller, params) => {
  const state = controller.getState();

  const orRequest = state.ORRequestSequence || {};

  if (params.action === "edit") {
    HandleEdit(controller, params);
  } else if (["search", "clear", "select"].includes(params.action)) {
    let data = {};

    if (params.key === "procedures") {
      data = (orRequest as any).teams[params.teamIndex]
        .pre_operating_order_item[params.key][params.index];
    } else {
      data = (orRequest as any).teams[params.teamIndex][params.key][
        params.index
      ];
    }

    const change = (data: any) => {
      if (params.key === "procedures") {
        (orRequest as any).teams[params.teamIndex].pre_operating_order_item[
          params.key
        ][params.index] = data;
      } else {
        (orRequest as any).teams[params.teamIndex][params.key][params.index] =
          data;
      }

      controller.setState({
        ORRequestSequence: { ...orRequest },
      });
    };

    if (params.action === "search") {
      HandleSearch(controller as any, {
        ...params,
        data,
        onChange: change,
        onClear: (params: any) =>
          controller.handleEvent({ message: "RunSequence", params }),
      });
    } else if (params.action === "clear") {
      HandleClear(controller as any, {
        ...params,
        data,
        onChange: change,
      });
    } else if (params.action === "select") {
      HandleSelect(controller as any, {
        ...params,
        data,
        onSearch: (params: any) =>
          controller.handleEvent({
            message: "RunSequence",
            params,
          }),
        onChange: change,
      });
    }
  } else if (params.action === "add_team") {
    const teams = state.ORRequestSequence?.teams || [];

    teams.push(TeamInitial);

    controller.setState({
      ORRequestSequence: { ...state.ORRequestSequence, teams },
    });
  } else if (params.action === "anesthesia_team") {
    const teams = state.ORRequestSequence?.anesthesia_teams || [];

    teams.push(AnesthesiaTeamInitial);

    controller.setState({
      ORRequestSequence: {
        ...state.ORRequestSequence,
        anesthesia_teams: teams,
      },
    });
  } else if (params.action === "clear_screen") {
    const detail = state.ORRequestSequence || {};

    HandleInitial(controller, {
      card: params.card,
      orderId: detail?.id,
      emrId: detail?.emr,
      permission: detail?.permission?.[0] || {},
      clinicalTerm: detail?.clinicalTerm,
      admitOrderOptions: detail?.admitOrderOptions,
      orImplantOptions: detail?.orImplantOptions,
    });
  } else if (params.action === "update_location_room") {
    const detail: any = state.ORRequestSequence || {};
    const team =
      detail?.teams?.find((item: any) => item.is_main === true) || {};

    const [result]: any = await OperatingLocationList.list({
      apiToken: controller.apiToken,
      params: {},
    });

    if (detail.location) {
      detail.location = Number(detail.location);
    }

    const locations = mapOptions(result?.items || []);
    let rooms: any[] = [];
    const location = locations.find(
      (item: any) => item.value === detail.location
    );

    if (!location) {
      detail.location = "";
    }

    if (detail.location) {
      rooms = await GetOperatingRoomOptions(controller, {
        surgeon: team.chief_surgeon,
        location: detail.location,
      });

      const room = rooms.find(
        (item: any) => item.value === Number(detail.operating_room)
      );

      if (!room) {
        detail.operating_room = "";
      }
    } else {
      detail.operating_room = "";
    }

    controller.setState({
      ORRequestSequence: {
        ...detail,
        orLocationOptions: locations,
        operatingRoomOptions: rooms,
      },
    });
  } else if (
    ["CONFIRM", "CANCEL_CONFIRM", "EDIT_REQUEST", "CANCEL", "REQUEST"].includes(
      params.action
    )
  ) {
    if (
      !state.ORRequestSequence?.modConfirm &&
      ["CANCEL_CONFIRM", "CANCEL"].includes(params.action)
    ) {
      return controller.setState({
        ORRequestSequence: {
          ...state.ORRequestSequence,
          modConfirm: params,
        },
      });
    }

    HandleSave(controller, params);
  } else if (params.action === "PRINT") {
    HandlePrint(controller, params);
  }
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
const GetOperatingRoomOptions: Handler = async (controller, params) => {
  const result = await OperatingRoomList.list({
    apiToken: controller.apiToken,
    params: {
      location: params.location,
      // surgeon: params.surgeon,
    },
    extra: {
      division: controller.data.division,
    },
  });

  return mapOptions(result[0]?.items || [], "id", "room_no");
};

const UpdatePatientAppointmentUpdate: Handler = async (controller, params) => {
  return await PatientAppointmentUpdate.patch({
    pk: params.pk,
    apiToken: controller.apiToken,
    data: params.data as any,
  });
};

/* ------------------------------------------------------ */

/*                      HandleAction                     */

/* ------------------------------------------------------ */
const HandleEdit: Handler = async (controller, params) => {
  const state = controller.getState();

  const detail: any = state.ORRequestSequence || {};

  const split = params.name?.split(".") || [];
  const lastKey = split.slice(-1)?.[0] || "";

  if (["is_one_day_case", "is_opd_case", "is_ipd_case"].includes(lastKey)) {
    const caseValue = detail?.case || {};

    caseValue.is_ipd_case = false;
    caseValue.is_one_day_case = false;
    caseValue.is_opd_case = false;

    if (lastKey !== "is_ipd_case") {
      caseValue.ipd_day = "";
      caseValue.ipd_case = "";
    }

    detail.case = caseValue;
  } else if (lastKey === "is_app_admit") {
    const extra: any = detail?.extra || {};
    const is_ipd_patient = detail?.is_ipd_patient || false;
    let date = "";

    extra.nullMaxDate = params.value || is_ipd_patient;

    if (params.value) {
      date = formatDate(moment().add(1, "days"));
      extra.minDate = moment().add(1, "days");
      extra.maxDate = moment().add(1, "years");
    } else {
      date = formatDate(moment());
      extra.minDate = moment();
      extra.maxDate =
        extra.nullMaxDate || is_ipd_patient
          ? moment().add(1, "years")
          : moment().add(1, "days");
    }

    detail.case.app_admit_date = date;
    detail.extra = extra;
  } else if (lastKey === "is_request_icu") {
    const caseValue = detail?.case || {};

    if (!params.value) {
      caseValue.icu_day = "";
    }

    detail.case = caseValue;
  } else if (params.name === "is_med_block") {
    if (!params.value) {
      detail.med_block_date = "";
    }
  } else if (params.name === "is_anesthesia") {
    if (!params.value) {
      detail.anesthesia_date = "";
    }
  } else if (params.name === "is_anesthesia") {
    if (!params.value) {
      detail.anesthesia_date = "";
    }
  } else if (params.name === "is_other") {
    if (!params.value) {
      detail.other = "";
      detail.other_date = "";
    }
  } else if (params.name === "location") {
    const team =
      detail?.teams?.find((item: any) => item.is_main === true) || {};

    detail.operating_room = "";

    detail.operatingRoomOptions = await GetOperatingRoomOptions(controller, {
      surgeon: team.chief_surgeon,
      location: params.value,
    });
  }
  // blood request
  else if (["is_prc", "is_ffp", "is_plt", "is_other"].includes(lastKey)) {
    let bloodRequest = detail?.blood_request || {};

    if (!params.value) {
      bloodRequest[lastKey.replace(/is_/g, "")] = "";
    }

    detail.blood_request = bloodRequest;
  } else if (params.name === "blood_request") {
    let bloodRequest = detail?.blood_request || {};

    bloodRequest.is_na = false;
    bloodRequest.is_yes = false;
    bloodRequest.is_yes_ts = false;

    if (params.value === "is_na") {
      bloodRequest = {
        ffp: "",
        is_ffp: false,
        is_other: false,
        is_plt: false,
        is_prc: false,

        other: "",
        plt: "",
        prc: "",
      };

      detail.blood_request = bloodRequest;
    }
  } else if (["is_less_500", "is_more_500"].includes(lastKey)) {
    const antiBloodLoss = detail.anticipated_blood_loss || {};

    antiBloodLoss.is_less_500 = false;
    antiBloodLoss.is_more_500 = false;

    detail.anticipated_blood_loss = antiBloodLoss;
  } else if (params.name.includes("anti_platelet_coagulant")) {
    if (
      lastKey === "value" &&
      [ADM_FIBRINOLYTIC_USED.CUT_DRUG, ADM_FIBRINOLYTIC_USED.NO_DRUG].includes(
        params.value
      )
    ) {
      const antiPlateletCoagulant = detail.anti_platelet_coagulant || {};

      antiPlateletCoagulant.remark = "";
      antiPlateletCoagulant.date = "";

      detail.anti_platelet_coagulant = antiPlateletCoagulant;
    }
  } else if (lastKey === "is_main") {
    if (params.value) {
      detail.teams = detail.teams.map((item: any) => ({
        ...item,
        is_main: false,
      }));
    } else {
      return;
    }
  }

  controller.setState(
    {
      ORRequestSequence: { ...detail },
    },
    () => params.callback()
  );
};

const HandleSave: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.card_action]: "LOADING",
    },
    loadingStatus: {
      ...state.loadingStatus,
      [params.card_action]: true,
    },
  });

  const detail = { ...state.ORRequestSequence };

  const teamIndex = detail?.teams?.findIndex((item: any) => item.is_main);
  const teamName = `team${teamIndex ?? ""}`;

  const teams: any[] = (detail?.teams || [])?.map((item: any) => {
    const assistSurgeonIds = item.assistant_surgeon_items
      .map((item: any) => item?.id)
      .filter(Boolean);

    const formatItems = (items: any[] = [], icdType: string, type: any) => {
      return items
        .map((item: any) => {
          const cloneItem = Object.assign({}, item);
          return {
            ...cloneItem,
            type: type,
            ...formatProcedureData(cloneItem, icdType),
          };
        })
        .filter((item: any) => item[icdType] || `${item[icdType]}_id`);
    };

    return {
      ...item,
      assistant_surgeons: assistSurgeonIds,
      pre_operating_order_item: {
        ...item.pre_operating_order_item,
        procedures: item.pre_operating_order_item.procedures.map(
          (item: any) => {
            const cloneItem = Object.assign({}, item);
            return {
              ...cloneItem,
              type: cloneItem.subType,
              ...formatProcedureData(cloneItem, "icd9cm"),
              active_disease: cloneItem.active_disease || false,
            };
          }
        ),
      },
      pre_principal_diagnosis: formatItems([item.diagnosis[0]], "icd10", 1),
      pre_secondary_diagnosis: formatItems(item.diagnosis.slice(1), "icd10", 2),
      implant: item.implant.join(","),
    };
  });

  const anesthetistTeams: any[] = (detail?.anesthesia_teams || [])?.map(
    (item: any) => {
      const anesthetistIds = item.anesthetist_items
        .map((item: any) => item?.id)
        .filter(Boolean);

      return {
        ...item,
        anesthetists: anesthetistIds,
      };
    }
  );

  // formatData(primaryProcedure, "icd9cm")

  const data = {
    drug_operating_order_id: null,
    operating_order_id: detail?.id || null,
    operating_order: {
      action: params.action,
      ...(detail || {}),
      main_team: teamName,
      cancel_reason: params.cancel_reason,
      estimate_operation_time:
        parseInt(detail?.estimate_operation_hour || "0") * 60 +
        parseInt(detail?.estimate_operation_minute || "0"),
      teams,
      is_appointment: params.is_appointment,
      anesthesia_teams: anesthetistTeams,
      patient_appointment: params.patientAppointmentId,
      user: state?.userTokenize?.employeeName,
      start_time: detail?.start_time || null,
    },
  };

  let msgError: any = null;

  const errors = {
    estimate: {
      error:
        !Number(detail?.estimate_operation_hour) &&
        !Number(detail?.estimate_operation_minute),
      label: "Estimate time for OP",
      msg: "กรุณาระบุ estimate time for op",
    },
    principal: {
      error:
        typeof teamIndex === "number"
          ? !teams[teamIndex]?.pre_principal_diagnosis?.[0]?.icd10
          : false,
      label: "Preoperative diagnosis",
      msg: "กรุณาระบุ preoperative diagnosis",
    },
    ipd_case: {
      error: detail.case?.is_ipd_case ? !detail.case.ipd_case : false,
      label: "IPD Case",
      msg: "กรุณาระบุ ipd case",
    },
    ipd_day: {
      error: detail.case?.is_ipd_case ? !Number(detail?.case.ipd_day) : false,
      label: "นอน รพ.",
      msg: "กรุณาระบุวันนอน รพ.",
    }, 
    admit_date: {
      error: detail.case?.is_ipd_case ? !detail.case.app_admit_date : false,
      label: "นัดหมาย",
      msg: "กรุณาระบุวันที่นัดหมาย",
    },
    anti_platelet: {
      error: !detail.anti_platelet_coagulant?.value,
      label: "ยาที่ต้องหยุดก่อนผ่าตัด",
      msg: "กรุณาระบุ ยาที่ต้องหยุดก่อนผ่าตัด",
    },
  }; 

  const isError = Object.values(errors).some((value) => value.error);

  if (isError) {
    msgError = Object.values(errors).reduce(
      (result: any, value) => ({
        ...result,
        ...(value.error ? { [value.label]: value.msg } : {}),
      }),
      {}
    );
  }

  let result: any[] = [null, null, null];

  if (!msgError) {
    if (
      state.ORRequestSequence?.id &&
      ["CANCEL_CONFIRM", "CANCEL"].includes(params.action)
    ) {
      result = await OperatingOrderDetail.update({
        pk: params.operatingOrderId,
        apiToken: controller.apiToken,
        data: data.operating_order,
        extra: {
          division: controller.data.division,
        },
      });
      // result = await OperatingOrderBundleCreate.post({
      //   apiToken: controller.apiToken,
      //   data,
      //   extra: {
      //     division: controller.data.division,
      //   },
      // });
    } else {
      result = await OperatingOrderBundleCreate.post({
        apiToken: controller.apiToken,
        data,
        extra: {
          division: controller.data.division,
        },
      });
    }
  }

  if (result[1] || msgError) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: result[1] || msgError,
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.card_action]: "ERROR",
      },
      loadingStatus: {
        ...state.loadingStatus,
        [params.card_action]: false,
      },
    });
    params.onError?.();
  } else {
    if (!state.ORRequestSequence?.id) {
      const ansOrder = result[0].operating_order?.other_orders.find(
        (item: any) => item.model === "anesthesiaorder"
      );
      await UpdatePatientAppointmentUpdate(controller, {
        pk: params.patientAppointmentId,
        data: {
          order_dict: {
            [result[0].operating_order.id]: "operatingorder",
            ...(ansOrder ? { [ansOrder.id]: "anesthesiaorder" } : {}),
            ...(result[0].operating_order?.admit_order
              ? { [result[0].operating_order.admit_order]: "admitorder" }
              : {}),
          },
        },
      });
      await SelectAppointment(controller, { ...state.selectedAppointment });
      // controller.handleEvent({
      //   message: "SelectAppointment" as any,
      //   params: state.selectedAppointment,
      // });
    }

    await RefreshAppointment(controller, { ...state.selectedPatient });

    if (params.action === "CANCEL") {
      await SelectAppointment(controller, { ...state.selectedAppointment });
    }

    controller.setState(
      {
        errorMessage: { ...state.errorMessage, [params.card]: null },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.card_action]: "SUCCESS",
        },
        loadingStatus: {
          ...state.loadingStatus,
          [params.card_action]: false,
        },
        selectedOperatingOption: state.ORRequestSequence?.type || "",
        ORRequestSequence: {
          ...state.ORRequestSequence,
          sequenceIndex: "START",
          modConfirm: null,
          // ...(
          //   params?.action === "CONFIRM"?
          //   (result[0]?.operating_order?.status? {status: result[0]?.operating_order.status} : {}) :
          //   params?.action === "CANCEL_CONFIRM"?
          //   (result[0]?.status? {status: result[0]?.status} : {}) :
          //   {}
          // ),
          // ...(params?.action === "CONFIRM"? ({allowed_action: result[0]?.operating_order?.allowed_action}) : {})
        },
        // selectedOrOrder: {
        //   ...state.selectedOrOrder,
        //   ...(
        //     params?.action === "CONFIRM"?
        //     (result[0]?.operating_order?.status? {status: result[0]?.status} : {}) :
        //     params?.action === "CANCEL_CONFIRM"?
        //     (result[0]?.status? {status: result[0]?.status} : {}) :
        //     {}
        //   )
        // },
        userTokenize: null,
      },
      async () => {
        if (
          (state as any).ORQueueSequence?.orOrders &&
          ["CANCEL_CONFIRM", "CANCEL", "CONFIRM"].includes(params.action)
        ) {
          controller.handleEvent({
            message: "RunSequence",
            params: { sequence: "ORQueue", action: "FETCH" },
          });
          params.onSaveSuccess?.();
        }

        await GetMaster(controller, {
          encounterId: params.encounterId,
          operatingOrderId: params.operatingOrderId,
          isNewEncounter: params.isNewEncounter,
          patientId: params?.patientId,
        });
        params.onCreated?.();
      }
    );
  }
};

const HandlePrint: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.card_action]: "LOADING",
    },
  });

  const result = await OperatingOrderPrintDetail.retrieve({
    apiToken: controller.apiToken,
    pk: params.operatingOrderId,
    extra: {
      division: controller.data.division,
      device: controller.data.device,
    },
  });

  if (result[1]) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: result[1],
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.card_action]: "ERROR",
      },
    });
  } else {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: null },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.card_action]: "SUCCESS",
      },
    });
  }
};

export const mapOptions = (list: any[], valueKey = "id", textKey = "name") => {
  return list?.map((item: any) => ({
    key: item.id,
    value: item[valueKey],
    text: item[textKey],
  }));
};

// ========================= Appointment Event for Async ================================
const RefreshAppointment: Handler = async (controller, params) => {
  const state = controller.getState();
  // console.log('RefreshAppointment state.selectedPatient: ', state.selectedPatient);
  const appointment = await PatientAppointmentView.list({
    params: {
      ...(state.selectedPatient && { patient_id: state.selectedPatient?.id }),
      ...(params?.id && { patient_id: params?.id }),
      exclude_cancel: true,
    },
    apiToken: controller.apiToken,
  });
  console.log(appointment[1] ? appointment[1] : appointment[0]);

  if (appointment[1] && params?.card) {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: appointment[1] },
    });
  }

  const items = appointment[0].items.map((item: any) => ({
    ...item,
  }));
  controller.setState({
    appointmentList: items,
  });
};

const SelectAppointment: Handler = async (controller, params) => {
  const state = controller.getState();

  const [appointment, medRecord] = await Promise.all([
    PatientAppointmentUpdate.retrieve({
      pk: params.id,
      apiToken: controller.apiToken,
    }),

    EncounterMedicalRecordList.list({
      pk: params.order_encounter,
      extra: {
        division: controller.data.division,
      },
      apiToken: controller.apiToken,
    }),
  ]);

  if (appointment[1]) {
    if (params.card) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: appointment[1] },
      });
    }
  }

  console.log("medRecord", medRecord);
  console.log("appointment", appointment);

  let date = params.display_info?.start_datetime?.split("T")?.[0] || "";
  let start =
    params.display_info?.start_datetime?.split("T")?.[1]?.substring(0, 5) || "";
  let end =
    params.display_info?.end_datetime?.split("T")?.[1]?.substring(0, 5) || "";
  let datetime = `${date} ${start}-${end}`;

  // console.log("params", params)

  const [response, error] = await PatientDetailView.retrieve({
    pk: appointment[0]?.patient,
    apiToken: controller.apiToken,
  });

  controller.setState({
    selectedAppointment: {
      ...(params || {}),
      ...appointment[0],
      date: params.estimated_at?.split(" ")?.[0] || "",
      patient_name: `${params.patient_first_name} ${params.patient_last_name}`,
      detail: `${params.division_name || ""} (${
        params.display_info?.provider_name || ""
      } ${datetime || ""})`,
      datetime,
    },
    ...(!params?.isSave && { selectedBlock: null }),
    // selectedBlock: params?.isSave ? : null,
    selectedEncounter: {
      id: params.order_encounter,
      patient_gender_name: response?.gender_name,
    },
    preOrderList: appointment[0]?.orders || [],
    selectedEmr: medRecord[0]?.items?.[0],
  });

  // const [r, e, n] = await DivisionServiceBlockView.list({
  //   params: {
  //     divisions: [controller.data.division]
  //   },
  //   apiToken: controller.apiToken
  // });
  // if (e) return console.log("Error",
};
