import WasmController from "react-lib/frameworks/WasmController";
import DrugOrderDetailForAction from "issara-sdk/apis/DrugOrderDetailForAction_apps_TPD";
import DrugReturnRequestList from "issara-sdk/apis/DrugReturnRequestList_apps_TPD";
import DrugReturnRequestDetail from "issara-sdk/apis/DrugReturnRequestDetail_apps_TPD";
import DrugReturnRequestActionLogList from "issara-sdk/apis/DrugReturnRequestActionLogList_apps_TPD";

export type State = {
  errorMessage?: any;
  ReturnOrderSequence?: {
    sequenceIndex?: string | null;
  } | null;
  OrderReturnList?: any;
  DrugReturnList?:{
        code?: string | null;
        drug_order_item?: number | null;
        id?: number | null;
        name?: string | null;
        no?: number | null;
        quantity_discard?: number | null;
        quantity_issue?: number | null;
        quantity_left?: number | null;
        quantity_request?: number | null;
        quantity_restock?: number | null;
        reason?: string | null;
        stock_unit_name?: string | null;
      }[] | null;
  OrderHistory?: any;
  OrderReturnLog?:any;
};

export const StateInitial: State = {
  ReturnOrderSequence: null,
  OrderReturnList: [],
  DrugReturnList: null,
  OrderHistory: [],
  OrderReturnLog:[]
};

export type Event =
  | { message: "RunSequence"; params: {} }
  // | { messsage: "SelectDrugOrderWorking"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.ReturnOrderSequence) return;
  if (!params?.action) return;
  if (params.action === "RETURN_DRUG") {

    const drugOrder = await DrugOrderDetailForAction.retrieve({
      pk: params.id,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    const orderHistory = await DrugReturnRequestList.list({
      params: { order: params.id },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });


    const drugOrderReturn = (drugOrder?.[0]?.items || [])?.map((item:any) =>({
      ...item,
      code:item.code,
      id:item.id,
      name:item.name,
      no:item.no,
      quantity_discard: 0,
      quantity_issue: item.quantity,
      quantity_left: item.left_quantity,
      quantity_request: null,
      quantity_restock: 0,
      reason: "",
      stock_unit_name: item.stock_unit_name,

    })
    )

    controller.setState({
      OrderReturnList: drugOrder?.[0],
      OrderHistory: orderHistory?.[0].items,
      DrugReturnList: drugOrderReturn,
      ReturnOrderSequence: {
        sequenceIndex: "Edit",
      },
    });

  }
};

export const Edit: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.ReturnOrderSequence) return;
  if (!params?.action) return;
  if (params?.action === "Edit") {
    const data = params.data;
    let items = state.DrugReturnList|| [];

    const ItemsEdit = (item: any) => {
      items = items.map((acc: any, index: number) =>
        index === data.index ? { ...acc, ...item } : acc
      );
    };
    if (params.data.type === "quantity_request") {
      ItemsEdit({ quantity_request: data.value });
    }

    controller.setState({
      OrderReturnList: {
        ...state.OrderReturnList,
        items: items,
      },
      DrugReturnList:items
    });

    
  } else if (params.action === "Request") {
    let drug: any = state.DrugReturnList;

    let defaultData = (drug|| [])?.map((item:any) =>({
      code:item.code,
      drug_order_item:item.id,
      name:item.name,
      no:item.no,
      quantity_discard: 0,
      quantity_issue: item.quantity_issue ,
      quantity_left: item.quantity_left,
      quantity_request: item.quantity_request || 0,
      quantity_restock: 0,
      storekey: 0,
      stock_unit_name: item.stock_unit_name,

    })
    )
    

    const drugReturnRequest = await DrugReturnRequestList.create({
      data: {
        action: "REQUEST",
        items: defaultData,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    
    const orderHistory = await DrugReturnRequestList.list({
      params: { order: drugReturnRequest?.[0].order},
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    controller.setState({ OrderHistory: orderHistory?.[0].items });

  } else if (params?.action === "HistoryRequest") {
    const order = await DrugReturnRequestDetail.retrieve({
      pk:params?.orders?.pk,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      }

    })
    
    controller.setState({
      OrderReturnList:order?.[0],
      DrugReturnList:order?.[0]?.items
    })
    
  }
  else if (params?.action === "New"){
    const drugOrder = await DrugOrderDetailForAction.retrieve({
      pk: params.id,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });
    const drugOrderReturn = (drugOrder?.[0]?.items || [])?.map((item:any) =>({
      ...item,
      code:item.code,
      id:item.id,
      name:item.name,
      no:item.no,
      quantity_discard: 0,
      quantity_issue: item.quantity,
      quantity_left: item.left_quantity,
      quantity_request: null,
      quantity_restock: 0,
      reason: "",
      stock_unit_name: item.stock_unit_name,

    })
    )

    controller.setState({
      OrderReturnList: drugOrder[0],
      DrugReturnList: drugOrderReturn
      
    });
  }
  else if (params?.action === "Log"){

      const orderLog = await DrugReturnRequestActionLogList.list({
      pk: params.id,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    controller.setState({
      OrderReturnLog:orderLog?.[0]
    })
  }
  else if (params?.action === "Update"){
    console.log("ID",params);
    
    const orderUpdate = await DrugReturnRequestDetail.update({
      pk:params.id,
      data:{
        action:"EDIT",
        items:state.DrugReturnList,
        pk:params.id
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    })
    
    controller.setState({
      OrderReturnList:orderUpdate?.[0],
      DrugReturnList:orderUpdate?.[0]?.items
    })


  }
};
