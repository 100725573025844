import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";

import { Button, Form, Icon, Input, Label, Radio, Segment } from "semantic-ui-react";

const style = {
  paddingTop: { paddingTop: "20px" },
};

const PhysicalOrganExam = React.memo(
  forwardRef((props, ref) => {
    const [status, setStatus] = useState("X");
    const [description, setDescription] = useState("");

    useImperativeHandle(ref, () => ({
      getData: () => {
        return {
          organ_id: props.data.organ_id,
          status: status,
          description: description,
        };
      },
    }));

    useEffect(() => {
      if (props.data.status) {
        setStatus(props.data.status);
      }
    }, [props.data.status]);

    useEffect(() => {
      if (props.data.description) {
        setDescription(props.data.description);
      }
    }, [props.data.description]);

    const handleStatus = (e, data) => {
      setStatus(data.value);
    };

    const handleDescription = (e, data) => {
      setDescription(data.value);
    };

    const handleStatusDescription = (e, data) => {
      setStatus(data.value);
      setDescription("");
    };

    return (
      <Form.Group inline>
        <Form.Field width={2}>
          <label>{props.data.organ_name}</label>
        </Form.Field>

        <Form.Field width={1}>
          <Radio
            readOnly={props.isReadOnly}
            value="X"
            checked={status === "X"}
            label="N/A"
            onChange={handleStatusDescription}
          />
        </Form.Field>
        <Form.Field width={1}>
          <Radio
            readOnly={props.isReadOnly}
            value="N"
            checked={status === "N"}
            label="Normal"
            onChange={handleStatus}
          />
        </Form.Field>
        <Form.Field width={2}>
          <Radio
            readOnly={props.isReadOnly}
            value="A"
            checked={status === "A"}
            label="Abnormal"
            onChange={handleStatus}
          />
        </Form.Field>

        <Form.Field width={6}>
          <Input
            readOnly={props.isReadOnly}
            disabled={status === "X"}
            value={description}
            onChange={handleDescription}
          />
        </Form.Field>
      </Form.Group>
    );
  })
);

const SubPhysicalExamination = forwardRef((props, ref) => {
  const physicalExamRef = React.useRef({});
  const [physicalExamData, setPhysicalExamData] = useState({ items: [] });

  const { titleCard, isReadOnly } = props;

  useImperativeHandle(ref, () => ({
    getSaveData: () => {
      let data = [];
      for (let key in physicalExamRef.current) {
        data.push(physicalExamRef.current[key].getData());
      }
      return { items: data };
    },
    setData: (data) => {
      setPhysicalExamData(data);
    },
  }));

  return (
    <Segment raised secondary>
      <Label color="grey" ribbon size="big">
        {titleCard}
      </Label>

      <Form style={style.paddingTop}>
        {physicalExamData.items.map((data, index) => {
          const content = (
            <PhysicalOrganExam
              ref={(el) => (physicalExamRef.current[data.organ_id] = el)}
              key={data.organ_id}
              data={data}
              isReadOnly={isReadOnly}
            />
          );
          return content;
        })}

        <Form.Group inline>
          <Form.Field width={16}>
            <Button
              color="green"
              type="button"
              onClick={props.onSaveAllCallback}
            >
              {props.saveText ? <Icon className="check"></Icon> : "บันทึก"}
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  );
});

SubPhysicalExamination.defaultProps = {
  titleCard: "Physical Examination",

  onSaveAllCallback: () => {},

  isReadOnly: false,
};

SubPhysicalExamination.propTypes = {
  onSaveAllCallback: PropTypes.func,

  isReadOnly: PropTypes.bool,
};

export default React.memo(SubPhysicalExamination);
