import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input,
  Radio,
  Checkbox,
  Dropdown
} from 'semantic-ui-react'
import {
  DateTextBox,
  TimeFreeTextBox24
} from 'react-lib/apps/common'

const ANES_ANESRecord_01 = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", padding:"10px"}}>
        <div>
          
          <div
            style={{ gridRow: "1/2", gridColumn: "1/3",marginLeft:"10px",marginTop:"10px", display: "flex", justifyContent: "space-between", marginBottom: "10px", alingItem: "center" }}>
            
            <div
              style={{ display: "flex" ,fontWeight:"Bold",fontSize:"18px",marginBottom:"10px"}}>
              Anesthetic Assessment Record
            </div>
            <div
              style={{display: "flex" }}>
              
              <div>
                {props.buttonLog}
              </div>
              <div
                style={{display: "none"}}>
                {props.buttonPrint}
              </div>
              <div
                style={{ display: props.AnesRecordStatus === "CONFIRMED" ?  "none" : "",}}>
                {props.buttonSave}
              </div>
              <div
                style={{ display: props.AnesRecordStatus === "CONFIRMED" ?  "none" : "",}}>
                {props.buttonConfirm}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",height:"50%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(7, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/6",marginTop:"10px"}}>
              <div>
                
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  Date
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "13/17",marginTop:"10px"}}>
              <div>
                
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  OR Location
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "24/28",marginTop:"10px"}}>
              <div>
                
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  Patient location
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "28/40",marginTop:"10px"}}>
              <div
                style={{ width:"100%", height:"100%" , display: "flex" , alignItems:"center"}}>
                
                <Radio
                  checked={props.AnesRecordSequence?.PatientLocation === "OPD"}
                  label="OPD"
                  name="PatientLocation"
                  onChange={props.onChangeData}
                  style={{"flex-grow":"1"}}
                  value="OPD">
                </Radio>
                <Radio
                  checked={props.AnesRecordSequence?.PatientLocation === "Ward"}
                  label="Ward"
                  name="PatientLocation"
                  onChange={props.onChangeData}
                  style={{"flex-grow":"1"}}
                  value="Ward">
                </Radio>
                <Input
                  disabled={props.AnesRecordSequence?.PatientLocation !== "Ward"}
                  name="IsWardRemark"
                  onChange={props.onChangeData}
                  style={{ display: "none", "flex-grow": "2","width":"25%"}}
                  value={props.AnesRecordSequence?.PatientLocation === "Ward" ? props.AnesRecordSequence?.IsWardRemark : ""}>
                </Input>
                <Dropdown
                  clearable={true}
                  disabled={props.AnesRecordSequence?.PatientLocation !== "Ward"}
                  name="IsWardRemark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.divisionWard}
                  search={true}
                  selection={true}
                  style={{"flex-grow": "2","width":"25%"}}
                  value={props.AnesRecordSequence?.PatientLocation === "Ward" ? props.AnesRecordSequence?.IsWardRemark : ""}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "1/6",paddingTop:"5px"}}>
              <div>
                
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  Time : Start Anes.
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "6/10",paddingTop:"5px"}}>
              <div
                style={{ display: "flex" ,height:"100%",width:"100%" }}>
                
                <TimeFreeTextBox24
                  className="time-custom-react-picker"
                  onChange={props.onChangeStartAnesTime}
                  value={props.AnesRecordSequence?.StartAnesTime}>
                </TimeFreeTextBox24>
              </div>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "14/17",paddingTop:"5px"}}>
              <div>
                
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  Start Op.
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "21/24",paddingTop:"5px"}}>
              <div>
                
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  End Anes.
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "24/28",paddingTop:"5px"}}>
              <div
                style={{ display: "flex" ,height:"100%",width:"100%" }}>
                
                <TimeFreeTextBox24
                  className="time-custom-react-picker"
                  onChange={props.onChangeEndAnesTime}
                  value={props.AnesRecordSequence?.EndAnesTime}>
                </TimeFreeTextBox24>
              </div>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "32/35",paddingTop:"5px"}}>
              <div>
                
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  PACU duration
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "35/39",paddingTop:"5px"}}>
              <div
                style={{ display: "flex" }}>
                
                <Input
                  name="PACUDurartion"
                  onChange={props.onChangeData}
                  style={{"width":"100%"}}
                  value={props.AnesRecordSequence?.PACUDurartion}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "1/5",paddingTop:"5px"}}>
              <div>
                
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  Anesthesia team
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "6/13",paddingTop:"5px"}}>
              <div
                style={{ display: "flex",height:"100%" }}>
                
                <Input
                  name="AnesthesiaTeam"
                  onChange={props.onChangeData}
                  style={{height:"100%",width:"100%"}}
                  value={props.AnesRecordSequence?.AnesthesiaTeam}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "14/19", paddingTop:"5px" }}>
              <div>
                
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  Preoperative diagnosis
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "19/27", paddingTop:"5px"}}>
              <div
                style={{ display: "flex" ,height:"100%",width:"100%"}}>
                
                <Input
                  name="PreoperativeDiagnosis"
                  onChange={props.onChangeData}
                  readOnly={true}
                  style={{height:"100%",width:"100%"}}
                  value={props.AnesRecordSequence?.PreoperativeDiagnosis}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "28/33",paddingTop:"5px"}}>
              <div>
                
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  Postoperative diagnosis
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "33/40",paddingTop:"5px"}}>
              <div
                style={{ display: "flex",width:"100%",height:"100%"}}>
                
                <Input
                  name="PostoperativeDiagnosis"
                  onChange={props.onChangeData}
                  readOnly={true}
                  style={{height:"100%",width:"100%"}}
                  value={props.AnesRecordSequence?.PostoperativeDiagnosis}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "1/5",paddingTop:"5px"}}>
              <div>
                
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  Surgical team
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "6/13",paddingTop:"5px"}}>
              <div
                style={{ display: "flex",height:"100%",width:"100%"}}>
                
                <Input
                  name="SurgicalTeam"
                  onChange={props.onChangeData}
                  style={{height:"100%",width:"100%"}}
                  value={props.AnesRecordSequence?.SurgicalTeam}>
                </Input>
              </div>
            </div>
            <div
              style={{"gridRow":"4/5","gridColumn":"14/19",}}>
              <div>
                
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  Proposed operation
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "19/27",paddingTop:"5px"}}>
              <div
                style={{ display: "flex",height:"100%",width:"100%"}}>
                
                <Input
                  name="ProposedOperation"
                  onChange={props.onChangeData}
                  readOnly={true}
                  style={{height:"100%",width:"100%"}}
                  value={props.AnesRecordSequence?.ProposedOperation}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "28/33",paddingTop:"5px"}}>
              <div>
                
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  Performed operation
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "33/40",paddingTop:"5px"}}>
              <div
                style={{ display: "flex",height:"100%",width:"100%"}}>
                
                <Input
                  name="PerformedOperation"
                  onChange={props.onChangeData}
                  readOnly={true}
                  style={{height:"100%",width:"100%"}}
                  value={props.AnesRecordSequence?.PerformedOperation}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "6/11",marginTop:"10px"}}>
              <div
                style={{"display":"flex"}}>
                
                <DateTextBox
                  onChange={props.onChangeDateForm}
                  value={props.AnesRecordSequence?.DateRamrk}>
                </DateTextBox>
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "17/23",marginTop:"10px"}}>
              <div
                style={{"display":"flex"}}>
                
                <Input
                  name="ORRmark"
                  onChange={props.onChangeData}
                  style={{"width":"100%"}}
                  value={props.AnesRecordSequence?.ORRmark}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "17/21",paddingTop:"5px"}}>
              <div
                style={{ display: "flex" ,height:"100%",width:"100%" }}>
                
                <TimeFreeTextBox24
                  className="time-custom-react-picker"
                  onChange={props.onChangeStartOpTime}
                  value={props.AnesRecordSequence?.StartOpTime}>
                </TimeFreeTextBox24>
              </div>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "39/40",paddingTop:"5px"}}>
              <div>
                
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center",marginLeft:"5px"}}>
                  min
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "28/40", display: "none" }}>
              
              <div>
                {props.buttonLog}
              </div>
              <div>
                {props.buttonPrint}
              </div>
              <div>
                {props.buttonSave}
              </div>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "28/31",paddingTop:"5px"}}>
              
              <div
                style={{ width:"100%", height:"100%" , display: "flex" , alignItems:"center"}}>
                
                <Checkbox
                  checked={props.AnesRecordSequence?.endAfterClinic}
                  label="นอกเวลาราชการ"
                  name="endAfterClinic"
                  onChange={props.onChangeData}>
                </Checkbox>
              </div>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "10/13",paddingTop:"5px"}}>
              
              <div
                style={{ width:"100%", height:"100%" , display: "flex" , alignItems:"center"}}>
                
                <Checkbox
                  checked={props.AnesRecordSequence?.startAfterClinic}
                  label="นอกเวลาราชการ"
                  name="startAfterClinic"
                  onChange={props.onChangeData}>
                </Checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}


export default ANES_ANESRecord_01

export const screenPropsDefault = {}

/* Date Time : Wed May 10 2023 10:14:10 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",height:\"50%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/3\",marginLeft:\"10px\",marginTop:\"10px\", display: \"flex\", justifyContent: \"space-between\", marginBottom: \"10px\", alingItem: \"center\" }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthetic Assessment Record"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,fontWeight:\"Bold\",fontSize:\"18px\",marginBottom:\"10px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(7, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/6\",marginTop:\"10px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "Date"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"13/17\",marginTop:\"10px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "OR Location"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"24/28\",marginTop:\"10px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient location"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"28/40\",marginTop:\"10px\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width:\"100%\", height:\"100%\" , display: \"flex\" , alignItems:\"center\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/6\",paddingTop:\"5px\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"6/10\",paddingTop:\"5px\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"14/17\",paddingTop:\"5px\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"21/24\",paddingTop:\"5px\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"24/28\",paddingTop:\"5px\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"32/35\",paddingTop:\"5px\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"35/39\",paddingTop:\"5px\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "label",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": "Time : Start Anes."
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"100%\",width:\"100%\" }"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "label",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "Start Op."
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "End Anes."
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "PACU duration"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/5\",paddingTop:\"5px\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"6/13\",paddingTop:\"5px\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"14/19\", paddingTop:\"5px\" }"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"19/27\", paddingTop:\"5px\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "label",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesia team"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",height:\"100%\" }"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "label",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "Preoperative diagnosis"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "Input",
      "parent": 91,
      "props": {
        "name": {
          "type": "value",
          "value": "PreoperativeDiagnosis"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PreoperativeDiagnosis"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"28/33\",paddingTop:\"5px\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"33/40\",paddingTop:\"5px\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "label",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "Postoperative diagnosis"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",width:\"100%\",height:\"100%\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "Input",
      "parent": 103,
      "props": {
        "name": {
          "type": "value",
          "value": "PostoperativeDiagnosis"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PostoperativeDiagnosis"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/5\",paddingTop:\"5px\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"6/13\",paddingTop:\"5px\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"4/5\",\"gridColumn\":\"14/19\",}"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"19/27\",paddingTop:\"5px\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"28/33\",paddingTop:\"5px\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"33/40\",paddingTop:\"5px\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "label",
      "parent": 111,
      "props": {
        "children": {
          "type": "value",
          "value": "Surgical team"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Input",
      "parent": 113,
      "props": {
        "name": {
          "type": "value",
          "value": "SurgicalTeam"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.SurgicalTeam"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 107,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "label",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": "Proposed operation"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "Input",
      "parent": 117,
      "props": {
        "name": {
          "type": "value",
          "value": "ProposedOperation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.ProposedOperation"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "label",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "Performed operation"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 110,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "Input",
      "parent": 121,
      "props": {
        "name": {
          "type": "value",
          "value": "PerformedOperation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PerformedOperation"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"6/11\",marginTop:\"10px\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"display\":\"flex\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"17/23\",marginTop:\"10px\"}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "div",
      "parent": 157,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"display\":\"flex\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "Input",
      "parent": 158,
      "props": {
        "name": {
          "type": "value",
          "value": "ORRmark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.ORRmark"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "Radio",
      "parent": 29,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PatientLocation === \"OPD\""
        },
        "label": {
          "type": "value",
          "value": "OPD"
        },
        "name": {
          "type": "value",
          "value": "PatientLocation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{\"flex-grow\":\"1\"}"
        },
        "value": {
          "type": "value",
          "value": "OPD"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "Radio",
      "parent": 29,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PatientLocation === \"Ward\""
        },
        "label": {
          "type": "value",
          "value": "Ward"
        },
        "name": {
          "type": "value",
          "value": "PatientLocation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{\"flex-grow\":\"1\"}"
        },
        "value": {
          "type": "value",
          "value": "Ward"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "Input",
      "parent": 29,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PatientLocation !== \"Ward\""
        },
        "name": {
          "type": "value",
          "value": "IsWardRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\", \"flex-grow\": \"2\",\"width\":\"25%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PatientLocation === \"Ward\" ? props.AnesRecordSequence?.IsWardRemark : \"\""
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"17/21\",paddingTop:\"5px\"}"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"100%\",width:\"100%\" }"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"100%\",width:\"100%\" }"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "Input",
      "parent": 168,
      "props": {
        "name": {
          "type": "value",
          "value": "PACUDurartion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PACUDurartion"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"39/40\",paddingTop:\"5px\"}"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 170,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "label",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": "min"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\",marginLeft:\"5px\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 173,
      "name": "DateTextBox",
      "parent": 155,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDateForm"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.DateRamrk"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 174,
      "name": "TimeFreeTextBox24",
      "parent": 58,
      "props": {
        "className": {
          "type": "value",
          "value": "time-custom-react-picker"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartAnesTime"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.StartAnesTime"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 176,
      "name": "TimeFreeTextBox24",
      "parent": 164,
      "props": {
        "className": {
          "type": "value",
          "value": "time-custom-react-picker"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartOpTime"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.StartOpTime"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 177,
      "name": "TimeFreeTextBox24",
      "parent": 166,
      "props": {
        "className": {
          "type": "value",
          "value": "time-custom-react-picker"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndAnesTime"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.EndAnesTime"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"28/40\", display: \"none\" }"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "div",
      "parent": 178,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonLog"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "div",
      "parent": 178,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "div",
      "parent": 178,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\" }"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 182,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonLog"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "div",
      "parent": 182,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "div",
      "parent": 182,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.AnesRecordStatus === \"CONFIRMED\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 186,
      "name": "div",
      "parent": 182,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.AnesRecordStatus === \"CONFIRMED\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 188,
      "name": "Input",
      "parent": 87,
      "props": {
        "name": {
          "type": "value",
          "value": "AnesthesiaTeam"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.AnesthesiaTeam"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"28/31\",paddingTop:\"5px\"}"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"10/13\",paddingTop:\"5px\"}"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "div",
      "parent": 190,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width:\"100%\", height:\"100%\" , display: \"flex\" , alignItems:\"center\"}"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "div",
      "parent": 189,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width:\"100%\", height:\"100%\" , display: \"flex\" , alignItems:\"center\"}"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 193,
      "name": "Checkbox",
      "parent": 192,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.endAfterClinic"
        },
        "label": {
          "type": "value",
          "value": "นอกเวลาราชการ"
        },
        "name": {
          "type": "value",
          "value": "endAfterClinic"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 194,
      "name": "Checkbox",
      "parent": 191,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesRecordSequence?.startAfterClinic"
        },
        "label": {
          "type": "value",
          "value": "นอกเวลาราชการ"
        },
        "name": {
          "type": "value",
          "value": "startAfterClinic"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "toggle": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 195,
      "name": "Dropdown",
      "parent": 29,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PatientLocation !== \"Ward\""
        },
        "name": {
          "type": "value",
          "value": "IsWardRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.divisionWard"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{\"flex-grow\": \"2\",\"width\":\"25%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.AnesRecordSequence?.PatientLocation === \"Ward\" ? props.AnesRecordSequence?.IsWardRemark : \"\""
        }
      },
      "seq": 195,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_ANESRecord_01",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
