/* eslint-disable no-undef */
import javascriptBarcodeReader from "javascript-barcode-reader";

declare global {
  var cv: any;
  var MarvinImage: any;
  var Marvin: any
}

const decodeBarcodeMat = async (img: any) => {
  const canvasDebug = document.createElement("canvas");
  canvasDebug.width = img.width;
  canvasDebug.height = img.height;
  cv.imshow(canvasDebug, img);

  // console.log("debugMat canvasDebug", canvasDebug)
  let ctx: any = canvasDebug.getContext("2d");
  let imgDataCrop = ctx.getImageData(0, 0, canvasDebug.width, canvasDebug.height);

  let code = ""
  try {
    code = await javascriptBarcodeReader({
      /* Image file Path || {data: Uint8ClampedArray, width, height} || HTML5 Canvas ImageData */
      image: imgDataCrop,
      barcode: "code-128",
      // barcodeType: 'industrial',
      options: {
        // useAdaptiveThreshold: true
        // singlePass: true
      },
    });
  } catch (error) {
    console.error("error", error);
  }
  return code;
};

const chooseStringfromArray = (stringList: string[]) => {
  if (stringList?.length === 0) {
    return "Unknown";
  }
  console.log(" stringList", stringList);
  const accuOccur = (acc: any, curr: any) => {
    if (typeof acc[curr] == "undefined") {
      acc[curr] = 1;
    } else {
      acc[curr] += 1;
    }
    return acc;
  };

  const accuOccurWithoutQuestionMark = (acc: any, curr: any) => {
    if (typeof acc[curr] == "undefined") {
      acc[curr] = 1;
    } else {
      if (curr !== "?") {
        acc[curr] += 1;
      }
    }
    return acc;
  };

  /// Use major Length on array
  let totalDigitList = stringList.map((item) => item.length);
  var statLength: any = totalDigitList.reduce(accuOccur, {});
  let arrayMax = Object.keys(statLength).filter((key) => statLength[key] === Math.max(...(Object.values(statLength) as any[])));
  let arrayMaxNumber = arrayMax.map((item) => Number(item));
  let dominateLength = 0;
  if (arrayMaxNumber.length > 1) {
    dominateLength = Math.max(...arrayMaxNumber);
  } else {
    dominateLength = Number(arrayMaxNumber[0]);
  }

  // console.log("dominatedLength", dominateLength, arrayMaxNumber)
  let filterList = stringList.filter((item) => item.length === dominateLength);
  // console.log("filterList", filterList)
  let decode = "";
  for (let digit = 0; digit < dominateLength; digit++) {
    let characterList: any[] = [];
    filterList.forEach((codeword: any) => {
      characterList.push(codeword[digit]);
    });

    // console.log("characterList", characterList)
    let statCharacter = characterList.reduce(accuOccurWithoutQuestionMark, {});
    // console.log("statCharacter", statCharacter)
    let dominateChar = Object.keys(statCharacter).find((key) => statCharacter[key] === Math.max(...(Object.values(statCharacter) as any[])));
    // console.log(" dominateChar", dominateChar)
    decode = decode.concat(dominateChar || "");
  }

  // console.log("chooseStringfromArray Decode ", decode)

  return decode;
};

const enhanceBarcode = async (src: any) => {
  // console.log("enhanceBarcode")
  let gray = new cv.Mat();
  let gau = new cv.Mat();

  // Restore
  let reduce_h = new cv.Mat();
  let ero_b = new cv.Mat();

  await debugMat(src, "Original");

  cv.cvtColor(src, gau, cv.COLOR_RGBA2GRAY, 0);

  // Sharpen
  // let ksize = new cv.Size(3, 3);
  // cv.GaussianBlur(gray, gau, ksize, 0, 0, cv.BORDER_DEFAULT);
  // await debugMat(gau, "Gau")
  // cv.addWeighted(gray, 1.5, gau, -0.5, 0.0, gau, -1);
  // await debugMat(gau, "AddWeight")

  // Restore 1 Erosion
  let kernel_p = cv.Mat.ones(5, 1, cv.CV_8U);
  let anchor_p = new cv.Point(-1, -1);
  cv.erode(gau, ero_b, kernel_p, anchor_p, 1, cv.BORDER_CONSTANT, cv.morphologyDefaultBorderValue());
  await debugMat(ero_b, "ero_b Checkpoint ");

  let top = 0;
  let bottom = ero_b.rows;

  let lookingBarcode = true;

  let barcode_v = new cv.Mat();
  cv.reduce(ero_b, barcode_v, 1, cv.REDUCE_AVG);

  for (let row = 0; row < ero_b.rows; row++) {
    let avg = barcode_v.ucharAt(row, 0);

    // console.log("row", row, "avg", avg )
    if (lookingBarcode) {
      // console.log("Check")
      if (avg < 230 && top === 0) {
        // console.log("Set Top")
        top = row;
      } else if (top !== 0 && bottom === ero_b.rows) {
        if (avg > 230) {
          // console.log("Set Bottom")
          lookingBarcode = false;
          bottom = row;
          break;
        }
      }
    }
  }

  barcode_v.delete();

  // console.log("top ", top, "bottom", bottom)

  let rect = new cv.Rect(0, top, ero_b.cols, bottom - top);
  ero_b = ero_b.roi(rect);

  // Restore 2 Reduce *** key
  cv.reduce(ero_b, reduce_h, 0, cv.REDUCE_AVG);
  await debugMat(reduce_h, "reduce_h Checkpoint ");

  var i;
  var thrs;
  var matchStringList: any[] = [];
  var dominateStringList: any[] = [];
  var decodedString = "Unknown";

  for (thrs = 110; thrs < 230; thrs = thrs + 1) {
    let s = new cv.Scalar(0, 0, 0);
    let trial = new cv.Mat(100, reduce_h.cols, cv.CV_8U, s);
    trial.cols = reduce_h.cols;
    trial.rows = 100;

    for (i = 0; i < reduce_h.cols; i++) {
      // console.log ( "Loop reduce_h.ucharAt(0, i)" ,reduce_h.ucharAt(0, i))
      if (reduce_h.ucharAt(0, i) > thrs) {
        cv.line(trial, new cv.Point(i, 0), new cv.Point(i, trial.rows), new cv.Scalar(255, 255, 255), 1);
        // console.log(" reduce_h.ucharAt(0, i)", i , reduce_h.ucharAt(0, i), reduced_h_graph.rows)
      }
    }
    let code = await decodeBarcodeMat(trial);
    if (code) {
      let count = (code.match(/\?/g) || []).length;
      if (count === 0) {
        matchStringList.push(code);
        // console.log("decodeBarcodeMat 0 (thrs) : ", thrs.toString(), "code: " , code)
      }
      if (count <= 1) {
        // May change to one
        // console.log("decodeBarcodeMat ? (thrs) : ", thrs.toString(), "code: " , code)
        dominateStringList.push(code);
      }
    }
    // await debugMat(trial, "trial Checkpoint " + thrs.toString())
    trial.delete();
  }

  // chooseString
  if (matchStringList.length > 0) {
    decodedString = chooseStringfromArray(matchStringList);
  } else if (dominateStringList.length > 0) {
    console.log("dominateStringList", dominateStringList);
    // dominate Case
    if (dominateStringList[0].includes("C") || dominateStringList[0].includes("H")) {
      //H^54012246$C^042.001$VD^20170429
      // Process HN (H^)
      let hnList: any[] = [];
      let documentList: any[] = [];
      let visitDateList: any[] = [];
      dominateStringList.forEach((element) => {
        let start = [element.indexOf("H") + 2, element.indexOf("^") + 1];
        let end = [element.indexOf("$"), element.indexOf("C") - 1, element.indexOf("^", element.indexOf("^") + 1) - 2];
        start.forEach((startIndex) => {
          end.forEach((endIndex) => {
            let prepare = element.slice(startIndex, endIndex);
            if (prepare) {
              hnList.push(prepare);
            }
          });
        });
      });
      console.log("hnList", hnList);
      let dedicatehn = chooseStringfromArray(hnList);
      // console.log(" dedicatehn", dedicatehn)

      // Process Document
      dominateStringList.forEach((element) => {
        let start = [element.indexOf("$") + 3, element.indexOf("C") + 2, element.indexOf("^", element.indexOf("^") + 1) + 1];
        let end = [element.lastIndexOf("$"), element.lastIndexOf("V") - 1, element.lastIndexOf("D") - 2, element.lastIndexOf("^") - 3];
        start.forEach((startIndex) => {
          end.forEach((endIndex) => {
            let prepare = element.slice(startIndex, endIndex);
            if (prepare) {
              documentList.push(prepare);
            }
          });
        });
      });

      console.log("documentList", documentList);
      let dedicateDocument = chooseStringfromArray(documentList);
      // console.log('dedicateDocument: ', dedicateDocument);

      // Process Visit Date
      dominateStringList.forEach((element) => {
        let start = [element.lastIndexOf("$") + 4, element.lastIndexOf("V") + 3, element.lastIndexOf("D") + 2, element.lastIndexOf("^") + 1];
        start.forEach((startIndex) => {
          let prepare = element.slice(startIndex);
          if (prepare) {
            visitDateList.push(prepare);
          }
        });
      });

      console.log("documentList", visitDateList);
      let dedicateVD = chooseStringfromArray(visitDateList);
      // console.log('dedicateVD: ', dedicateVD);

      decodedString = "H^" + dedicatehn + "$C^" + dedicateDocument + "$VD^" + dedicateVD;

      // decodedString = chooseStringfromArray(dominateStringList)
    } else {
      decodedString = chooseStringfromArray(dominateStringList);
    }
  }

  gray.delete();
  gau.delete();
  reduce_h.delete();
  ero_b.delete();

  // console.log("decodedString !!! ", decodedString)
  return decodedString;
};

const boundingRectBarcode = (segments: any, image: any, margin: any, minHeight: any) => {
  let boundingRect: any[] = [];
  for (let i in segments) {
    const seg = segments[i];
    // Skip segments that are too small
    if (seg.height >= 40) {
      let { x1, y1, x2, y2 } = seg;
      // image.drawRect(x1 - 5, y1 - 5, seg.width, seg.height + 10, 0xFFFF0000);
      // image.drawRect(x1 - 5, y1 - 4, seg.width - 2, seg.height + 8, 0xFFFF0000);
      if (y1 < minHeight) {
        continue;
      }
      x1 -= margin * 2;
      y1 -= margin;
      // x2 += margin
      y2 += margin;

      boundingRect.push([x1, y1, x2 - x1, y2 - y1]);
    }
    // TODO: Didn't use algo to check is Barcode , use last 3 pic
    boundingRect = boundingRect.slice(-3);
  }

  return boundingRect;
};

export const debugMat = async (img: any, title: string = "Unknown") => {
  // Write Mat to Canvas
  const canvasDebug = document.createElement("canvas");
  canvasDebug.width = img.cols;
  canvasDebug.height = img.rows;
  const brElement = document.createElement("br");
  cv.imshow(canvasDebug, img);

  // Read Canvas for Find Barcode

  // console.log("debugMat canvasDebug", canvasDebug)
  let ctx = canvasDebug.getContext("2d");
  let imgDataCrop: any = ctx?.getImageData(0, 0, canvasDebug.width, canvasDebug.height);
  // console.log("debugMat imgDataCrop", )

  let code = "";
  try {
    code = await javascriptBarcodeReader({
      /* Image file Path || {data: Uint8ClampedArray, width, height} || HTML5 Canvas ImageData */
      image: imgDataCrop,
      barcode: "code-128",
      // barcodeType: 'industrial',
      options: {
        // useAdaptiveThreshold: true
        // singlePass: true
      },
    });
    console.log("code", code);
  } catch (error) {
    console.error("error", error);
  }

  // let target = document.getElementById("tongDiv")
  // target.appendChild(canvasDebug)
  // target.appendChild(document.createTextNode( code + " : " + title))
  // target.appendChild(brElement)
};

export const detectingBarcode = async (dataURI: string) => {


  //1 uri -> base64image 

  const marvinOriginalImage = new MarvinImage();
  let image_url = "";
  if (dataURI.includes("/9j/4AAQ")) {
    image_url = `data:${"image/jpg"};base64,${dataURI}`
  } else if (dataURI.includes("iVBORw0KGg")) {
    image_url = `data:${"image/png"};base64,${dataURI}`
  } else {
    image_url = dataURI
  }

  // Base64 -> marwinImage 
  // marwinImage รับ uri เลย 
  return new Promise((resolve, reject) => {
    marvinOriginalImage.load(image_url, async () => {
      // Make it crop correct
      const maxWhiteSpace = Math.round(marvinOriginalImage.width / 80);
      const maxFontLineWidth = Math.round(marvinOriginalImage.height / 50);
      const minTextWidth = Math.round(marvinOriginalImage.width / 10);
      const grayScaleThreshold = 180;
      const marginBound = Math.round(maxWhiteSpace / 4);
      const minHeight = Math.round(marvinOriginalImage.height / 13);
      // console.log(" maxWhiteSpace", maxWhiteSpace, "maxFontLineWidth",maxFontLineWidth, "minTextWidth", minTextWidth)
      const segment = Marvin.findTextRegions(marvinOriginalImage, maxWhiteSpace, maxFontLineWidth, minTextWidth, grayScaleThreshold);
      const boundingRectList = boundingRectBarcode(segment, marvinOriginalImage, marginBound, minHeight);

      // console.log("boundingRectList ", boundingRectList)
      const codeData: any[] = [];
      // const contureImage = marvinOriginalImage.clone()

      // Draw section
      for (const crop of boundingRectList.reverse()) {
        // Draw red rectangle
        // contureImage.drawRect(crop[0], crop[1], crop[2], crop[3], 0xFFFF0000);

        console.log("crop ", crop);

        // TO show where is crop
        const cropImage = new MarvinImage(1, 1);
        Marvin.crop(marvinOriginalImage, cropImage, crop[0], crop[1], crop[2], crop[3]);

        // draw crop section
        const canvasCrop = document.createElement("canvas");
        canvasCrop.width = cropImage.width;
        canvasCrop.height = cropImage.height;
        cropImage.draw(canvasCrop); // Draw !! canva

        // To OpenCV
        let ctx: any = canvasCrop.getContext("2d");
        let imgDataCrop = ctx.getImageData(0, 0, canvasCrop.width, canvasCrop.height);
        let imgCVMat = cv.matFromImageData(imgDataCrop);

        // console.log("imgCVMat", imgCVMat)
        // let ori = cv.imread(canvasCrop)

        // Enhance
        let code = await enhanceBarcode(imgCVMat);

        if (code !== "Unknown") {
          codeData.push(code);
        }
      }
      console.log(" codeData", codeData);
      resolve(codeData)
    });
  })
};
