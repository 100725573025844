import WasmController from "react-lib/frameworks/WasmController";
// interface
import * as TPDI from "../TPDInterface";
import * as ModPlanningI from "../../common/ModPlanningInterface";

// apis
import AdverseReactionCheck from "issara-sdk/apis/AdverseReactionCheck_apps_ADR";
import DrugDetail from "issara-sdk/apis/DrugDetail_apps_TPD";
import DrugInteractionCheck from "issara-sdk/apis/DrugInteractionCheck_apps_ADR";
import DrugLabInteractionCheck from "issara-sdk/apis/DrugLabInteractionCheck_apps_ADR";
import UserPermissionView from "issara-sdk/apis/UserPermissionView_users";
import DrugDiseaseCheck from "issara-sdk/apis/CheckDrugDisease_apps_PHR";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";

// card id
import { CARD_DRUG_CONT_ID } from "../CardDrugContinuePlan";

export const DRUG_INTERACTION_IN = {
  ORDER: 1,
  TRIAGE: 2
}

export type State = {
  // CommonInterface
  masterOptions?: any; // Obtained from event GetMasterData
  drugDose?: string | number;
  currentDoctor?: any;
  // permission
  drugPermission?: any;

  // triage
  drugTriage?: any;

  // selected
  selectedEncounter?: any;
  selectedPatient?: any;
  selectedEmr?: any;
  selectedDrug?: any | null;

  // search
  drugSearchText?: string;

  // order
  drugOrder?: any;

  // seq
  DrugSelectSequence?: {
    sequenceIndex: string | null;
    id?: number;
    selectedDrug?: any | null;

    // ADR
    adrType?: string | null;
    note_adverse_reaction?: string | null;
    adrNotes?: string | null;

    // DI
    diFeedback?: boolean;
    diLock?: boolean;
    diItems?: any[];
    confirmDINote?: string | null;
    confirmDLINote?: string | null;
    confirmDDNote?: string | null
    isTriageDrug?: boolean | null;

    // Master
    drugUnits?: any[];
    drugRoutes?: any[];
    drugSites?: any[];
    drugFrequencies?: any[];
    drugMethods?: any[];
    divisionList?: any[];
    standardAdminTimeOptions?: any[];

    selectedDrugUnitId?: any | null;
    selectedDrugRouteId?: any | null;
    selectedDrugSiteId?: any | null;
    selectedDrugFrequencyId?: any | null;
    selectedDrugMethodId?: any | null;

    claim_payload?: any;

    // Drug cont
    admin_time?: any[];
    dose?: string;
    extra_note?: string;
    frequency?: string;
    method?: string;
    route?: string;
    site?: string;
    standard_admin_time?: string;
    unit?: string;
    calculated_daily_quantity?: string;
    prn?: string;
    sending_interval?: string;
    sending_quantity?: string;
    stock_unit_name?: string;
    // solvent
    is_intravaneous?: boolean;
    default_solvent?: number | null;
    default_solvent_quantity?: number | null;
    default_iv_rate?: number | null;
    solvent?: number | null;
    solvent_product?: number | null;
    solvent_quantity?: number | null;
    strength?: number | null;
    strength_unit?: number | null;
    quantity?: number | null;
    volume?: number | null;
    drug_concentration_unit?: number | null;
    drug_drip_in?: number | null;
    drug_drip_in_unit?: number | null;
    // iv_rate?: number | null;
    // iv_rate_unit?: number | null;
    mixture_solvent_volume?: number | null;
    mixture_iv_rate?: number | null;
    mixture_iv_rate_unit?: any;
    mixture_concentration_solute?: any;
    mixture_concentration_solvent?: any;
    old_quantity?: number | null;
    old_rate?: number | null;

    // action log
    actionLogs?: { open?: boolean; data?: any };
  } | null;

  // drug lab interaction modal
  modDrugLabInteraction?: {
    open?: boolean;
    interactionItems?: any[];
    orderItem?: any;
    note?: string;
  };

  // drug disease interaction modal
  modDrugDisease?: {
    open?: boolean;
    diseaseItems?: any[];
    orderItem?: any;
    note?: string;
  };

  // drug interaction modal
  modDrugInteraction?: {
    open?: boolean;
    drugItems?: any[];
    orderItem?: any;
    note?: string;
  }
};

export const StateInitial: State = {
  // CommonInterface
  masterOptions: {}, // Obtained from event GetMasterData

  // permission
  drugPermission: null,

  // triage lastest drug
  drugTriage: [],

  // seq
  DrugSelectSequence: {
    sequenceIndex: "",
    selectedDrug: null,

    // ADR
    adrType: null,
    note_adverse_reaction: null,
    adrNotes: null,

    // DI
    diFeedback: false,
    diLock: false,
    diItems: [],
    confirmDINote: null,

    // Master
    drugUnits: [],
    drugRoutes: [],
    drugSites: [],
    drugFrequencies: [],
    drugMethods: [],
    standardAdminTimeOptions: [],

    selectedDrugUnitId: null,
    selectedDrugRouteId: null,
    selectedDrugSiteId: null,
    selectedDrugFrequencyId: null,
    selectedDrugMethodId: null,

    claim_payload: null,

    // drug cont
    admin_time: [],
    dose: "",
    extra_note: "",
    frequency: "",
    method: "",
    route: "",
    site: "",
    standard_admin_time: "",
    unit: "",
    calculated_daily_quantity: "",
    prn: "",
    sending_quantity: "",
    sending_interval: "",
    stock_unit_name: "",
    // solvent
    is_intravaneous: false,
    default_solvent: null,
    default_solvent_quantity: null,
    default_iv_rate: null,
    solvent: null,
    solvent_product: null,
    solvent_quantity: null,
    strength: null,
    strength_unit: null,
    quantity: null,
    volume: null,
    drug_concentration_unit: null,
    drug_drip_in: null,
    drug_drip_in_unit: null,
    // iv_rate: null,
    // iv_rate_unit: null,
    mixture_solvent_volume: null,
    mixture_iv_rate: null,
    mixture_iv_rate_unit: null,
    mixture_concentration_solute: null,
    mixture_concentration_solvent: null,
    old_quantity: null,
    old_rate: null,

    // action log
    actionLogs: { open: false, data: {} },
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any }
  | { message: "ClearDrugOrderForm"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  let state = controller.getState();
  if (!state.DrugSelectSequence) return;

  // Fetch master data, then transform into state.masterOptions[xxx]
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["unit", {}],
        ["route", {}],
        ["site", {}],
        ["frequency", {}],
        ["method", {}],
        ["duplicateReasons", {}],
        ["conversion", {}],
        ["standardAdminTime", {}],
        ["shippingEmployee", {}],
        ["divisionForOrderDiv", {}]
      ],
    },
  });

  const getPermission = UserPermissionView.post({
    apiToken: controller.apiToken,
    data: {
      config_TPD_ENABLE_IV_COMPATIBLE: false,
      config_TPD_PRESCRIPTION_FREQUENCY_OTHER_CODE: "OTH",
      action_TPD_DrugContinuePlan_REQUEST: false,
      action_TPD_DrugContinuePlan_EDIT: false,
      action_TPD_DrugContinuePlan_EDIT_QUANTITY: false,
      action_TPD_DrugContinuePlan_CANCEL: false,
      action_TPD_DrugContinuePlan_REJECT: false,
      action_TPD_DrugContinuePlan_VERIFY: false,
      role_DOCTOR: false,
      role_PHARMACIST: false,
      config_CLM_ENABLE_CLAIM_DRUG_FRONTEND_V2: false,
    },
  });

  const [permission] = await Promise.all([
    getPermission,
  ]);

  if (params?.card === CARD_DRUG_CONT_ID) {
    await controller.handleEvent({ message: "GetDrugContinuePlan" as any, params: {} });
  }

  const lastesTriage = await FormDataLatest.retrieve({
    apiToken: controller.apiToken,
    params: {
      encounter: state.selectedEncounter?.id,
      form_code: "CardPreAssessment",
      form_version: "1.0"
    }
  })

  const orderBy = await TPDI.getDefaultOrderBy(controller as any, params);

  state = controller.getState();

  controller.setState({
    drugPermission: permission[0],
    DrugSelectSequence: {
      ...state.DrugSelectSequence,
      sequenceIndex: "CheckADR",
      divisionList: [],
    },
    drugOrder: {
      ...state.drugOrder,
      order_div:
        state.selectedEncounter?.division ||
        (state.drugOrder.order_div === "1"
          ? controller.data.division
          : state.drugOrder.order_div),
      ...orderBy,
    },
    drugTriage: [...(lastesTriage[0]?.data?.medication?.present_drugs || [])]
  });
};

export const CheckADR: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.DrugSelectSequence) return;
  if (!params.selectedDrug) return;

  ModPlanningI.handleSetDefaultPlanningData(controller as any);

  const adr = await AdverseReactionCheck.get({
    patient: state.selectedEncounter.patient_id || params.patient,
    adversary: params.selectedDrug.id,
    params: {
      encounter: state.selectedEncounter.id,
      emr: state.selectedEmr.id,
      progression_cycle: null,
    },
    apiToken: controller.apiToken,
  });
  const adrToProcess =
    !adr[1] && ["NOTED", "PAUSED", "LOCKED"].includes(adr[0].status);
  // Will pass through if fetch error or adr not in the above list of statuses
  controller.setState(
    {
      DrugSelectSequence: {
        divisionList: state.DrugSelectSequence.divisionList,
        selectedDrug: params.selectedDrug,
        sequenceIndex: adrToProcess ? "FeedbackADR" : "CheckDI",
        adrType: adrToProcess ? adr[0].status : null,
        adrNotes: adr[0]?.notes,
      },
    },
    () => {
      // Go right to next sequence if there is no adr to process
      !adrToProcess &&
        controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const FeedbackADR: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.DrugSelectSequence) return;
  if (["cancel", "acknowledge"].includes(params?.feedback || "")) {
    controller.handleEvent({ message: "ClearDrugOrderForm", params: {} });
    controller.setState({
      DrugSelectSequence: null,
    });
    return;
  }

  controller.setState(
    {
      DrugSelectSequence: {
        ...state?.DrugSelectSequence,
        sequenceIndex: "CheckDI", // To go to step 2
        adrType: null, // To turn of ModCheckADR
      },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const CheckDI: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.DrugSelectSequence) return;
  const di = await DrugInteractionCheck.list({
    params: {
      drugs: [state.DrugSelectSequence.selectedDrug.id]
        .concat((state.drugOrder?.items || []).map((item: any) => item.product))
        .join(","),
      patient: (state.selectedPatient?.id)
    },
    apiToken: controller.apiToken,
  });
  // If no items or error go through to GetDrugDetail else go to FeedbackDI
  const items = (di[0]?.items || []).filter((item: any) =>
    item.members.includes(state.DrugSelectSequence?.selectedDrug.id)
  );
  const diFeedback = !di[1] && items.length > 0;
  console.log(items);
  controller.setState(
    {
      DrugSelectSequence: {
        ...state?.DrugSelectSequence,
        sequenceIndex: diFeedback ? "FeedbackDI" : "CheckDrugLabInteraction", // To go to step 3
        diItems: items,
        // diFeedback: diFeedback,
        diLock: items.filter((item: any) => item.is_lock).length > 0,
      },
      modDrugInteraction: {
        ...state.modDrugLabInteraction,
        open: diFeedback,
        drugItems: items
      }
    },
    () => {
      !diFeedback &&
        controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const FeedbackDI: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.DrugSelectSequence) return;
  if (["cancel", "acknowledge"].includes(params?.feedback || "")) {
    controller.handleEvent({ message: "ClearDrugOrderForm", params: {} });
    controller.setState({
      DrugSelectSequence: null,
      modDrugInteraction: {
        open: false,
        drugItems: [],
        orderItem: {},
        note: ""
      }
    });
    return;
  }

  let drugInteractionData: any = []
  state.modDrugInteraction?.drugItems?.forEach((drugI: any) => {
    drugInteractionData = drugInteractionData.concat(drugI.members)
  })

  let isTriageData = state.drugTriage?.some((drug: any) => {
    return drugInteractionData.includes(drug.id)
  })
  controller.setState(
    {
      drugOrder: {
        ...state.drugOrder,
        items: [...(state.drugOrder.items || [])].map((item: any) => drugInteractionData.includes(item.product) ?
          { ...item, note_drug_interaction: params?.note } : { ...item })
      },
      DrugSelectSequence: {
        ...state?.DrugSelectSequence,
        sequenceIndex: "CheckDrugLabInteraction", // To go to step 2
        // diFeedback: false, // To turn of ModCheckADR
        confirmDINote: params?.note,
        isTriageDrug: isTriageData
      },
      modDrugInteraction: {
        open: false,
        drugItems: [],
        orderItem: {},
        note: ""
      }

    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const CheckDrugLabInteraction: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.DrugSelectSequence) return;
  const dli = await DrugLabInteractionCheck.get({
    apiToken: controller.apiToken,
    params: {
      drugs: state.DrugSelectSequence.selectedDrug.id,
      patient: (state.selectedPatient as any)?.id
    },
    extra: {
      division: controller.data?.division,
      device: (controller.data as any)?.device
    }
  });
  const items = (dli[0]?.items || [])
  const dliFeedBack = !dli[1] && items.length > 0;
  // console.log(items);
  controller.setState(
    {
      DrugSelectSequence: {
        ...state?.DrugSelectSequence,
        sequenceIndex: dliFeedBack ? "FeedbackDrugLabInteraction" : "CheckDrugDisease", // To go to step 4
      },
      modDrugLabInteraction: {
        ...state.modDrugLabInteraction,
        open: dliFeedBack,
        interactionItems: items
      }
    },
    () => {
      !dliFeedBack &&
        controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const FeedbackDrugLabInteraction: Handler = (controller, params) => {
  const state = controller.getState();
  if (!state.DrugSelectSequence) return;
  if (params?.action === "cancel" || !state.modDrugLabInteraction?.note) {
    controller.handleEvent({ message: "ClearDrugOrderForm", params: {} });
    controller.setState({
      DrugSelectSequence: null,
      modDrugLabInteraction: {
        open: false,
        interactionItems: [],
        orderItem: {},
        note: ""
      }
    });
    return;
  }
  controller.setState(
    {
      DrugSelectSequence: {
        ...state?.DrugSelectSequence,
        sequenceIndex: "CheckDrugDisease",
        confirmDLINote: params?.note
      },
      modDrugLabInteraction: {
        open: false,
        interactionItems: [],
        orderItem: {},
        note: ""
      }
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
}

export const CheckDrugDisease: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.DrugSelectSequence) return;
  const dli = await DrugDiseaseCheck.get({
    apiToken: controller.apiToken,
    params: {
      drugs: state.DrugSelectSequence.selectedDrug.id,
      patient: (state.selectedPatient as any)?.id
    },
    extra: {
      division: controller.data?.division,
      device: (controller.data as any)?.device
    }
  });
  const items = (dli[0]?.items || [])
  const ddFeedBack = !dli[1] && items.length > 0;
  // console.log(items);
  controller.setState(
    {
      DrugSelectSequence: {
        ...state?.DrugSelectSequence,
        sequenceIndex: ddFeedBack ? "FeedbackDrugDisease" : "GetDrugDetail", // To go to step 4
      },
      modDrugDisease: {
        ...state.modDrugDisease,
        open: ddFeedBack,
        diseaseItems: items
      }
    },
    () => {
      !ddFeedBack &&
        controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const FeedbackDrugDisease: Handler = (controller, params) => {
  const state = controller.getState();
  if (!state.DrugSelectSequence) return;
  if (params?.action === "cancel" || !state.modDrugDisease?.note) {
    controller.handleEvent({ message: "ClearDrugOrderForm", params: {} });
    controller.setState({
      DrugSelectSequence: null,
      modDrugDisease: {
        open: false,
        diseaseItems: [],
        orderItem: {},
        note: ""
      }
    });
    return;
  }
  controller.setState(
    {
      DrugSelectSequence: {
        ...state?.DrugSelectSequence,
        sequenceIndex: "GetDrugDetail",
        confirmDDNote: params?.note
      },
      modDrugDisease: {
        open: false,
        diseaseItems: [],
        orderItem: {},
        note: ""
      }
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
}

export const GetDrugDetail: Handler = async (controller, params) => {
  let state = controller.getState();

  if (!state.DrugSelectSequence) {
    return;
  }

  const [r, e, _n] = await DrugDetail.retrieve({
    pk: state.DrugSelectSequence.selectedDrug.id,
    apiToken: controller.apiToken,
  });
  if (e) {
    return console.log(`Error: ${e.toString()}`);
  }

  // Filter master options for selected drug
  await controller.handleEvent({
    message: "SetDrugContinueOptions",
    params: { drugResp: r },
  } as any);

  state = controller.getState();

  console.log("r ---", r);

  controller.setState(
    {
      drugSearchText: state.DrugSelectSequence?.selectedDrug?.full_name,
      selectedDrug: {
        ...state.DrugSelectSequence?.selectedDrug,
        note_adverse_reaction: state.DrugSelectSequence?.note_adverse_reaction,
        note_drug_interaction: state.DrugSelectSequence?.confirmDINote,
        note_drug_lab_interaction: state.DrugSelectSequence?.confirmDLINote,
        note_drug_disease: state.DrugSelectSequence?.confirmDDNote,
        drug_interaction_in: state.DrugSelectSequence?.isTriageDrug ? "TRIAGE" : "ORDER",
        product_name: state.DrugSelectSequence?.selectedDrug?.full_name,
        factsheet: !r.factsheet || r.factsheet === "" ? null : r.factsheet,
        original: r,
      },
      DrugSelectSequence: {
        ...state.DrugSelectSequence,
        ...r,
        // seq
        sequenceIndex: "CheckADR",
        // drug data
        selectedDrugFrequencyId: r.default_frequency,
        selectedDrugMethodId: r.default_method,
        selectedDrugRouteId: r.default_route,
        selectedDrugSiteId: r.default_site,
        selectedDrugUnitId: r.default_dosage_unit,
        // drug cont
        id: null,
        dose: "",
        frequency: r.default_frequency,
        method: r.default_method,
        route: r.default_route,
        site: r.default_site,
        unit: r.default_dosage_unit,
      },
      drugDose: Number(r.default_unit_dose)
        ? r.default_unit_dose.toString()
        : "",
    },
    () => {
      if (params?.card === CARD_DRUG_CONT_ID) {
        TPDI.PreviewDrugContinuePlan(controller as any, params);
        TPDI.ClaimDrugContinuePlan(controller as any, params);
      } else {
        controller.handleEvent({ message: "CheckShowButtonSolvent" } as any);
      }
    }
  );
};
