import PropTypes from "prop-types";
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Header,
  Button,
  Divider,
  Input,
  Form,
  Icon,
  Modal,
  Message,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import _ from "react-lib/compat/lodashplus";

import CardLayout from "react-lib/apps/common/CardLayout";
import SubICDDetail from "./SubICDDetail";
import CardTreatHistory from "./CardTreatHistory";
import CardICD10Template from "./CardICD10Template";

var pdSelected: any = null;
var sdSelected: any[] = [];
const CardICD10 = forwardRef((props, ref) => {
  const isMounted = useRef(true);
  const [principalPK, setPrincipalPK] = useState(null);
  const [principal, setPrincipal] = useState(DEFAULT_SUB_ICD10);
  const [secondaries, setSecondaries] = useState(props.secondaryList);
  const [openTreatHistory, setOpenTreatHistory] = useState(false);
  const [openICD10Template, setOpenICD10Template] = useState(false);
  const [elementKey, setElementKey] = useState(0);
  const [principalError, setPrincipalError] = useState(null);
  const [secondaryError, setSecondaryError] = useState(null);
  const [diagnosis, setDiagnosis] = useState(null);
  const [alreadySave, setAlreadySave] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [completed, setCompleted] = useState(false);

  /** Secondary */
  const initialSecondary = (secList:any) => {
    var list = [];
    sdSelected = [];
    for (var i = 0; i < secList.length; i++) {
      var sDiagnosis = secList[i];
      list.push({
        ...DEFAULT_SUB_ICD10_MODEL,
        key: elementKey + i,
        selectData: sDiagnosis,
      });
      // For data save
      sdSelected.push({
        key: elementKey + i,
        selectData: {
          pk: sDiagnosis.pk,
          emr: sDiagnosis.emr,
          type: DIAGNOSIS_TYPE_SECONDARY,
          medical_description: sDiagnosis.medical_description,
          icd10: sDiagnosis.icd10,
          icd10_med_term: sDiagnosis.icd10_med_term,
          detail: sDiagnosis.detail,
          active_disease: sDiagnosis.active_disease,
        },
      });
    }
    setSecondaries(list);
    setElementKey(elementKey + secList.length);
  };

  const handleAddSecondary = () => {
    // For data save
    sdSelected.push({
      ...DEFAULT_DIAGNOSIS_MODEL,
      key: elementKey,
    });

    var newSec = {
      ...DEFAULT_SUB_ICD10_MODEL,
      key: elementKey,
    };
    setSecondaries([...secondaries, newSec]);
    setElementKey(elementKey + 1);
  };

  const handleRemoveSecondary = (key:any) => {
    var pos = null;
    for (var i = 0; i < secondaries.length; i++) {
      if (secondaries[i].key === key) {
        pos = i;
        break;
      }
    }

    if (pos != null) {
      sdSelected.splice(pos, 1);
      secondaries.splice(pos, 1);
      setSecondaries([...secondaries]);
    }
  };

  const handleSecondaryErased = (key:any) => {
    // For data save
    for (var sd of sdSelected) {
      if (sd.key === key) {
        sd.selectData = null;
      }
    }
  };

  const handleSecondarySelected = (key:any, data:any) => {
    // For data save
    for (var sd of sdSelected) {
      if (sd.key === key) {
        var temp = DEFAULT_DIAGNOSIS;
        if (sd.selectData) {
          var temp = sd.selectData;
        }
        sd.selectData = {
          ...temp,
          emr: props.emrId,
          type: DIAGNOSIS_TYPE_SECONDARY,
          medical_description: data.medterm,
          icd10: data.icd10_id,
          detail: data.detail,
          active_disease: data.active_disease,
        };
        break;
      }
    }
  };

  const handleSecondaryChecked = (key:any) => (checked:any) => {
    for (var sd of sdSelected) {
      if (sd.key === key) {
        var temp = DEFAULT_DIAGNOSIS;
        if (sd.selectData) {
          var temp = sd.selectData;
        }
        sd.selectData = {
          ...temp,
          active_disease: checked,
        };
        break;
      }
    }

    // console.log("handleSecondaryChecked sdSelected", sdSelected)
  };

  const handleSecondaryDetail = (key:any, data:any) => {
    for (var sd of sdSelected) {
      if (sd.key === key) {
        var temp = DEFAULT_DIAGNOSIS;
        if (sd.selectData) {
          var temp = sd.selectData;
        }
        sd.selectData = {
          ...temp,
          detail: data,
        };
        break;
      }
    }
  };

  /**
   * Update secondary after select data
   * @param {*} diagnosisList
   * @param {*} subICDList
   */
  const updateSecondary = (diagnosisList: any, subICDList: any) => {
    if (diagnosisList.length === 0) {
      return;
    }

    // Clear list before add new secondary from treatment history
    var tempList = secondaries;
    var removeList = [];
    for (var s of sdSelected) {
      if (!s.selectData) {
        removeList.push(s.key);
      }
    }
    removeListWithKey(removeList, tempList);
    removeListWithKey(removeList, sdSelected);

    // Update List
    for (var diag of diagnosisList) {
      // For data save
      sdSelected.push(diag);
    }
    for (var subICD of subICDList) {
      // For data display
      tempList.push(subICD);
    }

    // Update state
    setSecondaries(tempList);
  };

  /** Principal */
  const handlePrincipalErased = () => {
    // For data save
    pdSelected = null;
  };

  const handlePrincipalSelected = (data:any) => {
    var temp = DEFAULT_DIAGNOSIS;
    if (pdSelected) {
      temp = pdSelected;
    }
    const selected = {
      ...temp,
      emr: props.emrId,
      type: DIAGNOSIS_TYPE_PRIMARY,
      medical_description: data.medterm,
      icd10: data.icd10_id,
      detail: data.detail,
      active_disease: data.active_disease,
    };
    if (selected) {
      if (selected.pk == null) {
        selected.pk = principalPK;
      }
    }
    // For data save
    pdSelected = selected;
  };

  const handlePrincipalCheck = (checked:any) => {
    var temp = DEFAULT_DIAGNOSIS;
    if (pdSelected) {
      temp = pdSelected;
    }
    const selected = {
      ...temp,
      active_disease: checked,
    };
    if (selected) {
      if (selected.pk == null) {
        selected.pk = principalPK;
      }
    }
    // console.log(" handlePrincipalCheck ", selected)
    // For data save
    pdSelected = selected;
  };

  const handlePrincipalDetail = (data:any) => {
    var temp = DEFAULT_DIAGNOSIS;
    if (pdSelected) {
      temp = pdSelected;
    }
    const selected = {
      ...temp,
      detail: data,
    };
    // For data save
    pdSelected = selected;
  };

  /**
   * Update principal after select data
   * @param {*} diagnosis
   * @param {*} subICD
   */
  const updatePrincipal = (diagnosis:any, subICD:any  ) => {
    if (diagnosis) {
      if (diagnosis.pk == null) {
        diagnosis.pk = principalPK;
      }
    }
    pdSelected = diagnosis;
    // console.log("updatePrincipal diagnosis: ", diagnosis)
    // console.log("updatePrincipal subICD: ", subICD)
    setPrincipal(subICD);
  };

  // Handle 'SAVE' button
  const handleSaveDiagnosis = async () => {
    // console.log("handleSaveDiagnosis sdSelected", sdSelected)
    // console.log("handleSaveDiagnosis pdSelected", pdSelected)
    var secondaryList = [];
    setLoadingSave(true);
    for (var sd of sdSelected) {
      if (sd.selectData && sd.selectData.icd10) {
        secondaryList.push(sd.selectData);
      }
    }
    if (!pdSelected || (pdSelected && pdSelected.icd10 === null)) {
      var errorMsg = ERROR_MESSAGE.no_principal;
      if (secondaryList.length > 0) {
        errorMsg = ERROR_MESSAGE.update_secondary_without_principal;
      }
      setPrincipalError(errorMsg);
      setSecondaryError(null);
      setLoadingSave(false);
    } else {
      if (secondaryList.length != sdSelected.length) {
        setPrincipalError(null);
        setSecondaryError(ERROR_MESSAGE.no_secondary);
        setLoadingSave(false);
      } else {
        setPrincipalError(null);
        setSecondaryError(null);
        // Update diagnosis
        var principalTemp = _.clone(pdSelected);
        if (principalTemp.pk === null) {
          delete principalTemp.pk;
        }
        let result = await props.subICDController.saveDiagnosis(
          props.emrId,
          [principalTemp],
          secondaryList
        );

        if (result) {
          setLoadingSave(false);
          setCompleted(true);
          setTimeout(() => {
            setCompleted(false);
          }, 3000);
          // toast.success("บันทึก Diagnosis สำเร็จ");
          getDiagnosis(props.emrId);
          setAlreadySave(true);
          if (principalPK == null && result.principal_diagnosis.length > 0) {
            setPrincipalPK(result.principal_diagnosis[0].pk);
          }
        } else {
          setLoadingSave(false);
          // toast.error("เกิดความผิดพลาดในการบันทึก Diagnosis");
        }
      }
    }
  };

  /**
   * For TreatHistory Card
   * @param {*} dPrimary
   * @param {*} dSecondaryList
   */
  const handleSelectDiagnosis = (dPrimary:any, dSecondaryList:any) => {
    if (dPrimary) {
      // Update principal
      var temp = DEFAULT_DIAGNOSIS;
      if (pdSelected) {
        temp = pdSelected;
      }
      const diag = {
        ...temp,
        emr: props.emrId,
        type: DIAGNOSIS_TYPE_PRIMARY,
        medical_description: dPrimary.medical_description,
        icd10: dPrimary.icd10,
      };
      updatePrincipal(diag, dPrimary);
    }

    if (dSecondaryList.length > 0) {
      // Update List
      var targetDiag = [];
      var targetSubICD = [];
      for (var i = 0; i < dSecondaryList.length; i++) {
        var sDiagnosis = dSecondaryList[i];
        var isUpdate = true;
        for (var s of secondaries) {
          if (s.selectData.id && s.selectData.id === sDiagnosis.id) {
            isUpdate = false;
            break;
          }
        }

        if (isUpdate) {
          targetSubICD.push({
            ...DEFAULT_SUB_ICD10_MODEL,
            key: elementKey + i,
            selectData: sDiagnosis,
          });
          // For data save
          targetDiag.push({
            key: elementKey + i,
            selectData: {
              emr: props.emrId,
              type: DIAGNOSIS_TYPE_SECONDARY,
              medical_description: sDiagnosis.medical_description,
              icd10: sDiagnosis.icd10,
              icd10_med_term: sDiagnosis.icd10_med_term,
              detail: sDiagnosis.detail,
            },
          });
        }
      }
      updateSecondary(targetDiag, targetSubICD);
      setElementKey(elementKey + dSecondaryList.length);
    }
  };

  /**
   * For ICD10Template Card
   * @param {*} sPrimary
   * @param {*} sSecondaryList
   */
  const handleSelectTemplate = (sPrimary:any, sSecondaryList:any) => {
    if (sPrimary) {
      var temp = pdSelected;
      var diag = {
        ...temp,
        emr: props.emrId,
        type: DIAGNOSIS_TYPE_PRIMARY,
        medical_description: sPrimary.icd10_medical_term,
        icd10: sPrimary.icd10,
        icd10_med_term: sPrimary.icd10_med_term,
        detail: null,
      };
      var subICD = {
        ...DEFAULT_SUB_ICD10,
        medical_description: sPrimary.icd10_medical_term,
        icd_term: sPrimary.icd10_term,
        icd_code: sPrimary.icd10_code,
      };

      updatePrincipal(diag, subICD);
    }

    if (sSecondaryList.length > 0) {
      // Update List
      var targetDiag = [];
      var targetSubICD = [];
      for (var i = 0; i < sSecondaryList.length; i++) {
        var sDiagnosis = sSecondaryList[i];
        targetSubICD.push({
          ...DEFAULT_SUB_ICD10_MODEL,
          key: elementKey + i,
          selectData: {
            medical_description: sDiagnosis.icd10_medical_term,
            icd_term: sDiagnosis.icd10_term,
            icd_code: sDiagnosis.icd10_code,
          },
        });
        // For data save
        targetDiag.push({
          key: elementKey + i,
          selectData: {
            emr: props.emrId,
            type: DIAGNOSIS_TYPE_SECONDARY,
            medical_description: sDiagnosis.icd10_medical_term,
            icd10: sDiagnosis.icd10,
            icd10_med_term: sDiagnosis.icd10_med_term,
            detail: null,
          },
        });
      }
      updateSecondary(targetDiag, targetSubICD);
      setElementKey(elementKey + sSecondaryList.length);
    }
  };

  /**
   * Remove list with key list
   * @param {*} removeKeyList
   * @param {*} dataList
   */
  const removeListWithKey = (removeKeyList:any, dataList:any) => {""
    _.remove(dataList, (value) => {
      for (var key of removeKeyList) {
        if (key === value.key) {
          return true;
        }
      }
      return false;
    });
  };

  // Fetch data
  const getDiagnosis = async (emrId:any) => {
    let diagnosis = await props.subICDController.getDiagnosis(emrId);

    if (isMounted.current) {
      // console.log("diagnoiss", diagnosis)
      if (diagnosis) {
        setDiagnosis(diagnosis);
        if (diagnosis.principal_diagnosis.length > 0) {
          var pDiagnosis = diagnosis.principal_diagnosis[0];
          setPrincipalPK(pDiagnosis.pk);
          setPrincipal(pDiagnosis);
          // For data save
          pdSelected = {
            pk: pDiagnosis.pk,
            emr: pDiagnosis.emr,
            type: DIAGNOSIS_TYPE_PRIMARY,
            medical_description: pDiagnosis.medical_description,
            icd10: pDiagnosis.icd10,
            icd10_med_term: pDiagnosis.icd10_med_term,
            detail: pDiagnosis.detail,
            active_disease: pDiagnosis.active_disease,
          };
        }

        if (diagnosis.secondary_diagnosis.length > 0) {
          initialSecondary(diagnosis.secondary_diagnosis);
        }
      }
    }
  };

  useImperativeHandle(ref, () => ({
    saveDiagnosis() {
      // Save diagnosis
      handleSaveDiagnosis();
    },
    getDiagnosis: () => diagnosis,
    checkSave: () => {
      if (alreadySave) {
        return true;
      }
      if (diagnosis.id) {
        return true;
      }
      return false;
    },
  }));

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getDiagnosis(props.emrId);
  }, [props.emrId]);

  // console.log("Render CardICD10 principal:", principal)
  // console.log("Render CardICD10 secondaries:", secondaries)
  return (
    <CardLayout
      titleText={"Diagnosis"}
      headerColor="blue"
      toggleable={false}
      hideHeaderIcon={true}
      titleContent={
        !props.readonly && (
          <Form>
            <Form.Group inline>
              <Form.Field>
                <Button color="blue" onClick={() => setOpenTreatHistory(true)}>
                  ประวัติการรักษา
                </Button>
              </Form.Field>
              <Form.Field>
                <Input
                  icon={
                    <Icon
                      name="search"
                      link
                      onClick={() => setOpenICD10Template(true)}
                    />
                  }
                />
              </Form.Field>
            </Form.Group>
          </Form>
        )
      }
    >
      {/* Treatment history */}
      <Modal open={openTreatHistory} onClose={() => setOpenTreatHistory(false)}>
        <CardTreatHistory
          onClose={() => setOpenTreatHistory(false)}
          getDivisionList={props.subICDController.getAllDivision}
          getDoctorList={props.subICDController.getAllDoctor}
          onSearchDiagnosis={props.subICDController.searchPatientDiagnosis(
            props.patientId
          )}
          onSelectDiagnosis={handleSelectDiagnosis}
        />
      </Modal>

      {/* ICD10 Template */}
      <Modal
        open={openICD10Template}
        onClose={() => setOpenICD10Template(false)}
      >
        <CardICD10Template
          onClose={() => setOpenICD10Template(false)}
          onSearchTemplate={props.subICDController.searchDiagnosisTemplate}
          onSelectTemplate={handleSelectTemplate}
          manageTemplateUrl={props.manageTemplateUrl}
        />
      </Modal>

      {/* Principal Layout */}
      <Header>Principal</Header>
      <Message error={true} hidden={!principalError}>
        <Message.List items={[principalError]} />
      </Message>
      <SubICDDetail
        controller={props.subICDController}
        isShowDeleteButton={false}
        initialData={principal}
        onSelected={handlePrincipalSelected}
        onChecked={handlePrincipalCheck}
        onDetailChange={handlePrincipalDetail}
        onErased={handlePrincipalErased}
        isReadOnly={props.readonlyPrimary}
      />

      {/* Secondary Layout */}
      {secondaries.length > 0 && (
        <Divider style={{ marginTop: "32px", marginBottom: "32px" }} />
      )}
      {secondaries.length > 0 && <Header>Secondary</Header>}
      {secondaries.length > 0 && (
        <Message error={true} hidden={!secondaryError}>
          <Message.List items={[secondaryError]} />
        </Message>
      )}
      {secondaries.map((row:any, index:any) => {
        return (
          <SubICDDetail
            key={row.key}
            controller={props.subICDController}
            initialData={row.selectData}
            onDelete={handleRemoveSecondary.bind(this, row.key)}
            onSelected={handleSecondarySelected.bind(this, row.key)}
            onChecked={handleSecondaryChecked(row.key)}
            onDetailChange={handleSecondaryDetail.bind(this, row.key)}
            onErased={handleSecondaryErased.bind(this, row.key)}
            isReadOnly={false}
          />
        );
      })}

      {!props.readonly && (
        <Form>
          <Form.Group inline style={{ marginTop: "24px" }}>
            <Form.Field width={3}>
              <Button
                color="yellow"
                fluid
                toggle={true}
                onClick={handleAddSecondary}
              >
                <Icon name="plus" />
                Secondary
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      )}

      {/* Save */}
      {!props.readonly && (
        <Form>
          <Form.Group inline>
            <Form.Field width={13}></Form.Field>
            <Form.Field width={3}>
              <Button
                color="green"
                fluid
                onClick={handleSaveDiagnosis}
                loading={loadingSave}
              >
                {completed ? <Icon name="check"></Icon> : "Save"}
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      )}
    </CardLayout>
  );
});

const DIAGNOSIS_TYPE_PRIMARY = 1;
const DIAGNOSIS_TYPE_SECONDARY = 2;
const DEFAULT_DIAGNOSIS = {
  emr: null,
  type: DIAGNOSIS_TYPE_PRIMARY,
  medical_description: "",
  icd10: null,
  icd10_med_term: null,
  detail: "",
  active_disease: false,
};
const DEFAULT_DIAGNOSIS_MODEL = {
  key: "",
  selectData: null,
};

const DEFAULT_SUB_ICD10 = {
  medical_description: "",
  icd_term: "",
  icd_code: "",
  active_disease: false,
};

const DEFAULT_SUB_ICD10_MODEL = {
  key: "",
  selectData: DEFAULT_SUB_ICD10,
};

const ERROR_MESSAGE = {
  no_principal: "กรุณาระบุ Principal Diagnosis ให้ถูกต้อง",
  no_secondary: "กรุณาระบุ Secondary Diagnosis ให้ถูกต้อง",
  update_secondary_without_principal:
    "ต้องระบุ Principal จึงจะระบุ Secondary ได้",
};

CardICD10.defaultProps = {
  secondaryList: [],
  emrId: -1,
  // getDiagnosis: () => {return null},
  // getDivisionList: () => {return []},
  // getDoctorList: () => {return []},
  onSearchWithICDCode: () => {
    return [];
  },
  onSearchWithICDTerm: () => {
    return [];
  },
  onSearchWithMedTerm: () => {
    return [];
  },
  onSearchSublevelWithICDCode: () => {
    return [];
  },
  // onSave: () => {},
  // onSearchDiagnosis: () => {return []},
  subICDController: null,
  // ICD10 Template Card
  // onSearchTemplate: () => {return []},
  manageTemplateUrl: "",
};

CardICD10.propTypes = {
  secondaryList: PropTypes.array,
  emrId: PropTypes.number,
  getDiagnosis: PropTypes.func,
  getDivisionList: PropTypes.func,
  getDoctorList: PropTypes.func,
  onSave: PropTypes.func,
  onSearchDiagnosis: PropTypes.func,
  subICDController: PropTypes.object,
  // ICD10 Template Card
  onSearchTemplate: PropTypes.func,
  manageTemplateUrl: PropTypes.string,
  readonly: PropTypes.bool,
  readonlyPrimary: PropTypes.bool,
  style: PropTypes.object,
};

export default CardICD10;
