import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input,
  Dropdown
} from 'semantic-ui-react'

const SubAddressUX = (props: any) => {
    return(
      <div
        style={{height: "100%", display: "flex", flexDirection: "column"}}>
        <div
          style={{flex: 3}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(10, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
            <div
              style={{ gridRow: "2/4", gridColumn: "2/7", }}>
              บ้านเลขที่ / Current address
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "8/14", }}>
              <Input
                fluid={true}
                onChange={props.changeAddress("no")}
                value={props.no}>
              </Input>
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "15/20", }}>
              ชื่อหมู่บ้าน/อาคาร / Village name
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "21/32", }}>
              <Input
                fluid={true}
                onChange={props.changeAddress("name")}
                value={props.name}>
              </Input>
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "33/38", }}>
              ประเภทที่พักอาศัย / Village type
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "39/50", }}>
              <Dropdown
                fluid={true}
                onChange={props.changeAddress("type")}
                options={props.homeTypeOptions}
                selection={true}
                value={props.type}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "2/7", }}>
              หมู่ที่ / Village number
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "8/14", }}>
              <Input
                fluid={true}
                onChange={props.changeAddress("town")}
                value={props.town}>
              </Input>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "15/20", }}>
              ซอย / Soi
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "21/32", }}>
              <Input
                fluid={true}
                onChange={props.changeAddress("street")}
                value={props.street}>
              </Input>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "33/38", }}>
              ถนน / Street
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "39/50",}}>
              <Input
                fluid={true}
                onChange={props.changeAddress("road")}
                value={props.road}>
              </Input>
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "2/5", }}>
              จังหวัด / Province
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "5/12", }}>
              <Dropdown
                fluid={true}
                onChange={props.changeAddress("province")}
                options={props.provinceOptions}
                selection={true}
                value={props.province}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "13/17", }}>
              อำเภอ / District
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "17/23", }}>
              <Dropdown
                fluid={true}
                onChange={props.changeAddress("district")}
                options={props.districtOptions}
                selection={true}
                value={props.district}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "24/28", }}>
              ตำบล / Sub-district
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "28/35", }}>
              <Dropdown
                fluid={true}
                onChange={props.changeAddress("city")}
                options={props.cityOptions}
                selection={true}
                value={props.city}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "36/40", }}>
              รหัสไปรษณีย์ / Zip code
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "40/50", }}>
              <Input
                fluid={true}
                onChange={props.changeAddress("zipcode")}
                value={props.zipcode}>
              </Input>
            </div>
          </div>
        </div>
        <div
          style={{flex: 2}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(7, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
            <div
              style={{ gridRow: "2/4", gridColumn: "2/6", }}>
              โทรศัพท์บ้าน / Landline
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "6/13", }}>
              <Input
                fluid={true}
                onChange={props.changeAddress("tel_home")}
                value={props.tel_home}>
              </Input>
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "13/15", }}>
              <Input
                fluid={true}
                onChange={props.changeAddress("tel_home_suffix")}
                value={props.tel_home_suffix}>
              </Input>
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "16/21", }}>
              โทรศัพท์ที่ทำงาน  /  Work phone
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "21/28", }}>
              <Input
                fluid={true}
                onChange={props.changeAddress("tel_office")}
                value={props.tel_office}>
              </Input>
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "28/31", }}>
              <Input
                fluid={true}
                onChange={props.changeAddress("tel_office_suffix")}
                value={props.tel_office_suffix}>
              </Input>
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "32/36", }}>
              โทรศัพท์มือถือ / Mobile phone
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "36/50", }}>
              <Input
                fluid={true}
                onChange={props.changeAddress("tel_mobile")}
                value={props.tel_mobile}>
              </Input>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "2/5", }}>
              E-mail
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "5/15", }}>
              <Input
                fluid={true}
                onChange={props.changeAddress("email")}
                value={props.email}>
              </Input>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "16/22", }}>
              ช่องทางการส่งข้อมูล / Information Channel
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "22/31", }}>
              <Dropdown
                fluid={true}
                onChange={props.changeAddress("channel")}
                options={props.contactOptions}
                selection={true}
                value={props.channel}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "32/36", }}>
              หมายเหตุ  / Note
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "36/50", }}>
              <Input
                fluid={true}
                onChange={props.changeAddress("note")}
                value={props.note}>
              </Input>
            </div>
          </div>
        </div>
      </div>
    )
}

export default SubAddressUX

export const screenPropsDefault = {}

/* Date Time : Sat Apr 16 2022 14:42:53 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{height: \"100%\", display: \"flex\", flexDirection: \"column\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(10, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 4,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(7, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 3}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 2}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "บ้านเลขที่ / Current address"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"2/7\", }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"8/14\", }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อหมู่บ้าน/อาคาร / Village name"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"15/20\", }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"21/32\", }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทที่พักอาศัย / Village type"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"33/38\", }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"39/50\", }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "หมู่ที่ / Village number"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"2/7\", }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"8/14\", }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ซอย / Soi"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"15/20\", }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"21/32\", }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ถนน / Street"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"33/38\", }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"39/50\",}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "จังหวัด / Province"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"2/5\", }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"5/12\", }"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "อำเภอ / District"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"13/17\", }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"17/23\", }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ตำบล / Sub-district"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"24/28\", }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"28/35\", }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสไปรษณีย์ / Zip code"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"36/40\", }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"40/50\", }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Input",
      "parent": 6,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"no\")"
        },
        "value": {
          "type": "code",
          "value": "props.no"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Input",
      "parent": 8,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"name\")"
        },
        "value": {
          "type": "code",
          "value": "props.name"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Input",
      "parent": 12,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"town\")"
        },
        "value": {
          "type": "code",
          "value": "props.town"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Input",
      "parent": 14,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"street\")"
        },
        "value": {
          "type": "code",
          "value": "props.street"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Input",
      "parent": 16,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"road\")"
        },
        "value": {
          "type": "code",
          "value": "props.road"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Input",
      "parent": 24,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"zipcode\")"
        },
        "value": {
          "type": "code",
          "value": "props.zipcode"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Dropdown",
      "parent": 10,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"type\")"
        },
        "options": {
          "type": "code",
          "value": "props.homeTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.type"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Dropdown",
      "parent": 18,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"province\")"
        },
        "options": {
          "type": "code",
          "value": "props.provinceOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.province"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Dropdown",
      "parent": 20,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"district\")"
        },
        "options": {
          "type": "code",
          "value": "props.districtOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.district"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Dropdown",
      "parent": 22,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"city\")"
        },
        "options": {
          "type": "code",
          "value": "props.cityOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.city"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "โทรศัพท์บ้าน / Landline"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"2/6\", }"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"6/13\", }"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"13/15\", }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "โทรศัพท์ที่ทำงาน  /  Work phone"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"16/21\", }"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"21/28\", }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"28/31\", }"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "โทรศัพท์มือถือ / Mobile phone"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"32/36\", }"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"36/50\", }"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "E-mail"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"2/5\", }"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"5/15\", }"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ช่องทางการส่งข้อมูล / Information Channel"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"16/22\", }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"22/31\", }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ  / Note"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"32/36\", }"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"36/50\", }"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Dropdown",
      "parent": 46,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"channel\")"
        },
        "options": {
          "type": "code",
          "value": "props.contactOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.channel"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Input",
      "parent": 36,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"tel_home\")"
        },
        "value": {
          "type": "code",
          "value": "props.tel_home"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Input",
      "parent": 37,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"tel_home_suffix\")"
        },
        "value": {
          "type": "code",
          "value": "props.tel_home_suffix"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Input",
      "parent": 39,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"tel_office\")"
        },
        "value": {
          "type": "code",
          "value": "props.tel_office"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Input",
      "parent": 40,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"tel_office_suffix\")"
        },
        "value": {
          "type": "code",
          "value": "props.tel_office_suffix"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Input",
      "parent": 42,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"tel_mobile\")"
        },
        "value": {
          "type": "code",
          "value": "props.tel_mobile"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Input",
      "parent": 48,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"note\")"
        },
        "value": {
          "type": "code",
          "value": "props.note"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Input",
      "parent": 44,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"email\")"
        },
        "value": {
          "type": "code",
          "value": "props.email"
        }
      },
      "seq": 56,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 40,
  "isMounted": false,
  "memo": false,
  "name": "SubAddressUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
