import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Icon, Checkbox, Modal, Input } from "semantic-ui-react";

import withFixedColumns from "react-table-hoc-fixed-columns";
import ReactTable from "react-table-6";
import "react-lib/css/ReactTableFixedColumns.css";
import "react-table-hoc-fixed-columns/lib/styles.css";

// UI
import ModLabReport from "./ModLabReport";
import CardLabReportUX from "./CardLabReportUX";
import ModAttachFile from "./ModAttachFile";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { ModAuthen } from "react-lib/apps/common";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// Interface
import { State, RunSequence } from "./sequence/LabReport";
import { getRefValue, getStatus } from "./sequence/OPDLabSummary";
import { DisplayResult } from "./CardOPDLabSummary";

// Types
type CardLabReportProps = {
  setProp: (key: string, value: any) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: RunSequence;
  // data
  LabReportSequence?: State["LabReportSequence"];
  successMessage?: any;
  selectedLabOrderWorking: any;
  buttonLoadCheck?: any;
  errorMessage?: any;
  permissionLabResultConfidential?: boolean;
  searchedItemListWithKey?: Record<string, any>;
  // options
  masterOptions?: Record<string, any>;
  patientInfo?: any;
};

// Const
const ReactTableFixedColumns = withFixedColumns(ReactTable);

const CARD_LAB_REPORT = "CardLabReport";
const MOD_ATTACH_FILE = "ModAttachfile";

const LAB_STATUS = {
  ORDERED: "ร้องขอทำ Lab",
  SPECIMEN_COLLECTING: "พิมพ์สติ๊กเกอร์แล้ว",
  SPECIMEN_COLLECTED: "เก็บ specimen เสร็จสิ้น",
  SPECIMEN_SENT: "Specimen Center ได้รับ Specimen",
  SPECIMEN_CHECKED_OUT: "ห้อง Lab ได้รับ Specimen",
  PROCESSING: "กำลังทำ Lab",
  FINISHED: "เสร็จสิ้น",
  REPORTED: "รายงานผล",
  CANCELED: "ยกเลิก",
  SPECIMEN_REJECTED: "Specimen ถูกปฏิเสธ",
  PENDING_CONFIRM: "รอยืนยัน",
};

const tableStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 8px",
  margin: 0,
};

const CardLabReport = (props: CardLabReportProps) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [selectItemExpand, setSelectItemExpand] = useState<any>({});

  const [openModAttachFile, setOpenModAttachFile] = useState<boolean>(false);
  const [modLabReportData, setModLabReportData] = useState<any>({
    openLabReport: false,
    openAuthen: false,
    lab_order_item_id: null,
  });

  const [labOrderItems, setLabOrderItems] = useState<any[]>([]);

  useEffect(() => {
    props.runSequence({ sequence: "LabReport", restart: true });

    return () => {
      props.runSequence({ sequence: "LabReport", clear: true });
    };
  }, []);

  useEffect(() => {
    let temp: any[] = [];
    let items = JSON.parse(
      JSON.stringify([
        ...(props.LabReportSequence?.centralLabResult?.items || []),
      ])
    );

    items = items.filter(
      (item: any) =>
        ![LAB_STATUS.ORDERED, LAB_STATUS.SPECIMEN_COLLECTING].includes(
          item.central_lab_order_item_status
        )
    );

    items.forEach((it: any, parent_idx: any) => {
      it["chk"] = false;
      it["value"] = it.central_lab_result_value;
      it["children"] = it.children.map((child: any, idx: any) => {
        return {
          ...child,
          chk: false,
          parent_idx: parent_idx,
          value: child.central_lab_result_value,
        };
      });

      temp.push(it);
    });

    setLabOrderItems(temp);
  }, [props.LabReportSequence?.centralLabResult]);

  useEffect(() => {
    if (props.successMessage?.[MOD_ATTACH_FILE]) {
      setOpenModAttachFile(false);
      props.setProp(`successMessage.${MOD_ATTACH_FILE}`, null);
    }
  }, [props.successMessage?.[MOD_ATTACH_FILE]]);

  useEffect(() => {
    if (props.successMessage?.[CARD_LAB_REPORT]) {
      setUsername("");
      setPassword("");
    }
  }, [props.successMessage?.[CARD_LAB_REPORT]]);

  useEffect(() => {
    if (
      modLabReportData.openAuthen &&
      !modLabReportData.openLabReport &&
      props.permissionLabResultConfidential
    )
      setModLabReportData({
        ...modLabReportData,
        openLabReport: true,
        openAuthen: false,
      });
  }, [props.permissionLabResultConfidential]);

  const checkboxSelected = useCallback(
    (item: any) => (data: any, props: any) => {
      const children: any[] = labOrderItems[item["parent_idx"]]?.children || [];

      if (props.checked) {
        item.chk = true;

        const isCheckAll: boolean = children.every((acc: any) => acc.chk);

        if (isCheckAll && !!children.length) {
          labOrderItems[item["parent_idx"]].chk = true;
        }

        item.children?.forEach((it: any) => {
          it["chk"] = true;
        });

        setLabOrderItems([...labOrderItems]);
      } else {
        item.chk = false;

        const isUnCheckAll: boolean = children.every((acc: any) => !acc.chk);

        if (isUnCheckAll && !!children.length) {
          labOrderItems[item["parent_idx"]].chk = false;
        }

        if ("parent_idx" in item) {
          labOrderItems[item["parent_idx"]]?.children?.forEach((it: any) => {
            if (it.product_id === item.product_id) {
              it["chk"] = false;
            }
          });
        } else {
          item.children?.forEach((it: any) => {
            it["chk"] = false;
          });
        }

        setLabOrderItems([...labOrderItems]);
      }
    },
    [labOrderItems]
  );

  const handleChangeValue = useCallback(
    (item: any) => (e: any, v: any) => {
      if ("parent_idx" in item) {
        labOrderItems[item["parent_idx"]]?.children?.forEach((it: any) => {
          if (it.product_id === item.product_id) {
            it["central_lab_result_value"] = v.value;
          }
        });
      } else {
        item.central_lab_result_value = v.value;
      }

      let tmp = [...labOrderItems];

      setLabOrderItems(tmp);
    },
    [labOrderItems]
  );

  const handelCheckedAll = useCallback(
    (e: any, v: any) => {
      let items: any[] = [...labOrderItems];

      items = items.map((item) => ({
        ...item,
        chk: v.checked,
        children: item.children.map((acc: any) => ({
          ...acc,
          chk: v.checked,
        })),
      }));

      setLabOrderItems(items);
    },
    [labOrderItems]
  );

  const disabledAttachFile = useMemo(() => {
    const filterItems = labOrderItems
      .flatMap((item: any) => (item.children.length ? item.children : [item]))
      .filter((item: any) => item.chk);

    return !filterItems?.length;
  }, [labOrderItems]);

  const isCheckedAll = useMemo(() => {
    return (
      !!labOrderItems.length &&
      labOrderItems
        .flatMap((item: any) => (item.children.length ? item.children : [item]))
        .every((item: any) => item.chk)
    );
  }, [labOrderItems]);

  const tableColumns = useMemo(() => {
    return [
      {
        id: "checkbox",
        accessor: "",
        Cell: ({ original }: any) => {
          return (
            <div>
              <Checkbox
                checked={original.chk}
                onClick={checkboxSelected(original)}
              />
            </div>
          );
        },
        Header: (
          <>
            <Checkbox checked={isCheckedAll} onClick={handelCheckedAll} />
          </>
        ),
        sortable: false,
        width: 30,

        style: tableStyle,
        resizable: false,
      },
      {
        Header: "Lab Test",
        minWidth: 200,
        resizable: false,
        expander: true,
        style: { whiteSpace: "unset" }, // WordWrap

        Expander: ({ isExpanded, ...rest }: any) => {
          // test your condition for Sub-Component here
          // I am using the presence of no comments
          if (!rest.original.hasOwnProperty("children")) {
            return <div>&nbsp;&nbsp;&nbsp;{rest.original.name}</div>;
          } else if (
            rest.original.hasOwnProperty("children") &&
            rest.original.children.length === 0
          ) {
            return (
              <div
                style={{
                  color:
                    rest.original.central_lab_order_item_status ===
                    LAB_STATUS.SPECIMEN_REJECTED
                      ? "red"
                      : "",
                }}
              >
                {rest.original.name}
              </div>
            );
          } else {
            return (
              <div>
                {isExpanded ? (
                  <>
                    <span
                      style={{
                        display: "inline-block",
                        transform: "rotate(90deg)",
                      }}
                    >
                      &#10148;
                    </span>
                    <span>{rest.original.name}</span>
                  </>
                ) : (
                  <span
                    style={{
                      color:
                        rest.original.central_lab_order_item_status ===
                        LAB_STATUS.SPECIMEN_REJECTED
                          ? "red"
                          : "",
                    }}
                  >
                    &#10148; {rest.original.name}
                  </span>
                )}
              </div>
            );
          }
        },
        getProps: (state: any, rowInfo: any, column: any) => {
          if (rowInfo) {
            if (
              !rowInfo.original.children ||
              (rowInfo.original.children &&
                rowInfo.original.children.length === 0)
            ) {
              return { onClick: () => {} };
            }
          }
          return { className: "show-pointer" };
        },
      },
      {
        id: "file",
        accessor: "",
        Cell: ({ original }: any) => {
          return (
            <div
              style={{
                display: original.children?.length > 0 ? "none" : "flex",
                alignItems: "center",
              }}
            >
              <Icon
                name="file pdf outline"
                color="blue"
                size="large"
                style={{
                  display:
                    original.summary_file_result?.filter(
                      (item: any) => item.active
                    )?.length === 0
                      ? "none"
                      : "",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.setProp("permissionLabResultConfidential", false);

                  setModLabReportData({
                    openLabReport: !original.is_confidential,
                    openAuthen: original.is_confidential,
                    lab_order_item_id: original?.central_lab_order_item_id,
                  });
                }}
              ></Icon>
            </div>
          );
        },
        Header: "File",
        sortable: false,
        width: 80,

        style: tableStyle,
        resizable: false,
      },
      {
        id: "value",
        accessor: "",
        Cell: ({ original }: any) => {
          const handleRef = (ref: any) => {
            if (ref) {
              ref.inputRef.current.style.backgroundColor =
                original?.central_lab_order_item_status === LAB_STATUS.REPORTED
                  ? "transparent"
                  : "";
            }
          };

          const status = getStatus({
            refValue: original.ref_value_txt,
            criticalValue: {
              max: original.critical_value_max,
              min: original.critical_value_min,
            },
            value: original.value,
            genderName: props.patientInfo?.gender_name,
          });

          return (
            <div
              style={{
                display: original.children?.length > 0 ? "none" : "flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Input
                ref={handleRef}
                value={original.central_lab_result_value}
                fluid={true}
                style={{ width: "77.5%" }}
                onChange={handleChangeValue(original)}
              ></Input>
              <DisplayResult
                status={status.status}
                critical={status.is_critical}
                paddingStyle={0}
                labelStyle={{ fontSize: "0.525rem", marginTop: "-2px" }}
                statusStyle={{ marginLeft: "-0.45rem" }}
              />
            </div>
          );
        },
        Header: "Value",
        sortable: false,
        width: 170,

        style: tableStyle,
        resizable: false,
      },
      {
        id: "reference",
        accessor: "",
        Cell: ({ original }: any) => {
          return (
            <div
              style={{ display: original.children?.length > 0 ? "none" : "" }}
            >
              {getRefValue(
                original.ref_value_txt,
                props.patientInfo?.gender_name
              )}
            </div>
          );
        },
        Header: "Reference",
        sortable: false,
        width: 150,

        style: { display: "flex", padding: "0 8px", margin: 0 },
        resizable: false,
      },
      {
        id: "unit",
        accessor: "",
        Cell: ({ original }: any) => {
          return (
            <div
              style={{ display: original.children?.length > 0 ? "none" : "" }}
            >
              {original.unit}
            </div>
          );
        },
        Header: "Unit",
        sortable: false,
        width: 150,

        style: { display: "flex", padding: "0 8px", margin: 0 },
        resizable: false,
      },
      {
        id: "status",
        accessor: "",
        Cell: ({ original }: any) => {
          const status =
            original.central_lab_order_item_status === LAB_STATUS.REPORTED &&
            !original.central_lab_result_is_authorized
              ? LAB_STATUS.PENDING_CONFIRM
              : original.central_lab_order_item_status;

          return (
            <div
              style={{
                display: original.children?.length > 0 ? "none" : "",
                color: status === LAB_STATUS.SPECIMEN_REJECTED ? "red" : "",
              }}
            >
              {status}
            </div>
          );
        },
        Header: "Status",
        sortable: false,
        minWidth: 150,

        style: { display: "flex", padding: "0 8px", margin: 0 },
        resizable: false,
      },
    ];
  }, [isCheckedAll, labOrderItems, props.patientInfo]);

  const closeModLabReport = () => {
    props.setProp("permissionLabResultConfidential", false);
    setModLabReportData({
      openLabReport: false,
      openAuthen: false,
      lab_order_item_id: null,
    });
  };

  const handleOnAddFile = (data: any) => {
    props.runSequence({
      sequence: "LabReport",
      action: "attach_file",
      card: MOD_ATTACH_FILE,
      buttonLoadKey: `${MOD_ATTACH_FILE}_SAVE`,
      ...data,
    });
  };

  const handleRemoveItem = (index: number) => {
    let tmp = [...labOrderItems];
    tmp[index] = {
      ...tmp[index],
      chk: false,
    };
    tmp[index].children?.forEach((it: any) => {
      it["chk"] = false;
    });

    setLabOrderItems(tmp);
  };

  const handleEnterToken = (code: string) => {
    props.runSequence({
      sequence: "LabReport",
      action: "token",
      code: code,
    });
  };

  const handleCloseModAttachFile = () => {
    setOpenModAttachFile(false);
  };

  const handleEdit = () => {
    props.runSequence({
      sequence: "LabReport",
      action: "EDIT",
      card: CARD_LAB_REPORT,
      buttonLoadKey: `${CARD_LAB_REPORT}_SAVE`,
      labOrderItems: labOrderItems,
      username: username,
      password: password,
    });
  };

  const handleAuthorize = () => {
    props.runSequence({
      sequence: "LabReport",
      action: "AUTHORIZE",
      card: CARD_LAB_REPORT,
      buttonLoadKey: `${CARD_LAB_REPORT}_AUTHORIZE`,
      labOrderItems: labOrderItems,
      username: username,
      password: password,
    });
  };

  console.log("CardLabReport props: ", props, labOrderItems);

  return (
    <div style={{ height: "94vh" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_LAB_REPORT}`, null);
        }}
        error={
          "error" in (props.errorMessage?.[CARD_LAB_REPORT] || {})
            ? props.errorMessage?.[CARD_LAB_REPORT]?.error
            : props.errorMessage?.[CARD_LAB_REPORT]
        }
        success={null}
      />

      <CardLabReportUX
        // Data
        selectedLabOrderWorking={props.selectedLabOrderWorking}
        username={username}
        password={password}
        disabledAttachFile={disabledAttachFile}
        // Callback
        handleAttachFile={() => setOpenModAttachFile(true)}
        changePassword={(e: any) => setPassword(e.target.value)}
        changeUsername={(e: any) => setUsername(e.target.value)}
        // Element
        tableReport={
          <div style={{ width: "100%" }}>
            <ExpandingTable
              data={labOrderItems || []}
              columns={tableColumns}
              selectItemExpand={selectItemExpand}
              setSelectItemExpand={setSelectItemExpand}
            />
          </div>
        }
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleEdit}
            // data
            paramKey={`${CARD_LAB_REPORT}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_LAB_REPORT}_SAVE`]}
            // config
            disabled={username && password ? false : true}
            size="small"
            color="green"
            name="SAVE"
            style={{ width: "100%" }}
            title="Save"
          />
        }
        buttonAuthorize={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleAuthorize}
            // data
            paramKey={`${CARD_LAB_REPORT}_AUTHORIZE`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_LAB_REPORT}_AUTHORIZE`]
            }
            // config
            disabled={username && password ? false : true}
            size="small"
            name="AUTHORIZE"
            color="yellow"
            style={{ width: "100%" }}
            title="Authorize"
          />
        }
      />

      <ModAttachFile
        setProp={props.setProp}
        // data
        open={openModAttachFile}
        labOrderItems={labOrderItems}
        errorMessage={props.errorMessage}
        buttonLoadCheck={props.buttonLoadCheck}
        labReportToken={props.LabReportSequence?.LabReportToken || {}}
        // callback
        onRemoveItem={handleRemoveItem}
        onEnterToken={handleEnterToken}
        onClose={handleCloseModAttachFile}
        onSave={handleOnAddFile}
      />

      <ModAuthen
        titleName="อนุญาตให้เข้าถึงผลการทดสอบทางห้องปฏิบัติการ เฉพาะบุคคลที่มีสิทธิ์เท่านั้น"
        titleColor="blue"
        open={modLabReportData?.openAuthen}
        onDeny={closeModLabReport}
        onApprove={({ username, password }: any) => {
          props.onEvent({
            message: "CheckPermissionLabConfidential",
            params: {
              username: username,
              password: password,
              cardKey: CARD_LAB_REPORT,
            },
          });
        }}
      />

      <Modal
        open={modLabReportData?.openLabReport}
        style={{
          width: "80%",
          padding: "1rem 1rem",
          margin: "1rem 0 0",
          height: "95vh",
          overflowY: "auto",
        }}
        onClose={closeModLabReport}
      >
        <ModLabReport
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          LabReportSequence={props.LabReportSequence}
          // options
          masterOptions={props.masterOptions}
          // data
          permissionLabResultConfidential={
            props.permissionLabResultConfidential
          }
          cardKey={CARD_LAB_REPORT}
          // CommonInterface
          errorMessage={props.errorMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          patientInfo={props.patientInfo}
          selectedLabOrderWorking={props.selectedLabOrderWorking}
          selectedLabOrderItem={modLabReportData?.lab_order_item_id}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // callback
          onModLabReport={closeModLabReport}
        />
      </Modal>
    </div>
  );
};

export default CardLabReport;

/* ------------------------------------------------------ */

/*                     ExpandingTable                     */

/* ------------------------------------------------------ */
const ExpandingTable = (props: any) => {
  const [scroll, setScroll] = useState<boolean>(
    props.data && props.data?.length && props.data?.length > 20 ? true : false
  );

  useEffect(() => {
    if (props.data && props.data?.length && props.data?.length > 20) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  }, [props.data]);

  const handleGetTdProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    const original = rowInfo?.original || {};
    const getStatus = (data: any = {}) => {
      return data?.central_lab_order_item_status === LAB_STATUS.REPORTED &&
        !data?.central_lab_result_is_authorized
        ? LAB_STATUS.PENDING_CONFIRM
        : data?.central_lab_order_item_status;
    };
    let status = getStatus(original);

    status = !!original.children?.length
      ? original.children.every((item: any) => getStatus(item) === status)
        ? status
        : ""
      : status;

    return {
      style: {
        backgroundColor:
          status === LAB_STATUS.REPORTED ? "rgba(62, 247, 92, 0.5)" : "",
      },
    };
  };

  return (
    <ReactTableFixedColumns
      // @ts-ignore
      data={props.data}
      columns={props.columns}
      expanded={props.selectItemExpand}
      expanderDefaults={{
        sortable: false,
        resizable: true,
        filterable: false,
      }}
      onExpandedChange={(expanded: any, index: number, event: any) => {
        // Find Expand
        let parentCount =
          props.data && props.data?.length ? props.data?.length : 0;
        let childCount = 0;
        // Adjust PageSize and setHeight
        Object.keys(expanded).forEach(function (key) {
          if (expanded[key]) {
            childCount += props.data[key].children?.length;
          }
        });
        if (parentCount + childCount > 20) {
          setScroll(true);
        } else {
          setScroll(false);
        }

        props.setSelectItemExpand(expanded);
      }}
      pageSize={props.data && props.data?.length ? props.data?.length : 20}
      style={{ maxHeight: "700px" }}
      subRowsKey={"children"}
      showPagination={false}
      getNoDataProps={() => {
        return { style: { display: "none" } };
      }}
      getTdProps={handleGetTdProps}
      minRows={4}
    />
  );
};
