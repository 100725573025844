import React, { useState, useEffect, useImperativeHandle } from "react";
import { Dropdown, Form, Modal } from "semantic-ui-react";

// UX
import CardDrugClaimQAUX from "./CardDrugClaimQAUX";

// Interface
import { EstimateMap } from "./TPDInterface";

const CardDrugClaimQA: React.FunctionComponent<any> = (props) => {
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [payloadDetail, setPayloadDetail] = useState<Record<string, any>>({});
  const [drugOrderItems, setDrugOrderItems] = useState<Record<string, any>>({});
  const [payloads, setPayloads] = useState<any[]>([]);

  useEffect(() => {
    const items = (props.estimate?.items || []).map((item: any) => {
      item.quantity =
        parseFloat(item.quantity) * parseFloat(item.dispense_quantity);
      item.dispense_quantity = 1;
      return item;
    });

    setDrugOrderItems(items);
  }, [props.estimate?.items]);

  useEffect(() => {
    if (Object.keys(props.estimate || {}).length) {
      const { index } = getAnswered();
      setCurrentIndex(index);
      setPayloads(props.estimate.payloads);
    }
  }, [props.estimate]);

  useEffect(() => {
    if (Object.keys(props.estimate || {}).length) {
      handleSetPayload(currentIndex || 0);
    }
  }, [currentIndex]);

  const getAnswered = () => {
    let index = 0;
    const detail: any = handleSetPayload(index, true);
    const answerDict = detail.currentPayload?.answer_dict;

    
    for (const item of detail.subTab) {
      const answered = item.questions.every(
        (item: any) => item.id in answerDict
      );
      // console.log("getAnswered: ", index, answerDict, answered)
      if (answered) {
        index += 1;
      }
    }

    return {
      index: index > detail.subTab.length - 1 ? index - 1 : index,
      // index
    };
  };

  const getTabModel = (payload: any) => {
    let flags: any = {};
    let tabModel = payload["questions"].filter((value: any) => {
      if (flags[value.tab_name]) {
        return false;
      }
      flags[value.tab_name] = true;
      return true;
    });

    return tabModel.map((q: any) => {
      return {
        tab_name: q.tab_name,
        questions: payload["questions"].filter((value: any) => {
          return q.tab_name === value.tab_name;
        }),
      };
    });
  };

  const handleSetPayload = (index: number, isReturn?: boolean) => {
    const estimate = props.estimate;

    const payloads: any[] = estimate.payloads;
    const productId: number | null = estimate.selectedProduct;

    let productItems: any[] = estimate.items;
    let currentPayload: any = null;
    let currentPayloadIndex: any = index;
    let haveQuestion = false;
    let title: string = "รายการคำถาม Claim";
    let subTab: any[] = [];
    //  check cause payload item !== GetAnswer index back
    // index = estimate.index === index ? index : estimate.index

    if (index >= payloads?.length) {
      index = payloads.length - 1
    }

    // console.log("handleSetPayload1: ", productId, payloads, index)
    // this always set the latest unanswer question
    for (const item in payloads) {
      // Check is matched depend on productId
      let isMatched = false;
      if (!productId) {
        // Select first payload that have_question
        isMatched = payloads[item]["have_question"];
      } else {
        // Select payload that match product_id
        isMatched = payloads[item]["product_id"] === productId;
      }

      // console.log("isMatched? ", isMatched, payloads[index])
      if (isMatched) {
        currentPayload = payloads[index];
        currentPayloadIndex = index;
        subTab = getTabModel(currentPayload);
        title = currentPayload["label"];
        productItems = props.estimate.items;
        haveQuestion = true;
        break;
      }
    }
    
    const detail = {
      haveQuestion,
      currentPayload,
      currentPayloadIndex,
      productItems,
      title,
      subTab,
      productId,
      index: currentIndex,
    };
    // console.log("handleSetPayload2: ", detail, isReturn)
    if (isReturn) {
      return detail;
    } else {
      setPayloadDetail(detail);
    }
  };

  const handleSave = () => {
    var isAnswerCompleted = true;
    const currentPayload = payloadDetail.currentPayload;

    payloads.forEach((item: any) => {
      item.questions.forEach((question: any) => {
        console.log(
          "questions",
          question.id,
          item.answer_dict,
          question.id in item.answer_dict
        );
        if (!(question.id in item.answer_dict)) {
          isAnswerCompleted = false;
          return;
        }
        currentPayload["answer_dict"][question.id] =
          item.answer_dict[question.id];
      });
    });

    if (isAnswerCompleted) {
      estimate(payloadDetail.productItems, true);
    } else {
      estimate(payloadDetail.productItems);
    }
    setCurrentIndex(null)
  };

  const estimate = (rows: any, hideQuestion?: boolean) => {

    if(props.onSave){
      return props.onSave(
        {
          ...props.estimate?.original?.claim_payload,
          payloads,
        },
        props.estimate.index
      );
    }

    const data = {
      claim_payload: {
        ...props.estimate.original.claim_payload,
        payloads,
      },
      drug_type: EstimateMap[props.orderType],
      emr: props.selectedEmr?.id || null,
      encounter: props.selectedEncounter?.id || null,
      hideQuestion: hideQuestion,
      items: drugOrderItems,
      progression_cycle: props.selectedProgressCycle?.id || null,
    };

    // props.onEvent({
    //   message: "HandleSavePostponeAnswer",
    //   params: {
    //     data,
    //     index: props.estimate.index,
    //     isAdd: props.estimate.isAdd,
    //     stateKey: props.stateKey || "drugOrder",
    //   },
    // });

    if (props.stateKey === "drugResuscitation") {
      props.onSaveQA(data)
    } else {
      props.onEvent({
        message: "HandleSavePostponeAnswer",
        params: {
          data,
          index: props.estimate.index,
          isAdd: props.estimate.isAdd,
          stateKey: props.stateKey || "drugOrder",
        },
      });
    }
  };

  const handleChangeAnswerDict = (answer: any) => {
    payloads[0].answer_dict = {
      ...payloads[0].answer_dict,
      ...answer,
    };

    if (!props.notSetAnswer){
      props.onEvent({
        message: "HandleSetAnswerDrugOrderItem",
        params: {
          index: props.estimate.index, // this only index of items not payloads
          answer,
          stateKey: props.stateKey || "drugOrder",
        },
      });
    }

    setPayloads(payloads);
  };

  // const handleClose = () => {
  //   setCurrentIndex(null)
  //   props.onEvent({
  //     message: "HandleCloseDrugOrderEstimate",
  //     params: {
  //       stateKey: props.stateKey || "drugOrder",
  //     },
  //   });
  // };

  const handleClose = () => {
    setCurrentIndex(null);

    if (props.stateKey === "drugResuscitation") {
      props.onCloseQA()
    } else {
      props.onEvent({
        message: "HandleCloseDrugOrderEstimate",
        params: {
          stateKey: props.stateKey || "drugOrder",
          index: props.estimate.index,
        },
      });
    }
  };

  return (
    <Modal open={!!Object.keys(props.estimate || {}).length}>
      <div>
        <CardDrugClaimQAUX
          title={payloadDetail.title}
          subQATab={(payloadDetail.subTab || [])?.map(
            (item: any, index: number) => (
              <div
                key={index}
                style={{
                  padding: "5px",
                  ...(index === payloadDetail.index
                    ? { backgroundColor: "rgb(32, 131, 193)", color: "white" }
                    : {}),
                }}
                onClick={() => setCurrentIndex(index)}
              >
                {item.tab_name}
              </div>
            )
          )}
          subQAField={
            <SubQAField
              questions={
                payloadDetail.subTab?.[currentIndex || 0]?.questions || []
              }
              currentPayload={payloadDetail.currentPayload}
              onChangeAnswerDict={handleChangeAnswerDict}
              statusRequest={props.statusRequest}
            />
          }
          onSave={handleSave}
          onClose={handleClose}
          statusRequest={props.statusRequest}
        />
      </div>
    </Modal>
  );
};

const SubQAField = React.forwardRef<any, any>((props, ref) => {
  const [answerDict, setAnswerDict] = useState<Record<string, any>>({});

  useEffect(() => {
    setAnswerDict(props.currentPayload?.["answer_dict"] || {});
  }, [props.currentPayload?.["answer_dict"]]);

  const handleChange = (e: any, v: any) => {
    let checked: string = v.checked?.toString();
    if (typeof v.checked === "boolean") {
      checked = `${checked[0].toUpperCase()}${checked.slice(1)}`;
    }

    const dict = {
      ...answerDict,
      [v.name]: typeof v.checked === "boolean" ? checked : v.value,
    };

    setAnswerDict(dict);
    props.onChangeAnswerDict?.(dict);
  };

  return (
    <Form>
      {props.questions.map((item: any) => (
        <SubQAType
          label={item.label}
          type={item.type}
          answer={answerDict[item.id]}
          choices={item.choices}
          dataValidateKey={item.id}
          questionId={item.id}
          default_answer={item.default_answer}
          statusRequest={props.statusRequest}
          onChange={handleChange}
        />
      ))}
    </Form>
  );
});

const CHECK_PARSE: Record<string, boolean> = {
  True: true,
  False: false,
};

const SubQAType: React.FunctionComponent<any> = (props) => {
  const mapOptions = (list: any[]) => {
    return list.map((item: any) => ({
      key: item.id,
      text: item.name,
      value: item.id,
    }));
  };

  return (
    <>
      {props.type === "CHOICE" ? (
        <Form.Dropdown
          value={
            typeof props.choices?.[0]?.id === "number"
              ? Number(props.answer)
              : props.answer
          }
          label={props.label}
          placeholder="กรุณาระบุ"
          options={mapOptions(props.choices)}
          selection
          style={{ width: "auto" }}
          name={props.dataValidateKey}
          onChange={props.onChange}
          disabled={props.statusRequest ? true : false}
        />
      ) : props.type === "BOOLEAN" ? (
        <Form.Checkbox
          checked={CHECK_PARSE[props.answer]}
          label={props.label}
          name={props.dataValidateKey}
          onChange={props.onChange}
          readOnly={props.statusRequest ? true : false}
        />
      ) : props.type === "DECIMAL" ? (
        <Form.Input
          value={props.answer || ""}
          type="number"
          name={props.dataValidateKey}
          onChange={props.onChange}
          readOnly={props.statusRequest ? true : false}
        />
      ) : props.type === "TEXT" ? (
        <Form.Input
          value={props.answer || ""}
          name={props.dataValidateKey}
          onChange={props.onChange}
          readOnly={props.statusRequest ? true : false}
        />
      ) : null}
    </>
  );
};

export default React.memo(CardDrugClaimQA);
