import React, {
  CSSProperties,
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Dimmer, Icon, Loader } from "semantic-ui-react";

// UX
import CardSearchPackageUX from "./CardSearchPackageUX";
import LoadingIcon from "./LoadingIcon";
import ModPackageDescription from "./ModPackageDescription";

// Common
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import { ModInfo } from "react-lib/apps/common";

// Interface
import {
  FilterPackageType,
  MasterOptionsType,
  PACKAGE_SEARCH_SP,
  RunSequence,
  State,
  BUTTON_ACTIONS,
} from "./sequence/PackagePurchase";

// Types
type CardSearchPackageProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: RunSequence;
  PackagePurchaseSequence?: State["PackagePurchaseSequence"];
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  // options
  masterOptions?: MasterOptionsType;
};

const GridCenter = { display: "grid", placeContent: "center" } as CSSProperties;

const CARD_SEARCH_PACKAGE = "CardSearchPackage";

const CardSearchPackage = (props: CardSearchPackageProps) => {
  // Use Effect
  useEffect(() => {
    // use action SearchPackage
    props.runSequence({
      sequence: "PackagePurchase",
      restart: true,
      nextIndex: "SearchPackage",
      card: CARD_SEARCH_PACKAGE,
    });

    return () => {
      handleCloseMessage();
    };
  }, []);

  // Use Callback
  const mapPackageOptions = useCallback((items: any[]) => {
    return items.map((item: any) => ({
      key: item.id,
      value: item.id,
      text: `[${item.code}] ${item.name}`,
    }));
  }, []);

  const handleSelectedItem = useCallback(
    async (value: any, key: any) => {
      props.setProp(
        `PackagePurchaseSequence.filterPackage.programId`,
        value || null
      );
    },
    [props.searchedItemListWithKey]
  );

  const handleBuy = useCallback(
    (id?: number) => () => {
      if (!!id) {
        props.runSequence({
          sequence: "PackagePurchase",
          action: "PURCHASE",
          card: CARD_SEARCH_PACKAGE,
          packageId: id,
        });
      }
    },
    []
  );

  const handleOpenInfo = useCallback(
    (id?: number) => () => {
      if (!!id) {
        props.runSequence({
          sequence: "PackagePurchase",
          action: "DESCRIPTION",
          card: CARD_SEARCH_PACKAGE,
          packageId: id,
        });
      }
    },
    []
  );

  const handleInputBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.valueAsNumber;

      if (["ageStart", "ageEnd"].includes(name)) {
        const detail = props.PackagePurchaseSequence?.filterPackage || {};
        const start = detail.ageStart || "";
        const end = detail.ageEnd || "";

        let isError = false;
        // หากระบุ age start มากกว่า year end หรือ age end น้อยกว่า age start ให้แสดงข้อความแจ้งเตือน
        if (
          (name === "ageStart" && value > Number(end) && end !== "") ||
          (name === "ageEnd" && value < Number(start) && start !== "")
        ) {
          isError = true;
        } else {
          isError = false;
        }

        props.setProp(
          "PackagePurchaseSequence.filterPackage.ageRangeError",
          isError
        );
      }
    },
    [props.PackagePurchaseSequence?.filterPackage]
  );

  const handleInputRef = useCallback(
    (ref: any) => {
      if (!!ref) {
        const input = ref.inputRef.current;

        input.onblur = handleInputBlur;
      }
    },
    [props.PackagePurchaseSequence?.filterPackage]
  );

  // Use Memo
  const filterPackage = useMemo(() => {
    return props.PackagePurchaseSequence?.filterPackage || {};
  }, [props.PackagePurchaseSequence?.filterPackage]);

  const packageItems = useMemo(() => {
    return (props.PackagePurchaseSequence?.packageList || []).map(
      (item, index) => {
        const centerFormat = "code,selling_price".split(",").reduce(
          (a, k) => ({
            ...a,
            [k]: <div style={{ textAlign: "center" }}>{(item as any)[k]}</div>,
          }),
          {} as any
        );

        const packageType =
          props.masterOptions?.packageType?.find(
            (option) => option.value === item.package_type
          )?.text || "";

        return {
          ...item,
          description: (
            <div style={GridCenter}>
              <LoadingIcon
                name="eye"
                loading={
                  !!props.buttonLoadCheck?.[
                    `${CARD_SEARCH_PACKAGE}_${BUTTON_ACTIONS.desc}_${item.id}`
                  ]
                }
                onClick={handleOpenInfo(item.id)}
              />
            </div>
          ),
          buy: (
            <div style={GridCenter}>
              <LoadingIcon
                name="in cart"
                color="green"
                loading={
                  !!props.buttonLoadCheck?.[
                    `${CARD_SEARCH_PACKAGE}_${BUTTON_ACTIONS.purchase}_${item.id}`
                  ]
                }
                onClick={handleBuy(item.id)}
              />
            </div>
          ),
          package_type: <div>{packageType}</div>,
          ...centerFormat,
        };
      }
    );
  }, [
    props.PackagePurchaseSequence?.packageList,
    props.buttonLoadCheck,
    props.masterOptions?.packageType,
  ]);

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    let value = typeof data.value === "undefined" ? data.checked : data.value;
    const name = data.name as keyof FilterPackageType;

    const detail = { ...filterPackage };

    // type number value ไม่น้อยกว่า 0
    if (["ageStart", "ageEnd"].includes(name)) {
      value = Number(value) < 0 ? 0 : value;
    }

    if (!value) {
      const clear = (
        {
          isServiceType: ["serviceType"],
          isPackageType: ["packageType"],
          isProgramName: ["programId"],
          isGender: ["gender"],
          isAgeRange: ["ageStart", "ageEnd", "ageRangeError"],
        } as any
      )[name];

      clear?.forEach((key: string) => {
        (detail as any)[key] = "";
      });
    }

    detail[name] = value;

    props.setProp(`PackagePurchaseSequence.filterPackage`, { ...detail });
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "PackagePurchase",
      action: "SEARCH",
      card: CARD_SEARCH_PACKAGE,
    });
  };

  const handleClear = () => {
    props.setProp(`PackagePurchaseSequence.filterPackage`, {});
  };

  const handleCloseModInfo = () => {
    props.setProp("PackagePurchaseSequence.packageInfo", null);
  };

  const handleCloseMessage = async () => {
    await props.setProp(`errorMessage.${CARD_SEARCH_PACKAGE}`, null);

    props.setProp(`successMessage.${CARD_SEARCH_PACKAGE}`, null);
  };

  console.log("CardSearchPackageUX", props);

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={handleCloseMessage}
        error={props.errorMessage?.[CARD_SEARCH_PACKAGE]}
        success={props.successMessage?.[CARD_SEARCH_PACKAGE]}
      />

      <CardSearchPackageUX
        // ref
        inputRef={handleInputRef}
        // data
        filterPackage={filterPackage}
        packageItems={packageItems}
        // options
        packageTypeOptions={props.masterOptions?.packageType}
        packageServiceTypeOptions={props.masterOptions?.packageServiceType}
        // config
        disabledPackageType={!filterPackage.isPackageType}
        disabledPackageServiceType={!filterPackage.isServiceType}
        ageRangeError={filterPackage.ageRangeError}
        // callback
        onChangeValue={handleChangeValue}
        onClear={handleClear}
        // Element
        SearchBoxProgramName={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="Package"
            id="SP"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            disabled={!filterPackage.isProgramName}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={filterPackage.programId || null}
            setSelectedItem={handleSelectedItem}
            // options
            mapOptions={mapPackageOptions}
          />
        }
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={`${CARD_SEARCH_PACKAGE}_${BUTTON_ACTIONS.search}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_SEARCH_PACKAGE}_${BUTTON_ACTIONS.search}`
              ]
            }
            // config
            disabled={filterPackage.ageRangeError}
            color={"blue"}
            name={BUTTON_ACTIONS.search}
            size="medium"
            title="ค้นหา"
          />
        }
      />

      <ModPackageDescription
        open={!!props.PackagePurchaseSequence?.packageInfo}
        data={props.PackagePurchaseSequence?.packageInfo || null}
        onClose={handleCloseModInfo}
      />
    </div>
  );
};

export default React.memo(CardSearchPackage);
