import JsBarcode from "jsbarcode";
import moment from "moment";
import { beToAd, formatDate } from "react-lib/utils/dateUtils";

const textToBase64BarcodeBlob = (text: string) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, text, { displayValue: false });
  return canvas.toDataURL("image/png");
};

const FormAppointmentDetail = (props: any) => {
  console.log(props.data);

  return {
    pageSize: "A5",
    pageOrientation: "landscape",
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 13,
    },
    pageMargins: [10, 70, 10, 55],
    styles: {
      facultyHeader: {
        fontSize: 20,
        bold: true,
      },
      fieldKey: {
        bold: true,
        fontSize: 15,
      },
      fieldValue: {
        fontSize: 15,
      },
      miniFieldKey: {
        bold: true,
        fontSize: 12.5,
      },
      miniFieldValue: {
        fontSize: 13,
      },
    },
    header: {
      margin: [10, 5, 10, 5],
      stack: [
        {
          columns: [
            { margin: [0, 0, 0, 0], width: 60, image: "logochula", alignment: "left" },
            {
              margin: [-10, 5, 0, 0],
              width: "*",
              stack: [
                {
                  text: "CHULALONGKORN UNIVERSITY, FACULTY OF DENTISTRY",
                  style: "facultyHeader",
                  alignment: "center",
                },
                { text: "ใบนัดหมาย", style: "facultyHeader", alignment: "center" },
              ],
            },
          ],
        },
      ],
    },
    content: [
      {
        table: {
          widths: ["100%"],
          body: [
            [
              {
                margin: [20, 3, 20, 3],
                stack: [
                  {
                    columns: [
                      { width: "14.5%", text: "คลินิก", style: "fieldKey" },
                      { width: "4%", text: ":", style: "fieldValue" },
                      { width: "60.5%", text: props.data?.division_name, style: "fieldValue" },
                      { width: "auto", text: "โทร 02-2188733", style: "fieldValue" },
                    ],
                  },
                  {
                    stack: [
                      { field_1: "ชื่อ-นามสกุล", value_1: props.data?.full_name || "", field_2: "อายุ", value_2: props.data?.full_age || "" },
                      { field_1: "HN", value_1: props.data?.patient_hn || "", field_2: "การแพ้ยา", value_2: props.data?.extra?.allergy || "-" },
                      { field_1: "สั่งทำ Lab", value_1: props.data?.extra?.orderLab || "-", field_2: "สั่งทำ X-Ray", value_2: props.data?.extra?.orderXray || "-" },
                    ].map((item) => ({
                      columns: [
                        { width: "14.5%", text: item.field_1, style: "fieldKey" },
                        { width: "4%", text: ":", style: "fieldValue" },
                        { width: "42%", text: item.value_1, style: "fieldValue" },
                        { width: "14.5%", text: item.field_2, style: "fieldKey" },
                        { width: "4%", text: ":", style: "fieldValue" },
                        { width: "21%", text: item.value_2, style: "fieldValue" },

                      ],
                    }))
                  },
                  {
                    columns: [
                      { width: "14.5%", text: "Premedication", style: "fieldKey" },
                      { width: "4%", text: ":", style: "fieldValue" },
                      { width: "auto", text: props.data?.extra?.premedication || "-", style: "fieldValue" },
                    ],
                  },

                ],
              },
            ],
            [
              {
                margin: [20, 3, 20, 3],
                stack: [
                  {
                    columns: [
                      { width: "14.5%", text: "เลขที่นัด", style: "fieldKey" },
                      { width: "4%", text: ":", style: "fieldValue" },
                      { width: "auto", text: `P01${props.data?.content_id || ""}`, style: "fieldValue" },
                      {
                        margin: [20, 0, 0, 0],
                        image: textToBase64BarcodeBlob(`P01${props.data?.content_id || ""}`),
                        width: 40,
                        height: 17.5,
                      },
                    ],
                  },
                  {
                    columns: [
                      { width: "14.5%", text: "วันที่นัด", style: "fieldKey" },
                      { width: "4%", text: ":", style: "fieldValue" },
                      {
                        width: "42%",
                        text: props.data?.date ? `${beToAd(props.data.date)?.clone().locale("th")?.format("วันdddd ที่ DD MMMM")} ${moment(props.data.date, "DD/MM/YYYY").format(
                          "YYYY"
                        )}` : "",
                        style: "fieldValue",
                      },
                      {
                        width: "*",
                        columns: [
                          { text: "เวลานัด", style: "fieldKey", width: "17.5%" },
                          { text: " : ", style: "fieldKey", width: "7.5%" },
                          { text: props.data.appointment_time ? `${props.data.appointment_time} น.` :`${props.data.start || ""} - ${props.data.end || ""} น.`, style: "fieldValue", width: "auto" },
                        ]
                      },
                    ],
                  },
                  {
                    columns: [
                      { width: "14.5%", text: "ทันตแพทย์", style: "fieldKey" },
                      { width: "4%", text: ":", style: "fieldValue" },
                      { width: "42%", text: props.data?.display_info?.provider_name || "", style: "fieldValue" },
                    ],
                  },
                  {
                    columns: [
                      { width: "14.5%", text: "นัดเพื่อ", style: "fieldKey" },
                      { width: "4%", text: ":", style: "fieldValue" },
                      { width: "42%", text: props.data?.description || "-", style: "fieldValue" },
                    ],
                  },
                ],
              },
            ],
            [
              // {
              //   margin: [0, 3, 0, 3],
              //   stack: [
              //     {
              //       columns: [
              //         { width: "46.5%", text: "เอกสารและขั้นตอนการใช้สิทธิข้าราชการของผู้ป่วย", style: "miniFieldKey" },
              //         { width: "auto", text: "หมายเหตุ", style: "miniFieldKey" },
              //       ],
              //     },
              //     {
              //       columns: [
              //         { width: "46.5%", text: "สิทธิเบิกจ่ายตรง", style: "miniFieldValue" },
              //         { width: "auto", text: "ปัญหาที่เกิดขึ้นเกี่ยวกับสิทธิราชการคือ", style: "miniFieldValue" },
              //       ],
              //     },
              //     {
              //       columns: [
              //         {
              //           // to treat a paragraph as a bulleted list, set an array of items under the ul key
              //           width: "46.5%",
              //           margin: [2.5, 0, 0, 0],
              //           ul: [
              //             {
              //               stack: [
              //                 { text: "ผู้ป่วยใหม่-เก่า ติดต่อประชาสัมพันธ์เพื่อแจ้งสิทธิ", style: "miniFieldValue" },
              //                 { text: "พร้อมบัตรประชาชนก่อนพบทันตแพทย์", style: "miniFieldValue" },
              //               ],
              //             },
              //             { text: "ผู้ป่วยใหม่-เก่า นำบัตรประชาชนมาด้วยทุกครั้งที่ต้องชำระเงิน", style: "miniFieldValue" },
              //           ],
              //         },
              //         {
              //           width: "auto",
              //           margin: [2.5, 0, 0, 0],
              //           ul: [
              //             { text: "สิทธิหมดอายุ", style: "miniFieldValue" },
              //             { text: "สิทธิถูกยกเลิก", style: "miniFieldValue" },
              //             { text: "บัตรประชาชนหมดอายุ", style: "miniFieldValue" },
              //             { text: "ผู้ป่วยลืมบัตรประชาชน", style: "miniFieldValue" },
              //           ],
              //         },
              //       ],
              //     },
              //   ],
              // },
              {
                margin: [20, 3, 0, 3],
                stack: [
                  { text: "คำแนะนำ", style: "fieldKey" },
                  {
                    margin: [5.5, 0, 0, 0],
                    ul: (props.data.doctor_note || []).map((note: string) => ({ text: note, style: "miniFieldValue" })),
                  },
                ],
              },
            ],
          ],
        },
      },
    ],
    footer: {
      columns: [
        {
          width: 80,
          margin: [20, 2.5, 0, 0],
          stack: [
            {
              image: textToBase64BarcodeBlob(props.data?.patient_hn),
              width: 80,
              height: 35,
            },
            { margin: [20, -2.5, 0, 0], text: "HN", style: "fieldValue", alignment: "center", bold: true },
          ],
        },
        {
          width: "40%",
          margin: [40, 4, 0, 0],
          stack: [
            {
              text: [
                { text: `วัน-เวลาที่พิมพ์ ${formatDate(moment())} `, style: "miniFieldValue" },
                { text: moment().format("HH:mm"), style: "miniFieldValue" },
              ],
            },
            { text: props.data?.staff || "", style: "miniFieldValue" },
          ],
        },
        {
          width: "*",
          margin: [40, 21, 0, 0],
          stack: [
            { text: "เลื่อนนัดกรุณาติดต่อในวันและเวลาราชการเท่านั้น", style: "miniFieldValue" },
            { text: "สอบถามค่ารักษาพยาบาล, นัดหมาย, ตอบแบบสอบถาม ถามทางไลน์", style: "miniFieldValue" },
          ],
        },
        {
          margin: [-50, -4, 0, 0],
          width: 40,
          stack: [
            { text: "ไลน์นัดหมาย", style: "miniFieldValue", alignment: "center" },
            {
              margin: [28, -5, 0, 0],
              image: "qrcode_line",
              width: 32.5,
              height: 32.5,
            },
          ],
        },
      ],
    },
    images: {
      logochula: origin + "/static/images/logochula.png",
      qrcode_line: origin + "/static/images/qrcode_appointment_line.png",
    },
  };
};

export default FormAppointmentDetail;
