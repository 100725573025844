import React, { useRef, useEffect, useState, MutableRefObject } from "react";
import { Dropdown, Form } from "semantic-ui-react";

import { ErrorMessage, ModConfirm } from "react-lib/apps/common";

type CardDuplicateDrugOrdersProps = {
  duplicateReasonsOptions: {
    key: string;
    text: string;
    value: string | number;
  }[];
  duplicateList: {
    drug_name: string;
    duplicated_details: string;
    first_duplicated_item: string;
    duplicated_note: string;
    product: string[];
  }[];
  onClose: Function;
  onApprove: Function;
};

const CardDuplicateDrugOrders: React.FunctionComponent<
  CardDuplicateDrugOrdersProps
> = (props) => {
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [duplicateNote, setDuplicateNote] = useState<any>("");
  const [errorMessage, setErrorMessage] = useState<any>(null);

  const dropdownRef = useRef() as MutableRefObject<any>;

  const handleConfirm = () => {
    if (!isConfirm) {
      setIsConfirm(true);
      setTimeout(() => {
        dropdownRef.current?.ref?.current?.click();
      }, 200);
    } else {
      const note =
        props.duplicateReasonsOptions.find(
          (item) => item.value === duplicateNote
        )?.text || "";

      const firstNote = props.duplicateList[0].duplicated_note;

      if (!note && !firstNote) {
        return setErrorMessage("กรุณาระบุ เหตุผลที่สั่งยาซ้ำ");
      }

      props.onApprove?.({
        duplicated_note: note || firstNote,
      });
    }
  };

  return (
    <div>
      <ModConfirm
        openModal={true}
        titleName={
          isConfirm ? "ยืนยันการสั่งซ้ำ" : "พบรายการสั่งยาซ้ำตาม Generic Name"
        }
        titleColor={isConfirm ? "blue" : "orange"}
        size="small"
        approveButtonText={isConfirm ? "ใช่" : "ยืนยัน"}
        denyButtonText={isConfirm ? "ยกเลิก" : "ไม่"}
        onApprove={handleConfirm}
        onDeny={props.onClose}
        onCloseWithDimmerClick={props.onClose}
        content={
          <>
            <ErrorMessage error={errorMessage} />
            {(props.duplicateList || []).map((item: any) => (
              <div
                className="ui"
                style={{ whiteSpace: "pre-wrap", lineHeight: 1.75 }}
              >
                <div dangerouslySetInnerHTML={{ __html: item.drug_name }} />
                <div
                  dangerouslySetInnerHTML={{ __html: item.duplicated_details }}
                />
              </div>
            ))}

            <br />
            {isConfirm ? (
              <Dropdown
                ref={dropdownRef}
                placeholder="กรุณาระบุเหตุผลการสั่งซ้ำ"
                value={duplicateNote}
                selection={true}
                fluid
                options={props.duplicateReasonsOptions || []}
                onChange={(e, v) => setDuplicateNote(v.value)}
              />
            ) : (
              <strong>ต้องการสั่งหรือไม่</strong>
            )}
          </>
        }
      />
    </div>
  );
};

export default React.memo(CardDuplicateDrugOrders);
