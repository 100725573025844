import React, { useState, useEffect, useRef, useMemo, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import CardLayout from "../common/CardLayout";
import { Form, Button, Grid, Input, Confirm, Icon, Modal, Radio } from "semantic-ui-react";
import DateTextBox from "../common/DateTextBox";
import ReactTable from "react-table-6";
import { Bar } from "react-chartjs-2";
import SearchInput from "../common/SearchInput";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
import "../../css/ReactTableFixedColumns.css";
import { toast } from "react-toastify";
import TimeTextBox from "../common/TimeTextBox";
import ModInfo from "../common/ModInfo";
import ModalVerifyUser from "../LAB/ModalVerifyUser";
import ErrorMessage from "../common/ErrorMessage";
import ComboBox from "../common/ComboBox";
import { formatUTCtoBEString } from "react-lib/utils/dateUtils";
import moment from "moment";
import $ from "jquery";
import { formatDate } from "../../utils/dateUtils";
import { adToBe, beToAd } from "../../utils";
import { start } from "repl";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const CardVitalSign = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModInfo, setShowModInfo] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertTitleText, setAlertTitleText] = useState("");
  const [alertTitleColor, setAlertTitleColor] = useState("yellow");
  const [isShowDuplicateDialog, setIsShowDuplicateDialog] = useState(false);
  const [messageDupDialog, setMessageDupDialog] = useState("");
  const [vitalSignType, setVitalSignType] = useState([]);
  const [vitalSign, setVitalSign] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [offset, setOffset] = useState("0");
  const [tableColumnData, setTableColumnData] = useState([]);
  const [tableRowData, setTableRowData] = useState([]);
  const [vitalSignGraphData, setVitalSignGraph] = useState([]);
  const [chkDateSearch, setChkDateSearch] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [chkTimeSearch, setChkTimeSearch] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [grapLabel, setChartLabel] = useState([]);
  const [chkSave, setChkSave] = useState(false);
  const [saveDate, setSaveDate] = useState(formatDate(new Date()));
  const [saveTime, setSaveTime] = useState("");
  const [rowSelected, setRowSelected] = useState({});
  const [selectedCell, setSelectedCell] = useState({});
  const [originalTableRowData, setOriginalTableRowData] = useState([]);
  const [authmodalOpen, setAuthModalOpen] = useState(false);
  const [delMeasureId, setDeleteMeasureId] = useState(null);
  const [authErrorsMessage, setAuthErrorsMessage] = useState(null);
  const [cancelUser, setCancelUser] = useState("");
  const [cancelPassword, setCancelPassword] = useState("");
  const [lastHeight, setLastHeight] = useState("");

  const [dropdownVitalSignType, setDropdownVitalSignType] = useState("");
  const [radioCheckedDate, setRadioCheckedDate] = useState<any>("current");

  const NUMBER_LENGTH = 6;
  const RESULT_TYPE_INT = "INT";
  const RESULT_TYPE_DECIMAL = "DECIMAL";
  const DUPLICATE_SAVE_TRESHOLD = 1; // Minutes of mark duplication save

  const TYPE_WEIGHT = 3;
  const TYPE_HEIGHT = 2;
  const TYPE_BMI = 1;

  const myRef = useRef(null);
  const isMounted = React.useRef(false);
  //input
  const inputMeasuredValueRef = React.useRef<any>();

  useImperativeHandle(ref, () => ({
    onValidateDuplicationSave: () => {
      validateDuplicationSave();
    },
  }));

  function compare_vitalsign_obj(arr1, original) {
    var arr1Copy = JSON.parse(JSON.stringify(arr1));
    for (var i = 0; i < original.length; i++) {
      // console.log(arr1Copy[i]);

      // delete same value in each object
      for (const [key, value] of Object.entries(arr1Copy[i])) {
        // console.log(key, value, original[i][key]);
        if (key === "newVitalSign" && arr1Copy[i].vitalsign_type === TYPE_WEIGHT) {
          if (arr1Copy[i][key] === "NA") {
            delete arr1Copy[i][key];
          }
        } else if (key === "newVitalSign" && arr1Copy[i].vitalsign_type === TYPE_HEIGHT) {
          if (arr1Copy[i][key] === "NA") {
            delete arr1Copy[i][key];
          }
        } else if (JSON.stringify(original[i][key]) === JSON.stringify(value)) {
          delete arr1Copy[i][key];
        }
      }
      arr1Copy[i]["meta"] = {};
      arr1Copy[i]["meta"]["name"] = original[i]["name"];
      arr1Copy[i]["meta"]["vitalsign_type"] = original[i]["vitalsign_type"];
    }
    return arr1Copy;
  }

  const validateData = (value, type) => {
    if (type == RESULT_TYPE_DECIMAL || type == RESULT_TYPE_INT) {
      if (isNaN(Number(value))) {
        setAlertTitleColor("red");
        setAlertText("");
        setAlertTitleText("กรุณาระบุค่าที่เป็นตัวเลข");
        setShowModInfo(true);
        return false;
      }
      if (Number(value) < 0) {
        setAlertTitleColor("red");
        setAlertText("");
        setAlertTitleText("ไม่สามารถระบุเป็นจำนวนติดลบได้");
        setShowModInfo(true);
        return false;
      }
      var exceedLength = value
        .split(".")
        .concat([""])
        .reduce(function (a, b) {
          if (a.length > NUMBER_LENGTH || b.length > NUMBER_LENGTH) {
            return true;
          } else {
            return false;
          }
        });
      if (exceedLength) {
        setAlertTitleColor("red");
        setAlertText("");
        setAlertTitleText("ไม่สามารถระบุจำนวนเกิน " + NUMBER_LENGTH + " หลักได้");
        setShowModInfo(true);
        return false;
      }
      // if(type == RESULT_TYPE_INT && Number(value) % 1 !== 0){
      //     modInfo.titleName = 'ต้องระบุเป็นจำนวนเต็ม'
      //     modInfo.titleColor = 'red'
      //     modInfo.txtContent = ''
      //     modInfo.show()
      //     return false
      // }
    }
    return true;
  };

  const getBMI = (newTableRowData, isNew, measurement_id) => {
    // Calculate BMI
    const weights = newTableRowData.find((member) => {
      return member.vitalsign_type == TYPE_WEIGHT;
    });
    const heights = newTableRowData.find((member) => {
      return member.vitalsign_type == TYPE_HEIGHT;
    });
    console.log("--getBMI,weights,heights", weights, heights);

    var weight = null;
    var height = null;
    if (isNew) {
      weight = parseFloat(weights.newVitalSign);
      height = parseFloat(heights.newVitalSign);
    } else {
      weight = parseFloat(weights[measurement_id].formatted_string);
      height = parseFloat(heights[measurement_id].formatted_string);
    }

    var bmi = calBMI(weight, height);
    return bmi;
  };

  const calBMI = (weight, height) => {
    height /= 100;
    var bmi = weight / (height * height);
    bmi = isFinite(bmi) ? parseFloat(bmi).toFixed(2) : "";

    return bmi;
  };

  const handleOpen = () => {
    setAuthModalOpen(true);
  };

  const handleSubmitVerify = async () => {
    setIsLoading(true);
    setAuthErrorsMessage(null);
    var params = {};
    params.username = cancelUser;
    params.password = cancelPassword;
    params.active = false;
    params.measurement_id = delMeasureId;

    if (props.controller) {
      var [response, errors, network] = await props.controller.deleteMeasurement(delMeasureId, params);
    } else {
      errors = await props.onDeleteMeasurement(delMeasureId, params);
    }

    if (isMounted.current) {
      if (errors) {
        setIsLoading(false);
        console.log(errors);
        setAuthErrorsMessage(errors);
      } else {
        getVitalSign(20, 0);
        setAuthModalOpen(false);
        setIsLoading(false);
        setDeleteMeasureId(null);
      }
    }
  };

  const handleModVerifyUserCancel = () => {
    setAuthModalOpen(false);
  };

  const dismissDupDialog = () => {
    setIsShowDuplicateDialog(false);
  };

  const validateDuplicationSave = () => {
    var showPopup = false;

    tableRowData.forEach(function iterator(item) {
      for (const [key, value] of Object.entries(item)) {
        var lastSaveDate = moment(key, "DD-MM-YYYY hh:mm");
        if (lastSaveDate._isValid) {
          lastSaveDate.add(-543, "years"); //convert to Buddhist year
          if (moment().diff(lastSaveDate, "minutes") < DUPLICATE_SAVE_TRESHOLD) {
            showPopup = true;
          }
        }
      }
    });

    if (showPopup) {
      var txt = "มีข้อมูล Vital Sign ถูกบันทึกไปแล้วในระบบภายในระยะเวลา ";
      txt += DUPLICATE_SAVE_TRESHOLD;
      txt += " นาที ต้องการบันทึกข้อมูลซ้ำหรือไม่";
      setMessageDupDialog(txt);
      setIsShowDuplicateDialog(true);
    } else {
      saveVitalSign();
    }
  };

  const isNewVitalSignUpdate = (vital_signs) => {
    for (var i = 0; i < vital_signs.length; i++) {
      if (vital_signs[i].vitalsign_type !== TYPE_HEIGHT && vital_signs[i].vitalsign_type !== TYPE_WEIGHT) {
        return true;
      }
    }
    return false;
  };

  const isMultiMeasureUpdate = (update_list) => {
    var firstMeasureId = null;
    for (var i = 0; i < update_list.length; i++) {
      if (i === 0) {
        firstMeasureId = update_list[i].measurement;
      }

      if (firstMeasureId !== update_list[i].measurement) {
        return true;
      }
    }
    return false;
  };

  const saveVitalSign = async () => {
    setIsLoading(true);
    console.log(vitalSign);
    console.log(tableRowData);
    console.log(originalTableRowData);

    var isModified = !(JSON.stringify(tableRowData) === JSON.stringify(originalTableRowData));
    var diff = isModified ? compare_vitalsign_obj(tableRowData, originalTableRowData) : [];

    console.log("isModified: ", isModified);
    console.log("diff: ", diff);

    var vital_signs = [];
    var params = {};
    //{items: [{vitalsign_type: 1, result: "59.0", measurement: 336371, id: 2654441}]}
    var update_list = [];
    var update_params = {};
    if (radioCheckedDate === "manual") {
      params.date = saveDate;
      if (parseInt(saveTime.split(":")[0]) <= 6) {
        params.date = beToAd(params.date).subtract(moment.duration({ days: 1 }));
        params.date = adToBe(params.date, "YYYY-MM-DD");
      }
      params.time = moment(saveTime, "HH:mm")
        .subtract(moment.duration({ hour: 7, minutes: 0 }))
        .format("HH:mm");
    } else {
      params.date = null;
      params.time = null;
    }
    params.encounter = vitalSign[0] === undefined ? null : vitalSign[0].encounter;

    diff.forEach(function iterator(item) {
      for (const [key, value] of Object.entries(item)) {
        if (key != "meta") {
          if (key === "newVitalSign") {
            var newMeasure = {};
            newMeasure.vitalsign_type = item["meta"]["vitalsign_type"];
            newMeasure.result = value;
            vital_signs.push(newMeasure);
          } else {
            console.log("Update:", item["meta"], key, value);
            // update previous result
            var updateMeasure = {};
            updateMeasure.id = value.id;
            updateMeasure.vitalsign_type = item["meta"]["vitalsign_type"];
            updateMeasure.result = value.formatted_string;
            updateMeasure.measurement = value.measurement_id;
            update_list.push(updateMeasure);
          }
        }
      }
    });

    if (props.showBottomLeftForm && inputMeasuredValueRef.current) {
      const value = inputMeasuredValueRef.current.value;
      if (value && dropdownVitalSignType) {
        if (radioCheckedDate === "current") {
          vital_signs.push({
            vitalsign_type: dropdownVitalSignType,
            result: value,
          });
        }
        if (radioCheckedDate === "last") {
          const findVitalSign = originalTableRowData.find((row) => row.vitalsign_type === dropdownVitalSignType);
          const last = Object.keys(findVitalSign)
            .reverse()
            .filter((key) => {
              return +key;
            });
          const data = findVitalSign[last[0]];
          if (data) {
            update_list.push({
              id: data.id,
              vitalsign_type: dropdownVitalSignType,
              result: value,
              measurement: data.measurement_id,
            });
          } else {
            vital_signs.push({
              vitalsign_type: dropdownVitalSignType,
              result: value,
            });
          }
        }
        if (radioCheckedDate === "manual") {
          let measurementTarget = vitalSign.find((item) => item.date === params.date && item.time === params.time);
          if (measurementTarget) {
            let vitalSignId = measurementTarget.vital_signs.find((i) => i.vitalsign_type === dropdownVitalSignType)?.id || null;
            update_list.push({
              id: vitalSignId,
              vitalsign_type: dropdownVitalSignType,
              result: value,
              measurement: measurementTarget.id,
            });
          } else {
            vital_signs.push({
              vitalsign_type: dropdownVitalSignType,
              result: value,
            });
          }
        }
      }
      inputMeasuredValueRef.current.value = "";
      setRadioCheckedDate("current");
      setDropdownVitalSignType("");
    }

    if (radioCheckedDate === "manual") {
      if (params.date && params.time) {
        let measurementTarget = vitalSign.find((item) => item.date === params.date && item.time === params.time);
        if (measurementTarget) {
          vital_signs.forEach((item) => {
            let vitalSignId = measurementTarget.vital_signs.find((i) => i.vitalsign_type === item.vitalsign_type)?.id || null;
            update_list.push({ ...item, id: vitalSignId, measurement: measurementTarget.id });
          });
          vital_signs = [];
        }
      }
    }

    // if input the last column without date and select choice "last"
    if (radioCheckedDate === "last" && vitalSign.length > 0) {
      let lastMeasurement = vitalSign.reverse()[0];
      console.log("Last Measurement:", lastMeasurement);
      vital_signs.forEach((item) => {
        let idMeasurement = lastMeasurement.vital_signs.find((i) => i.vitalsign_type === item.vitalsign_type)?.id || null;
        update_list.push({ ...item, id: idMeasurement, measurement: lastMeasurement.id });
      });
      vital_signs = [];
    }

    console.log("vital sign data: ", vital_signs, update_list);

    if (isMultiMeasureUpdate(update_list)) {
      setIsLoading(false);
      setAlertTitleColor("yellow");
      setAlertText("ไม่สามารถแก้ไขหลาย measurement ในครั้งเดียวได้");
      setAlertTitleText("-");
      setShowModInfo(true);
      return;
    }

    var fetchReq = false;
    console.log(vital_signs);
    if (isNewVitalSignUpdate(vital_signs)) {
      params.vital_signs = vital_signs;
      console.log(params);
      if (props.encounterId) {
        params.encounter = props.encounterId;
      }
      if (props.controller) {
        var [response, errors, network] = await props.controller.saveNewMeasurement(params);
      } else {
        errors = await props.onNewMeasurement(params); //เรียกจริง
      }
      if (isMounted.current) {
        if (errors) {
          console.log("vitalsign errors:", errors);
          setAlertTitleColor("red");
          setAlertText(errors);
          setAlertTitleText("เกิดข้อผิดพลาด");
          setShowModInfo(true);
        }
        fetchReq = true;
      } else {
        return;
      }
    }
    console.log(tableRowData, originalTableRowData, "56");
    if (update_list.length > 0) {
      update_params.items = update_list;
      console.log(update_params);
      if (props.controller) {
        var [response, update_errors, network] = await props.controller.updateVitalSign(update_params);
      } else {
        update_errors = await props.onUpdateVitalsignValue(update_params); //เรียกจริง
      }
      if (isMounted.current) {
        console.log(update_errors);
        if (update_errors) {
          setAlertTitleColor("red");
          setAlertText(update_errors);
          setAlertTitleText("เกิดข้อผิดพลาด");
          setShowModInfo(true);
        }
        fetchReq = true;
      } else {
        return;
      }
    }

    if (isMounted.current) {
      if (fetchReq) {
        getVitalSign(20, 0);
        setIsShowDuplicateDialog(false);
      }
      setIsLoading(false);
    }
  };

  const getVitalSign = async (limit, offset) => {
    setIsLoading(true);
    if (props.controller && props.patientId) {
      var [lastHeightData, lastHeightError] = await props.controller.getLastHeight(props.patientId);
    } else {
      [lastHeightData, lastHeightError] = await props.onGetLastHeight();
    }
    if (isMounted.current) {
      if (lastHeightError) {
        toast.error(lastHeightError);
      } else if (lastHeightData && isMounted.current) {
        setLastHeight(lastHeightData);
        console.log("setLastHeight...");
      }
    } else {
      return;
    }

    if (props.controller && props.encounterId) {
      var [vitalSignTypeData, vitalSignTypeError, network] = await props.controller.getVitalSignType({
        encounterId: props.encounterId,
      });
    } else {
      [vitalSignTypeData, vitalSignTypeError, network] = await props.onGetVitalSignType();
    }

    if (!isMounted.current) {
      return;
    }
    console.log(vitalSignTypeData, "TYPE");
    if (vitalSignTypeError) {
      toast.error(vitalSignTypeError);
    } else if (vitalSignTypeData && isMounted.current) {
      setVitalSignType(vitalSignTypeData);
    }

    let params = {};
    if (limit && offset) {
      params.limit = limit;
      params.offset = offset;
    }
    if (chkDateSearch) {
      params.startDate = startDate;
      params.endDate = endDate;
    }
    if (chkTimeSearch) {
      params.startTime = startTime;
      params.endTime = endTime;
    }

    if (props.encounterId) {
      params.encounterId = props.encounterId;
    }

    console.log("onGetVitalSign...");
    if (props.controller) {
      var [vitalSignData, vitalSignError] = await props.controller.getVitalSign(params);
    } else {
      [vitalSignData, vitalSignError] = await props.onGetVitalSign(params);
    }
    console.log(vitalSignData);
    if (isMounted.current) {
      if (vitalSignError) {
        toast.error(vitalSignError);
      } else if (vitalSignData) {
        setNextUrl(vitalSignData.next);
        if (vitalSign.length <= 0 || offset <= 0) {
          setVitalSign(vitalSignData.items.reverse());
        } else {
          var appendVitalSing = vitalSignData.items.reverse().concat(vitalSign);
          setVitalSign(appendVitalSing);
        }
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    isMounted.current = true;

    setSaveTime(moment().format("HH:mm"));
    setSaveDate(formatDate(new Date()));

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.encounterId) {
      getVitalSign(20, 0);
    } else {
      setVitalSign([]);
    }
  }, [props.encounterId]);

  useEffect(() => {
    if (!props.callGetVitalSign) {
      return;
    }
    getVitalSign(20, 0);
  }, [props.callGetVitalSign]);

  useEffect(() => {
    createTableHeader();
    createTableRow();

    if (vitalSign && vitalSign.length > 0) {
      createChartLabel();
      createGraphData();
    } else {
      // setTableColumnData([])
      // setTableRowData([])
      setVitalSignGraph([]);
      setChkDateSearch(false);
      setStartDate(null);
      setEndDate(null);
      setChkTimeSearch(false);
      setStartTime(null);
      setEndTime(null);
      setChartLabel([]);
    }
  }, [vitalSign]);

  useEffect(() => {
    createTableHeader();

    const timer = setTimeout(() => {
      scrollToRight();
    }, 2000);
    return () => clearTimeout(timer);
  }, [selectedCell, originalTableRowData, tableRowData]);

  const handleNextVitalSign = () => {
    if (nextUrl != null) {
      console.log("loading more");
      const urlParams = new URLSearchParams(nextUrl);
      const _offset = urlParams.get("offset");
      const limit = 20;
      if (_offset >= offset) {
        setOffset(_offset);
        getVitalSign(limit, _offset);
      }
    }
  };

  const scrollToRight = () => {
    var rtTable = $("." + myRef.current.uniqClassName + " > .rt-table").first();
    var rtTBody = $("." + myRef.current.uniqClassName + " .rt-tbody").first();
    rtTable.scrollLeft(rtTBody.width() - rtTable.width());
  };

  const createGraphData = () => {
    let graphShowList = vitalSignType
      .filter((items) => {
        return items.graph_shown === true;
      })
      .map((items) => items.name);

    // let graphContinueList = vitalSignType.filter((items) => {
    //   return items.continue_default === true
    // }).map(items => items.name)

    // backend ref:
    // continue_default = models.BooleanField(default=False, help_text='ตั้งค่า Default ให้อัตโนมัติ?')

    var graphDataSet = [];
    vitalSign.map((vitalSignDataItem) => {
      vitalSignDataItem.vital_signs.map((vitalSignItem) => {
        let dateTime = `${vitalSignDataItem.date}\n${vitalSignDataItem.time}`;
        if (props.useUTCTime && vitalSignDataItem.date_time_utc) {
          const [dateComponent, timeComponent] = formatUTCtoBEString(vitalSignDataItem.date_time_utc);
          dateTime = `${dateComponent}\n${timeComponent}`;
        }

        let graphData = [{ x: dateTime, y: vitalSignItem.formatted_string }];
        if (!graphShowList.includes(vitalSignItem.name)) {
          return;
        }
        let sameField = graphDataSet.find((obj) => obj.origin_name === vitalSignItem.name);
        if (sameField) {
          let diastolic = vitalSignDataItem.vital_signs.find((items) => items.name === "Diastolic");
          if (vitalSignItem.name === "Systolic") {
            if (diastolic) {
              sameField.data.push({
                x: dateTime,
                y: parseInt(vitalSignItem.formatted_string) - parseInt(diastolic.formatted_string),
              });
            }
          } else {
            sameField.data.push(...graphData);
          }
        } else {
          let label = vitalSignItem.name + "(" + vitalSignItem.unit + ")";
          let lineColor = vitalSignType.find((obj) => obj.name === vitalSignItem.name).graph_color;
          if (vitalSignItem.name === "Systolic") {
            let diastolic = vitalSignDataItem.vital_signs.find((items) => items.name === "Diastolic");
            if (diastolic) {
              label = vitalSignItem.name + "/" + diastolic.name + "(" + vitalSignItem.unit + ")";
              graphData = [
                {
                  x: dateTime,
                  y: parseInt(vitalSignItem.formatted_string) - parseInt(diastolic.formatted_string),
                },
              ];
            }
          }

          sameField = {
            label: label,
            origin_name: vitalSignItem.name,
            type: ["Systolic", "Diastolic"].includes(vitalSignItem.name) ? "bar" : "line",
            stack: ["Systolic", "Diastolic"].includes(vitalSignItem.name) ? "Stack 0" : null,
            fill: false,
            backgroundColor: vitalSignItem.name === "Diastolic" ? "rgba(255, 255, 255, 0.1)" : lineColor,
            borderColor: vitalSignItem.name === "Diastolic" ? "rgba(255, 255, 255, 0.1)" : lineColor,
            showLine: true,
            data: [].concat(graphData),
          };
          let systolic = graphDataSet.find((items) => items.label.includes("Systolic"));
          let systolicIndex = 0;
          if (systolic) {
            systolicIndex = graphDataSet.findIndex((item) => item.label === systolic.label);
            graphDataSet.splice(systolicIndex, 0, sameField);
          } else {
            graphDataSet.push(sameField);
          }
        }
      });
    });
    setVitalSignGraph(graphDataSet);
  };

  const createTableHeader = () => {
    let headerColumn = [
      {
        Header: "Vital Sign",
        accessor: "name",
        fixed: "left",
        resizable: false,
        width: 200,
      },
    ];
    vitalSign.map((vitalSignDataItem) => {
      let dateTime = `${vitalSignDataItem.date} ${vitalSignDataItem.time}`;
      if (props.useUTCTime && vitalSignDataItem.date_time_utc) {
        const [dateComponent, timeComponent] = formatUTCtoBEString(vitalSignDataItem.date_time_utc);
        dateTime = `${dateComponent} ${timeComponent}`;
      }
      let measurement_id = vitalSignDataItem.id + "";
      headerColumn.push({
        Header: dateTime,
        Header: (props) => {
          return (
            <div>
              <label>{dateTime}</label>
              <Icon
                name="cancel"
                inverted
                color="red"
                link={true}
                onClick={async () => {
                  setDeleteMeasureId(measurement_id);
                  handleOpen();
                }}
              />
            </div>
          );
        },
        accessor: measurement_id,
        className: "noPadding",
        resizable: false,
        width: 90,
        getProps: (state, rowInfo, column) => {
          if (rowInfo) {
            var origiRowData = originalTableRowData[rowInfo.index];
            if (origiRowData && origiRowData[measurement_id]) {
              if (origiRowData[measurement_id].formatted_string !== rowInfo.original[measurement_id].formatted_string) {
                return {
                  style: { background: "#FEFFC8" },
                };
              }
            }
          }
          return {
            style: { background: "#FFFFFF" },
          };
        },
        Cell: (props) => {
          let index = tableRowData.findIndex((items) => items.name === props.original.name);
          let newTableRowData = [...tableRowData];
          const preValidate = () => {
            if (props.original[measurement_id].formatted_string.length === 0) {
              if (index > -1) {
                newTableRowData[index][measurement_id] = {
                  ...newTableRowData[index][measurement_id],
                  ["formatted_string"]: "NA",
                };
                setTableRowData(newTableRowData);
              }
            } else {
              var validate_result = validateData(props.original[measurement_id].formatted_string, props.original.result_type);
              if (!validate_result) {
                newTableRowData[index][measurement_id] = {
                  ...newTableRowData[index][measurement_id],
                  ["formatted_string"]: originalTableRowData[index][measurement_id].formatted_string,
                };
                setTableRowData(newTableRowData);
              }
            }
            if ([TYPE_WEIGHT, TYPE_HEIGHT, TYPE_BMI].includes(newTableRowData[index].vitalsign_type)) {
              const bmis = newTableRowData.find((member) => {
                return member.vitalsign_type == TYPE_BMI;
              });
              // newTableRowData, isNew, measurement_id
              var bmi = getBMI(newTableRowData, false, measurement_id);
              bmis[measurement_id].formatted_string = bmi;
            }
            // setSelectedCell({});
          };

          if (selectedCell && selectedCell.hasOwnProperty(measurement_id) && props.index === selectedCell[measurement_id]) {
            return (
              <Input
                style={{ width: "inherit" }}
                value={props.original[measurement_id].formatted_string}
                autoFocus
                onChange={(e) => {
                  if (index > -1) {
                    newTableRowData[index][measurement_id] = {
                      ...newTableRowData[index][measurement_id],
                      ["formatted_string"]: e.target.value,
                    };

                    if ([TYPE_WEIGHT, TYPE_HEIGHT, TYPE_BMI].includes(newTableRowData[index].vitalsign_type)) {
                      const bmis = newTableRowData.find((member) => {
                        return member.vitalsign_type == TYPE_BMI;
                      });

                      // newTableRowData, isNew, measurement_id
                      var bmi = getBMI(newTableRowData, false, measurement_id);
                      bmis[measurement_id].formatted_string = bmi;
                    }

                    setTableRowData(newTableRowData);
                  }
                }}
                onFocus={(e) => {
                  // console.log("focus b", props.index , selectedCell);
                  // console.log(newTableRowData);
                  if (e.target.value === "NA") {
                    newTableRowData[index][measurement_id] = {
                      ...newTableRowData[index][measurement_id],
                      ["formatted_string"]: "",
                    };
                    setTableRowData(newTableRowData);
                  } else {
                    e.target.select();
                  }
                }}
                onBlur={(e) => {
                  setTimeout(() => {
                    preValidate();
                    setSelectedCell((pre) => {
                      if (pre !== selectedCell) {
                        return pre;
                      } else {
                        return {};
                      }
                    });
                  }, 100);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    console.log("Didn't call on development phase");
                    preValidate();
                  }
                }}
              />
            );
          }
          return (
            <div className="reactTablePadding" style={{ color: props.original[measurement_id].color }}>
              {props.original[measurement_id].formatted_string}
            </div>
          );
        },
      });
    });
    if (!props.readOnly) {
      headerColumn.push({
        Header: "New Vital Sign",
        accessor: "newVitalSign",
        className: "noPadding",
        getProps: (state, rowInfo, column) => {
          if (rowInfo) {
            if (originalTableRowData[rowInfo.index] && originalTableRowData[rowInfo.index].newVitalSign !== rowInfo.original.newVitalSign) {
              return {
                style: { background: "#CFFFC4" },
              };
            }
          }

          return {
            style: { background: "#FFFFFF" },
          };
        },
        Cell: (props) => {
          let index = tableRowData.findIndex((items) => items.name === props.original.name);
          let newTableRowData = [...tableRowData];
          const preValidate = () => {
            if (props.original.newVitalSign.length === 0 || props.original.newVitalSign === "NA") {
              if (index > -1) {
                newTableRowData[index] = {
                  ...newTableRowData[index],
                  newVitalSign: "NA",
                };
                setTableRowData(newTableRowData);
              }
            } else {
              var validate_result = validateData(props.original.newVitalSign, props.original.result_type);
              if (!validate_result) {
                newTableRowData[index] = {
                  ...newTableRowData[index],
                  newVitalSign: originalTableRowData[index].newVitalSign,
                };
                setTableRowData(newTableRowData);
              }
            }
            if ([TYPE_WEIGHT, TYPE_HEIGHT, TYPE_BMI].includes(newTableRowData[index].vitalsign_type)) {
              const bmis = newTableRowData.find((member) => {
                return member.vitalsign_type == TYPE_BMI;
              });
              //newTableRowData, dateTime, isNew
              if (bmis) {
                var bmi = getBMI(newTableRowData, true, null);
                bmis.newVitalSign = bmi;
              }
            }
            // setSelectedCell({});
          };
          if (selectedCell && selectedCell.hasOwnProperty("newVitalSign") && props.index === selectedCell.newVitalSign) {
            return (
              <Input
                style={{ width: "inherit" }}
                value={props.original.newVitalSign}
                autoFocus
                onChange={(e) => {
                  if (index > -1) {
                    newTableRowData[index] = {
                      ...newTableRowData[index],
                      newVitalSign: e.target.value,
                    };

                    if ([TYPE_WEIGHT, TYPE_HEIGHT, TYPE_BMI].includes(newTableRowData[index].vitalsign_type)) {
                      const bmis = newTableRowData.find((member) => {
                        return member.vitalsign_type == TYPE_BMI;
                      });
                      //newTableRowData, dateTime, isNew
                      if (bmis) {
                        var bmi = getBMI(newTableRowData, true, null);
                        bmis.newVitalSign = bmi;
                      }
                    }

                    setTableRowData(newTableRowData);
                  }
                }}
                onFocus={(e) => {
                  console.log("focus a");
                  if (e.target.value === "NA") {
                    newTableRowData[index] = {
                      ...newTableRowData[index],
                      newVitalSign: "",
                    };
                    setTableRowData(newTableRowData);
                  } else {
                    e.target.select();
                  }
                }}
                onBlur={(e) => {
                  setTimeout(() => {
                    preValidate();
                    setSelectedCell((pre) => {
                      if (pre !== selectedCell) {
                        return pre;
                      } else {
                        return {};
                      }
                    });
                  }, 100);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    console.log("Didn't call on development phase");
                    preValidate();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowDown" || e.key === "Enter") {
                    let nextIndex = index + 1;
                    if (tableRowData.length == nextIndex || tableRowData[nextIndex].vitalsign_type === TYPE_BMI) {
                      // TODO: If BMI skip to next row, but this is out focus (need both implement NewVitalSignCell and VitalSign)
                      setSelectedCell({});
                    } else {
                      setSelectedCell({ newVitalSign: nextIndex });
                    }
                  } else if (e.key === "ArrowUp") {
                    let previousIndex = index - 1;
                    if (previousIndex < 0 || tableRowData[previousIndex].vitalsign_type === TYPE_BMI) {
                      setSelectedCell({});
                    } else {
                      setSelectedCell({ newVitalSign: previousIndex });
                    }
                  }
                }}
              />
            );
          }
          return <div className="reactTablePadding">{props.original.newVitalSign}</div>;
        },
      });
    }
    setTableColumnData(headerColumn);
  };

  const createTableRow = () => {
    var rowData = [];
    const minField = props.minField || "min_ref"
    const maxField = props.maxField || "max_ref";

    vitalSignType.sort((a, b) => (a.display_seq > b.display_seq ? 1 : -1));

    for (let item of vitalSignType) {
      // console.log("item ", item);
      let info = { newVitalSign: "NA" };
      vitalSign.map((vitalSignDataItem) => {
        let index = vitalSignDataItem.vital_signs.findIndex((items) => items.name === item.name);
        if (index > -1) {
          var item_obj = {};

          item_obj.id = vitalSignDataItem.vital_signs[index].id;
          item_obj.formatted_string = vitalSignDataItem.vital_signs[index].formatted_string;
          item_obj.measurement_id = vitalSignDataItem.id;
          item_obj.color = "#000000";
          // if(vitalSignDataItem.vital_signs[index] && vitalSignDataItem.vital_signs[index].level && vitalSignDataItem.vital_signs[index].max_color && vitalSignDataItem.vital_signs[index].min_color){
          //   if(vitalSignDataItem.vital_signs[index].level === "สูง"){
          //     item_obj.color = vitalSignDataItem.vital_sign[index].max_color
          //   }
          //   if(vitalSignDataItem.vital_signs[index].level === "ต่ำ"){
          //     item_obj.color = vitalSignDataItem.vital_sign[index].min_color
          //   }
          // }
          // console.log("Card vitalSign color: ", item)
          console.log("vital sign item: ", parseFloat(vitalSignDataItem.vital_signs[index].result) > parseFloat(vitalSignDataItem.vital_signs[index][maxField]));

          if (
            vitalSignDataItem.vital_signs[index]?.result &&
            vitalSignDataItem.vital_signs[index]?.[maxField] 
          ) {
            if(parseFloat(vitalSignDataItem.vital_signs[index].result) > parseFloat(vitalSignDataItem.vital_signs[index][maxField])){
            item_obj.color = item.over_value_color;
            }
          } else if (vitalSignDataItem.vital_signs[index].level === "สูง") {
            item_obj.color = item.over_value_color;
          }

          if (
            vitalSignDataItem.vital_signs[index]?.result &&
            vitalSignDataItem.vital_signs[index]?.[minField] 
          ) {
            if(parseFloat(vitalSignDataItem.vital_signs[index].result) < parseFloat(vitalSignDataItem.vital_signs[index][minField])){
            item_obj.color = item.under_value_color;
            }
          } else if (vitalSignDataItem.vital_signs[index].level === "ต่ำ") {
            item_obj.color = item.under_value_color;
          }

          // info[vitalSignDataItem.date + ' ' + vitalSignDataItem.time] = item_obj
          info[vitalSignDataItem.id] = item_obj;
          // copy BMI, BW, Height to newVitalSign
          if ([TYPE_WEIGHT, TYPE_HEIGHT, TYPE_BMI].includes(vitalSignDataItem.vital_signs[index].vitalsign_type)) {
            if (vitalSignDataItem.vital_signs[index].vitalsign_type === TYPE_HEIGHT) {
              info.newVitalSign = item_obj.formatted_string ? item_obj.formatted_string : lastHeight;
            } else {
              info.newVitalSign = item_obj.formatted_string;
            }
          }
        } else {
          var item_obj = { formatted_string: "NA" };
          item_obj.measurement_id = vitalSignDataItem.id;
          // info[vitalSignDataItem.date + ' ' + vitalSignDataItem.time] = item_obj
          info[vitalSignDataItem.id] = item_obj;
        }
      });
      rowData.push({
        name: item.name + "(" + item.unit + ")",
        ...info,
        vitalsign_type: item.id,
        result_type: item.result_type,
      });
    }
    setOriginalTableRowData(JSON.parse(JSON.stringify(rowData))); // clone rowData
    setTableRowData(rowData);
  };

  const createChartLabel = () => {
    let chartLabel = [];
    vitalSign.map((vitalSignDataItem) => {
      let dateTime = `${vitalSignDataItem.date} ${vitalSignDataItem.time}`;
      if (props.useUTCTime && vitalSignDataItem.date_time_utc) {
        const [dateComponent, timeComponent] = formatUTCtoBEString(vitalSignDataItem.date_time_utc);
        dateTime = `${dateComponent}\n${timeComponent}`;
      }

      chartLabel.push(dateTime);
    });
    setChartLabel(chartLabel);
  };

  const generateLeftAxisLabel = () => {
    let label = "";
    vitalSignType.map((items) => {
      if (!items.graph_shown) {
        return;
      }
      if (items.y_axis === 1) {
        label += items.code + "  ";
      }
    });
    return label;
  };

  const generateRightAxisLabel = () => {
    let label = "";
    vitalSignType.map((items) => {
      if (!items.graph_shown) {
        return "";
      }
      if (items.y_axis === 2) {
        label += items.code + "  ";
      }
    });
    return label;
  };

  useEffect(() => {
    setCancelUser("");
    setCancelPassword("");
  }, [authmodalOpen]);
  // console.log(originalTableRowData, 'originalTableRowData')

  //"1127"
  const vitalSignTypeOptions = useMemo(() => {
    return vitalSignType.map((item, index) => ({
      key: index,
      text: `${item.name}(${item.unit})`,
      value: item.id,
    }));
  }, [vitalSignType]);

  console.log(vitalSignType);
  return (
    <CardLayout
      titleText="Vital Sign/ Neorulogical Sign"
      closeable={props.closeable}
      toggleable={props.toggleable}
      loading={isLoading}
      onClose={props.hideCallback}
      style={props.cardStyle}
      hideHeaderIcon={props.hideHeaderIcon}
      headerColor={props.headerColor}
    >
      <ModInfo open={showModInfo} titleColor={alertTitleColor} alertText={alertText} titleName={alertTitleText} onApprove={() => setShowModInfo(false)} />

      <Confirm
        open={isShowDuplicateDialog}
        onCancel={dismissDupDialog}
        onConfirm={saveVitalSign}
        size={"tiny"}
        content={messageDupDialog}
        // style = {{width:'400px'}}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        cancelButton={"ยกเลิก"}
        confirmButton={"ตกลง"}
      />

      <Modal open={authmodalOpen} onClose={handleModVerifyUserCancel} size="mini" closeIcon>
        <Modal.Content>
          <Form>
            <Form.Input placeholder="Username" name="name" value={cancelUser} label="Username" onChange={(e, item) => setCancelUser(item.value)} />
            <Form.Input
              placeholder="Password"
              name="email"
              value={cancelPassword}
              type="password"
              label="Password"
              onChange={(e, item) => setCancelPassword(item.value)}
            />
            <ErrorMessage style={{ width: "100%" }} error={authErrorsMessage} />
            <Button color="yellow" style={{ width: "100%" }} className={isLoading ? "ui button disabled loading" : "ui button"} onClick={handleSubmitVerify}>
              ยืนยัน ยกเลิกค่า Vital Sign
            </Button>
          </Form>
        </Modal.Content>
      </Modal>

      {/* <ModalVerifyUser
        handleSubmitVerify={handleSubmitVerify}
        open={authmodalOpen}
        onCancel={handleModVerifyUserCancel}
      /> */}
      {!props.hideFilter && (
        <Form>
          <Form.Group inline>
            <Form.Checkbox label="ค้นหาโดยระบุวันที่" checked={chkDateSearch} onChange={(e, { checked }) => setChkDateSearch(checked)} />
            <Form.Field>
              <DateTextBox value={startDate} onChange={(date) => setStartDate(date)} />
            </Form.Field>
            <Form.Field>ถึง</Form.Field>
            <Form.Field>
              <DateTextBox value={endDate} onChange={(date) => setEndDate(date)} />
            </Form.Field>
            <Form.Checkbox label="ค้นหาโดยระบุเวลา" checked={chkTimeSearch} onChange={(e, { checked }) => setChkTimeSearch(checked)} />
            <Form.Field>
              <SearchInput onTextChange={(startTime) => setStartTime(startTime)} />
            </Form.Field>
            <Form.Field>ถึง</Form.Field>
            <Form.Field>
              <SearchInput onTextChange={(endTime) => setEndTime(endTime)} />
            </Form.Field>
            <Button
              color="blue"
              onClick={(e) => {
                getVitalSign(20, 0);
              }}
            >
              ค้นหา
            </Button>
          </Form.Group>
        </Form>
      )}
      <Grid columns={props.hideBarChart ? 1 : 2}>
        {!props.hideBarChart && (
          <Grid.Column className="card-vital-sign-bar-chart">
            <Bar
              plugins={[]}
              data={{
                labels: grapLabel,
                datasets: vitalSignGraphData,
              }}
              options={{
                plugins: {
                  datalabels: {
                    display: false,
                  },
                },
                pan: {
                  // Boolean to enable panning
                  enabled: true,

                  // Panning directions. Remove the appropriate direction to disable
                  // Eg. 'y' would only allow panning in the y direction
                  mode: "xy",
                },

                // Container for zoom options
                zoom: {
                  // Boolean to enable zooming
                  enabled: true,

                  // Zooming directions. Remove the appropriate direction to disable
                  // Eg. 'y' would only allow zooming in the y direction
                  mode: "xy",
                },
                legend: {
                  labels: {
                    filter: function (item, chart) {
                      return item.text !== "Diastolic(mmHg)";
                    },
                  },
                },
                tooltips: {
                  mode: "single",
                  filter: function (tooltipItem, data) {
                    var label = data.datasets[tooltipItem.datasetIndex].label || "";
                    return label !== "Diastolic(mmHg)";
                  },
                  callbacks: {
                    label: function (tooltipItem, data) {
                      var label = data.datasets[tooltipItem.datasetIndex].label || "";
                      var diastolicData = data.datasets.find((items) => items.label === "Diastolic(mmHg)");
                      if (diastolicData) {
                        var diastolic = diastolicData.data[tooltipItem.index] ? diastolicData.data[tooltipItem.index].y : "";
                      }

                      if (label === "Diastolic(mmHg)") {
                        return;
                      }
                      if (label) {
                        label += ": ";
                      }
                      if (label.includes("Systolic")) {
                        label += parseInt(tooltipItem.yLabel) + parseInt(diastolic) + "/" + diastolic;
                      } else {
                        label += Math.round(tooltipItem.yLabel * 100) / 100;
                      }
                      return label;
                    },
                  },
                },
                scales: {
                  xAxes: [
                    {
                      categoryPercentage: 0.1,
                      maxBarThickness: 5,
                    },
                  ],
                  yAxes: [
                    {
                      id: 1,
                      type: "linear",
                      position: "left",
                      // scaleLabel: {
                      //   display: true,
                      //   labelString: generateLeftAxisLabel(),
                      // },
                    },
                    {
                      id: 2,
                      type: "linear",
                      position: "right",
                      scaleLabel: {
                        display: true,
                        labelString: generateRightAxisLabel(),
                      },
                    },
                  ],
                },
              }}
            />
          </Grid.Column>
        )}
        <Grid.Column>
          <ReactTableFixedColumns
            ref={myRef}
            style={{ height: "380px" }}
            showPagination={false}
            data={tableRowData}
            columns={tableColumnData}
            className={"fix-height"}
            pageSize={tableRowData ? (tableRowData.length < 9 ? 9 : tableRowData.length) : 9}
            getTrGroupProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  style: { height: "auto" },
                };
              } else {
                return {};
              }
            }}
            getTrProps={(state, rowInfo, column) => {
              if (rowInfo && rowInfo.row) {
                let background = rowInfo.index === (rowSelected ? rowSelected.index : null) ? "blueSelectedRow" : "";
                return {
                  onClick: (e) => {
                    if (rowInfo.id !== rowSelected.id) {
                      setRowSelected(rowInfo);
                    }
                  },
                  className: background,
                };
              } else {
                return {};
              }
            }}
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: (e, handleOriginal) => {
                  if (props.readOnly) {
                    return;
                  }
                  if (!rowInfo || !column) {
                    return;
                  }
                  if (rowInfo.original.vitalsign_type === TYPE_BMI) {
                    //BMI type
                    return;
                  }
                  if (column.id === "newVitalSign" && selectedCell.newVitalSign !== rowInfo.index) {
                    setSelectedCell({ [column.id]: rowInfo.index });
                  } else if (selectedCell[column.id] !== rowInfo.index) {
                    setSelectedCell({ [column.id]: rowInfo.index });
                  }
                },
                onKeyDown: (e) => {
                  if (e.key == "Enter" || e.key == "ArrowDown") {
                    let nextIndex = rowInfo.index + 1;
                    if (tableRowData.length == nextIndex || tableRowData[nextIndex].vitalsign_type === TYPE_BMI) {
                      setSelectedCell({});
                    } else {
                      setSelectedCell({ [column.id]: nextIndex });
                    }
                  } else if (e.key == "ArrowUp") {
                    let previousIndex = rowInfo.index - 1;
                    if (previousIndex < 0 || tableRowData[previousIndex].vitalsign_type === TYPE_BMI) {
                      setSelectedCell({});
                    } else {
                      setSelectedCell({ [column.id]: previousIndex });
                    }
                  }
                },
              };
            }}
          />
        </Grid.Column>
      </Grid>
      <Grid columns={2}>
        {props.showBottomLeftForm ? (
          <>
            <Grid.Column className="noPadding">
              <Form className="noPadding">
                <Form.Group inline style={{ justifyContent: "center" }}>
                  <Form.Field width={1}></Form.Field>

                  <Form.Field width={5}>
                    <label>ชนิด Vital Sign</label>
                    {/* <Input ref={(instance) => {
                    if (instance) {
                      inputVitalSignRef.current = instance.inputRef.current
                    }
                  }} /> */}
                    <ComboBox
                      options={vitalSignTypeOptions}
                      search={true}
                      selection={true}
                      clearable={true}
                      placeholder="เลือก"
                      fluid={true}
                      value={dropdownVitalSignType}
                      onChange={(e, { value }) => {
                        setDropdownVitalSignType(value);
                      }}
                    />
                  </Form.Field>
                  <Form.Field width={4}>
                    <label>ค่าที่วัดได้</label>
                    <Input
                      ref={(instance) => {
                        if (instance) {
                          inputMeasuredValueRef.current = instance.inputRef.current;
                        }
                      }}
                    />
                  </Form.Field>
                  <Form.Field width={3}>
                    <Radio
                      label="วันที่เวลาปัจุบัน"
                      name="radioGroup"
                      value="current"
                      checked={radioCheckedDate === "current"}
                      onChange={(e, { value }) => {
                        setRadioCheckedDate(value);
                      }}
                    />
                  </Form.Field>
                  <Form.Field width={3}>
                    <Radio
                      label="วันเวลาการวัดล่าสุด"
                      name="radioGroup"
                      value="last"
                      checked={radioCheckedDate === "last"}
                      onChange={(e, { value }) => {
                        setRadioCheckedDate(value);
                      }}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </>
        ) : (
          !props.hideFooter && (
            <Grid.Column className="noPadding">
              <Form className="noPadding">
                <Form.Group inline>
                  <Button color="blue" onClick={handleNextVitalSign}>
                    ก่อนหน้า
                  </Button>
                </Form.Group>
              </Form>
            </Grid.Column>
          )
        )}

        {!props.readOnly && !props.hideFooter && (
          <Grid.Column className="noPadding">
            <Form className="noPadding">
              <Form.Group inline>
                <Form.Field>
                  <Radio
                    label="วัน/เวลา ที่บันทึก(ระบุเอง)"
                    name="radioGroup"
                    value="manual"
                    checked={radioCheckedDate === "manual"}
                    onChange={(e, { value }) => {
                      if (value === radioCheckedDate && !props.showBottomLeftForm) {
                        setRadioCheckedDate("current");
                      } else {
                        setRadioCheckedDate(value);
                      }
                    }}
                  />
                  {/* <Form.Checkbox label="วัน/เวลา ที่บันทึก(ระบุเอง)" style={{ fontWeight: "bold" }} onChange={(e, { checked }) => setChkSave(checked)} /> */}
                </Form.Field>
                <Form.Field>วันที่</Form.Field>
                <Form.Field width={3}>
                  <DateTextBox
                    value={saveDate}
                    onChange={(date) => setSaveDate(date)}
                    disabled={radioCheckedDate !== "manual"}
                    maxDate={formatDate(new Date())}
                  />
                </Form.Field>
                <Form.Field>เวลา</Form.Field>
                <Form.Field width={3}>
                  <TimeTextBox
                    style={{ width: "100%" }}
                    disabled={radioCheckedDate !== "manual"}
                    search={true}
                    value={saveTime}
                    onTimeChange={(e, value) => setSaveTime(value)}
                  />
                </Form.Field>
                <Button content="บันทึก" color="green" onClick={validateDuplicationSave} />
              </Form.Group>
            </Form>
          </Grid.Column>
        )}
      </Grid>
    </CardLayout>
  );
});

CardVitalSign.propTypes = {
  closeable: PropTypes.bool,
  toggleable: PropTypes.bool,
  onGetVitalSign: PropTypes.func,
  onGetVitalSignType: PropTypes.func,
  callOnDidMount: PropTypes.bool,
  showBottomLeftForm: PropTypes.bool,
  hideCallback: PropTypes.func,
  readOnly: PropTypes.bool,
  onNewMeasurement: PropTypes.func,
  onUpdateVitalsignValue: PropTypes.func,
  onDeleteMeasurement: PropTypes.func,
  onGetLastHeight: PropTypes.func,
  useUTCTime: PropTypes.bool,
  hideBarChart: PropTypes.bool,
  hideFilter: PropTypes.bool,
  hideFooter: PropTypes.bool,
  cardStyle: PropTypes.object,
  hideHeaderIcon: PropTypes.bool,
  headerColor: PropTypes.string,
  minField: PropTypes.string,
  maxField: PropTypes.string
};

CardVitalSign.defaultProps = {
  closeable: true,
  toggleable: true,
  onGetVitalSign: () => [null, null],
  onGetVitalSignType: () => [null, null],
  callOnDidMount: true,
  showBottomLeftForm: false,
  hideCallback: () => {},
  readOnly: false,
  onNewMeasurement: () => {},
  onGetLastHeight: () => [null, null],
  useUTCTime: false,
  hideBarChart: false,
  hideFilter: false,
  hideFooter: false,
  cardStyle: {},
  hideHeaderIcon: false,
  headerColor: "blue",
};

export default React.memo(CardVitalSign);
