import WasmController from "react-lib/frameworks/WasmController";

// APIs
// MSD
import SupplyList from "issara-sdk/apis/SupplyList_apps_MSD";
import SupplyOrderItemPreviewM from "issara-sdk/apis/SupplyOrderItemPreview_apps_MSDM"; // Retrieve api with no pk
import SupplyOrderPreview from "issara-sdk/apis/SupplyOrderPreview_apps_MSD";
import SupplyOrderList from "issara-sdk/apis/SupplyOrderList_apps_MSD";
import SupplyFavoriteUpdateView from "issara-sdk/apis/SupplyFavoriteUpdateView_apps_MSD";
import SupplyFavoriteUpdateViewM from "issara-sdk/apis/SupplyFavoriteUpdateView_apps_MSDM";
import SupplyOrderDetail from "issara-sdk/apis/SupplyOrderDetail_apps_MSD";

// Interface
import * as CoreI from "../../../../../HIS/Interface/CoreI";
import * as IsHealthInterface from "../../../../../HIS/IsHealth/IsHealthInterface";

const SUPPLY_ORDER_LIMIT = 7;

export type State = {
  django?: any;
  selectedEncounter?: any;
  selectedEmr?: any;
  OrderSupplySequence?: {
    sequenceIndex?: string | null;
    searchSupply?: string;
    searchMode?: string;
    searchManufacturer?: string;
    isFavorite?: boolean;
    manufacturerOptions?: any[];
    manufactureLoading?: boolean;
    manufactureHaveResult?: boolean;
    searchGroup?: string;
    supplyList?: any[];
    showSupplyList?: boolean;
    selectedSupply?: any;
    qty?: number;
    nameLoading?: boolean;
    supplyOrder?: {
      pk?: number | null;
      items?: any[];
      action?: string;
      encounter?: number;
      type?: string;
      claim_payload?: any;
      status?: string;
      code?: string;
      encounter_number?: string;
      order_div?: number;
      order_perform_div?: number;
      doctor?: string;
      note?: string;
    };
    historySupplyOrder?: any[];
    historySupplyActivePage?: number;
    historySupplyTotalPage?: number;
    searchFromHistorySearch?: string;
    searchToHistorySearch?: string;
    isUseRangeSearch?: boolean;
    searchBtnLoading?: boolean;
    searchHistorySelected?: number | null;
  } | null;
  successMessage?: any;
  errorMessage?: any;
  buttonLoadCheck?: any;
  loadingStatus?: any;
  DispensingOrderSupplySequence?: any;
} & CoreI.State &
  IsHealthInterface.State;

export const StateInitial: State = {
  OrderSupplySequence: null,
  successMessage: null,
  errorMessage: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
  divisionDetail?: any;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.OrderSupplySequence || !state.selectedEmr) return;

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["division", {}],
        ["divisionSupply", {}],
      ],
    },
  });
  console.log(" STARTING.... OrderSupply");
  const [r, e, n] = await SupplyOrderList.list({
    params: {
      limit: SUPPLY_ORDER_LIMIT,
      patient: state?.selectedEncounter?.patient,
    },
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
  });

  console.log("r", r);

  controller.setState({
    OrderSupplySequence: {
      ...state.OrderSupplySequence,
      sequenceIndex: "SearchAndEdit",
      searchSupply: "",
      searchMode: "ANY",
      searchManufacturer: "",
      manufacturerOptions: [],
      isFavorite: false,
      manufactureLoading: false,
      manufactureHaveResult: false,
      searchGroup: "ANY",
      supplyList: [],
      showSupplyList: false,
      nameLoading: false,
      selectedSupply: null,
      qty: 0,
      supplyOrder: {
        pk: 0,
        items: [],
        order_div: controller.data.division,
        order_perform_div: controller.data.divisionDetail?.supply_div,
      },
      historySupplyOrder: r?.items?.length > 0 ? r?.items : [],
      historySupplyActivePage: 1,
      historySupplyTotalPage: r?.total
        ? Math.ceil(r?.total / SUPPLY_ORDER_LIMIT)
        : 0,
      searchFromHistorySearch: "",
      searchToHistorySearch: "",
      isUseRangeSearch: false,
      searchBtnLoading: false,
      searchHistorySelected: null,
    },
  });
  console.log(" Finish .... OrderSupply");
};

export const SearchAndEdit: Handler = async (controller, params) => {
  var state = controller.getState();

  if (!state.OrderSupplySequence || !state.selectedEmr) return;

  if (params?.action === "selectedHistory") {
    const [r, e, n] = await SupplyOrderDetail.retrieve({
      pk: params?.original?.id,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (r) {
      console.log("selectedHistory", r);

      controller.setState(
        {
          OrderSupplySequence: {
            ...state.OrderSupplySequence,
            supplyOrder: {
              items: [...r?.items],
              pk: r?.id,
              type: r?.type,
              status: r?.status,
              code: r?.code,
              order_div: r?.order_div,
              order_perform_div: r?.order_perform_div,
              doctor: r?.doctor,
              encounter_number: r?.encounter_number,
            },
            searchHistorySelected: params?.original,
          },
        },
        () => {
          controller.handleEvent({
            message: "RunSequence",
            params: { sequence: "OrderSupply", action: "calc" },
          });
        }
      );
    }
  } else if (params?.action === "searchHistory") {
    controller.setState({
      OrderSupplySequence: {
        ...state.OrderSupplySequence,
        searchBtnLoading: true,
        searchHistorySelected: null,
      },
    });

    const [r, e, n] = await SupplyOrderList.list({
      params: {
        patient: state?.selectedEncounter?.patient,
        limit: SUPPLY_ORDER_LIMIT,
        ...(!params?.firstPage && {
          offset: (params?.toActivePage - 1) * SUPPLY_ORDER_LIMIT,
        }),
        ...(state.OrderSupplySequence?.searchFromHistorySearch &&
          state.OrderSupplySequence?.isUseRangeSearch && {
          order_from_date: state.OrderSupplySequence?.searchFromHistorySearch,
        }),
        ...(state.OrderSupplySequence?.searchToHistorySearch &&
          state.OrderSupplySequence?.isUseRangeSearch && {
          order_to_date: state.OrderSupplySequence?.searchToHistorySearch,
        }),
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    state = controller.getState();

    controller.setState({
      OrderSupplySequence: {
        ...state?.OrderSupplySequence,
        historySupplyOrder: r?.items?.length > 0 ? r?.items : [],
        historySupplyActivePage: params?.firstPage ? 1 : params?.toActivePage,
        historySupplyTotalPage: r?.total
          ? Math.ceil(r?.total / SUPPLY_ORDER_LIMIT)
          : 0,
        searchBtnLoading: false,
      },
    });
  } else if (params?.action === "advanceSearch") {
    console.log("advanceSearch");
    console.log(state.OrderSupplySequence?.searchSupply);

    if (!params?.keepCount) {
      controller.setState({
        OrderSupplySequence: {
          ...state.OrderSupplySequence,
          nameLoading: true,
        },
      });
    }

    let mode = state.OrderSupplySequence?.searchMode;
    let modeId = state.supplyOrderModeOptions?.find(
      (item: any) => item.value === mode
    )?.key;
    let manufacturer = state.OrderSupplySequence?.searchManufacturer;
    let manufacturerId = state.OrderSupplySequence?.manufacturerOptions?.find(
      (item: any) => item.value === manufacturer
    )?.key;

    const supply = await SupplyList.list({
      params: {
        encounter: state.patientData?.ENCOUNTER?.encounter_id || "",
        keyword: state.OrderSupplySequence?.searchSupply || "",
        mode: modeId || "",
        manufacturer: manufacturerId || "",
        favorite: state.OrderSupplySequence?.isFavorite ? true : "",
        group: "", // [{ key: 0, value: "ANY", text: "ANY" }]
      },
      apiToken: controller.apiToken,
    });
    console.log(supply[1] ? supply[1] : supply[0]);
    let previousSupplyList = state.OrderSupplySequence?.supplyList;
    let supplyList = (supply[0]?.items || []).map((item: any) => ({
      ...item,
      selected: false,
      count: 0,
    }));
    console.log("1 supplyList", supplyList);
    // console.log("1 supplyList[0]?.favorite", supplyList[0]?.favorite)

    if (params?.keepCount) {
      // เปลี่ยน favor
      // merge previous
      supplyList = supplyList.map((item: any) => {
        let pre = previousSupplyList?.find(
          (pItem: any) => pItem.id === item.id
        );
        return {
          ...item,
          ...(pre && { selected: pre.selected, count: pre.count }),
        };
      });
    }

    console.log("state.OrderSupplySequence", state.OrderSupplySequence);
    console.log("2 supplyList", supplyList);
    // console.log("2 supplyList[0]?.favorite", supplyList[0]?.favorite)

    controller.setState({
      OrderSupplySequence: {
        ...state.OrderSupplySequence,
        supplyList: supplyList,
        nameLoading: false,
      },
    });
  } else if (params?.action === "selectedAndAdd") {
    let supplyList = state.OrderSupplySequence?.supplyList;
    supplyList = supplyList?.filter((item: any) => item.selected);

    let previewList: any[] = [];

    console.log("supplyList", supplyList);
    for await (const item of supplyList || []) {
      const preview = await SupplyOrderItemPreviewM.retrieve({
        params: {
          change: "quantity_request",
          encounter: state.selectedEncounter.id,
          mode: item?.mode,
          product: item?.id,
          product_issue: "",
          quantity_request: item.count,
          quantity_issue: "",
          quantity_postpone: "",
          eligibility_type: 1,
          qa_claim_id: 0,
        },
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });

      console.log("preview", preview);

      if (!preview[1]) {
        let updateItem = preview[0];
        let items = [...(state.OrderSupplySequence?.supplyOrder?.items || [])];
        let idx = items.findIndex(
          (item: any) => item.product === updateItem.product
        );
        if (idx === -1) {
          previewList.push(preview[0]);
        } else {
          console.log(
            "already in list ",
            state.OrderSupplySequence?.supplyOrder?.items?.[idx]?.name
          );
        }
      }
    }

    // console.log("check previewList", previewList)
    controller.setState({
      OrderSupplySequence: {
        ...state.OrderSupplySequence,
        supplyOrder: {
          ...state.OrderSupplySequence.supplyOrder,
          items: [
            ...(state.OrderSupplySequence?.supplyOrder?.items || []),
            ...previewList,
          ],
        },
        showSupplyList: false,
      },
    });

    // console.log(preview[1] ? preview[1] : preview[0]);
    // controller.setState({
    //   OrderSupplySequence: {
    //     ...state.OrderSupplySequence,
    //     supplyOrder: {
    //       ...state.OrderSupplySequence.supplyOrder,
    //       items: [
    //         ...state.OrderSupplySequence?.supplyOrder?.items || [],
    //         preview[0]
    //       ],
    //     }
    //   }
    // });
  } else if (params?.action === "favorite" && params?.id) {
    console.log("favorite", params.id);
    const result = await SupplyFavoriteUpdateViewM.update({
      pk: params?.id,
      apiToken: controller.apiToken,
    });

    let previousSupplyList = state.OrderSupplySequence?.supplyList;
    let idx =
      previousSupplyList?.findIndex((item: any) => item.id === params?.id) || 0;
    let supply: any = previousSupplyList?.[idx];
    supply.favorLoading = true;

    controller.setState({
      OrderSupplySequence: {
        ...state.OrderSupplySequence,
        supplyList: previousSupplyList,
      },
    });

    console.log("result", result);
    if (result?.[0]?.success) {
      controller.handleEvent({
        message: "RunSequence",
        params: {
          action: "advanceSearch",
          sequence: "OrderSupply",
          keepCount: true,
        },
      });
    } else {
      supply.favorLoading = false;
      controller.setState({
        OrderSupplySequence: {
          ...state.OrderSupplySequence,
          supplyList: previousSupplyList,
        },
      });
    }
  } else if (params?.action === "unfavorite" && params?.id) {
    console.log("unfavorite", params.id);
    const result = await SupplyFavoriteUpdateView.update({
      pk: params?.id,
      apiToken: controller.apiToken,
    });

    let previousSupplyList = state.OrderSupplySequence?.supplyList;
    let idx =
      previousSupplyList?.findIndex((item: any) => item.id === params?.id) || 0;
    let supply: any = previousSupplyList?.[idx];
    supply.favorLoading = true;

    controller.setState({
      OrderSupplySequence: {
        ...state.OrderSupplySequence,
        supplyList: previousSupplyList,
      },
    });

    if (result?.[0]?.success) {
      controller.handleEvent({
        message: "RunSequence",
        params: {
          action: "advanceSearch",
          sequence: "OrderSupply",
          keepCount: true,
        },
      });
    } else {
      supply.favorLoading = false;
      controller.setState({
        OrderSupplySequence: {
          ...state.OrderSupplySequence,
          supplyList: previousSupplyList,
        },
      });
    }
  } else if (params?.action === "search") {
    console.log("search");
    console.log(state.OrderSupplySequence?.searchSupply);
    const supply = await SupplyList.list({
      params: {
        keyword: state.OrderSupplySequence?.searchSupply || "",
      },
      apiToken: controller.apiToken,
    });
    console.log(supply[1] ? supply[1] : supply[0]);
    const supplyList = (supply[0]?.items || []).map((item: any) => ({
      ...item,
      selected: false,
      count: 0,
    }));

    if (supplyList.length !== 1) {
      controller.setState({
        OrderSupplySequence: {
          ...state.OrderSupplySequence,
          supplyList: supplyList,
          showSupplyList: supplyList.length !== 1,
          selectedSupply: null,
        },
      });
    } else if (supplyList.length === 1) {
      controller.setState({
        OrderSupplySequence: {
          ...state.OrderSupplySequence,
          supplyList: supplyList,
          searchSupply: supplyList?.[0]?.name || "",
          selectedSupply: supplyList?.[0],
          qty: 0,
          showSupplyList: false,
        },
      });
    }
  } else if (params?.action === "select" && params?.item) {
    console.log(params.item);
    controller.setState({
      OrderSupplySequence: {
        ...state.OrderSupplySequence,
        searchSupply: params.item?.name || "",
        selectedSupply: params.item,
        qty: 0,
        showSupplyList: false,
      },
    });
  } else if (params?.action === "add") {
    if (
      state.OrderSupplySequence?.qty === undefined ||
      state.OrderSupplySequence?.qty <= 0
    )
      return;
    const preview = await SupplyOrderItemPreviewM.retrieve({
      params: {
        change: "quantity_request",
        encounter: state.selectedEncounter.id,
        mode: "SUPPLY",
        product: state.OrderSupplySequence?.selectedSupply?.id,
        product_issue: "",
        quantity_request: state.OrderSupplySequence?.qty,
        quantity_issue: "",
        quantity_postpone: "",
        eligibility_type: 1,
        qa_claim_id: 0,
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });
    console.log(preview[1] ? preview[1] : preview[0]);

    if (!preview[1]) {
      let updateItem = preview[0];
      let items = [...(state.OrderSupplySequence?.supplyOrder?.items || [])];
      let idx = items.findIndex(
        (item: any) => item.product === updateItem.product
      );
      // Not found then add
      if (idx === -1) {
        controller.setState({
          OrderSupplySequence: {
            ...state.OrderSupplySequence,
            supplyOrder: {
              ...state.OrderSupplySequence.supplyOrder,
              items: [
                ...(state.OrderSupplySequence?.supplyOrder?.items || []),
                preview[0],
              ],
            },
          },
        });
      } else {
        console.log("Already in list ");
      }
    }
  } else if (params?.action === "edit") {
    if (params?.idx !== null && params?.key) {
      let supply = state.OrderSupplySequence.supplyOrder?.items?.[params.idx];
      console.log("supply: ", supply);
      const preview = await SupplyOrderItemPreviewM.retrieve({
        params: {
          change: params.key,
          encounter: state.selectedEncounter.id,
          mode: supply?.mode,
          product: supply?.product,
          product_issue: "",
          quantity_request: supply.quantity_request,
          quantity_issue: "",
          quantity_postpone: "",
          eligibility_type: supply.eligibility_type,
          qa_claim_id: 0,
        },
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });

      console.log("preview", preview);
      if (!preview[1]) {
        let updateItem = preview[0];
        let items = [...(state.OrderSupplySequence?.supplyOrder?.items || [])];
        let idx = items.findIndex(
          (item: any) => item.product === updateItem.product
        );
        if (idx !== -1) {
          items[idx] = updateItem;
          controller.setState({
            OrderSupplySequence: {
              ...state.OrderSupplySequence,
              supplyOrder: {
                ...state.OrderSupplySequence.supplyOrder,
                items: items,
              },
            },
          });
        }
      }
    }
  } else if (params?.action === "calc") {
    let claim_payload: any = null;

    state.OrderSupplySequence.supplyOrder?.items?.forEach((item: any) => {
      if (item.claim_payload != undefined) claim_payload = item.claim_payload;
    });

    const orderPreview = await SupplyOrderPreview.create({
      data: {
        ...state.OrderSupplySequence?.supplyOrder,
        action: "REQUEST",
        encounter: state.selectedEncounter.id,
        claim_payload: claim_payload,
      } as any,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (orderPreview[0]) {
      params.onSuccess?.()

      controller.setState({
        OrderSupplySequence: {
          ...state.OrderSupplySequence,
          supplyOrder: orderPreview[0],
        },
      });
    } else {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.sequence]: orderPreview[1] },
      });
    }
  } else if (
    params?.action === "save" &&
    state.OrderSupplySequence?.supplyOrder
  ) {
    const { pk, claim_payload, ...rest } =
      state.OrderSupplySequence.supplyOrder;

    let claim_payload_qa: any = null;
    state.OrderSupplySequence.supplyOrder?.items?.forEach((item: any) => {
      if (item.claim_payload != undefined)
        claim_payload_qa = item.claim_payload;
    });

    const [response, error] = await SupplyOrderList.create({
      data: {
        ...rest,
        action: "REQUEST",
        encounter: state.selectedEncounter.id,
        qa_claim_id: claim_payload_qa?.qa_claim_id,
        order_div: state.OrderSupplySequence.supplyOrder?.order_div,
        order_perform_div:
          state.OrderSupplySequence.supplyOrder?.order_perform_div,
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });
    if (response) {
      controller.setState(
        {
          OrderSupplySequence: {
            ...state.OrderSupplySequence,
            sequenceIndex: "START",
          },
          successMessage: {
            ...state.successMessage,
            [params?.sequence]: response,
          },
        },
        () =>
          controller.handleEvent({
            message: "RunSequence" as any,
            params: {
              sequence: "OrderSupply" as any,
            },
          })
      );
    }
    if (error) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.sequence]: error },
      });
    }
  } else if (params?.action === "update") {
    const supplyOrder = state.OrderSupplySequence.supplyOrder;

    let claim_payload_qa: any = null;
    state.OrderSupplySequence.supplyOrder?.items?.forEach((item: any) => {
      if (item.claim_payload != undefined)
        claim_payload_qa = item.claim_payload;
    });

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const [response, error] = await SupplyOrderDetail.update({
      pk: supplyOrder?.pk,
      data: {
        action: "EDIT",
        code: supplyOrder?.code,
        doctor: supplyOrder?.doctor,
        encounter: supplyOrder?.encounter,
        encounter_number: supplyOrder?.encounter_number,
        items: supplyOrder?.items,
        order_div: supplyOrder?.order_div,
        order_perform_div: supplyOrder?.order_perform_div,
        patient_id: state?.selectedEncounter?.patient,
        pk: supplyOrder?.pk,
        status: supplyOrder?.status,
        type: supplyOrder?.type,
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (error) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: error },
          },
        });
      }
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
      });
    } else {
      if (params.isEdit) {
        return handleRefreshSupplyOrderWorkflow(controller, {
          ...params,
          response,
        });
      }

      controller.setState(
        {
          OrderSupplySequence: {
            ...state.OrderSupplySequence,
            sequenceIndex: "START",
          },
          successMessage: { ...state.successMessage, [params?.card]: response },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.buttonLoadKey]: "SUCCESS",
          },
        },
        () =>
          controller.handleEvent({
            message: "RunSequence" as any,
            params: {
              sequence: "OrderSupply" as any,
            },
          })
      );
    }
  } else if (params?.action === "cancel") {
    console.log("OrderSupplySequence", params?.data);

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const [response, error] = await SupplyOrderDetail.update({
      pk: params.pk,
      data: {
        action: "CANCEL",
        note: params.note,
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (error) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: error },
        },
      });
    } else {
      if (params.isEdit) {
        return handleRefreshSupplyOrderWorkflow(controller, {
          ...params,
          card: params.cardKey,
          response: { pk: params.pk },
        });
      }

      controller.setState(
        {
          OrderSupplySequence: {
            ...state.OrderSupplySequence,
            sequenceIndex: "START",
          },
          successMessage: { ...state.successMessage, [params.card]: response },
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: null },
          },

          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.buttonLoadKey]: "SUCCESS",
          },
        },
        () =>
          controller.handleEvent({
            message: "RunSequence" as any,
            params: {
              sequence: "OrderSupply" as any,
            },
          })
      );
    }
  } else if (params?.action === "clear") {
    controller.setState({
      successMessage: { ...state.successMessage, [params?.sequence]: null },
      errorMessage: { ...state.errorMessage, [params?.sequence]: null },
    });
  }
};


const handleRefreshSupplyOrderWorkflow: Handler = async (controller, params) => {
  const state = controller.getState()

  const updateOrder = await SupplyOrderDetail.retrieve({
    pk: params.response?.pk,
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
  });

  return controller.setState({
    OrderSupplySequence: {
      ...state.OrderSupplySequence,
    },
    DispensingOrderSupplySequence: {
      ...state.DispensingOrderSupplySequence,
      selectedOrder: updateOrder?.[0],
    },
    successMessage: { ...state.successMessage, [params?.card]: params.response },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "SUCCESS",
    },
  });
}