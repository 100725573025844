import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Checkbox,
  Radio,
  Input,
  TextArea,
  Button
} from 'semantic-ui-react'

const CardORDetailUX = (props: any) => {
    return(
      <div
        style={{width:"100%",}}>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}
              width={2}>
              <label
                style={{fontWeight: "bold", margin: 0}}>
                วิธีระงับความรู้สึก
              </label>
              <label
                style={{color: "red"}}>
                *
              </label>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <Dropdown
                fluid={true}
                name="anesthesia_method"
                onChange={props.onChangeValue}
                options={props.anesthesiaMethodOptions || []}
                selection={true}
                style={{width: "100%"}}
                value={props.anesthesiaMethod || ""}>
              </Dropdown>
            </FormField>
            <FormField
              className="required"
              inline={true}>
              <label
                style={{color: "#056EBD", minWidth: "max-content"}}>
                Estimate time for OP
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                name="estimate_operation_hour"
                onChange={props.onChangeValue}
                type="number"
                value={props.estimateOperationHour || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{fontWeight: "normal",color: "#056EBD" }}>
                hr.
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                name="estimate_operation_minute"
                onChange={props.onChangeValue}
                type="number"
                value={props.estimateOperationMinute || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{fontWeight: "normal",color: "#056EBD" }}>
                min
              </label>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{color: "#056EBD", minWidth: "max-content"}}>
                วันที่ผ่าตัด
              </label>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%"}}>
                {props.DateTextBox}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{color: "#056EBD",minWidth: "max-content"}}>
                เวลา เริ่มต้น
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.StartTime}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{color: "#056EBD", minWidth: "max-content"}}>
                เวลา สิ้นสุด
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.EndTime}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{color: "#056EBD"}}>
                Location
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                disabled={props.config?.disableLocation || false}
                fluid={true}
                icon={null}
                name="location"
                onChange={props.onChangeValue}
                options={props.orLocationOptions || []}
                selection={true}
                style={{width: "100%", opacity: 1}}
                value={props.location || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{color: "#056EBD"}}>
                Room
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                disabled={props.config?.disabledRoom || false}
                fluid={true}
                icon={null}
                name="operating_room"
                onChange={props.onChangeValue}
                options={props.operatingRoomOptions || []}
                selection={true}
                style={{width: "100%", opacity: 1}}
                value={props.operatingRoom || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <label
                style={{fontWeight: "bold"}}>
                Procedure Type
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                fluid={true}
                name="procedure_type"
                onChange={props.onChangeValue}
                options={props.procedureTypeOptions || []}
                selection={true}
                style={{width: "100%"}}
                value={props.procedureType || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.frozenSection || false}
                label="Sentinel lymph node biopsy & Frozen section"
                name="frozen_section"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.mammogram || false}
                label="Needle Localized; Mammogram"
                name="mammogram"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}
              width={2}>
              <label>
                ยาที่ต้องหยุดก่อนผ่าตัด
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.antiPlateletValue === props.ADM_FIBRINOLYTIC_USED.NO_DRUG}
                label="ไม่ได้ใช้ยา"
                onChange={(e: any, v: value)=> props.onChangeValue(e, { value:  props.ADM_FIBRINOLYTIC_USED.NO_DRUG, name: "anti_platelet_coagulant.value" })}
                style={{minWidth: "max-content"}}>
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.antiPlateletValue === props.ADM_FIBRINOLYTIC_USED.CUT_DRUG}
                label="ไม่ต้องงด"
                onChange={(e: any, v: value)=> props.onChangeValue(e, { value:  props.ADM_FIBRINOLYTIC_USED.CUT_DRUG, name:"anti_platelet_coagulant.value"})}
                style={{minWidth: "max-content"}}>
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.antiPlateletValue === props.ADM_FIBRINOLYTIC_USED.ANTI_PALETELET}
                label="ใช้ Anti-Platelet/ Anti-Coagulant"
                onChange={(e: any, v: value)=> props.onChangeValue(e, { value:  props.ADM_FIBRINOLYTIC_USED.ANTI_PALETELET, name:"anti_platelet_coagulant.value"})}
                style={{minWidth: "max-content"}}>
              </Radio>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                disabled={props.config.disabledAntiPlatelet || false}
                name="anti_platelet_coagulant.remark"
                onChange={props.onChangeValue}
                value={props.remark || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                งดใช้ยาตั้งแต่วันที่
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%"}}>
                {props.DateTextBoxAntiPlatelet}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <label>
                Contrast Media
              </label>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <Dropdown
                name="contrast_media"
                onChange={props.onChangeValue}
                options={props.cmOptions || {}}
                selection={true}
                style={{width: "100%"}}
                value={props.contrastMedia || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <label>
                ASA PS
              </label>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <Dropdown
                name="asa_class"
                onChange={props.onChangeValue}
                options={props.asaClassOptions || []}
                selection={true}
                style={{width: "100%"}}
                value={props.asaClass || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <label>
                Special Equipment
              </label>
            </FormField>
            <FormField
              inline={{width: "100%"}}
              width={12}>
              <Dropdown
                multiple={true}
                name="special_equipments"
                onChange={props.onChangeValue}
                options={props.specialEquipmentOptions || []}
                selection={true}
                style={{width: "100%"}}
                value={props.specialEquipments || []}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label>
                คำแนะนำในการปฏิบัติตัวเพิ่มเติม
              </label>
            </FormField>
            <FormField>
              <Button
                color="blue"
                onClick={props.onAddNote}>
                เพิ่มคำแนะนำ
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={16}>
              <TextArea
                name="remark"
                onChange={props.onChangeValue}
                rows={3}
                value={props.remarkDetail || ""}>
              </TextArea>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <label
                style={{minWidth: "max-content"}}>
                Anticipate Blood loss
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.isLess_500 || false}
                label=" < 500 ml in adult or < 7 ml/kg in children"
                name="anticipated_blood_loss.is_less_500"
                onChange={props.onChangeValue}>
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.isMore_500 || false}
                label=" > 500 ml in adult or < 7 ml/kg in children"
                name="anticipated_blood_loss.is_more_500"
                onChange={props.onChangeValue}>
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <div>
                Define
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                name="anticipated_blood_loss.define"
                onChange={props.onChangeValue}
                type="number"
                value={props.define || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <div>
                mL
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <label>
                Blood Request
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Dropdown
                disabled={props.blood_component || props.cross_match_detail}
                fluid={true}
                name="blood_request"
                onChange={props.onChangeValue}
                options={props.bloodRequestOptions || []}
                selection={true}
                style={{width: "100%"}}
                value={props.bloodRequest || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="purple"
                disabled={!props.config?.showBRCheck && !props.canOrderBloodBank}
                onClick={props.onOrderBloodRequest}>
                สั่งจองเลือด
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <label
                style={{display: props.config?.showBRCheck ? "": "none"}}>
                Blood Component
              </label>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <Input
                readOnly={true}
                style={{display: props.config?.showBRCheck ? "": "none"}}
                value={props.blood_component}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <label
                style={{display: props.config?.showBRCheck ? "": "none"}}>
                นัดหมาย Cross-match
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                readOnly={true}
                style={{display: props.config?.showBRCheck ? "": "none"}}
                value={props.cross_match_detail}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width="1">
              <label
                style={{display: props.config?.showBRCheck ? "": "none"}}>
                วันเจาะเลือด
              </label>
            </FormField>
            <FormField
              inline={true}
              width="3">
              <Input
                readOnly={true}
                style={{display: props.config?.showBRCheck ? "": "none"}}
                value={props.cross_match_date}>
              </Input>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}


export default CardORDetailUX

export const screenPropsDefault = {}

/* Date Time : Wed Jun 21 2023 04:38:59 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\",}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "วิธีระงับความรู้สึก"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", margin: 0}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Dropdown",
      "parent": 13,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "anesthesia_method"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.anesthesiaMethodOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.anesthesiaMethod || \"\""
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา เริ่มต้น"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#056EBD\",minWidth: \"max-content\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.StartTime"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา สิ้นสุด"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#056EBD\", minWidth: \"max-content\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": "props.EndTime"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "Procedure Type"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Dropdown",
      "parent": 46,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "procedure_type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.procedureTypeOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.procedureType || \"\""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Checkbox",
      "parent": 47,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.frozenSection || false"
        },
        "label": {
          "type": "value",
          "value": "Sentinel lymph node biopsy & Frozen section"
        },
        "name": {
          "type": "value",
          "value": "frozen_section"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Checkbox",
      "parent": 48,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.mammogram || false"
        },
        "label": {
          "type": "value",
          "value": "Needle Localized; Mammogram"
        },
        "name": {
          "type": "value",
          "value": "mammogram"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": "Blood Request"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Dropdown",
      "parent": 54,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.blood_component || props.cross_match_detail"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "blood_request"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.bloodRequestOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.bloodRequest || \"\""
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "label",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "Anticipate Blood loss"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Radio",
      "parent": 74,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isLess_500 || false"
        },
        "label": {
          "type": "value",
          "value": " < 500 ml in adult or < 7 ml/kg in children"
        },
        "name": {
          "type": "value",
          "value": "anticipated_blood_loss.is_less_500"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Radio",
      "parent": 75,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isMore_500 || false"
        },
        "label": {
          "type": "value",
          "value": " > 500 ml in adult or < 7 ml/kg in children"
        },
        "name": {
          "type": "value",
          "value": "anticipated_blood_loss.is_more_500"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": "Define"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "mL"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Input",
      "parent": 77,
      "props": {
        "name": {
          "type": "value",
          "value": "anticipated_blood_loss.define"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.define || \"\""
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 7,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "label",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "ยาที่ต้องหยุดก่อนผ่าตัด"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "Radio",
      "parent": 86,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.antiPlateletValue === props.ADM_FIBRINOLYTIC_USED.NO_DRUG"
        },
        "label": {
          "type": "value",
          "value": "ไม่ได้ใช้ยา"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, v: value)=> props.onChangeValue(e, { value:  props.ADM_FIBRINOLYTIC_USED.NO_DRUG, name: \"anti_platelet_coagulant.value\" })"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "Radio",
      "parent": 87,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.antiPlateletValue === props.ADM_FIBRINOLYTIC_USED.CUT_DRUG"
        },
        "label": {
          "type": "value",
          "value": "ไม่ต้องงด"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, v: value)=> props.onChangeValue(e, { value:  props.ADM_FIBRINOLYTIC_USED.CUT_DRUG, name:\"anti_platelet_coagulant.value\"})"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Radio",
      "parent": 88,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.antiPlateletValue === props.ADM_FIBRINOLYTIC_USED.ANTI_PALETELET"
        },
        "label": {
          "type": "value",
          "value": "ใช้ Anti-Platelet/ Anti-Coagulant"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, v: value)=> props.onChangeValue(e, { value:  props.ADM_FIBRINOLYTIC_USED.ANTI_PALETELET, name:\"anti_platelet_coagulant.value\"})"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "Input",
      "parent": 89,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.config.disabledAntiPlatelet || false"
        },
        "name": {
          "type": "value",
          "value": "anti_platelet_coagulant.remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.remark || \"\""
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "label",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": "งดใช้ยาตั้งแต่วันที่"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 91,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DateTextBoxAntiPlatelet"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "Contrast Media"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "label",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": "ASA PS"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "Dropdown",
      "parent": 101,
      "props": {
        "name": {
          "type": "value",
          "value": "contrast_media"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.cmOptions || {}"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.contrastMedia || \"\""
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Dropdown",
      "parent": 103,
      "props": {
        "name": {
          "type": "value",
          "value": "asa_class"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.asaClassOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.asaClass || \"\""
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "Dropdown",
      "parent": 109,
      "props": {
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "special_equipments"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.specialEquipmentOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.specialEquipments || []"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "label",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": "Special Equipment"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "label",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": "คำแนะนำในการปฏิบัติตัวเพิ่มเติม"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormField",
      "parent": 115,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "TextArea",
      "parent": 116,
      "props": {
        "name": {
          "type": "value",
          "value": "remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "rows": {
          "type": "code",
          "value": "3"
        },
        "value": {
          "type": "code",
          "value": "props.remarkDetail || \"\""
        }
      },
      "seq": 117,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormField",
      "parent": 11,
      "props": {
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "Button",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มคำแนะนำ"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddNote"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "FormField",
      "parent": 2,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 126,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "label",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": "Estimate time for OP"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#056EBD\", minWidth: \"max-content\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "label",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": "hr."
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\",color: \"#056EBD\" }"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "label",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": "min"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\",color: \"#056EBD\" }"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "Input",
      "parent": 123,
      "props": {
        "name": {
          "type": "value",
          "value": "estimate_operation_hour"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.estimateOperationHour || \"\""
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "Input",
      "parent": 125,
      "props": {
        "name": {
          "type": "value",
          "value": "estimate_operation_minute"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.estimateOperationMinute || \"\""
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "label",
      "parent": 127,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#056EBD\", minWidth: \"max-content\"}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 128,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "label",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": "Location"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#056EBD\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "label",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": "Room"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#056EBD\"}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "Dropdown",
      "parent": 140,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledRoom || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "code",
          "value": "null"
        },
        "name": {
          "type": "value",
          "value": "operating_room"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.operatingRoomOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.operatingRoom || \"\""
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "Dropdown",
      "parent": 138,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disableLocation || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "code",
          "value": "null"
        },
        "name": {
          "type": "value",
          "value": "location"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.orLocationOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.location || \"\""
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "Button",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "สั่งจองเลือด"
        },
        "color": {
          "type": "value",
          "value": "purple"
        },
        "disabled": {
          "type": "code",
          "value": "!props.config?.showBRCheck && !props.canOrderBloodBank"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOrderBloodRequest"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "FormField",
      "parent": 146,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "label",
      "parent": 147,
      "props": {
        "children": {
          "type": "value",
          "value": "Blood Component"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.showBRCheck ? \"\": \"none\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "FormField",
      "parent": 146,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "FormField",
      "parent": 153,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "label",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": "นัดหมาย Cross-match"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.showBRCheck ? \"\": \"none\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "FormField",
      "parent": 153,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "FormField",
      "parent": 153,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "FormField",
      "parent": 153,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "label",
      "parent": 158,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเจาะเลือด"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.showBRCheck ? \"\": \"none\"}"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "Input",
      "parent": 149,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.showBRCheck ? \"\": \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.blood_component"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "Input",
      "parent": 156,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.showBRCheck ? \"\": \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.cross_match_detail"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "Input",
      "parent": 160,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.showBRCheck ? \"\": \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.cross_match_date"
        }
      },
      "seq": 167,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardORDetailUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
