import WasmController from "../../../../frameworks/WasmController";
import moment from "moment";
// APIs
import AnesthesiaDataList from "issara-sdk/apis/AnesthesiaDataList_apps_ANS";
import ANSFormPrint from "issara-sdk/apis/ANSFormPrint_apps_ANS";
import FormDataActionLogList from "issara-sdk/apis/FormDataActionLogList_apps_PTM";
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import UserTokenizeLicenseView from "issara-sdk/apis/UserTokenizeLicenseView_users";
import OperatingOrderDetail from "issara-sdk/apis/OperatingOrderDetail_apps_ORM";
import TeamDetail from "issara-sdk/apis/TeamDetail_apps_ORM";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import { formatDate } from "react-lib/utils";
import UserEmployeeDetailAPIView from "issara-sdk/apis/UserEmployeeDetailAPIView_users";
import UserList from "issara-sdk/apis/UserList_users";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // ORQueueI
  selectedOrOrder?: any;

  // sequence
  PACURecordSequence?: {
    sequenceIndex?: string | null;

    id?: number | null;
    data?: {
      // CardPACURecordFormUX
      DateRamrk?: string;
      ORRmark?: string;
      IsOPD?: boolean;
      IsWard?: boolean;
      IsWardRemark?: string;
      StartOpRemark?: string;
      EndAnesRemark?: string;
      PACUDurartionTime?: string;
      AnesthesiaTeamRemark?: string; // CardPACURecordAnesthesiaUX
      DiagnosisRemark?: string;
      AnestheticAgentsRemark?: string; // CardPACURecordAnesthesiaUX
      OperationRemark?: string;
      // CardPACURecordAnesthesiaUX
      IsGA?: boolean;
      IsGARemark?: string;
      IsMAC?: boolean;
      IsRegionalAnesthesia?: boolean;
      IsRegionalAnesthesiaRemark?: string;
      AdmissionStatus?: string;
      Airway?: string;
      ASAPS?: string;
      Emergency?: boolean;
      AdverseEventsRemark?: string;
      // CardPACURecordIntraoperativeUX
      Crystalloid?: string;
      BloodLoss?: string;
      Colloid?: string;
      Urine?: string;
      Blood?: string;
      Drain?: string;
      // CardPostAnestheticRecoveryScore
      postAnestheticRecoveryScoreData?: {
        time: any[];
        activity: any[];
        respiration: any[];
        circulation: any[];
        consciousness: any[];
        spO2Colour: any[];
        total: any[];
        nauseaVomiting: any[];
        pain: any[];
        bleeding: any[];
        mdRn: any[];
        totalDischargeScore: string;
      };
      // CardPACURecordMonitorUX
      IsNIBP?: boolean;
      IsSpO2?: boolean;
      IsEKG?: boolean;
      IsUrine?: boolean;
      ObserveRRAndVS?: boolean;
      FallPrevention?: boolean;
      PainAssessment?: boolean;
      RespiratoryCare?: boolean;
      KeepWarm?: boolean;
      IsPositionManagement?: boolean;
      TympanicTemperature?: boolean;
      TympanicTemperatureRemark?: string;
      NursingCareOther?: boolean;
      NursingCareOtherRemark?: string;
      AttendingStaff?: string;
      AttendingStaffLicenseNumber?: string;
      AttendingStaffCode?: string;
      PACUNurse?: string;
      PACUNurseLicenseNumber?: string;
      PACUNurseCode?: string;
      // CardPACURecordMedicationUX
      nurseMedicationData?: any[];
      // CardPACURecordProblemsUX
      ansProblemData?: any[];
      // CardPACURecordReferralUX
      NotPACU?: boolean;
      BPRemark?: string;
      PulseRemark?: string;
      SpO2Remark?: string;
      TempRemark?: string;
      O2Transfer?: string;
      O2TransferRemark?: string;
      PatientControlledAnalgesia?: string;
      PatientControlledAnalgesiaRemark?: string;
      SpinalEpiduralMorphine?: string;
      SpinalEpiduralMorphineRemark?: string;
      ReceiverRemark?: string;
      SenderRemark?: string;
      LicenseNumberRemark?: string;
      IsTransferToWard?: boolean;
      TransferToWardRemark?: string;
      IsTransferToICU?: boolean;
      TransferToICURemark?: string;
      IsTrsnsferToDischarge?: boolean;
      TrsnsferToDischargeRemark?: string;
      IsLow?: boolean;
      IsHigh?: boolean;
    };
    userNurseOptions?: any;
    ansStartDatetime?: string;
    ansTableStart?: string;
    ansEndDatetime?: string;
    ansTableEnd?: string;
    modLogInfo?: { open: boolean; logList: any[] };
  } | null;
};

export const StateInitial: State = {
  PACURecordSequence: {
    sequenceIndex: null,

    id: null,
    data: {
      // CardPACURecordFormUX
      DateRamrk: "",
      ORRmark: "",
      IsOPD: false,
      IsWard: false,
      IsWardRemark: "",
      StartOpRemark: "",
      EndAnesRemark: "",
      PACUDurartionTime: "",
      AnesthesiaTeamRemark: "", // CardPACURecordAnesthesiaUX
      DiagnosisRemark: "",
      AnestheticAgentsRemark: "", // CardPACURecordAnesthesiaUX
      OperationRemark: "",
      // CardPACURecordAnesthesiaUX
      IsGA: false,
      IsGARemark: "",
      IsMAC: false,
      IsRegionalAnesthesia: false,
      IsRegionalAnesthesiaRemark: "",
      AdmissionStatus: "",
      Airway: "",
      ASAPS: "",
      Emergency: false,
      AdverseEventsRemark: "",
      // CardPACURecordIntraoperativeUX
      Crystalloid: "",
      BloodLoss: "",
      Colloid: "",
      Urine: "",
      Blood: "",
      Drain: "",
      // CardPostAnestheticRecoveryScore
      postAnestheticRecoveryScoreData: {
        time: Array(7).fill(""),
        activity: Array(7).fill(""),
        respiration: Array(7).fill(""),
        circulation: Array(7).fill(""),
        consciousness: Array(7).fill(""),
        spO2Colour: Array(7).fill(""),
        total: Array(7).fill(""),
        nauseaVomiting: Array(7).fill(""),
        pain: Array(7).fill(""),
        bleeding: Array(7).fill(""),
        mdRn: Array(7).fill(""),
        totalDischargeScore: "",
      },
      // CardPACURecordMonitorUX
      IsNIBP: false,
      IsSpO2: false,
      IsEKG: false,
      IsUrine: false,
      ObserveRRAndVS: false,
      FallPrevention: false,
      PainAssessment: false,
      RespiratoryCare: false,
      KeepWarm: false,
      IsPositionManagement: false,
      TympanicTemperature: false,
      TympanicTemperatureRemark: "",
      NursingCareOther: false,
      NursingCareOtherRemark: "",
      AttendingStaff: "",
      AttendingStaffLicenseNumber: "",
      AttendingStaffCode: "",
      PACUNurse: "",
      PACUNurseLicenseNumber: "",
      PACUNurseCode: "",
      // CardPACURecordMedicationUX
      nurseMedicationData: [],
      // CardPACURecordProblemsUX
      ansProblemData: [],
      // CardPACURecordReferralUX
      NotPACU: false,
      BPRemark: "",
      PulseRemark: "",
      SpO2Remark: "",
      TempRemark: "",
      O2Transfer: "",
      O2TransferRemark: "",
      PatientControlledAnalgesia: "",
      PatientControlledAnalgesiaRemark: "",
      SpinalEpiduralMorphine: "",
      SpinalEpiduralMorphineRemark: "",
      ReceiverRemark: "",
      SenderRemark: "",
      LicenseNumberRemark: "",
      IsTransferToWard: false,
      TransferToWardRemark: "",
      IsTransferToICU: false,
      TransferToICURemark: "",
      IsTrsnsferToDischarge: false,
      TrsnsferToDischargeRemark: "",
      IsLow: false,
      IsHigh: false,
    },
    userNurseOptions: [],
    ansStartDatetime: "",
    ansTableStart: "",
    ansEndDatetime: "",
    ansTableEnd: "",
    modLogInfo: { open: false, logList: [] },
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const GetMaster: Handler = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["drugName", {}],
        ["doctor", {}],
        ["divisionWard", {}],
        // CardPACURecordAnesthesiaUX
        ["pacu_airway", {}],
        ["anes_asa_ps", {}],
        // CardPACURecordReferralUX
        ["pacu_o2transfer", {}],
        ["pacu_ptctrlanal", {}],
        ["pacu_spiepimorph", {}],
      ],
    },
  });

  controller.setState(
    {
      PACURecordSequence: { sequenceIndex: "Action" },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "FETCH_PACU") {
    const [formLatestResp, formLatestErr, formLatestNet] =
      await FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: params.formCode,
          form_version: params.formVersion,
        },
      });

    const [operatingResp, operatingErr, operatingNet] =
      await OperatingOrderDetail.retrieve({
        apiToken: controller.apiToken,
        pk: state.selectedOrOrder.id,
      });

    const [anesthesiaResp, anesthesiaErr, anesthesiaNet] =
      await DoctorDetail.retrieve({
        apiToken: controller.apiToken,
        pk: operatingResp?.anesthesia_teams[0]?.anesthesiologist,
      });

    const [surgicalTeamResp, surgicalTeamErr, surgicalTeamNet] =
      await DoctorDetail.retrieve({
        apiToken: controller.apiToken,
        pk: operatingResp?.teams[0]?.chief_surgeon,
      });

    const [teamDetailResp, teamDetailErr, teamDetailNet] =
      await TeamDetail.retrieve({
        apiToken: controller.apiToken,
        pk: operatingResp?.teams[0]?.id,
      });

    // userNurseOptions
    const [userNurseRes, userNurseErr, userNurseNet] = await UserList.list({
      apiToken: controller.apiToken,
      params: {
        role: "REGISTERED_NURSE",
      },
    });

    let newUserNurse: any = [];

    if (userNurseRes) {
      userNurseRes?.items?.forEach((item: any) => {
        newUserNurse.push({
          key: item?.id,
          value: item?.id,
          text: item?.full_name_code,
        });
      });
    }

    if (formLatestErr || !formLatestResp.data) {
      let seq: any = {
        ...StateInitial.PACURecordSequence,
        sequenceIndex: state.PACURecordSequence?.sequenceIndex,
      };

      const aneOrderId = state.selectedOrOrder?.other_orders?.find(
        (item: any) => item.model === "anesthesiaorder"
      )?.id;
      if (aneOrderId) {
        const [aneDataResp, aneDataErr, aneDataNet] =
          await AnesthesiaDataList.list({
            apiToken: controller.apiToken,
            params: {
              encounter: state.selectedOrOrder?.encounter,
              ans_order_id: aneOrderId,
            },
          });

        if (aneDataResp) {
          seq.data.DiagnosisRemark = aneDataResp.post_diagnosis;
          seq.data.OperationRemark = aneDataResp.post_operation;
        }

        seq.data.ORRmark = operatingResp?.operating_room_no;
        seq.data.AnesthesiaTeamRemark = anesthesiaResp?.name_code;
        seq.data.DiagnosisRemark =
          teamDetailResp?.post_principal_diagnosis.length !== 0
            ? `[${teamDetailResp?.post_principal_diagnosis[0]?.icd_code}] ${teamDetailResp?.post_principal_diagnosis[0]?.icd_term}`
            : "";
        seq.data.AnestheticAgentsRemark = surgicalTeamResp?.name_code;
        seq.data.OperationRemark =
          teamDetailResp !== null
            ? `[${teamDetailResp?.post_operating_order_item?.procedures[0]?.icd9cm_code}] ${teamDetailResp?.post_operating_order_item?.procedures[0]?.icd9cm_term}`
            : "";
        seq.data.DateRamrk = formatDate(moment());
        seq.data.StartDate = formatDate(moment());
        seq.data.EndDate = formatDate(moment());
        seq.userNurseOptions = newUserNurse || [];
      }
      controller.setState({
        PACURecordSequence: seq,
        ...state.PACURecordSequence,
      });
    } else {
      let seq: any = { sequenceIndex: state.PACURecordSequence?.sequenceIndex };
      if (formLatestResp?.data) {
        seq.id = formLatestResp.id;
        seq.data = formLatestResp.data;
        seq.status = formLatestResp.status;
        seq.userNurseOptions = newUserNurse || [];

        let start: string = formLatestResp.data?.DateRamrk;
        start += formLatestResp.data?.StartOpRemark
          ? " " + formLatestResp.data?.StartOpRemark
          : " 00:00";
        const startMM: any = moment(start, "DD/MM/YYYY HH:mm");
        if (startMM.format("DD/MM/YYYY HH:mm") !== "Invalid date") {
          seq.ansStartDatetime = startMM.format("DD/MM/YYYY HH:mm");
          seq.ansTableStart = startMM.format("DD/MM/YYYY HH:mm");
        }

        let end: string = formLatestResp.data?.DateRamrk;
        end += formLatestResp.data?.EndAnesRemark
          ? " " + formLatestResp.data?.EndAnesRemark
          : " 00:00";
        const endMM: any = moment(end, "DD/MM/YYYY HH:mm");
        if (endMM.format("DD/MM/YYYY HH:mm") !== "Invalid date") {
          seq.ansEndDatetime = endMM.format("DD/MM/YYYY HH:mm");
          if (endMM - startMM > 14400000) {
            // 14400000 ms is 4 hours
            seq.ansTableEnd = startMM
              .add(3, "hours")
              .format("DD/MM/YYYY HH:mm");
            seq.ansTableEnd = startMM
              .add(59, "minutes")
              .format("DD/MM/YYYY HH:mm");
            seq.ansTableEnd = startMM
              .add(59, "seconds")
              .format("DD/MM/YYYY HH:mm");
          } else {
            seq.ansTableEnd = endMM.format("DD/MM/YYYY HH:mm");
          }
        }
        // Todo
        //  let newData: SubCardPacuRecord1Data = await getLastVitalSign(
        //    startMM,
        //    endMM,
        //    responseData
        //  );
        //  console.log(newData);
        //  if (response.data.doctorSelected) {
        //    doctorSearchBoxRef.current.setName(
        //      response.data.doctorSelected.name
        //    );
        //    setDoctorSelected(response.data.doctorSelected);
        //  }
      }
      controller.setState({
        PACURecordSequence: seq,
        ...state.PACURecordSequence,
      });
    }
  } else if (params?.action === "SAVE" || params?.action === "CONFIRM") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    let saveResp = null;
    let saveErr = null;
    let saveNet = null;

    const data = {
      action: params.action, // SAVE
      data: state.PACURecordSequence?.data,
      encounter: state.selectedOrOrder?.encounter,
      form_code: params.formCode,
      form_name: params.formName,
      form_version: params.formVersion,
    };
    if (state.PACURecordSequence?.id) {
      [saveResp, saveErr, saveNet] = await FormDataDetail.update({
        apiToken: controller.apiToken,
        pk: state.PACURecordSequence?.id,
        data: data,
      });
    } else {
      [saveResp, saveErr, saveNet] = await FormDataList.create({
        apiToken: controller.apiToken,
        data: data,
      });
    }
    if (saveErr) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.cardKey]: saveErr },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    } else {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params.cardKey]: `${params?.action} Success`,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "SUCCESS",
        },
      });
      Action(controller, { ...params, action: "FETCH_PACU" });
    }
  } else if (params?.action === "PRINT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    if (state.PACURecordSequence?.id) {
      // Todo
      // const pngData = await ansTableRef.current.getImageData();
      const [printResp, printErr, printNet] = await ANSFormPrint.post({
        apiToken: controller.apiToken,
        data: {
          form_id: state.PACURecordSequence?.id,
          pdf: true,
          // image: pngData,  // Todo
        },
      });
      if (printErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: printErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
      }
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: "บันทึก Form ก่อนทำรายการ",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params?.action === "LOG") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    if (state.PACURecordSequence?.id) {
      const [logResp, logErr, logNet] = await FormDataActionLogList.list({
        apiToken: controller.apiToken,
        pk: state.PACURecordSequence?.id,
      });
      if (logErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: logErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
          PACURecordSequence: {
            ...state.PACURecordSequence,
            modLogInfo: { open: true, logList: logResp?.items || [] },
          },
        });
      }
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: "ไม่มี Form id",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params?.action === "GET_USER_TOKENIZE_LICENSE") {
    const [userResp, userErr, userNet] = await UserTokenizeLicenseView.post({
      apiToken: controller.apiToken,
      data: {
        code: state.PACURecordSequence?.data?.[`${params.paramKey}Code`],
      },
    });

    let seq: any = { ...state.PACURecordSequence };

    if (userErr) {
      seq.data[params.paramKey] = "";
      seq.data[`${params.paramKey}LicenseNumber`] = "";
      seq.data[`${params.paramKey}Code`] = "";

      controller.setState({
        errorMessage: { ...state.errorMessage, [params.cardKey]: userErr },
      });
    } else {
      const encodedName = userResp.token.split(".")[1];
      seq.data[params.paramKey] = decodeURIComponent(atob(encodedName));
      seq.data[`${params.paramKey}LicenseNumber`] = userResp.license;
    }
    controller.setState({
      PACURecordSequence: seq,
      ...state.PACURecordSequence,
    });
  } else if (params?.action === "GET_LICENSE") {
    // Get Employee Code
    const [employeeResp, employeeErr, employeeNet] =
      await UserEmployeeDetailAPIView.retrieve({
        pk: params.paramKey,
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });

    // Get License
    if (employeeResp?.code) {
      const [userResp, userErr, userNet] = await UserTokenizeLicenseView.post({
        apiToken: controller.apiToken,
        data: {
          code: employeeResp?.code,
        },
        extra: {
          division: controller.data.division,
        },
      });

      let data: any = { ...state.PACURecordSequence?.data };

      if (userErr) {
        data[`${params.dataKey}LicenseNumber`] = "";
        data[`${params.dataKey}Code`] = "";

        controller.setState({
          errorMessage: { ...state.errorMessage, [params.cardKey]: userErr },
          PACURecordSequence: {
            ...state.PACURecordSequence,
            data: data,
          },
        });
      } else {
        data[`${params.dataKey}LicenseNumber`] = userResp.license;
        data[`${params.dataKey}Code`] = employeeResp.code;

        controller.setState({
          errorMessage: { ...state.errorMessage, [params.cardKey]: userErr },
          PACURecordSequence: {
            ...state.PACURecordSequence,
            data: data,
          },
        });
      }
    }
  }
};
