import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  FormGroup,
  FormField,
  Input,
  Dropdown,
  Checkbox
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardORQueueUX = (props: any) => {
    return(
      <div
        style={{ padding: "15px" }}>
        <div
          className="ui form"
          style={{display: props.showFilterORQueue  || props.showFilterWardQueue ? "": "none"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div>
                HN
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{minWidth: "135px"}}>
                {props.patientSearchBox}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{minWidth: "max-content"}}>
                ชื่อ - นามสกุล
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Input
                fluid={true}
                readOnly={true}
                style={{minWidth: "135px"}}
                value={props.ORQueueSequence?.orFilter?.patientFullName || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              style={{paddingRight: "0.25rem"}}>
              <div
                style={{minWidth: "max-content"}}>
                แพทย์ผ่าตัด
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{minWidth: "135px"}}>
                {props.doctorSearchBox}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div>
                Type
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Dropdown
                clearable={true}
                fluid={true}
                name="type"
                onChange={props.onChangeTypeStatus}
                options={props.masterOptions?.operatingType || []}
                search={true}
                selection={true}
                style={{width: "100%", maxWidth: "calc(100vw - 99rem)", minWidth: "220px"}}
                value={props.ORQueueSequence?.orFilter?.type || null}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <div>
                Status
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Dropdown
                clearable={true}
                fluid={true}
                name="status"
                onChange={props.onChangeTypeStatus}
                options={props.masterOptions?.operatingStatus || []}
                search={true}
                selection={true}
                style={{width: "100%",  minWidth: "135px"}}
                value={props.ORQueueSequence?.orFilter?.status || null}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.buttonSearch}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.buttonPrint}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{minWidth: "max-content"}}>
                วันที่ผ่าตัด
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%"}}>
                {props.startORDate}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div>
                ถึง
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%"}}>
                {props.endORDate}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{minWidth: "max-content"}}>
                วันจองห้องผ่าตัด
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%"}}>
                {props.creatORDate}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{fontWeight: "normal", minWidth: "max-content"}}>
                Location
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Dropdown
                clearable={true}
                disabled={props.ORQueueSequence?.orFilter?.no_date_no_room}
                fluid={true}
                name="location"
                onChange={props.onChangeLocationRoom}
                options={props.ORQueueSequence?.locationOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.ORQueueSequence?.orFilter?.operating_detail__operating_room__location_id || null}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <div>
                Room
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Dropdown
                clearable={true}
                disabled={props.ORQueueSequence?.orFilter?.no_date_no_room}
                fluid={true}
                name="room"
                onChange={props.onChangeLocationRoom}
                options={props.ORQueueSequence?.roomOptions || []}
                search={true}
                selection={true}
                style={{minWidth: "125px"}}
                value={props.ORQueueSequence?.orFilter?.operating_detail__operating_room__id || null}>
              </Dropdown>
            </FormField>
            <FormField>
              <Checkbox
                checked={props.ORQueueSequence?.orFilter?.no_date_no_room}
                label="OR Request ไม่ระบุนัดหมาย"
                name="no_date_no_room"
                onChange={props.onChangeNoDateRoom}
                style={{minWidth: "max-content"}}>
              </Checkbox>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form small"
          style={{display: props.showFilterDrugQueue ? "": "none"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div>
                วันที่ผ่าตัด
              </div>
            </FormField>
            <FormField
              inline={true}
              width="2">
              <div
                style={{width: "100%"}}>
                
                <DateTextBox
                  onChange={props.onDateChange("start_date")}
                  value={props.startDate}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%", textAlign: "center" }}>
                ถึง
              </div>
            </FormField>
            <FormField
              inline={true}
              width="2">
              <div
                style={{width: "100%"}}>
                
                <DateTextBox
                  onChange={props.onDateChange("end_date")}
                  value={props.endDate}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width="6">
              <div
                style={{ marginLeft: "20px" }}>
                {props.buttonSearch}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <Table
          data={props.orOrderItems}
          getTrProps={props.getSelectedRow}
          headers={props.showFilterORQueue ? "วันที่ผ่าตัด, HN, ข้อมูลผู้ป่วย, Location/Room, แพทย์ผ่าตัด,Medical Term / Diagnosis, Type, Operation, Special Equipment, Blood, Anesthesia, Status," : "วันที่ผ่าตัด, HN, ข้อมูลผู้ป่วย, Location/Room, แพทย์ผ่าตัด,Medical Term / Diagnosis, Type, Operation, Special Equipment, Blood, Anesthesia, Status"}
          keys={props.showFilterORQueue ? "start_date, hn, patientNameCheckinCode, locationRoom, primary_doctor_name, diagnosis, typeLabel, pre_operation_summary, special_equipments_text, blood_request_summary, anesthesia_method_name, statusNote, menu" : "start_date, hn, patientNameCheckinCode, locationRoom, primary_doctor_name, diagnosis, typeLabel, pre_operation_summary, special_equipments_text, blood_request_summary, anesthesia_method_name, statusNote"}
          minRows="10"
          showPagination={false}
          style={{height: "70vh"}}
          widths={props.showFilterORQueue ? "100, 80, 170, 110,auto,auto,auto,auto,140, 80, 100, 100,80" : "100, 80, 170, 110,auto,auto,auto,auto,140, 80, 100, 100"}>
        </Table>
      </div>
    )
}


export default CardORQueueUX

export const screenPropsDefault = {"current":null}

/* Date Time : Wed May 24 2023 08:21:14 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ padding: \"15px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 51,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.orOrderItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getSelectedRow"
        },
        "headers": {
          "type": "code",
          "value": "props.showFilterORQueue ? \"วันที่ผ่าตัด, HN, ข้อมูลผู้ป่วย, Location/Room, แพทย์ผ่าตัด,Medical Term / Diagnosis, Type, Operation, Special Equipment, Blood, Anesthesia, Status,\" : \"วันที่ผ่าตัด, HN, ข้อมูลผู้ป่วย, Location/Room, แพทย์ผ่าตัด,Medical Term / Diagnosis, Type, Operation, Special Equipment, Blood, Anesthesia, Status\""
        },
        "keys": {
          "type": "code",
          "value": "props.showFilterORQueue ? \"start_date, hn, patientNameCheckinCode, locationRoom, primary_doctor_name, diagnosis, typeLabel, pre_operation_summary, special_equipments_text, blood_request_summary, anesthesia_method_name, statusNote, menu\" : \"start_date, hn, patientNameCheckinCode, locationRoom, primary_doctor_name, diagnosis, typeLabel, pre_operation_summary, special_equipments_text, blood_request_summary, anesthesia_method_name, statusNote\""
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"70vh\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.showFilterORQueue ? \"100, 80, 170, 110,auto,auto,auto,auto,140, 80, 100, 100,80\" : \"100, 80, 170, 110,auto,auto,auto,auto,140, 80, 100, 100\""
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "label": "divgrid",
      "name": "div",
      "parent": 87,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(2, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 84,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"2/5\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showFilterDrugQueue ? \"\": \"none\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormGroup",
      "parent": 132,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "6"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ผ่าตัด"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"center\" }"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "div",
      "parent": 135,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 146,
      "name": "DateTextBox",
      "parent": 144,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onDateChange(\"start_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 147,
      "name": "DateTextBox",
      "parent": 145,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onDateChange(\"end_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "div",
      "parent": 138,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"20px\" }"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showFilterORQueue  || props.showFilterWardQueue ? \"\": \"none\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "FormGroup",
      "parent": 149,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "FormGroup",
      "parent": 149,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "FormField",
      "parent": 150,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: \"0.25rem\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "div",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"135px\"}"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ - นามสกุล"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "Input",
      "parent": 156,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"135px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.patientFullName || \"\""
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 157,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 158,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"135px\"}"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 159,
      "props": {
        "children": {
          "type": "value",
          "value": "Type"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "Dropdown",
      "parent": 160,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTypeStatus"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.operatingType || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"calc(100vw - 99rem)\", minWidth: \"220px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.type || null"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 173,
      "name": "Dropdown",
      "parent": 162,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTypeStatus"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.operatingStatus || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",  minWidth: \"135px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.status || null"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "div",
      "parent": 161,
      "props": {
        "children": {
          "type": "value",
          "value": "Status"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "div",
      "parent": 163,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "div",
      "parent": 164,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 183,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "FormField",
      "parent": 151,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "FormField",
      "parent": 151,
      "props": {
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "div",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "div",
      "parent": 177,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startORDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 178,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "div",
      "parent": 179,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endORDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "div",
      "parent": 180,
      "props": {
        "children": {
          "type": "value",
          "value": "วันจองห้องผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "div",
      "parent": 181,
      "props": {
        "children": {
          "type": "code",
          "value": "props.creatORDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 193,
      "name": "label",
      "parent": 182,
      "props": {
        "children": {
          "type": "value",
          "value": "Location"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"max-content\"}"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 194,
      "name": "Dropdown",
      "parent": 183,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.no_date_no_room"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "location"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeLocationRoom"
        },
        "options": {
          "type": "code",
          "value": "props.ORQueueSequence?.locationOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.operating_detail__operating_room__location_id || null"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": null,
      "id": 195,
      "name": "div",
      "parent": 184,
      "props": {
        "children": {
          "type": "value",
          "value": "Room"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "Dropdown",
      "parent": 185,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.no_date_no_room"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "room"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeLocationRoom"
        },
        "options": {
          "type": "code",
          "value": "props.ORQueueSequence?.roomOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"125px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.operating_detail__operating_room__id || null"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "Checkbox",
      "parent": 186,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORQueueSequence?.orFilter?.no_date_no_room"
        },
        "label": {
          "type": "value",
          "value": "OR Request ไม่ระบุนัดหมาย"
        },
        "name": {
          "type": "value",
          "value": "no_date_no_room"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNoDateRoom"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 197,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardORQueueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "current": null
  },
  "width": 80
}

*********************************************************************************** */
