import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Modal } from "semantic-ui-react";
import CardProgressNoteListUX from "./CardProgressNoteListUX";
import CardProgressNote from "react-lib/apps/DPI/CardProgressNote";
import { ActionMenuColumn } from "./CardOrderSummary";
const CardProgressNoteList = (props: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [progressNoteId, setProgressNoteId] = useState<number | null>(null);

  const getListProgressionNote = useCallback(() => {
    props.onEvent({
      message: "GetListProgressionNote",
      params: { emrId: props.selectedEmr?.id },
    });
  }, [props.selectedEmr?.id]);

  useEffect(() => {
    getListProgressionNote();
  }, [props.selectedEmr?.id]);

  const handleClickAction = useCallback((data: any) => {
    setIsOpen(true);
    setProgressNoteId(data.item.id);
  }, []);

  const progressionNoteItems = useMemo(() => {
    return (props.progressionNoteList || []).map(
      (item: any, index: number) => ({
        ...item,
        menu: (
          <ActionMenuColumn
            key={"menu" + index}
            item={item}
            allowedActions={["EDIT"]}
            isDoctor={props.DJANGO.user?.id === item.editer_id}
            onClickAction={handleClickAction}
          />
        ),
      })
    );
  }, [props.progressionNoteList, props.DJANGO]);

  const handleSuccessCallback = () => {
    getListProgressionNote();
    handleClose();
  };

  const handleCloseModProgressNote = () => {
    handleClose();
  };

  const handleClose = () => {
    setIsOpen(false);
    setProgressNoteId(null);
  };

  const handleCreate = () => {
    setIsOpen(true);
  };

  console.log("CardProgressNoteList", props);

  return (
    <div>
      <CardProgressNoteListUX
        onCreate={handleCreate}
        hideButtonCreate={!props.DJANGO.user?.role_types?.includes("DOCTOR")}
        progressionNoteItems={progressionNoteItems}
      />
      <Modal open={isOpen} size="large">
        {/* @ts-ignore */}
        <CardProgressNote
          apiToken={props.apiToken}
          django={props.DJANGO}
          createModule="DPI"
          doctorId={props.selectedEncounter.doctor}
          emrId={props.selectedEmr?.id}
          progressionNoteId={progressNoteId as number}
          isTitleReporter={!!progressNoteId}
          rows={5}
          // callback
          successCallback={handleSuccessCallback}
          hideCallback={handleCloseModProgressNote}
        />
      </Modal>
    </div>
  );
};

export default CardProgressNoteList;
