import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Checkbox,
  Input,
  Button,
  Dropdown,
  Radio
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardCreateInvoiceGroupUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{backgroundColor: "#FFFFF",width: "100%",height:"100%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"transparent"}}>
        <div
          style={{padding: "10px 0px"}}>
          <div
            style={{display: "flex",height:"100%", fontSize:"18px",fontWeight:"bold",alignItems:"center"}}>
            
            <label>
              ออกใบแจ้งหนี้เรียกเก็บเงินต้นสังกัด
            </label>
          </div>
          <hr>
          </hr>
        </div>
        <div
          className="ui form small">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={1}>
              <div
                style={{display:"flex",width: "100%"}}>
                
                <div>
                  เลือกสิทธิ
                </div>
                <div
                  style={{color: "red"}}>
                  *
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                
                <Dropdown
                  clearable={true}
                  fluid={true}
                  onChange={props.onChangeOption("selectedCoveragePayer")}
                  options={props.coveragePayerOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.selectedCoveragePayer}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <Checkbox
                checked={props.checkedSelectedDate}
                label="ระบุวันที่"
                onChange={props.onChangeChecked}
                style={{minWidth: "max-content"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                
                <DateTextBox
                  disabled={props.disabledDate}
                  onChange={props.onDateChange("startDate")}
                  style={{width: "100%"}}
                  value={props.startDate}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <div
                style={{textAlign: "center", width: "100%"}}>
                ถึง
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                
                <DateTextBox
                  disabled={props.disabledDate}
                  onChange={props.onDateChange("endDate")}
                  style={{width: "100%"}}
                  value={props.endDate}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{textAlign: "center", width: "100%"}}>
                หน่วยงานต้นสังกัดที่เรียกเก็บ
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                
                <Dropdown
                  clearable={true}
                  fluid={true}
                  onChange={props.onChangeOption("selectedPayer")}
                  options={props.payerOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.selectedPayer}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%", textAlign: "center"}}>
                
                <Button
                  color="blue"
                  disabled={!props.selectedCoveragePayer}
                  onClick={props.onSearchClick}>
                  ค้นหา
                </Button>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <Checkbox
                checked={props.checkedInvoiceType === "noInvoice"}
                label="เฉพาะรายการที่ยังไม่ถูกสร้างใบแจ้งหนี้"
                onClick={props.onCheckRadio("noInvoice")}
                style={{minWidth: "max-content"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Checkbox
                checked={props.checkedInvoiceType === "fromPayer"}
                label="เลขที่ใบแจ้งหนี้เรียกเก็บจากต้นสังกัด"
                onClick={props.onCheckRadio("fromPayer")}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%"}}>
                {props.arInvoiceSearch}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <Table
          className="-striped -highlight"
          data={props.billingTransactionItem}
          defaultPageSize="10"
          headers=" Date Transaction,เลขที่ใบแจ้งหนี้,HN, Encounter, ID Card No.,Bill No., Amount, Claim Amount, Paid, สิทธิ,ต้นสังกัด, "
          keys="dt_tran,lot_no,hn,encounter,citizen_no,bill_no,amount,claim_amount,paid,coverage_payer,payer,detail"
          showPagination={false}
          style={{height: "50vh"}}
          widths="150">
        </Table>
        <div
          className="ui form small"
          style={{paddingTop: "20px"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              width={8}>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{textAlign: "right", width: "100%"}}>
                จำนวนข้อมูลที่เบิกได้
              </div>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <Input
                readOnly={true}
                value={props.claimInfoCount}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <div
                style={{textAlign: "center", width: "100%"}}>
                ยอดเบิก
              </div>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <Input
                readOnly={true}
                value={props.claimAmount}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <div
                style={{textAlign: "center", width: "100%"}}>
                ยอดผู้ป่วยจ่าย
              </div>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <Input
                readOnly={true}
                value={props.claimPatientAmount}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <div
                style={{textAlign: "center", width: "100%"}}>
                ยอดร่วมจ่าย
              </div>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <Input
                readOnly={true}
                value={props.coPaySummary}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <div
              className="field"
              style={{flex: 1}}>
              
            </div>
            <FormField>
              <div
                style={{display: "flex", justifyContent: "space-between", flex: "1", paddingTop: "15px"}}>
                
                <Button
                  color="blue"
                  onClick={props.onCreateAR}>
                  สร้างข้อมูลส่งเบิก
                </Button>
                <div>
                  {props.ButtonCreate}
                </div>
                <Button
                  color="teal">
                  พิมพ์เอกสาร
                </Button>
                <Button
                  color="orange">
                  Export Data ส่ง Payer
                </Button>
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{display: "none"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(40, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
            <div
              style={{ gridRow: "2/4", gridColumn: "2/50"}}>
              <div
                style={{display: "flex",height:"100%", fontSize:"18px",fontWeight:"bold",alignItems:"center"}}>
                
                <label>
                  ออกใบแจ้งหนี้เรียกเก็บเงินต้นสังกัด
                </label>
              </div>
              <hr>
              </hr>
            </div>
            <div
              style={{ gridRow: "8/32", gridColumn: "2/50"}}>
              <Table
                defaultPageSize="10"
                headers=" ,Date Transaction,เลขที่ใบแจ้งหนี้,HN, Encounter, ID Card No.,Bill No., Amount, Claim Amount, Paid, สิทธิ,ต้นสังกัด, "
                showPagination={false}>
              </Table>
            </div>
            <div
              style={{ gridRow: "33/34", gridColumn: "28/30"}}>
              <label
                style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center",fontWeight:"bold"}}>
                ยอดเบิก
              </label>
            </div>
            <div
              style={{ gridRow: "33/34", gridColumn: "30/35"}}>
              <Input
                readonly="true">
              </Input>
            </div>
            <div
              style={{ gridRow: "33/34", gridColumn: "40/40"}}>
              <Input
                readonly="true">
              </Input>
            </div>
            <div
              style={{ gridRow: "33/34", gridColumn: "39/40"}}>
            </div>
            <div
              style={{ gridRow: "33/34", gridColumn: "39/45"}}>
            </div>
            <div
              style={{ gridRow: "33/34", gridColumn: "36/39"}}>
              <label
                style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center",fontWeight:"bold"}}>
                ยอดผู้ป่วยจ่าย
              </label>
            </div>
            <div
              style={{ gridRow: "33/34", gridColumn: "42/45"}}>
              <label
                style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center",fontWeight:"bold"}}>
                ยอดร่วมจ่าย
              </label>
            </div>
            <div
              style={{ gridRow: "33/34", gridColumn: "45/49"}}>
              <Input
                readonly="true">
              </Input>
            </div>
            <div
              style={{ gridRow: "36/37", gridColumn: "42/50"}}>
              <Button
                style={{backgroundColor:"#FC9434",padding:"15px 30px", color:"#FFFFFF",fontSize:"18px",width:"100%"}}>
                Export Data ส่ง Payer
              </Button>
            </div>
            <div
              style={{ gridRow: "36/37", gridColumn: "38/41"}}>
              <Button
                style={{backgroundColor:"#0E98B7",padding:"15px 30px", color:"#FFFFFF",fontSize:"18px",width:"100%"}}>
                พิมพ์เอกสาร
              </Button>
            </div>
            <div
              style={{ gridRow: "36/37", gridColumn: "30/37"}}>
              <Button
                style={{backgroundColor:"#27AE60",padding:"15px 30px", color:"#FFFFFF",fontSize:"18px",width:"100%"}}>
                สร้างเลขที่ใบแจ้งหนี้
              </Button>
            </div>
            <div
              style={{ gridRow: "36/37", gridColumn: "23/29"}}>
              <Button
                style={{backgroundColor:"#2185D0",padding:"15px 30px", color:"#FFFFFF",fontSize:"18px",width:"100%"}}>
                สร้างข้อมูลส่งเบิก
              </Button>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "2/5"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <label
                  style={{display: "flex",height:"100%", fontSize:"16px",alignItems:"center",fontWeight:"bold",marginLeft:"10px"}}>
                  เลือกสิทธิ
                </label>
                <label
                  style={{display: "flex",height:"100%", fontSize:"16px",alignItems:"center",fontWeight:"bold",marginLeft:"10px",color:"red"}}>
                  *
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "5/11"}}>
              <Dropdown
                selection={true}
                style={{height:"100%",width:"100%"}}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "12/30"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox>
                </Checkbox>
                <label
                  style={{ marginLeft:"20px",marginRight:"20px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center",fontWeight:"bold"} }>
                  ระบุวันที่ เริ่มต้น 
                </label>
                <DateTextBox
                  style={{marginRight:"20px",height:"100%",width:"100%"}}>
                </DateTextBox>
                <label
                  style={{ marginLeft:"20px",marginRight:"5px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center",fontWeight:"bold"}}>
                  ถึง
                </label>
                <DateTextBox
                  style={{marginLeft:"20px",height:"100%",width:"100%"}}>
                </DateTextBox>
              </div>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "30/35"}}>
              <label
                style={{display: "flex",height:"100%", fontSize:"16px",alignItems:"center",fontWeight:"bold",marginLeft:"10px"}}>
                หน่วยงานต้นสังกัดที่เรียกเก็บ
              </label>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "36/42"}}>
              <Dropdown
                selection={true}
                style={{height:"100%",width:"100%"}}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "2/11",paddingTop:"10px"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Radio>
                </Radio>
                <label
                  style={{ marginLeft:"10px",marginRight:"5px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center",fontWeight:"bold"} }>
                  เฉพาะรายการที่ยังถูกสร้างใบแจ้งหนี้
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "12/25",paddingTop:"10px"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Radio>
                </Radio>
                <label
                  style={{ marginLeft:"10px",marginRight:"5px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center",fontWeight:"bold"} }>
                  เลขที่ใบแจ้งหนี้เรียกเก็บเงินจากต้นสังกัด
                </label>
                <Input
                  icon="search"
                  readonly="true"
                  style={{marginLeft:"10px",width:"300px",height:"100%"}}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "35/40",padding:"10px"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Button
                  style={{backgroundColor:"#3674D0",padding:"10px 15px", color:"#FFFFFF",fontSize:"18px",width:"100%"}}>
                  ค้นหา
                </Button>
              </div>
            </div>
            <div
              style={{ gridRow: "33/34", gridColumn: "23/27" }}>
              <Input
                readonly="true">
              </Input>
            </div>
            <div
              style={{ gridRow: "33/34", gridColumn: "18/23"}}>
              <label
                style={{display: "flex",height:"100%", fontSize:"16px",alignItems:"center",fontWeight:"bold",marginLeft:"10px"}}>
                จำนวนข้อมูลที่ส่งเบิกได้
              </label>
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardCreateInvoiceGroupUX

export const screenPropsDefault = {}

/* Date Time : Wed Mar 01 2023 03:47:04 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#FFFFF\",width: \"100%\",height:\"100%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"transparent\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 158,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormGroup",
      "parent": 158,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "FormField",
      "parent": 159,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "FormField",
      "parent": 159,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "FormField",
      "parent": 159,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "FormField",
      "parent": 159,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "FormField",
      "parent": 159,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "FormField",
      "parent": 159,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "FormField",
      "parent": 159,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "FormField",
      "parent": 159,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "FormField",
      "parent": 159,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 328,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 162,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "div",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 164,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานต้นสังกัดที่เรียกเก็บ"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"center\", width: \"100%\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "Checkbox",
      "parent": 161,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedSelectedDate"
        },
        "label": {
          "type": "value",
          "value": "ระบุวันที่"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeChecked"
        },
        "onClick": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 178,
      "name": "DateTextBox",
      "parent": 171,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabledDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onDateChange(\"startDate\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 179,
      "name": "DateTextBox",
      "parent": 172,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabledDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onDateChange(\"endDate\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "tabIndex": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "div",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"center\", width: \"100%\"}"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"10px 0px\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 182,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"100%\", fontSize:\"18px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "label",
      "parent": 183,
      "props": {
        "children": {
          "type": "value",
          "value": "ออกใบแจ้งหนี้เรียกเก็บเงินต้นสังกัด"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "hr",
      "parent": 182,
      "props": {
      },
      "seq": 185,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 234,
      "name": "FormGroup",
      "parent": 158,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": null,
      "id": 245,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 309,
      "void": false
    },
    {
      "from": null,
      "id": 246,
      "name": "div",
      "parent": 245,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(40, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": null,
      "id": 247,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"2/50\"}"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": null,
      "id": 248,
      "name": "div",
      "parent": 247,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"100%\", fontSize:\"18px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": null,
      "id": 249,
      "name": "label",
      "parent": 248,
      "props": {
        "children": {
          "type": "value",
          "value": "ออกใบแจ้งหนี้เรียกเก็บเงินต้นสังกัด"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": null,
      "id": 250,
      "name": "hr",
      "parent": 247,
      "props": {
      },
      "seq": 250,
      "void": true
    },
    {
      "from": null,
      "id": 251,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/32\", gridColumn: \"2/50\"}"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 252,
      "name": "Table",
      "parent": 251,
      "props": {
        "columns": {
          "type": "value",
          "value": ""
        },
        "defaultPageSize": {
          "type": "value",
          "value": "10"
        },
        "headers": {
          "type": "value",
          "value": " ,Date Transaction,เลขที่ใบแจ้งหนี้,HN, Encounter, ID Card No.,Bill No., Amount, Claim Amount, Paid, สิทธิ,ต้นสังกัด, "
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": null,
      "id": 253,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"33/34\", gridColumn: \"28/30\"}"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": null,
      "id": 254,
      "name": "label",
      "parent": 253,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดเบิก"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\",fontWeight:\"bold\"}"
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": null,
      "id": 255,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"33/34\", gridColumn: \"30/35\"}"
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 256,
      "name": "Input",
      "parent": 255,
      "props": {
        "readonly": {
          "type": "value",
          "value": "true"
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": null,
      "id": 257,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"33/34\", gridColumn: \"40/40\"}"
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 258,
      "name": "Input",
      "parent": 257,
      "props": {
        "readonly": {
          "type": "value",
          "value": "true"
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": null,
      "id": 259,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"33/34\", gridColumn: \"39/40\"}"
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": null,
      "id": 260,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"33/34\", gridColumn: \"39/45\"}"
        }
      },
      "seq": 260,
      "void": false
    },
    {
      "from": null,
      "id": 261,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"33/34\", gridColumn: \"36/39\"}"
        }
      },
      "seq": 261,
      "void": false
    },
    {
      "from": null,
      "id": 262,
      "name": "label",
      "parent": 261,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดผู้ป่วยจ่าย"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\",fontWeight:\"bold\"}"
        }
      },
      "seq": 262,
      "void": false
    },
    {
      "from": null,
      "id": 263,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"33/34\", gridColumn: \"42/45\"}"
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": null,
      "id": 264,
      "name": "label",
      "parent": 263,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดร่วมจ่าย"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\",fontWeight:\"bold\"}"
        }
      },
      "seq": 264,
      "void": false
    },
    {
      "from": null,
      "id": 265,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"33/34\", gridColumn: \"45/49\"}"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 266,
      "name": "Input",
      "parent": 265,
      "props": {
        "readonly": {
          "type": "value",
          "value": "true"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": null,
      "id": 267,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"36/37\", gridColumn: \"42/50\"}"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 268,
      "name": "Button",
      "parent": 267,
      "props": {
        "children": {
          "type": "value",
          "value": "Export Data ส่ง Payer"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor:\"#FC9434\",padding:\"15px 30px\", color:\"#FFFFFF\",fontSize:\"18px\",width:\"100%\"}"
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": null,
      "id": 269,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"36/37\", gridColumn: \"38/41\"}"
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 270,
      "name": "Button",
      "parent": 269,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์เอกสาร"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor:\"#0E98B7\",padding:\"15px 30px\", color:\"#FFFFFF\",fontSize:\"18px\",width:\"100%\"}"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": null,
      "id": 271,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"36/37\", gridColumn: \"30/37\"}"
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 272,
      "name": "Button",
      "parent": 271,
      "props": {
        "children": {
          "type": "value",
          "value": "สร้างเลขที่ใบแจ้งหนี้"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor:\"#27AE60\",padding:\"15px 30px\", color:\"#FFFFFF\",fontSize:\"18px\",width:\"100%\"}"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": null,
      "id": 273,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"36/37\", gridColumn: \"23/29\"}"
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 274,
      "name": "Button",
      "parent": 273,
      "props": {
        "children": {
          "type": "value",
          "value": "สร้างข้อมูลส่งเบิก"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor:\"#2185D0\",padding:\"15px 30px\", color:\"#FFFFFF\",fontSize:\"18px\",width:\"100%\"}"
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": null,
      "id": 275,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"2/5\"}"
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": null,
      "id": 276,
      "name": "div",
      "parent": 275,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": null,
      "id": 277,
      "name": "label",
      "parent": 276,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกสิทธิ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"100%\", fontSize:\"16px\",alignItems:\"center\",fontWeight:\"bold\",marginLeft:\"10px\"}"
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": null,
      "id": 278,
      "name": "label",
      "parent": 276,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"100%\", fontSize:\"16px\",alignItems:\"center\",fontWeight:\"bold\",marginLeft:\"10px\",color:\"red\"}"
        }
      },
      "seq": 278,
      "void": false
    },
    {
      "from": null,
      "id": 279,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"5/11\"}"
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 280,
      "name": "Dropdown",
      "parent": 279,
      "props": {
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": null,
      "id": 281,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"12/30\"}"
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": null,
      "id": 282,
      "name": "div",
      "parent": 281,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 283,
      "name": "Checkbox",
      "parent": 282,
      "props": {
      },
      "seq": 283,
      "void": false
    },
    {
      "from": null,
      "id": 284,
      "name": "label",
      "parent": 282,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุวันที่ เริ่มต้น "
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"20px\",marginRight:\"20px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\",fontWeight:\"bold\"} "
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 285,
      "name": "DateTextBox",
      "parent": 282,
      "props": {
        "style": {
          "type": "code",
          "value": "{marginRight:\"20px\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 285,
      "void": false
    },
    {
      "from": null,
      "id": 286,
      "name": "label",
      "parent": 282,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"20px\",marginRight:\"5px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\",fontWeight:\"bold\"}"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 287,
      "name": "DateTextBox",
      "parent": 282,
      "props": {
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": null,
      "id": 288,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"30/35\"}"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": null,
      "id": 289,
      "name": "label",
      "parent": 288,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานต้นสังกัดที่เรียกเก็บ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"100%\", fontSize:\"16px\",alignItems:\"center\",fontWeight:\"bold\",marginLeft:\"10px\"}"
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": null,
      "id": 290,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"36/42\"}"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 291,
      "name": "Dropdown",
      "parent": 290,
      "props": {
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": null,
      "id": 292,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"2/11\",paddingTop:\"10px\"}"
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": null,
      "id": 293,
      "name": "div",
      "parent": 292,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 294,
      "name": "Radio",
      "parent": 293,
      "props": {
      },
      "seq": 294,
      "void": false
    },
    {
      "from": null,
      "id": 295,
      "name": "label",
      "parent": 293,
      "props": {
        "children": {
          "type": "value",
          "value": "เฉพาะรายการที่ยังถูกสร้างใบแจ้งหนี้"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",marginRight:\"5px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\",fontWeight:\"bold\"} "
        }
      },
      "seq": 295,
      "void": false
    },
    {
      "from": null,
      "id": 296,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"12/25\",paddingTop:\"10px\"}"
        }
      },
      "seq": 296,
      "void": false
    },
    {
      "from": null,
      "id": 297,
      "name": "div",
      "parent": 296,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 298,
      "name": "Radio",
      "parent": 297,
      "props": {
      },
      "seq": 298,
      "void": false
    },
    {
      "from": null,
      "id": 299,
      "name": "label",
      "parent": 297,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบแจ้งหนี้เรียกเก็บเงินจากต้นสังกัด"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",marginRight:\"5px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\",fontWeight:\"bold\"} "
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 300,
      "name": "Input",
      "parent": 297,
      "props": {
        "icon": {
          "type": "value",
          "value": "search"
        },
        "readonly": {
          "type": "value",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",width:\"300px\",height:\"100%\"}"
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": null,
      "id": 301,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"35/40\",padding:\"10px\"}"
        }
      },
      "seq": 301,
      "void": false
    },
    {
      "from": null,
      "id": 302,
      "name": "div",
      "parent": 301,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 302,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 303,
      "name": "Button",
      "parent": 302,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor:\"#3674D0\",padding:\"10px 15px\", color:\"#FFFFFF\",fontSize:\"18px\",width:\"100%\"}"
        }
      },
      "seq": 303,
      "void": false
    },
    {
      "from": null,
      "id": 304,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"33/34\", gridColumn: \"23/27\" }"
        }
      },
      "seq": 304,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 305,
      "name": "Input",
      "parent": 304,
      "props": {
        "readonly": {
          "type": "value",
          "value": "true"
        }
      },
      "seq": 305,
      "void": false
    },
    {
      "from": null,
      "id": 306,
      "name": "div",
      "parent": 246,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"33/34\", gridColumn: \"18/23\"}"
        }
      },
      "seq": 306,
      "void": false
    },
    {
      "from": null,
      "id": 307,
      "name": "label",
      "parent": 306,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนข้อมูลที่ส่งเบิกได้"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"100%\", fontSize:\"16px\",alignItems:\"center\",fontWeight:\"bold\",marginLeft:\"10px\"}"
        }
      },
      "seq": 307,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 308,
      "name": "Table",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "-striped -highlight"
        },
        "columns": {
          "type": "value",
          "value": ""
        },
        "data": {
          "type": "code",
          "value": "props.billingTransactionItem"
        },
        "defaultPageSize": {
          "type": "value",
          "value": "10"
        },
        "headers": {
          "type": "value",
          "value": " Date Transaction,เลขที่ใบแจ้งหนี้,HN, Encounter, ID Card No.,Bill No., Amount, Claim Amount, Paid, สิทธิ,ต้นสังกัด, "
        },
        "keys": {
          "type": "value",
          "value": "dt_tran,lot_no,hn,encounter,citizen_no,bill_no,amount,claim_amount,paid,coverage_payer,payer,detail"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"50vh\"}"
        },
        "widths": {
          "type": "value",
          "value": "150"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": null,
      "id": 309,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"20px\"}"
        }
      },
      "seq": 308,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 310,
      "name": "FormGroup",
      "parent": 309,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 310,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 311,
      "name": "FormGroup",
      "parent": 309,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 311,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 312,
      "name": "FormField",
      "parent": 310,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 313,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 313,
      "name": "FormField",
      "parent": 310,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 314,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 314,
      "name": "FormField",
      "parent": 310,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 315,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 315,
      "name": "FormField",
      "parent": 310,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 316,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 316,
      "name": "FormField",
      "parent": 310,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 317,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 317,
      "name": "FormField",
      "parent": 310,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 318,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 318,
      "name": "FormField",
      "parent": 310,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 319,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 319,
      "name": "FormField",
      "parent": 310,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 328,
      "void": false
    },
    {
      "from": null,
      "id": 320,
      "name": "div",
      "parent": 312,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนข้อมูลที่เบิกได้"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 320,
      "void": false
    },
    {
      "from": null,
      "id": 321,
      "name": "div",
      "parent": 314,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดเบิก"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"center\", width: \"100%\"}"
        }
      },
      "seq": 321,
      "void": false
    },
    {
      "from": null,
      "id": 322,
      "name": "div",
      "parent": 316,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดผู้ป่วยจ่าย"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"center\", width: \"100%\"}"
        }
      },
      "seq": 322,
      "void": false
    },
    {
      "from": null,
      "id": 323,
      "name": "div",
      "parent": 318,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดร่วมจ่าย"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"center\", width: \"100%\"}"
        }
      },
      "seq": 323,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 324,
      "name": "Input",
      "parent": 313,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.claimInfoCount"
        }
      },
      "seq": 324,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 325,
      "name": "Input",
      "parent": 315,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.claimAmount"
        }
      },
      "seq": 325,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 326,
      "name": "Input",
      "parent": 317,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.claimPatientAmount"
        }
      },
      "seq": 326,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 327,
      "name": "Input",
      "parent": 319,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.coPaySummary"
        }
      },
      "seq": 327,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 328,
      "name": "FormField",
      "parent": 310,
      "props": {
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 312,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 338,
      "name": "FormField",
      "parent": 311,
      "props": {
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 369,
      "void": false
    },
    {
      "from": null,
      "id": 343,
      "name": "div",
      "parent": 338,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\", flex: \"1\", paddingTop: \"15px\"}"
        }
      },
      "seq": 343,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 344,
      "name": "Button",
      "parent": 343,
      "props": {
        "children": {
          "type": "value",
          "value": "สร้างข้อมูลส่งเบิก"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCreateAR"
        }
      },
      "seq": 344,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 346,
      "name": "Button",
      "parent": 343,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์เอกสาร"
        },
        "color": {
          "type": "value",
          "value": "teal"
        }
      },
      "seq": 347,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 347,
      "name": "Button",
      "parent": 343,
      "props": {
        "children": {
          "type": "value",
          "value": "Export Data ส่ง Payer"
        },
        "color": {
          "type": "value",
          "value": "orange"
        }
      },
      "seq": 370,
      "void": false
    },
    {
      "from": null,
      "id": 348,
      "name": "div",
      "parent": 166,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 348,
      "void": false
    },
    {
      "from": null,
      "id": 349,
      "name": "div",
      "parent": 165,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 349,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 350,
      "name": "Dropdown",
      "parent": 348,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeOption(\"selectedCoveragePayer\")"
        },
        "options": {
          "type": "code",
          "value": "props.coveragePayerOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedCoveragePayer"
        }
      },
      "seq": 350,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 351,
      "name": "Dropdown",
      "parent": 349,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeOption(\"selectedPayer\")"
        },
        "options": {
          "type": "code",
          "value": "props.payerOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedPayer"
        }
      },
      "seq": 351,
      "void": false
    },
    {
      "from": null,
      "id": 352,
      "name": "div",
      "parent": 169,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"center\"}"
        }
      },
      "seq": 352,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 353,
      "name": "Button",
      "parent": 352,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "!props.selectedCoveragePayer"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearchClick"
        }
      },
      "seq": 353,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 354,
      "name": "FormField",
      "parent": 234,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 355,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 355,
      "name": "FormField",
      "parent": 234,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 358,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 358,
      "name": "FormField",
      "parent": 234,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 360,
      "void": false
    },
    {
      "from": null,
      "id": 359,
      "name": "div",
      "parent": 358,
      "props": {
        "children": {
          "type": "code",
          "value": "props.arInvoiceSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 359,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 364,
      "name": "Checkbox",
      "parent": 354,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedInvoiceType === \"noInvoice\""
        },
        "label": {
          "type": "value",
          "value": "เฉพาะรายการที่ยังไม่ถูกสร้างใบแจ้งหนี้"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCheckRadio(\"noInvoice\")"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 364,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 365,
      "name": "Checkbox",
      "parent": 355,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedInvoiceType === \"fromPayer\""
        },
        "label": {
          "type": "value",
          "value": "เลขที่ใบแจ้งหนี้เรียกเก็บจากต้นสังกัด"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCheckRadio(\"fromPayer\")"
        }
      },
      "seq": 365,
      "void": false
    },
    {
      "from": null,
      "id": 366,
      "name": "div",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",width: \"100%\"}"
        }
      },
      "seq": 366,
      "void": false
    },
    {
      "from": null,
      "id": 367,
      "name": "div",
      "parent": 366,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกสิทธิ"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 367,
      "void": false
    },
    {
      "from": null,
      "id": 368,
      "name": "div",
      "parent": 366,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 368,
      "void": false
    },
    {
      "from": null,
      "id": 369,
      "name": "div",
      "parent": 311,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 330,
      "void": false
    },
    {
      "from": null,
      "id": 370,
      "name": "div",
      "parent": 343,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonCreate"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 346,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": true,
  "memo": false,
  "name": "CardCreateInvoiceGroupUX",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 100
}

*********************************************************************************** */
