import React, {
  useState,
  useEffect,
  useRef,
  SyntheticEvent,
  useMemo,
} from "react";
import {
  Pagination,
  PaginationProps,
  Loader,
  Button,
  Popup,
  Modal,
  TextArea,
  Image,
  Container,
  Segment,
  Icon,
  List,
} from "semantic-ui-react";

import moment from "moment";

// Framework
import { Table } from "react-lib/frameworks/Table";

// UX
import CardAllergyUX from "./CardAllergyUX";
import CardAdverseReactionNote from "./CardAdverseReactionNote";
import CardAllergyActionLog from "./CardAllergyActionLog";

// Common
import { ModConfirm, ErrorMessage } from "react-lib/apps/common";

// Utils
import { formatDate, formatDatetime } from "react-lib/utils/dateUtils";

// CSS
import "./CardAllergy.scss";

type CardAllergyProps = {
  runSequence: any;
  onEvent: any;
  AllergySequence: any;
  setProp: any;
  errorMessage: any;
  successMessage: any;
  masterOptions: any;
  searchedItemList: any;
  selectedDivision: any;
  DJANGO?: any;
  restart?: boolean;
  noEmr?: boolean;
};

const IMAGES = {
  //drug
  drug: "/static/images/Icon_drug.png",
  drug_yellow: "/static/images/allergy/Drug(Yellow).png",
  drug_gray: "/static/images/allergy/Drug(Gray).png",
  drug_white: "/static/images/allergy/Drug_white.png",
  drug_red: "/static/images/allergy/Drug(Red).png",
  //food
  food: "/static/images/allergy/Icon_food.png",
  food_gray: "/static/images/allergy/Food(Gray).png",
  food_red: "/static/images/allergy/Food(Red).png",
  food_white: "/static/images/allergy/Food_white.png",
  //other
  other: "/static/images/allergy/Icon_other.png",
  other_gray: "/static/images/allergy/Other(Gray).png",
  other_red: "/static/images/allergy/Other(Red).png",
  other_white: "/static/images/allergy/other_chemistry.png",
  // verify
  noted: "/static/images/allergy/Note_Status.png",
  verified: "/static/images/allergy/Verified_Status.png",
  locked: "/static/images/allergy/Verified_Lock_Status.png",
  inactive: "/static/images/allergy/Inactive_Status.png",
};

export const CARD_ALLERGY = "CardAllergy";

function CardAllergy(props: CardAllergyProps) {
  const newARDRef = useRef<HTMLDivElement>(null);
  // const [filterRadio, setFilterRadio] = useState<string>("all");
  const [openModNKDAConfirm, setOpenModNKDAConfirm] = useState<boolean>(false);
  const [openModNKFAConfirm, setOpenModNKFAConfirm] = useState<boolean>(false);
  const [openModNKOAConfirm, setOpenModNKOAConfirm] = useState<boolean>(false);
  const [openADRDetail, setOpenADRDetail] = useState<boolean>(false);
  const [openModNote, setOpenModNote] = useState<boolean>(false);
  const [actionLogId, setActionLogId] = useState<number | null>(null);
  const [adrClick, setAdrClick] = useState<any>(null);
  const [actionButton, setActionButton] = useState<string>("");

  const [showNKDAButton, setShowNKDAButton] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(true);
  const [showNoneDrug, setShowNoneDrug] = useState<boolean>(false);
  const [showDrugStatus, setShowDrugStatus] = useState<boolean>(false);
  //KNA = know not allery text
  const [showKNAText, setShowKNAText] = useState<boolean>(false);

  const [openNKA, setOpenNKA] = useState<boolean>(false); //not know allergy
  const [openNFA, setOpenNFA] = useState<boolean>(false); //not food allergy
  const [openNOA, setOpenNOA] = useState<boolean>(false); //not other allergy
  const [openDrug, setOpenDrug] = useState<boolean>(false);
  const [openFood, setOpenFood] = useState<boolean>(false);
  const [openOther, setOpenOther] = useState<boolean>(false);
  const [onEdit, setOnEdit] = useState<any>();

  // const [newADR, setNewADR] = useState<boolean>(false)

  //food
  const [showTableFood, setShowTableFood] = useState<boolean>(true);
  const [showFoodStatus, setShowFoodStatus] = useState<boolean>(false);
  //NKF=not know food
  const [showNKFButton, setShowNKFButton] = useState<boolean>(false);
  const [showKNFText, setShowKNFText] = useState<boolean>(false);

  //other
  const [showTableOther, setShowTableOther] = useState<boolean>(true);
  const [showOtherStatus, setShowOtherStatus] = useState<boolean>(false);
  //NKF=not know other
  const [showNKOButton, setShowNKOButton] = useState<boolean>(false);
  const [showKNOText, setShowKNOText] = useState<boolean>(false);

  const [showInactive, setShowInactive] = useState<boolean>(false);
  const [showPharmacist, setShowPharmacist] = useState<boolean>(false);
  //set

  useEffect(() => {
    props.runSequence({
      sequence: "Allergy",
      restart: props.restart,
      noEmr: props.noEmr,
    });
  }, []);

  const finddrug = props.AllergySequence?.adrList?.filter(
    (item: any) => item?.type_name_name === "DRUG"
  );
  const finddrug_notinactive = props.AllergySequence?.adrList?.filter(
    (item: any) =>
      item?.type_name_name === "DRUG" && item?.is_inactive === false
  );
  const findfood = props.AllergySequence?.adrList?.filter(
    (item: any) => item?.type_name_name === "FOOD"
  );
  const findother = props.AllergySequence?.adrList?.filter(
    (item: any) => item?.type_name_name === "OTHER"
  );
  let findverify = finddrug_notinactive?.filter(
    (item: any) =>
      item?.is_acknowledged === true && item?.status !== "NO_REACTION"
  );
  let findnotverify = finddrug_notinactive?.filter(
    (item: any) =>
      item?.is_acknowledged !== true &&
      item?.status !== "NO_REACTION" &&
      item?.status !== "LOCKED"
  );
  let drug_rec_notinactive = finddrug_notinactive?.filter(
    (item: any) => item?.status === "NO_REACTION"
  );
  let findverifylock = finddrug_notinactive?.filter(
    (item: any) => item?.status === "LOCKED"
  );

  let drug_rec = finddrug?.filter(
    (item: any) => item?.status === "NO_REACTION"
  );
  let drug_name = finddrug?.filter((item: any) => item?.name !== "");
  let food_rec = findfood?.filter(
    (item: any) => item?.status === "NO_REACTION"
  );
  let food_name = findfood?.filter((item: any) => item?.note !== "");
  let other_rec = findother?.filter(
    (item: any) => item?.status === "NO_REACTION"
  );
  let other_name = findother?.filter((item: any) => item?.note !== "");

  // console.log("finddrug", finddrug);
  // console.log("findverify", findverify);
  // console.log("findnotverify", findnotverify);
  // console.log("drug_rec_notinactive", drug_rec_notinactive);
  // console.log("findverifylock", findverifylock);

  useEffect(() => {
    if (props.DJANGO?.user?.role_types.includes("PHARMACIST")) {
      setShowPharmacist(true);
    } else {
      setShowPharmacist(false);
    }
  }, [props.AllergySequence]);
  // console.log(
  //   "PHARMACIST ===",
  //   props.DJANGO?.user?.role_types.includes("PHARMACIST"),
  //   showPharmacist
  // );

  useEffect(() => {
    if (finddrug?.length === 0) {
      setShowTable(false);
    } else if (drug_name?.length === 0 && drug_rec?.length !== 0) {
      setShowTable(false);
    } else if (drug_name?.length !== 0 && drug_rec?.length !== 0) {
      setShowTable(true);
    } else {
      setShowTable(true);
    }
  }, [props.AllergySequence?.adrList]);
  useEffect(() => {
    if (findfood?.length === 0) {
      setShowTableFood(false);
    } else if (food_name?.length === 0 && food_rec?.length !== 0) {
      setShowTableFood(false);
    } else if (food_name?.length !== 0 && food_rec?.length !== 0) {
      setShowTableFood(true);
    } else {
      setShowTableFood(true);
    }
  }, [props.AllergySequence?.adrList]);
  useEffect(() => {
    if (findother?.length === 0) {
      setShowTableOther(false);
    } else if (other_name?.length === 0 && other_rec?.length !== 0) {
      setShowTableOther(false);
    } else if (other_name?.length !== 0 && other_rec?.length !== 0) {
      setShowTableOther(true);
    } else {
      setShowTableOther(true);
    }
  }, [props.AllergySequence?.adrList]);
  useEffect(() => {
    if (
      finddrug?.length !== 0 &&
      drug_name?.length === 0 &&
      drug_rec?.length !== 0
    ) {
      setShowKNAText(true);
    } else {
      setShowKNAText(false);
    }
  }, [props.AllergySequence?.adrList]);
  useEffect(() => {
    if (
      findfood?.length !== 0 &&
      food_name?.length === 0 &&
      food_rec?.length !== 0
    ) {
      setShowKNFText(true);
    } else {
      setShowKNFText(false);
    }
  }, [props.AllergySequence?.adrList]);
  useEffect(() => {
    if (
      findother?.length !== 0 &&
      other_name?.length === 0 &&
      other_rec?.length !== 0
    ) {
      setShowKNOText(true);
    } else {
      setShowKNOText(false);
    }
  }, [props.AllergySequence?.adrList]);
  useEffect(() => {
    if (finddrug?.length === 0) {
      setShowNKDAButton(true);
    } else {
      setShowNKDAButton(false);
    }
  }, [props.AllergySequence?.adrList]);
  useEffect(() => {
    if (findfood?.length === 0) {
      setShowNKFButton(true);
    } else {
      setShowNKFButton(false);
    }
  }, [props.AllergySequence?.adrList]);
  useEffect(() => {
    if (findother?.length === 0) {
      setShowNKOButton(true);
    } else {
      setShowNKOButton(false);
    }
  }, [props.AllergySequence?.adrList]);
  useEffect(() => {
    if (finddrug?.length === 0) {
      setShowNoneDrug(true);
    } else {
      setShowNoneDrug(false);
    }
  }, [props.AllergySequence?.adrList]);
  useEffect(() => {
    if (finddrug?.length !== 0) {
      setShowDrugStatus(true);
    } else {
      setShowDrugStatus(false);
    }
  }, [props.AllergySequence?.adrList]);
  useEffect(() => {
    if (findfood?.length !== 0) {
      setShowFoodStatus(true);
    } else {
      setShowFoodStatus(false);
    }
  }, [props.AllergySequence?.adrList]);
  useEffect(() => {
    if (findother?.length !== 0) {
      setShowOtherStatus(true);
    } else {
      setShowOtherStatus(false);
    }
  }, [props.AllergySequence?.adrList]);

  useEffect(() => {
    if (props?.successMessage?.[CARD_ALLERGY] === "Success" && openModNote) {
      setOpenModNote(false);
      props.setProp("successMessage", {
        ...props.successMessage,
        [CARD_ALLERGY]: null,
      });

      props.setProp("errorMessage", {
        ...props.errorMessage,
        [CARD_ALLERGY]: null,
      });
    }
  }, [props?.successMessage]);

  useEffect(() => {
    if (openADRDetail) {
      if (newARDRef?.current?.scrollIntoView) {
        newARDRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }
  }, [openADRDetail]);

  useEffect(() => {
    console.log(" adrSelected ", props.AllergySequence?.adrSelected);
    if (
      props.AllergySequence?.adrSelected !== null &&
      props.AllergySequence?.adrSelected !== undefined
    ) {
      console.log(" adrSelected TRUE ");
      setOpenADRDetail(true);
    } else if (
      props.AllergySequence?.adrSelected === null ||
      props.AllergySequence?.adrSelected === undefined
    ) {
      console.log(" adrSelected TRUE new ADR ");
      setOpenADRDetail(false);
    }
  }, [props.AllergySequence?.adrSelected]);

  // ADR Detail
  const handleNKDAConfirm = () => {
    props.onEvent({
      message: "RunSequence",
      params: { sequence: "Allergy", action: "noADR" },
    });
    setOpenModNKDAConfirm(false);
  };
  const handleNKFAConfirm = () => {
    props.onEvent({
      message: "RunSequence",
      params: { sequence: "Allergy", action: "noFood" },
    });
    setOpenModNKFAConfirm(false);
  };
  const handleNKOAConfirm = () => {
    props.onEvent({
      message: "RunSequence",
      params: { sequence: "Allergy", action: "noOther" },
    });
    setOpenModNKOAConfirm(false);
  };

  const handleNKDADeny = () => {
    setOpenModNKDAConfirm(false);
  };
  const handleNKFADeny = () => {
    setOpenModNKFAConfirm(false);
  };
  const handleNKOADeny = () => {
    setOpenModNKOAConfirm(false);
  };

  // NOTE
  const handleShowInactive = (e: any, { checked }: any) => {
    props.setProp("AllergySequence.showInactive", checked);

    props.runSequence({
      sequence: "Allergy",
      action: "inactive",
      checked: checked,
    });
  };

  // NOTE
  const handleNoteConfirmed = () => {
    props.runSequence({
      sequence: "Allergy",
      action: "actionButton",
      card: CARD_ALLERGY,
      ...(actionButton === "toggleLock" && { toggleLock: true }),
      ...(actionButton === "cancel" && { cancel: true }),
      item: adrClick,
    });

    //
    // setOpenModNote(false);
    // setActionButton("")
  };

  const handleNoteDeny = () => {
    props.runSequence({
      sequence: "Allergy",
      action: "clearNote",
      card: CARD_ALLERGY,
    });

    // props.setProp("AllergySequence.actionNote", "");
    // props.setProp("successMessage", {
    //   ...props.successMessage,
    //   [CARD_ALLERGY]: null
    // })
    // props.setProp("errorMessage", {
    //   ...props.errorMessage,
    //   [CARD_ALLERGY]: null
    // })

    setOpenModNote(false);
  };

  const handleOnClickNew = () => {
    console.log(" onClickNew ", newARDRef, props.AllergySequence?.adrList);

    if (props.AllergySequence?.adrList?.length > 0) {
      // let adr = props.AllergySequence?.adrList?.find((item: any) => item.status === "NOTED")
      // if (adr) {
      props.runSequence({
        sequence: "Allergy",
        action: "newNote",
        // adr: adr
      });
      // }
    } else {
      props.runSequence({
        sequence: "Allergy",
        action: "newNote",
      });
    }

    // props.setProp("AllergySequence.adrSelected", "new" )
    // setOpenADRnNote(true);
    // setNewADR(true)
  };

  // useEffect(() => {
  //   if (props.AllergySequence?.newADR?.id) {
  //     setOpenADRnNote(true);
  //   } else if (props.AllergySequence?.newADR === null) {
  //     setOpenADRnNote(false);
  //   }
  // }, [props.AllergySequence?.newADR]);

  const handleOnClickNKDA = () => {
    setOpenModNKDAConfirm(true);
  };
  const handleOnClickNKOA = () => {
    setOpenModNKOAConfirm(true);
  };
  const handleOnClickNKF = () => {
    setOpenModNKFAConfirm(true);
  };
  const handleOnClickNKA = () => {
    setOpenNKA(true);
  };
  const handleOnClickNFA = () => {
    setOpenNFA(true);
  };
  const handleOnClickNOA = () => {
    setOpenNOA(true);
  };
  const handleOnClickDrug = () => {
    setOpenDrug(true);
  };
  const handleOnClickFood = () => {
    setOpenFood(true);
  };
  const handleOnClickOther = () => {
    setOpenOther(true);
  };
  const openNote = (item: any) => {
    setAdrClick(item);
    setOpenModNote(true);
  };

  const handleCancelNewADRNote = () => {
    props.onEvent({
      message: "RunSequence",
      params: { sequence: "Allergy", action: "clear" },
    });
    setOpenADRDetail(false);
  };

  const handleSaveNewADRNote = () => {
    if (props?.AllergySequence?.adrSelected) {
      props.onEvent({
        message: "RunSequence",
        params: { sequence: "Allergy", action: "save", card: CARD_ALLERGY },
      });
    }
  };

  const handleSaveCommonADRNote = () => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "Allergy",
        action: "saveIngredientData",
        card: CARD_ALLERGY,
      },
    });
  };

  const adrListData = useMemo(
    () =>
      props.AllergySequence?.adrList
        ?.filter(
          (item: any) =>
            item?.type_name_name === "DRUG" && item?.status !== "NO_REACTION"
        )
        ?.map((item: any) => {
          return {
            ...item,
            adversary_symptom_type: (
              <>
                {/*  freetext ไม่ต้องแสดงประเภท */}
                {item.adversary_symptom_type === "ALLERGY" &&
                item.adversary_type !== "OTHER"
                  ? "แพ้ยา"
                  : item.adversary_symptom_type === "SIDE_EFFECT" &&
                    item.adversary_type !== "OTHER"
                  ? "มีอาการข้างเคียง"
                  : item.adversary_type !== "OTHER"
                  ? item.adversary_symptom_type
                  : ""}
              </>
            ),
            name: (
              <>
                {item.adversary_type === "PRODUCT"
                  ? `${item.name} (Product)`
                  : item.adversary_type === "INGREDIENT"
                  ? `${item.name} (Ingredient)`
                  : item.adversary_type === "MIMS"
                  ? `${item.name} (MIMS Group)`
                  : item.adversary_type === "ATC"
                  ? `${item.name} (ATC Group)`
                  // : item.adversary_type === "OTHER"
                  // ? `${item.name} (FreeText)`
                  : item.name}
              </>
            ),
            probability: (
              <>
                {
                  props.masterOptions?.ADRProbability?.find(
                    (opt: any) => opt.key === item.probability
                  )?.text
                }
              </>
            ),
            symptoms: (
              <>
                {/*  freetext ไม่ต้องแสดงวันที่เกิดอาการ */}
                <div>
                  {item.adversary_type !== "OTHER" &&
                    item.symptoms
                      .map((sym: any) => {
                        return props.masterOptions?.ADRSymptoms?.find(
                          (opt: any) => opt.key === sym
                        )?.text;
                      })
                      .join(", ")}
                </div>
                {item.adversary_type !== "OTHER" && item?.issue_date && (
                  <div>
                    วันที่เกิดอาการ:{" "}
                    {formatDate(moment(item?.issue_date, "DD-MM-YYYY"))}
                  </div>
                )}
              </>
            ),
            //name:(<>{item.adversary_type === ""}</>),
            status: (
              <>
                {item.status === "NOTED" &&
                item?.is_acknowledged === false &&
                item?.is_inactive === false ? (
                  <Popup
                    content={"NOTED"}
                    trigger={
                      <Image width={35} height={35} src={IMAGES.noted} />
                    }
                    position="left center"
                  />
                ) : item.status === "NOTED" &&
                  item?.is_acknowledged === true &&
                  item?.is_inactive === false ? (
                  <Popup
                    content={
                      <div>
                        <List>
                          <List.Item>VERIFIED (สั่งยาได้)</List.Item>
                          <List.Item>{item?.edit_user}</List.Item>
                          <List.Item>{item?.edited}</List.Item>
                        </List>
                      </div>
                    }
                    trigger={
                      <Image width={30} height={30} src={IMAGES.verified} />
                    }
                    position="left center"
                  />
                ) : item.status === "LOCKED" && item?.is_inactive === false ? (
                  <Popup
                    content={
                      <div>
                        <List>
                          <List.Item>VERIFIED & LOCKED (ห้ามสั่งยา)</List.Item>
                          <List.Item>{item?.edit_user}</List.Item>
                          <List.Item>{item?.edited}</List.Item>
                        </List>
                      </div>
                    }
                    trigger={
                      <Image width={47} height={37} src={IMAGES.locked} />
                    }
                    position="left center"
                    size="small"
                  />
                ) : item?.is_inactive === true ? (
                  <Popup
                    content={
                      <div>
                        <List>
                          <List.Item>INACTIVE</List.Item>
                          <List.Item>{item?.note}</List.Item>
                          <List.Item>{item?.edit_user}</List.Item>
                          <List.Item>{item?.edited}</List.Item>
                        </List>
                      </div>
                    }
                    trigger={
                      <Image width={35} height={35} src={IMAGES.inactive} />
                    }
                    position="left center"
                  />
                ) : null}
              </>
            ),
            button: (
              <>
                <Popup
                  content={"EDIT"}
                  trigger={
                    <Button
                      style={{
                        display: props.DJANGO?.user?.role_types.includes(
                          "PHARMACIST"
                        )
                          ? "inline-block"
                          : "none",
                      }}
                      size="mini"
                      icon="edit"
                      color="yellow"
                      onClick={(event: SyntheticEvent) => {
                        event.stopPropagation();

                        setOnEdit(item?.id);
                        props.runSequence({
                          sequence: "Allergy",
                          action: "selectedADR",
                          original: item,
                        });
                      }}
                    />
                  }
                />
                {item.status !== "NO_REACTION" ? (
                  <Popup
                    content={"CANCEL"}
                    trigger={
                      <Button
                        size="mini"
                        icon="trash alternate"
                        color="red"
                        onClick={(event: SyntheticEvent) => {
                          event.stopPropagation();
                          openNote(item);
                          setActionButton("cancel");
                        }}
                      />
                    }
                  />
                ) : null}

                <Button
                  size="mini"
                  icon="history"
                  onClick={(event: SyntheticEvent) => {
                    event.stopPropagation();
                    setActionLogId(item?.id);
                  }}
                />
              </>
            ),
          };
        }),

    [props.AllergySequence?.adrList]
  );
  const adrListDataFood = useMemo(
    () =>
      props.AllergySequence?.adrList
        ?.filter(
          (item: any) =>
            item?.type_name_name === "FOOD" && item?.status !== "NO_REACTION"
        )
        ?.map((item: any) => {
          return {
            ...item,
            probability: (
              <>
                {
                  props.masterOptions?.ADRProbability?.find(
                    (opt: any) => opt.key === item.probability
                  )?.text
                }
              </>
            ),
            symptoms: (
              <>
                {item.symptoms
                  .map((sym: any) => {
                    return props.masterOptions?.ADRSymptoms?.find(
                      (opt: any) => opt.key === sym
                    )?.text;
                  })
                  .join(", ")}
              </>
            ),
            status: (
              <>
                {item?.status === "NOTED" ? (
                  <Popup
                    content={"NOTED"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    trigger={
                      <Button
                        size="mini"
                        circular
                        icon="exclamation"
                        color="yellow"
                      />
                    }
                  />
                ) : (
                  item?.status
                )}
              </>
            ),
            button: (
              <>
                <Popup
                  content={"EDIT"}
                  trigger={
                    <Button
                      style={{
                        display: props.DJANGO?.user?.role_types.includes(
                          "PHARMACIST"
                        )
                          ? "inline-block"
                          : "none",
                      }}
                      size="mini"
                      icon="edit"
                      color="yellow"
                      onClick={(event: SyntheticEvent) => {
                        event.stopPropagation();

                        setOnEdit(item?.id);
                        props.runSequence({
                          sequence: "Allergy",
                          action: "selectedADR",
                          original: item,
                        });
                      }}
                    />
                  }
                />
                {item.status !== "NO_REACTION" ? (
                  <Popup
                    content={"CANCEL"}
                    trigger={
                      <Button
                        size="mini"
                        icon="trash alternate"
                        color="red"
                        onClick={(event: SyntheticEvent) => {
                          event.stopPropagation();
                          openNote(item);
                          setActionButton("cancel");
                        }}
                      />
                    }
                  />
                ) : null}

                <Button
                  size="mini"
                  icon="history"
                  onClick={(event: SyntheticEvent) => {
                    event.stopPropagation();
                    setActionLogId(item?.id);
                  }}
                />
              </>
            ),
          };
        }),

    [props.AllergySequence?.adrList]
  );
  const adrListDataOther = useMemo(
    () =>
      props.AllergySequence?.adrList
        ?.filter(
          (item: any) =>
            item?.type_name_name === "OTHER" && item?.status !== "NO_REACTION"
        )
        ?.map((item: any) => {
          return {
            ...item,
            probability: (
              <>
                {
                  props.masterOptions?.ADRProbability?.find(
                    (opt: any) => opt.key === item.probability
                  )?.text
                }
              </>
            ),
            symptoms: (
              <>
                {item.symptoms
                  .map((sym: any) => {
                    return props.masterOptions?.ADRSymptoms?.find(
                      (opt: any) => opt.key === sym
                    )?.text;
                  })
                  .join(", ")}
              </>
            ),
            status: (
              <>
                {item?.status === "NOTED" ? (
                  <Popup
                    content={"NOTED"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    trigger={
                      <Button
                        size="mini"
                        circular
                        icon="exclamation"
                        color="yellow"
                      />
                    }
                  />
                ) : (
                  item?.status
                )}
              </>
            ),
            button: (
              <>
                <Popup
                  content={"EDIT"}
                  trigger={
                    <Button
                      style={{
                        display: props.DJANGO?.user?.role_types.includes(
                          "PHARMACIST"
                        )
                          ? "inline-block"
                          : "none",
                      }}
                      size="mini"
                      icon="edit"
                      color="yellow"
                      onClick={(event: SyntheticEvent) => {
                        event.stopPropagation();

                        setOnEdit(item?.id);
                        props.runSequence({
                          sequence: "Allergy",
                          action: "selectedADR",
                          original: item,
                        });
                      }}
                    />
                  }
                />
                {item.status !== "NO_REACTION" ? (
                  <Popup
                    content={"CANCEL"}
                    trigger={
                      <Button
                        size="mini"
                        icon="trash alternate"
                        color="red"
                        onClick={(event: SyntheticEvent) => {
                          event.stopPropagation();
                          openNote(item);
                          setActionButton("cancel");
                        }}
                      />
                    }
                  />
                ) : null}

                <Button
                  size="mini"
                  icon="history"
                  onClick={(event: SyntheticEvent) => {
                    event.stopPropagation();
                    setActionLogId(item?.id);
                  }}
                />
              </>
            ),
          };
        }),

    [props.AllergySequence?.adrList]
  );

  const actionLogData = useMemo(
    () =>
      props.AllergySequence?.actionLog?.items?.map((item: any) => ({
        ...item,
        datetime: formatDatetime(item.datetime),
      })),
    [props.AllergySequence?.actionLog]
  );

  return (
    <div
      className="cardAllergy"
      style={{
        height: "calc(100vh - 4.5rem)",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <CardAllergyUX
        angle_down={
          <div>
            <Icon size="large" name="angle down"></Icon>
          </div>
        }
        disabledNKDA={showNKDAButton ? "inline-block" : "none"}
        onRadioFilter={(e: any, { value }: any) => {
          props.onEvent({
            message: "RunSequence",
            params: { sequence: "Allergy", action: "filter", filter: value },
          });

          // props.setProp("AllergySequence.filterRadio", value);
        }}
        loadingComponent={
          props?.AllergySequence?.loadingADRTable ? (
            <Loader active inline="centered" style={{ marginLeft: "20px" }} />
          ) : null
        }
        inactive={props.AllergySequence?.showInactive}
        changeInactive={handleShowInactive}
        drug_lmage={<Image width={45} height={45} src={IMAGES.drug}></Image>}
        drug_status={
          showDrugStatus === false ? null : showTable === false ? (
            <Image
              display={showDrugStatus}
              width={35}
              height={35}
              onClick={handleOnClickNKA}
              src={IMAGES.drug_gray}
            ></Image>
          ) : findverify?.legth === 0 ? (
            <Image
              display={showDrugStatus}
              width={35}
              height={35}
              onClick={handleOnClickDrug}
              src={IMAGES.drug_yellow}
            ></Image>
          ) : findverify?.length !== 0 &&
            drug_rec_notinactive?.length !== 0 &&
            findnotverify?.length === 0 ? (
            <Image
              display={showDrugStatus}
              width={35}
              height={35}
              onClick={handleOnClickDrug}
              src={IMAGES.drug_red}
            ></Image>
          ) : findverifylock?.length !== 0 &&
            drug_rec_notinactive?.length !== 0 &&
            findnotverify?.length === 0 ? (
            <Image
              display={showDrugStatus}
              width={35}
              height={35}
              onClick={handleOnClickDrug}
              src={IMAGES.drug_red}
            ></Image>
          ) : findverifylock?.length !== 0 &&
            drug_rec_notinactive?.length === 0 &&
            findnotverify?.length === 0 ? (
            <Image
              display={showDrugStatus}
              width={35}
              height={35}
              onClick={handleOnClickDrug}
              src={IMAGES.drug_red}
            ></Image>
          ) : findverify?.length !== 0 &&
            drug_rec_notinactive?.length === 0 &&
            findnotverify?.length === 0 ? (
            <Image
              display={showDrugStatus}
              width={35}
              height={35}
              onClick={handleOnClickDrug}
              src={IMAGES.drug_red}
            ></Image>
          ) : findverify?.length !== 0 &&
            drug_rec_notinactive?.length !== 0 &&
            findverifylock?.length !== 0 &&
            findnotverify?.length === 0 ? (
            <Image
              display={showDrugStatus}
              width={35}
              height={35}
              onClick={handleOnClickDrug}
              src={IMAGES.drug_red}
            ></Image>
          ) : findverify?.length !== 0 &&
            findnotverify?.length === 0 &&
            findverifylock?.length !== 0 ? (
            <Image
              display={showDrugStatus}
              width={35}
              height={35}
              onClick={handleOnClickDrug}
              src={IMAGES.drug_red}
            ></Image>
          ) : (
            <Image
              display={showDrugStatus}
              width={35}
              height={35}
              onClick={handleOnClickDrug}
              src={IMAGES.drug_yellow}
            ></Image>
          )
        }
        showTableDrug={showTable ? showTable : "none"}
        showTextKNA={
          showKNAText ? (
            <div>
              <div style={{ fontWeight: "bold", fontSize: "large" }}>
                <Container style={{ fontSize: "1.7rem" }} textAlign="center">
                  {" "}
                  ผู้ป่วยปฏิเสธแพ้ยา [Not Know Allergy]
                </Container>
                <Container
                  style={{
                    fontSize: "1rem",
                    fontWeight: "normal",
                    marginTop: "20px",
                  }}
                  textAlign="center"
                >
                  ผู้บันทึก : {props?.AllergySequence?.adrList?.[0]?.edit_user}{" "}
                  [{props?.selectedDivision?.name}]
                </Container>
                <Container
                  style={{ fontSize: "1rem", fontWeight: "normal" }}
                  textAlign="center"
                >
                  วันที่-เวลา : {props?.AllergySequence?.adrList?.[0]?.edited}
                </Container>
              </div>
            </div>
          ) : null
        }
        showTextNoneDrug={showNoneDrug && <SubNotFound />}
        showLineNoneDrug={
          showNoneDrug ? (
            <div style={{ padding: "30px 20px 0px 20px" }}>
              <div
                style={{ flex: 1, height: "1px", backgroundColor: "#D8D9D9" }}
              ></div>
            </div>
          ) : null
        }
        showLineKNA={
          showKNAText ? (
            <div style={{ padding: "30px 20px 0px 20px" }}>
              <div
                style={{ flex: 1, height: "1px", backgroundColor: "#D8D9D9" }}
              ></div>
            </div>
          ) : null
        }
        modalKDA={
          <Modal
            open={openNKA}
            onClose={() => setOpenNKA(false)}
            onOpen={() => setOpenNKA(true)}
            size="large"
          >
            <Segment
              inverted
              className="blue"
              style={{ margin: 0, fontWeight: "bold", fontSize: "large" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "row",
                  alignItems: "end",
                  marginLeft: "20px",
                }}
              >
                <Image width={45} height={45} src={IMAGES.drug_white}></Image>
                <div style={{ marginLeft: "10px" }}> ประวัติการแพ้ยา </div>
              </div>
            </Segment>
            <Modal.Content>
              <div style={{ fontWeight: "bold" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "row",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <Image width={30} height={30} src={IMAGES.drug_gray}></Image>
                  <div style={{ marginLeft: "10px" }}> ปฏิเสธแพ้ยา </div>
                </div>
                <div>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginTop: "20px",
                    }}
                  >
                    <Container
                      style={{ fontSize: "1.7rem" }}
                      textAlign="center"
                    >
                      {" "}
                      ผู้ป่วยปฏิเสธแพ้ยา [Not Know Allergy]
                    </Container>
                    <Container
                      style={{
                        fontSize: "1rem",
                        fontWeight: "normal",
                        marginTop: "20px",
                      }}
                      textAlign="center"
                    >
                      ผู้บันทึก :{" "}
                      {props?.AllergySequence?.adrList?.[0]?.edit_user} [
                      {props?.selectedDivision?.name}]
                    </Container>
                    <Container
                      style={{ fontSize: "1rem", fontWeight: "normal" }}
                      textAlign="center"
                    >
                      วันที่-เวลา :{" "}
                      {props?.AllergySequence?.adrList?.[0]?.edited}
                    </Container>
                  </div>
                </div>
              </div>
            </Modal.Content>
          </Modal>
        }
        modalKFA={
          <Modal
            open={openNFA}
            onClose={() => setOpenNFA(false)}
            onOpen={() => setOpenNFA(true)}
            size="large"
          >
            <Segment
              inverted
              className="blue"
              style={{ margin: 0, fontWeight: "bold", fontSize: "large" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "row",
                  alignItems: "end",
                  marginLeft: "20px",
                }}
              >
                <Image width={45} height={45} src={IMAGES.food_white}></Image>
                <div style={{ marginLeft: "10px" }}> ประวัติการแพ้อาหาร </div>
              </div>
            </Segment>
            <Modal.Content>
              <div style={{ fontWeight: "bold" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "row",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <Image width={30} height={30} src={IMAGES.food_gray}></Image>
                  <div style={{ marginLeft: "10px" }}> ปฏิเสธแพ้อาหาร </div>
                </div>
                <div>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginTop: "20px",
                    }}
                  >
                    <Container
                      style={{ fontSize: "1.7rem" }}
                      textAlign="center"
                    >
                      {" "}
                      ผู้ป่วยปฏิเสธแพ้อาหาร [Not Know Allergy]
                    </Container>
                    <Container
                      style={{
                        fontSize: "1rem",
                        fontWeight: "normal",
                        marginTop: "20px",
                      }}
                      textAlign="center"
                    >
                      ผู้บันทึก : {findfood?.[0]?.edit_user} [
                      {props?.selectedDivision?.name}]
                    </Container>
                    <Container
                      style={{ fontSize: "1rem", fontWeight: "normal" }}
                      textAlign="center"
                    >
                      วันที่-เวลา : {findfood?.[0]?.edited}
                    </Container>
                  </div>
                </div>
              </div>
            </Modal.Content>
          </Modal>
        }
        modalKOA={
          <Modal
            open={openNOA}
            onClose={() => setOpenNOA(false)}
            onOpen={() => setOpenNOA(true)}
            size="large"
          >
            <Segment
              inverted
              className="blue"
              style={{ margin: 0, fontWeight: "bold", fontSize: "large" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "row",
                  alignItems: "end",
                  marginLeft: "20px",
                }}
              >
                <Image width={45} height={45} src={IMAGES.other_white}></Image>
                <div style={{ marginLeft: "10px" }}>
                  {" "}
                  ประวัติการแพ้สารอื่นๆ{" "}
                </div>
              </div>
            </Segment>
            <Modal.Content>
              <div style={{ fontWeight: "bold" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "row",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <Image width={30} height={30} src={IMAGES.other_gray}></Image>
                  <div style={{ marginLeft: "10px" }}> ปฏิเสธแพ้สารอื่นๆ </div>
                </div>
                <div>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginTop: "20px",
                    }}
                  >
                    <Container
                      style={{ fontSize: "1.7rem" }}
                      textAlign="center"
                    >
                      {" "}
                      ผู้ป่วยปฏิเสธแพ้สารอื่นๆ [Not Know Allergy]
                    </Container>
                    <Container
                      style={{
                        fontSize: "1rem",
                        fontWeight: "normal",
                        marginTop: "20px",
                      }}
                      textAlign="center"
                    >
                      ผู้บันทึก : {findother?.[0]?.edit_user} [
                      {props?.selectedDivision?.name}]
                    </Container>
                    <Container
                      style={{ fontSize: "1rem", fontWeight: "normal" }}
                      textAlign="center"
                    >
                      วันที่-เวลา : {findother?.[0]?.edited}
                    </Container>
                  </div>
                </div>
              </div>
            </Modal.Content>
          </Modal>
        }
        loadingTable={props?.AllergySequence?.loadingADRTable}
        filterRadio={props?.AllergySequence?.filterRadio}
        onClickNKDA={handleOnClickNKDA}
        onClickNKF={handleOnClickNKF}
        onClickNKOA={handleOnClickNKOA}
        onClickNew={handleOnClickNew}
        adrList={adrListData}
        getTrPropsAdrTable={(state: any, rowInfo: any) => {
          // console.log("rowInfo", rowInfo);
          if (rowInfo && rowInfo.row) {
            let background =
              rowInfo?.original?.id ===
              (props.AllergySequence?.adrSelected !== null
                ? props.AllergySequence?.adrSelected?.id
                : null)
                ? "blueSelectedRow"
                : "";

            // console.log("background", background);
            return {
              // onClick: (e: any) => {
              //   // props.setProp("OrderSupplySequence.searchHistorySelected", rowInfo?.original)

              //   props.runSequence({
              //     sequence: "Allergy",
              //     action: "selectedADR",
              //     original: props.AllergySequence?.adrList.find(
              //       (item: any) => rowInfo?.original?.id == item.id
              //     ),
              //   });
              // },

              className: background,
            };
          } else {
            return {};
          }
        }}
        modalDrug={
          <Modal
            open={openDrug}
            onClose={() => setOpenDrug(false)}
            onOpen={() => setOpenDrug(true)}
            size="large"
          >
            <div className="cardAllergy">
              <Segment
                inverted
                className="blue"
                style={{ margin: 0, fontWeight: "bold", fontSize: "large" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "row",
                    alignItems: "end",
                    marginLeft: "20px",
                  }}
                >
                  <Image width={45} height={45} src={IMAGES.drug_white}></Image>
                  <div style={{ marginLeft: "10px" }}> ประวัติการแพ้ยา </div>
                </div>
              </Segment>
              <Modal.Content>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "row",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    {findverify?.legth === 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "row",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          display={showDrugStatus}
                          width={30}
                          height={30}
                          src={IMAGES.drug_yellow}
                        ></Image>
                        <div
                          style={{
                            marginLeft: "10px",
                            color: "#F3AD25",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          มีประวัติแพ้ยา{" "}
                        </div>
                      </div>
                    ) : findverify?.length !== 0 &&
                      drug_rec_notinactive?.length !== 0 &&
                      findnotverify?.length === 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "row",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          display={showDrugStatus}
                          width={30}
                          height={30}
                          src={IMAGES.drug_red}
                        ></Image>
                        <div
                          style={{
                            marginLeft: "10px",
                            color: "red",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          มีประวัติแพ้ยา{" "}
                        </div>
                      </div>
                    ) : findverifylock?.length !== 0 &&
                      drug_rec_notinactive?.length !== 0 &&
                      findnotverify?.length === 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "row",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          display={showDrugStatus}
                          width={30}
                          height={30}
                          src={IMAGES.drug_red}
                        ></Image>
                        <div
                          style={{
                            marginLeft: "10px",
                            color: "red",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          มีประวัติแพ้ยา{" "}
                        </div>
                      </div>
                    ) : findverifylock?.length !== 0 &&
                      drug_rec_notinactive?.length === 0 &&
                      findnotverify?.length === 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "row",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          display={showDrugStatus}
                          width={30}
                          height={30}
                          src={IMAGES.drug_red}
                        ></Image>
                        <div
                          style={{
                            marginLeft: "10px",
                            color: "red",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          มีประวัติแพ้ยา{" "}
                        </div>
                      </div>
                    ) : findverify?.length !== 0 &&
                      drug_rec_notinactive?.length === 0 &&
                      findnotverify?.length === 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "row",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          display={showDrugStatus}
                          width={30}
                          height={30}
                          src={IMAGES.drug_red}
                        ></Image>
                        <div
                          style={{
                            marginLeft: "10px",
                            color: "red",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          มีประวัติแพ้ยา{" "}
                        </div>
                      </div>
                    ) : findverify?.length !== 0 &&
                      drug_rec_notinactive?.length !== 0 &&
                      findverifylock?.length !== 0 &&
                      findnotverify?.length === 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "row",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          display={showDrugStatus}
                          width={30}
                          height={30}
                          src={IMAGES.drug_red}
                        ></Image>
                        <div
                          style={{
                            marginLeft: "10px",
                            color: "red",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          มีประวัติแพ้ยา{" "}
                        </div>
                      </div>
                    ) : findverify?.length !== 0 &&
                      findnotverify?.length === 0 &&
                      findverifylock?.length !== 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "row",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          display={showDrugStatus}
                          width={30}
                          height={30}
                          src={IMAGES.drug_red}
                        ></Image>
                        <div
                          style={{
                            marginLeft: "10px",
                            color: "red",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          มีประวัติแพ้ยา{" "}
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "row",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          display={showDrugStatus}
                          width={30}
                          height={30}
                          src={IMAGES.drug_yellow}
                        ></Image>
                        <div
                          style={{
                            marginLeft: "10px",
                            color: "#F3AD25",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          มีประวัติแพ้ยา{" "}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <div style={{ padding: "30px 20px 0px 20px" }}>
                      <Table
                        data={adrListData}
                        getTrProps={(state: any, rowInfo: any) => {
                          // console.log("rowInfo", rowInfo);
                          if (rowInfo && rowInfo.row) {
                            let background =
                              rowInfo?.original?.id ===
                              (props.AllergySequence?.adrSelected !== null
                                ? props.AllergySequence?.adrSelected?.id
                                : null)
                                ? "blueSelectedRow"
                                : "";
                            return {
                              className: background,
                            };
                          } else {
                            return {};
                          }
                        }}
                        headers=",ชื่อ,อาการ,ความรุนแรง,ข้อความแจ้งเตือน,ผู้บันทึก,วันที่-เวลา"
                        keys="status,name,symptoms,severity_name,note,edit_user,edited"
                        minRows="1"
                        showPagination={false}
                        widths="60,auto,130,130,150,100,100"
                      ></Table>
                    </div>
                  </div>
                </div>
              </Modal.Content>
            </div>
          </Modal>
        }
        paginationComponent={
          props.AllergySequence?.adrTotalPage > 0 ? (
            <Pagination
              boundaryRange={0}
              ellipsisItem={null}
              siblingRange={1}
              disabled={props?.AllergySequence?.loadingADRTable}
              activePage={props.AllergySequence?.adrActivePage || 1}
              totalPages={props.AllergySequence?.adrTotalPage}
              onPageChange={(
                e: SyntheticEvent,
                { activePage }: PaginationProps
              ) => {
                if (props.AllergySequence?.adrActivePage !== activePage) {
                  // Fetch data
                  props.runSequence({
                    sequence: "Allergy",
                    action: "selectedPage",
                    toActivePage: activePage,
                  });
                }
              }}
            />
          ) : null
        }
        //Food
        disabledNKF={showNKFButton ? "inline-block" : "none"}
        food_lmage={<Image width={45} height={45} src={IMAGES.food}></Image>}
        adrListFood={adrListDataFood}
        showTable_Food={showTableFood ? !showTableFood : "none"}
        food_status={
          showFoodStatus === false ? null : showTableFood === false ? (
            <Image
              display={showFoodStatus}
              width={35}
              height={35}
              onClick={handleOnClickNFA}
              src={IMAGES.food_gray}
            ></Image>
          ) : (
            <Image
              display={showFoodStatus}
              width={35}
              height={35}
              onClick={handleOnClickFood}
              src={IMAGES.food_red}
            ></Image>
          )
        }
        modalFood={
          <Modal
            open={openFood}
            onClose={() => setOpenFood(false)}
            onOpen={() => setOpenFood(true)}
            size="large"
          >
            <div className="cardAllergy">
              <Segment
                inverted
                className="blue"
                style={{ margin: 0, fontWeight: "bold", fontSize: "large" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "row",
                    alignItems: "end",
                    marginLeft: "20px",
                  }}
                >
                  <Image width={45} height={45} src={IMAGES.food_white}></Image>
                  <div style={{ marginLeft: "10px" }}> ประวัติการแพ้อาหาร </div>
                </div>
              </Segment>
              <Modal.Content>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "row",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <Image width={30} height={30} src={IMAGES.food_red}></Image>
                    <div
                      style={{
                        marginLeft: "10px",
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      มีประวัติแพ้อาหาร{" "}
                    </div>
                  </div>
                  <div>
                    <div style={{ padding: "30px 20px 0px 20px" }}>
                      <Table
                        data={adrListDataFood}
                        getTrProps={(state: any, rowInfo: any) => {
                          // console.log("rowInfo", rowInfo);
                          if (rowInfo && rowInfo.row) {
                            let background =
                              rowInfo?.original?.id ===
                              (props.AllergySequence?.adrSelected !== null
                                ? props.AllergySequence?.adrSelected?.id
                                : null)
                                ? "blueSelectedRow"
                                : "";
                            return {
                              className: background,
                            };
                          } else {
                            return {};
                          }
                        }}
                        headers="ชื่อ,อาการ,ผู้บันทึก,วันที่-เวลา"
                        keys="name,note,edit_user,edited"
                        minRows="1"
                        showPagination={false}
                        widths="auto,200,150,150"
                      ></Table>
                    </div>
                  </div>
                </div>
              </Modal.Content>
            </div>
          </Modal>
        }
        showTextNoneFood={showNKFButton && <SubNotFound />}
        showLineNoneFood={
          showNKFButton ? (
            <div style={{ padding: "30px 20px 0px 20px" }}>
              <div
                style={{ flex: 1, height: "2px", backgroundColor: "#D8D9D9" }}
              ></div>
            </div>
          ) : null
        }
        showTextKNF={
          showKNFText ? (
            <div>
              <div style={{ fontWeight: "bold", fontSize: "large" }}>
                <Container style={{ fontSize: "1.7rem" }} textAlign="center">
                  {" "}
                  ผู้ป่วยปฏิเสธแพ้อาหาร [Not Know Allergy]
                </Container>
                <Container
                  style={{
                    fontSize: "1rem",
                    fontWeight: "normal",
                    marginTop: "20px",
                  }}
                  textAlign="center"
                >
                  ผู้บันทึก : {findfood?.[0]?.edit_user} [
                  {props?.selectedDivision?.name}]
                </Container>
                <Container
                  style={{ fontSize: "1rem", fontWeight: "normal" }}
                  textAlign="center"
                >
                  วันที่-เวลา : {findfood?.[0]?.edited}
                </Container>
              </div>
            </div>
          ) : null
        }
        showLineKNF={
          showKNFText ? (
            <div style={{ padding: "30px 20px 0px 20px" }}>
              <div
                style={{ flex: 1, height: "2px", backgroundColor: "#D8D9D9" }}
              >
                <div></div>
              </div>
            </div>
          ) : null
        }
        //Other
        disabledNKO={showNKOButton ? "inline-block" : "none"}
        other_lmage={<Image width={45} height={45} src={IMAGES.other}></Image>}
        adrListOther={adrListDataOther}
        showTable_other={showTableOther ? !showTableOther : "none"}
        other_status={
          showOtherStatus === false ? null : showTableOther === false ? (
            <Image
              display={showOtherStatus}
              width={35}
              height={35}
              onClick={handleOnClickNOA}
              src={IMAGES.other_gray}
            ></Image>
          ) : (
            <Image
              display={showOtherStatus}
              width={35}
              height={35}
              onClick={handleOnClickOther}
              src={IMAGES.other_red}
            ></Image>
          )
        }
        modalOther={
          <Modal
            open={openOther}
            onClose={() => setOpenOther(false)}
            onOpen={() => setOpenOther(true)}
            size="large"
          >
            <div className="cardAllergy">
              <Segment
                inverted
                className="blue"
                style={{ margin: 0, fontWeight: "bold", fontSize: "large" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "row",
                    alignItems: "end",
                    marginLeft: "20px",
                  }}
                >
                  <Image
                    width={45}
                    height={45}
                    src={IMAGES.other_white}
                  ></Image>
                  <div style={{ marginLeft: "10px" }}>
                    {" "}
                    ประวัติการแพ้สารอื่นๆ{" "}
                  </div>
                </div>
              </Segment>
              <Modal.Content>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "row",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <Image
                      width={30}
                      height={30}
                      src={IMAGES.other_red}
                    ></Image>
                    <div
                      style={{
                        marginLeft: "10px",
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      มีประวัติแพ้สารอื่นๆ
                    </div>
                  </div>
                  <div>
                    <div style={{ padding: "30px 20px 0px 20px" }}>
                      <Table
                        data={adrListDataOther}
                        getTrProps={(state: any, rowInfo: any) => {
                          // console.log("rowInfo", rowInfo);
                          if (rowInfo && rowInfo.row) {
                            let background =
                              rowInfo?.original?.id ===
                              (props.AllergySequence?.adrSelected !== null
                                ? props.AllergySequence?.adrSelected?.id
                                : null)
                                ? "blueSelectedRow"
                                : "";
                            return {
                              className: background,
                            };
                          } else {
                            return {};
                          }
                        }}
                        headers="ชื่อ,อาการ,ผู้บันทึก,วันที่-เวลา"
                        keys="name,note,edit_user,edited"
                        minRows="1"
                        showPagination={false}
                        widths="auto,200,150,150"
                      ></Table>
                    </div>
                  </div>
                </div>
              </Modal.Content>
            </div>
          </Modal>
        }
        showTextNoneOther={showNKOButton && <SubNotFound />}
        showLineNoneOther={
          showNKOButton ? (
            <div style={{ padding: "30px 20px 0px 20px" }}>
              <div
                style={{ flex: 1, height: "2px", backgroundColor: "#D8D9D9" }}
              >
                <div></div>
              </div>
            </div>
          ) : null
        }
        showTextKNO={
          showKNOText ? (
            <div>
              <div style={{ fontWeight: "bold", fontSize: "large" }}>
                <Container style={{ fontSize: "1.7rem" }} textAlign="center">
                  {" "}
                  ผู้ป่วยปฏิเสธแพ้สารอื่นๆ [Not Know Allergy]
                </Container>
                <Container
                  style={{
                    fontSize: "1rem",
                    fontWeight: "normal",
                    marginTop: "20px",
                  }}
                  textAlign="center"
                >
                  ผู้บันทึก : {findother?.[0]?.edit_user} [
                  {props?.selectedDivision?.name}]
                </Container>
                <Container
                  style={{ fontSize: "1rem", fontWeight: "normal" }}
                  textAlign="center"
                >
                  วันที่-เวลา : {findother?.[0]?.edited}
                </Container>
              </div>
            </div>
          ) : null
        }
        showLineKNO={
          showKNOText ? (
            <div style={{ padding: "30px 20px 0px 20px" }}>
              <div
                style={{ flex: 1, height: "2px", backgroundColor: "#D8D9D9" }}
              >
                <div></div>
              </div>
            </div>
          ) : null
        }
      />

      {openADRDetail ? (
        <div ref={newARDRef}>
          <CardAdverseReactionNote
            rolePharma={props.DJANGO?.user?.role_types.includes("PHARMACIST")}
            searchedItemList={props.searchedItemList}
            masterOptions={props.masterOptions}
            onCancelNewADRNote={handleCancelNewADRNote}
            onSaveNewADRNote={handleSaveNewADRNote}
            onSaveCommonADRNote={handleSaveCommonADRNote}
            AllergySequence={props.AllergySequence}
            onEvent={props.onEvent}
            setProp={props.setProp}
            adrSelected={props.AllergySequence?.adrSelected}
            onEdit={onEdit}
          />
        </div>
      ) : null}

      {openModNote && (
        <Modal open={openModNote} size="small" onClose={handleNoteDeny}>
          <Modal.Header
            as="h5"
            style={{
              backgroundColor: "#2185D0",
              color: "white",
              fontSize: "16px",
            }}
          >
            กรอกข้อมูลที่ต้องการบันทึกเพิ่มเติม
          </Modal.Header>
          <Modal.Content>
            <div> Note </div>
            <div style={{ height: "100px" }}>
              <TextArea
                style={{
                  width: "100%",
                  height: "100%",
                  border: "solid #cccccc 1px",
                  marginTop: "5px",
                }}
                value={props.AllergySequence?.actionNote}
                onChange={(e, v) => {
                  props.setProp("AllergySequence.actionNote", v.value);
                }}
              />
            </div>

            <div style={{ marginTop: "10px" }}>
              <ErrorMessage error={props?.errorMessage?.[CARD_ALLERGY]} />
            </div>

            <div
              style={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                className="basic"
                color="green"
                onClick={handleNoteConfirmed}
                style={{ width: "100px" }}
              >
                ใช่
              </Button>
              <Button
                className="basic"
                color="red"
                onClick={handleNoteDeny}
                style={{ width: "100px" }}
              >
                ไม่
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      )}

      {actionLogId && (
        <Modal open={!!actionLogId}>
          <CardAllergyActionLog
            id={actionLogId}
            runSequence={props.runSequence}
            actionLogData={actionLogData}
            onDismiss={() => {
              setActionLogId(null);
            }}
          />
        </Modal>
      )}

      {openModNKDAConfirm && (
        <ModConfirm
          openModal={openModNKDAConfirm}
          titleName={"แจ้งเตือน"}
          titleColor={"blue"}
          textConTent={'ยืนยันการบันทึก "ผู้ป่วยไม่แพ้ยา (NKA)"'}
          onApprove={handleNKDAConfirm}
          denyButtonColor="red"
          approveButtonColor="blue"
          basic={false}
          onDeny={handleNKDADeny}
        />
      )}
      {openModNKFAConfirm && (
        <ModConfirm
          openModal={openModNKFAConfirm}
          titleName={"แจ้งเตือน"}
          titleColor={"blue"}
          textConTent={'ยืนยันการบันทึก "ผู้ป่วยไม่แพ้อาหาร (NKA)"'}
          onApprove={handleNKFAConfirm}
          denyButtonColor="red"
          approveButtonColor="blue"
          basic={false}
          onDeny={handleNKFADeny}
        />
      )}
      {openModNKOAConfirm && (
        <ModConfirm
          openModal={openModNKOAConfirm}
          titleName={"แจ้งเตือน"}
          titleColor={"blue"}
          textConTent={'ยืนยันการบันทึก "ผู้ป่วยไม่แพ้สารอื่นๆ (NKA)"'}
          onApprove={handleNKOAConfirm}
          denyButtonColor="red"
          approveButtonColor="blue"
          basic={false}
          onDeny={handleNKOADeny}
        />
      )}
    </div>
  );
}

const SubNotFound = () => {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <div
        style={{
          width: "100%",
          padding: "0 1.5rem",
          position: "relative",
        }}
      >
        <div
          style={{
            alignItems: "center",
            width: "100%",
            border: "3.5px dashed rgb(197, 197, 197)",
            borderTop: "thin",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            top: "-10px",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "fit-content",
              padding: "0 5px",
            }}
          >
            ไม่มีข้อมูลการบันทึก
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CardAllergy);
