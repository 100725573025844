import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useState,
  useCallback,
  SyntheticEvent,
  useRef,
} from "react";
import { Icon, Pagination, SemanticCOLORS } from "semantic-ui-react";

// UX
import CardImagingResultUX from "./CardImagingResultUX";
import ModXrayDetail from "./ModXrayDetail";
import ModImagingAuditLog from "./ModImagingAuditLog";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";

// Interface
import {
  State,
  RunSequence,
  MasterOptionsType,
} from "./sequence/ImagingResult";
import { State as OrderState } from "./sequence/ImagingOrder";

// Utils
import { Column, RowInfo } from "react-table-6";
import ActionButton from "./ActionButton";
import ModalGallery from "./ModalGallery";
import {
  ImagingQueueSerializer,
  ORDER_STATUS,
  ORDER_STATUS_COLORS,
  OrderStatusType,
} from "./sequence/ImagingWorkList";
import {
  getLocationsSet,
  tidyLocation2,
} from "react-lib/apps/common/CUDENT/DentalRecordUtil";

// Types
type CardImagingResultProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: RunSequence;
  ImagingResultSequence: State["ImagingResultSequence"];
  // data
  doctorId?: number;
  encounterId?: number | null; // filter by encounter id
  orderStatus?: OrderStatusType; // filter by order status
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  searchedItemListWithKey?: Record<string, any>;
  modXrayDetail: OrderState["modXrayDetail"];
  // options
  masterOptions?: MasterOptionsType;
  // style
  cardStyle?: CSSProperties;
  tableHeight?: CSSProperties["height"];
  // config
  readOnly?: boolean;
  hideColumnResult?: boolean;
  // Dental disgram
  clinicalFindingId?: number | null;
  clinicalFindingIndex?: number | null;
  clinicalFindings: Record<string, any>[];
  filterClinicalFindingIds: number[];
  organ?: { name: string; items: any[] };
  clinicaltags?: { name: string; items: any[] };
  // ----- #
};

type ReportActionType = "EDIT" | "REPORT" | "APPROVE" | "UNAPPROVE";

// Const
const IMAGES = {
  comment_green: "/static/images/x-ray/comment-outline-green.png",
  dismiss: "/static/images/x-ray/dismiss-red.png",
  check_yellow: "/static/images/x-ray/check-yellow.png",
  check_green: "/static/images/x-ray/check-green.png",
  comment_grey: "/static/images/x-ray/comment-outline-grey.png",
};

const RESULT_SUMMARY_OPTIONS = [
  { key: 1, value: 1, text: "NORMAL" },
  { key: 1, value: 2, text: "ABNORMAL" },
];

const BUTTON_ACTIONS = {
  edit: "EDIT_EDIT",
  approve: "EDIT_APPROVE",
  unapprove: "EDIT_UNAPPROVE",
  report: "EDIT_REPORT",
  cancel: "CANCEL_ORDER",
  pacs: "OPEN_PACS",
};

const CHECK_ICONS = {
  REPORTED: { src: IMAGES.check_yellow, alt: "yellow check" },
  APPROVED: { src: IMAGES.check_green, alt: "green check" },
} as Record<OrderStatusType, { src: string; alt: string }>;

const GridCenter = {
  display: "grid",
  placeContent: "center",
  textAlign: "center",
} as CSSProperties;

const LIMIT = 20;

const CARD_IMAGING_RESULT = "CardImagingResult";

const CardImagingResult = (props: CardImagingResultProps) => {
  const [activePage, setActivePage] = useState<number>(1);

  const [modAuditLog, setModAuditLog] = useState<ImagingQueueSerializer | null>(
    null
  );

  const encounterIdRef = useRef<any>(-1);

  // Callback Effect
  const getImagingResult = useCallback((data: any = {}) => {
    props.runSequence({
      sequence: "ImagingResult",
      restart: true,
      orderStatus: props.orderStatus,
      ...data,
    });
  }, []);

  // Effect
  useEffect(() => {
    // TODO: เนื่องจากมีการ effect เข้ามาโดยที่ encounter id ยังไม่เปลี่ยนแปลง
    if (encounterIdRef.current === props.encounterId) {
      return;
    }

    encounterIdRef.current = props.encounterId;

    if ("encounterId" in props) {
      if (props.encounterId) {
        getImagingResult({ encounterId: props.encounterId });
      } else {
        props.runSequence({
          sequence: "ImagingResult",
          clear: true,
        });
      }
    } else {
      getImagingResult();
    }
  }, [props.encounterId]);

  // Callback

  const handleOpenModDetail = useCallback(
    (data: any) => (e: SyntheticEvent) => {
      e.stopPropagation();

      props.runSequence({
        sequence: "ImagingResult",
        action: "MOD_XRAY",
        type: "OPEN",
        data,
      });
    },
    []
  );

  const handleOpenAuditLog = useCallback(
    (data: ImagingQueueSerializer) => (e: SyntheticEvent) => {
      e.stopPropagation();

      props.runSequence({
        sequence: "ImagingResult",
        action: "GET_AUDIT_LOG",
        orderItemId: data.id,
      });

      setModAuditLog(data);
    },
    []
  );

  // Memo
  const orderItemStatus = useMemo(() => {
    return props.ImagingResultSequence?.reportDetail?.order_item_status || "";
  }, [props.ImagingResultSequence?.reportDetail?.order_item_status]);

  const hideApprove = useMemo(() => {
    return !([ORDER_STATUS.REPORTED, ORDER_STATUS.APPROVED] as any).includes(
      orderItemStatus
    );
  }, [orderItemStatus]);

  const filterOrderList = useMemo(() => {
    const offset = (activePage - 1) * LIMIT;

    return (props.ImagingResultSequence?.orderList || []).slice(
      offset,
      offset + LIMIT
    );
  }, [props.ImagingResultSequence?.orderList, activePage]);

  const divisionName = useMemo(() => {
    return (
      props.masterOptions?.division?.find(
        (item) =>
          item.value ===
          props.ImagingResultSequence?.imagingItemDetail?.order_div
      )?.text || ""
    );
  }, [
    props.ImagingResultSequence?.imagingItemDetail,
    props.masterOptions?.division,
  ]);

  const statusDetail = useMemo(() => {
    const status = props.ImagingResultSequence?.selectedOrder?.status || "";
    return {
      color: ORDER_STATUS_COLORS[status],
      label: ORDER_STATUS[status],
    };
  }, [props.ImagingResultSequence?.selectedOrder?.status]);

  const allowApprove = useMemo(() => {
    const detail = props.ImagingResultSequence?.reportDetail || {};
    return (
      !!detail.username &&
      !!detail.password &&
      !!detail.result &&
      !!detail.impression
    );
  }, [props.ImagingResultSequence?.reportDetail]);

  const isRadiologist = useMemo(() => {
    return (
      props.doctorId ===
      props.ImagingResultSequence?.selectedOrder?.radiologist_id
    );
  }, [props.ImagingResultSequence?.selectedOrder]);

  const readOnly = useMemo(() => {
    // เมื่อ status เป็น approve, props.readOnly, แพทย์ผู้ใช้งานไปตรงกับ radiologist ที่ execute
    // หรือ
    return (
      orderItemStatus === ORDER_STATUS.APPROVED ||
      props.readOnly ||
      !isRadiologist
    );
  }, [props.readOnly, props.doctorId, isRadiologist, orderItemStatus]);

  const showApprove = useMemo(() => {
    // เมื่อ status เป็น reported, approved และ !props.readOnly
    // แพทย์รังสีอื่นๆสามารถ approve และ unapprove ได้
    return (
      ([ORDER_STATUS.REPORTED, ORDER_STATUS.APPROVED] as any).includes(
        orderItemStatus
      ) && !props.readOnly
    );
  }, [props.readOnly, props.doctorId, isRadiologist, orderItemStatus]);

  const allowEdit = useMemo(() => {
    const detail = props.ImagingResultSequence?.reportDetail || {};

    return allowApprove && (!!detail.id ? !!detail?.edit_reason : true);
  }, [props.ImagingResultSequence?.reportDetail, allowApprove]);

  const locationsName = useMemo(() => {
    const detail = props.ImagingResultSequence?.selectedOrder;

    const locationsSet = getLocationsSet(
      detail?.locations_name || "",
      props.organ?.items || []
    );

    return tidyLocation2(locationsSet, props.organ?.items || []).join(",");
  }, [props.ImagingResultSequence?.selectedOrder, props.organ]);

  const imagingOrderItems = useMemo(() => {
    return filterOrderList.map((item) => {
      const icon = CHECK_ICONS[item.status];

      return {
        ...item,
        datetime: <div style={GridCenter}>{item.created}</div>,
        order_no: <div style={GridCenter}>{item.order_number}</div>,
        type: <div style={GridCenter}>{item.group_name}</div>,
        doctor_name: <div style={GridCenter}>{item.care_provider}</div>,
        order_status: (
          <div style={{ ...GridCenter, textTransform: "capitalize" }}>
            {ORDER_STATUS[item.status].toLowerCase()}
          </div>
        ),
        request: (
          <div style={{ height: "100%", position: "relative" }}>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 30px" }}>
              <div>{item.product_name}</div>
              <div>
                <img
                  role="button"
                  aria-label="open modal x-ray detail"
                  src={!!item.note ? IMAGES.comment_green : IMAGES.comment_grey}
                  alt={
                    !!item.note ? "green speech bubble" : "grey speech bubble"
                  }
                  // style
                  style={{ width: "25px", cursor: "pointer" }}
                  onClick={handleOpenModDetail(item)}
                />
              </div>
            </div>
          </div>
        ),
        result: (
          <div style={{ ...GridCenter, height: "100%" }}>
            <img
              src={icon?.src || IMAGES.dismiss}
              alt={icon?.alt || "red dismiss"}
              style={{ width: "20px", height: "100%" }}
            />
          </div>
        ),
        log: (
          <div style={{ ...GridCenter, height: "100%" }}>
            <Icon
              role="button"
              aria-label="open modal audit log"
              name="history"
              link={true}
              style={{ fontSize: "1.15rem" }}
              onClick={handleOpenAuditLog(item)}
            />
          </div>
        ),
      };
    });
  }, [filterOrderList]);

  // Handler
  const handleChangeValue = (_e: any, data: any) => {
    const value = typeof data.checked === "boolean" ? data.checked : data.value;
    const name = data.name;

    const detail = props.ImagingResultSequence?.reportDetail || {};

    (detail as any)[name] = value;

    props.setProp(`ImagingResultSequence.reportDetail`, { ...detail });
  };

  const handleSelectOrder = (rowInfo?: RowInfo) => {
    const row = props.ImagingResultSequence?.orderList?.[rowInfo?.index || 0];

    if (!!row) {
      props.runSequence({
        sequence: "ImagingResult",
        action: "SELECT_ORDER",
        data: row,
      });
    }
  };

  const handleGetTrProps = (
    state: any,
    rowInfo?: RowInfo,
    column?: Column,
    instance?: any
  ) => {
    return {
      className:
        !!props.ImagingResultSequence?.selectedOrder?.id &&
        props.ImagingResultSequence?.selectedOrder?.id === rowInfo?.original.id
          ? "blueSelectedRow"
          : "",
      onClick: () => {
        handleSelectOrder(rowInfo);
      },
    };
  };

  const handleEdit = (
    actionType: "EDIT" | "REPORT" | "APPROVE" | "UNAPPROVE"
  ) => {
    props.runSequence({
      sequence: "ImagingResult",
      action: "EDIT",
      actionType,
      card: CARD_IMAGING_RESULT,
    });
  };

  const handlePageChange = (e: any, data: any) => {
    setActivePage(data.activePage);
  };

  const handleCancel = () => {};

  const handleClose = async () => {
    props.runSequence({
      sequence: "ImagingResult",
      action: "CLOSE_ORDER",
    });
  };

  const handleClickPacs = () => {
    if (!!props.ImagingResultSequence?.selectedOrder) {
      props.runSequence({
        sequence: "ImagingResult",
        action: "OPEN_PACS",
        card: CARD_IMAGING_RESULT,
        data: props.ImagingResultSequence.selectedOrder,
      });
    }
  };

  const handleCloseModXray = () => {
    props.runSequence({
      sequence: "ImagingResult",
      action: "MOD_XRAY",
      type: "CLOSE",
    });
  };

  const handleCloseModLog = () => {
    setModAuditLog(null);

    props.setProp("ImagingResultSequence.auditLogList", []);
  };

  const handleCloseModalGallery = () => {
    props.setProp("ImagingResultSequence.pacsGalleryDetail", null);
  };

  console.log("CardImagingResultUX", props);

  return (
    <div style={{ paddingBottom: "2rem", ...(props.cardStyle || {}) }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_IMAGING_RESULT}`, null);
        }}
        error={props.errorMessage?.[CARD_IMAGING_RESULT]}
        success={null}
      />

      <CardImagingResultUX
        // data
        imagingOrderList={imagingOrderItems}
        reportDetail={props.ImagingResultSequence?.reportDetail}
        imagingItemDetail={props.ImagingResultSequence?.imagingItemDetail}
        principalDiagnosis={props.ImagingResultSequence?.principalDiagnosis}
        selectedOrder={props.ImagingResultSequence?.selectedOrder}
        statusDetail={statusDetail}
        divisionName={divisionName}
        locationsName={locationsName}
        // options
        resultSummaryOptions={RESULT_SUMMARY_OPTIONS}
        // style
        tableHeight={props.tableHeight}
        // config
        // เมื่อบันทึกแล้วและสถานะไม่เป็น approved และ radiologist ตรงกับตัวเอง
        showEditReason={
          !!props.ImagingResultSequence?.reportDetail?.id &&
          orderItemStatus !== ORDER_STATUS.APPROVED &&
          isRadiologist &&
          !props.readOnly
        }
        showApprove={showApprove}
        showEdit={
          orderItemStatus !== ORDER_STATUS.APPROVED &&
          isRadiologist &&
          !props.readOnly
        }
        readOnly={readOnly}
        hideColumnResult={props.hideColumnResult}
        // callback
        onChangeValue={handleChangeValue}
        onGetTrProps={handleGetTrProps}
        onClose={handleClose}
        // Element
        Pagination={
          <Pagination
            activePage={activePage}
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            totalPages={
              (props.ImagingResultSequence?.orderList?.length || 0) / LIMIT
            }
            size="mini"
            // callback
            onPageChange={handlePageChange}
          />
        }
        ButtonEdit={
          <ButtonLoadCheckCustom
            setProp={props.setProp}
            onClick={handleEdit}
            // data
            action={["REPORT", "EDIT"]}
            color={["blue", "yellow"]}
            title={["SAVE", "EDIT"]}
            // CommonInterface
            buttonLoadCheck={props.buttonLoadCheck}
            // config
            switch={!!props.ImagingResultSequence?.reportDetail?.id}
            disabled={!allowEdit || orderItemStatus === ORDER_STATUS.APPROVED}
          />
        }
        ButtonApprove={
          !hideApprove && (
            <ButtonLoadCheckCustom
              setProp={props.setProp}
              onClick={handleEdit}
              // data
              action={["APPROVE", "UNAPPROVE"]}
              color={["green", "red"]}
              title={["APPROVE", "UNAPPROVE"]}
              // CommonInterface
              buttonLoadCheck={props.buttonLoadCheck}
              // config
              switch={orderItemStatus === ORDER_STATUS.APPROVED}
              disabled={!allowApprove}
            />
          )
        }
        ButtonCancel={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleCancel}
            // data
            paramKey={`${CARD_IMAGING_RESULT}_${BUTTON_ACTIONS.cancel}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_IMAGING_RESULT}_${BUTTON_ACTIONS.cancel}`
              ]
            }
            // config
            color={"red"}
            name={BUTTON_ACTIONS.cancel}
            size="medium"
            title={"CANCEL"}
            fluid={true}
            style={{ minWidth: "max-content" }}
          />
        }
        IconPacs={
          <ActionButton
            icon={
              props.ImagingResultSequence?.selectedOrder?.ready_to_view
                ? "pacs"
                : "pacs_grey"
            }
            disabled={
              !props.ImagingResultSequence?.selectedOrder?.ready_to_view
            }
            loading={
              props.buttonLoadCheck?.[
                `${CARD_IMAGING_RESULT}_${BUTTON_ACTIONS.pacs}`
              ]
            }
            badgeSx={{ marginLeft: "0.5rem" }}
            onClick={handleClickPacs}
          />
        }
        SearchBoxPrincipalDiag={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="Diagnosis"
            id="IR"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            disabled={true}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={
              props.ImagingResultSequence?.imagingItemDetail
                ?.suspected_diagnosis || null
            }
            setSelectedItem={() => null}
            // style
            dropdownStyle={{ opacity: 1 }}
          />
        }
      />

      <ModXrayDetail
        onEvent={props.onEvent}
        setProp={props.setProp}
        // data
        open={!!props.modXrayDetail}
        data={props.modXrayDetail}
        clinicalFindingId={props.clinicalFindingId}
        clinicalFindingIndex={props.clinicalFindingIndex}
        masterData={{ organ: props.organ, clinicaltags: props.clinicaltags }}
        clinicalFindings={props.clinicalFindings}
        filterClinicalFindingIds={props.filterClinicalFindingIds}
        // options
        eligibilityOptions={props.masterOptions?.eligibilityType}
        // config
        readOnly={true}
        // callback
        onClose={handleCloseModXray}
        // onSave={handleSaveXray}
      />

      <ModImagingAuditLog
        // data
        open={!!modAuditLog}
        data={modAuditLog || {}}
        auditLogList={props.ImagingResultSequence?.auditLogList}
        // callback
        onClose={handleCloseModLog}
      />

      <ModalGallery
        open={!!props.ImagingResultSequence?.pacsGalleryDetail}
        data={props.ImagingResultSequence?.pacsGalleryDetail || null}
        onClose={handleCloseModalGallery}
      />
    </div>
  );
};

/* ------------------------------------------------------ */

/*                    ButtonLoadCheck;                    */

/* ------------------------------------------------------ */
type ButtonLoadCheckCustomProps = {
  setProp: any;
  onClick: (action: ReportActionType) => any;
  // data
  action: [ReportActionType, ReportActionType];
  color: [SemanticCOLORS, SemanticCOLORS];
  title: [ReportActionType | "SAVE", ReportActionType];
  // CommonInterface
  buttonLoadCheck?: any;
  // config
  switch: boolean;
  disabled?: boolean;
};

const ButtonLoadCheckCustom = (props: ButtonLoadCheckCustomProps) => {
  const action = useMemo(() => {
    return props.switch ? props.action[1] : props.action[0];
  }, [props.switch, props.action]);

  const buttonKey = useMemo(() => {
    return (BUTTON_ACTIONS as any)[action.toLowerCase()];
  }, [props.switch, action]);

  const handleClick = () => {
    props.onClick(props.switch ? props.action[1] : props.action[0]);
  };

  return (
    <ButtonLoadCheck
      // function
      setProp={props.setProp}
      onClick={handleClick}
      // data
      paramKey={`${CARD_IMAGING_RESULT}_${buttonKey}`}
      buttonLoadCheck={
        props.buttonLoadCheck?.[`${CARD_IMAGING_RESULT}_${buttonKey}`]
      }
      // config
      disabled={props.disabled}
      color={props.switch ? props.color[1] : props.color[0]}
      name={buttonKey}
      size="medium"
      title={props.switch ? props.title[1] : props.title[0]}
      fluid={true}
      style={{ minWidth: "max-content" }}
    />
  );
};

export default React.memo(CardImagingResult);
