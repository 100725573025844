import { Dialog, Grid } from "@mui/material";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Icon, Input, Label, Popup } from "semantic-ui-react";
import CardOpenEncounterUX from "./CardOpenEncounterUX";
import htmlParse from "html-react-parser";
import {
  DOCTOR_ORDER_ACTION_MAP,
  DOCTOR_ORDER_MAPPING_ICON,
} from "../REG/REGInterface";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

const CARD_OPEN_ENCOUNTER = "CreateUpdateEncounter";
const CardOpenEncounter = (props: any) => {
  const [appointmentCompleted, setAppointmentCompleted] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [note, setNote] = useState("");
  const [showEncounterEditor, setShowEncounterEditor] = useState(false);
  const [pregnancyData, setPregnancyData] = useState({
    pregnancy_period: 0,
    pregnancy_status: 1,
  });

  useEffect(() => {
    if (
      props.errorMessage?.[CARD_OPEN_ENCOUNTER] !== null &&
      props.errorMessage?.[CARD_OPEN_ENCOUNTER] !== undefined
    ) {
      setTimeout(() => {
        setOpen(false);
        props.runSequence({
          sequence: "CreateUpdateEncounter",
          action: "clear",
        });
      }, 2000);
    }
    if (
      props.successMessage?.[CARD_OPEN_ENCOUNTER] !== null &&
      props.successMessage?.[CARD_OPEN_ENCOUNTER] !== undefined &&
      props.CreateUpdateEncounterSequence?.selectedEncouter === null
    ) {
      setTimeout(() => {}, 2000);
      props.runSequence({
        sequence: "CreateUpdateEncounter",
        action: "clear",
      });
    }
    if (
      props.successMessage?.[CARD_OPEN_ENCOUNTER] !== null &&
      props.successMessage?.[CARD_OPEN_ENCOUNTER]?.appointment
    ) {
      setAppointmentCompleted(true);
      setTimeout(() => {
        setAppointmentCompleted(false);
      }, 2000);
    }
  }, [
    props.successMessage?.CreateUpdateEncounter,
    props.errorMessage?.CreateUpdateEncounter,
  ]);

  useEffect(() => {
    props.runSequence({
      sequence: "CreateUpdateEncounter",
      card: "CreateUpdateEncounter",
      form: props.form,
      isEditEncounter: props.isEditEncounter,
      editEncounterId: props.editEncounterId,
      restart: true,
    });

    if (props.isEditEncounter) {
      setShowEncounterEditor(true);
    }
  }, []);

  useEffect(() => {
    setPregnancyData({
      pregnancy_status:
        props.CreateUpdateEncounterSequence?.pregnancy_status || 1,
      pregnancy_period:
        props.CreateUpdateEncounterSequence?.pregnancy_period || 0,
    });
  }, [
    props.CreateUpdateEncounterSequence?.pregnancy_status,
    props.CreateUpdateEncounterSequence?.pregnancy_period,
  ]);

  // useEffect(() => {
  //   props?.onEvent({
  //     message: "GetMasterData",
  //     params: {
  //       masters: [
  //         ["division", {}],
  //         ["doctor", {}],
  //         ["patientCase", {}],
  //       ],
  //     },
  //   });
  // }, []);

  // useEffect(() => {
  //   props.setProp(
  //     `SetPatientSequence.patient.encounter_no`,
  //     props.CreateUpdateEncounterSequence?.encounterList?.[0]?.number
  //   );
  // }, [props.CreateUpdateEncounterSequence?.encounterList]);

  const changePregnancy = (key: string) => (e: any, v: any) => {
    setPregnancyData({
      ...pregnancyData,
      ...(key === "pregnancy_status" && { pregnancy_status: v.value }),
      ...(key === "pregnancy_period" && { pregnancy_period: v.value }),
    });
  };

  const changeEncounter = (key: string) => (e: any, v: any) =>
    props.setProp(`CreateUpdateEncounterSequence.${key}`, v.value);

  const generateOrder = (orders: any[]) => {
    let sortingOrderType: any = [];
    Object.keys(DOCTOR_ORDER_MAPPING_ICON).forEach((order_type: any) => {
      // find match order type and status
      let target = orders.filter((order) => order.type.includes(order_type));
      if (target?.length > 0) {
        sortingOrderType.push({
          image: DOCTOR_ORDER_MAPPING_ICON[order_type]["appoiment_or_cancel"],
          items: target?.map((i) => i.summary_detail),
        });
      }
    });
    console.log("generate Order: ", sortingOrderType);
    return sortingOrderType;
  };

  const encounterList = useMemo(
    () =>
      props.CreateUpdateEncounterSequence?.encounterList?.map(
        (item: any, idx: any) => {
          return {
            ...item,
            number: (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {item.number}
                {props.masterOptions?.patientCase
                  ?.find((acc: any) => acc.value === item.patient_case)
                  ?.text?.toLowerCase() === "telemed" && (
                  <Label
                    circular
                    color="yellow"
                    style={{ height: "1.8rem", width: "1.8rem" }}
                  >
                    <Icon name="phone" style={{ transform: "rotate(90deg)" }} />
                  </Label>
                )}
              </div>
            ),
            delete: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  color="red"
                  content="ยกเลิก"
                  onClick={() => {
                    handleOpenDialog();
                  }}
                />
              </div>
            ),
          };
        }
      ),
    [
      props.CreateUpdateEncounterSequence,
      props.CreateUpdateEncounterSequence?.encounterList,
    ]
  );

  const appointmentList = useMemo(() => {
    return (props.appointmentEncounterList || []).map((item: any) => ({
      ...item,
      _checked: (
        <Checkbox
          checked={props.selectedAppointmentItems.includes(item.id)}
          onChange={(e: any, { checked }) => {
            props.runSequence({
              sequence: "CreateUpdateEncounter",
              action: "checked_encounter_appointment",
              appointmentId: item.id,
            });
          }}
        />
      ),
      order:
        item.children?.length > 0 ? (
          <div style={{ display: "grid", gridTemplateColumns: "40% 40%" }}>
            {generateOrder(item.children).map((item: any, index: number) => {
              return (
                <div key={"order" + index} style={{ paddingLeft: "5px" }}>
                  <Popup
                    on="click"
                    trigger={
                      <img
                        src={item.image}
                        style={{ width: "30px", height: "30px" }}
                        // onClick={
                        //   () => {
                        //     console.log("items: ", item.items);
                        //   }
                        // }
                      />
                    }
                  >
                    {item.items.map((order: any) => htmlParse(order))}
                  </Popup>
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        ),
    }));
  }, [props.appointmentEncounterList, props.selectedAppointmentItems]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNote("");
    setPassword("");
    setUsername("");
  };

  console.log("CardOpenEncounter", props);
  return (
    <div style={{ paddingBottom: "2rem", ...(props.cardStyle || {}) }}>
      {open ? (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            setOpen(false);
            props.runSequence({
              sequence: "CreateUpdateEncounter",
              action: "clear",
            });
          }}
          error={props.errorMessage?.[CARD_OPEN_ENCOUNTER]}
          success={props.successMessage?.[CARD_OPEN_ENCOUNTER]}
        />
      ) : (
        ""
      )}

      <Dialog
        onClose={handleCloseDialog}
        open={openDialog}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <div
          style={{ padding: "10px", background: "#2185D0", color: "#FFFFFF" }}
        >
          ระบุเหตุผลยกเลิก Encounter
        </div>
        <div style={{ padding: "10px" }}>
          <div>Username</div>
          <Input
            placeholder="Username"
            fluid={true}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <div>Password</div>
          <Input
            placeholder="Password"
            fluid={true}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div>เหตุผลการยกเลิก</div>
          <Input
            fluid={true}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <Grid container spacing={2} style={{ paddingTop: "15px" }}>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                basic
                color="green"
                content="ใช่"
                onClick={async () => {
                  await props.runSequence({
                    sequence: "CreateUpdateEncounter",
                    action: "delete",
                    username: username,
                    password: password,
                    note: note,
                  });
                  handleCloseDialog();
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                basic
                color="red"
                content="ไม่"
                onClick={() => handleCloseDialog()}
              />
            </Grid>
          </Grid>
        </div>
      </Dialog>

      <CardOpenEncounterUX
        // edit encounter
        isEditEncounter={props.isEditEncounter}
        // Data & Fields
        // appointment data
        appointmentData={appointmentList}
        disabledOpenEncounterAppointment={
          !props.selectedAppointmentItems.length
        }
        onOpenEncounterAppointment={() => {
          props.runSequence({
            sequence: "CreateUpdateEncounter",
            action: "open_encounter_appointment",
            card: CARD_OPEN_ENCOUNTER,
          });
        }}
        appointmentRegisterButton={
          appointmentCompleted ? (
            <Icon name="check"></Icon>
          ) : (
            "ลงทะเบียนจากนัดหมาย"
          )
        }
        // encounter
        showEncounterEditor={showEncounterEditor}
        onOpenEncounter={() => {
          props.runSequence({
            sequence: "CreateUpdateEncounter",
            action: "clearData",
          });
          setShowEncounterEditor(true);
        }}
        encounterList={encounterList || []}
        isShowPregnancy={
          props.selectedPatient?.gender === "F" &&
          props.selectedPatient?.age >= 15 &&
          props.selectedPatient?.age <= 50
        }
        encounterData={props.CreateUpdateEncounterSequence}
        pregnancyData={pregnancyData}
        divisionOptions={props.masterOptions?.divisionNameCode || []}
        doctorOptions={props.masterOptions?.doctor || []}
        patientCaseOptions={props.masterOptions?.patientCase || []}
        rowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
          style: {
            backgroundColor:
              props.CreateUpdateEncounterSequence?.selectedEncouter &&
              rowInfo?.original?.id &&
              rowInfo?.original?.id ===
                props.CreateUpdateEncounterSequence?.selectedEncouter?.id
                ? "#d6ecf3"
                : "white",
          },
          onClick: async () => {
            await props.runSequence({
              sequence: "CreateUpdateEncounter",
              action: "select",
              item: rowInfo?.original,
            });
            setShowEncounterEditor(true);
          },
        })}
        // Methods
        changeEncounter={changeEncounter}
        changePregnancy={changePregnancy}
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              if (
                props.CreateUpdateEncounterSequence?.selectedEncouter?.id !==
                undefined
              ) {
                props.runSequence({
                  sequence: "CreateUpdateEncounter",
                  action: "clearData",
                });
              } else {
                props.runSequence({
                  sequence: "CreateUpdateEncounter",
                  action: "create",
                  pregnancy: pregnancyData,
                  card: CARD_OPEN_ENCOUNTER,
                  buttonLoadKey: `${CARD_OPEN_ENCOUNTER}_SAVE`,
                });
                // setShowEncounterEditor(false);
                if (!props.isEditEncounter) {
                  setShowEncounterEditor(false);
                }
              }
            }}
            // data
            paramKey={`${CARD_OPEN_ENCOUNTER}_SAVE`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_OPEN_ENCOUNTER}_SAVE`]
            }
            // config
            color={
              props.CreateUpdateEncounterSequence?.selectedEncouter?.id
                ? "teal"
                : "green"
            }
            title={
              props.CreateUpdateEncounterSequence?.selectedEncouter?.id !==
              undefined
                ? "ล้างหน้าจอเพิ่ม Encounter"
                : "SAVE"
            }
            style={{ width: `100%`, minWidth: "max-content" }}
          />
        }
        buttonEdit={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "CreateUpdateEncounter",
                action: "update",
                pregnancy: pregnancyData,
                card: CARD_OPEN_ENCOUNTER,
                buttonLoadKey: `${CARD_OPEN_ENCOUNTER}_UPDATE`,
              });
              // setShowEncounterEditor(false);
              if (!props.isEditEncounter) {
                setShowEncounterEditor(false);
              }
            }}
            // data
            paramKey={`${CARD_OPEN_ENCOUNTER}_UPDATE`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_OPEN_ENCOUNTER}_UPDATE`]
            }
            // config
            color="orange"
            title="แก้ไข Encounter"
            style={{ width: `100%` }}
          />
        }
      />
    </div>
  );
};

export default CardOpenEncounter;
