import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardImagingWorkListUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          className="ui form">
          
          <FormGroup
            style={{marginBottom: "0.5rem"}}>
            <FormField
              width={4}>
              <div>
                Order department
              </div>
              <Dropdown
                clearable={true}
                fluid={true}
                name="division"
                onChange={props.onChangeValue}
                options={props.divisionOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filter?.division || ""}>
              </Dropdown>
            </FormField>
            <FormField
              width={4}>
              <div>
                Type
              </div>
              <Dropdown
                clearable={true}
                fluid={true}
                name="type"
                options={props.typeOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filter?.type || ""}>
              </Dropdown>
            </FormField>
            <FormField
              width={4}>
              <div>
                Radiologist
              </div>
              <div
                style={{width: "100%"}}>
                {props.SearchBoxRadiologist}
              </div>
            </FormField>
            <FormField
              width={3}>
              <div>
                Item status
              </div>
              <Dropdown
                clearable={true}
                fluid={true}
                name="orderStatus"
                onChange={props.onChangeValue}
                options={props.orderStatusOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filter?.orderStatus || ""}>
              </Dropdown>
            </FormField>
            <FormField
              width={3}>
              <div>
                Billing status
              </div>
              <Dropdown
                clearable={true}
                fluid={true}
                name="billingStatus"
                onChange={props.onChangeValue}
                options={props.billingStatusOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filter?.billingStatus || ""}>
              </Dropdown>
            </FormField>
            <FormField
              width={2}>
              <div>
                Encounter type
              </div>
              <Dropdown
                clearable={true}
                fluid={true}
                name="encounterType"
                onChange={props.onChangeValue}
                options={props.encounterTypeOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filter?.encounterType || ""}>
              </Dropdown>
            </FormField>
            <FormField>
              <div>
                {"\u00a0"}
              </div>
              <div>
                {props.ButtonSearch}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              width={3}>
              <div>
                Patient
              </div>
              <div
                style={{width: "100%"}}>
                {props.SearchBoxPatient}
              </div>
            </FormField>
            <FormField
              width={3}>
              <div>
                Order No.
              </div>
              <div
                style={{width: "100%"}}>
                {props.SearchBoxOrderNo}
              </div>
            </FormField>
            <FormField>
              <div>
                Order date
              </div>
              <DateTextBox
                onChange={props.onChangeDate?.("startDate")}
                value={props.filter?.startDate || ""}>
              </DateTextBox>
            </FormField>
            <FormField>
              <div>
                {"\u00a0"}
              </div>
              <div
                style={{marginTop: "0.5rem"}}>
                to
              </div>
            </FormField>
            <FormField>
              <div>
                {"\u00a0"}
              </div>
              <DateTextBox
                onChange={props.onChangeDate?.("endDate")}
                value={props.filter?.endDate || ""}>
              </DateTextBox>
            </FormField>
            <FormField
              style={{flex: 1}}>
            </FormField>
            <FormField>
              <div>
                {"\u00a0"}
              </div>
              <div
                style={{marginRight: "0.5rem"}}>
                {props.IconRegister}
              </div>
            </FormField>
            <FormField>
              <div>
                {"\u00a0"}
              </div>
              <div
                style={{marginRight: "1.5rem"}}>
                {props.IconExecute}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{marginTop: "1rem"}}>
          
          <Table
            data={props.orderList || []}
            headers={props.headers ? props.headers : "Orderdate/Time,Order No.,Patient,Order department,Careprovider,Type,Request,Status,Radiologist,"}
            keys="check,datetime,order_no,patient,division_name,doctor_name,type,request,status,radiologist,action"
            minRows={7}
            resizable={false}
            showPagination={false}
            style={{height: "calc(100vh - 22rem)"}}
            widths="45,110,120,^155,^155,^140,^110,^155,^145,^155,^135">
          </Table>
        </div>
        <div
          style={{display: "flex", justifyContent: "flex-end", marginTop: "1rem"}}>
          {props.Pagination}
        </div>
      </div>
    )
}

CardImagingWorkListUX.displayName = "CardImagingWorkListUX";
export default React.memo(CardImagingWorkListUX)

export const screenPropsDefault = {}

/* Date Time : Tue Jul 18 2023 08:37:33 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormField",
      "parent": 2,
      "props": {
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 2,
      "props": {
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 2,
      "props": {
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 2,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 2,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 2,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 2,
      "props": {
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Order department"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "Type"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "Radiologist"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "Item status"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "Billing status"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "Encounter type"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Dropdown",
      "parent": 3,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "division"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.division || \"\""
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Dropdown",
      "parent": 4,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "type"
        },
        "options": {
          "type": "code",
          "value": "props.typeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.type || \"\""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Dropdown",
      "parent": 7,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "billingStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.billingStatusOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.billingStatus || \"\""
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Dropdown",
      "parent": 8,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "encounterType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.encounterTypeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.encounterType || \"\""
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBoxRadiologist"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Dropdown",
      "parent": 6,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "orderStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.orderStatusOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.orderStatus || \"\""
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormGroup",
      "parent": 1,
      "props": {
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 35,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 35,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 35,
      "props": {
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 35,
      "props": {
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 35,
      "props": {
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 35,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 35,
      "props": {
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 35,
      "props": {
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "Order No."
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "Order date"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "to"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"0.5rem\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBoxPatient"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBoxOrderNo"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 51,
      "name": "DateTextBox",
      "parent": 38,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate?.(\"startDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.startDate || \"\""
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 52,
      "name": "DateTextBox",
      "parent": 40,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate?.(\"endDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.endDate || \"\""
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.IconRegister"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "code",
          "value": "props.IconExecute"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1.5rem\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 57,
      "name": "Table",
      "parent": 56,
      "props": {
        "data": {
          "type": "code",
          "value": "props.orderList || []"
        },
        "headers": {
          "type": "code",
          "value": "props.headers ? props.headers : \"Orderdate/Time,Order No.,Patient,Order department,Careprovider,Type,Request,Status,Radiologist,\""
        },
        "keys": {
          "type": "value",
          "value": "check,datetime,order_no,patient,division_name,doctor_name,type,request,status,radiologist,action"
        },
        "minRows": {
          "type": "code",
          "value": "7"
        },
        "resizable": {
          "type": "code",
          "value": "false"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100vh - 22rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": "45,110,120,^155,^155,^140,^110,^155,^145,^155,^135"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.Pagination"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", marginTop: \"1rem\"}"
        }
      },
      "seq": 61,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardImagingWorkListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
