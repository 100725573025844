import WasmController from "../../../../frameworks/WasmController";
import moment from "moment";
import { formatDate } from "react-lib/utils/dateUtils";
import getPdfMake from "react-lib/appcon/common/pdfMake";
// PDF
import FormAnesDoctorFeeList from "../FormAnesDoctorFeeList";
// APIs
import DoctorFeeOrderList from "issara-sdk/apis/DoctorFeeOrderList_apps_DFC";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  django?: any;

  // sequence
  AnesDoctorFeeListSequence?: {
    sequenceIndex?: string | null;

    anesFilter?: {
      checkedDate?: boolean;
      start_perform_date?: string;
      end_perform_date?: string;
      checkedEmployee?: boolean;
      employee?: string;
      selectedEmployee?: any;
      checkedDrFeeRule?: boolean;
      doctor_fee_rule?: string;
      selectedDoctorFeeRule?: any;
    };

    drFeeList?: {};
  } | null;
};

export const StateInitial: State = {
  AnesDoctorFeeListSequence: {
    sequenceIndex: null,

    anesFilter: {
      checkedDate: true,
      start_perform_date: formatDate(moment()),
      end_perform_date: formatDate(moment()),
      checkedEmployee: false,
      employee: "",
      selectedEmployee: null,
      checkedDrFeeRule: false,
      doctor_fee_rule: "",
      selectedDoctorFeeRule: null,
    },

    drFeeList: {},
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  controller.setState(
    {
      AnesDoctorFeeListSequence: { sequenceIndex: "Action" },
    },
    () => {
      Action(controller, { action: "CLEAR_FILTER" });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "FETCH") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params?.action}`]: "LOADING",
      },
    });

    let queryParams: any = {
      limit: 100,
      employee: state.AnesDoctorFeeListSequence?.anesFilter?.checkedEmployee
        ? state.AnesDoctorFeeListSequence?.anesFilter?.employee
        : "",
      doctor_fee_rule: state.AnesDoctorFeeListSequence?.anesFilter
        ?.checkedDrFeeRule
        ? state.AnesDoctorFeeListSequence?.anesFilter?.doctor_fee_rule
        : "",
    };
    if (state.AnesDoctorFeeListSequence?.anesFilter?.checkedDate) {
      queryParams.start_perform_date =
        state.AnesDoctorFeeListSequence?.anesFilter?.start_perform_date || "";
      queryParams.end_perform_date =
        state.AnesDoctorFeeListSequence?.anesFilter?.end_perform_date || "";
    }

    const [resp, error, network] = await DoctorFeeOrderList.list({
      apiToken: controller.apiToken,
      params: queryParams,
    });

    if (error) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params?.action}`]: "ERROR",
        },
        AnesDoctorFeeListSequence: {
          ...state.AnesDoctorFeeListSequence,
          drFeeList: { items: [], next: null, previous: null, total: 0 },
        },
      });
    } else {
      const items = resp?.items?.map((item: any) => {
        return {
          ...item,
          performDT: moment(
            item.perform_datetime,
            "YYYY-MM-DD HH:mm:ss"
          ).format("DD/MM/YYYY"),
        };
      });

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params?.action}`]: "SUCCESS",
        },
        AnesDoctorFeeListSequence: {
          ...state.AnesDoctorFeeListSequence,
          drFeeList: { ...resp, items: items },
        },
      });
    }
  } else if (params?.action === "SET_FILTER") {
    controller.setState({
      AnesDoctorFeeListSequence: {
        ...state.AnesDoctorFeeListSequence,
        anesFilter: {
          ...state.AnesDoctorFeeListSequence?.anesFilter,
          ...params.data,
        },
      },
    });
  } else if (params?.action === "CLEAR_FILTER") {
    controller.setState({
      AnesDoctorFeeListSequence: {
        ...state.AnesDoctorFeeListSequence,
        anesFilter: {
          checkedDate: true,
          start_perform_date: formatDate(moment()),
          end_perform_date: formatDate(moment()),
          checkedEmployee: false,
          employee: "",
          selectedEmployee: null,
          checkedDrFeeRule: false,
          doctor_fee_rule: "",
          selectedDoctorFeeRule: null,
        },
      },
    });
  } else if (params?.action === "PRINT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    const data = Object.assign({
      start_date:
        state.AnesDoctorFeeListSequence?.anesFilter?.start_perform_date ||
        " - ",
      end_date:
        state.AnesDoctorFeeListSequence?.anesFilter?.end_perform_date || " - ",
      print_user: state.django?.user?.full_name || "",
      data: state.AnesDoctorFeeListSequence?.drFeeList?.items || [],
    });

    let docDef: any = { content: [] };

    docDef = FormAnesDoctorFeeList(data);

    const pdfMake = (await getPdfMake()).createPdf(docDef);
    pdfMake.open();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "SUCCESS",
      },
      successMessage: "Print Success",
    });
    return;
  } 
};
