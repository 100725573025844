import React from 'react';
import Interceptor from '../apps/common/Interceptor';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const withInterceptor = WrappedComponent => (
  class extends React.PureComponent {
    static displayName = `withInterceptor(${getDisplayName(WrappedComponent)})`;

    render() {
      return (
        <React.Fragment>
          <Interceptor />
          <WrappedComponent {...this.props}/>
        </React.Fragment>
      );
    }
  }
);
