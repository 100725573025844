import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "semantic-ui-react";
import { EmployeeToken } from "react-lib/apps/common";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import CardOPDOrderSummaryUX from "./CardOPDOrderSummaryUX";
import ModNursePerformOrderUX from "./ModNursePerformOrderUX";
import htmlParse from "html-react-parser";

const CARD_OPD_SUMMARY = "cardOPDOrderSummary";

const CardOPDOrderSummary = (props: any) => {
  useEffect(() => {
    props.onEvent({
      message: "HandleOPDOrderSummary",
      params: { action: "search", card: CARD_OPD_SUMMARY },
    });
  }, []);

  const handleOnCheckedChange = (name: string) => (e: any, { checked }: any) => {
      if(name === "isOrder"){
        props.setProp(`orderSummaryOPDFilter`, {...props.orderSummaryOPDFilter, isOrder: checked, order: null });

      }else{
        props.setProp(`orderSummaryOPDFilter.${name}`, checked);
      }

  };

  const orderItems = useMemo(() => {
    return (props.orderSummaryOPDList || [])?.map((item: any) => ({
      ...item,
      order_time: (
        <div style={{ height: "100%", width: "100%", padding: "5px" }}>
          {item.order_time}
        </div>
      ),
      order_ack_time: (
        <div style={{ height: "100%", width: "100%", padding: "5px" }}>
          {item.order_ack_time}
        </div>
      ),
      order_ack_by_name: (
        <div style={{ height: "100%", width: "100%", padding: "5px" }}>
          {item.order_ack_by_name}
        </div>
      ),
      detail: (
        <div
          style={{
            height: "100%",
            width: "100%",
            padding: "5px",
            textAlign: "left",
          }}
        >
          {item.order_summary_detail_list.map((i: any, index: number) =>
            <div>{htmlParse(typeof i === "string" ? i : i.detail)}</div>
          )}
          {item.verbal_description && (
            <div>
              <span style={{fontWeight: "bold"}}>Description:</span>
              {" "}
              <span>{item.verbal_description }</span>
              </div>
          )}
        </div>
      ),
      status: (
        <div
          style={{
            height: "100%",
            width: "100%",
            padding: "5px",
            background:
              item.order_status === "PENDING"
                ? "#ffa500"
                : item.order_status === "PERFORMED"
                ? "#008000"
                : item.order_status === "CANCEL"
                ? "#7f7f7f"
                : "",
          }}
        >
          <div>{item.specific_label}</div>
          <div>{item.specific_order_by}</div>
        </div>
      ),
    }));
  }, [props.orderSummaryOPDList]);

  console.log(CARD_OPD_SUMMARY, " props:", props);

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_OPD_SUMMARY}`, null);
          props.setProp(`successMessage.${CARD_OPD_SUMMARY}`, null);
        }}
        error={props.errorMessage?.[CARD_OPD_SUMMARY]}
        success={props.successMessage?.[CARD_OPD_SUMMARY]}
      />
      <CardOPDOrderSummaryUX
        isOrder={props.orderSummaryOPDFilter?.isOrder}
        orderType={props.orderSummaryOPDFilter?.order}
        orderTypeOptions={props.orderSummaryOPDOptions}
        onOrderTypeChange={(e: any, data: any) => {
          props.setProp("orderSummaryOPDFilter.order", data.value);
        }}
        isLab={props.orderSummaryOPDFilter?.isLab}
        isTreatment={props.orderSummaryOPDFilter?.isTreatment}
        isImaging={props.orderSummaryOPDFilter?.isImaging}
        isOthers={props.orderSummaryOPDFilter?.isOthers}
        isOutPerformDiv={props.orderSummaryOPDFilter?.isOutPerformDiv}
        orderSummaryOPD={orderItems}
        onChangeChecked={handleOnCheckedChange}
        onCheckedAll={() => {
          props.setProp("orderSummaryOPDFilter", {
            ...props.orderSummaryOPDFilter,
            isLab: true,
            isTreatment: true,
            isImaging: true,
            isOthers: true,
            isOutPerformDiv: true,
          });
        }}
        onSearch={() => {
          props.onEvent({
            message: "HandleOPDOrderSummary",
            params: { action: "search", card: CARD_OPD_SUMMARY },
          });
        }}
        onSelectedOrder={(
          state: any,
          rowInfo: any,
          column: any,
          instance: any
        ) => ({
          style: {
            backgroundColor:
              props.nurseOPDOrderPerformed?.selectedOrder &&
              rowInfo?.original?.id ===
                props.nurseOPDOrderPerformed?.selectedOrder
                ? "#cccccc"
                : "white",
          },
          onClick: () => {
            props.onEvent({
              message: "HandleOPDOrderSummary",
              params: {
                action: "open",
                card: CARD_OPD_SUMMARY,
                orderId: rowInfo?.original?.id,
              },
            });
          },
        })}
      />
      <Modal
        open={props.nurseOPDOrderPerformed?.modOpen}
        onClose={() => {
          props.setProp("nurseOPDOrderPerformed.modOpen", false);
        }}
      >
        <ModNursePerformOrderUX
          selectedDate={props.nurseOPDOrderPerformed?.performedDate}
          onChangeDate={(date: string) => {
            props.setProp("nurseOPDOrderPerformed.performedDate", date);
          }}
          selectedTime={props.nurseOPDOrderPerformed?.performedTime}
          onTimeChange={(time) => {
            props.setProp("nurseOPDOrderPerformed.performedTime", time);
          }}
          onSave={() => {
            props.onEvent({
              message: "HandleOPDOrderSummary",
              params: {
                action: "confirm_performed",
                card: CARD_OPD_SUMMARY,
              },
            });
          }}
          // component
          employeeCode={
            <EmployeeToken
              placeholder="ระบุ code employee"
              onEnterToken={(code) => {
                props.onEvent({
                  message: "HandleOPDOrderSummary",
                  params: { action: "tokenize", code: code },
                });
              }}
              onClearToken={() => {
                props.setProp("nurseOPDOrderPerformed", {
                  ...props.nurseOPDOrderPerformed,
                  nurseToken: "",
                  nurseName: "",
                  nurseLoading: false,
                  nurseError: null,
                });
              }}
              error={props.nurseOPDOrderPerformed.nurseError}
              loading={props.nurseOPDOrderPerformed.nurseLoading}
              employeeName={props.nurseOPDOrderPerformed.nurseName}
            />
          }
        />
      </Modal>
    </>
  );
};

export default CardOPDOrderSummary;
