import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  FormGroup,
  FormField,
  TextArea,
  Button
} from 'semantic-ui-react'

const ANES_PACU_Problems = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div>
        <div
          style={{backgroundColor:"rgb(214, 236, 243, 0.23)",width: "100%",height:"500px"}}>
          
          <div
            style={{height:"50px",display:"flex",backgroundColor:"#B4E4EF",border: "solid 1px rgb(200, 200, 200, 0.5)"}}>
            
            <label
              style={{textAlign:"center",marginLeft:"20px",paddingTop:"10px","fontWeight":"bold","fontSize":"20px"}}>
              Problems / Remarks
            </label>
          </div>
          <div>
            
            <div
              className="ui form"
              style={{ margin: "20px"}}>
              
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={2}>
                  <label
                    style={{textAlign:"center","fontSize":"16px"}}>
                    Time
                  </label>
                  <div>
                    {props.problemTime}
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={14}>
                  <TextArea
                    name="remark"
                    onChange={props.onChangeProblemInput}
                    style={{marginLeft: "30px"}}
                    value={props.problem?.remark || ""}>
                  </TextArea>
                </FormField>
                <FormField
                  inline={true}
                  width={2}>
                  <Button
                    color={props.problem?.index === "" ?  "blue" : "yellow"}
                    onClick={props.onAddProblem}
                    style={{margin:"10px"}}>
                    {props.problem?.index === "" ?  "Add" : "Edit"}
                  </Button>
                </FormField>
              </FormGroup>
            </div>
          </div>
          <Table
            data={props.ansProblemData || []}
            headers="Time,Problem/Remarks,"
            keys="time,remark,menu"
            minRows="8"
            widths="150,auto,150">
          </Table>
        </div>
      </div>
    )
}

export default ANES_PACU_Problems

export const screenPropsDefault = {}

/* Date Time : Thu Mar 16 2023 10:11:17 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor:\"rgb(214, 236, 243, 0.23)\",width: \"100%\",height:\"500px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"50px\",display:\"flex\",backgroundColor:\"#B4E4EF\",border: \"solid 1px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Problems / Remarks"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"10px\",\"fontWeight\":\"bold\",\"fontSize\":\"20px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 15,
      "name": "Table",
      "parent": 1,
      "props": {
        "columns": {
          "type": "code",
          "value": ""
        },
        "data": {
          "type": "code",
          "value": "props.ansProblemData || []"
        },
        "headers": {
          "type": "value",
          "value": "Time,Problem/Remarks,"
        },
        "keys": {
          "type": "value",
          "value": "time,remark,menu"
        },
        "minRows": {
          "type": "value",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": ""
        },
        "widths": {
          "type": "value",
          "value": "150,auto,150"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"20px\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormGroup",
      "parent": 18,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "Time"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",\"fontSize\":\"16px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.problemTime"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "TextArea",
      "parent": 21,
      "props": {
        "name": {
          "type": "value",
          "value": "remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeProblemInput"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"30px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.problem?.remark || \"\""
        }
      },
      "seq": 25,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Button",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": "props.problem?.index === \"\" ?  \"Add\" : \"Edit\""
        },
        "color": {
          "type": "code",
          "value": "props.problem?.index === \"\" ?  \"blue\" : \"yellow\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddProblem"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 26,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PACU_Problems",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
