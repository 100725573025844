import React from "react";
import PropTypes from "prop-types";
import * as Common from "../common";
import {
  Button,
  Icon,
  Form,
  Label,
  Input,
  Divider,
  Header,
  TextArea,
  Checkbox,
  Modal
} from "semantic-ui-react";
import { SearchBox, ComboBox } from "../common";
import * as DPO from "../DPO";
import * as REG from "../REG";
import * as Util from "../../utils";
import * as CONSTANT from "../../utils/constant";
import SubTreatment from "./SubTreatment"

// TODO: CREATE flow 6/022020
// TODO: EDIT flow 6/022020
// TODO: advance order 6/022020
// TODO: template 6/022020
// TODO: show orderId from progressCycle

const CardTreatmentOrder = React.forwardRef((props, ref) => {
  const [readOnly, setReadOnly] = React.useState(false);
  const [treatment, setTreatment] = React.useState([]);
  const [principal, setPrincipal] = React.useState([]);
  const [showSecondary, setShowSecondary] = React.useState(false);
  const [secondary, setSecondary] = React.useState([]);
  const [performer, setPerformer] = React.useState([]);
  const [eligibility, setEligibility] = React.useState([]);
  const [emrId, setEmrId] = React.useState(null);
  const [encounterId, setEncounterId] = React.useState(null);
  const [progressionCycleId,  setProgressionCycleId] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [price, setPrice] = React.useState({
    totalPrice: 0,
    claimable: 0,
    nonClaimable: 0
  });
  // TODO: UI and logic
  const [advanceTreatmentOrder, setAdvanceTreatmentOrder] = React.useState(
    false
  );
  const [toBePerformer, setToBePerformer] = React.useState(null);
  const [isOperationDivision, setIsOperationDivision] = React.useState(false);
  const [orderDetail, setOrderDetail] = React.useState("");
  const [equipmentDetail, setEquipmentDetail] = React.useState("");
  const [note, setNote] = React.useState("");
  const [saveData, setSaveData] = React.useState({}); //* currently use in case appointment WAITED PAYMENT only

  const subTreatmentRef = React.useRef([]);
  const principalRef = React.useRef([]);
  const secondaryRef = React.useRef([]);
  const isMounted = React.useRef(false);

  React.useImperativeHandle(ref, () => ({
    getData: () => {
      //* get data and save outside(Ex. VerbalOrder)
      return prepareData();
    }
  }));

  React.useEffect(() => {
    isMounted.current = true;

    const getTreatmentOrderPerformer = async () => {
      const [data, error] = await props.controller.getTreatmentOrderPerformer();
      if (isMounted.current) {
        if (data) {
          setPerformer(data);
        }
      }
    };

    const getEligibility = async () => {
      const [data, error] = await props.controller.getChoices({
        field: "eligibility_type",
        model: "TRT.TreatmentOrderItem"
      });
      if (isMounted.current) {
        if (data) {
          setEligibility(data);
        }
      }
    };

    getTreatmentOrderPerformer();
    getEligibility();

    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    setEmrId(props.emrId);
  }, [props.emrId]);

  React.useEffect(() => {
    setEncounterId(props.encounterId);
  }, [props.encounterId]);

  React.useEffect(() => {
    setProgressionCycleId(props.progressionCycleId);
  }, [props.progressionCycleId]);

  const clear = () => {
    // rmdOrder._id = null;
    setEmrId(null)
    setProgressionCycleId(null)
    // rmdOrder._principalDiag = [];
    // rmdOrder._secondaryDiag = [];
    // rmdOrder._items = [];
    // rmdOrder.core_procedure = [];
    // rmdOrder.order_items = [];
    setOrderDetail("")
    // rmdOrder.order_summary_detail = "";
    setEquipmentDetail("")
    setPrice({
      totalPrice: 0,
      claimable: 0,
      nonClaimable: 0
    })
    setReadOnly(false)
    setToBePerformer(null)
    setNote("")
    // _rootOrder.order_id = null;

    // var adbChecked = _rootOrder.titleContent.find(function(item) {
    //   return item.id == "chkAdvanceTreatmentOrder";
    // });
    // adbChecked.checked = false;

    // var dtb = _rootOrder.titleContent.find(function(item) {
    //   return item.id == "dtbAdvanceTreatmentOrder";
    // });
    // dtb.text = "";

    // frmTreatment.clear();
    // frmDetail.clear();
    // btnSave.text = "Save";
    // btnSave.backgroundColor = "green";
  }

  const retrieve = (id) => {
    setIsLoading(true)
    setReadOnly(false)
    // rmdOrderForFetch.query = { id: id };
    // rmdOrderForFetch.url = "/apis/TRT/treatment-order/";
    // rmdOrderForFetch.fetch();
  }

  const refresh = () => {
    // TODO: Implement Refresh
    // var query = {};
    // if (isIPD) {
    //   if (!_.get(PATIENT_DATA, "EMR.emr_id", null)) {
    //     Util.danger("ไม่พบ emr");
    //   }
    //   _rootOrder.emr_id = PATIENT_DATA.EMR.emr_id;
    //   _rootOrder.encounter_id = PATIENT_DATA.ENCOUNTER.encounter_id;
    //   return;
    // }
    // _rootOrder.loading = true;
    // if (_rootOrder.is_appointment) {
    //   if (_rootOrder.order_id) {
    //     query["id"] = _rootOrder.order_id;
    //     rmdOrderForFetch.query = query;
    //     rmdOrderForFetch.url = "/apis/TRT/treatment-order/";
    //     rmdOrderForFetch.fetch();
    //   } else {
    //     _rootOrder.loading = false;
    //     // if(rptPrincipal.model.items.length == 0 ) {
    //     //     rptPrincipal.model.push({name:''})
    //     // }
    //   }
    // } else {
    //   if (!_rootOrder.emr_id || !_rootOrder.progression_cycle_id) {
    //     return (_rootOrder.loading = false);
    //   }
    //   if (_rootOrder.emr_id) {
    //     query["emr"] = _rootOrder.emr_id;
    //   }
    //   if (_rootOrder.progression_cycle_id) {
    //     query["progression_cycle"] = _rootOrder.progression_cycle_id;
    //   }
    //   rmdOrderForFetch.query = query;
    //   rmdOrderForFetch.url = "/apis/TRT/treatment-order/";
    //   rmdOrderForFetch.fetch();
    // }
    // Check OR division and set default value
    // if (
    //   typeof OR_DIVISIONS !== "undefined" &&
    //   _.includes(OR_DIVISIONS, DJANGO.division.code)
    // ) {
    //   chk_is_operation_div.checked = true;
    // } else {
    //   chk_is_operation_div.checked = false;
    // }
  };

  React.useEffect(() => {
    setReadOnly(props.readOnly);
  }, [props.readOnly]);

  React.useEffect(() => {
    if (secondary.length === 0) {
      setShowSecondary(false);
    } else {
      setShowSecondary(true);
    }
  }, [secondary]);

  React.useEffect(() => {
    handleCalculatePrice();
  }, [treatment]);

  const handleSearchTreatmentOptions = async ({
    searchText,
    limit,
    offset
  } = {}) => {
    const [data, error] = await props.controller.getTreatment({
      emrId,
      encounterId,
      search: searchText,
      limit: limit ? limit : 10,
      offset
    });
    return [data, error];
  };

  const handleCalculatePrice = () => {
    let arr = [];
    for (let item of subTreatmentRef.current) {
      if (item && item.checkValidData()) {
        arr.push(item.getData());
      }
    }
    postTreatmentOrderEstimate({ orderItems: arr });
  };

  const postTreatmentOrderEstimate = async ({ orderItems } = {}) => {
    if (orderItems.length === 0 || !encounterId) {
      return;
    }
    setIsLoading(true);
    const [
      data,
      error,
      network
    ] = await props.controller.postTreatmentOrderEstimate({
      orderItems,
      encounterId: encounterId
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        setPrice(prevState => ({
          ...prevState,
          totalPrice: data.price_total,
          claimable: data.price_claimable,
          nonClaimable: data.price_non_claimable
        }));
      } else if (error && ![500, 503].includes(network.response.state)) {
        // frmTreatment.showError(error)
      }
    }
  };

  const generateTreatment = () => {
    return treatment.map((item, index) => (
      <SubTreatment
        key={item.id}
        ref={el => (subTreatmentRef.current[index] = el)}
        readOnly={readOnly}
        eligibility={eligibility}
        onGetSearchOptions={handleSearchTreatmentOptions}
        onRecalculatePrice={handleCalculatePrice}
        onRemove={() => {
          let newArr = [...treatment];
          let index = treatment.findIndex(items => items.id === item.id);
          newArr.splice(index, 1);
          setTreatment(newArr);
        }}
      />
    ));
  };

  const handleAddTreatment = () => {
    // if (frmTreatment.validateForm()) {
    let newArr = [...treatment];
    newArr.push({
      id: "fakeId" + newArr.length,
      product: null,
      product_name: null,
      quantity: null,
      unit: "&nbsp"
    });
    setTreatment(newArr);
    // }
  };

  const generatePrincipal = () => {
    return principal.map((item, index) => (
      <DPO.SubICDDetail
        controller={props.subICDController}
        key={item.id}
        ref={el => (principalRef.current[index] = el)}
        onDelete={() => {
          let newArr = [...principal];
          let index = principal.findIndex(items => items.id === item.id);
          newArr.splice(index, 1);
          setPrincipal(newArr);
        }}
      />
    ));
  };

  const handleAddPrincipal = () => {
    let newArr = [...principal];
    newArr.push({
      id: "fakeId" + newArr.length,
      name: ""
    });
    setPrincipal(newArr);
  };

  const handleAddSecondary = () => {
    let newArr = [...secondary];
    newArr.push({
      id: "fakeId" + newArr.length,
      name: ""
    });
    setSecondary(newArr);
  };

  const generateSecondary = () => {
    return secondary.map((item, index) => (
      <DPO.SubICDDetail
        controller={props.subICDController}
        key={item.id}
        ref={el => (secondaryRef.current[index] = el)}
        onDelete={() => {
          let newArr = [...secondary];
          let index = secondary.findIndex(items => items.id === item.id);
          newArr.splice(index, 1);
          setSecondary(newArr);
        }}
      />
    ));
  };

  const handleSetICDData = ({ data, type } = {}) => {
    let newData = {};
    newData.type = type;
    newData.emr = emrId;
    newData.medical_description = data.medical_description;
    newData.icd9cm = data.icd10_id;
    newData.icd9cm_med_term = data.medterm_id ? data.medterm_id : null; //! Not sure where medterm_id from yet
    newData.icd9cm_code = data.icd_code;
    newData.icd9cm_term = data.icd_term;
    newData.id = data.id ? data.id : 0; //! Not sure where id from yet
    return newData;
  };

  const handleSave = () => {
    if (!props.isAppointment && !emrId && !progressionCycleId) {
      return;
    }
    let data = prepareData();
    console.log(data, "data");
    if (data && data.action === "EDIT") {
      if (
        props.isAppointment &&
        props.appointment &&
        props.appointment.is_billed &&
        props.appointment.status_name === "WAIT_PAYMENTED"
      ) {
        //* this case will show confirm modal, and save after approved
        return;
      }
      handleEditTreatmentOrder({ data });
    } else if (data && data.action === "ORDER") {
      handleCreateTreatmentOrder({ data });
    }
  };

  const handleEditTreatmentOrder = ({ data } = {}) => {};

  const handleCreateTreatmentOrder = async ({ data } = {}) => {
    const [response, error, network] = await props.controller.postTreatmentOrder({ data })
    if(response){
      handleSaveTreatmentOrderSuccess({ response })
    }
    console.log(response, 'response')
  };

  const handleSaveTreatmentOrderSuccess
  
  
  = ({ response } = {}) => {

  }

  const prepareData = () => {
    let data = {};
    data.to_be_performer = toBePerformer;
    data.show_alert = props.showAlert;
    data.is_operation_division = isOperationDivision;
    data.order_detail = orderDetail;
    data.equipment_detail = equipmentDetail;
    data.note = note;
    data.order_summary_detail = ""; // TODO: have to implement in EDIT case, "" is for create
    if (advanceTreatmentOrder) {
      data.is_advance = true;
      // TODO: Implement advance Order
      //     var dtb = _rootOrder.titleContent.find( function(item) {
      //         return  item.id=="dtbAdvanceTreatmentOrder"
      //         });
      //     var advDateList = dtb.getMomentValue().format('DD/MM/YYYY').split('/')
      //     var advThaiYear = parseInt(advDateList[2]) + 543
      //     var currentTime = moment().format('HH:mm')
      //     rmdOrder.order_time = advDateList[0] + '/' + advDateList[1] + '/' + advThaiYear + '-' + currentTime
    } else {
      data.is_advance = false;
      data.order_time = null;
    }
    data.emr = emrId;
    data.progression_cycle = progressionCycleId;
    data.encounter = encounterId;
    let _principalDiag = principalRef.current.map(item => {
      let principalData = item.getData();
      return handleSetICDData({ data: principalData, type: "PRIMARY" });
    });
    let _secondaryDiag = secondaryRef.current.map(item => {
      let secondaryData = item.getData();
      return handleSetICDData({ data: secondaryData, type: "SECONDARY" });
    });
    data._principalDiag = _principalDiag;
    data._secondaryDiag = _secondaryDiag;
    if (_principalDiag.length === 0 && _secondaryDiag.length > 0) {
      // TODO: Implement treatment's form error
      // frmTreatment.showError({'ต้องระบุอย่างน้อย 1 Primary, จึงจะระบุ Secondary ได้': []})
      return;
    }
    let _items = subTreatmentRef.current.map(item => item.getData());
    data.core_procedure = _principalDiag.concat(_secondaryDiag);
    data.order_items = _items;
    // data._items = _items;
    data.action = props.id ? "EDIT" : "ORDER";
    data.core_procedure.forEach(procedure => {
      procedure.emr = emrId;
      if (!procedure.id) {
        delete procedure.id;
      }
    });
    data.order_items.forEach(order_item => {
      if (!order_item.id) {
        delete order_item.id;
      }
    });
    if (props.isAppointment) {
      if (data.action === "EDIT") {
        if (
          props.appointment &&
          props.appointment.is_billed &&
          props.appointment.status_name === "WAIT_PAYMENTED"
        ) {
          // TODO: return show modal
          setSaveData(data);
          //             Util.confirm({
          //                 titleName: 'Order นี้มีค่ามัดจำ กรุณายืนยันการแก้ไข',
          //                 titleColor: 'yellow',
          //                 approveButtonText: 'ยืนยัน',
          //                 denyButtonText: 'ยกเลิก',
          //                 onApprove: function() {
          //                     rmdOrder.save()
          //                 }
          //             })
          return data; //* also return data
        } else {
          return data;
        }
      } else {
        //* case create
        data.order_status = CONSTANT.DOCTOR_ORDER.ORDER_STATUS.APPOINTMENT;
        data.order_payment_status = props.isIPD
          ? CONSTANT.DOCTOR_ORDER.PAYMENT_STATUS.READY
          : CONSTANT.DOCTOR_ORDER.PAYMENT_STATUS.PENDING;
        return data;
      }
    } else {
      if (data.action !== "EDIT") {
        //* case create
        data.order_status = props.isIPD
          ? CONSTANT.DOCTOR_ORDER.ORDER_STATUS.PENDING
          : CONSTANT.DOCTOR_ORDER.ORDER_STATUS.DRAFT;
        data.order_payment_status = props.isIPD
          ? CONSTANT.DOCTOR_ORDER.PAYMENT_STATUS.READY
          : CONSTANT.DOCTOR_ORDER.PAYMENT_STATUS.PENDING;
        return data;
      }
      return data; //* case EDIT
    }
  };

  return (
    <Common.CardLayout
      titleText="Treatment"
      toggleable={props.toggleable}
      closeable={props.closeable}
      loading={isLoading}
    >
      <Form>
        {generateTreatment()}
        <Form.Group inline>
          <Form.Field width={3}>
            <Button
              disabled={readOnly}
              fluid
              color="green"
              onClick={handleAddTreatment}
              type="button"
            >
              <Icon name="plus" /> Add
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
      <Divider />
      <Header content="Principal" />
      <Form>
        {generatePrincipal()}
        <Form.Group inline>
          <Form.Field
            width={3}
            style={{ display: !props.showAddButton ? "none" : null }}
          >
            <Button
              fluid
              color="green"
              onClick={handleAddPrincipal}
              disabled={readOnly}
            >
              <Icon name="plus" /> Principal
            </Button>
          </Form.Field>
          <Form.Field
            width={3}
            style={{ display: !props.showAddButton ? "none" : null }}
          >
            <Button
              fluid
              color="yellow"
              style={{ display: showSecondary ? "none" : null }}
              disabled={readOnly}
              onClick={() => {
                handleAddSecondary();
              }}
            >
              <Icon name="plus" /> Secondary
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
      <Divider hidden={!showSecondary} />
      <Header
        content="Secondary"
        style={{ display: !showSecondary ? "none" : null }}
      />
      <Form style={{ display: !showSecondary ? "none" : null }}>
        {generateSecondary()}
        <Form.Group inline>
          <Form.Field width={3}>
            <Button
              fluid
              color="yellow"
              onClick={handleAddSecondary}
              disabled={readOnly}
            >
              <Icon name="plus" /> Secondary
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
      <br />
      <Form>
        <Form.Group inline>
          <Form.Field width={3}>
            <label>ผู้ทำหัตถการ</label>
          </Form.Field>
          <Form.Field width={13}>
            <ComboBox
              readOnly={readOnly}
              options={performer}
              onChange={(e, { value }) => setToBePerformer(value)}
              value={toBePerformer}
              fluid
            />
          </Form.Field>
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={3}>
            <label>รายละเอียดเพิ่มเติม</label>
          </Form.Field>
          <Form.Field width={13}>
            <TextArea
              rows={2}
              readOnly={readOnly}
              value={orderDetail}
              onChange={e => setOrderDetail(e.target.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={3}>
            <label>อุปกรณ์</label>
          </Form.Field>
          <Form.Field width={13}>
            <TextArea
              rows={2}
              readOnly={readOnly}
              value={equipmentDetail}
              onChange={e => setEquipmentDetail(e.target.value)}
            />
          </Form.Field>
        </Form.Group>
        <br />
        <Form.Group inline>
          <Form.Field width={10}>
            <label>ราคา</label>
            <Label
              className="fluid large price"
              content={
                price.totalPrice ? (
                  Util.formatComma(price.totalPrice)
                ) : (
                  <div>&nbsp;</div>
                )
              }
            />
          </Form.Field>
          <Form.Field width={10}>
            <label>เบิกได้</label>
            <Label
              className="fluid large price"
              content={
                price.claimable ? (
                  Util.formatComma(price.claimable)
                ) : (
                  <div>&nbsp;</div>
                )
              }
            />
          </Form.Field>
          <Form.Field width={10}>
            <label>เบิกไม่ได้</label>
            <Label
              className="fluid large price"
              content={
                price.nonClaimable ? (
                  Util.formatComma(price.nonClaimable)
                ) : (
                  <div>&nbsp;</div>
                )
              }
            />
          </Form.Field>
        </Form.Group>
        <br />
        {!props.hideSaveRow && <Form.Group inline>
          <Form.Field width={3}>
            <Checkbox
              toggle
              label="เป็นแผนก OR"
              disabled={readOnly}
              checked={isOperationDivision}
              onChange={(e, { checked }) => setIsOperationDivision(checked)}
            />
          </Form.Field>
          <Form.Field
            width={2}
            style={{ display: props.hideSaveButton ? "none" : null }}
          >
            <Button
              color="green"
              fluid
              content="Save"
              disabled={readOnly}
              onClick={handleSave}
            />
          </Form.Field>
          <Form.Field
            width={11}
            style={{ display: !props.isIPD ? "none" : null }}
          >
            <label>Note</label>
            <Input value={note} onChange={e => setNote(e.target.value)} />
          </Form.Field>
        </Form.Group>}
      </Form>
    </Common.CardLayout>
  );
});

CardTreatmentOrder.defaultProps = {
  toggleable: true,
  closeable: false,
  subICDController: {},
  readOnly: false,
  isIPD: false,
  showAddButton: true,
  controller: {},
  patientData: {},
  hideSaveButton: false,
  isAppointment: false,
  appointment: null,
  progressionCycleId: null,
  showAlert: true,
  hideSaveRow: false,
};

CardTreatmentOrder.propTypes = {
  toggleable: PropTypes.bool,
  true: PropTypes.bool,
  subICDController: PropTypes.object,
  readOnly: PropTypes.bool,
  isIPD: PropTypes.bool,
  showAddButton: PropTypes.bool,
  controller: PropTypes.object,
  patientData: PropTypes.object,
  hideSaveButton: PropTypes.bool,
  isAppointment: PropTypes.bool,
  appointment: PropTypes.object,
  progressionCycleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showAlert: PropTypes.bool,
  hideSaveRow: PropTypes.bool,
};

export default React.memo(CardTreatmentOrder);
