import WasmController from "react-lib/frameworks/WasmController";

// APIs
import * as FoodListI from "./sequence/FoodList";
import * as FoodRequestI from "./sequence/FoodRequest";

export type State = {} & FoodListI.State & FoodRequestI.State;

export const StateInitial: State = {
  ...FoodListI.StateInitial,
  ...FoodRequestI.StateInitial,
};

export type Event =
  | FoodListI.Event
  | FoodRequestI.Event;

export type Data = {
  division?: number;
} & FoodListI.Data &
  FoodRequestI.Data;

export const DataInitial = {
  ...FoodListI.DataInitial,
  ...FoodRequestI.DataInitial,
};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const DidMount: Handler = async (controller, params) => {};
