import moment from "moment"
import JsBarcode from "jsbarcode";

const textToBase64BarcodeBlob = (text: string) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, text, { displayValue: false });
  return canvas.toDataURL("image/png");
};

export const createPDFLabOrderQueue = (props: any) => {
  console.log("createPDFLabRequest props", props);

  import("pdfmake/build/pdfmake").then((make) => {
    import("react-lib/assets/fonts/pdfFonts").then(async (font) => {
      let pdfMake = make.default;
      pdfMake.vfs = font.default;
      pdfMake.fonts = {
        THSarabunNew: {
          normal: "THSarabunNew.ttf",
          bold: "THSarabunNew-Bold.ttf",
          italics: "THSarabunNew-Italic.ttf",
          bolditalics: "THSarabunNew-BoldItalic.ttf",
        },
        Roboto: {
          normal: "Roboto-Regular.ttf",
          bold: "Roboto-Medium.ttf",
          italics: "Roboto-Italic.ttf",
          bolditalics: "Roboto-MediumItalic.ttf",
        },
      };
      let today = new Date();
      let getdate =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        (today.getFullYear() + 543);

      let gettime = today.getHours() + ":" + today.getMinutes();
      let time = moment(gettime, "hh:mm").format("HH:mm");
      let timenow = `${time}`;
      let id = props?.data?.id;

      let hn = props?.data?.hn;
      let hn_s = `${hn}`;
      let patient_name = props?.data?.patient_name;
      let patient_name_s = `${patient_name}`;
      let datetime = props?.data?.date;
      let datetime_s = `${datetime}`;
      let doctor_name = props?.data?.doctor_name;
      let doctor_name_s = `${doctor_name}`;
      let division_name = props?.data?.division_name;
      let division_name_s = `${division_name}`;
      let highest_urgency = props?.data?.highest_urgency;
      let highest_urgency_s = `${highest_urgency}`;

      let birthdate = props?.hn_data?.birthdate || "";
      let birthdate_s = `${birthdate}`;
      let full_age = props?.hn_data?.full_age || "";
      let full_age_s = `${full_age}`;
      let coverage = props?.hn_data?.coverage || "";
      let coverage_s = `${coverage}`;
      let gender_name = props?.hn_data?.gender_name;
      let gender_name_s = `${gender_name}`;

      let urgency = props?.urgency;
      let username = props?.username;

      function printUrgency(highest_urgency: any) {
        if (highest_urgency === "STAT") {
          return {
            text: "STAT",
            style: "fieldHead",
            color: "red",
          };
        } else if (highest_urgency === "FAST_STACK") {
          return {
            text: "FAST_STACK",
            style: "fieldHead",
            color: "red",
          };
        } else {
          return { text: " " };
        }
      }

      let labOrderList = props?.lab_data?.specimen_containers?.map(
        (item: any) => {
          let specimen_name = item?.specimen_name;
          let specimen_name_s = `${specimen_name}`;
          let lab_code = item?.lab_code;
          let lab_test = item?.lab_test;

          function printLabcode(lab_code: any) {
            let input = lab_code?.split(",");
            var labOutput = "";
            for (let i = 0; i < input.length; i++) {
              labOutput += input[i] + "\n";
            }

            return labOutput;
          }
          function printLabtest(lab_test: any) {
            let input = lab_test?.split("-");
            console.log(input);
            return input;
          }

          return [
            { text: specimen_name_s, style: "fieldValues" },
            { text: printLabcode(lab_code), style: "fieldValues" },
            { text: printLabtest(lab_test), style: "fieldValues" },
          ];
        }
      );

      var docDefinition = {
        defaultStyle: {
          font: "THSarabunNew",
        },
        pageMargins: [8, 167, 0, 90],
        pageSize: "A5",
        pageOrientation: "landscape",
        styles: {
          fieldHead: {
            bold: true,
            fontSize: 18,
          },
          fieldIDHead: {
            bold: true,
            fontSize: 16,
          },
          fieldOPHead: {
            bold: true,
            fontSize: 18,
          },
          fieldDataBold: {
            bold: true,
            fontSize: 14,
          },
          fieldValues: {
            fontSize: 14,
          },
          fieldFooter: {
            fontSize: 12,
          },
          tableHeader: {
            fillColor: "white",
          },
          tableNormal: {
            margin: [0, 5, 0, 5],
          },

          tableExample: {
            margin: [0, 0, 0, 0],
          },
        },
        header: {
          margin: [0, 8, 40, 0],

          stack: [
            {
              columns: [
                {
                  table: {
                    widths: [10, 532],
                    heights: 305,
                    body: [
                      [
                        { text: " ", border: [true, true, false, false] },
                        { text: " ", border: [false, true, true, false] },
                      ],
                      [
                        { text: " ", border: [true, true, false, false] },
                        { text: " ", border: [false, true, true, false] },
                      ],
                    ],
                  },
                },
              ],
              relativePosition: { x: 17, y: 57 },
            },
            {
              columns: [
                {
                  image: "logochula",
                  width: 120,
                  absolutePosition: { x: 14, y: 10 },
                },
                {
                  text: [
                    {
                      text: "In Lab: ",
                    },

                    {
                      text: id,
                    },
                  ],
                  style: "fieldIDHead",

                  absolutePosition: { x: 515, y: 3 },
                },
              ],
            },

            {
              columns: [
                {
                  text: [
                    {
                      text: "ใบขอตรวจแลป (Lab Request)",
                      style: "fieldHead",
                    },
                  ],
                  relativePosition: { x: 222.5, y: 26.5 },
                },
              ],
            },
            {
              columns: [
                {
                 
                 text: printUrgency(highest_urgency)
                },
              ],
              relativePosition: { x: 550, y: 25.5 },
            },
            {
              columns: [
                {
                  width: 180,
                  text: [
                    { text: "HN : ", style: "fieldDataBold" },
                    { text: hn_s, style: "fieldValues" },
                  ],
                },
                {
                  width: 320,
                  text: [
                    { text: "ชื่อ - สกุล : ", style: "fieldDataBold" },
                    { text: patient_name_s, style: "fieldValues" },
                  ],
                },
                {
                  width: "auto",
                  text: [
                    { text: "เพศ : ", style: "fieldDataBold" },
                    {
                      ...(gender_name_s === "Female"
                        ? {
                            text: "หญิง",
                          }
                        : {
                            text: "ชาย",
                          }),
                      style: "fieldValues",
                    },
                  ],
                },
              ],
              relativePosition: { x: 26, y: 72 },
            },
            {
              margin: [0, 0, 53, 0],
              columns: [
                {
                  width: 180,
                  text: [
                    { text: "วันเดือนปีเกิด : ", style: "fieldDataBold" },
                    { text: birthdate_s, style: "fieldValues" },
                  ],
                },
                {
                  width: "auto",
                  text: [
                    { text: "อายุ : ", style: "fieldDataBold" },
                    { text: full_age_s, style: "fieldValues" },
                  ],
                },
                {
                  width: "*",
                  text: [
                    {
                      text: "สิทธิการรักษา : ",
                      style: "fieldDataBold",
                    },
                    { text: coverage_s, style: "fieldValues" },
                  ],
                  alignment: "right",
                },
              ],
              relativePosition: { x: 26, y: 92 },
            },
            {
              margin: [0, 0, 70, 0],
              columns: [
                {
                  width: 210,
                  text: [
                    { text: "วัน เวลา ที่สั่ง : ", style: "fieldDataBold" },
                    { text: datetime_s, style: "fieldValues" },
                  ],
                },
                {
                  text: [
                    { text: "แพทย์ผู้สั่ง : ", style: "fieldDataBold" },
                    { text: doctor_name_s, style: "fieldValues" },
                    { text: " (", style: "fieldValues" },
                    { text: division_name_s, style: "fieldValues" },
                    { text: ") ", style: "fieldValues" },
                  ],
                  alignment: "right",
                },
              ],
              relativePosition: { x: 35, y: 127 },
            },
            {
              margin: [0, 0, 70, 0],
              columns: [
                {
                  image: textToBase64BarcodeBlob(id),
                  width: 80,
                  height: 35,
                  alignment: "right",
                },
              ],
              relativePosition: { x: 494, y: 325 },
            },
            {
              columns: [
                {
                  canvas: [
                    {
                      type: "line",
                      lineColor: "#black",
                      x1: 17,
                      y1: 117,
                      x2: 577,
                      y2: 117,
                      lineWidth: 1,
                    },
                    {
                      type: "line",
                      x1: 35,
                      y1: 157,
                      x2: 560,
                      y2: 157,
                      lineWidth: 2.09,
                    },

                  ],
                },
              ],
            },
          ],
        },

        images: {
          // logo: origin + "/images/IHimage.png",
          logochula: origin + "/static/images/logochula_dent.jpg",
        },
        content: [
          {
            margin: [0, 0, 0, 100],
            style: "tableExample",
            absolutePosition: { x: 35, y: 167 },
            table: {
              headerRows: 1,
              widths: ["23.5%", "20%", "50%"],
              body: [
                [
                  { text: "Specimen", style: "fieldDataBold" },
                  { text: "Code", style: "fieldDataBold" },
                  { text: "Lab test", style: "fieldDataBold" },
                ],

                ...labOrderList,
              ],
            },
            layout: "lightHorizontalLines",
          },
        ],
        footer: function (currentPage: any, pageCount: any) {
          return {
            margin: [17, 68, 0, 0],
            text: [
              {
                text: [{ text: " ผู้พิมพ์ : ", style: "fieldDataBold" }],
                alignment: "right",
              },
            ],

            columns: [
              {
                width: 230,
                text: [
                  {
                    text: [
                      { text: " ผู้พิมพ์ : ", style: "fieldFooter" },
                      { text: username, style: "fieldFooter" },
                    ],
                    alignment: "right",
                  },
                ],
              },

              {
                width: 295,
                text: [
                  {
                    text: [
                      { text: " วัน เวลาที่พิมพ์:", style: "fieldFooter" },
                      { text: ` ${getdate} `, style: "fieldFooter" },
                      { text: "[", style: "fieldFooter" },
                      { text: `${timenow}`, style: "fieldFooter" },
                      { text: "]", style: "fieldFooter" },
                    ],
                    alignment: "right",
                  },
                ],
              },
              {
                text: [
                  {
                    text: [
                      {
                        text:
                          " หน้าที่ " +
                          currentPage.toString() +
                          "/" +
                          pageCount,
                        style: "fieldPrintDate",
                      },
                    ],
                    alignment: "left",
                  },
                ],
              },
            ],
          };
        },
      };

      let pdfDocGenerator = pdfMake.createPdf(docDefinition).open();
    });
  });
};
