import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Dropdown,
  Checkbox,
  FormGroup,
  FormField,
  Icon
} from 'semantic-ui-react'

const CardDrugOrderQueueUX = (props: any) => {
    return(
      <div
        style={{ width: "100%",padding: "5px" }}>
        <div
          style={{display: "flex", fontSize: "1.5rem", fontWeight: "bold", padding: "5px", margin: "0.5rem 0 1rem", justifyContent: "space-between", alignItems: "center",}}>
          
          <div>
            {props.isVerbal ? "รคส" : "รายการใบยา"}
          </div>
          <div
            style={{ display: props.showDrugOrderPendingList ? "flex" : "none" }}>
            {props.refillPlaned}
          </div>
        </div>
        <div
          className="ui form"
          style={{display: props.hidePanel ? "none" : ""}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={1}>
              <label
                style={{textAlign: "right", width: "100%"}}>
                Patient
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%"}}>
                {props.patientSearch}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.checkedOPD}
                label="OPD"
                onChange={props.changeSetDrugOrderCheck("checkedOPD")}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.checkedIPD}
                label="IPD"
                onChange={props.changeSetDrugOrderCheck("checkedIPD")}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Checkbox
                checked={props.checkedContinue}
                label="Continue for Day dose"
                onChange={props.changeSetDrugOrderCheck("checkedContinue")}
                style={{width: "167.5px"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <label
                style={{textAlign: "right", width: "100%"}}>
                Division/Ward
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.changeSetDrugOrder("selectedDivision")}
                options={props.divisionOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.selectedDivision}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{width: "30px", marginLeft: "1rem"}}>
                วันที่
              </label>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%"}}>
                {props.StartDate}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label>
                ถึง
              </label>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%"}}>
                {props.EndDate}
              </div>
            </FormField>
            <div
              className="inline four wide field"
              style={{marginLeft: "auto", justifyContent: "flex-end"}}>
              
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}>
                  <Button
                    color="green"
                    size="tiny"
                    style={{minWidth: "max-content"}}>
                    พิมพ์ใบจัดยา
                  </Button>
                </FormField>
                <FormField
                  inline={true}>
                  <Button
                    color="green"
                    size="tiny"
                    style={{minWidth: "max-content", marginRight: "-25px"}}>
                    พิมพ์ใบเตรียมจัดส่งยา
                  </Button>
                </FormField>
              </FormGroup>
            </div>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{textAlign: "right", width: "100%"}}>
                Location
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Dropdown
                fluid={true}
                onChange={props.changeSetDrugOrder("selectedLocation")}
                options={props.divisionTypeDrugOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.selectedLocation}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <div
                style={{textAlign: "right", width: "100%",minWidth: "max-content"}}>
                
                <label
                  style={{fontWeight: 700, color: "rgba(0,0,0,.87)", fontSize: ".92857143em"}}>
                  Order Status
                </label>
                <div>
                  
                  <div
                    style={{flex:1}}>
                    
                  </div>
                  <div
                    style={{cursor: "pointer"}}>
                    
                    <Icon
                      name="undo"
                      onClick={props.onResetFilter("selectedDrugOrderStatus")}>
                    </Icon>
                  </div>
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <div
                id="cb-medOrderStatus"
                style={{width: "100%"}}>
                {props.OrderStatus}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <div
                style={{textAlign: "right", width: "100%",minWidth: "max-content"}}>
                
                <label
                  style={{fontWeight: 700, color: "rgba(0,0,0,.87)", fontSize: ".92857143em"}}>
                  Order Type
                </label>
                <div>
                  
                  <div
                    style={{flex:1}}>
                    
                  </div>
                  <div
                    style={{cursor: "pointer"}}>
                    
                    <Icon
                      name="undo"
                      onClick={props.onResetFilter("selectedDrugOrderType")}>
                    </Icon>
                  </div>
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <div
                id="cb-medOrderType"
                style={{width: "100%"}}>
                {props.OrderType}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="blue"
                id="btn-searchMedOrder"
                onClick={props.onSearch}>
                ค้นหา
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{display: props.hidePanel ? "none" : "none", alignItems: "center"}}>
          
          <label
            style={{marginRight: "10px", width: "80px",textAlign: "right"}}>
            Patient
          </label>
          <div
            style={{marginRigth: "5px", width: "230px"}}>
            {props.patientSearch}
          </div>
          <div
            style={{display: "flex", alignItems: "center", marginRight: "10px"}}>
            
            <div
              style={{marginRight: "5px", width: "100px",textAlign: "right"}}>
              Division/Ward
            </div>
            <Dropdown
              clearable={true}
              onChange={props.changeSetDrugOrder("selectedDivision")}
              options={props.divisionOptions}
              search={true}
              selection={true}
              value={props.selectedDivision}>
            </Dropdown>
          </div>
          <div
            style={{display: "flex", alignItems: "center", marginRight: "10px"}}>
            
            <div
              style={{marginRight: "5px", width: "100px",textAlign: "right"}}>
              Order Type
            </div>
            <Dropdown
              clearable={true}
              onChange={props.changeSetDrugOrder("selectedDrugOrderType")}
              options={props.drugOrderType}
              search={true}
              selection={true}
              value={props.selectedDrugOrderType}>
            </Dropdown>
          </div>
          <div
            style={{flex: 1}}>
            
          </div>
          <Button
            color="green"
            size="tiny">
            พิมพ์ใบจัดยา
          </Button>
          <Button
            color="green"
            size="tiny">
            พิมพ์ใบเตรียมจัดส่งยา
          </Button>
        </div>
        <div
          style={{display: props.hidePanel ? "none" : "none", alignItems: "center", padding: "10px 0px"}}>
          
          <div
            style={{marginRight: "5px", width: "80px",textAlign: "right"}}>
            Order Status
          </div>
          <Dropdown
            clearable={true}
            onChange={props.changeSetDrugOrder("selectedDrugOrderStatus")}
            options={props.orderStatusOptions}
            search={true}
            selection={true}
            style={{width: "200px"}}
            value={props.selectedDrugOrderStatus}>
          </Dropdown>
          <div
            style={{margin: "0px 5px",width: "130px",textAlign: "right"}}>
            Shipper
          </div>
          <Dropdown
            clearable={true}
            onChange={props.changeSetDrugOrder("selectedShipper")}
            options={props.shipperOptions}
            search={true}
            selection={true}
            value={props.selectedShipper}>
          </Dropdown>
          <div
            style={{display: "flex", alignItems: "center", margin: "0px 10px"}}>
            
            <Checkbox
              checked={props.checkedOPD}
              onChange={props.changeSetDrugOrderCheck("checkedOPD")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              OPD
            </div>
            <Checkbox
              checked={props.checkedIPD}
              onChange={props.changeSetDrugOrderCheck("checkedIPD")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              IPD
            </div>
          </div>
          <Button
            color="blue"
            loading={props.searching}
            onClick={props.onSearch}
            size="tiny">
            ค้นหา
          </Button>
        </div>
        <Table
          data={props.drugOrderQueue}
          getTrProps={props.rowProps}
          headers={props.drugOrderQueueHeaders ? props.drugOrderQueueHeaders : ",Order No.,Status,Encounter Type,Order Type,HN,Patient name,Order by,Department,Order date/time,Shipper,Tracking No."}
          id="tb-medOrderQueueList"
          keys="checkbox,code,status,encounter_type,type,hn,patient, order_by_name, order_div_name,requested,shipping_company,tracking_number"
          showPagination={false}
          style={{ height: props?.height || "80vh", display: props.isVerbal ? "none" : "flex", alignItems: "center", marginTop: "1rem"}}
          widths="40">
        </Table>
        <Table
          data={props.verbalOrderQueue}
          getTrProps={props.verbalRowProps}
          headers="ชื่อนามสกุล,เลขที่,division,ประเภท,en,วันเวลา"
          keys="patient_name,drug_order_code,division,drug_order_type,en,edited"
          showPagination={false}
          style={{height: props?.height || "80vh", display: props.isVerbal ? "flex":"none", alignItems: "center"}}>
        </Table>
      </div>
    )
}

export default CardDrugOrderQueueUX

export const screenPropsDefault = {"height":"40vh","patientSearch":"[Patient Search Box]"}

/* Date Time : Wed Mar 29 2023 07:16:52 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\",padding: \"5px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 8,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugOrderQueue"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "code",
          "value": "props.drugOrderQueueHeaders ? props.drugOrderQueueHeaders : \",Order No.,Status,Encounter Type,Order Type,HN,Patient name,Order by,Department,Order date/time,Shipper,Tracking No.\""
        },
        "id": {
          "type": "value",
          "value": "tb-medOrderQueueList"
        },
        "keys": {
          "type": "value",
          "value": "checkbox,code,status,encounter_type,type,hn,patient, order_by_name, order_div_name,requested,shipping_company,tracking_number"
        },
        "minRows": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: props?.height || \"80vh\", display: props.isVerbal ? \"none\" : \"flex\", alignItems: \"center\", marginTop: \"1rem\"}"
        },
        "widths": {
          "type": "value",
          "value": "40"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontSize: \"1.5rem\", fontWeight: \"bold\", padding: \"5px\", margin: \"0.5rem 0 1rem\", justifyContent: \"space-between\", alignItems: \"center\",}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.isVerbal ? \"รคส\" : \"รายการใบยา\""
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ใบจัดยา"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ใบเตรียมจัดส่งยา"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.hidePanel ? \"none\" : \"none\", alignItems: \"center\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearch"
        },
        "style": {
          "type": "code",
          "value": "{marginRigth: \"5px\", width: \"230px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"10px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "Division/Ward"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"5px\", width: \"100px\",textAlign: \"right\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Dropdown",
      "parent": 17,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrder(\"selectedDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDivision"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"10px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "Order Type"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"5px\", width: \"100px\",textAlign: \"right\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Dropdown",
      "parent": 20,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrder(\"selectedDrugOrderType\")"
        },
        "options": {
          "type": "code",
          "value": "props.drugOrderType"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugOrderType"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 31,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.verbalOrderQueue"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.verbalRowProps"
        },
        "headers": {
          "type": "value",
          "value": "ชื่อนามสกุล,เลขที่,division,ประเภท,en,วันเวลา"
        },
        "keys": {
          "type": "value",
          "value": "patient_name,drug_order_code,division,drug_order_type,en,edited"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: props?.height || \"80vh\", display: props.isVerbal ? \"flex\":\"none\", alignItems: \"center\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\", width: \"80px\",textAlign: \"right\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.hidePanel ? \"none\" : \"none\", alignItems: \"center\", padding: \"10px 0px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "Order Status"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"5px\", width: \"80px\",textAlign: \"right\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "Shipper"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 5px\",width: \"130px\",textAlign: \"right\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Dropdown",
      "parent": 33,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrder(\"selectedDrugOrderStatus\")"
        },
        "options": {
          "type": "code",
          "value": "props.orderStatusOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"200px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugOrderStatus"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Dropdown",
      "parent": 33,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrder(\"selectedShipper\")"
        },
        "options": {
          "type": "code",
          "value": "props.shipperOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedShipper"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"0px 10px\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Checkbox",
      "parent": 38,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedOPD"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrderCheck(\"checkedOPD\")"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "OPD"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Checkbox",
      "parent": 38,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedIPD"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrderCheck(\"checkedIPD\")"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "IPD"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Button",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "loading": {
          "type": "code",
          "value": "props.searching"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: props.hidePanel ? \"none\" : \"\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormGroup",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormGroup",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Checkbox",
      "parent": 49,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedOPD"
        },
        "label": {
          "type": "value",
          "value": "OPD"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrderCheck(\"checkedOPD\")"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Checkbox",
      "parent": 50,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedIPD"
        },
        "label": {
          "type": "value",
          "value": "IPD"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrderCheck(\"checkedIPD\")"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "label",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "Division/Ward"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "style": {
          "type": "code",
          "value": "{width: \"30px\", marginLeft: \"1rem\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "code",
          "value": "props.StartDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "code",
          "value": "props.EndDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "inline four wide field"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"auto\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormGroup",
      "parent": 71,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormField",
      "parent": 76,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 76,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Button",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ใบจัดยา"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Button",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ใบเตรียมจัดส่งยา"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginRight: \"-25px\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormField",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "code",
          "value": "props.OrderStatus"
        },
        "id": {
          "type": "value",
          "value": "cb-medOrderStatus"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "FormField",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "code",
          "value": "props.OrderType"
        },
        "id": {
          "type": "value",
          "value": "cb-medOrderType"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Checkbox",
      "parent": 89,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedContinue"
        },
        "label": {
          "type": "value",
          "value": "Continue for Day dose"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrderCheck(\"checkedContinue\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"167.5px\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "FormField",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "FormField",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "label",
      "parent": 91,
      "props": {
        "children": {
          "type": "value",
          "value": "Location"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "Dropdown",
      "parent": 92,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrder(\"selectedLocation\")"
        },
        "options": {
          "type": "code",
          "value": "props.divisionTypeDrugOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedLocation"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Dropdown",
      "parent": 95,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetDrugOrder(\"selectedDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDivision"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "FormField",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Button",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "btn-searchMedOrder"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.refillPlaned"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.showDrugOrderPendingList ? \"flex\" : \"none\" }"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\",minWidth: \"max-content\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "Order Status"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: 700, color: \"rgba(0,0,0,.87)\", fontSize: \".92857143em\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\",minWidth: \"max-content\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "label",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "Order Type"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: 700, color: \"rgba(0,0,0,.87)\", fontSize: \".92857143em\"}"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Icon",
      "parent": 112,
      "props": {
        "name": {
          "type": "value",
          "value": "undo"
        },
        "onClick": {
          "type": "code",
          "value": "props.onResetFilter(\"selectedDrugOrderType\")"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "div",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "Icon",
      "parent": 115,
      "props": {
        "name": {
          "type": "value",
          "value": "undo"
        },
        "onClick": {
          "type": "code",
          "value": "props.onResetFilter(\"selectedDrugOrderStatus\")"
        }
      },
      "seq": 116,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugOrderQueueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "height": "40vh",
    "patientSearch": "[Patient Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
