import React from "react";
import PropTypes from "prop-types";
import * as CONSTANT from "../../utils/constant";
import * as Common from "../common";
import * as Util from "../../utils";
import * as PTM from "../PTM";
import {
  DateTextBox,
  ModAuthen as ModDeleteAuthen,
  ModAuthen as ModCancelAuthen,
} from "../common";
import { Label, Form, Button, Modal } from "semantic-ui-react";
import moment from "moment";
import PureReactTable from "../common/PureReactTable";

const CardNurseNote = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [readOnly, setReadOnly] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [fromDate, setFromDate] = React.useState(
    Util.formatDate(moment().subtract(7, "days"))
  );
  const [toDate, setToDate] = React.useState(Util.formatDate(moment()));
  const [nurseNoteItemData, setNurseNoteItemData] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [nurseNoteData, setNurseNoteData] = React.useState({});

  const [hideBtnCancel, setHideBtnCancel] = React.useState(true);
  const [hideBtnConfirm, setHideBtnConfirm] = React.useState(true);

  const [openNurseNoteItem, setOpenNurseNoteItem] = React.useState(false);

  const [openModSuccess, setOpenModSuccess] = React.useState(false);
  const [openModDeleteAuthen, setOpenModDeleteAuthen] = React.useState(false);
  const [openModCancelAuthen, setOpenModCancelAuthen] = React.useState(false);
  const [openModSelectDoctorOrder, setOpenModSelectDoctorOrder] =
    React.useState(false);
  const [authenError, setAuthenError] = React.useState(null);

  const [encounter, setEncounter] = React.useState(null);

  const [nurseNoteAction, setNurseNoteAction] = React.useState(null);

  const authenDeleteRef = React.useRef();
  const authenCancelRef = React.useRef();
  const nurseNoteItemRef = React.useRef();

  React.useEffect(() => {
    if (!openNurseNoteItem && nurseNoteItemRef && nurseNoteItemRef.current) {
      nurseNoteItemRef.current.clear();
    }
  }, [openNurseNoteItem]);

  React.useEffect(() => {
    setAuthenError(null);
    if (authenCancelRef && authenCancelRef.current) {
      authenCancelRef.current.clear();
    }
  }, [openModCancelAuthen]);

  React.useEffect(() => {
    setAuthenError(null);
    if (authenDeleteRef && authenDeleteRef.current) {
      authenDeleteRef.current.clear();
    }
  }, [openModDeleteAuthen]);

  React.useEffect(() => {
    refresh({ encounterId: props.encounterId });
    setEncounter(props.encounterId);
  }, [props.encounterId]);

  React.useEffect(() => {
    setReadOnly(props.readOnly);
  }, [props.readOnly]);

  React.useEffect(() => {
    checkStatus({ dataStatus: nurseNoteData.status });
  }, [nurseNoteData.status]);

  const refresh = ({ encounterId } = {}) => {
    let encounter = encounterId
      ? encounterId
      : props.patientData.ENCOUNTER.encounter_id;
    getEncounterDetail({ encounterId: encounter });
    if (props.nurseNoteData?.id) {
      getNurseNoteItem({ nurseNote: props.nurseNoteData.id });
    } else {
      findNurseNoteIdByEN({ encounterId: encounter });
    }
  };

  const getNurseNoteItem = async ({ nurseNote } = {}) => {
    setIsLoading(true);
    const [data, error] = await props.controller.getNurseNoteItem({
      nurseNoteId: nurseNote ? nurseNote : nurseNoteData.id,
      fromDate,
      toDate,
    });
    setNurseNoteItemData(data.items);
    setIsLoading(false);
  };

  const getEncounterDetail = async ({ encounterId } = {}) => {
    setIsLoading(true);
    const [data, error] = await props.controller.getEncounterDetail({
      encounterId,
    });
    if (data.is_nurse_key_discharge_form_expired) {
      setReadOnly(true);
    }
    setIsLoading(false);
  };

  const findNurseNoteIdByEN = async ({ encounterId } = {}) => {
    setIsLoading(true);
    const [data, error] = await props.controller.getNurseNoteLatest({
      encounterId,
    });
    if (data) {
      setNurseNoteData(data);
      if (data.status) {
        setStatus(data.status);
      }
      if (data.id) {
        return getNurseNoteItem({ nurseNote: data.id });
      }
    }
    setIsLoading(false);
  };

  const checkStatus = ({ dataStatus } = {}) => {
    if (!dataStatus) {
      return;
    }
    if (STATUS[dataStatus] === STATUS_TYPE.DRAFTED) {
      setHideBtnConfirm(false);
      setHideBtnCancel(true);
    } else if (STATUS[dataStatus] === STATUS_TYPE.CLOSED) {
      setReadOnly(true);
    } else if (STATUS[dataStatus] === STATUS_TYPE.CONFIRMED) {
      setHideBtnConfirm(true);
      setHideBtnCancel(false);
    }
    if (
      props.isIPD &&
      !CHECKOUT_STATUS.includes(parseInt(props.roomItemStatus))
    ) {
      setHideBtnConfirm(true);
      setHideBtnCancel(true);
    }
  };

  const handleEditClick = (row) => {
    setOpenNurseNoteItem(true);
  };

  const handleSaveNurseNoteItem = () => {
    setOpenModSuccess(true);
    setOpenNurseNoteItem(false);
    findNurseNoteIdByEN({ encounterId: encounter });
  };

  const handleRemoveNurseNoteItem = async ({ username, password } = {}) => {
    setIsLoading(true);
    const [data, error] = await props.controller.deleteNurseNoteItem({
      username,
      password,
      csrf: props.django.csrf,
      nurseNoteItemId: selectedRow.id,
    });
    if (error) {
      setAuthenError(error);
    } else {
      setOpenModDeleteAuthen(false);
      getNurseNoteItem();
    }
    setIsLoading(false);
  };

  const handleCancelNurseNote = async ({ username, password } = {}) => {
    setIsLoading(true);
    const [data, error] = await props.controller.putNurseNote({
      username,
      password,
      csrf: props.django.csrf,
      nurseNoteId: nurseNoteData.id,
      status,
      action: nurseNoteAction,
      isCheifNurse: nurseNoteData.is_chief_nurse,
      encounter: encounter
        ? encounter
        : props.patientData.ENCOUNTER.encounter_id,
    });
    if (error) {
      setAuthenError(error);
    } else if (data) {
      setOpenModCancelAuthen(false);
      setOpenModSuccess(true);
      setNurseNoteData(data);
      if (data.status) {
        setStatus(data.status);
      }
    }
    setIsLoading(false);
  };

  const handleSelectDoctorOrder = ({ orderDetail = "" } = {}) => {
    setOpenModSelectDoctorOrder(false);
    setOpenNurseNoteItem(true);
    setSelectedRow({ id: null, plan: orderDetail });
  };

  let statusColor = "black";
  if (STATUS[status] === STATUS_TYPE.DRAFTED) {
    statusColor = "yellow";
  } else if (STATUS[status] === STATUS_TYPE.CONFIRMED) {
    statusColor = "green";
  } else if (STATUS[status] === STATUS_TYPE.CLOSED) {
    statusColor = "red";
  }

  let labelStatus = <Label color={statusColor}>{STATUS[status]}</Label>;

  return (
    <Common.CardLayout
      closeable={props.closeable}
      loading={isLoading}
      titleText="Nurse's Note"
      onClose={props.onClose}
      titleContent={labelStatus}
    >
      <ModCancelAuthen
        open={openModCancelAuthen}
        onDeny={() => setOpenModCancelAuthen(false)}
        onApprove={handleCancelNurseNote}
        loading={isLoading}
        ref={authenCancelRef}
        error={authenError}
      />
      <ModDeleteAuthen
        open={openModDeleteAuthen}
        onDeny={() => setOpenModDeleteAuthen(false)}
        error={authenError}
        onApprove={handleRemoveNurseNoteItem}
        loading={isLoading}
        ref={authenDeleteRef}
      />
      <Common.ModInfo
        open={openModSuccess}
        titleName="บันทึกสำเร็จ"
        titleColor="green"
        onApprove={() => setOpenModSuccess(false)}
      />
      <Modal open={openModSelectDoctorOrder} size="large">
        <PTM.CardSelectDoctorOrder
          controller={props.controller}
          patientData={props.patientData}
          toggleable={false}
          encounter={encounter}
          onClose={() => setOpenModSelectDoctorOrder(false)}
          onSelect={handleSelectDoctorOrder}
        />
      </Modal>
      <Modal
        open={openNurseNoteItem}
        closeOnDimmerClick
        onClose={() => {
          setOpenNurseNoteItem(false);
        }}
      >
        <PTM.SubNurseNoteItem
          ref={nurseNoteItemRef}
          isIPD={props.isIPD}
          onClose={() => {
            setOpenNurseNoteItem(false);
          }}
          nurseNoteId={nurseNoteData ? nurseNoteData.id : null}
          controller={props.controller}
          patientData={props.patientData}
          onSaved={handleSaveNurseNoteItem}
          django={props.django}
          workingShift={selectedRow ? selectedRow.working_shift : ""}
          plan={selectedRow ? selectedRow.plan : ""}
          goal={selectedRow ? selectedRow.goal : ""}
          diagnosis={selectedRow ? selectedRow.diagnosis : ""}
          nurseNoteItemId={selectedRow ? selectedRow.id : null}
          date={selectedRow ? selectedRow.formatted_date : null}
        />
      </Modal>
      <Form>
        <Form.Group inline className="rightAlign">
          <Form.Field width={4}>
            <Button
              color="yellow"
              fluid
              content="เลือกรายการคำสั่งแพทย์"
              disabled={readOnly}
              onClick={() => setOpenModSelectDoctorOrder(true)}
            />
          </Form.Field>
          <Form.Field width={3}>
            <DateTextBox
              testid="dtbStartDate"
              value={fromDate}
              onChange={(date) => setFromDate(date)}
            />
          </Form.Field>
          <Form.Field width={3}>
            <DateTextBox
              testid="dtbEndDate"
              value={toDate}
              onChange={(date) => setToDate(date)}
            />
          </Form.Field>
          <Form.Field width={2}>
            <Button
              color="blue"
              fluid
              content="ค้นหา"
              onClick={getNurseNoteItem}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <PureReactTable
        testid="multiTable"
        showPagination={false}
        className=""
        minRows={0}
        data={nurseNoteItemData}
        style={styles.nurseNoteTable}
        selectedClassName="blueSelectedRow"
        onSelect={(originalRow) => setSelectedRow(originalRow)}
        selectedRow={selectedRow}
        columns={[
          {
            Header: "เวร",
            accessor: "working_shift",
            minWidth: 90,
          },
          {
            Header: "วันที่และเวลาที่บันทึก",
            accessor: "formatted_date",
            minWidth: 140,
          },
          {
            Header: "ข้อมูล/อาการ/ปัญหา",
            accessor: "plan",
            minWidth: 160,
          },
          {
            Header: "การพยาบาล Note",
            accessor: "diagnosis",
            minWidth: 160,
          },
          {
            Header: "ผลการพยาบาล",
            accessor: "goal",
            minWidth: 160,
          },
          {
            Header: "Nurse",
            accessor: "nurse",
            minWidth: 135,
          },
          {
            Header: "Division",
            accessor: "division_code",
            minWidth: 130,
          },
          {
            Header: "",
            accessor: "action",
            minWidth: 90,
            show: !readOnly,
            Cell: (row) => {
              return (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    color="yellow"
                    icon="pencil"
                    size="mini"
                    onClick={() => {
                      handleEditClick(row);
                    }}
                  />
                  <Button
                    color="red"
                    icon="trash"
                    size="mini"
                    onClick={() => {
                      setOpenModDeleteAuthen(true);
                    }}
                  />
                </div>
              );
            },
          },
        ]}
      />
      <br />
      <Form>
        <Form.Group inline>
          <Form.Field width={1}>
            <Button
              disabled={readOnly}
              testid="btnAdd"
              circular
              color="yellow"
              icon="plus"
              onClick={handleSelectDoctorOrder}
            />
          </Form.Field>
          <Form.Field width={9} />
          <Form.Field width={5} className="rowReverse">
            <Button
              style={{ display: hideBtnConfirm || readOnly ? "none" : null }}
              testid="btnConfirm"
              color="olive"
              content="ยืนยันเอกสาร"
              onClick={() => {
                setNurseNoteAction("CONFIRM");
                setOpenModCancelAuthen(true);
              }}
            />
            <Button
              style={{ display: hideBtnCancel || readOnly ? "none" : null }}
              testid="btnCancel"
              color="red"
              content="ยกเลิกยืนยันเอกสาร"
              onClick={() => {
                setNurseNoteAction("CANCEL_CONFIRM");
                setOpenModCancelAuthen(true);
              }}
            />
          </Form.Field>
          <Form.Field width={2}>
            <Button
              // no implementation in QML
              fluid
              testid="btnPrint"
              color="blue"
              content="พิมพ์"
              disabled={readOnly}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </Common.CardLayout>
  );
});

const CHECKOUT_STATUS = [
  CONSTANT.ROOM_ITEM_STATUS.CHECKED_OUT,
  CONSTANT.ROOM_ITEM_STATUS.CASHIER_REQUESTED,
  CONSTANT.ROOM_ITEM_STATUS.CASHIER_DISCHARGED,
  CONSTANT.ROOM_ITEM_STATUS.FINISHED,
];

const styles = {
  nurseNoteTable: {
    height: "300px",
  },
};

const STATUS_TYPE = {
  DRAFTED: "DRAFTED",
  CONFIRMED: "CONFIRMED",
  CLOSED: "CLOSED",
};

const STATUS = {
  "1": "DRAFTED",
  "2": "CONFIRMED",
  "3": "CLOSED",
};

CardNurseNote.defaultProps = {
  closeable: true,
  onClose: () => {},
  controller: {},
  patientData: {},
  readOnly: false,
  isIPD: false,
  roomItemStatus: null,
};

CardNurseNote.propTypes = {
  closeable: PropTypes.bool,
  onClose: PropTypes.func,
  controller: PropTypes.object,
  patientData: PropTypes.object,
  readOnly: PropTypes.bool,
  isIPD: PropTypes.bool,
  roomItemStatus: PropTypes.number,
};

export default React.memo(CardNurseNote);
