import axios from 'axios'
import { to } from '../../../utils'
import BaseService from  '../../services/BaseService'
import config from '../../../../config/config'
import { Response, Error } from '../common'
const HOST = `${config.API_HOST}`

export interface DivisionSerializer {
    id?: any,
    name?: any,
    display_seq?: any,
    is_active?: any,
    code?: any,
    name_en?: any,
    parent?: any,
    short_name?: any,
    type?: any,
    billing_div?: any,
    drug_div?: any,
    supply_div?: any,
    cost_center?: any,
    storage?: any,
    area?: any,
    location?: any,
    is_telemed?: any,
    background_color?: any,
    name_code?: any,
    type_label?: any,
}


/* ['generics.ListAPIView'] */
/* params: 'code', 'code_contains', 'name', 'name_code_contains', 'type', 'exclude_self', 'filter_clinic', 'for_opd_encounter', 'for_ipd_encounter', 'for_order_div', 'exclude_type', 'is_telemed', 'get_encounter_div' */
/* data:  */
export const DivisionList : 
{
  list:     (arg0:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/division/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

/* ['generics.RetrieveUpdateDestroyAPIView'] */
/* params:  */
/* data:  */
export const DivisionDetail : 
{
  retrieve: (arg0:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  update:   (arg0:
             { pk: any, 
                params?: any,
                data?: DivisionSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    (arg0:
             { pk: any, 
                params?: any,
                data?: DivisionSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/division/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  update: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${HOST}/apis/core/division/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${HOST}/apis/core/division/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface TPDDispenserWorkloadSerializer {
    id?: any,
    name?: any,
    display_seq?: any,
    is_active?: any,
    code?: any,
    name_en?: any,
    parent?: any,
    short_name?: any,
    type?: any,
    billing_div?: any,
    drug_div?: any,
    supply_div?: any,
    cost_center?: any,
    storage?: any,
    area?: any,
    location?: any,
    is_telemed?: any,
    background_color?: any,
    name_code?: any,
    worker_count?: any,
    waiting_count?: any,
    waiting_average?: any,
}


/* ['generics.ListAPIView'] */
/* params:  */
/* data:  */
export const TPDDispenserWorkloadView : 
{
  list:     (arg0:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/TPD/dispense-workload/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface ADMDivisionRoomSummarySerializer {
    id?: any,
    name?: any,
    display_seq?: any,
    is_active?: any,
    code?: any,
    name_en?: any,
    parent?: any,
    short_name?: any,
    type?: any,
    billing_div?: any,
    drug_div?: any,
    supply_div?: any,
    cost_center?: any,
    storage?: any,
    area?: any,
    location?: any,
    is_telemed?: any,
    background_color?: any,
    room_summary?: any,
}

/*----------------------------------------------------------------------------------------------------*/

export interface PRXDivisionSummarySerializer {
    id?: any,
    name?: any,
    display_seq?: any,
    is_active?: any,
    code?: any,
    name_en?: any,
    parent?: any,
    short_name?: any,
    type?: any,
    billing_div?: any,
    drug_div?: any,
    supply_div?: any,
    cost_center?: any,
    storage?: any,
    area?: any,
    location?: any,
    is_telemed?: any,
    background_color?: any,
    name_code?: any,
    type_label?: any,
}

/*----------------------------------------------------------------------------------------------------*/

