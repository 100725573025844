import React, { CSSProperties, useCallback, useMemo, useEffect } from "react";

// UX
import CardAppointmentUsageHistoryUX from "./CardAppointmentUsageHistoryUX";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import { DateTextBox } from "react-lib/apps/common";

// Interface
import {
  MasterOptionsType,
  RunSequence,
  State,
  PACKAGE_SEARCH_AUH,
  PRODUCT_SEARCH_AUH,
} from "./sequence/PackagePurchase";

// Types
type CardAppointmentUsageHistoryProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: RunSequence;
  PackagePurchaseSequence?: State["PackagePurchaseSequence"];
  // options
  masterOptions?: MasterOptionsType;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
};

// Const
const BUTTON_ACTIONS = {
  search: "SEARCH_USAGE_HISTORY",
};

const APPOINTMENT_STATUS_OPTIONS = [
  { key: 1, text: "แสดงทั้งหมด", value: "ALL" },
  { key: 2, text: "รับบริการแล้ว", value: "COMPLETED" },
  { key: 3, text: "นัดหมาย", value: "APPOINTMENT" },
];

const GridCenter = {
  display: "grid",
  placeContent: "center",
  textAlign: "center",
} as CSSProperties;

const CARD_APPOINTMENT_USAGE_HISTORY = "CardAppointmentUsageHistory";

const CardAppointmentUsageHistory = (
  props: CardAppointmentUsageHistoryProps
) => {
  // Callback Effect
  const handleSearch = useCallback(() => {
    props.runSequence({
      sequence: "PackagePurchase",
      action: "SEARCH_USAGE_HISTORY",
      card: CARD_APPOINTMENT_USAGE_HISTORY,
    });
  }, []);

  // Effect
  useEffect(() => {
    const initial = async () => {
      await props.setProp(
        "PackagePurchaseSequence.filterAppointmentHistory.status",
        "ALL"
      );

      const filter =
        props.PackagePurchaseSequence?.filterAppointmentHistory || {};

      // key length = 1, เข้ามาโดยยังไม่มีการ filter จะ default เฉพาะ field status
      if (!(Object.keys(filter).length <= 1)) {
        handleSearch();
      }
    };

    initial();

    return () => {
      handleCleanUp();
    };
  }, []);

  // Use Callback
  const handleSelectedItem = useCallback(
    (data: any) => async (value: any, key: any) => {
      props.setProp(
        `PackagePurchaseSequence.filterAppointmentHistory.${data.name}`,
        value || null
      );
    },
    [props.searchedItemListWithKey]
  );

  // Use Memo
  const filterAppointmentHistory = useMemo(() => {
    return props.PackagePurchaseSequence?.filterAppointmentHistory || {};
  }, [props.PackagePurchaseSequence?.filterAppointmentHistory]);

  const searchedItemListWithKey = useMemo(() => {
    const mapNameCode = (id: string) => {
      return {
        [id]: props.searchedItemListWithKey?.[id]?.map((item: any) => ({
          ...item,
          name_code: `[${item.code}] ${item.name}`,
        })),
      };
    };

    return {
      ...props.searchedItemListWithKey,
      ...mapNameCode(PACKAGE_SEARCH_AUH),
      ...mapNameCode(PRODUCT_SEARCH_AUH),
    };
  }, [props.searchedItemListWithKey]);

  const appointmentHistoryItems = useMemo(() => {
    return (props.PackagePurchaseSequence?.appointmentHistoryList || []).map(
      (item) => ({
        ...item,
        date: <div style={GridCenter}>{item.usage_datetime}</div>,
        name: item.package_name,
        service: item.product_name,
        code: <div style={GridCenter}>{item.product_code}</div>,
        qty: <div style={GridCenter}>{item.quantity}</div>,
        status: (
          <div
            style={{
              ...GridCenter,
              color: item.status === "COMPLETED" ? "#1B9D2C" : "",
            }}
          >
            {item.status_label}
          </div>
        ),
      })
    );
  }, [props.PackagePurchaseSequence?.appointmentHistoryList]);

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    let value = typeof data.value === "undefined" ? data.checked : data.value;

    const detail = {
      ...(props.PackagePurchaseSequence?.filterAppointmentHistory || {}),
    };

    (detail as any)[data.name] = value;

    props.setProp(`PackagePurchaseSequence.filterAppointmentHistory`, {
      ...detail,
    });
  };

  const handleClear = () => {
    handleCleanUp();
  };

  const handleChangeDate = (name: string) => (value: string) => {
    handleChangeValue(null, { name, value });
  };

  const handleCleanUp = async () => {
    await props.setProp(`searchedItemListWithKey.${PACKAGE_SEARCH_AUH}`, []);

    await props.setProp(`searchedItemListWithKey.${PACKAGE_SEARCH_AUH}`, []);

    await props.setProp("PackagePurchaseSequence.filterAppointmentHistory", {
      status: "ALL",
    });

    props.setProp("PackagePurchaseSequence.appointmentHistoryList", []);
  };

  console.log("CardAppointmentUsageHistoryUX", props);

  return (
    <div>
      <CardAppointmentUsageHistoryUX
        // data
        appointmentHistoryItems={appointmentHistoryItems}
        filterAppointmentHistory={filterAppointmentHistory}
        // options
        packageServiceTypeOptions={props.masterOptions?.packageServiceType}
        appointmentStatusOptions={APPOINTMENT_STATUS_OPTIONS}
        // callback
        onChangeValue={handleChangeValue}
        onClear={handleClear}
        // Element
        SearchBoxPackageName={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="Package"
            id="AUH"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            active={true}
            // Select
            searchedItemListWithKey={searchedItemListWithKey}
            selectedItem={filterAppointmentHistory.package || null}
            setSelectedItem={handleSelectedItem({
              name: "package",
              type: "Package",
            })}
          />
        }
        SearchBoxProductName={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="ProductAll"
            id="AUH"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            // Select
            searchedItemListWithKey={searchedItemListWithKey}
            selectedItem={filterAppointmentHistory.product || null}
            setSelectedItem={handleSelectedItem({
              name: "product",
              type: "ProductAll",
            })}
          />
        }
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={`${CARD_APPOINTMENT_USAGE_HISTORY}_${BUTTON_ACTIONS.search}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_APPOINTMENT_USAGE_HISTORY}_${BUTTON_ACTIONS.search}`
              ]
            }
            // config
            color={"blue"}
            name={BUTTON_ACTIONS.search}
            size="medium"
            title="ค้นหา"
          />
        }
        StartDate={
          <DateTextBox
            value={
              props.PackagePurchaseSequence?.filterAppointmentHistory?.startDate
            }
            onChange={handleChangeDate("startDate")}
          />
        }
        EndDate={
          <DateTextBox
            value={
              props.PackagePurchaseSequence?.filterAppointmentHistory?.endDate
            }
            onChange={handleChangeDate("endDate")}
          />
        }
      />
    </div>
  );
};

export default React.memo(CardAppointmentUsageHistory);
