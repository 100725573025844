import React from "react";
import PropTypes from "prop-types";
import CardLayout from "../common/CardLayout";
import PureReactTable from "../../component-js/common/PureReactTable";

const style = {
  gridCustom: { height: 350 },
};

const createColumns = [
  { Header: "Created date", accessor: "created", width: 300 },
  { Header: "Edit user", accessor: "editor_full_name", width: 300 },
  { Header: "Status", accessor: "status_label", width: 265 },
];

const editColumns = [
  { Header: "Edit date", accessor: "edited", width: 400 },
  { Header: "Edit user", accessor: "name", width: 465 },
];

const CardANSLog = (props) => {
  return (
    <CardLayout
      titleText={props.titleCard}
      headerColor="blue"
      closeable={props.closeable}
      toggleable={props.toggleable}
      onClose={props.hideCallback}
    >
      {props.createDetail && (
        <PureReactTable
          data={props.dataList}
          style={style.gridCustom}
          columns={createColumns}
          showPagination={false}
          pageSize={10}
          manual
        />
      )}

      {props.editDetail && (
        <PureReactTable
          data={props.dataList}
          style={style.gridCustom}
          columns={editColumns}
          showPagination={false}
          pageSize={10}
          manual
        />
      )}
    </CardLayout>
  );
};

CardANSLog.defaultProps = {
  titleCard: "Document Log",
  hideCallback: () => {},

  closeable: true,
  toggleable: true,
  createDetail: false,
  editDetail: false,

  dataList: [],
};

CardANSLog.propTypes = {
  titleCard: PropTypes.string,
  hideCallback: PropTypes.func,

  closeable: PropTypes.bool,
  toggleable: PropTypes.bool,
  createDetail: PropTypes.bool,
  editDetail: PropTypes.bool,

  dataList: PropTypes.array,
};

export default React.memo(CardANSLog);
