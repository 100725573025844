import * as DoctorNoteOrderI from "./DoctorNoteOrder";
import * as SetProgressCycleI from "./SetProgressCycle";
import * as OrderTreatmentI from "./OrderTreatment";
import * as DoctorPendingTask from "./DoctorPendingTask"
import * as TreatmentResultI from"./TreatmentResult";

const SequencePattern: {
  [name: string]: { [flavor: string]: { [func: string]: any } };
} = {
  SetProgressCycle: {
    default: {
      START: SetProgressCycleI.RefreshProgressCycle,
      AddOrEdit: SetProgressCycleI.AddOrEdit,
    },
  },
  OrderTreatment: {
    default: {
      START: OrderTreatmentI.GetMaster,
      SearchAndAdd: OrderTreatmentI.SearchAndAdd,
    },
  },
  DoctorNoteOrder: {
    default: {
      START: DoctorNoteOrderI.InitData,
      AddOrEdit: DoctorNoteOrderI.AddOrEdit,
    },
  },
  DoctorPendingTask: {
    default: {
      START: DoctorPendingTask.START,
      SearchAndEdit: DoctorPendingTask.SearchAndEdit
    }
  },
  TreatmentResult: {
    default : {
      START : TreatmentResultI.Start,
      SaveTreatmentResult:TreatmentResultI.SaveTreatmentResult
    }
  }
};

export default SequencePattern;
