import moment from "moment";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";

const FormStatList = (props: any) => {
  return {
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 14,
    },
    pageOrientation: "landscape",
    pageMargins: [15, 100, 15, 35],
    pageSize: "A4",
    styles: {
      fieldHeader: {
        fontSize: 22,
        bold: true,
        alignment: "center",
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
      tableData: {},
      miniFieldKey: {
        fontSize: 12.5,
        bold: true,
      },
      miniFieldValue: {
        fontSize: 12.5,
      },
    },
    header: {
      margin: [20, 40, 20, 0],
      stack: [
        {
          absolutePosition: { x: 17.5, y: 15 },
          image: "logochula",
          width: 140,
        },
        {
          alignment: "center",
          stack: [
            {
              text: "รายงานสถิติผู้ป่วยนอก/ใน แยกตามประเภทผู้ป่วย โรค ทันตแพทย์/แพทย์เจ้าของไข้",
              style: "fieldHeader",
            },
            {
              marginTop: -2.5,
              text: `วันที่ ${props.start_date || ""} - ${
                props.end_date || ""
              }`,
              style: "fieldHeader",
            },
          ],
        },
        {
          absolutePosition: { x: 632.5, y: 7 },
          stack: [
            {
              marginRight: 12.5,
              text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
              style: "miniFieldValue",
              alignment: "right",
            },
            {
              margin: [0, -4, 12.5, 0],
              text: [
                { text: "ที่อยู่", style: "miniFieldKey" },
                { text: " : ", style: "miniFieldValue" },
                { text: "34 ถนนอังรีดูนังต์ แขวงวังใหม่ เขตประทุมวัน" },
              ],
              alignment: "right",
            },
            {
              margin: [0, -4, 12.5, 0],
              text: "กรุงเทพมหานคร 10330 โทร 0 2218 8635",
              style: "miniFieldValue",
              alignment: "right",
            },
          ],
        },
      ],
    },
    content: [
      {
        // style: "table",
        table: {
          headerRows: 1,
          widths: ["4.5%", "23.5%", "17.5%", "17.5%", "28.5%", "9%"],
          body: [
            [
              {
                text: "ลำดับ",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "แพทย์เจ้าของไข้",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "ประเภทผู้ป่วย",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "ประเภท Visit",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "Diagnosis",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "จำนวน",
                style: "tableHeader",
                alignment: "center",
              },
            ],
            ...(props.items || []).map((item: any, index: number) => [
              {
                text: index + 1,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.doctor_name,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.patient_case_name,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.encounter_general_type,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.icd10_name,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.encounter_count,
                style: "tableData",
                alignment: "center",
              },
            ]),
          ],
        },
        layout: {
          vLineWidth: function (i: number, node: any) {
            return 0.25;
          },
          hLineWidth: function (i: number, node: any) {
            return 0.25;
          },
          paddingBottom: function (i: number, node: any) {
            return i === 0 ? 1 : 12;
          },
          paddingTop: function (i: number, node: any) {
            return i === 0 ? 1 : 12;
          },
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        margin: [12.5, 0, 15, 0],
        columns: [
          {
            stack: [
              {
                text: [
                  { text: "วันเวลาที่พิมพ์ ", style: "miniFieldKey" },
                  {
                    text: `${formatDate(moment())} [${moment().format(
                      "HH:mm"
                    )}]`,
                    style: "miniFieldValue",
                  },
                ],
              },
              {
                text: `ผู้ออกรายงาน : ${props.staff}`,
                style: "miniFieldValue",
                marginTop: -2,
              },
            ],
          },
          {
            margin: [0, 5, 5, 0],
            text: `หน้าที่ ${currentPage}/${pageCount}`,
            alignment: "right",
          },
        ],
      };
    },
    images: {
      logochula: origin + "/static/images/logochula_dent.jpg",
    },
  };
};

export default FormStatList;
