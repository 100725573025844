import React, {
  MutableRefObject,
  useRef,
  useState,
  useEffect,
  useMemo,
} from "react";
import CardQueueWard from "react-lib/apps/TPD/CardQueueWard";
import CardMedAdmin from "react-lib/apps/TPD/CardMedAdmin";
import SubDrugOrderStatusAlert from "../TPD/SubDrugOrderStatusAlert";
import { Modal } from "semantic-ui-react";
import ModDrugLabInteraction from "./ModDrugLabInteraction";
import ModDrugDisease from "./ModDrugDisease";
import ModDrugInteraction from "./ModDrugInteraction";

const MedAdminQueue = (props: any) => {
  const [selectedQueue, setSelectedQueue] = useState<{
    emr: number;
    patient: number;
  } | null>(null);

  const barcodeRef = useRef() as MutableRefObject<any>;

  useEffect(() => {
    handleEvent({ message: "MainComponentDidMount", params: {} });
  }, []);

  useEffect(() => {
    if (selectedQueue?.emr && selectedQueue.patient) {
      handleEvent({
        message: "MainComponentDidMount",
        params: {
          patientId: selectedQueue.patient,
        },
      });
    }
  }, [selectedQueue]);

  const setEvent = (message: string, params: any) => {
    return handleEvent({ message, params });
  };

  const handleEvent = (event: any) => {
    if (event.params?.questionList) {
      event.params.question = event.params.questionList;
    }

    return props.onEvent({
      ...event,
      message: `MedAdmin${event.message[0].toUpperCase()}${event.message.slice(
        1
      )}`,
    });
  };

  const controller = useMemo(() => {
    return {
      patientPanelController: {
        ...props.patientPanelController,
        getAdverseReactionList: (params: any) =>
          props.onEvent({
            message: "getAdverseReactionList",
            params: { ...params, byPatientId: true },
          }),
        getPatientInfo: (encounterId: any, patientId: any) =>
          setEvent("getPatientInfo", { encounterId, patientId }),
      },
      userManager: {
        getUserTokenize: props.proxyController.getUserTokenize,
      },
      coreManager: {
        getClinicalTermSet: (params: any) =>
          setEvent("getClinicalTermSet", params),
        getDivision: props.proxyController.getDivision,
      },
      tpdManager: {
        putMedAdminRecord: (medicationRecordId: any, data: any) =>
          setEvent("putMedAdminRecord", { medicationRecordId, data }),
        postCreateMedAdminRecord: (params: any) =>
          setEvent("postCreateMedAdminRecord", params),
        putUpdateMedAdminRecord: (medicationRecordId: any, data: any) =>
          setEvent("putUpdateMedAdminRecord", { medicationRecordId, data }),
        postMedAdminRecord: (params: any) =>
          setEvent("postMedAdminRecord", params),
        putDrugOrderItemIdAdminDivision: (drugOrderItemId: any, data: any) =>
          setEvent("putDrugOrderItemIdAdminDivision", {
            drugOrderItemId,
            data,
          }),
        getListMedAdminRecord: (params: any) =>
          setEvent("getListMedAdminRecord", params),
        getPrintMedRecord: (params: any) =>
          setEvent("getPrintMedRecord", params),
      },
    };
  }, [props.patientPanelController, props.proxyController]);

  const django = useMemo(() => {
    return {
      ...props.django,
      division: {
        id: props.django.division,
      },
    };
  }, [props.django]);

  const divisionOptions = useMemo(() => {
    if (props.masterData?.division) {
      let division = props.masterData?.division.filter(
        (item: any) =>
          item.type_label === "หน่วยตรวจ" || item.type_label === "หอผู้ป่วย"
      );
      return division;
    }
  }, [props.masterData?.division]);

  // console.log("AdminQueue", props, django);

  return (
    <div style={{ marginTop: "10px" }}>
      {selectedQueue && (
        <div
          style={{
            margin: "0 0 0 5px",
            cursor: "pointer",
          }}
          onClick={() => setSelectedQueue(null)}
        >
          &lt;&lt;&lt;
        </div>
      )}
      <div style={{ overflow: "auto", height: "88vh" }}>
        {!selectedQueue && (
          <CardQueueWard
            barcodeHNRef={barcodeRef}
            patientInfo={props.patientInfo}
            onEvent={handleEvent}
            originalOnEvent={props.onEvent} // call funtion in MainHISController
            medicationRecord={props.medicationRecord}
            django={django}
            division={props.division}
            // wardTypeList={props.wardTypeList}
            wardTypeList={divisionOptions}
            controller={controller}
            tabCurrentIndex={props.tabCurrentIndex}
            onSelectRow={setSelectedQueue}
            tableHeight={"500px"}
            // Element
            SubDrugOrderStatusAlert={SubDrugOrderStatusAlert}
            // config
            checkEditablePreAdmin={true}
            checkDrugAdminByCanAdmin={true}
            preAdminNarcotic={true}
            drugDetailByLabel={true}
          />
        )}
        {selectedQueue?.emr && selectedQueue?.patient && (
          <CardMedAdmin
            emrId={selectedQueue?.emr}
            controller={controller}
            django={django}
            medRecordSummary={props.medRecordSummary}
            division={props.division}
            patientInfo={props.patientInfo}
            tableHeight={"500px"}
            // Element
            SubDrugOrderStatusAlert={SubDrugOrderStatusAlert}
            // callback

            originalOnEvent={props.onEvent} // call funtion in MainHISController
            onEvent={handleEvent}
          />
        )}
      </div>

      {/* drug-lab interaction */}
      <Modal
        open={props.modDrugLabInteraction?.open}
        closeOnDimmerClick={false}
      >
        <ModDrugLabInteraction
          // main function
          runSequence={props.runSequence}
          setProp={props.setProp}
          onEvent={props.onEvent}
          // data
          modDrugLabInteraction={props.modDrugLabInteraction}
          currentDoctor={props.currentDoctor}
        />
      </Modal>

      {/* drug-disease */}
      <Modal open={props.modDrugDisease?.open} closeOnDimmerClick={false}>
        <ModDrugDisease
          // main function
          runSequence={props.runSequence}
          setProp={props.setProp}
          onEvent={props.onEvent}
          // data
          modDrugDisease={props.modDrugDisease}
          currentDoctor={props.currentDoctor}
        />
      </Modal>

      {/* drug-interaction */}
      <Modal open={props.modDrugInteraction?.open} closeOnDimmerClick={false}>
        <ModDrugInteraction
          // main function
          runSequence={props.runSequence}
          setProp={props.setProp}
          onEvent={props.onEvent}
          // data
          modDrugInteraction={props.modDrugInteraction}
          currentDoctor={props.currentDoctor}
        />
      </Modal>
    </div>
  );
};

export default MedAdminQueue;
