// NPM: MAIN
import React, { useEffect, useState, useMemo, useRef } from "react"
import {
  Form,
  Button,
  Input,
  Segment,
  Checkbox,
  Label,
  Icon,
  Popup,
  Dimmer,
  Loader,
  Dropdown
} from "semantic-ui-react"
import _ from "../../compat/lodashplus"
import moment from "moment"
// CARD
import { DateTextBox } from "../common"
import SemanticTable from "./SemanticTable"
import TimeField from "react-simple-timefield";

//Mod
import ModInfo from "../common/ModInfo"

//Constant
import * as Util from "../../utils";
import {
  PopupConfig,
  CardMedAdminQueueWardStateType,
  ModDescriptionType
} from "./CardPreAdminQueueWard"
import { DisplayDrugDetail, FilterMedRecordType, TYPE_COLOR } from "./CardQueueWard";

// STYLE
import "./CardMedAdmin.scss"

const currentTime = () => Util.formatDatetime(moment()).split(" ")[1]

const initialFilterMedRecord: FilterMedRecordType = {
  ward: null,
  date: Util.formatDate(moment()),
  time: currentTime(),
  checkedTime: false,
  division: " ",
  hn: null,
}

const initialModDescription: ModDescriptionType = {
  textContent: null,
  color: "",
  open: false,
  title: ""
}

export const CardReviewQueueWardInitial: CardMedAdminQueueWardStateType = {
  patientInfo: null,
  medicationRecord: null,
  onEvent: () => null,
  django: null,
  division: [],
  wardTypeList: [],
  controller: null,
  filterMedRecord: initialFilterMedRecord,
  barcodeHNRef: {
    current: null
  },
  tabCurrentIndex: 2,
  onSetFilter: () => null
}

const CardReviewQueueWard: React.FC<CardMedAdminQueueWardStateType> = (props) => {
  //* Show loading icon when fetch api
  const [isLoading, setIsLoading] = useState(false)

  //* Show Mod
  const [openModDescription, setOpenModDescription] = useState(initialModDescription)
  const [adminRemarkList, setAdminRemarkList] = useState([])
  const isMounted = useRef(true)

  useEffect(() => {
    onFetchAdminRemarkList()
  }, [])

  useEffect(() => {
    if (isMounted.current) {
      if (props.django && props.filterMedRecord.ward) {
        onFetchTableData(props.django)
        isMounted.current = false
      }
    }
  }, [props.django, props.filterMedRecord.ward])

   // Component
  const SubDrugOrderStatusAlert = useMemo(() => {
    return props.SubDrugOrderStatusAlert;
  }, [props.SubDrugOrderStatusAlert]);

  const onFetchTableData = async (django: any) => {
    setIsLoading(true)
    await getListMedAdminRecord(django)
    setIsLoading(false)
  }

  const onFetchAdminRemarkList = async () => {
    const [res] = await getClinicalTermSet({ questionList: ["ADMIN_REMARK"] })
    if (res?.ADMIN_REMARK) {
      setAdminRemarkList(res.ADMIN_REMARK.items)
    }
  }

  // ---------------------------- FETCH API, method: GET ---------------------
  const getListMedAdminRecord = async (django: any, setParams: any = {}) => {
    const { division, checkedTime, time, ward } = props.filterMedRecord
    const hn = props.barcodeHNRef.current.value

    if (typeof division !== "string") {
      setParams.admin_division = division
    }
    if (checkedTime) {
      setParams.time = time
    }
    if (hn) {
      setParams.hn = hn
    }
    if (typeof ward !== "string") {
      setParams.ward = ward
    }

    return await props.onEvent({
      message: "getListMedAdminRecord",
      params: {
        data: {
          mode: "REVIEW",
          date: props.filterMedRecord.date,
          ...setParams
        },
        tabCurrentIndex: 2
      }
    })
  }

  const getClinicalTermSet = async (params) => {
    return props.onEvent({
      message: "getClinicalTermSet",
      params
    })
  }
  // ---------------------------- END FETCH API -----------------------------

  const createPopup = (
    textContent: string,
    textLabel: string | JSX.Element,
    config: PopupConfig) => {

    return <Popup
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "offsetParent",
        },
      }}
      offset={config.offset}
      trigger={
        typeof textLabel === "string" ?
          <Label
            size="tiny"
            circular
            style={{ cursor: "pointer" }}
            color={config.color}
          >
            {textLabel}
          </Label> : textLabel
      }
      wide
      inverted={config.inverted}
      position={config.position || "bottom left"}
      content={
        <div
          dangerouslySetInnerHTML={{
            __html: textContent
          }}
        />
      }
    />
  }

  const adminTimeStatus = (value) => {
    let style = {
      backgroundColor: 'gray',
      cursor: ''
    }
    let innerHTML = ""

    if (!value) {
      return { style, innerHTML }
    }
    if (value.is_editable) {
      style.backgroundColor = 'lightyellow'
    }

    if (value.is_omitted) {
      style.backgroundColor = '#ff8383'
    }
    if (style.backgroundColor !== 'gray') {
      value.is_editable = true
      // style.cursor = 'pointer'
    }
    innerHTML = value.summary || '&nbsp;';

    return { style, innerHTML }
  }

  const handleOnSelectWard = (ev: any, type) => {
    props.onSetFilter("ward", type.value)
  }

  //* Handle click order for redirect to main
  const handleGetProps = (() => {
    return {
      onDoubleClick: (ev: any, row: any) => {
        if (props.onSelectRow) {
          return props.onSelectRow({ emr: row.emr, patient: row.patient });
        }
        window.location.href = `?app=MainMedAdmin&patient_id=${row.patient}&emr=${row.emr}`;
      },
      style: {
        cursor: "pointer"
      }
    }
  })

  const createMedicationDoseLabel = (drug_info, isSolvent = false) => {
    return (
      <Form.Field
        width={5}
        style={{
          justifyContent: "flex-end",
          display: "grid",
          marginRight: isSolvent ? "1.9rem" : "",
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {/* TODO เรียกใช้ Component แบบนี้เพราะ จะได้ไม่กระทบของเดิม CNMI และเรียกใช้ Component ได้โดยตรงจาก HISV3 */}
          {SubDrugOrderStatusAlert ? (
            <SubDrugOrderStatusAlert
              item={{ ...drug_info, claim_payload: null }}
              isFemale={true}
              onEvent={props.originalOnEvent}
              targetStateOrder={"medRecordSummary"}
            />
          ) : (
            <>
              {drug_info.alerts["I"] && (
                <Label
                  circular
                  size="tiny"
                  color="orange"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpenModDescription({
                      open: true,
                      title: `แจ้งเตือน !!! Drug Interaction`,
                      textContent: drug_info.alerts["I"].info_tpd,
                      color: "orange",
                    });
                  }}
                >
                  I
                </Label>
              )}
              {drug_info.pregcat &&
                drug_info.pregcat !== "NA" &&
                createPopup(drug_info.pregcat_description, "P", {
                  color: "pink",
                  offset: -14,
                })}
              {drug_info.alerts["H"] &&
                createPopup(drug_info.alerts["H"], "HAD", {
                  color: "red",
                })}
              {drug_info.alerts["L"] &&
                createPopup(drug_info.alerts["L"], "LASA", {
                  color: "yellow",
                })}
            </>
          )}
        </div>
        <p style={{ marginTop: "5px" }} />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: isSolvent ? "" : "0em 0.86666667em 0em 0em",
          }}
        >
          {drug_info.is_external && (
            <Label size="tiny" color="blue">
              External
            </Label>
          )}
        </div>
      </Form.Field>
    );
  }

  const createDrugLabel = (drug_info) => {
    return <Label
      circular
      size="tiny"
      style={{ cursor: "pointer" }}
      onClick={() => {
        setOpenModDescription({
          open: true,
          title: `ฉลากยาของ ${drug_info.changing_detail.split("\n")[0]}`,
          textContent: drug_info.label,
        })
      }}>
      <Icon name="info" style={{ margin: "0" }} />
    </Label>
  }

  const displayDrugName = (drug_info) => {
    return drug_info.changing_detail?.split("\n")[0] || drug_info.name
  }

  const gridColumns = [
    {
      Header: "Room",
      columns: [
        {
          Header: "",
          width: 120,
          accessor: " ",
        },
      ],
      Cell: (row: any) => <b>{row.room_no}</b>,
    },
    {
      Header: "HN/AN",
      width: 100,
      Cell: (row: any) => (
        <Form>
          <Form.Field>
            <b>{row.hn}</b>
            <p>{row.encounter_number}</p>
          </Form.Field>
        </Form>
      ),
      columns: [
        {
          Header: "MEDICATION AND DOSAGE",
          accessor: "hn",
          colSpan: 2,
          Cell: (row) => {
            const { drug_info } = row
            const { solvent } = drug_info
            return drug_info ? (
              <Form>
                <Form.Group
                  inline
                  style={{ alignItems: "flex-start", marginBottom: "0px" }}
                >
                  <Form.Field width={2}>
                    {drug_info.type_name && (
                      <Label
                        color={TYPE_COLOR[drug_info.type_name] || undefined}
                        style={{ textTransform: "lowercase" }}
                      >
                        {drug_info.type_name.replace(/_/g, " ")}
                      </Label>
                    )}
                  </Form.Field>
                  <Form.Field width={9}>
                    <div>
                      <b>{displayDrugName(drug_info)}</b>
                      <DisplayDrugDetail
                        drugInfo={drug_info}
                        byLabel={props.drugDetailByLabel}
                      />
                    </div>
                  </Form.Field>
                  {createMedicationDoseLabel(drug_info)}
                </Form.Group>
                {solvent && (
                  <Form.Group>
                    <Form.Field width={2}></Form.Field>
                    <Form.Field width={9}>
                      <div>
                        <b>+ {displayDrugName(solvent)}</b>
                        <p style={{ margin: "0.2em" }}>
                          Conc. {solvent.conc} VOLUME: {solvent.volume} Rate:{" "}
                          {solvent.rate} Drip in {solvent.drip}
                        </p>
                      </div>
                    </Form.Field>
                    {createMedicationDoseLabel(solvent, true)}
                  </Form.Group>
                )}
                <Form.Group inline>
                  <Form.Field width={2}></Form.Field>
                  <Form.Field>
                    <Button
                      content="Detail"
                      color="orange"
                      size="tiny"
                      className="button-tiny"
                      disabled
                    />
                  </Form.Field>
                  <Form.Field>
                    <div>
                      {drug_info.admin_remark?.split("\n").map((str) => (
                        <div>{str}</div>
                      ))}
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form>
            ) : null;
          },

        },
      ],
      getProps: handleGetProps,

    },
    {
      Header: "ผู้ป่วย",
      accessor: "",
      width: 650,
      rowSpan: 1,
      colSpan: 2,
      Cell: (row: any) => <b>{row.patient_full_name}</b>,
      getProps: handleGetProps
    },
    {
      Header: "",
      accessor: "",
      columns: [
        {
          Header: "ออกฉลากยา",
          accessor: "",
          width: 100,
          Cell: (row) => {
            const { drug_info } = row

            return <div style={{
              display: "flex", justifyContent: "center",
            }}>
              <Form>
                <Form.Group>
                  <Form.Field>
                    {createDrugLabel(drug_info)}
                  </Form.Field>
                </Form.Group>
                {
                  drug_info.solvent &&
                  <Form.Group style={{
                    marginTop: "24px"
                  }}>
                    <Form.Field>
                      {createDrugLabel(drug_info.solvent)}
                    </Form.Field>
                  </Form.Group>
                }

              </Form>
            </div>
          }

        },
      ],
    },
    {
      Header: "อายุ",
      Cell: (row: any) => <b>{row.patient_age}</b>,
      columns: [
        {
          Header: "Std Time",
          accessor: "age",
          width: 70,
          Cell: (row) => {
            const { medication_record, drug_info } = row

            return drug_info.type_name === "CONTINUE" ? (
              _.map(medication_record, (item: any) =>
                <Form style={{
                  display: "grid", margin: "0 auto",
                  alignItems: "center"
                }}>
                  <Form.Group inline>
                    <Form.Field
                      width={15}
                      style={{ paddingRight: '0' }}>
                      <b>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.expected_admin_time?.replace(/:\w+$/i, "") || '&nbsp;'
                          }}
                        />
                      </b>
                    </Form.Field>
                    {
                      item.is_manual_time &&
                      <Form.Field width={1} style={{
                        position: "absolute",
                        right: "-15px"
                      }}>
                        {createPopup(
                          `<p>Edited: ${item.manual_user}</p>
                          <p>${item.manual_remark}</p>`,
                          <div
                            style={{
                              position: "absolute",
                              cursor: "pointer",
                              fontSize: "1.5em",
                              color: "red",
                              marginLeft: "3px"
                            }}> *</div>,
                          {
                            offset: 15,
                            inverted: true,
                            position: "bottom right"
                          }
                        )}
                      </Form.Field>
                    }
                  </Form.Group>
                </Form>

              )

            ) : null
          }
        },
        {
          Header: "Admin Time",
          accessor: "",
          width: 100,
          Cell: (row) => {
            const { medication_record } = row

            return _.map(medication_record, (item: any) => {
              return {
                Cell: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.admin_time?.replace(/:\w+$/i, "") || "&nbsp"
                    }}
                  />

                ),
                getProps: {
                  style: {
                    ...adminTimeStatus(item).style,
                    backgroundColor: "lightyellow",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "center"
                  }
                }
              }
            }
            )

          },
          getProps: (row) => ({
            style: {
              backgroundColor: !row.medication_record[0] ? "lightyellow" : "",

            }
          })

        },
        {
          Header: "Nurse",
          accessor: "",
          width: 250,
          Cell: (row) => {
            const { medication_record, drug_info } = row

            return _.map(medication_record, (item: any) => {

              const isEditable = item?.on_date_status === "ACTIVE" || drug_info.type_name !== "CONTINUE"
              const isReasonAdmin = item?.summary && !item?.is_omitted && item?.reason
              const adminStatus = adminTimeStatus(item)
              const statusText = item?.on_date_status === "HELD" ? "HOLD" : item?.on_date_status
              const reason = adminRemarkList?.find((list) => list.id === item?.reason)
              return {
                Cell: isEditable ? (<Form>
                  <Form.Group >
                    <Form.Field
                      width={15}
                      style={{ paddingRight: '0' }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: adminStatus.innerHTML
                        }}
                      />
                    </Form.Field>
                    {
                      isReasonAdmin &&
                      <Form.Field width={1} style={{
                        position: "absolute",
                        right: "5px"
                      }}>
                        {
                          createPopup(
                            `<p>${reason?.name}</p>`,
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                color: "red",
                                fontSize: "1.5em",
                                cursor: "pointer",
                              }}>
                              *</div>,
                            {
                              offset: 15,
                              inverted: true,
                              position: "bottom right"
                            }
                          )
                        }

                      </Form.Field>
                    }
                  </Form.Group>
                </Form>) :
                  <div>{statusText}</div>
                ,
                getProps: {
                  style: isEditable ?
                    {
                      ...adminStatus.style,
                      backgroundColor: isReasonAdmin ? "lightyellow" : adminStatus.style.backgroundColor
                    } : {
                      backgroundColor: item?.on_date_status === "OFF" ? "#474744" : "grey",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontWeight: "500",
                    }
                }
              }
            }
            )
          },
          getProps: (row) => ({

            style: {
              backgroundColor: !row.medication_record[0] ? "lightyellow" : "",
            }
          })
        },
      ],
      getProps: handleGetProps
    },
  ];
  const mapOptions = (option) => _.map(option, (item, index) => ({ key: index, text: item.name, value: item.id }))
  /**
   * option choose admin division
   */
  const adminDivisionOptions = useMemo(() => {
    return props.division ?
      [{ key: "", text: "All", value: " " },
      ...mapOptions(props.division)
      ] : []
  }, [props.division])

  const wardTypeOptions = useMemo(() => {
    if (props.wardTypeList) {
      return [{ key: "", text: "All", value: " " },
      ...mapOptions(props.wardTypeList)
      ]
    } else {
      return []
    }
  }, [props.wardTypeList, props.django])

  return (
    <>
      <Segment className="medication-admin-ward-table">
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        {/* Form display and filter queue-ward list*/}
        <Form>
          <Form.Group inline>
            <Form.Field >
              <label>Division/Ward</label>
            </Form.Field>
            <Form.Field width={3}>
              <Dropdown
                size="tiny"
                className="fluidDropdown"
                selection
                search
                value={props.filterMedRecord.ward}
                options={wardTypeOptions}
                onChange={handleOnSelectWard}
                style={{ marginRight: 14 }}
              />
            </Form.Field>

            <Form.Field >
              <label>วันที่</label>
            </Form.Field>
            <Form.Field >
              <DateTextBox
                value={props.filterMedRecord.date}
                onChange={(value) => props.onSetFilter("date", value)}
              />
            </Form.Field>
            <Form.Field
              style={{
                display: "flex",
                paddingRight: "5px"
              }}
            >
              <Checkbox
                checked={props.filterMedRecord.checkedTime}
                onChange={() =>
                  props.onSetFilter("checkedTime", !props.filterMedRecord.checkedTime)
                }
              />
            </Form.Field>

            <Form.Field width={2} >
              <label>เวลา</label>
              <TimeField
                input={<Input icon="clock outline" />}
                value={props.filterMedRecord.time}
                onChange={(ev, value) => {
                  props.onSetFilter("time", value)
                }}
              />
            </Form.Field>

            <Form.Field >
              <label>Admin Division</label>
            </Form.Field>
            <Form.Field width={3}>
              <Dropdown
                size="tiny"
                className="fluidDropdown"
                selection
                search
                value={props.filterMedRecord.division}
                options={adminDivisionOptions}
                onChange={(ev, input) => {
                  props.onSetFilter("division", input.value)
                }}
                style={{ marginRight: 14 }}
              />
            </Form.Field>

            <Form.Field >
              <Button color="blue" onClick={() =>
                onFetchTableData(props.django)
              }>ค้นหา
              </Button>
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field>
              <label>Barcode HN</label>
            </Form.Field>
            <Form.Field>
              <Input
                ref={(instance) => {
                  if (instance) {
                    if (props.barcodeHNRef.current) {
                      const value = props.barcodeHNRef.current.value

                      props.barcodeHNRef.current = instance.inputRef.current
                      props.barcodeHNRef.current.value = value
                    } else {
                      props.barcodeHNRef.current = instance.inputRef.current
                    }
                  }
                }}
              />
            </Form.Field>
          </Form.Group>
        </Form>

        {/* Table display queue-ward list */}
        <SemanticTable
          data={props.medicationRecord}
          columns={gridColumns as any[]}
          headerLevel={0}
          toggleChildBy="items"
          defaultToggle={true}
          pageSize={5}
          tableHeight={props.tableHeight}
        />

        <br />

        <ModInfo
          color={openModDescription.color || "blue"}
          titleName={openModDescription.title}
          closeOnDimmerClick
          open={openModDescription.open}
          onApprove={() => setOpenModDescription(initialModDescription)}
          onClose={() => setOpenModDescription(initialModDescription)}
        >
          <div style={{
            display: "grid",
            textAlign: "left"
          }}>
            {openModDescription.textContent?.split('\n').map((str, index) => <p key={index}>{str}</p>)}
          </div>
        </ModInfo>
      </Segment>
    </>
  )
}

CardReviewQueueWard.defaultProps = CardReviewQueueWardInitial

export default React.memo(CardReviewQueueWard)

