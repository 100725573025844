import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Icon,
  FormGroup,
  FormField,
  Label,
  Checkbox,
  Input,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardImagingOrderUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div>
          
          <div
            style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            
            <div
              style={{fontSize: "1.1rem", fontWeight: "bold", padding: "2.5px 0"}}>
              Imaging Reguest
            </div>
            <div>
              
              <Button
                color="green"
                onClick={props.onAddOrder}
                style={{position: "relative", display: "flex", padding: "0.65em 0.75em 0.45rem"}}>
                <div
                  style={{fontSize: "1.2rem"}}>
                  
                  <Icon
                    name="file text outline">
                  </Icon>
                </div>
                <label>
                  Add order
                </label>
                <div
                  style={{position: "absolute", fontSize: "0.45rem", left: "16.5px", top: "11px"}}>
                  
                  <Icon
                    name="plus">
                  </Icon>
                </div>
              </Button>
            </div>
          </div>
        </div>
        <div
          className="ui divider"
          style={{marginTop: "0.5rem"}}>
          
        </div>
        <div
          className="ui form">
          
          <Table
            data={props.imagingRequestList || []}
            getTrProps={props.onGetTrPropsRequest}
            headers="Order ID, Request date, Order status, Detail, Item status, Careprovider, Del, Edit"
            keys="order_id,request_date,order_status,summary, status,doctor_name,delete,edit"
            minRows={!!props.imagingItemDetail ? 7 : 11}
            showPagination={false}
            style={{height: !!props.imagingItemDetail ? "calc(100vh - 33rem)" : "calc(100vh - 10rem)"}}
            widths="120,120,120,,120,120,80,80">
          </Table>
        </div>
        <div
          className="ui form"
          style={{marginTop: "1.5rem", display: !!props.imagingItemDetail ? "": "none"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label>
                {!!props.imagingItemDetail?.id ? "แก้ไขรายการคำสั่ง Imaging": "รายการคำสั่ง Imaging"}
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{display: !!props.imagingItemDetail?"":"none"}}>
              <Label
                color="teal">
                {props.imagingItemDetail?.order_id || "-"}
              </Label>
            </FormField>
            <FormField
              style={{flex: 1}}>
            </FormField>
            <FormField>
              <Icon
                className="red large"
                link={true}
                name="close"
                onClick={props.onClose}>
              </Icon>
            </FormField>
          </FormGroup>
          <FormGroup
            className={props.readOnly ? "--readOnly" : ""}
            inline={true}
            style={{backgroundColor: "#e5f4ff", padding: "0.75rem"}}>
            <FormField
              inline={true}>
              <div
                style={{minWidth: "max-content"}}>
                
                <label>
                  Suspected Diagnosis
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={10}>
              <div
                style={{width: "100%", marginRight: "0.75rem"}}>
                {props.SearchBoxPrincipalDiag}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.imagingItemDetail?.extra?.out_time || false}
                disabled={props.readOnly || false}
                label="นอกเวลา"
                name="extra.out_time"
                onChange={props.onChangeValue}
                style={{minWidth: "max-content", marginRight: "0.75rem"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.imagingItemDetail?.is_emergency|| false}
                disabled={props.readOnly || false}
                label="Emergency"
                name="is_emergency"
                onChange={props.onChangeValue}
                style={{minWidth: "max-content", marginRight: "0.75rem"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.imagingItemDetail?.extra?.secret|| false}
                disabled={props.readOnly || false}
                label="ปกปิด"
                name="extra.secret"
                onChange={props.onChangeValue}
                style={{minWidth: "max-content", marginRight: "0.75rem"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.imagingItemDetail?.extra?.repeat_medication|| false}
                label="Repeat Medication"
                name="extra.repeat_medication"
                onChange={props.onChangeValue}
                readOnly={props.readOnly || false}
                style={{minWidth: "max-content", marginRight: "0.75rem"}}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                Imaging Item
              </label>
            </FormField>
            <FormField
              inline={true}
              width="16">
              <div
                style={{width: "100%"}}>
                {props.SearchBoxImagingItem}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{display: !!props.imagingItemDetail? "":"none"}}>
          
          <Table
            data={props.imagingItemList || []}
            getTrProps={props.onGetTrPropsItem}
            headers="No., Code, Imaging item, X-ray Group, QTY, Unit, Price/Unit, Total price, เบิกได้,เบิกไม่ได้,Detail, Del"
            keys="no, code, name,group_name,qty,unit_name,price_unit,price_total,price_claimable,price_non_claimable,summary,delete"
            minRows={4}
            showPagination={false}
            style={{height: "300px"}}
            widths="50,90,,120,90,90,90,90,90,90,,80">
          </Table>
        </div>
        <div
          className="ui form"
          style={{marginTop: "1.5rem", marginBottom: "3rem",display: !!props.imagingItemDetail? "":"none"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                แผนกที่สั่ง
              </label>
            </FormField>
            <FormField
              inline={true}
              width="6">
              <Dropdown
                fluid={true}
                icon="search"
                name="order_div"
                onChange={props.onChangeValue}
                options={props.divisionForOrderDivOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.imagingItemDetail?.order_div || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                ราคาทั้งหมด
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                readOnly={true}
                ref={(ref: any) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.summaryPrice?.total || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                เบิกได้
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                readOnly={true}
                ref={(ref: any) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.summaryPrice?.claimable || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                เบิกไม่ได้
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                readOnly={true}
                ref={(ref: any) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.summaryPrice?.non_claimable || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.ButtonConfirmOrder}
              </div>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}

CardImagingOrderUX.displayName = "CardImagingOrderUX";
export default React.memo(CardImagingOrderUX)

export const screenPropsDefault = {}

/* Date Time : Thu Jul 13 2023 02:40:10 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"0.5rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "Imaging Reguest"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\", fontWeight: \"bold\", padding: \"2.5px 0\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 7,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddOrder"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\", display: \"flex\", padding: \"0.65em 0.75em 0.45rem\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "Add order"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", fontSize: \"0.45rem\", left: \"16.5px\", top: \"11px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Icon",
      "parent": 11,
      "props": {
        "name": {
          "type": "value",
          "value": "plus"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Icon",
      "parent": 13,
      "props": {
        "name": {
          "type": "value",
          "value": "file text outline"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 15,
      "name": "Table",
      "parent": 4,
      "props": {
        "data": {
          "type": "code",
          "value": "props.imagingRequestList || []"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrPropsRequest"
        },
        "headers": {
          "type": "value",
          "value": "Order ID, Request date, Order status, Detail, Item status, Careprovider, Del, Edit"
        },
        "keys": {
          "type": "value",
          "value": "order_id,request_date,order_status,summary, status,doctor_name,delete,edit"
        },
        "minRows": {
          "type": "code",
          "value": "!!props.imagingItemDetail ? 7 : 11"
        },
        "pageSize": {
          "type": "code",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: !!props.imagingItemDetail ? \"calc(100vh - 33rem)\" : \"calc(100vh - 10rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": "120,120,120,,120,120,80,80"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1.5rem\", display: !!props.imagingItemDetail ? \"\": \"none\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormGroup",
      "parent": 16,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: !!props.imagingItemDetail?\"\":\"none\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "code",
          "value": "!!props.imagingItemDetail?.id ? \"แก้ไขรายการคำสั่ง Imaging\": \"รายการคำสั่ง Imaging\""
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Label",
      "parent": 19,
      "props": {
        "children": {
          "type": "code",
          "value": "props.imagingItemDetail?.order_id || \"-\""
        },
        "color": {
          "type": "value",
          "value": "teal"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormGroup",
      "parent": 16,
      "props": {
        "className": {
          "type": "code",
          "value": "props.readOnly ? \"--readOnly\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#e5f4ff\", padding: \"0.75rem\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "Suspected Diagnosis"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Checkbox",
      "parent": 25,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.imagingItemDetail?.extra?.out_time || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "label": {
          "type": "value",
          "value": "นอกเวลา"
        },
        "name": {
          "type": "value",
          "value": "extra.out_time"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginRight: \"0.75rem\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Checkbox",
      "parent": 26,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.imagingItemDetail?.is_emergency|| false"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "label": {
          "type": "value",
          "value": "Emergency"
        },
        "name": {
          "type": "value",
          "value": "is_emergency"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginRight: \"0.75rem\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Checkbox",
      "parent": 27,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.imagingItemDetail?.extra?.secret|| false"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "label": {
          "type": "value",
          "value": "ปกปิด"
        },
        "name": {
          "type": "value",
          "value": "extra.secret"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginRight: \"0.75rem\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Checkbox",
      "parent": 28,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.imagingItemDetail?.extra?.repeat_medication|| false"
        },
        "label": {
          "type": "value",
          "value": "Repeat Medication"
        },
        "name": {
          "type": "value",
          "value": "extra.repeat_medication"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "readOnly": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginRight: \"0.75rem\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBoxPrincipalDiag"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginRight: \"0.75rem\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormGroup",
      "parent": 16,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 37,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 37,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "16"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "Imaging Item"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBoxImagingItem"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: !!props.imagingItemDetail? \"\":\"none\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 48,
      "name": "Table",
      "parent": 47,
      "props": {
        "data": {
          "type": "code",
          "value": "props.imagingItemList || []"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrPropsItem"
        },
        "headers": {
          "type": "value",
          "value": "No., Code, Imaging item, X-ray Group, QTY, Unit, Price/Unit, Total price, เบิกได้,เบิกไม่ได้,Detail, Del"
        },
        "keys": {
          "type": "value",
          "value": "no, code, name,group_name,qty,unit_name,price_unit,price_total,price_claimable,price_non_claimable,summary,delete"
        },
        "minRows": {
          "type": "code",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\"}"
        },
        "widths": {
          "type": "value",
          "value": "50,90,,120,90,90,90,90,90,90,,80"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1.5rem\", marginBottom: \"3rem\",display: !!props.imagingItemDetail? \"\":\"none\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormGroup",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "6"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "FormField",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormField",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "label",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนกที่สั่ง"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "label",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคาทั้งหมด"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "label",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกได้"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกไม่ได้"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Input",
      "parent": 58,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref: any) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.summaryPrice?.non_claimable || \"\""
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Input",
      "parent": 56,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref: any) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.summaryPrice?.claimable || \"\""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "Input",
      "parent": 54,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref: any) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.summaryPrice?.total || \"\""
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Dropdown",
      "parent": 52,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "name": {
          "type": "value",
          "value": "order_div"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.divisionForOrderDivOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.imagingItemDetail?.order_div || \"\""
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonConfirmOrder"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 17,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 17,
      "props": {
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Icon",
      "parent": 71,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 72,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardImagingOrderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
