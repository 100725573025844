import WasmController from 'react-lib/frameworks/WasmController';

// APIS
import ARTransactionList from "issara-sdk/apis/ARTransactionList_apps_INF"

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "getSentClaimChoices", params: any }
  |  { message: "getChoicesAdmitSource", params: any }
  |  { message: "getChoicesPreDischargeType", params: any }
  |  { message: "getChoicesDischargeType", params: any }
  |  { message: "getChoicesDivision", params: any }
  |  { message: "getChoicesSentClaimStatus", params: any }
  |  { message: "createARTransaction", params: any }
  |  { message: "getARTransaction", params: any }
  |  { message: "getCoveragePayerSentClaimGroup", params: any }
  |  { message: "getBillTransactionIPD", params: any }
  |  { message: "getBillTransactionIPDWithId", params: any }
  |  { message: "updateBillTransactionIPDWithId", params: any }
  |  { message: "putBillTransactionUpdateStatus", params: any }
  |  { message: "putBillTransaction", params: any }
  |  { message: "getBillTransactionIPDSummary", params: any }
  |  { message: "getIPDDiagnosisSentClaim", params: any }
  |  { message: "getIPDProcedureSentClaim", params: any }
  |  { message: "getBillTransactionIPDItem", params: any }
  |  { message: "doGenerateSentClaimDataIPD", params: any }
  |  { message: "arTaskTrackingIPDGenerateSentClaim", params: any }
  |  { message: "arTaskTrackingIPDUpdateSentClaim", params: any }
  |  { message: "updateSentClaimDataIPDFromARId", params: any }
  |  { message: "generateSentClaimIPDFileFromARId", params: any }
  |  { message: "importResponseFileIPD", params: any }
  |  { message: "getSentClaimTransactionIPD", params: any }
  |  { message: "getEncounterFromARId", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const getSentClaimChoices: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, field = _b.field;
//            return __awaiter(_this, void 0, void 0, function () {
//                var dataList, params, infService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            dataList = [];
//                            params = {};
//                            if (field) {
//                                params.field = field;
//                            }
//                            infService = new INFService_1.default();
//                            return [4 /*yield*/, utils_1.to(infService.getSentClaimChoices(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            if (!error) {
//                                dataList = lodashplus_1.default.map(response.items, function (item) { return ({
//                                    id: item.id,
//                                    text: item.label ? item.label : item.value,
//                                    value: item.value
//                                }); });
//                            }
//                            return [2 /*return*/, [dataList, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.INF.SENT_CLAIM_CHOICE, { params: params })
//            .then(function (response) { return response.data; })
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/INF/sent-claim-choice/
}

export const getChoicesAdmitSource: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            return __generator(this, function (_a) {
//                switch (_a.label) {
//                    case 0: return [4 /*yield*/, this.getSentClaimChoices({ field: "admit_source" })];
//                    case 1: return [2 /*return*/, _a.sent()];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const getChoicesPreDischargeType: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            return __generator(this, function (_a) {
//                switch (_a.label) {
//                    case 0: return [4 /*yield*/, this.getSentClaimChoices({ field: "predischarge_condition" })];
//                    case 1: return [2 /*return*/, _a.sent()];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const getChoicesDischargeType: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            return __generator(this, function (_a) {
//                switch (_a.label) {
//                    case 0: return [4 /*yield*/, this.getSentClaimChoices({ field: "discharge_type" })];
//                    case 1: return [2 /*return*/, _a.sent()];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const getChoicesDivision: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            return __generator(this, function (_a) {
//                switch (_a.label) {
//                    case 0: return [4 /*yield*/, this.getSentClaimChoices({ field: "division" })];
//                    case 1: return [2 /*return*/, _a.sent()];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const getChoicesSentClaimStatus: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            return __generator(this, function (_a) {
//                switch (_a.label) {
//                    case 0: return [4 /*yield*/, this.getSentClaimChoices({ field: "status" })];
//                    case 1: return [2 /*return*/, _a.sent()];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const createARTransaction: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, startDate = _b.startDate, endDate = _b.endDate, coverageGroupId = _b.coverageGroupId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var data, infService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            data = {};
//                            // Convert date
//                            if (startDate) {
//                                data.start_date = utils_1.formatDateToYYYYMMDD(startDate);
//                            }
//                            if (endDate) {
//                                data.end_date = utils_1.formatDateToYYYYMMDD(endDate);
//                            }
//                            if (coverageGroupId) {
//                                data.coverage_payer_sent_claim_group = coverageGroupId;
//                            }
//                            infService = new INFService_1.default();
//                            return [4 /*yield*/, utils_1.to(infService.postCreateARTransaction(data, {}))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//
//----> Name mismatch between manager and service
}

export const getARTransaction: Handler = async (controller, params) => {
  return ARTransactionList.list({
    apiToken: controller.apiToken,
    params
  })

//function (_a) {
//            var _b = _a === void 0 ? {} : _a, invoiceDate = _b.invoiceDate, lotNo = _b.lotNo, fiscalYear = _b.fiscalYear, onlyContainFailed = _b.onlyContainFailed, groupType = _b.groupType, page = _b.page, pageSize = _b.pageSize;
//            return __awaiter(_this, void 0, void 0, function () {
//                var dataList, params, infService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            dataList = [];
//                            params = {};
//                            if (page) {
//                                params.page = page;
//                            }
//                            if (pageSize) {
//                                params.page_size = pageSize;
//                            }
//                            if (invoiceDate) {
//                                params.invoice_date = invoiceDate;
//                            }
//                            if (lotNo) {
//                                params.lot_no = lotNo;
//                            }
//                            if (fiscalYear) {
//                                params.fiscal_year = fiscalYear;
//                            }
//                            if (onlyContainFailed) {
//                                params.only_contain_failed = onlyContainFailed;
//                            }
//                            if (groupType) {
//                                params.group_type = groupType;
//                            }
//                            infService = new INFService_1.default();
//                            return [4 /*yield*/, utils_1.to(infService.getARTransaction(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.INF.AR_TRANSACTION_LIST, { params: params })
//            .then(function (response) { return response.data; })
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/INF/ar-transaction/
}

export const getCoveragePayerSentClaimGroup: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, groupType = _b.groupType;
//            return __awaiter(_this, void 0, void 0, function () {
//                var dataList, params, infService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            dataList = [];
//                            params = {};
//                            if (groupType) {
//                                params.group_type = groupType;
//                            }
//                            infService = new INFService_1.default();
//                            return [4 /*yield*/, utils_1.to(infService.getCoveragePayerSentClaimGroup(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            if (response) {
//                                dataList = response.items;
//                            }
//                            return [2 /*return*/, [dataList, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.INF.GET_COVERAGE_PAYER_SENT_CLAIM_GROUP, { params: params })
//            .then(function (response) { return response.data; })
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/INF/coverage-payer-sent-claim-group/
}

export const getBillTransactionIPD: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, coverageGroupId = _b.coverageGroupId, startDate = _b.startDate, endDate = _b.endDate, transactionId = _b.transactionId, status = _b.status, encounter = _b.encounter, page = _b.page, pageSize = _b.pageSize;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, infService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (page) {
//                                params.page = page;
//                            }
//                            if (pageSize) {
//                                params.page_size = pageSize;
//                            }
//                            if (coverageGroupId) {
//                                params.coverage_payer_sent_claim_group = coverageGroupId;
//                            }
//                            if (startDate) {
//                                params.start_date = startDate;
//                            }
//                            if (endDate) {
//                                params.end_date = endDate;
//                            }
//                            if (status) {
//                                params.status = status;
//                            }
//                            if (encounter) {
//                                params.encounter = encounter;
//                            }
//                            // Get items
//                            if (transactionId) {
//                                params.ar_transaction = transactionId;
//                            }
//                            infService = new INFService_1.default();
//                            return [4 /*yield*/, utils_1.to(infService.getBillTransactionIPD(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.INF.BILL_TRANSACTION_IPD, { params: params })
//            .then(function (response) { return response.data; })
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/INF/bill-transaction-ipd/
}

export const getBillTransactionIPDWithId: Handler = async (controller, {}) => {
//function (transactionId) { return __awaiter(_this, void 0, void 0, function () {
//            var infService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        infService = new INFService_1.default();
//                        return [4 /*yield*/, utils_1.to(infService.getBillTransactionIPDWithId(transactionId))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (transactionId) {
//        var url = apis_1.INF.BILL_TRANSACTION_IPD_ID({
//            bill_transaction_ipd_id: transactionId
//        });
//        return this.client
//            .get(url)
//            .then(function (response) { return response.data; })
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/INF/bill-transaction-ipd/' +
//((__t = (bill_transaction_ipd_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const updateBillTransactionIPDWithId: Handler = async (controller, {}) => {
//function (transactionId, data) { return __awaiter(_this, void 0, void 0, function () {
//            var infService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        infService = new INFService_1.default();
//                        return [4 /*yield*/, utils_1.to(infService.patchBillTransactionIPDWithId(transactionId, data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const putBillTransactionUpdateStatus: Handler = async (controller, {}) => {
//function (transactionId, data) { return __awaiter(_this, void 0, void 0, function () {
//            var infService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        infService = new INFService_1.default();
//                        return [4 /*yield*/, utils_1.to(infService.putBillTransactionUpdateStatus(transactionId, data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (transactionId, data) {
//        var url = apis_1.INF.BILL_TRANSACTION_UPDATE_STATUS({
//            bill_transaction_id: transactionId
//        });
//        return this.client
//            .put(url, data)
//            .then(function (response) { return response.data; })
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += '/apis/INF/bill-transaction-update-status/' +
//((__t = (bill_transaction_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const putBillTransaction: Handler = async (controller, {}) => {
//function (transactionId, data) { return __awaiter(_this, void 0, void 0, function () {
//            var infService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        infService = new INFService_1.default();
//                        return [4 /*yield*/, utils_1.to(infService.putBillTransaction(transactionId, data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (transactionId, data) {
//        var url = apis_1.INF.BILL_TRANSACTION_DETAIL({
//            bill_transaction_id: transactionId
//        });
//        return this.client
//            .put(url, data)
//            .then(function (response) { return response.data; })
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/INF/bill-transaction/' +
//((__t = (bill_transaction_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getBillTransactionIPDSummary: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, transactionId = _b.transactionId, startDate = _b.startDate, endDate = _b.endDate, coverageGroupId = _b.coverageGroupId, status = _b.status, encounter = _b.encounter, sentClaimId = _b.sentClaimId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, infService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (transactionId) {
//                                params.ar_transaction = transactionId;
//                            }
//                            if (startDate) {
//                                params.start_date = startDate;
//                            }
//                            if (endDate) {
//                                params.end_date = endDate;
//                            }
//                            if (coverageGroupId) {
//                                params.coverage_payer_sent_claim_group = coverageGroupId;
//                            }
//                            if (status) {
//                                params.status = status;
//                            }
//                            if (sentClaimId) {
//                                params.sent_claim_transaction_ipd = sentClaimId;
//                            }
//                            if (encounter) {
//                                params.encounter = encounter;
//                            }
//                            infService = new INFService_1.default();
//                            return [4 /*yield*/, utils_1.to(infService.getBillTransactionIPDSummary(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.INF.BILL_TRANSACTION_IPD_SUMMARY, { params: params })
//            .then(function (response) { return response.data; })
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/INF/bill-transaction-ipd/summary/
}

export const getIPDDiagnosisSentClaim: Handler = async (controller, {}) => {
//function (_a) {
//            var transactionId = _a.transactionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, infService, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {};
//                            if (transactionId) {
//                                params.bill_transaction_ipd = transactionId;
//                            }
//                            infService = new INFService_1.default();
//                            return [4 /*yield*/, utils_1.to(infService.getIPDDiagnosisSentClaim(params))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.INF.IPD_DIAGNOSIS_SENT_CLAIM, { params: params })
//            .then(function (response) { return response.data; })
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/INF/ipd-diagnosis-sent-claim/
}

export const getIPDProcedureSentClaim: Handler = async (controller, {}) => {
//function (_a) {
//            var transactionId = _a.transactionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, infService, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {};
//                            if (transactionId) {
//                                params.bill_transaction_ipd = transactionId;
//                            }
//                            infService = new INFService_1.default();
//                            return [4 /*yield*/, utils_1.to(infService.getIPDProcedureSentClaim(params))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.INF.IPD_PROCEDURE_SENT_CLAIM, { params: params })
//            .then(function (response) { return response.data; })
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/INF/ipd-procedure-sent-claim/
}

export const getBillTransactionIPDItem: Handler = async (controller, {}) => {
//function (_a) {
//            var transactionId = _a.transactionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, infService, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {};
//                            if (transactionId) {
//                                params.bill_transaction_ipd = transactionId;
//                            }
//                            infService = new INFService_1.default();
//                            return [4 /*yield*/, utils_1.to(infService.getBillTransactionIPDItem(params))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.INF.BILL_TRANSACTION_IPD_ITEM, { params: params })
//            .then(function (response) { return response.data; })
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/INF/bill-transaction-ipd-item/
}

export const doGenerateSentClaimDataIPD: Handler = async (controller, {}) => {
//function (coverageGroupId, startDate, endDate) { return __awaiter(_this, void 0, void 0, function () {
//            var infService, targetStartDate, targetEndDate, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        infService = new INFService_1.default();
//                        targetStartDate = utils_1.formatDateToYYYYMMDD(startDate);
//                        targetEndDate = utils_1.formatDateToYYYYMMDD(endDate);
//                        return [4 /*yield*/, utils_1.to(infService.doGenerateSentClaimDataIPD(coverageGroupId, targetStartDate, targetEndDate))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (coverageGroupId, startDate, endDate) {
//        var url = apis_1.INF.GENERATE_SENT_CLAIM_DATA_IPD({
//            coverage_group_id: coverageGroupId,
//            start_date: startDate,
//            end_date: endDate
//        });
//        return this.client
//            .get(url)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/INF/do-generate-sent-claim-data-ipd-from-group/' +
//((__t = (coverage_group_id)) == null ? '' : __t) +
//'/' +
//((__t = (start_date)) == null ? '' : __t) +
//'/' +
//((__t = (end_date)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const arTaskTrackingIPDGenerateSentClaim: Handler = async (controller, {}) => {
//function (_a) {
//            var groupType = _a.groupType;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, action, infService, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {};
//                            if (groupType) {
//                                params.group_type = groupType;
//                            }
//                            action = "IPD_GENERATE_SENT_CLAIM_DATA";
//                            infService = new INFService_1.default();
//                            return [4 /*yield*/, utils_1.to(infService.arTaskTrackingAction(action, params))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//
//----> Name mismatch between manager and service
}

export const arTaskTrackingIPDUpdateSentClaim: Handler = async (controller, {}) => {
//function (_a) {
//            var groupType = _a.groupType;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, action, infService, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {};
//                            if (groupType) {
//                                params.group_type = groupType;
//                            }
//                            action = "IPD_UPDATE_SENT_CLAIM_DATA";
//                            infService = new INFService_1.default();
//                            return [4 /*yield*/, utils_1.to(infService.arTaskTrackingAction(action, params))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//
//----> Name mismatch between manager and service
}

export const updateSentClaimDataIPDFromARId: Handler = async (controller, {}) => {
//function (transactionId) { return __awaiter(_this, void 0, void 0, function () {
//            var infService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        infService = new INFService_1.default();
//                        return [4 /*yield*/, utils_1.to(infService.getUpdateSentClaimDataIPDFromARId(transactionId))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const generateSentClaimIPDFileFromARId: Handler = async (controller, {}) => {
//function (transactionId, _a) {
//            var encounter = _a.encounter, sentClaimStatus = _a.sentClaimStatus;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, infService, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {};
//                            if (encounter) {
//                                params.encounter = encounter;
//                            }
//                            if (sentClaimStatus) {
//                                params.status = sentClaimStatus;
//                            }
//                            infService = new INFService_1.default();
//                            return [4 /*yield*/, utils_1.to(infService.getGenerateSentClaimIPDFileFromARId(transactionId, params))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            if (response) {
//                                // Download file
//                                utils_1.downloadFile(response);
//                            }
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//
//----> Name mismatch between manager and service
}

export const importResponseFileIPD: Handler = async (controller, {}) => {
//function (_a) {
//            var file = _a.file, isSave = _a.isSave;
//            return __awaiter(_this, void 0, void 0, function () {
//                var formData, infService, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            formData = new FormData();
//                            if (file) {
//                                formData.append("file", file);
//                            }
//                            if (isSave !== undefined) {
//                                formData.append("save", isSave);
//                            }
//                            infService = new INFService_1.default();
//                            return [4 /*yield*/, utils_1.to(infService.putImportResponseFileIPD(formData))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//
//----> Name mismatch between manager and service
}

export const getSentClaimTransactionIPD: Handler = async (controller, {}) => {
//function (_a) {
//            var arTransactionId = _a.arTransactionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, infService, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {};
//                            if (arTransactionId) {
//                                params.ar_transaction = arTransactionId;
//                            }
//                            infService = new INFService_1.default();
//                            return [4 /*yield*/, utils_1.to(infService.getSentClaimTransactionIPD(params))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.INF.SENT_CLAIM_TRANSACTION_IPD, { params: params })
//            .then(function (response) { return response.data; })
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/INF/sent-claim-transaction-ipd/
}

export const getEncounterFromARId: Handler = async (controller, {}) => {
//function (transactionId) { return __awaiter(_this, void 0, void 0, function () {
//            var infService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        infService = new INFService_1.default();
//                        return [4 /*yield*/, utils_1.to(infService.getEncounterFromARId(transactionId))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (transactionId) {
//        var url = apis_1.INF.ENCOUNTER_FROM_AR_ID({ ar_transaction_id: transactionId });
//        return this.client
//            .get(url)
//            .then(function (response) { return response.data; })
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/INF/encounter-from-ar/' +
//((__t = (ar_transaction_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}
