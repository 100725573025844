import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Label,
  Button
} from 'semantic-ui-react'

const CardDrugResuscitationNurseUx = (props: any) => {
    return(
      <div
        style={{margin: "10px", backgroundColor: "#F3F3F3", height:"90%"}}>
        <div
          style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>
          
          <label
            className="header"
            style={{fontWeight: "bold", fontSize: "20px", marginLeft: "4px"}}>
            รายการยากู้ชีพฉุกเฉิน
          </label>
          <div
            style={{flex:1}}>
            
            <Label
              color="yellow"
              style={{margin: "0px 0px 0px 14px"}}>
              {props.DrugResuscitationSequence?.selectedBox?.code || ""}
            </Label>
          </div>
          <Label
            color="red"
            style={{ display: props.DrugResuscitationSequence?.selectedBox?.is_expired ? "flex" : "none"}}>
            {props.DrugResuscitationSequence?.selectedBox?.is_expired ? "Drug Expired" : ""}
          </Label>
          <Label
            color={props.DrugResuscitationSequence?.selectedBox?.status_name === "ACTIVE" ?  "green" : "grey"}
            style={{display:"flex", width:"auto", marginLeft: "auto"}}>
            {props.DrugResuscitationSequence?.selectedBox?.status_name || ""}
          </Label>
        </div>
        <div>
          
          <hr>
          </hr>
        </div>
        <div
          style={{border:"1px solid #ccc", borderRadius: "8px", padding: "0px 8px 4px 10px"}}>
          
          <div
            style={{ height: "40%", position: "relative", padding: "15px" }}>
            
            <Table
              data={props.drugItems}
              headers="Drug,จำนวนยาทั้งหมด,Used Quantity,จำนวนยาที่เหลือ,Stock Unit,วันหมดอายุ"
              keys="drugs_str,total_quantity,used,quantity,default_dosage_unit_name,expired"
              minRows={8}
              showPagination={false}
              style={{fontSize: "medium", height: "25vh"}}
              >
            </Table>
          </div>
          <div
            style={{display: "flex", alignItems: "center", margin: "14px 0px"}}>
            
            <Button
              onClick={props.handleGetDrugActionLog}
              size="small">
              ประวัติการจ่ายยา
            </Button>
            <div
              style={{flex:1}}>
              
            </div>
            <Button
              color="blue"
              onClick={props.returnBox}
              size="small"
              style={{display: props.showReturnBox ? "flex" : "none", margin: "0px 10px 0px 5px"}}>
              แจ้งคืนกล่องยา
            </Button>
            <Button
              color="red"
              onClick={props.cancelreturnBox}
              size="small"
              style={{display: props.showCancelReturnBox ? "flex" : "none", margin: "0px 10px 0px 5px"}}>
              ยกเลิกแจ้งคืนกล่องยา
            </Button>
          </div>
        </div>
      </div>
    )
}

export default CardDrugResuscitationNurseUx

export const screenPropsDefault = {"language":"TH"}

/* Date Time : Wed Mar 15 2023 17:16:25 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", backgroundColor: \"#F3F3F3\", height:\"90%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "hr",
      "parent": 6,
      "props": {
      },
      "seq": 7,
      "void": true
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"40%\", position: \"relative\", padding: \"15px\" }"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 15,
      "name": "Table",
      "parent": 14,
      "props": {
        "columns": {
          "type": "code",
          "value": ""
        },
        "data": {
          "type": "code",
          "value": "props.drugItems"
        },
        "headers": {
          "type": "value",
          "value": "Drug,จำนวนยาทั้งหมด,Used Quantity,จำนวนยาที่เหลือ,Stock Unit,วันหมดอายุ"
        },
        "keys": {
          "type": "value",
          "value": "drugs_str,total_quantity,used,quantity,default_dosage_unit_name,expired"
        },
        "minRows": {
          "type": "code",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"medium\", height: \"25vh\"}"
        },
        "widths": {
          "type": "value",
          "value": "auto,160,160,160,100,160"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการยากู้ชีพฉุกเฉิน"
        },
        "className": {
          "type": "value",
          "value": "header"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"20px\", marginLeft: \"4px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.selectedBox?.is_expired ? \"Drug Expired\" : \"\""
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugResuscitationSequence?.selectedBox?.is_expired ? \"flex\" : \"none\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"14px 0px\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{border:\"1px solid #ccc\", borderRadius: \"8px\", padding: \"0px 8px 4px 10px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Button",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการจ่ายยา"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.handleGetDrugActionLog"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.selectedBox?.status_name || \"\""
        },
        "color": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.selectedBox?.status_name === \"ACTIVE\" ?  \"green\" : \"grey\""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", width:\"auto\", marginLeft: \"auto\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Button",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "แจ้งคืนกล่องยา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.returnBox"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showReturnBox ? \"flex\" : \"none\", margin: \"0px 10px 0px 5px\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Label",
      "parent": 62,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.selectedBox?.code || \"\""
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 0px 0px 14px\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Button",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกแจ้งคืนกล่องยา"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.cancelreturnBox"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showCancelReturnBox ? \"flex\" : \"none\", margin: \"0px 10px 0px 5px\"}"
        }
      },
      "seq": 65,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugResuscitationNurseUx",
  "project": "IsHealth",
  "screenPropsDefault": {
    "language": "TH"
  },
  "width": 80
}

*********************************************************************************** */
