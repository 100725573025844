import React, {
  useEffect,
  useState,
  useRef,
  MutableRefObject,
  ReactElement,
} from "react";
import { Button, Modal, Image, Icon, Pagination } from "semantic-ui-react";

import * as XLSX from "xlsx";

// Framework
import { Table } from "react-lib/frameworks/Table";

// UX
import CardRegSearchUX from "./CardRegSearchUX";

// Common
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModInfo from "react-lib/apps/common/ModInfo";

// CONFIG
import ModOpenEmergencyUX from "./ModOpenEmergencyUX";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Const
const MOD_OPEN_EMERGENCY = "ModOpenEmergencyUX";

const CARD_REG_SEARCH = "CardRegSearch";

const limit = 40;

const IMAGES = {
  //icon
  addPatient: "/static/images/regSearch/addPatient.png",
  addPatientGroup: "/static/images/regSearch/addPatientGroup.png",
  smartCard: "/static/images/regSearch/smartCard.png",
  addPatientEmergency: "/static/images/regSearch/addPatientEmergency.png",
  plus: "/static/images/regSearch/plus.png",
};

const CardRegSearch = (props: any) => {
  const [tab, setTab] = useState("register");

  // data
  const [file, setFile] = useState<any>(null);
  const [dataFile, setDataFile] = useState<any>();
  const [page, setPage] = useState(1);
  // mod
  const [openCAgentMessage, setOpenCAgentMessage] = useState<boolean>();
  const [openModEmergency, setOpenModEmergency] = useState<boolean>(false);
  const [openBulkRegist, setOpenBulkRegist] = useState<boolean>(false);

  const fileRef = useRef() as MutableRefObject<HTMLInputElement>;
  const buttonLoadingRef = useRef<string>("");
  // useEffect(() => {
  //   props.runSequence({
  //     sequence: "SetPatient",
  //     action: "search",
  //     searchText: props?.SetPatientSequence?.searchText,
  //     page: page,
  //   });
  // }, [page]);

  useEffect(() => {
    buttonLoadingRef.current =
      props.buttonLoadCheck?.[`${CARD_REG_SEARCH}_search`];
  }, [props.buttonLoadCheck]);

  useEffect(() => {
    if (props?.errorMessage?.["regCAgent"]) {
      setOpenCAgentMessage(true);
    }
  }, [props?.errorMessage?.["regCAgent"]]);

  useEffect(() => {
    if (props?.successMessage?.[MOD_OPEN_EMERGENCY]) {
      setOpenModEmergency(false);
    }
  }, [props?.successMessage?.[MOD_OPEN_EMERGENCY]]);

  useEffect(() => {
    const list = (props.SetPatientSequence?.patientEmergencyList || [])?.map(
      (item: any, index: number) => ({
        ...item,
        no: index + 1,
      })
    );

    setDataFile(list);
  }, [props.SetPatientSequence?.patientEmergencyList]);

  const handleCloseCAgent = () => {
    setOpenCAgentMessage(false);
    props.setProp(`errorMessage.${"regCAgent"}`, null);
  };

  const handleChangeFile = (event: any) => {
    const file = event?.target?.files?.[0];

    if (file) {
      setFile(file);

      const fileReader = new FileReader();
      fileReader.readAsBinaryString(file);

      fileReader.onload = (event: any) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const rowObject = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[workbook.SheetNames[0]]
        );

        const list: any[] = rowObject.map((item: any, index: number) => ({
          ...item,
          no: index + 1,
          hn: "",
          pre_name: item["คำนำหน้า"],
          first_name_th: item["ชื่อ"],
          last_name_th: item["นามสกุล"],
          gender: item["เพศ"],
        }));

        setDataFile(list);
      };
    } else {
      fileRef.current.value = "";
      setFile(null);
    }
  };
  const handlePaginationChange = (e: any, { activePage }: any) => {
    setPage(activePage);
  };

  const changeValue = (key: string) => (e: any, v: any) => {
    props.setProp(`SetPatientSequence.patientEmergency.${key}`, v.value);
  };

  const handleCloseModGroupPatientHistory = () => {
    setOpenBulkRegist(false);

    props.setProp("SetPatientSequence.patientEmergencyList", []);
  };

  // Import File
  const handleClickUpload = () => {
    fileRef.current?.click?.();
  };

  const handleSearchText = (e: any, v: any) => {
    props.setProp("SetPatientSequence.searchText", v.value);
  };

  const handleClickSmartCard = (e: any) => {
    props.runSequence({
      sequence: "SetPatient",
      action: "smartCard",
    });
  };

  const handleSearch = (e: any) => {
    props.runSequence({
      sequence: "SetPatient",
      action: "search",
      searchText: props?.SetPatientSequence?.searchText,
      page: page,
      limit: limit,
      card: CARD_REG_SEARCH,
    });
  };

  const handleNewPatient = (e: any) => {
    props.setProp("AllergySequence", null);

    props.runSequence({ sequence: "SetPatient", action: "new" });

    props.forward();
  };

  const handlePatientRowProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      onClick: async (e: any) => {
        props.runSequence({
          sequence: "SetPatient",
          action: "select",
          patient: rowInfo?.original,
        });

        props.forward();
      },
    };
  };

  const handleOpenEmergency = () => {
    setOpenModEmergency(true);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {/* <div
        style={{
          display: "flex",
          padding: "5px 0 10px 0",
          flex: "1 0 auto"
        }}
      >
        <MenuItem
          tab={tab}
          name="register"
          title="เปิดประวัติ (บุคคล)"
          setTab={setTab}
        />
        {!CONFIG.V3_HIDDEN_TAB_LIST.includes("emergency") && (
          <MenuItem
            tab={tab}
            name="emergency"
            title="เปิดประวัติหมู่"
            setTab={setTab}
          />
        )}
      </div> */}
      {tab === "register" ? (
        <CardRegSearchUX
          // data
          searchText={props?.SetPatientSequence?.searchText}
          patientList={props?.SetPatientSequence?.patientList || []}
          // callback
          changeSearchText={handleSearchText}
          onSmartCard={handleClickSmartCard}
          // onSearch={handleSearch}
          onNewPatient={handleNewPatient}
          patientRowProps={handlePatientRowProps}
          OnEmergency={handleOpenEmergency}
          // condig
          isFinance={["การเงิน"].includes(props.divisionType || "")}
          // Element
          addPatientName={
            <div style={{ display: "flex", alignItems: "center" }}>
              <Image
                height={15}
                src={IMAGES.addPatient}
                style={{ marginRight: "10px" }}
              />
              เปิดประวัติ
            </div>
          }
          addPatientGroupName={
            <div
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => setOpenBulkRegist(true)}
            >
              <Image
                height={10}
                src={IMAGES.plus}
                style={{ marginRight: "5px" }}
              />
              <Image
                height={15}
                src={IMAGES.addPatientGroup}
                style={{ marginRight: "10px" }}
              />
              เปิดประวัติหมู่
            </div>
          }
          smartCardName={
            <div style={{ display: "flex", alignItems: "center" }}>
              <Image
                height={15}
                src={IMAGES.smartCard}
                style={{ marginRight: "10px" }}
              />
              Smart Card
            </div>
          }
          addPatientEmergencyName={
            <div style={{ display: "flex", alignItems: "center" }}>
              <Image
                height={15}
                src={IMAGES.addPatientEmergency}
                style={{ marginRight: "10px" }}
              />
              เปิดประวัติฉุกเฉิน
            </div>
          }
          paginationComponent={
            <Pagination
              activePage={page}
              onPageChange={handlePaginationChange}
              totalPages={
                props.SetPatientSequence?.patientListTotal !== undefined
                  ? Math.ceil(
                      props.SetPatientSequence?.patientListTotal / limit
                    )
                  : 0
              }
            />
          }
          NoDataComponent={
            <NoDataComponent
              // seq
              SetPatientSequence={props.SetPatientSequence}
              // CommonInterface
              buttonLoadCheck={props.buttonLoadCheck}
              buttonLoading={buttonLoadingRef.current}
            />
          }
          buttonSearch={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleSearch}
              // data
              paramKey={`${CARD_REG_SEARCH}_search`}
              buttonLoadCheck={
                props.buttonLoadCheck?.[`${CARD_REG_SEARCH}_search`]
              }
              // config
              color={"blue"}
              name={"search"}
              size="medium"
              title="ค้นหา"
              style={{ height: "100%" }}
            />
          }
        />
      ) : (
        ""
      )}

      <Modal
        open={openBulkRegist}
        closeOnDimmerClick={true}
        onClose={handleCloseModGroupPatientHistory}
      >
        <>
          <div
            style={{
              backgroundColor: "var(--primary-theme-color)",
              padding: "15px 20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", color: "white" }}
            >
              <Image
                height={10}
                src={IMAGES.plus}
                style={{ marginRight: "5px" }}
              />
              <Image
                height={15}
                src={IMAGES.addPatientGroup}
                style={{ marginRight: "10px" }}
              />
              เปิดประวัติหมู่
            </div>
            <Icon
              name="close"
              link={true}
              style={{ color: "white" }}
              onClick={handleCloseModGroupPatientHistory}
            />
          </div>
          <div style={{ padding: "10px" }}>
            <div style={{ display: "flex" }}>
              <input
                ref={fileRef}
                type="file"
                accept=".xlsx"
                onChange={handleChangeFile}
                hidden
              />

              <div style={{ flex: "1" }}></div>
              <Button color="blue" size="small" onClick={handleClickUpload}>
                อัพโหลดไฟล์
              </Button>

              <Button
                color="green"
                size="small"
                onClick={() => {
                  props.runSequence({
                    sequence: "SetPatient",
                    action: "register_emergency",
                    patient: dataFile,
                  });
                }}
              >
                ยืนยันเปิดประวัติ
              </Button>
            </div>

            <div style={{ marginTop: "5px" }}>
              <Table
                data={dataFile}
                headers="No.,HN,คำนำหน้า,ชื่อ,สกุล,เพศ"
                keys="no,hn,pre_name,first_name_th,last_name_th,gender"
                minRows="15"
                showPagination={false}
                style={{ height: "500px" }}
              ></Table>
            </div>
          </div>
        </>
      </Modal>

      <Modal
        open={openModEmergency}
        size="small"
        onClose={() => {
          setOpenModEmergency(false);
          props.setProp(`errorMessage.${MOD_OPEN_EMERGENCY}`, null);
        }}
      >
        <ModOpenEmergencyUX
          hn={props?.SetPatientSequence?.patientEmergency?.hn}
          first_name={props?.SetPatientSequence?.patientEmergency?.first_name}
          last_name={props?.SetPatientSequence?.patientEmergency?.last_name}
          gender={props?.SetPatientSequence?.patientEmergency?.gender}
          ErrorMessage={
            <ErrorMessage error={props?.errorMessage?.[MOD_OPEN_EMERGENCY]} />
          }
          changeValue={changeValue}
          OnSave={() => {
            console.log("test_save");
            props.runSequence({
              sequence: "SetPatient",
              action: "patient_emergency",
              card: MOD_OPEN_EMERGENCY,
              forward: props.forward,
            });
          }}
          OnClear={() => {
            props.runSequence({
              sequence: "SetPatient",
              action: "clear_emergency",
              card: MOD_OPEN_EMERGENCY,
            });
          }}
          onClose={() => {
            setOpenModEmergency(false);
            props.setProp(`errorMessage.${MOD_OPEN_EMERGENCY}`, null);
          }}
        />
      </Modal>

      <ModInfo
        open={openCAgentMessage}
        titleColor={"red"}
        titleName={"แจ้งเตือน"}
        onApprove={handleCloseCAgent}
        onClose={handleCloseCAgent}
      >
        <div
          style={{ padding: "0.5rem 0", fontWeight: "bold", lineHeight: 1.5 }}
        >
          <div>{props?.errorMessage?.["regCAgent"]?.error}</div>
        </div>
      </ModInfo>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                    NoDataComponent;                    */

/* ------------------------------------------------------ */
const NoDataComponent = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (
      !props.buttonLoadCheck?.[`${CARD_REG_SEARCH}_search`] &&
      !props?.SetPatientSequence?.patientList &&
      !!props.buttonLoading
    ) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 750);
    }
  }, [props.buttonLoadCheck, props.SetPatientSequence]);

  return (
    <div
      className="rt-noData"
      style={{
        fontSize: "1.35rem",
        fontWeight: "bold",
        color: "rgba(0,0,0,0.25)",
        textAlign: "center",
        lineHeight: 1.5,
      }}
    >
      {!!props.buttonLoadCheck?.[`${CARD_REG_SEARCH}_search`] || loading ? (
        "กำลังค้นหาผู้ป่วย..."
      ) : !props?.SetPatientSequence?.patientList ? (
        "กรุณาทำการค้นหาผู้ป่วย"
      ) : !props?.SetPatientSequence?.patientList.length ? (
        <>
          <div>ไม่พบข้อมูล</div>
          <div>กรุณาทำการค้นหาใหม่อีกครั้ง</div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

const MenuItem = (props: any) => (
  <Button
    size="tiny"
    color={props.name === props.tab ? "blue" : undefined}
    onClick={(e: any) => props.setTab(props.name)}
  >
    {props.title}
  </Button>
);

export default React.memo(CardRegSearch);
