import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Input,
  Dropdown,
  Checkbox,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardLabOrderQueueUX = (props: any) => {
    return(
      <div>
        <div
          style={{zoom:"80%"}}>
          
          <div
            style={{ display: "flex", alignItems:"center",paddingBottom: "10px" }}>
            
            <div
              style={{marginRight:"10px", minWidth: "max-content"}}>
              Barcode
            </div>
            <div>
              
              <Input
                fluid={true}
                icon="search"
                onChange={props.handleChangeValue("selectedBarcode")}
                style={{width: "100%", minWidth: "12rem"}}
                value={props.selectedBarcode}>
              </Input>
            </div>
            <div
              style={{marginLeft:"10px",marginRight:"10px", minWidth: "max-content"}}>
              HN
            </div>
            <div>
              
              <Input
                fluid={true}
                icon="search"
                onChange={props.handleChangeValue("selectedHN")}
                style={{width: "100%", minWidth: "12rem"}}
                value={props.selectedHN}>
              </Input>
            </div>
            <div
              style={{marginLeft:"10px",marginRight:"10px", minWidth: "max-content"}}>
              ชื่อ
            </div>
            <div>
              
              <Input
                fluid={true}
                onChange={props.handleChangeValue("selectedFirstname")}
                style={{width: "100%", minWidth: "12rem"}}
                value={props.selectedFirstname}>
              </Input>
            </div>
            <div
              style={{marginLeft:"10px",marginRight:"10px", minWidth: "max-content"}}>
              นามสกุล
            </div>
            <div>
              
              <Input
                fluid={true}
                onChange={props.handleChangeValue("selectedLastname")}
                style={{width: "100%", minWidth: "12rem"}}
                value={props.selectedLastname}>
              </Input>
            </div>
            <div
              style={{marginLeft:"10px",marginRight:"10px",minWidth: "max-content"}}>
              วันที่สั่ง
            </div>
            <div>
              
              <DateTextBox
                onChange={props.handleChangeDate("selectedStartDate")}
                value={props.selectedStartDate}>
              </DateTextBox>
            </div>
            <div
              style={{marginLeft:"10px",marginRight:"10px", minWidth: "max-content"}}>
              ถึง
            </div>
            <div>
              
              <DateTextBox
                onChange={props.handleChangeDate("selectedEndDate")}
                value={props.selectedEndDate}>
              </DateTextBox>
            </div>
            <div
              style={{marginLeft:"10px",marginRight:"10px", minWidth: "max-content"}}>
              Encounter Type
            </div>
            <div>
              
              <Dropdown
                defaultValue="ALL"
                onChange={props.handleChangeValue("selectedEncounterType")}
                options={props.encounterOptions}
                search={true}
                selection={true}
                value={props.selectedEncounterType}>
              </Dropdown>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems:"center",paddingBottom: "10px" }}>
            
            <Checkbox
              checked={props.checkSpecimen}
              onChange={props.handleChangeChecked("checkSpecimen")}>
            </Checkbox>
            <div
              style={{margin: "0 1rem",minWidth: "max-content"}}>
              Specimen ที่ไม่ได้จัดเก็บ
            </div>
            <Checkbox
              checked={props.checkSpecimenReject}
              onChange={props.handleChangeChecked("checkSpecimenReject")}>
            </Checkbox>
            <div
              style={{margin: "0 1rem", minWidth: "max-content"}}>
              Specimen Rejection
            </div>
            <div
              style={{marginRight: "1rem", minWidth: "max-content"}}>
              สถานะ
            </div>
            <div>
              
              <Dropdown
                defaultValue="ALL"
                disabled={props.checkSpecimenReject}
                fluid={true}
                onChange={props.handleChangeValue("selectedStatus")}
                options={props.statusOptions}
                search={true}
                selection={true}
                style={{width: "100%", minWidth: "12rem"}}
                value={props.selectedStatus}>
              </Dropdown>
            </div>
            <div
              style={{marginLeft:"20px",marginRight:"10px", minWidth: "max-content"}}>
              ประเภทแลป
            </div>
            <div>
              
              <Dropdown
                defaultValue="ALL"
                fluid={true}
                onChange={props.handleChangeValue("selectedLabType")}
                options={props.labOptions}
                search={true}
                selection={true}
                style={{width: "100%", minWidth: "12rem"}}
                value={props.selectedLabType}>
              </Dropdown>
            </div>
            <div
              style={{marginLeft:"20px",marginRight:"10px", minWidth: "max-content"}}>
              ความเร่งด่วน
            </div>
            <div>
              
              <Dropdown
                defaultValue="ALL"
                fluid={true}
                onChange={props.handleChangeValue("selectedUrgency")}
                options={props.urgencyOptions}
                search={true}
                selection={true}
                style={{width: "100%", minWidth: "12rem"}}
                value={props.selectedUrgency}>
              </Dropdown>
            </div>
            <div
              style={{marginLeft:"20px",marginRight:"10px", minWidth: "max-content"}}>
              แผนกที่สั่ง
            </div>
            <div>
              
              <Dropdown
                defaultValue="ALL"
                onChange={props.handleChangeValue("selectedDivision")}
                options={props.divisionOptions}
                search={true}
                selection={true}
                value={props.selectedDivision}>
              </Dropdown>
            </div>
            <div
              style={{marginLeft:"20px",marginRight:"10px", minWidth: "max-content"}}>
              แพทย์ผู้สั่ง
            </div>
            <div>
              
              <Dropdown
                defaultValue="ALL"
                onChange={props.handleChangeValue("selectedDoctor")}
                options={props.doctorOptions}
                search={true}
                selection={true}
                value={props.selectedDoctor}>
              </Dropdown>
            </div>
            <div
              style={{marginLeft:"15px"}}>
              
              <div>
                {props.buttonSearch}
              </div>
            </div>
          </div>
          <div
            style={{display: "flex", paddingBottom: "5px"}}>
            
            <div
              style={{display: "flex", flex: "1", alignItems: "center"}}>
              
              <div
                style={{width: "auto", padding: "0px 5px"}}>
                
                <Button
                  color={props.isShow? "pink": "green"}
                  onClick={props.onClickShowCrossMatch}>
                  {props.isShow? "ปิดรายการจองเลือด":"แสดงรายการจองเลือด"}
                </Button>
              </div>
            </div>
            <div
              style={{textAlign: "right"}}>
              
              <div>
                {props.buttonPrint}
              </div>
            </div>
          </div>
        </div>
        <Table
          data={props.labOrderQueue}
          getTrProps={props.rowProps}
          headers={props.headerTitle}
          keys={props.headerKey}
          minRows="20"
          showPagination={false}
          style={{height: props.isShow? "44vh":"80vh"}}
          widths={props.width}>
        </Table>
        <div
          style={{height: "36vh", display: props.isShow? "flex": "none", flexDirection: "column"}}>
          
          <div
            style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            
            <div
              style={{padding: "10px 5px", display: "flex", alignItems: "center"}}>
              {}
              <div
                style={{display: "flex", flex: "1", alignItems: "center"}}>
                
                <div
                  style={{padding: "0px 5px",}}>
                  วันที่ทำ Cross-Match
                </div>
                <div>
                  
                  <DateTextBox
                    onChange={props.handleChangeDate("selectedStartDateCrossMatch")}
                    value={props.selectedStartDateCrossMatch}>
                  </DateTextBox>
                </div>
                <div
                  style={{padding: "0px 5px"}}>
                  ถึง
                </div>
                <div>
                  
                  <DateTextBox
                    onChange={props.handleChangeDate("selectedEndDateCrossMatch")}
                    value={props.selectedEndDateCrossMatch}>
                  </DateTextBox>
                </div>
              </div>
              <div
                style={{padding: "0px 5px"}}>
                {props.crossMatchSearchButton}
              </div>
            </div>
            <div>
              {props.crossMatchPrintButton}
            </div>
          </div>
          <Table
            data={props.labCrossMatchOrderQueue}
            getTrProps={props.rowPropsCrossMatch}
            headers={props.headerTitleCrossMatch}
            keys={props.headerKeyCrossMatch}
            minRows="10"
            showPagination={false}
            style={{height: "35vh"}}
            widths={props.widthCrossMatch}>
          </Table>
        </div>
      </div>
    )
}


export default CardLabOrderQueueUX

export const screenPropsDefault = {"headerKey":"hn,patient_name,doctor_name,date,arrive_time,highest_urgency,status_name","headerTitle":"HN,ชื่อ-สกุล,แพทย์,วันเวลาที่สั่ง,วันเวลารับเข้า,Urgency,สถานะ"}

/* Date Time : Thu Jul 20 2023 03:52:37 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.labOrderQueue"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "code",
          "value": "props.headerTitle"
        },
        "keys": {
          "type": "code",
          "value": "props.headerKey"
        },
        "minRows": {
          "type": "value",
          "value": "20"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: props.isShow? \"44vh\":\"80vh\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.width"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{zoom:\"80%\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems:\"center\",paddingBottom: \"10px\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Barcode"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"10px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",marginRight:\"10px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",marginRight:\"10px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "นามสกุล"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",marginRight:\"10px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่สั่ง"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",marginRight:\"10px\",minWidth: \"max-content\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",marginRight:\"10px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Encounter Type"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",marginRight:\"10px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Input",
      "parent": 6,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedBarcode\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth: \"12rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedBarcode"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Input",
      "parent": 8,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedHN\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth: \"12rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedHN"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Input",
      "parent": 10,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedFirstname\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth: \"12rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedFirstname"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Input",
      "parent": 12,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedLastname\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth: \"12rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedLastname"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 23,
      "name": "DateTextBox",
      "parent": 14,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedStartDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.selectedStartDate"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 24,
      "name": "DateTextBox",
      "parent": 16,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedEndDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.selectedEndDate"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Dropdown",
      "parent": 18,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "defaultValue": {
          "type": "value",
          "value": "ALL"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedEncounterType\")"
        },
        "options": {
          "type": "code",
          "value": "props.encounterOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedEncounterType"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems:\"center\",paddingBottom: \"10px\" }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Checkbox",
      "parent": 26,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkSpecimen"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkSpecimen\")"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "Specimen ที่ไม่ได้จัดเก็บ"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 1rem\",minWidth: \"max-content\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", minWidth: \"max-content\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทแลป"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",marginRight:\"10px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "ความเร่งด่วน"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",marginRight:\"10px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนกที่สั่ง"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",marginRight:\"10px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ผู้สั่ง"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",marginRight:\"10px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"15px\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Dropdown",
      "parent": 30,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "defaultValue": {
          "type": "value",
          "value": "ALL"
        },
        "disabled": {
          "type": "code",
          "value": "props.checkSpecimenReject"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedStatus\")"
        },
        "options": {
          "type": "code",
          "value": "props.statusOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth: \"12rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedStatus"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Dropdown",
      "parent": 32,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "defaultValue": {
          "type": "value",
          "value": "ALL"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedLabType\")"
        },
        "options": {
          "type": "code",
          "value": "props.labOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth: \"12rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedLabType"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Dropdown",
      "parent": 34,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "defaultValue": {
          "type": "value",
          "value": "ALL"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedUrgency\")"
        },
        "options": {
          "type": "code",
          "value": "props.urgencyOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth: \"12rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedUrgency"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Dropdown",
      "parent": 36,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "defaultValue": {
          "type": "value",
          "value": "ALL"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDivision"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Dropdown",
      "parent": 38,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "defaultValue": {
          "type": "value",
          "value": "ALL"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedDoctor\")"
        },
        "options": {
          "type": "code",
          "value": "props.doctorOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDoctor"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Checkbox",
      "parent": 26,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkSpecimenReject"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkSpecimenReject\")"
        },
        "onClick": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "Specimen Rejection"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 1rem\", minWidth: \"max-content\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", paddingBottom: \"5px\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flex: \"1\", alignItems: \"center\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"auto\", padding: \"0px 5px\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Button",
      "parent": 52,
      "props": {
        "children": {
          "type": "code",
          "value": "props.isShow? \"ปิดรายการจองเลือด\":\"แสดงรายการจองเลือด\""
        },
        "color": {
          "type": "code",
          "value": "props.isShow? \"pink\": \"green\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickShowCrossMatch"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"36vh\", display: props.isShow? \"flex\": \"none\", flexDirection: \"column\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 64,
      "name": "Table",
      "parent": 62,
      "props": {
        "data": {
          "type": "code",
          "value": "props.labCrossMatchOrderQueue"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowPropsCrossMatch"
        },
        "headers": {
          "type": "code",
          "value": "props.headerTitleCrossMatch"
        },
        "keys": {
          "type": "code",
          "value": "props.headerKeyCrossMatch"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"35vh\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.widthCrossMatch"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 75,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 5px\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flex: \"1\", alignItems: \"center\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ทำ Cross-Match"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 5px\",}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 81,
      "name": "DateTextBox",
      "parent": 80,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedStartDateCrossMatch\")"
        },
        "value": {
          "type": "code",
          "value": "props.selectedStartDateCrossMatch"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 5px\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 84,
      "name": "DateTextBox",
      "parent": 83,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedEndDateCrossMatch\")"
        },
        "value": {
          "type": "code",
          "value": "props.selectedEndDateCrossMatch"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 75,
      "props": {
        "children": {
          "type": "code",
          "value": "props.crossMatchPrintButton"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 76,
      "props": {
        "children": {
          "type": "code",
          "value": "props.crossMatchSearchButton"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 5px\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        }
      },
      "seq": 88,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardLabOrderQueueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "headerKey": "hn,patient_name,doctor_name,date,arrive_time,highest_urgency,status_name",
    "headerTitle": "HN,ชื่อ-สกุล,แพทย์,วันเวลาที่สั่ง,วันเวลารับเข้า,Urgency,สถานะ"
  },
  "width": 80
}

*********************************************************************************** */
