import WasmController from "react-lib/frameworks/WasmController";
import { SCNState, SCNEvent, SCNStateInitial } from "./SCNInterface";
import * as ScannedDocument from "./SCNInterface";
// import { setup, logout } from "./appcon/common/LoginFormInterface";
// import { Login } from "./appcon/common/LoginFormInterface";
// import * as ScannedDocument from "./appcon/common/ScannedDocumentInterface";
export default class SCNController extends WasmController<SCNState, SCNEvent, any> {
  masterData: any;

  constructor(
    getState: () => SCNState,
    setState: (state: SCNState, callback?: any) => void,
    window: any
  ) {
    super(getState, setState, window, SCNStateInitial);
  }

  handleEvent = (e: SCNEvent): any => {
    console.log(e.message,e.params, "SCN_H");
    switch (e.message) {
      case "handleComponentDidMount":
        ScannedDocument.DidMount(this, e.params);
        return;
      case "FirstHandler":
        ScannedDocument.FirstHandler(this, e.params);
        return;
      case "SetScannedDocumentTab":
        ScannedDocument.SetScannedDocumentTab(this, e.params);
        return;

      case "ClickedScannedDocument":
        ScannedDocument.ClickedScannedDocument(this, e.params);
        return;
      case "ClickEditBarcodeDocument":
        ScannedDocument.ClickEditBarcodeDocument(this, e.params);
        return;
      case "ConfirmEditBarcodeDocument":
        ScannedDocument.ConfirmEditBarcodeDocument(this, e.params);
        return;
      case "ClickedBarcodeScan":
        ScannedDocument.ClickedBarcodeScan(this, e.params);
        return;
      case "GetSearchDocument":
        ScannedDocument.GetSearchDocument(this, e.params);
        return;
      case "ChangeDocumentDate":
        ScannedDocument.ChangeDocumentDate(this, e.params);
        return;
      case "SetOpenModError":
        ScannedDocument.SetOpenModError(this, e.params);
        return;
      case "SetOpenModSecretAuthen":
        ScannedDocument.SetOpenModSecretAuthen(this, e.params);
        return;
      case "SetOpenModCancelAuthen":
        ScannedDocument.SetOpenModCancelAuthen(this, e.params);
        return;
      case "SetScannedDocLimit":
        ScannedDocument.SetScannedDocLimit(this, e.params);
        return;
      case "SetDoctorFilter":
        ScannedDocument.SetDoctorFilter(this, e.params);
        return;
      case "GetDoctorSearch":
        return ScannedDocument.GetDoctorSearch(this, e.params);
      case "GetEncounterSearch":
        return ScannedDocument.GetEncounterSearch(this, e.params);
      case "GetEncounterList":
        return ScannedDocument.GetEncounterList(this, e.params);
      case "GetNationalities":
        return ScannedDocument.GetNationalities(this, e.params);
      case "GetPatientList":
        return ScannedDocument.GetPatientList(this, e.params);
      case "GetPatientDetail":
        ScannedDocument.GetPatientDetail(this, e.params);
        return;
      case "ToggleDocumentOrder":
        ScannedDocument.ToggleDocumentOrder(this, e.params);
        return;
      case "SetModEditDocument":
        ScannedDocument.SetModEditDocument(this, e.params);
        return;
      case "ToggleCheckedIsSecret":
        ScannedDocument.ToggleCheckedIsSecret(this, e.params);
        return;
      case "ToggleCheckedThumbnail":
        ScannedDocument.ToggleCheckedThumbnail(this, e.params);
        return;
      case "ToggleCheckedIsSecretAll":
        ScannedDocument.ToggleCheckedIsSecretAll(this, e.params);
        return;
      case "ToggleCheckedIsCancelSecretAll":
        ScannedDocument.ToggleCheckedIsCancelSecretAll(this, e.params);
        return;
      case "SetSelectedDocScan":
        ScannedDocument.SetSelectedDocScan(this, e.params);
        return;
      case "SetOpenPrintDocument":
        ScannedDocument.SetOpenPrintDocument(this, e.params);
        return;
      case "GetDocumentType":
        return ScannedDocument.GetDocumentType(this, e.params);
      case "SaveDocumentScan":
        ScannedDocument.SaveDocumentScan(this, e.params);
        return;
      case "ConfirmSaveDocumentScan":
        ScannedDocument.ConfirmSaveDocumentScan(this, e.params);
        return;
      case "ConfirmSaveDocumentBarcode":
        ScannedDocument.ConfirmSaveDocumentBarcode(this, e.params);
        return;
      case "handleClearDocScan":
        ScannedDocument.handleClearDocScan(this, e.params);
        return;
      case "RemoveScannedOrder":
        ScannedDocument.RemoveScannedOrder(this, e.params);
        return;
      case "PatchScannedDocument":
        return ScannedDocument.PatchScannedDocument(this, e.params);
      case "GetPatientOldName":
        return ScannedDocument.GetPatientOldName(this, e.params);
      case "GetPatientByHN":
        return ScannedDocument.GetPatientByHN(this, e.params);

      case "PrintDocument":
        ScannedDocument.PrintDocument(this, e.params);
        return;
      case "PrintDocumentImage":
        ScannedDocument.PrintDocumentImage(this, e.params);
        return;

      case "PutScannedDocUpdateSecret":
        return ScannedDocument.PutScannedDocUpdateSecret(this, e.params);
      case "ConfirmRemoveDocument":
        ScannedDocument.ConfirmRemoveDocument(this, e.params);
        return;
      case "SelectEncounter":
        ScannedDocument.SelectEncounter(this, e.params);
        return;
      case "ClearEncounter":
        ScannedDocument.ClearEncounter(this, e.params);
        return;
      case "clearInputBarcodeScan":
        ScannedDocument.clearInputBarcodeScan(this, e.params);
        return;
      default:
        // @ts-ignore
        let testExhausitve: never = e;
    }
    console.log("-- Unhandled case");
  };
}
