import React, { SyntheticEvent } from "react";
import Cookies from "js-cookie";
import { SCNState, SCNStateInitial } from "./SCNInterface";
import SCNController from "./SCNController";
import SetProp from "react-lib/frameworks/SetProp";
import {
  Icon,
  Checkbox,
  Segment,
  Form,
  Dimmer,
  Loader,
  Button,
  TextArea,
  Pagination,
  Input,
} from "semantic-ui-react";
import moment from "moment";
import { formatUTCtoBEString } from "react-lib/utils/dateUtils";
import * as Util from "_qmljs/Common/Util";
// import CardScannedDocument from 'react-lib/apps/common/CardScannedDocument'
import ScannedDocumentUX from "./ScannedDocumentUX";
import {
  PatientSearchBoxCU,
  DateTextBox,
  SearchBox,
  ModEditDocument,
  ModInfo,
  ModAuthen as ModSecretAuthen,
  ErrorMessage,
  ModConfirm as ModConfirmSave,
  ModAuthen as ModCancelAuthen,
} from "react-lib/apps/common";

import ModalItemFilter from "./ModalItemFilter";
import ModalSearchEncounter from "./ModalSearchEncounter";

// manager
import CoreManager from "react-lib/apis/manager/CoreManager";
import REGManager from "react-lib/apis/manager/REGManager";

// controller
import PatientSearchBoxCUController from "react-lib/apps/common/PatientSearchBoxCUController";

// import Viewer from 'react-viewer';
import { formatDate } from "react-lib/utils/dateUtils";
import "./SCN.scss";
import { injectIntl, IntlShape } from "react-intl";
class SCN extends React.Component<
  {
    intl: IntlShape;
    encounterId: string | number;
    patientId: string | number;
    hn: string;
    categoryId?: string | number;
    tab?: string | number;
    disabledRequiredField?: boolean;
    closeIcon?: boolean;
    onClose?: () => any;
  },
  SCNState
> {
  controller: SCNController;
  patientSearchCUController: any;
  coreManager: any;
  regManager: any;
  isMount: any;
  patientSearchBoxController: any;
  doctorRef: any;
  hnSearchRef: any;
  hnBarcodeRef: any;
  runningId: any;
  modSecretAuthenRef: any;
  modCancelRef: any;
  encounterRef: any;
  inputNumberRef: any;

  contentBounding: any;

  constructor(props: any) {
    super(props);

    const apiToken = Cookies.get("apiToken") || "";

    this.state = SCNStateInitial;
    this.controller = new SCNController(
      () => {
        return this.state;
      },
      (state: SCNState) => {
        this.setState(state);
      },
      window
    );

    this.controller.apiToken = apiToken;
    this.controller.cookies = Cookies;

    this.isMount = React.createRef();

    // manager
    this.coreManager = new CoreManager(apiToken);
    this.regManager = new REGManager(apiToken);

    // controller
    this.patientSearchCUController = new PatientSearchBoxCUController(
      this.coreManager,
      this.regManager
    );

    this.doctorRef = React.createRef();
    this.hnSearchRef = React.createRef();
    this.runningId = React.createRef();
    this.modSecretAuthenRef = React.createRef();
    this.contentBounding = React.createRef();
    this.encounterRef = React.createRef();
    this.hnBarcodeRef = React.createRef();
    this.inputNumberRef = React.createRef();
    this.modCancelRef = React.createRef();
  }

  componentDidMount = () => {
    this.controller.handleEvent({
      message: "handleComponentDidMount",
      params: {},
    });

    window.document.title = "ScannedDocument";
    let params: any = Util.getUrlParameters(window.location.href);
    let patientId = this.props.patientId || params.patient_id;
    let categoryId = +(this.props.categoryId || params.category_id);
    let tab = +(this.props.tab || params.tab || 0);
    this.controller.handleEvent({
      message: "FirstHandler",
      params: { date: formatDate(moment()), categoryId, tab, patientId },
    });
    this.setState({
      disabledRequiredField: this.props.disabledRequiredField,
      categoryProps: categoryId,
    });
    if (patientId) {
      this.handleOnSelectPatient(patientId, null, categoryId, tab);
      // this.isMount.current = true
    }
    this.runningId.current = 0;
  };

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (!this.isMount.current && prevProps.patientId) {
      setTimeout(() => {
        this.hnSearchRef.current?.setValue(this.props.hn);
      });

      this.handleOnSelectPatient(
        this.props.patientId,
        null,
        this.props.categoryId,
        // @ts-ignore
        +this.props.tab
      );
      this.isMount.current = true;
    }
    if (!this.isMount.current && this.controller.apiToken) {
      this.isMount.current = true;
    }
  };

  handleScannedDocumentTab = (tab: any) => {
    this.doctorRef.current.clear();
    this.doctorRef.current.setTextByElement("");
    if (this.hnSearchRef.current) {
      this.hnSearchRef.current.clear();
    }
    if (this.inputNumberRef.current) {
      this.inputNumberRef.current.value = "";
    }
    this.controller.handleEvent({
      message: "SetScannedDocumentTab",
      params: {
        tab: tab,
        doctorRef: this.doctorRef.current,
        runningId: this.runningId,
        hnSearchRef: this.hnSearchRef,
        encounterRef: this.encounterRef.current,
        hnBarcodeRef: this.hnBarcodeRef,
      },
    });
  };

  handleClickScanDocument = () => {
    const id = this.doctorRef.current.getId();
    const name = id ? this.doctorRef.current.getText() : "";
    const visitNumber = this.encounterRef.current.getId();
    const visitName = visitNumber ? this.encounterRef.current.getText() : "";
    const visitDate = visitNumber
      ? this.encounterRef.current.getItem()?.started
      : "";

    this.controller.handleEvent({
      message: "ClickedScannedDocument",
      params: {
        runningId: this.runningId,
        encounterDoctor: id,
        encounterDoctorName: name,
        visitNumber,
        visitName,
        visitDate,
      },
    });
  };

  handleClickEditBarcodeDocument = () => {
    this.controller.handleEvent({
      message: "ClickEditBarcodeDocument",
      params: {},
    });
  };

  handleConfirmEditBarcodeDocument = () => {
    const id = this.doctorRef.current.getId();
    const name = id ? this.doctorRef.current.getText() : "";
    const visitNumber = this.encounterRef.current.getText();
    const visitId = this.encounterRef.current.getId();
    this.controller.handleEvent({
      message: "ConfirmEditBarcodeDocument",
      params: {
        runningId: this.runningId,
        encounterDoctor: id,
        encounterDoctorName: name,
        visitNumber,
        visitId,
      },
    });
  };

  handleClickBarcodeScan = () => {
    if (!this.state.toggleBarcodeScan) {
      if (this.encounterRef.current) {
        this.encounterRef.current.clear();
      }
      if (this.doctorRef.current) {
        this.doctorRef.current.clear();
        this.doctorRef.current.setTextByElement("");
      }
      this.controller.handleEvent({
        message: "clearInputBarcodeScan",
        params: {},
      });
      this.handleClickScanDocument();
      return;
    }
    const id = this.doctorRef.current.getId();
    const name = id ? this.doctorRef.current.getText() : "";
    if (this.encounterRef.current) {
      this.encounterRef.current.clear();
    }
    if (this.doctorRef.current) {
      this.doctorRef.current.clear();
      this.doctorRef.current.setTextByElement("");
    }
    this.controller.handleEvent({
      message: "ClickedBarcodeScan",
      params: {
        runningId: this.runningId,
        encounterDoctor: id,
        encounterDoctorName: name,
      },
    });
  };

  handleClickDocumentSecret = () => {
    this.controller.handleEvent({
      message: "SetOpenModSecretAuthen",
      params: {
        open: true,
        error: "",
      },
    });
  };

  handleClickSearchDocument = () => {
    let text = this.encounterRef.current.getText() || "";
    if (text) {
      // text = text.match(/^\d+/i)[0]
    }
    this.controller.handleEvent({
      message: "GetSearchDocument",
      params: {
        ...this.state,
        encounterDoctor: +(this.state.doctorSearch || ""),
        encounterNumber: text,
        encounterNumberId: this.encounterRef.current.getId(),
      },
    });
    this.setState({ activePage: 1 });
  };

  handleChangeDocLimit = (limit: number) => {
    // this.controller.handleEvent({
    //   message: "SetScannedDocLimit",
    //   params: {
    //     ...this.state,
    //     limit,
    //     encounterDoctor: this.doctorRef.current.getId()
    //   }
    // })

    this.setState({ activePage: 1, scannedDocLimit: limit });
  };

  handleScannedRowProps = (state: any, rowInfo: any) => {
    return {
      onClick: () => {
        this.doctorRef.current.clear();
        if (this.hnBarcodeRef.current) {
          this.hnBarcodeRef.current.clear();
        }
        if (this.encounterRef.current) {
          this.encounterRef.current.clear();
        }
        this.controller.handleEvent({
          message: "SetSelectedDocScan",
          params: {
            data: rowInfo.original,
            doctorRef: this.doctorRef.current,
            hnSearchRef: this.hnSearchRef.current,
            encounterRef: this.encounterRef.current,
            hnBarcodeRef: this.hnBarcodeRef.current,
            inputNumberRef: this.inputNumberRef.current,
          },
        });
      },
      className:
        rowInfo?.original &&
        rowInfo?.original?.id === this.state.selectedDocScan?.id
          ? "selected"
          : "",
      style: {
        cursor: "pointer",
      },
    };
  };

  handleDocumentSearchRowProps = (state: any, rowInfo: any) => {
    return {
      style: {
        color: rowInfo?.original?.is_secret ? "#b6b6b6" : "",
      },
    };
  };

  handleScannedTdProps = (state: any, rowInfo: any, cellInfo: any) => {
    let is_required = false;
    const { id } = cellInfo || {};
    const { original = null } = rowInfo || {};
    // console.log(original, id);
    if (original && this.state.isSaveBarcodeScan) {
      if (id === "document_type_name" && !original.document_type) {
        is_required = true;
      }
      if (id === "document_category_name" && !original.document_category) {
        is_required = true;
      }
      if (id === "hn" && !original.hn) {
        is_required = true;
      }
      if (id === "dentist" && !original.encounter__doctor) {
        is_required = true;
      }
      if (id === "visit_number" && !original.encounter_number) {
        is_required = true;
      }
      if (id === "visit_date" && !original.visit_date) {
        is_required = true;
      }
    }
    return {
      className:
        rowInfo?.original &&
        rowInfo?.original?.id === this.state.selectedDocScan?.id
          ? "selected"
          : "",
      style: {
        border: is_required ? "1.5px solid red" : "",
      },
    };
  };

  handleGetSearchOptions = async ({ searchText = "" }) => {
    return await this.controller.handleEvent({
      message: "GetDoctorSearch",
      params: { search: searchText },
    });
  };

  handleGetSearchEncounter = async (params: any) => {
    return await this.controller.handleEvent({
      message: "GetEncounterSearch",
      params,
    });
  };

  handleGetEncounterList = async (params: any) => {
    return await this.controller.handleEvent({
      message: "GetEncounterList",
      params,
    });
  };

  handleCloseModInfo = () =>
    this.controller.handleEvent({
      message: "SetOpenModError",
      params: {
        open: false,
        title: "",
        error: "",
        color: "",
      },
    });

  handleCloseModSecret = () => {
    this.controller.handleEvent({
      message: "SetOpenModSecretAuthen",
      params: {
        open: false,
        error: "",
      },
    });
    this.modSecretAuthenRef.current.clear();
  };
  handleSetModCancel = (params = {}) => {
    this.controller.handleEvent({
      message: "SetOpenModCancelAuthen",
      params: {
        open: false,
        error: "",
        data: null,
        isClearNote: true,
        ...params,
      },
    });
    this.modCancelRef.current.clear();
  };

  handleGetPatientList = async ({
    hn,
    fullName,
    citizenId,
    nationality,
    patientCurrentPage,
  }: any = {}) => {
    let limit = 40;
    let page = patientCurrentPage
      ? patientCurrentPage
      : this.state.currentHNPage;
    this.setState({ currentHNPage: page });
    return await this.controller.handleEvent({
      message: "GetPatientList",
      params: {
        hn: hn,
        name_search: fullName,
        citizenId: citizenId,
        nationality: nationality,
        is_old_name_search: true,
        limit: limit,
        offset: (page - 1) * limit,
      },
    });
  };

  handleGetPatientOldName = async (patientId: number) => {
    return await this.controller.handleEvent({
      message: "GetPatientOldName",
      params: { patientId },
    });
  };

  handleGetPatientByHN = async (hn: string) => {
    this.setState({ disableInput: true });
    return await this.controller.handleEvent({
      message: "GetPatientByHN",
      params: { hn },
    });
  };

  handleGetNationality = async () => {
    return await this.controller.handleEvent({
      message: "GetNationalities",
      params: {},
    });
  };

  handlePaginationChange = async ({
    hn,
    fullName,
    citizenId,
    nationality,
    patientCurrentPage,
  }: any = {}) => {
    this.setState({ currentHNPage: patientCurrentPage });
    this.handleGetPatientList({
      hn,
      fullName,
      citizenId,
      nationality,
      patientCurrentPage,
    });
  };

  handleOnSelectPatient = (
    id: number | string,
    hn: string | null,
    categoryId: any = null,
    tab: any
  ) => {
    if (id) {
      if (this.encounterRef.current) {
        this.encounterRef.current.clear();
      }

      this.controller.handleEvent({
        message: "GetPatientDetail",
        params: {
          id,
          hnSearchRef: this.hnSearchRef,
          hnBarcodeRef: this.hnBarcodeRef,
          categoryId,
          doctorRef: this.doctorRef,
          encounterRef: this.encounterRef,
          tab: tab ? tab : this.state.scannedDocumentTab,
        },
      });
    }
  };

  handleSelectEncounter = (item: any) => {
    if (this.state.scannedDocumentTab === "BarcodeScan" && item.doctor) {
      this.controller.handleEvent({
        message: "SelectEncounter",
        params: { data: item, doctorRef: this.doctorRef.current },
      });
    }
  };

  handleClearEncounter = () => {
    if (this.state.scannedDocumentTab === "BarcodeScan") {
      this.controller.handleEvent({
        message: "ClearEncounter",
        params: { doctorRef: this.doctorRef.current },
      });
    }
  };

  handleToggleDocumentOrder = (data: any) => {
    if (data?.items.length > 0 && data?.items[0].patient_file) {
      this.setState({
        openModMessage: true,
        messageTitle: "ข้อมูลการรักษา",
        errorMessage: null,
        successMessage: data?.items[0].patient_file,
      });
    }
    this.controller.handleEvent({
      message: "ToggleDocumentOrder",
      params: { data, intl: this.props.intl },
    });
  };

  handleOpenEditDocument = (data: any = null, setParams = {}) => {
    data = data || this.state.openModEditDoc?.data;
    this.controller.handleEvent({
      message: "SetModEditDocument",
      params: { data, open: true, ...setParams },
    });
  };

  handleCloseEditDocument = () => {
    this.controller.handleEvent({
      message: "SetModEditDocument",
      params: { data: null, open: false },
    });
  };

  handleCheckIsSecret = ({ row, checked }: any = {}) => {
    this.controller.handleEvent({
      message: "ToggleCheckedIsSecret",
      params: { id: row.original.id, checked },
    });
  };

  handleCheckThumbnail = ({ data, checked }: any = {}) => {
    this.controller.handleEvent({
      message: "ToggleCheckedThumbnail",
      params: { id: data.documentId, checked, index: data.index },
    });
  };

  handleCheckSecretAll = (checked: boolean | undefined) => {
    this.controller.handleEvent({
      message: "ToggleCheckedIsSecretAll",
      params: { checked },
    });
  };

  handleCheckCancelSecretAll = (checked: boolean | undefined) => {
    this.controller.handleEvent({
      message: "ToggleCheckedIsCancelSecretAll",
      params: { checked },
    });
  };

  handleChangeDocumentCategory = async (
    value: any,
    searchText = "",
    isReturnOnly = false
  ) => {
    return await this.controller.handleEvent({
      message: "GetDocumentType",
      params: { value, searchText, isReturnOnly },
    });
  };

  handleSaveDocScan = () => {
    this.controller.handleEvent({
      message: "SaveDocumentScan",
      params: {},
    });
  };

  handleCancelDocScan = () => {
    this.controller.handleEvent({
      message: "handleClearDocScan",
      params: {
        doctorRef: this.doctorRef.current,
        encounterRef: this.encounterRef.current,
      },
    });
  };

  handleSaveItemScanned = () => {
    this.controller.handleEvent({
      message: "ConfirmSaveDocumentScan",
      params: {
        doctorRef: this.doctorRef.current,
        runningId: this.runningId,
        encounterRef: this.encounterRef.current,
        hnSearchRef: this.hnSearchRef,
      },
    });
  };

  handleRemoveScannedOrder = (row: any = {}) => {
    if (this.encounterRef.current) {
      this.encounterRef.current.clear();
    }
    this.controller.handleEvent({
      message: "RemoveScannedOrder",
      params: {
        data: row,
      },
    });
  };

  handleUpdateSecret = async ({ username, password }: any = {}) => {
    this.handleClickDocumentSecret();
    let text = this.encounterRef.current.getText() || "";
    if (text) {
      // text = text.match(/^\d+/i)[0]
    }
    const [r, e] = await this.controller.handleEvent({
      message: "PutScannedDocUpdateSecret",
      params: {
        username,
        password,
        items: this.state.selectedDocumentList,
        modSecretAuthenRef: this.modSecretAuthenRef,
        encounterDoctor: this.doctorRef.current.getId(),
        encounterNumber: text,
        encounterNumberId: this.encounterRef.current.getId(),
      },
    });
    this.modSecretAuthenRef.current.clear();
    return [r, e];
  };

  handleConfirmRemoveDocument = async ({
    username,
    password,
    cancelNote,
  }: any = {}) => {
    let text = this.encounterRef.current.getText() || "";
    if (text) {
      // text = text.match(/^\d+/i)[0]
    }
    this.controller.handleEvent({
      message: "ConfirmRemoveDocument",
      params: {
        username,
        password,
        items: this.state.openModCancelAuthen?.data,
        note: this.state.cancelNote,
        encounterDoctor: this.doctorRef.current.getId(),
        encounterNumber: text,
        encounterNumberId: this.encounterRef.current.getId(),
      },
    });
    this.modCancelRef.current.clear();
  };

  handleCancelDocument = async ({
    username,
    password,
    cancelNote,
    scanDocId,
  }: any = {}) => {
    // setIsLoading(true);
    const cancelError = {
      cancelError: null,
    };
    this.handleOpenEditDocument(null, cancelError);

    const [r, e] = await this.controller.handleEvent({
      message: "PatchScannedDocument",
      params: {
        data: {
          username,
          password,
          cancelNote,
          scanDocId,
          active: false,
        },
        keyError: "cancelError",
      },
    });

    if (!e) {
      this.handleClickSearchDocument();
    }
    return [r, e];
  };

  handleEditDocument = async ({
    username,
    password,
    scanDocId,
    documentType,
    documentNo,
    version,
    ownerDivision,
    documentDate,
    expiryDate,
    remark,
    encounterDoctor,
  }: any = {}) => {
    const editError = {
      editError: null,
    };
    this.handleOpenEditDocument(null, editError);
    const [r, e] = await this.controller.handleEvent({
      message: "PatchScannedDocument",
      params: {
        data: {
          username,
          password,
          scanDocId,
          documentType,
          documentNo,
          version,
          ownerDivision,
          documentDate,
          expiryDate,
          remark,
          encounterDoctor,
        },
        keyError: "editError",
      },
    });

    if (!e) {
      this.handleClickSearchDocument();
    }
    return [r, e];
  };

  handlePrintDocumentImage = (items: any) => {
    this.controller.handleEvent({
      message: "PrintDocumentImage",
      params: {
        items,
        intl: this.props.intl,
      },
    });
  };

  handleSaveBarcodeDocument = () => {
    this.controller.handleEvent({
      message: "ConfirmSaveDocumentBarcode",
      params: {
        doctorRef: this.doctorRef.current,
        runningId: this.runningId,
        inputNumberRef: this.inputNumberRef.current,
        encounterRef: this.encounterRef.current,
      },
    });
  };

  handleDoctorFilter = (list: any) => {
    console.log(list);
    this.controller.handleEvent({
      message: "SetDoctorFilter",
      params: {
        filter: list,
      },
    });
  };

  handlePageChange = (event: SyntheticEvent, data: any) => {
    this.setState({ activePage: data.activePage });
  };

  render = () => {
    const scannedDocLimit = this.state?.scannedDocLimit || 0;
    const dataLength = this.state.documentSearchData?.length || 0;
    const totalPages =
      scannedDocLimit > 0 ? Math.ceil(dataLength / scannedDocLimit) : 1;
    const pageDisable = dataLength <= scannedDocLimit;
    const offset = ((this.state.activePage || 0) - 1) * scannedDocLimit || 0;
    const searchData =
      scannedDocLimit < dataLength
        ? this.state.documentSearchData?.slice(offset, scannedDocLimit + offset)
        : this.state.documentSearchData;

    const currentDate = new Date();
    const year = (currentDate.getFullYear() + 543).toString().padStart(4, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const hour = currentDate.getHours().toString().padStart(2, "0");
    const min = currentDate.getMinutes().toString().padStart(2, "0");
    const stringDateTimeWaterMark =
      day + "/" + month + "/" + year + " " + hour + ":" + min;

    // console.log(this.state);
    // console.log("SCN render ",dataLength , this.state.scannedDocLimit, "totalPages", totalPages, "pageDisable", pageDisable, "offset", offset, "searchData", searchData)
    return (
      <div
        id="main-scn"
        style={{ height: "100%", marginLeft: "10px", marginRight: "10px" }}
      >
        {this.props.closeIcon && (
          <div
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              zIndex: 2000,
              cursor: "pointer",
            }}
            onClick={this.props.onClose}
          >
            <Icon name="close" size="big" color="red" />
          </div>
        )}
        {!this.state.loggedin ? null : (
          <div
            className={"scn-content " + this.state.scannedDocumentTab}
            style={{
              display: "grid",
              gridTemplateColumns: "10% 90%",
              gridTemplateRows: "45px 1fr",
              width: "100%",
              height: "100%",
            }}
          >
            {/* <Menu style={{ gridColumn: "1/3", marginBottom: 2 }}>
              <Menu.Item>
                <Button
                  fluid
                  icon={"home"}
                  size={"huge"}
                  className="noBoxShadow"
                  style={{ backgroundColor: "transparent" }}
                />
              </Menu.Item>
            </Menu> */}

            <div
              style={{ gridColumn: "1/3", height: "max-content" }}
              ref={(instance: any) => {
                if (instance) {
                  this.contentBounding.current =
                    instance.getBoundingClientRect();
                }
              }}
            >
              {/* <CardScannedDocument
            closeable={false}
            toggleable
            controller={this.controller.scannedDocumentController}
            patientData={this.state.patientData}
            patientInfo={this.state.patientInfo}
            django={this.state.django}
          /> */}

              {/* <div id="tongDiv"/> */}
              {/* <button onClick={
                () => {
                  const handleAllTest = async () => {
                    await barcodeScannerTest()
                  }
                  handleAllTest()
                } 
              }>  Test All Image that I have  </button> */}

              <ScannedDocumentUX
                onEvent={this.controller.handleEvent}
                setProp={SetProp(this, "")}
                tab={this.state.scannedDocumentTab}
                setScannedDocumentTab={this.handleScannedDocumentTab}
                documentCategoryOptions={this.state.documentCategoryOptions}
                documentCategory={this.state.documentCategory}
                documentTypeOptions={this.state.documentTypeOptions}
                documentType={this.state.documentType}
                documentScanDivisionOptions={
                  this.state.documentScanDivisionOptions
                }
                documentScanDivision={this.state.documentScanDivision}
                documentOwnerDivisionOptions={
                  this.state.scannedDocumentTab === "BarcodeScan" &&
                  !this.state.selectedDocScan
                    ? []
                    : this.state.documentOwnerDivisionOptions
                }
                doctorSearchOptions={this.state.doctorSearchOptions}
                doctorSearch={this.state.doctorSearch}
                documentOwnerDivision={this.state.documentOwnerDivision}
                documentDate={this.state.documentDate}
                pageOptions={pageOptions}
                scannedDocLimit={this.state.scannedDocLimit}
                scannedDocumentData={this.state.scannedDocumentData}
                barcodeDocumentData={this.state.barcodeDocumentData}
                selectedDocumentList={this.state.selectedDocumentList}
                documentSearchData={searchData}
                handleClickScanDocument={this.handleClickScanDocument}
                handleClickBarcodeScan={this.handleClickBarcodeScan}
                handleClickDocumentSecret={this.handleClickDocumentSecret}
                handleClickSearchDocument={this.handleClickSearchDocument}
                handleChangeDocLimit={this.handleChangeDocLimit}
                handleCheckSecretAll={this.handleCheckSecretAll}
                handleCheckCancelSecretAll={this.handleCheckCancelSecretAll}
                handleChangeDocumentCategory={this.handleChangeDocumentCategory}
                handleSaveDocScan={this.handleSaveDocScan}
                handleCancelDocScan={this.handleCancelDocScan}
                checkedSecretAll={this.state.checkedSecretAll}
                checkedCancelSecretAll={this.state.checkedCancelSecretAll}
                scannedRowProps={this.handleScannedRowProps}
                documentSearchRowProps={this.handleDocumentSearchRowProps}
                remark={this.state.remark}
                patientInfo={this.state.patientInfo}
                errMessage={this.state.errMessage}
                errMessageHN={this.state.errMessageHN}
                admisNumber={this.state.admisNumber}
                visitNumber={this.state.visitNumber}
                // contentBounding={this.contentBounding.current} ไม่ได้ใช้ ใน ScannedDocumentUX แล้ว
                statusDocumentSecret={this.state.statusDocumentSecret}
                documentNo={this.state.documentNo}
                patientBarcodeScan={this.state.patientBarcodeScan}
                patientHN={this.state.patientHN}
                patientFullName={this.state.patientFullName}
                patientEncounter={this.state.patientEncounter}
                patientId={this.state.patientId}
                disabledRequiredField={this.props.disabledRequiredField}
                inputReadOnly={
                  this.state.scannedDocumentTab === "BarcodeScan" &&
                  !this.state.selectedDocScan
                }
                requiredFieldError={this.state.requiredFieldError}
                handleClickEditBarcodeDocument={
                  this.handleClickEditBarcodeDocument
                }
                selectedDocScan={this.state.selectedDocScan}
                handleSaveBarcodeDocument={this.handleSaveBarcodeDocument}
                scannedTdProps={this.handleScannedTdProps}
                toggleBarcodeScan={this.state.toggleBarcodeScan}
                paginationElement={
                  !pageDisable ? (
                    <Pagination
                      activePage={this.state.activePage}
                      boundaryRange={0}
                      onPageChange={this.handlePageChange}
                      ellipsisItem={null}
                      firstItem={null}
                      lastItem={null}
                      siblingRange={1}
                      totalPages={totalPages}
                    />
                  ) : null
                }
                cardFilterDentistElement={
                  this.state.openModalFilter ? (
                    <ModalItemFilter
                      style={{ transform: "translate(225px, 47px)" }}
                      data={this.state?.doctorFilterList || []}
                      onClose={() => this.setState({ openModalFilter: false })}
                      textField="name"
                      onSelect={this.handleDoctorFilter}
                    />
                  ) : null
                }
                secretElement={{
                  checkboxCancel: this.state.isPermissionAdmin ? (
                    <Checkbox
                      label="ยกเลิกปกปิดทั้งหมด"
                      checked={this.state.checkedCancelSecretAll}
                      onChange={(e, { checked }) =>
                        this.handleCheckCancelSecretAll(checked)
                      }
                    />
                  ) : null,
                  checkboxSecret: this.state.isPermissionAdmin ? (
                    <Checkbox
                      label="ปกปิดทั้งหมด"
                      checked={this.state.checkedSecretAll}
                      onChange={(e, { checked }) =>
                        this.handleCheckSecretAll(checked)
                      }
                    />
                  ) : null,
                  buttonSecret: this.state.isPermissionAdmin ? (
                    <Button
                      color="red"
                      onClick={this.handleClickDocumentSecret}
                      disabled={this.state.selectedDocumentList.length === 0}
                    >
                      {this.state.statusDocumentSecret?.name}
                    </Button>
                  ) : null,
                }}
                elementThumbnailList={this.state.selectedThumbnailList?.map(
                  (data, index) => (
                    <Form
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      <Form.Group inline>
                        <Form.Checkbox
                          width={3}
                          checked={data.isChecked}
                          onChange={(e: any, { checked }: any) =>
                            this.handleCheckThumbnail({ data, checked })
                          }
                        ></Form.Checkbox>
                        <Form.Field width={13}>
                          <img
                            src={data.image}
                            width="125"
                            height="176.25"
                            style={{ border: "1px solid rgba(34,36,38,.15)" }}
                            alt={`thumbnail-image${index}`}
                          ></img>
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  )
                )}
                columnsScannedDocument={[
                  {
                    Header: "หน้า",
                    accessor: " ",
                    width: 50,
                    Cell: (row: any = {}) => {
                      return (
                        <div style={{ textAlign: "center" }}>
                          {row.index + 1}
                        </div>
                      );
                    },
                    // show: !props.readOnly,
                  },
                  {
                    Header: "ประเภทเอกสาร",
                    accessor: "document_category_name",
                    minWidth: 120,
                  },
                  {
                    Header: "ชื่อเอกสาร",
                    accessor: "document_type_name",
                    minWidth: 120,
                  },
                  {
                    Header: "ลบ",
                    accessor: "_remove",
                    minWidth: 50,
                    Cell: ({ original }: any = {}) => (
                      <div style={{ textAlign: "center" }}>
                        <Button
                          icon
                          color="red"
                          size="mini"
                          onClick={() =>
                            this.handleRemoveScannedOrder(original)
                          }
                        >
                          <Icon name="minus" />
                        </Button>
                      </div>
                    ),
                  },
                ]}
                columnsSearchDocument={[
                  {
                    Header: "",
                    accessor: " ",
                    width: 35,
                    Cell: ({ original }: any = {}) => {
                      return (
                        <div style={{ textAlign: "center" }}>
                          {original.items[0].document_image && (
                            <Checkbox
                              checked={
                                this.state.selectedDocumentList?.find(
                                  (doc: any) => doc.id === original.id
                                ) || false
                              }
                              onClick={() => {
                                this.handleToggleDocumentOrder(original);
                              }}
                            />
                          )}
                        </div>
                      );
                    },

                    // show: props.readOnly,
                  },
                  {
                    Header: "No.",
                    width: 40,
                    Cell: (row: any = {}) => {
                      return (
                        <div style={{ textAlign: "center" }}>
                          {row.original.id + 1}
                        </div>
                      );
                    },
                    // show: !props.readOnly,
                  },
                  {
                    Header: "Visit date",
                    accessor: " ",
                    minWidth: 80,
                    Cell: (row: any = {}) => {
                      let [dateComponent, timeComponent]: any = ["", ""];
                      if (row.original.items[0]?.visit_date_utc) {
                        [dateComponent, timeComponent] = formatUTCtoBEString(
                          row.original.items[0]?.visit_date_utc
                        );
                      }
                      return (
                        <div style={{ textAlign: "center" }}>
                          {`${dateComponent} ${timeComponent}`}
                          {row.original.items[0].encounter_type === "IPD"
                            ? " (IPD)"
                            : ""}
                        </div>
                      );
                    },
                  },
                  {
                    Header: "Document date",
                    accessor: " ",
                    minWidth: 80,
                    Cell: (row: any = {}) => {
                      return (
                        <div style={{ textAlign: "center" }}>
                          {row.original.items[0]?.document_date}
                        </div>
                      );
                    },
                  },
                  {
                    Header: "ประเภทเอกสาร",
                    accessor: " ",
                    minWidth: 110,
                    Cell: (row: any = {}) => (
                      <div style={{ textAlign: "center" }}>
                        {row.original.items[0].document_category_name ||
                          row.original.items[0].document_category_name_en}
                      </div>
                    ),
                  },
                  {
                    Header: "ชื่อเอกสาร",
                    accessor: " ",
                    minWidth: 105,
                    Cell: (row: any = {}) => (
                      <div style={{ textAlign: "center" }}>
                        {row.original.items[0].document_type_name ||
                          row.original.items[0].document_type_name_en}
                      </div>
                    ),
                  },
                  {
                    Header: (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {this.props.intl.formatMessage({ id: "cukey313" })}
                        <Icon
                          name="filter"
                          style={{
                            marginLeft: "5px",
                            cursor: "pointer",
                            zIndex: 2,
                          }}
                          onClick={() =>
                            this.setState({
                              openModalFilter: !this.state.openModalFilter,
                            })
                          }
                        />
                      </div>
                    ),
                    accessor: "dentist",
                    minWidth: 90,
                    Cell: (row: any = {}) => (
                      <div style={{ textAlign: "center" }}>
                        {row.original.items[0].doctor_fullname}
                      </div>
                    ),
                  },
                  {
                    Header: "จำนวนหน้า",
                    accessor: " ",
                    width: 65,
                    Cell: (row: any = {}) => (
                      <div style={{ textAlign: "center" }}>
                        {row.original.items.length}
                      </div>
                    ),
                  },
                  // {
                  //   Header: "ปกปิด",
                  //   accessor: "is_secret",
                  //   width: 55,
                  //   Cell: (row:any={}) => (
                  //     <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "4.5px" }}>
                  //     </div>
                  //   ),
                  // },
                  {
                    Header: "พิมพ์",
                    accessor: "_print",
                    show: this.state.isPermissionAdmin,
                    width: 45,
                    Cell: ({ original }: any = {}) => (
                      <Button
                        icon
                        color="black"
                        disabled={
                          original.items[0].document_image ? false : true
                        }
                        onClick={() =>
                          this.handlePrintDocumentImage(original.items)
                        }
                        size="mini"
                      >
                        <Icon name="print" />
                      </Button>
                    ),
                  },

                  {
                    Header: "แก้ไข",
                    accessor: "_edit",
                    show: this.state.isPermissionAdmin,
                    minWidth: 50,
                    Cell: ({ original }: any = {}) => (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          icon
                          color="yellow"
                          disabled={
                            original.items[0].document_image ? false : true
                          }
                          onClick={() => this.handleOpenEditDocument(original)}
                          size="mini"
                        >
                          <Icon name="pencil" />
                        </Button>
                      </div>
                    ),
                  },
                  {
                    Header: "ลบ",
                    accessor: "_remove",
                    show: this.state.isPermissionAdmin,
                    minWidth: 45,
                    Cell: ({ original }: any = {}) => (
                      <Button
                        icon
                        color="red"
                        size="mini"
                        disabled={
                          original.items[0].document_image ? false : true
                        }
                        onClick={() =>
                          this.handleSetModCancel({
                            open: true,
                            error: "",
                            data: original,
                          })
                        }
                      >
                        <Icon name="minus" />
                      </Button>
                    ),
                  },
                ]}
                columnsBarcodeScan={[
                  {
                    Header: "No.",
                    width: 40,
                    Cell: (row: any = {}) => {
                      // console.log(row:any={});
                      return (
                        <div style={{ textAlign: "center" }}>
                          {row.index + 1}
                        </div>
                      );
                    },
                    // show: !props.readOnly,
                  },
                  {
                    Header: (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {this.props.intl.formatMessage({ id: "cukey92" })}{" "}
                        <div style={{ color: "red" }}>*</div>
                      </div>
                    ),
                    accessor: "hn",
                    width: 80,
                    Cell: (row: any = {}) => {
                      return (
                        <div style={{ textAlign: "center" }}>
                          {row.original.hn}
                        </div>
                      );
                    },
                  },
                  {
                    Header: (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {this.props.intl.formatMessage({ id: "cukey335" })}{" "}
                        <div style={{ color: "red" }}>*</div>
                      </div>
                    ),
                    accessor: "document_category_name",
                    width: 120,
                  },
                  {
                    Header: (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {this.props.intl.formatMessage({ id: "cukey301" })}{" "}
                        <div style={{ color: "red" }}>*</div>
                      </div>
                    ),
                    accessor: "document_type_name",
                    width: 100,
                  },
                  {
                    Header: (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {this.props.intl.formatMessage({ id: "cukey224" })}{" "}
                        <div style={{ color: "red" }}>*</div>
                      </div>
                    ),
                    accessor: "visit_date",
                    width: 100,
                    Cell: (row: any = {}) => {
                      return (
                        <div
                          style={{
                            textAlign: "center",
                            ...(row.original.visit_date
                              ? {}
                              : {
                                  textDecorationLine: "line-through",
                                  color: "#b5b5b5",
                                }),
                          }}
                        >
                          {row.original.visit_date
                            ? row.original.visit_date
                            : row.original.placeholder_visit_date}
                        </div>
                      );
                    },
                  },

                  {
                    Header: (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {this.props.intl.formatMessage({ id: "cukey313" })}{" "}
                        <div style={{ color: "red" }}>*</div>
                      </div>
                    ),
                    accessor: "dentist",
                    minWidth: 80,
                    Cell: (row: any = {}) => {
                      return (
                        <div style={{ textAlign: "center" }}>
                          {row.original.encounter_doctor_name ||
                            row.original.encounter_doctor_full_name}
                        </div>
                      );
                    },
                  },
                  {
                    Header: (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {this.props.intl.formatMessage({ id: "cukey223" })}{" "}
                        <div style={{ color: "red" }}>*</div>
                      </div>
                    ),
                    accessor: "visit_number",
                    width: 80,
                    Cell: (row: any = {}) => {
                      return (
                        <div
                          style={{
                            textAlign: "center",
                            ...(row.original.encounter_number
                              ? {}
                              : {
                                  textDecorationLine: "line-through",
                                  color: "#b5b5b5",
                                }),
                          }}
                        >
                          {row.original.encounter_number
                            ? row.original.encounter_number
                            : row.original.placeholder_visit_number}
                        </div>
                      );
                    },
                  },
                  {
                    Header: "ลบ",
                    accessor: "_remove",
                    width: 45,
                    Cell: ({ original }: any = {}) => (
                      <div style={{ textAlign: "center" }}>
                        <Button
                          icon
                          color="red"
                          size="mini"
                          onClick={() =>
                            this.handleRemoveScannedOrder(original)
                          }
                        >
                          <Icon name="minus" />
                        </Button>
                      </div>
                    ),
                  },
                ]}
              >
                {[1, 2].map((value) => {
                  const ref =
                    value === 1 ? this.hnSearchRef : this.hnBarcodeRef;
                  return (
                    <PatientSearchBoxCU
                      ref={ref}
                      key={`patient_search_box_${value}`}
                      controller={this.patientSearchCUController}
                      onSelectPatient={this.handleOnSelectPatient}
                      onEnter={this.handleOnSelectPatient}
                      systemHn8Digit={true}
                      clearHnBeforeSearch={true}
                    />
                  );
                })}
                {/* strict position */}
                {Object.keys(this.state.documentDate || {}).map((key) => {
                  const dateValue = (this.state.documentDate as any)?.[key];
                  return (
                    <DateTextBox
                      ref={(instance) => {
                        if (instance?.datePickerRef) {
                          instance.datePickerRef.input.ref.inputRef.current.parentElement.classList.add(
                            "fluid"
                          );
                        }
                      }}
                      style={{
                        width: "100%",
                      }}
                      onChange={(date: string) => {
                        this.controller.handleEvent({
                          message: "ChangeDocumentDate",
                          params: {
                            key,
                            date,
                          },
                        });
                      }}
                      key={key}
                      value={dateValue}
                      disabled={
                        (this.state.scannedDocumentTab === "BarcodeScan" &&
                          !this.state.selectedDocScan &&
                          ["startExpiry", "startDoc"].includes(key)) ||
                        (key === "startVisit" &&
                          this.state.scannedDocumentTab === "BarcodeScan")
                      }
                      inputStyle={{ opacity: "1" }}
                    />
                  );
                })}
                <DateTextBox
                  ref={(instance) => {
                    if (instance?.datePickerRef) {
                      instance.datePickerRef.input.ref.inputRef.current.parentElement.classList.add(
                        "fluid"
                      );
                    }
                  }}
                  style={{
                    width: "100%",
                  }}
                  onChange={(date: any) => {
                    // this.controller.handleEvent({
                    //   message: "ChangeDocumentDate",
                    //   params: {
                    //     key,
                    //     date,
                    //   },
                    // });
                  }}
                  // key={key}
                  // value={dateValue}
                />
                <SearchBox
                  ref={this.doctorRef}
                  // defaultId={this.doctorRef.current?.getId()}
                  // defaultText={this.doctorRef.current?.getText()}
                  key="searchBoxDoctor"
                  onGetSearchOptions={this.handleGetSearchOptions}
                  textField="name_code"
                  readOnly={this.state.scannedDocumentTab === "BarcodeScan"}
                  fluid={true}
                />
                <Input
                  key="inputVisitNumber"
                  ref={(instance: any) => {
                    if (instance?.inputRef) {
                      this.inputNumberRef.current = instance.inputRef.current;
                    }
                  }}
                  // readOnly={this.state.selectedDocScan?.encounter_number || !this.state.selectedDocScan ? true : false}
                  readOnly={
                    this.state.scannedDocumentTab === "BarcodeScan" &&
                    !this.state.selectedDocScan
                  }
                />
                {/* <SearchBox
                  ref={this.encounterRef}
                  // defaultId={this.doctorRef.current?.getId()}
                  // defaultText={this.doctorRef.current?.getText()}
                  key="searchBoxEncounter"
                  onGetSearchOptions={this.handleGetSearchEncounter}
                  textField={"number_started"}
                /> */}
                <ModalSearchEncounter
                  key="searchBoxEncounter"
                  ref={this.encounterRef}
                  fluid
                  onGetEncounter={this.handleGetEncounterList}
                  onSelect={this.handleSelectEncounter}
                  onClearInput={this.handleClearEncounter}
                  patientId={this.state.patientInfo?.id || this.state.patientId}
                  readOnly={
                    this.state.scannedDocumentTab === "BarcodeScan" &&
                    (!this.state.selectedDocScan || !this.state.patientId)
                  }
                  disabledSearch={
                    this.state.scannedDocumentTab === "BarcodeScan" &&
                    (!this.state.selectedDocScan || !this.state.patientId)
                  }
                />
                <ModEditDocument
                  key="modEditDocument"
                  // ref={this.modEditDocRef}
                  open={this.state.openModEditDoc?.open}
                  onIconClose={this.handleCloseEditDocument}
                  data={this.state.openModEditDoc?.data}
                  onGetDocumentTypeOptions={({
                    searchText = "",
                    documentCategory = "",
                  }) =>
                    this.handleChangeDocumentCategory(
                      documentCategory,
                      searchText,
                      true
                    )
                  }
                  divisionOptions={this.state.openModEditDoc?.options}
                  categoryOptions={this.state.openModEditDoc?.categoryOptions}
                  onCancelApprove={this.handleCancelDocument}
                  onEditApprove={this.handleEditDocument}
                  cancelDocError={this.state.openModEditDoc?.cancelError}
                  editDocError={this.state.openModEditDoc?.editError}
                  loading={this.state.isLoadingModal}
                  closeIcon={true}
                  hideButtonCancel={true}
                  buttonEditTextTh={true}
                  showDocumentCategory={true}
                  onGetSearchOptions={this.handleGetSearchOptions}
                  size="small"
                />
                <ModInfo
                  open={this.state.openModError?.open}
                  titleColor={this.state.openModError?.color || "red"}
                  key="modError"
                  titleName={this.state.openModError?.title}
                  onApprove={this.handleCloseModInfo}
                  onClose={this.handleCloseModInfo}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.openModError?.error,
                    }}
                  />
                </ModInfo>
                <ModSecretAuthen
                  key="modSecretAuthen"
                  ref={this.modSecretAuthenRef}
                  open={this.state.openModSecretAuthen?.open}
                  titleName={this.state.statusDocumentSecret?.name}
                  approveButtonText="ตกลง"
                  denyButtonText="ปิด"
                  onApprove={this.handleUpdateSecret}
                  onClose={this.handleCloseModSecret}
                  onDeny={this.handleCloseModSecret}
                  // onCloseWithDimmerClick={this.handleCloseModSecret}
                  loading={this.state.isLoadingModal}
                  error={this.state.openModSecretAuthen?.error}
                />
                <ModCancelAuthen
                  key="modCancelAuthen"
                  ref={this.modCancelRef}
                  open={this.state.openModCancelAuthen?.open}
                  approveButtonText="ตกลง"
                  denyButtonText="ปิด"
                  titleColor="red"
                  titleName="ลบเอกสาร"
                  onDeny={this.handleSetModCancel}
                  error={this.state.openModCancelAuthen?.error}
                  loading={this.state.isLoadingModal}
                  onClose={this.handleSetModCancel}
                  // onCloseWithDimmerClick={this.handleSetModCancel}
                  onApprove={this.handleConfirmRemoveDocument}
                >
                  <Form.Field
                    label="เหตุผลในการลบ"
                    error={this.state.openModCancelAuthen?.error ? true : false}
                  />
                  <Form.Field
                    error={this.state.openModCancelAuthen?.error ? true : false}
                  >
                    <TextArea
                      value={this.state.cancelNote}
                      onChange={(e, input) =>
                        this.setState({
                          cancelNote: input.value?.toString(),
                        })
                      }
                    />
                  </Form.Field>
                </ModCancelAuthen>
                <ModConfirmSave
                  key="modConfirmSave"
                  titleColor="yellow"
                  openModal={this.state.openModConfirmSave}
                  content={
                    <div>
                      {this.props.intl.formatMessage({ id: "cukey490" })}
                    </div>
                  }
                  onDeny={() => this.setState({ openModConfirmSave: false })}
                  onApprove={this.handleSaveItemScanned}
                  onCloseWithDimmeClick={() =>
                    this.setState({ openModConfirmSave: false })
                  }
                />
                <ModConfirmSave
                  key="modConfirmEdit"
                  titleColor="yellow"
                  openModal={this.state.openModConfirmEdit}
                  content={
                    <div style={{ textAlign: "center" }}>
                      {this.props.intl.formatMessage({ id: "cukey363" })}
                    </div>
                  }
                  onDeny={() => this.setState({ openModConfirmEdit: false })}
                  onApprove={this.handleConfirmEditBarcodeDocument}
                  onCloseWithDimmeClick={() =>
                    this.setState({ openModConfirmEdit: false })
                  }
                />
                <ErrorMessage
                  key="errorMessage1"
                  error={this.state.errMessage}
                />
                <ErrorMessage
                  // @ts-ignore
                  className="test"
                  key="errorMessageHN"
                  error={this.state.errMessageHN}
                  style={{
                    marginBottom: "5px",
                  }}
                />
                <Segment
                  id="container"
                  style={{ height: "100%", padding: "0" }}
                  className="grey inverted"
                  key="viewerDocument"
                >
                  {/* {this.state.scannedDocumentTab === "NewScan" &&
                    (this.state.selectedDocScan?.base64DataUri || this.state.previewPdf?.blobUrl || this.state.previewPdf?.base64Uri) && (
                      <>
                        <div className="water-mark-1">
                          <p> {this.props.intl.formatMessage({ id: "cukey467" })} </p>
                          <p> {this.props.intl.formatMessage({ id: "cukey266" })} </p>
                          <p>
                            {" "}
                            {this.props.intl.formatMessage({ id: "cukey443" })} {this.state.django?.user?.full_name ?? ""} {stringDateTimeWaterMark}{" "}
                          </p>
                        </div>
                        <div className="water-mark-2">
                          <p> {this.props.intl.formatMessage({ id: "cukey467" })} </p>
                          <p> {this.props.intl.formatMessage({ id: "cukey266" })} </p>
                          <p>
                            {" "}
                            {this.props.intl.formatMessage({ id: "cukey443" })} {this.state.django?.user?.full_name ?? ""} {stringDateTimeWaterMark}{" "}
                          </p>
                        </div>
                      </>
                    )} */}
                  <FrameChrome
                    className="frame-chrome"
                    file={
                      this.state.selectedDocScan?.base64DataUri ||
                      this.state.previewPdf?.blobUrl ||
                      this.state.previewPdf?.base64Uri
                    }
                  />
                </Segment>
              </ScannedDocumentUX>
            </div>
          </div>
        )}
        {this.state.loggedin && (
          <Dimmer
            style={{ height: this.contentBounding.current?.height + "px" }}
            key="loading"
            active={this.state.isLoading}
            inverted
          >
            <Loader inverted>
              {this.state.successfulPageScan !== "" &&
              this.state.totalPageNumber !== ""
                ? `${this.state.successfulPageScan} / ${this.state.totalPageNumber}`
                : "Loading"}
            </Loader>
          </Dimmer>
        )}
        <ModConfirmSave
          key="modConfirm"
          closeIcon={true}
          titleColor={this.state.errorMessage ? "red" : "green"}
          openModal={this.state.openModMessage}
          titleName={this.state.messageTitle}
          content={
            <ErrorMessage
              error={this.state.errorMessage}
              success={this.state.successMessage}
            />
          }
          onDeny={() => this.setState({ openModMessage: false })}
          onApprove={() => this.setState({ openModMessage: false })}
          onCloseWithDimmeClick={() => this.setState({ openModMessage: false })}
          approveButtonText={"ตกลง"}
          approveButtonColor={this.state.errorMessage ? "red" : "green"}
          denyButtonText={""}
        />
      </div>
    );
  };
}

const pageOptions = [
  {
    key: 10,
    value: 10,
    text: 10,
  },
  {
    key: 20,
    value: 20,
    text: 20,
  },
  {
    key: 30,
    value: 30,
    text: 30,
  },
  {
    key: 40,
    value: 40,
    text: 40,
  },
  {
    key: 50,
    value: 50,
    text: 50,
  },
  {
    key: 100,
    value: 100,
    text: 100,
  },
];

const FrameChrome = (props: any) => {
  if (props.file) {
    let frame =
      `
      
      
      <iframe 
        id="iframe"
        src="
    ` +
      props.file +
      `" width="100%" height="100%" >

    </iframe>
    `;

    return (
      <div
        style={{ width: "100%", height: "100%" }}
        dangerouslySetInnerHTML={{ __html: frame }}
      />
    );
  } else {
    return <></>;
  }
};

export default injectIntl(SCN);
