import moment from "moment";
import { formatDate } from "react-lib/utils/dateUtils";

const FormORCaseList = (props: any) => {
  return {
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 12,
    },
    pageMargins: [15, 125, 0, 50],
    pageSize: "A4",
    pageOrientation: "landscape",
    styles: {
      title: {
        bold: true,
        fontSize: 20,
        alignment: "center",
      },
      header: {
        fontSize: 18,
        alignment: "center",
      },
      tableHeader: {
        fontSize: 16,
        alignment: "center",
      },
      tableDetail: {
        fontSize: 14,
      },
      addressRightStyle: {
        fontSize: 12,
        alignment: "right",
      },
      addressLeftStyle: {
        fontSize: 12,
        alignment: "left",
      },
    },
    header: {
      margin: [40, 20, 20, 20],
      stack: [
        {
          columns: [
            {
              width: "50%",
              stack: [{ image: "logochula_dent", width: 150 }],
            },
            {
              width: "50%",
              stack: [
                {
                  text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
                  style: "addressRightStyle",
                },
                {
                  text: [
                    {
                      text: "ที่อยู่ : ",
                      style: "addressRightStyle",
                      bold: true,
                    },
                    {
                      text: "34  ถนนอังรีดูนังต์  แขวงวังใหม่  เขตปทุมวัน",
                      style: "addressRightStyle",
                    },
                  ],
                },
                {
                  text: "กรุงเทพมหานคร  10330  โทร 0 2218 8635",
                  style: "addressRightStyle",
                },
              ],
            },
          ],
        },
        {
          text: "รายงานสถิติการใช้บริการห้องผ่าตัด",
          style: "title",
        },
        {
          text: `วันที่ ${props?.start_date} - ${props?.end_date}`,
          style: "header",
        },
      ],
    },
    content: [
      {
        margin: [20, 0, 10, 10],
        table: {
          widths: ["*", "10%", "10%", "10%", "10%", "10%"],
          body: [
            [
              { text: "Location/Room", style: "tableHeader", bold: true },
              { text: "Cases", style: "tableHeader", bold: true },
              { text: "Anesthesia", style: "tableHeader", bold: true },
              { text: "Age range", style: "tableHeader", bold: true },
              { text: "Gender", style: "tableHeader", bold: true },
              { text: "QTY", style: "tableHeader", bold: true },
            ],
            ...(props?.data || []).map((item: any, index: number) => [
              {
                text: item.locationRoom,
                style: "tableDetail",
              },
              {
                text: item.caseText,
                style: "tableDetail",
                alignment: "center",
              },
              {
                text: item.anesthesia_method_name,
                style: "tableDetail",
                alignment: "center",
              },
              {
                text: item.ageRangeText,
                style: "tableDetail",
                alignment: "center",
              },
              { text: item.gender, style: "tableDetail", alignment: "center" },
              { text: item.count, style: "tableDetail", alignment: "center" },
            ]),
          ],
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      const printDateMM = moment();
      return {
        stack: [
          {
            margin: [40, 0, 20, 0],
            columns: [
              {
                width: "50%",
                stack: [
                  {
                    text: `วันเวลาที่พิมพ์ : ${formatDate(
                      printDateMM
                    )} [${printDateMM.format("HH:mm")}]`,
                    style: "addressLeftStyle",
                  },
                  {
                    text: `ผู้ออกรายงาน : ${props?.print_user || ""}`,
                    style: "addressLeftStyle",
                  },
                ],
              },
              {
                width: "50%",
                stack: [
                  {
                    text: `หน้าที่ ${currentPage} / ${pageCount}`,
                    style: "addressRightStyle",
                  },
                ],
              },
            ],
          },
        ],
      };
    },
    images: {
      logochula_dent: origin + "/static/images/logochula_dent.jpg",
    },
  };
};

export default FormORCaseList;
