import WasmController from "react-lib/frameworks/WasmController";

// APIs
import AdmitOrderListPostponeReportPrint from "issara-sdk/apis/AdmitOrderListPostponeReportPrint_apps_ADM";
import AdmitOrderListPrint from "issara-sdk/apis/AdmitOrderListPrint_apps_ADM";
import RoomList from "issara-sdk/apis/RoomList_apps_ADM";
import AdmitOrderList from "issara-sdk/apis/AdmitOrderList_apps_ADM";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import DivisionList from "issara-sdk/apis/DivisionList_core";
import AdmitOrderRoomItemDetail from "issara-sdk/apis/AdmitOrderRoomItemDetail_apps_ADM";
import CurrentRoomByPatient from "issara-sdk/apis/CurrentRoomByPatient_apps_ADM";

import * as SetBed from "./sequence/SetBed";
import * as WardQueue from "./sequence/WardQueue";
import * as InpatientDiagnosis from "./sequence/InpatientDiagnosis";
import * as PreAssessment from "./sequence/PreAssessment";
import * as ReAssessment from "./sequence/ReAssessment";
import * as ReportPatientIPDList from "./sequence/ReportPatientIPDList";
import * as ReportPatientList from "./sequence/ReportPatientList"

export type State = {
  admitOrderId?: number;
} & SetBed.State &
  WardQueue.State &
  InpatientDiagnosis.State &
  PreAssessment.State &
  ReAssessment.State &
  ReportPatientIPDList.State;

export const StateInitial: State = {
  ...SetBed.StateInitial,
  ...WardQueue.StateInitial,
  ...InpatientDiagnosis.StateInitial,
  ...PreAssessment.StateInitial,
  ...ReAssessment.StateInitial,
  ...ReportPatientIPDList.StateInitial,
  ...ReportPatientList.StateInitial
};

export type Event =
  | { message: "DidMount"; params: any }
  // GET
  | { message: "GetListOrderDivision"; params: any }
  | { message: "GetListRoom"; params: any }
  | { message: "GetListAdmitOrder"; params: any }
  | { message: "GetCurrentRoomByPatient"; params: any }
  // PRINT
  | { message: "PrintReportAdmitOrder"; params: any }
  | { message: "PrintReportPostponeAdmitOrder"; params: any }
  | { message: "HandleSelectAdmitEncounter"; params: any }
  | SetBed.Event
  | WardQueue.Event
  | InpatientDiagnosis.Event
  | PreAssessment.Event
  | ReAssessment.Event
  | ReportPatientIPDList.Event
  | ReportPatientList.Event;

export type Data = {
  division?: number;
} & SetBed.Data &
  WardQueue.Data &
  InpatientDiagnosis.Data &
  PreAssessment.Data &
  ReAssessment.Data &
  ReportPatientIPDList.Data &
  ReportPatientList.Data;

export const DataInitial = {
  ...SetBed.DataInitial,
  ...WardQueue.DataInitial,
  ...InpatientDiagnosis.DataInitial,
  ...PreAssessment.DataInitial,
  ...ReAssessment.DataInitial,
  ...ReportPatientIPDList.DataInitial,
  ...ReportPatientList.DataInitial
};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const DidMount: Handler = async (controller, params) => {};

export const HandleSelectAdmitEncounter: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  const [[enRes, enError], [admitRes, admitError]] = await Promise.all([
    EncounterDetail.retrieve({
      pk: params.encounterId,
      apiToken: controller.apiToken,
    }),
    AdmitOrderRoomItemDetail.retrieve({
      pk: params.admitRoom.room_item_id,
      apiToken: controller.apiToken,
    }),
  ]);

  if (enError) {
    console.log("HandleSelect Admit Encounter error:", enError);
    if (params.card) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: enError },
        },
      });
    }
    return;
  }

  await controller.handleEvent({
    message: "SelectEncounter" as any,
    params: { encounter: enRes || null, admitRoom: admitRes , callForm: "WARD"},
  });
};

export const GetListOrderDivision: Handler = async (controller, params) => {
  const result = await DivisionList.list({
    apiToken: controller.apiToken,
    params,
  });

  return result;
};

export const GetListRoom: Handler = async (controller, params) => {
  const result = await RoomList.list({
    apiToken: controller.apiToken,
    params,
  });

  return result;
};

export const GetListAdmitOrder: Handler = async (controller, params) => {
  const result = await AdmitOrderList.list({
    apiToken: controller.apiToken,
    params,
  });

  return result;
};

export const GetCurrentRoomByPatient: Handler = async (controller, params) => {
  const result = await CurrentRoomByPatient.get({
    apiToken: controller.apiToken,
    pk: params.patient,
  });

  return result;
};

export const PrintReportAdmitOrder: Handler = async (controller, params) => {
  const state = controller.getState();

  await AdmitOrderListPrint.post({
    apiToken: controller.apiToken,
    data: {
      items: state.SetBedSequence?.reserveBedQueueList || [],
    },
    extra: {
      division: controller.data.division,
    },
  });
};

export const PrintReportPostponeAdmitOrder: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  await AdmitOrderListPostponeReportPrint.post({
    apiToken: controller.apiToken,
    data: {
      items: state.SetBedSequence?.reserveBedQueueList || [],
    },
    extra: {
      division: controller.data.division,
    },
  });
};
