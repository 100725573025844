import moment from "moment";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";

const COLORS = {
  primary: "#5dbcd2",
  blue: "#063bab",
  light_blue: "#b2f1ff",
  grey: "#d9d9d9",
};

// Const
const momentDate = moment();

const FormIssueStock = (props: any) => {
  console.log("FormIssueStock", props);

  return {
    pageSize: "A4",
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 18,
    },
    pageMargins: [25, !!props.providerName ? 330 : 300, 30, 30],
    styles: {
      fieldHeader: {
        fontSize: 28,
        bold: true,
        color: COLORS.blue,
      },
      tableHeader: {
        fontSize: 23,
        bold: true,
      },
      fieldKey: {
        bold: true,
        fontSize: 23,
      },
      fieldValue: {
        fontSize: 23,
      },
      miniFieldKey: {
        bold: true,
        fontSize: 18,
      },
      miniFieldValue: {
        fontSize: 18,
      },
      footerValue: {
        fontSize: 18,
      },
    },
    header: (currentPage: number, pageCount: number) => {
      return {
        margin: [25, 20, 25, 0],
        stack: [
          {
            text: "ใบตัดจ่ายสินค้า",
            style: "fieldHeader",
            alignment: "center",
          },
          {
            layout: "noBorders",
            table: {
              widths: ["100%"],
              heights: 1,
              body: [[{ text: "", fillColor: COLORS.primary }]],
            },
          },
          {
            margin: [0, 10, 0, 0],
            alignment: "right",
            stack: [
              {
                svg: `<svg width="240" height="30">
            <rect width="240" height="30" style="fill:${COLORS.light_blue};"  rx="5"/>
          </svg>`
              },
              {
                columns: [
                  "เลขที่ใบตัดจ่ายสินค้า: ",
                  { text: props.code, bold: false, width: 90, alignment: "left", marginLeft: 5 }
                ],
                style: "fieldKey",
                relativePosition: { x: -5.5, y: -27.5 }
              }
            ],
          },
          {
            margin: [0, 25, 0, 0],
            stack: [
              [
                ["วันที่ตัดจ่าย: ", props.datetime],
                ["", ""],
              ],
              [
                ["แผนกผู้ตัดจ่าย: ", props.divisionName],
                ["ผู้ตัดจ่าย: ", props.userName],
              ],
              [
                ["เหตุผลในการตัดจ่าย: ", props.reason || "-"],
                ["", ""],
              ],
              [
                [
                  !!props.providerName ? "ตัดจ่ายไปยัง: " : "",
                  props.providerName,
                ],
                ["", ""],
              ],
              [
                ["หมายเหตุเพิ่มเติม: ", props.remark || "-"],
                ["", ""],
              ],
            ].map((item) => ({
              columns: [
                {
                  width: "60%",
                  text: [item[0][0], { text: item[0][1], bold: false }],
                  style: "fieldKey",
                },
                {
                  width: "*",
                  text: [item[1][0], { text: item[1][1], bold: false }],
                  style: "fieldKey",
                },
              ],
            })),
          },
          {
            margin: [0, 20, 0, 0],
            text: "รายการสินค้า",
            style: "fieldKey",
          },
        ],
      };
    },
    content: [
      {
        table: {
          headerRows: 1,
          widths: ["15%", "30%", "15%", "20%", "10%", "10%"],
          body: [
            [
              { text: "Code", style: "tableHeader", fillColor: COLORS.grey },
              { text: "Name", style: "tableHeader", fillColor: COLORS.grey },
              {
                text: "Lot No.",
                style: "tableHeader",
                alignment: "center",
                fillColor: COLORS.grey,
              },
              {
                text: "Expiry date",
                style: "tableHeader",
                alignment: "center",
                fillColor: COLORS.grey,
              },
              {
                text: "จำนวน",
                style: "tableHeader",
                alignment: "center",
                fillColor: COLORS.grey,
              },
              {
                text: "รวม",
                style: "tableHeader",
                alignment: "center",
                fillColor: COLORS.grey,
              },
            ],
            ...props.items.map((item: any) => [
              { text: item.code, style: "fieldValue", rowSpan: item.rowSpan },

              { text: item.name, style: "fieldValue", rowSpan: item.rowSpan },
              { text: item.lot_no, style: "fieldValue", alignment: "center" },
              {
                text: item.expire_date,
                style: "fieldValue",
                alignment: "center",
              },
              {
                text: item.quantity,
                style: "fieldValue",
                alignment: "center",
              },
              { text: item.total, style: "fieldValue", alignment: "center" },
            ]),
          ],
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        margin: [25, 0, 25, 0],
        stack: [
          {
            table: {
              widths: ["100%"],
              body: [[{ text: "", border: [false, true, false, false] }]],
            },
          },
          {
            columns: [
              {
                width: "auto",
                text: "พิมพ์เอกสารโดย งานของยาผู้ป่วยนอก",
                style: "footerValue",
              },
              {
                width: "*",
                margin: [15, 0, 0, 0],
                text: formatDatetime(momentDate),
                style: "footerValue",
              },
              {
                width: "auto",
                text: `หน้าที่ ${currentPage}/${pageCount}`,
                style: "footerValue",
              },
            ],
          },
        ],
      };
    },
    images: {
      logochula: origin + "/static/images/logochula.png",
    },
  };
};

export default FormIssueStock;
