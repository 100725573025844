import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Modal,
  Button,
  Icon,
  Checkbox,
  Label,
  TextArea,
  Popup,
  Form,
  Input,
} from "semantic-ui-react";

import htmlParse from "html-react-parser";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

// component
import CardDrugOrderUX from "./CardDrugOrderUX";
import ModCheckADR from "./ModCheckADRUX";
import ModCheckDI from "./ModCheckDIUX";
import ModCheckDoctorCertificateUX from "./ModCheckDoctorCertificateUX";
import ModCertificateErrorUX from "./ModCertificateErrorUX";
import ModDrugLabInteraction from "./ModDrugLabInteraction";
import ModDrugInteraction from "./ModDrugInteraction";
import CardDrugClaimQA from "./CardDrugClaimQA";
import ModSolventSelection from "./ModSolventSelection";
import { ErrorMessage } from "react-lib/apps/common";
import SearchBox from "react-lib/appcon/common/SearchBox";
// import { DeleteColumn } from "../DFC/CardDoctorFeeOrderAndCompensation";
import DropDownColumn from "react-lib/appcon/common/DropDownColumn";
import EditorColumn from "react-lib/appcon/common/EditorColumn";
import ModConfirmDelete from "react-lib/apps/common/ModConfirm";
import DeleteColumn from "react-lib/appcon/common/DeleteColumn";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import SubDrugOrderStatusAlert, { TaperPopup } from "./SubDrugOrderStatusAlert";
import CardDrugOrderHistory from "./CardDrugOrderHistory";
import ModReMedicationUX from "./ModReMedicationUX";
import ModRecontinueMed from "./ModRecontinueMed";
import CardDuplicateDrugOrders from "./CardDuplicateDrugOrders";
import CardDuplicateDrugClassificationOrders from "./CardDuplicateDrugClassificationOrders";
import ModDrugAllVerify from "./ModDrugAllVerify";
import ModDescriptionUD from "./ModDescriptionUD";
import ModDrugPackageAlert from "./ModDrugPackageAlert";

import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";

// DFC/CardDoctorFeeOrderAndCompensation'

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";

// Interface
import {
  EstimateMap,
  CARD_DUPLICATE_REASON,
  CARD_DUPLICATE_CLASSIFICATION_REASON,
} from "./TPDInterface";

// CONFIG
import config from "config/config";
import ModDrugDisease from "./ModDrugDisease";
import ModDrugOrderTemplate from "./ModDrugOrderTemplate";
import ModDrugFact from "./ModDrugFact";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

const API_HOST = `${config.API_HOST}`;

export const CARD_DRUG_ORDER_ID: string = "CardDrugOrder";

const CARD_DRUG_ORDER = "CardDrugOrder";
const CARD_REJECT_ORDER = "CardRejectOrder";

const SEMANTIC_COLOR: any[] = [
  "red",
  "orange",
  "yellow",
  "olive",
  "green",
  "teal",
  "blue",
  "violet",
  "purple",
  "pink",
  "brown",
];

const SOLVENT_MODE: any = {
  ADD_SOLVENT: "ADD_SOLVENT",
  EDIT_SOLVENT: "EDIT_SOLVENT",
};

const DRUG_ORDER_TYPE = null;

const CardDrugOrder = (props: any) => {
  const [orderType, setOrderType] = useState("");
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [statusRequested, setStatusRequested] = useState<boolean>(true);
  const [orderName, setOrderName] = useState<any>("");
  const [openModDrugHistory, setOpenModDrugHistory] = useState<boolean>(false);
  // factsheet
  const [openModFactSheet, setOpenModFactSheet] = useState<boolean>(false);
  // med reconcile duplicate
  const [modMRDuplicate, setModMRDuplicate] = useState<any>({
    open: false,
    message: "",
  });

  const [modPharmaConfirm, setModPharmaConfirm] = useState<any>({
    open: false,
    note: "",
  });
  const [modDrugDesc, setModDrugDesc] = useState<{
    open?: boolean;
    desc?: string;
    index?: number;
  }>({});

  const ORDER_TYPES: { [key: string]: string } = {
    // OPD
    HOME_OPD: "Home Med OPD",
    ONE_DOSE_OPD: "One Dose OPD",

    // IPD
    HOME_IPD: "Home Med IPD",
    ONE_DOSE_IPD: "One Dose IPD ",
    ONE_DAY_IPD: "One Day",
    STAT_IPD: "Stat IPD",

    // OPD , IPD (emr ทั่งสองอัน)
    STAT_OPD: "Stat",
    OPERATING: "OPERATING",
  };

  const VERBAL_ORDER_MAPPED: { [key: string]: string } = {
    DRUG_ORDER_ONE_DOSE__OPD: "ONE_DOSE_OPD",
    DRUG_ORDER_HOME_OPD: "HOME_OPD",
    DRUG_ORDER_HOME_IPD: "HOME_IPD",
    DRUG_ORDER_ONE_DOSE__IPD: "ONE_DOSE_IPD",
    DRUG_ORDER_ONE_DAY: "ONE_DAY_IPD",
  };

  /* ------------------------------------------------------ */

  /*                       USE EFFECT                       */

  /* ------------------------------------------------------ */
  useEffect(() => {
    // console.log(" useEffect !!! clear ")
    props.runSequence?.({
      sequence: "DrugSelect",
      restart: true,
      defaultOrderBy: props.defaultOrderBy,
    });

    return () => {
      props.onEvent?.({
        message: "ClearDrugOrderForm",
        params: { hard: true, card: CARD_DRUG_ORDER_ID },
      });
    };
  }, []);

  useEffect(() => {
    setStatusRequested(props.isCardRejectOrder || props.isEditable);
  }, [props.isEditable]);

  useEffect(() => {
    if (
      props.errorMessage?.[CARD_REJECT_ORDER] === null &&
      props.isCardRejectOrder
    ) {
      props.runSequence({ sequence: "RejectOrder", restart: true });
      props.setOpenModDrug(false);
    }
  }, [props.errorMessage?.[CARD_REJECT_ORDER]]);

  useEffect(() => {
    let drugOperatingOrderIdJoin =
      props.preOrderList
        ?.filter((item: any) => item.type === "drugoperatingorder")
        ?.map((item: any) => item.id)
        ?.join(",") || "";

    if (
      orderType === "OPERATING" &&
      props.preOrderList?.length > 0 &&
      !drugOperatingOrderIdJoin
    ) {
      props.onEvent({
        message: "ClearDrugOperatingOrderList",
        params: {
          orderType: orderType,
          card: CARD_DRUG_ORDER_ID,
        },
      });
    }
  }, [props.selectedAppointment?.id]);

  // console.log(" CardDrugORder props.drugOperatingOrderId ",  props.drugOperatingOrderId)
  useEffect(() => {
    // console.log('useEffect CardDrugOrder props.drugOperatingOrderId: ', props.drugOperatingOrderId);
    // console.log("useEffect CardDrugOrder orderType: ", orderType, props.selectedEmr, props.selectedProgressCycle);
    // console.log("useEffect CardDrugOrder preOrderList: ", props.preOrderList);
    if (
      !orderType ||
      (["ONE_DOSE_OPD", "STAT_OPD"].includes(orderType) &&
        Object.keys(props.selectedVerbalDrugOrderWorking || {})?.length === 0 &&
        !props.selectedProgressCycle?.id)
    ) {
      return;
    }

    let drugOperatingOrderIdJoin =
      props.preOrderList
        ?.filter((item: any) => item.type === "drugoperatingorder")
        ?.map((item: any) => item.id)
        ?.join(",") || "";

    if (
      orderType === "OPERATING" &&
      props.preOrderList?.length > 0 &&
      drugOperatingOrderIdJoin
    ) {
      // console.log("useEffect called GetDrugOrderList")
      props.onEvent({
        message: "GetDrugOrderList",
        params: {
          pk: drugOperatingOrderIdJoin,
          orderType: orderType,
          apiType: "pks",
          card: CARD_DRUG_ORDER_ID,
          defaultOrderBy: props.defaultOrderBy,
        },
      });
    } else if (
      orderType === "OPERATING" &&
      props.preOrderList?.length > 0 &&
      !drugOperatingOrderIdJoin
    ) {
      // clear drugOrder, drugOrderList
      // console.log("useEffect called ClearDrugOperatingOrderList")
      // props.onEvent({
      //   message: "ClearDrugOperatingOrderList",
      //   params: {
      //     orderType: orderType,
      //     card: CARD_DRUG_ORDER_ID,
      //   },
      // });
    } else if (
      (orderType !== "" &&
        orderType !== "OPERATING" &&
        props.selectedEmr?.id) ||
      (orderType !== "" && props.selectedProgressCycle?.id)
    ) {
      console.log("Check orderType here: ", orderType);
      props.onEvent({
        message: "GetDrugOrderList",
        params: {
          pk: [
            "HOME_OPD",
            "HOME_IPD",
            "ONE_DOSE_IPD",
            "ONE_DAY_IPD",
            "STAT_IPD",
          ].includes(orderType)
            ? props.selectedEmr?.id
            : ["ONE_DOSE_OPD", "STAT_OPD"].includes(orderType) &&
              props.selectedProgressCycle?.id
            ? props.selectedProgressCycle?.id
            : props.selectedEmr?.id,
          orderType: orderType,
          card: CARD_DRUG_ORDER_ID,
          progressCycle: props.selectedProgressCycle?.id,
          emr: props.selectedEmr?.id,
        },
      });
    }
  }, [
    orderType,
    props.preOrderList,
    props.selectedEmr?.id,
    props.selectedProgressCycle?.id,
  ]);

  useEffect(() => {
    if (
      props.orderTypes?.length &&
      props.orderTypes.length > 0 &&
      !props.orderTypes.includes(orderType)
    ) {
      const include = props.orderTypes.includes(props.goToTab || "");
      setOrderType(orderType || include ? props.goToTab : props.orderTypes[0]);
    }
  }, [props.orderTypes]);

  useEffect(() => {
    if (orderType && props.goToTab) {
      props.setProp("goToTab", "");
    }
  }, [orderType, props.goToTab]);

  useEffect(() => {
    const order_specific_type =
      props.selectedVerbalDrugOrderWorking?.order_specific_type;
    const selectedOrderType = VERBAL_ORDER_MAPPED[order_specific_type];
    setOrderType(selectedOrderType);
  }, [props.selectedVerbalDrugOrderWorking?.order_specific_type]);

  useEffect(() => {
    // เมื่อปิด modal solvent
    if (props.modSolventSelectionConfig?.openModal === false) {
      // check ว่า modal ถูกเปิดจากปุ่ม add solvent, edit solvent
      if (
        props.modSolventSelectionConfig?.admixtureMode ===
          SOLVENT_MODE.ADD_SOLVENT &&
        props.modSolventSelectionConfig?.admixtureSolute
      ) {
        handleAddSolventToOrder();
      } else if (
        props.modSolventSelectionConfig?.admixtureMode ===
          SOLVENT_MODE.EDIT_SOLVENT &&
        props.modSolventSelectionConfig?.admixtureSolute
      ) {
        handleEditSolventToOrder();
      }
      // update dose, unit
      else {
        if (
          parseInt(props.modSolventSelectionConfig?.drug_strength) > 0 &&
          props.modSolventSelectionConfig?.drug_strength_choice
        ) {
          props.onEvent({
            message: "HandleChangeDrugOrderDetail",
            params: {
              key: "drugDose",
              value: props.modSolventSelectionConfig?.drug_strength,
              orderType,
              card: CARD_DRUG_ORDER,
            },
          });

          props.onEvent({
            message: "HandleChangeDrugOrderDetail",
            params: {
              key: "selectedDrugUnitId",
              value: props.modSolventSelectionConfig?.drug_strength_choice,
              stateKey: "DrugSelectSequence",
              card: CARD_DRUG_ORDER,
              orderType,
            },
          });
        }
      }
    }
  }, [props.modSolventSelectionConfig]);

  useEffect(() => {
    if (props.selectedDivision) {
      props.setProp("drugOrder.order_div", props.selectedDivision?.id);
    }
  }, [props.selectedDivision]);

  const getLabelWithId = useCallback(
    (items: any[], id: number, labelKey: string) => {
      // console.log("getLabelWithId", items, id, labelKey)
      for (const item of items) if (item.value === id) return item[labelKey];
      return "";
    },
    []
  );

  // MedReconcile Check
  useEffect(() => {
    console.log("useEffect props.selectedEncounter: ", props.selectedEncounter);
    console.log("useEffect props.divisionType: ", props.divisionType);
    let roleTypes = props.django?.user?.role_types || [];
    if (
      ["หอผู้ป่วย"].includes(props.divisionType) &&
      (roleTypes || []).includes("DOCTOR") &&
      props.selectedEncounter?.type === "IPD"
    ) {
      let callFrom = "DPI";
      if (props.selectedEncounter?.id) {
        props.onEvent({
          message: "GetMedReconcileCheck",
          params: { encounter: props.selectedEncounter, callForm: callFrom },
        });
      }
    } else {
      console.warn("ไม่ได้มาจาก หอผู้ป่วย เปิด CardDrugOrder: ");
    }
  }, [props.selectedEncounter, props.django, props.divisionType]);

  useEffect(() => {
    // default deration "1" เมื่อ order type one day
    if (!!props.selectedDrug?.id && orderType === "ONE_DAY_IPD") {
      props.setProp("drugDuration", "1");
    }

    // default qty "1" เมื่อ order type "STAT_OPD", "STAT_IPD", "ONE_DOSE_IPD", "ONE_DOSE_OPD"
    if (
      !!props.selectedDrug?.id &&
      ["STAT_OPD", "STAT_IPD", "ONE_DOSE_IPD", "ONE_DOSE_OPD"].includes(
        orderType
      )
    ) {
      props.setProp("drugQty", "1");
    }

    // calculate qty, duration เมื่อเลือกรายการยา
    if (!!props.selectedDrug?.id && !!orderType) {
      props.onEvent({
        message: "HandleChangeDrugOrderDetail",
        params: {
          key: "selectedDrugFrequencyId",
          orderType,
          card: CARD_DRUG_ORDER,
        },
      });
    }
  }, [props.selectedDrug?.id, orderType]);

  /* ------------------------------------------------------ */

  /*                       USE CALLBACK                       */

  /* ------------------------------------------------------ */

  const handleEditSolventToOrder = useCallback(async () => {
    const drugSelectSeq: any = props.DrugSelectSequence;

    await props.onEvent({
      message: "SetDrugOrderItemsData",
      params: {
        index: props.modSolventSelectionConfig.admixtureIndex,
        items: {
          iv_rate: drugSelectSeq?.mixture_iv_rate || drugSelectSeq?.iv_rate,
          dose: drugSelectSeq?.dose,
          unit: drugSelectSeq?.unit,
        },
      },
    });
  }, [props.DrugSelectSequence, props.modSolventSelectionConfig]);

  const handleCheckHaveQuestion = useCallback((data: any, index: number) => {
    props.onEvent({
      message: "CheckHaveQuestion",
      params: {
        estimate: {
          ...data.estimate,
          claim_payload: {
            ...(data.estimate?.claim_payload || {}),
            have_question: true,
          },
        },
        payload: {},
        index,
      },
    });
  }, []);

  const handleSaveNote = useCallback(
    (item: any, index: number) => {
      props.onEvent({
        message: "AddToOrder",
        params: {
          editItem: {
            [item.key]: item[item.key],
          },
          drugIndex: index,
          orderType: orderType,
          card: CARD_DRUG_ORDER_ID,
        },
      });
    },
    [orderType]
  );

  const handleEdit = useCallback(
    (e: any, item: any) => {
      e.preventDefault();
      e.stopPropagation();
      setStatusRequested(true);
      setOrderName(
        `${
          orderType.includes("STAT")
            ? "STAT"
            : orderType.includes("ONE_DOSE")
            ? "ONE DOSE"
            : orderType.includes("HOME")
            ? "HOME"
            : orderType.includes("ONE_DAY")
            ? "ONE DAY"
            : ""
        } : ${item.code} `
      );

      props.onEvent({
        message: "SetDrugOrder",
        params: {
          item,
          orderType: orderType,
          card: CARD_DRUG_ORDER_ID,
        },
      });
    },
    [orderType]
  );

  const handleAddSolvent = useCallback(
    async (solute: any, index: number) => {
      await props.setProp("DrugSelectSequence", solute);
      await props.onEvent({
        message: "SetModSolventSelectionConfig",
        params: {
          editable: true,
          editableQuantity: true,
          openModal: true,
          admixtureMode: SOLVENT_MODE.ADD_SOLVENT,
          admixtureSolute: solute,
          admixtureIndex: index,
        },
      });

      let admixtureOptions: any = {};
      if (props.drugPermission.config_TPD_ENABLE_IV_COMPATIBLE) {
        admixtureOptions.strength = solute.dose;
        admixtureOptions.strength_unit = solute.unit;
      } else {
        admixtureOptions.soluteQuantity = solute.quantity;
      }

      props.onEvent({
        message: "initModSolventSelection",
        params: {
          drugOrderType: DRUG_ORDER_TYPE,
          solventId: "",
          admixtureOptions: admixtureOptions,
        },
      });
    },
    [props.drugPermission]
  );

  const checkFrequencyUD = useCallback(
    (options: any[], frequencyId: number) => {
      return (
        (options || [])
          .find((option: any) => option.value === frequencyId)
          ?.text?.search(/\(UD\)$/g) >= 0
      );
    },
    []
  );

  /* ------------------------------------------------------ */

  /*                       USE MEMO                       */

  /* ------------------------------------------------------ */
  const drugSearchResult = useMemo(() => {
    return (props.drugSearchResult || []).map((item: any) => ({
      ...item,
      generic_name: (
        <div dangerouslySetInnerHTML={{ __html: item.generic_name || "" }} />
      ),
    }));
  }, [props.drugSearchResult]);

  const divisionOptions = useMemo(() => {
    return props.divisionForOrderDivOptions || [];
  }, [props.divisionForOrderDivOptions]);

  const renderDrugOrderItems = useMemo(() => {
    // console.log("  renderDrugOrderItems  props.drugOrder: ", props.drugOrder);
    let items: any[] = props.drugOrder?.items || [];
    let admixtureKeyMapper: any = {};
    props.drugOrder?.items?.forEach((item: any) => {
      if (item?.drug_admixture_order_key) {
        let admixtureItem = props.drugOrder?.items.filter(
          (admixture: any) =>
            admixture.drug_admixture_order_key === item.drug_admixture_order_key
        );

        if (admixtureItem?.length > 1) {
          if (admixtureKeyMapper?.[item?.drug_admixture_order_key]) {
          } else {
            admixtureKeyMapper[item?.drug_admixture_order_key] =
              Object.keys(admixtureKeyMapper).length + 1;
          }
        }
      }
    });

    const data = props.drugOrderItemMasterData || {};

    return items.map((item: any, index: number) => ({
      ...item,
      title_status: (
        <>
          {props.isCardRejectOrder && item.rejected ? (
            <div
              style={{
                backgroundColor: "rgba(219, 40, 40, 0.2)",
                fontSize: "1.3em",
              }}
            >
              <strong>Rejected:</strong> {item.note_rejected}
            </div>
          ) : null}
          <div>
            <label style={{ marginRight: "1rem" }}>{item.title}</label>
            {item.mr_duplicate && (
              <Button
                color="yellow"
                content="MR"
                style={{ padding: "0.35rem 0.55rem", fontSize: "0.85rem" }}
                onClick={() => {
                  setModMRDuplicate({ open: true, message: item.mr_message });
                }}
              />
            )}
            {checkFrequencyUD(
              props.selectedDrugItemDetailOptions?.frequency ||
                props.drugOrderItemMasterData?.masterFrequencies,
              item.frequency
            ) && (
              <Button
                icon="clipboard list"
                circular={true}
                color="green"
                style={{ padding: "0.4rem 0.45rem 0.45rem", fontSize: "1rem" }}
                onClick={() =>
                  setModDrugDesc({ open: true, desc: item.description, index })
                }
              />
            )}
          </div>
          <SubDrugOrderStatusAlert
            onEvent={props.onEvent}
            stateKey="drugOrder"
            index={index}
            item={{
              ...item,
              alerts: {
                H: item?.highalert === "True",
                L: item?.lasa === "True",
                I:
                  item?.drug_interaction_info || item?.note_drug_interaction
                    ? {
                        info:
                          item.drug_interaction_info ||
                          item.note_drug_interaction,
                      }
                    : null,
                ...(item.alerts || {}),
                ...(item?.duplicateReason?.code === "DUPLICATE_DRUG_GROUP" && {
                  D: {
                    details:
                      item?.duplicateReason?.duplicate_list?.find(
                        (d: any) => d.product === item.product
                      )?.duplicated_detail +
                      `<div> <br/> <span style="color: red; font-weight: bolder"> เหตุผลที่สั่งยาซ้ำ: </span> ${item.note_group_duplicated} </div>`,
                  },
                }),
              },
              claim_payload: item.estimate?.claim_payload
                ? item.estimate?.claim_payload
                : null,
            }}
            saveClaimByEvent={true}
            estimate={props.drugOrder?.estimate || {}}
            selectedEncounter={props.selectedEncounter}
            selectedEmr={props.selectedEmr}
            orderType={orderType}
            selectedProgressCycle={props.selectedProgressCycle}
            isFemale={props.selectedEncounter?.patient_gender_name === "Female"}
            statusRequest={!statusRequested}
            onSaveNote={handleSaveNote}
            // callback
            onDrugClaim={() => handleCheckHaveQuestion(item, index)}
            targetStateOrder={"drugOrder"}
          />
        </>
      ),
      dose: (
        <EditorColumn
          value={item?.dose || ""}
          disabled={!statusRequested}
          onDataChanged={(value: string) =>
            props.onEvent({
              message: "AddToOrder",
              params: {
                editItem: { dose: value },
                drugIndex: index,
                orderType: orderType,
                card: CARD_DRUG_ORDER_ID,
              },
            })
          }
        />
      ),
      unit: (
        <DropDownColumn
          showText={getLabelWithId(data.masterUnits || [], item.unit, "text")}
          options={
            props.selectedDrugItemDetailOptions?.unit ||
            props.drugOrderItemMasterData?.masterUnits ||
            []
          }
          value={item.unit}
          readOnly={!statusRequested}
          onDataChanged={(value: string) =>
            props.onEvent({
              message: "AddToOrder",
              params: {
                editItem: { unit: value },
                drugIndex: index,
                orderType: orderType,
                card: CARD_DRUG_ORDER_ID,
              },
            })
          }
        />
      ),
      route: (
        <DropDownColumn
          showText={getLabelWithId(data.masterRoutes || [], item.route, "text")}
          options={
            props.selectedDrugItemDetailOptions?.route ||
            props.drugOrderItemMasterData?.masterRoutes ||
            []
          }
          value={item.route || ""}
          readOnly={!statusRequested}
          onDataChanged={(value: string) =>
            props.onEvent({
              message: "AddToOrder",
              params: {
                editItem: { route: value },
                drugIndex: index,
                orderType: orderType,
                card: CARD_DRUG_ORDER_ID,
              },
            })
          }
        />
      ),
      frequency: (
        <DropDownColumn
          showText={getLabelWithId(
            data.masterFrequencies || [],
            item.frequency,
            "text"
          )}
          options={
            props.selectedDrugItemDetailOptions?.frequency ||
            props.drugOrderItemMasterData?.masterFrequencies ||
            []
          }
          value={item.frequency || ""}
          readOnly={!statusRequested}
          onDataChanged={(value: string) =>
            props.onEvent({
              message: "AddToOrder",
              params: {
                editItem: { frequency: value, description: "" },
                drugIndex: index,
                orderType: orderType,
                card: CARD_DRUG_ORDER_ID,
              },
            })
          }
        />
      ),
      method: (
        <DropDownColumn
          showText={getLabelWithId(
            data.masterMethods || [],
            item.method,
            "text"
          )}
          options={
            props.selectedDrugItemDetailOptions?.method ||
            props.drugOrderItemMasterData?.masterMethods ||
            []
          }
          value={item.method || ""}
          readOnly={!statusRequested}
          onDataChanged={(value: string) =>
            props.onEvent({
              message: "AddToOrder",
              params: {
                editItem: { method: value },
                drugIndex: index,
                orderType: orderType,
                card: CARD_DRUG_ORDER_ID,
              },
            })
          }
        />
      ),
      prn: (
        <EditorColumn
          value={item.prn || ""}
          disabled={!statusRequested}
          onDataChanged={(value: string) =>
            props.onEvent({
              message: "AddToOrder",
              params: {
                editItem: { prn: value },
                drugIndex: index,
                orderType: orderType,
                card: CARD_DRUG_ORDER_ID,
              },
            })
          }
        />
      ),
      quantity: (
        <EditorColumn
          value={item.quantity || ""}
          disabled={!statusRequested}
          inputMin={0}
          onDataChanged={(value: string) => {
            let valueNumber = Number(value);
            if (value === "" || isNaN(valueNumber)) {
              return;
            }
            if (valueNumber >= 0) {
              props.onEvent({
                message: "AddToOrder",
                params: {
                  editItem: { quantity: value },
                  drugIndex: index,
                  orderType: orderType,
                  card: CARD_DRUG_ORDER_ID,
                },
              });
            }
          }}
        />
      ),
      duration: (
        <EditorColumn
          value={item.duration || ""}
          disabled={!statusRequested}
          inputMin={0}
          onDataChanged={(value: string) => {
            let valueNumber = Number(value);
            if (value === "" || isNaN(valueNumber)) {
              return;
            }
            if (valueNumber >= 0) {
              props.onEvent({
                message: "AddToOrder",
                params: {
                  editItem: { duration: valueNumber },
                  drugIndex: index,
                  orderType: orderType,
                  card: CARD_DRUG_ORDER_ID,
                },
              });
            }
          }}
        />
      ),
      _remove: (
        <div style={{ height: "100%", width: "100%", textAlign: "center" }}>
          {admixtureKeyMapper?.[item?.drug_admixture_order_key] && (
            <Label
              circular
              color={
                SEMANTIC_COLOR[
                  admixtureKeyMapper[item?.drug_admixture_order_key]
                ]
              }
            >
              {admixtureKeyMapper[item?.drug_admixture_order_key]}
            </Label>
          )}
          <Icon
            name="chain"
            size="large"
            disabled={!statusRequested}
            style={{
              cursor: "pointer",
              display:
                !!item?.is_solvent ||
                (!!item?.drug_admixture_order_key &&
                  props.drugOrder?.items?.filter(
                    (d: any) =>
                      d?.drug_admixture_order_key ===
                      item?.drug_admixture_order_key
                  ).length > 1) ||
                !item?.is_intravaneous
                  ? "none"
                  : "",
            }}
            onClick={() => {
              handleAddSolvent(item, index);
            }}
          />
          <Icon
            name="flask"
            size="large"
            disabled={!statusRequested}
            style={{
              cursor: "pointer",
              display:
                !props.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE ||
                !!item?.is_solvent ||
                !item?.drug_admixture_order_key ||
                props.drugOrder?.items?.filter(
                  (d: any) =>
                    d?.drug_admixture_order_key ===
                    item?.drug_admixture_order_key
                ).length === 1
                  ? "none"
                  : "",
            }}
            onClick={() => {
              handleEditSolvent(item, index);
            }}
          />
          <Icon
            name="broken chain"
            size="large"
            disabled={!statusRequested}
            style={{
              cursor: "pointer",
              display:
                item.drug_admixture_order_key &&
                props.drugOrder?.items?.filter(
                  (d: any) =>
                    d?.drug_admixture_order_key ===
                    item?.drug_admixture_order_key
                ).length > 1
                  ? ""
                  : "none",
            }}
            onClick={() => {
              props.onEvent({
                message: "HandleOnRemoveDrug",
                params: {
                  action: "unlink_mixture",
                  removeAdmixtureKey: item.drug_admixture_order_key,
                  card: CARD_DRUG_ORDER_ID,
                },
              });
            }}
          />
          <Icon
            name="trash alternate outline"
            size="large"
            color="red"
            disabled={!statusRequested}
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.onEvent({
                message: "HandleOnRemoveDrug",
                params: {
                  action: "need_confirm",
                  removeDrugId: item.product || null,
                  drugIndex: index,
                  card: CARD_DRUG_ORDER_ID,
                },
              });
            }}
          />
        </div>
      ),
      // unitText: getLabelWithId(data.masterUnits || [], item.unit, "text"),
      // routeText: getLabelWithId(data.masterRoutes || [], item.route, "text"),
      // siteText: getLabelWithId(data.masterSites || [], item.site, "text"),
      // frequencyText:
      // methodText: getLabelWithId(data.masterMethods || [], item.method, "text"),
    }));
  }, [
    props.drugOrder?.items,
    props.drugOrderItemMasterData,
    props.selectedEncounter,
    props.selectedEmr,
    props.selectedProgressCycle,
    orderType,
  ]);

  // const drugOrderTemplateList = useMemo(() => {
  //   return (props.modDrugOrderTemplate?.drugOrderTemplateList || []).map(
  //     (item: any) => ({
  //       ...item,
  //       _checkbox: (
  //         <Checkbox
  //           checked={props.modDrugOrderTemplate?.selectedDrugOrderTemplates?.includes(
  //             item.id
  //           )}
  //           onChange={(e: any, { v }) => {
  //             props.onEvent({
  //               message: "HandleDrugOrderTemplate",
  //               params: {
  //                 action: "select_drug_order",
  //                 selectedDrugOrder: item.id,
  //                 card: CARD_DRUG_ORDER_ID,
  //               },
  //             });
  //           }}
  //         />
  //       ),
  //       name: (
  //         <div
  //           style={{ height: "100%", width: "100%" }}
  //           onClick={(e: any) => {
  //             props.onEvent({
  //               message: "HandleDrugOrderTemplate",
  //               params: {
  //                 action: "show",
  //                 selectedDrugOrder: item?.id || null,
  //               },
  //             });
  //           }}
  //         >
  //           {item?.name}
  //         </div>
  //       ),
  //     })
  //   );
  // }, [props.modDrugOrderTemplate]);

  const drugOrderList = useMemo(() => {
    return (props.drugOrderList || []).map((item: any) => ({
      ...item,
      created: formatDatetime(item.created),
      summary: (
        <div>
          <div dangerouslySetInnerHTML={{ __html: item.summary }} />
          {/* <Popup
            content={<TaperPopup />}
            on="click"
            position="bottom left"
            pinned
            trigger={
              
            }
          /> */}
          {/* <img
          src={"/static/images/taper-schedule.png"}
          style={{
            width: "1.5rem",
            cursor: "pointer",
            margin: "0px 0.142857em",
          }}
          alt="taper-schedule.icon"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              /> */}
        </div>
      ),
      delete: item.can_cancel && (
        <DeleteColumn
          value={true}
          id={item.id}
          titleName={`ต้องการลบ รายการสั่งยา ${EstimateMap[orderType]} นี้ใช่หรือไม่`}
          size="mini"
          titleColor="orange"
          onConfirm={() =>
            props.onEvent({
              message: "DeleteDrugOrderItem",
              params: { orderType, id: item.id, item },
            })
          }
        />
      ),
      edit: item.can_edit && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="blue"
            icon="pencil"
            onClick={(e) => handleEdit(e, item)}
            size="mini"
          ></Button>
        </div>
      ),
    }));
  }, [props.drugOrderList, orderType]);

  const remedList = useMemo(() => {
    return (props.modReMedication?.remedData || [])
      .filter((item: any) => item.show)
      .map((item: any) => {
        return {
          ...item,
          selected: (
            <Checkbox
              checked={
                (item.order &&
                  props.modReMedication?.selectedDrugs?.includes(
                    item.drugId
                  )) || // for drug type
                (!item.order &&
                  props.modReMedication?.selectedOrders?.includes(item.orderId)) // for order type
              }
              onChange={(e, { checked }) => {
                if (item?.order) {
                  // drug type
                  props.onEvent({
                    message: "HandleReMedication",
                    params: {
                      action: "select_drug",
                      card: CARD_DRUG_ORDER_ID,
                      drugId: item.drugId,
                    },
                  });
                } else {
                  // order type
                  props.onEvent({
                    message: "HandleReMedication",
                    params: {
                      action: "select_order",
                      card: CARD_DRUG_ORDER_ID,
                      orderId: item.orderId,
                    },
                  });
                }
              }}
            />
          ),
          name: item.name ? (
            item.name
          ) : (
            <div
              style={{ width: "100%", height: "100%" }}
              onClick={() => {
                if (item.orderId) {
                  props.onEvent({
                    message: "HandleReMedication",
                    params: {
                      action: "show_drug",
                      orderId: item.orderId,
                    },
                  });
                }
              }}
            >
              {item.doctor_name +
                " " +
                moment(new Date(item.created)).format("DD/MM/YYYY [[]HH:mm[]]")}
            </div>
          ),
          unit: item.unit
            ? getLabelWithId(
                props.drugOrderItemMasterData?.masterUnits || [],
                item.unit,
                "text"
              )
            : "",
        };
      });
  }, [props.modReMedication]);

  const hideDrugForm = useMemo(() => {
    const checkRequire = (requireKey: string) => {
      const required: boolean =
        props.DrugSelectSequence?.[`require_${requireKey}`];

      return !required && typeof required !== "undefined";
    };

    return {
      dose: checkRequire("unit"),
      unit: checkRequire("unit"),
      route: checkRequire("route"),
      site: checkRequire("site"),
      frequency: checkRequire("frequency"),
      method: checkRequire("method"),
      duration: [
        "STAT_OPD",
        "STAT_IPD",
        "ONE_DOSE_IPD",
        "ONE_DOSE_OPD",
      ].includes(orderType),
    };
  }, [props.DrugSelectSequence, props.selectedDrug, orderType]);

  const hideDrugDescription = useMemo(() => {
    return !checkFrequencyUD(
      props.DrugSelectSequence?.drugFrequencies,
      props.DrugSelectSequence?.selectedDrugFrequencyId
    );
  }, [
    props.DrugSelectSequence?.drugFrequencies,
    props.DrugSelectSequence?.selectedDrugFrequencyId,
  ]);

  /* ------------------------------------------------------ */

  /*                       // HANDLER                       */

  /* ------------------------------------------------------ */

  // TABLE
  const handleDrugItemsTrProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        lineHeight: 1.75,
        display: props.isCardRejectOrder
          ? (rowInfo?.original?.rejected && rowInfo?.original?.id) ||
            !rowInfo?.original?.id
            ? ""
            : "none"
          : "",
      },
      onClick: () => {
        props.onEvent({
          message: "GetDrugItemDetail",
          params: {
            selectedDrugId: rowInfo?.original?.product,
            selectedDrugIndex: rowInfo?.index,
            card: CARD_DRUG_ORDER_ID,
          },
        });
      },
    };
  };

  const handleListRowProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => ({
    style: {
      backgroundColor:
        Number.isInteger(props.drugOrder?.id) &&
        rowInfo?.original?.id === props.drugOrder?.id
          ? "#cccccc"
          : "white",
    },
    onClick: (e: any) => {
      props.onEvent({
        message: "SetDrugOrder",
        params: {
          item: rowInfo?.original,
          orderType: orderType,
          card: CARD_DRUG_ORDER_ID,
        },
      });
      setStatusRequested(rowInfo?.original?.status_name === "REQUESTED");
      setOrderName(
        `${
          orderType.includes("STAT")
            ? "STAT"
            : orderType.includes("ONE_DOSE")
            ? "ONE DOSE"
            : orderType.includes("HOME")
            ? "HOME"
            : orderType.includes("ONE_DAY")
            ? "ONE DAY"
            : ""
        } : ${rowInfo?.original?.code} `
      );
    },
  });

  const handleDrugRowProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      onClick: () => {
        setErrorMessage(null);

        props.onEvent({
          message: "RunSequence",
          params: {
            sequence: "DrugSelect",
            selectedDrug: props?.drugSearchResult[rowInfo?.index],
            card: CARD_DRUG_ORDER_ID,
            patient: props.selectedAppointment?.patient,
          },
        });
      },
    };
  };

  const handleOrderTrProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id &&
          rowInfo?.original?.id ===
            props.DrugOrderTemplateSequence?.selectedOrderId
            ? "#cccccc"
            : "white",
      },
    };
  };

  const changeOrderType = (item: string) => {
    props.onEvent({ message: "ClearDrugOrderForm", params: { hard: true } });
    setOrderType(item);
  };

  const handleConfirmDrugGroupDuplicateWithReason = (reason: any) => {
    // const error = props.errorMessage?.[CARD_DUPLICATE_REASON];

    props.onEvent({
      message: "AddToOrder",
      params: {
        orderType: orderType,
        onFailed: setErrorMessage,
        isCheckDrugGroup: true,
        noteGroupDuplicated: reason?.duplicated_note,
        card: CARD_DRUG_ORDER,
      },
    });
  };

  const handleAddToOrder = () => {
    console.log("handleAddToOrder: Issue 59228 ");

    // TODO: Issue 59228 ( Stat, One Dose, One Day, Home med )

    const textAlert: any[] = [];
    const drug = props.DrugSelectSequence || {};

    const checkEmpty = (value: any, key: keyof typeof hideDrugForm) => {
      return !value && !hideDrugForm[key];
    };

    if (!props.drugSearchText) {
      textAlert.push("กรุณาระบุค่าใน drug");
    }
    if (checkEmpty(props.drugDose, "dose")) {
      textAlert.push("กรุณาระบุค่าใน dose");
      textAlert.push("กรุณาระบุค่า dose ให้มีจำนวนมากกว่า 0");
    }
    if (props.drugDose === "0" && !hideDrugForm.dose) {
      textAlert.push("กรุณาระบุค่า dose ให้มีจำนวนมากกว่า 0");
    }
    if (checkEmpty(drug.selectedDrugUnitId, "unit")) {
      textAlert.push("กรุณาระบุค่าใน unit");
    }
    if (checkEmpty(drug.selectedDrugRouteId, "route")) {
      textAlert.push("กรุณาระบุค่าใน route");
    }
    if (checkEmpty(drug.selectedDrugSiteId, "site")) {
      textAlert.push("กรุณาระบุค่าใน site");
    }
    if (checkEmpty(drug.selectedDrugFrequencyId, "frequency")) {
      textAlert.push("กรุณาระบุค่าใน frequency");
    }
    if (!props.drugQty) {
      textAlert.push("กรุณาระบุค่าใน quantity");
      textAlert.push("กรุณาระบุค่า quantity ให้มีจำนวนมากกว่า 0");
    }
    if (props.drugQty === "0") {
      textAlert.push("กรุณาระบุค่า quantity ให้มีจำนวนมากกว่า 0");
    }

    setErrorMessage(textAlert);
    if (textAlert.length === 0) {
      props.onEvent({
        message: "AddToOrder",
        params: {
          orderType: orderType,
          onFailed: setErrorMessage,
          isCheckDrugGroup: true,
          card: CARD_DRUG_ORDER,
        },
      });
      setErrorMessage(null);
    }
  };

  const handleClearDrugOrderForm = () => {
    setErrorMessage(null);
    props.onEvent({ message: "ClearDrugOrderForm", params: {} });
  };

  const handleConfirmOrder = (params: any = {}) => {
    if (!props.currentDoctor?.id && !modPharmaConfirm?.note) {
      setModPharmaConfirm({ open: true, note: "" });
      return;
    }

    if (props.isCardRejectOrder && props.orderTypeCardRejectOrder) {
      props.onEvent({
        message: "ConfirmDrugOrder",
        params: {
          orderType: props.orderTypeCardRejectOrder,
          card: CARD_REJECT_ORDER,
          cardKey: CARD_DRUG_ORDER,
          action: "SAVE",
          note: modPharmaConfirm?.note,
          ...params,
        },
      });
    } else {
      props.onEvent({
        message: "ConfirmDrugOrder",
        params: {
          orderType: orderType,
          card: CARD_DUPLICATE_REASON,
          cardKey: CARD_DRUG_ORDER,
          action: "SAVE",
          note: modPharmaConfirm?.note,
          ...params,
          onSuccess: () => !props.hiddenDrugList && setStatusRequested(false),
        },
      });
    }
    setModPharmaConfirm({ open: false, note: "" });
  };

  const handleChangeDrugDose = (e: any) => {
    const value = e.target.value;
    // Todo: validate number
    // props.controller.setState({
    //   drugDose: value === "" ? "" : +value < 0 ? "0" : value,
    // });
    props.onEvent({
      message: "HandleChangeDrugOrderDetail",
      params: {
        key: "drugDose",
        value: value === "" ? "" : +value < 0 ? "0" : value,
        orderType,
        card: CARD_DRUG_ORDER,
      },
    });
  };

  // Mod Solvent Selection
  const handleOpenModSolventSelection = async () => {
    await props.onEvent({
      message: "SetModSolventSelectionConfig",
      params: { editable: true, editableQuantity: true },
    });

    let drugSelectSeq: any = props.DrugSelectSequence;

    let admixtureOptions: any = {
      quantity: drugSelectSeq?.solvent_quantity,
      strength: drugSelectSeq?.dose || 1,
      strength_unit: drugSelectSeq?.unit || "",
      volume: drugSelectSeq?.mixture_solvent_volume,
      mixture_iv_rate: drugSelectSeq?.mixture_iv_rate,
      mixture_iv_rate_unit: drugSelectSeq?.mixture_iv_rate_unit,
      solute_concentration_rate: drugSelectSeq?.mixture_concentration_solute,
      solvent_concentration_rate: drugSelectSeq?.mixture_concentration_solvent,
      concentration_unit: drugSelectSeq?.drug_concentration_unit,
      drug_drip_in: drugSelectSeq?.drug_drip_in,
      drug_drip_in_unit: drugSelectSeq?.drug_drip_in_unit,
    };

    if (
      drugSelectSeq?.add_auto_solvent &&
      drugSelectSeq?.default_solvent &&
      props.drugPermission.config_TPD_ENABLE_IV_COMPATIBLE
    ) {
      admixtureOptions.volume = drugSelectSeq?.stock_size || 1;
      admixtureOptions.quantity = drugSelectSeq?.quantity || 1;
      admixtureOptions.mixture_iv_rate = drugSelectSeq?.mixture_iv_rate || "";
      admixtureOptions.mixture_iv_rate_unit =
        drugSelectSeq?.mixture_iv_rate_unit || "";
      admixtureOptions.solute_concentration_rate =
        drugSelectSeq?.solute_concentration_rate || "";
      admixtureOptions.solvent_concentration_rate =
        drugSelectSeq?.solvent_concentration_rate || "";
      admixtureOptions.concentration_unit =
        drugSelectSeq?.concentration_unit || "";
      admixtureOptions.drug_drip_in = drugSelectSeq?.drug_drip_in || "";
      admixtureOptions.drug_drip_in_unit =
        drugSelectSeq?.drug_drip_in_unit || "";
    }

    props.onEvent({
      message: "initModSolventSelection",
      params: {
        drugOrderType: DRUG_ORDER_TYPE,
        solventId: drugSelectSeq?.solvent_product,
        admixtureOptions: admixtureOptions,
      },
    });
  };

  const handleCloseModSolventSelection = () => {
    props.onEvent({
      message: "SetModSolventSelectionConfig",
      params: { openModal: false },
    });
  };

  const handleAddSolventToOrder = async () => {
    const drugSelectSeq: any = props.DrugSelectSequence;
    const modSolventConfig: any = props.modSolventSelectionConfig;
    const drug_admixture_order_key =
      props.modSolventSelectionConfig?.admixtureSolute
        ?.drug_admixture_order_key || uuidv4();

    if (!modSolventConfig?.solvent) return;

    await props.onEvent({
      message: "SetDrugOrderItemsData",
      params: {
        index: props.modSolventSelectionConfig.admixtureIndex,
        items: {
          iv_rate: drugSelectSeq?.mixture_iv_rate,
          dose: drugSelectSeq?.dose,
          unit: drugSelectSeq?.unit,
          drug_admixture_order_key: drug_admixture_order_key,
        },
      },
    });

    if (props.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE) {
      props.onEvent({
        message: "AddToOrder",
        params: {
          drugItems: [
            {
              product: modSolventConfig?.solvent,
              product_name: modSolventConfig?.selectedSolvent?.full_name,
              stock_unit_name:
                modSolventConfig?.selectedSolvent?.stock_unit_name,
              drug_list: modSolventConfig?.selectedSolvent?.drug_list,
              dose: "0",
              quantity: drugSelectSeq?.solvent_quantity,
              volume: drugSelectSeq?.mixture_solvent_volume,
              solvent_volume: drugSelectSeq?.mixture_solvent_volume,
              solute_concentration_rate:
                drugSelectSeq?.mixture_concentration_solute,
              solvent_concentration_rate:
                drugSelectSeq?.mixture_concentration_solvent,
              concentration_unit: drugSelectSeq?.drug_concentration_unit,
              mixture_iv_rate: drugSelectSeq?.mixture_iv_rate,
              mixture_iv_rate_unit: drugSelectSeq?.mixture_iv_rate_unit,
              drug_drip_in: drugSelectSeq?.drug_drip_in,
              drug_drip_in_unit: drugSelectSeq?.drug_drip_in_unit,
              is_solvent: true,
              drug_admixture_order_key: drug_admixture_order_key,
            },
          ],
          orderType: orderType,
          card: CARD_DRUG_ORDER_ID,
        },
      });
    } else {
      props.onEvent({
        message: "AddToOrder",
        params: {
          drugItems: [
            {
              product: modSolventConfig?.solvent,
              product_name: modSolventConfig?.selectedSolvent?.full_name,
              stock_unit_name:
                modSolventConfig?.selectedSolvent?.stock_unit_name,
              drug_list: modSolventConfig?.selectedSolvent?.drug_list,
              dose: drugSelectSeq?.solvent_quantity,
              quantity: drugSelectSeq?.solvent_quantity,
              is_solvent: true,
              drug_admixture_order_key: drug_admixture_order_key,
            },
          ],
          orderType: orderType,
          card: CARD_DRUG_ORDER_ID,
        },
      });
    }
  };

  const handleEditSolvent = async (solute: any, index: number) => {
    await props.setProp("DrugSelectSequence", solute);

    await props.onEvent({
      message: "SetModSolventSelectionConfig",
      params: {
        editable: true,
        editableQuantity: true,
        openModal: true,
        admixtureMode: SOLVENT_MODE.EDIT_SOLVENT,
        admixtureSolute: solute,
        admixtureIndex: index,
      },
    });

    let solvent: any = null;
    props.drugOrder?.items?.forEach((item: any) => {
      if (
        item?.drug_admixture_order_key === solute.drug_admixture_order_key &&
        item?.is_solvent
      ) {
        solvent = item;
      }
    });

    const admixtureOptions: any = {
      strength: solute.dose,
      strength_unit: solute.unit,
      volume: solvent?.solvent_volume || solvent?.volume || "",
      quantity: solvent?.quantity || 1,
      mixture_iv_rate: parseInt(solvent?.mixture_iv_rate),
      mixture_iv_rate_unit: solvent?.mixture_iv_rate_unit || "",
      solute_rate: solvent?.solute_concentration_rate || "",
      solvent_rate: solvent?.solvent_concentration_rate || "",
      concentration_unit: solvent?.drug_concentration_unit || "",
      drip_in: solvent?.drug_drip_in || "",
      drip_in_unit: solvent?.drug_drip_in_unit || "",
    };

    await props.onEvent({
      message: "initModSolventSelection",
      params: {
        drugOrderType: DRUG_ORDER_TYPE,
        solventId: solvent.product,
        admixtureOptions: admixtureOptions,
      },
    });
  };

  const handleClickDrugHistory = () => {
    setOpenModDrugHistory(true);
  };

  const handleCloseModDrugHistory = () => {
    setOpenModDrugHistory(false);
  };

  const handleDrugHistoryAddToOrder = async (
    items: any[],
    checked: number[]
  ) => {
    const data = props.drugOrderItemMasterData || {};

    const getValue = (options: any, value: any) => {
      return value
        ? (options || []).find((item: any) => item.text.includes(`(${value})`))
            ?.value || ""
        : value;
    };

    await props.setProp("modReMedication", {
      remedData: items.map((item) => ({
        ...item,
        drugId: item.id,
        product_name: item.name,
        frequency: getValue(data.masterFrequencies, item.code_frequency),
        method: getValue(data.masterMethods, item.code_method),
        route: getValue(data.masterRoutes, item.code_route),
        site: getValue(data.masterSites, item.code_site),
        unit: getValue(data.masterUnits, item.code_unit),
      })),
      selectedDrugs: checked,
    });

    props.onEvent({
      message: "HandleReMedication",
      params: {
        action: "add_to_order",
        card: CARD_DRUG_ORDER_ID,
        orderType,
      },
    });

    handleCloseModDrugHistory();
  };

  const handleChangeDate = (date: string) => {
    props.setProp("DrugSelectSequence.selectedDate", date);
  };

  const handleCloseModDesc = () => {
    setModDrugDesc({});
  };

  const handleConfirmDesc = (description: string) => {
    props.onEvent({
      message: "AddToOrder",
      params: {
        editItem: { description },
        drugIndex: modDrugDesc.index,
        orderType: orderType,
        card: CARD_DRUG_ORDER_ID,
      },
    });

    handleCloseModDesc();
  };

  const handleAddNewOrder = () => {
    props.onEvent({
      message: "SetDrugOrder",
      params: { orderType: orderType, card: CARD_DRUG_ORDER_ID },
    });

    setStatusRequested(true);
    setOrderName("");
  };

  const handleCloseDrugPackageAlert = () => {
    props.setProp("drugPackageAlertList", []);
  };

  console.log("CardDrugOrder: ", props);
  // console.log("test", props.drugOrder?.items);
  return (
    <div style={{ width: "100%", paddingBottom: "2rem" }}>
      {/* height: "100%" */}
      {!props.disabledVerbalMedOrder &&
      props.selectedVerbalDrugOrderWorking &&
      Object.keys(props.selectedVerbalDrugOrderWorking || {})?.length !== 0 ? (
        // @ts-ignore
        <Form
          style={{
            padding: "10px 0 2.5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ padding: "10px 10px 0 10px" }}>
            <label style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
              รคส.แพทย์
            </label>
            <br></br>
            <label style={{ fontSize: "1rem" }}>
              {
                ORDER_TYPES[
                  VERBAL_ORDER_MAPPED[
                    props.selectedVerbalDrugOrderWorking?.order_specific_type
                  ]
                ]
              }
            </label>
            <br></br>
            <label style={{ fontSize: "1rem" }}>
              {props.selectedVerbalDrugOrderWorking?.drug_order_code}
            </label>
          </div>
          <TextArea
            style={{ width: "50%", fontSize: "1.1rem" }}
            disabled={true}
            rows={3}
            value={props.selectedVerbalDrugOrderWorking?.note}
          ></TextArea>
          <div style={{ padding: "10px 10px 0 10px" }}>
            <label style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
              ผู้บันทึกคำสั่ง
            </label>
            <br></br>
            <label style={{ fontSize: "1.0rem" }}>
              {props.selectedVerbalDrugOrderWorking?.editor}
            </label>
          </div>
        </Form>
      ) : (
        ""
      )}
      {!props.hiddenMenuOrderType &&
      props.orderTypes?.length &&
      props.orderTypes.length > 1 ? (
        <div
          style={{
            display: "flex",
            borderBottom: "solid #cccccc 1px",
            padding: "10px 10px 0 10px",
          }}
        >
          {props.orderTypes
            .filter((item: any) => Object.keys(ORDER_TYPES).includes(item))
            .map((item: any, index: number) => (
              <Button
                key={index}
                size="tiny"
                color={orderType === item ? "blue" : undefined}
                onClick={() => {
                  changeOrderType(item);
                  setStatusRequested(false);
                  setOrderName("");
                }}
              >
                {ORDER_TYPES[item]}
              </Button>
            ))}
        </div>
      ) : null}

      {props.title ? (
        <div style={{ fontWeight: "bolder", marginLeft: "5px" }}>
          {" "}
          {props.title}{" "}
        </div>
      ) : null}
      <CardDrugOrderUX
        orderStatus={orderName === "" ? "รายการยาสั่งใหม่" : "แก้ไขรายการยา"}
        orderName={orderName !== "" && <Label color="blue">{orderName}</Label>}
        showRecon={orderType === "HOME_IPD"}
        onClickRecontinueMed={() => {
          props.onEvent({
            message: "HandleModRecontinueMedication",
            params: { action: "OPEN", card: CARD_DRUG_ORDER },
          });
        }}
        onPrintDrugOrder={() => {
          props.onEvent({
            message: "PrintDrugOrderFromDoctor",
            params: { card: CARD_DRUG_ORDER },
          });
        }}
        onPrintNarcotic={() => {
          props.onEvent({
            message: "HandlePrintNarcoticForm",
            params: {
              order_id: props.drugOrder?.id,
              loading: "PRINT_NARCOTIC_FORM",
              card: CARD_DRUG_ORDER,
            },
          });
        }}
        onPrintPsychotropic={() => {
          props.onEvent({
            message: "HandlePrintPsychotropicForm",
            params: {
              order_id: props.drugOrder?.id,
              loading: "PRINT_PSYCHOTROPIC_FORM",
              card: CARD_DRUG_ORDER,
            },
          });
        }}
        disabledPrintDrugOrderBtn={!props.drugOrder?.id}
        hiddenDrugList={props.hiddenDrugList}
        isShowNarcoticPrint={props.drugOrder?.isShowNarcotic}
        isShowPsychotropicPrint={props.drugOrder?.isShowPsychotropic}
        loadingStatus={props.loadingStatus}
        // Element
        ErrorMessage={<ErrorMessage error={errorMessage} />}
        errorMessage={errorMessage}
        orderType={orderType}
        drugOrderList={drugOrderList}
        statusRequest={statusRequested}
        hideDrugForm={hideDrugForm}
        drugFormFlexStyle={Object.values(hideDrugForm).some((hide) => hide)}
        listRowProps={handleListRowProps}
        addNewOrder={handleAddNewOrder}
        doctorElement={
          <SearchBox
            onEvent={props.onEvent}
            setProp={props.setProp}
            searchedItemList={props.searchedItemList}
            selectedItem={props.drugOrder.selectedOrderBy}
            setSelectedItem={(item: any) => {
              let result = { ...props.drugOrder };
              result.selectedOrderBy = item;
              result.order_by = item?.id;
              props.setProp("drugOrder", result);
            }}
            type="Doctor"
            toDisplay={(item: any) =>
              `${item.first_name} ${item.last_name} [${item.code}]`
            }
            style={{ width: "100%" }}
            fluid={true}
          />
        }
        buttonConfirmOrder={
          !props.disabledConfirmedOrderBtn && (
            <ButtonLoadCheck
              // function
              id="btn-confirmMedOrder"
              setProp={props.setProp}
              onClick={() => handleConfirmOrder()}
              // data
              paramKey={`${CARD_DRUG_ORDER}_SAVE`}
              buttonLoadCheck={
                props.buttonLoadCheck?.[`${CARD_DRUG_ORDER}_SAVE`]
              }
              // config
              disabled={statusRequested ? false : true}
              color={"green"}
              name="SAVE"
              // size="medium"
              title="CONFIRM ORDER"
              style={{ width: "100%" }}
            />
          )
        }
        buttonTaperSchedule={
          <Popup
            content={
              <TaperPopup
                startDate={props.DrugSelectSequence?.selectedDate}
                duration={props.drugDuration}
                onChangeDate={handleChangeDate}
              />
            }
            on="click"
            position="bottom right"
            pinned
            trigger={
              <Button
                size="small"
                color="purple"
                style={{
                  padding: "0.6em 0.75em 0.6rem 0.5rem",
                  marginLeft: "0.5rem",
                  display: "flex",
                  alignItems: "center",
                  background: "#9a4082",
                  width: "9.75rem",
                }}
              >
                <img
                  src={"/static/images/taper-schedule.png"}
                  style={{ width: "1.5rem" }}
                  alt="taper-schedule.icon"
                />
                <label style={{ marginLeft: "10px" }}>Taper Schedule</label>
              </Button>
            }
          />
        }
        // permission
        drugPermission={props.drugPermission}
        roleTypes={props.django?.user?.role_types}
        // Drug search
        drugSearchText={props.drugSearchText}
        onDrugSearchTextChange={(e: any) => {
          props.onEvent({
            message: "DrugSearch",
            params: { keyword: e.target.value },
          });
        }}
        showSearchResult={
          props.selectedDrug === null &&
          (props?.drugSearchText?.length || 0) >= 3 &&
          (props?.drugSearchResult?.length || 0) > 0
        }
        drugSearchResult={drugSearchResult}
        drugRowProps={handleDrugRowProps}
        selectedDrug={props.selectedDrug}
        // Set drug order item detail
        openFactSheet={() => setOpenModFactSheet(true)}
        clearDrugOrderForm={handleClearDrugOrderForm}
        drugPrn={props.drugPrn}
        ChangeDrugPrn={(e: any) => props.setProp("drugPrn", e.target.value)}
        drugDose={props.drugDose}
        // doseUp={() => {
        //   props.controller.setState({
        //     drugDose: (props.drugDose
        //       ? parseInt(props.drugDose) + 1
        //       : 1
        //     ).toString(),
        //   });
        // }}
        changeDrugDose={handleChangeDrugDose}
        drugQty={props.drugQty}
        changeDrugQty={(e: any) =>
          // Todo: validate number
          props.onEvent({
            message: "HandleChangeDrugOrderDetail",
            params: {
              key: "drugQty",
              value: e.target.value,
              card: CARD_DRUG_ORDER,
              orderType,
            },
          })
        }
        drugDuration={props.drugDuration}
        drugDescription={props.drugDescription}
        changeDrugDuration={(e: any) =>
          props.onEvent({
            message: "HandleChangeDrugOrderDetail",
            params: {
              key: "drugDuration",
              value: e.target.value,
              card: CARD_DRUG_ORDER,
              orderType,
            },
          })
        }
        changeDrugDescription={(e: any) =>
          props.onEvent({
            message: "HandleChangeDrugOrderDetail",
            params: {
              key: "drugDescription",
              value: e.target.value,
              card: CARD_DRUG_ORDER,
            },
          })
        }
        drugUnits={props.DrugSelectSequence?.drugUnits || []}
        selectedDrugUnitId={props.DrugSelectSequence?.selectedDrugUnitId}
        changeDrugUnit={(e: any, data: any) =>
          props.onEvent({
            message: "HandleChangeDrugOrderDetail",
            params: {
              key: "selectedDrugUnitId",
              value: data.value,
              stateKey: "DrugSelectSequence",
              card: CARD_DRUG_ORDER,
              orderType,
            },
          })
        }
        drugRoutes={props.DrugSelectSequence?.drugRoutes || []}
        selectedDrugRouteId={props.DrugSelectSequence?.selectedDrugRouteId}
        changeDrugRoute={(e: any, data: any) =>
          props.controller.setState({
            DrugSelectSequence: {
              ...props.DrugSelectSequence,
              selectedDrugRouteId: data.value,
            },
          })
        }
        drugSites={props.DrugSelectSequence?.drugSites || []}
        selectedDrugSiteId={props.DrugSelectSequence?.selectedDrugSiteId}
        changeDrugSite={(e: any, data: any) =>
          props.controller.setState({
            DrugSelectSequence: {
              ...props.DrugSelectSequence,
              selectedDrugSiteId: data.value,
            },
          })
        }
        drugFrequencies={props.DrugSelectSequence?.drugFrequencies || []}
        selectedDrugFrequencyId={
          props.DrugSelectSequence?.selectedDrugFrequencyId
        }
        hideDrugDescription={hideDrugDescription}
        changeDrugFrequency={(e: any, data: any) =>
          props.onEvent({
            message: "HandleChangeDrugOrderDetail",
            params: {
              key: "selectedDrugFrequencyId",
              value: data.value,
              stateKey: "DrugSelectSequence",
              card: CARD_DRUG_ORDER,
              orderType,
            },
          })
        }
        drugMethods={props.DrugSelectSequence?.drugMethods || []}
        selectedDrugMethodId={props.DrugSelectSequence?.selectedDrugMethodId}
        changeDrugMethod={(e: any, data: any) =>
          props.controller.setState({
            DrugSelectSequence: {
              ...props.DrugSelectSequence,
              selectedDrugMethodId: data.value,
            },
          })
        }
        // order div
        onChangeDivision={(e: any, v: any) =>
          props.setProp("drugOrder.order_div", v.value)
        }
        orderDiv={props.drugOrder?.order_div || ""}
        divisionOptions={divisionOptions}
        showToolBox={true}
        // Add to Order
        addToOrder={handleAddToOrder}
        // Order Items
        drugOrderItems={renderDrugOrderItems}
        hasFactSheet={
          !!Object.keys(props.selectedDrug?.original?.factsheet_data || {})
            .length
        }
        doctorNote={props.drugOrder?.doctor_note}
        onChangeDoctorNote={(e: any, v: any) =>
          props.setProp("drugOrder.doctor_note", v.value)
        }
        // selectedDrug to edit
        drugItemsTrProps={handleDrugItemsTrProps}
        // drug order set
        onClickOrderset={(e: any) => {
          props.runSequence({ sequence: "DrugOrderTemplate", restart: true });
        }}
        onClickREMED={(e: any) => {
          props.onEvent({
            message: "HandleReMedication",
            params: { action: "open", card: CARD_DRUG_ORDER_ID },
          });
        }}
        onClickDrugHistory={handleClickDrugHistory}
        // Confirm order
        confirmOrder={() => handleConfirmOrder()}
        // SolventSelectionConfig
        onOpenModSolventSelection={handleOpenModSolventSelection}
        modSolventSelectionConfig={props.modSolventSelectionConfig}
        disabledConfirmedOrderBtn={true}
        readOnlyDuration={
          orderType?.includes("ONE") || orderType?.includes("STAT")
        }
        noRequiredDuration={[
          "ONE_DAY_IPD",
          "STAT_OPD",
          "STAT_IPD",
          "ONE_DOSE_IPD",
          "ONE_DOSE_OPD",
        ].includes(orderType || "")}
      />

      <ModMedReconcileAlert
        // medReconcileCheck={props.medReconcileCheck}
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        onApprove={() => {
          props.setProp(
            "medReconcileCheck.open_card_med_reconciliation",
            false
          );

          props.onEvent({
            message: "OpenMedReconcileFromWarning",
            params: {
              medReconcileCheck: props.medReconcileCheck,
              selecteRecordViewIndex: props.medReconcileIndex,
            },
          });

          // find med reconcile Index
          // if (props.medReconcileIndex !== -1) {
          //   props.setProp("selectedRecordViewIndex", props.medReconcileIndex)
          // }
        }}
      >
        {props.medReconcileCheck?.med_reconciliation != null
          ? `Med Reconcile ${props.medReconcileCheck?.med_reconciliation_label} ยังไม่ได้ถูกรับทราบโดยแพทย์`
          : `ยังไม่ได้สร้าง Medication Reconciliation สำหรับ PlanTransfer ${props.medReconcileCheck?.plan_transfer_id}`}
      </ModMedReconcileAlert>

      <Modal size="tiny" open={!!props.DrugSelectSequence?.adrType}>
        <ModCheckADR
          adrType={props.DrugSelectSequence?.adrType}
          adrDetail={(props.DrugSelectSequence?.adrNotes || "")
            .split("\n")
            .map((note: any, note_index: number) => (
              <div style={{ padding: "5px" }} key={note_index}>
                {note}
              </div>
            ))}
          confirmADRNote={props.DrugSelectSequence?.note_adverse_reaction}
          needNote={["NOTED", "PAUSED"].includes(
            props.DrugSelectSequence?.adrType || ""
          )}
          acknowledge={() => {
            props.onEvent({
              message: "RunSequence",
              params: { sequence: "DrugSelect", feedback: "acknowledge" },
            });
          }}
          confirmOrder={() => {
            props.onEvent({
              message: "RunSequence",
              params: { sequence: "DrugSelect", feedback: "confirm" },
            });
          }}
          cancelOrder={() => {
            props.onEvent({
              message: "RunSequence",
              params: { sequence: "DrugSelect", feedback: "cancel" },
            });
          }}
          changeNote={(e: any) => {
            props.setProp(
              "DrugSelectSequence.note_adverse_reaction",
              e.target.value
            );
          }}
        />
      </Modal>

      <Modal size="tiny" open={!!props.DrugSelectSequence?.diFeedback}>
        <ModCheckDI
          diDetail={(props.DrugSelectSequence?.diItems || []).map(
            (item: any, index: number) => (
              <div key={index} style={{ padding: "5px" }}>
                <div>{item.description}</div>
                {item.members_name.map(
                  (member_name: string, member_index: number) => (
                    <div
                      key={member_index}
                    >{`${member_index}. ${member_name}`}</div>
                  )
                )}
              </div>
            )
          )}
          needNote={!props.DrugSelectSequence?.diLock}
          acknowledge={() => {
            props.onEvent({
              message: "RunSequence",
              params: { sequence: "DrugSelect", feedback: "acknowledge" },
            });
          }}
          confirmOrder={() => {
            props.onEvent({
              message: "RunSequence",
              params: { sequence: "DrugSelect", feedback: "confirm" },
            });
          }}
          cancelOrder={() => {
            props.onEvent({
              message: "RunSequence",
              params: { sequence: "DrugSelect", feedback: "cancel" },
            });
          }}
          changeNote={(e: any) => {
            props.setProp("DrugSelectSequence.confirmDINote", e.target.value);
          }}
        />
      </Modal>

      <Modal open={props.modDoctorCertificate?.open} size="small">
        <ModCheckDoctorCertificateUX
          narcoticTypeDetail={htmlParse(
            props.modDoctorCertificate?.narcoticDrugType || ""
          )}
          doctorCertificate={props.modDoctorCertificate?.doctorCertificate}
          onInputChange={(e: any, v: any) => {
            props.setProp("modDoctorCertificate.doctorCertificate", v.value);
          }}
          onApprove={() => {
            props.onEvent({
              message: "HandleCheckDoctorCertificate",
              params: {
                orderType,
                approvalCallBack: props.onCertificateApprove
                  ? props.onCertificateApprove
                  : null,
                card: CARD_DRUG_ORDER_ID,
                cardKey: CARD_DRUG_ORDER,
                action: "SAVE",
              },
            });
          }}
          onCancel={() => {
            props.setProp("modDoctorCertificate.open", false);
          }}
        />
      </Modal>

      {/* drug-lab interaction */}
      <Modal
        open={props.modDrugLabInteraction?.open}
        closeOnDimmerClick={false}
      >
        <ModDrugLabInteraction
          // main function
          runSequence={props.runSequence}
          setProp={props.setProp}
          onEvent={props.onEvent}
          // data
          modDrugLabInteraction={props.modDrugLabInteraction}
          currentDoctor={props.currentDoctor}
          orderType={orderType}
        />
      </Modal>

      {/* drug-disease */}
      <Modal open={props.modDrugDisease?.open} closeOnDimmerClick={false}>
        <ModDrugDisease
          // main function
          runSequence={props.runSequence}
          setProp={props.setProp}
          onEvent={props.onEvent}
          // data
          modDrugDisease={props.modDrugDisease}
          currentDoctor={props.currentDoctor}
          orderType={orderType}
        />
      </Modal>

      {/* drug-interaction */}
      <Modal open={props.modDrugInteraction?.open} closeOnDimmerClick={false}>
        <ModDrugInteraction
          // main function
          runSequence={props.runSequence}
          setProp={props.setProp}
          onEvent={props.onEvent}
          // data
          modDrugInteraction={props.modDrugInteraction}
          currentDoctor={props.currentDoctor}
          orderType={orderType}
        />
      </Modal>

      <Modal
        open={props.modDoctorCertificate?.openModConfirm}
        style={{ height: "auto", width: "300px" }}
      >
        <ModCertificateErrorUX
          onConfirm={() => {
            props.setProp("modDoctorCertificate.openModConfirm", false);
          }}
        />
      </Modal>

      {/* SolventSelection */}
      <Modal
        closeIcon
        open={props.modSolventSelectionConfig?.openModal}
        closeOnDimmerClick={true}
        onClose={handleCloseModSolventSelection}
      >
        <ModSolventSelection
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // permission
          drugPermission={props.drugPermission}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          // seq
          DrugSelectSequence={props.DrugSelectSequence}
          // Master
          drugUnits={props.DrugSelectSequence?.drugUnits || []}
          // selected data
          selectedDrug={props.selectedDrug}
          // data
          drugOrderType={orderType}
          modSolventSelectionConfig={props.modSolventSelectionConfig}
        />
      </Modal>

      {props.errorMessage?.[CARD_DUPLICATE_REASON]?.error?.code ===
        "DUPLICATE_DRUG_ORDERS" && (
        <CardDuplicateDrugOrders
          duplicateReasonsOptions={props.duplicateReasonsOptions}
          duplicateList={
            props.errorMessage?.[CARD_DUPLICATE_REASON]?.error?.duplicate_list
          }
          onClose={() =>
            props.setProp("errorMessage", {
              ...props.errorMessage,
              [CARD_DUPLICATE_REASON]: null,
            })
          }
          onApprove={(data: any) => {
            handleConfirmOrder({ ...data, confirm_duplicated_drugs: true });
          }}
        />
      )}

      <ModDrugOrderTemplate
        runSequence={props.runSequence}
        DrugOrderTemplateSequence={props.DrugOrderTemplateSequence}
        currentDoctor={props.currentDoctor}
        card={CARD_DRUG_ORDER}
        orderType={orderType}
      />

      <Modal open={props.modReMedication?.open}>
        <ModReMedicationUX
          onSearchReMED={() => {
            props.onEvent({
              message: "HandleReMedication",
              params: { action: "search", card: CARD_DRUG_ORDER_ID },
            });
          }}
          doctorSearchBox={
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                style={{ marginRight: "5px" }}
                checked={props.modReMedication?.checkedDoctor}
                onChange={(e: any, { checked }) => {
                  props.setProp("modReMedication", {
                    ...props.modReMedication,
                    checkedDoctor: checked,
                    doctor: null,
                  });
                }}
              />
              <SearchBox
                // vertical={true}
                disabled={!props.modReMedication?.checkedDoctor}
                onEvent={props.onEvent}
                setProp={props.setProp}
                searchedItemList={props.searchedItemList}
                selectedItem={props.modReMedication?.doctor}
                setSelectedItem={(item: any) => {
                  props.setProp("modReMedication.doctor", item);
                }}
                type="Doctor"
                label=" แพทย์"
                toDisplay={(item: any) => `${item.name_code}`}
                fluid={true}
                style={{ width: "100%" }}
              />
            </div>
          }
          divisionSearchBox={
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                style={{ marginRight: "5px" }}
                checked={props.modReMedication?.checkedDivision}
                onChange={(e: any, { checked }) => {
                  props.setProp("modReMedication", {
                    ...props.modReMedication,
                    checkedDivision: checked,
                    division: null,
                  });
                }}
              />
              <SearchBox
                // vertical={true}
                disabled={!props.modReMedication?.checkedDivision}
                onEvent={props.onEvent}
                setProp={props.setProp}
                searchedItemList={props.searchedItemList}
                selectedItem={props.modReMedication?.division}
                setSelectedItem={(item: any) => {
                  props.setProp("modReMedication.division", item);
                }}
                type="Division"
                label=" แผนก"
                // placeholder="กรอกชื่อผู้จ่ายเงินที่ต้องการค้นหา"
                toDisplay={(item: any) => `${item.name_code}`}
                fluid={true}
                style={{ width: "100%" }}
              />
            </div>
          }
          remedData={remedList}
          onClickAddToOrder={() => {
            props.onEvent({
              message: "HandleReMedication",
              params: {
                action: "add_to_order",
                card: CARD_DRUG_ORDER_ID,
                orderType,
              },
            });
          }}
          onClickCancel={() => {
            props.onEvent({
              message: "HandleReMedication",
              params: { action: "close", card: CARD_DRUG_ORDER_ID },
            });
          }}
        />
      </Modal>

      {/* Recontinue Medication */}
      <Modal open={props.modRecontinueMed?.open} size="large">
        <ModRecontinueMed
          // main function
          onEvent={props.onEvent}
          card={CARD_DRUG_ORDER}
          // data
          modRecontinueMed={props.modRecontinueMed}
          searchedItemListWithKey={props.searchedItemListWithKey}
          orderType={orderType}
        />
      </Modal>

      <Modal
        open={openModDrugHistory}
        style={{
          width: "85%",
        }}
        closeIcon={true}
        closeOnDimmerClick={true}
        onClose={handleCloseModDrugHistory}
      >
        <div style={{ maxHeight: "93vh", overflowY: "auto", padding: "1rem" }}>
          <CardDrugOrderHistory
            isDrugOrderHistory={true}
            frezzeRecordViewIndex={true}
            DrugOrderHistorySequence={props.DrugOrderHistorySequence}
            drugHistoryList={props.drugHistoryList}
            drugHistoryOrderList={props.drugHistoryOrderList}
            controller={props.drugOrderHistoryController}
            runSequence={props.runSequence}
            onEvent={props.onEvent}
            setProp={props.setProp}
            selectedEncounter={props.selectedEncounter}
            selectedEmr={props.selectedEmr}
            masterOptions={props.masterOptions}
            errorMessage={props.errorMessage}
            loadingStatus={props.loadingStatus}
            successMessage={props.successMessage}
            selectedDivision={props.selectedDivision}
            searchedItemListWithKey={props.searchedItemListWithKey}
            // config
            addToOrderMode={true}
            // callback
            onAddToOrder={handleDrugHistoryAddToOrder}
            type={"PATIENT_HISTORY"} // for flag selectEncounter in function SelectDrugOrderWorking
          />
        </div>
      </Modal>

      <ModConfirmDelete
        open={props.modConfirmDeleteDrug?.open}
        titleName="ต้องการลบรายการยาหรือไม่"
        size="mini"
        buttonRightColor="red"
        buttonRight="ยกเลิก"
        buttonLeftColor="green"
        buttonLeft="ตกลง"
        onButtonLeftClick={() => {
          props.onEvent({
            message: "HandleOnRemoveDrug",
            params: {
              action: "confirm",
              removeDrugId: props.modConfirmDeleteDrug.product,
              drugIndex: props.modConfirmDeleteDrug.index,
            },
          });
        }}
        onButtonRightClick={() => {
          props.setProp("modConfirmDeleteDrug.open", false);
        }}
        titleColor={"yellow"}
      />

      <ModDrugAllVerify
        onEvent={props.onEvent}
        setProp={props.setProp}
        // data
        modAllVerifyDrug={props.modAllVerifyDrug}
      />

      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(
            `errorMessage.${CARD_DRUG_ORDER_ID}.showMessage`,
            false
          );
        }}
        error={props.errorMessage?.[CARD_DRUG_ORDER_ID]?.error}
        success={props.successMessage?.[CARD_DRUG_ORDER_ID]}
      />

      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_DRUG_ORDER}`, null);
        }}
        error={props.errorMessage?.[CARD_DRUG_ORDER]?.error}
        success={props.successMessage?.[CARD_DRUG_ORDER]}
      />

      {props.errorMessage?.[CARD_DUPLICATE_REASON]?.error?.code !==
        "DUPLICATE_DRUG_ORDERS" &&
        props.errorMessage?.[CARD_DUPLICATE_REASON]?.error && (
          <SnackMessage
            onEvent={props.onEvent}
            onClose={() => {
              props.setProp(`errorMessage.${CARD_DUPLICATE_REASON}`, null);
            }}
            error={props.errorMessage?.[CARD_DUPLICATE_REASON]?.error}
            success={null}
          />
        )}

      {props.errorMessage?.[CARD_DUPLICATE_CLASSIFICATION_REASON] && (
        <CardDuplicateDrugClassificationOrders
          // function
          onClose={() =>
            props.setProp("errorMessage", {
              ...props.errorMessage,
              [CARD_DUPLICATE_CLASSIFICATION_REASON]: null,
            })
          }
          onApprove={handleConfirmDrugGroupDuplicateWithReason}
          // options
          duplicateReasonsOptions={props.duplicateReasonsOptions}
          // data
          duplicateList={
            props.errorMessage?.[CARD_DUPLICATE_CLASSIFICATION_REASON]
          }
        />
      )}

      {props.drugOrder?.estimate?.isAdd && (
        <CardDrugClaimQA
          estimate={props.drugOrder?.estimate || {}}
          orderType={orderType}
          selectedEncounter={props.selectedEncounter}
          selectedEmr={props.selectedEmr}
          selectedProgressCycle={props.selectedProgressCycle}
          onEvent={props.onEvent}
        />
      )}

      <ModDrugFact
        open={openModFactSheet}
        drugDetail={props.selectedDrug?.original}
        // callback
        onClose={() => setOpenModFactSheet(false)}
      />

      {/* Modal add note for confirm order with pharmacist */}
      <Modal
        open={modPharmaConfirm?.open}
        closeOnDimmerClick={false}
        size="tiny"
      >
        <Modal.Header style={{ backgroundColor: "#f6ad24", color: "white" }}>
          กรุณาใส่เหตุผล ในการแก้ไขรายละเอียดรายการยา
        </Modal.Header>
        <Modal.Content>
          <div style={{ padding: "10px" }}>
            <Input
              style={{ width: "100%", padding: "10px" }}
              value={modPharmaConfirm?.note}
              onChange={(e: any, data: any) => {
                setModPharmaConfirm({ ...modPharmaConfirm, note: data.value });
              }}
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{ marginRight: "15px" }}
                color="green"
                content={"ยืนยันการแก้ไข"}
                onClick={() => {
                  handleConfirmOrder();
                }}
              />
              <Button
                color="red"
                content={"ยกเลิก"}
                onClick={() => {
                  setModPharmaConfirm({
                    open: false,
                    note: "",
                  });
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>

      {/* Modal Med Reconcile Duplicate Drug */}
      <Modal open={modMRDuplicate?.open} closeOnDimmerClick={false} size="tiny">
        <Modal.Header style={{ backgroundColor: "#fbbd08", color: "white" }}>
          แจ้งเตือน
        </Modal.Header>
        <Modal.Content>
          <div>
            <div style={{ fontSize: "16px" }}>
              {htmlParse(modMRDuplicate?.message)}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "15px",
              }}
            >
              <Button
                inverted
                color="yellow"
                content="ตกลง"
                onClick={() => {
                  setModMRDuplicate({ open: false, message: "" });
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>

      <ModDescriptionUD
        open={!!modDrugDesc.open}
        description={modDrugDesc.desc}
        // config
        editable={true}
        // callback
        onApprove={handleConfirmDesc}
        onClose={handleCloseModDesc}
      />

      <ModDrugPackageAlert
        onEvent={props.onEvent}
        setProp={props.setProp}
        // seq
        runSequence={props.runSequence}
        // data
        items={props.drugPackageAlertList}
        card={CARD_DRUG_ORDER}
        orderType={orderType}
        // callback
        onClose={handleCloseDrugPackageAlert}
      />
    </div>
  );
};

export default React.memo(CardDrugOrder);
