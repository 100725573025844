import React, {
  CSSProperties,
  useCallback,
  useMemo,
  useEffect,
  useState,
  SyntheticEvent,
} from "react";
import { Checkbox, Icon, Label, Pagination, Popup } from "semantic-ui-react";

// UX
import CardImagingWorkListUX from "./CardImagingWorkListUX";
import ActionButton from "./ActionButton";
import ModExecuteRadiologyOrder from "./ModExecuteRadiologyOrder";
import ModCancelImagingOrder, {
  ApproveDataType,
} from "./ModCancelImagingOrder";
import ModImagingAuditLog from "./ModImagingAuditLog";

// Common
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ModXrayDetail, { ButtonReadyToView } from "./ModXrayDetail";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Interface
import {
  State,
  RunSequence,
  ORDER_STATUS_COLORS,
  BILLING_STATUS_COLORS,
  ORDER_STATUS,
  MasterOptionsType,
  ORDER_STATUS_OPTIONS,
  BILLING_STATUS_OPTIONS,
  ENCOUNTER_TYPE_OPTIONS,
  ImagingQueueSerializer,
  WORK_LIST_LIMIT,
  BILLING_STATUS,
  RADIOLOGIST_SEARCH_ID,
  ORDER_ITEM_STATUS_LABEL,
  checkReadyToView,
} from "./sequence/ImagingWorkList";
import { State as OrderState } from "./sequence/ImagingOrder";
import ModalGallery from "./ModalGallery";
import {
  ActionStatusType,
  ReportActionStatusType,
} from "./sequence/ImagingResult";

// Const
const PATIENT_SEARCH_ID = "Patient_IWL";

// Types
type CardImagingWorkListProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: RunSequence;
  ImagingWorkListSequence: State["ImagingWorkListSequence"];
  // options
  masterOptions?: MasterOptionsType;
  // data
  userId?: number;
  userName?: string;
  doctorDetail?: { id: number; name_code: string };
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  userTokenize?: Record<string, any>;
  modXrayDetail: OrderState["modXrayDetail"];
  errorMessage?: Record<string, any>;
  // callback
  forward?: () => any;
  // Dental disgram
  clinicalFindingId?: number | null;
  clinicalFindingIndex?: number | null;
  clinicalFindings: Record<string, any>[];
  filterClinicalFindingIds: number[];
  organ?: { name: string; items: any[] };
  clinicaltags?: { name: string; items: any[] };
  // ----- #
};

// Const
const IMAGES = {
  male: "/static/images/x-ray/male.png",
  female: "/static/images/x-ray/female.png",
  comment_green: "/static/images/x-ray/comment-outline-green.png",
  comment_grey: "/static/images/x-ray/comment-outline-grey.png",
};

const GENDER_ICONS = {
  M: {
    src: IMAGES.male,
    alt: "blue male symbol",
    style: { width: "13px", marginLeft: "0.65rem" },
  },
  F: {
    src: IMAGES.female,
    alt: "blue female symbol",
    style: { width: "10px", marginLeft: "0.75rem" },
  },
};

const BUTTON_ACTIONS = {
  search: "SEARCH",
  approve: "APPROVE_RESULT",
  register: "REGISTER",
  update_status: "UPDATE_STATUS",
  ready_to_view: "READY_TO_VIEW",
} as const;

const GridCenter = {
  display: "grid",
  placeContent: "center",
  textAlign: "center",
} as CSSProperties;

const CARD_IMAGING_WORK_LIST = "CardImagingWorkList";

const CardImagingWorkList = (props: CardImagingWorkListProps) => {
  const [modActionOrder, setModActionOrder] = useState<{
    data: any;
    action: "Unapprove" | "UNEXECUTE" | "Approve";
    orderStatus: "REGISTERED" | "reported" | "APPROVED";
    authUsrPass?: boolean;
    title?: string;
    infoOnly?: boolean;
    hideReason?: boolean;
    backgroundColor?: CSSProperties["backgroundColor"];
  } | null>(null);
  const [modExecute, setModExecute] = useState<{
    orderItemIds: number[];
  } | null>(null);
  const [modAuditLog, setModAuditLog] = useState<ImagingQueueSerializer | null>(
    null
  );

  const [popupIndex, setPopupIndex] = useState<number>(-1);

  const [checkedItems, setCheckedItems] = useState<ImagingQueueSerializer[]>(
    []
  );

  useEffect(() => {
    const index = props.ImagingWorkListSequence?.sequenceIndex;

    if (!index) {
      props.runSequence({
        sequence: "ImagingWorkList",
        restart: true,
        card: CARD_IMAGING_WORK_LIST,
        doctorDetail: props.doctorDetail,
      });
    } else {
      handleSearch(props.ImagingWorkListSequence?.orderQueue?.activePage || 1);
    }
  }, []);

  useEffect(() => {
    const items = props.ImagingWorkListSequence?.orderQueue?.items || [];

    setCheckedItems((checkedItems) =>
      checkedItems.flatMap((item) =>
        items.find((acc) => acc.id === item.id && acc.status !== item.status)
          ? []
          : [item]
      )
    );
  }, [props.ImagingWorkListSequence?.orderQueue?.items]);

  // callback
  const handleSelectedItem = useCallback(
    (data: any) => async (value: any, key: any) => {
      props.setProp(`ImagingWorkListSequence.${data.name}`, value || null);
    },
    [props.searchedItemListWithKey]
  );

  const handleOpenModAuditLog = useCallback(
    (item: ImagingQueueSerializer, index: number) => () => {
      props.runSequence({
        sequence: "ImagingWorkList",
        action: "GET_AUDIT_LOG",
        orderItemId: item.id,
      });

      setModAuditLog(item);
      setPopupIndex(-1);
    },
    []
  );

  const handleOpenModDetail = useCallback(
    (data: any) => (e: SyntheticEvent) => {
      e.stopPropagation();

      props.runSequence({
        sequence: "ImagingWorkList",
        action: "MOD_XRAY",
        type: "OPEN",
        data,
      });
    },
    []
  );

  const handleGotoEncounter = useCallback(
    async (
      item: ImagingQueueSerializer & { readOnly?: boolean },
      menu: "Imaging Order" | "Imaging Result"
    ) => {
      props.runSequence({
        sequence: "ImagingWorkList",
        action: "GO_TO_ENCOUNTER",
        item,
        menu,
        forward: props.forward,
      });
    },
    [props.masterOptions?.division]
  );

  const handleChecked = useCallback(
    (item: ImagingQueueSerializer) => (_e: any, data: any) => {
      setCheckedItems((checked) =>
        data.checked
          ? [...checked, item]
          : checked.filter((value) => value.id !== item.id)
      );
    },
    []
  );

  const handleRegister = useCallback(
    (data: { orderItems: { id: number }[]; index: number }) => {
      handleUpdateStatus({
        orderItems: data.orderItems,
        action: "REGISTER",
        index: data.index,
      });
    },
    []
  );

  const handleClickPacs = useCallback(
    (data: ImagingQueueSerializer, index: number) => () => {
      props.runSequence({
        sequence: "ImagingWorkList",
        action: "OPEN_PACS",
        card: CARD_IMAGING_WORK_LIST,
        data,
        index,
      });
    },
    []
  );

  const handleReadyToView = useCallback(
    (data: { id: number; ready_to_view: boolean }) => () => {
      handleUpdateStatus({
        index: data.id,
        orderItems: [{ id: data.id, ready_to_view: !data.ready_to_view }],
        action: data.ready_to_view ? "UNREADY_TO_VIEW" : "READY_TO_VIEW",
      });
    },
    []
  );

  const orderList = useMemo(() => {
    return props.ImagingWorkListSequence?.orderQueue?.items || [];
  }, [props.ImagingWorkListSequence?.orderQueue]);

  const isCheckAll = useMemo(() => {
    const list = orderList.filter((item) =>
      ([ORDER_STATUS.REGISTERED, ORDER_STATUS.REQUESTED] as any).includes(
        ORDER_STATUS[item.status]
      )
    );

    return !!checkedItems.length && checkedItems.length === list.length;
  }, [checkedItems, orderList]);

  const checkedRegisterItems = useMemo(() => {
    return orderList.filter(
      (item) =>
        ORDER_STATUS[item.status] === ORDER_STATUS.REQUESTED &&
        checkedItems.find((acc) => acc.id === item.id)
    );
  }, [checkedItems, orderList]);

  const checkedExecuteItems = useMemo(() => {
    return orderList.filter(
      (item) =>
        ORDER_STATUS[item.status] === ORDER_STATUS.REGISTERED &&
        checkedItems.find((acc) => acc.id === item.id)
    );
  }, [checkedItems, orderList]);

  const allowReadyToView = useMemo(() => {
    const status = props.modXrayDetail?.status;

    return !!status && checkReadyToView(ORDER_STATUS[status]);
  }, [props.modXrayDetail]);

  const orderItems = useMemo(() => {
    return orderList.map((item, index) => {
      const icon = GENDER_ICONS[item.patient_gender];
      const status = ORDER_STATUS[item.status];

      return {
        ...item,
        datetime: <div style={GridCenter}>{item.created}</div>,
        order_no: <div style={GridCenter}>{item.order_number}</div>,
        division_name: <div style={GridCenter}>{item.order_division}</div>,
        type: <div style={GridCenter}>{item.group_name}</div>,
        doctor_name: <div style={GridCenter}>{item.care_provider}</div>,
        check: (
          <div style={GridCenter}>
            {(
              [ORDER_STATUS.REGISTERED, ORDER_STATUS.REQUESTED] as any
            ).includes(status) && (
              <Checkbox
                checked={!!checkedItems.find((acc) => acc.id === item.id)}
                onChange={handleChecked(item)}
              />
            )}
          </div>
        ),
        patient: (
          <div style={{ lineHeight: 1.75 }}>
            <div>{item.patient_name}</div>
            <div>HN: {item.patient_hn}</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>DOB: {item.patient_dob}</div>
              {!!icon && (
                <img
                  src={icon.src}
                  alt={icon.alt}
                  style={{
                    ...icon.style,
                    height: "100%",
                    marginBottom: "1px",
                  }}
                />
              )}
            </div>
          </div>
        ),
        request: (
          <div style={{ height: "100%", position: "relative" }}>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 30px" }}>
              <div>{item.product_name}</div>
              <div>
                <img
                  src={!!item.note ? IMAGES.comment_green : IMAGES.comment_grey}
                  alt={
                    !!item.note ? "green speech bubble" : "grey speech bubble"
                  }
                  // style
                  style={{
                    width: "25px",
                    cursor: "pointer",
                    marginLeft: "3px",
                  }}
                  onClick={handleOpenModDetail(item)}
                />
              </div>
            </div>

            <ButtonReadyToView
              active={item.ready_to_view}
              hideLabel={true}
              disabled={!checkReadyToView(item.status)}
              loading={
                !!props.buttonLoadCheck?.[
                  `${CARD_IMAGING_WORK_LIST}_${BUTTON_ACTIONS.ready_to_view}_${item.id}`
                ]
              }
              // style
              style={{
                margin: "-4px -4px 0 0",
                transform: "scale(0.75)",
                float: "right",
              }}
              checkboxSx={{ "& .MuiSvgIcon-root": { fontSize: "1.25rem" } }}
              // callback
              onClick={handleReadyToView(item)}
            />
          </div>
        ),
        status: (
          <div style={GridCenter}>
            {(
              [
                ["Item", status, ORDER_STATUS_COLORS],
                [
                  "Billing",
                  BILLING_STATUS[item.payment_status],
                  BILLING_STATUS_COLORS,
                ],
              ] as const
            ).map((acc) => (
              <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                <div style={{ width: "44px", textAlign: "left" }}>
                  {acc[0]}:{" "}
                </div>
                <Label
                  style={{
                    width: "75px",
                    marginLeft: "0.5rem",
                    backgroundColor: (acc[2] as any)[acc[1]],
                    color: "white",
                    fontSize: "0.7rem",
                    textAlign: "center",
                  }}
                >
                  {acc[1]}
                </Label>
              </div>
            ))}
          </div>
        ),
        radiologist: (
          <div style={GridCenter}>
            {(
              [ORDER_STATUS.REQUESTED, ORDER_STATUS.REGISTERED] as any
            ).includes(status) ? (
              <div style={{ color: "#D9D9D9" }}>{"Waiting for execute"}</div>
            ) : item.status === ORDER_STATUS.EXECUTED ? (
              <>
                {item.radiologist}
                {item.radiologist_id === props.doctorDetail?.id && (
                  <ActionButton
                    icon="report"
                    badgeSx={{ justifyContent: "center" }}
                    onClick={() => handleGotoEncounter(item, "Imaging Result")}
                  />
                )}
              </>
            ) : item.status === ORDER_STATUS.REPORTED ? (
              <>
                {item.radiologist}
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns:
                      item.radiologist_id === props.doctorDetail?.id
                        ? "50% 1fr"
                        : "100%",
                  }}
                >
                  {item.radiologist_id === props.doctorDetail?.id && (
                    <ActionButton
                      icon="edit"
                      badgeSx={{ justifyContent: "center", minWidth: "50px" }}
                      onClick={() =>
                        handleGotoEncounter(item, "Imaging Result")
                      }
                    />
                  )}
                  {/* {item.radiologist_id === props.doctorDetail?.id && ( */}
                  <ActionButton
                    icon="approve"
                    badgeSx={{ justifyContent: "center", minWidth: "50px" }}
                    loading={
                      !!props.buttonLoadCheck?.[
                        `${CARD_IMAGING_WORK_LIST}_${BUTTON_ACTIONS.approve}_${item.id}`
                      ]
                    }
                    onClick={() => {
                      setModActionOrder({
                        data: item,
                        action: "Approve",
                        orderStatus: "APPROVED",
                        authUsrPass: true,
                        hideReason: true,
                        title: "Approve Imaging Order",
                        backgroundColor: "var(--primary-theme-color)",
                      });
                    }}
                  />
                  {/* )} */}
                </div>
              </>
            ) : item.status === ORDER_STATUS.APPROVED ? (
              <>
                {item.radiologist}
                {/* {item.radiologist_id === props.doctorDetail?.id && ( */}
                <ActionButton
                  icon="unapprove"
                  badgeSx={{ justifyContent: "center" }}
                  onClick={() =>
                    setModActionOrder({
                      data: item,
                      action: "Unapprove",
                      orderStatus: "reported",
                      authUsrPass: true,
                      hideReason: true,
                    })
                  }
                />
                {/* )} */}
              </>
            ) : null}
          </div>
        ),
        action: (
          <div style={{ ...GridCenter, height: "100%", position: "relative" }}>
            <Popup
              open={popupIndex === index}
              position="bottom right"
              on="click"
              pinned
              // callback
              onClose={() => setPopupIndex(-1)}
              // Element
              content={
                <>
                  <div
                    style={{
                      padding: "0 0 11px",
                      borderBottom: "1px solid rgba(0,0,0,0.15)",
                      cursor: "pointer",
                    }}
                    onClick={() => handleGotoEncounter(item, "Imaging Order")}
                  >
                    Imaging Order
                  </div>
                  <div
                    style={{ padding: "9px 0 1px", cursor: "pointer" }}
                    onClick={handleOpenModAuditLog(item, index)}
                  >
                    Audit Log
                  </div>
                </>
              }
              trigger={
                <Icon
                  role="button"
                  aria-label=""
                  name="ellipsis vertical"
                  style={{
                    position: "absolute",
                    top: "-3px",
                    right: "-7px",
                    fontSize: "0.85rem",
                    cursor: "pointer",
                  }}
                  onClick={() => setPopupIndex(index)}
                />
              }
            />

            {status === ORDER_STATUS.REQUESTED ? (
              <ActionButton
                icon="register"
                loading={
                  !!props.buttonLoadCheck?.[
                    `${CARD_IMAGING_WORK_LIST}_${BUTTON_ACTIONS.register}_${index}`
                  ]
                }
                onClick={() =>
                  handleRegister({ orderItems: [{ id: item.id }], index })
                }
              />
            ) : item.status === ORDER_STATUS.REGISTERED ? (
              <ActionButton
                icon="execute"
                onClick={() => setModExecute({ orderItemIds: [item.id] })}
              />
            ) : (
                [
                  ORDER_STATUS.EXECUTED,
                  ORDER_STATUS.REPORTED,
                  ORDER_STATUS.APPROVED,
                ] as any
              ).includes(item.status) ? (
              <div style={{ display: "grid", gridTemplateColumns: "56% 1fr" }}>
                <ActionButton
                  icon="result"
                  badgeSx={{ justifyContent: "center", minWidth: "50px" }}
                  onClick={() =>
                    handleGotoEncounter(
                      { ...item, readOnly: true },
                      "Imaging Result"
                    )
                  }
                />
                <ActionButton
                  icon={item.ready_to_view ? "pacs" : "pacs_grey"}
                  disabled={!item.ready_to_view}
                  badgeSx={{ justifyContent: "center", minWidth: "50px" }}
                  onClick={handleClickPacs(item, index)}
                />
                {item.status === ORDER_STATUS.EXECUTED && (
                  <ActionButton
                    icon="unexecute"
                    badgeSx={{ justifyContent: "center", minWidth: "50px" }}
                    onClick={() =>
                      setModActionOrder({
                        data: item,
                        action: "UNEXECUTE",
                        orderStatus: "REGISTERED",
                        infoOnly: true,
                        title: "Unexecute Radiology Order",
                      })
                    }
                  />
                )}
              </div>
            ) : null}
          </div>
        ),
      };
    });
  }, [
    props.buttonLoadCheck,
    props.doctorDetail,
    props.masterOptions?.division,
    orderList,
    checkedItems,
    popupIndex,
  ]);

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    const value = typeof data.checked === "boolean" ? data.checked : data.value;
    const name = data.name;

    props.setProp(`ImagingWorkListSequence.filter.${name}`, value);
  };

  const handleChangeDate = (name: string) => (value: string) => {
    handleChangeValue(null, { name, value });
  };

  const handleUpdateStatus = (data: {
    orderItems: any[];
    action: ReportActionStatusType | ActionStatusType;
    index?: number;
    onSuccess?: () => any;
  }) => {
    props.runSequence({
      sequence: "ImagingWorkList",
      action: "UPDATE_STATUS",
      card: CARD_IMAGING_WORK_LIST,
      orderItems: data.orderItems,
      actionType: data.action,
      index: data.index,
      onSuccess: data.onSuccess,
    });
  };

  const handleConfirmActionOrder = (data: ApproveDataType) => {
    if (!!modActionOrder) {
      if (
        ["Unapprove", "Approve", "UNEXECUTE"].includes(modActionOrder?.action)
      ) {
        handleUpdateStatus({
          orderItems: [
            {
              id: modActionOrder.data.id,
              username: data.username || undefined,
              passowrd: data.password || undefined,
            },
          ],
          action: modActionOrder.action.toUpperCase() as any,
          onSuccess: () => {
            handleCloseModActionOrder();

            data.onClear?.();
          },
        });
      }
    }
  };

  const handleCloseModActionOrder = () => {
    setModActionOrder(null);
  };

  const handleCloseModExecute = () => {
    setModExecute(null);
  };

  const handleCloseModLog = () => {
    setModAuditLog(null);

    props.setProp("ImagingWorkListSequence.auditLogList", []);
  };

  const handleCloseModXray = () => {
    props.runSequence({
      sequence: "ImagingWorkList",
      action: "MOD_XRAY",
      type: "CLOSE",
    });
  };

  const handleSearch = (page: number) => {
    setCheckedItems([]);

    props.runSequence({
      sequence: "ImagingWorkList",
      action: "SEARCH",
      card: CARD_IMAGING_WORK_LIST,
      activePage: page,
    });
  };

  const handleCheckedAll = (e: any, data: any) => {
    const list = orderList.filter((item) =>
      ([ORDER_STATUS.REGISTERED, ORDER_STATUS.REQUESTED] as any).includes(
        ORDER_STATUS[item.status]
      )
    );

    setCheckedItems(data.checked ? [...list] : []);
  };

  const handleOpenModExecute = () => {
    if (!!checkedExecuteItems.length) {
      setModExecute({
        orderItemIds: checkedExecuteItems.map((item) => item.id),
      });
    }
  };

  const handleClickRegister = () => {
    if (!!checkedRegisterItems.length) {
      handleRegister({
        orderItems: checkedRegisterItems.map((item) => ({ id: item.id })),
        index: -1,
      });
    }
  };

  const handlePageChange = (e: any, data: any) => {
    handleSearch(data.activePage);
  };

  const handleCloseModalGallery = () => {
    props.setProp("ImagingWorkListSequence.pacsGalleryDetail", null);
  };

  const handleSaveXray = () => {
    const detail = props.modXrayDetail;

    if (!!detail) {
      handleReadyToView({
        id: detail.id,
        ready_to_view: detail.ready_to_view,
      })();

      handleCloseModXray();
    }
  };

  console.log("CardImagingWorkList", props);

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_IMAGING_WORK_LIST}`, null);
        }}
        error={props.errorMessage?.[CARD_IMAGING_WORK_LIST]}
        success={null}
      />

      <CardImagingWorkListUX
        // data
        orderList={orderItems}
        filter={props.ImagingWorkListSequence?.filter}
        // table
        headers={[
          <Checkbox checked={isCheckAll} onChange={handleCheckedAll} />,
          ..."Order date/Time,Order No.,Patient,Order department,Careprovider,Type,Request,Status,Radiologist,".split(
            ","
          ),
        ]}
        keys={
          "check,datetime,order_no,patient,division_name,doctor_name,type,request,status,radiologist,action"
        }
        // options
        divisionOptions={props.masterOptions?.division}
        orderStatusOptions={ORDER_STATUS_OPTIONS}
        billingStatusOptions={BILLING_STATUS_OPTIONS}
        encounterTypeOptions={ENCOUNTER_TYPE_OPTIONS}
        // callback
        onChangeValue={handleChangeValue}
        onChangeDate={handleChangeDate}
        // Element
        IconRegister={
          <ActionButton
            icon="register"
            badgeContent={checkedRegisterItems.length}
            loading={
              !!props.buttonLoadCheck?.[
                `${CARD_IMAGING_WORK_LIST}_${BUTTON_ACTIONS.register}_${-1}`
              ]
            }
            onClick={handleClickRegister}
          />
        }
        IconExecute={
          <ActionButton
            icon="execute"
            badgeContent={checkedExecuteItems.length}
            onClick={handleOpenModExecute}
          />
        }
        SearchBoxRadiologist={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="Doctor"
            id="IWL"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={
              props.ImagingWorkListSequence?.filter?.radiologist || null
            }
            setSelectedItem={handleSelectedItem({
              id: RADIOLOGIST_SEARCH_ID,
              name: "filter.radiologist",
            })}
          />
        }
        SearchBoxPatient={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="Patient"
            id="IWL"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={
              props.ImagingWorkListSequence?.filter?.patient || null
            }
            setSelectedItem={handleSelectedItem({
              id: PATIENT_SEARCH_ID,
              name: "filter.patient",
            })}
          />
        }
        SearchBoxOrderNo={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="SearchType"
            id="IWL"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={
              props.ImagingWorkListSequence?.filter?.orderNo || null
            }
            setSelectedItem={handleSelectedItem({
              id: PATIENT_SEARCH_ID,
              name: "filter.patient",
            })}
          />
        }
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => handleSearch(1)}
            // data
            paramKey={`${CARD_IMAGING_WORK_LIST}_${BUTTON_ACTIONS.search}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_IMAGING_WORK_LIST}_${BUTTON_ACTIONS.search}`
              ]
            }
            // config
            color={"blue"}
            name={BUTTON_ACTIONS.search}
            size="medium"
            title="ค้นหา"
            fluid={true}
            style={{ minWidth: "max-content" }}
          />
        }
        // Element
        Pagination={
          <Pagination
            activePage={
              props.ImagingWorkListSequence?.orderQueue?.activePage || 0
            }
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            totalPages={Math.ceil(
              (props.ImagingWorkListSequence?.orderQueue?.total || 0) /
                WORK_LIST_LIMIT
            )}
            size="mini"
            // callback
            onPageChange={handlePageChange}
          />
        }
      />

      <ModCancelImagingOrder
        onEvent={props.onEvent}
        setProp={props.setProp}
        // data
        open={!!modActionOrder}
        action={modActionOrder?.action || ""}
        orderStatus={modActionOrder?.orderStatus || ""}
        card={CARD_IMAGING_WORK_LIST}
        title={modActionOrder?.title}
        backgroundColor={modActionOrder?.backgroundColor}
        buttonKey={`${modActionOrder?.action.toUpperCase()}`}
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        userTokenize={props.userTokenize}
        // options
        cancelImagingOrderOptions={props.masterOptions?.cancelImagingOrder}
        // config
        hideReason={!!modActionOrder?.hideReason}
        authUsrPass={modActionOrder?.authUsrPass}
        infoOnly={modActionOrder?.infoOnly}
        // callback
        onApprove={handleConfirmActionOrder}
        onClose={handleCloseModActionOrder}
      />

      <ModExecuteRadiologyOrder
        onEvent={props.onEvent}
        setProp={props.setProp}
        // seq
        runSequence={props.runSequence}
        // data
        open={!!modExecute}
        userName={props.userName}
        userId={props.userId}
        orderItemIds={modExecute?.orderItemIds}
        card={CARD_IMAGING_WORK_LIST}
        // CommonInterface
        searchedItemListWithKey={props.searchedItemListWithKey}
        buttonLoadCheck={props.buttonLoadCheck}
        // callback
        onClose={handleCloseModExecute}
      />

      <ModImagingAuditLog
        // data
        open={!!modAuditLog}
        data={modAuditLog || {}}
        auditLogList={props.ImagingWorkListSequence?.auditLogList || []}
        // callback
        onClose={handleCloseModLog}
      />

      <ModXrayDetail
        onEvent={props.onEvent}
        setProp={props.setProp}
        // data
        open={!!props.modXrayDetail}
        data={props.modXrayDetail}
        clinicalFindingId={props.clinicalFindingId}
        clinicalFindingIndex={props.clinicalFindingIndex}
        masterData={{ organ: props.organ, clinicaltags: props.clinicaltags }}
        clinicalFindings={props.clinicalFindings}
        filterClinicalFindingIds={props.filterClinicalFindingIds}
        // options
        eligibilityOptions={props.masterOptions?.eligibilityType}
        // config
        readOnly={true}
        allowReadyToView={allowReadyToView}
        // callback
        onClose={handleCloseModXray}
        onSave={handleSaveXray}
      />

      <ModalGallery
        open={!!props.ImagingWorkListSequence?.pacsGalleryDetail}
        data={props.ImagingWorkListSequence?.pacsGalleryDetail || null}
        onClose={handleCloseModalGallery}
      />
    </div>
  );
};

export default React.memo(CardImagingWorkList);
