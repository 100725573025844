import React, { useEffect } from "react";
// ui ORM
import CardSurgicalSafetyChecklistTimeoutUX from "./CardSurgicalSafetyChecklistTimeoutUX";
import { PreOPButtonAction } from "./CardPreOPVisit";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";

const CARD_KEY: string = "surgicalSafetyChecklistTimeOut";
const FORM_CODE: string = "CardSurgicalSafetyChecklistTimeOut";
const FORM_NAME: string = "Surgical Safety Checklist - Timeout";
const FORM_VERSION: string = "0.1";

type CardSurgicalSafetyChecklistTimeoutProps = {
  // function
  onEvent: any;
  setProp: any;
  buttonLoadCheck?: any;
  errorMessage?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;
  masterData?: any;

  // seq
  runSequence?: any;
  PerioperativeNursingSequence?: any;
};

const CardSurgicalSafetyChecklistTimeoutInitial: CardSurgicalSafetyChecklistTimeoutProps =
  {
    // funtion
    onEvent: () => null,
    setProp: () => null,
    buttonLoadCheck: null,
  };

const CardSurgicalSafetyChecklistTimeout: React.FC<
  CardSurgicalSafetyChecklistTimeoutProps
> = (props: any) => {
  useEffect(() => {
    props.runSequence({
      sequence: "PreOperation",
      action: "FETCH_FORM_DATA_LATEST",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formVersion: FORM_VERSION,
    });
  }, []);

  const handleChangeValue = (key: string) => (_event: any, data: any) => {
    if (data.type === "checkbox" || data.type === "radio") {
      props.setProp(
        `PerioperativeNursingSequence.surgicalSafetyChecklistTimeOut.data.${key}`,
        data.checked
      );
    } else {
      props.setProp(
        `PerioperativeNursingSequence.surgicalSafetyChecklistTimeOut.data.${key}`,
        data.value
      );
    }
  };

  const handleChangeDate = (key: string) => (date: any) => {
    props.setProp(
      `PerioperativeNursingSequence.surgicalSafetyChecklistTimeOut.data.${key}`,
      date
    );
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SAVE",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleConfirm = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "CONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleUnconfirm = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "UNCONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={null}
      />

      <CardSurgicalSafetyChecklistTimeoutUX
        surgicalSafety={
          props.PerioperativeNursingSequence?.surgicalSafetyChecklistTimeOut
        }
        handleChangeValue={handleChangeValue}
        handleChangeDate={handleChangeDate}
        status={
          props.PerioperativeNursingSequence?.surgicalSafetyChecklistTimeOut
            ?.status
        }
        buttonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="save"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave}
          />
        }
        buttonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="confirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleConfirm}
          />
        }
        buttonUnconfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="unconfirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleUnconfirm}
          />
        }
        timeTextBox={
          <TimeComboBox
            noMinWidth={true}
            defaultValue={
              props.PerioperativeNursingSequence?.surgicalSafetyChecklistTimeOut
                ?.data?.incision_time || ""
            }
            onTextChange={(time) => {
              props.setProp(
                "PerioperativeNursingSequence.surgicalSafetyChecklistTimeOut.data.incision_time",
                time
              );
            }}
          />
        }
      />
    </>
  );
};

CardSurgicalSafetyChecklistTimeout.defaultProps =
  CardSurgicalSafetyChecklistTimeoutInitial;

export default React.memo(CardSurgicalSafetyChecklistTimeout);
