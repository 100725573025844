import React, { useMemo, useState, useCallback } from "react";
import {
  Button,
  Dimmer,
  Dropdown,
  Input,
  Loader,
  Modal,
} from "semantic-ui-react";

// Framework
import { Table } from "react-lib/frameworks/Table";

// UX
import ModEditDiscountUX from "./ModEditDiscountUX";

// Common
import { ErrorMessage } from "react-lib/apps/common";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Types
type ModEditDiscountProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: any;
  MedicalFeeHistorySequence?: any;
  // data
  // * ไม่ต้องส่ง encounter หากไม่ต้องการ filter by encounter
  encounterId?: any;
  // CommonInterface
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  loadingStatus?: Record<string, any>;
  // options
  masterOptions?: Record<string, any[]>;
  // config
  hideSelectCoverage?: boolean;
};

// Const
const ColumnStyle = {
  marginTop: -7,
  marginLeft: -4,
  width: "calc(100% + 10px)",
  height: "calc(100% + 14px)",
  padding: "7px 7px",
};

const calculationOptions = [
  { key: 0, value: "TOTAL_PRICE", text: "คำนวณจากค่าใช้จ่ายทั้งหมด" },
  {
    key: 1,
    value: "NON_CLAIMABLE_PRICE",
    text: "คำนวณจากส่วนที่เบิกไม่ได้เท่านั้น",
  },
];

const MOD_EDIT_DISCOUNT = "ModEditDiscountUX";
const MOD_ADD_ITEMS = "ModAddItems";

const ModEditDiscount = (props: ModEditDiscountProps) => {
  const [openModAddItem, setOpenModAddItem] = useState<boolean>(false);
  const [typeAddItem, setTypeAddItem] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<any>(null);

  // Use Callback
  const handleGetTdProps = useCallback(
    (state: any, rowInfo: any, column: any, instance: any) => {
      return {
        style: {
          cursor: "pointer",
          overflow: "visible",
        },
      };
    },
    []
  );

  // Use Memo
  const orderList = useMemo(() => {
    return (props.MedicalFeeHistorySequence?.orderList || [])?.map(
      (item: any, idx: any) => ({
        name: (
          <div
            style={{
              ...ColumnStyle,
              textDecoration: item.remove ? "line-through" : "",
              backgroundColor: item.title ? "" : "rgb(255, 255, 204)",
            }}
          >
            {item.name || item.title}
          </div>
        ),
        encounter_type: (
          <div
            style={{
              ...ColumnStyle,
              backgroundColor: item.title ? "" : "rgb(255, 255, 204)",
              textDecoration: item.remove ? "line-through" : "",
            }}
          >
            {item.encounter_type}
          </div>
        ),
        amount: (
          <div
            style={{
              ...ColumnStyle,
            }}
          >
            <input
              style={{
                width: "100%",
                height: "100%",
                border: "none",
                backgroundColor: item.title ? "" : "rgb(255, 255, 204)",
                textDecoration: item.remove ? "line-through" : "",
              }}
              type="number"
              min={0}
              value={item.amount}
              onChange={(e: any) => {
                let items: any[] = props.MedicalFeeHistorySequence?.orderList;
                if (items?.[idx]) {
                  items[idx].amount = e.target.value;
                  props.setProp("MedicalFeeHistorySequence.orderList", [
                    ...items,
                  ]);
                }
              }}
            />
          </div>
        ),
        percent: (
          <div
            style={{
              ...ColumnStyle,
            }}
          >
            <input
              style={{
                width: "100%",
                height: "100%",
                border: "none",
                backgroundColor: item.title ? "" : "rgb(255, 255, 204)",
                textDecoration: item.remove ? "line-through" : "",
              }}
              type="number"
              min={0}
              value={item.percent}
              onChange={(e: any) => {
                let items: any[] = props.MedicalFeeHistorySequence?.orderList;
                if (items?.[idx]) {
                  items[idx].percent = e.target.value;
                  props.setProp("MedicalFeeHistorySequence.orderList", [
                    ...items,
                  ]);
                }
              }}
            />
          </div>
        ),
        calculation: (
          <div>
            <div className="dropdown overflow" style={{ ...ColumnStyle }}>
              <Dropdown
                fluid={true}
                upward={false}
                style={{
                  textDecoration: item.remove ? "line-through" : "",
                  backgroundColor: item.title ? "" : "rgb(255, 255, 204)",
                }}
                onChange={(e: any, v: any) => {
                  let items: any[] = props.MedicalFeeHistorySequence?.orderList;
                  let text = calculationOptions?.find(
                    (item: any) => item.value === v.value
                  )?.text;
                  if (items?.[idx]) {
                    items[idx] = {
                      ...items[idx],
                      calculation: v.value,
                      calculation_label: text,
                    };

                    props.setProp("MedicalFeeHistorySequence.orderList", [
                      ...items,
                    ]);
                  }
                }}
                options={calculationOptions}
                selection={true}
                value={item.calculation}
              />
            </div>
          </div>
        ),
        delete: (
          <div
            style={{
              ...ColumnStyle,
              display: "flex",
              justifyContent: "flex-end",
              backgroundColor: item.title ? "" : "rgb(255, 255, 204)",
            }}
          >
            <div>
              <Button
                icon="trash alternate"
                color="red"
                style={{ display: item.remove ? "none" : "" }}
                onClick={() => {
                  let items: any[] = props.MedicalFeeHistorySequence?.orderList;
                  if (item.title) {
                    if (items?.[idx]) {
                      items[idx] = {
                        ...items[idx],
                        remove: true,
                      };

                      props.setProp("MedicalFeeHistorySequence.orderList", [
                        ...items,
                      ]);
                    }
                  } else {
                    items.splice(idx, 1);
                    props.setProp("MedicalFeeHistorySequence.orderList", [
                      ...items,
                    ]);
                  }
                }}
              />

              <Button
                icon="redo"
                color="green"
                style={{ display: item.remove ? "" : "none" }}
                onClick={() => {
                  let items: any[] = props.MedicalFeeHistorySequence?.orderList;
                  if (items?.[idx]) {
                    items[idx] = {
                      ...items[idx],
                      remove: false,
                    };

                    props.setProp("MedicalFeeHistorySequence.orderList", [
                      ...items,
                    ]);
                  }
                }}
              />
            </div>
          </div>
        ),
      })
    );
  }, [props.MedicalFeeHistorySequence?.orderList]);

  const discountOptions = useMemo(() => {
    return (props.MedicalFeeHistorySequence?.DiscountList || [])?.map(
      (item: any) => ({
        key: item.id,
        value: item.code,
        text: item.name,
      })
    );
  }, [props.MedicalFeeHistorySequence?.DiscountList]);

  // Handler
  const handleChangeValue = (key: string) => (e: any, v: any) => {
    let text = discountOptions?.find(
      (item: any) => item.value === v.value
    )?.text;
    if (key === "discountByCoverageCode") {
      props.setProp("MedicalFeeHistorySequence", {
        ...props.MedicalFeeHistorySequence,
        [key]: v.value,
        discountByCoverageName: text,
      });
      props.runSequence({
        sequence: "MedicalFeeHistory",
        action: "changeDiscount",
        encounter: props.encounterId,
        code: v.value,
        name: text,
      });
    } else {
      props.setProp(`MedicalFeeHistorySequence.${key}`, v.value);
    }
  };

  const handleAddProduct = () => {
    setOpenModAddItem(true);
    setTypeAddItem("product");
    props.runSequence({
      sequence: "MedicalFeeHistory",
      action: "addItem",
      type: "product",
      card: MOD_ADD_ITEMS,
    });
  };

  const handleAddBillMode = () => {
    setOpenModAddItem(true);
    setTypeAddItem("billmode");
    props.runSequence({
      sequence: "MedicalFeeHistory",
      action: "addItem",
      type: "billmode",
      card: MOD_ADD_ITEMS,
    });
  };

  return (
    <>
      <ModEditDiscountUX
        orderList={orderList}
        coverage={props.MedicalFeeHistorySequence?.coverage}
        discountByCoverageCode={
          props.MedicalFeeHistorySequence?.discountByCoverageCode
        }
        coverageOptions={props.masterOptions?.discount}
        discountOptions={discountOptions}
        // config
        hideSelectCoverage={props.hideSelectCoverage}
        showEdit={props.MedicalFeeHistorySequence?.DiscountList?.length > 0}
        // callback
        handleChangeValue={handleChangeValue}
        getTdProps={handleGetTdProps}
        onAddProduct={handleAddProduct}
        onAddBillMode={handleAddBillMode}
        // Element
        errorMessageAdd={
          <ErrorMessage
            error={props.errorMessage?.[`${MOD_EDIT_DISCOUNT}_ADD`]?.error}
          />
        }
        errorMessageSave={
          <ErrorMessage
            error={props.errorMessage?.[`${MOD_EDIT_DISCOUNT}_SAVE`]?.error}
          />
        }
        buttonAddCoverage={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "MedicalFeeHistory",
                action: "addCoverage",
                encounter: props.encounterId,
                card: `${MOD_EDIT_DISCOUNT}_ADD`,
                buttonLoadKey: `${MOD_EDIT_DISCOUNT}_ADD`,
              });
            }}
            // data
            paramKey={`${MOD_EDIT_DISCOUNT}_ADD`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${MOD_EDIT_DISCOUNT}_ADD`]
            }
            // config
            name="ADD"
            size="small"
            color="green"
            title={"เพิ่มสิทธิ"}
          />
        }
        buttonBillProduct={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "MedicalFeeHistory",
                action: "addBillProduct",
                encounter: props.encounterId,
                card: MOD_EDIT_DISCOUNT,
                buttonLoadKey: `${MOD_EDIT_DISCOUNT}_BILLED_PRODUCTS`,
              });
            }}
            // data
            paramKey={`${MOD_EDIT_DISCOUNT}_BILLED_PRODUCTS`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${MOD_EDIT_DISCOUNT}_BILLED_PRODUCTS`]
            }
            // config
            name="BILLED_PRODUCTS"
            color="green"
            title={"ADD BILLED PRODUCTS"}
          />
        }
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "MedicalFeeHistory",
                action: "save",
                encounter: props.encounterId,
                card: `${MOD_EDIT_DISCOUNT}_SAVE`,
                buttonLoadKey: `${MOD_EDIT_DISCOUNT}_SAVE`,
                order: orderList,
              });
            }}
            // data
            paramKey={`${MOD_EDIT_DISCOUNT}_SAVE`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${MOD_EDIT_DISCOUNT}_SAVE`]
            }
            // config
            name="SAVE"
            color="green"
            title={"SAVE"}
          />
        }
        buttonBillMode={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "MedicalFeeHistory",
                action: "addBillMode",
                encounter: props.encounterId,
                card: MOD_EDIT_DISCOUNT,
                buttonLoadKey: `${MOD_EDIT_DISCOUNT}_BILL_MODE`,
              });
            }}
            // data
            paramKey={`${MOD_EDIT_DISCOUNT}_BILL_MODE`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${MOD_EDIT_DISCOUNT}_BILL_MODE`]
            }
            // config
            name="BILL_MODE"
            color="green"
            title={"ADD BILLED BILL MODE"}
          />
        }
      />

      <Modal
        open={openModAddItem}
        onClose={() => {
          setOpenModAddItem(false);
          setSearchText("");
        }}
        size="small"
      >
        <div style={{ padding: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px 0 10px 0",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: "1.2rem",
                marginRight: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {" "}
              ค้นหา{" "}
            </div>
            <Input
              onChange={(e: any, v: any) => {
                setSearchText(v.value);
              }}
              style={{ width: "80%", marginRight: "5px" }}
              value={searchText}
            ></Input>

            <Button
              color="blue"
              onClick={() => {
                props.runSequence({
                  sequence: "MedicalFeeHistory",
                  action: "addItem",
                  type: typeAddItem,
                  searchText: searchText,
                  card: MOD_ADD_ITEMS,
                });
              }}
            >
              ค้นหา
            </Button>
          </div>

          <div style={{ margin: "10px 0 10px 0" }}>
            <Table
              data={props.MedicalFeeHistorySequence?.productList}
              getTrProps={(
                state: any,
                rowInfo: any,
                column: any,
                instance: any
              ) => {
                return {
                  style: {
                    backgroundColor:
                      rowInfo?.original?.id &&
                      rowInfo?.original?.id === selectedItem?.id
                        ? "#cccccc"
                        : "white",
                  },
                  onClick: () => setSelectedItem(rowInfo.original),
                  onDoubleClick: () => {
                    let item = [
                      ...(props.MedicalFeeHistorySequence?.orderList || []),
                    ];
                    item.push({
                      ...rowInfo.original,
                      encounter_type: "IPD",
                      product:
                        typeAddItem === "product" ? rowInfo.original?.id : "",
                      bill_mode:
                        typeAddItem === "billmode" ? rowInfo.original?.id : "",
                    });
                    item.push({
                      ...rowInfo.original,
                      encounter_type: "OPD",
                      product:
                        typeAddItem === "product" ? rowInfo.original?.id : "",
                      bill_mode:
                        typeAddItem === "billmode" ? rowInfo.original?.id : "",
                    });
                    props.setProp("MedicalFeeHistorySequence.orderList", item);
                    setOpenModAddItem(false);
                  },
                };
              }}
              headers="Code,Name"
              keys="code,name"
              minRows="5"
              showPagination={false}
              style={{ height: "350px" }}
            ></Table>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px 0 10px 0",
            }}
          >
            <Button color="red" onClick={() => setOpenModAddItem(false)}>
              ยกเลิก
            </Button>
            <Button
              color="green"
              onClick={() => {
                let item = [
                  ...(props.MedicalFeeHistorySequence?.orderList || []),
                ];
                item.push({
                  ...selectedItem,
                  encounter_type: "IPD",
                  product: typeAddItem === "product" ? selectedItem?.id : "",
                  bill_mode: typeAddItem === "billmode" ? selectedItem?.id : "",
                });
                item.push({
                  ...selectedItem,
                  encounter_type: "OPD",
                  product: typeAddItem === "product" ? selectedItem?.id : "",
                  bill_mode: typeAddItem === "billmode" ? selectedItem?.id : "",
                });
                props.setProp("MedicalFeeHistorySequence.orderList", item);
                setOpenModAddItem(false);
              }}
            >
              เลือก
            </Button>
          </div>

          <Dimmer active={props.loadingStatus?.[MOD_ADD_ITEMS]} inverted>
            <Loader />
          </Dimmer>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(ModEditDiscount);
