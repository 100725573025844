import React, { useState, useRef, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import * as Common from "../common";
import * as PTM from "../PTM";
import * as CONSTANT from "../../utils/constant";
import { Form, Label, Menu, Input, Button } from "semantic-ui-react";
import * as Util from "../../utils";
import { RightLabel, DateTextBox, ComboBox, SearchBox } from "../common";
import PureReactTable from "../common/PureReactTable";
import moment from "moment";

const CardFormViewer = forwardRef((props, ref) => {
  let todayDate = Util.formatDate(moment());
  const isMounted = useRef(true);

  const [isLoading, setIsLoading] = useState(false);
  const [encounterType, setEncounterType] = useState("ALL");
  const [formTypeChoices, setFormTypeChoices] = useState([]);
  const [formType, setFormType] = useState(null);
  const [encounterText, setEncounterText] = useState("");
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayDate);
  const [nurseNoteList, setNurseNoteList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [error, setError] = useState(null);
  const [openCard, setOpenCard] = useState(null);
  const [selectEncounterId, setSelectEncounterId] = useState(null);
  const [nurseNoteData, setNurseNoteData] = useState(null)

  const doctorRef = useRef();
  const patientAssessRef = useRef();
  const fallRiskAssessRef = useRef();
  const painAssessRef = useRef();
  const nutritionAssessRef = useRef();
  const reassessRef = useRef();
  const nurseNoteRef = useRef()

  const handleEncounterType = (e, { name }) => {
    setEncounterType(name);
  };

  const handleTypeEncounter = e => {
    const { value } = e.target;
    setEncounterText(value);
  };

  useEffect(() => {
    getFormType();
    return () => {
      isMounted.current = null
    }
  }, []);

  useEffect(() => {
    getFormDataNurseNote()
  }, [props.patientData.patient_id])

  useEffect(() => {
    props.onOpenCard?.(openCard, selectEncounterId);
  }, [openCard])

  const getFormType = async () => {
    const [data, error] = await props.controller.getFormType();
    if (isMounted.current) {
      let options = Util.formatDropdown(data?.items || [], "name", "id");
      options.push({
        key: "NURSE",
        text: "Nurse's Note",
        value: "NURSE"
      });
      setFormTypeChoices(options);
    }
  };

  const getDoctor = async ({ searchText }) => {
    const [data, error] = await props.controller.getDoctor({
      nameCode: searchText
    });
    return [data, error];
  };

  const getFormDataNurseNote = async () => {
    setIsLoading(true);
    setSelectedRow(0);
    let doctor = doctorRef.current.getId();
    let patientType = encounterType;
    if (encounterType === CONSTANT.ENCOUNTER_TYPE.ALL) {
      patientType = null;
    }
    const [data, error] = await props.controller.getFormDataNurseNote({
      encounterType: patientType,
      encounterNo: encounterText,
      formType,
      doctor,
      startDate,
      endDate,
      patient: props.patientData.patient_id
    });
    if (isMounted.current) {
      if (data) {
        let arr = [...data.items];
        for (let index in arr) {
          if (!arr[index].id) {
            arr[index].id = "fakeId" + index;
          }
        }
        setNurseNoteList(arr);
      } else if (error) {
        setError(error);
        setNurseNoteList([]);
      }
      setIsLoading(false);
      return [data, error];
    }
  };

  const handleSearch = () => {
    setOpenCard(null);
    getFormDataNurseNote();
  };

  const handleSelectNurseNote = async (data: any) => {
    await handleCloseCard();
    switch (data.code) {
      case CONSTANT.FORM_TYPE_CODE.CARD_PATIENT_ASSESSMENT:
        setSelectEncounterId(data.encounter_id);
        setOpenCard(CONSTANT.FORM_TYPE_CODE.CARD_PATIENT_ASSESSMENT);
        break;
      case CONSTANT.FORM_TYPE_CODE.CARD_FALL_RISK_ASSESSMENT:
        setSelectEncounterId(data.encounter_id);
        setOpenCard(CONSTANT.FORM_TYPE_CODE.CARD_FALL_RISK_ASSESSMENT);
        break;
      case CONSTANT.FORM_TYPE_CODE.CARD_PAIN_ASSESSMENT:
        setSelectEncounterId(data.encounter_id);
        setOpenCard(CONSTANT.FORM_TYPE_CODE.CARD_PAIN_ASSESSMENT);
        break;
      case CONSTANT.FORM_TYPE_CODE.CARD_NUTRITION_ASSESSMENT:
        setSelectEncounterId(data.encounter_id);
        setOpenCard(CONSTANT.FORM_TYPE_CODE.CARD_NUTRITION_ASSESSMENT);
        break;
      case CONSTANT.FORM_TYPE_CODE.CARD_DISCHARGE_SUMMARY:
        // Cant find example, Cant fint data in db, Cant find place to save
        // Dont think in QML will work since there is no PTM/CardDischargeSummary.qml
        // But there is ADM&DPI/CardPreAssessment.qml
        break;
      case CONSTANT.FORM_TYPE_CODE.DISCHARGE_PLANNING:
        // Not work in QML because there is no PTM/discharge_planning.qml
        break;
      case CONSTANT.FORM_TYPE_CODE.DISCHARGE_TEACHING:
        // Not work in QML because there is no PTM/discharge_teaching.qml
        break;
      case CONSTANT.FORM_TYPE_CODE.DISCHARGE_NURSING:
        // Not work in QML because there is no PTM/discharge_nursing.qml
        break;
      case CONSTANT.FORM_TYPE_CODE.CARD_REASSESSMENT:
        setSelectEncounterId(data.encounter_id);
        setOpenCard(CONSTANT.FORM_TYPE_CODE.CARD_REASSESSMENT);
        break;
      case CONSTANT.FORM_TYPE_CODE.CARD_PREASSESSMENT:
        // Not work in QML because there is no PTM/CardPreAssessment.qml
        // But there is ADM/CardPreAssessment.qml
        break;
      case CONSTANT.FORM_TYPE_CODE.CARD_PREASSESSMENT_IPD:
        setSelectEncounterId(data.encounter_id);
        setOpenCard(CONSTANT.FORM_TYPE_CODE.CARD_PREASSESSMENT_IPD);
        break;
      case ADDITION_FORM_TYPE.CARD_NURSE_NOTE:
        setNurseNoteData(data)
        setSelectEncounterId(data.encounter_id);
        setOpenCard(ADDITION_FORM_TYPE.CARD_NURSE_NOTE);
        break;
      default:
        break;
      // case nurseNote
    }
  };

  const handleCloseCard = () => {
    setOpenCard(null);
    return null
  };

  return (
    <Common.CardLayout
      headerColor="teal"
      titleText="เอกสารการพยาบาล"
      closeable={props.closeable}
      onClose={props.onClose}
      loading={isLoading}
    >
      <Form>
        <Common.ErrorMessage error={error} />
        <Form.Group inline>
          <Form.Field width={1}>
            <Label>ค้นหา</Label>
          </Form.Field>
          <Form.Field width={2}>
            <RightLabel>ประเภทผู้ป่วย</RightLabel>
          </Form.Field>
          <Form.Field width={2}>
            <Menu inverted size="tiny" className="bold">
              <Menu.Item
                name={CONSTANT.ENCOUNTER_TYPE.OPD}
                color="green"
                active={encounterType === CONSTANT.ENCOUNTER_TYPE.OPD}
                onClick={handleEncounterType}
              />
              <Menu.Item
                name={CONSTANT.ENCOUNTER_TYPE.IPD}
                color="green"
                active={encounterType === CONSTANT.ENCOUNTER_TYPE.IPD}
                onClick={handleEncounterType}
              />
              <Menu.Item
                name={CONSTANT.ENCOUNTER_TYPE.ALL}
                color="orange"
                active={encounterType === CONSTANT.ENCOUNTER_TYPE.ALL}
                onClick={handleEncounterType}
              />
            </Menu>
          </Form.Field>
          <Form.Field width={2}>
            <RightLabel>Encounter</RightLabel>
          </Form.Field>
          <Form.Field width={2}>
            <Input
              testid="txtEncounter"
              onChange={handleTypeEncounter}
              value={encounterText}
            />
          </Form.Field>
          <Form.Field width={2}>
            <RightLabel>ประเภทเอกสาร</RightLabel>
          </Form.Field>
          <Form.Field width={3}>
            <ComboBox
              fluid
              search
              placeholder="แสดงทุกประเภท"
              clearable
              options={formTypeChoices}
              onChange={(e, { value }) => setFormType(value)}
            />
          </Form.Field>
          <Form.Field width={2}>
            <Button content="ค้นหา" color="blue" fluid onClick={handleSearch} />
          </Form.Field>
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={1} />
          <Form.Field width={2}>
            {" "}
            <RightLabel>แพทย์เจ้าของไข้</RightLabel>
          </Form.Field>
          <Form.Field width={4}>
            <SearchBox
              ref={doctorRef}
              onGetSearchOptions={getDoctor}
              textField="name_code"
            />
          </Form.Field>
          <Form.Field width={3} />
          <Form.Field width={1}>
            <RightLabel>ตั้งแต่</RightLabel>
          </Form.Field>
          <Form.Field width={2}>
            <DateTextBox
              value={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </Form.Field>
          <Form.Field>ถึง</Form.Field>
          <Form.Field width={2}>
            <DateTextBox
              value={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <PureReactTable
        testid="nurseNoteTable"
        showPagination={false}
        className=""
        pageSize={
          nurseNoteList
            ? nurseNoteList.length < 1
              ? 1
              : nurseNoteList.length
            : 1
        }
        data={nurseNoteList}
        style={styles.nurseNoteTable}
        selectedClassName="blueSelectedRow"
        onSelect={(originalRow: any) => {
          setSelectedRow(originalRow);
          if (props.selectNoteOnClick) {
            handleSelectNurseNote(originalRow);
          }
        }}
        selectedRow={selectedRow}
        onEnter={(originalRow: any) => {
          if (props.selectNoteOnClick) {
            setSelectedRow(originalRow);
          }
          handleSelectNurseNote(originalRow);
        }}
        columns={[
          {
            Header: "ประเภท",
            accessor: "name",
            minWidth: 150,
          },
          {
            Header: "วันที่",
            accessor: "created",
            minWidth: 135,
          },
          {
            Header: "Encounter",
            accessor: "encounter_number",
            minWidth: 75,
          },
          {
            Header: "แพทย์",
            accessor: "doctor_name",
            minWidth: 165,
          },
          {
            Header: "แผนก",
            accessor: "division",
            minWidth: 150,
          },
          {
            Header: "สถานะเอกสาร",
            accessor: "status",
            minWidth: 120,
          },
          {
            Header: "ผู้บันทึกเอกสาร",
            accessor: "edit_user_name",
            minWidth: 150,
          },
        ]}
      />
      {openCard === CONSTANT.FORM_TYPE_CODE.CARD_PATIENT_ASSESSMENT && (
        <PTM.CardPatientAssessment
          ref={patientAssessRef}
          showSummary={true}
          onClose={handleCloseCard}
          patientData={props.patientData}
          controller={props.patientAssessmentController}
          encounterId={selectEncounterId}
        />
      )}
      {openCard === CONSTANT.FORM_TYPE_CODE.CARD_FALL_RISK_ASSESSMENT && (
        <PTM.CardFallRiskAssessment
          ref={fallRiskAssessRef}
          showSummary={true}
          onClose={handleCloseCard}
          patientData={props.patientData}
          controller={props.fallRiskAssessmentController}
          encounterId={selectEncounterId}
        />
      )}
      {openCard === CONSTANT.FORM_TYPE_CODE.CARD_PAIN_ASSESSMENT && (
        <PTM.CardPainAssessment
          ref={painAssessRef}
          showSummary={true}
          onClose={handleCloseCard}
          patientData={props.patientData}
          controller={props.painAssessmentController}
          encounterId={selectEncounterId}
        />
      )}
      {openCard === CONSTANT.FORM_TYPE_CODE.CARD_NUTRITION_ASSESSMENT && (
        <PTM.CardNutritionAssessment
          ref={nutritionAssessRef}
          showSummary={true}
          onClose={handleCloseCard}
          patientData={props.patientData}
          controller={props.nutritionAssessmentController}
          encounterId={selectEncounterId}
        />
      )}
      {openCard === CONSTANT.FORM_TYPE_CODE.CARD_REASSESSMENT && (
        <PTM.CardReassessment
          ref={reassessRef}
          showSummary={true}
          onClose={handleCloseCard}
          patientData={props.patientData}
          controller={props.reassessmentController}
          encounterId={selectEncounterId}
        />
      )}
      {openCard === ADDITION_FORM_TYPE.CARD_NURSE_NOTE && (
        <PTM.CardNurseNote
          ref={nurseNoteRef}
          onClose={handleCloseCard}
          patientData={props.patientData}
          nurseNoteData={nurseNoteData}
          controller={props.nurseNoteController}
          encounterId={selectEncounterId}
          django={props.django}
        />
      )}
      {openCard === CONSTANT.FORM_TYPE_CODE.CARD_PREASSESSMENT_IPD &&
        props.cardPreAssessment}
    </Common.CardLayout>
  );
});

const ADDITION_FORM_TYPE = {
  CARD_NURSE_NOTE: 'CardNurseNote'
}

const styles = {
  nurseNoteTable: {
    height: "150px"
  }
};

CardFormViewer.defaultProps = {
  closeable: true,
  onClose: () => {},
  onOpenCard: () => {},
  controller: {},
  patientData: {},
  patientAssessmentController: {},
  fallRiskAssessmentController: {},
  painAssessmentController: {},
  nutritionAssessmentController: {},
  reassessmentController: {},
  nurseNoteController: {},
  selectNoteOnClick: false,
  cardPreAssessment: null,
};

CardFormViewer.propTypes = {
  closeable: PropTypes.bool,
  onClose: PropTypes.func,
  onOpenCard: PropTypes.func,
  controller: PropTypes.object,
  patientData: PropTypes.object,
  patientAssessmentController: PropTypes.object,
  fallRiskAssessmentController: PropTypes.object,
  painAssessmentController: PropTypes.object,
  nutritionAssessmentController: PropTypes.object,
  reassessmentController: PropTypes.object,
  nurseNoteController: PropTypes.object,
  selectNoteOnClick: PropTypes.bool,
  cardPreAssessment: PropTypes.element,
};

export default React.memo(CardFormViewer);
