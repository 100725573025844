import React, { useEffect, useState, useMemo } from "react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { EmployeeToken, ErrorMessage } from "react-lib/apps/common";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";
// ui ORM
import CardBasicInfoUX from "./CardBasicInfoUX";
import CardBasicInfoSurgeryTeam from "./CardBasicInfoSurgeryTeam";
import CardBasicInfoAnesthesiaTeam from "./CardBasicInfoAnesthesiaTeam";
import moment from "moment";

const CARD_BASIC_INFO: string = "CardBasicInfo";

type CardBasicInfoProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  buttonLoadCheck?: any;
  errorMessage?: any;
  successMessage?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  PerioperativeNursingSequence?: any;
  searchedItemListWithKey?: any;
};

const CardBasicInfoInitial: CardBasicInfoProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  buttonLoadCheck: null,

  // select OR
  selectedOrOrder: {},

  // options
  masterOptions: {},

  // seq
  runSequence: null,
  PerioperativeNursingSequence: {},
};

const CardBasicInfo: React.FC<CardBasicInfoProps> = (props: any) => {
  useEffect(() => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "FETCH_DATA_BASIC_INFO",
      card: CARD_BASIC_INFO,
    });
  }, []);

  const handleChangeDate = (key: string) => (date: any) => {
    props.setProp(`PerioperativeNursingSequence.basicInfoData.${key}`, date);

    if (key === "patient_in_date" || key === "patient_out_date") {
      handleSetTotalTime();
    } else if (key === "incision_date" || key === "closure_date") {
      handleSetOperationTime();
    }
  };

  const handleChangeValue = (key: string) => (e: any, v: any) => {
    console.log("key", v, key);
    if (key === "anesthesia_method") {
      props.setProp(
        `PerioperativeNursingSequence.basicInfoData.${key}`,
        v.value
      );
    } else {
      props.setProp(`PerioperativeNursingSequence.basicInfoData.${key}`, v);
    }
  };

  const handleSetTotalTime = () => {
    let dataRecord = props.PerioperativeNursingSequence?.basicInfoData;
    let newEndDate = moment(
      `${dataRecord?.patient_out_date} ${dataRecord?.patient_out_time}`,
      "DD/MM/YYYY HH:mm"
    );
    let newStartDate = moment(
      `${dataRecord?.patient_in_date} ${dataRecord?.patient_in_time}`,
      "DD/MM/YYYY HH:mm"
    );

    let hoursTime = newEndDate.diff(newStartDate, "hours");
    let minutesTime = newEndDate.diff(newStartDate, "minutes");

    props.setProp(
      "PerioperativeNursingSequence.basicInfoData.total_time",
      `${hoursTime}:${minutesTime - hoursTime * 60}` || 0
    );
  };

  const handleSetOperationTime = () => {
    let dataRecord = props.PerioperativeNursingSequence?.basicInfoData;
    let newEndDate = moment(
      `${dataRecord?.closure_date} ${dataRecord?.closure_time}`,
      "DD/MM/YYYY HH:mm"
    );
    let newStartDate = moment(
      `${dataRecord?.incision_date} ${dataRecord?.incision_time}`,
      "DD/MM/YYYY HH:mm"
    );

    let hoursTime = newEndDate.diff(newStartDate, "hours");
    let minutesTime = newEndDate.diff(newStartDate, "minutes");

    props.setProp(
      "PerioperativeNursingSequence.basicInfoData.operation_time",
      `${hoursTime}:${minutesTime - hoursTime * 60}` || 0
    );
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SAVE_BASIC_INFO",
      card: CARD_BASIC_INFO,
      buttonLoadKey: `${CARD_BASIC_INFO}_SAVE`,
    });
  };

  const handleSplitTime = (key: string, type: string) => {
    let timeData;

    if (key === "total_time") {
      timeData = props.PerioperativeNursingSequence?.basicInfoData?.total_time;
    } else if (key === "operation_time") {
      timeData =
        props.PerioperativeNursingSequence?.basicInfoData?.operation_time;
    } else {
      return
    }

    let newTime = timeData?.split(":");

    return type === "h" ? newTime?.[0] : type === "m" ?  newTime?.[1] : "";
  };

  return (
    <>
      <CardBasicInfoUX
        basicInfoData={props.PerioperativeNursingSequence?.basicInfoData}
        splitTime={handleSplitTime}
        errorMessage={
          <ErrorMessage error={props?.errorMessage?.[CARD_BASIC_INFO]?.error} />
        }
        preOperativeDiagnosis={
          <div
            dangerouslySetInnerHTML={{
              __html:
                props.PerioperativeNursingSequence?.basicInfoData
                  ?.preoperative_diagnosis,
            }}
          />
        }
        postOperativeDiagnosis={
          <div
            dangerouslySetInnerHTML={{
              __html:
                props.PerioperativeNursingSequence?.basicInfoData
                  ?.postoperative_diagnosis,
            }}
          />
        }
        preOperationSummary={
          <div
            dangerouslySetInnerHTML={{
              __html:
                props.PerioperativeNursingSequence?.basicInfoData
                  ?.pre_operation_summary,
            }}
          />
        }
        anesthesiaOptions={props.masterOptions?.anesthesiaMethod}
        handleChangeDate={handleChangeDate}
        handleChangeValue={handleChangeValue}
        // component
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={`${CARD_BASIC_INFO}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_BASIC_INFO}_SAVE`]}
            // config
            color="green"
            size="small"
            title="บันทึก"
          />
        }
        surgeryTeam={
          <CardBasicInfoSurgeryTeam
            onEvent={props.onEvent}
            setProp={props.setProp}
            selectedOrOrder={props.selectedOrOrder}
            masterOptions={props.masterOptions}
            runSequence={props.runSequence}
            PerioperativeNursingSequence={props.PerioperativeNursingSequence}
            surgery_teams={
              props.PerioperativeNursingSequence?.basicInfoData?.surgery_teams
            }
            searchedItemListWithKey={props.searchedItemListWithKey}
            activeIndex={
              props.PerioperativeNursingSequence?.basicInfoData
                ?.activeSurgeonTeam || 0
            }
          />
        }
        anesthesiaTeam={
          <CardBasicInfoAnesthesiaTeam
            onEvent={props.onEvent}
            setProp={props.setProp}
            selectedOrOrder={props.selectedOrOrder}
            masterOptions={props.masterOptions}
            runSequence={props.runSequence}
            PerioperativeNursingSequence={props.PerioperativeNursingSequence}
            anesthesia_teams={
              props.PerioperativeNursingSequence?.basicInfoData
                ?.anesthesia_teams
            }
            searchedItemListWithKey={props.searchedItemListWithKey}
            activeIndex={
              props.PerioperativeNursingSequence?.basicInfoData
                ?.activeAnesthesiaTeam || 0
            }
          />
        }
        patientInTime={
          <TimeComboBox
            noMinWidth={true}
            defaultValue={
              props.PerioperativeNursingSequence?.basicInfoData
                ?.patient_in_time || ""
            }
            onTextChange={(time) => {
              props.setProp(
                "PerioperativeNursingSequence.basicInfoData.patient_in_time",
                time
              );
              handleSetTotalTime();
            }}
          />
        }
        patientOutTime={
          <TimeComboBox
            noMinWidth={true}
            defaultValue={
              props.PerioperativeNursingSequence?.basicInfoData
                ?.patient_out_time || ""
            }
            onTextChange={(time) => {
              props.setProp(
                "PerioperativeNursingSequence.basicInfoData.patient_out_time",
                time
              );
              handleSetTotalTime();
            }}
          />
        }
        incisionTime={
          <TimeComboBox
            noMinWidth={true}
            defaultValue={
              props.PerioperativeNursingSequence?.basicInfoData
                ?.incision_time || ""
            }
            onTextChange={(time) => {
              props.setProp(
                "PerioperativeNursingSequence.basicInfoData.incision_time",
                time
              );
              handleSetOperationTime();
            }}
          />
        }
        closureTime={
          <TimeComboBox
            noMinWidth={true}
            defaultValue={
              props.PerioperativeNursingSequence?.basicInfoData?.closure_time ||
              ""
            }
            onTextChange={(time) => {
              props.setProp(
                "PerioperativeNursingSequence.basicInfoData.closure_time",
                time
              );
              handleSetOperationTime();
            }}
          />
        }
      />
    </>
  );
};

CardBasicInfo.defaultProps = CardBasicInfoInitial;

export default React.memo(CardBasicInfo);
