import React, { useEffect, useState, CSSProperties, useRef, useMemo } from "react";
import { Modal } from "semantic-ui-react";
import moment from 'moment';
import { momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import SearchBox from "react-lib/appcon/common/SearchBox";
import CardDentistScheduleUX from "./CardDentistScheduleUX";
import ModSchedulingDetailUX from "./ModSchedulingDetailUX";
import ModEventDetailUX from "./ModEventDetailUX";
import CardSelectTemplate from "./CardSelectTemplate";
import { timeSlotList } from "react-lib/apps/Scheduling/common/Utils";
import { DsbStatus } from "react-lib/apps/Scheduling/common/Model";
import { blockListForDentistSchedule } from "react-lib/apps/Scheduling/common/BlockList";
import { ModConfirm } from "react-lib/apps/common";
import ModConfirmAuthen from "react-lib/apps/common/ModConfirmAuthen";
import { formatADtoBEString } from "react-lib/utils/dateUtils";

moment.locale('en-es', { week: { dow: 1 } });
const localizer = momentLocalizer(moment);
const CARD_DENTIST_SCHEDULE ="DentistSchedule"

const DentistSchedule = (props: any) => {
  // console.log("DentistSchedule props.chairList", props.chairList)
  // console.log("DentistSchedule props.blockList", props.blockList)
  const chairExistProvider = Object.fromEntries((props.chairExistDSBList || []).concat({provider: 0, zone_id: null})
  .map((item: any) => [item.provider, item.zone_id ? ` (${item.zone_name})`: ""]));

  const chairExists = (props.chairExistDSBList || []).map((item: any) => item.chair_id)
  const chairOptions = [{ key: 0, value: 0, text: "ไม่ระบุ" }].concat(
    (props.chairList || [])
      // .filter((chair: any) =>
      //   chair.division === props.selectedDivision?.id)
      .filter((chair: any) => chairExists.includes(chair.id))
      .map((chair: any) => (
        { key: chair.provider || chair.name, value: chair.provider, text: chair.name })
      )
  );

  const [selectedDivision, setSelectedDivision] = useState<any>(null);
  const [selectedProvider, setSelectedProvider] = useState<any>(null);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showListModal, setShowListModal] = useState(false);
  const [timeOptions, setTimeOptions] = useState<any[]>([]);

  // Default
  const [defaultChair, setDefaultChair] = useState<any>(null);
  const [defaultSlotLength, setDefaultSlotLength] = useState<any>(5);

  // Individual block edit
  const [event, setEvent] = useState<any>(null);
  const [chair, setChair] = useState<any>(null);
  const [inactive, setInactive] = useState(false);
  const [doctorStartSerial, setDoctorStartSerial] = useState<string | number>("");
  const [doctorEndSerial, setDoctorEndSerial] = useState<string | number>("");
  const [slotLength, setSlotLength] = useState("5");
  const [calendarYear, setCalendarYear] = useState<string[]>([moment().format("YYYY")]);

  const handleSelectTimeout = useRef(0);

  // ModConfirm
  const [openModMessage, setOpenModMessage] = useState(false);
  const [currentValue, setCurrentValue] = useState("");

  // only old patient
  const [exceptNewPatientAll, setExceptNewPatientAll] = useState<boolean>(false);
  const [exceptNewPatient, setExceptNewPatient] = useState<boolean>(false);

  useEffect(() => {
    props.onEvent({ message: "GetChairList", params: {} });
    props.onEvent({ message: "GetChairWithDivisionServiceBlock", params: {division: props.selectedDivision?.id || ""}})
    props.onEvent({
      message: "SetScheduling",
      params: {
        action: "GetScheduleTemplate",
      },
    });
  }, []);

  useEffect(() => {
    if (props.telephar){
      setSelectedProvider({provider_id:562})
    } else {
      setSelectedProvider(null)
    }
  }, [props.telephar]);


  useEffect(() => {
    props.onEvent({
      message: "FilterSchedule",
      params: {
        divisionId: props.selectedDivision?.id,
        initial: selectedDivision === null
      },
    });
    props.onEvent({ message: "Holiday", params: { action: "refresh" }});

    setSelectedDivision(props.selectedDivision);
  }, [props.selectedDivision]);

  useEffect(() => {
    props.onEvent({
      message: "FilterSchedule",
      params: {
        providerId: selectedProvider?.provider_id,
      },
    });
  }, [selectedProvider]);

  const blockList = useMemo(() => {

    let bl = blockListForDentistSchedule(props.blockList, props.selectedDivision, selectedProvider, props.chairList, props.providerBlockList,props.telephar);
    // console.log('selectedProvider: ', selectedProvider);
    if (selectedProvider === null) {
      // ไม่ได้ เลือกแพทย์ 
      // มี dsb เมื่อไม่เลือก แพทย์​
 
      bl = bl.map((item: any) => {
        // console.log('item: ', item);
        let doctorCount = item.providers?.filter((item: any) => (props.telephar?["TelePhar"]:["Doctor"]).includes(item.dsb.doctor_provider_type) && item?.dsb?.doctor_dsb_status !== 2)?.length 
        // console.log('BlockList !! doctorCount: ', doctorCount);
        let doctorCountText = doctorCount > 0 ? ` (แพทย์ ${doctorCount} คน)` : "";
        return ({ 
          ...item,
          title : `${item.title}${doctorCountText}`
        })
      })
  
    } else {
      // เลือกแพทย์ 
      // ไม่มี dsb เมื่อเลือก แพทย์ 

      bl = bl.map((item: any) => {
        let patientCount = selectedProvider && item.providers?.find((item: any) => item.doctor_provider_id === selectedProvider?.provider_id)?.appointments?.length
        let patientCountText = patientCount > 0 ? ` (คนไข้ ${patientCount} คน) `: ""

        return ({ 
          ...item,
          title : `${item.title}${patientCountText}`
        })
      })
    }

    return bl 
  }, [props.blockList, props.selectedDivision, selectedProvider, props.chairList, props.providerBlockList]);

  const blockListAndHoliday = useMemo(() => {
    const holiday = (props.holiday?.items || []).flatMap((item: any) => {
      const [day, month, year] = item.date[0].split("/");
      return calendarYear.map((year)=>{
      const date = `${year}-${month}-${day}`;
        return { start: new Date(`${date} 00:00`), end: new Date(`${date} 23:59`), title: item.detail, holiday: true };
      })
    });
    return [...holiday, ...blockList];

  }, [calendarYear, blockList, props.holiday?.items]);

  const updateTimeOptions = (start_serial: number, end_serial: number, gap: number) => {
    setTimeOptions(timeSlotList(start_serial, end_serial, gap, false));
  };

  const handleSelectEvent = (
    event: any, e: React.SyntheticEvent<HTMLElement, globalThis.Event>
  ) => {
    // Save current target in case we need to use it
    console.log(event);
    if(event?.holiday){
      return
    }

    // #55768 สีเหลืองกดไม่ได้ 
    if (event?.division_id !== props.selectedDivision?.id) {
      return 
    }

    if (event?.start_serial && event?.end_serial)
      updateTimeOptions(event.start_serial, event.end_serial, 6);
    // const currentTarget = e.currentTarget;
    if (!handleSelectTimeout.current) {
      handleSelectTimeout.current = setTimeout(() => {
        // Beyond 200 ms, consider a normal click
        handleSelectTimeout.current = 0;
        setEvent(event);
        if (selectedProvider) {
          console.log("handleSelectEvent", event)
          setShowFormModal(true);
          if(event?.providers?.[0]?.chair_provider_id){
            setChair(event?.providers?.[0]?.chair_provider_id || defaultChair);
            props.onEvent({ 
              message: "GetChairWithDivisionServiceBlock", 
              params: {
                start_serial: event.start_serial, 
                end_serial: event.end_serial, 
                only_chair: true, 
                division: props.selectedDivision?.id,
                exclude: [event?.providers?.[0]?.chair_provider_id]
              }
            })
          }
          else{
            props.onEvent({ 
              message: "GetChairWithDivisionServiceBlock", 
              params: {
                start_serial: event.start_serial, 
                end_serial: event.end_serial, 
                only_chair: true, 
                division: props.selectedDivision?.id
              }
            })
          }
          setInactive(event?.providers?.[0]?.doctor_dsb_status === DsbStatus.INACTIVE);
          setExceptNewPatient(event?.providers?.[0]?.only_old_patient || false)
          setDoctorStartSerial(event?.providers?.[0]?.doctor_start_serial || event?.start_serial);
          setDoctorEndSerial(event?.providers?.[0]?.doctor_end_serial || event?.end_serial);
          setSlotLength(event?.providers?.[0]?.doctor_dsb_slot_length.toString() || defaultSlotLength);
        } else {
          // For division, not specific provider
          setShowListModal(true);
        }
      }, 200) as any;
    } else {
      // Before 200 ms, consider a 'double click
      clearTimeout(handleSelectTimeout.current);
      handleSelectTimeout.current = 0;
      if (selectedProvider) {
        props.onEvent({
          message: "AddBlock",
          params: {
            event: event,
            selectedProvider: selectedProvider,
            chair: defaultChair,
            inactive: typeof event?.providers?.[0]?.doctor_dsb_status !== "undefined" && event?.providers?.[0]?.doctor_dsb_status !== DsbStatus.INACTIVE,
            slot_length: defaultSlotLength,
          },
        });
      } else {
        // For division, not specific provider
      }
    }
  };

  const handleConfirmAddBlock = () => {
    props.onEvent({
      message: "AddBlock",
      params: props.openModConfirmAddBlock.params,
    });
  };

  const handleFinish = (params = {}) => {
    props.onEvent({
      message: "AddBlock",
      params: {
        event,
        selectedProvider,
        chair,
        inactive,
        exceptNewPatient,
        doctorStartSerial,
        doctorEndSerial,
        slotLength,
        ...params,
        callback: () => {
          props.onEvent({ message: "GetChairWithDivisionServiceBlock", params: { division: props.selectedDivision?.id || "" } });
          setShowFormModal(false)
        },
      },
    });
  }

  const handleChange = (e: any, { value }: any) => setCurrentValue(value);

  const handleCancelButton = () => {
    setOpenModMessage(false);
    setCurrentValue("");
    props.setProp("userTokenize", null);
  };

  const handleConfirmedButton = () => {
    handleFinish({ note: currentValue || "", isDelete: true })
    handleCancelButton()
  };

  // const chairProviderIdList = event?.chairs?.length > 0 ? 
  // (event?.chairs || []).map((chair: any) => chair?.chair_provider_id) 
  // : (chairOptions || []).map((chair: any) => chair.value);
  const chairProviderIdList = (chairOptions || []).map((chair: any) => chair.value);

  // console.log("props.blockList: ", props);
  // console.log("event?.providers?.[0]?.appointmentList?.length,", event?.providers?.[0]?.appointments?.length)
  // console.log("event?.providers?.[0]?.appointmentList?.length,", event)
  return (
    <div style={{ height: "90vh", padding: "5px" }}>
      <CardDentistScheduleUX
        // Select provider
        telephar={props.telephar}
        selectedProvider={selectedProvider}
        providerSelector={
          <SearchBox
            type="Provider"
            vertical={true}
            style={{ width: "100%" }}
            fluid={true}
            label={"ทันตแพทย์"}
            placeholder="ค้นหาชื่อทันตแพทย์"
            toDisplay={(item: any) => `${item.first_name} ${item.last_name}`}
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={selectedProvider}
            setSelectedItem={setSelectedProvider}
          />
        }
        // Default
        defaultSlotLength={defaultSlotLength}
        changeDefaultSlotLength={(e: any, v: any) => setDefaultSlotLength(v.value)}
        defaultChair={defaultChair}
        chairOptions={chairOptions}
        changeDefaultChair={(e: any, v: any) => setDefaultChair(v.value)}
        // chair={chair}
        // changeChair={(e: any, v: any) => setChair(v.value)}

        cardSelectTemplate={
          <CardSelectTemplate
            scheduleTemplates={props.scheduleTemplates}
            disableCreate={selectedProvider === null}
            createDSBFromTemplateItems={(
              templateItems: any[],
              templateItemIndexList: number[],
              startDateTime: string,
              endDateTime: string,
              card: string,
              buttonLoadKey: string
            ) => {
              // console.log("createDSBFromTemplateItems templateItems: ", templateItems);
              // console.log("createDSBFromTemplateItems templateItemIndexList: ", templateItemIndexList);
              // console.log("createDSBFromTemplateItems startDateTime: ", startDateTime);
              // console.log("createDSBFromTemplateItems endDateTime: ", endDateTime);

              let items = templateItems.filter((item: any, index: number) => templateItemIndexList.includes(index));
              items = items.map((item: any) => ({ ...item, chair: defaultChair, slot_length: defaultSlotLength }));

              props.onEvent({
                message: "SetScheduling",
                params: {
                  action: "CreateDSBFromTemplateItems",
                  items: items,
                  startDateTime: startDateTime,
                  endDateTime: endDateTime,
                  exceptNewPatientAll: exceptNewPatientAll,
                  providers: [selectedProvider?.provider_id],
                  card: card,
                  buttonLoadKey: buttonLoadKey,
                },
              });
            }}
            chairOptions={chairOptions}
            defaultChair={defaultChair}
            defaultSlotLength={defaultSlotLength}
            onSelectTemplateDate={(start_date: any, end_date: any) => {
              props.onEvent({ message: "GetChairWithDivisionServiceBlock", params: { start_date, end_date } });
            }}
            buttonLoadCheck={props.buttonLoadCheck}
            setProp={props.setProp}
            card={CARD_DENTIST_SCHEDULE}
          />
        }
        disablePrint={!selectedProvider}
        // Print
        printSummary={(e: any) => {
          props.onEvent({
            message: "PrintScheduling",
            params: {
              form: "FormDentistSchedule",
              data: {
                divisionDict: props.divisionDict,
                provider_name: `${selectedProvider?.first_name || ""} ${selectedProvider?.last_name || ""}`,
                orders: blockList,
              },
            },
          });
        }}
        // flag accept new patient
        exceptNewPatientAll={exceptNewPatientAll}
        changeExceptNewPatientAll={() => setExceptNewPatientAll(!exceptNewPatientAll)}
        // Block
        minTime={new Date(1972, 0, 1, 8, 0, 0)}
        maxTime={new Date(1972, 0, 1, 20, 0, 0)}
        localizer={localizer}
        blockList={blockListAndHoliday}
        eventPropGetter={(event: any, start: Date, end: Date, isSelected: boolean) => {


          // console.log("------------------------------------------------------------------------------------------------------------------")
          // console.log("CardDentistSchedule blockList: ", blockList);
          // console.log("CardDentistSchedule event", event);
          // console.log("CardDentistSchedule event?.providers?.[0]?.doctor_dsb_status", event?.providers?.[0]?.doctor_dsb_status)
          // console.log("CardDentistSchedule event?.providers?.[0]?.doctor_dsb_status", event?.providers?.[0]?.dsb?.doctor_dsb_status) 

          // มี dsb เมื่อไม่เลือก แพทย์​
          // ไม่มี dsb เมื่อเลือก แพทย์ 
          
          // ดังนั้นต้อง แยก Case ก่อน 

          // สีชมพู คือ provider?.length = 0 
          // ไม่เลือกหมอ แล้ว มีหมอ แต่ ไม่ออกตรวจ 
          // เลือกหมอ แล้วในนั้น ไม่มีใครออก รวม เฉพาะหมอที่ออกตรวจ เท่ากับ 0 

          // สีเทา (เฉพาะเลือกแพทย์ )
          // ตัวเองออกตรวจ แต่งดออกตรวจ 

          // สีเขียว (เฉพาะเลือกแพทย์ )
          // ตัวเองออกตรวจแบบ มีเก้าอี้ 

          // สีส้ม (เฉพาะเลือกแพทย์) 
          // ตัวเองออกตรวจที่ division อื่น 

          // สีน้ำเงิน 
          // เลือกแพทย์ 
          //// ตัวเองออกตรวจ ที่ตัวเองไม่งดตรวจ (ออกตรวจจริง)
          // ไม่เลือกแพทย์ 
          //// มีคนใดคนหนึ่งออกตรวจจริง ()

          // console.log("---------------*********----")
          // Issue 55924


          if (selectedProvider === null) {
            // ไม่เลือกแพทย์ มี dsb
            let doctorCount = event.providers?.filter((item: any) => (props.telephar?["TelePhar"]:["Doctor"]).includes(item.dsb.doctor_provider_type) && item?.dsb?.doctor_dsb_status !== 2)?.length 
            return {
              style: event?.holiday
                ? { backgroundColor: "#FFE696", color: "black", textAlign: "center" }
                : doctorCount === 0
                ? { backgroundColor: "pink", color: "black" }
  
                : ({} as CSSProperties),
            };

          } else {
            // เลือกแพทย์ ไม่มี dsb
            // console.log("props.selectedDivision?.id", props.selectedDivision?.id)
            console.log("event?.division_id", event?.division_id)
            return {
              style: event?.holiday
                ? { backgroundColor: "#FFE696", color: "black", textAlign: "center" }
                :  event?.division_id !== props.selectedDivision?.id
                ? { backgroundColor: "orange", color: "black" }
                : event?.providers?.[0]?.doctor_dsb_status === 2
                ? { backgroundColor: "#cccccc", color: "black" }
                : event?.providers?.[0]?.chairName && event?.providers?.[0]?.chairName !== null
                ? { backgroundColor: "green", color: "white" }
                : event?.providers?.length === 0
                ? { backgroundColor: "pink", color: "black" }
  
                : ({} as CSSProperties),
            };
          }

          
     
        }}
        onRangeChange={(range: any, view: any) => {
          const year = Array.isArray(range) ? [range[0], range.slice(-1)[0]] : [range.start, range.end];
          const uniqueYear = Array.from(new Set([moment(year[0]).format("YYYY"), moment(year[1]).format("YYYY")]));
          if (uniqueYear.toString() !== calendarYear.toString()) {
            setCalendarYear(uniqueYear);
          }
        }}
        onSelectEvent={handleSelectEvent}
      />

      {/* Open when selectedProvider, show details for doing scheduling */}
      <Modal
        open={showFormModal}
        closeOnDimmerClick={true}
        onClose={() => {
          props.onEvent({ message: "GetChairWithDivisionServiceBlock", params: { division: props.selectedDivision?.id || "" } });
          setShowFormModal(false);
        }}
      >
        <ModSchedulingDetailUX
          blockDetail={event?.block_datetime}
          // Start / End time
          dentistStartSerial={doctorStartSerial}
          startTimeOptions={timeOptions}
          changeStartSerial={(e: any, v: any) => setDoctorStartSerial(v.value)}
          dentistEndSerial={doctorEndSerial}
          endTimeOptions={timeOptions}
          changeEndSerial={(e: any, v: any) => setDoctorEndSerial(v.value)}
          // Chair
          chair={chair}
          chairOptions={chairOptions
            .filter((option: any) => option.value === 0 || chairProviderIdList.includes(option.value))
            .map((option: any) => {
              return { ...option, text: option.text + chairExistProvider?.[option.value] || "" };
            })}
          changeChair={(e: any, v: any) => setChair(v.value)}
          // Capacity
          slotLength={slotLength}
          changeSlotLength={(e: any) => setSlotLength(e.target.value)}
          // Inactive
          inactive={inactive}
          changeInactive={() => setInactive(!inactive)}
          exceptNewPatient={exceptNewPatient}
          changeExceptNewPatient={() => setExceptNewPatient(!exceptNewPatient)}
          allowDeleteSchedule={!!event?.providers?.[0]?.doctor_dsb_id}
          onDeleteSchedule={() => setOpenModMessage(true)}
          // Finish button
          finish={(e: any) => {
            if (selectedProvider) {
              handleFinish();
            } else {
              // For division, not specific provider
              props.onEvent({ message: "GetChairWithDivisionServiceBlock", params: { division: props.selectedDivision?.id || "" } });
            }
          }}
          disabledDeleteSchedule={event?.providers?.[0]?.appointments?.length > 0}
        />
      </Modal>

      {/* Open when no selectedProvider, show details of scheduled blocks */}
      <Modal open={showListModal} closeOnDimmerClick={true} onClose={(e: any) => setShowListModal(false)}>
        <ModEventDetailUX
          title={`${formatADtoBEString(event?.date)} ${event?.title}`}
          providers={event?.providers?.map((item: any) => ({
            ...item?.dsb,
            chair: item?.dsb?.chair_provider_id ? props?.chairList?.filter((acc: any) => acc.provider === item?.dsb?.chair_provider_id)?.[0]?.name : "ว่าง",
            zone: item?.dsb?.chair_zone_id ? props?.divisionDict?.[item?.dsb?.chair_zone_id]?.name : "ว่าง",
            number_appointments: `${item?.dsb?.appointments?.length} (${
              ((parseFloat(item?.dsb?.doctor_end_time) - parseFloat(item?.dsb?.doctor_start_time)) * 60) / item?.dsb?.doctor_dsb_slot_length
            })`,
            date: formatADtoBEString(item?.dsb?.date),
          }))}
          rowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
            style: {
              backgroundColor: props.selectedBlock && rowInfo?.original?.dsb_id === props.selectedBlock?.dsb_id ? "#cccccc" : "white",
            },
            onClick: () => {
              console.log(rowInfo?.original);
            },
          })}
        />
      </Modal>

      <ModConfirm
        closeIcon={true}
        titleColor={"red"}
        openModal={!!props.openModConfirmAddBlock}
        titleName={"ยืนยันงดออกตรวจ"}
        content={
          <div style={{ textAlign: "center", lineHeight: 2.25, margin: "-10px", fontWeight: "bold" }}>
            <div>วันที่ {props.openModConfirmAddBlock?.datetime || ""}</div>
            <div>
              มีผู้ป่วยนัดหมาย {props.openModConfirmAddBlock?.provider_name || ""} จำนวน {props.openModConfirmAddBlock?.number || ""} คน
            </div>
            <div>จะต้องยืนยันวันเวลานัดหมายใหม่อีกครั้ง</div>
          </div>
        }
        onDeny={() => props.setProp("openModConfirmAddBlock", null)}
        onApprove={handleConfirmAddBlock}
        onCloseWithDimmeClick={() => props.setProp("openModConfirmAddBlock", null)}
        approveButtonText={"ใช่"}
        approveButtonColor={"green"}
        denyButtonText={"ไม่"}
        denyButtonColor={"red"}
      />

      <ModConfirmAuthen
        {...{
          title: "ยืนยันลบตารางออกตรวจ",
          questionDetail: "ระบุเหตุผลการลบตารางออกตรวจ",
          titleColor: "red",
          userEditQuestion: "ระบุชื่อผู้บันทึกข้อมูล",
          buttonLeftLabel: "ใช่",
          buttonRightLabel: "ไม่",
          buttonLeftColor: "green",
          buttonRightColor: "red",
          size: "small",
          // disableButtonLeft: !props.userTokenize?.employeeName || !currentValue,
          isUserEditQuesion: true,
          isErrMsg: true,
        }}
        onEvent={props.onEvent}
        setProp={props.setProp}
        userTokenize={props.userTokenize}
        onButtonLeftClick={handleConfirmedButton}
        onButtonRightClick={handleCancelButton}
        open={openModMessage}
        inputType="textarea"
        onClose={handleCancelButton}
        isButtonBasic={true}
        handleChange={handleChange}
        currentValue={currentValue as any}
      />
    </div>
  );
}

export default DentistSchedule;