import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Modal, Checkbox, Label } from "semantic-ui-react";

import moment from "moment";

// UX
import CardCreateInvoiceGroupUX from "./CardCreateInvoiceGroupUX";
import CardInputDateForCreateUX from "./CardInputDateForCreateUX";
import CardPatientExpenseItemInfoUX from "./CardPatientExpenseItemInfoUX";
import CardMedicalFee from "../BIL/CardMedicalFee";

// Common
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import { ModInfo } from "react-lib/apps/common";

// Utils
import { adToBe } from "react-lib/utils/dateUtils";

// Types
type CardCreateInvoiceGroupProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: any;
  ARInvoiceGroupSequence?: any;
  BillPaymentSequence?: any;
  // data
  billtransactionItem?: any[];
  commonBillTransaction?: Record<string, any>;
  // options
  coveragePayerOptions?: any[];
  masterOptions?: Record<string, any>;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  selectedPatient?: any;
  selectedEncounter?: any;
  invoiceItemByModes?: any[];
  invoiceItemByOrders?: any[];
  invoiceItemByItems?: any[];
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  // Bill Report Print
  billReportPrint?: Record<string, any>;
};

// Const
const BILL_STATUS_COLORS = {
  ชำระเงินแล้วเรียบร้อย: "green",
  รอรับชำระเงิน: "orange",
  ยกเลิก: "red",
};

const ACTIONS = {
  CREATE_AR_TRANSACTION: "create_ar_transaction",
};

const CARD_CREATE_INVOICE_GROUP = "CardCreateInvoiceGroup";

const CardCreateInvoiceGroup = (props: CardCreateInvoiceGroupProps) => {
  // Mod
  const [modGenerateAR, setModGenerateAR] = useState({
    open: false,
    coveragePayer: null,
    date: "",
  });
  const [modPatientExpense, setModPatientExpense] = useState<Record<
    string,
    any
  > | null>(null);
  const [openModSuccess, setOpenModSuccess] = useState<string>("");
  const [openModError, setOpenModError] = useState<boolean>(false);
  // Check
  const [checkedIds, setCheckedIds] = useState<number[]>([]);

  useEffect(() => {
    props.runSequence({ sequence: "ARInvoiceGroup", restart: true });
    
    return () => {
      props.runSequence({ sequence: "ARInvoiceGroup", clear: true });
      props.setProp("billtransactionItem", []);
    }
  }, []);

  useEffect(() => {
    setCheckedIds(props.billtransactionItem?.map((item: any) => item.id) || []);
  }, [props.billtransactionItem]);

  useEffect(() => {
    const success =
      props.successMessage?.[
        `${CARD_CREATE_INVOICE_GROUP}_${ACTIONS.CREATE_AR_TRANSACTION}`
      ];

    if (success) {
      props.setProp(
        `successMessage.${CARD_CREATE_INVOICE_GROUP}_${ACTIONS.CREATE_AR_TRANSACTION}`,
        null
      );

      setOpenModSuccess(success);
    }
  }, [props.successMessage]);

  const handleChecked = useCallback(
    (e: any, data: any) => {
      let ids = [...checkedIds];
      if (data.checked) {
        ids = [...ids, data.name];
      } else {
        ids = ids.filter((id) => id !== data.name);
      }

      setCheckedIds(ids);
    },
    [checkedIds]
  );

  const billingTransaction = useMemo(() => {
    return (props.billtransactionItem || []).map((item: any) => ({
      ...item,
      checked: (
        <div style={{ display: "grid", placeContent: "center" }}>
          <Checkbox
            name={item.id}
            checked={checkedIds.includes(item.id)}
            // onChange={handleChecked}
          />
        </div>
      ),
      detail: (
        <Button
          content="รายละเอียดค่าใช้จ่าย"
          color="orange"
          size="mini"
          onClick={() => {
            props.onEvent({
              message: "GetCommonBillTransaction",
              params: { invoiceNo: item.invoice_no },
            });

            setModPatientExpense(item);
          }}
        />
      ),
      coverage_payer:
        props.coveragePayerOptions?.find(
          (coverage: any) =>
            coverage.value === item.coverage_payer_sent_claim_group_item_ref
        )?.text || "",
      payer:
        props.masterOptions?.payer?.find(
          (payer: any) => payer.value === item.sent_claim_payer
        )?.text || "",
    }));
  }, [props.billtransactionItem, checkedIds]);

  const summary = useMemo(() => {
    const items: any[] = (props.billtransactionItem || []).filter((item: any) =>
      checkedIds.includes(item.id)
    );
    const keyValues = [
      ["total_sent_claim_price", "claim_amount"],
      ["total_paid_price", "paid"],
      ["total_other_pay_price", "other_pay"],
    ];

    const sum = items.reduce((result, item) => {
      return keyValues.reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: Number(result[key] || 0) + Number(item[value]),
        }),
        {} as any
      );
    }, {});

    return {} as any; //{ ...sum, total_rows: items.length };
  }, [checkedIds, props.billtransactionItem]);

  const mapOptions = (items: any) => {
    return items.map((item: any) => ({
      key: item?.id,
      value: item?.id,
      text: `${item.lot_no}-${item.fiscal_year} ${item.payer_code_name}`,
    }));
  };

  const handleChangeOption = (name: string) => (e: any, data: any) => {
    props.setProp(`ARInvoiceGroupSequence.${name}`, data.value);
  };

  const handleOnDateChange = (name: string) => (date: string) => {
    console.log("handleOnDateChange ", date, name);
    props.setProp(`ARInvoiceGroupSequence.${name}`, date);
  };

  const handleOnChecked = (e: any, data: any) => {
    console.log("handleOnChecked: ", data);
    props.setProp("ARInvoiceGroupSequence", {
      ...props.ARInvoiceGroupSequence,
      startDate: "",
      endDate: "",
      checkedSelectedDate: data.checked,
    });
  };

  const handleOnChangeRadio = (value: string) => (e: any, data: any) => {
    // console.log("handleOnChangeRadio: ", props.ARInvoiceGroupSequence.checkedInvoiceType, value)
    if (props.ARInvoiceGroupSequence?.checkedInvoiceType !== value) {
      props.setProp("ARInvoiceGroupSequence.checkedInvoiceType", value);
    } else {
      props.setProp("ARInvoiceGroupSequence.checkedInvoiceType", "");
    }
  };

  const handleOnSearch = () => {
    props.runSequence({ sequence: "ARInvoiceGroup", action: "search" });
  };

  const handleOnCreateAR = () => {
    setModGenerateAR({
      open: true,
      coveragePayer:
        props.ARInvoiceGroupSequence?.selectedCoveragePayer || null,
      date: adToBe(moment().format("YYYY-MM-DD"), "YYYY-MM-DD"),
    });
  };

  const handleCloseModal = () => {
    setModPatientExpense(null);

    props.setProp(`commonBillTransaction`, {});
  };

  const handleCreate = (e: any, data: any) => {
    // if (!checkedIds.length) {
    //   return setOpenModError(true);
    // }

    props.runSequence({
      sequence: "ARInvoiceGroup",
      action: data.name,
      card: CARD_CREATE_INVOICE_GROUP,
    });
  };

  const handleCloseModSuccess = () => {
    setOpenModSuccess("");
  };

  const handleCloseModError = () => {
    setOpenModError(false);
  };

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_CREATE_INVOICE_GROUP}`, null);
        }}
        error={props.errorMessage?.[CARD_CREATE_INVOICE_GROUP]}
        success={null}
      />

      <CardCreateInvoiceGroupUX
        // data
        selectedCoveragePayer={
          props.ARInvoiceGroupSequence?.selectedCoveragePayer
        }
        selectedPayer={props.ARInvoiceGroupSequence?.selectedPayer}
        checkedSelectedDate={props.ARInvoiceGroupSequence?.checkedSelectedDate}
        startDate={props.ARInvoiceGroupSequence?.startDate}
        endDate={props.ARInvoiceGroupSequence?.endDate}
        checkedInvoiceType={props.ARInvoiceGroupSequence?.checkedInvoiceType}
        claimInfoCount={summary.total_rows || 0}
        claimAmount={(summary.total_sent_claim_price || 0).toFixed(2)}
        claimPatientAmount={(summary.total_paid_price || 0).toFixed(2)}
        coPaySummary={(summary.total_other_pay_price || 0).toFixed(2)}
        // options
        coveragePayerOptions={props.coveragePayerOptions || []}
        payerOptions={props.masterOptions?.payer || []}
        billingTransactionItem={billingTransaction}
        // config
        disabledDate={!props.ARInvoiceGroupSequence?.checkedSelectedDate}
        // function
        onChangeOption={handleChangeOption}
        onChangeChecked={handleOnChecked}
        onDateChange={handleOnDateChange}
        onCheckRadio={handleOnChangeRadio}
        onCreateAR={handleOnCreateAR}
        onSearchClick={handleOnSearch}
        // component
        arInvoiceSearch={
          <SearchBoxDropdown
            id={"1"}
            fluid={true}
            style={{ width: "100%" }}
            onEvent={props.onEvent}
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={props.ARInvoiceGroupSequence?.selectedLotNo}
            disabled={
              props.ARInvoiceGroupSequence?.checkedInvoiceType !== "fromPayer"
            }
            setSelectedItem={(value: any, key: any, obj: any) => {
              console.log("setSelectedItem", value, key);
              if (value === null && key === null) {
                props.setProp("ARInvoiceGroupSequence.selectedLotNo", null);
                return;
              }
              let data =
                props.searchedItemListWithKey?.ARTransactionLotNO_1?.find(
                  (item: any) => item.id === value
                );
              console.log(
                "setSelectedItem",
                props.searchedItemListWithKey?.ARTransactionLotNO_1
              );
              props.setProp(
                "ARInvoiceGroupSequence.selectedLotNo",
                data?.id || null
              );
            }}
            type="ARTransactionLotNO"
            // toDisplay={(item: any) =>
            //     `${item.lot_no}-${item.fiscal_year} ${item.payer_code_name}`
            // }
            mapOptions={mapOptions}
            useWithKey={true}
          />
        }
        // Element
        ButtonCreate={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleCreate}
            // data
            paramKey={`${CARD_CREATE_INVOICE_GROUP}_${ACTIONS.CREATE_AR_TRANSACTION}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_CREATE_INVOICE_GROUP}_${ACTIONS.CREATE_AR_TRANSACTION}`
              ]
            }
            // config
            disabled={!props.ARInvoiceGroupSequence?.selectedCoveragePayer}
            color={"green"}
            name={ACTIONS.CREATE_AR_TRANSACTION}
            size="medium"
            title="สร้างเลขที่ใบแจ้งหนี้"
          />
        }
      />
      <Modal open={modGenerateAR?.open} closeOnDimmerClick={false} size="large">
        <CardInputDateForCreateUX
          // data
          date={modGenerateAR?.date}
          coveragePayer={modGenerateAR?.coveragePayer}
          coveragePayerOptions={props.coveragePayerOptions}
          // function
          onChangeDate={(date: string) => {
            setModGenerateAR({ ...modGenerateAR, date: date });
          }}
          onCoveragePayerChange={(e: any, data: any) => {
            setModGenerateAR({ ...modGenerateAR, coveragePayer: data.value });
          }}
          onConfirm={() => {
            props.runSequence({
              sequence: "ARInvoiceGroup",
              action: "create_ar",
              coveragePayer: modGenerateAR.coveragePayer,
              date: modGenerateAR.date,
            });
            setModGenerateAR({ open: false, date: "", coveragePayer: null });
          }}
          onClose={() =>
            setModGenerateAR({ open: false, date: "", coveragePayer: null })
          }
        />
      </Modal>

      <Modal
        open={!!modPatientExpense}
        style={{ width: "80%" }}
        closeOnDimmerClick={true}
        onClose={handleCloseModal}
      >
        <CardPatientExpenseItemInfoUX
          // data
          data={{
            ...props.commonBillTransaction,
            patient_name: modPatientExpense?.name,
          }}
          // callback
          onClose={handleCloseModal}
          // Element
          status={
            <Label
              color={
                (BILL_STATUS_COLORS as any)[
                  props.commonBillTransaction?.encounter_payment_status || ""
                ]
              }
              style={{ marginRight: "2rem" }}
            >
              {props.commonBillTransaction?.encounter_payment_status || ""}
            </Label>
          }
          CardMedicalFee={
            <>
              <CardMedicalFee
                onEvent={props.onEvent}
                setProp={props.setProp}
                patientInfo={{}}
                receiptId={props.commonBillTransaction?.receipt}
                invoiceItemByModes={props.invoiceItemByModes}
                invoiceItemByOrders={props.invoiceItemByOrders}
                invoiceItemByItems={props.invoiceItemByItems}
                BillPaymentSequence={props.BillPaymentSequence}
                errorMessage={props.errorMessage}
                // Bill Report Print
                billReportPrint={props.billReportPrint}
                // config
                viewMode={true}
                // Hide แจ้งชำระเงิน และ รับชำระเงิน button
                hidebp={true}
                hidebpInvoice={true}
              />
            </>
          }
        />
      </Modal>

      <ModInfo
        open={!!openModSuccess}
        titleColor={"green"}
        titleName={"สร้างเลขที่ใบแจ้งหนี้สำเร็จ"}
        style={{ width: "30%" }}
        onApprove={handleCloseModSuccess}
        onClose={handleCloseModSuccess}
      >
        <div
          style={{ padding: "1rem 0", display: "flex", alignItems: "center" }}
        >
          <label
            style={{
              paddingRight: "10px",
              fontSize: "1.1rem",
              minWidth: "max-content",
              fontWeight: "bold",
            }}
          >
            เลขที่ใบแจ้งหนี้
          </label>
          <Label
            size="large"
            style={{
              fontSize: "1.1rem",
              color: "rgba(0,0,0,0.87)",
              width: "100%",
            }}
          >
            {openModSuccess}
          </Label>
        </div>
      </ModInfo>

      <ModInfo
        open={openModError}
        titleColor={"red"}
        titleName={"แจ้งเตือน"}
        onApprove={handleCloseModError}
        onClose={handleCloseModError}
      >
        <div style={{ padding: "0.5rem 0" }}>
          <label style={{ paddingRight: "10px", fontSize: "1.1rem" }}>
            กรุณาเลือกรายการที่ต้องการสร้างเลขที่ใบแจ้งหนี้
          </label>
        </div>
      </ModInfo>
    </div>
  );
};

export default CardCreateInvoiceGroup;
