import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input,
  Button,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardDrugResuscitationUx = (props: any) => {
    return(
      <div
        style={{margin: "10px", backgroundColor: "#F3F3F3", height:"90%"}}>
        <div
          style={{display: "flex", alignItems: "center", padding: "15px"}}>
          
          <label
            style={{width:"auto",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
            เลขที่ใบจัดกล่อง
          </label>
          <Input
            icon="search"
            name="filterBox.code"
            onChange={props.handleChangeData}
            style={{width:"140px"}}
            value={props.DrugResuscitationSequence?.filterBox?.code || ""}>
          </Input>
          <label
            style={{width:"auto",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
            วันที่เปิดกล่องยา
          </label>
          <DateTextBox
            onChange={props.handleChangeStartDate}
            value={props.DrugResuscitationSequence?.filterBox?.created_start || ""}>
          </DateTextBox>
          <label
            style={{width:"auto",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
            ถึง
          </label>
          <DateTextBox
            onChange={props.handleChangeEndDate}
            value={props.DrugResuscitationSequence?.filterBox?.created_end || ""}>
          </DateTextBox>
          <label
            style={{width:"auto",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
            เลขที่กล่อง
          </label>
          <Input
            name="filterBox.container_code"
            onChange={props.handleChangeData}
            style={{width:"140px"}}
            value={props.DrugResuscitationSequence?.filterBox?.container_code || ""}>
          </Input>
          <label
            style={{width:"auto",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
            ชื่อกล่องยา
          </label>
          <Input
            name="filterBox.container_name"
            onChange={props.handleChangeData}
            style={{width:"140px"}}
            value={props.DrugResuscitationSequence?.filterBox?.container_name || ""}>
          </Input>
          <label
            style={{width:"auto",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
            สถานะ
          </label>
          <Dropdown
            clearable={true}
            name="filterBox.status"
            onChange={props.handleChangeData}
            options={props.statusTypeItems || []}
            search={true}
            selection={true}
            style={{width:"70px", margin: "0px 10px 0px 0px"}}
            value={props.DrugResuscitationSequence?.filterBox?.status || ""}>
          </Dropdown>
          <div>
            {props.buttonSearchBox}
          </div>
          <div
            style={{flex:1}}>
            
          </div>
          <Button
            color="blue"
            onClick={props.addNewClick}
            size="small"
            style={{display: props.isNurseScreen ? "none" : "flex", margin: "0px 4px 0px 5px"}}>
            เพิ่มใบจัดกล่องยา
          </Button>
        </div>
        <div
          style={{ height: "20%", position: "relative", padding: "15px" }}>
          
          <Table
            columns={props.columnsBoxItem}
            data={props?.boxItems}
            getTrProps={props.boxTableRowProps}
            showPagination={false}
            style={{fontSize: "medium", height: "20vh"}}>
          </Table>
        </div>
        <div
          style={{ height: "50%", position: "relative", padding: "15px" }}>
          {props.container}
        </div>
      </div>
    )
}


export default CardDrugResuscitationUx

export const screenPropsDefault = {}

/* Date Time : Mon Jul 10 2023 03:43:00 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", backgroundColor: \"#F3F3F3\", height:\"90%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", padding: \"15px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Input",
      "parent": 8,
      "props": {
        "icon": {
          "type": "value",
          "value": "search"
        },
        "name": {
          "type": "value",
          "value": "filterBox.code"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"140px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.filterBox?.code || \"\""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"20%\", position: \"relative\", padding: \"15px\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 15,
      "name": "Table",
      "parent": 14,
      "props": {
        "columns": {
          "type": "code",
          "value": "props.columnsBoxItem"
        },
        "data": {
          "type": "code",
          "value": "props?.boxItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.boxTableRowProps"
        },
        "headers": {
          "type": "value",
          "value": ""
        },
        "keys": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"medium\", height: \"20vh\"}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.container"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"50%\", position: \"relative\", padding: \"15px\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบจัดกล่อง"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่เปิดกล่องยา"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Button",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มใบจัดกล่องยา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.addNewClick"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.isNurseScreen ? \"none\" : \"flex\", margin: \"0px 4px 0px 5px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Dropdown",
      "parent": 8,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "filterBox.status"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.statusTypeItems || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"70px\", margin: \"0px 10px 0px 0px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.filterBox?.status || \"\""
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 28,
      "name": "DateTextBox",
      "parent": 8,
      "props": {
        "id": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeStartDate"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.filterBox?.created_start || \"\""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 29,
      "name": "DateTextBox",
      "parent": 8,
      "props": {
        "id": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeEndDate"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "tabIndex": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.filterBox?.created_end || \"\""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearchBox"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่กล่อง"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อกล่องยา"
        },
        "style": {
          "type": "code",
          "value": "{width:\"auto\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Input",
      "parent": 8,
      "props": {
        "name": {
          "type": "value",
          "value": "filterBox.container_code"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"140px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.filterBox?.container_code || \"\""
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Input",
      "parent": 8,
      "props": {
        "name": {
          "type": "value",
          "value": "filterBox.container_name"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"140px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.filterBox?.container_name || \"\""
        }
      },
      "seq": 31,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugResuscitationUx",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
