import WasmController from "react-lib/frameworks/WasmController";

// APIs
// BIL
import BeforePaymentCheckAPI from "issara-sdk/apis/BeforePaymentCheck_apps_BIL";
import ReceiptList from "issara-sdk/apis/ReceiptList_apps_BIL";
import ReceiptSerializer from "issara-sdk/types/ReceiptSerializer_apps_BIL";
import ReceiptCodeView from "issara-sdk/apis/ReceiptCodeView_apps_BIL";
import InvoiceList from "issara-sdk/apis/InvoiceList_apps_BIL";
import ReceiptByItemList from "issara-sdk/apis/ReceiptByItemList_apps_BIL";
import PrintReceiptByItemView from "issara-sdk/apis/PrintReceiptByItemView_apps_BIL";
import SimpleReceiptList from "issara-sdk/apis/SimpleReceiptList_apps_BIL"
// ADM
import AdmitOrderRoomItemDetail from "issara-sdk/apis/AdmitOrderRoomItemDetail_apps_ADM";

// Interface
import { GetMasterDataPayMethod } from "../BILInterface";

// Common
import getPdfMake from "react-lib/appcon/common/pdfMake";

// Form
import FormReceipt from "../FormReceipt";
import FormReceiptCoverageDocument from "../FormReceiptCoverageDocument";

// Utils
import * as CAgent from "react-lib/apps/common/CAgent";

export type State = {
  // CommonInterface
  bilReceiptCodeDetail?: {
    code: string;
    BIL_RECEIPT_SYSTEM_PREFIX: string,
    BIL_RECEIPT_PREFIX_SIZE: number,
    BIL_RECEIPT_RUNNING_SIZE: number,
  };
  errorMessage?: any;
  selectedAdmitOrderRoomItem?: any;
  selectedPatient?: any;
  invoiceItemByModes?: any;
  invoiceItemByOrders?: any;
  invoiceItemByItems?: any;
  invoiceHistory?: any;
  goToMenu?: string;
  // Sequence
  BillPaymentSequence?: {
    sequenceIndex: string | null;
    openCardPaying?: boolean;
    sumAmount?: any;
    cashAmountText?: string;
    oweText?: string;
    cannotPay?: boolean;
    items?: any[];
    payments?: Payment[];
    rawOweText?: string;
    transferText?: string;
    cardText?: string;
    chequeText?: string;
    couponText?: string;
    installmentText?: string;
    totalText?: string;
    rawTotalText?: string;
    roundOffText?: string;
    masterAccountList?: any[];
    masterEDCList?: any[];
    receiptTargetList?: any[];
    requireApprovalCodeCheck?: {
      require_approval_code: boolean;
    };
    edcTransactionAmount?: {
      total_amount: number;
    };
    requireInterfaceClaimCodeCheck?: {
      require_interface_claim_code: boolean;
    };
    paymentMethodList?: {
      type: number | string;
      paymentNo: string | string[];
      paymentTarget: number;
      value: string;
      couponMessage?: "success" | "error";
    }[];
    autoEDC?: Partial<{
      check: boolean;
      approvalType: string;
      traceId: string;
      approvalCode: string;
    }>;
    payErrorMessage?: any;
    invoiceData?: any;
  } | null;
};

export const StateInitial: State = {
  BillPaymentSequence: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "SelectEncounter"; params: any };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Payment = {
  type: number;
  payment_no: string;
  payment_target: number;
  value: number;
};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const BeforePaymentCheck: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.BillPaymentSequence) return;

  if (params.action === "invoice") {
    const result = await InvoiceList.create({
      data: {
        items: params.idList,
        patient: state.selectedPatient.id,
        type: "FULL_PAYMENT",
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
      apiToken: controller.apiToken,
    });

    if (result[0]) {
      controller.setState({
        invoiceHistory: true,
        BillPaymentSequence: {
          ...state.BillPaymentSequence,
          invoiceData: result[0],
        },
        goToMenu: params.goToMenu
      });
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: result[1],
        },
      });
    }

    return;
  }

  if (!params.invoiceData?.items) {
    return;
  }

  // ผู้ป่วยชำระราย item เปลี่ยนเป็น ผลรวมของ pay ที่ user กรอกมา
  let items: any[] = params.invoiceData.items || [];
  const isOrderItem = params.invoiceData.orderBy === "Item";
  const byItems: any[] = params.invoiceItemByItems || [];

  if (isOrderItem) {
    items = items.map((item) => ({
      ...item,
      price: item.pay,
      payable: item.pay,
    }));
  } else {
    // คำนวณค่าใช้จ่ายทั้งหมดโดยนำมาจาก pay ที่ by-items
    items = items.map((item) => {
      const sum = item.id_list.reduce(
        (result: number, id: number) =>
          result + Number(byItems.find((item) => item.id === id)?.pay || 0),
        0
      );
      return {
        ...item,
        price__sum: sum,
        payable__sum: sum,
      };
    });
  }

  const sumInitial = {
    price: 0,
    reimbursable: 0,
    cashonly: 0,
    discount: 0,
    payable: 0,
    send_claim: 0,
    absorb: 0,
    patient_claim: 0,
    non_claimable: 0,
  };

  const sum = items.reduce((acc: any, item: any) => {
    if (item.status__name === "BILLED" && !item.payment_ready) return acc;
    for (const key of Object.keys(sumInitial)) {
      acc[key] +=
        Number.parseFloat(item[`${key}${isOrderItem ? "" : "__sum"}`]) || 0;
    }
    return acc;
  }, sumInitial);

  const check = await BeforePaymentCheckAPI.post({
    data: {
      items: params.invoiceData.ids,
    },
    apiToken: controller.apiToken,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  if (check[0]?.status === "error") {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: check[0]?.message },
    });
  }

  if (check[0]?.status === "success" || params.allowKTB) {
    const oweText = sumInitial.payable
    const roundOff = getDecimal(oweText)

    controller.setState({
      BillPaymentSequence: {
        ...state.BillPaymentSequence,
        sequenceIndex: "PaymentCalculate",
        sumAmount: {
          ...sum,
          payable: sum.payable - roundOff
        },
        openCardPaying: true,
        cashAmountText: "",
        oweText: (oweText - roundOff).toFixed(2),
        roundOffText: roundOff.toFixed(2),
        cannotPay: true,
        items: params.invoiceData.ids,
        rawOweText: (oweText - roundOff).toFixed(2),
        paymentMethodList: [
          { type: "1", paymentNo: "", paymentTarget: 0, value: "0.00" },
        ],
        autoEDC: {
          check: true,
          approvalType: "SELF",
        },
        transferText: "0.00",
        cardText: "0.00",
        chequeText: "0.00",
        couponText: "0.00",
        installmentText: "0.00",
        totalText: "0.00",
        rawTotalText: "0.00",
      },
    });

    GetMasterDataPayMethod(controller as any, {
      ...params,
      idList: params.invoiceData.ids,
    });
  } else if (check[0]?.message === "รายการรับชำระย้อนหลัง ต้องขอเลข approval code ในระบบ KTB"
  ) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [`${params.card}_KTB`]: check[0]?.message,
      },
    });
  }
};

export const PaymentCalculate: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.BillPaymentSequence) return;
  if (params.action === "close")
    return controller.setState({ BillPaymentSequence: null });

  if (params.action !== "pay") {
    UpdateTextMoneyValue(controller, params);
  } else if (params.action === "pay") {
    return controller.setState(
      {
        BillPaymentSequence: {
          ...state.BillPaymentSequence,
          sequenceIndex: "CreateReceipt",
        },
      },
      () => controller.handleEvent({ message: "RunSequence", params: params })
    );
  }
};

const UpdateTextMoneyValue: Handler = (controller, params) => {
  const state = controller.getState();

  if (!state.BillPaymentSequence) {
    return;
  }

  const sumInitial = {
    transferText: 0,
    cardText: 0,
    chequeText: 0,
    couponText: 0,
    installmentText: 0,
  };

  const type: any = {
    2: "transferText",
    3: "cardText",
    4: "chequeText",
    5: "couponText",
    7: "installmentText",
  };

  const cashAmountText = state.BillPaymentSequence.cashAmountText;
  const rawOweText = Number(state.BillPaymentSequence.rawOweText);
  const roundOffText = Number(state.BillPaymentSequence.roundOffText);
  const payments = state?.BillPaymentSequence?.paymentMethodList || [];

  const sum = payments.reduce((acc, item) => {
    // if (item.type === "1") {
    //   (acc as any)[type[item.type]] += Number.parseFloat(item.value) || 0;
    // }
    (acc as any)[type[item.type]] += Number.parseFloat(item.value) || 0;

    return acc;
  }, sumInitial);

  const total =
    sum.transferText +
    sum.cardText +
    sum.chequeText +
    sum.couponText +
    sum.installmentText +
    (Number(cashAmountText) || 0);

  const billPayment = {
    ...state.BillPaymentSequence,
    transferText: sum.transferText.toFixed(2),
    cardText: sum.cardText.toFixed(2),
    chequeText: sum.chequeText.toFixed(2),
    couponText: sum.couponText.toFixed(2),
    installmentText: sum.installmentText.toFixed(2),
    totalText: rawOweText > total ? total.toFixed(2) : rawOweText.toFixed(2),
    rawTotalText: total.toFixed(2),
    oweText: rawOweText > total ? (rawOweText - total).toFixed(2) : "0.00",
    sequenceIndex: "PaymentCalculate",
    cannotPay: rawOweText > total,
  };

  controller.setState({ BillPaymentSequence: billPayment });

  if (params.isUpdateCashAmount) {
    payments[0].paymentNo = `${Number(cashAmountText).toFixed(2)} / ${Number(cashAmountText) > rawOweText
      ? (Number(cashAmountText) - rawOweText).toFixed(
        2
      )
      : "0.00"
      }`;
    payments[0].value =
      Number(cashAmountText) > rawOweText
        ? `${rawOweText}`
        : cashAmountText || "";

    controller.setState({
      BillPaymentSequence: { ...billPayment, paymentMethodList: payments },
    });
  }
};

export const CreateReceipt: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.BillPaymentSequence) return;
  console.log("create receipt");

  params.loading?.(true);

  const bill = state.BillPaymentSequence;
  const payments = [...(bill.paymentMethodList || [])].map((item) => ({
    type: item.type,
    payment_no:
      item.type === 3
        ? `${item.paymentNo?.[0]}${item.paymentNo?.[1]}${item.paymentNo?.[2]}${item.paymentNo?.[3]}`
        : item.paymentNo,
    payment_target: item.paymentTarget,
    value: item.value,
  }));

  if (payments[0].type === "1" && Number(payments[0].value) === 0) {
    payments.shift();
  }

  // ยังไม่พร้อมใช้งาน comment ไว้ก่อนค่อยมาเปิด function นี้
  // if (
  //   bill.autoEDC?.check &&
  //   bill.requireApprovalCodeCheck?.require_approval_code &&
  //   !params.confirmData
  // ) {
  //   return HandleKtbedcApprove(controller, params);
  // }

  let data = {
    payments,
    items: params.idList,
    patient: state.selectedPatient.id,
    patient_name_lang: params.patientNameLang,
    price: bill.rawOweText,
    invoice: params.invoice,
    code: "",
    round_off: bill.roundOffText,
    reference_code: bill.autoEDC?.traceId || "",
    approval_code: bill.autoEDC?.approvalCode || "",
    terminal_id: "",
    merchant_id: "",
    tx_date: null,
    tx_time: null,
    edc_amount: bill.edcTransactionAmount?.total_amount,
    require_approval_code:
      bill.requireApprovalCodeCheck?.require_approval_code ?? false,
    receipt_code: state.bilReceiptCodeDetail?.code || "",
    // receipt_code: "01-0000003",
    // receipt_target: null,
    // nhso_token_key: root.nhso_token_key,
    // nhso_patient_type: root.nhso_patient_type,
    // nhso_smartcard_data: root.nhso_smartcard_data,
  } as ReceiptSerializer;

  // เมื่อเป็นสิทธิชำระเงินและ Confirm เมื่อเรียก CAgent
  if (params.confirmData) {
    const confirm = params.confirmData;
    data = {
      ...data,
      reference_code: confirm.traceId || "",
      approval_code: confirm.approvalCode || "",
      terminal_id: confirm.terminalId || "",
      merchant_id: confirm.merchantId || "",
      tx_date: confirm.date || null,
      tx_time: confirm.time || null,
      edc_amount: bill.edcTransactionAmount?.total_amount,
    };
  }

  const api = params.simpleReceipt ? SimpleReceiptList.create : ReceiptByItemList.post

  const result = await api({
    data,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  console.log(result[0] ? result[0] : result[1]);

  if (result[1]) {
    params.loading?.(false);

    controller.setState({
      BillPaymentSequence: {
        ...state.BillPaymentSequence,
        payErrorMessage: result[1],
        sequenceIndex: "PaymentCalculate",
      },
    });
  } else {
    // TODO * เพื่อแก้ปัญหา get invoiceitem แล้ว response ไม่เปลี่ยนแปลง
    await new Promise((resolve) => setTimeout(() => resolve("!!!DONE"), 500));

    if (!params.simpleReceipt){
      await HandlePrintReceipt(controller, { id: result[0].id });
    }

    params.loading?.(false);

    params.callback?.({ status: "success", message: "" });

    // #59883 ไม่แสดง pop-up ว่าบันทึกสำเร็จ
    controller.setState({ BillPaymentSequence: {openCardPaying: true} });

    // Invoice
    controller.handleEvent({ message: "GetInvoiceItemByMode" } as any);
    controller.handleEvent({ message: "GetInvoiceItemByOrder" } as any);
    controller.handleEvent({ message: "GetInvoiceItemByItem" } as any);

    // Bill Pending
    controller.handleEvent({
      message: "GetBillPendingDetail",
      params: {},
    } as any);

    // Receipt Code
    controller.handleEvent({
      message: "HandleGetReceiptCode",
      params: {},
    } as any);

    if (state.selectedAdmitOrderRoomItem?.id) {
      const admitRes = await AdmitOrderRoomItemDetail.retrieve({
        pk: state.selectedAdmitOrderRoomItem?.id,
        apiToken: controller.apiToken,
      });

      controller.setState({
        selectedAdmitOrderRoomItem: admitRes[0] || null,
      });

    }
  }
};

export const HandlePrintReceipt: Handler = async (
  controller,
  params: { id: number }
) => {
  const result = await PrintReceiptByItemView.get({
    apiToken: controller.apiToken,
    pk: params.id,
  });

  const [receipt, receiptCoverageDoc] = await Promise.all([
    createPDFReceipt(result[0]?.receipt),
    createPDFReceiptCoverageDocList(result[0]?.receipt_coverage_document_list),
  ]);

  receipt?.open();

  for (const doc of receiptCoverageDoc || []) {
    doc.open();
  }
};

const HandleKtbedcApprove: Handler = (controller, params) => {
  const state = controller.getState();
  const patient = state.selectedPatient || {};
  const bill = state.BillPaymentSequence;

  if (patient.citizen_type == "T") {
    patient.personal_id = patient.citizen_no;
  } else if (patient.citizen_type == "F") {
    patient.personal_id = patient.reimburse_foreign_code;
  } else if (patient.citizen_type == "A") {
    patient.personal_id = patient.reimburse_foreign_code;
  }

  CAgent.ktbedcApprove(
    patient.personal_id.replace(/\D/g, ""),
    bill?.edcTransactionAmount?.total_amount,
    bill?.autoEDC?.approvalType
  )
    .then(function (result) {
      if (result.approvalCode) {
        params.callback?.({ status: "confirm", message: result });
        params.loading?.(false);
      } else {
        params.callback?.({ status: "error_payment", message: result });
        params.loading?.(false);
      }
    })
    .catch(function (result) {
      params.loading?.(false);

      controller.setState({
        BillPaymentSequence: {
          ...state.BillPaymentSequence,
          payErrorMessage: result,
          sequenceIndex: "PaymentCalculate",
        },
      });
    });
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
const getDecimal = (number: any) => {
  var decimalPlace = number - parseInt(number)
  return parseFloat(decimalPlace.toFixed(2))
}

const createPDFReceipt = async (receipt: any) => {
  if (!receipt) {
    return null;
  }

  let docDef: any = { content: [] };

  const fields: any[] = receipt.fields || [];

  const data = {
    ...receipt,
    fields,
    sum_non_claimable: fields
      .reduce(
        (result, item) => Number(item.non_claimable.replace(",", "")) + result,
        0
      ),
    sum_self_reimbursement: fields
      .reduce(
        (result, item) =>
          Number(item.self_reimbursement.replace(",", "")) + result,
        0
      ),
  };

  docDef = FormReceipt({ ...data });

  return (await getPdfMake()).createPdf(docDef);
};

const createPDFReceiptCoverageDocList = async (receipt: any[]) => {
  if (!receipt || !receipt?.length) {
    return null;
  }

  const pdfMake = await getPdfMake();

  const promiseArr = receipt.map((item) => {
    let docDef: any = { content: [] };

    const data = { ...item };

    docDef = FormReceiptCoverageDocument({ ...data });

    return pdfMake.createPdf(docDef);
  });

  return Promise.all(promiseArr);
};
