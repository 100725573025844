import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Checkbox,
  Input,
  Dropdown
} from 'semantic-ui-react'

const CardPACURecordReferralUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", height: "100%" ,padding:"10px"}}>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",height:"80%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(20, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              style={{ gridRow: "2/3", gridColumn: "1/6",paddingBottom:"5px"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.NotPACU || false}
                  name="NotPACU"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  ไม่ผ่าน PACU
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "1/9",paddingBottom:"5px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                O2 Transfer
              </label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "6/8",paddingBottom:"5px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                BP
              </label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "8/12",paddingBottom:"5px"}}>
              <Input
                name="BPRemark"
                onChange={props.onChangeData}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.BPRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "12/13",paddingBottom:"5px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center", marginLeft:"5px"}}>
                mmHg
              </label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "14/16",paddingBottom:"5px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Pulse
              </label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "16/20",paddingBottom:"5px"}}>
              <Input
                name="PulseRemark"
                onChange={props.onChangeData}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.PulseRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "20/22",paddingBottom:"5px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center", marginLeft:"5px"}}>
                BPM
              </label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "23/25",paddingBottom:"5px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                SpO2
              </label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "25/29",paddingBottom:"5px"}}>
              <Input
                name="SpO2Remark"
                onChange={props.onChangeData}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.SpO2Remark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "29/30",paddingBottom:"5px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center", marginLeft:"5px"}}>
                %
              </label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "31/33",paddingBottom:"5px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center", marginLeft:"5px"}}>
                Temp
              </label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "33/37",paddingBottom:"5px"}}>
              <Input
                name="TempRemark"
                onChange={props.onChangeData}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.TempRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "37/40",paddingBottom:"5px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center", marginLeft:"5px"}}>
                ํC
              </label>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "1/9",paddingBottom:"5px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Patient-Controlled Analgesia
              </label>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "1/9",paddingBottom:"5px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Spinal/Epidural Morphine
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "9/13",paddingBottom:"5px"}}>
              <Dropdown
                name="O2Transfer"
                onChange={props.onChangeData}
                options={props.masterOptions?.pacu_o2transfer || []}
                selection={true}
                style={{minWidth: "100%",minHeight:"32px" }}
                value={props.PACURecordSequence?.data?.O2Transfer || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "9/13",paddingBottom:"5px"}}>
              <Dropdown
                name="PatientControlledAnalgesia"
                onChange={props.onChangeData}
                options={props.masterOptions?.pacu_ptctrlanal || []}
                selection={true}
                style={{minWidth: "100%",minHeight:"32px" }}
                value={props.PACURecordSequence?.data?.PatientControlledAnalgesia || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "9/13",paddingBottom:"5px"}}>
              <Dropdown
                name="SpinalEpiduralMorphine"
                onChange={props.onChangeData}
                options={props.masterOptions?.pacu_spiepimorph || []}
                selection={true}
                style={{minWidth: "100%",minHeight:"32px" }}
                value={props.PACURecordSequence?.data?.SpinalEpiduralMorphine || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "14/31",paddingBottom:"5px"}}>
              <Input
                name="O2TransferRemark"
                onChange={props.onChangeData}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.O2TransferRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "14/31",paddingBottom:"5px"}}>
              <Input
                name="PatientControlledAnalgesiaRemark"
                onChange={props.onChangeData}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.PatientControlledAnalgesiaRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "14/31",paddingBottom:"5px"}}>
              <Input
                name="SpinalEpiduralMorphineRemark"
                onChange={props.onChangeData}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.SpinalEpiduralMorphineRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "1/4",paddingBottom:"5px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                ผู้รับ
              </label>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "1/5",paddingBottom:"5px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Trasfer to
              </label>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "4/13",paddingBottom:"5px"}}>
              <Input
                name="ReceiverRemark"
                onChange={props.onChangeData}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.ReceiverRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "14/17",paddingBottom:"5px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                ผู้ส่ง
              </label>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "17/26",paddingBottom:"5px"}}>
              <Input
                name="SenderRemark"
                onChange={props.onChangeData}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.SenderRemark || ""}>
              </Input>
            </div>
            <div
              style={{ display: "none", gridRow: "6/7", gridColumn: "27/31",paddingBottom:"5px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                License number
              </label>
            </div>
            <div
              style={{ display: "none", gridRow: "6/7", gridColumn: "31/39",paddingBottom:"5px"}}>
              <Input
                name="LicenseNumberRemark"
                onChange={props.onChangeData}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.LicenseNumberRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "5/15",paddingBottom:"5px"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.IsTransferToWard || false}
                  disabled={props.PACURecordSequence?.data?.IsTrsnsferToDischarge === true || props.PACURecordSequence?.data?.IsTransferToICU === true}
                  name="IsTransferToWard"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  ward
                </label>
                <Input
                  disabled={props.PACURecordSequence?.data?.IsTransferToWard === false}
                  name="TransferToWardRemark"
                  onChange={props.onChangeData}
                  style={{ display: "flex" ,height:"90%",width:"100%",marginLeft:"10px"}}
                  value={props.PACURecordSequence?.data?.TransferToWardRemark || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "16/26",paddingBottom:"5px"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.IsTransferToICU || false}
                  disabled={props.PACURecordSequence?.data?.IsTrsnsferToDischarge === true || props.PACURecordSequence?.data?.IsTransferToWard === true}
                  name="IsTransferToICU"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  ICU
                </label>
                <Input
                  disabled={props.PACURecordSequence?.data?.IsTransferToICU === false}
                  name="TransferToICURemark"
                  onChange={props.onChangeData}
                  style={{ display: "flex" ,height:"90%",width:"100%",marginLeft:"10px"}}
                  value={props.PACURecordSequence?.data?.TransferToICURemark || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "27/37", paddingBottom:"5px"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.IsTrsnsferToDischarge || false}
                  disabled={props.PACURecordSequence?.data?.IsTransferToICU === true || props.PACURecordSequence?.data?.IsTransferToWard === true}
                  name="IsTrsnsferToDischarge"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Discharge
                </label>
                <Input
                  disabled={props.PACURecordSequence?.data?.IsTrsnsferToDischarge === false}
                  name="TrsnsferToDischargeRemark"
                  onChange={props.onChangeData}
                  style={{ display: "flex" ,height:"90%",width:"100%",marginLeft:"10px"}}
                  value={props.PACURecordSequence?.data?.TrsnsferToDischargeRemark || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "1/5",paddingBottom:"5px"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Risk for fall
              </label>
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "5/8", paddingBottom:"5px"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.IsLow || false}
                  name="IsLow"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Low
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "9/13",paddingBottom:"5px" }}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.IsHigh || false}
                  name="IsHigh"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  High
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardPACURecordReferralUX

export const screenPropsDefault = {}

/* Date Time : Tue Feb 28 2023 10:37:13 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" ,padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",height:\"80%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(20, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/6\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/9\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "O2 Transfer"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Checkbox",
      "parent": 37,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.NotPACU || false"
        },
        "name": {
          "type": "value",
          "value": "NotPACU"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "ไม่ผ่าน PACU"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"6/8\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"8/12\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"12/13\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "BP"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Input",
      "parent": 41,
      "props": {
        "name": {
          "type": "value",
          "value": "BPRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.BPRemark || \"\""
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "mmHg"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\", marginLeft:\"5px\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"14/16\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"16/20\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"20/22\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "Pulse"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Input",
      "parent": 47,
      "props": {
        "name": {
          "type": "value",
          "value": "PulseRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.PulseRemark || \"\""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "BPM"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\", marginLeft:\"5px\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"23/25\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"25/29\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"29/30\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"31/33\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"33/37\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"37/40\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "label",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "SpO2"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Input",
      "parent": 53,
      "props": {
        "name": {
          "type": "value",
          "value": "SpO2Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.SpO2Remark || \"\""
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "label",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "%"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\", marginLeft:\"5px\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "label",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "Temp"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\", marginLeft:\"5px\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Input",
      "parent": 56,
      "props": {
        "name": {
          "type": "value",
          "value": "TempRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.TempRemark || \"\""
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "ํC"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\", marginLeft:\"5px\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/9\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/9\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"9/13\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"9/13\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"9/13\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"14/31\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"14/31\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"14/31\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient-Controlled Analgesia"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "Spinal/Epidural Morphine"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Dropdown",
      "parent": 67,
      "props": {
        "name": {
          "type": "value",
          "value": "PatientControlledAnalgesia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.pacu_ptctrlanal || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100%\",minHeight:\"32px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.PatientControlledAnalgesia || \"\""
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Dropdown",
      "parent": 66,
      "props": {
        "name": {
          "type": "value",
          "value": "O2Transfer"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.pacu_o2transfer || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100%\",minHeight:\"32px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.O2Transfer || \"\""
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Dropdown",
      "parent": 68,
      "props": {
        "name": {
          "type": "value",
          "value": "SpinalEpiduralMorphine"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.pacu_spiepimorph || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100%\",minHeight:\"32px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.SpinalEpiduralMorphine || \"\""
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Input",
      "parent": 69,
      "props": {
        "name": {
          "type": "value",
          "value": "O2TransferRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.O2TransferRemark || \"\""
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Input",
      "parent": 70,
      "props": {
        "name": {
          "type": "value",
          "value": "PatientControlledAnalgesiaRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.PatientControlledAnalgesiaRemark || \"\""
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Input",
      "parent": 71,
      "props": {
        "name": {
          "type": "value",
          "value": "SpinalEpiduralMorphineRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.SpinalEpiduralMorphineRemark || \"\""
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/4\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/5\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"4/13\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"14/17\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"17/26\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"none\", gridRow: \"6/7\", gridColumn: \"27/31\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"none\", gridRow: \"6/7\", gridColumn: \"31/39\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "label",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้รับ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "Input",
      "parent": 86,
      "props": {
        "name": {
          "type": "value",
          "value": "ReceiverRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.ReceiverRemark || \"\""
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "label",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "Trasfer to"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "label",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ส่ง"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "Input",
      "parent": 88,
      "props": {
        "name": {
          "type": "value",
          "value": "SenderRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.SenderRemark || \"\""
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "label",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "License number"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "Input",
      "parent": 90,
      "props": {
        "name": {
          "type": "value",
          "value": "LicenseNumberRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.LicenseNumberRemark || \"\""
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"5/15\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Checkbox",
      "parent": 101,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsTransferToWard || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsTrsnsferToDischarge === true || props.PACURecordSequence?.data?.IsTransferToICU === true"
        },
        "name": {
          "type": "value",
          "value": "IsTransferToWard"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "label",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "ward"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "Input",
      "parent": 101,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsTransferToWard === false"
        },
        "name": {
          "type": "value",
          "value": "TransferToWardRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\",marginLeft:\"10px\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.TransferToWardRemark || \"\""
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"16/26\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Checkbox",
      "parent": 106,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsTransferToICU || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsTrsnsferToDischarge === true || props.PACURecordSequence?.data?.IsTransferToWard === true"
        },
        "name": {
          "type": "value",
          "value": "IsTransferToICU"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "label",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "ICU"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "Input",
      "parent": 106,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsTransferToICU === false"
        },
        "name": {
          "type": "value",
          "value": "TransferToICURemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\",marginLeft:\"10px\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.TransferToICURemark || \"\""
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"27/37\", paddingBottom:\"5px\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 110,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Checkbox",
      "parent": 111,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsTrsnsferToDischarge || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsTransferToICU === true || props.PACURecordSequence?.data?.IsTransferToWard === true"
        },
        "name": {
          "type": "value",
          "value": "IsTrsnsferToDischarge"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "label",
      "parent": 111,
      "props": {
        "children": {
          "type": "value",
          "value": "Discharge"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Input",
      "parent": 111,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsTrsnsferToDischarge === false"
        },
        "name": {
          "type": "value",
          "value": "TrsnsferToDischargeRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\",marginLeft:\"10px\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.TrsnsferToDischargeRemark || \"\""
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"1/5\",paddingBottom:\"5px\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"5/8\", paddingBottom:\"5px\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "label",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": "Risk for fall"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 116,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "Checkbox",
      "parent": 122,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsLow || false"
        },
        "name": {
          "type": "value",
          "value": "IsLow"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "label",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": "Low"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"9/13\",paddingBottom:\"5px\" }"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 125,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "Checkbox",
      "parent": 126,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsHigh || false"
        },
        "name": {
          "type": "value",
          "value": "IsHigh"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "label",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": "High"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 128,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "CardPACURecordReferralUX",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
