import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Button,
  Modal,
  Icon,
  Image,
} from "semantic-ui-react";


const ModalUploadFile = ({
  openUploadFileModal,
  onCloseModal,
  onUploadFile
}) => {
  const fileUploaderRef = useRef();
  const [uploadImage, setUploadImage] = useState(null);
  // const defaultImage = "../../static/images/picture.gif";

  const readChooseFile = event => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = e => {
        setUploadImage(e.target.result);
        //console.log('base64 of a choosed file:', e.target.result)
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  return (
    <Modal
      open={openUploadFileModal}
      size="small"
      onClose={() => {
        setUploadImage(null);
        onCloseModal(false);
      }}
      closeOnDimmerClick={false}
      closeIcon={true}
    >
      <Modal.Header>
        <Form>
          <Form.Field inline>
            <label> Upload Image </label>
            <Button
              color="teal"
              onClick={() => {
                fileUploaderRef.current.click();
              }}
            >
              <Icon name="folder" /> Choose file
            </Button>
            <input
              type="file"
              id="file"
              ref={fileUploaderRef}
              style={{ display: "none" }}
              onChange={readChooseFile}
              accept="image/*"
            />
          </Form.Field>
        </Form>
      </Modal.Header>
      {uploadImage && (
        <Modal.Description style={{ textAlign: "center" }}>
          <img src={uploadImage} style={{ maxWidth: 640, maxHeight: 480 }} />
        </Modal.Description>
      )}

      <Modal.Actions>
        <Button
          color="green"
          onClick={() => {
            onUploadFile(uploadImage);
            onCloseModal(false);
            setUploadImage(null);
          }}
        >
          <Icon name="check" /> Upload File
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ModalUploadFile