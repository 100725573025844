import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Dropdown,
  FormGroup,
  FormField,
  Input,
  Label
} from 'semantic-ui-react'

const CardBillingDocumentUX = (props: any) => {
    return(
      <div
        style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{ display: props.viewMode ? "none" : "flex", justifyContent: "space-between",  padding: "10px 10px 0px 10px"  }}>
          {}
          <div
            style={{ fontSize: "1.2rem", fontWeight: "bold",display: "flex", alignItems: "center" }}>
            {props.title}
            <div
              style={{marginLeft: "50px", display: "none"}}>
              เลขที่ใบเสร็จ : 
            </div>
            <Label
              style={{marginLeft: "50px"}}>
              {props.receiptCode}
            </Label>
          </div>
          <div
            style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            {props.titleDescription}
          </div>
        </div>
        <div
          style={{padding: "0px 10px 10px 10px", display: props.viewMode ? "none" : "" }}>
          
          <hr>
          </hr>
        </div>
        <div
          style={{ display: props.orderBy == "Order" ? "block" : "none", padding: "0 10px" }}>
          
          <Table
            data={props.invoiceItems}
            headers={`${props.viewMode ? "" : "...,"}DIVISION,ORDER NAME,ประเภท,DATE,ราคา, ส่งเบิก, absorb,เบิกเอง, เบิกไม่ได้,ส่วนลด,ผู้ป่วยชำระ, TYPE,STATUS,ENCOUNTER`}
            keys={`${props.viewMode ? "": "chk,"}division__name,display_name,encounter__type,created,price__sum,send_claim__sum, absorb__sum,patient_claim__sum,non_claimable__sum,discount__sum,payable__sum,type__name,status__name,encounter__number`}
            minRows="10"
            showPagination={false}
            style={{ height: "50vh", ...(props.tableStyle || {})}}
            widths={props.viewMode ? "" : "60"}>
          </Table>
        </div>
        <div
          style={{ display: props.orderBy == "Mode" ? "block" : "none", padding: "0 10px" }}>
          
          <Table
            data={props.invoiceItems}
            getTdProps={props.onSelectedFaxClaim}
            headers="MODE, MODE NAME, ราคา, ส่งเบิก, absorb, เบิกเอง, เบิกไม่ได้, ส่วนลด, ผู้ป่วยชำระ"
            keys="bill_mode__code, bill_mode__name, price__sum, send_claim__sum, absorb__sum, patient_claim__sum, non_claimable__sum, discount__sum, payable__sum"
            minRows="10"
            showPagination={false}
            style={{ height: "50vh" }}
            widths="100">
          </Table>
        </div>
        <div
          style={{ display: props.orderBy == "Item" ? "block" : "none", padding: "0 10px" }}>
          
          <Table
            columns={`${props.viewMode ? "" : "chk,"}mode_code, code, product, quantity, unitprice, price, send_claim, absorb, patient_claim, non_claimable, discount, pay, unpaid, drg_discount_amount, discount_code`}
            data={props.invoiceItems}
            getTdProps={props.onSelectedFaxClaim}
            headers={`${props.viewMode ? "": "...,"}MODE, CODE, PRODUCT, จำนวน, ราคาต่อหน่วย, ราคา, ส่งเบิก, absorb, เบิกเอง, เบิกไม่ได้, ส่วนลด, ผู้ป่วยชำระ, ค้างชำระ, ส่วนลดสิทธิ, รหัสส่วนลด`}
            id="tb-billInvoiceItem"
            keys={`${props.viewMode ? "" : "chk,"}mode_code, code, product, quantity, unitprice, price, send_claim, absorb, patient_claim, non_claimable, discount, pay, unpaid, drg_discount_amount, discount_code`}
            minRows="10"
            showPagination={false}
            style={{ height: "50vh", ...(props.tableStyle || {})}}
            widths={props.viewMode ? "" : "60,100"}>
          </Table>
        </div>
        <div
          style={{ height: "8vh" , display: "none"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(2, 1fr)","gridTemplateColumns":"repeat(7, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/2", padding: "5px" }}>
              
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                ราคารวม
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "2/3", padding: "5px" }}>
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                ส่งเบิก
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "3/4", padding: "5px" }}>
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                absorb
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "4/5", padding: "5px" }}>
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                เบิกเอง
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "5/6", padding: "5px" }}>
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                เบิกไม่ได้
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "6/7", padding: "5px" }}>
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                ส่วนลด
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "7/8", padding: "5px" }}>
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                ผู้ป่วยชำระ
              </div>
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "1/2", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.price_total}
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "2/3", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.sendClaimTotal}
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "3/4", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.absorbTotal}
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "4/5", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.patientClaimTotal}
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "5/6", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.nonClaimableTotal}
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "6/7", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.discountTotal}
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "7/8", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.payableTotal}
            </div>
          </div>
        </div>
        <div
          className="ui form"
          style={{padding: "10px 15px 5px", marginBottom: "-10px"}}>
          
          <FormGroup>
            <FormField
              className="noPadding --hard"
              width={3}>
              <label>
                ราคารวม
              </label>
              <Input
                readOnly={true}
                size="small"
                value={props.price_total || ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              width={3}>
              <label>
                ส่งเบิก
              </label>
              <Input
                readOnly={true}
                size="small"
                value={props.sendClaimTotal || ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              width={3}>
              <label>
                absorb
              </label>
              <Input
                readOnly={true}
                size="small"
                value={props.absorbTotal || ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              width={3}>
              <label>
                เบิกเอง
              </label>
              <Input
                readOnly={true}
                size="small"
                value={props.patientClaimTotal || ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              width={3}>
              <label>
                 เบิกไม่ได้
              </label>
              <Input
                readOnly={true}
                value={props.nonClaimableTotal || ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              width={3}>
              <label>
                ส่วนลด
              </label>
              <Input
                readOnly={true}
                size="small"
                value={props.discountTotal || ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              width={3}>
              <label>
                ผู้ป่วยชำระ
              </label>
              <Input
                readOnly={true}
                size="small"
                value={props.payableTotal || ""}>
              </Input>
            </FormField>
            <FormField
              className={props.orderBy === "Item" ? "noPadding --hard" : "hidden noPadding --hard"}
              width={3}>
              <label>
                ค้างชำระ
              </label>
              <Input
                readOnly={true}
                size="small"
                value={props.unpaidTotal || ""}>
              </Input>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{ display: "flex", padding: "5px 10px", alignItems: "center" }}>
          
          <Button
            className={props.orderBy === "Mode" ? "active" : ""}
            color="teal"
            id="btn-billMode"
            name="Mode"
            onClick={props.onChangeOrderBy}
            size="mini">
            BY MODE
          </Button>
          <Button
            className={props.orderBy === "Order" ? "active" : ""}
            color="teal"
            id="btn-billOrder"
            name="Order"
            onClick={props.onChangeOrderBy}
            size="mini">
            BY ORDER
          </Button>
          <Button
            className={props.orderBy === "Item" ? "active" : ""}
            color="teal"
            id="btn-billItem"
            name="Item"
            onClick={props.onChangeOrderBy}
            size="mini">
            BY ITEM
          </Button>
          <div
            style={props.displaySelect}>
            {}
            <div
              style={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
              Select:
            </div>
            <Button
              id="btn-billAll"
              onClick={props.onClickAll}
              size="mini"
              style={{ marginLeft: "10px" }}>
              ALL
            </Button>
            <Button
              id="btn-billNone"
              onClick={props.onClickNone}
              size="mini">
              NONE
            </Button>
          </div>
          <div
            style={{ flex: 1 }}>
            
          </div>
          <div
            style={{ marginRight: "10px" , fontSize: "0.9rem", minWidth: "max-content", display: props.viewMode ? "none" : ""}}>
            ชื่อผู้ป่วยบนใบเสร็จ
          </div>
          <Dropdown
            options={props.patientOptions}
            selection={true}
            style={{marginRight: "10px", fontSize: "0.9rem", maxWidth: "15rem", display: props.viewMode ? "none" : ""}}
            value={props.patientValue}>
          </Dropdown>
          <Button
            color="blue"
            id="btn-bilDetailReport"
            onClick={props.onPrintReceipt}
            size="mini">
            พิมพ์ใบเสดงรายละเอียด
          </Button>
          <Button
            color="grey"
            disabled={props.viewMode || false}
            onClick={props.billPaymentTransfer}
            size="mini"
            style={{display: props.viewMode ? "none" : ""}}>
            โอนค่าใช้จ่าย
          </Button>
          <Button
            color="yellow"
            disabled={props.hidebpInvoice || false}
            id="btn-bilPaymentInvoice"
            onClick={props.billPaymentInvoice}
            size="mini"
            style={{...props.hidebpInvoice && {display: "none"}}}>
            แจ้งชำระเงิน
          </Button>
          <Button
            color="green"
            disabled={props.hidebp || false}
            id="btn-billPayment"
            onClick={props.billPayment}
            size="mini"
            style={{...props.hidebp && {display: "none"}}}>
            รับชำระเงิน
          </Button>
        </div>
      </div>
    )
}

export default CardBillingDocumentUX

export const screenPropsDefault = {"by":"order","title":"MEDICAL FEE","titleDescription":"ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"}

/* Date Time : Thu Mar 30 2023 10:25:57 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flexDirection: \"column\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.viewMode ? \"none\" : \"flex\", justifyContent: \"space-between\",  padding: \"10px 10px 0px 10px\"  }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.orderBy == \"Mode\" ? \"block\" : \"none\", padding: \"0 10px\" }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"8vh\" , display: \"none\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", padding: \"5px 10px\", alignItems: \"center\" }"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 5,
      "name": "Table",
      "parent": 2,
      "props": {
        "data": {
          "type": "code",
          "value": "props.invoiceItems"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.onSelectedFaxClaim"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": "MODE, MODE NAME, ราคา, ส่งเบิก, absorb, เบิกเอง, เบิกไม่ได้, ส่วนลด, ผู้ป่วยชำระ"
        },
        "keys": {
          "type": "value",
          "value": "bill_mode__code, bill_mode__name, price__sum, send_claim__sum, absorb__sum, patient_claim__sum, non_claimable__sum, discount__sum, payable__sum"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"50vh\" }"
        },
        "widths": {
          "type": "value",
          "value": "100"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.title"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", fontWeight: \"bold\",display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.titleDescription"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", fontWeight: \"bold\" }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "label": "divgrid",
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(2, 1fr)\",\"gridTemplateColumns\":\"repeat(7, 1fr)\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/2\", padding: \"5px\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"2/3\", padding: \"5px\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"3/4\", padding: \"5px\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"4/5\", padding: \"5px\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"5/6\", padding: \"5px\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"6/7\", padding: \"5px\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"7/8\", padding: \"5px\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.price_total"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"1/2\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.sendClaimTotal"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"2/3\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.absorbTotal"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"3/4\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientClaimTotal"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"4/5\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nonClaimableTotal"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"5/6\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.discountTotal"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"6/7\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคารวม"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่งเบิก"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "absorb"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกเอง"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกไม่ได้"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่วนลด"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ป่วยชำระ"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.payableTotal"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"7/8\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "BY MODE"
        },
        "className": {
          "type": "code",
          "value": "props.orderBy === \"Mode\" ? \"active\" : \"\""
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "id": {
          "type": "value",
          "value": "btn-billMode"
        },
        "name": {
          "type": "value",
          "value": "Mode"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeOrderBy"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "BY ORDER"
        },
        "className": {
          "type": "code",
          "value": "props.orderBy === \"Order\" ? \"active\" : \"\""
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "id": {
          "type": "value",
          "value": "btn-billOrder"
        },
        "name": {
          "type": "value",
          "value": "Order"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeOrderBy"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "BY ITEM"
        },
        "className": {
          "type": "code",
          "value": "props.orderBy === \"Item\" ? \"active\" : \"\""
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "id": {
          "type": "value",
          "value": "btn-billItem"
        },
        "name": {
          "type": "value",
          "value": "Item"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeOrderBy"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1 }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อผู้ป่วยบนใบเสร็จ"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" , fontSize: \"0.9rem\", minWidth: \"max-content\", display: props.viewMode ? \"none\" : \"\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Dropdown",
      "parent": 4,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "options": {
          "type": "code",
          "value": "props.patientOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\", fontSize: \"0.9rem\", maxWidth: \"15rem\", display: props.viewMode ? \"none\" : \"\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patientValue"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ใบเสดงรายละเอียด"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "btn-bilDetailReport"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintReceipt"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "แจ้งชำระเงิน"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "disabled": {
          "type": "code",
          "value": "props.hidebpInvoice || false"
        },
        "id": {
          "type": "value",
          "value": "btn-bilPaymentInvoice"
        },
        "onClick": {
          "type": "code",
          "value": "props.billPaymentInvoice"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{...props.hidebpInvoice && {display: \"none\"}}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "รับชำระเงิน"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.hidebp || false"
        },
        "id": {
          "type": "value",
          "value": "btn-billPayment"
        },
        "onClick": {
          "type": "code",
          "value": "props.billPayment"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{...props.hidebp && {display: \"none\"}}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.orderBy == \"Order\" ? \"block\" : \"none\", padding: \"0 10px\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 40,
      "name": "Table",
      "parent": 39,
      "props": {
        "data": {
          "type": "code",
          "value": "props.invoiceItems"
        },
        "getTdProps": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "code",
          "value": "`${props.viewMode ? \"\" : \"...,\"}DIVISION,ORDER NAME,ประเภท,DATE,ราคา, ส่งเบิก, absorb,เบิกเอง, เบิกไม่ได้,ส่วนลด,ผู้ป่วยชำระ, TYPE,STATUS,ENCOUNTER`"
        },
        "keys": {
          "type": "code",
          "value": "`${props.viewMode ? \"\": \"chk,\"}division__name,display_name,encounter__type,created,price__sum,send_claim__sum, absorb__sum,patient_claim__sum,non_claimable__sum,discount__sum,payable__sum,type__name,status__name,encounter__number`"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"50vh\", ...(props.tableStyle || {})}"
        },
        "widths": {
          "type": "code",
          "value": "props.viewMode ? \"\" : \"60\""
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.orderBy == \"Item\" ? \"block\" : \"none\", padding: \"0 10px\" }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 42,
      "name": "Table",
      "parent": 41,
      "props": {
        "columns": {
          "type": "code",
          "value": "`${props.viewMode ? \"\" : \"chk,\"}mode_code, code, product, quantity, unitprice, price, send_claim, absorb, patient_claim, non_claimable, discount, pay, unpaid, drg_discount_amount, discount_code`"
        },
        "data": {
          "type": "code",
          "value": "props.invoiceItems"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.onSelectedFaxClaim"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "code",
          "value": "`${props.viewMode ? \"\": \"...,\"}MODE, CODE, PRODUCT, จำนวน, ราคาต่อหน่วย, ราคา, ส่งเบิก, absorb, เบิกเอง, เบิกไม่ได้, ส่วนลด, ผู้ป่วยชำระ, ค้างชำระ, ส่วนลดสิทธิ, รหัสส่วนลด`"
        },
        "id": {
          "type": "value",
          "value": "tb-billInvoiceItem"
        },
        "keys": {
          "type": "code",
          "value": "`${props.viewMode ? \"\" : \"chk,\"}mode_code, code, product, quantity, unitprice, price, send_claim, absorb, patient_claim, non_claimable, discount, pay, unpaid, drg_discount_amount, discount_code`"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"50vh\", ...(props.tableStyle || {})}"
        },
        "widths": {
          "type": "code",
          "value": "props.viewMode ? \"\" : \"60,100\""
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "props.displaySelect"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "Select:"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"10px\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Button",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "ALL"
        },
        "id": {
          "type": "value",
          "value": "btn-billAll"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickAll"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"10px\" }"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Button",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "NONE"
        },
        "id": {
          "type": "value",
          "value": "btn-billNone"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickNone"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบเสร็จ : "
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"50px\", display: \"none\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 10px 10px 10px\", display: props.viewMode ? \"none\" : \"\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "hr",
      "parent": 53,
      "props": {
      },
      "seq": 54,
      "void": true
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 15px 5px\", marginBottom: \"-10px\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "FormGroup",
      "parent": 55,
      "props": {
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคารวม"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่งเบิก"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "absorb"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "label",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกเอง"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่วนลด"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "label",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ป่วยชำระ"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Input",
      "parent": 57,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "value": {
          "type": "code",
          "value": "props.price_total || \"\""
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Input",
      "parent": 58,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "value": {
          "type": "code",
          "value": "props.sendClaimTotal || \"\""
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Input",
      "parent": 59,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "value": {
          "type": "code",
          "value": "props.absorbTotal || \"\""
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Input",
      "parent": 60,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "value": {
          "type": "code",
          "value": "props.patientClaimTotal || \"\""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Input",
      "parent": 61,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "value": {
          "type": "code",
          "value": "props.discountTotal || \"\""
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Input",
      "parent": 62,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "value": {
          "type": "code",
          "value": "props.payableTotal || \"\""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "label",
      "parent": 75,
      "props": {
        "children": {
          "type": "value",
          "value": " เบิกไม่ได้"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Input",
      "parent": 75,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.nonClaimableTotal || \"\""
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Label",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.receiptCode"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"50px\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "โอนค่าใช้จ่าย"
        },
        "color": {
          "type": "value",
          "value": "grey"
        },
        "disabled": {
          "type": "code",
          "value": "props.viewMode || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.billPaymentTransfer"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{display: props.viewMode ? \"none\" : \"\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "code",
          "value": "props.orderBy === \"Item\" ? \"noPadding --hard\" : \"hidden noPadding --hard\""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "label",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้างชำระ"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Input",
      "parent": 80,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "value": {
          "type": "code",
          "value": "props.unpaidTotal || \"\""
        }
      },
      "seq": 82,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardBillingDocumentUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "by": "order",
    "title": "MEDICAL FEE",
    "titleDescription": "ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"
  },
  "width": 80
}

*********************************************************************************** */
