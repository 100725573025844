import axios from 'axios'
import { to } from '../../../../utils'
import BaseService from  '../../../services/BaseService'
import config from '../../../../../config/config'
import { Response, Error } from '../../common'
const HOST = `${config.API_HOST}`

export interface MAIMAIPatientSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    type?: any,
    hn?: any,
    pre_name?: any,
    first_name?: any,
    middle_name?: any,
    last_name?: any,
    pre_name_th?: any,
    first_name_th?: any,
    middle_name_th?: any,
    last_name_th?: any,
    pre_name_en?: any,
    first_name_en?: any,
    middle_name_en?: any,
    last_name_en?: any,
    sequence?: any,
    gender?: any,
    marriage?: any,
    birthdate?: any,
    birthdate_year_only?: any,
    deathdate?: any,
    location?: any,
    natural_mother?: any,
    student_no?: any,
    employee_no?: any,
    race?: any,
    belief?: any,
    patient_ptr?: any,
    profile_image?: any,
    blood_type?: any,
    blood_rh?: any,
    allergy?: any,
    nickname?: any,
    nickname_active?: any,
    nickname_permit_no?: any,
    nickname_reason?: any,
    nickname_warrantor?: any,
    religion_code?: any,
    nationality?: any,
    citizen_type?: any,
    citizen_passport?: any,
    citizen_no?: any,
    passport_no?: any,
    alien_no?: any,
    reimburse_foreign_code?: any,
    non_resident?: any,
    career?: any,
    position?: any,
    education?: any,
    birth_province?: any,
    birth_country?: any,
    couple?: any,
    father?: any,
    mother?: any,
    info_channel?: any,
    patient_reg_type?: any,
    home_address?: any,
    present_address?: any,
    relative_address?: any,
    foreign_address?: any,
    document_address?: any,
    ref_hn?: any,
    detail?: any,
    dob?: any,
    gender_code?: any,
    full_address?: any,
    area?: any,
    city?: any,
    province?: any,
    zip_code?: any,
    mobile_number?: any,
}


/* ['generics.RetrieveUpdateAPIView'] */
/* params:  */
/* data:  */
export const MAIUpdatePatientView : 
{
  retrieve: (arg0:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  update:   (arg0:
             { pk: any, 
                params?: any,
                data?: MAIMAIPatientSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    (arg0:
             { pk: any, 
                params?: any,
                data?: MAIMAIPatientSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/MAI/update-patient/(?P<pk>\\d+)/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  update: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${HOST}/apis/MAI/update-patient/(?P<pk>\\d+)/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${HOST}/apis/MAI/update-patient/(?P<pk>\\d+)/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface MAIMAICreatePatientDetailSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    type?: any,
    hn?: any,
    pre_name?: any,
    first_name?: any,
    middle_name?: any,
    last_name?: any,
    pre_name_th?: any,
    first_name_th?: any,
    middle_name_th?: any,
    last_name_th?: any,
    pre_name_en?: any,
    first_name_en?: any,
    middle_name_en?: any,
    last_name_en?: any,
    sequence?: any,
    gender?: any,
    marriage?: any,
    birthdate?: any,
    birthdate_year_only?: any,
    deathdate?: any,
    location?: any,
    natural_mother?: any,
    student_no?: any,
    employee_no?: any,
    race?: any,
    belief?: any,
    patient_ptr?: any,
    profile_image?: any,
    blood_type?: any,
    blood_rh?: any,
    allergy?: any,
    nickname?: any,
    nickname_active?: any,
    nickname_permit_no?: any,
    nickname_reason?: any,
    nickname_warrantor?: any,
    religion_code?: any,
    nationality?: any,
    citizen_type?: any,
    citizen_passport?: any,
    citizen_no?: any,
    passport_no?: any,
    alien_no?: any,
    reimburse_foreign_code?: any,
    non_resident?: any,
    career?: any,
    position?: any,
    education?: any,
    birth_province?: any,
    birth_country?: any,
    couple?: any,
    father?: any,
    mother?: any,
    info_channel?: any,
    patient_reg_type?: any,
    home_address?: any,
    present_address?: any,
    relative_address?: any,
    foreign_address?: any,
    document_address?: any,
    ref_hn?: any,
    detail?: any,
}


/* ['generics.CreateAPIView'] */
/* params:  */
/* data:  */
export const MAICreatePatientView : 
{
  create:   (arg0:
             { 
                params?: any,
                data?: MAIMAICreatePatientDetailSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  create: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/apis/MAI/create-patient/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface MAIMAIPatientBasicInfoSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    type?: any,
    hn?: any,
    pre_name?: any,
    first_name?: any,
    middle_name?: any,
    last_name?: any,
    pre_name_th?: any,
    first_name_th?: any,
    middle_name_th?: any,
    last_name_th?: any,
    pre_name_en?: any,
    first_name_en?: any,
    middle_name_en?: any,
    last_name_en?: any,
    sequence?: any,
    gender?: any,
    marriage?: any,
    birthdate?: any,
    birthdate_year_only?: any,
    deathdate?: any,
    location?: any,
    natural_mother?: any,
    student_no?: any,
    employee_no?: any,
    race?: any,
    belief?: any,
    patient_ptr?: any,
    profile_image?: any,
    blood_type?: any,
    blood_rh?: any,
    allergy?: any,
    nickname?: any,
    nickname_active?: any,
    nickname_permit_no?: any,
    nickname_reason?: any,
    nickname_warrantor?: any,
    religion_code?: any,
    nationality?: any,
    citizen_type?: any,
    citizen_passport?: any,
    citizen_no?: any,
    passport_no?: any,
    alien_no?: any,
    reimburse_foreign_code?: any,
    non_resident?: any,
    career?: any,
    position?: any,
    education?: any,
    birth_province?: any,
    birth_country?: any,
    couple?: any,
    father?: any,
    mother?: any,
    info_channel?: any,
    patient_reg_type?: any,
    home_address?: any,
    present_address?: any,
    relative_address?: any,
    foreign_address?: any,
    document_address?: any,
    ref_hn?: any,
    detail?: any,
    height?: any,
    weight?: any,
    height_meas_date?: any,
    weight_meas_date?: any,
    blood_pressure_meas_date?: any,
    bmi_meas_date?: any,
    blood_pressure?: any,
    bmi?: any,
}


/* ['generics.RetrieveAPIView'] */
/* params:  */
/* data:  */
export const MAIPatientBasicInfoDetail : 
{
  retrieve: (arg0:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/MAI/patient-basic-info/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface MAIMAIPatientLabResultSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    type?: any,
    hn?: any,
    pre_name?: any,
    first_name?: any,
    middle_name?: any,
    last_name?: any,
    pre_name_th?: any,
    first_name_th?: any,
    middle_name_th?: any,
    last_name_th?: any,
    pre_name_en?: any,
    first_name_en?: any,
    middle_name_en?: any,
    last_name_en?: any,
    sequence?: any,
    gender?: any,
    marriage?: any,
    birthdate?: any,
    birthdate_year_only?: any,
    deathdate?: any,
    location?: any,
    natural_mother?: any,
    student_no?: any,
    employee_no?: any,
    race?: any,
    belief?: any,
    patient_ptr?: any,
    profile_image?: any,
    blood_type?: any,
    blood_rh?: any,
    allergy?: any,
    nickname?: any,
    nickname_active?: any,
    nickname_permit_no?: any,
    nickname_reason?: any,
    nickname_warrantor?: any,
    religion_code?: any,
    nationality?: any,
    citizen_type?: any,
    citizen_passport?: any,
    citizen_no?: any,
    passport_no?: any,
    alien_no?: any,
    reimburse_foreign_code?: any,
    non_resident?: any,
    career?: any,
    position?: any,
    education?: any,
    birth_province?: any,
    birth_country?: any,
    couple?: any,
    father?: any,
    mother?: any,
    info_channel?: any,
    patient_reg_type?: any,
    home_address?: any,
    present_address?: any,
    relative_address?: any,
    foreign_address?: any,
    document_address?: any,
    ref_hn?: any,
    detail?: any,
    vital_sign_values?: any,
    interested_lab_results?: any,
    parents_lab_data?: any,
}


/* ['generics.RetrieveAPIView'] */
/* params:  */
/* data:  */
export const MAIPatientLabResultList : 
{
  retrieve: (arg0:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/MAI/patient-lab-result/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface PatientSerializerForList {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    type?: any,
    hn?: any,
    pre_name?: any,
    first_name?: any,
    middle_name?: any,
    last_name?: any,
    pre_name_th?: any,
    first_name_th?: any,
    middle_name_th?: any,
    last_name_th?: any,
    pre_name_en?: any,
    first_name_en?: any,
    middle_name_en?: any,
    last_name_en?: any,
    sequence?: any,
    gender?: any,
    marriage?: any,
    birthdate?: any,
    birthdate_year_only?: any,
    deathdate?: any,
    location?: any,
    natural_mother?: any,
    student_no?: any,
    employee_no?: any,
    race?: any,
    belief?: any,
    patient_ptr?: any,
    profile_image?: any,
    blood_type?: any,
    blood_rh?: any,
    allergy?: any,
    nickname?: any,
    nickname_active?: any,
    nickname_permit_no?: any,
    nickname_reason?: any,
    nickname_warrantor?: any,
    religion_code?: any,
    nationality?: any,
    citizen_type?: any,
    citizen_passport?: any,
    citizen_no?: any,
    passport_no?: any,
    alien_no?: any,
    reimburse_foreign_code?: any,
    non_resident?: any,
    career?: any,
    position?: any,
    education?: any,
    birth_province?: any,
    birth_country?: any,
    couple?: any,
    father?: any,
    mother?: any,
    info_channel?: any,
    patient_reg_type?: any,
    home_address?: any,
    present_address?: any,
    relative_address?: any,
    foreign_address?: any,
    document_address?: any,
    ref_hn?: any,
    detail?: any,
    old_name?: any,
    full_name?: any,
    full_name_th?: any,
    full_name_en?: any,
    name_code?: any,
    address?: any,
    tel_num?: any,
}

/*----------------------------------------------------------------------------------------------------*/

export interface PatientSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    type?: any,
    hn?: any,
    pre_name?: any,
    first_name?: any,
    middle_name?: any,
    last_name?: any,
    pre_name_th?: any,
    first_name_th?: any,
    middle_name_th?: any,
    last_name_th?: any,
    pre_name_en?: any,
    first_name_en?: any,
    middle_name_en?: any,
    last_name_en?: any,
    sequence?: any,
    gender?: any,
    marriage?: any,
    birthdate?: any,
    birthdate_year_only?: any,
    deathdate?: any,
    location?: any,
    natural_mother?: any,
    student_no?: any,
    employee_no?: any,
    race?: any,
    belief?: any,
    patient_ptr?: any,
    profile_image?: any,
    blood_type?: any,
    blood_rh?: any,
    allergy?: any,
    nickname?: any,
    nickname_active?: any,
    nickname_permit_no?: any,
    nickname_reason?: any,
    nickname_warrantor?: any,
    religion_code?: any,
    nationality?: any,
    citizen_type?: any,
    citizen_passport?: any,
    citizen_no?: any,
    passport_no?: any,
    alien_no?: any,
    reimburse_foreign_code?: any,
    non_resident?: any,
    career?: any,
    position?: any,
    education?: any,
    birth_province?: any,
    birth_country?: any,
    couple?: any,
    father?: any,
    mother?: any,
    info_channel?: any,
    patient_reg_type?: any,
    home_address?: any,
    present_address?: any,
    relative_address?: any,
    foreign_address?: any,
    document_address?: any,
    ref_hn?: any,
    detail?: any,
    patient_id?: any,
    is_pre_hn?: any,
    real_name?: any,
    real_name_th?: any,
    real_name_en?: any,
    full_hn?: any,
    old_name?: any,
    full_name?: any,
    full_name_th?: any,
    full_name_en?: any,
    full_age?: any,
    age?: any,
    encounter_id?: any,
    encounter_no?: any,
    encounter_type?: any,
    date_time?: any,
    day_admit?: any,
    total_day_admit?: any,
    gender_name?: any,
    division_name?: any,
    division_code?: any,
    name_code?: any,
    coverage?: any,
    coverage_code?: any,
    payer_name?: any,
    require_approval_code?: any,
    welfare_category?: any,
    need_new_image?: any,
    encounter_opd_no?: any,
    encounter_ipd_no?: any,
    religion?: any,
    room_no?: any,
    is_new_patient?: any,
    is_secret?: any,
    is_newborn_unconfirmed?: any,
    status_ward?: any,
    doctor_name?: any,
    primary_diagnosis?: any,
    underlying_disease?: any,
    admit_date?: any,
}


/* ['generics.RetrieveUpdateDestroyAPIView'] */
/* params:  */
/* data:  */
export const PatientDetailView : 
{
  retrieve: (arg0:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  update:   (arg0:
             { pk: any, 
                params?: any,
                data?: PatientSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    (arg0:
             { pk: any, 
                params?: any,
                data?: PatientSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
  SmartCard:    () => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/REG/patient/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  update: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${HOST}/apis/REG/patient/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${HOST}/apis/REG/patient/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
  SmartCard: async () => {
    const base = new BaseService()
    const result = await to(base.client.get(`https://scan_local.rama.mahidol.ac.th:5555/smartcard/isAvailable`,)
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface PatientQuickSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    type?: any,
    hn?: any,
    pre_name?: any,
    first_name?: any,
    middle_name?: any,
    last_name?: any,
    pre_name_th?: any,
    first_name_th?: any,
    middle_name_th?: any,
    last_name_th?: any,
    pre_name_en?: any,
    first_name_en?: any,
    middle_name_en?: any,
    last_name_en?: any,
    sequence?: any,
    gender?: any,
    marriage?: any,
    birthdate?: any,
    birthdate_year_only?: any,
    deathdate?: any,
    location?: any,
    natural_mother?: any,
    student_no?: any,
    employee_no?: any,
    race?: any,
    belief?: any,
    patient_ptr?: any,
    profile_image?: any,
    blood_type?: any,
    blood_rh?: any,
    allergy?: any,
    nickname?: any,
    nickname_active?: any,
    nickname_permit_no?: any,
    nickname_reason?: any,
    nickname_warrantor?: any,
    religion_code?: any,
    nationality?: any,
    citizen_type?: any,
    citizen_passport?: any,
    citizen_no?: any,
    passport_no?: any,
    alien_no?: any,
    reimburse_foreign_code?: any,
    non_resident?: any,
    career?: any,
    position?: any,
    education?: any,
    birth_province?: any,
    birth_country?: any,
    couple?: any,
    father?: any,
    mother?: any,
    info_channel?: any,
    patient_reg_type?: any,
    home_address?: any,
    present_address?: any,
    relative_address?: any,
    foreign_address?: any,
    document_address?: any,
    ref_hn?: any,
    detail?: any,
    old_name?: any,
    full_name?: any,
    full_name_th?: any,
    full_name_en?: any,
    full_age?: any,
    age?: any,
}


/* ['generics.ListCreateAPIView'] */
/* params:  */
/* data:  */
export const PatientQuickList : 
{
  list:     (arg0:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  create:   (arg0:
             { 
                params?: any,
                data?: PatientQuickSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/REG/quick-hn/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  create: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/apis/REG/quick-hn/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface PatientTempSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    type?: any,
    hn?: any,
    pre_name?: any,
    first_name?: any,
    middle_name?: any,
    last_name?: any,
    pre_name_th?: any,
    first_name_th?: any,
    middle_name_th?: any,
    last_name_th?: any,
    pre_name_en?: any,
    first_name_en?: any,
    middle_name_en?: any,
    last_name_en?: any,
    sequence?: any,
    gender?: any,
    marriage?: any,
    birthdate?: any,
    birthdate_year_only?: any,
    deathdate?: any,
    location?: any,
    natural_mother?: any,
    student_no?: any,
    employee_no?: any,
    race?: any,
    belief?: any,
    patient_ptr?: any,
    profile_image?: any,
    blood_type?: any,
    blood_rh?: any,
    allergy?: any,
    nickname?: any,
    nickname_active?: any,
    nickname_permit_no?: any,
    nickname_reason?: any,
    nickname_warrantor?: any,
    religion_code?: any,
    nationality?: any,
    citizen_type?: any,
    citizen_passport?: any,
    citizen_no?: any,
    passport_no?: any,
    alien_no?: any,
    reimburse_foreign_code?: any,
    non_resident?: any,
    career?: any,
    position?: any,
    education?: any,
    birth_province?: any,
    birth_country?: any,
    couple?: any,
    father?: any,
    mother?: any,
    info_channel?: any,
    patient_reg_type?: any,
    home_address?: any,
    present_address?: any,
    relative_address?: any,
    foreign_address?: any,
    document_address?: any,
    ref_hn?: any,
    detail?: any,
    name?: any,
}


/* ['generics.CreateAPIView'] */
/* params:  */
/* data:  */
export const PatientTempView : 
{
  create:   (arg0:
             { 
                params?: any,
                data?: PatientTempSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  create: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/apis/REG/temp-patient/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

