import React, { useState, useEffect } from 'react';
import { Tab } from 'semantic-ui-react';


const TabBar = (props) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const findChildren = (key) => {
    return props.children.find((items) => items.key === key)
  }

  const handleOnTabChange = (e, { activeIndex }) => {
    if (!props.isSecret) {
      setActiveIndex(activeIndex)
    }
  }

  useEffect(() => {
    if (props.isSecret) {
      setActiveIndex(-1)
    } else {
      setActiveIndex(0)
    }
  }, [props.isSecret])

  useEffect(() => {
    props.onActiveIndexChange(activeIndex)
  }, [activeIndex])

  const panes = [
    {
      menuItem: "EMR Summary",
      pane: {
        key: "EMR Summary",
        content: findChildren("emrSummary"),
        attached: false,
        style: { borderWidth: 0, margin: 0, padding: 0 },
      },
    },
    {
      menuItem: "จ่ายยา",
      pane: {
        key: "จ่ายยา",
        content: findChildren("medDetail"),
        attached: false,
        style: { borderWidth: 0, margin: 0, padding: 0 },
      },
    },
    {
      menuItem: "Medications",
      pane: {
        key: "Medications",
        content: findChildren("medications"),
        attached: false,
        style: { borderWidth: 0, margin: 0, padding: 0 },
      },
    },
    {
      menuItem: "Lab",
      pane: {
        key: "Lab",
        content: findChildren("lab"),
        attached: false,
        style: { borderWidth: 0, margin: 0, padding: 0 },
      },
    },
    {
      menuItem: "Blood",
      pane: {
        key: "Blood",
        content: findChildren("blood"),
        attached: false,
        style: { borderWidth: 0, margin: 0, padding: 0 },
      },
    },
    {
      menuItem: "Imaging Exam",
      pane: {
        key: "Imaging Exam",
        content: findChildren("imagingExam"),
        attached: false,
        style: { borderWidth: 0, margin: 0, padding: 0 },
      },
    },
    {
      menuItem: "Medical Note",
      pane: {
        key: "Medical Note",
        content: findChildren("medicalNote"),
        attached: false,
        style: { borderWidth: 0, margin: 0, padding: 0 },
      },
    },
    {
      menuItem: "Nurse's record",
      pane: {
        key: "Nurse's record",
        content: findChildren("nurseRecord"),
        attached: false,
        style: { borderWidth: 0, margin: 0, padding: 0 },
      },
    },
    {
      menuItem: "Scanned Document",
      pane: {
        key: "Scanned Document",
        content: findChildren("scannedDocument"),
        attached: false,
        style: { borderWidth: 0, margin: 0, padding: 0 },
      },
    },
    {
      menuItem: "Vital Sign",
      pane: {
        key: "Vital Sign",
        content: findChildren("vitalSign"),
        attached: false,
        style: { borderWidth: 0, margin: 0, padding: 0 },
      },
    },
    {
      menuItem: "ปกปิดประวัติผู้ป่วย",
      pane: {
        key: "secretEncounter",
        content: findChildren("secretEncounter"),
        attached: false,
        style: { borderWidth: 0, margin: 0, padding: 0 },
      },
    },
    {
      menuItem: "ประวัติการผ่าตัด",
      pane: {
        key: "orHistory",
        content: findChildren("orHistory"),
        attached: false,
        style: { borderWidth: 0, margin: 0, padding: 0 },
      },
    },
  ];

  return (
    <Tab
      activeIndex={activeIndex}
      onTabChange={handleOnTabChange}
      disabled={true}
      style={{
        width: '100%'
      }}
      menu={{ attached: false, className: 'blueHover' }}
      panes={panes}
      renderActiveOnly={false}
    />
  )
}

TabBar.defaultProps = {
  children: [],
  isSecret: false,
  onActiveIndexChange: () => { },
};


export default TabBar;
