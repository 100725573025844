import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { Checkbox, Dropdown, Input } from "semantic-ui-react";

const Anes_PeriDataSheet = (props: any) => {
  const isMounted = React.useRef(true);
  const intl = useIntl();

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div style={{ height: "80px" }}>
      <div style={{ height: "80px" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(2, 1fr)",
            gridTemplateColumns: "repeat(10, 1fr)",
          }}
        >
          <div
            style={{
              gridRow: "1/2",
              gridColumn: "1/11",
              display: "flex",
              border: "solid 1px rgb(200, 200, 200, 0.5)",
              backgroundColor: "rgb(161, 221, 230, 0.49)",
              fontWeight: "bold",
              fontSize: "17px",
            }}
          >
            <label
              style={{
                textAlign: "center",
                marginLeft: "20px",
                paddingTop: "10px",
              }}
            >
              Preoperative conditions โปรดระบุ Condition ให้ตรงตามระบบ
            </label>
          </div>
          <div
            style={{
              gridRow: "2/3",
              gridColumn: "1/11",
              border: "solid 1px rgb(200, 200, 200, 0.5)",
            }}
          >
            <Checkbox
              checked={
                props.PeriDataSheetSequence?.data
                  ?.PreoperativeConditionsIsNone || false
              }
              label="None"
              name="PreoperativeConditionsIsNone"
              onChange={props.onChangeData}
              style={{ marginLeft: "20px", paddingTop: "10px" }}
            ></Checkbox>
          </div>
        </div>
      </div>
      <div style={{ height: "550px" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(13, 1fr)",
            gridTemplateColumns: "repeat(48, 1fr)",
          }}
        >
          <div
            style={{
              gridRow: "1/14",
              gridColumn: "1/13",
              border: "solid 1px rgb(200, 200, 200, 0.5)",
              margin: "1px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(13, 1fr)",
                gridTemplateColumns: "repeat(8, 1fr)",
              }}
            >
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "1/9",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  display: "flex",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  backgroundColor: "rgb(161, 221, 230, 0.49)",
                  fontWeight: "bold",
                }}
              >
                <label
                  style={{
                    textAlign: "center",
                    marginLeft: "20px",
                    paddingTop: "10px",
                  }}
                >
                  Respiratory
                </label>
              </div>
              <div style={{ gridRow: "2/3", gridColumn: "1/5" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Smoke || false}
                  label="101 Smoke"
                  name="Smoke"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "3/4", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is102 || false}
                  label="102"
                  name="Is102"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "3/4", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is102Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_102 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is102Remark || ""}
                ></Dropdown>
              </div>
              <div style={{ gridRow: "4/5", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is103 || false}
                  label="103"
                  name="Is103"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "4/5", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is103Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_103 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is103Remark || ""}
                ></Dropdown>
              </div>
              <div style={{ gridRow: "5/6", gridColumn: "1/5" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is104URI || false}
                  label="104 URI"
                  name="Is104URI"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "6/7", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data
                      ?.Is105LRIpneumoniaTbcOldTB || false
                  }
                  label="105 LRI (pneumonia, Tbc, Old TB)"
                  name="Is105LRIpneumoniaTbcOldTB"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "7/8", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is106 || false}
                  label="106"
                  name="Is106"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "7/8", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is106Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_106 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is106Remark || ""}
                ></Dropdown>
              </div>
              <div style={{ gridRow: "8/9", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data
                      ?.Is107PredictedDifficultAirway || false
                  }
                  label="107 Predicted difficult airway"
                  name="Is107PredictedDifficultAirway"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "9/10", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is108 || false}
                  label="108"
                  name="Is108"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "9/10", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is108Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_108 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is108Remark || ""}
                ></Dropdown>
              </div>
              <div style={{ gridRow: "10/11", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is109 || false}
                  label="109"
                  name="Is109"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "10/11", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is109Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_109 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is109Remark || ""}
                ></Dropdown>
              </div>
              <div style={{ gridRow: "11/12", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.AllergicRhinitis || false
                  }
                  label="110 Allergic rhinitis"
                  name="AllergicRhinitis"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "12/13", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Hypoxaemia || false
                  }
                  label="111 Hypoxemia"
                  name="Hypoxaemia"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "13/14", gridColumn: "1/3" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.IsRespiratoryOther ||
                    false
                  }
                  label="Other"
                  name="IsRespiratoryOther"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "13/14", gridColumn: "3/10" }}>
                <Input
                  name="IsRespiratoryOtherRemark"
                  onChange={props.onChangeData}
                  style={{ width: "270px" }}
                  value={
                    props.PeriDataSheetSequence?.data
                      ?.IsRespiratoryOtherRemark || ""
                  }
                ></Input>
              </div>
            </div>
          </div>
          <div
            style={{
              gridRow: "1/14",
              gridColumn: "13/25",
              border: "solid 1px rgb(200, 200, 200, 0.5)",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(13, 1fr)",
                gridTemplateColumns: "repeat(8, 1fr)",
                margin: "1px",
              }}
            >
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "1/9",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  display: "flex",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  backgroundColor: "rgb(161, 221, 230, 0.49)",
                  fontWeight: "bold",
                }}
              >
                <label
                  style={{
                    textAlign: "center",
                    marginLeft: "20px",
                    paddingTop: "10px",
                  }}
                >
                  Cardiovascular
                </label>
              </div>
              <div style={{ gridRow: "2/3", gridColumn: "1/5" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Hypertension || false
                  }
                  label="201 Hypertension"
                  name="Hypertension"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "3/4", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.PulmonaryHypertension ||
                    false
                  }
                  label="202 Pulmonary Hypertension"
                  name="PulmonaryHypertension"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "4/5", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is203 || false}
                  label="203"
                  name="Is203"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "4/5", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is203Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_203 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is203Remark || ""}
                ></Dropdown>
              </div>
              <div style={{ gridRow: "5/6", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is204 || false}
                  label="204"
                  name="Is204"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "6/7", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is205 || false}
                  label="205"
                  name="Is205"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "7/8", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.CongenitalHeartDisease ||
                    false
                  }
                  label="206 Congenital heart disease"
                  name="CongenitalHeartDisease"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "8/9", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.ValvularHeartDisease ||
                    false
                  }
                  label="207 Valvular heart disease"
                  name="ValvularHeartDisease"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "9/10", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is208 || false}
                  label="208"
                  name="Is208"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "9/10", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is208Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_208 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is208Remark || ""}
                ></Dropdown>
              </div>
              <div style={{ gridRow: "10/11", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is209 || false}
                  label="209"
                  name="Is209"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "10/11", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is209Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_209 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is209Remark || ""}
                ></Dropdown>
              </div>
              <div style={{ gridRow: "11/12", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.PericardialDisease ||
                    false
                  }
                  label="210 Pericardial disease"
                  name="PericardialDisease"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "12/13", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.PulmonaryEmbolism ||
                    false
                  }
                  label="211 Pulmonary embolism"
                  name="PulmonaryEmbolism"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "13/14", gridColumn: "1/3" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.IsCardiovascularOther ||
                    false
                  }
                  label="Other"
                  name="IsCardiovascularOther"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "13/14", gridColumn: "3/10" }}>
                <Input
                  name="IsCardiovascularOtherRemark"
                  onChange={props.onChangeData}
                  style={{ width: "270px" }}
                  value={
                    props.PeriDataSheetSequence?.data
                      ?.IsCardiovascularOtherRemark || ""
                  }
                ></Input>
              </div>
              <div style={{ gridRow: "5/6", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is204Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_204 || []}
                  selection={true}
                  value={
                    props.PeriDataSheetSequence?.data?.Is204Remark || false
                  }
                ></Dropdown>
              </div>
              <div style={{ gridRow: "6/7", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is205Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_205 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is205Remark || ""}
                ></Dropdown>
              </div>
            </div>
          </div>
          <div
            style={{
              gridRow: "1/14",
              gridColumn: "25/37",
              border: "solid 1px rgb(200, 200, 200, 0.5)",
              margin: "1px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(13, 1fr)",
                gridTemplateColumns: "repeat(8, 1fr)",
                margin: "1px",
              }}
            >
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "1/9",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  display: "flex",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  backgroundColor: "rgb(161, 221, 230, 0.49)",
                  fontWeight: "bold",
                }}
              >
                <label
                  style={{
                    textAlign: "center",
                    marginLeft: "20px",
                    paddingTop: "10px",
                  }}
                >
                  Neuro
                </label>
              </div>
              <div
                style={{ gridRow: "2/3", gridColumn: "1/4", marginTop: "2px" }}
              >
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is301 || false}
                  label="301"
                  name="Is301"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "3/4", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is302 || false}
                  label="302"
                  name="Is302"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "4/5", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.SpinalCordlesion || false
                  }
                  label="303 Spinal cord lesion"
                  name="SpinalCordlesion"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "5/6", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is304 || false}
                  label="304"
                  name="Is304"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "6/7", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is305 || false}
                  label="305"
                  name="Is305"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "7/8", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is306 || false}
                  label="306"
                  name="Is306"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "8/9", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Convulsion || false
                  }
                  label="307 Convulsion"
                  name="Convulsion"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "9/10", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is308 || false}
                  label="308"
                  name="Is308"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "9/10", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is308Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_308 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is308Remark || ""}
                ></Dropdown>
              </div>
              <div style={{ gridRow: "10/11", gridColumn: "1/3" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.IsNeuroOther || false
                  }
                  label="Other"
                  name="IsNeuroOther"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "10/11", gridColumn: "3/10" }}>
                <Input
                  name="IsNeuroOtherRemark"
                  onChange={props.onChangeData}
                  style={{ width: "270px" }}
                  value={
                    props.PeriDataSheetSequence?.data?.IsNeuroOtherRemark || ""
                  }
                ></Input>
              </div>
              <div style={{ gridRow: "5/6", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is304Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_304 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is304Remark || ""}
                ></Dropdown>
              </div>
              <div style={{ gridRow: "6/7", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is305Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_305 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is305Remark || ""}
                ></Dropdown>
              </div>
              <div
                style={{ gridRow: "2/3", gridColumn: "4/9", marginTop: "2px" }}
              >
                <Dropdown
                  name="Is301Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_301 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is301Remark || ""}
                ></Dropdown>
              </div>
              <div style={{ gridRow: "7/8", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is306Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_306 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is306Remark || ""}
                ></Dropdown>
              </div>
              <div
                style={{ gridRow: "3/4", gridColumn: "4/9", marginTop: "2px" }}
              >
                <Dropdown
                  name="Is302Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_302 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is302Remark || ""}
                ></Dropdown>
              </div>
            </div>
          </div>
          <div
            style={{
              gridRow: "1/14",
              gridColumn: "37/49",
              border: "solid 1px rgb(200, 200, 200, 0.5)",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(13, 1fr)",
                gridTemplateColumns: "repeat(8, 1fr)",
                margin: "1px",
              }}
            >
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "1/9",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  display: "flex",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  backgroundColor: "rgb(161, 221, 230, 0.49)",
                  fontWeight: "bold",
                }}
              >
                <label
                  style={{
                    textAlign: "center",
                    marginLeft: "20px",
                    paddingTop: "10px",
                  }}
                >
                  Endocrine/Metabolic
                </label>
              </div>
              <div
                style={{ gridRow: "2/3", gridColumn: "1/4", marginTop: "2px" }}
              >
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is401 || false}
                  label="401"
                  name="Is401"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "3/4", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is402 || false}
                  label="402"
                  name="Is402"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "4/5", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is403 || false}
                  label="403 "
                  name="Is403"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "5/6", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.OnSteriod || false
                  }
                  label="404 On steriod"
                  name="OnSteriod"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "6/7", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is405 || false}
                  label="405"
                  name="Is405"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "7/8", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is406 || false}
                  label="406"
                  name="Is406"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "8/9", gridColumn: "1/9" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Obesity || false}
                  label="407 Obesity (BMI >= 30)"
                  name="Obesity"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "9/10", gridColumn: "1/9" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.DLP || false}
                  label="408 DLP"
                  name="DLP"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "10/11", gridColumn: "1/3" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data
                      ?.IsEndocrineMetabolicOther || false
                  }
                  label="Other"
                  name="IsEndocrineMetabolicOther"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "10/11", gridColumn: "3/10" }}>
                <Input
                  name="IsEndocrineMetabolicOtherRemark"
                  onChange={props.onChangeData}
                  style={{ width: "270px" }}
                  value={
                    props.PeriDataSheetSequence?.data
                      ?.IsEndocrineMetabolicOtherRemark || ""
                  }
                ></Input>
              </div>
              <div style={{ gridRow: "6/7", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is405Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_405 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is405Remark || ""}
                ></Dropdown>
              </div>
              <div
                style={{ gridRow: "2/3", gridColumn: "4/9", marginTop: "2px" }}
              >
                <Dropdown
                  name="Is401Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_401 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is401Remark || ""}
                ></Dropdown>
              </div>
              <div style={{ gridRow: "7/8", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is406Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_406 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is406Remark || ""}
                ></Dropdown>
              </div>
              <div
                style={{ gridRow: "4/5", gridColumn: "4/9", marginTop: "2px" }}
              >
                <Dropdown
                  name="Is403Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_403 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is403Remark || ""}
                ></Dropdown>
              </div>
              <div
                style={{ gridRow: "3/4", gridColumn: "4/9", marginTop: "2px" }}
              >
                <Dropdown
                  name="Is402Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_402 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is402Remark || ""}
                ></Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "550px" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(13, 1fr)",
            gridTemplateColumns: "repeat(48, 1fr)",
          }}
        >
          <div
            style={{
              gridRow: "1/14",
              gridColumn: "1/13",
              border: "solid 1px rgb(200, 200, 200, 0.5)",
              margin: "1px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(13, 1fr)",
                gridTemplateColumns: "repeat(8, 1fr)",
              }}
            >
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "1/9",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  display: "flex",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  backgroundColor: "rgb(161, 221, 230, 0.49)",
                  fontWeight: "bold",
                }}
              >
                <label
                  style={{
                    textAlign: "center",
                    marginLeft: "20px",
                    paddingTop: "10px",
                  }}
                >
                  Hematologic/Infection
                </label>
              </div>
              <div style={{ gridRow: "2/3", gridColumn: "1/5" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Anaemia || false}
                  label="501 Anemia"
                  name="Anaemia"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "3/4", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Plateletlessthan100k ||
                    false
                  }
                  label="502 Platelet < 100,000/cum"
                  name="Plateletlessthan100k"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "4/5", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Coagulopathy || false
                  }
                  label="503 Coagulopathy"
                  name="Coagulopathy"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "5/6", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is504 || false}
                  label="504"
                  name="Is504"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "5/6", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is504Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_504 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is504Remark || ""}
                ></Dropdown>
              </div>
              <div style={{ gridRow: "6/7", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is505 || false}
                  label="505"
                  name="Is505"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "6/7", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is505Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_505 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is505Remark || ""}
                ></Dropdown>
              </div>
              <div style={{ gridRow: "7/8", gridColumn: "1/9" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Fever || false}
                  label="506 Fever"
                  name="Fever"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "8/9", gridColumn: "1/9" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.HIV || false}
                  label="507 HIV+"
                  name="HIV"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "9/10", gridColumn: "1/3" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data
                      ?.IsHaematologicInfectionOther || false
                  }
                  label="Other"
                  name="IsHaematologicInfectionOther"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "9/10", gridColumn: "3/10" }}>
                <Input
                  name="IsHaematologicInfectionOtherRemark"
                  onChange={props.onChangeData}
                  style={{ width: "270px" }}
                  value={
                    props.PeriDataSheetSequence?.data
                      ?.IsHaematologicInfectionOtherRemark || ""
                  }
                ></Input>
              </div>
            </div>
          </div>
          <div
            style={{
              gridRow: "1/14",
              gridColumn: "13/25",
              border: "solid 1px rgb(200, 200, 200, 0.5)",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(13, 1fr)",
                gridTemplateColumns: "repeat(8, 1fr)",
                margin: "1px",
              }}
            >
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "1/9",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  display: "flex",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  backgroundColor: "rgb(161, 221, 230, 0.49)",
                  fontWeight: "bold",
                }}
              >
                <label
                  style={{
                    textAlign: "center",
                    marginLeft: "20px",
                    paddingTop: "10px",
                  }}
                >
                  Liver disease
                </label>
              </div>
              <div style={{ gridRow: "2/3", gridColumn: "1/5" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.AbnormalLFT || false
                  }
                  label="601 Abnormal LFT"
                  name="AbnormalLFT"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "3/4", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Cirrhosis || false
                  }
                  label="602 Cirrhosis"
                  name="Cirrhosis"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "4/5", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Liverfailure || false
                  }
                  label="603 Liver failure"
                  name="Liverfailure"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "5/6", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Hepatitis || false
                  }
                  label="604 Hepatitis"
                  name="Hepatitis"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "6/7", gridColumn: "1/3" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.IsLiverDiseaseOther ||
                    false
                  }
                  label="Other"
                  name="IsLiverDiseaseOther"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "6/7", gridColumn: "3/10" }}>
                <Input
                  name="IsLiverDiseaseOtherRemark"
                  onChange={props.onChangeData}
                  style={{ width: "270px" }}
                  value={
                    props.PeriDataSheetSequence?.data
                      ?.IsLiverDiseaseOtherRemark || ""
                  }
                ></Input>
              </div>
            </div>
          </div>
          <div
            style={{
              gridRow: "1/14",
              gridColumn: "25/37",
              border: "solid 1px rgb(200, 200, 200, 0.5)",
              margin: "1px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(13, 1fr)",
                gridTemplateColumns: "repeat(8, 1fr)",
                margin: "1px",
              }}
            >
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "1/9",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  display: "flex",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  backgroundColor: "rgb(161, 221, 230, 0.49)",
                  fontWeight: "bold",
                }}
              >
                <label
                  style={{
                    textAlign: "center",
                    marginLeft: "20px",
                    paddingTop: "10px",
                  }}
                >
                  Renal disease
                </label>
              </div>
              <div
                style={{ gridRow: "2/3", gridColumn: "1/9", marginTop: "2px" }}
              >
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.RenalImpairment || false
                  }
                  label="701 Renal Impairment"
                  name="RenalImpairment"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "3/4", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is702 || false}
                  label="702"
                  name="Is702"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "4/5", gridColumn: "1/3" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.IsRenalDiseaseOther ||
                    false
                  }
                  label="Other"
                  name="IsRenalDiseaseOther"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "4/5", gridColumn: "3/10" }}>
                <Input
                  name="IsRenalDiseaseOtherRemark"
                  onChange={props.onChangeData}
                  style={{ width: "270px" }}
                  value={
                    props.PeriDataSheetSequence?.data
                      ?.IsRenalDiseaseOtherRemark || ""
                  }
                ></Input>
              </div>
              <div
                style={{ gridRow: "3/4", gridColumn: "4/9", marginTop: "2px" }}
              >
                <Dropdown
                  name="Is702Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_702 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is702Remark || ""}
                ></Dropdown>
              </div>
            </div>
          </div>
          <div
            style={{
              gridRow: "1/14",
              gridColumn: "37/49",
              border: "solid 1px rgb(200, 200, 200, 0.5)",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(13, 1fr)",
                gridTemplateColumns: "repeat(8, 1fr)",
                margin: "1px",
              }}
            >
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "1/9",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  display: "flex",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  backgroundColor: "rgb(161, 221, 230, 0.49)",
                  fontWeight: "bold",
                }}
              >
                <label
                  style={{
                    textAlign: "center",
                    marginLeft: "20px",
                    paddingTop: "10px",
                  }}
                >
                  Miscellaneous
                </label>
              </div>
              <div
                style={{ gridRow: "2/3", gridColumn: "1/9", marginTop: "2px" }}
              >
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Sepsis || false}
                  label="801 Sepsis"
                  name="Sepsis"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "3/4", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.PostTransplantationn ||
                    false
                  }
                  label="802 Post transplantation"
                  name="PostTransplantationn"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "4/5", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.AutoimmuneDisease ||
                    false
                  }
                  label="803 Autoimmune disease"
                  name="AutoimmuneDisease"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "5/6", gridColumn: "1/9" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Extreme || false}
                  label="804 Extreme"
                  name="Extreme"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "6/7", gridColumn: "1/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.postCardiacArrest ||
                    false
                  }
                  label="805 Post cardiac arrest"
                  name="postCardiacArrest"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "7/8", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Addict || false}
                  label="806 Addict"
                  name="Addict"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "8/9", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is807 || false}
                  label="807"
                  name="Is807"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "9/10", gridColumn: "1/9" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Cancer || false}
                  label="808 Cancer"
                  name="Cancer"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "10/11", gridColumn: "1/3" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.IsMiscellaneousOther ||
                    false
                  }
                  label="Other"
                  name="IsMiscellaneousOther"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "10/11", gridColumn: "3/10" }}>
                <Input
                  name="IsMiscellaneousOtherRemark"
                  onChange={props.onChangeData}
                  style={{ width: "270px" }}
                  value={
                    props.PeriDataSheetSequence?.data
                      ?.IsMiscellaneousOtherRemark || ""
                  }
                ></Input>
              </div>
              <div style={{ gridRow: "7/8", gridColumn: "4/9" }}>
                <Input
                  name="AddictRemark"
                  onChange={props.onChangeData}
                  style={{ width: "270px" }}
                  value={props.PeriDataSheetSequence?.data?.AddictRemark || ""}
                ></Input>
              </div>
              <div style={{ gridRow: "8/9", gridColumn: "4/9" }}>
                <Dropdown
                  name="Is807Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_807 || []}
                  selection={true}
                  value={props.PeriDataSheetSequence?.data?.Is807Remark || ""}
                ></Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "550px", marginTop: "20px" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(13, 1fr)",
            gridTemplateColumns: "repeat(48, 1fr)",
          }}
        >
          <div
            style={{
              gridRow: "1/14",
              gridColumn: "1/16",
              border: "solid 1px rgb(200, 200, 200, 0.5)",
              margin: "1px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(13, 1fr)",
                gridTemplateColumns: "repeat(8, 1fr)",
              }}
            >
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "1/9",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  display: "flex",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  backgroundColor: "rgb(161, 221, 230, 0.49)",
                  fontWeight: "bold",
                }}
              >
                <label
                  style={{
                    textAlign: "center",
                    marginLeft: "20px",
                    paddingTop: "10px",
                  }}
                >
                  Monitors
                </label>
              </div>
              <div style={{ gridRow: "2/3", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.NIBP || false}
                  label="1. NIBP"
                  name="NIBP"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "3/4", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.SpO2 || false}
                  label="2. SpO2"
                  name="SpO2"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "4/5", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.EKG || false}
                  label="3. EKG"
                  name="EKG"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "5/6", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.IBP || false}
                  label="4. IBP"
                  name="IBP"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "6/7", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.CVP || false}
                  label="5. CVP"
                  name="CVP"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "7/8", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.EtCO2 || false}
                  label="6. EtCO2"
                  name="EtCO2"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "8/9", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Urine || false}
                  label="7. Urine"
                  name="Urine"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "9/10", gridColumn: "1/4" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Temp || false}
                  label="8. Temp"
                  name="Temp"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "10/11", gridColumn: "1/3" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is9 || false}
                  label="9."
                  name="Is9"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "10/11", gridColumn: "3/9" }}>
                <Dropdown
                  name="Is9Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_9 || []}
                  selection={true}
                  style={{ width: "95%" }}
                  value={props.PeriDataSheetSequence?.data?.Is9Remark || ""}
                ></Dropdown>
              </div>
              <div style={{ gridRow: "11/12", gridColumn: "1/3" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is10 || false}
                  label="10."
                  name="Is10"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "12/13", gridColumn: "1/9" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.NMB || false}
                  label="11. NMB"
                  name="NMB"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "13/14", gridColumn: "1/3" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is12 || false}
                  label="12."
                  name="Is12"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "13/14", gridColumn: "3/10" }}>
                <Dropdown
                  name="Is12Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_12 || []}
                  selection={true}
                  style={{ width: "95%" }}
                  value={props.PeriDataSheetSequence?.data?.Is12Remark || ""}
                ></Dropdown>
              </div>
              <div style={{ gridRow: "2/3", gridColumn: "4/9" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.TEE || false}
                  label="13. TEE"
                  name="TEE"
                  onChange={props.onChangeData}
                  style={{ paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "3/4", gridColumn: "4/5" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Is14 || false}
                  label="14."
                  name="Is14"
                  onChange={props.onChangeData}
                  style={{ paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "3/4", gridColumn: "5/9" }}>
                <Dropdown
                  name="Is14Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_14 || []}
                  selection={true}
                  style={{ width: "95%" }}
                  value={props.PeriDataSheetSequence?.data?.Is14Remark || ""}
                ></Dropdown>
              </div>
              <div style={{ gridRow: "11/12", gridColumn: "3/9" }}>
                <Dropdown
                  name="Is10Remark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.peri_10 || []}
                  selection={true}
                  style={{ width: "95%" }}
                  value={props.PeriDataSheetSequence?.data?.Is10Remark || ""}
                ></Dropdown>
              </div>
            </div>
          </div>
          <div style={{ gridRow: "1/3", gridColumn: "16/28", padding: "1px" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(2, 1fr)",
                gridTemplateColumns: "repeat(1, 1fr)",
              }}
            >
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "1/2",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  display: "flex",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  backgroundColor: "rgb(161, 221, 230, 0.49)",
                  fontWeight: "bold",
                }}
              >
                <label
                  style={{
                    textAlign: "center",
                    marginLeft: "20px",
                    paddingTop: "10px",
                  }}
                >
                  Special Tech.
                </label>
              </div>
              <div
                style={{
                  gridRow: "2/3",
                  gridColumn: "1/2",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                }}
              >
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.SpecialTechNone || false
                  }
                  label="None"
                  name="SpecialTechNone"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
            </div>
          </div>
          <div
            style={{
              gridRow: "3/14",
              gridColumn: "16/28",
              border: "solid 1px rgb(200, 200, 200, 0.5)",
              padding: "1px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(11, 1fr)",
                gridTemplateColumns: "repeat(10, 1fr)",
              }}
            >
              <div style={{ gridRow: "1/2", gridColumn: "1/11" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.AwakeIntubation || false
                  }
                  label="1. Awake intubation"
                  name="AwakeIntubation"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "2/3", gridColumn: "1/11" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.FiberopticIntubation ||
                    false
                  }
                  label="2. Fiberoptic intubation"
                  name="FiberopticIntubation"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "3/4", gridColumn: "1/11" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Hypotension || false
                  }
                  label="3. Hypotension"
                  name="Hypotension"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "4/5", gridColumn: "1/11" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Hypothermia || false
                  }
                  label="4. Hypothermia"
                  name="Hypothermia"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "5/6", gridColumn: "1/11" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.CPB || false}
                  label="5. CPB"
                  name="CPB"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "6/7", gridColumn: "1/11" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data
                      ?.AutologousBloodTransfusion || false
                  }
                  label="6. Autologous blood transfusion"
                  name="AutologousBloodTransfusion"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "7/8", gridColumn: "1/11" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.AwakeCraniotomy || false
                  }
                  label="7. Awake craniotomy"
                  name="AwakeCraniotomy"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "8/9", gridColumn: "1/11" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.DeepCirculatoryArrest ||
                    false
                  }
                  label="8. Deep circulatory arrest"
                  name="DeepCirculatoryArrest"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "9/10", gridColumn: "1/11" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.BarbiturateComa || false
                  }
                  label="9. Barbiturate coma"
                  name="BarbiturateComa"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "10/11", gridColumn: "1/4" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.OtherSpecialTec || false
                  }
                  label="10. Other"
                  name="OtherSpecialTec"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "10/11", gridColumn: "4/11" }}>
                <Input
                  name="IsOtherSpecialTecRemark"
                  onChange={props.onChangeData}
                  style={{ width: "95%" }}
                  value={
                    props.PeriDataSheetSequence?.data
                      ?.IsOtherSpecialTecRemark || ""
                  }
                ></Input>
              </div>
            </div>
          </div>
          <div
            style={{
              gridRow: "1/14",
              gridColumn: "28/49",
              border: "solid 1px rgb(200, 200, 200, 0.5)",
              margin: "1px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(13, 1fr)",
                gridTemplateColumns: "repeat(12, 1fr)",
              }}
            >
              <div
                style={{
                  gridRow: "1/2",
                  gridColumn: "1/13",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  display: "flex",
                  border: "solid 1px rgb(200, 200, 200, 0.5)",
                  backgroundColor: "rgb(161, 221, 230, 0.49)",
                  fontWeight: "bold",
                }}
              >
                <label
                  style={{
                    textAlign: "center",
                    marginLeft: "20px",
                    paddingTop: "10px",
                  }}
                >
                  Anesthetic Agents
                </label>
              </div>
              <div style={{ gridRow: "2/3", gridColumn: "1/6" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Thiopental || false
                  }
                  label="1. Thiopental"
                  name="Thiopental"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "3/4", gridColumn: "1/6" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Propofol || false}
                  label="2. Propofol"
                  name="Propofol"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "4/5", gridColumn: "1/6" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Ketamine || false}
                  label="3. Ketamine"
                  name="Ketamine"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "5/6", gridColumn: "1/6" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Etomidate || false
                  }
                  label="4. Etomidate"
                  name="Etomidate"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "6/7", gridColumn: "1/6" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Dexmedetomidine || false
                  }
                  label="5. Dexmedetomidine"
                  name="Dexmedetomidine"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "7/8", gridColumn: "1/6" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Midazolam || false
                  }
                  label="6. Midazolam"
                  name="Midazolam"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "8/9", gridColumn: "1/6" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Isoflurane || false
                  }
                  label="7. Isoflurane"
                  name="Isoflurane"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "9/10", gridColumn: "1/6" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Sevoflurane || false
                  }
                  label="8. Sevoflurane"
                  name="Sevoflurane"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "10/11", gridColumn: "1/6" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Desflurane || false
                  }
                  label="9. Desflurane"
                  name="Desflurane"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "11/12", gridColumn: "1/6" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Succinylcholine || false
                  }
                  label="10. Succinylcholine"
                  name="Succinylcholine"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "12/13", gridColumn: "1/6" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Pancuronium || false
                  }
                  label="11. Pancuronium"
                  name="Pancuronium"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "13/14", gridColumn: "1/6" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.CisAtracurium || false
                  }
                  label="12. Cis-Atracurium"
                  name="CisAtracurium"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "2/3", gridColumn: "6/13" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Atracurium || false
                  }
                  label="13. Atracurium"
                  name="Atracurium"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "3/4", gridColumn: "6/13" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Vecuronium || false
                  }
                  label="14. Vecuronium"
                  name="Vecuronium"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "4/5", gridColumn: "6/13" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Rocuronium || false
                  }
                  label="15. Rocuronium"
                  name="Rocuronium"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "5/6", gridColumn: "6/13" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Morphine || false}
                  label="16. Morphine"
                  name="Morphine"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "6/7", gridColumn: "6/13" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Fentany || false}
                  label="17. Fentanyl"
                  name="Fentany"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "7/8", gridColumn: "6/13" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.Pethidine || false
                  }
                  label="18. Pethidine"
                  name="Pethidine"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "8/9", gridColumn: "6/13" }}>
                <Checkbox
                  checked={props.PeriDataSheetSequence?.data?.Tramadol || false}
                  label="19. Tramadol"
                  name="Tramadol"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "9/10", gridColumn: "6/13" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.LidocaineHCL || false
                  }
                  label="20. Lidocaine HCL"
                  name="LidocaineHCL"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "10/11", gridColumn: "6/13" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.BupivacaineHCL || false
                  }
                  label="21. Bupivacaine HCL"
                  name="BupivacaineHCL"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "11/12", gridColumn: "6/13" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.LevoBupivacaine || false
                  }
                  label="22. Levo-bupivacaine"
                  name="LevoBupivacaine"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "12/13", gridColumn: "6/9" }}>
                <Checkbox
                  checked={
                    props.PeriDataSheetSequence?.data?.OthersAnestheticAgents ||
                    false
                  }
                  label="23. Others"
                  name="OthersAnestheticAgents"
                  onChange={props.onChangeData}
                  style={{ marginLeft: "20px", paddingTop: "10px" }}
                ></Checkbox>
              </div>
              <div style={{ gridRow: "12/13", gridColumn: "9/13" }}>
                <Input
                  name="IsOthersAnestheticAgentsRemark"
                  onChange={props.onChangeData}
                  style={{ width: "95%" }}
                  value={
                    props.PeriDataSheetSequence?.data
                      ?.IsOthersAnestheticAgentsRemark || ""
                  }
                ></Input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Anes_PeriDataSheet;

export const screenPropsDefault = {};

/* Date Time : Tue Nov 29 2022 15:26:10 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{height:\"80px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"80px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(2, 1fr)\",\"gridTemplateColumns\":\"repeat(10, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/11\", display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230, 0.49)\",\"fontWeight\":\"bold\",\"fontSize\":\"17px\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/11\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Preoperative conditions โปรดระบุ Condition ให้ตรงตามระบบ"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Checkbox",
      "parent": 4,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.PreoperativeConditionsIsNone || false"
        },
        "label": {
          "type": "value",
          "value": "None"
        },
        "name": {
          "type": "value",
          "value": "PreoperativeConditionsIsNone"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"550px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "label": "divgrid",
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(13, 1fr)\",\"gridTemplateColumns\":\"repeat(48, 1fr)\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/14\", gridColumn: \"1/13\", border: \"solid 1px rgb(200, 200, 200, 0.5)\",margin:\"1px\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "label": "divgrid",
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(13, 1fr)\",\"gridTemplateColumns\":\"repeat(8, 1fr)\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/9\", border: \"solid 1px rgb(200, 200, 200, 0.5)\",display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230, 0.49)\",\"fontWeight\":\"bold\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/5\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "Respiratory"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Checkbox",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Smoke || false"
        },
        "label": {
          "type": "value",
          "value": "101 Smoke"
        },
        "name": {
          "type": "value",
          "value": "Smoke"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Checkbox",
      "parent": 13,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is102 || false"
        },
        "label": {
          "type": "value",
          "value": "102"
        },
        "name": {
          "type": "value",
          "value": "Is102"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Dropdown",
      "parent": 14,
      "props": {
        "name": {
          "type": "value",
          "value": "Is102Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_102 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is102Remark || \"\""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Checkbox",
      "parent": 19,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is103 || false"
        },
        "label": {
          "type": "value",
          "value": "103"
        },
        "name": {
          "type": "value",
          "value": "Is103"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/5\" }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Checkbox",
      "parent": 21,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is104URI || false"
        },
        "label": {
          "type": "value",
          "value": "104 URI"
        },
        "name": {
          "type": "value",
          "value": "Is104URI"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/9\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Checkbox",
      "parent": 23,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is105LRIpneumoniaTbcOldTB || false"
        },
        "label": {
          "type": "value",
          "value": "105 LRI (pneumonia, Tbc, Old TB)"
        },
        "name": {
          "type": "value",
          "value": "Is105LRIpneumoniaTbcOldTB"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Dropdown",
      "parent": 25,
      "props": {
        "name": {
          "type": "value",
          "value": "Is103Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_103 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is103Remark || \"\""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Checkbox",
      "parent": 27,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is106 || false"
        },
        "label": {
          "type": "value",
          "value": "106"
        },
        "name": {
          "type": "value",
          "value": "Is106"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Dropdown",
      "parent": 29,
      "props": {
        "name": {
          "type": "value",
          "value": "Is106Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_106 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is106Remark || \"\""
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"1/9\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Checkbox",
      "parent": 31,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is107PredictedDifficultAirway || false"
        },
        "label": {
          "type": "value",
          "value": "107 Predicted difficult airway"
        },
        "name": {
          "type": "value",
          "value": "Is107PredictedDifficultAirway"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Checkbox",
      "parent": 33,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is108 || false"
        },
        "label": {
          "type": "value",
          "value": "108"
        },
        "name": {
          "type": "value",
          "value": "Is108"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Dropdown",
      "parent": 35,
      "props": {
        "name": {
          "type": "value",
          "value": "Is108Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_108 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is108Remark || \"\""
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Checkbox",
      "parent": 37,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is109 || false"
        },
        "label": {
          "type": "value",
          "value": "109"
        },
        "name": {
          "type": "value",
          "value": "Is109"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Dropdown",
      "parent": 39,
      "props": {
        "name": {
          "type": "value",
          "value": "Is109Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_109 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is109Remark || \"\""
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"1/9\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Checkbox",
      "parent": 41,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.AllergicRhinitis || false"
        },
        "label": {
          "type": "value",
          "value": "110 Allergic rhinitis"
        },
        "name": {
          "type": "value",
          "value": "AllergicRhinitis"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/13\", gridColumn: \"1/9\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Checkbox",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Hypoxaemia || false"
        },
        "label": {
          "type": "value",
          "value": "111 Hypoxemia"
        },
        "name": {
          "type": "value",
          "value": "Hypoxaemia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"1/3\" }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Checkbox",
      "parent": 45,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IsRespiratoryOther || false"
        },
        "label": {
          "type": "value",
          "value": "Other"
        },
        "name": {
          "type": "value",
          "value": "IsRespiratoryOther"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"3/10\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Input",
      "parent": 47,
      "props": {
        "name": {
          "type": "value",
          "value": "IsRespiratoryOtherRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"270px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IsRespiratoryOtherRemark || \"\""
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/14\", gridColumn: \"13/25\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 49,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(13, 1fr)\",\"gridTemplateColumns\":\"repeat(8, 1fr)\",margin:\"1px\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/9\", border: \"solid 1px rgb(200, 200, 200, 0.5)\",display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230, 0.49)\",\"fontWeight\":\"bold\" }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "Cardiovascular"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/5\" }"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Checkbox",
      "parent": 53,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Hypertension || false"
        },
        "label": {
          "type": "value",
          "value": "201 Hypertension"
        },
        "name": {
          "type": "value",
          "value": "Hypertension"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/9\" }"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Checkbox",
      "parent": 55,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.PulmonaryHypertension || false"
        },
        "label": {
          "type": "value",
          "value": "202 Pulmonary Hypertension"
        },
        "name": {
          "type": "value",
          "value": "PulmonaryHypertension"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Checkbox",
      "parent": 59,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is203 || false"
        },
        "label": {
          "type": "value",
          "value": "203"
        },
        "name": {
          "type": "value",
          "value": "Is203"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Dropdown",
      "parent": 61,
      "props": {
        "name": {
          "type": "value",
          "value": "Is203Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_203 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is203Remark || \"\""
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Checkbox",
      "parent": 63,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is204 || false"
        },
        "label": {
          "type": "value",
          "value": "204"
        },
        "name": {
          "type": "value",
          "value": "Is204"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/4\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Checkbox",
      "parent": 65,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is205 || false"
        },
        "label": {
          "type": "value",
          "value": "205"
        },
        "name": {
          "type": "value",
          "value": "Is205"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/9\" }"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Checkbox",
      "parent": 67,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.CongenitalHeartDisease || false"
        },
        "label": {
          "type": "value",
          "value": "206 Congenital heart disease"
        },
        "name": {
          "type": "value",
          "value": "CongenitalHeartDisease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"1/9\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Checkbox",
      "parent": 71,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.ValvularHeartDisease || false"
        },
        "label": {
          "type": "value",
          "value": "207 Valvular heart disease"
        },
        "name": {
          "type": "value",
          "value": "ValvularHeartDisease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Checkbox",
      "parent": 73,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is208 || false"
        },
        "label": {
          "type": "value",
          "value": "208"
        },
        "name": {
          "type": "value",
          "value": "Is208"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Dropdown",
      "parent": 75,
      "props": {
        "name": {
          "type": "value",
          "value": "Is208Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_208 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is208Remark || \"\""
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Checkbox",
      "parent": 77,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is209 || false"
        },
        "label": {
          "type": "value",
          "value": "209"
        },
        "name": {
          "type": "value",
          "value": "Is209"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Dropdown",
      "parent": 79,
      "props": {
        "name": {
          "type": "value",
          "value": "Is209Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_209 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is209Remark || \"\""
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"1/9\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Checkbox",
      "parent": 81,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.PericardialDisease || false"
        },
        "label": {
          "type": "value",
          "value": "210 Pericardial disease"
        },
        "name": {
          "type": "value",
          "value": "PericardialDisease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/13\", gridColumn: \"1/9\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Checkbox",
      "parent": 83,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.PulmonaryEmbolism || false"
        },
        "label": {
          "type": "value",
          "value": "211 Pulmonary embolism"
        },
        "name": {
          "type": "value",
          "value": "PulmonaryEmbolism"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"1/3\" }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Checkbox",
      "parent": 85,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IsCardiovascularOther || false"
        },
        "label": {
          "type": "value",
          "value": "Other"
        },
        "name": {
          "type": "value",
          "value": "IsCardiovascularOther"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"3/10\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Input",
      "parent": 87,
      "props": {
        "name": {
          "type": "value",
          "value": "IsCardiovascularOtherRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"270px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IsCardiovascularOtherRemark || \"\""
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Dropdown",
      "parent": 89,
      "props": {
        "name": {
          "type": "value",
          "value": "Is204Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_204 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is204Remark || false"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 50,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "Dropdown",
      "parent": 91,
      "props": {
        "name": {
          "type": "value",
          "value": "Is205Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_205 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is205Remark || \"\""
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/14\", gridColumn: \"25/37\", border: \"solid 1px rgb(200, 200, 200, 0.5)\",margin:\"1px\" }"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 93,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(13, 1fr)\",\"gridTemplateColumns\":\"repeat(8, 1fr)\",margin:\"1px\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 94,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/9\", border: \"solid 1px rgb(200, 200, 200, 0.5)\",display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230, 0.49)\",\"fontWeight\":\"bold\" }"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "label",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": "Neuro"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 94,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/4\",marginTop:\"2px\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Checkbox",
      "parent": 97,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is301 || false"
        },
        "label": {
          "type": "value",
          "value": "301"
        },
        "name": {
          "type": "value",
          "value": "Is301"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 94,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "Checkbox",
      "parent": 99,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is302 || false"
        },
        "label": {
          "type": "value",
          "value": "302"
        },
        "name": {
          "type": "value",
          "value": "Is302"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 94,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/9\" }"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Checkbox",
      "parent": 101,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.SpinalCordlesion || false"
        },
        "label": {
          "type": "value",
          "value": "303 Spinal cord lesion"
        },
        "name": {
          "type": "value",
          "value": "SpinalCordlesion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 94,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "Checkbox",
      "parent": 105,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is304 || false"
        },
        "label": {
          "type": "value",
          "value": "304"
        },
        "name": {
          "type": "value",
          "value": "Is304"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 94,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/4\"}"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "Checkbox",
      "parent": 107,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is305 || false"
        },
        "label": {
          "type": "value",
          "value": "305"
        },
        "name": {
          "type": "value",
          "value": "Is305"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 94,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Checkbox",
      "parent": 109,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is306 || false"
        },
        "label": {
          "type": "value",
          "value": "306"
        },
        "name": {
          "type": "value",
          "value": "Is306"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 94,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"1/9\"}"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Checkbox",
      "parent": 111,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Convulsion || false"
        },
        "label": {
          "type": "value",
          "value": "307 Convulsion"
        },
        "name": {
          "type": "value",
          "value": "Convulsion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "div",
      "parent": 94,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Checkbox",
      "parent": 113,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is308 || false"
        },
        "label": {
          "type": "value",
          "value": "308"
        },
        "name": {
          "type": "value",
          "value": "Is308"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 94,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "Dropdown",
      "parent": 115,
      "props": {
        "name": {
          "type": "value",
          "value": "Is308Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_308 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is308Remark || \"\""
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 94,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"1/3\" }"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 126,
      "name": "Checkbox",
      "parent": 125,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IsNeuroOther || false"
        },
        "label": {
          "type": "value",
          "value": "Other"
        },
        "name": {
          "type": "value",
          "value": "IsNeuroOther"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 94,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"3/10\"}"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "Input",
      "parent": 127,
      "props": {
        "name": {
          "type": "value",
          "value": "IsNeuroOtherRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"270px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IsNeuroOtherRemark || \"\""
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 94,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "Dropdown",
      "parent": 129,
      "props": {
        "name": {
          "type": "value",
          "value": "Is304Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_304 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is304Remark || \"\""
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 94,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "Dropdown",
      "parent": 131,
      "props": {
        "name": {
          "type": "value",
          "value": "Is305Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_305 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is305Remark || \"\""
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 94,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"4/9\",marginTop:\"2px\"}"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "Dropdown",
      "parent": 133,
      "props": {
        "name": {
          "type": "value",
          "value": "Is301Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_301 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is301Remark || \"\""
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 94,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "Dropdown",
      "parent": 135,
      "props": {
        "name": {
          "type": "value",
          "value": "Is306Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_306 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is306Remark || \"\""
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 94,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"4/9\",marginTop:\"2px\"}"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "Dropdown",
      "parent": 137,
      "props": {
        "name": {
          "type": "value",
          "value": "Is302Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_302 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is302Remark || \"\""
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/14\", gridColumn: \"37/49\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 139,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(13, 1fr)\",\"gridTemplateColumns\":\"repeat(8, 1fr)\",margin:\"1px\"}"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/9\", border: \"solid 1px rgb(200, 200, 200, 0.5)\",display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230, 0.49)\",\"fontWeight\":\"bold\" }"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": "Endocrine/Metabolic"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "div",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/4\",marginTop:\"2px\"}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "Checkbox",
      "parent": 143,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is401 || false"
        },
        "label": {
          "type": "value",
          "value": "401"
        },
        "name": {
          "type": "value",
          "value": "Is401"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "Checkbox",
      "parent": 145,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is402 || false"
        },
        "label": {
          "type": "value",
          "value": "402"
        },
        "name": {
          "type": "value",
          "value": "Is402"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "div",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "Checkbox",
      "parent": 147,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is403 || false"
        },
        "label": {
          "type": "value",
          "value": "403 "
        },
        "name": {
          "type": "value",
          "value": "Is403"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/9\" }"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "Checkbox",
      "parent": 149,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.OnSteriod || false"
        },
        "label": {
          "type": "value",
          "value": "404 On steriod"
        },
        "name": {
          "type": "value",
          "value": "OnSteriod"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "div",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/4\"}"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "Checkbox",
      "parent": 151,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is405 || false"
        },
        "label": {
          "type": "value",
          "value": "405"
        },
        "name": {
          "type": "value",
          "value": "Is405"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        },
        "toggle": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "Checkbox",
      "parent": 153,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is406 || false"
        },
        "label": {
          "type": "value",
          "value": "406"
        },
        "name": {
          "type": "value",
          "value": "Is406"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"1/9\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "Checkbox",
      "parent": 155,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Obesity || false"
        },
        "label": {
          "type": "value",
          "value": "407 Obesity (BMI >= 30)"
        },
        "name": {
          "type": "value",
          "value": "Obesity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/9\" }"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "Checkbox",
      "parent": 157,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.DLP || false"
        },
        "label": {
          "type": "value",
          "value": "408 DLP"
        },
        "name": {
          "type": "value",
          "value": "DLP"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"1/3\" }"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "Checkbox",
      "parent": 161,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IsEndocrineMetabolicOther || false"
        },
        "label": {
          "type": "value",
          "value": "Other"
        },
        "name": {
          "type": "value",
          "value": "IsEndocrineMetabolicOther"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"3/10\"}"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "Input",
      "parent": 163,
      "props": {
        "name": {
          "type": "value",
          "value": "IsEndocrineMetabolicOtherRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"270px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IsEndocrineMetabolicOtherRemark || \"\""
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "div",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "Dropdown",
      "parent": 167,
      "props": {
        "name": {
          "type": "value",
          "value": "Is405Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_405 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is405Remark || \"\""
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"4/9\",marginTop:\"2px\"}"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "Dropdown",
      "parent": 169,
      "props": {
        "name": {
          "type": "value",
          "value": "Is401Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_401 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is401Remark || \"\""
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "Dropdown",
      "parent": 171,
      "props": {
        "name": {
          "type": "value",
          "value": "Is406Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_406 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is406Remark || \"\""
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"4/9\",marginTop:\"2px\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "Dropdown",
      "parent": 173,
      "props": {
        "name": {
          "type": "value",
          "value": "Is403Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_403 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is403Remark || \"\""
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "div",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"4/9\",marginTop:\"2px\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "Dropdown",
      "parent": 175,
      "props": {
        "name": {
          "type": "value",
          "value": "Is402Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_402 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is402Remark || \"\""
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"550px\"}"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "div",
      "parent": 177,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(13, 1fr)\",\"gridTemplateColumns\":\"repeat(48, 1fr)\"}"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "div",
      "parent": 178,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/14\", gridColumn: \"1/13\", border: \"solid 1px rgb(200, 200, 200, 0.5)\",margin:\"1px\" }"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "div",
      "parent": 179,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(13, 1fr)\",\"gridTemplateColumns\":\"repeat(8, 1fr)\"}"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "div",
      "parent": 180,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/9\", border: \"solid 1px rgb(200, 200, 200, 0.5)\",display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230, 0.49)\",\"fontWeight\":\"bold\" }"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "label",
      "parent": 181,
      "props": {
        "children": {
          "type": "value",
          "value": "Hematologic/Infection"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 180,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/5\" }"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "Checkbox",
      "parent": 183,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Anaemia || false"
        },
        "label": {
          "type": "value",
          "value": "501 Anemia"
        },
        "name": {
          "type": "value",
          "value": "Anaemia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "div",
      "parent": 180,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/9\" }"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "Checkbox",
      "parent": 185,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Plateletlessthan100k || false"
        },
        "label": {
          "type": "value",
          "value": "502 Platelet < 100,000/cum"
        },
        "name": {
          "type": "value",
          "value": "Plateletlessthan100k"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 180,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/9\" }"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 190,
      "name": "Checkbox",
      "parent": 189,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Coagulopathy || false"
        },
        "label": {
          "type": "value",
          "value": "503 Coagulopathy"
        },
        "name": {
          "type": "value",
          "value": "Coagulopathy"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 197,
      "name": "div",
      "parent": 180,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "Checkbox",
      "parent": 197,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is504 || false"
        },
        "label": {
          "type": "value",
          "value": "504"
        },
        "name": {
          "type": "value",
          "value": "Is504"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": null,
      "id": 199,
      "name": "div",
      "parent": 180,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "Dropdown",
      "parent": 199,
      "props": {
        "name": {
          "type": "value",
          "value": "Is504Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_504 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is504Remark || \"\""
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": null,
      "id": 203,
      "name": "div",
      "parent": 180,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 204,
      "name": "Checkbox",
      "parent": 203,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is505 || false"
        },
        "label": {
          "type": "value",
          "value": "505"
        },
        "name": {
          "type": "value",
          "value": "Is505"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": null,
      "id": 205,
      "name": "div",
      "parent": 180,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 206,
      "name": "Dropdown",
      "parent": 205,
      "props": {
        "name": {
          "type": "value",
          "value": "Is505Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_505 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is505Remark || \"\""
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": null,
      "id": 211,
      "name": "div",
      "parent": 180,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/9\"}"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 212,
      "name": "Checkbox",
      "parent": 211,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Fever || false"
        },
        "label": {
          "type": "value",
          "value": "506 Fever"
        },
        "name": {
          "type": "value",
          "value": "Fever"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": null,
      "id": 213,
      "name": "div",
      "parent": 180,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"1/9\"}"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 214,
      "name": "Checkbox",
      "parent": 213,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.HIV || false"
        },
        "label": {
          "type": "value",
          "value": "507 HIV+"
        },
        "name": {
          "type": "value",
          "value": "HIV"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": null,
      "id": 215,
      "name": "div",
      "parent": 180,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/3\" }"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "Checkbox",
      "parent": 215,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IsHaematologicInfectionOther || false"
        },
        "label": {
          "type": "value",
          "value": "Other"
        },
        "name": {
          "type": "value",
          "value": "IsHaematologicInfectionOther"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": null,
      "id": 217,
      "name": "div",
      "parent": 180,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"3/10\"}"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 218,
      "name": "Input",
      "parent": 217,
      "props": {
        "name": {
          "type": "value",
          "value": "IsHaematologicInfectionOtherRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"270px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IsHaematologicInfectionOtherRemark || \"\""
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": null,
      "id": 219,
      "name": "div",
      "parent": 178,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/14\", gridColumn: \"13/25\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": null,
      "id": 220,
      "name": "div",
      "parent": 219,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(13, 1fr)\",\"gridTemplateColumns\":\"repeat(8, 1fr)\",margin:\"1px\"}"
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": null,
      "id": 221,
      "name": "div",
      "parent": 220,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/9\", border: \"solid 1px rgb(200, 200, 200, 0.5)\",display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230, 0.49)\",\"fontWeight\":\"bold\" }"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": null,
      "id": 222,
      "name": "label",
      "parent": 221,
      "props": {
        "children": {
          "type": "value",
          "value": "Liver disease"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": null,
      "id": 223,
      "name": "div",
      "parent": 220,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/5\" }"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 224,
      "name": "Checkbox",
      "parent": 223,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.AbnormalLFT || false"
        },
        "label": {
          "type": "value",
          "value": "601 Abnormal LFT"
        },
        "name": {
          "type": "value",
          "value": "AbnormalLFT"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": null,
      "id": 225,
      "name": "div",
      "parent": 220,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/9\" }"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 226,
      "name": "Checkbox",
      "parent": 225,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Cirrhosis || false"
        },
        "label": {
          "type": "value",
          "value": "602 Cirrhosis"
        },
        "name": {
          "type": "value",
          "value": "Cirrhosis"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": null,
      "id": 227,
      "name": "div",
      "parent": 220,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/9\" }"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 228,
      "name": "Checkbox",
      "parent": 227,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Liverfailure || false"
        },
        "label": {
          "type": "value",
          "value": "603 Liver failure"
        },
        "name": {
          "type": "value",
          "value": "Liverfailure"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": null,
      "id": 231,
      "name": "div",
      "parent": 220,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/9\" }"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 232,
      "name": "Checkbox",
      "parent": 231,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Hepatitis || false"
        },
        "label": {
          "type": "value",
          "value": "604 Hepatitis"
        },
        "name": {
          "type": "value",
          "value": "Hepatitis"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": null,
      "id": 251,
      "name": "div",
      "parent": 220,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/3\" }"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 252,
      "name": "Checkbox",
      "parent": 251,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IsLiverDiseaseOther || false"
        },
        "label": {
          "type": "value",
          "value": "Other"
        },
        "name": {
          "type": "value",
          "value": "IsLiverDiseaseOther"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": null,
      "id": 253,
      "name": "div",
      "parent": 220,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"3/10\"}"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 254,
      "name": "Input",
      "parent": 253,
      "props": {
        "name": {
          "type": "value",
          "value": "IsLiverDiseaseOtherRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"270px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IsLiverDiseaseOtherRemark || \"\""
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": null,
      "id": 259,
      "name": "div",
      "parent": 178,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/14\", gridColumn: \"25/37\", border: \"solid 1px rgb(200, 200, 200, 0.5)\",margin:\"1px\" }"
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": null,
      "id": 260,
      "name": "div",
      "parent": 259,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(13, 1fr)\",\"gridTemplateColumns\":\"repeat(8, 1fr)\",margin:\"1px\"}"
        }
      },
      "seq": 260,
      "void": false
    },
    {
      "from": null,
      "id": 261,
      "name": "div",
      "parent": 260,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/9\", border: \"solid 1px rgb(200, 200, 200, 0.5)\",display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230, 0.49)\",\"fontWeight\":\"bold\" }"
        }
      },
      "seq": 261,
      "void": false
    },
    {
      "from": null,
      "id": 262,
      "name": "label",
      "parent": 261,
      "props": {
        "children": {
          "type": "value",
          "value": "Renal disease"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 262,
      "void": false
    },
    {
      "from": null,
      "id": 263,
      "name": "div",
      "parent": 260,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/9\",marginTop:\"2px\"}"
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 264,
      "name": "Checkbox",
      "parent": 263,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.RenalImpairment || false"
        },
        "label": {
          "type": "value",
          "value": "701 Renal Impairment"
        },
        "name": {
          "type": "value",
          "value": "RenalImpairment"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 264,
      "void": false
    },
    {
      "from": null,
      "id": 265,
      "name": "div",
      "parent": 260,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 266,
      "name": "Checkbox",
      "parent": 265,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is702 || false"
        },
        "label": {
          "type": "value",
          "value": "702"
        },
        "name": {
          "type": "value",
          "value": "Is702"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": null,
      "id": 281,
      "name": "div",
      "parent": 260,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/3\" }"
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 282,
      "name": "Checkbox",
      "parent": 281,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IsRenalDiseaseOther || false"
        },
        "label": {
          "type": "value",
          "value": "Other"
        },
        "name": {
          "type": "value",
          "value": "IsRenalDiseaseOther"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": null,
      "id": 283,
      "name": "div",
      "parent": 260,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"3/10\"}"
        }
      },
      "seq": 283,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 284,
      "name": "Input",
      "parent": 283,
      "props": {
        "name": {
          "type": "value",
          "value": "IsRenalDiseaseOtherRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"270px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IsRenalDiseaseOtherRemark || \"\""
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": null,
      "id": 293,
      "name": "div",
      "parent": 260,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"4/9\",marginTop:\"2px\"}"
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 294,
      "name": "Dropdown",
      "parent": 293,
      "props": {
        "name": {
          "type": "value",
          "value": "Is702Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_702 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is702Remark || \"\""
        }
      },
      "seq": 294,
      "void": false
    },
    {
      "from": null,
      "id": 295,
      "name": "div",
      "parent": 178,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/14\", gridColumn: \"37/49\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 295,
      "void": false
    },
    {
      "from": null,
      "id": 296,
      "name": "div",
      "parent": 295,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(13, 1fr)\",\"gridTemplateColumns\":\"repeat(8, 1fr)\",margin:\"1px\"}"
        }
      },
      "seq": 296,
      "void": false
    },
    {
      "from": null,
      "id": 297,
      "name": "div",
      "parent": 296,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/9\", border: \"solid 1px rgb(200, 200, 200, 0.5)\",display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230, 0.49)\",\"fontWeight\":\"bold\" }"
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": null,
      "id": 298,
      "name": "label",
      "parent": 297,
      "props": {
        "children": {
          "type": "value",
          "value": "Miscellaneous"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 298,
      "void": false
    },
    {
      "from": null,
      "id": 299,
      "name": "div",
      "parent": 296,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/9\",marginTop:\"2px\"}"
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 300,
      "name": "Checkbox",
      "parent": 299,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Sepsis || false"
        },
        "label": {
          "type": "value",
          "value": "801 Sepsis"
        },
        "name": {
          "type": "value",
          "value": "Sepsis"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": null,
      "id": 301,
      "name": "div",
      "parent": 296,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/9\" }"
        }
      },
      "seq": 301,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 302,
      "name": "Checkbox",
      "parent": 301,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.PostTransplantationn || false"
        },
        "label": {
          "type": "value",
          "value": "802 Post transplantation"
        },
        "name": {
          "type": "value",
          "value": "PostTransplantationn"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 302,
      "void": false
    },
    {
      "from": null,
      "id": 303,
      "name": "div",
      "parent": 296,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/9\" }"
        }
      },
      "seq": 303,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 304,
      "name": "Checkbox",
      "parent": 303,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.AutoimmuneDisease || false"
        },
        "label": {
          "type": "value",
          "value": "803 Autoimmune disease"
        },
        "name": {
          "type": "value",
          "value": "AutoimmuneDisease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 304,
      "void": false
    },
    {
      "from": null,
      "id": 305,
      "name": "div",
      "parent": 296,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/9\" }"
        }
      },
      "seq": 305,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 306,
      "name": "Checkbox",
      "parent": 305,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Extreme || false"
        },
        "label": {
          "type": "value",
          "value": "804 Extreme"
        },
        "name": {
          "type": "value",
          "value": "Extreme"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 306,
      "void": false
    },
    {
      "from": null,
      "id": 307,
      "name": "div",
      "parent": 296,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/9\"}"
        }
      },
      "seq": 307,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 308,
      "name": "Checkbox",
      "parent": 307,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.postCardiacArrest || false"
        },
        "label": {
          "type": "value",
          "value": "805 Post cardiac arrest"
        },
        "name": {
          "type": "value",
          "value": "postCardiacArrest"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        },
        "toggle": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 308,
      "void": false
    },
    {
      "from": null,
      "id": 309,
      "name": "div",
      "parent": 296,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 309,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 310,
      "name": "Checkbox",
      "parent": 309,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Addict || false"
        },
        "label": {
          "type": "value",
          "value": "806 Addict"
        },
        "name": {
          "type": "value",
          "value": "Addict"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 310,
      "void": false
    },
    {
      "from": null,
      "id": 311,
      "name": "div",
      "parent": 296,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"1/4\"}"
        }
      },
      "seq": 311,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 312,
      "name": "Checkbox",
      "parent": 311,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is807 || false"
        },
        "label": {
          "type": "value",
          "value": "807"
        },
        "name": {
          "type": "value",
          "value": "Is807"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 312,
      "void": false
    },
    {
      "from": null,
      "id": 313,
      "name": "div",
      "parent": 296,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/9\" }"
        }
      },
      "seq": 313,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 314,
      "name": "Checkbox",
      "parent": 313,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Cancer || false"
        },
        "label": {
          "type": "value",
          "value": "808 Cancer"
        },
        "name": {
          "type": "value",
          "value": "Cancer"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 314,
      "void": false
    },
    {
      "from": null,
      "id": 315,
      "name": "div",
      "parent": 296,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"1/3\" }"
        }
      },
      "seq": 315,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 316,
      "name": "Checkbox",
      "parent": 315,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IsMiscellaneousOther || false"
        },
        "label": {
          "type": "value",
          "value": "Other"
        },
        "name": {
          "type": "value",
          "value": "IsMiscellaneousOther"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 316,
      "void": false
    },
    {
      "from": null,
      "id": 317,
      "name": "div",
      "parent": 296,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"3/10\"}"
        }
      },
      "seq": 317,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 318,
      "name": "Input",
      "parent": 317,
      "props": {
        "name": {
          "type": "value",
          "value": "IsMiscellaneousOtherRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"270px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IsMiscellaneousOtherRemark || \"\""
        }
      },
      "seq": 318,
      "void": false
    },
    {
      "from": null,
      "id": 323,
      "name": "div",
      "parent": 296,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 323,
      "void": false
    },
    {
      "from": null,
      "id": 325,
      "name": "div",
      "parent": 296,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 325,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 326,
      "name": "Dropdown",
      "parent": 325,
      "props": {
        "name": {
          "type": "value",
          "value": "Is807Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_807 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is807Remark || \"\""
        }
      },
      "seq": 326,
      "void": false
    },
    {
      "from": null,
      "id": 327,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"550px\",marginTop:\"20px\"}"
        }
      },
      "seq": 327,
      "void": false
    },
    {
      "from": null,
      "id": 328,
      "label": "divgrid",
      "name": "div",
      "parent": 327,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(13, 1fr)\",\"gridTemplateColumns\":\"repeat(48, 1fr)\"}"
        }
      },
      "seq": 328,
      "void": false
    },
    {
      "from": null,
      "id": 329,
      "name": "div",
      "parent": 328,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/14\", gridColumn: \"1/16\", border: \"solid 1px rgb(200, 200, 200, 0.5)\",margin:\"1px\" }"
        }
      },
      "seq": 329,
      "void": false
    },
    {
      "from": null,
      "id": 330,
      "name": "div",
      "parent": 329,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(13, 1fr)\",\"gridTemplateColumns\":\"repeat(8, 1fr)\"}"
        }
      },
      "seq": 330,
      "void": false
    },
    {
      "from": null,
      "id": 331,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/9\", border: \"solid 1px rgb(200, 200, 200, 0.5)\",display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230, 0.49)\",\"fontWeight\":\"bold\" }"
        }
      },
      "seq": 331,
      "void": false
    },
    {
      "from": null,
      "id": 332,
      "name": "label",
      "parent": 331,
      "props": {
        "children": {
          "type": "value",
          "value": "Monitors"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 332,
      "void": false
    },
    {
      "from": null,
      "id": 333,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 333,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 334,
      "name": "Checkbox",
      "parent": 333,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.NIBP || false"
        },
        "label": {
          "type": "value",
          "value": "1. NIBP"
        },
        "name": {
          "type": "value",
          "value": "NIBP"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 334,
      "void": false
    },
    {
      "from": null,
      "id": 335,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 335,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 336,
      "name": "Checkbox",
      "parent": 335,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.SpO2 || false"
        },
        "label": {
          "type": "value",
          "value": "2. SpO2"
        },
        "name": {
          "type": "value",
          "value": "SpO2"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 336,
      "void": false
    },
    {
      "from": null,
      "id": 339,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 339,
      "void": false
    },
    {
      "from": null,
      "id": 343,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 343,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 344,
      "name": "Checkbox",
      "parent": 343,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IBP || false"
        },
        "label": {
          "type": "value",
          "value": "4. IBP"
        },
        "name": {
          "type": "value",
          "value": "IBP"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 344,
      "void": false
    },
    {
      "from": null,
      "id": 345,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/4\"}"
        }
      },
      "seq": 345,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 346,
      "name": "Checkbox",
      "parent": 345,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.CVP || false"
        },
        "label": {
          "type": "value",
          "value": "5. CVP"
        },
        "name": {
          "type": "value",
          "value": "CVP"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 346,
      "void": false
    },
    {
      "from": null,
      "id": 347,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 347,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 348,
      "name": "Checkbox",
      "parent": 347,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.EtCO2 || false"
        },
        "label": {
          "type": "value",
          "value": "6. EtCO2"
        },
        "name": {
          "type": "value",
          "value": "EtCO2"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 348,
      "void": false
    },
    {
      "from": null,
      "id": 351,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"1/4\"}"
        }
      },
      "seq": 351,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 352,
      "name": "Checkbox",
      "parent": 351,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Urine || false"
        },
        "label": {
          "type": "value",
          "value": "7. Urine"
        },
        "name": {
          "type": "value",
          "value": "Urine"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 352,
      "void": false
    },
    {
      "from": null,
      "id": 353,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/4\" }"
        }
      },
      "seq": 353,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 354,
      "name": "Checkbox",
      "parent": 353,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Temp || false"
        },
        "label": {
          "type": "value",
          "value": "8. Temp"
        },
        "name": {
          "type": "value",
          "value": "Temp"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 354,
      "void": false
    },
    {
      "from": null,
      "id": 357,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"1/3\" }"
        }
      },
      "seq": 357,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 358,
      "name": "Checkbox",
      "parent": 357,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is9 || false"
        },
        "label": {
          "type": "value",
          "value": "9."
        },
        "name": {
          "type": "value",
          "value": "Is9"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 358,
      "void": false
    },
    {
      "from": null,
      "id": 359,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"3/9\"}"
        }
      },
      "seq": 359,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 360,
      "name": "Dropdown",
      "parent": 359,
      "props": {
        "name": {
          "type": "value",
          "value": "Is9Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_9 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"95%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is9Remark || \"\""
        }
      },
      "seq": 360,
      "void": false
    },
    {
      "from": null,
      "id": 361,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"1/3\"}"
        }
      },
      "seq": 361,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 362,
      "name": "Checkbox",
      "parent": 361,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is10 || false"
        },
        "label": {
          "type": "value",
          "value": "10."
        },
        "name": {
          "type": "value",
          "value": "Is10"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 362,
      "void": false
    },
    {
      "from": null,
      "id": 363,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/13\", gridColumn: \"1/9\"}"
        }
      },
      "seq": 363,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 364,
      "name": "Checkbox",
      "parent": 363,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.NMB || false"
        },
        "label": {
          "type": "value",
          "value": "11. NMB"
        },
        "name": {
          "type": "value",
          "value": "NMB"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 364,
      "void": false
    },
    {
      "from": null,
      "id": 365,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"1/3\" }"
        }
      },
      "seq": 365,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 366,
      "name": "Checkbox",
      "parent": 365,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is12 || false"
        },
        "label": {
          "type": "value",
          "value": "12."
        },
        "name": {
          "type": "value",
          "value": "Is12"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 366,
      "void": false
    },
    {
      "from": null,
      "id": 367,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"3/10\"}"
        }
      },
      "seq": 367,
      "void": false
    },
    {
      "from": null,
      "id": 369,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"4/9\"}"
        }
      },
      "seq": 369,
      "void": false
    },
    {
      "from": null,
      "id": 371,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"4/5\"}"
        }
      },
      "seq": 371,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 372,
      "name": "Checkbox",
      "parent": 371,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is14 || false"
        },
        "label": {
          "type": "value",
          "value": "14."
        },
        "name": {
          "type": "value",
          "value": "Is14"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop:\"10px\"}"
        }
      },
      "seq": 372,
      "void": false
    },
    {
      "from": null,
      "id": 373,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"5/9\"}"
        }
      },
      "seq": 373,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 374,
      "name": "Dropdown",
      "parent": 373,
      "props": {
        "name": {
          "type": "value",
          "value": "Is14Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_14 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"95%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is14Remark || \"\""
        }
      },
      "seq": 374,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 375,
      "name": "Checkbox",
      "parent": 369,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.TEE || false"
        },
        "label": {
          "type": "value",
          "value": "13. TEE"
        },
        "name": {
          "type": "value",
          "value": "TEE"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop:\"10px\"}"
        }
      },
      "seq": 375,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 376,
      "name": "Checkbox",
      "parent": 339,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.EKG || false"
        },
        "label": {
          "type": "value",
          "value": "3. EKG"
        },
        "name": {
          "type": "value",
          "value": "EKG"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 376,
      "void": false
    },
    {
      "from": null,
      "id": 377,
      "name": "div",
      "parent": 330,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"3/9\"}"
        }
      },
      "seq": 377,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 378,
      "name": "Dropdown",
      "parent": 377,
      "props": {
        "name": {
          "type": "value",
          "value": "Is10Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_10 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"95%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is10Remark || \"\""
        }
      },
      "seq": 378,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 379,
      "name": "Dropdown",
      "parent": 367,
      "props": {
        "name": {
          "type": "value",
          "value": "Is12Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.peri_12 || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"95%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Is12Remark || \"\""
        }
      },
      "seq": 379,
      "void": false
    },
    {
      "from": null,
      "id": 380,
      "name": "div",
      "parent": 328,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/3\", gridColumn: \"16/28\",padding:\"1px\"}"
        }
      },
      "seq": 380,
      "void": false
    },
    {
      "from": null,
      "id": 381,
      "name": "div",
      "parent": 328,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/14\", gridColumn: \"16/28\", border: \"solid 1px rgb(200, 200, 200, 0.5)\",padding:\"1px\" }"
        }
      },
      "seq": 381,
      "void": false
    },
    {
      "from": null,
      "id": 382,
      "name": "div",
      "parent": 328,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/14\", gridColumn: \"28/49\", border: \"solid 1px rgb(200, 200, 200, 0.5)\",margin:\"1px\" }"
        }
      },
      "seq": 382,
      "void": false
    },
    {
      "from": null,
      "id": 383,
      "label": "divgrid",
      "name": "div",
      "parent": 382,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(13, 1fr)\",\"gridTemplateColumns\":\"repeat(12, 1fr)\"}"
        }
      },
      "seq": 383,
      "void": false
    },
    {
      "from": null,
      "id": 384,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/13\", border: \"solid 1px rgb(200, 200, 200, 0.5)\",display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230, 0.49)\",\"fontWeight\":\"bold\" }"
        }
      },
      "seq": 384,
      "void": false
    },
    {
      "from": null,
      "id": 385,
      "name": "label",
      "parent": 384,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthetic Agents"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 385,
      "void": false
    },
    {
      "from": null,
      "id": 386,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 386,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 387,
      "name": "Checkbox",
      "parent": 386,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Thiopental || false"
        },
        "label": {
          "type": "value",
          "value": "1. Thiopental"
        },
        "name": {
          "type": "value",
          "value": "Thiopental"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 387,
      "void": false
    },
    {
      "from": null,
      "id": 388,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 388,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 389,
      "name": "Checkbox",
      "parent": 388,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Propofol || false"
        },
        "label": {
          "type": "value",
          "value": "2. Propofol"
        },
        "name": {
          "type": "value",
          "value": "Propofol"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 389,
      "void": false
    },
    {
      "from": null,
      "id": 390,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 390,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 391,
      "name": "Checkbox",
      "parent": 390,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Ketamine || false"
        },
        "label": {
          "type": "value",
          "value": "3. Ketamine"
        },
        "name": {
          "type": "value",
          "value": "Ketamine"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 391,
      "void": false
    },
    {
      "from": null,
      "id": 392,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 392,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 393,
      "name": "Checkbox",
      "parent": 392,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Etomidate || false"
        },
        "label": {
          "type": "value",
          "value": "4. Etomidate"
        },
        "name": {
          "type": "value",
          "value": "Etomidate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 393,
      "void": false
    },
    {
      "from": null,
      "id": 394,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 394,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 395,
      "name": "Checkbox",
      "parent": 394,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Dexmedetomidine || false"
        },
        "label": {
          "type": "value",
          "value": "5. Dexmedetomidine"
        },
        "name": {
          "type": "value",
          "value": "Dexmedetomidine"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 395,
      "void": false
    },
    {
      "from": null,
      "id": 396,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 396,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 397,
      "name": "Checkbox",
      "parent": 396,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Midazolam || false"
        },
        "label": {
          "type": "value",
          "value": "6. Midazolam"
        },
        "name": {
          "type": "value",
          "value": "Midazolam"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 397,
      "void": false
    },
    {
      "from": null,
      "id": 398,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 398,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 399,
      "name": "Checkbox",
      "parent": 398,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Isoflurane || false"
        },
        "label": {
          "type": "value",
          "value": "7. Isoflurane"
        },
        "name": {
          "type": "value",
          "value": "Isoflurane"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 399,
      "void": false
    },
    {
      "from": null,
      "id": 400,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 400,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 401,
      "name": "Checkbox",
      "parent": 400,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Sevoflurane || false"
        },
        "label": {
          "type": "value",
          "value": "8. Sevoflurane"
        },
        "name": {
          "type": "value",
          "value": "Sevoflurane"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 401,
      "void": false
    },
    {
      "from": null,
      "id": 402,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 402,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 403,
      "name": "Checkbox",
      "parent": 402,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Desflurane || false"
        },
        "label": {
          "type": "value",
          "value": "9. Desflurane"
        },
        "name": {
          "type": "value",
          "value": "Desflurane"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 403,
      "void": false
    },
    {
      "from": null,
      "id": 404,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 404,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 405,
      "name": "Checkbox",
      "parent": 404,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Succinylcholine || false"
        },
        "label": {
          "type": "value",
          "value": "10. Succinylcholine"
        },
        "name": {
          "type": "value",
          "value": "Succinylcholine"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 405,
      "void": false
    },
    {
      "from": null,
      "id": 406,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/13\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 406,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 407,
      "name": "Checkbox",
      "parent": 406,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Pancuronium || false"
        },
        "label": {
          "type": "value",
          "value": "11. Pancuronium"
        },
        "name": {
          "type": "value",
          "value": "Pancuronium"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 407,
      "void": false
    },
    {
      "from": null,
      "id": 408,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 408,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 409,
      "name": "Checkbox",
      "parent": 408,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.CisAtracurium || false"
        },
        "label": {
          "type": "value",
          "value": "12. Cis-Atracurium"
        },
        "name": {
          "type": "value",
          "value": "CisAtracurium"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 409,
      "void": false
    },
    {
      "from": null,
      "id": 410,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"6/13\" }"
        }
      },
      "seq": 410,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 411,
      "name": "Checkbox",
      "parent": 410,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Atracurium || false"
        },
        "label": {
          "type": "value",
          "value": "13. Atracurium"
        },
        "name": {
          "type": "value",
          "value": "Atracurium"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 411,
      "void": false
    },
    {
      "from": null,
      "id": 412,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"6/13\" }"
        }
      },
      "seq": 412,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 413,
      "name": "Checkbox",
      "parent": 412,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Vecuronium || false"
        },
        "label": {
          "type": "value",
          "value": "14. Vecuronium"
        },
        "name": {
          "type": "value",
          "value": "Vecuronium"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 413,
      "void": false
    },
    {
      "from": null,
      "id": 414,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"6/13\" }"
        }
      },
      "seq": 414,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 415,
      "name": "Checkbox",
      "parent": 414,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Rocuronium || false"
        },
        "label": {
          "type": "value",
          "value": "15. Rocuronium"
        },
        "name": {
          "type": "value",
          "value": "Rocuronium"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 415,
      "void": false
    },
    {
      "from": null,
      "id": 416,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"6/13\" }"
        }
      },
      "seq": 416,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 417,
      "name": "Checkbox",
      "parent": 416,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Morphine || false"
        },
        "label": {
          "type": "value",
          "value": "16. Morphine"
        },
        "name": {
          "type": "value",
          "value": "Morphine"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 417,
      "void": false
    },
    {
      "from": null,
      "id": 418,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"6/13\" }"
        }
      },
      "seq": 418,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 419,
      "name": "Checkbox",
      "parent": 418,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Fentany || false"
        },
        "label": {
          "type": "value",
          "value": "17. Fentanyl"
        },
        "name": {
          "type": "value",
          "value": "Fentany"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 419,
      "void": false
    },
    {
      "from": null,
      "id": 420,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"6/13\" }"
        }
      },
      "seq": 420,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 421,
      "name": "Checkbox",
      "parent": 420,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Pethidine || false"
        },
        "label": {
          "type": "value",
          "value": "18. Pethidine"
        },
        "name": {
          "type": "value",
          "value": "Pethidine"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 421,
      "void": false
    },
    {
      "from": null,
      "id": 422,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"6/13\" }"
        }
      },
      "seq": 422,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 423,
      "name": "Checkbox",
      "parent": 422,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Tramadol || false"
        },
        "label": {
          "type": "value",
          "value": "19. Tramadol"
        },
        "name": {
          "type": "value",
          "value": "Tramadol"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 423,
      "void": false
    },
    {
      "from": null,
      "id": 424,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"6/13\" }"
        }
      },
      "seq": 424,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 425,
      "name": "Checkbox",
      "parent": 424,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.LidocaineHCL || false"
        },
        "label": {
          "type": "value",
          "value": "20. Lidocaine HCL"
        },
        "name": {
          "type": "value",
          "value": "LidocaineHCL"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 425,
      "void": false
    },
    {
      "from": null,
      "id": 426,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"6/13\" }"
        }
      },
      "seq": 426,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 427,
      "name": "Checkbox",
      "parent": 426,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.BupivacaineHCL || false"
        },
        "label": {
          "type": "value",
          "value": "21. Bupivacaine HCL"
        },
        "name": {
          "type": "value",
          "value": "BupivacaineHCL"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 427,
      "void": false
    },
    {
      "from": null,
      "id": 428,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"6/13\" }"
        }
      },
      "seq": 428,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 429,
      "name": "Checkbox",
      "parent": 428,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.LevoBupivacaine || false"
        },
        "label": {
          "type": "value",
          "value": "22. Levo-bupivacaine"
        },
        "name": {
          "type": "value",
          "value": "LevoBupivacaine"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 429,
      "void": false
    },
    {
      "from": null,
      "id": 430,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/13\", gridColumn: \"6/9\" }"
        }
      },
      "seq": 430,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 431,
      "name": "Checkbox",
      "parent": 430,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.OthersAnestheticAgents || false"
        },
        "label": {
          "type": "value",
          "value": "23. Others"
        },
        "name": {
          "type": "value",
          "value": "OthersAnestheticAgents"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 431,
      "void": false
    },
    {
      "from": null,
      "id": 432,
      "name": "div",
      "parent": 383,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/13\", gridColumn: \"9/13\" }"
        }
      },
      "seq": 432,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 433,
      "name": "Input",
      "parent": 432,
      "props": {
        "name": {
          "type": "value",
          "value": "IsOthersAnestheticAgentsRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"95%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IsOthersAnestheticAgentsRemark || \"\""
        }
      },
      "seq": 433,
      "void": false
    },
    {
      "from": null,
      "id": 434,
      "label": "divgrid",
      "name": "div",
      "parent": 381,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(11, 1fr)\",\"gridTemplateColumns\":\"repeat(10, 1fr)\"}"
        }
      },
      "seq": 434,
      "void": false
    },
    {
      "from": null,
      "id": 435,
      "name": "div",
      "parent": 434,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/11\"}"
        }
      },
      "seq": 435,
      "void": false
    },
    {
      "from": null,
      "id": 436,
      "name": "div",
      "parent": 434,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/11\"}"
        }
      },
      "seq": 436,
      "void": false
    },
    {
      "from": null,
      "id": 437,
      "name": "div",
      "parent": 434,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/11\"}"
        }
      },
      "seq": 437,
      "void": false
    },
    {
      "from": null,
      "id": 438,
      "name": "div",
      "parent": 434,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/11\"}"
        }
      },
      "seq": 438,
      "void": false
    },
    {
      "from": null,
      "id": 439,
      "name": "div",
      "parent": 434,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/11\" }"
        }
      },
      "seq": 439,
      "void": false
    },
    {
      "from": null,
      "id": 440,
      "name": "div",
      "parent": 434,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/11\" }"
        }
      },
      "seq": 440,
      "void": false
    },
    {
      "from": null,
      "id": 441,
      "name": "div",
      "parent": 434,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/11\" }"
        }
      },
      "seq": 441,
      "void": false
    },
    {
      "from": null,
      "id": 442,
      "name": "div",
      "parent": 434,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"1/11\"}"
        }
      },
      "seq": 442,
      "void": false
    },
    {
      "from": null,
      "id": 443,
      "name": "div",
      "parent": 434,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/11\" }"
        }
      },
      "seq": 443,
      "void": false
    },
    {
      "from": null,
      "id": 444,
      "name": "div",
      "parent": 434,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"1/4\"}"
        }
      },
      "seq": 444,
      "void": false
    },
    {
      "from": null,
      "id": 445,
      "name": "div",
      "parent": 434,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"4/11\"}"
        }
      },
      "seq": 445,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 446,
      "name": "Checkbox",
      "parent": 435,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.AwakeIntubation || false"
        },
        "label": {
          "type": "value",
          "value": "1. Awake intubation"
        },
        "name": {
          "type": "value",
          "value": "AwakeIntubation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 446,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 447,
      "name": "Checkbox",
      "parent": 436,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.FiberopticIntubation || false"
        },
        "label": {
          "type": "value",
          "value": "2. Fiberoptic intubation"
        },
        "name": {
          "type": "value",
          "value": "FiberopticIntubation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 447,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 448,
      "name": "Checkbox",
      "parent": 437,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Hypotension || false"
        },
        "label": {
          "type": "value",
          "value": "3. Hypotension"
        },
        "name": {
          "type": "value",
          "value": "Hypotension"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 448,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 449,
      "name": "Checkbox",
      "parent": 438,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.Hypothermia || false"
        },
        "label": {
          "type": "value",
          "value": "4. Hypothermia"
        },
        "name": {
          "type": "value",
          "value": "Hypothermia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 449,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 450,
      "name": "Checkbox",
      "parent": 439,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.CPB || false"
        },
        "label": {
          "type": "value",
          "value": "5. CPB"
        },
        "name": {
          "type": "value",
          "value": "CPB"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 450,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 451,
      "name": "Checkbox",
      "parent": 440,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.AutologousBloodTransfusion || false"
        },
        "label": {
          "type": "value",
          "value": "6. Autologous blood transfusion"
        },
        "name": {
          "type": "value",
          "value": "AutologousBloodTransfusion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 451,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 452,
      "name": "Checkbox",
      "parent": 441,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.AwakeCraniotomy || false"
        },
        "label": {
          "type": "value",
          "value": "7. Awake craniotomy"
        },
        "name": {
          "type": "value",
          "value": "AwakeCraniotomy"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 452,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 453,
      "name": "Checkbox",
      "parent": 442,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.DeepCirculatoryArrest || false"
        },
        "label": {
          "type": "value",
          "value": "8. Deep circulatory arrest"
        },
        "name": {
          "type": "value",
          "value": "DeepCirculatoryArrest"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 453,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 454,
      "name": "Checkbox",
      "parent": 443,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.BarbiturateComa || false"
        },
        "label": {
          "type": "value",
          "value": "9. Barbiturate coma"
        },
        "name": {
          "type": "value",
          "value": "BarbiturateComa"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 454,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 455,
      "name": "Checkbox",
      "parent": 444,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.OtherSpecialTec || false"
        },
        "label": {
          "type": "value",
          "value": "10. Other"
        },
        "name": {
          "type": "value",
          "value": "OtherSpecialTec"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 455,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 456,
      "name": "Input",
      "parent": 445,
      "props": {
        "name": {
          "type": "value",
          "value": "IsOtherSpecialTecRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"95%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.IsOtherSpecialTecRemark || \"\""
        }
      },
      "seq": 456,
      "void": false
    },
    {
      "from": null,
      "id": 457,
      "label": "divgrid",
      "name": "div",
      "parent": 380,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(2, 1fr)\",\"gridTemplateColumns\":\"repeat(1, 1fr)\"}"
        }
      },
      "seq": 457,
      "void": false
    },
    {
      "from": null,
      "id": 458,
      "name": "div",
      "parent": 457,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/2\",  border: \"solid 1px rgb(200, 200, 200, 0.5)\",display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230, 0.49)\",\"fontWeight\":\"bold\" }"
        }
      },
      "seq": 458,
      "void": false
    },
    {
      "from": null,
      "id": 459,
      "name": "div",
      "parent": 457,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/2\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 459,
      "void": false
    },
    {
      "from": null,
      "id": 460,
      "name": "label",
      "parent": 458,
      "props": {
        "children": {
          "type": "value",
          "value": "Special Tech."
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 460,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 461,
      "name": "Checkbox",
      "parent": 459,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.SpecialTechNone || false"
        },
        "label": {
          "type": "value",
          "value": "None"
        },
        "name": {
          "type": "value",
          "value": "SpecialTechNone"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",paddingTop:\"10px\"}"
        }
      },
      "seq": 461,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 462,
      "name": "Input",
      "parent": 323,
      "props": {
        "name": {
          "type": "value",
          "value": "AddictRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"270px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PeriDataSheetSequence?.data?.AddictRemark || \"\""
        }
      },
      "seq": 462,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "Anes_PeriDataSheet",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
