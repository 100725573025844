import WasmController from "react-lib/frameworks/WasmController";

// APIs
// DEN
// IME
import ImagingQueueList from "issara-sdk/apis/ImagingQueueList_apps_IME";
import ImagingOrderResultReportList from "issara-sdk/apis/ImagingOrderResultReportList_apps_IME";
import ImagingOrderDetail from "issara-sdk/apis/ImagingOrderDetail_apps_IME";
import ImagingOrderItemActionLogList from "issara-sdk/apis/ImagingOrderItemActionLogList_apps_IME";
import ImagingOrderItemImageList from "issara-sdk/apis/ImagingOrderItemImageList_apps_IME";

// CORE
import EncounterMedicalRecordList from "issara-sdk/apis/EncounterMedicalRecordList_core";
// DPO
import DiagnosisMedicalRecordDetail from "issara-sdk/apis/DiagnosisMedicalRecordDetail_apps_DPO";
// Serializer
import ImagingOrderResultSerializerI from "issara-sdk/types/ImagingOrderResultSerializer_apps_IME";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import {
  GetClinicalFindingList,
  GetOrgan,
  HandleModXrayDetail,
  ImagingItemSerializer,
  ImagingOrderSerializer,
  ModXrayDetailType,
} from "./ImagingOrder";
import { GetIcd10KeyUp } from "../../ADM/sequence/ReportPatientList";
import {
  ImagingOrderItemActionLogSerializer,
  ImagingQueueSerializer,
  ORDER_ITEM_STATUS_LABEL,
  OrderStatusType,
} from "./ImagingWorkList";

export type State = Partial<{
  // CommonInterface
  imagingResultEditData?:
    | (ImagingQueueSerializer & { readOnly?: boolean })
    | null;
  // sequence
  ImagingResultSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    orderList: ImagingQueueSerializer[];
    selectedOrder: ImagingQueueSerializer | null;
    reportDetail: Partial<ImagingOrderResultSerializer>;
    imagingItemDetail: Partial<ImagingOrderSerializer> | null;
    modXrayDetail: (ImagingItemSerializer & { index: number }) | null;
    principalDiagnosis: Record<string, any> | null;
    auditLogList: ImagingOrderItemActionLogSerializer[];
    pacsGalleryDetail: PacsGalleryDetailType | null;
    readOnly?: boolean;
  }> | null;
}>;

type Picked = Partial<
  Pick<
    MainState,
    | "buttonLoadCheck"
    | "errorMessage"
    | "searchedItemListWithKey"
    | "selectedEncounter"
  >
>;

type ImagingOrderResultSerializer = {
  username?: string;
  password?: string;
  edit_reason?: string;
  order_item_status: OrderStatusType;
} & Omit<ImagingOrderResultSerializerI, "order_item_status">;

type CUIRResponse = Promise<
  [
    {
      order_item: number;
      status: OrderStatusType;
      order_item_status: OrderStatusType;
    },
    any
  ]
>;

export type PacsGalleryDetailType = {
  accessionId: string | null;
  name: string;
  items: { id: number; image: string; description: string }[];
};

export type ReportActionStatusType =
  | "EDIT"
  | "REPORT"
  | "APPROVE"
  | "UNAPPROVE";

export type ActionStatusType =
  | "REGISTER"
  | "EXECUTE"
  | "UNEXECUTE"
  | "READY_TO_VIEW"
  | "UNREADY_TO_VIEW";

export type MasterOptionsType = Record<
  (typeof Masters)[number][0],
  OptionType[]
>;

type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
};

// Sequence
type SeqState = {
  sequence: "ImagingResult";
  restart?: boolean;
  clear?: boolean;
  encounterId?: number | null;
  orderStatus?: OrderStatusType;
};

// Common Params
type LoadCheck = {
  card: string;
  errorKey?: string;
  btnAction?: string;
  action?: string;
};

// Handle Action
type ActionType =
  // Search
  | {
      action: "SEARCH";
      encounterId?: number | null;
      orderStatus?: OrderStatusType;
    }
  // Action
  | { action: "SELECT_ORDER"; data: ImagingQueueSerializer }
  | { action: "OPEN_PACS"; card: string; data: ImagingQueueSerializer }
  | { action: "CLOSE_ORDER" }
  | {
      action: "MOD_XRAY";
      data?: ModXrayDetailType;
      type: "OPEN" | "CLOSE";
    }
  | {
      action: "GET_AUDIT_LOG";
      orderItemId: number;
    }
  // Method
  | {
      action: "EDIT";
      card: string;
      actionType: ReportActionStatusType;
    };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(
  params: SeqType<Pick<SeqAct, K>>
) => any;

type CustomExtract<T, U> = T extends T
  ? U extends Partial<T>
    ? T
    : never
  : never;

type Params<A extends ActionType["action"]> = CustomExtract<
  ActionType,
  { action: A }
>;

export const StateInitial: State = {
  // sequence
  ImagingResultSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

const Masters = [
  ["division", {}],
  ["eligibilityType", {}],
] as const;

export const DIAGNOSIS_SEARCH_ID = "Diagnosis_IR";

type Handler<P = any, R = any> = (
  controller: WasmController<State & Picked, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  } as any);

  GetClinicalFindingList(controller, {});

  GetOrgan(controller, {});

  controller.setState(
    {
      ImagingResultSequence: {
        ...state.ImagingResultSequence,
        sequenceIndex: "Action",
      },
    },
    () => Action(controller, { ...params, action: "SEARCH" })
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  if (params.action === "SEARCH") {
    HandleSearch(controller, params);
  } else if (params.action === "SELECT_ORDER") {
    HandleSelectOrder(controller, params);
  } else if (params.action === "EDIT") {
    HandleEditReport(controller, params);
  } else if (params.action === "OPEN_PACS") {
    HandleOpenPacs(controller, params);
  } else if (params.action === "CLOSE_ORDER") {
    HandleCloseOrder(controller, params);
  } else if (params.action === "MOD_XRAY") {
    HandleModDetail(controller, params);
  } else if (params.action === "GET_AUDIT_LOG") {
    HandleGetAuditLog(controller, params);
  }
};

const HandleSearch: Handler<Params<"SEARCH">> = async (controller, params) => {
  let state = controller.getState();

  const orderStatus = Object.fromEntries(
    Object.entries(ORDER_ITEM_STATUS_LABEL).map(([k, v]) => [v, k])
  );

  const [result] = await ImagingQueueList.list({
    apiToken: controller.apiToken,
    params: {
      encounter: state.selectedEncounter?.id || params.encounterId,
      status: orderStatus[params.orderStatus || ""],
      exclude_cancel: true,
    },
  });

  state = controller.getState();

  // เมื่อกด ปุ่ม report entry จากหน้า imaging worklist
  if (!!state.imagingResultEditData) {
    Action(controller, {
      action: "SELECT_ORDER",
      data: state.imagingResultEditData,
    });
  }

  controller.setState({
    ImagingResultSequence: {
      ...state.ImagingResultSequence,
      sequenceIndex: "Action",
      orderList: result?.items || [],
      readOnly: state.imagingResultEditData?.readOnly,
    },
    imagingResultEditData: null,
  });
};

const HandleSelectOrder: Handler<Params<"SELECT_ORDER">> = async (
  controller,
  params
) => {
  const [[result], [detail]] = await Promise.all([
    ImagingOrderResultReportList.list({
      apiToken: controller.apiToken,
      params: {
        order_item: params.data.id,
      },
    }),
    ImagingOrderDetail.retrieve({
      apiToken: controller.apiToken,
      pk: params.data.order,
    }),
  ]);

  const [medicalRecord] = await EncounterMedicalRecordList.list({
    apiToken: controller.apiToken,
    pk: detail.encounter,
  });

  const medicalRecordId = medicalRecord?.items?.[0]?.id;

  const [[diagnosis], [icd10KeyUp]] = await Promise.all([
    DiagnosisMedicalRecordDetail.retrieve({
      apiToken: controller.apiToken,
      pk: medicalRecordId,
    }),
    GetIcd10KeyUp(controller, {
      method: "icdcode",
      search: detail.suspected_diagnosis_code,
    }),
  ]);

  const state = controller.getState();

  const items: any[] = result?.items || [];
  const icd10 = icd10KeyUp?.response?.[0];

  controller.setState({
    searchedItemListWithKey: {
      ...state.searchedItemListWithKey,
      [DIAGNOSIS_SEARCH_ID]: icd10
        ? [
            {
              ...icd10,
              id: icd10.icd10_id,
              name: `[${icd10.icdcode}] ${icd10.icdterm}`,
            },
          ]
        : [],
    },
    ImagingResultSequence: {
      ...state.ImagingResultSequence,
      selectedOrder: params.data,
      reportDetail: !!items[0]
        ? { ...items[0] }
        : {
            result_summary: 2,
            order_item: params.data.id,
          },
      imagingItemDetail: detail || {},
      principalDiagnosis: diagnosis?.principal_diagnosis?.[0] || {},
    },
  });
};

const HandleEditReport: Handler<Params<"EDIT">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}_${params.actionType}`]: "LOADING",
    },
  });

  const imaging = state.ImagingResultSequence || {};
  const detail = imaging.reportDetail || {};

  const result = await CreateUpdateImagingResult(controller, {
    data: detail,
    actionType: params.actionType,
  });

  if (result[1]) {
    SetErrorMessage(controller, {
      ...params,
      btnAction: `${params.action}_${params.actionType}`,
      error: result[1],
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}_${params.actionType}`]: "SUCCESS",
      },
    });

    Action(controller, { action: "SEARCH" });

    if (!!imaging.selectedOrder) {
      Action(controller, {
        action: "SELECT_ORDER",
        data: {
          ...imaging.selectedOrder,
          status: result[0].order_item_status,
        },
      });
    }
  }
};

const HandleOpenPacs: Handler<Params<"OPEN_PACS">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const detail = await GetOrderItemImageList(controller, params);

  controller.setState({
    ImagingResultSequence: {
      ...state.ImagingResultSequence,
      pacsGalleryDetail: detail,
    },
  });
};

const HandleCloseOrder: Handler<Params<"CLOSE_ORDER">> = (controller) => {
  const state = controller.getState();

  controller.setState({
    ImagingResultSequence: {
      ...state.ImagingResultSequence,
      selectedOrder: null,
      imagingItemDetail: null,
      reportDetail: {},
      principalDiagnosis: null,
    },
    searchedItemListWithKey: {
      ...state.searchedItemListWithKey,
      [DIAGNOSIS_SEARCH_ID]: [],
    },
  });
};

const HandleModDetail: Handler<Params<"MOD_XRAY">> = async (
  controller,
  params
) => {
  HandleModXrayDetail(controller, params);
};

const HandleGetAuditLog: Handler<Params<"GET_AUDIT_LOG">> = async (
  controller,
  params
) => {
  const list = await GetAuditLogList(controller, params);

  const state = controller.getState();

  controller.setState({
    ImagingResultSequence: {
      ...state.ImagingResultSequence,
      auditLogList: list,
    },
  });
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */

export const CreateUpdateImagingResult: Handler<
  {
    data: Partial<ImagingOrderResultSerializer>;
    actionType: ReportActionStatusType;
    orderItemId?: number;
  },
  CUIRResponse
> = async (controller, params) => {
  let data = params.data;

  if (!!params.orderItemId) {
    const [report] = await ImagingOrderResultReportList.list({
      apiToken: controller.apiToken,
      params: {
        order_item: params.orderItemId,
      },
    });

    data = {
      ...(report?.items?.[0] || {}),
      ...data,
    };
  }

  const api = !!data.id
    ? ImagingOrderResultReportList.create
    : ImagingOrderResultReportList.create;

  return api({
    apiToken: controller.apiToken,
    // pk: data.id,
    data: {
      action: params.actionType,
      ...data,
    } as any,
    extra: {
      division: controller.data.division,
      device: controller.data?.device,
    },
  }).then(([res, err]: any) => [
    {
      ...(res || {}),
      order_item: data.order_item,
      status: res?.order_item_status,
    },
    err,
  ]);
};

export const GetAuditLogList: Handler<{ orderItemId: number }> = async (
  controller,
  params
) => {
  const result = await ImagingOrderItemActionLogList.list({
    apiToken: controller.apiToken,
    order_item_id: params.orderItemId,
  });

  return (result[0]?.items || []).filter(
    (item: any) =>
      !["READY_TO_VIEW", "UNREADY_TO_VIEW"].includes(item.action_name)
  );
};

export const GetOrderItemImageList: Handler<
  Params<"OPEN_PACS"> & { btnAction?: string }
> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction || params.action}`]: "LOADING",
    },
  });

  const result = await ImagingOrderItemImageList.list({
    apiToken: controller.apiToken,
    pk: params.data.id,
  });

  const name = params.data.product_name;
  const items: any[] = result[0]?.items || [];

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction || params.action}`]: null,
    },
  });

  return {
    accessionId: params.data.accession_id || "AC511801213",
    name: params.data.product_name,
    items: !!items.length
      ? []
      : [
          {
            id: 1,
            image:
              "https://www.imaginghealthcare.com/wp-content/uploads/2020/06/x-ray.jpg",
            description: `${name}/imaging no.1`,
          },
          {
            id: 2,
            image:
              "https://hai.stanford.edu/sites/default/files/styles/media/public/2022-11/chestxraysstablediffusion.jpg?itok=NYm-q7Yk",
            description: `${name}/imaging no.2`,
          },
        ],
  };
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */

const SetErrorMessage: Handler<LoadCheck & { error: any }> = (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction || params.action}`]: "ERROR",
    },
    errorMessage: {
      ...state.errorMessage,
      [params.errorKey || params.card]: params.error,
    },
  });
};
