import WasmController from "react-lib/frameworks/WasmController";
import ShippingOrderDrugView from "issara-sdk/apis/ShippingOrderDrugView_apps_PRX";
import ShippingOrderPerformed from "issara-sdk/apis/ShippingOrderPerformedDetail_apps_PRX";
import moment from "moment";

export type State = {
  // Seq
  DashboardTelepharSequence?: {
    sequenceIndex?: string | null;
    drugOrderQueue?: any[] | null;
    searchLoading?: boolean | null;
    abortController?: any | null;
    buttonLoadCheck?: string | null;
  } | null;
  selectedDivision?: any | null;
  selectedEncounter?: any | null;
  selectedPatient?: any | null;
};

export const StateInitial: State = {
  DashboardTelepharSequence: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  user?: number;
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  console.log("DashboardTelephar Start params: ", params);
  let state = controller.getState();
  if (!state.DashboardTelepharSequence) return;

  // Master data
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["division", {}],
        ["drugOrderType", {}],
        ["drugOrderStatus", {}],
        ["shippingCompany", {}],
        ["divisionTypeDrug", {}],
      ],
    },
  });

  console.log("selectedEncounter: null");
  controller.setState({
    DashboardTelepharSequence: {
      ...state.DashboardTelepharSequence,
      sequenceIndex: "Action",
      drugOrderQueue: []
    },
    selectedEncounter: null,
    selectedPatient: null,
  });
};

export const Action: Handler = async (controller, params) => {
  console.log("DashboardTelephar Action params: ", params);
  let state = controller.getState();
  if (params.action === "search") {

    if (!state.DashboardTelepharSequence) {
      console.warn("SetDrugOrderQueueSequence are null return");
      return;
    }

    controller.setState({
      DashboardTelepharSequence: {
        ...state.DashboardTelepharSequence,
        searchLoading: true,
      },
    });

    if (params?.action === "search" && params.query) {
      // Issue 59616
      var startDate = "";
      var endDate = "";
      if (params.query.startDate) {
        let a = params.query.startDate.split("/");
        a[2] = a[2] - 543;
        a = a.join("/");
        startDate = moment(a, "DD/MM/YYYY").zone("+0700").toISOString(true);
      }

      if (params.query.endDate) {
        let a = params.query.endDate.split("/");
        a[2] = a[2] - 543;
        a = a.join("/");
        endDate = moment(a, "DD/MM/YYYY").zone("+0700").add(1,"day").toISOString(true);
      }

      let urlParams = {
        ...(params.query.patientName && { search: params.query.patientName }),
        // ...(params.query.firstName && {first_name: params.query.firstName}), // Obsolete
        // ...(params.query.lastName && {last_name: params.query.lastName}), // Obsolete
        ...(params.query.hn && { hn: params.query.hn }),
        ...(params.query.patientType && {
          patient_type: params.query.patientType,
        }),
        ...(startDate && { start: startDate }),
        ...(endDate && { end: endDate }),
        ...(params.query.en && { en: params.query.en }),
        limit: 200,
        // ...(params.query.deliveryType && {delivery_type: params.query.deliveryType}),
        ...(params.query.deliveryStatus && {delivery_status: params.query.deliveryStatus}),
        ...(params.query.deliveryNo && {tracking_number: params.query.deliveryNo}),
        // ...(params.query.consultStatus && {consult_status: params.query.consultStatus}),
        // ...(params.query.orderPerformDiv && {order_perform_div: params.query.orderPerformDiv}),
        // ...(!params.query.orderPerformDiv && {order_perform_div: state.selectedDivision.id}),
        // shipping_company: ""
      };

      let abortController = new AbortController()
      // console.log('state.DashboardTelepharSequence?.abortController abortController: ', abortController);
      // console.log('state.DashboardTelepharSequence?.abortController: ', state.DashboardTelepharSequence?.abortController);
      if (state.DashboardTelepharSequence?.abortController?.signal?.aborted !== undefined && !state.DashboardTelepharSequence?.abortController?.signal?.aborted)  {
        console.log('state.DashboardTelepharSequence?.abortController.abort()' );
        state.DashboardTelepharSequence?.abortController.abort()
      }
      // console.log('state.DashboardTelepharSequence?.abortController: ', state.DashboardTelepharSequence?.abortController);

      controller.setState({
        DashboardTelepharSequence: {
          ...state.DashboardTelepharSequence,
          abortController: abortController
        },
      });

      const [shippingOrderDrug, shippingOrderDrugError] =
        await ShippingOrderDrugView.list({
          params: urlParams,
          apiToken: controller.apiToken,
          extra : { 
            signal: abortController.signal
          }
        });

      state = controller.getState();

      if (shippingOrderDrugError) {
        console.log("Drug queue error fetching");
        controller.setState({
          DashboardTelepharSequence: {
            ...state.DashboardTelepharSequence,
            drugOrderQueue: [],
            searchLoading: false,
          },
        });
        return;
      }
      let drugOrderQueueFilter = (shippingOrderDrug?.items || [])
      
      console.log('params.deliveryType: ', params.deliveryType);
      if ( params.query?.deliveryType ) {
        
        // params.deliveryType === 1 : ส่งด่วน
        // params.deliveryType === 2 : ส่งธรรมดา
        
        // get Lala
        if ( controller?.data?.masterData?.shippingCompany?.length > 0) {
          let lalaCompanyId = controller.data?.masterData?.shippingCompany?.find((item: any) => item.code === "Lala")?.id
            console.log('lalaCompanyId: ', lalaCompanyId);

          if (params.query?.deliveryType == lalaCompanyId) {
            // Lala
            drugOrderQueueFilter = drugOrderQueueFilter.filter((item: any) => item.shipping_order?.shipping_company?.id === lalaCompanyId)
            console.log("lala")
          } else {
            // Not Lala 
            drugOrderQueueFilter = drugOrderQueueFilter.filter((item: any) => item.shipping_order?.shipping_company?.id !== lalaCompanyId)
            console.log("no lala")
          }
        }
      }
      console.log('drugOrderQueueFilter: ', drugOrderQueueFilter);

      controller.setState({
        DashboardTelepharSequence: {
          ...state.DashboardTelepharSequence,
          drugOrderQueue: drugOrderQueueFilter,
          searchLoading: false,
        },
      });
    }
  } else if (params.action === "clear") {
    console.log("Clear Dashboard Data")
    controller.setState({
      DashboardTelepharSequence: {
        ...state.DashboardTelepharSequence,
        drugOrderQueue: [],
      },
    });
  } else if (params.action === "updateRemark") {
    let state = controller.getState();
    controller.setState({
      DashboardTelepharSequence: {
        ...state.DashboardTelepharSequence,
        buttonLoadCheck: "LOADING"
      },
    });
      const [res, err, network] = await ShippingOrderPerformed.patch( {
      apiToken: controller.apiToken,
      pk: params.pk,
      data: { ...(params.remarkStr && {
        remark: params.remarkStr
      })
      }
    })

    if (res) {
      controller.setState({
        DashboardTelepharSequence: {
          ...state.DashboardTelepharSequence,
          buttonLoadCheck: "SUCCESS"
        },
      });
    } else {
      controller.setState({
        DashboardTelepharSequence: {
          ...state.DashboardTelepharSequence,
          buttonLoadCheck: "ERROR"
        },
      });
    }
  }
};
