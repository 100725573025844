import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardFallRiskAssessmentUX = (props: any) => {
    return(
      <div
        style={{padding: "5px"}}>
        <div
          style={{display: "flex" }}>
          
          <div
            style={{padding: "5px", fontSize: "1.2rem", fontWeight: "bold"}}>
            Fall Risk Assessment
          </div>
          <div
            style={{flex: 1}}>
            
          </div>
        </div>
        <Table
          data={props.data}
          headers="Date,User,Fall Risk Assessment,Nursing,Delete"
          keys="date,user_fullname,fall_risk_description,nursing,delete"
          minRows="3"
          showPagination={false}
          style={{height: "150px"}}>
        </Table>
        <div
          style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(8, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
          <div
            style={{ gridRow: "2/4", gridColumn: "2/8", }}>
            Fall Risk Assessment
          </div>
          <div
            style={{ gridRow: "5/7", gridColumn: "2/8", }}>
            การให้การพยาบาลผู้ป่วย
          </div>
          <div
            style={{ gridRow: "2/4", gridColumn: "9/19", }}>
            <Dropdown
              compact={true}
              onChange={props.changeFallRisk("fall_risk")}
              options={props.fallRiskOptions}
              selection={true}
              style={{height: "100%", width:"100%"}}
              value={props.fall_risk}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "5/7", gridColumn: "9/35", }}>
            <Dropdown
              compact={true}
              multiple={true}
              onChange={props.changeFallRisk("nursing")}
              options={props.fallRiskNursingOptions}
              selection={true}
              style={{height: "100%", width:"100%"}}
              value={props.nursing}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "5/7", gridColumn: "40/45", }}>
          </div>
          <div
            style={{ gridRow: "5/7", gridColumn: "46/51", }}>
            <Button
              color="blue"
              onClick={props.onReassess}>
              Reassessment
            </Button>
          </div>
        </div>
      </div>
    )
}


export default CardFallRiskAssessmentUX

export const screenPropsDefault = {}

/* Date Time : Tue May 23 2023 04:16:24 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(8, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Fall Risk Assessment"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"2/8\", }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "การให้การพยาบาลผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"2/8\", }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"9/19\", }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"9/35\", }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Dropdown",
      "parent": 11,
      "props": {
        "compact": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeFallRisk(\"fall_risk\")"
        },
        "options": {
          "type": "code",
          "value": "props.fallRiskOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.fall_risk"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Dropdown",
      "parent": 12,
      "props": {
        "compact": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeFallRisk(\"nursing\")"
        },
        "options": {
          "type": "code",
          "value": "props.fallRiskNursingOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.nursing"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Fall Risk Assessment"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\", fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 17,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "headers": {
          "type": "value",
          "value": "Date,User,Fall Risk Assessment,Nursing,Delete"
        },
        "keys": {
          "type": "value",
          "value": "date,user_fullname,fall_risk_description,nursing,delete"
        },
        "minRows": {
          "type": "value",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"150px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Button",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "Reassessment"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onReassess"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"40/45\", }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"46/51\", }"
        }
      },
      "seq": 23,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 60,
  "isMounted": false,
  "memo": false,
  "name": "CardFallRiskAssessmentUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 75
}

*********************************************************************************** */
