import React, { useEffect, useState, useMemo } from "react";
import CardDrugVerbalOrderUX from "./CardDrugVerbalOrderUX";

const CardDrugVerbalOrder = (props: any) => {

  return (
    <div>
      <CardDrugVerbalOrderUX
        setProp={props.setProp}
        onEvent={props.onEvent}
        drugTypeOptions={props.drugTypeOptions}
        drugTypeChange={props.drugTypeChange}
        orderDivOptions={props.orderDivOptions}
        orderDivChange={props.orderDivChange}
        orderDiv={props.orderDiv}
        noteChange={props.noteChange}
      />
    </div>
  );
};

export default CardDrugVerbalOrder;
