import React, { useEffect, useState, CSSProperties, useRef } from "react";
import { Modal } from "semantic-ui-react";
import moment from 'moment';
import { momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import SearchBox from "react-lib/appcon/common/SearchBox";
import CardDoctorScheduleUX from "./CardDoctorScheduleUX";
import ModSchedulingUX from "./ModSchedulingUX";
import { DsbStatus } from "./Model";

moment.locale('en-es', { week: { dow: 1 } });
const localizer = momentLocalizer(moment);

const DentistSchedule = (props: any) => {

  const [showModal, setShowModal] = useState(false);
  const [event, setEvent] = useState<any>(null);
  const [inactive, setInactive] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<any>(null);

  useEffect (() => {
    props.runSequence({sequence: "SetSchedule", restart: true});
  }, []);

  useEffect (() => {
    setSelectedProvider(props.selectedProvider);
  }, [props.selectedProvider])

  // Prepare blockList for rendering
  const blockList = 
    selectedProvider === null ? (props.SetScheduleSequence?.blockList || [])
    : 
    (props.SetScheduleSequence?.blockList || [])
    .map((item: any) => ({
      ...item,
      providers: item.providers
                      .filter((item: any) => 
                              item.provider_id == selectedProvider?.provider_id)
                      .map((item: any) => item.dsb)
    }));

  console.log(blockList);

  // Handler for calendar select event, double click
  const handleSelectTimeout = useRef(0);
  const handleSelectEvent = (
    event: any, e: React.SyntheticEvent<HTMLElement, globalThis.Event>
  ) => {
    // Save current target in case we need to use it
    const currentTarget = e.currentTarget;
    if (!handleSelectTimeout.current) {
      handleSelectTimeout.current = setTimeout(() => {
        handleSelectTimeout.current = 0;
        console.log(event);
        setShowModal(true);
        setEvent(event);
        setInactive(event?.providers?.[0]?.status === DsbStatus.INACTIVE);
      }, 200) as any;
    } else {
      clearTimeout(handleSelectTimeout.current);
      handleSelectTimeout.current = 0; 
      console.log(event);
      console.log(event?.providers?.[0]?.status);
      props.runSequence({
        sequence: "SetSchedule", 
        action: "AddBlock", 
        event: event, 
        selectedProvider: selectedProvider, 
        inactive: (typeof event?.providers?.[0]?.status !== "undefined"
                     && event?.providers?.[0]?.status !== DsbStatus.INACTIVE)
      });
    }
  };

  return(
    <div style={{height: "90vh", padding: "5px"}}>
      <CardDoctorScheduleUX
        // Select provider
        selectedProvider={selectedProvider}
        providerSelector={
          <SearchBox
            type="Provider"
            vertical={true}
            style={{width: "100%"}}
            fluid={true}
            label="แพทย์"
            placeholder="ค้นหาชื่อแพทย์"
            toDisplay={(item: any) => `${item.first_name} ${item.last_name}`}
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={selectedProvider}
            setSelectedItem={props.selectedProvider ? () => {} : setSelectedProvider}
          />}
        
        // Block
        localizer={localizer}
        
        blockList={selectedProvider ? blockList : []}
        eventPropGetter={(event: any, start: Date, end: Date, isSelected: boolean) => {
          return { 
            style: event?.providers?.length === 0 ? 
                   {backgroundColor: "pink", color: "black"} 
                   : 
                   event?.providers?.[0]?.status === 2 ?
                   {backgroundColor: "#cccccc", color: "black"}
                   :
                   {} as CSSProperties
          }
        }}
        onSelectEvent={handleSelectEvent}
      />
      <Modal
        open={showModal}
        closeOnDimmerClick={true}
        onClose={() => setShowModal(false)}>
        <ModSchedulingUX
          data={event ? [event] : []}
          addBlock={(e: any) => {
            props.runSequence({
              sequence: "SetSchedule", 
              action: "AddBlock", 
              event: event, 
              selectedProvider: selectedProvider, 
              inactive: inactive
            });
            setShowModal(false);
          }}
          
          // Inactive
          inactive={inactive}
          changeInactive={() => setInactive(!inactive)}
        />
      </Modal>
    </div>
  )
}

export default DentistSchedule;