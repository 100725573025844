import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Input,
  Dropdown,
  Radio
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const ANES_PACU_Post = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", padding:"10px"}}>
        <div>
          
          <div
            style={{ gridRow: "1/2", gridColumn: "1/3",marginLeft:"10px",marginTop:"10px", justifyContent: "space-between", display: "flex", alingItem: "center", marginBottom: "10px"}}>
            <div
              style={{ display: "flex" ,fontWeight:"Bold",fontSize:"18px",marginBottom:"10px"}}>
              Post Anesthetic Care Unit Record
            </div>
            <div
              style={{display: "flex"}}>
              
              <div>
                {props.buttonLog}
              </div>
              <div
                style={{ display: "none" }}>
                {props.buttonPrint}
              </div>
              <div
                style={{ display: props.buttonStatus === "CONFIRMED" ?  "none" : "",}}>
                {props.buttonSave}
              </div>
              <div
                style={{ display: props.buttonStatus === "CONFIRMED" ?  "none" : "",}}>
                {props.buttonConfirm}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",height:"50%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            className="ui form">
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  Date
                </label>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{ width: "100%" }}>
                  
                  <DateTextBox
                    onChange={props.onChangeDateForm}
                    style={{ display: "flex" ,height:"100%" }}
                    value={props.PACURecordSequence?.data?.DateRamrk || ""}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center", paddingLeft: "60px"}}>
                  OR Location
                </label>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Input
                  name="ORRmark"
                  onChange={props.onChangeData}
                  style={{ display: "flex" ,height:"90%",width:"100%"}}
                  value={props.PACURecordSequence?.data?.ORRmark || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center", paddingLeft: "60px"}}>
                  Patient location
                </label>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <Radio
                  checked={props.PACURecordSequence?.data?.patientLocation === "IsOPD"}
                  name="patientLocation"
                  onChange={props.onChangeData}
                  value="IsOPD">
                </Radio>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  OPD
                </label>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <Radio
                  checked={props.PACURecordSequence?.data?.patientLocation === "IsWard"}
                  name="patientLocation"
                  onChange={props.onChangeData}
                  value="IsWard">
                </Radio>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Ward
                </label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Input
                  disabled={props.PACURecordSequence?.data?.patientLocation === "IsWard"}
                  name="IsWardRemark"
                  onChange={props.onChangeData}
                  style={{ display: "none" ,height:"100%",width:"100%"}}
                  value={props.PACURecordSequence?.data?.IsWardRemark || ""}>
                </Input>
                <Dropdown
                  clearable={true}
                  disabled={props.PACURecordSequence?.data?.patientLocation !== "IsWard"}
                  fluid={true}
                  name="IsWardRemark"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.divisionWard}
                  search={true}
                  selection={true}
                  style={{ display: "flex" ,height:"100%",width:"100%"}}
                  value={props.PACURecordSequence?.data?.patientLocation !== "IsWard" ? "" :  props.PACURecordSequence?.data?.IsWardRemark || ""}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  PACU Time : Start
                </label>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{ width: "100%" }}>
                  
                  <DateTextBox
                    onChange={props.onChangeStartDate}
                    value={props.PACURecordSequence?.data?.StartDate || ""}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div>
                  {props.startTime}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center", paddingLeft: "60px"}}>
                  End
                </label>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{ width: "100%" }}>
                  
                  <DateTextBox
                    onChange={props.onChangeEndDate}
                    value={props.PACURecordSequence?.data?.EndDate || ""}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div>
                  {props.endTime}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center", paddingLeft: "60px"}}>
                  Total time
                </label>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Input
                  name="PACUDurartionTime"
                  onChange={props.onChangeData}
                  readOnly={true}
                  style={{ display: "flex" ,height:"90%",width:"100%"}}
                  value={props.PACURecordSequence?.data?.PACUDurartionTime || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center",marginLeft:"5px"}}>
                  min
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  Anesthesia team
                </label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Input
                  name="AnesthesiaTeamRemark"
                  onChange={props.onChangeData}
                  style={{ display: "flex" ,height:"100%",width:"100%"}}
                  value={props.PACURecordSequence?.data?.AnesthesiaTeamRemark || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center", paddingLeft: "60px"}}>
                  Diagnosis
                </label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Input
                  name="DiagnosisRemark"
                  readOnly={true}
                  style={{ display: "flex" ,height:"100%",width:"100%"}}
                  value={props.PACURecordSequence?.data?.DiagnosisRemark || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  Anesthetic agents
                </label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    multiple={true}
                    name="AnestheticAgentsRemark"
                    onChange={props.onChangeData}
                    options={props.masterOptions?.drugName}
                    search={true}
                    selection={true}
                    value={props.PACURecordSequence?.data?.AnestheticAgentsRemark || ""}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center", paddingLeft: "60px"}}>
                  Operation
                </label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Input
                  name="OperationRemark"
                  style={{display: "flex" ,height:"100%",width:"100%"}}
                  value={props.PACURecordSequence?.data?.OperationRemark || ""}>
                </Input>
              </FormField>
            </FormGroup>
          </div>
        </div>
      </div>
    )
}


export default ANES_PACU_Post

export const screenPropsDefault = {}

/* Date Time : Tue May 23 2023 04:16:28 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",height:\"50%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 69,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/3\",marginLeft:\"10px\",marginTop:\"10px\", justifyContent: \"space-between\", display: \"flex\", alingItem: \"center\", marginBottom: \"10px\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "Post Anesthetic Care Unit Record"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,fontWeight:\"Bold\",fontSize:\"18px\",marginBottom:\"10px\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonLog"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.buttonStatus === \"CONFIRMED\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.buttonStatus === \"CONFIRMED\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "FormGroup",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "FormField",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormField",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "FormField",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "FormField",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "FormField",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "FormField",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "label",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "Date"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "label",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": "OR Location"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\", paddingLeft: \"60px\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "label",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "Ward"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "label",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient location"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\", paddingLeft: \"60px\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Input",
      "parent": 100,
      "props": {
        "name": {
          "type": "value",
          "value": "ORRmark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.ORRmark || \"\""
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 112,
      "name": "DateTextBox",
      "parent": 111,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDateForm"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.DateRamrk || \"\"",
          "valueEN": ""
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "label",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": "OPD"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "Input",
      "parent": 104,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.patientLocation === \"IsWard\""
        },
        "name": {
          "type": "value",
          "value": "IsWardRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" ,height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsWardRemark || \"\""
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "FormGroup",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "FormField",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "FormField",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormField",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "FormField",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "FormField",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "FormField",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "label",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": "PACU Time : Start"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "label",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": "End"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\", paddingLeft: \"60px\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "label",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": "Total time"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\", paddingLeft: \"60px\"}"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "label",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": "min"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\",marginLeft:\"5px\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 131,
      "name": "Input",
      "parent": 123,
      "props": {
        "name": {
          "type": "value",
          "value": "PACUDurartionTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.PACUDurartionTime || \"\""
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "FormGroup",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormGroup",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "FormField",
      "parent": 132,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "FormField",
      "parent": 132,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormField",
      "parent": 132,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormField",
      "parent": 132,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "label",
      "parent": 135,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesia team"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "label",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": "Diagnosis"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\", paddingLeft: \"60px\"}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "label",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthetic agents"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "label",
      "parent": 140,
      "props": {
        "children": {
          "type": "value",
          "value": "Operation"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\", paddingLeft: \"60px\"}"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "Input",
      "parent": 141,
      "props": {
        "name": {
          "type": "value",
          "value": "OperationRemark"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\" ,height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.OperationRemark || \"\""
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "Input",
      "parent": 138,
      "props": {
        "name": {
          "type": "value",
          "value": "DiagnosisRemark"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.DiagnosisRemark || \"\""
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "Input",
      "parent": 136,
      "props": {
        "name": {
          "type": "value",
          "value": "AnesthesiaTeamRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.AnesthesiaTeamRemark || \"\""
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "Dropdown",
      "parent": 149,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AnestheticAgentsRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.drugName"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.AnestheticAgentsRemark || \"\""
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startTime"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 121,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endTime"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "FormField",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 155,
      "name": "DateTextBox",
      "parent": 154,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartDate"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.StartDate || \"\""
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "FormField",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 158,
      "name": "DateTextBox",
      "parent": 157,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.EndDate || \"\""
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "Radio",
      "parent": 102,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.patientLocation === \"IsOPD\""
        },
        "name": {
          "type": "value",
          "value": "patientLocation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "IsOPD"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "Radio",
      "parent": 103,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.patientLocation === \"IsWard\""
        },
        "name": {
          "type": "value",
          "value": "patientLocation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "IsWard"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "Dropdown",
      "parent": 104,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.patientLocation !== \"IsWard\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "IsWardRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.divisionWard"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.patientLocation !== \"IsWard\" ? \"\" :  props.PACURecordSequence?.data?.IsWardRemark || \"\""
        }
      },
      "seq": 161,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PACU_Post",
  "project": "New Project",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
