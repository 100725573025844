import WasmController from "react-lib/frameworks/WasmController";
//apis
import DrugReturnRequestList from "issara-sdk/apis/DrugReturnRequestList_apps_TPD";
import DrugReturnRequestDetail from "issara-sdk/apis/DrugReturnRequestDetail_apps_TPD";

export type State = {
  errorMessage?: any;
  successMessage?: any;

  DrugReturnRequestHistorySequence?: {
    sequenceIndex?: string | null;
    selectedDivisionReturn?: any | null;
    selectedDivisionReceive?: any | null;
    selectedStartDate?: string | null;
    selectedEndDate?: string | null;
    selectedStatus?: string | null;
    selectedPatient?: string | null;
    checkedStatus?: boolean | null;
    checkedPatient?: boolean | null;
    checkedDivisionReturn?: boolean | null;
    checkedDivisionReceive?: boolean | null;
    checkedFilterDate?: boolean | null;
    selectedBox?: any;
  } | null;
  DrugReturnRequestHistoryList?: any;
};

export const StateInitial: State = {
  DrugReturnRequestHistorySequence: null,
  DrugReturnRequestHistoryList: [],
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  var state = controller.getState();
  if (!state.DrugReturnRequestHistorySequence) return;

  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["division", {}],
        ["drugStatus", {}],
      ],
    },
  });

  state = controller.getState();

  controller.setState({
    DrugReturnRequestHistorySequence: {
      ...state.DrugReturnRequestHistorySequence,
      sequenceIndex: "SearchDrugReturnRequestHistory",
    },
  });
};

export const SearchDrugReturnRequestHistory: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  if (params?.action === "search") {
    HandleSearch(controller, params);
  } else if (params?.action === "clear") {
    controller.setState({
      DrugReturnRequestHistorySequence: {
        ...state.DrugReturnRequestHistorySequence,
        sequenceIndex: "SearchDrugReturnRequestHistory",
        checkedDivisionReceive: false,
        checkedDivisionReturn: false,
        checkedFilterDate: false,
        checkedPatient: false,
        checkedStatus: false,
        selectedDivisionReceive: "แสดงทุกหน่วยงาน",
        selectedDivisionReturn: "แสดงทุกหน่วยงาน",
        selectedEndDate: null,
        selectedPatient: null,
        selectedStartDate: null,
        selectedStatus: "แสดงทุกสถานะ",
      },
      DrugReturnRequestHistoryList: { ...state.DrugReturnRequestHistoryList },
    });
  } else if (params.action === "SET_SELECTED") {
    const state = controller.getState();

    controller.setState({
      errorMessage: { ...state.errorMessage, [params?.card]: "" },
      DrugReturnRequestHistorySequence: {
        ...state.DrugReturnRequestHistorySequence,
        selectedBox: params.selectedBox,
      },
    });
  } else if (
    params.action === "UNAPPROVE" ||
    params.action === "APPROVE" ||
    params.action === "REJECT" ||
    params.action === "DELIVER" ||
    params.action === "RECEIVE" ||
    params.action === "EDIT"
  ) {
    const state = controller.getState();
    // controller.setState({
    //   loadingStatus: { ...state.loadingStatus, [params?.card]: true },
    //   buttonLoadCheck: {
    //     ...state.buttonLoadCheck,
    //     [`${params.card}`]: "LOADING",
    //   },
    // });

    let [response, error, network] = [undefined, undefined, undefined];

    [response, error, network] = await DrugReturnRequestDetail.update({
      pk: state.DrugReturnRequestHistorySequence?.selectedBox?.id,
      data: {
        action: params?.action,
        items: state.DrugReturnRequestHistorySequence?.selectedBox?.items,
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    console.log("response", response);

    if (response) {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params?.card]: response,
        },
        errorMessage: { ...state.errorMessage, [params?.card]: "" },
        // loadingStatus: { ...state.loadingStatus, [params?.card]: false },
        // buttonLoadCheck: {
        //   ...state.buttonLoadCheck,
        //   [`${params.card}`]: "SUCCESS",
        // },
      });

      params.onSuccess?.();
    }
    if (error) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.card]: error },
        // loadingStatus: { ...state.loadingStatus, [params?.card]: false },
        // buttonLoadCheck: {
        //   ...state.buttonLoadCheck,
        //   [`${params.card}`]: "ERROR",
        // },
      });
    }
    HandleSearch(controller, {...params, selectedBoxId: state.DrugReturnRequestHistorySequence?.selectedBox?.id});
  } else if (params?.action === "SELECTBOX_EDIT_VALUE") {
    const state = controller.getState();

    let selectedBoxItem = [...state.DrugReturnRequestHistorySequence?.selectedBox?.items || []].map((item: any, idx: number) =>
      item.id === params?.selectedItemId ? { ...item, [params.name]: params.value } : { ...item })

    controller.setState({
      errorMessage: { ...state.errorMessage, [params?.card]: "" },
      DrugReturnRequestHistorySequence: {
        ...state.DrugReturnRequestHistorySequence,
        selectedBox: {
          ...state.DrugReturnRequestHistorySequence?.selectedBox,
          items: [...selectedBoxItem]
        }
      },
    });
  }
};

const HandleSearch: Handler = async (controller, params) => {
  const state = controller.getState();

  const [response, error, network] = await DrugReturnRequestList.list({
    params: {
      patient: state?.DrugReturnRequestHistorySequence?.checkedPatient
        ? state?.DrugReturnRequestHistorySequence?.selectedPatient
        : null,
      status: state?.DrugReturnRequestHistorySequence?.checkedStatus
        ? state?.DrugReturnRequestHistorySequence?.selectedStatus
        : null,
      from_date: state?.DrugReturnRequestHistorySequence?.checkedFilterDate
        ? state?.DrugReturnRequestHistorySequence?.selectedStartDate
        : null,
      to_date: state?.DrugReturnRequestHistorySequence?.checkedFilterDate
        ? state?.DrugReturnRequestHistorySequence?.selectedEndDate
        : null,
      requester: state?.DrugReturnRequestHistorySequence?.checkedDivisionReturn
        ? state?.DrugReturnRequestHistorySequence?.selectedDivisionReturn
        : null,
      receiver: state?.DrugReturnRequestHistorySequence?.checkedDivisionReceive
        ? state?.DrugReturnRequestHistorySequence?.selectedDivisionReceive
        : null,
      offset: 0,
      limit: 40,
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });

  let selectedBox = null
  if(params.selectedBoxId){
    selectedBox = response?.items?.find((item: any) => item?.id === params?.selectedBoxId)
  }

  controller.setState({
    DrugReturnRequestHistorySequence: {
      ...state.DrugReturnRequestHistorySequence,
      ...(params?.selectedBoxId? {selectedBox: selectedBox}: {}),
      sequenceIndex: "SearchDrugReturnRequestHistory",
    },
    DrugReturnRequestHistoryList: response,
  });
};
