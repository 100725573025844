import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Input,
  Button
} from 'semantic-ui-react'

const CardRegSearchUX = (props: any) => {
    return(
      <div
        style={{ display: "flex", flexDirection: "column", flexGrow: 1, overflow: "hidden" }}>
        <div
          style={{ display: "flex", flex: "1 0 auto" }}>
          
          <Input
            id="txt-searchPatient"
            onChange={props.changeSearchText}
            onKeyDown={(e: any) => (e.key === "Enter") && props.onSearch()}
            placeholder="ค้นหาจาก HN, ชื่อ, นามสกุล"
            style={{flex:1}}
            value={props.searchText}>
          </Input>
          <div>
            {props.buttonSearch}
          </div>
          <Button
            color="green"
            id="btn-createPatient"
            onClick={(e:any) => props.onNewPatient()}
            size="small"
            style={{ ...(props.isFinance && { display: "none" }) }}>
            {props.addPatientName}
          </Button>
          <Button
            color="green"
            style={{ ...(props.isFinance && { display: "none" }) }}>
            {props.addPatientGroupName}
          </Button>
          <Button
            basic={true}
            color="green"
            onClick={(e:any) => props.onSmartCard()}
            style={{ ...(props.isFinance && { display: "none" }) }}>
            {props.smartCardName}
          </Button>
          <Button
            color="yellow"
            onClick={props.OnEmergency}
            style={{ marginLeft: "50px",  ...(props.isFinance && { display: "none" }) }}>
            {props.addPatientEmergencyName}
          </Button>
        </div>
        <div
          style={{ display: "flex", flex: "1 1 auto", width: "100%"}}>
          
          <Table
            NoDataComponent={()=> props.NoDataComponent || null}
            data={props.patientList}
            getTrProps={props.patientRowProps}
            headers="HN,ชื่อ,วันเดือนปีเกิด,เลขประจำตัวประชาชน/พาสปอร์ต,ชื่อเดิม,ชื่อมารดา,ที่อยู่ปัจจุบัน,เบอร์โทรศัพท์"
            id="tb-patientInfoSearch"
            keys="hn,full_name,birthdate,citizen_passport,old_name,mother,address,tel_num"
            showPagination={false}
            style={{width: "100%", height: "calc(90vh - 70px)", paddingTop: "5px"}}>
          </Table>
        </div>
        <div
          style={{ display: "flex", flex: "1 0 auto" , margin: "5px", justifyContent: "flex-start"}}>
          {props.paginationComponent}
        </div>
      </div>
    )
}


export default CardRegSearchUX

export const screenPropsDefault = {}

/* Date Time : Tue Jul 25 2023 03:16:55 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flexDirection: \"column\", flexGrow: 1, overflow: \"hidden\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flex: \"1 0 auto\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 7,
      "props": {
        "NoDataComponent": {
          "type": "code",
          "value": "()=> props.NoDataComponent || null"
        },
        "data": {
          "type": "code",
          "value": "props.patientList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.patientRowProps"
        },
        "headers": {
          "type": "value",
          "value": "HN,ชื่อ,วันเดือนปีเกิด,เลขประจำตัวประชาชน/พาสปอร์ต,ชื่อเดิม,ชื่อมารดา,ที่อยู่ปัจจุบัน,เบอร์โทรศัพท์"
        },
        "id": {
          "type": "value",
          "value": "tb-patientInfoSearch"
        },
        "keys": {
          "type": "value",
          "value": "hn,full_name,birthdate,citizen_passport,old_name,mother,address,tel_num"
        },
        "minRows": {
          "type": "value",
          "value": ""
        },
        "resizable": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"calc(90vh - 70px)\", paddingTop: \"5px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Input",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "txt-searchPatient"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSearchText"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any) => (e.key === \"Enter\") && props.onSearch()"
        },
        "placeholder": {
          "type": "value",
          "value": "ค้นหาจาก HN, ชื่อ, นามสกุล"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        },
        "value": {
          "type": "code",
          "value": "props.searchText"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.addPatientName"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "btn-createPatient"
        },
        "onClick": {
          "type": "code",
          "value": "(e:any) => props.onNewPatient()"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.isFinance && { display: \"none\" }) }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "div"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flex: \"1 1 auto\", width: \"100%\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.paginationComponent"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flex: \"1 0 auto\" , margin: \"5px\", justifyContent: \"flex-start\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.addPatientGroupName"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.isFinance && { display: \"none\" }) }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Button",
      "parent": 1,
      "props": {
        "basic": {
          "type": "code",
          "value": "true"
        },
        "children": {
          "type": "code",
          "value": "props.smartCardName"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "(e:any) => props.onSmartCard()"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.isFinance && { display: \"none\" }) }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Button",
      "parent": 1,
      "props": {
        "basic": {
          "type": "value",
          "value": ""
        },
        "children": {
          "type": "code",
          "value": "props.addPatientEmergencyName"
        },
        "className": {
          "type": "code",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.OnEmergency"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"50px\",  ...(props.isFinance && { display: \"none\" }) }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 5,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardRegSearchUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
