import WasmController from 'react-lib/frameworks/WasmController';
import LatestHeight from "issara-sdk/apis/LatestHeight_apps_PTM";
import VitalSignTypeList from "issara-sdk/apis/VitalSignTypeList_apps_PTM";
import MeasurementList from "issara-sdk/apis/MeasurementList_apps_PTM";
import VitalSignValueCreateUpdate from "issara-sdk/apis/VitalSignValueCreateUpdate_apps_PTM";
import MeasurementCancelView from "issara-sdk/apis/MeasurementCancelView_apps_PTM";
import { toast } from "react-toastify";

export type State = 
  {
    selectedEncounter?: any | null,
    buttonLoadCheck?: any,
  }

export const StateInitial: State = 
  {
  }

export type Event = 
    { message: "GetVitalSign", params: {} } 
  | { message: "GetVitalSignType", params: {} }
  | { message: "GetLastHeight", params: {} }
  | { message: "SaveNewMeasurement", params: { data: any } }
  | { message: "AddVitalSignValue", params: { data: any } }
  | { message: "CancelMeasurement", params: { measureId: number, data: any } }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

export const GetVitalSign: Handler = async (controller, params) => {
  if (params.startDate) {
    params.from_date = params.startDate;
  }
  if (params.endDate) {
    params.to_date = params.endDate;
  }
  if (params.startTime) {
    params.from_time = params.startTime;
  }
  if (params.endTime) {
    params.to_time = params.endTime;
  }

  return await MeasurementList.list({
    params: {
      ...params,
      sort_by_seq: true,
      use_patient: true,
      invert_order: true,
      encounter: controller.getState().selectedEncounter.id
    },
    apiToken: controller.apiToken
  });
}

export const GetVitalSignType: Handler = async (controller, params) => {
  return await VitalSignTypeList.list({
    params: { encounter: controller.getState().selectedEncounter.id },
    apiToken: controller.apiToken,
    extra: { division: controller.data.division }
  });
}

export const GetLastHeight: Handler = async (controller, params) => {
  return await LatestHeight.get({
    patient: controller.getState().selectedEncounter.patient_id,
    apiToken: controller.apiToken
  });
}

export const SaveNewMeasurement: Handler = async (controller, params) => {
  const newMeasurement = await MeasurementList.create({
    data: {
      ...params,
      encounter: controller.getState().selectedEncounter.id
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division
    }
  });
  if (newMeasurement[1]) {
    // toast.error("บันทึกไม่สำเร็จ");
    return [newMeasurement[1]];
  } else {
    // toast.success("Success");
    return null;
  }
};

export const AddVitalSignValue: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      ["CardVitalSign_SAVE"]: "LOADING",
    },
  });

  const [vsResponse, vsError] = await VitalSignValueCreateUpdate.create({
    data: params,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division
    }
  })
  if (vsError) {
    // toast.error("บันทึกไม่สำเร็จ");
    // return [vsError];
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        ["CardVitalSign_SAVE"]: "ERROR",
      },
    });
  } else {
    // console.log(vsResponse);
    // toast.success("Success");
    // return null;
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        ["CardVitalSign_SAVE"]: "SUCCESS",
      },
    });
  }
}

export const CancelMeasurement: Handler = async (controller, params) => {
  const [vsResponse, vsError] = await MeasurementCancelView.patch({
    pk: params.measureId,
    data: params.data,
    apiToken: controller.apiToken
  });
  if (vsError) {
    //   toast.error("ลบไม่สำเร็จ");
    console.log(vsError);
    return vsError;
  } else {
    // toast.success("Success");
    return null;
  }
}
