import WasmController from "react-lib/frameworks/WasmController";

// APIs
// QUE
import Scheduling from "issara-sdk/apis/Scheduling_apps_QUE";
// CORE
import icd10KeyUp from "issara-sdk/apis/icd10KeyUp_core";
import PayerList from "issara-sdk/apis/PayerList_core";
import DoctorList from "issara-sdk/apis/DoctorList_core";
import DivisionList from "issara-sdk/apis/DivisionList_core";
import EncounterList from "issara-sdk/apis/EncounterList_core";
import ProductList from "issara-sdk/apis/ProductList_core";
import PackageList from "issara-sdk/apis/PackageList_core";
// REG
import PatientList from "issara-sdk/apis/PatientList_apps_REG";
// TPD
import SubstanceList from "issara-sdk/apis/SubstanceList_apps_TPD";
import VTMList from "issara-sdk/apis/VirtualTherapeuticMoietyList_apps_TPD";
import GenericProductList from "issara-sdk/apis/GenericProductList_apps_TPD";
import TradeProductList from "issara-sdk/apis/TradeProductList_apps_TPD";
import GenericProductUseList from "issara-sdk/apis/GenericProductUseList_apps_TPD";
import TradeProductUseList from "issara-sdk/apis/TradeProductUseList_apps_TPD";
import DrugList from "issara-sdk/apis/DrugList_apps_TPD";
import IngredientList from "issara-sdk/apis/IngredientList_apps_TPD";
import DrugGroupList from "issara-sdk/apis/DrugGroupList_apps_TPD";
// DFC
import DoctorFeeRuleListM from "issara-sdk/apis/DoctorFeeRuleList_apps_DFCM";
// TRT
import TreatmentList from "issara-sdk/apis/TreatmentList_apps_TRT";
// ADM
import RoomList from "issara-sdk/apis/RoomList_apps_ADM";
// USREs
import EmployeeList from "issara-sdk/apis/EmployeeList_users";
import UserList from "issara-sdk/apis/UserList_users";
// ORM
import OperatingTreatmentList from "issara-sdk/apis/OperatingTreatmentList_apps_ORM";
import OperatingItemList from "issara-sdk/apis/OperatingItemList_apps_ORM";
// INF
import ARTransactionList from "issara-sdk/apis/ARTransactionList_apps_INF";
// MSD
import ManufacturerList from "issara-sdk/apis/ManufacturerList_apps_MSD";
// LAB
import CentralLabTestList from "issara-sdk/apis/CentralLabTestList_apps_LAB";
// IME
import ImagingTestList from "issara-sdk/apis/ImagingTestList_apps_IME"  

export type State = {
  searchedItemList?: any[];
  selectedEncounter?: any;
  selectedEmr?: any;
  searchedItemListWithKey?: any;
};

export const StateInitial: State = {
  searchedItemList: [],
  searchedItemListWithKey: {},
};

export type Event = {
  message: "ItemSearch";
  params: { searchText: string; searchType?: string };
};

export type Data = {
  division?: any;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const ItemSearch: Handler = async (controller, params) => {
  // console.log("ItemSearch params: ", params)
  if (params?.action === "clear") {
    console.log("ItemSearch clear: ");

    let blank = {};
    let state = controller.getState();
    // console.log(" ItemSearch  clear")

    if (params.type && params?.id) {
      blank = { [params.type + "_" + params.id]: [] };
    } else if (params.type && !params?.id) {
      blank = { [params.type]: [] };
    }

    // console.log(" blank ", blank)
    controller.setState({
      searchedItemList: [],
      searchedItemListWithKey: {
        ...state.searchedItemListWithKey,
        ...blank,
      },
    });

    // } else if (params?.searchText && params.searchText.length >= 3) {
  } else if (params?.searchText) {
    // console.log(" ItemSearch", params);
    let itemList: any = [];
    let data = {};

    if (Search?.[params.type])
      itemList = await Search[params.type](controller, params);

    console.log("itemList: ", itemList);

    if (params?.type && params?.id) {
      data = { [params.type + "_" + params.id]: itemList || [] };
    } else if (params?.type && !params?.id) {
      data = { [params.type]: itemList || [] };
    }
    console.log("data", data);

    let state = controller.getState();
    controller.setState({
      searchedItemList: itemList || [],
      searchedItemListWithKey: {
        ...state.searchedItemListWithKey,
        ...data,
      },
    });
  }
};

const Search: { [key: string]: Handler } = {
  Treatment: async (controller, params) => {
    // console.log(" Treatment ");
    let state = controller.getState();

    const result = await TreatmentList.list({
      params: {
        emr: state?.selectedEmr?.id,
        encounter: state?.selectedEncounter?.id,
        search: params?.searchText,
      },
      apiToken: controller.apiToken,
    });
    // const result = await DrugGroupList.list({
    //   params: {
    //     type: "ATC",
    //     name_desc: params.searchText
    //   },
    //   apiToken: controller.apiToken
    // })

    // console.log(" TreatmentList result", result);

    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);

    let itemList = result[1] ? [] : result[0] || [];
    return itemList;
  },

  ADRatc: async (controller, params) => {
    const result = await DrugGroupList.list({
      params: {
        type: "ATC",
        name_desc: params.searchText,
      },
      apiToken: controller.apiToken,
    });

    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);

    let itemList = result[1] ? [] : result[0] || [];
    return itemList;
  },
  ADRmims: async (controller, params) => {
    const result = await DrugGroupList.list({
      params: {
        type: "MIMS",
        name_desc: params.searchText,
      },
      apiToken: controller.apiToken,
    });

    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);

    let itemList = result[1] ? [] : result[0] || [];
    return itemList;
  },
  ADRingredient: async (controller, params) => {
    const result = await IngredientList.list({
      params: { name__icontains: params.searchText, limit: params.limit },
      apiToken: controller.apiToken,
    });

    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);

    let itemList = result[1] ? [] : result[0]?.items || [];
    return itemList;
  },
  ADRproduct: async (controller, params) => {
    const result = await DrugList.list({
      params: { name_code: params.searchText },
      apiToken: controller.apiToken,
    });

    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);

    let itemList = result[1] ? [] : result[0]?.items || [];
    return itemList;
  },
  Drug: async (controller, params) => {
    const result = await DrugList.list({
      params: { keyword: params.searchText, limit: 20 },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);

    let itemList = result[1] ? [] : result[0]?.items || [];
    return itemList;
  },
  Provider: async (controller, params) => {
    const result = await Scheduling.get({
      command: "ProviderSearch",
      params: {
        searchText: params.searchText,
        start_date: "2000-01-01",
        end_date: "2100-12-29",
      },
      apiToken: controller.apiToken,
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    let itemList: any[] = [];
    itemList = result[1] ? [] : result[0] || [];
    itemList = itemList.map((item: any) => ({ ...item }));
    return itemList;
  },
  Payer: async (controller, params) => {
    const result = await PayerList.list({
      params: { search: params.searchText },
      apiToken: controller.apiToken,
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    let itemList: any[] = [];
    itemList = result[1] ? [] : result[0]?.items || [];
    itemList = itemList.map((item: any) => ({ ...item }));
    return itemList;
  },
  Patient: async (controller, params) => {
    const result = await PatientList.list({
      params: {
        name_search: params?.searchText,
        is_old_name_search: true,
        offset: 0,
        limit: params.limit || 40,
      },
      apiToken: controller.apiToken,
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result[0]?.items || [];
  },
  Substance: async (controller, params) => {
    const result = await SubstanceList.get({
      params: { search: params?.searchText },
      apiToken: controller.apiToken,
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result[0]?.items || [];
  },
  VTM: async (controller, params) => {
    const result = await VTMList.get({
      params: { search: params?.searchText },
      apiToken: controller.apiToken,
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result[0]?.items || [];
  },
  GenericProduct: async (controller, params) => {
    const result = await GenericProductList.get({
      params: { search: params?.searchText },
      apiToken: controller.apiToken,
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result[0]?.items || [];
  },
  TradeProduct: async (controller, params) => {
    const result = await TradeProductList.get({
      params: { search: params?.searchText },
      apiToken: controller.apiToken,
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result[0]?.items || [];
  },
  GenericProductUse: async (controller, params) => {
    const result = await GenericProductUseList.get({
      params: { search: params?.searchText },
      apiToken: controller.apiToken,
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result[0]?.items || [];
  },
  ICD10Code: async (controller, params) => {
    const result = await icd10KeyUp.post({
      apiToken: controller.apiToken,
      method: "icdcode",
      data: { icdcode: params?.searchText },
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result[0]?.response || [];
  },
  ICD10Term: async (controller, params) => {
    const result = await icd10KeyUp.post({
      apiToken: controller.apiToken,
      method: "icdterm",
      data: { icdterm: params?.searchText },
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result[0]?.response || [];
  },
  TradeProductUse: async (controller, params) => {
    const result = await TradeProductUseList.get({
      params: { search: params?.searchText },
      apiToken: controller.apiToken,
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result[0]?.items || [];
  },
  Division: async (controller, params) => {
    const result = await DivisionList.list({
      params: { name: params?.searchText, limit: 10 },
      apiToken: controller.apiToken,
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result[0]?.items || [];
  },
  DoctorFeeRule: async (controller, params) => {
    const result = await DoctorFeeRuleListM.list({
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
      params: { keyword: params?.searchText },
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result?.[0]?.items || [];
  },
  Doctor: async (controller, params) => {
    const result = await DoctorList.list({
      apiToken: controller.apiToken,
      params: { name_code: params?.searchText, limit: 10 },
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result?.[0]?.items || [];
  },
  RoomCanReserve: async (controller, params) => {
    const result = await RoomList.list({
      apiToken: controller.apiToken,
      params: {
        room_no__icontains: params?.searchText,
        can_reserve: true,
        limit: 10,
      },
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result?.[0].items || [];
  },
  Room: async (controller, params) => {
    const result = await RoomList.list({
      apiToken: controller.apiToken,
      params: {
        room_no__icontains: params?.searchText,
        limit: 10,
      },
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result?.[0].items || [];
  },
  Employee: async (controller, params) => {
    const result = await EmployeeList.list({
      apiToken: controller.apiToken,
      params: { keyword: params?.searchText, limit: 50 },
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result?.[0].items || [];
  },
  Encounter: async (controller, params) => {
    const result = await EncounterList.list({
      apiToken: controller.apiToken,
      params: { number: params?.searchText, limit: 10 },
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result?.[0].items || [];
  },
  User: async (controller, params) => {
    const result = await UserList.list({
      apiToken: controller.apiToken,
      params: {
        [params.role ? "name_code" : "name_username"]: params?.searchText,
        limit: 10,
        role: params.role,
      },
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result?.[0]?.items || [];
  },
  OperatingTreatment: async (controller, params) => {
    const result = await OperatingTreatmentList.list({
      apiToken: controller.apiToken,
      params: { search: params?.searchText, limit: 10 },
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result?.[0].items || [];
  },

  OperatingItem: async (controller, params) => {
    const result = await OperatingItemList.list({
      apiToken: controller.apiToken,
      params: { search: params?.searchText, limit: 10 },
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result?.[0].items || [];
  },

  OperatingPackingItem: async (controller, params) => {
    const result = await OperatingItemList.list({
      apiToken: controller.apiToken,
      params: { type: params?.searchType, name: params?.searchText, limit: 10 },
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result?.[0].items || [];
  },

  Nurse: async (controller, params) => {
    const result = await UserList.list({
      apiToken: controller.apiToken,
      params: {
        role: "REGISTERED_NURSE",
        name_code: params?.searchText,
        limit: 10,
      },
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result?.[0].items || [];
  },

  ARTransactionLotNO: async (controller, params) => {
    const result = await ARTransactionList.list({
      apiToken: controller.apiToken,
      params: { lot_no: params?.searchText },
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result?.[0].items || [];
  },

  Product: async (controller, params) => {
    const result = await ProductList.list({
      apiToken: controller.apiToken,
      params: {
        search: params?.searchText,
        p_type__code__in: "DRUG,SUPPLY,EQUIP",
        limit: params.limit,
      },
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result?.[0].items || [];
  },

  Manufacturer: async (controller, params) => {
    const result = await ManufacturerList.list({
      apiToken: controller.apiToken,
      params: { name__icontains: params?.searchText, limit: params.limit },
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result?.[0].items || [];
  },
  Package: async (controller, params) => {
    const result = await PackageList.list({
      params: { keyword: params.searchText, limit: params.limit, active: params.active },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);

    let itemList = result[1] ? [] : result[0]?.items || [];
    return itemList;
  },
  CentralLabTest: async (controller, params) => {
    const result = await CentralLabTestList.list({
      params: { name: params.searchText, limit: params.limit, encounter: params.encounter },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);

    let itemList = result[1] ? [] : result[0]?.items || [];
    return itemList;
  },
  ProductAll: async (controller, params) => {
    const result = await ProductList.list({
      apiToken: controller.apiToken,
      params: {
        search: params?.searchText,
        limit: params.limit,
      },
    });
    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);
    return result?.[0].items || [];
  },
  ImagingXray: async (controller, params) => {
    const result = await ImagingTestList.list({
      params: { search: params.searchText, limit: params.limit },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    if (params?.verbose) console.log(result[1] ? result[1] : result[0]);

    let itemList = result[1] ? [] : result[0]?.items || [];
    return itemList;
  },
};
