import React, { CSSProperties, useEffect, useState } from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import ModEditDiscount from "../BIL/ModEditDiscount";

// UX
import ButtonSuspending from "./ButtonSuspending";

type CardSuspendingProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: any;
  MedicalFeeHistorySequence?: any;
  // data
  patientId: number;
  encounterSuspension?: {
    discount: boolean;
    coverage: boolean;
    discountList: any[];
  };
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  loadingStatus?: Record<string, any>;
  // options
  masterOptions?: Record<string, any[]>;
};

// Const
export const buttonStyle = {
  minWidth: "max-content",
  paddingRight: "10px",
  paddingLeft: "10px",
  width: "75px",
} as CSSProperties;

const MOD_EDIT_DISCOUNT = "ModEditDiscountUX";

const CardSuspending = (props: CardSuspendingProps) => {
  const [openModEdit, setOpenModEdit] = useState<boolean>(false);

  useEffect(() => {
    props.runSequence({ sequence: "MedicalFeeHistory", restart: true });

    return () => {
      props.runSequence({ sequence: "MedicalFeeHistory", clear: true });
    };
  }, []);

  useEffect(() => {
    if (props.patientId) {
      props.onEvent({
        message: "HandleGetEncounterSuspension",
        params: { patientId: props.patientId },
      });
    }
  }, [props.patientId]);

  const handleClick = () => {
    setOpenModEdit(true);
    props.runSequence({
      sequence: "MedicalFeeHistory",
      action: "openEdit",
      encounter: undefined,
    });
  };

  console.log("CardSuspending", props);

  return (
    <div>
      {/* @ts-ignore */}
      <Form>
        <Form.Group>
          <Form.Field width={10} style={{ padding: 0 }}>
            <ButtonSuspending
              onEvent={props.onEvent}
              setProp={props.setProp}
              type="coverage"
              patientId={props.patientId}
              suspended={props.encounterSuspension?.coverage ?? false}
              // CommonInterface
              buttonLoadCheck={props.buttonLoadCheck}
              errorMessage={props.errorMessage}
              // options
              activatePrivilegeOptions={props.masterOptions?.activatePrivilege}
              suspendPrivilegeOptions={props.masterOptions?.suspendPrivilege}
            />
          </Form.Field>
          <Form.Field width={10} style={{ padding: 0 }}>
            <ButtonSuspending
              onEvent={props.onEvent}
              setProp={props.setProp}
              type="discount"
              patientId={props.patientId}
              suspended={props.encounterSuspension?.discount ?? false}
              // CommonInterface
              buttonLoadCheck={props.buttonLoadCheck}
              errorMessage={props.errorMessage}
              // options
              activatePrivilegeOptions={props.masterOptions?.activatePrivilege}
              suspendPrivilegeOptions={props.masterOptions?.suspendPrivilege}
            />
          </Form.Field>
        </Form.Group>
        {props.patientId && (
          <Form.Group style={{ marginBottom: 0, marginTop: "-0.75rem" }}>
            <Form.Field width={10} style={{ padding: 0 }}></Form.Field>
            <Form.Field width={10} style={{ padding: 0 }}>
              <Button
                size="mini"
                disabled={!props.encounterSuspension?.discountList?.length}
                basic={true}
                color={"yellow"}
                style={buttonStyle}
                onClick={handleClick}
              >
                <Icon name="edit outline" />
                แก้ไข
              </Button>
            </Form.Field>
          </Form.Group>
        )}
      </Form>

      <Modal
        open={openModEdit}
        size="large"
        onClose={() => {
          setOpenModEdit(false);
          props.setProp("errorMessage", {
            ...props.errorMessage,
            [`${MOD_EDIT_DISCOUNT}_ADD`]: null,
            [`${MOD_EDIT_DISCOUNT}_SAVE`]: null,
          });
        }}
      >
        <ModEditDiscount
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          MedicalFeeHistorySequence={props.MedicalFeeHistorySequence}
          // data
          // * ไม่ต้องส่ง encounter หากไม่ต้องการ filter by encounter
          // CommonInterface
          errorMessage={props.errorMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          loadingStatus={props.loadingStatus}
          // options
          masterOptions={props.masterOptions}
          // config
          hideSelectCoverage={true}
        />
      </Modal>
    </div>
  );
};

export default React.memo(CardSuspending);
