import React, {
  useState,
  useEffect,
  useMemo,
  SyntheticEvent,
  useRef,
} from "react";
import {
  Image,
  Icon,
  Label,
  Button,
  Modal,
  Segment,
  Header,
  Divider,
  Form,
  Dropdown,
  Input,
  DropdownProps,
  InputProps,
  Dimmer,
  Loader,
  Popup,
  Container,
  Checkbox,
  List,
} from "semantic-ui-react";
import { Dialog } from "@mui/material";

import moment from "moment";

// Framework
import { Table } from "react-lib/frameworks/Table";

// UX
import CardPatientPanelUX from "./CardPatientPanelUX";
import CardVitalSign from "react-lib/apps/HISV3/PTM/CardVitalSign";
import ModAllergyUX from "react-lib/apps/HISV3/PTM/ModAllergyUX";
import CardUnderlyingDiseaseUX from "react-lib/apps/HISV3/PTM/CardUnderlyingDiseaseUX";
import CardAllergyActionLog from "react-lib/apps/HISV3/TPD/CardAllergyActionLog";
import CardAdverseReactionNote from "react-lib/apps/HISV3/TPD/CardAdverseReactionNote";
import CardSuspending from "./CardSuspending";
import WebcamProfile from "./WebcamProfile";
import CardBindIdUX from "./CardBindIdUX";

// DPO
import CardDischargeReact from "react-lib/apps/DPO/CardDischarge";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import TimeTextBox from "react-lib/apps/common/TimeTextBox";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ModInfo from "react-lib/apps/common/ModInfo";
import { alignCenter } from "react-lib/apps/common/PureReactTable";

// Utils
import {
  formatDatetime,
  beStringDateTimeToDateObject,
  adToBe,
  formatDate,
} from "react-lib/utils/dateUtils";

// Styles
import "./CardPatientPanel.scss";
import config from "config/config";

const DISCHARGE_STATUS = {
  PLAN_DISCHARGE: "Plan Discharged",
  DOCTOR_DISCHARGE: "หมอมีคำสั่งให้ Discharge",
  CASHIER_REQUEST: "Cashier Request",
  CASHIER_DISCHARGED: "Cashier Discharged",
};

const BUTTON_DISCHARGE: Record<string, string> = {
  [DISCHARGE_STATUS.PLAN_DISCHARGE]: "Discharge",
  [DISCHARGE_STATUS.DOCTOR_DISCHARGE]: "Request discharge",
  [DISCHARGE_STATUS.CASHIER_REQUEST]: "Cashier Request",
  [DISCHARGE_STATUS.CASHIER_DISCHARGED]: "Ward Discharge",
} as const;

const ALLOWED_ACTIONS = {
  OPD_CANCEL_QUEUE_EXAM: "OPD_CANCEL_QUEUE_EXAM",
  OPD_QUEUE_EXAM: "OPD_QUEUE_EXAM",
  OPD_DISCHARGE: "OPD_DISCHARGE",
  OPD_CANCEL_CHECK_IN: "OPD_CANCEL_CHECK_IN",
  OPD_CHECK_OUT: "OPD_CHECK_OUT",
  CANCEL_CHECKOUT: "CANCEL_CHECKOUT",
} as const;

const BUTTON_ACTIONS: Record<string, string> = {
  [ALLOWED_ACTIONS.OPD_CANCEL_QUEUE_EXAM]: "นำออกจากคิวรอตรวจ",
  [ALLOWED_ACTIONS.OPD_QUEUE_EXAM]: "ส่งผู้ป่วยเข้าคิวรอตรวจ",
  [ALLOWED_ACTIONS.OPD_DISCHARGE]: "OPD Discharge",
  [ALLOWED_ACTIONS.OPD_CANCEL_CHECK_IN]: "ยกเลิกการรับเข้าตรวจ",
  [ALLOWED_ACTIONS.OPD_CHECK_OUT]: "Checkout",
  [ALLOWED_ACTIONS.CANCEL_CHECKOUT]: "ยกเลิก checkout",
} as const;

const CARD_PATIENT_DISCHARGE = "CardPatientDischarge";
const CARD_ALLERGY = "CardAllergy";
const CARD_PATIENT_PANEL = "CardPatientPanel";

const SEMANTIC_COLOR = {
  blue: "rgba(33, 133, 208, 1)",
  yellow: "rgb(251, 189, 8)",
  orange: "rgba(242, 113, 28)",
  olive: "rgba(181, 204, 24)",
};

const CardPatientPanel = (props: any) => {
  const [openWebCam, setOpenWebCam] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openModDischarge, setOpenModDischarge] = useState<{
    type: any;
  } | null>(null);
  const [openModORCheckout, setOpenModORCheckout] = useState<boolean>(false);
  const [openModORDischarge, setOpenModORDischarge] = useState<boolean>(false);
  const [openModORCancelDischarge, setOpenModORCancelDischarge] =
    useState<boolean>(false);
  const [openModInfo, setOpenModInfo] = useState<any>(null);
  const [openModSelectedADR, setOpenModSelectedADR] = useState<boolean>(false);
  const [openModSelectedUD, setOpenModSelectedUD] = useState<boolean>(false);
  const [openModSelectedLAB, setOpenModSelectedLAB] = useState<boolean>(false);
  const [openModSelectedVS, setOpenModSelectedVS] = useState<boolean>(false);
  const [openModADRNote, setOpenModADRNote] = useState<boolean>(false);
  const [actionLogId, setActionLogId] = useState<number | null>(null);
  const [openModNote, setOpenModNote] = useState<boolean>(false);
  const [adrClick, setAdrClick] = useState<any>(null);
  const [actionButton, setActionButton] = useState<string>("");
  const handleCloseModSelectedADR = () => setOpenModSelectedADR(false);
  const handleCloseModSelectedUD = () => setOpenModSelectedUD(false);
  const handleCloseModSelectedLAB = () => setOpenModSelectedLAB(false);
  const handleCloseModSelectedVS = () => setOpenModSelectedVS(false);
  const handleCloseModADRNote = () => setOpenModADRNote(false);
  const [ihid, setIhid] = useState<any>("");
  const [openEditProblemList, setOpenEditProblemList] = useState(false);
  const [dataProblemList, setDataProblemList] = useState<any>();
  const [textEdit, setTextEdit] = useState("");
  const [openPregnancyLog, setOpenPregnancyLog] = useState(false);

  const doctorRef = useRef();
  const newWindowRef = useRef<any>(null);

  //allergy status
  const [showTable, setShowTable] = useState<boolean>(true);
  const [showDrugStatus, setShowDrugStatus] = useState<boolean>(false);

  const [openNKA, setOpenNKA] = useState<boolean>(false);
  const [openNFA, setOpenNFA] = useState<boolean>(false);
  const [openNOA, setOpenNOA] = useState<boolean>(false);
  const [openDrug, setOpenDrug] = useState<boolean>(false);
  const [openFood, setOpenFood] = useState<boolean>(false);
  const [openOther, setOpenOther] = useState<boolean>(false);

  // const [newADR, setNewADR] = useState<boolean>(false)

  //food
  const [showTableFood, setShowTableFood] = useState<boolean>(true);
  const [showFoodStatus, setShowFoodStatus] = useState<boolean>(false);

  //other
  const [showTableOther, setShowTableOther] = useState<boolean>(true);
  const [showOtherStatus, setShowOtherStatus] = useState<boolean>(false);

  useEffect(() => {
    props.runSequence({
      sequence: "Allergy",
      form: props.form,
      restart: true,
      noEmr: true,
    });

    // form: is check ViewRegistration || ViewEncounter
    props.runSequence({
      sequence: "CreateUpdateEncounter",
      card: "CreateUpdateEncounter",
      form: props.form,
      restart: true,
    });
  }, [
    // ViewRegistration
    props.selectedPatient?.encounter_id,
    props.selectedPatient?.encounter_no,
    props.selectedPatient?.hn,
    // ViewEncounter
    props.selectedEncounter?.patient_id
  ]);

  // console.log("Allergy -> PatientPanel", props?.AllergySequence?.adrList);
  const handleSaveNewImage = async (imgSrc: any) => {
    // console.log(imgSrc);
    // imgSrc is base64 encoded string
    console.log(imgSrc);
    let data = {
      image: imgSrc,
      image_type: "P",
    };
    props.setProp("SetPatientSequence.patient.profile_image", data);
  };
  const IMAGES = {
    //drug
    drug_yellow: "/static/images/allergy/Drug(Yellow).png",
    drug_gray: "/static/images/allergy/Drug(Gray).png",
    drug_red: "/static/images/allergy/Drug(Red).png",
    drug_white: "/static/images/allergy/Drug_white.png",
    //food
    food_gray: "/static/images/allergy/Food(Gray).png",
    food_red: "/static/images/allergy/Food(Red).png",
    food_white: "/static/images/allergy/Food_white.png",
    //other
    other_gray: "/static/images/allergy/Other(Gray).png",
    other_red: "/static/images/allergy/Other(Red).png",
    other_white: "/static/images/allergy/other_chemistry.png",
    // verify
    noted: "/static/images/allergy/Note_Status.png",
    verified: "/static/images/allergy/Verified_Status.png",
    locked: "/static/images/allergy/Verified_Lock_Status.png",
    inactive: "/static/images/allergy/Inactive_Status.png",
    // pregnancyStatus
    pregnancyPeriodNA: "/static/images/pregnancy/pregnancyPeriodNA.png",
    pregnancyPeriod1: "/static/images/pregnancy/pregnancyPeriod1.png",
    pregnancyPeriod2: "/static/images/pregnancy/pregnancyPeriod2.png",
    pregnancyPeriod3: "/static/images/pregnancy/pregnancyPeriod3.png",
  };

  const finddrug = props.AllergySequence?.adrList?.filter(
    (item: any) => item?.type_name_name === "DRUG"
  );
  const finddrug_notinactive = props.AllergySequence?.adrList?.filter(
    (item: any) =>
      item?.type_name_name === "DRUG" && item?.is_inactive === false
  );
  const findfood = props.AllergySequence?.adrList?.filter(
    (item: any) => item?.type_name_name === "FOOD"
  );
  const findother = props.AllergySequence?.adrList?.filter(
    (item: any) => item?.type_name_name === "OTHER"
  );
  let findverify = finddrug_notinactive?.filter(
    (item: any) =>
      item?.is_acknowledged === true && item?.status !== "NO_REACTION"
  );
  let findnotverify = finddrug_notinactive?.filter(
    (item: any) =>
      item?.is_acknowledged !== true &&
      item?.status !== "NO_REACTION" &&
      item?.status !== "LOCKED"
  );
  let drug_rec_notinactive = finddrug_notinactive?.filter(
    (item: any) => item?.status === "NO_REACTION"
  );
  let findverifylock = finddrug_notinactive?.filter(
    (item: any) => item?.status === "LOCKED"
  );

  let drug_rec = finddrug?.filter(
    (item: any) => item?.status === "NO_REACTION"
  );
  let drug_name = finddrug?.filter((item: any) => item?.name !== "");
  let food_rec = findfood?.filter(
    (item: any) => item?.status === "NO_REACTION"
  );
  let food_name = findfood?.filter((item: any) => item?.note !== "");
  let other_rec = findother?.filter(
    (item: any) => item?.status === "NO_REACTION"
  );
  let other_name = findother?.filter((item: any) => item?.note !== "");

  useEffect(() => {
    if (finddrug?.length === 0) {
      setShowTable(false);
    } else if (drug_name?.length === 0 && drug_rec?.length !== 0) {
      setShowTable(false);
    } else if (drug_name?.length !== 0 && drug_rec?.length !== 0) {
      setShowTable(true);
    } else {
      setShowTable(true);
    }
  }, [props.AllergySequence?.adrList]);
  useEffect(() => {
    if (findfood?.length === 0) {
      setShowTableFood(false);
    } else if (food_name?.length === 0 && food_rec?.length !== 0) {
      setShowTableFood(false);
    } else if (food_name?.length !== 0 && food_rec?.length !== 0) {
      setShowTableFood(true);
    } else {
      setShowTableFood(true);
    }
  }, [props.AllergySequence?.adrList]);
  useEffect(() => {
    if (findother?.length === 0) {
      setShowTableOther(false);
    } else if (other_name?.length === 0 && other_rec?.length !== 0) {
      setShowTableOther(false);
    } else if (other_name?.length !== 0 && other_rec?.length !== 0) {
      setShowTableOther(true);
    } else {
      setShowTableOther(true);
    }
  }, [props.AllergySequence?.adrList]);
  useEffect(() => {
    if (finddrug?.length !== 0) {
      setShowDrugStatus(true);
    } else {
      setShowDrugStatus(false);
    }
  }, [props.AllergySequence?.adrList]);
  useEffect(() => {
    if (findfood?.length !== 0) {
      setShowFoodStatus(true);
    } else {
      setShowFoodStatus(false);
    }
  }, [props.AllergySequence?.adrList]);
  useEffect(() => {
    if (findother?.length !== 0) {
      setShowOtherStatus(true);
    } else {
      setShowOtherStatus(false);
    }
  }, [props.AllergySequence?.adrList]);
  const handleOnClickNKA = () => {
    setOpenNKA(true);
  };
  const handleOnClickNFA = () => {
    setOpenNFA(true);
  };
  const handleOnClickNOA = () => {
    setOpenNOA(true);
  };
  const handleOnClickDrug = () => {
    setOpenDrug(true);
  };
  const handleOnClickFood = () => {
    setOpenFood(true);
  };
  const handleOnClickOther = () => {
    setOpenOther(true);
  };

  const handleShowInactive = (e: any, { checked }: any) => {
    props.setProp("AllergySequence.showInactive", checked);

    props.runSequence({
      sequence: "Allergy",
      action: "inactive",
      checked: checked,
    });
  };

  const adrIcon = useMemo(() => {
    if (props.patientADR?.items) {
      if (props.patientADR?.items?.length === 0) {
        return null;
      } else if (
        props.patientADR?.items?.length === 1 &&
        props.patientADR?.items?.[0].status === "NO_REACTION"
      ) {
        return <Label> NKA </Label>;
      } else {
        return <Label color="red"> ADR </Label>;
      }
    }
  }, [props.patientADR]);

  const statusLabel = useMemo(() => {
    return props.selectedAdmitOrderRoomItem?.status_label;
  }, [props.selectedAdmitOrderRoomItem]);

  const ardList = useMemo(
    () =>
      props.patientADR?.items?.map((item: any, idx: any) => {
        return {
          ...item,
        };
      }),
    [props.patientADR]
  );

  const pregnancyLog = useMemo(
    () =>
      props.CreateUpdateEncounterSequence?.pregnancyLog?.map(
        (item: any, idx: any) => {
          return {
            ...item,
            tracked_data_detail: (
              <>
                {item?.tracked_data?.pregnancy_status === 1
                  ? "ไม่ทราบ"
                  : item?.tracked_data?.pregnancy_status === 2
                  ? "ไม่ตั้งครรภ์"
                  : item?.tracked_data?.pregnancy_status === 3
                  ? `กำลังตั้งครรภ์ ${
                      props.CreateUpdateEncounterSequence?.pregnancyPeriodOptions?.find(
                        (opt: any) =>
                          opt.value === item?.tracked_data?.pregnancy_period
                      )?.text || ""
                    }`
                  : ""}
              </>
            ),
          };
        }
      ),
    [props.CreateUpdateEncounterSequence?.pregnancyLog]
  );

  //PatienPanel ADR
  const patientADRSort = props.patientADR?.items?.sort((a: any, b: any) =>
    a.no < b.no ? 1 : -1
  );
  const ADR = patientADRSort
    ?.map((item: any) =>
      item.name ? `-${item.adr_short_info}` : item.note ? `-${item.note}` : null
    )
    .join("\n");

  const openNote = (item: any) => {
    setAdrClick(item);
    setOpenModNote(true);
  };

  const drugLabInteraction = useMemo(() => {
    return (props.LabResult || [])
      .map((item: any) => {
        let date = adToBe(moment(item.date).format("DD/MM/YYYY"));
        return item.name
          ? `-${item.name} ${item.value} ${item.unit} [${date}]`
          : null;
      })
      ?.join("\n");
  }, [props.LabResult]);

  const labData = useMemo(() => {
    return (props.LabResult || []).map((item: any) => {
      let date = adToBe(moment(item.date).format("DD/MM/YYYY"));
      return {
        code: alignCenter(item.code),
        name: alignCenter(item.name),
        value: alignCenter(item.value),
        unit: alignCenter(item.unit),
        result_date: alignCenter(
          `${date} [${moment(item.date).format("HH:mm")}]`
        ),
      };
    });
  }, [props.LabResult]);

  const allergyListMod = useMemo(
    () =>
      patientADRSort?.map((item: any, index: number) => {
        return (
          <div style={{ marginTop: "2px" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                position: "relative",
                gridTemplateRows: "repeat(2, 1fr)",
                gridTemplateColumns: "repeat(50, 1fr)",
              }}
            >
              <div style={{ gridRow: "1/2", gridColumn: "1/2" }}>
                {index + 1}
              </div>
              <div style={{ gridRow: "1/2", gridColumn: "3/5" }}>
                <Label
                  style={{
                    color: "white",
                    backgroundColor:
                      item.status === "NOTED"
                        ? SEMANTIC_COLOR.yellow
                        : item.status === "LOCKED"
                        ? SEMANTIC_COLOR.orange
                        : item.status === "PAUSED"
                        ? SEMANTIC_COLOR.olive
                        : "",
                  }}
                >
                  {item.status}
                </Label>
              </div>
              <div style={{ gridRow: "1/2", gridColumn: "10/15" }}>
                {item.name}
              </div>
              <div style={{ gridRow: "1/2", gridColumn: "17/25" }}>
                {item.note}
              </div>
              <div style={{ gridRow: "1/2", gridColumn: "43/50" }}>
                <Button
                  size="mini"
                  icon="edit"
                  color="yellow"
                  onClick={(event: SyntheticEvent) => {
                    props.runSequence({
                      sequence: "Allergy",
                      action: "selectedADR",
                      original: item,
                    });
                    setOpenModADRNote(true);
                  }}
                />

                <Button
                  size="mini"
                  icon={item.status === "LOCKED" ? "unlock" : "lock"}
                  color={item.status === "LOCKED" ? "olive" : "orange"}
                  onClick={(event: SyntheticEvent) => {
                    openNote(item);
                    setActionButton("toggleLock");
                  }}
                />

                <Button
                  size="mini"
                  icon="trash alternate"
                  color="red"
                  onClick={(event: SyntheticEvent) => {
                    openNote(item);
                    setActionButton("cancel");
                  }}
                />

                <Button
                  size="mini"
                  icon="history"
                  onClick={() => {
                    setActionLogId(item?.id);
                  }}
                />
              </div>
            </div>
          </div>
        );
      }),
    [props.patientADR]
  );

  const actionLogData = useMemo(
    () =>
      props.AllergySequence?.actionLog?.items?.map((item: any) => ({
        ...item,
        datetime: formatDatetime(item.datetime),
      })),
    [props.AllergySequence?.actionLog]
  );

  const allowedActions = useMemo(() => {
    const actions: string[] = props.selectedEncounter?.allowed_actions || [];

    if (actions.includes(ALLOWED_ACTIONS.OPD_QUEUE_EXAM)) {
      actions.push(ALLOWED_ACTIONS.CANCEL_CHECKOUT);
    }

    return actions;
  }, [props.selectedEncounter]);

  const buttonActions = useMemo(() => {
    const isDoctor = props.django?.user?.role_types?.includes("DOCTOR");
    const isCheckout =
      isDoctor &&
      !allowedActions.includes(ALLOWED_ACTIONS.OPD_QUEUE_EXAM) &&
      !props.selectedOrOrder;

    const isCancelCheckout =
      isDoctor && allowedActions.includes(ALLOWED_ACTIONS.OPD_CHECK_OUT);
    const isOPDDischarge = !(
      props.selectedAppointment?.type_display === "นัดหมายผ่าตัด"
    );

    return [
      {
        action: ALLOWED_ACTIONS.OPD_CANCEL_QUEUE_EXAM,
        color: "purple",
      },
      {
        action: ALLOWED_ACTIONS.OPD_CANCEL_CHECK_IN,
        color: "brown",
      },
      {
        action: ALLOWED_ACTIONS.OPD_QUEUE_EXAM,
        color: "orange",
      },
      isOPDDischarge && {
        action: ALLOWED_ACTIONS.OPD_DISCHARGE,
        color: "blue",
      },
      isCheckout && {
        action: ALLOWED_ACTIONS.OPD_CHECK_OUT,
        color: "blue",
      },
      isCancelCheckout && {
        action: ALLOWED_ACTIONS.CANCEL_CHECKOUT,
        color: "orange",
      },
    ].filter(Boolean);
  }, [
    allowedActions,
    props.django,
    props.selectedAppointment,
    props.selectedOrOrder,
  ]);

  // NOTE
  const handleNoteConfirmed = () => {
    props.runSequence({
      sequence: "Allergy",
      action: "actionButton",
      card: CARD_ALLERGY,
      ...(actionButton === "toggleLock" && { toggleLock: true }),
      ...(actionButton === "cancel" && { cancel: true }),
      item: adrClick,
    });
    setOpenModNote(false);
  };

  const handleNoteDeny = () => {
    props.runSequence({
      sequence: "Allergy",
      action: "clearNote",
      card: CARD_ALLERGY,
    });

    setOpenModNote(false);
  };

  //PatienPanel UD
  const underlyingDiseaseList = props?.problemListDPO?.items?.map(
    (item: any, index: number) => {
      return (
        <div style={{ borderTop: index === 0 ? "" : "2px solid #ccc" }}>
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}
          >
            <div
              style={{ textDecoration: item.is_active ? "" : "line-through" }}
            >
              {`${index + 1}. ${item.message}`}
            </div>
            <div style={{ flex: "1" }} />
            <div style={{ margin: "0px 10px" }}>
              <Button
                size="mini"
                icon="edit"
                color="yellow"
                disabled={!item.is_active}
                onClick={() => {
                  setOpenEditProblemList(true);
                  setDataProblemList(item);
                  setTextEdit(item.message);
                }}
              />
            </div>
            <div>
              <Button
                size="mini"
                icon={item.is_active ? "ban" : "check"}
                color={item.is_active ? "red" : "green"}
                onClick={() => {
                  props.onEvent({
                    message: "HandleAddMessageProblemDPO",
                    params: {
                      action: "editProblemList",
                      pk: item.pk,
                      patient: item.patient,
                      is_active: !item.is_active,
                      message: item.message,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  );

  const underlyingDisease = props?.problemListDPO?.items
    ?.filter((item: any) => item.is_active === true)
    .map((item: any) => item.message)
    .join(" , ");

  //PatienPanel VS
  const filterVitalSign = props?.vitalSignsInfo?.items?.filter(
    (item: any, index: number) =>
      item.vitalsign_type_code !== "Weight" &&
      item.vitalsign_type_code !== "Height" &&
      index !== 0
  );
  const sortVitalSign = filterVitalSign?.sort((a: any, b: any) =>
    a.display_seq > b.display_seq ? 1 : -1
  );
  const vitalSignList = sortVitalSign
    ?.map((item: any) => `${item.name}(${item.unit}) : ${item.result}`)
    .join("\n");

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleOnChangeNoteADR = (key: string) => (e: any, v: any) => {
    console.log("Key", key);
    props.setProp("AllergySequence", {
      ...props.AllergySequence,
      sequenceIndex: "Edit",
      adrSelected: {
        [key]: v.value,
      },
    });
  };

  const handleCheckout = (params: any = {}) => {
    return props.onEvent({
      message: "HandleSaveMedicalRecordDischarge",
      params: {
        ...params,
        encounter: props.selectedEncounter?.id,
        admitRoom: props.selectedAdmitOrderRoomItem,
        type: openModDischarge?.type,
        card: CARD_PATIENT_DISCHARGE,
        patientId: props.selectedPatient?.id,
        ihid,
        onSuccess: handleCloseModDischarge,
      },
    });
  };

  const handleAction = (type = "") => {
    type = openModDischarge?.type || type;
    type =
      type === ALLOWED_ACTIONS.CANCEL_CHECKOUT
        ? ALLOWED_ACTIONS.OPD_QUEUE_EXAM
        : type;

    props.runSequence({
      sequence: "Assessment",
      action: type,
      card: CARD_PATIENT_DISCHARGE,
      ihid,
      patientId: props.selectedPatient?.id,
      onSuccess: (message: any) => {
        if (message) {
          setOpenModInfo({ type: "success", message });
        } else {
          handleCloseModDischarge();
        }
      },
      onFailed: (message: any) => setOpenModInfo({ type: "error", message }),
    });
  };

  const handleCloseModDischarge = async () => {
    setOpenModDischarge(null);
    setOpenModInfo(null);
    setIhid("");
   
    await props.setProp(`errorMessage.${CARD_PATIENT_DISCHARGE}`, null);

    props.setProp("dischargeDoctor", { userId: null, username: "", password: "" })
  };

  const handleComplete = () => {
    const backElm = document.querySelector(
      ".patient-layout-backward"
    ) as HTMLDivElement;

    if (backElm) {
      backElm.click();
    }
  };

  const handleCancelNewADRNote = () => {
    props.onEvent({
      message: "RunSequence",
      params: { sequence: "Allergy", action: "clear" },
    });
    // setOpenADRDetail(false);
  };

  const handleSaveNewADRNote = () => {
    if (props?.AllergySequence?.adrSelected) {
      props.onEvent({
        message: "RunSequence",
        params: { sequence: "Allergy", action: "save" },
      });
    }
  };

  const handleChangeMessageProblemDPO = (e: any, v: any) => {
    props.setProp("messageProblemDPO", v.value);
  };

  const handleGoToDentalHistory = () => {
    const isDoctor = props.django?.user?.role_types?.includes("DOCTOR");
    const pathName = `${config.SHOW_DENTAL_HISTORY}/?app=${
      isDoctor ? "MainDPO" : "MainPTM"
    }`; // "https://issara-platform.web.app/?app=HIS"
    const url = `${pathName}&HN=${props.selectedPatient?.hn}&history=true`;

    newWindowRef.current?.close();

    newWindowRef.current = globalThis.open(url, "_blank");
  };

  //data table allergy
  const adrListData = useMemo(
    () =>
      props.AllergySequence?.adrList
        ?.filter(
          (item: any) =>
            item?.type_name_name === "DRUG" && item?.status !== "NO_REACTION"
        )
        ?.map((item: any) => {
          return {
            ...item,
            adversary_symptom_type: (
              <>
                {/*  freetext ไม่ต้องแสดงประเภท */}
                {item.adversary_symptom_type === "ALLERGY" &&
                item.adversary_type !== "OTHER"
                  ? "แพ้ยา"
                  : item.adversary_symptom_type === "SIDE_EFFECT" &&
                    item.adversary_type !== "OTHER"
                  ? "มีอาการข้างเคียง"
                  : item.adversary_type !== "OTHER"
                  ? item.adversary_symptom_type
                  : ""}
              </>
            ),
            name: (
              <>
                {item.adversary_type === "PRODUCT"
                  ? `${item.name} (Product)`
                  : item.adversary_type === "INGREDIENT"
                  ? `${item.name} (Ingredient)`
                  : item.adversary_type === "MIMS"
                  ? `${item.name} (MIMS Group)`
                  : item.adversary_type === "ATC"
                  ? `${item.name} (ATC Group)`
                  : // : item.adversary_type === "OTHER"
                    // ? `${item.name} (FreeText)`
                    item.name}
              </>
            ),
            probability: (
              <>
                {
                  props.masterOptions?.ADRProbability?.find(
                    (opt: any) => opt.key === item.probability
                  )?.text
                }
              </>
            ),
            symptoms: (
              <>
                {/*  freetext ไม่ต้องแสดงวันที่เกิดอาการ */}
                <div>
                  {item.adversary_type !== "OTHER" &&
                    item.symptoms
                      .map((sym: any) => {
                        return props.masterOptions?.ADRSymptoms?.find(
                          (opt: any) => opt.key === sym
                        )?.text;
                      })
                      .join(", ")}
                </div>
                {item.adversary_type !== "OTHER" && item?.issue_date && (
                  <div>
                    วันที่เกิดอาการ:{" "}
                    {formatDate(moment(item?.issue_date, "DD-MM-YYYY"))}
                  </div>
                )}
              </>
            ),
            status: (
              <>
                {item.status === "NOTED" &&
                item?.is_acknowledged === false &&
                item?.is_inactive === false ? (
                  <Popup
                    content={"NOTED"}
                    trigger={
                      <Image width={35} height={35} src={IMAGES.noted} />
                    }
                    position="left center"
                  />
                ) : item.status === "NOTED" &&
                  item?.is_acknowledged === true &&
                  item?.is_inactive === false ? (
                  <Popup
                    content={
                      <div>
                        <List>
                          <List.Item>VERIFIED (สั่งยาได้)</List.Item>
                          <List.Item>{item?.edit_user}</List.Item>
                          <List.Item>{item?.edited}</List.Item>
                        </List>
                      </div>
                    }
                    trigger={
                      <Image width={30} height={30} src={IMAGES.verified} />
                    }
                    position="left center"
                  />
                ) : item.status === "LOCKED" && item?.is_inactive === false ? (
                  <Popup
                    content={
                      <div>
                        <List>
                          <List.Item>VERIFIED & LOCKED (ห้ามสั่งยา)</List.Item>
                          <List.Item>{item?.edit_user}</List.Item>
                          <List.Item>{item?.edited}</List.Item>
                        </List>
                      </div>
                    }
                    trigger={
                      <Image width={47} height={37} src={IMAGES.locked} />
                    }
                    position="left center"
                    size="small"
                  />
                ) : item?.is_inactive === true ? (
                  <Popup
                    content={
                      <div>
                        <List>
                          <List.Item>INACTIVE</List.Item>
                          <List.Item>{item?.note}</List.Item>
                          <List.Item>{item?.edit_user}</List.Item>
                          <List.Item>{item?.edited}</List.Item>
                        </List>
                      </div>
                    }
                    trigger={
                      <Image width={35} height={35} src={IMAGES.inactive} />
                    }
                    position="left center"
                  />
                ) : null}
              </>
            ),
            button: (
              <>
                <Popup
                  content={"EDIT"}
                  trigger={
                    <Button
                      style={{
                        display: props.DJANGO?.user?.role_types.includes(
                          "PHARMACIST"
                        )
                          ? "inline-block"
                          : "none",
                      }}
                      size="mini"
                      icon="edit"
                      color="yellow"
                      onClick={(event: SyntheticEvent) => {
                        event.stopPropagation();

                        props.runSequence({
                          sequence: "Allergy",
                          action: "selectedADR",
                          original: item,
                        });
                      }}
                    />
                  }
                />
                {item.status !== "NO_REACTION" ? (
                  <Popup
                    content={"CANCEL"}
                    trigger={
                      <Button
                        size="mini"
                        icon="trash alternate"
                        color="red"
                        onClick={(event: SyntheticEvent) => {
                          event.stopPropagation();
                          openNote(item);
                          setActionButton("cancel");
                        }}
                      />
                    }
                  />
                ) : null}

                <Button
                  size="mini"
                  icon="history"
                  onClick={(event: SyntheticEvent) => {
                    event.stopPropagation();
                    setActionLogId(item?.id);
                  }}
                />
              </>
            ),
          };
        }),

    [props.AllergySequence?.adrList]
  );
  const adrListDataFood = useMemo(
    () =>
      props.AllergySequence?.adrList
        ?.filter(
          (item: any) =>
            item?.type_name_name === "FOOD" && item?.status !== "NO_REACTION"
        )
        ?.map((item: any, idx: any) => {
          return {
            ...item,
            probability: (
              <>
                {
                  props.masterOptions?.ADRProbability?.find(
                    (opt: any) => opt.key === item.probability
                  )?.text
                }
              </>
            ),
            symptoms: (
              <>
                {item.symptoms
                  .map((sym: any) => {
                    return props.masterOptions?.ADRSymptoms?.find(
                      (opt: any) => opt.key === sym
                    )?.text;
                  })
                  .join(", ")}
              </>
            ),
            status: (
              <>
                {item?.status === "NOTED" ? (
                  <Popup
                    content={"NOTED"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    trigger={
                      <Button
                        size="mini"
                        circular
                        icon="exclamation"
                        color="yellow"
                      />
                    }
                  />
                ) : (
                  item?.status
                )}
              </>
            ),
            button: (
              <>
                <Popup
                  content={"EDIT"}
                  trigger={
                    <Button
                      size="mini"
                      icon="edit"
                      color="yellow"
                      onClick={(event: SyntheticEvent) => {
                        event.stopPropagation();

                        props.runSequence({
                          sequence: "Allergy",
                          action: "selectedADR",
                          original: item,
                        });
                      }}
                    />
                  }
                />
                {item.status !== "NO_REACTION" ? (
                  <Popup
                    content={"CANCEL"}
                    trigger={
                      <Button
                        size="mini"
                        icon="trash alternate"
                        color="red"
                        onClick={(event: SyntheticEvent) => {
                          event.stopPropagation();
                          openNote(item);
                          setActionButton("cancel");
                        }}
                      />
                    }
                  />
                ) : null}

                <Button
                  size="mini"
                  icon="history"
                  onClick={(event: SyntheticEvent) => {
                    event.stopPropagation();
                    setActionLogId(item?.id);
                  }}
                />
              </>
            ),
          };
        }),

    [props.AllergySequence?.adrList]
  );
  const adrListDataOther = useMemo(
    () =>
      props.AllergySequence?.adrList
        ?.filter(
          (item: any) =>
            item?.type_name_name === "OTHER" && item?.status !== "NO_REACTION"
        )
        ?.map((item: any, idx: any) => {
          return {
            ...item,
            probability: (
              <>
                {
                  props.masterOptions?.ADRProbability?.find(
                    (opt: any) => opt.key === item.probability
                  )?.text
                }
              </>
            ),
            symptoms: (
              <>
                {item.symptoms
                  .map((sym: any) => {
                    return props.masterOptions?.ADRSymptoms?.find(
                      (opt: any) => opt.key === sym
                    )?.text;
                  })
                  .join(", ")}
              </>
            ),
            status: (
              <>
                {item?.status === "NOTED" ? (
                  <Popup
                    content={"NOTED"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    trigger={
                      <Button
                        size="mini"
                        circular
                        icon="exclamation"
                        color="yellow"
                      />
                    }
                  />
                ) : (
                  item?.status
                )}
              </>
            ),
            button: (
              <>
                <Popup
                  content={"EDIT"}
                  trigger={
                    <Button
                      size="mini"
                      icon="edit"
                      color="yellow"
                      onClick={(event: SyntheticEvent) => {
                        event.stopPropagation();

                        props.runSequence({
                          sequence: "Allergy",
                          action: "selectedADR",
                          original: item,
                        });
                      }}
                    />
                  }
                />
                {item.status !== "NO_REACTION" ? (
                  <Popup
                    content={"CANCEL"}
                    trigger={
                      <Button
                        size="mini"
                        icon="trash alternate"
                        color="red"
                        onClick={(event: SyntheticEvent) => {
                          event.stopPropagation();
                          openNote(item);
                          setActionButton("cancel");
                        }}
                      />
                    }
                  />
                ) : null}

                <Button
                  size="mini"
                  icon="history"
                  onClick={(event: SyntheticEvent) => {
                    event.stopPropagation();
                    setActionLogId(item?.id);
                  }}
                />
              </>
            ),
          };
        }),

    [props.AllergySequence?.adrList]
  );

  return (
    <div className="card-patient-panel">
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_PATIENT_PANEL}`, null);
        }}
        error={props.errorMessage?.[CARD_PATIENT_PANEL]}
        success={null}
      />

      <Dialog
        onClose={handleClose}
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
      >
        <Table
          data={ardList}
          headers="ชื่อ,ประเภท,อาการ,ความรุนแรง,ข้อความแจ้งเตือน,สถานะ,ผู้บันทึก,วันที่-เวลา"
          keys="name,type_name,severity_name,severity_name,note,status,edit_user,edited"
          showPagination={false}
          minRows="3"
        ></Table>
      </Dialog>
      <CardPatientPanelUX
        ENABLE_ECLINICAL={config.ENABLE_ECLINICAL}
        showDentalHistory={
          config.SHOW_DENTAL_HISTORY &&
          props.django?.user?.role_types?.find((role: string) =>
            ["DOCTOR", "NURSE", "REGISTERED_NURSE", "PHARMACIST"].includes(role)
          )
        }
        goToEclinical={() => {}} // TODO: add link Eclinical
        goToCreateAppointment={() => {
          props.goToCreateAppointment();
        }}
        goToDentalHistory={handleGoToDentalHistory}
        patientImage={props.selectedPatient?.profile_image?.image}
        profileImage={
          <div className="Img-container">
            <Image
              src={
                props.selectedPatient?.profile_image != null
                  ? props.selectedPatient?.profile_image?.image
                  : "/static/images/person.png"
              }
              size="small"
              className="image"
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = "/static/images/person.png";
              }}
              style={{
                resizeMode: "contain",
                width: "auto",
                maxwidth: "200px",
                height: "200px",
                border: "solid black 1px",
                marginRight: "10px",
              }}
            />
            <div className="middle" style={{ position: "absolute" }}>
              <div className="InputUpload" onClick={() => setOpenWebCam(true)}>
                <Icon className="camera" size="large" />
              </div>
            </div>
          </div>
        }
        hn={
          <div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 2rem" }}>
              <div>
                <div>{props.selectedPatient?.hn}</div>
                {props.selectedEncounter?.number && (
                  <div>({props.selectedEncounter?.number})</div>
                )}
              </div>
              {props.chatDetail && (
                <Icon.Group>
                  <Icon
                    name="facebook messenger"
                    style={{ fontSize: "1.7rem", color: "#1c9e2a" }}
                  />
                  <Icon
                    name="phone"
                    style={{
                      color: "white",
                      fontSize: ".8rem",
                      top: ".3rem",
                      left: ".3rem",
                      transform: "rotate(90deg)",
                      zIndex: 1,
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      width: "1.1rem",
                      height: "1.1rem",
                      borderRadius: "500rem",
                      backgroundColor: "#1c9e2a",
                      top: ".2rem",
                      left: ".2rem",
                    }}
                  ></div>
                </Icon.Group>
              )}
            </div>
          </div>
        }
        // check adrList
        adrList={props?.AllergySequence?.adrList}
        patientLanguage={props.selectedPatient?.patientLanguage}
        patientName={props.selectedPatient?.full_name}
        gender={props.selectedPatient?.gender_name}
        age={props.selectedPatient?.age}
        birthdate={props.selectedPatient?.birthdate}
        email={props.selectedPatient?.present_address?.email}
        tel_mobile={props.selectedPatient?.present_address?.tel_mobile}
        citizen_no={
          props.selectedPatient?.citizen_passport ||
          props.selectedPatient?.citizen_no
        }
        coverage={props.selectedPatient?.coverage}
        payer={props.selectedPatient?.payer_name}
        religion={props.selectedPatient?.religion}
        checkout={
          props.selectedEmr?.checkout_cause_name ||
          props.selectedEmr?.checkout_cause_label
        }
        remark={
          props?.CreateUpdateEncounterSequence?.encounterList?.filter(
            (item: any) => item.number === props.selectedPatient?.encounter_no
          )?.[0]?.remark || props.selectedEncounter?.remark
        }
        note={
          props?.CreateUpdateEncounterSequence?.encounterList?.filter(
            (item: any) => item.number === props.selectedPatient?.encounter_no
          )?.[0]?.note || props.selectedEncounter?.note
        }
        //PatienPanel
        tobacco={props?.patientAssessment?.data?.tobacco}
        narcotic={props?.patientAssessment?.data?.narcotic}
        underlyingDisease={underlyingDisease}
        selectedADR={ADR}
        weigth={
          props?.weightInfo?.weight !== undefined
            ? `${props?.weightInfo?.weight}  ${props?.weightInfo?.weight_unit}`
            : null
        }
        height={
          props?.heightInfo?.height !== undefined
            ? `${props?.heightInfo?.height}  ${props?.heightInfo?.height_unit}`
            : null
        }
        vitalSign={vitalSignList}
        labResult={drugLabInteraction}
        OnADR={() => setOpenModSelectedADR(true)}
        OnUnderlyingDisease={() => setOpenModSelectedUD(true)}
        OnLab={() => setOpenModSelectedLAB(true)}
        OnVitalSign={() => setOpenModSelectedVS(true)}
        // telemed={null}
        ButtonSuspending={
          ["การเงิน"].includes(props.divisionType || "") && (
            <CardSuspending
              onEvent={props.onEvent}
              setProp={props.setProp}
              // seq
              runSequence={props.runSequence}
              MedicalFeeHistorySequence={props.MedicalFeeHistorySequence}
              // data
              patientId={props.selectedEncounter?.patient}
              encounterSuspension={props.encounterSuspension}
              // CommonInterface
              buttonLoadCheck={props.buttonLoadCheck}
              errorMessage={props.errorMessage}
              loadingStatus={props.loadingStatus}
              // options
              masterOptions={props.masterOptions}
            />
          )
        }
        //Allergy Status
        otherImage={
          showOtherStatus === false ? null : showTableOther === false ? (
            <Image
              display={showOtherStatus}
              width={35}
              height={35}
              onClick={handleOnClickNOA}
              src={IMAGES.other_gray}
            ></Image>
          ) : (
            <Image
              display={showOtherStatus}
              width={35}
              height={35}
              onClick={handleOnClickOther}
              src={IMAGES.other_red}
            ></Image>
          )
        }
        foodImage={
          showFoodStatus === false ? null : showTableFood === false ? (
            <Image
              display={showFoodStatus}
              width={35}
              height={35}
              onClick={handleOnClickNFA}
              src={IMAGES.food_gray}
            ></Image>
          ) : (
            <Image
              display={showFoodStatus}
              width={35}
              height={35}
              onClick={handleOnClickFood}
              src={IMAGES.food_red}
            ></Image>
          )
        }
        drugImage={
          showDrugStatus === false ? null : showTable === false ? (
            <Image
              display={showDrugStatus}
              width={35}
              height={35}
              onClick={handleOnClickNKA}
              src={IMAGES.drug_gray}
            ></Image>
          ) : findverify?.legth === 0 ? (
            <Image
              display={showDrugStatus}
              width={35}
              height={35}
              onClick={handleOnClickDrug}
              src={IMAGES.drug_yellow}
            ></Image>
          ) : findverify?.length !== 0 &&
            drug_rec_notinactive?.length !== 0 &&
            findnotverify?.length === 0 ? (
            <Image
              display={showDrugStatus}
              width={35}
              height={35}
              onClick={handleOnClickDrug}
              src={IMAGES.drug_red}
            ></Image>
          ) : findverifylock?.length !== 0 &&
            drug_rec_notinactive?.length !== 0 &&
            findnotverify?.length === 0 ? (
            <Image
              display={showDrugStatus}
              width={35}
              height={35}
              onClick={handleOnClickDrug}
              src={IMAGES.drug_red}
            ></Image>
          ) : findverifylock?.length !== 0 &&
            drug_rec_notinactive?.length === 0 &&
            findnotverify?.length === 0 ? (
            <Image
              display={showDrugStatus}
              width={35}
              height={35}
              onClick={handleOnClickDrug}
              src={IMAGES.drug_red}
            ></Image>
          ) : findverify?.length !== 0 &&
            drug_rec_notinactive?.length === 0 &&
            findnotverify?.length === 0 ? (
            <Image
              display={showDrugStatus}
              width={35}
              height={35}
              onClick={handleOnClickDrug}
              src={IMAGES.drug_red}
            ></Image>
          ) : findverify?.length !== 0 &&
            drug_rec_notinactive?.length !== 0 &&
            findverifylock?.length !== 0 &&
            findnotverify?.length === 0 ? (
            <Image
              display={showDrugStatus}
              width={35}
              height={35}
              onClick={handleOnClickDrug}
              src={IMAGES.drug_red}
            ></Image>
          ) : findverify?.length !== 0 &&
            findnotverify?.length === 0 &&
            findverifylock?.length !== 0 ? (
            <Image
              display={showDrugStatus}
              width={35}
              height={35}
              onClick={handleOnClickDrug}
              src={IMAGES.drug_red}
            ></Image>
          ) : (
            <Image
              display={showDrugStatus}
              width={35}
              height={35}
              onClick={handleOnClickDrug}
              src={IMAGES.drug_yellow}
            ></Image>
          )
        }
        //Allergy Modal
        modalFood={
          <Modal
            open={openFood}
            onClose={() => setOpenFood(false)}
            onOpen={() => setOpenFood(true)}
            size="large"
          >
            <Segment
              inverted
              className="blue"
              style={{ margin: 0, fontWeight: "bold", fontSize: "large" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "row",
                  alignItems: "end",
                  marginLeft: "20px",
                }}
              >
                <Image width={45} height={45} src={IMAGES.food_white}></Image>
                <div style={{ marginLeft: "10px" }}> ประวัติการแพ้อาหาร </div>
              </div>
            </Segment>
            <Modal.Content>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "row",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <Image width={30} height={30} src={IMAGES.food_red}></Image>
                  <div
                    style={{
                      marginLeft: "10px",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    มีประวัติแพ้อาหาร{" "}
                  </div>
                </div>
                <div>
                  <div style={{ padding: "30px 20px 0px 20px" }}>
                    <Table
                      data={adrListDataFood}
                      getTrProps={(state: any, rowInfo: any) => {
                        // console.log("rowInfo", rowInfo);
                        if (rowInfo && rowInfo.row) {
                          let background =
                            rowInfo?.original?.id ===
                            (props.AllergySequence?.adrSelected !== null
                              ? props.AllergySequence?.adrSelected?.id
                              : null)
                              ? "blueSelectedRow"
                              : "";
                          return {
                            className: background,
                          };
                        } else {
                          return {};
                        }
                      }}
                      headers="ชื่อ,อาการ,ผู้บันทึก,วันที่-เวลา"
                      keys="name,note,edit_user,edited"
                      minRows="5"
                      style={{ height: "200px" }}
                      showPagination={false}
                      widths="auto,200,150,150"
                    ></Table>
                  </div>
                </div>
              </div>
            </Modal.Content>
          </Modal>
        }
        modalOther={
          <Modal
            open={openOther}
            onClose={() => setOpenOther(false)}
            onOpen={() => setOpenOther(true)}
            size="large"
          >
            <Segment
              inverted
              className="blue"
              style={{ margin: 0, fontWeight: "bold", fontSize: "large" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "row",
                  alignItems: "end",
                  marginLeft: "20px",
                }}
              >
                <Image width={45} height={45} src={IMAGES.other_white}></Image>
                <div style={{ marginLeft: "10px" }}>
                  {" "}
                  ประวัติการแพ้สารอื่นๆ{" "}
                </div>
              </div>
            </Segment>
            <Modal.Content>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "row",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <Image width={30} height={30} src={IMAGES.other_red}></Image>
                  <div
                    style={{
                      marginLeft: "10px",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    มีประวัติแพ้สารอื่นๆ
                  </div>
                </div>
                <div>
                  <div style={{ padding: "30px 20px 0px 20px" }}>
                    <Table
                      data={adrListDataOther}
                      getTrProps={(state: any, rowInfo: any) => {
                        // console.log("rowInfo", rowInfo);
                        if (rowInfo && rowInfo.row) {
                          let background =
                            rowInfo?.original?.id ===
                            (props.AllergySequence?.adrSelected !== null
                              ? props.AllergySequence?.adrSelected?.id
                              : null)
                              ? "blueSelectedRow"
                              : "";
                          return {
                            className: background,
                          };
                        } else {
                          return {};
                        }
                      }}
                      headers="ชื่อ,อาการ,ผู้บันทึก,วันที่-เวลา"
                      keys="name,note,edit_user,edited"
                      minRows="5"
                      style={{ height: "200px" }}
                      showPagination={false}
                      widths="auto,200,150,150"
                    ></Table>
                  </div>
                </div>
              </div>
            </Modal.Content>
          </Modal>
        }
        modalDrug={
          <Modal
            open={openDrug}
            onClose={() => setOpenDrug(false)}
            onOpen={() => setOpenDrug(true)}
            size="large"
          >
            <Segment
              inverted
              className="blue"
              style={{ margin: 0, fontWeight: "bold", fontSize: "large" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "row",
                  alignItems: "end",
                  marginLeft: "20px",
                }}
              >
                <Image width={45} height={45} src={IMAGES.drug_white}></Image>
                <div style={{ marginLeft: "10px" }}> ประวัติการแพ้ยา </div>
              </div>
            </Segment>
            <Modal.Content>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "row",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  {findverify?.legth === 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "row",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        display={showDrugStatus}
                        width={30}
                        height={30}
                        src={IMAGES.drug_yellow}
                      ></Image>
                      <div
                        style={{
                          marginLeft: "10px",
                          color: "#F3AD25",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        มีประวัติแพ้ยา{" "}
                      </div>
                    </div>
                  ) : findverify?.length !== 0 &&
                    drug_rec_notinactive?.length !== 0 &&
                    findnotverify?.length === 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "row",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        display={showDrugStatus}
                        width={30}
                        height={30}
                        src={IMAGES.drug_red}
                      ></Image>
                      <div
                        style={{
                          marginLeft: "10px",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        มีประวัติแพ้ยา{" "}
                      </div>
                    </div>
                  ) : findverifylock?.length !== 0 &&
                    drug_rec_notinactive?.length !== 0 &&
                    findnotverify?.length === 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "row",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        display={showDrugStatus}
                        width={30}
                        height={30}
                        src={IMAGES.drug_red}
                      ></Image>
                      <div
                        style={{
                          marginLeft: "10px",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        มีประวัติแพ้ยา{" "}
                      </div>
                    </div>
                  ) : findverifylock?.length !== 0 &&
                    drug_rec_notinactive?.length === 0 &&
                    findnotverify?.length === 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "row",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        display={showDrugStatus}
                        width={30}
                        height={30}
                        src={IMAGES.drug_red}
                      ></Image>
                      <div
                        style={{
                          marginLeft: "10px",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        มีประวัติแพ้ยา{" "}
                      </div>
                    </div>
                  ) : findverify?.length !== 0 &&
                    drug_rec_notinactive?.length === 0 &&
                    findnotverify?.length === 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "row",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        display={showDrugStatus}
                        width={30}
                        height={30}
                        src={IMAGES.drug_red}
                      ></Image>
                      <div
                        style={{
                          marginLeft: "10px",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        มีประวัติแพ้ยา{" "}
                      </div>
                    </div>
                  ) : findverify?.length !== 0 &&
                    drug_rec_notinactive?.length !== 0 &&
                    findverifylock?.length !== 0 &&
                    findnotverify?.length === 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "row",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        display={showDrugStatus}
                        width={30}
                        height={30}
                        src={IMAGES.drug_red}
                      ></Image>
                      <div
                        style={{
                          marginLeft: "10px",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        มีประวัติแพ้ยา{" "}
                      </div>
                    </div>
                  ) : findverify?.length !== 0 &&
                    findnotverify?.length === 0 &&
                    findverifylock?.length !== 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "row",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        display={showDrugStatus}
                        width={30}
                        height={30}
                        src={IMAGES.drug_red}
                      ></Image>
                      <div
                        style={{
                          marginLeft: "10px",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        มีประวัติแพ้ยา{" "}
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "row",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        display={showDrugStatus}
                        width={30}
                        height={30}
                        src={IMAGES.drug_yellow}
                      ></Image>
                      <div
                        style={{
                          marginLeft: "10px",
                          color: "#F3AD25",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        มีประวัติแพ้ยา{" "}
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginLeft: "780px",
                    }}
                  >
                    <Checkbox
                      checked={props.AllergySequence?.showInactive}
                      label="เเสดงประวัติ Inactived"
                      onChange={handleShowInactive}
                    ></Checkbox>
                  </div>
                </div>
                <div>
                  <div style={{ padding: "30px 20px 0px 20px" }}>
                    <Table
                      data={adrListData}
                      getTrProps={(state: any, rowInfo: any) => {
                        // console.log("rowInfo", rowInfo);
                        if (rowInfo && rowInfo.row) {
                          let background =
                            rowInfo?.original?.id ===
                            (props.AllergySequence?.adrSelected !== null
                              ? props.AllergySequence?.adrSelected?.id
                              : null)
                              ? "blueSelectedRow"
                              : "";
                          return {
                            className: background,
                          };
                        } else {
                          return {};
                        }
                      }}
                      headers=",ชื่อ,อาการ,ความรุนแรง,ข้อความแจ้งเตือน,ผู้บันทึก,วันที่-เวลา"
                      keys="status,name,symptoms,severity_name,note,edit_user,edited"
                      minRows="5"
                      style={{ height: "200px" }}
                      showPagination={false}
                      widths="60,auto,130,130,150,100,100"
                    ></Table>
                  </div>
                </div>
              </div>
            </Modal.Content>
          </Modal>
        }
        modalKDA={
          <Modal
            open={openNKA}
            onClose={() => setOpenNKA(false)}
            onOpen={() => setOpenNKA(true)}
            size="large"
          >
            <Segment
              inverted
              className="blue"
              style={{ margin: 0, fontWeight: "bold", fontSize: "large" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "row",
                  alignItems: "end",
                  marginLeft: "20px",
                }}
              >
                <Image width={45} height={45} src={IMAGES.drug_white}></Image>
                <div style={{ marginLeft: "10px" }}> ประวัติการแพ้ยา </div>
              </div>
            </Segment>
            <Modal.Content>
              <div style={{ fontWeight: "bold" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "row",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <Image width={30} height={30} src={IMAGES.drug_gray}></Image>
                  <div style={{ marginLeft: "10px" }}> ปฏิเสธแพ้ยา </div>
                </div>
                <div>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginTop: "20px",
                    }}
                  >
                    <Container
                      style={{ fontSize: "1.7rem" }}
                      textAlign="center"
                    >
                      {" "}
                      ผู้ป่วยปฏิเสธแพ้ยา [Not Know Allergy]
                    </Container>
                    <Container
                      style={{
                        fontSize: "1rem",
                        fontWeight: "normal",
                        marginTop: "20px",
                      }}
                      textAlign="center"
                    >
                      ผู้บันทึก :{" "}
                      {props?.AllergySequence?.adrList?.[0]?.edit_user} [
                      {props?.selectedDivision?.name}]
                    </Container>
                    <Container
                      style={{ fontSize: "1rem", fontWeight: "normal" }}
                      textAlign="center"
                    >
                      วันที่-เวลา :{" "}
                      {props?.AllergySequence?.adrList?.[0]?.edited}
                    </Container>
                  </div>
                </div>
              </div>
            </Modal.Content>
          </Modal>
        }
        modalKFA={
          <Modal
            open={openNFA}
            onClose={() => setOpenNFA(false)}
            onOpen={() => setOpenNFA(true)}
            size="large"
          >
            <Segment
              inverted
              className="blue"
              style={{ margin: 0, fontWeight: "bold", fontSize: "large" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "row",
                  alignItems: "end",
                  marginLeft: "20px",
                }}
              >
                <Image width={45} height={45} src={IMAGES.food_white}></Image>
                <div style={{ marginLeft: "10px" }}> ประวัติการแพ้อาหาร </div>
              </div>
            </Segment>
            <Modal.Content>
              <div style={{ fontWeight: "bold" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "row",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <Image width={30} height={30} src={IMAGES.food_gray}></Image>
                  <div style={{ marginLeft: "10px" }}> ปฏิเสธแพ้อาหาร </div>
                </div>
                <div>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginTop: "20px",
                    }}
                  >
                    <Container
                      style={{ fontSize: "1.7rem" }}
                      textAlign="center"
                    >
                      {" "}
                      ผู้ป่วยปฏิเสธแพ้อาหาร [Not Know Allergy]
                    </Container>
                    <Container
                      style={{
                        fontSize: "1rem",
                        fontWeight: "normal",
                        marginTop: "20px",
                      }}
                      textAlign="center"
                    >
                      ผู้บันทึก : {findfood?.[0]?.edit_user} [
                      {props?.selectedDivision?.name}]
                    </Container>
                    <Container
                      style={{ fontSize: "1rem", fontWeight: "normal" }}
                      textAlign="center"
                    >
                      วันที่-เวลา : {findfood?.[0]?.edited}
                    </Container>
                  </div>
                </div>
              </div>
            </Modal.Content>
          </Modal>
        }
        modalKOA={
          <Modal
            open={openNOA}
            onClose={() => setOpenNOA(false)}
            onOpen={() => setOpenNOA(true)}
            size="large"
          >
            <Segment
              inverted
              className="blue"
              style={{ margin: 0, fontWeight: "bold", fontSize: "large" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "row",
                  alignItems: "end",
                  marginLeft: "20px",
                }}
              >
                <Image width={45} height={45} src={IMAGES.other_white}></Image>
                <div style={{ marginLeft: "10px" }}>
                  {" "}
                  ประวัติการแพ้สารอื่นๆ{" "}
                </div>
              </div>
            </Segment>
            <Modal.Content>
              <div style={{ fontWeight: "bold" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "row",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <Image width={30} height={30} src={IMAGES.other_gray}></Image>
                  <div style={{ marginLeft: "10px" }}> ปฏิเสธแพ้สารอื่นๆ </div>
                </div>
                <div>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "large",
                      marginTop: "20px",
                    }}
                  >
                    <Container
                      style={{ fontSize: "1.7rem" }}
                      textAlign="center"
                    >
                      {" "}
                      ผู้ป่วยปฏิเสธแพ้สารอื่นๆ [Not Know Allergy]
                    </Container>
                    <Container
                      style={{
                        fontSize: "1rem",
                        fontWeight: "normal",
                        marginTop: "20px",
                      }}
                      textAlign="center"
                    >
                      ผู้บันทึก : {findother?.[0]?.edit_user} [
                      {props?.selectedDivision?.name}]
                    </Container>
                    <Container
                      style={{ fontSize: "1rem", fontWeight: "normal" }}
                      textAlign="center"
                    >
                      วันที่-เวลา : {findother?.[0]?.edited}
                    </Container>
                  </div>
                </div>
              </div>
            </Modal.Content>
          </Modal>
        }
        btnBindId={
          !props.chatDetail &&
          !props.selectedEncounter?.id && (
            <Button
              color="blue"
              style={{
                width: "140px",
                marginLeft: "-52px",
                minWidth: "max-content",
              }}
              onClick={() => {
                setOpenModDischarge({ type: "BIND_ID" });
              }}
            >
              ผุก HN Telemed
            </Button>
          )
        }
        statusAndAction={
          <div
            style={{
              display: "grid",
              justifyContent: "flex-end",
              marginTop: "-26.5px",
            }}
          >
            {/* <div>{`สถานะ: ${props.selectedEncounter?.status_name}`}</div> */}
            {props.selectedOrOrder &&
              ["OPERATION", "HOLDING_ROOM", "OPERATING_ROOM", "PACU"].includes(
                props.selectedOrOrder?.status_name
              ) &&
              props.django?.user?.role_types?.includes("DOCTOR") && (
                <Button
                  color="red"
                  size="mini"
                  onClick={() => setOpenModORCheckout(true)}
                >
                  Check out
                </Button>
              )}
            {props.selectedOrOrder &&
              props.selectedEmr?.checkout_cause &&
              ["OPERATION", "HOLDING_ROOM", "OPERATING_ROOM", "PACU"].includes(
                props.selectedOrOrder?.status_name
              ) &&
              props.django?.user?.role_types?.includes("REGISTERED_NURSE") && (
                <Button
                  color="black"
                  size="mini"
                  onClick={() => setOpenModORDischarge(true)}
                >
                  Discharge
                </Button>
              )}
            {props.selectedOrOrder &&
              props.selectedOrOrder?.status_name === "COMPLETED" &&
              !props.selectedOrOrder?.is_paid && (
                <Button
                  color="red"
                  size="mini"
                  onClick={() => setOpenModORCancelDischarge(true)}
                >
                  ยกเลิก Discharge
                </Button>
              )}
            {props.selectedEncounter?.id &&
              buttonActions.map(
                (item) =>
                  [
                    ...allowedActions,
                    // "OPD_CANCEL_CHECK_IN",
                    // "OPD_CHECK_OUT",
                  ]?.includes(item.action) && (
                    <Button
                      size="tiny"
                      color={item.color as any}
                      style={{
                        ...(ALLOWED_ACTIONS.OPD_DISCHARGE === item.action
                          ? {}
                          : {
                              fontSize: "0.75rem",
                              padding: "0.65rem 0.75rem",
                            }),
                        margin: "0.2rem",
                      }}
                      onClick={() => {
                        if (
                          ALLOWED_ACTIONS.OPD_CANCEL_CHECK_IN === item.action
                        ) {
                          handleAction(item.action);
                        } else {
                          setOpenModDischarge({ type: item.action });
                        }
                      }}
                    >
                      {BUTTON_ACTIONS[item.action]}
                    </Button>
                  )
              )}

            {BUTTON_DISCHARGE[statusLabel] && (
              <Button
                color="blue"
                size="mini"
                style={{ margin: "0.2rem 0" }}
                onClick={() =>
                  setOpenModDischarge({ type: BUTTON_DISCHARGE[statusLabel] })
                }
                disabled={statusLabel === DISCHARGE_STATUS.CASHIER_REQUEST}
              >
                {BUTTON_DISCHARGE[statusLabel]}
              </Button>
            )}
            {props?.selectedShippingOrder?.[0]?.tele_pharmacy_status ===
              "REQUESTED" && (
              <Button
                color="teal"
                size="mini"
                style={{ margin: "0.2rem 0" }}
                onClick={() => {
                  props.onEvent({
                    message: "HandleDischargeConsult",
                    params: {
                      patientAppointment: props?.selectedShippingOrder?.[0],
                      card: CARD_PATIENT_PANEL,
                    },
                  });
                }}
              >
                จบการให้คำแนะนำ
              </Button>
            )}
          </div>
        }
        isShowPregnancy={
          props.CreateUpdateEncounterSequence?.pregnancy_status === 3 &&
          props.selectedPatient?.gender_name === "Female" &&
          parseInt(props.selectedPatient?.age) >= 15 &&
          parseInt(props.selectedPatient?.age) <= 50
        }
        pregnancyImage={
          props.CreateUpdateEncounterSequence?.pregnancy_period === 0 ? (
            <Image
              width={50}
              src={IMAGES.pregnancyPeriodNA}
              onClick={() => {
                setOpenPregnancyLog(true);
                props.runSequence({
                  sequence: "CreateUpdateEncounter",
                  action: "getLogPregnancy",
                });
              }}
            ></Image>
          ) : props.CreateUpdateEncounterSequence?.pregnancy_period === 1 ? (
            <Image
              width={50}
              src={IMAGES.pregnancyPeriod1}
              onClick={() => {
                setOpenPregnancyLog(true);
                props.runSequence({
                  sequence: "CreateUpdateEncounter",
                  action: "getLogPregnancy",
                });
              }}
            ></Image>
          ) : props.CreateUpdateEncounterSequence?.pregnancy_period === 2 ? (
            <Image
              width={50}
              src={IMAGES.pregnancyPeriod2}
              onClick={() => {
                setOpenPregnancyLog(true);
                props.runSequence({
                  sequence: "CreateUpdateEncounter",
                  action: "getLogPregnancy",
                });
              }}
            ></Image>
          ) : (
            <Image
              width={50}
              src={IMAGES.pregnancyPeriod3}
              onClick={() => {
                setOpenPregnancyLog(true);
                props.runSequence({
                  sequence: "CreateUpdateEncounter",
                  action: "getLogPregnancy",
                });
              }}
            ></Image>
          )
        }
      />
      <WebcamProfile
        open={openWebCam}
        onClose={() => setOpenWebCam(false)}
        setNewImage={handleSaveNewImage}
      />

      <Modal
        open={
          !!openModDischarge &&
          (statusLabel === DISCHARGE_STATUS.PLAN_DISCHARGE ||
            openModDischarge.type === ALLOWED_ACTIONS.OPD_CHECK_OUT)
        }
        size="small"
      >
        <CardDischargeReact
          setProp={props.setProp}
          onEvent={props.onEvent}
          doctorOptions={{ items: [] }}
          userId={props.userId}
          dischargeDoctor={props.dischargeDoctor}
          controller={{
            ...props.cardDischargeController,
            discharge: (
              emr_id: number,
              checkout_cause: any,
              division: number,
              require_diagnosis: boolean,
              approve_by: any
            ) => {
              return handleCheckout({
                emr_id,
                checkout_cause,
                require_diagnosis,
                approve_by,
              });
            },
          }}
          DJANGO={props.django}
          PATIENT_DATA={props.patientData}
          // config
          // hideSupervisorPassword={true}
          isCUDent={true}
          encounterInfo={
            props.selectedEncounter &&
            props.selectedEncounter?.approve_by === null
              ? {
                  ...props.selectedEncounter,
                  approve_by: props.selectedEncounter.doctor,
                  approve_by_name: props.selectedEncounter.doctor_name,
                }
              : props.selectedEncounter
          }
          doctorRef={doctorRef}
          errorMessage={props.errorMessage?.[CARD_PATIENT_DISCHARGE]}
          loginVia={props.loginVia}
          approve_by={props.approve_by}
          // callback
          onDischarged={async (checkoutCause: any) => {}}
          onClose={handleCloseModDischarge}
          medReconcileIndex={props.medReconcileIndex}
          // onCheckout={handleCheckout}
        />
      </Modal>

      <ModConfirm
        openModal={
          !!openModDischarge &&
          statusLabel === DISCHARGE_STATUS.DOCTOR_DISCHARGE
        }
        titleName="กรุณายืนยันการ Request Discharge"
        size="mini"
        denyButtonText="ยกเลิก"
        approveButtonText="ยืนยัน"
        onApprove={() => {
          handleCheckout();
        }}
        content={
          <div style={{ margin: "-1rem 0" }}>
            <ErrorMessage
              error={props.errorMessage?.[CARD_PATIENT_DISCHARGE]}
            />
            ระบบจะทำการ Off รายการ Continue ทั้งหมด
            ก่อนส่งรายการค่าใช้จ่ายไปการเงิน
          </div>
        }
        onDeny={handleCloseModDischarge}
        titleColor={"blue"}
      />

      <ModConfirm
        openModal={
          !!openModDischarge &&
          [
            ALLOWED_ACTIONS.OPD_CANCEL_QUEUE_EXAM,
            ALLOWED_ACTIONS.OPD_QUEUE_EXAM,
          ].includes(openModDischarge?.type || "")
        }
        titleName="แจ้งเตือน!!!"
        size="mini"
        denyButtonText="ไม่"
        approveButtonText="ใช่"
        onApprove={() => {
          handleAction();
        }}
        content={
          <div style={{ margin: "-1rem 0 -2rem" }}>
            <ErrorMessage
              error={props.errorMessage?.[CARD_PATIENT_DISCHARGE]}
            />
            <Dimmer
              active={props.loadingStatus?.[CARD_PATIENT_DISCHARGE]}
              inverted
            >
              <Loader inverted></Loader>
            </Dimmer>
            {ALLOWED_ACTIONS.OPD_CANCEL_QUEUE_EXAM ===
              openModDischarge?.type && (
              <>
                ต้องการนำผู้ป่วย {props.selectedPatient?.full_name || ""}{" "}
                ออกจากคิวรอตรวจหรือไม่
              </>
            )}
            {ALLOWED_ACTIONS.OPD_QUEUE_EXAM === openModDischarge?.type && (
              <>
                ส่งผู้ป่วย {props.selectedPatient?.full_name || ""}{" "}
                เข้าคิวรอตรวจ
              </>
            )}
          </div>
        }
        onDeny={handleCloseModDischarge}
        titleColor={"blue"}
      />

      <Modal
        open={
          !!openModDischarge &&
          (statusLabel === DISCHARGE_STATUS.CASHIER_DISCHARGED ||
            openModDischarge?.type === "OPD_DISCHARGE")
        }
        onClose={handleCloseModDischarge}
        closeOnDimmerClick
      >
        <DischargeForm
          patientCondition={props.masterOptions?.patientCondition || []}
          patientDischarge={props.masterOptions?.patientDischarge || []}
          cleaningChoice={props.masterOptions?.cleaningChoice || []}
          onSave={handleCheckout}
          errorMessage={props.errorMessage?.[CARD_PATIENT_DISCHARGE]}
          loading={props.loadingStatus?.[CARD_PATIENT_DISCHARGE]}
          showCleaning={statusLabel === DISCHARGE_STATUS.CASHIER_DISCHARGED}
          showModComplete={openModDischarge?.type === "OPD_DISCHARGE"}
          onComplete={handleComplete}
        />
      </Modal>

      <ModConfirm
        openModal={
          !!openModDischarge &&
          openModDischarge?.type === ALLOWED_ACTIONS.CANCEL_CHECKOUT
        }
        // titleName="แจ้งเตือน"
        size="mini"
        denyButtonText="ยกเลิก"
        approveButtonText="ยืนยัน"
        onApprove={() => {
          handleAction();
        }}
        content={
          <div
            style={{ margin: "-1rem 0", lineHeight: 1.75, textAlign: "center" }}
          >
            <ErrorMessage
              error={props.errorMessage?.[CARD_PATIENT_DISCHARGE]}
            />
            <div>เมื่อยกเลิก checkout supervisor</div>
            <div>จำเป็นต้อง approve ใหม่อีกครั้ง</div>
            <div>คุณต้องการแก้ไขหรือไม่</div>
          </div>
        }
        onDeny={handleCloseModDischarge}
        titleColor={"red"}
      />

      <ModInfo
        open={!!openModInfo}
        titleName={openModInfo?.type === "success" ? "สำเร็จ" : ""}
        titleColor={openModInfo?.type === "success" ? "green" : "red"}
        onApprove={handleCloseModDischarge}
      >
        <div>{openModInfo?.message}</div>
      </ModInfo>

      {openModNote && (
        <ModConfirm
          openModal={openModNote}
          titleName={"กรอกข้อมูลที่ต้องการบันทึกเพิ่มเติม"}
          titleColor={"blue"}
          textConTent={<span style={{ fontWeight: "bold" }}> Note</span>}
          onApprove={handleNoteConfirmed}
          denyButtonColor="red"
          approveButtonColor="blue"
          basic={true}
          onDeny={handleNoteDeny}
          showTextNote={true}
          setTextNote={(e: any, data: any) => {
            props.setProp("AllergySequence.actionNote", data.value);
          }}
          textNote={props.AllergySequence?.actionNote || ""}
          size={"tiny"}
          errorMessageText={props.errorMessage?.[CARD_ALLERGY]}
        />
      )}

      <Modal
        open={openModSelectedADR}
        size="large"
        onClose={handleCloseModSelectedADR}
      >
        <ModAllergyUX
          AllergyList={allergyListMod}
          options={[
            { key: 1, value: 1, text: "ผู้ป่วยแจ้ง" },
            { key: 2, value: 2, text: "ญาติผู้ป่วยแจ้ง" },
            { key: 3, value: 3, text: "มาจากโรงพยาบาลอื่น" },
          ]}
          note={props.AllergySequence?.adrSelected?.newNote}
          onChangeNote={handleOnChangeNoteADR}
          OnAddADR={() =>
            props.runSequence({
              sequence: "Allergy",
              action: "newNote",
              card: CARD_PATIENT_PANEL,
            })
          }
        />
      </Modal>

      <Modal
        open={openModSelectedUD}
        size="large"
        onClose={handleCloseModSelectedUD}
      >
        <CardUnderlyingDiseaseUX
          encounter={props.selectedEncounter?.pk}
          underlyingDiseaseList={underlyingDiseaseList}
          message={props.messageProblemDPO}
          onChangeData={handleChangeMessageProblemDPO}
          onAddData={() => {
            props.onEvent({
              message: "HandleAddMessageProblemDPO",
              params: {
                action: "createProblemList",
                patient: props.selectedEncounter?.patient,
              },
            });
          }}
        />
      </Modal>

      <Modal
        open={openModSelectedLAB}
        size="large"
        onClose={handleCloseModSelectedLAB}
      >
        <Modal.Header> Lab Result</Modal.Header>
        <div style={{ padding: "5px" }}>
          <Table
            data={labData}
            headers="Code,Name,Value,Unit,Result Date"
            keys="code,name,value,unit,result_date"
            minRows="10"
            showPagination={false}
            style={{ height: "300px" }}
          ></Table>
        </div>
      </Modal>

      <Modal
        open={openModSelectedVS}
        size="large"
        onClose={handleCloseModSelectedVS}
      >
        <CardVitalSign
          closeable={props?.closeable}
          toggleable={props?.toggleable}
          callOnDidMount={props?.callOnDidMount}
          useCheckboxDateManual={true}
          vitalSignTypeByAge={props.selectedPatient?.age}
          vitalSignTypeByEncounter={props.selectedEncounter?.id}
          defaultDateFilter={props.selectedEncounter?.type === "IPD"}
          onGetLastHeight={props?.onGetLastHeight}
          onGetVitalSignType={props?.onGetVitalSignType}
          onGetVitalSign={props?.onGetVitalSign}
          onNewMeasurement={props?.onNewMeasurement}
          onUpdateVitalsignValue={props?.onUpdateVitalsignValue}
          onDeleteMeasurement={props?.onDeleteMeasurement}
        />
      </Modal>

      <Modal open={!!actionLogId}>
        <CardAllergyActionLog
          id={actionLogId}
          runSequence={props.runSequence}
          actionLogData={actionLogData}
          onDismiss={() => {
            setActionLogId(null);
          }}
        />
      </Modal>

      <Modal open={openModADRNote} onClose={handleCloseModADRNote}>
        <CardAdverseReactionNote
          searchedItemList={props.searchedItemList}
          masterOptions={props.masterOptions}
          onCancelNewADRNote={handleCancelNewADRNote}
          onSaveNewADRNote={handleSaveNewADRNote}
          AllergySequence={props.AllergySequence}
          onEvent={props.onEvent}
          setProp={props.setProp}
          adrSelected={props.AllergySequence?.adrSelected}
        />
      </Modal>

      {openModNote && (
        <ModConfirm
          openModal={openModNote}
          titleName={"กรอกข้อมูลที่ต้องการบันทึกเพิ่มเติม"}
          titleColor={"blue"}
          textConTent={<span style={{ fontWeight: "bold" }}> Note</span>}
          onApprove={handleNoteConfirmed}
          denyButtonColor="red"
          approveButtonColor="blue"
          basic={true}
          onDeny={handleNoteDeny}
          showTextNote={true}
          setTextNote={(e: any, data: any) => {
            props.setProp("AllergySequence.actionNote", data.value);
          }}
          textNote={props.AllergySequence?.actionNote || ""}
          size={"tiny"}
          errorMessageText={props.errorMessage?.[CARD_ALLERGY]}
        />
      )}

      <Modal
        open={!!openModDischarge && openModDischarge?.type === "BIND_ID"}
        style={{ width: "40%" }}
        size="small"
        closeOnDimmerClick
        onClose={handleCloseModDischarge}
      >
        <Dimmer active={props.loadingStatus?.[CARD_PATIENT_DISCHARGE]} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <CardBindIdUX
          hn={props.selectedPatient?.hn || ""}
          fullName={props.selectedPatient?.real_name || ""}
          onCancel={handleCloseModDischarge}
          onConfirm={() => {
            handleCheckout();
          }}
          onClose={handleCloseModDischarge}
          onChange={(e: any, v: any) => setIhid(v.value)}
          ihid={ihid}
          ErrorMessage={
            <ErrorMessage
              style={{ marginBottom: "1rem" }}
              error={props.errorMessage?.[CARD_PATIENT_DISCHARGE]}
            />
          }
        />
      </Modal>

      {openModORCheckout && (
        <ORCheckoutComponent
          onEvent={props.onEvent}
          onCloseMod={() => setOpenModORCheckout(false)}
          openMod={openModORCheckout}
          selectedOrOrder={props.selectedOrOrder}
          masterOptions={props.masterOptions}
        />
      )}

      {openModORDischarge && (
        <ORDischargeComponent
          onEvent={props.onEvent}
          setProp={props.setProp}
          onCloseMod={() => setOpenModORDischarge(false)}
          openMod={openModORDischarge}
          selectedOrOrder={props.selectedOrOrder}
          masterOptions={props.masterOptions}
          orDischargeResult={props.orDischargeResult}
        />
      )}

      {openModORCancelDischarge && (
        <ORCancelDischargeComponent
          onEvent={props.onEvent}
          onCloseMod={() => setOpenModORCancelDischarge(false)}
          openMod={openModORCancelDischarge}
          selectedOrOrder={props.selectedOrOrder}
        />
      )}

      <Modal
        size="mini"
        open={openEditProblemList}
        onClose={() => {
          setOpenEditProblemList(false);
        }}
      >
        <Segment
          inverted
          className="yellow"
          style={{ margin: "0px", fontWeight: "bold" }}
        >
          แก้ไขโรคประจำตัว
        </Segment>
        <Modal.Content>
          <Form>
            <Form.Field width={16}>
              <label>โรคประจำตัว</label>
            </Form.Field>

            <Form.Field width={16}>
              <textarea
                rows={3}
                style={{ resize: "none" }}
                value={textEdit || ""}
                onChange={(v: any) => {
                  setTextEdit(v.target.value);
                }}
              />
            </Form.Field>
          </Form>
          <div
            style={{
              margin: "15px 20% 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              color="green"
              onClick={() => {
                props.onEvent({
                  message: "HandleAddMessageProblemDPO",
                  params: {
                    action: "editProblemList",
                    pk: dataProblemList.pk,
                    patient: dataProblemList.patient,
                    message: textEdit,
                  },
                });
                setOpenEditProblemList(false);
              }}
            >
              ใช่
            </Button>
            <Button
              color="red"
              onClick={() => {
                setOpenEditProblemList(false);
              }}
            >
              ไม่
            </Button>
          </div>
        </Modal.Content>
      </Modal>

      <Modal
        size="large"
        open={openPregnancyLog}
        onClose={() => {
          setOpenPregnancyLog(false);
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: "large", padding: "20px" }}>
          ประวัติการทำงาน
        </div>
        <div>
          <Table
            data={pregnancyLog}
            headers="วันที่/เวลา,รายละเอียด,ผู้บันทึก,แผนกที่บันทึก"
            keys="edited,tracked_data_detail,name,division_name"
            minRows="5"
            showPagination={false}
            style={{ padding: "20px", height: "400px" }}
          ></Table>
        </div>
      </Modal>
    </div>
  );
};

const DischargeForm: React.FunctionComponent<any> = (props) => {
  const [condition, setCondition] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [specify, setSpecify] = useState<string>("");
  const [cleaning, setCleaning] = useState<any>("");
  const [modInfo, setModInfo] = useState<string[] | null>(null);

  const handleChangeCondition = (e: any, v: DropdownProps) => {
    setCondition(v.value);
  };

  const handleDischarge = (e: any, v: DropdownProps) => {
    setStatus(v.value);
  };

  const handleChangeSpecify = (e: any, v: InputProps) => {
    setSpecify(v.value);
  };

  const handleCleaning = (e: any, v: DropdownProps) => {
    setCleaning(v.value);
  };

  const handleSave = () => {
    props.onSave({
      predischarge_condition: condition,
      discharge_status: status,
      discharge_note: specify,
      cleaning: cleaning,
      onModInfo: setModInfo,
    });
  };

  const handleComplete = () => {
    setModInfo(null);
    props.onComplete?.();
  };

  return (
    <Segment>
      <Header>Discharge</Header>
      <Divider />
      <Form>
        <ErrorMessage error={props.errorMessage} />
        <Dimmer active={props.loading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <Form.Group style={{ alignItems: "center" }}>
          <Form.Field width={4}>
            <label style={{ textAlign: "right", width: "100%" }}>
              สภาพผู้ป่วยก่อนจำหน่าย
            </label>
          </Form.Field>
          <Form.Field width={5}>
            <Dropdown
              selection={true}
              options={props.patientCondition}
              value={condition}
              onChange={handleChangeCondition}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group style={{ alignItems: "center" }}>
          <Form.Field width={4}>
            <label style={{ textAlign: "right", width: "100%" }}>
              Discharge Status
            </label>
          </Form.Field>
          <Form.Field width={5}>
            <Dropdown
              selection={true}
              options={props.patientDischarge}
              value={status}
              onChange={handleDischarge}
            />
          </Form.Field>
          <Form.Field width={1}>
            <label style={{ textAlign: "right", width: "100%" }}>ระบุ</label>
          </Form.Field>
          <Form.Field width={6}>
            <Input value={specify} onChange={handleChangeSpecify} />
          </Form.Field>
        </Form.Group>

        {props.showCleaning && (
          <>
            <br />
            <Form.Group style={{ alignItems: "center" }}>
              <Form.Field width={4}>
                <label style={{ textAlign: "right", width: "100%" }}>
                  การทำความสะอาด
                </label>
              </Form.Field>
              <Form.Field width={5}>
                <Dropdown
                  selection={true}
                  options={props.cleaningChoice}
                  value={cleaning}
                  onChange={handleCleaning}
                />
              </Form.Field>
            </Form.Group>
          </>
        )}

        <Form.Group>
          <Form.Field width={14}></Form.Field>
          <Form.Field width={2}>
            <Button color={"green"} fluid onClick={handleSave}>
              บันทึก
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>

      <ModInfo
        open={!!modInfo}
        titleName="Discharge Complete"
        titleColor="blue"
        onApprove={handleComplete}
      >
        <div style={{ textAlign: "initial", lineHeight: 1.75 }}>
          {modInfo?.map((text: string) => (
            <div>{text}</div>
          ))}
        </div>
      </ModInfo>
    </Segment>
  );
};

const ORCheckoutComponent: React.FunctionComponent<any> = (props) => {
  const [payload, setPayload] = useState<any>({
    dead: false,
    checkout_cause: "",
    death_date: null,
    death_time: null,
  });

  useEffect(() => {
    props.onEvent({ message: "GetCheckoutMaster" });
  }, [props.selectedOrOrder]);

  const handleChangeORCheckout = (_event: any, data: any) => {
    if (data.value === "DEAD") {
      setPayload({ ...payload, dead: true, [data.name]: data.value });
    } else {
      setPayload({ ...payload, [data.name]: data.value });
    }
  };

  const handleChangeORDeathDate = (date: string) => {
    setPayload({ ...payload, death_date: date });
  };

  const handleChangeORDeathTime = (_event: any, time: string) => {
    setPayload({ ...payload, death_time: time });
  };

  const closeModORCheckout = () => {
    setPayload({
      dead: false,
      checkout_cause: "",
      death_date: null,
      death_time: null,
    });
    props.onCloseMod();
  };

  const handleORCheckout = () => {
    props.onEvent({
      message: "ORCheckout",
      params: {
        order: props.selectedOrOrder,
        checkout_cause: payload.checkout_cause,
        death_datetime: payload.dead
          ? payload.death_date + "-" + payload.death_time
          : null,
      },
    });
    closeModORCheckout();
  };

  return (
    <>
      <Modal
        size="mini"
        closeIcon
        open={props.openMod}
        onClose={closeModORCheckout}
      >
        <Segment inverted className="blue" style={{ margin: "0px" }}>
          Check out
        </Segment>
        <Modal.Content>
          <Form>
            <Form.Field width={16} required>
              <label>สาเหตุการสิ้นสุดการรักษา</label>
            </Form.Field>
            <Form.Field width={16}>
              <Dropdown
                className="fluidDropdown"
                selection
                name="checkout_cause"
                value={payload.checkout_cause || ""}
                options={props.masterOptions?.planDischargeType || []}
                onChange={handleChangeORCheckout}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={handleORCheckout}>
            ใช่
          </Button>
          <Button color="red" onClick={closeModORCheckout}>
            ไม่
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        size="mini"
        closeIcon
        open={props.openMod && payload.dead}
        onClose={closeModORCheckout}
      >
        <Segment inverted className="blue" style={{ margin: "0px" }}>
          Check out DEAD
        </Segment>
        <Modal.Content>
          <Form>
            <Form.Field width={16}>
              <DateTextBox
                value={payload.death_date}
                onChange={handleChangeORDeathDate}
              />
            </Form.Field>
            <Form.Field width={16}>
              <TimeTextBox
                value={payload.death_time}
                useDefaultTime={true}
                onTimeChange={handleChangeORDeathTime}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={handleORCheckout}>
            ใช่
          </Button>
          <Button color="red" onClick={closeModORCheckout}>
            ไม่
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const ORDischargeComponent: React.FunctionComponent<any> = (props) => {
  const [payload, setPayload] = useState<any>({
    predischarge_condition: "",
    discharge_status: "",
    discharge_note: "",
    transfer_location: "",
    patient_out_date: "",
    patient_out_time: "",
  });
  const [openModConfirmCompensation, setOpenModConfirmCompensation] =
    useState<boolean>(false);
  const [openModConfirmAdmit, setOpenModConfirmAdmit] =
    useState<boolean>(false);

  useEffect(() => {
    props.onEvent({ message: "GetDischargeMaster" });
    props.setProp("orDischargeResult", null);
  }, [props.selectedOrOrder]);

  const closeModORDischarge = () => {
    setPayload({
      predischarge_condition: "",
      discharge_status: "",
      discharge_note: "",
      transfer_location: "",
      patient_out_date: "",
      patient_out_time: "",
    });
    setOpenModConfirmCompensation(false);
    setOpenModConfirmAdmit(false);
    props.onCloseMod();
    props.onEvent({
      message: "GetOROrderByID",
      params: {
        order: props.selectedOrOrder,
      },
    });
  };

  const handleChangeORDischarge = (_event: any, data: any) => {
    setPayload({ ...payload, [data.name]: data.value });
  };

  const handleChangeDischargeDate = (date: string) => {
    setPayload({ ...payload, patient_out_date: date });
  };

  const handleChangeDischargeTime = (_event: any, time: string) => {
    setPayload({ ...payload, patient_out_time: time });
  };

  const handleORDischarge = () => {
    props.onEvent({
      message: "ORDischarge",
      params: {
        order: props.selectedOrOrder,
        result: "",
        admit_detail: "",
        admit_result: "",
        predischarge_condition: payload.predischarge_condition,
        discharge_status: payload.discharge_status,
        discharge_note: payload.discharge_note,
        transfer_location: payload.transfer_location,
        patient_out_datetime: beStringDateTimeToDateObject(
          payload.patient_out_date + "-" + payload.patient_out_time
        ),
        card: CARD_PATIENT_PANEL,
      },
    });
    setOpenModConfirmCompensation(true);
  };

  const handleConfirmCompensation = () => {
    props.onEvent({
      message: "ORConfirmCompensation",
      params: {
        order: props.selectedOrOrder,
      },
    });
    handleShowConfirmAdmit();
  };
  const handleShowConfirmAdmit = () => {
    if (props.orDischargeResult?.admit_result === "CONFIRM") {
      setOpenModConfirmAdmit(true);
    } else if (props.orDischargeResult?.admit_result === "INFO") {
      alert(props.orDischargeResult?.admit_detail);
      closeModORDischarge();
    } else {
      closeModORDischarge();
    }
  };

  const handleConfirmAdmit = () => {
    props.onEvent({
      message: "ORConfirmAdmit",
      params: {
        order: props.selectedOrOrder,
      },
    });

    closeModORDischarge();
  };

  return (
    <>
      <Modal
        size="small"
        closeIcon
        open={props.openMod}
        onClose={closeModORDischarge}
      >
        <Segment inverted className="blue" style={{ margin: "0px" }}>
          Discharge
        </Segment>
        <Modal.Content>
          <Form>
            <Form.Group inline>
              <Form.Field width={4}>
                <label>สภาพผู้ป่วยก่อนจำหน่าย</label>
              </Form.Field>
              <Form.Field width={5}>
                <Dropdown
                  className="fluidDropdown"
                  selection
                  name="predischarge_condition"
                  value={payload.predischarge_condition || ""}
                  options={props.masterOptions?.patientCondition || []}
                  onChange={handleChangeORDischarge}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group inline>
              <Form.Field width={4}>
                <label>Discharge Status</label>
              </Form.Field>
              <Form.Field width={5}>
                <Dropdown
                  className="fluidDropdown"
                  selection
                  name="discharge_status"
                  value={payload.discharge_status || ""}
                  options={props.masterOptions?.patientDischarge || []}
                  onChange={handleChangeORDischarge}
                />
              </Form.Field>
              <Form.Field width={2}>
                <label style={{ width: "100%", textAlign: "right" }}>
                  ระบุ
                </label>
              </Form.Field>
              <Form.Field width={5}>
                <Input
                  name="discharge_note"
                  value={payload.discharge_note || ""}
                  onChange={handleChangeORDischarge}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group inline>
              <Form.Field width={4}>
                <label>Transfer to</label>
              </Form.Field>
              <Form.Field width={12}>
                <Dropdown
                  className="fluidDropdown"
                  selection
                  name="transfer_location"
                  value={payload.transfer_location || ""}
                  options={props.masterOptions?.orTransferLocation || []}
                  onChange={handleChangeORDischarge}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group inline>
              <Form.Field width={4}>
                <label>Patient out</label>
              </Form.Field>
              <Form.Field width={6}>
                <DateTextBox
                  value={payload.patient_out_date}
                  onChange={handleChangeDischargeDate}
                />
              </Form.Field>
              <Form.Field width={6}>
                <TimeTextBox
                  value={payload.patient_out_time}
                  useDefaultTime={true}
                  onTimeChange={handleChangeDischargeTime}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={handleORDischarge}>
            ใช่
          </Button>
          <Button color="red" onClick={closeModORDischarge}>
            ไม่
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={openModConfirmCompensation && props.orDischargeResult}
      >
        <Segment inverted className="blue" style={{ margin: "0px" }}>
          ยืนยันรายการค่าตอบแทน
        </Segment>
        <Modal.Content>{props.orDischargeResult?.result}</Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={handleConfirmCompensation}>
            ใช่
          </Button>
          <Button color="red" onClick={handleShowConfirmAdmit}>
            ไม่
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal size="mini" open={openModConfirmAdmit}>
        <Segment inverted className="blue" style={{ margin: "0px" }}>
          ยืนยันการ admit
        </Segment>
        <Modal.Content>{props.orDischargeResult?.admit_detail}</Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={handleConfirmAdmit}>
            ใช่
          </Button>
          <Button color="red" onClick={() => setOpenModConfirmAdmit(false)}>
            ไม่
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const ORCancelDischargeComponent: React.FunctionComponent<any> = (props) => {
  const [payload, setPayload] = useState<any>({ cancel_reason: "" });

  const closeModORCancelDischarge = () => {
    setPayload({ cancel_reason: "" });
    props.onCloseMod();
  };

  const handleChangeORDischarge = (_event: any, data: any) => {
    setPayload({ ...payload, [data.name]: data.value });
  };

  const handleORCancelDischarge = () => {
    props.onEvent({
      message: "ORCancelDischarge",
      params: {
        order: props.selectedOrOrder,
        cancel_reason: payload.cancel_reason,
      },
    });
    closeModORCancelDischarge();
  };

  return (
    <>
      <Modal
        size="mini"
        closeIcon
        open={props.openMod}
        onClose={closeModORCancelDischarge}
      >
        <Segment inverted className="blue" style={{ margin: "0px" }}>
          ยกเลิก Discharge
        </Segment>
        <Modal.Content>
          <Form>
            <Form.Field width={16}>
              <label>เหตุผลในการยกเลิก</label>
            </Form.Field>

            <Form.Field width={16}>
              <Input
                name="cancel_reason"
                value={payload.cancel_reason || ""}
                onChange={handleChangeORDischarge}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={handleORCancelDischarge}>
            ใช่
          </Button>
          <Button color="red" onClick={closeModORCancelDischarge}>
            ไม่
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default CardPatientPanel;
