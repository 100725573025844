import WasmController from "react-lib/frameworks/WasmController";
import PatientCoverageMaxReimbList from "issara-sdk/apis/PatientCoverageMaxReimbList_apps_CLM";
import PatientCoverageMaxReimbDetail from "issara-sdk/apis/PatientCoverageMaxReimbDetail_apps_CLM";
import PatientCoverageList from "issara-sdk/apis/PatientCoverageList_core"

export type State = {
    // main item
    financialAmountList?: any[];
    modCoverageApprove?: {
        open?: boolean;
        patientCoverageList?: any[];
        selectedPatientCoverage?: any;
    };
    FinancialAmountSetSequence?: {
        sequenceIndex?: string | null;
        amount?: any;
        selectedPatientCoverage?: any;
        selectedPatientCoverageDetail?: string | null;
        selectedFinancialAmount?: any;
    };

    // other item
    selectedPatient?: any;
    buttonLoadCheck?: any;
    errorMessage?: any;
    successMessage?: any;
}

export const StateInitial: State = {
    financialAmountList: [],
    modCoverageApprove: {
        open: false,
        patientCoverageList: []
    },
    FinancialAmountSetSequence: {
        sequenceIndex: null
    }
}

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
    division?: number;
    device?: number;
};

export const DataInitial = {};

type Handler = (
    controller: WasmController<State, Event, Data>,
    params?: any
) => any;


export const Start: Handler = async (controller, params) => {
    const state = controller.getState();
    if (!state.FinancialAmountSetSequence) return;
    const patientReimList = await PatientCoverageMaxReimbList.list({
        apiToken: controller.apiToken,
        params: {
            patient_id: state.selectedPatient?.id,
            unexpired: true,
            offset: 0,
            limit: 200
        },
        extra: {
            division: controller.data?.division,
            device: controller.data?.device
        }
    })

    if (patientReimList[1]) {
        return controller.setState({
            errorMessage: {
                ...state.errorMessage,
                [params.card]: { error: patientReimList[1]?.length > 2000 ? "Backend error" : patientReimList[1] }
            }
        })
    }
    return controller.setState({
        financialAmountList: patientReimList[0]?.items || [],
        FinancialAmountSetSequence: {
            sequenceIndex: "Action",
            amount: "0.00",
            selectedPatientCoverage: null,
            selectedPatientCoverageDetail: "",
            selectedFinancialAmount: null
        }
    })
}

export const Action: Handler = async (controller, params) => {
    const state = controller.getState();
    if (params?.action === "fetch") {
        const patientReimList = await PatientCoverageMaxReimbList.list({
            apiToken: controller.apiToken,
            params: {
                patient_id: state.selectedPatient?.id,
                unexpired: true,
                offset: 0,
                limit: 200
            },
            extra: {
                division: controller.data?.division,
                device: controller.data?.device
            }
        })

        if (patientReimList[1]) {
            return controller.setState({
                errorMessage: {
                    ...state.errorMessage,
                    [params.card]: { error: patientReimList[1]?.length > 2000 ? "Backend error" : patientReimList[1] }
                }
            })
        }
        return controller.setState({
            financialAmountList: patientReimList[0]?.items || [],
            FinancialAmountSetSequence: {
                ...state.FinancialAmountSetSequence,
                amount: "0.00",
                selectedPatientCoverage: null,
                selectedPatientCoverageDetail: "",
                selectedFinancialAmount: null
            }
        })
    } else if (params.action === "selectPatientReim" && params?.data?.id) {
        return controller.setState({
            FinancialAmountSetSequence: {
                ...state.FinancialAmountSetSequence,
                amount: params?.data?.max_reimb || "0.00",
                selectedPatientCoverage: params?.data?.patient_coverage || null,
                selectedPatientCoverageDetail: `id: ${params?.data?.patient_coverage} coverage: ${params?.data?.coverage_name || ""} ช่วงวันที่ ${params?.data?.start_date || ""} ${params?.data?.stop_date || ""}`,
                selectedFinancialAmount: { ...params.data }
            }
        })
    } else if (params?.action === "save") {
        // update
        if (state.FinancialAmountSetSequence?.selectedFinancialAmount?.id) {
            const patientReimUpdate = await PatientCoverageMaxReimbDetail.patch({
                pk: state.FinancialAmountSetSequence?.selectedFinancialAmount?.id,
                apiToken: controller.apiToken,
                data: {
                    max_reimb: state.FinancialAmountSetSequence?.amount,
                    patient_coverage: state.FinancialAmountSetSequence?.selectedPatientCoverage
                },
                extra: {
                    division: controller.data?.division,
                    device: controller.data?.device
                }
            })
            if (patientReimUpdate[1]) {
                return controller.setState({
                    buttonLoadCheck: {
                        ...state.buttonLoadCheck, [`${params.card}_SAVE`]: "ERROR"
                    },
                    errorMessage: {
                        ...state.errorMessage,
                        [params.card]: { error: patientReimUpdate[1]?.length > 2000 ? "Backend error" : patientReimUpdate[1] }
                    }
                })
            }
        }
        // create
        else {
            const patientReimCreate = await PatientCoverageMaxReimbList.create({
                apiToken: controller.apiToken,
                data: {
                    max_reimb: state.FinancialAmountSetSequence?.amount,
                    patient_coverage: state.FinancialAmountSetSequence?.selectedPatientCoverage
                },
                extra: {
                    division: controller.data?.division,
                    device: controller.data?.device
                }
            })
            if (patientReimCreate[1]) {
                return controller.setState({
                    buttonLoadCheck: {
                        ...state.buttonLoadCheck, [`${params.card}_SAVE`]: "ERROR"
                    },
                    errorMessage: {
                        ...state.errorMessage,
                        [params.card]: { error: patientReimCreate[1]?.length > 2000 ? "Backend error" : patientReimCreate[1] }
                    }
                })
            }

        }
        return controller.setState({
            buttonLoadCheck: {
                ...state.buttonLoadCheck, [`${params.card}_SAVE`]: "SUCCESS"
            },
        }, async () => {
            await Action(controller, { action: "fetch" })
        })
    } else if (params?.action === "delete" && params?.data.id) {
        const patientReimDelete = await PatientCoverageMaxReimbDetail.delete({
            pk: params?.data.id,
            apiToken: controller.apiToken,
            params: {
                max_reimb: params?.data.max_reimb,
                patient_coverage: params?.data.patient_coverage
            },
            extra: {
                division: controller.data?.division,
                device: controller.data?.device
            }
        })
        if (patientReimDelete[1]) {
            return controller.setState({
                errorMessage: {
                    ...state.errorMessage,
                    [params.card]: { error: patientReimDelete[1]?.length > 2000 ? "Backend error" : patientReimDelete[1] }
                }
            })
        }
        return await Action(controller, { action: "fetch" })
    } else if (params?.action === "clearData") {
        return controller.setState({
            FinancialAmountSetSequence: {
                ...state.FinancialAmountSetSequence,
                amount: "0.00",
                selectedPatientCoverage: null,
                selectedPatientCoverageDetail: "",
                selectedFinancialAmount: null
            }
        })
    } else if (params?.action === "openModal") {
        const patientCoverage = await PatientCoverageList.list({
            apiToken: controller.apiToken,
            params: {
                patient: state.selectedPatient?.id,
                order_by_priority_and_id: true,
                unexpired: true,
                no_max_reimb: true,
                offset: 0,
                limit: 200
            }
        })

        if (patientCoverage[1]) {
            return controller.setState({
                errorMessage: {
                    ...state.errorMessage,
                    [params.card]: { error: patientCoverage[1]?.length > 2000 ? "Backend error" : patientCoverage[1] }
                }
            })
        }

        return controller.setState({
            FinancialAmountSetSequence: {
                ...state.FinancialAmountSetSequence,
                sequenceIndex: "ModalAction"
            },
            modCoverageApprove: {
                open: true,
                patientCoverageList: patientCoverage[0]?.items || "",
                selectedPatientCoverage: null
            }
        })
    }
}

export const ModalAction: Handler = async (controller, params) => {
    const state = controller.getState();

    if (params?.action === "selectPatientCoverage") {
        let coverageDetail = ""
        if (state.modCoverageApprove?.selectedPatientCoverage?.id) {
            coverageDetail = `id: ${state.modCoverageApprove?.selectedPatientCoverage?.id} coverage: ${state.modCoverageApprove?.selectedPatientCoverage?.coverage_name} ช่วงวันที่ ${state.modCoverageApprove?.selectedPatientCoverage?.start_date} ${state.modCoverageApprove?.selectedPatientCoverage?.stop_date}`
        }

        controller.setState({
            FinancialAmountSetSequence: {
                ...state.FinancialAmountSetSequence,
                sequenceIndex: "Action",
                selectedPatientCoverage: state.modCoverageApprove?.selectedPatientCoverage?.id || null,
                selectedPatientCoverageDetail: coverageDetail
            },
            modCoverageApprove: {
                open: false,
                patientCoverageList: [],
                selectedPatientCoverage: null
            }
        })
    } else if (params?.action === "close") {
        controller.setState({
            FinancialAmountSetSequence: {
                ...state.FinancialAmountSetSequence,
                sequenceIndex: "Action"
            },
            modCoverageApprove: {
                open: false,
                patientCoverageList: [],
                selectedPatientCoverage: null
            }
        })
    }
}
