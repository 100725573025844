import React, { useEffect, useState, useMemo, useRef } from "react";
import { Tab } from "semantic-ui-react";
import moment from "moment";
import _ from "../../compat/lodashplus";
import * as Util from "../../utils";

import CardAdminQueueWard from "./CardAdminQueueWard";
import CardReviewQueueWard from "./CardReviewQueueWard";
import CardPreAdminQueueWard from "./CardPreAdminQueueWard";

import {
  CardMedAdminQueueWardStateType,
  CardMedAdminQueueWardInitial,
} from "./CardPreAdminQueueWard";

export type FilterMedRecordType = {
  ward: number;
  date: string;
  time: string;
  checkedTime: boolean;
  division: any;
  hn: any;
};

export type CardQueueWardStateType = {} & CardMedAdminQueueWardStateType;

export const CardQueueWardInitial: CardQueueWardStateType = {
  ...CardMedAdminQueueWardInitial,
};

const currentTime = () => Util.formatDatetime(moment()).split(" ")[1];

export type DRUG_TYPE_COLOR = "" 
| "RESUSCITATION" 
| "STAT" 
| "HOME_OPD"
| "SELLING"
| "HOME_IPD"
| "CONTINUE_PLAN"
| "CONTINUE"
| "ONE_DOSE"
| "ONE_DAY"
| "REFILL"
| "OPERATING"
| "URGENT"

export const TYPE_COLOR = {
  "": "black",
  RESUSCITATION: "orange",
  STAT: "red",
  HOME_OPD: "violet",
  SELLING: "violet",
  HOME_IPD: "purple",
  CONTINUE_PLAN: "teal",
  CONTINUE: "blue",
  ONE_DOSE: "olive",
  ONE_DAY: "olive",
  REFILL: "pink",
  OPERATING: "orange",
  URGENT: "red",
};

const initialFilterMedRecord: FilterMedRecordType = {
  ward: null as any,
  date: Util.formatDate(moment()),
  time: currentTime(),
  checkedTime: false,
  division: " ",
  hn: null,
};

const panes = [
  {
    menuItem: "Pre Admin",
    render: (props: any) => (
      <Tab.Pane>
        <CardPreAdminQueueWard {...props} />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Admin",
    render: (props: any) => (
      <Tab.Pane>
        <CardAdminQueueWard {...props} />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Review",
    render: (props: any) => (
      <Tab.Pane>
        <CardReviewQueueWard {...props} />
      </Tab.Pane>
    ),
  },
];

/* ------------------------------------------------------ */

/*                   Display Drug Detail                  */

/* ------------------------------------------------------ */
export const DisplayDrugDetail = (props: any) => {
  return (
    <>
      <p style={{ margin: "0.2em" }}>
        {!props.byLabel
          ? props.drugInfo.changing_detail?.split("\n")[1] ||
            `${+props.drugInfo.dose} ${props.drugInfo.code_unit} ${
              props.drugInfo.code_method
            } ${props.drugInfo.code_frequency}`
          : `${+props.drugInfo.dose} ${props.drugInfo.name_unit} ${
              props.drugInfo.label_th_route
            } ${props.drugInfo.label_th_site} ${
              props.drugInfo.label_th_frequency
            } ${props.drugInfo.label_th_site} ${props.drugInfo.description} ${
              ["BLANK", "NA"].includes(props.drugInfo.code_method)
                ? ""
                : props.drugInfo.label_th_method
            }`}
      </p>
    </>
  );
};

const CardQueueWard: React.FC<CardQueueWardStateType> = (props) => {
  const [filterMedRecord, setFilterMedRecord] = useState(
    initialFilterMedRecord
  );
  const barcodeHNRef = useRef();
  const [medicationRecord, setMedicationRecord] = useState([]);
  const activeIndex = useRef<any>(0);
  useEffect(() => {
    if (props.django && props.wardTypeList) {
      const compare = _.find(props.wardTypeList, {
        id: props.django?.division?.id,
      });

      if (compare && !filterMedRecord.ward) {
        setFilterMedRecord({
          ...filterMedRecord,
          ward: props.django.division.id,
        });
      }
    }
  }, [props.django, props.wardTypeList]);

  useEffect(() => {
    if (props.medicationRecord && props.tabCurrentIndex === activeIndex.current)
      setMedicationRecord(props.medicationRecord);
  }, [props.medicationRecord]);

  const onSetFilter = (key: keyof FilterMedRecordType, value: any) => {
    setFilterMedRecord({
      ...filterMedRecord,
      [key]: value,
    });
  };

  return (
    <Tab
      {...props}
      panes={panes as any}
      medicationRecord={medicationRecord}
      filterMedRecord={filterMedRecord}
      barcodeHNRef={barcodeHNRef}
      onSetFilter={onSetFilter}
      onTabChange={(ev, data) => {
        if (activeIndex.current !== data.activeIndex) {
          activeIndex.current = data.activeIndex;
          setMedicationRecord([]);
        }
      }}
    />
  );
};

CardQueueWard.defaultProps = CardQueueWardInitial;

export default React.memo(CardQueueWard);
