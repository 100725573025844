import React, { useMemo, useState, useEffect } from "react";
import { Modal } from "semantic-ui-react";
import moment from "moment";
// UI
import CardORDetailUX from "./CardORDetailUX";
import ModSearchDoctorNote from "react-lib/apps/APP/ModSearchDoctorNote";

import { ADM_FIBRINOLYTIC_USED, mapOptions } from "./sequence/ORRequest";

// Common
import { DateTextBox } from "../../common";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";

// Utils
import {
  beStringToAdString,
  formatDate,
  adToBe,
} from "../../../utils/dateUtils";

const BLOOD_REQUEST_OPTIONS = [
  { key: "is_na", value: "is_na", text: "N/A" },
  { key: "is_yes", value: "is_yes", text: "YES" },
  { key: "is_yes_ts", value: "is_yes_ts", text: "YES(T/S)" },
];

const CardORDetail = (props: any) => {
  const [openModDoctorNote, setOpenModDoctorNote] = useState<boolean>(false);

  useEffect(() => {
    const date = beStringToAdString(props.ORRequestSequence?.start_date);
    const addDate =
      beStringToAdString(formatDate(moment().clone().add(1, "days"))) || "";
    const beDate = adToBe(addDate);

    if (props.isNewEncounter) {
      if (date && date < addDate) {
        props.onChangeDate("start_date")(beDate);
      }
    } else {
      if (date && date > addDate) {
        props.onChangeDate("start_date")(beDate);
      }
    }
  }, [props.isNewEncounter]);

  const cmOptions = useMemo(() => {
    return mapOptions(
      props.ORRequestSequence?.clinicalTerm?.CM?.items || [],
      "id",
      "name"
    );
  }, [props.ORRequestSequence?.clinicalTerm]);

  const asaClassOptions = useMemo(() => {
    return mapOptions(
      props.ORRequestSequence?.clinicalTerm?.asa_class?.items || [],
      "id",
      "name"
    );
  }, [props.ORRequestSequence?.clinicalTerm]);

  const handleAddNote = () => {
    setOpenModDoctorNote(true);
  };

  const handleOnSelectNote = (noteList: string[]) => {
    const orderNote = props.ORRequestSequence?.remark;

    const note: (string | number)[] = orderNote
      ? [orderNote, ...noteList]
      : noteList;

    props.onChangeValue(null, { name: "remark", value: note.join("\n") });
    setOpenModDoctorNote(false);
  };

  // console.log(cmOptions, asaClassOptions);

  return (
    <div>
      <CardORDetailUX
        ADM_FIBRINOLYTIC_USED={ADM_FIBRINOLYTIC_USED}
        // data
        isAppointment={props.isNewEncounter}
        location={props.ORRequestSequence?.location}
        anesthesiaMethod={props.ORRequestSequence?.anesthesia_method}
        operatingRoom={props.ORRequestSequence?.operating_room}
        procedureType={props.ORRequestSequence?.procedure_type}
        frozenSection={props.ORRequestSequence?.frozen_section}
        mammogram={props.ORRequestSequence?.mammogram}
        other={props.ORRequestSequence?.blood_request?.other}
        isOther={props.ORRequestSequence?.blood_request?.is_other}
        plt={props.ORRequestSequence?.blood_request?.plt}
        isPlt={props.ORRequestSequence?.blood_request?.is_plt}
        ffp={props.ORRequestSequence?.blood_request?.ffp}
        isFfp={props.ORRequestSequence?.blood_request?.is_ffp}
        prc={props.ORRequestSequence?.blood_request?.prc}
        isPrc={props.ORRequestSequence?.blood_request?.is_prc}
        estimateOperationHour={props.ORRequestSequence?.estimate_operation_hour}
        estimateOperationMinute={
          props.ORRequestSequence?.estimate_operation_minute
        }
        bloodRequest={
          BLOOD_REQUEST_OPTIONS.find(
            (item: any) => props.ORRequestSequence?.blood_request?.[item.value]
          )?.value
        }
        blood_component={
          props.ORRequestSequence?.blood_request?.blood_component || ""
        }
        cross_match_detail={
          props.ORRequestSequence?.blood_request?.cross_match_detail || ""
        }
        cross_match_date={
          props.ORRequestSequence?.blood_request?.cross_match_date
            ? adToBe(
                moment(
                  props.ORRequestSequence?.blood_request?.cross_match_date
                ).format("YYYY-MM-DD"),
                "YYYY-MM-DD"
              )
            : "-"
        }
        isLess_500={
          props.ORRequestSequence?.anticipated_blood_loss?.is_less_500
        }
        isMore_500={
          props.ORRequestSequence?.anticipated_blood_loss?.is_more_500
        }
        define={props.ORRequestSequence?.anticipated_blood_loss?.define}
        antiPlateletValue={
          props.ORRequestSequence?.anti_platelet_coagulant?.value
        }
        remark={props.ORRequestSequence?.anti_platelet_coagulant?.remark}
        contrastMedia={props.ORRequestSequence?.contrast_media}
        asaClass={props.ORRequestSequence?.asa_class}
        specialEquipments={props.ORRequestSequence?.special_equipments}
        remarkDetail={props.ORRequestSequence?.remark}
        // options
        anesthesiaMethodOptions={props.masterOptions.anesthesiaMethod}
        orLocationOptions={props.ORRequestSequence?.orLocationOptions}
        procedureTypeOptions={props.masterOptions.orProcedureType}
        operatingRoomOptions={props.ORRequestSequence?.operatingRoomOptions}
        bloodRequestOptions={BLOOD_REQUEST_OPTIONS}
        cmOptions={cmOptions}
        asaClassOptions={asaClassOptions}
        specialEquipmentOptions={props.masterOptions.specialEquipment}
        // config
        config={{
          disabledRoom: true, //!props.ORRequestSequence?.location,
          showBRCheck:
            props.ORRequestSequence?.blood_request?.is_yes ||
            props.ORRequestSequence?.blood_request?.is_yes_ts,
          enabledPrc: props.ORRequestSequence?.blood_request?.is_prc,
          enabledFfp: props.ORRequestSequence?.blood_request?.is_ffp,
          enabledPlt: props.ORRequestSequence?.blood_request?.is_plt,
          enabledOther: props.ORRequestSequence?.blood_request?.is_other,
          disabledAntiPlatelet:
            props.ORRequestSequence?.anti_platelet_coagulant?.value !==
            ADM_FIBRINOLYTIC_USED.ANTI_PALETELET,
          disableLocation: true,
          // hideFFP: isNa,
          // hidePlt: isNa,
          // hideOther: isNa,
        }}
        // callback
        onChangeValue={props.onChangeValue}
        onAddNote={handleAddNote}
        onOrderBloodRequest={props.onOrderBloodRequest}
        // Element
        DateTextBox={
          <DateTextBox
            inputRef={(instance: any) => {
              instance && (instance.ref.inputRef.current.style.opacity = "1");
            }}
            value={props.ORRequestSequence?.start_date || ""}
            onChange={props.onChangeDate("start_date")}
            minDate={
              props.isNewEncounter
                ? formatDate(moment().clone().add(1, "days"))
                : formatDate(moment())
            }
            maxDate={
              props.isNewEncounter
                ? ""
                : formatDate(moment().clone().add(1, "days"))
            }
            disabled={true}
            style={{ width: "13rem" }}
            inputStyle={{ opacity: 1, width: "13rem" }}
          />
        }
        DateTextBoxAntiPlatelet={
          <DateTextBox
            value={props.ORRequestSequence?.anti_platelet_coagulant?.date || ""}
            onChange={props.onChangeDate("anti_platelet_coagulant.date")}
            disabled={
              props.ORRequestSequence?.anti_platelet_coagulant?.value !==
              ADM_FIBRINOLYTIC_USED.ANTI_PALETELET
            }
          />
        }
        StartTime={
          <TimeComboBox
            ref={(instance: any) => {
              instance && (instance.inputRef.current.style.opacity = "1");
            }}
            defaultValue={props.ORRequestSequence?.start_time || ""}
            // onTextChange={props.onChangeDate("start_time")}
            disabled={true}
            inputStyle={{ opacity: 1 }}
          />
        }
        EndTime={
          <TimeComboBox
            ref={(instance: any) => {
              instance && (instance.inputRef.current.style.opacity = "1");
            }}
            defaultValue={
              props.ORRequestSequence?.end_date_time
                ? moment(props.ORRequestSequence?.end_date_time).format("HH:mm")
                : ""
            }
            // onTextChange={props.onChangeDate("start_time")}
            disabled={true}
            inputStyle={{ opacity: 1 }}
          />
        }
      />

      <Modal
        open={openModDoctorNote}
        onClose={() => setOpenModDoctorNote(false)}
      >
        <ModSearchDoctorNote
          controller={props.proxyController}
          division={props.divisionId}
          onClose={() => setOpenModDoctorNote(false)}
          onSelectNote={handleOnSelectNote}
        />
      </Modal>
    </div>
  );
};

export default React.memo(CardORDetail);
