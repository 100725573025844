import React from "react";
import PropTypes from "prop-types";
import * as Common from "react-lib/apps/common/";
import {
  Grid,
  Header,
  Form,
  Input,
  Button,
  TextArea,
  Modal,
  Radio,
  Container,
  Label,
  Popup,
  Icon
} from "semantic-ui-react";
import * as DPO from "react-lib/apps/DPO";
import { ModInfo as ModInfoError, ModInfo as ModInfoSuccess } from "react-lib/apps/common";

const PhysicalOrganExam = React.memo(
  React.forwardRef((props: any, ref) => {
    const [value, setValue] = React.useState("X");
    const [text, setText] = React.useState("");

    React.useImperativeHandle(ref, () => ({
      getData: () => {
        return {
          organ: props.data.organ_id,
          status: value,
          description: text,
          emr: props.emrId
        };
      },
      getValue: () => value,
      getText: () => text,
      getOrganId: () => props.data.organ_id
    }));

    React.useEffect(() => {
      if (props.data.status) {
        setValue(props.data.status);
      }
    }, [props.data.status]);

    React.useEffect(() => {
      if (props.data.description) {
        setText(props.data.description);
      }
    }, [props.data.description]);

    return (
      <Container>
        <Grid>
          <Grid.Column width={2}>
            <Form.Field>
              <label>{props.data.organ_name}</label>
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={14}>
            <Form.Group inline>
              <Form.Field>
                <Radio
                  label="N/A"
                  value="X"
                  checked={value === "X"}
                  onChange={(e, { value }) => setValue(value)}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Normal"
                  value="N"
                  checked={value === "N"}
                  onChange={(e, { value }) => setValue(value)}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Abnormal"
                  value="A"
                  checked={value === "A"}
                  onChange={(e, { value }) => setValue(value)}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={16}>
                <TextArea
                  style={{ display: value === "X" ? "none" : null }}
                  value={text}
                  onChange={e => setText(e.target.value)}
                />
              </Form.Field>
            </Form.Group>
          </Grid.Column>
        </Grid>
      </Container>
    );
  })
);

const CardPatientInfo = React.forwardRef((props, ref) => {
  const [patientIllness, setPatientIllness] = React.useState({});
  const [allergy, setAllergy] = React.useState([]);
  const [notApproveAllergy, setNotApproveAllergy] = React.useState([]);
  const [newInfo, setNewInfo] = React.useState(true);
  const [enableSave, setEnableSave] = React.useState(true);
  const [isADRFailed, setIsADRFailed] = React.useState(false);
  const [patientExamOther, setPatientExamOther] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [btnHistoryClassName, setBtnHistoryClassName] = React.useState(
    "grey fluid"
  );
  const [openModPatientHistory, setOpenModPatientHistory] = React.useState(
    false
  );
  const [physicalOrganList, setPhysicalOrganList] = React.useState([]);
  const [openModInfoError, setOpenModInfoError] = React.useState(false);
  const [openModInfoSuccess, setOpenModInfoSuccess] = React.useState(false);
  const [errorText, setErrorText] = React.useState(null);

  const [openModADR, setOpenModADR] = React.useState(false);
  const [modADRApproveOnly, setModADRApproveOnly] = React.useState(true);
  const [showSaveAlert, setShowSaveAlert] = React.useState(true);
  const [confirmSave, setConfirmSave] = React.useState(false);

  const [validateError, setValidateError] = React.useState(null);

  const isMounted = React.useRef(false);
  const subADRAndOtherRef = React.useRef();
  const physicalOrganRef = React.useRef([]);
  const textareaRef = React.useRef<any>();
  React.useImperativeHandle(ref, () => ({
    save: () => {
      setShowSaveAlert(false);
      setTimeout(() => save(), 0);
    },
    saveForCheckOut: () => {
      setShowSaveAlert(false);
      setTimeout(() => save(), 0);
      if (!validateFrom()) {
        props.onFailed();
      }
    }
  }));

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    fetchPatientInfo();
  }, [props.emrId]);

  React.useEffect(() => {
    if(props.patientSymptomInfo && !patientIllness.present_illness){
    handleSetPatientIllness({
      field: "present_illness",
      value: props.patientSymptomInfo
      // value: e.target.value.replace(/\nANCRecord:.*$/g, "")
    })
  }
  }, [props.patientSymptomInfo])

  const fetchPatientInfo = () => {
    if (!props.emrId) {
      return;
    }
    getMedicalRecordEmrDetail();
    getAdverseReactionApproved();
    getAdverseReactionNotApproved();
  };

  const clear = () => {
    setPatientIllness({});
    setPatientExamOther({});
    setNewInfo(true);
    setPhysicalOrganList([]);
    setIsADRFailed(false);
    setValidateError(null);
  };

  const getAdverseReactionApproved = async () => {
    setIsLoading(true);
    const [data, error] = await props.controller.getAdverseReactionList({
      patientId: props.patientData?.patient_id,
      excludeUnused: true,
      severeFirst: true,
      approveOnly: true,
      showNotKnownByDoctor: true
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        if (data.items.length > 5) {
          setAllergy(data.items);
        } else {
          setAllergy(data.items);
        }
      }
    }
  };

  const getAdverseReactionNotApproved = async () => {
    setIsLoading(true);
    const [data, error] = await props.controller.getAdverseReactionList({
      patientId: props.patientData?.patient_id,
      excludeUnused: true,
      severeFirst: true,
      notApproveOnly: true
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        if (data.items.length > 5) {
          setNotApproveAllergy(data.items);
        } else {
          setNotApproveAllergy(data.items);
        }
      }
    }
  };

  const getMedicalRecordEmrDetail = async () => {
    setIsLoading(true);
    const [data, error] = await props.controller.getMedicalRecordEmrDetail({
      emrId: props.emrId
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        setDataToUI(data);
      }
    }
  };

  const setDataToUI = async data => {
    //* User with getMedicalRecordEmrDetail only
    if (data.patient_illness) {
      setPatientIllness(data.patient_illness);
      setNewInfo(!data.patient_illness.id);
    } else {
      setPatientIllness({
        chief_complaint: data.encounter_chief_complaint
      });
      setNewInfo(true);
    }

    if (data.patient_exam.length > 0) {
      setEnableSave(true);
      setPhysicalOrganList(data.patient_exam);
    } else {
      getPhysicalExamOrgan();
    }

    if (!isADRFailed) {
      if (subADRAndOtherRef.current) {
        subADRAndOtherRef.current.refresh();
      }
    }

    if (data.patient_exam_other) {
      setPatientExamOther(data.patient_exam_other);
    } else {
      setPatientExamOther("");
    }

    if (
      data.patient_history.personal_history.length > 0 ||
      data.patient_history.past_illness.length > 0 ||
      data.patient_history.family_history.length > 0 ||
      data.sensitive_note.sensitive_note.length > 0
    ) {
      setBtnHistoryClassName("blue fluid");
    } else {
      setBtnHistoryClassName("grey fluid");
    }
  };

  const getPhysicalExamOrgan = async () => {
    setIsLoading(true);
    const [data, error] = await props.controller.getPhysicalExamOrgan();
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        if (data.items.length > 0) {
          setPhysicalOrganList(data.items);
        } else {
          setErrorText(
            "กรุณาตั้งค่า PhysicalExamOrgan กับหน่วยงาน " +
            props.django.division.name
          );
          setOpenModInfoError(true);
          setEnableSave(false);
          props.onNoPhysicalExamOrgan(); //* Notify when no organ examination created
        }
      }
    }
  };

  const generateOrganUI = React.useCallback(() => {
    return physicalOrganList.map((item, index) => (
      <PhysicalOrganExam
        key={item.organ_id}
        data={item}
        ref={el => (physicalOrganRef.current[index] = el)}
        emrId={props.emrId}
      />
    ));
  });

  const handleSetPatientIllness = ({ field, value } = {}) => {
    setPatientIllness(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const generateNotApproveAllergyLabel = () => {
    let arr = notApproveAllergy.slice(0, 5).map((item, index) => {
      return (
        <Form.Field width={3} key={index}>
          <Popup
            trigger={
              <Label
                icon="help"
                className="grey fluid"
                style={styles.label}
                content={item.name ? item.name : item.note}
              />
            }
          >{`[${item.name}] [${item.severity_name}] ${item.note}`}</Popup>
        </Form.Field>
      );
    });
    return (
      <Form>
        <Form.Group inline>
          {arr}{" "}
          <Button
            style={{ display: notApproveAllergy.length < 6 ? "none" : null }}
            color="blue"
            content="more"
            onClick={() => {
              setModADRApproveOnly(false);
              setOpenModADR(true);
            }}
          />
        </Form.Group>
      </Form>
    );
  };

  const generateAllergyLabel = () => {
    let arr = allergy.slice(0, 5).map((item, index) => {
      return (
        <Form.Field width={3} key={index}>
          <Popup
            trigger={
              <Label
                className="fluid"
                style={styles.label}
                color={ALLERGY_LEVEL.COLOR[item.level]}
              >
                {item.name ? item.name : item.note}
              </Label>
            }
          >{`[${item.name}] [${item.severity_name}] ${item.note}`}</Popup>
        </Form.Field>
      );
    });
    return (
      <Form>
        <Form.Group inline>
          {arr}{" "}
          <Button
            style={{ display: allergy.length < 6 ? "none" : null }}
            color="blue"
            content="more"
            onClick={() => {
              setModADRApproveOnly(true);
              setOpenModADR(true);
            }}
          />
        </Form.Group>
      </Form>
    );
  };

  const save = async () => {
    setValidateError(null);
    if (enableSave === false) {
      setErrorText(
        "กรุณาตั้งค่า PhysicalExamOrgan กับหน่วยงาน " +
        props.django.division.name
      );
      setOpenModInfoError(true);
      props.onFailed();
      return;
    }
    setIsLoading(true);
    subADRAndOtherRef.current?.save?.();
  };

  const setSaveData = () => {
    let saveData = {};
    saveData.patient_illness = {
      ...patientIllness,
      emr: props.emrId
    };
    saveData.patient_exam_other = {
      ...patientExamOther,
      emr: props.emrId
    };

    let arr = physicalOrganRef.current.map(item => {
      return item.getData();
    });
    saveData.patient_exam = arr;
    return saveData;
  };

  const postPatientScreenNew = async () => {
    const saveData = setSaveData();
    setIsLoading(true);
    const [data, error] = await props.controller.postPatientScreenNew({
      data: saveData
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        setNewInfo(false);
        if (showSaveAlert) {
          setOpenModInfoSuccess(true);
        }
        fetchPatientInfo();
        props.onSuccess();
      } else if (error) {
        setErrorText("เกิดข้อผิดพลาด บันทึกไม่สำเร็จ");
        setOpenModInfoError(true);
        props.onFailed();
      }
    }
  };

  const putPatientScreenUpdate = async () => {
    const saveData = setSaveData();
    setIsLoading(true);
    const [data, error] = await props.controller.putPatientScreenUpdate({
      data: saveData
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        setNewInfo(false);
        // if (showSaveAlert) {
        //   setOpenModInfoSuccess(true);
        // }
        fetchPatientInfo();
        setIsLoading(false);
        props.onSuccess();
      } else if (error) {
        setErrorText(
          "ข้อมูลการรักษาถูกสร้างไว้แล้วโดยแพทย์ท่านอื่น กำลังทำการดึงข้อมูลการรักษาที่บันทึกแล้วออกมา"
        );
        setOpenModInfoError(true);
        fetchPatientInfo();
        props.onFailed();
      }
    }
  };

  const handleOnSaveAllergy = success => { 
    if (success) {
      setIsADRFailed(false);
      if (newInfo) {
        postPatientScreenNew();
      } else {
        putPatientScreenUpdate();
      }
      subADRAndOtherRef.current?.clearError?.()
    } else {
      setIsADRFailed(true);
      setShowSaveAlert(false);
      if (newInfo) {
        postPatientScreenNew();
      } else {
        putPatientScreenUpdate();
      }
      // failed to save allergy notify parent card that we stopped here
      setIsLoading(false);
      props.onFailed();
    } 
  };

  // const setValueTextArea = React.useCallback(() => {
  //   const replace = (patientIllness.present_illness
  //     ? patientIllness.present_illness
  //     : "").replace(/\nANCRecord:.*$/g, "")
  //   const anc = patientIllness.anc_symptom ? `\nANCRecord: ${patientIllness.anc_symptom}` : ""
  //   const value = `${replace}${anc}`

  //   if (textareaRef.current) {
  //     console.log(textareaRef.current.selectionEnd, replace.length)
  //     if (textareaRef.current.selectionEnd > replace.length) {
  //       textareaRef.current.selectionEnd = replace.length
  //     }
  //   }

  //   console.log("Patient Illness:", patientIllness, value)
  //   return value
  // }, [patientIllness])

  const validateFrom = () => {
    let error = [];
    if (!patientIllness.chief_complaint) {
      error.push('กรุณาระบุค่าใน "Chief complaint"');
    }
    if (!patientIllness.present_illness) {
      error.push('กรุณาระบุค่าใน "Present illness"');
    }
    setValidateError(error);
  };

  return (
    <Common.CardLayout
      titleText="ประวัติผู้ป่วยและการตรวจร่างกาย"
      closeable={false}
      toggleable={false}
      loading={isLoading}
    >
      <Modal
        open={openModADR}
        closeOnDimmerClick={true}
        onClose={() => setOpenModADR(false)}
      >
        <Common.SubADR
          patientData={props.patientData}
          controller={props.controller}
          approvedOnly={modADRApproveOnly}
        />
      </Modal>
      <ModInfoError
        type="error"
        open={openModInfoError}
        titleName={errorText}
        onClose={() => setOpenModInfoError(false)}
        onApprove={() => setOpenModInfoError(false)}
      />
      <ModInfoSuccess
        type="success"
        duration={2000}
        open={openModInfoSuccess}
        onClose={() => setOpenModInfoSuccess(false)}
        onApprove={() => setOpenModInfoSuccess(false)}
      />
      <Modal
        open={openModPatientHistory}
        closeOnDimmerClick={true}
        onClose={() => setOpenModPatientHistory(false)}
      >
        <DPO.SubPatientHistory
          controller={props.controller}
          patientData={props.patientData}
          emrId={props.emrId}
          divisionId={props.divisionId}
          onClose={() => setOpenModPatientHistory(false)}
        />
      </Modal>
      <Grid divided columns={2}>
        <Grid.Column>
          {/* <Header>อาการไม่พึงประสงค์จากยาและอาหารเพิ่มเติม</Header>
          {generateAllergyLabel()}
          {generateNotApproveAllergyLabel()} */}
          <DPO.SubADRAndOther
            ref={subADRAndOtherRef}
            controller={props.controller}
            patientData={props.patientData || {}}
            onSaveAllergy={handleOnSaveAllergy}
            hideForm={true}
          /> 
          <Header>ข้อมูลประวัติผู้ป่วย</Header>
          <Form>
            <Common.ErrorMessage error={validateError} />
            <Form.Field
              width={15}
              error={validateError && !patientIllness.chief_complaint}
            >
              <label>Chief complaint</label>
              <Input
                value={
                  patientIllness.chief_complaint
                    ? patientIllness.chief_complaint
                    : ""
                }
                onChange={e =>
                  handleSetPatientIllness({
                    field: "chief_complaint",
                    value: e.target.value
                  })
                }
              />
            </Form.Field>
            <Form.Field
              width={15}
              error={validateError && !patientIllness.present_illness}
            >
              <label>Present illness</label>
              <TextArea
                ref={(instance) => {
                  if (instance) {
                    textareaRef.current = instance.ref.current
                  }
                }}
                rows={8}
                value={patientIllness.present_illness}
                onChange={e =>
                  handleSetPatientIllness({
                    field: "present_illness",
                    value: e.target.value
                  })
                }
                onClick={(e) => {
                  handleSetPatientIllness({
                    field: "present_illness",
                    value: e.target.value
                    // value: e.target.value.replace(/\nANCRecord:.*$/g, "")
                  })
                }}
              />
            </Form.Field>
            <br />
            <Form.Field width={4}>
              <Button
                className={btnHistoryClassName}
                content="History"
                onClick={() => setOpenModPatientHistory(true)}
              />
            </Form.Field>
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Form>
            <Form.Group inline>
              <Form.Field>
                <Header>ข้อมูลการตรวจร่างกาย</Header>
              </Form.Field>
              {/* <Form.Field width={3}>
                <Button
                  color="blue"
                  fluid
                  content="Upload"
                  onClick={props.onUploadImageClicked}
                />
              </Form.Field> */}
            </Form.Group>
            {generateOrganUI()}
            <Form.Group inline>
              <Form.Field width={2}>
                <label>Other</label>
              </Form.Field>
              <Form.Field width={14}>
                <TextArea
                  rows={3}
                  value={patientExamOther.other_detail}
                  onChange={e => {
                    const { value } = e.target;
                    setPatientExamOther(prevState => ({
                      ...prevState,
                      other_detail: value
                    }));
                  }}
                />
              </Form.Field>
            </Form.Group>
            <br />
            <Form.Field width={3}>
              <Button
                color="green"
                fluid
                content={confirmSave ? <Icon name="check"></Icon> : "Save"}
                onClick={() => {
                  if (subADRAndOtherRef.current?.validate?.()) {
                    // setShowSaveAlert(true);
                    setConfirmSave(true);
                    setTimeout(() => {
                      setConfirmSave(false);
                    }, 3000)

                  }else{
                    // setShowSaveAlert(false)
                  }
                  setTimeout(() => save(), 0);
                }}
              />
            </Form.Field>
          </Form>
        </Grid.Column>
      </Grid>
    </Common.CardLayout>
  );
});

const styles = {
  label: { textOverflow: "ellipsis", overflow: "hidden" }
};

const ALLERGY_LEVEL = {
  COLOR: {
    0: "teal",
    1: "olive",
    2: "yellow",
    3: "orange",
    4: "red"
  }
};

CardPatientInfo.defaultProps = {
  patientData: {},
  controller: {},
  emrId: null,
  divisionId: null,
  django: {},
  onNoPhysicalExamOrgan: () => { },
  onUploadImageClicked: () => { },
  onFailed: () => { },
  onSuccess: () => { }
};

CardPatientInfo.propTypes = {
  controller: PropTypes.object,
  patientData: PropTypes.object,
  emrId: PropTypes.number,
  divisionId: PropTypes.number,
  django: PropTypes.object,
  onNoPhysicalExamOrgan: PropTypes.func,
  onUploadImageClicked: PropTypes.func,
  onFailed: PropTypes.func,
  onSuccess: PropTypes.func
};

export default React.memo(CardPatientInfo);
