import * as ORQueueI from "./ORQueue";
import * as ORCountsI from "./ORCounts";
import * as PreOperationI from "./PreOperation";
import * as ORPostOperationI from "./ORPostOperation";
import * as ORRequestI from "./ORRequest"
import * as PerioperativeNursingI from "./PerioperativeNursing";
import * as ManageORI from "./ManageOR"
import * as OperatingDateTime from "./OperatingDateTime";
import * as ORServiceRefundI from "./ORServiceRefund";
import * as ORHistoryI from "./ORHistory";
import * as ORCancelListI from "./ORCancelList";
import * as ORCaseListI from "./ORCaseList";

const SequencePattern: {
  [name: string]: { [flavor: string]: { [func: string]: any } };
} = {
  ORQueue: {
    default: {
      START: ORQueueI.GetMaster,
      Action: ORQueueI.Action,
    },
  },
  ORCounts: {
    default: {
      START: ORCountsI.GetMaster,
      Action: ORCountsI.Action,
    },
  },
  PreOperation: {
    default: {
      START: PreOperationI.GetMaster,
      Action: PreOperationI.Action,
    },
  },
  ORPostOperation: {
    default: {
      START: ORPostOperationI.Start,
    },
  },
  ORRequest: {
    default: {
      START: ORRequestI.GetMaster,
      SaveAndEdit: ORRequestI.SaveAndEdit,
    },
  },
  PerioperativeNursing: {
    default: {
      START: PerioperativeNursingI.GetMaster,
      Action: PerioperativeNursingI.Action,
    },
  },
  ManageOR: {
    default: {
      START: ManageORI.GetMaster,
      Action: ManageORI.Action,
    },
  },
  OperatingDateTime: {
    default: {
      START: OperatingDateTime.Start,
      Action: OperatingDateTime.Action,
    },
  },
  ORServiceRefund: {
    default: {
      START: ORServiceRefundI.Start,
    },
  },
  ORHistory: {
    default: {
      START: ORHistoryI.Start,
    },
  },
  ORCancelList: {
    default: {
      START: ORCancelListI.Start,
      Action: ORCancelListI.Action,
    },
  },
  ORCaseList: {
    default: {
      START: ORCaseListI.Start,
      Action: ORCaseListI.Action,
    },
  },
};

export default SequencePattern;
