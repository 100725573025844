import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown,
  Input,
  Checkbox
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const ANES_PreANES_02_history = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", height: "100%" ,marginTop:"10px"}}>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",height:"90%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            style={{ display: "flex", justifyContent: "flex-end"}}>
            
            <div
              style={{ display: props.PreAnestheticSequence?.status_label === "Confirm" ?  "none" : "",}}>
              {props.buttonSave}
            </div>
          </div>
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(50, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              style={{ gridRow: "2/3", gridColumn: "1/7"}}>
              <div>
                
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  Previous anesthesia
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "7/27" }}>
              <div
                style={{ display: "flex",paddingTop:"5px" }}>
                
                <Dropdown
                  name="PreviousAnesthesia"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_previous || []}
                  selection={true}
                  style={{minWidth: "100px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                  value={props.PreAnestheticSequence?.data?.PreviousAnesthesia || ""}>
                </Dropdown>
                <Input
                  disabled={props.PreAnestheticSequence?.data?.PreviousAnesthesia !== "Yes" }
                  name="PreviousAnesthesiaRemark"
                  onChange={props.onChangeData}
                  style={{marginLeft:"5px",height:"32px",width:"1000px"}}
                  value={props.PreAnestheticSequence?.data?.PreviousAnesthesiaRemark || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "1/7"}}>
              <div>
                
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  Complication
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "7/27"}}>
              <div
                style={{ display: "flex",paddingTop:"5px" }}>
                
                <Dropdown
                  name="Complication"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_complication || []}
                  selection={true}
                  style={{minWidth: "100px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                  value={props.PreAnestheticSequence?.data?.Complication || ""}>
                </Dropdown>
                <Input
                  disabled={props.PreAnestheticSequence?.data?.Complication !== "Yes" }
                  name="ComplicationRemark"
                  onChange={props.onChangeData}
                  style={{marginLeft:"5px",height:"32px",width:"1000px"}}
                  value={props.PreAnestheticSequence?.data?.ComplicationRemark || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "4/4", gridColumn: "1/7"}}>
              <div>
                
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  Known disease
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "4/4", gridColumn: "7/27"}}>
              <div
                style={{ display: "flex",paddingTop:"5px" }}>
                
                <Dropdown
                  name="KnownDisease"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_known_disease || []}
                  selection={true}
                  style={{minWidth: "100px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                  value={props.PreAnestheticSequence?.data?.KnownDisease || ""}>
                </Dropdown>
                <Input
                  disabled={props.PreAnestheticSequence?.data?.KnownDisease !== "Yes" }
                  name="KnownDiseaseRemark"
                  onChange={props.onChangeData}
                  style={{marginLeft:"5px",height:"32px",width:"1000px"}}
                  value={props.PreAnestheticSequence?.data?.KnownDiseaseRemark || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "5/5", gridColumn: "1/7"}}>
              <div>
                
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  Family history
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "5/5", gridColumn: "7/27"}}>
              <div
                style={{ gridRow: "4/4", gridColumn: "7/27" }}>
                <div
                  style={{ display: "flex",paddingTop:"5px" }}>
                  
                  <Dropdown
                    name="FamilyHistory"
                    onChange={props.onChangeData}
                    options={props.masterOptions?.preanes_family_history || []}
                    selection={true}
                    style={{minWidth: "100px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                    value={props.PreAnestheticSequence?.data?.FamilyHistory || ""}>
                  </Dropdown>
                  <Input
                    disabled={props.PreAnestheticSequence?.data?.FamilyHistory !== "Yes" }
                    name="FamilyHistoryRemark"
                    onChange={props.onChangeData}
                    style={{marginLeft:"5px",height:"32px",width:"1000px"}}
                    value={props.PreAnestheticSequence?.data?.FamilyHistoryRemark || ""}>
                  </Input>
                </div>
              </div>
            </div>
            <div
              style={{ gridRow: "6/6", gridColumn: "1/7"}}>
              <div>
                
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  Current medications
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "6/6", gridColumn: "7/27"}}>
              <div
                style={{ display: "flex",paddingTop:"5px" }}>
                
                <Dropdown
                  name="CurrentMedications"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_current_med || []}
                  selection={true}
                  style={{minWidth: "100px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                  value={props.PreAnestheticSequence?.data?.CurrentMedications || ""}>
                </Dropdown>
                <Input
                  disabled={props.PreAnestheticSequence?.data?.CurrentMedications !== "Yes" }
                  name="CurrentMedicationsRemark"
                  onChange={props.onChangeData}
                  style={{marginLeft:"5px",height:"32px",width:"1000px"}}
                  value={props.PreAnestheticSequence?.data?.CurrentMedicationsRemark || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "1/7"}}>
              <div>
                
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  Habit
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "7/12"}}>
              <div
                style={{ display: "flex",  alignItems:"center" }}>
                
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.HabitAlcohol || false}
                  name="HabitAlcohol"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Alcohol
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "7/12"}}>
              <div
                style={{ display: "flex",  alignItems:"center" }}>
                
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.HabitSmoking || false}
                  name="HabitSmoking"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Smoking
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "9/10", gridColumn: "7/12" }}>
              <div
                style={{ display: "flex",  alignItems:"center" }}>
                
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.HabitDrugAbuse || false}
                  name="HabitDrugAbuse"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Drug abuse
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "7/12"}}>
              <div
                style={{ display: "flex",  alignItems:"center" }}>
                
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.HabitHerbSupplement || false}
                  name="HabitHerbSupplement"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Herb/Supplement
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "12/23"}}>
              <div
                style={{ display: props.PreAnestheticSequence?.data?.HabitAlcohol  ? "flex" : "none", alignItems:"center" }}>
                
                <Input
                  disabled={!props.PreAnestheticSequence?.data?.HabitAlcohol}
                  name="HabitAlcoholRemark"
                  onChange={props.onChangeData}
                  style={{marginLeft:"5px",height:"32px",width:"1000px"}}
                  value={props.PreAnestheticSequence?.data?.HabitAlcoholRemark || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "12/23"}}>
              <div
                style={{ display: props.PreAnestheticSequence?.data?.HabitSmoking  ? "flex" : "none", alignItems:"center" }}>
                
                <Input
                  disabled={!props.PreAnestheticSequence?.data?.HabitSmoking}
                  name="HabitSmokingRemark"
                  onChange={props.onChangeData}
                  style={{marginLeft:"5px",height:"32px",width:"1000px"}}
                  value={props.PreAnestheticSequence?.data?.HabitSmokingRemark || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "9/10", gridColumn: "12/23"}}>
              <div
                style={{ display: props.PreAnestheticSequence?.data?.HabitDrugAbuse  ? "flex" : "none" ,alignItems:"center" }}>
                
                <Input
                  disabled={!props.PreAnestheticSequence?.data?.HabitDrugAbuse}
                  name="HabitDrugAbuseRemark"
                  onChange={props.onChangeData}
                  style={{marginLeft:"5px",height:"32px",width:"1000px"}}
                  value={props.PreAnestheticSequence?.data?.HabitDrugAbuseRemark || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "12/23"}}>
              <div
                style={{ display:  props.PreAnestheticSequence?.data?.HabitHerbSupplement ? "flex" : "none",alignItems:"center" }}>
                
                <Input
                  disabled={!props.PreAnestheticSequence?.data?.HabitHerbSupplement}
                  name="HabitHerbSupplementRemark"
                  onChange={props.onChangeData}
                  style={{marginLeft:"5px",height:"32px",width:"1000px"}}
                  value={props.PreAnestheticSequence?.data?.HabitHerbSupplementRemark || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "1/7"}}>
              <div>
                
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  Functional class
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "7/27"}}>
              <div
                style={{paddingTop:"5px"}}>
                
                <Dropdown
                  name="FunctionalClass"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_functional_class || []}
                  selection={true}
                  style={{minWidth: "100px",minHeight:"32px" }}
                  value={props.PreAnestheticSequence?.data?.FunctionalClass || ""}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ gridRow: "12/13", gridColumn: "1/7"}}>
              <div>
                
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  LMP
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "12/13", gridColumn: "7/21"}}>
              <div
                style={{ display: "flex",alignItems:"center" }}>
                
                <DateTextBox
                  onChange={props.onChangeDate}
                  value={props.PreAnestheticSequence?.data?.FunctionalLMP || ""}>
                </DateTextBox>
              </div>
            </div>
            <div
              style={{ gridRow: "13/14", gridColumn: "1/7"}}>
              <div>
                
                <label
                  style={{marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                  Contact lens
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "13/14", gridColumn: "7/11"}}>
              <div
                style={{paddingTop:"5px"}}>
                
                <Dropdown
                  name="FunctionalIsContactLens"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_contact_lens || []}
                  selection={true}
                  style={{minWidth: "100px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                  value={props.PreAnestheticSequence?.data?.FunctionalIsContactLens || ""}>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default ANES_PreANES_02_history

export const screenPropsDefault = {}

/* Date Time : Thu Jan 26 2023 11:12:25 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" ,marginTop:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",height:\"90%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(50, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/7\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "Previous anesthesia"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"7/27\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",paddingTop:\"5px\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Dropdown",
      "parent": 14,
      "props": {
        "name": {
          "type": "value",
          "value": "PreviousAnesthesia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_previous || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreviousAnesthesia || \"\""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Input",
      "parent": 14,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreviousAnesthesia !== \"Yes\" "
        },
        "name": {
          "type": "value",
          "value": "PreviousAnesthesiaRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"32px\",width:\"1000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreviousAnesthesiaRemark || \"\""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/7\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"7/27\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "label",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": "Complication"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",paddingTop:\"5px\" }"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Dropdown",
      "parent": 58,
      "props": {
        "name": {
          "type": "value",
          "value": "Complication"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_complication || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Complication || \"\""
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Input",
      "parent": 58,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Complication !== \"Yes\" "
        },
        "name": {
          "type": "value",
          "value": "ComplicationRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"32px\",width:\"1000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ComplicationRemark || \"\""
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/4\", gridColumn: \"1/7\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/4\", gridColumn: \"7/27\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "label",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "Known disease"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/5\", gridColumn: \"1/7\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/5\", gridColumn: \"7/27\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "label",
      "parent": 111,
      "props": {
        "children": {
          "type": "value",
          "value": "Family history"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/6\", gridColumn: \"1/7\"}"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/6\", gridColumn: \"7/27\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "label",
      "parent": 129,
      "props": {
        "children": {
          "type": "value",
          "value": "Current medications"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",paddingTop:\"5px\" }"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "Dropdown",
      "parent": 145,
      "props": {
        "disabled": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "KnownDisease"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_known_disease || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.KnownDisease || \"\""
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "Input",
      "parent": 145,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.KnownDisease !== \"Yes\" "
        },
        "name": {
          "type": "value",
          "value": "KnownDiseaseRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"32px\",width:\"1000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.KnownDiseaseRemark || \"\""
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "div",
      "parent": 106,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/4\", gridColumn: \"7/27\" }"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 148,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",paddingTop:\"5px\" }"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "Dropdown",
      "parent": 149,
      "props": {
        "name": {
          "type": "value",
          "value": "FamilyHistory"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_family_history || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.FamilyHistory || \"\""
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "Input",
      "parent": 149,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.FamilyHistory !== \"Yes\" "
        },
        "name": {
          "type": "value",
          "value": "FamilyHistoryRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"32px\",width:\"1000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.FamilyHistoryRemark || \"\""
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",paddingTop:\"5px\" }"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "Dropdown",
      "parent": 152,
      "props": {
        "name": {
          "type": "value",
          "value": "CurrentMedications"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_current_med || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.CurrentMedications || \"\""
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "Input",
      "parent": 152,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.CurrentMedications !== \"Yes\" "
        },
        "name": {
          "type": "value",
          "value": "CurrentMedicationsRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"32px\",width:\"1000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.CurrentMedicationsRemark || \"\""
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/7\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "label",
      "parent": 158,
      "props": {
        "children": {
          "type": "value",
          "value": "Habit"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"7/12\"}"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"7/12\"}"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"7/12\" }"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"7/12\"}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"12/23\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"12/23\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"12/23\"}"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"12/23\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "div",
      "parent": 168,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\" }"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "label",
      "parent": 176,
      "props": {
        "children": {
          "type": "value",
          "value": "Alcohol"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.data?.HabitAlcohol  ? \"flex\" : \"none\", alignItems:\"center\" }"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "Input",
      "parent": 179,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.HabitAlcohol"
        },
        "name": {
          "type": "value",
          "value": "HabitAlcoholRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"32px\",width:\"1000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HabitAlcoholRemark || \"\""
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 169,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\" }"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "label",
      "parent": 182,
      "props": {
        "children": {
          "type": "value",
          "value": "Smoking"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "div",
      "parent": 170,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\" }"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "label",
      "parent": 185,
      "props": {
        "children": {
          "type": "value",
          "value": "Drug abuse"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "div",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\" }"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "label",
      "parent": 188,
      "props": {
        "children": {
          "type": "value",
          "value": "Herb/Supplement"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "div",
      "parent": 173,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.data?.HabitSmoking  ? \"flex\" : \"none\", alignItems:\"center\" }"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 192,
      "name": "Input",
      "parent": 191,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.HabitSmoking"
        },
        "name": {
          "type": "value",
          "value": "HabitSmokingRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"32px\",width:\"1000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HabitSmokingRemark || \"\""
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 193,
      "name": "div",
      "parent": 174,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.data?.HabitDrugAbuse  ? \"flex\" : \"none\" ,alignItems:\"center\" }"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 194,
      "name": "Input",
      "parent": 193,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.HabitDrugAbuse"
        },
        "name": {
          "type": "value",
          "value": "HabitDrugAbuseRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"32px\",width:\"1000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HabitDrugAbuseRemark || \"\""
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": null,
      "id": 195,
      "name": "div",
      "parent": 175,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display:  props.PreAnestheticSequence?.data?.HabitHerbSupplement ? \"flex\" : \"none\",alignItems:\"center\" }"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "Input",
      "parent": 195,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.HabitHerbSupplement"
        },
        "name": {
          "type": "value",
          "value": "HabitHerbSupplementRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\",height:\"32px\",width:\"1000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HabitHerbSupplementRemark || \"\""
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": null,
      "id": 197,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"1/7\"}"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": null,
      "id": 198,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"7/27\"}"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": null,
      "id": 199,
      "name": "div",
      "parent": 197,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": null,
      "id": 200,
      "name": "label",
      "parent": 199,
      "props": {
        "children": {
          "type": "value",
          "value": "Functional class"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": null,
      "id": 203,
      "name": "div",
      "parent": 198,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop:\"5px\"}"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 204,
      "name": "Dropdown",
      "parent": 203,
      "props": {
        "name": {
          "type": "value",
          "value": "FunctionalClass"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_functional_class || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\",minHeight:\"32px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.FunctionalClass || \"\""
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": null,
      "id": 205,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/13\", gridColumn: \"1/7\"}"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": null,
      "id": 206,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/13\", gridColumn: \"7/21\"}"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": null,
      "id": 207,
      "name": "div",
      "parent": 205,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": null,
      "id": 208,
      "name": "label",
      "parent": 207,
      "props": {
        "children": {
          "type": "value",
          "value": "LMP"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": null,
      "id": 209,
      "name": "div",
      "parent": 206,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",alignItems:\"center\" }"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": null,
      "id": 211,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"1/7\"}"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": null,
      "id": 212,
      "name": "div",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"7/11\"}"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": null,
      "id": 213,
      "name": "div",
      "parent": 211,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": null,
      "id": 214,
      "name": "label",
      "parent": 213,
      "props": {
        "children": {
          "type": "value",
          "value": "Contact lens"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": null,
      "id": 215,
      "name": "div",
      "parent": 212,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop:\"5px\"}"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "Dropdown",
      "parent": 215,
      "props": {
        "name": {
          "type": "value",
          "value": "FunctionalIsContactLens"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_contact_lens || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.FunctionalIsContactLens || \"\""
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 221,
      "name": "Checkbox",
      "parent": 188,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HabitHerbSupplement || false"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "HabitHerbSupplement"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 222,
      "name": "Checkbox",
      "parent": 185,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HabitDrugAbuse || false"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "HabitDrugAbuse"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 223,
      "name": "Checkbox",
      "parent": 182,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HabitSmoking || false"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "HabitSmoking"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 224,
      "name": "Checkbox",
      "parent": 176,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HabitAlcohol || false"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "HabitAlcohol"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 226,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 227,
      "name": "div",
      "parent": 226,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.status_label === \"Confirm\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 228,
      "name": "DateTextBox",
      "parent": 209,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.FunctionalLMP || \"\""
        }
      },
      "seq": 228,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PreANES_02_history",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
