import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Checkbox,
  Input,
  Icon,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardSearchInvoiceGroup = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{width: "100%",height:"100%",padding:"10px"}}>
        <div>
          
          <div
            style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px 0"}}>
            
            <div
              style={{fontSize: "1.2rem", fontWeight: "bold"}}>
              ออกใบแจ้งหนี้เรียกเก็บเงินต้นสังกัด
            </div>
            <div
              style={{cursor: "pointer"}}>
              
              <Icon
                className="red large"
                name="close"
                onClick={props.onClose}>
              </Icon>
            </div>
          </div>
        </div>
        <div
          className="ui divider">
          
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.isCreateDate || false}
                label="วันที่สร้างใบแจ้งหนี้"
                name="isCreateDate"
                onClick={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%"}}>
                {props.DateTextBox}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.isInvoiceNo || false}
                label="เลขที่ใบแจ้งหนี้"
                name="isInvoiceNo"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                disabled={!props.isInvoiceNo}
                name="invoiceNo"
                onChange={props.onChangeValue}
                value={props.invoiceNo || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.isFiscalYear || false}
                label="ปีงบประมาณ"
                name="isFiscalYear"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                disabled={!props.isFiscalYear }
                name="fiscalYear"
                onChange={props.onChangeValue}
                value={props.fiscalYear || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={1}>
            </FormField>
            <div
              className="inline two wide field"
              style={{marginLeft: "auto"}}>
              
              <Button
                color={"blue"}
                fluid={true}
                onClick={props.onSearch}>
                ค้นหา
              </Button>
            </div>
          </FormGroup>
        </div>
        <div>
          
          <Table
            data={props.items || []}
            getTrProps={props.getTrProps}
            headers="ปีงบประมาณ,เลขที่ใบแจ้งหนี้,สิทธิ,ประเภทผู้ป่วย,สถานะส่งเบิก"
            keys="fiscal_year,lot_no,coverage_code_name,patient_type,status_display"
            showPagination={false}
            style={{height: "420px"}}>
          </Table>
        </div>
        <div
          style={{display: "flex", justifyContent: "flex-end",marginTop: "1rem"}}>
          
          <Button
            color="green"
            disabled={props.disabledButtonSelect || false}
            onClick={props.onSelect}>
            เลือก
          </Button>
        </div>
      </div>
    )
}

export default CardSearchInvoiceGroup

export const screenPropsDefault = {}

/* Date Time : Tue Dec 13 2022 12:54:38 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\",height:\"100%\",padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\", padding: \"5px 0\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormGroup",
      "parent": 35,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 38,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 38,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 38,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 38,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 38,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormField",
      "parent": 38,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Checkbox",
      "parent": 39,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isCreateDate || false"
        },
        "label": {
          "type": "value",
          "value": "วันที่สร้างใบแจ้งหนี้"
        },
        "name": {
          "type": "value",
          "value": "isCreateDate"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Checkbox",
      "parent": 41,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isInvoiceNo || false"
        },
        "label": {
          "type": "value",
          "value": "เลขที่ใบแจ้งหนี้"
        },
        "name": {
          "type": "value",
          "value": "isInvoiceNo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Input",
      "parent": 42,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.isInvoiceNo"
        },
        "name": {
          "type": "value",
          "value": "invoiceNo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.invoiceNo || \"\""
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Checkbox",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isFiscalYear || false"
        },
        "label": {
          "type": "value",
          "value": "ปีงบประมาณ"
        },
        "name": {
          "type": "value",
          "value": "isFiscalYear"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Input",
      "parent": 44,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.isFiscalYear "
        },
        "name": {
          "type": "value",
          "value": "fiscalYear"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.fiscalYear || \"\""
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 53,
      "name": "Table",
      "parent": 36,
      "props": {
        "data": {
          "type": "code",
          "value": "props.items || []"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "ปีงบประมาณ,เลขที่ใบแจ้งหนี้,สิทธิ,ประเภทผู้ป่วย,สถานะส่งเบิก"
        },
        "keys": {
          "type": "value",
          "value": "fiscal_year,lot_no,coverage_code_name,patient_type,status_display"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"420px\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "ออกใบแจ้งหนี้เรียกเก็บเงินต้นสังกัด"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Icon",
      "parent": 55,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\",marginTop: \"1rem\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Button",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือก"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledButtonSelect || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelect"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 38,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "inline two wide field"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"auto\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Button",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "code",
          "value": "\"blue\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        }
      },
      "seq": 62,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": true,
  "memo": false,
  "name": "CardSearchInvoiceGroup",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 85
}

*********************************************************************************** */
