import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardInputDateForCreateUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{backgroundColor: "#FFFFF",width: "100%",height:"100%",padding:"20px"}}>
        <div
          style={{fontSize:"18px",fontWeight:"bold", padding: "10px 5px"}}>
          
          <label>
            ระบุข้อมูลสำหรับสร้างข้อมูลส่งเบิก
          </label>
        </div>
        <hr>
        </hr>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width="3">
              <label
                style={{display: "flex",height:"100%", fontSize:"16px",alignItems:"center"}}>
                ระบุสิทธิ
              </label>
              <label
                style={{display: "flex",marginLeft:"10px",height:"100%", fontSize:"16px",alignItems:"center",color:"red"}}>
                *
              </label>
            </FormField>
            <FormField
              inline={true}
              width="3">
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.onCoveragePayerChange}
                options={props.coveragePayerOptions}
                selection={true}
                style={{width: "100%"}}
                value={props.coveragePayer}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width="3">
              <label
                style={{display: "flex",height:"100%", fontSize:"16px",alignItems:"center"}}>
                ระบุวันที่
              </label>
            </FormField>
            <FormField
              inline={true}
              width="3">
              <div
                style={{width: "100%"}}>
                
                <DateTextBox
                  onChange={props.onChangeDate}
                  value={props.date}>
                </DateTextBox>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width="12">
            </FormField>
            <FormField
              inline={true}
              width="4">
              <Button
                onClick={props.onConfirm}
                style={{backgroundColor:"#27AE60",padding:"10px 30px", color:"#FFFFFF",fontSize:"18px",width:"100%"}}>
                ยืนยัน
              </Button>
              <Button
                onClick={props.onClose}
                style={{backgroundColor:"#FB3333",padding:"10px 30px", color:"#FFFFFF",fontSize:"18px",width:"100%"}}>
                ยกเลิก
              </Button>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}

export default CardInputDateForCreateUX

export const screenPropsDefault = {}

/* Date Time : Mon Nov 28 2022 21:52:18 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#FFFFF\",width: \"100%\",height:\"100%\",padding:\"20px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontSize:\"18px\",fontWeight:\"bold\", padding: \"10px 5px\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุข้อมูลสำหรับสร้างข้อมูลส่งเบิก"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 60,
      "void": true
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormGroup",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormGroup",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormGroup",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 64,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "12"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 64,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "4"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุสิทธิ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"100%\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",marginLeft:\"10px\",height:\"100%\", fontSize:\"16px\",alignItems:\"center\",color:\"red\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุวันที่"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"100%\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Dropdown",
      "parent": 66,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onCoveragePayerChange"
        },
        "options": {
          "type": "code",
          "value": "props.coveragePayerOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.coveragePayer"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Button",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยัน"
        },
        "onClick": {
          "type": "code",
          "value": "props.onConfirm"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor:\"#27AE60\",padding:\"10px 30px\", color:\"#FFFFFF\",fontSize:\"18px\",width:\"100%\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Button",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor:\"#FB3333\",padding:\"10px 30px\", color:\"#FFFFFF\",fontSize:\"18px\",width:\"100%\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 79,
      "name": "DateTextBox",
      "parent": 78,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate"
        },
        "value": {
          "type": "code",
          "value": "props.date"
        }
      },
      "seq": 79,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 35,
  "isMounted": true,
  "memo": false,
  "name": "CardInputDateForCreateUX",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 75
}

*********************************************************************************** */
