import React, { useEffect, useState, CSSProperties } from "react";
import moment from "moment";
import { momentLocalizer } from "react-big-calendar";
import { Dropdown, Modal } from "semantic-ui-react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CardAppointmentSummaryUX from "./CardAppointmentSummaryUX";
import ChartAppointmentSummary from "./ChartAppointmentSummary";
import { blockListForAppointmentSummary } from "./common/BlockList";
import DateTextBox8 from "react-lib/apps/common/DateTextBox8";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";

import { DateTextBox, TimeTextBox, TimeFreeTextBox24 } from "react-lib/apps/common";
import { formatDate } from "react-lib/utils/dateUtils";

moment.locale("en-es", { week: { dow: 1 } });
const localizer = momentLocalizer(moment);

const CardAppointmentSummary = (props: any) => {
  const [appointmentList, setAppointmentList] = useState<any[]>([]);
  const [selectedDivision, setSelectedDivision] = useState<any>(null);
  const [openChart, setOpenChart] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState<any>(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [appointmentType, setAppointmentType] = useState<any>(null);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);

  const [blockListAndHoliday, setBlockListAndHoliday] = useState<any[]>([]);
  const [calendarYear, setCalendarYear] = useState<string[]>([moment().format("YYYY")]);

  useEffect(() => {
    props.onEvent({ message: "GetChairList", params: {} });
  }, []);

  useEffect(() => {
    if (props.blockList?.length > 0) {
      let bl = blockListForAppointmentSummary(props.blockList);
      console.log("useEffect bl", bl);

      const holiday = (props.holiday?.items || []).flatMap((item: any) => {
        const [day, month, year] = item.date[0].split("/");
        return calendarYear.map((year: any) => {
          const date = `${year}-${month}-${day}`;
          return { start: new Date(`${date} 00:00`), end: new Date(`${date} 23:59`), title: item.detail, holiday: true };
        });
      });

      console.log("useEffect holiday: ", holiday);
      setBlockListAndHoliday([...holiday, ...bl]);
    }
  }, [props.blockList, props.holiday]);

  useEffect(() => {
    props.onEvent({
      message: "FilterSchedule",
      params: {
        divisionId: props.selectedDivision?.id,
        initial: selectedDivision === null,
      },
    });
    setSelectedDivision(props.selectedDivision);
  }, [props.selectedDivision]);

  useEffect(() => {
    // filter only status === 1   Note: active(1), inactive(2), revise(3)
    let fAppointmentList = props.filterAppointmentList?.filter((item: any) => item.status === 1);
    // console.log("filterAppointmentList: ", props.filterAppointmentList)
    setAppointmentList(fAppointmentList);
  }, [props.filterAppointmentList]);

  const getAppointmentForChair = (list: any[], providers: any[]) => {
    // console.log('getAppointmentForChair: list', list);
    // console.log('getAppointmentForChair: providers', providers);
    // console.log('getAppointmentForChair: props.chairList', props.chairList);
    let ret = list.map((item: any) => ({
      ...item,
      providers: providers
        .filter((item: any) => item.dsb.appointments.length)
        .filter((provider: any) => provider.dsb.doctor_provider_name === item.provider && provider.dsb.appointments.find((acc: any) => acc.id === item.id))
        .map((provider: any) => provider.dsb)
        .map((provider: any) => ({
          ...provider,
          chairName: props.chairList.find((el: any) => el.provider && el.provider === provider?.chair_provider_id)?.name,
        })),
    }));
    // console.log('getAppointmentForChair ret: ', ret);
    return ret;
  };

  const mapProviderOptions = (items: any) => {
    // console.log("mapProviderOptions items", items)
    return items.map((item: any) => ({
      key: item.provider_id,
      value: item.first_name + " " + item.last_name + " [" + item.code + "]",
      text: item.first_name + " " + item.last_name + " [" + item.code + "]",
    }));
  };

  const handleSelectEvent = (e: any) => {
    console.log(e);

    setSelectedEvent(e);
    setAppointmentList(e.appointmentList);
  };

  const handlePrint = (e: any) => {
    const appointments = getAppointmentForChair(
      appointmentList || [],
      selectedEvent ? selectedEvent.providers : (props.blockList || []).flatMap((acc: any) => acc.providers)
    );

    // console.log('handlePrint selectedEvent: ', selectedEvent, props.blockList);
    // console.log('handlePrint appointmentList: ', appointmentList);
    // console.log('handlePrint appointments: ', appointments);
    // console.log(" handlePrint props.data?.divisionDict" , props?.divisionDict)
    const orders = appointments.map((item: any) => {
      // console.log("CardAppointmentSummary item" , item )
      return {
        ...item,
        ...(item?.original || {}),
        patient_hn: item.patient_hn || item.original?.hn,
        patient: item.patient_first_name
          ? item.patient_pre_name
            ? `${item.patient_pre_name} ${item.patient_first_name} ${item.patient_last_name}`
            : `${item.patient_first_name} ${item.patient_last_name}`
          : item.patient,
        patient_tel: item.patient_tel || item.original?.tel,
        patient_id: item.patient_id || item.original?.id,
        division_name: item.original?.appointment_div_name || selectedEvent?.division_name,
        provider_name: item.display_info?.provider_name || item.provider,
        chair: props.chairList.find((el: any) => el.provider && el.provider === item.chair_provider_id)?.name,
        zone: item?.chair_zone_id === null ? "ว่าง" : props.divisionDict?.[item?.chair_zone_id]?.name,
        estimated_at: item.estimated_at
          ? item.estimated_at
          : `${formatDate(moment(item.original.estimated_at))} [${moment(item.original.estimated_at).format("HH:mm")}]`,
      };
    });

    const provider = Array.from(new Set(orders.map((item) => item.provider_name)));
    const fromDate = startDate.includes("-") ? startDate.replace(/-/g, "/") : startDate;
    const toDate = endDate.includes("-") ? endDate.replace(/-/g, "/") : endDate;
    // console.log('SORT fromDate: ', fromDate);
    // console.log('SORT toDate: ', toDate);

    // console.log('orders: ', orders);

    props.onEvent({
      message: "PrintScheduling",
      params: {
        form: "FormAppointmentSummary",
        data: {
          is_search: !selectedEvent,
          division_name: props.selectedDivision.name_code,
          provider_name: !selectedEvent ? `${selectedDoctor?.first_name || ""} ${selectedDoctor?.last_name || ""}` : provider.length === 1 ? provider[0] : "",
          date_range: `${selectedEvent ? formatDate(moment(selectedEvent?.start)) : fromDate} - ${
            selectedEvent ? formatDate(moment(selectedEvent?.end)) : toDate
          }`,
          orders,
        },
      },
    });
  };

  // console.log("props.blockList", props, appointmentList);
  // console.log("props.blockList", blockListForAppointmentSummary(props.blockList));

  return (
    <div style={{ margin: "10px 0px 0px 0px" }}>
      <CardAppointmentSummaryUX
        startDateBox={
          <DateTextBox8
            mode="single"
            onChange={({ string }: any) => {
              console.log("setStartDate stringDate: ", string);

              setStartDate(string);
            }}
            valueList={startDate}
          />
        }
        endDateBox={
          <DateTextBox8
            mode="single"
            onChange={({ string }: any) => {
              console.log("setEndDate stringDate: ", string);
              setEndDate(string);
            }}
            valueList={endDate}
          />
        }
        startTime={<TimeTextBox onTimeChange={(e: any, v: string) => setStartTime(v)} />}
        endTime={<TimeTextBox onTimeChange={(e: any, v: string) => setEndTime(v)} />}
        searchDoctorBox={
          <SearchBoxDropdown
            type="Provider"
            id="5"
            style={{ width: "100%" }}
            fluid={true}
            label=""
            // disabled={selectedManagePatient !== null}
            toDisplay={(item: any) => `${item.first_name} ${item.last_name} [${item.code}]`}
            onEvent={props.onEvent}
            // searchedItemList={props.searchedItemList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={selectedDoctor}
            setSelectedItem={(value: any, key: any, obj: any) => {
              if (value === null && key === null) {
                setSelectedDoctor(null);
                return;
              }
              let doctor = props.searchedItemListWithKey?.Provider_5?.find((item: any) => item.provider_id === key);
              setSelectedDoctor(doctor);
            }}
            useWithKey={true}
            mapOptions={mapProviderOptions}
          />
        }
        appointmentType={
          <Dropdown
            selection={true}
            options={props.patientAppType}
            onChange={(e: any, data: any) => {
              setAppointmentType(data.value);
            }}
            clearable={true}
            value={appointmentType}
          />
        }
        onSearchClick={(e: any) => {
          setAppointmentList([]);
          setSelectedEvent(null);
          if (startDate || startTime || endDate || endTime || appointmentType) {
            props.onEvent({
              message: "SetScheduling",
              params: {
                action: "FilterByAppointmentSummary",
                startDate,
                startTime,
                endDate,
                endTime,
                appointmentType,
                selectedDoctor,
              },
            });
          }
        }}
        localizer={localizer}
        blockList={blockListAndHoliday}
        appointmentList={appointmentList || []}
        onSelectEvent={handleSelectEvent}
        print={handlePrint}
        showChart={(e: any) => setOpenChart(true)}
        loadingFilterAppList={props.loadingFilterAppList}
        loadingPrintAppList={props.loadingPrintAppList}
        eventPropGetter={(event: any, start: Date, end: Date, isSelected: boolean) => {
          // เลือกแพทย์ ไม่มี dsb
          // console.log("props.selectedDivision?.id", props.selectedDivision?.id)
          console.log("event", event)
          return {
            style: event?.holiday ? { backgroundColor: "#FFE696", color: "black", textAlign: "center" } : ({} as CSSProperties),
          };
        }}
        onRangeChange={(range: any, view: any) => {
          const year = Array.isArray(range) ? [range[0], range.slice(-1)[0]] : [range.start, range.end];
          const uniqueYear = Array.from(new Set([moment(year[0]).format("YYYY"), moment(year[1]).format("YYYY")]));
          if (uniqueYear.toString() !== calendarYear.toString()) {
            setCalendarYear(uniqueYear);
          }
        }}
      />
      <Modal open={openChart} onClose={() => setOpenChart(false)} closeOnDimmerClick={true}>
        <ChartAppointmentSummary onEvent={props.onEvent} />
      </Modal>
    </div>
  );
};

export default CardAppointmentSummary;
