import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Checkbox,
  Dropdown,
  Button,
  Form,
  TextArea,
  Input
} from 'semantic-ui-react'
import {
  TimeTextBox,
  TimeFreeTextBox24
} from 'react-lib/apps/common'

const CardAppointmentDetailUX = (props: any) => {
    return(
      <div
        style={{padding: "10px 10px"}}>
        <div
          style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(11, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
          <div
            style={{"gridRow":"2/11","gridColumn":"1/41",...(props.hideGeneralDetail ? {display: "none"} : {display: "block"} )}}>
            <Table
              data={props.blockAppointments}
              headers="ชื่อคนไข้,เวลาเริ่ม,ระยะเวลา,ผู้ทำนัดหมาย,วันเวลาที่ทำนัดหมาย"
              keys="patient_name,estimated_at_display,estimated_duration,edit_user_name,updated"
              minRows="4"
              showPagination={false}
              style={{height: "200px"}}>
            </Table>
          </div>
          <div
            style={{ gridRow: "1/2", gridColumn: "1/15", ...(props.hideGeneralDetail ? {display: "none"} : {display: "block"} )}}>
            รายชื่อคนไข้อื่นๆในคาบเดียวกัน
          </div>
        </div>
        <div
          style={{display: "flex", flexDirection: "column", gap: "10px"}}>
          
          <div
            style={{display: "flex", justifyContent:"flex-start", gap: "35px", alignItems: "center" , ...(props.hideGeneralDetail ? {display: "none"} : {display: "flex"} )}}>
            
            <div
              style={{display: "flex", gap: "10px", alignItems:"center"}}>
              
              <div
                style={{fontWeight: "bolder"}}>
                HN: 
              </div>
              <div>
                {}
                <Input
                  readOnly={true}
                  value={props.selectedAppointment?.hn || props.selectedAppointment?.patient_hn || ""}>
                </Input>
              </div>
            </div>
            <div
              style={{display: "flex", gap: "10px"}}>
              
              <div
                style={{fontWeight: "bolder"}}>
                {props.language === "EN" ? "Name  -  Family name:" : "ชื่อ-สกุล : "}
              </div>
              <div>
                {props.selectedAppointment?.patient_name}
              </div>
            </div>
            <div
              style={{display: "flex", gap: "10px"}}>
              
              <div
                style={{fontWeight: "bolder"}}>
                เบอร์โทร : 
              </div>
              <div>
                {props.selectedAppointment?.patient_tel}
              </div>
            </div>
          </div>
          <div
            style={{display: "flex", justifyContent:"flex-start", gap: "35px",alignItems: "center", ...(props.hideGeneralDetail ? {display: "none"} : {display: "flex"} )}}>
            
            <div
              style={{display: "flex", gap: "10px"}}>
              
              <div
                style={{fontWeight: "bolder"}}>
                พบแพทย์: 
              </div>
              <div>
                {props.selectedAppointment?.detail}
              </div>
            </div>
            <div
              style={{display: "flex", gap: "10px", alignItems:"center"}}>
              
              <div
                style={{fontWeight: "bolder"}}>
                คลินิก : 
              </div>
              <div>
                {}
                <Input
                  value={props.selectedAppointment?.division_name}>
                </Input>
              </div>
            </div>
          </div>
          <div
            style={{display: "flex", justifyContent:"flex-start", gap: "35px",alignItems: "center", ...(props.hideGeneralDetail ? {display: "none"} : {display: "flex"} )}}>
            
            <div
              style={{display: "flex",alignItems: "center", gap: "10px"}}>
              
              <div
                style={{fontWeight: "bolder"}}>
                วันที่นัดหมาย : 
              </div>
              <div>
                
                <Input
                  onFocus={props.onDateFocus}
                  value={props.appointmentedDate}>
                </Input>
              </div>
            </div>
            <div
              style={{display: "flex",alignItems: "center", gap: "10px"}}>
              
              <div
                style={{fontWeight: "bolder"}}>
                เวลานัดหมาย : 
              </div>
              <div
                style={{display: props.manualTime ? "none" : "block"}}>
                {}
                <TimeTextBox
                  availableOptions={props.availableOptions}
                  interval={props.intervalTimeSelected}
                  maxHour={props.maxHour}
                  minHour={props.minHour}
                  onTimeChange={props.onTimeAppointmentChange}
                  reservedText={props.reservedText}
                  useDefaultTime={true}
                  value={props.estimatedAt}>
                </TimeTextBox>
              </div>
              <div
                style={{display: props.manualTime ? "block" : "none"}}>
                
                <TimeFreeTextBox24
                  className="time-custom-react-picker"
                  maxHour={props.maxHour}
                  minHour={props.minHour}
                  onChange={(value: any) => props.setEstimatedAt(value)}
                  value={props.estimatedAt}>
                </TimeFreeTextBox24>
              </div>
            </div>
            <div>
              
              <Checkbox
                checked={props.manualTime}
                label="กำหนดเวลาแบบ manual"
                onChange={() => props.setManualTime(!props.manualTime)}>
              </Checkbox>
            </div>
          </div>
          <div
            style={{display: "flex", justifyContent:"flex-start", gap: "35px",alignItems: "center", ...(props.hideGeneralDetail ? {display: "none"} : {display: "flex"} )}}>
            
            <div
              style={{display: "flex",alignItems: "center", gap: "10px"}}>
              
              <div
                style={{fontWeight: "bolder"}}>
                ระยะเวลา (นาที) :
              </div>
              <div>
                {}
                <Input
                  min={1}
                  onChange={(e: any) => props.setEstimatedDuration(e.target.value)}
                  type="number"
                  value={props.estimatedDuration}>
                </Input>
              </div>
            </div>
            <div>
              
              <Checkbox
                checked={props.full}
                label="บันทึกว่าคาบนี้เต็มแล้ว"
                onChange={props.toggleFull}>
              </Checkbox>
            </div>
            <div
              style={{display: "flex",alignItems: "center", gap: "10px"}}>
              
              <div
                style={{fontWeight: "bolder"}}>
                ประเภทการนัดหมาย
              </div>
              <div>
                
                <Dropdown
                  onChange={props.onChangeTreatmentType}
                  options={props.treatmentTypeOptions || []}
                  selection={true}
                  value={props.treatmentType}>
                </Dropdown>
              </div>
            </div>
          </div>
          <div>
            {props.divider}
          </div>
          <div
            style={{display: props.hideDrugAllergy ? "none" : "flex", justifyContent:"flex-start", gap: "35px",alignItems: "center", ...(props.hideGeneralDetail ? {display: "none"} : {display: "flex"} )}}>
            
            <div
              style={{display: "flex", alignItems:"center", gap: "10px"}}>
              
              <div
                style={{fontWeight: "bolder"}}>
                การแพ้ยา : 
              </div>
              <div>
                
                <Input
                  onChange={props.onAllergyChange}
                  value={props.allergy}>
                </Input>
              </div>
            </div>
            <div
              style={{display: "flex", alignItems:"center", gap: "10px"}}>
              
              <div
                style={{fontWeight: "bolder"}}>
                Premedication : 
              </div>
              <div>
                
                <Input
                  onChange={props.onPreMedicationChange}
                  value={props.premedication}>
                </Input>
              </div>
            </div>
          </div>
          <div
            style={{display: props.hideDrugAllergy ? "none" : "flex",fontWeight: "bolder", margin: "20px 0px 5px 0px", ...(props.hideGeneralDetail ? {display: "none"} : {display: "flex"} )}}>
            คำสั่งแพทย์
          </div>
          <div
            style={{display: props.hideDrugAllergy ? "none" : "flex", justifyContent:"flex-start", gap: "35px",alignItems: "center", ...(props.hideGeneralDetail ? {display: "none"} : {display: "flex"} )}}>
            
            <div
              style={{display: "flex", alignItems:"center", gap: "10px"}}>
              
              <div
                style={{fontWeight: "bolder"}}>
                สั่งทำ Lab : 
              </div>
              <div>
                
                <Input
                  onChange={props.onOrderLabChange}
                  value={props.orderLab}>
                </Input>
              </div>
            </div>
            <div
              style={{display: "flex", alignItems:"center", gap: "10px"}}>
              
              <div
                style={{fontWeight: "bolder"}}>
                สั่งทำ x-ray : 
              </div>
              <div>
                
                <Input
                  onChange={props.onOrderXrayChange}
                  value={props.orderXray}>
                </Input>
              </div>
            </div>
          </div>
          <div
            style={{display: "flex", justifyContent:"space-between", gap: "10px",alignItems: props.useNoteTemplate ? "flex-end" : "center", ...(props.hideGeneralDetail ? {display: "none"} : {display: "flex"} )}}>
            
            <div
              style={{fontWeight: "bolder"}}>
              คำแนะนำในการปฏิบัติตัวเพิ่มเติม
            </div>
            <div>
              
              <Button
                color="blue"
                onClick={props.onAddNote}>
                เพิ่มคำแนะนำ
              </Button>
            </div>
          </div>
          <div
            style={{width: "100%", ...(props.hideGeneralDetail ? {display: "none"} : {display: "block"} )}}>
            
            <Form>
              <label
                style={{fontWeight: "bold", display: props.useNoteTemplate ? "none" : ""}}>
                รายละเอียดการนัด
              </label>
              <TextArea
                onChange={props.setOrderNote}
                style={{width: "100%"}}
                value={props.orderNote}>
              </TextArea>
            </Form>
          </div>
          <div
            style={{width: "100%", display: props.showOrderAppointment? "block": "none"}}>
            
            <Form>
              <label
                style={{fontWeight: "bold"}}>
                สั่งออเดอร์ล่วงหน้า
              </label>
              <div
                style={{display: "grid", gridTemplateColumns: "18% 82%"}}>
                
                <div>
                  
                  <div
                    className="ui vertical menu">
                    
                    <Button
                      className={`item${props.activeOrder === "CENTRAL_LAB" ? " active" : ""}`}
                      disabled={props.isEditable === undefined? false : !props.isEditable}
                      fluid={true}
                      name="CENTRAL_LAB"
                      onClick={props.onClickOrder}
                      style={{boxShadow: "none", textAlign: "left"}}>
                      Central Lab
                    </Button>
                    <Button
                      className={`item${props.activeOrder === "TREATMENT" ? " active" : ""}`}
                      disabled={props.isEditable === undefined? false : !props.isEditable}
                      fluid={true}
                      name="TREATMENT"
                      onClick={props.onClickOrder}
                      style={{boxShadow: "none", textAlign: "left"}}>
                      Treatment
                    </Button>
                    <Button
                      className={`item${props.activeOrder === "IMAGING" ? " active" : ""}`}
                      disabled={props.isEditable === undefined? false : !props.isEditable}
                      fluid={true}
                      name="IMAGING"
                      onClick={props.onClickOrder}
                      style={{boxShadow: "none", textAlign: "left"}}>
                      Imaging
                    </Button>
                    <Button
                      className={`item${props.activeOrder === "BLOOD_BANK" ? " active" : ""}`}
                      disabled={props.isEditable === undefined? false : !props.isEditable}
                      fluid={true}
                      name="BLOOD_BANK"
                      onClick={props.onClickOrder}
                      style={{boxShadow: "none", textAlign: "left"}}>
                      Blood Bank
                    </Button>
                  </div>
                </div>
                <div>
                  
                  <Table
                    data={props.preOrderList || []}
                    headers="ID, Type, Details, Del, Edit"
                    keys="id, specific_type_name, order_detail, del, edit"
                    showPagination={false}
                    style={{height: "200px"}}>
                  </Table>
                </div>
              </div>
            </Form>
          </div>
          <div
            style={{display: "flex", gap: "10px", margin: "1rem 0 4rem", justifyContent: "flex-end"}}>
            
            <Button
              className="ignore-prevent"
              color="green"
              disabled={props.isEditable === undefined? false : !props.isEditable}
              loading={props.isLoading}
              onClick={props.onSaveAppointment}
              style={{display: props.hideButtonSave ?"none":""}}>
              {props.save}
            </Button>
            <Button
              className="ignore-prevent"
              color="red"
              disabled={props.isEditable === undefined? false : !props.isEditable}
              onClick={props.onCancelAppointment}
              style={{display: props.config?.hideDoctorCancel ? "none": "flex"}}>
              แพทย์ยกเลิก
            </Button>
            <Button
              className="ignore-prevent"
              color="red"
              disabled={props.isEditable === undefined? false : !props.isEditable}
              onClick={props.onCancelAppointmentByPatient}
              style={{display: props.config?.hidePatientCancel ? "none": "flex"}}>
              ผู้ป่วยยกเลิก
            </Button>
            <Button
              className="ignore-prevent"
              color="orange"
              disabled={props.isEditable === undefined? false : !props.isEditable}
              onClick={props.onPostponeAppointment}
              style={{ ...(props.config?.disabledPostpone ? { display: "none"} : {display: "flex"})}}>
              เลื่อนนัด
            </Button>
            <Button
              className="ignore-prevent"
              color="blue"
              disabled={props.isEditable === undefined? false : !props.isEditable}
              onClick={props.printAppointment}
              style={{display: props.hidePrintAppointmentDoc? "none": ""}}>
              พิมพ์ใบนัด
            </Button>
          </div>
        </div>
      </div>
    )
}

export default CardAppointmentDetailUX

export const screenPropsDefault = {"manualTime":false}

/* Date Time : Tue Mar 21 2023 17:35:17 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(11, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"2/11\",\"gridColumn\":\"1/41\",...(props.hideGeneralDetail ? {display: \"none\"} : {display: \"block\"} )}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 19,
      "name": "Table",
      "parent": 18,
      "props": {
        "data": {
          "type": "code",
          "value": "props.blockAppointments"
        },
        "headers": {
          "type": "value",
          "value": "ชื่อคนไข้,เวลาเริ่ม,ระยะเวลา,ผู้ทำนัดหมาย,วันเวลาที่ทำนัดหมาย"
        },
        "keys": {
          "type": "value",
          "value": "patient_name,estimated_at_display,estimated_duration,edit_user_name,updated"
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รายชื่อคนไข้อื่นๆในคาบเดียวกัน"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/15\", ...(props.hideGeneralDetail ? {display: \"none\"} : {display: \"block\"} )}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flexDirection: \"column\", gap: \"10px\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent:\"flex-start\", gap: \"35px\", alignItems: \"center\" , ...(props.hideGeneralDetail ? {display: \"none\"} : {display: \"flex\"} )}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent:\"flex-start\", gap: \"35px\",alignItems: \"center\", ...(props.hideGeneralDetail ? {display: \"none\"} : {display: \"flex\"} )}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent:\"flex-start\", gap: \"35px\",alignItems: \"center\", ...(props.hideGeneralDetail ? {display: \"none\"} : {display: \"flex\"} )}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent:\"flex-start\", gap: \"35px\",alignItems: \"center\", ...(props.hideGeneralDetail ? {display: \"none\"} : {display: \"flex\"} )}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divider"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.hideDrugAllergy ? \"none\" : \"flex\", justifyContent:\"flex-start\", gap: \"35px\",alignItems: \"center\", ...(props.hideGeneralDetail ? {display: \"none\"} : {display: \"flex\"} )}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "คำสั่งแพทย์"
        },
        "style": {
          "type": "code",
          "value": "{display: props.hideDrugAllergy ? \"none\" : \"flex\",fontWeight: \"bolder\", margin: \"20px 0px 5px 0px\", ...(props.hideGeneralDetail ? {display: \"none\"} : {display: \"flex\"} )}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.hideDrugAllergy ? \"none\" : \"flex\", justifyContent:\"flex-start\", gap: \"35px\",alignItems: \"center\", ...(props.hideGeneralDetail ? {display: \"none\"} : {display: \"flex\"} )}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent:\"space-between\", gap: \"10px\",alignItems: props.useNoteTemplate ? \"flex-end\" : \"center\", ...(props.hideGeneralDetail ? {display: \"none\"} : {display: \"flex\"} )}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", ...(props.hideGeneralDetail ? {display: \"none\"} : {display: \"block\"} )}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "HN: "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุล : ",
          "valueEN": "Name  -  Family name:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedAppointment?.patient_name"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": "เบอร์โทร : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "พบแพทย์: "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedAppointment?.detail"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "คลินิก : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 101,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่นัดหมาย : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลานัดหมาย : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 103,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.manualTime ? \"none\" : \"block\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "ระยะเวลา (นาที) :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทการนัดหมาย"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "การแพ้ยา : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 107,
      "props": {
        "children": {
          "type": "value",
          "value": "Premedication : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 107,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": "สั่งทำ Lab : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": "สั่งทำ x-ray : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 99,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedAppointment?.patient_tel"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Checkbox",
      "parent": 65,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.manualTime"
        },
        "label": {
          "type": "value",
          "value": "กำหนดเวลาแบบ manual"
        },
        "onChange": {
          "type": "code",
          "value": "() => props.setManualTime(!props.manualTime)"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Checkbox",
      "parent": 68,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.full"
        },
        "label": {
          "type": "value",
          "value": "บันทึกว่าคาบนี้เต็มแล้ว"
        },
        "onChange": {
          "type": "code",
          "value": "props.toggleFull"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Dropdown",
      "parent": 70,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeTreatmentType"
        },
        "options": {
          "type": "code",
          "value": "props.treatmentTypeOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.treatmentType"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": "คำแนะนำในการปฏิบัติตัวเพิ่มเติม"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Button",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มคำแนะนำ"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddNote"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Form",
      "parent": 51,
      "props": {
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "TextArea",
      "parent": 86,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.setOrderNote"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.orderNote"
        }
      },
      "seq": 123,
      "void": true
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"10px\", margin: \"1rem 0 4rem\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "Button",
      "parent": 88,
      "props": {
        "children": {
          "type": "code",
          "value": "props.save"
        },
        "className": {
          "type": "value",
          "value": "ignore-prevent"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.isEditable === undefined? false : !props.isEditable"
        },
        "loading": {
          "type": "code",
          "value": "props.isLoading"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSaveAppointment"
        },
        "style": {
          "type": "code",
          "value": "{display: props.hideButtonSave ?\"none\":\"\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Button",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ยกเลิก"
        },
        "className": {
          "type": "value",
          "value": "ignore-prevent"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "code",
          "value": "props.isEditable === undefined? false : !props.isEditable"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelAppointment"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideDoctorCancel ? \"none\": \"flex\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "Button",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "เลื่อนนัด"
        },
        "className": {
          "type": "value",
          "value": "ignore-prevent"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "disabled": {
          "type": "code",
          "value": "props.isEditable === undefined? false : !props.isEditable"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPostponeAppointment"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.config?.disabledPostpone ? { display: \"none\"} : {display: \"flex\"})}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "Button",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ใบนัด"
        },
        "className": {
          "type": "value",
          "value": "ignore-prevent"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.isEditable === undefined? false : !props.isEditable"
        },
        "onClick": {
          "type": "code",
          "value": "props.printAppointment"
        },
        "style": {
          "type": "code",
          "value": "{display: props.hidePrintAppointmentDoc? \"none\": \"\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Input",
      "parent": 67,
      "props": {
        "autoFocus": {
          "type": "code",
          "value": ""
        },
        "min": {
          "type": "code",
          "value": "1"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any) => props.setEstimatedDuration(e.target.value)"
        },
        "onFocus": {
          "type": "value",
          "value": ""
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.estimatedDuration"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"10px\", alignItems:\"center\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"10px\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"10px\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"10px\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"10px\", alignItems:\"center\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",alignItems: \"center\", gap: \"10px\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",alignItems: \"center\", gap: \"10px\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",alignItems: \"center\", gap: \"10px\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",alignItems: \"center\", gap: \"10px\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems:\"center\", gap: \"10px\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems:\"center\", gap: \"10px\"}"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems:\"center\", gap: \"10px\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems:\"center\", gap: \"10px\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 113,
      "name": "TimeTextBox",
      "parent": 64,
      "props": {
        "availableOptions": {
          "type": "code",
          "value": "props.availableOptions"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "interval": {
          "type": "code",
          "value": "props.intervalTimeSelected"
        },
        "maxHour": {
          "type": "code",
          "value": "props.maxHour"
        },
        "minHour": {
          "type": "code",
          "value": "props.minHour"
        },
        "onTimeChange": {
          "type": "code",
          "value": "props.onTimeAppointmentChange"
        },
        "reservedText": {
          "type": "code",
          "value": "props.reservedText"
        },
        "useDefaultTime": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.estimatedAt"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Input",
      "parent": 72,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onAllergyChange"
        },
        "value": {
          "type": "code",
          "value": "props.allergy"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Input",
      "parent": 74,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onPreMedicationChange"
        },
        "value": {
          "type": "code",
          "value": "props.premedication"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "Input",
      "parent": 76,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onOrderLabChange"
        },
        "value": {
          "type": "code",
          "value": "props.orderLab"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "Input",
      "parent": 78,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onOrderXrayChange"
        },
        "value": {
          "type": "code",
          "value": "props.orderXray"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "Input",
      "parent": 53,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedAppointment?.hn || props.selectedAppointment?.patient_hn || \"\""
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "Input",
      "parent": 60,
      "props": {
        "value": {
          "type": "code",
          "value": "props.selectedAppointment?.division_name"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.manualTime ? \"block\" : \"none\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 122,
      "name": "TimeFreeTextBox24",
      "parent": 121,
      "props": {
        "className": {
          "type": "value",
          "value": "time-custom-react-picker"
        },
        "maxHour": {
          "type": "code",
          "value": "props.maxHour"
        },
        "minHour": {
          "type": "code",
          "value": "props.minHour"
        },
        "onChange": {
          "type": "code",
          "value": "(value: any) => props.setEstimatedAt(value)"
        },
        "value": {
          "type": "code",
          "value": "props.estimatedAt"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "label",
      "parent": 86,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดการนัด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", display: props.useNoteTemplate ? \"none\" : \"\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", display: props.showOrderAppointment? \"block\": \"none\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "Form",
      "parent": 124,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 125,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"18% 82%\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 133,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 133,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 136,
      "name": "Table",
      "parent": 135,
      "props": {
        "data": {
          "type": "code",
          "value": "props.preOrderList || []"
        },
        "headers": {
          "type": "value",
          "value": "ID, Type, Details, Del, Edit"
        },
        "keys": {
          "type": "value",
          "value": "id, specific_type_name, order_detail, del, edit"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\"}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui vertical menu"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "label",
      "parent": 125,
      "props": {
        "children": {
          "type": "value",
          "value": "สั่งออเดอร์ล่วงหน้า"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "Button",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": "Imaging"
        },
        "className": {
          "type": "code",
          "value": "`item${props.activeOrder === \"IMAGING\" ? \" active\" : \"\"}`"
        },
        "disabled": {
          "type": "code",
          "value": "props.isEditable === undefined? false : !props.isEditable"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "IMAGING"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickOrder"
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\", textAlign: \"left\"}"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "Button",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": "Central Lab"
        },
        "className": {
          "type": "code",
          "value": "`item${props.activeOrder === \"CENTRAL_LAB\" ? \" active\" : \"\"}`"
        },
        "disabled": {
          "type": "code",
          "value": "props.isEditable === undefined? false : !props.isEditable"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "CENTRAL_LAB"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickOrder"
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\", textAlign: \"left\"}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "Button",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": "Treatment"
        },
        "className": {
          "type": "code",
          "value": "`item${props.activeOrder === \"TREATMENT\" ? \" active\" : \"\"}`"
        },
        "disabled": {
          "type": "code",
          "value": "props.isEditable === undefined? false : !props.isEditable"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "TREATMENT"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickOrder"
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\", textAlign: \"left\"}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "Button",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ป่วยยกเลิก"
        },
        "className": {
          "type": "value",
          "value": "ignore-prevent"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "code",
          "value": "props.isEditable === undefined? false : !props.isEditable"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelAppointmentByPatient"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hidePatientCancel ? \"none\": \"flex\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "Input",
      "parent": 62,
      "props": {
        "onFocus": {
          "type": "code",
          "value": "props.onDateFocus"
        },
        "value": {
          "type": "code",
          "value": "props.appointmentedDate"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "Button",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": "Blood Bank"
        },
        "className": {
          "type": "code",
          "value": "`item${props.activeOrder === \"BLOOD_BANK\" ? \" active\" : \"\"}`"
        },
        "disabled": {
          "type": "code",
          "value": "props.isEditable === undefined? false : !props.isEditable"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "BLOOD_BANK"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickOrder"
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\", textAlign: \"left\"}"
        }
      },
      "seq": 148,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardAppointmentDetailUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "manualTime": false
  },
  "width": 75
}

*********************************************************************************** */
