import React from "react";
import PropTypes from "prop-types";
import { Form, Label, Divider } from "semantic-ui-react";
import { RightLabel } from "../common";

const SubFormViewer = React.forwardRef((props, ref) => {
  const generateUI = () => {
    let arr = props.data
    if(!Array.isArray(props.data)){
      arr = [props.data]
    }
    return arr.map((item, index) => {
      return (
        <>
          {props.fieldList.map((field, subIndex) => {
            return (
              <Form.Group inline key={index + field + subIndex}>
                <Form.Field width={7}>
                  <RightLabel>
                    <Label size="large" style={styles.labelText}>
                      {props.fieldMap[field]}:
                    </Label>
                  </RightLabel>
                </Form.Field>
                <Form.Field width={9}>
                  <Label className="fluidLabel" size="large">
                    {item[field] ? item[field] : "-"}
                  </Label>
                </Form.Field>
              </Form.Group>
            );
          })}
          <Divider />
        </>
      );
    });
  };

  return <Form>{generateUI()}</Form>;
});

const styles = {
  labelText: {
    backgroundColor: "#F3F3F3",
    color: "black",
    lineHeight: "1.6em"
  }
};

SubFormViewer.defaultProps = {
  data: {},
  fieldList: [],
  fieldMap: {}
};

SubFormViewer.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fieldList: PropTypes.array,
  fieldMap: PropTypes.object
};

export default React.memo(SubFormViewer);
