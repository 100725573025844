import {
  useEffect,
  useState,
  useMemo,
  useCallback,
  CSSProperties,
} from "react";
import {
  Button,
  Checkbox,
  Icon,
  Label,
  Modal,
  Segment,
} from "semantic-ui-react";

// UX
import CardBillingDocumentUX from "./CardBillingDocumentUX";
import CardRefundUX from "./CardRefundUX";
import CardFaxClaimUX from "./CardFaxClaimUX";
import CardPaying from "./CardPaying";
import ModLinkPackage from "./ModLinkPackage";

// Common
import {
  ErrorMessage,
  ModAuthen,
  ModConfirm,
  ModInfo,
} from "react-lib/apps/common";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import EditorColumn from "../../../appcon/common/EditorColumn";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Const
const MessageStyle = {
  padding: "10px",
  fontSize: "22px",
  fontWeight: "bold",
  color: "#FFFFFF",
  backgroundColor: "#E4A210",
  borderRadius: "5px",
} as CSSProperties;

const AlignRightStyle = { textAlign: "right" } as CSSProperties;

export const CARD_MEDICAL_FEE = "CardMedicalFee";

const CardMedicalFee = (props: any) => {
  const [orderBy, setOrderBy] = useState<string>("Item");
  const [isPendingSomeOrder, setIsPendingSomeOrder] = useState<boolean>(false);
  const [invoiceItems, setInvoiceItems] = useState<any[]>([]);
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [priceRefund, setPriceRefund] = useState<any>(0);
  const [receiptRefund, setReceiptRefund] = useState<string>("");
  const [tracking, setTracking] = useState<boolean>(false);

  const [price, setPrice] = useState<any>({
    price: 0,
    discount: 0,
    payable: 0,
    send_claim: 0,
    absorb: 0,
    patient_claim: 0,
    non_claimable: 0,
  });

  // mod
  const [openInvoice, setOpenInvoice] = useState<boolean>(false);
  const [openRefund, setOpenRefund] = useState<boolean>(false);
  const [openTransfer, setOpenTransfer] = useState<boolean>(false);
  const [openModAuthen, setOpenModAuthen] = useState<boolean>(false);
  const [openModFaxClaim, setOpenModFaxClaim] = useState<boolean>(false);
  const [modLinkPackage, setModLinkPackage] = useState<any>(null);

  // Effect Callback
  const getInvoiceItem = useCallback((params: any) => {
    props.onEvent({ message: "GetInvoiceItemByMode", params });

    props.onEvent({ message: "GetInvoiceItemByOrder", params });

    props.onEvent({ message: "GetInvoiceItemByItem", params });
  }, []);

  useEffect(() => {
    if (props.viewMode || props.openPaying) {
      return;
    }

    getInvoiceItem({ card: CARD_MEDICAL_FEE });

    props.onEvent({
      message: "GetPatientDoctorOrder",
      params: {
        card: CARD_MEDICAL_FEE,
        patientId: props.selectedEncounter?.patient_id,
      },
    });
    props.onEvent({
      message: "GetCreditNoteItem",
      params: {
        card: CARD_MEDICAL_FEE,
        action: "getCreditNoteItem",
        patientId: props.selectedEncounter?.patient_id,
      },
    });
  }, []);

  useEffect(() => {
    // หากส่ง receiptId จะ get by receipt id
    if (!props.receiptId) {
      return;
    }

    getInvoiceItem({
      card: CARD_MEDICAL_FEE,
      receiptId: props.receiptId,
    });
  }, [props.receiptId]);

  useEffect(() => {
    if (props.selectedEncounter?.patient) {
      props.onEvent({
        message: "GetBillPendingDetail",
        params: {},
      });
    }
    return () => {
      props.setProp("billPendingDetail", null);
    };
  }, [props.selectedEncounter?.patient]);

  useEffect(() => {
    if (
      props.refundOrders?.tracking?.task_id !== undefined &&
      props.refundOrders?.tracking?.status !== "CANCELED"
    ) {
      setTracking(true);
    }
  }, [props.refundOrders?.tracking]);

  useEffect(() => {
    let invoices: any[] = [];
    if (orderBy === "Mode") {
      invoices = props.invoiceItemByModes || [];
    } else if (orderBy === "Order") {
      invoices = props.invoiceItemByOrders || [];
      setCheckedIds(invoices.flatMap((item) => item.id_list));
    } else if (orderBy === "Item") {
      invoices = props.invoiceItemByItems || [];
      setCheckedIds(invoices.map((item) => item.id));
    }
    setInvoiceItems(invoices);

    if (props.patientOrders?.length > 0) {
      let isPending = props.patientOrders?.some(
        (item: any) =>
          item.is_pending &&
          (item.name.toLowerCase().includes("supply") ||
            item.name.toLowerCase().includes("drug"))
      );
      setIsPendingSomeOrder(isPending);
    } else {
      setIsPendingSomeOrder(false);
    }
  }, [
    orderBy,
    props.invoiceItemByOrders,
    props.patientOrders,
    props.invoiceItemByItems,
    props.invoiceItemByModes,
  ]);

  // open faxClaim
  useEffect(() => {
    if (props.faxClaimItemList?.items.length > 0) {
      setOpenModFaxClaim(true);
    }
  }, [props.faxClaimItemList]);

  const invoiceData = useMemo(() => {
    let invoices = invoiceItems;

    if (["Order", "Item"].includes(orderBy)) {
      invoices = invoices.filter((item: any) => checkedIds.includes(item.id));
    }

    let idList = invoices.flatMap((item) =>
      item.id_list ? item.id_list : [item.id]
    );

    return {
      items: invoices,
      ids: idList,
      orderBy,
    };
  }, [orderBy, invoiceItems, checkedIds]);

  useEffect(() => {
    if (props.invoiceHistoryItems) {
      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "BillPayment",
          invoiceData: {
            items: props.invoiceHistoryItems,
            ids: props.invoiceHistoryItems.flatMap((item: any) =>
              item.id_list ? item.id_list : [item.id]
            ),
            orderBy: props.orderBy,
          },
          invoiceItemByItems: props.invoiceItemByItems,
          card: CARD_MEDICAL_FEE,
        },
      });
    }
  }, [props.historyInvoiceData]);

  useEffect(() => {
    let price: number = 0;
    let discount: number = 0;
    let payable: number = 0;
    let send_claim: number = 0;
    let absorb: number = 0;
    let patient_claim: number = 0;
    let non_claimable: number = 0;
    let unpaid: number = 0;

    let items: any[] = invoiceData.items || [];

    items.forEach((item: any) => {
      if (
        orderBy === "Order" &&
        item.status__name === "BILLED" &&
        (!item.payment_ready || !item.chk)
      ) {
        const getParseFloat = (key: string) => {
          return parseFloat(item?.[`${key}__sum`]) || 0;
        };

        price += getParseFloat("price");
        discount += getParseFloat("discount");
        payable += getParseFloat("payable");
        send_claim += getParseFloat("send_claim");
        absorb += getParseFloat("absorb");
        patient_claim += getParseFloat("patient_claim");
        non_claimable += getParseFloat("non_claimable");
      } else if (
        orderBy === "Item" &&
        item.status__name === "BILLED" &&
        !item.payment_ready
      ) {
      } else {
        const getParseFloat = (key: string) => {
          return (
            parseFloat(
              item?.[invoiceData.orderBy === "Item" ? key : `${key}__sum`]
            ) || 0
          );
        };

        price += getParseFloat("price");
        discount += getParseFloat("discount");
        payable += getParseFloat("pay");
        send_claim += getParseFloat("send_claim");
        absorb += getParseFloat("absorb");
        patient_claim += getParseFloat("patient_claim");
        non_claimable += getParseFloat("non_claimable");
        unpaid += item.is_editing
          ? getParseFloat("payable") -
            getParseFloat("paid") -
            getParseFloat("pay")
          : item.status_name === "PARTIAL_PAID"
          ? getParseFloat("payable") - getParseFloat("paid")
          : 0;
      }
    });
    setPrice({
      price: price,
      discount: discount,
      payable: payable,
      send_claim: send_claim,
      absorb: absorb,
      patient_claim: patient_claim,
      non_claimable: non_claimable,
      unpaid,
    });
  }, [invoiceItems, invoiceData, orderBy]);

  useEffect(() => {
    let price: number = 0;

    props.refundOrders?.data?.forEach((item: any) => {
      price += parseFloat(item?.price);
    });

    setPriceRefund(formatComma(price));
  }, [props.refundOrders?.data]);

  useEffect(() => {
    if (props.selectedEncounter?.patient) {
      props.onEvent({
        message: "HandleRefreshTaskTracking",
        params: {},
      });
    }
  }, [props.selectedEncounter?.patient]);

  const formatComma = (val: any) =>
    !isNaN(parseFloat(val))
      ? parseFloat(val)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(?:\d{3})+(?!\d))/g, ",")
      : val;

  const handleChecked = (checked: boolean | undefined, item: any) => {
    const ids = item.id_list || [item.id];

    if (checkedIds.find((id) => ids.includes(id))) {
      setCheckedIds(checkedIds.filter((id) => !ids.includes(id)));
    } else {
      setCheckedIds([...checkedIds, ...ids]);
    }
  };

  const handleChangeEdit = useCallback(
    (data: { item: any; value: string; index: number; key: string }) => {
      let value = data.value;
      if (Number(value) > Number(data.item.payable)) {
        value = data.item.payable;
      }

      const list = invoiceItems.map((item, index) =>
        index === data.index
          ? { ...item, [data.key]: Number(value).toFixed(2), is_editing: true }
          : item
      );

      setInvoiceItems([...list]);
    },
    [invoiceItems]
  );

  const handleLinkPackage = useCallback(
    (data: any) => (e: any) => {
      e.stopPropagation();

      setModLinkPackage(data);
    },
    []
  );

  const memoInvoiceItems = useMemo(() => {
    return invoiceItems.map((item, index) => ({
      ...item,
      created: formatDatetime(item.created),
      chk: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Checkbox
            checked={checkedIds.includes(item.id)}
            onChange={(e, v) => handleChecked(v.checked, item)}
          />
        </div>
      ),
      price__sum: (
        <div style={AlignRightStyle}>{formatComma(item.price__sum)}</div>
      ),
      send_claim__sum: (
        <div style={AlignRightStyle}>{formatComma(item.send_claim__sum)}</div>
      ),
      absorb__sum: (
        <div style={AlignRightStyle}>{formatComma(item.absorb__sum)}</div>
      ),
      patient_claim__sum: (
        <div style={AlignRightStyle}>
          {formatComma(item.patient_claim__sum)}
        </div>
      ),
      non_claimable__sum: (
        <div style={AlignRightStyle}>
          {formatComma(item.non_claimable__sum)}
        </div>
      ),
      discount__sum: (
        <div style={AlignRightStyle}>{formatComma(item.discount__sum)}</div>
      ),
      payable__sum: (
        <div style={AlignRightStyle}>{formatComma(item.payable__sum)}</div>
      ),
      //By Item
      quantity: <div style={AlignRightStyle}>{formatComma(item.quantity)}</div>,
      unitprice: (
        <div style={AlignRightStyle}>{formatComma(item.unitprice)}</div>
      ),
      price: <div style={AlignRightStyle}>{formatComma(item.price)}</div>,
      send_claim: (
        <div style={AlignRightStyle}>{formatComma(item.send_claim)}</div>
      ),
      absorb: <div style={AlignRightStyle}>{formatComma(item.absorb)}</div>,
      patient_claim: (
        <div style={AlignRightStyle}>{formatComma(item.patient_claim)}</div>
      ),
      non_claimable: (
        <div style={AlignRightStyle}>{formatComma(item.non_claimable)}</div>
      ),
      discount: <div style={AlignRightStyle}>{formatComma(item.discount)}</div>,
      payable: <div style={AlignRightStyle}>{formatComma(item.payable)}</div>,
      pay: (
        <EditorColumn
          value={item.pay}
          disabled={props.viewMode}
          valueOnFocus={true}
          selectOnFocus={true}
          // style
          backgroundColor={props.viewMode ? "" : "rgb(255, 255, 204)"}
          onDataChanged={(value: string) =>
            handleChangeEdit({ value, index, key: "pay", item })
          }
        />
      ),
      unpaid: (
        <div style={AlignRightStyle}>
          {item.is_editing
            ? formatComma(item.payable - item.paid - item.pay)
            : item.status_name === "PARTIAL_PAID"
            ? formatComma(item.payable - item.paid)
            : "0.00"}
        </div>
      ),
      drg_discount_amount: (
        <div style={AlignRightStyle}>
          {formatComma(item.drg_discount_amount)}
        </div>
      ),
      product: (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div>{item.product}</div>
          {item.package_status !== "NONE" && (
            <>
              <div style={{ flex: 1 }}></div>
              <Label
                color={
                  item.package_status === "AVAILABLE"
                    ? undefined
                    : item.package_status === "APPLIED"
                    ? "green"
                    : undefined
                }
                style={{
                  fontSize: "0.55rem",
                  marginTop: "-2px",
                  cursor: "pointer",
                }}
                onClick={handleLinkPackage(item)}
              >
                <Icon name="linkify" style={{ marginRight: "5px" }} />
                PK
              </Label>
            </>
          )}
        </div>
      ),
    }));
  }, [invoiceItems, checkedIds, orderBy]);

  const memoRefundOrders = useMemo(() => {
    return props.refundOrders?.data?.map((item: any) => ({
      ...item,
      pricing_quantity: (
        <div style={AlignRightStyle}>{formatComma(item.pricing_quantity)}</div>
      ),
      unitprice: (
        <div style={AlignRightStyle}>{formatComma(item.unitprice)}</div>
      ),
      payable: <div style={AlignRightStyle}>{formatComma(item.payable)}</div>,
      quantity: <div style={AlignRightStyle}>{formatComma(item.quantity)}</div>,
      price: <div style={AlignRightStyle}>{formatComma(item.price)}</div>,
    }));
  }, [props.refundOrders?.data]);

  const citizenDetail = useMemo(() => {
    const patient = props.patientInfo;
    let text = "";
    let patientId = "";
    if (patient?.citizen_type == "T") {
      text = "หมายเลขบัตรประชาชน " + patient?.citizen_no;
      patientId = patient?.citizen_no;
    } else if (patient?.citizen_type == "F") {
      text = "หมายเลขพาสปอร์ต " + patient?.passport_no;
      text += " [เลขกรมบัญชีกลาง " + patient?.reimburse_foreign_code + "]";
      patientId = patient?.reimburse_foreign_code;
    } else if (patient?.citizen_type == "A") {
      text = "ใบต่างด้าว " + patient?.alien_no;
      text += " [เลขกรมบัญชีกลาง " + patient?.reimburse_foreign_code + "]";
      patientId = patient?.reimburse_foreign_code;
    }

    return {
      text,
      patientId,
    };
  }, [props.patientInfo]);

  const patientOptionData = useMemo(() => {
    const patient = props.patientInfo;
    const _items = [];
    let value = "";

    if (patient?.real_name_th !== "") {
      _items.push({
        key: "TH",
        value: "TH",
        text: patient?.real_name_th,
      });
    } else if (patient?.real_name_en !== "") {
      _items.push({
        key: "EN",
        value: "EN",
        text: patient?.real_name_en,
      });
    }
    if (patient?.real_name === patient?.real_name_en) {
      value = "EN";
    } else {
      value = "TH";
    }

    return {
      options: _items,
      value,
    };
  }, [props.patientInfo]);

  const hasFeeItems = useMemo(() => {
    return [
      props.billPendingDetail?.has_opd_fee && "มีค่าใช้จ่าย OPD",
      props.billPendingDetail?.has_ipd_fee && "มีค่าใช้จ่าย IPD",
      props.billPendingDetail?.has_refund && "มีรายการรอคืนเงิน",
    ].filter(Boolean);
  }, [props.billPendingDetail]);

  const handleChangeOrderBy = (_event: any, data: any) => {
    setOrderBy(data.name);
  };

  const onSelectALL = () => {
    var id: any[] = [];
    invoiceItems.map((item: any) => {
      id.push(item.id);
    });
    setCheckedIds(id);
  };

  const onSelectNone = () => {
    setCheckedIds([]);
  };

  const handleGetReceipt = () => {
    let nameReceipt: any = [];
    let receipt = "";
    props.refundOrders?.data.forEach((item: any) => {
      if (!nameReceipt.includes(item.receipts)) {
        nameReceipt.push(item.receipts);
      }
    });

    nameReceipt.forEach((item: any) => {
      receipt = `${receipt}` + `, ${item}`;
    });

    setReceiptRefund(receipt);
  };

  const handleCloseModalTracking = () => {
    if (
      props.refundOrders?.tracking?.status === "COMPLETED" ||
      props.refundOrders?.tracking?.status === "CANCELED"
    ) {
      props.setProp("refundOrders.tracking", null);
      setTracking(false);
    } else {
      // props.onEvent({
      //   message: "GetCreditNoteItem",
      //   params: { action: "cancelCreditNote" },
      // });
    }
  };

  const handlePrintReceipt = () => {
    // หากส่ง receiptId จะ get by receipt id
    props.onEvent({
      message: "HandlePrintMedicalFeeDetail",
      params: { receipt: props.receiptId },
    });
  };

  const handleCancelReportPrint = () => {
    props.onEvent({
      message: "HandleReportPrintTrackingResult",
      params: {
        action: "CANCEL",
        taskId: props.billReportPrint?.task_id,
      },
    });
  };

  const handleCloseModCancel = () => {
    props.setProp("billReportPrint", {});
  };

  const handlePaying = () => {
    props.setProp(`errorMessage.${CARD_MEDICAL_FEE}_KTB`, null);
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "BillPayment",
        invoiceData,
        card: CARD_MEDICAL_FEE,
        allowKTB: true,
      },
    });
  };

  const editOrderFaxClaim = (index: number, status: string) => {
    let newData: any = [];
    let faxClaimData = props.faxClaimItemList?.items;

    if (status === "up" && faxClaimData.length > 1) {
      let cloneData = faxClaimData[index];
      let cloneDataUp = faxClaimData[index - 1];
      faxClaimData.forEach((item: any, number: any) => {
        if (index === number) {
          newData.push({ ...cloneDataUp, priority: number + 1 });
        } else if (index - 1 === number) {
          newData.push({ ...cloneData, priority: number + 1 });
        } else {
          newData.push(item);
        }
      });

      props.setProp("faxClaimItemList.items", newData);
    } else if (status === "down" && faxClaimData.length > 1) {
      let cloneData = faxClaimData[index];
      let cloneDataUp = faxClaimData[index + 1];
      faxClaimData.forEach((item: any, number: any) => {
        if (index === number) {
          newData.push({ ...cloneDataUp, priority: number + 1 });
        } else if (index + 1 === number) {
          newData.push({ ...cloneData, priority: number + 1 });
        } else {
          newData.push(item);
        }
      });

      props.setProp("faxClaimItemList.items", newData);
    }
  };

  const handleChangeFaxClaim = useCallback(
    (data: { item: any; value: string; index: number; key: string }) => {
      let value = data.value;
      if (Number(value) > Number(data.item.payable)) {
        value = data.item.payable;
      }

      const list = props.faxClaimItemList?.items.map((item: any, index: any) =>
        index === data.index
          ? { ...item, [data.key]: Number(value).toFixed(2) }
          : item
      );

      // calculator
      let totalPrice = 0;
      let nonClaimable = 0;
      props.faxClaimItemList?.items.map((item: any, index: any) => {
        if (index === data.index) {
          totalPrice = totalPrice + parseFloat(value);
        } else {
          totalPrice = totalPrice + parseFloat(item?.total_send_claim_price);
        }
      });

      // by_mode faxClaimItemList?.total is string
      nonClaimable =
        parseFloat(props.faxClaimItemList?.total?.price.replace(/\,/g, "")) -
        parseFloat(
          props.faxClaimItemList?.total?.absorbable.replace(/\,/g, "")
        ) -
        parseFloat(
          props.faxClaimItemList?.total?.patient_claim.replace(/\,/g, "")
        ) -
        parseFloat(props.faxClaimItemList?.total?.discount.replace(/\,/g, "")) -
        totalPrice;

      props.setProp("faxClaimItemList", {
        total: {
          ...props.faxClaimItemList?.total,
          payable: nonClaimable.toFixed(2),
          non_claimable: nonClaimable.toFixed(2),
          send_claim: totalPrice.toFixed(2),
        },
        items: [...list],
      });
    },
    [props.faxClaimItemList]
  );

  const faxClaimItems = useMemo(() => {
    return props.faxClaimItemList?.items.filter((item: any) => item.send_claim === true).map((item: any, index: number) => ({
      ...item,
      mode_name: (
        <div>{`[${item?.bill_mode_code}] ${item?.bill_mode_name}`}</div>
      ),
      total_send_claim_price: (
        <EditorColumn
          value={item.total_send_claim_price}
          valueOnFocus={true}
          selectOnFocus={true}
          // style
          backgroundColor={"rgb(255, 255, 204)"}
          onDataChanged={(value: string) => {
            // set total_send_claim_price
            handleChangeFaxClaim({
              value,
              index,
              key: "total_send_claim_price",
              item,
            });
          }}
        />
      ),
      order: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {index === 0 ? (
            <Icon
              name="caret down"
              size="big"
              color="grey"
              onClick={() => editOrderFaxClaim(index, "down")}
            ></Icon>
          ) : index + 1 === props.faxClaimItemList?.items.length ? (
            <Icon
              name="caret up"
              size="big"
              color="grey"
              onClick={() => editOrderFaxClaim(index, "up")}
            ></Icon>
          ) : (
            <div>
              <div>
                <Icon
                  name="caret up"
                  size="big"
                  color="grey"
                  onClick={() => editOrderFaxClaim(index, "up")}
                ></Icon>
              </div>
              <div>
                <Icon
                  name="caret down"
                  size="big"
                  color="grey"
                  onClick={() => editOrderFaxClaim(index, "down")}
                ></Icon>
              </div>
            </div>
          )}
        </div>
      ),
    }));
  }, [props.faxClaimItemList?.items]);

  const handleGetFaxClaim = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => ({
    onClick: async () => {
      // check onClick Table
      if (column.Header !== "..." && column.Header !== "ผู้ป่วยชำระ") {
        if (orderBy === "Item") {
          props.onEvent({
            message: "GetFexClaimDetail",
            params: { dataType: "by_item", invoiceItem: rowInfo?.original?.id },
          });
        } else if (orderBy === "Mode") {
          props.onEvent({
            message: "GetFexClaimDetail",
            params: {
              dataType: "by_mode",
              hn: props.selectedEncounter.hn,
              billMode: rowInfo?.original?.bill_mode__code,
            },
          });
        }
      }
    },
  });

  const handleCheckedTypeSave = () => {
    if (orderBy === "Item") {
      props.onEvent({
        message: "HandleUpdateFexClaim",
        params: {
          dataType: "by_item",
          invoiceItem: props.faxClaimItemList?.total?.id,
          card: "FaxClaim_SAVE",
        },
      });
    } else if (orderBy === "Mode") {
      props.onEvent({
        message: "HandleUpdateFexClaim",
        params: {
          dataType: "by_mode",
          invoiceItem: props.faxClaimItemList?.total?.id,
          card: "FaxClaim_SAVE",
        },
      });
    }
  };

  const handleSaveModFaxClaim = () => {
    if (props.showAuthorize) {
      setOpenModAuthen(true);
    } else {
      handleCheckedTypeSave();
    }
  };

  const handleCloseModFaxClaim = () => {
    props.setProp("faxClaimItemList", { total: {}, items: [] });
    setOpenModFaxClaim(false);
  };

  const handleCloseModLinkPackage = () => {
    setModLinkPackage(null);
  };

  console.log("CardMedicalFee", props);
  return (
    <div
      style={{
        width: props.viewMode ? "" : "calc(100vw - 28rem)",
        overflowY: "auto",
        ...(props.openPaying
          ? {}
          : { height: "100%", paddingBottom: "3rem", paddingTop: "5px" }),
      }}
    >
      {props.openPaying ? (
        <>
          <CardPaying
            onEvent={props.onEvent}
            setProp={props.setProp}
            // seq
            BillPaymentSequence={props.BillPaymentSequence}
            patientInfo={props.patientInfo}
            citizenDetail={citizenDetail}
            defaultAmount={{
              price: 0,
              reimbursable: 0,
              cashonly: 0,
              discount: 0,
              payable: 0,
            }}
            patientOptionData={patientOptionData}
            invoiceData={{
              items: props.invoiceHistoryItems,
              ids: props.invoiceHistoryItems.flatMap((item: any) =>
                !!item.id_list?.length ? item.id_list : [item.id]
              ),
              orderBy: props.orderBy,
            }}
            simpleReceipt={props.simpleReceipt}
            // callback
            onSuccess={props.onSuccess}
            invoiceItemByItems={props.invoiceItemByItems}
            // CommonInterface
            errorMessage={props.errorMessage}
            // config
            invoiceId={props.invoiceId}
            historyInvoiceData={props.historyInvoiceData}
            openPaying={props.openPaying}
          />

          {!!props.errorMessage?.[CARD_MEDICAL_FEE] && (
            <ModInfo
              open={true}
              titleColor={"red"}
              onApprove={() =>
                props.setProp(`errorMessage.${CARD_MEDICAL_FEE}`, null)
              }
            >
              <ErrorMessage error={props.errorMessage?.[CARD_MEDICAL_FEE]} />
            </ModInfo>
          )}
        </>
      ) : (
        <>
          <SnackMessage
            onEvent={props.onEvent}
            onClose={() => {
              props.setProp(`BillPaymentSequence.payErrorMessage`, null);
            }}
            error={props.BillPaymentSequence?.payErrorMessage}
            success={null}
          />

          {!!props.taskTrackingList?.length && (
            // @ts-ignore
            <Segment
              color="red"
              inverted={true}
              style={{ borderRadius: "5px", margin: "0 10px 5px" }}
            >
              ระบบกำลังคำนวณค่าใช้จ่าย กรุณางดทำรายการดังต่อไปนี้
              <div style={{ paddingLeft: "0.5rem", marginTop: "-1.5rem" }}>
                <br /> 1. รับชำระเงิน / แจ้งชำระเงิน
                <br /> 2. โอนค่าใช้จ่าย
                <br /> 3. เพิ่ม / แก้ไข สิทธิและส่วนลด
                <br /> 4. เพิ่ม / แก้ไข วงเงิน
                <br /> 5. คืนสินค้าหรือบริการ
                <br /> 6. ระงับสิทธิ / ยกเลิกระงับสิทธิ
              </div>
            </Segment>
          )}

          {isPendingSomeOrder && (
            <div style={{ margin: "0 10px 5px" }}>
              <div style={MessageStyle}>มีคำสั่งแพทย์ที่ยังไม่พร้อมรับชำระ</div>
            </div>
          )}

          {!!hasFeeItems.length ? (
            <div style={{ margin: "0 10px 5px" }}>
              <div style={MessageStyle}>
                {hasFeeItems.map((value) => `${value}`).join(" , ")}
              </div>
            </div>
          ) : (
            <>
              {!!props.billPendingDetail && (
                <div style={{ margin: "0 10px 5px" }}>
                  <div
                    style={{
                      ...MessageStyle,
                      backgroundColor: "#21ba45",
                    }}
                  >
                    ไม่มีค่าใช้จ่าย
                  </div>
                </div>
              )}
            </>
          )}

          {Number(props.billPendingDetail?.total_billed_price) > 0 && (
            <div style={{ margin: "0 10px 5px" }}>
              <div style={{ ...MessageStyle, backgroundColor: "#db2828" }}>
                ผู้ป่วยมีรายการยังไม่ชำระ{" "}
                {formatComma(props.billPendingDetail?.total_billed_price)} บาท
              </div>
            </div>
          )}

          {Number(props.billPendingDetail?.total_outstanding_balance_price) >
            0 && (
            <div style={{ margin: "0 10px 5px" }}>
              <div style={{ ...MessageStyle, backgroundColor: "#db2828" }}>
                ผู้ป่วยมีรายการค้างชำระบางส่วน{" "}
                {formatComma(
                  props.billPendingDetail?.total_outstanding_balance_price
                )}{" "}
                บาท
              </div>
            </div>
          )}

          {/* Medical Fee */}
          <CardBillingDocumentUX
            title="MEDICAL FEE"
            titleDescription="ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"
            orderBy={orderBy}
            invoiceItems={memoInvoiceItems}
            patientValue={patientOptionData.value}
            // options
            patientOptions={patientOptionData.options}
            displaySelect={
              !props.viewMode
                ? ["Order", "Item"].includes(orderBy)
                  ? { display: "flex", justifyContent: "center" }
                  : { display: "none" }
                : { display: "none" }
            }
            // config
            // แสดงตารางและ button by
            viewMode={props.viewMode}
            // Hide แจ้งชำระเงิน และ รับชำระเงิน button
            hidebp={props.hidebp}
            hidebpInvoice={props.hidebpInvoice}
            // callback
            billPayment={(e: any) => {
              props.onEvent({
                message: "RunSequence",
                params: {
                  sequence: "BillPayment",
                  invoiceData,
                  invoiceItemByItems: props.invoiceItemByItems,
                  card: CARD_MEDICAL_FEE,
                },
              });
            }}
            // tableStyle={{ zoom: "87%", fontSize: "16px" }}
            onChangeOrderBy={handleChangeOrderBy}
            price_total={formatComma(price.price)}
            discountTotal={formatComma(price.discount)}
            payableTotal={formatComma(price.payable)}
            unpaidTotal={formatComma(price.unpaid)}
            sendClaimTotal={formatComma(price.send_claim)}
            absorbTotal={formatComma(price.absorb)}
            patientClaimTotal={formatComma(price.patient_claim)}
            nonClaimableTotal={formatComma(price.non_claimable)}
            onClickAll={onSelectALL}
            onClickNone={onSelectNone}
            billPaymentInvoice={() => setOpenInvoice(true)}
            billPaymentTransfer={() => setOpenTransfer(true)}
            onPrintReceipt={handlePrintReceipt}
            onSelectedFaxClaim={handleGetFaxClaim}
            // Element
            receiptCode={
              <>
                เลขที่ใบเสร็จ :{" "}
                <strong style={{ color: "rgba(0,0,0,0.87)" }}>
                  {typeof props.bilReceiptCodeDetail?.code === "undefined"
                    ? "-"
                    : props.bilReceiptCodeDetail?.code ||
                      "ยังไม่ได้ตั้งเลขที่ใบเสร็จ"}
                </strong>
              </>
            }
          />

          {/* Refund */}
          {!!props.refundOrders?.data?.length && (
            <div style={{ marginTop: "50px" }}>
              <CardRefundUX
                refundOrders={memoRefundOrders}
                priceRefund={priceRefund}
                onRefund={() => {
                  handleGetReceipt();
                  setOpenRefund(true);
                }}
              />
            </div>
          )}

          <ModConfirm
            titleColor="yellow"
            openModal={openRefund}
            titleName={<label>-</label>}
            content={
              <label>
                {`จะต้องรับใบเสร็จเลขที่ ${receiptRefund} จากผู้ป่วย ยืนยันการคืนเงินผู้ป่วยเป็นจำนวน ${priceRefund} หรือไม่`}
              </label>
            }
            onApprove={(e: any) => {
              props.onEvent({
                message: "GetCreditNoteItem",
                params: {
                  card: CARD_MEDICAL_FEE,
                  action: "getCreditNoteList",
                },
              });
              setOpenRefund(false);
            }}
            onDeny={() => setOpenRefund(false)}
            size={"mini"}
            // Element
            receiptCode={
              <>
                เลขที่ใบเสร็จ :{" "}
                <strong style={{ color: "rgba(0,0,0,0.87)" }}>
                  {typeof props.bilReceiptCodeDetail?.code === "undefined"
                    ? "-"
                    : props.bilReceiptCodeDetail?.code ||
                      "ยังไม่ได้ตั้งเลขที่ใบเสร็จ"}
                </strong>
              </>
            }
          />

          <ModConfirm
            titleColor="blue"
            openModal={openTransfer}
            titleName={<label>กรุณายืนยัน</label>}
            content={<label>ต้องการโอนค่าใช้จ่ายใช่หรือไม่</label>}
            onApprove={(e: any) => {
              props.onEvent({
                message: "HandleTransfer",
                params: {
                  invoiceData,
                },
              });
              setOpenTransfer(false);
            }}
            onDeny={() => setOpenTransfer(false)}
            size={"mini"}
          />

          {/* tracking */}
          <Modal open={tracking} size="mini">
            <div>
              <div
                style={{
                  backgroundColor:
                    props.refundOrders?.tracking?.status === "COMPLETED"
                      ? "green"
                      : props.refundOrders?.tracking?.status === "CANCELED"
                      ? "red"
                      : "#00B5AD",
                  padding: "15px",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                {props.refundOrders?.tracking?.status === "COMPLETED"
                  ? "เตรียมเอกสารเสร็จสิ้น"
                  : props.refundOrders?.tracking?.status === "CANCELED"
                  ? "ยกเลิก"
                  : "กำลังเตรียมเอกสาร"}
              </div>
              <div style={{ padding: "15px" }}>
                {props.refundOrders?.tracking?.status === "COMPLETED" ? (
                  <div>
                    <div>เตรียมเอกสารเสร็จสิ้น</div>
                  </div>
                ) : props.refundOrders?.tracking?.status === "CANCELED" ? (
                  <div>
                    <div>
                      {
                        props.refundOrders?.tracking
                          ?.current_progress_description
                      }
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>กรุณารอสักครู่</div>
                    <div style={{ padding: "10px 0px" }}>
                      For IT: task_id = {props.refundOrders?.tracking?.task_id}
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    basic
                    color={
                      props.refundOrders?.tracking?.status === "COMPLETED"
                        ? "green"
                        : "red"
                    }
                    size="tiny"
                    onClick={() => handleCloseModalTracking()}
                  >
                    {props.refundOrders?.tracking?.status === "COMPLETED" ||
                    props.refundOrders?.tracking?.status === "CANCELED"
                      ? "ตกลง"
                      : "ยกเลิก"}
                  </Button>
                </div>
              </div>
            </div>
          </Modal>

          <ModConfirm
            titleColor="blue"
            openModal={openInvoice}
            titleName={<label>กรุณายืนยัน</label>}
            content={
              <label>
                ต้องการรวบค่าใช้จ่ายเพื่อออกเอกสารใบแจ้งการชำระค่ารักษาพยาบาลหรือไม่
              </label>
            }
            onApprove={(e: any) => {
              props.onEvent({
                message: "RunSequence",
                params: {
                  sequence: "BillPayment",
                  action: "invoice",
                  idList: invoiceData.ids,
                  goToMenu: "เอกสารทั้งหมด",
                  card: CARD_MEDICAL_FEE,
                },
              });
              setOpenInvoice(false);
            }}
            onDeny={() => setOpenInvoice(false)}
            size={"mini"}
          />
          <Modal
            closeOnDimmerClick={true}
            style={{ maxHeight: "80%", width: "80%", overflow: "auto" }}
            open={!!props?.BillPaymentSequence?.openCardPaying}
            onClose={() => {
              props.onEvent({
                message: "RunSequence",
                params: { sequence: "BillPayment", action: "close" },
              });
            }}
          >
            <CardPaying
              onEvent={props.onEvent}
              setProp={props.setProp}
              // seq
              BillPaymentSequence={props.BillPaymentSequence}
              patientInfo={props.patientInfo}
              citizenDetail={citizenDetail}
              patientOptionData={patientOptionData}
              invoiceData={invoiceData}
              invoiceItemByItems={props.invoiceItemByItems}
              // config
            />
          </Modal>

          {!!props.errorMessage?.[CARD_MEDICAL_FEE] && (
            <ModInfo
              open={true}
              titleColor={"red"}
              onApprove={() =>
                props.setProp(`errorMessage.${CARD_MEDICAL_FEE}`, null)
              }
            >
              <ErrorMessage error={props.errorMessage?.[CARD_MEDICAL_FEE]} />
            </ModInfo>
          )}

          <ModInfo
            open={!!props.billReportPrint?.open}
            titleColor={"black"}
            titleName={"กำลังเตรียมเอกสาร"}
            btnText="ยกเลิก"
            buttonColor="red"
            style={{ width: "27.5%" }}
            onApprove={handleCancelReportPrint}
            // onClose={handleCloseModInfo}
          >
            <div
              style={{
                padding: "0.5rem 0",
                lineHeight: 1.75,
                textAlign: "left",
              }}
            >
              <div>
                กรุณารอสักครู่
                <br />
                {!!props.billReportPrint?.status && (
                  <>
                    Status: {props.billReportPrint?.status}{" "}
                    {props.billReportPrint?.progress}%
                    <br />
                  </>
                )}
                For IT: task_id = {props.billReportPrint?.task_id}
              </div>
            </div>
          </ModInfo>

          <ModInfo
            open={!!props.billReportPrint?.error}
            titleColor={"red"}
            onApprove={handleCloseModCancel}
            onClose={handleCloseModCancel}
          >
            <div
              style={{
                padding: "0.5rem 0",
                lineHeight: 1.75,
                textAlign: "left",
              }}
            >
              {props.billReportPrint?.error}
            </div>
          </ModInfo>

          <ModInfo
            open={!!props.errorMessage?.[`${CARD_MEDICAL_FEE}_KTB`]}
            titleColor={"yellow"}
            onApprove={handlePaying}
            onClose={handlePaying}
          >
            <div
              style={{
                padding: "0.5rem 0",
                lineHeight: 1.75,
                textAlign: "left",
              }}
            >
              {props.errorMessage?.[`${CARD_MEDICAL_FEE}_KTB`]}
            </div>
          </ModInfo>

          {/* flxclaim */}
          <Modal
            style={{ margin: "20px" }}
            open={openModFaxClaim}
            onClose={handleCloseModFaxClaim}
            size={"large"}
          >
            <CardFaxClaimUX
              onEvent={props.onEvent}
              setProp={props.setProp}
              // data
              orderBy={orderBy}
              faxClaimItems={faxClaimItems}
              onSaveFaxCliaim={handleSaveModFaxClaim}
              onCancelFaxCliaim={handleCloseModFaxClaim}
              invoiceItem={props.faxClaimItemList?.total}
              buttonSave={
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={handleSaveModFaxClaim}
                  // data
                  paramKey={"FaxClaim_SAVE"}
                  buttonLoadCheck={props.buttonLoadCheck?.["FaxClaim_SAVE"]}
                  // config
                  name="SAVE"
                  color="green"
                  title={"บันทึก"}
                />
              }
            />
          </Modal>

          {/* comfirm faxclaim */}
          <ModAuthen
            titleName={"ยืนยันการแก้ไขข้อมูล"}
            titleColor={"blue"}
            open={openModAuthen}
            onDeny={() => setOpenModAuthen(false)}
            // error={props.authenError}
            onApprove={({ username, password }: any) => {
              // save
              handleCheckedTypeSave();
              setOpenModAuthen(false);
            }}
          />

          <ModLinkPackage
            onEvent={props.onEvent}
            setProp={props.setProp}
            // data
            open={!!modLinkPackage}
            data={modLinkPackage || {}}
            items={props.invoiceItemPackageList}
            // CommonInterface
            errorMessage={props.errorMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            // callback
            onClose={handleCloseModLinkPackage}
          />
        </>
      )}
    </div>
  );
};

export default CardMedicalFee;
