import WasmController from "react-lib/frameworks/WasmController";
import FormDataDetailByEncounter from "issara-sdk/apis/FormDataDetailByEncounter_apps_PTM";
import ClinicalTermList from "issara-sdk/apis/ClinicalTermList_core";
import ClinicalTermSetView from "issara-sdk/apis/ClinicalTermSetView_core";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import ReportMR1Print from "issara-sdk/apis/ReportMR1Print_apps_ADM";

export type State = {
  DischaegeNurseSequence?: {
    sequenceIndex?: string | null;
  } | null;
  FormDataDischargePlanning?: any;
  FormDataDischargeTeaching?: any;
  FormDataDischargeNursing?: any;
  ClinicalTermPlanning?: any;
  ClinicalTermTeaching?: any;
  ClinicalTermNursing?: any;
  selectedEncounter?: any;
  typeOptionsMethod?: any;
  typeOptionsRelative?: any;
  typeOptionKnowledge?: any;
  buttonLoadCheck?: Record<string, any>;
};

export const StateInitial: State = {
  DischaegeNurseSequence: null,
  FormDataDischargePlanning: [],
  FormDataDischargeTeaching: [],
  FormDataDischargeNursing: [],
  ClinicalTermPlanning: [],
  ClinicalTermTeaching: [],
  ClinicalTermNursing: [],
  typeOptionsMethod: [
    { key: 1, value: "-", text: "-" },
    { key: 2, value: "อธิบาย", text: "อธิบาย" },
    { key: 3, value: "สาธิต", text: "สาธิต" },
    { key: 4, value: "เปิดวิดีโอ", text: "เปิดวิดีโอ" },
    { key: 5, value: "แจกแผ่นพับ", text: "แจกแผ่นพับ" },
    { key: 6, value: "อื่น ๆ ระบุ", text: "อื่น ๆ ระบุ" },
  ],
  typeOptionsRelative: [
    { key: 1, value: "-", text: "-" },
    { key: 2, value: "บิดา", text: "บิดา" },
    { key: 3, value: "มารดา", text: "มารดา" },
    { key: 4, value: "บุตร", text: "บุตร" },
    { key: 5, value: "อื่น ๆ ระบุ", text: "อื่น ๆ ระบุ" },
  ],
  typeOptionKnowledge: [
    { key: 1, value: "-", text: "-" },
    { key: 2, value: "ความรู้ดี/ทำได้ดี", text: "ความรู้ดี/ทำได้ดี" },
    { key: 3, value: "ความรู้ปานกลาง/พอทำได้", text: "ความรู้ปานกลาง/พอทำได้" },
    { key: 4, value: "รู้น้อย/ทำได้น้อย", text: "รู้น้อย/ทำได้น้อย" },
    { key: 5, value: "ไม่ทราบ/ไม่เคยทำ", text: "ไม่ทราบ/ไม่เคยทำ" },
  ],
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
  masterData?: any;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  let state = controller.getState();

  console.log("is Get GET Master every time ?");
  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["disc_plan1", {}],
        ["disc_plan2", {}],
        ["disc_plan4", {}],
        ["disc_plan5", {}],
        ["disc_plan6", {}],
        ["disc_teaching1", {}],
        ["disc_teaching2", {}],
        ["disc_teaching3", {}],
        ["disc_teaching4", {}],
        ["disc_teaching5", {}],
        ["disc_teaching6", {}],
        ["disc_teaching7", {}],
        ["disc_teaching8", {}],
        ["disc_nursing1", {}],
        ["disc_nursing2", {}],
        ["disc_nursing3", {}],
        ["disc_nursing4", {}],
        ["disc_nursing5", {}],
        ["disc_nursing6", {}],
      ],
    },
  });

  state = controller.getState();

  //   if (!state.DischaegeNurseSequence) return;
  if (!params?.action) return;
  if (params?.action === "Formdata") {
    const dischargePlanning = await FormDataDetailByEncounter.retrieve({
      pk: state?.selectedEncounter.pk,
      params: { form_data_code: "discharge_planning" },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    const dischargeTeaching = await FormDataDetailByEncounter.retrieve({
      pk: state?.selectedEncounter.pk,
      params: { form_data_code: "discharge_teaching" },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    const dischargeNursing = await FormDataDetailByEncounter.retrieve({
      pk: state?.selectedEncounter.pk,
      params: { form_data_code: "discharge_nursing" },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    if (
      dischargePlanning?.[0]?.data &&
      dischargeTeaching?.[0]?.data &&
      dischargeNursing?.[0]?.data
    ) {
      const teachingData: Record<string, any> = {
        disc_teaching1: dischargeTeaching?.[0].data?.disc_teaching1,
        disc_teaching2: dischargeTeaching?.[0].data?.disc_teaching2,
        disc_teaching3: dischargeTeaching?.[0].data?.disc_teaching3,
        disc_teaching4: dischargeTeaching?.[0].data?.disc_teaching4,
        disc_teaching5: dischargeTeaching?.[0].data?.disc_teaching5,
      };

      const optionsRelativeI = [...StateInitial.typeOptionsRelative];
      const findRelativeI = optionsRelativeI.map((item: any) => item.value);

      const optionsKnowledgeI = [...StateInitial.typeOptionKnowledge];
      const findKnowledgeI = optionsKnowledgeI.map((item: any) => item.value);

      const optionsMethodI = [...StateInitial.typeOptionsMethod];
      const findMethodI = optionsMethodI.map((item: any) => item.value);

      {
        Object.keys(teachingData).map((key: any, index: number) => {
          (teachingData[key].items || [])?.forEach((value: any) => {
            if (!findRelativeI.includes(value.sub_relative)) {
              optionsRelativeI.push({
                key: value.sub_relative,
                value: value.sub_relative,
                text: value.sub_relative,
              });
            }

            if (!findMethodI.includes(value.sub_method)) {
              optionsMethodI.push({
                key: value.sub_method,
                value: value.sub_method,
                text: value.sub_method,
              });
            }

            if (!findKnowledgeI.includes(value.sub_before)) {
              optionsKnowledgeI.push({
                key: value.sub_before,
                value: value.sub_before,
                text: value.sub_before,
              });
            }

            if (!findKnowledgeI.includes(value.sub_first)) {
              optionsKnowledgeI.push({
                key: value.sub_first,
                value: value.sub_first,
                text: value.sub_first,
              });
            }

            if (!findKnowledgeI.includes(value.sub_second)) {
              optionsKnowledgeI.push({
                key: value.sub_second,
                value: value.sub_second,
                text: value.sub_second,
              });
            }

            if (!findKnowledgeI.includes(value.sub_home)) {
              optionsKnowledgeI.push({
                key: value.sub_home,
                value: value.sub_home,
                text: value.sub_home,
              });
            }
          });
          const main_relative = teachingData[key]?.main_relative || "";
          const main_method = teachingData[key]?.main_method || "";
          const main_before = teachingData[key]?.main_before || "";
          const main_first = teachingData[key]?.main_first || "";
          const main_second = teachingData[key]?.main_second || "";
          const main_home = teachingData[key]?.main_home || "";
          if (!findRelativeI.includes(main_relative)) {
            if (main_relative) {
              main_relative.map((item: any) =>
                optionsRelativeI.push({ key: item, value: item, text: item })
              );
            }
          }
          if (!findMethodI.includes(main_method)) {
            if (main_method) {
              main_method.map((item: any) =>
                optionsMethodI.push({ key: item, value: item, text: item })
              );
            }
          }

          if (!findKnowledgeI.includes(main_before)) {
            if (main_before) {
              main_before.map((item: any) =>
                optionsKnowledgeI.push({ key: item, value: item, text: item })
              );
            }
          }

          if (!findKnowledgeI.includes(main_first)) {
            if (main_first) {
              main_first.map((item: any) =>
                optionsKnowledgeI.push({ key: item, value: item, text: item })
              );
            }
          }
          if (!findKnowledgeI.includes(main_second)) {
            if (main_second) {
              main_second.map((item: any) =>
                optionsKnowledgeI.push({ key: item, value: item, text: item })
              );
            }
          }
          if (!findKnowledgeI.includes(main_home)) {
            if (main_home) {
              main_home.map((item: any) =>
                optionsKnowledgeI.push({ key: item, value: item, text: item })
              );
            }
          }
        });

        controller.setState({
          FormDataDischargePlanning: dischargePlanning?.[0],
          FormDataDischargeTeaching: dischargeTeaching?.[0],
          FormDataDischargeNursing: dischargeNursing?.[0],
          ClinicalTermPlanning: dischargePlanning?.[0]?.data,
          ClinicalTermTeaching: dischargeTeaching?.[0]?.data,
          ClinicalTermNursing: dischargeNursing?.[0]?.data,
          typeOptionsMethod: optionsMethodI,
          typeOptionsRelative: optionsRelativeI,
          typeOptionKnowledge: optionsKnowledgeI,
        });
      }
    } else {
      const ClinicalTermt = await ClinicalTermList.list({
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      const itemPlan1 = controller.data?.masterData?.disc_plan1.map(
        (item: any) => ({
          answer: "",
          checked: false,
          has_description: true,
          id: item.key,
          name: item.text,
        })
      );

      const itemPlan2 = controller.data?.masterData?.disc_plan2.map(
        (item: any) => ({
          answer: "",
          checked: false,
          has_description: true,
          id: item.key,
          name: item.text,
        })
      );
      const itemPlan4 = controller.data?.masterData?.disc_plan4.map(
        (item: any) => ({
          answer: "",
          checked: false,
          has_description: true,
          id: item.key,
          name: item.text,
        })
      );
      const itemPlan5 = controller.data?.masterData?.disc_plan5.map(
        (item: any) => ({
          answer: "",
          checked: false,
          has_description: true,
          id: item.key,
          name: item.text,
        })
      );
      const itemPlan6 = controller.data?.masterData?.disc_plan6.map(
        (item: any) => ({
          answer: "",
          checked: false,
          has_description: true,
          id: item.key,
          name: item.text,
        })
      );

      const itemTeach1 = controller.data?.masterData?.disc_teaching1.map(
        (item: any) => ({
          id: item.key,
          name: item.text,
          sub_before: "",
          sub_before_user: "",
          sub_first: "",
          sub_first_user: "",
          sub_home: "",
          sub_home_user: "",
          sub_method: "",
          sub_method_note: "",
          sub_relative: "",
          sub_relative_name: "",
          sub_relative_note: "",
          sub_second: "",
          sub_second_user: "",
        })
      );
      const itemTeach7 = controller.data?.masterData?.disc_teaching7.map(
        (item: any) => ({
          id: item.key,
          name: item.text,
          sub_before: "",
          sub_before_user: "",
          sub_first: "",
          sub_first_user: "",
          sub_home: "",
          sub_home_user: "",
          sub_method: "",
          sub_method_note: "",
          sub_relative: "",
          sub_relative_name: "",
          sub_relative_note: "",
          sub_second: "",
          sub_second_user: "",
        })
      );

      const itemNursing2 = controller.data?.masterData?.disc_nursing2.map(
        (item: any) => ({
          answer: "",
          checked: false,
          has_description: true,
          id: item.key,
          name: item.text,
        })
      );
      const itemNursing6 = controller.data?.masterData?.disc_nursing6.map(
        (item: any) => ({
          answer: "",
          checked: false,
          has_description: true,
          id: item.key,
          name: item.text,
        })
      );

      const ClinicalTermSetPlan = await ClinicalTermSetView.post({
        data: {
          disc_plan1: {
            code: "disc_plan1",
            has_problem: "N",
            id: 16,
            items: [itemPlan1],
            name: "ปัญหาสุขภาพที่ต้องได้รับความช่วยเหลือ",
            no: 1,
          },
          disc_plan2: {
            code: "disc_plan2",
            has_problem: "N",
            id: 17,
            items: [itemPlan2],
            name: "ปัญหาทางด้านครอบครัว สังคมและสิ่งแวดล้อม",
            no: 2,
          },
          disc_plan4: {
            code: "disc_plan4",
            has_problem: "N",
            id: 22,
            items: [itemPlan4],
            name: "ผู้ดูแลผู้ป่วยหลังจำหน่ายออกจากโรงพยาบาล",
            no: 3,
          },
          disc_plan5: {
            code: "disc_plan5",
            has_problem: "N",
            id: 23,
            items: [itemPlan5],
            name: "ครอบครัวผู้ป่วยมีความพร้อมให้การช่วยเหลือดูแลผู้ป่วยเมื่อจำหน่ายกลับบ้าน",
            no: 4,
          },
          disc_plan6: {
            code: "disc_plan6",
            has_problem: "N",
            id: 24,
            items: [itemPlan6],
            name: "สรุป ผู้ป่วย/ครอบครัว จำเป็นต้องได้รับการดูแลต่อเนื่องที่บ้าน",
            no: 5,
          },
          question: [
            "disc_plan1",
            "disc_plan2",
            "disc_plan4",
            "disc_plan5",
            "disc_plan6",
          ],
        },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      const ClinicalTermSetTeach = await ClinicalTermSetView.post({
        data: {
          disc_teaching1: {
            id: 1005,
            items: [itemTeach1],
            main_before: "",
            main_before_user: "",
            main_first: "",
            main_first_user: "",
            main_home: "",
            main_home_user: "",
            main_method: "",
            main_method_note: "",
            main_relative: "",
            main_relative_name: "",
            main_relative_note: "",
            main_second: "",
            main_second_user: "",
            name: "ความรู้เกี่ยวกับโรค",
            no: 1,
          },
          disc_teaching2: {
            id: 1006,
            items: [],
            main_before: "",
            main_before_user: "",
            main_first: "",
            main_first_user: "",
            main_home: "",
            main_home_user: "",
            main_method: "",
            main_method_note: "",
            main_relative: "",
            main_relative_name: "",
            main_relative_note: "",
            main_second: "",
            main_second_user: "",
            name: "Special care",
            no: 2,
          },
          disc_teaching3: {
            id: 1007,
            items: [],
            main_before: "",
            main_before_user: "",
            main_first: "",
            main_first_user: "",
            main_home: "",
            main_home_user: "",
            main_method: "",
            main_method_note: "",
            main_relative: "",
            main_relative_name: "",
            main_relative_note: "",
            main_second: "",
            main_second_user: "",
            name: "การป้องกัน/การส่งเสริม/การฟื้นฟูสุขภาพ",
            no: 3,
          },
          disc_teaching4: {
            id: 1008,
            items: [],
            main_before: "",
            main_before_user: "",
            main_first: "",
            main_first_user: "",
            main_home: "",
            main_home_user: "",
            main_method: "",
            main_method_note: "",
            main_relative: "",
            main_relative_name: "",
            main_relative_note: "",
            main_second: "",
            main_second_user: "",
            name: "อาการสำคัญที่ควรมาพบแพทย์ก่อนวันนัด",
            no: 4,
          },
          disc_teaching5: {
            id: 1009,
            items: [],
            main_before: "",
            main_before_user: "",
            main_first: "",
            main_first_user: "",
            main_home: "",
            main_home_user: "",
            main_method: "",
            main_method_note: "",
            main_relative: "",
            main_relative_name: "",
            main_relative_note: "",
            main_second: "",
            main_second_user: "",
            name: "การรับประทานอาหาร/อาการข้างเคียง Side effects",
            no: 5,
          },
          disc_teaching6: {
            id: 1010,
            items: [],
            main_before: "",
            main_before_user: "",
            main_first: "",
            main_first_user: "",
            main_home: "",
            main_home_user: "",
            main_method: "",
            main_method_note: "",
            main_relative: "",
            main_relative_name: "",
            main_relative_note: "",
            main_second: "",
            main_second_user: "",
            name: "อาหาร/นม",
            no: 6,
          },
          disc_teaching7: {
            id: 1011,
            items: [itemTeach7],
            main_before: "",
            main_before_user: "",
            main_first: "",
            main_first_user: "",
            main_home: "",
            main_home_user: "",
            main_method: "",
            main_method_note: "",
            main_relative: "",
            main_relative_name: "",
            main_relative_note: "",
            main_second: "",
            main_second_user: "",
            name: "Activity",
            no: 7,
          },
          disc_teaching8: {
            id: 1012,
            items: [],
            main_before: "",
            main_before_user: "",
            main_first: "",
            main_first_user: "",
            main_home: "",
            main_home_user: "",
            main_method: "",
            main_method_note: "",
            main_relative: "",
            main_relative_name: "",
            main_relative_note: "",
            main_second: "",
            main_second_user: "",
            name: "การมาตรวจตามนัด",
            no: 8,
          },

          question: [
            "disc_teaching1",
            "disc_teaching2",
            "disc_teaching3",
            "disc_teaching4",
            "disc_teaching5",
            "disc_teaching6",
            "disc_teaching7",
            "disc_teaching8",
          ],
        },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      const ClinicalTermSetNursing = await ClinicalTermSetView.post({
        data: {
          disc_nursing1: {
            code: "disc_nursing1",
            has_problem: "N",
            id: 1142,
            items: [],
            name: "สภาพผู้ป่วยก่อนจำหน่าย",
            no: 1,
          },
          disc_nursing2: {
            code: "disc_nursing2",
            has_problem: "N",
            id: 25,
            items: [itemNursing2],
            name: "อุปกรณ์การแพทย์ที่จำหน่ายพร้อมผู้ป่วย",
            no: 2,
          },
          disc_nursing3: {
            code: "disc_nursing3",
            has_problem: "N",
            id: 1002,
            items: [itemNursing2],
            name: "ปัญหาที่ต้องได้รับการดูแลต่อเนื่องที่บ้านและความช่วยเหลือที่ให้",
            no: 3,
          },
          disc_nursing4: {
            code: "disc_nursing4",
            has_problem: "N",
            id: 1003,
            items: [],
            name: "การจำหน่าย",
            no: 4,
          },
          disc_nursing5: {
            code: "disc_nursing5",
            has_problem: "N",
            id: 1004,
            items: [],
            name: "การนัดตรวจครั้งต่อไป",
            no: 5,
          },
          disc_nursing6: {
            code: "disc_nursing6",
            has_problem: "N",
            id: 26,
            items: [itemNursing6],
            name: "สิ่งที่ผู้ป่วยได้รับก่อนจำหน่าย",
            no: 6,
          },
          disc_nursing7: {
            code: "disc_nursing7",
            has_problem: "N",
            id: 1143,
            items: [],
            name: "จำหน่ายออกจากโรงพยาบาลโดย",
            no: 7,
          },

          question: [
            "disc_nursing1",
            "disc_nursing2",
            "disc_nursing3",
            "disc_nursing4",
            "disc_nursing5",
            "disc_nursing6",
            "disc_nursing7",
          ],
        },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      controller.setState({
        FormDataDischargePlanning: dischargePlanning?.[0],
        FormDataDischargeTeaching: dischargeTeaching?.[0],
        FormDataDischargeNursing: dischargeNursing?.[0],
        ClinicalTermPlanning: ClinicalTermSetPlan?.[0],
        ClinicalTermTeaching: ClinicalTermSetTeach?.[0],
        ClinicalTermNursing: ClinicalTermSetNursing?.[0],
        typeOptionsMethod: StateInitial.typeOptionsMethod,
        typeOptionsRelative: StateInitial.typeOptionsRelative,
        typeOptionKnowledge: StateInitial.typeOptionKnowledge,
      });
    }
  } else if (params?.action === "Save") {
    if (
      state.FormDataDischargePlanning?.form_data_id &&
      state.FormDataDischargeTeaching?.form_data_id &&
      state.FormDataDischargeNursing?.form_data_id
    ) {
      const formPlanUpdate = await FormDataDetail.update({
        pk: state.FormDataDischargePlanning?.form_data_id,
        data: {
          action: "SAVE",
          data: {
            disc_plan1: state.ClinicalTermPlanning?.disc_plan1,
            disc_plan2: state.ClinicalTermPlanning?.disc_plan2,
            disc_plan4: state.ClinicalTermPlanning?.disc_plan4,
            disc_plan5: state.ClinicalTermPlanning?.disc_plan5,
            disc_plan6: state.ClinicalTermPlanning?.disc_plan6,
            question: [
              "disc_plan1",
              "disc_plan2",
              "disc_plan4",
              "disc_plan5",
              "disc_plan6",
            ],
          },
          encounter: state?.selectedEncounter.pk,
          form_code: "discharge_planning",
          form_data_id: state.FormDataDischargePlanning?.form_data_id,
          form_data_status_name:
            state.FormDataDischargePlanning?.form_data_status_name,
          form_data_status_value:
            state.FormDataDischargePlanning?.form_data_status_value,
          form_name: "Discharge Planning (Nurse)",
          form_version: "1.0",
        },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      const formTeachUpdate = await FormDataDetail.update({
        pk: state.FormDataDischargeTeaching?.form_data_id,
        data: {
          action: "SAVE",
          data: {
            disc_teaching1: state.ClinicalTermTeaching?.disc_teaching1,
            disc_teaching2: state.ClinicalTermTeaching?.disc_teaching2,
            disc_teaching3: state.ClinicalTermTeaching?.disc_teaching3,
            disc_teaching4: state.ClinicalTermTeaching?.disc_teaching4,
            disc_teaching5: state.ClinicalTermTeaching?.disc_teaching5,
            disc_teaching6: state.ClinicalTermTeaching?.disc_teaching6,
            disc_teaching7: state.ClinicalTermTeaching?.disc_teaching7,
            disc_teaching8: state.ClinicalTermTeaching?.disc_teaching8,

            question: [
              "disc_teaching1",
              "disc_teaching2",
              "disc_teaching3",
              "disc_teaching4",
              "disc_teaching5",
              "disc_teaching6",
              "disc_teaching7",
              "disc_teaching8",
            ],
          },
          encounter: state?.selectedEncounter.pk,
          form_code: "discharge_teaching",
          form_data_id: state.FormDataDischargeTeaching?.form_data_id,
          form_data_status_name:
            state.FormDataDischargeTeaching?.form_data_status_name,
          form_data_status_value:
            state.FormDataDischargeTeaching?.form_data_status_value,
          form_name: "Discharge Teaching (Nurse)",
          form_version: "1.0",
        },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      const formNurseUpdate = await FormDataDetail.update({
        pk: state.FormDataDischargeNursing?.form_data_id,
        data: {
          action: "SAVE",
          data: {
            disc_nursing1: state.ClinicalTermNursing?.disc_nursing1,
            disc_nursing2: state.ClinicalTermNursing?.disc_nursing2,
            disc_nursing3: state.ClinicalTermNursing?.disc_nursing3,
            disc_nursing4: state.ClinicalTermNursing?.disc_nursing4,
            disc_nursing5: state.ClinicalTermNursing?.disc_nursing5,
            disc_nursing6: state.ClinicalTermNursing?.disc_nursing6,
            disc_nursing7: state.ClinicalTermNursing?.disc_nursing7,
            question: [
              "disc_nursing1",
              "disc_nursing2",
              "disc_nursing3",
              "disc_nursing4",
              "disc_nursing5",
              "disc_nursing6",
              "disc_nursing7",
            ],
          },
          encounter: state?.selectedEncounter.pk,
          form_code: "discharge_nursing",
          form_data_id: state.FormDataDischargeNursing?.form_data_id,
          form_data_status_name:
            state.FormDataDischargeNursing?.form_data_status_name,
          form_data_status_value:
            state.FormDataDischargeNursing?.form_data_status_value,
          form_name: "Discharge Nursing (Nurse)",
          form_version: "1.0",
        },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      const teachingData: Record<string, any> = {
        disc_teaching1: formTeachUpdate?.[0].data?.disc_teaching1,
        disc_teaching2: formTeachUpdate?.[0].data?.disc_teaching2,
        disc_teaching3: formTeachUpdate?.[0].data?.disc_teaching3,
        disc_teaching4: formTeachUpdate?.[0].data?.disc_teaching4,
        disc_teaching5: formTeachUpdate?.[0].data?.disc_teaching5,
      };

      const optionsRelativeI = [...StateInitial.typeOptionsRelative];
      const findRelativeI = optionsRelativeI.map((item: any) => item.value);

      const optionsKnowledgeI = [...StateInitial.typeOptionKnowledge];
      const findKnowledgeI = optionsKnowledgeI.map((item: any) => item.value);

      const optionsMethodI = [...StateInitial.typeOptionsMethod];
      const findMethodI = optionsMethodI.map((item: any) => item.value);

      {
        Object.keys(teachingData).map((key: any, index: number) => {
          (teachingData[key].items || [])?.forEach((value: any) => {
            if (!findRelativeI.includes(value.sub_relative)) {
              optionsRelativeI.push({
                key: value.sub_relative,
                value: value.sub_relative,
                text: value.sub_relative,
              });
            }

            if (!findMethodI.includes(value.sub_method)) {
              optionsMethodI.push({
                key: value.sub_method,
                value: value.sub_method,
                text: value.sub_method,
              });
            }

            if (!findKnowledgeI.includes(value.sub_before)) {
              optionsKnowledgeI.push({
                key: value.sub_before,
                value: value.sub_before,
                text: value.sub_before,
              });
            }

            if (!findKnowledgeI.includes(value.sub_first)) {
              optionsKnowledgeI.push({
                key: value.sub_first,
                value: value.sub_first,
                text: value.sub_first,
              });
            }

            if (!findKnowledgeI.includes(value.sub_second)) {
              optionsKnowledgeI.push({
                key: value.sub_second,
                value: value.sub_second,
                text: value.sub_second,
              });
            }

            if (!findKnowledgeI.includes(value.sub_home)) {
              optionsKnowledgeI.push({
                key: value.sub_home,
                value: value.sub_home,
                text: value.sub_home,
              });
            }
          });
          const main_relative = teachingData[key]?.main_relative || "";
          const main_method = teachingData[key]?.main_method || "";
          const main_before = teachingData[key]?.main_before || "";
          const main_first = teachingData[key]?.main_first || "";
          const main_second = teachingData[key]?.main_second || "";
          const main_home = teachingData[key]?.main_home || "";
          if (!findRelativeI.includes(main_relative)) {
            if (main_relative) {
              main_relative.map((item: any) =>
                optionsRelativeI.push({ key: item, value: item, text: item })
              );
            }
          }
          if (!findMethodI.includes(main_method)) {
            if (main_method) {
              main_method.map((item: any) =>
                optionsMethodI.push({ key: item, value: item, text: item })
              );
            }
          }

          if (!findKnowledgeI.includes(main_before)) {
            if (main_before) {
              main_before.map((item: any) =>
                optionsKnowledgeI.push({ key: item, value: item, text: item })
              );
            }
          }

          if (!findKnowledgeI.includes(main_first)) {
            if (main_first) {
              main_first.map((item: any) =>
                optionsKnowledgeI.push({ key: item, value: item, text: item })
              );
            }
          }
          if (!findKnowledgeI.includes(main_second)) {
            if (main_second) {
              main_second.map((item: any) =>
                optionsKnowledgeI.push({ key: item, value: item, text: item })
              );
            }
          }
          if (!findKnowledgeI.includes(main_home)) {
            if (main_home) {
              main_home.map((item: any) =>
                optionsKnowledgeI.push({ key: item, value: item, text: item })
              );
            }
          }
        });
      }
      controller.setState({
        FormDataDischargePlanning: formPlanUpdate?.[0],
        FormDataDischargeTeaching: formTeachUpdate?.[0],
        FormDataDischargeNursing: formNurseUpdate?.[0],
        ClinicalTermPlanning: formPlanUpdate?.[0]?.data,
        ClinicalTermTeaching: formTeachUpdate?.[0]?.data,
        ClinicalTermNursing: formNurseUpdate?.[0]?.data,
        typeOptionsMethod: optionsMethodI,
        typeOptionsRelative: optionsRelativeI,
        typeOptionKnowledge: optionsKnowledgeI,
      });
    } else {
      const formPlan = await FormDataList.create({
        data: {
          action: "SAVE",
          data: {
            disc_plan1: state.ClinicalTermPlanning?.disc_plan1,
            disc_plan2: state.ClinicalTermPlanning?.disc_plan2,
            disc_plan4: state.ClinicalTermPlanning?.disc_plan4,
            disc_plan5: state.ClinicalTermPlanning?.disc_plan5,
            disc_plan6: state.ClinicalTermPlanning?.disc_plan6,
            question: [
              "disc_plan1",
              "disc_plan2",
              "disc_plan4",
              "disc_plan5",
              "disc_plan6",
            ],
          },
          encounter: state?.selectedEncounter.pk,
          form_code: "discharge_planning",
          form_data_id: null,
          form_data_status_name: null,
          form_data_status_value: null,
          form_name: "Discharge Planning (Nurse)",
          form_version: "1.0",
        },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      const formTeach = await FormDataList.create({
        data: {
          action: "SAVE",
          data: {
            disc_teaching1: state.ClinicalTermTeaching?.disc_teaching1,
            disc_teaching2: state.ClinicalTermTeaching?.disc_teaching2,
            disc_teaching3: state.ClinicalTermTeaching?.disc_teaching3,
            disc_teaching4: state.ClinicalTermTeaching?.disc_teaching4,
            disc_teaching5: state.ClinicalTermTeaching?.disc_teaching5,
            disc_teaching6: state.ClinicalTermTeaching?.disc_teaching6,
            disc_teaching7: state.ClinicalTermTeaching?.disc_teaching7,
            disc_teaching8: state.ClinicalTermTeaching?.disc_teaching8,

            question: [
              "disc_teaching1",
              "disc_teaching2",
              "disc_teaching3",
              "disc_teaching4",
              "disc_teaching5",
              "disc_teaching6",
              "disc_teaching7",
              "disc_teaching8",
            ],
          },
          encounter: state?.selectedEncounter.pk,
          form_code: "discharge_teaching",
          form_data_id: null,
          form_data_status_name: null,
          form_data_status_value: null,
          form_name: "Discharge Teaching (Nurse)",
          form_version: "1.0",
        },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      const formNurse = await FormDataList.create({
        data: {
          action: "SAVE",
          data: {
            disc_nursing1: state.ClinicalTermNursing?.disc_nursing1,
            disc_nursing2: state.ClinicalTermNursing?.disc_nursing2,
            disc_nursing3: state.ClinicalTermNursing?.disc_nursing3,
            disc_nursing4: state.ClinicalTermNursing?.disc_nursing4,
            disc_nursing5: state.ClinicalTermNursing?.disc_nursing5,
            disc_nursing6: state.ClinicalTermNursing?.disc_nursing6,
            disc_nursing7: state.ClinicalTermNursing?.disc_nursing7,
            question: [
              "disc_nursing1",
              "disc_nursing2",
              "disc_nursing3",
              "disc_nursing4",
              "disc_nursing5",
              "disc_nursing6",
              "disc_nursing7",
            ],
          },
          encounter: state?.selectedEncounter.pk,
          form_code: "discharge_nursing",
          form_data_id: null,
          form_data_status_name: null,
          form_data_status_value: null,
          form_name: "Discharge Nursing (Nurse)",
          form_version: "1.0",
        },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      const teachingData: Record<string, any> = {
        disc_teaching1: formTeach?.[0].data?.disc_teaching1,
        disc_teaching2: formTeach?.[0].data?.disc_teaching2,
        disc_teaching3: formTeach?.[0].data?.disc_teaching3,
        disc_teaching4: formTeach?.[0].data?.disc_teaching4,
        disc_teaching5: formTeach?.[0].data?.disc_teaching5,
      };

      const optionsRelativeI = [...StateInitial.typeOptionsRelative];
      const findRelativeI = optionsRelativeI.map((item: any) => item.value);

      const optionsKnowledgeI = [...StateInitial.typeOptionKnowledge];
      const findKnowledgeI = optionsKnowledgeI.map((item: any) => item.value);

      const optionsMethodI = [...StateInitial.typeOptionsMethod];
      const findMethodI = optionsMethodI.map((item: any) => item.value);

      {
        Object.keys(teachingData).map((key: any, index: number) => {
          (teachingData[key].items || [])?.forEach((value: any) => {
            if (!findRelativeI.includes(value.sub_relative)) {
              optionsRelativeI.push({
                key: value.sub_relative,
                value: value.sub_relative,
                text: value.sub_relative,
              });
            }

            if (!findMethodI.includes(value.sub_method)) {
              optionsMethodI.push({
                key: value.sub_method,
                value: value.sub_method,
                text: value.sub_method,
              });
            }

            if (!findKnowledgeI.includes(value.sub_before)) {
              optionsKnowledgeI.push({
                key: value.sub_before,
                value: value.sub_before,
                text: value.sub_before,
              });
            }

            if (!findKnowledgeI.includes(value.sub_first)) {
              optionsKnowledgeI.push({
                key: value.sub_first,
                value: value.sub_first,
                text: value.sub_first,
              });
            }

            if (!findKnowledgeI.includes(value.sub_second)) {
              optionsKnowledgeI.push({
                key: value.sub_second,
                value: value.sub_second,
                text: value.sub_second,
              });
            }

            if (!findKnowledgeI.includes(value.sub_home)) {
              optionsKnowledgeI.push({
                key: value.sub_home,
                value: value.sub_home,
                text: value.sub_home,
              });
            }
          });
          const main_relative = teachingData[key]?.main_relative || "";
          const main_method = teachingData[key]?.main_method || "";
          const main_before = teachingData[key]?.main_before || "";
          const main_first = teachingData[key]?.main_first || "";
          const main_second = teachingData[key]?.main_second || "";
          const main_home = teachingData[key]?.main_home || "";
          if (!findRelativeI.includes(main_relative)) {
            if (main_relative) {
              main_relative.map((item: any) =>
                optionsRelativeI.push({ key: item, value: item, text: item })
              );
            }
          }
          if (!findMethodI.includes(main_method)) {
            if (main_method) {
              main_method.map((item: any) =>
                optionsMethodI.push({ key: item, value: item, text: item })
              );
            }
          }

          if (!findKnowledgeI.includes(main_before)) {
            if (main_before) {
              main_before.map((item: any) =>
                optionsKnowledgeI.push({ key: item, value: item, text: item })
              );
            }
          }

          if (!findKnowledgeI.includes(main_first)) {
            if (main_first) {
              main_first.map((item: any) =>
                optionsKnowledgeI.push({ key: item, value: item, text: item })
              );
            }
          }
          if (!findKnowledgeI.includes(main_second)) {
            if (main_second) {
              main_second.map((item: any) =>
                optionsKnowledgeI.push({ key: item, value: item, text: item })
              );
            }
          }
          if (!findKnowledgeI.includes(main_home)) {
            if (main_home) {
              main_home.map((item: any) =>
                optionsKnowledgeI.push({ key: item, value: item, text: item })
              );
            }
          }
        });
      }
      controller.setState({
        FormDataDischargePlanning: formPlan?.[0],
        FormDataDischargeTeaching: formTeach?.[0],
        FormDataDischargeNursing: formNurse?.[0],
        ClinicalTermPlanning: formPlan?.[0]?.data,
        ClinicalTermTeaching: formTeach?.[0]?.data,
        ClinicalTermNursing: formNurse?.[0]?.data,
        typeOptionsMethod: optionsMethodI,
        typeOptionsRelative: optionsRelativeI,
        typeOptionKnowledge: optionsKnowledgeI,
      });
    }
  } else if (params.action === "printDischargeSummary") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.cardName]: "LOADING",
      },
    });

    const [data, error] = await ReportMR1Print.retrieve({
      params: { encounter: state?.selectedEncounter.id, pdf: true },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    if (data?.pdf_b64data) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.cardName]: "SUCCESS",
        },
      });

      const pdfWindow = window.open(
        "_blank",
        `data:application/pdf;base64,${data.pdf_b64data}`
      );
      pdfWindow?.document?.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64," +
        data.pdf_b64data +
          "'></iframe>"
      );

      return;
    }

    if (data?.error?.message) {
      alert(data?.error?.message);
    }
    if (error) {
      console.warn("error", error);
    }

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.cardName]: "ERROR",
      },
    });
  }
};
