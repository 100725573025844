import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import ModCoverageSelectUX from "./ModCoverageSelectUX";

const ModCoverageSelect = (props: any) => {

    return (
        <ModCoverageSelectUX
            // data
            patientCoverageList={props.patientCoverageList}
            selectedPatientCoverage={props.selectedPatientCoverage}
            // function
            onSelectedPatientCoverage={(
                state: any,
                rowInfo: any,
                column: any,
                instance: any) => ({
                    style: {
                        backgroundColor:
                            props.selectedPatientCoverage && props.selectedPatientCoverage?.id && rowInfo?.original?.id === props.selectedPatientCoverage?.id
                                ? "#cccccc"
                                : "white",
                    },
                    onClick: (e: any) => {
                        props.setSelectedPatientCoverage(rowInfo?.original);
                    }
                })}
            onClickSelectedPatientCoverage={props.onClickSelectedPatientCoverage}
            // component
            closeIcon={
                <Icon 
                    name="close"
                    color="red"
                    size="big"
                    style={{cursor: "pointer"}}
                    onClick={props.onClose}
                />
            }
        />
    )
}

export default ModCoverageSelect;