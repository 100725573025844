import WasmController from "react-lib/frameworks/WasmController";
// TPD
import DrugTransferRequestList from "issara-sdk/apis/DrugTransferRequestList_apps_TPD";
import DrugTransferRequestDetail from "issara-sdk/apis/DrugTransferRequestDetail_apps_TPD";
import DrugTransferRequestItemPlanList from "issara-sdk/apis/DrugTransferRequestItemPlanList_apps_TPD";
// MSD
import SupplyTransferRequestList from "issara-sdk/apis/SupplyTransferRequestListCreate_apps_MSD";
import SupplyTransferRequestDetail from "issara-sdk/apis/SupplyTransferRequestDetail_apps_MSD";
import SupplyTransferRequestItemPlanList from "issara-sdk/apis/SupplyTransferRequestItemPlanList_apps_MSD";
// CORE
import ProductStockList from "issara-sdk/apis/ProductStockList_core";

import moment from "moment";

// FORM
import { HandlePrintFormTransferStock } from "./StockManagement";

// Utils
import { formatDate, formatDatetime } from "react-lib/utils/dateUtils";

export type State = {
  // CommonInterface
  successMessage?: any;
  errorMessage?: any;
  buttonLoadCheck?: any; // {cardName: LOADING || SUCCESS || ERROR}
  loadingStatus?: any;
  // seq
  StockTransferOrderSequence?: {
    sequenceIndex?: string | null;
    apiMode?: string;
    selectedBox?: any;
    filterBox?: Partial<{
      status: string;
      provider: string;
      requester: string;
      from_date: string;
      to_date: string;
    }>;
    drugTransferRequestlist?:
    | {
      drug?: number | null;
      code?: any;
      name?: any;
      request_quantity?: number | null;
      storekey?: number | null;
      onhand_requester?: number | null;
      onhand_provider?: number | null;
      stock_unit_name?: number | null;
      request?: number;
    }[]
    | null;
  } | null;
};

export const StateInitial: State = {
  StockTransferOrderSequence: {
    drugTransferRequestlist: null,
    apiMode: "Drugs",
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const start: Handler = async (controller, params) => {
  var state = controller.getState();

  if (!state.StockTransferOrderSequence) return;

  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["divisionPharma", {}]],
    },
  });

  controller.setState(
    {
      StockTransferOrderSequence: {
        sequenceIndex: "Action",
        apiMode: "Drugs",
        filterBox: {
          from_date: formatDate(moment()),
          to_date: formatDate(moment())
        }
      },
    },
    () => {
      controller.handleEvent({
        message: "RunSequence",
        params: { ...params, action: "SEARCH" },
      });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  if (params.action === "SEARCH") {
    HandleSearch(controller, params);
  } else if (params.action === "SET_SELECTED") {
    const state = controller.getState();

    params.selectedBox.items?.forEach((item: any, idx: number) => {
      if (state.StockTransferOrderSequence?.apiMode === "Supply") {
        GetStockPlaning(controller, { id: item.id, index: idx, selectedBox: params.selectedBox })
      } else {
        GetDrugPlaning(controller, { id: item.id, index: idx, selectedBox: params.selectedBox })
      }
    })
  } else if (params.action === "GET_LOTS_DETAIL") {
    const state = controller.getState();
    if (state.StockTransferOrderSequence?.apiMode === "Supply") {
      GetStockPlaning(controller, params)
    } else {
      GetDrugPlaning(controller, params)
    }
  } else if (
    params.action === "APPROVE" ||
    params.action === "REJECT" ||
    params.action === "DELIVER" ||
    params.action === "RECEIVE" ||
    params.action === "EDIT"
  ) {
    const state = controller.getState();

    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params?.card]: true },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    const selectedBox = state.StockTransferOrderSequence?.selectedBox;

    let [response, error, network] = [undefined, undefined, undefined];

    if (state.StockTransferOrderSequence?.apiMode === "Supply") {
      [response, error] = await SupplyTransferRequestDetail.update({
        pk: selectedBox?.id,
        data: {
          action: params?.action,
          items: selectedBox?.items,
        } as any,
        extra: { division: controller.data.division, pdf: false },
        apiToken: controller.apiToken,
      });
    } else {
      [response, error, network] = await DrugTransferRequestDetail.update({
        pk: selectedBox?.id,
        data: {
          action: params?.action,
          items: selectedBox?.items,
        } as any,
        extra: { division: controller.data.division, pdf: false },
        apiToken: controller.apiToken,
      });
    }

    if (response) {
      if (params.action === "APPROVE") {
        await HandlePrintFormTransferStock(controller, {
          data: selectedBox,
          items: selectedBox.items,
        });
      }

      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params?.card]: response,
        },
        errorMessage: { ...state.errorMessage, [params?.card]: "" },
        loadingStatus: { ...state.loadingStatus, [params?.card]: false },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
      });

      params.onSuccess?.();

      HandleSearch(controller, params);
    } else {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.card]: error },
        loadingStatus: { ...state.loadingStatus, [params?.card]: false },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    }

  }
};

const HandleSearch: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  let filterParams = {
    ...state.StockTransferOrderSequence?.filterBox,
    is_queue: true,
    sort: "code",
  };

  let [response, error, network]: any = [undefined, undefined, undefined];

  if (state.StockTransferOrderSequence?.apiMode === "Supply") {
    [response, error, network] = await SupplyTransferRequestList.list({
      params: filterParams,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });
  } else {
    [response, error, network] = await DrugTransferRequestList.list({
      params: filterParams,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });
  }

  let tempSel = state.StockTransferOrderSequence?.selectedBox;

  if (tempSel) {
    const data = response?.items?.find((t: any) => t.id === tempSel.id) || {};

    tempSel = { ...tempSel, ...data, id: tempSel.id, items: tempSel.items }
  }

  if (error) {
    tempSel = {};
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
      StockTransferOrderSequence: {
        ...state.StockTransferOrderSequence,
        drugTransferRequestlist: response?.items || [],
        selectedBox: formatSelectedData(tempSel),
      },
    });
  }
};

const GetStockPlaning: Handler = async (controller, params) => {
  const state = controller.getState();

  const [response, error, network] = await SupplyTransferRequestItemPlanList.list({
    pk: params.id,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });

  let plans = response?.items || []

  plans = await Promise.all(plans.map(async (p: any) => {
    const [response2, error2, network2] = await ProductStockList.list({
      pk: p.lot.product,
      apiToken: controller.apiToken,
      params: { active: true, lot: p.lot.id },
      extra: {
        division: controller.data.division,
      },
    });

    let quantity = 0

    if (response2?.total > 0) {
      quantity = response2?.items[0].quantity
    }

    p.lot["get_quantity"] = quantity

    return { ...p }
  }));

  if (plans) {
    let temp = params.selectedBox?.items[params.index]

    temp["items"] = plans

    let parentItems = params.selectedBox?.items

    parentItems[params.index] = temp

    controller.setState({
      StockTransferOrderSequence: {
        ...state.StockTransferOrderSequence,
        selectedBox: { ...formatSelectedData(params.selectedBox), items: parentItems },
      },
    });
  }
}

const GetDrugPlaning: Handler = async (controller, params) => {
  const state = controller.getState();

  const [response, error, network] = await DrugTransferRequestItemPlanList.list({
    pk: params.id,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });

  let plans = response?.items

  plans = await Promise.all(plans.map(async (p: any) => {
    const [response2, error2, network2] = await ProductStockList.list({
      pk: p.lot.product,
      apiToken: controller.apiToken,
      params: { active: true, lot: p.lot.id },
      extra: {
        division: controller.data.division,
      },
    });
    let quantity = 0
    if (response2?.total > 0)
      quantity = response2?.items[0].quantity
    p.lot["get_quantity"] = quantity
    return { ...p }
  }));

  console.log("plans", plans)

  if (plans) {
    let temp = params.selectedBox?.items[params.index]
    temp["items"] = plans
    let parentItems = params.selectedBox?.items
    parentItems[params.index] = temp


    controller.setState({
      StockTransferOrderSequence: {
        ...state.StockTransferOrderSequence,
        selectedBox: { ...formatSelectedData(params.selectedBox), items: parentItems },
      },
    });
  }
}

/* ------------------------------------------------------ */

/*                          Uitls                         */

/* ------------------------------------------------------ */

const formatSelectedData = (data: Record<string, any>) => {
  let clickedOrder = data;

  const requestDatetime = formatDatetime(moment(data?.requested?.datetime))
  const approveDatetime = formatDatetime(moment(data?.approved?.datetime))
  const deliverDatetime = formatDatetime(moment(data?.delivered?.datetime))

  let requested = {
    ...clickedOrder?.requested,
    datetime_str: requestDatetime,
  };
  let approved = {
    ...clickedOrder?.approved,
    datetime_str: clickedOrder?.approved
      ? approveDatetime
      : "",
  };
  let delivered = {
    ...clickedOrder?.delivered,
    datetime_str: clickedOrder?.delivered
      ? deliverDatetime
      : "",
  };

  return {
    ...clickedOrder,
    requested: requested,
    approved: approved,
    delivered: delivered,
    request_no: clickedOrder?.code,
    status: clickedOrder?.status_name,
  };
}