import React, { useState, useCallback, useMemo } from "react";

// Common
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { ModConfirm, ModInfo } from "react-lib/apps/common";

// UX
import SubSearchPackageUX from "./SubSearchPackageUX";

// Interface
import {
  PACKAGE_SEARCH_ID,
  RunSequence,
  State,
} from "./sequence/SettingPackage";
import { CARD_SETTING_PACKAGE } from "./CardSettingPackage";

// Types
type SubSearchPackageProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: RunSequence;
  SettingPackageSequence?: State["SettingPackageSequence"];
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
};

// Const
const BUTTON_ACTIONS = {
  search: "SEARCH",
  delete: "DELETE",
};

const SUB_SEARCH_PACKAGE = "SubSearchPackage";

const SubSearchPackage = (props: SubSearchPackageProps) => {
  const [openModDelete, setOpenModDelete] = useState<boolean>(false);

  const searchedItemListWithKey = useMemo(() => {
    return {
      ...props.searchedItemListWithKey,
      [PACKAGE_SEARCH_ID]: props.searchedItemListWithKey?.[
        PACKAGE_SEARCH_ID
      ]?.map((item: any) => ({
        ...item,
        name_code: `[${item.code}] ${item.name}`,
      })),
    };
  }, [props.searchedItemListWithKey]);

  // Use Callback
  const handleSelectedItem = useCallback(
    async (value: any, key: any, searchText?: string) => {
      props.runSequence({
        sequence: "SettingPackage",
        action: "SELECT_PACKAGE",
        packageId: key,
      });
    },
    [props.searchedItemListWithKey]
  );

  // Handler
  const handleAddNewPackage = async () => {
    await handleClear();

    const settingPackage = {
      ...props.SettingPackageSequence,
      showDetail: true,
      showRequiredField: {},
      packageDetail: { items: [], can_delete: true, gender: "NA" },
    };

    await props.setProp("SettingPackageSequence", settingPackage);
  };

  const handleCloseModNotFound = () => {
    props.setProp(
      `errorMessage.${SUB_SEARCH_PACKAGE}_${BUTTON_ACTIONS.search}`,
      null
    );
  };

  const handleClear = async () => {
    if (!!props.SettingPackageSequence) {
      const settingPackage = {
        ...props.SettingPackageSequence,
        showDetail: false,
        selectedPackage: null,
        packageDetail: {},
        showRequiredField: {},
      };

      await props.setProp("SettingPackageSequence", settingPackage);

      await props.setProp(`searchedItemListWithKey.${PACKAGE_SEARCH_ID}`, []);
    }
  };

  const handleDelete = () => {
    setOpenModDelete(true);
  };

  const handleConfirmDelete = () => {
    props.runSequence({
      sequence: "SettingPackage",
      action: "DELETE",
      card: SUB_SEARCH_PACKAGE,
      errorKey: CARD_SETTING_PACKAGE,
      onSuccess: handleCloseModDelete,
    });
  };

  const handleCloseModDelete = () => {
    setOpenModDelete(false);
  };

  return (
    <div>
      <SubSearchPackageUX
        // data
        allowDelete={
          !!props.SettingPackageSequence?.packageDetail?.can_delete &&
          !!props.SettingPackageSequence?.packageDetail?.id
        }
        // callback
        onAddNewPackage={handleAddNewPackage}
        onClear={handleClear}
        onDelete={handleDelete}
        // Element
        SearchBox={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            placeholder="package code, package name"
            // config
            type="Package"
            id="SSP"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            clearable={false}
            search={!props.SettingPackageSequence?.selectedPackage}
            noResultsMessage={
              <>
                ไม่พบรายการที่ค้นหา{" "}
                <strong style={{ marginLeft: "0.5rem" }}>
                  "กรุณาเพิ่มแพกเกจใหม่"
                </strong>
              </>
            }
            // Select
            searchedItemListWithKey={searchedItemListWithKey}
            selectedItem={props.SettingPackageSequence?.selectedPackage || null}
            setSelectedItem={handleSelectedItem}
          />
        }
        // ButtonSearch={
        // <ButtonLoadCheck
        //   // function
        //   setProp={props.setProp}
        //   onClick={handleSearch}
        //   // data
        //   paramKey={`${SUB_SEARCH_PACKAGE}_${BUTTON_ACTIONS.search}`}
        //   buttonLoadCheck={
        //     props.buttonLoadCheck?.[
        //       `${SUB_SEARCH_PACKAGE}_${BUTTON_ACTIONS.search}`
        //     ]
        //   }
        //   // config
        //   color={"blue"}
        //   name={BUTTON_ACTIONS.search}
        //   size="medium"
        //   title="ค้นหา"
        // />
        // }
      />

      <ModInfo
        open={
          props.errorMessage?.[
            `${SUB_SEARCH_PACKAGE}_${BUTTON_ACTIONS.search}`
          ] === "NOT_FOUND"
        }
        titleColor={"red"}
        titleName="แจ้งเตือน!"
        btnText="ปิด"
        onApprove={handleCloseModNotFound}
        onClose={handleCloseModNotFound}
      >
        <div
          style={{ padding: "0.5rem 0", fontWeight: "bold", lineHeight: 1.75 }}
        >
          <div>ไม่พบรายการที่ค้นหา</div>
          <div>กรุณาเพิ่มแพกเกจใหม่</div>
        </div>
      </ModInfo>

      <ModConfirm
        openModal={openModDelete}
        titleName="ยืนยันการลบแพกเกจ"
        titleColor="red"
        size="mini"
        denyButtonColor="red"
        denyButtonText="ยกเลิก"
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirmDelete}
            // data
            paramKey={`${SUB_SEARCH_PACKAGE}_${BUTTON_ACTIONS.delete}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${SUB_SEARCH_PACKAGE}_${BUTTON_ACTIONS.delete}`
              ]
            }
            // config
            color={"green"}
            name={BUTTON_ACTIONS.delete}
            size="medium"
            title="ตกลง"
            basic={true}
          />
        }
        content={
          <div
            style={{
              margin: "-0.5rem 0rem -1rem 0rem",
              textAlign: "center",
              fontSize: "1.2rem",
            }}
          >
            “{props.SettingPackageSequence?.packageDetail?.name}”
          </div>
        }
        onDeny={handleCloseModDelete}
        onCloseWithDimmerClick={handleCloseModDelete}
      />
    </div>
  );
};

export default React.memo(SubSearchPackage);
