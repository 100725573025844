import React, { useState, useEffect } from 'react';
import { Dropdown, Button } from "semantic-ui-react";
import CardDivisionScheduleUX from "./CardDivisionScheduleUX";
import { parseDate, formatADtoBEString } from "react-lib/utils/dateUtils";
import { timeSlotList, DATE_FORMAT, dayNames, shortDayNames, engDayNames 
} from "react-lib/apps/Scheduling/common/Utils";
import moment from "moment";
import { ModInfo } from 'react-lib/apps/common';

const CardDivisionSchedule = (props: any) => {
  const slotOptions = timeSlotList(12, 156, 6);
  const blankTemplateData = () => 
    Array.from({length: 7}, (day: any, day_index: number) => (
      Array.from({length: 0}, (slot: any, slot_index: number) => (
        { start: null, end: null, exist: false }))));

  // Original template from server
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");

  // Template item data
  const [templateData, setTemplateData] = useState(blankTemplateData());

  // alert 
  const [showWarning, setShowWarning] = useState(false)

  const addSlot = (day_index: number) => {
    const count = templateData[day_index].length;
    if (templateData[day_index].length === 0 || 
        (templateData[day_index][count - 1].start
        && templateData[day_index][count - 1].end)
    ) {
      const newTemplateData: any[][] = Object.assign([], templateData);
      newTemplateData[day_index].push({start: null, end: null, exist: false});
      setTemplateData(newTemplateData);
    }
  }

  const setSlot = (key: string, day_index: number, slot_index: number, value: string) => {
    if (templateData[day_index][slot_index].exist) return
    const newTemplateData: any[][] = Object.assign([], templateData);
    newTemplateData[day_index][slot_index][key] = value;
    setTemplateData(newTemplateData);
  }

  const saveData = () => {

    if ( templateData.find(item => item.length !== 0) === undefined ) { 

      /// didn't select any time slot 

      setShowWarning(true)
      return 
    }


    let newItems = templateData.flatMap(
      (day: any, day_index: number) => (
        day.filter((slot: any) => !slot.exist)
           .filter((slot: any) => (slot?.start !== null && slot?.end !== null))
           .map((slot: any, slot_index: number) => (
              {
                weekday: engDayNames[day_index].toUpperCase(),
                start_time: slot.start + ":00",
                end_time: slot.end + ":00",
                patient_per_slot: 10,
                active: true,
                is_weekday: true,
              }
           ))
    ));
    const itemsWeekDay = newItems.map((slot: any, index: number) => 
      (slot.weekday.toLowerCase()))
    const workonData = Object.fromEntries(engDayNames.map((name: string, index: number) => (
      [`workOn${name.charAt(0).toUpperCase() + name.slice(1)}`, itemsWeekDay.includes(name)]
    )))
    console.log(workonData);
    props.onEvent({message: "CreateUpdateSchedule", params: {
      data: selectedTemplate?.id ?
      {
        id: selectedTemplate?.id,
        confirmed: true,
        patientPerSlot: 10,
        multi: true,
        items: newItems,
        check_overlap: false,
      }
      :
      {
        confirmed: true,
        division: props.selectedDivision?.id,
        patientPerSlot: 10,
        multi: true,
        startDate: startDateTime,
        endDate: endDateTime,
        providerTypeCategory: "opd_telemed",
        items: newItems,
        check_overlap: false,
        ...workonData,
      }
    }});
  }

  const clearData = () => {
    setSelectedTemplate(null);
    setStartDateTime("");
    setEndDateTime("");
    setTemplateData(blankTemplateData());
  }

  const changeDate = (key: string) => 
    (value: any) => {
      console.log(value);
      const newDateString = (parseDate(value, true) as moment.Moment).format(DATE_FORMAT);
      if (key === "startDateTime")
        setStartDateTime(newDateString)
      else if (key === "endDateTime")
        setEndDateTime(newDateString)
    }
      
  // Load template from server
  useEffect(() => {
    props.onEvent({message: "GetListSchedule", 
                   params: {divisionId: props.selectedDivision?.id}}); 
  }, [props.selectedDivision]);

  // Update selectedTemplate with new data from props.scheduleTemplates
  useEffect(() => {
    if (props.scheduleTemplates?.length === 0) {
      clearData();
    } else {
      if (selectedTemplate?.id) {
        let newTemplate = props.scheduleTemplates.find((template: any) => 
          template?.id === selectedTemplate.id)
        if (newTemplate) {
          setSelectedTemplate(newTemplate);
          return
        }
      }
      setSelectedTemplate(props.scheduleTemplates[props.scheduleTemplates.length - 1]);
    }
  }, [props.scheduleTemplates])

  // Transform template from server to templatedata
  useEffect(() => {
    if (selectedTemplate) {
      const newData = selectedTemplate.items.reduce(
        (acc: any, cur: {weekday: string, start_time: string, end_time: string}) => {
          const dayIndex = engDayNames.indexOf(cur.weekday.toLowerCase());
          acc[dayIndex].push({
            start: cur.start_time.substring(0, 5), 
            end: cur.end_time.substring(0, 5),
            exist: true,
          })
          return acc;
        }, [[], [], [], [], [], [], []]);
      setTemplateData(newData);
      setStartDateTime(selectedTemplate?.start_datetime || "");
      setEndDateTime(selectedTemplate?.end_datetime || "");
    } else {
      clearData()
    }
  }, [selectedTemplate]);

  // Transform template from server for display
  const scheduleTemplates = (props.scheduleTemplates || []).map((template: any) => ({
    ...template,
    start: formatADtoBEString(`${template.start_datetime.split("T")[0]}`),
    end: formatADtoBEString(`${template.end_datetime.split("T")[0]}`),
    days: engDayNames
      .map((name: string) => `work_on_${name}`)
      .map((key: string) => template?.[key] || false)
      .map((open: boolean, index: number) => (open ? shortDayNames[index] : null))
      .filter((item: any) => item)
      .join(", "),
  }));
  
  return(
    <>
    <ModInfo
      open={showWarning || false}
      titleColor={"blue"}
      titleName={"แจ้งเตือน"}
      alertText={"กรุณาเพิ่มรายการก่อนกด อัพเดท (SAVE) "}
  
      onApprove={() => setShowWarning(false)}
      onClose={() => setShowWarning(false)}
      closeOnDimmerClick
      style={{ margin: "auto" }}
    > 
    <br/> 
    </ModInfo>
    <CardDivisionScheduleUX 
      scheduleTemplates={scheduleTemplates}
      templateRowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
        style: { 
          backgroundColor: selectedTemplate && 
                           rowInfo?.original?.id === selectedTemplate?.id ? 
                           "#cccccc" : "#d6ecf3"
        },
        onClick: () => setSelectedTemplate(rowInfo?.original)
      })}
      startDateTime={formatADtoBEString(moment(startDateTime).format(DATE_FORMAT))}
      endDateTime={formatADtoBEString(moment(endDateTime).format(DATE_FORMAT))}
      changeDate={changeDate}
      onSave={saveData}
      onClear={clearData}
      editMode={Number.isInteger(selectedTemplate?.id)}
      weekTemplate={
        <div style={{display: "flex", width: "100%"}}>
        {templateData.map((day: any, day_index: number) => (
          <div key={day_index} 
            style={{
              flex: 1, padding: "15px", display: "flex", 
              flexDirection: "column"
            }}>
            <b 
              style={{
                fontSize:"1.2rem", textAlign: "center", 
                borderBottom: "solid #cccccc 1px", paddingBottom: "20px", marginBottom: "20px"
              }}>
              {dayNames[day_index]}
            </b>
            {day.map((slot: any, slot_index: number) => (
              <div key={slot_index} style={{marginBottom: "40px"}}>
                <div>เวลาเริ่ม</div>
                <Dropdown fluid selection 
                  disabled={slot.exist}
                  options={slotOptions} 
                  value={slot.start}
                  onChange={(e: any, v: any) => setSlot("start", day_index, slot_index, v.value)}
                />
                <div>เวลาสิ้นสุด</div>
                <Dropdown fluid selection 
                  disabled={slot.exist}
                  options={slotOptions} 
                  value={slot.end} 
                  onChange={(e: any, v: any) => setSlot("end", day_index, slot_index, v.value)}
                />
              </div>
            )).concat(
              <Button size="tiny" color="green"
                onClick={() => addSlot(day_index)}>
                เพิ่มรายการ
              </Button>
            )}
          </div>
        ))}
        </div>}
    />
    </>
  )
}

export default CardDivisionSchedule;
