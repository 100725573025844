import React, { useMemo } from "react";
import { MOD_SOLVENT_SELECTION_ID } from "./ModSolventSelectionInterface";
import ModSolventSelectionUX from "./ModSolventSelectionUX";
import SnackMessage from "react-lib/apps/common/SnackMessage";

type ModSolventSelectionProps = {
  // function
  onEvent: any;
  setProp: any;

  // permission
  drugPermission?: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;

  // seq
  DrugSelectSequence?: any;

  // master data
  drugUnits?: any[];

  // selected data
  selectedDrug?: any;

  // data
  drugOrderType?: string | null;
  modSolventSelectionConfig?: any;
};

const ModSolventSelectionInitial: ModSolventSelectionProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // permission
  drugPermission: {},

  // CommonInterface
  errorMessage: null,
  successMessage: null,

  // seq
  DrugSelectSequence: {},

  // master data
  drugUnits: [],

  // selected data
  selectedDrug: {},

  // data
  drugOrderType: null,
  modSolventSelectionConfig: {},
};

const ModSolventSelection: React.FC<ModSolventSelectionProps> = (
  props: any
) => {
  const txtConc = useMemo(() => {
    if (
      !parseInt(props.modSolventSelectionConfig?.soluteStockSize) &&
      !parseInt(props.modSolventSelectionConfig?.solventStockSize)
    ) {
      return "NO SOLUTE AND SOLVENT STOCK SIZE";
    } else if (!parseInt(props.modSolventSelectionConfig?.soluteStockSize)) {
      return "NO SOLUTE STOCK SIZE";
    } else if (!parseInt(props.modSolventSelectionConfig?.solventStockSize)) {
      return "NO SOLVENT STOCK SIZE";
    } else if (!parseInt(props.DrugSelectSequence?.old_quantity)) {
      return "NO SOLVENT QUANTITY";
    } else {
      return (
        (props.modSolventSelectionConfig?.soluteQuantity *
          parseInt(props.modSolventSelectionConfig?.soluteStockSize)) /
        (parseInt(props.DrugSelectSequence?.old_quantity) *
          parseInt(props.modSolventSelectionConfig?.solventStockSize))
      );
    }
  }, [props.DrugSelectSequence, props.modSolventSelectionConfig]);

  // change
  const handleChangeModData = (_event: any, data: any) => {
    props.onEvent({
      message: "SetModSolventSelectionData",
      params: { name: data.name, value: data.value },
    });
  };

  // search
  const handleSearchDrugSolvent = (_event: any, data: any) => {
    props.onEvent({
      message: "GetDrugSolventList",
      params: { name: data.searchQuery },
    });
  };

  const handleSelectedDrugSolvent = (_event: any, data: any) => {
    props.onEvent({
      message: "GetDrugSolventDetail",
      params: { id: data.value },
    });
  };

  // save
  const handleSaveSolventOldData = () => {
    props.onEvent({ message: "SaveSolventOldData" });
  };

  const handleSaveSolventData = () => {
    props.onEvent({
      message: "SaveSolventData",
      params: { drugOrderType: props.drugOrderType },
    });
  };

  const handleSaveQuantity = () => {
    props.setProp(
      "DrugSelectSequence.solvent_quantity",
      props.modSolventSelectionConfig?.quantity
    );
    props.setProp("DrugSelectSequence.is_intravaneous", true);

    props.onEvent({
      message: "EditDrugContinuePlan",
      params: { action: "EDIT_QUANTITY", card: MOD_SOLVENT_SELECTION_ID },
    });
  };

  const handleRemoveSolvent = () => {
    props.onEvent({ message: "RemoveSolventData" });
  };

  // close
  const handleCloseModSolventSelection = () => {
    props.onEvent({
      message: "SetModSolventSelectionConfig",
      params: { openModal: false },
    });
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {props.errorMessage?.[MOD_SOLVENT_SELECTION_ID] && (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            props.setProp(`errorMessage.${MOD_SOLVENT_SELECTION_ID}`, null);
          }}
          error={props.errorMessage?.[MOD_SOLVENT_SELECTION_ID]}
          success={props.successMessage?.[MOD_SOLVENT_SELECTION_ID]}
        />
      )}

      <ModSolventSelectionUX
        // function
        onChangeModData={handleChangeModData}
        onCloseModSolventSelection={handleCloseModSolventSelection}
        onSaveOldData={handleSaveSolventOldData}
        onSaveData={handleSaveSolventData}
        onSaveQuantity={handleSaveQuantity}
        onRemoveSolvent={handleRemoveSolvent}
        // search solvent
        onSearchDrugSolvent={handleSearchDrugSolvent}
        onSelectedDrugSolvent={handleSelectedDrugSolvent}
        // seq
        DrugSelectSequence={props.DrugSelectSequence}
        // Master
        drugUnits={props.drugUnits || []}
        // permission
        drugPermission={props.drugPermission}
        // data
        modSolventSelectionConfig={props.modSolventSelectionConfig}
        drugOrderType={props.drugOrderType}
        txtConc={txtConc}
      />
    </div>
  );
};

ModSolventSelection.defaultProps = ModSolventSelectionInitial;

export default React.memo(ModSolventSelection);
