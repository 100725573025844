import React, { useEffect, useState, useMemo, useRef } from "react";
import moment from "moment";

import CardDrugReturnRequestHistoryUX from "./CardDrugReturnRequestHistoryUX";
import CardReturnDrugWorking from "./CardReturnDrugWorking"
import { PatientSearchBox } from "react-lib/apps/common";

const ColumnStyle = {
  marginTop: -7,
  marginLeft: -4,
  width: "calc(100% + 10px)",
  height: "calc(100% + 14px)",
  padding: "7px 7px",
};

const SEMANTIC_COLOR = {
  blue: "rgba(33, 133, 208, 1)",
  red: "rgba(219, 40, 40, 0.2)",
  yellow: "rgba(251, 189, 8, 0.2)",
  green: "rgba(33, 186, 69, 0.2)",
  white: "rgb(255, 255, 255)",
};
const STATUS_COLOR: Record<string, string> = {
  REQUESTED: SEMANTIC_COLOR.red,
  APPROVED: SEMANTIC_COLOR.yellow,
  DELIVERED: SEMANTIC_COLOR.green,
  RECEIVED: SEMANTIC_COLOR.blue,
};

const get_status_color = (status: string) => {
  return STATUS_COLOR[status] || STATUS_COLOR[""];
};

const CARD_NAME = "CardDrugReturnRequestHistory"
const CardDrugReturnRequestHistory = (props: any) => {
  const isMounted = React.useRef(false);
  const [nationalities, setNationalities] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patientList, setPatientList] = useState<any>([]);
  const [currentHNPage, setCurrentHNPage] = useState(1);
  const [numberOfHNPage, setNumberOfHNPage] = useState(1);
  const patientRef = React.useRef();

  useEffect(() => {
    props.runSequence({
      sequence: "DrugReturnRequestHistory",
      restart: true,
    });
    isMounted.current = true;
    return () => {
      props.runSequence({
        sequence: "DrugReturnRequestHistory",
        clear: true,
      });
      isMounted.current = false;
    };
  }, []);

  const divisionOptions = useMemo(() => {
    return [
      {
        key: "แสดงทุกหน่วยงาน",
        value: "แสดงทุกหน่วยงาน",
        text: "แสดงทุกหน่วยงาน",
      },
      ...(props.masterOptions?.division || []),
    ].map((item: any) => ({
      key: item.key,
      value: item.value,
      text: item.text,
    }));
  }, [props.masterOptions?.division]);

  const dataDrugReturnRequestHistoryList = useMemo(() => {
    return (props.DrugReturnRequestHistoryList?.items || []).map(
      (item: any, index: number) => ({
        ...item,
        code: item.code,
        orderCode: item.order_code,
        patientName: item.patient_name_code,
        requesterName: item.requester_name,
        statusName: item.status_name,
        listDrug: item.items
          .map(
            (acc: any) =>
              `[${acc.code}] ${acc.name} (${acc.quantity_restock || 0}/${
                acc.quantity_request || 0
              })`
          )
          .join(),
        request: item.requested?.datetime ? (
          <div
            style={{
              ...ColumnStyle,
              backgroundColor:
                item.requested?.datetime &&
                props?.DrugReturnRequestHistorySequence?.selectedStatus === 1
                  ? get_status_color("REQUESTED")
                  : SEMANTIC_COLOR.white,
            }}
          >
            {moment(item.requested?.datetime).format("HH:mm")}
          </div>
        ) : null,
        approve: item.approved?.datetime ? (
          <div
            style={{
              ...ColumnStyle,
              backgroundColor:
                item.approved?.datetime &&
                props?.DrugReturnRequestHistorySequence?.selectedStatus === 2
                  ? get_status_color("APPROVED")
                  : SEMANTIC_COLOR.white,
            }}
          >
            {moment(item.approved?.datetime).format("HH:mm")}
          </div>
        ) : null,
        delivery: item.delivered?.datetime ? (
          <div
            style={{
              ...ColumnStyle,
              backgroundColor:
                item.delivered?.datetime &&
                props?.DrugReturnRequestHistorySequence?.selectedStatus === 3
                  ? get_status_color("DELIVERED")
                  : SEMANTIC_COLOR.white,
            }}
          >
            {moment(item.delivered?.datetime).format("HH:mm")}
          </div>
        ) : null,
        receive: item.received?.datetime ? (
          <div
            style={{
              ...ColumnStyle,
              backgroundColor:
                item.received?.datetime &&
                props?.DrugReturnRequestHistorySequence?.selectedStatus === 4
                  ? get_status_color("RECEIVED")
                  : SEMANTIC_COLOR.white,
            }}
          >
            {moment(item.received?.datetime).format("HH:mm")}
          </div>
        ) : null,
      })
    );
  }, [props.DrugReturnRequestHistoryList?.items]);

  const handleChangeValue =
    (key: string, keyChecked: string) => (e: any, v: any) => {
      if (key === "selectedDivisionReturn" && v.value === "แสดงทุกหน่วยงาน") {
        props.setProp("DrugReturnRequestHistorySequence", {
          ...props.DrugOrderHistorySequence,
          sequenceIndex: "SearchDrugReturnRequestHistory",
          checkedDivisionReturn: false,
          selectedDivisionReturn: v.value,
        });
      } else if (
        key === "selectedDivisionReceive" &&
        v.value === "แสดงทุกหน่วยงาน"
      ) {
        props.setProp("DrugReturnRequestHistorySequence", {
          ...props.DrugOrderHistorySequence,
          sequenceIndex: "SearchDrugReturnRequestHistory",
          checkedDivisionReceive: false,
          selectedDivisionReceive: v.value,
        });
      } else if (key === "selectedStatus" && v.value === "แสดงทุกสถานะ") {
        props.setProp("DrugReturnRequestHistorySequence", {
          ...props.DrugOrderHistorySequence,
          sequenceIndex: "SearchDrugReturnRequestHistory",
          checkedStatus: false,
          selectedStatus: v.value,
        });
      } else {
        props.setProp("DrugReturnRequestHistorySequence", {
          ...props.DrugOrderHistorySequence,
          sequenceIndex: "SearchDrugReturnRequestHistory",
          [keyChecked]: true,
          [key]: v.value,
        });
      }
    };

  const handleChangeChecked =
    (key: string) =>
    (e: any, { checked }) => {
      props.setProp(`DrugReturnRequestHistorySequence.${key}`, checked);
    };

  const handleChangeDate = (key: string, keyChecked: string) => (date: any) => {
    props.setProp(`DrugReturnRequestHistorySequence.${key}`, date);
  };

  const getNationality = async () => {
    const [data, error] = await props.controller.getNationalities();
    if (isMounted.current) {
      if (data) {
        setNationalities(data);
      } else {
        setNationalities([]);
      }
    }
  };

  const getPatientList = async ({
    hn,
    fullName,
    citizenId,
    nationality,
    patientCurrentPage,
  }: any = {}) => {
    let limit = 40;
    let page = patientCurrentPage ? patientCurrentPage : currentHNPage;
    setIsLoading(true);
    setCurrentHNPage(page);
    const [data, error] = await props.controller.getSearchAllPatient({
      hn,
      fullName,
      citizenId,
      nationality,
      is_old_name_search: true,
      limit,
      offset: (page - 1) * limit,
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        setPatientList(data.items);
        setNumberOfHNPage(parseInt(data.total) / limit + 1);
      } else {
        setPatientList([]);
        setNumberOfHNPage(1);
      }
    }
    return [data, error];
  };

  const handlePaginationChange = async ({
    hn,
    fullName,
    citizenId,
    nationality,
    patientCurrentPage,
  }: any = {}) => {
    setCurrentHNPage(patientCurrentPage);
    getPatientList({
      hn,
      fullName,
      citizenId,
      nationality,
      patientCurrentPage,
    });
  };

  const handleGetPatientByHN = async (hn) => {
    const [data, error, network] = await props.controller.getPatientByHN({
      hn,
    });
    return [data, error, network];
  };

  const handleOnEnterPatientSearch = (
    id: number,
    hn: string,
    full_name: string
  ) => {
    if (isMounted.current) {
      props.setProp("DrugReturnRequestHistorySequence", {
        ...props.DrugOrderHistorySequence,
        sequenceIndex: "SearchDrugReturnRequestHistory",
        checkedPatient: true,
        selectedPatient: id,
      });
    }
  };

  return (
    <>
      <CardDrugReturnRequestHistoryUX
        //options
        divisionOptions={divisionOptions}
        statuOptions={[
          { key: "แสดงทุกสถานะ", value: "แสดงทุกสถานะ", text: "แสดงทุกสถานะ" },
          { key: 1, value: 1, text: "REQUESTED" },
          { key: 2, value: 2, text: "APPROVED" },
          { key: 3, value: 3, text: "DELIVERED" },
          { key: 4, value: 4, text: "RECEIVED" },
          { key: 5, value: 5, text: "REJECTED" },
          { key: 6, value: 6, text: "CENCELED" },
        ]}
        dataDrugReturnRequestHistoryList={dataDrugReturnRequestHistoryList}
        selectedDivisionReturn={
          props?.DrugReturnRequestHistorySequence?.selectedDivisionReturn
        }
        selectedDivisionReceive={
          props?.DrugReturnRequestHistorySequence?.selectedDivisionReceive
        }
        selectedStartDate={
          props?.DrugReturnRequestHistorySequence?.selectedStartDate
        }
        selectedEndDate={
          props?.DrugReturnRequestHistorySequence?.selectedEndDate
        }
        selectedStatus={props?.DrugReturnRequestHistorySequence?.selectedStatus}
        selectedPatient={
          props?.DrugReturnRequestHistorySequence?.selectedPatient
        }
        checkedStatus={props?.DrugReturnRequestHistorySequence?.checkedStatus}
        checkedPatient={props?.DrugReturnRequestHistorySequence?.checkedPatient}
        checkedDivisionReturn={
          props?.DrugReturnRequestHistorySequence?.checkedDivisionReturn
        }
        checkedDivisionReceive={
          props?.DrugReturnRequestHistorySequence?.checkedDivisionReceive
        }
        checkedFilterDate={
          props?.DrugReturnRequestHistorySequence?.checkedFilterDate
        }
        patientSearch={
          <PatientSearchBox
            ref={patientRef}
            patientList={patientList}
            nationalitiesList={nationalities}
            patientListLoading={isLoading}
            numberOfPage={numberOfHNPage}
            currentPage={currentHNPage}
            getNationality={getNationality}
            getPatientList={getPatientList}
            onEnter={handleOnEnterPatientSearch}
            onSelectPatient={handleOnEnterPatientSearch}
            onGetPatientByHN={handleGetPatientByHN}
            onPatientListPaginationChange={handlePaginationChange}
          />
        }
        handleChangeValue={handleChangeValue}
        handleChangeChecked={handleChangeChecked}
        handleChangeDate={handleChangeDate}
        OnSearch={() =>
          props.runSequence({
            sequence: "DrugReturnRequestHistory",
            action: "search",
          })
        }
        OnClear={() =>
          props.runSequence({
            sequence: "DrugReturnRequestHistory",
            action: "clear",
          })
        }
        getTrProps={(state: any, rowInfo: any, column: any, instance: any) => ({
          style: {
            backgroundColor:
              rowInfo?.original?.id &&
              rowInfo?.original?.id ===
                props.DrugReturnRequestHistorySequence?.selectedBox?.id
                ? "#cccccc"
                : "white",
          },
          onClick: () => {
            let clickedOrder = rowInfo?.original;
            let requested = {
              ...clickedOrder?.requested,
              datetime_str: moment(
                rowInfo?.original?.requested?.datetime
              ).format("DD/MM/YYYY HH:mm"),
            };
            let approved = {
              ...clickedOrder?.approved,
              datetime_str: clickedOrder?.approved
                ? moment(rowInfo?.original?.approved?.datetime).format(
                    "DD/MM/YYYY HH:mm"
                  )
                : "",
            };
            let delivered = {
              ...clickedOrder?.delivered,
              datetime_str: clickedOrder?.delivered
                ? moment(rowInfo?.original?.delivered?.datetime).format(
                    "DD/MM/YYYY HH:mm"
                  )
                : "",
            };
            clickedOrder = {
              ...clickedOrder,
              requested: requested,
              approved: approved,
              delivered: delivered,
            };

            props.runSequence({
              sequence: "DrugReturnRequestHistory",
              action: "SET_SELECTED",
              selectedBox: clickedOrder,
              card: CARD_NAME,
            });
          },
        })}
      />

    <CardReturnDrugWorking
        // seq
        setProp={props.setProp}
        onEvent={props.onEvent}
        runSequence={props.runSequence}
        DrugReturnRequestHistorySequence={props.DrugReturnRequestHistorySequence}
        // buttonLoadCheck={props.buttonLoadCheck}
        // errorMessage={props.errorMessage}
      />
    </>
  );
};

export default CardDrugReturnRequestHistory;
