import React from "react";

// UX
import CardEssentialImagingUX from "./CardEssentialImagingUX";
import {
  ACTION,
  checkHideButton,
} from "./CardOtherForm";
import { PreOPButtonAction } from "./CardPreOPVisit";

export const ESSENTIAL_IMAGING = "essentialImaging";
const CARD_ESSENTIAL_IMAGING = "CardEssentialImaging";

const CardEssentialImaging = (props: any) => {
  const handleSave = (action: string) => (e: any, v: any) => {
    props.onSave(action)(e, {
      ...v,
      formCode: CARD_ESSENTIAL_IMAGING,
      formName: "Essentail Imaging",
      key: ESSENTIAL_IMAGING,
    });
  };

  console.log(props)

  return (
    <>
      <CardEssentialImagingUX
        // data
        specialEquipment={props.data?.data?.special_equipment}
        status={props.data?.status}
        // options
        specialEquipmentOptions={props.masterOptions?.specialEquipment}
        // callback
        onChangeData={props.onChangeData}
        // config
        config={{
          hideStatus: checkHideButton(props.data, "STATUS"),
        }}
        // Element
        ButtonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [ESSENTIAL_IMAGING]: props.data }}
            type="save"
            cardKey={ESSENTIAL_IMAGING}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.SAVE)}
          />
        }
        ButtonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [ESSENTIAL_IMAGING]: props.data }}
            type="confirm"
            cardKey={ESSENTIAL_IMAGING}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.CONFIRM)}
          />
        }
        ButtonUnConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [ESSENTIAL_IMAGING]: props.data }}
            type="unconfirm"
            cardKey={ESSENTIAL_IMAGING}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.UNCONFIRM)}
          />
        }
      />
    </>
  );
};

export default React.memo(CardEssentialImaging);
