import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import CardDrugTransportationUX from "react-lib/apps/HISV3/TPD/CardDrugTransportationUX";
import {
  Divider,
  Checkbox,
  Modal,
  Input,
  Button,
  Dropdown,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import "./CardDrugTransportation.scss";
import { param } from "jquery";

const styles = {
  button: {
    padding: "14px 0",
    minWidth: "150px",
    borderRadius: "10px",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
  } as CSSProperties,
};

const CardDrugTransportation = (props: any) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [tracking, setTracking] = useState("");
  const [noneTracking, setNoneTracking] = useState(false);
  const [dropdownSelete, setDropdownSelete] = useState<any>("");
  const [openTracking, setOpenTracking] = useState<boolean>(false);
  const [infoPopup, setInfoPopup] = useState<any>({
    confirmState: false,
    update: true,
  });
  const [selectedDrugOrder, setSelectedDrugOrder] = useState<any>(null);
  const [isReadOnly, setIsReadOnly] = useState<Boolean>(true);
  const [encounterId, setEncounterId] = useState<any>("");
  useEffect(() => {
    //get from URL if have
    const params = new URLSearchParams(window.location.search);
    const encounter_id = params.get("encounter_id");
    setEncounterId(params.get("encounter_id"));
    setOpenTracking(false);
    props.runSequence({
      sequence: "Transportation",
      restart: true,
      ...(encounter_id && { encounter_id }),
    });
    return () => {
      props.runSequence({ sequence: "Transportation", clear: true });
    };
  }, []);

  const deliveryStatusOptions = [
    { key: 1, text: "รอจัดส่ง", value: "INITIAL" },
    { key: 2, text: "กำลังหาคนขับ", value: "ASSIGN_DRIVER" },
    { key: 3, text: "คนขับกำลังไปรับยา", value: "WAIT_DRIVER" },
    { key: 4, text: "คนขับรับยาแล้ว", value: "PICKED_UP" },
    // { key: 5, text: "คนขับรับยาแล้ว มากกว่า 2 วัน", value: 5 },
    { key: 6, text: "คนไข้ได้รับยาแล้ว", value: "DELIVERED" },
    { key: 7, text: "จัดส่งไม่สำเร็จ", value: "DELIVERY_FAILED" },
    { key: 8, text: "ยกเลิกโดยโรงพยาบาล", value: "CANCELED" },
    { key: 9, text: "ยกเลิกโดยคนขับ", value: "REJECT_DRIVER" },
    { key: 10, text: "เรียกรถเข้ารับไม่สำเร็จ", value: "ERROR" },
  ];

  const fastCompanyId = useMemo(() => {
    if (Array.isArray(props.masterData?.shippingCompany)) {
      let lalaCompanyId = props.masterData?.shippingCompany?.find(
        (item: any) => item.code === "Lala"
      )?.id;
      console.log("Found Lala Id");
      return lalaCompanyId;
    }
    return 1;
  }, [props.masterData?.shippingCompany]);

  const drugOrderList = useMemo(() => {
    let drugOrders = (props.TransportationSequence?.drugOrders || []).map(
      (item: any) => {
        return {
          ...item,
          delivery_type:
            item?.shipping_order?.shipping_company?.id == fastCompanyId ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "4px",
                }}
              >
                <img
                  alt="fast"
                  src="/static/images/transport/fast-delivery.png"
                  width="20px"
                />{" "}
                <span style={{ color: "red", fontWeight: "bolder" }}>
                  ส่งด่วน
                </span>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "4px",
                }}
              >
                <img
                  alt="fast"
                  src="/static/images/transport/normal-delivery.png"
                  width="20px"
                />{" "}
                <span style={{ color: "red", fontWeight: "bolder" }}>
                  ส่งธรรมดา
                </span>
              </div>
            ),
          delivery_status: item.shipping_order?.delivery_status,
          tracking_number: item.shipping_order?.tracking_number,
          delivery_datetime: item.shipping_order?.delivery_datetime,
          delivery_received_datetime:
            item.shipping_order?.delivery_received_datetime,
          delivery_address: item.shipping_order?.delivery_address,
        };
      }
    );

    console.log("drugOrders: ", drugOrders);
    return drugOrders;
  }, [
    props.TransportationSequence?.drugOrders,
    props.TransportationSequence?.listCheckbox,
  ]);

  const getTdProps = (state: any, rowInfo: any, column: any, instance: any) => {
    return {
      onClick: (event: any) => {
        if (column.id !== "Checkbox") {
          let rowId = rowInfo?.original?.id;
          console.log("getTdProps rowId: ", rowId);
          setSelectedRow(rowId);
          let drugOrder = props.TransportationSequence?.drugOrders?.find(
            (item: any) => item.id === rowId
          );
          setSelectedDrugOrder(drugOrder);
        }
      },

      className: rowInfo?.original?.id === selectedRow ? "blueSelectedRow" : "",
      ...(rowInfo && { style: { cursor: "pointer" } }),
    };
  };

  useEffect(() => {
    if (selectedRow) {
      props.runSequence({
        sequence: "Transportation",
        action: "getTransport",
        selectedRow: selectedRow,
      });
    }
  }, [selectedRow]);

  useEffect(() => {
    let isLala = false;
    if (
      props.TransportationSequence?.selectedDrugOrder?.shipping_company &&
      props.masterData?.shippingCompany?.length > 0
    ) {
      console.log(
        " print props.TransportationSequence?.selectedDrugOrder?.shipping_company: ",
        props.TransportationSequence?.selectedDrugOrder?.shipping_company
      );
      console.log(
        " print props.masterOptions.shippingCompany: ",
        props.masterOptions.shippingCompany
      );
      let a = props.masterData?.shippingCompany?.find(
        (item: any) =>
          item.id ===
          props.TransportationSequence?.selectedDrugOrder?.shipping_company
      );
      console.log("print a: ", a);
      isLala = a?.code === "Lala";
    }
    setIsReadOnly(isLala);
  }, [
    props.TransportationSequence?.selectedDrugOrder?.shipping_company,
    props.masterData?.shippingCompany,
  ]);

  const saveListCheckBox = (list: any) => {
    props.setProp("TransportationSequence.listCheckbox", list);
  };

  const columnsItem = useMemo(() => {
    const listCheckbox = props.TransportationSequence?.listCheckbox || [];
    return [
      {
        Header: () => (
          <div>
            <Checkbox
              checked={
                listCheckbox?.length ===
                  props.TransportationSequence?.drugOrders?.length &&
                listCheckbox?.length > 0
              }
              onChange={(e, data) => {
                if (data.checked) {
                  saveListCheckBox(props.TransportationSequence?.drugOrders);
                } else {
                  saveListCheckBox([]);
                }
              }}
            />
          </div>
        ),
        id: "Checkbox",
        accessor: "encounter_number",
        Cell: (row: any) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              checked={listCheckbox.some(
                (item: any) => item.id === row.original.id
              )}
              onChange={(e, data) => {
                if (data.checked) {
                  if (
                    !listCheckbox.some(
                      (item: any) => item.id === row.original.id
                    )
                  ) {
                    const addList = listCheckbox;
                    addList.push(row.original);
                    saveListCheckBox(addList);
                  }
                } else {
                  const addList = listCheckbox.filter(
                    (item: any) => item.id !== row.original.id
                  );
                  saveListCheckBox(addList);
                }
              }}
            />
          </div>
        ),
      },
      {
        Header: "EN",
        accessor: "encounter_number",
      },
      {
        Header: "ประเภทคนไข้",
        accessor: "encounter_number",
      },
      {
        Header: "ประเภทการจัดส่ง",
        accessor: "delivery_type",
      },
      {
        Header: "สถานะการจัดส่ง",
        accessor: "delivery_status",
      },
      {
        Header: "เลขที่จัดส่ง",
        accessor: "tracking_number",
      },
      {
        Header: "วันเวลาที่จัดส่ง",
        accessor: "delivery_datetime",
      },
      {
        Header: "วันเวลาที่รับยา",
        accessor: "delivery_received_datetime",
      },
      {
        Header: "ที่อยู่จัดส่ง",
        accessor: "delivery_address",
      },
    ];
  }, [
    props.TransportationSequence?.listCheckbox,
    props.TransportationSequence?.drugOrders,
  ]);

  const handleAddMultiUpdate = () => {
    if (infoPopup.update) {
      setOpenTracking(false);
      props.runSequence({
        sequence: "Transportation",
        action: "multiUpdateStatus",
        statusOrder: dropdownSelete,
        encounter_id: encounterId,
      });
    } else {
      const updateTrckingList: any[] = [];
      const newTrckingList: any[] = [];
      props.TransportationSequence?.listCheckbox?.forEach((item: any) => {
        if (item.shipping_order != null) {
          updateTrckingList.push(item.shipping_order.id);
        } else {
          newTrckingList.push(item.id);
        }
      });

      const encounterId =
        props.TransportationSequence?.listCheckbox?.[0].encounter_id;
      setOpenTracking(false);
      props.runSequence({
        sequence: "Transportation",
        action: "multiTracking",
        encounter_id: encounterId,
        trackingNumber: tracking,
        shippingCompany: dropdownSelete,
        ...(updateTrckingList.length > 0 && {
          updateTrckingList: updateTrckingList,
        }),
        ...(newTrckingList.length > 0 && { newTrckingList: newTrckingList }),
      });
    }
  };

  const handleCheckTracking = () => {
    if (infoPopup.update) {
      if (
        props.TransportationSequence?.listCheckbox?.some(
          (item: any) => item.shipping_order?.tracking_number != null
        )
      ) {
        setInfoPopup({ ...infoPopup, confirmState: true });
        setNoneTracking(true);
      } else {
        setInfoPopup({ ...infoPopup, confirmState: false });
        setNoneTracking(false);
      }
    } else {
      setInfoPopup({ ...infoPopup, confirmState: true });
      return true;
    }
  };

  const handleCheckDisableTracking = () => {
    if (
      props.TransportationSequence?.listCheckbox?.some(
        (item: any) => item.shipping_order != null && item.shipping_order?.tracking_number != "" 
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  console.log("isReadOnly", isReadOnly);

  return (
    <div id="drug-transportation">
      <Dimmer inverted active={props.TransportationSequence?.popUpLoading}>
        <Loader>Loading</Loader>
      </Dimmer>
      <CardDrugTransportationUX
        columnsItem={columnsItem}
        barOption={
          (props.TransportationSequence?.listCheckbox?.length || 0) > 0
        }
        totalList={`${
          props.TransportationSequence?.listCheckbox?.length || ""
        } รายการ`}
        isLoading={props.TransportationSequence?.isSaving}
        labelSave={
          props.TransportationSequence?.saveStatus === "SUCCESS"
            ? "บันทึกสำเร็จ"
            : props.TransportationSequence?.saveStatus === "ERROR"
            ? "บันทึกไม่สำเร็จ"
            : props.TransportationSequence?.saveStatus === "PENDING"
            ? "กำลังบันทึก"
            : "บันทึก"
        }
        colorSave={
          props.TransportationSequence?.saveStatus === "SUCCESS"
            ? "green"
            : props.TransportationSequence?.saveStatus === "ERROR"
            ? "red"
            : props.TransportationSequence?.saveStatus === "PENDING"
            ? "yellow"
            : "blue"
        }
        data={drugOrderList}
        readOnly={isReadOnly}
        getTdProps={getTdProps}
        divider={<Divider />}
        encounterType={selectedDrugOrder?.encounter_type}
        encounterNo={selectedDrugOrder?.encounter_number}
        deliveryDateTime={props.TransportationSequence?.delivery_datetime}
        receivedDateTime={
          props.TransportationSequence?.delivery_received_datetime
        }
        deliveryNo={
          props.TransportationSequence?.selectedDrugOrder?.tracking_number
        }
        deliveryType={props.TransportationSequence?.delivery_type}
        deliveryStatusOptions={deliveryStatusOptions}
        shippingCompanyOptions={props.masterOptions.shippingCompany}
        deliveryStatus={
          props.TransportationSequence?.selectedDrugOrder?.delivery_status
        }
        shippingCompany={
          props.TransportationSequence?.selectedDrugOrder?.shipping_company
        }
        telephoneNo={props.TransportationSequence?.telephoneNo}
        additionAddress={props.TransportationSequence?.additionAddress}
        addressDelivery={
          props.TransportationSequence?.selectedDrugOrder?.delivery_address
        }
        addressName={props.TransportationSequence?.addressName}
        name={props.TransportationSequence?.name}
        // onChange

        onChangeDeliveryNo={(e: any, { value }: any) => {
          props.setProp(
            "TransportationSequence.selectedDrugOrder.tracking_number",
            value
          );
        }}
        onChangeDeliveryStatus={(e: any, { value }: any) => {
          props.setProp(
            "TransportationSequence.selectedDrugOrder.delivery_status",
            value
          );
        }}
        onChangeShippingName={(e: any, { value }: any) => {
          props.setProp(
            "TransportationSequence.selectedDrugOrder.shipping_company",
            value
          );
        }}
        onChangeDeliveryAddress={(e: any, { value }: any) => {
          props.setProp(
            "TransportationSequence.selectedDrugOrder.delivery_address",
            value
          );
        }}
        onSave={() => {
          props.runSequence({
            sequence: "Transportation",
            action: "save",
            selectedRow: selectedRow,
            ...(encounterId && { encounter_id: encounterId }),
          });
        }}
        onClickTrackingOption={() => {
          setOpenTracking(true);
          setInfoPopup({
            confirmState: false,
            update: false,
          });
          setTracking("");
          setDropdownSelete("");
        }}
        onClickUpdateStatus={() => {
          setOpenTracking(true);
          setInfoPopup({
            confirmState: false,
            update: true,
          });
          setTracking("");
          setDropdownSelete("");
        }}
        disableTracking={handleCheckDisableTracking()}
      />

      <Modal
        open={openTracking}
        onClose={() => {
          setOpenTracking(false);
        }}
        style={{ width: "450px", borderRadius: "10px", position: "relative" }}
      >
        <div>
          <div style={{ padding: "30px" }}>
            <label
              style={{
                color: "#0147A3",
                fontSize: "20px",
                fontWeight: "bold",
                display: "flex",
                ...(infoPopup.confirmState && { justifyContent: "center" }),
              }}
            >
              {infoPopup.confirmState
                ? infoPopup.update
                  ? "ยืนยันการอัพเดตสถานะการจัดส่ง"
                  : "ยืนยันการอัพเดตหมายเลขจัดส่ง"
                : infoPopup.update
                ? "อัพเดตสถานะการจัดส่ง"
                : "แก้ไข Tracking no."}
            </label>
            {infoPopup.confirmState ? (
              <div
                style={{
                  margin: "32px 0",
                  whiteSpace: "pre-line",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  lineHeight: "19px",
                  fontSize: "16px",
                }}
              >
                {infoPopup.update
                  ? `คุณต้องการอัพเดตสถานะการจัดส่ง \nจำนวน ${
                      props.TransportationSequence?.listCheckbox?.length || ""
                    } รายการใช้หรือไม่`
                  : `คุณต้องการอัพเดตหมายเลขการจัดส่ง \nจำนวน ${
                      props.TransportationSequence?.listCheckbox?.length || ""
                    } รายการใช้หรือไม่`}
              </div>
            ) : (
              <>
                {infoPopup.update ? (
                  <div style={{ margin: "32px 0" }}>
                    <div style={{ marginTop: "20px" }}>
                      <label style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {"Status"}
                      </label>
                      <Dropdown
                        options={deliveryStatusOptions}
                        search
                        selection
                        style={{ marginLeft: "30px" }}
                        onChange={(e: any, { value }: any) => {
                          setDropdownSelete(value);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          whiteSpace: "pre-line",
                          color: "red",
                          textAlign: "center",
                          lineHeight: "19px",
                          ...(!noneTracking && { display: "none" }),
                        }}
                      >
                        {
                          "ไม่สามารถแก้ไขได้ \nเนื่องจากยังไม่ได้ใส่หมายเลชจัดส่ง"
                        }
                      </label>
                    </div>
                  </div>
                ) : (
                  <div style={{ margin: "32px 0" }}>
                    <div>
                      <label style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {"ระบุ Tracking no"}
                      </label>
                      <Input
                        style={{ marginLeft: "30px" }}
                        placeholder="Tracking..."
                        value={tracking}
                        onChange={(e: any) => setTracking(e.target.value)}
                      />
                    </div>

                    <div style={{ marginTop: "20px" }}>
                      <label style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {"ผู้ให้บริการจัดส่ง"}
                      </label>
                      <Dropdown
                        options={props.masterOptions?.shippingCompany}
                        search
                        selection
                        style={{ marginLeft: "30px" }}
                        onChange={(e: any, { value }: any) => {
                          setDropdownSelete(value);
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            )}

            <div
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              <div
                style={{
                  ...styles.button,
                  background: "#0147A3",
                  color: "white",
                }}
                onClick={() => {
                  infoPopup.confirmState
                    ? handleAddMultiUpdate()
                    : handleCheckTracking();
                }}
              >
                {infoPopup.confirmState ? "ใช่" : "บันทึก"}
              </div>
              <div
                style={{
                  ...styles.button,
                  color: "#0147A3",
                  border: "1px solid #0147A3",
                }}
                onClick={() => {
                  setOpenTracking(false);
                }}
              >
                {infoPopup.confirmState ? "ไม่ใช่" : "ยกเลิก"}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CardDrugTransportation;
