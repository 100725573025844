import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Input
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardImagingRequestUX = (props: any) => {
    return(
      <div
        style={{width: "100%", height: "100%", padding: "5px 15px"}}>
        <div
          style={{fontSize: "18px", fontWeight: "bold", padding: "10px 0px"}}>
          Imaging Request
        </div>
        <div
          style={{display: "flex", alignItems: "center", padding: "10px"}}>
          
          <div
            style={{paddingRight: "25px"}}>
            ชื่อรายการ
          </div>
          <div
            id="txt-searchImaging"
            style={{paddingRight: "25px"}}>
            {props.searchBox}
          </div>
          <Button
            color="blue"
            id="btn-searchImaging"
            onClick={props.onSearch}>
            ค้นหา
          </Button>
        </div>
        <div
          style={{paddingBottom: "25px"}}>
          
          <Table
            data={props.searchingData}
            getTrProps={props.getProductRow}
            headers="No,Service code,Product Name,ราคา"
            id="tb-imagingList"
            keys="number,service_code,name,price_unit"
            minRows={3}
            showPagination={false}
            style={{height: "150px"}}
            widths="70,200,,250">
          </Table>
        </div>
        <div
          style={{paddingBottom: "25px"}}>
          
          <Table
            data={props.productSelectedData}
            headers="No,Service code,Product Name,จำนวนสั่ง,หน่วยสั่ง,ราคาต่อหน่วย,ราคารวม,เบิกได้,เบิกไม่ได้,Eligibility Type,Del"
            id="tb-imagingOrder"
            keys="number,service_code,name,quantity,unit,price_unit,price_total,price_claimable,price_non_claimable,eligibility_type,_delete"
            minRows={3}
            showPagination={false}
            style={{height: "150px"}}
            widths="70,100,300,,,,,,,,">
          </Table>
        </div>
        <div
          className=" --readOnly --grey"
          style={{display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "0px"}}>
          
          <div
            style={{padding: "0px 10px 0px 25px"}}>
            ราคา
          </div>
          <div>
            {}
            <Input
              disabled={true}
              readOnly={true}
              value={props.totalPrice ?? 0}>
            </Input>
          </div>
          <div
            style={{padding: "0px 10px 0px 25px"}}>
            เบิกได้
          </div>
          <div>
            {}
            <Input
              disabled={true}
              readOnly={true}
              value={props.canClaim}>
            </Input>
          </div>
          <div
            style={{padding: "0px 10px 0px 25px"}}>
            เบิกไม่ได้
          </div>
          <div>
            {}
            <Input
              disabled={true}
              readOnly={true}
              value={props.unclaim}>
            </Input>
          </div>
        </div>
        <div
          style={{padding: "15px 0px", display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: "0.25rem"}}>
          
          <Button
            color="green"
            id="btn-saveImaging"
            onClick={props.onSaveOrder}>
            SAVE
          </Button>
        </div>
      </div>
    )
}


export default CardImagingRequestUX

export const screenPropsDefault = {}

/* Date Time : Fri Jul 14 2023 05:29:05 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", padding: \"5px 15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "Imaging Request"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"18px\", fontWeight: \"bold\", padding: \"10px 0px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", padding: \"10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingBottom: \"25px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingBottom: \"25px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": " --readOnly --grey"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"flex-end\", padding: \"0px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"15px 0px\", display: \"flex\", alignItems: \"center\", justifyContent: \"flex-end\", marginTop: \"0.25rem\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อรายการ"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: \"25px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchBox"
        },
        "id": {
          "type": "value",
          "value": "txt-searchImaging"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: \"25px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "btn-searchImaging"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 10,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.searchingData"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getProductRow"
        },
        "headers": {
          "type": "value",
          "value": "No,Service code,Product Name,ราคา"
        },
        "id": {
          "type": "value",
          "value": "tb-imagingList"
        },
        "keys": {
          "type": "value",
          "value": "number,service_code,name,price_unit"
        },
        "minRows": {
          "type": "code",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"150px\"}"
        },
        "widths": {
          "type": "value",
          "value": "70,200,,250"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 11,
      "name": "Table",
      "parent": 4,
      "props": {
        "data": {
          "type": "code",
          "value": "props.productSelectedData"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": "No,Service code,Product Name,จำนวนสั่ง,หน่วยสั่ง,ราคาต่อหน่วย,ราคารวม,เบิกได้,เบิกไม่ได้,Eligibility Type,Del"
        },
        "id": {
          "type": "value",
          "value": "tb-imagingOrder"
        },
        "keys": {
          "type": "value",
          "value": "number,service_code,name,quantity,unit,price_unit,price_total,price_claimable,price_non_claimable,eligibility_type,_delete"
        },
        "minRows": {
          "type": "code",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"150px\"}"
        },
        "widths": {
          "type": "value",
          "value": "70,100,300,,,,,,,,"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคา"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 10px 0px 25px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกได้"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 10px 0px 25px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกไม่ได้"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 10px 0px 25px\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Button",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "SAVE"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "id": {
          "type": "value",
          "value": "btn-saveImaging"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSaveOrder"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Input",
      "parent": 19,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.totalPrice ?? 0"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Input",
      "parent": 20,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.canClaim"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Input",
      "parent": 21,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.unclaim"
        }
      },
      "seq": 24,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": false,
  "name": "CardImagingRequestUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 75
}

*********************************************************************************** */
