import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Radio,
  Dropdown,
  Input
} from 'semantic-ui-react'

const ANES_PreANES_03_Airway = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", height: "100%" ,padding:"10px"}}>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",height:"105%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px"}}>
            
            <div
              style={{ display: props.PreAnestheticSequence?.status_label === "Confirm" ?  "none" : "",}}>
              {props.buttonSave}
            </div>
          </div>
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(20, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/6"}}>
              <label
                style={{display: "flex",height:"30px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Mallampati grade
              </label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "1/6"} }>
              <label
                style={{display: "flex",height:"30px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Patency of nares
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "1/8"}}>
              
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Dificult intubation expected
              </label>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "1/6"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Artificial airway
              </label>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "1/6"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Dental
              </label>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "1/6"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Systemic evaluation
              </label>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "1/6"}}>
              <label
                style={{display: "flex",height:"30px", fontSize:"16px",alignItems:"center",marginLeft:"5px"}}>
                Respiratory
              </label>
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "1/6"}}>
              <label
                style={{display: "flex",height:"30px", fontSize:"16px",alignItems:"center",marginLeft:"5px"}}>
                Cardiovascular
              </label>
            </div>
            <div
              style={{ gridRow: "9/10", gridColumn: "1/6" }}>
              <label
                style={{display: "flex",height:"30px", fontSize:"16px",alignItems:"center",marginLeft:"5px"}}>
                Neurologic
              </label>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "1/6" }}>
              <label
                style={{display: "flex",height:"30px", fontSize:"16px",alignItems:"center",marginLeft:"5px"}}>
                EENT
              </label>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "1/6"}}>
              <label
                style={{display: "flex",height:"30px", fontSize:"16px",alignItems:"center",marginLeft:"5px"}}>
                GI
              </label>
            </div>
            <div
              style={{ gridRow: "12/13", gridColumn: "1/6" }}>
              <label
                style={{display: "flex",height:"30px", fontSize:"16px",alignItems:"center",marginLeft:"5px"}}>
                Genitourinary
              </label>
            </div>
            <div
              style={{ gridRow: "13/14", gridColumn: "1/7" }}>
              <label
                style={{display: "flex",height:"30px", fontSize:"16px",alignItems:"center",marginLeft:"5px"}}>
                Endocrine and metobolic
              </label>
            </div>
            <div
              style={{ gridRow: "14/15", gridColumn: "1/6" }}>
              <label
                style={{display: "flex",height:"30px", fontSize:"16px",alignItems:"center",marginLeft:"5px"}}>
                Skin/ Brest
              </label>
            </div>
            <div
              style={{ gridRow: "15/16", gridColumn: "1/7" }}>
              <label
                style={{display: "flex",height:"30px", fontSize:"16px",alignItems:"center",marginLeft:"5px"}}>
                Immunology/ Hematology
              </label>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "8/14"}}>
              <Dropdown
                name="MallampatiGrade"
                onChange={props.onChangeData}
                options={props.masterOptions?.preanes_mallampati || []}
                selection={true}
                style={{Width: "200px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                value={props.PreAnestheticSequence?.data?.MallampatiGrade || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "8/14"}}>
              <Dropdown
                name="PatencyOfNares"
                onChange={props.onChangeData}
                options={props.masterOptions?.preanes_patency_nares || []}
                selection={true}
                style={{Width: "200px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                value={props.PreAnestheticSequence?.data?.PatencyOfNares || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "8/14"}}>
              <Dropdown
                name="DifficultIntubationExpected"
                onChange={props.onChangeData}
                options={props.masterOptions?.preanes_difficult || []}
                selection={true}
                style={{Width: "200px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                value={props.PreAnestheticSequence?.data?.DifficultIntubationExpected || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "15/20" }}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Thyromental distance
              </label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "15/20"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Prominent incisor
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "15/20"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Comment
              </label>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "21/27"}}>
              <Dropdown
                name="ThyromentalDistance"
                onChange={props.onChangeData}
                options={props.masterOptions?.preanes_thyromental || []}
                selection={true}
                style={{Width: "200px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                value={props.PreAnestheticSequence?.data?.ThyromentalDistance || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "21/27"}}>
              <Dropdown
                name="ProminentIncisor"
                onChange={props.onChangeData}
                options={props.masterOptions?.preanes_prominent || []}
                selection={true}
                style={{Width: "200px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                value={props.PreAnestheticSequence?.data?.ProminentIncisor || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "21/40"}}>
              <Input
                name="Comment"
                onChange={props.onChangeData}
                style={{height:"35px",width:"500px"}}
                value={props.PreAnestheticSequence?.data?.Comment || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "28/33"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Mouth opening
              </label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "28/33"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Limitation of head /neck motility
              </label>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "34/40"}}>
              <Dropdown
                name="MouthOpening"
                onChange={props.onChangeData}
                options={props.masterOptions?.preanes_mouth_opening || []}
                selection={true}
                style={{Width: "200px",minHeight:"35px" ,flex: "5 4 13.00%"}}
                value={props.PreAnestheticSequence?.data?.MouthOpening || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "34/40"}}>
              <Dropdown
                name="LimitationOfHeadNeckMotility"
                onChange={props.onChangeData}
                options={props.masterOptions?.preanes_limitation || []}
                selection={true}
                style={{minWidth: "100px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                value={props.PreAnestheticSequence?.data?.LimitationOfHeadNeckMotility || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "8/19"}}>
              <div
                style={{ display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                
                <Radio
                  checked={props.PreAnestheticSequence?.data?.ArtificialAirway === "none"}
                  label="None"
                  name="ArtificialAirway"
                  onChange={props.onChangeData}
                  style={{marginRight: "30px",fontSize:"16px"}}
                  value="none">
                </Radio>
                <Radio
                  checked={props.PreAnestheticSequence?.data?.ArtificialAirway === "ett"}
                  label="ETT"
                  name="ArtificialAirway"
                  onChange={props.onChangeData}
                  style={{marginRight: "30px",fontSize:"16px"}}
                  value="ett">
                </Radio>
                <Radio
                  checked={props.PreAnestheticSequence?.data?.ArtificialAirway === "tracheostomy_tube"}
                  label="Tracheostomy tube"
                  name="ArtificialAirway"
                  onChange={props.onChangeData}
                  style={{marginRight: "30px",fontSize:"16px"}}
                  value="tracheostomy_tube">
                </Radio>
              </div>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "19/20"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"Bold",alignItems:"center"}}>
                Comment
              </label>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "21/40"}}>
              <Input
                name="ArtificialAirwayIsCommentTube"
                onChange={props.onChangeData}
                style={{height:"35px",width:"500px"}}
                value={props.PreAnestheticSequence?.data?.ArtificialAirwayIsCommentTube || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "8/28"}}>
              <Input
                name="Dental"
                onChange={props.onChangeData}
                style={{height:"35px",width:"1000px"}}
                value={props.PreAnestheticSequence?.data?.Dental || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "8/14"}}>
              <Dropdown
                name="Respiratory"
                onChange={props.onChangeData}
                options={props.masterOptions?.preanes_respiratory || []}
                selection={true}
                style={{Width: "200px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                value={props.PreAnestheticSequence?.data?.Respiratory || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "8/14"}}>
              <Dropdown
                name="Cardiovascular"
                onChange={props.onChangeData}
                options={props.masterOptions?.preanes_cardiovascul || []}
                selection={true}
                style={{Width: "200px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                value={props.PreAnestheticSequence?.data?.Cardiovascular || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "9/10", gridColumn: "8/14" }}>
              <Dropdown
                name="Neurologic"
                onChange={props.onChangeData}
                options={props.masterOptions?.preanes_neurologic || []}
                selection={true}
                style={{Width: "200px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                value={props.PreAnestheticSequence?.data?.Neurologic || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "8/14"}}>
              <Dropdown
                name="EENT"
                onChange={props.onChangeData}
                options={props.masterOptions?.preanes_EENT || []}
                selection={true}
                style={{Width: "200px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                value={props.PreAnestheticSequence?.data?.EENT || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "8/14" }}>
              <Dropdown
                name="GI"
                onChange={props.onChangeData}
                options={props.masterOptions?.preanes_gi || []}
                selection={true}
                style={{Width: "200px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                value={props.PreAnestheticSequence?.data?.GI || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "12/13", gridColumn: "8/14"}}>
              <Dropdown
                name="Genitourinary"
                onChange={props.onChangeData}
                options={props.masterOptions?.preanes_genitourinar || []}
                selection={true}
                style={{Width: "200px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                value={props.PreAnestheticSequence?.data?.Genitourinary || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "13/14", gridColumn: "8/14" }}>
              <Dropdown
                name="EndocrineAndMetabolic"
                onChange={props.onChangeData}
                options={props.masterOptions?.preanes_Endocrine || []}
                selection={true}
                style={{Width: "200px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                value={props.PreAnestheticSequence?.data?.EndocrineAndMetabolic || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "14/15", gridColumn: "8/14" }}>
              <Dropdown
                name="SkinBreast"
                onChange={props.onChangeData}
                options={props.masterOptions?.preanes_skin_breast || []}
                selection={true}
                style={{Width: "200px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                value={props.PreAnestheticSequence?.data?.SkinBreast || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "15/16", gridColumn: "8/14"}}>
              <Dropdown
                name="ImmunologyHematology"
                onChange={props.onChangeData}
                options={props.masterOptions?.preanes_Immunology || []}
                selection={true}
                style={{Width: "200px",minHeight:"32px" ,flex: "5 4 13.00%"}}
                value={props.PreAnestheticSequence?.data?.ImmunologyHematology || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "15/20"}}>
              
              <Input
                disabled={props.PreAnestheticSequence?.data?.Respiratory !== "Abnormal"}
                name="RespiratoryRemark"
                onChange={props.onChangeData}
                style={{height:"35px",width:"500px"}}
                value={props.PreAnestheticSequence?.data?.RespiratoryRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "15/20"}}>
              
              <Input
                disabled={props.PreAnestheticSequence?.data?.Cardiovascular !== "Abnormal"}
                name="CardiovascularRemark"
                onChange={props.onChangeData}
                style={{height:"35px",width:"500px"}}
                value={props.PreAnestheticSequence?.data?.CardiovascularRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "9/10", gridColumn: "15/20"}}>
              
              <Input
                disabled={props.PreAnestheticSequence?.data?.Neurologic !== "Abnormal"}
                name="NeurologicRemark"
                onChange={props.onChangeData}
                style={{height:"35px",width:"500px"}}
                value={props.PreAnestheticSequence?.data?.NeurologicRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "15/20"}}>
              
              <Input
                disabled={props.PreAnestheticSequence?.data?.EENT !== "Abnormal"}
                name="EENTRemark"
                onChange={props.onChangeData}
                style={{height:"35px",width:"500px"}}
                value={props.PreAnestheticSequence?.data?.EENTRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "15/20"}}>
              
              <Input
                disabled={props.PreAnestheticSequence?.data?.GI !== "Abnormal"}
                name="GIRemark"
                onChange={props.onChangeData}
                style={{height:"35px",width:"500px"}}
                value={props.PreAnestheticSequence?.data?.GIRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "12/13", gridColumn: "15/20"}}>
              
              <Input
                disabled={props.PreAnestheticSequence?.data?.Genitourinary !== "Abnormal"}
                name="GenitourinaryRemark"
                onChange={props.onChangeData}
                style={{height:"35px",width:"500px"}}
                value={props.PreAnestheticSequence?.data?.GenitourinaryRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "13/14", gridColumn: "15/20"}}>
              
              <Input
                disabled={props.PreAnestheticSequence?.data?.EndocrineAndMetabolic !== "Abnormal"}
                name="EndocrineAndMetabolicRemark"
                onChange={props.onChangeData}
                style={{height:"35px",width:"500px"}}
                value={props.PreAnestheticSequence?.data?.EndocrineAndMetabolicRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "14/15", gridColumn: "15/20"}}>
              
              <Input
                disabled={props.PreAnestheticSequence?.data?.SkinBreast !== "Abnormal"}
                name="SkinBreastRemark"
                onChange={props.onChangeData}
                style={{height:"35px",width:"500px"}}
                value={props.PreAnestheticSequence?.data?.SkinBreastRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "15/16", gridColumn: "15/20"}}>
              
              <Input
                disabled={props.PreAnestheticSequence?.data?.ImmunologyHematology !== "Abnormal"}
                name="ImmunologyHematologyRemake"
                onChange={props.onChangeData}
                style={{height:"35px",width:"500px"}}
                value={props.PreAnestheticSequence?.data?.ImmunologyHematologyRemake || ""}>
              </Input>
            </div>
          </div>
        </div>
      </div>
    )
}

export default ANES_PreANES_03_Airway

export const screenPropsDefault = {}

/* Date Time : Wed Jan 04 2023 10:07:42 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" ,padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",height:\"105%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(20, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/6\"} "
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/8\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/6\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"1/6\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"1/6\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/13\", gridColumn: \"1/6\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"1/7\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/15\", gridColumn: \"1/6\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"15/16\", gridColumn: \"1/7\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"8/14\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"8/14\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"8/14\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"15/20\" }"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"15/20\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"15/20\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"21/27\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"21/27\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"21/40\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"28/33\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"28/33\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"34/40\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"34/40\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"8/19\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 86,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Radio",
      "parent": 87,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ArtificialAirway === \"none\""
        },
        "label": {
          "type": "value",
          "value": "None"
        },
        "name": {
          "type": "value",
          "value": "ArtificialAirway"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"30px\",fontSize:\"16px\"}"
        },
        "value": {
          "type": "value",
          "value": "none"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "Radio",
      "parent": 87,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ArtificialAirway === \"ett\""
        },
        "label": {
          "type": "value",
          "value": "ETT"
        },
        "name": {
          "type": "value",
          "value": "ArtificialAirway"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"30px\",fontSize:\"16px\"}"
        },
        "value": {
          "type": "value",
          "value": "ett"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Radio",
      "parent": 87,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ArtificialAirway === \"tracheostomy_tube\""
        },
        "label": {
          "type": "value",
          "value": "Tracheostomy tube"
        },
        "name": {
          "type": "value",
          "value": "ArtificialAirway"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"30px\",fontSize:\"16px\"}"
        },
        "value": {
          "type": "value",
          "value": "tracheostomy_tube"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"19/20\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"21/40\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"8/28\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "Systemic evaluation"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Mallampati grade"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"30px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "Patency of nares"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"30px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "Dificult intubation expected"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "Artificial airway"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "Dental"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "Respiratory"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"30px\", fontSize:\"16px\",alignItems:\"center\",marginLeft:\"5px\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Cardiovascular"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"30px\", fontSize:\"16px\",alignItems:\"center\",marginLeft:\"5px\"}"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "Neurologic"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"30px\", fontSize:\"16px\",alignItems:\"center\",marginLeft:\"5px\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "EENT"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"30px\", fontSize:\"16px\",alignItems:\"center\",marginLeft:\"5px\"}"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "GI"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"30px\", fontSize:\"16px\",alignItems:\"center\",marginLeft:\"5px\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "Genitourinary"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"30px\", fontSize:\"16px\",alignItems:\"center\",marginLeft:\"5px\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "Endocrine and metobolic"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"30px\", fontSize:\"16px\",alignItems:\"center\",marginLeft:\"5px\"}"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "label",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "Skin/ Brest"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"30px\", fontSize:\"16px\",alignItems:\"center\",marginLeft:\"5px\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "Immunology/ Hematology"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"30px\", fontSize:\"16px\",alignItems:\"center\",marginLeft:\"5px\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "label",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": "Thyromental distance"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"8/14\"}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"8/14\"}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"8/14\" }"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"8/14\"}"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"8/14\" }"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/13\", gridColumn: \"8/14\"}"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"8/14\" }"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/15\", gridColumn: \"8/14\" }"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"15/16\", gridColumn: \"8/14\"}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "Dropdown",
      "parent": 142,
      "props": {
        "name": {
          "type": "value",
          "value": "SkinBreast"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_skin_breast || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{Width: \"200px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.SkinBreast || \"\""
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "Dropdown",
      "parent": 141,
      "props": {
        "name": {
          "type": "value",
          "value": "EndocrineAndMetabolic"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_Endocrine || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{Width: \"200px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.EndocrineAndMetabolic || \"\""
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "Dropdown",
      "parent": 140,
      "props": {
        "name": {
          "type": "value",
          "value": "Genitourinary"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_genitourinar || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{Width: \"200px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Genitourinary || \"\""
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "Dropdown",
      "parent": 139,
      "props": {
        "name": {
          "type": "value",
          "value": "GI"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_gi || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{Width: \"200px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.GI || \"\""
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "Dropdown",
      "parent": 138,
      "props": {
        "name": {
          "type": "value",
          "value": "EENT"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_EENT || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{Width: \"200px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.EENT || \"\""
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "Dropdown",
      "parent": 137,
      "props": {
        "name": {
          "type": "value",
          "value": "Neurologic"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_neurologic || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{Width: \"200px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Neurologic || \"\""
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "Dropdown",
      "parent": 136,
      "props": {
        "name": {
          "type": "value",
          "value": "Cardiovascular"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_cardiovascul || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{Width: \"200px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Cardiovascular || \"\""
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "Dropdown",
      "parent": 135,
      "props": {
        "name": {
          "type": "value",
          "value": "Respiratory"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_respiratory || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{Width: \"200px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Respiratory || \"\""
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "label",
      "parent": 91,
      "props": {
        "children": {
          "type": "value",
          "value": "Comment"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "Dropdown",
      "parent": 19,
      "props": {
        "name": {
          "type": "value",
          "value": "PatencyOfNares"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_patency_nares || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{Width: \"200px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PatencyOfNares || \"\""
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "Dropdown",
      "parent": 20,
      "props": {
        "name": {
          "type": "value",
          "value": "DifficultIntubationExpected"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_difficult || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{Width: \"200px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.DifficultIntubationExpected || \"\""
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "Dropdown",
      "parent": 18,
      "props": {
        "name": {
          "type": "value",
          "value": "MallampatiGrade"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_mallampati || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{Width: \"200px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.MallampatiGrade || \"\""
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "Dropdown",
      "parent": 70,
      "props": {
        "name": {
          "type": "value",
          "value": "MouthOpening"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_mouth_opening || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{Width: \"200px\",minHeight:\"35px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.MouthOpening || \"\""
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "Limitation of head /neck motility"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "label",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": "Mouth opening"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "Input",
      "parent": 52,
      "props": {
        "name": {
          "type": "value",
          "value": "Comment"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"500px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Comment || \"\""
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "Dropdown",
      "parent": 51,
      "props": {
        "name": {
          "type": "value",
          "value": "ProminentIncisor"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_prominent || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{Width: \"200px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ProminentIncisor || \"\""
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "Prominent incisor"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "label",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "Comment"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"Bold\",alignItems:\"center\"}"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "Dropdown",
      "parent": 50,
      "props": {
        "name": {
          "type": "value",
          "value": "ThyromentalDistance"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_thyromental || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{Width: \"200px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ThyromentalDistance || \"\""
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "Dropdown",
      "parent": 71,
      "props": {
        "name": {
          "type": "value",
          "value": "LimitationOfHeadNeckMotility"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_limitation || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.LimitationOfHeadNeckMotility || \"\""
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "Dropdown",
      "parent": 143,
      "props": {
        "name": {
          "type": "value",
          "value": "ImmunologyHematology"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_Immunology || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{Width: \"200px\",minHeight:\"32px\" ,flex: \"5 4 13.00%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ImmunologyHematology || \"\""
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "Input",
      "parent": 92,
      "props": {
        "name": {
          "type": "value",
          "value": "ArtificialAirwayIsCommentTube"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"500px\"}",
          "valueEN": ""
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ArtificialAirwayIsCommentTube || \"\""
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "Input",
      "parent": 97,
      "props": {
        "name": {
          "type": "value",
          "value": "Dental"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"1000px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Dental || \"\""
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", marginBottom: \"10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "div",
      "parent": 183,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.status_label === \"Confirm\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"15/20\"}"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 186,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"15/20\"}"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"15/20\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"15/20\"}"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/13\", gridColumn: \"15/20\"}"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"15/20\"}"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/15\", gridColumn: \"15/20\"}"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"15/16\", gridColumn: \"15/20\"}"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 193,
      "name": "Input",
      "parent": 185,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Respiratory !== \"Abnormal\""
        },
        "name": {
          "type": "value",
          "value": "RespiratoryRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"500px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.RespiratoryRemark || \"\""
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 194,
      "name": "Input",
      "parent": 186,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Neurologic !== \"Abnormal\""
        },
        "name": {
          "type": "value",
          "value": "NeurologicRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"500px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.NeurologicRemark || \"\""
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 195,
      "name": "Input",
      "parent": 187,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.EENT !== \"Abnormal\""
        },
        "name": {
          "type": "value",
          "value": "EENTRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"500px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.EENTRemark || \"\""
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "Input",
      "parent": 188,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.GI !== \"Abnormal\""
        },
        "name": {
          "type": "value",
          "value": "GIRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"500px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.GIRemark || \"\""
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "Input",
      "parent": 189,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Genitourinary !== \"Abnormal\""
        },
        "name": {
          "type": "value",
          "value": "GenitourinaryRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"500px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.GenitourinaryRemark || \"\""
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "Input",
      "parent": 190,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.EndocrineAndMetabolic !== \"Abnormal\""
        },
        "name": {
          "type": "value",
          "value": "EndocrineAndMetabolicRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"500px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.EndocrineAndMetabolicRemark || \"\""
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "Input",
      "parent": 191,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.SkinBreast !== \"Abnormal\""
        },
        "name": {
          "type": "value",
          "value": "SkinBreastRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"500px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.SkinBreastRemark || \"\""
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "Input",
      "parent": 192,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ImmunologyHematology !== \"Abnormal\""
        },
        "name": {
          "type": "value",
          "value": "ImmunologyHematologyRemake"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"500px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ImmunologyHematologyRemake || \"\""
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": null,
      "id": 201,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"15/20\"}"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 202,
      "name": "Input",
      "parent": 201,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Cardiovascular !== \"Abnormal\""
        },
        "name": {
          "type": "value",
          "value": "CardiovascularRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35px\",width:\"500px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.CardiovascularRemark || \"\""
        }
      },
      "seq": 202,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PreANES_03_Airway",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 90
}

*********************************************************************************** */
