import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Icon,
  Radio,
  Checkbox,
  Input
} from 'semantic-ui-react'

const CardDischargePlanningUX = (props: any) => {
    return(
      <div
        style={{padding: "10px"}}>
        <div
          style={{display: "flex", justifyContent: "space-between"}}>
          
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <div
              className="ui label teal ribbon">
              DISCHARGE PLANNING INFORMATION
            </div>
          </div>
          <div
            style={{display: "flex"}}>
            
            <Label
              color="teal"
              style={{display: props.showStatus ? "" : "none"}}>
              Draft
            </Label>
            <div
              style={{cursor: "pointer", margin: "-5px -5px 0 15px", display: props.closeIcon ? "" : "none"}}>
              
              <Icon
                className="red large"
                name={"close"}
                onClick={props.onClose}>
              </Icon>
            </div>
          </div>
        </div>
        <div
          style={{paddingTop:"10px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(40, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/18",}}>
              
              <Label>
                 การประเมินปัญหาและความต้องการของผู้ป่วยที่มีผลกระทบต่อสุขภาพ
              </Label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "1/15",fontWeight: "bold"}}>
              { `1.  ${props.ClinicalTermPlanning?.disc_plan1?.name}`}
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "23/40" }}>
              
              <Radio
                checked={props.ClinicalTermPlanning?.disc_plan1?.has_problem === "N"}
                label="ไม่มีปัญหา"
                name="has_problem"
                onChange={props.handleChangeValue("disc_plan1.has_problem")}
                style={{ marginRight: "20px" }}
                value="N">
              </Radio>
              <Radio
                checked={props.ClinicalTermPlanning?.disc_plan1?.has_problem === "Y"}
                label="มีปัญหา"
                name="has_problem"
                onChange={props.handleChangeValue("disc_plan1.has_problem")}
                style={{ marginRight: "20px" }}
                value="Y">
              </Radio>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "1/15",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan1?.items[0]?.checked}
                onChange={props.handleCheckValue("disc_plan1.items.0.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {`1. ${props.ClinicalTermPlanning?.disc_plan1?.items[0]?.name}`}
              </div>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "19/22" }}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "23/40" }}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan1.items.0.answer")}
                style={{width:"100%"}}
                value={props.ClinicalTermPlanning?.disc_plan1?.items[0]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "1/15",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan1?.items[1]?.checked}
                onChange={props.handleCheckValue("disc_plan1.items.1.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {`2. ${props.ClinicalTermPlanning?.disc_plan1?.items[1]?.name}`}
              </div>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "19/22" }}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "23/40" }}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan1.items.1.answer")}
                style={{width:"100%"}}
                type="text"
                value={props.ClinicalTermPlanning?.disc_plan1?.items[1]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "1/15",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan1?.items[2]?.checked}
                onChange={props.handleCheckValue("disc_plan1.items.2.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {`3. ${props.ClinicalTermPlanning?.disc_plan1?.items[2]?.name}`}
              </div>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "19/22" }}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "23/40" }}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan1.items.2.answer")}
                style={{width:"100%"}}
                type="text"
                value={props.ClinicalTermPlanning?.disc_plan1?.items[2]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "9/10", gridColumn: "1/15",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan1?.items[3]?.checked}
                onChange={props.handleCheckValue("disc_plan1.items.3.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {`4. ${props.ClinicalTermPlanning?.disc_plan1?.items[3]?.name}`}
              </div>
            </div>
            <div
              style={{ gridRow: "9/10", gridColumn: "19/22" }}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "9/10", gridColumn: "23/40" }}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan1.items.3.answer")}
                style={{width:"100%"}}
                type="text"
                value={props.ClinicalTermPlanning?.disc_plan1?.items[3]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "1/15",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan1?.items[4]?.checked}
                onChange={props.handleCheckValue("disc_plan1.items.4.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {`5. ${props.ClinicalTermPlanning?.disc_plan1?.items[4]?.name}`}
              </div>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "19/22" }}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "23/40" }}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan1.items.4.answer")}
                style={{width:"100%"}}
                type="text"
                value={props.ClinicalTermPlanning?.disc_plan1?.items[4]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "13/14", gridColumn: "1/15",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan1?.items[5]?.checked}
                onChange={props.handleCheckValue("disc_plan1.items.5.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {`6. ${props.ClinicalTermPlanning?.disc_plan1?.items[5]?.name}`}
              </div>
            </div>
            <div
              style={{ gridRow: "13/14", gridColumn: "19/22" }}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "13/14", gridColumn: "23/40" }}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan1.items.5.answer")}
                style={{width:"100%"}}
                type="text"
                value={props.ClinicalTermPlanning?.disc_plan1?.items[5]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "15/16", gridColumn: "1/15",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan1?.items[6]?.checked}
                onChange={props.handleCheckValue("disc_plan1.items.6.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {`7. ${props.ClinicalTermPlanning?.disc_plan1?.items[6]?.name}`}
              </div>
            </div>
            <div
              style={{ gridRow: "15/16", gridColumn: "19/22" }}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "15/16", gridColumn: "23/40" }}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan1.items.6.answer")}
                style={{width:"100%"}}
                type="text"
                value={props.ClinicalTermPlanning?.disc_plan1?.items[6]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "17/18", gridColumn: "1/15",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan1?.items[7]?.checked}
                onChange={props.handleCheckValue("disc_plan1.items.7.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {`8. ${props.ClinicalTermPlanning?.disc_plan1?.items[7]?.name}`}
              </div>
            </div>
            <div
              style={{ gridRow: "17/18", gridColumn: "19/22" }}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "17/18", gridColumn: "23/40" }}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan1.items.7.answer")}
                style={{width:"100%"}}
                type="text"
                value={props.ClinicalTermPlanning?.disc_plan1?.items[7]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "19/20", gridColumn: "1/15",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan1?.items[8]?.checked}
                onChange={props.handleCheckValue("disc_plan1.items.8.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {`9. ${props.ClinicalTermPlanning?.disc_plan1?.items[8]?.name}`}
              </div>
            </div>
            <div
              style={{ gridRow: "19/20", gridColumn: "19/22" }}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "19/20", gridColumn: "23/40" }}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan1.items.8.answer")}
                style={{width:"100%"}}
                type="text"
                value={props.ClinicalTermPlanning?.disc_plan1?.items[8]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "21/22", gridColumn: "1/15",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan1?.items[9]?.checked}
                onChange={props.handleCheckValue("disc_plan1.items.9.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {`10. ${props.ClinicalTermPlanning?.disc_plan1?.items[9]?.name}`}
              </div>
            </div>
            <div
              style={{ gridRow: "21/22", gridColumn: "19/22" }}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "21/22", gridColumn: "23/40" }}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan1.items.9.answer")}
                style={{width:"100%"}}
                type="text"
                value={props.ClinicalTermPlanning?.disc_plan1?.items[9]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "23/24", gridColumn: "1/18",fontWeight: "bold"}}>
              {`2.  ${props.ClinicalTermPlanning?.disc_plan2?.name}`}
            </div>
            <div
              style={{ gridRow: "23/24", gridColumn: "23/40" }}>
              
              <Radio
                checked={props.ClinicalTermPlanning?.disc_plan2?.has_problem === "N"}
                label="ไม่มีปัญหา"
                name="has_problem"
                onChange={props.handleChangeValue("disc_plan2.has_problem")}
                style={{ marginRight: "20px" }}
                value="N">
              </Radio>
              <Radio
                checked={props.ClinicalTermPlanning?.disc_plan2?.has_problem === "Y"}
                label="มีปัญหา"
                name="has_problem"
                onChange={props.handleChangeValue("disc_plan2.has_problem")}
                style={{ marginRight: "20px" }}
                value="Y">
              </Radio>
            </div>
            <div
              style={{ gridRow: "24/25", gridColumn: "1/10",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan2?.items[0]?.checked}
                onChange={props.handleCheckValue("disc_plan2.items.0.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {`1. ${props.ClinicalTermPlanning?.disc_plan2?.items[0]?.name}`}
              </div>
            </div>
            <div
              style={{ gridRow: "24/25", gridColumn: "12/13"}}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "24/25", gridColumn: "14/20",}}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan2.items.0.answer")}
                value={props.ClinicalTermPlanning?.disc_plan2?.items[0]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "24/25", gridColumn: "24/31",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan2?.items[1]?.checked}
                onChange={props.handleCheckValue("disc_plan2.items.1.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {`2. ${props.ClinicalTermPlanning?.disc_plan2?.items[1]?.name}`}
              </div>
            </div>
            <div
              style={{ gridRow: "24/25", gridColumn: "32/33",}}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "24/25", gridColumn: "34/45",}}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan2.items.1.answer")}
                value={props.ClinicalTermPlanning?.disc_plan2?.items[1]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "26/27", gridColumn: "1/10",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan2?.items[2]?.checked}
                onChange={props.handleCheckValue("disc_plan2.items.2.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {`3. ${props.ClinicalTermPlanning?.disc_plan2?.items[2]?.name}`}
              </div>
            </div>
            <div
              style={{ gridRow: "26/27", gridColumn: "12/13"}}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "26/27", gridColumn: "14/20",}}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan2.items.2.answer")}
                value={props.ClinicalTermPlanning?.disc_plan2?.items[2]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "26/27", gridColumn: "24/30",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan2?.items[3]?.checked}
                onChange={props.handleCheckValue("disc_plan2.items.3.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {`4. ${props.ClinicalTermPlanning?.disc_plan2?.items[3]?.name}`}
              </div>
            </div>
            <div
              style={{ gridRow: "26/27", gridColumn: "32/33",}}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "26/27", gridColumn: "34/45",}}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan2.items.3.answer")}
                value={props.ClinicalTermPlanning?.disc_plan2?.items[3]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "28/29", gridColumn: "1/10",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan2?.items[4]?.checked}
                onChange={props.handleCheckValue("disc_plan2.items.4.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {`5. ${props.ClinicalTermPlanning?.disc_plan2?.items[4]?.name}`}
              </div>
            </div>
            <div
              style={{ gridRow: "28/29", gridColumn: "12/13"}}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "28/29", gridColumn: "14/20",}}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan2.items.4.answer")}
                type="text"
                value={props.ClinicalTermPlanning?.disc_plan2?.items[4]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "28/29", gridColumn: "24/30",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan2?.items[5]?.checked}
                onChange={props.handleCheckValue("disc_plan2.items.5.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {`6. ${props.ClinicalTermPlanning?.disc_plan2?.items[5]?.name}`}
              </div>
            </div>
            <div
              style={{ gridRow: "28/29", gridColumn: "32/33",}}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "28/29", gridColumn: "34/45",}}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan2.items.5.answer")}
                type="text"
                value={props.ClinicalTermPlanning?.disc_plan2?.items[5]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "30/31", gridColumn: "1/10",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan2?.items[6]?.checked}
                onChange={props.handleCheckValue("disc_plan2.items.6.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {`7. ${props.ClinicalTermPlanning?.disc_plan2?.items[6]?.name}`}
              </div>
            </div>
            <div
              style={{ gridRow: "30/31", gridColumn: "12/13"}}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "30/31", gridColumn: "14/20",}}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan2.items.6.answer")}
                type="text"
                value={props.ClinicalTermPlanning?.disc_plan2?.items[6]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "30/31", gridColumn: "24/36",display:"flex"}}>
              
              <Checkbox
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {`8. ${props.ClinicalTermPlanning?.disc_plan2?.items[6]?.name}`}
              </div>
            </div>
            <div
              style={{ gridRow: "31/31", gridColumn: "1/18",fontWeight: "bold"}}>
              { `3.  ${props.ClinicalTermPlanning?.disc_plan4?.name}`}
            </div>
            <div
              style={{ gridRow: "32/33", gridColumn: "1/8",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan4?.items[0]?.checked}
                onChange={props.handleCheckValue("disc_plan4.items.0.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermPlanning?.disc_plan4?.items[0]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "32/33", gridColumn: "9/16",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan4?.items[1]?.checked}
                onChange={props.handleCheckValue("disc_plan4.items.1.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermPlanning?.disc_plan4?.items[1]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "32/33", gridColumn: "17/24",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan4?.items[2]?.checked}
                onChange={props.handleCheckValue("disc_plan4.items.2.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermPlanning?.disc_plan4?.items[2]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "32/33", gridColumn: "25/26",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan4?.items[3]?.checked}
                onChange={props.handleCheckValue("disc_plan4.items.3.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermPlanning?.disc_plan4?.items[3]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "32/33", gridColumn: "27/28"}}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "32/33", gridColumn: "29/38"}}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan4.items.3.answer")}
                type="text"
                value={props.ClinicalTermPlanning?.disc_plan4?.items[3]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "33/34", gridColumn: "1/18",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan4?.items[4]?.checked}
                onChange={props.handleCheckValue("disc_plan4.items.4.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermPlanning?.disc_plan4?.items[4]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "34/35", gridColumn: "1/25",fontWeight: "bold"}}>
              { `4.  ${props.ClinicalTermPlanning?.disc_plan5?.name}`}
            </div>
            <div
              style={{ gridRow: "35/36", gridColumn: "1/8",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan5?.items[0]?.checked}
                onChange={props.handleCheckValue("disc_plan5.items.0.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermPlanning?.disc_plan5?.items[0]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "35/36", gridColumn: "10/15",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan5?.items[1]?.checked}
                onChange={props.handleCheckValue("disc_plan5.items.1.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermPlanning?.disc_plan5?.items[1]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "35/36", gridColumn: "17/18"}}>
              ระบุ
            </div>
            <div
              style={{ gridRow: "35/36", gridColumn: "19/30"}}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan5.items.1.answer")}
                style={{width:"100%"}}
                value={props.ClinicalTermPlanning?.disc_plan5?.items[1]?.answer}>
              </Input>
            </div>
            <div
              style={{ gridRow: "36/37", gridColumn: "1/25",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan5.items[2]?.checked}
                onChange={props.handleCheckValue("disc_plan5.items.2.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={{marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermPlanning?.disc_plan5?.items[2]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "37/38", gridColumn: "1/19",fontWeight: "bold"}}>
              { `5.  ${props.ClinicalTermPlanning?.disc_plan6?.name}`}
            </div>
            <div
              style={{ gridRow: "37/38", gridColumn: "23/40" }}>
              
              <Radio
                checked={props.ClinicalTermPlanning?.disc_plan6?.has_problem === "N"}
                label="ไม่จำเป็น"
                name="has_problem"
                onChange={props.handleChangeValue("disc_plan6.has_problem")}
                style={{ marginRight: "20px" }}
                value="N">
              </Radio>
              <Radio
                checked={props.ClinicalTermPlanning?.disc_plan6?.has_problem === "Y"}
                label="จำเป็น"
                name="has_problem"
                onChange={props.handleChangeValue("disc_plan6.has_problem")}
                style={{ marginRight: "20px" }}
                value="Y">
              </Radio>
            </div>
            <div
              style={{ gridRow: "38/39", gridColumn: "1/9",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan6?.items[0]?.checked}
                onChange={props.handleCheckValue("disc_plan6.items.0.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={ {marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermPlanning?.disc_plan6?.items[0]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "38/39", gridColumn: "10/17",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan6?.items[1]?.checked}
                onChange={props.handleCheckValue("disc_plan6.items.1.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={ {marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermPlanning?.disc_plan6?.items[1]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "38/39", gridColumn: "18/25",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan6?.items[2]?.checked}
                onChange={props.handleCheckValue("disc_plan6.items.2.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={ {marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermPlanning?.disc_plan6?.items[2]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "38/39", gridColumn: "26/30",display:"flex"}}>
              
              <Checkbox
                checked={props.ClinicalTermPlanning?.disc_plan6?.items[3]?.checked}
                onChange={props.handleCheckValue("disc_plan6.items.3.checked")}
                style={{marginTop:"5px"}}>
              </Checkbox>
              <div
                style={ {marginTop:"3px",marginLeft:"10px"}}>
                {props.ClinicalTermPlanning?.disc_plan6?.items[3]?.name}
              </div>
            </div>
            <div
              style={{ gridRow: "38/39", gridColumn: "31/32"}}>
              ระบุ 
            </div>
            <div
              style={{ gridRow: "38/39", gridColumn: "31/40"}}>
              
              <Input
                onChange={props.handleChangeValue("disc_plan6.items.3.answer")}
                value={props.ClinicalTermPlanning?.disc_plan6?.items[3]?.answer}>
              </Input>
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardDischargePlanningUX

export const screenPropsDefault = {"drugSearch":"[Drug Search Box]"}

/* Date Time : Fri Jul 01 2022 11:56:48 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "DISCHARGE PLANNING INFORMATION"
        },
        "className": {
          "type": "value",
          "value": "ui label teal ribbon"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Label",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "Draft"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showStatus ? \"\" : \"none\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", margin: \"-5px -5px 0 15px\", display: props.closeIcon ? \"\" : \"none\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Icon",
      "parent": 102,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "name": {
          "type": "code",
          "value": "\"close\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop:\"10px\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "label": "divgrid",
      "name": "div",
      "parent": 104,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(40, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/18\",}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Label",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": " การประเมินปัญหาและความต้องการของผู้ป่วยที่มีผลกระทบต่อสุขภาพ"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "code",
          "value": " `1.  ${props.ClinicalTermPlanning?.disc_plan1?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/15\",fontWeight: \"bold\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"23/40\" }"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Radio",
      "parent": 109,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.has_problem === \"N\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มีปัญหา"
        },
        "name": {
          "type": "value",
          "value": "has_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan1.has_problem\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "N"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "Radio",
      "parent": 109,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.has_problem === \"Y\""
        },
        "label": {
          "type": "value",
          "value": "มีปัญหา"
        },
        "name": {
          "type": "value",
          "value": "has_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan1.has_problem\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "Y"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/15\",display:\"flex\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Checkbox",
      "parent": 112,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[0]?.checked"
        },
        "label": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan1.items.0.checked\")"
        },
        "onClick": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "div",
      "parent": 112,
      "props": {
        "children": {
          "type": "code",
          "value": "`1. ${props.ClinicalTermPlanning?.disc_plan1?.items[0]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"19/22\" }"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"23/40\" }"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "Input",
      "parent": 116,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan1.items.0.answer\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[0]?.answer"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"1/15\",display:\"flex\"}"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "Checkbox",
      "parent": 118,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[1]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan1.items.1.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 118,
      "props": {
        "children": {
          "type": "code",
          "value": "`2. ${props.ClinicalTermPlanning?.disc_plan1?.items[1]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"19/22\" }"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"23/40\" }"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"1/15\",display:\"flex\"}"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"19/22\" }"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"23/40\" }"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"1/15\",display:\"flex\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "Input",
      "parent": 122,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan1.items.1.answer\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[1]?.answer"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "Checkbox",
      "parent": 123,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[2]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan1.items.2.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 123,
      "props": {
        "children": {
          "type": "code",
          "value": "`3. ${props.ClinicalTermPlanning?.disc_plan1?.items[2]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "Input",
      "parent": 125,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan1.items.2.answer\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[2]?.answer"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 131,
      "name": "Checkbox",
      "parent": 126,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[3]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan1.items.3.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 126,
      "props": {
        "children": {
          "type": "code",
          "value": "`4. ${props.ClinicalTermPlanning?.disc_plan1?.items[3]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"19/22\" }"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/10\", gridColumn: \"23/40\" }"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "Input",
      "parent": 134,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan1.items.3.answer\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[3]?.answer"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"1/15\",display:\"flex\"}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"19/22\" }"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"23/40\" }"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "Checkbox",
      "parent": 136,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[4]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan1.items.4.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "code",
          "value": "`5. ${props.ClinicalTermPlanning?.disc_plan1?.items[4]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "Input",
      "parent": 138,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan1.items.4.answer\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[4]?.answer"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"1/15\",display:\"flex\"}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"19/22\" }"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"13/14\", gridColumn: \"23/40\" }"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"15/16\", gridColumn: \"1/15\",display:\"flex\"}"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"15/16\", gridColumn: \"19/22\" }"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"15/16\", gridColumn: \"23/40\" }"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"17/18\", gridColumn: \"1/15\",display:\"flex\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"17/18\", gridColumn: \"19/22\" }"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"17/18\", gridColumn: \"23/40\" }"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"19/20\", gridColumn: \"1/15\",display:\"flex\"}"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"19/20\", gridColumn: \"19/22\" }"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"19/20\", gridColumn: \"23/40\" }"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"21/22\", gridColumn: \"1/15\",display:\"flex\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"21/22\", gridColumn: \"19/22\" }"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"21/22\", gridColumn: \"23/40\" }"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "Checkbox",
      "parent": 142,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[5]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan1.items.5.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "Checkbox",
      "parent": 145,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[6]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan1.items.6.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "Checkbox",
      "parent": 148,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[7]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan1.items.7.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "Checkbox",
      "parent": 151,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[8]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan1.items.8.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "Checkbox",
      "parent": 154,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[9]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan1.items.9.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "div",
      "parent": 142,
      "props": {
        "children": {
          "type": "code",
          "value": "`6. ${props.ClinicalTermPlanning?.disc_plan1?.items[5]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 145,
      "props": {
        "children": {
          "type": "code",
          "value": "`7. ${props.ClinicalTermPlanning?.disc_plan1?.items[6]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 148,
      "props": {
        "children": {
          "type": "code",
          "value": "`8. ${props.ClinicalTermPlanning?.disc_plan1?.items[7]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "div",
      "parent": 151,
      "props": {
        "children": {
          "type": "code",
          "value": "`9. ${props.ClinicalTermPlanning?.disc_plan1?.items[8]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "code",
          "value": "`10. ${props.ClinicalTermPlanning?.disc_plan1?.items[9]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "Input",
      "parent": 147,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan1.items.6.answer\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[6]?.answer"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "Input",
      "parent": 150,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan1.items.7.answer\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[7]?.answer"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "Input",
      "parent": 153,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan1.items.8.answer\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[8]?.answer"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "Input",
      "parent": 156,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan1.items.9.answer\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[9]?.answer"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "Input",
      "parent": 144,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan1.items.5.answer\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan1?.items[5]?.answer"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "code",
          "value": "`2.  ${props.ClinicalTermPlanning?.disc_plan2?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"23/24\", gridColumn: \"1/18\",fontWeight: \"bold\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"23/24\", gridColumn: \"23/40\" }"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"24/25\", gridColumn: \"1/10\",display:\"flex\"}"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "Radio",
      "parent": 173,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan2?.has_problem === \"N\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มีปัญหา"
        },
        "name": {
          "type": "value",
          "value": "has_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan2.has_problem\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "N"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "Radio",
      "parent": 173,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan2?.has_problem === \"Y\""
        },
        "label": {
          "type": "value",
          "value": "มีปัญหา"
        },
        "name": {
          "type": "value",
          "value": "has_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan2.has_problem\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "Y"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "Checkbox",
      "parent": 174,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan2?.items[0]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan2.items.0.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": null,
      "id": 216,
      "name": "div",
      "parent": 174,
      "props": {
        "children": {
          "type": "code",
          "value": "`1. ${props.ClinicalTermPlanning?.disc_plan2?.items[0]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": null,
      "id": 217,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"24/25\", gridColumn: \"12/13\"}"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": null,
      "id": 218,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"24/25\", gridColumn: \"14/20\",}"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 219,
      "name": "Input",
      "parent": 218,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan2.items.0.answer\")"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan2?.items[0]?.answer"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": null,
      "id": 220,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"24/25\", gridColumn: \"24/31\",display:\"flex\"}"
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": null,
      "id": 221,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"24/25\", gridColumn: \"34/45\",}"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 222,
      "name": "Checkbox",
      "parent": 220,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan2?.items[1]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan2.items.1.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": null,
      "id": 223,
      "name": "div",
      "parent": 220,
      "props": {
        "children": {
          "type": "code",
          "value": "`2. ${props.ClinicalTermPlanning?.disc_plan2?.items[1]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 224,
      "name": "Input",
      "parent": 221,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan2.items.1.answer\")"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan2?.items[1]?.answer"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": null,
      "id": 225,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"26/27\", gridColumn: \"1/10\",display:\"flex\"}"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": null,
      "id": 226,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"24/25\", gridColumn: \"32/33\",}"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": null,
      "id": 227,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"26/27\", gridColumn: \"14/20\",}"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": null,
      "id": 228,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"26/27\", gridColumn: \"24/30\",display:\"flex\"}"
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": null,
      "id": 229,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"26/27\", gridColumn: \"32/33\",}"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 230,
      "name": "Checkbox",
      "parent": 225,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan2?.items[2]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan2.items.2.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 231,
      "name": "Checkbox",
      "parent": 228,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan2?.items[3]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan2.items.3.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": null,
      "id": 232,
      "name": "div",
      "parent": 225,
      "props": {
        "children": {
          "type": "code",
          "value": "`3. ${props.ClinicalTermPlanning?.disc_plan2?.items[2]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": null,
      "id": 233,
      "name": "div",
      "parent": 228,
      "props": {
        "children": {
          "type": "code",
          "value": "`4. ${props.ClinicalTermPlanning?.disc_plan2?.items[3]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": null,
      "id": 234,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"26/27\", gridColumn: \"34/45\",}"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 235,
      "name": "Input",
      "parent": 227,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan2.items.2.answer\")"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan2?.items[2]?.answer"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 236,
      "name": "Input",
      "parent": 234,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan2.items.3.answer\")"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan2?.items[3]?.answer"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": null,
      "id": 237,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"26/27\", gridColumn: \"12/13\"}"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": null,
      "id": 238,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"28/29\", gridColumn: \"1/10\",display:\"flex\"}"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": null,
      "id": 239,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"28/29\", gridColumn: \"12/13\"}"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": null,
      "id": 240,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"28/29\", gridColumn: \"14/20\",}"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 241,
      "name": "Checkbox",
      "parent": 238,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan2?.items[4]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan2.items.4.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": null,
      "id": 242,
      "name": "div",
      "parent": 238,
      "props": {
        "children": {
          "type": "code",
          "value": "`5. ${props.ClinicalTermPlanning?.disc_plan2?.items[4]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 243,
      "name": "Input",
      "parent": 240,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan2.items.4.answer\")"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan2?.items[4]?.answer"
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": null,
      "id": 244,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"28/29\", gridColumn: \"24/30\",display:\"flex\"}"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": null,
      "id": 245,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"28/29\", gridColumn: \"32/33\",}"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": null,
      "id": 246,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"28/29\", gridColumn: \"34/45\",}"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": null,
      "id": 247,
      "name": "div",
      "parent": 244,
      "props": {
        "children": {
          "type": "code",
          "value": "`6. ${props.ClinicalTermPlanning?.disc_plan2?.items[5]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 249,
      "name": "Checkbox",
      "parent": 244,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan2?.items[5]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan2.items.5.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 250,
      "name": "Input",
      "parent": 246,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan2.items.5.answer\")"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan2?.items[5]?.answer"
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": null,
      "id": 251,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"30/31\", gridColumn: \"1/10\",display:\"flex\"}"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": null,
      "id": 252,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"30/31\", gridColumn: \"12/13\"}"
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": null,
      "id": 253,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"30/31\", gridColumn: \"14/20\",}"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": null,
      "id": 254,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"30/31\", gridColumn: \"24/36\",display:\"flex\"}"
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": null,
      "id": 255,
      "name": "div",
      "parent": 251,
      "props": {
        "children": {
          "type": "code",
          "value": "`7. ${props.ClinicalTermPlanning?.disc_plan2?.items[6]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": null,
      "id": 256,
      "name": "div",
      "parent": 254,
      "props": {
        "children": {
          "type": "code",
          "value": "`8. ${props.ClinicalTermPlanning?.disc_plan2?.items[6]?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 257,
      "name": "Checkbox",
      "parent": 251,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan2?.items[6]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan2.items.6.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 258,
      "name": "Checkbox",
      "parent": 254,
      "props": {
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 259,
      "name": "Input",
      "parent": 253,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan2.items.6.answer\")"
        },
        "style": {
          "type": "value",
          "value": ""
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan2?.items[6]?.answer"
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": null,
      "id": 260,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "code",
          "value": " `3.  ${props.ClinicalTermPlanning?.disc_plan4?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"31/31\", gridColumn: \"1/18\",fontWeight: \"bold\"}"
        }
      },
      "seq": 260,
      "void": false
    },
    {
      "from": null,
      "id": 261,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"32/33\", gridColumn: \"1/8\",display:\"flex\"}"
        }
      },
      "seq": 261,
      "void": false
    },
    {
      "from": null,
      "id": 262,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"32/33\", gridColumn: \"9/16\",display:\"flex\"}"
        }
      },
      "seq": 262,
      "void": false
    },
    {
      "from": null,
      "id": 263,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"32/33\", gridColumn: \"17/24\",display:\"flex\"}"
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": null,
      "id": 264,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"32/33\", gridColumn: \"25/26\",display:\"flex\"}"
        }
      },
      "seq": 264,
      "void": false
    },
    {
      "from": null,
      "id": 265,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"32/33\", gridColumn: \"27/28\"}"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": null,
      "id": 266,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"32/33\", gridColumn: \"29/38\"}"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": null,
      "id": 267,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"33/34\", gridColumn: \"1/18\",display:\"flex\"}"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 268,
      "name": "Checkbox",
      "parent": 261,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[0]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan4.items.0.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 269,
      "name": "Checkbox",
      "parent": 262,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[1]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan4.items.1.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 270,
      "name": "Checkbox",
      "parent": 263,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[2]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan4.items.2.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 271,
      "name": "Checkbox",
      "parent": 264,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[3]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan4.items.3.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 272,
      "name": "Checkbox",
      "parent": 267,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[4]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan4.items.4.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 273,
      "name": "Input",
      "parent": 266,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan4.items.3.answer\")"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[3]?.answer"
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": null,
      "id": 274,
      "name": "div",
      "parent": 261,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[0]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": null,
      "id": 275,
      "name": "div",
      "parent": 262,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[1]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": null,
      "id": 276,
      "name": "div",
      "parent": 263,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[2]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": null,
      "id": 277,
      "name": "div",
      "parent": 264,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[3]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": null,
      "id": 278,
      "name": "div",
      "parent": 267,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan4?.items[4]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 278,
      "void": false
    },
    {
      "from": null,
      "id": 279,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"35/36\", gridColumn: \"1/8\",display:\"flex\"}"
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": null,
      "id": 280,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"35/36\", gridColumn: \"10/15\",display:\"flex\"}"
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": null,
      "id": 281,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"35/36\", gridColumn: \"17/18\"}"
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": null,
      "id": 282,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"35/36\", gridColumn: \"19/30\"}"
        }
      },
      "seq": 283,
      "void": false
    },
    {
      "from": null,
      "id": 283,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"36/37\", gridColumn: \"1/25\",display:\"flex\"}"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": null,
      "id": 284,
      "name": "div",
      "parent": 279,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan5?.items[0]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": null,
      "id": 285,
      "name": "div",
      "parent": 280,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan5?.items[1]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": null,
      "id": 286,
      "name": "div",
      "parent": 283,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan5?.items[2]?.name"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 287,
      "name": "Checkbox",
      "parent": 279,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan5?.items[0]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan5.items.0.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 288,
      "name": "Checkbox",
      "parent": 280,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan5?.items[1]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan5.items.1.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 285,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 289,
      "name": "Checkbox",
      "parent": 283,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan5.items[2]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan5.items.2.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 290,
      "name": "Input",
      "parent": 282,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan5.items.1.answer\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan5?.items[1]?.answer"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": null,
      "id": 291,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "code",
          "value": " `4.  ${props.ClinicalTermPlanning?.disc_plan5?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"34/35\", gridColumn: \"1/25\",fontWeight: \"bold\"}"
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": null,
      "id": 292,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "code",
          "value": " `5.  ${props.ClinicalTermPlanning?.disc_plan6?.name}`"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"37/38\", gridColumn: \"1/19\",fontWeight: \"bold\"}"
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": null,
      "id": 293,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"37/38\", gridColumn: \"23/40\" }"
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 294,
      "name": "Radio",
      "parent": 293,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.has_problem === \"N\""
        },
        "label": {
          "type": "value",
          "value": "ไม่จำเป็น"
        },
        "name": {
          "type": "value",
          "value": "has_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan6.has_problem\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "N"
        }
      },
      "seq": 294,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 295,
      "name": "Radio",
      "parent": 293,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.has_problem === \"Y\""
        },
        "label": {
          "type": "value",
          "value": "จำเป็น"
        },
        "name": {
          "type": "value",
          "value": "has_problem"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan6.has_problem\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "Y"
        }
      },
      "seq": 295,
      "void": false
    },
    {
      "from": null,
      "id": 296,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"38/39\", gridColumn: \"1/9\",display:\"flex\"}"
        }
      },
      "seq": 296,
      "void": false
    },
    {
      "from": null,
      "id": 297,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"38/39\", gridColumn: \"10/17\",display:\"flex\"}"
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": null,
      "id": 298,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"38/39\", gridColumn: \"18/25\",display:\"flex\"}"
        }
      },
      "seq": 298,
      "void": false
    },
    {
      "from": null,
      "id": 299,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"38/39\", gridColumn: \"26/30\",display:\"flex\"}"
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": null,
      "id": 300,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ "
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"38/39\", gridColumn: \"31/32\"}"
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": null,
      "id": 301,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"38/39\", gridColumn: \"31/40\"}"
        }
      },
      "seq": 301,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 302,
      "name": "Checkbox",
      "parent": 296,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.items[0]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan6.items.0.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 302,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 303,
      "name": "Checkbox",
      "parent": 297,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.items[1]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan6.items.1.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 303,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 304,
      "name": "Checkbox",
      "parent": 298,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.items[2]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan6.items.2.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 304,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 305,
      "name": "Checkbox",
      "parent": 299,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.items[3]?.checked"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleCheckValue(\"disc_plan6.items.3.checked\")"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\"}"
        }
      },
      "seq": 305,
      "void": false
    },
    {
      "from": null,
      "id": 306,
      "name": "div",
      "parent": 296,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.items[0]?.name"
        },
        "style": {
          "type": "code",
          "value": " {marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 306,
      "void": false
    },
    {
      "from": null,
      "id": 307,
      "name": "div",
      "parent": 297,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.items[1]?.name"
        },
        "style": {
          "type": "code",
          "value": " {marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 307,
      "void": false
    },
    {
      "from": null,
      "id": 308,
      "name": "div",
      "parent": 298,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.items[2]?.name"
        },
        "style": {
          "type": "code",
          "value": " {marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 308,
      "void": false
    },
    {
      "from": null,
      "id": 309,
      "name": "div",
      "parent": 299,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.items[3]?.name"
        },
        "style": {
          "type": "code",
          "value": " {marginTop:\"3px\",marginLeft:\"10px\"}"
        }
      },
      "seq": 309,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 310,
      "name": "Input",
      "parent": 301,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"disc_plan6.items.3.answer\")"
        },
        "value": {
          "type": "code",
          "value": "props.ClinicalTermPlanning?.disc_plan6?.items[3]?.answer"
        }
      },
      "seq": 310,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDischargePlanningUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "drugSearch": "[Drug Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
