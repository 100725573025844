import { useIntl } from "react-intl";
import React, { useState, useEffect, useRef, CSSProperties } from "react";
import { Segment, Checkbox, Button, CheckboxProps } from "semantic-ui-react";

//Common
import { SearchBox } from "react-lib/apps/common";

type ListChecked = {
  [key: string]: any;
  is_selected: boolean;
  is_show: boolean;
};

type ModalItemFilterProps = {
  data: ListChecked[];
  textField: string;
  style?: CSSProperties;
  // Callback
  onClose?: () => any;
  onSelect?: (list: any[]) => any;
};

const ModalItemFilterInitial: ModalItemFilterProps = {
  data: [],
  textField: "",
  style: {},
  // Callback
  onClose: () => null,
  onSelect: () => null,
};

const ModalItemFilter: React.FC<ModalItemFilterProps> = (props) => {
  const intl = useIntl();
  const [items, setItems] = useState<ListChecked[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

  // Ref
  const segmentHoverRef = useRef<any>(false);

  useEffect(() => {
    document.addEventListener("click", handleMouseClick);
    return () => {
      document.removeEventListener("click", handleMouseClick);
    };
  }, []);

  useEffect(() => {
    setCheckedData(props.data, null, true);
  }, [props.data]);

  const setCheckedData = (
    data: ListChecked[],
    setFieldCheck: any = null,
    setFieldShow: any = null
  ) => {
    let setShow = false;
    let setCheck = false;

    if (setFieldShow !== null) {
      setShow = true;
    }
    if (setFieldCheck !== null) {
      setCheck = true;
    }

    const list: any[] = data.map((acc) => ({
      ...acc,
      is_selected: setCheck ? setFieldCheck : acc.is_selected,
      is_show: setShow ? setFieldShow : acc.is_show,
    }));

    if (list[0] && list.every((item) => item.is_selected)) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }

    setItems(list);
  };

  const clearState = () => {
    setItems([]);
    setIsSelectAll(false);
    segmentHoverRef.current = false;
  };

  const handleMouseClick = (e: any) => {
    if (!segmentHoverRef.current) {
      props.onClose?.();
      clearState();
    }
  };

  const handleChange = (index: number, checked: any) => {
    const cloneItems = [...items];
    cloneItems[index].is_selected = checked;

    if (cloneItems.every((item) => item.is_selected)) {
      setIsSelectAll(true);
    } else {
      if (isSelectAll) {
        setIsSelectAll(false);
      }
    }

    setItems(cloneItems);
  };

  const handleToggleSelectAll = (
    event: React.FormEvent<HTMLInputElement>,
    { checked }: CheckboxProps
  ) => {
    setCheckedData(items, checked);
  };

  const handleGetSearchOptions = ({ searchText = "" }) => {
    const filterItems = items.map((item) =>
      item?.[props.textField].includes(searchText)
        ? { ...item, is_show: true }
        : { ...item, is_show: false }
    );

    setItems(filterItems);
    return [[], null];
  };

  const handleFilter = () => {
    const newArr = [...items];
    const itemsCheck: any[] = newArr.filter((item) => item.is_selected);

    for (const item of itemsCheck) {
      delete item.is_show;
    }

    props.onSelect?.(itemsCheck);
  };

  return (
    <>
      <Segment
        className="scn-modal-item-filter"
        style={{
          position: "absolute",
          background: "#f2f2f2",
          zIndex: 9,
          width: "300px",
          ...props.style,
        }}
        onMouseOver={() => (segmentHoverRef.current = true)}
        onClick={() => (segmentHoverRef.current = true)}
        onMouseLeave={() => (segmentHoverRef.current = false)}
      >
        <div style={{ display: "flex", marginBottom: "6px" }}>
          {intl.formatMessage({ id: "cukey79" })}{" "}
          <label style={{ fontWeight: "bold", paddingLeft: "5px" }}>
            {intl.formatMessage({ id: "cukey65" })}
          </label>
        </div>
        <SearchBox
          inputStyle={{ width: "100%" }}
          onGetSearchOptions={handleGetSearchOptions}
          minCharacters={0}
        />
        <Checkbox
          label="Select All"
          style={{
            fontWeight: "bold",
            padding: "6px",
            width: "100%",
            border: "1px solid #e1e1e1",
          }}
          checked={isSelectAll}
          onChange={handleToggleSelectAll}
        />
        <div style={{ height: "130px", background: "white" }}>
          <div
            style={{
              height: "120px",
              overflow: "auto",
              background: "white",
              display: "grid",
              padding: "10px",
            }}
          >
            {items.map((item, index) =>
              item.is_show ? (
                <Checkbox
                  key={"list-check" + index}
                  label={item.name}
                  style={{ margin: "5px 0" }}
                  checked={item.is_selected}
                  onChange={(e, { checked }) => {
                    handleChange(index, checked);
                  }}
                />
              ) : null
            )}
          </div>
        </div>
        <Button fluid color="blue" onClick={handleFilter}>
          {intl.formatMessage({ id: "cukey78" })}
        </Button>
      </Segment>
    </>
  );
};

ModalItemFilter.defaultProps = ModalItemFilterInitial;

export default React.memo(ModalItemFilter);
