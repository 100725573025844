import WasmController from "react-lib/frameworks/WasmController";
import NursingDiagnosisResultList from "issara-sdk/apis/NursingDiagnosisResultList_apps_PTM";
import NursingDiagnosisResultGroupList from "issara-sdk/apis/NursingDiagnosisResultGroupList_apps_PTM";
import NursingDiagnosisResultDetail from "issara-sdk/apis/NursingDiagnosisResultDetail_apps_PTM";
import NursingDiagnosisTemplateList from "issara-sdk/apis/NursingDiagnosisTemplateList_apps_PTM";
import NursingDiagnosisResulFromTemplate from "issara-sdk/apis/NursingDiagnosisResulFromTemplate_apps_PTM";
import NursingDiagnosisResultGroupDetail from "issara-sdk/apis/NursingDiagnosisResultGroupDetail_apps_PTM";
import moment from "moment";
import { formatDate } from "react-lib/utils";

export type State = {
  selectedEncounter?: any;
  NursingDiagnosisSequence?: {
    sequenceIndex: string | null;
    nursingDiagnosisTemplateOptions?: any[];
    nursingDiagnosistemplateList?: any[any];
    nursingDiagnosistemplateQuestionsList?: any[any];
    nursingDiagnosisList?: any[];
    nursingDiagnosisSelected?: any[any];
    nursingDiagnosisSearch?: {
      start_date?: any;
      end_date?: any;
      division?: any;
    };
    nursingDiagnosis?: {
      patient_id?: number;
      division?: number;
      id?: number | null;
      pk?: string | null;
      diagnosis?: any;
      end_date?: any;
      goal?: any;
      group?: any;
      plan?: any;
      templateID?: any;
    };
  } | null;
  successMessage?: any;
  errorMessage?: any;
  buttonLoadCheck?: any; // {cardName: LOADING || SUCCESS || ERROR}
};

export const StateInitial: State = {
  NursingDiagnosisSequence: null,
  successMessage: null,
  errorMessage: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.NursingDiagnosisSequence) return;

  // Get master
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["division", {}]],
    },
  });
  if (params.action === "search") {
    const nursingSearch =
      state.NursingDiagnosisSequence?.nursingDiagnosisSearch;
    const nursingDiagnosisList = await NursingDiagnosisResultGroupList.list({
      params: {
        encounter: state.selectedEncounter.id,
        start_date: nursingSearch?.start_date || formatDate(moment()),
        end_date: nursingSearch?.end_date || formatDate(moment()),
        division: nursingSearch?.division,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    controller.setState({
      NursingDiagnosisSequence: {
        ...state.NursingDiagnosisSequence,
        nursingDiagnosisList: nursingDiagnosisList[0]?.items || [],
      },
    });
  } else if (params.action === "add") {
    controller.setState({
      NursingDiagnosisSequence: {
        ...state.NursingDiagnosisSequence,
        nursingDiagnosis: {
          group: null,
          diagnosis: "",
          end_date: "",
          goal: "",
          plan: "",
        },
      },
    });
  } else if (params.action === "save") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    const nursing = state.NursingDiagnosisSequence.nursingDiagnosis;
    const [response, error] = await NursingDiagnosisResultList.create({
      data: {
        encounter: state.selectedEncounter.id,
        diagnosis: nursing?.diagnosis,
        end_date: nursing?.end_date,
        goal: nursing?.goal,
        group: nursing?.group || null,
        plan: nursing?.plan,
      },
      apiToken: controller.apiToken,
    });

    if (response) {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params?.sequence]: response,
        },
        NursingDiagnosisSequence: null,
      });

      const [responseGroupDetail, errorGroupDetail] =
        await NursingDiagnosisResultGroupDetail.retrieve({
          pk: response?.document,
          apiToken: controller.apiToken,
        });

      if (responseGroupDetail) {
        controller.setState({
          NursingDiagnosisSequence: {
            ...state.NursingDiagnosisSequence,
            nursingDiagnosisSelected: responseGroupDetail,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "SUCCESS",
          },
        });
      } else {
        controller.setState({
          NursingDiagnosisSequence: {
            ...state.NursingDiagnosisSequence,
            nursingDiagnosisSelected: [],
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "ERROR",
          },
        });
      }
    } else {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.sequence]: error },
        NursingDiagnosisSequence: null,
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params.action === "update") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    const nursing = state.NursingDiagnosisSequence.nursingDiagnosis;

    const [response, error] = await NursingDiagnosisResultDetail.update({
      data: {
        encounter: state.selectedEncounter.id,
        diagnosis: nursing?.diagnosis,
        goal: nursing?.goal,
        end_date: nursing?.end_date,
        group: nursing?.group || null,
        plan: nursing?.plan,
      },
      pk: nursing?.group,
      apiToken: controller.apiToken,
    });

    if (response) {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params?.sequence]: response,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
      });
    }
    if (error) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.sequence]: error },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    }

    const nursingDiagnosisSelected =
      await NursingDiagnosisResultGroupDetail.retrieve({
        pk: state.NursingDiagnosisSequence?.nursingDiagnosisSelected?.id,
        apiToken: controller.apiToken,
      });

    controller.setState({
      NursingDiagnosisSequence: {
        ...state.NursingDiagnosisSequence,
        nursingDiagnosisSelected: nursingDiagnosisSelected[0],
        nursingDiagnosis: {
          group: null,
          diagnosis: "",
          end_date: "",
          goal: "",
          plan: "",
        },
      },
    });
  } else if (params.action === "selectEdit") {
    const selectEdit =
      state.NursingDiagnosisSequence?.nursingDiagnosisSelected?.results;
    console.log("selectEdit", selectEdit);
    controller.setState({
      NursingDiagnosisSequence: {
        ...state.NursingDiagnosisSequence,
        nursingDiagnosis: {
          group: selectEdit?.[params.idx]?.id,
          diagnosis: selectEdit?.[params.idx]?.diagnosis,
          end_date: selectEdit?.[params.idx]?.end_date,
          goal: selectEdit?.[params.idx]?.goal,
          plan: selectEdit?.[params.idx]?.plan,
        },
      },
    });
  } else if (params.action === "clear") {
    controller.setState({
      successMessage: { ...state.successMessage, [params?.sequence]: null },
      errorMessage: { ...state.errorMessage, [params?.sequence]: null },
    });
  } else if (params.action === "select" && params.item) {
    const [response, error] = await NursingDiagnosisResultGroupDetail.retrieve({
      pk: params.item?.id,
      apiToken: controller.apiToken,
    });

    if (response) {
      controller.setState({
        NursingDiagnosisSequence: {
          ...state.NursingDiagnosisSequence,
          nursingDiagnosisSelected:
            // {
            //   results: [response],
            // },
            response,
        },
      });
    } else {
      controller.setState({
        NursingDiagnosisSequence: {
          ...state.NursingDiagnosisSequence,
          nursingDiagnosisSelected: [],
        },
      });
    }
  } else if (params.action === "delete") {
    const nursing = state.NursingDiagnosisSequence.nursingDiagnosis;
    const [response, error] = await NursingDiagnosisResultDetail.update({
      data: {
        active: false,
      },
      pk: state.NursingDiagnosisSequence?.nursingDiagnosisSelected?.results?.[
        params.idx
      ]?.id,
      apiToken: controller.apiToken,
    });

    if (response) {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params?.sequence]: response,
        },
      });
    }
    if (error) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.sequence]: error },
      });
    }

    const nursingDiagnosisSelected =
      await NursingDiagnosisResultGroupDetail.retrieve({
        pk: state.NursingDiagnosisSequence?.nursingDiagnosisSelected?.id,
        apiToken: controller.apiToken,
      });

    if (nursingDiagnosisSelected[0]?.results?.length !== 0) {
      controller.setState({
        NursingDiagnosisSequence: {
          ...state.NursingDiagnosisSequence,
          nursingDiagnosisSelected: nursingDiagnosisSelected[0],
          nursingDiagnosis: {
            group: null,
            diagnosis: "",
            end_date: "",
            goal: "",
            plan: "",
          },
        },
      });
    } else {
      controller.setState({
        NursingDiagnosisSequence: {
          ...state.NursingDiagnosisSequence,
          nursingDiagnosisSelected: null,
          nursingDiagnosis: {
            group: null,
            diagnosis: "",
            end_date: "",
            goal: "",
            plan: "",
          },
        },
      });
    }
  } else if (params.action === "templateList") {
    const nursingDiagnosistemplateList =
      await NursingDiagnosisTemplateList.list({
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
          device: controller.data.device,
        },
      });
    const nursingDiagnosisTemplateOptions: any[] = [];
    nursingDiagnosistemplateList?.[0].items?.map((item: any) => {
      nursingDiagnosisTemplateOptions.push({
        key: item?.id,
        value: item?.id,
        text: item?.name,
      });
    });

    controller.setState({
      NursingDiagnosisSequence: {
        ...state.NursingDiagnosisSequence,
        nursingDiagnosisTemplateOptions: nursingDiagnosisTemplateOptions || [],
        nursingDiagnosistemplateList:
          nursingDiagnosistemplateList?.[0].items || [],
      },
    });
  } else if (params.action === "templateForm") {
    let questions: any;
    state.NursingDiagnosisSequence?.nursingDiagnosistemplateList?.map(
      (item: any) => {
        if (
          state.NursingDiagnosisSequence?.nursingDiagnosis?.templateID ===
          item?.id
        ) {
          questions = item?.questions;
        }
      }
    );

    controller.setState({
      NursingDiagnosisSequence: {
        ...state.NursingDiagnosisSequence,
        nursingDiagnosistemplateQuestionsList: questions || [],
      },
    });
  } else if (params.action === "saveTemplate") {
    const questions =
      state.NursingDiagnosisSequence.nursingDiagnosistemplateQuestionsList;
    let data: any;
    var dataQue: any;
    const questionsData = questions?.map((data: any) => {
      console.log("items", data);
      dataQue = data.items?.map((item: any) => {
        return {
          column: item?.column || "",
          name: item?.name || "",
          type: item?.type || "",
          value: item?.value,
          // item?.type === "CHECKBOX" && item?.value === undefined
          //   ? false
          //   : item?.value === undefined
          //   ? ""
          //   : item?.value,
        };
      });
      console.log("dataQue", dataQue);
      return {
        active: true,
        end_date: data?.end_date || "",
        name: data?.name || "",
        template: data?.template || "",
        items: dataQue || "",
      };
    });
    console.log("questionsData", questionsData);
    const [response, error] = await NursingDiagnosisResulFromTemplate.create({
      data: {
        encounter: state.selectedEncounter.id,
        document: null,
        template: {
          questions: questionsData,
        },
      },
      apiToken: controller.apiToken,
    });

    if (response) {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params?.sequence]: response,
        },
        NursingDiagnosisSequence: null,
      });

      const [responseGroupDetail, errorGroupDetail] =
        await NursingDiagnosisResultGroupDetail.retrieve({
          pk: response?.document,
          apiToken: controller.apiToken,
        });

      if (responseGroupDetail) {
        controller.setState({
          NursingDiagnosisSequence: {
            ...state.NursingDiagnosisSequence,
            nursingDiagnosisSelected: responseGroupDetail,
          },
        });
      } else {
        controller.setState({
          NursingDiagnosisSequence: {
            ...state.NursingDiagnosisSequence,
            nursingDiagnosisSelected: [],
          },
        });
      }
    }
    if (error) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.sequence]: error },
        NursingDiagnosisSequence: null,
      });
    }
  } else if (params.action === "handleChange") {
    console.log("params.date", params.date);
    state.NursingDiagnosisSequence.nursingDiagnosistemplateQuestionsList[
      params.pIndex
    ].end_date = params.date;
    controller.setState({
      NursingDiagnosisSequence: {
        ...state.NursingDiagnosisSequence,
        nursingDiagnosistemplateQuestionsList: [
          ...state.NursingDiagnosisSequence
            .nursingDiagnosistemplateQuestionsList,
        ],
      },
    });
  }
};
