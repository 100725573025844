import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Segment,
  Button,
  Icon
} from 'semantic-ui-react'

const CardDrugClaimQAUX = (props: any) => {
    return(
      <div
        style={{ width : "100%", height: "100%" }}>
        <Segment>
          <div
            style={{display: "flex", justifyContent: "space-between"}}>
            
            <div
              style={{fontWeight: "bold", fontSize: "1.2rem"}}>
              {props.title}
            </div>
            <div
              style={{cursor: "pointer"}}>
              
              <Icon
                className="red"
                name={"close"}
                onClick={props.onClose}>
              </Icon>
            </div>
          </div>
          <hr>
          </hr>
          <div
            style={{display: "flex", width: "100%", height: "100%"}}>
            
            <div
              style={{cursor: "pointer", flex: 1}}>
              {props.subQATab}
            </div>
            <div
              style={{marginLeft: "10px", flex: 2.5}}>
              {props.subQAField}
            </div>
          </div>
          <div
            style={{display: "flex", justifyContent: "flex-end"}}>
            
            <Button
              color={"green"}
              disabled={props.statusRequest ? true : false}
              onClick={props.onSave}>
              Save
            </Button>
          </div>
        </Segment>
      </div>
    )
}

export default CardDrugClaimQAUX

export const screenPropsDefault = {"cannotPay":false,"cashAmountText":"14","citizenID":"31020000552","oweText":123,"patientNameAndHN":"HN: xxxxx นายทดสอบ","sumAmount":{"price":100,"reimbursable":42}}

/* Date Time : Wed Aug 03 2022 11:31:11 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width : \"100%\", height: \"100%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Segment",
      "parent": 0,
      "props": {
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.title"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 6,
      "void": true
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width: \"100%\", height: \"100%\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.subQATab"
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", flex: 1}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.subQAField"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\", flex: 2.5}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Button",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Save"
        },
        "color": {
          "type": "code",
          "value": "\"green\""
        },
        "disabled": {
          "type": "code",
          "value": "props.statusRequest ? true : false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSave"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Icon",
      "parent": 12,
      "props": {
        "className": {
          "type": "value",
          "value": "red"
        },
        "name": {
          "type": "code",
          "value": "\"close\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 14,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 45,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugClaimQAUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "cannotPay": false,
    "cashAmountText": "14",
    "citizenID": "31020000552",
    "oweText": 123,
    "patientNameAndHN": "HN: xxxxx นายทดสอบ",
    "sumAmount": {
      "price": 100,
      "reimbursable": 42
    }
  },
  "width": 60
}

*********************************************************************************** */
