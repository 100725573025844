import React from "react";
import PropTypes from "prop-types";
import * as CONSTANT from "../../utils/constant";
import * as Common from "../common";
import * as PTM from "../PTM";
import { Label } from "semantic-ui-react";

const CardReassessment = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [reassessmentData, setReassessmentData] = React.useState(
    []
  );
  const [subFormViewData, setSubFormViewerData] = React.useState([]);

  React.useImperativeHandle(ref, () => ({
    refresh: ({ encounterId } = {}) => {
      refresh({ encounterId });
    }
  }));

  React.useEffect(() => {
    refresh({ encounterId: props.encounterId });
  }, [props.encounterId]);

  React.useEffect(() => {
    formatFormViewData();
  }, [reassessmentData]);

  const refresh = ({ encounterId } = {}) => {
    getFormDataLatest({ encounterId });
  };

  const formatFormViewData = () => {
    let newObj = {...reassessmentData}
    newObj.education_need = YES_NO[newObj.education_need]
    newObj.barriers = YES_NO[newObj.barriers]
    newObj.readiness = YES_NO[newObj.readiness]
    newObj.education_evaluation = EDUCATION_EVALUATION[newObj.education_evaluation]
    newObj.follow_up = YES_NO[newObj.follow_up]
    setSubFormViewerData(newObj);
  };

  const getFormDataLatest = async ({ encounterId }) => {
    setIsLoading(true);
    const [data, error] = await props.controller.getFormDataLatest({
      encounterId: encounterId
        ? encounterId
        : props.patientData.ENCOUNTER.encounter_id,
      formCode: "CardReassessment",
      formVersion: "0.1"
    });
    if (data) {
      setStatus(data.status);
      setReassessmentData(data.data);
    }
    setIsLoading(false);
  };

  let statusColor = "black";
  if (status === CONSTANT.FORM_DATA_STATUS.DRAFT) {
    statusColor = "yellow";
  } else if (status === CONSTANT.FORM_DATA_STATUS.EDITED) {
    statusColor = "olive";
  } else if (status === CONSTANT.FORM_DATA_STATUS.CONFIRMED) {
    statusColor = "green";
  } else if (status === CONSTANT.FORM_DATA_STATUS.CANCELLED) {
    statusColor = "red";
  }

  let labelStatus = <Label color={statusColor}>{status}</Label>;

  return (
    <Common.CardLayout
      headerColor="grey"
      loading={isLoading}
      titleText="ประเมินผู้ป่วยหลังออกจากห้องตรวจ (OPD Reassessment)"
      onClose={props.onClose}
      titleContent={labelStatus}
    >
      {props.showSummary ? (
        <PTM.SubFormViewer
          fieldList={rmdFieldList}
          fieldMap={rmdFieldMap}
          data={subFormViewData}
        />
      ) : (
        "TODO ---> Implement ShowSummary = false"
      )}
    </Common.CardLayout>
  );
});

const EDUCATION_EVALUATION = {
  'EXP': 'เข้าใจดี/ทำได้ดี',
  'EAM': 'ยังมีข้อสงสัย/ทำได้บางส่วน ทบทวน',
  'VDO': 'ทำซ้ำในส่วนที่ยังไม่เข้าใจ หรือทำได้ไม่ดี',
  'LLT': 'ไม่เข้าใจ/ทำไม่ได้ต้องสอนใหม่',
}

const YES_NO = {
  "true": "Yes",
  "false": "No"
}

const rmdFieldList = [
  "user_fullname",
  "education_need",
  "barriers",
  "readiness",
  "education_way",
  "education_method",
  "education",
  "education_evaluation",
  "follow_up",
];

const rmdFieldMap = {
  "user_fullname": "ผู้บันทึก/แก้ไข",
  "education_need": "Patient and Family Education Need",
  "barriers": "ข้อจำกัดในการรับรู้",
  "readiness": "Readiness and Willingness",
  "education_way": "ผู้รับคำแนะนำ",
  "education_method": "วิธีการให้ความรู้",
  "education": "คำแนะนำที่ให้",
  "education_evaluation": "Education Evaluation",
  "follow_up": "Follow Up",
};

CardReassessment.defaultProps = {
  onClose: () => {},
  controller: {},
  encounterId: null,
  showSummary: false
};

CardReassessment.propTypes = {
  onClose: PropTypes.func,
  controller: PropTypes.object,
  encounterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showSummary: PropTypes.bool
};

export default React.memo(CardReassessment);
