import WasmController from "react-lib/frameworks/WasmController";
import SupplyTransferQueueList from "issara-sdk/apis/SupplyTransferQueueList_apps_MSD";
import SupplyTransferDetail from "issara-sdk/apis/SupplyTransferDetail_apps_MSD";
import moment from "moment";

export type State = {
  SupplyDeliverySequence?: {
    sequenceIndex?: any;
    deliveryQueue?: any[];
    deliveryManager?: any[];
    selectedTransferId?: number | null;
    coUser?: string;
    coUserToken?: string;
    supplyDeliveryActionLog?: any[];
    selectedEndDate: string | null;
    selectedStartDate: string | null;
    idSearch: any;
    checkedFilterDate: boolean;
    filterDivision: string | null;
    filterStatus: any[];
  } | null;
  successMessage?: any;
  buttonLoadCheck?: any;
  errorMessage?: any;
  selectedRecordViewIndex?: any;
};

export const StateInitial: State = {
  SupplyDeliverySequence: {
    deliveryQueue: [],
    deliveryManager: [],
    selectedTransferId: null,
    coUser: "",
    coUserToken: "",
    supplyDeliveryActionLog: [],
    filterStatus: ["REQUESTED", "PRINTED", "CHECKED"],
  },
  successMessage: null,
  errorMessage: null,
  selectedRecordViewIndex: 0,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  masterData: any;
  division?: number;
};

const LIMIT = 40;

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

const mapOptions = (list: any[], valueKey = "id") => {
  return list.map((item: any) => ({
    key: item.id,
    value: item[valueKey],
    text: item.name,
  }));
};

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  console.log("start getDeliveryList....0");
  if (!state.SupplyDeliverySequence) return;

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["supplyOrderStatus", {}],
        ["division", {}],
      ],
    },
  });
  console.log("getDeliveryList....0");
  await getDeliveryList(controller, params, true);
};

export const SearchAndSelect: Handler = async (controller, params) => {
  var state = controller.getState();
  if (!state.SupplyDeliverySequence) return;

  if (params?.action === "select") {
    console.log(" select deliveryQueue params?.id: ", params?.id);
    await getSupplyTransferDetail(controller, params);
  } else if (
    [
      "CHECK",
      "DELIVER",
      "UNDELIVER",
      "UNCHECK",
      "CANCEL",
      "RECEIVE",
      "UNRECEIVE",
      "REPRINT",
    ].includes(params?.action)
  ) {
    await updateSupplyTransferDetail(controller, params);
    await getSupplyTransferDetail(controller, params);
  } else if (params.action === "search") {
    const data = state.SupplyDeliverySequence;
    console.log("DeliveryList", getDeliveryList(controller, params));

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });
    console.log(" Search.... SupplyDeliverySequence");
    await getDeliveryList(controller, params);
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "SUCCESS",
      },
    });
  } else if (params.action === "clear") {
    const state = controller.getState();
    controller.setState({
      SupplyDeliverySequence: {
        ...state.SupplyDeliverySequence,
        idSearch: "",
        filterDivision: "",
        checkedFilterDate: false,
        selectedEndDate: null,
        selectedStartDate: null,
        filterStatus: [],
      },
    });
  }
};

const getDeliveryList = async (controller: any, params: any, init = false) => {
  console.log("getDeliveryList....1");
  const [r, e, n] = await SupplyTransferQueueList.list({
    params: {
      // pending_only: false,
      // limit: LIMIT,
      order_perform_div: controller.data.division,
    },
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
  });

  if (init) {
    controller.setState({
      SupplyDeliverySequence: {
        ...StateInitial.SupplyDeliverySequence,
        sequenceIndex: "SearchAndSelect",
        deliveryQueue: r ? r.items : [],
      },
    });
  } else {
    const state = controller.getState();
    let code = state.SupplyDeliverySequence.idSearch;
    let division = state.SupplyDeliverySequence.filterDivision;
    let click = state.SupplyDeliverySequence.checkedFilterDate;
    let selectedStartDate = state.SupplyDeliverySequence.selectedStartDate;
    let selectedEndDate = state.SupplyDeliverySequence.selectedEndDate;

    let date_s = selectedStartDate?.split("/")[0];
    let month_s = selectedStartDate?.split("/")[1];
    let year_s = selectedStartDate?.split("/")[2];
    let year_sp = +year_s - 543;
    let totalstartdate = year_sp + "-" + month_s + "-" + date_s;

    let date_e = selectedEndDate?.split("/")[0];
    let month_e = selectedEndDate?.split("/")[1];
    let year_e = selectedEndDate?.split("/")[2];
    let year_ep = +year_e - 543;
    let totalenddate = year_ep + "-" + month_e + "-" + date_e;

    console.log("code", code);
    console.log("division", division);
    console.log("click", click);
    console.log("selectedStartDate", selectedStartDate);
    console.log("selectedEndDate", selectedEndDate);
    console.log(
      "totalstartdate",
      totalstartdate,
      "- totalenddate",
      totalenddate
    );

    let filterDQ = (r ? r.items : [])
      ?.filter((item: any) => (code ? item.code?.includes(code) : true))
      ?.filter((item: any) =>
        division ? item.division_name === 
          (mapOptions(controller.data.masterData?.division).filter((item:any) => item.value === division))[0].text
        : true
      )
      ?.filter((item: any) =>
        click != true
          ? true
          : totalstartdate && totalenddate
          ? moment(item.printed).format("YYYY-MM-DD") >= totalstartdate &&
            moment(item.printed).format("YYYY-MM-DD") <= totalenddate
          : true
      );
    controller.setState({
      SupplyDeliverySequence: {
        ...state.SupplyDeliverySequence,
        deliveryQueue: filterDQ,
      },
    });
  }
};

const getSupplyTransferDetail = async (
  controller: any,
  params: any,
  init = false
) => {
  const state = controller.getState();
  const [r, error, n] = await SupplyTransferDetail.retrieve({
    pk: params?.id,
    params: {},
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
      device: controller.data.device,
    },
  });

  if (error) {
    console.log("error:...", error);
    controller.setState({
      errorMessage: { ...state.errorMessage, [params?.card]: error },
    });
  }

  controller.setState({
    SupplyDeliverySequence: {
      ...StateInitial.SupplyDeliverySequence,
      sequenceIndex: "SearchAndSelect",
      selectedTransferId: params?.id,
      deliveryManager: r ? r : {},
    },
  });
};

const updateSupplyTransferDetail = async (
  controller: any,
  params: any,
  init = false
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "LOADING",
    },
  });

  const [r, error, n] = await SupplyTransferDetail.update({
    pk: params?.id,
    params: {},
    data: {
      action: params?.action,
      co_user: params?.co_user,
      note: params?.note,
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
      device: controller.data.device,
    },
  });

  if (error) {
    console.log("error:...", error);
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "ERROR",
      },
      errorMessage: { ...state.errorMessage, [params?.card]: error },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "SUCCESS",
      },
      successMessage: { ...state.successMessage, [params?.card]: r },
      errorMessage: { ...state.errorMessage, [params?.card]: null },
    });
  }
};
