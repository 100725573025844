import WasmController from "react-lib/frameworks/WasmController";

// APIS
// INF
import CoveragePayerSentClaimGroupList from "issara-sdk/apis/CoveragePayerSentClaimGroupList_apps_INF";
import BillTransactionList from "issara-sdk/apis/BillTransactionList_apps_INF";
import BillTransactionSummary from "issara-sdk/apis/BillTransactionSummaryList_apps_INF";
import UpdateRefNoView from "issara-sdk/apis/UpdateRefNoView_apps_INF";
import CommonBillTransactionView from "issara-sdk/apis/CommonBillTransactionView_apps_INF";
import BillTransactionDetail from "issara-sdk/apis/BillTransactionDetail_apps_INF";

// Serializer
import BillTransactionIPDListSerialize from "issara-sdk/types/BillTransactionIPDListSerializer_apps_INF";

import moment from "moment";

export type State = Partial<{
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  masterOptions?: Record<string, any[]>;
  // sequence
  ExportEClaimFileSequence: Partial<{
    sequenceIndex: "START" | "ACTION" | null;
    filter: Partial<FilterType>;
    coveragePayerOptions: any[];
    billTransactionList: BillTransactionIPDListSerialize[];
    billTransactionSummary: {
      total_other_pay_price: number;
      total_paid_price: number;
      total_rows: number;
      total_sent_claim_price: number;
    };
    claimInfoDetail:
      | (Record<string, any> & {
          files: Record<Key16Files, any[]>;
        })
      | null;
  }> | null;
}>;

type FilterType = {
  arTransaction: number;
  arTransactionData: Record<string, any>;
  isSpecifyDate: boolean;
  startDate: string;
  endDate: string;
  coveragePayer: number;
  treatmentType: string;
  isHN: boolean;
  hn: string;
  isEncounter: boolean;
  encounter: number;
  isStatus: boolean;
  status: string;
  notSpecifyRefNo: boolean;
  patientId: number;
};

export type Key16Files = keyof typeof TABLE_16_FILES;

// Sequence
type SeqState = {
  sequence: "ExportEClaimFile";
  restart?: boolean;
  clear?: boolean;
};
// Handle Action
type ActionType =
  | { action: "SEARCH"; card: string }
  | { action: "EXPORT_DATA_16_FILE" }
  | { action: "SAVE_REF_NO"; refNo: string; card: string; onSuccess?: Function }
  | { action: "DESCRIPTION"; data: Record<string, any> }
  | { action: "EDIT_REF_NO"; refNo: string; card: string; errorKey: string }
  | {
      action: "CHANGE_VALUE";
      id: string;
      text: string;
      index: number;
      options: any[];
      type: Key16Files;
      value: number;
    };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(
  params: SeqType<Pick<SeqAct, K>>
) => any;

type Params<A extends ActionType["action"]> = Extract<
  ActionType,
  { action: A }
>;

export const StateInitial: State = {
  // sequence
  ExportEClaimFileSequence: {
    sequenceIndex: null,
  },
};

const Masters = [
  ["sentClaimChoice", {}],
  ["province", {}],
  ["district", {}],
];

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler<P = any, R = any> = (
  controller: WasmController<State, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  } as any);

  const coveragePayerResult = await CoveragePayerSentClaimGroupList.list({
    apiToken: controller.apiToken,
    params: {},
  });

  const coveragePayerOptions = [...(coveragePayerResult?.[0]?.items || [])].map(
    (item: any) => ({
      key: item.id,
      value: item.id,
      text: `[${item.code}] ${item.name}`,
    })
  );

  controller.setState(
    {
      ExportEClaimFileSequence: {
        sequenceIndex: "ACTION",
        coveragePayerOptions,
        filter: {
          coveragePayer: coveragePayerOptions?.[0]?.value,
        },
      },
    }
    // () =>
    //   controller.handleEvent({ message: "RunSequence", params: { ...params } })
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  if (params.action === "SEARCH") {
    HandleSearch(controller, params);
  } else if (params.action === "EXPORT_DATA_16_FILE") {
    HandleExportData16File(controller, params);
  } else if (params.action === "SAVE_REF_NO") {
    HandleSaveRefNo(controller, params);
  } else if (params.action === "DESCRIPTION") {
    HandleDescription(controller, params);
  } else if (params.action === "EDIT_REF_NO") {
    HandleEditRefNo(controller, params);
  } else if (params.action === "CHANGE_VALUE") {
    HandleChangeValue(controller, params);
  }
};

/* ------------------------------------------------------ */

/*                         Action                         */

/* ------------------------------------------------------ */
const HandleSearch: Handler<Params<"SEARCH">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const filter = state.ExportEClaimFileSequence?.filter || {};

  const formatParams = (isKey: keyof FilterType, value: any) => {
    return ((filter as any)[isKey] || undefined) && value;
  };

  const urlParams = {
    ar_transaction: filter.arTransaction,
    start_date: formatParams("isSpecifyDate", filter.startDate),
    end_date: formatParams("isSpecifyDate", filter.endDate),
    coverage_payer_sent_claim_group: filter.coveragePayer,
    // treatment type
    // hn
    encounter: formatParams("isEncounter", filter.encounter),
    status: formatParams("isStatus", filter.status),
    // ไม่ระบุ Ref No.
  };

  const [ipdList] = await BillTransactionList.list({
    apiToken: controller.apiToken,
    params: urlParams,
  });

  const [ipdSummary] = await BillTransactionSummary.get({
    apiToken: controller.apiToken,
    params: urlParams,
  });

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "SUCCESS",
    },
    ExportEClaimFileSequence: {
      ...state.ExportEClaimFileSequence,
      billTransactionList: ipdList?.items || [],
      billTransactionSummary: ipdSummary || {},
      // billTransactionSummary: {
      //   total_rows: 4,
      //   total_sent_claim_price: 113606,
      //   total_other_pay_price: 0,
      //   total_paid_price: 208720,
      // },
    },
  });
};

export const HandleExportData16File: Handler<
  Params<"EXPORT_DATA_16_FILE">
> = async (controller, params) => {
  const XLSX = await import("xlsx");

  // const bookType = "csv"

  const blobFiles = Object.entries(HEADERS).map(([key, value]) => {
    let workbook = XLSX.utils.book_new();
    workbook.SheetNames.push(key);

    const sheet_data = [value, ...(DATA as any)[key]];
    const sheet = XLSX.utils.aoa_to_sheet(sheet_data);

    workbook.Sheets[key] = sheet;

    const output = XLSX.write(workbook, { bookType: "csv", type: "binary" });

    return {
      filename: key,
      output,
    };
  });

  // console.log(blobFiles)
  const JSZip = await (await import("jszip")).default;
  const zip = new JSZip();

  const zipFilename = "Export data 16 แฟ้ม";

  for (const data of blobFiles) {
    // const arrayBuffer = await blobToArrayBuffer(data.blob);

    zip.file(`${data.filename}.${"csv"}`, data.output, { binary: true });
  }

  zip.generateAsync({ type: "blob" }).then(function (content) {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(content);
    link.download = zipFilename;
    link.click();
    link.remove();
  });
};

const HandleSaveRefNo: Handler<Params<"SAVE_REF_NO">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const result = await UpdateRefNoView.put({
    apiToken: controller.apiToken,
    data: {
      ar_transaction: state.ExportEClaimFileSequence?.filter?.arTransaction, //# ar transaction id(invoice group)
      ref_no: params.refNo, //# ref no(only numeric)
    },
  });

  if (result[1]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.card]: result[1],
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });

    params.onSuccess?.();
  }

  HandleSearch(controller, params as any);
};

const HandleEditRefNo: Handler<Params<"EDIT_REF_NO">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const claim = state.ExportEClaimFileSequence;
  const detail = claim?.claimInfoDetail;

  const bill = await BillTransactionDetail.update({
    apiToken: controller.apiToken,
    pk: detail?.id,
    data: {
      id: detail?.id,
      ref_no: params.refNo,
    },
  });

  if (bill[1]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.errorKey]: bill[1],
      },
    });
  } else {
    controller.setState({
      ExportEClaimFileSequence: {
        ...claim,
        billTransactionList: (claim?.billTransactionList || []).map((item) =>
          item.id === detail?.id ? { ...item, ref_no: bill[0].ref_no } : item
        ),
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });
  }
};

const HandleDescription: Handler<Params<"DESCRIPTION">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const bill = await CommonBillTransactionView.get({
    apiToken: controller.apiToken,
    invoice_no: params.data.invoice_no,
  });

  controller.setState({
    ExportEClaimFileSequence: {
      ...state.ExportEClaimFileSequence,
      claimInfoDetail: {
        ...(bill[0] || {}),
        id: params.data.id,
        ref_no: params.data.ref_no,
        patient_name: params.data.name,
        files: {
          ins: [],
          pat: [
            // {
            //   HCODE: "15199",
            //   HN: "65015261",
            //   CHANGWAT: "กรุงเทพมหานคร",
            //   province: 1,
            //   AMPHUR: "เขตภาษีเจริญ",
            //   district: 22,
            //   DOB: "20/01/2527",
            //   SEX: "",
            //   MARRIAGE: "S",
            //   OCCUPA: "",
            //   NATION: "",
            //   PERSON_ID: "",
            //   NAMEPAT: "",
            //   TITLE: "",
            //   FNAME: "อุมาพร",
            //   LNAME: "ณ ทดสอบ",
            //   IDTYPE: "",
            // },
          ],
          opd: [],
          orf: [],
          odx: [
            {
              HN: "66000086",
              DATEDX: "29/3/2023",
              CLINIC: "11",
              DIAG: "F4582",
              DXTYPE: "1",
              DRDX: "0.9327",
              PERSION_ID: "3101203636923",
              SEQ: "1",
            },
          ],
          oop: [],
          ipd: [],
          irf: [],
          idx: [],
          iop: [],
          cht: [
            {
              HN: "66000086",
              AN: "",
              DATE: "29/3/2023",
              TOTAL: "1450",
              PAID: "320",
              PTTYPE: "OF",
              PERSON_ID: "3101203636923",
              SEQ: "1",
            },
          ],
          cha: [],
          aer: [],
          adp: [],
          lvd: [],
          dru: [
            {
              HCODE: "15199",
              HN: "66000086",
              AN: "",
              CLINIC: "11",
              PERSON_ID: "3102001843449",
              DATE_SERV: "29/3/2023",
              DID: "1AMOX500",
              DIDNAME: "AMOXICILLIN capsule 500 MG",
              AMOUNT: "21",
              DRUGPRIC: "4",
              DRUGCOST: "1.69",
              DIDSTD: "100176000004493120181479",
              UNIT: "CAPSULE",
              UNIT_PACK: "",
              SEQ: "69",
              DRUGREMARK: "",
              PA_NO: "",
              TOTCOPAY: "0",
              USE_STATUS: "2",
              TOTAL: "84",
            },
          ],
        },
      },
    },
  });
};

const HandleChangeValue: Handler<Params<"CHANGE_VALUE">> = (
  controller,
  params
) => {
  const state = controller.getState();

  const claim = state.ExportEClaimFileSequence;
  const { value, type, id, index, text } = params;

  const name =
    params.options?.find((option: any) => option.id === value)?.text || "";

  if (id === "province") {
    (claim as any).claimInfoDetail.files[type][index]["district"] = null;
    (claim as any).claimInfoDetail.files[type][index]["AMPHUR"] = "";
  }

  (claim as any).claimInfoDetail.files[type][index][id] = value;
  (claim as any).claimInfoDetail.files[type][index][text] = name;

  controller.setState({
    ExportEClaimFileSequence: {
      ...claim,
      claimInfoDetail: {
        ...((claim?.claimInfoDetail || {}) as any),
      },
    },
  });
};

// Utils

// Download File
const s2ab = (s: any) => {
  let buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  let view = new Uint8Array(buf); //create uint8array as viewer
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
};

const blobToArrayBuffer = (blob: Blob) => {
  return new Promise<ArrayBuffer>(function (resolve) {
    const reader = new FileReader();

    reader.onloadend = function () {
      resolve(reader.result as ArrayBuffer);
    };

    reader.readAsArrayBuffer(blob);
  });
};

export const TABLE_16_FILES = {
  ins: {
    title: "ข้อมูลผู้มีสิทธิการรักษาพยาบาล",
    headers: [
      "HN",
      "INSCL",
      "SUBTYPE",
      "CID",
      "DATEIN",
      "DATEEXP",
      "HOSPMAIN",
      "HOSPSUB",
      "GOVCODE",
      "GOVNAME",
      "PERMITNO",
      "DOCNO",
      "OWNRPID",
      "OWNNAME",
      "AN",
      "SEQ",
      "SUBINSCL",
      "RELINSCL",
    ],
    widths: "80,45,60,70,80,80,70,70,,,80,,100,80,75,45,60,70,100",
    required: [],
  },
  pat: {
    title: "ข้อมูลผู้ป่วยกลาง",
    headers: [
      "HCODE",
      "HN",
      "CHANGWAT",
      "AMPHUR",
      "DOB",
      "SEX",
      "MARRIAGE",
      "OCCUPA",
      "NATION",
      "PERSON_ID",
      "NAMEPAT",
      "TITLE",
      "FNAME",
      "LNAME",
      "IDTYPE",
    ],
    widths: "70,85,140,170,90,110,100,,,,,,,,,100",
    required: ["CHANGWAT", "AMPHUR", "SEX"],
  },
  opd: {
    title: "ข้อมูลการมารับบริการผู้ป่วยนอก",
    headers: ["HN", "CLINIC", "DATEOPD", "TIMEOPD", "SEQ", "UUC"],
    widths: ",,,,,,100",
    required: [],
  },
  orf: {
    title: "ข้อมูลผู้ป่วยนอกส่งต่อ",
    headers: ["HN", "DATEOPD", "CLINIC", "REFER", "REFERTYPE", "SEQ"],
    widths: ",,,,,,100",
    required: [],
  },
  odx: {
    title: "ข้อมูลวินิจฉัยโรคผู้ป่วยนอก",
    headers: [
      "HN",
      "DATEDX",
      "CLINIC",
      "DIAG",
      "DXTYPE",
      "DRDX",
      "PERSION_ID",
      "SEQ",
    ],
    widths: ",,,,,,,,100",
    required: [],
  },
  oop: {
    title: "ข้อมูลหัตถการผู้ป่วยนอก",
    headers: ["HN", "DATEOPD", "CLINIC", "OPER", "DROPID", "PERSON_ID", "SEQ"],
    widths: ",,,,,,,100",
    required: [],
  },
  ipd: {
    title: "ข้อมูลผู้ป่วยใน",
    headers: [
      "HN",
      "AN",
      "DATEADM",
      "TIMEADM",
      "DATEDSC",
      "TIMEDSC",
      "DISCHS",
      "DISCHT",
      "WARDDSC",
      "DEPT",
      "ADM_W",
      "UUC",
      "SVCTYPE",
    ],
    widths: ",,,,,,,,,,,,,100",
    required: [],
  },
  irf: {
    title: "ข้อมูลผู้ป่วยในที่ต้องส่งต่อ",
    headers: ["AN", "REFER", "REFERTYPE"],
    widths: ",,,100",
    required: [],
  },
  idx: {
    title: "ข้อมูลวินิจฉัยโรคผู้ป่วยใน",
    headers: ["AN", "DIAG", "DXTYPE", "DRDX"],
    widths: ",,,,100",
    required: [],
  },
  iop: {
    title: "ข้อมูลวินิจฉัยโรคผู้ป่วยใน",
    headers: [
      "AN",
      "OPER",
      "OPTYPE",
      "DROPID",
      "DATEIN",
      "TIMEIN",
      "DATEOUT",
      "TIMEOUT",
    ],
    widths: ",,,,,,,,100",
    required: [],
  },
  cht: {
    title: "ข้อมูลการเงิน (แบบสรุป)",
    headers: [
      "HN",
      "AN",
      "DATE",
      "TOTAL",
      "PAID",
      "PTTYPE",
      "PERSON_ID",
      "SEQ",
    ],
    widths: ",,,,,,,,100",
    required: [],
  },
  cha: {
    title: "ข้อมูลการเงิน (แบบรายละเอียด)",
    headers: ["HN", "AN", "DATE", "CHRGITEM", "AMOUNT", "PERSON_ID", "SEQ"],
    widths: ",,,,,,,100",
    required: [],
  },
  aer: {
    title: "ข้อมูลอุบัติเหตุ ฉุกเฉิน และรับส่งเพื่อรักษา",
    headers: [
      "HN",
      "AN",
      "DATEOPD",
      "AUTHAE",
      "AEDATE",
      "AETIME",
      "AETYPE",
      "REFER_NO",
      "REFMANI",
      "IREFTYPE",
      "REFMAINO",
      "OREFTYPE",
      "UCAE",
      "EMTYPE",
      "SEQ",
    ],
    widths: "",
    required: [],
  },
  adp: {
    title: "ข้อมูลค่าใช้จ่ายเพิ่ม และบริการที่ยังไม่ได้จัดหมวด",
    headers: [
      "HN",
      "AN",
      "DATEOPD",
      "TYPE",
      "CODE",
      "QTY",
      "RATE",
      "SEQ",
      "CAGCODE",
      "DOSE",
      "CA_TYPE",
      "SERIALNO",
      "TOTCOPAY",
      "USE_STATUS",
      "TOTAL",
      "QTYDAY",
    ],
    widths: "",
    required: [],
  },
  lvd: {
    title: "ข้อมูลกรณีที่ผู้ป่วยมีการลากลับบ้าน (Leave day)",
    headers: [
      "SEQLVD",
      "AN",
      "DATEOUT",
      "TIMEOUT",
      "DATEIN",
      "TIMEIN",
      "QTYDAY",
    ],
    widths: ",,,,,,,100",
    required: [],
  },
  dru: {
    title: "ข้อมูลการใช้ยา",
    headers: [
      "HCODE",
      "HN",
      "AN",
      "CLINIC",
      "PERSON_ID",
      "DATE_SERV",
      "DID",
      "DIDNAME",
      "AMOUNT",
      "DRUGPRIC",
      "DRUGCOST",
      "DIDSTD",
      "UNIT",
      "UNIT_PACK",
      "SEQ",
      "DRUGREMARK",
      "PA_NO",
      "TOTCOPAY",
      "USE_STATUS",
      "TOTAL",
    ],
    widths:
      "70,85,70,120,120,80,100,100,75,75,75,210,85,85,75,100,85,75,75,75,100",
    required: [],
  },
} as const;

export const HEADERS = {
  ins: [
    "HN",
    "INSCL",
    "SUBTYPE",
    "CID",
    "DATEIN",
    "DATEEXP",
    "HOSPMAIN",
    "HOSPSUB",
    "GOVCODE",
    "GOVNAME",
  ],
  pat: [
    "HCODE",
    "HN",
    "CHANGWAT",
    "AMPHUR",
    "DOB",
    "SEX",
    "MARRIAGE",
    "OCCUPA",
    "NATION",
    "PERSON_ID",
    "NAMEPAT",
    "TITLE",
    "FNAME",
    "LNAME",
    "IDTYPE",
  ],
  opd: ["HN", "CLINIC", "DATEOPD", "TIMEOPD", "SEQ", "UUC"],
  orf: ["HN", "DATEOPD", "CLINIC", "REFER", "REFERTYPE", "SEQ"],
  odx: ["HN", "DATEDX", "CLINIC", "DIAG", "DXTYPE", "DRDX", "PERSON_ID", "SEQ"],
  oop: ["HN", "DATEOPD", "CLINIC", "OPER", "DROPID", "PERSON_ID", "SEQ"],
  ipd: [
    "HN",
    "AN",
    "DATEADM",
    "TIMEADM",
    "DATEDSC",
    "TIMEDSC",
    "DISCHS",
    "DISCHT",
    "WARDDSC",
    "DEPT",
    "ADM_W",
    "UUC",
  ],
  irf: ["AN", "REFER", "REFERTYPE"],
  idx: ["AN", "DIAG", "DXTYPE", "DRDX"],
  iop: [
    "AN",
    "OPER",
    "OPTYPE",
    "DROPID",
    "DATEIN",
    "TIMEIN",
    "DATEOUT",
    "TIMEOUT",
  ],
  cht: ["HN", "AN", "DATE", "TOTAL", "PAID", "PTTYPE", "PERSON_ID", "SEQ"],
  cha: ["HN", "AN", "DATE", "CHRGITEM", "AMOUNT", "PERSON_ID", "SEQ"],
  aer: [
    "HN",
    "AN",
    "DATEOPD",
    "AUTHAE",
    "AEDATE",
    "AETIME",
    "AETYPE",
    "REFER_NO",
    "REFMAINI",
    "IREFTYPE",
    "REFMAINO",
    "OREFTYPE",
    "UCAE",
    "EMTYPE",
    "SEQ",
  ],
  adp: [
    "HN",
    "AN",
    "DATEOPD",
    "TYPE",
    "CODE",
    "QTY",
    "RATE",
    "SEQ",
    "CAGCODE",
    "DOSE",
    "CA_TYPE",
    "SERIALNO",
    "TOTCOPAY",
    "USE_STATUS",
    "TOTAL",
    "QTYDAY",
  ],
  lvd: ["SEQLVD", "AN", "DATEOUT", "TIMEOUT", "DATEIN", "TIMEIN", "QTYDAY"],
  dru: [
    "HCODE",
    "HN",
    "AN",
    "CLINIC",
    "PERSON_ID",
    "DATE_SERV",
    "DID",
    "DIDNAME",
  ],
};

export const DATA = {
  adp: [
    [
      "55-254",
      "",
      "10/10/2022",
      "12",
      "64102",
      "4",
      "350",
      "1",
      "",
      "",
      "",
      "",
      "0.00",
      "1",
      "1400.00",
      "0",
    ],
    [
      "55-254",
      "",
      "10/10/2022",
      "8",
      "55020",
      "1",
      "50",
      "1",
      "",
      "",
      "",
      "",
      "0.00",
      "1",
      "50.00",
      "0",
    ],
  ],
  aer: [
    [
      "55-254",
      "",
      "10/10/2022",
      "",
      "",
      "",
      "",
      "",
      "",
      "0000",
      "",
      "0000",
      "",
      "",
      "1",
    ],
    [
      "47-759",
      "",
      "10/10/2022",
      "DJJ78X",
      "",
      "",
      "",
      "",
      "",
      "0000",
      "",
      "0000",
      "",
      "",
      "1",
    ],
  ],
  cha: [
    ["55-254", "", "10/10/2022", "C1", "50.00", "3101203636923", "1"],
    ["55-254", "", "10/10/2022", "D1", "1400.00", "3101203636923", "1"],
  ],
  cht: [
    [
      "55-254",
      "",
      "10/10/2022",
      "1450.00",
      "320.00",
      "OF",
      "3101203636923",
      "1",
    ],
    ["47-759", "", "10/10/2022", "21.00", "0.00", "OF", "3650100948676", "10"],
  ],
  dru: [
    [
      "15199",
      "42-6874",
      "",
      "11",
      "3102001843449",
      "10/10/2022",
      "1AMOX500",
      "AMOXICILLIN capsule 500 MG",
    ],
    [
      "15199",
      "42-20222",
      "",
      "11",
      "3420600064353",
      "10/10/2022",
      "3NAIRR1000",
      "SODIUM CHLORIDE for irrigation, solution 0.9%, 1,000 ml",
    ],
  ],
  idx: [],
  ins: [
    [
      "41-15649",
      "OFC",
      "11",
      "",
      "",
      "",
      "",
      "",
      "20004",
      "สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน",
    ],
    [
      "41-15857",
      "OFC",
      "11",
      "",
      "",
      "",
      "",
      "",
      "20316",
      "จุฬาลงกรณ์มหาวิทยาลัย",
    ],
  ],
  iop: [],
  ipd: [],
  irf: [],
  lvd: [],
  odx: [
    [
      "55-254",
      "10/10/2022",
      "11",
      "F4582",
      "1",
      "ท.9327",
      "3101203636923",
      "1",
    ],
    [
      "42-21575",
      "10/10/2022",
      "11",
      "K089",
      "1",
      "ท.3682",
      "3100902754711",
      "100",
    ],
  ],
  oop: [
    ["55-254", "10/10/2022", "11", "8931", "ท.9327", "3101203636923", "1"],
    ["47-759", "10/10/2022", "11", "8931", "ท.2261", "3650100948676", "10"],
  ],
  opd: [
    ["55-254", "11", "10/10/2022", "1334", "1", "1"],
    ["47-759", "11", "10/10/2022", "1026", "10", "1"],
  ],
  orf: [],
  pat: [
    [
      "15199",
      "41-15649",
      "10",
      "05",
      "4/12/1948",
      "2",
      "1",
      "208",
      "099",
      "3669700098400",
      "นงลักษณ์ น้ำแก้ว",
      "น.ส.",
      "นงลักษณ์",
      "น้ำแก้ว",
      "1",
    ],
    [
      "15199",
      "41-15857",
      "",
      "",
      "26/3/1962",
      "2",
      "1",
      "201",
      "099",
      "3101800945128",
      "สุวรรณา แซ่เฮ้ง",
      "น.ส.",
      "สุวรรณา",
      "แซ่เฮ้ง",
      "1",
    ],
  ],
};
