import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  SyntheticEvent,
} from "react";
import { useHistory } from "react-router-dom";

import {
  Modal,
  Checkbox,
  Radio,
  Button,
  Icon,
  Dropdown,
  Input,
  Menu,
  Dimmer,
  Loader,
  Label,
  Header,
  Image,
} from "semantic-ui-react";

import moment from "moment";

// Framework
import { Table } from "react-lib/frameworks/Table";

// UX
import CardDrugOrderWorkingUX from "./CardDrugOrderWorkingUX";
import CardDrugOrderQueue from "react-lib/apps/HISV3/TPD/CardDrugOrderQueue";
import CardDrugContinuePlan from "react-lib/apps/HISV3/TPD/CardDrugContinuePlan";
import CardDrugTransferRequest from "react-lib/apps/HISV3/TPD/CardDrugTransferRequest";
import ModReprintDrugOrderWorkingUX from "react-lib/apps/HISV3/TPD/ModReprintDrugOrderWorkingUX";
import CardDrugOrderRefillUX from "./CardDrugOrderRefillUX";

import CardMedicationErrorWorking from "./CardMedicationErrorWorking";
import DrugActionLog from "./DrugActionLog";

import SubDrugOrderToolbox from "./SubDrugOrderToolbox";
import ModConfirmReprint from "./ModConfirmReprint";
import ModDrugOrderActionNoteUX from "./ModDrugOrderActionNoteUX";
import SubDrugOrderStatusAlert from "react-lib/apps/HISV3/TPD/SubDrugOrderStatusAlert";
import ModDrugOrderRejectUX from "react-lib/apps/HISV3/TPD/ModDrugOrderRejectUX";
import ModErrorRejectUX from "react-lib/apps/HISV3/TPD/ModErrorRejectUX";
import CardReturnDrug from "react-lib/apps/HISV3/PTM/CardReturnDrug";
import ModDrugRecommendation from "./ModDrugRecommendation";
import ModLotNoExp from "./ModLotNoExp";
import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";

import ModDrugInteraction from "./ModDrugInteraction";
import ModDrugDisease from "./ModDrugDisease";
import ModDrugLabInteraction from "./ModDrugLabInteraction";
import ModDescriptionUD from "./ModDescriptionUD";

// Common
import {
  DateTextBox,
  EmployeeToken,
  ErrorMessage,
  ModConfirm,
  ModInfo,
} from "react-lib/apps/common";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import EditorColumn from "react-lib/appcon/common/EditorColumn";
import SnackMessage from "react-lib/apps/common/SnackMessage";

const CARD_MOD_REPRINT = "CardModReprint";
const CARD_DRUG_ORDER_WORKING = "CardDrugOrderWorking";
const CARD_DRUG_ORDER_REFILL = "CardDrugOrderRefill";
const CARD_REPRINT_DRUG_ORDER_WORKING = "ModReprintDrugOrderWorkingUX";

const CloseIconStyle = {
  cursor: "pointer",
  padding: "2px",
  right: "13px",
  position: "absolute",
  zIndex: 1,
};

const COLORS = {
  red: "#FF0000",
  yellow: "#F7AC08",
  blue: "#0072BC",
  light_blue: "#5DBCD2",
  green: "#27AE60",
  violet: "#8649E9",
  grey: "#746A6A",
  orange: "#F2711C",
  black: "#000000",
} as const;

const ORDER_TYPE_COLORS = {
  STAT: COLORS.red,
  ONE_DAY: COLORS.yellow,
  ONE_DOSE: COLORS.yellow,
  CONTINUE: COLORS.blue,
  CONTINUE_PLAN: COLORS.light_blue,
  HOME_OPD: COLORS.green,
  HOME_IPD: COLORS.green,
  OPERATING: COLORS.violet,
  REFILL: COLORS.light_blue,
} as const;

const ORDER_STATUS_COLORS = {
  REQUESTED: COLORS.red,
  PRINTED: COLORS.yellow,
  CHECKED: COLORS.green,
  TRANSPORTED: COLORS.blue,
  RECEIVED: COLORS.blue,
  REJECTED: COLORS.grey,
  DIVIDED: COLORS.light_blue,
  REFILL_PLANNED: COLORS.black,
} as const;

const ORDER_PAYMENT_STATUS = {
  PENDING: COLORS.orange,
  READY: COLORS.green,
  PAID: COLORS.green,
  BILL: COLORS.green,
};

const IMAGES = {
  //refill
  refill: "/static/images/drugRefill/refill.png",
  view: "/static/images/drugRefill/view.png",
};

const CardDrugOrderWorking = (props: any) => {
  console.log("Init CardDrugOrderWorking ");
  const [forwardAction, setForwardAction] = useState<string | null>(null);
  const [backwardAction, setBackwardAction] = useState<string | null>(null);
  const [buttonLoadKey, setButtonLoadKey] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedDelivery, setSelectedDelivery] = useState<string | null>(null);
  const [isReprint, setIsReprint] = useState<boolean>(false);

  const [forwardColor, setForwardColor] = useState("green");
  const [openErrorReject, setOpenErrorReject] = useState<boolean>(false);
  const [dataSelectedRow, setDataSelectedRow] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string | null | any[]>(null);
  const [multiCheckbox, setMultiCheckbox] = useState<number[]>([]);
  // Mod
  const [openModalLog, setOpenModelLog] = useState(false);
  const [openModHomeMedUnPaid, setOpenModHomeMedUnPaid] = useState(false);
  const [openModReturnDrug, setOpenModReturnDrug] = useState<boolean>(false);
  const [openModRefillDrug, setOpenModRefillDrug] = useState<boolean>(false);
  const [openModReprint, setOpenModeReprint] = useState<boolean>(false);
  const [openModRefill, setOpenModRefill] = useState<boolean>(false);
  const [openModRefillLog, setOpenModRefillLog] = useState<boolean>(false);
  const [openCancelRefill, setOpenCancelRefill] = useState<boolean>(false);
  const [openRefillPlanned, setOpenRefillPlanned] = useState<boolean>(false);
  const [openModLotNo, setOpenModLotNo] = useState<boolean>(false);
  const [openDrugRequest, setOpenDrugRequest] = useState<boolean>(false);
  const [modDrugDesc, setModDrugDesc] = useState<{
    open?: boolean;
    desc?: string;
  }>({});

  const [tab, setTab] = useState("thai");

  const history = useHistory();

  const actionButton: { [action: string]: string } = {
    PRINT: "VERIFY AND PRINT",
    VERIFY: "VERIFY",
    CHECK: "CHECK",
    DELIVER: "DELIVER",
    RECEIVE: "RECEIVE",
    UNVERIFY: "UNVERIFY",
    UNDIVIDE: "UNDIVIDE",
    UNCHECK: "UNCHECK",
    UNDELIVER: "UNDELIVER",
    CANCEL: "CANCEL",
    REJECT: "REJECT",
    WAIVE: "WAIVE",
  };

  useEffect(() => {
    setOpenCancelRefill(false);
    props.DrugRefillSequence?.appointments.forEach((item: any) => {
      if (item?.refill != null) {
        setOpenCancelRefill(true);
      }
    });
  }, [props.DrugRefillSequence?.appointments]);

  useEffect(() => {
    if (props.successMessage?.[CARD_DRUG_ORDER_REFILL] != null) {
      if (props.successMessage?.[CARD_DRUG_ORDER_REFILL]?.edit) {
        setOpenModRefillDrug(false);
      }
      // setOpenModRefillDrug(false);
      props.setProp(`successMessage.${CARD_DRUG_ORDER_REFILL}`, null);
    }
  }, [props.successMessage?.[CARD_DRUG_ORDER_REFILL]]);

  useEffect(() => {
    props.runSequence({ sequence: "DrugOrderAction", restart: true });
    return () => {
      props.runSequence({ sequence: "DrugOrderAction", clear: true });
    };
  }, []);

  useEffect(() => {
    return () => {
      props.runSequence({ sequence: "ReturnOrder", clear: true });
    };
  }, []);

  useEffect(() => {
    if (props.drugDelivery?.showDrugDelivery) {
      setSelectedDelivery("drugDelivery");
    } else {
      setSelectedDelivery(null);
    }
  }, [props.drugDelivery?.showDrugDelivery]);

  useEffect(() => {
    if (props?.DrugOrderActionSequence?.isReprintSuccess) {
      setOpenModeReprint(false);
      props.setProp("DrugOrderActionSequence", {
        ...props.DrugOrderActionSequence,
        printPharma: false,
        printPatient: false,
        printLabel: false,
        language: "thai",
        noteReprint: "",
        isReprintSuccess: false,
      });
      setTab("thai");
    }
  }, [props.DrugOrderActionSequence?.isReprintSuccess]);

  useEffect(() => {
    props.onEvent({ message: "GetDrugTransferRequestList" });
  }, []);

  useEffect(() => {
    const allowed_actions = props.drugOrder?.allowed_actions || [];
    if (allowed_actions.includes("PRINT")) setForwardAction("PRINT");
    else if (allowed_actions.includes("VERIFY")) setForwardAction("VERIFY");
    else if (allowed_actions.includes("CHECK")) setForwardAction("CHECK");
    else if (allowed_actions.includes("DELIVER")) setForwardAction("DELIVER");
    else if (allowed_actions.includes("RECEIVE")) setForwardAction("RECEIVE");

    if (allowed_actions.includes("UNVERIFY")) setBackwardAction("UNVERIFY");
    else if (allowed_actions.includes("UNDIVIDE"))
      setBackwardAction("UNDIVIDE");
    else if (allowed_actions.includes("UNCHECK")) setBackwardAction("UNCHECK");
    else if (allowed_actions.includes("UNDELIVER"))
      setBackwardAction("UNDELIVER");
    else if (allowed_actions.includes("CANCEL")) setBackwardAction("CANCEL");
    else if (allowed_actions.includes("REJECT")) setBackwardAction("REJECT");

    if (
      props.drugOrder?.order_payment_status !== "PAID" &&
      props.drugOrder?.status === "CHECKED" &&
      props.drugOrder?.type === "HOME_OPD"
    ) {
      setForwardAction("WAIVE");
      setForwardColor("yellow");
      setOpenModHomeMedUnPaid(true);
    } else {
      setForwardColor("green");
      setOpenModHomeMedUnPaid(false);
    }
  }, [props.drugOrder]);

  useEffect(() => {
    const handleSetDrugState = async () => {
      // const findDivision = props.masterOptions?.divisionPharma.find(
      //   (item: any) => item.text === props.drugOrder?.order_perform_div
      // );
      // const findDivision = props.masterOptions?.divisionPharma.slice(-1)[0];
      const findDivision = props.masterOptions?.divisionPharma.find(
        (option: any) => option.value !== props.selectedDivision.id
      );

      await props.setProp(
        `DrugTransferRequestSequence.sequenceIndex`,
        "SaveDrugTransferRequest"
      );
      await props.setProp(
        `DrugTransferRequestSequence.selectedDivisionPharma`,
        findDivision?.value
      );
      await props.setProp(
        "DrugTransferRequestSequence.selectedDrugSearch.id",
        selectedRow?.product
      );

      props.setProp("DrugTransferRequestSequence.selectedDrugDose", "1", () => {
        props.runSequence({ sequence: "DrugTransferRequest", action: "add" });
      });
    };

    if (
      openDrugRequest &&
      props.masterOptions?.divisionPharma?.length &&
      props.drugOrder?.order_perform_div
    ) {
      handleSetDrugState();
    }
  }, [
    props.masterOptions?.divisionPharma,
    openDrugRequest,
    props.drugOrder?.order_perform_div,
    selectedRow,
  ]);

  useEffect(() => {
    const handleDataInitial = async () => {
      const contains = (array: string[]) => {
        return !!Object.entries(ORDER_TYPE_COLORS).find((item) =>
          array.includes(item[0])
        );
      };
      const delivery = contains(["HOME"])
        ? "patient"
        : contains(["STAT", "ONE_DAY", "ONE_DOSE", "CONTINUE"])
        ? "transporter"
        : props.drugOrder.is_telemed
        ? "drugDelivery"
        : null;

      await props.setProp("drugDelivery.token", "");

      await props.setProp("drugDelivery.employeeName", "");

      handleSelectedDelivery(delivery);
    };
    if (props.drugOrder?.pk) {
      handleDataInitial();
    }
  }, [props.drugOrder?.pk]);

  // MedReconcile Check
  // useEffect(() => {
  //   console.log("useEffect props.selectedEncounter: ", props.selectedEncounter);
  //   console.log("useEffect props.divisionType: ", props.divisionType);
  //   let roleTypes = props.django?.user?.role_types || [];
  //   if (
  //     ["หอผู้ป่วย"].includes(props.divisionType) &&
  //     (roleTypes || []).includes("DOCTOR") &&
  //     props.selectedEncounter?.type === "IPD"
  //   ) {
  //     let callFrom = "DPI";
  //     if (props.selectedEncounter?.id) {
  //       props.onEvent({
  //         message: "GetMedReconcileCheck",
  //         params: { encounter: props.selectedEncounter, callForm: callFrom },
  //       });
  //     }
  //   } else {
  //     console.warn("ไม่ได้มาจาก หอผู้ป่วย เปิด CardTreatmentOrder: ");
  //   }
  // }, [props.django, props.divisionType]);

  const handleDrugClaim = useCallback(
    ({ item, questionIndex, isEdit, haveQuestion, itemIndex }: any = {}) => {
      props.onEvent({
        message: "CreateDrugOrderItemClaim",
        params: {
          data: {
            claim_payload: item.claim_payload,
            emr: props.selectedEmr?.id || null,
            encounter: props.selectedEncounter?.id || null,
            order_type: props.drugOrder?.type,
            product: item.product,
            quantity: Number.parseFloat(item.quantity),
          },
          item,
          questionIndex,
          itemIndex,
          stateKey: "selectedDrugOrderWorking",
          isEdit,
          haveQuestion,
          order_type: item.type,
        },
      });
    },
    [props.selectedEmr, props.selectedEncounter, props.drugOrder]
  );

  const numberGridRow = useCallback(() => {
    const drugOrder = props.drugOrder;
    const numRow: number = [
      "refill",
      "printed",
      "checked",
      "delivered",
      "transported",
    ].reduce<number>(
      (result, key) => (!drugOrder?.[key] ? result + 1 : result),
      0
    );

    const sub = numRow * 3;
    const add = numRow * 2;
    const max = sub === 12 ? sub + 2 : sub;

    return {
      sub: (sub === 0 ? -1 : max) + 1,
      add: sub - add + 1,
      vh: add,
    };
  }, [props.drugOrder]);

  const handleCheckbox = (e: SyntheticEvent, d: any) => {
    e.preventDefault();
    e.stopPropagation();

    let checkList = [...multiCheckbox];
    if (d.checked) {
      checkList.push(d.name);
    } else {
      checkList = checkList.filter((id) => id !== d.name);
    }
    setMultiCheckbox(checkList);
  };

  const handleChangeItemValue = (id: number, name: string, value: any) => {
    props.runSequence({
      sequence: "DrugOrderAction",
      action: "changeOrderItemData",
      name,
      value,
      id,
    });
  };

  const handleChangeChecked =
    (key: string) =>
    (_e: any, { checked }: any) => {
      if (props?.isCardReceive) {
        props.setProp("DrugOrderActionSequence", {
          ...props.DrugOrderActionSequence,
          sequenceIndex: "START",
          [key]: checked,
        });
      } else {
        props.setProp(`DrugOrderActionSequence.${key}`, checked);
      }
    };

  const getLabelFromCode = useCallback(
    (item: Record<string, any>) => {
      const options: Record<string, any[]> = props.masterOptions || {};

      options.stock = options.unit;

      const keys = ["frequency", "method", "route", "unit", "stock"];

      return keys.reduce((result, key) => {
        const value = item[`code_${key}`] || "";
        const reg = new RegExp(` \\(${value}\\)$`, "g");
        const data = options[key]?.find(
          (option) => option.text.search(reg) >= 0
        );

        return {
          ...result,
          [`code_${key}`]: value ? data?.text?.replace(reg, "") : "",
        };
      }, {});
    },
    [props.masterOptions]
  );

  const drugOrderItems = useMemo(() => {
    return (props.drugOrder?.items || []).map((item: any, index: number) => ({
      ...item,
      check: (
        <>
          <Checkbox
            name={item.id}
            onChange={handleCheckbox}
            checked={multiCheckbox.includes(item.id)}
          />
        </>
      ),
      print_label: (
        <div style={{ display: "flex", justifyContent: "center", flex: 1 }}>
          <Checkbox
            name={`print_label${item.id}`}
            onChange={(_e: SyntheticEvent, data: any) => {
              handleChangeItemValue(item.id, "print", data.checked);
            }}
            checked={item.print}
          />
        </div>
      ),
      name_status: (
        <>
          {item.note_rejected ? (
            <div style={{ backgroundColor: "#db2828", fontSize: "1.3em" }}>
              {" "}
              Rejected : {item.note_rejected}
            </div>
          ) : null}
          <div>
            <label style={{ marginRight: "1rem" }}>{item.name}</label>
            {item.code_frequency === "UD" && (
              <Button
                icon="clipboard list"
                circular={true}
                color="green"
                style={{ padding: "0.4rem 0.45rem 0.45rem", fontSize: "1rem" }}
                onClick={(e) => {
                  e.stopPropagation();

                  setModDrugDesc({ open: true, desc: item.description });
                }}
              />
            )}
          </div>
          <SubDrugOrderStatusAlert
            onEvent={props.onEvent}
            stateKey="selectedDrugOrderWorking"
            index={index}
            item={item}
            estimate={props.drugOrder?.estimate || {}}
            isFemale={props.selectedEncounter?.patient_gender_name === "Female"}
            // callback
            onDrugClaim={handleDrugClaim}
            targetStateOrder={"selectedDrugOrderWorking"}
          />
        </>
      ),
      quantity: item.quantity ? parseInt(item.quantity) : "",
      price_unit: (
        <div style={{ textAlign: "right" }}>{item.price_unit || ""}</div>
      ),
      price_total: (
        <div style={{ textAlign: "right" }}>{item.price_total || ""}</div>
      ),
      ...getLabelFromCode(item),
    }));
  }, [
    props.drugOrder?.items,
    props.drugOrder?.estimate,
    props.drugOrder?.type,
    props.selectedEncounter,
    multiCheckbox,
    props.masterOptions,
  ]);

  // อัพเดต SelectedRow
  useEffect(() => {
    if (selectedRow?.id && !!drugOrderItems.length) {
      const order = drugOrderItems.find(
        (item: any) => item.id === selectedRow.id
      );

      if (!!order && selectedRow.order_status !== order?.order_status) {
        handleSelectedRow(order);
      }
    }
  }, [selectedRow, drugOrderItems]);
  // -----------

  const drugOrderRefill = useMemo(() => {
    return (props.DrugRefillSequence?.order_items || []).map(
      (item: any, _index: number) => ({
        ...item,
        code: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0px",
            }}
          >
            {item.code}
          </div>
        ),
        name: <div style={{ margin: "10px 0px 10px 20px" }}>{item.name}</div>,
        planned_quantity: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0px",
              color: item.planned_quantity !== item.quantity ? "red" : "",
            }}
          >
            {item.planned_quantity}
          </div>
        ),
        quantity: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0px",
            }}
          >
            {item.quantity}
          </div>
        ),
      })
    );
  }, [props.DrugRefillSequence]);

  const priceDrugOrder = useMemo(() => {
    return (props.drugOrder?.items || []).map((item: any) => ({
      price_total: item.price_total,
      price_claimable: item.price_claimable,
      price_non_claimable: item.price_non_claimable,
    }));
  }, [props.drugOrder?.items]);

  const sumPriceTotal = useMemo(() => {
    return priceDrugOrder.reduce(
      (sum: any, current: any) => sum + current.price_total,
      0
    );
  }, [priceDrugOrder]);

  const sumPriceClaimable = useMemo(() => {
    return priceDrugOrder.reduce(
      (sum: any, current: any) => sum + current.price_claimable,
      0
    );
  }, [priceDrugOrder]);

  const sumPriceNonClaimable = useMemo(() => {
    return priceDrugOrder.reduce(
      (sum: any, current: any) => sum + current.price_non_claimable,
      0
    );
  }, [priceDrugOrder]);

  const dataRowReject = useMemo(() => {
    console.log("dataSelecterRow", dataSelectedRow);
    return (dataSelectedRow || []).map((item: any) => ({
      ...item,
      note: (
        <EditorColumn
          value={item.note}
          align="left"
          onDataChanged={(value: string) => {
            const items = dataSelectedRow.map((o: any) =>
              o.id === item.id
                ? {
                    ...o,
                    note: value,
                  }
                : o
            );
            setDataSelectedRow(items);
            props.setProp(`modNoteReject.note`, value);
          }}
        />
      ),
    }));
  }, [dataSelectedRow]);

  const drugOrderLog = useMemo(() => {
    return (props.drugOrderLog?.items || []).map((item: any) => ({
      ...item,
      datetime: moment(item.datetime).format("DD/MM/YY HH:mm"),
    }));
  }, [props.drugOrderLog?.items]);

  const drugDeliverOptions = useMemo(() => {
    const drugDelivery = {
      key: 1,
      value: "drugDelivery",
      text: "Drug Delivery",
    };
    if (props.drugDelivery?.showDrugDelivery) {
      return [drugDelivery];
    } else {
      return [
        { key: 2, value: "patient", text: "ผู้ป่วยรับเอง" },
        { key: 3, value: "others", text: "บุคคลอื่นรับยาแทน" },
        { key: 4, value: "transporter", text: "เจ้าหน้าที่ รพ." },
        drugDelivery,
      ];
    }
  }, []);

  const handleSelectedRow = async (row: any) => {
    const [medError] = await props.onEvent({
      message: "GetMedicationErrorDetail",
      params: { id: row.id },
    });

    setSelectedRow({ ...row, med_error_status: medError?.status });
  };

  const handleRowProps = (
    _state: any,
    rowInfo: any,
    _column: any,
    _instance: any
  ) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id && rowInfo?.original?.id === selectedRow?.id
            ? "#cccccc"
            : "white",
      },
      onClick: () => handleSelectedRow(rowInfo.original),
    };
  };

  const handleCloseMod = () => {
    setIsOpen(false);
    props.runSequence({
      sequence: "MedErrorList",
      action: "close_order_working",
    });
  };

  const handleEdited = () => {
    handleCloseMod();
  };

  const handleSelectedDelivery = (deliver: string | null) => {
    let deliveryBy = null;

    if (selectedDelivery !== deliver) {
      deliveryBy = deliver;
    }

    props.setProp("drugDelivery", {
      ...props.drugDelivery,
      isTransport: deliveryBy === "transporter",
      isOthers: deliveryBy === "others",
      isDrugDelivery: deliver === "drugDelivery",
    });

    setSelectedDelivery(deliveryBy);
  };

  const handleSelectedAddressDelivery = (key: string) => {
    props.setProp("drugDelivery", {
      ...props.drugDelivery,
      address: key,
    });
  };

  const changePropDrugDelivery = (key: string) => (_e: any, v: any) => {
    props.setProp(`drugDelivery.${key}`, v.value);
  };

  const handleReprint = (data: any) => {
    props.onEvent({
      message: "PutDrugOrderItemPrintLabel",
      params: { id: selectedRow?.id, data, card: CARD_MOD_REPRINT },
    });
  };

  const handleLogDrugOrder = () => {
    props.onEvent({
      message: "HanleOnLogDrugOrder",
      params: { action: "LOG" },
    });
    setOpenModelLog(true);
  };

  const handleClickDrugRequest = () => {
    setOpenDrugRequest(true);
  };

  const handleForwardAction = () => {
    setButtonLoadKey(`${CARD_DRUG_ORDER_WORKING}_FORWARD`);
    if (forwardAction === "DELIVER") {
      if (props?.drugDelivery?.isDrugDelivery) {
        console.log("CHECK DELIVER  DRUG");
      } else {
        props.onEvent({
          message: "RunSequence",
          params: {
            sequence: "DrugOrderAction",
            action: forwardAction,
            card: CARD_DRUG_ORDER_WORKING,
            buttonLoadKey: `${CARD_DRUG_ORDER_WORKING}_FORWARD`,
          },
        });
      }
    } else {
      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "DrugOrderAction",
          action: forwardAction,
          card: CARD_DRUG_ORDER_WORKING,
          buttonLoadKey: `${CARD_DRUG_ORDER_WORKING}_FORWARD`,
        },
      });
    }
  };

  const handlebackwardAction = () => {
    setButtonLoadKey(`${CARD_DRUG_ORDER_WORKING}_BACKWARD`);
    if (backwardAction === "REJECT") {
      if (!multiCheckbox.length) {
        setOpenErrorReject(true);
      } else {
        const dataRow = props.drugOrder?.items.filter((item: any) =>
          multiCheckbox.includes(item.id)
        );
        setDataSelectedRow(dataRow);
        props.onEvent({
          message: "RunSequence",
          params: {
            sequence: "DrugOrderAction",
            action: backwardAction,
            card: CARD_DRUG_ORDER_WORKING,
            buttonLoadKey: `${CARD_DRUG_ORDER_WORKING}_BACKWARD`,
          },
        });
      }
    } else if (props.drugOrder?.status === "REFILL_PLANNED") {
      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "DrugOrderAction",
          action: "CANCEL_PLAN",
          card: CARD_DRUG_ORDER_REFILL,
          buttonLoadKey: `${CARD_DRUG_ORDER_REFILL}_CENCAL`,
        },
      });
    } else {
      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "DrugOrderAction",
          action: backwardAction,
          card: CARD_DRUG_ORDER_WORKING,
          buttonLoadKey: `${CARD_DRUG_ORDER_WORKING}_BACKWARD`,
        },
      });
    }
  };

  const handleReturnDrug = () => {
    if (
      (props.drugOrder?.status === "DIVIDED" ||
        props.drugOrder?.status === "REFILL_PLANNED") &&
      (props.drugOrder?.type === "HOME_OPD" ||
        props.drugOrder?.type === "HOME_IPD")
    ) {
      setOpenModRefillDrug(true);
      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "DrugOrderAction",
          action: "getRefill",
        },
      });
    } else {
      setOpenModReturnDrug(true);
      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "ReturnOrder",
          action: "RETURN_DRUG",
          id: props.drugOrder.pk,
        },
      });
    }
  };

  const handleRefillAction = () => {
    setOpenModRefill(false);
    if (props.drugOrder?.status === "REFILL_PLANNED") {
      setButtonLoadKey(`${CARD_DRUG_ORDER_WORKING}_REFILLPRINT`);
      setOpenModRefillDrug(false);

      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "DrugOrderAction",
          action: "createRefillOrder",
          getRefill: true,
        },
      });
    } else {
      setButtonLoadKey(`${CARD_DRUG_ORDER_WORKING}_REFILL`);

      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "DrugOrderAction",
          action: "DIVIDE",
          card: CARD_DRUG_ORDER_WORKING,
          buttonLoadKey: `${CARD_DRUG_ORDER_WORKING}_REFILL`,
          getRefill: true,
        },
      });

      setOpenModRefillDrug(true);
    }
  };

  const handleRemoveRefill = (index: any) => {
    //calculatorRefill
    let newDataOrderItems: any = props.DrugRefillSequence?.order_items || [];
    (props.DrugRefillSequence?.appointments || []).forEach(
      (item: any, id: any) => {
        if (index === id) {
          item.plannings.forEach((data: any, number: any) => {
            newDataOrderItems[number].planned_quantity =
              newDataOrderItems[number].planned_quantity - data.quantity;
          });
        }
      }
    );

    // newDataOrderList
    let newDataAppointments: any = [];
    (props.DrugRefillSequence?.appointments || []).forEach(
      (item: any, id: any) => {
        if (index !== id) {
          newDataAppointments.push(item);
        }
      }
    );

    props.setProp("DrugRefillSequence.appointments", newDataAppointments);
  };

  const handleCalculatorRefill = (index: any, number: any, value: any) => {
    props.setProp(
      `DrugRefillSequence.appointments.${index}.plannings.${number}.quantity`,
      value
    );

    let sum: number = 0;
    (props.DrugRefillSequence?.appointments || []).forEach((item: any) => {
      sum = sum + parseInt(item.plannings[number].quantity);
    });

    props.setProp(
      `DrugRefillSequence.order_items.${number}.planned_quantity`,
      sum
    );
  };

  const handleAddOrderList = () => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "DrugOrderAction",
        action: "addOrderList",
      },
    });
  };

  const handleCalculatorSplit = () => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "DrugOrderAction",
        action: "calculatorSplit",
      },
    });
  };

  const handleChangeSplit = (key: string) => (_e: any, v: any) => {
    props.setProp(`DrugRefillSequence.${key}`, v.value);
  };

  const handleConfirmReject = () => {
    const textAlert: any[] = [];

    if (!props.modNoteReject.note) {
      textAlert.push("กรุณาระบุเหตุผลการ Reject ยาให้ครบทุกตัว");

      setErrorMessage(textAlert);
    }

    if (textAlert.length === 0) {
      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "DrugOrderAction",
          action: backwardAction,
          confirm: true,
          items: dataSelectedRow,
          buttonLoadKey: buttonLoadKey,
        },
      });

      setErrorMessage(null);
    }
  };

  const handleCloseDrugTransferRequest = async () => {
    await props.setProp(
      `DrugTransferRequestSequence.selectedDivisionPharma`,
      null
    );
    await props.setProp("DrugTransferRequestSequence.selectedDrugSearch", null);
    await props.setProp("DrugTransferRequestSequence.selectedDrugDose", null);
    await props.setProp("drugTransferRequestlist", []);
    await props.setProp("drugTransferRequestDetail", null);

    setOpenDrugRequest(false);
  };

  const handleClearSelectDrug = () => {
    props.setProp("selectedDrugOrderWorking", null);
  };

  const handleClickLotNo = () => {
    setOpenModLotNo(true);
  };

  const handleCloseModLotNo = () => {
    setOpenModLotNo(false);
  };

  // Handler
  const handleClose = () => {
    setOpenModelLog(false);
  };
  const handleCloseError = () => {
    setOpenErrorReject(false);
  };
  const handleCloseModReturnDrug = () => {
    setOpenModReturnDrug(false);
  };
  const handleChangeValue = (key: string) => (_e: any, v: any) => {
    props.setProp(`DrugOrderActionSequence.${key}`, v.value);
  };
  const handleCloseModReprint = () => {
    setOpenModeReprint(false);
    props.setProp("DrugOrderActionSequence", {
      ...props.DrugOrderActionSequence,
      printPharma: false,
      printPatient: false,
      printLabel: false,
      language: "thai",
      noteReprint: "",
    });
    setTab("thai");
  };

  const handleNarCoticPrint = () => {
    props.onEvent({
      message: "HandlePrintNarcoticForm",
      params: {
        order_id: props.drugOrder.pk,
        card: CARD_DRUG_ORDER_WORKING,
        loading: "narcotic",
      },
    });
  };
  const handleClickMedError = () => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "DrugOrderAction",
        action: "OPEN_MED_ERROR",
        buttonLoadKey: buttonLoadKey,
        callback: () => setIsOpen(true),
      },
    });
  };
  const handleChangeActionNote = (_e: any, data: any) => {
    // console.log("modConfirmAction: ",e, data)
    props.setProp("modConfirmAction", {
      ...props.modConfirmAction,
      value: data.value,
      text: data.options.find((i: any) => i.value === data.value).text,
    });
  };
  const handleConfirmActionNote = () => {
    if (props.drugOrder?.status === "REFILL_PLANNED") {
      setOpenModRefillDrug(false);
    }

    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "DrugOrderAction",
        action: props.modConfirmAction?.action,
        card: CARD_DRUG_ORDER_WORKING,
        buttonLoadKey: buttonLoadKey,
        confirm: true,
      },
    });
  };
  const handleConfirmModReprint = () => {
    if (
      props?.DrugOrderActionSequence?.printPharma ||
      props?.DrugOrderActionSequence?.printPatient ||
      props?.DrugOrderActionSequence?.printLabel ||
      props?.DrugOrderActionSequence?.printMedReport
    ) {
      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "DrugOrderAction",
          action: "REPRINT",
          card: CARD_REPRINT_DRUG_ORDER_WORKING,
        },
      });
    } else {
      alert("กรุณาเลือก");
    }
  };
  const handleDrugDeliveryPrint = () => {
    if (props?.drugDelivery?.showDrugDelivery) {
      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "DrugOrderAction",
          action: "PRINTQR",
          card: CARD_DRUG_ORDER_WORKING,
        },
      });
    } else {
      setButtonLoadKey(`${CARD_DRUG_ORDER_WORKING}_FORWARD`);
      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "DrugOrderAction",
          action: "DELIVER",
          card: CARD_DRUG_ORDER_WORKING,
          buttonLoadKey: `${CARD_DRUG_ORDER_WORKING}_FORWARD`,
        },
      });
    }
  };

  const handleChangePersonName = (_e: any, data: any) => {
    props.setProp("drugDelivery.personName", data.value);
  };

  const handleCloseModDesc = () => {
    setModDrugDesc({});
  };

  const handleCloseModLastEdited = async () => {
    await props.setProp(
      `errorMessage.${CARD_DRUG_ORDER_WORKING}_LAST_EDITED`,
      null
    );

    props.onEvent({
      message: "SelectDrugOrderWorking",
      params: {
        drugOrder: !!props.drugOrder
          ? { ...props.drugOrder, id: props.drugOrder.pk }
          : null,
        history,
      },
    });
  };

  // Todo: make drug order item table shrink to left and show toolbox on the right
  // Todo: add symbols for adr , etc in drug order item table
  // Todo: add CardOrderSummary for pharmacy to review
  // Todo: add drugorder type refill
  console.log("CardDrugOrderWorking:", props, selectedRow);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {props.drugOrderQueue && !props?.isCardReceive && (
        <CardDrugOrderQueue
          hidePanel={true}
          height={props.drugOrder ? "200px" : "80vh"}
          onEvent={props.onEvent}
          setProp={props.setProp}
          // data
          drugOrderQueue={
            !openRefillPlanned
              ? props.drugOrderQueue
              : props.DrugOrderPendingList || []
          }
          showDrugOrderPendingList={
            props.DrugOrderPendingList?.length !== 0 ? true : false
          }
          selectedDrugOrderWorking={props.drugOrder}
          selectedEncounter={props.selectedEncounter}
          layout={props.layout}
          forward={props.forward}
          refillPlaned={
            <div
              onClick={() => {
                setOpenRefillPlanned(!openRefillPlanned);
                props.onEvent({
                  message: "RunSequence",
                  params: {
                    sequence: "DrugOrderAction",
                    action: "getDrugRefillPlaned",
                  },
                });
              }}
            >
              {openRefillPlanned ? (
                <div
                  style={{
                    display: "flex",
                    color: "#000000",
                    fontSize: "14px",
                    padding: "6px 20px",
                    alignItems: "center",
                    border: "solid 0.5px ",
                    borderRadius: "10px",
                    borderColor: "#ED5151",
                    cursor: "pointer",
                  }}
                >
                  ซ่อนใบยา Refill_Planned
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    background: "#ED5151",
                    color: "#FFFFFF",
                    fontSize: "14px",
                    padding: "6px 20px",
                    borderRadius: "10px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      background: "#FEC13B",
                      color: "#ED5151",
                      borderRadius: "220px",
                      fontSize: "18px",
                      padding: "2px 8px",
                      marginRight: "5px",
                    }}
                  >
                    !
                  </div>
                  แสดงใบยา Refill_Planned
                </div>
              )}
            </div>
          }
        />
      )}

      {props.errorMessage?.[CARD_DRUG_ORDER_WORKING]?.error?.length > 0 &&
      props.errorMessage?.[CARD_DRUG_ORDER_WORKING]?.error?.[0]?.includes(
        "Med Reconcile"
      ) &&
      props.errorMessage?.[CARD_DRUG_ORDER_WORKING]?.error?.[0]?.includes(
        "ยังไม่ถูกรับทราบ"
      ) ? (
        <ModMedReconcileAlert
          // medReconcileCheck={props.medReconcileCheck}
          open={true}
          onApprove={() => {
            let medId = props.errorMessage?.[
              CARD_DRUG_ORDER_WORKING
            ]?.error?.[0]
              ?.split("[")?.[1]
              .split("]")?.[0];

            props.setProp(`errorMessage.${CARD_DRUG_ORDER_WORKING}`, null);

            props.onEvent({
              message: "OpenMedReconcileFromWarning",
              params: {
                medReconcileCheck: { med_reconciliation: medId },
                selecteRecordViewIndex: props.medReconcileIndex,
              },
            });

            // find med reconcile Index
            // if (props.medReconcileIndex !== -1) {
            //   props.setProp("selectedRecordViewIndex", props.medReconcileIndex)
            // }
          }}
        >
          {props.errorMessage?.[CARD_DRUG_ORDER_WORKING]?.error?.[0]}
        </ModMedReconcileAlert>
      ) : (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            props.setProp(`errorMessage.${CARD_DRUG_ORDER_WORKING}`, null);
          }}
          error={props.errorMessage?.[CARD_DRUG_ORDER_WORKING]?.error}
          success={props.successMessage?.[CARD_DRUG_ORDER_WORKING]}
        />
      )}

      {props.drugOrder && props.drugOrder?.type !== "CONTINUE_PLAN" && (
        <div style={{ width: "100%", paddingBottom: "2rem" }}>
          {props.closeIcon && (
            <div style={{ height: "20px", position: "relative" }}>
              <Icon
                name="close"
                size="large"
                color="grey"
                style={CloseIconStyle}
                onClick={handleClearSelectDrug}
              />
            </div>
          )}

          <CardDrugOrderWorkingUX
            //data
            height={selectedDelivery === "drugDelivery" ? "829px" : "750px"}
            nameTypeOrder={
              props?.isCardDrugOrderHistory ? (
                <Label
                  size="small"
                  style={{
                    backgroundColor:
                      (ORDER_TYPE_COLORS as any)[props.prescriptionType] || "",
                    margin: "auto 0",
                    color: "white",
                  }}
                >
                  {props.nameTypeOrder}
                </Label>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label>
                    {props.selectedEncounter?.general_type === "IPD"
                      ? "ตรวจสอบการสั่งยาผู้ป่วยใน"
                      : "ตรวจสอบการสั่งยาผู้ป่วยนอก"}
                  </label>
                  <Label
                    size="small"
                    style={{
                      backgroundColor:
                        (ORDER_TYPE_COLORS as any)[props.drugOrder?.type] || "",
                      marginLeft: "1rem",
                      color: "white",
                    }}
                  >
                    {props.drugOrder?.code} : {props.drugOrder?.type}
                  </Label>
                </div>
              )
            }
            orderStatus={
              props.drugOrder?.status && (
                <Label
                  size="small"
                  style={{
                    backgroundColor:
                      (ORDER_STATUS_COLORS as any)[props.drugOrder?.status] ||
                      "",
                    marginLeft: "1rem",
                    color: "white",
                  }}
                >
                  {props.drugOrder?.status}
                  {/* {"TRANSPORTED"} */}
                </Label>
              )
            }
            orderPaymentStatus={
              props.drugOrder?.order_payment_status && (
                <Label
                  size="small"
                  style={{
                    backgroundColor:
                      (ORDER_PAYMENT_STATUS as any)[
                        props.drugOrder?.order_payment_status
                      ] || "",
                    marginLeft: "1rem",
                    color: "white",
                  }}
                >
                  {props.drugOrder?.order_payment_status}
                  {/* {"PENDING"} */}
                </Label>
              )
            }
            OnReprint={() => setOpenModeReprint(true)}
            showReprint={props.drugOrder?.status !== "REQUESTED"}
            drugOrder={props.drugOrder}
            drugOrderItems={drugOrderItems}
            sumPriceTotal={sumPriceTotal}
            sumPriceClaimable={sumPriceClaimable}
            sumPriceNonClaimable={sumPriceNonClaimable}
            requestTime={
              moment(new Date(props.drugOrder?.requested?.datetime)).format(
                "DD-MM-YYYY [[]HH:mm[]]"
              ) || ""
            }
            printedTime={
              moment(new Date(props.drugOrder?.printed?.datetime)).format(
                "DD-MM-YYYY [[]HH:mm[]]"
              ) || ""
            }
            checkedTime={
              moment(new Date(props.drugOrder?.checked?.datetime)).format(
                "DD-MM-YYYY [[]HH:mm[]]"
              ) || ""
            }
            deliveredTime={
              moment(new Date(props.drugOrder?.delivered?.datetime)).format(
                "DD-MM-YYYY [[]HH:mm[]]"
              ) || ""
            }
            transportedTime={
              moment(new Date(props.drugOrder?.transported?.datetime)).format(
                "DD-MM-YYYY [[]HH:mm[]]"
              ) || ""
            }
            showPrintLabel={forwardAction === "PRINT"}
            // component
            buttonReturn={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleReturnDrug}
                // data
                paramKey={`${CARD_DRUG_ORDER_WORKING}_REFILL`}
                buttonLoadCheck={
                  props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_WORKING}_REFILL`]
                }
                // config
                color={
                  props.drugOrder?.status === "DIVIDED" &&
                  (props.drugOrder?.type === "HOME_OPD" ||
                    props.drugOrder?.type === "HOME_IPD")
                    ? "teal"
                    : props.drugOrder?.status === "REFILL_PLANNED" &&
                      (props.drugOrder?.type === "HOME_OPD" ||
                        props.drugOrder?.type === "HOME_IPD")
                    ? "black"
                    : ""
                }
                name=""
                size="mini"
                style={{
                  width: "100%",
                }}
                title={
                  (props.drugOrder?.status === "DIVIDED" ||
                    props.drugOrder?.status === "REFILL_PLANNED") &&
                  (props.drugOrder?.type === "HOME_OPD" ||
                    props.drugOrder?.type === "HOME_IPD")
                    ? "แผนการเติมยา REFILL"
                    : "คืนยา"
                }
              />
            }
            buttonRefill={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={() => setOpenModRefill(true)}
                // data
                paramKey={`${CARD_DRUG_ORDER_WORKING}_REFILL`}
                buttonLoadCheck={
                  props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_WORKING}_REFILL`]
                }
                // config
                color={"yellow"}
                name=""
                size="mini"
                style={{
                  width: "100%",
                }}
                title={"REFILL"}
              />
            }
            buttonForward={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleForwardAction}
                // data
                paramKey={`${CARD_DRUG_ORDER_WORKING}_FORWARD`}
                buttonLoadCheck={
                  props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_WORKING}_FORWARD`]
                }
                // config
                color={forwardColor ? forwardColor : "green"}
                name="FORWARD"
                size="mini"
                style={{
                  width: "100%",
                  display:
                    props?.isCardDrugOrderHistory ||
                    (props.drugOrder?.status === "TRANSPORTED" &&
                      forwardAction === "DELIVER") ||
                    !forwardAction
                      ? "none"
                      : "",
                  minWidth: "max-content",
                }}
                title={
                  forwardAction ? actionButton[forwardAction] : "NO ACTION"
                }
              />
            }
            buttonBackward={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handlebackwardAction}
                // data
                paramKey={`${CARD_DRUG_ORDER_WORKING}_BACKWARD`}
                buttonLoadCheck={
                  props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_WORKING}_BACKWARD`]
                }
                // config
                color="red"
                name="BACKWARD"
                size="mini"
                style={{
                  width: "100%",
                  display:
                    props?.isCardDrugOrderHistory ||
                    (props.drugOrder?.status === "TRANSPORTED" &&
                      backwardAction === "UNDELIVER") ||
                    (!!props.django?.user?.role_types?.find((role: string) =>
                      ["NURSE", "REGISTERED_NURSE"].includes(role)
                    ) &&
                      backwardAction === "UNDELIVER")
                      ? "none"
                      : "",
                }}
                title={
                  backwardAction ? actionButton[backwardAction] : "NO ACTION"
                }
              />
            }
            //
            onReturnDrug={() => {
              setOpenModReturnDrug(true);
              props.onEvent({
                message: "RunSequence",
                params: {
                  sequence: "ReturnOrder",
                  action: "RETURN_DRUG",
                  id: props.drugOrder.pk,
                },
              });
            }}
            printDrugAdvice={() => {
              props.onEvent({
                message: "HandleDrugRecommendation",
                params: {
                  action: "open",
                  drugOrderId: props.drugOrder?.pk,
                  card: CARD_DRUG_ORDER_WORKING,
                },
              });
            }}
            // narcotic set
            isNarcotic={props.drugOrder?.has_narcotic || false}
            loadingNarcotic={props.loadingStatus?.["narcotic"] || false}
            narcoticPrint={handleNarCoticPrint}
            handleOnLog={handleLogDrugOrder}
            statusRefill={
              props.drugOrder?.status === "REQUESTED" &&
              (props.drugOrder?.type === "HOME_OPD" ||
                props.drugOrder?.type === "HOME_IPD")
            }
            statusForward={
              props.drugOrder?.status === "REJECTED" ||
              props.drugOrder?.status === "RECEIVED" ||
              ((props.drugOrder?.status === "DIVIDED" ||
                props.drugOrder?.status === "REFILL_PLANNED") &&
                (props.drugOrder?.type === "HOME_OPD" ||
                  props.drugOrder?.type === "HOME_IPD")) ||
              props.drugDelivery?.showDrugDelivery
            }
            numberGridRow={numberGridRow()}
            statusBackward={props.drugOrder?.status === "REJECTED"}
            rowProps={handleRowProps}
            selectedTransporter={selectedDelivery === "transporter"}
            additionalProps={
              <div
                style={{
                  display: "flex",
                  justifyContent: props.drugDelivery?.showDrugDelivery
                    ? "flex-start"
                    : "flex-end",
                }}
              >
                <div style={{ flex: 1 }}></div>
                {props.drugDelivery?.showDrugDelivery ? (
                  <>
                    <DropdownDeliver
                      value={selectedDelivery}
                      options={drugDeliverOptions}
                      onChange={handleSelectedDelivery}
                    />
                  </>
                ) : props.drugOrder?.status !== "CHECKED" ||
                  forwardAction === "WAIVE" ? (
                  <></>
                ) : (
                  <>
                    <>
                      <DropdownDeliver
                        value={selectedDelivery}
                        options={drugDeliverOptions}
                        onChange={handleSelectedDelivery}
                      />

                      {selectedDelivery === "transporter" && (
                        <div>
                          {/* <span style={{ padding: "0px 5px" }}>ผู้จัดส่ง</span> */}
                          <EmployeeToken
                            employeeName={props.drugDelivery.employeeName}
                            error={props.drugDelivery.error}
                            loading={props.drugDelivery.loading}
                            fluid={true}
                            inputStyle={{
                              margin: "0 1rem 0 1rem",
                              minWidth: "200",
                              width: "200px",
                            }}
                            // Callback
                            onEnterToken={(code) => {
                              props.onEvent({
                                message: "HandleGetDeliverDrugTokenization",
                                params: { code },
                              });
                            }}
                            onClearToken={() =>
                              props.setProp("drugDelivery", {
                                ...props.drugDelivery,
                                token: "",
                                employeeName: "",
                                loading: false,
                                error: null,
                              })
                            }
                          />
                        </div>
                      )}

                      {selectedDelivery === "others" && (
                        <Input
                          value={props.drugDelivery?.personName}
                          placeholder="ชื่อผู้รับยา"
                          style={{ paddingLeft: "1rem" }}
                          onChange={handleChangePersonName}
                        />
                      )}
                    </>
                  </>
                )}
              </div>
            }
            drugDelivery={
              <>
                {selectedDelivery === "drugDelivery" && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "10px ",
                        alignItems: "baseline",
                      }}
                    >
                      <div style={{ marginRight: "5px" }}>
                        <Icon name="truck" size="small" />
                      </div>

                      <div style={{ display: "flex", marginRight: "5px" }}>
                        <label> Shipper </label>
                        <label style={{ color: "red" }}> * </label>
                      </div>

                      <div style={{ marginRight: "5px" }}>
                        <Dropdown
                          clearable={true}
                          onChange={changePropDrugDelivery("shipper")}
                          options={props?.masterOptions?.shippingEmployee}
                          search={true}
                          selection={true}
                          value={props?.drugDelivery?.shipper || ""}
                          disabled={
                            props?.drugDelivery?.showDrugDelivery ? true : false
                          }
                        ></Dropdown>
                      </div>

                      <div style={{ marginRight: "5px" }}>
                        <label> TrackingNo. </label>
                        <label style={{ color: "red" }}> * </label>
                      </div>

                      <div style={{ marginRight: "5px" }}>
                        <Input
                          value={props?.drugDelivery?.trackingNo || ""}
                          onChange={changePropDrugDelivery("trackingNo")}
                          readOnly={
                            props?.drugDelivery?.showDrugDelivery ? true : false
                          }
                        ></Input>
                      </div>
                    </div>

                    <div
                      style={{
                        paddingTop: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginRight: "5px" }}>
                        <Icon name="home" size="small" />
                      </div>

                      <div style={{ marginRight: "5px" }}>
                        <label> สถานที่จัดส่ง </label>
                        <label style={{ color: "red" }}> * </label>
                        <label> : </label>
                      </div>

                      <Radio
                        style={{ padding: "2px 5px" }}
                        label="ที่อยู่ตามทะเบียนบ้าน"
                        value="home"
                        checked={props?.drugDelivery?.address === "home"}
                        readOnly={
                          props?.drugDelivery?.showDrugDelivery ? true : false
                        }
                        onChange={() => {
                          handleSelectedAddressDelivery("home");
                        }}
                      />

                      <Radio
                        style={{ padding: "2px 5px" }}
                        label="ที่อยู่ปัจจุบัน"
                        value="present"
                        checked={props?.drugDelivery?.address === "present"}
                        readOnly={
                          props?.drugDelivery?.showDrugDelivery ? true : false
                        }
                        onChange={() => {
                          handleSelectedAddressDelivery("present");
                        }}
                      />

                      <ButtonLoadCheck
                        // function
                        setProp={props.setProp}
                        onClick={handleDrugDeliveryPrint}
                        // data
                        paramKey={`${CARD_DRUG_ORDER_WORKING}_FORWARD`}
                        buttonLoadCheck={
                          props.buttonLoadCheck?.[
                            `${CARD_DRUG_ORDER_WORKING}_FORWARD`
                          ]
                        }
                        // config
                        name="FORWARD"
                        size="tiny"
                        color="blue"
                        style={{ marginLeft: "15px" }}
                        title="PRINT"
                      />
                    </div>
                  </div>
                )}
              </>
            }
            showToolBox={!!selectedRow}
            SubDrugOrderToolbox={
              !!selectedRow && (
                <SubDrugOrderToolbox
                  onClose={() => setSelectedRow(null)}
                  name={selectedRow?.name || ""}
                  label={selectedRow?.label || ""}
                  orderStatus={selectedRow?.order_status}
                  // config
                  disabledMedError={
                    selectedRow?.med_error_status === "COMPLETED"
                  }
                  showButtonLotNo={!!selectedRow?.order_status}
                  // Callback
                  onClickMedError={handleClickMedError}
                  onClickReprint={() => setIsReprint(true)}
                  onClickDrugRequest={handleClickDrugRequest}
                  onClickLotNo={handleClickLotNo}
                />
              )
            }
          />
        </div>
      )}
      {isReprint && (
        <ModConfirmReprint
          error={props.errorMessage?.[CARD_MOD_REPRINT]?.error}
          success={props.successMessage?.[CARD_MOD_REPRINT]}
          loading={props.loadingStatus?.[CARD_MOD_REPRINT]}
          name={selectedRow?.name}
          // callback
          onApprove={handleReprint}
          onDeny={() => setIsReprint(false)}
          onClose={() => {
            setIsReprint(false);
            props.setProp(`successMessage.${CARD_MOD_REPRINT}`, null);
          }}
        />
      )}
      {props.drugOrder && props.drugOrder?.type === "CONTINUE_PLAN" && (
        <>
          {props.closeIcon && (
            <div
              style={{
                height: "20px",
                position: "relative",
                marginBottom: "-15px",
              }}
            >
              <Icon
                name="close"
                size="large"
                color="grey"
                style={CloseIconStyle}
                onClick={handleClearSelectDrug}
              />
            </div>
          )}
          <CardDrugContinuePlan
            // sequence
            runSequence={props.runSequence}
            DrugSelectSequence={props.DrugSelectSequence}
            // function
            onEvent={props.onEvent}
            setProp={props.setProp}
            // CommonInterface
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            // permission
            drugPermission={props.drugPermission}
            // options
            duplicateReasonsOptions={props.masterOptions.duplicateReasons}
            // selected data
            selectedPatient={props.selectedPatient}
            selectedEncounter={props.selectedEncounter}
            selectedDrug={props.selectedDrug}
            // drug list
            drugContinueFilter={props.drugContinueFilter}
            drugContinuePlanItems={props.drugContinuePlanItems}
            // search drug
            drugSearchLoading={props.drugSearchLoading}
            drugSearchText={props.drugSearchText}
            drugSearchResult={props.drugSearchResult}
            // planning
            modPlanningConfig={props.modPlanningConfig}
            planningData={props.planningData}
            // SpecialDirection
            modSpecialDirectionConfig={props.modSpecialDirectionConfig}
            // SolventSelectionConfig
            modSolventSelectionConfig={props.modSolventSelectionConfig}
            // mod note
            modNoteData={props.modNoteData}
            // modal doctor certificate check
            modDoctorCertificate={props.modDoctorCertificate}
            modDrugLabInteraction={props.modDrugLabInteraction}
            modDrugDisease={props.modDrugDisease}
            modDrugInteraction={props.modDrugInteraction}
            currentDoctor={props.currentDoctor}
            // Medreconcile Check
            django={props.django}
            medReconcileCheck={props.medReconcileCheck}
            divisionType={props.divisionType}
            medReconcileIndex={props.medReconcileIndex}
          />
        </>
      )}

      <Modal
        open={isOpen}
        closeOnDimmerClick={true}
        closeIcon={true}
        onClose={handleCloseMod}
      >
        <CardMedicationErrorWorking
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // Data
          title="Medication error note"
          medErrId={selectedRow?.id}
          medErrorWorking={props.medErrorWorking || {}}
          masterOptions={props.masterOptions}
          drugName={selectedRow?.name || ""}
          drugCode={selectedRow?.code || ""}
          selectedDivision={props.selectedDivision}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // config
          defaultStakeholder={{
            id: props.drugOrder?.requested?.user || null,
            name: props.drugOrder?.requested?.name || "",
          }}
          isOrderItem={true}
          // callback
          onEdited={handleEdited}
        />
      </Modal>

      <Modal open={openDrugRequest} size="large">
        <CardDrugTransferRequest
          // onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          DrugTransferRequestSequence={props.drugTransferRequestSequence}
          // controller
          controller={props.drugOrderHistoryController}
          // options
          masterOptions={props.masterOptions}
          // data
          drugRequestList={props.drugRequestList}
          // selectedDevice={props.selectedDevice}
          selectedDivision={props.selectedDivision}
          drugTransferRequestlist={props.drugTransferRequestlist}
          drugTransferLog={props.drugTransferLog}
          drugTransferRequestDetail={props.drugTransferRequestDetail}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          // config
          showStatus={true}
          closeIcon={true}
          notAllowedAdd={true}
          // callback
          onClose={handleCloseDrugTransferRequest}
        />
      </Modal>
      <Modal
        open={props.modNoteReject?.open}
        size="large"
        onClose={() => props.setProp(`modNoteReject.open`, false)}
      >
        <ModDrugOrderRejectUX
          ErrorMessage={
            <ErrorMessage
              error={errorMessage}
              style={{ margin: "0.5rem 1rem -0.25rem" }}
            />
          }
          onEvent={props.onEvent}
          setProp={props.setProp}
          runSequence={props.runSequence}
          dataRow={dataRowReject}
          onConfirm={handleConfirmReject}
          onClose={() => props.setProp(`modNoteReject.open`, false)}
        />
      </Modal>

      {/* <Modal open={openErrorReject} onClose={handleCloseError} size="mini">
        <ModErrorRejectUX onConfirm={handleCloseError} />
      </Modal> */}
      <ModInfo
        open={openErrorReject}
        titleName="ข้อความแจ้งเตือน"
        titleColor="red"
        size="mini"
        buttonColor="red"
        centered={true}
        closeOnDimmerClick={true}
        onApprove={handleCloseError}
        onClose={handleCloseError}
      >
        <div
          style={{
            margin: "1.25rem 0rem 0.5rem",
            lineHeight: 2,
            fontWeight: "bold",
          }}
        >
          กรุณาเลือกยาที่ต้องการ Reject
        </div>
      </ModInfo>

      <Modal
        open={props.modConfirmAction?.open}
        size="mini"
        closeOnDimmerClick={false}
        onClose={() => {
          props.setProp("modConfirmAction.open", false);
        }}
      >
        <ModDrugOrderActionNoteUX
          options={[
            { key: 1, value: 1, text: "พบความผิดพลาดในการสั่งยา" },
            { key: 2, value: 2, text: "ดำเนินขั้นตอนล่าสุดโดยไม่ได้จงใจ" },
            { key: 3, value: 3, text: "ขอแก้ไขคำสั่งการใช้ยา" },
          ]}
          note={props.modConfirmAction.value}
          actionName={
            props.drugOrder?.status === "REFILL_PLANNED"
              ? "CANCEL"
              : props.modConfirmAction?.action
          }
          onChange={handleChangeActionNote}
          onConfirm={handleConfirmActionNote}
          onCancel={() => {
            props.setProp("modConfirmAction.open", false);
          }}
        />
      </Modal>

      <Modal open={openModalLog} size="large" onClose={handleClose}>
        <DrugActionLog data={drugOrderLog} />
      </Modal>

      <Modal open={openModReprint} size="tiny" onClose={handleCloseModReprint}>
        <ModReprintDrugOrderWorkingUX
          handleChangeChecked={handleChangeChecked}
          handleChangeValue={handleChangeValue}
          printPharma={props?.DrugOrderActionSequence?.printPharma}
          printPatient={props?.DrugOrderActionSequence?.printPatient}
          printLabel={props?.DrugOrderActionSequence?.printLabel}
          printMedReport={props?.DrugOrderActionSequence?.printMedReport}
          isHomeMed={["HOME_OPD", "HOME_IPD"].includes(props.drugOrder?.type)}
          noteReprint={props?.DrugOrderActionSequence?.noteReprint}
          loading={
            <>
              <Dimmer
                active={props.loadingStatus?.[CARD_REPRINT_DRUG_ORDER_WORKING]}
                inverted
              >
                <Loader />
              </Dimmer>
            </>
          }
          language={
            <>
              <Menu inverted>
                <Menu.Item
                  name="thai"
                  color={tab === "thai" ? "purple" : "black"}
                  active={tab === "thai"}
                  onClick={() => {
                    setTab("thai");
                    props.setProp("DrugOrderActionSequence.language", "TH");
                  }}
                >
                  Thai
                </Menu.Item>

                <Menu.Item
                  name="eng"
                  color={tab === "eng" ? "purple" : "black"}
                  active={tab === "eng"}
                  onClick={() => {
                    setTab("eng");
                    props.setProp("DrugOrderActionSequence.language", "EN");
                  }}
                >
                  Eng
                </Menu.Item>
              </Menu>
            </>
          }
          onCancel={handleCloseModReprint}
          onConfirm={handleConfirmModReprint}
        />
      </Modal>
      <Modal
        open={openModReturnDrug}
        size="large"
        onClose={handleCloseModReturnDrug}
      >
        <CardReturnDrug
          ReturnOrderSequence={props.ReturnOrderSequence}
          OrderReturnList={props.OrderReturnList}
          DrugReturnList={props.DrugReturnList}
          OrderHistory={props.OrderHistory}
          OrderReturnLog={props.OrderReturnLog}
          closeIcon={true}
          onClose={handleCloseModReturnDrug}
          onEvent={props.onEvent}
          setProp={props.setProp}
          runSequence={props.runSequence}
        />
      </Modal>
      <Modal
        open={openModHomeMedUnPaid}
        size="mini"
        onClose={() => {
          setOpenModHomeMedUnPaid(false);
        }}
      >
        <Modal.Header
          as="h5"
          style={{ backgroundColor: "red", color: "white", fontSize: "16px" }}
        >
          ใบยายังไม่ได้รับชำระเงิน
        </Modal.Header>
        <Modal.Content style={{ textAlign: "center" }}>
          <Button
            inverted
            color="red"
            content="ตกลง"
            onClick={() => {
              setOpenModHomeMedUnPaid(false);
            }}
          />
        </Modal.Content>
      </Modal>

      {/* Refill */}
      <Modal
        open={openModRefillDrug}
        size="fullscreen"
        onClose={() => setOpenModRefillDrug(false)}
      >
        <CardDrugOrderRefillUX
          split={props.DrugRefillSequence}
          handleChangeSplit={handleChangeSplit}
          onChangeSplitDate={(date: any) => {
            props.setProp(`DrugRefillSequence.splitDate`, date);
          }}
          nameTypeOrder={`${props.drugOrder?.type} [${props.drugOrder?.code}]`}
          drugStatus={props.drugOrder?.status}
          openCancelRefill={
            !openCancelRefill && props.drugOrder?.status === "REFILL_PLANNED"
          }
          orderStatus={
            props.drugOrder?.status && (
              <Label
                size="small"
                style={{
                  backgroundColor:
                    (ORDER_STATUS_COLORS as any)[props.drugOrder?.status] || "",
                  color: "white",
                }}
              >
                {props.drugOrder?.status}
                {/* {"TRANSPORTED"} */}
              </Label>
            )
          }
          calculatorSplit={handleCalculatorSplit}
          addOrderList={handleAddOrderList}
          drugOrderRefill={drugOrderRefill}
          logRefillPlanning={() => {
            props.onEvent({
              message: "HanleOnLogDrugOrder",
              params: { action: "LOG" },
            });
            setOpenModRefillLog(true);
          }}
          printRefillPlanning={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.onEvent({
                  message: "RunSequence",
                  params: {
                    sequence: "DrugOrderAction",
                    action: "PRINT_PLAN",
                    last_edited: moment().format(),
                    card: `${CARD_DRUG_ORDER_REFILL}_PRINT`,
                    buttonLoadKey: `${CARD_DRUG_ORDER_REFILL}_PRINT`,
                  },
                });
              }}
              // data
              paramKey={`${CARD_DRUG_ORDER_REFILL}_PRINT`}
              buttonLoadCheck={
                props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_REFILL}_PRINT`]
              }
              // config
              name="PRINT"
              size="tiny"
              // color="green"
              style={{ marginLeft: "15px" }}
              title="PRINT"
            />
          }
          saveRefillPlanning={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                setButtonLoadKey(
                  props.drugOrder?.status === "REFILL_PLANNED"
                    ? `${CARD_DRUG_ORDER_REFILL}_EDIT`
                    : `${CARD_DRUG_ORDER_REFILL}_SAVE`
                );
                props.onEvent({
                  message: "RunSequence",
                  params: {
                    sequence: "DrugOrderAction",
                    action: "saveRefill",
                    card: CARD_DRUG_ORDER_REFILL,
                    buttonLoadKey:
                      props.drugOrder?.status === "REFILL_PLANNED"
                        ? `${CARD_DRUG_ORDER_REFILL}_EDIT`
                        : `${CARD_DRUG_ORDER_REFILL}_SAVE`,
                  },
                });
              }}
              // data
              paramKey={
                props.drugOrder?.status === "REFILL_PLANNED"
                  ? `${CARD_DRUG_ORDER_REFILL}_EDIT`
                  : `${CARD_DRUG_ORDER_REFILL}_SAVE`
              }
              buttonLoadCheck={
                props.drugOrder?.status === "REFILL_PLANNED"
                  ? props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_REFILL}_EDIT`]
                  : props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_REFILL}_SAVE`]
              }
              // config
              name="SAVE"
              size="tiny"
              color={
                props.drugOrder?.status === "REFILL_PLANNED"
                  ? "yellow"
                  : "green"
              }
              style={{ marginLeft: "15px" }}
              title={
                props.drugOrder?.status === "REFILL_PLANNED" ? "EDIT" : "SAVE"
              }
            />
          }
          cancelRefillPlanning={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handlebackwardAction}
              // data
              paramKey={`${CARD_DRUG_ORDER_REFILL}_CANCEL`}
              buttonLoadCheck={
                props.buttonLoadCheck?.[`${CARD_DRUG_ORDER_REFILL}_CANCEL`]
              }
              // config
              color="red"
              name="CANCEL"
              size="tiny"
              style={{ marginLeft: "15px" }}
              title="CANCEL"
            />
          }
          inputRefill={
            <div style={{ display: "flex" }}>
              {(props.DrugRefillSequence?.appointments || []).map(
                (item: any, index: number) => {
                  return (
                    <div style={{ marginRight: "20px" }}>
                      <div style={{}}>
                        <DateTextBox
                          value={item.start_date}
                          onChange={(date: any) => {
                            props.setProp(
                              `DrugRefillSequence.appointments.${index}`,
                              {
                                ...props.DrugRefillSequence.appointments[index],
                                end_date: date,
                                start_date: date,
                              }
                            );
                          }}
                          disabled={
                            props.drugOrder?.status === "DIVIDED" ||
                            item?.refill === null
                              ? false
                              : true
                          }
                        />
                      </div>
                      {(props.DrugRefillSequence?.order_items || []).map(
                        (_data: any, number: number) => {
                          return (
                            <div
                              style={{
                                marginTop: "10px",
                                width: "210px",
                                textAlign: "right",
                              }}
                            >
                              <Input
                                type="number"
                                fluid={true}
                                value={item?.plannings[number].quantity}
                                onChange={(_e: any, { value }: any) =>
                                  handleCalculatorRefill(index, number, value)
                                }
                                disabled={
                                  props.drugOrder?.status === "DIVIDED" ||
                                  item?.refill === null
                                    ? false
                                    : true
                                }
                              />
                            </div>
                          );
                        }
                      )}
                      <div
                        style={{ margin: "10px 20px 10px 0px", width: "210px" }}
                      >
                        {props.drugOrder?.status === "DIVIDED" ? (
                          <Button
                            onClick={() => handleRemoveRefill(index)}
                            color="red"
                            icon="trash alternate"
                            fluid={true}
                          >
                            Remove
                          </Button>
                        ) : (
                          <ButtonLoadCheck
                            // function
                            setProp={props.setProp}
                            onClick={() => {
                              if (item?.refill === null) {
                                props.setProp(
                                  `DrugRefillSequence.selectedRefill`,
                                  index
                                );
                                setOpenModRefill(true);
                              }
                            }} // data
                            paramKey={`${CARD_DRUG_ORDER_REFILL}_REFILLPRINT`}
                            buttonLoadCheck={
                              props.buttonLoadCheck?.[
                                `${CARD_DRUG_ORDER_REFILL}_REFILLPRINT`
                              ]
                            }
                            // config
                            fluid={true}
                            color={item?.refill === null ? "blue" : "yellow"}
                            name="REFILLPRINT"
                            size="tiny"
                            title={
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Image
                                  width={20}
                                  height={20}
                                  src={
                                    item?.refill === null
                                      ? IMAGES.refill
                                      : IMAGES.view
                                  }
                                />
                                <div style={{ marginLeft: "10px" }}>
                                  {item?.refill === null ? "REFILL" : "VIEW"}
                                </div>
                              </div>
                            }
                          />
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          }
        />
      </Modal>

      <ModConfirm
        openModal={openModRefill}
        titleName="ข้อความแจ้งเตือน"
        size="mini"
        denyButtonColor="red"
        denyButtonText="ไม่ใช่"
        approveButtonColor="green"
        approveButtonText="ใช่"
        content={
          props.drugOrder?.status === "REFILL_PLANNED"
            ? "ยืนยันการสร้างรายการจากการเติมยา"
            : "ยืนยันการแบ่งรายการยาเพื่อ Refill"
        }
        onApprove={() => {
          handleRefillAction();
        }}
        onDeny={() => {
          setOpenModRefill(false);
        }}
      />

      <Modal
        open={openModRefillLog}
        size="large"
        onClose={() => setOpenModRefillLog(false)}
      >
        <div style={{ padding: "10px" }}>
          {/* @ts-ignore */}
          <Header> ประวัติการทำงาน </Header>
          <Table
            data={drugOrderLog}
            headers="User,Action,Date-Time"
            keys="name,action,datetime"
            minRows="10"
            showPagination={false}
            style={{ height: "300px" }}
          ></Table>
        </div>
      </Modal>

      <Modal
        open={props.errorMessage?.[CARD_DRUG_ORDER_REFILL] != null}
        size="mini"
      >
        <Modal.Header
          as="h5"
          style={{ backgroundColor: "red", color: "white", fontSize: "16px" }}
        >
          ข้อความแจ้งเตือน
        </Modal.Header>
        <Modal.Content>
          <div>ไม่อนุญาติให้บันทึกแผน Refill</div>
          <div>เนื่องจากจำนวนเติม ไม่เท่ากับ จำนวนสั่ง</div>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Button
              color="green"
              content="ตกลง"
              onClick={() => {
                props.setProp(`errorMessage.${CARD_DRUG_ORDER_REFILL}`, null);
              }}
            />
          </div>
        </Modal.Content>
      </Modal>

      <Modal
        open={openModHomeMedUnPaid}
        size="mini"
        onClose={() => {
          setOpenModHomeMedUnPaid(false);
        }}
      >
        <Modal.Header
          as="h5"
          style={{ backgroundColor: "red", color: "white", fontSize: "16px" }}
        >
          ใบยายังไม่ได้รับชำระเงิน
        </Modal.Header>
        <Modal.Content style={{ textAlign: "center" }}>
          <Button
            inverted
            color="red"
            content="ตกลง"
            onClick={() => {
              setOpenModHomeMedUnPaid(false);
            }}
          />
        </Modal.Content>
      </Modal>

      {/* drug-lab interaction */}
      <Modal
        open={props.modDrugLabInteraction?.open}
        closeOnDimmerClick={false}
      >
        <ModDrugLabInteraction
          // main function
          runSequence={props.runSequence}
          setProp={props.setProp}
          onEvent={props.onEvent}
          // data
          modDrugLabInteraction={props.modDrugLabInteraction}
          currentDoctor={props.currentDoctor}
        />
      </Modal>

      {/* drug-disease */}
      <Modal open={props.modDrugDisease?.open} closeOnDimmerClick={false}>
        <ModDrugDisease
          // main function
          runSequence={props.runSequence}
          setProp={props.setProp}
          onEvent={props.onEvent}
          // data
          modDrugDisease={props.modDrugDisease}
          currentDoctor={props.currentDoctor}
        />
      </Modal>

      {/* drug-interaction */}
      <Modal open={props.modDrugInteraction?.open} closeOnDimmerClick={false}>
        <ModDrugInteraction
          // main function
          runSequence={props.runSequence}
          setProp={props.setProp}
          onEvent={props.onEvent}
          // data
          modDrugInteraction={props.modDrugInteraction}
          currentDoctor={props.currentDoctor}
        />
      </Modal>

      {/* drug-recommendation */}
      <ModDrugRecommendation
        onEvent={props.onEvent}
        modDrugRecommendation={props.modDrugRecommendation}
      />

      <ModLotNoExp
        onEvent={props.onEvent}
        setProp={props.setProp}
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
        // data
        open={openModLotNo}
        data={selectedRow}
        lotNoExpList={props.lotNoExpList}
        orderPerformDiv={props.drugOrder?.order_perform_div || ""}
        // config
        readOnly={selectedRow?.order_status !== "REQUESTED"}
        // callback
        onClose={handleCloseModLotNo}
      />

      <ModDescriptionUD
        open={!!modDrugDesc.open}
        description={modDrugDesc.desc}
        // callback
        onClose={handleCloseModDesc}
      />

      <ModInfo
        open={!!props.errorMessage?.[`${CARD_DRUG_ORDER_WORKING}_LAST_EDITED`]}
        titleName="ข้อความแจ้งเตือน"
        titleColor="yellow"
        size="mini"
        buttonColor="green"
        centered={true}
        closeOnDimmerClick={true}
        onApprove={handleCloseModLastEdited}
        onClose={handleCloseModLastEdited}
      >
        <div
          style={{
            margin: "1.25rem 0rem 0.5rem",
            lineHeight: 2,
            fontWeight: "bold",
          }}
        >
          <div>ใบสั่งยานี้มีการแก้ไข</div>
          <div>กรุณาตรวจสอบรายละเอียดในใบสั่งยาอีกครั้ง</div>
        </div>
      </ModInfo>
    </div>
  );
};

/* ------------------------------------------------------ */
/*                    DropdownDelivery;                   */
/* ------------------------------------------------------ */
const DropdownDeliver = (props: any) => {
  return (
    <div style={{ display: "flex", margin: "0 0 auto" }}>
      <label style={{ marginRight: "1rem", marginTop: "0.55rem" }}>
        <strong>การรับยา</strong>
      </label>
      <Dropdown
        value={props.value}
        options={props.options}
        selection={true}
        // clearable={true}
        fluid={true}
        style={{ width: "165px" }}
        onChange={(_e: any, d: any) => {
          props.onChange?.(d.value || null);
        }}
      />
    </div>
  );
};

export default CardDrugOrderWorking;
