import React, { useMemo, useState, useCallback, useEffect } from "react";
import { Icon, Modal } from "semantic-ui-react";

import moment from "moment";

// UX
import CardStockManagementTabLotUX from "./CardStockManagementTabLotUX";
import ModStockAdjustData from "./ModStockAdjustData";
import ModStockAuditLog from "./ModStockAuditLog";
import ModStockOtherStore from "./ModStockOtherStore";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Interface
import {
  ActiveStatusType,
  BUTTON_ACTIONS,
  ProductLotSerializer,
  RunSequence,
  ProductStockSerializer,
  StockLogSerializer,
  StockReconcileSerializer,
  PermissionsType,
  stockExpired,
} from "./sequence/StockManagement";
import { CARD_STOCK_MANAGEMENT } from "./CardStockManagement";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";

// Types
type CardStockManagementTabLotProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: RunSequence;
  // data
  data: Partial<ProductStockSerializer>;
  productLotList?: ProductLotSerializer[];
  productStockList?: ProductStockSerializer[];
  productStockByLotList?: ProductStockSerializer[];
  auditLogList?: StockLogSerializer[];
  stockReconcileList?: StockReconcileSerializer[];
  permissions?: PermissionsType;
  // options
  statusOptions: any[];
  productTypeOptions: any[];
  stockReconcileNoteOptions?: any[];
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
};

type ModActionType = "other_store" | "adjust_data" | "audit_log" | "";

type FilterType = {
  lotNo: number | "ALL";
  status: ActiveStatusType;
};

// Const
const ICONS = {
  audit_log: "/static/images/audit-log-icon.png",
  adjust_data: "/static/images/adjust-data-icon.png",
  other_store: "/static/images/other-store-icon.png",
};

export const CARD_STOCK_MANAGEMENT_TAB_LOT = "CardStockManagementTabLot";

const CardStockManagementTabLot = (props: CardStockManagementTabLotProps) => {
  // mod
  const [modAction, setModAction] = useState<{
    type: ModActionType;
    data: ProductStockSerializer | null;
  }>({
    type: "",
    data: null,
  });
  // filter
  const [filter, setFilter] = useState<Partial<FilterType>>({
    status: "ALL",
    lotNo: "ALL",
  });

  // Callback Effect
  const handleSetFilter = useCallback(() => {
    setFilter({
      status: "ALL",
      lotNo: "ALL",
    });
  }, [props.data.id]);

  useEffect(() => {
    handleSetFilter();
  }, [props.data.id]);

  const handleOpenModAction = useCallback(
    (data: ProductStockSerializer) => async (action: ModActionType) => {
      if (action === "other_store") {
        props.runSequence({
          sequence: "StockManagement",
          action: "GET_PRODUCT_STOCK_LOT",
          product: data.product?.id,
          lot: data.lot?.id,
        });
      } else if (action === "adjust_data") {
        props.runSequence({
          sequence: "StockManagement",
          action: "GET_STOCK_RECONCILE",
          stockId: data.id,
        });
      } else if (action === "audit_log") {
        props.runSequence({
          sequence: "StockManagement",
          action: "GET_AUDIT_LOG",
          stockId: data.id,
        });
      }

      setModAction({ type: action, data });
    },
    []
  );

  // Use Memo
  const productLotItems = useMemo(() => {
    return (props.productStockList || []).map((item) => {
      // Expiry date ภายใน 6 เดือน
      const isExp = stockExpired(item.lot?.exp_datetime || "");

      return {
        ...item,
        code: item.product.code,
        name: item.product.name,
        storage: item.storage.name,
        active: item.active && (
          <div
            style={{ display: "grid", placeContent: "center", height: "100%" }}
          >
            <Icon name="check circle" color="green" size="large" />
          </div>
        ),
        action: (
          <ActionCustom
            permissions={props.permissions}
            // callback
            onOpen={handleOpenModAction(item)}
          />
        ),
        lot_no: (
          <label style={{ color: isExp ? "red" : "" }}>
            {item.lot?.mfc_no === "N/A" ? "ไม่ระบุ lot" : item.lot?.mfc_no}
          </label>
        ),
        expired_date: (
          <label style={{ color: isExp ? "red" : "" }}>
            {item.lot?.exp_datetime
              ? formatDate(moment(item.lot?.exp_datetime))
              : ""}
          </label>
        ),
        balance: (
          <label style={{ color: isExp ? "red" : "" }}>{item.quantity}</label>
        ),
      };
    });
  }, [props.productStockList]);

  const productTypeName = useMemo(() => {
    const name = props.data.product?.p_type_name;

    const option = props.productTypeOptions.find((acc) => acc.text === name);

    return !!option ? `${option.value}: ${option.text}` : "";
  }, [props.productTypeOptions, props.data.product]);

  const productLotOptions = useMemo((): any[] => {
    return (props.productLotList || []).map((item) => ({
      key: item.id,
      value: item.id,
      text: item.mfc_no,
    }));
  }, [props.productLotList]);

  const allLotNoOptions = useMemo(() => {
    return [
      { key: 1, value: "ALL", text: "แสดงทั้งหมด" },
      ...productLotOptions,
    ];
  }, [productLotOptions]);

  // Handler
  const handleCloseModAction = () => {
    setModAction({ type: "", data: null });

    props.setProp("StockManagementSequence.productStockByLotList", []);
    props.setProp("StockManagementSequence.auditLogList", []);
    props.setProp("StockManagementSequence.stockReconcileList", []);
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "StockManagement",
      action: "SEARCH_LOT",
      card: CARD_STOCK_MANAGEMENT_TAB_LOT,
      errorKey: CARD_STOCK_MANAGEMENT,
      btnAction: BUTTON_ACTIONS.SEARCH,
      lot: filter.lotNo,
      status: filter.status,
    });
  };

  const handleChangeValue = (_e: any, data: any) => {
    setFilter({ ...filter, [data.name]: data.value });
  };

  const handleClear = () => {
    handleSetFilter();
  };

  return (
    <div>
      <CardStockManagementTabLotUX
        // data
        productLotList={productLotItems}
        name={props.data.product?.name}
        code={props.data.product?.code}
        unit={props.data.product?.unit_name}
        productType={productTypeName}
        status={filter.status}
        lotNo={filter.lotNo}
        // options
        statusOptions={props.statusOptions}
        lotNoOptions={allLotNoOptions}
        // config
        disabledClear={!props.data.product?.name}
        readOnly={!props.data.product?.name}
        // callback
        onChangeValue={handleChangeValue}
        onClear={handleClear}
        // Element
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={`${CARD_STOCK_MANAGEMENT_TAB_LOT}_${BUTTON_ACTIONS.SEARCH}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_STOCK_MANAGEMENT_TAB_LOT}_${BUTTON_ACTIONS.SEARCH}`
              ]
            }
            // config
            disabled={
              !props.data.product?.name || !props.permissions?.TAB_LOT_VIEW
            }
            color={"blue"}
            name={BUTTON_ACTIONS.SEARCH}
            size="mediu"
            title="ค้นหา"
          />
        }
      />

      <Modal
        open={modAction.type === "other_store"}
        closeOnDimmerClick={true}
        style={{ width: "40%" }}
        // callback
        onClose={handleCloseModAction}
      >
        <ModStockOtherStore
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // data
          data={modAction.data || {}}
          productStockByLotList={props.productStockByLotList || []}
          permissions={props.permissions}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
          // callback
          onClose={handleCloseModAction}
        />
      </Modal>

      <Modal
        open={modAction.type === "adjust_data"}
        closeOnDimmerClick={true}
        style={{ width: "40%" }}
        // callback
        onClose={handleCloseModAction}
      >
        <ModStockAdjustData
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // data
          data={modAction.data || {}}
          stockReconcileList={props.stockReconcileList}
          permissions={props.permissions}
          stockReconcileNoteOptions={props.stockReconcileNoteOptions}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
          // callback
          onClose={handleCloseModAction}
        />
      </Modal>

      <Modal
        open={modAction.type === "audit_log"}
        size="large"
        style={{ paddingBottom: "1rem", overflow: "hidden" }}
        closeOnDimmerClick={true}
        // callback
        onClose={handleCloseModAction}
      >
        <ModStockAuditLog
          // data
          data={modAction.data || {}}
          auditLogList={props.auditLogList}
          // callback
          onClose={handleCloseModAction}
        />
      </Modal>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                      ActionCustom                      */

/* ------------------------------------------------------ */
// types
type ActionCustomProps = {
  // data
  permissions?: PermissionsType;
  // callback
  onOpen: (type: ModActionType) => any;
};

export const ActionCustom = (props: ActionCustomProps) => {
  const handleClick = (type: ModActionType, disabled: boolean) => () => {
    if (!disabled) {
      props.onOpen(type);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {(
        [
          [
            "Other store",
            "other_store",
            "#068DE5",
            props.permissions?.OTHER_STORE_VIEW,
          ],
          [
            "Adjust data",
            "adjust_data",
            "#F2711C",
            props.permissions?.ADJUST_DATA_VIEW,
          ],
          [
            "Audit log",
            "audit_log",
            "#797878",
            props.permissions?.AUDIT_LOG_VIEW,
          ],
        ] as const
      ).map((item) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "0 0.75rem",
            ...(item[3] ? { cursor: "pointer" } : { filter: "opacity(0.5)" }),
          }}
          onClick={handleClick(item[1], !item[3])}
        >
          <div
            style={{
              height: "22px",
              width: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={ICONS[item[1]]}
              alt={`${item[1]}.icon`}
              style={{ width: "20px" }}
            />
          </div>
          <label
            style={{
              color: item[2],
              fontWeight: "bold",
              marginTop: 0,
              fontSize: "0.75rem",
            }}
          >
            {item[0]}
          </label>
        </div>
      ))}
    </div>
  );
};

export default React.memo(CardStockManagementTabLot);
