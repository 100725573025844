import React, { useState, useEffect } from "react";
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Label,
  Modal,
  TextArea,
} from "semantic-ui-react";

// framework
import Tab from "react-lib/frameworks/Tab";

// UX
import CardDrugOrderQueue from "./CardDrugOrderQueue";
import CardDrugOrderHistory from "./CardDrugOrderHistory";
import CardDrugTransferRequest from "./CardDrugTransferRequest";
import CardDrugTransferRequestQueue from "./CardDrugTransferRequestQueue";
import CardDrugTransferRequestHistory from "./CardDrugTransferRequestHistory";
import CardDrugReturnRequestHistory from "./CardDrugReturnRequestHistory";
import CardMedicationErrorList from "./CardMedicationErrorList";
import CardMedicationErrorStatistics from "./CardMedicationErrorStatistics";
import CardAppointmentTelephar from "./CardAppointmentTelephar";
import CardORQueue from "../ORM/CardORQueue";
import CardDrugResuscitation from "./CardDrugResuscitation";
import CardPrintDrugLabelManual from "./CardPrintDrugLabelManual";
import CardDashboardTelephar from "./CardDashboardTelephar";

// Stock
import CardStockManagement from "./CardStockManagement";
import CardStockTransferOrder from "./CardStockTransferOrder";
import CardPHVWrapper from "HIS/PHV/CardPHVWrapper";
import CardTelepharQueue from "./CardTelepharQueue";

import CONFIG from "config/config";

const CardPharmacyTask = (props: any) => {
  console.log("Init CardPharmacyTask: ");
  const [mode, setMode] = useState("order");
  const [noti, setNoti] = useState(false);

  useEffect(() => {
    console.log("CardPharmacyTask useEffect Init   SelectEncounter !!! ");
    if (props.drugOrderTabMode) {
      setMode(props.drugOrderTabMode);
    } else {
      props.setProp("drugOrderTabMode", "order");
    }

    return () => {
      console.log("CardPharmacyTask Involve unregister SelectEncounter !!! ");
    };
  }, []);

  useEffect(() => {
    if (props?.verbalDrugOrderQueue?.length === 0) {
      setNoti(false);
    } else {
      setNoti(true);
    }
  }, [props.verbalDrugOrderQueue]);

  // Todo: have filter IPD/OPD, patient search, ward, drug order status
  // Todo: add button to print for preparing drugs and delivering drugs

  // Todo: sort STAT to come first always, then continue plan, then continue issue 1st dose
  // Todo: add verbal order to CardDrugOrderQueue
  // Todo: default filter out pharmacy task finished
  // Todo: different color for waiting time

  // Todo: add drug order history for pharmacist to review in case of ad-hoc inquiry

  // Todo (inside tab) add lab result

  const handleSetMode = (menu: string) => {
    props.setProp("drugOrderTabMode", menu);
    setMode(menu);
  };
  console.log("CardPharmacyTask mode: ", mode);

  return (
    <div style={{ height: "100%", width: "100%", padding: "10px" }}>
      <div style={{ display: "flex" }}>
        <MenuItem
          menu="order"
          mode={mode}
          name="Order Queue"
          setMode={handleSetMode}
        />
        {CONFIG.ENABLE_TELEPHAR && (
          <MenuItem
            menu="dashboardTelephar"
            mode={mode}
            name="Dashboard"
            setMode={handleSetMode}
          />
        )}
        <MenuItem
          menu="history"
          mode={mode}
          name="Drug History Search"
          setMode={handleSetMode}
        />
        <MenuItem
          menu="stock"
          mode={mode}
          name="Stock Management"
          setMode={handleSetMode}
        />
        <MenuItem
          menu="mederror"
          mode={mode}
          name="Medication Error"
          setMode={handleSetMode}
        />
        <MenuItem
          menu="verbal"
          mode={mode}
          name="รคส."
          setMode={handleSetMode}
          noti={noti}
        />
        {CONFIG.ENABLE_TELEPHAR && (
          <MenuItem
            menu="telaphar"
            mode={mode}
            name="Telephar"
            setMode={handleSetMode}
          />
        )}
        <MenuItem
          menu="orschedule"
          mode={mode}
          name="OR Schedule"
          setMode={handleSetMode}
        />
        <MenuItem
          menu="patienthistoryviewer"
          mode={mode}
          name="Patient History Viewer"
          setMode={handleSetMode}
        />
        <div style={{ flex: 1 }}></div>
        {/* Right */}
        <Button
          color="yellow"
          onClick={() => handleSetMode("print_drug_label")}
        >
          <Icon name="print" />
          พิมพ์ฉลากยา Manual
        </Button>
      </div>
      {mode === "order" ? (
        <CardDrugOrderQueue
          onEvent={props.onEvent}
          drugOrderQueue={props.drugOrderQueue}
          verbalDrugOrderQueue={props.verbalDrugOrderQueue}
          masterOptions={props.masterOptions}
          runSequence={props.runSequence}
          setProp={props.setProp}
          SetDrugOrderQueueSequence={props.SetDrugOrderQueueSequence}
          controller={props.drugOrderQueueController}
          selectedDrugOrderWorking={props.selectedDrugOrderWorking}
          selectedVerbalDrugOrderWorking={props.selectedVerbalDrugOrderWorking}
          layout={props.layout}
          forward={props?.forward}
          // config
          hidePanel={false}
          isVerbal={false}
          height="57vh"
        />
      ) : mode === "dashboardTelephar" ? (
        <CardDashboardTelephar
          onEvent={props.onEvent}
          runSequence={props.runSequence}
          masterOptions={props.masterOptions}
          masterData={props.masterData}
          layout={props.layout}
          forward={props?.forward}
          setProp={props.setProp}
          DashboardTelepharSequence={props.DashboardTelepharSequence}
        />
      ) : mode === "verbal" ? (
        <CardDrugOrderQueue
          onEvent={props.onEvent}
          drugOrderQueue={props.drugOrderQueue}
          verbalDrugOrderQueue={props.verbalDrugOrderQueue}
          masterOptions={props.masterOptions}
          runSequence={props.runSequence}
          setProp={props.setProp}
          SetDrugOrderQueueSequence={props.SetDrugOrderQueueSequence}
          controller={props.drugOrderQueueController}
          selectedDrugOrderWorking={props.selectedDrugOrderWorking}
          selectedVerbalDrugOrderWorking={props.selectedVerbalDrugOrderWorking}
          layout={props.layout}
          forward={props?.forward}
          mode={mode}
          hidePanel={true}
          isVerbal={true}
        />
      ) : mode === "history" ? (
        <div
          style={{
            padding: "1rem 5px 1.5rem",
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <CardDrugOrderHistory
            onEvent={props.onEvent}
            masterOptions={props.masterOptions}
            runSequence={props.runSequence}
            setProp={props.setProp}
            DrugOrderHistorySequence={props.DrugOrderHistorySequence}
            controller={props.drugOrderHistoryController}
            drugHistoryList={props.drugHistoryList}
            drugHistoryOrderList={props.drugHistoryOrderList}
            layout={props.layout}
            forward={props?.forward}
            //CardDrugOrderWorking
            selectedEncounter={props?.selectedEncounter}
            selectedEmr={props?.selectedEmr}
            drugOrderQueue={props?.drugOrderQueue}
            drugOrder={props?.selectedDrugOrderWorking}
            drugOrderLog={props?.drugOrderLog}
            modNoteReject={props?.modNoteReject}
            DrugSelectSequence={props?.DrugSelectSequence}
            selectedPatient={props?.selectedPatient}
            selectedDrug={props?.selectedDrug}
            drugDose={props?.drugDose}
            drugDelivery={props?.drugDelivery}
            modConfirmAction={props?.modConfirmAction}
            DrugOrderActionSequence={props?.DrugOrderActionSequence}
            medErrorWorking={props?.MedErrorListSequence?.medErrorWorking}
            errorMessage={props?.errorMessage}
            loadingStatus={props?.loadingStatus}
            successMessage={props?.successMessage}
            // DrugTransferRequest
            drugTransferRequestSequence={props?.DrugTransferRequestSequence}
            drugOrderHistoryController={props?.drugOrderHistoryController}
            drugRequestList={props?.drugRequestList}
            selectedDevice={props?.selectedDevice}
            drugTransferRequestlist={props?.drugTransferRequestlist}
            drugTransferLog={props?.drugTransferLog}
            drugTransferRequestDetail={props?.drugTransferRequestDetail}
            //ReturnOrder
            ReturnOrderSequence={props?.ReturnOrderSequence}
            OrderReturnList={props?.OrderReturnList}
            DrugReturnList={props?.DrugReturnList}
            OrderHistory={props?.OrderHistory}
            OrderReturnLog={props?.OrderReturnLog}
            // StockManagement
            lotNoExpList={props.lotNoExpList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            type={"ALL_HISTORY"} // for flag selectEncounter in function SelectDrugOrderWorking
          />
        </div>
      ) : mode === "stock" ? (
        <div style={{ height: "90vh", paddingTop: "5px" }}>
          <Tab
            tabWidth="150px"
            selectedTabIndex={0}
            data={[
              {
                key: "การจัดการสินค้า",
                name: "การจัดการสินค้า",
                render: () => (
                  <CardStockManagement
                    onEvent={props.onEvent}
                    setProp={props.setProp}
                    // seq
                    runSequence={props.runSequence}
                    StockManagementSequence={props.StockManagementSequence}
                    // data
                    selectedDivision={props.selectedDivision}
                    // CommonInterface
                    buttonLoadCheck={props.buttonLoadCheck}
                    errorMessage={props.errorMessage}
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    // options
                    masterOptions={props.masterOptions}
                  />
                ),
              },
              {
                key: "รายการโอนสินค้า (Transfer request)",
                name: (
                  <div
                    onClick={() => {
                      props.setProp("transferOrderCount", 0);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <label>รายการโอนสินค้า (Transfer request)</label>
                    {props.transferOrderCount > 0 && (
                      <Label
                        data-testid="msg-noti"
                        circular
                        color={"red"}
                        size="mini"
                        onClick={() => {
                          props.setProp("transferOrderCount", 0);
                        }}
                      >
                        {props.transferOrderCount}
                      </Label>
                    )}
                  </div>
                ),
                render: () => (
                  <CardStockTransferOrder
                    onEvent={props.onEvent}
                    setProp={props.setProp}
                    // seq
                    runSequence={props.runSequence}
                    StockTransferOrderSequence={
                      props.StockTransferOrderSequence
                    }
                    masterOptions={props.masterOptions}
                    // CommonInterface
                    buttonLoadCheck={props.buttonLoadCheck}
                    errorMessage={props.errorMessage}
                    selectedDivision={props.selectedDivision}
                  />
                ),
              },
              {
                key: "คิวใบขอยา",
                name: "คิวใบขอยา",
                render: () => (
                  <CardDrugTransferRequestQueue
                    onEvent={props.onEvent}
                    setProp={props.setProp}
                    runSequence={props.runSequence}
                    drugRequestList={props.drugRequestList}
                  />
                ),
              },
              // {
              //   key: "ขอยา",
              //   name: "ขอยา",
              //   render: () => (
              //     <CardDrugTransferRequest
              //       onEvent={props.onEvent}
              //       setProp={props.setProp}
              //       // seq
              //       runSequence={props.runSequence}
              //       DrugTransferRequestSequence={
              //         props.drugTransferRequestSequence
              //       }
              //       // options
              //       masterOptions={props.masterOptions}
              //       // controller
              //       controller={props.drugOrderHistoryController}
              //       // data
              //       drugRequestList={props.drugRequestList}
              //       selectedDevice={props.selectedDevice}
              //       selectedDivision={props.selectedDivision}
              //       drugTransferRequestlist={props.drugTransferRequestlist}
              //       drugTransferLog={props.drugTransferLog}
              //       drugTransferRequestDetail={props.drugTransferRequestDetail}
              //       // CommonInterface
              //       errorMessage={props.errorMessage}
              //       successMessage={props.successMessage}
              //     />
              //   ),
              // },
              {
                key: "ประวัติการขอยา",
                name: "ประวัติการขอยา",
                render: () => (
                  <CardDrugTransferRequestHistory
                    onEvent={props.onEvent}
                    runSequence={props.runSequence}
                    masterOptions={props.masterOptions}
                    setProp={props.setProp}
                    DrugTransferRequestHistorySequence={
                      props.DrugTransferRequestHistorySequence
                    }
                    DrugTransferRequestHistoryList={
                      props.DrugTransferRequestHistoryList
                    }
                  />
                ),
              },
              {
                key: "ประวัติการขอคืนยา",
                name: "ประวัติการขอคืนยา",
                render: () => (
                  <CardDrugReturnRequestHistory
                    onEvent={props.onEvent}
                    runSequence={props.runSequence}
                    masterOptions={props.masterOptions}
                    setProp={props.setProp}
                    controller={props.drugOrderHistoryController}
                    DrugReturnRequestHistorySequence={
                      props.DrugReturnRequestHistorySequence
                    }
                    DrugReturnRequestHistoryList={
                      props.DrugReturnRequestHistoryList
                    }
                  />
                ),
              },
              {
                key: "จัดการกล่องยากู้ชีพ",
                name: "จัดการกล่องยากู้ชีพ",
                render: () => (
                  <CardDrugResuscitation
                    // function
                    onEvent={props.onEvent}
                    setProp={props.setProp}
                    controller={props.drugOrderHistoryController}
                    // CommonInterface
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    loadingStatus={props?.loadingStatus}
                    buttonLoadCheck={props.buttonLoadCheck}
                    masterOptions={props.masterOptions}
                    // seq
                    runSequence={props.runSequence}
                    DrugResuscitationSequence={props.DrugResuscitationSequence}
                  />
                ),
              },
            ]}
          />
        </div>
      ) : mode === "telaphar" ? (
        <CardTelepharQueue
          runSequence={props.runSequence}
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          forward={props.forward}
          layout={props.layout}
          masterOptions={props.masterOptions}
          selectedDivision={props.selectedDivision}
          TelePharmacySequence={props.TelePharmacySequence}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // Noti
          postponeNotificationList={props.postponeNotificationList}
        />
      ) : mode === "orschedule" ? (
        <CardORQueue
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          forward={props.forward}
          // common
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // seq
          runSequence={props.runSequence}
          ORQueueSequence={props.ORQueueSequence}
          ZoneLogSequence={props.ZoneLogSequence}
          ORRequestSequence={props.ORRequestSequence}
          // controller
          drugOrderQueueController={props.drugOrderQueueController}
          proxyController={props.proxyController}
          // SearchBox
          searchedItemList={props.searchedItemList}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // options
          masterOptions={props.masterOptions}
          masterData={props.masterData}
          // data
          selectedOrOrder={props.selectedOrOrder}
          loadingStatus={props.loadingStatus}
          userTokenize={props.userTokenize}
          divisionId={props.divisionId}
          config={{
            showFilterORQueue: false,
            showFilterDrugQueue: true,
          }}
          cardStyle={{ margin: "0 -10px" }}
        />
      ) : mode === "print_drug_label" ? (
        <CardPrintDrugLabelManual
          onEvent={props.onEvent}
          setProp={props.setProp}
          // controller
          drugOrderQueueController={props.drugOrderQueueController}
          // CommonInterface
          searchedItemListWithKey={props.searchedItemListWithKey}
          // options
          masterOptions={props.masterOptions}
        />
      ) : mode === "patienthistoryviewer" ? (
        <div style={{ paddingTop: "1rem", height: "90vh", overflowY: "auto" }}>
          <CardPHVWrapper
            apiToken={props.apiToken}
            division={props.division}
            // function
            onEvent={props.onEvent}
            setProp={props.setProp}
            // controller
            proxyController={props.proxyController}
            // CommonInterface
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            // options
            masterOptions={props.masterOptions}
            masterData={props.masterData}
            ChoiceTriage={props.ChoiceTriage}
            // seq
            runSequence={props.runSequence}
            AssessmentSequence={props.AssessmentSequence}
            HistoryCentralLabSequence={props.HistoryCentralLabSequence}
            ORPostOperationSequence={props.ORPostOperationSequence}
            ORHistorySequence={props.ORHistorySequence}
            ORRequestSequence={props.ORRequestSequence}
            PerioperativeNursingSequence={props.PerioperativeNursingSequence}
            PreOperationSequence={props.PreOperationSequence}
            // SearchBox
            searchedItemListWithKey={props.searchedItemListWithKey}
            // data
            selectedPatient={props.selectedPatient}
            loadingStatus={props.loadingStatus}
            userTokenize={props.userTokenize}
          />
        </div>
      ) : !!props.MedErrorListSequence?.medErrorStatistics?.open ? (
        <CardMedicationErrorStatistics
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // data
          medErrorStatistics={props.MedErrorListSequence?.medErrorStatistics}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
        />
      ) : (
        <CardMedicationErrorList
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // options
          masterOptions={props.masterOptions}
          // data
          django={props.django}
          medErrorList={props.MedErrorListSequence?.medErrorList}
          filterMedError={props.MedErrorListSequence?.filterMedError}
          medErrorWorking={props.MedErrorListSequence?.medErrorWorking}
          userId={props.userId}
          selectedDivision={props.selectedDivision}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // config
          allowedPrint={true}
        />
      )}
    </div>
  );
};

const MenuItem = (props: any) => (
  <Button
    size="tiny"
    onClick={(e: any) => props.setMode(props.menu)}
    color={props.menu === props.mode ? "blue" : undefined}
  >
    {props.name === "รคส." ? (
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {props.name}
        </div>

        <div
          style={{
            display: props?.noti ? "" : "none",
            backgroundColor: "red",
            borderRadius: "50%",
            width: "1.5em",
            height: "1.5em",
            margin: "0px 5px",
          }}
        ></div>
      </div>
    ) : (
      props.name
    )}
  </Button>
);

export default CardPharmacyTask;
