import React, {
  useEffect,
  useState,
  CSSProperties,
  useRef,
  useMemo,
} from "react";
import { Button } from "semantic-ui-react";
import moment from "moment";

import CardDoctorFeeReportUX from "../APP/CardDoctorFeeReportUX";
import { beToAd, formatDate } from "react-lib/utils";

moment.locale("en-es", { week: { dow: 1 } });

const DoctorFeeReport = (props: any) => {
  const [tcp, setTcp] = useState("");
  const [tfp, setTfp] = useState("");
  const [tap, setTap] = useState("");
  const [startDate, setStartDate] = useState(formatDate(moment()));
  const [endDate, setEndDate] = useState(formatDate(moment()));

  useEffect(() => {
    props.runSequence({
      sequence: "SetDoctorFeeReport",
      action: "get_data",
      doctor: props.selectedProvider?.doctor_info?.id,
      start_date: moment(beToAd(startDate)).format("YYYY-MM-DD"),
      end_date: moment(beToAd(endDate)).format("YYYY-MM-DD"),
      report: false,
    });
  }, []);

  useEffect(() => {
    setTcp(
      formatComma(
        props.SetDoctorFeeReportSequence?.reportData?.total_compensation_price
      )
    );
    setTfp(
      formatComma(
        props.SetDoctorFeeReportSequence?.reportData
          ?.total_final_compensation_price
      )
    );
    setTap(
      formatComma(
        props.SetDoctorFeeReportSequence?.reportData
          ?.total_average_compensation_price
      )
    );
  }, [props.SetDoctorFeeReportSequence?.reportData]);

  const columns: any[] = [
    {
      Header: "CODE",
      accessor: "code",
      getFooterProps: () => ({ style: { border: "0px" } }),
      Cell: (props: any) => (
        <span
          className="code"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {props.value}
        </span>
      ),
      width: 150,
    },
    { Header: "Name", accessor: "name", Footer: "ผลรวมทั้งหมด" },
    {
      Header: "จำนวนคนไช้ (Encounter)",
      accessor: "case",
      Footer: props.SetDoctorFeeReportSequence?.reportData?.total_case,
      getFooterProps: () => ({
        style: { display: "flex", justifyContent: "center" },
      }),
      Cell: (props: any) => (
        <span
          className="case"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {props.value}
        </span>
      ),
    },
    {
      Header: "ยอดเงินรวมค่าตอบแทน (บาท)",
      accessor: "compensation_price",
      Footer: tcp,
      getFooterProps: () => ({
        style: { display: "flex", justifyContent: "center" },
      }),
      Cell: (props: any) => (
        <span
          className="compensation_price"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {formatComma(props.value)}
        </span>
      ),
    },
    {
      Header: "ยอดเงินรวมค่าตอบแทนหลังหักเช้าคณะ (บาท)",
      accessor: "final_compensation_price",
      Footer: tfp,
      getFooterProps: () => ({
        style: { display: "flex", justifyContent: "center" },
      }),
      Cell: (props: any) => (
        <span
          className="final_compensation_price"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {formatComma(props.value)}
        </span>
      ),
    },
    {
      Header: "ยอดเงินรวมค่าตอบแทนเฉลี่ย (บาท)",
      accessor: "average_compensation_price",
      Footer: tap,
      getFooterProps: () => ({
        style: { display: "flex", justifyContent: "center" },
      }),
      Cell: (props: any) => (
        <span
          className="average_compensation_price"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {formatComma(props.value)}
        </span>
      ),
    },
  ];

  const formatComma = (val: any) =>
    !isNaN(parseFloat(val))
      ? parseFloat(val)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(?:\d{3})+(?!\d))/g, ",")
      : val;

  const searchDate = () => {
    props.runSequence({
      sequence: "SetDoctorFeeReport",
      action: "get_data",
      doctor: props.selectedProvider?.doctor_info?.id,
      start_date: moment(beToAd(startDate)).format("YYYY-MM-DD"),
      end_date: moment(beToAd(endDate)).format("YYYY-MM-DD"),
      report: false,
    });
  };

  return (
    <div style={{ height: "90vh" }}>
      <div style={{ margin: "15px 25px" }}>
        <Button color="teal" active>
          รายงานภาพรวมค่าตอบแทน
        </Button>
      </div>
      <div style={{ margin: "5px 25px", borderBottom: "1px solid grey" }}></div>

      <CardDoctorFeeReportUX
        doctorFeeColumns={columns}
        doctorFeeData={props.SetDoctorFeeReportSequence?.reportData?.items}
        tableStyle={{ zoom: "95%" }}
        startDate={startDate}
        endDate={endDate}
        onChangeStartDate={(date: any) => setStartDate(date)}
        onChangeEndDate={(date: any) => setEndDate(date)}
        doctorName={props.selectedProvider?.doctor_info?.name_code}
        onSearchDate={searchDate}
        button_dowloadReport={
          <a
            href={`https://cudent-protype-d2wygk6yha-de.a.run.app/apis/DFC/doctor-fee-summary/?doctor=${
              props.selectedProvider?.doctor_info?.id
            }&report=true&start_date=${moment(beToAd(startDate)).format(
              "YYYY-MM-DD"
            )}&end_date=${moment(beToAd(endDate)).format("YYYY-MM-DD")}`}
          >
            <Button color="orange">Export Report</Button>
          </a>
        }
      />
    </div>
  );
};

export default DoctorFeeReport;
