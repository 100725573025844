import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'

const ANES_AnesComplication_Tab = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ padding: "0px 10px"}}>
        <div
          style={{ display: "flex" ,padding: "10px 0px"}}>
          
          <Button
            name="Complication"
            onClick={props.onChangeContainerName}
            style={{backgroundColor:"#D9D9D9", textalign: "center", color: props.tab === "Complication" ? "blue" : "#746A6A",}}>
            Complication
          </Button>
          <Button
            name="Outcome"
            onClick={props.onChangeContainerName}
            style={{backgroundColor:"#D9D9D9", textalign: "center", color: props.tab === "Outcome" ? "blue" : "#746A6A",}}>
            Outcome
          </Button>
          <Button
            name="Postoperative"
            onClick={props.onChangeContainerName}
            style={{backgroundColor:"#D9D9D9", textalign: "center", color: props.tab === "Postoperative" ? "blue" : "#746A6A",}}>
            Postoperative Pain management PACU
          </Button>
          <Button
            name="Postanesthesia"
            onClick={props.onChangeContainerName}
            style={{backgroundColor:"#D9D9D9", textalign: "center", color: props.tab === "Postanesthesia" ? "blue" : "#746A6A",}}>
            Postanesthesia care off service time
          </Button>
          <Button
            name="Assessment"
            onClick={props.onChangeContainerName}
            style={{backgroundColor:"#D9D9D9", textalign: "center", color: props.tab === "Assessment" ? "blue" : "#746A6A",}}>
            Assessment history
          </Button>
        </div>
        <hr>
        </hr>
      </div>
    )
}

export default ANES_AnesComplication_Tab

export const screenPropsDefault = {}

/* Date Time : Wed Dec 07 2022 10:16:24 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,padding: \"10px 0px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Complication"
        },
        "name": {
          "type": "value",
          "value": "Complication"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeContainerName"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor:\"#D9D9D9\", textalign: \"center\", color: props.tab === \"Complication\" ? \"blue\" : \"#746A6A\",}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Outcome"
        },
        "name": {
          "type": "value",
          "value": "Outcome"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeContainerName"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor:\"#D9D9D9\", textalign: \"center\", color: props.tab === \"Outcome\" ? \"blue\" : \"#746A6A\",}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Postoperative Pain management PACU"
        },
        "name": {
          "type": "value",
          "value": "Postoperative"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeContainerName"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor:\"#D9D9D9\", textalign: \"center\", color: props.tab === \"Postoperative\" ? \"blue\" : \"#746A6A\",}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Postanesthesia care off service time"
        },
        "name": {
          "type": "value",
          "value": "Postanesthesia"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeContainerName"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor:\"#D9D9D9\", textalign: \"center\", color: props.tab === \"Postanesthesia\" ? \"blue\" : \"#746A6A\",}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Assessment history"
        },
        "name": {
          "type": "value",
          "value": "Assessment"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeContainerName"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor:\"#D9D9D9\", textalign: \"center\", color: props.tab === \"Assessment\" ? \"blue\" : \"#746A6A\",}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "hr",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 12,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_AnesComplication_Tab",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
