import React, { useState } from "react";
import { Modal, Button, Segment, Dropdown, Loader, Form, TextArea } from "semantic-ui-react";
import EmployeeToken from "react-lib/apps/common/EmployeeToken";

const ModDuplicateAppointment = (props: any) => {
  const styles = {
    header: {
      lineHeight: "1.3em",
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
      backgroundColor: "red",
    },
    button: {
      marginTop: "10px",
      marginRight: "20px",
      minWidth: "100px",
    },
    line: {
      display: "flex",
      justifyContent: "flex-start",
      margin: "5px 0px",
    },
    fontBig: {
      fontSize: "1.5em",
    },
    appointmented: {
      margin: "10px 0px 30px 0px",
    },
    boldlefthalf: {
      width: "50%",
      fontWeight: "bolder",
      textAlign: "left" as const, // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html#const-assertions
    },
    column: {
      display: "flex",
      width: "50%",
      flexDirection: "column" as const,
      alignItems: "flex-start",
    },
    left: {
      textAlign: "left" as const,
    },
    boldleft: {
      fontWeight: "bolder",
      textAlign: "left" as const,
    },
  };

  const handleButtonLeftClick = () => {
    props.onButtonLeftClick();
  };

  const handleButtonRightClick = () => {
    props.onButtonRightClick();
  };

  return (
    <>
      <Modal open={props.open} size={props.size} onClose={props.onClose}>
        <Segment inverted className={"modHeader " + props.titleColor} style={styles.header}>
          {props.title}
        </Segment>
        <Segment align="center" className="modContent" style={styles.fontBig}>
          <div style={{ margin: "0px 10px" }}>
            <div style={styles.appointmented}>
              <div style={styles.line}>
                <div style={styles.boldlefthalf}>
                  {"HN: "} {props.hn}
                </div>
                <div style={styles.boldlefthalf}>
                  {"ชื่อ: "} {props.patientName}
                </div>
              </div>

              <div style={styles.line}>
                <div style={styles.boldlefthalf}>
                  {"มีนัดหมายแล้วในวันที่ "} {props.appointmentedDate}
                </div>
              </div>

              <div style={styles.line}>
                <div style={styles.boldlefthalf}>
                  {"คลินิก: "} {props.appointmentedClinicName}
                </div>
                <div style={styles.boldlefthalf}>
                  {"ทันตแพทย์: "} {props.appointmentedDoctorName}
                </div>
              </div>
            </div>

            <div style={styles.line}>
              <div style={styles.column}>
                <div style={styles.boldleft}>{"ระบุเหตุผลการนัดหมายซ้ำซ้อน "}</div>
                <div style={styles.left}>
                  {"คลินิก: "}
                  {props.duplicateClinicName}
                </div>
              </div>

              <div style={styles.column}>
                <div style={styles.left}>
                  {"วันที่ "} {props.duplicateDate}
                </div>
                <div style={styles.left}>
                  {"ทันตแพทย์: "} {props.duplicateDoctorName}
                </div>
              </div>
            </div>
            <div style={{ width: "100%", margin: "20px 0px" }}>
              <Form>
                <div style={{ textAlign: "left", fontWeight: "bolder", fontSize: "1.5em" }}> {"Repetition Note: "} </div>
                {props.reoccureDuplicateAppointment && (
                  <div style={{ textAlign: "left", fontWeight: "bolder", fontSize: "1.5em", color: "red", margin: "10px 0px" }}> กรุณาใส่เหตุผล </div>
                )}
                <TextArea style={{ width: "100%" }} rows={6} value={props.reason} onChange={props.onChangeReason} />
              </Form>
            </div>
            <div style={{ display: "flex", margin: "30px 0px", justifyContent: "space-between" }}>
              <div>
                <span style={{ marginRight: "10px", fontWeight: "bold" }}>{"ผู้ยืนยันนัดหมาย"}</span>

                <EmployeeToken
                  placeholder={"รหัสผู้บันทึก"}
                  onEnterToken={(code) => {
                    props.onEvent({
                      message: "UserTokenize",
                      params: {
                        action: "CheckUserToken",
                        code: code,
                      },
                    });

                    // props.onEvent({
                    //   message: "HandleGetDeliverDrugTokenization",
                    //   params: { code },
                    // });
                  }}
                  onClearToken={() =>
                    props.setProp("userTokenize", {
                      ...props.userTokenize,
                      token: "",
                      employeeName: "",
                      loading: false,
                      error: null,
                    })
                  }
                  error={!!props.userTokenize?.error}
                  loading={props.userTokenize?.loading}
                  employeeName={props.userTokenize?.employeeName}
                />
              </div>
              <div>
                <Button
                  style={styles.button}
                  {...(props.isButtonBasic && { basic: true })}
                  loading={props.buttonLeftLoading}
                  disabled={props.buttonRightLoading}
                  color={props.buttonLeftColor}
                  onClick={handleButtonLeftClick}
                >
                  {props.buttonLeftLabel}
                </Button>
                <Button
                  style={styles.button}
                  {...(props.isButtonBasic && { basic: true })}
                  loading={props.buttonRightLoading}
                  disabled={props.buttonLeftLoading}
                  color={props.buttonRightColor}
                  onClick={handleButtonRightClick}
                >
                  {props.buttonRightLabel}
                </Button>
              </div>
            </div>
          </div>
        </Segment>
      </Modal>
    </>
  );
};

export default ModDuplicateAppointment;
