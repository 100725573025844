import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  CSSProperties,
} from "react";
import {
  Icon,
  Button,
  Dropdown,
  Modal,
  Checkbox,
  Input,
} from "semantic-ui-react";

import moment from "moment";

// UX
import CardLabRequestUX from "./CardLabRequestUX";
import CardLabGroupUX from "./CardLabGroupUX";

// Common
import { ErrorMessage, ModConfirm } from "react-lib/apps/common";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";

// Interface
import { checkAllowEditOrder } from "./sequence/OrderCentralLab";

// Config
import config from "config/config";

// Types
type CardLabRequestProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: any;
  OrderCentralLabSequence?: Record<string, any> | null;
  // CommonInterface
  selectedEmr?: Record<string, any>;
  selectedProgressCycle?: Record<string, any>;
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  selectedEncounter?: Record<string, any>;
  loadingStatus?: Record<string, any>;
  django?: any;
  // data
  divisionType?: string | null;
  doctorLabOrderList?: { items: any[] };
  userLabPermission?: Record<string, any>;
  medReconcileCheck?: Record<string, any>;
  medReconcileIndex?: any;
  // style
  saveLabOrderStyle?: CSSProperties;
  doctorLabOrderTableStyle?: CSSProperties;
  // options
  divisionOpdOptions?: any[];
  // callback
  onSuccess?: () => any;
  // config
  isRestart?: boolean;
  isNullEmr?: boolean;
  checkOutPerformDiv?: boolean;
  editId?: number | null;
  isBloodBank?: boolean;
  isAppointment?: boolean;
};

// Const
const HOST = `${config.API_HOST}`;

const URGENCY_OPTIONS = [
  { key: "ROUTINE", value: "ROUTINE", text: "ROUTINE" },
  { key: "STAT", value: "STAT", text: "STAT" },
  {
    key: "FAST_TRACK",
    value: "FAST_TRACK",
    text: "FAST_TRACK",
  },
];

const CARD_LAB_REQUEST = "CardLabRequest";

const CardLabRequest = (props: CardLabRequestProps) => {
  const [openLabGroup, setOpenLabGroup] = useState(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [modDelete, setModDelete] = useState<Record<string, any> | null>(null);

  const [isOrderTime, setIsOrderTime] = useState(false);

  const [appointmentDate, setAppointmentDate] = useState<string>("");
  const [performDivBloodBank, setPerformDivBloodBank] = useState<any>(null);
  const [completed, setCompleted] = useState(false);

  // Effect
  useEffect(() => {
    props.runSequence({
      sequence: "OrderCentralLab",
      restart: props.isRestart,
      isNullEmr: props.isNullEmr,
      isOutPerformDiv: props.checkOutPerformDiv,
      editId: props.editId,
    });
  }, []);

  useEffect(() => {
    if (!!props.divisionOpdOptions?.length) {
      const labDivision = props.divisionOpdOptions.find((item: any) =>
        item.text.includes("ห้องแลป")
      );

      if (labDivision) {
        setPerformDivBloodBank(labDivision.value);
      } else {
        setPerformDivBloodBank(null);
      }
    }
    // console.log("divisionOpdOptions: ", props.divisionOpdOptions)
  }, [props.divisionOpdOptions]);

  useEffect(() => {
    if (!!props.selectedEmr || !!props.selectedProgressCycle) {
      props.onEvent({
        message: "GetDoctorLabOrderList",
        params: {
          progression_cycle: props.selectedProgressCycle?.id || null,
          emr: props.selectedEmr?.id || null,
        },
      });
    }
  }, [props.selectedEmr, props.selectedProgressCycle]);

  useEffect(() => {
    setIsOrderTime(
      props.OrderCentralLabSequence?.labOrder?.isOrderTime || false
    );
  }, [props.OrderCentralLabSequence?.labOrder?.isOrderTime]);

  useEffect(() => {
    if (props.OrderCentralLabSequence?.duplicateLabDetected === true) {
      setOpenConfirm(true);
    }
  }, [props.OrderCentralLabSequence?.duplicateLabDetected]);

  // MedReconcile Check
  useEffect(() => {
    const roleTypes: any[] = props.django?.user?.role_types || [];

    if (
      ["หอผู้ป่วย"].includes(props.divisionType || "") &&
      (roleTypes || []).includes("DOCTOR") &&
      props.selectedEncounter?.type === "IPD"
    ) {
      const callFrom = "DPI";

      if (props.selectedEncounter?.id) {
        props.onEvent({
          message: "GetMedReconcileCheck",
          params: { encounter: props.selectedEncounter, callForm: callFrom },
        });
      }
    } else {
      console.warn("ไม่ได้มาจาก หอผู้ป่วย เปิด CardLabRequest ");
    }
  }, [props.selectedEncounter, props.django, props.divisionType]);

  useEffect(() => {
    if (props.checkOutPerformDiv) {
      return;
    }

    if (
      props.errorMessage?.OrderCentralLab !== null &&
      props.errorMessage?.OrderCentralLab !== undefined
    ) {
      setTimeout(() => {
        props.runSequence({
          sequence: "OrderCentralLab",
          action: "clear",
          isNullEmr: props.isNullEmr,
        });
      }, 2000);
    }

    if (
      props.successMessage?.OrderCentralLab !== null &&
      props.successMessage?.OrderCentralLab !== undefined
    ) {
      setCompleted(true);

      setTimeout(() => {
        setCompleted(false);
      }, 2000);

      props.runSequence({
        sequence: "OrderCentralLab",
        action: "clear",
        isNullEmr: props.isNullEmr,
      });
    }
  }, [
    props.successMessage?.OrderCentralLab,
    props.errorMessage?.OrderCentralLab,
  ]);

  // Callback
  const handleDelete = useCallback(
    (idx: number) => () => {
      const labOrderItems: any[] =
        props.OrderCentralLabSequence?.labOrder?.order_items || [];

      labOrderItems.splice(idx, 1);

      props.setProp(
        "OrderCentralLabSequence.labOrder.order_items",
        labOrderItems
      );
      // clear previous order id
      if (labOrderItems.length <= 0) {
        props.setProp("OrderCentralLabSequence.labOrder", {
          order_items: [],
        });
      }

      props.runSequence({
        sequence: "OrderCentralLab",
        action: "delete",
        isNullEmr: props.isNullEmr,
      });
    },
    [props.OrderCentralLabSequence?.labOrder, props.isNullEmr]
  );

  const handleCheck = useCallback(
    (idx: number) => (e: any, data: any) => {
      const tmp: any[] = [...props.OrderCentralLabSequence?.labGroupItems];

      tmp[idx].selected = data.checked;

      if (tmp[idx].count === 0) {
        tmp[idx].count = 1;
      }

      props.setProp("OrderCentralLabSequence.labGroupItems", tmp);
    },
    [props.OrderCentralLabSequence?.labGroupItems]
  );

  // Memo
  const labDivisions = useMemo(() => {
    return [
      { key: "All Division", value: "All Division", text: "All Division" },
      ...(props.isBloodBank
        ? (props.OrderCentralLabSequence?.labDivisions || []).filter(
            (item: any) =>
              props.userLabPermission?.config_LAB_BLOOD_BANK_LAB_DIVISION_CODES.includes(
                item.code
              )
          )
        : props.OrderCentralLabSequence?.labDivisions || []),
    ].map((item: any) => ({
      key: item.key,
      value: item.value,
      text: item.text,
    }));
  }, [props.OrderCentralLabSequence?.labDivisions]);

  const labOrderItems = useMemo(
    () =>
      props.OrderCentralLabSequence?.labOrder?.order_items?.map(
        (item: any, idx: any) => {
          return {
            ...item,
            specimen_time: (
              <Input
                value={item.specimen_time}
                fluid={true}
                onChange={(e) => {
                  props.setProp(
                    `OrderCentralLabSequence.labOrder.order_items.${idx}.specimen_time`,
                    e.target.value
                  );
                }}
                style={{ border: "none" }}
              />
            ),
            urgency: (
              <div className="dropdown overflow">
                <Dropdown
                  fluid={true}
                  selection
                  options={URGENCY_OPTIONS}
                  value={item.urgency}
                  onChange={(e: any, { value }: any) => {
                    props.setProp(
                      `OrderCentralLabSequence.labOrder.order_items.${idx}.urgency`,
                      value
                    );
                  }}
                />
              </div>
            ),
            note: (
              <Input
                value={item.note}
                fluid={true}
                onChange={(e) => {
                  props.setProp(
                    `OrderCentralLabSequence.labOrder.order_items.${idx}.note`,
                    e.target.value
                  );
                }}
                style={{ border: "none" }}
              />
            ),
            delete: (
              <Button
                circular
                icon="trash alternate"
                color="red"
                onClick={handleDelete(idx)}
              />
            ),
          };
        }
      ),
    [
      props.OrderCentralLabSequence,
      props.OrderCentralLabSequence?.labOrder?.order_items,
    ]
  );

  const labGroupItems = useMemo(
    () =>
      props.OrderCentralLabSequence?.labGroupItems?.map(
        (item: any, idx: any) => {
          return {
            ...item,
            detail: item.detail.map((d: any) => d).join(),
            check: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Checkbox
                  key={item.id}
                  checked={item.selected}
                  onChange={handleCheck(idx)}
                />
              </div>
            ),
          };
        }
      ),
    [
      props.OrderCentralLabSequence,
      props.OrderCentralLabSequence?.labGroupItems,
    ]
  );

  const doctorLabOrderItems = useMemo(
    () =>
      props.doctorLabOrderList?.items.map((item: any, idx: any) => {
        const disabledButton = !checkAllowEditOrder(item);

        return {
          ...item,
          detail: (
            <div
              dangerouslySetInnerHTML={{
                __html: item.detail,
              }}
            />
          ),
          edit: (
            <Button
              circular
              icon="edit alternate"
              color="blue"
              disabled={disabledButton}
              onClick={() => {
                let items: any[] = props.doctorLabOrderList?.items || [];

                props.runSequence({
                  sequence: "OrderCentralLab",
                  action: "edit",
                  selected: items[idx].id || null,
                  isNullEmr: props.isNullEmr,
                });
              }}
            />
          ),
          delete: (
            <Button
              circular
              icon="trash alternate"
              color="red"
              disabled={disabledButton}
              onClick={() => {
                const items: any[] = props.doctorLabOrderList?.items || [];

                setModDelete(items[idx]);
              }}
            />
          ),
        };
      }),
    [props.doctorLabOrderList, props.doctorLabOrderList?.items]
  );

  // Handler
  const handleSaveDupLab = () => {
    props.setProp(
      "OrderCentralLabSequence.labOrder.allow_duplicate_flag",
      true
    );
    props.setProp("OrderCentralLabSequence.duplicateLabDetected", false);

    props.runSequence({
      sequence: "OrderCentralLab",
      action: props.OrderCentralLabSequence?.labOrder?.id
        ? "edit_save"
        : "save",
      isNullEmr: props.isNullEmr,
      isOrderTime,
      isOutPerformDiv: props.checkOutPerformDiv,
      cardKey: CARD_LAB_REQUEST,
      onSuccess: props.onSuccess,
    });

    handleCloseModConfirm();
  };

  const handleCloseModConfirm = () => {
    props.setProp("OrderCentralLabSequence.duplicateLabDetected", false);

    setOpenConfirm(false);
  };

  const handleSaveLabOrder = () => {
    // console.log(props.OrderCentralLabSequence.labOrder);
    if (props.OrderCentralLabSequence?.labOrder?.id) {
      props.runSequence({
        sequence: "OrderCentralLab",
        action: "edit_save",
        isNullEmr: props.isNullEmr,
        isOrderTime,
        isOutPerformDiv: props.checkOutPerformDiv,
        cardKey: CARD_LAB_REQUEST,
        isAppointment: props.isAppointment || false,
        onSuccess: props.onSuccess,
      });
    } else {
      props.runSequence({
        sequence: "OrderCentralLab",
        action: "save",
        isNullEmr: props.isNullEmr,
        isOrderTime,
        isOutPerformDiv: props.checkOutPerformDiv,
        isAppointment: props.isAppointment || false,
        isBloodBank: props.isBloodBank || false,
        isAdvanceAppointment: appointmentDate === "beforeAppointment",
        performDivBloodBank,
        cardKey: CARD_LAB_REQUEST,
        onSuccess: props.onSuccess,
      });
    }
  };

  const handleCloseLabGroup = async () => {
    setOpenLabGroup(false);

    const tmp: any[] = [
      ...(props.OrderCentralLabSequence?.labGroupItems || []),
    ].map((item) => ({ ...item, selected: false }));

    await props.setProp("OrderCentralLabSequence.labGroupItems", tmp);
  };

  const handleSelected = async () => {
    let selectedGroup = props.OrderCentralLabSequence?.labGroupItems.filter(
      (it: any) => it.selected === true
    );
    let selectedItems = selectedGroup.map((it: any) => it.items).flat();

    await handleCloseLabGroup();

    props.runSequence({
      sequence: "OrderCentralLab",
      action: "group_add",
      items: selectedItems,
      isNullEmr: props.isNullEmr,
    });
  };

  const handleGetTdPropsLabRequest = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        cursor: "pointer",
        overflow: "visible",
      },
    };
  };

  const handleGetTrPropsDoctorLabOrder = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      className:
        !!props.OrderCentralLabSequence?.labOrder?.id &&
        rowInfo?.original?.id === props.OrderCentralLabSequence?.labOrder?.id
          ? "blueSelectedRow"
          : "",
    };
  };

  const handleLabTestRowProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => ({
    onDoubleClick: () =>
      props.runSequence({
        sequence: "OrderCentralLab",
        action: "add",
        addedItem: rowInfo?.original,
        isNullEmr: props.isNullEmr,
      }),
  });

  const handleCancelEdit = () => {
    props.setProp("OrderCentralLabSequence.labOrder", {});
  };

  const handleAdvanceChange = (e: any, data: any) => {
    setIsOrderTime(data.checked);

    if (data.checked === false) {
      props.setProp("OrderCentralLabSequence.labOrder.order_time", null);
      props.setProp("OrderCentralLabSequence.labOrder.out_perform_div", null);
    }
  };

  const handleChangeOutPerformDiv = (e: any, v: any) => {
    props.setProp("OrderCentralLabSequence.labOrder.out_perform_div", v.value);
  };

  const handleSearchLabTest = () =>
    props.runSequence({
      sequence: "OrderCentralLab",
      action: "search",
      isNullEmr: props.isNullEmr,
      isBloodBank: props.isBloodBank,
    });

  const handleApproveMedReconcile = () => {
    props.setProp("medReconcileCheck.open_card_med_reconciliation", false);

    props.onEvent({
      message: "OpenMedReconcileFromWarning",
      params: {
        medReconcileCheck: props.medReconcileCheck,
        selecteRecordViewIndex: props.medReconcileIndex,
      },
    });
  };

  const handleConfirmDelete = () => {
    props.runSequence({
      sequence: "OrderCentralLab",
      action: "cancel",
      item: modDelete,
      isNullEmr: props.isNullEmr,
    });

    setModDelete(null)
  };

  const handleCloseModDelete = () => {
    setModDelete(null);
  };

  // console.log("CardLabRequest props:", props);

  return (
    <div style={{ padding: "10px" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_LAB_REQUEST}`, null);
        }}
        error={props.errorMessage?.[CARD_LAB_REQUEST]}
        success={null}
      />

      <ModMedReconcileAlert
        // medReconcileCheck={props.medReconcileCheck}
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        onApprove={handleApproveMedReconcile}
      >
        {props.medReconcileCheck?.med_reconciliation != null
          ? `Med Reconcile ${props.medReconcileCheck?.med_reconciliation_label} ยังไม่ได้ถูกรับทราบโดยแพทย์`
          : `ยังไม่ได้สร้าง Medication Reconciliation สำหรับ PlanTransfer ${props.medReconcileCheck?.plan_transfer_id}`}
      </ModMedReconcileAlert>

      <CardLabRequestUX
        setProp={props.setProp}
        onEvent={props.onEvent}
        // data
        isIPD={
          props.selectedEncounter?.type === "IPD" ||
          (props.checkOutPerformDiv && !props.isBloodBank)
        }
        isBloodBank={
          props.isBloodBank &&
          props.isAppointment &&
          props.userLabPermission?.config_QUE_ADVANCE_APPOINTMENT_DATE_BEFORE
        }
        doctorLabOrderList={doctorLabOrderItems || []}
        labDivision={props.OrderCentralLabSequence?.labDivision}
        outPerformDiv={
          props.OrderCentralLabSequence?.labOrder?.out_perform_div || ""
        }
        configAppointmentDateText={
          props.userLabPermission?.config_QUE_ADVANCE_APPOINTMENT_DATE_BEFORE
            ? `นัดหมายล่วงหน้า ${props.userLabPermission.config_QUE_ADVANCE_APPOINTMENT_DATE_BEFORE} วัน`
            : ""
        }
        performDiv={performDivBloodBank}
        isOrderTime={isOrderTime}
        orderTime={props.OrderCentralLabSequence?.labOrder?.order_time}
        labOrderItems={labOrderItems || []}
        message={props.OrderCentralLabSequence?.labOrder?.note || ""}
        price_claimable={props.OrderCentralLabSequence?.price_claimable || 0}
        price_non_claimable={
          props.OrderCentralLabSequence?.price_non_claimable || 0
        }
        price_total={props.OrderCentralLabSequence?.price_total || 0}
        appointmentDate={appointmentDate}
        labCode={props.OrderCentralLabSequence?.labCode}
        labName={props.OrderCentralLabSequence?.labName}
        labTests={props.OrderCentralLabSequence?.labTests || []}
        loadingSave={props?.loadingStatus?.["OrderCentralLab"]}
        // style
        saveLabOrderStyle={props.saveLabOrderStyle}
        doctorLabOrderTableStyle={props.doctorLabOrderTableStyle}
        // options
        labDivisions={labDivisions}
        divisionOpdOptions={props.divisionOpdOptions}
        // config
        disableOrderTime={!isOrderTime}
        checkOutPerformDiv={props.checkOutPerformDiv}
        isEdit={!!props.editId || !!props.OrderCentralLabSequence?.labOrder?.id}
        showCancelEdit={
          !props.editId && !!props.OrderCentralLabSequence?.labOrder?.id
        }
        // callback
        isAdvanceChange={handleAdvanceChange}
        onChangeOutPerformDiv={handleChangeOutPerformDiv}
        searchLabTest={handleSearchLabTest}
        labTestRowProps={handleLabTestRowProps}
        saveLabOrder={handleSaveLabOrder}
        onCancelEdit={handleCancelEdit}
        onChangePerformDiv={(e: any, data: any) => {
          setPerformDivBloodBank(data.value);
        }}
        openLabGroup={() => setOpenLabGroup(true)}
        orderTimeChange={(value: any) =>
          props.setProp(
            "OrderCentralLabSequence.labOrder.order_time",
            value + moment().format("-HH:mm")
          )
        }
        changeLabDivision={(e: any, v: any) =>
          props.setProp("OrderCentralLabSequence.labDivision", v.value)
        }
        changeLabCode={(e: any) =>
          props.setProp("OrderCentralLabSequence.labCode", e.target.value)
        }
        changeLabName={(e: any) =>
          props.setProp("OrderCentralLabSequence.labName", e.target.value)
        }
        onChangeMessage={(e: any) =>
          props.setProp("OrderCentralLabSequence.labOrder.note", e.target.value)
        }
        onCheckedBeforeAppointment={() => {
          setAppointmentDate(
            appointmentDate === "beforeAppointment" ? "" : "beforeAppointment"
          );
        }}
        // Table
        getTdPropsLabRequest={handleGetTdPropsLabRequest}
        getTrPropsDoctorLabOrder={handleGetTrPropsDoctorLabOrder}
        // Element
        button_save={
          completed ? (
            <Icon name="check"></Icon>
          ) : !!props.editId ||
            !!props.OrderCentralLabSequence?.labOrder?.id ? (
            "EDIT"
          ) : (
            "SAVE"
          )
        }
        ErrorMessage={
          <ErrorMessage error={props?.errorMessage?.["OrderCentralLab"]} />
        }
      />

      <Modal
        closeIcon
        style={{ left: "unset !important" }}
        size={"large"}
        open={openLabGroup}
        onClose={handleCloseLabGroup}
      >
        <CardLabGroupUX
          setProp={props.setProp}
          onEvent={props.onEvent}
          labGroupSearch={props.OrderCentralLabSequence?.labGroupSearch}
          labGroupSearchChange={(e: any) =>
            props.setProp(
              "OrderCentralLabSequence.labGroupSearch",
              e.target.value
            )
          }
          labGroupSearchBtn={() =>
            props.runSequence({
              sequence: "OrderCentralLab",
              action: "group_search",
              isNullEmr: props.isNullEmr,
            })
          }
          labGroupManageBtn={() =>
            window.open(`${HOST}/manage/LAB/centrallabtemplate/`)
          }
          labGroupSelectedBtn={handleSelected}
          labGroupItems={labGroupItems || []}
        />
      </Modal>

      <ModConfirm
        openModal={openConfirm}
        titleName="กรุณายืนยัน"
        // @ts-ignore
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: props.OrderCentralLabSequence?.promptMessage,
            }}
          />
        }
        onApprove={handleSaveDupLab}
        onDeny={handleCloseModConfirm}
      />

      <ModConfirm
        openModal={!!modDelete}
        titleName="ต้องการลบ Lab Center Laboratory นี้ใช่หนือไม่"
        titleColor="red"
        size="mini"
        denyButtonColor="red"
        denyButtonText="ไม่"
        approveButtonText="ใช่"
        content={
          <div
            style={{
              margin: "-0.5rem 0rem -1rem 0rem",
              textAlign: "center",
              fontSize: "1.2rem",
            }}
          ></div>
        }
        onDeny={handleCloseModDelete}
        onApprove={handleConfirmDelete}
        onCloseWithDimmerClick={handleCloseModDelete}
      />
    </div>
  );
};

export default React.memo(CardLabRequest);
