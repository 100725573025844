import React, { useEffect, useMemo, useState } from "react";
import { Button, Dropdown, Message, Modal } from "semantic-ui-react";
// ui ORM
import CardServiceRefundUX from "./CardServiceRefundUX";

type CardServiceRefundProps = {
  // function
  onEvent: any;
  setProp: any;
  buttonLoadCheck?: any;
  errorMessage?: any;
  successMessage?: any;

  // selectEncounter
  selectedEncounter?: any;

  // options
  masterOptions?: any;
  masterData?: any;

  // seq
  runSequence?: any;
  ORServiceRefundSequence?: any;
};

const CardServiceRefundInitial: CardServiceRefundProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,
  buttonLoadCheck: null,
};

const CardServiceRefund = (props: CardServiceRefundProps) => {
  const [encounterList, setEncounterList] = useState<any>([]);
  const [refundable, setRefundable] = useState(true);
  const [refunding, setRefunding] = useState(false);
  const [refunded, setRefunded] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [showModalRefund, setShowModalRefund] = useState(false);
  const [showModalCancelRefund, setShowModalCancelRefund] = useState(false);
  const [refundSelect, setRefundSelect] = useState();
  const [showModalSucceed, setShowModalSucceed] = useState(false);
  const [statusRefund, setStatusRefund] = useState(true);
  const [statusCancelRefund, setStatusCancelRefund] = useState(true);

  useEffect(() => {
    props.runSequence({
      sequence: "ORServiceRefund",
      action: "getEncounterList",
      encounterId: props.selectedEncounter?.patient,
    });
  }, []);

  useEffect(() => {
    let dataList: any = [];
    dataList.push({ key: 0, value: 0, text: "Select Encounter" });
    props.ORServiceRefundSequence?.encounterList.forEach(
      (item: any, index: any) => {
        let data = `[${item?.general_type}][${item?.number}] ${item?.edited}-${item?.division_name}-${item?.doctor_name}`;
        dataList.push({ key: index + 1, value: item?.id, text: data });
      }
    );
    setEncounterList(dataList);
  }, [props.ORServiceRefundSequence?.encounterList]);

  useEffect(() => {
    props.runSequence({
      sequence: "ORServiceRefund",
      action: "getInvoiceItem",
      encounterId: props.ORServiceRefundSequence?.data?.encounterSelect,
      refundable: refundable ? 1 : "",
      refunding: refunding ? 1 : "",
      refunded: refunded ? 1 : "",
    });
  }, [props.ORServiceRefundSequence?.data?.encounterSelect]);

  useEffect(() => {
    if (props.successMessage?.ORServiceRefund === "success") {
      setShowModalRefund(false);
      setShowModalCancelRefund(false);
      setShowModalSucceed(true);
      
      props.runSequence({
        sequence: "ORServiceRefund",
        action: "getInvoiceItem",
        encounterId: props.ORServiceRefundSequence?.data?.encounterSelect,
        refundable: refundable ? 1 : "",
        refunding: refunding ? 1 : "",
        refunded: refunded ? 1 : "",
      });
    }
  }, [props.successMessage]);

  const handleGetInvoiceitem = (status: any) => {
    if (status === "refundable") {
      setRefundable(true);
      setRefunding(false);
      setRefunded(false);
      setStatusCancelRefund(true);
      setStatusRefund(true);
    } else if (status === "refunding") {
      setRefundable(false);
      setRefunding(true);
      setRefunded(false);
      setStatusCancelRefund(true);
      setStatusRefund(true);
    } else if (status === "refunded") {
      setRefundable(false);
      setRefunding(false);
      setRefunded(true);
      setStatusCancelRefund(true);
      setStatusRefund(true);
    }

    props.runSequence({
      sequence: "ORServiceRefund",
      action: "getInvoiceItem",
      encounterId: props.ORServiceRefundSequence?.data?.encounterSelect,
      refundable: status === "refundable" ? 1 : "",
      refunding: status === "refunding" ? 1 : "",
      refunded: status === "refunded" ? 1 : "",
    });
  };

  const handleChangeValue = (key: string) => (_event: any, data: any) => {
    props.setProp(`ORServiceRefundSequence.data.${key}`, data.value);
  };

  const handleRowProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id && rowInfo?.original?.id === selectedRow?.id
            ? "#cccccc"
            : "white",
      },
      onClick: () => {
        setSelectedRow(rowInfo.original);
        if (refundable) {
          setStatusRefund(false);
        } else if (refunding) {
          setStatusCancelRefund(false);
        }
      },
    };
  };

  return (
    <>
      <CardServiceRefundUX
        refundable={() => {
          handleGetInvoiceitem("refundable");
        }}
        refunding={() => {
          handleGetInvoiceitem("refunding");
        }}
        refunded={() => {
          handleGetInvoiceitem("refunded");
        }}
        encounterList={encounterList}
        handleChangeValue={handleChangeValue}
        data={props.ORServiceRefundSequence?.data}
        invoiceItemData={props.ORServiceRefundSequence?.data?.invoiceItemList}
        handleRowProps={handleRowProps}
        statusRefundable={refundable}
        statusRefunding={refunding}
        statusRefunded={refunded}
        handleClickRefund={() => setShowModalRefund(true)}
        handleClickCancelRefund={() => {
          setShowModalRefund(true);
          setShowModalCancelRefund(true);
        }}
        statusRefund={statusRefund}
        statusCancelRefund={statusCancelRefund}
      />

      <Modal
        open={showModalSucceed}
        closeOnDimmerClick={true}
        onClose={() => setShowModalSucceed(false)}
        size={"mini"}
      >
        <Modal.Header
          as="h5"
          style={{
            backgroundColor: "#21BA45",
            color: "white",
            fontSize: "16px",
          }}
        ></Modal.Header>
        <Modal.Content>
          <div style={{ fontWeight: "bold" }}> แจ้งคืนเงินสำเร็จ </div>

          <div
            style={{
              padding: "20px 20px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              className="basic"
              color="green"
              onClick={() => {
                setShowModalSucceed(false);
              }}
              style={{ width: "100px" }}
            >
              ตกลง
            </Button>
          </div>
        </Modal.Content>
      </Modal>

      <Modal
        open={showModalRefund}
        closeOnDimmerClick={true}
        onClose={() => {
          setShowModalRefund(false);
          setShowModalCancelRefund(false);
        }}
        size={"tiny"}
      >
        <Modal.Header
          as="h5"
          style={{
            backgroundColor: "#2185D0",
            color: "white",
            fontSize: "16px",
          }}
        >
          กรุณาระบุเหตุผลการแจ้งคืนเงิน
        </Modal.Header>
        <Modal.Content>
          <div style={{ fontWeight: "bold" }}> สาเหตุ </div>
          <div style={{ paddingTop: "10px" }}>
            <Dropdown
              fluid={true}
              options={
                showModalCancelRefund
                  ? [
                      {
                        key: 0,
                        value: "แจ้งคืนเงินผิดรายการ",
                        text: "แจ้งคืนเงินผิดรายการ",
                      },
                      {
                        key: 1,
                        value: "ไม่มีเหตุอันควรให้คืนเงิน",
                        text: "ไม่มีเหตุอันควรให้คืนเงิน",
                      },
                    ]
                  : [
                      {
                        key: 0,
                        value: "การให้บริการมีปัญหา",
                        text: "การให้บริการมีปัญหา",
                      },
                      {
                        key: 1,
                        value: "บันทึกบริการผิดพลาด",
                        text: "บันทึกบริการผิดพลาด",
                      },
                      {
                        key: 2,
                        value: "ได้รับการยกเว้นค่าบริการ",
                        text: "ได้รับการยกเว้นค่าบริการ",
                      },
                    ]
              }
              selection={true}
              onChange={(event: any, data: any) => setRefundSelect(data?.value)}
              value={refundSelect}
            ></Dropdown>
          </div>
          <div
            style={{
              paddingTop: "10px",
              display:
                props.errorMessage?.ORServiceRefund === "error"
                  ? "block"
                  : "none",
            }}
          >
            <Message color="red">กรุณาระบุเหตุผลการคืนเงิน</Message>
          </div>

          <div
            style={{
              padding: "20px 20px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              className="basic"
              color="green"
              onClick={() => {
                props.runSequence({
                  sequence: "ORServiceRefund",
                  action: showModalCancelRefund
                    ? "deleteInvoiceRefundView"
                    : "getInvoiceRefundView",
                  invoiceId: selectedRow?.id,
                  reason: refundSelect,
                  card: "ORServiceRefund",
                });
              }}
              style={{ width: "100px" }}
            >
              ใช่
            </Button>
            <Button
              className="basic"
              color="red"
              onClick={() => {
                setShowModalRefund(false);
                setShowModalCancelRefund(false);
              }}
              style={{ width: "100px" }}
            >
              ไม่
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

CardServiceRefund.defaultProps = CardServiceRefundInitial;

export default React.memo(CardServiceRefund);
