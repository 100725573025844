import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'

const CardStaffORRequestUX = (props: any) => {
    return(
      <div>
        <div
          style={{ paddingTop: "20px", marginBottom: "-6px" }}>
          
          <Button
            color={props.containerName === "ORRequest" ? "blue":  ""}
            name="ORRequest"
            onClick={props.onSetContainerName}
            style={{marginLeft: "15px"}}>
            OR Request
          </Button>
          <Button
            color={props.containerName === "SelectedOperatingTime" ? "blue":  ""}
            name="SelectedOperatingTime"
            onClick={props.onSetContainerName}
            style={{marginLeft: "15px"}}>
            เลือกวันเวลา ผ่าตัด
          </Button>
          <Button
            color={props.containerName === "OtherAppointmentOrder" ? "blue":  ""}
            name="OtherAppointmentOrder"
            onClick={props.onSetContainerName}
            style={{marginLeft: "15px"}}>
            คำสั่งล่วงหน้า อื่นๆ
          </Button>
        </div>
        <hr>
        </hr>
      </div>
    )
}

export default CardStaffORRequestUX

export const screenPropsDefault = {}

/* Date Time : Tue Oct 18 2022 14:40:46 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingTop: \"20px\", marginBottom: \"-6px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "OR Request"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"ORRequest\" ? \"blue\":  \"\""
        },
        "name": {
          "type": "value",
          "value": "ORRequest"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetContainerName"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกวันเวลา ผ่าตัด"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"SelectedOperatingTime\" ? \"blue\":  \"\""
        },
        "name": {
          "type": "value",
          "value": "SelectedOperatingTime"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetContainerName"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "คำสั่งล่วงหน้า อื่นๆ"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"OtherAppointmentOrder\" ? \"blue\":  \"\""
        },
        "name": {
          "type": "value",
          "value": "OtherAppointmentOrder"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetContainerName"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 9,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardStaffORRequestUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
