import axios from 'axios'
import { to } from '../../../utils'
import BaseService from '../../services/BaseService'
import config from '../../../../config/config'
import { Response, Error } from '../common'
const HOST = `${config.API_HOST}`

export interface ScannedDocumentSerializerForPatch {
  id?: any,
  edit_user?: any,
  created?: any,
  edited?: any,
  document_type?: any,
  document_no?: any,
  document_code?: any,
  document_image?: any,
  scan_user?: any,
  scan_division?: any,
  owner_division?: any,
  page?: any,
  version?: any,
  scan_date?: any,
  document_date?: any,
  expiry_date?: any,
  patient?: any,
  encounter?: any,
  is_secret?: any,
  active?: any,
  remark?: any,
  cancel_note?: any,
}

export const ScannedDocumentDetail:
  {
    patch: (arg0:
      {
        pk: any,
        params?: any,
        data?: ScannedDocumentSerializerForPatch,
        apiToken?: any,
        extra?: any,
      }) => any,
    update: (arg0:
      {
        pk?: any,
        params?: any,
        data?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
  } =
{
  patch: async ({ pk, params, data, apiToken, extra }) => {
    const base = new BaseService()
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${HOST}/apis/core/scanned-document/${pk}/`,
      {
        ...data
      },
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  update: async ({ pk, params, data, apiToken, extra }) => {
    const base = new BaseService()
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${HOST}/apis/core/scanned-document/update-secret/`,
      {
        ...data
      },
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

/*----------------------------------------------------------------------------------------------------*/

