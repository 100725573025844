import WasmController from "react-lib/frameworks/WasmController";
import { formatDate } from "react-lib/utils/dateUtils";
import moment from "moment";
import PatientAppointmentView from "issara-sdk/apis/PatientAppointmentView_apps_QUE";
import PatientAppointmentUpdate from "issara-sdk/apis/PatientAppointmentUpdate_apps_QUE";
import DefaultWaitingListView from "issara-sdk/apis/DefaultWaitingListView_apps_QUE";
import DivisionServiceBlockSummaryView from "issara-sdk/apis/DivisionServiceBlockSummaryView_apps_QUE";
import DivisionProviderView from "issara-sdk/apis/DivisionProviderView_apps_QUE";
import WaitingListItemList from "issara-sdk/apis/WaitingListItemList_apps_QUE";
import config from "config/config";

// DrugOrderQueueList_apps_TPD
export type State = {
  // Common
  masterOptions?: any;
  // Seq
  TelePharmacySequence?: {
    sequenceIndex: string | null;
    selectedPatientSearch?: any;
    selectedPatientType?: any;
    selectedApplicationType?: any;
    selectedAppointmentStatus?: any;
    selectedStartDate?: any;
    selectedEndDate?: any;
    // Add patient
    patientSelectedId?: any;
    patientSearching?: boolean | null;
    patientName?: any;
    patientHn?: any;
    patientType?: any;
    // patientAppointmentDate คือวันที่เลือก แล้วนำไป filter เวลา
    // divisionServiceBlockSelected เวลาที่เลือก แต่ใช่เป็นเลข division แทนเวลาในการส่ง
    patientAppointmentDate?: any;
    divisionServiceBlockSelected?: any;
    patientOldAppointmentDate?: any;
    patientOldAppointmentTime?: any;
    checkedUnAppointment?: boolean | null;
    appointmentId?: any;
    // patientList main telephar
    patientList?: any;
    // stockPatient teblephar
    stockPatient?: any[];
    divisionServiceBlockList?: any;
    patientWittingList?: any;
    wittingList_id?: any;
    wittingList?: any;
    divisionLoading?: boolean;
    telepharLoading?: boolean;
    searchLoading?: boolean;
    provider?: any;
  } | null;
};

export const StateInitial: State = {
  TelePharmacySequence: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  user?: number;
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  let state = controller.getState();
  if (!state.TelePharmacySequence) return;

  // Master data
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["division", {}],
        ["patientType", {}],
        ["applications", {}],
      ],
    },
  });
  const gatDefaultWaitingList = DefaultWaitingListView.retrieve({
    params: {
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  const gatDivisionProvider = DivisionProviderView.retrieve({
    params: {
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  const [
    [waitingRes, waitingE, waitingN],
    [providerRes, providerE, providerN],
  ] = await Promise.all([gatDefaultWaitingList, gatDivisionProvider]);

  console.log("selectedEncounter: null")
  controller.setState({
    TelePharmacySequence: {
      ...state.TelePharmacySequence,
      sequenceIndex: "Action",
      selectedStartDate: formatDate(moment()),
      selectedEndDate: formatDate(moment()),
      wittingList_id: waitingRes.id || null,
      provider: providerRes?.id,
      divisionLoading: true,
    },
    selectedEncounter: null,
  });

  const [DSBRes, DSBE, DSBN] = await DivisionServiceBlockSummaryView.get({
    params: {
      divisions: [controller.data.division],
      provider: providerRes?.id,
    },
    apiToken: controller.apiToken,
  });

  state = controller.getState();
  if (DSBE) {
    controller.setState(
      {
        TelePharmacySequence: {
          ...state.TelePharmacySequence,
          sequenceIndex: "Action",
          divisionServiceBlockList: [],
          divisionLoading: false,
        },
      },
      () => {
        controller.handleEvent({
          message: "RunSequence",
          params: { ...params, action: "gatWaitingList" },
        });
      }
    );
  } else {
    controller.setState(
      {
        TelePharmacySequence: {
          ...state.TelePharmacySequence,
          sequenceIndex: "Action",
          divisionServiceBlockList: DSBRes?.items || [],
          divisionLoading: false,
        },
      },
      () => {
        controller.handleEvent({
          message: "RunSequence",
          params: { ...params, action: "gatWaitingList" },
        });
      }
    );
  }
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();
  if (params.action === "clearDataSearch") {
    ClearDataSearch(controller, params);
  } else if (params.action === "clearDataInfo") {
    ClearDataInfo(controller, params);
  } else if (params.action === "setPostpone") {
    PostponeState(controller, params);
  } else if (params.action === "searchAppointment") {
    SearchAppointment(controller, params);
  } else if (params.action === "createPatientAppointment") {
    CreatePatientAppointment(controller, params);
  } else if (params.action === "gatWaitingList") {
    GatWaitingList(controller, params);
  } else if (params.action === "createWaitingList") {
    CreateWaitingList(controller, params);
  } else if (params.action === "updatePatientAppointment") {
    UpdatePatientAppointment(controller, params);
  }
};

const PostponeState: Handler = async (controller, params) => {
  const state = controller.getState();
  controller.setState({
    TelePharmacySequence: {
      ...state.TelePharmacySequence,
      sequenceIndex: "Action",
      patientName: params.patientName || null,
      patientHn: params.patientHn || null,
      patientType: params.patientType || null,
      patientAppointmentDate: params.patientAppointmentDate || null,
      divisionServiceBlockSelected: params.divisionServiceBlockSelected || null,
      patientOldAppointmentDate: params.patientOldAppointmentDate || null,
      patientOldAppointmentTime: params.patientOldAppointmentTime || null,
      checkedUnAppointment: false,
      patientSelectedId: params.patientSelectedId || null,
      appointmentId: params.appointmentId || null,
    },
  });
};

const SearchAppointment: Handler = async (controller, params) => {
  const state = controller.getState();
  controller.setState({
    TelePharmacySequence: {
      ...state.TelePharmacySequence,
      sequenceIndex: "Action",
      searchLoading: true,
    },
  });

  const [r, e, n] = await PatientAppointmentView.list({
    params: {
      waiting_list_id: state.TelePharmacySequence?.wittingList_id,
      provider_id: state.TelePharmacySequence?.provider,
      ...(params.hn && { hn: params.hn }),
      ...(params.selectedStartDate && {
        start_datetime: params.selectedStartDate,
      }),
      ...(params.selectedPatientSearch && {
        search: params.selectedPatientSearch,
      }),
      ...(params.selectedEndDate && { end_datetime: params.selectedEndDate }),
      ...(params.selectedPatientType && { type: params.selectedPatientType }),
      ...(params.selectedApplicationType && { application: 6 }),
      ...(params.selectedAppointmentStatus && {
        status: params.selectedAppointmentStatus,
      }),
    },
    apiToken: controller.apiToken,
  });
  console.log("saika res ", r?.items);
  controller.setState({
    TelePharmacySequence: {
      ...state.TelePharmacySequence,
      sequenceIndex: "Action",
      patientList: r?.items || [],
      searchLoading: false,
    },
  });
};

const ClearDataSearch: Handler = async (controller, params) => {
  const state = controller.getState();
  const now = formatDate(moment());
  controller.setState({
    TelePharmacySequence: {
      ...state.TelePharmacySequence,
      sequenceIndex: "Action",
      selectedPatientSearch: null,
      selectedPatientType: null,
      selectedApplicationType: null,
      selectedAppointmentStatus: null,
      selectedStartDate: now,
      selectedEndDate: now,
    },
  });
};

const ClearDataInfo: Handler = async (controller, params) => {
  const state = controller.getState();
  controller.setState({
    TelePharmacySequence: {
      ...state.TelePharmacySequence,
      sequenceIndex: "Action",
      patientName: "",
      patientHn: "",
      patientType: "",
      patientAppointmentDate: "",
      divisionServiceBlockSelected: "",
      patientOldAppointmentDate: "",
      patientOldAppointmentTime: "",
      checkedUnAppointment: false,
      patientSelectedId: "",
      appointmentId: 0,
    },
  });
};

const GatWaitingList: Handler = async (controller, params) => {
  let state = controller.getState();
  const waitingListItem = await WaitingListItemList.list({
    params: {
      division_id: controller.data.division,
      waiting_list: params.waiting_list_id,
    },
    apiToken: controller.apiToken,
  });
  state = controller.getState();
  controller.setState({
    TelePharmacySequence: {
      ...state.TelePharmacySequence,
      sequenceIndex: "Action",
      wittingList: waitingListItem?.[0].items,
    },
  });
};

const CreateWaitingList: Handler = async (controller, params) => {
  const state = controller.getState();
  controller.setState({
    TelePharmacySequence: {
      ...state.TelePharmacySequence,
      sequenceIndex: "Action",
      telepharLoading: true,
    },
  });

  const seq = Math.max(
    ...[0].concat(
      (state.TelePharmacySequence?.wittingList || []).map(
        (item: any) => item?.seq || 0
      )
    )
  );

  const Item = await WaitingListItemList.create({
    data: {
      waiting_list: state.TelePharmacySequence?.wittingList_id,
      patient: state.TelePharmacySequence?.patientSelectedId,
      provider: state.TelePharmacySequence?.provider,
      seq: seq + 1,
    },
    apiToken: controller.apiToken,
  });

  Action(controller, {
    action: "createPatientAppointment",
    waiting_list_item_id: Item[0]?.id,
    stockPatient: params.stockPatient,
  });
};

const CreatePatientAppointment: Handler = async (controller, params) => {
  const state = controller.getState();

  let estimate = moment().add(1, "days").toISOString()
  if (state.TelePharmacySequence?.checkedUnAppointment) {
    estimate = state.TelePharmacySequence?.divisionServiceBlockList.find((item: any) =>
      item.id === state.TelePharmacySequence?.divisionServiceBlockSelected)?.start_datetime_iso || moment().add(1, "days").toISOString()
  }


  console.log("saika estimate ", estimate)

  if (Number.isInteger(state.TelePharmacySequence?.patientSelectedId)) {
    const [r, e, n] = await PatientAppointmentView.create({
      data: {
        patient: state.TelePharmacySequence?.patientSelectedId,
        division: controller.data.division,
        provider: state.TelePharmacySequence?.provider,
        division_service_block: state.TelePharmacySequence
          ?.checkedUnAppointment
          ? ""
          : state.TelePharmacySequence?.divisionServiceBlockSelected,
        waiting_list_item_id: params.waiting_list_item_id,
        status: 1,
        application: config.APP_NAME,
        type: state.TelePharmacySequence?.patientType,
        send_message: false,
        estimated_at_iso: estimate,
        repetition_note: "appointment from telephar"
      },
      apiToken: controller.apiToken,
    });

    let cloneStockPatient = params.stockPatient;

    if (e) {
      cloneStockPatient = (params.stockPatient || []).filter(
        (item: any) =>
          item.patientSelectedId !==
          state.TelePharmacySequence?.patientSelectedId
      );
    } else {
      cloneStockPatient = (params.stockPatient || []).map((item: any) => {
        if (
          item.patientSelectedId ===
          state.TelePharmacySequence?.patientSelectedId
        ) {
          return { ...item, appointmentId: r.id };
        } else {
          return { ...item };
        }
      });
    }

    controller.setState({
      TelePharmacySequence: {
        ...state.TelePharmacySequence,
        sequenceIndex: "Action",
        stockPatient: cloneStockPatient,
        telepharLoading: false,
      },
    });

    Action(controller, {
      action: "clearDataInfo",
    });
  }
};

const UpdatePatientAppointment: Handler = async (controller, params) => {
  let state = controller.getState();
  // REQUEST = 1, 'นัดหมายยังไม่ได้ถูกระบุ'
  // CONFIRM = 2, 'นัดหมายถูกระบุและยืนยัน'
  // REVISE = 3, 'นัดหมายต้องการการคำนวณใหม่'
  // RECONFIRM = 4, 'นัดหมายต้องการยืนยันจากผู้ป่วยอีกครั้ง'
  // CANCEL = 5, 'นัดหมายถูกยกเลิกจากผู้ป่วย'

  controller.setState({
    TelePharmacySequence: {
      ...state.TelePharmacySequence,
      sequenceIndex: "Action",
      telepharLoading: true,
    },
  });

  if (params.actionType === "update") {
    const promiseArr = (state.TelePharmacySequence?.stockPatient || []).map(
      (item: any) => {
        return PatientAppointmentUpdate.patch({
          pk: item.appointmentId,
          data: {
            division_service_block: item.divisionServiceBlockSelected || null,
            status: item.checkedUnAppointment ? 1 : params.status,
            send_message: true,
            repetition_note: "appointment from telephar"
          },
          apiToken: controller.apiToken,
        });
      }
    );

    await Promise.all(promiseArr);

    await controller.setState({
      TelePharmacySequence: {
        ...state.TelePharmacySequence,
        sequenceIndex: "Action",
        stockPatient: [],
        telepharLoading: false,
      },
    });
  } else if (params.actionType === "postpone") {
    const [r, e, n] = await PatientAppointmentUpdate.patch({
      pk: state.TelePharmacySequence?.appointmentId,
      data: {
        division_service_block:
          state.TelePharmacySequence?.divisionServiceBlockSelected || null,
        status: params.status,
        send_message: true,
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      TelePharmacySequence: {
        ...state.TelePharmacySequence,
        sequenceIndex: "Action",
        telepharLoading: false,
      },
    });
  } else if (params.actionType === "delete") {
    const [r, e, n] = await PatientAppointmentUpdate.patch({
      pk: params.id,
      data: {
        status: 5,
        send_message: true
      },
      apiToken: controller.apiToken,
    });

    if (r) {
      const stockPatient = (
        state.TelePharmacySequence?.stockPatient || []
      ).filter((item: any) => item.appointmentId !== params.id);

      controller.setState({
        TelePharmacySequence: {
          ...state.TelePharmacySequence,
          sequenceIndex: "Action",
          stockPatient: stockPatient,
          telepharLoading: false,
        },
      });
    }
  }

  Action(controller, {
    action: "clearDataInfo",
  });
};
