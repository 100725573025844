import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Input } from "semantic-ui-react";

import { RowInfo } from "react-table-6";
import moment from "moment";

// UX
import CardImagingOrderUX from "./CardImagingOrderUX";
import ModXrayDetail from "./ModXrayDetail";
import ModCancelImagingOrder, {
  ApproveDataType,
} from "./ModCancelImagingOrder";

// Common
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// Interface
import {
  State,
  RunSequence,
  MasterOptionsType,
  XRAY_SEARCH_ID,
  ImagingItemSerializer,
  DIAGNOSIS_SEARCH_ID,
  ImagingOrderSerializer,
  ModXrayDetailType,
} from "./sequence/ImagingOrder";
import {
  ORDER_ITEM_STATUS_LABEL,
  ORDER_STATUS,
  checkReadyToView,
} from "./sequence/ImagingWorkList";

import { URGENCY_STATUS } from "./ModXrayDetail";
import { ORDER_STATUS_COLORS } from "./sequence/ImagingWorkList";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import {
  getLocationsSet,
  tidyLocation2,
} from "react-lib/apps/common/CUDENT/DentalRecordUtil";

// Types
type CardImagingOrderProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: RunSequence;
  ImagingOrderSequence: State["ImagingOrderSequence"];
  // data
  orderId: number | null;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  selectedEncounter?: Record<string, any>;
  userTokenize?: Record<string, any>;
  errorMessage?: Record<string, any>;
  modXrayDetail: State["modXrayDetail"];
  // options
  masterOptions?: MasterOptionsType;

  // Dental disgram
  clinicalFindingId?: number | null;
  clinicalFindingIndex?: number | null;
  clinicalFindings: Record<string, any>[];
  filterClinicalFindingIds: number[];
  organ?: { name: string; items: any[] };
  clinicaltags?: { name: string; items: any[] };
  // ----- #
};

// Const
const styles = {
  label_status: {
    fontSize: "0.7rem",
    opacity: 0,
    position: "absolute",
    width: "80px",
    left: "calc(50% - 35px)",
    padding: "0.4rem",
    margin: 0,
    color: "white",
  } as CSSProperties,
};

const BUTTON_ACTIONS = {
  del: "CANCEL_ORDER",
  confirm: "CONFIRM_ORDER",
};

const IMAGES = {
  comment_green: "/static/images/x-ray/comment-outline-green.png",
  comment_grey: "/static/images/x-ray/comment-outline-grey.png",
};

const GridCenter = {
  display: "grid",
  placeContent: "center",
  textAlign: "center",
} as CSSProperties;

const CARD_IMAGING_ORDER = "CardImagingOrder";

const CardImagingOrder = (props: CardImagingOrderProps) => {
  const [modConfirmCancel, setModConfirmCancel] =
    useState<ImagingOrderSerializer | null>(null);

  // Effect
  useEffect(() => {
    props.runSequence({
      sequence: "ImagingOrder",
      restart: true,
    });
  }, []);

  // callback
  const handleSelectedItem = useCallback(
    (data: any) => async (value: any) => {
      if (data.id === XRAY_SEARCH_ID) {
        handleAddImagingItem(value);
      } else {
        props.setProp(`ImagingOrderSequence.${data.name}`, value || null);
      }
    },
    [props.searchedItemListWithKey]
  );

  const handleOpenModXray = useCallback(
    (data: ImagingItemSerializer, index: number) => () => {
      props.runSequence({
        sequence: "ImagingOrder",
        action: "MOD_XRAY",
        data: { ...data, index, status: ORDER_ITEM_STATUS_LABEL[data.status] },
        type: "OPEN",
      });
    },
    []
  );

  const handleEditItem = useCallback(
    (index: number) => (e: any, data: any) => {
      props.runSequence({
        sequence: "ImagingOrder",
        action: "EDIT_ITEM",
        data: { [data.name]: data.value },
        index,
      });
    },
    [props.ImagingOrderSequence?.imagingItemList]
  );

  const handleDeleteItem = useCallback(
    (index: number) => () => {
      const list = [...(props.ImagingOrderSequence?.imagingItemList || [])];

      list[index].active = false;

      props.setProp(`ImagingOrderSequence.imagingItemList`, [...list]);
    },
    [props.ImagingOrderSequence?.imagingItemList]
  );

  const handleEdit = useCallback(async (data: any) => {
    props.runSequence({
      sequence: "ImagingOrder",
      action: "EDIT_REQUEST",
      data,
    });
  }, []);

  const getDetailImagingRequest = useCallback(
    (items: ImagingOrderSerializer["order_items"]) => {
      return items.reduce((result: any, item) => {
        const groupName = item.group_name;

        if (!!result[groupName]) {
          result[groupName].items.push(item);
        } else {
          result[groupName] = {
            title: groupName,
            id: groupName.replaceAll(" ", "-"),
            items: [item],
          };
        }

        return result;
      }, {}) as Record<
        string,
        {
          id: string;
          title: string;
          items: ImagingOrderSerializer["order_items"];
        }
      >;
    },
    []
  );

  const handleLabelPosition = useCallback(
    (data: { id: string; pIndex: number; index: number }) => (ref: any) => {
      const elm = document.querySelector(
        `.imaging-request-detail-${data.id.replaceAll(" ", "-")}-${
          data.pIndex
        }-${data.index}`
      ) as HTMLLIElement;

      if (!!elm && ref) {
        ref.style.opacity = "1";
        ref.style.top = `${
          elm.offsetTop - Number(ref.parentElement?.offsetTop)
        }px`;
      }
    },
    []
  );

  const filterImagingItemList = useMemo(() => {
    return (props.ImagingOrderSequence?.imagingItemList || []).filter(
      (item) => !!item.active
    );
  }, [props.ImagingOrderSequence?.imagingItemList]);

  const summaryPrice = useMemo(() => {
    return filterImagingItemList.reduce<{
      total: number | string;
      claimable: number | string;
      non_claimable: number | string;
    }>(
      (result, item) => ({
        total: (
          Number(result.total) +
          Number(item.price_unit) * Number(item.quantity)
        ).toString(),
        claimable: (
          Number(result.claimable) + Number(item.price_claimable)
        ).toString(),
        non_claimable: (
          Number(result.non_claimable) + Number(item.price_non_claimable)
        ).toString(),
      }),
      { total: 0, claimable: 0, non_claimable: 0 }
    );
  }, [filterImagingItemList]);

  const readOnly = useMemo(() => {
    return ["In Progress", "Complete"].includes(
      props.ImagingOrderSequence?.imagingItemDetail?.order_status_label || ""
    );
  }, [props.ImagingOrderSequence?.imagingItemDetail]);

  const allowConfirmOrder = useMemo(() => {
    const allowReadyToView = filterImagingItemList.some((item) =>
      checkReadyToView(ORDER_ITEM_STATUS_LABEL[item.status])
    );

    return (
      !!props.ImagingOrderSequence?.imagingItemDetail?.suspected_diagnosis &&
      !!filterImagingItemList.length &&
      allowReadyToView
    );
  }, [props.ImagingOrderSequence?.imagingItemDetail, filterImagingItemList]);

  const allowReadyToView = useMemo(() => {
    const status = props.modXrayDetail?.status;

    return !!status && checkReadyToView(ORDER_STATUS[status]);
  }, [props.modXrayDetail?.status]);

  // Memo
  const imagingRequestItems = useMemo(() => {
    return (props.ImagingOrderSequence?.requestOrderList || []).map(
      (item, index) => {
        const detail = getDetailImagingRequest(item.order_items);

        return {
          ...item,
          request_date: (
            <div style={GridCenter}>
              {formatDate(moment(item.created))} [
              {moment(item.created).format("HH:mm")}]
            </div>
          ),
          order_status: (
            <div style={{ ...GridCenter, textTransform: "capitalize" }}>
              {/* {DOCTOR_ORDER_STATUS[item.order_status].toLowerCase()} */}
              {item.order_status_label}
            </div>
          ),
          doctor_name: <div style={GridCenter}>นทพ.ธัชชัย ชัยพร</div>,
          summary: (
            <>
              {Object.entries(detail).map(([, value]) => (
                <>
                  <div>
                    <strong>{value.title}</strong>
                  </div>
                  <ul style={{ margin: 0, paddingLeft: "2rem" }}>
                    {value.items.map((acc, idx) => {
                      const isStat = acc.urgency === URGENCY_STATUS.STAT;

                      return (
                        <li
                          className={`imaging-request-detail-${value.id}-${index}-${idx}`}
                          style={{
                            marginBottom: "0.8rem",
                            color: isStat ? "#DA0000" : "",
                          }}
                        >
                          {acc.product_name}
                        </li>
                      );
                    })}
                  </ul>
                  <br />
                </>
              ))}
            </>
          ),
          status: (
            <div style={{ ...GridCenter, position: "relative" }}>
              {Object.entries(detail).map(([, value]) => (
                <>
                  {value.items.map((acc, idx) => {
                    const status = ORDER_ITEM_STATUS_LABEL[acc.status];
                    return (
                      <>
                        <div
                          ref={handleLabelPosition({
                            id: value.id,
                            pIndex: index,
                            index: idx,
                          })}
                          className={`ui label`}
                          style={{
                            ...styles.label_status,
                            backgroundColor: ORDER_STATUS_COLORS[status],
                          }}
                        >
                          {status}
                        </div>
                      </>
                    );
                  })}
                </>
              ))}
            </div>
          ),

          delete: (
            <div style={GridCenter}>
              <Button
                icon="minus"
                size="mini"
                color="red"
                disabled={item.order_status_label !== "Pending"}
                style={{ fontSize: "0.75rem" }}
                onClick={() => setModConfirmCancel(item)}
              />
            </div>
          ),
          edit: (
            <div style={GridCenter}>
              <Button
                icon="pencil"
                size="mini"
                color="blue"
                style={{ fontSize: "0.75rem" }}
                disabled={item.order_status_label !== "Pending"}
                onClick={() => handleEdit(item)}
              />
            </div>
          ),
        };
      }
    );
  }, [
    props.ImagingOrderSequence?.requestOrderList,
    props.ImagingOrderSequence?.imagingItemDetail,
  ]);

  const imagingItems = useMemo(() => {
    return filterImagingItemList.map((item, index) => {
      const isStat = item.urgency === URGENCY_STATUS.STAT;
      const unitName =
        typeof item.unit === "number"
          ? props.masterOptions?.unit?.find(
              (option) => option.value === item.unit
            )?.text || ""
          : item.unit;
      const activeNote = !!item.note;
      const readOnly =
        !!item.status && ORDER_ITEM_STATUS_LABEL[item.status] !== "REQUESTED";

      const locationsSet = getLocationsSet(
        item.locations_name || "",
        props.organ?.items || []
      );

      const locationsName = tidyLocation2(
        locationsSet,
        props.organ?.items || []
      ).join(",");

      return {
        ...item,
        no: index + 1,
        code: (
          <div style={{ color: isStat ? "#DA0000" : "" }}>
            {/\[(\w+)\]/g.exec(item.name_code || "")?.[1] || ""}
          </div>
        ),
        name: (
          <div style={{ color: isStat ? "#DA0000" : "" }}> {item.name} </div>
        ),
        // Y14 : ภาพ
        unit_name: (
          <div style={GridCenter}>
            {unitName.replace(/( \(\w+\))|(\w+ : )/g, "")}
          </div>
        ),
        qty: (
          <Input
            ref={(ref: any) =>
              ref &&
              ((ref.inputRef.current.style.background = "#DCF4FA"),
              (ref.inputRef.current.style.textAlign = "center"))
            }
            type="number"
            name="quantity"
            value={item.quantity}
            fluid={true}
            disabled={readOnly}
            // style
            style={{ width: "100%", opacity: 1 }}
            onChange={handleEditItem(index)}
          />
        ),
        // price_total: Number(item.quantity) * item.price_unit,
        summary: (
          <div style={{ display: "grid", gridTemplateColumns: "28px 1fr" }}>
            <img
              src={activeNote ? IMAGES.comment_green : IMAGES.comment_grey}
              alt={activeNote ? "green speech bubble" : "grey speech bubble"}
              // style
              style={{
                width: "25px",
                cursor: "pointer",
              }}
              onClick={handleOpenModXray(item, index)}
            />
            <label
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
              }}
            >
              {locationsName} {item.note}
            </label>
          </div>
        ),
        delete: (
          <div style={GridCenter}>
            <Button
              icon="minus"
              size="mini"
              color="red"
              disabled={readOnly}
              style={{ fontSize: "0.75rem" }}
              onClick={handleDeleteItem(index)}
            />
          </div>
        ),
      };
    });
  }, [filterImagingItemList, props.masterOptions?.unit]);

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    const value = typeof data.checked === "boolean" ? data.checked : data.value;
    const name = data.name;

    props.setProp(`ImagingOrderSequence.imagingItemDetail.${name}`, value);
  };

  const handleConfirmCancel = (data: ApproveDataType) => {
    if (modConfirmCancel) {
      props.runSequence({
        sequence: "ImagingOrder",
        action: "CANCEL_ORDER",
        card: CARD_IMAGING_ORDER,
        data: modConfirmCancel,
        reason: data.reason,
        onSuccess: handleCloseModCancel,
      });
    }
  };

  const handleCloseModCancel = () => {
    setModConfirmCancel(null);
  };

  const handleGetTrPropsItem = (state: any, rowInfo?: RowInfo) => {
    const row =
      props.ImagingOrderSequence?.imagingItemList?.[rowInfo?.index || 0];

    return {
      style: {
        opacity:
          !!row?.status && ORDER_ITEM_STATUS_LABEL[row.status] !== "REQUESTED"
            ? 0.5
            : 1,
      },
    };
  };

  const handleSelectOrder = (rowInfo?: RowInfo) => {
    const row =
      props.ImagingOrderSequence?.requestOrderList?.[rowInfo?.index || 0];

    if (!!row) {
      props.runSequence({
        sequence: "ImagingOrder",
        action: "EDIT_REQUEST",
        data: row,
      });
    }
  };

  const handleGetTrPropsRequest = (state: any, rowInfo?: RowInfo) => {
    return {
      className:
        !!props.ImagingOrderSequence?.imagingItemDetail?.id &&
        props.ImagingOrderSequence?.imagingItemDetail?.id ===
          rowInfo?.original.id
          ? "blueSelectedRow"
          : "",
      onClick: () => {
        handleSelectOrder(rowInfo);
      },
    };
  };

  const handleCloseModXray = () => {
    props.runSequence({
      sequence: "ImagingOrder",
      action: "MOD_XRAY",
      type: "CLOSE",
    });
  };

  const handleClearImagingItem = async () => {
    await props.setProp(`searchedItemListWithKey.${XRAY_SEARCH_ID}`, []);

    props.setProp("ImagingOrderSequence.imagingItemId", null);
  };

  const handleAddImagingItem = (id?: number) => {
    if (!!id) {
      props.runSequence({
        sequence: "ImagingOrder",
        action: "ADD_ITEM",
        itemId: id,
      });
    }
  };

  const handleSaveXray = async (data: Partial<ModXrayDetailType>) => {
    const index = props.modXrayDetail?.index ?? -1;

    if (index >= 0) {
      props.runSequence({
        sequence: "ImagingOrder",
        action: "EDIT_ITEM",
        data: {
          eligibility_type: data.eligibility_type,
          urgency: data.urgency,
          note: data.note,
          // locations: data.locations,
          locations_name: data.locations_name,
          ready_to_view: data.ready_to_view,
        },
        index,
        onSuccess: handleCloseModXray,
      });
    }
  };

  const handleAddOrder = async () => {
    await props.setProp(`searchedItemListWithKey.${DIAGNOSIS_SEARCH_ID}`, []);

    await props.setProp(`ImagingOrderSequence.imagingItemList`, []);

    props.setProp(`ImagingOrderSequence.imagingItemDetail`, {
      order_div: props.selectedEncounter?.division,
      is_emergency: false,
      extra: {
        out_time: false,
        secret: false,
        repeat_medication: false,
        lawsuit: false,
      },
    });
  };

  const handleEventSearch = (data: any) => {
    props.runSequence({
      sequence: "ImagingOrder",
      action: "SEARCH_DIAGNOSIS",
      data,
    });
  };

  const handleConfirmOrder = () => {
    props.runSequence({
      sequence: "ImagingOrder",
      action: "CONFIRM_ORDER",
      card: CARD_IMAGING_ORDER,
    });
  };

  const handleCloseEdit = async () => {
    await props.setProp(`ImagingOrderSequence.imagingItemList`, []);

    props.setProp(`ImagingOrderSequence.imagingItemDetail`, null);
  };

  console.log("CardImagingOrder", props);

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_IMAGING_ORDER}`, null);
        }}
        error={props.errorMessage?.[CARD_IMAGING_ORDER]}
        success={null}
      />

      <CardImagingOrderUX
        // data
        imagingRequestList={imagingRequestItems}
        imagingItemList={imagingItems}
        summaryPrice={summaryPrice}
        imagingItemDetail={props.ImagingOrderSequence?.imagingItemDetail}
        // options
        divisionForOrderDivOptions={props.masterOptions?.divisionForOrderDiv}
        // config
        readOnly={readOnly}
        // callback
        onGetTrPropsItem={handleGetTrPropsItem}
        onGetTrPropsRequest={handleGetTrPropsRequest}
        onClear={handleClearImagingItem}
        // onAdd={handleAddImagingItem}
        onChangeValue={handleChangeValue}
        onAddOrder={handleAddOrder}
        onClose={handleCloseEdit}
        // Element
        SearchBoxPrincipalDiag={
          <SearchBoxDropdown
            onEvent={handleEventSearch}
            // config
            type="Diagnosis"
            id="IO"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            disabled={readOnly}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={
              props.ImagingOrderSequence?.imagingItemDetail
                ?.suspected_diagnosis || null
            }
            setSelectedItem={handleSelectedItem({
              id: DIAGNOSIS_SEARCH_ID,
              name: "imagingItemDetail.suspected_diagnosis",
            })}
          />
        }
        SearchBoxImagingItem={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="ImagingXray"
            id="IO"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            disabled={readOnly}
            // data
            encounterId={props.selectedEncounter?.id}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={null}
            setSelectedItem={handleSelectedItem({
              id: XRAY_SEARCH_ID,
              name: "imagingItemId",
            })}
          />
        }
        ButtonConfirmOrder={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirmOrder}
            // data
            paramKey={`${CARD_IMAGING_ORDER}_${BUTTON_ACTIONS.confirm}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_IMAGING_ORDER}_${BUTTON_ACTIONS.confirm}`
              ]
            }
            // config
            disabled={!allowConfirmOrder}
            color={"green"}
            name={BUTTON_ACTIONS.confirm}
            size="medium"
            title="Confirm Order"
            fluid={true}
            style={{ minWidth: "max-content" }}
          />
        }
      />

      <ModCancelImagingOrder
        onEvent={props.onEvent}
        setProp={props.setProp}
        // data
        open={!!modConfirmCancel}
        action="CANCEL"
        orderStatus="cancelled"
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        userTokenize={props.userTokenize}
        // options
        cancelImagingOrderOptions={props.masterOptions?.cancelImagingOrder}
        // config
        card={CARD_IMAGING_ORDER}
        // callback
        onApprove={handleConfirmCancel}
        onClose={handleCloseModCancel}
      />

      <ModXrayDetail
        onEvent={props.onEvent}
        setProp={props.setProp}
        // data
        open={!!props.modXrayDetail}
        data={props.modXrayDetail}
        clinicalFindingId={props.clinicalFindingId}
        clinicalFindingIndex={props.clinicalFindingIndex}
        masterData={{ organ: props.organ, clinicaltags: props.clinicaltags }}
        clinicalFindings={props.clinicalFindings}
        filterClinicalFindingIds={props.filterClinicalFindingIds}
        // options
        eligibilityOptions={props.masterOptions?.eligibilityType}
        // config
        readOnly={
          !!props.modXrayDetail?.status &&
          ORDER_STATUS[props.modXrayDetail?.status] !== "REQUESTED"
        }
        allowReadyToView={allowReadyToView}
        // callback
        onClose={handleCloseModXray}
        onSave={handleSaveXray}
      />
    </div>
  );
};

export default React.memo(CardImagingOrder);
