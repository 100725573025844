import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  FormGroup,
  FormField
} from 'semantic-ui-react'

const CardFinancialAmountSetUX = (props: any) => {
    return(
      <div
        style={{width: "100%", height: "100%", padding: "15px"}}>
        <div
          style={{fontSize: "18px", fontWeight: "bold"}}>
          กำหนดวงเงินรายครั้ง
        </div>
        <hr>
        </hr>
        <Table
          data={props.financialAmountData}
          getTrProps={props.onSelectedReim}
          headers="สิทธิ, ผู้จ่ายเงิน, ประเภทบริการ, วันที่เริ่ม, วันที่หมดอายุ, วงเงิน,"
          keys="coverage_name_text,payer_name_text,service_type_text,start_date_text,stop_date_text,max_reimb_text,_delete"
          minRows="10"
          showPagination={false}
          style={{height: "450px"}}
          widths=",,150,200,200,150,100">
        </Table>
        <div
          className="ui small form"
          style={{padding: "10px 0px"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{fontWeight: "bold"}}>
                การรับรองสิทธิ
              </div>
            </FormField>
            <FormField
              inline={true}
              width={8}>
              <div
                style={{width: "100%"}}>
                {props.patientCoverageSearch}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{fontWeight: "bold"}}>
                ระบุวงเงิน
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.amount}
              </div>
            </FormField>
            <FormField
              inline={true}
              width="2">
              <div
                style={{width: "100%"}}>
                {props.buttonSave}
              </div>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}

export default CardFinancialAmountSetUX

export const screenPropsDefault = {}

/* Date Time : Wed Dec 28 2022 22:35:58 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", padding: \"15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "กำหนดวงเงินรายครั้ง"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"18px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 2,
      "void": true
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 3,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.financialAmountData"
        },
        "defaultPageSize": {
          "type": "value",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onSelectedReim"
        },
        "headers": {
          "type": "value",
          "value": "สิทธิ, ผู้จ่ายเงิน, ประเภทบริการ, วันที่เริ่ม, วันที่หมดอายุ, วงเงิน,"
        },
        "keys": {
          "type": "value",
          "value": "coverage_name_text,payer_name_text,service_type_text,start_date_text,stop_date_text,max_reimb_text,_delete"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "pageSize": {
          "type": "code",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"450px\"}"
        },
        "widths": {
          "type": "value",
          "value": ",,150,200,200,150,100"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui small form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 0px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "การรับรองสิทธิ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientCoverageSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุวงเงิน"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.amount"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 18,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 75,
  "isMounted": false,
  "memo": false,
  "name": "CardFinancialAmountSetUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 70
}

*********************************************************************************** */
