import React, { useState, useEffect } from "react";
import { Menu } from "semantic-ui-react";

// UX
import CardPackagePurchaseHistory from "./CardPackagePurchaseHistory";
import CardAppointmentUsageHistory from "./CardAppointmentUsageHistory";

// Interface
import {
  MasterDataType,
  MasterOptionsType,
  RunSequence,
  State,
} from "./sequence/PackagePurchase";

// Types
type CardPurchaseHistoryProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: RunSequence;
  PackagePurchaseSequence?: State["PackagePurchaseSequence"];
  // data
  masterData?: MasterDataType;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  // options
  masterOptions?: MasterOptionsType;
  // config
  selectable?: boolean; // สามารถเลือก checkbox รายการที่สามารถนัดหมายได้
  selectedDivision?: Record<string, any> | null; // default performed_division,
  disabledOrderIds?: number[];
  // callback
  onSelectPackage?: (data: Record<string, any>) => any;
};

type ValueOf<T> = T[keyof T];

type TabPanKey = ValueOf<typeof TAB_PANS>;

// Const
const TAB_PANS = {
  PACKAGE_LIST: "Package List",
  APPOINTMENT: "Appointment & Usage History",
} as const;

const CARD_PURCHASE_HISTORY = "CardPurchaseHistory";

const CardPurchaseHistory = (props: CardPurchaseHistoryProps) => {
  const [activeItem, setActiveItem] = useState<TabPanKey>(
    TAB_PANS.PACKAGE_LIST
  );

  // Use Effect
  useEffect(() => {
    // use action PurchaseHistory
    const getPurchaseHistory = async () => {
      // เมื่อมี division ให้ default ตาม division และ get ตาม division
      props.runSequence({
        sequence: "PackagePurchase",
        restart: true,
        nextIndex: "PurchaseHistory",
        card: CARD_PURCHASE_HISTORY,
        divisionId: props.selectedDivision?.id,
      });
    };

    getPurchaseHistory();
  }, []);

  // Handler
  const handleChangeTab = (_e: any, data: any) => {
    setActiveItem(data.name);
  };

  const handleOpenHistory = () => {
    setActiveItem(TAB_PANS.APPOINTMENT);
  };

  return (
    <div style={{ padding: "10px", paddingBottom: "1rem" }}>
      <Menu tabular>
        {Object.values(TAB_PANS).map((menu, index) => (
          <Menu.Item
            key={"menu-" + index}
            active={activeItem === menu}
            name={menu}
            // disabled={props.selectable && menu === TAB_PANS.APPOINTMENT}
            style={
              activeItem === menu
                ? {
                    color: "#0370B7",
                  }
                : {}
            }
            onClick={handleChangeTab}
          >
            {menu}
          </Menu.Item>
        ))}
      </Menu>
      {activeItem === TAB_PANS.PACKAGE_LIST ? (
        <CardPackagePurchaseHistory
          setProp={props.setProp}
          onEvent={props.onEvent}
          // seq
          runSequence={props.runSequence}
          PackagePurchaseSequence={props.PackagePurchaseSequence}
          // data
          masterData={props.masterData}
          // CommonInterface
          searchedItemListWithKey={props.searchedItemListWithKey}
          buttonLoadCheck={props.buttonLoadCheck}
          // options
          masterOptions={props.masterOptions}
          // config
          selectable={props.selectable}
          selectedDivision={props.selectedDivision}
          disabledOrderIds={props.disabledOrderIds}
          // callback
          onOpenHistory={handleOpenHistory}
          onSelectPackage={props.onSelectPackage}
        />
      ) : activeItem === TAB_PANS.APPOINTMENT ? (
        <CardAppointmentUsageHistory
          setProp={props.setProp}
          onEvent={props.onEvent}
          // seq
          runSequence={props.runSequence}
          PackagePurchaseSequence={props.PackagePurchaseSequence}
          // options
          masterOptions={props.masterOptions}
          // CommonInterface
          searchedItemListWithKey={props.searchedItemListWithKey}
          buttonLoadCheck={props.buttonLoadCheck}
        />
      ) : null}
    </div>
  );
};

export default React.memo(CardPurchaseHistory);
