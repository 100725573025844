import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useImperativeHandle,
  useCallback,
} from "react";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Label,
  Select,
  Icon,
  Modal,
  TextArea,
  Popup,
  Header,
} from "semantic-ui-react";

import moment from "moment";
import htmlParse from "html-react-parser";

// UX
import CardPatientListUX from "./CardPatientListUX";
import CardPharmacyTask from "react-lib/apps/HISV3/TPD/CardPharmacyTask";
import CardLabOrderQueue from "react-lib/apps/HISV3/LAB/CardLabOrderQueue";
import CardADM from "react-lib/apps/HISV3/ADM/CardADM";
import CardReceiveOrder from "react-lib/apps/HISV3/PTM/CardReceiveOrder";
import CardWardQueue from "react-lib/apps/HISV3/ADM/CardWardQueue";
import CardORQueue from "react-lib/apps/HISV3/ORM/CardORQueue";
import MedAdminQueue from "react-lib/apps/HISV3/TPD/MedAdminQueue";
import MSDMenuUXMED from "react-lib/apps/HISV3/MSD/dispensing/MSDMenuUX";
import CardSupplyOrderQueueList from "react-lib/apps/HISV3/MSD/dispensing/CardSupplyOrderQueueList";
import CardSupplyDeliveryQueue from "react-lib/apps/HISV3/MSD/dispensing/CardSupplyDeliveryQueue";
import CardSupplyDeliveryManager from "react-lib/apps/HISV3/MSD/dispensing/CardSupplyDeliveryManager";
import CardRefillDivisionPrint from "react-lib/apps/HISV3/MSD/dispensing/CardRefillDivisionPrint";
import CardBilling from "react-lib/apps/HISV3/BIL/CardBilling";
import CardSupplyOrderHistory from "react-lib/apps/HISV3/MSD/dispensing/CardSupplyOrderHistory";
import CardSupplyDeliveryHistory from "react-lib/apps/HISV3/MSD/dispensing/CardSupplyDeliveryHistory";
import CardMedRequestReturn from "react-lib/apps/HISV3/MSD/dispensing/CardMedRequestReturn";
import CardReportQueue from "react-lib/apps/HISV3/ADM/CardReportQueue";
import CardRejectOrder from "./CardRejectOrder";
import CardImagingWorkList from "react-lib/apps/HISV3/IME/CardImagingWorkList";
import CardOpenEncounter from "react-lib/apps/HISV3/REG/CardOpenEncounter";

import CardPHVWrapper from "HIS/PHV/CardPHVWrapper";
import CardDoctorPendingTask from "react-lib/apps/HISV3/DPO/CardDoctorPendingTask";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ModInfo from "react-lib/apps/common/ModInfo";

import SearchBox from "react-lib/appcon/common/SearchBox";

// Interface
import {
  DOCTOR_ORDER_ACTION_MAP,
  DOCTOR_ORDER_MAPPING_ICON,
} from "react-lib/apps/HISV3/REG/REGInterface";

// Utils
import {
  formatDate,
  formatDateToStringBe,
  formatDateToYYYYMMDD,
} from "react-lib/utils/dateUtils";

// CONFIG
import CONFIG from "config/config";

const CardPatientList = React.forwardRef<any, any>((props, ref) => {
  console.log("Init CardPatientList: ");

  const [tab, setTab] = useState("queue");
  const [tabMSD, setTabMSD] = useState("queueOrder");
  const [hn, setHn] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [division, setDivision] = useState();
  const [doctor, setDoctor] = useState("");
  const [status, setStatus] = useState("");
  const [classify, setClassify] = useState("");
  const [modOrder, setModOrder] = useState({ open: false, items: [] });
  const [previousTab, setPreviousTab] = useState<string>("");
  const [messageTemplate, setMessageTemplate] = useState<any>(null);
  const [chatMessage, setChatMessage] = useState<any>("");
  const [selectedChat, setSelectedChat] = useState<any>(null);
  const [start_date, setStart_date] = useState(formatDateToStringBe(moment()));
  const [end_date, setEnd_date] = useState(formatDateToStringBe(moment()));
  const [editEncounter, setEditEncounter] = useState<number | null>(null);
  const [modEditEN, setModEditEN] = useState<{ en: number | null } | null>(null);

  const [classifyOptions, setClassifyOptions] = useState<any[]>([]);

  const textareaRef = useRef<any>();
  const isTextareaChange = useRef<boolean>(false);
  const clickCountRef = useRef(0);
  const debounceTimeoutRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    getLabOrderQueue: handlePrepareGetLabOrderQueue,
  }));

  useEffect(() => {
    props?.onEvent({
      message: "GetMasterData",
      params: {
        masters: [
          ["division", {}],
          ["doctor", {}],
          ["EncounterStatus", {}],
          ["patientCase", {}],
        ],
      },
    });
  }, []);

  const handleGetLabOrderQueue = useCallback(
    async (params: Record<string, any> = {}) => {
      await props.setProp(`labOrderQueue`, {
        ...props.labOrderQueue,
        ...params,
        checkSpecimen: false,
        checkSpecimenReject: false,
        selectedDivision: props.division,
      });

      //Specimen Management
      props.onEvent({
        message: "GetLabOrderQueue",
        params: {
          action: "search",
        },
      });
    },
    [props.labOrderQueue, props.division]
  );

  useEffect(() => {
    props.onEvent({
      message: "GetClassify",
      params: { division: props.divisionId },
    });

    if (["หน่วยตรวจ", "หอผู้ป่วย"].includes(props.divisionType)) {
      handleGetLabOrderQueue();
    }

    const isDoctor = props.django?.user?.role_types?.includes("DOCTOR");
    let doctorDetail = doctor;
    let divisionId = props.divisionId;

    if (isDoctor && props.currentDoctor) {
      setDoctor(props.currentDoctor);

      doctorDetail = props.currentDoctor;
      divisionId = "";
    } else {
      setDivision(divisionId);
    }

    props.onEvent({
      message: "GetPatientQueue",
      params: {
        hn: hn,
        first_name: firstName,
        last_name: lastName,
        division: divisionId,
        doctor: doctorDetail,
        status: status,
        start_date: start_date,
        end_date: end_date,
      },
    });
  }, [props.divisionId]);

  useEffect(() => {
    setClassifyOptions(
      props.classifyList?.map((item: any) => ({
        key: item.triage_level,
        value: item.triage_level,
        text: item.description,
      }))
    );
  }, [props.classifyList]);

  useEffect(() => {
    if (["หอผู้ป่วย"].includes(props.divisionType || "")) {
      if (["ward_queue", "receive", "med_admin"].includes(tab)) {
        setPreviousTab(tab);
      } else {
        setPreviousTab("ward_queue");
      }
      setTab("");
    } else if (props.selectedDivision?.code === "XRAY") {
      setTab("xray_queue");
    } else if (["หน่วยตรวจ"].includes(props.divisionType || "")) {
      setTab("queue");
    } else if (["ห้องผ่าตัด"].includes(props.divisionType || "")) {
      setTab("or_queue");
    }
  }, [props.selectedDivision]);

  useEffect(() => {
    console.log("saika tab ", tab);
    if (tab === "" && ["หอผู้ป่วย"].includes(props.divisionType || "")) {
      setTab(previousTab);
    }
  }, [tab]);

  useEffect(() => {
    if (isTextareaChange.current) {
      textareaRef.current?.ref?.current?.focus();
    }
  }, [chatMessage]);

  const handlePrepareGetLabOrderQueue = () => {
    const params: Record<string, any> = {};
    const currentDate = formatDate(moment());
    const startDate = props.labOrderQueue?.selectedStartDate || "";
    const endDate = props.labOrderQueue?.selectedEndDate || "";

    const date = formatDateToYYYYMMDD(currentDate);

    if (startDate && formatDateToYYYYMMDD(startDate) > date) {
      params.selectedStartDate = currentDate;
    }
    if (endDate && formatDateToYYYYMMDD(endDate) < date) {
      params.selectedEndDate = currentDate;
    }

    setTimeout(() => {
      handleGetLabOrderQueue(params);
    }, 500);
  };

  const handleSetDoctorOrder = (orders: any[], extra: any = null) => {
    let sortingOrderType: any[] = [];
    let abnormalLabData: any = null;
    if (extra?.abnormal_lab_order) {
      abnormalLabData = extra.abnormal_lab_order;
    }

    Object.keys(DOCTOR_ORDER_MAPPING_ICON).forEach((order_type: any) => {
      // find match order type and status
      Object.keys(DOCTOR_ORDER_ACTION_MAP).forEach((status: any) => {
        let target = orders.filter((order) => {
          if (
            order_type === "centrallaborder" &&
            order.specific_type.includes(order_type) &&
            order?.order_summary_detail_cache
          ) {
            const summary =
              typeof order.order_summary_detail_cache === "string"
                ? JSON.parse(order.order_summary_detail_cache)
                : order.order_summary_detail_cache;

            if (summary?.specific_status?.value === 4 && status === "pending") {
              return true;
            }
            if (
              summary?.specific_status?.value === 4 &&
              status === "performed"
            ) {
              return false;
            }
          }

          return (
            order.specific_type.includes(order_type) &&
            DOCTOR_ORDER_ACTION_MAP[status].includes(order?.order_status)
          );
        });

        if (target?.length > 0) {
          sortingOrderType.push({
            image: DOCTOR_ORDER_MAPPING_ICON[order_type][status],
            order_type,
            items: target?.map((i) => {
              const summary =
                typeof i.order_summary_detail_cache === "string"
                  ? JSON.parse(i.order_summary_detail_cache)
                  : i.order_summary_detail_cache;

              return summary.order_summary_detail;
            }),
            is_abnormal_lab:
              order_type === "centrallaborder" && abnormalLabData
                ? target.filter((item: any) =>
                    abnormalLabData.includes(item.id)
                  ).length > 0
                : false,
          });
        }
      });
    });
    return sortingOrderType;
  };

  const pharmacyList = useMemo(() => {
    return (props.encounterList || []).map((en: any) => {
      return {
        ...en,
        encounter: en.number,
        triage_level:
          en.triage_level === "1" ? (
            <Label color="violet">{en.triage_level}</Label>
          ) : en.triage_level === "2" ? (
            <Label color="red">{en.triage_level}</Label>
          ) : en.triage_level === "3" ? (
            <Label color="orange">{en.triage_level}</Label>
          ) : en.triage_level === "4" ? (
            <Label color="yellow">{en.triage_level}</Label>
          ) : en.triage_level === "5" ? (
            <Label color="green">{en.triage_level}</Label>
          ) : (
            <Label>-</Label>
          ),
      };
    });
  }, [props.encounterList]);

  const splitSequencePatientName = (patientName: string) => {
    let newPatientName = patientName?.split("(0)");

    if (newPatientName.length === 1) {
      return newPatientName;
    } else {
      return newPatientName?.[0];
    }
  };

  const encounterList = useMemo(() => {
    let userData: any[] = [];

    if (props.providerEmployeeInfo?.employee_info?.position === 3) {
      userData = props.encounterList.filter(
        (item: any) => item.division === props.selectedDivision?.id
      );
    } else {
      userData = props.encounterList;
    }
    return userData
      .sort((a: any, b: any) =>
        a.triage_level === ""
          ? 1
          : b.triage_level === ""
          ? -1
          : a.triage_level === b.triage_level
          ? 0
          : a.triage_level < b.triage_level
          ? -1
          : 1
      )
      .map((encounter: any) => {
        let waitingTime: string = "";
        if (
          ![
            "IN_QUEUE",
            "IN_EXAM",
            "CHECKED_OUT",
            "WAIT_PAY",
            "DISCHARGED",
          ].includes(encounter.status)
        ) {
          const enStart: moment.Moment = moment(
            encounter.started,
            "YYYY-MM-DD HH:mm"
          );
          const now: moment.Moment = moment();

          const diffTime = now.diff(enStart);
          const duration = moment.duration(diffTime);
          const hours: number = duration.hours();
          const minutes: number = duration.minutes();

          if (hours > 0) {
            waitingTime += hours + " ชั่วโมง ";
          }
          if (minutes > 0) {
            waitingTime += minutes + " นาที";
          }
        }

        return {
          ...encounter,
          hn_encounter: (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {`${encounter.hn} (${encounter.number})`}
              {props.masterOptions?.patientCase
                ?.find((item: any) => item.value === encounter.patient_case)
                ?.text?.toLowerCase() === "telemed" && (
                <Label
                  circular
                  color="yellow"
                  style={{ height: "1.8rem", width: "1.8rem" }}
                >
                  <Icon name="phone" style={{ transform: "rotate(90deg)" }} />
                </Label>
              )}
            </div>
          ),
          patient_name: (
            <div
              style={
                encounter.can_chat
                  ? {
                      display: "grid",
                      gridTemplateColumns: "1fr 35px",
                    }
                  : {}
              }
            >
              <label>{splitSequencePatientName(encounter.patient_name)}</label>
              {!!encounter.can_chat && (
                <div style={{ position: "relative" }}>
                  <img
                    src={"/static/images/order_images/telemed.png"}
                    style={{ width: "28px", height: "28px", cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      handleSelectMessage(null);
                      setSelectedChat({
                        chat_channel: encounter.chat_channel,
                        division: encounter.division,
                      });
                    }}
                  />
                  {!!encounter.unread_message_count && (
                    <div
                      style={{
                        width: 8,
                        height: 8,
                        backgroundColor: "red",
                        position: "absolute",
                        borderRadius: "50%",
                        top: 1,
                        right: 9,
                      }}
                    ></div>
                  )}
                </div>
              )}
            </div>
          ),
          triage_level:
            encounter.triage_level === "1" ? (
              <Label color="violet">{encounter.triage_level}</Label>
            ) : encounter.triage_level === "2" ? (
              <Label color="red">{encounter.triage_level}</Label>
            ) : encounter.triage_level === "3" ? (
              <Label color="orange">{encounter.triage_level}</Label>
            ) : encounter.triage_level === "4" ? (
              <Label color="yellow">{encounter.triage_level}</Label>
            ) : encounter.triage_level === "5" ? (
              <Label color="green">{encounter.triage_level}</Label>
            ) : (
              <Label>-</Label>
            ),
          //   <div style={{ color: "green" }}>
          //   {encounter.triage_level}
          // </div>

          waiting_time: waitingTime,
          admit_code: (
            props.encounterDetailDict?.admitorderfrom?.[encounter.id] || []
          )
            .map((order: any) => order.code)
            .join(", "),
          doctor_order: encounter?.doctor_orders?.length > 0 && (
            <div style={{ display: "grid", gridTemplateColumns: "40% 40%" }}>
              {handleSetDoctorOrder(
                encounter?.doctor_orders,
                encounter?.extra
              ).map((item: any, index: number) => (
                <div
                  key={"order" + index}
                  style={{ paddingLeft: "5px", display: "flex" }}
                  onClick={() => setModOrder({ open: true, items: item.items })}
                >
                  <img
                    src={item.image}
                    style={{ width: "30px", height: "30px" }}
                  />
                  {item.order_type === "centrallaborder" &&
                    item.is_abnormal_lab && (
                      <span
                        style={{
                          color: "red",
                          fontSize: "20px",
                          marginLeft: "-7px",
                        }}
                      >
                        !
                      </span>
                    )}
                </div>
              ))}
            </div>
          ),
          classify_name: classifyOptions.find(
            (item: any) => item.key === encounter.triage_level
          )?.text,
          edit_encounter: (
            <Popup
              content={
                <Button
                  style={{ backgroundColor: "orange", color: "white" }}
                  onClick={(e) => {
                    e.stopPropagation();

                    setModEditEN({ en: editEncounter });
                    setEditEncounter(null);
                  }}
                >
                  {"แก้ไข Encounter"}
                </Button>
              }
              trigger={<div>{encounter?.doctor_name}</div>}
              open={editEncounter === encounter?.id && !modOrder.open}
              position="bottom center"
            />
          ),
        };
      });
  }, [
    props.encounterList,
    props.encounterDetailDict,
    props.masterOptions?.patientCase,
    editEncounter,
  ]);

  const isDoctor = useMemo(() => {
    return props.django?.user?.role_types?.includes("DOCTOR");
  }, [props.django]);

  const handleSelectMessage = (e: any, v: any = null) => {
    const value =
      v?.value || props.chatMessageTemplateOptions?.[0]?.value || null;

    setMessageTemplate(value);
    setChatMessage(
      props.chatMessageTemplateOptions?.find(
        (item: any) => item.value === value
      )?.message || ""
    );
  };

  const handleSendMessage = () => {
    props.onEvent({
      message: "postChatChannelMessage",
      params: {
        apiToken: props.apiToken,
        chatChannelId: selectedChat?.chat_channel,
        content: chatMessage,
        contentFile: chatMessage,
        contentType: "text",
        divisionId: selectedChat?.division,
      },
    });

    setSelectedChat(null);
  };

  useEffect(() => {
    const updateStatus = async () => {
      await props.setProp(`labNotify`, { rejection: false, request: false });

      for (const item of props.labOrderQueue?.labOrderQueueList || []) {
        if (item.status_name === "ร้องขอทำ Lab") {
          await props.setProp(`labNotify.request`, true);
        } else if (
          item.status_name === "Specimen ถูกปฏิเสธ" ||
          item.order_item_have_rejected
        ) {
          await props.setProp(`labNotify.rejection`, true);
        }
      }
    };

    updateStatus();
  }, [props.labOrderQueue?.labOrderQueueList, props.division_id]);

  // console.log(encounterList);
  // console.log("CardPatientList", props);
  // console.log("props.searchedItemList", props.searchedItemList);
  // console.log("doctor", doctor);

  return props.selectedDivision?.code === "XRAY" ? (
    <div
      style={{
        height: "100%",
        overflow: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "15px 15px 0 10px",
        }}
      >
        <MenuItem
          tab={tab}
          name="xray_queue"
          title="Imaging worklist"
          setTab={setTab}
        />
      </div>
      {tab === "xray_queue" && (
        <CardImagingWorkList
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          ImagingWorkListSequence={props.ImagingWorkListSequence}
          // data
          userName={props.django?.user?.full_name}
          userId={props.django?.user?.id}
          doctorDetail={props.currentDoctor}
          // CommonInterface
          searchedItemListWithKey={props.searchedItemListWithKey}
          buttonLoadCheck={props.buttonLoadCheck}
          userTokenize={props.userTokenize}
          modXrayDetail={props.modXrayDetail}
          errorMessage={props.errorMessage}
          // options
          masterOptions={props.masterOptions}
          // callback
          forward={props.forward}
          // ----- Dental Diagram
          clinicalFindingId={props.clinicalFindingId}
          clinicalFindingIndex={props.clinicalFindingIndex}
          clinicalFindings={props.clinicalFindingList || []}
          organ={props.organ}
          clinicaltags={props.clinicaltags}
          filterClinicalFindingIds={props.filterClinicalFindingIds || []}
          // -----#
        />
      )}
    </div>
  ) : ["หน่วยตรวจ", "หอผู้ป่วย", "ห้องผ่าตัด"].includes(
      props.divisionType || ""
    ) ? (
    props.size === "max" ? (
      <div
        style={{
          height: "100%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: "15px 15px 0 10px",
          }}
        >
          {["หน่วยตรวจ"].includes(props.divisionType) &&
            !CONFIG.V3_HIDDEN_TAB_LIST.includes("queue") && (
              <MenuItem
                tab={tab}
                name="queue"
                title="Patient Queue"
                setTab={setTab}
              />
            )}
          {["หอผู้ป่วย"].includes(props.divisionType) && (
            <MenuItem
              tab={tab}
              name="ward_queue"
              title="Patient Queue"
              setTab={setTab}
            />
          )}
          {((["หน่วยตรวจ", "หอผู้ป่วย"].includes(props.divisionType) &&
            !isDoctor) ||
            ["ห้องผ่าตัด"].includes(props.divisionType)) && (
            <>
              {!CONFIG.V3_HIDDEN_TAB_LIST.includes("receive") && (
                <MenuItem
                  tab={tab}
                  name="receive"
                  title="Receive & Return ยา/เวชภัณฑ์"
                  setTab={setTab}
                />
              )}
            </>
          )}
          {["หน่วยตรวจ", "หอผู้ป่วย"].includes(props.divisionType) &&
            !isDoctor && (
              <>
                {!CONFIG.V3_HIDDEN_TAB_LIST.includes("med_admin") && (
                  <MenuItem
                    tab={tab}
                    name="med_admin"
                    title="Med Admin"
                    setTab={setTab}
                  />
                )}
              </>
            )}
          {["หน่วยตรวจ", "หอผู้ป่วย"].includes(props.divisionType) && (
            <>
              {!CONFIG.V3_HIDDEN_TAB_LIST.includes("verbal_order") && (
                <MenuItem
                  tab={tab}
                  name="verbal_order"
                  title="Verbal order"
                  setTab={setTab}
                />
              )}
            </>
          )}

          {[1, 2].includes(
            props.providerEmployeeInfo?.employee_info?.position
          ) && (
            <>
              {!CONFIG.V3_HIDDEN_TAB_LIST.includes("reject_order") && (
                <MenuItem
                  tab={tab}
                  name="reject_order"
                  title="คำสั่งรอการแก้ไข"
                  setTab={setTab}
                />
              )}
            </>
          )}
          {["หน่วยตรวจ", "หอผู้ป่วย", "ห้องผ่าตัด"].includes(
            props.divisionType
          ) && (
            <>
              {!CONFIG.V3_HIDDEN_TAB_LIST.includes("phv") && (
                <MenuItem
                  tab={tab}
                  name="phv"
                  title="Patient History Viewer"
                  setTab={setTab}
                />
              )}
            </>
          )}
          {/* show OR Queue when division ward and role nurse */}
          {(["ห้องผ่าตัด"].includes(props.divisionType) ||
            (["หอผู้ป่วย"].includes(props.divisionType) &&
              props.django?.user?.role_types?.includes(
                "REGISTERED_NURSE"
              ))) && (
            <MenuItem
              tab={tab}
              name="or_queue"
              title="OR Queue"
              setTab={setTab}
            />
          )}
          {["หน่วยตรวจ", "หอผู้ป่วย"].includes(props.divisionType) &&
            [1, 3].includes(
              props.providerEmployeeInfo?.employee_info?.position
            ) && (
              <>
                {!CONFIG.V3_HIDDEN_TAB_LIST.includes("specimen_management") && (
                  <MenuItem
                    tab={tab}
                    name="specimen_management"
                    title={
                      <div style={{ display: "flex" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          Specimen Management
                        </div>

                        <div
                          style={{
                            display: props.labNotify.rejection
                              ? "block"
                              : "none",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            width: "1.5em",
                            height: "1.5em",
                            margin: "0px 5px",
                          }}
                        ></div>
                        <div
                          style={{
                            display: props.labNotify.request ? "block" : "none",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            width: "1.5em",
                            height: "1.5em",
                          }}
                        ></div>
                      </div>
                    }
                    setTab={setTab}
                  />
                )}
              </>
            )}
          {["หอผู้ป่วย", "หน่วยตรวจ", "ห้องผ่าตัด"].includes(
            props.divisionType
          ) && (
            <>
              {!CONFIG.V3_HIDDEN_TAB_LIST.includes("report_order") && (
                <MenuItem
                  tab={tab}
                  name="report_order"
                  title="รายงาน"
                  setTab={setTab}
                />
              )}
            </>
          )}
        </div>

        {tab === "ward_queue" ? (
          <CardWardQueue
            onEvent={props.onEvent}
            layout={props.layout}
            forward={props?.forward}
            setProp={props.setProp}
            searchedItemList={props.searchedItemList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            masterOptions={props.masterOptions}
            runSequence={props.runSequence}
            drugOrderQueueController={props.drugOrderQueueController}
            // data
            WardQueueSequence={props.WardQueueSequence}
            AdmitChangeSequence={props.AdmitChangeSequence}
            SetBedSequence={props.SetBedSequence}
            modAdmit={props.modAdmit}
            intraTransferForm={props.intraTransferForm}
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            intraHospitalTransferOptions={props.intraHospitalTransferOptions}
            loadingStatus={props.loadingStatus}
            selectVitalSignLoading={props.selectVitalSignLoading}
            getEncounterVitalSignListErr={props.getEncounterVitalSignListErr}
            encounterVitalSignList={props.encounterVitalSignList}
            django={props.django}
            // labOrderQueue={props.labOrderQueue}
            // selectedLabOrderWorking={props.selectedLabOrderWorking}
          />
        ) : tab === "queue" ? (
          <div
            style={{
              padding: "15px 10px 15px 10px",
              width: "calc(100vw - 4.5rem)",
            }}
          >
            <Form>
              <Form.Group inline widths="equal">
                <Form.Field
                  control={Input}
                  label="HN"
                  placeholder="Search..."
                  icon="search"
                  value={hn}
                  onChange={(e: any) => setHn(e.target.value)}
                  width={4}
                />
                <Form.Field
                  control={Input}
                  label="ชื่อ"
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                  width={4}
                />
                <Form.Field
                  control={Input}
                  label="นามสกุล"
                  value={lastName}
                  onChange={(e: any) => setLastName(e.target.value)}
                  width={4}
                />
                <Form.Field width={4}>
                  <label style={{ minWidth: "max-content" }}>หน่วยตรวจ</label>
                  <Select
                    value={division}
                    clearable={true}
                    search={true}
                    options={props.masterOptions?.division || []}
                    fluid={true}
                    style={{ width: "80%" }}
                    onChange={(e: any, v: any) => setDivision(v.value)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field>
                  <SearchBox
                    onEvent={props.onEvent}
                    setProp={props.setProp}
                    // ref
                    inputRef={(ref: any) =>
                      ref &&
                      (ref.inputRef.current.style.minWidth = "max-content")
                    }
                    // data
                    searchedItemList={props.searchedItemList}
                    selectedItem={doctor}
                    setSelectedItem={setDoctor}
                    type="Doctor"
                    label="แพทย์"
                    toDisplay={(item: any) =>
                      `${item.first_name} ${item.last_name}`
                    }
                    style={{ width: "100%" }}
                    fluid={true}
                    disabled={isDoctor}
                  />
                </Form.Field>
                {CONFIG.SHOW_CLASSIFY_FILTER_PATIENT_QUEUE ? (
                  <Form.Field
                    control={Select}
                    label="classify"
                    options={(props.classifyList || []).map((item: any) => ({
                      key: item.triage_level,
                      value: item.triage_level,
                      text: item.description,
                    }))}
                    onChange={(e: any, v: any) => setClassify(v.value)}
                    value={classify}
                    clearable
                    style={{ width: "90%" }}
                    fluid={true}
                    width={4}
                  />
                ) : (
                  <Form.Field
                    control={Select}
                    label="สถานะ"
                    options={props.masterOptions?.EncounterStatus || []}
                    onChange={(e: any, v: any) => setStatus(v.value)}
                    value={status}
                    clearable
                    style={{ width: "90%" }}
                    fluid={true}
                    width={4}
                  />
                )}

                <Form.Field inline>
                  <label style={{ minWidth: "max-content" }}>วันที่</label>
                  <DateTextBox
                    value={start_date}
                    style={{ width: "165px" }}
                    inputStyle={{ width: "165px" }}
                    onChange={(date: any) => setStart_date(date)}
                  ></DateTextBox>
                </Form.Field>
                <Form.Field inline>
                  <label>ถึง</label>
                  <DateTextBox
                    value={end_date}
                    style={{ width: "165px" }}
                    inputStyle={{ width: "165px" }}
                    onChange={(date: any) => setEnd_date(date)}
                  ></DateTextBox>
                </Form.Field>
                <Form.Field inline>
                  <Button
                    content="ค้นหา"
                    color="blue"
                    onClick={() => {
                      props.onEvent({
                        message: "GetPatientQueue",
                        params: {
                          hn: hn,
                          first_name: firstName,
                          last_name: lastName,
                          division: division,
                          doctor: doctor,

                          start_date: start_date,
                          end_date: end_date,
                          ...(CONFIG.SHOW_CLASSIFY_FILTER_PATIENT_QUEUE
                            ? { triage_level: classify }
                            : { status: status }),
                        },
                      });
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>

            <CardPatientListUX
              encounterList={encounterList}
              showDivision={isDoctor}
              encounterListRowProps={(
                state: any,
                rowInfo: any,
                column: any,
                instance: any
              ) => {
                return {
                  style: {
                    backgroundColor:
                      rowInfo?.original?.id &&
                      rowInfo?.original?.id === props.selectedEncounter?.id
                        ? "#cccccc"
                        : rowInfo?.original?.triage_level.props.children ===
                            "1" ||
                          rowInfo?.original?.triage_level.props.children === "2"
                        ? "#fc8486"
                        : rowInfo?.original?.triage_level.props.children === "3"
                        ? "#feffa9"
                        : "white",
                  },

                  // edit encounter
                  onClick: () => {
                    // setTimeout in Single Click
                    debounceTimeoutRef.current = setTimeout(() => {
                      clickCountRef.current += 1;
                      if (editEncounter === rowInfo?.original?.hn) {
                        setEditEncounter(null);
                      } else {
                        props.onEvent({
                          message: "GetPatientDetailView",
                          params: {
                            patient: rowInfo?.original?.patient,
                          },
                        });
                        setEditEncounter(rowInfo?.original?.id);
                      }
                    }, 300);
                  },

                  onDoubleClick: () => {
                    console.log("row :", rowInfo?.original);
                    // Remove details from selected encounter to get original pure encounter from api
                    const { admit_code, ...encounter } = rowInfo?.original;
                    props.onEvent({
                      message: "SelectEncounter",
                      params: { encounter: encounter || null },
                    });
                    props?.forward();
                  },
                };
              }}
            />

            <Modal
              open={!!modEditEN}
              size="large"
              closeOnDimmerClick={true}
              onClose={() => setModEditEN(null)}
            >
              <div style={{ margin: "10px 10px 0" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "1rem 0 -0.75rem",
                  }}
                >
                  <div>
                    <Header>แก้ไข Encounter</Header>
                  </div>
                  <div>
                    <Icon
                      name="cancel"
                      color="red"
                      size="large"
                      link={true}
                      onClick={() => setModEditEN(null)}
                    />
                  </div>
                </div>
                <CardOpenEncounter
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  runSequence={props.runSequence}
                  patient={props.selectedPatient}
                  selectedPatient={props.selectedPatient}
                  CreateUpdateEncounterSequence={
                    props.CreateUpdateEncounterSequence
                  }
                  appointmentEncounterList={props.appointmentEncounterList}
                  selectedAppointmentItems={props.selectedAppointmentItems}
                  masterOptions={props.masterOptions}
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  isEditEncounter={true}
                  editEncounterId={modEditEN?.en}
                  // style
                  cardStyle={{ paddingBottom: 0 }}
                />
              </div>
            </Modal>

            <ModInfo
              open={modOrder.open}
              titleName="รายละเอียด"
              backgroundColor="var(--primary-theme-color)"
              // size="mini"
              buttonColor="grey"
              btnText="ปิด"
              // style={{ width: "25%" }}
              closeOnDimmerClick={true}
              onApprove={() => setModOrder({ open: false, items: [] })}
              onClose={() => setModOrder({ open: false, items: [] })}
            >
              <div
                style={{
                  margin: "-0.5rem 0rem -0.25rem",
                  textAlign: "left",
                  lineHeight: 1.75,
                }}
              >
                {modOrder?.items.map((i: any, index: number) => {
                  // console.log("open mod data: ", i)
                  return (
                    <div key={index} style={{ padding: "10px 0px" }}>
                      {htmlParse(i)}
                    </div>
                  );
                })}
              </div>
            </ModInfo>

            <ModConfirm
              openModal={!!selectedChat}
              titleColor="blue"
              titleName="ส่งข้อความ"
              basic={false}
              approveButtonText="OK"
              approveButtonColor="teal"
              denyButtonText="Cancel"
              denyButtonColor="grey"
              onDeny={() => setSelectedChat(null)}
              onApprove={handleSendMessage}
              onCloseWithDimmerClick={() => setSelectedChat(null)}
              content={
                <>
                  <Form>
                    <Dropdown
                      value={messageTemplate}
                      selection={true}
                      options={props.chatMessageTemplateOptions || []}
                      fluid
                      style={{ width: "100%", marginBottom: "1.25rem" }}
                      onChange={handleSelectMessage}
                    />
                    <TextArea
                      ref={textareaRef}
                      value={chatMessage}
                      rows={5}
                      onChange={(e: any, v) => {
                        isTextareaChange.current = true;
                        setChatMessage(v.value);
                      }}
                    />
                  </Form>
                </>
              }
            />
          </div>
        ) : tab === "or_queue" ? (
          <CardORQueue
            // function
            onEvent={props.onEvent}
            setProp={props.setProp}
            forward={props.forward}
            // common
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            // seq
            runSequence={props.runSequence}
            ORQueueSequence={props.ORQueueSequence}
            ZoneLogSequence={props.ZoneLogSequence}
            ORRequestSequence={props.ORRequestSequence}
            // controller
            drugOrderQueueController={props.drugOrderQueueController}
            proxyController={props.proxyController}
            // SearchBox
            searchedItemList={props.searchedItemList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            // options
            masterOptions={props.masterOptions}
            masterData={props.masterData}
            // data
            selectedOrOrder={props.selectedOrOrder}
            loadingStatus={props.loadingStatus}
            userTokenize={props.userTokenize}
            divisionId={props.divisionId}
            config={{
              showFilterORQueue: true,
              showFilterDrugQueue: false,
            }}
          />
        ) : tab === "receive" ? (
          <CardReceiveOrder
            runSequence={props.runSequence}
            onEvent={props.onEvent}
            setProp={props.setProp}
            django={props.django}
            selectedEncounter={props.selectedEncounter}
            selectedEmr={props.selectedEmr}
            selectedDivision={props.selectedDivision}
            drugOrderQueue={props.drugOrderQueue}
            drugOrder={props.drugOrder}
            drugOrderLog={props.drugOrderLog}
            modNoteReject={props.modNoteReject}
            layout={props.layout}
            forward={props.forward}
            DrugSelectSequence={props.DrugSelectSequence}
            selectedPatient={props.selectedPatient}
            selectedDrug={props.selectedDrug}
            drugDose={props.drugDose}
            drugDelivery={props.drugDelivery}
            modConfirmAction={props.modConfirmAction}
            modDrugRecommendation={props.modDrugRecommendation}
            modDrugInteraction={props.modDrugInteraction}
            modDrugLabInteraction={props.modDrugLabInteraction}
            modDrugDisease={props.modDrugDisease}
            masterOptions={props.masterOptions}
            medErrorWorking={props.MedErrorListSequence?.medErrorWorking}
            errorMessage={props.errorMessage}
            loadingStatus={props.loadingStatus}
            successMessage={props.successMessage}
            // DrugTransferRequest
            drugTransferRequestSequence={props.DrugTransferRequestSequence}
            drugOrderHistoryController={props.drugOrderHistoryController}
            drugRequestList={props.drugRequestList}
            selectedDevice={props.selectedDevice}
            drugTransferRequestlist={props.drugTransferRequestlist}
            drugTransferLog={props.drugTransferLog}
            drugTransferRequestDetail={props.drugTransferRequestDetail}
            ReceiveOrderSequence={props.ReceiveOrderSequence}
            EncounterReceive={props.EncounterReceive}
            ChoiceBarcode={props.ChoiceBarcode}
            //ReturnOrder
            ReturnOrderSequence={props.ReturnOrderSequence}
            OrderReturnList={props.OrderReturnList}
            DrugReturnList={props.DrugReturnList}
            OrderHistory={props.OrderHistory}
            OrderReturnLog={props.OrderReturnLog}
            DrugOrderActionSequence={props.DrugOrderActionSequence}
            DispensingOrderSupplySequence={props.DispensingOrderSupplySequence}
            ReturnSupplySequence={props.ReturnSupplySequence}
            SupplyDeliverySequence={props.SupplyDeliverySequence}
            supplyOrderEligibilityOptions={props.supplyOrderEligibilityOptions}
            selectedDrugOrderWorking={props.selectedDrugOrderWorking}
            // StockManagement
            lotNoExpList={props.lotNoExpList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            // TODO: Check is need MedReconcile Alert modal
            // divisionType={props.divisionType}
            // medReconcileIndex={props.medReconcileIndex}
            // django={props.django}
          />
        ) : tab === "med_admin" ? (
          <MedAdminQueue
            // controller
            patientPanelController={props.patientPanelController}
            proxyController={props.proxyController}
            // data
            patientInfo={props.patientInfo}
            medicationRecord={props.medicationRecord}
            medRecordSummary={props.medRecordSummary}
            django={props.django}
            division={props.divisionList}
            wardTypeList={props.wardTypeList}
            tabCurrentIndex={props.tabCurrentIndex}
            modDrugInteraction={props.modDrugInteraction}
            modDrugLabInteraction={props.modDrugLabInteraction}
            modDrugDisease={props.modDrugDisease}
            currentDoctor={props.currentDoctor}
            masterData={props.masterData}
            // callback
            onEvent={props.onEvent}
            runSequence={props.runSequence}
            setProp={props.setProp}
          />
        ) : tab === "verbal_order" ? (
          <CardDoctorPendingTask
            // controller
            drugOrderQueueController={props.drugOrderQueueController}
            // callback
            onEvent={props.onEvent}
            setProp={props.setProp}
            runSequence={props.runSequence}
            // data
            masterOptions={props.masterOptions}
            DoctorPendingTaskSequence={props.DoctorPendingTaskSequence}
            loadingStatus={props.loadingStatus}
          />
        ) : tab === "phv" ? (
          <div
            style={{ paddingTop: "1rem", height: "90vh", overflowY: "auto" }}
          >
            <CardPHVWrapper
              apiToken={props.apiToken}
              division={props.division}
              // function
              onEvent={props.onEvent}
              setProp={props.setProp}
              // controller
              proxyController={props.proxyController}
              // CommonInterface
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              buttonLoadCheck={props.buttonLoadCheck}
              // options
              masterOptions={props.masterOptions}
              masterData={props.masterData}
              ChoiceTriage={props.ChoiceTriage}
              // seq
              runSequence={props.runSequence}
              AssessmentSequence={props.AssessmentSequence}
              HistoryCentralLabSequence={props.HistoryCentralLabSequence}
              ORPostOperationSequence={props.ORPostOperationSequence}
              ORHistorySequence={props.ORHistorySequence}
              ORRequestSequence={props.ORRequestSequence}
              PerioperativeNursingSequence={props.PerioperativeNursingSequence}
              PreOperationSequence={props.PreOperationSequence}
              // SearchBox
              searchedItemListWithKey={props.searchedItemListWithKey}
              // data
              selectedPatient={props.selectedPatient}
              loadingStatus={props.loadingStatus}
              userTokenize={props.userTokenize}
              // Imaging Result
              ImagingResultSequence={props.ImagingResultSequence}
              // CommonInterface
              modXrayDetail={props.modXrayDetail}
              // ----- Dental Diagram
              clinicalFindingId={props.clinicalFindingId}
              clinicalFindingIndex={props.clinicalFindingIndex}
              clinicalFindingList={props.clinicalFindingList || []}
              organ={props.organ}
              clinicaltags={props.clinicaltags}
              filterClinicalFindingIds={props.filterClinicalFindingIds || []}
              // -----#
            />
          </div>
        ) : tab === "reject_order" ? (
          <>
            <CardRejectOrder
              onEvent={props.onEvent}
              setProp={props.setProp}
              runSequence={props.runSequence}
              masterOptions={props.masterOptions}
              layout={props.layout}
              forward={props?.forward}
              drugOrderQueueController={props.drugOrderQueueController}
              RejectOrderSequence={props.RejectOrderSequence}
              patientADR={props.patientADR}
              disabledVerbalMedOrder={props.disabledVerbalMedOrder}
              orderTypes={props.orderTypes}
              controller={props.controller}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              buttonLoadCheck={props.buttonLoadCheck}
              selectedEncounter={props.selectedEncounter}
              selectedEmr={props.selectedEmr}
              selectedProgressCycle={props.selectedProgressCycle}
              providerEmployeeInfo={props.providerEmployeeInfo}
              // selectedDrugItem to edit
              selectedDrugItemDetail={props.selectedDrugItemDetail}
              selectedDrugItemDetailOptions={
                props.selectedDrugItemDetailOptions
              }
              drugSearchText={props.drugSearchText}
              drugSearchResult={props.drugSearchResult}
              DrugSelectSequence={props.DrugSelectSequence}
              selectedDrug={props.selectedDrug}
              drugOrderList={props.drugOrderList}
              drugOrder={props.drugOrder}
              renderDrugOrderItems={props.renderDrugOrderItems}
              drugDose={props.drugDose}
              drugQty={props.drugQty}
              drugDuration={props.drugDuration}
              drugDescription={props.drugDescription}
              drugPrn={props.drugPrn}
              // modal doctor certificate check
              modDoctorCertificate={props.modDoctorCertificate}
              DrugOrderTemplateSequence={props.DrugOrderTemplateSequence}
              modConfirmDeleteDrug={props.modConfirmDeleteDrug}
              modDrugLabInteraction={props.modDrugLabInteraction}
              modDrugDisease={props.modDrugDisease}
              modDrugInteraction={props.modDrugInteraction}
              currentDoctor={props.currentDoctor}
              modReMedication={props.modReMedication}
              drugOrderItemEstimate={props.drugOrderItemEstimate}
              drugPermission={props.drugPermission}
              searchedItemList={props.searchedItemList}
              drugOrderItemMasterData={{
                masterUnits: props.masterOptions.unit,
                masterRoutes: props.masterOptions.route,
                masterSites: props.masterOptions.site,
                masterFrequencies: props.masterOptions.frequency,
                masterMethods: props.masterOptions.method,
              }}
              duplicateReasonsOptions={props.masterOptions.duplicateReasons}
              // SolventSelectionConfig
              modSolventSelectionConfig={props.modSolventSelectionConfig}
              selectedVerbalDrugOrderWorking={
                props.selectedVerbalDrugOrderWorking
              }
              django={props.django}
            />
          </>
        ) : tab === "specimen_management" ? (
          <CardLabOrderQueue
            masterOptions={props.masterOptions}
            setProp={props.setProp}
            size={props.size}
            onEvent={props.onEvent}
            layout={props.layout}
            forward={props?.forward}
            labOrderQueue={props.labOrderQueue}
            selectedLabOrderWorking={props.selectedLabOrderWorking}
            buttonLoadCheck={props.buttonLoadCheck}
            division={props.divisionId}
            selectedRecordView={true}
          />
        ) : tab === "report_order" ? (
          <CardReportQueue
            controller={props.controller}
            division={props.divisionId}
            divisionType={props.divisionType}
            // sequence
            runSequence={props.runSequence}
            AnesDoctorFeeListSequence={props.AnesDoctorFeeListSequence}
            FoodListSequence={props.FoodListSequence}
            FoodRequestSequence={props.FoodRequestSequence}
            InpatientDiagnosisSequence={props.InpatientDiagnosisSequence}
            ORQueueSequence={props.ORQueueSequence}
            ZoneLogSequence={props.ZoneLogSequence}
            ORRequestSequence={props.ORRequestSequence}
            ORCancelListSequence={props.ORCancelListSequence}
            ORCaseListSequence={props.ORCaseListSequence}
            MedErrorListSequence={props.MedErrorListSequence}
            DrugResuscitationSequence={props.DrugResuscitationSequence}
            SetBedSequence={props.SetBedSequence}
            ReportPatientIPDListSequence={props.ReportPatientIPDListSequence}
            ReportPatientListSequence={props.ReportPatientListSequence}
            ReportDrugUseRecordSequence={props.ReportDrugUseRecordSequence}
            // controller
            drugOrderQueueController={props.drugOrderQueueController}
            proxyController={props.proxyController}
            crdAdmitOrderController={props.crdAdmitOrderController}
            subICD10DetailController={props.subICD10DetailController}
            printListController={props.printListController}
            scannedDocumentController={props.scannedDocumentController}
            // function
            onEvent={props.onEvent}
            setProp={props.setProp}
            forward={props.forward}
            // SearchBox
            searchedItemList={props.searchedItemList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            // CommonInterface
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            // options
            foodRequestOptions={props.foodRequestOptions}
            masterOptions={props.masterOptions}
            masterData={props.masterData}
            // data
            selectedOrOrder={props.selectedOrOrder}
            loadingStatus={props.loadingStatus}
            userTokenize={props.userTokenize}
            divisionId={props.divisionId}
            django={props.django}
            selectedDivision={props.selectedDivision}
            divisionList={props.divisionList}
            // config
            config={{
              showFilterORQueue: false,
              showFilterDrugQueue: false,
              showFilterWardQueue: true,
            }}
            eocIsLoading={props.eocIsLoading}
            //CardWardQueue
            WardQueueSequence={props.WardQueueSequence}
            AdmitChangeSequence={props.AdmitChangeSequence}
            modAdmit={props.modAdmit}
            intraTransferForm={props.intraTransferForm}
            intraHospitalTransferOptions={props.intraHospitalTransferOptions}
            selectVitalSignLoading={props.selectVitalSignLoading}
            getEncounterVitalSignListErr={props.getEncounterVitalSignListErr}
            encounterVitalSignList={props.encounterVitalSignList}
          />
        ) : tab === "history" ? null : (
          <></>
        )}
      </div>
    ) : (
      <div>
        {encounterList?.map((encounter: any, index: number) => (
          <div
            style={{
              borderBottom: "solid #cccccc 1px",
              backgroundColor:
                encounter.id === props.selectedEncounter?.id
                  ? "#cccccc"
                  : "white",
              cursor: "pointer",
            }}
            key={index}
            onClick={() => {
              props.onEvent({
                message: "SelectEncounter",
                params: { encounter: encounter || null },
              });
            }}
            onDoubleClick={() => {
              if (props.layout === 1) {
                props.onEvent({
                  message: "SelectEncounter",
                  params: { encounter: encounter || null },
                });
                props?.forward?.();
              }
            }}
          >
            {encounter.patient_name}
            {encounter.admit_code && ` (Admit Code: ${encounter.admit_code})`}
          </div>
        ))}
      </div>
    )
  ) : props.divisionType === "ห้องยา" ? (
    <CardPharmacyTask
      apiToken={props.apiToken}
      onEvent={props.onEvent}
      masterOptions={props.masterOptions}
      runSequence={props.runSequence}
      setProp={props.setProp}
      // set mode
      drugOrderTabMode={props.drugOrderTabMode}
      // sequence
      SetDrugOrderQueueSequence={props.SetDrugOrderQueueSequence}
      DrugOrderHistorySequence={props.DrugOrderHistorySequence}
      // drug order queue
      drugOrderQueue={props.drugOrderQueue}
      verbalDrugOrderQueue={props.verbalDrugOrderQueue}
      selectedDrugOrderWorking={props.selectedDrugOrderWorking}
      selectedVerbalDrugOrderWorking={props.selectedVerbalDrugOrderWorking}
      drugOrderQueueController={props.drugOrderQueueController}
      // drug order history
      drugOrderHistoryController={props.drugOrderHistoryController}
      drugHistoryList={props.drugHistoryList}
      drugHistoryOrderList={props.drugHistoryOrderList}
      layout={props.layout}
      forward={props?.forward}
      drugTransferRequestSequence={props.DrugTransferRequestSequence}
      selectedDevice={props.selectedDevice}
      drugTransferRequestlist={props.drugTransferRequestlist}
      drugTransferLog={props.drugTransferLog}
      // Medication Error List
      medErrorList={props.medErrorList}
      filterMedError={props.filterMedError}
      medErrorWorking={props.medErrorWorking}
      MedErrorListSequence={props.MedErrorListSequence}
      drugRequestList={props.drugRequestList}
      errorMessage={props.errorMessage}
      successMessage={props.successMessage}
      drugTransferRequestDetail={props.drugTransferRequestDetail}
      django={props.django}
      //DrugtransferRequestHistory
      DrugTransferRequestHistorySequence={
        props.DrugTransferRequestHistorySequence
      }
      DrugTransferRequestHistoryList={props.DrugTransferRequestHistoryList}
      //DrugReturnRequestHistory
      DrugReturnRequestHistorySequence={props.DrugReturnRequestHistorySequence}
      DrugReturnRequestHistoryList={props.DrugReturnRequestHistoryList}
      //AppointmentTelephar
      TelePharmacySequence={props.TelePharmacySequence}
      // OR Queue
      buttonLoadCheck={props.buttonLoadCheck}
      ORQueueSequence={props.ORQueueSequence}
      ZoneLogSequence={props.ZoneLogSequence}
      ORRequestSequence={props.ORRequestSequence}
      proxyController={props.proxyController}
      searchedItemList={props.searchedItemList}
      searchedItemListWithKey={props.searchedItemListWithKey}
      masterData={props.masterData}
      selectedOrOrder={props.selectedOrOrder}
      loadingStatus={props.loadingStatus}
      userTokenize={props.userTokenize}
      divisionId={props.divisionId}
      userId={props.django?.user?.id || null}
      selectedDivision={props.selectedDivision}
      divisionList={props.divisionList}
      // Drug Resuscitation
      DrugResuscitationSequence={props.DrugResuscitationSequence}
      // StockManagement
      StockManagementSequence={props.StockManagementSequence}
      StockTransferOrderSequence={props.StockTransferOrderSequence}
      // StockManagement
      lotNoExpList={props.lotNoExpList}
      transferOrderCount={props.transferOrderCount}
      //DashboardTelephar
      DashboardTelepharSequence={props.DashboardTelepharSequence}
      // postponeNoti
      postponeNotificationList={props.postponeNotificationList}
    />
  ) : props.divisionType === "แผนกเก็บ Specimen" ? (
    <CardLabOrderQueue
      masterOptions={props.masterOptions}
      setProp={props.setProp}
      size={props.size}
      onEvent={props.onEvent}
      layout={props.layout}
      forward={props?.forward}
      labOrderQueue={props.labOrderQueue}
      selectedLabOrderWorking={props.selectedLabOrderWorking}
      buttonLoadCheck={props.buttonLoadCheck}
    />
  ) : props.divisionType === "การเงิน" ? (
    <CardBilling
      // callback
      setProp={props.setProp}
      onEvent={props.onEvent}
      drugOrderQueueController={props.drugOrderQueueController}
      forward={props?.forward}
      // data
      masterOptions={props.masterOptions}
      successMessage={props.successMessage}
      errorMessage={props.errorMessage}
      billingFilterQueue={props.billingFilterQueue}
      billingQueuePagination={props.billingQueuePagination}
      billingQueueList={props.billingQueueList}
      billingHistorySequence={props.billingHistorySequence}
      runSequence={props.runSequence}
      buttonLoadCheck={props.buttonLoadCheck}
      searchedItemList={props.searchedItemList}
      searchedItemListWithKey={props.searchedItemListWithKey}
      // ReceiptCode
      bilReceiptCodeDetail={props.bilReceiptCodeDetail}
    />
  ) : props.selectedDivision?.code === "CNADM" ? (
    <CardADM
      // controller
      drugOrderQueueController={props.drugOrderQueueController}
      crdAdmitOrderController={props.crdAdmitOrderController}
      subICD10DetailController={props.subICD10DetailController}
      printListController={props.printListController}
      scannedDocumentController={props.scannedDocumentController}
      // callback
      setProp={props.setProp}
      onEvent={props.onEvent}
      // seq
      runSequence={props.runSequence}
      AdmitChangeSequence={props.AdmitChangeSequence}
      SetBedSequence={props.SetBedSequence}
      // data
      masterOptions={props.masterOptions}
      django={props.django}
      eocIsLoading={props.eocIsLoading}
      successMessage={props.successMessage}
      errorMessage={props.errorMessage}
      searchedItemList={props.searchedItemList}
      buttonLoadCheck={props.buttonLoadCheck}
    />
  ) : props.selectedDivision?.code === "SUP01" ? ( // ห้องจ่ายเวชภัณฑ์
    <div>
      <MSDMenuUXMED
        selected={tabMSD}
        queueOrder={() => {
          setTabMSD("queueOrder");
        }}
        deliveryQueue={() => {
          setTabMSD("deliveryQueue");
        }}
        deliveryPrint={() => {
          setTabMSD("refillPrint");
        }}
        supplyOrderHistory={() => {
          setTabMSD("supplyOrderHistory");
        }}
        deliveryHistory={() => {
          setTabMSD("deliveryHistory");
        }}
        returnHistory={() => {
          setTabMSD("returnHistory");
        }}
        queueOrderClicked={tabMSD === "queueOrder"}
        deliveryQueueClicked={tabMSD === "deliveryQueue"}
        deliveryClicked={tabMSD === "deliveryManager"}
        deliveryPrintClicked={tabMSD === "refillPrint"}
        supplyOrderHistoryClicked={tabMSD === "supplyOrderHistory"}
        deliveryHistoryClicked={tabMSD === "deliveryHistory"}
        returnHistoryClicked={tabMSD === "returnHistory"}
      />
      <>
        {tabMSD === "queueOrder" ? (
          <>
            <CardSupplyOrderQueueList
              onEvent={props.onEvent}
              setProp={props.setProp}
              forward={props.forward}
              layout={props.layout}
              // seq
              runSequence={props.runSequence}
              DispensingOrderSupplySequence={
                props.DispensingOrderSupplySequence
              }
              // controller
              drugOrderQueueController={props.drugOrderQueueController}
              // data
              divisionList={props.divisionList}
              supplyOrderStatus={props.supplyOrderStatus}
              // options
              masterOptions={props.masterOptions}
            />
          </>
        ) : null}
        {tabMSD === "deliveryQueue" ? (
          <>
            <CardSupplyDeliveryQueue
              masterOptions={props.masterOptions}
              divisionList={props.divisionList}
              buttonLoadCheck={props.buttonLoadCheck}
              onEvent={props.onEvent}
              forward={props.forward}
              layout={props.layout}
              runSequence={props.runSequence}
              SupplyDeliverySequence={props.SupplyDeliverySequence}
              setProp={props.setProp}
              onSelected={() => {
                setTabMSD("deliveryManager");
              }}
            />
          </>
        ) : null}
        {tabMSD === "refillPrint" ? (
          <>
            <CardRefillDivisionPrint
              onEvent={props.onEvent}
              forward={props.forward}
              layout={props.layout}
              runSequence={props.runSequence}
              setProp={props.setProp}
              RefillDivisionSequence={props.RefillDivisionSequence}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              buttonLoadCheck={props.buttonLoadCheck}
            />
          </>
        ) : null}
        {tabMSD === "deliveryManager" ? (
          <>
            <CardSupplyDeliveryManager
              onEvent={props.onEvent}
              forward={props.forward}
              layout={props.layout}
              runSequence={props.runSequence}
              SupplyDeliverySequence={props.SupplyDeliverySequence}
              setProp={props.setProp}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              buttonLoadCheck={props.buttonLoadCheck}
            />
          </>
        ) : null}
        {tabMSD === "supplyOrderHistory" ? (
          <>
            <CardSupplyOrderHistory
              onEvent={props.onEvent}
              forward={props.forward}
              layout={props.layout}
              runSequence={props.runSequence}
              setProp={props.setProp}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              buttonLoadCheck={props.buttonLoadCheck}
              masterOptions={props.masterOptions}
              SupplyOrderHistorySequence={props.SupplyOrderHistorySequence}
              controller={props.drugOrderHistoryController}
              DispensingOrderSupplySequence={
                props.DispensingOrderSupplySequence
              }
              supplyOrderEligibilityOptions={
                props.supplyOrderEligibilityOptions
              }
            />
          </>
        ) : null}
        {tabMSD === "deliveryHistory" ? (
          <>
            <CardSupplyDeliveryHistory
              onEvent={props.onEvent}
              runSequence={props.runSequence}
              setProp={props.setProp}
              buttonLoadCheck={props.buttonLoadCheck}
              SupplyDeliveryHistorySequence={
                props.SupplyDeliveryHistorySequence
              }
              masterOptions={props.masterOptions}
              SupplyDeliverySequence={props.SupplyDeliverySequence}
              onSelected={() => {
                setTabMSD("deliveryManager");
              }}
            />
          </>
        ) : tabMSD === "returnHistory" ? (
          <>
            <CardMedRequestReturn
              setProp={props.setProp}
              onEvent={props.onEvent}
              runSequence={props.runSequence}
              masterOptions={props.masterOptions}
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              selectedEncounter={props.selectedEncounter}
              buttonLoadCheck={props.buttonLoadCheck}
              MedRequestReturnSequence={props.MedRequestReturnSequence}
              ReturnSupplySequence={props.ReturnSupplySequence}
              loadingStatus={props.loadingStatus}
            />
          </>
        ) : null}
      </>
    </div>
  ) : (
    <div>
      แผนกทั่วไป {props.selectedDivision?.code} {" tab: "} {props.tab}{" "}
    </div>
  );
});

const MenuItem = (props: any) => (
  <Button
    size="tiny"
    color={props.name === props.tab ? "blue" : undefined}
    onClick={(e: any) => props.setTab(props.name)}
  >
    {props.title}
  </Button>
);

CardPatientList.displayName = "CardPatientList";
export default CardPatientList;
