import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Menu, Tab } from "semantic-ui-react";
// interface
import { GetEncounterDetail } from "./ORMInterface";
// UI ORM
import CardORRequest from "react-lib/apps/HISV3/ORM/CardORRequest";
import CardPerioperativeNursingRecord from "react-lib/apps/HISV3/ORM/CardPerioperativeNursingRecord";
import CardPostOperation from "react-lib/apps/HISV3/ORM/CardPostOperation";
import CardPreOperation from "react-lib/apps/HISV3/ORM/CardPreOperation";
import CardSurgicalSafetyChecklist from "react-lib/apps/HISV3/ORM/CardSurgicalSafetyChecklist";
import "./CardORHistory.scss";

const styles = {
  sideTabBar: {
    fontSize: 13.5,
    width: "105%",
  },
};

type CardORHistoryProps = {
  // function
  onEvent: any;
  setProp: any;
  // controller
  proxyController?: any;
  subICD10DetailController?: any;
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  django?: any;
  division?: any;
  // options
  masterOptions?: any;
  masterData?: any;
  ChoiceTriage?: any;
  // seq
  runSequence?: any;
  AssessmentSequence?: any;
  HistoryCentralLabSequence?: any;
  ORHistorySequence?: any;
  ORPostOperationSequence?: any;
  ORRequestSequence?: any;
  PerioperativeNursingSequence?: any;
  PreOperationSequence?: any;
  // SearchBox
  searchedItemListWithKey?: any;
  // data
  encounterId?: number | null;
  selectedPatient?: any;
  loadingStatus?: any;
  userTokenize?: any;
};

const CardORHistory = (props: CardORHistoryProps) => {
  const [mainTabIndex, setMainTabIndex] = useState<number>(0);
  // const orHistoryRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    props.runSequence({
      sequence: "PreOperation",
      restart: true,
    });
    props.runSequence({
      sequence: "PerioperativeNursing",
      restart: true,
    });
  }, []);

  useEffect(() => {
    props.runSequence({
      sequence: "ORHistory",
      action: "SELECTED_ENCOUNTER",
      enId: props.encounterId,
      restart: true,
    });
  }, [props.encounterId]);

  useEffect(() => {
    props.setProp(
      "selectedEncounter",
      props.ORHistorySequence?.selectedEncounter
    );
  }, [props.ORHistorySequence?.selectedEncounter]);

  // Tab
  const handleMainTabChange = (_event: any, { activeIndex }: any) => {
    setMainTabIndex(activeIndex);
  };

  //TODO:
  // useEffect(() => {
  //   if (orHistoryRef.current) {
  //     const children = Array.from(
  //       orHistoryRef.current.querySelectorAll(".dropdown, .input, .search, input")
  //     ) as HTMLElement[];

  //     console.log("children: ", children);
  //     children.forEach((e) => {
  //       e.setAttribute("tabindex", -1);
  //     });
  //   }
  // }, [orHistoryRef]);

  return (
    <div>
      <Tab
        id="card-or-history"
        activeIndex={mainTabIndex}
        grid={{ paneWidth: 14, tabWidth: 2 }}
        menu={{
          vertical: true,
          style: styles.sideTabBar,
          className: "yellowHover",
        }}
        panes={[
          {
            menuItem: <Menu.Item key="tabOrRequest">OR request</Menu.Item>,
            render: () => (
              <Tab.Pane>
                <Tab
                  activeIndex={0}
                  menu={{ secondary: true, className: "blueHover" }}
                  panes={[
                    {
                      menuItem: (
                        <Menu.Item key="orRequest">OR request</Menu.Item>
                      ),
                      render: () => (
                        // <Tab.Pane id="tab-card-or-request-history">
                        <Tab.Pane>
                          <CardORRequest
                            class="readonly"
                            id="card-or-request-history"
                            phvReadOnly={true}
                            // callback
                            onEvent={props.onEvent}
                            setProp={props.setProp}
                            runSequence={props.runSequence}
                            // controller
                            proxyController={props.proxyController}
                            // data
                            ORRequestSequence={props.ORRequestSequence}
                            searchedItemListWithKey={
                              props.searchedItemListWithKey
                            }
                            masterData={props.masterData}
                            encounterId={
                              props.ORHistorySequence?.selectedEncounter?.id
                            }
                            operatingOrderId={
                              props.ORHistorySequence?.selectedOrOrder?.id
                            }
                            isNewEncounter={
                              props.ORHistorySequence?.selectedOrOrder
                                ?.is_new_order_encounter
                            }
                            patientId={
                              props.ORHistorySequence?.selectedOrOrder?.patient
                            }
                            loadingStatus={props.loadingStatus}
                            buttonLoadCheck={props.buttonLoadCheck}
                            userTokenize={props.userTokenize}
                            divisionId={props.division}
                            cardStyle={{ height: "88vh" }}
                            errorMessage={props.errorMessage}
                            // options
                            masterOptions={props.masterOptions}
                            // config
                            isConfirm={true}
                            isCancelConfirm={true}
                            isCancel={true}
                          />
                        </Tab.Pane>
                      ),
                    },
                  ]}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: <Menu.Item key="tabPreOp">Pre-operation</Menu.Item>,
            render: () => (
              <Tab.Pane id="tab-card-pre-operation-history">
                <CardPreOperation
                  phvReadOnly={true}
                  // function
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  // controller
                  proxyController={props.proxyController}
                  // CommonInterface
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  buttonLoadCheck={props.buttonLoadCheck}
                  DJANGO={props.django}
                  divisionId={props.division}
                  // options
                  masterOptions={props.masterOptions}
                  masterData={props.masterData}
                  choiceTriage={props.ChoiceTriage}
                  // seq
                  runSequence={props.runSequence}
                  AssessmentSequence={props.AssessmentSequence}
                  HistoryCentralLabSequence={props.HistoryCentralLabSequence}
                  ORRequestSequence={props.ORRequestSequence}
                  PreOperationSequence={props.PreOperationSequence}
                  // SearchBox
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  // data
                  selectedOrOrder={props.ORHistorySequence?.selectedOrOrder}
                  selectedEncounter={props.ORHistorySequence?.selectedEncounter}
                  patientInfo={props.selectedPatient}
                  loadingStatus={props.loadingStatus}
                  userTokenize={props.userTokenize}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: (
              <Menu.Item key="tabSafety">Surgical safety checklist</Menu.Item>
            ),
            render: () => (
              <Tab.Pane id="tab-card-surgical-safety-checklist-history">
                <CardSurgicalSafetyChecklist
                  phvReadOnly={true}
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  buttonLoadCheck={props.buttonLoadCheck}
                  selectedOrOrder={props.ORHistorySequence?.selectedOrOrder}
                  masterData={props.masterData}
                  masterOptions={props.masterOptions}
                  runSequence={props.runSequence}
                  PerioperativeNursingSequence={
                    props.PerioperativeNursingSequence
                  }
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: (
              <Menu.Item key="tabPeri">Perioperative nursing record</Menu.Item>
            ),
            render: () => (
              <Tab.Pane id="tab-card-perioperative-nursing-record-history">
                <CardPerioperativeNursingRecord
                  phvReadOnly={true}
                  // function
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  // CommonInterface
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  buttonLoadCheck={props.buttonLoadCheck}
                  // options
                  masterOptions={props.masterOptions}
                  masterData={props.masterData}
                  // seq
                  runSequence={props.runSequence}
                  PerioperativeNursingSequence={
                    props.PerioperativeNursingSequence
                  }
                  // SearchBox
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  // data
                  selectedOrOrder={props.ORHistorySequence?.selectedOrOrder}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: <Menu.Item key="tabPostOp">Post Operation</Menu.Item>,
            render: () => (
              <Tab.Pane id="tab-card-post-operation-history">
                <CardPostOperation
                  phvReadOnly={true}
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  runSequence={props.runSequence}
                  selectedOrOrder={props.ORHistorySequence?.selectedOrOrder}
                  ORPostOperationSequence={props.ORPostOperationSequence}
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  subICDController={props.subICD10DetailController}
                  buttonLoadCheck={props.buttonLoadCheck}
                />
              </Tab.Pane>
            ),
          },
        ]}
        onTabChange={handleMainTabChange}
      />
    </div>
  );
};

export default React.memo(CardORHistory);
