import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormField,
  FormGroup,
  Button,
  Checkbox,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardVerbalOrderHistoryUX = (props: any) => {
    return(
      <div
        style={{height: "100%", width: "100%"}}>
        <Form>
          <FormField
            inline={true}
            width="16">
            <label
              style={{fontSize: "16px", padding :"10px"}}>
              ประวัติ รคส.
            </label>
          </FormField>
          <FormGroup
            inline={true}>
            <div
              className={props.haveEncounter ? "" : "one wide field"}
              style={{display: props.haveEncounter? "none": "block"}}>
              
              <Dropdown
                fluid={true}
                onChange={props.changeSelectedSearch}
                options={props.optionsSearch}
                selection={true}
                style={{display: props.haveEncounter?  "none": "block"}}
                value={props.selectedSearch}>
              </Dropdown>
            </div>
            <div
              className={props.haveEncounter ? "" : "three wide field"}
              style={{display: props.haveEncounter? "none": "block"}}>
              
              <div
                style={{width: "100%", display: props.haveEncounter?  "none": "block"}}>
                {props.optionSearch}
              </div>
            </div>
            <FormField
              width="1">
              <div
                style={{width: "100%",textAlign: "center",}}>
                ผู้บันทึก
              </div>
            </FormField>
            <FormField
              width="3">
              <div
                style={{width: "100%"}}>
                {props.editorSearch}
              </div>
            </FormField>
            <FormField
              width="1">
              <div
                style={{textAlign: "center", width: "100%"}}>
                วันที่
              </div>
            </FormField>
            <FormField
              width="3">
              <div
                style={{ width: "100%"}}>
                
                <DateTextBox
                  onChange={props.onChangeStartDate}
                  value={props.startDate}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              width="1">
              <div
                style={{textAlign: "center", width: "100%"}}>
                ถึง
              </div>
            </FormField>
            <FormField
              width="3">
              <div
                style={{width: "100%"}}>
                
                <DateTextBox
                  onChange={props.onChangeEndDate}
                  value={props.endDate}>
                </DateTextBox>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              width="7">
              <div
                style={{width: "100%", display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
                
                <Checkbox
                  checked={props.selectedOrder?.includes("ADMIT_ORDER")}
                  label="Admit"
                  onChange={(e:any, data: any) => {props.changeSelectedOrder("ADMIT_ORDER")}}>
                </Checkbox>
                <Checkbox
                  checked={props.selectedOrder?.includes("DRUG_ORDER")}
                  label="ยา"
                  onChange={(e:any, data: any) => {props.changeSelectedOrder("DRUG_ORDER")}}>
                </Checkbox>
                <Checkbox
                  checked={props.selectedOrder?.includes("LAB_ORDER")}
                  label="Lab"
                  onChange={(e:any, data: any) => {props.changeSelectedOrder("LAB_ORDER")}}>
                </Checkbox>
                <Checkbox
                  checked={props.selectedOrder?.includes("TREATMENT_ORDER")}
                  label="Treatment"
                  onChange={(e:any, data: any) => {props.changeSelectedOrder("TREATMENT_ORDER")}}>
                </Checkbox>
                <Checkbox
                  checked={props.selectedOrder?.includes("OR_ORDER")}
                  label="ผ่าตัด"
                  onChange={(e:any, data: any) => {props.changeSelectedOrder("OR_ORDER")}}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              width="1">
              <Button
                color="blue"
                onClick={props.onSearch}>
                ค้นหา
              </Button>
            </FormField>
          </FormGroup>
        </Form>
        <Table
          data={props.historyData}
          getTrProps={props.rowProps}
          headers="Patient,Order Date,Type,Status,Description,ผู้บันทึกคำสั่งแทน,co-signed,co-signed Time,หมายเหตุ,"
          keys="patient_summary,created,order_specific_label,specific_status,description,order_receive_by_name,co_signed,co_signed_time,ack_comments,_action"
          minRows="10"
          showPagination={false}
          style={{maxHeight: "50vh"}}>
        </Table>
      </div>
    )
}


export default CardVerbalOrderHistoryUX

export const screenPropsDefault = {}

/* Date Time : Wed Jun 28 2023 07:45:50 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{height: \"100%\", width: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormField",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "16"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติ รคส."
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"16px\", padding :\"10px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 4,
      "props": {
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editorSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 14,
      "props": {
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Button",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 14,
      "props": {
        "width": {
          "type": "value",
          "value": "7"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", display: \"flex\", justifyContent: \"space-evenly\", alignItems: \"center\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Checkbox",
      "parent": 31,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.selectedOrder?.includes(\"ADMIT_ORDER\")"
        },
        "label": {
          "type": "value",
          "value": "Admit"
        },
        "onChange": {
          "type": "code",
          "value": "(e:any, data: any) => {props.changeSelectedOrder(\"ADMIT_ORDER\")}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Checkbox",
      "parent": 31,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.selectedOrder?.includes(\"DRUG_ORDER\")"
        },
        "label": {
          "type": "value",
          "value": "ยา"
        },
        "onChange": {
          "type": "code",
          "value": "(e:any, data: any) => {props.changeSelectedOrder(\"DRUG_ORDER\")}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Checkbox",
      "parent": 31,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.selectedOrder?.includes(\"LAB_ORDER\")"
        },
        "label": {
          "type": "value",
          "value": "Lab"
        },
        "onChange": {
          "type": "code",
          "value": "(e:any, data: any) => {props.changeSelectedOrder(\"LAB_ORDER\")}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Checkbox",
      "parent": 31,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.selectedOrder?.includes(\"TREATMENT_ORDER\")"
        },
        "label": {
          "type": "value",
          "value": "Treatment"
        },
        "onChange": {
          "type": "code",
          "value": "(e:any, data: any) => {props.changeSelectedOrder(\"TREATMENT_ORDER\")}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Checkbox",
      "parent": 31,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.selectedOrder?.includes(\"OR_ORDER\")"
        },
        "label": {
          "type": "value",
          "value": "ผ่าตัด"
        },
        "onChange": {
          "type": "code",
          "value": "(e:any, data: any) => {props.changeSelectedOrder(\"OR_ORDER\")}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 37,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.historyData"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": "Patient,Order Date,Type,Status,Description,ผู้บันทึกคำสั่งแทน,co-signed,co-signed Time,หมายเหตุ,"
        },
        "keys": {
          "type": "value",
          "value": "patient_summary,created,order_specific_label,specific_status,description,order_receive_by_name,co_signed,co_signed_time,ack_comments,_action"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{maxHeight: \"50vh\"}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 4,
      "props": {
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้บันทึก"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",textAlign: \"center\",}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 4,
      "props": {
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"center\", width: \"100%\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 44,
      "name": "DateTextBox",
      "parent": 43,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartDate"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 4,
      "props": {
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"center\", width: \"100%\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 4,
      "props": {
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 49,
      "name": "DateTextBox",
      "parent": 48,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "code",
          "value": "props.haveEncounter ? \"\" : \"one wide field\""
        },
        "style": {
          "type": "code",
          "value": "{display: props.haveEncounter? \"none\": \"block\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "code",
          "value": "props.haveEncounter ? \"\" : \"three wide field\""
        },
        "style": {
          "type": "code",
          "value": "{display: props.haveEncounter? \"none\": \"block\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "code",
          "value": "props.optionSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", display: props.haveEncounter?  \"none\": \"block\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Dropdown",
      "parent": 50,
      "props": {
        "disabled": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSelectedSearch"
        },
        "options": {
          "type": "code",
          "value": "props.optionsSearch"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.haveEncounter?  \"none\": \"block\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedSearch"
        }
      },
      "seq": 53,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardVerbalOrderHistoryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
