import WasmController from "react-lib/frameworks/WasmController";
import PatientData from "react-lib/apps/common/PatientData";
import PatientListOptimized from "issara-sdk/apis/EncounterPatientListOptimized_core";
// import PatientQueueList from "issara-sdk/apis/PatientQueueList_apps_DPO";
// import PatientDetailViewByHn from "issara-sdk/apis/PatientDetailViewByHn_apps_REG";
import MedicalRecordList from "issara-sdk/apis/MedicalRecordList_apps_DPO";
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import VitalSignValueList from "issara-sdk/apis/VitalSignValueList_apps_PTM";
import ProgressionCycleList from "issara-sdk/apis/ProgressionCycleList_core";
import ProblemListList from "issara-sdk/apis/ProblemListList_apps_DPO";
import AdmitOrderList from "issara-sdk/apis/AdmitOrderList_apps_ADM";
import AdverseReactionList from "issara-sdk/apis/AdverseReactionList_apps_ADR";
import ProxyPatientHasDivisionChatDetail from "issara-sdk/apis/ProxyPatientHasDivisionChatDetail_apps_PRX";
import MedicalRecordDischarge from "issara-sdk/apis/MedicalRecordDischarge_apps_DPI";
import AdmitOrderRoomItemDetail from "issara-sdk/apis/AdmitOrderRoomItemDetail_apps_ADM";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import DoctorDischargePatient from "issara-sdk/apis/DoctorDischargePatient_apps_DPO";
import LabResultDetail from "issara-sdk/apis/LabResultDetail_apps_ADR";
import DrugLabInteractionList from "issara-sdk/apis/DrugLabInteractionList_apps_ADR";
import PatientAppointmentTelePharmacyList from "issara-sdk/apis/PatientAppointmentTelePharmacyList_apps_PRX";
import PatientAppointmentFinishConsult from "issara-sdk/apis/PatientAppointmentFinishConsult_apps_QUE";
import EncounterMedicalRecordList from "issara-sdk/apis/EncounterMedicalRecordList_core";
import PatientDivisionChatChannelList from "issara-sdk/apis/PatientDivisionChatChannelList_apps_PRX";
import ProblemListDetail from "issara-sdk/apis/ProblemListDetail_apps_DPO";
import MedicationReconciliationCheckView from "issara-sdk/apis/MedicationReconciliationCheckView_apps_TPD";

import { ResetSequence } from "react-lib/apps/HISV3/MainHISResetSequence";
import ConnectUserWithPatient from "issara-sdk/apis/ConnectUserWithPatient_apps_REG";
import ImagingOrderList from "issara-sdk/apis/ImagingOrderlist_apps_IME";
import ClassifyList from "issara-sdk/apis/ClassifyList_apps_PRX";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import UserListM from "issara-sdk/apis/UserList_usersM";
import DoctorList from "issara-sdk/apis/DoctorList_core";
import UserLoginView from "issara-sdk/apis/UserLoginView_core";

// Interface
import { HandleGetProfileImage } from "../REG/sequence/SetPatient";
import { HandleGetProfileInfo } from "../REG/sequence/SetPatient";

// Config
import CONFIG from "../../../../config/config";

export type State = {
  openDischargeReassessment?: any;
  loadingStatus?: any;
  selectedAdmitOrderRoomItem?: any;
  errorMessage?: any;
  divisionType?: string | null;
  encounterList?: any[];
  encounterDetailDict?: any;
  hasAdmitOrder?: boolean;
  selectedPatient?: any;
  patientADR?: any;
  selectedEncounter?: any | null;
  selectedEmr?: any | null;
  selectedImaging?: any | null;
  selectedProgressCycle?: any | null;
  patientData?: PatientData | null;
  chatDetail?: any;
  patientAssessment?: any;
  weightInfo?: any;
  heightInfo?: any;
  TriageSequence?: any;
  AssessmentSequence?: any;
  selectedRecordViewIndex?: any;
  vitalSignsInfo?: any;
  problemListDPO?: any;
  messageProblemDPO?: any;
  LabResult?: any;
  SetPatientSequence?: any;
  selectedShippingOrder?: any;
  encounter?: number | null;
  chatMessageTemplateOptions?: any[];
  classifyList?: any[];
  goToMenu?: string;
  goToTab?: string;
  dischargeDoctor?: any;
  loginVia?: boolean;
  approve_by?: any;
  medReconcileCheck?: any;
};

export const StateInitial: State = {
  encounterList: [],
  encounterDetailDict: {},
  hasAdmitOrder: false,
  selectedEncounter: null,
  selectedImaging: null,
  selectedEmr: null,
  selectedProgressCycle: null,
  chatDetail: null,
  chatMessageTemplateOptions: [
    {
      key: 1,
      text: "แจ้งเตือนใกล้ถึงคิวรับบริการ",
      value: 1,
      message:
        "ขณะนี้ใกล้ถึงคิวรับบริการของท่านแล้ว\nกรุณามารอรับบริการที่หน้าห้องตรวจ",
    },
    {
      key: 2,
      text: "อื่นๆ",
      value: 2,
      message: "",
    },
  ],
  classifyList: [],
  dischargeDoctor: {
    userId: null,
    username: "",
    password: "",
  },
  approve_by: null,
  medReconcileCheck: null,
};

export type Event =
  | { message: "GetPatientQueue"; params: {} }
  | { message: "SelectEncounter"; params: { encounter: any } }
  | { message: "GetDrugOrderList"; params: { emr_id: number } }
  | { message: "GetDrugOrderQueue"; params: any }
  | { message: "GetLabOrderQueue"; params: any }
  | { message: "GetUsername"; params: any }
  | { message: "GetDoctorSearch"; params: any }
  | { message: "CheckUserLogin"; params: any }
  | {
    message: "GetProblemList";
    params: {
      patient: number;
      is_active: boolean;
    };
  }
  | { message: "GetPatientDetailView"; params: any }
  // Discharge
  | { message: "HandleSaveMedicalRecordDischarge"; params: any }
  | { message: "HandleRefreshEncounter"; params: any }
  | { message: "HandleDischargeConsult"; params: any }
  // Classify
  | { message: "GetClassify"; params: any }
  // ProblemDPO
  | { message: "HandleAddMessageProblemDPO"; params: any };

export type Data = {
  division?: number;
  divisionDict?: {};
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const GetProblemList: Handler = async (controller, params) => {
  return await ProblemListList.list({
    params: params,
    apiToken: controller.apiToken,
  });
};

export const GetPatientQueue: Handler = async (controller, params) => {
  const state = controller.getState();
  // console.log("ประเภทหน่วยงาน:", state.divisionType || "ไม่ทราบ");
  if (
    ["หน่วยตรวจ", "หอผู้ป่วย", "ห้องผ่าตัด"].includes(state.divisionType || "")
  ) {
    const encounterList = await PatientListOptimized.get({
      // const encounterList = await PatientQueueList.get({
      apiToken: controller.apiToken,
      params: {
        from_date: params.start_date,
        to_date: params.end_date,
        doctor: params.doctor?.id || null,
        patient__hn: params.hn || null,
        ...(params.first_name && { first_name: params.first_name }),
        ...(params.last_name && { last_name: params.last_name }),
        ...(params.patientName && { patient_name: params.patientName }),

        // patient_name: params.first_name || params.last_name || null,
        division: params.division || null,
        status: params.status || null,
        exclude_canceled: true,

        // telephar
        // ...(params.deliverNo && { delivery_no: params.deliverNo }),
        // ...(params.patientType && { patient_type: params.patientType }),
        // ...(params.deliveryType && { delivery_type: params.deliveryType }),
        // ...(params.deliveryStatus && {
        //   delivery_status: params.deliveryStatus,
        // }),
        // ...(params.consultStatus && { consult_status: params.consultStatus }),
      },
      extra: {
        division: controller.data.division,
      },
    });
    console.log(encounterList[1] ? encounterList[1] : encounterList[0]);
    console.log("encounterList[0].items", encounterList[0]?.items);
    controller.setState(
      {
        encounterList: encounterList[0]?.items || [],
      },
      async () => {
        const state = controller.getState();

        const divisionPatientList = (state.encounterList || [])
          .filter((item) => item.division)
          .map((item) => [item.division, item.patient]);

        const [admitOrder, patientChat] = await Promise.all([
          AdmitOrderList.list({
            params: {},
            apiToken: controller.apiToken,
          }),
          PatientDivisionChatChannelList.post({
            apiToken: controller.apiToken,
            data: { division_patient: divisionPatientList },
          }),
        ]);
        // Get additional details and store in encounterDetailDict
        console.log(admitOrder[1] ? admitOrder[1] : admitOrder[0]);
        let detailDict = state.encounterDetailDict;

        detailDict.admitorder = admitOrder[1]
          ? {}
          : admitOrder[0].items.reduce((acc: any, cur: any) => {
            if (!Object.keys(acc).includes(cur.encounter))
              acc[cur.encounter] = [];
            acc[cur.encounter].push(cur);
            return acc;
          }, {});

        detailDict.admitorderfrom = admitOrder[1]
          ? {}
          : admitOrder[0].items.reduce((acc: any, cur: any) => {
            if (!Object.keys(acc).includes(cur.encounter_opd))
              acc[cur.encounter_opd] = [];
            acc[cur.encounter_opd].push(cur);
            return acc;
          }, {});

        controller.setState({
          encounterDetailDict: detailDict,
          encounterList: (state.encounterList || []).map((item) => {
            const divisionPatient =
              patientChat[0]?.find(
                (acc: any) =>
                  acc.patient_id === item.patient &&
                  acc.division === item.division
              ) || null;

            return {
              ...item,
              can_chat: !!divisionPatient,
              unread_message_count:
                divisionPatient?.unread_message_count || null,
              chat_channel: divisionPatient?.chat_channel || null,
            };
          }),
        });
      }
    );
  } else if (state.divisionType === "ห้องยา") {
    controller.handleEvent({
      message: "GetDrugOrderQueue",
      params: { fetchDrugVerbalOnly: true },
    });
  } else if (state.divisionType === "แผนกเก็บ Specimen") {
    controller.handleEvent({
      message: "GetLabOrderQueue",
      params: { action: "search" },
    });
  } else {
    // console.log("divisionDict", controller.data.divisionDict);
    // console.log("division_id", controller.data.division);
  }
};

export const GetPatientDetailView: Handler = async (controller, params) => {
  const [patientRes, patientErr, patientNetwort] =
    await PatientDetailView.retrieve({
      pk: params?.patient,
      apiToken: controller.apiToken,
    });

  if (patientRes) {
    controller.setState({
      selectedPatient: patientRes,
    });
  }
};

export const SelectEncounter: Handler = async (controller, params) => {
  const state = controller.getState();
  // console.log('SelectEncounter: params: ', params, state);
  console.log("SelectEncounter params: ", params);

  // Clear info
  if (params?.encounter?.id !== state.selectedEncounter?.id) {
    console.log("selectedEncounter: null");
    controller.setState({
      selectedEncounter: null,
      selectedEmr: null,
      selectedImaging: null,
      selectedPatient: null,
      patientADR: null,
      patientData: null,
      patientAssessment: null,
      weightInfo: null,
      heightInfo: null,
      chatDetail: null,
      vitalSignsInfo: null,
      problemListDPO: null,
      LabResult: null,
      ...ResetSequence,
    });
  }

  const hasAdmitOrder =
    !!state.encounterDetailDict?.admitorderfrom?.[params.encounter.id];
  // console.log("hasAdmitOrder:", hasAdmitOrder, state);

  // First, get medical records
  // const medicalRecord = await

  const [
    patientInfo,
    patientAssessment,
    weightInfo,
    heightInfo,
    // progressionInfo,
    medicalRecord,
    adrInfo,
    vitalSignsInfo,
    problemListDPO,
    LabResult,
    selectedShippingOrder,
    encounterMedicalRecord,
    imagingOrder,
    chatDetail,
    // medReconcileCheck
  ] = await Promise.all([
    PatientDetailView.retrieve({
      pk: params.encounter.patient_id,
      apiToken: controller.apiToken,
    }),
    FormDataLatest.retrieve({
      params: {
        patient: params.encounter.patient_id,
        form_version: 0.1,
        form_code: "CardPatientAssessment",
      },
      apiToken: controller.apiToken,
    }),
    VitalSignValueList.list({
      params: {
        patient: params.encounter.patient_id,
        type_code: "Weight",
        latest_measurement: true,
      },
      apiToken: controller.apiToken,
    }),
    VitalSignValueList.list({
      params: {
        patient: params.encounter.patient_id,
        type_code: "Height",
        latest_measurement: true,
      },
      apiToken: controller.apiToken,
    }),
    MedicalRecordList.list({
      params: {
        encounter: params.encounter.id,
        // current_doctor: true,
      },
      extra: {
        division: controller.data.division,
      },
      apiToken: controller.apiToken,
    }),
    AdverseReactionList.list({
      params: {
        patient: params.encounter.patient_id,
        exclude_cancel: true,
        // exclude_unused: true,
        severe_first: true,
      },
      apiToken: controller.apiToken,
    }),
    VitalSignValueList.list({
      params: {
        patient: params.encounter.patient_id,
        latest_measurement: true,
      },
      apiToken: controller.apiToken,
    }),
    ProblemListList.list({
      params: {
        patient: params.encounter.patient_id,
      },
      apiToken: controller.apiToken,
    }),
    DrugLabInteractionList.get({
      // pk: params.encounter.patient_id,
      apiToken: controller.apiToken,
      params: { patient: params?.encounter.patient_id },
    }),
    PatientAppointmentTelePharmacyList.list({
      apiToken: controller.apiToken,
    }),
    EncounterMedicalRecordList.list({
      apiToken: controller.apiToken,
      pk: params.encounter.id,
    }),
    ImagingOrderList.list({
      apiToken: controller.apiToken,
      params: {
        encounter: params?.encounter?.id,
      },
    }),
    ProxyPatientHasDivisionChatDetail.retrieve({
      pk: params.encounter?.patient_id,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
      params: {
        force_create: true,
      },
    }),
    // MedicationReconciliationCheckView.get({
    //   extra: { division: controller.data.division },
    //   apiToken: controller.apiToken,
    //   params: {
    //     encounter: params.encounter.id,
    //     call_from: params.callForm
    //   },
    // })
  ]);

  const ShippingOrder = selectedShippingOrder?.[0]?.items?.filter(
    (item: any) => item.encounter === params.encounter?.id
  );

  // console.log("selectedShippingOrder");
  // console.log("medReconcileCheck", medReconcileCheck)

  controller.setState({
    selectedShippingOrder: ShippingOrder,
    chatDetail: chatDetail[0],
  });

  let patientData = new PatientData(() => {
    // controller.setState({
    //   patientData: controller.getState().patientData
    // });
  });

  patientData.setPatientId(params.encounter.patient_id);
  patientData.setEncounterId(params.encounter.id);

  console.log("encounterMedicalRecord: ", encounterMedicalRecord);

  console.log("medicalRecord: ", medicalRecord);
  // let emr = null
  if (
    encounterMedicalRecord[1] ||
    encounterMedicalRecord[0].items.length === 0 ||
    !encounterMedicalRecord[0].items[0].id
  ) {
    const emr = encounterMedicalRecord[0]?.items?.[0];

    if (CONFIG.PATIENT_IMAGE_URL && patientInfo[0]) {
      patientInfo[0].profile_image = null;

      HandleGetProfileImage(controller as any, { hn: patientInfo[0]?.hn });
    }

    console.log(
      "Change selectedRecordViewIndex: ",
      params?.selectedRecordViewIndex || 0
    );
    console.log(
      "Change frezzeRecordViewIndex selectedRecordViewIndex: ",
      params?.frezzeRecordViewIndex
    );

    controller.setState({
      goToMenu: params.goToMenu,
      goToTab: params.goToTab,
      ...(!params?.frezzeRecordViewIndex && {
        selectedRecordViewIndex: !!params.goToMenu ? -1 : params.selectedRecordViewIndex || 0,
      }),
      selectedEncounter: params.encounter,
      selectedImaging: { ...(imagingOrder?.[0]?.items?.[0] || {}) },
      hasAdmitOrder: hasAdmitOrder,
      selectedAdmitOrderRoomItem: params.admitRoom || null,
      ...(emr && { selectedEmr: emr ? { ...emr } : null }),
      selectedPatient: patientInfo[0],
      patientADR: adrInfo[0],
      patientAssessment: patientAssessment[0] ? patientAssessment[0] : {},
      weightInfo: {
        weight: weightInfo[0] ? weightInfo[0].items?.[0]?.result : null,
        weight_unit: weightInfo[0] ? weightInfo[0].items?.[0]?.unit : null,
      },
      heightInfo: {
        height: heightInfo[0] ? heightInfo[0].items?.[0]?.result : null,
        height_unit: heightInfo[0] ? heightInfo[0].items?.[0]?.unit : null,
      },
      patientData: patientData,
      selectedProgressCycle: null,
      vitalSignsInfo: vitalSignsInfo?.[0] ? vitalSignsInfo?.[0] : {},
      problemListDPO: problemListDPO[0],
      LabResult: LabResult[0] ? LabResult[0]?.items : [],
      // medReconcileCheck: medReconcileCheck?.[0] ? medReconcileCheck?.[0] : null,
    });

    if (CONFIG.SHOW_PATIENT_INFO_FROM_BDMS) {
      HandleGetProfileInfo(controller as any, {
        hn: patientInfo[0]?.hn,
        patient_id: patientInfo[0]?.patient_id,
      });
    }
    return console.log("Can't get emr for encounter_id:", params.encounter.id);
  }
  // console.log(medicalRecord[0]);

  // Set selectedEncounter, selectedEmr to open tab menu

  console.log(
    "Change selectedRecordViewIndex: ",
    params?.selectedRecordViewIndex || 0
  );
  console.log(
    "Change selectedRecordViewIndex: params?.frezzeRecordViewIndex ",
    params?.frezzeRecordViewIndex
  );
  console.log("DEBUG params.encounter: ", params.encounter);

  controller.setState({
    goToMenu: params.goToMenu,
    goToTab: params.goToTab,

    ...(!params?.frezzeRecordViewIndex && {
      selectedRecordViewIndex: !!params.goToMenu ? -1 : params.selectedRecordViewIndex || 0,
    }),

    selectedEncounter: params.encounter,
    selectedEmr:
      medicalRecord?.[0]?.items?.[0] || encounterMedicalRecord?.[0]?.items?.[0],
    hasAdmitOrder: hasAdmitOrder || !!medicalRecord[0]?.items[0]?.id,
    selectedAdmitOrderRoomItem: params.admitRoom || null,
    selectedImaging: { ...(imagingOrder?.[0]?.items?.[0] || {}) },
    // medReconcileCheck: medReconcileCheck?.[0] ? medReconcileCheck?.[0] : null,
  });

  // Get basic data - translate from MainDPO
  const progressionInfo = await ProgressionCycleList.list({
    params: {
      emr:
        medicalRecord?.[0]?.items?.[0]?.id ||
        encounterMedicalRecord?.[0]?.items?.[0]?.id,
    },
    apiToken: controller.apiToken,
  });
  // const [
  // patientInfo,
  // patientAssessment,
  // weightInfo,
  // heightInfo,
  // progressionInfo,
  // adrInfo,
  // vitalSignsInfo,
  // problemListDPO,
  // LabResult

  // ] = await Promise.all([
  //   PatientDetailView.retrieve({
  //     pk: params.encounter.patient_id,
  //     apiToken: controller.apiToken,
  //   }),
  //   FormDataLatest.retrieve({
  //     params: {
  //       patient: params.encounter.patient_id,
  //       form_version: 0.1,
  //       form_code: "CardPatientAssessment",
  //     },
  //     apiToken: controller.apiToken,
  //   }),
  //   VitalSignValueList.list({
  //     params: {
  //       patient: params.encounter.patient_id,
  //       type_code: "Weight",
  //       latest_measurement: true,
  //     },
  //     apiToken: controller.apiToken,
  //   }),
  //   VitalSignValueList.list({
  //     params: {
  //       patient: params.encounter.patient_id,
  //       type_code: "Height",
  //       latest_measurement: true,
  //     },
  //     apiToken: controller.apiToken,
  //   }),
  //   ProgressionCycleList.list({
  //     params: { emr: medicalRecord[0].items[0].id },
  //     apiToken: controller.apiToken,
  //   }),
  //   AdverseReactionList.list({
  //     params: {
  //       patient: params.encounter.patient_id,
  //       exclude_cancel: true,
  //       // exclude_unused: true,
  //       severe_first: true,
  //     },
  //     apiToken: controller.apiToken,
  //   }),
  //   VitalSignValueList.list({
  //     params: {
  //       patient: params.encounter.patient_id,
  //       latest_measurement: true,
  //     },
  //     apiToken: controller.apiToken,
  //   }),
  //   ProblemListList.list({
  //     params: {
  //       patient: params.encounter.patient_id,
  //     },
  //     apiToken: controller.apiToken,
  //   }),
  //   LabResultDetail.get({
  //     pk:params.encounter.patient_id,
  //     apiToken: controller.apiToken,
  //   })
  // ]);

  // Create progress cycle for OPD encounter if none exists
  let selectedProgressCycle: any = null;
  if (params.encounter?.type === "OPD") {
    if (progressionInfo[0] && progressionInfo[0]?.total > 0) {
      selectedProgressCycle = progressionInfo[0]?.items?.[0];
      console.log("Use existing progress cycle", selectedProgressCycle);
    } else {
      const progressCycle = await ProgressionCycleList.create({
        data: {
          emr:
            medicalRecord?.[0]?.items?.[0]?.id ||
            encounterMedicalRecord?.[0]?.items?.[0]?.id,
          checkout: false,
          progression_note: "",
        } as any,
        apiToken: controller.apiToken,
      });
      console.log(progressCycle[1] ? progressCycle[1] : progressCycle[0]);
      if (progressCycle[0]) {
        selectedProgressCycle = progressCycle[0];
        console.log("Use new progress cycle", selectedProgressCycle);
      }
    }
  }

  // Prepare patientData
  // let patientData = new PatientData(() => {
  // controller.setState({
  //   patientData: controller.getState().patientData
  // });
  // });
  // patientData.setPatientId(params.encounter.patient_id);
  // patientData.setEncounterId(params.encounter.id);
  patientData.setEmrId(
    medicalRecord?.[0]?.items?.[0]?.id ||
    encounterMedicalRecord?.[0]?.items?.[0]?.id
  );
  if (selectedProgressCycle?.id)
    patientData.setProgressionCycleId(selectedProgressCycle?.id);

  // if (progressionInfo[1]) toast.error(progressionInfo[1]);
  // if (progressionInfo[0].total > 0)
  //   patientData.setProgressionCycleId(progressionInfo[0]["items"][0]["id"]);
  if (CONFIG.PATIENT_IMAGE_URL && patientInfo[0]) {
    patientInfo[0].profile_image = null;

    HandleGetProfileImage(controller as any, { hn: patientInfo[0]?.hn });
  }

  controller.setState({
    selectedPatient: patientInfo[0],
    patientADR: adrInfo[0],
    patientAssessment: patientAssessment[0] ? patientAssessment[0] : {},
    weightInfo: {
      weight: weightInfo[0] ? weightInfo[0].items?.[0]?.result : null,
      weight_unit: weightInfo[0] ? weightInfo[0].items?.[0]?.unit : null,
    },
    heightInfo: {
      height: heightInfo[0] ? heightInfo[0].items?.[0]?.result : null,
      height_unit: heightInfo[0] ? heightInfo[0].items?.[0]?.unit : null,
    },
    patientData: patientData,
    selectedProgressCycle: selectedProgressCycle,
    vitalSignsInfo: vitalSignsInfo?.[0] ? vitalSignsInfo?.[0] : {},
    problemListDPO: problemListDPO[0],
    LabResult: LabResult[0] ? LabResult[0].items : [],
  });

  if (CONFIG.SHOW_PATIENT_INFO_FROM_BDMS) {
    HandleGetProfileInfo(controller as any, {
      hn: patientInfo[0]?.hn,
      patient_id: patientInfo[0]?.patient_id,
    });
  }

  // Get chat detail

  controller.handleEvent({
    message: "GetMasterData" as any,
    params: {
      masters: [
        ["patientCondition", {}],
        ["patientDischarge", {}],
        ["cleaningChoice", {}],
      ],
    },
  });
};

export const HandleSaveMedicalRecordDischarge: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  const data = {
    checkout_cause: params.checkout_cause,
    death_datetime: "",
    // doctor_fee: doctorFee,
    // doctor_fee_rule: doctorFeeRule,
    // doctor_fee_remark: doctorFeeRemark,
    require_diagnosis: params.require_diagnosis,
    approve_by: params.approve_by,
  };

  let discharge: any = [null, null, null];

  if (params.type === "Discharge") {
    discharge = await MedicalRecordDischarge.update({
      apiToken: controller.apiToken,
      pk: params.emr_id,
      data,
    });
  } else if (params.type === "Request discharge") {
    discharge = await AdmitOrderRoomItemDetail.update({
      apiToken: controller.apiToken,
      pk: params.admitRoom.id,
      data: {
        action: "CASHIER_REQUEST",
      },
    });
  } else if (params.type === "OPD_CHECK_OUT") {
    discharge = await DoctorDischargePatient.update({
      apiToken: controller.apiToken,
      pk: state.selectedEmr?.id,
      data,
      extra: {
        division: controller.data.division,
      },
    });
  } else if (["Ward Discharge", "OPD_DISCHARGE"].includes(params.type)) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: true },
    });

    if (params.type === "Ward Discharge") {
      discharge = await AdmitOrderRoomItemDetail.update({
        apiToken: controller.apiToken,
        pk: params.admitRoom.id,
        data: {
          action: "DISCHARGE",
          cleaning: params.cleaning,
          discharge_note: params.discharge_note,
          discharge_status: params.discharge_status,
          predischarge_condition: params.predischarge_condition,
        },
      });
    } else if (params.type === "OPD_DISCHARGE") {
      discharge = await EncounterDetail.update({
        apiToken: controller.apiToken,
        pk: params.encounter,
        data: {
          action: "OPD_DISCHARGE",
          type: "OPD",
          patient: params.patientId,
          predischarge_condition: params.predischarge_condition,
          discharge_status: params.discharge_status,
          discharge_note: params.discharge_note,
        },
        extra: {
          division: controller.data.division,
        },
      });
    }

    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: false },
    });

    if (!discharge[1]) {
      if (params.onModInfo) {
        return params.onModInfo?.(discharge[0]?.go_to || []);
      }

      const backElm = document.querySelector(
        ".patient-layout-backward"
      ) as HTMLDivElement;

      if (backElm) {
        backElm.click();
      }

      return;
    }
  } else if (params.type === "BIND_ID") {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: true },
    });

    const result = await ConnectUserWithPatient.post({
      apiToken: controller.apiToken,
      data: {
        patient: params.patientId,
        user_patient: Number(params.ihid),
        // user: Number(params.ihid)
      },
      extra: {
        division: controller.data.division,
      },
    });

    console.log("BIND_ID", controller);

    if (result[1]) {
      controller.setState({
        loadingStatus: { ...state.loadingStatus, [params.card]: false },
        errorMessage: { ...state.errorMessage, [params.card]: result[1] },
      });
    } else {
      params.onSuccess?.();
      controller.setState(
        {
          loadingStatus: { ...state.loadingStatus, [params.card]: false },
          errorMessage: { ...state.errorMessage, [params.card]: null },
        },
        () => {
          controller.setState(
            {
              SetPatientSequence: {
                ...state.SetPatientSequence,
                sequenceIndex: "SearchOrNew",
              },
            },
            () =>
              controller.handleEvent({
                message: "RunSequence" as any,
                params: {
                  ...params,
                  sequence: "SetPatient",
                  action: "select",
                  patient: state.SetPatientSequence.patient,
                },
              })
          );
        }
      );
    }

    return;
  }

  if (!discharge[1]) {
    if (params.admitRoom?.id) {
      await controller.handleEvent({
        message: "HandleSelectAdmitEncounter" as any,
        params: {
          encounterId: params.encounter || null,
          admitRoom: {
            room_item_id: params.admitRoom.id,
          },
        },
      });
    }

    controller.setState(
      {
        errorMessage: { ...state.errorMessage, [params.card]: null },
        dischargeDoctor: StateInitial.dischargeDoctor,
        approve_by: null,
      },
      () =>
        controller.handleEvent({
          message: "HandleRefreshEncounter" as any,
          params: {},
        })
    );

    params.onSuccess?.();

    return discharge;
  } else {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: discharge[1] },
    });

    return discharge;
  }
};

export const HandleRefreshEncounter: Handler = async (controller, params) => {
  const state = controller.getState();

  const encounter = await EncounterDetail.retrieve({
    pk: state.selectedEncounter?.id,
    apiToken: controller.apiToken,
  });

  const emr = await EncounterMedicalRecordList.list({
    apiToken: controller.apiToken,
    pk: state.selectedEncounter?.id,
  });

  controller.setState({
    selectedEncounter: encounter[0],
    selectedEmr: emr?.[0]?.items?.[0],
    openDischargeReassessment:
      typeof params.isOpen === "boolean"
        ? params.isOpen
        : state.openDischargeReassessment,
  });
};

export const HandleDischargeConsult: Handler = async (controller, params) => {
  const state = controller.getState();

  const [r, e, n] = await PatientAppointmentFinishConsult.post({
    data: { patient_appointment: params?.patientAppointment?.id },
    apiToken: controller.apiToken,
    extra: { division: controller.data?.division },
  });

  if (e) {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: e },
    });
  } else {
    const refreshConsult = await PatientAppointmentTelePharmacyList.list({
      apiToken: controller.apiToken,
    });

    const ShippingOrder = refreshConsult?.[0].items?.filter(
      (item: any) => item.encounter === params?.patientAppointment?.encounter
    );

    controller.setState({
      selectedShippingOrder: ShippingOrder,
    });
  }
};

export const GetClassify: Handler = async (controller, params) => {
  const state = controller.getState();
  let classify = {
    division: params?.divisionId,
  };
  const [classifyListRes, classifyListError] = await ClassifyList.get({
    params: classify,
    apiToken: controller.apiToken,
  });
  console.log("classifyListRes: ", classifyListRes);
  controller.setState({ classifyList: classifyListRes?.classify || [] });
};

export const HandleAddMessageProblemDPO: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  if (params?.action === "createProblemList") {
    const [r, e, n] = await ProblemListList.create({
      data: {
        patient: params.patient,
        message: state.messageProblemDPO,
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      messageProblemDPO: "",
    });

    if (e) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: e },
      });
    } else {
      HandleAddMessageProblemDPO(controller, {
        action: "getProblemList",
        patient: params.patient,
      });
    }
  } else if (params?.action === "editProblemList") {
    const [r, e, n] = await ProblemListDetail.update({
      pk: params.pk,
      data: {
        patient: params.patient,
        message: params.message,
        is_active: params.is_active,
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      messageProblemDPO: "",
    });

    if (e) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: e },
      });
    } else {
      HandleAddMessageProblemDPO(controller, {
        action: "getProblemList",
        patient: params.patient,
      });
    }
  } else if (params?.action === "getProblemList") {
    const problemListDPO = await ProblemListList.list({
      params: {
        patient: params.patient,
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      problemListDPO: problemListDPO[0],
    });
  }
};

export const GetUsername: Handler = async (controller, params) => {
  if (!!params.doctor && !params.pk) {
    const [doctor] = await DoctorDetail.retrieve({
      apiToken: controller.apiToken,
      pk: params.doctor,
    });

    params.pk = doctor?.user;
  }

  const [r, e, n] = await UserListM.list({
    apiToken: controller.apiToken,
    params: { ...params, limit: 99999 },
  });

  if (e) {
    console.log("GetUsernam  error", e);
    return [[], e];
  }

  let userData = {
    userId: r.items[0].id,
    username: r.items[0].username,
    // password: state.django?.user?.id === r.items[0].id ? "12345678" : "",
    password: "",
  };
  // console.log("user django: ", state.django?.user, r.items[0])
  controller.setState({ dischargeDoctor: userData });
};

export const GetDoctorSearch: Handler = async (controller, params) => {
  const [r, e, n] = await DoctorList.list({
    apiToken: controller.apiToken,
    params: { ...params, limit: 99999 },
  });
  if (e) {
    console.log("doctor search list error", e);
    return [[], e];
  }
  return [r.items, e];
};

export const CheckUserLogin: Handler = async (controller, data) => {
  const state = controller.getState();

  const [r, e, n] = await UserLoginView.post({
    apiToken: controller.apiToken,
    data: { ...data },
  });

  if (e) {
    controller.setState({
      errorMessage: { ...state.errorMessage, ["CardPatientDischarge"]: e },
      loginVia: false,
    });
    return false;
  }

  controller.setState({
    loginVia: true,
  });
};
