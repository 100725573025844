import WasmController from "react-lib/frameworks/WasmController";
import * as SetPatient from "./sequence/SetPatient";
import * as CreateUpdateEncounter from "./sequence/CreateUpdateEncounter";
import * as SetCoverage from "./sequence/SetCoverage";

export type State = {} & SetPatient.State &
  CreateUpdateEncounter.State &
  SetCoverage.State;

export const StateInitial: State = {
  ...SetPatient.StateInitial,
  ...CreateUpdateEncounter.StateInitial,
  ...SetCoverage.StateInitial,
};

export type Event =
  | SetPatient.Event
  | CreateUpdateEncounter.Event
  | SetCoverage.Event;

export type Data = {
  division?: number;
  device?: number;
} & SetPatient.Data &
  CreateUpdateEncounter.Data &
  SetCoverage.Data;

export const DataInitial = {
  ...SetPatient.DataInitial,
  ...CreateUpdateEncounter.DataInitial,
  ...SetCoverage.DataInitial,
};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const DOCTOR_ORDER_ACTION_MAP = {
  appoiment_or_cancel: ["APPOINTMENT", "CANCEL"],
  pending: ["PENDING"],
  performed: ["PERFORMED"],
};

export const DOCTOR_ORDER_MAPPING_ICON: any = {
  drug: {
    appoiment_or_cancel: "./static/images/order_images/medication.png",
    pending: "./static/images/order_images/medication_pending.png",
    performed: "./static/images/order_images/medication_performed.png",
  },
  treatmentorder: {
    appoiment_or_cancel: "./static/images/order_images/treatment.png",
    pending: "./static/images/order_images/treatment_pending.png",
    performed: "./static/images/order_images/treatment_performed.png",
  },
  centrallaborder: {
    appoiment_or_cancel: "./static/images/order_images/lab.png",
    pending: "./static/images/order_images/lab_pending.png",
    performed: "./static/images/order_images/lab_performed.png",
  },
  doctorconsultorder: {
    appoiment_or_cancel: "./static/images/order_images/consult.png",
    pending: "./static/images/order_images/consult_pending.png",
    performed: "./static/images/order_images/consult_performed.png",
  },
  imagingorder: {
    appoiment_or_cancel: "./static/images/order_images/xray.png",
    pending: "./static/images/order_images/xray_pending.png",
    performed: "./static/images/order_images/xray_performed.png",
  },
  operatingorder: {
    appoiment_or_cancel: "./static/images/order_images/or.png",
    pending: "./static/images/order_images/or_pending.png",
    performed: "./static/images/order_images/or_performed.png",
  },
  anesthesiaorder: {
    appoiment_or_cancel: "./static/images/order_images/ane.png",
    pending: "./static/images/order_images/ane_pending.png",
    performed: "./static/images/order_images/ane_performed.png",
  },
  supply: {
    appoiment_or_cancel: "./static/images/order_images/supply.png",
    pending: "./static/images/order_images/supply_pending.png",
    performed: "./static/images/order_images/supply_performed.png",
  },
  admitorder: {
    appoiment_or_cancel: "./static/images/order_images/admission.png",
    pending: "./static/images/order_images/admission_pending.png",
    performed: "./static/images/order_images/admission_performed.png",
  },
};
