import WasmController from "react-lib/frameworks/WasmController";
// apis
import FoodOrderList from "issara-sdk/apis/FoodOrderList_apps_FOD";
import EncounterPatientListOptimized from "issara-sdk/apis/EncounterPatientListOptimized_core";
import EncounterMedicalRecordList from "issara-sdk/apis/EncounterMedicalRecordList_core";
import QueueWardList from "issara-sdk/apis/QueueWardList_apps_ADM";
import moment from "moment";
import { formatDate } from "react-lib/utils/dateUtils";
import RegularMealList from "issara-sdk/apis/RegularMealList_apps_FOD";
import getPdfMake from "react-lib/appcon/common/pdfMake";
import FormFoodReport from "react-lib/apps/HISV3/FOD/FormFoodReport";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // common
  selectedEmr?: any;

  // sequence
  FoodListSequence?: {
    sequenceIndex?: string | null;

    // data
    filterQueue?: any;
    foodData?: any;
    items?: any[];
    next?: any;
    previous?: any;
    total?: number;
    loadingData?: boolean;
  } | null;
};

export const StateInitial: State = {
  // sequence
  FoodListSequence: {
    sequenceIndex: null,

    // data
    filterQueue: {
      date: formatDate(moment()),
      meal: "",
    },
    foodData: [],
    items: [],
    next: null,
    previous: null,
    total: 0,
    loadingData: false,
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const FetchData: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["divisionIpd", {}], ["regularMeal", {}]],
    },
  });

  const [response] = await FoodOrderList.list({
    apiToken: controller.apiToken,
    params: { emr: state.selectedEmr?.id, limit: 200 },
  });

  controller.setState({
    FoodListSequence: {
      sequenceIndex: "PrintReport",
      filterQueue: {
        division: controller.data.division,
        date: formatDate(moment()),
        meal: "",
      },
      ...response,
    },
  });

  if (!params.orderOnly) {
    PrintReport(controller, { action: "getFoodDate" });
  }
};

export const PrintReport: Handler = async (controller, params) => {
  let state = controller.getState();

  if (params?.action === "getFoodDate") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        ["CardFoodReport_Search"]: "LOADING",
      },
      FoodListSequence: {
        ...state.FoodListSequence,
        loadingData: true
      }
    });

    const encounterList = await EncounterPatientListOptimized.get({
      apiToken: controller.apiToken,
      params: {
        // from_date: state.FoodListSequence?.filterQueue?.date || null,
        // to_date: state.FoodListSequence?.filterQueue?.date || null,
        patient__hn: state.FoodListSequence?.filterQueue?.hn || null,
        patient_name:
          state.FoodListSequence?.filterQueue?.fristName ||
          state.FoodListSequence?.filterQueue?.lastName ||
          null,
        division: state.FoodListSequence?.filterQueue?.division || null,
        exclude_canceled: true,
      },
      extra: {
        division: controller.data.division,
      },
    });

    const selectEncounter = encounterList[0]?.items.filter(
      (item: any) => item?.status !== "FINISHED"
    );

    const getQueueWard = await QueueWardList.get({
      apiToken: controller.apiToken,
      params: {
        exclude_estimate_field: true,
        division: controller.data.division,
        limit: 20,
        offset: 0,
      },
      extra: {
        device: (controller.data as any).device,
        division: controller.data.division,
      },
    });

    const promiseArray = (selectEncounter || []).flatMap(
      async (item: any, index: any) => {
        const emr = await EncounterMedicalRecordList.list({
          apiToken: controller.apiToken,
          pk: item.pk,
        });

        const emrId = emr[0]?.items[0]?.id

        const getRoom = getQueueWard[0]?.items?.filter(
          (item: any) => item.emr === emrId
        );

        const foodOrderList = await FoodOrderList.list({
          apiToken: controller.apiToken,
          params: { emr: emrId, limit: 200 },
        });

        return foodOrderList[0]?.items?.length !== 0
          ? foodOrderList?.[0]?.items?.map((itm: any) => ({
            roomList: getRoom?.[0],
            foodOrderList: itm,
          }))
          : [
            {
              roomList: getRoom?.[0],
              foodOrderList: {},
            },
          ];
      }
    );

    const foodData: any[] = await Promise.all(promiseArray);
    state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        ["CardFoodReport_Search"]: "SUCCESS",
      },
      FoodListSequence: {
        ...state.FoodListSequence,
        foodData: foodData,
        loadingData: false
      },
    });
  } else if (params?.action === "printReport") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        ["CardFoodReport_Print"]: "LOADING",
      },
    });

    const pdfMake = await createPDF(controller, params);
    pdfMake.open();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        ["CardFoodReport_Print"]: "SUCCESS",
      },
    });
  }

  if (!state.FoodListSequence) {
    return;
  }
};

const createPDF: Handler = async (controller, params) => {
  const state = controller.getState();

  var divisionName = controller?.data?.masterData?.divisionIpd?.filter(
    (item: any) => item?.id === state.FoodListSequence?.filterQueue?.division
  );

  var mealStatus = state.FoodListSequence?.filterQueue?.meal;

  const data = Object.assign({
    date: moment(state.FoodListSequence?.filterQueue?.date, "DD/MM/YYYY")
      .locale("th")
      .format("DD MMMM YYYY"),
    division: divisionName[0]?.name,
    reportDetail: state.FoodListSequence?.filterQueue,
    foodData: {
      foodMData:
        mealStatus === 1 || mealStatus === "" ? params?.data?.foodMData : [],
      foodLData:
        mealStatus === 2 || mealStatus === "" ? params?.data?.foodLData : [],
      foodDData:
        mealStatus === 3 || mealStatus === "" ? params?.data?.foodDData : [],
      foodBData:
        mealStatus === 4 || mealStatus === "" ? params?.data?.foodBData : [],
    },
  });

  let docDef: any = { content: [] };

  docDef = FormFoodReport(data);

  return (await getPdfMake()).createPdf(docDef);
};
