import React, { useCallback, useMemo, useState } from "react";
import { Input } from "semantic-ui-react";
// Frameworks
import { Table } from "react-lib/frameworks/Table";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// UX
import CardReturnDrugWorkingUX from "./CardReturnDrugWorkingUX";

// Common
import { ModConfirm, ModInfo, ErrorMessage } from "react-lib/apps/common";

import moment from "moment";
import EditorColumn from "react-lib/appcon/common/EditorColumn";

const CARD_NAME = "CardStockTransferOrder";

// Types
type CardReturnDrugWorkingProps = {
	setProp: (key: string, value: any, callback?: Function) => any;
	onEvent: (e: any) => any;
	// seq
	runSequence: any;
	DrugReturnRequestHistorySequence?: any;
	// CommonInterface
	errorMessage?: Record<string, any>;
	buttonLoadCheck?: Record<string, any>;
};

const CardReturnDrugWorking = (props: CardReturnDrugWorkingProps) => {
	const [openModConfirmApprove, setOpenModConfirmApprove] =
		useState<boolean>(false);
	const [openModConfirmReject, setOpenModConfirmReject] = useState(false);

	// Use Memo
	const workItems = useMemo(() => {
		return props.DrugReturnRequestHistorySequence?.selectedBox?.items?.map(
			(item: any, idx: any) => ({
				...item,
				// provide_quantity_input: (
				//   <Input
				//     value={item.provide_quantity}
				//     onChange={(e) => {
				//       props.setProp(
				//         `DrugReturnRequestHistorySequence.selectedBox.items.${idx}.provide_quantity`,
				//         e.target.value
				//       );
				//     }}
				//     style={{ border: "none" }}
				//   />
				// ),
				provide_quantity_input: (
					<div style={{ margin: "-7px -5px" }}>
						{item.items?.map((acc: any, index: number) => (
							<div
								style={{
									borderBottom: index === 0 ? "solid 1px rgba(0,0,0,0.05)" : "",
									padding: "7px",
								}}
							>
								{acc?.quantity * -1}
							</div>
						))}
					</div>
				),
				total_amount: (
					<div style={{ margin: "-7px -5px" }}>
						{item.items?.map((acc: any, index: number) => {
							if (index === 0)
								item.items[index]["sum"] = acc?.quantity
							else
								item.items[index]["sum"] = acc?.quantity + item.items[index - 1]["sum"]
							console.log(item.items[index])
							return (
								<div
									style={{
										borderBottom:
											index === 0 ? "solid 1px rgba(0,0,0,0.05)" : "",
										padding: "7px",
									}}
								>
									{acc?.sum * -1 || "-"}
								</div>
							);
						})}
					</div>
				),
				lot_no: (
					<div style={{ margin: "-7px -5px" }}>
						{item.items?.map((acc: any, index: number) => (
							<div
								style={{
									borderBottom: index === 0 ? "solid 1px rgba(0,0,0,0.05)" : "",
									padding: "7px",
									textAlign: "center",
								}}
							>
								{acc?.lot?.mfc_no}
							</div>
						))}
					</div>
				),
				expired_date: (
					<div style={{ margin: "-7px -5px" }}>
						{item.items?.map((acc: any, index: number) => (
							<div
								style={{
									borderBottom: index === 0 ? "solid 1px rgba(0,0,0,0.05)" : "",
									padding: "7px",
									textAlign: "center",
								}}
							>
								{moment(acc?.lot?.exp_datetime, "YYYY-MM-DD HH:mm").format(
									"DD/MM/YYYY"
								)}
							</div>
						))}
					</div>
				),
				quantity: (
					<div style={{ margin: "-7px -5px" }}>
						{item.items?.map((acc: any, index: number) => (
							<div
								style={{
									borderBottom: index === 0 ? "solid 1px rgba(0,0,0,0.05)" : "",
									padding: "7px",
								}}
							>
								{acc?.lot?.get_quantity || "-"}
							</div>
						))}
					</div>
				),
				quantity_restock: props.DrugReturnRequestHistorySequence?.selectedBox?.status_name === "DELIVERED" ? (
					<EditorColumn
						value={item.quantity_restock !== undefined ?  item.quantity_restock.toString() : "0"}
						backgroundColor={"#ffffc3"}
						align="left"
						onDataChanged={(value: string) =>
							props.runSequence({
								sequence: "DrugReturnRequestHistory",
								action: "SELECTBOX_EDIT_VALUE",
								selectedItemId: item.id,
								name: "quantity_restock",
								card: CARD_NAME,
								value,
							})
						}
					/>
				) : item.quantity_restock,
				quantity_discard: props.DrugReturnRequestHistorySequence?.selectedBox?.status_name === "DELIVERED" ? (
					<EditorColumn
						value={item.quantity_discard !== undefined ?  item.quantity_discard.toString() : "0"}
						backgroundColor={"#ffffc3"}
						align="left"
						onDataChanged={(value: string) =>
							props.runSequence({
								sequence: "DrugReturnRequestHistory",
								action: "SELECTBOX_EDIT_VALUE",
								selectedItemId: item.id,
								name: "quantity_discard",
								card: CARD_NAME,
								value,
							})
						}
					/>
				) : item.quantity_discard,
				reason: props.DrugReturnRequestHistorySequence?.selectedBox?.status_name === "DELIVERED" ? (
					<EditorColumn
						value={item.reason !== undefined ?  item.reason : ""}
						backgroundColor={"#ffffc3"}
						align="left"
						onDataChanged={(value: string) =>
							props.runSequence({
								sequence: "DrugReturnRequestHistory",
								action: "SELECTBOX_EDIT_VALUE",
								selectedItemId: item.id,
								name: "reason",
								card: CARD_NAME,
								value,
							})
						}
					/>
				) : item.reason
			})
		);
	}, [
		props.DrugReturnRequestHistorySequence,
		props.DrugReturnRequestHistorySequence?.selectedBox,
	]);

	// status_name
	// Use Callback
	//   const handleGetTopTheadThProps = useCallback(
	//     (_state: any, _rowInfo: any, column: any, _instance: any) => {
	//       return {
	//         style: ["คลังผู้ให้โอนสินค้า", "จำนวนจ่าย จากผู้ให้สินค้า"].includes(
	//           column?.Header || ""
	//         )
	//           ? {}
	//           : { border: "none", backgroundColor: "white" },
	//       };
	//     },
	//     []
	//   );

	const handleGetTheadThProps = useCallback(
		(_state: any, _rowInfo: any, column: any, _instance: any) => {
			return {
				style: ["เหตุผล",].includes(column?.Header || "")
					? { backgroundColor: "#2185D0" }
					: {},
			};
		},
		[]
	);

	// Handler
	// Mod
	const handleCloseModConfirmApprove = () => {
		setOpenModConfirmApprove(false);
	};

	const handleCloseModConfirmReject = () => {
		setOpenModConfirmApprove(false);
	};

	const handleApproveClick = () => {
		props.runSequence({
			sequence: "DrugReturnRequestHistory",
			action: isApproved ? "UNAPPROVE":"APPROVE",
			card: CARD_NAME,
		});
	};

	const handleDeliverClick = () => {
		props.runSequence({
			sequence: "DrugReturnRequestHistory",
			action: "DELIVER",
			card: CARD_NAME,
		});
	};

	const handleReceiveClick = () => {
		props.runSequence({
			sequence: "DrugReturnRequestHistory",
			action: "RECEIVE",
			card: CARD_NAME,
		});
	};

	const handleRejectClick = () => {
		props.runSequence({
			sequence: "DrugReturnRequestHistory",
			action: "REJECT",
			card: CARD_NAME,
		});
	};

	const handleEditClick = () => {
		props.runSequence({
			sequence: "DrugReturnRequestHistory",
			action: "EDIT",
			card: CARD_NAME,
		});
	};

	const isApproved  = useMemo(() => {
		if (props.DrugReturnRequestHistorySequence?.selectedBox?.status_name === "APPROVED")
			return true;
		return false;
	}, [props.DrugReturnRequestHistorySequence?.selectedBox]);

	const showApproveBox = useMemo(() => {
		if (props.DrugReturnRequestHistorySequence?.selectedBox?.status_name === "REQUESTED")
			return true;
		if (props.DrugReturnRequestHistorySequence?.selectedBox?.status_name === "APPROVED")
			return true;
		else return false;
	}, [props.DrugReturnRequestHistorySequence?.selectedBox]);

	const showRejectBox = useMemo(() => {
		if (
			props.DrugReturnRequestHistorySequence?.selectedBox?.status_name === "REQUESTED" ||
			props.DrugReturnRequestHistorySequence?.selectedBox?.status_name === "DELIVERED"
		)
			return true;
		else return false;
	}, [props.DrugReturnRequestHistorySequence?.selectedBox]);

	const showDeliverBox = useMemo(() => {
		if (props.DrugReturnRequestHistorySequence?.selectedBox?.status_name === "APPROVED")
			return true;
		else return false;
	}, [props.DrugReturnRequestHistorySequence?.selectedBox]);

	const showReceiveBox = useMemo(() => {
		if (props.DrugReturnRequestHistorySequence?.selectedBox?.status_name === "DELIVERED")
			return true;
		else return false;
	}, [props.DrugReturnRequestHistorySequence?.selectedBox]);

	console.log("CardReturnDrugWorking props: ", props)
	return (
		<div>
			<CardReturnDrugWorkingUX
				DrugReturnRequestHistorySequence={props.DrugReturnRequestHistorySequence}
				ErrorMessage={<ErrorMessage error={props?.errorMessage?.[CARD_NAME]} />}
				// data
				items={workItems}
				// table
				getTheadThProps={handleGetTheadThProps}
				// getTrProps={(state: any, rowInfo: any, column: any, instance: any) => ({
				//   onClick: () => {
				//     let item = rowInfo?.original;
				//     props.runSequence({
				//       sequence: "DrugReturnRequestHistory",
				//       action: "GET_LOTS_DETAIL",
				//       id: item?.id,
				//       index: rowInfo?.index,
				//     });
				//   },
				// })}
				// Element
				// TopTable={
				//   <Table
				//     headers=",,,,,,จำนวนจ่าย จากผู้ให้สินค้า,"
				//     widths="100,,100,100,100,100,200,200"
				//     showPagination={false}
				//     minRows={0}
				//     resizable={false}
				//     style={{ border: "none" }}
				//     // callback
				//     getTheadThProps={handleGetTopTheadThProps}
				//     // Element
				//     NoDataComponent={() => null}
				//   />
				// }
				buttonApprove={
					<ButtonLoadCheck
						// function
						setProp={props.setProp}
						onClick={handleApproveClick}
						// data
						paramKey={`${CARD_NAME}`}
						buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_NAME}`]}
						// config
						color={"yellow"}
						title={isApproved ? "Unapprove":"Approve"}
						style={{
							display: showApproveBox ? "flex" : "none",
							margin: "0px 4px 0px 0px",
						}}
					/>
				}
				buttonReject={
					<ButtonLoadCheck
						// function
						setProp={props.setProp}
						onClick={handleRejectClick}
						// data
						paramKey={`${CARD_NAME}`}
						buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_NAME}`]}
						// config
						color={"red"}
						title={"Reject"}
						style={{
							display: showRejectBox ? "flex" : "none",
							margin: "0px 4px 0px 0px",
						}}
					/>
				}
				buttonDeliver={
					<ButtonLoadCheck
						// function
						setProp={props.setProp}
						onClick={handleDeliverClick}
						// data
						paramKey={`${CARD_NAME}`}
						buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_NAME}`]}
						// config
						color={"green"}
						title={"Deliver"}
						style={{
							display: showDeliverBox ? "flex" : "none",
							margin: "0px 4px 0px 0px",
						}}
					/>
				}
				buttonReceive={
					<ButtonLoadCheck
						// function
						setProp={props.setProp}
						onClick={handleReceiveClick}
						// data
						paramKey={`${CARD_NAME}`}
						buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_NAME}`]}
						// config
						color={"green"}
						title={"Receive"}
						style={{
							display: showReceiveBox ? "flex" : "none",
							margin: "0px 4px 0px 0px",
						}}
					/>
				}
				buttonEdit={
					// <ButtonLoadCheck
					//   // function
					//   setProp={props.setProp}
					//   onClick={handleEditClick}
					//   // data
					//   paramKey={`${CARD_NAME}`}
					//   buttonLoadCheck={
					//     props.buttonLoadCheck?.[
					//       `${CARD_NAME}`
					//     ]
					//   }
					//   // config
					//   color={"yellow"}
					//   title={"Edit"}
					// />
					<></>
				}
			/>

			<ModConfirm
				openModal={openModConfirmApprove}
				titleName="อนุมัติ คำขอสินค้า"
				titleColor="green"
				size="mini"
				denyButtonColor="red"
				denyButtonText="ยกเลิก"
				approveButtonColor="blue"
				approveButtonText="ตกลง"
				content={
					<div
						style={{
							padding: "0.5rem 0",
							lineHeight: 1.5,
							margin: "-1.5rem 0",
						}}
					>
						<div>โปรดระบุเหตุผล</div>
						<div>
							เนื่องจากจ่ายสินค้าไม่ครบตามจำนวนขอ
							<span style={{ color: "red" }}>*</span>
						</div>
						<Input style={{ width: "100%", marginTop: "1rem" }} />
					</div>
				}
				// onApprove={handleConfirmRemove}
				onDeny={handleCloseModConfirmApprove}
				onCloseWithDimmerClick={handleCloseModConfirmApprove}
			/>

			<ModConfirm
				openModal={openModConfirmReject}
				titleName="ปฏิเสธ คำขอสินค้า"
				titleColor="red"
				size="mini"
				denyButtonColor="red"
				denyButtonText="ยกเลิก"
				approveButtonColor="blue"
				approveButtonText="ตกลง"
				content={
					<div
						style={{
							padding: "0.5rem 0",
							lineHeight: 1.5,
							margin: "-1.5rem 0",
						}}
					>
						<div>
							โปรระบุเหตุผล ปฏิเสธคำขอ
							<span style={{ color: "red" }}>*</span>
						</div>
						<Input style={{ width: "100%", marginTop: "1rem" }} />
					</div>
				}
				// onApprove={handleConfirmRemove}
				onDeny={handleCloseModConfirmReject}
				onCloseWithDimmerClick={handleCloseModConfirmReject}
			/>
		</div>
	);
};

export default React.memo(CardReturnDrugWorking);
