import React, {useState, useRef, useCallback} from 'react'
import Webcam from "react-webcam";
import {
  Button,
  Modal,
  Icon,
} from "semantic-ui-react";

const videoConstraints = {
  width: 640,
  height: 480,
  facingMode: "user"
};

const WebcamComponent = ({ open, onClose , setNewImage}) => {

  const [isCapture, setIsCapture] = useState(false);
  const [imageSrc, setImageSrc] = useState(null)

  const webcamRef = useRef(null);
  const capture = useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImageSrc(imageSrc)
      setIsCapture(true)
    },
    [webcamRef]
  );

  const confirmImage = () => {
    setNewImage(imageSrc)
    onClose()
  }
  
  return (
    <Modal open={open} basic size="tiny">
      {!isCapture ? (
        <>
          <Modal.Header style={{textAlign:"center"}} >Take a photo</Modal.Header>
          <Modal.Description style={{ textAlign: "center" }}>
            <Webcam
              audio={false}
              width={640}
              height={480}
              
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
          </Modal.Description>
          <Modal.Actions style={{ textAlign: "center" }}>
            <Button color="green" onClick={capture} inverted>
              <Icon name="camera" /> Capture photo
            </Button>
            <Button color="red" onClick={onClose} inverted>
              <Icon name="cancel" /> Cancel
            </Button>
          </Modal.Actions>
        </>
      ) : (
        <>
          <Modal.Header style={{textAlign:"center"}} >Please confirmed this Image</Modal.Header>
          <Modal.Description style={{ textAlign: "center" }}>
            <img src={imageSrc} />
          </Modal.Description>

          <Modal.Actions style={{ textAlign: "center" }}>
            <Button color="green" onClick={confirmImage} inverted>
              <Icon name="check" /> OK
            </Button>
            <Button color="red" onClick={()=>{ setIsCapture(false)}} inverted>
              <Icon name="cancel" /> Cancel
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
};

export default WebcamComponent ;
