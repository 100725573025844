import WasmController from "react-lib/frameworks/WasmController";

import MedicationErrorList from "issara-sdk/apis/MedicationErrorList_apps_TPD";
import MedicationErrorDetailM from "issara-sdk/apis/MedicationErrorDetail_apps_TPDM";
import MedicationErrorDetail from "issara-sdk/apis/MedicationErrorDetail_apps_TPD";
import UserList from "issara-sdk/apis/UserList_users";
import MedicationErrorTypeList from "issara-sdk/apis/MedicationErrorTypeList_apps_TPD";
import MedicationErrorCauseList from "issara-sdk/apis/MedicationErrorCauseList_apps_TPD";
import MedicationErrorTypeDetailList from "issara-sdk/apis/MedicationErrorTypeDetailList_apps_TPD";

import moment from "moment";
import { PDFDocument } from "pdf-lib";

// Form
import FormFeedbackReport from "../FormFeedbackReport";

// Utils
import {
	adToBe,
	beToAd,
	formatDate,
	formatDatetime,
} from "react-lib/utils/dateUtils";
import getPdfMake from "react-lib/appcon/common/pdfMake";
import { base64toBlob } from "react-lib/apps/HISV3/common/CommonInterface";

// DrugOrderQueueList_apps_TPD
export type State = {
	// CommonInterface
	django?: any;
	selectedPatient?: any;
	selectedDrugOrderWorking?: any;
	masterOptions?: any;
	buttonLoadCheck?: any;
	searchedItemListWithKey?: any;
	MedErrorListSequence?: {
		// Medication Error List
		sequenceIndex?: string | null;
		medErrorList?: any[];
		filterMedError?: Partial<{
			status: number[];
			division: string;
			user: string;
			start_date: string;
			end_date: string;
			type: string[];
			stakeholder: number;
			stakeholder_name: string;
		}>;
		medErrorWorking?: Record<string, any>;
		medErrorStatistics?: Partial<{
			open: true,
			start_date: string,
			end_date: string,
			items: any[],
		}>
	} | null;
};

export const StateInitial: State = {
	MedErrorListSequence: null,
};

export type Event =
	| { message: "RunSequence"; params: {} }
	| { message: "GetMasterData"; params: {} };

export type Data = {
	user?: number;
	division?: number;
};

export const DataInitial = {};

export const MED_ERROR_CATEGORY = [
	"A = เหตุการณ์ที่มีศักภาพที่จะเกิดความผิดพลาด",
	"B = เกิดข้อผิดพลาดแล้ว แต่ไม่ถึงผู้ป่วย",
	"C = เกิดข้อผิดพลาด ถึงผู้ป่วย แต่ไม่มีอาการ",
	"D = เกิดข้อผิดพลาด ถึงผู้ป่วย อยู่ในช่วงของการเฝ้าระวังว่าเกิดอันตรายต่อจนต้องการ Intervention เพื่อแก้ปัญหา (E) หรือไม่เกิดอันตรายต่อผู้ป่วย (C)",
	"E = เกิดข้อผิดพลาด ถึงผุ้ป่วย เกิดอันตรายต่อผู้ป่วยชั่วคราว และต้องการ intervention เพื่อแก้ปัญหา",
	"F = เกิดข้อผิดพลาด ถึงผุ้ป่วย เกิดอันตรายต่อผู้ป่วยชั่วคราว และต้องการ intervention เพื่อแก้ปัญหาจนทำให้ระยะเวลาที่อยู่ รพ.ยาวนานขึ้นกว่าเดิม",
	"G = เกิดข้อผิดพลาด ถึงผุ้ป่วย เกิดอันตรายต่อผู้ป่วยอย่างถาวร",
	"H = เกิดข้อผิดพลาด ถึงผุ้ป่วย เกิดอันตรายต่อผู้ป่วยอยู่ในระดับที่รุนแรงถึงต้องช่วยชีวิต",
	"I = เกิดข้อผิดพลาด ถึงผุ้ป่วย เกิดอันตายจนทำให้ผู้ป่วยเสียชีวิต",
];

type Handler = (
	controller: WasmController<State, Event, Data>,
	params?: any
) => any;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const Start: Handler = async (controller, params) => {
	let state = controller.getState();
	if (!state.MedErrorListSequence) return;

	const status = state.masterOptions?.medErrorStatus;

	if (!status) {
		// Master data
		await controller.handleEvent({
			message: "GetMasterData",
			params: {
				masters: [
					["medErrorStatus", {}],
					["divisionDrug", {}],
					["medErrorType", {}],
				],
			},
		});
	}


	const options = (controller.getState() as any).masterOptions;
	// const status = options.medErrorStatus.flatMap((option: any) =>
	//  ["INCOMPLETE", "COMPLETED"].includes(option.text) ? [option.value] : []
	// );
	// const type = options.medErrorType.flatMap((option: any) =>
	//  ["Prescription error", "Dispensing error", "Transcribing error"].includes(
	//    option.text
	//  )
	//    ? [option.value]
	//    : []
	// );
	controller.setState(
		{
			MedErrorListSequence: {
				...state.MedErrorListSequence,
				sequenceIndex: "SearchMedError",
				filterMedError: {
					division: "ALL",
					start_date: formatDate(moment().add(-6, "days")),
					end_date: formatDate(moment()),
					...(params.filter || {}),
					...(state.MedErrorListSequence?.filterMedError || {}),
				},
			},
		},
		() => {
			controller.handleEvent({
				message: "RunSequence",
				params: { ...params, action: params.action || "search", byCorrective: params.byCorrective },
			});
		}
	);
};

/* ------------------------------------------------------ */

/*                     SearchMedError                     */

/* ------------------------------------------------------ */
export const SearchMedError: Handler = async (controller, params) => {
	const state = controller.getState();

	if (!state.MedErrorListSequence) {
		return;
	}

	if (params.action === "change") {
		controller.setState({
			MedErrorListSequence: {
				...state.MedErrorListSequence,
				filterMedError: { ...params.data },
			},
		});
	} else if (params.action === "search") {
		HandleSearch(controller, params);
	} else if (params.action === "statistics_search") {
		HandleStatisticsSearch(controller, params);
	} else if (params.action === "edit") {
		controller.setState(
			{
				MedErrorListSequence: {
					...state.MedErrorListSequence,
					sequenceIndex: "EditMedError",
				},
			},
			() => params.callback?.()
		);
	} else if (params.action === "delete") {
		controller.setState(
			{
				MedErrorListSequence: {
					...state.MedErrorListSequence,
					sequenceIndex: "SaveMedError",
				},
			},
			() => controller.handleEvent({ message: "RunSequence", params })
		);
	} else if (params.action === "export_excel") {
		HandleExportExcel(controller, params);
	} else if (params.action === "export_excel_statistics") {
		HandleExportExcelStatistics(controller, params);
	} else if (params.action === "print") {
		HandlePrint(controller, params);
	}
};

/* ------------------------------------------------------ */

/*                  SearchMedError Action                 */

/* ------------------------------------------------------ */
const HandleSearch: Handler = async (controller, params) => {
	const state = controller.getState();

	controller.setState({
		buttonLoadCheck: {
			...state.buttonLoadCheck,
			[`${params.card}_${params.action}`]: "LOADING",
		},
	});

	const medError = await GetMedicationErrorList(controller, {
		...params,
		filter: state.MedErrorListSequence?.filterMedError,
	});

	controller.setState({
		MedErrorListSequence: {
			...state.MedErrorListSequence,
			medErrorList: medError[0]?.items || [],
		},
		buttonLoadCheck: {
			...state.buttonLoadCheck,
			[`${params.card}_${params.action}`]: "SUCCESS",
		},
	});
};

const HandleStatisticsSearch: Handler = async (controller, params) => {
	const state = controller.getState()

	controller.setState({
		buttonLoadCheck: {
			...state.buttonLoadCheck,
			[`${params.card}_${params.action}`]: "LOADING",
		},
	});

	const medError = await GetMedicationErrorList(controller, {
		...params,
		filter: state.MedErrorListSequence?.medErrorStatistics,
	});

	let items: any[] = medError[0]?.items || []

	items = items.reduce((result, item) => {
		const index = result.findIndex(
			(acc: any) =>
				acc.stakeholder === item.stakeholder &&
				acc.type === item.type &&
				acc.drug_code === item.drug_code &&
				acc.drug_name === item.drug_name
		);

		if (index === -1) {
			result.push({ ...item, qty: 1 })
		} else {
			result[index].qty += 1
		}

		return result;
	}, []);

	controller.setState({
		MedErrorListSequence: {
			...state.MedErrorListSequence,
			medErrorStatistics: {
				...(state.MedErrorListSequence?.medErrorStatistics || {}),
				items
			}
		},
		buttonLoadCheck: {
			...state.buttonLoadCheck,
			[`${params.card}_${params.action}`]: "SUCCESS",
		},
	})
}

const HandleExportExcel: Handler = async (controller, params) => {
	const state = controller.getState();

	const medErrorList = state.MedErrorListSequence?.medErrorList || [];

	const XLSX = await import("xlsx");

	let workbook = XLSX.utils.book_new();
	workbook.SheetNames.push("Report");

	const sheet_data = [
		[
			"ลำดับ",
			"วัน-เวลาที่รายงาน",
			"รายการที่คลาดเคลื่อน",
			"ประเภท",
			"ลักษณะ",
			"สาเหตุ",
			"ผู้ที่ทำให้เกิดความผิดพลาด",
			"สถานะ",
		],
		...medErrorList.map((item, index) => [
			index + 1,
			formatDatetime(item.created),
			`[${item.drug_code || ""}][${item.drug_name || ""}]`,
			item.type_name,
			item.type_detail,
			item.cause_detail,
			item.stakeholder_name,
			item.status,
		]),
	];

	const sheet = XLSX.utils.aoa_to_sheet(sheet_data);

	workbook.Sheets["Report"] = sheet;

	const output = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });

	const url = URL.createObjectURL(
		new Blob([output], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		})
	);

	const link = document.createElement("a");
	link.href = url;
	link.download = "report.xlsx";
	link.click();
	link.remove();
};

const HandleExportExcelStatistics: Handler = async (controller, params) => {
	const state = controller.getState();

	const items = state.MedErrorListSequence?.medErrorStatistics?.items || [];

	const XLSX = await import("xlsx");

	let workbook = XLSX.utils.book_new();
	workbook.SheetNames.push("Report");

	const sheet_data = [
		[
			"ลำดับ",
			"ผู้ที่ทำให้เกิดความผิดพลาด",
			"ประเภท",
			"รายการที่คลาดเคลื่อน",
			"จำนวน",
		],
		...items.map((item, index) => [
			index + 1,
			item.stakeholder_name,
			item.type_name,
			`[${item.drug_code || ""}][${item.drug_name || ""}]`,
			item.qty,
		]),
	];

	const sheet = XLSX.utils.aoa_to_sheet(sheet_data);

	workbook.Sheets["Report"] = sheet;

	const output = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });

	const url = URL.createObjectURL(
		new Blob([output], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		})
	);

	const link = document.createElement("a");
	link.href = url;
	link.download = "report.xlsx";
	link.click();
	link.remove();
}

const HandlePrint: Handler = async (controller, params) => {
	let state = controller.getState();

	controller.setState({
		buttonLoadCheck: {
			...state.buttonLoadCheck,
			[`${params.card}_${params.action}`]: "LOADING",
		},
	});

	const medErrorCategory = state.masterOptions.medErrorCategory

	if (!medErrorCategory) {
		// Get Master
		await controller.handleEvent({
			message: "GetMasterData",
			params: {
				masters: [
					["medErrorRiskType", {}], //Risk
					["medErrorCategory", {}], //Category
				],
			},
		});
	}

	state = controller.getState();

	const pdfMake = await getPdfMake();

	const createPDFBase64 = async (data: any): Promise<string> => {
		let docDef: any = { content: [] };
		docDef = FormFeedbackReport(data);
		return new Promise(async (resolve, reject) =>
			pdfMake.createPdf(docDef).getBase64((result: any) =>
				resolve(result)))
	}

	const promiseArr = params.list.map((data: any) => {
		const categoryName = state.masterOptions.medErrorCategory.find(
			(option: any) => option.value === data.category
		)?.text;
		const message = MED_ERROR_CATEGORY.find(
			(value: string) => value.search(new RegExp(`^${categoryName}`, "g")) >= 0
		)

		const quarterDetail =
			typeof params.quarterDetail === "undefined"
				? {
					quarter: data.quarter_no,
					year: data.quarter_year,
					startDate: data.quarter_start_date
						? formatDate(moment(data.quarter_start_date))
						: "",
					endDate: data.quarter_end_date
						? formatDate(moment(data.quarter_end_date))
						: "",
				}
				: params.quarterDetail;

		return createPDFBase64({
			...data,
			category: message?.replace(" = ", ": "),
			staff: state.django?.user?.full_name || "",
			quarterDetail,
		});
	})

	const pdfBase64 = await Promise.all(promiseArr)

	const pdfDoc = await PDFDocument.create();

	for (const base64 of pdfBase64) {
		const doc = await PDFDocument.load(base64);
		const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());

		for (const page of copiedPages) {
			pdfDoc.addPage(page)
		}
	}

	const base64Data = await pdfDoc.saveAsBase64();

	const blob = base64toBlob("data:application/pdf;base64," + base64Data);

	const bloburl = URL.createObjectURL(blob);
	window.open(bloburl);

	controller.setState({
		buttonLoadCheck: {
			...state.buttonLoadCheck,
			[`${params.card}_${params.action}`]: "SUCCESS",
		},
	});
};

/* ------------------------------------------------------ */

/*                      EditMedError                      */

/* ------------------------------------------------------ */
export const EditMedError: Handler = async (controller, params) => {
	const state = controller.getState();

	if (!state.MedErrorListSequence) {
		return;
	}

	if (!params.action) {
		// Get Master
		controller.handleEvent({
			message: "GetMasterData",
			params: {
				masters: [
					["medErrorRiskType", {}], //Risk
					["medErrorCategory", {}], //Category
				],
			},
		});

		const MedErrorDetailAPI = params.isOrderItem
			? MedicationErrorDetail
			: MedicationErrorDetailM;

		const getDetail = await MedErrorDetailAPI.retrieve({
			apiToken: controller.apiToken,
			pk: params.id,
		});

		const detail = getDetail[0] || {}; //รายการที่คลาดเคลื่อน

		let getUserList: any = [null, null, null];

		if (detail.stakeholder) {
			getUserList = UserList.list({
				apiToken: controller.apiToken,
				params: {
					pk: detail.stakeholder,
				},
			}); //ผู้รับผิดชอบ
		}

		const getTypeList = MedicationErrorTypeList.list({
			apiToken: controller.apiToken,
		}); //ประเภท

		const getTypeDetailList = MedicationErrorTypeDetailList.list({
			apiToken: controller.apiToken,
			params: { error_type: detail.type },
		}); //ลักษณะ

		const getCauseList = MedicationErrorCauseList.list({
			apiToken: controller.apiToken,
		}); //สาเหตุ

		const [userList, typeList, typeDetailList, causeList] = await Promise.all([
			getUserList,
			getTypeList,
			getTypeDetailList,
			getCauseList,
		]);

		const corrective = detail.corrective_user
			? "STAFF"
			: detail.corrective_division
				? "CLINIC"
				: "";

		const isClinic = detail.editor_by === "CLINIC";
		const searchKey = `${detail.editor_by === "CLINIC" ? "Division" : "User"
			}_2`;

		if (corrective) {
			controller.setState({
				searchedItemListWithKey: {
					...state.searchedItemListWithKey,
					[searchKey]: [
						{
							id: detail.corrective_user || detail.corrective_division,
							[isClinic ? "name" : "full_name"]:
								detail.corrective_user_name || detail.corrective_division_name,
						},
					],
				},
			});
		}

		controller.handleEvent({
			message: "RunSequence",
			params: {
				...params,
				action: "update",
				detail: {
					...detail,
					type_detail: detail.type_detail ? detail.type_detail.split(",") : [],
					cause: detail.cause ? detail.cause.split(",") : [],
					cause_detail: detail.cause_detail
						? detail.cause_detail.split(",")
						: [],
					risk_date: detail.risk_date
						? adToBe(detail.risk_date, "YYYY-MM-DD")
						: "",
					order_division:
						detail.order_division_name ||
						state.selectedDrugOrderWorking?.order_div ||
						"",
					patient_name:
						detail.patient_name || state.selectedPatient?.full_name || "",
				},
				typeList: typeList?.[0]?.items || [],
				typeDetailOptions: mapOptions(typeDetailList[0]?.items || [], "name"),
				causeList: causeList?.[0]?.items || [],
				user: userList[0]?.items?.[0] || {},
				causeDetailOptions: detail.cause_detail
					? detail.cause_detail
						.split(",")
						.map((value: any) => ({ key: value, value, text: value }))
					: [],
			},
		});
	} else if (params.action === "change_type") {
		const typeDetailList = await MedicationErrorTypeDetailList.list({
			apiToken: controller.apiToken,
			params: { error_type: params.type },
		}); //ลักษณะ

		controller.handleEvent({
			message: "RunSequence",
			params: {
				...params,
				action: "update",
				typeDetailOptions: mapOptions(typeDetailList[0]?.items || [], "name"),
			},
		});
	} else if (params.action === "update") {
		controller.setState({
			MedErrorListSequence: {
				...state.MedErrorListSequence,
				medErrorWorking: {
					...(state.MedErrorListSequence?.medErrorWorking || {}),
					...params,
				},
			},
		});
	} else if (params.action === "edit") {
		controller.setState(
			{
				MedErrorListSequence: {
					...state.MedErrorListSequence,
					sequenceIndex: "SaveMedError",
				},
			},
			() => controller.handleEvent({ message: "RunSequence", params })
		);
	} else if (params.action === "close") {
		controller.setState(
			{
				MedErrorListSequence: {
					...state.MedErrorListSequence,
					sequenceIndex: "SearchMedError",
				},
			},
			() => controller.handleEvent({ message: "RunSequence", params })
		);
	} else if (params.action === "close_order_working") {
		controller.setState({ MedErrorListSequence: null });
	}
};

export const SaveMedError: Handler = async (controller, params) => {
	const state = controller.getState();
	const data = params.data || {};
	let error: any = null;

	if (params.action === "edit") {
		let updateRes = [null, null, null];

		if (!data.editor_by) {
			error = {
				ผู้ดำเนินการแก้ไข: ["This field is required."],
			};
		} else if (data.editor_by === "STAFF" && !data.corrective) {
			error = {
				เจ้าหน้าที่ที่ต้องแก้ไข: ["This field is required."],
			};
		}

		const updateData = {
			...data,
			cause: (data.cause || []).join(","),
			cause_detail: (data.cause_detail || []).join(","),
			type_detail: (data.type_detail || []).join(","),
			risk_date: beToAd(data.risk_date)?.format("YYYY-MM-DD"),
			order_division:
				state.masterOptions.division.find(
					(option: any) => option.text === data.order_division
				)?.value || null,
			...(data.editor_by === "CLINIC"
				? { corrective_division: data.corrective, corrective_user: null }
				: { corrective_division: null, corrective_user: data.corrective }),
		};

		if (!error) {
			if (data.pk) {
				updateRes = await MedicationErrorDetailM.update({
					apiToken: controller.apiToken,
					pk: data.id,
					data: {
						action: params.isActionComplete && data.solution_note ? "COMPLETE" : "EDIT",
						...updateData,
					},
				});
			} else {
				updateRes = await MedicationErrorList.create({
					apiToken: controller.apiToken,
					data: { action: "NOTE", ...updateData },
				});
			}

			error = updateRes[1];
		}
	} else if (params.action === "delete") {
		const delRes = await MedicationErrorDetailM.delete({
			apiToken: controller.apiToken,
			pk: data.id,
		});

		error = delRes[1];
	}

	params.callback?.(error ? "error" : "success", error);

	if (error) {
		controller.setState({
			MedErrorListSequence: {
				...state.MedErrorListSequence,
				sequenceIndex: "EditMedError",
			},
		});
	}
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
const GetMedicationErrorList: Handler = (controller, params) => {
	const state = controller.getState()

	const urlParams: any = {};
	const data = params.filter || {};

	if (data.division && data.division !== "ALL") {
		urlParams.order_division = data.division;
	}
	if (!!data.status?.length) {
		urlParams.status = data.status;
	}
	if (data.start_date) {
		urlParams.start_date = data.start_date;
	}
	if (data.end_date) {
		urlParams.end_date = data.end_date;
	}
	if (!!data.type?.length) {
		urlParams.type = data.type;
	}
	if (data.stakeholder) {
		urlParams.stakeholder = data.stakeholder;
	}
	if (params.byCorrective) {
		urlParams.corrective_division = controller.data.division;
		urlParams.corrective_user = state.django.user.id
	}

	return MedicationErrorList.list({
		apiToken: controller.apiToken,
		params: {
			...urlParams,
			offset: 0,
			limit: 40,
		},
	});
}

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
const mapOptions = (list: any[], valueKey = "id") => {
	return list.map((item: any) => ({
		key: item.id,
		value: item[valueKey],
		text: item.name,
	}));
};