import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Label,
  Button,
  Input,
  Dropdown
} from 'semantic-ui-react'
import {
  TimeFreeTextBox24
} from 'react-lib/apps/common'

const CardCountUx = (props: any) => {
    return(
      <div
        style={{margin: "10px", backgroundColor: "rgba(198, 235, 243, 0.2)", height:"90%"}}>
        <div
          style={{display: "flex", alignItems: "center", margin: "10px 10px"}}>
          
          <label
            className="header"
            style={{fontWeight: "bold", fontSize: "18px", marginLeft: "4px"}}>
            Counts
          </label>
        </div>
        <div>
          
          <hr>
          </hr>
        </div>
        <div
          style={{height:"90%",margin: "14px 14px",backgroundColor: "#FFFFFF"}}>
          
          <div>
            {props.CountTables}
          </div>
          <div
            style={{height:"auto",margin: "20px 40px"}}>
            
            <Table
              data={props.items}
              getTrProps={props.shiftTableRowProps}
              headers=",Time,Swab,Sharp,Instrument,Scrub Nurses,Circulating Nurses"
              keys="shift_name,start_time,swabStatus,sharpStatus,instrumentStatus,scrub_nurses_name,circulating_nurses_name"
              showPagination={false}
              style={{height: "20vh"}}
              widths="auto,110,150,150,150,300,300">
            </Table>
            <div
              style={{border:"1px solid #ccc", borderRadius: "4px", padding: "0px 8px 4px 10px", margin:"10px 0px"}}>
              
              <Label
                color={props.ORCountsSequence?.selectedShift?.id ? "yellow":"blue"}
                ribbon={true}
                style={{margin:"10px 3px"}}>
                {props.ORCountsSequence?.selectedShift?.id ? "Edit":"New"}
              </Label>
              <div
                style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>
                
                <label
                  style={{width:"80px",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
                  Shift
                </label>
                <Input
                  readOnly={true}
                  style={{width:"300px"}}
                  value={props.ORCountsSequence?.selectedShift?.shift_name || ""}>
                </Input>
                <label
                  style={{width:"40px",fontWeight: "bold", fontSize: "14px", marginLeft: "14px"}}>
                  Time
                </label>
                <TimeFreeTextBox24
                  value={props.ORCountsSequence?.selectedShift?.start_time || ""}>
                </TimeFreeTextBox24>
                <label
                  style={{width:"80px",fontWeight: "bold", fontSize: "14px", marginLeft: "18px"}}>
                  Instrument
                </label>
                <Dropdown
                  name="selectedShift.instrument_status_cal"
                  onChange={props.handleChangeData}
                  options={props.ORCountsSequence?.instrumentOptions || []}
                  selection={true}
                  value={props.ORCountsSequence?.selectedShift?.instrument_status_cal || ""}>
                </Dropdown>
              </div>
              <div
                style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>
                
                <label
                  style={{width:"80px",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
                  Scrub nurse
                </label>
                <div
                  style={{width:"350px"}}>
                  {props.scrubNurse}
                </div>
                <label
                  style={{width:"120px",fontWeight: "bold", fontSize: "14px", marginLeft: "14px"}}>
                  Circulating Nurse
                </label>
                <div
                  style={{width:"350px"}}>
                  {props.circulatingNurse}
                </div>
              </div>
              <div
                style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>
                
                <div
                  style={{display:"flex", width:"auto", marginLeft: "auto"}}>
                  
                  <Button
                    color="red"
                    onClick={props.clearSelectedShift}
                    size="small">
                    Clear
                  </Button>
                  <div>
                    {props.createOreditShift}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{width: "100%"}}>
            {props.ErrorMessage}
          </div>
          <div>
            
            <div
              style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>
              
              <label
                style={{width:"200px",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
                Solve Method if count incorrect
              </label>
              <Dropdown
                multiple={true}
                name="resolveFrom.solve_method"
                onChange={props.handleChangeData}
                options={props.ORCountsSequence?.methodOptions || []}
                selection={true}
                style={{width:"70%"}}
                value={props.ORCountsSequence?.resolveFrom?.solve_method || []}>
              </Dropdown>
            </div>
            <div
              style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>
              
              <label
                style={{width:"200px",fontWeight: "bold", fontSize: "14px", margin: "0px 10px"}}>
                Remark
              </label>
              <Input
                name="resolveFrom.remark"
                onChange={props.handleChangeData}
                style={{width:"70%"}}
                value={props.ORCountsSequence?.resolveFrom?.remark || ""}>
              </Input>
            </div>
            <div
              style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>
              
              <div
                style={{display:"flex", width:"auto", marginLeft: "auto"}}>
                
                <div>
                  {props.saveRemark}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardCountUx

export const screenPropsDefault = {}

/* Date Time : Tue Feb 07 2023 16:33:58 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", backgroundColor: \"rgba(198, 235, 243, 0.2)\", height:\"90%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 3,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 6,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Counts"
        },
        "className": {
          "type": "value",
          "value": "header"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"18px\", marginLeft: \"4px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"90%\",margin: \"14px 14px\",backgroundColor: \"#FFFFFF\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"auto\",margin: \"20px 40px\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 24,
      "name": "Table",
      "parent": 23,
      "props": {
        "data": {
          "type": "code",
          "value": "props.items"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.shiftTableRowProps"
        },
        "headers": {
          "type": "value",
          "value": ",Time,Swab,Sharp,Instrument,Scrub Nurses,Circulating Nurses"
        },
        "keys": {
          "type": "value",
          "value": "shift_name,start_time,swabStatus,sharpStatus,instrumentStatus,scrub_nurses_name,circulating_nurses_name"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"20vh\"}"
        },
        "widths": {
          "type": "value",
          "value": "auto,110,150,150,150,300,300"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{border:\"1px solid #ccc\", borderRadius: \"4px\", padding: \"0px 8px 4px 10px\", margin:\"10px 0px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Label",
      "parent": 25,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ORCountsSequence?.selectedShift?.id ? \"Edit\":\"New\""
        },
        "color": {
          "type": "code",
          "value": "props.ORCountsSequence?.selectedShift?.id ? \"yellow\":\"blue\""
        },
        "ribbon": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px 3px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", width:\"auto\", marginLeft: \"auto\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Button",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "Clear"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.clearSelectedShift"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "Shift"
        },
        "style": {
          "type": "code",
          "value": "{width:\"80px\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Input",
      "parent": 27,
      "props": {
        "disabled": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"300px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ORCountsSequence?.selectedShift?.shift_name || \"\""
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "Time"
        },
        "style": {
          "type": "code",
          "value": "{width:\"40px\",fontWeight: \"bold\", fontSize: \"14px\", marginLeft: \"14px\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "label",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "Instrument"
        },
        "style": {
          "type": "code",
          "value": "{width:\"80px\",fontWeight: \"bold\", fontSize: \"14px\", marginLeft: \"18px\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "Scrub nurse"
        },
        "style": {
          "type": "code",
          "value": "{width:\"80px\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "Circulating Nurse"
        },
        "style": {
          "type": "code",
          "value": "{width:\"120px\",fontWeight: \"bold\", fontSize: \"14px\", marginLeft: \"14px\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "Solve Method if count incorrect"
        },
        "style": {
          "type": "code",
          "value": "{width:\"200px\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "Remark"
        },
        "style": {
          "type": "code",
          "value": "{width:\"200px\",fontWeight: \"bold\", fontSize: \"14px\", margin: \"0px 10px\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Input",
      "parent": 51,
      "props": {
        "name": {
          "type": "value",
          "value": "resolveFrom.remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"70%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ORCountsSequence?.resolveFrom?.remark || \"\""
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", width:\"auto\", marginLeft: \"auto\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Dropdown",
      "parent": 48,
      "props": {
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "resolveFrom.solve_method"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.ORCountsSequence?.methodOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"70%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ORCountsSequence?.resolveFrom?.solve_method || []"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.CountTables"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Dropdown",
      "parent": 27,
      "props": {
        "name": {
          "type": "value",
          "value": "selectedShift.instrument_status_cal"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.ORCountsSequence?.instrumentOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ORCountsSequence?.selectedShift?.instrument_status_cal || \"\""
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 62,
      "name": "TimeFreeTextBox24",
      "parent": 27,
      "props": {
        "value": {
          "type": "code",
          "value": "props.ORCountsSequence?.selectedShift?.start_time || \"\""
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.scrubNurse"
        },
        "style": {
          "type": "code",
          "value": "{width:\"350px\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.circulatingNurse"
        },
        "style": {
          "type": "code",
          "value": "{width:\"350px\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "code",
          "value": "props.saveRemark"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "code",
          "value": "props.createOreditShift"
        }
      },
      "seq": 67,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardCountUx",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
