import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Input,
  Icon
} from 'semantic-ui-react'

const CardClaimInformationDetailUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{width: "100%",height:"100%",padding:"10px"}}>
        <div
          style={{marginBottom: "-0.5rem"}}>
          
          <div
            style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 0"}}>
            
            <div
              style={{fontSize: "1.2rem", fontWeight: "bold"}}>
              รายละเอียดข้อมูลส่งเบิก E-Claim
            </div>
            <div
              className="ui form">
              
              <div
                className="inline fields"
                style={{margin: 0}}>
                
                <FormField>
                  <label>
                    Ref No.
                  </label>
                </FormField>
                <FormField>
                  <Input
                    name="refNo "
                    onChange={props.onChange}
                    value={props.refNo || ""}>
                  </Input>
                </FormField>
                <FormField>
                  <div>
                    {props.ButtonEdit}
                  </div>
                </FormField>
                <FormField
                  width={2}>
                  <div
                    style={{cursor: "pointer", margin: "0 -1rem 0 5rem"}}>
                    
                    <Icon
                      className="red large"
                      name="close"
                      onClick={props.onClose}>
                    </Icon>
                  </div>
                </FormField>
              </div>
            </div>
          </div>
        </div>
        <div
          className="ui divider">
          
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label>
                HN:
              </label>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.detail?.hn || "-"}
              </div>
            </FormField>
            <FormField>
              <label
                style={{marginLeft: "1rem"}}>
                Encounter:
              </label>
            </FormField>
            <FormField>
              <div>
                {props.detail?.encounter_id|| "-"}
              </div>
            </FormField>
            <FormField>
              <label
                style={{marginLeft: "1rem"}}>
                ชื่อ-สกุล:
              </label>
            </FormField>
            <FormField>
              <div>
                {props.detail?.patient_name|| "-"}
              </div>
            </FormField>
            <FormField>
              <label
                style={{marginLeft: "1rem"}}>
                คลินิก:
              </label>
            </FormField>
            <FormField>
              <div>
                {props.detail?.division_name|| "-"}
              </div>
            </FormField>
            <FormField>
              <label
                style={{marginLeft: "1rem"}}>
                ทันตแพทย์ผู้รักษา:
              </label>
            </FormField>
            <FormField>
              <div>
                {props.detail?.doctor_name|| "-"}
              </div>
            </FormField>
            <FormField>
              <label
                style={{marginLeft: "1rem"}}>
                วันที่รับบริการ:
              </label>
            </FormField>
            <FormField>
              <div>
                {props.detail?.service_date|| "-"}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{display: "grid", gridTemplateColumns: "5.25% 1fr", paddingBottom: "0.5rem", marginTop:"1rem"}}>
          
          <div
            style={{overflowY: "auto", overflowX: "hidden", paddingRight: "5px", height: "81vh", paddingTop:"0.5rem"}}>
            {props.typeList || []}
          </div>
          <div
            style={{padding:"0 8px", width: "calc(100vw - 13rem)"}}>
            {}
            <div
              style={{margin: "0 0 1rem", fontSize:"1.1rem"}}>
              
            </div>
            <div
              style={{margin: "0 0 1rem", fontSize: "1.1rem"}}>
              {props.title || ""}
            </div>
            <div
              style={{width: "100%"}}>
              {props.TableElement}
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardClaimInformationDetailUX

export const screenPropsDefault = {}

/* Date Time : Wed Mar 29 2023 02:57:43 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\",height:\"100%\",padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"-0.5rem\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\", padding: \"0px 0\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดข้อมูลส่งเบิก E-Claim"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormGroup",
      "parent": 69,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormField",
      "parent": 74,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 74,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "label",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": "HN:"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "inline fields"
        },
        "style": {
          "type": "code",
          "value": "{margin: 0}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "FormField",
      "parent": 96,
      "props": {
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormField",
      "parent": 96,
      "props": {
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "FormField",
      "parent": 96,
      "props": {
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 96,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "label",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "Ref No."
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Input",
      "parent": 98,
      "props": {
        "name": {
          "type": "value",
          "value": "refNo "
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "value": {
          "type": "code",
          "value": "props.refNo || \"\""
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", margin: \"0 -1rem 0 5rem\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Icon",
      "parent": 104,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "code",
          "value": "props.detail?.hn || \"-\""
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "FormField",
      "parent": 74,
      "props": {
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 74,
      "props": {
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormField",
      "parent": 74,
      "props": {
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "FormField",
      "parent": 74,
      "props": {
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "FormField",
      "parent": 74,
      "props": {
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "FormField",
      "parent": 74,
      "props": {
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "FormField",
      "parent": 74,
      "props": {
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "FormField",
      "parent": 74,
      "props": {
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "label",
      "parent": 107,
      "props": {
        "children": {
          "type": "value",
          "value": "Encounter:"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "label",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุล:"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "label",
      "parent": 111,
      "props": {
        "children": {
          "type": "value",
          "value": "คลินิก:"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "label",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": "ทันตแพทย์ผู้รักษา:"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "code",
          "value": "props.detail?.encounter_id|| \"-\""
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 110,
      "props": {
        "children": {
          "type": "code",
          "value": "props.detail?.patient_name|| \"-\""
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 112,
      "props": {
        "children": {
          "type": "code",
          "value": "props.detail?.division_name|| \"-\""
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 114,
      "props": {
        "children": {
          "type": "code",
          "value": "props.detail?.doctor_name|| \"-\""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 74,
      "props": {
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "FormField",
      "parent": 74,
      "props": {
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "label",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่รับบริการ:"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "code",
          "value": "props.detail?.service_date|| \"-\""
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"5.25% 1fr\", paddingBottom: \"0.5rem\", marginTop:\"1rem\"}"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 127,
      "props": {
        "children": {
          "type": "code",
          "value": "props.typeList || []"
        },
        "style": {
          "type": "code",
          "value": "{overflowY: \"auto\", overflowX: \"hidden\", paddingRight: \"5px\", height: \"81vh\", paddingTop:\"0.5rem\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 127,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding:\"0 8px\", width: \"calc(100vw - 13rem)\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 129,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 0 1rem\", fontSize:\"1.1rem\"}"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 129,
      "props": {
        "children": {
          "type": "code",
          "value": "props.title || \"\""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 0 1rem\", fontSize: \"1.1rem\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 99,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonEdit"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 129,
      "props": {
        "children": {
          "type": "code",
          "value": "props.TableElement"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 134,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": true,
  "memo": false,
  "name": "CardClaimInformationDetailUX",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 70
}

*********************************************************************************** */
