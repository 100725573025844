import axios from "axios";
import { to } from "../../../../utils";
import BaseService from "../../../services/BaseService";
import config from "../../../../../config/config";
import { PatientSerializer } from "./PatientDetail";

const HOST = `${config.API_HOST}`;

export const PatientList: {
  retrieve: (arg0: { pk: any; params?: any; apiToken?: any; extra?: any }) => any;
  list: (arg0: { params?: any; apiToken?: any; extra?: any }) => any;
  create: (arg0: { params?: any; data?: PatientSerializer; apiToken?: any; extra?: any }) => any;
} = {
  retrieve: async ({ pk, params, apiToken, extra }) => {
    const base = new BaseService();
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const result = await to(
      base.client
        .get(`${HOST}/apis/REG/patient/hn/${pk}/`, { params: params ? { ...params } : {}, ...extra })
        .then(base.handleResponse)
        .catch(base.throwErrorMessage)
    );
    return [result[1], result[0], result[2]];
  },
  list: async ({ params, apiToken, extra }) => {
    const base = new BaseService();
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const result = await to(
      base.client
        .get(`${HOST}/apis/REG/patient/`, { params: params ? { ...params } : {}, ...extra })
        .then(base.handleResponse)
        .catch(base.throwErrorMessage)
    );
    return [result[1], result[0], result[2]];
  },

  create: async ({ params, data, apiToken, extra }) => {
    const base = new BaseService();
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const result = await to(
      base.client
        .post(`${HOST}/apis/REG/patient/`, { ...data }, { params: params ? { ...params } : {}, ...extra })
        .then(base.handleResponse)
        .catch(base.throwErrorMessage)
    );
    return [result[1], result[0], result[2]];
  },
};
