import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input,
  Button
} from 'semantic-ui-react'

const LandingUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", height: "100%" }}>
        <form
          onSubmit={(e: any) => { e.preventDefault();  props.loginSubmit() }}
          style={{ width: "100%", height: "100%" }}>
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(8, 1fr)","gridTemplateColumns":"repeat(8, 1fr)"}}>
            <div
              style={{ display: "flex", justifyContent: "center", gridRow: "1/4", gridColumn: "2/8",    paddingTop: "20px"}}>
              
              <img
                src={props.logo}
                style={{width: "100%", maxWidth: "1040px", height: "380px"}}>
              </img>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", gridRow: "4/8", gridColumn: "4/6",  paddingTop: "20px"}}>
              <Input
                id="username"
                onChange={props.changeUsername}
                placeholder="username"
                style={{ margin: "5px" }}
                value={props.username}>
              </Input>
              <Input
                id="password"
                icon={props.eye}
                onChange={props.changePassword}
                placeholder="password"
                style={{ margin: "5px" }}
                type={props.typePassword}
                value={props.password}>
              </Input>
              <Button
                id="submit"
                color="teal"
                loading={props.loading}
                style={{ margin: "5px" }}>
                Login IsHealth
              </Button>
              <div
                style={{flex: 1}}>
                
              </div>
            </div>
          </div>
        </form>
      </div>
    )
}

export default LandingUX

export const screenPropsDefault = {}

/* Date Time : Tue Aug 23 2022 07:06:29 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 13,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(8, 1fr)\",\"gridTemplateColumns\":\"repeat(8, 1fr)\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flexDirection: \"column\", justifyContent: \"space-around\", gridRow: \"4/8\", gridColumn: \"4/6\",  paddingTop: \"20px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Input",
      "parent": 2,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeUsername"
        },
        "placeholder": {
          "type": "value",
          "value": "username"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"5px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.username"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Input",
      "parent": 2,
      "props": {
        "icon": {
          "type": "code",
          "value": "props.eye"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePassword"
        },
        "placeholder": {
          "type": "value",
          "value": "password"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"5px\" }"
        },
        "type": {
          "type": "code",
          "value": "props.typePassword"
        },
        "value": {
          "type": "code",
          "value": "props.password"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Login IsHealth"
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "loading": {
          "type": "code",
          "value": "props.loading"
        },
        "onClick": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"5px\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", gridRow: \"1/4\", gridColumn: \"2/8\",    paddingTop: \"20px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "img",
      "parent": 11,
      "props": {
        "src": {
          "type": "code",
          "value": "props.logo"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"1040px\", height: \"380px\"}"
        }
      },
      "seq": 12,
      "void": true
    },
    {
      "from": null,
      "id": 13,
      "name": "form",
      "parent": 0,
      "props": {
        "onSubmit": {
          "type": "code",
          "value": "(e: any) => { e.preventDefault();  props.loginSubmit() }"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" }"
        }
      },
      "seq": 13,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "LandingUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
