import toothDetails from "./ToothDetails";

export const ClinicalAbsenceTermSet = [
  "Clinical absence",
  "Congenital absence",
  "Extracted tooth",
  "Partial eruption",
  "Unerupted tooth"
];

export const isTooth = (location: string) => {
  if (["1", "2", "3", "4", "5", "6", "7", "8"].includes(location.substring(0, 1))) {
    return true
  } else {
    return false
  }
}

export const getOrgansWithRecord = (organMaster: any, clinicalFindings: any) => {
  // console.log(" getOrgansWithRecord,", clinicalFindings)
  let organsWithRecord: any[] = [];
  if (organMaster && clinicalFindings?.length > 0) {

    clinicalFindings?.forEach((clinicalFinding: any) => {
      if (clinicalFinding?.location) {
        // console.debug("finishColor clinicalFinding.location have ", clinicalFinding.location)
        const locations = clinicalFinding.locations_name?.split(",");

        for (const location of locations) {
          // console.debug("finishColor location have ", location)
          const index = organMaster
            .map((item: any) => item.nickName)
            .indexOf(location.trim());
          // console.debug("finishColor getOrgansWithRecord index: ", index)
          if (index !== -1) {

            let descendants: any[] = getDescendants(organMaster, index, []);
            // console.debug('finishColor descendants: ', descendants);

            let nick = descendants.map((item) => item.nickName)
            // console.debug ("finishColor Check getOrgansWithRecord !!! nick: ", nick)
            if (nick.length === 1 && nick[0].startsWith("S")) {
              nick[0] = superTeethSHumanToSOrgan(nick[0])
            }
            organsWithRecord = organsWithRecord.concat(nick);
          } else {
            // console.log("index else !!! " , index)
          }
        }
      } else {
        // console.log(" location not have ")
      }
    });
  }

  // console.debug("getOrgansWithRecord , ",organsWithRecord)
  return organsWithRecord;
};


export const getSurfaceName = (q: number, i: number, section: number) => {
  const candidates = toothDetails.filter((item: any) => item.q === q && item.i === i);
  if (candidates.length === 0) return "";
  if (section >= candidates[0].surfaceName.length) return "";
  return candidates[0].surfaceName[section];
};

const getTooth = (primary: string, secondary: string, organMaster: any) => {

  // console.log(" getNickName primary: ", primary)
  // console.log(" getNickName secondary:", secondary)
  // console.log(" getNickName organMaster:", organMaster)
  if (primary && secondary && organMaster) {

    let found = organMaster.filter((item: any) => item.primary === primary && item.secondary?.length === 1 && item.secondary[0] === secondary)
    // console.log(" found" ,found) 
    if (found?.length > 0) {
      return found[0]
    }
  }
  return ""
}

export const getOrgansWithMultiSelected = (
  masterData: any,
  multiSelected: any
) => {
  let organsMultiSelected: any[] = [];
  const organMaster = masterData?.organ?.items;
  if (organMaster && multiSelected) {
    Object.keys(multiSelected).forEach((key) => {
      let pri = key.substr(0, 1) + key.substr(2, 1);
      let selected = multiSelected[key];
      if (selected?.length > 0) {
        selected.forEach((item: any) => {
          let surfaceName = getSurfaceName(Number(key.substr(0, 1)), Number(key.substr(2, 1)), item)
          let nickName = getTooth(pri, surfaceName, organMaster).nickName;
          organsMultiSelected.push(nickName)
        });
      }
    });

  }
  return organsMultiSelected;
};

export const getDescendants: (
  organMaster: any,
  index: number,
  output: any[]
) => any[] = (organMaster, index, output) => {
  if (index >= 0) {
    if (!organMaster[index].hasChildren) {
      output.push(organMaster[index]);
      return output;
    } else {
      for (const child of organMaster[index].children) {
        const child_index = organMaster.map((item: any) => item.id).indexOf(child);
        output = getDescendants(organMaster, child_index, output);
      }
      return output;
    }
  } else {
    return [];
  }
};

export const tidyLocation2 = (locationList: string[], organItems: any[]) => {

  let fragment: any[] = []
  let children: any[] = []
  let byTooth: { [key: string]: any[] } = {}
  let tidyResult: any[] = []
  // Only Tooth in tidy  else send it into result.
  for (const location of locationList) {
    if (!isTooth(location)) {
      tidyResult.push(location)
    } else if (location.includes("S")) {
      tidyResult.push(location)
    } else if (!location.includes("S")) {
      let element = organItems.filter(item => item.nickName === location.trim())
      if (element?.length === 1) {
        fragment.push(element[0])
      }
    }
  }
  // find all children แตกแผงฟันออกเป็นซี่ และ เตรียม byTooth ที่มี key คือ ตำแหน่ง ฟัน e.g. 11 
  if (fragment.length > 0) {
    fragment.forEach(item => {
      if (item.children?.length > 0) {
        children = children.concat(item.children)
      } else {
        children.push(item.id)
      }
    })
    children = children.map(item => item.replace('tooth', ''))
    children = Array.from(new Set(children)) // unique 

    // group by toothid e.g. 11, 12 with key 
    children.forEach(item => {
      let toothId = item.substring(0, 2)
      // console.log(" toothId iliterte", toothId)
      if (byTooth.hasOwnProperty(toothId)) {
        byTooth[toothId] = [...byTooth[toothId], item]
      } else {
        byTooth[toothId] = [item]
      }
    })

    // merge to one complete tooth รวม เป็นฟันเต็ม ถ้าครบ 5 ส่วน เป็นต้นไป 
    for (const key in byTooth) {
      if (Object.prototype.hasOwnProperty.call(byTooth, key)) {
        if (byTooth[key]?.length >= 5 || byTooth[key].filter(item => item.length === 2).length === 1) {
          // ครบส่วน ได้ 1 ฟัน ส่งไป result รอได้เลย
          tidyResult.push(byTooth[key][0].substring(0, 2))
        } else if (byTooth[key]?.length === 1) {
          // มีแค่ ส่วนเดียว ส่งไป result รอได้เลย
          tidyResult.push(byTooth[key][0])
        }
        else {
          // มีหลายส่วน แต่รวมเป็น 1 ฟันไม่ได้ หา 1 nickname ที่ represent ส่วนฟันนี้ 
          // more than one part and below five part find Nickname from children 
          let toothId = byTooth[key][0].substring(0, 2)
          let prepare = organItems.filter(item => item.primary === toothId && item.children.length === byTooth[key].length)
          // console.log("fragment Check 1 prepare : ", prepare)
          if (prepare.length > 0) {
            byTooth[key].forEach(secondary => {
              prepare = prepare.filter(pre => pre.children.includes("tooth" + secondary))
            })
          }
          if (prepare?.length === 1) {
            tidyResult.push(prepare[0].nickName)
            // console.log("fragment prepare[0].nickName ", prepare[0].nickName)
          } else {
            // console.log("fragment tinyLocation2 Should not see me !")
            // console.log("fragment prepare: ", prepare )
            // console.log("fragment byTooth[key]: ", byTooth[key] )
            tidyResult = tidyResult.concat(byTooth[key])
          }
        }
      }
    }

    // console.log("fragment : Before รวมแผงฟัน tidyResult: " ,tidyResult)
    // ทำแผงฟัน ให้เป็น 11-18 
    let prepareMerge = tidyResult.filter(item => item.length === 2)
    let mergeTooth: any[] = []
    prepareMerge.sort() // e.g. 11, 12, 21, 31, 34 
    let qList = prepareMerge.map(item => item.substring(0, 1))
    qList = Array.from(new Set(qList)) // e.g. 1, 2, 3 
    let groupToothMaster = organItems
      .filter(item => item.primary === null && item.q) //only one teeth
      .filter(item => qList.includes(item.q.toString()))  // only quan
      .filter(item => item.children.length > 1) // at least two part 
    qList.forEach(q => {
      // only one Tooth no need merge 
      let prepareQ = prepareMerge.filter(item => item.substring(0, 1) === q) /// 11 ,12 or 21 or 31, 34 
      if (prepareQ.length === 1) {
        mergeTooth.push(prepareQ)
        return;
      }
      // วน loop หาแผงฟัน ที่ใหญ่ที่สุดก่อน ไล่ ไปแผงฟันเล็ก มันจะได้ 11-14 จะไม่ได้ แผงเล็ก 11-12, 13-14 
      for (let index = prepareQ.length; index > 1; index--) {
        let groupToothMasterQ = groupToothMaster.filter(item => q === item.q.toString() && item.children.length === index)
        // console.log("fragment groupToothMasterQ" , groupToothMasterQ, 'q', q, "index", index )
        let candidate = groupToothMasterQ.filter(testMaster => {
          let found = true
          testMaster.children.forEach((master: any) => {
            if (!prepareQ.includes(master.substring(5, 7))) {
              found = false
            }
          })

          return found
        })

        // console.log("fragment candidate" , candidate )
        if (candidate.length === 1) {
          /// found only one 
          // console.log(" fragment one")
          mergeTooth.push(candidate[0].nickName)
          candidate[0].children.forEach((item: any) => {
            const index = prepareQ.indexOf(item.substring(5, 7));
            if (index > -1) {
              prepareQ.splice(index, 1);
            }
          })
          // console.log("fragment remain One prepareQ", prepareQ)
          if (prepareQ.length === 0) {
            break;
          }
        } else if (candidate.length > 1) {
          // console.log(" fragment > one ")
          candidate.forEach(item => mergeTooth.push(item.nickName))
          candidate.forEach(candi => {
            candi.children.forEach((item: any) => {
              const index = prepareQ.indexOf(item.substring(5, 7));
              if (index > -1) {
                prepareQ.splice(index, 1);
              }
            })
          })
          // console.log("fragment remain prepareQ", prepareQ)
          if (prepareQ.length === 0) {
            break;
          }
        } else {
          /// not found 
        }
        // let trial = prepareMerge.filter(item => item.children.length === index)
        // if ( trial?.children.forEach(trialItem => ) )
        // console.log("fragment index" , index )
      }
      if (prepareQ.length !== 0) {
        mergeTooth = mergeTooth.concat(prepareQ)
      }
    })
    // รวม ผลลัพท์ทั้งหมดเข้าด้วยกัน 
    tidyResult = tidyResult.filter(item => item.length !== 2)
    tidyResult = tidyResult.concat(mergeTooth);

    // เรียงลำดับตาม location ที่ลง
    const orderItems = tidyResult.map((tooth: string) => ({
      tooth,
      order: locationList.findIndex((location) => {
        const number = (Array.isArray(tooth) ? tooth[0] : tooth).substring(0, 2)
        return Number.isNaN(Number(number)) ? tooth === location : number === location.substring(0, 2)
      }),
    }));

    tidyResult = orderItems.sort((a, b) => Number(a.order) - Number(b.order)).map((item) => item.tooth)
    // console.log("fragment : tidyResult: " ,tidyResult)
  }
  // console.log(tidyResult)
  return tidyResult
}

export const getAllChildren = (nickName: string, organItems: any[]) => {
  // let children  

  // console.log("getAllChildren nickName", nickName)
  // console.log("organItems", organItems)
  let element = organItems.find(item => item.nickName === nickName)
  if (!element) {
    console.error("Can't find thisNickname")
    return []
  }
  if (element?.children?.length === 0) {

    return [element.nickName]
  } else {
    let a = element?.children.reduce((acc: any, child: any) => {
      let b = getAllChildren(child.replace('tooth', ''), organItems)
      // console.log(" b", b)
      return acc.concat(b)
    }, [])
    // console.log(" a", a)
    return a
  }
}

export const getAbbrSurface = (surfaceName: string) => surfaceName === "Labial" ? "La" : surfaceName === "Palatal" ? "Pa" : surfaceName === "Lingual" ? "Li" : surfaceName[0]

export const getNickName = (toothId: string, organItems: any[]) => {
  console.log("getNickName ~ toothId", toothId, "organItems", organItems)

  // console.log("getNickName ~  Number(toothId.substring(1,2)",  Number(toothId.substring(1,2)))
  // console.log("getNickName ~  Number(toothId.substring(2,3)",  Number(toothId.substring(2,3)))
  // console.log("getNickName ~  Number(toothId.substring(4,5)",  Number(toothId.substring(4,5)))
  // console.log("getNickName ~ toothDetails", toothDetails)

  let tooth = toothDetails.filter(item => item.q === Number(toothId.substring(1, 2)) && item.i === Number(toothId.substring(2, 3)))
  // console.log("tooth", tooth)
  if (tooth.length === 1) {
    // console.log("getNickName ~ tooth[0]", tooth[0])

    let surface = tooth[0].surfaceName[Number(toothId.substring(4, 5))]
    // console.log("getNickName ~ surface", surface)
    // console.log(" organItems ", organItems)
    let q = toothId.substring(1, 2)
    let i = toothId.substring(2, 3)

    //&& item.secondary.length === 1 && item.secondary[0] === surface
    // console.log("getNickName ~ surface", surface)
    let organItem = organItems.filter(item => (item.primary === q + i) && (item.secondary.length === 1))
    // console.log("getNickName ~ organItem", organItem)
    organItem = organItem.filter(item => item.secondary[0] === surface)
    // console.log("getNickName ~ organItem", organItem)
    if (organItem.length === 1) {
      return organItem[0]
    }
  }

  return ""
}

export const getLocationsSet = (locationName: string, organItems: any[]) => {
  return locationName
    .split(",")
    .map((item) => {
      let superTooth = organItems.find((o) => o.fullName === item.trim());

      if (superTooth) {
        return superTooth.nickName;
      }
      return item.trim();
    })
    .filter((item) => item !== "");
}

export const deTinyLocation2 = (locationList: string[], organItems: any[]) => {
  let deTinyLocation: any[] = []
  // console.log(" locationList", locationList)
  for (const locationNottrim of locationList) {
    // console.log(" loop location:", location )
    let location = locationNottrim.trim()
    if (!isTooth(location)) {
      deTinyLocation.push(location)
    } else if (location.includes("S")) {
      deTinyLocation.push(location)
    } else {
      // console.log("location :", location)
      let child = getAllChildren(location, organItems)
      deTinyLocation = deTinyLocation.concat(child)
    }
  }


  return deTinyLocation
}


export const tidyLocation = (locationList: string[], organItems: any[]) => {

  let newLocationList: any[] = []
  let byTooth: { [key: string]: any[] } = {}
  console.log("tidyLocation start")
  console.log("tidyLocation ~ locationList", locationList)
  console.log("tidyLocation ~ organItems", organItems)

  for (const location of locationList) {
    if (!isTooth(location)) {
      newLocationList.push(location)
    } else {
      let toothId = location.substring(0, 2)
      if (!Object.keys(byTooth).includes(toothId)) {
        byTooth[toothId] = []
      }
      let children
      if (location.length > 2 && !location.includes("-")) {
        const index = organItems.map((item => item.nickName))
          .indexOf(location.trim())
        children = getDescendants(organItems, index, [])
      } else {
        children = [{ nickName: location }]
      }
      for (const child of children) {
        if (!byTooth[toothId].includes(child.nickName)) {
          byTooth[toothId].push(child.nickName)
        }
      }
    }
  }

  // console.log("tidyLocation ~ byTooth", byTooth)
  // console.log("tidyLocation ~ newLocationList", newLocationList)

  let newToothList: any[] = []
  for (const toothId of Object.keys(byTooth)) {
    if (byTooth[toothId].length === 1) {
      newToothList.push(byTooth[toothId][0])
    } else if (byTooth[toothId].length === 5) {
      newToothList.push(toothId)
    } else {
      let candidates = organItems.filter(item => {
        if (item.tissue !== "tooth") {
          return false
        } else if (item.primary !== toothId) {
          return false
        } else if (item.children.length !== byTooth[toothId].length) {
          return false
        }
        const array1 = byTooth[toothId].sort()
        const array2 = item.children.map((child: any) => child.substring(5)).sort()
        for (var i = 0; i < array1.length; i++) {
          if (array1[i] !== array2[i]) {
            return false
          }
        }
        return true
      })
      if (candidates.length > 0) {
        newToothList.push(candidates[0].nickName)
      } else {
        newToothList = newToothList.concat(byTooth[toothId])
      }
    }
  }
  // console.log("tidyLocation ~ newToothList", newToothList)
  let byQuadrant: { [key: string]: any[] } = {}
  for (const item of newToothList) {
    if (item.length > 2) {
      newLocationList.push(item)
    } else {
      let q = item.substring(0, 1)
      if (!Object.keys(byQuadrant).includes(q)) {
        byQuadrant[q] = []
      }
      byQuadrant[q].push(item)
    }
  }

  // console.log("tidyLocation ~ byQuadrant: ", byQuadrant)

  for (const q of Object.keys(byQuadrant)) {
    let start = null
    let last: any = null
    let listToProcess = byQuadrant[q].sort()
    for (const item of listToProcess) {
      // console.log("tidyLocation ~ item: ", item)
      if (!start) {
        start = item
      } else if (start && (parseInt(item.substring(1)) - parseInt(last)) > 1) {
        if (!last) {
          newLocationList.push(start)
        } else {
          newLocationList.push(start + "-" + q + last)
        }
        start = item
        last = null
      } else {
        last = item.substring(1)
      }
    }
    if (!last) {
      newLocationList.push(start)
    } else {
      newLocationList.push(start + "-" + q + last)
    }
  }
  console.log(newLocationList)
  // newLocationList = newLocationList.concat(newToothList)
  return newLocationList
}


// if (item.location.length === 6
//   && item.location.startsWith("S")
//   && item.location.substring(3, 4) === "S"
//   && !isNaN(Number(item.location.substring(1, 3)))
//   && !isNaN(Number(item.location.substring(4, 6)))) {
//   let nickname = item.location.substring(0, 3) + "/" + item.location.substring(4);
//   return { i: item.i, q: item.q, location: nickname }
// } else {
//   return item
// }

// TS13S14 to TS13/14
export const superTeethTSOrganToTSHuman = (tooth: string) => {
  if (tooth.startsWith("TS") && tooth.length === 7 && tooth[4] === "S") {
    return tooth.slice(0, 4) + "/" + tooth.slice(5)
  } else {
    return tooth
  }
}

// TS13/14 to TS13S14
export const superTeethTSHumanToTSOrgan = (tooth: string) => {
  if (tooth.startsWith("TS") && tooth.length === 7 && tooth[4] === "/") {
    return tooth.slice(0, 4) + "S" + tooth.slice(5)
  } else {
    return tooth
  }
}


// S13/14 to S13S14 
export const superTeethSHumanToSOrgan = (tooth: string) => {
  if (tooth.length === 6
    && tooth.startsWith("S")
    && tooth.substring(3, 4) === "/"
    && !isNaN(Number(tooth.substring(1, 3)))
    && !isNaN(Number(tooth.substring(4, 6)))) {
    let nickname = tooth.substring(0, 3) + "S" + tooth.substring(4);
    return nickname
  } else {
    return tooth
  }
}

// S13S14 to S13/14 
export const superTeethSOrganToSHuman = (tooth: string) => {
  if (tooth.length === 6
    && tooth.startsWith("S")
    && tooth.substring(3, 4) === "S"
    && !isNaN(Number(tooth.substring(1, 3)))
    && !isNaN(Number(tooth.substring(4, 6)))) {
    let nickname = tooth.substring(0, 3) + "/" + tooth.substring(4);
    return nickname
  } else {
    return tooth
  }
}

// only tooth nickName prefix "S" ?? อะไร มันเอาหมดไม่ใช่หรอฟังค์ชั่นนี้หน่ะ 
export const getOnlyFullTeeth = (tooth: string[], teethOrgans: any[]) => {
  let teeths = tooth.filter(item => isTooth(item) || item.startsWith("S"))
  if (!teeths) {
    return [];
  }
  let teethList = deTinyLocation2(teeths
    .filter(o => !o.startsWith("S")), teethOrgans);
  // console.log("getTeeth teethList", teethList)
  //set only left teeths are supertooths. 
  teeths = teeths.filter(o => o.startsWith("S"))
  while (teethList.length > 0) {
    let teethNumber = teethList[0].slice(0, 2)
    // console.log("getTeeth  teethNumber", teethNumber)
    let teehNumberList = tidyLocation2(
      teethList.filter(item => item.includes(teethNumber)),
      teethOrgans
    ).join(",")

    if (teehNumberList && teehNumberList === teethNumber && !teeths.includes(teethNumber)) {
      teeths.push(teethNumber)
    }
    // console.log('getTeeth teeths: ', teeths);
    teethList = teethList.filter(item => !item.includes(teethNumber))
  }

  // console.log('getTeeth Final teeths: ', teeths);
  if (teeths.length > 0) {
    teeths = teeths.map(item => {
      return teethOrgans.find(o => o.nickName === item)
    })
  }

  return teeths
};

export const getSurfaceBorderLine = (organ: string, abbrSurfaceDict: Record<string, string[]>, organsWithRecord: any[]) => {
  if (organ.search(/\d+[OI]$/g) === -1) {
    // map ด้านใกล้เคียงที่ต้องการแสดง stroke
    const q: any = { 1: [2], 2: [3], 0: [1, 3] };

    const abbr = abbrSurfaceDict[`${parseInt(organ)}`];

    if (!abbr) {
      return []
    }

    const surfaceIndex = abbr.indexOf(organ.replace(/\d+/g, ""));

    // เส้นด้านข้าง surface ที่ต้องวาด
    const surfaces: number[] = q[surfaceIndex] || [];
    return surfaces.reduce((result, value, index) => {
      // เมื่อ surface ด้านข้างไม่ได้เลือกให้ทำการวาดเส้นขอบ
      const showStroke = !organsWithRecord.find((key) => key === `${parseInt(organ)}${abbr[value]}`);

      if (showStroke) {
        const key = `T${parseInt(organ)}${surfaceIndex === 0 && index === 1 ? abbr[surfaceIndex] : abbr[value]}` + "BL";

        result.push(key)
      }

      return result
    }, [] as string[]);
  } else {
    return []
  }
}

export const MissingToothClinical = ["Clinical absence", "Congenital absence", "Extracted tooth", "Unerupted tooth"];
