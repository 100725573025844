import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'

const ANES_ChargeItem = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div>
        <div>
          
          <Table
            columns={props.columnsBill}
            data={props.data || []}
            headers={props.headers}
            keys={props.keys}
            minRows={10}
            showPagination={false}
            widths="80,200">
          </Table>
        </div>
        <div
          style={{height:"50px", marginTop: "1rem"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(1, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "40/45", display:"flex"}}>
              <div>
                {props.buttonCalculate}
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "46/51", display:"flex" }}>
              <div>
                {props.buttonSave}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default ANES_ChargeItem

export const screenPropsDefault = {}

/* Date Time : Tue Jan 17 2023 12:02:17 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 1,
      "props": {
        "columns": {
          "type": "code",
          "value": "props.columnsBill"
        },
        "data": {
          "type": "code",
          "value": "props.data || []"
        },
        "defaultPageSize": {
          "type": "value",
          "value": ""
        },
        "headers": {
          "type": "code",
          "value": "props.headers"
        },
        "keys": {
          "type": "code",
          "value": "props.keys"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "pageSize": {
          "type": "code",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": "80,200"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"50px\", marginTop: \"1rem\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "label": "divgrid",
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 4,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"40/45\", display:\"flex\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 4,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"46/51\", display:\"flex\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonCalculate"
        }
      },
      "seq": 10,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_ChargeItem",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
