// NPM: MAIN
import React, { useEffect, useState, useMemo, useRef } from "react"
import {
  Form,
  Grid,
  Button,
  Input,
  Segment,
  Checkbox,
  Label,
  Icon,
  Popup,
  Dimmer,
  Loader,
  Modal,
  Header,
  Dropdown,
  TextArea,
  List
} from "semantic-ui-react"
import moment from "moment"
import _ from "../../compat/lodashplus"

// CARD
import { DateTextBox } from "../common"
import SemanticTable from "./SemanticTable"
import TimeField from "react-simple-timefield";

//Mod
import { ErrorMessage } from "../common"
import ModInfo from "../common/ModInfo"
import ModMedicationRecordDetail from "./ModMedicationRecordDetail"
import SubUserToken from "./SubUserToken"

//Constant
import * as Util from "../../utils";
import {
  PopupConfig,
  CardMedAdminQueueWardStateType,
  DrugBarcodeType,
  InputNurseType,
  initialInput,
  initialCorReason,
  HardDrugType,
  CorrectionReasonType,
  ModErrorType,
  ModDescriptionType,
} from "./CardPreAdminQueueWard"
import { DisplayDrugDetail, FilterMedRecordType, TYPE_COLOR } from "./CardQueueWard";

// STYLE
import "./CardMedAdmin.scss"

const currentTime = () => Util.formatDatetime(moment()).split(" ")[1]

const initialFilterMedRecord: FilterMedRecordType = {
  ward: null as any,
  date: Util.formatDate(moment()),
  time: currentTime(),
  checkedTime: false,
  division: " ",
  hn: null,
}

const initialModError: ModErrorType = {
  error: null,
  type: "",
  open: false,
  title: ""
}

const initialModDescription: ModDescriptionType = {
  textContent: null,
  color: "",
  open: false,
  title: ""
}

export const CardAdminQueueWardInitial: CardMedAdminQueueWardStateType = {
  patientInfo: null,
  medicationRecord: null,
  onEvent: () => null,
  django: null,
  division: [],
  wardTypeList: [],
  controller: null,
  filterMedRecord: initialFilterMedRecord,
  barcodeHNRef: {
    current: null
  },
  tabCurrentIndex: 1,
  onSetFilter: () => null
}

const CardAdminQueueWard: React.FC<CardMedAdminQueueWardStateType> = (props) => {
  //* Show loading icon when fetch api
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false)

  //* Show Modal
  const [openModalRemark, setOpenModalRemark] = useState<boolean>(false)
  const [openModalDetail, setOpenModalDetail] = useState<string>("")
  const [openModalReason, setOpenModalReason] = useState(false)
  const [openModSuccess, setOpenModSuccess] = useState(false)
  const [openModError, setOpenModError] = useState<ModErrorType>(initialModError)
  const [openModDescription, setOpenModDescription] = useState(initialModDescription)

  //* Show Mod
  const [errMessageNurse1, setErrMessageNurse1] = useState(null)

  const [remarkDivision, setRemarkDivision] = useState<any>(" ")
  const [reasonList, setReasonList] = useState<any[]>([])
  const [adminRemarkList, setAdminRemarkList] = useState([])
  const [reason, setReason] = useState<any>("")

  //* Selected drug order
  const [selectedDetail, setSelectedDetail] = useState<any>(null)
  const [selectedDrugItem, setSelectedDrugItem] = useState(null)

  const [correctionReason, setCorrectionReason] = useState<CorrectionReasonType>(initialCorReason)
  const [showInputNurse, setShowInputNurse] = useState<InputNurseType>(initialInput)
  const [drugBarcode, setDrugBarcode] = useState<DrugBarcodeType[]>([])
  const textAreaRef = useRef<HTMLTextAreaElement>()
  const isMounted = useRef(true)


  useEffect(() => {
    onFetchAdminRemarkList()
  }, [])

  useEffect(() => {
    if (isMounted.current) {
      if (props.django && props.filterMedRecord.ward) {
        onFetchTableData(props.django)
        isMounted.current = false
      }
    }
  }, [props.django, props.filterMedRecord.ward])

  // Component
  const SubDrugOrderStatusAlert = useMemo(() => {
    return props.SubDrugOrderStatusAlert;
  }, [props.SubDrugOrderStatusAlert]);

  const onFetchTableData = async (django: any) => {
    setIsLoading(true)
    drugBarcode[0] && setDrugBarcode([])
    await getListMedAdminRecord(django)
    setIsLoading(false)
  }

  const onFetchAdminRemarkList = async () => {
    if (!reasonList[0]) {
      const [res] = await getClinicalTermSet({ questionList: ["ADMIN_REMARK"] })
      if (res?.ADMIN_REMARK) {
        setAdminRemarkList(res.ADMIN_REMARK.items)
      }
    }
  }

  // ---------------------------- FETCH API, method: GET ---------------------
  const getListMedAdminRecord = async (_django: any, setParams: any = {}) => {
    const { division, checkedTime, time, ward, } = props.filterMedRecord
    const hn = props.barcodeHNRef.current.value

    if (typeof division !== "string") {
      setParams.admin_division = division
    }
    if (checkedTime) {
      setParams.time = time
    }
    if (hn) {
      setParams.hn = hn
    }
    if (typeof ward !== "string") {
      setParams.ward = ward
    }

    return await props.onEvent({
      message: "getListMedAdminRecord",
      params: {
        data: {
          mode: "ADMIN",
          date: props.filterMedRecord.date,
          ...setParams
        },
        tabCurrentIndex: 1
      }
    })
  }

  const getUserPermission = async () => {
    return props.onEvent({
      message: "getUserPermission",
      params: {
        "config_TPD_PRE_ADMIN_TIME": 0
      }
    })
  }

  const getUserTokenize = async (code) => {
    return props.onEvent({
      message: "getUserTokenize",
      params: {
        code
      }
    })
  }

  const getClinicalTermSet = async (params) => {
    return props.onEvent({
      message: "getClinicalTermSet",
      params
    })
  }

  // ---------------------------- FETCH API, method: POST ---------------------
  const postCreateMedAdminRecord = async (items) => {
    return await props.onEvent({
      message: "postCreateMedAdminRecord",
      params: {
        action: 'ADMIN',
        items,
        nurse1: showInputNurse.nurse1.token,
        nurse2: showInputNurse.nurse2.token,
        admin_date: props.filterMedRecord.date,
        rate: '',
        remark: ''
      }
    })
  }

  // ---------------------------- FETCH API, method: PUT ---------------------
  const putDrugOrderItemIdAdminDivision = async (id, params) => {
    return await props.onEvent({
      message: "putDrugOrderItemIdAdminDivision",
      params: {
        drugOrderItemId: id,
        data: params
      }
    })
  }

  const putDrugOrderItemIdAddStdTimeManual = async (id, params) => {
    return await props.onEvent({
      message: "putDrugOrderItemIdAddStdTimeManual",
      params: {
        drugOrderItemId: id,
        data: params
      }
    })
  }

  const putDrugOrderItemIdEditStdTimeManual = async (id, params) => {
    return await props.onEvent({
      message: "putDrugOrderItemIdEditStdTimeManual",
      params: {
        drugOrderItemId: id,
        data: params
      }
    })
  }

  const putUpdateMedAdminRecord = async (id, params) => {
    return await props.onEvent({
      message: "putUpdateMedAdminRecord",
      params: {
        medicationRecordId: id,
        data: params
      }
    })
  }

  // ---------------------------- END FETCH API -----------------------------

  const createPopup = (
    textContent: string,
    textLabel: string | JSX.Element,
    config: PopupConfig) => {

    return <Popup
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "offsetParent",
        },
      }}
      offset={config.offset}
      trigger={
        typeof textLabel === "string" ?
          <Label
            size="tiny"
            circular
            style={{ cursor: "pointer" }}
            color={config.color}
          >
            {textLabel}
          </Label> : textLabel
      }
      wide
      inverted={config.inverted}
      position={config.position || "bottom left"}
      content={
        <div
          dangerouslySetInnerHTML={{
            __html: textContent
          }}
        />
      }
    />
  }

  const adminTimeStatus = (value) => {
    let style = {
      backgroundColor: 'gray',
      cursor: ''
    }
    let innerHTML = ""

    if (!value) {
      return { style, innerHTML }
    }
    if (value.is_editable) {
      style.backgroundColor = 'lightyellow'
    }

    if (value.is_omitted) {
      style.backgroundColor = '#ff8383'
    }
    if (style.backgroundColor !== 'gray') {
      value.is_editable = true
      style.cursor = 'pointer'
    }
    innerHTML = value.summary || '&nbsp;';
    return { style, innerHTML }
  }

  const findDrugBarcode = (id, time?: string) => {
    const params: any = { drug_item_id: id }

    if (time) {
      params.expected_admin_time = time
    }
    return _.find(drugBarcode, params)
  }

  const findDrugOrder = (id: number, key?: string) => {
    const findOrder = _.map(props.medicationRecord, (data) =>
      _.find(data.items, (item) =>
        key === "product" ? (item.drug_info.product === id) : (item.drug_info.id === id)
      )
    )

    return _.without(findOrder, undefined)?.[0]
  }

  const onSetStyleError = (key: keyof InputNurseType) => {
    setShowInputNurse({
      ...showInputNurse,
      [key]: {
        ...showInputNurse[key],
        error: true
      }
    })
  }

  const onSetDrugBarcode = (drug_info, medrecord, isNotFilter = false) => {
    if (!medrecord?.can_admin) {
      setOpenModError({
        ...openModError,
        open: true,
        error: "รายการยานี้ยังไม่ Pre Admin<br/>ไม่สามารถ Admin ได้",
        type: "warning",
        title: `ฉลากยาของ ${displayDrugName(drug_info)}`
      })
      return
    }
    if (medrecord.admin_time) {
      setOpenModError({
        ...openModError,
        open: true,
        error: "รายการยานี้ Admin แล้ว",
        type: "warning",
        title: `ฉลากยาของ ${displayDrugName(drug_info)}`
      })
      return
    }
    const find = findDrugBarcode(medrecord.drug_order_item_id)

    if (find && !isNotFilter) {
      const filter = _.reject(drugBarcode, { drug_item_id: find?.drug_item_id })

      setDrugBarcode(filter)
    } else {
      const curentTime = currentTime()
      const { expected_admin_time,
        expected_admin_date,
        drug_order_item_id,
        reference_standard_admin_time } = medrecord

      setDrugBarcode([...drugBarcode, {
        drug_item_id: drug_order_item_id,
        expected_admin_date,
        expected_admin_time,
        admin_time: curentTime,
        reference_standard_admin_time,
        original: medrecord
      }])
    }

  }

  const handleOnSelectWard = (_ev: any, type) => {
    props.onSetFilter("ward", type.value)
  }

  const handleOnKeyPress = async (ev) => {
    if (ev.key === "Enter") {
      ev.preventDefault()
      const value = ev.target.value
      const data = findDrugOrder(+value.replace(/^D10/i, ""))

      if (data) {
        handleClickCheckbox(data.medication_record, data.drug_info, true)
        ev.target.value = ""
      }
    }
  }

  const handleOnClickDetail = (drugInfo: any) => {
    setOpenModalDetail("ADD")
    setRemarkDivision(props.django?.division?.id)
    setTimeout(() => {
      textAreaRef.current.value = drugInfo.admin_remark || ""
    });
    if (drugInfo.admin_division) {
      setRemarkDivision(drugInfo.admin_division)
    }
    setSelectedDetail(drugInfo)
  }

  const reduceCreateOrUpdateDrug = () => {
    return drugBarcode.reduce((result, item) => {
      const cloneItem = { ...item }
      const { original } = cloneItem
      delete cloneItem.original
      if (!original.medication_record_id) {
        result[0].push(cloneItem)
      } else {
        const { nurse1, nurse2, rate = "", reason, remark, medication_record_id } = original
        result[1].push({
          action: "ADMIN",
          admin_date: props.filterMedRecord?.date,
          items: [cloneItem],
          nurse1: nurse1 || showInputNurse.nurse1.token,
          nurse2,
          rate,
          reason,
          remark,
          medication_record_id
        })
      }
      return result
    }, [[], []] as any[][])
  }

  const onSetErrorAdmin = (err) => {
    if (err.hasOwnProperty("nurse1")) {
      setErrMessageNurse1(err)
      onSetStyleError("nurse1")
      return
    }
    setOpenModError({
      ...openModError,
      open: true,
      error: err
    })
  }

  const handleOnSaveOrder = async () => {
    if (!drugBarcode[0]) {
      setOpenModError({
        ...openModError,
        open: true,
        error: "กรุณาสแกนฉลากยาก่อนบันทึก",
        type: "warning"
      })
      return
    }
    const [postData, putData] = reduceCreateOrUpdateDrug()
    if (postData[0]) {
      const [, err] = await postCreateMedAdminRecord(postData)

      if (err) {
        onSetErrorAdmin(err)
        return
      }
    }

    if (putData[0]) {
      for (const data of putData) {
        const { medication_record_id } = data
        delete data.medication_record_id
        const [, err] = await putUpdateMedAdminRecord(medication_record_id, data)
        if (err) {
          onSetErrorAdmin(err)
          return
        }
      }
    }
    setOpenModSuccess(true)
    onFetchTableData(props.django)
    setShowInputNurse(initialInput)
  }

  const handleSaveRemark = async () => {
    const params = {
      admin_division: remarkDivision,
      admin_remark: textAreaRef.current.value
    }

    setIsLoadingModal(true)
    const [, err] = await putDrugOrderItemIdAdminDivision(selectedDetail.id, params)

    setIsLoadingModal(false)
    if (err) {
      setOpenModError({
        ...openModError,
        open: true,
        error: err
      })
    } else {
      setOpenModSuccess(true)
      setSelectedDetail(null)
      onFetchTableData(props.django)
      handleCloseModalDetail()
    }

  }

  const handleCloseModalDetail = () => {
    setOpenModalDetail("")
  }

  const handleOnSaveDrug = () => {
    setOpenModalRemark(false)
    setSelectedDrugItem(null)
    setOpenModSuccess(true)
    onFetchTableData(props.django)
  }

  const handleChangeToken = (key: keyof InputNurseType, token: any, error = null) => {

    setShowInputNurse({
      ...showInputNurse,
      [key]: {
        ...showInputNurse[key],
        token
      }
    })
    if (key === "nurse1") {
      setErrMessageNurse1(error)
    }

    if (error) {
      onSetStyleError(key)
    }
  }

  const handleOnSaveReason = async () => {
    const { time, token, id, timeOld } = correctionReason
    const params: any = {
      remark: correctionReason.reason,
      user: token
    }

    let response = []
    setIsLoadingModal(true)
    if (timeOld) {
      params.std_time_old = timeOld
      params.std_time_new = time
      response = await putDrugOrderItemIdEditStdTimeManual(id, params)
    } else {
      params.std_time = time
      response = await putDrugOrderItemIdAddStdTimeManual(id, params)
    }
    const [, err] = response

    setIsLoadingModal(false)
    if (err) {
      setOpenModError({
        ...openModError,
        open: true,
        error: err
      })
    } else {
      setOpenModSuccess(true)
      handleCloseCorReason()
      onFetchTableData(props.django)
    }
  }

  const handleCloseCorReason = () => {
    setCorrectionReason(initialCorReason)
    setReason("")
    setOpenModalReason(false)
  }

  const handleClickCheckbox = async (medication_record, drug_info, isNotFilter = false) => {
    const [res]: any[] = await getUserPermission()
    const present = moment().format('HH:mm:ss');
    const travelTime = moment().add(`${res.config_TPD_PRE_ADMIN_TIME}`, 'minutes').format('HH:mm:ss');
    const filterMedRecord = medication_record.filter((item) => present <= item.expected_admin_time &&
      item.expected_admin_time <= travelTime)
    const findMedRecord = filterMedRecord?.find((item) => !item.admin_time)

    const checkedDrug = findMedRecord || filterMedRecord[0]
    if (checkedDrug) {
      onSetDrugBarcode(drug_info, checkedDrug, isNotFilter)
    } else {
      const find = findDrugBarcode(drug_info.id)

      if (find) {
        const filter = _.reject(drugBarcode, { drug_item_id: find?.drug_item_id })

        setDrugBarcode(filter)
      } else {
        setOpenModError({
          ...openModError,
          open: true,
          error: "ไม่ตรง Standard time",
          type: "warning",
          title: `ฉลากยาของ ${displayDrugName(drug_info)}`
        })
      }
    }
  }

  const handleClickAddStdTime = async () => {
    if (!reasonList[0]) {
      const [res] = await getClinicalTermSet({ questionList: ["ADD_STD_TIME_REMARK"] })

      if (res?.ADD_STD_TIME_REMARK) {
        setReasonList(res.ADD_STD_TIME_REMARK.items)
      }
    }
    setOpenModalReason(true)
  }

  const handleClickEditStdTime = async (drugInfo, timeManual) => {
    const { manual_remark, expected_admin_time, manual_user } = timeManual
    const expectedTime = expected_admin_time?.replace(/:\w+$/i, "")
    const userCode = manual_user.match(/\[(.*?)\]/i)[0]
    const [res, err] = await getUserTokenize(userCode.match(/\w+/i)[0])

    setCorrectionReason({
      ...correctionReason,
      reason: manual_remark,
      time: expectedTime,
      id: drugInfo.id,
      timeOld: expectedTime,
      token: res.token
    })
    setReason(manual_remark)
    handleClickAddStdTime()
  }

  //* Handle click order for redirect to main
  const handleGetProps = (() => {
    return {
      onDoubleClick: (_ev: any, row: any) => {
        if(props.onSelectRow){
          return props.onSelectRow({ emr: row.emr, patient: row.patient });
        }
        window.location.href = `?app=MainMedAdmin&patient_id=${row.patient}&emr=${row.emr}`;
      },
      style: {
        cursor: "pointer"
      }
    }
  })

  const handleClickMedRecord = (drugInfo, medRecord) => {
    if (!medRecord?.is_omitted && !props.checkDrugAdminByCanAdmin) {
      setOpenModError({
        ...openModError,
        open: true,
        error: "รายการยานี้ยังไม่ Pre Admin<br/>ไม่สามารถ Admin ได้",
        type: "warning",
        title: `ฉลากยาของ ${displayDrugName(drugInfo)}`,
      });
      return;
    }
    setOpenModalRemark(true)
    setSelectedDrugItem({
      ...medRecord,
      admin_remark: drugInfo.admin_remark,
      admin_division: drugInfo.admin_division,
      date: props.filterMedRecord.date
    })
  }

  const createMedicationDoseLabel = (drug_info, isSolvent = false) => {
    return (
      <Form.Field
        width={5}
        style={{
          justifyContent: "flex-end",
          display: "grid",
          marginRight: isSolvent ? "1.9rem" : "",
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {/* TODO เรียกใช้ Component แบบนี้เพราะ จะได้ไม่กระทบของเดิม CNMI และเรียกใช้ Component ได้โดยตรงจาก HISV3 */}
          {SubDrugOrderStatusAlert ? (
            <SubDrugOrderStatusAlert
              item={{ ...drug_info, claim_payload: null }}
              isFemale={true}
              onEvent={props.originalOnEvent}
              targetStateOrder={"medRecordSummary"}
            />
          ) : (
            <>
              {drug_info.alerts["I"] && (
                <Label
                  circular
                  size="tiny"
                  color="orange"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpenModDescription({
                      open: true,
                      title: `แจ้งเตือน !!! Drug Interaction`,
                      textContent: drug_info.alerts["I"].info_tpd,
                      color: "orange",
                    });
                  }}
                >
                  I
                </Label>
              )}
              {drug_info.pregcat &&
                drug_info.pregcat !== "NA" &&
                createPopup(drug_info.pregcat_description, "P", {
                  color: "pink",
                  offset: -14,
                })}
              {drug_info.alerts["H"] &&
                createPopup(drug_info.alerts["H"], "HAD", {
                  color: "red",
                })}
              {drug_info.alerts["L"] &&
                createPopup(drug_info.alerts["L"], "LASA", {
                  color: "yellow",
                })}
            </>
          )}
        </div>
        <p style={{ marginTop: "5px" }} />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: isSolvent ? "" : "0em 0.86666667em 0em 0em",
          }}
        >
          {drug_info.is_external && (
            <Label size="tiny" color="blue">
              External
            </Label>
          )}
        </div>
      </Form.Field>
    );
  }

  const createDrugLabel = (drug_info) => {
    return <Label
      circular
      size="tiny"
      style={{ cursor: "pointer" }}
      onClick={() => {
        setOpenModDescription({
          open: true,
          title: `ฉลากยาของ ${displayDrugName(drug_info)}`,
          textContent: drug_info.label,
        })
      }}>
      <Icon name="info" style={{ margin: "0" }} />
    </Label>
  }

  const createAddStdTime = (drugInfo) => {
    return <>
      <br />
      <div style={{ width: "100%", marginLeft: "-8px" }}>
        <Button icon='plus'
          size="tiny"
          style={{ transform: "scale(0.7)" }}
          color="green"
          onClick={() => {
            setCorrectionReason({
              ...correctionReason,
              id: drugInfo.id
            })
            handleClickAddStdTime()
          }}
        />
      </div>
    </>
  }

  const displayDrugName = (drug_info) => {
    return drug_info.changing_detail?.split("\n")[0] || drug_info.name
  }

  const gridColumns = [
    {
      Header: "Room",
      columns: [
        {
          Header: "",
          width: 120,
          accessor: " ",
          Cell: (row) => {
            const { drug_info, medication_record = [] } = row
            const findTime = medication_record?.find((item) => !item.admin_time)

            return <div style={{
              display: "flex", justifyContent: "center",
              height: "100%", alignItems: "center"
            }}>
              {findTime ?
                (<>
                  {drug_info.type_name === "CONTINUE" ?
                    <Checkbox
                      style={{ transform: "scale(1.3)" }}
                      checked={!!findDrugBarcode(drug_info.id)}
                      onChange={() =>
                        handleClickCheckbox(medication_record, drug_info)
                      }
                    /> : null
                  }
                </>) : null}
            </div>
          },
        },
      ],
      Cell: (row: any) => <b>{row.room_no}</b>,
    },
    {
      Header: "HN/AN",
      width: 100,
      Cell: (row: any) => (
        <Form>
          <Form.Field>
            <b>{row.hn}</b>
            <p>{row.encounter_number}</p>
          </Form.Field>
        </Form>
      ),
      columns: [
        {
          Header: "MEDICATION AND DOSAGE",
          accessor: "hn",
          colSpan: 2,
          Cell: (row) => {
            const { drug_info } = row
            const { solvent } = drug_info
            return drug_info ? (
              <Form>
                <Form.Group
                  inline
                  style={{ alignItems: "flex-start", marginBottom: "0px" }}
                >
                  <Form.Field width={2}>
                    {drug_info.type_name && (
                      <Label
                        color={TYPE_COLOR[drug_info.type_name] || undefined}
                        style={{ textTransform: "lowercase" }}
                      >
                        {drug_info.type_name.replace(/_/g, " ")}
                      </Label>
                    )}
                  </Form.Field>
                  <Form.Field width={9}>
                    <div>
                      <b>{displayDrugName(drug_info)}</b>
                      <DisplayDrugDetail
                        drugInfo={drug_info}
                        byLabel={props.drugDetailByLabel}
                      />
                    </div>
                  </Form.Field>
                  {createMedicationDoseLabel(drug_info)}
                </Form.Group>
                {solvent && (
                  <Form.Group style={{ marginTop: "5px" }}>
                    <Form.Field width={2}></Form.Field>
                    <Form.Field width={9}>
                      <div>
                        <b>+ {displayDrugName(solvent)}</b>
                        <p style={{ margin: "0.2em" }}>
                          Conc. {solvent.conc} VOLUME: {solvent.volume} Rate:{" "}
                          {solvent.rate} Drip in {solvent.drip}
                        </p>
                      </div>
                    </Form.Field>
                    {createMedicationDoseLabel(solvent, true)}
                  </Form.Group>
                )}
                <Form.Group inline>
                  <Form.Field width={2}></Form.Field>
                  <Form.Field>
                    <Button
                      content="Detail"
                      color="orange"
                      size="tiny"
                      className="button-tiny"
                      onClick={() => {
                        handleOnClickDetail(drug_info);
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <div>
                      {drug_info.admin_remark?.split("\n").map((str, index) => (
                        <div key={"remark" + index}>{str}</div>
                      ))}
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form>
            ) : null;
          },

        },
      ],
      getProps: handleGetProps,

    },
    {
      Header: "ผู้ป่วย",
      accessor: "",
      width: 650,
      rowSpan: 1,
      colSpan: 2,
      Cell: (row: any) => <b>{row.patient_full_name}</b>,
      getProps: handleGetProps
    },
    {
      Header: "",
      accessor: "",
      columns: [
        {
          Header: "ออกฉลากยา",
          accessor: "",
          width: 100,
          Cell: (row) => {
            const { drug_info } = row

            return <div style={{
              display: "flex", justifyContent: "center",
            }}>
              <Form>
                <Form.Group>
                  <Form.Field>
                    {createDrugLabel(drug_info)}
                  </Form.Field>
                </Form.Group>
                {
                  drug_info.solvent &&
                  <Form.Group style={{
                    marginTop: "24px"
                  }}>
                    <Form.Field>
                      {createDrugLabel(drug_info.solvent)}
                    </Form.Field>
                  </Form.Group>
                }

              </Form>
            </div>

          }

        },
      ],
    },
    {
      Header: "อายุ",
      Cell: (row: any) => <b>{row.patient_age}</b>,
      columns: [
        {
          Header: "Std Time",
          accessor: "age",
          width: 70,
          Cell: (row) => {
            const { medication_record, drug_info } = row,
              { length } = medication_record

            return drug_info.type_name === "CONTINUE" ? (
              !medication_record[0] && drug_info.can_add_std_time ?
                <Form style={{
                  display: "grid",
                  alignItems: "flex-end",
                  height: "100%",
                  marginLeft: "11px"
                }}>
                  {createAddStdTime(drug_info)}
                </Form> :
                _.map(medication_record, (item: any, index: number) =>
                  <Form style={{
                    display: "grid", margin: "0 auto",
                    alignItems: "center"
                  }}>
                    <Form.Group inline>
                      <Form.Field
                        width={15}
                        style={{ paddingRight: '0' }}>
                        <b>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.expected_admin_time?.replace(/:\w+$/i, "") || '&nbsp;'
                            }}
                          />
                        </b>
                      </Form.Field>
                      {
                        item.is_manual_time &&
                        <Form.Field width={1} style={{
                          position: "absolute",
                          right: "-15px"
                        }}>
                          {createPopup(
                            `<p>Edited: ${item.manual_user}</p>
                          <p>${item.manual_remark}</p>`,
                            <div
                              style={{
                                position: "absolute",
                                cursor: "pointer",
                                fontSize: "1.5em",
                                color: "red",
                                marginLeft: "3px"
                              }}
                              onClick={() => {
                                handleClickEditStdTime(drug_info, item)
                              }}
                            > *</div>,
                            {
                              offset: 15,
                              inverted: true,
                              position: "bottom right"
                            }
                          )}
                        </Form.Field>
                      }
                    </Form.Group>
                    {
                      (drug_info.can_add_std_time && index === length - 1) &&
                      createAddStdTime(drug_info)
                    }
                  </Form>

                )
            ) : null
          }
        },
        {
          Header: "Admin Time",
          accessor: "",
          width: 100,
          Cell: (row) => {
            const { medication_record, drug_info } = row

            return _.map(medication_record, (item: any) => {
              const findDrug = findDrugBarcode(item.drug_order_item_id, item.expected_admin_time)
              const isEditable = item?.on_date_status === "ACTIVE" || drug_info.type_name !== "CONTINUE"
              const adminStatus = adminTimeStatus(item)
              return {
                Cell: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: findDrug ? findDrug.admin_time :
                        item.admin_time?.replace(/:\w+$/i, "") || "&nbsp"
                    }}
                  />

                ),
                getProps: {
                  onClick: (ev, rows, self) => {
                    if (self.getProps.style?.cursor === "pointer") {
                      handleClickMedRecord(drug_info, item)
                    }
                  },
                  style: {
                    ...adminStatus.style,
                    cursor: isEditable ? adminStatus.style.cursor : "",
                    backgroundColor: findDrug ? "#c8ffcd" : "lightyellow",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "center"
                  }
                }
              }
            }
            )

          },
          getProps: (row) => ({
            style: {
              backgroundColor: !row.medication_record[0] ? "lightyellow" : "",

            }
          })

        },
        {
          Header: "Nurse",
          accessor: "",
          width: 250,
          Cell: (row) => {
            const { medication_record, drug_info } = row

            return _.map(medication_record, (item: any) => {

              const isEditable = item?.on_date_status === "ACTIVE" || drug_info.type_name !== "CONTINUE"
              const isReasonAdmin = item?.summary && !item?.is_omitted && item?.reason
              const adminStatus = adminTimeStatus(item)
              const statusText = item?.on_date_status === "HELD" ? "HOLD" : item?.on_date_status
              const reason = adminRemarkList?.find((list) => list.id === item?.reason)
              return {
                Cell: isEditable ? (<Form>
                  <Form.Group >
                    <Form.Field
                      width={15}
                      style={{ paddingRight: '0' }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: adminStatus.innerHTML
                        }}
                      />
                    </Form.Field>
                    {
                      isReasonAdmin &&
                      <Form.Field width={1} style={{
                        position: "absolute",
                        right: "5px"
                      }}>
                        {
                          createPopup(
                            `<p>${reason?.name}</p>`,
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                color: "red",
                                fontSize: "1.5em",
                                cursor: "pointer",
                              }}>
                              *</div>,
                            {
                              offset: 15,
                              inverted: true,
                              position: "bottom right"
                            }
                          )
                        }

                      </Form.Field>
                    }
                  </Form.Group>
                </Form>) :
                  <div>{statusText}</div>
                ,
                getProps: {
                  onClick: (ev, rows, self) => {
                    if (self.getProps.style?.cursor === "pointer") {
                      handleClickMedRecord(drug_info, item)
                    }
                  },
                  style: isEditable ?
                    {
                      ...adminStatus.style,
                      backgroundColor: isReasonAdmin ? "lightyellow" : adminStatus.style.backgroundColor
                    } : {
                      backgroundColor: item?.on_date_status === "OFF" ? "#474744" : "grey",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontWeight: "500",
                    }
                }
              }
            }
            )
          },
          getProps: (row) => ({

            style: {
              backgroundColor: !row.medication_record[0] ? "lightyellow" : "",
            }
          })
        },
      ],
      getProps: handleGetProps
    },
  ];

  const mapOptions = (option) => _.map(option, (item, index) => ({ key: index, text: item.name, value: item.id }))
  /**
   * option choose admin division
   */
  const adminDivisionOptions = useMemo(() => {
    return props.division ?
      [{ key: "", text: "All", value: " " },
      ...mapOptions(props.division)
      ] : []
  }, [props.division])

  const wardTypeOptions = useMemo(() => {
    if (props.wardTypeList) {
      return [{ key: "", text: "All", value: " " },
      ...mapOptions(props.wardTypeList)
      ]
    } else {
      return []
    }
  }, [props.wardTypeList, props.django])

  const reasonOptions = useMemo(() => {
    return reasonList ?
      _.map(reasonList, (item, index) => ({ key: index, text: item.name, value: item.name }))
      : []
  }, [reasonList])

  return (
    <>
      <Segment className="medication-admin-ward-table">
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        {/* Form display and filter queue-ward list*/}
        <Form>
          <Form.Group inline>
            <Form.Field >
              <label>Division/Ward</label>
            </Form.Field>
            <Form.Field width={3}>
              <Dropdown
                size="tiny"
                className="fluidDropdown"
                selection
                search
                value={props.filterMedRecord.ward}
                options={wardTypeOptions}
                onChange={handleOnSelectWard}
                style={{ marginRight: 14 }}
              />
            </Form.Field>

            <Form.Field >
              <label>วันที่</label>
            </Form.Field>
            <Form.Field >
              <DateTextBox
                value={props.filterMedRecord.date}
                onChange={(value) => props.onSetFilter("date", value)}
              />
            </Form.Field>
            <Form.Field
              style={{
                display: "flex",
                paddingRight: "5px"
              }}
            >
              <Checkbox
                checked={props.filterMedRecord.checkedTime}
                onChange={() =>
                  props.onSetFilter("checkedTime", !props.filterMedRecord.checkedTime)
                }
              />
            </Form.Field>

            <Form.Field width={2}>
              <label>เวลา</label>
              <TimeField
                input={<Input icon="clock outline" />}
                value={props.filterMedRecord.time}
                onChange={(_ev, value) => {
                  props.onSetFilter("time", value)
                }}
              />
            </Form.Field>

            <Form.Field >
              <label>Admin Division</label>
            </Form.Field>
            <Form.Field width={3}>
              <Dropdown
                size="tiny"
                className="fluidDropdown"
                selection
                search
                value={props.filterMedRecord.division}
                options={adminDivisionOptions}
                onChange={(_ev, input) => {
                  props.onSetFilter("division", input.value)
                }}
                style={{ marginRight: 14 }}
              />
            </Form.Field>

            <Form.Field >
              <Button color="blue" onClick={() =>
                onFetchTableData(props.django)
              }>ค้นหา
              </Button>
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field>
              <label>Barcode HN</label>
            </Form.Field>
            <Form.Field>
              <Input
                ref={(instance: any) => {
                  if (instance) {
                    if (props.barcodeHNRef.current) {
                      const value = props.barcodeHNRef.current.value

                      props.barcodeHNRef.current = instance.inputRef.current
                      props.barcodeHNRef.current.value = value
                    } else {
                      props.barcodeHNRef.current = instance.inputRef.current
                    }
                  }
                }}
              />
            </Form.Field>
            <Form.Field>
              <Input
                placeholder="สแกนฉลากยา"
                onKeyPress={handleOnKeyPress}
              />
            </Form.Field>
          </Form.Group>
        </Form>

        {/* Table display queue-ward list */}
        <SemanticTable
          data={props.medicationRecord}
          columns={gridColumns as any[]}
          headerLevel={0}
          toggleChildBy="items"
          defaultToggle={true}
          pageSize={5}
          tableHeight={props.tableHeight}
        />

        <br />
        <Form>
          <Form.Group inline>
            <Form.Field width={10}
              style={{
                display: "flex",
                justifyContent: "flex-end"
              }}>
              <ErrorMessage error={errMessageNurse1} style={{ width: "100%" }} />
            </Form.Field>
            <Form.Field >
              <label >รหัสพยาบาล 1</label>
            </Form.Field>
            <Form.Field width={2}>
              <SubUserToken
                token={showInputNurse.nurse1.token}
                controller={props.controller}
                error={showInputNurse.nurse1.error}
                onChangeToken={(token, error) => {
                  handleChangeToken("nurse1", token, error)
                }}
              />
            </Form.Field>
            <Form.Field width={1}>
              <Button color="green" size="tiny" onClick={handleOnSaveOrder}>SAVE</Button>
            </Form.Field>
          </Form.Group>
        </Form>

        <ModInfo
          type={openModError.type || "error"}
          titleName={openModError.title}
          closeOnDimmerClick
          open={openModError.open}
          onApprove={() => setOpenModError(initialModError)}
          onClose={() => setOpenModError(initialModError)}
        >
          {typeof openModError.error === "string" ?
            <div
              dangerouslySetInnerHTML={{
                __html: openModError.error
              }}
            /> :
            <ErrorMessage error={openModError.error} />
          }
        </ModInfo>

        <ModInfo
          color={openModDescription.color || "blue"}
          titleName={openModDescription.title}
          closeOnDimmerClick
          open={openModDescription.open}
          onApprove={() => setOpenModDescription(initialModDescription)}
          onClose={() => setOpenModDescription(initialModDescription)}
        >
          <div style={{
            display: "grid",
            textAlign: "left"
          }}>
            {openModDescription.textContent?.split('\n').map((str, index) => <p key={"content" + index}>{str}</p>)}
          </div>
        </ModInfo>

        <ModInfo
          type="success"
          titleName={""}
          closeOnDimmerClick
          open={openModSuccess}
          onApprove={() => setOpenModSuccess(false)}
          onClose={() => setOpenModSuccess(false)}
        />

        <Modal
          size="tiny"
          open={openModalReason}
          style={{ margin: "auto" }}
          onClose={handleCloseCorReason}
        >
          <Segment>
            <Dimmer active={isLoadingModal} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
            <Grid >
              <Grid.Row >
                <Grid.Column
                  textAlign="right"
                  width={16}
                >
                  <Icon
                    name="close"
                    size="large"
                    color="red"
                    style={{ cursor: "pointer" }}
                    onClick={handleCloseCorReason}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Form>
              <Form.Group inline>
                <Form.Field width={4}>
                  <label >Time</label>
                </Form.Field>
                <Form.Field width={5}>
                  <TimeField
                    input={<Input icon="clock outline" />}
                    value={correctionReason.time || props.filterMedRecord.time}
                    onChange={(_ev, value) => {
                      setCorrectionReason({
                        ...correctionReason,
                        time: value
                      })
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field width={4}>
                  <label >เหตุผลการแก้ไข</label>
                </Form.Field>
                <Form.Field width={12}>
                  <Dropdown
                    size="tiny"
                    className="fluidDropdown"
                    selection
                    search
                    value={reason}
                    options={reasonOptions}
                    onChange={(_ev, input) => {
                      setCorrectionReason({
                        ...correctionReason,
                        reason: input.value
                      })
                      setReason(input.value)
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field width={4}>
                  <label >รหัสพยาบาล</label>
                </Form.Field>
                <Form.Field width={7}>
                  <SubUserToken
                    token={correctionReason.token}
                    controller={props.controller}
                    error={correctionReason.error}
                    onChangeToken={(token) => {
                      setCorrectionReason({
                        ...correctionReason,
                        token
                      })
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field width={12} />
                <Form.Field width={2} >
                  <Button color="green" onClick={handleOnSaveReason}>บันทึก</Button>
                </Form.Field>
              </Form.Group>
            </Form>
          </Segment>
        </Modal>

        {
          props.django &&
          <ModMedicationRecordDetail
            type="ADMIN"
            DJANGO={props.django}
            orderItemId={selectedDrugItem?.drug_order_item_id}
            controller={props.controller}
            open={openModalRemark}
            onClose={() => {
              setOpenModalRemark(false)
              setSelectedDrugItem(null)
            }
            }
            onEvent={props.onEvent}
            record={selectedDrugItem}
            onSaved={handleOnSaveDrug}
          />
        }

        {/* Modal manage admin_remark */}
        <Modal
          size="tiny"
          open={!!openModalDetail}
          style={{ margin: "auto" }}
          onClose={handleCloseModalDetail}
        >
          <Form>
            <Dimmer active={isLoadingModal} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
            <Form.Group >
              <Form.Field inline width={16} >
                <Grid >
                  <Grid.Row >
                    <Grid.Column
                      textAlign="right"
                      width={16}
                    >
                      <Icon
                        name="close"
                        color="red"
                        style={{ cursor: "pointer" }}
                        onClick={handleCloseModalDetail}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
            </Form.Group>

            <Form.Group inline style={{ paddingLeft: "10px" }}>
              <Form.Field width={12}>
                <Header as='h5'>Admin Division</Header>
                <Dropdown
                  size="tiny"
                  className="fluidDropdown"
                  selection
                  search
                  value={remarkDivision}
                  options={adminDivisionOptions}
                  onChange={(_ev, input) => {
                    setRemarkDivision(input.value)
                  }}
                  style={{ marginRight: 14 }}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group inline style={{ paddingLeft: "10px" }}>
              <Form.Field width={16}>
                <Header as='h5'>Remark</Header>
                <TextArea
                  placeholder=""
                  rows={3}
                  style={{ resize: "none" }}
                  ref={(instance: any) => {
                    if (instance) {
                      textAreaRef.current = instance.ref.current
                    }
                  }}
                />
              </Form.Field>
            </Form.Group>

            <br />
            {
              openModalDetail === "ADD" &&
              <Button
                color="green"
                fluid
                onClick={handleSaveRemark}
              >SAVE</Button>
            }
          </Form>
        </Modal>

      </Segment>
    </>

  )
}

CardAdminQueueWard.defaultProps = CardAdminQueueWardInitial

export default React.memo(CardAdminQueueWard)

