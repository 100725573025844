import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Input,
  Dropdown,
  Button
} from 'semantic-ui-react'

const CardDrugContinuePlanUX = (props: any) => {
    return(
      <div>
        <div
          style={{ height: "80px" , padding: "0 5px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(3, 1fr)","gridTemplateColumns":"repeat(38, 1fr)"}}>
            <div
              style={{ gridRow: "2/3", gridColumn: "1/8" }}>
              <Button
                className={props.drugContinueFilter?.status === "only_open" ? "fluid" : "basic fluid"}
                color="blue"
                name="only_open"
                onClick={props.onSetdrugContinueFilter}>
                เฉพาะรายการที่ยังไม่ปิด
              </Button>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "9/15" }}>
              <Button
                className={props.drugContinueFilter?.status === "only_new" ? "fluid" : "basic fluid"}
                color="blue"
                name="only_new"
                onClick={props.onSetdrugContinueFilter}>
                เฉพาะรายการใหม่
              </Button>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "16/22" }}>
              <Button
                className={props.drugContinueFilter?.status === "only_active" ? "fluid" : "basic fluid"}
                color="blue"
                name="only_active"
                onClick={props.onSetdrugContinueFilter}>
                เฉพาะรายการปัจจุบัน
              </Button>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "23/27" }}>
              <Button
                className={props.drugContinueFilter?.status === "all" ? "fluid" : "basic fluid"}
                color="blue"
                name="all"
                onClick={props.onSetdrugContinueFilter}>
                แสดงทั้งหมด
              </Button>
            </div>
          </div>
        </div>
        <Table
          data={props.drugContinuePlanItems}
          getTrProps={props.drugListProps}
          headers="Drug, Dose, Unit, Route, Site, Frequency, Method, PRN, Qty, Status, Action"
          keys="title_status, dose, unitText, routeText, siteText, frequencyText, methodText, prn, sending_interval, status,action"
          minRows="10"
          showPagination={false}
          style={{height:"35vh", padding: "0 5px"}}
          widths=",80,80,,130,130,130,80,80,,80">
        </Table>
        <div
          style={{height: "320px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(20, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
            <div
              style={{ gridRow: "2/4", gridColumn: "2/4",  padding:"5px"}}>
              ยา
            </div>
            <div
              style={{ padding: "5px", "gridRow":"2/4","gridColumn":"20/22",}}>
              
              <label>
                Dose
              </label>
              <label
                style={{ display: props.DrugSelectSequence?.require_unit ? "" : "none", color: "red" }}>
                *
              </label>
            </div>
            <div
              style={{"gridRow":"2/4","gridColumn":"4/13","position":"relative"}}>
              <div
                style={{display: props.showSearchResult ? "block" : "none", position: "absolute", zIndex: 1000, bottom: 50, padding: "5px", backgroundColor: "#eeeeee"}}>
                
                <Table
                  data={props.drugSearchResult}
                  getTrProps={props.drugSearchProps}
                  headers="Generic Name, Drug Name, Form, Strength, Contain,หน่วยขาย,ราคา,บัญชี,status"
                  keys="generic_name,full_name,dosage_form_name,strength,container,stock_unit_name,price_unit,drug_list,material_status"
                  minRows="7"
                  showPagination={false}
                  style={{height: "300px", width: "60vw"}}>
                </Table>
              </div>
              <Input
                disabled={props.drugSearchLoading ? true : !props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes("REQUEST") || props.DrugSelectSequence?.allowed_actions?.includes("EDIT")) ? false : true}
                fluid={true}
                loading={props.drugSearchLoading}
                onChange={props.onDrugSearchTextChange}
                value={props.drugSearchText}>
              </Input>
            </div>
            <div
              style={{"gridRow":"2/4","gridColumn":"22/28"}}>
              <Input
                disabled={!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes("REQUEST") || props.DrugSelectSequence?.allowed_actions?.includes("EDIT")) ? false : true}
                fluid={true}
                name="dose"
                onChange={props.onPreviewDrugContinuePlan}
                value={props.DrugSelectSequence?.dose || ""}>
              </Input>
            </div>
            <div
              style={{"gridRow":"18/19","gridColumn":"15/47", display: "flex" }}>
              <Button
                color="grey"
                onClick={props.onClearDrugOrderForm}
                size="small">
                CLEAR
              </Button>
              <Button
                name="NOTE"
                onClick={props.onNoteAndVerifyAndCancelDrugContinuePlan}
                size="small"
                style={{ display: props.DrugSelectSequence?.allowed_actions?.includes("NOTE") ? "" : "none" }}>
                NOTE
              </Button>
              <div>
                {props.buttonEdit}
              </div>
              <div>
                {props.buttonEditQuantity}
              </div>
              <Button
                color="red"
                name="CANCEL"
                onClick={props.onNoteAndVerifyAndCancelDrugContinuePlan}
                size="small"
                style={{ display: props.DrugSelectSequence?.allowed_actions?.includes("CANCEL") && props.drugPermission?.action_TPD_DrugContinuePlan_CANCEL ? "" : "none" }}>
                CANCEL
              </Button>
              <div>
                {props.buttonRequest}
              </div>
              <Button
                color="red"
                name="REJECT"
                onClick={props.onNoteAndVerifyAndCancelDrugContinuePlan}
                size="small"
                style={{ display: props.DrugSelectSequence?.allowed_actions?.includes("REJECT") && props.drugPermission?.action_TPD_DrugContinuePlan_REJECT ? "" : "none" }}>
                REJECT
              </Button>
              <Button
                color="green"
                name="VERIFY"
                onClick={props.onNoteAndVerifyAndCancelDrugContinuePlan}
                size="small"
                style={{ display: props.DrugSelectSequence?.allowed_actions?.includes("VERIFY") && props.drugPermission?.action_TPD_DrugContinuePlan_VERIFY ? "" : "none" }}>
                VERIFY
              </Button>
              <Button
                color="orange"
                onClick={props.onOpenModSpecialDirection}
                size="small"
                style={{ display: props.modSpecialDirectionConfig?.showButton ? "" : "none" }}>
                รายละเอียดวิธีการใช้ยา
              </Button>
              <Button
                color="yellow"
                onClick={props.onOpenModSolventSelection}
                size="small"
                style={{ display: props.modSolventSelectionConfig?.showButtonSolvent ? "" : "none" }}>
                เลือกตัวทำละลาย
              </Button>
            </div>
            <div
              style={{ padding: "5px", "gridRow":"2/4","gridColumn":"29/32",}}>
              
              <label>
                Unit
              </label>
              <label
                style={{ display: props.DrugSelectSequence?.require_unit ? "" : "none", color: "red" }}>
                 *
              </label>
            </div>
            <div
              style={{"gridRow":"2/4","gridColumn":"32/38",}}>
              <Dropdown
                clearable={true}
                disabled={!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes("REQUEST") || props.DrugSelectSequence?.allowed_actions?.includes("EDIT")) ? false : true}
                fluid={true}
                name="unit"
                onChange={props.onPreviewDrugContinuePlan}
                options={props.drugUnits}
                search={true}
                selection={true}
                value={props.DrugSelectSequence?.unit || ""}>
              </Dropdown>
            </div>
            <div
              style={{"padding":"5px","gridRow":"5/7","gridColumn":"39/42"}}>
              
              <label>
                Frequency
              </label>
              <label
                style={{ display: props.DrugSelectSequence?.require_frequency ? "" : "none", color: "red" }}>
                 *
              </label>
            </div>
            <div
              style={{"gridRow":"5/7","gridColumn":"42/48"}}>
              <Dropdown
                clearable={true}
                disabled={!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes("REQUEST") || props.DrugSelectSequence?.allowed_actions?.includes("EDIT")) ? false : true}
                fluid={true}
                name="frequency"
                onChange={props.onPreviewDrugContinuePlan}
                options={props.drugFrequencies}
                search={true}
                selection={true}
                value={props.DrugSelectSequence?.frequency || ""}>
              </Dropdown>
            </div>
            <div
              style={{ padding: "5px", gridRow: "5/7", gridColumn: "12/14",}}>
              
              <label>
                Site
              </label>
              <label
                style={{ display: props.DrugSelectSequence?.require_site ? "" : "none", color: "red" }}>
                 *
              </label>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "14/19", }}>
              <Dropdown
                clearable={true}
                disabled={!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes("REQUEST") || props.DrugSelectSequence?.allowed_actions?.includes("EDIT")) ? false : true}
                fluid={true}
                name="site"
                onChange={props.onPreviewDrugContinuePlan}
                options={props.drugSites}
                search={true}
                selection={true}
                value={props.DrugSelectSequence?.site || ""}>
              </Dropdown>
            </div>
            <div
              style={{ padding: "5px",  gridRow: "5/7", gridColumn: "20/22", }}>
              
              <label>
                Route
              </label>
              <label
                style={{ display: props.DrugSelectSequence?.require_route ? "" : "none", color: "red" }}>
                 *
              </label>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "22/28", }}>
              <Dropdown
                clearable={true}
                disabled={!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes("REQUEST") || props.DrugSelectSequence?.allowed_actions?.includes("EDIT")) ? false : true}
                fluid={true}
                name="route"
                onChange={props.onPreviewDrugContinuePlan}
                options={props.drugRoutes}
                search={true}
                selection={true}
                value={props.DrugSelectSequence?.route || ""}>
              </Dropdown>
            </div>
            <div
              style={{ padding: "5px",  gridRow: "5/7", gridColumn: "29/32",}}>
              
              <label>
                Method
              </label>
              <label
                style={{ display: props.DrugSelectSequence?.require_method ? "" : "none", color: "red" }}>
                 *
              </label>
            </div>
            <div
              style={{ gridRow: "5/7", gridColumn: "32/38", }}>
              <Dropdown
                clearable={true}
                disabled={!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes("REQUEST") || props.DrugSelectSequence?.allowed_actions?.includes("EDIT")) ? false : true}
                fluid={true}
                name="method"
                onChange={props.onPreviewDrugContinuePlan}
                options={props.drugMethods}
                search={true}
                selection={true}
                value={props.DrugSelectSequence?.method || ""}>
              </Dropdown>
            </div>
            <div
              style={{ padding: "5px", gridRow: "8/10", gridColumn: "12/14" }}>
              prn.
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "14/19" }}>
              <Input
                fluid={true}
                name="prn"
                onChange={props.onUnPreviewDrugContinuePlan}
                value={props.DrugSelectSequence?.prn || ""}>
              </Input>
            </div>
            <div
              style={{ padding: "5px", gridRow: "8/10", gridColumn: "20/22" }}>
              est.
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "22/28" }}>
              <label>
                {props.DrugSelectSequence?.calculated_daily_quantity}
              </label>
            </div>
            <div
              style={{ padding: "5px", gridRow: "8/10", gridColumn: "29/32" }}>
              qty.
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "32/38" }}>
              <Input
                fluid={true}
                name="sending_quantity"
                onChange={props.onPreviewDrugContinuePlan}
                value={props.DrugSelectSequence?.sending_quantity || props.DrugSelectSequence?.calculated_daily_quantity || ""}>
              </Input>
            </div>
            <div
              style={{ padding: "5px", gridRow: "8/10", gridColumn: "39/42" }}>
              day
            </div>
            <div
              style={{ gridRow: "8/10", gridColumn: "42/48" }}>
              <Input
                fluid={true}
                name="sending_interval"
                onChange={props.onPreviewDrugContinuePlan}
                value={props.DrugSelectSequence?.sending_interval || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "11/13", gridColumn: "12/16" }}>
              ความต่อเนื่อง
            </div>
            <div
              style={{ gridRow: "11/13", gridColumn: "16/17" }}>
              <Button
                color="yellow"
                icon="edit"
                onClick={props.onOpenModPlanning}>
              </Button>
            </div>
            <div
              style={{ gridRow: "11/13", gridColumn: "17/22" }}>
              {props.planningData?.date_time_summary}
            </div>
            <div
              style={{ gridRow: "11/13", gridColumn: "22/23" }}>
              
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "32/38" }}>
            </div>
            <div
              style={{"gridRow":"2/4","gridColumn":"14/19",}}>
              
              <Button
                color={props.hasFactsheetData ? "blue" : undefined}
                disabled={!props.hasFactsheetData}
                fluid={true}
                onClick={props.onClickDrugFact}>
                DRUG FACT
              </Button>
            </div>
            <div
              style={{"gridRow":"5/7","gridColumn":"48/50", paddingLeft: "0.5rem", display: props.hideDrugDescription ? "none": ""}}>
              
              <Button
                className="circular"
                color="green"
                icon="clipboard list"
                onClick={props.onDrugDescription}
                style={{ padding: "0.5rem 0.59rem 0.6rem", fontSize: "1.45rem"}}>
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardDrugContinuePlanUX

export const screenPropsDefault = {"showSearchResult":true}

/* Date Time : Wed May 24 2023 05:09:17 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugContinuePlanItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.drugListProps"
        },
        "headers": {
          "type": "value",
          "value": "Drug, Dose, Unit, Route, Site, Frequency, Method, PRN, Qty, Status, Action"
        },
        "keys": {
          "type": "value",
          "value": "title_status, dose, unitText, routeText, siteText, frequencyText, methodText, prn, sending_interval, status,action"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"35vh\", padding: \"0 5px\"}"
        },
        "widths": {
          "type": "value",
          "value": ",80,80,,130,130,130,80,80,,80"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"320px\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "label": "divgrid",
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(20, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ยา"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"2/4\",  padding:\"5px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"2/4\",\"gridColumn\":\"4/13\",\"position\":\"relative\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"2/4\",\"gridColumn\":\"22/28\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Input",
      "parent": 6,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.drugSearchLoading ? true : !props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes(\"REQUEST\") || props.DrugSelectSequence?.allowed_actions?.includes(\"EDIT\")) ? false : true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "loading": {
          "type": "code",
          "value": "props.drugSearchLoading"
        },
        "onChange": {
          "type": "code",
          "value": "props.onDrugSearchTextChange"
        },
        "value": {
          "type": "code",
          "value": "props.drugSearchText"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"18/19\",\"gridColumn\":\"15/47\", display: \"flex\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showSearchResult ? \"block\" : \"none\", position: \"absolute\", zIndex: 1000, bottom: 50, padding: \"5px\", backgroundColor: \"#eeeeee\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 13,
      "name": "Table",
      "parent": 12,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugSearchResult"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.drugSearchProps"
        },
        "headers": {
          "type": "value",
          "value": "Generic Name, Drug Name, Form, Strength, Contain,หน่วยขาย,ราคา,บัญชี,status"
        },
        "keys": {
          "type": "value",
          "value": "generic_name,full_name,dosage_form_name,strength,container,stock_unit_name,price_unit,drug_list,material_status"
        },
        "minRows": {
          "type": "value",
          "value": "7"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\", width: \"60vw\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Input",
      "parent": 7,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes(\"REQUEST\") || props.DrugSelectSequence?.allowed_actions?.includes(\"EDIT\")) ? false : true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "dose"
        },
        "onChange": {
          "type": "code",
          "value": "props.onPreviewDrugContinuePlan"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.dose || \"\""
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", \"gridRow\":\"2/4\",\"gridColumn\":\"29/32\",}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"2/4\",\"gridColumn\":\"32/38\",}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"padding\":\"5px\",\"gridRow\":\"5/7\",\"gridColumn\":\"39/42\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"5/7\",\"gridColumn\":\"42/48\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", gridRow: \"5/7\", gridColumn: \"12/14\",}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"14/19\", }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\",  gridRow: \"5/7\", gridColumn: \"20/22\", }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"22/28\", }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\",  gridRow: \"5/7\", gridColumn: \"29/32\",}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"32/38\", }"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Dropdown",
      "parent": 16,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes(\"REQUEST\") || props.DrugSelectSequence?.allowed_actions?.includes(\"EDIT\")) ? false : true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onPreviewDrugContinuePlan"
        },
        "options": {
          "type": "code",
          "value": "props.drugUnits"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.unit || \"\""
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Dropdown",
      "parent": 18,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes(\"REQUEST\") || props.DrugSelectSequence?.allowed_actions?.includes(\"EDIT\")) ? false : true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "frequency"
        },
        "onChange": {
          "type": "code",
          "value": "props.onPreviewDrugContinuePlan"
        },
        "options": {
          "type": "code",
          "value": "props.drugFrequencies"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.frequency || \"\""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Dropdown",
      "parent": 20,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes(\"REQUEST\") || props.DrugSelectSequence?.allowed_actions?.includes(\"EDIT\")) ? false : true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "site"
        },
        "onChange": {
          "type": "code",
          "value": "props.onPreviewDrugContinuePlan"
        },
        "options": {
          "type": "code",
          "value": "props.drugSites"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.site || \"\""
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Dropdown",
      "parent": 22,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes(\"REQUEST\") || props.DrugSelectSequence?.allowed_actions?.includes(\"EDIT\")) ? false : true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "route"
        },
        "onChange": {
          "type": "code",
          "value": "props.onPreviewDrugContinuePlan"
        },
        "options": {
          "type": "code",
          "value": "props.drugRoutes"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.route || \"\""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Dropdown",
      "parent": 24,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.DrugSelectSequence?.id ? false : props.DrugSelectSequence?.id && (props.DrugSelectSequence?.allowed_actions?.includes(\"REQUEST\") || props.DrugSelectSequence?.allowed_actions?.includes(\"EDIT\")) ? false : true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "method"
        },
        "onChange": {
          "type": "code",
          "value": "props.onPreviewDrugContinuePlan"
        },
        "options": {
          "type": "code",
          "value": "props.drugMethods"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.method || \"\""
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "prn."
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", gridRow: \"8/10\", gridColumn: \"12/14\" }"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "est."
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", gridRow: \"8/10\", gridColumn: \"20/22\" }"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "qty."
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", gridRow: \"8/10\", gridColumn: \"29/32\" }"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "day"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", gridRow: \"8/10\", gridColumn: \"39/42\" }"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"32/38\" }"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Input",
      "parent": 59,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "sending_quantity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onPreviewDrugContinuePlan"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.sending_quantity || props.DrugSelectSequence?.calculated_daily_quantity || \"\""
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"42/48\" }"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Input",
      "parent": 63,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "sending_interval"
        },
        "onChange": {
          "type": "code",
          "value": "props.onPreviewDrugContinuePlan"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.sending_interval || \"\""
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"14/19\" }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"22/28\" }"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 71,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DrugSelectSequence?.calculated_daily_quantity"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ความต่อเนื่อง"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/13\", gridColumn: \"12/16\" }"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.planningData?.date_time_summary"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/13\", gridColumn: \"17/22\" }"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/13\", gridColumn: \"16/17\" }"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Button",
      "parent": 81,
      "props": {
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "icon": {
          "type": "value",
          "value": "edit"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenModPlanning"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "CANCEL"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "CANCEL"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNoteAndVerifyAndCancelDrugContinuePlan"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugSelectSequence?.allowed_actions?.includes(\"CANCEL\") && props.drugPermission?.action_TPD_DrugContinuePlan_CANCEL ? \"\" : \"none\" }"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "REJECT"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "REJECT"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNoteAndVerifyAndCancelDrugContinuePlan"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugSelectSequence?.allowed_actions?.includes(\"REJECT\") && props.drugPermission?.action_TPD_DrugContinuePlan_REJECT ? \"\" : \"none\" }"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "VERIFY"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "VERIFY"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNoteAndVerifyAndCancelDrugContinuePlan"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugSelectSequence?.allowed_actions?.includes(\"VERIFY\") && props.drugPermission?.action_TPD_DrugContinuePlan_VERIFY ? \"\" : \"none\" }"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "CLEAR"
        },
        "color": {
          "type": "value",
          "value": "grey"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClearDrugOrderForm"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "NOTE"
        },
        "disabled": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "NOTE"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNoteAndVerifyAndCancelDrugContinuePlan"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugSelectSequence?.allowed_actions?.includes(\"NOTE\") ? \"\" : \"none\" }"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "label": "divgrid",
      "name": "div",
      "parent": 98,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(3, 1fr)\",\"gridTemplateColumns\":\"repeat(38, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"80px\" , padding: \"0 5px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 97,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/8\" }"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "Button",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": "เฉพาะรายการที่ยังไม่ปิด"
        },
        "className": {
          "type": "code",
          "value": "props.drugContinueFilter?.status === \"only_open\" ? \"fluid\" : \"basic fluid\""
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "name": {
          "type": "value",
          "value": "only_open"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetdrugContinueFilter"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 97,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"9/15\" }"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Button",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "เฉพาะรายการใหม่"
        },
        "className": {
          "type": "code",
          "value": "props.drugContinueFilter?.status === \"only_new\" ? \"fluid\" : \"basic fluid\""
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "name": {
          "type": "value",
          "value": "only_new"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetdrugContinueFilter"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 97,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"16/22\" }"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 97,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"23/27\" }"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Button",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "เฉพาะรายการปัจจุบัน"
        },
        "className": {
          "type": "code",
          "value": "props.drugContinueFilter?.status === \"only_active\" ? \"fluid\" : \"basic fluid\""
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "name": {
          "type": "value",
          "value": "only_active"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetdrugContinueFilter"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "Button",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "แสดงทั้งหมด"
        },
        "className": {
          "type": "code",
          "value": "props.drugContinueFilter?.status === \"all\" ? \"fluid\" : \"basic fluid\""
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "name": {
          "type": "value",
          "value": "all"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetdrugContinueFilter"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/13\", gridColumn: \"22/23\" }"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดวิธีการใช้ยา"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenModSpecialDirection"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.modSpecialDirectionConfig?.showButton ? \"\" : \"none\" }"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกตัวทำละลาย"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenModSolventSelection"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.modSolventSelectionConfig?.showButtonSolvent ? \"\" : \"none\" }"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"32/38\" }"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "Input",
      "parent": 67,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "prn"
        },
        "onChange": {
          "type": "code",
          "value": "props.onUnPreviewDrugContinuePlan"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.prn || \"\""
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "Frequency"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": " *"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugSelectSequence?.require_frequency ? \"\" : \"none\", color: \"red\" }"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "label",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "Method"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "label",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": " *"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugSelectSequence?.require_method ? \"\" : \"none\", color: \"red\" }"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "Route"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": " *"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugSelectSequence?.require_route ? \"\" : \"none\", color: \"red\" }"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "Site"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": " *"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugSelectSequence?.require_site ? \"\" : \"none\", color: \"red\" }"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "Unit"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": " *"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugSelectSequence?.require_unit ? \"\" : \"none\", color: \"red\" }"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonEdit"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonEditQuantity"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonRequest"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", \"gridRow\":\"2/4\",\"gridColumn\":\"20/22\",}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "label",
      "parent": 132,
      "props": {
        "children": {
          "type": "value",
          "value": "Dose"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "label",
      "parent": 132,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.DrugSelectSequence?.require_unit ? \"\" : \"none\", color: \"red\" }"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"2/4\",\"gridColumn\":\"14/19\",}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "Button",
      "parent": 135,
      "props": {
        "children": {
          "type": "value",
          "value": "DRUG FACT"
        },
        "color": {
          "type": "code",
          "value": "props.hasFactsheetData ? \"blue\" : undefined"
        },
        "disabled": {
          "type": "code",
          "value": "!props.hasFactsheetData"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickDrugFact"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"5/7\",\"gridColumn\":\"48/50\", paddingLeft: \"0.5rem\", display: props.hideDrugDescription ? \"none\": \"\"}"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "Button",
      "parent": 137,
      "props": {
        "className": {
          "type": "value",
          "value": "circular"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": "clipboard list"
        },
        "onClick": {
          "type": "code",
          "value": "props.onDrugDescription"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0.5rem 0.59rem 0.6rem\", fontSize: \"1.45rem\"}"
        }
      },
      "seq": 139,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugContinuePlanUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "showSearchResult": true
  },
  "width": 80
}

*********************************************************************************** */
