import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Dropdown,
  Input
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardPainAssessmentUX = (props: any) => {
    return(
      <div
        style={{padding: "5px"}}>
        <div
          style={{padding: "5px", fontSize: "1.2rem", fontWeight: "bold"}}>
          Pain Assessment
        </div>
        <Table
          data={props.data}
          getTrProps={props.rowProps}
          headers="Date,User,Pain Score,Assessment,Location,Characteristic,Frequency,Duration(min),Duration(sec),Nursing,Remark,Delete"
          keys="date,user_fullname,pain_score_desc,pain_assessment_desc,location,characteristic,frequency_desc,duration_min,duration_sec,nursing_desc,nursing_remark,delete"
          minRows="3"
          showPagination={false}
          style={{height: "150px"}}>
        </Table>
        <div
          style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(40, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
          <div
            style={{ gridRow: "2/5", gridColumn: "2/9", }}>
            Pain Score
          </div>
          <div
            style={{ gridRow: "2/5", gridColumn: "10/20", }}>
            <Dropdown
              fluid={true}
              onChange={props.changePain("pain_score")}
              options={props.painScoreOptions}
              selection={true}
              value={props.pain_score}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "6/9", gridColumn: "2/9", }}>
            Pain Assessment Tool
          </div>
          <div
            style={{ gridRow: "10/13", gridColumn: "2/9", }}>
            Location
          </div>
          <div
            style={{ gridRow: "14/17", gridColumn: "2/9",}}>
            Characteristic
          </div>
          <div
            style={{ gridRow: "18/21", gridColumn: "2/9",}}>
            Frequency
          </div>
          <div
            style={{ gridRow: "22/25", gridColumn: "2/9", }}>
            Duration (ครั้งละประมาณ)
          </div>
          <div
            style={{ gridRow: "26/29", gridColumn: "2/9", }}>
            การให้การพยาบาลผู้ป่วย
          </div>
          <div
            style={{ gridRow: "30/33", gridColumn: "2/9", }}>
            ระบุ
          </div>
          <div
            style={{ gridRow: "37/40", gridColumn: "43/50", }}>
            <Button
              color="blue"
              onClick={props.onReassess}
              style={{width: "100%"}}>
              Reassessment
            </Button>
          </div>
          <div
            style={{ gridRow: "6/9", gridColumn: "10/20",}}>
            <Dropdown
              fluid={true}
              onChange={props.changePain("pain_assessment_tool")}
              options={props.painAssessmentOptions}
              selection={true}
              value={props.pain_assessment_tool}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "10/13", gridColumn: "10/20", }}>
            <Dropdown
              fluid={true}
              onChange={props.changePain("location")}
              options={props.painLocationOptions}
              selection={true}
              value={props.location}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "14/17", gridColumn: "10/20", }}>
            <Dropdown
              fluid={true}
              onChange={props.changePain("characteristic")}
              options={props.painCharacteristicOptions}
              selection={true}
              value={props.characteristic}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "26/29", gridColumn: "10/28",  width: `100%`}}>
            <Dropdown
              fluid={true}
              multiple={true}
              onChange={props.changePain("nursing")}
              options={props.painNursingOptions}
              selection={true}
              style={{ width: `100%` }}
              value={props.nursing}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "22/25", gridColumn: "10/13", }}>
            <Input
              onChange={props.changePain("duration_min")}
              size="mini"
              style={{width: "100%"}}
              value={props.duration_min}>
            </Input>
          </div>
          <div
            style={{ gridRow: "22/25", gridColumn: "13/15",marginLeft:"10px" }}>
            นาที
          </div>
          <div
            style={{ gridRow: "22/25", gridColumn: "15/18", }}>
            <Input
              onChange={props.changePain("duration_sec")}
              size="mini"
              style={{width:"100%"}}
              value={props.duration_sec}>
            </Input>
          </div>
          <div
            style={{ gridRow: "22/25", gridColumn: "18/20",marginLeft:"10px"}}>
            วินาที
          </div>
          <div
            style={{ gridRow: "18/21", gridColumn: "10/20", }}>
            <Dropdown
              fluid={true}
              onChange={props.changePain("frequency")}
              options={props.painFrequencyOptions}
              selection={true}
              value={props.frequency}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "30/33", gridColumn: "10/20", }}>
            <Input
              fluid={true}
              onChange={props.changePain("nursing_remark")}
              value={props.nursing_remark}>
            </Input>
          </div>
          <div
            style={props.openEdit}>
            <Button
              color="orange"
              onClick={props.updatePain}
              style={{width: "100%"}}>
              แก้ไข Pain Assessment
            </Button>
          </div>
          <div
            style={props.openClear}>
            
            <Button
              color="teal"
              onClick={props.clearPain}
              style={{width: "100%"}}>
              ล้างหน้าจอ Pain Assessment
            </Button>
          </div>
        </div>
      </div>
    )
}

export default CardPainAssessmentUX

export const screenPropsDefault = {}

/* Date Time : Fri Oct 14 2022 16:08:47 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(40, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Pain Score"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/5\", gridColumn: \"2/9\", }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/5\", gridColumn: \"10/20\", }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Pain Assessment Tool"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/9\", gridColumn: \"2/9\", }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Location"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/13\", gridColumn: \"2/9\", }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Characteristic"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/17\", gridColumn: \"2/9\",}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Frequency"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"18/21\", gridColumn: \"2/9\",}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Duration (ครั้งละประมาณ)"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"22/25\", gridColumn: \"2/9\", }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "การให้การพยาบาลผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"26/29\", gridColumn: \"2/9\", }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"30/33\", gridColumn: \"2/9\", }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"37/40\", gridColumn: \"43/50\", }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/9\", gridColumn: \"10/20\",}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/13\", gridColumn: \"10/20\", }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/17\", gridColumn: \"10/20\", }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"26/29\", gridColumn: \"10/28\",  width: `100%`}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"22/25\", gridColumn: \"10/13\", }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "นาที"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"22/25\", gridColumn: \"13/15\",marginLeft:\"10px\" }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"22/25\", gridColumn: \"15/18\", }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "วินาที"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"22/25\", gridColumn: \"18/20\",marginLeft:\"10px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"18/21\", gridColumn: \"10/20\", }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"30/33\", gridColumn: \"10/20\", }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "Reassessment"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onReassess"
        },
        "size": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Dropdown",
      "parent": 9,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"pain_score\")"
        },
        "options": {
          "type": "code",
          "value": "props.painScoreOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.pain_score"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Dropdown",
      "parent": 18,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"pain_assessment_tool\")"
        },
        "options": {
          "type": "code",
          "value": "props.painAssessmentOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.pain_assessment_tool"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Dropdown",
      "parent": 19,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"location\")"
        },
        "options": {
          "type": "code",
          "value": "props.painLocationOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.location"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Dropdown",
      "parent": 20,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"characteristic\")"
        },
        "options": {
          "type": "code",
          "value": "props.painCharacteristicOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.characteristic"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Dropdown",
      "parent": 26,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"frequency\")"
        },
        "options": {
          "type": "code",
          "value": "props.painFrequencyOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.frequency"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Dropdown",
      "parent": 21,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"nursing\")"
        },
        "options": {
          "type": "code",
          "value": "props.painNursingOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: `100%` }"
        },
        "value": {
          "type": "code",
          "value": "props.nursing"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Input",
      "parent": 22,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"duration_min\")"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.duration_min"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Input",
      "parent": 24,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"duration_sec\")"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.duration_sec"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "Pain Assessment"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\", fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 38,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": "Date,User,Pain Score,Assessment,Location,Characteristic,Frequency,Duration(min),Duration(sec),Nursing,Remark,Delete"
        },
        "keys": {
          "type": "value",
          "value": "date,user_fullname,pain_score_desc,pain_assessment_desc,location,characteristic,frequency_desc,duration_min,duration_sec,nursing_desc,nursing_remark,delete"
        },
        "minRows": {
          "type": "value",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"150px\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Input",
      "parent": 27,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePain(\"nursing_remark\")"
        },
        "value": {
          "type": "code",
          "value": "props.nursing_remark"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "props.openEdit"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Button",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "แก้ไข Pain Assessment"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "onClick": {
          "type": "code",
          "value": "props.updatePain"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "props.openClear"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Button",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างหน้าจอ Pain Assessment"
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "onClick": {
          "type": "code",
          "value": "props.clearPain"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 44,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 75,
  "isMounted": false,
  "memo": false,
  "name": "CardPainAssessmentUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
