import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardRescheduleUX = (props: any) => {
    return(
      <div>
        
        <div
          style={{margin: "10px 0px"}}>
          
          <div
            style={{display: "flex", alignItems:"center", justifyContent:"space-between"}}>
            
            <div
              style={{flex: "0 0 30%"}}>
              ชื่อ - สกุล / HN
              <div>
                {props.searchHN}
              </div>
            </div>
            <div
              style={{flex: "0 0 30%"}}>
              ทันตแพทย์ / นิสิต
              <div>
                {props.searchDoctor}
              </div>
            </div>
            <div
              style={{flex: "0 0 30%"}}>
              หน่วยงาน / คลินิก
              <div>
                {props.searchClinic}
              </div>
            </div>
          </div>
          <div
            style={{display: "flex", alignItems:"center", justifyContent: "space-between"}}>
            
            <div
              style={{flex: "0 0 30%"}}>
              Waiting List
              <div>
                {props.searchWaitingList}
              </div>
            </div>
            <div
              style={{flex: "0 0 30%"}}>
              สถานะ
              <div>
                {props.searchStatus}
              </div>
            </div>
            <div
              style={{flex: "0 0 30%"}}>
              
            </div>
          </div>
          <div
            style={{display: "flex", alignItems:"flex-end"}}>
            
            <div
              style={{display:"flex",flexDirection: "column", flex: "0 0 30%"}}>
              วันที่เริ่มต้น
              <DateTextBox
                inputClassNames="fluid"
                onChange={props.onChangeStartDate}
                value={props.startDate}>
              </DateTextBox>
            </div>
            <div
              style={{margin : "10px 10px"}}>
               ถึง
            </div>
            <div
              style={{display:"flex",flexDirection: "column", flex: "0 0 30%"}}>
              วันที่สิ้นสุด
              <DateTextBox
                inputClassNames="fluid"
                onChange={props.onChangeEndDate}
                value={props.endDate}>
              </DateTextBox>
            </div>
            <Button
              color="green"
              onClick={props.onClickSearch}
              style={{marginLeft: "20px"}}>
              ค้นหา
            </Button>
          </div>
        </div>
        <Table
          data={props.rescheduleList}
          getTrProps={props.rowProps}
          headers="HN,ชื่อ-สกุล,แพทย์/นิสิต,หน่วยงาน/คลินิก,วันเวลานัดหมายเดิม,Waiting List"
          keys="patient_hn,patient_name,provider_name, division_name,estimated_at,waiting_list_name"
          minRows="10"
          showPagination={false}
          style={{height: "400px"}}>
        </Table>
      </div>
    )
}

export default CardRescheduleUX

export const screenPropsDefault = {}

/* Date Time : Wed Aug 10 2022 09:30:22 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px 0px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.rescheduleList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": "HN,ชื่อ-สกุล,แพทย์/นิสิต,หน่วยงาน/คลินิก,วันเวลานัดหมายเดิม,Waiting List"
        },
        "keys": {
          "type": "value",
          "value": "patient_hn,patient_name,provider_name, division_name,estimated_at,waiting_list_name"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"400px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems:\"center\", justifyContent:\"space-between\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems:\"center\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems:\"flex-end\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ - สกุล / HN"
        },
        "style": {
          "type": "code",
          "value": "{flex: \"0 0 30%\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ทันตแพทย์ / นิสิต"
        },
        "style": {
          "type": "code",
          "value": "{flex: \"0 0 30%\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงาน / คลินิก"
        },
        "style": {
          "type": "code",
          "value": "{flex: \"0 0 30%\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchHN"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchDoctor"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchClinic"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "Waiting List"
        },
        "style": {
          "type": "code",
          "value": "{flex: \"0 0 30%\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        },
        "style": {
          "type": "code",
          "value": "{flex: \"0 0 30%\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: \"0 0 30%\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchWaitingList"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchStatus"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่เริ่มต้น"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",flexDirection: \"column\", flex: \"0 0 30%\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": " ถึง"
        },
        "style": {
          "type": "code",
          "value": "{margin : \"10px 10px\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่สิ้นสุด"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",flexDirection: \"column\", flex: \"0 0 30%\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickSearch"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"20px\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 21,
      "name": "DateTextBox",
      "parent": 17,
      "props": {
        "inputClassNames": {
          "type": "value",
          "value": "fluid"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartDate"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 22,
      "name": "DateTextBox",
      "parent": 19,
      "props": {
        "inputClassNames": {
          "type": "value",
          "value": "fluid"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 22,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardRescheduleUX",
  "project": "cudent2",
  "screenPropsDefault": {
  },
  "width": 25
}

*********************************************************************************** */
