import WasmController from "react-lib/frameworks/WasmController";
import moment from "moment";
import { formatADtoBEString } from "react-lib/utils/dateUtils";
import { GetDrugContinuePlan } from "../TPD/TPDInterface";
// apis
import PlanItemDetail from "issara-sdk/apis/PlanItemDetail_core";

export type State = {
  // CommonInterface
  buttonLoadCheck?: any;

  // selected data
  selectedPatient?: any;
  selectedDrug?: any;

  // msg
  modPlanningConfig?: {
    open?: boolean;
    error?: any;
  };

  // planning
  planningData?: any;
};

export const StateInitial: State = {
  // selected data
  selectedPatient: null,
  selectedDrug: null,

  // msg
  modPlanningConfig: {
    open: false,
    error: null,
  },

  // planning
  planningData: {},
};

export type Event =
  // set
  | { message: "handleSetDefaultPlanningData", params: any }
  | {
      message: "handleSetPlanningData";
      params: { name: string; value: string };
    }
  | {
      message: "handleSetModPlanningConfig";
      params: { name: string; value: any };
    }
  // save
  | { message: "handleEditPlanItem"; params: { card: string; action: string } }
  | {
      message: "handleActionPlanItem";
      params: { action: string; card: string };
    };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

// set
export const handleSetDefaultPlanningData: Handler = async (controller) => {
  const state = controller.getState();

  const today = formatADtoBEString(moment().format("YYYY-MM-DD"));

  controller.setState({
    planningData: {
      id: null,
      action: "REQUEST",
      allowed_actions: ["REQUEST"],
      // type normal
      start_date: today,
      day_take: "",
      day_skip: "",
      week_days: [],
      endDate: "NONE",
      occurrence: "",
      end_date: "",
      date_time_summary: `เริ่ม ${today}`,
      note: "",
      plan_summary: "Planning",//`แผนการจ่ายยาต่อเนื่องสำหรับผู้ป่วยใน: ${state.selectedPatient?.full_name} | ${state.selectedDrug?.full_name}`,
      // type custom
      dates: [],
    },
  } as any);
};

export const handleSetPlanningData: Handler = async (controller, params) => {
  const state = controller.getState();

  let data: any = { ...state.planningData, [params.name]: params.value };

  if (params.name === "type") {
    if (params.value === "NORMAL") {
      data.dates = [];
    } else if (params.value === "CUSTOM") {
      data.start_date = "";
      data.day_take = "";
      data.day_skip = "";
      data.week_days = [];
      data.endDate = "NONE";
      data.occurrence = "";
      data.end_date = "";
    }
  }

  if (params.name === "endDate") {
    if (params.value === "OCCURRENCE") {
      data.end_date = "";
    } else if (params.value === "END_DATE") {
      data.occurrence = "";
    } else {
      data.occurrence = "";
      data.end_date = "";
    }
  }

  data.date_time_summary = summarize(data);

  controller.setState({ planningData: data } as any);
};

export const handleSetModPlanningConfig: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    modPlanningConfig: {
      ...state.modPlanningConfig,
      [params.name]: params.value,
    },
  });
};

// save
export const handleEditPlanItem: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const data = { ...state.planningData, action: params.action };

  const [response, error, _network] = await PlanItemDetail.update({
    pk: state.planningData?.id,
    data: { ...data, occurrence: data.occurrence ? Number(data.occurrence): data.occurrence },
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
  });

  if (error) {
    controller.setState({
      planningData: data,
      modPlanningConfig: { ...state.modPlanningConfig, error: error },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
    });
  } else {
    let result: any = { ...response };
    if (result.dates.length > 0) {
      result.type = "CUSTOM";
    } else if (result.start_date) {
      result.type = "NORMAL";
      if (result?.occurrence) {
        result.endDate = "OCCURRENCE";
      } else if (response?.end_date) {
        result.endDate = "END_DATE";
      } else {
        result.endDate = "NONE";
      }
    }

    controller.setState({
      planningData: result,
      modPlanningConfig: { ...state.modPlanningConfig, error: null },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });
  }
};

export const handleActionPlanItem: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const data = { action: params.action, note: "" };

  const [_response, error, _network] = await PlanItemDetail.update({
    pk: state.planningData?.id,
    data: data,
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
  });

  if (error) {
    controller.setState({
      planningData: data,
      modPlanningConfig: { ...state.modPlanningConfig, error: error },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });

    handleSetModPlanningConfig(controller, { name: "open", value: false });
    GetDrugContinuePlan(controller as any);
  }
};

// utils
const summarize = (data: any) => {
  if (data?.start_date === "" && data?.dates?.length === 0) {
    return "กรุณาระบุ เริ่มต้น หรือ เลือกวันที่ แบบเลือกเอง";
  }

  let summary: string = "";

  // -------------------- type normal
  if (data?.start_date !== "") {
    summary += `เริ่ม ${data?.start_date}`;
  }
  if (data.week_days.length > 0) {
    summary += " ทำทุกวัน";
    const weekDayMap: any = {
      Mon: "จันทร์",
      Tue: "อังคาร",
      Wed: "พุธ",
      Thu: "พฤหัสบดี",
      Fri: "ศุกร์",
      Sat: "เสาร์",
      Sun: "อาทิตย์",
    };
    const sorter: any = {
      Mon: 1,
      Tue: 2,
      Wed: 3,
      Thu: 4,
      Fri: 5,
      Sat: 6,
      Sun: 7,
    };
    const week_days = data.week_days.sort((a: any, b: any) => {
      return sorter[a] > sorter[b] ? 1 : -1;
    });
    const week: any[] = week_days.map((item: any) => {
      return weekDayMap[item];
    });
    summary += " " + week.join(", ");
  }
  if (data.day_take) {
    if (data.day_skip) {
      summary += ` ทำซ้ำ ${data.day_take} วัน เว้น ${data.day_skip} วัน`;
    } else {
      summary += ` ทำซ้ำ ${data.day_take} วัน`;
    }
  }
  if (data.occurrence) {
    summary += ` , ${data.occurrence} วัน`;
  }
  if (data.end_date) {
    summary += ` , สิ้นสุด ${data.end_date}`;
  }

  // -------------------- type custom
  if (data?.dates.length > 0) {
    summary += "ทำในวันที่ ";
    const dateSort = data.dates.sort((a: any, b: any) => {
      return a > b ? 1 : -1;
    });
    summary += " " + dateSort.join(", ");
  }

  return summary;
};
