import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { Image } from "semantic-ui-react";
import { toast, ToastContainer } from "react-toastify";

type CardNotiMessageProps = {
  postponeNotificationList: any;
  setProp: any;
};

const IMAGES = {
  noti: "/static/images/icon-noti.png",
};

const CardNotiMessage = (props: CardNotiMessageProps) => {
  const handleCloseNoti = useCallback(
    (appointmentId: any, time: any) => {
      toast.dismiss(time);
      const postponeNotificationList = props.postponeNotificationList.filter(
        (item: any) => item.appointmentId !== appointmentId
      );
      props.setProp("postponeNotificationList", postponeNotificationList);
    },
    [props.postponeNotificationList]
  );

  useEffect(() => {
    let listDisableUpdate: any[] = [];

    if (props.postponeNotificationList?.length > 0) {
      (props.postponeNotificationList || []).map((item: any) => {
        if (item.update) {
          toast.dismiss(item.idDelete);
          setTimeout(
            () =>
              toast(<CardNoti time={item.time} hn={item.hn} />, {
                autoClose: false,
                position: toast.POSITION.TOP_RIGHT,
                bodyClassName: "CardTelepharToast",
                className: "CardTelepharToast",
                toastId: item.time,
                onClick: () => handleCloseNoti(item.appointmentId, item.time),
              }),
            1000 * 3
          );

          listDisableUpdate = props.postponeNotificationList.map(
            (itemValue: any) => {
              if (itemValue.appointmentId === item.appointmentId) {
                return { ...itemValue, update: false };
              } else {
                return { ...itemValue };
              }
            }
          );
        } else {
          toast(<CardNoti time={item.time} hn={item.hn} />, {
            autoClose: false,
            position: toast.POSITION.TOP_RIGHT,
            bodyClassName: "CardTelepharToast",
            className: "CardTelepharToast",
            toastId: item.time,
            onClick: () => handleCloseNoti(item.appointmentId, item.time),
          });
        }
      });

      if (listDisableUpdate.length > 0) {
        props.setProp("postponeNotificationList", listDisableUpdate);
      }
    }
  }, [props.postponeNotificationList]);

  return (
    <div>
      {props.postponeNotificationList?.length > 0 && (
        <>
          <div
            style={{
              background: "white",
              position: "fixed",
              top: "0.5em",
              right: "2em",
              padding: "5px 10px",
              borderRadius: "15px",
              cursor: "pointer",
              zIndex: "10000"
            }}
            onClick={() => {
              props.setProp("postponeNotificationList", []);
              toast.dismiss();
            }}
          >
            ปิดทั้งหมด
          </div>
        </>
      )}
    </div>
  );
};

const handleCalculateTime = (dateTime: string) => {
  const dateTimeNow = moment();
  const timestamp = moment(dateTime);
  const duration = moment.duration(dateTimeNow.diff(timestamp));

  if (duration.days() < 1) {
    if (duration.hours() >= 1) {
      return `${duration.hours().toFixed(0)} ชั่วโมงที่แล้ว`;
    } else if (duration.minutes() < 60 && duration.minutes() > 1) {
      return `${duration.minutes().toFixed(0)} นาทีที่แล้ว`;
    } else {
      return `now`;
    }
  } else {
    return `${duration.asDays().toFixed(0)} วันที่แล้ว`;
  }
};

const CardNoti = (props: any) => {
  const [showTime, setShowTime] = useState<string>("");

  useEffect(() => {
    setShowTime(handleCalculateTime(props.time));
  }, [props.time]);

  useEffect(() => {
    setInterval(() => {
      setShowTime(handleCalculateTime(props.time));
    }, 1000 * 60);
  }, []);

  return (
    <div style={{ display: "flex", position: "relative" }}>
      <div style={{ width: "35px", height: "35px", marginRight: "10px" }}>
        <Image src={IMAGES.noti} />
      </div>
      <div>
        <div style={{ color: "black" }}>{`นัดหมายของ HN : ${props.hn}`}</div>
        <div style={{ color: "red" }}>{"ถูกเลื่อนเวลานัดหมาย"}</div>
        <div>{showTime}</div>
      </div>
    </div>
  );
};

export default React.memo(CardNotiMessage);
