import React, { useEffect } from "react";
// ui ORM
import CardSurgicalSafetyChecklistSigninUX from "./CardSurgicalSafetyChecklistSigninUX";
import { PreOPButtonAction } from "./CardPreOPVisit";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";

const CARD_KEY: string = "surgicalSafetyChecklistSignin";
const FORM_CODE: string = "CardSurgicalSafetyChecklistSignIn";
const FORM_NAME: string = "Surgical Safety Checklist - Sign in";
const FORM_VERSION: string = "0.1";

type CardSurgicalSafetyChecklistSigninProps = {
  // function
  onEvent: any;
  setProp: any;
  buttonLoadCheck?: any;
  errorMessage?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;
  masterData?: any;

  // seq
  runSequence?: any;
  PerioperativeNursingSequence?: any;
};

const CardSurgicalSafetyChecklistSigninInitial: CardSurgicalSafetyChecklistSigninProps =
  {
    // funtion
    onEvent: () => null,
    setProp: () => null,
    buttonLoadCheck: null,
  };

const CardSurgicalSafetyChecklistSignin: React.FC<
  CardSurgicalSafetyChecklistSigninProps
> = (props: any) => {
  useEffect(() => {
    props.runSequence({
      sequence: "PreOperation",
      action: "FETCH_FORM_DATA_LATEST",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formVersion: FORM_VERSION,
    });
  }, []);

  const handleChangeValue = (key: string) => (_event: any, data: any) => {
    if (data.type === "checkbox" || data.type === "radio") {
      props.setProp(
        `PerioperativeNursingSequence.surgicalSafetyChecklistSignin.data.${key}`,
        data.checked
      );
    } else {
      props.setProp(
        `PerioperativeNursingSequence.surgicalSafetyChecklistSignin.data.${key}`,
        data.value
      );
    }
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SAVE",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleConfirm = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "CONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleUnconfirm = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "UNCONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={null}
      />

      <CardSurgicalSafetyChecklistSigninUX
        surgicalSafety={
          props.PerioperativeNursingSequence?.surgicalSafetyChecklistSignin
        }
        handleChangeValue={handleChangeValue}
        status={
          props.PerioperativeNursingSequence?.surgicalSafetyChecklistSignin
            ?.status
        }
        buttonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="save"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave}
          />
        }
        buttonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="confirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleConfirm}
          />
        }
        buttonUnconfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="unconfirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleUnconfirm}
          />
        }
      />
    </>
  );
};

CardSurgicalSafetyChecklistSignin.defaultProps =
  CardSurgicalSafetyChecklistSigninInitial;

export default React.memo(CardSurgicalSafetyChecklistSignin);
