import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import CardMedRequestReturnUx from "./CardMedRequestReturnUX";
import CardReturnSupply from "./CardReturnSupply";

const ColumnStyle = {
  marginTop: -7,
  marginLeft: -4,
  width: "calc(100% + 10px)",
  height: "calc(100% + 14px)",
  padding: "7px 7px",
};

const SEMANTIC_COLOR = {
  blue: "rgba(33, 133, 208, 0.2)",
  red: "rgba(219, 40, 40, 0.2)",
  yellow: "rgba(251, 189, 8, 0.2)",
  green: "rgba(33, 186, 69, 0.2)",
  white: "rgb(255, 255, 255)",
};
const STATUS_COLOR: Record<string, string> = {
  REQUESTED: SEMANTIC_COLOR.red,
  APPROVED: SEMANTIC_COLOR.yellow,
  DELIVERED: SEMANTIC_COLOR.green,
  RECEIVED: SEMANTIC_COLOR.blue,
};

const get_status_color = (status: string) => {
  return STATUS_COLOR[status] || STATUS_COLOR[""];
};

const CARD_RETURN_SUPPLY = "CardReturnSupply";
const CardMedRequestReturn = (props: any) => {
  const [selectedRow, setSelectedRow] = useState<any>(null);

  useEffect(() => {
    props.runSequence({
      sequence: "MedRequestReturn",
      restart: true,
    });

    return () => {
      props.runSequence({
        sequence: "MedRequestReturn",
        clear: true,
      });
    };
  }, []);

  useEffect(() => {
    props.runSequence({
      sequence: "ReturnSupply",
      restart: true,
    });

    return () => {
      props.runSequence({
        sequence: "ReturnSupply",
        clear: true,
      });
    };
  }, []);

  const divisionOptions = useMemo(() => {
    return [
      {
        key: "แสดงทุกหน่วยงาน",
        value: "แสดงทุกหน่วยงาน",
        text: "แสดงทุกหน่วยงาน",
      },
      ...(props.masterOptions?.division || []),
    ].map((item: any) => ({
      key: item.key,
      value: item.value,
      text: item.text,
    }));
  }, [props.masterOptions?.division]);

  const MedRequestReturnList = useMemo(() => {
    return (props.MedRequestReturnSequence?.MedRequestReturnList || []).map(
      (item: any, index: number) => ({
        ...item,
        code: item.code,
        statusName: item.status_name,
        listSupply: item.items
          .map(
            (acc: any) =>
              `${acc.code}(${acc.quantity_restock || 0}/${
                acc.quantity_request || 0
              })`
          )
          .join(),
        request: item.requested?.datetime ? (
          <div
            style={{
              ...ColumnStyle,
              backgroundColor:
                item.requested?.datetime &&
                props?.MedRequestReturnSequence?.selectedStatus === 1
                  ? get_status_color("REQUESTED")
                  : "",
            }}
          >
            {moment(item.requested?.datetime).format("HH:mm")}
          </div>
        ) : null,
        approve: item.approved?.datetime ? (
          <div
            style={{
              ...ColumnStyle,
              backgroundColor:
                item.approved?.datetime &&
                props?.MedRequestReturnSequence?.selectedStatus === 2
                  ? get_status_color("APPROVED")
                  : "",
            }}
          >
            {moment(item.approved?.datetime).format("HH:mm")}
          </div>
        ) : null,
        delivery: item.delivered?.datetime ? (
          <div
            style={{
              ...ColumnStyle,
              backgroundColor:
                item.delivered?.datetime &&
                props?.MedRequestReturnSequence?.selectedStatus === 3
                  ? get_status_color("DELIVERED")
                  : "",
            }}
          >
            {moment(item.delivered?.datetime).format("HH:mm")}
          </div>
        ) : null,
        receive: item.received?.datetime ? (
          <div
            style={{
              ...ColumnStyle,
              backgroundColor: item.received?.datetime
                ? get_status_color("RECEIVED")
                : "",
            }}
          >
            {moment(item.received?.datetime).format("HH:mm")}
          </div>
        ) : null,
      })
    );
  }, [props.MedRequestReturnSequence?.MedRequestReturnList]);

  const handleChangeValue =
    (key: string, keyChecked: string) => (e: any, v: any) => {
      if (key === "selectedDivisionReturn" && v.value === "แสดงทุกหน่วยงาน") {
        props.setProp("MedRequestReturnSequence", {
          ...props.MedRequestReturnSequence,
          sequenceIndex: "SearchAndSelect",
          checkedDivisionReturn: false,
          selectedDivisionReturn: v.value,
        });
      } else if (
        key === "selectedDivisionReceive" &&
        v.value === "แสดงทุกหน่วยงาน"
      ) {
        props.setProp("MedRequestReturnSequence", {
          ...props.MedRequestReturnSequence,
          checkedDivisionReceive: false,
          selectedDivisionReceive: v.value,
        });
      } else if (key === "selectedStatus" && v.value === "แสดงทุกสถานะ") {
        props.setProp("MedRequestReturnSequence", {
          ...props.MedRequestReturnSequence,
          checkedStatus: false,
          selectedStatus: v.value,
        });
      } else {
        props.setProp("MedRequestReturnSequence", {
          ...props.MedRequestReturnSequence,
          [keyChecked]: true,
          [key]: v.value,
        });
      }
    };

  const handleChangeChecked =
    (key: string) =>
    (e: any, { checked }) => {
      props.setProp(`MedRequestReturnSequence.${key}`, checked);
    };

  const handleChangeDate = (key: string, keyChecked: string) => (date: any) => {
    props.setProp("MedRequestReturnSequence", {
      ...props.MedRequestReturnSequence,
      [keyChecked]: true,
      [key]: date,
    });
    props.setProp(`MedRequestReturnSequence.${key}`, date);
  };

  const handleRowProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.pk && rowInfo?.original?.pk === selectedRow?.pk
            ? "#cccccc"
            : "white",
      },
      onClick: () => {
        setSelectedRow(rowInfo.original);
      },
      onDoubleClick: (e: any) => {
        props.runSequence({
          sequence: "ReturnSupply",
          action: "returnSupply",
          order: rowInfo.original?.order,
          id: rowInfo.original?.id,
          card: CARD_RETURN_SUPPLY,
        });
      },
    };
  };

  return (
    <>
      <div
        style={{
          height: "85vh",
          flex: "1 1 0%",
          overflow: "scroll",
          padding: "5px",
        }}
      >
        <CardMedRequestReturnUx
          MedRequestReturnList={MedRequestReturnList}
          handleRowProps={handleRowProps}
          selectedDivisionReturn={
            props?.MedRequestReturnSequence?.selectedDivisionReturn
          }
          selectedDivisionReceive={
            props?.MedRequestReturnSequence?.selectedDivisionReceive
          }
          selectedStartDate={props?.MedRequestReturnSequence?.selectedStartDate}
          selectedEndDate={props?.MedRequestReturnSequence?.selectedEndDate}
          selectedStatus={props?.MedRequestReturnSequence?.selectedStatus}
          checkedStatus={props?.MedRequestReturnSequence?.checkedStatus}
          checkedDivisionReturn={
            props?.MedRequestReturnSequence?.checkedDivisionReturn
          }
          checkedDivisionReceive={
            props?.MedRequestReturnSequence?.checkedDivisionReceive
          }
          checkedFilterDate={props?.MedRequestReturnSequence?.checkedFilterDate}
          statuOptions={[
            {
              key: "แสดงทุกสถานะ",
              value: "แสดงทุกสถานะ",
              text: "แสดงทุกสถานะ",
            },
            { key: 1, value: 1, text: "REQUESTED" },
            { key: 2, value: 2, text: "APPROVED" },
            { key: 3, value: 3, text: "DELIVERED" },
            { key: 4, value: 4, text: "RECEIVED" },
            { key: 5, value: 5, text: "REJECTED" },
            { key: 6, value: 6, text: "CENCELED" },
          ]}
          divisionOptions={divisionOptions}
          handleChangeValue={handleChangeValue}
          handleChangeChecked={handleChangeChecked}
          handleChangeDate={handleChangeDate}
          OnSearch={() =>
            props.runSequence({
              sequence: "MedRequestReturn",
              action: "search",
            })
          }
          OnClear={() =>
            props.runSequence({
              sequence: "MedRequestReturn",
              action: "clear",
            })
          }
        />
        {props.ReturnSupplySequence?.OrderReturnList?.id && (
          <CardReturnSupply
            setProp={props.setProp}
            onEvent={props.onEvent}
            // seq
            runSequence={props.runSequence}
            ReturnSupplySequence={props.ReturnSupplySequence}
            // options
            masterOptions={props.masterOptions}
            // CommonInterface
            errorMessage={props.errorMessage}
            loadingStatus={props.loadingStatus}
            buttonLoadCheck={props.buttonLoadCheck}
            // config
            closeIcon={
              props.ReturnSupplySequence?.OrderReturnList?.id ? true : false
            }
            // callback
            onClose={() => {
              props.setProp("ReturnSupplySequence", null);
            }}
          />
        )}
      </div>
    </>
  );
};

export default CardMedRequestReturn;
