import React, { useState, useCallback, useRef, MutableRefObject } from "react";

// UX
import CardProductInfoUX from "./CardProductInfoUX";
import SubProductIngredientsUX from "./SubProductIngredientsUX";
import SubProductIngredients from "./SubProductIngredients";

// Common
import { DateTextBox } from "react-lib/apps/common";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Interface
import {
  BUTTON_ACTIONS,
  ProductDetailType,
  RunSequence,
} from "./sequence/StockManagement";
import { CARD_STOCK_MANAGEMENT } from "./CardStockManagement";
import { beToAd } from "react-lib/utils/dateUtils";

// Types
type CardProductInfoProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: RunSequence;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  // options
  productTypeDrugSupplyOptions: any[];
  unitOptions?: any[];
  billModeOptions?: any[];
  dosageOptions?: any[];
  modeOptions?: any[];
  // data
  productDetail?: Partial<ProductDetailType>;
  // callback
  onClose?: Function;
};

// Const
const PRODUCT_NAME = "name";
const PRODUCT_ID = "id";

const CARD_PRODUCT_INFO = "CardProductInfo";

const CardProductInfo = (props: CardProductInfoProps) => {
  const boxRef = useRef() as MutableRefObject<HTMLDivElement>;

  // Callback
  const mapProductOptions = useCallback((items: any) => {
    return items.map((item: any) => ({
      key: item[PRODUCT_ID],
      value: item[PRODUCT_ID],
      text: item[PRODUCT_NAME],
    }));
  }, []);

  const handleSelectedItem = useCallback(
    (data: { id: string; name: string; type: string }) =>
      async (value: any, key: any, obj: any) => {
        handleChangeValue(null, { name: data.name, value: value || null });
      },
    [props.searchedItemListWithKey, props.productDetail]
  );

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    let value = typeof data.checked === "boolean" ? data.checked : data.value;
    const [first, second] = data.name.split(".") as [
      keyof ProductDetailType,
      string
    ];

    const detail = { ...(props.productDetail || {}) };

    const priceKey: (keyof ProductDetailType)[] = [
      "price_normal",
      "price_special",
      "price_premium",
      "price_foreign",
      "price_pledge",
      "max_discount",
      "overall_cose",
    ];

    // type number value ไม่น้อยกว่า 0
    if (priceKey.includes(first)) {
      value = !value ? "" : Number(value) < 0 ? 0 : value;
    }
    // type number value ไม่น้อยกว่า 0, supply
    else if (second === "stock_size") {
      value = !value ? "" : Number(value) < 0 ? 0 : value;
    }
    // set default value เมื่อเปลี่ยน P-type
    else if (first === "product_type") {
      if (value === "DRUG") {
        delete detail.supply;
        detail.drug = { drug_ingredients: [{}] };
      } else if (value === "SUPPLY") {
        delete detail.drug;
        detail.supply = {};
      }
    }

    if (second) {
      (detail as any)[first] = {
        ...(detail as any)[first],
        [second]: value,
      };
    } else {
      (detail as any)[first] = value;
    }

    props.setProp(`StockManagementSequence.productDetail`, { ...detail });
  };

  const handleChangeDate =
    (name: keyof ProductDetailType) => async (date: string) => {
      if (!!date && date.length !== 10) {
        return;
      }

      if (name === "start_date") {
        const start = beToAd(date);
        const end = beToAd(props.productDetail?.end_date || "");
        const diff = start?.diff(end, "days") || 0;

        // clear end date หาก start date มากกว่า end date
        if (start && end && diff >= 1) {
          await props.setProp(
            `StockManagementSequence.productDetail.end_date`,
            ""
          );
        }
      }

      handleChangeValue(null, { name, value: date });
    };

  const handleSave = () => {
    props.runSequence({
      sequence: "StockManagement",
      action: "ADD_PRODUCT",
      card: CARD_PRODUCT_INFO,
      errorKey: CARD_STOCK_MANAGEMENT,
      btnAction: BUTTON_ACTIONS.SAVE,
      onSuccess: props.onClose,
    });
  };

  const handleCancel = () => {
    props.onClose?.();
  };

  const handleEventSearch = (data: any) => {
    const params = data.params || {};
    const searchText = params.searchText || "";
    const key = `searchedItemListWithKey.${params.type}_${params.id}`;
    const filter = (props.unitOptions || []).flatMap((option) =>
      option.text.toLowerCase().includes(searchText.toLowerCase())
        ? [{ id: option.value, name: option.text }]
        : []
    );

    props.setProp(key, params.action === "clear" ? [] : filter);
  };

  const handleClickDate = (e: any) => {
    const modal = boxRef.current.closest(".ui.modal.active");

    if (modal) {
      modal.scrollTop = modal.scrollHeight;
    }
  };

  return (
    <div ref={boxRef}>
      <CardProductInfoUX
        // data
        productDetail={props.productDetail}
        // options
        productTypeDrugSupplyOptions={props.productTypeDrugSupplyOptions}
        unitOptions={props.unitOptions}
        billModeOptions={props.billModeOptions}
        dosageOptions={props.dosageOptions}
        modeOptions={props.modeOptions}
        // config
        type={props.productDetail?.product_type}
        // callback
        onChangeValue={handleChangeValue}
        onCancel={handleCancel}
        // Element
        StartDate={
          <DateTextBox
            value={props.productDetail?.start_date || ""}
            style={{ width: "100%" }}
            inputStyle={{ width: "100%" }}
            onChange={handleChangeDate("start_date")}
            onClick={handleClickDate}
          />
        }
        EndDate={
          <DateTextBox
            value={props.productDetail?.end_date || ""}
            minDate={props.productDetail?.start_date || ""}
            style={{ width: "100%" }}
            inputStyle={{ width: "100%" }}
            onChange={handleChangeDate("end_date")}
            onClick={handleClickDate}
          />
        }
        SubProductIngredients={
          props.productDetail?.product_type === "DRUG" &&
          (props.productDetail.drug?.drug_ingredients || []).map(
            (item, index) => (
              <SubProductIngredients
                key={"ingredients-" + index}
                onEvent={props.onEvent}
                setProp={props.setProp}
                // data
                index={index}
                data={item}
                productDetail={props.productDetail}
                // CommonInterface
                searchedItemListWithKey={props.searchedItemListWithKey}
                // config
                // hideRemove={index === 0}
              />
            )
          )
        }
        BaseUnitSearch={
          <SearchBoxDropdown
            key="BaseUnitSearch"
            onEvent={handleEventSearch}
            // config
            type="Unit"
            id="1"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={props.productDetail?.supply?.base_unit || null}
            setSelectedItem={handleSelectedItem({
              id: "1",
              name: "supply.base_unit",
              type: "Unit",
            })}
            // options
            mapOptions={mapProductOptions}
          />
        }
        StockUnitSearch={
          <SearchBoxDropdown
            key="StockUnitSearch"
            onEvent={handleEventSearch}
            // config
            type="Unit"
            id="2"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={props.productDetail?.supply?.stock_unit || null}
            setSelectedItem={handleSelectedItem({
              id: "2",
              name: "supply.stock_unit",
              type: "Unit",
            })}
            // options
            mapOptions={mapProductOptions}
          />
        }
        ManufacturerSearch={
          <SearchBoxDropdown
            key="ManufacturerSearch"
            onEvent={props.onEvent}
            // config
            type="Manufacturer"
            id="1"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={props.productDetail?.supply?.manufacturer || null}
            setSelectedItem={handleSelectedItem({
              id: "1",
              name: "supply.manufacturer",
              type: "Manufacturer",
            })}
            // options
            mapOptions={mapProductOptions}
          />
        }
        ButtonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={`${CARD_PRODUCT_INFO}_${BUTTON_ACTIONS.SAVE}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_PRODUCT_INFO}_${BUTTON_ACTIONS.SAVE}`
              ]
            }
            // config
            color={"green"}
            name={BUTTON_ACTIONS.SAVE}
            size="medium"
            title="บันทึก"
          />
        }
      />
    </div>
  );
};

export default React.memo(CardProductInfo);
