import WasmController from "../../../../frameworks/WasmController";
import moment from "moment";
// APIs
import AnesthesiaDataList from "issara-sdk/apis/AnesthesiaDataList_apps_ANS";
import ANSFormPrint from "issara-sdk/apis/ANSFormPrint_apps_ANS";
import FormDataActionLogList from "issara-sdk/apis/FormDataActionLogList_apps_PTM";
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import UserTokenizeLicenseView from "issara-sdk/apis/UserTokenizeLicenseView_users";
import { formatDate } from "react-lib/utils";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import OperatingOrderDetail from "issara-sdk/apis/OperatingOrderDetail_apps_ORM";
import TeamDetail from "issara-sdk/apis/TeamDetail_apps_ORM";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // ORQueueI
  selectedOrOrder?: any;

  // sequence
  AnesComplicationSequence?: {
    sequenceIndex?: string | null;

    id?: number | null;
    anesComplicationID?: string;
    anesOutcomeID?: string;
    anesPACUID?: string;
    anesServiceTimeID?: string;
    anesAssessmentID?: string;
    data?: {
      // CardPACURecordFormUX
      DateRamrk?: string;
      ORRmark?: string;
      IsOPD?: boolean;
      IsWard?: boolean;
      IsWardRemark?: string;
      StartOpRemark?: string;
      EndAnesRemark?: string;
      PACUDurartionTime?: string;
      AnesthesiaTeamRemark?: string; // CardPACURecordAnesthesiaUX
      DiagnosisRemark?: string;
      AnestheticAgentsRemark?: string; // CardPACURecordAnesthesiaUX
      OperationRemark?: string;
      // CardAnesComplication01UX
      Is1RetainedETTTracheostomyTube: boolean;
      Is2RetainedETTTracheostomyTube: boolean;
      Is3RetainedETTTracheostomyTube: boolean;
      Is4RetainedETTTracheostomyTube: boolean;
      Is1VentilatorySupport: boolean;
      Is2VentilatorySupport: boolean;
      Is3VentilatorySupport: boolean;
      Is4VentilatorySupport: boolean;
      Is1SoreThroat: boolean;
      Is2SoreThroat: boolean;
      Is3SoreThroat: boolean;
      Is4SoreThroat: boolean;
      Is1UpperAirwayObstruction: boolean;
      Is2UpperAirwayObstruction: boolean;
      Is3UpperAirwayObstruction: boolean;
      Is4UpperAirwayObstruction: boolean;
      Is1LowerAirwayObstruction: boolean;
      Is2LowerAirwayObstruction: boolean;
      Is3LowerAirwayObstruction: boolean;
      Is4LowerAirwayObstruction: boolean;
      Is1UnpredictedDifficultIntubation: boolean;
      Is2UnpredictedDifficultIntubation: boolean;
      Is3UnpredictedDifficultIntubation: boolean;
      Is4UnpredictedDifficultIntubation: boolean;
      Is1Aspiration: boolean;
      Is2Aspiration: boolean;
      Is3Aspiration: boolean;
      Is4Aspiration: boolean;
      Is1AirwayInjury: boolean;
      Is2AirwayInjury: boolean;
      Is3AirwayInjury: boolean;
      Is4AirwayInjury: boolean;
      Is1DentalInjury: boolean;
      Is2DentalInjury: boolean;
      Is3DentalInjury: boolean;
      Is4DentalInjury: boolean;
      Is1EsophagealIntubation: boolean;
      Is2EsophagealIntubation: boolean;
      Is3EsophagealIntubation: boolean;
      Is4EsophagealIntubation: boolean;
      Is1Pneumothorax: boolean;
      Is2Pneumothorax: boolean;
      Is3Pneumothorax: boolean;
      Is4Pneumothorax: boolean;
      Is1Hypoxaemia: boolean;
      Is2Hypoxaemia: boolean;
      Is3Hypoxaemia: boolean;
      Is4Hypoxaemia: boolean;
      Is1Hypoventilation: boolean;
      Is2Hypoventilation: boolean;
      Is3Hypoventilation: boolean;
      Is4Hypoventilation: boolean;
      Is1ReintubationTubeDisplacement: boolean;
      Is2ReintubationTubeDisplacement: boolean;
      Is3ReintubationTubeDisplacement: boolean;
      Is4ReintubationTubeDisplacement: boolean;
      Is1Atelectasis: boolean;
      Is2Atelectasis: boolean;
      Is3Atelectasis: boolean;
      Is4Atelectasis: boolean;
      Is1PulmonaryEdemaEffusion: boolean;
      Is2PulmonaryEdemaEffusion: boolean;
      Is3PulmonaryEdemaEffusion: boolean;
      Is4PulmonaryEdemaEffusion: boolean;
      Is1SigHypertensionmore180: boolean;
      Is2SigHypertensionmore180: boolean;
      Is3SigHypertensionmore180: boolean;
      Is4SigHypertensionmore180: boolean;
      Is1SigHypertensionthan180: boolean;
      Is2SigHypertensionthan180: boolean;
      Is3SigHypertensionthan180: boolean;
      Is4SigHypertensionthan180: boolean;
      Is1SigArrhythmia: boolean;
      Is2SigArrhythmia: boolean;
      Is3SigArrhythmia: boolean;
      Is4SigArrhythmia: boolean;
      Is1MyocardialIschemia: boolean;
      Is2MyocardialIschemia: boolean;
      Is3MyocardialIschemia: boolean;
      Is4MyocardialIschemia: boolean;
      Is1CardiacFailure: boolean;
      Is2CardiacFailure: boolean;
      Is3CardiacFailure: boolean;
      Is4CardiacFailure: boolean;
      Is1CardiacArrest: boolean;
      Is2CardiacArrest: boolean;
      Is3CardiacArrest: boolean;
      Is4CardiacArrest: boolean;
      Is1Shock: boolean;
      Is2Shock: boolean;
      Is3Shock: boolean;
      Is4Shock: boolean;
      Is1DelayedEmergence: boolean;
      Is2DelayedEmergence: boolean;
      Is3DelayedEmergence: boolean;
      Is4DelayedEmergence: boolean;
      Is1ComaCVA: boolean;
      Is2ComaCVA: boolean;
      Is3ComaCVA: boolean;
      Is4ComaCVA: boolean;
      Is1AwarenessUnderGA: boolean;
      Is2AwarenessUnderGA: boolean;
      Is3AwarenessUnderGA: boolean;
      Is4AwarenessUnderGA: boolean;
      Is1Highblock: boolean;
      Is2HighBlock: boolean;
      Is3HighBlock: boolean;
      Is4HighBlock: boolean;
      Is1PostDuralPunctureHeadache: boolean;
      Is2PostDuralPunctureHeadache: boolean;
      Is3PostDuralPunctureHeadache: boolean;
      Is4PostDuralPunctureHeadache: boolean;
      Is1PeripheralNerveInjury: boolean;
      Is2PeripheralNerveInjury: boolean;
      Is3PeripheralNerveInjury: boolean;
      Is4PeripheralNerveInjury: boolean;
      Is1BackPain: boolean;
      Is2BackPain: boolean;
      Is3BackPain: boolean;
      Is4BackPain: boolean;
      Is1Convulsion: boolean;
      Is2Convulsion: boolean;
      Is3Convulsion: boolean;
      Is4Convulsion: boolean;
      Is1LAToxicity: boolean;
      Is2LAToxicity: boolean;
      Is3LAToxicity: boolean;
      Is4LAToxicity: boolean;
      Is1Delirium: boolean;
      Is2Delirium: boolean;
      Is3Delirium: boolean;
      Is4Delirium: boolean;
      Is1Hypoglycemia: boolean;
      Is2Hypoglycemia: boolean;
      Is3Hypoglycemia: boolean;
      Is4Hypoglycemia: boolean;
      Is1ElectolyteAcidBaseImbalance: boolean;
      Is2ElectolyteAcidBaseImbalance: boolean;
      Is3ElectolyteAcidBaseImbalance: boolean;
      Is4ElectolyteAcidBaseImbalance: boolean;
      Is1Hypothermia: boolean;
      Is2Hypothermia: boolean;
      Is3Hypothermia: boolean;
      Is4Hypothermia: boolean;
      Is1Fever: boolean;
      Is2Fever: boolean;
      Is3Fever: boolean;
      Is4Fever: boolean;
      Is1TransfusionReaction: boolean;
      Is2TransfusionReaction: boolean;
      Is3TransfusionReaction: boolean;
      Is4TransfusionReaction: boolean;
      Is1CoagulopathyMI: boolean;
      Is2CoagulopathyMI: boolean;
      Is3CoagulopathyMI: boolean;
      Is4CoagulopathyMI: boolean;
      Is1MassiveBloodLoss: boolean;
      Is2MassiveBloodLoss: boolean;
      Is3MassiveBloodLoss: boolean;
      Is4MassiveBloodLoss: boolean;
      Is1AllergicReaction: boolean;
      Is2AllergicReaction: boolean;
      Is3AllergicReaction: boolean;
      Is4AllergicReaction: boolean;
      Is1Pain: boolean;
      Is2Pain: boolean;
      Is3Pain: boolean;
      Is4Pain: boolean;
      Is1Burn: boolean;
      Is2Burn: boolean;
      Is3Burn: boolean;
      Is4Burn: boolean;
      Is1Shivering: boolean;
      Is2Shivering: boolean;
      Is3Shivering: boolean;
      Is4Shivering: boolean;
      Is1NauseaVomiting: boolean;
      Is2NauseaVomiting: boolean;
      Is3NauseaVomiting: boolean;
      Is4NauseaVomiting: boolean;
      Is1UrineRetention: boolean;
      Is2UrineRetention: boolean;
      Is3UrineRetention: boolean;
      Is4UrineRetention: boolean;
      Is1Itching: boolean;
      Is2Itching: boolean;
      Is3Itching: boolean;
      Is4Itching: boolean;
      Is1DrugErrorHumanError: boolean;
      Is2DrugErrorHumanError: boolean;
      Is3DrugErrorHumanError: boolean;
      Is4DrugErrorHumanError: boolean;
      Is1PressureSore: boolean;
      Is2PressureSore: boolean;
      Is3PressureSore: boolean;
      Is4PressureSore: boolean;
      Is1ReOperation: boolean;
      Is2ReOperation: boolean;
      Is3ReOperation: boolean;
      Is4ReOperation: boolean;
      OtherSpecifyRemark?: string | null;
      Is1OtherSpecify: boolean;
      Is2OtherSpecify: boolean;
      Is3OtherSpecify: boolean;
      Is4OtherSpecify: boolean;
      WoundRemark?: string | null;
      Is1Wound: boolean;
      Is2Wound: boolean;
      Is3Wound: boolean;
      Is4Wound: boolean;
      ComplicationNoneRemark?: string | null;
      IS1ComplicationNone: boolean;
      IS2ComplicationNone: boolean;
      IS3ComplicationNone: boolean;
      IS4ComplicationNone: boolean;
      IsDirectTransferred: boolean;
      IsDirectTransferredToOtherRemark?: string | null;
      PACUDurationTime?: string | null;
      PACUDurationRemark?: string | null;
      // CardAnesComplication02UX
      Outcome?: string;
      OutcomeRemark?: string;
      // CardAnesComplication03UX
      IsPostoperativePainManagementInPACUNone: boolean;
      IsIVIM: boolean;
      IsPatientControlledAnalgesia: boolean;
      IsLumbarEpidural: boolean;
      IsThoracicEpidural: boolean;
      IsPeripheralNerveBlock: boolean;
      IsCaudal: boolean;
      SpinalOpioid: boolean;
      Oral: boolean;
      IsPostoperativePainManagementInPACU: boolean;
      IsPostoperativePainManagementInPACURemark?: string | null;
      // CardAnesComplication04UX
      PostAnesthesiaCareOffServiceTime?: string;
      PostAnesthesiaCareOffServiceTimeRemark?: string;
      // CardAnesComplication05UX
      transferInformationData?: [];
    };
    modLogInfo?: { open?: boolean; logList: any[] };
  } | null;
};

export const StateInitial: State = {
  AnesComplicationSequence: {
    sequenceIndex: null,

    id: null,
    anesComplicationID: "",
    anesOutcomeID: "",
    anesPACUID: "",
    anesServiceTimeID: "",
    anesAssessmentID: "",
    data: {
      // CardPACURecordFormUX
      DateRamrk: "",
      ORRmark: "",
      IsOPD: false,
      IsWard: false,
      IsWardRemark: "",
      StartOpRemark: "",
      EndAnesRemark: "",
      PACUDurartionTime: "",
      AnesthesiaTeamRemark: "", // CardPACURecordAnesthesiaUX
      DiagnosisRemark: "",
      AnestheticAgentsRemark: "", // CardPACURecordAnesthesiaUX
      OperationRemark: "",
      // CardAnesComplication01UX
      Is1RetainedETTTracheostomyTube: false,
      Is2RetainedETTTracheostomyTube: false,
      Is3RetainedETTTracheostomyTube: false,
      Is4RetainedETTTracheostomyTube: false,
      Is1VentilatorySupport: false,
      Is2VentilatorySupport: false,
      Is3VentilatorySupport: false,
      Is4VentilatorySupport: false,
      Is1SoreThroat: false,
      Is2SoreThroat: false,
      Is3SoreThroat: false,
      Is4SoreThroat: false,
      Is1UpperAirwayObstruction: false,
      Is2UpperAirwayObstruction: false,
      Is3UpperAirwayObstruction: false,
      Is4UpperAirwayObstruction: false,
      Is1LowerAirwayObstruction: false,
      Is2LowerAirwayObstruction: false,
      Is3LowerAirwayObstruction: false,
      Is4LowerAirwayObstruction: false,
      Is1UnpredictedDifficultIntubation: false,
      Is2UnpredictedDifficultIntubation: false,
      Is3UnpredictedDifficultIntubation: false,
      Is4UnpredictedDifficultIntubation: false,
      Is1Aspiration: false,
      Is2Aspiration: false,
      Is3Aspiration: false,
      Is4Aspiration: false,
      Is1AirwayInjury: false,
      Is2AirwayInjury: false,
      Is3AirwayInjury: false,
      Is4AirwayInjury: false,
      Is1DentalInjury: false,
      Is2DentalInjury: false,
      Is3DentalInjury: false,
      Is4DentalInjury: false,
      Is1EsophagealIntubation: false,
      Is2EsophagealIntubation: false,
      Is3EsophagealIntubation: false,
      Is4EsophagealIntubation: false,
      Is1Pneumothorax: false,
      Is2Pneumothorax: false,
      Is3Pneumothorax: false,
      Is4Pneumothorax: false,
      Is1Hypoxaemia: false,
      Is2Hypoxaemia: false,
      Is3Hypoxaemia: false,
      Is4Hypoxaemia: false,
      Is1Hypoventilation: false,
      Is2Hypoventilation: false,
      Is3Hypoventilation: false,
      Is4Hypoventilation: false,
      Is1ReintubationTubeDisplacement: false,
      Is2ReintubationTubeDisplacement: false,
      Is3ReintubationTubeDisplacement: false,
      Is4ReintubationTubeDisplacement: false,
      Is1Atelectasis: false,
      Is2Atelectasis: false,
      Is3Atelectasis: false,
      Is4Atelectasis: false,
      Is1PulmonaryEdemaEffusion: false,
      Is2PulmonaryEdemaEffusion: false,
      Is3PulmonaryEdemaEffusion: false,
      Is4PulmonaryEdemaEffusion: false,
      Is1SigHypertensionmore180: false,
      Is2SigHypertensionmore180: false,
      Is3SigHypertensionmore180: false,
      Is4SigHypertensionmore180: false,
      Is1SigHypertensionthan180: false,
      Is2SigHypertensionthan180: false,
      Is3SigHypertensionthan180: false,
      Is4SigHypertensionthan180: false,
      Is1SigArrhythmia: false,
      Is2SigArrhythmia: false,
      Is3SigArrhythmia: false,
      Is4SigArrhythmia: false,
      Is1MyocardialIschemia: false,
      Is2MyocardialIschemia: false,
      Is3MyocardialIschemia: false,
      Is4MyocardialIschemia: false,
      Is1CardiacFailure: false,
      Is2CardiacFailure: false,
      Is3CardiacFailure: false,
      Is4CardiacFailure: false,
      Is1CardiacArrest: false,
      Is2CardiacArrest: false,
      Is3CardiacArrest: false,
      Is4CardiacArrest: false,
      Is1Shock: false,
      Is2Shock: false,
      Is3Shock: false,
      Is4Shock: false,
      Is1DelayedEmergence: false,
      Is2DelayedEmergence: false,
      Is3DelayedEmergence: false,
      Is4DelayedEmergence: false,
      Is1ComaCVA: false,
      Is2ComaCVA: false,
      Is3ComaCVA: false,
      Is4ComaCVA: false,
      Is1AwarenessUnderGA: false,
      Is2AwarenessUnderGA: false,
      Is3AwarenessUnderGA: false,
      Is4AwarenessUnderGA: false,
      Is1Highblock: false,
      Is2HighBlock: false,
      Is3HighBlock: false,
      Is4HighBlock: false,
      Is1PostDuralPunctureHeadache: false,
      Is2PostDuralPunctureHeadache: false,
      Is3PostDuralPunctureHeadache: false,
      Is4PostDuralPunctureHeadache: false,
      Is1PeripheralNerveInjury: false,
      Is2PeripheralNerveInjury: false,
      Is3PeripheralNerveInjury: false,
      Is4PeripheralNerveInjury: false,
      Is1BackPain: false,
      Is2BackPain: false,
      Is3BackPain: false,
      Is4BackPain: false,
      Is1Convulsion: false,
      Is2Convulsion: false,
      Is3Convulsion: false,
      Is4Convulsion: false,
      Is1LAToxicity: false,
      Is2LAToxicity: false,
      Is3LAToxicity: false,
      Is4LAToxicity: false,
      Is1Delirium: false,
      Is2Delirium: false,
      Is3Delirium: false,
      Is4Delirium: false,
      Is1Hypoglycemia: false,
      Is2Hypoglycemia: false,
      Is3Hypoglycemia: false,
      Is4Hypoglycemia: false,
      Is1ElectolyteAcidBaseImbalance: false,
      Is2ElectolyteAcidBaseImbalance: false,
      Is3ElectolyteAcidBaseImbalance: false,
      Is4ElectolyteAcidBaseImbalance: false,
      Is1Hypothermia: false,
      Is2Hypothermia: false,
      Is3Hypothermia: false,
      Is4Hypothermia: false,
      Is1Fever: false,
      Is2Fever: false,
      Is3Fever: false,
      Is4Fever: false,
      Is1TransfusionReaction: false,
      Is2TransfusionReaction: false,
      Is3TransfusionReaction: false,
      Is4TransfusionReaction: false,
      Is1CoagulopathyMI: false,
      Is2CoagulopathyMI: false,
      Is3CoagulopathyMI: false,
      Is4CoagulopathyMI: false,
      Is1MassiveBloodLoss: false,
      Is2MassiveBloodLoss: false,
      Is3MassiveBloodLoss: false,
      Is4MassiveBloodLoss: false,
      Is1AllergicReaction: false,
      Is2AllergicReaction: false,
      Is3AllergicReaction: false,
      Is4AllergicReaction: false,
      Is1Pain: false,
      Is2Pain: false,
      Is3Pain: false,
      Is4Pain: false,
      Is1Burn: false,
      Is2Burn: false,
      Is3Burn: false,
      Is4Burn: false,
      Is1Shivering: false,
      Is2Shivering: false,
      Is3Shivering: false,
      Is4Shivering: false,
      Is1NauseaVomiting: false,
      Is2NauseaVomiting: false,
      Is3NauseaVomiting: false,
      Is4NauseaVomiting: false,
      Is1UrineRetention: false,
      Is2UrineRetention: false,
      Is3UrineRetention: false,
      Is4UrineRetention: false,
      Is1Itching: false,
      Is2Itching: false,
      Is3Itching: false,
      Is4Itching: false,
      Is1DrugErrorHumanError: false,
      Is2DrugErrorHumanError: false,
      Is3DrugErrorHumanError: false,
      Is4DrugErrorHumanError: false,
      Is1PressureSore: false,
      Is2PressureSore: false,
      Is3PressureSore: false,
      Is4PressureSore: false,
      Is1ReOperation: false,
      Is2ReOperation: false,
      Is3ReOperation: false,
      Is4ReOperation: false,
      OtherSpecifyRemark: "",
      Is1OtherSpecify: false,
      Is2OtherSpecify: false,
      Is3OtherSpecify: false,
      Is4OtherSpecify: false,
      WoundRemark: "",
      Is1Wound: false,
      Is2Wound: false,
      Is3Wound: false,
      Is4Wound: false,
      ComplicationNoneRemark: "",
      IS1ComplicationNone: false,
      IS2ComplicationNone: false,
      IS3ComplicationNone: false,
      IS4ComplicationNone: false,
      IsDirectTransferred: false,
      IsDirectTransferredToOtherRemark: "",
      PACUDurationTime: "",
      PACUDurationRemark: "",
      // CardAnesComplication02UX
      Outcome: "",
      OutcomeRemark: "",
      // CardAnesComplication03UX
      IsPostoperativePainManagementInPACUNone: false,
      IsIVIM: false,
      IsPatientControlledAnalgesia: false,
      IsLumbarEpidural: false,
      IsThoracicEpidural: false,
      IsPeripheralNerveBlock: false,
      IsCaudal: false,
      SpinalOpioid: false,
      Oral: false,
      IsPostoperativePainManagementInPACU: false,
      IsPostoperativePainManagementInPACURemark: "",
      // CardAnesComplication04UX
      PostAnesthesiaCareOffServiceTime: "",
      PostAnesthesiaCareOffServiceTimeRemark: "",
      // CardAnesComplication05UX
      transferInformationData: [],
    },
    modLogInfo: { open: false, logList: [] },
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const GetMaster: Handler = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["drugName", {}],
        ["divisionWard", {}],
      ],
    },
  });

  controller.setState(
    {
      AnesComplicationSequence: { sequenceIndex: "Action" },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "FETCH_ANESCOMPLI") {
    const [formPeriResp, formPeriErr, formPeriNet] =
      await FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: "CardAnestheticComplication",
          form_version: params.formVersion,
        },
      });

    const [formComplicationResp, formComplicationErr, formComplicationNet] =
      await FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: "CardAnesComplication",
          form_version: params.formVersion,
        },
      });

    const [formOutcomeResp, formOutcomeErr, formOutcomeNet] =
      await FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: "CardAnesOutcome",
          form_version: params.formVersion,
        },
      });

    const [formPACUResp, formPACUErr, formPACUNet] =
      await FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: "CardAnesPACU",
          form_version: params.formVersion,
        },
      });

    const [formServiceTimeResp, formServiceTimeErr, formServiceTimeNet] =
      await FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: "CardAnesServiceTime",
          form_version: params.formVersion,
        },
      });

    const [formAssessmentResp, formAssessmentErr, formAssessmentNet] =
      await FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: "CardAnesAssessment",
          form_version: params.formVersion,
        },
      });

    const [operatingResp, operatingErr, operatingNet] =
      await OperatingOrderDetail.retrieve({
        apiToken: controller.apiToken,
        pk: state.selectedOrOrder.id,
      });

    const [anesthesiaResp, anesthesiaErr, anesthesiaNet] =
      await DoctorDetail.retrieve({
        apiToken: controller.apiToken,
        pk: operatingResp?.anesthesia_teams[0]?.anesthesiologist,
      });

    const [surgicalTeamResp, surgicalTeamErr, surgicalTeamNet] =
      await DoctorDetail.retrieve({
        apiToken: controller.apiToken,
        pk: operatingResp?.teams[0]?.chief_surgeon,
      });

    const [teamDetailResp, teamDetailErr, teamDetailNet] =
      await TeamDetail.retrieve({
        apiToken: controller.apiToken,
        pk: operatingResp?.teams[0]?.id,
      });

    if (formPeriErr || !formPeriResp.data) {
      const [formPacuResp, formPacuErr, formPacuNet] =
        await FormDataLatest.retrieve({
          apiToken: controller.apiToken,
          params: {
            encounter: state.selectedOrOrder?.encounter,
            form_code: "CardPacuRecord",
            form_version: "1.0",
          },
        });
      if (formPacuErr || !formPacuResp.data) {
        let seq: any = {
          ...StateInitial.AnesComplicationSequence,
          sequenceIndex: state.AnesComplicationSequence?.sequenceIndex,
        };

        const aneOrderId = state.selectedOrOrder?.other_orders?.find(
          (item: any) => item.model === "anesthesiaorder"
        )?.id;
        if (aneOrderId) {
          const [aneDataResp, aneDataErr, aneDataNet] =
            await AnesthesiaDataList.list({
              apiToken: controller.apiToken,
              params: {
                encounter: state.selectedOrOrder?.encounter,
                ans_order_id: aneOrderId,
              },
            });
          if (aneDataResp) {
            seq.data.DiagnosisRemark = aneDataResp.post_diagnosis;
            seq.data.OperationRemark = aneDataResp.post_operation;
          }

          seq.data.ORRmark = operatingResp?.operating_room_no;
          seq.data.AnesthesiaTeamRemark = anesthesiaResp?.name_code;
          seq.data.DiagnosisRemark =
            teamDetailResp?.post_principal_diagnosis.length !== 0
              ? `[${teamDetailResp?.post_principal_diagnosis[0]?.icd_code}] ${teamDetailResp?.post_principal_diagnosis[0]?.icd_term}`
              : "";
          seq.data.AnestheticAgentsRemark = surgicalTeamResp?.name_code;
          seq.data.OperationRemark =
            teamDetailResp !== null
              ? `[${teamDetailResp?.post_operating_order_item?.procedures[0]?.icd9cm_code}] ${teamDetailResp?.post_operating_order_item?.procedures[0]?.icd9cm_term}`
              : "";
          seq.data.DateRamrk = formatDate(moment());
          seq.data.StartDate = formatDate(moment());
          seq.data.EndDate = formatDate(moment());
        }
        controller.setState({ AnesComplicationSequence: seq });
      } else {
        let seq: any = {
          ...StateInitial.AnesComplicationSequence,
          sequenceIndex: state.AnesComplicationSequence?.sequenceIndex,
          ...formPacuResp
        };

        // seq.data.DateRamrk = formPacuResp.data?.DateRamrk;
        // seq.data.ORRmark = formPacuResp.data?.ORRmark;
        // seq.data.IsOPD = formPacuResp.data?.IsOPD;
        // seq.data.IsWard = formPacuResp.data?.IsWard;
        // seq.data.IsWardRemark = formPacuResp.data?.IsWardRemark;
        // seq.data.StartOpRemark = formPacuResp.data?.StartOpRemark;
        // seq.data.EndAnesRemark = formPacuResp.data?.EndAnesRemark;
        // seq.data.PACUDurartionTime = formPacuResp.data?.PACUDurartionTime;
        // seq.data.AnesthesiaTeamRemark = formPacuResp.data?.AnesthesiaTeamRemark;
        // seq.data.DiagnosisRemark = formPacuResp.data?.DiagnosisRemark;
        // seq.data.AnestheticAgentsRemark =
        //   formPacuResp.data?.AnestheticAgentsRemark;
        // seq.data.OperationRemark = formPacuResp.data?.OperationRemark;
        controller.setState({
          AnesComplicationSequence: {
            ...seq,
            anesComplicationID: formComplicationResp?.id,
            anesOutcomeID: formOutcomeResp?.id,
            anesPACUID: formPACUResp?.id,
            anesServiceTimeID: formServiceTimeResp?.id,
            anesAssessmentID: formAssessmentResp?.id,
            data: {
              ...seq.data,
              ...formPeriResp?.data,
              ...formComplicationResp?.data,
              ...formOutcomeResp?.data,
              ...formPACUResp?.data,
              ...formServiceTimeResp?.data,
              ...formAssessmentResp?.data,
            },
          },
        });
      }
    } else {
      controller.setState({
        AnesComplicationSequence: {
          ...state.AnesComplicationSequence,
          ...formPeriResp,
          anesComplicationID: formComplicationResp?.id,
          anesOutcomeID: formOutcomeResp?.id,
          anesPACUID: formPACUResp?.id,
          anesServiceTimeID: formServiceTimeResp?.id,
          anesAssessmentID: formAssessmentResp?.id,
          data: {
            ...state.AnesComplicationSequence,
            ...formPeriResp?.data,
            ...formComplicationResp?.data,
            ...formOutcomeResp?.data,
            ...formPACUResp?.data,
            ...formServiceTimeResp?.data,
            ...formAssessmentResp?.data,
          },
        },
      });
    }
  } else if (params?.action === "SAVE" || params?.action === "CONFIRM") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    let saveResp = null;
    let saveErr = null;
    let saveNet = null;
    let preAnesData = state.AnesComplicationSequence?.data;
    var preID =
      params.formCode === "CardAnesComplication"
        ? state.AnesComplicationSequence?.anesComplicationID
        : params.formCode === "CardAnesOutcome"
        ? state.AnesComplicationSequence?.anesOutcomeID
        : params.formCode === "CardAnesPACU"
        ? state.AnesComplicationSequence?.anesPACUID
        : params.formCode === "CardAnesServiceTime"
        ? state.AnesComplicationSequence?.anesServiceTimeID
        : params.formCode === "CardAnesAssessment"
        ? state.AnesComplicationSequence?.anesAssessmentID
        : "";

    const dataPostAnesthetic = {
      DateRamrk: preAnesData?.DateRamrk,
      ORRmark: preAnesData?.ORRmark,
      IsOPD: preAnesData?.IsOPD,
      IsWard: preAnesData?.IsWard,
      IsWardRemark: preAnesData?.IsWardRemark,
      StartOpRemark: preAnesData?.StartOpRemark,
      EndAnesRemark: preAnesData?.EndAnesRemark,
      PACUDurartionTime: preAnesData?.PACUDurartionTime,
      AnesthesiaTeamRemark: preAnesData?.AnesthesiaTeamRemark,
      DiagnosisRemark: preAnesData?.DiagnosisRemark,
      AnestheticAgentsRemark: preAnesData?.AnestheticAgentsRemark,
      OperationRemark: preAnesData?.OperationRemark,
    };

    const dataComplication = {
      Is1RetainedETTTracheostomyTube:
        preAnesData?.Is1RetainedETTTracheostomyTube,
      Is2RetainedETTTracheostomyTube:
        preAnesData?.Is2RetainedETTTracheostomyTube,
      Is3RetainedETTTracheostomyTube:
        preAnesData?.Is3RetainedETTTracheostomyTube,
      Is4RetainedETTTracheostomyTube:
        preAnesData?.Is4RetainedETTTracheostomyTube,
      Is1VentilatorySupport: preAnesData?.Is1VentilatorySupport,
      Is2VentilatorySupport: preAnesData?.Is2VentilatorySupport,
      Is3VentilatorySupport: preAnesData?.Is3VentilatorySupport,
      Is4VentilatorySupport: preAnesData?.Is4VentilatorySupport,
      Is1SoreThroat: preAnesData?.Is1SoreThroat,
      Is2SoreThroat: preAnesData?.Is2SoreThroat,
      Is3SoreThroat: preAnesData?.Is3SoreThroat,
      Is4SoreThroat: preAnesData?.Is4SoreThroat,
      Is1UpperAirwayObstruction: preAnesData?.Is1UpperAirwayObstruction,
      Is2UpperAirwayObstruction: preAnesData?.Is2UpperAirwayObstruction,
      Is3UpperAirwayObstruction: preAnesData?.Is3UpperAirwayObstruction,
      Is4UpperAirwayObstruction: preAnesData?.Is4UpperAirwayObstruction,
      Is1LowerAirwayObstruction: preAnesData?.Is1LowerAirwayObstruction,
      Is2LowerAirwayObstruction: preAnesData?.Is2LowerAirwayObstruction,
      Is3LowerAirwayObstruction: preAnesData?.Is3LowerAirwayObstruction,
      Is4LowerAirwayObstruction: preAnesData?.Is4LowerAirwayObstruction,
      Is1UnpredictedDifficultIntubation:
        preAnesData?.Is1UnpredictedDifficultIntubation,
      Is2UnpredictedDifficultIntubation:
        preAnesData?.Is2UnpredictedDifficultIntubation,
      Is3UnpredictedDifficultIntubation:
        preAnesData?.Is3UnpredictedDifficultIntubation,
      Is4UnpredictedDifficultIntubation:
        preAnesData?.Is4UnpredictedDifficultIntubation,
      Is1Aspiration: preAnesData?.Is1Aspiration,
      Is2Aspiration: preAnesData?.Is2Aspiration,
      Is3Aspiration: preAnesData?.Is3Aspiration,
      Is4Aspiration: preAnesData?.Is4Aspiration,
      Is1AirwayInjury: preAnesData?.Is1AirwayInjury,
      Is2AirwayInjury: preAnesData?.Is2AirwayInjury,
      Is3AirwayInjury: preAnesData?.Is3AirwayInjury,
      Is4AirwayInjury: preAnesData?.Is4AirwayInjury,
      Is1DentalInjury: preAnesData?.Is1DentalInjury,
      Is2DentalInjury: preAnesData?.Is2DentalInjury,
      Is3DentalInjury: preAnesData?.Is3DentalInjury,
      Is4DentalInjury: preAnesData?.Is4DentalInjury,
      Is1EsophagealIntubation: preAnesData?.Is1EsophagealIntubation,
      Is2EsophagealIntubation: preAnesData?.Is2EsophagealIntubation,
      Is3EsophagealIntubation: preAnesData?.Is3EsophagealIntubation,
      Is4EsophagealIntubation: preAnesData?.Is4EsophagealIntubation,
      Is1Pneumothorax: preAnesData?.Is1Pneumothorax,
      Is2Pneumothorax: preAnesData?.Is2Pneumothorax,
      Is3Pneumothorax: preAnesData?.Is3Pneumothorax,
      Is4Pneumothorax: preAnesData?.Is4Pneumothorax,
      Is1Hypoxaemia: preAnesData?.Is1Hypoxaemia,
      Is2Hypoxaemia: preAnesData?.Is2Hypoxaemia,
      Is3Hypoxaemia: preAnesData?.Is3Hypoxaemia,
      Is4Hypoxaemia: preAnesData?.Is4Hypoxaemia,
      Is1Hypoventilation: preAnesData?.Is1Hypoventilation,
      Is2Hypoventilation: preAnesData?.Is2Hypoventilation,
      Is3Hypoventilation: preAnesData?.Is3Hypoventilation,
      Is4Hypoventilation: preAnesData?.Is4Hypoventilation,
      Is1ReintubationTubeDisplacement:
        preAnesData?.Is1ReintubationTubeDisplacement,
      Is2ReintubationTubeDisplacement:
        preAnesData?.Is2ReintubationTubeDisplacement,
      Is3ReintubationTubeDisplacement:
        preAnesData?.Is3ReintubationTubeDisplacement,
      Is4ReintubationTubeDisplacement:
        preAnesData?.Is4ReintubationTubeDisplacement,
      Is1Atelectasis: preAnesData?.Is1Atelectasis,
      Is2Atelectasis: preAnesData?.Is2Atelectasis,
      Is3Atelectasis: preAnesData?.Is3Atelectasis,
      Is4Atelectasis: preAnesData?.Is4Atelectasis,
      Is1PulmonaryEdemaEffusion: preAnesData?.Is1PulmonaryEdemaEffusion,
      Is2PulmonaryEdemaEffusion: preAnesData?.Is2PulmonaryEdemaEffusion,
      Is3PulmonaryEdemaEffusion: preAnesData?.Is3PulmonaryEdemaEffusion,
      Is4PulmonaryEdemaEffusion: preAnesData?.Is4PulmonaryEdemaEffusion,
      Is1SigHypertensionmore180: preAnesData?.Is1SigHypertensionmore180,
      Is2SigHypertensionmore180: preAnesData?.Is2SigHypertensionmore180,
      Is3SigHypertensionmore180: preAnesData?.Is3SigHypertensionmore180,
      Is4SigHypertensionmore180: preAnesData?.Is4SigHypertensionmore180,
      Is1SigHypertensionthan180: preAnesData?.Is1SigHypertensionthan180,
      Is2SigHypertensionthan180: preAnesData?.Is2SigHypertensionthan180,
      Is3SigHypertensionthan180: preAnesData?.Is3SigHypertensionthan180,
      Is4SigHypertensionthan180: preAnesData?.Is4SigHypertensionthan180,
      Is1SigArrhythmia: preAnesData?.Is1SigArrhythmia,
      Is2SigArrhythmia: preAnesData?.Is2SigArrhythmia,
      Is3SigArrhythmia: preAnesData?.Is3SigArrhythmia,
      Is4SigArrhythmia: preAnesData?.Is4SigArrhythmia,
      Is1MyocardialIschemia: preAnesData?.Is1MyocardialIschemia,
      Is2MyocardialIschemia: preAnesData?.Is2MyocardialIschemia,
      Is3MyocardialIschemia: preAnesData?.Is3MyocardialIschemia,
      Is4MyocardialIschemia: preAnesData?.Is4MyocardialIschemia,
      Is1CardiacFailure: preAnesData?.Is1CardiacFailure,
      Is2CardiacFailure: preAnesData?.Is2CardiacFailure,
      Is3CardiacFailure: preAnesData?.Is3CardiacFailure,
      Is4CardiacFailure: preAnesData?.Is4CardiacFailure,
      Is1CardiacArrest: preAnesData?.Is1CardiacArrest,
      Is2CardiacArrest: preAnesData?.Is2CardiacArrest,
      Is3CardiacArrest: preAnesData?.Is3CardiacArrest,
      Is4CardiacArrest: preAnesData?.Is4CardiacArrest,
      Is1Shock: preAnesData?.Is1Shock,
      Is2Shock: preAnesData?.Is2Shock,
      Is3Shock: preAnesData?.Is3Shock,
      Is4Shock: preAnesData?.Is4Shock,
      Is1DelayedEmergence: preAnesData?.Is1DelayedEmergence,
      Is2DelayedEmergence: preAnesData?.Is2DelayedEmergence,
      Is3DelayedEmergence: preAnesData?.Is3DelayedEmergence,
      Is4DelayedEmergence: preAnesData?.Is4DelayedEmergence,
      Is1ComaCVA: preAnesData?.Is1ComaCVA,
      Is2ComaCVA: preAnesData?.Is2ComaCVA,
      Is3ComaCVA: preAnesData?.Is3ComaCVA,
      Is4ComaCVA: preAnesData?.Is4ComaCVA,
      Is1AwarenessUnderGA: preAnesData?.Is1AwarenessUnderGA,
      Is2AwarenessUnderGA: preAnesData?.Is2AwarenessUnderGA,
      Is3AwarenessUnderGA: preAnesData?.Is3AwarenessUnderGA,
      Is4AwarenessUnderGA: preAnesData?.Is4AwarenessUnderGA,
      Is1Highblock: preAnesData?.Is1Highblock,
      Is2HighBlock: preAnesData?.Is2HighBlock,
      Is3HighBlock: preAnesData?.Is3HighBlock,
      Is4HighBlock: preAnesData?.Is4HighBlock,
      Is1PostDuralPunctureHeadache: preAnesData?.Is1PostDuralPunctureHeadache,
      Is2PostDuralPunctureHeadache: preAnesData?.Is2PostDuralPunctureHeadache,
      Is3PostDuralPunctureHeadache: preAnesData?.Is3PostDuralPunctureHeadache,
      Is4PostDuralPunctureHeadache: preAnesData?.Is4PostDuralPunctureHeadache,
      Is1PeripheralNerveInjury: preAnesData?.Is1PeripheralNerveInjury,
      Is2PeripheralNerveInjury: preAnesData?.Is2PeripheralNerveInjury,
      Is3PeripheralNerveInjury: preAnesData?.Is3PeripheralNerveInjury,
      Is4PeripheralNerveInjury: preAnesData?.Is4PeripheralNerveInjury,
      Is1BackPain: preAnesData?.Is1BackPain,
      Is2BackPain: preAnesData?.Is2BackPain,
      Is3BackPain: preAnesData?.Is3BackPain,
      Is4BackPain: preAnesData?.Is4BackPain,
      Is1Convulsion: preAnesData?.Is1Convulsion,
      Is2Convulsion: preAnesData?.Is2Convulsion,
      Is3Convulsion: preAnesData?.Is3Convulsion,
      Is4Convulsion: preAnesData?.Is4Convulsion,
      Is1LAToxicity: preAnesData?.Is1LAToxicity,
      Is2LAToxicity: preAnesData?.Is2LAToxicity,
      Is3LAToxicity: preAnesData?.Is3LAToxicity,
      Is4LAToxicity: preAnesData?.Is4LAToxicity,
      Is1Delirium: preAnesData?.Is1Delirium,
      Is2Delirium: preAnesData?.Is2Delirium,
      Is3Delirium: preAnesData?.Is3Delirium,
      Is4Delirium: preAnesData?.Is4Delirium,
      Is1Hypoglycemia: preAnesData?.Is1Hypoglycemia,
      Is2Hypoglycemia: preAnesData?.Is2Hypoglycemia,
      Is3Hypoglycemia: preAnesData?.Is3Hypoglycemia,
      Is4Hypoglycemia: preAnesData?.Is4Hypoglycemia,
      Is1ElectolyteAcidBaseImbalance:
        preAnesData?.Is1ElectolyteAcidBaseImbalance,
      Is2ElectolyteAcidBaseImbalance:
        preAnesData?.Is2ElectolyteAcidBaseImbalance,
      Is3ElectolyteAcidBaseImbalance:
        preAnesData?.Is3ElectolyteAcidBaseImbalance,
      Is4ElectolyteAcidBaseImbalance:
        preAnesData?.Is4ElectolyteAcidBaseImbalance,
      Is1Hypothermia: preAnesData?.Is1Hypothermia,
      Is2Hypothermia: preAnesData?.Is2Hypothermia,
      Is3Hypothermia: preAnesData?.Is3Hypothermia,
      Is4Hypothermia: preAnesData?.Is4Hypothermia,
      Is1Fever: preAnesData?.Is1Fever,
      Is2Fever: preAnesData?.Is2Fever,
      Is3Fever: preAnesData?.Is3Fever,
      Is4Fever: preAnesData?.Is4Fever,
      Is1TransfusionReaction: preAnesData?.Is1TransfusionReaction,
      Is2TransfusionReaction: preAnesData?.Is2TransfusionReaction,
      Is3TransfusionReaction: preAnesData?.Is3TransfusionReaction,
      Is4TransfusionReaction: preAnesData?.Is4TransfusionReaction,
      Is1CoagulopathyMI: preAnesData?.Is1CoagulopathyMI,
      Is2CoagulopathyMI: preAnesData?.Is2CoagulopathyMI,
      Is3CoagulopathyMI: preAnesData?.Is3CoagulopathyMI,
      Is4CoagulopathyMI: preAnesData?.Is4CoagulopathyMI,
      Is1MassiveBloodLoss: preAnesData?.Is1MassiveBloodLoss,
      Is2MassiveBloodLoss: preAnesData?.Is2MassiveBloodLoss,
      Is3MassiveBloodLoss: preAnesData?.Is3MassiveBloodLoss,
      Is4MassiveBloodLoss: preAnesData?.Is4MassiveBloodLoss,
      Is1AllergicReaction: preAnesData?.Is1AllergicReaction,
      Is2AllergicReaction: preAnesData?.Is2AllergicReaction,
      Is3AllergicReaction: preAnesData?.Is3AllergicReaction,
      Is4AllergicReaction: preAnesData?.Is4AllergicReaction,
      Is1Pain: preAnesData?.Is1Pain,
      Is2Pain: preAnesData?.Is2Pain,
      Is3Pain: preAnesData?.Is3Pain,
      Is4Pain: preAnesData?.Is4Pain,
      Is1Burn: preAnesData?.Is1Burn,
      Is2Burn: preAnesData?.Is2Burn,
      Is3Burn: preAnesData?.Is3Burn,
      Is4Burn: preAnesData?.Is4Burn,
      Is1Shivering: preAnesData?.Is1Shivering,
      Is2Shivering: preAnesData?.Is2Shivering,
      Is3Shivering: preAnesData?.Is3Shivering,
      Is4Shivering: preAnesData?.Is4Shivering,
      Is1NauseaVomiting: preAnesData?.Is1NauseaVomiting,
      Is2NauseaVomiting: preAnesData?.Is2NauseaVomiting,
      Is3NauseaVomiting: preAnesData?.Is3NauseaVomiting,
      Is4NauseaVomiting: preAnesData?.Is4NauseaVomiting,
      Is1UrineRetention: preAnesData?.Is1UrineRetention,
      Is2UrineRetention: preAnesData?.Is2UrineRetention,
      Is3UrineRetention: preAnesData?.Is3UrineRetention,
      Is4UrineRetention: preAnesData?.Is4UrineRetention,
      Is1Itching: preAnesData?.Is1Itching,
      Is2Itching: preAnesData?.Is2Itching,
      Is3Itching: preAnesData?.Is3Itching,
      Is4Itching: preAnesData?.Is4Itching,
      Is1DrugErrorHumanError: preAnesData?.Is1DrugErrorHumanError,
      Is2DrugErrorHumanError: preAnesData?.Is2DrugErrorHumanError,
      Is3DrugErrorHumanError: preAnesData?.Is3DrugErrorHumanError,
      Is4DrugErrorHumanError: preAnesData?.Is4DrugErrorHumanError,
      Is1PressureSore: preAnesData?.Is1PressureSore,
      Is2PressureSore: preAnesData?.Is2PressureSore,
      Is3PressureSore: preAnesData?.Is3PressureSore,
      Is4PressureSore: preAnesData?.Is4PressureSore,
      Is1ReOperation: preAnesData?.Is1ReOperation,
      Is2ReOperation: preAnesData?.Is2ReOperation,
      Is3ReOperation: preAnesData?.Is3ReOperation,
      Is4ReOperation: preAnesData?.Is4ReOperation,
      OtherSpecifyRemark: preAnesData?.OtherSpecifyRemark,
      Is1OtherSpecify: preAnesData?.Is1OtherSpecify,
      Is2OtherSpecify: preAnesData?.Is2OtherSpecify,
      Is3OtherSpecify: preAnesData?.Is3OtherSpecify,
      Is4OtherSpecify: preAnesData?.Is4OtherSpecify,
      WoundRemark: preAnesData?.WoundRemark,
      Is1Wound: preAnesData?.Is1Wound,
      Is2Wound: preAnesData?.Is2Wound,
      Is3Wound: preAnesData?.Is3Wound,
      Is4Wound: preAnesData?.Is4Wound,
      ComplicationNoneRemark: preAnesData?.ComplicationNoneRemark,
      IS1ComplicationNone: preAnesData?.IS1ComplicationNone,
      IS2ComplicationNone: preAnesData?.IS2ComplicationNone,
      IS3ComplicationNone: preAnesData?.IS3ComplicationNone,
      IS4ComplicationNone: preAnesData?.IS4ComplicationNone,
      IsDirectTransferred: preAnesData?.IsDirectTransferred,
      IsDirectTransferredToOtherRemark:
        preAnesData?.IsDirectTransferredToOtherRemark,
      PACUDurationTime: preAnesData?.PACUDurationTime,
      PACUDurationRemark: preAnesData?.PACUDurationRemark,
    };

    const dataOutcome = {
      Outcome: preAnesData?.Outcome,
      OutcomeRemark: preAnesData?.OutcomeRemark,
    };

    const dataPACU = {
      IsPostoperativePainManagementInPACUNone:
        preAnesData?.IsPostoperativePainManagementInPACUNone,
      IsIVIM: preAnesData?.IsIVIM,
      IsPatientControlledAnalgesia: preAnesData?.IsPatientControlledAnalgesia,
      IsLumbarEpidural: preAnesData?.IsLumbarEpidural,
      IsThoracicEpidural: preAnesData?.IsThoracicEpidural,
      IsPeripheralNerveBlock: preAnesData?.IsPeripheralNerveBlock,
      IsCaudal: preAnesData?.IsCaudal,
      SpinalOpioid: preAnesData?.SpinalOpioid,
      Oral: preAnesData?.Oral,
      IsPostoperativePainManagementInPACU:
        preAnesData?.IsPostoperativePainManagementInPACU,
      IsPostoperativePainManagementInPACURemark:
        preAnesData?.IsPostoperativePainManagementInPACURemark,
    };

    const dataServiceTime = {
      PostAnesthesiaCareOffServiceTime:
        preAnesData?.PostAnesthesiaCareOffServiceTime,
      PostAnesthesiaCareOffServiceTimeRemark:
        preAnesData?.PostAnesthesiaCareOffServiceTimeRemark,
    };

    const dataAssessment = {
      transferInformationData: preAnesData?.transferInformationData,
    };

    const data = {
      action: params?.action, // SAVE
      data:
        params.formCode === "CardAnesComplication"
          ? dataComplication
          : params.formCode === "CardAnesOutcome"
          ? dataOutcome
          : params.formCode === "CardAnesPACU"
          ? dataPACU
          : params.formCode === "CardAnesServiceTime"
          ? dataServiceTime
          : params.formCode === "CardAnesAssessment"
          ? dataAssessment
          : params.formCode === "CardAnestheticComplication"
          ? dataPostAnesthetic
          : "",
      encounter: state.selectedOrOrder?.encounter,
      form_code: params?.formCode,
      form_name: params?.formName,
      form_version: params?.formVersion,
    };

    if (params.tabForm) {
      if (preID !== "" && preID !== undefined) {
        [saveResp, saveErr, saveNet] = await FormDataDetail.update({
          apiToken: controller.apiToken,
          pk: preID,
          data: data,
        });
      } else {
        [saveResp, saveErr, saveNet] = await FormDataList.create({
          apiToken: controller.apiToken,
          data: data,
        });
      }
      if (saveErr) {
        controller.setState({
          errorMessage: { ...state.errorMessage, [params.cardKey]: saveErr },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params.cardKey]: `${params?.action} Success`,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
        Action(controller, { ...params, action: "FETCH_ANESCOMPLI" });
      }
    } else {
      if (state.AnesComplicationSequence?.id) {
        [saveResp, saveErr, saveNet] = await FormDataDetail.update({
          apiToken: controller.apiToken,
          pk: state.AnesComplicationSequence?.id,
          data: data,
        });
      } else {
        [saveResp, saveErr, saveNet] = await FormDataList.create({
          apiToken: controller.apiToken,
          data: data,
        });
      }
      if (saveErr) {
        controller.setState({
          errorMessage: { ...state.errorMessage, [params.cardKey]: saveErr },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params.cardKey]: `${params?.action} Success`,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
        Action(controller, { ...params, action: "FETCH_ANESCOMPLI" });
      }
    }
  } else if (params?.action === "PRINT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    if (state.AnesComplicationSequence?.id) {
      // Todo
      // const pngData = await ansTableRef.current.getImageData();
      const [printResp, printErr, printNet] = await ANSFormPrint.post({
        apiToken: controller.apiToken,
        data: {
          form_id: state.AnesComplicationSequence?.id,
          pdf: true,
          // image: pngData,  // Todo
        },
      });
      if (printErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: printErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
      }
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: "บันทึก Form ก่อนทำรายการ",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params?.action === "LOG") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    if (state.AnesComplicationSequence?.id) {
      const [logResp, logErr, logNet] = await FormDataActionLogList.list({
        apiToken: controller.apiToken,
        pk: state.AnesComplicationSequence?.id,
      });
      if (logErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: logErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
          AnesComplicationSequence: {
            ...state.AnesComplicationSequence,
            modLogInfo: { open: true, logList: logResp?.items || [] },
          },
        });
      }
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: "ไม่มี Form id",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params?.action === "GET_USER_TOKENIZE_LICENSE") {
    const [userResp, userErr, userNet] = await UserTokenizeLicenseView.post({
      apiToken: controller.apiToken,
      data: {
        code: state.AnesComplicationSequence?.data?.[`${params.paramKey}Code`],
      },
    });

    let seq: any = { ...state.AnesComplicationSequence };

    if (userErr) {
      seq.data[params.paramKey] = "";
      seq.data[`${params.paramKey}LicenseNumber`] = "";
      seq.data[`${params.paramKey}Code`] = "";

      controller.setState({
        errorMessage: { ...state.errorMessage, [params.cardKey]: userErr },
      });
    } else {
      const encodedName = userResp.token.split(".")[1];
      seq.data[params.paramKey] = decodeURIComponent(atob(encodedName));
      seq.data[`${params.paramKey}LicenseNumber`] = userResp.license;
    }
    controller.setState({ AnesComplicationSequence: seq });
  }
};
