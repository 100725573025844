import React, { useMemo, useCallback, useEffect } from "react";
import { Button, Popup } from "semantic-ui-react";

import { Column, RowInfo } from "react-table-6";
import moment from "moment";

// UX
import CardStockTransferOrderUX from "./CardStockTransferOrderUX";
import CardStockOrderWorking from "./CardStockOrderWorking";

// Common
import { DateTextBox } from "react-lib/apps/common";
import DropdownOptions from "react-lib/appcon/common/DropdownOptions";

// Const
const CARD_NAME = "CardStockTransferOrder";

// Types
type CardStockTransferOrderProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: any;
  StockTransferOrderSequence?: any;
  // CommonInterface
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  selectedDivision?: Record<string, any> | null;
  // options
  masterOptions?: any;
};

const CardStockTransferOrder = (props: CardStockTransferOrderProps) => {
  // Effect
  useEffect(() => {
    props.runSequence({
      sequence: "StockTransferOrder",
      card: CARD_NAME,
      restart: true,
    });
  }, []);

  useEffect(() => {
    if (
      props.StockTransferOrderSequence?.sequenceIndex === "Action" &&
      props.StockTransferOrderSequence?.apiMode
    ) {
      props.runSequence({
        sequence: "StockTransferOrder",
        action: "SEARCH",
        card: CARD_NAME,
      });
    }
  }, [
    props.StockTransferOrderSequence?.apiMode,
    props.StockTransferOrderSequence?.sequenceIndex,
  ]);

  // Use Memo
  const orderItems = useMemo(() => {
    return props.StockTransferOrderSequence?.drugTransferRequestlist?.map(
      (item: any) => ({
        ...item,
        date: item?.created ? (
          <div>{moment(item?.created).format("DD/MM/YYYY HH:mm")}</div>
        ) : null,
        request_division: item?.requester_name,
        request_user: item?.requested?.name,
        request_no: item?.code,
        status: item?.status_name,
        supply_division: item?.provider_name,
        transfer_no: item?.code,
        approve_status: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "-7px 5px",
              padding: "7px 5px",
              background:
                item.approve_status === "PENDING"
                  ? "rgba(247, 172, 8, 0.6)"
                  : "",
              color: item.approve_status === "REJECT" ? "red" : "",
            }}
          >
            <label>{item.approve_status}</label>
            <Popup
              trigger={
                <Button
                  circular={true}
                  icon="info"
                  color="blue"
                  style={{
                    fontSize: "0.7rem",
                    marginRight: "1rem",
                    padding: "0.25rem",
                  }}
                />
              }
            />
          </div>
        ),
      })
    );
  }, [props.StockTransferOrderSequence?.drugTransferRequestlist]);

  const statusTypeItems = useMemo(() => {
    return [
      { key: "", value: "", text: "All" },
      { key: "REQUESTED", value: "1", text: "REQUESTED" },
      { key: "APPROVED", value: "2", text: "APPROVED" },
      { key: "DELIVERED", value: "3", text: "DELIVERED" },
      { key: "RECEIVED", value: "4", text: "RECEIVED" },
      { key: "REJECTED", value: "5", text: "REJECTED" },
      { key: "CANCELED", value: "6", text: "CANCELED" },
    ];
  }, []);

  const divisionTypeDrugOptions = useMemo(() => {
    return [
      { key: "", value: "", text: "All" },
      ...(props.masterOptions?.divisionTypeDrug || []),
    ];
  }, [props.masterOptions?.divisionTypeDrug]);

  const apiModeOptions = useMemo(() => {
    return [
      { key: "Drugs", value: "Drugs", text: "Drugs" },
      { key: "Supply", value: "Supply", text: "Supply" },
    ];
  }, []);

  const handleSearchClick = () => {
    props.runSequence({
      sequence: "StockTransferOrder",
      action: "SEARCH",
      card: CARD_NAME,
    });
  };

  const handleClearSearch = () => {
    props.setProp("StockTransferOrderSequence.filterBox", {
      status: "",
      provider: "",
      requester: "",
      from_date: "",
      to_date: "",
    });
  };

  const handleGetTdProps = (
    state: any,
    rowInfo: RowInfo,
    column: Column,
    instance: any
  ) => {
    return {
      style: {},
    };
  };

  const handleGetTrProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => ({
    style: {
      backgroundColor:
        rowInfo?.original?.id &&
        rowInfo?.original?.id ===
          props.StockTransferOrderSequence?.selectedBox?.id
          ? "#cccccc"
          : "white",
    },
    onClick: () => {
      props.runSequence({
        sequence: "StockTransferOrder",
        action: "SET_SELECTED",
        selectedBox: rowInfo?.original,
        card: CARD_NAME,
      });
    },
  });

  console.log("CardStockTransferOrderUX", props);

  return (
    <div>
      <CardStockTransferOrderUX
        setProp={props.setProp}
        StockTransferOrderSequence={props.StockTransferOrderSequence}
        apiModeOptions={apiModeOptions}
        // data
        items={orderItems}
        // callback
        getTdProps={handleGetTdProps}
        getTrProps={handleGetTrProps}
        clearSearchClick={handleClearSearch}
        // Element
        StartDate={
          <DateTextBox
            style={{ width: "130px" }}
            inputStyle={{ width: "125px" }}
            value={props.StockTransferOrderSequence?.filterBox?.from_date || ""}
            onChange={(d: any) => {
              props.setProp(
                "StockTransferOrderSequence.filterBox.from_date",
                d
              );
            }}
          />
        }
        EndDate={
          <DateTextBox
            style={{ width: "130px" }}
            inputStyle={{ width: "130px" }}
            value={props.StockTransferOrderSequence?.filterBox?.to_date || ""}
            onChange={(d: any) => {
              props.setProp("StockTransferOrderSequence.filterBox.to_date", d);
            }}
          />
        }
        DropdownDivisionRequester={
          <DropdownOptions
            search={true}
            options={divisionTypeDrugOptions}
            value={props.StockTransferOrderSequence?.filterBox?.requester || ""}
            onChange={(e, v) => {
              props.setProp(
                "StockTransferOrderSequence.filterBox.requester",
                v.value
              );
            }}
          />
        }
        DropdownDivisionProvider={
          <DropdownOptions
            search={true}
            options={divisionTypeDrugOptions}
            value={props.StockTransferOrderSequence?.filterBox?.provider || ""}
            onChange={(e, v) => {
              props.setProp(
                "StockTransferOrderSequence.filterBox.provider",
                v.value
              );
            }}
          />
        }
        DropdownStatus={
          <DropdownOptions
            search={true}
            options={statusTypeItems}
            value={props.StockTransferOrderSequence?.filterBox?.status || ""}
            fluid={true}
            onChange={(e, v) => {
              props.setProp(
                "StockTransferOrderSequence.filterBox.status",
                v.value
              );
            }}
          />
        }
        searchClick={handleSearchClick}
      />

      <CardStockOrderWorking
        setProp={props.setProp}
        onEvent={props.onEvent}
        // seq
        runSequence={props.runSequence}
        StockTransferOrderSequence={props.StockTransferOrderSequence}  
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
        selectedDivision={props.selectedDivision}
      />
    </div>
  );
};

export default React.memo(CardStockTransferOrder);
