import WasmController from 'react-lib/frameworks/WasmController';

import * as CommonInterface from "react-lib/apps/HISV3/common/CommonInterface";
import CommonSequencePattern from "react-lib/apps/HISV3/common/sequence/SequencePattern"
import * as ADMInterface from "react-lib/apps/HISV3/ADM/ADMInterface";
import * as IMEInterface from "react-lib/apps/HISV3/IME/IMEInterface";
import IMESequencePattern from "react-lib/apps/HISV3/IME/sequence/SequencePattern";
import ADMSequencePattern from "react-lib/apps/HISV3/ADM/sequence/SequencePattern";
import * as APPInterface from "react-lib/apps/HISV3/APP/APPInterface";
import * as StaffChatInterface from "react-lib/apps/MSG/StaffChatInterface";
import APPSequencePattern from "react-lib/apps/HISV3/APP/sequence/SequencePattern";
import * as REGInterface from "react-lib/apps/HISV3/REG/REGInterface";
import REGSequencePattern from "react-lib/apps/HISV3/REG/sequence/SequencePattern";
import * as PTMInterface from "react-lib/apps/HISV3/PTM/PTMInterface";
import * as MedAdminInterface from "react-lib/apps/HISV3/PTM/MedAdminInterface";
import PTMSequencePattern from "react-lib/apps/HISV3/PTM/sequence/SequencePattern";
import * as VitalSignInterface from "react-lib/apps/HISV3/PTM/VitalSignInterface";
import * as IsHealthInterface from "HIS/IsHealth/IsHealthInterface";
import * as DPIInterface from "react-lib/apps/HISV3/DPI/DPIInterface";
import * as DPOInterface from "react-lib/apps/HISV3/DPO/DPOInterface";
import DPOSequencePattern from "react-lib/apps/HISV3/DPO/sequence/SequencePattern"
import * as CardPatientListI from "react-lib/apps/HISV3/common/CardPatientListInterface";
import * as TPDInterface from "react-lib/apps/HISV3/TPD/TPDInterface";
import TPDSequencePattern from "react-lib/apps/HISV3/TPD/sequence/SequencePattern";
import * as HCUInterface from "react-lib/apps/HISV3/HCU/HCUInterface";
import HCUSequencePattern from "react-lib/apps/HISV3/HCU/sequence/SequencePattern";
import * as FODInterface from "react-lib/apps/HISV3/FOD/FODInterface";
import FODSequencePattern from "react-lib/apps/HISV3/FOD/sequence/SequencePattern";
import * as BILInterface from "react-lib/apps/HISV3/BIL/BILInterface";
import DFCSequencePattern from "react-lib/apps/HISV3/DFC/sequence/SequencePattern";
import * as DFCInterface from "react-lib/apps/HISV3/DFC/DFCInterface";
import BILSequencePattern from "react-lib/apps/HISV3/BIL/sequence/SequencePattern"
import * as LabInterface from "react-lib/apps/HISV3/LAB/LabInterface";
import LabSequencePattern from "react-lib/apps/HISV3/LAB/sequence/SequencePattern"
import * as MSDInterface from "react-lib/apps/HISV3/MSD/MSDInterface";
import MSDSequencePattern from "react-lib/apps/HISV3/MSD/sequence/SequencePattern"
import * as ORMInterface from "react-lib/apps/HISV3/ORM/ORMInterface";
import * as ANEInterface from "react-lib/apps/HISV3/ANE/ANEInterface";
import ORMSequencePattern from "react-lib/apps/HISV3/ORM/sequence/SequencePattern";
import ANESequencePattern from "react-lib/apps/HISV3/ANE/sequence/SequencePattern";
import CLMSequencePattern from "react-lib/apps/HISV3/CLM/sequence/SequencePattern";
import PHRSequencePattern from "react-lib/apps/HISV3/PHR/sequence/SequencePattern";
import DPISequencePattern from "react-lib/apps/HISV3/DPI/sequence/SequencePattern";

import * as VaccineUploadI from "HIS/VaccineUpload/VaccineUploadInterface";
import * as ModPlanningI from "react-lib/apps/HISV3/common/ModPlanningInterface";
import * as ModSolventSelectionI from "react-lib/apps/HISV3/TPD/ModSolventSelectionInterface";
import * as SchedulingI from "react-lib/apps/Scheduling/SchedulingInterface";
import * as DispensingOrderSupplyI from "react-lib/apps/HISV3/MSD/sequence/DispensingOrderSupply";
import * as SupplyDeliveryI from "react-lib/apps/HISV3/MSD/sequence/SupplyDelivery";
import * as RefillDivisionPrintI from "react-lib/apps/HISV3/MSD/sequence/RefillDivisionPrint";
import * as PatientSearchCUI from "react-lib/apps/common/CU/PatientSearchCUInterface";
import * as QueueForPatientI from "react-lib/apps/IsHealth/Dashboard/QueueInterface";
import * as CLMInterface from "react-lib/apps/HISV3/CLM/CLMInterface";
import * as PHRInterface from "react-lib/apps/HISV3/PHR/PHRInterface";
import * as DentalRecordI from "react-lib/apps/common/CUDENT/DentalRecordInterface";

import DivisionDetail from 'issara-sdk/apis/DivisionDetail_core';
import DeviceList from "issara-sdk/apis/DeviceList_core"
import { ResetSequence } from "react-lib/apps/HISV3/MainHISResetSequence";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";


// Generated skeleton + manual handler implementation
import * as ADMI from "./Interface/ADMI";
import * as ADRI from "./Interface/ADRI";
import * as ANSI from "./Interface/ANSI";
import * as APPI from "./Interface/APPI";
import * as BILI from "./Interface/BILI";
import * as BLBI from "./Interface/BLBI";
import * as CoreI from "./Interface/CoreI";
import * as DFCI from "./Interface/DFCI";
import * as DPII from "./Interface/DPII";
import * as DPOI from "./Interface/DPOI";
import * as HRMI from "./Interface/HRMI";
import * as ICUI from "./Interface/ICUI";
import * as IMEI from "./Interface/IMEI";
import * as INFI from "./Interface/INFI";
import * as LABI from "./Interface/LABI";
import * as LRMI from "./Interface/LRMI";
import * as MixInI from "./Interface/MixInI";
import * as MSGI from "./Interface/MSGI";
import * as ORMI from "./Interface/ORMI";
import * as PRXI from "./Interface/PRXI";
import * as PTMI from "./Interface/PTMI";
import * as QUEI from "./Interface/QUEI";
import * as REGI from "./Interface/REGI";
import * as TECI from "./Interface/TECI";
import * as TPDI from "./Interface/TPDI";
import * as TRTI from "./Interface/TRTI";
import * as UserI from "./Interface/UserI";

import config from "config/config";
import axios from "axios";
import Cookies from 'js-cookie';

export type State = Partial<
  {
    debugInfo?: any,
    selectedRecordViewIndex?: number,
    QAChatTable?: any,
    goToCreateAppointment?: any,
    selectedTabIndex?: number,
  }
  & TPDInterface.State
  & CardPatientListI.State
  & VitalSignInterface.State
  & DPIInterface.State
  & DPOInterface.State
  & LabInterface.State
  & MSDInterface.State
  & ADMInterface.State
  & IMEInterface.State
  & FODInterface.State
  & PTMInterface.State
  & MedAdminInterface.State
  & DFCInterface.State
  & BILInterface.State
  & IsHealthInterface.State
  & CommonInterface.State
  & REGInterface.State
  & APPInterface.State
  & StaffChatInterface.State
  & VaccineUploadI.State
  & SchedulingI.State
  // & SchedulingIV3.State
  & ORMInterface.State
  & ANEInterface.State
  & ADMI.State
  & ADRI.State
  & ANSI.State
  & APPI.State
  & BILI.State
  & BLBI.State
  & CoreI.State
  & DFCI.State
  & DPII.State
  & DPOI.State
  & HRMI.State
  & ICUI.State
  & IMEI.State
  & INFI.State
  & LABI.State
  & LRMI.State
  & MixInI.State
  & MSGI.State
  & ORMI.State
  & PRXI.State
  & PTMI.State
  & QUEI.State
  & REGI.State
  & TECI.State
  & TPDI.State
  & TRTI.State
  & UserI.State
  & ModPlanningI.State
  & ModSolventSelectionI.State
  & DispensingOrderSupplyI.State
  & SupplyDeliveryI.State
  & RefillDivisionPrintI.State
  & QueueForPatientI.State
  & CLMInterface.State
  & PHRInterface.State
  & HCUInterface.State
  & DentalRecordI.State
>;

export const StateInitial: State =
{
  debugInfo: {},
  selectedRecordViewIndex: 0,
  goToCreateAppointment: false,
  ...TPDInterface.StateInitial,
  ...CardPatientListI.StateInitial,
  ...VitalSignInterface.StateInitial,
  ...DPIInterface.StateInitial,
  ...DPOInterface.StateInitial,
  ...LabInterface.StateInitial,
  ...MSDInterface.StateInitial,
  ...ADMInterface.StateInitial,
  ...IMEInterface.StateInitial,
  ...FODInterface.StateInitial,
  ...PTMInterface.StateInitial,
  ...MedAdminInterface.StateInitial,
  ...DFCInterface.StateInitial,
  ...BILInterface.StateInitial,
  ...IsHealthInterface.StateInitial,
  ...CommonInterface.StateInitial,
  ...REGInterface.StateInitial,
  ...APPInterface.StateInitial,
  ...StaffChatInterface.StateInitial,
  ...VaccineUploadI.StateInitial,
  ...SchedulingI.StateInitial,
  ...ORMInterface.StateInitial,
  ...ANEInterface.StateInitial,
  ...ADMI.StateInitial,
  ...ADRI.StateInitial,
  ...ANSI.StateInitial,
  ...APPI.StateInitial,
  ...BILI.StateInitial,
  ...BLBI.StateInitial,
  ...CoreI.StateInitial,
  ...DFCI.StateInitial,
  ...DPII.StateInitial,
  ...DPOI.StateInitial,
  ...HRMI.StateInitial,
  ...ICUI.StateInitial,
  ...IMEI.StateInitial,
  ...INFI.StateInitial,
  ...LABI.StateInitial,
  ...LRMI.StateInitial,
  ...MixInI.StateInitial,
  ...MSGI.StateInitial,
  ...ORMI.StateInitial,
  ...PRXI.StateInitial,
  ...PTMI.StateInitial,
  ...QUEI.StateInitial,
  ...REGI.StateInitial,
  ...TECI.StateInitial,
  ...TPDI.StateInitial,
  ...TRTI.StateInitial,
  ...UserI.StateInitial,
  ...ModPlanningI.StateInitial,
  ...QueueForPatientI.StateInitial,
  ...ModSolventSelectionI.StateInitial,
  ...CLMInterface.StateInitial,
  ...PHRInterface.StateInitial,
  ...HCUInterface.StateInitial,
  ...DentalRecordI.StateInitial
}

export type Event =
  | { message: "RunSequence"; params: any }
  | { message: "DidMount"; params: {} }
  | { message: "DidUpdate"; params: {} }
  | { message: "ChangeDivision"; params: { divisionId: any } }
  | { message: "ChangeDevice"; params: { deviceId: any } }
  | { message: "RefreshDivision"; params: { divisionId: number } }
  | { message: "GetDebugInfo"; params: {} }
  | { message: "GetEncounterInfo"; params: { encounterId: any } }
  | TPDInterface.Event
  | CardPatientListI.Event
  | VitalSignInterface.Event
  | DPIInterface.Event
  | DPOInterface.Event
  | LabInterface.Event
  | MSDInterface.Event
  | ADMInterface.Event
  | IMEInterface.Event
  | FODInterface.Event
  | PTMInterface.Event
  | MedAdminInterface.Event
  | DFCInterface.Event
  | BILInterface.Event
  | IsHealthInterface.Event
  | CommonInterface.Event
  | REGInterface.Event
  | APPInterface.Event
  | StaffChatInterface.Event
  | VaccineUploadI.Event
  | SchedulingI.Event
  | ORMInterface.Event
  | ANEInterface.Event
  | ADMI.Event
  | ADRI.Event
  | ANSI.Event
  | APPI.Event
  | BILI.Event
  | BLBI.Event
  | CoreI.Event
  | DFCI.Event
  | DPII.Event
  | DPOI.Event
  | HRMI.Event
  | ICUI.Event
  | IMEI.Event
  | INFI.Event
  | LABI.Event
  | LRMI.Event
  | MixInI.Event
  | MSGI.Event
  | ORMI.Event
  | PRXI.Event
  | PTMI.Event
  | QUEI.Event
  | REGI.Event
  | TECI.Event
  | TPDI.Event
  | TRTI.Event
  | UserI.Event
  | ModPlanningI.Event
  | ModSolventSelectionI.Event
  | DispensingOrderSupplyI.Event
  | PatientSearchCUI.Event
  | QueueForPatientI.Event
  | CLMInterface.Event
  | PHRInterface.Event
  | HCUInterface.Event
  | DentalRecordI.Event

export type Data =
  {
  }
  & TPDInterface.Data
  & CardPatientListI.Data
  & VitalSignInterface.Data
  & DPIInterface.Data
  & DPOInterface.Data
  & LabInterface.Data
  & MSDInterface.Data
  & ADMInterface.Data
  & IMEInterface.Data
  & FODInterface.Data
  & PTMInterface.Data
  & DFCInterface.Data
  & BILInterface.Data
  & IsHealthInterface.Data
  & CommonInterface.Data
  & REGInterface.Data
  & APPInterface.Data
  & StaffChatInterface.Data
  & VaccineUploadI.Data
  & SchedulingI.Data
  & ORMInterface.Data
  & ANEInterface.Data
  & ADMI.Data
  & ADRI.Data
  & ANSI.Data
  & APPI.Data
  & BILI.Data
  & BLBI.Data
  & CoreI.Data
  & DFCI.Data
  & DPII.Data
  & DPOI.Data
  & HRMI.Data
  & ICUI.Data
  & IMEI.Data
  & INFI.Data
  & LABI.Data
  & LRMI.Data
  & MixInI.Data
  & MSGI.Data
  & ORMI.Data
  & PRXI.Data
  & PTMI.Data
  & QUEI.Data
  & REGI.Data
  & TECI.Data
  & TPDI.Data
  & TRTI.Data
  & UserI.Data
  & ModPlanningI.Data
  & ModSolventSelectionI.Data
  & QueueForPatientI.Data
  & CLMInterface.Data
  & PHRInterface.Data
  & HCUInterface.Data
  & IMEInterface.Data

export const DataInitial =
{
  estimateResult: {},
  ...TPDInterface.DataInitial,
  ...CardPatientListI.DataInitial,
  ...VitalSignInterface.DataInitial,
  ...DPIInterface.DataInitial,
  ...DPOInterface.DataInitial,
  ...LabInterface.DataInitial,
  ...MSDInterface.DataInitial,
  ...ADMInterface.DataInitial,
  ...IMEInterface.DataInitial,
  ...FODInterface.DataInitial,
  ...PTMInterface.DataInitial,
  ...DFCInterface.DataInitial,
  ...BILInterface.DataInitial,
  ...IsHealthInterface.DataInitial,
  ...CommonInterface.DataInitial,
  ...REGInterface.DataInitial,
  ...APPInterface.DataInitial,
  ...StaffChatInterface.DataInitial,
  ...VaccineUploadI.DataInitial,
  ...SchedulingI.DataInitial,
  ...ORMInterface.DataInitial,
  ...ANEInterface.DataInitial,
  ...ADMI.DataInitial,
  ...ADRI.DataInitial,
  ...ANSI.DataInitial,
  ...APPI.DataInitial,
  ...BILI.DataInitial,
  ...BLBI.DataInitial,
  ...CoreI.DataInitial,
  ...DFCI.DataInitial,
  ...DPII.DataInitial,
  ...DPOI.DataInitial,
  ...HRMI.DataInitial,
  ...ICUI.DataInitial,
  ...IMEI.DataInitial,
  ...INFI.DataInitial,
  ...LABI.DataInitial,
  ...LRMI.DataInitial,
  ...MixInI.DataInitial,
  ...MSGI.DataInitial,
  ...ORMI.DataInitial,
  ...PRXI.DataInitial,
  ...PTMI.DataInitial,
  ...QUEI.DataInitial,
  ...REGI.DataInitial,
  ...TECI.DataInitial,
  ...TPDI.DataInitial,
  ...TRTI.DataInitial,
  ...UserI.DataInitial,
  ...ModPlanningI.DataInitial,
  ...ModSolventSelectionI.DataInitial,
  ...HCUInterface.DataInitial,
}

const SequencePattern: { [sequenceName: string]: any } = {
  ...CommonSequencePattern,
  ...REGSequencePattern,
  ...TPDSequencePattern,
  ...FODSequencePattern,
  ...BILSequencePattern,
  ...APPSequencePattern,
  ...PTMSequencePattern,
  ...DFCSequencePattern,
  ...DPOSequencePattern,
  ...DPISequencePattern,
  ...LabSequencePattern,
  ...MSDSequencePattern,
  ...ADMSequencePattern,
  ...ORMSequencePattern,
  ...ANESequencePattern,
  ...CLMSequencePattern,
  ...PHRSequencePattern,
  ...HCUSequencePattern,
  ...IMESequencePattern
};

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

export const RunSequence: Handler = async (controller, params) => {
  // This will get called repeatedly with sequenceIndex 
  // to determine which branch to execute
  if (!params?.sequence) return console.log("Sequence name not specified.")
  const sequenceState = `${params.sequence}Sequence`;
  const state: { [key: string]: any } = controller.getState();
  console.log("sequenceState", state?.[sequenceState])
  if (!state?.[sequenceState] || params?.restart) {
    // First run or restart
    if (params?.restart) delete params.restart
    controller.setState({
      [sequenceState]: {
        sequenceIndex: "START",
      }
    }, () => { RunSequence(controller, params); });
    return
  } else if (params?.clear) {
    // Clear state
    controller.setState({
      [sequenceState]: null
    });
    return
  }
  // Subsequent run
  if (!state?.[sequenceState]?.sequenceIndex)
    return console.log(`state.${sequenceState}?.sequenceIndex not set.`)
  console.log("Running: ", params.sequence, " at index:", state[sequenceState].sequenceIndex, SequencePattern);
  const SEQUENCE = SequencePattern[params.sequence][config.SEQUENCE?.[params.sequence] || "default"];
  console.log('SEQUENCE: ', SEQUENCE);
  SEQUENCE[state[sequenceState].sequenceIndex](controller, params);
}

// Move this to AppInitialize Sequence
export const DidMount: Handler = async (controller, params) => {
}

export const DidUpdate: Handler = async (controller, params) => {
}

export const ChangeDivision: Handler = async (controller, params) => {
  const state = controller.getState();
  const divisionType = controller.data.divisionDict?.[params?.divisionId || 0]?.type_label || null;
  if (!params?.divisionId) return;
  if (!controller.data.divisionDict?.[params.divisionId] && !params?.isRefresh) return
  try {
    const divisionId = parseInt(params.divisionId);
    Cookies.set("division_id", divisionId.toString());
    controller.data.division = divisionId;

    console.log(" !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!  Change Division !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!  ")
    const divisionDetail = DivisionDetail.retrieve({ pk: divisionId, apiToken: controller.apiToken })
    controller.data.divisionDetail = !divisionDetail[1] ? divisionDetail[0] : {}

    await controller.setState({
      django: {
        ...state.django,
        division: divisionId,
      },
      selectedDivision: controller.data.divisionDict[divisionId],
      divisionType: divisionType,
      // mode: "patient",
      resetLayout: state.mode === "patient" ? !state.resetLayout : state.resetLayout,
      ...ResetSequence,
    });

    RefreshDevice(controller, params)
  } catch (e: any) { console.log(e.toString()); }
}

export const RefreshDevice: Handler = async (controller, params) => {
  // Handle division-related data and state ========================================
  const deviceList = await DeviceList.list({
    params: { division: params.divisionId },
    apiToken: controller.apiToken
  });

  const deviceOptions = (deviceList[0] || []).items
    ?.map((item: any) => (
      {
        key: item.id,
        value: item.id,
        text: item.computer_name,
      }
    ));

  const deviceDict = Object.fromEntries(
    (deviceList[0]?.items || []).map((item: any) => ([item.id, item]))
  );

  // Set selectedDevice to in controller.data (from cookies) or first in the list
  const selectedDevice = deviceList[0]?.items[0] || null

  // Set data  and state ============================================================
  if (selectedDevice?.id) {
    controller.data = {
      ...controller.data,
      device: selectedDevice?.id || 0,
      deviceDict: deviceDict,
    };

    controller.setState({
      deviceOptions: deviceOptions,
      selectedDevice: selectedDevice,
      selectedOrOrder: null
    });
  } else {
    controller.data = {
      ...controller.data,
      device: 0,
      deviceDict: deviceDict,
    };

    controller.setState({
      deviceOptions: deviceOptions,
      selectedDevice: { id: "" },
      selectedOrOrder: null
    });
  }
}

export const ChangeDevice: Handler = async (controller, params) => {
  if (!params?.deviceId) return;
  if (!controller.data.deviceDict?.[params.deviceId]) return
  try {
    const deviceId = parseInt(params.deviceId);
    Cookies.set("device_id", deviceId.toString());
    controller.data.device = deviceId;
    controller.setState({
      selectedDevice: controller.data.deviceDict[deviceId],
    });
  } catch (e: any) { console.log(e.toString()); }
}

export const RefreshDivision: Handler = async (controller, params) => {
  if (typeof params.divisionId === "undefined" || params.divisionId === null || params.divisionId === 0) return;
  console.log(params.divisionId);
  Cookies.set("division_id", params.divisionId.toString());
  controller.data.division = params.divisionId;
  // Start as if app just initialize, refresh all data including division
  controller.handleEvent({ message: "RunSequence", params: { sequence: "AppInitialize" } })
}

export const GetDebugInfo: Handler = (controller, params) => {
  controller.setState({
    debugInfo: {}
  })
  axios
    .get("http://localhost:8080/api/analyze-encounter/", {
      params: {
        hn: "65000016"
      },
      headers: {
        "Authorization": `Token ${controller.apiToken}`
      }
    })
    .then((res) => {
      console.log(res);
      controller.setState({
        debugInfo: res.data
      });
    });
}

export const GetEncounterInfo: Handler = async (controller, params) => {

  const [r, e, n] = await EncounterDetail.retrieve({
    pk: params.encounterId,
    apiToken: controller.apiToken,
  });
  if (e) {
    console.log(e, n);
    return;
  }

  controller.handleEvent({
    message: "SelectEncounter" as any,
    params: { encounter: r },
  });

};