import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Button,
  Form,
  Icon,
  IconProps,
  Label,
  Popup,
  Modal,
  Segment,
  Header,
  Divider,
  Dimmer,
  Loader,
  Message,
  DropdownProps,
  Checkbox,
  Radio,
} from "semantic-ui-react";

import moment from "moment";

// Framework
import { Table } from "react-lib/frameworks/Table";

// UX
import CardOrderSummaryUX from "./CardOrderSummaryUX";
import ModNursePerformOrderUX from "../PTM/ModNursePerformOrderUX";

// Common
import { ModInfo as ModInfoSuccess, ErrorMessage } from "react-lib/apps/common";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import { EmployeeToken } from "react-lib/apps/common";

// Utils
import { formatADtoBEString } from "react-lib/utils/dateUtils";
import { beToAd } from "react-lib/utils";

type CardOrderSummaryProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // data
  orderSummaryPermission?: Record<string, boolean>;
  orderSummary?: { items?: any[] };
  orderSummaryOneDay?: { items?: any[] };
  continueHistoryList?: {
    items?: any[];
    summary: string;
    division: string;
    header: string;
  };
  nurseOrderPerformed?: {
    modOpen: boolean;
    selectedOneDayOrder: any[];
    selectedContinueOrder: any[];
    performedTime: string;
    performedDate: string;
    nurseName: any;
    nurseToken: any;
    nurseError: any;
    nurseLoading: any;
  };
  filterOrderSummary?: {
    date: moment.Moment;
    nurseStatus: any;
    isNurseStatus: boolean;
    isOutPerformDivision: boolean;
    isPharmacy: boolean;
    isTreatmentOrder: boolean;
    isLab: boolean;
    isFood: boolean;
    isNoteOrder: boolean;
    isProcedure: boolean;
    isHD: boolean;
  };
  divisionType?: string;
  medReconcileCheck?: Record<string, any> | null;
  medReconcileIndex?: number;
  isLoadingModal?: boolean;
  openModSuccessOrderSummary?: { message: string; open: boolean };
  // Commoninterface
  selectedEncounter?: Record<string, any>;
  django?: any;
  successMessage?: Record<string, any>;
  errorMessage?: Record<string, any>;
};

// Const
const DRUG_TYPE = {
  ONE_DAY: " ONE DAY",
  ONE_DOSE: "ONE DOSE",
  STAT: "STAT",
  HOME_IPD: "HOME IPD",
};

const DATE_FORMAT = "YYYY-MM-DD";
const CARD_ORDER_SUMMARY = "CardOrderSummary";

const CardOrderSummary = (props: CardOrderSummaryProps) => {
  const [openModHistory, setOpenModHistory] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [oneDayCancelReason, setOneDayCancelReason] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<any>(null);

  useEffect(() => {
    props.onEvent({ message: "GetUserPermissionOrderSummary", params: {} });
    props.onEvent({ message: "GetIPDOrderSummary", params: {} });
  }, []);

  const handleClickHistory = useCallback((planItemId: number) => {
    setOpenModHistory(true);

    props.onEvent({
      message: "GetIPDContinueHistory",
      params: { id: planItemId },
    });
  }, []);

  const handleClickAction = useCallback(
    async (data: any) => {
      console.log("handleClickAction: ", data);

      if (props.orderSummaryPermission?.role_DOCTOR) {
        if (data.action === "EDIT" || data.action === "EDIT_REQUEST") {
          if (data.item.type === "centrallaborder") {
            await props.setProp("centralLabOrderEditId", data.item.id);

            props.setProp("goToMenu", "Lab Order");
          } else if (data.item.type === "doctornoteorder") {
            await props.setProp("doctorNoteOrderEditId", data.item.id);

            props.setProp("goToMenu", "คำสั่งอื่น ๆ");
          } else {
            props.setProp("goToMenu", "Continue Med");

            props.onEvent({
              message: "SetDrugContinuePlan",
              params: data.item,
            });
          }
        } else {
          if (data.action === "CANCEL") {
            return setSelectedOrder(data.item);
          }
          // console.log("handleClickAction: ", data)
          if (data?.item?.plan_item?.id) {
            props.onEvent({
              message: "HandleUpdatePlanItem",
              params: {
                id: data.item.plan_item.id,
                action: data.action,
                allowed_actions: data.allowedActions,
                end_time: data.endTime,
                end_date: data.endDate,
              },
            });
          }
        }
      }
    },
    [props.orderSummaryPermission?.role_DOCTOR]
  );

  const orderSummaryItems = useMemo(() => {
    return (props.orderSummary?.items || []).map((item: any) => {
      return {
        ...item,
        _check:
          item.nurse_order === "รอรับคำสั่ง" ? (
            <Checkbox
              checked={props.nurseOrderPerformed?.selectedContinueOrder.includes(
                item.id
              )}
              onChange={() => {
                props.onEvent({
                  message: "HandleNurseOrderPerformed",
                  params: {
                    action: "update_order",
                    orderType: "continue",
                    orderId: item.id,
                  },
                });
              }}
            />
          ) : (
            ""
          ),
        type_name: <ContinueOrderCustom item={item} />,
        summary: (
          <ContinueMedicalOrderCustom
            item={item}
            currentDate={formatADtoBEString(
              props.filterOrderSummary?.date?.format(DATE_FORMAT)
            )}
            onClickHistory={handleClickHistory}
          />
        ),
        nurse_status: <ContinueTakeOrderCustom item={item} />,
        menu: (
          <ActionMenuColumn
            item={item}
            isDoctor={props.orderSummaryPermission?.role_DOCTOR}
            allowedActions={item.plan_item?.allowed_actions}
            date={props.filterOrderSummary?.date}
            onClickAction={handleClickAction}
          />
        ),
      };
    });
  }, [
    props.orderSummary?.items,
    props.orderSummaryPermission?.role_DOCTOR,
    props.nurseOrderPerformed?.selectedContinueOrder,
    props.filterOrderSummary?.date,
  ]);

  const orderSummaryOneDayItems = useMemo(() => {
    return (props.orderSummaryOneDay?.items || []).map((item: any) => ({
      ...item,
      _check:
        item.nurse_order === "รอรับคำสั่ง" ? (
          <Checkbox
            checked={props.nurseOrderPerformed?.selectedOneDayOrder.includes(
              item.id
            )}
            onChange={() => {
              props.onEvent({
                message: "HandleNurseOrderPerformed",
                params: {
                  action: "update_order",
                  orderType: "oneday",
                  orderId: item.id,
                },
              });
            }}
          />
        ) : (
          ""
        ),
      type_name: <ContinueOrderCustom item={item} />,
      summary: <OneDayMedicalOrderCustom item={item} />,
      nurse_status: <ContinueTakeOrderCustom item={item} />,
      menu: (
        <ActionMenuColumn
          item={item}
          isDoctor={props.orderSummaryPermission?.role_DOCTOR}
          allowedActions={item?.allowed_action}
          onClickAction={handleClickAction}
        />
      ),
    }));
  }, [
    props.orderSummaryOneDay?.items,
    props.nurseOrderPerformed?.selectedOneDayOrder,
  ]);

  const continueHistoryItems = useMemo(() => {
    return (props.continueHistoryList?.items || []).map((item: any) => ({
      ...item,
      summary: (
        <div
          dangerouslySetInnerHTML={{
            __html: props.continueHistoryList?.summary || "",
          }}
        />
      ),
      division: props.continueHistoryList?.division,
    })) as any[];
  }, [props.continueHistoryList?.items]);

  const handleChangeDate = (date: string) => {
    const momentDate = beToAd(date);
    handleChangeFilter(null, { name: "date", value: momentDate });
  };

  const handleChangeStepDate = (e: any, v: IconProps) => {
    let date = props.filterOrderSummary?.date as moment.Moment;

    if (v.name === "angle double left") {
      date = date.clone().add(-1, "days");
    } else if (v.name === "angle double right") {
      date = date.clone().add(1, "days");
    }

    handleChangeFilter(null, { name: "date", value: date });
  };

  const handleCloseModHistory = () => {
    setOpenModHistory(false);

    props.onEvent({
      message: "HandleSetContinueHistoryList",
      params: { data: null },
    });
  };

  const onCloseModSuccess = () => {
    props.onEvent({
      message: "HandleSetOpenModSuccessOrderSummary",
      params: { open: false },
    });
  };

  const handleChangeFilter = (e: any, v: any) => {
    props.onEvent({
      message: "HandleSetFilterOrderSummary",
      params: {
        key: v.name,
        value: typeof v.checked === "boolean" ? v.checked : v.value,
      },
    });
  };

  const handleOneDayCancelReasonChange = (e: any, v: DropdownProps) => {
    setOneDayCancelReason(v.value as string);
  };

  const handleApproveOneDayCancel = () => {
    if (!oneDayCancelReason) {
      setErrorMessage("ยกเลิกไม่สำเร็จ กรุณาระบุเหตุผลในการยกเลิก");
      return;
    }

    setErrorMessage(null);

    props.onEvent({
      message: "UpdateDoctorOrderCancel",
      params: { id: selectedOrder?.id, note: selectedOrder?.id },
    });

    setOneDayCancelReason("");
    setSelectedOrder(null);
  };

  const handleApproveMedReconcile = () => {
    props.setProp("medReconcileCheck.open_card_med_reconciliation", false);

    props.onEvent({
      message: "OpenMedReconcileFromWarning",
      params: {
        medReconcileCheck: props.medReconcileCheck,
        selecteRecordViewIndex: props.medReconcileIndex,
      },
    });

    // find med reconcile Index
    // if (props.medReconcileIndex !== -1) {
    //   props.setProp("selectedRecordViewIndex", props.medReconcileIndex)
    // }
  };

  const handleConfirmPerform = () => {
    props.onEvent({
      message: "HandleNurseOrderPerformed",
      params: {
        action: "confirm_performed",
        orderItems: [...(props.orderSummaryOneDay?.items || [])].concat([
          ...(props.orderSummary?.items || []),
        ]),
        card: CARD_ORDER_SUMMARY,
      },
    });
  };

  console.log("CardOrderSummart props: ", props);

  return (
    <>
      <CardOrderSummaryUX
        setProp={props.setProp}
        // data
        orderSummaryItems={orderSummaryItems}
        orderSummaryOneDayItems={orderSummaryOneDayItems}
        filterOrderSummary={props.filterOrderSummary}
        date={formatADtoBEString(
          props.filterOrderSummary?.date?.format(DATE_FORMAT)
        )}
        isDoctor={props.orderSummaryPermission?.role_DOCTOR}
        // callback
        onChangeDate={handleChangeDate}
        onChangeStepDate={handleChangeStepDate}
        onChangeFilter={handleChangeFilter}
        // component
        orderPerformAction={
          !props.orderSummaryPermission?.role_DOCTOR && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "10px",
              }}
            >
              <Checkbox
                style={{ paddingRight: "20px" }}
                label="เลือกทั้งหมด"
                onChange={(e: any, { checked }) => {
                  props.onEvent({
                    message: "HandleNurseOrderPerformed",
                    params: {
                      action: "select_all",
                      isChecked: checked,
                      continueOrder: props.orderSummary?.items || [],
                      oneDayOrder: props.orderSummaryOneDay?.items || [],
                    },
                  });
                }}
              />
              <Button
                content="รับคำสั่ง"
                color="green"
                disabled={
                  !(
                    (props.nurseOrderPerformed?.selectedContinueOrder?.length ||
                      0) > 0 ||
                    (props.nurseOrderPerformed?.selectedOneDayOrder?.length ||
                      0) > 0
                  )
                }
                onClick={() => {
                  // let callFrom = "WARD";
                  let roleTypes = props.django?.user?.role_types || [];
                  if (
                    ["หอผู้ป่วย"].includes(props.divisionType || "") &&
                    (roleTypes || []).includes("REGISTERED_NURSE") &&
                    props.selectedEncounter?.type === "IPD"
                  ) {
                    let callFrom = "WARD";
                    if (props.selectedEncounter?.id) {
                      props.onEvent({
                        message: "GetMedReconcileCheck",
                        params: {
                          encounter: props.selectedEncounter,
                          callForm: callFrom,
                        },
                      });
                    }
                  } else {
                    console.warn(
                      "ไม่ได้มาจาก หอผู้ป่วย เปิด CardOrderSummary: "
                    );
                  }

                  props.onEvent({
                    message: "HandleNurseOrderPerformed",
                    params: { action: "order_performed" },
                  });
                }}
              />
            </div>
          )
        }
      />

      <ModMedReconcileAlert
        // medReconcileCheck={props.medReconcileCheck}
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        onApprove={handleApproveMedReconcile}
      >
        {props.medReconcileCheck?.med_reconciliation != null
          ? `Med Reconcile ${props.medReconcileCheck?.med_reconciliation_label} ยังไม่ได้ถูกรับทราบโดยพยาบาล`
          : `ยังไม่ได้สร้าง Medication Reconciliation สำหรับ PlanTransfer ${props.medReconcileCheck?.plan_transfer_id}`}
      </ModMedReconcileAlert>

      <Modal
        open={props.nurseOrderPerformed?.modOpen}
        onClose={() => {
          props.setProp("nurseOrderPerformed.modOpen", false);
        }}
      >
        <ModNursePerformOrderUX
          selectedDate={props.nurseOrderPerformed?.performedDate}
          onChangeDate={(date: string) => {
            props.setProp("nurseOrderPerformed.performedDate", date);
          }}
          selectedTime={props.nurseOrderPerformed?.performedTime}
          onTimeChange={(time: string) => {
            props.setProp("nurseOrderPerformed.performedTime", time);
          }}
          onSave={handleConfirmPerform}
          // component
          employeeCode={
            <EmployeeToken
              placeholder="ระบุ code employee"
              onEnterToken={(code) => {
                props.onEvent({
                  message: "HandleNurseOrderPerformed",
                  params: { action: "tokenize", code: code },
                });
              }}
              onClearToken={() => {
                props.setProp("nurseOrderPerformed", {
                  ...props.nurseOrderPerformed,
                  nurseToken: "",
                  nurseName: "",
                  nurseLoading: false,
                  nurseError: null,
                });
              }}
              error={props.nurseOrderPerformed?.nurseError}
              loading={props.nurseOrderPerformed?.nurseLoading}
              employeeName={props.nurseOrderPerformed?.nurseName}
            />
          }
        />
      </Modal>

      <Modal open={openModHistory} size="large" onClose={handleCloseModHistory}>
        {/* @ts-ignore */}
        <Segment>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "-1.2rem",
            }}
          >
            {/* @ts-ignore */}
            <Header>ประวัติการทำงาน</Header>
            <Icon
              name="close"
              size="large"
              color="red"
              style={{ cursor: "pointer" }}
              onClick={handleCloseModHistory}
            />
          </div>
          <Divider />
          <Dimmer active={props.isLoadingModal} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          {/* @ts-ignore */}
          <Header style={{ margin: "1.5rem 0 5px" }}>
            {props.continueHistoryList?.header || ""}
          </Header>
          <Table
            data={continueHistoryItems}
            headers="User, Action, รายการคำสั่ง, Division, Date-time"
            keys="user, action, summary, division, datetime"
            showPagination={false}
            style={{
              height: "500px",
            }}
          />
        </Segment>
      </Modal>

      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_ORDER_SUMMARY}`, null);
        }}
        error={props.errorMessage?.[CARD_ORDER_SUMMARY]?.error}
        success={props.successMessage?.[CARD_ORDER_SUMMARY]}
      />

      <ModInfoSuccess
        titleColor="green"
        titleName={props.openModSuccessOrderSummary?.message || "บันทึกสำเร็จ"}
        btnText="ตกลง"
        open={props.openModSuccessOrderSummary?.open}
        onApprove={onCloseModSuccess}
      />

      <ModConfirm
        openModal={!!selectedOrder}
        titleName="ยืนยันการยกเลิก"
        content={
          <>
            {
              // @ts-ignore
              <Form>
                <Message>
                  {
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedOrder?.summary,
                      }}
                    />
                  }
                </Message>
                <ErrorMessage error={errorMessage} />
                <Form.Dropdown
                  onChange={handleOneDayCancelReasonChange}
                  label="ระบุเหตุผลในการยกเลิก"
                  options={[
                    { key: 1, text: "สั่งผิด", value: "สั่งผิด" },
                    {
                      key: 2,
                      text: "ต้องการเปลี่ยนรายการ",
                      value: "ต้องการเปลี่ยนรายการ",
                    },
                  ]}
                  selection
                />
              </Form>
            }
          </>
        }
        onApprove={handleApproveOneDayCancel}
        onDeny={() => setSelectedOrder(null)}
      />
    </>
  );
};

/* ------------------------------------------------------ */

/*                  ContinueOrderCustom;                  */

/* ------------------------------------------------------ */

const ContinueOrderCustom = (props: any) => {
  return (
    <>
      {
        // @ts-ignore
        <Form>
          <Form.Field inline style={{ margin: "0em 0em 0.3em" }}>
            <Label color="brown">{props.item.type_name}</Label>
          </Form.Field>
          <Form.Field inline>
            <div dangerouslySetInnerHTML={{ __html: props.item.order_time }} />
          </Form.Field>
        </Form>
      }
    </>
  );
};

/* ------------------------------------------------------ */

/*                OneDayMedicalOrderCustom;               */

/* ------------------------------------------------------ */

const OneDayMedicalOrderCustom = (props: any) => {
  const summary = useMemo(() => {
    const hideDuration = [
      DRUG_TYPE.ONE_DAY,
      DRUG_TYPE.ONE_DOSE,
      DRUG_TYPE.STAT,
    ].find((type) => props.item?.type_name?.includes(type));

    return hideDuration
      ? props.item?.summary?.replace(/<b>Duration<\/b> \d+ days/g, "")
      : props.item?.summary;
  }, [props.item?.summary, props.item?.type_name]);

  return (
    <>
      {
        <div style={{ display: "grid" }}>
          <div
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
              display: "flex",
            }}
          >
            {props.item.status_label && (
              <Label className="teal">{props.item.status_label}</Label>
            )}
            {props.item.doctor_pending_task && (
              <Label
                className={
                  props.item.doctor_pending_task_approve ? "green" : "yellow"
                }
              >
                {props.item.doctor_pending_task}
              </Label>
            )}
            {props.item.is_advance && (
              <Label className="pick">{props.item.is_advance_label}</Label>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: summary,
              }}
              style={{ whiteSpace: "pre-line" }}
            />
            {props.item.is_operation_division && (
              <Label
                circular
                color="yellow"
                style={{
                  lineHeight: "1.2rem",
                  width: "2.5rem",
                  height: "2rem",
                  margin: "5px 0",
                }}
              >
                OR
              </Label>
            )}
          </div>
          <div
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
              display: "flex",
            }}
          >
            {props.item.is_out_perform_div && (
              <Label className="yellow">{props.item.out_perform_div}</Label>
            )}
          </div>
        </div>
      }
    </>
  );
};

/* ------------------------------------------------------ */

/*               ContinueMedicalOrderCustom;              */

/* ------------------------------------------------------ */

const ContinueMedicalOrderCustom = (props: any) => {
  const offSoon = useMemo(() => {
    const endTime = props.item.plan_item?.end_time
      ? moment(props.item.plan_item?.end_time, "HH:mm:ss").format("HH:mm")
      : null;

    if (
      (endTime === null ||
        props.item.item_status.replace("(OFF) ", "") === endTime) &&
      props.item.last_dose_datetime ===
        `${props.item.plan_item?.end_date} ${endTime || ""}`
    ) {
      return true;
    } else {
      return false;
    }
  }, [props.item]);

  const status = useMemo(() => {
    let status: string = props.item.item_status;
    const plan_item = props.item.plan_item;

    // VERIFIED [Plan Off 24/04/2566 18:00 น.]
    if (status.includes("(OFF)") || status.search(/VERIFIED.*Plan Off/g) >= 0) {
      const statusOff = getStatusOff({
        status,
        endDate: plan_item.end_date,
        endTime: plan_item.end_time,
        startDate: plan_item.start_date,
        currentDate: props.currentDate,
      });

      status = `${statusOff.status}${statusOff.time}`;
    }

    return status;
  }, [props.item, props.currentDate]);

  return (
    <>
      {
        <div style={{ display: "grid" }}>
          <div
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
              display: "flex",
            }}
          >
            {props.item.doctor_pending_task && (
              <Label
                className={
                  props.item.doctor_pending_task_approve ? "green" : "yellow"
                }
              >
                {props.item.doctor_pending_task}
              </Label>
            )}
            {props.item.status_label && (
              <Label className="teal">{props.item.status_label}</Label>
            )}
            {props.item.is_external && (
              <Label color="yellow" style={{ minWidth: "max-content" }}>
                {" "}
                own med{" "}
              </Label>
            )}
            {props.item.item_status && (
              <Label
                className={props.item.item_status === "icon" ? "circular" : ""}
                color={
                  status.includes("Plan Off")
                    ? "grey"
                    : status.includes("Off")
                    ? "black"
                    : status.includes("(HOLD)")
                    ? "brown"
                    : "blue"
                }
                style={
                  status.includes("Plan Off")
                    ? {
                        filter: "opacity(0.7)",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                      }
                    : {}
                }
              >
                {props.item.item_status === "icon" ? "" : status}
                {props.item.item_status === "icon" && (
                  <Icon
                    name="calendar alternate"
                    style={{ margin: "1px 1px 0 0" }}
                  />
                )}
              </Label>
            )}
            {["drugcontinueplan", "doctornoteorder"].includes(
              props.item.type
            ) && (
              <Button
                icon="history"
                size="mini"
                onClick={() => props.onClickHistory?.(props.item.plan_item.id)}
              ></Button>
            )}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: status.includes("Plan Off")
                ? props.item?.summary?.replace(/<\/*s>/g, "")
                : props.item?.summary,
            }}
            style={{ whiteSpace: "pre-line" }}
          />
          {!offSoon && props.item?.last_dose_datetime && (
            <div style={{ fontWeight: "bold" }}>
              Last dose: {props.item?.last_dose_datetime} น.
            </div>
          )}
          <div
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
              display: "flex",
            }}
          >
            {props.item.is_out_perform_div && (
              <Label className="yellow">{props.item.out_perform_div}</Label>
            )}
          </div>
        </div>
      }
    </>
  );
};

/* ------------------------------------------------------ */

/*                ContinueTakeOrderCustom;                */

/* ------------------------------------------------------ */

const ContinueTakeOrderCustom = (props: any) => {
  return (
    <>
      {
        <div style={{ position: "relative", height: "100%" }}>
          <div
            style={{
              position: "absolute",
              top: "-7px",
              width: "calc(100% + 10px)",
              left: "-4px",
              height: "calc(100% + 14px)",
              backgroundColor: props.item.is_stat_order
                ? "red"
                : props.item.nurse_status === "รับทราบแล้ว"
                ? "rgba(0,128,0,0.2)"
                : props.item.nurse_status === "รอรับคำสั่ง"
                ? "rgba(255,255,0,0.2)"
                : props.item.nurse_status === "ยกเลิก"
                ? "rgba(128,128,128,0.2)"
                : "",
            }}
            dangerouslySetInnerHTML={{ __html: props.item.nurse_order }}
          />
        </div>
      }
    </>
  );
};

/* ------------------------------------------------------ */

/*                    ActionMenuColumn                   */

/* ------------------------------------------------------ */

const HOLD_OFF = {
  OFF_NOW: "OFF ทันที",
  HOLD_NOW: "HOLD ทันที",
};

export const ActionMenuColumn = (props: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedHoldOff, setSelectedHoldOff] = useState<"HOLD" | "OFF" | "">(
    ""
  );
  const [checkedTime, setCheckedTime] = useState<string>("");

  const isDisabledButton = useMemo(() => {
    const allowedActions: string[] = props.allowedActions || [];

    return ["EDIT", "EDIT_REQUEST", "HOLD", "RESUME", "OFF", "RENEW"].some(
      (action) => allowedActions.includes(action)
    );
  }, [props.allowedActions]);

  const timeLine = useMemo(() => {
    const current = moment().format(DATE_FORMAT);
    const date = props.date?.format(DATE_FORMAT);

    switch (true) {
      case current === date:
        return "present";
      case current < date:
        return "future";
      case current > date:
        return "past";
      default:
        break;
    }
  }, [props.date]);

  const stdTimes = useMemo(() => {
    let times: string[] = (props.item.std_times || []).map(
      (time: string) => `${time} น.`
    );

    if (timeLine === "present" && selectedHoldOff) {
      const hm = `${moment().format("HH:mm")} น.`;

      times = [
        HOLD_OFF[`${selectedHoldOff}_NOW`],
        ...times.filter((time) => time >= hm),
      ];
    }

    return times;
  }, [props.item.std_times, timeLine, selectedHoldOff]);

  const buttonColors = useMemo(() => {
    return {
      EDIT: "yellow",
      HOLD: "brown",
      RESUME: "blue",
      OFF: "black",
      RENEW: "teal",
      CANCEL: "red",
    } as any;
  }, []);

  const handleClick = (value: string) => (e: any) => {
    if (["HOLD", "OFF"].includes(value) && !!props.item?.std_times?.length) {
      setCheckedTime("");
      setSelectedHoldOff(selectedHoldOff ? "" : (value as "HOLD" | "OFF"));
      return;
    }

    handleSave(value);
  };

  const handleSave = (action: string, endTime?: string, endDate?: string) => {
    handleClose();
    props.onClickAction?.({
      action: action,
      allowed_actions: props.allowedActions,
      item: props.item,
      endTime,
      endDate,
    });
  };

  const handleSelectLastDose = () => {
    const hm = checkedTime.replace(/ น\.$/g, "");

    if ([HOLD_OFF.HOLD_NOW, HOLD_OFF.OFF_NOW].includes(hm)) {
      const time = moment().format("HH:mm");

      handleSave(selectedHoldOff, time);
    } else {
      const date = formatADtoBEString(props.date?.format(DATE_FORMAT));

      handleSave(selectedHoldOff, hm, timeLine === "future" ? date : undefined);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setCheckedTime("");
    setSelectedHoldOff("");
  };

  return (
    <>
      {
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Popup
            open={isOpen}
            style={{ padding: "6px 8px" }}
            content={
              <>
                {props.allowedActions?.map((value: string, index: number) => (
                  <>
                    {Object.keys(buttonColors).includes(value) && (
                      <Button
                        key={"button" + index}
                        style={{ width: "7em" }}
                        disabled={!props.isDoctor}
                        color={buttonColors[value]}
                        onClick={handleClick(value)}
                      >
                        {value}
                      </Button>
                    )}
                  </>
                ))}

                {selectedHoldOff && (
                  <div style={{ margin: "0.5rem 0.25rem", fontWeight: "bold" }}>
                    <div>[{selectedHoldOff}] กรุณาเลือก Last dose</div>
                    {stdTimes.map((time: any) => (
                      <div>
                        <Radio
                          label={time}
                          style={{ margin: "0.5rem 0" }}
                          checked={checkedTime === time}
                          onChange={() => setCheckedTime(time)}
                        />
                      </div>
                    ))}

                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        size="mini"
                        color="green"
                        onClick={handleSelectLastDose}
                        disabled={!checkedTime}
                      >
                        OK
                      </Button>
                    </div>
                  </div>
                )}
              </>
            }
            position="bottom right"
            onOpen={() => setIsOpen(true)}
            onClose={handleClose}
            on="click"
            trigger={
              <Button
                icon="list layout"
                size="mini"
                disabled={!isDisabledButton || timeLine === "past"}
                onDoubleClick={(e: any) => e.stopPropagation()}
              ></Button>
            }
          />
        </div>
      }
    </>
  );
};

// Utils
export const getStatusOff = (params: {
  status: string;
  endDate: string;
  endTime: string | null;
  startDate: string | null;
  currentDate?: string;
}) => {
  const endTime = params.endTime;

  let status = params.status;
  let time: string = "";
  let lastDoseDatetime = params.endDate || "";

  // 18:00:00
  lastDoseDatetime = endTime
    ? `${lastDoseDatetime} ${endTime.match(/\d+:\d+/g)?.[0]}`
    : lastDoseDatetime;

  if (endTime) {
    const reg = /(\d{2})\/(\d{2})\/(\d{4})/g;
    // dd/mm/yyyy (BE) to yyyy-mm-dd (AD)
    const formatDate = (date: string) => {
      return date.replace(
        reg,
        (...res: any[]) => `${Number(res[3]) - 543}-${res[2]}-${res[1]}`
      );
    };
    // (OFF) 15:59
    const currentDate = formatDate(params.currentDate || "");
    const startDate = formatDate(params.startDate || "");
    const endTime = status.match(/\d+:\d+/g)?.[0];

    const currentDatetime = params.currentDate
      ? formatDate(
          `${params.currentDate} ${
            currentDate > startDate ? "00:00" : moment().format("HH:mm")
          }`
        )
      : moment().format("YYYY-MM-DD HH:mm");

    const endDatetime = formatDate(lastDoseDatetime);

    if (currentDatetime < endDatetime) {
      status = "Plan Off";
      time = ` ${params.endDate} ${endTime} น.`;
    } else {
      status = "Off";
      time = ` ${lastDoseDatetime} น.`;
    }
  } else {
    status = status.replace(/\(OFF\)/g, "Off");
  }

  return { status, time };
};

export default React.memo(CardOrderSummary);
