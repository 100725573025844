import JsBarcode from "jsbarcode";
import moment from "moment";
import { formatDate } from "react-lib/utils/dateUtils";

const textToBase64BarcodeBlob = async (text: string) => {
  var img = new Image();
  const canvas = document.createElement("canvas");

  JsBarcode(canvas, text, { displayValue: false });

  await new Promise((resolve) => {
    img.onload = function (evt) {
      resolve("!!!done");
    };
    img.src = canvas.toDataURL("image/png");
  });

  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  const currentAngle = 90;

  // swap the height and width
  canvas.height = canvas.width + ((canvas.width = canvas.height), 0);

  context.save();
  // rotate the canvas center
  context.translate(canvas.width / 2, canvas.height / 2);
  context.rotate((currentAngle * Math.PI) / 180);
  context.drawImage(img, -img.width / 2, -img.height / 2);
  context.restore();

  return canvas.toDataURL("image/png");
};

const Form = (props: any) => {
  const barcodeHN = ""; //await textToBase64BarcodeBlob(props.hn);

  return {
    pageSize: {
      width: 302.4,
      height: 302.4,
    },
    pageOrientation: "landscape",
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 10,
    },
    pageMargins: [10, 40, 10, 15],
    styles: {
      facultyHeader: {
        fontSize: 11,
        bold: true,
      },
      fieldKey: {
        bold: true,
        fontSize: 17,
      },
      fieldValue: {
        fontSize: 17,
      },
      miniFieldKey: {
        bold: true,
        fontSize: 15,
      },
      miniFieldValue: {
        fontSize: 15,
      },
    },
    header: (currentPage: number, pageCount: number) => {
      return {
        margin: [2.5, 2, 10, 0],
        columns: [
          {
            image: "logochula",
            width: 67.5,
            marginTop: 8.5
          },
          {
            width: "100%",
            margin: [0, 2.5, 0, 0],
            stack: [
              {
                text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
                style: "facultyHeader"
              },
              {
                margin: [0, -3, 0, 0],
                text: "Faculty of Dentistry, Chulalongkorn University"
              },
              {
                margin: [0, -3, 0, 0],
                text:
                  "34 ถนนอังรีดูนังต์ แขวงวังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330 โทร 02-218-8705"
              }
            ]
          }
        ]
      };
    },
    content: [
      {
        stack: [
          {
            margin: [5, -2.5, 0, 0],
            text: props.patientName || " ",
            style: "fieldKey",
            fontSize: 18,
            preserveLeadingSpaces: true,
          },
          {
            columns: [
              {
                width: "91.5%",
                stack: [
                  {
                    margin: [5, 5, 0, 0],
                    columns: [
                      {
                        width: "37.5%",
                        text: [
                          props.birthdate ? "DOB : " : " ",
                          props.birthdate || "",
                        ],
                        style: "miniFieldKey",
                        preserveLeadingSpaces: true,
                      },
                      {
                        width: "37.5%",
                        text: [props.birthdate ? "HN : " : " ", props.hn || ""],
                        style: "miniFieldKey",
                        preserveLeadingSpaces: true,
                      },
                      {
                        width: "*",
                        text: formatDate(moment()),
                        style: "miniFieldKey",
                        alignment: "right",
                      },
                    ],
                  },
                  {
                    margin: [0, -2.5, 0, 0],
                    table: {
                      headerRows: 1,
                      widths: ["100%"],
                      heights: 57.5,
                      body: [
                        [
                          {
                            margin: [-2, -6, 0, 0],
                            border: [false, false, false, false],
                            stack: [
                              {
                                text: props.drugName || "",
                                style: {
                                  lineHeight: 0.5,
                                  fontSize: 22,
                                  bold: true,
                                },
                              },
                              {
                                stack: [
                                  {
                                    lineHeight: 0.7,
                                    text: props.pronunciationTrade || "",
                                    style: "miniFieldKey",
                                    bold: false,
                                  },
                                  {
                                    lineHeight: 0.8,
                                    text: props.properties || "",
                                    style: "miniFieldKey",
                                    bold: false,
                                  },
                                ],
                                absolutePosition: { x: 2.5, y: 32 },
                              },
                            ],
                            fillColor: "#e8e8e8",
                          },
                        ],
                      ],
                    },
                  },
                  {
                    margin: [0, 0, 0, 0],
                    table: {
                      // headerRows: 1,
                      widths: ["100%"],
                      heights: 125,
                      body: [
                        [
                          {
                            borderColor: [
                              "#696969",
                              "#696969",
                              "#696969",
                              "#696969",
                            ],
                            margin: [-0.5, -0.5, 0, 0],
                            stack: [
                              {
                                lineHeight: 0.7,
                                text: props.method || "",
                                style: "fieldKey",
                              },
                              {
                                lineHeight: 0.7,
                                text: props.indication || "",
                                style: "fieldValue",
                              },
                              {
                                lineHeight: 0.7,
                                text: props.helperLabels || "",
                                style: "fieldValue",
                              },
                              {
                                lineHeight: 0.7,
                                text: props.remark || "",
                                style: "fieldValue",
                              },
                            ],
                          },
                        ],
                      ],
                    },
                  },
                ],
              },
              {
                // margin: [0, -15, 0, 0],
                // image: barcodeHN,
                // width: 23.5,
                // height: 230,
                text: "",
              },
              {
                table: {
                  headerRows: 1,
                  heights: 15,
                  widths: [50],
                  body: [
                    [
                      {
                        border: [false, false, false, false],
                        text: [
                          {
                            text: `${props.qty || ""} ${props.unitName || ""}`,
                          },
                        ],
                        fillColor: "#e8e8e8",
                        fontSize: 11.5,
                        bold: true,
                        alignment: "center",
                      },
                    ],
                  ],
                },
                absolutePosition: { x: 208, y: 255 },
                layout: {
                  paddingBottom: function (i: number, node: any) {
                    return 2.5;
                  },
                  paddingTop: function (i: number, node: any) {
                    return 3;
                  },
                },
              },
            ],
          },
        ],
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        stack: [{}],
      };
    },
    images: {
      logochula: origin + "/static/images/logochula_dent.jpg"
    }
  };
};

export default Form;
