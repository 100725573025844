import WasmController from 'react-lib/frameworks/WasmController';
import * as SetDoctorFeeI from "./sequence/SetDoctorFee";

export type State = 
  {
  }
  & SetDoctorFeeI.State

export const StateInitial: State = 
  {
    ...SetDoctorFeeI.StateInitial
  }

export type Event = 
  { message: "RunSequence", params: {} }
  | SetDoctorFeeI.Event

export type Data = 
  {
    division?: number,
    device?: number,
  }
  & SetDoctorFeeI.Data

export const DataInitial = 
  {
    ...SetDoctorFeeI.DataInitial
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any
