import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Dropdown,
  Input
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardSupplyOrderSearchUX = (props: any) => {
    return(
      <div
        style={{width:"100%", height:"100%"}}>
        <div
          style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(32, 1fr)","gridTemplateColumns":"repeat(41, 1fr)"}}>
          <div
            style={{ gridRow: "4/6", gridColumn: "2/6", border: "none 1px rgb(200, 200, 200, 0.5)" ,  display: "flex", alignItems: "center" }}>
            ชื่อ หรือ รหัส
          </div>
          <div
            style={{ gridRow: "7/9", gridColumn: "2/4", border: "none 1px rgb(200, 200, 200, 0.5)", display: "flex", alignItems: "center"}}>
            หมวด
          </div>
          <div
            style={{ gridRow: "7/9", gridColumn: "4/11", border: "none 1px rgb(200, 200, 200, 0.5)" }}>
            <Dropdown
              id="cb-supplyMode"
              onChange={props.onChangeSearchMode}
              options={props.modeOptions}
              selection={true}
              style={{width: "100%", height:"100%"}}
              value={props.searchMode}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "7/9", gridColumn: "12/14", border: "none 1px rgb(200, 200, 200, 0.5)",  display: "flex", alignItems: "center" }}>
            ผู้ผลิต
          </div>
          <div
            style={{ gridRow: "7/9", gridColumn: "14/21", border: "none 1px rgb(200, 200, 200, 0.5)" }}>
            <Dropdown
              icon="search"
              loading={props.manufactureLoading}
              minCharacters={2}
              onChange={props.onChangeSearchManufacturer}
              onClose={props.onCloseSearchManufacturer}
              onSearchChange={props.onSearchChangeQueryManufacturer}
              open={props.openManufacturer}
              openOnFocus={false}
              options={props.manufacturerOptions}
              search={true}
              searchQuery={props.searchQueryManufacturer}
              selection={true}
              style={{width: "100%", height:"100%"}}
              value={props.searchManufacturer}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "7/9", gridColumn: "22/24", border: "none 1px rgb(200, 200, 200, 0.5)",  display: "flex", alignItems: "center" }}>
            กองทุน
          </div>
          <div
            style={{ gridRow: "7/9", gridColumn: "24/31", border: "none 1px rgb(200, 200, 200, 0.5)" }}>
            <Dropdown
              disabled={true}
              onChange={props.onChangeSearchGroup}
              options={props.groupOptions}
              selection={true}
              style={{width: "100%", height:"100%"}}
              value={props.searchGroup}>
            </Dropdown>
          </div>
          <div
            style={{ gridRow: "4/6", gridColumn: "7/31", border: "none 1px rgb(200, 200, 200, 0.5)" }}>
            <Input
              id="txt-searchSupplyitemList"
              onChange={props.onChangeSearchName}
              onKeyDown={(e: any) => { e.key === "Enter" && props.onSearch(); }}
              style={{width: "100%", height:"100%"}}
              value={props.searchName}>
            </Input>
          </div>
          <div
            style={{ gridRow: "4/6", gridColumn: "32/36", border: "none 1px rgb(200, 200, 200, 0.5)" }}>
            <Button
              color="blue"
              id="btn-searchSupplyitem"
              loading={props.nameLoading}
              onClick={props.onSearch}
              style={{width: "100%", height:"100%"}}>
              ค้นหา
            </Button>
          </div>
          <div
            style={{ gridRow: "4/6", gridColumn: "37/41", border: "none 1px rgb(200, 200, 200, 0.5)" }}>
            <Button
              color="blue"
              disabled={true}
              onClick={props.onClickListView}
              style={{width: "100%", height:"100%", minWidth: "max-content"}}>
              แสดงภาพ
            </Button>
          </div>
          <div
            style={{ gridRow: "7/9", gridColumn: "32/41", border: "none 1px rgb(200, 200, 200, 0.5)" }}>
            <Button
              color={props.isFavorite ?  "yellow" : "grey"}
              onClick={props.onSelectFavorite}
              style={{width: "100%", height:"100%"}}>
              เฉพาะรายการโปรด
            </Button>
          </div>
          <div
            style={{ gridRow: "1/4", gridColumn: "2/41", border: "none 1px rgb(200, 200, 200, 0.5)", display: "flex", alignItems: "center", fontWeight:"bold", fontSize: "large"}}>
            ค้นหาเวชภัณฑ์
          </div>
          <div
            style={{ gridRow: "30/32", gridColumn: "37/41", border: "none 1px rgb(200, 200, 200, 0.5)" }}>
            <Button
              color="red"
              id="btn-cancelSupply"
              onClick={props.onCancel}
              style={{width: "100%", height:"100%"}}>
              ยกเลิก
            </Button>
          </div>
          <div
            style={{ gridRow: "30/32", gridColumn: "32/36", border: "none 1px rgb(200, 200, 200, 0.5)" }}>
            <Button
              color="green"
              id="btn-selectSupply"
              onClick={props.onSelect}
              style={{width: "100%", height:"100%"}}>
              เลือก
            </Button>
          </div>
          <div
            style={{ gridRow: "10/30", gridColumn: "2/41", border: "none 1px rgb(200, 200, 200, 0.5)" }}>
            <Table
              columns={props.columns}
              data={props.data}
              getTdProps={props.getTdprops}
              getTheadThProps={props.getThreadThprops}
              getTrProps={props.getTrprops}
              headers=" ,No,Code,Mode,Product Name,จำนวน,ขนาดบรรจุ,ราคา,ใช้บ่อย"
              id="tb-supplyListItem"
              keys="selected,no,code,mode,name,count,package_size,price_unit,favorite"
              minRows="5"
              showPagination={false}
              style={{height: "420px", marginBottom: "30px" }}
              widths=" 40,80,100,140,auto,80,100,100,60">
            </Table>
          </div>
        </div>
      </div>
    )
}

export default CardSupplyOrderSearchUX

export const screenPropsDefault = {}

/* Date Time : Wed Apr 19 2023 04:36:31 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", height:\"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(32, 1fr)\",\"gridTemplateColumns\":\"repeat(41, 1fr)\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ หรือ รหัส"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/6\", gridColumn: \"2/6\", border: \"none 1px rgb(200, 200, 200, 0.5)\" ,  display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "หมวด"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"2/4\", border: \"none 1px rgb(200, 200, 200, 0.5)\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"4/11\", border: \"none 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ผลิต"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"12/14\", border: \"none 1px rgb(200, 200, 200, 0.5)\",  display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"14/21\", border: \"none 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "กองทุน"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"22/24\", border: \"none 1px rgb(200, 200, 200, 0.5)\",  display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"24/31\", border: \"none 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/6\", gridColumn: \"7/31\", border: \"none 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/6\", gridColumn: \"32/36\", border: \"none 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/6\", gridColumn: \"37/41\", border: \"none 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"32/41\", border: \"none 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาเวชภัณฑ์"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/4\", gridColumn: \"2/41\", border: \"none 1px rgb(200, 200, 200, 0.5)\", display: \"flex\", alignItems: \"center\", fontWeight:\"bold\", fontSize: \"large\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"30/32\", gridColumn: \"37/41\", border: \"none 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"30/32\", gridColumn: \"32/36\", border: \"none 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/30\", gridColumn: \"2/41\", border: \"none 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Button",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "btn-searchSupplyitem"
        },
        "loading": {
          "type": "code",
          "value": "props.nameLoading"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height:\"100%\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Button",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "แสดงภาพ"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickListView"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height:\"100%\", minWidth: \"max-content\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Button",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "เฉพาะรายการโปรด"
        },
        "color": {
          "type": "code",
          "value": "props.isFavorite ?  \"yellow\" : \"grey\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectFavorite"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height:\"100%\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "id": {
          "type": "value",
          "value": "btn-cancelSupply"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height:\"100%\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Button",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือก"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "id": {
          "type": "value",
          "value": "btn-selectSupply"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelect"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height:\"100%\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Dropdown",
      "parent": 4,
      "props": {
        "id": {
          "type": "value",
          "value": "cb-supplyMode"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSearchMode"
        },
        "options": {
          "type": "code",
          "value": "props.modeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.searchMode"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Dropdown",
      "parent": 8,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSearchGroup"
        },
        "options": {
          "type": "code",
          "value": "props.groupOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.searchGroup"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Input",
      "parent": 9,
      "props": {
        "id": {
          "type": "value",
          "value": "txt-searchSupplyitemList"
        },
        "loading": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSearchName"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any) => { e.key === \"Enter\" && props.onSearch(); }"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.searchName"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 33,
      "name": "Table",
      "parent": 17,
      "props": {
        "columns": {
          "type": "code",
          "value": "props.columns"
        },
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdprops"
        },
        "getTheadThProps": {
          "type": "code",
          "value": "props.getThreadThprops"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrprops"
        },
        "headers": {
          "type": "value",
          "value": " ,No,Code,Mode,Product Name,จำนวน,ขนาดบรรจุ,ราคา,ใช้บ่อย"
        },
        "id": {
          "type": "value",
          "value": "tb-supplyListItem"
        },
        "keys": {
          "type": "value",
          "value": "selected,no,code,mode,name,count,package_size,price_unit,favorite"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"420px\", marginBottom: \"30px\" }"
        },
        "widths": {
          "type": "value",
          "value": " 40,80,100,140,auto,80,100,100,60"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Dropdown",
      "parent": 6,
      "props": {
        "disabled": {
          "type": "value",
          "value": ""
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "loading": {
          "type": "code",
          "value": "props.manufactureLoading"
        },
        "minCharacters": {
          "type": "code",
          "value": "2"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSearchManufacturer"
        },
        "onClose": {
          "type": "code",
          "value": "props.onCloseSearchManufacturer"
        },
        "onSearchChange": {
          "type": "code",
          "value": "props.onSearchChangeQueryManufacturer"
        },
        "open": {
          "type": "code",
          "value": "props.openManufacturer"
        },
        "openOnFocus": {
          "type": "code",
          "value": "false"
        },
        "options": {
          "type": "code",
          "value": "props.manufacturerOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "searchQuery": {
          "type": "code",
          "value": "props.searchQueryManufacturer"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.searchManufacturer"
        }
      },
      "seq": 35,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSupplyOrderSearchUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
