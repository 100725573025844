import moment from "moment";

// Utils
import { formatDate, formatDatetime } from "react-lib/utils/dateUtils";

const FormPatientMedicationDispense = (props: any) => {
  return {
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 14,
    },
    pageOrientation: "landscape",
    pageMargins: [15, 170, 15, 35],
    pageSize: "A4",
    styles: {
      fieldHeader: {
        fontSize: 22,
        bold: true,
        alignment: "center",
      },
      tableHeader: {
        bold: true,
        fontSize: 18,
      },
      fieldValue: { fontSize: 18 },
      miniFieldKey: {
        fontSize: 12.5,
        bold: true,
      },
      miniFieldValue: {
        fontSize: 12.5,
      },
    },
    header: {
      margin: [20, 40, 20, 0],
      stack: [
        {
          absolutePosition: { x: 17.5, y: 15 },
          image: "logochula",
          width: 140,
        },
        {
          alignment: "center",
          stack: [
            {
              text: "รายงานการจ่ายยาผู้ป่วย",
              style: "fieldHeader",
            },
            {
              text: [
                { text: props.titleName, bold: false },
                { text: "   ของ ", bold: false },
                { text: props.storageName },
              ],
              style: "fieldHeader",
            },
            {
              text: [
                { text: "Lot: " },
                {
                  text: Array.from(
                    new Set(
                      props.items.flatMap((item: any) =>
                        item.lot_no ? [item.lot_no] : []
                      )
                    )
                  ).join(", "),
                  bold: false,
                },
              ],
              style: "fieldHeader",
            },
            {
              marginTop: 7.5,
              text: `วันที่ ${props.startDate || ""} - ${props.endDate || ""}`,
              style: "fieldHeader",
              bold: false,
            },
          ],
        },
        {
          absolutePosition: { x: 632.5, y: 7 },
          stack: [
            {
              marginRight: 12.5,
              text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
              style: "miniFieldValue",
              alignment: "right",
            },
            {
              margin: [0, -4, 12.5, 0],
              text: [
                { text: "ที่อยู่", style: "miniFieldKey" },
                { text: " : ", style: "miniFieldValue" },
                { text: "34 ถนนอังรีดูนังต์ แขวงวังใหม่ เขตประทุมวัน" },
              ],
              alignment: "right",
            },
            {
              margin: [0, -4, 12.5, 0],
              text: "กรุงเทพมหานคร 10330 โทร 0 2218 8635",
              style: "miniFieldValue",
              alignment: "right",
            },
          ],
        },
      ],
    },
    content: [
      {
        // style: "table",
        table: {
          headerRows: 1,
          widths: ["14%", "10%", "18%", "14%", "14%", "11%", "10%", "9%"],
          body: [
            [
              {
                text: "วันที่/เวลา",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "HN",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "ข้อมูลผู้ป่วย",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "Diagnosis",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "ผู้ดำเนินการ",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "เลขที่อ้างอิง",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "Lot. No.",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "จำนวนจ่าย",
                style: "tableHeader",
                alignment: "center",
              },
            ],
            ...props.items.map((item: any, index: number) => [
              {
                text: formatDatetime(item.datetime),
                style: "fieldValue",
                alignment: "center",
              },
              {
                text: item.related_issue.patient.hn,
                style: "fieldValue",
                alignment: "center",
              },
              {
                text: `${item.related_issue.patient.full_name}\n(${
                  item.related_issue.patient.age
                } ปี) ${
                  item.related_issue.patient.phone
                    ? // 0971261744 to 097-126-1744
                      item.related_issue.patient.tel_no.replace(
                        /(\d{3})(\d{3})(\d{4})/g,
                        "$1-$2-$3"
                      )
                    : ""
                }`,
                style: "fieldValue",
                alignment: "center",
              },
              {
                text: item.related_issue.diagnosis,
                style: "fieldValue",
                alignment: "center",
              },
              {
                text: item.edit_user.full_name,
                style: "fieldValue",
                alignment: "center",
              },
              {
                text: item.related_issue.order_code,
                style: "fieldValue",
                alignment: "center",
              },
              {
                text: item.lot_no,
                style: "fieldValue",
                alignment: "center",
              },
              {
                text: Math.abs(item.quantity),
                style: "fieldValue",
                alignment: "center",
              },
            ]),
          ],
        },
        layout: {
          vLineWidth: function (i: number, node: any) {
            return 0.25;
          },
          hLineWidth: function (i: number, node: any) {
            return 0.25;
          },
          paddingBottom: function (i: number, node: any) {
            return i === 0 ? 1 : 8;
          },
          paddingTop: function (i: number, node: any) {
            return i === 0 ? 1 : 8;
          },
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        margin: [12.5, 0, 15, 0],
        columns: [
          {
            stack: [
              {
                text: [
                  { text: "วันเวลาที่พิมพ์ : ", style: "miniFieldKey" },
                  {
                    text: `${formatDate(moment())} [${moment().format(
                      "HH:mm"
                    )}]`,
                    style: "miniFieldValue",
                  },
                ],
              },
              {
                text: `ผู้ออกรายงาน : ${props.userName}`,
                style: "miniFieldValue",
                marginTop: -2,
              },
            ],
          },
          {
            margin: [0, 5, 5, 0],
            text: `หน้าที่ ${currentPage}/${pageCount}`,
            alignment: "right",
          },
        ],
      };
    },
    images: {
      logochula: origin + "/static/images/logochula_dent.jpg",
    },
  };
};

export default FormPatientMedicationDispense;
