import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { Checkbox, Input, Dropdown, Button } from "semantic-ui-react";
import { Table } from "react-lib/frameworks/Table";

const CardORCancelListUX = (props: any) => {
  return (
    <div>
      <div style={{ padding: "30px 20px 20px" }}>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <Checkbox
            checked={props.ORCancelListSequence?.orFilter?.checkedHn}
            label="HN"
            name="checkedHn"
            onChange={props.onChangeFilter}
            style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}
          ></Checkbox>
          <div style={{ marginRight: "10px" }}>{props.patientSearchBox}</div>
          <label style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}>
            ชื่อ-สกุลผู้ป่วย
          </label>
          <Input
            disabled={true}
            style={{ marginRight: "10px", width: "230px" }}
            value={props.ORCancelListSequence?.orFilter?.patientFullName || ""}
          ></Input>
          <Checkbox
            checked={props.ORCancelListSequence?.orFilter?.checkedDoctor}
            label="แพทย์ผ่าตัด"
            name="checkedDoctor"
            onChange={props.onChangeFilter}
            style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}
          ></Checkbox>
          <div style={{ marginRight: "10px" }}>{props.doctorSearchBox}</div>
          <label style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}>
            Location
          </label>
          <Dropdown
            clearable={true}
            name="operating_detail__operating_room__location_id"
            onChange={props.onChangeLocation}
            options={props.locationOptions || []}
            search={true}
            selection={true}
            style={{ width: "200px" }}
            value={
              props.ORCancelListSequence?.orFilter
                ?.operating_detail__operating_room__location_id || ""
            }
          ></Dropdown>
        </div>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <label style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}>
            วันที่ผ่าตัด
          </label>
          <div style={{ marginRight: "10px" }}>{props.startDateTextBox}</div>
          <label style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}>
            ถึง
          </label>
          <div style={{ marginRight: "10px" }}>{props.endDateTextBox}</div>
          <label style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}>
            วันที่จองผ่าตัด
          </label>
          <div style={{ marginRight: "10px" }}>{props.createdDateTextBox}</div>
          <label style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}>
            Room
          </label>
          <Dropdown
            clearable={true}
            name="operating_detail__operating_room__id"
            onChange={props.onChangeFilter}
            options={props.roomOptions || []}
            search={true}
            selection={true}
            style={{ marginRight: "10px", width: "200px" }}
            value={
              props.ORCancelListSequence?.orFilter
                ?.operating_detail__operating_room__id || ""
            }
          ></Dropdown>
          <div style={{ marginRight: "10px" }}>{props.buttonSearch}</div>
          <Button
            color="yellow"
            onClick={props.onClear}
            size="small"
            style={{ marginRight: "10px" }}
          >
            ล้างการค้นหา
          </Button>
          <div>{props.buttonPrint}</div>
        </div>
      </div>
      <div style={{ padding: "0px 20px" }}>
        <Table
          data={props.ORCancelListSequence?.cancelOrder?.items || []}
          headers="วันที่ผ่าตัด, HN, ข้อมูลผู้ป่วย, Location/Room, แพทย์ผ่าตัด, Diagnosis, Type, Operation, Special Equipment, Blood, Anesthesia"
          keys="start_date, hn, patient_name, locationRoom, primary_doctor_name, diagnosis, typeLabel, pre_operation_summary, special_equipments_text, blood_request_summary, anesthesia_method_name"
          minRows={10}
          widths="100, 80, 170, 110, 170, 150, 150, 220, 140, 80, 100"
        ></Table>
      </div>
    </div>
  );
};

export default CardORCancelListUX;

export const screenPropsDefault = {};

/* Date Time : Wed Mar 01 2023 17:11:56 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"30px 20px 20px\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginBottom: \"10px\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Checkbox",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORCancelListSequence?.orFilter?.checkedHn"
        },
        "label": {
          "type": "value",
          "value": "HN"
        },
        "name": {
          "type": "value",
          "value": "checkedHn"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุลผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Input",
      "parent": 12,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", width: \"230px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ORCancelListSequence?.orFilter?.patientFullName || \"\""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 20px\" }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Checkbox",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORCancelListSequence?.orFilter?.checkedDoctor"
        },
        "label": {
          "type": "value",
          "value": "แพทย์ผ่าตัด"
        },
        "name": {
          "type": "value",
          "value": "checkedDoctor"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "Location"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Dropdown",
      "parent": 12,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "operating_detail__operating_room__location_id"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeLocation"
        },
        "options": {
          "type": "code",
          "value": "props.locationOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"200px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ORCancelListSequence?.orFilter?.operating_detail__operating_room__location_id || \"\""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginBottom: \"10px\" }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 33,
      "name": "Table",
      "parent": 22,
      "props": {
        "data": {
          "type": "code",
          "value": "props.ORCancelListSequence?.cancelOrder?.items || []"
        },
        "headers": {
          "type": "value",
          "value": "วันที่ผ่าตัด, HN, ข้อมูลผู้ป่วย, Location/Room, แพทย์ผ่าตัด, Diagnosis, Type, Operation, Special Equipment, Blood, Anesthesia"
        },
        "keys": {
          "type": "value",
          "value": "start_date, hn, patient_name, locationRoom, primary_doctor_name, diagnosis, typeLabel, pre_operation_summary, special_equipments_text, blood_request_summary, anesthesia_method_name"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "widths": {
          "type": "value",
          "value": "100, 80, 170, 110, 170, 150, 150, 220, 140, 80, 100"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่จองผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "Room"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Dropdown",
      "parent": 29,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "operating_detail__operating_room__id"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "props.roomOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", width: \"200px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ORCancelListSequence?.orFilter?.operating_detail__operating_room__id || \"\""
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Button",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างการค้นหา"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startDateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endDateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.createdDateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 42,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardORCancelListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
