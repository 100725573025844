import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  MutableRefObject,
  KeyboardEvent,
} from "react";
import {
  Button,
  Input,
  Form,
  Icon,
  Modal,
  Divider,
  Checkbox,
} from "semantic-ui-react";
import CardDoctorFeeOrderAndCompensationUX from "./CardDoctorFeeOrderAndCompensationUX";
import CardServiceCompensationItemUX from "./CardServiceCompensationItemUX";

// Common
import {
  ErrorMessage,
  ModInfo as ModError,
  ModConfirm,
  CardLayout,
  PureReactTable,
} from "react-lib/apps/common";
import PickerColumn from "react-lib/appcon/common/PickerColumn";

// Utils
import { formatDatetime, formatDate } from "react-lib/utils/dateUtils";
import moment from "moment";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

const ColumnStyle = {
  background: "rgb(255, 255, 204)",
  marginTop: -7,
  marginLeft: -4,
  width: "calc(100% + 10px)",
  height: "calc(100% + 14px)",
  padding: "7px 7px",
};

const ColumnStyleWhite = {
  marginTop: -7,
  marginLeft: -4,
  width: "calc(100% + 10px)",
  height: "calc(100% + 14px)",
  padding: "7px 7px",
};

// 2566-02-28T15:48:40
const BE_FORMAT_REG = /^(\d{4})-(\d{2})-(\d{2})/g;

// "2566-02-28T15:48:40" To "2023-02-28T15:48:40";
const formatADDatetime = (datetime: string) => {
  return datetime.replace(BE_FORMAT_REG, (...res) => {
    return `${Number(res[1]) - 543}-${res[2]}-${res[3]}`;
  });
};

const CardDoctorFeeOrderAndCompensation = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [open, setOpen] = useState(false);
  const [filterTimeOut, setFilterTimeOut] = useState(null);
  const [doctorFeeOrder, setDoctorFeeOrder] = useState(true);
  const [serviceCompensation, setServiceCompensation] = useState(false);
  const [startDate, setStartDate] = useState(formatDate(moment()));
  const [endDate, setEndDate] = useState(formatDate(moment()));
  const [checkDate, setCheckDate] = useState(true);
  const [openSearch, setOpenSearch] = useState(false);
  const [selectItem, setSelectItem] = React.useState({});
  const [selectItemData, setSelectItemData] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [selectedRow, setSelectedRow] = React.useState({});

  useEffect(() => {
    props.runSequence({ sequence: "SetDoctorFee", restart: true });
    return () => {
      props.runSequence({ sequence: "SetDoctorFee", clear: true });
    };
  }, []);

  useEffect(() => {
    if (
      props.errorMessage?.SetDoctorFee !== null &&
      props.errorMessage?.SetDoctorFee !== undefined
    ) {
      setLoading(false);
      setTimeout(() => {
        setOpen(false);
        props.runSequence({
          sequence: "SetDoctorFee",
          action: "clear",
        });
      }, 2000);
    }
    if (
      props.successMessage?.SetDoctorFee !== null &&
      props.successMessage?.SetDoctorFee !== undefined
    ) {
      setLoading(false);
      setCompleted(true);
      setTimeout(() => {
        setCompleted(false);
      }, 2000);
      props.runSequence({
        sequence: "SetDoctorFee",
        action: "clear",
      });
    }
  }, [props.successMessage?.SetDoctorFee, props.errorMessage?.SetDoctorFee]);

  useEffect(() => {
    setIsLoading(false);
  }, [props?.SetDoctorFeeSequence?.searchProductResult]);

  const handleChangeEdit = (data: any) => {
    props.runSequence({
      sequence: "SetDoctorFee",
      action: "update",
      data,
      onFailed: (error: any) => setErrorMessage(error),
    });

    // unCheck in searchProductResult
    if (data.type === "delete") {
      let stateItem: any = { ...selectItem };
      let stateItemData: any = [];
      let selectPreviewItems =
        props?.SetDoctorFeeSequence?.dfPreviewItems[data.index];
        
      delete stateItem[selectPreviewItems?.doctor_fee_rule];
      let select_data = [selectItemData];
      select_data[0]?.map((item: any, index: any) => {
        if (item?.doctor_fee_rule !== selectPreviewItems?.doctor_fee_rule) {
          stateItemData.push(item);
        }
      });

      setSelectItemData(stateItemData);
      setSelectItem(stateItem);
    }
  };

  const dfPreviewItems = useMemo(() => {
    return (props?.SetDoctorFeeSequence?.dfPreviewItems || []).map(
      (item: any, index: number) => ({
        ...item,
        no: <div style={{ textAlign: "center" }}>{index + 1}</div>,
        price_compensation_max: (
          <div style={{ textAlign: "right" }}>
            {item.price_compensation_max}
          </div>
        ),
        price_unit: <div style={{ textAlign: "right" }}>{item.price_unit}</div>,
        compensation_price: (
          <EditorColumn
            value={item.compensation_price}
            onDataChanged={(value: string) =>
              handleChangeEdit({ value, index, type: "compensation_price" })
            }
          />
        ),
        received_employee_display: (
          <EditorColumn
            value={item.received_employee_display}
            onDataChanged={(value: string) =>
              handleChangeEdit({ value, index, type: "received_employee" })
            }
          />
        ),
        perform_datetime: (
          <PickerColumn
            value={
              item.perform_datetime
                ? formatDatetime(
                    item.perform_datetime.includes("Z")
                      ? item.perform_datetime
                      : formatADDatetime(item.perform_datetime)
                  )
                : ""
            }
            onDataChanged={(value: string) =>
              handleChangeEdit({ value, index, type: "perform_datetime" })
            }
          />
        ),
        menu: (
          <DeleteColumn
            value={item.cancelable}
            id={item.id}
            onConfirm={() =>
              handleChangeEdit({ value: item.id, index, type: "delete" })
            }
          />
        ),
      })
    );
  }, [props?.SetDoctorFeeSequence?.dfPreviewItems]);

  const handleChangeEditSc = (data: any) => {
    props.runSequence({
      sequence: "SetDoctorFee",
      action: "updateSc",
      data,
      onFailed: (error: any) => setErrorMessage(error),
    });
  };

  const scPreviewItems = useMemo(() => {
    return (props?.SetDoctorFeeSequence?.scPreviewItems || []).map(
      (item: any, index: number) => ({
        ...item,
        no: <div style={{ textAlign: "center" }}>{index + 1}</div>,
        compensation_price: (
          <EditorColumn
            value={item.compensation_price}
            onDataChanged={(value: string) =>
              handleChangeEditSc({
                value,
                index,
                type: "compensation_price",
              })
            }
            disabled={item.price_unit === "" ? true : false}
            scPreviewItems={true}
          />
        ),
        received_employee_display: (
          <EditorColumn
            value={item.received_employee_display}
            onDataChanged={(value: string) =>
              handleChangeEditSc({
                value,
                index,
                type: "received_employee",
              })
            }
            disabled={item.price_unit === "" ? true : false}
            scPreviewItems={true}
          />
        ),
        edited_by: (
          <div>{item.received_employee_display ? item.edited_by : ""}</div>
        ),
      })
    );
  }, [props?.SetDoctorFeeSequence?.scPreviewItems]);

  const sum = useMemo(() => {
    const [total, claimable, nonClaimable] = (
      props?.SetDoctorFeeSequence?.dfPreviewItems || []
    ).reduce(
      (result: any, item: any) => {
        result[0] += Number.parseFloat(item.price_total);
        result[1] += Number.parseFloat(item.price_claimable);
        result[2] += Number.parseFloat(item.price_non_claimable);
        return result;
      },
      [0, 0, 0]
    );

    return {
      total: total.toFixed(2),
      claimable: claimable.toFixed(2),
      nonClaimable: nonClaimable.toFixed(2),
    };
  }, [props?.SetDoctorFeeSequence?.dfPreviewItems]);

  const handleSearchText = (text: string) => {
    let timeOut;

    if (filterTimeOut !== null) {
      clearTimeout(filterTimeOut);
    }

    setSearchText(text);

    timeOut = setTimeout(() => {
      if (searchText.length >= 3) {
        props.runSequence({
          sequence: "SetDoctorFee",
          action: "search",
          searchText: searchText,
        });
      } else if (searchText.length === 1) {
        props.setProp(`SetDoctorFeeSequence.searchResult`, null);
      }
    }, 800);

    setFilterTimeOut(timeOut as any);
  };

  const handleOpenSearch = () => {
    setIsLoading(true);
    props.runSequence({
      sequence: "SetDoctorFee",
      action: "open_search",
    });
    setOpenSearch(true);
  };

  const handleSearchItem = () => {
    setIsLoading(true);
    props.runSequence({
      sequence: "SetDoctorFee",
      action: "open_search",
      keyword: searchValue,
    });
  };

  const handleAddItem = () => {
    setSearchValue("");
    props.runSequence({
      sequence: "SetDoctorFee",
      action: "add_select",
      item: selectItemData,
    });
    setOpenSearch(false);
  };

  const handleCheckBoxChange = (e: any, checked: any, row: any) => {
    let stateItem: any = { ...selectItem };
    let stateItemData: any = [];
    if (!checked) {
      delete stateItem[row.original.id];
      let select_data = [selectItemData];
      select_data[0]?.map((item: any, index: any) => {
        if (item?.doctor_fee_rule !== row.original.id) {
          stateItemData.push(item);
        }
      });
    } else {
      stateItem[row.original.id] = row.original;
      stateItemData.push(...selectItemData, {
        encounter: props?.selectedEncounter?.id,
        quantity: 1,
        doctor_fee_rule: row.original?.id,
        doctor_fee_rule_display: row.original?.name,
        service_code: row.original?.service_code,
        price_unit: row.original?.price_unit,
        received_employee_display:
          props?.providerEmployeeInfo?.employee_info?.full_name,
        compensation_price: row.original?.price_unit,
        received_employee: props?.providerEmployeeInfo?.employee_info?.id,
      });
    }
    setSelectItemData(stateItemData);
    setSelectItem(stateItem);
  };

  const formatComma = (val: any) =>
    !isNaN(parseFloat(val))
      ? parseFloat(val)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(?:\d{3})+(?!\d))/g, ",")
      : val;

  const searchResult = props?.SetDoctorFeeSequence?.searchResult || [];

  console.log("dfPreviewItems", dfPreviewItems);

  return (
    <>
      {open ? (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            setOpen(false);
            props.runSequence({
              sequence: "SetDoctorFee",
              action: "clear",
            });
          }}
          error={props.errorMessage?.SetDoctorFee}
          success={props.successMessage?.SetDoctorFee}
        />
      ) : (
        ""
      )}
      <div style={{ margin: "15px 25px" }}>
        <div style={{ fontSize: "1.5rem", marginBottom: "15px" }}>
          ค่าตอบแทนแพทย์
        </div>
        <Button
          color={doctorFeeOrder ? "blue" : undefined}
          onClick={() => {
            setDoctorFeeOrder(true);
            setServiceCompensation(false);
          }}
        >
          ค่าธรรมเนียมตรวจ/วิสัญญี
        </Button>
        <Button
          color={serviceCompensation ? "blue" : undefined}
          onClick={() => {
            setDoctorFeeOrder(false);
            setServiceCompensation(true);
          }}
          style={{ marginLeft: "10px" }}
        >
          ค่าตอบแทน(หัตถการ)
        </Button>
      </div>

      {doctorFeeOrder && (
        <CardDoctorFeeOrderAndCompensationUX
          open={handleOpenSearch}
          searchText={searchText}
          onChangeSearchText={(e: any, v: any) => handleSearchText(v.value)}
          showSearchResult={
            searchResult && searchResult?.length > 0 ? true : false
          }
          searchResult={searchResult.map((item: any, index: number) => (
            <div
              key={index}
              style={{
                cursor: "pointer",
                padding: "5px",
                borderBottom: "1px solid #EEEEEE",
              }}
              onClick={(e: any) => {
                setSearchText("");
                props.runSequence({
                  sequence: "SetDoctorFee",
                  action: "add",
                  item: item,
                });
              }}
            >
              <div>{item.name}</div>
              <div style={{ color: "rgba(174,174,174)" }}>{item.code}</div>
            </div>
          ))}
          dfPreviewItems={dfPreviewItems}
          tableStyle={{ zoom: "85%" }}
          priceTotal={sum.total}
          priceClaimable={sum.claimable}
          priceNonClaimable={sum.nonClaimable}
          onSaveDF={() => {
            setLoading(true);
            props.runSequence({
              sequence: "SetDoctorFee",
              action: "save",
              onFailed: (error: any) => setErrorMessage(error),
            });
          }}
          button_save={completed ? <Icon name="check"></Icon> : "SAVE"}
          loadingSave={loading}
        />
      )}

      {serviceCompensation && (
        <CardServiceCompensationItemUX
          scPreviewItems={scPreviewItems}
          tableStyle={{ zoom: "85%" }}
          checkDate={checkDate}
          onChangeCheckDate={(e: any, { checked }: any) =>
            setCheckDate(checked)
          }
          startDate={startDate}
          endDate={endDate}
          onChangeStartDate={(date: any) => setStartDate(date)}
          onChangeEndDate={(date: any) => setEndDate(date)}
          buttonSave={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => {
                props.runSequence({
                  sequence: "SetDoctorFee",
                  action: "Save_SC",
                  card: CardDoctorFeeOrderAndCompensation,
                  buttonLoadKey: `${CardDoctorFeeOrderAndCompensation}_Save_SC`,
                });
              }}
              // data
              paramKey={`${CardDoctorFeeOrderAndCompensation}_Save_SC`}
              buttonLoadCheck={
                props.buttonLoadCheck?.[
                  `${CardDoctorFeeOrderAndCompensation}_Save_SC`
                ]
              }
              // config
              name="Save_SC"
              size="mini"
              style={{ width: "100%" }}
              title="SAVE"
              color="green"
            />
          }
        />
      )}

      <ModError
        open={!!errorMessage}
        titleColor="red"
        onApprove={() => setErrorMessage(null)}
        onClose={() => setErrorMessage(null)}
      >
        <ErrorMessage error={errorMessage} />
      </ModError>

      <Modal
        onClose={() => setOpenSearch(false)}
        open={openSearch}
        size="large"
      >
        <CardLayout
          headerColor="orange"
          titleText="ค้นหารายการค่าตอบแทน"
          loading={isLoading}
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
        >
          {/* @ts-ignore */}
          <Form>
            <Form.Group inline>
              <Form.Field width={2}>
                <label>ชื่อหรือรหัส</label>
              </Form.Field>
              <Form.Field width={12}>
                <Input
                  onChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                />
              </Form.Field>
              <Form.Field width={2}>
                <Button
                  color="blue"
                  content="ค้นหา"
                  fluid
                  onClick={handleSearchItem}
                />
              </Form.Field>
            </Form.Group>
          </Form>
          <Divider />
          <PureReactTable
            style={{ height: "440px" }}
            data={props?.SetDoctorFeeSequence?.searchProductResult}
            showPagination={false}
            minRows={10}
            onSelect={(originalRow: any) => setSelectedRow(originalRow)}
            selectedRow={selectedRow}
            columns={[
              {
                Header: "",
                accessor: "checked",
                width: 25,
                Cell: (row: any) => {
                  return (
                    <Checkbox
                      checked={Object.keys(selectItem).includes(
                        row.original.id.toString()
                      )}
                      onChange={(e, { checked }) =>
                        handleCheckBoxChange(e, checked, row)
                      }
                    />
                  );
                },
              },
              {
                Header: "Service Code",
                accessor: "service_code",
                width: 200,
              },
              {
                Header: "Product Name",
                accessor: "name",
                width: 600,
              },
              {
                Header: "ราคา",
                accessor: "price_unit",
                resizable: false,
                Cell: (row: any) => {
                  return (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {formatComma(row.value)}
                    </div>
                  );
                },
              },
            ]}
          />
          <Divider />
          {/* @ts-ignore */}
          <Form>
            <Form.Group inline className="rightAlign">
              <Form.Field width={2}>
                <Button
                  type="button"
                  fluid
                  color="green"
                  content="เลือก"
                  onClick={handleAddItem}
                />
              </Form.Field>
              <Form.Field width={2}>
                <Button
                  type="button"
                  fluid
                  color="red"
                  content="ยกเลิก"
                  onClick={() => setOpenSearch(false)}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </CardLayout>
      </Modal>
    </>
  );
};

const EditorColumn: React.FunctionComponent<any> = (props) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const inputRef = useRef() as MutableRefObject<any>;

  const handleClick = () => {
    if (!props.disabled && props.scPreviewItems) {
      setIsEdit(false);
    } else {
      setIsEdit(true);

      setTimeout(() => {
        inputRef.current.inputRef.current.focus();
      }, 100);
    }
  };

  const handleBlur = () => {
    props.onDataChanged?.(inputRef.current.inputRef.current.value);

    setIsEdit(false);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };

  return (
    <div
      style={
        !props.disabled && props.scPreviewItems ? ColumnStyleWhite : ColumnStyle
      }
      onClick={handleClick}
    >
      {isEdit ? (
        <Input
          ref={inputRef}
          style={{ width: "100%", height: "100%" }}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <div style={{ textAlign: "right" }}>{props.value}</div>
      )}
    </div>
  );
};

export const DeleteColumn: React.FC<any> = (props) => {
  const [openModConfirm, setOpenModConfirm] = useState<boolean>(false);

  const handleCancel = () => {
    props.onConfirm?.();
    handleCloseModConfirm();
  };

  const handleCloseModConfirm = () => {
    setOpenModConfirm(false);
  };

  return (
    <>
      {!(!props.value && props.id) && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="red"
            icon="minus"
            onClick={() => setOpenModConfirm(true)}
            size={props.size}
          ></Button>
        </div>
      )}
      <ModConfirm
        openModal={openModConfirm}
        titleName={props.titleName ?? "ต้องการลบรายการค่าธรรมเนียมหรือไม่"}
        titleColor={props.titleColor}
        onApprove={handleCancel}
        onDeny={handleCloseModConfirm}
      />
    </>
  );
};

export default CardDoctorFeeOrderAndCompensation;
