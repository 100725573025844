import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown,
  Input
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardFoodReportOrderQueueUX = (props: any) => {
    return(
      <div
        style={{ width: "100%",padding: "5px" }}>
        <div
          style={{display: props.hidePanel ? "none" : "flex", alignItems: "center"}}>
          
          <div
            style={{display: "flex", alignItems: "center", marginRight: "10px"}}>
            
            <div
              style={{marginRight: "10px", width: "60px",textAlign: "right"}}>
              HN
            </div>
            <Input
              name="filterQueue.hn"
              onChange={props.onChangeData}
              value={props.FoodListSequence?.filterQueue?.hn}>
            </Input>
          </div>
          <div
            style={{display: "flex", alignItems: "center", marginRight: "10px"}}>
            
            <div
              style={{marginRight: "5px", width: "100px",textAlign: "right"}}>
              ชื่อ
            </div>
            <Input
              name="filterQueue.fristName"
              onChange={props.onChangeData}
              value={props.FoodListSequence?.filterQueue?.fristName}>
            </Input>
          </div>
          <div
            style={{display: "flex", alignItems: "center", marginRight: "10px"}}>
            
            <div
              style={{marginRight: "5px", width: "100px",textAlign: "right"}}>
              นาสกุล
            </div>
            <Input
              name="filterQueue.lastName"
              onChange={props.onChangeData}
              value={props.FoodListSequence?.filterQueue?.lastName}>
            </Input>
          </div>
          <div
            style={{display: "flex", alignItems: "center", marginRight: "10px"}}>
            
            <div
              style={{marginRight: "5px", width: "100px",textAlign: "right"}}>
              หน่วยงาน
            </div>
            <Dropdown
              clearable={true}
              name="filterQueue.division"
              onChange={props.onChangeData}
              options={props.wardOptions}
              search={true}
              selection={true}
              value={props.FoodListSequence?.filterQueue?.division}>
            </Dropdown>
          </div>
        </div>
        <div
          style={{display: props.hidePanel ? "none" : "flex", alignItems: "center", margin: "10px 0px"}}>
          
          <div
            style={{display: "flex", alignItems: "center", marginRight: "10px"}}>
            
            <div
              style={{marginRight: "10px", width: "60px",textAlign: "right"}}>
              วันที่
            </div>
            <DateTextBox
              onChange={props.onChangeDate}
              value={props.FoodListSequence?.filterQueue?.date}>
            </DateTextBox>
          </div>
          <div
            style={{display: "flex", alignItems: "center", marginRight: "10px"}}>
            
            <div
              style={{marginRight: "5px", width: "75px",textAlign: "right"}}>
              มื้อ
            </div>
            <Dropdown
              clearable="true"
              name="filterQueue.meal"
              onChange={props.onChangeData}
              options={props.mealOptions}
              selection={true}
              value={props.FoodListSequence?.filterQueue?.meal}>
            </Dropdown>
          </div>
          <div
            style={{display: "flex", alignItems: "center", marginLeft: "30px"}}>
            
            <div
              style={{margin: "0px 15px"}}>
              {props.buttonSearch}
            </div>
            <div>
              {props.buttonPrintReport}
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardFoodReportOrderQueueUX

export const screenPropsDefault = {"height":"40vh","patientSearch":"[Patient Search Box]"}

/* Date Time : Thu Dec 29 2022 05:25:13 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\",padding: \"5px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.hidePanel ? \"none\" : \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"10px\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงาน"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"5px\", width: \"100px\",textAlign: \"right\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Dropdown",
      "parent": 17,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "filterQueue.division"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.wardOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.FoodListSequence?.filterQueue?.division"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.hidePanel ? \"none\" : \"flex\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"10px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Input",
      "parent": 44,
      "props": {
        "name": {
          "type": "value",
          "value": "filterQueue.fristName"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.FoodListSequence?.filterQueue?.fristName"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"10px\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Input",
      "parent": 46,
      "props": {
        "name": {
          "type": "value",
          "value": "filterQueue.lastName"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.FoodListSequence?.filterQueue?.lastName"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"5px\", width: \"100px\",textAlign: \"right\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "นาสกุล"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"5px\", width: \"100px\",textAlign: \"right\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"10px\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"10px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\", width: \"60px\",textAlign: \"right\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "มื้อ"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"5px\", width: \"75px\",textAlign: \"right\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Dropdown",
      "parent": 52,
      "props": {
        "clearable": {
          "type": "value",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "filterQueue.meal"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.mealOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.FoodListSequence?.filterQueue?.meal"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 56,
      "name": "DateTextBox",
      "parent": 51,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate"
        },
        "value": {
          "type": "code",
          "value": "props.FoodListSequence?.filterQueue?.date"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginLeft: \"30px\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"10px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\", width: \"60px\",textAlign: \"right\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Input",
      "parent": 60,
      "props": {
        "name": {
          "type": "value",
          "value": "filterQueue.hn"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.FoodListSequence?.filterQueue?.hn"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 57,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 15px\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 57,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrintReport"
        }
      },
      "seq": 65,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardFoodReportOrderQueueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "height": "40vh",
    "patientSearch": "[Patient Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
