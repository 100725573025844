import WasmController from "react-lib/frameworks/WasmController";
import EncounterList from "issara-sdk/apis/EncounterList_core";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import AppointmentList from "issara-sdk/apis/AppointmentList_apps_APP";
import AppointmentFinish from "issara-sdk/apis/AppointmentFinish_apps_APP";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import FormDataActionLogList from "issara-sdk/apis/FormDataActionLogList_apps_PTM";

export type State = {
  selectedPatient?: any;
  selectedEncounter?: any;
  buttonLoadCheck?: any;
  CreateUpdateEncounterSequence?: {
    sequenceIndex: string | null;
    encounterList: any[];
    division: any;
    doctor: any;
    patientCase: any;
    remark: any;
    note: any;
    underlying_disease: any;
    selectedEncouter: any;
    pregnancy_status?: any,
    pregnancy_period?: any;
    pregnancyPeriodOptions?: any[];
    pregnancyPeriodID?: any;
    pregnancyLog?: any[];
  } | null;
  appointmentEncounterList?: any[];
  selectedAppointmentItems?: any[];
  successMessage?: any;
  errorMessage?: any;
  SetPatientSequence?: any
};

export const StateInitial: State = {
  CreateUpdateEncounterSequence: null,
  appointmentEncounterList: [],
  selectedAppointmentItems: [],
  successMessage: null,
  errorMessage: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const GetActiveEncounterList: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.CreateUpdateEncounterSequence) return;
  if (!Number.isInteger(state.selectedPatient?.id)) return;

  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["division", {}],
        ["divisionNameCode", {}],
        ["doctor", {}],
        ["patientCase", {}],
      ],
    },
  });

  const appointmentEncounter = AppointmentList.list({
    apiToken: controller.apiToken,
    params: {
      patient: state.selectedPatient?.id,
      to_real_doctor_order: true,
    },
    extra: {
      division: controller.data?.division || null,
      device: controller.data?.device || null,
    },
  });

  const encounter = EncounterList.list({
    params: {
      unexpired_only: true,
      patient: state.selectedPatient.id,
    },
    apiToken: controller.apiToken,
  });

  const response = await Promise.all([appointmentEncounter, encounter]);

  const errors = response
    .map((res: any) => (typeof res[1] === "string" ? "" : res[1]))
    .filter(Boolean);

  if (!!errors.length) {
    if (params.card) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: errors },
      });
    }
    return console.log("fetch encounter error: ", errors);
  }

  const [appList, enList] = response;

  console.log(encounter[1] ? encounter[1] : encounter[0]);

  // params.form: is check ViewRegistration || ViewEncounter
  const pregnancyAssessment = await FormDataLatest.retrieve({
    apiToken: controller.apiToken,
    params: {
      encounter: params.form ? state.selectedPatient?.encounter_id : state.selectedEncounter?.id,
      form_code: "CardPregnancyAssessment",
      form_version: "1.0",
    },
    extra: { division: controller.data.division },
  });

  let encounterList = enList[0]?.items || [];
  let selectedEncounter = encounterList?.[0]

  if (params.editEncounterId){
    selectedEncounter = encounterList.find((item: any) => item.id === params.editEncounterId)
  }

  controller.setState({
    CreateUpdateEncounterSequence: {
      ...state.CreateUpdateEncounterSequence,
      pregnancy_status: pregnancyAssessment?.[0]?.data?.pregnancy_status || 1,
      pregnancy_period: pregnancyAssessment?.[0]?.data?.pregnancy_period || 0,
      pregnancyPeriodID: pregnancyAssessment?.[0]?.id,
      sequenceIndex: "CreateOrUpdate",
      encounterList: encounterList || [],
      pregnancyPeriodOptions: [
        { key: 1, value: 0, text: "ไม่ระบุ" },
        { key: 2, value: 1, text: "ไตรมาสที่ 1" },
        { key: 3, value: 2, text: "ไตรมาสที่ 2" },
        { key: 4, value: 3, text: "ไตรมาสที่ 3" },
      ],
      // isEditEncounter
      selectedEncouter: params.isEditEncounter && selectedEncounter,
      division: params.isEditEncounter && selectedEncounter?.division,
      doctor: params.isEditEncounter && selectedEncounter?.doctor,
      patientCase: params.isEditEncounter && selectedEncounter?.patient_case,
      remark: params.isEditEncounter && selectedEncounter?.remark,
      note: params.isEditEncounter && selectedEncounter?.note,
      underlying_disease: params.isEditEncounter && selectedEncounter?.underlying_disease,
    },
    appointmentEncounterList: appList[0]?.items || [],
  });
};

export const CreateOrUpdate: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.CreateUpdateEncounterSequence) return;
  if (
    params.action === "create" &&
    Number.isInteger(state?.selectedPatient?.id)
  ) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const encounter = await EncounterList.create({
      data: {
        type: "OPD",
        action: "OPD_NEW",
        patient: state.selectedPatient.id,
        division: state.CreateUpdateEncounterSequence.division,
        doctor: state.CreateUpdateEncounterSequence.doctor,
        patient_case: state.CreateUpdateEncounterSequence.patientCase,
        remark: state.CreateUpdateEncounterSequence.remark,
        note: state.CreateUpdateEncounterSequence.note,
        underlying_disease:
          state.CreateUpdateEncounterSequence.underlying_disease,
        // Additional fields (find out why we need them)
        chief_complaints: "",
        location: "",
        zone: null,
      },
      apiToken: controller.apiToken,
    });
    console.log(encounter[1] ? encounter[1] : encounter[0]);
    if (encounter[1]) console.log("Error open a new encounter");
    if (encounter[0]) {
      let patient = {
        ...encounter?.[0],
        encounter_no: encounter?.[0]?.number,
        full_name: encounter?.[0]?.patient_name,
        gender_name: encounter?.[0]?.patient_gender_name,
        age: encounter?.[0]?.patient_age?.split('ปี')?.[0],
        birthdate: encounter?.[0]?.patient_birthdate
      }

      // Save CardPregnancyAssessment
      const pregnancyAssessment = await FormDataList.create({
        data: {
          form_code: "CardPregnancyAssessment",
          form_name: "CardPregnancyAssessment",
          form_version: "1.0",
          encounter: encounter[0].id,
          action: "CONFIRM",
          data: {
            pregnancy_status: params.pregnancy?.pregnancy_status || 1,
            pregnancy_period: params.pregnancy?.pregnancy_period || 0,
          },
        },
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });

      // Refresh encounter list
      const encounterUpdate = await EncounterList.list({
        params: {
          unexpired_only: true,
          patient: state.selectedPatient.id,
        },
        apiToken: controller.apiToken,
      });
      console.log(encounterUpdate[1] ? encounterUpdate[1] : encounterUpdate[0]);

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
        successMessage: {
          ...state.successMessage,
          [params?.sequence]: encounterUpdate[0],
        },
        CreateUpdateEncounterSequence: {
          ...state.CreateUpdateEncounterSequence,
          encounterList: encounterUpdate[0]?.items || [],
          division: "",
          doctor: "",
          patientCase: "",
          remark: "",
          note: "",
          underlying_disease: "",
          // selectedEncouter: null,
          pregnancy_status: pregnancyAssessment?.[0]?.data?.pregnancy_status || 1,
          pregnancy_period: pregnancyAssessment?.[0]?.data?.pregnancy_period || 0,
          pregnancyPeriodID: pregnancyAssessment?.[0]?.id,
        },
        SetPatientSequence: {
          ...state.SetPatientSequence,
          patient: {
            ...(state.SetPatientSequence?.patient || {}),
            patient
          }
        }
      });
    }
    if (encounter[1]) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params?.sequence]: encounter[1],
        },
      });
    }
  } else if (params.action === "clear") {
    controller.setState({
      successMessage: { ...state.successMessage, [params?.sequence]: null },
      errorMessage: { ...state.errorMessage, [params?.sequence]: null },
    });
  } else if (params.action === "select" && params.item) {
    const targetEncounter = state.CreateUpdateEncounterSequence?.encounterList.find((encounter: any) => encounter.id === params.item?.id)

    const patient = {
      ...targetEncounter,
      encounter_no: targetEncounter.number,
      full_name: targetEncounter.patient_name,
      gender_name: targetEncounter.patient_gender_name,
      age: targetEncounter.patient_age?.split('ปี')?.[0],
      birthdate: targetEncounter.patient_birthdate
    }

    const pregnancyAssessment = await FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: targetEncounter?.id,
        form_code: "CardPregnancyAssessment",
        form_version: "1.0",
      },
      extra: { division: controller.data.division },
    });

    controller.setState({
      CreateUpdateEncounterSequence: {
        ...state.CreateUpdateEncounterSequence,
        selectedEncouter: { ...targetEncounter },
        division: params.item.division,
        doctor: params.item.doctor,
        patientCase: params.item.patient_case,
        remark: params.item.remark,
        note: params.item.note,
        underlying_disease: params.item.underlying_disease,
        pregnancy_status: pregnancyAssessment?.[0]?.data?.pregnancy_status,
        pregnancy_period: pregnancyAssessment?.[0]?.data?.pregnancy_period,
        pregnancyPeriodID: pregnancyAssessment?.[0]?.id,
      },
      SetPatientSequence: {
        ...state.SetPatientSequence,
        patient: {
          ...(state.SetPatientSequence?.patient || {}),
          patient
        }
      }
    });
  } else if (params.action === "update") {
    const {
      id,
      ...data
    } = state.CreateUpdateEncounterSequence.selectedEncouter;

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const patientCoverage = await EncounterDetail.update({
      pk: id,
      data: {
        chief_complaints: data.chief_complaints,
        drug_allergy: data.drug_allergy,
        ended: data.ended,
        location: data.location,
        number: data.number,
        pk: data.pk,
        type: data.type,
        zone: data.zone,
        patient: state.selectedPatient.id,
        division: state.CreateUpdateEncounterSequence.division,
        doctor: state.CreateUpdateEncounterSequence.doctor,
        patient_case: state.CreateUpdateEncounterSequence.patientCase,
        remark: state.CreateUpdateEncounterSequence.remark,
        note: state.CreateUpdateEncounterSequence.note,
        underlying_disease:
          state.CreateUpdateEncounterSequence.underlying_disease,
        action: "OPD_EDIT",
      },
      apiToken: controller.apiToken,
    });
    console.log("patientCoverage", patientCoverage[0], patientCoverage[1]);

    // Edit CardPregnancyAssessment
    const pregnancyAssessment = await FormDataDetail.update({
      pk: state.CreateUpdateEncounterSequence.pregnancyPeriodID,
      data: {
        form_code: "CardPregnancyAssessment",
        form_name: "CardPregnancyAssessment",
        form_version: "1.0",
        encounter: id,
        action: "CONFIRM",
        data: {
          pregnancy_status: params.pregnancy?.pregnancy_status,
          pregnancy_period: params.pregnancy?.pregnancy_period,
        },
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (patientCoverage[0]) {
      // Refresh encounter list
      const encounterUpdate = await EncounterList.list({
        params: {
          unexpired_only: true,
          patient: state.selectedPatient.id,
        },
        apiToken: controller.apiToken,
      });

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
        successMessage: {
          ...state.successMessage,
          [params?.sequence]: encounterUpdate?.[0],
        },
        CreateUpdateEncounterSequence: {
          ...state.CreateUpdateEncounterSequence,
          encounterList: encounterUpdate?.[0]?.items || [],
          division: "",
          doctor: "",
          patientCase: "",
          remark: "",
          note: "",
          underlying_disease: "",
          pregnancy_status: pregnancyAssessment?.[0]?.data?.pregnancy_status,
          pregnancy_period: pregnancyAssessment?.[0]?.data?.pregnancy_period,
          // selectedEncouter: null,
        },
      });
    }
    if (patientCoverage[1]) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params?.sequence]: patientCoverage[1],
        },
      });
    }

  } else if (params.action === "clearData") {
    controller.setState({
      CreateUpdateEncounterSequence: {
        ...state.CreateUpdateEncounterSequence,
        division: "",
        doctor: "",
        patientCase: "",
        remark: "",
        note: "",
        underlying_disease: "",
        selectedEncouter: null,
        pregnancy_status: 1,
        pregnancy_period: 0,
      },
    });
  } else if (params.action === "delete") {
    const {
      id,
      ...data
    } = state.CreateUpdateEncounterSequence.selectedEncouter;
    const patientCoverage = await EncounterDetail.delete({
      pk: id,
      apiToken: controller.apiToken,
      extra: {
        data: {
          username: params.username,
          password: params.password,
          note: params.note,
        },
        division: controller.data.division,
        device: controller.data.device,
      },
    });
    console.log("patientCoverage", patientCoverage[0], patientCoverage[1]);

    if (patientCoverage[1]) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params?.sequence]: patientCoverage[1],
        },
      });
    } else {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params?.sequence]: patientCoverage[0],
        },
        CreateUpdateEncounterSequence: {
          ...state.CreateUpdateEncounterSequence,
          division: "",
          doctor: "",
          patientCase: "",
          remark: "",
          note: "",
          underlying_disease: "",
          selectedEncouter: null,
        },
      });

      // Refresh encounter list
      const encounterUpdate = await EncounterList.list({
        params: {
          unexpired_only: true,
          patient: state.selectedPatient.id,
        },
        apiToken: controller.apiToken,
      });
      console.log(encounterUpdate[1] ? encounterUpdate[1] : encounterUpdate[0]);
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params?.sequence]: encounterUpdate[0],
        },
        CreateUpdateEncounterSequence: {
          ...state.CreateUpdateEncounterSequence,
          encounterList: encounterUpdate[0]?.items || [],
          division: "",
          doctor: "",
          patientCase: "",
          remark: "",
          note: "",
          underlying_disease: "",
          selectedEncouter: null,
        },
      });
    }
  } else if (params?.action === "getLogPregnancy") {
    const pregnancyLog = await FormDataActionLogList.list({
      pk: state.CreateUpdateEncounterSequence.pregnancyPeriodID,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    controller.setState({
      CreateUpdateEncounterSequence: {
        ...state.CreateUpdateEncounterSequence,
        pregnancyLog: pregnancyLog[0]?.items
      },
    });
  }

  // APPOINTMENT SEGMENT
  else if (params?.action === "open_encounter_appointment") {
    let items = [...state.appointmentEncounterList]
      .filter((item: any) => state.selectedAppointmentItems.includes(item.id))
      .map((item: any) => ({ ...item, checked: true }));
    // do something
    const openAppointment = await AppointmentFinish.post({
      apiToken: controller.apiToken,
      data: {
        items: items,
      },
      extra: {
        division: controller.data?.division || null,
        device: controller.data?.device || null,
      },
    });

    if (openAppointment[1]) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: openAppointment[1],
          },
        });
      }
      return console.log(
        "Open encounter by appointment error: ",
        openAppointment[1]
      );
    }

    controller.setState(
      {
        selectedAppointmentItems: [],
        successMessage: { ...state.successMessage, [params.card]: { appointment: true } }
      },
      async () => {
        await GetActiveEncounterList(controller, { card: params.card });
      }
    );
  } else if (params?.action === "checked_encounter_appointment") {
    let items = [...(state.selectedAppointmentItems || [])];
    if (items.includes(params?.appointmentId)) {
      items = items.filter((i: any) => i !== params?.appointmentId);
    } else {
      items.push(params?.appointmentId);
    }
    controller.setState({
      selectedAppointmentItems: items,
    });
  } else if (params.action === "getPregnancyAssessment") {
    const pregnancyAssessment = await FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: state.selectedEncounter?.id,
        form_code: "CardPregnancyAssessment",
        form_version: "1.0",
      },
      extra: { division: controller.data.division },
    });

    controller.setState({
      CreateUpdateEncounterSequence: {
        ...state.CreateUpdateEncounterSequence,
        pregnancy_status: pregnancyAssessment?.[0]?.data?.pregnancy_status,
        pregnancy_period: pregnancyAssessment?.[0]?.data?.pregnancy_period,
        pregnancyPeriodID: pregnancyAssessment?.[0]?.id,
      }
    });
  } else if (params.action === "savePregnancyAssessment") {
    const pregnancyAssessment = await FormDataDetail.update({
      pk: state.CreateUpdateEncounterSequence.pregnancyPeriodID,
      data: {
        form_code: "CardPregnancyAssessment",
        form_name: "CardPregnancyAssessment",
        form_version: "1.0",
        encounter: state.selectedEncounter?.id,
        action: "CONFIRM",
        data: {
          pregnancy_status: state.CreateUpdateEncounterSequence.pregnancy_status,
          pregnancy_period: state.CreateUpdateEncounterSequence.pregnancy_period,
        },
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (pregnancyAssessment[0]) {
      CreateOrUpdate(controller, { action: "getPregnancyAssessment" })
    }
  }
};
