import WasmController from "react-lib/frameworks/WasmController";
import DrugOrderActionView from "issara-sdk/apis/DrugOrderActionView_apps_TPD";
import DrugOrderDetailForAction from "issara-sdk/apis/DrugOrderDetailForAction_apps_TPD";
import ShippingOrderPerformedList from "issara-sdk/apis/ShippingOrderPerformedList_apps_PRX";
import DrugOrderDetailForRefillPlanning from "issara-sdk/apis/DrugOrderDetailForRefillPlanning_apps_TPD";
import DrugRefillOrderCreate from "issara-sdk/apis/DrugRefillOrderCreate_apps_TPD";
import DrugOrderQueueList from "issara-sdk/apis/DrugOrderQueueList_apps_TPD";
import { beToAd, formatDate } from "react-lib/utils";

import { createPDFQrCodeDrugDelivery } from "react-lib/appcon/common/createPDFQrCodeDrugDelivery";
import getPdfMake from "react-lib/appcon/common/pdfMake";
import FormHomeMed from "../FormHomeMed";
import moment from "moment";

export type State = {
  // CommonInterface
  successMessage?: any;
  errorMessage?: any;
  buttonLoadCheck?: any; // {cardName: LOADING || SUCCESS || ERROR}
  modConfirmAction?: any;

  // TPDInterface
  drugDelivery?: {
    token?: string;
    employeeName?: string;
    isTransport?: boolean | null;
    error?: any;
    loading?: boolean;
    isDrugDelivery?: boolean;
    showDrugDelivery?: boolean;
    shipper?: string | null;
    trackingNo?: string | null;
    address?: string | null;
    shippingOrder?: any[] | null;
    personName?: string;
    isOthers?: boolean | null;
  };

  // selected
  selectedEncounter?: any;
  selectedDrugOrderWorking?: any;
  selectedPatient?: any;

  // modNoteReject
  modNoteReject?: {
    open?: boolean;
    note?: any;
  };

  loadingStatus?: any;
  // seq
  DrugOrderActionSequence?: {
    sequenceIndex?: string | null;
    noteReprint?: string | null;
    language?: string | null;
    printPharma?: boolean | null;
    printPatient?: boolean | null;
    printLabel?: boolean | null;
    printMedReport?: boolean | null;
    isReprintSuccess?: boolean | null;
  } | null;
  DrugRefillSequence?: any;
  DrugOrderPendingList?: any;
};

export const StateInitial: State = {
  DrugOrderActionSequence: {
    sequenceIndex: null,
    printMedReport: false,
    isReprintSuccess: false,
  },
  DrugRefillSequence: null,
  DrugOrderPendingList: null,
  modNoteReject: {
    open: false,
    note: null,
  },
  drugDelivery: {
    isDrugDelivery: false,
    showDrugDelivery: false,
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any }
  | { message: "HandleQRDrugDeliveryReport"; params: {} }
  | { message: "SelectDrugOrderWorking"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  let state = controller.getState();

  if (!state.DrugOrderActionSequence) {
    return;
  }

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["unit", {}],
        ["route", {}],
        ["frequency", {}],
        ["method", {}],
        ["site", {}],
        ["shippingEmployee", {}],
      ],
    },
  });

  if (state?.selectedEncounter?.pk) {
    const selectedShippingOrder = await ShippingOrderPerformedList.list({
      params: {
        encounter: state?.selectedEncounter?.pk,
      },
      extra: {
        division: controller.data.division,
      },
      apiToken: controller.apiToken,
    });

    var shippingOrder = selectedShippingOrder?.[0]?.items?.[0];
    console.log("shippingOrder", shippingOrder);
  }

  // check REFILL_PLANNED Queue
  getDrugRefillPlaned(controller, params)

  state = controller.getState();

  if (shippingOrder) {
    controller.setState({
      DrugOrderActionSequence: {
        ...state.DrugOrderActionSequence,
        sequenceIndex: "POOL",
      },
      drugDelivery: {
        ...state.drugDelivery,
        shipper: shippingOrder?.shipping_employee,
        address:
          shippingOrder?.transport_to_type === 1
            ? "present"
            : shippingOrder?.transport_to_type === 2
              ? "home"
              : "home",
        trackingNo: shippingOrder?.tracking_number,
        isDrugDelivery: true,
        showDrugDelivery: true,
        shippingOrder: shippingOrder,
      },
    });
  } else {
    controller.setState({
      DrugOrderActionSequence: {
        ...state.DrugOrderActionSequence,
        sequenceIndex: "POOL",
      },
      drugDelivery: {
        ...state.drugDelivery,
        showDrugDelivery: false,
        shipper: "",
        address: "",
        trackingNo: "",
        // isDrugDelivery: false,
      },
    }, async () =>
    await controller.handleEvent({
      // get drugOrderQueue with out filter
      message: "GetDrugOrderQueue" as any,
      params: {
        patient: state.selectedEncounter?.patient_id || "",
      },
    }));
  }
};

const openMedError: Handler = async (controller, params) => {
  controller.setState(
    {
      MedErrorListSequence: {
        sequenceIndex: "EditMedError",
      },
    } as any,
    () => params.callback?.()
  );
};

const deliveryAction: Handler = async (controller, params) => {
  let paramAction = {};
  let state = controller.getState();

  if (state?.drugDelivery?.isDrugDelivery) {
    let textAlert: any[] = [];

    if (!state?.drugDelivery?.shipper) {
      textAlert.push("กรุณาระบุค่าใน Shipper");
    }
    if (!state?.drugDelivery?.trackingNo) {
      textAlert.push("กรุณาระบุค่าใน TrackingNo");
    }
    if (!state?.drugDelivery?.address) {
      textAlert.push("กรุณาระบุค่าใน สถานที่จัดส่ง");
    }

    if (textAlert.length === 0) {
      paramAction = {
        action: params.action,
      };
    } else {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: textAlert },
          },
        });
      }

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
      });
      return null
    }
  } else {
    const delivery = state?.drugDelivery || {}

    if (delivery.isOthers && !delivery.personName) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: ["กรุณาระบุชื่อผู้รับยา"] },
        },
      });

      return null
    }

    paramAction = {
      action: params.action,
      co_user: !!state?.drugDelivery?.isTransport ? state?.drugDelivery?.token : undefined,
      note: delivery.isOthers ? delivery.personName : undefined,
      is_transporter: !!state?.drugDelivery?.isTransport,
      items: [],
      language: "TH",
    };
  }

  return paramAction;
};

const wuncAction: Handler = async (controller, params) => {
  let paramAction = {};
  let state = controller.getState();
  if (!params?.confirm) {
    controller.setState({
      modConfirmAction: {
        ...state.modConfirmAction,
        open: true,
        action: params.action,
      },
    });
    return null
  } else {
    paramAction = {
      action: params.action,
      note: state.modConfirmAction.text,
    };
    controller.setState({
      modConfirmAction: {
        ...state.modConfirmAction,
        open: false,
        value: null,
        action: "",
        text: "",
      },
    });
  }
  return paramAction;
};

const printQR: Handler = async (controller, params) => {
  let state = controller.getState();
  return controller.setState(
    {
      drugDelivery: {
        ...state?.drugDelivery,
        shippingOrder: state.drugDelivery?.shippingOrder,
      },
    },
    () => HandleQRDrugDeliveryReport(controller, params)
  );
};

const reprint: Handler = async (controller, params) => {
  let state = controller.getState();
  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  let paramAction = {
    action: params.action,
    language: state?.DrugOrderActionSequence?.language || "TH",
    note: state?.DrugOrderActionSequence?.noteReprint || "เนื่องจาก ",
    print_drug_label: state?.DrugOrderActionSequence?.printLabel || false,
    print_patient: state?.DrugOrderActionSequence?.printPatient || false,
    print_pharma: state?.DrugOrderActionSequence?.printPharma || false,
  };
  return paramAction;
};
const reject: Handler = async (controller, params) => {
  let paramAction = null;
  const state = controller.getState();

  if (params.confirm) {
    const items = params?.items;
    const drugOrder = items.map((item: any) => ({
      ...item,
      note_rejected: state.modNoteReject?.note,
    }));

    paramAction = {
      action: params.action,
      items: drugOrder,
    };

    controller.setState({
      modNoteReject: {
        ...state.modNoteReject,
        open: false,
      },
    });
  } else {
    controller.setState({
      modNoteReject: {
        ...state.modNoteReject,
        open: true,
      },
    });
  }

  return paramAction;
};

const printFormHomeMedPDF: Handler = async (
  controller,
  resultSelectedDrugOrderWorking: any
) => {
  let state = controller.getState();
  const promiseArray = (resultSelectedDrugOrderWorking?.items || []).map(
    (item: any) => {
      return new Promise((resolve, reject) => {
        const img = document.createElement("img");
        img.crossOrigin = "anonymous";
        img.src = item.drug_image;
        img.onload = () => {
          resolve({
            ...item,
            drug_image: convertImageToPNG(img),
          });
        };
        img.onerror = () => {
          resolve({...item})
        }
      });
    }
  );
  const items = await Promise.all(promiseArray);
  const data = Object.assign({
    selectedPatient: state.selectedPatient,
    selectedDrugOrder: { ...resultSelectedDrugOrderWorking, items },
  });

  let docDef: any = { content: [] };
  docDef = FormHomeMed(data);

  const pdfMake = (await getPdfMake()).createPdf(docDef);
  pdfMake.open();
};

export const getDrugRefillPlaned: Handler = async (controller, params) => {
  let state = controller.getState();
  const [drugRes, drugErr] = await DrugOrderQueueList.list({
    params: {
      is_opd: true,
      is_ipd: true,
      order_div: "",
      type:
        state.selectedEncounter?.type === "OPD"
          ? 1
          : state.selectedEncounter?.type === "IPD"
            ? 7
            : "",
      patient: state.selectedEncounter?.patient_id,
      is_shipping: true,
      status: "REFILL_PLANNED",
      shipping_company: "",
      order_perform_div: controller.data.division,
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
      device: (controller.data as any).device,
    },
  });

  if (drugRes) {
    let drugRefillPlaned = drugRes.items.filter(
      (item: any) => item.hn === state.selectedEncounter?.hn
    );

    controller.handleEvent({
      message: "SelectDrugOrderWorking",
      params: {
        drugOrder: {
          id: drugRefillPlaned[0]?.id,
          encounter: state?.selectedEncounter?.pk,
        },
      },
    });

    controller.setState({
      DrugOrderPendingList: drugRes.items,
    });
  }
};

export const getRefill: Handler = async (controller, params) => {
  let state = controller.getState();
  const drugOrderWorking = await DrugOrderDetailForRefillPlanning.retrieve({
    pk: state.selectedDrugOrderWorking.pk,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
      device: (controller.data as any).device,
    },
  });

  if (drugOrderWorking[0]) {
    controller.setState({
      DrugRefillSequence: drugOrderWorking[0],
    });
  }

  // check REFILL_PLANNED Queue
  PerformDrugOrderAction(controller, { action: "getDrugRefillPlaned" });
};

export const saveRefill: Handler = async (controller, params) => {
  let state = controller.getState();
  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "LOADING",
    },
  });

  const drugOrderWorking = await DrugOrderDetailForRefillPlanning.update({
    pk: state.selectedDrugOrderWorking.pk,
    data: state.DrugRefillSequence,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
      device: (controller.data as any).device,
    },
  });

  if (drugOrderWorking[1]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.card]: { error: drugOrderWorking[1] },
      },
      loadingStatus: { ...state.loadingStatus, [params.card]: false },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "SUCCESS",
      },
      successMessage: {
        ...state.successMessage,
        [params.card]: {
          ...drugOrderWorking[0],
          edit:
            params.buttonLoadKey === "CardDrugOrderRefill_EDIT" ? false : true,
        },
      },
    });

    PerformDrugOrderAction(controller, { action: "getRefill" });
  }
};

export const addOrderList: Handler = async (controller, params) => {
  let state = controller.getState();
  let dataOrderList: any[] = [];
  let dataRefill: any[] = [];

  if (state?.DrugRefillSequence?.appointments !== undefined) {
    state?.DrugRefillSequence?.appointments.map((item: any) => {
      dataOrderList.push(item);
    });
  }

  state?.DrugRefillSequence?.order_items.map((item: any) => {
    dataRefill.push({ order_item: item.pk, quantity: 0 });
  });

  dataOrderList.push({
    start_date: "",
    end_date: "",
    is_editable: true,
    refreshCount: 1,
    plannings: dataRefill,
  });

  controller.setState({
    DrugRefillSequence: {
      ...state?.DrugRefillSequence,
      appointments: dataOrderList,
    },
  });
};

export const calculatorSplit: Handler = async (controller, params) => {
  let state = controller.getState();
  let dataOrderList: any[] = [];
  let newSplitDate = moment(
    beToAd(state?.DrugRefillSequence?.splitDate)
  ).format("DD/MM/YYYY");

  let splitCount = state?.DrugRefillSequence?.splitCount || 1;
  let splitDay = state?.DrugRefillSequence?.splitDay || 1;
  let splitDate = moment(newSplitDate, "DD/MM/YYYY") || moment();

  let newOrderItems: any = [];
  (state.DrugRefillSequence?.order_items || []).forEach((item: any) => {
    newOrderItems.push({ ...item, planned_quantity: 0 });
  });

  for (let i = 0; i < splitCount; i++) {
    var plan_date = formatDate(splitDate);
    var plannings: any = [];
    newOrderItems.map((item: any) => {
      var expected_quantity = parseInt((item.quantity / splitCount) * (i + 1));
      let planning = {
        order_item: item.pk,
        quantity: expected_quantity - item.planned_quantity,
      };
      item.planned_quantity += planning.quantity;
      plannings.push(planning);
    });

    dataOrderList.push({
      start_date: plan_date,
      end_date: plan_date,
      is_editable: true,
      refreshCount: 1,
      plannings: plannings,
    });
    splitDate.add(splitDay, "days");
  }

  newOrderItems = [];
  (state.DrugRefillSequence?.order_items || []).forEach((item: any) => {
    newOrderItems.push({ ...item, planned_quantity: item.quantity });
  });

  controller.setState({
    DrugRefillSequence: {
      ...state?.DrugRefillSequence,
      appointments: dataOrderList,
      order_items: newOrderItems,
    },
  });
};

export const createRefillOrder: Handler = async (controller, params) => {
  let state = controller.getState();
  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      ["CardDrugOrderRefill_REFILLPRINT"]: "LOADING",
    },
  });

  const drugRefillOrderCreate = await DrugRefillOrderCreate.create({
    data: {
      action: "PRINT",
      appointment:
        state.DrugRefillSequence?.appointments[
          state.DrugRefillSequence?.selectedRefill
        ]?.pk,
      pk:
        state.DrugRefillSequence?.appointments[
          state.DrugRefillSequence?.selectedRefill
        ]?.refill || 0,
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
      device: (controller.data as any).device,
    },
  });

  if (drugRefillOrderCreate[1]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        ["CardDrugOrderRefill_REFILLPRINT"]: "ERROR",
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        ["CardDrugOrderRefill_REFILLPRINT"]: "SUCCESS",
      },
      successMessage: {
        ...state.successMessage,
        [params.card]: {
          edit:
            params.buttonLoadKey === "CardDrugOrderRefill_EDIT" ? false : true,
        },
      },
    });

    controller.handleEvent({
      message: "SelectDrugOrderWorking",
      params: {
        drugOrder: {
          id: drugRefillOrderCreate[0].pk,
          encounter: state?.selectedEncounter?.pk,
        },
      },
    });
  }
};

export const printAction: Handler = async (controller, params) => {
  const state = controller.getState();
  return {
    action: params.action,
    print_ids: [...(state.selectedDrugOrderWorking.items || [])]
      .filter((item: any) => item.print)
      .map((item: any) => item.id),
    last_edited: params.last_edited
  };
}
export const changeOrderItemData: Handler = async (controller, params) => {
  let state = controller.getState();
  // for case that expect drugOrder.item have target field
  let newData = [...(state.selectedDrugOrderWorking?.items || [])].map(
    (item: any) =>
      item.id === params.id
        ? { ...item, [params.name]: params.value }
        : { ...item }
  );
  return controller.setState({
    selectedDrugOrderWorking: {
      ...state.selectedDrugOrderWorking,
      items: newData,
    },
  });
};
export const PerformDrugOrderAction: Handler = async (controller, params) => {
  var state = controller.getState();

  if (!state.DrugOrderActionSequence) {
    return;
  }

  if (!params?.action) {
    return;
  }

  if (params.action === "OPEN_MED_ERROR") {
    openMedError(controller, params);
  } else {
    let paramAction = {};
    if (params?.action === "DELIVER") {
      paramAction = await deliveryAction(controller, params);
      if (paramAction === null) {
        return
      }
    } else if (
      params?.action === "WAIVE" ||
      params?.action === "UNVERIFY" ||
      params?.action === "UNCHECK" ||
      params?.action === "UNDELIVER" ||
      params?.action === "UNDIVIDE" ||
      params?.action === "CANCEL_PLAN"
    ) {
      paramAction = await wuncAction(controller, params);
      if (paramAction === null) {
        return
      }
    } else if (params?.action === "PRINTQR") {
      return printQR(controller, params);
    } else if (params?.action === "REPRINT") {
      paramAction = await reprint(controller, params);
    } else if (params?.action === "REJECT") {
      // TODO: Check is need paramAction continue sending 
      paramAction = await reject(controller, params);
      if (paramAction === null) {
        return
      }
    } else if (params?.action === "getDrugRefillPlaned") {
      return getDrugRefillPlaned(controller, params);
    } else if (params?.action === "getRefill") {
      getRefill(controller, params);
    } else if (params?.action === "saveRefill") {
      return saveRefill(controller, params);
    } else if (params?.action === "addOrderList") {
      return addOrderList(controller, params);
    } else if (params?.action === "calculatorSplit") {
      return calculatorSplit(controller, params);
    } else if (params.action === "createRefillOrder") {
      // TODO : เดี๋ยวกลับมาดู 
      createRefillOrder(controller, params);
    } else if (params.action === "changeOrderItemData" && params?.id) {
      return changeOrderItemData(controller, params);
    } else if (params.action === "PRINT") {
      paramAction = await printAction(controller, {
        ...params,
        last_edited: state.selectedDrugOrderWorking?.edited,
      });
    } else {
      paramAction = {
        action: params.action,
        ...params,
      };
    }

    console.log("check !! paramAction: ", paramAction);

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });


    /// PRINT and Change Statue 

    const [response, error, network] = await DrugOrderActionView.update({
      pk: state.selectedDrugOrderWorking.pk,
      data: {
        ...paramAction,
      } as any,
      extra: {
        division: controller.data.division,
        device: (controller.data as any).device,
      },
      apiToken: controller.apiToken,
    });

    // console.log('params.card: ', params.card, ' error: ', error);
    if (error) {
      if (params.card) {
        const key = Array.isArray(error.last_edited) ? `${params.card}_LAST_EDITED` : params.card

        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [key]: { error: error },
          },
        });
      }
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        loadingStatus: { ...state.loadingStatus, [params.card]: false },
      });

      if (params?.action === "PRINT" || params?.action === "REPRINT") {
        // กรณีจะ print จาก เภสัช บางที error ด้วยเหตุ ยังไม่ได้ รับทราบ
        return;
      }

    } else {
      // Success DrugOrderActionView
      state = controller.getState();

      if (state?.drugDelivery?.isDrugDelivery && params?.action === "DELIVER") {
        const drug = state?.selectedDrugOrderWorking;
        const orderdrug = drug?.items?.map((item: any) => ({
          id: item.id,
          item_type: 1,
        }));

        const [response2, error2, network2] =
          await ShippingOrderPerformedList.create({
            data: {
              encounter: state?.selectedEncounter?.pk,
              shipping_employee: state?.drugDelivery?.shipper,
              tracking_number: state?.drugDelivery?.trackingNo,
              transport_address: state?.drugDelivery?.address,
              order_items: orderdrug,
            },
            extra: {
              division: controller.data.division,
              device: (controller.data as any).device,
            },
            apiToken: controller.apiToken,
          });

        if (error2) {
          if (params.card) {
            controller.setState({
              errorMessage: {
                ...state.errorMessage,
                [params.card]: { error: error2 },
              },
            });
          }
          controller.setState({
            buttonLoadCheck: {
              ...state.buttonLoadCheck,
              [params.buttonLoadKey]: "ERROR",
            },
            drugDelivery: {
              ...state?.drugDelivery,
              shipper: null,
              trackingNo: null,
              address: null,
            },
          });
        } else {
          // 2 success create ShippingOrderPerformedList 
          controller.setState(
            {
              drugDelivery: {
                ...state?.drugDelivery,
                showDrugDelivery: true,
                shippingOrder: response2,
              },
            },
            () => HandleQRDrugDeliveryReport(controller, params)
          );
        }
      }

      controller.setState({
        drugDelivery: {
          ...state?.drugDelivery,
          personName: "",
          isOthers: false,
          error: null
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
        loadingStatus: { ...state.loadingStatus, [params.card]: false },
        DrugOrderActionSequence: {
          ...state.DrugOrderActionSequence,
          isReprintSuccess: true,
        },
      });

      if (params.getRefill || response?.status === "DIVIDED") {
        // check REFILL_PLANNED Queue
        PerformDrugOrderAction(controller, { action: "getRefill" });
      }
    }

    // Refresh to get new allowed actions 
    console.log('DrugOrderDetailForAction: ');

    const drugOrderWorking = await DrugOrderDetailForAction.retrieve({
      pk: state.selectedDrugOrderWorking.pk,
      apiToken: controller.apiToken,
    });
    if (drugOrderWorking[1]) {
      if (params.card) {
        return controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: drugOrderWorking[1] },
          },
        });
      }
      return console.log("Error: ", drugOrderWorking[1].toString());
    }


    // ไม่แน่ใจว่า เฉพาะ Action 
    // params?.action === "WAIVE" ||
    // params?.action === "UNVERIFY" ||
    // params?.action === "UNCHECK" ||
    // params?.action === "UNDELIVER" ||
    // params?.action === "UNDIVIDE" ||
    // params?.action === "CANCEL_PLAN"
    // REJECT หรือ เปล่า 

    const resultSelectedDrugOrderWorking: any = {
      ...drugOrderWorking[0],
      items: drugOrderWorking[0].items.map((drug: any, index: number) => ({
        ...drug,
        print: state.selectedDrugOrderWorking?.items[index]?.print || false,
      })),
    };

    if (
      ["HOME_OPD", "HOME_IPD"].includes(resultSelectedDrugOrderWorking?.type) &&
      (params.action === "PRINT" ||
        (params?.action === "REPRINT" &&
          state?.DrugOrderActionSequence?.printMedReport))
    ) {
      // const data = Object.assign({
      //   selectedPatient: state.selectedPatient,
      //   selectedDrugOrder: resultSelectedDrugOrderWorking,
      // });

      // let docDef: any = { content: [] };
      // docDef = FormHomeMed(data);

      // const pdfMake = (await getPdfMake()).createPdf(docDef);
      // pdfMake.open();
      printFormHomeMedPDF(controller, resultSelectedDrugOrderWorking);
    }

    controller.setState(
      {
        selectedDrugOrderWorking: resultSelectedDrugOrderWorking,
      },
      async () =>
        await controller.handleEvent({
          message: "GetDrugOrderQueue" as any,
          params: {
            patient: state.selectedEncounter?.patient_id || "",
          },
        })
    );
  }
};

export const HandleQRDrugDeliveryReport: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  const patient = state?.selectedPatient;
  const orderDrug = state?.selectedDrugOrderWorking;

  // ----- createPDFQRcode
  const data = Object.assign({
    // --- Patien and OrderDrug
    encounter: state?.selectedEncounter?.pk,
    order: orderDrug?.code,
    patient_name: patient?.full_name_th,
    division: "หน่วยเภสัชกรรม",
    shipping: state?.drugDelivery?.shippingOrder?.id,
  });
  await createPDFQrCodeDrugDelivery(data);
};

const convertImageToPNG = (image: any) => {
  // create an off-screen canvas
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // set its dimension to target size
  canvas.width = image.width;
  canvas.height = image.height;

  // draw source image into the off-screen canvas:
  ctx?.drawImage(image, 0, 0, image.width, image.height);

  // encode image to data-uri with base64 version of the image
  return canvas.toDataURL("image/png");
};
