import React from "react";

// UI
import CardIrrigationUX from "./CardIrrigationUX";
import {
  ACTION,
  checkHideButton,
} from "./CardOtherForm";
import { PreOPButtonAction } from "./CardPreOPVisit";

export const IRRIGATION = "irrigation";
const CARD_IRRIGATION = "CardIrrigation";

const configKeys = [
  "Nss",
  "Water",
  "Glycline",
  "Bss",
  "BssDetail",
  "Other",
  "OtherDetail",
  "In",
  "Out",
];

const CardIrrigation = (props: any) => {
  const handleSave = (action: string) => (e: any, v: any) => {
    props.onSave(action)(e, {
      ...v,
      formCode: CARD_IRRIGATION,
      formName: "Irrigation",
      key: IRRIGATION,
    });
  };

  const configIrrigation = () => {
    const config: Record<string, boolean> = {};

    for (const key of configKeys) {
      let is = props.data?.data?.is_irrigation;

      if (key === "BssDetail") {
        is = is && props.data?.data?.is_bss;
      } else if (key === "OtherDetail") {
        is = is && props.data?.data?.is_other_irrigation;
      }

      config[`enabled${key}`] = is;
    }

    return config;
  };

  const handleChangeData = (e: any, v: any) => {
    let data = { ...(props.data?.data || {}) };
    const value = typeof v.checked === "boolean" ? v.checked : v.value;

    if (v.name === "is_irrigation" && !value) {
      data = {
        ...data,
        is_nss: false,
        is_water: false,
        is_glycline: false,
        is_bss: false,
        bss: "",
        is_other_irrigation: false,
        other_irrigation: "",
        irrigation_in: "",
        irrigation_out: "",
      };
    } else if (v.name === "is_bss" && !value) {
      data.bss = "";
    } else if (v.name === "is_other_irrigation" && !value) {
      data.other_irrigation = "";
    }

    data[v.name] = value;

    props.onChangeData?.(e, { name: "", value: data });
  };

  return (
    <>
      <CardIrrigationUX
        // data
        status={props.data?.status}
        isIrrigation={props.data?.data?.is_irrigation}
        isNss={props.data?.data?.is_nss}
        isWater={props.data?.data?.is_water}
        isGlycline={props.data?.data?.is_glycline}
        isBss={props.data?.data?.is_bss}
        bss={props.data?.data?.bss}
        isOtherIrrigation={props.data?.data?.is_other_irrigation}
        otherIrrigation={props.data?.data?.other_irrigation}
        irrigationIn={props.data?.data?.irrigation_in}
        irrigationOut={props.data?.data?.irrigation_out}
        // callback
        onChangeData={handleChangeData}
        // config
        config={{
          ...configIrrigation(),
          hideStatus: checkHideButton(props.data, "STATUS"),
        }}
        // Element
        ButtonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [IRRIGATION]: props.data }}
            type="save"
            cardKey={IRRIGATION}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.SAVE)}
          />
        }
        ButtonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [IRRIGATION]: props.data }}
            type="confirm"
            cardKey={IRRIGATION}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.CONFIRM)}
          />
        }
        ButtonUnConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [IRRIGATION]: props.data }}
            type="unconfirm"
            cardKey={IRRIGATION}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.UNCONFIRM)}
          />
        }
      />
    </>
  );
};

export default React.memo(CardIrrigation);
