import moment from "moment";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";

export const createPDFLabRequest = (props: any) => {
  console.log("createPDFLabRequest props", props);

  import("pdfmake/build/pdfmake").then((make) => {
    import("react-lib/assets/fonts/pdfFonts").then(async (font) => {
      let pdfMake = make.default;
      pdfMake.vfs = font.default;
      pdfMake.fonts = {
        THSarabunNew: {
          normal: "THSarabunNew.ttf",
          bold: "THSarabunNew-Bold.ttf",
          italics: "THSarabunNew-Italic.ttf",
          bolditalics: "THSarabunNew-BoldItalic.ttf",
        },
        Roboto: {
          normal: "Roboto-Regular.ttf",
          bold: "Roboto-Medium.ttf",
          italics: "Roboto-Italic.ttf",
          bolditalics: "Roboto-MediumItalic.ttf",
        },
      };

      var origin = window?.location?.origin;

      const momentDate = moment()

      let startDate = moment(props.startDate, "DD-MM-YYYY")
        .locale("th")
        .format("DD MMMM YYYY");
      let endDate = moment(props.endDate, "DD-MM-YYYY")
        .locale("th")
        .format("DD MMMM YYYY");

      let labRequestList = props.data.map((item: any) => {
        let estimate_time = item.total_estimate_operation_time;
        let hours = estimate_time / 60;
        let rhours = Math.floor(hours);
        let minutes = (hours - rhours) * 60;
        let rminutes = Math.round(minutes);
        let get_time = rhours + ":" + rminutes;
        let total_operation_time = moment(get_time, "hh:mm").format("HH:mm");

        let start_time = item.start_time;
        let start_time_format = moment(start_time, "hh:mm:ss").format("HH:mm");
        //_s from start_time
        let hours_s = start_time_format.split(":")[0]; //Split returns an array
        let minutes_s = start_time_format.split(":")[1];
        let time_s = +hours_s * 60 + +minutes_s;

        let total_time = +estimate_time + +time_s;
        //_t from total_time
        let hours_t = total_time / 60;
        let rhours_t = Math.floor(hours_t);
        let minutes_t = (hours_t - rhours_t) * 60;
        let rminutes_t = Math.round(minutes_t);
        let get_time_t = rhours_t + ":" + rminutes_t;
        let total_endtime = moment(get_time_t, "hh:mm").format("HH:mm");

        let ipd_case = item?.case?.is_ipd_case;
        let admit_order = item?.admit_order;

        let blood_component = item?.blood_request?.blood_component;
        let sp_blood_component = blood_component?.split(" ");

        function printBlood(sp_blood_component: any) {
          let quote = "";
          for (let i = 0; i < sp_blood_component?.length; i++) {
            quote +=
              sp_blood_component?.[i] +
              " " +
              "(" +
              sp_blood_component?.[i + 1] +
              ")" +
              "\n";
            i = i + 2;
          }
          return quote;
        }

        function printBloodComponent(sp_blood_component: any) {
          let result;
          if (sp_blood_component === undefined) {
            result = "-";
          } else {
            result = printBlood(sp_blood_component);
          }
          return result;
        }

        var operation_summary = item?.pre_operation_summary;

        function printOperaion(operation_summary: any) {
          let input = operation_summary?.split("\n");
          if (input?.length > 2) {
            return operation_summary;
          } else {
            let line = input
              ?.find((item: any) => item) // Get First
              ?.split("1. ")
              ?.filter((item: any) => item) // remove ""
              ?.find((item: any) => item); // Get First
            //   ?.split("-")
            // line[0] = "[" + line?.[0] + "]"
            // line = line.join(" ")

            return line;
          }
        }

        return [
          {
            text: [
              { text: item?.start_date, style: "fieldTableData" },
              { text: "\n", style: "fieldTableData" },
              { text: item?.operating_room_no, style: "fieldTable" },
              { text: "\n", style: "fieldTableData" },
              { text: start_time_format, style: "fieldTableData" },
              { text: " - ", style: "fieldTableData" },
              { text: total_endtime, style: "fieldTableData" },
            ],
          },
          {
            text: item?.anesthesia_method_name,
            style: "fieldTableData",
          },
          {
            text: [
              { text: item?.hn, style: "fieldTableData" },
              { text: "\n", style: "fieldTableData" },
              { text: item?.patient_name, style: "fieldTableData" },
              { text: "\n", style: "fieldTableData" },
              { text: "อายุ ", style: "fieldTableData" },
              { text: item?.patient_age, style: "fieldTableData" },
              { text: " ปี", style: "fieldTableData" },
            ],
          },

          {
            ...(ipd_case && admit_order != null
              ? {
                  image: "checkmark",
                  width: 13,
                  alignment: "center",
                }
              : {
                  text: "-",
                  style: "fieldTableData",
                  alignment: "center",
                }),
          },

          {
            ...(item?.teams[0]?.pre_principal_diagnosis[0]?.icd_code
              ? {
                  text: [
                    { text: "[", style: "fieldTableData" },
                    {
                      text: item?.teams[0]?.pre_principal_diagnosis[0]
                        ?.icd_code,
                      style: "fieldTableData",
                    },
                    { text: "]", style: "fieldTableData" },
                    { text: " ", style: "fieldTableData" },
                    {
                      text: item?.teams[0]?.pre_principal_diagnosis[0]
                        ?.icd_term,
                      style: "fieldTableData",
                    },
                  ],
                }
              : {
                  text: "-",
                  style: "fieldTableData",
                }),
          },
          {
            ...(item?.pre_operation_summary
              ? {
                  text: printOperaion(operation_summary),
                  style: "fieldTableData",
                }
              : {
                  text: "-",
                  style: "fieldTableData",
                }),
          },

          {
            ...(item?.teams[0]?.pre_operating_order_item?.procedures[0]
              ?.icd9cm_code
              ? {
                  text: [
                    { text: "[", style: "fieldTableData" },
                    {
                      text: item?.teams[0]?.pre_operating_order_item
                        ?.procedures[0]?.icd9cm_code,
                      style: "fieldTableData",
                    },
                    { text: "]", style: "fieldTableData" },
                    { text: " ", style: "fieldTableData" },
                    {
                      text: item?.teams[0]?.pre_operating_order_item
                        ?.procedures[0]?.icd9cm_term,
                      style: "fieldTableData",
                    },
                  ],
                }
              : {
                  text: "-",
                  style: "fieldTableData",
                }),
          },
          {
            text: [
              { text: item?.primary_doctor_name, style: "fieldTableData" },
              { text: "\n", style: "fieldTableData" },
              {
                text: item?.assistant_primary_doctor_name,
                style: "fieldTableData",
              },
            ],
            style: "fieldTableData",
          },
          {
            text: [
              {
                text: total_operation_time,
                style: "fieldTableData",
              },
            ],
          },
          {
            text: printBloodComponent(sp_blood_component),
            style: "fieldTableData",
          },
        ];
      });

      var docDefinition = {
        defaultStyle: {
          font: "THSarabunNew",
        },
        pageMargins: [0, 75, 0, 50],
        pageSize: "A4",
        pageOrientation: "landscape",
        styles: {
          fieldHead: {
            bold: true,
            fontSize: 20,
          },
          fieldOPHead: {
            bold: true,
            fontSize: 16,
          },
          fieldTable: {
            bold: true,
            fontSize: 16,
          },
          fieldTableData: {
            fontSize: 16,
          },
          fieldPrintDate: {
            fontSize: 16,
          },
          tableHeader: {
            fillColor: "white",
          },
          tableNormal: {
            margin: [0, 5, 0, 5],
          },

          tableExample: {
            margin: [0, 0, 0, 0],
          },
        },
        header: {
          margin: [0, 8, 10, 0],

          stack: [
            {
              columns: [
                {
                  image: "logo",
                  width: 75,
                  margin: [8, 0, 0, 0],
                  // absolutePosition: { x: 3, y: 1.2 },
                },
                {
                  text: "โรงพยาบาลคณะทันตแพทย์ศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
                  style: "fieldHead",
                  margin: [13, 0, 0, 0],
                },
                {
                  text: [
                    { text: "วันที่พิมพ์:", style: "fieldPrintDate" },
                    { text: ` ${formatDate(momentDate)} `, style: "fieldPrintDate" },
                    { text: "เวลา:", style: "fieldPrintDate" },
                    { text: ` ${momentDate.format("HH:mm")} `, style: "fieldPrintDate" },
                  ],
                  absolutePosition: { x: 670, y: 11.4 },
                  //alignment: "right",
                },
              ],
              lineHeight: 1,
            },
            {
              columns: [
                {
                  canvas: [
                    {
                      type: "line",
                      lineColor: "#E25F9E",
                      x1: 0,
                      y1: 5,
                      x2: 890,
                      y2: 5,
                      lineWidth: 2,
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  text: [
                    {
                      text: "OPERATIVE SCHEDULE    ",
                      style: "fieldOPHead",
                    },
                    //{ text: "             ", style: "fieldOPHead" },
                    { text: " ประจำวันที่", style: "fieldPrintDate" },
                    { text: ` ${startDate} `, style: "fieldPrintDate" },
                    { text: " - ", style: "fieldPrintDate" },
                    { text: ` ${endDate} `, style: "fieldPrintDate" },
                  ],
                  alignment: "center",
                  relativePosition: { x: 0, y: 5 },
                },
              ],
            },
          ],
        },

        images: {
          logo: origin + "/static/images/logochula_dent.jpg",
          queue: origin + "/static/images/menus/queue.png",
          checkmark: origin + "/static/images/checkmark.png",
          //style: "header",
        },

        content: [
          // { stack: [...columns] },
          {
            columns: [
              { width: "*", text: "" },
              {
                style: "tableExample",
                absolutePosition: { x: 7, y: 75 },
                table: {
                  headerRows: 1,
                  widths: [
                    "9%",
                    "4%",
                    "13%",
                    "4%",
                    "12%",
                    "16.5%",
                    "15%",
                    "12.5%",
                    "6%",
                    "7%",
                  ],
                  body: [
                    [
                      {
                        text: [
                          { text: "วันที่ผ่าตัด/\n", style: "fieldTable" },
                          { text: "Room", style: "fieldTable" },
                        ],
                      },

                      { text: "ANES", style: "fieldTable" },
                      { text: "ชื่อ-สกุล", style: "fieldTable" },
                      { text: "ADM", style: "fieldTable" },
                      {
                        text: [
                          { text: "Principal\n", style: "fieldTable" },
                          { text: "Diagnosis", style: "fieldTable" },
                        ],
                      },
                      { text: "Operation", style: "fieldTable" },
                      { text: "ICD9", style: "fieldTable" },
                      {
                        text: [
                          { text: "Staff/\n", style: "fieldTable" },
                          { text: "Assistant", style: "fieldTable" },
                        ],
                      },
                      {
                        text: [
                          { text: "เวลาที่ใช้\n", style: "fieldTable" },
                          { text: "[HH:MM]", style: "fieldTable" },
                        ],
                      },
                      { text: "Blood Request", style: "fieldTable" },
                    ],
                    ...labRequestList,
                  ],
                },
              },

              { width: "*", text: "" },
            ],
          },
        ],
        footer: function (currentPage: number, pageCount: number) {
          return {
            margin: [12, 20, 5, 20],
            columns: [
              {
                width: 775,
                text: [
                  {
                    text: [
                      { text: " วันที่พิมพ์:", style: "fieldPrintDate" },
                      { text: ` ${formatDate(momentDate)} `, style: "fieldPrintDate" },
                      { text: "[", style: "fieldPrintDate" },
                      { text: `${momentDate.format("HH:mm")}`, style: "fieldPrintDate" },
                      { text: "]", style: "fieldPrintDate" },
                      { text: "         ", style: "fieldPrintDate" },
                    ],
                    alignment: "right",
                    //absolutePosition: { x: 331, y: 9.7 },
                    //alignment: "right",
                  },
                ],
              },
              {
                text: [
                  {
                    text: [
                      {
                        text:
                          " หน้าที่" + currentPage.toString() + "/" + pageCount,
                        style: "fieldPrintDate",
                      },
                    ],
                    alignment: "left",
                  },
                ],
              },
            ],
          };
        },
      };

      pdfMake.createPdf(docDefinition as any).open();
    });
  });
};
