import WasmController from "react-lib/frameworks/WasmController";
// apis
import DivisionList from "issara-sdk/apis/DivisionList_core";
import DoctorNoteOrderDetail from "issara-sdk/apis/DoctorNoteOrderDetail_apps_DPO";
import DoctorNoteOrderList from "issara-sdk/apis/DoctorNoteOrderList_apps_DPO";
import UserPermissionView from "issara-sdk/apis/UserPermissionView_users";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  planningData?: any;
  // common
  selectedEmr?: any;

  // permission
  drNoteOrderPermission?: any;

  // sequence
  DoctorNoteOrderSequence?: {
    sequenceIndex?: string | null;

    // data
    id?: number | null;
    detail?: string;
    is_out_perform_div: boolean;
    note?: string;
    out_perform_div?: number | null;
    plan_item?: any;

    // config
    readOnly?: boolean;
    chkCont: { checked: boolean; enabled: boolean };
    selected_out_perform_div?: number | null;
  } | null;

  // options
  drNoteDivisionOptions?: any[];
  // แก้ไข doctornoteorder
  doctorNoteOrderEditId?: number | null;
};

export const StateInitial: State = {
  // permission
  drNoteOrderPermission: null,

  // sequence
  DoctorNoteOrderSequence: {
    sequenceIndex: null,

    // data
    id: null,
    detail: "",
    is_out_perform_div: false,
    note: "",
    out_perform_div: null,
    plan_item: null,

    // config
    readOnly: false,
    chkCont: { checked: false, enabled: true },
    selected_out_perform_div: null,
  },

  // options
  drNoteDivisionOptions: [],
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  masterData?: any;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const InitData: Handler = async (controller, params) => {
  const getPermission = UserPermissionView.post({
    apiToken: controller.apiToken,
    data: {
      config_core_ENABLE_OUT_PERFORM_DIV: false,
    },
  });

  const [permission] = await Promise.all([
    getPermission,
  ]);

  const state = controller.getState()

  controller.setState({
    DoctorNoteOrderSequence: {
      sequenceIndex: "AddOrEdit",

      // data
      id: null,
      detail: "",
      is_out_perform_div: false,
      note: "",
      out_perform_div: null,
      plan_item: null,

      // config
      readOnly: false,
      chkCont: { checked: false, enabled: true },
      selected_out_perform_div: null,
    },
    drNoteOrderPermission: permission[0],
    drNoteDivisionOptions: controller.data.masterData.division || [],
    planningData: {}
  },()=>{
    // เมื่อมีการกด edit มาจากรายการ doctornote tab order summary
    if (!!state.doctorNoteOrderEditId) {
      controller.setState({ doctorNoteOrderEditId: null })

      AddOrEdit(controller, { action: "FETCH", pk: state.doctorNoteOrderEditId });
    }
  });
};

export const AddOrEdit: Handler = async (controller, params) => {
  var state = controller.getState();

  if (!state.DoctorNoteOrderSequence || !state.selectedEmr) {
    return;
  }

  if (params?.action === "FETCH") {
    const [respFet, errFet, _netwFet] = await DoctorNoteOrderDetail.retrieve({
      pk: params.pk,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (!errFet) {
      const selectedOutPerformDiv = state.drNoteDivisionOptions?.filter(
        (item: any) => item?.id === respFet?.out_perform_div
      );

      controller.setState({
        DoctorNoteOrderSequence: {
          sequenceIndex: "AddOrEdit",
          ...respFet,
          readOnly: respFet?.nurse_status !== "รอรับคำสั่ง",
          chkCont: {
            checked: respFet?.plan_item?.date_time_summary,
            enabled: false,
          },
          selected_out_perform_div: selectedOutPerformDiv?.[0],
        },
      });
    }
  } else if (params?.action === "REQUEST") {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "LOADING" },
    });

    const [respReq, errReq, _netwReq] = await DoctorNoteOrderList.create({
      data: {
        ...state.DoctorNoteOrderSequence,
        emr: state.selectedEmr?.id,
        action: params.action,
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });
    if (errReq) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: errReq },
        buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "ERROR" },
      });
    } else {
      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "SUCCESS" },
      });

      AddOrEdit(controller, { action: "FETCH", pk: respReq.id });
    }
  } else if (params?.action === "EDIT") {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "LOADING" },
    });
    
    const [respEdit, errEdit, _netwEdit] = await DoctorNoteOrderDetail.update({
      pk: state.DoctorNoteOrderSequence.id,
      data: {
        ...state.DoctorNoteOrderSequence,
        emr: state.selectedEmr?.id,
        action: params.action,
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });
    if (errEdit) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: errEdit },
        buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "ERROR" },
      });
    } else {
      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "SUCCESS" },
      });

      AddOrEdit(controller, { action: "FETCH", pk: respEdit.id });
    }
  }
};
