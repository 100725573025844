import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Button,
  Checkbox,
  Dropdown,
  Input
} from 'semantic-ui-react'

const CardSurgeryTeamUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "1rem", backgroundColor: "#D6ECF33B"}}>
        <div
          style={{display: "grid", gridTemplateColumns: "115px 1fr"}}>
          
          <div
            className="ui form"
            style={{paddingTop: "8px"}}>
            
            <Checkbox
              checked={props.isMain || false}
              label="Main Team"
              name="is_main"
              onChange={props.onChangeValue}>
            </Checkbox>
          </div>
          <div
            className="ui form">
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{padding: 0, width: "127px"}}>
                <label
                  style={{fontWeight: "bold", margin: 0}}>
                  แพทย์ผ่าตัด
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <div
                  style={{width: "100%"}}>
                  {props.SurgeonElement}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={8}>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Button
                  color={"red"}
                  fluid={true}
                  onClick={props.onDeleteTeam}
                  style={{minWidth: "max-content"}}>
                  Delete Team
                </Button>
              </FormField>
            </FormGroup>
            <FormGroup>
              <FormField
                inline={true}
                style={{width: "110px", paddingTop: "0.55rem", paddingRight: 0}}>
                <label>
                  แพทย์ผ่าตัดร่วม
                </label>
              </FormField>
              <FormField
                inline={true}
                width={14}>
                <div
                  style={{width: "100%"}}>
                  {props.AssistantsElement}
                </div>
              </FormField>
            </FormGroup>
          </div>
        </div>
        <div
          style={{display: "grid", gridTemplateColumns: "115px 1fr", marginTop: "-1rem"}}>
          
          <div
            className="ui form"
            style={{display: "flex", alignItems: "center"}}>
            
            <label
              style={{fontWeight: "bold"}}>
              Implant
            </label>
          </div>
          <div
            className="ui form">
            
            <FormField
              inline={true}
              width={5}>
              <Dropdown
                allowAdditions={true}
                multiple={true}
                name="implant"
                onAddItem={props.onAddition}
                onChange={props.onChangeValue}
                options={props.orImplantOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.implant || []}>
              </Dropdown>
            </FormField>
          </div>
        </div>
        <div
          style={{display: "grid", gridTemplateColumns: "115px 1fr", marginTop: "1rem"}}>
          
          <div
            className="ui form"
            style={{display: "flex", alignItems: "center"}}>
            
            <label
              style={{fontWeight: "bold"}}>
              Operation
            </label>
          </div>
          <div
            className="ui form"
            style={{border: "1px solid rgba(214,236,243,1)", padding: "1rem 0.5rem"}}>
            
            <FormGroup
              style={{marginBottom: 0}}>
              <FormField
                inline={true}
                style={{minWidth: "max-content"}}
                width={2}>
                <label
                  style={{fontWeight: "bold", paddingTop: "8px", margin: 0}}>
                  รายการหัตถการ
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </FormField>
              <FormField
                inline={true}
                width={14}>
                <div
                  style={{width: "100%"}}>
                  {props.TreatmentElement}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{fontWeight: "bold"}}>
                  Other
                </label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Input
                  name="pre_operating_order_item.other_treatment"
                  onChange={props.onChangeValue}
                  placeholder="Other"
                  value={props.otherTreatment || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}>
                <label
                  style={{fontWeight: "bold"}}>
                  Position
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Dropdown
                  fluid={true}
                  name="pre_operating_order_item.position"
                  onChange={props.onChangeValue}
                  options={props.otherTrtPositionOptions || []}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.position || ""}>
                </Dropdown>
              </FormField>
              <FormField>
                <Input
                  name="pre_operating_order_item.other_position"
                  onChange={props.onChangeValue}
                  placeholder="Other position"
                  style={{display: props.config?.showOtherPosition ? "": "none"}}
                  value={props.otherPosition || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}>
                <label
                  style={{fontWeight: "bold"}}>
                  Site
                </label>
              </FormField>
              <FormField
                inline={true}>
                <Checkbox
                  checked={props.siteRight || false}
                  label="Right"
                  name="pre_operating_order_item.site_right"
                  onChange={props.onChangeValue}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}>
                <Checkbox
                  checked={props.siteLeft || false}
                  label="Left"
                  name="pre_operating_order_item.site_left"
                  onChange={props.onChangeValue}>
                </Checkbox>
              </FormField>
            </FormGroup>
            <div
              style={{width: "100%"}}>
              {props.ProvisionDiagElement}
            </div>
          </div>
        </div>
        <div
          style={{display: "grid", gridTemplateColumns: "115px 1fr", marginTop: "1rem"}}>
          
          <div
            className="ui form"
            style={{display: "flex", alignItems: "center",paddingRight: "55px"}}>
            
            <label
              style={{fontWeight: "bold",textAlign: "center", padding: "-1px"}}>
              Preoperative diagnosis
            </label>
          </div>
          <div
            className="ui form"
            style={{border: "1px solid rgba(214,236,243,1)", padding: "1rem 0.5rem"}}>
            
            <div
              style={{width: "100%"}}>
              {props.PreoperativeDiagElement}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardSurgeryTeamUX

export const screenPropsDefault = {}

/* Date Time : Wed Jun 21 2023 04:38:58 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"1rem\", backgroundColor: \"#D6ECF33B\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"115px 1fr\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"115px 1fr\", marginTop: \"-1rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"115px 1fr\", marginTop: \"1rem\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"115px 1fr\", marginTop: \"1rem\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"8px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0, width: \"127px\"}"
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", margin: 0}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "Delete Team"
        },
        "color": {
          "type": "code",
          "value": "\"red\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onDeleteTeam"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Checkbox",
      "parent": 5,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isMain || false"
        },
        "label": {
          "type": "value",
          "value": "Main Team"
        },
        "name": {
          "type": "value",
          "value": "is_main"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "Implant"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormField",
      "parent": 32,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Dropdown",
      "parent": 34,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "implant"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddition"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.orImplantOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.implant || []"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{border: \"1px solid rgba(214,236,243,1)\", padding: \"1rem 0.5rem\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "Operation"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormGroup",
      "parent": 37,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: 0}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormGroup",
      "parent": 37,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormField",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "label",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการหัตถการ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", paddingTop: \"8px\", margin: 0}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "label",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormGroup",
      "parent": 6,
      "props": {
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 52,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"110px\", paddingTop: \"0.55rem\", paddingRight: 0}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 52,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "label",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ผ่าตัดร่วม"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "code",
          "value": "props.AssistantsElement"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 44,
      "props": {
        "children": {
          "type": "code",
          "value": "props.TreatmentElement"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormField",
      "parent": 40,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 40,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 40,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 40,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 40,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 40,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 40,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "Other"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Input",
      "parent": 59,
      "props": {
        "name": {
          "type": "value",
          "value": "pre_operating_order_item.other_treatment"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "placeholder": {
          "type": "value",
          "value": "Other"
        },
        "value": {
          "type": "code",
          "value": "props.otherTreatment || \"\""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "Position"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Dropdown",
      "parent": 61,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "pre_operating_order_item.position"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.otherTrtPositionOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.position || \"\""
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "label",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": "Site"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Checkbox",
      "parent": 63,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.siteRight || false"
        },
        "label": {
          "type": "value",
          "value": "Right"
        },
        "name": {
          "type": "value",
          "value": "pre_operating_order_item.site_right"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Checkbox",
      "parent": 64,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.siteLeft || false"
        },
        "label": {
          "type": "value",
          "value": "Left"
        },
        "name": {
          "type": "value",
          "value": "pre_operating_order_item.site_left"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ProvisionDiagElement"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\",paddingRight: \"55px\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{border: \"1px solid rgba(214,236,243,1)\", padding: \"1rem 0.5rem\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "Preoperative diagnosis"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",textAlign: \"center\", padding: \"-1px\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 74,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreoperativeDiagElement"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SurgeonElement"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 40,
      "props": {
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Input",
      "parent": 78,
      "props": {
        "name": {
          "type": "value",
          "value": "pre_operating_order_item.other_position"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "placeholder": {
          "type": "value",
          "value": "Other position"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.showOtherPosition ? \"\": \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.otherPosition || \"\""
        }
      },
      "seq": 79,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSurgeryTeamUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
