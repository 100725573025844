import moment from "moment";

const getBase64ImageFromURL = (url: string) => {
  // create an off-screen canvas
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // set its dimension to target size
  canvas.width = 150;
  canvas.height = 150;

  const image = new Image();
  image.src = url;

  // draw source image into the off-screen canvas:
  ctx?.drawImage(image, 0, 0);

  // encode image to data-uri with base64 version of the image
  return canvas.toDataURL("image/png;base64");
};

const FormHomeMed = (props: any) => {
  return {
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 12,
    },
    pageMargins: [15, 190, 0, 50],
    pageSize: "A4",
    styles: {
      title: {
        bold: true,
        fontSize: 20,
        alignment: "center",
      },
      header: {
        fontSize: 18,
      },
      drugHeader: {
        fontSize: 16,
      },
      drugDetail: {
        fontSize: 14,
      },
      addressRightStyle: {
        fontSize: 12,
        alignment: "right",
      },
      addressLeftStyle: {
        fontSize: 12,
        alignment: "left",
      },
    },
    header: {
      margin: [40, 20, 20, 20],
      stack: [
        {
          columns: [
            {
              width: "50%",
              stack: [{ image: "logochula_dent", width: 150 }],
            },
            {
              width: "50%",
              stack: [
                {
                  text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
                  style: "addressRightStyle",
                },
                {
                  text: [
                    {
                      text: "ที่อยู่ : ",
                      style: "addressRightStyle",
                      bold: true,
                    },
                    {
                      text: "34  ถนนอังรีดูนังต์  แขวงวังใหม่  เขตปทุมวัน",
                      style: "addressRightStyle",
                    },
                  ],
                },
                {
                  text: "กรุงเทพมหานคร  10330  โทร 0 2218 8635",
                  style: "addressRightStyle",
                },
              ],
            },
          ],
        },
        {
          text: "ใบแสดงรายการยากลับบ้าน",
          style: "title",
        },
        {
          stack: [
            {
              layout: "noBorders",
              table: {
                widths: ["25%", "25%", "*"],
                body: [
                  [
                    {
                      text: [
                        {
                          text: "HN: ",
                          style: "header",
                          bold: true,
                        },
                        {
                          text: props.selectedPatient?.hn || "",
                          style: "header",
                        },
                      ],
                    },
                    { text: "", style: "header", bold: true },
                    {
                      text: [
                        {
                          text: "ชื่อ-สกุล: ",
                          style: "header",
                          bold: true,
                        },
                        {
                          text: props.selectedPatient?.full_name || "",
                          style: "header",
                        },
                      ],
                    },
                  ],
                  [
                    {
                      text: [
                        {
                          text: "เพศ: ",
                          style: "header",
                          bold: true,
                        },
                        {
                          text: props.selectedPatient?.gender_name || "",
                          style: "header",
                        },
                      ],
                    },
                    {
                      text: [
                        {
                          text: "อายุ: ",
                          style: "header",
                          bold: true,
                        },
                        {
                          text: `${props.selectedPatient?.age} ปี` || "",
                          style: "header",
                        },
                      ],
                    },
                    {
                      text: [
                        {
                          text: "ข้อมูลการแพ้: ",
                          style: "header",
                          bold: true,
                        },
                        {
                          text: props.selectedPatient?.allergy || "-",
                          style: "header",
                          color: props.selectedPatient?.allergy
                            ? "red"
                            : "black",
                        },
                      ],
                    },
                  ],
                ],
              },
            },
          ],
        },
        {
          text: [
            {
              text: "วัน/เดือน/ปีเกิด: ",
              style: "header",
              bold: true,
            },
            {
              text: props.selectedPatient?.birthdate || "",
              style: "header",
            },
          ],
        },
      ],
    },
    content: [
      {
        margin: [20, 0, 10, 10],
        table: {
          widths: ["30%", "*"],
          body: [
            ...(props?.selectedDrugOrder?.items || []).map((item: any) => [
              item.drug_image
                ? {
                    image: item.drug_image,
                    width: 150,
                    alignment: "center",
                  }
                : {
                    text: "",
                  },
              {
                stack: [
                  {
                    columns: [
                      {
                        width: "*",
                        text: item.drug_label_name || "",
                        style: "drugHeader",
                        bold: true,
                        alignment: "left",
                      },
                      {
                        width: "*",
                        text: `จำนวน ${item.drug_quantity || ""} ${
                          item.stock_unit_name || ""
                        }\n\n`,
                        style: "drugHeader",
                        bold: true,
                        alignment: "right",
                      },
                    ],
                  },
                  {
                    text: `${item.label?.replace("\n", " ") || ""}\n\n\n`,
                    style: "drugHeader",
                  },
                  {
                    text: item.drug_indication || "",
                    style: "drugDetail",
                  },
                  {
                    text: item.helper_label || "",
                    style: "drugDetail",
                  },
                ],
              },
            ]),
          ],
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      const pageText = "หน้าที่ " + currentPage.toString() + "/" + pageCount;
      const printDateMM = moment(props?.selectedDrugOrder?.printed?.datetime);
      const printDate = `วันเวลาที่พิมพ์ ${printDateMM.format(
        "DD/MM/YYYY"
      )} [${printDateMM.format("HH:mm")}]`;
      return {
        stack: [
          {
            margin: [40, 0, 20, 0],
            columns: [
              {
                width: "50%",
                stack: [
                  {
                    text: printDate,
                    style: "addressLeftStyle",
                  },
                  {
                    text: props?.selectedDrugOrder?.printed?.name || "",
                    style: "addressLeftStyle",
                  },
                ],
              },
              {
                width: "50%",
                stack: [
                  {
                    text: pageText,
                    style: "addressRightStyle",
                  },
                ],
              },
            ],
          },
        ],
      };
    },
    images: {
      logochula_dent: origin + "/static/images/logochula_dent.jpg",
    },
  };
};

export default FormHomeMed;
