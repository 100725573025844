import React, { useEffect, useState } from "react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { ModConfirm } from "react-lib/apps/common";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import { Button, Input, Modal } from "semantic-ui-react";
import CardFinancialAmountSetUX from "./CardFinancialAmountSetUX";
import ModCoverageSelect from "./ModCoverageSelect"
import { alignCenter, formatPrice } from "react-lib/appcon/common/PureReactTable";

const CARD_FINANCIAL_AMOUNT_SET = "CardFinancialAmountSet"

const CardFinancialAmountSet = (props: any) => {
    const [modalConfirm, setModalConfirm] = useState<any>({ open: false, item: {} })

    useEffect(() => {
        props.runSequence({ sequence: "FinancialAmountSet", restart: true, card: CARD_FINANCIAL_AMOUNT_SET });
        return () => {
            props.runSequence({ sequence: "FinancialAmountSet", clear: true, card: CARD_FINANCIAL_AMOUNT_SET });
        };
    }, [])

    const handleOnChange = (name: string, value: any) => {
        // console.log("handleOnChange: ", name, value, Number.isNaN(Number.parseFloat(value)))
        if (name === "amount" && (value === "" || value > -1)) {
            props.setProp(`FinancialAmountSetSequence.amount`, value)
        }
        if (name !== "amount") {
            props.setProp(`FinancialAmountSetSequence.${name}`, value)
        }

    }

    const financialAmountList = React.useMemo(() => {
        return (props.financialAmountList || []).map((item: any) => ({
            ...item,
            coverage_name_text: alignCenter(item.coverage_name),
            payer_name_text: alignCenter(item.payer_name),
            service_type_text: alignCenter(item.service_type),
            start_date_text: alignCenter(item.start_date),
            stop_date_text: alignCenter(item.stop_date),
            max_reimb_text: formatPrice(item.max_reimb),
            _delete: (<div style={{width: "100%", textAlign: "center"}}>
                <Button
                    icon="minus"
                    color="red"
                    size="mini"
                    onClick={() => {
                        console.log("item: ", item)
                        setModalConfirm({ open: true, item: { ...item } })
                    }}
                />
            </div>
            )
        }))
    }, [props.financialAmountList])

    const handleConfirm = () => {
        props.runSequence({
            sequence: "FinancialAmountSet",
            action: "delete",
            card: CARD_FINANCIAL_AMOUNT_SET, data: { ...modalConfirm.item }
        })
        setModalConfirm({ open: false, item: {} })
    }

    const handleCancel = () => {
        setModalConfirm({ open: false, item: {} })
    }

    console.log("CardFinancialAmountSet props: ", props)
    return <>
        <SnackMessage
            onEvent={props.onEvent}
            onClose={() => {
                props.setProp(`errorMessage.${CARD_FINANCIAL_AMOUNT_SET}`, null);
            }}
            error={props.errorMessage?.[CARD_FINANCIAL_AMOUNT_SET]?.error}
            success={null}
        />
        <CardFinancialAmountSetUX
            // data
            // amount={props.FinancialAmountSetSequence?.amount}
            financialAmountData={financialAmountList}
            // function
            onSelectedReim={(state: any,
                rowInfo: any,
                column: any,
                instance: any) => ({
                    style: {
                        backgroundColor:
                            props.FinancialAmountSetSequence?.selectedFinancialAmount && props.FinancialAmountSetSequence?.selectedFinancialAmount?.id && rowInfo?.original?.id === props.FinancialAmountSetSequence?.selectedFinancialAmount?.id
                                ? "#cccccc"
                                : "white",
                    },
                    onClick: (e: any) => {
                        props.runSequence({ sequence: "FinancialAmountSet", action: "selectPatientReim", card: CARD_FINANCIAL_AMOUNT_SET, data: rowInfo?.original })
                    }
                })}
            // component
            amount={
                <Input
                    onChange={(e: any, data: any) => { handleOnChange("amount", data.value) }}
                    value={props.FinancialAmountSetSequence?.amount}
                    step={0.01}
                    type="number"
                />
            }
            patientCoverageSearch={
                <Input
                    readOnly={true}
                    style={{ width: "100%" }}
                    label={
                        <Button
                            disabled={props.FinancialAmountSetSequence?.selectedFinancialAmount?.id}
                            icon="search"
                            onClick={() => { props.runSequence({ sequence: "FinancialAmountSet", action: "openModal", card: CARD_FINANCIAL_AMOUNT_SET }) }}
                        />}
                    labelPosition="right"
                    value={props.FinancialAmountSetSequence?.selectedPatientCoverageDetail}
                />
            }
            buttonSave={
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <ButtonLoadCheck
                        // function
                        setProp={props.setProp}
                        onClick={() => {
                            props.runSequence({ sequence: "FinancialAmountSet", action: "save", card: CARD_FINANCIAL_AMOUNT_SET })
                        }}
                        // data
                        paramKey={`${CARD_FINANCIAL_AMOUNT_SET}_SAVE`}
                        buttonLoadCheck={
                            props.buttonLoadCheck?.[`${CARD_FINANCIAL_AMOUNT_SET}_SAVE`]
                        }
                        // config
                        color={props.FinancialAmountSetSequence?.selectedFinancialAmount?.id ? "yellow" : "green"}
                        name="SAVE"
                        size="small"
                        title={props.FinancialAmountSetSequence?.selectedFinancialAmount?.id ? "แก้ไข" : "เพิ่มรายการ"}
                    />
                    {props.FinancialAmountSetSequence?.selectedFinancialAmount?.id && (
                        <Button
                            content="เคลียร์"
                            size="small"
                            onClick={() => {
                                props.runSequence({ sequence: "FinancialAmountSet", action: "clearData", card: CARD_FINANCIAL_AMOUNT_SET })
                            }}
                        />
                    )}
                </div>
            }
        />
        <ModConfirm
            openModal={modalConfirm.open}
            titleName="ต้องการยกเลิกใช่หรือไม่ ?"
            onDeny={handleCancel}
            onCloseWithDimmerClick={handleCancel}
            onApprove={handleConfirm}
        />

        <Modal
            open={props.modCoverageApprove?.open}
            size="large"
            closeOnDimmerClick={false}
        >
            <ModCoverageSelect
                // data
                patientCoverageList={props.modCoverageApprove?.patientCoverageList}
                // function
                selectedPatientCoverage={props.modCoverageApprove?.selectedPatientCoverage}
                setSelectedPatientCoverage={(coverage: any) => props.setProp('modCoverageApprove.selectedPatientCoverage', coverage)}
                onClickSelectedPatientCoverage={() =>
                    props.runSequence({ sequence: "FinancialAmountSet", action: "selectPatientCoverage", card: CARD_FINANCIAL_AMOUNT_SET })
                }
                onClose={() => props.runSequence({ sequence: "FinancialAmountSet", action: "close", card: CARD_FINANCIAL_AMOUNT_SET })}
            />
        </Modal>
    </>
}

export default CardFinancialAmountSet;