import { useState, useEffect, useMemo, useCallback } from "react";
import { Modal, Label, Item, Button,Header } from "semantic-ui-react";

import { Table } from "react-lib/frameworks/Table";
import moment from "moment";

import CardReturnDrugUX from "./CardReturnDrugUX";
import EditorColumn from "react-lib/appcon/common/EditorColumn";
import DrugActionLog from "react-lib/apps/HISV3/TPD/DrugActionLog";

const CardReturnDrug = (props: any) => {
  const [openModLog, SetOpenModLog] = useState<boolean>(false);
  const handleClose = () => SetOpenModLog(false);
  useEffect(() => {
    return () => {
      props.runSequence({ sequence: "ReturnOrder" });
    };
  }, []);


  const drugOrderList = useMemo(() => {
    return (props.DrugReturnList || []).map((item: any, index: number) => ({
      ...item,
      quantity_request: (
        <EditorColumn
          value={item.quantity_request}
          onDataChanged={(value: string) =>
            handleChangeEdit({ value, index, type: "quantity_request" })
          }
        />
      ),
      no:index+1
    }));
  }, [props.DrugReturnList]);
  
  
    const buttonOrderCode = useMemo(() => {
    return (props.OrderHistory || []).map((item: any, index: number) => (
      <Button 
      color="red" 
      size="mini" 
      content={`REQUEST:${item.code}`} 
      onClick={() =>{
        props.runSequence({
            sequence: "ReturnOrder",
            action: "HistoryRequest",
            orders: item,
          });
      }}
      />
    ));
  }, [props.OrderHistory]);

  
  const drugOrderLog = useMemo(() =>{
    return(props.OrderReturnLog?.items || []).map(
      (item:any) =>({
        ...item,
        datetime:(moment(item.datetime).format("DD/MM/YY HH:mm"))
      })
    )
  },[props.OrderReturnLog?.items ])

  
  const handleChangeEdit = (data: any) => {
    props.runSequence({
      sequence: "ReturnOrder",
      action: "Edit",
      data,
    });
  };

  return (
    <div>
      <CardReturnDrugUX
        drugOrderList={drugOrderList}
        code={props.OrderReturnList?.order_code ||props.OrderReturnList?.code }
        orderCode={props.OrderReturnList?.code}
        patientName={props.OrderReturnList?.patient_name_code}
        divisionOrder={props.OrderReturnList?.requester_name || props.OrderReturnList?.order_div}
        closeIcon={props.closeIcon}
        onClose={props.onClose}
        requesterName={props.OrderReturnList?.requested?.name}
        requestedDivision={props.OrderReturnList?.requested?.division}
        requestedTime={
          moment(new Date(props.OrderReturnList?.requested?.datetime)).format(
            "DD-MM-YYYY [[]HH:mm[]]"
          ) || ""
        }
        buttonOrderCode={buttonOrderCode}
        statusName={props.OrderReturnList?.status_name }
        isRequest={props.OrderReturnList?.status_name ==="REQUESTED"}
        handleOnRequest={() => {
          props.runSequence({
            sequence: "ReturnOrder",
            action: "Request",
            id: props.OrderReturnList?.pk,
          });
        }}
        handleOnNew={() =>{
          props.runSequence({
            sequence: "ReturnOrder",
            action: "New",
            id: props.OrderReturnList?.order,
          });
        }}
        handleOnLog={() => {
          props.runSequence({
            sequence: "ReturnOrder",
            action: "Log",
            id: props.OrderReturnList?.id

          });
          SetOpenModLog(true);
        }}
        handleOnEdit={() =>{
          props.runSequence({
            sequence: "ReturnOrder",
            action: "Update",
            id: props.OrderReturnList?.pk
          });

        }}
      />


    <Modal open={openModLog} size="small" onClose={handleClose}>
      <div style={{padding:"10px"}}>
        <Modal.Header as="h3">
            ประวัติการทำงาน
        </Modal.Header>
        <Table  
          data={drugOrderLog}
          headers="User,Action,Date-Time"
          keys="name,action,datetime"
          minRows="10"
         showPagination={false}
         style={{ height: "300px" }}
        ></Table>
      </div>
    </Modal>


    </div>

   
  );
};

export default CardReturnDrug;
