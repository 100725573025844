import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown,
  Button,
  Radio,
  Input
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardHolidayUX = (props: any) => {
    return(
      <div
        style={{display: "flex"}}>
        <div
          style={{flex: 1, padding: "5px"}}>
          
          <Dropdown
            fluid={true}
            onChange={props.changeDivision}
            options={props.divisionOptions}
            selection={true}
            value={props.selectedDivisionId}>
          </Dropdown>
          <div
            style={{margin: "5px", flex: 1, display: props.hideRadioGroup ? "none" : "flex", justifyContent: "flex-start", alignItems: "center"}}>
            
            <Radio
              checked={props.selected === "normal"}
              label="หยุดคลีนิคทุกวันที่คณะหยุด (ตั้งวันหยุดเพิ่มได้)"
              name="radioGroup"
              onChange={props.onChangeRadio}
              value="normal">
            </Radio>
          </div>
          <div
            style={{margin: "5px", flex: 1, display: props.hideRadioGroup ? "none" : "flex", justifyContent: "flex-start", alignItems: "center"}}>
            
            <Radio
              checked={props.selected === "manual"}
              label="ตั้งวันหยุดคลีนิคเองทั้งหมด (ไม่หยุดตามวันหยุดคณะ)"
              name="radioGroup"
              onChange={props.onChangeRadio}
              value="manual">
            </Radio>
          </div>
          <div
            style={{marginTop: "10px", display: props.hideCopyPaste ? "none":""}}>
            
            <Button
              color="blue"
              size="small">
              สำเนาวันหยุดคณะมาเป็นวันหยุดคลีนิค (Copy & Paste)
            </Button>
          </div>
          <div
            style={{height: "50vh"}}>
            
            <div
              style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(25, 1fr)","gridTemplateColumns":"repeat(30, 1fr)"}}>
              <div
                style={{paddingTop: "5px", textAlign: "right", gridRow: "2/4", gridColumn: "1/12", }}>
                วันที่
              </div>
              <div
                style={{ gridRow: "2/4", gridColumn: "13/31",}}>
                <div>
                  {props.dateTextBox}
                </div>
              </div>
              <div
                style={{paddingTop: "5px", textAlign: "right", gridRow: "8/10", gridColumn: "1/12", }}>
                รายละเอียด
              </div>
              <div
                style={{ gridRow: "8/10", gridColumn: "13/31", }}>
                <Input
                  fluid={true}
                  onChange={props.setHolidayField("detail")}
                  value={props.detail}>
                </Input>
              </div>
              <div
                style={{paddingTop: "5px", textAlign: "right", gridRow: "11/13", gridColumn: "1/12", }}>
                หมายเหตุ
              </div>
              <div
                style={{ gridRow: "11/13", gridColumn: "13/31",}}>
                <Input
                  fluid={true}
                  onChange={props.setHolidayField("remark")}
                  value={props.remark}>
                </Input>
              </div>
              <div
                style={{ gridRow: "14/17", gridColumn: "22/31", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
                <Button
                  color="green"
                  disabled={props.disableUpdate}
                  onClick={props.updateHoliday}
                  size="small"
                  style={{width: "100%", height: "100%"}}>
                  อัพเดท
                </Button>
              </div>
              <div
                style={{ padding: "5px", gridRow: "17/21", gridColumn: "1/31", border:  props.error ? "solid red 1px" : "", color: "red" }}>
                {props.error}
              </div>
              <div
                style={{ gridRow: "14/17", gridColumn: "12/21", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
                <Button
                  color="orange"
                  onClick={props.clearHoliday}
                  size="small"
                  style={{width: "100%", height: "100%"}}>
                  เคลียร์
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{flex: 3, padding: "5px"}}>
          
          <Table
            data={props.holidayItems}
            getTrProps={props.rowProps}
            headers="เดือน,วัน,รายละเอียด,หมายเหตุ,ลบ"
            keys="month,day,detail,remark,deleteButton"
            showPagination={false}
            style={{height: "90vh"}}>
          </Table>
        </div>
      </div>
    )
}

export default CardHolidayUX

export const screenPropsDefault = {}

/* Date Time : Thu Aug 04 2022 02:31:02 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Dropdown",
      "parent": 3,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDivision"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDivisionId"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 11,
      "props": {
        "data": {
          "type": "code",
          "value": "props.holidayItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": "เดือน,วัน,รายละเอียด,หมายเหตุ,ลบ"
        },
        "keys": {
          "type": "value",
          "value": "month,day,detail,remark,deleteButton"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"90vh\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1, padding: \"5px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "สำเนาวันหยุดคณะมาเป็นวันหยุดคลีนิค (Copy & Paste)"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Radio",
      "parent": 10,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.selected === \"normal\""
        },
        "label": {
          "type": "value",
          "value": "หยุดคลีนิคทุกวันที่คณะหยุด (ตั้งวันหยุดเพิ่มได้)"
        },
        "name": {
          "type": "value",
          "value": "radioGroup"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeRadio"
        },
        "value": {
          "type": "value",
          "value": "normal"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"5px\", flex: 1, display: props.hideRadioGroup ? \"none\" : \"flex\", justifyContent: \"flex-start\", alignItems: \"center\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 3, padding: \"5px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"5px\", flex: 1, display: props.hideRadioGroup ? \"none\" : \"flex\", justifyContent: \"flex-start\", alignItems: \"center\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Radio",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.selected === \"manual\""
        },
        "label": {
          "type": "value",
          "value": "ตั้งวันหยุดคลีนิคเองทั้งหมด (ไม่หยุดตามวันหยุดคณะ)"
        },
        "name": {
          "type": "value",
          "value": "radioGroup"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeRadio"
        },
        "value": {
          "type": "value",
          "value": "manual"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"10px\", display: props.hideCopyPaste ? \"none\":\"\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"50vh\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "label": "divgrid",
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(25, 1fr)\",\"gridTemplateColumns\":\"repeat(30, 1fr)\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"5px\", textAlign: \"right\", gridRow: \"2/4\", gridColumn: \"1/12\", }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 19,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"13/31\",}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียด"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"5px\", textAlign: \"right\", gridRow: \"8/10\", gridColumn: \"1/12\", }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 19,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/10\", gridColumn: \"13/31\", }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"5px\", textAlign: \"right\", gridRow: \"11/13\", gridColumn: \"1/12\", }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 19,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/13\", gridColumn: \"13/31\",}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 19,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/17\", gridColumn: \"22/31\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "code",
          "value": "props.error"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", gridRow: \"17/21\", gridColumn: \"1/31\", border:  props.error ? \"solid red 1px\" : \"\", color: \"red\" }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Button",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "อัพเดท"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disableUpdate"
        },
        "onClick": {
          "type": "code",
          "value": "props.updateHoliday"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 19,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/17\", gridColumn: \"12/21\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Button",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "เคลียร์"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "onClick": {
          "type": "code",
          "value": "props.clearHoliday"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Input",
      "parent": 25,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.setHolidayField(\"detail\")"
        },
        "value": {
          "type": "code",
          "value": "props.detail"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Input",
      "parent": 27,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.setHolidayField(\"remark\")"
        },
        "value": {
          "type": "code",
          "value": "props.remark"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "code",
          "value": "props.dateTextBox"
        }
      },
      "seq": 33,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 95,
  "isMounted": false,
  "memo": false,
  "name": "CardHolidayUX",
  "project": "cudent2",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
