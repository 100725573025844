import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Header, Button, Form, Dropdown, Checkbox } from 'semantic-ui-react';
import ReactTable from 'react-table-6'
import CardLayout from '../../../react-lib/apps/common/CardLayout';

var sListDiagnosis = []
const CardTreatHistory = (props) => {
	// Data search
	const [divisions, setDivisions] = useState([])
	const [selectedDivision, setSelectedDivision] = useState(null)
	const [doctors, setDoctors] = useState([])
	const [selectedDoctor, setSelectedDoctor] = useState(null)
	// Data list
	const [diagnosisList, setDiagnosisList] = useState([])
	// Data selected
	const [pDiagnosis, setPDiagnosis] = useState(null)

	const initialDivision = async () => {
		let data = await props.getDivisionList()
		setDivisions([
			{ id: -1, text: '', value: '' },
			...data
		])
	}

	const initialDoctor = async () => {
		let data = await props.getDoctorList()
		setDoctors([
			{ id: -1, text: '', value: '' },
			...data
		])
	}

	/**
	 * Division selected
	 * @param {*} event 
	 * @param {*} data 
	 */
	const changeDivision = (event, data) => {
		let targetValue = data.value
		for (var dv of divisions) {
			if (dv.value === targetValue) {
				setSelectedDivision(dv)
				break
			}
		}
	}

	/**
	 * Doctor selected
	 * @param {*} event 
	 * @param {*} data 
	 */
	const changeDoctor = (event, data) => {
		let targetValue = data.value
		for (var dt of doctors) {
			if (dt.value === targetValue) {
				setSelectedDoctor(dt)
				break
			}
		}
	}

	/**
	 * Handle 'Clear' Button
	 */
	const handleClear = () => {
		setSelectedDivision(null)
		setSelectedDoctor(null)
	}

	/**
	 * Search diagnosis
	 */
	const searchDiagnosis = async () => {
		let params = { isOnlyICD10: true }
		if (selectedDivision && (selectedDivision.id != (-1))) {
			params.divisionId = selectedDivision.id
		}
		if (selectedDoctor && (selectedDoctor.id != (-1))) {
			params.doctorId = selectedDoctor.id
		}

		let data = await props.onSearchDiagnosis(params)
		setDiagnosisList(data)
	}

	/**
	 * Select diagnosis
	 * @param {*} selected 
	 * @param {*} ev 
	 * @param {*} data 
	 */
	const selectDiagnosis = (selected, ev, data) => {
		if (data.checked) {
			switch (selected.type) {
				case DIAGNOSIS_TYPE_PRIMARY:
					setPDiagnosis(selected)
					break

				case DIAGNOSIS_TYPE_SECONDARY:
					sListDiagnosis.push(selected)
					break

				default:
					break
			}
		} else {
			switch (selected.type) {
				case DIAGNOSIS_TYPE_PRIMARY:
					setPDiagnosis(null)
					break

				case DIAGNOSIS_TYPE_SECONDARY:
					var index
					for (index in sListDiagnosis) {
						var diagnosis = sListDiagnosis[index]
						if (selected.id === diagnosis.id) {
							break
						}
					}
					sListDiagnosis.splice(index, 1)
					break

				default:
					break
			}
		}

	}

	/**
	 * Handle 'Select' Button
	 */
	const handleSelect = () => {
		props.onSelectDiagnosis(pDiagnosis, sListDiagnosis)
		props.onClose()
	}

	const columns = [
		{
			Header: '..',
			style: { whiteSpace: 'unset' },
			accessor: 'check',
			width: 35,
			Cell: props => {
				const data = props.original
				var isMultiSelection = true
				var isCheck = false
				if (data.type === DIAGNOSIS_TYPE_PRIMARY) {
					isMultiSelection = false
					if (pDiagnosis) {
						if (pDiagnosis.id === data.id) {
							isCheck = true
						}
					}
				}

				// Rendering
				if (isMultiSelection) {
					return (
						<Checkbox onChange={selectDiagnosis.bind(this, data)} />
					)
				} else {
					return (
						<Checkbox checked={isCheck} onChange={selectDiagnosis.bind(this, data)} />
					)
				}
			}
		},
		{
			Header: 'Date',
			style: { 'whiteSpace': 'unset' },
			accessor: 'date',
			width: 90
		},
		{
			Header: 'Division',
			style: { 'whiteSpace': 'unset' },
			accessor: 'division',
			width: 80
		},
		{
			Header: 'แพทย์',
			style: { 'whiteSpace': 'unset' },
			accessor: 'doctor',
			width: 140
		},
		{
			Header: 'Type',
			style: { 'whiteSpace': 'unset' },
			accessor: 'type_name',
			width: 110
		},
		{
			Header: 'Medical term',
			style: { 'whiteSpace': 'unset' },
			accessor: 'medical_description',
			width: 165
		},
		{
			Header: 'ICD term',
			style: { 'whiteSpace': 'unset' },
			accessor: 'icd_term',
			width: 165
		},
		{
			Header: 'ICD Code',
			style: { 'whiteSpace': 'unset' },
			accessor: 'icd_code',
			width: 80
		},
	]

	useEffect(() => {
		initialDivision()
		initialDoctor()
		sListDiagnosis = []
	}, [])

	useEffect(() => {
		if (!selectedDivision && !selectedDoctor) {
			// search diagnosis without filter
			searchDiagnosis()
		}
	}, [selectedDivision, selectedDoctor])

	return (
		<CardLayout
			titleText={'ข้อมูลการวินิจฉัย (ICD10) ที่ผ่านมา'}
			headerColor={null}
			toggleable={false}
			closeable={true}
			onClose={props.onClose}>

			<Form>
				<Form.Group inline>
					<Form.Field style={{ width: '10%' }} />
					<Form.Field style={{ width: '8%' }}>
						<Header size={'tiny'} textAlign={'right'}>Division</Header>
					</Form.Field>
					<Form.Field style={{ width: '22%' }}>
						<Dropdown
							fluid
							selection
							placeholder='ทั้งหมด'
							options={divisions}
							value={selectedDivision ? selectedDivision.value : ''}
							onChange={changeDivision.bind(this)}
						/>
					</Form.Field>
					<Form.Field style={{ width: '8%' }}>
						<Header size={'tiny'} textAlign={'right'}>แพทย์</Header>
					</Form.Field>
					<Form.Field style={{ width: '32%' }}>
						<Dropdown
							fluid
							selection
							placeholder='ทั้งหมด'
							options={doctors}
							value={selectedDoctor ? selectedDoctor.value : ''}
							onChange={changeDoctor.bind(this)}
						/>
					</Form.Field>
					<Form.Field style={{ width: '10%' }}>
						<Button color={'blue'} onClick={searchDiagnosis}>ค้นหา</Button>
					</Form.Field>
					<Form.Field style={{ width: '10%' }}>
						<Button color={'yellow'} onClick={handleClear}>Clear</Button>
					</Form.Field>
				</Form.Group>
			</Form>

			<ReactTable
				style={{ height: '450px' }}
				noDataText='ไม่มีข้อมูล'
				showPagination={false}
				defaultPageSize={150}
				minRows={14}
				columns={columns}
				data={diagnosisList}
			/>

			<Form style={{ marginTop: '16px' }}>
				<Form.Group inline>
					<Form.Field style={{ width: '70%' }} />
					<Form.Field style={{ width: '15%' }}>
						<Button fluid color={'red'} onClick={props.onClose}>ยกเลิก</Button>
					</Form.Field>
					<Form.Field style={{ width: '15%' }}>
						<Button fluid color={'green'} onClick={handleSelect}>เลือก</Button>
					</Form.Field>
				</Form.Group>
			</Form>

		</CardLayout>
	)
};

const DIAGNOSIS_TYPE_PRIMARY = 1
const DIAGNOSIS_TYPE_SECONDARY = 2

CardTreatHistory.defaultProps = {
	onClose: () => { },
	getDivisionList: () => { return [] },
	getDoctorList: () => { return [] },
	onSearchDiagnosis: () => { return [] },
	onSelectDiagnosis: (pSelected, sSelected) => { }
}

CardTreatHistory.propTypes = {
	onClose: PropTypes.func,
	getDivisionList: PropTypes.func,
	getDoctorList: PropTypes.func,
	onSearchDiagnosis: PropTypes.func,
	onSelectDiagnosis: PropTypes.func
}

export default CardTreatHistory