import axios from 'axios'
import { to } from '../../../utils'
import BaseService from  '../../services/BaseService'
import config from '../../../../config/config'
import { Response, Error } from '../common'
const HOST = `${config.API_HOST}`

export interface DocumentCategorySerializer {
    id?: any,
    code?: any,
    name?: any,
    name_en?: any,
    parent?: any,
    active?: any,
    display_seq?: any,
}


/* ['generics.ListAPIView'] */
/* params:  */
/* data:  */
export const DocumentCategoryList : 
{
  list:     (arg0:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/document-category/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

