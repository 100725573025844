import WasmController from "react-lib/frameworks/WasmController";
// APIs
// REG
import PatientList from "issara-sdk/apis/PatientList_apps_REG";
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";
// USERs
import UserProfileAPI from "issara-sdk/apis/UserProfileAPI_users";
import UserTokenizeView from "issara-sdk/apis/UserTokenizeView_users";
// QUE
import PatientAppointmentView from "issara-sdk/apis/PatientAppointmentView_apps_QUE";
import PatientAppointmentUpdate from "issara-sdk/apis/PatientAppointmentUpdate_apps_QUE";
import DivisionServiceBlockView from "issara-sdk/apis/DivisionServiceBlockView_apps_QUE";
import DivisionServiceBlockDetail from "issara-sdk/apis/DivisionServiceBlockDetail_apps_QUE";
import CreateDSBFromTemplateItems from "issara-sdk/apis/CreateDSBFromTemplateItems_apps_QUE";
import WaitingListList from "issara-sdk/apis/WaitingListList_apps_QUE";
import WaitingListItemList from "issara-sdk/apis/WaitingListItemList_apps_QUE";
import WaitingListItemDetail from "issara-sdk/apis/WaitingListItemDetail_apps_QUE";
import Scheduling from "issara-sdk/apis/Scheduling_apps_QUE";
import DoctorReportList from "issara-sdk/apis/DoctorReportList_apps_QUE";
import AppointmentReportList from "issara-sdk/apis/AppointmentReportList_apps_QUE";
import WaitingQueueReport from "issara-sdk/apis/WaitingQueueReport_apps_QUE";
// import WaitingListAssign from "issara-sdk/apis/WaitingListAssign_apps_QUE";
import DSBExceptionList from "issara-sdk/apis/DSBExceptionList_apps_QUE";
import DSBExceptionDetail from "issara-sdk/apis/DSBExceptionDetail_apps_QUE";
import WaitingListCancelReport from "issara-sdk/apis/WaitingListCancelReport_apps_QUE";
import WaitingListTime from "issara-sdk/apis/WaitingListTime_apps_QUE";
import GetAvailableChairListAPI from "issara-sdk/apis/GetAvailableChairListAPI_apps_QUE";
// PRX
import CreateUpdateScheduleView from "issara-sdk/apis/CreateUpdateScheduleView_apps_PRX";
import ScheduleList from "issara-sdk/apis/ScheduleList_apps_PRX";
import V3DashboardAppointmentView from "issara-sdk/apis/V3DashboardAppointmentView_apps_PRX";
// CORE
import EncounterMedicalRecordList from "issara-sdk/apis/EncounterMedicalRecordList_core";
import ChairList from "issara-sdk/apis/ChairList_core";
import EncounterList from "issara-sdk/apis/EncounterList_core";
import MiscellaneousList from "issara-sdk/apis/MiscellaneousList_core";
import EncounterPatientListOptimized from "issara-sdk/apis/EncounterPatientListOptimized_core";
// APP
import DoctorNoteTemplateList from "issara-sdk/apis/DoctorNoteTemplateList_apps_APP";
import DoctorNoteTemplateGroupList from "issara-sdk/apis/DoctorNoteTemplateGroupList_apps_APP";
// IME
import ImagingOrderEstimate from "issara-sdk/apis/ImagingOrderEstimate_apps_IME";
import ImagingOrderlist from "issara-sdk/apis/ImagingOrderlist_apps_IME";
import ImagingOrderDetail from "issara-sdk/apis/ImagingOrderDetail_apps_IME";
// ORM
import OperatingOrderDetail from "issara-sdk/apis/OperatingOrderDetail_apps_ORM";
import OperatingOrderBundleCreate from "issara-sdk/apis/OperatingOrderBundleCreate_apps_ORM";
// LAB
import CentralLabOrderList from "issara-sdk/apis/CentralLabOrderList_apps_LAB";

import ProviderList from "issara-sdk/apis/ProviderList_penta_appointment";

import { DsbStatus } from "react-lib/apps/Scheduling/common/Model";
import { APPOINTMENT_STATUS } from "react-lib/apps/Scheduling/common/BlockList";

// FORM
import FormAppointmentSummary from "./FormAppointmentSummary";
import FormChartAppointmentSummary from "./FormChartAppointmentSummary";
import FormDentistSchedule from "./FormDentistSchedule";
import FormAppointmentDetail from "./FormAppointmentDetail";
import FormWaitingList from "./FormWaitingList";
import ORAppointmentForm from "./ORAppointmentForm"


import fontkit from "@pdf-lib/fontkit";
import { degrees, PDFDocument } from "pdf-lib";
import moment, { Moment } from "moment";

// Utils
import { adToBe, adToBeWithSetFormat, formatDateToYYYYMMDD } from "react-lib/utils/dateUtils";
import getPdfMake from "react-lib/appcon/common/pdfMake";
import {
  formatADtoBEString,
  YYYYMMDDadStringDateTimeToDateObject,
} from "react-lib/utils/dateUtils";
import { base64toBlob } from "react-lib/apps/HISV3/common/CommonInterface";
import {
  serialToDate,
  DATE_FORMAT,
} from "react-lib/apps/Scheduling/common/Utils";

export type State = {
  loadingStatus?: any;
  errorMessage?: any;
  preOrderList?: any;
  selectedEncounter?: any;
  patientList?: any[];
  patientListMyBplus?: any[];
  operatingBlock?: any[];

  divisionList?: any[];
  selectedEmr?: any;
  selectedPatient?: any;
  chairList?: any[];
  chairExistDSBList?: any[];
  appointmentList?: any[];
  rescheduleList?: any[];
  reconfirmList?: any[];
  waitingList?: any[];
  patientAppointmentList?: any[];
  selectedAppointment?: any;
  filterAppointmentList?: any[];
  blockList?: any[];
  providerBlockList?: any[];
  selectedBlock?: any;
  selectedDivision?: any;
  availableSlots?: any[];
  scheduleTemplates?: any[];
  duplicateAppointment?: any;
  reoccureDuplicateAppointment?: boolean;
  holiday?: any;
  summaryDoctorList?: any[];
  summaryWaitingQueueList?: any[];
  summaryStatisticsList?: any[];
  allWaitingList?: any[];
  waitingQueueList?: any[];
  staticCanCelQueueList?: any[];
  loadingFilterAppList?: boolean;
  loadingPrintAppList?: boolean;
  buttonLoadCheck?: any;
  searchBlockList?: any[];
  encounterPatientList?: any[];
  selectOperatingDSBChange?: boolean;
  
  userTokenize?: {
    // UserTokenize
    token?: any;
    loading?: boolean;
    error?: any;
    employeeName?: any;
    employeeCode?: string | null;
  } | null;
  openModConfirmAddBlock?: {
    datetime: string;
    provider_name: string;
    number: number;
    params: any;
  } | null;
  // imaging
  imagingList?: any[];
  orderImagingList?: any[];
  selectedEncounterId?: number | null;
  selectedImaging?: any;
  selectedOrOrder?: any;

  // drugorder
  drugOrder?: any

  // mod Doctor certificate confirm
  modDoctorCertificate?: {
    open?: boolean;
    narcoticDrugType?: string;
    doctorCertificate?: string;
    openModConfirm?: boolean;
  };

  django?: any;
};

export const StateInitial: State = {
  patientList: [],
  patientListMyBplus: [],
  selectedPatient: null,
  chairList: [],
  chairExistDSBList: [],
  appointmentList: [],
  rescheduleList: [],
  waitingList: [],
  patientAppointmentList: [],
  filterAppointmentList: [],
  selectedAppointment: null,
  blockList: [],
  providerBlockList: [],
  selectedBlock: null,
  selectedDivision: null,
  availableSlots: [],
  scheduleTemplates: [],
  duplicateAppointment: null,
  reoccureDuplicateAppointment: false,
  holiday: null,
  userTokenize: null,
  openModConfirmAddBlock: null,
  summaryDoctorList: [],
  summaryWaitingQueueList: [],
  summaryStatisticsList: [],
  encounterPatientList: [],

  // imaging
  imagingList: [],
  orderImagingList: [],
  selectedEncounterId: null,
  selectedImaging: null,
  selectedOrOrder: null,

  modDoctorCertificate: {
    open: false,
    narcoticDrugType: "",
    doctorCertificate: "",
    openModConfirm: false,
  },
};

export type Event =
  | { message: "SearchPatient"; params: {} }
  | { message: "ClearAppointment"; params: {} }
  | { message: "SelectPatient"; params: { id: number } }
  | { message: "RefreshAppointment"; params: {} }
  | { message: "CreatePatientAppointment"; params: {} }
  | { message: "SelectAppointment"; params: {} }
  | { message: "AssignAppointmentToBlock"; params: {} }
  // | { message: "ClearSelectedBlock"; params: {}}
  | { message: "FilterSchedule"; params: any }
  | { message: "AddBlock"; params: any }
  | { message: "GetChairList"; params: any }
  | { message: "BookChair"; params: any }
  | { message: "SetScheduling"; params: any }
  | { message: "GetListSchedule"; params: any }
  | { message: "CreateUpdateSchedule"; params: any }
  | { message: "Holiday"; params: any }
  | { message: "PrintScheduling"; params: any }
  | { message: "UserTokenize"; params: any }
  | { message: "getDoctorNoteList"; params: any }
  | { message: "getDoctorNoteGroupList"; params: any }
  | { message: "GetSummaryWaitingQueue"; params: {} }
  | { message: "GetSummaryStatistics"; params: {} }
  | { message: "GetSummaryDoctor"; params: {} }
  | { message: "ChangeDivision"; params: {} }
  | { message: "GetChairWithDivisionServiceBlock"; params: {} }
  | { message: "SearchBlockList"; params: {} }
  | {
    message: "HandleActionPreOrderList";
    params: { action: string; id: number };
  }
  | { message: "ImagingRequestOrder"; params: any }
  | { message: "HandleSelectAppointmentByOROrder"; params: any }
  | { message: "GetEncounterWithPatient"; params: any }
  | { message: "HandlePrintORAppointmentForm"; params: any }
  | { message: "CreatePatientAppointmentForBloodBank"; params: any }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
  provider?: number;
  divisionDict?: any;
  chairDict?: any;
  device?: any;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const GetListSchedule: Handler = async (controller, params) => {
  const state = controller.getState();
  const scheduleList = await ScheduleList.list({
    params: { division: params.divisionId },
    apiToken: controller.apiToken,
  });
  // console.log(scheduleList[1] ? scheduleList[1] : scheduleList[0]);
  if (scheduleList[1]) {
    if (params.card) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: scheduleList[1] },
      });
    }
  }
  controller.setState({
    scheduleTemplates: (scheduleList[0]?.items || []).map((item: any) => ({
      ...item,
      start: item?.start_datetime?.split("T")?.[0] || "",
      end: item?.end_datetime?.split("T")?.[0] || "",
    })),
  });
  return scheduleList;
};

export const CreateUpdateSchedule: Handler = async (controller, params) => {
  const state = controller.getState();
  const {
    apiToken,
    division,
    providerTypeCategory,
    startDate,
    endDate,
    patientPerSlot,
    items,
    workOnMonday,
    workOnTuesday,
    workOnWednesday,
    workOnThursday,
    workOnFriday,
    workOnSaturday,
    workOnSunday,
    id,
    confirmed,
    diagRule,
    multi,
    check_overlap,
  } = params?.data;

  let data: any = {};
  if (id) {
    data.id = id;
  }

  if (division) {
    data.division = division;
  }
  if (providerTypeCategory) {
    data.provider_type_category = providerTypeCategory;
  }
  if (startDate) {
    data.start_date = startDate;
  }
  if (endDate) {
    data.end_date = endDate;
  }
  if (patientPerSlot) {
    data.patient_per_slot = patientPerSlot;
  }
  if (items) {
    data.items = items;
  }
  if (diagRule) {
    data.diag_rule = diagRule;
  }
  if (workOnMonday !== undefined) {
    data.work_on_monday = workOnMonday;
  }
  if (workOnTuesday !== undefined) {
    data.work_on_tuesday = workOnTuesday;
  }
  if (workOnWednesday !== undefined) {
    data.work_on_wednesday = workOnWednesday;
  }
  if (workOnThursday !== undefined) {
    data.work_on_thursday = workOnThursday;
  }
  if (workOnFriday !== undefined) {
    data.work_on_friday = workOnFriday;
  }
  if (workOnSaturday !== undefined) {
    data.work_on_saturday = workOnSaturday;
  }
  if (workOnSunday !== undefined) {
    data.work_on_sunday = workOnSunday;
  }
  if (confirmed !== undefined) {
    data.confirmed = confirmed;
  }
  if (multi) {
    data.multi = multi;
  }
  if (typeof check_overlap !== "undefined") {
    data.check_overlap = check_overlap;
  }

  const result = await CreateUpdateScheduleView.post({
    data: data,
    apiToken: controller.apiToken,
  });
  console.log(result[1] ? result[1] : result[0]);
  FilterSchedule(controller, { divisionId: state.selectedDivision.id });
  return result;
};

export const Holiday: Handler = async (controller, params) => {
  const state = controller.getState();
  if (params?.action === "refresh") {
    const holidays = await DSBExceptionList.list({
      params: { division_id: state.selectedDivision?.id },
      apiToken: controller.apiToken,
    });
    console.log(holidays[1] ? holidays[1] : holidays[0]);
    if (holidays[0]?.items?.length && holidays[0].items.length > 0) {
      let holidayItem = holidays[0].items[0];
      holidayItem.items = holidayItem.items
        .map((item: any) => ({
          ...item,
          // day: item.day?.toString(),
          // month: item.month?.toString(),
          ...(item.day &&
            item.month && {
            date: [
              item.day?.toString() + "/" + item.month?.toString() + "/2565",
            ],
          }), // migrate
        }))
        ?.map((item: any) => {
          let { day, month, ...rest } = item;
          return { ...rest };
        });
      controller.setState({
        holiday: holidayItem,
      });
    } else {
      controller.setState({
        holiday: {
          id: null,
          division: state.selectedDivision?.id,
          items: [],
          follow_parent: true,
        },
      });
    }
  } else if (["update", "delete"].includes(params?.action)) {
    let items = state.holiday.items;

    // modify items if applicable
    if (params?.action === "update" && params?.item) {
      const { isNew, date, ...itemToAdd } = params.item;
      let dateList: string[] = date?.map((d: any) => d.replace(/-/g, "/"));
      // console.log('update date: ', date);
      // console.log('update dateList: ', dateList);
      // console.log("update params.item", params.item)
      if (params.item.isNew) {
        // NEW CASE
        items = items.filter((item: any) => {
          // console.log("item check map new item: " , item)
          return !dateList?.includes(item.date?.[0]);
        });

        dateList?.forEach((date: any) => {
          items.push({ ...itemToAdd, date: [date] });
        });
        console.log("New Holiday NEW items: ", items);
      } else {
        // EDIT CASE
        // Append if not have
        dateList?.forEach((date: any) => {
          if (!items?.find((item: any) => item.date?.[0] === date)) {
            items.push({ ...itemToAdd, date: [date] });
          }
        });

        // Edit If match
        items = items.map((item: any) => {
          return dateList?.includes(item.date?.[0])
            ? { ...params.item, date: [item.date?.[0]] }
            : item;
        });

        console.log(" Holiday EDIT items: ", items);
      }
    } else if (params?.action === "delete" && params?.item) {
      items = items.filter(
        (item: any) => !params.item?.date?.includes(item.date?.[0])
      );
    }

    // sort
    items = items.sort(
      (a: any, b: any) =>
        a.date?.[0].replace(/\//g, "") - b.date?.[0].replace(/\//g, "")
    );

    // Add day, month
    items = items.map((item: any) => ({
      ...item,
      day: parseInt(item.date?.[0]?.split("/")[0]),
      month: parseInt(item.date?.[0]?.split("/")[1]),
    }));

    console.log("++++ Holiday items: ", items);

    // Then create or update
    let holiday;
    if (state.holiday?.id) {
      holiday = await DSBExceptionDetail.update({
        pk: state.holiday.id,
        data: {
          ...state.holiday,
          items: items,
        },
        apiToken: controller.apiToken,
      });
    } else {
      holiday = await DSBExceptionList.create({
        data: {
          ...state.holiday,
          items: items,
        },
        apiToken: controller.apiToken,
      });
    }
    console.log(holiday[1] ? holiday[1] : holiday[0]);
    let holidayItem = holiday[0];
    holidayItem.items = holidayItem.items.map((item: any) => ({
      ...item,
      // day: item.day.toString(),
      // month: item.month.toString(),
    }));
    controller.setState({
      holiday: holidayItem,
    });
  }
};

export const SetScheduling: Handler = async (controller, params) => {
  var state = controller.getState();

  if (params?.action === "GetReconfirmAppointment") {
    // Get Reconfirm
    // Issue 55606

    // console.log("SetScheduling  GetReconfirmAppointment ")

    // query parameters:
    // hn: เลข hn ของผู้ป่วย
    // provider_id: provider id ของ หมอ
    // division_id : id ของ หน่วย/แผนก
    // waiting_list: <string>
    // start_date: วันที่เริ่มต้น ในรูปแบบ format DD/MM/YYYY BE
    // end_date: วันที่สิ้นสุด ในรูปแบบ format DD/MM/YYYY BE
    // type: ชนิดของนัดหมาย (id ของ clinicalTermset ของ ประเภทนัดหมาย)

    const reconfirm = await PatientAppointmentView.list({
      params: {
        division_id: params.division_id,
        reconfirm_only: true,
        hn: /\[(.*)\] ?(?= )/g.exec(params?.hn)?.[1] || params?.hn,
        provider_id: params?.provider_id,
        waiting_list: params?.waiting_list,
        start_date: params?.start_date,
        end_date: params?.end_date,
        appointment_related_type: params?.appointment_type,
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      reconfirmList: (reconfirm[0]?.items || []).map((app: any) => ({
        ...app,
        patient_name: app?.patient_pre_name
          ? `${app.patient_pre_name} ${app.patient_first_name} ${app.patient_last_name}`
          : `${app.patient_first_name} ${app.patient_last_name}`,
        provider_name: `${app.display_info?.provider_name}`,
      })),
    });
  } else if (params?.action === "GetRescheduleAppointment") {
    // Get Reschedule
    // Issue 55606

    // query parameters:
    // hn: เลข hn ของผู้ป่วย
    // provider_id: provider id ของ หมอ
    // division_id : id ของ หน่วย/แผนก
    // waiting_list: <string>
    // start_date: วันที่เริ่มต้น ในรูปแบบ format DD/MM/YYYY BE
    // end_date: วันที่สิ้นสุด ในรูปแบบ format DD/MM/YYYY BE
    // type: ชนิดของนัดหมาย (id ของ clinicalTermset ของ ประเภทนัดหมาย)
    const reschedule = await PatientAppointmentView.list({
      params: {
        division_id: state.selectedDivision?.id,
        reschedule_only: true,
        hn: /\[(.*)\] ?(?= )/g.exec(params?.hn)?.[1] || params?.hn,
        provider_id: params?.provider_id,
        waiting_list: params?.waiting_list,
        start_date: params?.start_date,
        end_date: params?.end_date,
        waiting_list_status: params?.type,
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      rescheduleList: (reschedule[0]?.items || []).map((app: any) => ({
        ...app,
        // patient_name: `${app.patient_pre_name} ${app.patient_first_name} ${app.patient_last_name}`,
        patient_name: app?.patient_pre_name
          ? `${app.patient_pre_name} ${app.patient_first_name} ${app.patient_last_name}`
          : `${app.patient_first_name} ${app.patient_last_name}`,
        provider_name: `${app.display_info?.provider_name}`,
      })),
    });
  }
  // Waiting กับ ยกเลิก ใช้ ตัวเดียวกัน เพราะหลังบ้าน ยังไม่มี status
  else if (params?.action === "WaitItems") {
    const promiseArr = params?.item?.map((item: any) => {
      if (item.status !== "APPOINTMENT" && item.status !== "CANCEL") {
        return WaitingListItemDetail.patch({
          pk: item.id,
          data: {
            status: "STALL",
          },
          apiToken: controller.apiToken,
        });
      } else {
        return;
      }
    });
    const waiting = await Promise.all(promiseArr);
    // console.log('params?.selectedWaitingList?.id: ', params?.waitinglist?.id);
    SetScheduling(controller, {
      action: "GetCardPatientAppointmentData",
      waiting_list_id: params?.waitinglist?.id,
    });

    // console.log(" WaitItems !!!");
  } else if (params?.action === "ResumeWaiting") {
    const promiseArr = params?.item?.map((item: any) => {
      if (item.status !== "APPOINTMENT" && item.status !== "CANCEL") {
        return WaitingListItemDetail.patch({
          pk: item.id,
          data: {
            status: "PENDING",
          },
          apiToken: controller.apiToken,
        });
      } else {
        return;
      }
    });
    const resumeWaiting = await Promise.all(promiseArr);

    SetScheduling(controller, {
      action: "GetCardPatientAppointmentData",
      waiting_list_id: params?.waitinglist?.id,
    });
  } else if (params?.action === "RemoveItems") {
    const promiseArr = params?.item?.map((item: any) => {
      if (item.status !== "APPOINTMENT") {
        return WaitingListItemDetail.patch({
          pk: item.id,
          data: {
            status: "CANCEL",
          },
          apiToken: controller.apiToken,
        });
      } else {
        return;
      }
    });
    const resumeItems = await Promise.all(promiseArr);

    SetScheduling(controller, {
      action: "GetCardPatientAppointmentData",
      waiting_list_id: params?.waitinglist?.id,
    });
  } else if (
    params?.action === "SaveAppointment" &&
    state.selectedAppointment?.id
  ) {
    // console.log('SaveAppointment state.selectedAppointment: ', state?.selectedAppointment);

    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: true },
    });

    if (
      state?.selectedAppointment?.orders?.find(
        (item: any) => item?.type === "operatingorder"
      ) &&
      state?.selectedAppointment?.type_display === "นัดหมายผ่าตัด"
    ) {
      /// save
      // save other treatment, lab, imaging บันทึกส่วน
      let orderDict: Record<string, string> = {};
      if (state.preOrderList?.length) {
        for (const item of state.preOrderList) {
          if (item.specific_label_type === "imagingorder") {
            orderDict[item.id] = "imagingorder";
          } else {
            orderDict[item.id] = item.type;
          }
        }
      }

      const a = await PatientAppointmentUpdate.patch({
        pk: state.selectedAppointment.id,
        apiToken: controller.apiToken,
        data: {
          order_dict: orderDict,
        } as any,
      });

      // save ยา
      /// Case OR

      let operatingorder = state?.selectedAppointment?.orders?.find(
        (item: any) => item?.type === "operatingorder"
      );
      // console.log('operatingorder: ', operatingorder);

      const ood = await OperatingOrderDetail.retrieve({
        pk: operatingorder?.id,
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });
      console.log("ood: ", ood);
      const data = {
        drug_operating_order_id: state.drugOrder?.id || null,
        drug_operating_order: state.drugOrder?.items?.length > 0 ? {
          ...state.drugOrder,
          action: state.drugOrder?.id ? "EDIT" : "REQUEST",
          emr: ood[0]?.emr,
          order_status: params.isAppointment ? 1 : 2,
          out_perform_div: (controller.data as any).division,
          order_status_name: params.isAppointment ? "APPOINTMENT" : "PENDING"
        } : {},
        operating_order_id: ood[0].id,
        operating_order: { ...ood[0], action: "EDIT_REQUEST" },
      };

      const result = await OperatingOrderBundleCreate.post({
        // pk: operatingorder?.id,
        apiToken: controller.apiToken,
        data: data,
        extra: {
          division: controller.data.division,
        },
      });


      if (result[1]) {
        if (result[1]?.code === "NEED_VERIFY_ORDER") {
          controller.setState({
            modDoctorCertificate: {
              ...state.modDoctorCertificate,
              open: true,
              narcoticDrugType: result[1]?.detail,
            },
          });
          return;
        } else if (result[1]?.code === "DUPLICATE_DRUG_ORDERS") {
          
        }
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            ...(params.card? {[params.card]: result[1]}: {})
            // [CARD_DUPLICATE_REASON]: result[1],
          },
          loadingStatus: { ...state.loadingStatus, [params.card]: false }
          // successMessage: { ...state.successMessage, [params.card]: null },
        });
        return;
      }

      controller.setState({
        loadingStatus: { ...state.loadingStatus, [params.card]: false },
        ...(!result[1] && {
          drugOrder: {
            ...state.drugOrder,
            id: result[0]?.drug_operating_order?.id,
          },
        }), // TODO: ต้องimplement เรื่องใบยา
      }, async () => {
        if (!data.drug_operating_order_id && result[0]?.drug_operating_order?.id) {
          orderDict = { ...orderDict, [result[0]?.drug_operating_order?.id]: "drugoperatingorder" }
          const a = await PatientAppointmentUpdate.patch({
            pk: state.selectedAppointment.id,
            apiToken: controller.apiToken,
            data: {
              order_dict: orderDict,
            } as any,
          });
        }
        await RefreshAppointment(controller, params);
      });
      return;
    }

    let needReConfirm = state.selectedAppointment?.status === 4;
    console.log("needReConfirm: ", needReConfirm);
    let needReSchedule = state.selectedAppointment?.status === 3;
    console.log("needReSchedule: ", needReSchedule);

    // Ishealth-v3 port into CUDent
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params?.card || ""]: true },
    });
    let orderDict: Record<string, string> = {};

    if (state.preOrderList?.length) {
      for (const item of state.preOrderList) {
        if (item.specific_label_type === "imagingorder") {
          orderDict[item.id] = "imagingorder";
        } else {
          orderDict[item.id] = item.type;
        }
      }
    }

    const date = formatDateToYYYYMMDD(state.selectedAppointment?.date, "-", true);
    console.log("SaveAppointment state.selectedBlock: ", state.selectedBlock);
    console.log(
      "SaveAppointment state.selectedAppointment: ",
      state.selectedAppointment
    );
    console.log("SaveAppointment date: ", date);
    console.log("SaveAppointment params?.estimatedAt: ", params?.estimatedAt);

    // let slashdate = date?.replace(/-/g,'/')
    let ADDateTimeObj = YYYYMMDDadStringDateTimeToDateObject(
      `${date}/${params?.estimatedAt || (state.selectedAppointment?.main_patient_appointment ?
        moment(state.selectedAppointment?.estimated_at_iso).format("HH:mm") : "")}`
    );
    console.log("SaveAppointment  ADDateTimeObj: ", ADDateTimeObj);
    console.log(
      "SaveAppointment  ADDateTimeObj toISO",
      ADDateTimeObj?.toISOString()
    );

    const patientAppointment = await PatientAppointmentUpdate.patch({
      pk: state.selectedAppointment.id,
      data: {
        division_service_block: state.selectedBlock?.doctor_dsb_id,
        // estimated_at: `${date}-${params?.estimatedAt}`,
        estimated_at_iso: ADDateTimeObj?.toISOString(),
        estimated_duration: params?.estimatedDuration || state.selectedAppointment?.main_patient_appointment ? 5 : null,
        order_note: `${params?.orderNote || ""}`,
        order_dict: orderDict,
        ...((needReConfirm || needReSchedule) && { status: 1 }),
        type: state?.selectedAppointment?.type,
        ...(params?.repetition_note && {
          repetition_note: params?.repetition_note,
          ...(state?.userTokenize?.employeeName && {
            user: state?.userTokenize?.employeeName,
          }),
        }),
        // ...(state.selectedBlock?.division_id &&
        //   {
        //     division: state.selectedBlock.division_id,
        //     division_name: state.selectedBlock.division_name,
        //   }
        // ),
        extra: {
          allergy: state?.selectedAppointment?.extra?.allergy,
          premedication: state?.selectedAppointment?.extra?.premedication,
          orderLab: state?.selectedAppointment?.extra?.orderLab,
          orderXray: state?.selectedAppointment?.extra?.orderXray,
        },
      },
      apiToken: controller.apiToken,
    });

    if (patientAppointment[1]?.rep_patient_appointments && params.card) {
      if (state.duplicateAppointment) {
        // ครั้งที่สอง และ ต่อๆ ไป
        controller.setState({
          duplicateAppointment: patientAppointment[1],
          reoccureDuplicateAppointment: true,
          userTokenize: null,
          // errorMessage: { ...state.errorMessage, [params.card]: patientAppointment[1] },
          loadingStatus: {
            ...state.loadingStatus,
            [params?.card || ""]: false,
          },
        });
      } else {
        // ครั้งแรก
        controller.setState({
          duplicateAppointment: patientAppointment[1],
          reoccureDuplicateAppointment: false,
          userTokenize: null,
          // errorMessage: { ...state.errorMessage, [params.card]: patientAppointment[1] },
          loadingStatus: {
            ...state.loadingStatus,
            [params?.card || ""]: false,
          },
        });
      }
      console.log(" patientAppointment", patientAppointment);
      console.log(
        " patientAppointment",
        patientAppointment[2]?.response?.data?.estimated_at?.join(", ")
      );
      return;
    } else {
      controller.setState({
        duplicateAppointment: null,
        errorMessage: { ...state.errorMessage, [params.card]: null },
        loadingStatus: { ...state.loadingStatus, [params?.card || ""]: false },
      });
    }

    console.log(
      patientAppointment[1] ? patientAppointment[1] : patientAppointment[0]
    );
    const block = await DivisionServiceBlockDetail.patch({
      pk:
        state.selectedBlock?.doctor_dsb_id ||
        state.selectedAppointment?.division_service_block,
      data: {
        division: state.selectedDivision?.id,
        full: params?.full || false,
      } as any,
      apiToken: controller.apiToken,
    });
    console.log(block[1] ? block[1] : block[0]);

    // Ishealth-v3 port into CUDent
    await RefreshAppointment(controller, params);

    if (needReConfirm) {
      SetScheduling(controller, { action: "GetReconfirmAppointment" });
      controller.setState({ selectedAppointment: null });
    } else if (needReSchedule) {
      SetScheduling(controller, { action: "GetRescheduleAppointment" });
      controller.setState({ selectedAppointment: null });
    } else {
      SelectAppointment(controller, {
        ...(patientAppointment[0] || state.selectedAppointment),
        isSave: true,
      });
    }
  } else if (params?.action === "GetScheduleTemplate") {
    const scheduleList = await ScheduleList.list({
      params: { division: state.selectedDivision?.id },
      apiToken: controller.apiToken,
    });
    console.log(scheduleList[1] ? scheduleList[1] : scheduleList[0]);
    controller.setState({
      scheduleTemplates: (scheduleList[0]?.items || []).map((item: any) => ({
        ...item,
        start: item?.start_datetime?.split("T")?.[0] || "",
        end: item?.end_datetime?.split("T")?.[0] || "",
      })),
    });
  } else if (params?.action === "CreateDSBFromTemplateItems") {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "LOADING" },
    });
    const dsb = await CreateDSBFromTemplateItems.post({
      data: {
        items: params?.items,
        providers: params?.providers,
        start_date: params?.startDateTime,
        end_date: params?.endDateTime,
        zone: params?.zone?.id,
        only_old_patient: params?.exceptNewPatientAll || null,
      },
      apiToken: controller.apiToken,
    });
    if (dsb[1]) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
      });
    } else {
      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "SUCCESS" },
      });
    }
    console.log(dsb[1] ? dsb[1] : dsb[0]);
    // Refresh schedule
    controller.handleEvent({
      message: "FilterSchedule",
      params: { divisionId: state.selectedDivision?.id },
    });
  } else if (params?.action === "GetCardPatientAppointmentData") {
    // Get Appointment to reschedule (doctor change scheduling)

    console.log("GetCardPatientAppointmentData");
    controller.handleEvent({
      message: "GetMasterData",
      params: {
        masters: [["orCancelNote", {}]],
      },
    } as any);

    const reschedule = await PatientAppointmentView.list({
      params: {
        division_id: state.selectedDivision?.id,
        reschedule_only: true,
      },
      apiToken: controller.apiToken,
    });
    // console.log(reschedule[1] ? reschedule[1] : reschedule[0]);

    // Get Appointment to reconfirm
    // patient can't make it but cannot cancel due to the nature of treatment)
    const reconfirm = await PatientAppointmentView.list({
      params: {
        division_id: state.selectedDivision?.id,
        reconfirm_only: true,
      },
      apiToken: controller.apiToken,
    });
    // console.log(reconfirm[1] ? reconfirm[1] : reconfirm[0]);

    // Get waiting list
    const waitingList = await WaitingListList.list({
      params: {
        division_id: state.selectedDivision?.id,
        limit: 99999,
      },
      apiToken: controller.apiToken,
    });
    // console.log("waitingList: ", waitingList);
    console.log(waitingList[1] ? waitingList[1] : waitingList[0]);

    let wL = (waitingList[0]?.items || []).map((item: any) => ({
      ...item,
      patient_count: item.waiting_list_state?.item_count,
      pending: item.waiting_list_state?.pending,
      ...(isNaN(item.waiting_list_state?.item_count) && {
        patient_count: item?.items?.length || item?.patient_count || 0,
      }),
      ...(isNaN(item.waiting_list_state?.pending) && {
        pending:
          item?.items?.filter((wli: any) => wli.status === "PENDING")?.length ||
          0,
      }),
    }));

    // Remove items ออก เพื่อทดสอบ API ใหม่ของหลง
    wL = wL.map((item: any) => {
      let { items, ...rest } = item;
      return { ...rest };
    });

    // ทำการดึง waitingListITem จาก API 2 ถ้ามี params pass เข้ามา
    if (params?.waiting_list_id) {
      // get
      const waitingListItem = await WaitingListItemList.list({
        params: {
          division_id: state.selectedDivision?.id,
          waiting_list: params.waiting_list_id,
        },
        apiToken: controller.apiToken,
      });
      // console.log('waitingListItem: ', waitingListItem);

      if (Array.isArray(wL) && wL?.length > 0 && waitingListItem?.[0]) {
        let idx = wL?.findIndex(
          (item: any) => item.id === params?.waiting_list_id
        );
        // console.log('idx: ', idx);
        if (idx >= 0) {
          wL[idx].items = waitingListItem[0].items;
        }
      }
    }

    let [r, e, n] = await PatientAppointmentView.list({
      params: {
        division_id: state.selectedDivision?.id,
        limit: 99999,
      },
      apiToken: controller.apiToken,
    });
    console.log("wL: ", wL);
    console.log("PatientAppointmentView r: ", r);

    // Send to ux
    controller.setState({
      patientAppointmentList: r?.items ? r?.items : [],
      rescheduleList: (reschedule[0]?.items || []).map((app: any) => ({
        ...app,
        // patient_name: `${app.patient_pre_name} ${app.patient_first_name} ${app.patient_last_name}`,
        patient_name: app?.patient_pre_name
          ? `${app.patient_pre_name} ${app.patient_first_name} ${app.patient_last_name}`
          : `${app.patient_first_name} ${app.patient_last_name}`,
        provider_name: `${app.display_info?.provider_name}`,
      })),

      reconfirmList: (reconfirm[0]?.items || []).map((app: any) => ({
        ...app,
        // patient_name: `${app.patient_pre_name} ${app.patient_first_name} ${app.patient_last_name}`,
        patient_name: app?.patient_pre_name
          ? `${app.patient_pre_name} ${app.patient_first_name} ${app.patient_last_name}`
          : `${app.patient_first_name} ${app.patient_last_name}`,
        provider_name: `${app.display_info?.provider_name}`,
      })),

      waitingList: wL,
    });
  } else if (params?.action === "AddPatientToWaitingList") {
    console.log(params);
    // Prevent duplicate addition
    if (
      params?.waitingList?.items
        .filter((item: any) => item?.appointment === null)
        .filter((item: any) => item.status !== "CANCEL")
        .map((item: any) => item?.patient)
        .includes(params?.patientId)
    ) {
      console.log("duplicated patient id:", params?.patientId);
      return;
    }

    // Create new waitinglist item
    const seq = Math.max(
      ...[0].concat(
        (params?.waitingList?.items || []).map((item: any) => item?.seq || 0)
      )
    );
    const item = await WaitingListItemList.create({
      data: {
        waiting_list: params?.waitingList?.id,
        patient: params?.patientId,
        provider: params?.provider?.provider_id,
        seq: seq + 1,
      },
      apiToken: controller.apiToken,
    });

    console.log("WaitingListItemList create", item[1] ? item[1] : item[0]);

    // Refresh waiting list after create
    SetScheduling(controller, {
      action: "GetWaitingList",
      selectedWaitingListId: params?.waitingList?.id,
    });
  } else if (params?.action === "CancelAppointment") {
    const cancelPatientApp = await PatientAppointmentUpdate.patch({
      pk: state.selectedAppointment.id,
      data: {
        status: APPOINTMENT_STATUS.CANCEL,
        status_note: JSON.stringify(params?.reason),
        ...(state?.userTokenize?.employeeName? {user: state?.userTokenize?.employeeName} : {})
      },
      apiToken: controller.apiToken,
    });
    controller.setState({
      userTokenize: null,
      selectedAppointment: null,
      selectedBlock: null,
    });
    console.log(
      cancelPatientApp[1] ? cancelPatientApp[1] : cancelPatientApp[0]
    );
    RefreshAppointment(controller, params);
  } else if (params?.action === "CancelAppointmentByPatient") {
    const cancelPatientApp = await PatientAppointmentUpdate.patch({
      pk: state.selectedAppointment.id,
      data: {
        status: APPOINTMENT_STATUS.REJECT,
        status_note: JSON.stringify(params?.reason),
        ...(state?.userTokenize?.employeeName? {user: state?.userTokenize?.employeeName} : {})
      },
      apiToken: controller.apiToken,
    });
    controller.setState({
      userTokenize: null,
      selectedAppointment: null,
      selectedBlock: null,
    });
    console.log(
      cancelPatientApp[1] ? cancelPatientApp[1] : cancelPatientApp[0]
    );
    RefreshAppointment(controller, params);
  } else if (params?.action === "PostponeAppointment") {
    const cancelPatientApp = await PatientAppointmentUpdate.patch({
      pk: state.selectedAppointment.id,
      data: {
        status: APPOINTMENT_STATUS.RECONFIRM,
        status_note: JSON.stringify(params?.reason),
        ...(state?.userTokenize?.employeeName? {user: state?.userTokenize?.employeeName} : {})
      },
      apiToken: controller.apiToken,
    });
    controller.setState({ userTokenize: null });
    console.log(
      cancelPatientApp[1] ? cancelPatientApp[1] : cancelPatientApp[0]
    );
    RefreshAppointment(controller, params);
  } else if (params?.action === "GetWaitingList") {
    // Get waiting list
    const waitingList = await WaitingListList.list({
      params: {
        division_id: state.selectedDivision?.id,
        limit: 99999,
      },
      apiToken: controller.apiToken,
    });
    console.log(waitingList[1] ? waitingList[1] : waitingList[0]);

    let wL = (waitingList[0]?.items || []).map((item: any) => ({
      ...item,
      patient_count: item.waiting_list_state?.item_count,
      pending: item.waiting_list_state?.pending,
      ...(isNaN(item.waiting_list_state?.item_count) && {
        patient_count: item?.items?.length || item?.patient_count || 0,
      }),
      ...(isNaN(item.waiting_list_state?.pending) && {
        pending:
          item?.items?.filter((wli: any) => wli.status === "PENDING")?.length ||
          0,
      }),
      // patient_count: item?.items?.length || item?.patient_count || 0,
      // not_appointed: item?.items?.filter((item: any) => item.appointment === null)?.length || 0,
    }));

    // Remove
    wL = wL.map((item: any) => {
      let { items, ...rest } = item;
      return { ...rest };
    });
    console.log("wL: ", wL);
    // Send to ux

    controller.setState({
      waitingList: wL,
    });

    console.log(
      "GetWaitingList params?.selectedWaitingListId: ",
      params?.selectedWaitingListId
    );
    if (params?.selectedWaitingListId) {
      SetScheduling(controller, {
        action: "GetWaitingListItem",
        waiting_list_id: params?.selectedWaitingListId,
      });
    }
  } else if (params?.action === "GetWaitingListItem") {
    const waitingListItem = await WaitingListItemList.list({
      params: {
        division_id: state.selectedDivision?.id,
        waiting_list: params.waiting_list_id,
      },
      apiToken: controller.apiToken,
    });
    state = controller.getState();
    if (
      Array.isArray(state.waitingList) &&
      state.waitingList?.length > 0 &&
      waitingListItem?.[0]
    ) {
      let idx = state.waitingList?.findIndex(
        (item: any) => item.id === params?.waiting_list_id
      );
      let wL = [...state.waitingList];
      if (idx >= 0) {
        wL[idx].items = waitingListItem[0].items;
      }
      controller.setState({
        waitingList: wL,
      });
    }
  } else if (params?.action === "EditWaitingListItem") {
    // EditWaitingListItem
    const promiseArr = params?.list?.map((item: any) => {
      if (item.status !== "APPOINTMENT" && item.status !== "CANCEL") {
        return WaitingListItemDetail.patch({
          pk: item.id,
          data: {
            provider: params?.selectedProvider?.provider_id || item.provider,
            note: params?.note || item.note,
            type: params?.type || item.type,
            ...(params?.status && { status: params?.status }),
          },
          apiToken: controller.apiToken,
        });
      } else {
        return;
      }
    });
    const assign = await Promise.all(promiseArr);
    SetScheduling(controller, {
      action: "GetWaitingList",
      selectedWaitingListId: params?.waitingList?.id,
    });
  } else if (params?.action === "FilterByAppointmentSummary") {
    controller.setState({ loadingFilterAppList: true });

    let filterAppList = await PatientAppointmentView.list({
      params: {
        division_id: state.selectedDivision?.id,
        limit: 99999,
        start_date: params.startDate?.replaceAll("-", "/") || "",
        end_date: params.endDate?.replaceAll("-", "/") || "",
        start_time: params.startTime || "",
        end_time: params.endTime || "",
        type: params.appointmentType || "",
        provider_id: params.selectedDoctor?.provider_id || "",
      },
      apiToken: controller.apiToken,
    });
    console.log("PatientAppointmentView: ", filterAppList);

    if (filterAppList[0]) {
      let items = (filterAppList[0].items || []).map(
        (item: any, index: number) => ({
          ...item,
          patient: item?.patient_pre_name
            ? `${item.patient_pre_name} ${item.patient_first_name} ${item.patient_last_name}`
            : `${item.patient_first_name} ${item.patient_last_name}`,
          datetime: item?.estimated_at_iso
            ? moment(item.estimated_at_iso).format("DD/MM/YYYY HH:mm:ss")
            : "",
          provider: item?.display_info?.provider_name,
          patient_id: item.patient,
        })
      );
      // Sort

      items.sort((a: any, b: any) =>
        a.estimated_at_iso?.localeCompare(b.estimated_at_iso)
      );
      controller.setState({
        filterAppointmentList: items,
        loadingFilterAppList: false,
      });
    } else {
      controller.setState({
        loadingFilterAppList: false,
      });
    }
  } else if (params?.action === "GetAllWaitingList") {
    const waitingList = await WaitingListList.list({
      apiToken: controller.apiToken,
    });

    controller.setState({
      allWaitingList: waitingList?.[0],
    });
  } else if (params?.action === "SelectedWaitingList") {
    const waitingList = await WaitingListList.list({
      params: {
        division_id: params?.division?.id,
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      allWaitingList: waitingList?.[0],
    });
  } else if (params?.action === "SendWaitingListToClinic") {
    // console.log(" SendWaitingListToClinic ", params);
    const promiseArr = params?.patient?.map((item: any) => {
      return WaitingListItemDetail.patch({
        pk: item.id,
        data: {
          waiting_list: params?.waitinglist?.id,
        },
        apiToken: controller.apiToken,
      });
    });

    const sendWaitingList = await Promise.all(promiseArr);

    SetScheduling(controller, {
      action: "GetWaitingList",
      selectedWaitingListId: params?.waitinglist?.id,
    });
  } else if (params?.action === "SummaryStatic") {
    const staterDate = new Date().setMonth(new Date().getMonth() - 1);
    const startDateDefault = moment(staterDate)?.format(DATE_FORMAT);
    const endDateDefault = moment(new Date())?.format(DATE_FORMAT);

    if (!params?.card) {
      const waitingListTime = await WaitingListTime.get({
        params: {
          start_date: startDateDefault,
          end_date: endDateDefault,
          waiting_list_id: params?.waitingList?.id,
          division_id: params?.waitingList?.division,
          appointment: true,
        },
        apiToken: controller.apiToken,
      });

      const waitingListCanCel = await WaitingListCancelReport.get({
        params: {
          start_date: startDateDefault,
          end_date: endDateDefault,
          waiting_list_id: params?.waitingList?.id,
          division_id: params?.waitingList?.division,
          appointment: false,
        },
        apiToken: controller.apiToken,
      });

      controller.setState({
        waitingQueueList: waitingListTime?.[0]?.items,
        staticCanCelQueueList: waitingListCanCel?.[0]?.items,
      });
    }

    if (params?.card === "ModSummaryWaitingList") {
      const [r, e, n] = await WaitingListTime.get({
        params: {
          start_date: params?.startDateModWL,
          end_date: params?.endDateModWL,
          waiting_list_id: params?.waitingList?.id,
          division_id: params?.waitingList?.division,
          appointment: true,
        },
        apiToken: controller.apiToken,
      });
      controller.setState({
        waitingQueueList: r?.items,
      });
    } else if (params?.card === "ModSummaryStatic") {
      const [r, e, n] = await WaitingListCancelReport.get({
        params: {
          start_date: params?.startDateModWL,
          end_date: params?.endDateModWL,
          waiting_list_id: params?.waitingList?.id,
          division_id: params?.waitingList?.division,
          appointment: true,
        },
        apiToken: controller.apiToken,
      });
      controller.setState({
        staticCanCelQueueList: r?.items,
      });
    }
  }
  //  else if (params?.action === "SearchWaitingList") {
};

export const GetChairList: Handler = async (controller, params) => {
  // Get Provider with provider_type__name == "Chair"
  const chairProvider = await ProviderList.list({
    params: { provider_type__name: "Chair" },
  });
  console.log(
    "chairProvider: ",
    chairProvider[1] ? chairProvider[1] : chairProvider[0]
  );
  const chairDict = Object.fromEntries(
    (chairProvider[0]?.items || []).map((item: any) => [item.object_id, item])
  );
  controller.data = {
    ...controller.data,
    chairDict: chairDict,
  };

  console.log("ChairDict: ", chairDict);
  // Get Chair
  const chair = await ChairList.list({
    params: { limit: 99999 },
    apiToken: controller.apiToken,
  });
  console.log(chair[1] ? chair[1] : chair[0]);
  const chairList = (chair[0]?.items || [])
    .map((item: any) => ({
      ...item,
      division_name: controller.data.divisionDict?.[item.division]?.name,
      status_name: item.status === 1 ? "พร้อมใช้งาน" : "ไม่พร้อมใช้งาน",
      provider: chairDict?.[item.id]?.id || null,
    }))
    .filter((chair: any) => chair.provider);
  // console.log("chairList: ", chairList);
  controller.setState({
    chairList: chairList,
  });
};

export const GetChairWithDivisionServiceBlock: Handler = async (
  controller,
  params
) => {
  // can filter with
  // division: <division_id>
  // show_all: boolean to show all chair with dsb
  const chairDSB = await GetAvailableChairListAPI.list({
    apiToken: controller.apiToken,
    params: {
      start_date: params.start_date || "",
      end_date: params.end_date || "",
      start_serial: params.start_serial || "",
      end_serial: params.end_serial || "",
      only_chair: params.only_chair || false,
      division: params.division || "",
      exclude: params.exclude || [],
    },
  });
  controller.setState({
    chairExistDSBList: chairDSB?.[0]?.items || [],
  });
};

export const BookChair: Handler = async (controller, params) => {
  const state = controller.getState();
  const dentistDsb = params?.event?.providers?.[0];
  console.log(dentistDsb);
  if (params?.chair && dentistDsb) {
    const block = await DivisionServiceBlockView.create({
      data: {
        division: dentistDsb?.division_id,
        provider: params?.chair,
        start_serial: dentistDsb?.start_serial,
        end_serial: dentistDsb?.end_serial,
        parent: dentistDsb?.dsb_id,
      } as any,
      apiToken: controller.apiToken,
    });
    console.log(block[1] ? block[1] : block[0]);
    if (block[1]) return console.log("Error booking chair");
    controller.handleEvent({
      message: "FilterSchedule",
      params: { divisionId: state.selectedDivision?.id },
    });
  }
};

export const AddBlock: Handler = async (controller, params) => {
  const state = controller.getState();
  const providerId = params?.selectedProvider?.provider_id;
  const ISO_FORMAT = "YYYY-MM-DDTHH:mm:ssZZ";
  console.log(params);
  const getISODateTime = (date: string, isStart: boolean) => {
    const [start, end] = date.match(/\d+:\d+/g) || [];
    return `${date.match(/.* ?(?= )/g)?.[0]} ${isStart ? start : end}`;
  };

  const getISODate = (datetime: string) => {
    return moment(datetime).format(ISO_FORMAT);
  };

  let appointment: any = {};

  if (params.inactive && !state.openModConfirmAddBlock) {
    [appointment] = await PatientAppointmentView.list({
      apiToken: controller.apiToken,
      params: {
        start_datetime: getISODateTime(params.event.block_datetime, true),
        end_datetime: getISODateTime(params.event.block_datetime, false),
        provider_id: providerId,
      },
    });
  }

  if (
    params.inactive &&
    appointment?.items?.length &&
    !state.openModConfirmAddBlock
  ) {
    controller.setState({
      openModConfirmAddBlock: {
        datetime: `${params.event.date} [${params.event.title}]`,
        provider_name:
          appointment?.items?.[0]?.display_info?.provider_name || "",
        number: appointment?.items?.length,
        params,
      },
    });
    return;
  }

  controller.setState({ openModConfirmAddBlock: null });

  if (
    providerId &&
    params?.event &&
    !params?.event?.providers
      .map((p: any) => p.doctor_provider_id)
      .includes(providerId)
  ) {
    const block = await DivisionServiceBlockView.create({
      data: {
        division: state.selectedDivision?.id,
        provider: providerId,
        only_old_patient: params?.exceptNewPatient,
        chair: params?.chair || 0,
        parent: params?.event?.dsb_id,

        slot_length: parseInt(params?.slot_length || params.slotLength),
        start_datetime_iso: getISODate(params.event.start),
        end_datetime_iso: getISODate(params.event.end),

        // Use division dsb serial as default
        start_serial: params.event?.start_serial,
        end_serial: params.event?.end_serial,
        // Override if providing doctorStartSerial, doctorEndSerial
        ...(params?.doctorStartSerial
          ? { start_serial: params?.doctorStartSerial }
          : {}),
        ...(params?.doctorEndSerial
          ? { end_serial: params?.doctorEndSerial }
          : {}),
      } as any,
      apiToken: controller.apiToken,
    });
    console.log(block[1] ? block[1] : block[0]);

    // // reserve chair if specified
    // if (block[0] && params?.chair) {
    //   const chairBlock = await DivisionServiceBlockView.create({
    //     data: {
    //       division: controller.data.division,
    //       provider: params?.chair,
    //       start_serial: params.event?.start_serial,
    //       end_serial: params.event?.end_serial,
    //       parent: block[0].id,
    //     } as any,
    //     apiToken: controller.apiToken
    //   });
    //   console.log(chairBlock[1] ? chairBlock[1] : chairBlock[0]);
    //   if (chairBlock[1]) console.log("Error booking chair")
    // }

    // Refresh schedule
    controller.handleEvent({
      message: "FilterSchedule",
      params: { divisionId: state.selectedDivision?.id },
    });
  } else {
    console.log("DSB with this provider already exists.");

    const data = {
      status: params?.inactive ? DsbStatus.INACTIVE : DsbStatus.REVISE,
      only_old_patient: params?.exceptNewPatient,
      division: state.selectedDivision?.id,
      chair: params?.chair || 0,
      slot_length: parseInt(params?.slotLength),

      // Use division dsb serial as default
      start_serial: params.event?.start_serial,
      end_serial: params.event?.end_serial,
      // Override if providing doctorStartSerial, doctorEndSerial
      ...(params?.doctorStartSerial
        ? { start_serial: params?.doctorStartSerial }
        : {}),
      ...(params?.doctorEndSerial
        ? { end_serial: params?.doctorEndSerial }
        : {}),
    } as any;

    const block = await DivisionServiceBlockDetail.patch({
      pk: params.event?.providers?.[0]?.doctor_dsb_id,
      data: params.isDelete ? { is_active: false, note: params.note } : data,
      apiToken: controller.apiToken,
    });
    console.log(block[1] ? block[1] : block[0]);

    // Refresh schedule
    controller.handleEvent({
      message: "FilterSchedule",
      params: { divisionId: state.selectedDivision?.id },
    });
  }

  params.callback?.();
};

const getDivisionServiceBlockFilter: Handler = async (controller, params) => {
  const dsb = await Scheduling.get({
    command: "DivisionServiceBlockFilter",
    params: {
      divisions: [params.divisionId],
      start_date: "2000-01-01",
      end_date: "2100-12-29",
      only_new_patient: params.onlyNewPatient || null,
    },
    apiToken: controller.apiToken,
  });
  console.log("dsb: FilterSchedule ", dsb);
  const state = controller.getState();
  if (dsb[1]) {
    if (params?.card) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.card]: dsb[1] },
      });
    }
  }

  const blockList = (dsb[0] || []).map((item: any, index: number) => {
    return {
      ...item,
      id: index,
      start: serialToDate(item.start_serial),
      end: serialToDate(item.end_serial),
      title: `${item.start_time} - ${item.end_time}`,
    };
  });

  return blockList;
};

export const FilterSchedule: Handler = async (controller, params) => {
  console.log("FilterSchedule params: ", params);
  const state = controller.getState();
  if (Object.keys(params).includes("divisionId")) {
    if (params.divisionId === -1) {
      controller.setState({
        selectedDivision: null,
        // blockList: [],
        // providerBlockList: [],
      });
      return;
    } else {
      // If this is for initial, only do if divisionId is changed
      if (
        params?.initial &&
        params?.divisionId === state.selectedDivision?.id &&
        state.blockList?.length
      )
        return console.log("No need to load as division id is the same");

      // Clear calendar
      const division = (state.divisionList || []).find(
        (div: any) => div.id === params.divisionId
      );
      controller.setState({
        selectedDivision: division,
        blockList: [],
        // providerBlockList: []
      });

      // Filter by division
      const blockList = await getDivisionServiceBlockFilter(controller, params);

      controller.setState({
        blockList: blockList,
      });
      GetListSchedule(controller, params);

      // Example for future use: calling embedded-rust module
      // const schedulingRust = await Scheduling.list({
      //   command: "rust",
      //   params: {},
      //   apiToken: controller.apiToken
      // });
      // console.log(schedulingRust[1] ? schedulingRust[1] : schedulingRust[0]);
    }
  }
  if (Object.keys(params).includes("providerId")) {
    console.log(params);
    if (params?.providerId && typeof params?.providerId === "object") {
      const dsbChairs = await Promise.all(
        params?.providerId.map((item: any) => {
          return Scheduling.get({
            command: "DSBProviderFilter",
            params: {
              providerId: item,
              start_date: "2000-01-01",
              end_date: "2100-12-29",
            },
            apiToken: controller.apiToken,
          });
        })
      );

      let providerBlockList: any = [];
      dsbChairs.forEach((chair: any, index: number) => {
        if (chair[0]) {
          providerBlockList = providerBlockList.concat(
            (chair[0] || []).map((item: any, index: number) => {
              return {
                ...item,
                id: index,
                start: serialToDate(item.start_serial),
                end: serialToDate(item.end_serial),
                title: `${item.start_time} - ${item.end_time}`,
              };
            })
          );
        } else {
          console.log("Can't get chair: ", params?.providerId[index]);
        }
      });
      controller.setState({
        providerBlockList: providerBlockList,
      });
    } else if (params?.providerId) {
      const dsb = await Scheduling.get({
        command: "DSBProviderFilter",
        params: {
          providerId: params?.providerId,
          start_date: "2000-01-01",
          end_date: "2100-12-29",
        },
        apiToken: controller.apiToken,
      });
      console.log(dsb[1] ? dsb[1] : dsb[0]);
      const providerBlockList = (dsb[0] || []).map(
        (item: any, index: number) => {
          return {
            ...item,
            id: index,
            start: serialToDate(item.start_serial),
            end: serialToDate(item.end_serial),
            title: `${item.start_time} - ${item.end_time}`,
          };
        }
      );
      controller.setState({
        providerBlockList: providerBlockList,
      });
    } else {
      // Reset if providerId is undefined
      controller.setState({
        providerBlockList: [],
      });
    }
  }
  if (params?.range) {
    console.log(params.range);
  }
};

export const SearchPatient: Handler = async (controller, params) => {
  controller.setState({
    patientList: [],
    selectedPatient: null,
    appointmentList: [],
    selectedAppointment: null,
    selectedBlock: null,
  });

  // HN Search ,
  let [r, e, n]: any = [null, null, null];
  if (params?.type !== "mybplus") {
    if (/\d/.test(params?.patientSearchText)) {
      // มี ตัวเลข
      [r, e, n] = await PatientList.list({
        params: {
          hn: params?.patientSearchText,
          is_old_name_search: true,
          offset: 0,
          limit: 40,
        },
        apiToken: controller.apiToken,
      });
    } else {
      // ไม่มี ตัวเลข
      [r, e, n] = await PatientList.list({
        params: {
          name_search: params?.patientSearchText,
          is_old_name_search: true,
          offset: 0,
          limit: 40,
        },
        apiToken: controller.apiToken,
      });
    }
    if (e) return console.log("Error", e.toString());
    controller.setState({
      patientList: r?.items,
    });
  } else {
    /// My Bplus V3

    // patient_name = request.query_params.get('patient_name')
    // patient_dob = request.query_params.get('patient_dob')
    // division_id = request.query_params.get('division')
    // doctor_name = request.query_params.get('doctor_name')
    // start_date = request.query_params.get('start_date')
    // end_date = request.query_params.get('end_date')
    // is_telemed = request.query_params.get('telemed', '')

    let parameters = {
      ...(params?.hn && { hn: params?.hn }),
      ...(params?.name && { patient_name: params?.name }),
      ...(params?.doctor && { doctor_name: params?.doctor }),
      ...(params?.division && { division: params?.division }),
      ...(params?.start_date && { start_date: params?.start_date }),
      ...(params?.end_date && { end_date: params?.end_date }),
      telemed: true,
      offset: 0,
      // limit: 40, เพราะต้องทำ filter หน้าบ้าน (feature 56561)
    };
    const [r, e, n] = await V3DashboardAppointmentView.get({
      params: parameters,
      apiToken: controller.apiToken,
    });
    if (e) return console.log("Error", e.toString());
    // console.log("r", r)
    controller.setState({
      patientListMyBplus: (r.items || r.results)?.map((item: any) => ({
        ...item,
        full_name: item.patient_name,
      })),
    });
  }
};

export const ClearAppointment: Handler = async (controller, params) => {
  controller.setState({
    patientList: [],
    selectedPatient: null,
    appointmentList: [],
    selectedAppointment: null,
    selectedBlock: null,
  });
};

export const SelectPatient: Handler = async (controller, params) => {
  // console.log("SelectPatient params: ", params);
  if (params?.id) {
    controller.setState(
      {
        selectedPatient: params,
        appointmentList: [],
        selectedAppointment: null,
        selectedBlock: null,
      },
      () => {
        // console.log(" RefreshAppointment params", params)
        RefreshAppointment(controller, { ...params, noGetWaiting: true });
        // RefreshAppointment(controller,  {params: {...params, noGetWaiting: true}});
      }
    );
  } else if (params === null) {
    controller.setState({
      selectedPatient: null,
      appointmentList: [],
      selectedAppointment: null,
      selectedBlock: null,
    });
  } else {
    controller.setState({
      selectedPatient: params,
      appointmentList: [],
      selectedAppointment: null,
      selectedBlock: null,
    });
  }
};

export const RefreshAppointment: Handler = async (controller, params) => {
  console.log('RefreshAppointment params: ', params);
  const state = controller.getState();
  // console.log('RefreshAppointment state.selectedPatient: ', state.selectedPatient);
  const appointment = await PatientAppointmentView.list({
    params: {
      ...(state.selectedPatient && { patient_id: state.selectedPatient?.id }),
      ...(params?.id && { patient_id: params?.id }),
      exclude_cancel: true,
    },
    apiToken: controller.apiToken,
  });
  console.log(appointment[1] ? appointment[1] : appointment[0]);

  if (appointment[1] && params?.card) {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: appointment[1] },
    });
  }

  const items = appointment[0]?.items.map((item: any) => ({
    ...item,
  }));
  controller.setState({
    appointmentList: items,
  });
  FilterSchedule(controller, {
    divisionId: state.selectedDivision?.id,
    card: params?.card,
  });

  // console.log(" Refresh waiting List params: ", params , params?.noGetWaiting)
  // Refresh waiting list after create
  if (!params?.noGetWaiting) {
    SetScheduling(controller, {
      action: "GetWaitingList",
      selectedWaitingListId: params?.waitingList?.id,
    });
  }
};

export const CreatePatientAppointment: Handler = async (controller, params) => {
  const { selectedPatient, selectedDivision, errorMessage } =
    controller.getState();
  if (
    Number.isInteger(selectedPatient?.id) &&
    Number.isInteger(selectedDivision?.id)
  ) {
    const [r, e, n] = await PatientAppointmentView.create({
      data: {
        patient: selectedPatient?.id,
        division: selectedDivision?.id,
        waiting_list_item_id: params?.waitingListItem?.id,
        type: params?.waitingListItem?.type,
        is_or_type: params?.is_or_type,
        encounter_id: params?.encounter_id,
      } as any,
      apiToken: controller.apiToken,
    });
    console.log(r);
    if (e) {
      if (params.card) {
        controller.setState({
          errorMessage: { ...errorMessage, [params.card]: e },
        });
      }
    }

    RefreshAppointment(controller, params);
  }
};

// Ishealth-v3 port into CUDent
export const HandleActionPreOrderList: Handler = async (controller, params) => {
  const state = controller.getState();

  let cloneArr = [...state.preOrderList];

  if (params.action === "delete") {
    let itemTarget = cloneArr.find((item: any) => item.id === params.id);
    cloneArr = cloneArr.filter((item: any) => item.id !== params.id);

    // if(itemTarget && itemTarget.type === "imagingorder"){
    //   const order = await ImagingOrderDetail.delete({
    //     pk: params.id,
    //     apiToken: controller.apiToken,
    //     extra: {
    //       division: controller.data.division,
    //       device: controller.data?.device,
    //     },
    //   });

    //   if (order[1]) {
    //     // console.log("error: ", order[1]);
    //     if (params.card) {
    //       controller.setState({
    //         errorMessage: { ...state.errorMessage, [params.card]: order[1] },
    //       });
    //     }
    //     return;
    //   }
    // }
  }

  controller.setState({
    preOrderList: cloneArr,
  });
};

// Ishealth-v3 port into CUDent
export const ImagingRequestOrder: Handler = async (controller, params) => {
  const state = controller.getState();

  let encounterId = params.isAppointment
    ? state.selectedAppointment?.order_encounter || null
    : state.selectedEncounter?.id || state.selectedEncounterId || null;
  if (params?.action === "initial") {
    controller.handleEvent({
      message: "GetMasterData",
      params: {
        masters: [
          ["claimImaging", {}],
          ["unit", {}],
          ["eligibilityType", {}]
        ],
      },
    });

    if (params?.orderId) {
      const order = await ImagingOrderDetail.retrieve({
        pk: params.orderId,
        apiToken: controller.apiToken,
      });

      if (order[1]) {
        // console.log("error: ", order[1]);
        if (params.card) {
          controller.setState({
            errorMessage: { ...state.errorMessage, [params.card]: order[1] },
          });
        }
        return;
      }
      let items = order[0]?.order_items || [];
      const estimateProduct = await ImagingOrderEstimate.post({
        apiToken: controller.apiToken,
        data: {
          encounter: encounterId,
          order_items: [...items],
        },
        extra: {
          division: controller.data.division,
          device: controller.data?.device,
        },
      });

      if (estimateProduct[1]) {
        if (params.card) {
          controller.setState({
            errorMessage: {
              ...state.errorMessage,
              [params.card]: estimateProduct[1],
            },
          });
        }
        return;
      }

      items = items.map((item: any, index: number) => ({
        ...item,
        ...estimateProduct[0].orders[index],
        active: true,
        name: item?.product_name || "",
      }));

      return controller.setState({
        orderImagingList: items,
        imagingList: [],
      });
    } else {
      return controller.setState({
        imagingList: [],
        orderImagingList: [],
      });
    }
  } else if (params?.action === "search_product") {
    if (!encounterId) {
      const encounter = await EncounterList.list({
        apiToken: controller.apiToken,
        params: {
          patient: state.selectedPatient,
          active_only: true,
        },
      });

      if (encounter[1]) {
        console.log("error: ", encounter[1]);
        if (params.card) {
          controller.setState({
            errorMessage: {
              ...state.errorMessage,
              [params.card]: encounter[1],
            },
          });
        }
        return;
      }
      encounterId = encounter[0]?.items[0].id;
    }

    const product = await MiscellaneousList.list({
      apiToken: controller.apiToken,
      params: {
        encounter: encounterId,
        group_code: "XRAY",
        name: params.searchName,
        limit: 40,
      },
    });

    if (product[1]) {
      console.log("error: ", product[1]);
      if (params.card) {
        controller.setState({
          errorMessage: { ...state.errorMessage, [params.card]: product[1] },
        });
      }
      return;
    }

    controller.setState({
      imagingList: product[0]?.items || [],
      selectedEncounterId: encounterId,
    });
  } else if (params?.action === "add_product" && params?.item?.id) {
    // set pre process quantity
    let estimateItem = {
      ...params.item,
      product: params?.item.id,
      quantity: 1,
      encounter: encounterId,
      eligibility_type: 1,
      active: true,
      // charges_date_iso: moment().format("YYYY-MM-DD"),
    };
    delete estimateItem["id"];
    const estimateProduct = await ImagingOrderEstimate.post({
      apiToken: controller.apiToken,
      data: {
        encounter: encounterId,
        order_items: [estimateItem],
      },
      extra: {
        division: controller.data.division,
        device: controller.data?.device,
      },
    });

    if (estimateProduct[1]) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: estimateProduct[1],
          },
        });
      }
      return;
    }
    console.log("estimate item: ", estimateProduct[0], estimateItem);
    estimateItem = { ...estimateItem, ...estimateProduct[0]?.orders[0] };
    // add item
    let items = [...(state.orderImagingList || [])];
    let orderItems = [...items].filter(
      (item: any) => item.product === estimateItem.product && item.active
    );

    if (orderItems?.length > 0) {
      items = items.map((item: any) =>
        item.product === estimateItem.product
          ? { ...item, quantity: item.quantity + 1 }
          : { ...item }
      );
    } else {
      items.push(estimateItem);
    }
    console.log("orderImagingList: ", items);
    return controller.setState({
      imagingList: [],
      orderImagingList: items,
    });
  } else if (params?.action === "edit_order_item") {
    let targetItem = state.orderImagingList?.find(
      (item: any) => item.product === params.targetProduct
    );
    targetItem = {
      ...targetItem,
      [params.name]: params.value,
      // charges_date_iso: moment().format("YYYY-MM-DD"),
    };

    const estimateProduct = await ImagingOrderEstimate.post({
      apiToken: controller.apiToken,
      data: {
        encounter: encounterId,
        order_items: [targetItem],
      },
      extra: {
        division: controller.data.division,
        device: controller.data?.device,
      },
    });

    if (estimateProduct[1]) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: estimateProduct[1],
          },
        });
      }
      return;
    }

    let items = [...(state.orderImagingList || [])].map((item: any) =>
      item.product === params.targetProduct
        ? {
          ...item,
          [params.name]: params.value,
          ...estimateProduct[0]?.orders[0],
        }
        : { ...item }
    );

    return controller.setState({
      orderImagingList: items,
    });
  } else if (params?.action === "delete_order_item") {
    return controller.setState({
      orderImagingList: [...(state.orderImagingList || [])].map((item) =>
        item.product === params.targetProduct
          ? { ...item, active: false }
          : { ...item }
      ),
    });
  } else if (params?.action === "clear_data") {
    return controller.setState({
      orderImagingList: [],
      imagingList: [],
    });
  } else if (params?.action === "save_orders") {
    // console.log("save_orders", params)
    let order: any = null;
    if (params.orderId) {
      const orders = await ImagingOrderDetail.patch({
        pk: params.orderId,
        apiToken: controller.apiToken,
        data: {
          action: "EDIT",
          order_status: params.isAppointment ? 1 : 2,
          encounter: encounterId,
          order_items: [
            ...(state.orderImagingList?.filter((item: any) => item.active) ||
              []),
          ],
          extra: {}
        },
        extra: {
          division: controller.data.division,
          device: controller.data?.device,
        },
      });

      if (orders[1]) {
        // console.log("error: ", order[1]);
        if (params.card) {
          controller.setState({
            errorMessage: { ...state.errorMessage, [params.card]: orders[1] },
          });
        }
        return;
      }
      order = orders[0];
    } else {
      const orders = await ImagingOrderlist.create({
        apiToken: controller.apiToken,
        data: {
          action: "ORDER",
          order_status: params.isAppointment ? 1 : 2,
          encounter:
            state.selectedAppointment?.order_encounter ||
            state.selectedEncounterId,
          order_items: [...(state.orderImagingList || [])],
          extra: {}
        },
        extra: {
          division: controller.data.division,
          device: controller.data?.device,
        },
      });
      if (orders[1]) {
        if (params.card) {
          controller.setState({
            errorMessage: { ...state.errorMessage, [params.card]: orders[1] },
          });
        }
        return;
      }
      order = orders[0];
    }

    if (params.onSuccess) {
      let preOrderList = [...(state.preOrderList || [])];
      if (params.orderId) {
        preOrderList = preOrderList.map((item: any) =>
          item.id === params.orderId
            ? {
              ...item,
              specific_label_type: "imagingorder",
              type: "imagingorder",
              summary_detail: order.order_summary,
            }
            : { ...item }
        );
      } else {
        preOrderList.push({
          ...order,
          specific_label_type: "imagingorder",
          type: "imagingorder",
          summary_detail: order.order_summary,
        });
      }
      controller.setState(
        { preOrderList: preOrderList, orderImagingList: [], imagingList: [] },
        () => {
          params?.onSuccess();
        }
      );
    } else {
      controller.setState({ selectedImaging: order });
    }
  }
};

export const SelectAppointment: Handler = async (controller, params) => {
  const state = controller.getState();

  const [appointment, medRecord] = await Promise.all([
    PatientAppointmentUpdate.retrieve({
      pk: params.id,
      apiToken: controller.apiToken,
    }),

    EncounterMedicalRecordList.list({
      pk: params.order_encounter,
      extra: {
        division: controller.data.division,
      },
      apiToken: controller.apiToken,
    }),
  ]);

  if (appointment[1]) {
    if (params.card) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: appointment[1] },
      });
    }
  }

  console.log("medRecord", medRecord);
  console.log("appointment", appointment);

  let date = params.display_info?.start_datetime?.split("T")?.[0] || "";
  let start =
    params.display_info?.start_datetime?.split("T")?.[1]?.substring(0, 5) || "";
  let end =
    params.display_info?.end_datetime?.split("T")?.[1]?.substring(0, 5) || "";
  let datetime = `${date} ${start}-${end}`;

  // console.log("params", params)

  const [response, error] = await PatientDetailView.retrieve({
    pk: appointment[0]?.patient,
    apiToken: controller.apiToken,
  });

  controller.setState(
    {
      selectedAppointment: {
        ...(params || {}),
        ...appointment[0],
        date: params.estimated_at?.split(" ")?.[0] || "",
        patient_name: `${params.patient_first_name} ${params.patient_last_name}`,
        detail: `${params.division_name || ""} (${params.display_info?.provider_name || ""
          } ${datetime || ""})`,
        datetime,
      },
      ...(!params?.isSave && { selectedBlock: null }),
      // selectedBlock: params?.isSave ? : null,
      selectedEncounter: {
        id: params.order_encounter,
        patient_gender_name: response?.gender_name,
      },
      preOrderList: appointment[0]?.orders || [],
      selectedEmr: medRecord[0]?.items?.[0],
      selectOperatingDSBChange: false,
    },
    async () => {
      console.log("GetAppointmentDetail: ", params, appointment[0])
      await GetAppointmentDetail(controller, {
        card: params.card,
        selectedAppointment: params,
        doctor_dsb_id: params?.division_service_block,
        estimated_at_iso: appointment[0]?.estimated_at_iso
      });
    }
  );

  // const [r, e, n] = await DivisionServiceBlockView.list({
  //   params: {
  //     divisions: [controller.data.division]
  //   },
  //   apiToken: controller.apiToken
  // });
  // if (e) return console.log("Error", e.toString());
  // // console.log(r.items);
};

export const AssignAppointmentToBlock: Handler = async (controller, params) => {
  const state = controller.getState();
  console.log("AssignAppointmentToBlock params: ", params);
  // console.log('AssignAppointmentToBlock state.selectedAppointment?.id: ', state.selectedAppointment?.id);

  if (state.selectedAppointment?.id) {
    // controller.setState({ selectedBlock: params });
    console.log(
      "AssignAppointmentToBlock state.selectedAppointment?.id",
      state.selectedAppointment?.id
    );

    // Ishealth-v3 port into CUDent
    let datetime = `${adToBe(params.dsb.date, "YYYY-MM-DD")} ${params.doctor_start_time || ""
      }-${params?.doctor_end_time || ""}`;
    // console.log('datetime: ', datetime);
    controller.setState(
      {
        selectedBlock: params,
        selectedAppointment: {
          ...state.selectedAppointment,
          detail: `${params.division_name || ""} (${params.doctor_provider_name || ""
            } ${datetime || ""})`,
          division_name: params.division_name,
        },
      },
      async () => {
        // console.log('AssignAppointmentToBlock state.selectedAppointment.detail: ', state.selectedAppointment?.detail);
        await GetAppointmentDetail(controller, {
          selectedAppointment: state.selectedAppointment,
          doctor_dsb_id: params.doctor_dsb_id,
          selectedBlock: params,
          card: params.card,
        });
      }
    );
  }

  // const update = await PatientAppointmentUpdate.patch({
  //   pk: state.selectedAppointment?.id,
  //   data: { division_service_block: params.dsb_id },
  //   apiToken: controller.apiToken
  // });
  // console.log(update[1] ? update[1] : update[0]);
  // RefreshAppointment(controller, params);
};

export const GetAppointmentDetail: Handler = async (controller, params) => {
  /// use params?.selectedBlock
  // console.log(" GetAppointmentDetail params: ", params);

  const state = controller.getState();
  // console.log('GetAppointmentDetail state: ', state);

  if (params?.doctor_dsb_id) {
    const dsb = await DivisionServiceBlockDetail.retrieve({
      pk: params.doctor_dsb_id,
      apiToken: controller.apiToken,
    });

    if (dsb[1]) {
      if (params.card) {
        controller.setState({
          errorMessage: { ...state.errorMessage, [params.card]: dsb[1] },
        });
      }
      return;
    }

    // console.log("DivisionServiceBlockDetail dsb: ", dsb);
    const reservedSlots = (dsb[0]?.service_appointments || [])
      .filter((app: any) => app?.id !== params.selectedAppointment?.id)
      .map((app: any) => [
        app?.estimated_at?.split(" ")?.[1],
        app?.estimated_duration,
      ])
      .map((slot: [string, number]) => [
        slot[0].substring(1, slot[0].length - 1),
        slot[1],
      ]);
    console.log("available reservedSlots: ", reservedSlots)
    const slotLength = dsb[0]?.slot_length;
    const start = moment(serialToDate(dsb[0]?.start_serial));
    const end = moment(serialToDate(dsb[0]?.end_serial));
    const slotCount = Math.floor(end.diff(start, "minutes") / slotLength) - 1;
    // console.log('slotCount: ', slotCount);

    const slots = Array.from({ length: slotCount })
      .map((v: any, i: number) =>
        start
          .clone()
          .add(slotLength * i, "minutes")
          .format("HH:mm")
      )
      .filter((s: string) => {
        let date = new Date();
        let hourS = parseInt(s?.split(":")[0]);
        let minS = parseInt(s?.split(":")[1]);

        let slotStart = new Date(date.getTime());
        slotStart.setHours(hourS, minS, 0);
        let slotEnd = new Date(slotStart.getTime());
        slotEnd.setMinutes(slotEnd.getMinutes() + slotLength);
        let match = reservedSlots?.find((item: [string, number]) => {
          let reservedStart = new Date(date.getTime());
          let hour = parseInt(item[0]?.split(":")[0]);
          let min = parseInt(item[0]?.split(":")[1]);
          reservedStart.setHours(hour, min, 0);
          let reservedEnd = new Date(reservedStart.getTime());
          reservedEnd.setMinutes(reservedEnd.getMinutes() + item[1]);

          if (
            (reservedStart?.getTime() <= slotStart?.getTime() &&
              reservedEnd?.getTime() > slotStart?.getTime()) ||
            (reservedStart?.getTime() < slotEnd?.getTime() &&
              reservedEnd?.getTime() >= slotEnd?.getTime()) ||
            (reservedStart?.getTime() <= slotStart?.getTime() &&
              reservedEnd?.getTime() >= slotEnd?.getTime()) ||
            (reservedStart?.getTime() > slotStart?.getTime() &&
              reservedEnd?.getTime() < slotEnd?.getTime())
          ) {
            // overlap
            return true;
          } else {
            return false;
          }
        });

        // console.log('s: ', s, ' match: ', match);
        return !Array.isArray(match);
      })
      .map((s: string, index: number) => ({ key: index, value: s, text: s }));

    console.log("availableSlots: ", slots);

    const date = dsb[0]?.start_datetime.split("T")?.[0];
    const selectedBlock = params.selectedBlock;
    const datetime = selectedBlock
      ? `${formatADtoBEString(selectedBlock.date) || ""} ${selectedBlock.start_time
      } - ${selectedBlock.end_time}`
      : "";
    // console.log("start", start)
    // console.log("end", end)
    // console.log(" get slots?.[0]?.value: ", slots?.[0]?.value)
    // console.log(" state.selectedAppointment?.estimated_at", state.selectedAppointment?.estimated_at)
    controller.setState({
      availableSlots: slots,
      selectedAppointment: {
        ...state.selectedAppointment,
        date: date,
        slotLength: slotLength,
        start: start?.clone()?.format("HH"),
        end: end?.clone()?.format("HH"),

        /// use params?.selectedBlock
        ...(selectedBlock
          ? {
            ...(slots?.[0]?.value &&
              date && { estimated_at: `${date} [${slots?.[0]?.value}]` }),
            datetime,
            detail: `${selectedBlock.division_name || ""} (${selectedBlock.doctor_provider_name || ""
              } ${datetime})`,
            display_info: {
              provider_name: selectedBlock.doctor_provider_name,
              // division: selectedBlock.division_id
            },
            division_name: selectedBlock.division_name,
            // division: selectedBlock.division_id
          }
          : {}),
      },
      ...(selectedBlock && { selectedBlock }),
    });
  } else {
    const slotLength = 5 // this may be hard code
    const start = moment(params.estimated_at_iso);
    const end = moment(params.estimated_at_iso).add('days', 1);
    const slotCount = Math.floor(end.diff(start, "minutes") / slotLength) - 1;

    const slots = Array.from({ length: slotCount })
      .map((v: any, i: number) =>
        start
          .clone()
          .add(slotLength * i, "minutes")
          .format("HH:mm")
      )
      .map((s: string, index: number) => ({ key: index, value: s, text: s }));
    controller.setState({
      availableSlots: slots,
    })
  }
};

export const PrintScheduling: Handler = async (controller, params) => {
  let docDef: any = { content: [] };
  if (params.form === "FormAppointmentSummary") {
    const state = controller.getState();

    if (params.data?.is_search && params.data?.orders?.[0]) {
      controller.setState({ loadingPrintAppList: true });

      const patientIds = params.data.orders.map((item: any) => item.patient_id);
      const promiseArr = Array.from(new Set(patientIds)).map((id) =>
        PatientDetailView.retrieve({ pk: id, apiToken: controller.apiToken })
      );
      const patientList = await Promise.all(promiseArr);

      params.data.orders = params.data.orders.map(
        (item: any, index: number) => {
          const patient = patientList.find(
            (patient: any) => patient[0]?.id === item.patient_id
          );
          return { ...item, address: patient[0]?.main_address_doc || "" };
        }
      );
    }

    docDef = FormAppointmentSummary({ data: params.data });
  } else if (params.form === "FormChartAppointmentSummary") {
    docDef = FormChartAppointmentSummary({ data: params.data });
  } else if (params.form === "FormDentistSchedule") {
    docDef = FormDentistSchedule({ data: params.data });
  } else if (params.form === "FormAppointmentDetail") {
    const [[patient], [user]] = await Promise.all([
      PatientDetailView.retrieve({
        apiToken: controller.apiToken,
        pk: params.data.patient,
      }),
      UserProfileAPI.retrieve({
        apiToken: controller.apiToken,
      }),
    ]);

    const [start, end] = params.data?.datetime
      ? params.data.datetime.match(/\d+:\d+/g)
      : ["", ""];

    const appointmentTime = params.data?.estimated_at_iso ? moment(params.data?.estimated_at_iso).format("HH:mm") : ""

    docDef = FormAppointmentDetail({
      data: {
        ...params.data,
        start,
        end,
        appointment_time: appointmentTime,
        full_age: patient.full_age,
        full_name: patient.full_name,
        staff: user?.fullname
          ? user?.fullname
          : `${user?.first_name || ""} ${user?.last_name || ""}`,
      },
    });
  } else if (params.form === "FormWaitingList") {
    // console.log('FormWaitingList params.data: ', params.data);
    docDef = FormWaitingList({ data: params.data });
  }
  if (
    ["FormWaitingList", "FormAppointmentSummary"].includes(params.form || "")
  ) {
    (await getPdfMake())
      .createPdf(docDef)
      .getDataUrl((dataUrl: any) => waterMarkToPdf(dataUrl));

    controller.setState({ loadingPrintAppList: false });
  } else {
    (await getPdfMake()).createPdf(docDef).open();
  }
};

export const HandlePrintORAppointmentForm: Handler = async (controller, params) => {
  const state = controller.getState()

  if (!state.selectedOrOrder?.id) {
    console.log("No or data")
    return
  }

  const [patient] = await PatientDetailView.retrieve({
    apiToken: controller.apiToken,
    pk: state.selectedPatient?.id,
  })

  let startDateTime: string | Moment = state.selectedAppointment?.display_info?.start_datetime_iso ? 
  moment(state.selectedAppointment?.display_info?.start_datetime_iso) : ""
  // let endDateTime = moment(state.selectedAppointment?.display_info?.end_datetime_iso) || ""
  const crossMatchDate = state.selectedOrOrder?.blood_request?.cross_match_date

  let findMatchDSB = [...(state.operatingBlock || [])].find((dsb: any) => dsb.operating_detail === state.selectedOrOrder.operating_detail)

  if(findMatchDSB && findMatchDSB.admit_case === "Admit ก่อนผ่าตัด"){
    startDateTime = moment(`${findMatchDSB?.admit_date}T${findMatchDSB?.admit_time}`, "YYYY-MM-DDTHH:mm")
    // console.log(`findMatchDSB: ${findMatchDSB?.admit_date}T${findMatchDSB?.admit_time}`, startDateTime)
  }
  let startTime = "ไม่ระบุเวลา"
  // let endTime = ""
  let fullThaiDate = "ไม่ระบุวัน"
  // let periodTime = ""
  let fullThaiCrossMatchDate = "ไม่ระบุวัน"
  let crossMatchTime = "ไม่ระบุเวลา"

  if (startDateTime) {
    fullThaiDate = adToBeWithSetFormat((startDateTime as Moment)?.format("YYYY-MM-DD"), "YYYY-MM-DD", "DD MMMM YYYY", "th")
    startTime = (startDateTime as Moment)?.format("HH:mm")
  }

  if (crossMatchDate) {
    fullThaiCrossMatchDate = adToBeWithSetFormat(moment(crossMatchDate).format("YYYY-MM-DD"), "YYYY-MM-DD", "DD MMMM YYYY", "th")
    crossMatchTime = moment(crossMatchDate).format("HH:mm")
  }

  // if (endDateTime) {
  //   endTime = endDateTime.format("HH:mm")
  // }

  // if (startTime && endTime) {
  //   periodTime = `${startTime} - ${endTime}`
  // }

  let birthdate = ""

  if (patient?.full_age && patient?.birthdate) {
    birthdate = `${patient.birthdate} ${patient.full_age}`
  } else if (patient?.birthdate) {
    birthdate = patient.birthdate
  }

  const caseDetail = state.selectedOrOrder?.case || {}
  let operatingType = ""
  let operatingCase = ""

  if (caseDetail.is_ipd_case) {
    operatingCase = "IPD"
  } else if (caseDetail.is_one_day_case) {
    operatingCase = "One day"
  } else if (caseDetail.is_opd_case) {
    operatingCase = "OPD"
  }
  // is_one_day_case: false
  // is_opd_case: false

  if (state.selectedOrOrder?.type) {
    if (state.selectedOrOrder?.type.includes("EL")) {
      operatingType = "Elective case"
    }
    if (state.selectedOrOrder?.type.includes("ER")) {
      operatingType = "Emergency case"
    }
  }

  let note = "-"

  if (state.selectedOrOrder?.case?.is_one_day_case) {
    note = "One Day Surgery"
  } else if (state.selectedOrOrder?.case?.is_ipd_case) {
    note = `นัดหมาย ${state.selectedOrOrder?.case?.ipd_case}`
  }

  let data = {
    patient_hn: patient?.hn || "",
    patient_full_name: patient?.full_name || "",
    patient_birthday: birthdate,
    patient_coverage: patient?.coverage || "",
    appointment_date: fullThaiDate,
    appointment_time: startTime,
    doctor: state.selectedOrOrder?.primary_doctor_name || "",
    division:
    state.selectedOrOrder?.anesthesia_method_name === "GA"
      ? "หออภิบาลผู้ป่วย (GA)"
      : `${state.selectedOrOrder?.division_name} (${state.selectedOrOrder?.anesthesia_method_name})` ||
        "",
    appointment_type: "นัดหมายผ่าตัด",
    operating_type: `${operatingType} (${operatingCase})`,
    note: note,
    remark: state.selectedOrOrder?.remark?.split("\n") || [],
    content_id: state.selectedAppointment?.content_id || "",
    staff: state.django?.user?.full_name || "",
  }

  const promiseArr: Promise<string>[] = [];
  const pdfMake = await getPdfMake()

  const createPDFBase64 = async (data: any): Promise<string> => {
    let docDef: any = { content: [] };
    docDef = ORAppointmentForm(data);
    return new Promise(async (resolve, reject) =>
      pdfMake.createPdf(docDef).getBase64((result: any) =>
        resolve(result)))
  }

  promiseArr.push(createPDFBase64({ ...data }))

  if (state.selectedOrOrder?.blood_request_summary === "Yes") {
    promiseArr.push(createPDFBase64({
      ...data,
      appointment_date: fullThaiCrossMatchDate,
      appointment_type: "นัดหมายเจาะเลือด",
      division: "ห้องแลป",
      title: "บัตรนัดหมายเจาะเลือด สำหรับการขอเลือดผ่าตัด",
      hide_note: true,
      hide_before_anesthesia: true
    }))
  }

  const pdfBase64 = await Promise.all(promiseArr)

  const pdfDoc = await PDFDocument.create();

  for (const base64 of pdfBase64) {
    const doc = await PDFDocument.load(base64);
    const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());

    for (const page of copiedPages) {
      pdfDoc.addPage(page)
    }
  }

  const base64Data = await pdfDoc.saveAsBase64();

  const blob = base64toBlob("data:application/pdf;base64," + base64Data);

  const bloburl = URL.createObjectURL(blob);
  window.open(bloburl);
}

export const UserTokenize: Handler = async (controller, params) => {
  var state = controller.getState();

  console.log("UserTokenize", params);
  if (params?.action === "clear") {
    controller.setState({
      userTokenize: {
        ...state.userTokenize,
        token: "",
        employeeName: "",
        loading: false,
      },
    });
  } else if (params?.action === "CheckUserToken" && params?.code?.length > 0) {
    console.log("UserTokenize", params);

    controller.setState({
      userTokenize: {
        ...state.userTokenize,
        loading: true,
      },
    });

    const [response, error, network] = await UserTokenizeView.post({
      apiToken: controller.apiToken,
      data: { code: params?.code },
    });

    state = controller.getState();

    console.log("UserTokenizeView response", response);
    console.log("UserTokenizeView error", error);

    if (error) {
      controller.setState({
        userTokenize: {
          ...state.userTokenize,
          error: error,
          loading: false,
        },
      });
      return;
    }
    // console.log("decodeURIComponent", decodeURIComponent(atob(response["token"])))
    controller.setState({
      userTokenize: {
        ...state.userTokenize,
        token: response?.token || "",
        employeeName:
          decodeURIComponent(atob(response?.token.split(".")[1])) || "",
        employeeCode: params?.code,
        loading: false,
      },
    });
  }
};

export const getDoctorNoteList: Handler = async (controller, params) => {
  const [r, e, n] = await DoctorNoteTemplateList.list({
    apiToken: controller.apiToken,
    params,
  });

  return r?.items || [];
};

export const getDoctorNoteGroupList: Handler = async (controller, params) => {
  const [r, e, n] = await DoctorNoteTemplateGroupList.list({
    apiToken: controller.apiToken,
    params,
  });

  return r?.items || [];
};

export const GetSummaryDoctor: Handler = async (controller, params) => {
  const dateDefault = moment(new Date())?.format(DATE_FORMAT);

  const [r, e, n] = await DoctorReportList.get({
    params: {
      start_date: dateDefault,
      end_date: dateDefault,
    },
    apiToken: controller.apiToken,
  });

  let filter = r?.items?.filter(
    (item: any) => item.doctor__first_name !== null
  );

  controller.setState({
    summaryDoctorList: filter,
  });

  if (params?.action === "search") {
    const [r, e, n] = await DoctorReportList.get({
      params: {
        start_date: params?.data.startDate,
        end_date: params?.data?.endDate,
        doctor_code: params?.data?.doctor?.code,
      },
      apiToken: controller.apiToken,
    });

    let filter = r?.items?.filter(
      (item: any) => item.doctor__first_name !== null
    );

    controller.setState({
      summaryDoctorList: filter,
    });
  }
};

export const CreatePatientAppointmentForBloodBank: Handler = async (controller, params) => {
  const { selectedPatient, selectedDivision, selectedAppointment, loadingStatus } =
    controller.getState();
  if (
    Number.isInteger(selectedPatient?.id) &&
    Number.isInteger(params.divisionId)
  ) {
    const patientAppointment = await PatientAppointmentView.create({
      data: {
        main_patient_appointment: selectedAppointment?.id,
        patient: selectedPatient?.id,
        division: params.divisionId,
      } as any,
      apiToken: controller.apiToken,
    });

    if (patientAppointment[0]) {
      const medicalRecord = await EncounterMedicalRecordList.list({
        pk: patientAppointment[0]?.order_encounter,
        extra: {
          division: controller.data.division,
        },
        apiToken: controller.apiToken,
      })

      const labData = await CentralLabOrderList.create({
        data: {
          action: "ORDER",
          allow_duplicate_flag: false,
          emr: medicalRecord?.[0].id,
          encounter: patientAppointment[0]?.order_encounter,
          note: params.note,
          order_items: params.order_items || [],
          order_status: 1,
          is_advance: false
        },
        extra: { division: controller.data.division },
        apiToken: controller.apiToken
      });

      if (labData[0]) {
        /// save
        // save other treatment, lab, imaging บันทึกส่วน
        let orderDict: Record<string, string> = {};
        let preLabList = labData[0]?.order_list?.map((item: any) => ({ ...item, summary_detail: item.order_summary, type: "centrallaborder" })) || []
        if (preLabList) {
          for (const item of preLabList) {
            orderDict[item.id] = item.type;
          }
        }

        const a = await PatientAppointmentUpdate.patch({
          pk: patientAppointment[0].id,
          apiToken: controller.apiToken,
          data: {
            order_dict: orderDict,
          } as any,
        });

        if (params.refreshPage) {
          controller.setState({
            loadingStatus: { ...loadingStatus, [params?.sequence]: false }
          })

          if (params.onSuccess) {
            params.onSuccess()
          }
          await RefreshAppointment(controller, params);
          await SelectAppointment(controller, {
            ...(patientAppointment[0]),
            isSave: true,
          });
        }
      }
    }
  }
}

export const GetSummaryWaitingQueue: Handler = async (controller, params) => {
  const staterDate = new Date().setMonth(new Date().getMonth() - 1);
  const startDateDefault = moment(staterDate)?.format(DATE_FORMAT);
  const endDateDefault = moment(new Date())?.format(DATE_FORMAT);

  const [r, e, n] = await WaitingQueueReport.get({
    params: {
      start_date: startDateDefault,
      end_date: endDateDefault,
    },
    apiToken: controller.apiToken,
  });

  controller.setState({
    summaryWaitingQueueList: r?.items,
  });

  if (params?.action === "search") {
    const [r, e, n] = await WaitingQueueReport.get({
      params: {
        start_date: params?.data.startDate,
        end_date: params?.data?.endDate,
        division_id: params?.data?.division?.id,
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      summaryWaitingQueueList: r?.items,
    });
  }
};

export const GetSummaryStatistics: Handler = async (controller, params) => {
  const dateDefault = moment(new Date())?.format(DATE_FORMAT);

  const [r, e, n] = await AppointmentReportList.get({
    params: {
      start_date: dateDefault,
      end_date: dateDefault,
    },
    apiToken: controller.apiToken,
  });

  controller.setState({
    summaryStatisticsList: r?.items,
  });

  if (params?.action === "search") {
    const [r, e, n] = await AppointmentReportList.get({
      params: {
        start_date: params?.data.startDate,
        end_date: params?.data?.endDate,
        division_id: params?.data?.division?.id,
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      summaryStatisticsList: r?.items,
    });
  }
};

export const GetEncounterWithPatient: Handler = async (controller, params) => {
  const result = await EncounterPatientListOptimized.get({
    apiToken: controller.apiToken,
    params: {
      patient: params.patientId,
    },
  });

  controller.setState({
    encounterPatientList: (result[0]?.items || []).filter(
      (item: any) => !item.is_expired && item.status !== "CANCELED"
    ),
  });
};

export const ChangeDivision: Handler = (controller, params) => {
  const state = controller.getState();

  controller.setState({
    selectedDivision: state.divisionList?.find(
      (item: any) => item?.id === params.value
    ),
    appointmentList: [],
    selectedAppointment: null,
  });
};

export const SearchBlockList: Handler = async (controller, params) => {
  if (params.action === "clear") {
    return controller.setState({ searchBlockList: [] });
  }

  const blockList = await getDivisionServiceBlockFilter(controller, params);

  controller.setState({
    searchBlockList: blockList,
  });
};

export const HandleSelectAppointmentByOROrder: Handler = async (controller, params) => {
  const state = controller.getState()

  const orOrder = { ...state.selectedOrOrder || {} }
  if (!orOrder?.id) {
    console.log("Not selected Operating order")
    return;
  }

  console.log("HandleSelectAppointmentByOROrder patient: ", state.selectedPatient?.id)
  const appointments = await PatientAppointmentView.list({
    params: {
      patient_id: orOrder?.patient_id,
      exclude_cancel: true,
    },
    apiToken: controller.apiToken,
  });

  let selectedPatientApp = appointments?.[0]?.items?.find((pa: any) => {
    let filterOrder = pa.orders.filter((item: any) => item.id === orOrder.id && item.type === "operatingorder")
    return filterOrder.length > 0
  })

  console.log("HandleSelectAppointmentByOROrder: ", appointments?.[0], selectedPatientApp)
  // if (selectedPatientApp) {
    controller.setState({
      preOrderList: selectedPatientApp?.orders || [],
      selectedAppointment: selectedPatientApp || {},
      drugOrder: {}
    })
  // }
}

const waterMarkToPdf = async (dataUrl: string) => {
  const pdfDoc = await PDFDocument.create();
  const doc = await PDFDocument.load(dataUrl);
  const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());

  const url = "/static/fonts/THSarabunNew-Bold.ttf";
  pdfDoc.registerFontkit(fontkit);
  const fontBytes = await fetch(url).then((res) => res.arrayBuffer());

  const timesRomanFont = await pdfDoc.embedFont(fontBytes);
  copiedPages.forEach((page) => {
    const pageDraw = pdfDoc.addPage(page);
    pageDraw.drawText(
      "เอกสารควบคุม ห้ามเผยแพร่โดยไม่ได้รับอนุญาต \n\n คณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
      {
        font: timesRomanFont,
        x: 125,
        y: 290,
        size: 32,
        lineHeight: 30,
        opacity: 0.15,
        rotate: degrees(45),
      }
    );
  });
  // pdfDoc.setTitle(props.patient_name);
  // pdfDoc.setAuthor(props.author);

  const base64Data = await pdfDoc.saveAsBase64();

  const blob = base64toBlob("data:application/pdf;base64," + base64Data);
  const bloburl = URL.createObjectURL(blob);
  window.open(bloburl);
};

const getWidth = (text: string) => {
  const combine = text;
  const div = document.createElement("div");

  div.setAttribute(
    "style",
    "width:fit-content;font-size:15px;font-family:'THSarabunNew', sans-serif;"
  );
  div.innerHTML = combine;
  document.body.appendChild(div);

  const width = div.offsetWidth;

  div.remove();

  return width;
};

export const truncateString = (text: string, width = 230) => {
  const ellipseWidth = getWidth("...");
  const maxWidth = width;

  return text.split("").reduce(
    (result, char, index) => {
      if (result.end) return result;

      const combine = result.text + char;

      const nextCharWidth = getWidth(text.split("")[index + 1]);
      const width = getWidth(result.text + char);

      const end = width > maxWidth && !!nextCharWidth;
      const findStack = result.stacks.find(
        (item) => item.width < maxWidth - ellipseWidth
      );

      return {
        end,
        text: end && combine !== text ? findStack?.text + "..." : combine,
        stacks: [{ width, text: combine }, ...result.stacks]
      };
    },
    { text: "", end: false, stacks: [] as { width: number; text: string }[] }
  );
};