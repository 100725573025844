import React, { useEffect, useMemo, SyntheticEvent, useState } from "react";

import CardDischargeSummaryNurseUX from "./CardDischargeSummaryNurseUX";
import CardDischargePlanningUX from "./CardDischargePlanningUX";
import CardDisChargeTeachingUX from "./CardDischargeTeachingUX";
import CardSummaryPlanningUX from "./CardSummaryPlanningUX";
import { Dimmer, Loader } from "semantic-ui-react";

const CARD_DISCHARGE_SUMMARY_NURSE = "CardDischargeSummaryNurse";
const CardDischargeSummaryNurse = (props: any) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    props.runSequence({ sequence: "DischargeNurse", action: "Formdata" });
  }, []);

  const handleChangeValue = (key: string) => (e: any, v: any) => {
    props.setProp(`ClinicalTermPlanning.${key}`, v.value);
    console.log("value", v);
  };

  const handleCheckValue = (key: string) => (e: any, v: any) => {
    props.setProp(`ClinicalTermPlanning.${key}`, v.checked);
  };

  const handleChangeValueTeach = (key: string) => (e: any, v: any) => {
    props.setProp(`ClinicalTermTeaching.${key}`, v.value);
  };

  const handleChangeValueNursing = (key: string) => (e: any, v: any) => {
    props.setProp(`ClinicalTermNursing.${key}`, v.value);
  };
  const handleCheckValueNursing = (key: string) => (e: any, v: any) => {
    props.setProp(`ClinicalTermNursing.${key}`, v.checked);
  };

  const handleAddOptions = (e: SyntheticEvent, v: any) => {
    console.log("handleAddOptions", e, v);

    if (v.name === "relative") {
      props.setProp("typeOptionsRelative", [
        ...props.typeOptionsRelative,
        { key: v.value, text: v.value, value: v.value },
      ]);
    }
    if (v.name === "knowledge") {
      props.setProp("typeOptionKnowledge", [
        ...props.typeOptionKnowledge,
        { key: v.value, text: v.value, value: v.value },
      ]);
    }
    if (v.name === "method") {
      props.setProp("TypeOptionsMethod", [
        ...props.typeOptionsMethod,
        { key: v.value, text: v.value, value: v.value },
      ]);
    }
  };

  useEffect(() => {
    if (
      props.FormDataDischargePlanning !== null &&
      props.FormDataDischargeTeaching !== null &&
      props.FormDataDischargeNursing !== null &&
      props.ClinicalTermPlanning !== null &&
      props.ClinicalTermTeaching !== null &&
      props.ClinicalTermNursing !== null
    ) {
      setIsLoading(false);
    }
  }, [
    props.FormDataDischargePlanning,
    props.FormDataDischargeTeaching,
    props.FormDataDischargeNursing,
    props.ClinicalTermPlanning,
    props.ClinicalTermTeaching,
    props.ClinicalTermNursing,
  ]);

  console.log("Data", props.ClinicalTermTeaching);

  console.log("test1", props.FormDataDischargePlanning);
  console.log("test2", props.FormDataDischargeTeaching);
  console.log("test3", props.FormDataDischargeNursing);
  console.log("test4", props.ClinicalTermPlanning);
  console.log("test5", props.ClinicalTermTeaching);
  console.log("test6", props.ClinicalTermNursing);

  return (
    <div style={{ position: "relative", paddingBottom: "15rem" }}>
      <div>
        <Dimmer active={isLoading} inverted>
          <Loader inverted>{"loading"}</Loader>
        </Dimmer>
      </div>
      {!isLoading && (
        <CardDischargeSummaryNurseUX
          OnSave={() => {
            props.runSequence({ sequence: "DischargeNurse", action: "Save" });
          }}
          DischargePlanning={
            <CardDischargePlanningUX
              ClinicalTermPlanning={props.ClinicalTermPlanning}
              handleChangeValue={handleChangeValue}
              handleCheckValue={handleCheckValue}
              showStatus={
                props?.FormDataDischargePlanning?.form_data_status_name ===
                "Draft"
              }
            />
          }
          onDischargeSummaryPrint={() => {
            props.runSequence({
              sequence: "DischargeNurse",
              action: "printDischargeSummary",
              cardName: CARD_DISCHARGE_SUMMARY_NURSE
            });
          }}
          printLoading={props.buttonLoadCheck?.[CARD_DISCHARGE_SUMMARY_NURSE] === "LOADING"}
          DischargeTeaching={
            <CardDisChargeTeachingUX
              ClinicalTermTeaching={props.ClinicalTermTeaching}
              handleChangeValue={handleChangeValueTeach}
              handleCheckValue={handleCheckValue}
              onAddOptions={handleAddOptions}
              showStatus={
                props?.FormDataDischargeTeaching?.form_data_status_name ===
                "Draft"
              }
              options={props.typeOptionsRelative}
              optionsmethod={props.typeOptionsMethod}
              optionstype={props.typeOptionKnowledge}
            />
          }
          DischargeSummaryPlanning={
            <CardSummaryPlanningUX
              ClinicalTermNursing={props.ClinicalTermNursing}
              handleChangeValue={handleChangeValueNursing}
              handleCheckValue={handleCheckValueNursing}
              showStatus={
                props?.FormDataDischargeNursing?.form_data_status_name ===
                "Draft"
              }
            />
          }
        />
      )}
    </div>
  );
};

export default CardDischargeSummaryNurse;
