import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const PatientLayout = (props: {
  layout?: number,
  patientList: any,
  patientPanel: any,
  patientRecord: any,
  resetLayout?: boolean,
  initialLayout?: number,
  LAYOUT_SETTINGS: any[],
  onBackward?: any,
  onForward?: any,
  onClearEncounter?: any,
}) => {

  const [layout, setLayout] = useState(0);
  const history = useHistory();

  const handleLayoutSwitch = (direction: string="forward") => {
    if (direction === "forward") {
      if (layout + 1 === props.LAYOUT_SETTINGS.length) return;
      props?.onForward?.(layout);
      setLayout(layout + 1);
    } else if (direction === "backward") {
      if (layout === 0) return;
      props?.onBackward?.(layout);
      setLayout(layout - 1);
      history.replace("?app=HIS")
    }
  }

  useEffect(() => {
    if (props.layout && Number.isInteger(layout))
      setLayout(props.layout)
  }, [props.layout])

  useEffect(() => {
    setLayout(0); 
  }, [props.resetLayout]);

  useEffect(() => {
    if(typeof props.initialLayout === "number"){
      setLayout(props.initialLayout)
    }
    return () =>{
      props.onClearEncounter?.()
    } 
  }, [props.initialLayout])

  return (
    <div style={{height: "100%", width: "100%"}}>
      
      {/* Header row */}
      {(props.LAYOUT_SETTINGS[layout]?.backward === true || props.LAYOUT_SETTINGS[layout]?.forward === true) &&
      <div style={{display: "flex", justifyContent: "space-between", borderBottom: "solid #cccccc 1px"}}>
        {props.LAYOUT_SETTINGS[layout]?.backward === true ?
        <div
          style={{marginRight: "10px", cursor: "pointer"}}
          className="patient-layout-backward"
          onClick={(e: any) => handleLayoutSwitch("backward")}>
          {layout > 0 ? '<<<' : ""}
        </div> : <div></div>}
        {props.LAYOUT_SETTINGS[layout]?.forward === true ?
        <div 
          style={{marginRight: "10px", cursor: "pointer"}}
          onClick={(e: any) => handleLayoutSwitch("forward")}>
          {layout + 1 < props.LAYOUT_SETTINGS.length ? '>>>' : ""}
        </div> : <div></div>}
      </div>}

      {/* Content */}
      <div style={{display: "flex", height: "100%", width: "100%"}}>
        {props.LAYOUT_SETTINGS[layout].patientList.display &&
        <div style={{height: "100%", flex: props.LAYOUT_SETTINGS[layout].patientList.flex}}> 
          {props.patientList({
            size: props.LAYOUT_SETTINGS[layout].patientList.size,
            layout: layout,
            setLayout: setLayout,
            forward: () => handleLayoutSwitch("forward"),
            backward: () => handleLayoutSwitch("backward"),
          })}
        </div>}
        
        {props.LAYOUT_SETTINGS[layout].patientPanel.display &&
        <div style={{height: "100%", width: "180px", maxWidth: "180px", flex: props.LAYOUT_SETTINGS[layout].patientPanel.flex}}>
          {props.patientPanel({
            size: props.LAYOUT_SETTINGS[layout].patientPanel.size,
            layout: layout,
            setLayout: setLayout,
            forward: () => handleLayoutSwitch("forward"),
            backward: () => handleLayoutSwitch("backward"),
          })}
        </div>}

        {props.LAYOUT_SETTINGS[layout].patientRecord.display &&
        <div style={{height: "100%", flex: props.LAYOUT_SETTINGS[layout].patientRecord.flex}}>
          {props.patientRecord({
            size: props.LAYOUT_SETTINGS[layout].patientRecord.size,
            layout: layout,
            setLayout: setLayout,
            forward: () => handleLayoutSwitch("forward"),
            backward: () => handleLayoutSwitch("backward"),
          })}
        </div>}
      </div>

    </div>
  )
}

export default PatientLayout;