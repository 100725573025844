import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label
} from 'semantic-ui-react'

const CardTourniquetUX = (props: any) => {
    return(
      <div
        style={{ margin: "15px", padding: "20px", backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", fontSize: "22px" }}>
            Tourniquet (C)
          </label>
          <Label
            color={props.statusColor}
            style={{ float: "right" }}>
            {props.status}
          </Label>
          <hr>
          </hr>
        </div>
        <div>
          {}
          <div>
            {props.round}
          </div>
        </div>
        <div
          style={{ height: "55px", paddingTop: "15px" }}>
          
          <div
            style={{display:"flex",justifyContent:"flex-end"}}>
            
            <div
              style={{margin:"10px"}}>
              {props.buttonSave}
            </div>
            <div
              style={{margin:"10px"}}>
              {props.buttonConfirm}
            </div>
            <div
              style={{margin:"10px"}}>
              {props.buttonUnconfirm}
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardTourniquetUX

export const screenPropsDefault = {}

/* Date Time : Wed Sep 14 2022 12:28:13 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\", padding: \"20px\", backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Tourniquet (C)"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"22px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        },
        "color": {
          "type": "code",
          "value": "props.statusColor"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"flex-end\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\" }"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "code",
          "value": "props.round"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 51,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardTourniquetUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 90
}

*********************************************************************************** */
