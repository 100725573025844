import * as SettingPackageI from "./SettingPackage";
import * as PackagePurchaseI from "./PackagePurchase";
import * as PackageDateTimeI from "./PackageDateTime";

const SequencePattern: {
  [name: string]: { [flavor: string]: { [func: string]: any } };
} = {
  SettingPackage: {
    default: {
      START: SettingPackageI.GetMaster,
      Action: SettingPackageI.Action,
    },
  },
  PackagePurchase: {
    default: {
      START: PackagePurchaseI.GetMaster,
      SearchPackage: PackagePurchaseI.SearchPackage,
      PurchaseList: PackagePurchaseI.PurchaseList,
      PurchaseHistory: PackagePurchaseI.PurchaseHistory,
    },
  },
  PackageDateTime: {
    default: {
      START: PackageDateTimeI.GetMaster,
      Action: PackageDateTimeI.Action,
    },
  }
};

export default SequencePattern;
