import React, { useEffect, useState } from "react";
// ui common
import SnackMessage from "../../common/SnackMessage";
import CardORRequest from "./CardORRequest";
import CardStaffORRequestUX from "./CardStaffORRequestUX";
import CardSelectDateTimeOperation from "../../Scheduling/CardSelectDateTimeOperation";
import CardOtherOrderAppointment from "../../Scheduling/CardOtherOrderAppointment";

const CARD_KEY = "CardStaffORRequest"

type CardStaffORRequestProps = {
    // function
    onEvent?: any;
    setProp?: any;
    runSequence?: any;

    // CommonInterface
    errorMessage?: any;
    successMessage?: any;
    buttonLoadCheck?: any;
    searchedItemListWithKey?: any;
    loadingStatus?: any
    userTokenize?: any
    divisionId?: any;

    // select OR
    selectedOrOrder?: any;
    proxyController?: any;
    preOrderList?: any;

    // select operating time
    selectedOperatingDetail?: any,
    selectedDoctor?: any,
    selectedAnesthesiologist?: any,
    selectedOperatingRoom?: any,
    operatingBlock?: any,
    selectOperatingDSBChange?: any,
    estimateTimeText?: string,

    // other order
    config?: any;
    treatmentOrderList?: any[];
    searchedItemList?: any;
    procedureItems?: any[];
    secondaryItems?: any[];
    treatmentTemplateList?: any[];
    selectedAppointment?: any;
    selectedEncounter?: any;
    selectedEmr?: any;
    selectedProgressCycle?: any;
    doctorLabOrderList?: any[];
    imagingList?: any[];
    orderImagingList?: any[];
    selectedOperatingOption?: any;
    selectedDrugItemDetail?: any;
    selectedDrugItemDetailOptions?: any[];
    drugSearchText?: string;
    drugSearchResult?: any[];
    selectedDrug?: any;
    drugOrderList?: any;
    drugOrder?: any
    renderDrugOrderItems?: any;
    drugDose?: any
    drugQty?: any
    drugDuration?: any
    drugPrn?: boolean,
    modDoctorCertificate?: any;
    modDrugLabInteraction?: any;
    modDrugInteraction?: any;
    modDrugDisease?: any;
    ModDrugInteraction?: any;
    currentDoctor?: any;
    modReMedication?: any;
    modConfirmDeleteDrug?: any;
    drugOrderItemEstimate?: any[]
    drugPermission?: any;
    modSolventSelectionConfig?: any;
    selectedVerbalDrugOrderWorking?: any;

    // options
    masterOptions?: any;
    masterData?: any;

    // seq
    ORRequestSequence?: any;
    OperatingDateTimeSequence?: any;
    OrderTreatmentSequence?: any;
    OrderCentralLabSequence?: any;
    DrugSelectSequence?: any;
    DrugOrderTemplateSequence?: any;

    // case or drug queue
    drugOrderTabMode?: string
    divisionType?: string
}

const CardStaffORRequestInitailProps: CardStaffORRequestProps = {
    // funtion
    onEvent: () => null,
    setProp: () => null,
    runSequence: () => null,

    // CommonInterface
    errorMessage: null,
    successMessage: null,
    buttonLoadCheck: null,
    loadingStatus: null,
    searchedItemListWithKey: {},
    userTokenize: null,
    divisionId: null,

    // select OR
    selectedOrOrder: {},
    proxyController: null,
    preOrderList: [],

    // select operating time
    selectedOperatingDetail: null,
    selectedDoctor: null,
    selectedAnesthesiologist: null,
    selectedOperatingRoom: null,
    operatingBlock: null,
    selectOperatingDSBChange: null,
    estimateTimeText: "",

    // other order
    config: null,
    treatmentOrderList: [],
    searchedItemList: null,
    procedureItems: [],
    secondaryItems: [],
    treatmentTemplateList: [],
    selectedAppointment: null,
    selectedEncounter: null,
    selectedEmr: null,
    selectedProgressCycle: null,
    doctorLabOrderList: [],
    selectedOperatingOption: null,
    selectedDrugItemDetail: null,
    selectedDrugItemDetailOptions: [],
    drugSearchText: "",
    drugSearchResult: [],
    selectedDrug: null,
    drugOrderList: [],
    drugOrder: null,
    renderDrugOrderItems: () => null,
    drugDose: null,
    drugQty: null,
    drugDuration: null,
    drugPrn: false,
    modDoctorCertificate: {},
    modReMedication: null,
    modConfirmDeleteDrug: null,
    modDrugLabInteraction:{},
    modDrugDisease:{},
    modDrugInteraction: {},
    currentDoctor:{},
    drugOrderItemEstimate: [],
    drugPermission: null,
    modSolventSelectionConfig: null,
    selectedVerbalDrugOrderWorking: null,

    // options
    masterOptions: {},
    masterData: {},

    // seq
    ORRequestSequence: null,
    OperatingDateTimeSequence: null,
    OrderTreatmentSequence: null,
    OrderCentralLabSequence: null,
    DrugSelectSequence: null,

    // case Drug OR queue
    drugOrderTabMode: "",
    divisionType: "",
}

const CardStaffORRequest: React.FC<CardStaffORRequestProps> = (props: any) => {
    console.log('Init CardStaffORRequest ');
    const [containerName, setContainerName] = useState<string>("");
    const [modOrder, setModOrder] = useState("")

    useEffect(() => {
        props.onEvent({
            message: "HandleSelectAppointmentByOROrder",
            params: {}
        })
        // console.log("CardStaffORRequest: HandleSelectAppointmentByOROrder")
        if(props.drugOrderTabMode && props.divisionType === "ห้องยา"){
            setContainerName("OtherAppointmentOrder")
        }else{
            setContainerName("ORRequest")
        }      
    }, [])

    const handleSetContainerName = (_event: any, data: any) => {
        setContainerName(data.name);
    };

    console.log("CardStaffORRequest props: ", props)

    return (<>
        {(props.errorMessage?.[CARD_KEY] || props.successMessage?.[CARD_KEY]) && (
            <SnackMessage
                onEvent={props.onEvent}
                onClose={() => {
                    props.setProp(`errorMessage.${CARD_KEY}`, null);
                    props.setProp(`successMessage.${CARD_KEY}`, null);
                }}
                error={props.errorMessage?.[CARD_KEY]}
                success={props.successMessage?.[CARD_KEY]}
            />
        )}
        <CardStaffORRequestUX
            onSetContainerName={handleSetContainerName}
            containerName={containerName}
        />
        {containerName === "ORRequest" ? (
            <CardORRequest
                // callback
                onEvent={props.onEvent}
                setProp={props.setProp}
                setTab={setContainerName}
                setModOrder={setModOrder}
                runSequence={props.runSequence}
                // controller
                proxyController={props.proxyController}
                // data
                ORRequestSequence={props.ORRequestSequence}
                searchedItemListWithKey={props.searchedItemListWithKey}
                masterData={props.masterData}
                encounterId={props.selectedOrOrder?.encounter}
                operatingOrderId={props.selectedOrOrder?.id}
                isNewEncounter={props.selectedOrOrder?.is_new_order_encounter}
                patientId={props.selectedOrOrder?.patient}
                loadingStatus={props.loadingStatus}
                buttonLoadCheck={props.buttonLoadCheck}
                userTokenize={props.userTokenize}
                divisionId={props.divisionId}
                cardStyle={{ height: "" }}
                // patientAppointmentId={props.selectedOrOrder?.id}
                errorMessage={props.errorMessage}
                // options
                masterOptions={props.masterOptions}
                // config
                isConfirm={props.selectedOrOrder?.status === 1? true: false}
                isCancelConfirm={props.selectedOrOrder?.status === 2? true: false}
                isCancel={true}
            />
        ) : containerName === "SelectedOperatingTime" ? (
            <CardSelectDateTimeOperation
                runSequence={props.runSequence}
                masterOptions={props.masterOptions}
                operatingLocationRoom={props.operatingLocationRoom}
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                setProp={props.setProp}
                // data
                preOrderList={props.preOrderList}
                OperatingDateTimeSequence={props.OperatingDateTimeSequence}
                selectedOrOrder={props.selectedOrOrder}
                selectedOperatingDetail={props.selectedOperatingDetail}
                selectedDoctor={props.selectedDoctor}
                selectedAnesthesiologist={props.selectedAnesthesiologist}
                selectedOperatingRoom={props.selectedOperatingRoom}
                operatingBlock={props.operatingBlock}
                selectOperatingDSBChange={props.selectOperatingDSBChange}
                estimateTimeText={props.estimateTimeText}
                selectedAppointment={props.selectedAppointment}
                isSelectTabCalendar={props.isSelectTabCalendar}
            />
        ) : containerName === "OtherAppointmentOrder" ? (
            <CardOtherOrderAppointment
                controller={props.controller}
                onEvent={props.onEvent}
                setProp={props.setProp}
                config={props.config}
                runSequence={props.runSequence}
                masterOptions={props.masterOptions}

                // drugOperatingOrderId={props.preOrderList?.find((item: any) => item.type === "drugoperatingorder")?.id}
                setTab={setContainerName}
                setModOrder={setModOrder}
                ORRequestSequence={props.ORRequestSequence}

                OrderTreatmentSequence={props.OrderTreatmentSequence}
                treatmentOrderList={props.treatmentOrderList}

                searchedItemList={props.searchedItemList}
                successMessage={props.successMessage}
                errorMessage={props.errorMessage}
                procedureItems={props.procedureItems}
                secondaryItems={props.secondaryItems}
                treatmentTemplateList={props.treatmentTemplateList}
                selectedAppointment={props.selectedAppointment}

                // central lab
                OrderCentralLabSequence={props.OrderCentralLabSequence}
                selectedEncounter={props.selectedEncounter}
                selectedEmr={props.selectedEmr}
                selectedProgressCycle={props.selectedProgressCycle}
                doctorLabOrderList={props.doctorLabOrderList}
                // imaging
                imagingList={props.imagingList}
                orderImagingList={props.orderImagingList}
                loadingStatus={props.loadingStatus}
                buttonLoadCheck={props.buttonLoadCheck}
                selectedOperatingOption={props.selectedOperatingOption}

                //CardDrugOrder 
                selectedDrugItemDetail={props.selectedDrugItemDetail}
                selectedDrugItemDetailOptions={
                    props.selectedDrugItemDetailOptions
                }
                drugSearchText={props.drugSearchText}
                drugSearchResult={props.drugSearchResult}
                DrugSelectSequence={props.DrugSelectSequence}
                selectedDrug={props.selectedDrug}
                drugOrderList={props.drugOrderList}
                drugOrder={props.drugOrder}
                renderDrugOrderItems={props.renderDrugOrderItems}
                drugDose={props.drugDose}
                drugQty={props.drugQty}
                drugDuration={props.drugDuration}
                drugDescription={props.drugDescription}
                drugPrn={props.drugPrn}
                // modal doctor certificate check
                modDoctorCertificate={props.modDoctorCertificate}
                // modal drug order template
                DrugOrderTemplateSequence={props.DrugOrderTemplateSequence}
                modReMedication={props.modReMedication}
                modConfirmDeleteDrug={props.modConfirmDeleteDrug}
                modDrugLabInteraction={props.modDrugLabInteraction}
                modDrugDisease={props.modDrugDisease}
                modDrugInteraction={props.modDrugInteraction}
                currentDoctor={props.currentDoctor}
                drugOrderItemEstimate={props.drugOrderItemEstimate}
                drugPermission={props.drugPermission}
                modSolventSelectionConfig={
                    props.modSolventSelectionConfig
                }
                selectedVerbalDrugOrderWorking={
                    props.selectedVerbalDrugOrderWorking
                }
                preOrderList={props.preOrderList}
                defaultModOrder={modOrder}
                defaultOrderBy={true}
            />
        )
            : <></>}
    </>)
}

CardStaffORRequest.defaultProps = CardStaffORRequestInitailProps;

export default React.memo(CardStaffORRequest);