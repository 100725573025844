import React from "react";
import { Menu, Dropdown, Button, Icon } from "semantic-ui-react";

const TopBar = (props: any) => {
  return (
    <Menu secondary className="topbar-menu">
      <Menu.Item>
        <Dropdown
          style={{
            color: "white",
            backgroundColor: "transparent",
            cursor: "pointer",
          }}
          button
          className={"icon"}
          floating
          labeled
          icon={<Icon name="hospital" style={{ fontSize: "1.3em" }} />}
          options={props.divisionOptions}
          search
          value={props.selectedDivision?.id}
          placeholder={"Select Division"}
          onChange={(e: any, v: any) =>
            props.onEvent({
              message: "ChangeDivision",
              params: { divisionId: v.value },
            })
          }
          onOpen={() => {
            // this.setState({
            //   currentDivision: null,
            //   division: null,
            // });
          }}
        />
        <Dropdown
          style={{
            color: "white",
            backgroundColor: "transparent",
            cursor: "pointer",
          }}
          button
          className={"icon"}
          floating
          labeled
          icon={<Icon name="hospital outline" style={{ fontSize: "1.3em" }} />}
          options={props.deviceOptions}
          search
          value={props.selectedDevice?.id}
          placeholder={"Select Device"}
          onChange={(e: any, v: any) =>
            props.onEvent({
              message: "ChangeDevice",
              params: { deviceId: v.value },
            })
          }
          onOpen={() => {
            // this.setState({
            //   currentDivision: null,
            //   division: null,
            // });
          }}
        />
      </Menu.Item>
      <Menu.Item>
        <Button onClick={(e: any) => props.setProp("mode", "admin")}>
          Admin
        </Button>
        <Button onClick={(e: any) => props.setProp("mode", "patient")}>
          Patient
        </Button>
        {/* <Button onClick={(e: any) => props.setProp("mode", "segment")}>
          Segment tool
        </Button> */}
      </Menu.Item>
      <Menu.Item position="right">
        <div style={{ position: "relative", marginRight: "22px" }}>
          <Icon
            name="bell"
            style={{
              fontSize: "1.4em",
              color: "white",
              cursor: "pointer",
            }}
            onClick={props.onClickBell}
          />
          {props.showIconNoti && (
            <div
              style={{
                width: "8px",
                height: "8px",
                backgroundColor: "red",
                borderRadius: "50%",
                position: "absolute",
                top: "2px",
                right: "6px",
              }}
            ></div>
          )}
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={(e: any) => props.setProp("mode", "user")}
        >
          <Icon
            name="user doctor"
            style={{ color: "white", fontSize: "1.3em" }}
          />
        </div>
        <Button
          style={{ paddingLeft: "3px" }}
          onClick={(e: any) => props.setProp("mode", "user")}
        >
          {props.django?.user?.full_name}
        </Button>
        <Button
          onClick={(e: any) => props.onEvent({ message: "Logout", params: {} })}
        >
          Logout
        </Button>
      </Menu.Item>
      {/* {this.getLeftMenuItem()}
  {this.getRightMenuItem()} */}
    </Menu>
  );
};

export default TopBar;
