import WasmController from 'react-lib/frameworks/WasmController';
import DiagnosisMedicalRecordDetail from "issara-sdk/apis/DiagnosisMedicalRecordDetail_apps_DPO";
import DiagnosisList from "issara-sdk/apis/DiagnosisList_apps_DPO";
import DiagnosisTemplateList from "issara-sdk/apis/DiagnosisTemplateList_apps_DPO";
import EstimateDoctorOrder from "issara-sdk/apis/EstimateDoctorOrder_apps_DPO";
import DoctorConsultOrderDetail from "issara-sdk/apis/DoctorConsultOrderDetail_apps_DPO";
import DoctorConsultOrderList from "issara-sdk/apis/DoctorConsultOrderList_apps_DPO";
import DoctorDischargePatient from "issara-sdk/apis/DoctorDischargePatient_apps_DPO";
import MedicalRecordGalleryList from "issara-sdk/apis/MedicalRecordGalleryList_apps_DPO";
import MedicalRecordPictureDetail from "issara-sdk/apis/MedicalRecordPictureDetail_apps_DPO";
import TemplateGalleryList from "issara-sdk/apis/TemplateGalleryList_apps_DPO";
import DoctorCertificatesTypeList from "issara-sdk/apis/DoctorCertificatesTypeList_apps_DPO";
import DiagnosisSummaryList from "issara-sdk/apis/DiagnosisSummaryList_apps_DPO";
import DoctorCertificatesPrintLogList from "issara-sdk/apis/DoctorCertificatesPrintLogList_apps_DPO";
import DoctorCertificatesList from "issara-sdk/apis/DoctorCertificatesList_apps_DPO";
import DoctorCertificatesDetail from "issara-sdk/apis/DoctorCertificatesDetail_apps_DPO";
import SensitiveNoteList from "issara-sdk/apis/ClassifiedPatientHistoryList_apps_DPO"
import SensitiveNoteDetail from "issara-sdk/apis/ClassifiedPatientHistoryDetail_apps_DPO"
import PatientHistoryList from "issara-sdk/apis/PatientHistoryList_apps_DPO"
import PatientHistoryDetail from "issara-sdk/apis/PatientHistoryDetail_apps_DPO"

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "apiToken", params: any }
  |  { message: "dpoService", params: any }
  |  { message: "getMedicalRecord", params: any }
  |  { message: "discharged", params: any }
  |  { message: "cancelCheckIn", params: any }
  |  { message: "getDiagnosis", params: any }
  |  { message: "updateDiagnosis", params: any }
  |  { message: "getPatientDiagnosis", params: any }
  |  { message: "getClinialType", params: any }
  |  { message: "getDiagnosisTemplate", params: any }
  |  { message: "getDoctorCertificateType", params: any }
  |  { message: "postDoctorCertificate", params: any }
  |  { message: "getDiagnosisSummary", params: any }
  |  { message: "getDoctorCertificatesPrintlog", params: any }
  |  { message: "getDoctorOrder", params: any }
  |  { message: "putDoctorOrder", params: any }
  |  { message: "postDoctorConsultOrder", params: any }
  |  { message: "getDoctorConsultOrderWithId", params: any }
  |  { message: "updateDoctorConsultOrderWithId", params: any }
  |  { message: "getMedicalRecordGallery", params: any }
  |  { message: "postMedicalRecordGallery", params: any }
  |  { message: "putMedicalRecordPicture", params: any }
  |  { message: "getTemplateGalleryImage", params: any }
  |  { message: "_convertToConsultOrderModel", params: any }
  |  { message: "estimateDoctorOrder", params: any }
  |  { message: "getMedicalRecordEmrDetail", params: any }
  |  { message: "getPatientHistoryEMR", params: any }
  |  { message: "getSensitiveNoteEMR", params: any }
  |  { message: "postSensitiveNoteEMR", params: any }
  |  { message: "putSensitiveNoteEMR", params: any }
  |  { message: "putPatientHistoryEMR", params: any }
  |  { message: "getPhysicalExamOrgan", params: any }
  |  { message: "postPatientScreenNew", params: any }
  |  { message: "putPatientScreenUpdate", params: any }
  |  { message: "patchPatientDiagnosis", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

export const CONSULT_ORDER_MODEL = {
  id: null,
  action: "",
  encounter: null,
  order_encounter: null,
  order_status: null,
  division: "",
  consulted_doctor: "",
  note: "",
  urgency: ""
};

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const apiToken: Handler = async (controller, {}) => {
//undefined
//
//----> Name mismatch between manager and service
}

export const dpoService: Handler = async (controller, {}) => {
//[object Object]
//
//----> Name mismatch between manager and service
}

export const getMedicalRecord: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            var medicalRecord, medicalRecordError, dpoService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        medicalRecord = [];
//                        medicalRecordError = null;
//                        dpoService = new DPOService_1.default();
//                        return [4 /*yield*/, utils_1.to(dpoService.getMedicalRecord(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        if (response) {
//                            medicalRecord = response.items;
//                        }
//                        else {
//                            medicalRecordError = error;
//                        }
//                        return [2 /*return*/, [medicalRecord, medicalRecordError, network]];
//                }
//            });
//        }); }
//function (params) {
//        return this.client
//            .get(apis_1.DPO.MEDICAL_RECORD, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/DPO/medical_record/
}

export const discharged: Handler = async (controller, {emrId, checkoutCause, params, division}) => {
  let params_: any = {
    checkout_cause: checkoutCause,
    death_datetime: params?.deathDateTime,
    doctor_fee: params?.doctorFee,
    doctor_fee_rule: params?.doctorFeeRule,
    doctor_fee_remark: params?.doctorFeeRemark,
    require_diagnosis: params?.require_diagnosis,
    approve_by: params?.approve_by,
  };
  return await DoctorDischargePatient.update({
    pk: emrId,
    data: params_,
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
  });
}

export const cancelCheckIn: Handler = async (controller, {}) => {
//function (emrId) { return __awaiter(_this, void 0, void 0, function () {
//            var dpoService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        dpoService = new DPOService_1.default();
//                        return [4 /*yield*/, utils_1.to(dpoService.postCancelCheckIn(emrId))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const getDiagnosis: Handler = async (controller, emrId) => {
  return await DiagnosisMedicalRecordDetail.retrieve({
    pk: emrId,
    apiToken: controller.apiToken
  });
}

export const updateDiagnosis: Handler = async (controller, {emrId, dPrimaryList, dSecondaryList}) => {
  const params = {
    principal_diagnosis: dPrimaryList,
    secondary_diagnosis: dSecondaryList,
  };
  return await DiagnosisMedicalRecordDetail.update({
    pk: emrId,
    data: params,
    apiToken: controller.apiToken
  });
}

export const getPatientDiagnosis: Handler = async (controller, {patientId, params}) => {
  let params_: any = {
      patient_id: patientId,
  };
  if (params?.divisionId) {
      params_.division = params.divisionId;
  }
  if (params?.doctorId) {
      params_.doctor = params.doctorId;
  }
  if (params?.isOnlyICD10) {
      params_.only_current_icd10_version = params.isOnlyICD10;
  }
  return await DiagnosisList.list({
    params: params,
    apiToken: controller.apiToken
  });
//function (patientId, _a) {
//            var _b = _a === void 0 ? {} : _a, divisionId = _b.divisionId, doctorId = _b.doctorId, isOnlyICD10 = _b.isOnlyICD10;
//            return __awaiter(_this, void 0, void 0, function () {
//                var diagnosisData, diagnosisError, params, dpoService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            diagnosisData = [];
//                            diagnosisError = null;
//                            params = {
//                                patient_id: patientId,
//                            };
//                            if (divisionId) {
//                                params.division = divisionId;
//                            }
//                            if (doctorId) {
//                                params.doctor = doctorId;
//                            }
//                            if (isOnlyICD10) {
//                                params.only_current_icd10_version = isOnlyICD10;
//                            }
//                            dpoService = new DPOService_1.default();
//                            return [4 /*yield*/, utils_1.to(dpoService.getPatientDiagnosis(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            if (error) {
//                                diagnosisError = error;
//                            }
//                            else {
//                                diagnosisData = response.items;
//                            }
//                            return [2 /*return*/, [diagnosisData, diagnosisError, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.DPO.DIAGNOSIS, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/DPO/diagnosis/
}

export const getClinialType: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            var dpoService, noteType, noteError, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        dpoService = new DPOService_1.default();
//                        noteType = [];
//                        noteError = [];
//                        return [4 /*yield*/, utils_1.to(dpoService.getClinialType())];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        if (response) {
//                            noteType = response.items;
//                        }
//                        else {
//                            noteError = error;
//                        }
//                        return [2 /*return*/, [noteType, noteError, network]];
//                }
//            });
//        }); }
//function () {
//        return this.client
//            .get(apis_1.DPO.GET_CLINICAL_NOTE_TYPE)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/DPO/clinical-note/
}

export const getDiagnosisTemplate: Handler = async (controller, {name}) => {
  let params: any = {};
  if (name) {
      params.name__icontains = name;
  }
  const result = await DiagnosisTemplateList.list({
    params: params,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division
    }
  });
  return [result[0].items || [], result[1], result[2]]
}

export const getDoctorCertificateType: Handler = async (controller, {}) => {
  const result = await DoctorCertificatesTypeList.list({
    extra: {
      division: controller.data.division
    },
    apiToken: controller.apiToken
  });
  return [
    // result[0]?.items?.map((item: any) => ({ key: item.id, text: item.name, value: item.name})) || [],
    result[0]?.items || [],
    result[1]
  ];
}

export const postDoctorCertificate: Handler = async (controller, {
  id, emr, pdf, pdf_b64data, original, detail, type, 
  language, diagnosis, doctor
}) => {
  let params: any = {
    id: id ? id : "",
    emr: emr ? emr : "",
    pdf: pdf ? pdf : false,
    pdf_b64data: pdf_b64data ? pdf_b64data : "",
    original: !!original,
    detail: detail ? detail : "",
    type: type ? type : "",
    language: language ? language : "",
    diagnosis: diagnosis ? diagnosis : "",
    doctor: doctor ? doctor : "",
  };
  if (!id) {
    return await DoctorCertificatesList.create({
      data: params,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division
      },
    });
  } else {
    return await DoctorCertificatesDetail.update({
      pk: id,
      data: params,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division
      },
    });
  }
}

export const getDiagnosisSummary: Handler = async (controller, {emrId}) => {
  return await DiagnosisSummaryList.get({
    params: emrId ? { emr: emrId } : {},
    apiToken: controller.apiToken
  });
}

export const getDoctorCertificatesPrintlog: Handler = async (controller, 
  {startDate, endDate, patient, doctorId }) => {
  let params: any = {};
  if (startDate) {
    params.start_date = startDate;
  }
  if (endDate) {
    params.end_date = endDate;
  }
  if (patient) {
    params.patient = patient;
  }
  if (doctorId) {
    params.doctor = doctorId;
  }
  const result = await DoctorCertificatesPrintLogList.list({
    params: params,
    apiToken: controller.apiToken
  });
  return [result[0]?.items || result[0], result[1]];
}

export const getDoctorOrder: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, emrId = _b.emrId, progressionId = _b.progressionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var doctorOrderData, doctorOrderError, params, dpoService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            doctorOrderData = null;
//                            doctorOrderError = null;
//                            params = {};
//                            if (emrId) {
//                                params.emr = emrId;
//                            }
//                            if (progressionId) {
//                                params.progression_cycle = progressionId;
//                            }
//                            dpoService = new DPOService_1.default();
//                            return [4 /*yield*/, utils_1.to(dpoService.getDoctorOrder(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            if (error) {
//                                doctorOrderError = error;
//                            }
//                            else {
//                                doctorOrderData = response.items;
//                            }
//                            return [2 /*return*/, [doctorOrderData, doctorOrderError, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.DPO.DOCTOR_ORDER, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/DPO/doctor-order/
}

export const putDoctorOrder: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, orderId = _b.orderId, action = _b.action;
//            return __awaiter(_this, void 0, void 0, function () {
//                var data, dpoService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            data = {};
//                            if (action) {
//                                data.action = action;
//                            }
//                            dpoService = new DPOService_1.default();
//                            return [4 /*yield*/, utils_1.to(dpoService.putDoctorOrder({ orderId: orderId, data: data }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var params = _a.params, orderId = _a.orderId, data = _a.data;
//        var url = apis_1.DPO.DOCTOR_ORDER_CANCEL({ order_id: orderId });
//        return this.client
//            .put(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/DPO/doctor-order/' +
//((__t = (order_id)) == null ? '' : __t) +
//'/cancel';
//
//}
//return __p
//}
}

export const postDoctorConsultOrder: Handler = async (controller, order = CONSULT_ORDER_MODEL) => {
  console.log("postDoctorConsultOrder order:",order)
  const params = _convertToConsultOrderModel(controller, order);
  console.log("postDoctorConsultOrder params:",params)
  return await DoctorConsultOrderList.create({
    data: params,
    apiToken: controller.apiToken,
    extra: { division: controller.data.division }
  });
}

export const getDoctorConsultOrderWithId: Handler = async (controller, orderId) => {
  return await DoctorConsultOrderDetail.retrieve({
    pk: orderId,
    apiToken: controller.apiToken,
    extra: { division: controller.data.division }
  });
}

export const updateDoctorConsultOrderWithId: Handler = async (controller, order = CONSULT_ORDER_MODEL) => {
  let params = _convertToConsultOrderModel(controller, order);
  return await DoctorConsultOrderDetail.update({
    pk: order.id,
    data: params,
    apiToken: controller.apiToken,
    extra: { division: controller.data.division }
  });
}

export const getMedicalRecordGallery: Handler = async (controller, {emrId, params}) => {
  return await MedicalRecordGalleryList.list({
    emr: emrId,
    apiToken: controller.apiToken
  });
}

export const postMedicalRecordGallery: Handler = async (controller, {emrId, data}) => {
  return await MedicalRecordGalleryList.create({
    emr: emrId,
    data: data,
    apiToken: controller.apiToken
  });
}

export const putMedicalRecordPicture: Handler = async (controller, 
  {selectedImage, imageDataURI, detail, isActive = true }) => {
  let picId = selectedImage.id;
  let data: any = {
    emr: selectedImage.emr,
    description: detail,
    image: imageDataURI,
    is_active: isActive,
  };
  return await MedicalRecordPictureDetail.update({
    pk: picId,
    data: data,
    apiToken: controller.apiToken
  });
}

export const getTemplateGalleryImage: Handler = async (controller, {name}) => {
  return await TemplateGalleryList.list({
    params: name ? { name: name } : {},
    apiToken: controller.apiToken
  });
}

export const _convertToConsultOrderModel = (controller:any, order:any) => {
  return {
      id: order.id ? order.id : null,
      action: order.action ? order.action : "",
      encounter: order.encounter ? order.encounter : null,
      order_encounter: order.order_encounter ? order.order_encounter : null,
      order_status: order.order_status ? order.order_status : null,
      division: order.division ? order.division : "",
      consulted_doctor: order.consulted_doctor ? order.consulted_doctor : "",
      note: order.note ? order.note : "",
      urgency: order.urgency ? order.urgency : "",
  };
}

export const estimateDoctorOrder: Handler = async (controller, params) => {
  return await EstimateDoctorOrder.get({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getMedicalRecordEmrDetail: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, emrId = _b.emrId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, dpoService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            dpoService = new DPOService_1.default();
//                            return [4 /*yield*/, utils_1.to(dpoService.getMedicalRecordEmrDetail({ params: params, emrId: emrId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, emrId = _b.emrId;
//        var url = apis_1.DPO.MEDICAL_RECORD_EMR_DETAIL({ emrId: emrId });
//        return this.client
//            .get(url, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/DPO/medical_record/emr/' +
//((__t = (emrId)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getPatientHistoryEMR: Handler = async (controller, {emrId}) => {
  return await PatientHistoryDetail.retrieve({
    emr: emrId,
    apiToken: controller.apiToken
  });
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, emrId = _b.emrId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, dpoService, _c, error, data, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            dpoService = new DPOService_1.default();
//                            return [4 /*yield*/, utils_1.to(dpoService.getPatientHistoryEMR({ params: params, emrId: emrId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], data = _c[1], network = _c[2];
//                            return [2 /*return*/, [data, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, emrId = _b.emrId;
//        var url = apis_1.DPO.PATIENT_HISTORY_EMR({ emrId: emrId });
//        return this.client
//            .get(url, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/DPO/patient_history/emr/' +
//((__t = (emrId)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getSensitiveNoteEMR: Handler = async (controller, {emrId, divisionId}) => {
  return await SensitiveNoteDetail.retrieve({
    pk: emrId,
    params: { divisionId },
    apiToken: controller.apiToken
  });
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, emrId = _b.emrId, divisionId = _b.divisionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, dpoService, _c, error, data, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (divisionId) {
//                                params.div = divisionId;
//                            }
//                            dpoService = new DPOService_1.default();
//                            return [4 /*yield*/, utils_1.to(dpoService.getSensitiveNoteEMR({ params: params, emrId: emrId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], data = _c[1], network = _c[2];
//                            return [2 /*return*/, [data, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, emrId = _b.emrId;
//        var url = apis_1.DPO.SENSITIVE_NOTE_EMR({ emrId: emrId });
//        return this.client
//            .get(url, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/DPO/sensitive_note/emr/' +
//((__t = (emrId)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const postSensitiveNoteEMR: Handler = async (controller, {emrId, divisionId, sensitiveNote}) => {
  return await SensitiveNoteDetail.update({
    data: {
      emr: emrId,
      division: divisionId,
      sensitive_note: sensitiveNote
    },
    apiToken: controller.apiToken
  })
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, emrId = _b.emrId, divisionId = _b.divisionId, sensitiveNote = _b.sensitiveNote;
//            return __awaiter(_this, void 0, void 0, function () {
//                var data, dpoService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            data = {};
//                            if (divisionId) {
//                                data.division = [divisionId];
//                            }
//                            if (emrId) {
//                                data.emr = emrId;
//                            }
//                            if (sensitiveNote) {
//                                data.sensitive_note = sensitiveNote;
//                            }
//                            dpoService = new DPOService_1.default();
//                            return [4 /*yield*/, utils_1.to(dpoService.postSensitiveNoteEMR({ data: data }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, data = _b.data;
//        return this.client
//            .post(apis_1.DPO.SENSITIVE_NOTE, data)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/DPO/sensitive_note/
}

export const putSensitiveNoteEMR: Handler = async (controller, {emrId, divisionId, sensitiveNote}) => {
  return await SensitiveNoteDetail.update({
    pk: emrId,
    data: {
      emr: emrId,
      division: [divisionId],
      sensitive_note: sensitiveNote
    },
    apiToken: controller.apiToken
  })
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, emrId = _b.emrId, divisionId = _b.divisionId, sensitiveNote = _b.sensitiveNote;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, data, dpoService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            data = {};
//                            if (divisionId) {
//                                params.div = divisionId;
//                                data.division = [divisionId];
//                            }
//                            if (emrId) {
//                                data.emr = emrId;
//                            }
//                            if (sensitiveNote) {
//                                data.sensitive_note = sensitiveNote;
//                            }
//                            dpoService = new DPOService_1.default();
//                            return [4 /*yield*/, utils_1.to(dpoService.putSensitiveNoteEMR({ params: params, data: data, emrId: emrId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data, emrId = _b.emrId;
//        var url = apis_1.DPO.SENSITIVE_NOTE_EMR({ emrId: emrId });
//        return this.client
//            .put(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/DPO/sensitive_note/emr/' +
//((__t = (emrId)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const putPatientHistoryEMR: Handler = async (controller, {emrId, pastIllness, personalHistory, familyHistory }) => {
  return await PatientHistoryDetail.update({
    emr: emrId,
    data: {
      emr: emrId,
      past_illness: pastIllness ,
      personal_history: personalHistory ,
      family_history: familyHistory ,
    },
    apiToken: controller.apiToken
  })
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, emrId = _b.emrId, pastIllness = _b.pastIllness, personalHistory = _b.personalHistory, familyHistory = _b.familyHistory;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, data, dpoService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            data = {};
//                            if (emrId) {
//                                data.emr = emrId;
//                            }
//                            if (pastIllness) {
//                                data.past_illness = pastIllness;
//                            }
//                            if (personalHistory) {
//                                data.personal_history = personalHistory;
//                            }
//                            if (familyHistory) {
//                                data.family_history = familyHistory;
//                            }
//                            dpoService = new DPOService_1.default();
//                            return [4 /*yield*/, utils_1.to(dpoService.putPatientHistoryEMR({ params: params, data: data, emrId: emrId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data, emrId = _b.emrId;
//        var url = apis_1.DPO.PATIENT_HISTORY_EMR({ emrId: emrId });
//        return this.client
//            .put(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/DPO/patient_history/emr/' +
//((__t = (emrId)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getPhysicalExamOrgan: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            var dpoService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        dpoService = new DPOService_1.default();
//                        return [4 /*yield*/, utils_1.to(dpoService.getPhysicalExamOrgan())];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params;
//        return this.client
//            .get(apis_1.DPO.PHYSICAL_EXAM_ORGAN, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/DPO/physical_exam_organ/
}

export const postPatientScreenNew: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, data = _b.data;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, dpoService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            dpoService = new DPOService_1.default();
//                            return [4 /*yield*/, utils_1.to(dpoService.postPatientScreenNew({ params: params, data: data }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data;
//        return this.client
//            .post(apis_1.DPO.PATIENT_SCREEN_NEW, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/DPO/patient_screen/new/
}

export const putPatientScreenUpdate: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, data = _b.data;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, dpoService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            dpoService = new DPOService_1.default();
//                            return [4 /*yield*/, utils_1.to(dpoService.putPatientScreenUpdate({ params: params, data: data }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data;
//        return this.client
//            .put(apis_1.DPO.PATIENT_SCREEN_UPDATE, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/DPO/patient_screen/update/
}

export const patchPatientDiagnosis: Handler = async (controller, {}) => {
//function (diagnosisId, data) { return __awaiter(_this, void 0, void 0, function () {
//            var dpoService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        dpoService = new DPOService_1.default();
//                        return [4 /*yield*/, utils_1.to(dpoService.patchPatientDiagnosis(diagnosisId, data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (diagnosisId, data) {
//        var url = apis_1.DPO.UPDATE_DIAGNOSIS({ diagnosisId: diagnosisId });
//        return this.client
//            .patch(url, data)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/DPO/diagnosis/' +
//((__t = (diagnosisId)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}
