import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { Checkbox, Input, Dropdown, Button } from "semantic-ui-react";
import { Table } from "react-lib/frameworks/Table";

const CardORCaseListUX = (props: any) => {
  return (
    <div>
      <div style={{ padding: "30px 20px 20px" }}>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <Checkbox
            checked={props.ORCaseListSequence?.orFilter?.checkedHn}
            label="HN"
            name="checkedHn"
            onChange={props.onChangeFilter}
            style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}
          ></Checkbox>
          <div style={{ marginRight: "10px" }}>{props.patientSearchBox}</div>
          <label style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}>
            ชื่อ-สกุลผู้ป่วย
          </label>
          <Input
            disabled={true}
            style={{ marginRight: "10px", width: "200px" }}
            value={props.ORCaseListSequence?.orFilter?.patientFullName || ""}
          ></Input>
          <Checkbox
            checked={props.ORCaseListSequence?.orFilter?.checkedDate}
            label="วันที่ผ่าตัด"
            name="checkedDate"
            onChange={props.onChangeFilter}
            style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}
          ></Checkbox>
          <div style={{ marginRight: "10px" }}>{props.startDateTextBox}</div>
          <label style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}>
            ถึง
          </label>
          <div style={{ marginRight: "10px" }}>{props.endDateTextBox}</div>
          <Checkbox
            checked={props.ORCaseListSequence?.orFilter?.checkedDoctor}
            label="แพทย์ผ่าตัด"
            name="checkedDoctor"
            onChange={props.onChangeFilter}
            style={{ marginTop: "10px", fontWeight: "bold" }}
          ></Checkbox>
          <div style={{ marginRight: "5px" }}>{props.doctorSearchBox}</div>
          <Button
            color="yellow"
            onClick={props.onClear}
            size="small"
            style={{ marginRight: "10px" }}
          >
            ล้างการค้นหา
          </Button>
        </div>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <Checkbox
            checked={props.ORCaseListSequence?.orFilter?.checkedCase}
            label="Case"
            name="checkedCase"
            onChange={props.onChangeFilter}
            style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}
          ></Checkbox>
          <Dropdown
            clearable={true}
            disabled={!props.ORCaseListSequence?.orFilter?.checkedCase}
            multiple={true}
            name="cases"
            onChange={props.onChangeFilter}
            options={[
              { key: "is_opd_case", value: "is_opd_case", text: "OPD" },
              { key: "is_ipd_case", value: "is_ipd_case", text: "IPD" },
              {
                key: "is_one_day_case",
                value: "is_one_day_case",
                text: "One Day",
              },
            ]}
            search={true}
            selection={true}
            style={{ marginRight: "10px", width: "320px" }}
            value={props.ORCaseListSequence?.orFilter?.cases || ""}
          ></Dropdown>
          <Checkbox
            checked={props.ORCaseListSequence?.orFilter?.checkedLocation}
            label="Location"
            name="checkedLocation"
            onChange={props.onChangeFilter}
            style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}
          ></Checkbox>
          <Dropdown
            clearable={true}
            disabled={!props.ORCaseListSequence?.orFilter?.checkedLocation}
            name="operating_detail__operating_room__location_id"
            onChange={props.onChangeLocation}
            options={props.locationOptions || []}
            search={true}
            selection={true}
            style={{ marginRight: "10px", width: "200px" }}
            value={
              props.ORCaseListSequence?.orFilter
                ?.operating_detail__operating_room__location_id || ""
            }
          ></Dropdown>
          <Checkbox
            checked={props.ORCaseListSequence?.orFilter?.checkedRoom}
            label="Room"
            name="checkedRoom"
            onChange={props.onChangeFilter}
            style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}
          ></Checkbox>
          <Dropdown
            clearable={true}
            disabled={!props.ORCaseListSequence?.orFilter?.checkedRoom}
            name="operating_detail__operating_room__id"
            onChange={props.onChangeFilter}
            options={props.roomOptions || []}
            search={true}
            selection={true}
            style={{ marginRight: "5px", width: "200px" }}
            value={
              props.ORCaseListSequence?.orFilter
                ?.operating_detail__operating_room__id || ""
            }
          ></Dropdown>
          <div style={{ marginRight: "5px" }}>{props.buttonSearch}</div>
          <div style={{ marginRight: "5px" }}>{props.buttonPrint}</div>
          <div style={{ marginRight: "5px" }}>{props.buttonStat}</div>
          <div>{props.buttonPrintStat}</div>
        </div>
      </div>
      <div
        style={{
          padding: "0px 20px",
          display: props.showStatistic ? "none" : "",
        }}
      >
        <Table
          data={props.ORCaseListSequence?.caseList?.items || []}
          headers="วันที่ผ่าตัด, HN, ชื่อ - สกุล, อายุ, Cases, Location/Room, แพทย์ผ่าตัด, Operation, Anesthesia"
          keys="start_date, hn, patient_name, patient_age, caseText, locationRoom, primary_doctor_name, pre_operation_summary, anesthesia_method_name"
          minRows={10}
          widths="100, 80, 170, 80, 80, 200, 200, 250, 100"
        ></Table>
      </div>
      <div
        style={{
          padding: "0px 20px",
          display: props.showStatistic ? "" : "none",
        }}
      >
        <Table
          data={props.ORCaseListSequence?.statCaseList || []}
          headers="Location/Room, Cases, Anesthesia, Age range, Gender, QTY"
          keys="locationRoom, caseText, anesthesia_method_name, ageRangeText, gender, count"
          minRows={10}
          widths="auto, 170, 170, 170, 170, 170"
        ></Table>
      </div>
    </div>
  );
};

export default CardORCaseListUX;

export const screenPropsDefault = {};

/* Date Time : Tue Mar 07 2023 11:01:16 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"30px 20px 20px\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginBottom: \"10px\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Checkbox",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter?.checkedHn"
        },
        "label": {
          "type": "value",
          "value": "HN"
        },
        "name": {
          "type": "value",
          "value": "checkedHn"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุลผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Input",
      "parent": 12,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", width: \"200px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter?.patientFullName || \"\""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 20px\", display: props.showStatistic ? \"none\" : \"\" }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Checkbox",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter?.checkedDoctor"
        },
        "label": {
          "type": "value",
          "value": "แพทย์ผ่าตัด"
        },
        "name": {
          "type": "value",
          "value": "checkedDoctor"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"10px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"5px\" }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Dropdown",
      "parent": 29,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.ORCaseListSequence?.orFilter?.checkedLocation"
        },
        "name": {
          "type": "value",
          "value": "operating_detail__operating_room__location_id"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeLocation"
        },
        "options": {
          "type": "code",
          "value": "props.locationOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", width: \"200px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter?.operating_detail__operating_room__location_id || \"\""
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginBottom: \"10px\" }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 33,
      "name": "Table",
      "parent": 22,
      "props": {
        "data": {
          "type": "code",
          "value": "props.ORCaseListSequence?.caseList?.items || []"
        },
        "headers": {
          "type": "value",
          "value": "วันที่ผ่าตัด, HN, ชื่อ - สกุล, อายุ, Cases, Location/Room, แพทย์ผ่าตัด, Operation, Anesthesia"
        },
        "keys": {
          "type": "value",
          "value": "start_date, hn, patient_name, patient_age, caseText, locationRoom, primary_doctor_name, pre_operation_summary, anesthesia_method_name"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "widths": {
          "type": "value",
          "value": "100, 80, 170, 80, 80, 200, 200, 250, 100"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Dropdown",
      "parent": 29,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.ORCaseListSequence?.orFilter?.checkedRoom"
        },
        "name": {
          "type": "value",
          "value": "operating_detail__operating_room__id"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "props.roomOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"5px\", width: \"200px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter?.operating_detail__operating_room__id || \"\""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"5px\" }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"5px\" }"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Button",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างการค้นหา"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startDateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endDateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Checkbox",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter?.checkedDate"
        },
        "label": {
          "type": "value",
          "value": "วันที่ผ่าตัด"
        },
        "name": {
          "type": "value",
          "value": "checkedDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Checkbox",
      "parent": 29,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter?.checkedCase"
        },
        "label": {
          "type": "value",
          "value": "Case"
        },
        "name": {
          "type": "value",
          "value": "checkedCase"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Dropdown",
      "parent": 29,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.ORCaseListSequence?.orFilter?.checkedCase"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "cases"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "[ { key: \"is_opd_case\", value: \"is_opd_case\", text: \"OPD\" }, { key: \"is_ipd_case\", value: \"is_ipd_case\", text: \"IPD\" }, { key: \"is_one_day_case\", value: \"is_one_day_case\", text: \"One Day\" } ]"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", width: \"320px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter?.cases || \"\""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Checkbox",
      "parent": 29,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter?.checkedLocation"
        },
        "label": {
          "type": "value",
          "value": "Location"
        },
        "name": {
          "type": "value",
          "value": "checkedLocation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Checkbox",
      "parent": 29,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter?.checkedRoom"
        },
        "label": {
          "type": "value",
          "value": "Room"
        },
        "name": {
          "type": "value",
          "value": "checkedRoom"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonStat"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"5px\" }"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrintStat"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 20px\", display: props.showStatistic ? \"\" : \"none\" }"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 56,
      "name": "Table",
      "parent": 55,
      "props": {
        "data": {
          "type": "code",
          "value": "props.ORCaseListSequence?.statCaseList || []"
        },
        "headers": {
          "type": "value",
          "value": "Location/Room, Cases, Anesthesia, Age range, Gender, QTY"
        },
        "keys": {
          "type": "value",
          "value": "locationRoom, caseText, anesthesia_method_name, ageRangeText, gender, count"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "widths": {
          "type": "value",
          "value": "auto, 170, 170, 170, 170, 170"
        }
      },
      "seq": 56,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardORCaseListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
