import PropTypes from "prop-types";
import React, { useState, useEffect, forwardRef, useRef } from "react";
import Cookies from "js-cookie";
import { Button, Form, Radio, Dropdown, Input } from "semantic-ui-react";
import { toast } from "react-toastify";
import _ from "react-lib/compat/lodashplus";

import * as CONSTANT from "react-lib/utils/constant";
import CardLayout from "react-lib/apps/common/CardLayout";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModConfirmCNMI from "react-lib/apps/common/cnmi/ModConfirm";
import { SearchBox } from "react-lib/apps/common";
import ModMedReconcileAlert from "../common/ModMedReconcileAlert";
import { SetMedReconcile } from "../HISV3/TPD/TPDInterface";

const CardDischarge = (props: any) => {

	const [loading, setLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState<any>(null);
	const [openModalChangeZone, setOpenModalChangeZone] = useState(false);
	const [targetOrder, setTargetOrder] = useState("");
	const [choices, setChoices] = useState<any[]>([]);
	const [targetZone, setTargetZone] = useState<any>(null);
	const [zones, setZones] = useState<any[]>([]);
	const [doctor, handleSetDoctor] = useState(props.encounterInfo?.approve_by);
	const [modMedReconcile, setModMedReconcile] = useState<any>({open: false, message: "", med_reconcile_id: null})

	const isInitiated = useRef(false);

	const initialZone = async (divisionId: number) => {
		const [data, error] = await props.controller.getZone(divisionId);
		setZones([DEFAULT_ZONE, ...data])
	}

	const initialCheckoutCause = async () => {
		const [data, error] = await props.controller.getChoicesCheckout();
		if (error) {
			console.log("Error: ", error);
		}

		let hasProgressionCycle = props.controller.hasProgressionCycle
		let dValue = hasProgressionCycle ? CONSTANT.CHECKOUT_CAUSE.WAIT_RESULT : CONSTANT.CHECKOUT_CAUSE.BY_APPROVAL;
		let choices = data.filter(function (item: any) {
			// Set enable/disable
			if (item.value === CONSTANT.CHECKOUT_CAUSE.WAIT_RESULT && !hasProgressionCycle) {
				item.disabled = true;
			} else {
				item.disabled = false;
			}
			// Filter item
			if (props.isIPD && HIDE_ON_IPD.includes(item.value)) {
				return false;
			}
			if (!props.isIPD && HIDE_ON_OPD.includes(item.value)) {
				return false;
			}
			return true;
		});
		setTargetOrder(dValue);
		setChoices(choices);
		setLoading(false);
	};

	const clearErrorMessage = () => {
		setErrorMessage(null);
	};

	const handleDoctorFeeOrder = async (emrId: number) => {
		const [hasWarning, message] = await props.controller.checkDoctorFeeOrder(emrId);
		if (hasWarning) {
			setErrorMessage({ "Warning DoctorFee": [message] });
		}
	};

	const discharge = async (checkoutCause: string) => {
		const division = Cookies.get("division_id");
		const [isSuccess, errorMessage] = await props.controller.discharge(props.PATIENT_DATA.EMR.emr_id, checkoutCause, division, props.require_diagnosis, props.approve_by);
		if (!isSuccess) {
			if(errorMessage && Array.isArray(errorMessage) && errorMessage?.length > 0 && errorMessage[0].includes("Med Reconcile")){
				setModMedReconcile({
					open: true,
					message: errorMessage[0],
					med_reconcile_id: errorMessage[0].slice(errorMessage[0].indexOf("[")+1, errorMessage[0].indexOf("]"))
				})

				props.setProp("loginVia", false);
				return;
			}else{
				setErrorMessage(errorMessage);
				props.setProp("loginVia", false);
				return;
			}
		}
		props.setProp('loginVia', false);
		setErrorMessage(null);
		props.onDischarged(checkoutCause);
	};

	/**
	 * Handle "Radio"
	 * @param {*} e
	 * @param {*} param1
	 */
	const handleChanged = (e: any, { value }: any) => {
		setTargetOrder(value);
	};

	/**
	 * Handle "Dropdown" of division's zone
	 * @param {*} e 
	 * @param {*} data 
	 */
	const handleZoneSelected = (e: any, { value }: any) => {
		if (value != DEFAULT_ZONE.value) {
			for (var item of zones) {
				if (item.value === value) {
					setTargetZone(item)
					break;
				}
			}
		} else {
			setTargetZone(null)
		}
	}

	/**
	 * 
	 * @param {*} zoneId 
	 */
	const handleChangeZone = async () => {
		if (!targetZone) {
			return;
		}
		if (targetZone.value === DEFAULT_ZONE.value) {
			return;
		}

		let encounterId = props.PATIENT_DATA.ENCOUNTER.encounter_id;
		let zoneId = targetZone.id;
		let isSuccess = await props.controller.changeZone(encounterId, zoneId);

		// Close modal
		setOpenModalChangeZone(false);
		if (isSuccess) {
			// Discharge
			let checkoutCause = targetOrder;
			discharge(checkoutCause);
		}
	}

	/**
	 * Handle "Check out" Button
	 */
	const handleCUDentCheckout = async () => {
		if (props.approve_by === null) {
			setErrorMessage("กรุณาเลือก Dentist/Instructor");
			return;
		}

		if (props.dischargeDoctor?.password === "" && (props.userId != props.dischargeDoctor.userId)) {
			setErrorMessage("กรุณาระบุรหัสผ่าน");
			return;
		}

		// if (props.userId === props.dischargeDoctor.userId){
		//  handleCheckoutSave();
		//  return;
		// }
		setErrorMessage(null)

		await props.onEvent({
      message: "CheckUserLogin",
      params: {
        username: props.dischargeDoctor?.username,
        password: props.dischargeDoctor?.password,
        checkMedicalHistory: props.checkMedicalHistory,
      },
    });
	};


	const handleCheckoutSave = async () => {
		var checkoutCause = targetOrder;
		if (checkoutCause === CONSTANT.CHECKOUT_CAUSE.WAIT_RESULT) {
			if (targetZone && (targetZone.value !== DEFAULT_ZONE.value)) {
				setOpenModalChangeZone(true);
				return;
			}
		}
		// Discharge
		discharge(checkoutCause);
	}

	const handleCheckoutSaveCase = async () => {
		if (props.onCheckout) {
			props.onCheckout({
        emr_id: props.PATIENT_DATA.EMR.emr_id,
        checkout_cause: targetOrder,
        require_diagnosis: props.require_diagnosis,
        approve_by: props.approve_by,
      });
		}
		else if (props.isCUDent) {
			handleCUDentCheckout();
		} else {
			handleCheckoutSave();
		}
	}

	const generateOption = (option: any) => {
		return (
			<Radio
				disabled={option.disabled}
				label={option.text}
				name={option.value}
				value={option.value}
				checked={targetOrder === option.value}
				onChange={handleChanged.bind(this)}
			/>
		);
	};

	const generateRadioList = () => {
		return choices.map((item, index) => <Form.Field key={item.id}>{generateOption(item)}</Form.Field>);
	};

	const handleGetSearchOptions = async ({ searchText = "" }) => {
		return await props.onEvent({
			message: "GetDoctorSearch",
			params: { search: searchText },
		});
	};

	const handleOnSelectedDoctor = async ({ item, id }: any) => {
		await props.setProp(`approve_by`, id);
		await props.onEvent({
			message: "GetUsername",
			params: { pk: item.user },
		});
	};

	const handleSetPassword = (e: any, { value }: any) => {
		props.setProp(`dischargeDoctor`, { ...props.dischargeDoctor, password: value });
	};

	useEffect(() => {
		return () => {
      isInitiated.current = false;
    };
	}, []);

	useEffect(() => {
		if (
      props.controller &&
      props.PATIENT_DATA &&
      props.DJANGO &&
      !isInitiated.current
    ) {
      isInitiated.current = true;

      initialCheckoutCause();
      initialZone(props.DJANGO.division.id);
      handleDoctorFeeOrder(props.PATIENT_DATA.EMR.emr_id);
    }
	}, [props.controller, props.PATIENT_DATA, props.DJANGO]);

	useEffect(() => {
		if (props.encounterInfo?.approve_by) {
			setTimeout(() => {
				props.setProp(`approve_by`, props.doctorRef.current?.getId());
				props.onEvent({
          message: "GetUsername",
          params: {
            pk: props.doctorRef.current.getItem()?.user,
            doctor: props.encounterInfo?.approve_by,
          },
        });
			}, 1200);
		}
	}, []);


	useEffect(() => {
		if (props.loginVia === true) {
			handleCheckoutSave();
		}
	}, [props.loginVia]);

	console.log("props.encounterInfo", props.encounterInfo, targetOrder);

	return (
    <CardLayout
      titleText={props.titleText ?? "Check Out"}
      headerColor="blue"
      loading={loading}
      toggleable={false}
      closeable={props.closeable}
      onClose={props.onClose}
    >
      {/* Handle error */}
      <ErrorMessage error={errorMessage || props.errorMessage} />

      {/* Confirm change zone */}
      <ModConfirmCNMI
        openModal={openModalChangeZone}
        size={"mini"}
        titleName={"ยืนยัน"}
        content={
          <p>
            ต้องการย้ายผู้ป่วยไปยัง {targetZone ? targetZone.text : "..."}{" "}
            ใช่หรือไม่
          </p>
        }
        onApprove={handleChangeZone.bind()}
        onDeny={() => {
          setOpenModalChangeZone(false);
        }}
        onCloseWithDimmerClick={() => {
          setOpenModalChangeZone(false);
        }}
      />

      {/* Checkout cause */}
      <Form style={{ marginLeft: "32px", marginRight: "32px" }}>
        {generateRadioList()}

        {/* Change zone */}
        {targetOrder === CONSTANT.CHECKOUT_CAUSE.WAIT_RESULT && (
          <Form.Group inline style={{ marginTop: "16px" }}>
            <Form.Field disabled={zones.length === 1}>
              <label>ย้ายไปยัง zone</label>
            </Form.Field>
            <Form.Field width={10} disabled={zones.length === 1}>
              <Dropdown
                fluid
                selection
                search
                options={zones}
                placeholder={"ไม่เลือก"}
                onChange={handleZoneSelected.bind(this)}
              />
            </Form.Field>
          </Form.Group>
        )}

        {/* Dentist/Instructor && password */}
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          {!props.hideSupervisorPassword && (
            <>
              <div style={{ flex: 1, padding: "10px" }}>
                <label>Dentist/Instructor</label>
              </div>
              <div style={{ flex: 2 }}>
                <SearchBox
                  ref={props.doctorRef}
                  defaultId={props.encounterInfo?.approve_by}
                  defaultText={props.encounterInfo?.approve_by_name}
                  defaultOptions={props.doctorOptions.items}
                  onGetSearchOptions={handleGetSearchOptions}
                  onSelectOption={handleOnSelectedDoctor}
                  textField="full_name"
                  fluid={true}
                />
              </div>
              <div style={{ flex: 1, padding: "10px" }}>
                <label>Password</label>
              </div>
              <div style={{ flex: 2 }}>
                <Input
                  type={"password"}
                  placeholder="Password"
                  onChange={handleSetPassword}
                  style={{ marginLeft: "5px", marginRight: "20px" }}
                  value={props.dischargeDoctor?.password}
                  // readOnly={props.userId === props.dischargeDoctor.userId ? true : false}
                />
              </div>
            </>
          )}

          <div style={{ flex: 1 }}>
            {/* Checkout button */}
            <Button color={"green"} style={{ minWidth:"max-content" }} onClick={handleCheckoutSaveCase.bind(this)}>
              Check out
            </Button>
          </div>
        </div>
      </Form>

	  {/* Mod MedReconcile */}
	  <ModMedReconcileAlert
        // medReconcileCheck={props.medReconcileCheck}
        open={modMedReconcile.open}
        onApprove={() => {

          props.onEvent({
            message: "OpenMedReconcileFromWarning",
            params: {
              medReconcileCheck: {med_reconciliation: modMedReconcile.med_reconcile_id},
              selecteRecordViewIndex: props.medReconcileIndex,
            },
          });

          setModMedReconcile({open: false, message: "", med_reconcile_id: null})
		  props.onClose?.()

          // find med reconcile Index
          // if (props.medReconcileIndex !== -1) {
          //   props.setProp("selectedRecordViewIndex", props.medReconcileIndex)
          // }
        }}
      >
        {modMedReconcile.message}
      </ModMedReconcileAlert>
    </CardLayout>
  );
};

const HIDE_ON_IPD = [CONSTANT.CHECKOUT_CAUSE.ADMIT, CONSTANT.CHECKOUT_CAUSE.WAIT_RESULT];

const HIDE_ON_OPD = [CONSTANT.CHECKOUT_CAUSE.DISAPPEAR];

const DEFAULT_ZONE = {
	id: 0,
	text: "ไม่เลือก",
	value: "NOT_SELECTED"
}

CardDischarge.defaultProps = {
  controller: null,
  DJANGO: null,
  PATIENT_DATA: null,
  isIPD: false,
  require_diagnosis: true,
  onDischarged: (checkoutCause: string) => {},
  onClose: () => {},
  doctorOptions: null,
  encounterInfo: null,
  doctorRef: null,
  onEvent: null,
  setProp: null,
  dischargeDoctor: null,
  userId: null,
  approve_by: null,
  loginVia: false,
  isCUDent: false,
  hideSupervisorPassword: false,
  onCheckout: null,
  errorMessage: null,
  closeable: true
};

CardDischarge.propTypes = {
  controller: PropTypes.object,
  DJANGO: PropTypes.object,
  PATIENT_DATA: PropTypes.object,
  isIPD: PropTypes.bool,
  require_diagnosis: PropTypes.bool,
  onDischarged: PropTypes.func,
  onClose: PropTypes.func,
  doctorOptions: PropTypes.object,
  encounterInfo: PropTypes.object,
  doctorRef: PropTypes.object,
  onEvent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  setProp: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  dischargeDoctor: PropTypes.object,
  userId: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  approve_by: PropTypes.number,
  loginVia: PropTypes.bool,
  isCUDent: PropTypes.bool,
  hideSupervisorPassword: PropTypes.bool,
  onCheckout: PropTypes.func,
  errorMessage: PropTypes.any,
  titleText: PropTypes.string,
  closeable: PropTypes.bool,
  checkMedicalHistory: PropTypes.bool,
};

export default CardDischarge;
