import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button
} from 'semantic-ui-react'

const CardFoodListUX = (props: any) => {
    return(
      <div>
        <div>
          
          <Table
            data={props.foodList}
            getTrProps={props.foodListProps}
            headers={", ชนิดอาหาร, ข้อมูลอาหารเฉพาะโรค คำสั่งควบคุมต่อวัน, ปริมาตร, จำนวนมื้อ, เวลา, รายละเอียด, หมายเหตุ, "}
            keys="statusLabel, type, special, size, quantity, meal_time, detailText, remark, menu"
            minRows={10}
            showPagination={false}
            widths="60, 200, , 70, 80, 100, , 200, 50">
          </Table>
        </div>
        <div
          style={{ height: "60px" }}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(4, 1fr)","gridTemplateColumns":"repeat(34, 1fr)"}}>
            <div
              style={{ gridRow: "2/4", gridColumn: "20/34", textAlign: "right" }}>
              <Button
                color="blue"
                size="small">
                พิมพ์สรุปรายการทั้งหมด
              </Button>
              <Button
                color="yellow"
                size="small">
                ส่งห้องโภชนาการ
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardFoodListUX

export const screenPropsDefault = {}

/* Date Time : Mon May 08 2023 06:03:19 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"60px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "label": "divgrid",
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(4, 1fr)\",\"gridTemplateColumns\":\"repeat(34, 1fr)\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 4,
      "name": "Table",
      "parent": 1,
      "props": {
        "data": {
          "type": "code",
          "value": "props.foodList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.foodListProps"
        },
        "headers": {
          "type": "code",
          "value": "\", ชนิดอาหาร, ข้อมูลอาหารเฉพาะโรค คำสั่งควบคุมต่อวัน, ปริมาตร, จำนวนมื้อ, เวลา, รายละเอียด, หมายเหตุ, \""
        },
        "keys": {
          "type": "value",
          "value": "statusLabel, type, special, size, quantity, meal_time, detailText, remark, menu"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": "60, 200, , 70, 80, 100, , 200, 50"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"20/34\", textAlign: \"right\" }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์สรุปรายการทั้งหมด"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "fluid": {
          "type": "value",
          "value": ""
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่งห้องโภชนาการ"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 9,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardFoodListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
