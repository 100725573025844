import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  TextArea
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardTreatmentResultUX = (props: any) => {
    return(
      <div>
        <div
          style={{padding: "10px 10px 0px 10px", fontSize: "1.4rem", fontWeight: "bold" ,display: "flex", alignItems: "center" }}>
          
          <div>
            บันทึกผลหัตถการ
          </div>
        </div>
        <div
          className="ui divider"
          style={{marginRight:"10px", marginLeft:"10px"}}>
          
        </div>
        <div
          style={{padding:"10px"}}>
          
          <Table
            data={props.dataTreatment}
            getTrProps={props.rowProps}
            headers="รายละเอียดคำสั่งหัตถการ,รายละเอียดอุปกรณ์,สถานะ"
            keys="order_detail,equipment_detail,status_name"
            minRows={4}
            showPagination={false}
            style={{height: "150px"}}>
          </Table>
        </div>
        <div
          className="ui divider"
          style={{marginRight:"10px", marginLeft:"10px"}}>
          
        </div>
        <div
          style={{padding: "0px 10px 0px 10px", fontSize: "1.4rem", fontWeight: "bold" ,display: props.hideDescription ? "none":"flex", alignItems: "center",  }}>
          Principal
        </div>
        <div
          style={{position: "relative",display: props.hideDescription ? "none":""}}>
          
          <div
            style={{padding: "10px 10px 0px 10px"}}>
            {props?.diagnosisListPrincipal}
          </div>
          <div
            style={{display: props?.showSearchResultPrincipal ? "" : "none", position: "absolute", zIndex: 100, width: "80%", height: "400px" }}>
            
          </div>
          <div
            style={{padding: "10px 10px 0px 10px" ,display:"flex"}}>
            
            <div>
              
              <Button
                color="green"
                onClick={props.OnaddPrincipal}>
                + Principal
              </Button>
            </div>
            <div
              style={{display: props.isShowDiagnosisSecondary ? "none" : ""}}>
              
              <Button
                color="yellow"
                onClick={props.OnaddSecondary}>
                + Secondary
              </Button>
            </div>
          </div>
          <div
            style={{padding: "10px 10px 0px 10px",display: props.isShowDiagnosisSecondary ? "" : "none"}  }>
            
            <div
              className="ui divider">
              
            </div>
            <div
              style={{fontSize: "1.4rem", fontWeight: "bold" ,display: "flex", alignItems: "center"}}>
              Secondary
            </div>
            <div>
              {props?.diagnosisListSecondary}
            </div>
            <div
              style={{display: props?.showSearchResultSecondary ? "" : "none", position: "absolute", zIndex: 100, width: "80%", height: "400px" }}>
              
            </div>
            <div
              style={{marginTop:"10px"}}>
              
              <Button
                color="yellow"
                onClick={props.OnaddSecondary}>
                + Secondary
              </Button>
            </div>
            <div
              className="ui divider">
              
            </div>
          </div>
        </div>
        <div
          style={{padding: "10px 10px 0px 10px",display: props.hideDescription ? "none":""}}>
          
          <div>
            {props?.ErrorMessage}
          </div>
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(4, 1fr)","gridTemplateColumns":"repeat(52, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/5",fontSize: "1.3rem", fontWeight: "bold" }}>
              บันทึกผล :
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "5/12",   fontWeight: "bold" }}>
              รายละเอียดคำสั่งหัตถการ:
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "12/26",   fontWeight: "bold" }}>
              {props?.treatmentRow?.order_detail}
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "27/32",   fontWeight: "bold" }}>
               รายละเอียดอุปกรณ์:
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "32/52",   fontWeight: "bold" }}>
              {props?.treatmentRow?.equipment_detail}
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "1/8", fontWeight: "bold" ,paddingTop:"5px"}}>
              วันเวลาการเริ่มทำหัตการ:
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "8/17",paddingTop:"5px"}}>
              {props?.StartTime}
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "17/26",paddingTop:"5px"}}>
              
              <DateTextBox
                onChange={props.handleChangeDate(`${props.treatmentRow?.index}.start`)}
                value={props?.startDate}>
              </DateTextBox>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "26/34",paddingTop:"5px",fontWeight: "bold"}}>
              วันเวลาสิ้นสุดการทำหัตถการ :
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "34/42",paddingTop:"5px"}}>
              {props?.FinishTime}
            </div>
            <div
              style={{ gridRow: "/4", gridColumn: "43/50",paddingTop:"5px"}}>
              
              <DateTextBox
                onChange={props.handleChangeDate(`${props.treatmentRow?.index}.finish`)}
                value={props?.finishDate}>
              </DateTextBox>
            </div>
            <div
              style={{ gridRow: "3/5", gridColumn: "1/5", fontWeight: "bold" ,paddingTop:"5px"}}>
              รายละเอียด :
            </div>
            <div
              style={{ gridRow: "3/5", gridColumn: "5/25", paddingTop:"5px"}}>
              
              <TextArea
                onChange={props.handleChangeValue(`${props.treatmentRow?.index}.result`)}
                style={{width: "100%", height: "100%", border: "solid #cccccc 1px"}}
                value={props?.treatmentRow?.result}>
              </TextArea>
            </div>
            <div
              style={{ gridRow: "3/5", gridColumn: "31/50", paddingTop:"5px" }}>
              
              <TextArea
                onChange={props.handleChangeValue(`${props.treatmentRow?.index}.complications`)}
                style={{width: "100%", height: "100%", border: "solid #cccccc 1px"}}
                value={props?.treatmentRow?.complications}>
              </TextArea>
            </div>
            <div
              style={{ gridRow: "3/5", gridColumn: "26/31", paddingTop:"5px",fontWeight: "bold" }}>
              ภาวะแทรกซ้อน :
            </div>
          </div>
        </div>
        <div
          style={{paddingTop: "20px ",display: props.hideDescription ? "none":"flex"}}>
          
          <div
            style={{flex:"1"}}>
            
          </div>
          <div
            style={{ fontWeight: "bold" ,display: "flex", alignItems: "center",marginRight:"7px"}}>
            รหัสผู้ทำหัตถการ
          </div>
          <div>
            {props?.code}
          </div>
          <div
            style={{paddingLeft:"5px"}}>
            
            <Button
              color={props?.showEdit ? "yellow" : "green"}
              onClick={props?.onSave}>
              {props?.showEdit ?  "Edit" : "Save"}
            </Button>
          </div>
        </div>
        <div
          style={{padding: "10px ",display: props.hideDescription ? "none":"flex"}}>
          
          <div
            style={{flex:"1"}}>
            
          </div>
          <div>
            
            <Button
              color="red"
              onClick={props.OnCancel}
              style={{display : props?.showEdit ?  "" :"none "}}>
              ยกเลิก PERFORM
            </Button>
          </div>
        </div>
      </div>
    )
}

export default CardTreatmentResultUX

export const screenPropsDefault = {}

/* Date Time : Thu Nov 24 2022 03:38:03 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 0px 10px\", fontSize: \"1.4rem\", fontWeight: \"bold\" ,display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึกผลหัตถการ"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"10px\", marginLeft:\"10px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding:\"10px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 8,
      "name": "Table",
      "parent": 7,
      "props": {
        "data": {
          "type": "code",
          "value": "props.dataTreatment"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": "รายละเอียดคำสั่งหัตถการ,รายละเอียดอุปกรณ์,สถานะ"
        },
        "keys": {
          "type": "value",
          "value": "order_detail,equipment_detail,status_name"
        },
        "minRows": {
          "type": "code",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"150px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"10px\", marginLeft:\"10px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "Principal"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 10px 0px 10px\", fontSize: \"1.4rem\", fontWeight: \"bold\" ,display: props.hideDescription ? \"none\":\"flex\", alignItems: \"center\",  }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\",display: props.hideDescription ? \"none\":\"\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.diagnosisListPrincipal"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 0px 10px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 0px 10px\" ,display:\"flex\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 0px 10px\",display: props.isShowDiagnosisSecondary ? \"\" : \"none\"}  "
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.isShowDiagnosisSecondary ? \"none\" : \"\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "+ Principal"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnaddPrincipal"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Button",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "+ Secondary"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnaddSecondary"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "Secondary"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.4rem\", fontWeight: \"bold\" ,display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.diagnosisListSecondary"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"10px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Button",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "+ Secondary"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnaddSecondary"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"20px \",display: props.hideDescription ? \"none\":\"flex\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.code"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft:\"5px\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Button",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.showEdit ?  \"Edit\" : \"Save\""
        },
        "color": {
          "type": "code",
          "value": "props?.showEdit ? \"yellow\" : \"green\""
        },
        "onClick": {
          "type": "code",
          "value": "props?.onSave"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสผู้ทำหัตถการ"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" ,display: \"flex\", alignItems: \"center\",marginRight:\"7px\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 0px 10px\",display: props.hideDescription ? \"none\":\"\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "label": "divgrid",
      "name": "div",
      "parent": 64,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(4, 1fr)\",\"gridTemplateColumns\":\"repeat(52, 1fr)\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึกผล :"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/5\",fontSize: \"1.3rem\", fontWeight: \"bold\" }"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดคำสั่งหัตถการ:"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"5/12\",   fontWeight: \"bold\" }"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.treatmentRow?.order_detail"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"12/26\",   fontWeight: \"bold\" }"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": " รายละเอียดอุปกรณ์:"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"27/32\",   fontWeight: \"bold\" }"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.treatmentRow?.equipment_detail"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"32/52\",   fontWeight: \"bold\" }"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเวลาการเริ่มทำหัตการ:"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/8\", fontWeight: \"bold\" ,paddingTop:\"5px\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.StartTime"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"8/17\",paddingTop:\"5px\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"17/26\",paddingTop:\"5px\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 75,
      "name": "DateTextBox",
      "parent": 74,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(`${props.treatmentRow?.index}.start`)"
        },
        "value": {
          "type": "code",
          "value": "props?.startDate"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเวลาสิ้นสุดการทำหัตถการ :"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"26/34\",paddingTop:\"5px\",fontWeight: \"bold\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.FinishTime"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"34/42\",paddingTop:\"5px\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"/4\", gridColumn: \"43/50\",paddingTop:\"5px\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 80,
      "name": "DateTextBox",
      "parent": 79,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(`${props.treatmentRow?.index}.finish`)"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props?.finishDate"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียด :"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/5\", gridColumn: \"1/5\", fontWeight: \"bold\" ,paddingTop:\"5px\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/5\", gridColumn: \"5/25\", paddingTop:\"5px\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "TextArea",
      "parent": 82,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(`${props.treatmentRow?.index}.result`)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", border: \"solid #cccccc 1px\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.treatmentRow?.result"
        }
      },
      "seq": 83,
      "void": true
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "ภาวะแทรกซ้อน :"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/5\", gridColumn: \"26/31\", paddingTop:\"5px\",fontWeight: \"bold\" }"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/5\", gridColumn: \"31/50\", paddingTop:\"5px\" }"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "TextArea",
      "parent": 85,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(`${props.treatmentRow?.index}.complications`)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", border: \"solid #cccccc 1px\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.treatmentRow?.complications"
        }
      },
      "seq": 86,
      "void": true
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px \",display: props.hideDescription ? \"none\":\"flex\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Button",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก PERFORM"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnCancel"
        },
        "style": {
          "type": "code",
          "value": "{display : props?.showEdit ?  \"\" :\"none \"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 64,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.ErrorMessage"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props?.showSearchResultPrincipal ? \"\" : \"none\", position: \"absolute\", zIndex: 100, width: \"80%\", height: \"400px\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props?.showSearchResultSecondary ? \"\" : \"none\", position: \"absolute\", zIndex: 100, width: \"80%\", height: \"400px\" }"
        }
      },
      "seq": 22,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": false,
  "memo": false,
  "name": "CardTreatmentResultUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 90
}

*********************************************************************************** */
