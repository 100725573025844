import React, { useState } from "react";
import { Button } from "semantic-ui-react";
// UI ANE
import CardPreAnesthetic from "./CardPreAnesthetic";
import CardPACURecord from "./CardPACURecord";
import CardPeriDataSheet from "./CardPeriDataSheet";
import CardAnestheticComplication from "./CardAnestheticComplication";
import CardAnesRecord from "./CardAnesRecord";
import CardAnesBill from "./CardAnesBill";

type CardAnestheticDocumentProps = {
  // function
  onEvent: any;
  setProp: any;
  DJANGO: any;

  // controller
  cardANSBillController?: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  billDrugOrder?: any;
  selectedEncounter?: any;
  selectedEmr?: any;
  selectedProgressCycle?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;
  masterData?: any;

  // seq
  runSequence?: any;
  PreAnestheticSequence?: any;
  PACURecordSequence?: any;
  PeriDataSheetSequence?: any;
  AnesComplicationSequence?: any;
  AnesRecordSequence?: any;
  AnesBillSequence?: any;
  selectedDoctor?: any;
  selectedAnesthesiologist?: any;
};

const CardAnestheticDocument = (props: CardAnestheticDocumentProps) => {
  const [containerName, setContainerName] = useState<string>("PreAnesthetic");

  return (
    <>
      <div style={{ paddingTop: "20px", marginBottom: "-6px" }}>
        <Button
          color={containerName === "PreAnesthetic" ? "blue" : ""}
          onClick={() => setContainerName("PreAnesthetic")}
          style={{ marginLeft: "10px" }}
        >
          Pre Anes
        </Button>
        <Button
          color={containerName === "AnesRecord" ? "blue" : ""}
          onClick={() => setContainerName("AnesRecord")}
        >
          Anes Record
        </Button>
        <Button
          color={containerName === "PACU" ? "blue" : ""}
          onClick={() => setContainerName("PACU")}
        >
          PACU Record
        </Button>
        <Button
          color={containerName === "PeriDataSheet" ? "blue" : ""}
          onClick={() => setContainerName("PeriDataSheet")}
        >
          Peri Data Sheet
        </Button>
        <Button
          color={containerName === "AnesComplication" ? "blue" : ""}
          onClick={() => setContainerName("AnesComplication")}
        >
          Anes Complication
        </Button>
        <Button
          color={containerName === "AnesBill" ? "blue" : ""}
          onClick={() => setContainerName("AnesBill")}
          // encounter type not HID type
          disabled={props.selectedEncounter?.type !== "IPD" && props.selectedEncounter?.type !== "OPD"}
        >
          Anes ค่าใช้จ่าย
        </Button>
      </div>
      <hr />

      {containerName === "PreAnesthetic" ? (
        <CardPreAnesthetic
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // options
          masterOptions={props.masterOptions}
          masterData={props.masterData}
          // seq
          runSequence={props.runSequence}
          PreAnestheticSequence={props.PreAnestheticSequence}
          selectedDoctor={props.selectedDoctor}
          selectedAnesthesiologist={props.selectedAnesthesiologist}
        />
      ) : containerName === "PACU" ? (
        <CardPACURecord
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          DJANGO={props.DJANGO}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // options
          masterOptions={props.masterOptions}
          masterData={props.masterData}
          // seq
          runSequence={props.runSequence}
          PACURecordSequence={props.PACURecordSequence}
        />
      ) : containerName === "PeriDataSheet" ? (
        <CardPeriDataSheet
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // options
          masterOptions={props.masterOptions}
          // seq
          runSequence={props.runSequence}
          PACURecordSequence={props.PACURecordSequence}
          PeriDataSheetSequence={props.PeriDataSheetSequence}
        />
      ) : containerName === "AnesComplication" ? (
        <CardAnestheticComplication
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // options
          masterOptions={props.masterOptions}
          // seq
          runSequence={props.runSequence}
          AnesComplicationSequence={props.AnesComplicationSequence}
        />
      ) : containerName === "AnesRecord" ? (
        <CardAnesRecord
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // options
          masterOptions={props.masterOptions}
          // seq
          runSequence={props.runSequence}
          AnesRecordSequence={props.AnesRecordSequence}
        />
      ) : containerName === "AnesBill" ? (
        <CardAnesBill
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // controller
          cardANSBillController={props.cardANSBillController}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          billDrugOrder={props.billDrugOrder}
          selectedEncounter={props.selectedEncounter}
          selectedEmr={props.selectedEmr}
          selectedProgressCycle={props.selectedProgressCycle}
          // options
          masterOptions={props.masterOptions}
          // seq
          runSequence={props.runSequence}
          AnesBillSequence={props.AnesBillSequence}
        />
      ) : containerName === "AnesRecord" ? (
        <CardAnesRecord
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // options
          masterOptions={props.masterOptions}
          // seq
          runSequence={props.runSequence}
          AnesRecordSequence={props.AnesRecordSequence}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(CardAnestheticDocument);
