import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'

const ModEventDetailUX = (props: any) => {
    return(
      <div
        style={{padding: "20px 20px 40px 20px"}}>
        
        <div
          style={{margin: "10px 0px 15px 0px", fontWeight: "bold"}}>
          {props?.title}
        </div>
        <div>
          
          <Table
            data={props.providers}
            getTrProps={props.rowProps}
            headers="ทันตแพทย์,วันที่,เริ่ม,ถึง,เก้าอี้,ZONE,จำนวนนัดหมาย"
            keys="doctor_provider_name,date,doctor_start_time,doctor_end_time,chair,zone,number_appointments"
            minRows="5"
            showPagination={false}
            style={{height: "200px"}}>
          </Table>
        </div>
      </div>
    )
}

export default ModEventDetailUX

export const screenPropsDefault = {"title":"9:00-12:00"}

/* Date Time : Wed Aug 10 2022 15:14:46 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"20px 20px 40px 20px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.title"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px 0px 15px 0px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.providers"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": "ทันตแพทย์,วันที่,เริ่ม,ถึง,เก้าอี้,ZONE,จำนวนนัดหมาย"
        },
        "keys": {
          "type": "value",
          "value": "doctor_provider_name,date,doctor_start_time,doctor_end_time,chair,zone,number_appointments"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "ModEventDetailUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "title": "9:00-12:00"
  },
  "width": 80
}

*********************************************************************************** */
