import WasmController from "react-lib/frameworks/WasmController";
// apis
import DrugDetail from "issara-sdk/apis/DrugDetail_apps_TPD";
import DrugList from "issara-sdk/apis/DrugList_apps_TPD";

export type State = {
  // CommonInterface
  masterOptions?: any;
  errorMessage?: any;
  successMessage?: any;

  // DrugSelect
  DrugSelectSequence?: any;
  drugPermission?: any;

  // selected data
  selectedDrug?: any;

  // mod
  modSolventSelectionConfig?: {
    openModal?: boolean;
    showButtonSolvent?: boolean;
    showButtonIVSave?: boolean;
    editableQuantity?: boolean;
    editable?: boolean;

    // options
    drugSolventSearchOptions?: any[];
    dosageUnitOptions?: any[];
    dripInOptions?: any[];
    ivRateOptions?: any[];
    concentrationOptions?: any[];

    // data
    solvent?: number | null;
    selectedSolvent?: any;

    txtMaxRate?: string;
    txtMaxRateColor?: string;
    txtNoRate?: string;
    txtMaxConc?: string;

    max_concentration_rate?: number | null;
    max_concentration_unit?: number | null;
    max_rate?: number | null;

    dripRate?: string;

    drug_strength?: number | null;
    drug_strength_choice?: number | null;

    volume?: string;

    drug_drip_in?: number | null;
    drug_drip_in_unit?: number | null;

    mixture_iv_rate?: number | null;
    mixture_iv_rate_unit?: number | null;

    quantity?: number | null;

    solute_concentration_rate?: number | null;
    solvent_concentration_rate?: number | null;
    conc_choice?: number | null;

    soluteQuantity?: number | null;
    soluteStockSize?: number | null;
    soluteBaseUnit?: number | null;
    solventStockSize?: number | null;
    solventBaseUnit?: number | null;

    //
    admixtureMode?: string;
    admixtureSolute?: any | null;
    admixtureIndex?: number | null;
  };
};

export const StateInitial: State = {
  // DrugSelect
  DrugSelectSequence: null,
  drugPermission: null,

  // selected data
  selectedDrug: null,

  // mod
  modSolventSelectionConfig: {
    openModal: false,
    showButtonSolvent: false,
    showButtonIVSave: false,
    editableQuantity: true,
    editable: true,

    // options
    drugSolventSearchOptions: [],
    dosageUnitOptions: [],
    dripInOptions: [],
    ivRateOptions: [],
    concentrationOptions: [],

    // data
    solvent: null,
    selectedSolvent: {},

    txtMaxRate: "",
    txtMaxRateColor: "",
    txtNoRate: "",
    txtMaxConc: "",

    max_concentration_rate: null,
    max_concentration_unit: null,
    max_rate: null,

    dripRate: "",

    drug_strength: null,
    drug_strength_choice: null,

    volume: "",

    drug_drip_in: null,
    drug_drip_in_unit: null,

    mixture_iv_rate: null,
    mixture_iv_rate_unit: null,

    quantity: null,

    solute_concentration_rate: null,
    solvent_concentration_rate: null,
    conc_choice: null,

    soluteQuantity: null,
    soluteStockSize: null,
    soluteBaseUnit: null,
    solventStockSize: null,
    solventBaseUnit: null,

    //
    admixtureMode: "",
    admixtureSolute: null,
    admixtureIndex: null,
  },
};

export type Event =
  // set
  | {
      message: "initModSolventSelection";
      params: {
        drugOrderType: string;
        solventId: number | null;
        admixtureOptions: any;
      };
    }
  | {
      message: "SetModSolventSelectionData";
      params: { name: string; value: any };
    }
  | { message: "SetModSolventSelectionConfig"; params: {} }
  // get
  | { message: "GetDrugSolventList"; params: { name: string } }
  | { message: "GetDrugSolventDetail"; params: { id: number } }
  // save
  | { message: "SaveSolventOldData" , params: any}
  | { message: "SaveSolventData"; params: { drugOrderType: string } }
  | { message: "RemoveSolventData", params: any};

export type Data = {
  division?: number;
};

export const DataInitial = {};

export const MOD_SOLVENT_SELECTION_ID: string = "ModSolventSelection";

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

// set
export const initModSolventSelection: Handler = async (controller, params) => {
  let state = controller.getState();

  let drugSelectSeq: any = { ...state.DrugSelectSequence };
  let modSolventConfig: any = {
    ...state.modSolventSelectionConfig,
    showButtonSolvent: false,
  };

  if (
    drugSelectSeq?.is_intravaneous &&
    state.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE
  ) {
    modSolventConfig.showButtonSolvent = true;
  }

  if (state.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE) {
    modSolventConfig = await clear(controller, modSolventConfig);

    modSolventConfig.drug_strength =
      parseInt(params.admixtureOptions?.strength) || 1;

    modSolventConfig.max_concentration_rate =
      drugSelectSeq?.max_concentration_rate;
    modSolventConfig.max_concentration_unit =
      drugSelectSeq?.max_concentration_unit;
    modSolventConfig.max_rate = drugSelectSeq?.max_rate_text;

    // choice
    [
      ["dosageUnitOptions", "limited_dosage_units"],
      ["dripInOptions", "limited_drug_drip_in"],
      ["ivRateOptions", "limited_iv_rate"],
      ["concentrationOptions", "limited_drug_concentration"],
    ].forEach((item: any) => {
      modSolventConfig[item[0]] =
        drugSelectSeq[item[1]]?.length > 0
          ? state.masterOptions?.unit?.filter((option: any) =>
              drugSelectSeq[item[1]].includes(option.key)
            )
          : state.masterOptions?.unit;
    });

    if (!params?.solventId) {
      if (drugSelectSeq?.default_solvent) {
        await controller.setState({
          DrugSelectSequence: drugSelectSeq,
          modSolventSelectionConfig: modSolventConfig,
        } as any);

        await GetDrugSolventDetail(controller, {
          id: drugSelectSeq?.default_solvent,
        });

        state = controller.getState();

        drugSelectSeq = { ...state.DrugSelectSequence };
        modSolventConfig = { ...state.modSolventSelectionConfig };

        modSolventConfig.drugSolventSearchOptions = [
          {
            key: modSolventConfig.selectedSolvent?.id,
            value: modSolventConfig.selectedSolvent?.id,
            text: modSolventConfig.selectedSolvent?.label_name,
          },
        ];
      }
      if (
        !params.admixtureOptions?.quantity &&
        !parseInt(params.admixtureOptions?.quantity) &&
        parseInt(drugSelectSeq?.default_solvent_quantity)
      ) {
        modSolventConfig.quantity =
          parseInt(drugSelectSeq?.default_solvent_quantity) || 1;
      }
      if (
        !params.admixtureOptions?.rate &&
        !parseFloat(params.admixtureOptions?.rate) &&
        parseFloat(drugSelectSeq?.default_iv_rate)
      ) {
        modSolventConfig.mixture_iv_rate = drugSelectSeq?.default_iv_rate;
      }
    } else {
      await controller.setState({
        DrugSelectSequence: drugSelectSeq,
        modSolventSelectionConfig: modSolventConfig,
      } as any);

      await GetDrugSolventDetail(controller, { id: params?.solventId });

      state = controller.getState();

      drugSelectSeq = { ...state.DrugSelectSequence };
      modSolventConfig = { ...state.modSolventSelectionConfig };

      modSolventConfig.drugSolventSearchOptions = [
        {
          key: modSolventConfig.selectedSolvent?.id,
          value: modSolventConfig.selectedSolvent?.id,
          text: modSolventConfig.selectedSolvent?.label_name,
        },
      ];

      modSolventConfig.volume = drugSelectSeq?.stock_size || 0;

      if (
        params.admixtureOptions?.volume &&
        parseFloat(params.admixtureOptions?.volume)
      ) {
        modSolventConfig.volume = parseFloat(params.admixtureOptions?.volume);
      }
    }
    modSolventConfig.drug_strength_choice =
      params.admixtureOptions?.strength_unit || "";

    // set iv rate unit
    if (
      params.admixtureOptions?.quantity &&
      parseInt(params.admixtureOptions?.quantity)
    ) {
      modSolventConfig.quantity = parseInt(params.admixtureOptions?.quantity);
    }
    if (
      params.admixtureOptions?.rate &&
      parseFloat(params.admixtureOptions?.rate)
    ) {
      modSolventConfig.mixture_iv_rate = parseFloat(
        params.admixtureOptions?.rate
      );
    }
    if (
      params.admixtureOptions?.mixture_iv_rate_unit &&
      parseInt(params.admixtureOptions?.mixture_iv_rate_unit)
    ) {
      modSolventConfig.mixture_iv_rate_unit = parseInt(
        params.admixtureOptions?.mixture_iv_rate_unit
      );
    }
    if (
      params.admixtureOptions?.drug_drip_in &&
      parseFloat(params.admixtureOptions?.drug_drip_in)
    ) {
      modSolventConfig.drug_drip_in =
        parseInt(params.admixtureOptions?.drug_drip_in) || 0;
    }
    if (
      params.admixtureOptions?.drug_drip_in_unit &&
      parseInt(params.admixtureOptions?.drug_drip_in_unit)
    ) {
      modSolventConfig.drug_drip_in_unit = parseInt(
        params.admixtureOptions?.drug_drip_in_unit
      );
    }
    if (
      params.admixtureOptions?.solute_concentration_rate &&
      params.admixtureOptions?.solvent_concentration_rate &&
      parseInt(params.admixtureOptions?.solute_concentration_rate) &&
      parseInt(params.admixtureOptions?.solvent_concentration_rate)
    ) {
      modSolventConfig.solute_concentration_rate = parseInt(
        params.admixtureOptions?.solute_concentration_rate
      );
      modSolventConfig.solvent_concentration_rate = parseInt(
        params.admixtureOptions?.solvent_concentration_rate
      );
      modSolventConfig.concentration = "conc";
    } else {
      modSolventConfig.concentration = "strength";
    }
    if (
      params.admixtureOptions?.concentration_unit &&
      parseInt(params.admixtureOptions?.concentration_unit)
    ) {
      modSolventConfig.conc_choice = parseInt(
        params.admixtureOptions?.concentration_unit
      );
    }

    // Todo
    // set enabled item
    // if (radioConc.checked) {
    //   txtStrength.enabled = false;
    //   txtVolume.enabled = false;
    //   cboStrengthUnit.enabled = false;
    //   txtConcSolute.enabled = true;
    //   txtConcSolvent.enabled = true;
    //   cboConcUnit.enabled = true;
    // } else {
    //   txtStrength.enabled = true;
    //   if (solventId) {
    //     txtVolume.enabled = true;
    //   } else {
    //     txtVolume.enabled = false;
    //   }
    //   cboStrengthUnit.enabled = true;
    //   txtConcSolute.enabled = false;
    //   txtConcSolvent.enabled = false;
    //   cboConcUnit.enabled = false;
    // }

    if (
      params.admixtureOptions?.drug_drip_in &&
      params.admixtureOptions?.drug_drip_in_unit
    ) {
      modSolventConfig.dripRate = "drip";
    } else {
      modSolventConfig.dripRate = "rate";
    }

    if (params.drugOrderType && modSolventConfig?.dripRate === "rate") {
      if (modSolventConfig.max_rate) {
        modSolventConfig.txtMaxRate = modSolventConfig.max_rate;
        modSolventConfig.txtMaxRateColor = "blue";
      }
      // Todo
      // if (!modSolventSelection.editable || permission.role_PHARMACIST) {
      //   txtConcSolute.readOnly = true;
      //   txtConcSolvent.readOnly = true;
      //   stbSolvent.readOnly = true;
      //   txtDrugDrip.readOnly = true;
      //   txtVolume.readOnly = true;
      //   txtRate.readOnly = true;
      //   txtStrength.readOnly = true;
      //   if (modSolventSelection.editableQuantity) {
      //     btnCancel.text = "CLOSE";
      //     txtQuantity.readOnly = false;
      //   } else {
      //     txtQuantity.readOnly = true;
      //   }
      //   cboConcUnit.readOnly = true;
      //   cboDrugDripUnit.readOnly = true;
      //   cboDrugRateUnit.readOnly = true;
      //   cboStrengthUnit.readOnly = true;
      //   radioDrugConcentrate.readOnly = true;
      //   radioStrength.readOnly = true;
      //   radioDrugDrip.readOnly = true;
      //   radioDrugRate.readOnly = true;
      // }
    }
  } else {
    modSolventConfig = await clear(controller, modSolventConfig);

    if (
      params.admixtureOptions?.quantity &&
      parseInt(params.admixtureOptions?.quantity)
    ) {
      modSolventConfig.old_quantity = drugSelectSeq?.quantity;
    }
    if (
      params.admixtureOptions?.rate &&
      parseInt(params.admixtureOptions?.rate)
    ) {
      modSolventConfig.old_rate = drugSelectSeq?.rate;
    }

    modSolventConfig.solventStockSize = null;
    modSolventConfig.solventBaseUnit = null;

    modSolventConfig.soluteQuantity =
      parseInt(params.admixtureOptions?.soluteQuantity) || 1;
    modSolventConfig.soluteStockSize = drugSelectSeq?.stock_size;
    modSolventConfig.soluteBaseUnit = drugSelectSeq?.base_unit_name;

    if (!params.solventId) {
      await controller.setState({
        DrugSelectSequence: drugSelectSeq,
        modSolventSelectionConfig: modSolventConfig,
      });

      await GetDrugSolventDetail(controller, {
        id: drugSelectSeq?.default_solvent,
      });

      state = controller.getState();

      drugSelectSeq = { ...state.DrugSelectSequence };
      modSolventConfig = { ...state.modSolventSelectionConfig };

      modSolventConfig.drugSolventSearchOptions = [
        {
          key: modSolventConfig.selectedSolvent?.id,
          value: modSolventConfig.selectedSolvent?.id,
          text: modSolventConfig.selectedSolvent?.label_name,
        },
      ];

      if (
        !parseInt(params.admixtureOptions?.quantity) &&
        parseInt(drugSelectSeq?.default_solvent_quantity)
      ) {
        modSolventConfig.old_quantity = drugSelectSeq?.default_solvent_quantity;
      }
      if (
        !parseFloat(params.admixtureOptions?.rate) &&
        parseFloat(drugSelectSeq?.default_iv_rate)
      ) {
        modSolventConfig.old_rate = drugSelectSeq?.default_iv_rate;
      }
    } else {
      await controller.setState({
        DrugSelectSequence: drugSelectSeq,
        modSolventSelectionConfig: modSolventConfig,
      } as any);

      await GetDrugSolventDetail(controller, { id: params?.solventId });

      state = controller.getState();

      drugSelectSeq = { ...state.DrugSelectSequence };
      modSolventConfig = { ...state.modSolventSelectionConfig };

      modSolventConfig.drugSolventSearchOptions = [
        {
          key: modSolventConfig.selectedSolvent?.id,
          value: modSolventConfig.selectedSolvent?.id,
          text: modSolventConfig.selectedSolvent?.label_name,
        },
      ];
    }
  }
  modSolventConfig.openModal = true;

  controller.setState({
    DrugSelectSequence: drugSelectSeq,
    modSolventSelectionConfig: modSolventConfig,
  } as any);
};

export const SetModSolventSelectionData: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  let modSolventConfig: any = { ...state.modSolventSelectionConfig };
  modSolventConfig[params.name] = params.value;

  if (params.name === "dripRate") {
    modSolventConfig.txtMaxRate = "";
    modSolventConfig.txtMaxRateColor = "";

    if (params.value === "rate") {
      if (modSolventConfig?.max_rate) {
        modSolventConfig.txtMaxRate = `ค่า Rate มาตรฐาน: ${modSolventConfig?.max_rate}`;
        modSolventConfig.txtMaxRateColor = "blue";
      }
    }
  }

  await controller.setState({ modSolventSelectionConfig: modSolventConfig });

  if (params.name === "strength" && parseFloat(params.value) > 0) {
    checkMaxConc(controller);
    recalculateIV(controller, "SoluteConc");
    recalculateConc(controller);
  } else if (params.name === "drug_strength_choice") {
    checkMaxConc(controller);
  } else if (params.name === "volume" && parseFloat(params.value) > 0) {
    recalculateIV(controller, "SolventConc");
    checkMaxConc(controller);
    recalculateConc(controller);
  } else if (
    params.name === "solute_concentration_rate" &&
    parseFloat(params.value) > 0
  ) {
    recalculateConc(controller);
    recalculateIV(controller, "SoluteDose");
  } else if (
    params.name === "solvent_concentration_rate" &&
    parseFloat(params.value) > 0
  ) {
    recalculateConc(controller);
    recalculateIV(controller, "SolventVolume");
  } else if (params.name === "conc_choice") {
    checkMaxConc(controller);
  }
};

export const SetModSolventSelectionConfig: Handler = (controller, params) => {
  const state = controller.getState();

  controller.setState({
    modSolventSelectionConfig: {
      ...state.modSolventSelectionConfig,
      ...params,
    },
  } as any);
};

// get
export const GetDrugSolventList: Handler = async (controller, params) => {
  const state = controller.getState();

  const [response, error, _network] = await DrugList.list({
    params: { solvent_for: state.selectedDrug?.id, name: params.name },
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });
  if (error) {
  } else {
    const result = response?.items?.map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.full_name,
    }));
    controller.setState({
      modSolventSelectionConfig: {
        ...state.modSolventSelectionConfig,
        drugSolventSearchOptions: result,
      },
    } as any);
  }
};

export const GetDrugSolventDetail: Handler = async (controller, params) => {
  const [response, error, _network] = await DrugDetail.retrieve({
    pk: params.id,
    apiToken: controller.apiToken,
  });

  if (!error) {
    let state = controller.getState();

    controller.setState({
      modSolventSelectionConfig: {
        ...state.modSolventSelectionConfig,
        solvent: response.id,
        selectedSolvent: response,
      },
    });

    if (state.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE) {
      state = controller.getState();
      let modSolventConfig: any = { ...state.modSolventSelectionConfig };

      if (!parseFloat(modSolventConfig.selectedSolvent?.stock_size)) {
        modSolventConfig.volume = "NO SOLVENT STOCK SIZE";
        modSolventConfig.solvent_default_stock_size = 1;
      } else {
        modSolventConfig.volume = modSolventConfig.selectedSolvent?.stock_size;
        modSolventConfig.solvent_default_stock_size =
          modSolventConfig.selectedSolvent?.stock_size;
      }
      if (!state.DrugSelectSequence?.quantity) {
        modSolventConfig.quantity = 1;
      }

      controller.setState({
        modSolventSelectionConfig: {
          ...state.modSolventSelectionConfig,
          showButtonIVSave: true,
        },
      });

      if (modSolventConfig.volume !== "NO SOLVENT STOCK SIZE") {
        recalculateIV(controller, "SolventConc");
        recalculateConc(controller);
      }
      checkMaxConc(controller);
    } else {
      // Todo
      // txtConc.solventStockSize = props.selectedDrug?.stock_size;
      // txtConc.solventBaseUnit = props.selectedDrug?.base_unit_name;
    }
  }
};

// save
export const SaveSolventOldData: Handler = async (controller) => {
  if (!validateData(controller)) {
    return;
  }

  const state = controller.getState();

  let drugSelectSeq: any = { ...state.DrugSelectSequence };
  let modSolventConfig: any = { ...state.modSolventSelectionConfig };

  if (modSolventConfig.solvent) {
    modSolventConfig.openModal = false;

    if (drugSelectSeq?.is_intravaneous) {
      drugSelectSeq.solvent_product = modSolventConfig.solvent;
      drugSelectSeq.solvent_quantity = modSolventConfig.old_quantity;
      drugSelectSeq.iv_rate = modSolventConfig.old_rate;
    } else {
      drugSelectSeq.solvent_product = modSolventConfig.solvent;
      drugSelectSeq.solvent_quantity = modSolventConfig.old_quantity;
      drugSelectSeq.mixture_iv_rate = modSolventConfig.old_rate;
    }
    controller.setState({
      DrugSelectSequence: drugSelectSeq,
      modSolventSelectionConfig: modSolventConfig,
    });
  }
};

export const SaveSolventData: Handler = async (controller, params) => {
  if (!validateData(controller)) {
    return;
  }

  const state = controller.getState();

  let drugSelectSeq: any = { ...state.DrugSelectSequence };
  let modSolventConfig: any = { ...state.modSolventSelectionConfig };

  if (
    modSolventConfig?.concentration === "conc" &&
    (!modSolventConfig?.solute_concentration_rate ||
      !modSolventConfig?.solvent_concentration_rate ||
      !modSolventConfig?.conc_choice)
  ) {
    modSolventConfig.txtNoRate =
      "required: \nConc. solute\n Conc. solvent \nConc. unit";
  }
  if (
    params.drugOrderType &&
    state.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE
  ) {
    if (
      modSolventConfig?.dripRate === "drip" &&
      (!modSolventConfig?.drug_drip_in || !modSolventConfig?.drug_drip_in_unit)
    ) {
      modSolventConfig.txtNoRate = "required: \ndrip in \ndrip in unit";
      controller.setState({ modSolventSelectionConfig: modSolventConfig });
      return;
    }
    if (
      modSolventConfig?.dripRate === "rate" &&
      (!modSolventConfig?.mixture_iv_rate ||
        !modSolventConfig?.mixture_iv_rate_unit)
    ) {
      modSolventConfig.txtNoRate = "required: \ndrug rate \ndrug rate unit";
      controller.setState({ modSolventSelectionConfig: modSolventConfig });
      return;
    }
  }

  let result: any = {};
  if (modSolventConfig.solvent) {
    modSolventConfig.openModal = false;
    if (params.drugOrderType) {
      result["0"] = modSolventConfig.solvent;
      result["1"] = modSolventConfig.quantity;
      result["2"] = null;
      result["3"] = null;
      result["4"] = null;
      result["5"] = null;
      result["6"] = modSolventConfig.drug_strength;
      result["7"] = modSolventConfig.drug_strength_choice;
      result["8"] = modSolventConfig.volume;
      result["9"] = null;
      result["10"] = null;
      result["11"] = null;
      if (modSolventConfig?.dripRate === "drip") {
        result["4"] = modSolventConfig.drug_drip_in;
        result["5"] = modSolventConfig.drug_drip_in_unit;
      } else {
        result["2"] = modSolventConfig.mixture_iv_rate;
        result["3"] = modSolventConfig.mixture_iv_rate_unit;
      }
      if (modSolventConfig.concentration !== "strength") {
        result["9"] = modSolventConfig.solute_concentration_rate;
        result["10"] = modSolventConfig.solvent_concentration_rate;
        result["11"] = modSolventConfig.conc_choice;
      }
    } else {
      result["0"] = modSolventConfig.solvent;
      result["1"] = modSolventConfig.quantity;
      result["2"] = null;
      result["3"] = null;
      result["4"] = null;
      result["5"] = null;
      result["6"] = modSolventConfig.drug_strength;
      result["7"] = modSolventConfig.drug_strength_choice;
      result["8"] = modSolventConfig.volume;
      result["9"] = null;
      result["10"] = null;
      result["11"] = null;
      if (modSolventConfig.concentration !== "strength") {
        result["9"] = modSolventConfig.solute_concentration_rate;
        result["10"] = modSolventConfig.solvent_concentration_rate;
        result["11"] = modSolventConfig.conc_choice;
      }
    }
  }

  if (
    drugSelectSeq?.is_intravaneous &&
    state.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE &&
    drugSelectSeq?.add_auto_solvent
  ) {
    drugSelectSeq.dose = result["6"];
    drugSelectSeq.unit = result["7"];
    drugSelectSeq.solvent_product = result["0"];
    drugSelectSeq.solvent_quantity = result["1"];
    drugSelectSeq.mixture_solvent_volume = result["8"];
    drugSelectSeq.mixture_iv_rate = result["2"];
    drugSelectSeq.mixture_iv_rate_unit = result["3"];
    drugSelectSeq.mixture_concentration_solute = result["9"];
    drugSelectSeq.mixture_concentration_solvent = result["10"];
    drugSelectSeq.drug_concentration_unit = result["11"];
    drugSelectSeq.drug_drip_in = parseInt(result["4"]);
    drugSelectSeq.drug_drip_in_unit = result["5"];
    drugSelectSeq.is_intravaneous = true;
  } else if (
    drugSelectSeq?.is_intravaneous &&
    !state.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE
  ) {
    drugSelectSeq.solvent_product = result["0"];
    drugSelectSeq.solvent_quantity = result["1"];
    drugSelectSeq.mixture_iv_rate = result["2"];
  } else {
    drugSelectSeq.solvent_product = result["0"];
    drugSelectSeq.solvent_quantity = result["1"];
    if (state.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE) {
      drugSelectSeq.dose = result["6"];
      drugSelectSeq.unit = result["7"];
      drugSelectSeq.mixture_solvent_volume = result["8"];
      drugSelectSeq.iv_rate = result["2"];
      drugSelectSeq.iv_rate_unit = result["3"];

      drugSelectSeq.mixture_iv_rate = result["2"];
      drugSelectSeq.mixture_iv_rate_unit = result["3"];
      drugSelectSeq.mixture_concentration_solute = result["9"];
      drugSelectSeq.mixture_concentration_solvent = result["10"];
      drugSelectSeq.drug_concentration_unit = result["11"];
      drugSelectSeq.drug_drip_in = parseInt(result["4"]);
      drugSelectSeq.drug_drip_in_unit = result["5"];
    } else {
      drugSelectSeq.mixture_iv_rate = result["2"];
    }
  }

  // console.log("set drug data: ", drugSelectSeq)

  await controller.setState({
    DrugSelectSequence: drugSelectSeq,
    modSolventSelectionConfig: modSolventConfig,
  });
};

export const RemoveSolventData: Handler = (controller) => {
  const state = controller.getState();

  let drugSelectSeq: any = { ...state.DrugSelectSequence };

  drugSelectSeq.solvent_product = null;
  drugSelectSeq.solvent_quantity = 0;
  drugSelectSeq.mixture_solvent_volume = null;
  drugSelectSeq.mixture_iv_rate = null;
  drugSelectSeq.mixture_iv_rate_unit = null;
  drugSelectSeq.mixture_concentration_solute = null;
  drugSelectSeq.mixture_concentration_solvent = null;
  drugSelectSeq.drug_concentration_unit = null;
  drugSelectSeq.drug_drip_in = null;
  drugSelectSeq.drug_drip_in_unit = null;

  let modSolventConfig: any = { ...state.modSolventSelectionConfig };
  modSolventConfig.openModal = false;

  controller.setState({
    DrugSelectSequence: drugSelectSeq,
    modSolventSelectionConfig: modSolventConfig,
  });
};

// calculation
const checkMaxConc = (controller: any) => {
  const state = controller.getState();
  let modSolventConfig: any = { ...state.modSolventSelectionConfig };

  if (
    modSolventConfig?.max_concentration_rate &&
    modSolventConfig?.max_concentration_unit
  ) {
    // unit
    const unitTextList: any[] =
      modSolventConfig?.drugUnits ||
      []?.filter((item: any) => {
        return item.value === modSolventConfig?.max_concentration_unit;
      });
    const unitText = unitTextList?.[0]?.text;

    // conc rate
    let concRateUnit: string = "";
    if (
      modSolventConfig?.concentration === "strength" &&
      modSolventConfig?.drug_strength_choice
    ) {
      const concRateUnitList: any[] =
        state.DrugSelectSequence?.drugUnits?.filter((item: any) => {
          return (
            item.value === parseInt(modSolventConfig?.drug_strength_choice)
          );
        });
      if (concRateUnitList.length > 0) {
        concRateUnit = concRateUnitList?.[0]?.text + "/mL";
      }
    }
    if (
      modSolventConfig?.concentration === "conc" &&
      modSolventConfig?.conc_choice
    ) {
      const concRateUnitList: any[] =
        state.DrugSelectSequence?.drugUnits?.filter((item: any) => {
          return item.value === parseInt(modSolventConfig?.conc_choice);
        });
      if (concRateUnitList.length > 0) {
        concRateUnit = concRateUnitList?.[0]?.text;
      }
    }

    // max conc
    let result: any = { ...state.modSolventSelectionConfig };
    result.txtMaxConc = `มาตรฐานค่าความเข้มข้นที่มากสุด คือ ${modSolventConfig?.max_concentration_rate} ${unitText}`;
    result.txtMaxConcColor = "blue";

    if (
      parseFloat(modSolventConfig?.drug_strength) / parseFloat(result?.volume) >
        modSolventConfig?.max_concentration_rate &&
      concRateUnit &&
      concRateUnit === unitText
    ) {
      result.txtMaxConc = `ความเข้มข้นของสารละลายเกินมาตรฐาน ซึ่งอยู่ที่ ${modSolventConfig?.max_concentration_rate} ${unitText}`;
      result.txtMaxConcColor = "red";
    }
    controller.setState({ modSolventSelectionConfig: result });
  }
};

const recalculateIV = (controller: any, calculateField: string) => {
  const state = controller.getState();

  let modSolventConfig: any = { ...state.modSolventSelectionConfig };

  if (
    !modSolventConfig?.solvent ||
    !parseFloat(modSolventConfig?.volume) ||
    !parseFloat(modSolventConfig?.drug_strength) ||
    !parseFloat(modSolventConfig?.solute_concentration_rate) ||
    !parseFloat(modSolventConfig?.solvent_concentration_rate)
  ) {
    return;
  }
  if (
    modSolventConfig?.concentration === "conc" &&
    (calculateField === "SoluteDose" || calculateField === "SolventVolume")
  ) {
    modSolventConfig.volume =
      parseFloat(modSolventConfig?.solvent_default_stock_size) *
      parseFloat(modSolventConfig?.solute_concentration_rate);
    modSolventConfig.drug_strength =
      parseFloat(modSolventConfig?.solvent_default_stock_size) *
      parseFloat(modSolventConfig?.solute_concentration_rate);
    controller.setState({ modSolventSelectionConfig: modSolventConfig });
    return;
  }

  let calculate = 0.0;
  let multipierSoluteDose = calculateMultipler(
    parseFloat(modSolventConfig?.drug_strength)
  );
  let multipierSolventVolume = calculateMultipler(
    parseFloat(modSolventConfig?.volume)
  );

  // calculate multipier of solute and solvent to make it value to integer
  if (multipierSoluteDose > multipierSolventVolume) {
    multipierSolventVolume = multipierSoluteDose;
  } else {
    multipierSoluteDose = multipierSolventVolume;
  }

  if (calculateField === "SoluteConc") {
    calculate =
      (parseFloat(modSolventConfig?.drug_strength) *
        multipierSoluteDose *
        parseFloat(modSolventConfig?.solvent_concentration_rate)) /
      (parseFloat(modSolventConfig?.volume) * multipierSolventVolume);
    modSolventConfig.solute_concentration_rate = calculate;
  }
  if (calculateField === "SoluteDose") {
    calculate =
      (parseFloat(modSolventConfig?.solute_concentration_rate) *
        parseFloat(modSolventConfig?.volume)) /
      parseFloat(modSolventConfig?.solvent_concentration_rate);
    modSolventConfig.drug_strength = calculate;
  }
  if (calculateField === "SolventConc") {
    calculate =
      (parseFloat(modSolventConfig?.volume) *
        multipierSolventVolume *
        parseFloat(modSolventConfig?.solute_concentration_rate)) /
      (parseFloat(modSolventConfig?.drug_strength) * multipierSolventVolume);
    modSolventConfig.solvent_concentration_rate = calculate;
  }
  if (calculateField === "SolventVolume") {
    calculate =
      (parseFloat(modSolventConfig?.solvent_concentration_rate) *
        parseFloat(modSolventConfig?.drug_strength)) /
      parseFloat(modSolventConfig?.solute_concentration_rate);
    modSolventConfig.volume = calculate;
  }
  controller.setState({ modSolventSelectionConfig: modSolventConfig });
};

const recalculateConc = (controller: any) => {
  const state = controller.getState();
  let modSolventConfig: any = { ...state.modSolventSelectionConfig };

  if (
    !modSolventConfig?.solvent ||
    !parseFloat(modSolventConfig?.volume) ||
    !parseFloat(modSolventConfig?.drug_strength) ||
    !parseFloat(modSolventConfig?.solute_concentration_rate) ||
    !parseFloat(modSolventConfig?.solvent_concentration_rate)
  ) {
    return;
  }

  let concSolU: number = parseFloat(
    modSolventConfig?.solute_concentration_rate
  );
  let concSolV: number = parseFloat(
    modSolventConfig?.solvent_concentration_rate
  );

  if (concSolU === concSolV) {
    modSolventConfig.solute_concentration_rate = 1;
    modSolventConfig.solvent_concentration_rate = 1;
    controller.setState({ modSolventSelectionConfig: modSolventConfig });
    return;
  }

  // reformat Concentration to interger
  const concSolUMultipier: number = calculateMultipler(
    parseFloat(modSolventConfig?.solute_concentration_rate)
  );
  const concSolVMultipier: number = calculateMultipler(
    parseFloat(modSolventConfig?.solvent_concentration_rate)
  );
  if (concSolUMultipier > concSolVMultipier) {
    concSolV = concSolV * concSolUMultipier;
    concSolU = concSolU * concSolUMultipier;
  } else {
    concSolV = concSolV * concSolVMultipier;
    concSolU = concSolU * concSolVMultipier;
  }

  // find GCD
  if (!concSolU || !concSolV) {
    return;
  }
  let i: number = 2;
  while (true) {
    if (concSolU % i === 0 && concSolV % i === 0) {
      concSolV = concSolV / i;
      concSolU = concSolU / i;
      i = 2;
    }

    if (concSolU === 1 || concSolV === 1 || i >= concSolU || i >= concSolV) {
      break;
    }
    i = i + 1;
  }

  modSolventConfig.solute_concentration_rate = concSolU;
  modSolventConfig.solvent_concentration_rate = concSolV;
  controller.setState({ modSolventSelectionConfig: modSolventConfig });
};

const calculateMultipler = (value: number) => {
  var multipier = 1;
  while (true) {
    if (value % 1 === 0) {
      break;
    } else {
      multipier = multipier * 10;
      value = value * 10;
    }
  }
  return multipier;
};

//
const clear = (controller: any, modSolventConfig: any) => {
  let state = controller.getState();

  modSolventConfig.solvent = null;
  modSolventConfig.selectedSolvent = null;

  if (state.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE) {
    modSolventConfig.txtMaxRate = "";
    modSolventConfig.txtNoRate = "";
    modSolventConfig.txtMaxConc = "";

    modSolventConfig.volume = "";
    modSolventConfig.drug_drip_in = 0;
    modSolventConfig.mixture_iv_rate = "";
    modSolventConfig.quantity = 1;
    modSolventConfig.solute_concentration_rate = 1;
    modSolventConfig.solvent_concentration_rate = 1;
    modSolventConfig.mixture_iv_rate_unit = "";
    modSolventConfig.drug_drip_in_unit = "";
    modSolventConfig.drug_strength_choice = "";
    modSolventConfig.conc_choice = "";
    // Todo
    // txtVolume.enabled = false;
    // btnIVSave.enabled = false;

    // txtConcSolute.readOnly = false;
    // txtConcSolvent.readOnly = false;
    // stbSolvent.readOnly = false;
    // txtDrugDrip.readOnly = false;
    // txtVolume.readOnly = false;
    // txtQuantity.readOnly = false;
    // txtRate.readOnly = false;
    // txtStrength.readOnly = false;
    // cboConcUnit.readOnly = false;
    // cboDrugDripUnit.readOnly = false;
    // cboDrugRateUnit.readOnly = false;
    // cboStrengthUnit.readOnly = false;
    // radioDrugConcentrate.readOnly = false;
    // radioStrength.readOnly = false;
    // radioDrugDrip.readOnly = false;
    // radioDrugRate.readOnly = false;
    // btnCancel.text = "CANCEL";
  } else {
    // Todo
    // txtOldQuantity.text = "";
    // txtOldRate.text = "";
  }
  return modSolventConfig;
};

const validateData = (controller: any) => {
  const state = controller.getState();

  let modSolventConfig: any = { ...state.modSolventSelectionConfig };
  let error = [];
  if (state.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE) {
    if (!modSolventConfig?.solvent || modSolventConfig?.solvent === "") {
      error.push({ solvent: "กรุณาระบุค่า" });
    }

    if (modSolventConfig?.concentration === "strength") {
      if (!modSolventConfig?.volume || modSolventConfig?.volume === "") {
        error.push({ volume: "กรุณาระบุค่า" });
      } else if (parseInt(modSolventConfig?.volume) < 1) {
        error.push({ volume: "กรุณาระบุให้มีจำนวนมากกว่า 0" });
      }
    }

    if (
      !modSolventConfig?.drug_strength ||
      modSolventConfig?.drug_strength === ""
    ) {
      error.push({ drug_strength: "กรุณาระบุค่า" });
    } else if (parseInt(modSolventConfig?.drug_strength) < 1) {
      error.push({ drug_strength: "กรุณาระบุให้มีจำนวนมากกว่า 0" });
    }

    if (
      !modSolventConfig?.solute_concentration_rate ||
      modSolventConfig?.solute_concentration_rate === ""
    ) {
      error.push({ solute_concentration_rate: "กรุณาระบุค่า" });
    } else if (parseInt(modSolventConfig?.solute_concentration_rate) < 1) {
      error.push({ solute_concentration_rate: "กรุณาระบุให้มีจำนวนมากกว่า 0" });
    }

    if (
      !modSolventConfig?.solvent_concentration_rate ||
      modSolventConfig?.solvent_concentration_rate === ""
    ) {
      error.push({ solvent_concentration_rate: "กรุณาระบุค่า" });
    } else if (parseInt(modSolventConfig?.solvent_concentration_rate) < 1) {
      error.push({
        solvent_concentration_rate: "กรุณาระบุให้มีจำนวนมากกว่า 0",
      });
    }

    if (!modSolventConfig?.quantity || modSolventConfig?.quantity === "") {
      error.push({ quantity: "กรุณาระบุค่า" });
    } else if (parseInt(modSolventConfig?.quantity) < 1) {
      error.push({ quantity: "กรุณาระบุให้มีจำนวนมากกว่า 0" });
    }
  } else {
    if (!modSolventConfig?.solvent || modSolventConfig?.solvent === "") {
      error.push({ solvent: "กรุณาระบุค่า" });
    }
    if (
      !modSolventConfig?.old_quantity ||
      modSolventConfig?.old_quantity === ""
    ) {
      error.push({ old_quantity: "กรุณาระบุค่า" });
    } else if (parseInt(modSolventConfig?.old_quantity) < 1) {
      error.push({ old_quantity: "กรุณาระบุให้มีจำนวนมากกว่า 0" });
    }
  }

  if (error.length > 0) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [MOD_SOLVENT_SELECTION_ID]: error,
      },
      successMessage: {
        ...state.successMessage,
        [MOD_SOLVENT_SELECTION_ID]: null,
      },
    });
    return false;
  }
  return true;
};
