import WasmController from "../../../../frameworks/WasmController";

// APIs
import AnesthesiaDataList from "issara-sdk/apis/AnesthesiaDataList_apps_ANS";
import ANSFormPrint from "issara-sdk/apis/ANSFormPrint_apps_ANS";
import FormDataActionLogList from "issara-sdk/apis/FormDataActionLogList_apps_PTM";
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import OperatingOrderDetail from "issara-sdk/apis/OperatingOrderDetail_apps_ORM";
import TeamDetail from "issara-sdk/apis/TeamDetail_apps_ORM";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import { formatDate } from "react-lib/utils";
import moment from "moment";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // ORQueueI
  selectedOrOrder?: any;

  // sequence
  PeriDataSheetSequence?: {
    sequenceIndex?: string | null;

    id?: number | null;
    data?: {
      // CardPACURecordFormUX
      DateRamrk?: string;
      ORRmark?: string;
      IsOPD?: boolean;
      IsWard?: boolean;
      IsWardRemark?: string;
      StartOpRemark?: string;
      EndAnesRemark?: string;
      PACUDurartionTime?: string;
      AnesthesiaTeamRemark?: string;
      DiagnosisRemark?: string;
      AnestheticAgentsRemark?: string;
      OperationRemark?: string;
      // CardPeriDataSheetFormUX
      PreoperativeConditionsIsNone?: boolean;
      // CardPeriDataSheetFormUX: Respiratory
      Smoke?: boolean;
      Is102?: boolean;
      Is102Remark?: string;
      Is103?: boolean;
      Is103Remark?: string;
      Is104URI?: boolean;
      Is105LRIpneumoniaTbcOldTB?: boolean;
      Is106?: boolean;
      Is106Remark?: string;
      Is107PredictedDifficultAirway?: boolean;
      Is108?: boolean;
      Is108Remark?: string;
      Is109?: boolean;
      Is109Remark?: string;
      AllergicRhinitis?: boolean;
      Hypoxaemia?: boolean;
      IsRespiratoryOther?: boolean;
      IsRespiratoryOtherRemark?: string;
      // CardPeriDataSheetFormUX: Cardiovascular
      Hypertension?: boolean;
      PulmonaryHypertension?: boolean;
      Is203?: boolean;
      Is203Remark?: string;
      Is204?: boolean;
      Is204Remark?: string;
      Is205?: boolean;
      Is205Remark?: string;
      CongenitalHeartDisease?: boolean;
      ValvularHeartDisease?: boolean;
      Is208?: boolean;
      Is208Remark?: string;
      Is209?: boolean;
      Is209Remark?: string;
      PericardialDisease?: boolean;
      PulmonaryEmbolism?: boolean;
      IsCardiovascularOther?: boolean;
      IsCardiovascularOtherRemark?: string;
      // CardPeriDataSheetFormUX: Neuro
      Is301?: boolean;
      Is301Remark?: string;
      Is302?: boolean;
      Is302Remark?: string;
      SpinalCordlesion?: boolean;
      Is304?: boolean;
      Is304Remark?: string;
      Is305?: boolean;
      Is305Remark?: string;
      Is306?: boolean;
      Is306Remark?: string;
      Convulsion?: boolean;
      Is308?: boolean;
      Is308Remark?: string;
      IsNeuroOther?: boolean;
      IsNeuroOtherRemark?: string;
      // CardPeriDataSheetFormUX: Endocrine/Metabolic
      Is401?: boolean;
      Is401Remark?: string;
      Is402?: boolean;
      Is402Remark?: string;
      Is403?: boolean;
      Is403Remark?: string;
      OnSteriod?: boolean;
      Is405?: boolean;
      Is405Remark?: string;
      Is406?: boolean;
      Is406Remark?: string;
      Obesity?: boolean;
      DLP?: boolean;
      IsEndocrineMetabolicOther?: boolean;
      IsEndocrineMetabolicOtherRemark?: string;
      // CardPeriDataSheetFormUX: Hematologic/Infection
      Anaemia?: boolean;
      Plateletlessthan100k?: boolean;
      Coagulopathy?: boolean;
      Is504?: boolean;
      Is504Remark?: string;
      Is505?: boolean;
      Is505Remark?: string;
      Fever?: boolean;
      HIV?: boolean;
      IsHaematologicInfectionOther?: boolean;
      IsHaematologicInfectionOtherRemark?: string;
      // CardPeriDataSheetFormUX: Liver disease
      AbnormalLFT?: boolean;
      Cirrhosis?: boolean;
      Liverfailure?: boolean;
      Hepatitis?: boolean;
      IsLiverDiseaseOther?: boolean;
      IsLiverDiseaseOtherRemark?: string;
      // CardPeriDataSheetFormUX: Renal disease
      RenalImpairment?: boolean;
      Is702?: boolean;
      Is702Remark?: string;
      IsRenalDiseaseOther?: boolean;
      IsRenalDiseaseOtherRemark?: string;
      // CardPeriDataSheetFormUX: Miscellaneous
      Sepsis?: boolean;
      PostTransplantationn?: boolean;
      AutoimmuneDisease?: boolean;
      Extreme?: boolean;
      postCardiacArrest?: boolean;
      Addict?: boolean;
      AddictRemark?: string;
      Is807?: boolean;
      Is807Remark?: string;
      Cancer?: boolean;
      IsMiscellaneousOther?: boolean;
      IsMiscellaneousOtherRemark?: string;
      // CardPeriDataSheetFormUX: Monitors
      NIBP?: boolean;
      TEE?: boolean;
      SpO2?: boolean;
      Is14?: boolean;
      Is14Remark?: string;
      EKG?: boolean;
      IBP?: boolean;
      CVP?: boolean;
      EtCO2?: boolean;
      Urine?: boolean;
      Temp?: boolean;
      Is9?: boolean;
      Is9Remark?: string;
      Is10?: boolean;
      Is10Remark?: string;
      NMB?: boolean;
      Is12?: boolean;
      Is12Remark?: string;
      // CardPeriDataSheetFormUX: Special Tech.
      SpecialTechNone?: boolean;
      AwakeIntubation?: boolean;
      FiberopticIntubation?: boolean;
      Hypotension?: boolean;
      Hypothermia?: boolean;
      CPB?: boolean;
      AutologousBloodTransfusion?: boolean;
      AwakeCraniotomy?: boolean;
      DeepCirculatoryArrest?: boolean;
      BarbiturateComa?: boolean;
      OtherSpecialTec?: boolean;
      IsOtherSpecialTecRemark?: string;
      // CardPeriDataSheetFormUX: Anesthetic Agents
      Thiopental?: boolean;
      Atracurium?: boolean;
      Propofol?: boolean;
      Vecuronium?: boolean;
      Ketamine?: boolean;
      Rocuronium?: boolean;
      Etomidate?: boolean;
      Morphine?: boolean;
      Dexmedetomidine?: boolean;
      Fentany?: boolean;
      Midazolam?: boolean;
      Pethidine?: boolean;
      Isoflurane?: boolean;
      Tramadol?: boolean;
      Sevoflurane?: boolean;
      LidocaineHCL?: boolean;
      Desflurane?: boolean;
      BupivacaineHCL?: boolean;
      Succinylcholine?: boolean;
      LevoBupivacaine?: boolean;
      Pancuronium?: boolean;
      OthersAnestheticAgents?: boolean;
      IsOthersAnestheticAgentsRemark?: string;
      CisAtracurium?: boolean;
      // CardPeriDataSheetFormUX: Remark
      key174?: string;
    };

    modLogInfo?: { open?: boolean; logList: any[] };
  } | null;
};

export const StateInitial: State = {
  PeriDataSheetSequence: {
    sequenceIndex: null,

    id: null,
    data: {
      // CardPACURecordFormUX
      DateRamrk: "",
      ORRmark: "",
      IsOPD: false,
      IsWard: false,
      IsWardRemark: "",
      StartOpRemark: "",
      EndAnesRemark: "",
      PACUDurartionTime: "",
      AnesthesiaTeamRemark: "",
      DiagnosisRemark: "",
      AnestheticAgentsRemark: "",
      OperationRemark: "",
      // CardPeriDataSheetFormUX
      PreoperativeConditionsIsNone: false,
      // CardPeriDataSheetFormUX: Respiratory
      Smoke: false,
      Is102: false,
      Is102Remark: "",
      Is103: false,
      Is103Remark: "",
      Is104URI: false,
      Is105LRIpneumoniaTbcOldTB: false,
      Is106: false,
      Is106Remark: "",
      Is107PredictedDifficultAirway: false,
      Is108: false,
      Is108Remark: "",
      Is109: false,
      Is109Remark: "",
      AllergicRhinitis: false,
      Hypoxaemia: false,
      IsRespiratoryOther: false,
      IsRespiratoryOtherRemark: "",
      // CardPeriDataSheetFormUX: Cardiovascular
      Hypertension: false,
      PulmonaryHypertension: false,
      Is203: false,
      Is203Remark: "",
      Is204: false,
      Is204Remark: "",
      Is205: false,
      Is205Remark: "",
      CongenitalHeartDisease: false,
      ValvularHeartDisease: false,
      Is208: false,
      Is208Remark: "",
      Is209: false,
      Is209Remark: "",
      PericardialDisease: false,
      PulmonaryEmbolism: false,
      IsCardiovascularOther: false,
      IsCardiovascularOtherRemark: "",
      // CardPeriDataSheetFormUX: Neuro
      Is301: false,
      Is301Remark: "",
      Is302: false,
      Is302Remark: "",
      SpinalCordlesion: false,
      Is304: false,
      Is304Remark: "",
      Is305: false,
      Is305Remark: "",
      Is306: false,
      Is306Remark: "",
      Convulsion: false,
      Is308: false,
      Is308Remark: "",
      IsNeuroOther: false,
      IsNeuroOtherRemark: "",
      // CardPeriDataSheetFormUX: Endocrine/Metabolic
      Is401: false,
      Is401Remark: "",
      Is402: false,
      Is402Remark: "",
      Is403: false,
      Is403Remark: "",
      OnSteriod: false,
      Is405: false,
      Is405Remark: "",
      Is406: false,
      Is406Remark: "",
      Obesity: false,
      DLP: false,
      IsEndocrineMetabolicOther: false,
      IsEndocrineMetabolicOtherRemark: "",
      // CardPeriDataSheetFormUX: Hematologic/Infection
      Anaemia: false,
      Plateletlessthan100k: false,
      Coagulopathy: false,
      Is504: false,
      Is504Remark: "",
      Is505: false,
      Is505Remark: "",
      Fever: false,
      HIV: false,
      IsHaematologicInfectionOther: false,
      IsHaematologicInfectionOtherRemark: "",
      // CardPeriDataSheetFormUX: Liver disease
      AbnormalLFT: false,
      Cirrhosis: false,
      Liverfailure: false,
      Hepatitis: false,
      IsLiverDiseaseOther: false,
      IsLiverDiseaseOtherRemark: "",
      // CardPeriDataSheetFormUX: Renal disease
      RenalImpairment: false,
      Is702: false,
      Is702Remark: "",
      IsRenalDiseaseOther: false,
      IsRenalDiseaseOtherRemark: "",
      // CardPeriDataSheetFormUX: Miscellaneous
      Sepsis: false,
      PostTransplantationn: false,
      AutoimmuneDisease: false,
      Extreme: false,
      postCardiacArrest: false,
      Addict: false,
      AddictRemark: "",
      Is807: false,
      Is807Remark: "",
      Cancer: false,
      IsMiscellaneousOther: false,
      IsMiscellaneousOtherRemark: "",
      // CardPeriDataSheetFormUX: Monitors
      NIBP: false,
      TEE: false,
      SpO2: false,
      Is14: false,
      Is14Remark: "",
      EKG: false,
      IBP: false,
      CVP: false,
      EtCO2: false,
      Urine: false,
      Temp: false,
      Is9: false,
      Is9Remark: "",
      Is10: false,
      Is10Remark: "",
      NMB: false,
      Is12: false,
      Is12Remark: "",
      // CardPeriDataSheetFormUX: Special Tech.
      SpecialTechNone: false,
      AwakeIntubation: false,
      FiberopticIntubation: false,
      Hypotension: false,
      Hypothermia: false,
      CPB: false,
      AutologousBloodTransfusion: false,
      AwakeCraniotomy: false,
      DeepCirculatoryArrest: false,
      BarbiturateComa: false,
      OtherSpecialTec: false,
      IsOtherSpecialTecRemark: "",
      // CardPeriDataSheetFormUX: Anesthetic Agents
      Thiopental: false,
      Atracurium: false,
      Propofol: false,
      Vecuronium: false,
      Ketamine: false,
      Rocuronium: false,
      Etomidate: false,
      Morphine: false,
      Dexmedetomidine: false,
      Fentany: false,
      Midazolam: false,
      Pethidine: false,
      Isoflurane: false,
      Tramadol: false,
      Sevoflurane: false,
      LidocaineHCL: false,
      Desflurane: false,
      BupivacaineHCL: false,
      Succinylcholine: false,
      LevoBupivacaine: false,
      Pancuronium: false,
      OthersAnestheticAgents: false,
      IsOthersAnestheticAgentsRemark: "",
      CisAtracurium: false,
      // CardPeriDataSheetFormUX: Remark
      key174: "",
    },
    modLogInfo: { open: false, logList: [] },
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const GetMaster: Handler = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["drugName", {}],
        ["divisionWard", {}],
        // CardPeriDataSheetFormUX: Respiratory
        ["peri_102", {}],
        ["peri_103", {}],
        ["peri_106", {}],
        ["peri_108", {}],
        ["peri_109", {}],
        // CardPeriDataSheetFormUX: Cardiovascular
        ["peri_203", {}],
        ["peri_204", {}],
        ["peri_205", {}],
        ["peri_208", {}],
        ["peri_209", {}],
        // CardPeriDataSheetFormUX: Neuro
        ["peri_301", {}],
        ["peri_302", {}],
        ["peri_304", {}],
        ["peri_305", {}],
        ["peri_306", {}],
        ["peri_308", {}],
        // CardPeriDataSheetFormUX: Endocrine/Metabolic
        ["peri_401", {}],
        ["peri_402", {}],
        ["peri_403", {}],
        ["peri_405", {}],
        ["peri_406", {}],
        // CardPeriDataSheetFormUX: Hematologic/Infection
        ["peri_504", {}],
        ["peri_505", {}],
        // CardPeriDataSheetFormUX: Renal disease
        ["peri_702", {}],
        // CardPeriDataSheetFormUX: Miscellaneous
        ["peri_807", {}],
        // CardPeriDataSheetFormUX: Monitors
        ["peri_9", {}],
        ["peri_10", {}],
        ["peri_12", {}],
        ["peri_14", {}],
      ],
    },
  });

  controller.setState(
    {
      PeriDataSheetSequence: { sequenceIndex: "Action" },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "FETCH_PERI") {
    const [formPeriResp, formPeriErr, formPeriNet] =
      await FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: params.formCode,
          form_version: params.formVersion,
        },
      });

    const [operatingResp, operatingErr, operatingNet] =
      await OperatingOrderDetail.retrieve({
        apiToken: controller.apiToken,
        pk: state.selectedOrOrder.id,
      });

    const [teamDetailResp, teamDetailErr, teamDetailNet] =
      await TeamDetail.retrieve({
        apiToken: controller.apiToken,
        pk: operatingResp?.teams[0]?.id,
      });

    const [anesthesiaResp, anesthesiaErr, anesthesiaNet] =
      await DoctorDetail.retrieve({
        apiToken: controller.apiToken,
        pk: operatingResp?.anesthesia_teams[0]?.anesthesiologist,
      });

    const [surgicalTeamResp, surgicalTeamErr, surgicalTeamNet] =
      await DoctorDetail.retrieve({
        apiToken: controller.apiToken,
        pk: operatingResp?.teams[0]?.chief_surgeon,
      });

    if (formPeriErr || !formPeriResp.data) {
      const [formPacuResp, formPacuErr, formPacuNet] =
        await FormDataLatest.retrieve({
          apiToken: controller.apiToken,
          params: {
            encounter: state.selectedOrOrder?.encounter,
            form_code: "CardPacuRecord",
            form_version: "1.0",
          },
        });
      if (formPacuErr || !formPacuResp.data) {
        let seq: any = {
          ...StateInitial.PeriDataSheetSequence,
          sequenceIndex: state.PeriDataSheetSequence?.sequenceIndex,
        };

        const aneOrderId = state.selectedOrOrder?.other_orders?.find(
          (item: any) => item.model === "anesthesiaorder"
        )?.id;
        if (aneOrderId) {
          const [aneDataResp, aneDataErr, aneDataNet] =
            await AnesthesiaDataList.list({
              apiToken: controller.apiToken,
              params: {
                encounter: state.selectedOrOrder?.encounter,
                ans_order_id: aneOrderId,
              },
            });
          if (aneDataResp) {
            seq.data.DiagnosisRemark = aneDataResp.post_diagnosis;
            seq.data.OperationRemark = aneDataResp.post_operation;
          }
          seq.data.ORRmark = operatingResp?.operating_room_no;
          seq.data.AnesthesiaTeamRemark = anesthesiaResp?.name_code;
          seq.data.DiagnosisRemark =
            teamDetailResp?.post_principal_diagnosis.length !== 0
              ? `[${teamDetailResp?.post_principal_diagnosis[0]?.icd_code}] ${teamDetailResp?.post_principal_diagnosis[0]?.icd_term}`
              : "";
          seq.data.AnestheticAgentsRemark = surgicalTeamResp?.name_code;
          seq.data.OperationRemark =
            teamDetailResp !== null
              ? `[${teamDetailResp?.post_operating_order_item?.procedures[0]?.icd9cm_code}] ${teamDetailResp?.post_operating_order_item?.procedures[0]?.icd9cm_term}`
              : "";
          seq.data.DateRamrk = formatDate(moment());
          seq.data.StartDate = formatDate(moment());
          seq.data.EndDate = formatDate(moment());
        }
        controller.setState({ PeriDataSheetSequence: seq });
      } else {
        let seq: any = {
          ...StateInitial.PeriDataSheetSequence,
          sequenceIndex: state.PeriDataSheetSequence?.sequenceIndex,
          ...formPacuResp
        };

        // seq.data.DateRamrk = formPacuResp.data?.DateRamrk;
        // seq.data.ORRmark = formPacuResp.data?.ORRmark;
        // seq.data.IsOPD = formPacuResp.data?.IsOPD;
        // seq.data.IsWard = formPacuResp.data?.IsWard;
        // seq.data.IsWardRemark = formPacuResp.data?.IsWardRemark;
        // seq.data.StartOpRemark = formPacuResp.data?.StartOpRemark;
        // seq.data.EndAnesRemark = formPacuResp.data?.EndAnesRemark;
        // seq.data.PACUDurartionTime = formPacuResp.data?.PACUDurartionTime;
        // seq.data.AnesthesiaTeamRemark = formPacuResp.data?.AnesthesiaTeamRemark;
        // seq.data.DiagnosisRemark = formPacuResp.data?.DiagnosisRemark;
        // seq.data.AnestheticAgentsRemark =
        //   formPacuResp.data?.AnestheticAgentsRemark;
        // seq.data.OperationRemark = formPacuResp.data?.OperationRemark;
        controller.setState({ PeriDataSheetSequence: seq });
      }
    } else {
      controller.setState({
        PeriDataSheetSequence: {
          ...state.PeriDataSheetSequence,
          ...formPeriResp,
        },
      });
    }
  } else if (params?.action === "SAVE" || params?.action === "CONFIRM") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    let saveResp = null;
    let saveErr = null;
    let saveNet = null;

    const data = {
      action: params.action, // SAVE
      data: state.PeriDataSheetSequence?.data,
      encounter: state.selectedOrOrder?.encounter,
      form_code: params.formCode,
      form_name: params.formName,
      form_version: params.formVersion,
    };
    if (state.PeriDataSheetSequence?.id) {
      [saveResp, saveErr, saveNet] = await FormDataDetail.update({
        apiToken: controller.apiToken,
        pk: state.PeriDataSheetSequence?.id,
        data: data,
      });
    } else {
      [saveResp, saveErr, saveNet] = await FormDataList.create({
        apiToken: controller.apiToken,
        data: data,
      });
    }
    if (saveErr) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.cardKey]: saveErr },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    } else {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params.cardKey]: `${params?.action} Success`,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "SUCCESS",
        },
      });
      Action(controller, { ...params, action: "FETCH_PERI" });
    }
  } else if (params?.action === "PRINT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    if (state.PeriDataSheetSequence?.id) {
      const [printResp, printErr, printNet] = await ANSFormPrint.post({
        apiToken: controller.apiToken,
        data: {
          form_id: state.PeriDataSheetSequence?.id,
          pdf: true,
        },
      });
      if (printErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: printErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
      }
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: "บันทึก Form ก่อนทำรายการ",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params?.action === "LOG") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    if (state.PeriDataSheetSequence?.id) {
      const [logResp, logErr, logNet] = await FormDataActionLogList.list({
        apiToken: controller.apiToken,
        pk: state.PeriDataSheetSequence?.id,
      });
      if (logErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: logErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
          PeriDataSheetSequence: {
            ...state.PeriDataSheetSequence,
            modLogInfo: { open: true, logList: logResp?.items || [] },
          },
        });
      }
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: "ไม่มี Form id",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  }
};
