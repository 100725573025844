import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { Accordion, Form, Dropdown, Icon, Label, Button, Modal, List, Input, Message } from "semantic-ui-react";
import { toast } from "react-toastify";
import _ from "react-lib/compat/lodashplus";
import * as utils from "react-lib/utils";

import CardLayout from "react-lib/apps/common/CardLayout";
import PureReactTable from "react-lib/apps/common/PureReactTable";
import { formatPrice, formatComma } from "react-lib/apps/common/PureReactTable";

import { BillTransactionIPDItemDetail } from "react-lib/apis/master/apps/INF/BillTransactionIPDItem"

const AccordionTitle = React.memo(({ name = "", isActive = true, onClick = () => {} }) => {
	return (
		<Accordion.Title style={{ border: "none" }} active={isActive} onClick={onClick}>
			<label style={{ fontWeight: "bold" }}>{name}</label>
			<Icon name="dropdown" />
		</Accordion.Title>
	);
});

const FormLabel = ({ name, value, isRequired = true, weight = 16 }) => {
	return (
		<Form.Field width={weight}>
			<label>
				{name}
				{isRequired && <span>*</span>}
			</label>
			<Label style={STYLE.LABEL_INFO}>{value}</Label>
		</Form.Field>
	);
};

const FormDropdown = forwardRef(
	({ name, text, options, onChange = (event, data) => {}, isRequired = true, weight = 16 }, ref) => {
		return (
			<Form.Field width={weight}>
				<label>
					{text}
					{isRequired && <span>*</span>}
				</label>
				<Dropdown selection fluid ref={ref ? ref : null} name={name} options={options} onChange={onChange} />
			</Form.Field>
		);
	}
);

const PersonalInfo = ({ data, showDetail, onToggle }) => {
	const handleAccordion = (event, data) => {
		onToggle();
	};

	return (
		<>
			<AccordionTitle name={"ข้อมูลส่วนบุคคล"} isActive={showDetail} onClick={handleAccordion} />
			<Accordion.Content style={{ marginLeft: "16px" }} active={showDetail}>
				<Form>
					<Form.Group inline>
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={"HN"} value={data.hn ? data.hn : "-"} />
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={"ชื่อ"} value={data.patient_name ? data.patient_name : "-"} />
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={"Encounter ID/รหัสเบิก"} value={data.encounter_id ? data.encounter_id : "-"} />
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "11%" }}>
							<FormLabel name={"AN"} value={data.an ? data.an : "-"} />
						</Form.Field>
						<Form.Field style={{ width: "11%" }}>
							<FormLabel name={"เพศ"} value={data.gender_name ? data.gender_name : "-"} />
						</Form.Field>
					</Form.Group>

					<Form.Group inline style={{ marginTop: "16px" }}>
						<Form.Field style={{ width: "22%" }}>
							<FormLabel
								name={"เอกสารระบุตัวตน"}
								value={data.identity_document_type ? data.identity_document_type : "-"}
							/>
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={"เลขบัตร"} value={data.identity_document_no ? data.identity_document_no : "-"} />
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={"วันเดือนปีเกิด"} value={data.birthdate ? data.birthdate : "-"} />
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "16%" }}>
							<FormLabel name={"สถานภาพสมรส"} value={data.marriage_name ? data.marriage_name : "-"} />
						</Form.Field>
					</Form.Group>

					<Form.Group inline style={{ marginTop: "16px" }}>
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={"จังหวัด"} value={data.province_name ? data.province_name : "-"} isRequired={false} />
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={"อำเภอ/เขต"} value={data.district_name ? data.district_name : "-"} isRequired={false} />
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={"สัญชาติ"} value={data.nationality_name ? data.nationality_name : "-"} />
						</Form.Field>
					</Form.Group>
				</Form>
			</Accordion.Content>
		</>
	);
};

const ConfirmSentClaim = React.memo(({ editData, onClose = () => {}, onConfirm = staffCode => {} }) => {
	const [errorMessage, setErrorMessage] = useState("");
	const staffCodeRef = useRef();

	const generateListItem = items => {
		let newArr = [];
		var index = 0;
		for (var k in items) {
			if (items.hasOwnProperty(k)) {
				newArr.push(
					<List.Item key={index} as={"li"}>
						{EDIT_TYPE_STRING[k]}
					</List.Item>
				);
				index++;
			}
		}
		return newArr;
	};

	const handleConfirm = (event, data) => {
		if (staffCodeRef) {
			let staffCode = staffCodeRef.current.inputRef.current.value;
			if (staffCode === "") {
				setErrorMessage("กรุณาระบุรหัสพนักงาน");
				return;
			}
			setErrorMessage("");
			onConfirm(staffCode);
		}
	};

	return (
		<CardLayout
			titleText={"ยืนยันข้อมูลการส่งเบิก"}
			headerColor="orange"
			toggleable={false}
			closeable={true}
			onClose={onClose}
		>
			{errorMessage !== "" && <Message error>{errorMessage}</Message>}

			<Form style={{ marginLeft: "16px", marginRight: "16px" }}>
				{!_.isEmpty(editData) && (
					<Form.Group inline style={{ marginBottom: "16px" }}>
						<Form.Field width={6}>
							<label>รายการที่แก้ไข</label>
						</Form.Field>
						<Form.Field width={10}>
							<List as={"ul"}>{generateListItem(editData)}</List>
						</Form.Field>
					</Form.Group>
				)}
				<Form.Group inline style={{ marginBottom: "16px" }}>
					<Form.Field width={6}>
						<label>
							กรุณาระบุรหัสพนักงาน<span>*</span>
						</label>
					</Form.Field>
					<Form.Field width={10}>
						<Input fluid ref={staffCodeRef} />
					</Form.Field>
				</Form.Group>
				<Form.Group inline style={{ display: "flex", justifyContent: "flex-end" }}>
					<Button color={"green"} type={"submit"} onClick={handleConfirm}>
						ตกลง
					</Button>
				</Form.Group>
			</Form>
		</CardLayout>
	);
});

// Columns for Diagnosis Information
const DiagnosisColumns = [
	{ Header: "ID", accessor: "id", width: 80 },
	{
		Header: "Date",
		width: 140,
		Cell: props => {
			let data = props.original;
			let date = new Date(data.diagnosis_date);
			return <label>{utils.formatDate(date)}</label>;
		},
	},
	{ Header: "แพทย์ผู้วินิจฉัย", accessor: "doctor_full_name", width: 220 },
	{ Header: "Diagnosis Type", accessor: "dx_type", width: 220 },
	{ Header: "Medical Term", accessor: "medical_term", width: 260 },
	{ Header: "Code", accessor: "code", width: 120 },
	{ Header: "Diagnosis Term", accessor: "diagnosis_term" },
];
// Columns for Treatment Information
const ProcedureColumns = [
	{ Header: "ID", accessor: "id", width: 80 },
	{
		Header: "วัน-เวลาที่เริ่ม",
		width: 200,
		Cell: props => {
			let data = props.original;
			return <label>{utils.formatToBEDatetime({ date: data.start_date })}</label>;
		},
	},
	{
		Header: "วัน-เวลาที่สิ้นสุด",
		width: 200,
		Cell: props => {
			let data = props.original;
			return <label>{utils.formatToBEDatetime({ date: data.end_date })}</label>;
		},
	},
	{ Header: "แผนกที่ทำหัตถการ", accessor: "location", width: 220 },
	{ Header: "Medical Term", accessor: "medical_term", width: 220 },
	{ Header: "Code", accessor: "code", width: 120 },
	{ Header: "Procedure Term", accessor: "procedure_term" },
];

const InvoiceColumns = (editedCell, setEditedCell, loadInvoiceItem) =>  ([
	{ Header: "ID", accessor: "id", width: 80 },
	{
		Header: "วันเวลาที่ใช้",
		width: 180,
		Cell: props => {
			let data = props.original;
			return <label>{utils.formatToBEDatetime({ date: data.service_date })}</label>;
		},
	},
	{ Header: "หมวดค่าใช้จ่าย", style: { whiteSpace: "nowrap" }, accessor: "bill_group", width: 240 },
	{ Header: "รหัสรายการ", accessor: "lc_code", width: 120 },
	{ Header: "รายการ", accessor: "description", width: 220 },
	{ Header: "ปริมาณ", accessor: "qty", width: 80,
	  Cell: props => {
		console.log(props.original)
		console.log(editedCell)
		
		if (editedCell.id === props.original.id && editedCell.key === "qty") {
			return (<input
				autoFocus
				style={{minWidth: 0, width: "100%", color: isNaN(editedCell.value) ? "red" : "black" }}
				value={editedCell.value} 
				onChange={(e) => { 
					setEditedCell({
						id: editedCell.id,
						key: "qty",
						value: e.target.value
					})
				}}
				onBlur={async (e) => {
					if (!isNaN(editedCell.value)) {
						const [r, err, n] = await BillTransactionIPDItemDetail.patch({
							pk: props.original.id,
							data: {
								qty: editedCell.value
							}
						})
						if (err) { console.log(e, n); }
					}
					loadInvoiceItem()
					setEditedCell({
						id: null,
						key: null,
						value: null
					})
				}}
			/>)
		} else {
			return (<div
				onClick={(e) => {
					setEditedCell({
						id: props.original.id,
						key: "qty",
						value: ""
					})
				}}>
				{props.original.qty}
			</div>)
		}
		
	  }
	},
	{ Header: "ราคา(หน่วย)", accessor: "unit_price", width: 120, Cell: row => formatPrice(row.value) },
	{ Header: "ราคา", accessor: "charge_amount", width: 120, Cell: row => formatPrice(row.value) },
	{ Header: "Diag ID", accessor: "diagnosis_sequence", width: 100 },
	{ Header: "ID หัตถการ", accessor: "procedure_sequence", width: 100 },
	{ Header: "CSMBS Type", accessor: "bill_group_cs", width: 140 },
	{ Header: "STD Code", accessor: "std_code", width: 140 },
	{ Header: "ประเภทการเบิก", accessor: "claim_cat", width: 120 },
	{ Header: "อัตราการเบิก(หน่วย)", accessor: "claim_unit_price", width: 160, Cell: row => formatPrice(row.value) },
	{ Header: "ยอดเบิก", accessor: "claim_amount", width: 140, Cell: row => formatPrice(row.value) },
]);

var selectedData = {};
const CardSentClaimInformationDetail = props => {
	const isMounted = useRef(true);
	const [loading, setLoading] = useState(false);
	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	// State for Accordion
	const [togglePersonalInFo, setTogglePersonalInFo] = useState(true);
	const [toggleAdmitInfo, setToggleAdmitInfo] = useState(true);
	const [toggleDiagnosisInfo, setToggleDiagnosisInFo] = useState(true);
	const [toggleTreatmentInfo, setToggleTreatmentInFo] = useState(true);
	const [toggleInvoiceInfo, setToggleInvoiceInfo] = useState(true);
	const [toggleSentInfo, setToggleSentInfo] = useState(true);
	// State for choices
	const [choicesSentClaimType, setChoicesSentClaimType] = useState([]);
	const [choicesAdmitType, setChoicesAdmitType] = useState([]);
	const [choicesAdmitSource, setChoicesAdmitSource] = useState([]);
	const [choicesPreDischarge, setChoicesPreDischarge] = useState([]);
	const [choicesDischarge, setChoicesDischarge] = useState([]);
	const [choicesDivision, setChoicesDivision] = useState([]);
	// State for detail
	const [detail, setDetail] = useState({});
	const [diagnosisInfo, setDiagnosisInfo] = useState([]);
	const [procedureInfo, setProcedureInfo] = useState([]);
	const [invoiceInfo, setInvoiceInfo] = useState([]);
	const [editedCell, setEditedCell] = useState({ id: null, key: null, value: null })
	// Use ref
	const sentClaimTypeRef = useRef();
	const admitTypeRef = useRef();
	const admitSourceRef = useRef();
	const preDischargeRef = useRef();
	const dischargeRef = useRef();
	const divisionRef = useRef();

	/** Handle confirm modal */
	const showConfirmModal = () => {
		setOpenConfirmModal(true);
	};

	const hideConfirmModal = () => {
		setOpenConfirmModal(false);
	};

	/** Method for Accordion */
	const handlePersonalInfo = () => {
		setTogglePersonalInFo(!togglePersonalInFo);
	};

	const handleAdmitInfo = () => {
		setToggleAdmitInfo(!toggleAdmitInfo);
	};

	const handleDiagnosisInfo = () => {
		setToggleDiagnosisInFo(!toggleDiagnosisInfo);
	};

	const handleTreatmentInfo = () => {
		setToggleTreatmentInFo(!toggleTreatmentInfo);
	};

	const handleInvoiceInfo = () => {
		setToggleInvoiceInfo(!toggleInvoiceInfo);
	};

	const handleSentInfo = () => {
		setToggleSentInfo(!toggleSentInfo);
	};
	/********************** */
	/** Get choices */
	const getSentClaimType = async () => {
		const choices = await props.controller.choicesSentClaimType();
		if (!isMounted.current) {
			return;
		}
		setChoicesSentClaimType(choices);
		setDefaultDropdownValue(sentClaimTypeRef, choices, 0);
	};

	const getAdmitType = async () => {
		const choices = await props.controller.choicesAdmitOrderType();
		if (!isMounted.current) {
			return;
		}
		setChoicesAdmitType(choices);
		setDefaultDropdownValue(admitTypeRef, choices, 0);
	};

	const getAdmitSource = async () => {
		const choices = await props.controller.choicesAdmitSource();
		if (!isMounted.current) {
			return;
		}
		setChoicesAdmitSource(choices);
		setDefaultDropdownValue(admitSourceRef, choices, 0);
	};

	const getPreDischargeType = async () => {
		const choices = await props.controller.choicesPreDischargeType();
		if (!isMounted.current) {
			return;
		}
		setChoicesPreDischarge(choices);
		setDefaultDropdownValue(preDischargeRef, choices, 0);
	};

	const getDischargeType = async () => {
		const choices = await props.controller.choicesDischargeType();
		if (!isMounted.current) {
			return;
		}
		setChoicesDischarge(choices);
		setDefaultDropdownValue(dischargeRef, choices, 0);
	};

	const getDivision = async () => {
		const choices = await props.controller.choicesDivision();
		if (!isMounted.current) {
			return;
		}
		setChoicesDivision(choices);
		setDefaultDropdownValue(divisionRef, choices, 0);
	};

	// Initial value
	const setDefaultDropdownValue = (targetRef, choices, targetIndex) => {
		if (targetRef && targetRef.current.state.value === "") {
			if (choices.length > 0) {
				targetRef.current.setValue(choices[targetIndex].value);
			}
		}
	};
	/********************** */
	const loadDiagnosisInfo = async () => {
		var items = [];
		const [data, error] = await props.controller.loadDiagnosisInfo(props.transactionId);
		if (data) {
			items = data.items;
		}
		setDiagnosisInfo(items);
	};

	const loadProcedureInfo = async () => {
		var items = [];
		const [data, error] = await props.controller.loadProcedureInfo(props.transactionId);
		if (data) {
			items = data.items;
		}
		setProcedureInfo(items);
	};

	const loadInvoiceItem = async () => {
		var items = [];
		const [data, error] = await props.controller.loadInvoiceItems(props.transactionId);
		if (data) {
			items = data.items;
		}
		setInvoiceInfo(items);
	};

	/** Initial detail */
	const initialDetail = async () => {
		const [data, error] = await props.controller.loadBillTransactionDetail(props.transactionId);
		// console.log("....... data: ", data, ", error: ", error);
		// Initial UI
		if (data) {
			setDetail(data);
			sentClaimTypeRef.current.setValue(data.submit_type);
			admitTypeRef.current.setValue(data.admit_type);
			admitSourceRef.current.setValue(data.admit_source);
			preDischargeRef.current.setValue(data.predischarge_condition);
			dischargeRef.current.setValue(data.discharge_status);
			divisionRef.current.setValue(parseInt(data.department));
		}
		// Initial choices
		getSentClaimType();
		getAdmitType();
		getAdmitSource();
		getPreDischargeType();
		getDischargeType();
		getDivision();
		// Initial Table
		loadDiagnosisInfo();
		loadProcedureInfo();
		loadInvoiceItem();
	};
	/********************** */
	/** Handle all dropdown */
	const handleDropdownChange = (event, data) => {
		if (!data) {
			return;
		}

		let targetItem = findItem(data.options, data.value);
		if (targetItem) {
			selectedData = {
				...selectedData,
				[data.name]: targetItem,
			};
		}
	};

	const findItem = (items, value) => {
		const result = items.find(item => {
			if (item.value === value) {
				return true;
			}
			return false;
		});

		return result;
	};

	/** Handle "บันทึกข้อมูลการส่งเบิก" button */
	const handleSentClaimSave = async () => {
		if (_.isEmpty(selectedData)) {
			return;
		}
		// Start loading
		setLoading(true);
		var data = {};
		for (var k in selectedData) {
			if (selectedData.hasOwnProperty(k)) {
				data = { ...data, [k]: selectedData[k].value };
			}
		}
		// Update bill transaction
		const [billData, billError] = await props.controller.updateBillTransaction(props.transactionId, data);
		if (billData) {
			toast.success("บันทึกข้อมูลการส่งเบิกสำเร็จ");
			// Clear data
			selectedData = {};
		} else {
			toast.error("บันทึกข้อมูลการส่งเบิกไม่สำเร็จ!");
		}
		// Stop loading
		setLoading(false);
	};

	/** Handle "ยืนยันข้อมูลการส่งเบิก" button */
	const handleSentClaimConfirm = async staffCode => {
		hideConfirmModal();
		if (staffCode === "") {
			return;
		}

		// Start loading
		setLoading(true);
		// Get token with staff code
		const [tokenData, tokenError] = await props.controller.getUserTokenize(staffCode);
		if (tokenData) {
			let token = tokenData.token;
			// Prepare data
			var data = {
				confirmed: true,
				confirmed_by: token,
			};
			for (var k in selectedData) {
				if (selectedData.hasOwnProperty(k)) {
					data = { ...data, [k]: selectedData[k].value };
				}
			}
			// Update bill transaction
			const [billData, billError] = await props.controller.updateBillTransaction(props.transactionId, data);
			// Stop loading
			setLoading(false);
			if (billData) {
				props.onCompleted();
				props.onClose();
				return;
			}
		}
		toast.error("การยืนยันข้อมูลการส่งเบิกไม่สำเร็จ!");
	};

	useEffect(() => {
		if (props.controller && props.transactionId) {
			// Load detail
			initialDetail();
		}
	}, [props.controller, props.transactionId]);

	useEffect(() => {
		selectedData = {};

		return () => {
			isMounted.current = false;
		};
	}, []); 

	return (
		<CardLayout
			titleText={props.cardTitle}
			headerColor={"green"}
			loading={loading}
			toggleable={false}
			closeable={true}
			onClose={props.onClose}
		>
			<Modal open={openConfirmModal} size={"tiny"} onClose={hideConfirmModal}>
				<ConfirmSentClaim editData={selectedData} onClose={hideConfirmModal} onConfirm={handleSentClaimConfirm} />
			</Modal>

			<Accordion.Content>
				<Form style={{ marginLeft: "16px" }}>
					<Form.Group inline>
						<Form.Field width={4}>
							<FormDropdown
								ref={sentClaimTypeRef}
								name={EDIT_TYPE.SENT_CLAIM_TYPE}
								text={"ประเภทการส่งเบิก"}
								options={choicesSentClaimType}
								onChange={handleDropdownChange}
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			</Accordion.Content>

			{/* Personal Information */}
			<PersonalInfo data={detail} showDetail={togglePersonalInFo} onToggle={handlePersonalInfo} />

			{/* Admit Information */}
			<AccordionTitle name={"ข้อมูลการ Admit"} isActive={toggleAdmitInfo} onClick={handleAdmitInfo} />
			<Accordion.Content style={{ marginLeft: "16px" }} active={toggleAdmitInfo}>
				<Form>
					<Form.Group inline>
						<Form.Field style={{ width: "22%" }}>
							<FormDropdown
								ref={admitTypeRef}
								name={EDIT_TYPE.ADMIT_TYPE}
								text={"ประเภทการ Admit"}
								options={choicesAdmitType}
								onChange={handleDropdownChange}
							/>
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormDropdown
								ref={admitSourceRef}
								name={EDIT_TYPE.ADMIT_SOURCE}
								text={"รับ Admit จาก"}
								options={choicesAdmitSource}
								onChange={handleDropdownChange}
							/>
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={"เริ่ม Admit"} value={detail.admit_dt ? utils.formatToBEDatetime({ date: detail.admit_dt }) : "-"} />
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel
								name={"สิ้นสุด"}
								value={detail.discharge_dt ? utils.formatToBEDatetime({ date: detail.discharge_dt }) : "-"}
							/>
						</Form.Field>
					</Form.Group>

					<Form.Group inline style={{ marginTop: "16px" }}>
						<Form.Field style={{ width: "22%" }}>
							<FormDropdown
								ref={preDischargeRef}
								name={EDIT_TYPE.PRE_DISCHARGE_TYPE}
								text={"สภาพก่อนจำหน่าย"}
								options={choicesPreDischarge}
								onChange={handleDropdownChange}
							/>
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormDropdown
								ref={dischargeRef}
								name={EDIT_TYPE.DISCHARGE_TYPE}
								text={"สถานะการจำหน่าย"}
								options={choicesDischarge}
								onChange={handleDropdownChange}
							/>
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={"น้ำหนัก(kg.)"} value={detail.admit_weight ? detail.admit_weight : "-"} />
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormDropdown
								ref={divisionRef}
								name={EDIT_TYPE.DIVISION}
								text={"แผนกที่รักษา"}
								options={choicesDivision}
								onChange={handleDropdownChange}
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			</Accordion.Content>

			{/* Diagnosis Information */}
			<AccordionTitle name={"ข้อมูลการวินิจฉัย"} isActive={toggleDiagnosisInfo} onClick={handleDiagnosisInfo} />
			<Accordion.Content active={toggleDiagnosisInfo}>
				<PureReactTable
					style={{ height: "200px" }}
					showPagination={false}
					minRows={5}
					defaultPageSize={1000}
					data={diagnosisInfo}
					columns={DiagnosisColumns}
				/>
			</Accordion.Content>

			{/* Treatment Information */}
			<AccordionTitle name={"ข้อมูลการทำหัตถการ"} isActive={toggleTreatmentInfo} onClick={handleTreatmentInfo} />
			<Accordion.Content active={toggleTreatmentInfo}>
				<PureReactTable
					style={{ height: "200px" }}
					showPagination={false}
					minRows={5}
					defaultPageSize={1000}
					data={procedureInfo}
					columns={ProcedureColumns}
				/>
			</Accordion.Content>

			{/* Invoice Information */}
			<AccordionTitle name={"ข้อมูล Invoice"} isActive={toggleInvoiceInfo} onClick={handleInvoiceInfo} />
			<Accordion.Content active={toggleInvoiceInfo}>
				<Form style={{ marginLeft: "16px", marginBottom: "16px" }}>
					<Form.Group inline>
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={"Invoice No."} value={detail.invoice_no ? detail.invoice_no : "-"} isRequired={false} />
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={"วันที่ออก"} value={detail.invoice_date ? detail.invoice_date : "-"} />
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={"เวลา"} value={detail.invoice_time ? detail.invoice_time : "-"} isRequired={false} />
						</Form.Field>
					</Form.Group>
				</Form>

				<PureReactTable
					style={{ height: "200px" }}
					showPagination={false}
					minRows={5}
					defaultPageSize={1000}
					data={invoiceInfo}
					columns={InvoiceColumns(editedCell, setEditedCell, loadInvoiceItem)}
				/>

				<Form style={{ marginLeft: "16px", marginTop: "16px" }}>
					<Form.Group inline>
						<Form.Field style={{ width: "22%" }}>
							<FormLabel
								name={"ส่วนลด"}
								value={detail.total_discount_price ? formatComma(detail.total_discount_price) : "-"}
								isRequired={false}
							/>
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel
								name={"ยอดชำระทั้งหมด"}
								value={detail.total_amount_price ? formatComma(detail.total_amount_price) : "-"}
							/>
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={"ค่า RW"} value={detail.rw_value ? formatComma(detail.rw_value) : "-"} />
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={"ค่า ADJRW"} value={detail.adj_rw_value ? formatComma(detail.adj_rw_value) : "-"} />
						</Form.Field>
					</Form.Group>

					<Form.Group inline style={{ marginTop: "16px" }}>
						<Form.Field style={{ width: "22%" }}>
							<FormLabel name={"ค่า DRG"} value={detail.drg_value ? formatComma(detail.drg_value) : "-"} />
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel
								name={"ยอดเบิกตาม DRG"}
								value={detail.total_drg_price ? formatComma(detail.total_drg_price) : "-"}
							/>
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel
								name={"ยอดเบิกนอก DRG"}
								value={detail.total_non_drg_price ? formatComma(detail.total_non_drg_price) : "-"}
							/>
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel
								name={"ยอดเบิกทั้งหมด"}
								value={detail.total_send_claim_price ? formatComma(detail.total_send_claim_price) : "-"}
								isRequired={false}
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			</Accordion.Content>

			{/* Sent Information */}
			<AccordionTitle name={"ข้อมูลการเบิก"} isActive={toggleSentInfo} onClick={handleSentInfo} />
			<Accordion.Content style={{ marginLeft: "16px" }} active={toggleSentInfo}>
				<label style={{ textDecoration: "underline" }}>รายละเอียดยอดเบิก</label>
				<Form style={{ marginTop: "16px" }}>
					<Form.Group inline>
						<Form.Field style={{ width: "22%" }}>
							<FormLabel
								name={"ค่าห้องและค่าอาหาร"}
								value={detail.total_send_claim_01_price ? formatComma(detail.total_send_claim_01_price) : "-"}
							/>
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel
								name={"ค่าธรรมเนียมแพทย์"}
								value={detail.total_send_claim_df_price ? formatComma(detail.total_send_claim_df_price) : "-"}
							/>
						</Form.Field>
						<Form.Field style={{ width: "4%" }} />
						<Form.Field style={{ width: "22%" }}>
							<FormLabel
								name={"ค่ารักษาอื่น"}
								value={detail.total_send_claim_other_price ? formatComma(detail.total_send_claim_other_price) : "-"}
								isRequired={false}
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			</Accordion.Content>

			<Form.Group inline style={{ display: "flex", justifyContent: "flex-end" }}>
				<Button color={"green"} style={{ marginRight: "16px" }} type={"button"} onClick={handleSentClaimSave}>
					บันทึกข้อมูลการส่งเบิก
				</Button>
				<Button color={"blue"} type={"button"} onClick={showConfirmModal}>
					ยืนยันข้อมูลการส่งเบิก
				</Button>
			</Form.Group>
		</CardLayout>
	);
};

const STYLE = {
	LABEL_INFO: {
		width: "100%",
		textAlign: "center",
	},
};

const EDIT_TYPE = {
	SENT_CLAIM_TYPE: "submit_type",
	ADMIT_TYPE: "admit_type",
	ADMIT_SOURCE: "admit_source",
	PRE_DISCHARGE_TYPE: "predischarge_condition",
	DISCHARGE_TYPE: "discharge_status",
	DIVISION: "department",
};

const EDIT_TYPE_STRING = {
	[EDIT_TYPE.SENT_CLAIM_TYPE]: "ประเภทการส่งเบิก",
	[EDIT_TYPE.ADMIT_TYPE]: "ประเภทการ Admit",
	[EDIT_TYPE.ADMIT_SOURCE]: "รับ Admit จาก",
	[EDIT_TYPE.PRE_DISCHARGE_TYPE]: "สภาพก่อนจำหน่าย",
	[EDIT_TYPE.DISCHARGE_TYPE]: "สถานะการจำหน่าย",
	[EDIT_TYPE.DIVISION]: "แผนกที่รักษา",
};

CardSentClaimInformationDetail.defaultProps = {
	cardTitle: "รายละเอียดข้อมูลส่งเบิก",
	controller: null,
	transactionId: null,
	onCompleted: () => {},
	onClose: () => {},
};

CardSentClaimInformationDetail.propTypes = {
	cardTitle: PropTypes.string,
	controller: PropTypes.object,
	transactionId: PropTypes.number,
	onCompleted: PropTypes.func,
	onClose: PropTypes.func,
};

export default React.memo(CardSentClaimInformationDetail);
