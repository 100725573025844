import React, { CSSProperties, useState, useMemo, useEffect } from "react";
import {
  Accordion,
  AccordionProps,
  Button,
  Dropdown,
  Icon,
  Input,
  Modal,
} from "semantic-ui-react";

import moment from "moment";

// UX
import CardSelectPackageUX from "./CardSelectPackageUX";
import CardPurchaseHistory from "./CardPurchaseHistory";
import CardAppointmentPackage from "./CardAppointmentPackage";

// Common
import ModSearchDoctorNote from "react-lib/apps/APP/ModSearchDoctorNote";
import ModConfirmAuthen from "react-lib/apps/common/ModConfirmAuthen";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// Interface
import {
  AppointmentPackageSerializer,
  MasterOptionsType,
  PackageItemDetailType,
  PackageItemSerializer,
  RunSequence,
  State,
} from "./sequence/PackageDateTime";

// Utils
import { parseDate } from "react-lib/utils/dateUtils";
import { ModConfirm } from "react-lib/apps/common";

type CardSelectPackageProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // data
  selectedPatient?: Record<string, any> | null;
  // controller
  proxyController?: any;
  // seq
  runSequence: RunSequence;
  PackagePurchaseSequence?: any;
  PackageDateTimeSequence?: State["PackageDateTimeSequence"];
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  selectedDivision?: Record<string, any> | null;
  userTokenize?: any;
  masterOptions?: MasterOptionsType;
  selectedAppointment?: Record<string, any> | null;
};

// Const
const BUTTON_ACTIONS = {
  cancel: "CANCEL",
};

const GridCenter = {
  display: "grid",
  placeContent: "center",
  height: "fit-content",
} as CSSProperties;

const CARD_SELECT_PACKAGE = "CardSelectPackage";

const CardSelectPackage = (props: CardSelectPackageProps) => {
  // data
  const [activeIndex, setActiveIndex] = useState<number[]>([]);
  // Mod
  const [openModSelectPackage, setOpenModSelectPackage] =
    useState<boolean>(false);
  const [modEdit, setModEdit] = useState<{
    index: number;
    data: PackageItemSerializer;
  } | null>(null);
  const [openModDoctorNote, setOpenModDoctorNote] = useState<boolean>(false);
  // ModConfirm
  const [questionDetail, setQuestionDetail] = useState<any>(null);
  const [openModMessage, setOpenModMessage] = useState<boolean>(false);
  const [openModCancel, setOpenModCancel] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const [currentValue, setCurrentValue] = useState([]);

  const [reason, setReason] = useState<any>("");

  // Use Effect
  useEffect(() => {
    if (!props.PackageDateTimeSequence?.sequenceIndex) {
      props.runSequence({
        sequence: "PackageDateTime",
        restart: true,
        contentId: props.selectedAppointment?.content_id,
      });
    }
  }, [props.selectedAppointment]);

  // Use Memo
  const slotDate = useMemo(() => {
    const detail = props.PackageDateTimeSequence?.appointmentPackageDetail;
    const date = detail?.appointment_thai_date;

    return !!date ? `วัน ${date}` : "";
  }, [props.PackageDateTimeSequence?.appointmentPackageDetail]);

  const slotTime = useMemo(() => {
    const detail = props.PackageDateTimeSequence?.appointmentPackageDetail;
    const startTime = detail?.start_time;
    const endTime = detail?.end_time;

    return !!startTime ? `${startTime}-${endTime}` : "";
  }, [props.PackageDateTimeSequence?.appointmentPackageDetail]);

  const selectedPackageItems = useMemo(() => {
    return props.PackageDateTimeSequence?.selectedPackageItems || [];
  }, [props.PackageDateTimeSequence?.selectedPackageItems]);

  const packageOrderIds = useMemo(() => {
    return (props.PackageDateTimeSequence?.selectedPackageItems || []).map(
      (item) => item.id
    );
  }, [props.PackageDateTimeSequence?.selectedPackageItems]);

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    const name = data.name as keyof AppointmentPackageSerializer;
    const detail = {
      ...(props.PackageDateTimeSequence?.appointmentPackageDetail || {}),
    };

    detail[name] = data.value;

    props.setProp(`PackageDateTimeSequence.appointmentPackageDetail`, {
      ...detail,
    });
  };

  const handleClickAddPackage = () => {
    setOpenModSelectPackage(true);
  };

  const handleCloseModSelectPackage = () => {
    setOpenModSelectPackage(false);
  };

  const handleClick = (e: any, data: any) => {
    const index = Number(data.index);
    const newIndex = activeIndex.includes(data.index)
      ? activeIndex.filter((idx) => index !== idx)
      : [...activeIndex, index];

    setActiveIndex(newIndex);
  };

  const handleCloseModEdit = () => {
    setModEdit(null);
  };

  const handleDelete = (index: number) => () => {
    let items = props.PackageDateTimeSequence?.selectedPackageItems || [];

    items = items.filter((item, idx) => idx !== index);

    setActiveIndex(activeIndex.filter((_, idx) => idx !== index));

    props.setProp("PackageDateTimeSequence.selectedPackageItems", [...items]);
  };

  const handleAddNote = () => {
    setOpenModDoctorNote(true);
  };

  const handleOnSelectNote = (noteList: string[]) => {
    const orderNote = props.selectedAppointment?.remark || "";

    const note: (string | number)[] = orderNote
      ? [orderNote, ...noteList]
      : noteList;

    props.setProp("selectedAppointment.remark", note.join("\n"));
  };

  const handlePostPoneAppointment = () => {
    setOpenModMessage(true);
    setOptions(props.masterOptions?.postponeAppointment || []);
    setQuestionDetail({
      type: "postpone",
      title: "ต้องการเลื่อนนัดผู้ป่วยใช่หรือไม่",
      subTitle:
        "กรณีที่เลื่อนนัดผู้ป่วยเจ้าหน้าที่จะต้องทำการติดนามนัดหมายผู้ป่วยใหม่",
      questionDetail: "ระบุเหตุผลเลื่อนนัดผู้ป่วย",
      isRequiredQuestionDetail: true,
      titleColor: "orange",
      userEditQuestion: "ระบุชื่อผู้บันทึกข้อมูล",
      isUserEditQuesion: true,
      buttonLeftLabel: "ใช่",
      buttonRightLabel: "ไม่",
      buttonLeftColor: "green",
      buttonRightColor: "red",
    });
  };

  const handleConfirmedButton = () => {
    if (questionDetail?.type === "postpone") {
      props.onEvent({
        message: "SetScheduling",
        params: {
          action: "PostponeAppointment",
          reason: currentValue,
        },
      });
    }

    setCurrentValue([]);
    setOpenModMessage(false);
    setQuestionDetail(null);
  };

  const handleCancelButton = () => {
    setOpenModMessage(false);
    setOptions([]);
    setCurrentValue([]);
    setQuestionDetail(null);
    props.setProp("userTokenize", null);
  };

  const handleChange = (e: any, { value }: any) => {
    setCurrentValue(value);
  };

  const handleAddition = (e: any, { value }: any) => {
    setOptions([{ text: value, value }, ...options]);
  };

  const handleConfirmCancel = () => {
    props.runSequence({
      sequence: "PackageDateTime",
      action: "CANCEL",
      statusNote: reason,
      card: CARD_SELECT_PACKAGE,
      onSuccess: handleCloseModCancel,
    });
  };

  const handleCloseModCancel = () => {
    setOpenModCancel(false);
    setReason("");
  };

  const handleCancelAppointment = () => {
    setOpenModCancel(true);
  };

  const handleAddPackage = (data: any) => {
    props.setProp("PackageDateTimeSequence.selectedPackageItems", [
      ...selectedPackageItems,
      data,
    ]);

    handleCloseModSelectPackage();
  };

  const handleEditPackage = (data: any[]) => {
    if (!!modEdit) {
      const items = props.PackageDateTimeSequence?.selectedPackageItems || [];

      items[modEdit?.index].items = data;

      props.setProp("PackageDateTimeSequence.selectedPackageItems", [...items]);

      handleCloseModEdit();
    }
  };

  console.log("CardSelectPackageUX", props);

  return (
    <div style={{ padding: "10px" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_SELECT_PACKAGE}`, null);
        }}
        error={props.errorMessage?.[CARD_SELECT_PACKAGE]}
        success={null}
      />

      <CardSelectPackageUX
        // data
        isNotFound={!selectedPackageItems.length}
        patientInfo={props.selectedPatient}
        PackageDateTimeSequence={props.PackageDateTimeSequence}
        slotDate={slotDate}
        slotTime={slotTime}
        // config
        isSelected={!!slotTime}
        // callback
        onAddPackage={handleClickAddPackage}
        onAddNote={handleAddNote}
        onChangeValue={handleChangeValue}
        onPostponeAppointment={handlePostPoneAppointment}
        onCancelAppointment={handleCancelAppointment}
        // Element
        Accordion={
          <Accordion>
            {selectedPackageItems.map((item, index) => (
              <>
                <Accordion.Title
                  active={activeIndex.includes(index)}
                  index={index}
                  style={{
                    color: "#0072BC",
                    // fontSize: "1.2rem",
                    ...(selectedPackageItems.length - 1 === index
                      ? {}
                      : {
                          borderBottom: activeIndex.includes(index)
                            ? ""
                            : "1px solid #2185D0",
                          paddingBottom: "1rem",
                        }),
                    paddingTop: "1rem",
                  }}
                  onClick={handleClick}
                >
                  <Icon name="dropdown" />
                  {[item.product_code]} {item.product_name}
                </Accordion.Title>
                <Accordion.Content
                  active={activeIndex.includes(index)}
                  style={
                    activeIndex.includes(index) &&
                    selectedPackageItems.length - 1 !== index
                      ? {
                          borderBottom: "1px solid #2185D0",
                          marginTop: "-0.5rem",
                        }
                      : {}
                  }
                >
                  <CardAccordionContent
                    data={item.items}
                    // config
                    readOnly={!!slotTime}
                    // callback
                    onEdit={() => setModEdit({ index, data: item })}
                    onDelete={handleDelete(index)}
                  />
                </Accordion.Content>
              </>
            ))}
          </Accordion>
        }
      />

      <Modal
        open={openModSelectPackage}
        closeOnDimmerClick={true}
        style={{ maxHeight: "94vh", overflowY: "auto", paddingBottom: "3rem" }}
        onClose={handleCloseModSelectPackage}
      >
        <Icon
          name="close"
          color="red"
          size="large"
          style={{ position: "absolute", right: 5, top: 8 }}
          onClick={handleCloseModSelectPackage}
        />
        <CardPurchaseHistory
          setProp={props.setProp}
          onEvent={props.onEvent}
          // seq
          runSequence={props.runSequence as any}
          PackagePurchaseSequence={props.PackagePurchaseSequence}
          // CommonInterface
          searchedItemListWithKey={props.searchedItemListWithKey}
          buttonLoadCheck={props.buttonLoadCheck}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          // config
          selectable={true}
          selectedDivision={props.selectedDivision}
          disabledOrderIds={packageOrderIds}
          // callback
          onSelectPackage={handleAddPackage}
        />
      </Modal>

      <Modal
        open={!!modEdit}
        closeOnDimmerClick={true}
        // style={{ maxHeight: "94vh", overflowY: "auto", paddingBottom: "3rem" }}
        onClose={handleCloseModEdit}
      >
        <CardAppointmentPackage
          data={modEdit?.data?.items || []}
          headerName={`[${modEdit?.data?.product_name}] ${modEdit?.data?.product_name}`}
          // config
          selectable={true}
          isEdit={true}
          hideHistory={true}
          // callback
          onClose={handleCloseModEdit}
          onSelect={handleEditPackage}
        />
      </Modal>

      <Modal
        open={openModDoctorNote}
        onClose={() => setOpenModDoctorNote(false)}
      >
        <ModSearchDoctorNote
          controller={props.proxyController}
          division={props.selectedDivision?.id}
          onClose={() => setOpenModDoctorNote(false)}
          onSelectNote={handleOnSelectNote}
        />
      </Modal>

      <ModConfirmAuthen
        {...questionDetail}
        onEvent={props.onEvent}
        setProp={props.setProp}
        // options
        options={options}
        userTokenize={props.userTokenize}
        onButtonLeftClick={handleConfirmedButton}
        onButtonRightClick={handleCancelButton}
        open={openModMessage}
        onClose={handleCancelButton}
        isButtonBasic={true}
        handleAddition={handleAddition}
        handleChange={handleChange}
        currentValue={currentValue}
        isErrMsg={true}
        multiple={questionDetail?.multiple}
        style={{ width: "40%" }}
      />

      <ModConfirm
        openModal={openModCancel}
        titleName="ยกเลิกนัดหมาย"
        titleColor="red"
        size="mini"
        denyButtonColor="red"
        denyButtonText="ยกเลิก"
        // approveButtonColor="green"
        // approveButtonText="ตกลง"
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirmCancel}
            // data
            paramKey={`${CARD_SELECT_PACKAGE}_${BUTTON_ACTIONS.cancel}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_SELECT_PACKAGE}_${BUTTON_ACTIONS.cancel}`
              ]
            }
            // config
            color={"green"}
            name={BUTTON_ACTIONS.cancel}
            size="medium"
            title="ยืนยัน"
            basic={true}
            disabled={!reason}
          />
        }
        content={
          <div style={{ margin: "-2rem 0px -1rem" }}>
            <div style={{ textAlign: "left" }}>
              <div style={{ margin: "20px 0px 10px 0px" }}>
                กรุณาระบุเหตุผลในการยกเลิก
              </div>

              <Input
                value={reason}
                fluid={true}
                onChange={(e, data) => setReason(data.value)}
              />
            </div>
          </div>
        }
        onDeny={handleCloseModCancel}
        onCloseWithDimmerClick={handleCloseModCancel}
      />
    </div>
  );
};

/* ------------------------------------------------------ */

/*                  CardAccordionContent;                 */

/* ------------------------------------------------------ */
type CardAccordionContentProps = {
  data: PackageItemDetailType[];
  // config
  readOnly?: boolean;
  // callback
  onEdit: (e: any) => any;
  onDelete: (e: any) => any;
};

const CardAccordionContent = (props: CardAccordionContentProps) => {
  const items = useMemo(() => {
    return props.data.filter((item) => item.active);
  }, [props.data]);

  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr auto auto" }}>
      <div>
        {[
          {
            product_code: "รหัส",
            product_name: "รายการ",
            p_type_code: "ประเภท",
            quantity_appointment: "จำนวนนัดหมาย",
            balance: "จำนวนคงเหลือ",
          },
          ...items,
        ].map((item, index) => (
          <div
            style={{
              display: "grid",
              fontWeight: "normal",
              padding: "6px 25px 7px 10px",
              gridTemplateColumns: "repeat(5,20%)",
              ...(index === 0
                ? {
                    backgroundColor: "#D0EEF2",
                    border: "1px solid #979797",
                  }
                : {}),
            }}
          >
            <div style={{ paddingLeft: index === 0 ? "1rem" : "" }}>
              {item.product_code}
            </div>
            <div style={index === 0 ? GridCenter : {}}>
              {" "}
              {item.product_name}
            </div>
            <div style={index === 0 ? GridCenter : { paddingLeft: "5rem" }}>
              {" "}
              {item.p_type_code}
            </div>
            <div style={GridCenter}> {item.quantity_appointment}</div>
            <div style={GridCenter}> {item.balance}</div>
          </div>
        ))}
      </div>
      <div>
        {!props.readOnly && (
          <Button
            color="yellow"
            icon="edit"
            size="mini"
            style={{ margin: "0.25rem", marginLeft: "0.5rem" }}
            onClick={props.onEdit}
          />
        )}
      </div>
      <div>
        {!props.readOnly && (
          <Button
            color="red"
            icon="trash alternate"
            size="mini"
            style={{ margin: "0.25rem", marginRight: 0 }}
            onClick={props.onDelete}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(CardSelectPackage);
