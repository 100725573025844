import React, { useState, useMemo, KeyboardEvent, useEffect } from "react";
import { Modal } from "semantic-ui-react";

import moment from "moment";

// UX
import CardReserveBedQueueUX from "react-lib/apps/HISV3/ADM/CardReserveBedQueueUX";
import CardPatientSearchBox from "react-lib/apps/HISV3/TPD/CardPatientSearchBox";
import CardDivisionSearch from "react-lib/apps/HISV3/ADM/CardDivisionSearch";
import { MenuItem } from "react-lib/apps/HISV3/ADM/CardADM";
import CardBed from "react-lib/apps/HISV3/ADM/CardBed";
import CardAdmitOrder from "react-lib/apps/ADM/CardAdmitOrder";
import CardPrintList from "react-lib/apps/common/CardPrintList";
import SCN from "react-lib/apps/HISV3/ADM/SCN";

// Common
import { DateTextBox } from "react-lib/apps/common";
import PatientData from "react-lib/apps/common/PatientData";
import { beToAd } from "react-lib/utils/dateUtils";

const CardReserveBedQueue = (props: any) => {
  const [mode, setMode] = useState("");

  useEffect(() => {
    const updateData = async () => {
      const id = props.SetBedSequence?.selectedReserveBedQueue?.id;
      if (id) {
        const findRow = props.SetBedSequence?.reserveBedQueueList?.find(
          (item: any) => id === item.id
        );

        await props.setProp(
          `SetBedSequence.selectedReserveBedQueue`,
          findRow || null
        );
        setMode("");
        props.setProp(`SetBedSequence.selectedAdmitOrder`, findRow || null);
      }
    };

    updateData();
  }, [props.SetBedSequence?.reserveBedQueueList]);

  useEffect(() => {
    if (props.hideBtnCardBed) {
      props.runSequence({
        sequence: "SetBed",
        restart: true,
        defaultCancel: props.defaultCancel,
      });
    }

    return () => {
      if (props.hideBtnCardBed) {
        props.runSequence({
          sequence: "SetBed",
          clear: true,
          defaultCancel: props.defaultCancel,
        });
      }
    };
  }, []);

  const reserveBedQueueList = useMemo(() => {
    return (props.SetBedSequence?.reserveBedQueueList || []).map(
      (item: any) => ({
        ...item,
        room_info: <div dangerouslySetInnerHTML={{ __html: item.room_info }} />,
        status_label: (
          <label>
            {item.room_no
              ? `${item.status_label} ${item.room_no}`
              : item.status_label}
          </label>
        ),
        diagnosis: `${item.icd10_detail.code}  ${item.icd10_detail.term} `,
      })
    );
  }, [props.SetBedSequence?.reserveBedQueueList]);

  const patientData = useMemo(() => {
    const data = props.SetBedSequence?.selectedReserveBedQueue || {};
    let patientData = new PatientData(() => {});
    patientData.setPatientId(data.patient);
    patientData.setEncounterId(data.encounter);
    patientData.setEmrId(data.emr);
    patientData.setProgressionCycleId(data.progression_cycle);

    return patientData;
  }, [mode, props.SetBedSequence?.selectedReserveBedQueue]);

  const patientInfo = useMemo(() => {
    const data = props.SetBedSequence?.selectedReserveBedQueue || {};
    const birthdate = data.patient_birthdate;
    const a = moment();
    const b = moment(beToAd(birthdate));
    const diff = a.diff(b, "years");

    return {
      hn: data.hn,
      full_name_th: data.patient_name,
      full_name: data.patient_name,
      gender: data.patient_gender,
      birthdate,
      age: diff,
      encounter_no: data.encounter,
      encounter_id: data.encounter,
      id: data.patient,
    };
  }, [mode, props.SetBedSequence?.selectedReserveBedQueue]);

  const handleGetOrderDivisionList = (params: any) => {
    return props.onEvent({
      message: "GetListOrderDivision",
      params: { ...params, limit: 40 },
    });
  };

  const handleChangeValue = (e: any, v: any) => {
    const value = typeof v.checked === "boolean" ? v.checked : v.value;
    let sbs = {
      ...props.SetBedSequence,
      filterReserveBedQueue: {
        ...props.SetBedSequence?.filterReserveBedQueue,
        [v.name]: value,
      },
      reserveBedQueueList: [],
    };

    props.setProp(`SetBedSequence`, sbs);
  };

  const handleChangeOrderDiv = (data: any) => {
    handleChangeValue(null, {
      name: "selectedOrderDivision",
      value: data,
    });
  };

  const handleChangePatient = (id: any, hn: string, full_name: string) => {
    handleChangeValue(null, {
      name: "selectedPatient",
      value: id ? { id, hn, full_name } : {},
    });
  };

  const handleChangeDate = (key: string) => (date: string) => {
    handleChangeValue(null, {
      name: key,
      value: date,
    });
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "SetBed",
      action: "search_queue",
    });
  };

  const headleClear = () => {
    props.runSequence({
      sequence: "SetBed",
      action: "clear_queus",
    });
  };

  const handleChangeCodeIcontains = (e: any, v: any) => {
    props.setProp(`SetBedSequence.codeIcontains`, v.value);
  };

  const handleKeyDownCodeIcontains = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      props.runSequence({
        sequence: "SetBed",
        action: "search_queue",
        isBarcode: true,
      });
    }
  };

  const handlePrintReport = () => {
    // ถอนการ ใช้ jasper ไปใช้ PDFMake
    // props.onEvent({ message: "PrintReportAdmitOrder", params: {} });

    if (props.SetBedSequence?.reserveBedQueueList?.length === 0) {
      props.runSequence({
        sequence: "SetBed",
        action: "search_queue",
        withPrint: "printReserveBed",
      });
    } else {
      props.runSequence({
        sequence: "SetBed",
        action: "printReserveBed",
      });
    }
  };

  const handlePrintPostpone = () => {
    props.onEvent({ message: "PrintReportPostponeAdmitOrder", params: {} });
  };

  const handleSelectRow = async (row: any) => {
    if (props.SetBedSequence?.selectedReserveBedQueue?.id === row?.id) {
      await props.setProp(`SetBedSequence.selectedReserveBedQueue`, null);
      props.setProp(`SetBedSequence.selectedAdmitOrder`, null);
      setMode("");
    } else {
      await props.setProp(`SetBedSequence.selectedReserveBedQueue`, row);

      await props.setProp(`SetBedSequence.inputHN`, row.hn);

      props.setProp(`SetBedSequence.selectedAdmitOrder`, row);
    }
  };

  const handleGetTrProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => ({
    style: {
      backgroundColor:
        props.SetBedSequence?.selectedReserveBedQueue?.id &&
        rowInfo?.original?.id ===
          props.SetBedSequence?.selectedReserveBedQueue?.id
          ? "#cccccc"
          : "white",
    },
    onClick: (e: any) =>
      handleSelectRow(
        props.SetBedSequence?.reserveBedQueueList?.[rowInfo?.index]
      ),
  });

  const handleCloseModal = () => {
    setMode("");
  };

  const handleReserve = (mode: string) => {
    setMode(mode);
    props.runSequence({
      sequence: "SetBed",
      action: "search_reserve",
    });
  };

  const handlePrintDailyReport = () => {
    if (props.SetBedSequence?.reserveBedQueueList?.length === 0) {
      props.runSequence({
        sequence: "SetBed",
        action: "search_queue",
        withPrint: "printReserveBedDailyReport",
      });
    } else {
      props.runSequence({
        sequence: "SetBed",
        action: "printReserveBedDailyReport",
      });
    }
  };

  return (
    <div style={{ padding: "10px" }}>
      <CardReserveBedQueueUX
        // report
        showPrintReserveBedReportBtn={props.showPrintReserveBedReportBtn}
        onPrintDailyReport={handlePrintDailyReport}
        // data
        codeIcontains={props.SetBedSequence?.codeIcontains}
        isHN={props.filterReserveBedQueue.isHN}
        patientFullName={props.filterReserveBedQueue.selectedPatient?.full_name}
        isAdmitDate={props.filterReserveBedQueue.isAdmitDate}
        isOrderDiv={props.filterReserveBedQueue.isOrderDiv}
        orderDivName={
          props.filterReserveBedQueue.selectedOrderDivision?.name || ""
        }
        isSaveDate={props.filterReserveBedQueue.isSaveDate}
        isReserveStatus={props.filterReserveBedQueue.isReserveStatus}
        reserveStatus={props.filterReserveBedQueue.reserveStatus}
        isWardType={props.filterReserveBedQueue.isWardType}
        wardType={props.filterReserveBedQueue.wardType}
        wardTypeOptions={props.masterOptions.wardType}
        admitOrderOptions={props.masterOptions.admitOrderStatus}
        reserveBedQueueList={reserveBedQueueList}
        // callback
        getTrProps={handleGetTrProps}
        onChangeValue={handleChangeValue}
        onChangeCodeIcontains={handleChangeCodeIcontains}
        onKeyDownCodeIcontains={handleKeyDownCodeIcontains}
        onSearch={handleSearch}
        onClear={headleClear}
        onPrintReport={handlePrintReport}
        onPrintPostpone={handlePrintPostpone}
        // Element
        PatientSearchBox={
          <CardPatientSearchBox
            controller={props.drugOrderQueueController}
            modalStyle={{ width: "auto" }}
            onEnterPatientSearch={handleChangePatient}
            clearHNInput={
              props.SetBedSequence?.filterReserveBedQueue?.selectedPatient
                ?.hn === undefined
                ? true
                : false
            }
          />
        }
        CardDivisionSearch={
          <CardDivisionSearch
            divisionTypeOptions={props.masterOptions.divisionType}
            getOrderDivisionList={handleGetOrderDivisionList}
            fluid={true}
            // callback
            onSelect={handleChangeOrderDiv}
            onEnter={handleChangeOrderDiv}
            clearDivisionImput={
              props.SetBedSequence?.filterReserveBedQueue?.selectedOrderDivision
                ?.code === undefined
                ? true
                : false
            }
          />
        }
        AdmitStartDate={
          <DateTextBox
            value={props.filterReserveBedQueue.admitFromDate}
            onChange={handleChangeDate("admitFromDate")}
          />
        }
        AdmitEndDate={
          <DateTextBox
            value={props.filterReserveBedQueue.admitToDate}
            onChange={handleChangeDate("admitToDate")}
          />
        }
        SaveStartDate={
          <DateTextBox
            value={props.filterReserveBedQueue.saveFromDate}
            onChange={handleChangeDate("saveFromDate")}
          />
        }
        SaveEndDate={
          <DateTextBox
            value={props.filterReserveBedQueue.saveToDate}
            onChange={handleChangeDate("saveToDate")}
          />
        }
      />
      {!props.hideBtnCardBed && (
        <div style={{ display: "flex", margin: "1rem 0" }}>
          <MenuItem
            menu="description"
            mode={mode}
            name="รายละเอียด"
            setMode={setMode}
            active={!!props.SetBedSequence?.selectedReserveBedQueue?.id}
            color="yellow"
          />
          <MenuItem
            menu="print"
            mode={mode}
            name="พิมพ์เอกสาร"
            setMode={setMode}
            active={!!props.SetBedSequence?.selectedReserveBedQueue?.id}
            color="blue"
          />
          <MenuItem
            menu="see_scan"
            mode={mode}
            name="ดูรูปสแกน"
            setMode={setMode}
            active={!!props.SetBedSequence?.selectedReserveBedQueue?.id}
            color="green"
          />
          <MenuItem
            menu="document_scan"
            mode={mode}
            name="สแกนเอกสาร"
            setMode={setMode}
            active={!!props.SetBedSequence?.selectedReserveBedQueue?.id}
            color="green"
          />
          <MenuItem
            menu="reserve"
            mode={mode}
            name="จอง"
            setMode={handleReserve}
            active={
              !!props.SetBedSequence?.selectedReserveBedQueue?.id &&
              ["REQUESTED", "PROCESSED"].includes(
                props.SetBedSequence?.selectedReserveBedQueue?.status_name || ""
              )
            }
            color="violet"
          />
        </div>
      )}

      <Modal open={mode === "print"} size="large">
        <CardPrintList
          closeable={true}
          toggleable={true}
          onClose={handleCloseModal}
          patientInfo={patientInfo}
          encounterNumber={patientInfo.encounter_no}
          controller={props.printListController}
          patientData={patientData}
          patientId={patientData.patient_id}
          encounterId={patientData.ENCOUNTER.encounter_id}
        />
      </Modal>

      <Modal
        open={mode === "see_scan"}
        style={{ backgroundColor: "white", height: "100%", width: "80%" }}
      >
        <SCN
          encounterId={patientInfo.encounter_no}
          patientId={patientInfo?.id}
          hn={patientInfo?.hn}
          tab="1"
          disabledRequiredField={true}
          closeIcon={true}
          onClose={handleCloseModal}
        />
      </Modal>

      <Modal
        open={mode === "document_scan"}
        style={{ backgroundColor: "white", height: "100%", width: "80%" }}
      >
        <SCN
          encounterId={patientInfo.encounter_no}
          patientId={patientInfo?.id}
          hn={patientInfo?.hn}
          disabledRequiredField={true}
          closeIcon={true}
          tab="0"
          onClose={handleCloseModal}
        />
      </Modal>

      <Modal open={mode === "description"} style={{ width: "85%" }}>
        <CardAdmitOrder
          controller={props.crdAdmitOrderController}
          orderId={props.SetBedSequence?.selectedReserveBedQueue?.id || null}
          subICDController={props.subICD10DetailController}
          DJANGO={props.django}
          PATIENT_DATA={patientData}
          showHeader={false}
          allowClear={true}
          hideSaveButton={false}
          hideCancelButton={false}
          closeable={true}
          statusLabelStyle={{ marginRight: "3rem" }}
          toggleable={false}
          labelFitContent={true}
          hideCallback={() => {
            handleSearch();
            handleCloseModal();
          }}
        />
      </Modal>

      {!props.hideBtnCardBed && (
        <CardBed
          // callback
          setProp={props.setProp}
          onEvent={props.onEvent}
          runSequence={props.runSequence}
          masterOptions={props.masterOptions}
          // data
          SetBedSequence={props.SetBedSequence}
          patientInfo={patientInfo}
          hideAdmitOrder={true}
          successMessage={props.successMessage}
          errorMessage={props.errorMessage}
          reserveCode={
            props.SetBedSequence?.selectedReserveBedQueue?.code || ""
          }
          // controller
          drugOrderQueueController={props.drugOrderQueueController}
          // ปุ่มพิมพ์ใน รายงานเตียง
          showPrintBtn={true}
          hiddenAction={props.hiddenCardBedAction}
        />
      )}
    </div>
  );
};

export default React.memo(CardReserveBedQueue);
