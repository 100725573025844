import WasmController from 'react-lib/frameworks/WasmController';
import DivisionHasUserList from "issara-sdk/apis/DivisionHasUserList_apps_PRX";
import DoctorWorkScheduleIsHealthAPIView from "issara-sdk/apis/DoctorWorkScheduleIsHealthAPIView_apps_PRX";
import AvailableDivisionServiceBlockIsHealthAPIView from "issara-sdk/apis/AvailableDivisionServiceBlockIsHealthAPIView_apps_PRX";
import SelectDivisionServiceBlock from "issara-sdk/apis/SelectDivisionServiceBlock_apps_PRX";
import RequestChangeDivisionServiceBlock from "issara-sdk/apis/RequestChangeDivisionServiceBlock_apps_PRX";
import ClassifyList from "issara-sdk/apis/ClassifyList_apps_PRX";
import DashboardView from "issara-sdk/apis/DashboardView_apps_PRX";
import OfficialAccountDashboardView from "issara-sdk/apis/OfficialAccountDashboardView_apps_PRX";
import OfficialAccountInvitationLink from "issara-sdk/apis/OfficialAccountInvitationLink_apps_PRX";
import ScheduleList from "issara-sdk/apis/ScheduleList_apps_PRX";
import DiagRuleList from "issara-sdk/apis/DiagRuleList_apps_PRX";
import CreateUpdateScheduleView from "issara-sdk/apis/CreateUpdateScheduleView_apps_PRX";
import DiagRuleDetail from "issara-sdk/apis/DiagRuleDetail_apps_PRX";
import EncounterPatientAppointmentList from "issara-sdk/apis/EncounterPatientAppointmentList_apps_PRX";
import MyProxyPatientView from "issara-sdk/apis/MyProxyPatientView_apps_PRX";
import EncounterTriageList from "issara-sdk/apis/EncounterTriageList_apps_PRX";
import EncounterTriageDetail from "issara-sdk/apis/EncounterTriageDetail_apps_PRX";
import MessageListView from "issara-sdk/apis/MessageListView_apps_PRX"
import ChatChannelNoSubscriptionDetail from "issara-sdk/apis/ChatChannelNoSubscriptionDetail_apps_PRX";
import ProxyPatientDetail from "issara-sdk/apis/ProxyPatientDetail_apps_PRX";
import ClassifyUserList from "issara-sdk/apis/ClassifyUserList_apps_PRX";
import DivisionProfileList from "issara-sdk/apis/DivisionProfileList_apps_PRX";
import ResolveChatChannelView from "issara-sdk/apis/ResolveChatChannelView_apps_PRX";
import RequestBotMessageView from "issara-sdk/apis/RequestBotMessageView_apps_PRX";
import DiagFormList from "issara-sdk/apis/DiagFormList_apps_PRX";
import PublishedDiagRuleClassifyChoiceList from "issara-sdk/apis/PublishedDiagRuleClassifyChoiceList_apps_PRX";
import MonitorDiagForm from "issara-sdk/apis/MonitorDiagForm_apps_PRX";
import PublishedDiagRuleDetail from "issara-sdk/apis/PublishedDiagRuleDetail_apps_PRX";
import ProgressionNoteIsHealthList from "issara-sdk/apis/ProgressionNoteIsHealthList_apps_PRX";
import EncounterReclassifyView from "issara-sdk/apis/EncounterReclassifyView_apps_PRX";
import OfficialAccountMyRequestList from "issara-sdk/apis/OfficialAccountMyRequestList_apps_PRX";
import CreateOfficialAccountRequest from "issara-sdk/apis/CreateOfficialAccountRequest_apps_PRX";
import OfficialAccountMyProfile from "issara-sdk/apis/OfficialAccountMyProfile_apps_PRX";
import OfficialAccountCreate from "issara-sdk/apis/OfficialAccountCreate_apps_PRX";
import DiagFormDetail from "issara-sdk/apis/DiagFormDetail_apps_PRX";
import PublishedDiagRuleList from "issara-sdk/apis/PublishedDiagRuleList_apps_PRX";
import OfficialAccountDiagRuleTriageList from "issara-sdk/apis/OfficialAccountDiagRuleTriageList_apps_PRX";
import OfficialAccountDiagRuleTriageLevelList from "issara-sdk/apis/OfficialAccountDiagRuleTriageLevelList_apps_PRXM";
import ProxyPatientAllergy from "issara-sdk/apis/ProxyPatientAllergy_apps_PRX";
import PatientFromDiagRuleDetail from "issara-sdk/apis/PatientFromDiagRuleDetail_apps_PRX"
import NurseNoteIsHealthFromEncounterDetail from "issara-sdk/apis/NurseNoteIsHealthFromEncounterDetail_apps_PRX"
import VaccineList from "issara-sdk/apis/VaccineList_apps_PRX"
import VaccineNoteList from "issara-sdk/apis/VaccineNoteList_apps_PRX"
//Port Bplus
import ProxyPatientHasDivisionNotFollowList from "issara-sdk/apis/ProxyPatientHasDivisionNotFollowList_apps_PRX"
import DiagFormByIdList from "issara-sdk/apis/DiagFormByIdList_apps_PRX"
import ProxyPatientHasDivisionChatDetail from 'issara-sdk/apis/ProxyPatientHasDivisionChatDetail_apps_PRX';
import V2EncounterTriageList from "issara-sdk/apis/V2EncounterTriageList_apps_PRX"

// select by config 
import config from "config/config";
import DirectDashboardAppointmentView from "issara-sdk/apis/DirectDashboardAppointmentView_apps_PRX";
import OfficialAccountDashboardAppointmentView from "issara-sdk/apis/OfficialAccountDashboardAppointmentView_apps_PRX";
import DashboardAppointmentView from "issara-sdk/apis/DashboardAppointmentView_apps_PRX";


// Need to customize header for multi-part
import MessagePostNoSubscriptionView from "issara-sdk/apis/MessagePostNoSubscriptionView_apps_PRXM";
import OfficialAccountMyProfileM from "issara-sdk/apis/OfficialAccountMyProfile_apps_PRXM";

// Legacy
import PRXService from "react-lib/apis/services/PRXService";
import { to, formatDateToYYYYMMDD } from "react-lib/utils";

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "newPRXService", params: any }
  |  { message: "getDiagRule", params: any }
  |  { message: "getDiagFormClassify", params: any }
  |  { message: "getDiagForm", params: any }
  |  { message: "getDiagFormDetail", params: any }
  |  { message: "getDivisionHasUser", params: any }
  |  { message: "getDivisionProfile", params: any }
  |  { message: "createDivisionHasUser", params: any }
  |  { message: "getDiagRuleDetail", params: any }
  |  { message: "postDiagRule", params: any }
  |  { message: "postTuhSetPatientHn", params: any }
  |  { message: "postListPatientFromDiagForm", params: any }
  |  { message: "postListPatientFromPatient", params: any }
  |  { message: "getProxyPatientHasDivisionNotFollow", params: any }
  |  { message: "getProxyPatientHasDivisionPatientChat", params: any }
  |  { message: "patchDiagRuleDetail", params: any }
  |  { message: "patchDiagRuleName", params: any }
  |  { message: "patchDiagRulePublish", params: any }
  |  { message: "patchDiagForm", params: any }
  |  { message: "getV2EncounterTriage", params: any }
  |  { message: "postDiagFormMonitor", params: any }
  |  { message: "patchDiagRuleActive", params: any }
  |  { message: "getPublishedDiagRule", params: any }
  |  { message: "postPublishedDiagRule", params: any }
  |  { message: "patchPublishedDiagRule", params: any }
  |  { message: "getTriageLevelClassify", params: any }
  |  { message: "getClassifyUser", params: any }
  |  { message: "getMasterTriageLevelClassify", params: any }
  |  { message: "getLatestChatChannel", params: any }
  |  { message: "getEncounterTriage", params: any }
  |  { message: "getEncounter", params: any }
  |  { message: "getChatChannelMessageList", params: any }
  |  { message: "getMessageFromURL", params: any }
  |  { message: "postChatChannelMessage", params: any }
  |  { message: "geNurseNoteList", params: any }
  |  { message: "geProgressNoteList", params: any }
  |  { message: "getChannelDetail", params: any }
  |  { message: "geNurseNoteEncounter", params: any }
  |  { message: "getProxyPatient", params: any }
  |  { message: "getProxyPatientAllergy", params: any }
  |  { message: "getProxyPatientList", params: any }
  |  { message: "patchProxyPatientHasDivision", params: any }
  |  { message: "getProxyPatientHasDivisionAdmin", params: any }
  |  { message: "patchProxyPatientHasDivisionAdmin", params: any }
  |  { message: "patchEncounterReclassify", params: any }
  |  { message: "getChatChannel", params: any }
  |  { message: "getProxyPatientHasDivisionIdAdmin", params: any }
  |  { message: "getTuhUsersList", params: any }
  |  { message: "getProxyPatientHasDivision", params: any }
  |  { message: "getPatient", params: any }
  |  { message: "patchPatient", params: any }
  |  { message: "postEncounterPatientAppointment", params: any }
  |  { message: "getDoctorWorkSchedule", params: any }
  |  { message: "getAvailableDivisionServiceBlock", params: any }
  |  { message: "postSelectDivisionServiceBlock", params: any }
  |  { message: "postRequestChangeDivisionServiceBlock", params: any }
  |  { message: "getEncounterTriageDetail", params: any }
  |  { message: "getPatientQueue", params: any }
  |  { message: "getAppointedPatientQueue", params: any }
  |  { message: "getShippingAddress", params: any }
  |  { message: "postShippingAddress", params: any }
  |  { message: "getInitPatientAddress", params: any }
  |  { message: "getDashboard", params: any }
  |  { message: "getOfficialAccountDashboard", params: any }
  |  { message: "getDashboardAppointment", params: any }
  |  { message: "getResolveChatChannel", params: any }
  |  { message: "postRequestOA", params: any }
  |  { message: "getListOA", params: any }
  |  { message: "postFollowOA", params: any }
  |  { message: "getOAInviteLink", params: any }
  |  { message: "postCreatejoinOARequest", params: any }
  |  { message: "getListJoinOARequest", params: any }
  |  { message: "postApproveJoinOARequest", params: any }
  |  { message: "postCreateUpdateSchedule", params: any }
  |  { message: "postPatientFromDiagRule", params: any }
  |  { message: "deleteJoinOARequest", params: any }
  |  { message: "deleteDivisionHasUserOA", params: any }
  |  { message: "getListDivisionHasUserOA", params: any }
  |  { message: "getListAvailabelTraigeLevel", params: any }
  |  { message: "getListUserRoleOA", params: any }
  |  { message: "getOADivisionProfile", params: any }
  |  { message: "getListOARequestCheck", params: any }
  |  { message: "patchDivisionHasUserOA", params: any }
  |  { message: "patchOADivisionProfile", params: any }
  |  { message: "getOAUserProfile", params: any }
  |  { message: "getListSchedule", params: any }
  |  { message: "getDivisionClassify", params: any }
  |  { message: "getChatChannelTriageLevel", params: any }
  |  { message: "getListChatChannelTriageLevelDiagRule", params: any }
  |  { message: "getListAvailabelTriageLevelDiagRule", params: any }
  |  { message: "getPatientFromDiagRule", params: any }
  |  { message: "putPatientFromDiagRule", params: any }
  |  { message: "patchOAUserProfile", params: any }
  |  { message: "getVaccineHospital", params: any }
  |  { message: "getAvailableVaccineDivision", params: any }
  |  { message: "postVaccineServiceBlock", params: any }
  |  { message: "postVaccineNote", params: any }
  |  { message: "putVaccineServiceBlock", params: any }
  |  { message: "deleteVaccineServiceBlock", params: any }
  |  { message: "getListVaccine", params: any }
  |  { message: "getListVaccineLot", params: any }
  |  { message: "getListVaccineNote", params: any }
  |  { message: "getVaccineNoteDetail", params: any }
  |  { message: "patchVaccineNote", params: any }
  |  { message: "postRequestBotMessage", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const newPRXService: Handler = async (controller, {}) => {
//function (apiToken) {
//            //
//            if (apiToken === "anonymous") {
//                return new PRXServiceLocal_1.default();
//            }
//            else {
//                return new PRXService_1.default();
//            }
//        }
//
//----> Name mismatch between manager and service
}

export const getDiagRule: Handler = async (controller, { apiToken, division, active }) => {
  let params: any = {};
  if (division) {
    params.division = division;
  }
  params.active = !!active;
  return await DiagRuleList.list({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getDiagFormClassify: Handler = async (controller, { apiToken, patient }) => {
  let params: any = {};
  if (patient) {
    params.patient = patient;
  }
  return await PublishedDiagRuleClassifyChoiceList.list({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getDiagForm: Handler = async (controller, { apiToken, patient, limit, offset, division, today }) => {
  let params: any = {};
  if (patient) {
    params.patient = patient;
  }
  if (limit) {
    params.limit = limit;
  }
  if (offset) {
    params.offset = offset;
  }
  if (division) {
    params.division = division;
  }
  if (today) {
    params.today = 'true'
  }
  return await DiagFormList.list({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getDiagFormDetail: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, diagFormId = _b.diagFormId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.getDiagFormDetail({
//                                    params: params,
//                                    apiToken: apiToken,
//                                    diagFormId: diagFormId
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            //
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken, diagFormId = _b.diagFormId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        var url = apis_1.PRX.DIAG_FORM_DETAIL({ diag_form_id: diagFormId });
//        return this.client
//            .get(url, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PRX/diag-form/' +
//((__t = (diag_form_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getDivisionHasUser: Handler = async (controller, {}) => {
  // ? no parameter at all?
  return await DivisionHasUserList.list({
    params: {},
    apiToken: controller.apiToken
  });
}

export const getDivisionProfile: Handler = async (controller, { apiToken, division, divisionCode }) => { 
  let params: any = {};
  if (division) {
    params.division = division;
  }
  if (divisionCode) {
    params.code = divisionCode;
  }
  return await DivisionProfileList.list({
    params: params,
    apiToken: controller.apiToken
  });
}

export const createDivisionHasUser: Handler = async (controller, { apiToken, data }) => {
  let params: any = {};
  return await DivisionHasUserList.create({
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const getDiagRuleDetail: Handler = async (controller, {apiToken, id}) => {
  return await DiagRuleDetail.retrieve({
    pk: id,
    apiToken: controller.apiToken
  });
}

export const postDiagRule: Handler = async (controller, { apiToken, data }) => {
  let params: any = {};
  return await DiagRuleList.create({
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const postTuhSetPatientHn: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, data = _b.data;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.postTuhSetPatientHn({
//                                    params: params,
//                                    apiToken: apiToken,
//                                    data: data
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data, apiToken = _b.apiToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .post(apis_1.PRX.TUH_SET_PATIENT_HN, data, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/tuh/set-patient-hn/
}

export const postListPatientFromDiagForm: Handler = async (controller, {data, divisionId, apiToken}) => {

  return await DiagFormByIdList.post({
    apiToken: apiToken,
    extra: { division: divisionId },
    data: data,
  });


//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, data = _b.data, divisionId = _b.divisionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.postListPatientFromDiagForm({
//                                    params: params,
//                                    apiToken: apiToken,
//                                    data: data,
//                                    divisionId: divisionId
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data, apiToken = _b.apiToken, divisionId = _b.divisionId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        if (divisionId) {
//            this.client.defaults.headers["division"] = divisionId;
//        }
//        return this.client
//            .post(apis_1.PRX.LIST_PATIENT_FROM_DIAG_FORM, data, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/list-patient/from-diag-form/
}

export const postListPatientFromPatient: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, data = _b.data, divisionId = _b.divisionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.postListPatientFromPatient({
//                                    params: params,
//                                    apiToken: apiToken,
//                                    data: data,
//                                    divisionId: divisionId
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data, apiToken = _b.apiToken, divisionId = _b.divisionId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        if (divisionId) {
//            this.client.defaults.headers["division"] = divisionId;
//        }
//        return this.client
//            .post(apis_1.PRX.LIST_PATIENT_FROM_PATIENT, data, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/list-patient/from-patient/
}

export const getProxyPatientHasDivisionNotFollow: Handler = async (controller, {apiToken, search,offset,limit, divisionId}) => {

  return await ProxyPatientHasDivisionNotFollowList.list({
    apiToken,
    extra : {
      division: divisionId
    },
    params: {
      search,
      offset,
      limit
    }
  })


//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, divisionId = _b.divisionId, limit = _b.limit, offset = _b.offset, search = _b.search;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (limit) {
//                                params.limit = limit;
//                            }
//                            if (offset) {
//                                params.offset = offset;
//                            }
//                            if (search) {
//                                params.search = search;
//                            }
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.getProxyPatientHasDivisionNotFollow({
//                                    params: params,
//                                    apiToken: apiToken,
//                                    divisionId: divisionId
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken, divisionId = _b.divisionId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        if (divisionId) {
//            this.client.defaults.headers["division"] = divisionId;
//        }
//        return this.client
//            .get(apis_1.PRX.PROXY_PATIENT_HAS_DIVISION_NOT_FOLLOW, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/proxy-patient-has-division/not-follow/
}

export const getProxyPatientHasDivisionPatientChat: Handler = async (controller, {apiToken, divisionId, patientId}) => {

  return await ProxyPatientHasDivisionChatDetail.retrieve({
    apiToken,
    pk: patientId,
    extra : {
      division: divisionId
    },
  })


//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, divisionId = _b.divisionId, patientId = _b.patientId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.getProxyPatientHasDivisionPatientChat({
//                                    params: params,
//                                    apiToken: apiToken,
//                                    divisionId: divisionId,
//                                    patientId: patientId
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken, divisionId = _b.divisionId, patientId = _b.patientId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        if (divisionId) {
//            this.client.defaults.headers["division"] = divisionId;
//        }
//        var url = apis_1.PRX.PROXY_PATIENT_HAS_DIVISION_PATIENT_CHAT_ID({ patient_id: patientId });
//        return this.client
//            .get(url, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PRX/proxy-patient-has-division/patient-chat/' +
//((__t = (patient_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const patchDiagRuleDetail: Handler = async (controller, {apiToken, id, content}) => {
  let params: any = {};
  let data: any = {};
  if (content) {
    data.content = content;
  }
  return await DiagRuleDetail.patch({
    pk: id,
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const patchDiagRuleName: Handler = async (controller, { apiToken, id, name }) => {
  let params: any = {};
  let data: any = {};
  if (name) {
    data.name = name;
  }
  return await DiagRuleDetail.patch({
    pk: id,
    params: {},
    data: data,
    apiToken: controller.apiToken
  });  
}

export const patchDiagRulePublish: Handler = async (controller, { apiToken, id, published }) => {
  let params: any = {};
  let data: any = {
    published: published
  };
  return await DiagRuleDetail.patch({
    pk: id,
    params: params,
    data: data,
    apiToken: controller.apiToken 
  });
}

export const patchDiagForm: Handler = async (controller, { apiToken, diagFormId, trackingStatus, triageLevel }) => {
  let params: any = {};
  let data: any = {};
  if (trackingStatus) {
    data.tracking_status = trackingStatus;
  }
  if (triageLevel) {
    data.triage_level = triageLevel
  }
  return await DiagFormDetail.patch({
    pk: diagFormId,
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const getV2EncounterTriage: Handler = async (controller, { apiToken, diagRuleId }) => {
  let params: any = {};
  let data: any = {};
  if (diagRuleId) {
    params.diag_rule = diagRuleId;
  }
  return await V2EncounterTriageList.get({
    apiToken: controller.apiToken,
    params: params,
  });
}

export const postDiagFormMonitor: Handler = async (controller, { apiToken, patient, division }) => {
  let params: any = {};
  let data: any = {}
  if (patient) {
    data.patient = patient
  }
  if (division) {
    data.division = division
  }
  return await MonitorDiagForm.create({
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const patchDiagRuleActive: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, id = _b.id, active = _b.active, published = _b.published;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, data, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            data = {
//                                active: active
//                            };
//                            if (published !== undefined && published !== null) {
//                                data.published = published;
//                            }
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.patchDiagRuleDetail({
//                                    params: params,
//                                    apiToken: apiToken,
//                                    data: data,
//                                    id: id
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//
//----> Name mismatch between manager and service
}

export const getPublishedDiagRule: Handler = async (controller, { params, apiToken, diagRuleId }) => {
  return await PublishedDiagRuleDetail.retrieve({
    pk: diagRuleId,
    params: params,
    apiToken: controller.apiToken
  });
}

export const postPublishedDiagRule: Handler = async (controller, { apiToken, content, diagRule }) => {
  let params: any = {};
  let data: any = {};
  if (content) {
    data.content = content;
  }
  if (diagRule) {
    data.diag_rule = diagRule;
  }
  return await PublishedDiagRuleList.create({
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const patchPublishedDiagRule: Handler = async (controller, { apiToken, published, id }) => {
  let params: any = {};
  let data: any = {};
  if (published || published === false) {
    data.published = published;
  }
  // !!! no patch function in this api
  return await PublishedDiagRuleDetail?.patch({
    pk: id,
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const getTriageLevelClassify: Handler = async (controller, { apiToken, divisionId }) => {
  let params: any = {};
  let data: any = {};
  if (divisionId) {
    params.division = divisionId;
  }
  let result = await ClassifyList.get({
    params: params,
    apiToken: controller.apiToken
  });
  if (!result[1]) return result;

  const prxService: any = new PRXService();
  const [error, response, network] = await to(
    prxService.getMasterTriageLevelClassify({
      params: params,
      apiToken: controller.apiToken
    })
  );
  return [response, error, network];
}

export const getClassifyUser: Handler = async (controller, { apiToken, divisionId } ) => {
  let params: any = {};
  let data: any = {};
  if (divisionId) {
    params.division = divisionId;
  }
  return ClassifyUserList.get({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getMasterTriageLevelClassify: Handler = async (controller, { apiToken }) => {
  let params: any = {};
  let data: any = {};
  const prxService: any = new PRXService();
  const [error, response, network] = await to(
    prxService.getMasterTriageLevelClassify({
      params: params,
      apiToken: controller.apiToken
    })
  );
  return [response, error, network];
}

export const getLatestChatChannel: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, userId = _b.userId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (userId) {
//                                params.user_id = userId;
//                            }
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.getLatestChatChannel({
//                                    params: params,
//                                    apiToken: apiToken
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .get(apis_1.PRX.LATEST_CHAT_CHANNEL, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/latest-chat-channel/
}

export const getEncounterTriage: Handler = async (controller, {
  apiToken,
  filter,
  patientId,
  divisionIds,
  hasPatientApp,
  orderBy,
  date,
}) => {
  let params: any = {};
  if (filter) {
    params.triage_level = filter;
  }
  if (patientId) {
    params.patients = patientId;
  }
  if (divisionIds) {
    params.divisions = divisionIds;
  }
  if (hasPatientApp || hasPatientApp === false) {
    params.has_patient_appointment = hasPatientApp;
  }
  if (orderBy) {
    params.order_by = orderBy;
  }
  if (date) {
    params.date = date;
  }
  return await EncounterTriageList.list({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getEncounter: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, divisionId = _b.divisionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (divisionId) {
//                                params.division = divisionId;
//                            }
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.getEncounter({
//                                    params: params,
//                                    apiToken: apiToken
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .get(apis_1.PRX.ENCOUNTER, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/encounter/
}

export const getChatChannelMessageList: Handler = async (controller, {
  apiToken,
  chatChannelId,
  last
}) => {
  let params: any = {};
  if (last) {
    params.last = last;
  }
  return await MessageListView.list({
    chat_channel_id: chatChannelId,
    params: params,
    apiToken: controller.apiToken
  });
}

export const getMessageFromURL: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, url = _b.url;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.getMessageFromURL({
//                                    params: params,
//                                    apiToken: apiToken,
//                                    url: url
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken, url = _b.url;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .get(url, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
}

export const postChatChannelMessage: Handler = async (controller,{
  apiToken,
  content,
  contentType,
  chatChannelId,
  contentFile,
  divisionId
}) => {
  let params: any = {};
  let data: any = {};
  if (content && contentType !== "image" && contentType !== "file") {
    data.content = content;
  }
  if (contentType) {
    data.content_type = contentType;
  }
  if (contentFile) {
    data.content_file = contentFile;
  }
  var fd = new FormData();
  for (var key in data) {
      fd.append(key, data[key]);
  }
  return await MessagePostNoSubscriptionView.post({
    chat_channel_id: chatChannelId,
    params: params,
    data: fd,
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
  });
}

export const geNurseNoteList: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, encounterId = _b.encounterId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (encounterId) {
//                                params.encounter = encounterId;
//                            }
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.geNurseNoteList({
//                                    params: params,
//                                    apiToken: apiToken
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .get(apis_1.PRX.NURSE_NOTE_LIST, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/nurse-note
}

export const geProgressNoteList: Handler = async (controller, { apiToken, encounterId }) => {
  let params: any = {};
  if (encounterId) {
    params.encounter = encounterId;
  }
  return await ProgressionNoteIsHealthList.list({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getChannelDetail: Handler = async (controller, { apiToken, chatChannelId }) => {
  let params: any = {};
  return await ChatChannelNoSubscriptionDetail.retrieve({
    pk: chatChannelId,
    params: params,
    apiToken: controller.apiToken
  });
}

export const geNurseNoteEncounter: Handler = async (controller, { apiToken, encounterId }) => {
  return await NurseNoteIsHealthFromEncounterDetail.retrieve({
    pk: encounterId,
    apiToken: controller.apiToken
  })
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, encounterId = _b.encounterId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.geNurseNoteEncounter({
//                                    encounterId: encounterId,
//                                    params: params,
//                                    apiToken: apiToken
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken, encounterId = _b.encounterId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        var url = apis_1.PRX.NURSE_NOTE_ENCOUNTER({
//            encounterId: encounterId
//        });
//        return this.client
//            .get(url, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PRX/nurse-note/encounter/' +
//((__t = (encounterId)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getProxyPatient: Handler = async (controller, { apiToken, patientId }) => {
  let params: any = {};
  return await ProxyPatientDetail.retrieve({
    pk: patientId,
    params: params,
    apiToken: controller.apiToken
  });
}

export const getProxyPatientAllergy: Handler = async (controller, { apiToken, patientId }) => {
  let params: any = {};
  return await ProxyPatientAllergy.get({
    pk: patientId,
    params: params,
    apiToken: controller.apiToken
  });
}

export const getProxyPatientList: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, division = _b.division, search = _b.search;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (search) {
//                                params.search = search;
//                            }
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.getProxyPatientList({
//                                    apiToken: apiToken,
//                                    params: params,
//                                    division: division
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken, division = _b.division;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//            this.client.defaults.headers["division"] = division;
//        }
//        var url = apis_1.PRX.PROXY_PATIENT_LIST;
//        return this.client
//            .get(url, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/proxy-patient/
}

export const patchProxyPatientHasDivision: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, hn = _b.hn, id = _b.id;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, data, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            data = {};
//                            if (hn) {
//                                data.hn = hn;
//                            }
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.patchProxyPatientHasDivision({
//                                    apiToken: apiToken,
//                                    params: params,
//                                    data: data,
//                                    id: id
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var apiToken = _a.apiToken, params = _a.params, data = _a.data, id = _a.id;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        var url = apis_1.PRX.PROXY_PATIENT_HAS_DIVISION_ID({ id: id });
//        return this.client
//            .patch(url, data, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PRX/proxy-patient-has-division/' +
//((__t = (id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getProxyPatientHasDivisionAdmin: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, division = _b.division;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.getProxyPatientHasDivisionAdmin({
//                                    apiToken: apiToken,
//                                    params: params,
//                                    division: division
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken, division = _b.division;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//            this.client.defaults.headers["division"] = division;
//        }
//        var url = apis_1.PRX.PROXY_PATIENT_HAS_DIVISION_ADMIN;
//        return this.client
//            .get(url, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/proxy-patient-has-division/admin/
}

export const patchProxyPatientHasDivisionAdmin: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, hn = _b.hn, id = _b.id;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, data, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            data = {};
//                            if (hn) {
//                                data.hn = hn;
//                            }
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.patchProxyPatientHasDivisionAdmin({
//                                    apiToken: apiToken,
//                                    params: params,
//                                    data: data,
//                                    id: id
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var apiToken = _a.apiToken, params = _a.params, data = _a.data, id = _a.id;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        var url = apis_1.PRX.PROXY_PATIENT_HAS_DIVISION_ID_ADMIN({ id: id });
//        return this.client
//            .patch(url, data, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PRX/proxy-patient-has-division/' +
//((__t = (id)) == null ? '' : __t) +
//'/admin/';
//
//}
//return __p
//}
}

export const patchEncounterReclassify: Handler = async (controller, {
  apiToken,
  encounterId,
  triageLevel,
  diagFormId
}) => {
  let params: any = {};
  let data: any = {};
  data.triage_level = triageLevel;

  if (diagFormId) {
    data.diag_form = diagFormId
  }
  return EncounterReclassifyView.patch({
    pk: encounterId,
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const getChatChannel: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, userId = _b.userId, isPatient = _b.isPatient;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (userId) {
//                                params.user = userId;
//                                params.user_type = isPatient ? "PATIENT" : "STAFF";
//                            }
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.getChatChannel({
//                                    params: params,
//                                    apiToken: apiToken
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var params = _a.params, apiToken = _a.apiToken, isPatient = _a.isPatient;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .get(apis_1.PRX.CHAT_CHANNEL, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/chat-channel/
}

export const getProxyPatientHasDivisionIdAdmin: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, division = _b.division, params = _b.params;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.getProxyPatientHasDivisionIdAdmin({
//                                    params: params,
//                                    apiToken: apiToken,
//                                    division: division
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var apiToken = _a.apiToken, params = _a.params, division = _a.division, id = _a.id;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//            this.client.defaults.headers["division"] = division;
//        }
//        var url = apis_1.PRX.PROXY_PATIENT_HAS_DIVISION_ADMIN;
//        return this.client
//            .get(url, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/proxy-patient-has-division/admin/
}

export const getTuhUsersList: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.getTuhUsersList({
//                                    params: params,
//                                    apiToken: apiToken,
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .get(apis_1.PRX.TUH_USERS_LIST, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/tuh/users_list/
}

export const getProxyPatientHasDivision: Handler = async (controller, {}) => {
//function (_a) {
//            var apiToken = _a.apiToken, params = _a.params;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.getProxyPatientHasDivision({
//                                    params: params,
//                                    apiToken: apiToken
//                                }))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var params = _a.params, apiToken = _a.apiToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .get(apis_1.PRX.PROXY_PATIENT_HAS_DIVISION, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/proxy-patient-has-division/
}

export const getPatient: Handler = async (controller, { apiToken }) => {
  return await MyProxyPatientView.retrieve({
    apiToken: controller.apiToken
  });
}

export const patchPatient: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            var apiToken, address, first_name, last_name, phone, birthday, gender, data, formData, _i, _a, pair, prxService, _b, error, response;
//            return __generator(this, function (_c) {
//                switch (_c.label) {
//                    case 0:
//                        apiToken = params.apiToken, address = params.address, first_name = params.first_name, last_name = params.last_name, phone = params.phone, birthday = params.birthday, gender = params.gender, data = __rest(params, ["apiToken", "address", "first_name", "last_name", "phone", "birthday", "gender"]);
//                        formData = new FormData();
//                        formData.append("extra", JSON.stringify(data));
//                        console.log('formData: ');
//                        if (first_name) {
//                            formData.append("first_name", first_name);
//                        }
//                        if (last_name) {
//                            formData.append("last_name", last_name);
//                        }
//                        if (phone) {
//                            formData.append("phone_no", phone);
//                        }
//                        if (birthday) { // YYYY-MM-DD
//                            console.log(" data", birthday);
//                            // formData.append("birthdate", birthday);
//                        }
//                        if (gender) {
//                            console.log("data.gender", gender);
//                            //formData.append("gender", data.gender);
//                        }
//                        if (address) {
//                            formData.append("address", address);
//                        }
//                        for (_i = 0, _a = formData.entries(); _i < _a.length; _i++) {
//                            pair = _a[_i];
//                            console.log(pair[0] + ', ' + pair[1]);
//                        }
//                        prxService = this.newPRXService(apiToken);
//                        return [4 /*yield*/, utils_1.to(prxService.patchPatient({
//                                data: formData,
//                                options: { headers: { 'Content-Type': "multipart/form-data" } },
//                                apiToken: apiToken,
//                            }))];
//                    case 1:
//                        _b = _c.sent(), error = _b[0], response = _b[1];
//                        return [2 /*return*/, [response, error]];
//                }
//            });
//        }); }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, options = _b.options, data = _b.data, apiToken = _b.apiToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        console.log("patchPatient (data, option)", data, options);
//        return this.client
//            .patch(apis_1.PRX.PATIENT, data, options)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/patient/
}

export const postEncounterPatientAppointment: Handler = async (controller, { encounterId, apiToken } ) => {
  let params: any = {};
  let data: any = {};
  if (encounterId) {
    data.encounter = encounterId;
  }
  return await EncounterPatientAppointmentList.post({
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const getDoctorWorkSchedule: Handler = async (controller, {
  apiToken,
  providerId,
  startDate,
  endDate,
  divisionId
}) => {
  let params: any = {};
  if (providerId) {
    params.provider = providerId;
  }
  if (startDate) {
    params.start_date = startDate;
  }
  if (endDate) {
    params.end_date = endDate;
  }
  if (divisionId) {
    params.division = divisionId;
  }
  return await DoctorWorkScheduleIsHealthAPIView.get({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getAvailableDivisionServiceBlock: Handler = async (controller, {
  apiToken,
  division,
  employee,
  date,
  timeRange,
  providerTypeCategory,
}) => {
  let params: any = {};
  if (division) {
    params.division = division;
  }
  if (employee) {
    params.employee = employee;
  }
  if (date) {
    params.date = date;
  }
  if (timeRange) {
    params.time_range = timeRange;
  }
  if (providerTypeCategory) {
    params.provider_type_category = "covid_vaccine";
  }
  return await AvailableDivisionServiceBlockIsHealthAPIView.get({
    params: params,
    apiToken: controller.apiToken
  });
}

export const postSelectDivisionServiceBlock: Handler = async (controller, {
  apiToken,
  patientAppointmentId,
  division,
  timeRange,
  employee,
  diagFormId,
  providerTypeCategory
}) => {
  let params: any = {};
  let data: any = {};
  if (patientAppointmentId) {
    data.patient_appointment = patientAppointmentId;
  }
  if (division) {
    data.division = division;
  }
  if (employee) {
    data.employee = employee;
  }
  if (timeRange) {
    data.time_range = timeRange;
  }
  if (diagFormId) {
    data.diag_form = diagFormId;
  }
  if (providerTypeCategory) {
    data.provider_type_category = providerTypeCategory;
  }
  return await SelectDivisionServiceBlock.post({
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const postRequestChangeDivisionServiceBlock: Handler = async (controller, {
  apiToken,
  remark,
  division,
  patientAppointment
}) => {
  let params: any = {};
  let data: any = {};
  if (patientAppointment) {
    data.patient_appointment = patientAppointment;
  }
  if (division) {
    data.division = division;
  }
  if (remark) {
    data.remark = remark;
  }
  return await RequestChangeDivisionServiceBlock.post({
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const getEncounterTriageDetail: Handler = async (controller, { apiToken, encounterId }) => {
  let params: any = {};
  return await EncounterTriageDetail.retrieve({
    pk: encounterId,
    params: params,
    apiToken: controller.apiToken
  });
}

export const getPatientQueue: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, divisionIds = _b.divisionIds, divisionGroupCode = _b.divisionGroupCode, _c = _b.unsignedOnly, unsignedOnly = _c === void 0 ? false : _c, _d = _b.signedOnly, signedOnly = _d === void 0 ? false : _d, _e = _b.search, search = _e === void 0 ? "" : _e;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _f, error, response, network;
//                return __generator(this, function (_g) {
//                    switch (_g.label) {
//                        case 0:
//                            params = {};
//                            prxService = this.newPRXService(apiToken);
//                            if (divisionIds) {
//                                params.division = divisionIds;
//                            }
//                            if (divisionGroupCode) {
//                                params.division_group_code = divisionGroupCode;
//                            }
//                            if (unsignedOnly) {
//                                params.unsigned_only = unsignedOnly;
//                            }
//                            if (signedOnly) {
//                                params.signed_only = signedOnly;
//                            }
//                            if (search) {
//                                params.search = search;
//                            }
//                            return [4 /*yield*/, utils_1.to(prxService.getPatientQueue({
//                                    apiToken: apiToken,
//                                    params: params
//                                }))];
//                        case 1:
//                            _f = _g.sent(), error = _f[0], response = _f[1], network = _f[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .get(apis_1.PRX.PATIENT_QUEUE, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/patient-queue/
}

export const getAppointedPatientQueue: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, divisionIds = _b.divisionIds, date = _b.date;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            prxService = this.newPRXService(apiToken);
//                            if (divisionIds) {
//                                params.division = divisionIds;
//                            }
//                            if (date) {
//                                params.date = date;
//                            }
//                            return [4 /*yield*/, utils_1.to(prxService.getAppointedPatientQueue({
//                                    apiToken: apiToken,
//                                    params: params
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        var url = config_1.default.QUEUE_API_V2 ? apis_1.PRX.APPOINTED_PATIENT_QUEUE_V2 : apis_1.PRX.APPOINTED_PATIENT_QUEUE;
//        return this.client
//            .get(url, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/v2/appointment-patient-queue/
}

export const getShippingAddress: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, patientId = _b.patientId, encounterId = _b.encounterId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            prxService = this.newPRXService(apiToken);
//                            if (patientId) {
//                                params.patient = patientId;
//                            }
//                            if (encounterId) {
//                                params.encounter = encounterId;
//                            }
//                            return [4 /*yield*/, utils_1.to(prxService.getShippingAddress({
//                                    apiToken: apiToken,
//                                    params: params
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .get(apis_1.PRX.SHIPPING_ADDRESS, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/shipping-address/
}

export const postShippingAddress: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, patientAddressId = _b.patientAddressId, encounterId = _b.encounterId, selfPickup = _b.selfPickup, receiverName = _b.receiverName, receiverTel = _b.receiverTel;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, data, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            data = {};
//                            prxService = this.newPRXService(apiToken);
//                            if (patientAddressId) {
//                                data.patient_address = patientAddressId;
//                            }
//                            if (encounterId) {
//                                data.encounter = encounterId;
//                            }
//                            if (selfPickup || selfPickup === false) {
//                                data.self_pickup = selfPickup;
//                            }
//                            if (receiverName) {
//                                data.receiver_name = receiverName;
//                            }
//                            if (receiverTel) {
//                                data.receiver_tel = receiverTel;
//                            }
//                            return [4 /*yield*/, utils_1.to(prxService.postShippingAddress({
//                                    apiToken: apiToken,
//                                    params: params,
//                                    data: data
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params, data = _b.data;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .post(apis_1.PRX.SHIPPING_ADDRESS, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/shipping-address/
}

export const getInitPatientAddress: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, patientId = _b.patientId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            prxService = this.newPRXService();
//                            return [4 /*yield*/, utils_1.to(prxService.getInitPatientAddress({
//                                    params: params,
//                                    apiToken: apiToken,
//                                    patientId: patientId
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken, patientId = _b.patientId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .get(apis_1.PRX.INIT_PATIENT_ADDRESS_PATIENT({ patientId: patientId }), { params: params })
//            .then(function (response) { return response.data; })
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PRX/init-patient-address/patient/' +
//((__t = (patientId)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getDashboard: Handler = async (controller, {
  apiToken,
  search,
  date,
  triageLevels,
  division,
  paymentStatus,
  organization
}) => {
  let params: any = {};
  if (search) {
    params.search = search;
  }
  if (date) {
    params.date = date;
  }
  if (triageLevels) {
    params.triage_levels = triageLevels;
  }
  if (division) {
    params.division = division;
  }
  if (paymentStatus) {
    params.payment_status = paymentStatus;
  }
  if (organization){
    params.organization = organization;
  }
  return await DashboardView.get({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getOfficialAccountDashboard: Handler = async (controller, {
  apiToken,
  search,
  date,
  triageLevels,
  division,
  paymentStatus
}) => {
  let params: any = {};
  if (search) {
    params.search = search;
  }
  if (date) {
    params.date = date;
  }
  if (triageLevels) {
    params.triage_levels = triageLevels;
  }
  if (division) {
    params.division = division;
  }
  if (paymentStatus) {
    params.payment_status = paymentStatus;
  }

  return await OfficialAccountDashboardView.get({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getDashboardAppointment: Handler = async (controller, {   apiToken,
  hn,
  patient_name,
  division,
  doctor_name,
  start_date,
  end_date,
  patient_dob,
  cancelToken
}) => {

  let params: any = {};
  if (hn) {
    params.hn = hn;
  }
  if (patient_name) {
    params.patient_name = patient_name;
  }
  if (division) {
    params.division = division;
  }
  if (doctor_name) {
    params.doctor_name = doctor_name;
  }
  if (start_date) {
    params.start_date = formatDateToYYYYMMDD(start_date);
  }
  if (end_date) {
    params.end_date = formatDateToYYYYMMDD(end_date);
  }
  if (patient_dob) {
    params.patient_dob = formatDateToYYYYMMDD(patient_dob);
  }

  // if config.QUEUE_API_V2 ? PRX.DASHBOARD_APPOINTMENT_V2 :
  //     config.QUEUE_OA_DASHBOARD_APPOINTMENT ? PRX.OA_DASHBOARD_APPOINTMENT : PRX.DASHBOARD_APPOINTMENT;

  // DirectDashboardAppointmentView // PRX.DASHBOARD_APPOINTMENT_V2
  // OfficialAccountDashboardAppointmentView // PRX.OA_DASHBOARD_APPOINTMENT 
  // DashboardAppointmentView // PRX.DASHBOARD_APPOINTMENT

  if (config.QUEUE_API_V2) {
    return await DirectDashboardAppointmentView.get({
      params: params,
      apiToken: controller.apiToken,
      // cancelToken
    });
  } else if ( config.QUEUE_OA_DASHBOARD_APPOINTMENT ) {
    return await OfficialAccountDashboardAppointmentView.list({
      params: params,
      apiToken: controller.apiToken,
      // cancelToken,
    });
  } else {
    return await DashboardAppointmentView.get({
      params: params,
      apiToken: controller.apiToken,
      // cancelToken
    });
  }



//function (_a) {
//            var apiToken = _a.apiToken, hn = _a.hn, patient_name = _a.patient_name, division = _a.division, doctor_name = _a.doctor_name, start_date = _a.start_date, end_date = _a.end_date, patient_dob = _a.patient_dob, cancelToken = _a.cancelToken;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            if (hn) {
//                                params.hn = hn;
//                            }
//                            if (patient_name) {
//                                params.patient_name = patient_name;
//                            }
//                            if (division) {
//                                params.division = division;
//                            }
//                            if (doctor_name) {
//                                params.doctor_name = doctor_name;
//                            }
//                            if (start_date) {
//                                params.start_date = dateUtils_1.formatDateToYYYYMMDD(start_date);
//                            }
//                            if (end_date) {
//                                params.end_date = dateUtils_1.formatDateToYYYYMMDD(end_date);
//                            }
//                            if (patient_dob) {
//                                params.patient_dob = dateUtils_1.formatDateToYYYYMMDD(patient_dob);
//                            }
//                            return [4 /*yield*/, utils_1.to(prxService.getDashboardAppointment({ apiToken: apiToken, params: params, cancelToken: cancelToken }))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var apiToken = _a.apiToken, params = _a.params, cancelToken = _a.cancelToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        var url = config_1.default.QUEUE_API_V2 ? apis_1.PRX.DASHBOARD_APPOINTMENT_V2 :
//            config_1.default.QUEUE_OA_DASHBOARD_APPOINTMENT ? apis_1.PRX.OA_DASHBOARD_APPOINTMENT : apis_1.PRX.DASHBOARD_APPOINTMENT;
//        return this.client
//            .get(url, { params: params, cancelToken: cancelToken })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/v2/dashboard-appointment/
//undefined/apis/PRX/official-account/dashboard-appointment/
}

export const getResolveChatChannel: Handler = async (controller, {
  apiToken,
  divisionId,
  hn
}) => {
  let params: any = {};
  if (hn) {
    params.hn = hn;
  }
  return await ResolveChatChannelView.get({
    params: params,
    extra: { division: controller.data.division },
    apiToken: controller.apiToken
  });
}

export const postRequestOA: Handler = async (controller, {
  apiToken,
  division_name,
  user_role,
  detail,
  name
}) => {
  let params: any = {};
  let data: any = {};
  if (division_name) {
    data.division_name = division_name;
  }
  if (user_role) {
    data.user_role = user_role;
  }
  if (detail) {
    data.detail = detail;
  }
  if (name) {
    data.name = name;
  }
  return await OfficialAccountCreate.create({
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const getListOA: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            return [4 /*yield*/, utils_1.to(prxService.getListOA({ apiToken: apiToken, params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .get(apis_1.PRX.LIST_OA, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/official-account/
}

export const postFollowOA: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, id = _b.id;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, data, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            data = {};
//                            return [4 /*yield*/, utils_1.to(prxService.postFollowOA({ apiToken: apiToken, data: data, params: params, id: id }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, data = _b.data, params = _b.params, id = _b.id;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        var url = apis_1.PRX.FOLLOW_OA({ id: id });
//        return this.client
//            .post(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PRX/official-account/' +
//((__t = (id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getOAInviteLink: Handler = async (controller, {
  apiToken,
  divisionId
}) => {
  return await OfficialAccountInvitationLink.get({
    params: {},
    extra: { division: controller.data.division },
    apiToken: controller.apiToken
  });
}

export const postCreatejoinOARequest: Handler = async (controller, {
  apiToken,
  token,
}) => {
  let params: any = {};
  let data: any = {};
  if (token) {
    data.token = token;
  }
  return await CreateOfficialAccountRequest.create({
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const getListJoinOARequest: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, divisionId = _b.divisionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            return [4 /*yield*/, utils_1.to(prxService.getListJoinOARequest({ apiToken: apiToken, params: params, divisionId: divisionId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params, divisionId = _b.divisionId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        if (divisionId) {
//            this.client.defaults.headers["division"] = divisionId;
//        }
//        return this.client
//            .get(apis_1.PRX.LIST_JOIN_OA_REQUEST, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/official-account/invite/
}

export const postApproveJoinOARequest: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, id = _b.id, divisionId = _b.divisionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, data, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            data = {};
//                            return [4 /*yield*/, utils_1.to(prxService.postApproveJoinOARequest({ apiToken: apiToken, data: data, params: params, id: id, divisionId: divisionId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, data = _b.data, params = _b.params, id = _b.id, divisionId = _b.divisionId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//            this.client.defaults.headers["division"] = divisionId;
//        }
//        var url = apis_1.PRX.APPROVE_JOIN_OA_REQUEST({ id: id });
//        return this.client
//            .post(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PRX/official-account/invite/' +
//((__t = (id)) == null ? '' : __t) +
//'/approve/';
//
//}
//return __p
//}
}

export const postCreateUpdateSchedule: Handler = async (controller, {
  apiToken,
  division,
  providerTypeCategory,
  startDate,
  endDate,
  patientPerSlot,
  items,
  workOnMonday,
  workOnTuesday,
  workOnWednesday,
  workOnThursday,
  workOnFriday,
  workOnSaturday,
  workOnSunday,
  id,
  confirmed,
  diagRule
}) => {
  let params: any = {};
  let data: any = {};

  if (id) {
    data.id = id;
  }

  if (division) {
    data.division = division;
  }
  if (providerTypeCategory) {
    data.provider_type_category = providerTypeCategory;
  }
  if (startDate) {
    data.start_date = startDate;
  }
  if (endDate) {
    data.end_date = endDate;
  }
  if (patientPerSlot) {
    data.patient_per_slot = patientPerSlot;
  }
  if (items) {
    data.items = items;
  }
  if (diagRule) {
    data.diag_rule = diagRule;
  }
  if (workOnMonday !== undefined) {
    data.work_on_monday = workOnMonday;
  }
  if (workOnTuesday !== undefined) {
    data.work_on_tuesday = workOnTuesday;
  }
  if (workOnWednesday !== undefined) {
    data.work_on_wednesday = workOnWednesday;
  }
  if (workOnThursday !== undefined) {
    data.work_on_thursday = workOnThursday;
  }
  if (workOnFriday !== undefined) {
    data.work_on_friday = workOnFriday;
  }
  if (workOnSaturday !== undefined) {
    data.work_on_saturday = workOnSaturday;
  }
  if (workOnSunday !== undefined) {
    data.work_on_sunday = workOnSunday;
  }
  if (confirmed !== undefined) {
    data.confirmed = confirmed
  }
  return await CreateUpdateScheduleView.post({
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const postPatientFromDiagRule: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, data = _b.data;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            return [4 /*yield*/, utils_1.to(prxService.postPatientFromDiagRule({ apiToken: apiToken, data: data, params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data, apiToken = _b.apiToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .post(apis_1.PRX.PATIENT_FROM_DIAGRULE, data, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/patient-from-diag-rule/
}

export const deleteJoinOARequest: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, id = _b.id, divisionId = _b.divisionId, detail = _b.detail;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, data, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            data = {};
//                            if (detail) {
//                                data.detail = detail;
//                            }
//                            return [4 /*yield*/, utils_1.to(prxService.deleteJoinOARequest({ apiToken: apiToken, data: data, params: params, id: id, divisionId: divisionId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var apiToken = _a.apiToken, data = _a.data, params = _a.params, id = _a.id, divisionId = _a.divisionId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//            this.client.defaults.headers["division"] = divisionId;
//        }
//        var url = apis_1.PRX.DELETE_JOIN_OA_REQUEST({ id: id });
//        return this.client
//            .delete(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PRX/official-account/invite/' +
//((__t = (id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const deleteDivisionHasUserOA: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, id = _b.id, divisionId = _b.divisionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, data, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            data = {};
//                            return [4 /*yield*/, utils_1.to(prxService.deleteDivisionHasUserOA({ apiToken: apiToken, data: data, params: params, id: id, divisionId: divisionId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var apiToken = _a.apiToken, data = _a.data, params = _a.params, id = _a.id, divisionId = _a.divisionId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//            this.client.defaults.headers["division"] = divisionId;
//        }
//        var url = apis_1.PRX.EDIT_DIVISION_HAS_USER_OA({ id: id });
//        return this.client
//            .delete(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PRX/official-account/user/' +
//((__t = (id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getListDivisionHasUserOA: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, divisionId = _b.divisionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            return [4 /*yield*/, utils_1.to(prxService.getListDivisionHasUserOA({ apiToken: apiToken, params: params, divisionId: divisionId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params, divisionId = _b.divisionId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//            this.client.defaults.headers["division"] = divisionId;
//        }
//        return this.client
//            .get(apis_1.PRX.LIST_DIVISION_HAS_USER_OA, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/official-account/user/
}

export const getListAvailabelTraigeLevel: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, divisionId = _b.divisionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            return [4 /*yield*/, utils_1.to(prxService.getListAvailabelTraigeLevel({ apiToken: apiToken, params: params, divisionId: divisionId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params, divisionId = _b.divisionId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//            this.client.defaults.headers["division"] = divisionId;
//        }
//        return this.client
//            .get(apis_1.PRX.LIST_AVAILABLE_TRAIGE_LEVEL, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/official-account/triage/
}

export const getListUserRoleOA: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, divisionId = _b.divisionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            return [4 /*yield*/, utils_1.to(prxService.getListUserRoleOA({ apiToken: apiToken, params: params, divisionId: divisionId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params, divisionId = _b.divisionId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        if (divisionId) {
//            this.client.defaults.headers["division"] = divisionId;
//        }
//        return this.client
//            .get(apis_1.PRX.LIST_USER_ROLE_OA, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/official-account/role/
}

export const getOADivisionProfile: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, divisionId = _b.divisionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            return [4 /*yield*/, utils_1.to(prxService.getOADivisionProfile({ apiToken: apiToken, params: params, divisionId: divisionId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params, divisionId = _b.divisionId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//            this.client.defaults.headers["division"] = divisionId;
//        }
//        return this.client
//            .get(apis_1.PRX.GET_OA_DIVISION_PROFILE, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/official-account/division/
}

export const getListOARequestCheck: Handler = async (controller, {}) => {
  return await OfficialAccountMyRequestList.list({
    params: {},
    apiToken: controller.apiToken
  });
}

export const patchDivisionHasUserOA: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, id = _b.id, divisionId = _b.divisionId, roleId = _b.roleId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, data, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            data = {
//                                role: roleId
//                            };
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.patchDivisionHasUserOA({
//                                    params: params,
//                                    apiToken: apiToken,
//                                    data: data,
//                                    id: id,
//                                    divisionId: divisionId
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data, apiToken = _b.apiToken, id = _b.id, divisionId = _b.divisionId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        if (divisionId) {
//            this.client.defaults.headers["division"] = divisionId;
//        }
//        var url = apis_1.PRX.EDIT_DIVISION_HAS_USER_OA({
//            id: id
//        });
//        return this.client
//            .patch(url, data, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PRX/official-account/user/' +
//((__t = (id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const patchOADivisionProfile: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, divisionId = _b.divisionId, name = _b.name, address = _b.address, detail = _b.detail, code = _b.code, premiumUrl = _b.premiumUrl, imageFile = _b.imageFile, verified = _b.verified, divisionName = _b.divisionName, hospitalName = _b.hospitalName, systemName = _b.systemName;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, data, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            data = {};
//                            if (name) {
//                                data.name = name;
//                            }
//                            if (address) {
//                                data.address = address;
//                            }
//                            if (detail) {
//                                data.detail = detail;
//                            }
//                            if (premiumUrl) {
//                                data.premium_url = premiumUrl;
//                            }
//                            if (code) {
//                                data.code = code;
//                            }
//                            if (imageFile) {
//                                data.image_file = imageFile;
//                            }
//                            if (verified) {
//                                data.verified = verified;
//                            }
//                            if (divisionName) {
//                                data.division_name = divisionName;
//                            }
//                            if (hospitalName) {
//                                data.hospital_name = hospitalName;
//                            }
//                            if (systemName) {
//                                data.system_name = systemName;
//                            }
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.patchOADivisionProfile({
//                                    params: params,
//                                    apiToken: apiToken,
//                                    data: data,
//                                    divisionId: divisionId
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params, data = _b.data, divisionId = _b.divisionId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//            this.client.defaults.headers["division"] = divisionId;
//            this.client.defaults.headers["Content-Type"] = "multipart/form-data";
//        }
//        var fd = new FormData();
//        for (var key in data) {
//            fd.append(key, data[key]);
//        }
//        return this.client
//            .patch(apis_1.PRX.GET_OA_DIVISION_PROFILE, fd, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/official-account/division/
}

export const getOAUserProfile: Handler = async (controller, {}) => {
  return await OfficialAccountMyProfile.retrieve({
    params: {},
    apiToken: controller.apiToken
  });
}

export const getListSchedule: Handler = async (controller, {
  apiToken,
  divisionId
}) => {
  let params: any = {};
  if (divisionId) {
    params.division = divisionId;
  }
  return await ScheduleList.list({
    params: params,
    extra: { division: controller.data.division },
    apiToken: controller.apiToken
  });
}

export const getDivisionClassify: Handler = async (controller, params) => {
  return await ClassifyList.get({
    params,
    apiToken: controller.apiToken
  });
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, divisionId = _b.divisionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            if (divisionId) {
//                                params.division = divisionId;
//                            }
//                            return [4 /*yield*/, utils_1.to(prxService.getDivisionClassify({ apiToken: apiToken, params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .get(apis_1.PRX.LIST_DIVISION_CLASSIFY, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/classify/
}

export const getChatChannelTriageLevel: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, divisionId = _b.divisionId, triageLevel = _b.triageLevel;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            return [4 /*yield*/, utils_1.to(prxService.getChatChannelTriageLevel({ apiToken: apiToken, params: params, divisionId: divisionId, triageLevel: triageLevel }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params, divisionId = _b.divisionId, triageLevel = _b.triageLevel;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//            this.client.defaults.headers["division"] = divisionId;
//        }
//        var url = apis_1.PRX.LIST_CHAT_CHANNEL_TRIAGE_LEVEL({ triage_level: triageLevel });
//        return this.client
//            .get(url, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PRX/official-account/triage/' +
//((__t = (triage_level)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getListChatChannelTriageLevelDiagRule: Handler = async (controller, {
  apiToken,
  divisionId,
  triageLevel,
  diagRuleId
} ) => {
  let params: any = {};
  console.log(triageLevel)
  return await OfficialAccountDiagRuleTriageLevelList.list({
    diag_rule_id: diagRuleId,
    triage_level: triageLevel,
    params: params,
    extra: { division: controller.data.division },
    apiToken: controller.apiToken
  });
}

export const getListAvailabelTriageLevelDiagRule: Handler = async (controller, {
  apiToken,
  divisionId,
  diagRuleId
}) => {
  let params: any = {}
  return await OfficialAccountDiagRuleTriageList.get({
    diag_rule_id: diagRuleId,
    params: params,
    extra: { division: controller.data.division },
    apiToken: controller.apiToken
  });
}

export const getPatientFromDiagRule: Handler = async (controller, {
  apiToken,
  patientId,
  diagRuleId,
  divisionId
}) => {
  return await PatientFromDiagRuleDetail.get({
    diag_rule_id: diagRuleId,
    patient_id: patientId,
    extra: { division: controller.data.division },
    apiToken: controller.apiToken
  });
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, patientId = _b.patientId, diagRuleId = _b.diagRuleId, divisionId = _b.divisionId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            return [4 /*yield*/, utils_1.to(prxService.getPatientFromDiagRule({ apiToken: apiToken, params: params, patientId: patientId, diagRuleId: diagRuleId, divisionId: divisionId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params, patientId = _b.patientId, diagRuleId = _b.diagRuleId, divisionId = _b.divisionId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//            this.client.defaults.headers["division"] = divisionId;
//        }
//        var url = apis_1.PRX.PATIENT_FROM_DIAGRULE_BY_ID({ patient_id: patientId, diag_rule_id: diagRuleId });
//        return this.client
//            .get(url, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PRX/patient-from-diag-rule/' +
//((__t = (patient_id)) == null ? '' : __t) +
//'/' +
//((__t = (diag_rule_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const putPatientFromDiagRule: Handler = async (controller, {
  apiToken,
  patientId,
  diagRuleId,
  data
}) => {
  return await PatientFromDiagRuleDetail.put({
    diag_rule_id: diagRuleId,
    patient_id: patientId,
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
    data
  });
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, patientId = _b.patientId, diagRuleId = _b.diagRuleId, data = _b.data;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            prxService = this.newPRXService();
//                            return [4 /*yield*/, utils_1.to(prxService.putPatientFromDiagRule({ apiToken: apiToken, params: params, patientId: patientId, diagRuleId: diagRuleId, data: data }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, patientId = _b.patientId, diagRuleId = _b.diagRuleId, data = _b.data;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        var url = apis_1.PRX.PATIENT_FROM_DIAGRULE_BY_ID({ patient_id: patientId, diag_rule_id: diagRuleId });
//        return this.client.put(url, data)
//            .then(function (response) { return response.data; })
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PRX/patient-from-diag-rule/' +
//((__t = (patient_id)) == null ? '' : __t) +
//'/' +
//((__t = (diag_rule_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const patchOAUserProfile: Handler = async (controller, {
  apiToken,
  email,
  first_name,
  last_name,
  dob,
  position,
  licence,
  image
}) => {
  let params: any = {};
  let data: any = {};

  if (email) {
    data.email = email;
  }
  if (first_name) {
    data.first_name = first_name;
  }
  if (last_name) {
    data.last_name = last_name;
  }
  if (dob) {
    data.dob = formatDateToYYYYMMDD(dob);
  }
  if (position) {
    data.position = position;
  }
  if (licence) {
    data.licence = licence;
  }
  if (image) {
    data.image = image;
  }
  var fd = new FormData();
  for (var key in data) {
      fd.append(key, data[key]);
  }
  return await OfficialAccountMyProfileM.patch({
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const getVaccineHospital: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, keyword = _b.keyword;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = { "keyword": keyword };
//                            return [4 /*yield*/, utils_1.to(prxService.getVaccineHospital({ apiToken: apiToken, params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .get(apis_1.PRX.LIST_VACCINE_HOSP, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/division-appointment/
}

export const getAvailableVaccineDivision: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, division = _b.division, year_month = _b.year_month;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = { division: division, year_month: year_month };
//                            return [4 /*yield*/, utils_1.to(prxService.getAvailableVaccineDivision({ apiToken: apiToken, params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .get(apis_1.PRX.LIST_AVAILABLE_VACCINE_DIVISION, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/available-vaccine-division-service-block/
}

export const postVaccineServiceBlock: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, slot = _b.slot;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, data, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            data = { division_service_block: slot };
//                            return [4 /*yield*/, utils_1.to(prxService.postVaccineServiceBlock({ apiToken: apiToken, data: data, params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data, apiToken = _b.apiToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .post(apis_1.PRX.VACCINE_SERVICE_BLOCK, data, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/select-vaccine-division-service-block/
}

export const postVaccineNote: Handler = async (controller, { apiToken, data }) => {
  return await VaccineNoteList.create({
    apiToken: controller.apiToken,
    data
  })
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, data = _b.data;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            return [4 /*yield*/, utils_1.to(prxService.postVaccineNote({ apiToken: apiToken, data: data, params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data, apiToken = _b.apiToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .post(apis_1.PRX.VACCINE_NOTE, data, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/vaccine-note/
}

export const putVaccineServiceBlock: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, slot = _b.slot;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, data, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            data = { division_service_block: slot };
//                            return [4 /*yield*/, utils_1.to(prxService.putVaccineServiceBlock({ apiToken: apiToken, data: data, params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data, apiToken = _b.apiToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .put(apis_1.PRX.VACCINE_SERVICE_BLOCK, data, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/select-vaccine-division-service-block/
}

export const deleteVaccineServiceBlock: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, slot = _b.slot;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, data, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = { division_service_block: slot };
//                            data = {};
//                            return [4 /*yield*/, utils_1.to(prxService.deleteVaccineServiceBlock({ apiToken: apiToken, data: data, params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data, apiToken = _b.apiToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        var url = apis_1.PRX.DEL_VACCINE_SERVICE_BLOCK({ division_service_block: params.division_service_block });
//        return this.client
//            .delete(url, {})
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PRX/cancel-vaccine-division-service-block/' +
//((__t = (division_service_block)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getListVaccine: Handler = async (controller, {}) => {
  return await VaccineList.list({
    apiToken: controller.apiToken
  })
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            return [4 /*yield*/, utils_1.to(prxService.getListVaccine({ apiToken: apiToken, params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .get(apis_1.PRX.LIST_VACCINE, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/vaccine/
}

export const getListVaccineLot: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, vaccineId = _b.vaccineId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            if (vaccineId) {
//                                params.vaccine = vaccineId;
//                            }
//                            return [4 /*yield*/, utils_1.to(prxService.getListVaccineLot({ apiToken: apiToken, params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .get(apis_1.PRX.LIST_VACCINE_LOT, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/PRX/vaccine-lot/
}

export const getListVaccineNote: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, encounter = _b.encounter, patientId = _b.patientId, divisionId = _b.divisionId, vaccineId = _b.vaccineId, vaccineSeq = _b.vaccineSeq, severity = _b.severity, trackingStatus = _b.trackingStatus, durationVaccinated = _b.durationVaccinated;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            if (encounter) {
//                                params.encounter = encounter;
//                            }
//                            if (patientId) {
//                                params.patient = patientId;
//                            }
//                            if (divisionId) {
//                                params.division = divisionId;
//                            }
//                            if (vaccineId) {
//                                params.vaccine = vaccineId;
//                            }
//                            if (vaccineSeq) {
//                                params.vaccine_seq = vaccineSeq;
//                            }
//                            if (severity) {
//                                params.severity = severity;
//                            }
//                            if (trackingStatus) {
//                                params.tracking_status = trackingStatus;
//                            }
//                            if (durationVaccinated) {
//                                params.duration_vaccinated = durationVaccinated;
//                            }
//                            return [4 /*yield*/, utils_1.to(prxService.getListVaccineNote({ apiToken: apiToken, params: params, divisionId: divisionId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params, divisionId = _b.divisionId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        var url = apis_1.PRX.VACCINE_NOTE_FIREBASE({ division_id: divisionId });
//        return this.client
//            .get(url, {})
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'https://firebasestorage.googleapis.com/v0/b/ishealth-vaccine-monitor-side-effects/o/' +
//((__t = (division_id)) == null ? '' : __t) +
//'.json?alt=media&token=monitor-side-effect&pagination_class=page_number';
//
//}
//return __p
//}
}

export const getVaccineNoteDetail: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, vaccineId = _b.vaccineId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var prxService, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            prxService = this.newPRXService();
//                            params = {};
//                            return [4 /*yield*/, utils_1.to(prxService.getVaccineNoteDetail({ apiToken: apiToken, params: params, vaccineId: vaccineId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, params = _b.params, vaccineId = _b.vaccineId;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        var url = apis_1.PRX.VACCINE_NOTE_ID({ vaccine_id: vaccineId });
//        return this.client
//            .get(url, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PRX/vaccine-note/' +
//((__t = (vaccine_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const patchVaccineNote: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, data = _b.data, id = _b.id;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prxService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            prxService = this.newPRXService(apiToken);
//                            return [4 /*yield*/, utils_1.to(prxService.patchVaccineNote({ apiToken: apiToken, data: data, id: id, params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, data = _b.data, id = _b.id;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        var url = apis_1.PRX.VACCINE_NOTE_ID({ vaccine_id: id });
//        return this.client
//            .patch(url, data)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/PRX/vaccine-note/' +
//((__t = (vaccine_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const postRequestBotMessage: Handler = async (controller, { apiToken, code_id ,chat_channel_id }) => {
  let params: any = {};
  let data: any = {}
  if (code_id) {
    data.code = code_id
  }
  return await RequestBotMessageView.create({
    chat_channel_id: chat_channel_id,
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}
