import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Input
} from 'semantic-ui-react'

const RefillDivisionPrintUx = (props: any) => {
    return(
      <div>
        <div
          style={{fontWeight: "bold", fontSize: "1.4rem", padding: "1rem"}}>
          พิมพ์รายการจัดเวชภัณฑ์สำหรับหน่วยบริการ (REFILL-DIVISION-PRINT)
        </div>
        <div>
          
          <div
            style={{padding: "10px", display: "flex", alignItems: "center", backgroundColor: "#F3F3F3" }}>
            {props.container}
          </div>
        </div>
        <div
          style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>
          
          <Button
            color="yellow"
            onClick={props.selectAll}
            style={{width: "150px", marginLeft: "10px"}}>
            เลือกทั้งหมด
          </Button>
          <Button
            color="yellow"
            onClick={props.unSelectAll}
            style={{width: "150px", marginLeft: "10px"}}>
            ไม่เลือก
          </Button>
          <div
            style={{display:"flex", width:"auto", marginLeft: "auto"}}>
            
            <Input
              label="มูลค่ารวม"
              readOnly="true"
              style={{width: "400px"}}
              value={props.total}>
            </Input>
            <div>
              {props.buttonSave}
            </div>
          </div>
        </div>
        <div
          style={{width:"100%"}}>
          {props.ErrorMessage}
        </div>
      </div>
    )
}

export default RefillDivisionPrintUx

export const screenPropsDefault = {}

/* Date Time : Mon Aug 15 2022 14:56:13 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์รายการจัดเวชภัณฑ์สำหรับหน่วยบริการ (REFILL-DIVISION-PRINT)"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.4rem\", padding: \"1rem\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกทั้งหมด"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.selectAll"
        },
        "style": {
          "type": "code",
          "value": "{width: \"150px\", marginLeft: \"10px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "ไม่เลือก"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.unSelectAll"
        },
        "style": {
          "type": "code",
          "value": "{width: \"150px\", marginLeft: \"10px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", width:\"auto\", marginLeft: \"auto\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Input",
      "parent": 7,
      "props": {
        "label": {
          "type": "value",
          "value": "มูลค่ารวม"
        },
        "readOnly": {
          "type": "value",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"400px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.total"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.container"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", display: \"flex\", alignItems: \"center\", backgroundColor: \"#F3F3F3\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 12,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "RefillDivisionPrintUx",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
