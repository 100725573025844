import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Icon,
  Checkbox
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardAllergyUX = (props: any) => {
    return(
      <div
        style={{ padding: "0px"}}>
        
        <div
          style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding: "20px 20px 20px 10px"}}>
          
          <div
            style={{display:"flex", justifyContent: "row", alignItems:"center",padding: "10px 0px"}}>
            
          </div>
          <div
            style={{ padding: "0px"}}>
            
            <Button
              color="blue"
              disabled={props.loadingTable}
              icon={true}
              name="New"
              onClick={props.onClickNew}
              style={{fontWeight:"bold"}}>
              เพิ่มประวัติ ADR
              <Icon
                name="plus">
              </Icon>
            </Button>
          </div>
        </div>
        <div
          style={{display:"flex", justifyContent:"flex-end",padding: "0px 20px 0px 30px"}}>
          
          <Checkbox
            checked={props.inactive}
            label="เเสดงประวัติ Inactived"
            onChange={props.changeInactive}>
          </Checkbox>
        </div>
        <div
          style={{ padding: "2px"}}>
          
          <div
            style={{fontWeight: "bold",fontSize:"large"}}>
            
            <div
              style={{display:"flex", justifyContent: "row",marginLeft: "20px",alignItems:"center",color: "#2170BA"}}>
              
              <div>
                {props.angle_down}
              </div>
              <div>
                {props.drug_lmage}
              </div>
              <div
                style={{display:"flex", justifyContent: "row",marginLeft: "10px",marginTop: "25px"}}>
                ประวัติการแพ้ยา
              </div>
              <div
                style={{marginTop: "15px",marginLeft: "15px"}}>
                {props.drug_status}
              </div>
              <div>
                {props.loadingComponent}
              </div>
              <Button
                basic={true}
                color="black"
                disabled={props.loadingTable}
                name="radioGroup1"
                onClick={props.onClickNKDA}
                style={{ marginTop: "15px",marginLeft: "70px",display: props.disabledNKDA}}>
                ปฏิเสธแพ้ยา 
              </Button>
            </div>
            <div
              style={{ padding: "0px"}}>
              
              <div
                style={{ justifyContent: "row",marginLeft: "55px",width: "180px", height: '0.2rem', backgroundColor: '#2170BA'}}>
                
              </div>
            </div>
          </div>
          <div
            style={{fontWeight: "bold",fontSize:"large",color:"#C5C5C5"}}>
            
            <div
              style={{display: 'flex',alignItems: 'center',justifyContent: 'center',marginTop: "30px"}}>
              {props.showTextNoneDrug}
            </div>
            <div>
              {props.showLineNoneDrug}
            </div>
          </div>
          <div
            style={{fontWeight: "bold",fontSize:"large"}}>
            
            <div
              style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
              {props.showTextKNA}
            </div>
            <div>
              {props.showLineKNA}
            </div>
          </div>
          <div
            style={{padding:"0px 20px 0px 20px",display: props.showTableDrug}}>
            
            <Table
              data={props.adrList}
              getTrProps={props.getTrPropsAdrTable}
              headers=",ชื่อ, ประเภท, อาการ,ความรุนแรง,ความเป็นไปได้,ข้อความแจ้งเตือน,วันที่,ผู้บันทึก,"
              keys="status,name, adversary_symptom_type,symptoms,severity_name,probability,note,edited,edit_user,button"
              minRows="2"
              showPagination={false}
              style={{height:"200px"}}
              widths="60,auto,auto,auto,auto,auto,auto,auto,auto,230">
            </Table>
            <div
              className="allergy-list-pagination">
              { props.paginationComponent}
            </div>
          </div>
        </div>
        <div
          style={{ padding: "2px"}}>
          
          <div
            style={{fontWeight: "bold",fontSize:"large"}}>
            
            <div
              style={{ display:"flex", justifyContent: "row",marginLeft: "20px",alignItems:"center",color: "#2170BA"}}>
              
              <div>
                {props.angle_down}
              </div>
              <div>
                {props.food_lmage}
              </div>
              <div
                style={{display:"flex", justifyContent: "row",marginLeft: "10px",marginTop: "25px"}}>
                ประวัติการแพ้อาหาร
              </div>
              <div
                style={{ marginTop: "15px",marginLeft: "15px"}}>
                {props.food_status}
              </div>
              <div>
                {props.loadingComponent}
              </div>
              <Button
                basic={true}
                color="black"
                disabled={props.loadingTable}
                name="radioGroup1"
                onClick={props.onClickNKF}
                style={{ marginTop: "15px",marginLeft: "70px",display: props.disabledNKF}}>
                ปฏิเสธแพ้อาหาร
              </Button>
            </div>
            <div
              style={{ padding: "0px"}}>
              
              <div
                style={{ justifyContent: "row",marginLeft: "55px",width: "205px", height: '0.2rem', backgroundColor: '#2170BA'}}>
                
              </div>
            </div>
          </div>
          <div
            style={{fontWeight: "bold",fontSize:"large",color:"#C5C5C5"}}>
            
            <div
              style={{display: 'flex',alignItems: 'center',justifyContent: 'center',marginTop: "30px"}}>
              {props.showTextNoneFood}
            </div>
            <div>
              {props.showLineNoneFood}
            </div>
          </div>
          <div
            style={{fontWeight: "bold",fontSize:"large"}}>
            
            <div
              style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
              {props.showTextKNF}
            </div>
            <div>
              {props.showLineKNF}
            </div>
          </div>
          <div
            style={{padding:"0px 20px 0px 20px",display: props.showTable_Food}}>
            
            <Table
              data={props.adrListFood}
              getTdProps={props.getTrPropsAdrTable}
              headers="รายละเอียดการเเพ้,อาการ,วันที่,ผู้บันทึก,"
              keys="name,note,edited,edit_user,button"
              minRows="2"
              showPagination={false}
              style={{height:"200px"}}
              widths="auto,400,200,200,230">
            </Table>
            <div
              className="allergy-list-pagination">
              {props.paginationComponent}
            </div>
          </div>
        </div>
        <div
          style={{ padding: "2px"}}>
          
          <div
            style={{ fontWeight: "bold",fontSize:"large"}}>
            
            <div
              style={{ display:"flex", justifyContent: "row",marginLeft: "20px",alignItems:"center",color: "#2170BA"}}>
              
              <div>
                {props.angle_down}
              </div>
              <div>
                {props.other_lmage}
              </div>
              <div
                style={{display:"flex", justifyContent: "row",marginLeft: "10px",marginTop: "25px"}}>
                ประวัติการแพ้สารอื่นๆ
              </div>
              <div
                style={{ marginTop: "15px",marginLeft: "15px"}}>
                {props.other_status}
              </div>
              <div>
                {props.loadingComponent}
              </div>
              <Button
                basic={true}
                color="black"
                disabled={props.loadingTable}
                name="radioGroup1"
                onClick={props.onClickNKOA}
                style={{ marginTop: "15px",marginLeft: "70px",display: props.disabledNKO}}>
                ปฏิเสธแพ้สารอื่นๆ
              </Button>
            </div>
            <div
              style={{ padding: "0px"}}>
              
              <div
                style={{ justifyContent: "row",marginLeft: "55px",width: "205px", height: '0.2rem', backgroundColor: '#2170BA'}}>
                
              </div>
            </div>
          </div>
          <div
            style={{fontWeight: "bold",fontSize:"large",color:"#C5C5C5"}}>
            
            <div
              style={{display: 'flex',alignItems: 'center',justifyContent: 'center',marginTop: "30px"}}>
              {props.showTextNoneOther}
            </div>
            <div>
              {props.showLineNoneOther}
            </div>
          </div>
          <div
            style={{fontWeight: "bold",fontSize:"large"}}>
            
            <div
              style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
              {props.showTextKNO}
            </div>
            <div>
              {props.showLineKNO}
            </div>
          </div>
          <div
            style={{padding:"0px 20px 0px 20px",display: props.showTable_other}}>
            
            <Table
              data={props.adrListOther}
              getTrProps={props.getTrPropsAdrTable}
              headers="รายละเอียดการเเพ้,อาการ,วันที่,ผู้บันทึก,"
              keys="name,note,edited,edit_user,button"
              minRows="2"
              showPagination={false}
              style={{height:"200px"}}
              widths="auto,400,200,200,230">
            </Table>
            <div
              className="allergy-list-pagination">
              {props.paginationComponent}
            </div>
          </div>
        </div>
        <div
          style={{ padding: "0px"}}>
          
          <div>
            {props.modalKDA}
          </div>
          <div>
            {props.modalDrug}
          </div>
          <div>
            {props.modalFood}
          </div>
          <div>
            {props.modalOther}
          </div>
          <div>
            {props.modalKFA}
          </div>
          <div>
            {props.modalKOA}
          </div>
        </div>
        <div
          style={{width: "100%", height: "30px"}}>
          
        </div>
      </div>
    )
}

export default CardAllergyUX

export const screenPropsDefault = {}

/* Date Time : Tue Mar 21 2023 03:19:08 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"space-between\",alignItems:\"center\",padding: \"20px 20px 20px 10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"2px\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent: \"row\", alignItems:\"center\",padding: \"10px 0px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มประวัติ ADR"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.loadingTable"
        },
        "icon": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "New"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickNew"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Icon",
      "parent": 6,
      "props": {
        "name": {
          "type": "value",
          "value": "plus"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",fontSize:\"large\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent: \"row\",marginLeft: \"20px\",alignItems:\"center\",color: \"#2170BA\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.angle_down"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drug_lmage"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการแพ้ยา"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent: \"row\",marginLeft: \"10px\",marginTop: \"25px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drug_status"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"15px\",marginLeft: \"15px\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.loadingComponent"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Button",
      "parent": 14,
      "props": {
        "basic": {
          "type": "code",
          "value": "true"
        },
        "children": {
          "type": "value",
          "value": "ปฏิเสธแพ้ยา "
        },
        "color": {
          "type": "value",
          "value": "black"
        },
        "disabled": {
          "type": "code",
          "value": "props.loadingTable"
        },
        "name": {
          "type": "value",
          "value": "radioGroup1"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickNKDA"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"15px\",marginLeft: \"70px\",display: props.disabledNKDA}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ justifyContent: \"row\",marginLeft: \"55px\",width: \"180px\", height: '0.2rem', backgroundColor: '#2170BA'}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",fontSize:\"large\",color:\"#C5C5C5\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",fontSize:\"large\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding:\"0px 20px 0px 20px\",display: props.showTableDrug}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showTextNoneDrug"
        },
        "style": {
          "type": "code",
          "value": "{display: 'flex',alignItems: 'center',justifyContent: 'center',marginTop: \"30px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showTextKNA"
        },
        "style": {
          "type": "code",
          "value": "{display: 'flex',alignItems: 'center',justifyContent: 'center'}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 28,
      "name": "Table",
      "parent": 25,
      "props": {
        "data": {
          "type": "code",
          "value": "props.adrList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrPropsAdrTable"
        },
        "headers": {
          "type": "value",
          "value": ",ชื่อ, ประเภท, อาการ,ความรุนแรง,ความเป็นไปได้,ข้อความแจ้งเตือน,วันที่,ผู้บันทึก,"
        },
        "keys": {
          "type": "value",
          "value": "status,name, adversary_symptom_type,symptoms,severity_name,probability,note,edited,edit_user,button"
        },
        "minRows": {
          "type": "value",
          "value": "2"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"200px\"}"
        },
        "widths": {
          "type": "value",
          "value": "60,auto,auto,auto,auto,auto,auto,auto,auto,230"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "code",
          "value": " props.paginationComponent"
        },
        "className": {
          "type": "value",
          "value": "allergy-list-pagination"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"2px\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",fontSize:\"large\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display:\"flex\", justifyContent: \"row\",marginLeft: \"20px\",alignItems:\"center\",color: \"#2170BA\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "code",
          "value": "props.angle_down"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "code",
          "value": "props.food_lmage"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการแพ้อาหาร"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent: \"row\",marginLeft: \"10px\",marginTop: \"25px\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "code",
          "value": "props.food_status"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"15px\",marginLeft: \"15px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "code",
          "value": "props.loadingComponent"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",fontSize:\"large\",color:\"#C5C5C5\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",fontSize:\"large\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding:\"0px 20px 0px 20px\",display: props.showTable_Food}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showTextNoneFood"
        },
        "style": {
          "type": "code",
          "value": "{display: 'flex',alignItems: 'center',justifyContent: 'center',marginTop: \"30px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showTextKNF"
        },
        "style": {
          "type": "code",
          "value": "{display: 'flex',alignItems: 'center',justifyContent: 'center'}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Button",
      "parent": 32,
      "props": {
        "basic": {
          "type": "code",
          "value": "true"
        },
        "children": {
          "type": "value",
          "value": "ปฏิเสธแพ้อาหาร"
        },
        "color": {
          "type": "value",
          "value": "black"
        },
        "disabled": {
          "type": "code",
          "value": "props.loadingTable"
        },
        "name": {
          "type": "value",
          "value": "radioGroup1"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickNKF"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"15px\",marginLeft: \"70px\",display: props.disabledNKF}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ justifyContent: \"row\",marginLeft: \"55px\",width: \"205px\", height: '0.2rem', backgroundColor: '#2170BA'}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 46,
      "name": "Table",
      "parent": 41,
      "props": {
        "data": {
          "type": "code",
          "value": "props.adrListFood"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTrPropsAdrTable"
        },
        "headers": {
          "type": "value",
          "value": "รายละเอียดการเเพ้,อาการ,วันที่,ผู้บันทึก,"
        },
        "keys": {
          "type": "value",
          "value": "name,note,edited,edit_user,button"
        },
        "minRows": {
          "type": "value",
          "value": "2"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"200px\"}"
        },
        "widths": {
          "type": "value",
          "value": "auto,400,200,200,230"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "code",
          "value": "props.paginationComponent"
        },
        "className": {
          "type": "value",
          "value": "allergy-list-pagination"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"2px\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",fontSize:\"large\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display:\"flex\", justifyContent: \"row\",marginLeft: \"20px\",alignItems:\"center\",color: \"#2170BA\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "code",
          "value": "props.angle_down"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "code",
          "value": "props.other_lmage"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการแพ้สารอื่นๆ"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent: \"row\",marginLeft: \"10px\",marginTop: \"25px\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "code",
          "value": "props.other_status"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"15px\",marginLeft: \"15px\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "code",
          "value": "props.loadingComponent"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",fontSize:\"large\",color:\"#C5C5C5\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",fontSize:\"large\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding:\"0px 20px 0px 20px\",display: props.showTable_other}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 57,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showTextNoneOther"
        },
        "style": {
          "type": "code",
          "value": "{display: 'flex',alignItems: 'center',justifyContent: 'center',marginTop: \"30px\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showTextKNO"
        },
        "style": {
          "type": "code",
          "value": "{display: 'flex',alignItems: 'center',justifyContent: 'center'}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "code",
          "value": "props.paginationComponent"
        },
        "className": {
          "type": "value",
          "value": "allergy-list-pagination"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ justifyContent: \"row\",marginLeft: \"55px\",width: \"205px\", height: '0.2rem', backgroundColor: '#2170BA'}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Button",
      "parent": 50,
      "props": {
        "basic": {
          "type": "code",
          "value": "true"
        },
        "children": {
          "type": "value",
          "value": "ปฏิเสธแพ้สารอื่นๆ"
        },
        "color": {
          "type": "value",
          "value": "black"
        },
        "disabled": {
          "type": "code",
          "value": "props.loadingTable"
        },
        "name": {
          "type": "value",
          "value": "radioGroup1"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickNKOA"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"15px\",marginLeft: \"70px\",display: props.disabledNKO}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 65,
      "name": "Table",
      "parent": 59,
      "props": {
        "data": {
          "type": "code",
          "value": "props.adrListOther"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrPropsAdrTable"
        },
        "headers": {
          "type": "value",
          "value": "รายละเอียดการเเพ้,อาการ,วันที่,ผู้บันทึก,"
        },
        "keys": {
          "type": "value",
          "value": "name,note,edited,edit_user,button"
        },
        "minRows": {
          "type": "value",
          "value": "2"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"200px\"}"
        },
        "widths": {
          "type": "value",
          "value": "auto,400,200,200,230"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"30px\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalKDA"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalDrug"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalFood"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalOther"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showLineNoneDrug"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showLineKNA"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showLineNoneFood"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showLineKNF"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 57,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showLineNoneOther"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showLineKNO"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent:\"flex-end\",padding: \"0px 20px 0px 30px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Checkbox",
      "parent": 78,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.inactive"
        },
        "label": {
          "type": "value",
          "value": "เเสดงประวัติ Inactived"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeInactive"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalKFA"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "code",
          "value": "props.modalKOA"
        }
      },
      "seq": 81,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardAllergyUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
