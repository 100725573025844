import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Dropdown
} from 'semantic-ui-react'

const CardSurgicalPositionUX  = (props: any) => {
    return(
      <div
        style={{ margin: "15px",  padding: "20px",  backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", fontSize: "22px" }}>
            Surgical Position & Position AID (A)
          </label>
          <Label
            color={props.statusColor}
            style={{ float: "right" }}>
            {props.status}
          </Label>
          <hr>
          </hr>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              Position 
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Dropdown
            multiple={true}
            name="position"
            onChange={props.handleChangeData}
            options={props.masterOptions?.orSurgicalPosition}
            search={true}
            selection={true}
            style={{ width: "50%" }}
            value={props.position}>
          </Dropdown>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              Positioning AID 
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Dropdown
            multiple={true}
            name="device"
            onChange={props.handleChangeData}
            options={props.masterOptions?.orSurgicalDevice}
            search={true}
            selection={true}
            style={{ width: "50%" }}
            value={props.device}>
          </Dropdown>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              Warmer
            </label>
          </div>
          <Dropdown
            multiple={true}
            name="warmer"
            onChange={props.handleChangeData}
            options={props.masterOptions?.orSurgicalWarmer}
            search={true}
            selection={true}
            style={{ width: "50%" }}
            value={props.warmer}>
          </Dropdown>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  width: "25%",  margin: "5px 10px 0px 0px", }}>
            
            <label>
              Circulatory Support
            </label>
          </div>
          <Dropdown
            multiple={true}
            name="circulator_support"
            onChange={props.handleChangeData}
            options={props.masterOptions?.orSurgicalCirculatory}
            search={true}
            selection={true}
            style={{ width: "50%" }}
            value={props.circulatorSupport}>
          </Dropdown>
        </div>
        <div
          style={{ backgroundColor: "#FDF8DD", padding: "30px", margin: "30px 0px" }}>
          
          <div
            style={{ display: "flex", paddingBottom: "5px" }}>
            
            <label
              style={{  fontWeight: "bold",   width: "15%",  margin: "5px 10px 0px 0px" }}>
              Nursiong Diagnosis
            </label>
            <label
              style={{ width: "30%",  margin: "5px 10px 0px 0px" }}>
              {props.nurseDiagnosis}
            </label>
            <label
              style={{  fontWeight: "bold",   width: "10%",  margin: "5px 10px 0px 0px" }}>
              Goal
            </label>
            <label
              style={{ width: "25%",  margin: "5px 10px 0px 0px" }}>
              {props.goal}
            </label>
          </div>
          <div
            style={{ display: "flex", paddingBottom: "5px" }}>
            
            <label
              style={{  fontWeight: "bold",   width: "20%",  margin: "5px 10px 0px 0px" }}>
              Plan and implementation
            </label>
            <Dropdown
              clearable={true}
              name="plan_and_implementation"
              onChange={props.handleChangeData}
              options={props.masterOptions?.orPlanAndImple}
              search={true}
              selection={true}
              style={{ width: "30%",  margin: "5px 10px 0px 0px" }}
              value={props.planAndImplementation}>
            </Dropdown>
          </div>
        </div>
        <div
          style={{ height: "55px", paddingTop: "15px" }}>
          
          <div
            style={{display:"flex",justifyContent:"flex-end"}}>
            
            <div
              style={{margin:"10px"}}>
              {props.buttonSave}
            </div>
            <div
              style={{margin:"10px"}}>
              {props.buttonConfirm}
            </div>
            <div
              style={{margin:"10px"}}>
              {props.buttonUnconfirm}
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardSurgicalPositionUX 

export const screenPropsDefault = {}

/* Date Time : Wed Sep 14 2022 14:19:05 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\",  padding: \"20px\",  backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Surgical Position & Position AID (A)"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"22px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        },
        "color": {
          "type": "code",
          "value": "props.statusColor"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "Position "
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Dropdown",
      "parent": 68,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "position"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orSurgicalPosition"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.position"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 74,
      "props": {
        "children": {
          "type": "value",
          "value": "Positioning AID "
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "label",
      "parent": 74,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Dropdown",
      "parent": 73,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "device"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orSurgicalDevice"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.device"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "label",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": "Warmer"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Dropdown",
      "parent": 78,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "warmer"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orSurgicalWarmer"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.warmer"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  width: \"25%\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "label",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "Circulatory Support"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Dropdown",
      "parent": 83,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "circulator_support"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orSurgicalCirculatory"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.circulatorSupport"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#FDF8DD\", padding: \"30px\", margin: \"30px 0px\" }"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": "Nursiong Diagnosis"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"15%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nurseDiagnosis"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": "Goal"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"10%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "code",
          "value": "props.goal"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"25%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "Dropdown",
      "parent": 145,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "plan_and_implementation"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orPlanAndImple"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\",  margin: \"5px 10px 0px 0px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.planAndImplementation"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\" }"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"flex-end\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan and implementation"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\",   width: \"20%\",  margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 162,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardSurgicalPositionUX ",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
