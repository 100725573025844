import BaseService from "./BaseService";
import { INF } from "../../configs/apis";
export default class INFService extends BaseService {
  /**
   * Sent claim choice
   * @param {*} params
   */
  getSentClaimChoices(params) {
    return this.client
      .get(INF.SENT_CLAIM_CHOICE, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  postCreateARTransaction(data, params) {
    return this.client
      .post(INF.CREATE_AR_TRANSACTION, data, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  getARTransaction(params) {
    return this.client
      .get(INF.AR_TRANSACTION_LIST, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  getCoveragePayerSentClaimGroup(params) {
    return this.client
      .get(INF.GET_COVERAGE_PAYER_SENT_CLAIM_GROUP, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  getBillTransactionIPD(params) {
    return this.client
      .get(INF.BILL_TRANSACTION_IPD, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  getBillTransactionIPDSummary(params) {
    return this.client
      .get(INF.BILL_TRANSACTION_IPD_SUMMARY, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  getBillTransactionIPDWithId(transactionId) {
    var url = INF.BILL_TRANSACTION_IPD_ID({
      bill_transaction_ipd_id: transactionId
    });
    return this.client
      .get(url)
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  patchBillTransactionIPDWithId(transactionId, data) {
    var url = INF.BILL_TRANSACTION_IPD_ID({
      bill_transaction_ipd_id: transactionId
    });
    return this.client
      .patch(url, data)
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  putBillTransaction(transactionId, data) {
    var url = INF.BILL_TRANSACTION_DETAIL({
      bill_transaction_id: transactionId
    });
    return this.client
      .put(url, data)
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  putBillTransactionUpdateStatus(transactionId, data) {
    var url = INF.BILL_TRANSACTION_UPDATE_STATUS({
      bill_transaction_id: transactionId
    });
    return this.client
      .put(url, data)
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  getIPDDiagnosisSentClaim(params) {
    return this.client
      .get(INF.IPD_DIAGNOSIS_SENT_CLAIM, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  getIPDProcedureSentClaim(params) {
    return this.client
      .get(INF.IPD_PROCEDURE_SENT_CLAIM, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  getBillTransactionIPDItem(params) {
    return this.client
      .get(INF.BILL_TRANSACTION_IPD_ITEM, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  doGenerateSentClaimDataIPD(coverageGroupId, startDate, endDate) {
    var url = INF.GENERATE_SENT_CLAIM_DATA_IPD({
      coverage_group_id: coverageGroupId,
      start_date: startDate,
      end_date: endDate
    });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  arTaskTrackingAction(action, params) {
    var url = INF.AR_TASK_TRACKING_ACTION({ action: action });
    return this.client
      .get(url, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  getUpdateSentClaimDataIPDFromARId(transactionId) {
    var url = INF.UPDATE_SENT_CLAIM_DATA_IPD_FROM_AR_ID({
      ar_transaction_id: transactionId
    });
    return this.client
      .get(url)
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  getGenerateSentClaimIPDFileFromARId(transactionId, params) {
    var url = INF.GENERATE_SENT_CLAIM_IPD_FILE_FROM_AR_ID({
      ar_transaction_id: transactionId
    });
    return this.client
      .get(url, { params, responseType: "blob" })
      .then(response => response)
      .catch(this.throwErrorMessage);
  }
  putImportResponseFileIPD(data) {
    return this.client
      .put(INF.IMPORT_RESPONSE_FILE_IPD, data)
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  getSentClaimTransactionIPD(params) {
    return this.client
      .get(INF.SENT_CLAIM_TRANSACTION_IPD, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  getEncounterFromARId(transactionId) {
    var url = INF.ENCOUNTER_FROM_AR_ID({ ar_transaction_id: transactionId });
    return this.client
      .get(url)
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  postImportARLABCatalogMasterData(data){
    // this.client.defaults.headers["Content-Type"] = "multipart/form-data"
    return this.client
    .post(INF.IMPORT_AR_LAB_CATALOG_MASTER_DATA, data)
    .then(response => response.data)
    .catch(this.throwErrorMessage);
  }
}
