import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Input,
  Dropdown,
  Icon,
  Checkbox,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardSettingPackageUX = (props: any) => {
    return(
      <div
        style={{width: "100%"}}>
        <div
          style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "15px 10px", backgroundColor: "#A5D9FF"}}>
          
          <div
            style={{fontSize: "1.2rem", fontWeight: "bold"}}>
            การตั้งค่ารายละเอียดแพกเกจ
          </div>
        </div>
        <div
          className="ui divider"
          style={{marginTop: 0}}>
          
        </div>
        <div>
          {props.SubSearchPackage}
        </div>
        <div
          className={`ui form ${props.isActive  ? "--readOnly": ""}`}
          style={{padding: "0 20px", display: !props.hideDetail ? "": "none"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", alignItems: "center", width: "200px"}}>
                
                <label
                  style={{fontWeight: "bold", marginRight: "5px"}}>
                  Code
                </label>
                <label>
                  รหัสแพกเกจ
                </label>
              </div>
            </FormField>
            <FormField
              className={!!props.showRequiredField?.code ? "error" : ""}
              inline={true}
              width={4}>
              <Input
                disabled={props.isActive || false}
                name="code"
                onChange={props.onChangeValue}
                value={props.packageDetail?.code || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", alignItems: "center", width: "200px"}}>
                {}
                <label
                  style={{fontWeight: "bold", marginRight: "5px"}}>
                  Package name
                </label>
                <label>
                  ชื่อแพกเกจ
                </label>
              </div>
            </FormField>
            <FormField
              className={!!props.showRequiredField?.name ? "error" : ""}
              inline={true}
              width={6}>
              <Input
                disabled={props.isActive || false}
                name="name"
                onChange={props.onChangeValue}
                value={props.packageDetail?.name|| ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", alignItems: "center", width: "200px", marginTop: "0.75rem"}}>
                {}
                <label
                  style={{fontWeight: "bold", marginRight: "5px"}}>
                  Package type
                </label>
                <label>
                  ชนิดแพกเกจ
                </label>
              </div>
            </FormField>
            <FormField
              className={!!props.showRequiredField?.package_type ? "error" : ""}
              inline={true}
              width={4}>
              <div
                style={{display: "flex",  alignItems: "baseline",}}>
                
                <Dropdown
                  clearable={true}
                  disabled={props.isActive || false}
                  name="package_type"
                  onChange={props.onChangeValue}
                  options={props.packageTypeOptions || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.packageDetail?.package_type || ""}>
                </Dropdown>
                <div
                  style={{width: "fit-content", display: "flex", margin: "auto -7rem auto 1rem",cursor: "pointer", pointerEvents: props.isActive? "none": ""}}>
                  
                  <Icon
                    className="teal"
                    link={true}
                    name="plus"
                    onClick={()=> props.onOpenAdmin?.("PACKAGE_TYPE")}>
                  </Icon>
                  <div
                    style={{marginLeft: "0.75rem"}}>
                    {props.PackageRefreshIcon}
                  </div>
                </div>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", alignItems: "center", width: "200px"}}>
                
                <label
                  style={{fontWeight: "bold", marginRight: "5px"}}>
                  Service type
                </label>
                <label>
                  ประเภทบริการ
                </label>
              </div>
            </FormField>
            <FormField
              className={!!props.showRequiredField?.package_service_type ? "error" : ""}
              inline={true}
              style={{paddingRight: "0.75em"}}
              width={4}>
              <div
                style={{display: "flex",  alignItems: "baseline",width: "100%"}}>
                
                <Dropdown
                  clearable={true}
                  disabled={props.isActive || false}
                  name="package_service_type"
                  onChange={props.onChangeValue}
                  options={props.serviceTypeOptions || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.packageDetail?.package_service_type || ""}>
                </Dropdown>
                <div
                  style={{width: "fit-content", display: "flex", margin: "auto -7rem auto 1rem",cursor: "pointer", pointerEvents: props.isActive? "none": ""}}>
                  
                  <Icon
                    className="teal"
                    link={true}
                    name="plus"
                    onClick={()=> props.onOpenAdmin?.("SERVICE_TYPE")}>
                  </Icon>
                  <div
                    style={{marginLeft: "0.75rem"}}>
                    {props.ServiceRefreshIcon}
                  </div>
                </div>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", alignItems: "center", width: "200px"}}>
                
                <label
                  style={{fontWeight: "bold", marginRight: "5px"}}>
                  Gender:
                </label>
              </div>
            </FormField>
            <FormField
              className={!!props.showRequiredField?.gender ? "error" : ""}
              inline={true}
              width={2}>
              <Dropdown
                disabled={props.isActive || false}
                fluid={true}
                name="gender"
                onChange={props.onChangeValue}
                options={props.genderOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.packageDetail?.gender || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", alignItems: "center", width: "200px"}}>
                
                <label
                  style={{fontWeight: "bold", marginRight: "5px"}}>
                  Age start
                </label>
                <label>
                  อายุ (ปี)
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                disabled={props.isActive || false}
                fluid={true}
                name="age_limits_year_start"
                onChange={props.onChangeValue}
                onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
                ref={props.inputRef}
                style={{width: "100%"}}
                type="number"
                value={props.packageDetail?.age_limits_year_start ?? ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%"}}>
                
                <label
                  style={{fontWeight: "bold", marginRight: "5px"}}>
                  Age end
                </label>
                <label>
                  อายุ (ปี)
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                disabled={props.isActive || false}
                fluid={true}
                name="age_limits_year_end"
                onChange={props.onChangeValue}
                onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
                ref={props.inputRef}
                style={{width: "100%"}}
                type="number"
                value={props.packageDetail?.age_limits_year_end ?? ""}>
              </Input>
            </FormField>
            <FormField
              style={{display: props.showAgeError ? "": "none"}}>
              <label
                style={{color: "red", fontWeight: "normal"}}>
                Age end ต้องไม่น้อยกว่า Age start
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", alignItems: "center", width: "200px"}}>
                
                <label
                  style={{fontWeight: "bold", marginRight: "5px"}}>
                  Start date
                </label>
              </div>
            </FormField>
            <FormField
              className={!!props.showRequiredField?.start_date? "error" : ""}
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.StartDate}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", alignItems: "center", width: "200px"}}>
                
                <label
                  style={{fontWeight: "bold", marginRight: "5px"}}>
                  End date
                </label>
              </div>
            </FormField>
            <FormField
              className={!!props.showRequiredField?.end_date? "error" : ""}
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.EndDate}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <div
                style={{display: "flex", alignItems: "center", width: "100%", justifyContent: "flex-end"}}>
                
                <label
                  style={{fontWeight: "bold", marginRight: "1rem"}}>
                  IsActive
                </label>
                <Checkbox
                  checked={props.packageDetail?.active || ""}
                  disabled={true}
                  name="active"
                  readOnly={true}
                  style={{transform: "scale(1.2)"}}>
                </Checkbox>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{display: "grid", alignItems: "center", width: "200px"}}>
                
                <label
                  style={{fontWeight: "bold", marginRight: "5px"}}>
                  Package pricing
                </label>
                <label>
                  ราคาขายของสินค้านี้
                </label>
              </div>
            </FormField>
            <FormField
              className={!!props.showRequiredField?.selling_price? "error" : ""}
              inline={true}
              width={2}>
              <Input
                disabled={props.isActive || false}
                fluid={true}
                name="selling_price"
                onChange={props.onChangeValue}
                onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
                ref={props.inputRef}
                style={{width: "100%"}}
                type="number"
                value={props.packageDetail?.selling_price|| ""}>
              </Input>
            </FormField>
            <FormField>
              <label
                style={{fontWeight: "normal"}}>
                bath
              </label>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{display: !props.hideDetail ?props.isNewProduct ? "none": "flex" : "none" , justifyContent: "flex-end", margin: "1rem 20px"}}>
          
          <Button
            disabled={props.disabledAddNewProduct || false}
            onClick={props.onAddNewProduct}
            style={{backgroundColor: "#5CD6F2", color: "white"}}>
            <div
              style={{display: "flex", alignItems: "center"}}>
              
              <Icon
                name="plus">
              </Icon>
              <label>
                เพิ่ม รายการใหม่
              </label>
            </div>
          </Button>
        </div>
        <div
          className="ui form"
          style={{padding: "0 20px", display: !props.hideDetail ? props.isNewProduct ? "": "none":"none"}}>
          
          <FormGroup
            inline={true}>
            <FormField>
              <label
                style={{color: "red"}}>
                {`ค้นหารายการสินค้าจาก ${props.productType}`}
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                Product Code
              </label>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <div
                style={{width: "100%"}}>
                {props.SearchBoxPCode}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                Product Name
              </label>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <div
                style={{width: "100%"}}>
                {props.SearchBoxPName}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="green"
                onClick={props.onAddSuccess}
                style={{minWidth: "max-content"}}>
                เสร็จสิ้น
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form"
          style={{padding: "0 20px", display: !props.hideDetail ? "":"none"}}>
          {props.TabPans}
        </div>
        <div
          className="ui form"
          style={{padding: "0 20px", display: !props.hideDetail ? "":"none"}}>
          {}
          <Table
            data={props.productItems || []}
            getTrProps={props.getTrProps}
            headers="ID,Product code,Product Name,ชื่อสินค้า,QTY,Price,Display,Delete"
            keys="product, product_code, product_name_en, product_name,quantity,price, is_display,delete"
            showPagination={false}
            style={{height: "275px", marginTop: "1rem"}}
            widths="100,,,,100,150,100,100">
          </Table>
        </div>
        <div
          style={{ borderRadius: "5px", padding: "8px 0rem 8px 15px", margin: "1rem 20px 0rem", display: !props.hideDetail ? props.showPriceTotalProduct? "flex":"none":"none", justifyContent: "space-between", width: "calc(100vw - 28.5rem)"}}>
          
          <div
            style={{fontWeight: "bold", color: "#0072BC"}}>
            {`ราคาของ ${props.productType || ""} ทั้งหมด (บาท)`}
          </div>
          <div
            style={{width: "150px", display: "flex", justifyContent: "center"}}>
            
            <div
              style={{color: "#5A5A5A"}}>
              {props.priceTotalProduct || ""}
            </div>
          </div>
        </div>
        <div
          style={{background: "rgba(166, 238, 255, 0.32)", borderRadius: "5px", padding: "8px 0rem 8px 15px", margin: props.showPriceTotalProduct ?"0.5rem 20px 1.5rem":"2rem 20px 1.5rem", display: !props.hideDetail ? "flex":"none", justifyContent: "space-between", width: "calc(100vw - 28.5rem)"}}>
          
          <div
            style={{fontWeight: "bold", color: "#0072BC"}}>
            ราคาของสินค้าทั้งหมดของแพกเกจ  (บาท)
          </div>
          <div
            style={{width: "150px", display: "flex", justifyContent: "center"}}>
            
            <div
              style={{color: "#5A5A5A"}}>
              {props.priceTotal || ""}
            </div>
          </div>
        </div>
        <div
          className="ui form"
          style={{display: !props.hideDetail ? "flex":"none", alignItems: "center", justifyContent: "flex-end", padding: "0 20px"}}>
          
          <div>
            {props.ButtonSave}
          </div>
          <div>
            {props.ButtonActive}
          </div>
          <Button
            color="purple"
            disabled={!props.packageDetail?.id}
            onClick={props.onClickDuplicate}>
            DUPLICATE
          </Button>
        </div>
      </div>
    )
}


export default CardSettingPackageUX

export const screenPropsDefault = {"current":null,"price_claimable":0,"price_non_claimable":0,"price_total":0}

/* Date Time : Tue Jul 11 2023 09:16:57 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\", padding: \"15px 10px\", backgroundColor: \"#A5D9FF\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "การตั้งค่ารายละเอียดแพกเกจ"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: 0}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SubSearchPackage"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "code",
          "value": "`ui form ${props.isActive  ? \"--readOnly\": \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 20px\", display: !props.hideDetail ? \"\": \"none\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 6,
      "props": {
        "className": {
          "type": "code",
          "value": "!!props.showRequiredField?.code ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Input",
      "parent": 8,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.isActive || false"
        },
        "name": {
          "type": "value",
          "value": "code"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "ref": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.packageDetail?.code || \"\""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"200px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "Code"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginRight: \"5px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสแพกเกจ"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormField",
      "parent": 16,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 16,
      "props": {
        "className": {
          "type": "code",
          "value": "!!props.showRequiredField?.name ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Input",
      "parent": 18,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.isActive || false"
        },
        "name": {
          "type": "value",
          "value": "name"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "ref": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.packageDetail?.name|| \"\""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"200px\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "Package name"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginRight: \"5px\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อแพกเกจ"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 23,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 24,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 25,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"200px\", marginTop: \"0.75rem\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"200px\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"200px\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "Package type"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginRight: \"5px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "ชนิดแพกเกจ"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "Service type"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginRight: \"5px\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทบริการ"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "Gender:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginRight: \"5px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 23,
      "props": {
        "className": {
          "type": "code",
          "value": "!!props.showRequiredField?.package_type ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"baseline\",}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Dropdown",
      "parent": 38,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.isActive || false"
        },
        "name": {
          "type": "value",
          "value": "package_type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.packageTypeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.packageDetail?.package_type || \"\""
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"fit-content\", display: \"flex\", margin: \"auto -7rem auto 1rem\",cursor: \"pointer\", pointerEvents: props.isActive? \"none\": \"\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Icon",
      "parent": 40,
      "props": {
        "className": {
          "type": "value",
          "value": "teal"
        },
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "plus"
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onOpenAdmin?.(\"PACKAGE_TYPE\")"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 24,
      "props": {
        "className": {
          "type": "code",
          "value": "!!props.showRequiredField?.package_service_type ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: \"0.75em\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"baseline\",width: \"100%\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Dropdown",
      "parent": 43,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.isActive || false"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "package_service_type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.serviceTypeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.packageDetail?.package_service_type || \"\""
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"fit-content\", display: \"flex\", margin: \"auto -7rem auto 1rem\",cursor: \"pointer\", pointerEvents: props.isActive? \"none\": \"\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Icon",
      "parent": 47,
      "props": {
        "className": {
          "type": "value",
          "value": "teal"
        },
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "plus"
        },
        "onClick": {
          "type": "code",
          "value": "()=> props.onOpenAdmin?.(\"SERVICE_TYPE\")"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 25,
      "props": {
        "className": {
          "type": "code",
          "value": "!!props.showRequiredField?.gender ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Dropdown",
      "parent": 49,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "defaultValue": {
          "type": "value",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.isActive || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "gender"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.genderOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.packageDetail?.gender || \"\""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 51,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"200px\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "label",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "Age start"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginRight: \"5px\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "label",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "อายุ (ปี)"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Input",
      "parent": 53,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.isActive || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "age_limits_year_start"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "ref": {
          "type": "code",
          "value": "props.inputRef"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.packageDetail?.age_limits_year_start ?? \"\""
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormField",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"flex-end\", width: \"100%\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "label",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "Age end"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginRight: \"5px\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "label",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "อายุ (ปี)"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Input",
      "parent": 59,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.isActive || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "age_limits_year_end"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "ref": {
          "type": "code",
          "value": "props.inputRef"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.packageDetail?.age_limits_year_end ?? \"\""
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 64,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 64,
      "props": {
        "className": {
          "type": "code",
          "value": "!!props.showRequiredField?.start_date? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"200px\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "Start date"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginRight: \"5px\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "code",
          "value": "props.StartDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormField",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 73,
      "props": {
        "className": {
          "type": "code",
          "value": "!!props.showRequiredField?.end_date? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 74,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"200px\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": "End date"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginRight: \"5px\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 75,
      "props": {
        "children": {
          "type": "code",
          "value": "props.EndDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormField",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"100%\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "label",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": "IsActive"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginRight: \"1rem\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Checkbox",
      "parent": 80,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.packageDetail?.active || \"\""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "active"
        },
        "onChange": {
          "type": "code",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{transform: \"scale(1.2)\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "FormField",
      "parent": 83,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 83,
      "props": {
        "className": {
          "type": "code",
          "value": "!!props.showRequiredField?.selling_price? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", alignItems: \"center\", width: \"200px\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "label",
      "parent": 86,
      "props": {
        "children": {
          "type": "value",
          "value": "Package pricing"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginRight: \"5px\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "label",
      "parent": 86,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคาขายของสินค้านี้"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "Input",
      "parent": 85,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.isActive || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "selling_price"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "ref": {
          "type": "code",
          "value": "props.inputRef"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.packageDetail?.selling_price|| \"\""
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "FormField",
      "parent": 83,
      "props": {
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "label",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": "bath"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: !props.hideDetail ?props.isNewProduct ? \"none\": \"flex\" : \"none\" , justifyContent: \"flex-end\", margin: \"1rem 20px\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "Button",
      "parent": 92,
      "props": {
        "color": {
          "type": "value",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledAddNewProduct || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddNewProduct"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#5CD6F2\", color: \"white\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "Icon",
      "parent": 94,
      "props": {
        "name": {
          "type": "value",
          "value": "plus"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "label",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่ม รายการใหม่"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 20px\", display: !props.hideDetail ? \"\":\"none\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.TabPans"
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 20px\", display: !props.hideDetail ? \"\":\"none\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 100,
      "name": "Table",
      "parent": 98,
      "props": {
        "data": {
          "type": "code",
          "value": "props.productItems || []"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "ID,Product code,Product Name,ชื่อสินค้า,QTY,Price,Display,Delete"
        },
        "keys": {
          "type": "value",
          "value": "product, product_code, product_name_en, product_name,quantity,price, is_display,delete"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"275px\", marginTop: \"1rem\"}"
        },
        "widths": {
          "type": "value",
          "value": "100,,,,100,150,100,100"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{background: \"rgba(166, 238, 255, 0.32)\", borderRadius: \"5px\", padding: \"8px 0rem 8px 15px\", margin: props.showPriceTotalProduct ?\"0.5rem 20px 1.5rem\":\"2rem 20px 1.5rem\", display: !props.hideDetail ? \"flex\":\"none\", justifyContent: \"space-between\", width: \"calc(100vw - 28.5rem)\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคาของสินค้าทั้งหมดของแพกเกจ  (บาท)"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", color: \"#0072BC\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: !props.hideDetail ? \"flex\":\"none\", alignItems: \"center\", justifyContent: \"flex-end\", padding: \"0 20px\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Button",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "DUPLICATE"
        },
        "color": {
          "type": "value",
          "value": "purple"
        },
        "disabled": {
          "type": "code",
          "value": "!props.packageDetail?.id"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickDuplicate"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 20px\", display: !props.hideDetail ? props.isNewProduct ? \"\": \"none\":\"none\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormGroup",
      "parent": 108,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "FormGroup",
      "parent": 108,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "FormField",
      "parent": 109,
      "props": {
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "label",
      "parent": 111,
      "props": {
        "children": {
          "type": "code",
          "value": "`ค้นหารายการสินค้าจาก ${props.productType}`"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "FormField",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "FormField",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "FormField",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormField",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "FormField",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "label",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": "Product Code"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "label",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": "Product Name"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 114,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBoxPName"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 116,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBoxPCode"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "Button",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": "เสร็จสิ้น"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddSuccess"
        },
        "size": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ borderRadius: \"5px\", padding: \"8px 0rem 8px 15px\", margin: \"1rem 20px 0rem\", display: !props.hideDetail ? props.showPriceTotalProduct? \"flex\":\"none\":\"none\", justifyContent: \"space-between\", width: \"calc(100vw - 28.5rem)\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "code",
          "value": "`ราคาของ ${props.productType || \"\"} ทั้งหมด (บาท)`"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", color: \"#0072BC\"}"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 103,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 103,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonActive"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"150px\", display: \"flex\", justifyContent: \"center\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"150px\", display: \"flex\", justifyContent: \"center\"}"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 129,
      "props": {
        "children": {
          "type": "code",
          "value": "props.priceTotalProduct || \"\""
        },
        "style": {
          "type": "code",
          "value": "{color: \"#5A5A5A\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 130,
      "props": {
        "children": {
          "type": "code",
          "value": "props.priceTotal || \"\""
        },
        "style": {
          "type": "code",
          "value": "{color: \"#5A5A5A\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PackageRefreshIcon"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.75rem\"}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ServiceRefreshIcon"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.75rem\"}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormField",
      "parent": 51,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: props.showAgeError ? \"\": \"none\"}"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "label",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": "Age end ต้องไม่น้อยกว่า Age start"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", fontWeight: \"normal\"}"
        }
      },
      "seq": 138,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 95,
  "isMounted": false,
  "memo": false,
  "name": "CardSettingPackageUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "current": null,
    "price_claimable": 0,
    "price_non_claimable": 0,
    "price_total": 0
  },
  "width": 55
}

*********************************************************************************** */
