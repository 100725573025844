import WasmController from "react-lib/frameworks/WasmController";

import PatientList from "issara-sdk/apis/PatientList_apps_REG";
import NationalityList from "issara-sdk/apis/NationalityList_core";
import PatientOldNameList from "issara-sdk/apis/PatientOldNameList_apps_REG";
import { formatDropdown } from "react-lib/utils";
import PatientDetailViewByHn from "issara-sdk/apis/PatientDetailViewByHn_apps_REG";
import { param } from "jquery";

export type State = {};

export const StateInitial: State = {};

export type Event =
  | { message: "getPatientList"; params: any }
  | { message: "getNatinalities"; params: any }
  | { message: "choiceNationalities"; params: any }
  | { message: "getPatientOldName"; params: any }
  | { message: "getPatientByHN"; params: any }
  | { message: "searchPatients"; params: any }
  | { message: "loadPatientOldName"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const getPatientList: Handler = async (controller, params) => {
  console.log("getPatientList");
  var patientList = [];
  var numberOfPage = null;
  let limit = 40;

  const [data, error] = await PatientList.list({
    apiToken: controller.apiToken,
    params: {
      ...(params.hn && { hn: params.hn }),
      ...(params.fullName && { name_search: params.fullName }),
      ...(params.citizenId && { citizen_passport: params.citizenId }),
      ...(params.nationality && { nationality: params.nationality }),
      ...(params.startAge && { start_age: params.startAge }),
      ...(params.endAge && { end_age: params.endAge }),
      ...(params.noEncounter && { no_encounter: params.noEncounter }),
      ...(params.mergeFile && { merge_file: params.mergeFile }),
      ...(params.includeCancel && { include_cancel: params.includeCancel }),
      ...(params.isEmergency && { is_emergency: params.isEmergency }),
      ...(params.birthdate && { birthdate: params.birthdate }),
      ...(params.showADBirthDate && { show_ad_birthdate: params.showADBirthDate }),
      is_old_name_search: true,
      // ...(params.is_old_name_search && { is_old_name_search: params.is_old_name_search }),
      ...(params.offset && { offset: (params.patientCurrentPage - 1) * limit }),
      ...(params.limit && { limit }),
    },
  });

  if (data) {
    patientList = data.items;
    numberOfPage = Math.ceil(data.total / params.limit);
  }
  return [patientList, error, numberOfPage];
};

export const getNatinalities: Handler = async (controller, params) => {
  // console.log('getNatinalities: ', controller, params);

  let list = null;
  const [data, error, network] = await NationalityList.list({
    apiToken: controller.apiToken,
    params: { limit: 99999 },
  });

  if (data?.items) {
    let nationalitiesList = formatDropdown(data?.items, "full_name", "id");
    list = nationalitiesList.map((item) => {
      delete item.is_active;
      return item;
    });
  }
  // console.log("list", list)
  return [list, error];
};

export const choiceNationalities: Handler = async (controller, params) => {
  // console.log("choiceNationalities")
  var choices = [];
  const [dataList, error, network] = await NationalityList.list({
    apiToken: controller.apiToken,
    params: { limit: 99999 },
  });

  if (dataList?.items) {
    let nationalitiesList = formatDropdown(dataList?.items, "full_name", "id");
    choices = nationalitiesList.map((item) => {
      delete item.is_active;
      return item;
    });
  }
  console.log("choices", choices);
  return choices;
};

// same as loadPatientOldName, getPatientOldName
export const getPatientOldName: Handler = async (controller, params) => {
  console.log("getPatientOldName");

  const [data, error] = await PatientOldNameList.list({
    apiToken: controller.apiToken,
    patient_id: params.patientId,
  });

  return [data?.items, error];
};

export const getPatientByHN: Handler = async (controller, params) => {
  console.log("getPatientOldName");
  return await PatientDetailViewByHn.get({
    apiToken: controller.apiToken,
    hn: typeof params === "string" ? params : params.hn,
  });
};

export const searchPatients: Handler = async (controller, params) => {
  console.log("searchPatients");

  var patientList = [];
  var patientError = null;
  var numberOfPage = 0;
  let limit = 40;

  const [data, error, network] = PatientList.list({
    apiToken: controller.apiToken,
    params: {
      ...(params.hn && { hn: params.hn }),
      ...(params.includeCancel && { name_search: params.includeCancel }),
      ...(params.includeCancel && { citizen_passport: params.includeCancel }),
      ...(params.nationality && { nationality: params.nationality }),
      is_old_name_search: true,
      ...(params.offset && { offset: (params.patientCurrentPage - 1) * limit }),
      ...(params.limit && { limit }),
    },
  });

  if (data) {
    patientList = data.items;
    numberOfPage = Math.ceil(data.total / params.limit);
  } else {
    patientError = error;
  }

  return [patientList, patientError, numberOfPage];
};

export const loadPatientOldName: Handler = async (controller, params) => {
  console.log("loadPatientOldName");
  const [data, error] = await PatientOldNameList.list({
    apiToken: controller.apiToken,
    patient_id: params.patientId,
  });

  return [data?.items, error];
};
