import React, { useState, forwardRef } from "react";

import { Button, Icon, Modal } from "semantic-ui-react";
import Dashboard from "./Dashboard";
import CardQueueBill from "./CardQueueBill";
import CardQueueDrug from "./CardQueueDrug";
import CardQueuePrepareDrug from "./CardQueuePrepareDrug";
import CardQueueDoctor from "./CardQueueDoctor";

const CardQueueForPatient = forwardRef((props, ref) => {
  const [tab, setTab] = useState("Dashboard");
  const [openFullScreen, setOpenFullScreen] = useState<boolean>(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          padding: "15px 15px 15px 10px",
        }}
      >
        <MenuItem
          tab={tab}
          name="Dashboard"
          title="TeleMed Dashboard"
          setTab={setTab}
        />
        <MenuItem
          tab={tab}
          name="doctor"
          title="คิวหน้าห้องแพทย์"
          setTab={setTab}
        />
        <MenuItem
          tab={tab}
          name="prepareDrug"
          title="คิวรอจัดยา"
          setTab={setTab}
        />
        <MenuItem tab={tab} name="bill" title="คิวรอชำระเงิน" setTab={setTab} />
        <MenuItem tab={tab} name="drug" title="คิวรอรับยา" setTab={setTab} />

        <div style={{ flex: "1" }}></div>

        <div style={{ display: tab === "Dashboard" ? "none" : "" }}>
          <span
            className="textButton"
            onClick={() => {
              setOpenFullScreen(true);
            }}
          >
            FullScreen
          </span>
        </div>
      </div>

      {tab === "Dashboard" ? (
        <Dashboard
          match={props.match}
          ref={ref}
          apiToken={props.apiToken}
          division={props.division}
          controller={props.controller}
          // config
          organization={props.selectedDivision?.organization?.id}
        />
      ) : tab === "bill" ? (
        <>
          <CardQueueBill
            onEvent={props.onEvent}
            queueBill={props.queueBill}
            masterOptions={props.masterOptions}
            setOpenFullScreen={setOpenFullScreen}
            openFullScreen={openFullScreen}
          />
        </>
      ) : tab === "drug" ? (
        <>
          <CardQueueDrug
            onEvent={props.onEvent}
            masterOptions={props.masterOptions}
            setOpenFullScreen={setOpenFullScreen}
            openFullScreen={openFullScreen}
            queueDrug={props.queueDrug}
          />
        </>
      ) : tab === "prepareDrug" ? (
        <>
          <CardQueuePrepareDrug
            onEvent={props.onEvent}
            masterOptions={props.masterOptions}
            setOpenFullScreen={setOpenFullScreen}
            openFullScreen={openFullScreen}
            queuePrepareDrug={props.queuePrepareDrug}
          />
        </>
      ) : tab === "doctor" ? (
        <>
          <CardQueueDoctor
            onEvent={props.onEvent}
            masterOptions={props.masterOptions}
            setOpenFullScreen={setOpenFullScreen}
            openFullScreen={openFullScreen}
            queueDoctor={props.queueDoctor}
          />
        </>
      ) : (
        <></>
      )}

      <Modal
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 0,
          boxShadow: "none",
          margin: "-15px 0",
        }}
        open={openFullScreen}
        onClose={() => setOpenFullScreen(false)}
        closeOnDimmerClick
      >
        {tab === "bill" ? (
          <>
            <CardQueueBill
              onEvent={props.onEvent}
              queueBill={props.queueBill}
              masterOptions={props.masterOptions}
              setOpenFullScreen={setOpenFullScreen}
              onClose={() => setOpenFullScreen(false)}
              openFullScreen={openFullScreen}
            />
          </>
        ) : tab === "drug" ? (
          <>
            <CardQueueDrug
              onEvent={props.onEvent}
              masterOptions={props.masterOptions}
              setOpenFullScreen={setOpenFullScreen}
              openFullScreen={openFullScreen}
              queueDrug={props.queueDrug}
              onClose={() => setOpenFullScreen(false)}
            />
          </>
        ) : tab === "prepareDrug" ? (
          <>
            <CardQueuePrepareDrug
              onEvent={props.onEvent}
              masterOptions={props.masterOptions}
              setOpenFullScreen={setOpenFullScreen}
              openFullScreen={openFullScreen}
              queuePrepareDrug={props.queuePrepareDrug}
              onClose={() => setOpenFullScreen(false)}
            />
          </>
        ) : tab === "doctor" ? (
          <>
            <CardQueueDoctor
              onEvent={props.onEvent}
              masterOptions={props.masterOptions}
              setOpenFullScreen={setOpenFullScreen}
              openFullScreen={openFullScreen}
              queueDoctor={props.queueDoctor}
              onClose={() => setOpenFullScreen(false)}
            />
          </>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
});

const MenuItem = (props: any) => (
  <Button
    size="tiny"
    color={props.name === props.tab ? "blue" : undefined}
    onClick={(e: any) => props.setTab(props.name)}
  >
    {props.title}
  </Button>
);

export default CardQueueForPatient;
