import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Input
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardBasicInfoUX = (props: any) => {
    return(
      <div
        style={{ margin: "15px", padding: "20px", backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", fontSize: "22px" }}>
            Basic Info
          </label>
          <hr>
          </hr>
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={16}>
              <div
                style={{width:"100%"}}>
                {props.errorMessage}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={1}>
              <label>
                Patient in
              </label>
            </FormField>
            <FormField
              inline={true}>
              <DateTextBox
                onChange={props.handleChangeDate("patient_in_date")}
                style={{width:"100%"}}
                value={props.basicInfoData?.patient_in_date }>
              </DateTextBox>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>
                {props.patientInTime}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <label>
                Patient out
              </label>
            </FormField>
            <FormField
              inline={true}>
              <DateTextBox
                onChange={props.handleChangeDate("patient_out_date")}
                value={props.basicInfoData?.patient_out_date }>
              </DateTextBox>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>
                {props.patientOutTime}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <label>
                Total time
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                fluid={true}
                value={props.splitTime("total_time","h")}>
              </Input>
              <label>
                hr.
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                fluid={true}
                value={props.splitTime("total_time","m")}>
              </Input>
              <label>
                min
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}
              width={1}>
              <label>
                Incision
              </label>
            </FormField>
            <FormField
              inline={true}>
              <DateTextBox
                onChange={props.handleChangeDate("incision_date")}
                value={props.basicInfoData?.incision_date }>
              </DateTextBox>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>
                {props.incisionTime}
              </div>
            </FormField>
            <FormField
              className="required"
              inline={true}
              width={1}>
              <label>
                Closure
              </label>
            </FormField>
            <FormField
              inline={true}>
              <DateTextBox
                onChange={props.handleChangeDate("closure_date")}
                value={props.basicInfoData?.closure_date }>
              </DateTextBox>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>
                {props.closureTime}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <label>
                Total Operation time
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                fluid={true}
                value={props.splitTime("operation_time","h")}>
              </Input>
              <label>
                hr.
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                fluid={true}
                value={props.splitTime("operation_time","m")}>
              </Input>
              <label>
                min
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label>
                Preoperative Diagnosis
              </label>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.preOperativeDiagnosis}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <label>
                Postoperative Diagnosis
              </label>
            </FormField>
            <FormField>
              <div>
                {props.postOperativeDiagnosis}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <label>
                Operation
              </label>
            </FormField>
            <FormField>
              <div>
                {props.preOperationSummary}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <label>
                Anesthesia
              </label>
            </FormField>
            <FormField>
              <Dropdown
                onChange={props.handleChangeValue("anesthesia_method")}
                options={props.anesthesiaOptions}
                selection={true}
                value={props.basicInfoData?.anesthesia_method}>
              </Dropdown>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{  width: "50%" }}>
            
            <div>
              {props?.surgeryTeam}
            </div>
          </div>
          <div
            style={{ width: "50%" }}>
            
            <div>
              {props.anesthesiaTeam}
            </div>
          </div>
        </div>
        <div
          style={{ height: "55px", paddingTop: "15px" }}>
          
          <div
            style={{display:"flex",justifyContent:"flex-end"}}>
            
            <div
              style={{margin:"10px"}}>
              {props.buttonSave}
            </div>
            <div
              style={{margin:"10px"}}>
              {props.buttonConfirm}
            </div>
            <div
              style={{margin:"10px"}}>
              {props.buttonUnconfirm}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardBasicInfoUX

export const screenPropsDefault = {}

/* Date Time : Wed May 24 2023 11:03:31 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\", padding: \"20px\", backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Basic Info"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"22px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"flex-end\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\" }"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormGroup",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient in"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 52,
      "name": "DateTextBox",
      "parent": 51,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"patient_in_date\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.basicInfoData?.patient_in_date "
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "label",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient out"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 59,
      "name": "DateTextBox",
      "parent": 57,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"patient_out_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.basicInfoData?.patient_out_date "
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormGroup",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 63,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 63,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "label",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": "Incision"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "Closure"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 70,
      "name": "DateTextBox",
      "parent": 65,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"incision_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.basicInfoData?.incision_date "
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 74,
      "name": "DateTextBox",
      "parent": 72,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"closure_date\")"
        },
        "style": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.basicInfoData?.closure_date "
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormGroup",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormGroup",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormGroup",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormGroup",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormField",
      "parent": 76,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormField",
      "parent": 76,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "label",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": "Preoperative Diagnosis"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "code",
          "value": "props.preOperativeDiagnosis"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "FormField",
      "parent": 77,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 77,
      "props": {
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "label",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "Postoperative Diagnosis"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "props.postOperativeDiagnosis"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "FormField",
      "parent": 78,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormField",
      "parent": 79,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "FormField",
      "parent": 78,
      "props": {
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "FormField",
      "parent": 79,
      "props": {
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "label",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "Operation"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "label",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesia"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 90,
      "props": {
        "children": {
          "type": "code",
          "value": "props.preOperationSummary"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "Dropdown",
      "parent": 91,
      "props": {
        "disabled": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"anesthesia_method\")"
        },
        "options": {
          "type": "code",
          "value": "props.anesthesiaOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.basicInfoData?.anesthesia_method"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  width: \"50%\" }"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "code",
          "value": "props.anesthesiaTeam"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.surgeryTeam"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "label",
      "parent": 147,
      "props": {
        "children": {
          "type": "value",
          "value": "Total time"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "Input",
      "parent": 149,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.splitTime(\"total_time\",\"h\")"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "label",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": "Total Operation time"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "Input",
      "parent": 152,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.splitTime(\"operation_time\",\"h\")"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "FormGroup",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormField",
      "parent": 158,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 159,
      "props": {
        "children": {
          "type": "code",
          "value": "props.errorMessage"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientInTime"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientOutTime"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "code",
          "value": "props.incisionTime"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "code",
          "value": "props.closureTime"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "Input",
      "parent": 165,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.splitTime(\"operation_time\",\"m\")"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "Input",
      "parent": 167,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.splitTime(\"total_time\",\"m\")"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "label",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": "hr."
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "label",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": "min"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "label",
      "parent": 165,
      "props": {
        "children": {
          "type": "value",
          "value": "min"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "label",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": "hr."
        }
      },
      "seq": 172,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardBasicInfoUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 90
}

*********************************************************************************** */
