import { WasmHandler } from "react-lib/frameworks/WasmController";
import {
  DocumentCategoryList,
  DocumentCategorySerializer,
} from "react-lib/apis/issara/core/DocumentCategory";
import {
  DocumentTypeList,
  DocumentTypeSerializer,
} from "react-lib/apis/issara/core/DocumentType";
import {
  DivisionList,
  DivisionSerializer,
} from "react-lib/apis/issara/core/Division";
import {
  ScannedDocumentList,
  ScannedDocumentListSerializer,
} from "react-lib/apis/issara/core/ScannedDocumentDM";
import {
  ScannedDocumentDetail,
  ScannedDocumentSerializerForPatch,
} from "react-lib/apis/issara/core/ScannedDocumentM";
import { DoctorList } from "react-lib/apis/issara/core/Doctor";
import { EncounterSearchList_core } from "react-lib/../../src/issara-sdk/Encounter_core";
import EncounterList from "issara-sdk/apis/EncounterList_core";
import { EncounterDetail_core } from "react-lib/../../src/issara-sdk/Encounter_core";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import UserPermissionView from "react-lib/apis/issara/users/PermissionM";
import {
  NationalityList,
  NationalitySerializer,
} from "react-lib/apis/issara/core/Nationality";
import { PatientList } from "react-lib/apis/issara/apps/REG/PatientDetailM";
import { PatientOldNameList } from "react-lib/apis/issara/apps/REG/PatientOldName";
import fontkit from "@pdf-lib/fontkit";

import { PatientDetailView } from "react-lib/apis/issara/apps/REG/PatientDetail";
import axios from "axios";
import { PDFDocument, degrees } from "pdf-lib";
import { formatDate } from "react-lib/utils/dateUtils";
import moment from "moment";
import { printURL } from "react-lib/utils/printURL";
import * as CAgent from "react-lib/apps/common/CAgent";
import preflight from "issara-sdk/apis/preflightM";
import { detectingBarcode } from "../common/barcodeScanner";
import { isBlankPage } from "../common/blankPagedetect";
import Cookies from "js-cookie";

// const cv = require('./opencv.js')

export type SCNState = {
  errorMessage?: any;
  openModMessage?: any;
  successMessage?: any;
  messageTitle?: any;
  loggedin?: boolean;
  activePage?: number;
  scannedDocumentTab?: "ExistingScan" | "BarcodeScan" | "NewScan";
  scannedDocumentData?: any[];
  columnsScannedDocument?: any;
  selectedDocumentList?: any;
  selectedDocScan?: any;
  cloneDocumentSearchData?: any[];
  documentSearchData?: any[];
  documentCategoryOptions?: any[];
  documentCategoryData?: DocumentCategorySerializer[];
  documentCategory?: string;
  documentTypeOptions?: any[];
  documentTypeData?: DocumentTypeSerializer[];
  documentType?: string;
  documentScanDivisionOptions?: DivisionSerializer[];
  documentScanDivisionData?: DivisionSerializer[];
  documentScanDivision?: string | number;
  documentOwnerDivisionOptions?: DivisionSerializer[];
  doctorSearch?: string | number;
  doctorSearchOptions?: any[];
  doctorFilterList?: any[];
  openWatermark?: boolean;

  nationalitiesList?: NationalitySerializer[];
  patientList?: any[];
  currentHNPage?: number;
  numberOfHNPage?: any;
  documentOwnerDivision?: string | number;
  documentDate?: {
    startDoc: string;
    endDoc: string;
    startScan: string;
    endScan: string;
    startExpiry: string;
    endExpiry: string;
    startVisit?: string;
    endVisit?: string;
  };
  selectedThumbnailList?: {
    documentId: number;
    index: number;
    isChecked: boolean;
    base64Data: string;
    blobUrl: string;
    image: string;
  }[];
  scannedDocLimit?: number;
  patientInfo?: any;
  openModEditDoc?: {
    open: boolean;
    data: ScannedDocumentListSerializer | null;
    cancelError: any;
    editError: any;
    dataOriginal?: any;
    options?: any[];
    categoryOptions?: any[];
  };
  openModError?: {
    open?: boolean;
    title?: string;
    error?: any;
    color?: string;
  };
  openModSecretAuthen?: {
    open: boolean;
    error: any;
  };
  openModCancelAuthen?: {
    open: boolean;
    error: any;
    data: any;
  };
  openModConfirmSave?: boolean;
  openModConfirmEdit?: boolean;
  openPrintDocument?: boolean;
  openModalFilter?: boolean;
  checkedSecretAll?: boolean;
  checkedCancelSecretAll?: boolean;
  errMessage?: any;
  errMessageHN?: any;
  documentNo?: any;
  remark?: string;
  previewPdf?: {
    base64Uri: string;
    blobUrl: string;
    base64Data: string;
  };
  isLoading?: boolean;
  isLoadingModal?: boolean;
  patientListLoading?: boolean;
  patientOldNameData?: any[];
  patientOldNameLoading?: boolean;
  admisNumber?: string;
  visitNumber?: string;
  cancelNote?: string;
  statusDocumentSecret?: {
    is_secret: boolean;
    name: "ปกปิดเอกสาร" | "ยกเลิกการปกปิด";
  };
  barcodeDocumentData?: any[];
  typeOptionsFilter?: any[];
  editDocBarcode?: any;
  patientBarcodeScan?: any;
  patientHN?: string;
  patientFullName?: string;
  patientEncounter?: string | null;
  patientId?: string | null;
  isSaveBarcodeScan?: boolean;
  Statecount?: number;
  requiredFieldError?: {
    hn: boolean;
    category: boolean;
    type: boolean;
    doctor: boolean;
    visit_number: boolean;
  };
  disabledRequiredField?: boolean;
  disableInput?: boolean;
  categoryProps?: string | number;
  isPermissionAdmin?: boolean;
  toggleBarcodeScan?: boolean;
  django?: any;
  totalPageNumber?: number | string;
  successfulPageScan?: number | string;
};

export const SCNStateInitial: SCNState = {
  loggedin: true,
  activePage: 1,
  scannedDocumentTab: "BarcodeScan",
  selectedDocumentList: [],
  scannedDocumentData: [],
  cloneDocumentSearchData: [],
  openWatermark: false,
  documentDate: {
    startDoc: "",
    endDoc: "",
    startScan: "",
    endScan: "",
    startExpiry: "",
    endExpiry: "",
    startVisit: "",
    endVisit: "",
  },
  scannedDocLimit: 10,
  openModEditDoc: {
    open: false,
    data: null,
    cancelError: null,
    editError: null,
    dataOriginal: null,
    options: [],
  },
  openModError: {
    open: false,
    title: "",
    error: "",
  },
  openModSecretAuthen: {
    open: false,
    error: "",
  },
  openModConfirmSave: false,
  checkedSecretAll: false,
  checkedCancelSecretAll: false,
  openModalFilter: false,
  documentNo: "",
  remark: "",
  previewPdf: {
    base64Uri: "",
    blobUrl: "",
    base64Data: "",
  },
  selectedThumbnailList: [],
  openPrintDocument: false,
  isLoading: false,
  isLoadingModal: false,
  patientListLoading: false,
  patientOldNameData: [],
  patientOldNameLoading: false,
  admisNumber: "",
  visitNumber: "",
  openModCancelAuthen: {
    open: false,
    error: null,
    data: null,
  },
  cancelNote: "",
  statusDocumentSecret: {
    is_secret: true,
    name: "ปกปิดเอกสาร",
  },
  barcodeDocumentData: [],
  typeOptionsFilter: [],
  editDocBarcode: {},
  patientBarcodeScan: {},
  patientHN: "",
  patientFullName: "",
  openModConfirmEdit: false,
  patientEncounter: null,
  patientId: null,
  isSaveBarcodeScan: false,
  Statecount: 0,
  requiredFieldError: {
    hn: false,
    category: false,
    type: false,
    doctor: false,
    visit_number: false,
  },
  disabledRequiredField: false,
  disableInput: false,
  categoryProps: "",
  isPermissionAdmin: false,
  django: null,
  doctorFilterList: [],
  toggleBarcodeScan: true,
  totalPageNumber: "",
  successfulPageScan: "",
};

export type SCNEvent =
  | { message: "handleComponentDidMount"; params: {} }
  | {
      message: "SetScannedDocumentTab";
      params: {
        tab: string;
        doctorRef: any;
        runningId: number;
        hnSearchRef: any;
        encounterRef: any;
        hnBarcodeRef: any;
      };
    }
  | {
      message: "ClickedScannedDocument";
      params: {
        runningId: any;
        encounterDoctor: number;
        encounterDoctorName: string;
        visitNumber: number;
        visitName: string;
        visitDate: string;
      };
    }
  | { message: "ClickEditBarcodeDocument"; params: {} }
  | {
      message: "ConfirmEditBarcodeDocument";
      params: {
        runningId: any;
        encounterDoctor: number;
        encounterDoctorName: string;
        visitNumber: number;
        visitId: number;
      };
    }
  | {
      message: "ClickedBarcodeScan";
      params: {
        runningId: any;
        encounterDoctor: number;
        encounterDoctorName: string;
      };
    }
  | { message: "GetDocumentCategory"; params: {} }
  | {
      message: "GetDocumentType";
      params: { value: number; searchText?: string; isReturnOnly?: boolean };
    }
  | { message: "GetDivision"; params: {} }
  | {
      message: "FirstHandler";
      params: {
        date: string;
        categoryId: number;
        tab: number;
        patientId: number;
      };
    }
  | {
      message: "GetSearchDocument";
      params: SCNState & {
        encounterDoctor: number;
        encounterNumber: number;
        encounterNumberId: number;
      };
    }
  | { message: "GetDoctorSearch"; params: { search: string } }
  | { message: "GetEncounterSearch"; params: { number: string } }
  | { message: "GetEncounterList"; params: { type: string } }
  | {
      message: "SetOpenModError";
      params: { open: boolean; title: string; error: string; color: "" };
    }
  | {
      message: "SetOpenModSecretAuthen";
      params: { open: boolean; error: string };
    }
  | {
      message: "SetOpenModCancelAuthen";
      params: {
        open: boolean;
        error: string;
        data: any;
        isClearNote?: boolean;
      };
    }
  | { message: "ChangeDocumentDate"; params: { key: string; date: string } }
  | {
      message: "SetScannedDocLimit";
      params: SCNState & { limit: number; encounterDoctor: number };
    }
  | {
      message: "GetPatientList";
      params: {
        hn: string;
        fullName: string;
        citizenId: number;
        nationality: string;
        is_old_name_search: boolean;
        limit: number;
        offset: number;
      };
    }
  | { message: "GetNationalities"; params: {} }
  | { message: "SetDoctorFilter"; params: { filter: any } }
  | {
      message: "GetPatientDetail";
      params: {
        id: number | string;
        hnSearchRef: any;
        tab?: number;
        categoryId: number;
        hnBarcodeRef: any;
        doctorRef: any;
        encounterRef: any;
      };
    }
  | {
      message: "ToggleDocumentOrder";
      params: { data: ScannedDocumentListSerializer; intl: any };
    }
  | {
      message: "ToggleCheckedIsSecret";
      params: { checked: boolean; id: number };
    }
  | {
      message: "ToggleCheckedThumbnail";
      params: { checked: boolean; id: number; index: number };
    }
  | { message: "ToggleCheckedIsSecretAll"; params: { checked?: boolean } }
  | { message: "ToggleCheckedIsCancelSecretAll"; params: { checked?: boolean } }
  | {
      message: "SetModEditDocument";
      params: { data: any; open: boolean; cancelError?: any; editError?: any };
    }
  | {
      message: "SetSelectedDocScan";
      params: {
        data: any;
        doctorRef: any;
        hnSearchRef: any;
        encounterRef: any;
        hnBarcodeRef: any;
        inputNumberRef: any;
      };
    }
  | { message: "SaveDocumentScan"; params: {} }
  | {
      message: "handleClearDocScan";
      params: { doctorRef: any; encounterRef: any };
    }
  | {
      message: "ConfirmSaveDocumentScan";
      params: {
        doctorRef: any;
        runningId: number;
        encounterRef: any;
        hnSearchRef: any;
      };
    }
  | {
      message: "ConfirmSaveDocumentBarcode";
      params: {
        doctorRef: any;
        runningId: number;
        inputNumberRef: any;
        encounterRef: any;
      };
    }
  | { message: "RemoveScannedOrder"; params: { data: any } }
  | { message: "PatchScannedDocument"; params: { data: any; keyError: string } }
  | { message: "PrintDocument"; params: {} }
  | { message: "GetPatientOldName"; params: { patientId: number } }
  | {
      message: "PutScannedDocUpdateSecret";
      params: {
        username: string;
        password: string;
        items: any[];
        modSecretAuthenRef: any;
        encounterDoctor: number;
        encounterNumber: number;
        encounterNumberId: number;
      };
    }
  | { message: "PrintDocumentImage"; params: { items: any[]; intl: any } }
  | {
      message: "ConfirmRemoveDocument";
      params: {
        username: string;
        password: string;
        items: any[];
        note?: string;
        encounterDoctor: number;
        encounterNumber: number;
        encounterNumberId: number;
      };
    }
  | { message: "GetPatientByHN"; params: { hn: string } }
  | { message: "SelectEncounter"; params: { doctorRef: any; data: any } }
  | { message: "ClearEncounter"; params: { doctorRef: any } }
  | { message: "clearInputBarcodeScan"; params: {} }
  | { message: "SetOpenPrintDocument"; params: {} }
  | { message: "DidMount"; params: {} };

type Handler = WasmHandler<SCNState, SCNEvent>;

export const SetScannedDocumentTab: Handler = async (controller, params) => {
  controller.setState({ scannedDocumentTab: params.tab });
  await SetDocumentOption(controller, params);
};

export const SetSelectedDocScan: Handler = async (controller, params) => {
  setTimeout(async () => {
    const {
      scannedDocumentData,
      documentDate,
      scannedDocumentTab,
      patientInfo,
      documentTypeData,
    } = controller.getState();
    if (scannedDocumentData?.find((doc) => doc.id === params.data.id)) {
      const {
        document_category,
        remark,
        document_no,
        document_type,
        owner_division,
        document_date,
        expiry_date,
        encounter__doctor,
        encounter_doctor_name,
        hn,
        full_name,
        patient,
        encounter,
        visit_date,
        visit_number,
        visit_name,
        encounter_number,
        visit_id,
        isBarcode,
        placeholder_visit_date,
        placeholder_visit_number,
      } = params.data;

      if (
        (isBarcode && !encounter_number && placeholder_visit_number) ||
        (!visit_date && placeholder_visit_date)
      ) {
        controller.setState({
          openModError: {
            open: true,
            title: "แจ้งเตือน!!!",
            color: "blue",
            error: `<p>ไม่พบ visit number/admit number ในระบบ</p>
            <p style="margin:-5px 0 8px;">กรุณาเลือก visit number/admit number</p>
            <p>หรือ ติดต่อเจ้าหน้าที่`,
          },
        });
      }

      if (scannedDocumentTab === "BarcodeScan") {
        let typeOptions = [];
        if (document_category) {
          const [res, error] = await GetDocumentType(controller, {
            value: document_category,
            isReturnOnly: true,
          });
          typeOptions = CreateOptions(res, "", true);
        }
        controller.setState({
          patientFullName: full_name || "",

          documentCategory: " ",
          documentType: " ",
          documentTypeOptions: typeOptions,
        });
        params.hnBarcodeRef.setValue(hn);
      }
      clearRequiredFieldError(controller);
      const state: SCNState = {};
      state.documentCategory = document_category;

      const cloneDate = { ...documentDate };
      cloneDate.startDoc = document_date;
      cloneDate.endDoc = expiry_date;
      cloneDate.startVisit = visit_date;

      controller.setState({
        selectedDocScan: {
          ...params.data,
          visit_date: visit_date,
        },
        previewPdf: { blobUrl: "", base64Data: "", base64Uri: "" },
      });

      if (scannedDocumentTab === "ExistingScan") {
        await GetDocumentType(controller, { value: document_category });
      }

      // if (!document_type) {
      //   await GetDocumentType(controller, { value: document_category })
      // }
      controller.setState({
        documentCategory: document_category,
        documentNo: document_no,
        documentType: document_type,
        documentOwnerDivision: owner_division,
        remark: remark,
        documentDate: cloneDate as any,
        patientHN: hn,
        patientId: patient,
        patientEncounter: encounter,
      });
      params.doctorRef.setId(encounter__doctor);
      params.doctorRef.setTextByElement(encounter_doctor_name);
      if (scannedDocumentTab === "BarcodeScan") {
        // params.inputNumberRef.value = params.data.encounter_number || ""
      }
      if (scannedDocumentTab === "BarcodeScan") {
        params.encounterRef.setId(visit_id);
        params.encounterRef.setTextByElement(encounter_number);
      }
    }
  });
};

export const SelectEncounter: Handler = (controller, params) => {
  const { data, doctorRef } = params;
  const { documentDate } = controller.getState();
  doctorRef.setId(data.doctor);
  doctorRef.setTextByElement(data.doctor_name);
  // console.log(item)
  controller.setState({
    documentDate: {
      ...documentDate,
      startVisit: formatDate(moment(data.started)),
    } as any,
  });
};

export const ClearEncounter: Handler = (controller, params) => {
  const { doctorRef } = params;
  const { documentDate } = controller.getState();
  doctorRef.setId("");
  doctorRef.setTextByElement("");
  // console.log(item)
  controller.setState({
    documentDate: {
      ...documentDate,
      startVisit: "",
    } as any,
  });
};

export const SetOpenModError: Handler = (controller, params) => {
  controller.setState({ openModError: params });
};

export const SetOpenPrintDocument: Handler = (controller, params) => {
  controller.setState({ openPrintDocument: params.open });
};

export const SetScannedDocLimit: Handler = (controller, params) => {
  controller.setState({ scannedDocLimit: params.limit });
  GetSearchDocument(controller, {
    ...params,
    scannedDocLimit: params.limit,
  });
};

export const SetDoctorFilter: Handler = (controller, params) => {
  const { filter } = params;
  const { cloneDocumentSearchData, doctorFilterList } = controller.getState();
  const searchFilter = cloneDocumentSearchData?.filter((data) =>
    filter.find((acc: any) => acc.id === data.items[0].doctor_id)
  );
  const doctorFilter = doctorFilterList?.map((list) => {
    return {
      ...list,
      is_selected: filter.find((acc: any) => acc.id === list.id),
    };
  });

  controller.setState({
    documentSearchData: searchFilter,
    openModalFilter: false,
    doctorFilterList: doctorFilter,
  });
};

export const PrintDocument: Handler = (controller) => {
  const { previewPdf, selectedThumbnailList } = controller.getState();
  if (!selectedThumbnailList?.[0]) {
    controller.setState({
      openModError: {
        open: true,
        title: "กรุณาเลือกรายการเอกสาร",
        color: "yellow",
      },
    });
    return;
  }
  printURL(previewPdf?.blobUrl);
};

export const PrintDocumentImage: Handler = async (controller, params) => {
  const { items, intl } = params;
  const pdfDoc = await PDFDocument.create();
  const sortItems = items.sort((a: any, b: any) => a.page - b.page);
  try {
    for (const item of sortItems) {
      const image = item.document_image;
      const full_name = item.full_name;
      const res = await axios.get(image, { responseType: "arraybuffer" });
      const regexJpg = /.jpg$/i;
      const regexPng = /.png$/i;

      let jpgImage: any;
      if (regexJpg.test(image)) {
        jpgImage = await pdfDoc.embedJpg(res.data);
      } else if (regexPng.test(image)) {
        jpgImage = await pdfDoc.embedPng(res.data);
      }
      const jpgDims = jpgImage.scale(0.5);

      const page = pdfDoc.addPage([jpgDims.width, jpgDims.height]);
      page.drawImage(jpgImage, {
        x: 0,
        y: 0,
        width: jpgDims.width,
        height: jpgDims.height,
        rotate: degrees(0),
        opacity: 1,
      });

      await CreateWaterMark(controller, {
        pdfDoc,
        page,
        intl,
        jpgDims,
      });
    }
  } catch (error) {
    controller.setState({
      openModError: {
        open: true,
        title: "ไม่สามารพิมพ์เอกสารนี้ได้",
      },
    });
    return;
  }

  const byteArray = await pdfDoc.save();
  const blobUrl = byteArrayToBlobUrl(byteArray);

  printURL(blobUrl);
};

export const SetModEditDocument: Handler = (controller, params) => {
  if (!params.cancelError) {
    params.cancelError = null;
  }
  if (!params.editError) {
    params.editError = null;
  }
  controller.setState({
    openModEditDoc: {
      ...params,
      data: params.data?.items?.[0],
      dataOriginal: params.data,
      options: CreateOptions(controller.getState().documentScanDivisionData),
      categoryOptions: CreateOptions(
        controller.getState().documentCategoryData
      ),
    },
  });
};

export const ToggleCheckedIsSecret: Handler = (controller, params) => {
  const { documentSearchData } = controller.getState();
  let index = (documentSearchData || []).findIndex(
    (item) => item.id === params.id
  );
  let docClone = [...(documentSearchData || [])];
  let checkedAll = false;

  docClone[index].is_secret = params.checked;

  if (docClone.every((item) => item.is_secret)) {
    checkedAll = true;
  }

  controller.setState({
    documentSearchData: docClone,
    checkedSecretAll: checkedAll,
  });
};

export const ToggleCheckedThumbnail: Handler = (controller, params) => {
  const { selectedThumbnailList } = controller.getState();
  let index = (selectedThumbnailList || []).findIndex(
    (item) => item.documentId === params.id && item.index === params.index
  );
  let listClone = [...(selectedThumbnailList || [])];

  listClone[index].isChecked = params.checked;
  controller.setState({
    selectedThumbnailList: listClone,
  });

  CreatePreviewPdf(controller, { data: listClone });
};

export const ToggleCheckedIsSecretAll: Handler = (controller, params) => {
  const { documentSearchData, selectedDocumentList } = controller.getState();
  let selectedList = [];
  if (params.checked) {
    const filterSecret = (documentSearchData || []).filter(
      (doc) => !doc.is_secret
    );
    selectedList = [...filterSecret, ...selectedDocumentList];
  } else {
    selectedList = selectedDocumentList.filter((list: any) => list.is_secret);
  }
  let statusDocumentSecret: any = {
    name: "ปกปิดเอกสาร",
    is_secret: true,
  };
  if (selectedList.every((doc: any) => doc.is_secret) && selectedList[0]) {
    statusDocumentSecret = {
      name: "ยกเลิกการปกปิด",
      is_secret: false,
    };
  }
  controller.setState({
    selectedDocumentList: selectedList,
    checkedSecretAll: params.checked,
    statusDocumentSecret,
  });
};

export const ToggleCheckedIsCancelSecretAll: Handler = (controller, params) => {
  const { documentSearchData, selectedDocumentList } = controller.getState();
  let selectedList = [];
  if (params.checked) {
    const filterSecret = (documentSearchData || []).filter(
      (doc) => doc.is_secret
    );
    selectedList = [...filterSecret, ...selectedDocumentList];
  } else {
    selectedList = selectedDocumentList.filter((list: any) => !list.is_secret);
  }
  let statusDocumentSecret: any = {
    name: "ปกปิดเอกสาร",
    is_secret: true,
  };
  if (selectedList.every((doc: any) => doc.is_secret) && selectedList[0]) {
    statusDocumentSecret = {
      name: "ยกเลิกการปกปิด",
      is_secret: false,
    };
  }
  controller.setState({
    selectedDocumentList: selectedList,
    checkedCancelSecretAll: params.checked,
    statusDocumentSecret,
  });
};

export const ToggleDocumentOrder: Handler = (controller, params) => {
  let { selectedDocumentList, documentSearchData } = controller.getState();
  const { data } = params;
  const isChecked = !!selectedDocumentList.find(
    (doc: any) => doc?.id === data.id
  );
  if (!isChecked) {
    selectedDocumentList.push(data);
    AddThumbnailList(controller, params);
  } else {
    selectedDocumentList = selectedDocumentList.filter(
      (doc: any) => doc.id !== data.id
    );
    RemoveThumbnailList(controller, { data });
  }

  let checkedAll = false;
  let checkedCancelAll = false;
  let filterSecret = (documentSearchData || []).filter((doc) => !doc.is_secret);
  let secretList = selectedDocumentList.filter((doc: any) => !doc.is_secret);
  if (secretList.length >= filterSecret.length && filterSecret.length > 0) {
    checkedAll = true;
  }

  filterSecret = (documentSearchData || []).filter((doc) => doc.is_secret);
  secretList = selectedDocumentList.filter((doc: any) => doc.is_secret);
  if (secretList.length >= filterSecret.length && filterSecret.length > 0) {
    checkedCancelAll = true;
  }

  controller.setState({
    selectedDocumentList,
    checkedSecretAll: checkedAll,
    checkedCancelSecretAll: checkedCancelAll,
  });

  let statusDocumentSecret: any = {
    name: "ปกปิดเอกสาร",
    is_secret: true,
  };
  if (
    selectedDocumentList.every((doc: any) => doc.is_secret) &&
    selectedDocumentList[0]
  ) {
    statusDocumentSecret = {
      name: "ยกเลิกการปกปิด",
      is_secret: false,
    };
  }
  controller.setState({
    statusDocumentSecret,
  });
};

export const SaveDocumentScan: Handler = (controller) => {
  const { scannedDocumentData } = controller.getState();

  if (!scannedDocumentData?.[0]) {
    controller.setState({
      openModError: {
        open: true,
        title: "ไม่มีเอกสารที่สแกน",
      },
    });
    return;
  }

  controller.setState({
    openModConfirmSave: true,
  });
};

export const handleClearDocScan: Handler = (controller, params) => {
  params.doctorRef.clear();
  params.encounterRef.clear();

  controller.setState({
    documentCategory: "",
    documentType: "",
    documentOwnerDivision: "",
    documentNo: "",
    remark: "",
    scannedDocumentData: [],
    documentDate: {
      startDoc: "",
      endDoc: "",
      startScan: "",
      endScan: "",
      startExpiry: "",
      endExpiry: "",
      startVisit: "",
      endVisit: "",
    },
  });
};

export const PutScannedDocUpdateSecret: Handler = async (
  controller,
  params
) => {
  const { openModSecretAuthen, statusDocumentSecret } = controller.getState();
  controller.setState({
    isLoadingModal: true,
  });

  const data: any = {};
  const { username, password, items, modSecretAuthenRef } = params;

  const allItems = [];
  for (const data of items) {
    for (const doc of data.items) {
      doc.is_secret = statusDocumentSecret?.is_secret;
      allItems.push(doc);
    }
  }
  if (username) {
    data.username = username;
  }
  if (password) {
    data.password = password;
  }
  if (items) {
    data.items = allItems;
  }

  const [r, e, n] = await ScannedDocumentDetail.update({
    params: {},
    data,
    apiToken: controller.apiToken,
  });

  if (e) {
    controller.setState({
      openModSecretAuthen: {
        ...openModSecretAuthen,
        error: e,
      } as any,
    });
  } else {
    controller.setState({
      openModSecretAuthen: {
        open: false,
        error: null,
      },
      openModError: {
        open: true,
        color: "green",
        title: "บันทึกสำเร็จ",
      },
    });
    modSecretAuthenRef.current.clear();
    GetSearchDocument(controller, { ...controller.getState(), ...params });
  }

  controller.setState({
    isLoadingModal: false,
  });

  return [r, e];
};

export const ConfirmRemoveDocument: Handler = async (controller, params) => {
  const { openModSecretAuthen } = controller.getState();
  controller.setState({
    isLoadingModal: true,
  });

  const { username, password, items, note } = params;
  let promiseArr = items?.items?.map((cancelDoc: any) => {
    let data: ScannedDocumentSerializerForPatch & {
      username?: string;
      password?: string;
    } = {};
    if (username) {
      data.username = username;
    }
    if (password) {
      data.password = password;
    }
    if (note) {
      data.cancel_note = note;
    }
    data.active = false;
    return PatchScanned(controller, {
      saveData: {
        pk: cancelDoc.id,
        params: {},
        data,
        apiToken: controller.apiToken,
      },
    });
  });

  const response = await Promise.all(promiseArr);
  let success = response.every((item: any) => item[1] === null);
  let err = "";
  let result = [null, null];
  if (success) {
    SetOpenModCancelAuthen(controller, {
      data: null,
      err: null,
      open: false,
      isClearNote: true,
    });
    controller.setState({
      openModError: {
        open: true,
        title: "ลบสำเร็จ",
        color: "green",
      },
    });
    GetSearchDocument(controller, { ...controller.getState(), ...params });
  } else {
    response.map((res: any) => {
      if (res[1]) {
        err = res[1];
        result[0] = res[0];
        result[1] = res[1];
        response.splice(0);
      }
    });
  }
  controller.setState({
    isLoadingModal: false,
  });

  console.log(err);
  if (err) {
    SetOpenModCancelAuthen(controller, {
      data: items,
      error: err,
      open: true,
    });
  }
};

export const SetOpenModSecretAuthen: Handler = (controller, params) => {
  const { selectedDocumentList } = controller.getState();

  if (params.open) {
    if (selectedDocumentList.length === 0) {
      return;
    }
  }

  controller.setState({ openModSecretAuthen: params });
};

export const SetOpenModCancelAuthen: Handler = (controller, params) => {
  controller.setState({ openModCancelAuthen: params });
  if (params.isClearNote) {
    controller.setState({
      cancelNote: "",
    });
  }
};

export const FirstHandler: Handler = async (controller, params) => {
  const [secret] = await PostUserPermissionView(controller);
  if (secret) {
    controller.setState({
      isPermissionAdmin: secret.rolegroup_CAN_VIEW_SECRET_DOCUMENT_CODE,
    });
  }

  const { patientId, date, tab, categoryId } = params;
  ChangeDocumentDate(controller, { date: date, key: "startDoc" });
  controller.setState({
    isLoading: true,
  });
  if (tab >= 1) {
    SetScannedDocumentTab(controller, { tab: "NewScan" });
  } else {
    await GetDocumentCategory(controller, params);
    await GetDocumentType(controller, params);
    await GetDivision(controller, params);
  }

  if (!patientId) {
    controller.setState({
      isLoading: false,
    });
  }
  if (categoryId) {
    controller.setState({
      documentCategory: categoryId,
    });
  }
};

export const PatchScannedDocument: Handler = async (controller, params) => {
  controller.setState({
    isLoadingModal: true,
  });
  const {
    username,
    password,
    cancelNote,
    encounterDoctor,
    active,
    documentType,
    documentNo,
    version,
    ownerDivision,
    documentDate,
    expiryDate,
    remark,
  } = params.data;
  const { openModEditDoc } = controller.getState();

  let data: any = {
    // id: editDoc.id,
    // ...openModEditDoc.data
  };
  let promiseArr = openModEditDoc?.dataOriginal?.items?.map((editDoc: any) => {
    const user: any = {};
    if (username) {
      data.username = username;
    }
    if (password) {
      data.password = password;
    }
    if (cancelNote) {
      data.cancel_note = cancelNote;
    }
    if (active || active === false) {
      data.active = active;
    }
    if (documentType) {
      data.document_type = documentType;
    }
    if (documentNo) {
      data.document_no = documentNo;
    }
    if (version) {
      data.version = version;
    }
    if (+ownerDivision) {
      data.owner_division = ownerDivision;
    }
    if (documentDate) {
      data.document_date = documentDate;
    }
    if (expiryDate) {
      data.expiry_date = expiryDate;
    }
    if (remark) {
      data.remark = remark;
    }
    if (encounterDoctor) {
      data.doctor = encounterDoctor;
    }
    // if (encounterDoctor) {
    //   data.encounter__doctor = encounterDoctor;
    // }
    // data = {
    //   items: [data],
    //   ...user
    // }
    return PutScanned(controller, {
      saveData: {
        pk: editDoc.id,
        params: {},
        data,
        apiToken: controller.apiToken,
      },
    });
  });

  const response = await Promise.all(promiseArr);
  let success = response.every((item: any) => item[1] === null);
  let err = "";
  let result = [null, null];
  if (success) {
    SetModEditDocument(controller, { data: null, open: false });
    controller.setState({
      openModError: { open: true, title: "บันทึกสำเร็จ", color: "green" },
    });
    GetSearchDocument(controller, controller.getState());
  } else {
    response.map((res: any) => {
      if (res[1]) {
        err = res[1];
        result[0] = res[0];
        result[1] = res[1];
        response.splice(0);
      }
    });
  }
  controller.setState({
    isLoadingModal: false,
  });

  if (err) {
    const modData = { ...openModEditDoc?.dataOriginal };
    modData.items[0] = { ...modData.items[0], ...data };

    SetModEditDocument(controller, {
      ...openModEditDoc,
      data: modData,
      [params.keyError]: err,
    });
  }

  return [result[0], result[1]];
};

const PatchScanned: Handler = async (controller, params) => {
  return await ScannedDocumentDetail.patch(params.saveData);
};

const PutScanned: Handler = async (controller, params) => {
  // patch ไว้ก่อน
  return await ScannedDocumentDetail.patch(params.saveData);
};

export const GetSearchDocument: Handler = async (controller, params) => {
  controller.setState({
    selectedDocumentList: [],
    selectedThumbnailList: [],
    previewPdf: {
      blobUrl: "",
      base64Uri: "",
      base64Data: "",
    },
    statusDocumentSecret: {
      name: "ปกปิดเอกสาร",
      is_secret: true,
    },
    checkedSecretAll: false,
    checkedCancelSecretAll: false,
  });
  let {
    documentType,
    patientInfo,
    documentNo,
    documentScanDivision,
    documentOwnerDivision,
    documentDate,
    scannedDocLimit,
    encounterDoctor,
    documentCategory,
    admisNumber,
    encounterNumber,
    encounterNumberId,
  } = params;

  // if (!scannedDocLimit) {
  //   return [[]]
  // }
  let isValidate = false;
  let errMessageHN = null;
  if (!patientInfo?.hn) {
    isValidate = true;
    errMessageHN = "กรุณาระบุค่าในช่อง HN";
  }
  controller.setState({
    errMessageHN,
  });
  if (isValidate) {
    return;
  }

  controller.setState({
    isLoading: true,
  });

  let paramsUrl: any = {};

  if (patientInfo?.hn) {
    paramsUrl.patient__hn = patientInfo.hn;
  }
  console.log(encounterNumberId);
  if (patientInfo?.hn || encounterNumberId) {
    paramsUrl.encounter = encounterNumberId || patientInfo.encounter;
  }
  if (encounterNumber) {
    paramsUrl.encounter_number = encounterNumber;
  }
  if (+documentCategory) {
    paramsUrl.document_category = documentCategory;
  }
  if (+documentType) {
    paramsUrl.document_type = documentType;
  }
  if (documentNo) {
    paramsUrl.document_no = documentNo.toString();
  }
  if (+documentScanDivision) {
    paramsUrl.scan_division = documentScanDivision;
  }
  if (+documentOwnerDivision) {
    paramsUrl.owner_division = documentOwnerDivision;
  }

  if (encounterDoctor) {
    paramsUrl.encounter__doctor = encounterDoctor;
  }
  if (documentDate?.startDoc) {
    paramsUrl.doc_date_from = documentDate.startDoc;
  }
  if (documentDate?.endDoc) {
    paramsUrl.doc_date_to = documentDate.endDoc;
  }
  if (documentDate?.startScan) {
    paramsUrl.scan_date_from = documentDate.startScan;
  }
  if (documentDate?.endScan) {
    paramsUrl.scan_date_to = documentDate.endScan;
  }
  if (documentDate?.startExpiry) {
    paramsUrl.expiry_date_from = documentDate.startExpiry;
  }
  if (documentDate?.endExpiry) {
    paramsUrl.expiry_date_to = documentDate.endExpiry;
  }

  if (documentDate?.startVisit) {
    paramsUrl.visit_date_from = documentDate.startVisit;
  }
  if (documentDate?.endVisit) {
    paramsUrl.visit_date_to = documentDate.endVisit;
  }

  paramsUrl.offset = 0;
  paramsUrl.check_secret_permission = "false";
  if (scannedDocLimit) {
    paramsUrl.limit = scannedDocLimit;
  }
  const [r, e, n] = await ScannedDocumentList.list({
    apiToken: controller.apiToken,
    params: paramsUrl,
  });

  if (e) {
    controller.setState({ isLoading: false });
    console.log(e, n);
    return;
  }
  // if (+scannedDocLimit) {
  //   r.items.length = scannedDocLimit; // ??
  // }
  if (+documentOwnerDivision) {
    r.items = r.items.filter(
      (data: any) => data.items[0].owner_division === +documentOwnerDivision
    );
  }
  // if (+documentScanDivision) {
  //   r.items = r.items.filter((data) => data.items[0].scan_division === +documentScanDivision);
  // }

  const addDocId = r.items.map((item: any, index: number) => {
    let is_secret = false;
    if (item.items[0].is_secret) {
      is_secret = true;
    }
    return {
      ...item,
      id: index,
      is_secret,
    };
  });
  const filterList = GetDoctorData(addDocId).map((data) => ({
    ...data,
    is_selected: true,
  }));
  // console.log("documentSearchData ", addDocId)
  controller.setState({
    documentSearchData: addDocId,
    cloneDocumentSearchData: [...addDocId],
    isLoading: false,
    activePage: 1,
    doctorFilterList: filterList,
  });

  return [addDocId, e];
};

export const GetDocumentCategory: Handler = async (controller) => {
  const [r, e, n] = await DocumentCategoryList.list({
    apiToken: controller.apiToken,
    params: {
      limit: 99999,
    },
  });
  if (e) {
    console.log(e, n);
    return;
  }
  controller.setState({
    documentCategoryOptions: CreateOptions(r.items, "", true),
    documentCategory: Cookies.get("division_id"),
    documentCategoryData: r.items,
  });
};

export const GetPatientDetail: Handler = async (controller, params) => {
  let { scannedDocumentTab } = controller.getState();
  if (typeof params.tab === "number") {
    scannedDocumentTab = params.tab >= 1 ? "NewScan" : "BarcodeScan";
  } else if (typeof params.tab === "string"){
    scannedDocumentTab = params.tab
  }

  if (scannedDocumentTab === "NewScan") {
    controller.setState({
      isLoading: true,
    });
  }

  // Issue พศ หรือ คศ แก้กันที่หน้าบ้าน

  const [r, e, n] = await PatientDetailView.retrieve({
    pk: params.id,
    apiToken: controller.apiToken,
    params: {},
  });

  if (e) {
    controller.setState({ isLoading: false });
    console.log(e, n);
    return;
  }
  const { disabledRequiredField, categoryProps, selectedDocScan } =
    controller.getState();

  if (scannedDocumentTab === "BarcodeScan") {
    controller.setState({
      patientFullName: r.full_name,
      patientHN: r.hn,
      patientEncounter: r.encounter_id,
      patientId: r.id,
    });

    if (selectedDocScan?.isEncounterDoctor) {
      params.doctorRef.current.clear();
      params.doctorRef.current.setTextByElement("");
    }
    if (
      !selectedDocScan?.encounter__doctor &&
      r.encounter_id &&
      selectedDocScan?.isEncounterDoctor
    ) {
      SetInputDoctorSearch(controller, {
        encounterId: r.encounter_id,
        doctorRef: params.doctorRef?.current,
      });
    }

    return;
  } else {
    controller.setState({
      patientInfo: r,
    });
  }
  if (r.encounter_id && scannedDocumentTab === "ExistingScan") {
    SetInputDoctorSearch(controller, {
      encounterId: r.encounter_id,
      doctorRef: params.doctorRef?.current,
      encounterRef: params.encounterRef?.current,
    });
  } else {
    if (params.doctorRef?.current) {
      params.doctorRef.current.clear();
      params.doctorRef.current.setTextByElement("");
    }
  }

  if (params.hnSearchRef) {
    params.hnSearchRef.current.setValue(r.hn);
  }

  console.log(scannedDocumentTab);
  if (scannedDocumentTab === "NewScan") {
    if (!controller.getState().documentScanDivisionData) {
      await GetDocumentCategory(controller, params);
      await GetDocumentType(controller, params);
      await GetDivision(controller, { is_default: true });
      // controller.setState({
      //   isLoading:false
      // })
    }
    const paramsSearch: any = {};
    if (+params.categoryId) {
      paramsSearch.documentCategory = +params.categoryId;
    }
    if (disabledRequiredField) {
      paramsSearch.documentCategory = categoryProps;
    }
    const [searchDocument] = await GetSearchDocument(controller, {
      patientInfo: r,
      ...paramsSearch,
    });
    const { documentScanDivisionData, documentCategoryData, documentTypeData } =
      controller.getState();
    const ownerDivision = FilterDivision(
      searchDocument,
      documentScanDivisionData,
      "owner_division"
    );
    const category = FilterDivision(
      searchDocument,
      documentCategoryData,
      "document_category_id"
    );
    const scanDivision = FilterDivision(
      searchDocument,
      documentScanDivisionData,
      "scan_division"
    );
    const typeOptions = FilterDivision(
      searchDocument,
      documentTypeData,
      "document_type"
    );
    const documentCategoryOptions = CreateOptions(
      category,
      "แสดงทุกประเภท",
      true
    );
    const doctorData = GetDoctorData(searchDocument);

    let documentCategory: any = " ";

    if (
      documentCategoryOptions.find(
        (cate: any) => cate.value === +params.categoryId
      )
    ) {
      documentCategory = +params.categoryId;
    }
    if (disabledRequiredField) {
      documentCategory = categoryProps;
    }

    controller.setState({
      documentCategoryOptions,
      documentTypeOptions: CreateOptions(typeOptions, "แสดงทุกประเภท"),
      documentOwnerDivisionOptions: CreateOptions(
        ownerDivision,
        "แสดงทุกแผนกเจ้าของเอกสาร"
      ),
      documentScanDivisionOptions: CreateOptions(
        scanDivision,
        "แสดงทุกแผนกเจ้าของเอกสาร"
      ),
      documentScanDivision: " ",
      documentCategory,
      typeOptionsFilter: typeOptions,
      doctorSearchOptions: CreateOptions(doctorData, "แสดงทุกทันตแพทย์"),
      doctorSearch: " ",
      documentOwnerDivision: " ",
      documentType: " ",
      documentDate: {
        startDoc: "",
        endDoc: "",
        startScan: "",
        endScan: "",
        startExpiry: "",
        endExpiry: "",
        startVisit: "",
        endVisit: "",
      },
      selectedDocumentList: [],
    });
    if (+documentCategory) {
      GetSearchDocument(controller, controller.getState());
    }
  } else {
    controller.setState({
      isLoading: false,
    });
  }
};

const SetInputDoctorSearch: Handler = async (controller, params) => {
  const [encounter] = await GetEncounterDetail(controller, {
    encounterId: params.encounterId,
  });
  if (encounter.doctor) {
    const [doctor] = await GetDoctorDetail(controller, {
      doctorId: encounter.doctor,
    });
    if (doctor.id) {
      if (params?.doctorRef) {
        params.doctorRef.setId(doctor.id);
        params.doctorRef.setTextByElement(doctor.name_code);
      }
    }
    if (params.encounterRef) {
      params.encounterRef.setId(encounter.id);
      params.encounterRef.setTextByElement(encounter.number);
    }
  }
};

export const GetPatientByHN: Handler = async (controller, params) => {
  const [r, e, n] = await PatientList.retrieve({
    pk: params.hn,
    apiToken: controller.apiToken,
    params: {},
  });
  if (e) {
    console.log(e, n);
    return [null, null, n];
  }
  controller.setState({ disableInput: false });
  return [r, e, n];
};

export const FilterDivision = (dataAll: any, dataFilter: any, key: any) => {
  return dataFilter.filter((data: any) => {
    return dataAll.find((search: any) => {
      return search.items[0][key] === data.id;
    });
  });
};

export const GetDoctorData = (searchDocumentList: any[]) => {
  const hasDoctor = new Map();
  const doctorData = [];
  for (const list of searchDocumentList) {
    for (const item of list.items) {
      if (!hasDoctor.has(item.doctor_id) && item.doctor_id) {
        doctorData.push({
          id: item.doctor_id,
          name: item.doctor_fullname,
        });
        hasDoctor.set(item.doctor_id, hasDoctor.size);
      }
    }
  }

  return doctorData.sort((a, b) => ("" + a.name).localeCompare(b.name));
};

export const GetNationalities: Handler = async (controller) => {
  const [r, e, n] = await NationalityList.list({
    apiToken: controller.apiToken,
    params: {
      limit: 99999,
    },
  });
  if (e) {
    console.log(e, n);
    return;
  }
  controller.setState({
    nationalitiesList: CreateOptions(r.items),
  });
  return [r.items, e];
};

export const GetPatientList: Handler = async (controller, params) => {
  controller.setState({
    patientListLoading: true,
  });

  const [r, e, n] = await PatientList.list({
    apiToken: controller.apiToken,
    params,
  });
  if (e) {
    console.log(e, n);
    controller.setState({
      patientList: [],
      numberOfHNPage: 1,
      patientListLoading: false,
    });
    return;
  }
  controller.setState({
    patientList: r.items,
    numberOfHNPage: Number(r.total / params.limit) + 1,
    patientListLoading: false,
  });
  return [r.items, e];
};

export const GetPatientOldName: Handler = async (controller, params) => {
  controller.setState({
    patientOldNameLoading: true,
  });
  const [r, e, n] = await PatientOldNameList.list({
    apiToken: controller.apiToken,
    patient_id: params.patientId,
  });
  if (e) {
    console.log(e, n);
    return;
  }
  controller.setState({
    patientOldNameData: r.items,
    patientOldNameLoading: false,
  });
  return [r.items, e];
};

export const GetDocumentType: Handler = async (controller, params = {}) => {
  if (!params.isReturnOnly) {
    controller.setState({
      documentCategory: params.value,
    });
  }

  const paramsUrl: any = {};
  paramsUrl.limit = 99999;
  if (+params.value) {
    paramsUrl.category = params.value;
  }
  if (params.searchText) {
    paramsUrl.name = params.searchText;
  }
  let { scannedDocumentTab, typeOptionsFilter } = controller.getState();
  scannedDocumentTab = params.tab ? params.tab : scannedDocumentTab;
  let [r, e, n]: any = [{ items: [] }, null, null];

  if (
    scannedDocumentTab === "NewScan" &&
    params.value === " " &&
    !params.isReturnOnly
  ) {
    [r, e, n] = [{ items: typeOptionsFilter }, null, null];
  } else {
    [r, e, n] = await DocumentTypeList.list({
      apiToken: controller.apiToken,
      params: paramsUrl,
    });
  }

  if (e) {
    console.log(e, n);
    return;
  }

  if (!params.isReturnOnly) {
    let typeOptions = [];

    if (["ExistingScan", "BarcodeScan"].includes(scannedDocumentTab || "")) {
      typeOptions = CreateOptions(r.items, "", true);
    } else {
      typeOptions = CreateOptions(r.items, "แสดงทุกประเภท", true);
    }

    controller.setState({
      documentTypeOptions: typeOptions,
      documentTypeData: r.items,
      documentType: " ",
    });
  }
  const items = r.items.map((item: any) => {
    return item.name ? item : { ...item, name: item.name_en };
  });
  return [items, e];
};

export const GetDivision: Handler = async (controller, params) => {
  const [r, e, n] = await DivisionList.list({
    apiToken: controller.apiToken,
    params: {
      limit: 99999,
    },
  });
  if (e) {
    console.log(e, n);
    return;
  }
  let division_id = Cookies.get("division_id");
  division_id = params.is_default ? " " : +division_id;

  controller.setState({
    documentScanDivisionOptions: CreateOptions(r.items),
    documentScanDivisionData: r.items,
    documentOwnerDivisionOptions: CreateOptions(r.items),
    documentScanDivision: division_id,
    documentOwnerDivision: division_id,
  });
};

export const ClickedScannedDocument: Handler = (controller, params) => {
  const { documentCategory, patientInfo, documentType } = controller.getState();
  const { visitNumber } = params;
  // const [isValidate, state] = validateHNAndCategory({
  //   category: documentCategory,
  //   type: documentType,
  //   patient: patientInfo,
  //   isScan: true,
  //   visit: visitNumber
  // });

  // if (isValidate) {
  //   return;
  // }
  controller.setState({
    isLoading: true,
    selectedDocScan: null,
    previewPdf: {
      base64Uri: "",
      blobUrl: "",
      base64Data: "",
    },
  });
  CAgent.checkCAgentScanListAvailable.call(
    { useDataUri: true },
    () => scanList(controller, params),
    () => scan(controller, params)
  );
};

export const ClickedBarcodeScan: Handler = (controller, params) => {
  controller.setState({
    isLoading: true,
    selectedDocScan: null,
    previewPdf: {
      base64Uri: "",
      blobUrl: "",
      base64Data: "",
    },
    documentDate: {
      startDoc: formatDate(moment()),
      endDoc: "",
      startScan: "",
      endScan: "",
      startExpiry: "",
      endExpiry: "",
      startVisit: "",
      endVisit: "",
    },
    documentType: " ",
    documentCategory: " ",
    documentScanDivision: " ",
    documentOwnerDivision: Cookies.get("division_id"),
    documentNo: "",
    remark: "",
    patientHN: "",
    patientFullName: "",
    patientId: null,
    patientEncounter: null,
  });

  CAgent.checkCAgentScanListAvailable.call(
    { useDataUri: true },
    () => scanList(controller, { ...params, is_barcode: true }),
    () => scan(controller, { ...params, is_barcode: true })
  );
};

export const GetDoctorSearch: Handler = async (controller, params) => {
  const [r, e, n] = await DoctorList.list({
    apiToken: controller.apiToken,
    params: {
      search: params.search,
    },
  });
  if (e) {
    console.log(e, n);
    return [];
  }
  return [r.items, e];
};

export const GetEncounterSearch: Handler = async (controller, params) => {
  const { patientInfo, documentSearchData } = controller.getState();
  let paramsUrl: any = {
    number: params.number,
    patient: params.patient,
  };
  if (params.started) {
    paramsUrl = params;
  }
  const [r, e, n] = await EncounterSearchList_core.list({
    apiToken: controller.apiToken,
    params: paramsUrl,
  });
  if (e) {
    return [[], e];
  }
  // const items = r.items.filter((item) =>
  //   documentSearchData.find(data => data.items[0].encounter === item.id))
  return [r.items, e];
};

export const GetEncounterList: Handler = async (controller, params) => {
  const { patientInfo, documentSearchData } = controller.getState();
  const [r, e, n] = await EncounterList.list({
    apiToken: controller.apiToken,
    params: {
      ...params,
    },
  });
  if (e) {
    return [[], null];
  }

  return [r.items, e];
};

export const GetEncounterDetail: Handler = async (controller, params) => {
  const [r, e, n] = await EncounterDetail_core.retrieve({
    pk: params.encounterId,
    apiToken: controller.apiToken,
    params: {},
  });
  if (e) {
    console.log(e, n);
    return [{}];
  }
  return [r, e];
};

export const PostUserPermissionView: Handler = async (controller, params) => {
  const [r, e, n] = await UserPermissionView.post({
    data: {
      rolegroup_CAN_VIEW_SECRET_DOCUMENT_CODE: true,
    },
    apiToken: controller.apiToken,
    params: {},
  });
  if (e) {
    console.log(e, n);
    return [{}];
  }
  return [r, e];
};

export const GetDoctorDetail: Handler = async (controller, params) => {
  const [r, e, n] = await DoctorDetail.retrieve({
    pk: params.doctorId,
    apiToken: controller.apiToken,
    params: {},
  });
  if (e) {
    console.log(e, n);
    return [{}];
  }
  return [r, e];
};

export const ConfirmSaveDocumentBarcode: Handler = async (
  controller,
  params
) => {
  const { scannedDocumentData, patientInfo } = controller.getState();
  const checkedRequired = (scannedDocumentData || []).every(
    (doc) =>
      doc.hn &&
      doc.document_type &&
      doc.document_category &&
      doc.encounter__doctor &&
      doc.encounter_number &&
      doc.visit_date
  );

  if (checkedRequired) {
    controller.setState({
      isSaveBarcodeScan: false,
    });
    ConfirmSaveDocumentScan(controller, params);
  } else {
    controller.setState({
      openModError: {
        open: true,
        title: "ไม่สามารถบันทึกได้เนื่องจากเอกสารไม่สมบูรณ์",
        color: "yellow",
      },
      isSaveBarcodeScan: true,
    });
  }
};

export const ConfirmSaveDocumentScan: Handler = async (controller, params) => {
  const { scannedDocumentData, patientInfo, scannedDocumentTab } =
    controller.getState();
  let data = [...(scannedDocumentData || [])];
  const uriList: string[] = [];
  let promiseArr = data.map((item, index) => {
    let newObj = { ...item };
    if (newObj.id && newObj.id.includes("fakeId")) {
      delete newObj["id"];
    }
    if (!newObj.encounter_number) {
      newObj.encounter_number = undefined;
    }
    newObj.patient = newObj.patient || patientInfo?.id;
    newObj.encounter = newObj.encounter || patientInfo?.encounter_id;
    newObj.page = index + 1;
    newObj.doctor = newObj.encounter__doctor;
    newObj.started = newObj.visit_date;
    if (!newObj.version) {
      newObj.version = 0;
    }
    if (!newObj.owner_division) {
      newObj.owner_division = 0;
    }
    if (newObj.encounter === null) {
      newObj.encounter = undefined;
    }
    const fileName = newObj?.uri?.match(/name=(.*)/i)?.[1];
    if (fileName) {
      uriList.push(fileName);
    }
    delete newObj.uri;
    delete newObj.visit_date;
    delete newObj.base64DataUri;
    delete newObj.document_category_name;
    delete newObj.encounter_doctor_name;
    if (newObj.encounter_number) {
      delete newObj.encounter;
    }
    if (scannedDocumentTab === "ExistingScan") {
      newObj.encounter = newObj.visit_id;
      delete newObj.started;
      delete newObj.visit_id;
      delete newObj.encounter_number;
    }

    return SaveDocScan(controller, { saveData: newObj });
  });

  controller.setState({
    openModConfirmSave: false,

    isLoading: true,
  });

  const response = await Promise.all(promiseArr);
  let success = response.every((item: any) => item[1] === null);

  let err = "";

  controller.setState({
    isLoading: false,
  });

  if (success) {
    if (uriList[0]) {
      CAgent.imagesDone(
        JSON.stringify(uriList),
        (result) => {},
        (result) => {}
      );
    }
    controller.setState({
      openModError: {
        open: true,
        title: "บันทึกสำเร็จ",
        color: "green",
      },
      toggleBarcodeScan: true,
    });

    if (scannedDocumentTab === "ExistingScan") {
      params.hnSearchRef.current.clear();
    }
    SetDocumentOption(controller, { ...params, tab: "ExistingScan" });
  } else {
    response.map((res) => {
      console.log(res);
      if (res[1]) {
        err = res[1];
        if (err.hasOwnProperty("owner_division")) {
          err = "กรุณาระบุ 'แผนกเจ้าของเอกสาร'";
        }
        if (err.hasOwnProperty("encounter")) {
          err = "กรุณาระบุ 'encounter'";
        }
        if (err.hasOwnProperty("encounter_number")) {
          err = "กรุณาระบุ 'encounter_number'";
        }
        response.splice(0);
      }
    });
  }

  if (err) {
    controller.setState({
      openModError: {
        open: true,
        title: "ไม่สามารถบันทึกเอกสารได้",
        color: "red",
        error: err,
      },
    });
  }
};

const SaveDocScan: Handler = async (controller, params) => {
  return await ScannedDocumentList.create({
    apiToken: controller.apiToken,
    params: {},
    data: params.saveData,
    division: Cookies.get("division_id"),
  });
  // if (e) {
  //     console.log(e, n)
  //     return
  // }
  // return response;
};

export const ChangeDocumentDate: Handler = (controller, params) => {
  const { date, key } = params;
  const { documentDate: data } = controller.getState();
  console.log(params);
  // switch (key) {
  //   case "startDoc":
  //     if (date > data.endDoc && data.endDoc) {
  //       data.endDoc = date;
  //     }
  //     break;
  //   case "endDoc":
  //     if (date < data.startDoc && data.startDoc) {
  //       data.startDoc = date;
  //     }
  //     break;
  //   case "startScan":
  //     if (date > data.endScan && data.endScan) {
  //       data.endScan = date;
  //     }
  //     break;
  //   case "endScan":
  //     if (date < data.startScan && data.startScan) {
  //       data.startScan = date;
  //     }
  //     break;
  //   case "startExpiry":
  //     if (date > data.endExpiry && data.endExpiry) {
  //       data.endExpiry = date;
  //     }
  //     break;
  //   case "endExpiry":
  //     if (date < data.startExpiry && data.startExpiry) {
  //       data.startExpiry = date;
  //     }
  //     break;
  // }
  (data as any)[key] = date;
  controller.setState({
    documentDate: data,
  });
};
const SetDocumentOption: Handler = (controller, params) => {
  const { tab } = params;
  const {
    documentTypeData,
    documentScanDivisionData,
    documentCategoryData,
    categoryProps,
    disabledRequiredField,
    documentCategory,
    patientInfo,
  } = controller.getState();

  let typeOptions = [];
  let divisionOptions = [];
  let ownerOptions = [];
  let startDoc = "";
  let categoryOptions = [];
  let ownerDivision: string | number = " ";
  let category: any = " ";

  if (["ExistingScan", "BarcodeScan"].includes(tab)) {
    startDoc = formatDate(moment());
    typeOptions = CreateOptions(documentTypeData, "", true);
    divisionOptions = CreateOptions(documentScanDivisionData);
    ownerOptions = CreateOptions(documentScanDivisionData);
    categoryOptions = CreateOptions(documentCategoryData, "", true);
    if (disabledRequiredField && tab === "ExistingScan") {
      category = categoryProps;
      params.value = categoryProps;
    }

    GetDocumentType(controller, { ...params, tab });
    const division_id = Cookies.get("division_id");
    ownerDivision = +division_id;
  } else {
    // if (disabledRequiredField) {
    //   category = categoryProps
    // }
    typeOptions = CreateOptions(documentTypeData, "แสดงทุกประเภท");
    divisionOptions = CreateOptions(
      documentScanDivisionData,
      "แสดงทุกแผนกที่สแกน"
    );
    ownerOptions = CreateOptions(
      documentScanDivisionData,
      "แสดงทุกแผนกเจ้าของเอกสาร"
    );
    categoryOptions = CreateOptions(documentCategoryData, "", true);
  }

  controller.setState({
    documentDate: {
      startDoc,
      endDoc: "",
      startScan: "",
      endScan: "",
      startExpiry: "",
      endExpiry: "",
      startVisit: "",
      endVisit: "",
    },
    documentType: " ",
    documentTypeOptions: typeOptions,
    documentCategory: category,
    documentScanDivisionOptions: divisionOptions,
    documentScanDivision: " ",
    documentOwnerDivisionOptions: ownerOptions || divisionOptions,
    documentOwnerDivision: ownerDivision,
    documentCategoryOptions: categoryOptions,
    selectedDocScan: null,
    previewPdf: {
      base64Uri: "",
      blobUrl: "",
      base64Data: "",
    },
    selectedThumbnailList: [],
    selectedDocumentList: [],
    documentSearchData: [],
    errMessage: null,
    scannedDocumentData: [],
    documentNo: "",
    remark: "",
    patientHN: "",
    patientFullName: "",
    patientId: null,
    patientEncounter: null,
    patientInfo: {},
    doctorFilterList: [],
    doctorSearch: " ",
    doctorSearchOptions: [],
    errMessageHN: null,
    toggleBarcodeScan: true,
  });
  clearRequiredFieldError(controller);

  if (params.doctorRef) {
    params.doctorRef.clear();
    params.doctorRef.setTextByElement("");
  }
  if (params.runningId) {
    params.runningId.current = 0;
  }
  if (params.encounterRef) {
    params.encounterRef.clear();
    params.encounterRef.setTextByElement("");
  }
  if (params.hnBarcodeRef?.current) {
    params.hnBarcodeRef?.current.clear("");
  }
  if (params.inputNumberRef) {
    params.inputNumberRef.value = "";
  }
};

export const RemoveScannedOrder: Handler = async (controller, params) => {
  const { data } = params;
  let { scannedDocumentData, scannedDocumentTab, documentDate } =
    controller.getState();

  scannedDocumentData = scannedDocumentData?.filter(
    (doc) => doc.id !== data.id
  );
  controller.setState({
    scannedDocumentData,
    selectedDocScan: null,
    previewPdf: {
      blobUrl: "",
      base64Uri: "",
      base64Data: "",
    },
  });

  if (scannedDocumentTab === "BarcodeScan") {
    clearInputBarcodeScan(controller, params);
  }
};

export const clearInputBarcodeScan: Handler = (controller: any, params) => {
  let { documentDate } = controller.getState();
  controller.setState({
    selectedDocScan: null,
    patientEncounter: null,
    patientFullName: "",
    patientHN: "",
    patientId: "",
    documentCategory: " ",
    documentType: " ",
    documentDate: {
      ...documentDate,
      startVisit: "",
      startExpiry: "",
    },
    documentNo: "",
    remark: "",
  });
  clearRequiredFieldError(controller);
};

export const DidMount: Handler = async (controller) => {
  const [res] = await preflight.get({
    apiToken: controller.apiToken,
    params: {},
    division_id: Cookies.get("division_id"),
    device_id: Cookies.get("device_id"),
  });

  controller.setState({
    django: res || {},
  });
};

const CreateOptions = (items: any, placeholder = "", labelCode = false) => {
  const mapOption = (item: any, index: number) => ({
    key: index,
    text: `${item.name || item.name_th || item.name_en}${
      labelCode && item.code && item.code !== "UNDEFINED"
        ? ` (${item.code})`
        : ""
    }`,
    value: item.id,
  });

  return items
    ? placeholder
      ? [{ key: "", text: placeholder, value: " " }, ...items.map(mapOption)]
      : items.map(mapOption)
    : [];
};

const scanList: Handler = (controller, params) => {
  // * Single image
  // * CAgent.scan(function(base64imageList) {

  // * List image
  CAgent.scanList.call(
    { useDataUri: true },
    //
    async function (dataURIList: any) {
      const t0 = performance.now();
      controller.setState({
        totalPageNumber: dataURIList.length,
        successfulPageScan: 0,
      });
      for (let [i, dataURI] of dataURIList.entries()) {
        const t0 = performance.now();
        const getBase64 = await getBase64FromDataURI(dataURI);

        const objBase64 = {
          base64: getBase64.base64.original,
          uri: dataURI,
        };

        let isBlank = await isBlankPage(getBase64.dataURL.original);
        // console.log("isBlank", isBlank)
        if (isBlank !== "Blank") {
          if (params.is_barcode) {
            await addListBarcodeScanned(controller, objBase64, i + 1, params);
          } else {
            await addListScannedDocument(controller, objBase64, i + 1, params);
          }
        }
        controller.setState({ successfulPageScan: i + 1 });
        const t1 = performance.now();
        console.log(
          `Call to addListBarcodeScanned took ${t1 - t0} milliseconds.`
        );
      }
      controller.setState({ totalPageNumber: "", successfulPageScan: "" });

      const t1 = performance.now();
      console.log(`Call to scanList took ${t1 - t0} milliseconds.`);
      controller.setState({
        isLoading: false,
      });

      // subScannerFormRef.current.clearForm();
    },
    function (error: any) {
      SetOpenModError(controller, {
        open: true,
        error,
        title: "แจ้งเตือน",
      });
      controller.setState({
        isLoading: false,
      });
    }
  );
};

const scan: Handler = (controller, params) => {
  CAgent.scan(
    async function (dataURI: string) {
      var prefix = "data:text/xml;base64,";
      if (dataURI.startsWith(prefix)) {
        dataURI = dataURI.slice(prefix.length);
      }
      const objBase64 = {
        base64: dataURI,
        uri: dataURI,
      };
      if (params.is_barcode) {
        await addListBarcodeScanned(controller, objBase64, -1, params);
      } else {
        addListScannedDocument(controller, objBase64, -1, params);
      }
      controller.setState({
        isLoading: false,
      });
      // props.scanCompleted(base64image);
      // setIsLoading(false);

      // subScannerFormRef.current.clearForm();
    },
    function (error: any) {
      SetOpenModError(controller, {
        open: true,
        error,
        title: "แจ้งเตือน",
      });
      controller.setState({
        isLoading: false,
      });
    },
    {}
  );
};

const addListBarcodeScanned = async (
  controller: any,
  objBase64: { base64: string; uri: string },
  index: number,
  params: any
) => {
  // ORiginal
  // const imageDest = document.getElementById('tongImage')
  // imageDest.src="data:text/xml;base64, " + base64image;
  // console.log("🚀 ~ file: ScannedDocumentInterface.ts ~ line 2171 ~ addListBarcodeScanned ~ base64image", base64image)
  const t0 = performance.now();
  const codeData = (await detectingBarcode(objBase64.base64)) as string[];
  const t1 = performance.now();
  console.log(`Call to detectingBarcode took ${t1 - t0} milliseconds.`);
  ///
  const { documentCategoryData, documentTypeData } = controller.getState();

  // console.log(" documentCategoryData, documentTypeData ", documentCategoryData, documentTypeData);

  let hnCateDate = "";
  let doctor = "";
  let visitNumber = "";

  codeData.forEach((item) => {
    if (item.includes("H")) {
      hnCateDate = item;
    } else if (item.includes("D")) {
      doctor = item;
    } else if (item.includes("V")) {
      visitNumber = item;
    }
  });
  let [hn = "", categoryType = "", visitDate = ""] = hnCateDate.split("$");

  hn = hn.replace(/H\^/, "");
  categoryType = categoryType.replace(/C\^/, "");
  doctor = doctor.replace(/D\^/, "");
  visitDate = visitDate
    .replace(/VD\^/, "")
    .replace(/([\d]{4})([\d]{2})([\d]{2})/i, "$3/$2/$1");

  const splitDate = visitDate.split("/");

  visitDate = `${splitDate[0]}/${splitDate[1]}/${+splitDate[2] + 543}`;
  visitNumber = visitNumber.replace(/V\^/, "");

  let isVisitDate = false;
  let isEncounterDoctor = false;
  let isPatient = false;
  let isCategory = false;
  let isType = false;

  if (!visitDate || visitDate.length !== 10) {
    // TODO :Check this logic
    // visitDate = formatDate(moment());
    visitDate = "";
  }
  let patient;
  const visitNumberSearch: any = {};
  const [category] = categoryType.split(".");
  if (hn) {
    [patient] = await GetPatientByHN(controller, { hn });
    if (patient?.id) {
      // TODO : If can't find hn then all information are lost more than HN
      visitNumberSearch.patient = patient?.id;
      visitNumberSearch.number = visitNumber;
      visitNumberSearch.started = visitDate;
    }
  }

  let doctorItems = [];
  if (doctor) {
    [doctorItems] = await GetDoctorSearch(controller, { search: doctor });
    if (doctorItems?.[0]?.code !== doctor) {
      doctorItems = [];
    }
  } else if (patient?.encounter_id && !doctor) {
    const [encounter] = await GetEncounterDetail(controller, {
      encounterId: patient.encounter_id,
    });
    if (encounter?.doctor) {
      const [doctor] = await GetDoctorDetail(controller, {
        doctorId: encounter.doctor,
      });
      if (doctor?.id) {
        doctorItems = [doctor];
      }
    }
  }

  if (!doctorItems[0]?.id) {
    isEncounterDoctor = true;
  }

  let encounter = [];
  if (Object.keys(visitNumberSearch)[0]) {
    [encounter] = await GetEncounterSearch(controller, visitNumberSearch);
    if (encounter[0]?.number?.split("-")?.[0] !== visitNumber) {
      encounter = [];
    }
  }

  let findCategory = documentCategoryData?.find(
    (doc: any) => doc.code === category
  );
  let findType = documentTypeData?.find(
    (doc: any) => doc.code === categoryType && categoryType
  );
  const { scannedDocumentData } = controller.getState();
  const cloneArray = [...scannedDocumentData];

  if (!findCategory?.id) {
    isCategory = true;
  }
  if (!findType?.id) {
    isType = true;
  }
  if (!patient?.id) {
    isPatient = true;
  }

  const data: any = {
    hn: patient?.hn,
    full_name: patient?.full_name,
    patient: patient?.id,
    encounter: patient?.encounter_id,
    document_type_name: findType?.name || findType?.name_en,
    document_type: findType?.id,
    document_category_name: findCategory?.name || findCategory?.name_en,
    document_category: findCategory?.id,
    visit_date: encounter[0] ? visitDate : undefined,
    encounter__doctor: doctorItems[0]?.id,
    encounter_doctor_name: doctorItems[0]?.name_code,
    encounter_doctor_full_name:
      doctor === "9999999999" ? "ไม่ระบุแพทย์" : doctorItems[0]?.full_name,
    encounter_number: encounter[0]?.number,
    visit_id: encounter[0]?.id,
    // visit_date: visitDate ? moment(visitDate).format("DD/MM/YYYY") : undefined,
    document_date: formatDate(moment()),
    code: "",
    // document_image: base64image,
    document_no: "",
    expiry_date: "",
    owner_division: +Cookies.get("division_id"),
    remark: "",
    isVisitDate,
    isEncounterDoctor,
    isPatient,
    isCategory,
    isType,
    placeholder_visit_date: visitDate,
    placeholder_visit_number: visitNumber,
    isBarcode: true,
    uri: objBase64.uri,
  };

  let base64image = objBase64.base64;

  const t2 = performance.now();
  const blobUrl = await imageToBlobUrl(base64image);
  const t3 = performance.now();
  console.log(`Call to imageToBlobUrl took ${t3 - t2} milliseconds.`);
  data.base64DataUri = blobUrl;
  data.code = "";
  data.document_image = base64image;

  data.id = "fakeId" + params.runningId.current;
  if (index !== -1) {
    data.page_number = index;
  }

  params.runningId.current = params.runningId.current + 1;

  // ถ้าหน้า เปล่า

  cloneArray.push(data);
  controller.setState({
    scannedDocumentData: cloneArray,
    previewPdf: {
      blobUrl: "",
      base64Uri: data.base64DataUri,
      base64Data: base64image,
    },
  });
  clearRequiredFieldError(controller);
};

export const ClickEditBarcodeDocument: Handler = async (
  controller: any,
  params
) => {
  const { selectedDocScan } = controller.getState();
  if (!selectedDocScan) {
    controller.setState({
      openModError: {
        open: true,
        title: "กรุณาเลือกรายการเอกสาร",
        color: "yellow",
      },
    });
    return;
  }

  controller.setState({
    openModConfirmEdit: true,
  });
};

export const ConfirmEditBarcodeDocument = async (
  controller: any,
  params: any
) => {
  const {
    documentCategory,
    documentCategoryData,
    documentNo,
    documentOwnerDivision,
    documentDate,
    remark,
    documentTypeData,
    documentType,
    scannedDocumentData,
    selectedDocScan,
    patientHN,
    patientId,
    patientEncounter,
    patientFullName,
    requiredFieldError,
    scannedDocumentTab,
    documentTypeOptions,
  } = controller.getState();
  const { encounterDoctor, encounterDoctorName, visitNumber, visitId } = params;
  const findCategory = documentCategoryData?.find(
    (doc: any) => doc.id === documentCategory
  );
  let findCType = documentTypeData?.find((doc: any) => doc.id === documentType);
  if (!findCType && selectedDocScan?.document_type_name) {
    findCType = documentTypeOptions?.find(
      (doc: any) => doc.value === documentType
    );
    if (findCType) {
      findCType.id = findCType.value;
      findCType.name = findCType.text.replace(/ \(.*/i, "");
    }
  }

  console.log(
    findCType,
    selectedDocScan,
    documentTypeOptions,
    documentType,
    "Check doc scan"
  );

  if (scannedDocumentTab === "BarcodeScan") {
    let cloneError: any = {
      hn: false,
      category: false,
      type: false,
      doctor: false,
      visit_number: false,
      startVisit: false,
      doctorSearch: false,
    };

    if (
      !patientHN ||
      !findCategory ||
      !findCType ||
      !encounterDoctor ||
      !visitNumber
    ) {
      if (!patientHN) {
        cloneError.hn = true;
      }
      if (!findCategory) {
        cloneError.category = true;
      }
      if (!findCType) {
        cloneError.type = true;
      }
      if (!encounterDoctor) {
        cloneError.doctor = true;
      }
      if (!visitNumber) {
        cloneError.visit_number = true;
        cloneError.startVisit = true;
        cloneError.doctorSearch = true;
        cloneError.documentNo = false;
      }

      console.log("cloneError", cloneError);

      controller.setState({
        requiredFieldError: cloneError,
        openModConfirmEdit: false,
        openModError: {
          error: `<p>ไม่อนุญาติให้บันทึกการแก้ไข</p>
          <p>โปรดระบุข้อมูลในช่อง <label style="color:red;">*</label></p>`,
          color: "red",
          open: true,
        },
      });
      return;
    }
    clearRequiredFieldError(controller);
  }

  let data: any = {
    document_type_name: findCType?.name || findCType?.name_en,
    document_type: findCType?.id,
    document_category_name:
      findCategory.name || findCategory.name_th || findCategory.name_en,
    document_category: findCategory.id,
    document_no: documentNo,
    // version: version,
    owner_division: +documentOwnerDivision,
    document_date: documentDate.startDoc,
    expiry_date: documentDate.endDoc,
    visit_date: documentDate.startVisit,
    remark: remark,
    encounter__doctor: encounterDoctor,
    encounter_doctor_name: encounterDoctorName,
    hn: patientHN,
    patient: patientId,
    encounter: patientEncounter,
    full_name: patientFullName,
    encounter_number: visitNumber,
    visit_id: visitId,
  };

  const documentData = scannedDocumentData.map((doc: any) =>
    doc.id === selectedDocScan?.id
      ? {
          ...doc,
          ...data,
        }
      : doc
  );
  controller.setState({
    scannedDocumentData: documentData,
    openModConfirmEdit: false,
  });
};

const clearRequiredFieldError: Handler = async (controller) => {
  controller.setState({
    requiredFieldError: {
      hn: false,
      category: false,
      type: false,
      doctor: false,
      visit_number: false,
    },
  });
};

const addListScannedDocument = async (
  controller: any,
  objBase64: { base64: string; uri: string },
  index: number,
  params: any
) => {
  const {
    documentCategory,
    documentCategoryData,
    documentNo,
    documentOwnerDivision,
    documentDate,
    remark,
    scannedDocumentData,
    documentScanDivision,
    documentTypeData,
    documentType,
  } = controller.getState();
  const {
    encounterDoctor,
    encounterDoctorName,
    visitNumber,
    visitName,
    visitDate,
  } = params;
  const findCategory: any = undefined; //documentCategoryData?.find((doc) => doc.id === documentCategory);
  const findCType: any = undefined; //documentTypeData?.find((doc) => doc.id === documentType);
  let data: any = {
    document_type_name: findCType?.name || findCType?.name_en,
    document_type: findCType?.id,
    document_category_name: findCategory?.name || findCategory?.name_en,
    document_category: findCategory?.id,
    document_no: "", //documentNo,
    // version: version,
    owner_division: +documentOwnerDivision,
    document_date: documentDate.startDoc,
    expiry_date: "", //documentDate.endDoc,
    // visit_date: documentDate.startVisit,
    remark: "", // remark,
    encounter__doctor: null, // encounterDoctor,
    encounter_doctor_name: "", // encounterDoctorName,
    encounter_number: "", // visitName,
    visit_id: null, // visitNumber,
    visit_date: undefined, // visitDate ? moment(visitDate).format("DD/MM/YYYY") : undefined
    uri: objBase64.uri,
  };

  let base64image = objBase64.base64;
  // if (!dataURI.includes("/9j/4AAQ") && !dataURI.includes("iVBORw0KGg")) {
  //   const res = await axios.get(dataURI, { responseType: "arraybuffer" });
  //   const base64 = arrayBufferToBase64(res.data)
  //   base64image = base64
  // }

  const blobUrl = await imageToBlobUrl(base64image);

  data.document_image = base64image;
  data.code = "";
  data.base64DataUri = blobUrl;
  data.id = "fakeId" + params.runningId.current;

  if (index !== -1) {
    data.page_number = index;
  }

  data = {
    ...data,
    isVisitDate: false,
    isEncounterDoctor: true,
    isPatient: true,
    isCategory: true,
    isType: true,
  };

  let arrScanned = [...scannedDocumentData];

  arrScanned.push(data);
  controller.setState({
    scannedDocumentData: arrScanned,
    previewPdf: {
      blobUrl: "",
      base64Uri: data.base64DataUri, // <==
      base64Data: base64image, // <==
    },
  });
  params.runningId.current = params.runningId.current + 1;
};

const AddThumbnailList: Handler = async (controller, params) => {
  const { data, intl } = params;
  const thumbnailList = [];
  const sortItems = data.items.sort((a: any, b: any) => a.page - b.page);

  controller.setState({ isLoading: true });

  try {
    for (const [index, item] of sortItems.entries()) {
      const pdfDoc = await PDFDocument.create();

      const image = item.document_image;
      const res = await axios.get(image, { responseType: "arraybuffer" });
      const regexJpg = /.jpg$/i;
      const regexPng = /.png$/i;

      let jpgImage: any;
      if (regexJpg.test(image)) {
        jpgImage = await pdfDoc.embedJpg(res.data);
      } else if (regexPng.test(image)) {
        jpgImage = await pdfDoc.embedPng(res.data);
      }
      const jpgDims = jpgImage.scale(0.5);

      const page = pdfDoc.addPage([jpgDims.width, jpgDims.height]);
      page.drawImage(jpgImage, {
        x: 0,
        y: 0,
        width: jpgDims.width,
        height: jpgDims.height,
        rotate: degrees(0),
        opacity: 1,
      });

      await CreateWaterMark(controller, {
        pdfDoc,
        page,
        intl,
        jpgDims,
      });

      const base64Data = await pdfDoc.saveAsBase64();
      const byteArray = await pdfDoc.save();
      const blobUrl = byteArrayToBlobUrl(byteArray);
      thumbnailList.push({
        documentId: data.id,
        index,
        isChecked: true,
        base64Data,
        blobUrl,
        image: image,
      });
    }
    controller.setState({
      isLoading: false,
    });
  } catch (error) {
    controller.setState({
      isLoading: false,
    });
    controller.setState({
      openModError: {
        open: true,
        title: "เอกสารนี้ไม่สามารถใช้งานได้",
      },
    });
    console.log(error);
    return;
  }

  const { selectedThumbnailList } = controller.getState();
  const concat = [...(selectedThumbnailList || []), ...thumbnailList];

  controller.setState({
    selectedThumbnailList: concat,
  });

  CreatePreviewPdf(controller, { data: concat });
};

const RemoveThumbnailList: Handler = async (controller, params) => {
  const { data } = params;
  let { selectedThumbnailList } = controller.getState();

  selectedThumbnailList = selectedThumbnailList?.filter(
    (doc) => doc.documentId !== data.id
  );
  controller.setState({
    selectedThumbnailList,
  });
  CreatePreviewPdf(controller, { data: selectedThumbnailList });
};

const CreateWaterMark: Handler = async (controller, params) => {
  const url = "/static/fonts/THSarabunNew-Bold.ttf";
  params.pdfDoc.registerFontkit(fontkit);
  const fontBytes = await fetch(url).then((res) => res.arrayBuffer());

  const state = controller.getState();
  const timesRomanFont = await params.pdfDoc.embedFont(fontBytes);
  const currentDate = new Date();
  const year = (currentDate.getFullYear() + 543).toString().padStart(4, "0");
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const hour = currentDate.getHours().toString().padStart(2, "0");
  const min = currentDate.getMinutes().toString().padStart(2, "0");
  const stringDateTimeWaterMark =
    day + "/" + month + "/" + year + " " + hour + ":" + min;
  params.page.drawText(
    `${params.intl.formatMessage({ id: "cukey467" })} \n` +
      `${params.intl.formatMessage({ id: "cukey266" })} \n` +
      `${params.intl.formatMessage({ id: "cukey443" })} ` +
      state.django?.user?.full_name +
      " " +
      stringDateTimeWaterMark,
    {
      font: timesRomanFont,
      x: params.page.getWidth() - params.jpgDims.width / 1.3,
      y: params.page.getHeight() - params.jpgDims.height / 1.8,
      size: params.page.getWidth() - (96 * params.page.getWidth()) / 100,
      lineHeight: params.page.getWidth() - (94 * params.page.getWidth()) / 100,
      opacity: 0.2,
      rotate: degrees(30),
    }
  );
};

const validateHNAndCategory = (params: any): any => {
  const { category, type, patient, isScan = false, visit } = params;
  let errMessage = null;
  let errMessageHN = null;
  let isValidate = false;

  if (!patient?.hn) {
    isValidate = true;
    errMessageHN = "กรุณาระบุค่าในช่อง HN";
  } else if (typeof category === "string" || !category) {
    isValidate = true;
    errMessage = "กรุณาเลือกค่าในช่อง ประเภทเอกสาร";
  } else if (isScan && typeof type === "string") {
    isValidate = true;
    errMessage = "กรุณาเลือกค่าในช่อง ชื่อเอกสาร";
  }

  return [
    isValidate,
    {
      errMessage,
      errMessageHN,
    },
  ];
};

const CreatePreviewPdf: Handler = async (controller, params) => {
  console.log(params);
  const pdfDoc = await PDFDocument.create();

  for (const thumbnailList of params.data) {
    if (thumbnailList.isChecked) {
      const doc = await PDFDocument.load(thumbnailList.base64Data);
      const [pages] = await pdfDoc.copyPages(doc, [0]);
      pdfDoc.addPage(pages);
    } else {
    }
  }
  const byteArray = await pdfDoc.save();
  let base64Data = "";
  let base64Uri = "";
  let blobUrl = "";

  if (params.data[0] && !params.data.every((acc: any) => !acc.isChecked)) {
    base64Data = await pdfDoc.saveAsBase64();
    base64Uri = "data:application/pdf;base64," + base64Data;
    blobUrl = byteArrayToBlobUrl(byteArray);
  }
  console.log(params.data);
  controller.setState({
    previewPdf: {
      blobUrl,
      base64Uri,
      base64Data,
    },
  });
};

const byteArrayToBlobUrl = (byteArray: any) => {
  const contentType = "application/pdf";
  const blob = new Blob([byteArray], {
    type: contentType,
  });

  let url = "";
  try {
    url = URL.createObjectURL(blob);
  } catch (error) {}

  return url;
};

const imageToBlobUrl = async (imageData: string, type: string = "") => {
  const pdfDoc = await PDFDocument.create();
  let jpgImage: any;
  if (type === "image/jpg" || imageData.includes("/9j/4AAQ")) {
    jpgImage = await pdfDoc.embedJpg(imageData);
  } else if (type === "image/png" || imageData.includes("iVBORw0KGg")) {
    jpgImage = await pdfDoc.embedPng(imageData);
  }

  const jpgDims = jpgImage.scale(0.5);

  const page = pdfDoc.addPage([jpgDims.width, jpgDims.height]);
  page.drawImage(jpgImage, {
    x: 0,
    y: 0,
    width: jpgDims.width,
    height: jpgDims.height,
    rotate: degrees(0),
    opacity: 1,
  });
  const byteArray = await pdfDoc.save();
  return byteArrayToBlobUrl(byteArray);
};

const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

const getArrayBufferFromDataURI = (dataURI: string) => {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    var image = new Image();
    image.onload = function () {
      // Resize
      let width = image.width;
      let height = image.height;
      // Loadinto canvas
      if (image.width > 0 && image.height > 0) {
        let displayCanvas = document.createElement("canvas");
        displayCanvas.width = width;
        displayCanvas.height = height;
        var ctx = displayCanvas.getContext("2d");
        ctx?.drawImage(image, 0, 0, width, height);

        displayCanvas.toBlob((blob: any) => {
          const reader = new FileReader();
          reader.addEventListener("loadend", () => {
            const arrayBuffer: any = reader.result;
            resolve(arrayBuffer);
          });
          reader.readAsArrayBuffer(blob);
        }, "image/jpg");
      }
    };
    image.src = dataURI;
  });
};

const getBase64FromDataURI = async (dataURI: string) => {
  const regexJpg = /.jpg$/i;
  const regexPng = /.png$/i;
  const regReplace = /^data:image\/(png|jpg|jpeg);base64,/i;

  let mediaType = "";
  if (regexJpg.test(dataURI)) {
    mediaType = "image/jpeg";
  } else if (regexPng.test(dataURI)) {
    mediaType = "image/png";
  }

  let prefixJpg = "data:image/jpeg;base64,";
  let prefixPng = "data:image/png;base64,";
  const prefix = dataURI.startsWith(prefixJpg)
    ? prefixJpg
    : dataURI.startsWith(prefixPng)
    ? prefixPng
    : "";

  let isBase64 = false;
  let base64 = dataURI;
  if (/^\/9j\/4AAQ/g.test(dataURI)) {
    isBase64 = true;
    dataURI = `${prefixJpg}${dataURI}`;
  }
  // PNG
  if (/^iVBORw0KGg/g.test(dataURI)) {
    isBase64 = true;
    dataURI = `${prefixPng}${dataURI}`;
  }
  // is data url
  if (prefix) {
    isBase64 = true;
    base64 = dataURI.slice(prefix.length);
  }

  if (isBase64) {
    return {
      dataURL: { original: dataURI, medium: dataURI, low: dataURI },
      base64: { original: base64, medium: base64, low: base64 },
    };
  }

  return new Promise<{
    dataURL: { original: string; medium?: string; low?: string };
    base64: { original: string; medium?: string; low?: string };
  }>((resolve, reject) => {
    var img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = async function () {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        var fullQuality = canvas.toDataURL(mediaType);
        // var mediumQuality = canvas.toDataURL(mediaType, 10);
        // var lowQuality = canvas.toDataURL(mediaType, 10);
        resolve({
          base64: {
            original: fullQuality.replace(regReplace, ""),
            // low: lowQuality.replace(regReplace, ""),
            // medium: mediumQuality.replace(regReplace, "")
          },
          dataURL: {
            original: fullQuality,
            // low: lowQuality,
            // medium: mediumQuality
          },
        }); ///
      } else {
        resolve({
          dataURL: { original: "", low: "", medium: "" },
          base64: { original: "", low: "", medium: "" },
        });
      }
    };

    img.src = dataURI;
  });
};
