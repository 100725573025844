import React, {
  useState,
  useEffect,
  CSSProperties,
  MutableRefObject,
  useRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  SyntheticEvent,
  MouseEvent,
  KeyboardEvent,
} from "react";
import moment from "moment";
import { SemanticICONS, DropdownProps, Checkbox } from "semantic-ui-react";

// Styles
import "../../css/DropdownOptions.css";

// Types
type DropdownOptionsProps = {
  className?: string;
  value?: number | string | number[] | string[];
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  icon?: SemanticICONS;
  disabled?: boolean;
  placeholder?: string;
  options?: { key: any; text: string; value: any; subOptions?: any[] }[];
  multiple?: boolean;
  search?: boolean;
  fluid?: boolean;
  name?: string;
  showSubOptions?: boolean;
  // config
  formatTimeOnBlur?: boolean;
  checked?: boolean;
  noMinWidth?: boolean;
  // callback
  // void or return
  onBeforeChange?: (value: any) => any;
  onChange?: (
    event: SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => void;
};

const IconStyle = {
  cursor: "pointer",
  position: "absolute",
  width: "auto",
  height: "auto",
  top: "0.78571429em",
  right: "0.75em",
  zIndex: 3,
  margin: "-0.78571429em",
  padding: "0.91666667em",
  opacity: 0.8,
  transition: "opacity .1s ease",
} as CSSProperties;

const DropdownOptions = React.forwardRef<any, DropdownOptionsProps>(
  (props, ref) => {
    const [open, setOpen] = useState<boolean>(false);
    const [value, setValue] = useState<any>();
    const [inputValue, setInputValue] = useState<any>("");
    const [options, setOptions] = useState<any[]>([]);

    const inputRef = useRef(null) as MutableRefObject<any>;
    const isFocus = useRef(false) as MutableRefObject<boolean>;

    useImperativeHandle<any, any>(ref, () => inputRef.current);

    const allOptions = useMemo(() => {
      return props.showSubOptions
        ? props.options?.flatMap((option, index) => [
            {
              ...option,
              parent: true,
              groupId: index + 1,
              children: (option.subOptions || []).map((item) => item.value),
            },
            ...(option.subOptions || []).map((item) => ({
              ...item,
              child: true,
              groupId: index + 1,
            })),
          ])
        : props.options;
    }, [props.options, value]);

    const optionsWithoutParent = useMemo(() => {
      return allOptions?.filter((option) => !option.parent);
    }, [allOptions]);

    // Callback Memo -----
    const valueText = useMemo(() => {
      return allOptions?.find((item) => item.value === value)?.text || "";
    }, [allOptions, value]);

    const isSearch = useMemo(() => {
      return props.search && !props.multiple && !props.formatTimeOnBlur;
    }, [props.search, props.multiple, props.formatTimeOnBlur]);
    // -----

    // Effect Callback -----
    const handleMouseClick = useCallback(
      (e: any) => {
        if (!isFocus.current && open) {
          if (isSearch) {
            setInputValue(valueText);
          }

          if (props.formatTimeOnBlur) {
            formatTime({ target: { value: inputRef.current.value } });
          }

          handleBlur();
        }
      },
      [open]
    );
    // -----

    // Use Effect
    useEffect(() => {
      // Event mouse click, close dropdown
      if (open) {
        document.addEventListener("click", handleMouseClick);
      } else {
        document.removeEventListener("click", handleMouseClick);
      }
      return () => {
        document.removeEventListener("click", handleMouseClick);
      };
    }, [open]);

    useEffect(() => {
      if (typeof props.value !== "undefined") {
        setValue(props.value);
      }
    }, [props.value]);

    useEffect(() => {
      if (!props.multiple) {
        setInputValue(valueText);
      } else if (props.multiple && !Array.isArray(value)) {
        setValue((props.value ? [props.value] : []).flat());
      }
    }, [props.multiple, value, valueText]);

    useEffect(() => {
      setOptions(allOptions || []);
    }, [allOptions]);

    // Use Memo
    // Check ว่ามีการถูกเลือกหรือไม่
    const isSelected = useMemo(() => {
      return (
        (props.multiple && Array.isArray(value) ? !!value?.length : !!value) &&
        !props.formatTimeOnBlur
      );
    }, [value, props.multiple, props.formatTimeOnBlur]);

    const isPlaceholderValue = useMemo(() => {
      return open && props.search && !props.multiple;
    }, [open, props.search, props.multiple]);

    // Handler
    // Format time, ใช้สำหรับ TimeComboBox
    const formatTime = (e: any) => {
      let value = inputRef?.current?.value;

      if (!value) {
        return handleChangeValue(value)(e);
      }

      const shiftZero =
        value.length === 1 || /^\d\D/g.test(value) ? `0${value}` : value;
      const cleanText = shiftZero.replace(/\D+/g, "").padEnd(4, "0");

      const overTime =
        Number(cleanText[0] + cleanText[1]) > 24 ? `0${cleanText}` : cleanText;

      const overMinute =
        Number(overTime[2] + overTime[3]) > 59
          ? `${Number(overTime[0] + overTime[1]) + 1}`.padStart(2, "0") +
            `${Number(overTime[2] + overTime[3]) - 60}`.padStart(2, "0")
          : overTime;

      const overHour = (Number(overMinute[0] + overMinute[1]) % 24)
        .toString()
        .padStart(2, "0");

      const time = `${overHour[0]}${overHour[1]}${overMinute[2]}${
        overMinute[3] || 0
      }`;

      handleChangeValue(`${time[0]}${time[1]}:${time[2]}${time[3]}`)(e);
    };

    const handleFocus = () => {
      if (props.disabled) {
        return;
      }

      isFocus.current = true;

      setOpen(true);

      if (isSearch) {
        setInputValue("");
      }

      setTimeout(() => {
        isFocus.current = false;
      }, 200);
    };

    const handleBlur = () => {
      setOpen(false);

      inputRef.current?.blur();

      if (!props.formatTimeOnBlur) {
        setOptions(allOptions || []);
      }
    };

    const handleChangeInput = (e: any) => {
      if (!props.disabled) {
        const value = e.target.value as string;

        setInputValue(value);

        // ค้นหารายการ
        if (props.search && !props.formatTimeOnBlur) {
          let search = (allOptions || []).filter((item) =>
            item.text?.toLowerCase().includes(value.toLowerCase())
          );

          if (props.showSubOptions) {
            const groupIds = search.map((item) => item.groupId);
            search = (allOptions || []).filter((option) =>
              groupIds.includes(option.groupId)
            );
          }

          setOptions(search);
        }
      }
    };

    const handleChangeValue =
      (optionValue: any, data?: any) =>
      (e: SyntheticEvent<HTMLElement, Event>) => {
        // set value ตาม multiple หรือ 1
        let {
          value: id,
          children,
          parent,
        }: any = { value: optionValue, ...(data?.item || {}) };

        const formatValue =
          props.multiple && Array.isArray(value)
            ? parent && data.checked
              ? Array.from(new Set([...value, ...children]))
              : parent && !data.checked
              ? value.filter((acc: any) => !children.includes(acc))
              : value.includes(id)
              ? value.filter((acc) => acc !== id)
              : [...value, id]
            : id;

        if (typeof props.value === "undefined") {
          return setValue(formatValue);
        }

        props.onChange?.(e, { value: formatValue, name: props.name });
      };

    const handlePrevent = (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleClickCombobox = () => {
      // เป็น multiple และ click ภายใน combobox

      // เพื่อ ให้ focus input และ open dropdown
      if (props.multiple && props.search) {
        inputRef?.current?.focus();
      } else if (!props.search) {
        handleFocus();
      }
    };

    const handleRemoveValue = (value: any, data: any) => (e: any) => {
      handleChangeValue(value, data)(e);
    };

    const handleSelected =
      (value: any, data: any) => (e: SyntheticEvent<HTMLElement, Event>) => {
        if (!!data?.item?.parent && !props.multiple) {
          return;
        }

        if (props.multiple && props.search) {
          handleChangeInput({ target: { value: "" } });
        }

        handleChangeValue(value, data)(e);

        // close on selected
        if (!props.multiple) {
          if (isSearch) {
            setInputValue(valueText);
          }

          handleBlur();
        }
      };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      const input = (e.target as HTMLInputElement).value;

      if (
        e.key === "Backspace" &&
        !input &&
        props.multiple &&
        props.search &&
        Array.isArray(value) &&
        value[0]
      ) {
        handleChangeValue(value.slice(-1)[0])(e);
      }
    };

    // console.log("DropdownOptions props: ", props, value, allOptions);
    return (
      <div className={"DropdownOptions"} style={{ ...props.style }}>
        <div
          role="combobox"
          aria-label="options custom"
          aria-expanded={open}
          aria-controls="listbox1"
          className={`ui ${
            open ? "active visible" : ""
          } search selection dropdown${props.multiple ? " multiple" : ""}${
            props.search ? " search" : ""
          }${props.fluid ? " fluid" : ""}${props.disabled ? " disabled" : ""}`}
          style={{
            width: "100%",
            minWidth: props.noMinWidth || props.fluid ? "" : "auto",
            ...(props.disabled
              ? { borderColor: "rgba(34,36,38,.15)", opacity: 0.5 }
              : {}),
            ...(props.inputStyle || {}),
          }}
          onClick={handleClickCombobox}
        >
          {props.multiple &&
            Array.isArray(value) &&
            value.map((v: any) => {
              let data: any = optionsWithoutParent?.find(
                (item: any) => item.value === v
              );
              // const

              return (
                data && (
                  <a className="ui label" onClick={handlePrevent}>
                    {data.text}
                    <i
                      aria-hidden="true"
                      className="delete icon"
                      onMouseOver={() => (isFocus.current = true)}
                      onMouseLeave={() => (isFocus.current = false)}
                      onClick={(e) => {
                        isFocus.current = true;
                        handleRemoveValue(data.value, data)(e);
                      }}
                    />
                  </a>
                )
              );
            })}
          <input
            ref={inputRef}
            value={inputValue}
            type="text"
            readOnly={!props.search}
            autoComplete="off"
            className="search"
            placeholder={
              isSelected
                ? isPlaceholderValue
                  ? valueText
                  : ""
                : props.placeholder
            }
            disabled={props.disabled}
            style={{ width: isSelected ? "" : "100%" }}
            onFocus={handleFocus}
            // onBlur={handleBlur}
            onChange={handleChangeInput}
            onKeyDown={handleKeyDown}
            onMouseOver={() => (isFocus.current = props.search || false)}
            onClick={() => (isFocus.current = props.search || false)}
            onMouseLeave={() => (isFocus.current = false)}
          />
          <i
            aria-hidden="true"
            className={`${props.icon || "dropdown"} icon`}
            style={{
              ...IconStyle,
              cursor: props.disabled ? "auto" : "pointer",
              opacity: props.disabled ? 0.65 : 0.45,
            }}
            onClick={(e) => {
              if (!props.disabled && props.formatTimeOnBlur) {
                handlePrevent(e as any);
                handleChangeValue(moment().format("HH:mm"))(e);
              }
            }}
          />
          <div
            role="listbox"
            id="listbox1"
            aria-multiselectable={props.multiple ? "true" : "false"}
            className={`menu transition ${open ? "visible" : ""}`}
            onMouseOver={() => (isFocus.current = true)}
            onClick={() => (isFocus.current = true)}
            onMouseLeave={() => (isFocus.current = false)}
          >
            {!options.length && props.search ? (
              <div className="message">No results found.</div>
            ) : (
              options.map((item, index) => {
                const checked = item.parent
                  ? Array.isArray(value)
                    ? item.children.every((id: number) =>
                        (value as any)?.includes(id)
                      )
                    : false
                  : (Array.isArray(value) &&
                      (value as any)?.includes(item.value)) ||
                    item.value === value;

                return (
                  <div
                    key={"time-" + index}
                    style={{ pointerEvents: "all" }}
                    role="option"
                    aria-selected={checked}
                    className={`${
                      checked ? `active${props.checked ? "" : " selected"}` : ""
                    } item`}
                    onClick={handleSelected(item.value, {
                      item,
                      checked: !checked,
                    })}
                  >
                    <span
                      className="text"
                      style={{
                        paddingLeft: item.child ? "17.5px" : "",
                      }}
                    >
                      {!props.checked || (item.parent && !props.multiple) ? (
                        item.content ?? item.text
                      ) : (
                        <Checkbox
                          style={{ marginRight: "0.5rem" }}
                          checked={checked}
                          label={item.content ?? item.text}
                          onChange={handleSelected(item.value, {
                            item,
                            checked: !checked,
                          })}
                        />
                      )}
                    </span>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default React.memo(DropdownOptions);
