import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import CardBillingQueueUX from "./CardBillingQueueUX";
import CardPatientSearchBox from "react-lib/apps/HISV3/TPD/CardPatientSearchBox";

import { DateTextBox } from "react-lib/apps/common";
import { Icon, Input } from "semantic-ui-react";
import ModReceiptCode from "./ModReceiptCode";

const CARD_BILLING_QUEUE = "CardBillingQueue";

const CardBillingQueue = (props: any) => {
  const [selectedRow, setSelectedRow] = useState<any>(null);

  useEffect(() => {
    props.onEvent({
      message: "BillingQueueAction",
      params: {
        action: "initial",
        card: CARD_BILLING_QUEUE,
      },
    });

    props.onEvent({
        message: "BillingQueueAction",
        params: {
          action: "search",
          card: CARD_BILLING_QUEUE,
        },
      });
  }, []);

  const handleChangeChecked = (name: string, checked: boolean) => {
    // console.log("handleChangeChecked name: ", name, event)
    props.onEvent({
      message: "BillingQueueAction",
      params: {
        action: "change_value",
        name,
        value: checked,
        card: CARD_BILLING_QUEUE,
      },
    });
  };

  const handleChangePatient = (id: any, hn: string, full_name: string) => {
    // console.log("handleChangePatient: ", id, hn, full_name)
    props.onEvent({
      message: "BillingQueueAction",
      params: {
        action: "change_value",
        name: "selectedPatient",
        value: { id, hn, full_name },
        card: CARD_BILLING_QUEUE,
      },
    });
  };

  const handleChangeDate = (name: string) => (date: string) => {
    props.onEvent({
      message: "BillingQueueAction",
      params: {
        action: "change_value",
        name,
        value: date,
        card: CARD_BILLING_QUEUE,
      },
    });
    // console.log("name: ", name, " date: ", date)
  };

  const handleChangeOption = (event:any, data:any ) => {
    props.onEvent({
      message: "BillingQueueAction",
      params: {
        action: "change_value",
        name: data.name,
        value: data.value,
        card: CARD_BILLING_QUEUE,
      },
    });
  };

  // console.log("CardBilling Queue: ", props)
  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_BILLING_QUEUE}`, null);
          props.setProp(`successMessage.${CARD_BILLING_QUEUE}`, null);
        }}
        error={props.errorMessage?.[CARD_BILLING_QUEUE]}
        success={props.successMessage?.[CARD_BILLING_QUEUE]}
      />
      <CardBillingQueueUX
        // loading
        loadingData={props.loadingData}
        // checekd
        billingFilterQueue={props.billingFilterQueue}
        // options
        masterOptions={props.masterOptions}
        // value
        billingQueueList={props.billingQueueList}
        // function
        onChangeChecked={handleChangeChecked}
        onChangeOption={handleChangeOption}
        onSearch={() => {
          props.onEvent({
            message: "BillingQueueAction",
            params: {
              action: "search",
              card: CARD_BILLING_QUEUE,
            },
          });
        }}
        selectedQueue={(
          state: any,
          rowInfo: any,
          column: any,
          instance: any
        ) => {
          return {
            style: {
              backgroundColor:
                rowInfo?.original?.id &&
                rowInfo?.original?.id === selectedRow?.id
                  ? "#cccccc"
                  : "white",
            },
            onClick: () => {
              setSelectedRow(rowInfo?.original);
            },
            onDoubleClick: () => {
              const { id } = rowInfo?.original;
              if (id) {
                props.onEvent({
                  message: "BillingQueueAction",
                  params: {
                    action: "select_encounter",
                    encounterId: id,
                    card: CARD_BILLING_QUEUE,
                  },
                });
                props?.forward();
              }
            },
          };
        }}
        // component
        patientSearchBox={
          <CardPatientSearchBox
            controller={props.drugOrderQueueController}
            defaultValue={props.billingFilterQueue?.selectedPatient.hn}
            modalStyle={{ width: "auto" }}
            onEnterPatientSearch={handleChangePatient}
          />
        }
        receiptStartDate={
          <DateTextBox
            value={props.billingFilterQueue?.receiptStartDate}
            onChange={handleChangeDate("receiptStartDate")}
          />
        }
        receiptEndDate={
          <DateTextBox
            value={props.billingFilterQueue?.receiptEndDate}
            onChange={handleChangeDate("receiptEndDate")}
          />
        }
        pagination={
          <Pagination
            page={props.billingQueuePagination?.page}
            total={props.billingQueuePagination?.totalPage}
            onPrevious={() => {
              props.onEvent({
                message: "BillingQueuePagination",
                params: { action: "previous" },
              });
            }}
            onEnter={(page: string) => {
              if (Number.isInteger(parseInt(page))) {
                props.onEvent({
                  message: "BillingQueuePagination",
                  params: { action: "page_change", value: page },
                });
              }
            }}
            onNext={() => {
              props.onEvent({
                message: "BillingQueuePagination",
                params: { action: "next" },
              });
            }}
          />
        }
      />

      <ModReceiptCode
        onEvent={props.onEvent}
        setProp={props.setProp}
        // data
        bilReceiptCodeDetail={props.bilReceiptCodeDetail}
        // CommonInterface
        successMessage={props.successMessage}
        errorMessage={props.errorMessage}
      />
    </>
  );
};

const paginationStyle = {
  flexCenter: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  fontPadding: {
    fontSize: "16px",
    padding: "0px 3px",
  },
  inputStyle: {
    width: "60px",
    fontSize: "16px",
  },
};

const Pagination = (props: any) => {
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    setPage(props.page);
  }, [props.page]);

  return (
    <div style={paginationStyle.flexCenter}>
      <Icon name="caret left" size="big" onClick={props.onPrevious} />
      <Input
        style={paginationStyle.inputStyle}
        value={page}
        onChange={(event: any) => {
          setPage(event.target.value);
        }}
        onKeyDown={(event: any, data: any) => {
          console.log("Event keydown: ", event.key, page);
          if (event.key === "Enter") {
            props.onEnter(page);
          }
        }}
      />
      <span style={paginationStyle.fontPadding}>/</span>
      <span style={paginationStyle.fontPadding}>{props.total || 1}</span>
      <Icon name="caret right" size="big" onClick={props.onNext} />
    </div>
  );
};
export default CardBillingQueue;
