import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Checkbox, Label, CheckboxProps, Icon } from "semantic-ui-react";

// UX
import CardLabOrderQueueUX from "./CardLabOrderQueueUX";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Utils
import { adToBe } from "react-lib/utils/dateUtils";

// Const
const ENCOUNTER_OPTIONS = [
  { key: "ALL", value: "ALL", text: "แสดงทั้งหมด" },
  { key: "OPD", value: "OPD", text: "OPD" },
  { key: "IPD", value: "IPD", text: "IPD" },
  { key: "SS", value: "SS", text: "SS" },
  { key: "ER", value: "ER", text: "ER" },
  { key: "OTH", value: "OTH", text: "OTH" },
  { key: "HCU", value: "HCU", text: "HCU" },
  { key: "IME", value: "IME", text: "IME" },
  { key: "HID", value: "HID", text: "HID" },
];

const LAB_OPTIONS = [
  { key: "ALL", value: "ALL", text: "แสดงทุกประเภท" },
  { key: "INLAB", value: "INLAB", text: "Inlab" },
  { key: "OUTLAB", value: "OUTLAB", text: "Outlab" },
  { key: "PHAYATHAI", value: "PHAYATHAI", text: "พญาไท" },
];

const STATUS_OPTIONS = [
  { key: "ALL", value: "ALL", text: "ทุกสถานะ" },
  { key: 1, value: 1, text: "ร้องขอทำ Lab" },
  { key: 2, value: 2, text: "รับเข้าห้องเก็บ Specimen" },
  { key: 3, value: 3, text: "พิมพ์สติ๊กเกอร์แล้ว" },
  { key: 4, value: 4, text: "เก็บ Specimen แล้ว" },
  { key: 6, value: 6, text: "Specimen Center ได้รับ Specimen" },
  { key: 5, value: 5, text: "ได้รับผล Lab" },
  { key: 9, value: 9, text: "ยกเลิก" },
  { key: 10, value: 10, text: "Specimen ถูกปฏิเสธ" },
];

const URGENCY_OPTIONS = [
  { key: "ALL", value: "ALL", text: "แสดงทั้งหมด" },
  { key: "1", value: "1", text: "ROUTINE" },
  { key: "2", value: "2", text: "STAT" },
  { key: "3", value: "3", text: "FAST_TRACK" },
];

const CARD_LAB_ORDER_QUEUE = "CardLabOrderQueue";

const CardLabOrderQueue = (props: any) => {
  const [checkedIds, setCheckedIds] = useState<number[]>([]);

  // Effect
  useEffect(() => {
    const getLabOrderQueue = async () => {
      props.onEvent({ message: "GetLabOrderQueue", params: {} });

      if (props.division) {
        await props.setProp(`labOrderQueue`, {
          ...props.labOrderQueue,
          checkSpecimen: false,
          checkSpecimenReject: false,
          selectedDivision: props.division,
        });
      } else {
        await props.setProp(`labOrderQueue`, {
          ...props.labOrderQueue,
          checkSpecimen: false,
          checkSpecimenReject: false,
          selectedDivision: "ALL",
        });
      }

      props.onEvent({
        message: "GetLabOrderQueue",
        params: {
          action: "search",
          buttonLoadKey: `${CARD_LAB_ORDER_QUEUE}_SEARCH`,
        },
      });
    };

    getLabOrderQueue();
  }, []);

  const handleCheckedSpecimenTube = useCallback(
    (id: number, checked?: boolean) => {
      let idData: any[] = [];

      idData.push(id);

      if (checked) {
        let adds = [...checkedIds, id];
        let unique = [...new Set(adds)];
        setCheckedIds(unique);
      } else {
        let rest = checkedIds?.filter((item: number) => item !== id);
        setCheckedIds([...rest]);
      }
    },
    [checkedIds]
  );

  const labOrderQueue = useMemo(() => {
    return (props?.labOrderQueue?.labOrderQueueList || [])
      .sort((a: any, b: any) =>
        a.highest_urgency <= b.highest_urgency ? 1 : -1
      )
      .map((item: any, idx: any) => ({
        ...item,

        chceck: (
          <>
            <Checkbox
              // value={item.id}
              checked={checkedIds.includes(item.id)}
              onChange={(
                event: React.FormEvent<HTMLInputElement>,
                data: CheckboxProps
              ) => {
                handleCheckedSpecimenTube(item.id, data.checked);
              }}
            ></Checkbox>
          </>
        ),
        date: (
          <div style={{ color: item.highest_urgency === "STAT" ? "red" : "" }}>
            {item.date}
          </div>
        ),
        labId: (
          <div style={{ color: item.highest_urgency === "STAT" ? "red" : "" }}>
            {item.id}
          </div>
        ),
        hn: (
          <div style={{ color: item.highest_urgency === "STAT" ? "red" : "" }}>
            {item.encounter_type === "IPD" && item.encounter_an
              ? `${item.hn}/${item.encounter_an}`
              : item.hn}
          </div>
        ),
        patient_name: (
          <div style={{ color: item.highest_urgency === "STAT" ? "red" : "" }}>
            {item.patient_name}
          </div>
        ),
        division_name: (
          <div style={{ color: item.highest_urgency === "STAT" ? "red" : "" }}>
            {item.division_name}
          </div>
        ),
        highest_urgency: (
          <div style={{ color: item.highest_urgency === "STAT" ? "red" : "" }}>
            {item.highest_urgency}
          </div>
        ),
        arrive_time: (
          <div style={{ color: item.highest_urgency === "STAT" ? "red" : "" }}>
            {item.arrive_time}
          </div>
        ),
        status_name: (
          <div style={{ color: item.highest_urgency === "STAT" ? "red" : "" }}>
            {item.status_name}
            {item.order_item_have_rejected && <Label color="red">REJECT</Label>}
          </div>
        ),
        doctor_name: (
          <div style={{ color: item.highest_urgency === "STAT" ? "red" : "" }}>
            {item.doctor_name}
          </div>
        ),
        encounter_type: (
          <div style={{ color: item.highest_urgency === "STAT" ? "red" : "" }}>
            {item.encounter_type}
          </div>
        ),
        lab_type_label: (
          <div style={{ color: item.highest_urgency === "STAT" ? "red" : "" }}>
            {item.lab_type_label}
          </div>
        ),
      }));
  }, [props?.labOrderQueue?.labOrderQueueList, checkedIds]);

  const labCrossMatchOrderQueue = useMemo(() => {
    return (props?.labOrderQueue?.labCrossMatchOrderQueue || [])
      .sort((a: any, b: any) =>
        a.highest_urgency <= b.highest_urgency ? 1 : -1
      )
      .map((item: any) => ({
        ...item,
        chceck: (
          <>
            <Checkbox />
          </>
        ),
        crossMatchDate: (
          <div style={{ color: item.highest_urgency === "STAT" ? "red" : "" }}>
            {item.perform_date ? adToBe(item.perform_date, "YYYY-MM-DD") : ""}
          </div>
        ),
        labId: (
          <div style={{ color: item.highest_urgency === "STAT" ? "red" : "" }}>
            {item.id}
          </div>
        ),
        hn: (
          <div style={{ color: item.highest_urgency === "STAT" ? "red" : "" }}>
            {item.hn}
          </div>
        ),
        patient_name: (
          <div style={{ color: item.highest_urgency === "STAT" ? "red" : "" }}>
            {item.patient_name}
          </div>
        ),
        division_name: (
          <div style={{ color: item.highest_urgency === "STAT" ? "red" : "" }}>
            {item.division_name}
          </div>
        ),
        highest_urgency: (
          <div style={{ color: item.highest_urgency === "STAT" ? "red" : "" }}>
            {item.highest_urgency}
          </div>
        ),
        status_name: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyItems: "center",
              color: item.highest_urgency === "STAT" ? "red" : "",
              alignItems: "center",
            }}
          >
            <div>{item.status_name}</div>
            {item.order_status_name === "APPOINTMENT" && (
              <div>{"(รอเปิด Encounter)"}</div>
            )}
            {item.order_item_have_rejected && <Label color="red">REJECT</Label>}
          </div>
        ),
        doctor_name: (
          <div style={{ color: item.highest_urgency === "STAT" ? "red" : "" }}>
            {item.doctor_name}
          </div>
        ),
      }));
  }, [props?.labOrderQueue?.labCrossMatchOrderQueue]);

  const divisionOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "แสดงทั้งหมด" },
      ...(props.masterOptions?.division || []),
    ].map((item: any) => ({
      key: item.key,
      value: item.value,
      text: item.text,
    }));
  }, [props.masterOptions?.division]);

  const doctorOptions = useMemo(() => {
    return [
      { key: "ALL", value: "ALL", text: "แสดงทั้งหมด" },
      ...(props.masterOptions?.doctor || []),
    ].map((item: any) => ({
      key: item.key,
      value: item.value,
      text: item.text,
    }));
  }, [props.masterOptions?.doctor]);

  const handleChangeValue = (key: string) => (e: any, v: any) => {
    props.setProp(`labOrderQueue.${key}`, v.value);
  };

  const handleChangeChecked =
    (key: string) =>
    (e: any, { checked }: any) => {
      let params: any = {};

      if (key === "checkSpecimenReject" && checked) {
        params["selectedStatus"] = "";
      }
      params[key] = checked;
      props.onEvent({ message: "SetLabOrderQueueData", params: params });
    };

  const handleChangeDate = (key: string) => (date: any) => {
    props.setProp(`labOrderQueue.${key}`, date);
  };

  const handleSearch = () => {
    props.onEvent({
      message: "GetLabOrderQueue",
      params: { action: "search" },
    });
  };

  const handleRowProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id &&
          rowInfo?.original?.id === props.selectedLabOrderWorking?.id
            ? "#cccccc"
            : "white",
      },
      onDoubleClick: () => {
        props.onEvent({
          message: "SelectLabOrderWorking",
          params: {
            order: rowInfo?.original || null,
            // selectedRecordViewIndex: props.selectedRecordView && index,
            goToMenu: "Specimen Collection",
          },
        });
        props?.layout === 0 && props?.forward?.();
      },
    };
  };

  const handleShowCrossMatch = () => {
    if (!props.labOrderQueue?.showCrossMatch) {
      props.onEvent({
        message: "GetLabOrderQueue",
        params: {
          action: "CROSS_MATCH_SEARCH",
          card: CARD_LAB_ORDER_QUEUE,
        },
      });
    }
    props.setProp(
      `labOrderQueue.showCrossMatch`,
      !props.labOrderQueue?.showCrossMatch
    );
  };

  const handleRowPropsCrossMatch = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id &&
          rowInfo?.original?.id === props.selectedLabOrderWorking?.id
            ? "#cccccc"
            : "white",
      },
      onDoubleClick: () => {
        if (rowInfo?.original?.order_status_name !== "APPOINTMENT") {
          props.onEvent({
            message: "SelectLabOrderWorking",
            params: {
              order: rowInfo?.original || null,
              selectedRecordViewIndex: props.selectedRecordView && 16,
            },
          });
          props?.layout === 0 && props?.forward?.();
        }
      },
    };
  };

  // console.log("CardLabOrderQueue props: ", props)
  return (
    <div style={{ padding: props.size === "max" ? "15px" : "0px" }}>
      <CardLabOrderQueueUX
        labOrderQueue={labOrderQueue}
        selectedBarcode={props.labOrderQueue?.selectedBarcode}
        selectedHN={props.labOrderQueue?.selectedHN}
        selectedFirstname={props.labOrderQueue?.selectedFirstname}
        selectedLastname={props.labOrderQueue?.selectedLastname}
        selectedStartDate={props.labOrderQueue?.selectedStartDate}
        selectedEndDate={props.labOrderQueue?.selectedEndDate}
        selectedEncounterType={props.labOrderQueue?.selectedEncounterType}
        selectedStatus={props.labOrderQueue?.selectedStatus}
        selectedLabType={props.labOrderQueue?.selectedLabType}
        selectedUrgency={props.labOrderQueue?.selectedUrgency}
        selectedDivision={props.labOrderQueue?.selectedDivision}
        selectedDoctor={props.labOrderQueue?.selectedDoctor}
        checkSpecimen={props.labOrderQueue?.checkSpecimen}
        checkSpecimenReject={props.labOrderQueue?.checkSpecimenReject}
        handleChangeValue={handleChangeValue}
        handleChangeDate={handleChangeDate}
        handleChangeChecked={handleChangeChecked}
        divisionOptions={divisionOptions}
        doctorOptions={doctorOptions}
        encounterOptions={ENCOUNTER_OPTIONS}
        labOptions={LAB_OPTIONS}
        statusOptions={STATUS_OPTIONS}
        urgencyOptions={URGENCY_OPTIONS}
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.onEvent({
                message: "GetLabOrderQueue",
                params: {
                  action: "search",
                  card: CARD_LAB_ORDER_QUEUE,
                  buttonLoadKey: `${CARD_LAB_ORDER_QUEUE}_SEARCH`,
                },
              });
            }}
            // data
            paramKey={`${CARD_LAB_ORDER_QUEUE}_SEARCH`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_LAB_ORDER_QUEUE}_SEARCH`]
            }
            // config
            style={{ with: "100%", height: "100%" }}
            name="SEARCH"
            title="ค้นหา"
            color="blue"
          />
        }
        buttonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.onEvent({
                message: "GetLabOrderQueue",
                params: {
                  action: "PRINT",
                  card: CARD_LAB_ORDER_QUEUE,
                  buttonLoadKey: `${CARD_LAB_ORDER_QUEUE}_PRINT`,
                  ids: checkedIds,
                },
              });
            }}
            //props.onEvent({ message: "GetLabOrderQueue", action: "PRINT" })

            //handlePrintLab
            //props.runSequence({ sequence: "ORQueue", action: "PRINT" });
            // setOpen(true);

            // data
            paramKey={`${CARD_LAB_ORDER_QUEUE}_PRINT`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_LAB_ORDER_QUEUE}_PRINT`]
            }
            // config
            color="violet"
            size="small"
            title="ใบ Lab Request"
          />
        }
        onSearch={handleSearch}
        headerTitle={
          props.size === "max"
            ? ",วันเวลาสั่ง,Lab ID,HN/AN,EN type,ชื่อ-นามสกุล,แผนกที่สั่ง,แพทย์ผู้สั่ง,ประเภทของแลป,ความเร่งด่วน,วันเวลาที่รับเข้า,สถานะ"
            : "HN,ชื่อ-สกุล,แพทย์,สถานะ"
        }
        headerKey={
          props.size === "max"
            ? "chceck,date,labId,hn,encounter_type,patient_name,division_name,doctor_name,lab_type_label,highest_urgency,arrive_time,status_name"
            : "hn,patient_name,doctor_name,status_name"
        }
        width={
          props.size === "max"
            ? "30,auto,auto,auto,auto,auto,auto,auto,auto,auto,auto,auto"
            : ""
        }
        rowProps={handleRowProps}
        // cross match section
        isShow={props.labOrderQueue?.showCrossMatch}
        onClickShowCrossMatch={handleShowCrossMatch}
        selectedStartDateCrossMatch={
          props.labOrderQueue?.selectedStartDateCrossMatch
        }
        selectedEndDateCrossMatch={
          props.labOrderQueue?.selectedEndDateCrossMatch
        }
        headerTitleCrossMatch={
          props.size === "max"
            ? "วันที่ทำ Cross-Match,Lab ID,HN/AN,ชื่อ-นามสกุล,แผนกที่สั่ง,แพทย์ผู้สั่ง,ความเร่งด่วน,วันเวลาที่รับเข้า,สถานะ"
            : "HN,ชื่อ-สกุล,แพทย์,สถานะ"
        }
        headerKeyCrossMatch={
          props.size === "max"
            ? "crossMatchDate,labId,hn,patient_name,division_name,doctor_name,highest_urgency,arrive_time,status_name"
            : "hn,patient_name,doctor_name,status_name"
        }
        widthCrossMatch={
          props.size === "max"
            ? "auto,auto,auto,auto,auto,auto,auto,auto,auto"
            : ""
        }
        labCrossMatchOrderQueue={labCrossMatchOrderQueue}
        rowPropsCrossMatch={handleRowPropsCrossMatch}
        crossMatchSearchButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.onEvent({
                message: "GetLabOrderQueue",
                params: {
                  action: "CROSS_MATCH_SEARCH",
                  card: CARD_LAB_ORDER_QUEUE,
                  buttonLoadKey: `${CARD_LAB_ORDER_QUEUE}_CROSS_MATCH_SEARCH`,
                },
              });
            }}
            // data
            paramKey={`${CARD_LAB_ORDER_QUEUE}_CROSS_MATCH_SEARCH`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_LAB_ORDER_QUEUE}_CROSS_MATCH_SEARCH`
              ]
            }
            // config
            style={{ with: "100%", height: "100%" }}
            name="CROSS_MATCH_SEARCH"
            title="ค้นหา"
            color="blue"
          />
        }
        crossMatchPrintButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.onEvent({
                message: "GetLabOrderQueue",
                params: {
                  action: "CROSS_MATCH_PRINT",
                  card: CARD_LAB_ORDER_QUEUE,
                  buttonLoadKey: `${CARD_LAB_ORDER_QUEUE}_CROSS_MATCH_PRINT`,
                  // ids: checkedIds,
                },
              });
            }}
            //props.onEvent({ message: "GetLabOrderQueue", action: "PRINT" })

            //handlePrintLab
            //props.runSequence({ sequence: "ORQueue", action: "PRINT" });
            // setOpen(true);

            // data
            paramKey={`${CARD_LAB_ORDER_QUEUE}_CROSS_MATCH_PRINT`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${CARD_LAB_ORDER_QUEUE}_CROSS_MATCH_PRINT`
              ]
            }
            // config
            color="blue"
            size="small"
            title={
              <div>
                พิมพ์รายงาน Cross Match <Icon name="print" />
              </div>
            }
          />
        }
      />
    </div>
  );
};

export default React.memo(CardLabOrderQueue);
