import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Input
} from 'semantic-ui-react'

const CardOperativeNoteTemplateUX = (props: any) => {
    return(
      <div
        style={{ margin: "15px",}}>
        <div
          style={{ paddingBottom: "0px", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          
          <label
            style={{ fontWeight: "bold", fontSize: "22px" }}>
            Operative Note Template
          </label>
          <div>
            {props.iconClose}
          </div>
        </div>
        <hr>
        </hr>
        <div
          style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "5px 0px", fontWeight: "bold" }}>
          
          <div
            style={{width: "70%" , display: "flex", alignItems: "center"}}>
            
            <div
              style={{width: "90%" }}>
              
              <Input
                fluid={true}
                onChange={props.onChangeSearchTem}
                placeholder="ค้นหาจากชื่อ"
                value={props.searchTem}>
              </Input>
            </div>
            <Button
              color="blue"
              onClick={props.searchData}
              style={{ margin: "0px 10px", }}>
              ค้นหา
            </Button>
          </div>
          <Button
            color="green"
            onClick={props.openFormTem}>
            เพิ่ม Template ใหม่
          </Button>
        </div>
        <div
          style={{ display: props.styleFormTem ? "block" : "none" , padding:"10px" , margin: "10px 0px", fontWeight: "bold", backgroundColor: "rgba(217,217,217,217)" }}>
          
          <div
            style={{width: "100%"}}>
            
            <Input
              fluid={true}
              onChange={props.onChangeTemName}
              placeholder="เขียนชื่อ Template"
              value={props.temName}>
            </Input>
          </div>
          <div
            style={{width: "100%", margin: "10px 0px" }}>
            
            <Input
              fluid={true}
              onChange={props.onChangeNoteName}
              placeholder="เขียนชื่อ Note"
              value={props.noteName}>
            </Input>
          </div>
          <div>
            
            <Button
              color="green"
              onClick={props.saveForm}
              style={{ width: "100px"}}>
              บันทึก
            </Button>
            <Button
              color="red"
              onClick={props.closeForm}
              style={{margin: "0px 5px", width: "100px"}}>
              ปิด
            </Button>
          </div>
        </div>
        <div
          style={{padding:"10px" , margin: "10px 0px", fontWeight: "bold", backgroundColor: "rgba(224,224,224,224)", height: "500px" ,overflowX: "auto", overflowY: "scroll"}}>
          {props.temData}
        </div>
      </div>
    )
}

export default CardOperativeNoteTemplateUX

export const screenPropsDefault = {"debug":true,"showSupplyList":false}

/* Date Time : Wed Sep 07 2022 09:07:06 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\",}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"0px\", display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Operative Note Template"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"22px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 5,
      "void": true
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\", margin: \"5px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 241,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.iconClose"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": null,
      "id": 242,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"70%\" , display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": null,
      "id": 244,
      "name": "div",
      "parent": 242,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\" }"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 245,
      "name": "Button",
      "parent": 242,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.searchData"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\", }"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 246,
      "name": "Input",
      "parent": 244,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSearchTem"
        },
        "placeholder": {
          "type": "value",
          "value": "ค้นหาจากชื่อ"
        },
        "value": {
          "type": "code",
          "value": "props.searchTem"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 247,
      "name": "Button",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่ม Template ใหม่"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.openFormTem"
        },
        "size": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": null,
      "id": 248,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.styleFormTem ? \"block\" : \"none\" , padding:\"10px\" , margin: \"10px 0px\", fontWeight: \"bold\", backgroundColor: \"rgba(217,217,217,217)\" }"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": null,
      "id": 249,
      "name": "div",
      "parent": 248,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": null,
      "id": 250,
      "name": "div",
      "parent": 248,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", margin: \"10px 0px\" }"
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": null,
      "id": 251,
      "name": "div",
      "parent": 248,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 252,
      "name": "Input",
      "parent": 249,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTemName"
        },
        "placeholder": {
          "type": "value",
          "value": "เขียนชื่อ Template"
        },
        "value": {
          "type": "code",
          "value": "props.temName"
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 253,
      "name": "Input",
      "parent": 250,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNoteName"
        },
        "placeholder": {
          "type": "value",
          "value": "เขียนชื่อ Note"
        },
        "value": {
          "type": "code",
          "value": "props.noteName"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 254,
      "name": "Button",
      "parent": 251,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึก"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.saveForm"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100px\"}"
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 255,
      "name": "Button",
      "parent": 251,
      "props": {
        "children": {
          "type": "value",
          "value": "ปิด"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.closeForm"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 5px\", width: \"100px\"}"
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": null,
      "id": 256,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.temData"
        },
        "style": {
          "type": "code",
          "value": "{padding:\"10px\" , margin: \"10px 0px\", fontWeight: \"bold\", backgroundColor: \"rgba(224,224,224,224)\", height: \"500px\" ,overflowX: \"auto\", overflowY: \"scroll\"}"
        }
      },
      "seq": 256,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": false,
  "memo": false,
  "name": "CardOperativeNoteTemplateUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "debug": true,
    "showSupplyList": false
  },
  "width": 75
}

*********************************************************************************** */
