import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { formatDate, parseDate } from "react-lib/utils/dateUtils";
import { Loader } from "semantic-ui-react";
import CardFoodReportOrderQueueUX from "./CardFoodReportOrderQueueUX";
import CardFoodReportUX from "./CardFoodReportUX";

// ================================================== CardFood
type CardFoodReportProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  DJANGO?: any;

  // seq
  runSequence: any;
  FoodListSequence?: any;
  FoodRequestSequence?: any;

  // options
  foodRequestOptions?: any;
  masterOptions?: any;
};

const CardFoodReportInitial: CardFoodReportProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  errorMessage: null,
  successMessage: null,
  buttonLoadCheck: null,
  DJANGO: null,

  // seq
  runSequence: null,
  FoodListSequence: null,
  FoodRequestSequence: null,

  // options
  foodRequestOptions: {},
};

const CardFoodReport: React.FC<CardFoodReportProps> = (props: any) => {
  const [foodMData, setFoodMData] = useState<any>([]);
  const [foodLData, setFoodLData] = useState<any>([]);
  const [foodDData, setFoodDData] = useState<any>([]);
  const [foodBData, setFoodBData] = useState<any>([]);
  const [foodLoading, setFoodLoading] = useState(false);

  useEffect(() => {
    props.runSequence({
      sequence: "FoodList",
      restart: true,
    });
  }, []);

  useEffect(() => {
    if (props.FoodListSequence?.loadingData) {
      setFoodLoading(true);
    } else {
      setFoodLoading(false);
    }
  }, [props.FoodListSequence?.loadingData]);

  useEffect(() => {
    var getFoodMData: any[] = [];
    var getFoodLData: any[] = [];
    var getFoodDData: any[] = [];
    var getFoodBData: any[] = [];

    props.FoodListSequence?.foodData?.map((item: any) => {
      item?.map((itm: any) => {
        const createMM: Moment = moment(itm?.foodOrderList?.created);
        const createDate: Date = new Date(
          createMM.year(),
          createMM.month(),
          createMM.date()
        );
        const npoDate: Date = parseDate(itm?.foodOrderList?.npo_date, false);
        const targetDate: Date = parseDate(
          props.FoodListSequence?.filterQueue?.date,
          false
        );
        if (
          createDate <= targetDate &&
          (itm?.foodOrderList?.npo_date === undefined || npoDate >= targetDate)
        ) {
          var meal_time = itm?.foodOrderList?.meal_time?.split(", ");
          meal_time?.map((item: any) => {
            console.log("mm", item);
            if ((item > "00:00" && item <= "08:00") || item > "22:00") {
              getFoodMData.push(itm);
            }
            if (item > "08:00" && item <= "13:00") {
              getFoodLData.push(itm);
            }
            if (item > "13:00" && item <= "18:00") {
              getFoodDData.push(itm);
            }
            if (item > "18:00" && item <= "22:00") {
              getFoodBData.push(itm);
            }
          });
        }
      });
    });

    setFoodMData(getFoodMData);
    setFoodLData(getFoodLData);
    setFoodDData(getFoodDData);
    setFoodBData(getFoodBData);
  }, [props.FoodListSequence?.foodData]);

  const handleSetFoodReportList = (item: any) => {
    return (item || []).map((itm: any) => ({
      room_no: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {itm?.roomList?.room_no}
        </div>
      ),
      hn: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {itm?.roomList?.hn}
        </div>
      ),
      name: <div>{itm?.roomList?.patient_name}</div>,
      food_type_name: (
        <div>
          <div>{itm?.foodOrderList?.food_type_name || ""}</div>
          <div
            style={{
              display: itm?.foodOrderList?.food_type_type === "R" ? "" : "none",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ fontWeight: `bold` }}>ปริมาตร : </div>
              <div>{itm?.foodOrderList?.routine_size || ""}</div>
            </div>
          </div>
          <div
            style={{
              display: itm?.foodOrderList?.food_type_type === "R" ? "" : "none",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ fontWeight: `bold` }}>ความเข้มข้น : </div>
              <div>{itm?.foodOrderList?.intensity || ""}</div>
            </div>
          </div>
          <div
            style={{
              display: itm?.foodOrderList?.food_type_type === "R" ? "" : "none",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ fontWeight: `bold` }}>Mixture : </div>
              <div>{itm?.foodOrderList?.mixture || ""}</div>
            </div>
          </div>
          <div
            style={{
              display: itm?.foodOrderList?.food_type_type === "R" ? "" : "none",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ fontWeight: `bold` }}>Volume : </div>
              <div>{itm?.foodOrderList?.mixture_volume || ""}</div>
            </div>
          </div>
          <div
            style={{
              display: itm?.foodOrderList?.food_type_type === "R" ? "" : "none",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ fontWeight: `bold` }}>หมายเหตุ : </div>
              <div>{itm?.foodOrderList?.remark || ""}</div>
            </div>
          </div>
        </div>
      ),
      food_nutrition_control: (
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ fontWeight: `bold` }}>อาหารเฉพาะโรค : </div>
            <div>{itm?.foodOrderList?.special_diet_detail || ""}</div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ fontWeight: `bold` }}>หมายเหตุ : </div>
            <div>{itm?.foodOrderList?.special_diet_remark || ""}</div>
          </div>
          <hr
            style={{
              color: "gray",
              backgroundColor: "gray",
              height: 2,
            }}
          />
          <div>คำสั่งควบคุมต่อวัน</div>
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ fontWeight: `bold` }}>พลังงาน : </div>
              <div>
                {`${
                  itm?.foodOrderList?.food_nutrition_control?.[0]?.result || "-"
                } ${
                  itm?.foodOrderList?.food_nutrition_control?.[0]?.unit_name ||
                  ""
                }`}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ fontWeight: `bold` }}>โคเรสเตอรอล : </div>
              <div>
                {`${
                  itm?.foodOrderList?.food_nutrition_control?.[1]?.result || "-"
                } ${
                  itm?.foodOrderList?.food_nutrition_control?.[1]?.unit_name ||
                  ""
                }`}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ fontWeight: `bold` }}>LCT : </div>
              <div>
                {`${
                  itm?.foodOrderList?.food_nutrition_control?.[2]?.result || "-"
                } ${
                  itm?.foodOrderList?.food_nutrition_control?.[2]?.unit_name ||
                  ""
                }`}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ fontWeight: `bold` }}>MCT : </div>
              <div>
                {`${
                  itm?.foodOrderList?.food_nutrition_control?.[3]?.result || "-"
                } ${
                  itm?.foodOrderList?.food_nutrition_control?.[3]?.unit_name ||
                  ""
                }`}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ fontWeight: `bold` }}>ไขมัน : </div>
              <div>
                {`${
                  itm?.foodOrderList?.food_nutrition_control?.[4]?.result || "-"
                } ${
                  itm?.foodOrderList?.food_nutrition_control?.[4]?.unit_name ||
                  ""
                }`}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ fontWeight: `bold` }}>Salt : </div>
              <div>
                {`${
                  itm?.foodOrderList?.food_nutrition_control?.[5]?.result || "-"
                } ${
                  itm?.foodOrderList?.food_nutrition_control?.[5]?.unit_name ||
                  ""
                }`}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ fontWeight: `bold` }}>หมายเหตุ : </div>
              <div>{itm?.foodOrderList?.nutrition_control_remark || ""}</div>
            </div>
          </div>
        </div>
      ),
      detail: (
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ fontWeight: `bold` }}>แพ้ : </div>
            <div>{itm?.foodOrderList?.allergy_food || ""}</div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ fontWeight: `bold` }}>เสริม : </div>
            <div>{itm?.foodOrderList?.supplement_food || ""}</div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ fontWeight: `bold` }}>ข้อจำกัด : </div>
            <div>{itm?.foodOrderList?.restriction_food || ""}</div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ fontWeight: `bold` }}>หมายเหตุ : </div>
            <div>{itm?.foodOrderList?.remark || ""}</div>
          </div>
        </div>
      ),
    }));
  };

  const handleChangeData = (_event: any, data: any) => {
    if (data?.type === "checkbox") {
      props.setProp(`FoodListSequence.${data.name}`, data.checked);
    } else {
      props.setProp(`FoodListSequence.${data.name}`, data.value);
    }

    if (data.name === "filterQueue.division") {
    }
  };

  const handleChangeDate = (date: any) => {
    props.setProp(`FoodListSequence.filterQueue.date`, date);
  };

  return (
    <>
      <CardFoodReportOrderQueueUX
        mealOptions={props.masterOptions?.regularMeal}
        wardOptions={props.masterOptions.divisionIpd}
        FoodListSequence={props.FoodListSequence}
        onChangeData={handleChangeData}
        onChangeDate={handleChangeDate}
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "FoodList",
                action: "getFoodDate",
              });
            }}
            // data
            paramKey={`CardFoodReport_Search`}
            buttonLoadCheck={props.buttonLoadCheck?.[`CardFoodReport_Search`]}
            // config
            color={"blue"}
            name="SAVE"
            size="small"
            title={"แสดง"}
          />
        }
        buttonPrintReport={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "FoodList",
                action: "printReport",
                data: {
                  foodMData: foodMData,
                  foodLData: foodLData,
                  foodDData: foodDData,
                  foodBData: foodBData,
                },
              });
            }}
            // data
            paramKey={`CardFoodReport_Print`}
            buttonLoadCheck={props.buttonLoadCheck?.[`CardFoodReport_Print`]}
            // config
            color={"green"}
            name="SAVE"
            size="small"
            title={"พิมพ์รายงาน"}
          />
        }
      />

      <div
        style={{
          height: "100%",
          display: foodLoading ? "flex" : "none",
          alignItems: "center",
        }}
      >
        <Loader active={foodLoading} inline="centered" Inverted>
          Loading{" "}
        </Loader>
      </div>

      <div style={{ display: foodLoading ? "none" : "" }}>
        {(props.FoodListSequence?.filterQueue?.meal === 1 ||
          props.FoodListSequence?.filterQueue?.meal === "") && (
          <CardFoodReportUX
            title={"มื้อ 08:00 น."}
            foodData={handleSetFoodReportList(foodMData)}
          />
        )}

        {(props.FoodListSequence?.filterQueue?.meal === 2 ||
          props.FoodListSequence?.filterQueue?.meal === "") && (
          <CardFoodReportUX
            title={"มื้อ 13:00 น."}
            foodData={handleSetFoodReportList(foodLData)}
          />
        )}

        {(props.FoodListSequence?.filterQueue?.meal === 3 ||
          props.FoodListSequence?.filterQueue?.meal === "") && (
          <CardFoodReportUX
            title={"มื้อ 18:00 น."}
            foodData={handleSetFoodReportList(foodDData)}
          />
        )}

        {(props.FoodListSequence?.filterQueue?.meal === 4 ||
          props.FoodListSequence?.filterQueue?.meal === "") && (
          <CardFoodReportUX
            title={"มื้อ 22:00 น."}
            foodData={handleSetFoodReportList(foodBData)}
          />
        )}
      </div>
    </>
  );
};

CardFoodReport.defaultProps = CardFoodReportInitial;

export default React.memo(CardFoodReport);
